/* eslint-disable @typescript-eslint/no-explicit-any */
import { AbstractControl, ValidationErrors } from '@angular/forms';
import { CdsOption } from '@cds/ng-core/configuration';
import { ScaleType } from 'src/app/core/models/enum/commission-scale.enum';
import { CommissionScaleService } from 'src/app/core/services/commission/commission-scale.service';

export const invalidSelectValidator = (options: CdsOption[]) => {
  return (control: AbstractControl): ValidationErrors | null => {
    const input = control.value;
    const selectedOption = options.find(x => x.value === input);
    if (!selectedOption && input) return { invalidSelect: true };
    return null;
  };
};

export const invalidCalcTypeValidator = () => {
  return (control: AbstractControl): ValidationErrors | null => {
    const scaleTypeControl = control.get('scaleType');
    const calcTypeControl = control.get('calcType');
    const scaleType = scaleTypeControl?.value;
    const calcType = calcTypeControl?.value;
    const isFYOrPC = scaleType === ScaleType.FY || scaleType === ScaleType.FS_PC || scaleType === ScaleType.PS_PC;
    const isCalcTypeTouched = calcTypeControl?.touched || calcTypeControl?.dirty;
    const isScaleTypeTouched = scaleTypeControl?.touched || scaleTypeControl?.dirty;

    if (isCalcTypeTouched && isScaleTypeTouched) {
      if ((!calcType && isFYOrPC) || (calcType && !isFYOrPC)) {
        return { invalidCalc: true };
      }
    }
    return null;
  };
};

export const invalidCrfRateValidator = () => {
  return (control: AbstractControl): ValidationErrors | null => {
    const scaleTypeControl = control.get('scaleType');
    const crfRateControl = control.get('crfRate');
    const scaleType = scaleTypeControl?.value;
    const crfRate = crfRateControl?.value;
    const isPCOrSP =
      scaleType === ScaleType.FS_PC ||
      scaleType === ScaleType.PS_PC ||
      scaleType === ScaleType.RY_PC ||
      scaleType === ScaleType.TRF_SP_PC ||
      scaleType === ScaleType.TRF_SP;
    const isCrfRateTouched = crfRateControl?.touched || crfRateControl?.dirty;
    const isScaleTypeTouched = scaleTypeControl?.touched || scaleTypeControl?.dirty;

    if (isCrfRateTouched && isScaleTypeTouched) {
      if ((Number.isNaN(crfRate) || crfRate > 0) && isPCOrSP) {
        return { invalidCrfRate: true };
      }
    }
    return null;
  };
};

export const existCodeAsyncValidator = (commissionScaleService: CommissionScaleService, oldCode?: string) => {
  return (formControl: AbstractControl): Promise<ValidationErrors | null> => {
    return new Promise(resolve => {
      const input = formControl.value.replace(/\s+/g, '');
      if (!input || input === oldCode) {
        resolve(null);
      } else {
        const param = { page: 0, size: 1, scaleCodeSpecify: input };
        commissionScaleService.selectList(param).subscribe(response => {
          if (response && response.data && response.data.content) {
            resolve({ existCode: true });
          } else {
            resolve(null);
          }
        });
      }
    });
  };
};

export const requiredScaleCode = () => {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value?.replace(/\s/g, '');
    if (value) {
      return null;
    }
    return { required: true };
  };
};

export const invalidCodeAsyncValidator = (commissionScaleService: CommissionScaleService) => {
  return (formControl: AbstractControl): Promise<ValidationErrors | null> => {
    return new Promise(resolve => {
      const input = formControl.value;
      if (!input) {
        resolve(null);
      } else {
        const param = { page: 0, size: 1, scaleCodeSpecify: input };
        commissionScaleService.selectList(param).subscribe(response => {
          if (response && response.data && response.data.empty) {
            resolve({ invalidScaleCode: true });
          } else {
            resolve(null);
          }
        });
      }
    });
  };
};
