import { deepCopy } from 'src/app/utils/copy';
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CdsButtonConfig } from '@cds/ng-core/button';
import { CdsDropdownConfig } from '@cds/ng-core/dropdown';
import { DateTime } from 'luxon';
import moment from 'moment';
import { finalize } from 'rxjs';
import { ColumnItem, Sort } from 'src/app/components/table/table-expand';
import { PageConfig } from 'src/app/shared/data-table';
import { IPaginationCount } from 'src/app/shared/pagination/pagination.dto';
import { ToastAlertService } from 'src/app/shared/toast-alert.service';
import { dateValidator, descriptionValidator, numberStringValidator, startAndEndDateValidator } from 'src/app/shared/validators/validators';
import { FileType, jsonToSheet } from 'src/app/utils/xlsx';
import { SpecialQuote, SpecialQuoteRequest, SpecialQuoteStatusOptions, SpecialQuoteTypeOptions } from '../employer';
import { EmployerService } from '../employer.service';
import { PermissionAccess, PermissionItem } from 'src/app/core/models/enum/permission.enum';

@Component({
  selector: 'app-sq-log-master-list',
  templateUrl: './sq-log-master-list.component.html',
  styleUrls: ['./sq-log-master-list.component.scss'],
})
export class SqLogMasterListComponent implements OnInit {
  form: FormGroup = new FormGroup({});

  formReady = false;

  nowTimeInstr = DateTime.now().toFormat('dd/MM/yyyy');

  maxTime = this.getNowTime();

  maxTimeInStr = DateTime.fromJSDate(this.maxTime).toFormat('dd/MM/yyyy');

  showDatePicker = true;

  isLoading = false;

  pageCount: IPaginationCount = { current: 1, count: 1 };

  searchButtonConfig: CdsButtonConfig = {
    size: 'sm',
  };

  resetButtonConfig: CdsButtonConfig = {
    size: 'sm',
    style: 'secondary',
  };

  exportButtonConfig: CdsButtonConfig = {
    size: 'sm',
    style: 'secondary',
  };

  numberOfElements = 0;

  dataSource: SpecialQuote[] = [];

  Sort = Sort;

  get employerAccountCodeControl() {
    return this.form?.get('employerAccountCode');
  }

  get employerNameControl() {
    return this.form?.get('employerName');
  }

  get sqReferenceNoControl() {
    return this.form?.get('sqReferenceNo');
  }

  get sqSubmissionDateFromControl() {
    return this.form?.get('sqSubmissionDateFrom');
  }

  get sqSubmissionDateToControl() {
    return this.form?.get('sqSubmissionDateTo');
  }

  get sqSubmissionDateFromValue() {
    return this.sqSubmissionDateFromControl?.value;
  }

  get sqSubmissionDateToValue() {
    return this.sqSubmissionDateToControl?.value;
  }

  get searchButtonDisabled() {
    return !this.form?.valid || !this.form.dirty;
  }

  PermissionAccess = PermissionAccess;

  PermissionItem = PermissionItem;

  resetButtonDisabled = true;

  statusConfig: CdsDropdownConfig = {
    label: 'Status',
    placeholder: 'Select Status',
    options: SpecialQuoteStatusOptions,
  };

  quoteTypeConfig: CdsDropdownConfig = {
    label: 'Quote Type',
    placeholder: 'Select Quote Type',
    options: SpecialQuoteTypeOptions,
  };

  pageConfig: PageConfig = {
    current: 1,
    pageSize: 10,
    totalCount: 0,
  };

  // sortDto: SpecialQuote = {
  //   sqReferenceNo: Sort.DOWN,
  //   employerAccountCode: '',
  //   employerName: '',
  //   sqSubmissionDate: ''
  // };

  sortDto: ColumnItem = {
    // sqReferenceNo: Sort.DOWN,
    // employerAccountCode: '',
    // employerName: '',
    // sqSubmissionDate: '',
    label: '',
    prop: 'sqReferenceNo',
    sort: Sort.DEFAULT,
  };

  sheetHeader = {
    sqReferenceNo: 'SQ Reference No.',
    employerAccountCode: 'Employer Account Code',
    employerName: 'Company Name (English)',
    employerChineseName: 'Company Name (Chinese)',
    sqSubmissionDate: 'SQ Submission Date',
    lastStatusUpdatedDate: 'Last Status Update Date',
    quoteStatus: 'Status',
    quoteType: 'Quote Type',
    remarks: 'Remarks',
  };

  employerNameValidTip = 'Please input English, Chinese, numbers, or special characters';

  sqReferenceNoValidTip = 'Please input English, numbers, or special characters';

  tempParams: any = {};

  constructor(public service: EmployerService, private toastAlert: ToastAlertService, private router: Router) {}

  ngOnInit(): void {
    this.initForm();
    this.loadTable();
  }

  initForm() {
    this.form?.markAsUntouched();
    this.form?.markAsPristine();

    this.form = new FormGroup(
      {
        employerAccountCode: new FormControl('', [Validators.maxLength(15), numberStringValidator()]),
        employerName: new FormControl('', [Validators.maxLength(200), descriptionValidator(this.employerNameValidTip, true)]),
        sqReferenceNo: new FormControl('', [Validators.maxLength(50), descriptionValidator(this.sqReferenceNoValidTip)]),
        quoteStatus: new FormControl(''),
        quoteType: new FormControl(''),
        sqSubmissionDateFrom: new FormControl('', [dateValidator('create.dateError', '', this.nowTimeInstr)]),
        sqSubmissionDateTo: new FormControl('', [dateValidator('create.dateError', '', this.nowTimeInstr)]),
      },
      { validators: startAndEndDateValidator('create.dateError', 'sqSubmissionDateFrom', 'sqSubmissionDateTo') }
    );
    this.formReady = false;
    setTimeout(() => {
      this.formReady = true;
    });
  }

  loadTable() {
    const params: SpecialQuoteRequest = this.getRequestParams();
    this.isLoading = true;
    this.service
      .getSqLogMasterList(params)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe(data => {
        if (data.result === 0 && data.data) {
          this.tempParams = deepCopy(params);
          this.dataSource = data.data.empty ? [] : data.data.content;
          this.numberOfElements = data.data.totalElements;
          this.pageCount.current = data.data.number + 1;
          this.pageCount.count = data.data.totalPages;
          this.pageCount = { ...this.pageCount };
        } else {
          this.toastError(data.message);
        }
      });
  }

  toastError(errorMessage: string) {
    this.toastAlert.show('error', 'common.error', errorMessage, 5000);
  }

  getRequestParams(pageSize = 10) {
    const params: SpecialQuoteRequest = {
      ...this.form.value,
      page: (this.pageCount.current as number) - 1,
      size: pageSize,
    };
    params.sort = `${this.sortDto.prop},${this.sortDto.sort}`;
    if (this.sortDto.sort === Sort.DEFAULT) {
      delete params.sort;
    }
    params.sqSubmissionDateFrom = params.sqSubmissionDateFrom ? this.dateTrans(params.sqSubmissionDateFrom) : '';
    params.sqSubmissionDateTo = params.sqSubmissionDateTo ? this.dateTrans(params.sqSubmissionDateTo) : '';
    return params;
  }

  dateTrans(date: string) {
    if (date) {
      return moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD');
    }

    return date;
  }

  getNowTime() {
    return moment().toDate();
  }

  getMaxFromDate = (toDateStr?: string) => {
    if (!toDateStr) {
      return this.getNowTime();
    }
    return moment(toDateStr, 'DD/MM/YYYY').toDate();
  };

  getMinToDate = (fromDateStr = '01/01/0000') => {
    return moment(fromDateStr, 'DD/MM/YYYY').toDate();
  };

  search() {
    if (this.searchButtonDisabled) {
      return;
    }
    this.pageCount = { current: 1, count: 1 };
    this.loadTable();

    this.resetButtonDisabled = false;
  }

  reset() {
    if (this.resetButtonDisabled) {
      return;
    }
    this.resetButtonDisabled = true;
    this.pageCount = { current: 1, count: 1 };

    this.initForm();

    this.showDatePicker = false;
    setTimeout(() => {
      this.showDatePicker = true;
      this.loadTable();
    });
  }

  sortChange(columnItem: ColumnItem) {
    this.sortDto = columnItem;
    if (this.sortDto.prop == 'employerAccountCode') {
      this.sortDto.prop = 'erAcctCd';
    }
    if (this.sortDto.prop == 'employerName') {
      this.sortDto.prop = 'companyNm';
    }
    this.loadTable();
  }

  goToDetail(id: string | undefined) {
    this.router.navigate(['/employer/profile-detail'], {
      queryParams: { id: id },
    });
  }

  pageChange(currentPage: number) {
    this.pageCount.current = currentPage;
    this.loadTable();
  }

  export() {
    const params = deepCopy(this.tempParams);
    params.size = 100000;
    delete params.page;
    this.isLoading = true;
    this.service
      .sqLogMasterExport(params)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe(data => {
        if (data.result === 0 && data.data) {
          const exportData = data.data.empty ? [] : data.data.content;
          jsonToSheet(
            {
              fileName: `SQLogMasterList_${moment().format('DDMMYYYY')}`,
              fileType: FileType.XLSX,
              header: this.sheetHeader,
            },
            this.createData(exportData)
          );
        } else {
          this.toastError(data.message);
        }
      });
  }

  createData(data: SpecialQuote[]) {
    return data.map(item => {
      return {
        sqReferenceNo: item.sqReferenceNo,
        employerAccountCode: item.employerAccountCode,
        employerName: item.employerName,
        employerChineseName: item.employerChineseName,
        sqSubmissionDate: this.formatDate(item.sqSubmissionDate),
        lastStatusUpdatedDate: this.formatDate(item.lastStatusUpdatedDate),
        quoteStatus: this.service.optionLabelRender(item.quoteStatus, SpecialQuoteStatusOptions),
        quoteType: this.service.optionLabelRender(item.quoteType, SpecialQuoteTypeOptions),
        remarks: item.remarks,
      };
    });
  }

  formatDate(date?: string) {
    if (!date) {
      return '';
    }
    return DateTime.fromISO(date).toFormat('dd/MM/yyyy');
  }
}
