import { Component, OnInit } from '@angular/core';

import { CdsButtonConfig } from '@cds/ng-core/button';
import { MatDialogRef } from '@cds/ng-web-components/popup';

@Component({
  selector: 'app-advise-transfer-status',
  templateUrl: './advise-transfer-status.component.html',
  styleUrls: ['./advise-transfer-status.component.scss'],
})
export class AdviseTransferStatusComponent implements OnInit {
  constructor(private dialogRef: MatDialogRef<AdviseTransferStatusComponent>) {}

  clearButton: CdsButtonConfig = {
    size: 'sm',
    style: 'primary',
    disabled: false,
  };

  ngOnInit(): void {}

  closeCurrentPopup() {
    this.dialogRef.close({ agree: false });
  }
}
