import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { CdsButtonConfig } from '@cds/ng-core/button';

@Component({
  selector: 'app-narrow-search-popup',
  templateUrl: './narrow-search-popup.component.html',
})
export class NarrowSearchPopupComponent implements OnInit {
  gotItButtonConfig: CdsButtonConfig = {
    size: 'md',
    style: 'primary',
    disabled: false,
  };

  constructor(private dialogRef: MatDialogRef<NarrowSearchPopupComponent>) {}

  ngOnInit(): void {}

  returnToEdit() {
    this.dialogRef.close({ agree: true });
  }
}
