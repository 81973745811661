import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { CdsIconConfig } from '@cds/ng-core/icon';
import { CdsLangService } from '@cds/ng-core/lang';
import { ActionIcons } from '@cds/ng-themes';
import { CdsAlertService } from '@cds/ng-web-components/alert';
import { CdsPopupService } from '@cds/ng-web-components/popup';
import moment from 'moment';
import { Annuncement } from 'src/app/core/models/annuncement/annuncement.model';
import { NotifyService } from 'src/app/core/services/notify.service';
import { ScreenSizeService } from 'src/app/core/services/screen-size/screen-size.service';
import { addMinutesToTime, compareTime, datetimeRangeInvalidValidator, dateValidator, isSameOrAfterToday } from 'src/app/core/validators';
import { AlertPopupComponent } from 'src/app/shared/alert-popup/alert-popup.component';
import { CdHttpServeService } from 'src/app/shared/cd-http-serve/cd-http-serve.service';
import { ConfirmPopupComponent } from 'src/app/shared/confirm-popup/confirm-popup.component';
import { WillBeLostComponent } from 'src/app/shared/will-be-lost/will-be-lost.component';
import { pageToTop } from 'src/app/utils/utils';
import { I18N_KEY } from './announcement-details.component.config';

@Component({
  selector: 'app-announcement-details',
  templateUrl: './announcement-details.component.html',
  styleUrls: ['./announcement-details.component.scss'],
})
export class AnnouncementDetailsComponent implements OnInit, AfterViewInit, OnDestroy {
  currentAnnouncementId: number | null = null;
  backErIconConfig: CdsIconConfig = {
    color: 'cta',
    size: 'sm',
  };
  backErIcon = ActionIcons.button_left_filled;
  effectiveDateFromMax = new Date('3000/01/01');
  effectiveTimeFromMax: number[] | null = null;
  effectiveDateFromMin = new Date();
  effectiveDateToMax = new Date('3000/01/01');
  effectiveDateToMin = new Date();
  effectiveTimeToMin: number[] | null = null;
  refreshTimePicker = false;

  defaultFormGroupValues: {
    effectiveDateFrom: string;
    effectiveTimeFrom: string;
    effectiveDateTo: string;
    effectiveTimeTo: string;
    announcementSubjectEng: string;
    announcementSubjectChi: string;
    announcementBodyEng: string;
    announcementBodyChi: string;
  } | null = null;
  formGroup!: FormGroup;

  get effectiveDateFrom() {
    return this.formGroup.get('effectiveDateFrom') as FormControl;
  }

  get effectiveTimeFrom() {
    return this.formGroup.get('effectiveTimeFrom') as FormControl;
  }

  get effectiveDateTo() {
    return this.formGroup.get('effectiveDateTo') as FormControl;
  }

  get effectiveTimeTo() {
    return this.formGroup.get('effectiveTimeTo') as FormControl;
  }

  get announcementSubjectEng() {
    return this.formGroup.get('announcementSubjectEng') as FormControl;
  }

  get announcementSubjectChi() {
    return this.formGroup.get('announcementSubjectChi') as FormControl;
  }

  get announcementBodyEng() {
    return this.formGroup.get('announcementBodyEng') as FormControl;
  }

  get announcementBodyChi() {
    return this.formGroup.get('announcementBodyChi') as FormControl;
  }

  get isChangedFormGroupValues() {
    if (this.defaultFormGroupValues) {
      if (
        !(
          this.defaultFormGroupValues.effectiveDateFrom === this.effectiveDateFrom.value &&
          this.defaultFormGroupValues.effectiveTimeFrom === this.effectiveTimeFrom.value &&
          this.defaultFormGroupValues.effectiveDateTo === this.effectiveDateTo.value &&
          this.defaultFormGroupValues.effectiveTimeTo === this.effectiveTimeTo.value &&
          this.defaultFormGroupValues.announcementSubjectEng === this.announcementSubjectEng.value &&
          this.defaultFormGroupValues.announcementSubjectChi === this.announcementSubjectChi.value &&
          this.defaultFormGroupValues.announcementBodyEng === this.announcementBodyEng.value &&
          this.defaultFormGroupValues.announcementBodyChi === this.announcementBodyChi.value
        )
      ) {
        return true;
      }
    }
    return false;
  }

  constructor(
    private cdHttpServeService: CdHttpServeService,
    private alert: CdsAlertService,
    private router: Router,
    private route: ActivatedRoute,
    public cdf: ChangeDetectorRef,
    private cdsPopup: CdsPopupService,
    public notifyService: NotifyService,
    private langService: CdsLangService,
    public screenSizeService: ScreenSizeService
  ) {}

  private beforeunload = (event: BeforeUnloadEvent) => {
    if (this.isChangedFormGroupValues) {
      event.preventDefault();
      event.returnValue = '';
    }
  };

  private popstate = () => {
    const popupRef: MatDialogRef<WillBeLostComponent> = this.cdsPopup.open(WillBeLostComponent, {
      size: 'sm',
    });
    popupRef.afterClosed().subscribe(({ agree }) => {
      if (agree) {
        this.router.navigate(['annuncement/announcement-center']);
      } else {
        history.pushState(null, '', document.URL);
      }
    });
  };

  ngOnDestroy(): void {
    window.removeEventListener('beforeunload', this.beforeunload);
    window.removeEventListener('popstate', this.popstate);
  }

  ngAfterViewInit(): void {
    pageToTop();
  }

  ngOnInit(): void {
    window.addEventListener('beforeunload', this.beforeunload);

    history.pushState(null, '', document.URL);
    window.addEventListener('popstate', this.popstate);

    this.currentAnnouncementId = Number(this.route.snapshot.paramMap.get('announcementId')) || null;
    if (this.currentAnnouncementId != null) {
      this.getOne(this.currentAnnouncementId);
    }

    this.defaultFormGroupValues = {
      effectiveDateFrom: '',
      effectiveTimeFrom: '00:00',
      effectiveDateTo: '',
      effectiveTimeTo: '23:59',
      announcementSubjectEng: '',
      announcementSubjectChi: '',
      announcementBodyEng: '',
      announcementBodyChi: '',
    };

    this.formGroup = new FormGroup(
      {
        effectiveDateFrom: new FormControl(this.defaultFormGroupValues.effectiveDateFrom, {
          validators: [
            Validators.required,
            dateValidator({ error: I18N_KEY.EFFECTIVE_DATE_TO_INVALID }),
            isSameOrAfterToday({ error: I18N_KEY.EFFECTIVE_DATE_TO_INVALID }),
          ],
        }),
        effectiveTimeFrom: new FormControl(this.defaultFormGroupValues.effectiveTimeFrom, {
          validators: [Validators.required],
        }),
        effectiveDateTo: new FormControl(this.defaultFormGroupValues.effectiveDateTo, {
          validators: [
            Validators.required,
            dateValidator({ error: I18N_KEY.EFFECTIVE_DATE_TO_INVALID }),
            isSameOrAfterToday({ error: I18N_KEY.EFFECTIVE_DATE_TO_INVALID }),
          ],
        }),
        effectiveTimeTo: new FormControl(this.defaultFormGroupValues.effectiveTimeTo, {
          validators: [Validators.required],
        }),
        announcementSubjectEng: new FormControl(this.defaultFormGroupValues.announcementSubjectEng, {
          validators: [Validators.required, Validators.maxLength(30)],
        }),
        announcementSubjectChi: new FormControl(this.defaultFormGroupValues.announcementSubjectChi, {
          validators: [Validators.required, Validators.maxLength(30)],
        }),
        announcementBodyEng: new FormControl(this.defaultFormGroupValues.announcementBodyEng, {
          validators: [Validators.required, Validators.maxLength(500)],
        }),
        announcementBodyChi: new FormControl(this.defaultFormGroupValues.announcementBodyChi, {
          validators: [Validators.required, Validators.maxLength(500)],
        }),
      },
      [
        datetimeRangeInvalidValidator('effectiveDateFrom', 'effectiveDateTo', 'effectiveTimeFrom', 'effectiveTimeTo', {
          dateError: 'Please input a valid date',
          timeError: 'Please input a valid time',
        }),
      ]
    );

    this.effectiveDateFrom.valueChanges.subscribe(val => {
      const dateObject = moment(val, 'DD/MM/YYYY');
      if (dateObject.isValid()) {
        this.effectiveDateToMin = dateObject.toDate();
      }

      if (val === this.effectiveDateTo.value) {
        this.effectiveTimeToMin = this.effectiveTimeFrom.value.split(':').map(Number);
        this.effectiveTimeFromMax = this.effectiveTimeTo.value.split(':').map(Number);

        if (compareTime(this.effectiveTimeFrom.value, this.effectiveTimeTo.value) !== -1) {
          this.effectiveTimeTo.setValue(addMinutesToTime(this.effectiveTimeFrom.value, 1));
        }
      } else {
        this.effectiveTimeToMin = null;
        this.effectiveTimeFromMax = null;
      }
    });

    this.effectiveDateTo.valueChanges.subscribe(val => {
      const dateObject = moment(val, 'DD/MM/YYYY');
      if (dateObject.isValid()) {
        this.effectiveDateFromMax = dateObject.toDate();
      }

      if (val === this.effectiveDateFrom.value) {
        this.effectiveTimeToMin = this.effectiveTimeFrom.value.split(':').map(Number);
        this.effectiveTimeFromMax = this.effectiveTimeTo.value.split(':').map(Number);

        if (compareTime(this.effectiveTimeFrom.value, this.effectiveTimeTo.value) !== -1) {
          this.effectiveTimeTo.setValue(addMinutesToTime(this.effectiveTimeFrom.value, 1));
        }
      } else {
        this.effectiveTimeToMin = null;
        this.effectiveTimeFromMax = null;
      }
    });

    this.effectiveTimeFrom.valueChanges.subscribe(val => {
      if (this.effectiveDateFrom.value === this.effectiveDateTo.value) {
        this.effectiveTimeToMin = val.split(':').map(Number);
      } else {
        this.effectiveTimeToMin = null;
        this.effectiveTimeFromMax = null;
      }
    });

    this.effectiveTimeTo.valueChanges.subscribe(val => {
      if (this.effectiveDateFrom.value === this.effectiveDateTo.value) {
        this.effectiveTimeFromMax = val.split(':').map(Number);
      } else {
        this.effectiveTimeToMin = null;
        this.effectiveTimeFromMax = null;
      }
    });

    this.announcementSubjectChi.valueChanges.subscribe(val => {
      const regex = /\b[a-zA-Z]+\b/g;
      const newVal = val.replace(regex, '');
      if (val !== newVal) {
        this.announcementSubjectChi.setValue(newVal);
      }
    });

    this.announcementBodyChi.valueChanges.subscribe(val => {
      const regex = /\b[a-zA-Z]+\b/g;
      const newVal = val.replace(regex, '');
      if (val !== newVal) {
        this.announcementBodyChi.setValue(newVal);
      }
    });

    this.announcementSubjectEng.valueChanges.subscribe(val => {
      const regex = /[\u4e00-\u9fa5]/g;
      const newVal = val.replace(regex, '');
      if (val !== newVal) {
        this.announcementSubjectEng.setValue(newVal);
      }
    });

    this.announcementBodyEng.valueChanges.subscribe(val => {
      const regex = /[\u4e00-\u9fa5]/g;
      const newVal = val.replace(regex, '');
      if (val !== newVal) {
        this.announcementBodyEng.setValue(newVal);
      }
    });
  }

  getOne(currentAnnouncementId: number) {
    this.notifyService.screenLoadingSubject.next(true);
    this.cdHttpServeService.get<Annuncement>(`/ext/eb-ssms-sales-journey-service/announcement/cms/getOne/${currentAnnouncementId}`, null).subscribe({
      next: res => {
        if (res.result !== 0) {
          this.alert.warning('Warning!', `${res.message}`);
        } else {
          const [effectiveFromDateStr, effectiveFromTimeStr] = moment(res.data.effectiveFrom).format('DD/MM/YYYY HH:mm').split(' ');
          const [effectiveToDateStr, effectiveToTimeStr] = moment(res.data.effectiveTo).format('DD/MM/YYYY HH:mm').split(' ');

          this.defaultFormGroupValues = {
            effectiveDateFrom: effectiveFromDateStr,
            effectiveTimeFrom: effectiveFromTimeStr,
            effectiveDateTo: effectiveToDateStr,
            effectiveTimeTo: effectiveToTimeStr,
            announcementSubjectEng: res.data.announcementSubjectEng,
            announcementSubjectChi: res.data.announcementSubjectChi,
            announcementBodyEng: res.data.announcementBodyEng,
            announcementBodyChi: res.data.announcementBodyChi,
          };
          this.formGroup.reset(this.defaultFormGroupValues);
        }
        this.notifyService.screenLoadingSubject.next(false);
      },
      error: err => {
        this.alert.error('Error!', err);
        this.notifyService.screenLoadingSubject.next(false);
      },
    });
  }

  onBack() {
    if (!this.isChangedFormGroupValues) {
      this.router.navigate(['annuncement/announcement-center']);
    } else {
      const popupRef: MatDialogRef<WillBeLostComponent> = this.cdsPopup.open(WillBeLostComponent, {
        size: 'sm',
      });
      popupRef.afterClosed().subscribe(({ agree }) => {
        if (agree) {
          this.router.navigate(['annuncement/announcement-center']);
        }
      });
    }
  }

  reset() {
    if (!this.isChangedFormGroupValues) {
      return;
    }
    const popupRef: MatDialogRef<WillBeLostComponent> = this.cdsPopup.open(WillBeLostComponent, {
      size: 'sm',
    });
    popupRef.afterClosed().subscribe(({ agree }) => {
      if (agree) {
        this.formGroup.reset(this.defaultFormGroupValues);
        this.effectiveDateFromMax = new Date('3000/01/01');
        this.effectiveTimeFromMax = null;
        this.effectiveDateFromMin = new Date();
        this.effectiveDateToMax = new Date('3000/01/01');
        this.effectiveDateToMin = new Date();
        this.effectiveTimeToMin = null;

        this.refreshTimePicker = true;
        setTimeout(() => {
          this.refreshTimePicker = false;
        }, 0);
      }
    });
  }

  submit() {
    if (!this.formGroup.valid || !this.isChangedFormGroupValues) {
      return;
    }

    this.effectiveDateFrom.markAsDirty();
    this.effectiveTimeFrom.markAsDirty();
    this.effectiveDateTo.markAsDirty();
    this.effectiveTimeTo.markAsDirty();
    this.announcementSubjectEng.markAsDirty();
    this.announcementSubjectChi.markAsDirty();
    this.announcementBodyEng.markAsDirty();
    this.announcementBodyChi.markAsDirty();

    const body = {
      announcementId: this.currentAnnouncementId,
      announcementSubjectEng: this.formGroup.value.announcementSubjectEng,
      announcementBodyEng: this.formGroup.value.announcementBodyEng,
      announcementSubjectChi: this.formGroup.value.announcementSubjectChi,
      announcementBodyChi: this.formGroup.value.announcementBodyChi,
      effectiveFrom: moment(this.formGroup.value.effectiveDateFrom + this.formGroup.value.effectiveTimeFrom, 'DD/MM/YYYYHH:mm:ss')
        .utcOffset(8)
        .format(),
      effectiveTo: moment(this.formGroup.value.effectiveDateTo + this.formGroup.value.effectiveTimeTo, 'DD/MM/YYYYHH:mm:ss')
        .utcOffset(8)
        .format(),
    };

    this.notifyService.screenLoadingSubject.next(true);
    this.cdHttpServeService.post('/ext/eb-ssms-sales-journey-service/announcement/cms/addOrEdit', body).subscribe({
      next: res => {
        if (res.result !== 0) {
          this.alert.warning('Warning!', `${res.message}`);
        } else {
          this.cdsPopup
            .open(AlertPopupComponent, {
              size: 'sm',
              data: {
                message:
                  this.currentAnnouncementId != null
                    ? this.langService.translate('common.action.message.update.success.italic')
                    : this.langService.translate('common.action.messageCreateSuccess'),
                buttonName: this.langService.translate('common.gotItWithExclamation'),
              },
            })
            .afterClosed()
            .subscribe(() => {
              this.router.navigate(['annuncement/announcement-center']);
            });
        }
        this.notifyService.screenLoadingSubject.next(false);
      },
      error: err => {
        this.alert.error('Error!', err);
        this.notifyService.screenLoadingSubject.next(false);
      },
    });
  }

  recallAnnouncement(announcementId: number) {
    this.cdsPopup
      .open(ConfirmPopupComponent, {
        size: 'sm',
        data: {
          message: 'annuncement.center.recallAnnouncementConfirm',
        },
      })
      .afterClosed()
      .subscribe(({ agree }) => {
        if (agree) {
          this.deleteAnnuncement(announcementId);
        }
      });
  }

  deleteAnnuncement(announcementId: number) {
    this.notifyService.screenLoadingSubject.next(true);
    this.cdHttpServeService
      .post('/ext/eb-ssms-sales-journey-service/announcement/cms/delete', {
        announcementId: announcementId,
        deleted: 1,
      })
      .subscribe({
        next: res => {
          if (res.result !== 0) {
            this.alert.warning('Warning!', `${res.message}`);
          } else {
            this.router.navigate(['annuncement/announcement-center']);
          }
          this.notifyService.screenLoadingSubject.next(false);
        },
        error: err => {
          this.alert.error('Error!', err);
          this.notifyService.screenLoadingSubject.next(false);
        },
      });
  }
}
