<div class="widgets-campaign-result">
  <div class="l-ml-5 l-d-f l-ai-cen" style="margin-top: 2rem; margin-bottom: 2.75rem">
    <cds-icon style="width: 120px; height: 120px" icon="presentation:trophy"></cds-icon>
    <div class="l-ml-6">
      <div style="text-align: end">
        <span *ngIf="campaign?.totalPc === null || campaign?.totalPc === undefined" class="cds-h2-demibold" style="color: var(--cds-color-dark-1-green)"
          >N/A</span
        >
        <span *ngIf="!(campaign?.totalPc === null || campaign?.totalPc === undefined)" class="cds-h2-demibold" style="color: var(--cds-color-dark-1-green)">{{
          campaign?.totalPc | number
        }}</span>
      </div>
      <div class="l-d-f l-jc-fe">
        <span class="cds-body1 l-mb-0">PC earned</span>
      </div>
      <div style="text-align: end">
        <span *ngIf="campaign?.resultAsOfDate" class="cds-detail1">As of {{ campaign?.resultAsOfDate | date : 'dd MMM yyyy' }}</span>
        <span *ngIf="!campaign?.resultAsOfDate" class="cds-detail1">As of N/A</span>
      </div>
      <div style="text-align: end">
        <span class="cds-detail1">{{ campaign?.membershipCurrent || 'N/A' }}</span>
      </div>
    </div>
  </div>
  <div class="line"></div>
  <div class="l-d-f l-ml-5">
    <cds-icon class="cursor-pointer" style="--cds-icon-color: var(--cds-icon-cta)" icon="action:button_right_filled"></cds-icon>
    <div class="l-ml-2 cursor-pointer">
      <span class="cds-body2-demibold">To minisite</span>
    </div>
  </div>
</div>
