import { Component, OnInit } from '@angular/core';
import { CdsButtonConfig } from '@cds/ng-core/button';
import { CdsLangService } from '@cds/ng-core/lang';

import { MatDialogRef } from '@cds/ng-web-components/popup';

@Component({
  selector: 'app-user-confirm-pop',
  templateUrl: './user-confirm-pop.component.html',
  styleUrls: ['./user-confirm-pop.component.scss'],
})
export class UserConfirmPopComponent implements OnInit {
  agreeButtonConfig: CdsButtonConfig = {
    size: 'sm',
    style: 'secondary',
    disabled: false,
  };
  disagreeButtonConfig: CdsButtonConfig = {
    size: 'sm',
    style: 'primary',
    disabled: false,
  };
  constructor(private dialogRef: MatDialogRef<UserConfirmPopComponent>, public cdsLangService: CdsLangService) {}

  ngOnInit(): void {}
  agree() {
    this.dialogRef.close({ agree: true });
  }
  disagree() {
    this.dialogRef.close({ agree: false });
  }
}
