import {
  DefaultContribution,
  DefaultContributionRequest,
  EmpfWorkflow,
  EmpfWorkflowRequest,
  EmploymentInfo,
  MemberAccountTypeE,
  MemberAccountTypeMap,
  PolicyTermination,
  PolicyTransfer,
  PolicyTransferDetails,
  PolicyTransferRequest,
  PolicyWithdrawal,
  PolicyWithdrawalDetails,
  PolicyWithdrawalRequest,
  PolicyDetails,
  RegistrationTypeMap,
  TransactionRecordResponse,
  TransactionRecordRequest,
  InvestedFundInformationResponse,
  FundBalanceResponse,
  PaymentTransactionResponse,
} from './employee';
/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { BasicResponse, PageResponse } from 'src/app/core/models/response/response';
import { EEProfileDetailResponse } from './ee-modules/EEProfileDetail.module';
import { EEProfileContactResponse } from './ee-modules/EEProfileContact.module';
import { EEProfileFundResponse } from './ee-modules/EEProfileFund.module';
import { EEProfileOthersResponse } from './ee-modules/EEProfileOthers.module';
import { AgentInfoAndCommissionInfoResponse } from './ee-modules/AgentAndCommissionInfo.module';
import { filterParams } from 'src/app/core/models/request';
import { CampaignInformationRequest, ContactInfo, ContryCode, EeSearchRequest, Employee, RelatedPolicy } from './employee';
import { AgentInfo, ContributionDetails } from '../employer/employer';
import moment from 'moment';
import { paramsObj2paramsUrlStr } from 'src/app/utils/utils';
import { Campaign, CampaignSearchRequest, CustomerListFileCheckRes } from '../campaign/campaign';
import { CampaignInformation } from './ee-modules/CampaignInformation.module';

@Injectable({
  providedIn: 'root',
})
export class EmployeeService {
  private base_url = `${environment.apiPrefix}/ext/eb-ssms/customer-service/EE-Profile/`;
  //private base_url = `http://localhost:8093/api/v1/int/employee/`;

  private detail_url = this.base_url + 'details';
  private contact_url = this.base_url + 'contact';
  private agent_url = this.base_url + 'agentInfo';
  private fund_url = this.base_url + 'fund';
  private others_url = this.base_url + 'others';
  private customerServicePath = `${environment.apiPrefix}/ext/eb-ssms/customer-service/`;
  private employeePath = this.customerServicePath + 'employee/';
  //private employeePath =  `http://localhost:8093/api/v1/int/employee/`;
  private getListUrl = this.employeePath + 'getList';
  //private getListUrl = 'http://localhost:8093/api/v1/int/employee/' +'getList';
  private getDetailsUrl = this.employeePath + 'getDetails';
  //private getDetailsUrl = 'http://localhost:8093/api/v1/int/employee/' +'getDetails';
  private getCountryCodesUrl = this.employeePath + 'phoneExts';
  private overviewBasicInfoUrl = this.employeePath + 'overview/basicInformation';
  private contactInfoUrl = this.employeePath + 'policy/contactInformation';
  private agentInfoUrl = this.employeePath + 'policy/agentInfo';
  private policyBasicInfoUrl = this.employeePath + 'policy/basicInformation';
  private campaignUrl = this.customerServicePath + 'campaign';
  private getCampaignListUrl = this.campaignUrl + '/getList';
  private getrelatedPoliciesUrl = this.employeePath + 'overview/relatedPolicies';
  private employmentInfoUrl = this.employeePath + 'policy/employmentInfo';
  private getContributionRecordssUrl = this.employeePath + 'policy/contributionRecords';
  private getDefaultContributionUrl = this.employeePath + 'policy/contributionDefault';
  private getPolicyTransferListUrl = this.employeePath + 'policy/transferList';
  private getTransferDetailsUrl = this.employeePath + 'policy/transferDetails';
  private getPolicyWithdrawalListUrl = this.employeePath + 'policy/withdrawalList';
  private getWithdrawalDetailsUrl = this.employeePath + 'policy/withdrawalDetails';
  private getTerminationUrl = this.employeePath + 'policy/getTermination';
  // private uploadCustomerListUrl = this.campaignUrl + '/uploadExcelFile';
  private uploadCustomerListUrl = `${environment.apiPrefix}/ext/eb-ssms/reporting-service/api/v1/campaign` + '/uploadExcelFile';
  private getPolicyDetailsUrl = this.employeePath + 'policy/policyDetails';
  private getTransactionRecord = this.employeePath + 'policy/fundInfo/getTransactionRecord';
  private getInvestedFundInformation = this.employeePath + 'policy/fundInfo/getInvestedFundInformation';
  private getFundBalancePath = this.employeePath + 'policy/fundInfo/getFundBalance';
  private getPaymentTransactionPath = this.employeePath + 'policy/paymentTransaction';

  constructor(private http: HttpClient) {}

  getDetail(id: string): Observable<BasicResponse<EEProfileDetailResponse>> {
    return this.http.get<BasicResponse<any>>(this.detail_url, {
      params: { id: id },
    });
  }

  getOverviewBasicInfo(md5: string, id: string): Observable<BasicResponse<EEProfileDetailResponse>> {
    return this.http.get<BasicResponse<EEProfileDetailResponse>>(this.overviewBasicInfoUrl, {
      params: { md5, id },
    });
  }

  getContactInfo(id: string): Observable<BasicResponse<ContactInfo>> {
    return this.http.get<BasicResponse<ContactInfo>>(this.contactInfoUrl, {
      params: { id: id },
    });
  }

  gePolicyAgentInfo(id: string): Observable<BasicResponse<AgentInfo>> {
    return this.http.get<BasicResponse<AgentInfo>>(this.agentInfoUrl, {
      params: { id: id },
    });
  }

  getPolicyBasicInfo(id: string): Observable<BasicResponse<RelatedPolicy>> {
    return this.http.get<BasicResponse<RelatedPolicy>>(this.policyBasicInfoUrl, {
      params: { id: id },
    });
  }

  getEmploymentInfo(id: string): Observable<BasicResponse<EmploymentInfo>> {
    return this.http.get<BasicResponse<EmploymentInfo>>(this.employmentInfoUrl, {
      params: { id: id },
    });
  }

  getContact(trusteeId: string, entityType: string): Observable<BasicResponse<EEProfileContactResponse>> {
    return this.http.get<BasicResponse<any>>(this.contact_url, {
      params: { trusteeId: trusteeId, entityType: entityType },
    });
  }

  getAgentInfo(trusteeId: string, entityType: string): Observable<BasicResponse<AgentInfoAndCommissionInfoResponse>> {
    return this.http.get<BasicResponse<any>>(this.agent_url, {
      params: { trusteeId: trusteeId, entityType: entityType },
    });
  }

  getFundInfo(trusteeId: string, entityType: string): Observable<BasicResponse<EEProfileFundResponse>> {
    return this.http.get<BasicResponse<any>>(this.fund_url, {
      params: { trusteeId: trusteeId, entityType: entityType },
    });
  }

  getOthersInfo(trusteeId: string, entityType: string): Observable<BasicResponse<EEProfileOthersResponse>> {
    return this.http.get<BasicResponse<any>>(this.others_url, {
      params: { trusteeId: trusteeId, entityType: entityType },
    });
  }

  //this.getListUrl

  getPageList(params: EeSearchRequest): Observable<PageResponse<Employee>> {
    let paramsStr = paramsObj2paramsUrlStr(params);
    const sortList: string[] | undefined = params.sort?.split(',');
    if (sortList) {
      const sortKey = sortList[0];
      // const sortMode = sortList[1];
      if (sortKey === 'last_nm') {
        paramsStr += `&sort=empf_id,asc`;
      } else if (sortKey === 'empf_id') {
        paramsStr += `&sort=last_nm,asc`;
      } else {
        paramsStr += `&sort=last_nm,asc&sort=empf_id,asc`;
      }
    } else {
      paramsStr += `&sort=last_nm,asc&sort=empf_id,asc`;
    }
    return this.http.get<PageResponse<Employee>>(this.getListUrl + paramsStr);
  }

  getCampaingnList(params: CampaignInformationRequest): Observable<PageResponse<CampaignInformation>> {
    return this.http.get<PageResponse<CampaignInformation>>(this.getCampaignListUrl, {
      params: filterParams(params),
    });
  }

  getListExpansion(params: Employee): Observable<BasicResponse<Employee>> {
    return this.http.get<BasicResponse<Employee>>(this.getDetailsUrl, {
      params: filterParams(params),
    });
  }

  getCountryCodes(): Observable<BasicResponse<ContryCode[]>> {
    return this.http.get<BasicResponse<ContryCode[]>>(this.getCountryCodesUrl);
  }

  getRelatedPolicies(id: string, md5: string): Observable<BasicResponse<RelatedPolicy[]>> {
    return this.http.get<BasicResponse<RelatedPolicy[]>>(this.getrelatedPoliciesUrl, { params: { id, md5 } });
  }

  getTabPolicyDetails(id: string): Observable<BasicResponse<PolicyDetails>> {
    return this.http.get<BasicResponse<PolicyDetails>>(this.getPolicyDetailsUrl, { params: { id } });
  }

  getTransferDetails(transferReferenceNo: string, id: string): Observable<BasicResponse<PolicyTransferDetails>> {
    return this.http.get<BasicResponse<PolicyTransferDetails>>(this.getTransferDetailsUrl, { params: { transferReferenceNo, id } });
  }

  getWithdrawalDetails(withdrawalReferenceNo: string, id: string): Observable<BasicResponse<PolicyWithdrawalDetails>> {
    return this.http.get<BasicResponse<PolicyWithdrawalDetails>>(this.getWithdrawalDetailsUrl, { params: { withdrawalReferenceNo, id } });
  }

  getterminationDetails(terminationReferenceNo: string, id: string): Observable<BasicResponse<PolicyTermination>> {
    return this.http.get<BasicResponse<PolicyTermination>>(this.getTerminationUrl, { params: { terminationReferenceNo, id } });
  }

  getStatusColorStyle(status: string, mapObj: any) {
    if (!status) {
      return '';
    }
    return `background-color: ${mapObj[status]?.color};`;
  }

  getStatusColor(status: string, mapObj: any) {
    if (!status) {
      return '';
    }
    return mapObj[status]?.color;
  }

  getStatus(status: string, mapObj: any) {
    if (!status) {
      return '';
    }
    return mapObj[status]?.text;
  }
  //http://localhost:8083/api/v1/int/employee/policy/contributionRecords
  getContributionRecords(
    id = '',
    // filterType: ContributionSortByE,
    payrollGroupId?: string,
    startDate?: string,
    runDate?: string
  ): Observable<BasicResponse<ContributionDetails>> {
    return this.http.get<BasicResponse<ContributionDetails>>(this.getContributionRecordssUrl, {
      params: filterParams({
        id,
        payrollGroupId,
        // filterType,
        // startDate: '2023-6-24',
        startDate: startDate ? moment(startDate, 'DD/MM/YYYY').format('YYYY-MM-DD') : undefined,
        runDate: runDate ? moment(runDate, 'DD/MM/YYYY').format('YYYY-MM-DD') : undefined,
      }),
    });
  }

  getDefaultContribution(params: DefaultContributionRequest): Observable<BasicResponse<DefaultContribution[]>> {
    return this.http.get<BasicResponse<DefaultContribution[]>>(this.getDefaultContributionUrl, {
      params: filterParams(params),
    });
  }

  getPolicyTransferList(page: PolicyTransferRequest): Observable<PageResponse<PolicyTransfer>> {
    return this.http.get<PageResponse<PolicyTransfer>>(this.getPolicyTransferListUrl, {
      params: filterParams(page),
    });
  }

  getPolicyWithdrawalList(page: PolicyWithdrawalRequest): Observable<PageResponse<PolicyWithdrawal>> {
    return this.http.get<PageResponse<PolicyWithdrawal>>(this.getPolicyWithdrawalListUrl, {
      params: filterParams(page),
    });
  }

  getCampaignMemberList(page: CampaignSearchRequest): Observable<PageResponse<Campaign>> {
    return this.http.get<PageResponse<Campaign>>(this.getCampaignListUrl, {
      params: filterParams(page),
    });
  }

  uploadCustomerList(file: File) {
    const formData = new FormData();
    formData.append('file', file);
    return this.http.post<BasicResponse<CustomerListFileCheckRes[]>>(this.uploadCustomerListUrl, formData);
  }

  campaignEdit = (params: Campaign) => {
    return this.http.put<BasicResponse<any>>(this.campaignUrl, params);
  };

  campaignAdd = (params: Campaign) => {
    return this.http.post<BasicResponse<any>>(this.campaignUrl, params);
  };

  campaignDelete(id: string): Observable<BasicResponse<any>> {
    return this.http.delete<BasicResponse<any>>(this.campaignUrl, {
      params: {
        id,
      },
    });
  }

  // TODO
  getEmpfWorkflowList = (params: EmpfWorkflowRequest): Observable<PageResponse<EmpfWorkflow>> => {
    return this.http.get<PageResponse<EmpfWorkflow>>(this.getListUrl, {
      params: filterParams(params),
    });
  };

  accountTypeTrans(type?: MemberAccountTypeE) {
    if (!type) return undefined;
    return MemberAccountTypeMap.get(type);
  }

  getRegistrationType(type?: string) {
    if (!type) return undefined;
    return RegistrationTypeMap.get(type);
  }

  //"http://localhost:8093/api/v1/int/employee/policy/fundInfo/getTransactionRecord"
  getFundInfoTransactionRecordPageList(params: TransactionRecordRequest): Observable<PageResponse<TransactionRecordResponse>> {
    const paramsStr = paramsObj2paramsUrlStr(params);

    return this.http.get<PageResponse<TransactionRecordResponse>>(this.getTransactionRecord + paramsStr);
  }
  //"http://localhost:8093/api/v1/int/employee/policy/fundInfo/getInvestedFundInformation
  getInvestedFundInformationResponse(id: string): Observable<BasicResponse<InvestedFundInformationResponse>> {
    return this.http.get<BasicResponse<InvestedFundInformationResponse>>(this.getInvestedFundInformation, {
      params: { id: id },
    });
  }

  //"http://localhost:8093/api/v1/int/employee/policy/fundInfo/getFundBalance"
  getFundBalance(id: string): Observable<BasicResponse<FundBalanceResponse>> {
    return this.http.get<BasicResponse<FundBalanceResponse>>(this.getFundBalancePath, {
      params: { id: id },
    });
  }

  //"http://localhost:8093/api/v1/int/employee/policy/paymentTransaction"
  getPaymentTransaction(id: string, transactionRefNo: string, paySubmitRefNo: string): Observable<BasicResponse<PaymentTransactionResponse>> {
    return this.http.get<BasicResponse<PaymentTransactionResponse>>(this.getPaymentTransactionPath, {
      params: { id: id, transactionRefNo: transactionRefNo, paySubmitRefNo: paySubmitRefNo },
    });
  }
}
