/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CommissionCommonService {
  // Observable string sources
  private applySummarySource = new Subject<string>();
  private summarySource = new Subject<string>();
  private relatedPartiesSource = new Subject<string>();
  private scaleSource = new Subject<string>();

  // Observable string streams
  applySummary$ = this.applySummarySource.asObservable();
  summary$ = this.summarySource.asObservable();
  relatedParties$ = this.relatedPartiesSource.asObservable();
  scale$ = this.scaleSource.asObservable();

  applySummaryInfo(obj: any) {
    this.applySummarySource.next(obj);
  }

  // Service message commands
  announceSummary(obj: any) {
    this.summarySource.next(obj);
  }

  announceRelatedParties(obj: any) {
    this.relatedPartiesSource.next(obj);
  }

  announceScale(obj: any) {
    this.scaleSource.next(obj);
  }
}
