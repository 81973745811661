/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Inject, OnInit } from '@angular/core';

import { CdPopupService, CdPopupSize, MatDialogRef, CDS_POPUP_DATA, CdPopupType } from 'src/app/shared/cd-popup';

import { I18N_KEY, I18N_KEY_FOR_API_RES } from '../../calendar.config';
import { CalendarService } from 'src/app/views/system/calendar/calendar.service';
import { ResponseResult } from 'src/app/core/models/response/response-result';
import { ActionEnum } from 'src/app/core/models/enum';

@Component({
  selector: 'app-review-severe-weather',
  templateUrl: './review-severe-weather.component.html',
  styleUrls: ['./review-severe-weather.component.scss'],
})
export class ReviewSevereWeatherComponent implements OnInit {
  originalDate = '';
  title = '';
  newDate = '';
  reviewId = '';
  commitId = '';
  isCreate = true;

  constructor(
    private dialogRef: MatDialogRef<ReviewSevereWeatherComponent>,
    private cdsPopup: CdPopupService,
    public calendarService: CalendarService,
    @Inject(CDS_POPUP_DATA)
    public data: {
      data: any;
    }
  ) {}

  ngOnInit(): void {
    const event = this.data.data;
    this.title = event.title;
    this.commitId = event.commitId;
    this.newDate = event.eventDate;
    this.reviewId = event.id;
    this.isCreate = ActionEnum.CREATE === event.eventAction;
    this.originalDate = event.originalDate;
    if (event.eventAction === ActionEnum.DELETE) {
      this.newDate = I18N_KEY.NORMAL_WORKING_DAY;
      this.originalDate = event.eventDate;
    }
  }

  decline() {
    const popupRef: MatDialogRef<any> = this.cdsPopup.openCommon({
      size: CdPopupSize.MEDIUM,
      type: CdPopupType.CONTINUE,
      isShowClose: false,
      data: {
        message: I18N_KEY.CALENDAR_DECLINE_MSG,
        continue: I18N_KEY.CALENDAR_DECLINE,
      },
    });
    popupRef.afterClosed().subscribe(confirm => {
      if (confirm.continue) {
        this.approveRequest(false);
      }
    });
  }

  onSubmit() {
    this.approveRequest(true);
  }

  approveRequest(isApprove: boolean) {
    const params: any = {
      reviewId: this.reviewId,
      isApprove,
    };
    this.calendarService.approveSevereWeather(params).subscribe(res => {
      if (res.result == ResponseResult.SUCCESS) {
        this.calendarService.reviewEvents = this.calendarService.reviewEvents.filter(item => item.id !== this.reviewId);
        const popupRef: MatDialogRef<any> = this.cdsPopup.openCommon();
        popupRef.afterClosed().subscribe(() => {
          this.dialogRef.close();
        });
      } else {
        this.cdsPopup.openCommon({
          data: {
            message: I18N_KEY_FOR_API_RES[res.message] || res.message,
          },
        });
      }
    });
  }
}
