<div class="row l-pt-8 padding-10">
  <div class="col-xs-12 col-md-12">
    <span class="cds-h2-ita"> Session time out. </span>
  </div>
  <div class="col-xs-12 col-md-12">
    <p class="cds-body1">Please login and come back again.</p>
  </div>
  <div class="col-xs-12 col-md-2">
    <cds-button [fullWidth]="true" (click)="toLogin()"> Login</cds-button>
  </div>
  <br />
  <br />
  <br />
  <br />
  <br />
  <br />
  <br />
  <br />
  <br />
</div>
