import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-campaign-master-list',
  templateUrl: './campaign-master-list.component.html',
  styleUrls: ['./campaign-master-list.component.scss'],
})
export class CampaignMasterListComponent implements OnInit {
  tabIndex = 0;
  activedIndexList: number[] = [0];

  constructor() {}

  ngOnInit(): void {}

  fakeTabIndexChange(index: number) {
    console.log('fakeTabIndexChange:', index);
    this.tabIndex = index;
  }
}
