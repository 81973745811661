<div class="cds-popup-padding-top">
  <cds-icon icon="action:cross" *ngIf="size !== 'sm' && isShowClose" (click)="close()"></cds-icon>
</div>
<div mat-dialog-content class="cds-popup-{{ size }}">
  <h2 class="cds-h2-light cds-popup-title cds-popup-{{ size }}">
    <ng-content select="cds-popup-title"></ng-content>
  </h2>

  <div class="cds-popup-content cds-body1 cds-popup-{{ size }}">
    <ng-content select="cds-popup-content"></ng-content>
    <ng-content></ng-content>
  </div>

  <div class="cds-popup-actions cds-popup-{{ size }}">
    <ng-content select="cds-popup-actions"></ng-content>
  </div>
</div>
