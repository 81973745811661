import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WidgetsQuickLinksComponent } from './widgets-quick-links/widgets-quick-links.component';
import { CdsTabsModule } from '@cds/ng-web-components/tabs';
import { CdsIconModule } from '@cds/ng-web-components/icon';
import { WidgetsProductionCreditComponent } from './widgets-production-credit/widgets-production-credit.component';
import { CdsDonutChartModule } from '@cds/ng-charts/donut';
import { WidgetsPendingIffComponent } from './widgets-pending-iff/widgets-pending-iff.component';
import { WidgetsCampaignResultComponent } from './widgets-campaign-result/widgets-campaign-result.component';
import { WidgetsCampaignOverviewComponent } from './widgets-campaign-overview/widgets-campaign-overview.component';
import { CdsTableModule } from '@cds/ng-data-table/table';
import { CdsLanguageModule } from '@cds/ng-core/lang';
import { SharedModule } from 'src/app/shared/shared.module';
import { WidgetsProductionCreditByDistrictComponent } from './widgets-production-credit-by-district/widgets-production-credit-by-district.component';
import { CdsDropdownModule } from '@cds/ng-web-components/dropdown';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

const components = [
  WidgetsQuickLinksComponent,
  WidgetsProductionCreditComponent,
  WidgetsPendingIffComponent,
  WidgetsCampaignResultComponent,
  WidgetsCampaignOverviewComponent,
  WidgetsProductionCreditByDistrictComponent,
];

@NgModule({
  declarations: [...components],
  exports: [...components],
  imports: [
    CommonModule,
    CdsTabsModule,
    CdsIconModule,
    CdsDonutChartModule,
    CdsTableModule,
    CdsLanguageModule,
    SharedModule,
    CdsDropdownModule,
    FormsModule,
    ReactiveFormsModule,
  ],
})
export class WidgetsModule {}
