/* eslint-disable @typescript-eslint/no-explicit-any */
import { IPageableRequest } from 'src/app/core/models/request';

export const I18N_KEY = {
  INVALID_DATE: 'common.invalidDate',
  INVALID_DESCRIPTION: 'employer.invalid-description',
  INVALID_DESCRIPTION_CHINESE: 'employer.invalid-description-chinese',
  INVALID_USERNAME: 'employer.invalid-username',
  INVALID_AGENT_CODE: 'agent.invalide-agent-code',
};

export interface AutoRolloverSearchRequest extends IPageableRequest {
  empfMemberAccountNumber?: string;
  createStartDate?: string;
  createEndDate?: string;
  hkid?: string;
  passport?: string;
  sort?: string;
}

export interface AutoRollover {
  id: string;
  trusteeEmployerAccountNumber?: string;
  empfMemberAccountNumber?: string;
  status?: AutoRolloverStatsE;
  hkidNo?: string;
  passport?: string;
  firstName?: string;
  lastName?: string;
  chineseFirstName?: string;
  chineseLastName?: string;
  transactionId?: string;
  transactionDate?: string;
  dealingDate?: string;
  nationality1?: string;
  nationality2?: string;
  nationality3?: string;
  gender?: string;
  dateOfBirth?: string;
  bankruptcy?: string;
  bankruptcyDate?: string;
  bankruptcyNumber?: string;
  ordContact?: string;
  primaryMobileNumber?: string;
  secondaryMobileNumber?: string;
  primaryContactNumber?: string;
  secondaryContactNumber?: string;
  faxNumber?: string;
  emailAddress?: string;
  addressType?: string;
  addressLine1Room?: string;
  addressLine2Floor?: string;
  addressLine3Block?: string;
  addressLine4Building?: string;
  addressLine5Street?: string;
  addressLine6District?: string;
  addressLine7City?: string;
  addressLine8Country?: string;
  postalCode?: string;
  tax1CountryIdentificationNo?: string;
  tax1IdentificationNo?: string;
  tax1ReasonWithoutTaxIdentificationNumber?: string;
  tax1FurtherReasonTaxIdentificationNumber?: string;
  tax2CountryIdentificationNo?: string;
  tax2IdentificationNo?: string;
  tax2ReasonWithoutTaxIdentificationNumber?: string;
  tax2FurtherReasonTaxIdentificationNumber?: string;
  tax3CountryIdentificationNo?: string;
  tax3IdentificationNo?: string;
  tax3ReasonWithoutTaxIdentificationNumber?: string;
  tax3FurtherReasonTaxIdentificationNumber?: string;
  effectiveDate?: string;
  expiredDate?: string;
  crsReceivedDate?: string;
  accountOpenDate?: string;
  companyName?: string;
  checked?: boolean;
}

export enum AutoRolloverStatsE {
  NEW = 'New',
  READY = 'Ready',
  SUBMITTED = 'Submitted',
  HOLD = 'Hold',
  COMPLETED = 'Completed',
  ERROR = 'Error',
}

export enum MemberListTypeE {
  BOTH = 'both',
  ONLY_TERMINATED = 'terminated',
  ONLY_ENROLLED = 'enrolment',
}

export interface Employer {
  employerCode: string;
  employerName: string;
}

export interface RelatedMemberRequest {
  employerCodes: string;
  chooseType: string;
  agentCodes: string;
}

export interface RelatedMemberResponse {
  id: string;
  member_name: string;
  member_type: string;
}

export interface AgentReassignmentMemberCountRequet {
  employerCodes: string;
  chooseMember: string;
  agentCodes: string;
}

export interface AgentReassignmentMemberProcessRequet {
  memberIds: string[];
  employerCodes: string[];
  chooseType: MemberListTypeE;
  agentCodes: string[];
  newAgentCodes: string[];
}

export interface PaymentConfirmSearchDTO extends IPageableRequest {
  period?: number;
  feedReceiveDateFrom?: string;
  feedReceiveDateFromShow?: string;
  feedReceiveDateTo?: string;
  feedReceiveDateToShow?: string;
}

export interface PaymentConfirmDTO {
  id?: string;
  feedReceiveDate?: string;
  noOfRecord?: string;
  amount?: string;
  reissueAmount?: string;
  status?: string;
}

export interface AgentPerformanceHeadSearchDTO extends IPageableRequest {
  agentCode?: string;
  period?: number;
  assignmentDateFrom?: string;
  assignmentDateFromShow?: string;
  assignmentDateTo?: string;
  assignmentDateToShow?: string;
}
export interface AgentPerformanceHeadDTO {
  agentCode?: string;
  agentName?: string;
  agentNameChi?: string;
  outStanding?: string;
  initialContact?: string;
  scheduleMeetUp?: string;
  canNotReachClient?: string;
  rejectedByClient?: string;
  completed?: string;
  total?: string;
  transferToGs?: string;
  firstName?: string;
  lastName?: string;
  chineseFirstName?: string;
  chineseLastName?: string;
  contactMap?: any;
  expandData?: any;
}
