<div class="least-one-warning">
  <div>
    <span class="cds-body-hero" style="font-weight: 300">It’s </span>
    <span class="cds-body-hero" style="font-style: italic; font-weight: 400">not allowed to delete </span>
    <span class="cds-body-hero" style="font-weight: 300">the record as at least one servicing agent should be existed.</span>
  </div>

  <div class="l-mt-5">
    <cds-button size="sm" (click)="onCancel()">Got it!</cds-button>
  </div>
</div>
