<div class="row dropdown" [formGroup]="form" *ngIf="formReady">
  <div class="col-xs-12 l-pt-7">
    <span class="cds-h2-light">{{ 'list.serach-record' | lang }}</span>
  </div>

  <div class="col-xs-6 col-sm-3 col-md-3 col-lg-3 l-pt-4 l-pb-4">
    <cds-textfield formControlName="empfId" [label]="'er.profile.empf-id' | lang" [placeholder]="'er.profile.input-empf-id' | lang"> </cds-textfield>

    <div *ngIf="empfId?.invalid && (empfId?.dirty || empfId?.touched)" class="l-pt-2">
      <cds-assistive-text *ngIf="empfId?.errors?.['maxlength']" class="l-pt-2" [showIcon]="false">Max length is 20</cds-assistive-text>

      <cds-assistive-text
        *ngIf="empfId?.errors?.['invalidNumberString']"
        class="l-pt-2"
        [showIcon]="false"
        >{{ empfId?.errors?.['invalidNumberString'] }}</cds-assistive-text
      >
    </div>
  </div>

  <div class="col-xs-6 col-sm-3 col-md-3 col-lg-3 l-pt-4 l-pb-4">
    <cds-textfield formControlName="memberName" [label]="'ee.member-name' | lang" [placeholder]="'ee.input-member-name' | lang"></cds-textfield>

    <div *ngIf="memberName?.invalid && (memberName?.dirty || memberName?.touched)" class="l-pt-2">
      <cds-assistive-text *ngIf="memberName?.errors?.['maxlength']" class="l-pt-2" [showIcon]="false">Max length is 100</cds-assistive-text>

      <cds-assistive-text
        *ngIf="memberName?.errors?.['descriptionInvalid']"
        class="l-pt-2"
        [showIcon]="false"
        >{{ memberName?.errors?.['descriptionInvalid'] }}</cds-assistive-text
      >
    </div>
  </div>

  <div class="col-xs-6 col-sm-3 col-md-3 col-lg-3 l-pt-4 l-pb-4">
    <div class="flex">
      <div class="l-mr-2 input-label">{{ 'ee.origin-member-account-code' | lang }}</div>
      <app-tooltip-icon tooltipString="Cert number"></app-tooltip-icon>
    </div>
    <cds-textfield
      formControlName="originalMemberAccountCode"
      label=""
      cdsTooltip="Cert number"
      [placeholder]="'ee.input-origin-member-account-code' | lang"></cds-textfield>

    <div *ngIf="originalMemberAccountCode?.invalid && (originalMemberAccountCode?.dirty || originalMemberAccountCode?.touched)" class="l-pt-2">
      <cds-assistive-text *ngIf="originalMemberAccountCode?.errors?.['maxlength']" class="l-pt-2" [showIcon]="false">Max length is 100</cds-assistive-text>

      <cds-assistive-text
        *ngIf="originalMemberAccountCode?.errors?.['invalidNumberString']"
        class="l-pt-2"
        [showIcon]="false"
        >{{ originalMemberAccountCode?.errors?.['invalidNumberString'] }}</cds-assistive-text
      >
    </div>
  </div>

  <div class="col-xs-6 col-sm-3 col-md-3 col-lg-3 l-pt-4 l-pb-4">
    <cds-textfield
      formControlName="memeberAccountCode"
      [label]="'ee.member-account-code' | lang"
      [placeholder]="'ee.input-member-account-code' | lang"></cds-textfield>

    <div *ngIf="memeberAccountCode?.invalid && (memeberAccountCode?.dirty || memeberAccountCode?.touched)" class="l-pt-2">
      <cds-assistive-text *ngIf="memeberAccountCode?.errors?.['maxlength'] || memeberAccountCode?.errors?.['minlength']" class="l-pt-2" [showIcon]="false"
        >Max length is 15</cds-assistive-text
      >

      <cds-assistive-text
        *ngIf="memeberAccountCode?.errors?.['invalidNumberString']"
        class="l-pt-2"
        [showIcon]="false"
        >{{ memeberAccountCode?.errors?.['invalidNumberString'] }}</cds-assistive-text
      >
    </div>
  </div>

  <div class="col-xs-6 col-sm-3 col-md-3 col-lg-3 l-pt-4 l-pb-4">
    <app-cd-multidropdown
      formControlName="memberAccountType"
      label="Member Account Type"
      placeholder="Select Member Account Type"
      [style.width]="'100%'"
      [toppingList]="memberAccountTypeOptions"></app-cd-multidropdown>
  </div>

  <div class="col-xs-6 col-sm-3 col-md-3 col-lg-3 l-pt-4 l-pb-4">
    <app-cd-multidropdown
      formControlName="policyStatus"
      label="Member Status"
      placeholder="Select Member Status"
      [style.width]="'100%'"
      [toppingList]="policyStatusOptions"></app-cd-multidropdown>
  </div>

  <div class="col-xs-6 col-sm-3 col-md-3 col-lg-3 l-pt-4 l-pb-4">
    <cds-dropdown formControlName="lineOfBusiness" [config]="lineOfBusinessConfig"> </cds-dropdown>
  </div>

  <div class="col-xs-6 col-sm-3 col-md-3 col-lg-3 l-pt-4 l-pb-4 phone-number">
    <div class="phone-number-label cds-detail1">Phone Number</div>
    <div class="phone-number-content">
      <div class="country-code-wrapper">
        <cds-dropdown
          #countryCodeRef
          formControlName="phoneNumberExt"
          (cdsChange)="onCountryCodeChange($event, phoneNumberRef.value)"
          [config]="countryCodeConfig">
        </cds-dropdown>

        <div *ngIf="countryCode?.invalid" class="l-pt-2 absolute">
          <cds-assistive-text *ngIf="countryCode?.errors?.['required']" [showIcon]="false">Missing Country Calling Code</cds-assistive-text>
        </div>
      </div>
      <div class="number-wrapper">
        <cds-textfield
          #phoneNumberRef
          formControlName="phoneNumber"
          (cdsChange)="onPhoneNumberChange($event)"
          label=""
          placeholder="Input Phone Number"></cds-textfield>

        <div *ngIf="phoneNumber?.invalid" class="l-pt-2 absolute">
          <cds-assistive-text *ngIf="phoneNumber?.errors?.['maxlength']" class="l-pt-2" [showIcon]="false">Max length is 20</cds-assistive-text>

          <cds-assistive-text
            *ngIf="phoneNumber?.errors?.['invalidNumberString']"
            class="l-pt-2"
            [showIcon]="false"
            >{{ phoneNumber?.errors?.['invalidNumberString'] }}</cds-assistive-text
          >
          <cds-assistive-text *ngIf="phoneNumber?.errors?.['required']" class="l-pt-2" [showIcon]="false">Missing Phone Number</cds-assistive-text>
        </div>
      </div>
    </div>
  </div>

  <ng-container *ngIf="isAdvanceSearch">
    <div class="col-xs-6 col-sm-3 col-md-3 col-lg-3 l-pt-4 l-pb-4" *ngIf="isStaff">
      <cds-textfield formControlName="hkidOrPassport" [label]="'ee.hkid-passport' | lang" [placeholder]="'ee.input-hkid-passport' | lang"> </cds-textfield>

      <div *ngIf="hkidOrPassport?.invalid && (hkidOrPassport?.dirty || hkidOrPassport?.touched)" class="l-pt-2">
        <cds-assistive-text *ngIf="hkidOrPassport?.errors?.['maxlength']" class="l-pt-2" [showIcon]="false">Max length is 80</cds-assistive-text>

        <cds-assistive-text
          *ngIf="hkidOrPassport?.errors?.['descriptionInvalid']"
          class="l-pt-2"
          [showIcon]="false"
          >{{ hkidOrPassport?.errors?.['descriptionInvalid'] }}</cds-assistive-text
        >
      </div>
    </div>

    <div *ngIf="showDatePicker" class="row col-xs-12 col-sm-12 col-md-8 col-lg-6 l-pt-4 l-pb-4 l-plr-0 date-picker-area relative">
      <div class="col-xs-6 date-picker">
        <cds-textfield
          formControlName="enrolmentCompletionDateFrom"
          placeholder="DD/MM/YYYY"
          label="Enrolment Completion Date From"
          [max]="getMaxFromDate | fn : enrolmentCompletionDateToValue || nowTimeInstr"
          [cdsDatepicker]="startPicker">
        </cds-textfield>
        <cds-datepicker #startPicker="cdsDatepicker"></cds-datepicker>

        <div
          *ngIf="enrolmentCompletionDateFromControl?.invalid && (enrolmentCompletionDateFromControl?.dirty || enrolmentCompletionDateFromControl?.touched)"
          class="l-pt-2">
          <cds-assistive-text *ngIf="enrolmentCompletionDateFromControl?.errors?.['dateInvalid']" class="l-pt-2" [showIcon]="false">{{
            'create.dateError' | lang
          }}</cds-assistive-text>
          <cds-assistive-text *ngIf="enrolmentCompletionDateFromControl?.errors?.['startLaterThanEnd']" class="l-pt-2" [showIcon]="false">{{
            'common.date-range-error' | lang
          }}</cds-assistive-text>
        </div>
      </div>
      <div class="connector">-</div>
      <div class="col-xs-6 date-picker">
        <cds-textfield
          formControlName="enrolmentCompletionDateTo"
          placeholder="DD/MM/YYYY"
          label="To"
          [min]="getMinToDate | fn : enrolmentCompletionDateFromValue"
          [max]="maxTime"
          [cdsDatepicker]="endPicker">
        </cds-textfield>
        <cds-datepicker #endPicker="cdsDatepicker"></cds-datepicker>

        <div
          *ngIf="enrolmentCompletionDateToControl?.invalid && (enrolmentCompletionDateToControl?.dirty || enrolmentCompletionDateToControl?.touched)"
          class="l-pt-2">
          <cds-assistive-text *ngIf="enrolmentCompletionDateToControl?.errors?.['dateInvalid']" class="l-pt-2" [showIcon]="false">{{
            'create.dateError' | lang
          }}</cds-assistive-text>
          <cds-assistive-text *ngIf="enrolmentCompletionDateToControl?.errors?.['startLaterThanEnd']" class="l-pt-2" [showIcon]="false">{{
            'common.date-range-error' | lang
          }}</cds-assistive-text>
        </div>
      </div>
    </div>

    <div class="col-xs-6 col-sm-3 col-md-3 col-lg-3 l-pt-4 l-pb-4">
      <cds-textfield formControlName="companyName" [label]="'ee.company-name' | lang" [placeholder]="'ee.input-company-name' | lang"></cds-textfield>

      <div *ngIf="companyName?.invalid && (companyName?.dirty || companyName?.touched)" class="l-pt-2">
        <cds-assistive-text *ngIf="companyName?.errors?.['maxlength']" class="l-pt-2" [showIcon]="false">Max length is 200</cds-assistive-text>

        <cds-assistive-text
          *ngIf="companyName?.errors?.['descriptionInvalid']"
          class="l-pt-2"
          [showIcon]="false"
          >{{ companyName?.errors?.['descriptionInvalid'] }}</cds-assistive-text
        >
      </div>
    </div>

    <div class="col-xs-6 col-sm-3 col-md-3 col-lg-3 l-pt-4 l-pb-4">
      <cds-textfield
        formControlName="employerAccountCode"
        [label]="'er.profile.employer-account-code' | lang"
        [placeholder]="'er.profile.input-employer-account-code' | lang"></cds-textfield>

      <div *ngIf="employerAccountCode?.invalid && (employerAccountCode?.dirty || employerAccountCode?.touched)" class="l-pt-2">
        <cds-assistive-text *ngIf="employerAccountCode?.errors?.['maxlength']" class="l-pt-2" [showIcon]="false">Max length is 15</cds-assistive-text>

        <cds-assistive-text
          *ngIf="employerAccountCode?.errors?.['invalidNumberString']"
          class="l-pt-2"
          [showIcon]="false"
          >{{ employerAccountCode?.errors?.['invalidNumberString'] }}</cds-assistive-text
        >
      </div>
    </div>

    <div class="col-xs-6 col-sm-3 col-md-3 col-lg-3 l-pt-4 l-pb-4" *ngIf="isStaff">
      <app-cd-multidropdown
        formControlName="servicingChannel"
        label="Servicing Channnel"
        placeholder="Select Servicing Channnel"
        [style.width]="'100%'"
        [toppingList]="servicingChannelOptions"></app-cd-multidropdown>
    </div>

    <div class="col-xs-6 col-sm-3 col-md-3 col-lg-3 l-pt-4 l-pb-4" *ngIf="isStaff">
      <cds-textfield
        formControlName="servicingAgentCode"
        [label]="'list.servicing-agent-code' | lang"
        [placeholder]="'list.input-servicing-agent-code' | lang"></cds-textfield>

      <div *ngIf="servicingAgentCode?.invalid && (servicingAgentCode?.dirty || servicingAgentCode?.touched)" class="l-pt-2">
        <cds-assistive-text *ngIf="servicingAgentCode?.errors?.['maxlength']" class="l-pt-2" [showIcon]="false">Max length is 20</cds-assistive-text>

        <cds-assistive-text
          *ngIf="servicingAgentCode?.errors?.['invalidNumberString']"
          class="l-pt-2"
          [showIcon]="false"
          >{{ servicingAgentCode?.errors?.['invalidNumberString'] }}</cds-assistive-text
        >
      </div>
    </div>

    <div class="col-xs-6 col-sm-3 col-md-3 col-lg-3 l-pt-4 l-pb-4">
      <cds-dropdown formControlName="birthDayMonth" [config]="birthdayMonthConfig"></cds-dropdown>
    </div>

    <div class="col-xs-6 col-sm-3 col-md-3 col-lg-3 l-pt-4 l-pb-4 amount-range">
      <div class="amount-range-label cds-detail1">Age Range</div>
      <div class="amount-range-content">
        <div class="amount-wrapper">
          <cds-textfield formControlName="ageFrom" label="" placeholder="0"></cds-textfield>

          <div *ngIf="ageFrom?.invalid && (ageFrom?.dirty || ageFrom?.touched)" class="l-pt-2 absolute">
            <cds-assistive-text
              *ngIf="ageFrom?.errors?.['invalidRange']"
              class="l-pt-2"
              [showIcon]="false"
              >{{ ageFrom?.errors?.['invalidRange'] | lang }}</cds-assistive-text
            >
            <cds-assistive-text *ngIf="ageFrom?.errors?.['invalidNumberString']" class="l-pt-2" [showIcon]="false">{{
              'common.please-input-integer' | lang
            }}</cds-assistive-text>
            <cds-assistive-text *ngIf="ageFrom?.errors?.['required']" class="l-pt-2" [showIcon]="false">Please input range from</cds-assistive-text>
          </div>
        </div>
        <div class="line"></div>
        <div class="amount-wrapper">
          <cds-textfield formControlName="ageTo" label="" placeholder="0"></cds-textfield>

          <div *ngIf="ageTo?.invalid && (ageTo?.dirty || ageTo?.touched)" class="l-pt-2 absolute">
            <cds-assistive-text
              *ngIf="ageTo?.errors?.['invalidRange']"
              class="l-pt-2"
              [showIcon]="false"
              >{{ ageTo?.errors?.['invalidRange'] | lang }}</cds-assistive-text
            >
            <cds-assistive-text *ngIf="ageTo?.errors?.['invalidNumberString']" class="l-pt-2" [showIcon]="false">{{
              'common.please-input-integer' | lang
            }}</cds-assistive-text>
            <cds-assistive-text *ngIf="ageTo?.errors?.['required']" class="l-pt-2" [showIcon]="false">Please input range to</cds-assistive-text>
          </div>
        </div>
      </div>
    </div>

    <div class="col-xs-6 col-sm-3 col-md-3 col-lg-3 l-pt-4 l-pb-4 amount-range">
      <div class="amount-range-label cds-detail1">Asset Size</div>
      <div class="amount-range-content">
        <div class="currency-wrapper">
          <cds-dropdown formControlName="assetType" [config]="assetTypeConfig" (cdsChange)="assetTypeChange()"></cds-dropdown>

          <div *ngIf="assetType?.invalid && (assetType?.dirty || assetType?.touched)" class="l-pt-2 absolute">
            <cds-assistive-text *ngIf="assetType?.errors?.['required']" [showIcon]="false">Please select currency</cds-assistive-text>
          </div>
        </div>

        <div class="amount-wrapper">
          <cds-textfield formControlName="assetSizeFrom" label="" placeholder="0" (cdsChange)="assetSizeChange()"></cds-textfield>

          <div *ngIf="assetSizeFrom?.invalid && (assetSizeFrom?.dirty || assetSizeFrom?.touched)" class="l-pt-2 absolute">
            <cds-assistive-text
              *ngIf="assetSizeFrom?.errors?.['invalidRange']"
              class="l-pt-2"
              [showIcon]="false"
              >{{ assetSizeFrom?.errors?.['invalidRange'] | lang }}</cds-assistive-text
            >
            <cds-assistive-text *ngIf="assetSizeFrom?.errors?.['invalidNumberString']" class="l-pt-2" [showIcon]="false">{{
              'common.please-input-integer' | lang
            }}</cds-assistive-text>
            <cds-assistive-text *ngIf="assetSizeFrom?.errors?.['required']" class="l-pt-2" [showIcon]="false">Please input range from</cds-assistive-text>
          </div>
        </div>
        <div class="line"></div>
        <div class="amount-wrapper">
          <cds-textfield formControlName="assetSizeTo" label="" placeholder="0" (cdsChange)="assetSizeChange()"></cds-textfield>

          <div *ngIf="assetSizeTo?.invalid && (assetSizeTo?.dirty || assetSizeTo?.touched)" class="l-pt-2 absolute">
            <cds-assistive-text
              *ngIf="assetSizeTo?.errors?.['invalidRange']"
              class="l-pt-2"
              [showIcon]="false"
              >{{ assetSizeTo?.errors?.['invalidRange'] | lang }}</cds-assistive-text
            >
            <cds-assistive-text *ngIf="assetSizeTo?.errors?.['invalidNumberString']" class="l-pt-2" [showIcon]="false">{{
              'common.please-input-integer' | lang
            }}</cds-assistive-text>
            <cds-assistive-text *ngIf="assetSizeTo?.errors?.['required']" class="l-pt-2" [showIcon]="false">Please input range to</cds-assistive-text>
          </div>
        </div>
      </div>
    </div>

    <div class="col-xs-6 col-sm-3 col-md-3 col-lg-3 l-pt-4 l-pb-4">
      <app-dynamic-input-autocomplete
        label="Resident Address District"
        class="description-control"
        placeholder="Select Resident Address District"
        [requestUrl]="addressRequestUrl"
        [requestParams]="addressSearchParams"
        [resDataInContent]="false"
        [initLoad]="false"
        searchKey="residentAddressDistrict"
        [showDropdownButton]="true"
        formControlName="residentAddressDistrict"></app-dynamic-input-autocomplete>
    </div>

    <div class="col-xs-6 col-sm-3 col-md-3 col-lg-3 l-pt-4 l-pb-4">
      <cds-textfield
        formControlName="lastEcaMonthYearOrYear"
        label="{{ 'ee.last-eca-month-year' | lang }}"
        placeholder="{{ 'ee.input-last-eca-month-year' | lang }}"></cds-textfield>

      <div *ngIf="lastEcaMonthYearOrYear?.invalid && (lastEcaMonthYearOrYear?.dirty || lastEcaMonthYearOrYear?.touched)" class="l-pt-2">
        <cds-assistive-text *ngIf="lastEcaMonthYearOrYear?.errors?.['dateInvalid']" class="l-pt-2" [showIcon]="false"
          >Date format must be MM-YYYY or YYYY</cds-assistive-text
        >
      </div>
    </div>
  </ng-container>

  <div class="col-xs-12 l-mt-2 l-pb-2 l-pt-2 btns">
    <cds-button
      class="l-mr-4 button"
      [disabled]="searchButtonDisabled"
      [config]="searchButtonConfig"
      label="{{ 'list.search' | lang }}"
      (click)="search()"></cds-button>

    <cds-button class="l-mr-4 button" [disabled]="resetButtonDisabled" [config]="resetButtonConfig" label="{{ 'list.reset' | lang }}" (click)="reset()">
    </cds-button>

    <div (click)="advanceSearchToggle()" class="flex pointer">
      <div class="l-mr-2">
        <cds-icon *ngIf="isAdvanceSearch" color="#EC6453" icon="action:button_minus_filled"></cds-icon>
        <cds-icon *ngIf="!isAdvanceSearch" class="plus-icon" color="#EC6453" icon="action:button_x_filled"></cds-icon>
      </div>
      <div>
        <span class="cds-body2-demibold">Advanced Search</span>
      </div>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-xs-12 l-pt-7 l-pb-2">
    <span class="cds-h2-light">{{ 'list.record-list' | lang }}</span>
  </div>
  <div class="col-xs-12">
    <span class="cds-body1">Total {{ _totalCount | number }} records</span>
  </div>
</div>

<ng-template #checkBox let-element>
  <cds-checkbox *ngIf="element['parentCompany'] === 'N'" [config]="noCheckConfig"></cds-checkbox>
  <cds-checkbox *ngIf="element['parentCompany'] === 'Y'" [config]="checkedConfig"></cds-checkbox>
</ng-template>
<ng-template #nameTemplate let-element>
  <div (click)="goToDetail(element)" class="col-xs middle-xs l-plr-0">
    <span class="cds-body1-demibold bold" [class.green-line]="isStaff">
      <span class="l-mr-2">{{ element['lastName'] }}</span>
      <span class="l-mr-2">{{ element['firstName'] }}</span>
    </span>

    <span class="cds-body1-demibold bold" [class.green-line]="isStaff">
      <span>{{ element['lastZhName'] }}</span>
      <span>{{ element['firstZhName'] }}</span>
    </span>
  </div>
</ng-template>

<ng-template #dobTemplate let-element>
  <div>{{ element['doB'] | ssmsDate }}</div>
</ng-template>

<ng-template #expandTemplate let-element>
  <div class="row col-xs-12 l-plr-0" *ngIf="!_progress">
    <div class="cds-detail1 col-xs-12 l-plr-0 italic bold l-mb-4">Member Details</div>
    <div class="col-xs-12 l-plr-0">
      <app-data-table
        [overlay]="false"
        [dataSource]="getDataSource | fn : element"
        [isShowPagination]="false"
        [columnsConfig]="isStaff ? columnsConfig : columnsConfigAgent">
        <ng-template appDtBodyCellDef="memberAccountCode" let-item>
          <span class="green-line pointer" (click)="goToPolicyDetail(item)">{{ item.memberAccountCode }}</span>
        </ng-template>

        <ng-template appDtBodyCellDef="memberAuthorization" let-item>
          <cds-checkbox [disabled]="true" [checked]="item.memberAuthorization === 1"></cds-checkbox>
        </ng-template>

        <ng-template appDtBodyCellDef="lastEcaMonthYearOrYear" let-item>
          <span>{{ item.lastEcaMonthYearOrYear | ssmsDate }}</span>
        </ng-template>
      </app-data-table>
    </div>
  </div>
</ng-template>
<div class="row">
  <div class="col-xs-12 min-height" *ngIf="showTable">
    <app-table-expand
      (sortChange)="_sortChange($event)"
      [progress]="_progress"
      (expandChange)="expandRow($event)"
      [expandTemplate]="expandTemplate"
      [data]="_eeRecordList">
      <app-table-expand-col [sort]="Sort.DEFAULT" prop="empfID" label="eMPF ID"></app-table-expand-col>
      <app-table-expand-col [sort]="Sort.DEFAULT" [template]="nameTemplate" prop="employeeName" label="Name"></app-table-expand-col>
      <app-table-expand-col [sort]="Sort.DEFAULT" prop="gender" label="Gender"></app-table-expand-col>
      <app-table-expand-col [sort]="Sort.DEFAULT" [template]="dobTemplate" prop="dob" label="DOB"></app-table-expand-col>
      <app-table-expand-col [sort]="Sort.DEFAULT" prop="age" label="Age"></app-table-expand-col>
    </app-table-expand>
  </div>
</div>

<div *ngIf="_eeRecordList" class="row l-pt-4 middle-xs">
  <app-pagination [pageCount]="_pageCount" [isLoading]="_progress" [textfieldAlwaysAvailable]="true" [useOf]="true" (pageChange)="getPageList()">
  </app-pagination>
</div>
