import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TooltipIconComponent } from './tooltip-icon.component';
import { CdsIconModule } from '@cds/ng-web-components/icon';
import { CdsTooltipModule } from '@cds/ng-web-components/tooltip';

@NgModule({
  declarations: [TooltipIconComponent],
  imports: [CommonModule, CdsIconModule, CdsTooltipModule],
  exports: [TooltipIconComponent],
})
export class TooltipIconModule {}
