<div class="row l-pt-7">
  <div class="col-xs-4">
    <cds-dropdown [(ngModel)]="searchDTO.payMethod" (ngModelChange)="typeChange($event)" [config]="transferTypeConfig"></cds-dropdown>
  </div>
</div>
<div class="row l-pt-7">
  <div class="l-mr-6 l-mb-2">
    <div class="col-xs-12 l-pb-2">
      <span class="cds-h2-light">{{ 'list.record-list' | lang }}</span>
    </div>
    <div class="col-xs-12">
      <span class="cds-body1">{{ 'page.total' | lang }} {{ _totalCount }} {{ 'page.records' | lang }}</span>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-xs-12 row middle-xs between-xs">
    <div class="cds-color-navy-light3">
      <span>{{ 'cheque.list.number-of-records-shown' | lang }}</span>
    </div>
    <div class="row col-xs-6 col-md">
      <div
        class="page-size l-mr-2 l-ml-2 pointer"
        *ngFor="let size of pageSelectSize; let i = index"
        [ngClass]="_pageSelected === i ? 'size-selected' : ''"
        (click)="sizeSelect(i)">
        <span class="cds-body2">{{ size }}</span>
      </div>
    </div>
    <div class="l-pr-4">
      <cds-button [disabled]="_checkedDatasourceMap.size === 0" (click)="exportSelected()" [config]="exportSelectedButtonConfig">Export Selected</cds-button>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-xs-12 row l-pb-2">
    <div class="cds-color-navy-light3 l-pt-3">
      <span>{{ 'cheque.list.status' | lang }}</span>
    </div>
    <div class="row col-xs">
      <div class="row col-xs-12 l-plr-0">
        <div
          class="page-size l-ma-2 pointer"
          *ngFor="let item of _paymentStatusList; let i = index"
          [ngClass]="item.selected ? 'size-selected' : ''"
          (click)="paymentStatusSelecte(i)">
          <span class="cds-body2">{{ item.label }}</span>
        </div>
      </div>
      <div (click)="resetStatus()" class="row col-xs-12 middle-xs pointer l-pt-2 l-pb-2">
        <cds-icon class="l-pr-2" color="#00a758" icon="arrow:update_recycle"></cds-icon>
        <span class="cds-h6-demibold l-mb-0">Reset Status Filters</span>
      </div>
    </div>
  </div>
</div>

<div class="row l-plr-0">
  <app-loading *ngIf="_progress" [overlayInPopup]="true"></app-loading>
  <div class="col-xs-12">
    <div #tableBox class="table-x">
      <cds-table [dataSource]="dataSource" cdsSort (cdsSortChange)="onSortChange($event)" [class.full]="true">
        <ng-container cdsColumnDef="selected" sticky>
          <cds-header-cell *cdsHeaderCellDef>
            <cds-checkbox [checked]="_selectedAll" [indeterminate]="_checkedDatasourceMap.size > 0" (change)="triggerAll()"></cds-checkbox>
          </cds-header-cell>
          <cds-cell class="none-width" *cdsCellDef="let element; index as i">
            <cds-checkbox (change)="trigger(i)" [checked]="element.checked"></cds-checkbox>
          </cds-cell>
        </ng-container>
        <ng-container cdsColumnDef="payeeName" sticky>
          <cds-header-cell cds-sort-header *cdsHeaderCellDef> Payee Name </cds-header-cell>
          <cds-cell [ngClass]="element.checked ? 'bg-green-light-4' : ''" *cdsCellDef="let element">
            {{ element.payeeName }}
          </cds-cell>
        </ng-container>
        <ng-container cdsColumnDef="paymentStatus" sticky>
          <cds-header-cell *cdsHeaderCellDef> Payment Status </cds-header-cell>
          <cds-cell [ngClass]="element.checked ? 'bg-green-light-4' : ''" *cdsCellDef="let element">
            <div class="l-d-f l-ai-cen status">
              <span class="status-icon l-mr-2" [ngStyle]="{ background: getColor(element.recordStatus) }"></span>
              <span>{{ getPaymentStatus | fn : element.recordStatus }}</span>
            </div>
          </cds-cell>
        </ng-container>
        <ng-container cdsColumnDef="amt" sticky>
          <cds-header-cell cds-sort-header [ngClass]="checkIsHaveScroll ? 'shadow-right' : ''" *cdsHeaderCellDef> Amount</cds-header-cell>
          <cds-cell [ngClass]="[element.checked ? 'bg-green-light-4' : '', checkIsHaveScroll ? 'shadow-right' : '']" *cdsCellDef="let element">
            {{ element.curr }} {{ element.amt | number : '1.2-2' | mynumber }}
          </cds-cell>
        </ng-container>
        <ng-container cdsColumnDef="feedReceiveDate">
          <cds-header-cell cds-sort-header *cdsHeaderCellDef> Payment Instruction File Date </cds-header-cell>
          <cds-cell [ngClass]="[element.checked ? 'bg-green-light-4' : '']" *cdsCellDef="let element">
            {{ element.feedReceiveDate | ssmsDate }}
          </cds-cell>
        </ng-container>
        <ng-container cdsColumnDef="isReissue">
          <cds-header-cell cds-sort-header *cdsHeaderCellDef> Reissuance </cds-header-cell>
          <cds-cell [ngClass]="element.checked ? 'bg-green-light-4' : ''" *cdsCellDef="let element">
            {{ element.isReissue }}
          </cds-cell>
        </ng-container>
        <ng-container cdsColumnDef="reissueReason">
          <cds-header-cell *cdsHeaderCellDef> Reissurance Reason </cds-header-cell>
          <cds-cell [ngClass]="[element.checked ? 'bg-green-light-4' : '', 'break']" *cdsCellDef="let element">
            {{ formatReason(element.reissueReason) }}
          </cds-cell>
        </ng-container>
        <ng-container cdsColumnDef="payRefNo">
          <cds-header-cell cds-sort-header *cdsHeaderCellDef> Payment Ref No. </cds-header-cell>
          <cds-cell [ngClass]="element.checked ? 'bg-green-light-4' : ''" *cdsCellDef="let element">
            {{ element.payRefNo }}
          </cds-cell>
        </ng-container>
        <!-- <ng-container cdsColumnDef="appRefNo">
          <cds-header-cell cds-sort-header *cdsHeaderCellDef> Application Reference No. </cds-header-cell>
          <cds-cell [ngClass]="element.checked ? 'bg-green-light-4' : ''" *cdsCellDef="let element">
            <span class="green-line pointer">{{ element.appRefNo }}</span>
          </cds-cell>
        </ng-container> -->
        <ng-container cdsColumnDef="exptPayIssue">
          <cds-header-cell cds-sort-header *cdsHeaderCellDef> Expected Payment Issue Date on Statement </cds-header-cell>
          <cds-cell [ngClass]="element.checked ? 'bg-green-light-4' : ''" *cdsCellDef="let element">
            {{ formatDate2(element.exptPayIssue) }}
          </cds-cell>
        </ng-container>
        <ng-container cdsColumnDef="chequeIssueDate">
          <cds-header-cell cds-sort-header *cdsHeaderCellDef> Bank Value Date </cds-header-cell>
          <cds-cell [ngClass]="element.checked ? 'bg-green-light-4' : ''" *cdsCellDef="let element">
            {{ element.chequeIssueDate | ssmsDate }}
          </cds-cell>
        </ng-container>
        <ng-container cdsColumnDef="lastUpdateDate">
          <cds-header-cell cds-sort-header *cdsHeaderCellDef> Status Update Date </cds-header-cell>
          <cds-cell [ngClass]="element.checked ? 'bg-green-light-4' : ''" *cdsCellDef="let element">
            {{ element.lastUpdateDate | ssmsDate }}
          </cds-cell>
        </ng-container>

        <cds-header-row *cdsHeaderRowDef="displayedColumns"></cds-header-row>
        <cds-row *cdsRowDef="let row; columns: displayedColumns"></cds-row>
      </cds-table>
    </div>
  </div>

  <div *ngIf="!_progress && (!dataSource || dataSource.length === 0)" class="col-xs-12 l-pt-7 l-pb-7">
    <app-no-result-found></app-no-result-found>
  </div>

  <div *ngIf="dataSource.length" class="col-xs-12 l-pt-4 l-pb-4 l-d-f l-jc-sb">
    <app-pagination [pageCount]="pageCount" [isLoading]="_progress" [useOf]="true" (pageChange)="loadTable()"> </app-pagination>
  </div>
</div>

<div *ngIf="false && _checkedDatasourceMap.size > 0" class="operation-bar" [style.right]="scrollBarWidth" [style.left]="isMobile ? 0 : '80px'">
  <div class="cds-h4 l-mb-0" *ngIf="!isMobile">Please select record(s) to</div>
  <div class="button-area">
    <div class="cds-h5 l-mb-0 font-weight-6 l-mr-6">
      <span>{{ _checkedDatasourceMap.size }}</span> item(s) selected
    </div>
    <ng-container>
      <cds-button
        [disabled]="!dataSource.length"
        [config]="exportAllButtonConfig"
        label="{{ 'cheque.list.export-all-search-result' | lang }}"
        (click)="exportAll()"></cds-button>
      <div class="l-pr-5"></div>

      <cds-button (click)="exportSelected()" [config]="exportSelectedButtonConfig">Export Selected</cds-button>
    </ng-container>
  </div>
</div>

<app-cd-download-progress [show]="_downloading"></app-cd-download-progress>
