import { Component, OnInit } from '@angular/core';
import { CdsButtonConfig } from '@cds/ng-core/button';

@Component({
  selector: 'app-back-top',
  templateUrl: './back-top.component.html',
  styleUrls: ['./back-top.component.scss'],
})
export class BackTopComponent implements OnInit {
  constructor() {}

  config: CdsButtonConfig = {
    size: 'sm',
    style: 'secondary',
  };

  ngOnInit(): void {}

  backTop() {
    document.getElementsByClassName('cds-navigation-content')[0].scrollTop = 0;
  }
}
