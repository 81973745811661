import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CdsLangService } from '@cds/ng-core/lang';
import { CdsPopupService } from '@cds/ng-web-components/popup';
import { CdsToastService } from '@cds/ng-web-components/toast';
import { finalize } from 'rxjs';
import { PermissionAccess, PermissionItem } from 'src/app/core/models/enum/permission.enum';
import { IRoleInfo } from 'src/app/core/models/role';
import { RoleService } from 'src/app/core/services/role.service';
import { whitespaceValidator } from 'src/app/core/validators';
import { AlertPopupComponent } from 'src/app/shared/alert-popup/alert-popup.component';
import { ConfirmPopupComponent } from 'src/app/shared/confirm-popup/confirm-popup.component';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-role-info-form',
  templateUrl: './role-info-form.component.html',
})
export class RoleInfoFormComponent implements OnInit {
  @Input() roleId = '';
  @Input() role = '';
  @Input() formPermissions = new Map<PermissionItem, PermissionAccess>();
  @Output() errorEvent = new EventEmitter<boolean>();
  // formPermissions = new Map<PermissionItem, PermissionAccess>();
  form: FormGroup;
  isLoading = false;
  get roleName() {
    return this.form.get('roleName');
  }

  constructor(
    private router: Router,
    private roleService: RoleService,
    private toast: CdsToastService,
    private cdsPopup: CdsPopupService,
    private langService: CdsLangService
  ) {
    this.form = new FormGroup({
      roleName: new FormControl(environment.profile == 'dev' ? 'testa' : '', {
        validators: [Validators.required, Validators.minLength(3), whitespaceValidator()],
        updateOn: 'change',
      }),
    });
  }

  ngOnInit() {
    this.form.patchValue({ roleName: this.role });
    // if (this.roleId) {
    //   return
    //   this.isLoading = true;
    //   this.roleService
    //     .getById(this.roleId)
    //     .pipe(finalize(() => (this.isLoading = false)))
    //     .subscribe({
    //       next: res => {
    //         if (res.result === 0 && !!res.data) {
    //           this.form.patchValue({ roleName: res.data.roleName });
    //           this.formPermissions = res.data.permissions
    //             ? res.data.permissions
    //             : new Map<PermissionItem, PermissionAccess>();
    //           console.debug(`formPermissions=`, this.formPermissions);
    //         } else {
    //           this.errorEvent.emit(true);
    //         }
    //       },
    //       error: err => {
    //         this.errorEvent.emit(true);
    //         console.error('call role-query API error: ', err);
    //       },
    //     });
    // }
  }

  private popupWithRedirect() {
    this.cdsPopup
      .open(AlertPopupComponent, {
        size: 'sm',
        data: {
          title: this.langService.translate('common.action.changeSuccess'),
        },
      })
      .afterClosed()
      .subscribe(() => {
        this.router.navigate(['/system/role-list']);
      });
  }

  deleteRole() {
    if (this.submittable() && this.roleId != null && !this.isLoading) {
      this.cdsPopup
        .open(ConfirmPopupComponent, {
          size: 'sm',
          data: { title: 'role.confrim.delete' },
        })
        .afterClosed()
        .subscribe(result => {
          if (result?.agree && this.roleId != null) {
            console.log('into deleteRole(), roleId = ', this.roleId);
            this.roleService
              .deleteById(this.roleId)
              .pipe(finalize(() => (this.isLoading = false)))
              .subscribe({
                next: res => {
                  if (res.result === 0) {
                    this.popupWithRedirect();
                  } else {
                    this.toast.error('Operation failed: ' + res.message);
                  }
                },
                error: err => {
                  this.toast.error('Operation failed: ' + err);
                  console.error('Call role-delete API error: ', err);
                },
              });
          }
        });
    }
  }
  async saveRole() {
    if (this.submittable()) {
      this.isLoading = true;
      const data: IRoleInfo = {
        roleName: this.form.get('roleName')?.value,
        permissions: this.formPermissions,
      };
      if (this.roleId) {
        data.roleId = this.roleId;
      }
      this.roleService
        .saveOrUpdate(data)
        .pipe(finalize(() => (this.isLoading = false)))
        .subscribe({
          next: res => {
            if (res.result === 0 && res.data?.roleId) {
              this.router.navigate(['/system/role-info'], {
                queryParams: { roleId: res.data?.roleId },
              });
            } else {
              this.toast.error('create role failed: ' + res.message);
            }
          },
          error: err => {
            this.toast.error(err);
            console.error('call role-query API error: ', err);
          },
        });
    }
  }

  submittable() {
    return !this.isLoading && this.form.valid;
  }
}
