import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { MatDialogRef } from '@angular/material/dialog';

import { CdsButtonConfig } from '@cds/ng-core/button';
import { CdsDropdownConfig } from '@cds/ng-core/dropdown';
import { CdsIconConfig } from '@cds/ng-core/icon';
import { ActionIcons } from '@cds/ng-themes';
import { CdsAlertService } from '@cds/ng-web-components/alert';
import { LanguageChangeService } from 'src/app/shared/language-change.service';
import { IffService } from '../../service/iff.service';
import { ValidateEmpfRefNoRequest } from '../../service/model/validate-empf-ref-no-request.model';
import { CdsPopupService } from '@cds/ng-web-components/popup';
import { AgentInfo, AgentSubmissionRequest } from '../../service/model/agent-submission-request.model';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { AgentDetailInfo, AgentInfoResponse } from '../../service/model/agent-info-response.model';
import { EMPFRefNoUsedComponent } from '../declaration-iff/e-mpf-ref-no-used/e-mpf-ref-no-used.component';
import { IffSubmissionSuccPopupComponent } from '../declaration-iff/iff-submission-succ-popup/iff-submission-succ-popup.component';
import { ExitIffWarnPopupComponent } from '../declaration-iff/exit-iff-warn-popup/exit-iff-warn-popup.component';
import { empfCountryOption } from './empf-declaration-form.config';
import { CdsLangService } from '@cds/ng-core/lang';
import { CdHttpServeService } from 'src/app/shared/cd-http-serve/cd-http-serve.service';
import { agentCodeRemovePrefix0 } from 'src/app/utils/utils';
import { CdRadioComponent, CdRadioConfig } from 'src/app/shared/cd-radio/cd-radio.component';
import { AgentChannel } from 'src/app/core/models/enum/agent-channel.enum';
import { PreferredLanguageType, SalesJourneyProdType } from '../case-summary.model';
import { AgentChannelService } from 'src/app/core/services/agent-channel.service';
import { ValidateEmpfRefNoResponse } from '../../service/model/validate-empf-ref-no-response.model';
import { AgentSubmissionResponse } from '../../service/model/agent-submission-response.model';

export enum EmployerIdentifierEnum {
  employerName = 1,
  bRNumber = 2,
}

@Component({
  selector: 'app-declaration-iff-er',
  templateUrl: './declaration-iff-er.component.html',
  styleUrls: ['./declaration-iff-er.component.scss'],
})
export class DeclarationIffErComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('displayContent') displayContent!: ElementRef<HTMLDivElement>;
  @ViewChild('cdRadioPreferredLanguage') cdRadioPreferredLanguage!: CdRadioComponent;
  @ViewChild('cdRadioSecondAgentPreferredLanguage') cdRadioSecondAgentPreferredLanguage!: CdRadioComponent;
  @ViewChild('cdRadioThirdAgentPreferredLanguage') cdRadioThirdAgentPreferredLanguage!: CdRadioComponent;
  language = 'EN';
  public lanChangeSubscription?: Subscription;
  public validateEmpfRefNoSubscription?: Subscription;
  public agentSubmissionSubscription?: Subscription;
  public secondAgentInfoSubscription?: Subscription;
  public thirdAgentInfoSubscription?: Subscription;
  public errorSub?: Subscription;
  secondAgentCodeValueSubscription?: Subscription;
  secondAgentInfo?: AgentDetailInfo;
  thirdAgentInfo?: AgentDetailInfo;
  isScrollBottom = false;
  servicingAgentCode?: number;
  EmployerIdentifierEnum = EmployerIdentifierEnum;
  PreferredLanguageType = PreferredLanguageType;
  onScoll = () => {
    const scrollHeight = this.displayContent.nativeElement.scrollHeight;
    const clientHeight = this.displayContent.nativeElement.clientHeight;

    if (!this.isScrollBottom && this.displayContent.nativeElement.scrollTop > scrollHeight - clientHeight - 10) {
      this.isScrollBottom = true;
    }
  };

  secondAgentContactTypeSelected = '';
  secondAgentNotificationChannel = '';
  thirdAgentContactTypeSelected = '';
  thirdAgentNotificationChannel = '';
  isSplitSecondAgent = false;
  isSplitThirdAgent = false;

  rmReferralCodeVali = (control: AbstractControl): ValidationErrors | null => {
    let input = control.value || '';
    input = input.trim();
    const isValid = input.length >= 6 && input.length <= 8;
    return isValid ? null : { lengthError: true };
  };

  inputCustomerIdVali = (control: AbstractControl): ValidationErrors | null => {
    let input = control.value || '';
    input = input.trim();
    const isValid = ((input.length >= 6 && input.length <= 8) || input.length === 0) && /^[A-Za-z0-9-]*$/.exec(input);
    return isValid ? null : { lengthError: true };
  };

  sourceCodeVali = (control: AbstractControl): ValidationErrors | null => {
    let input = control.value || '';
    input = input.trim();
    const isValid = (input.length === 10 || input.length === 0) && /^[A-Za-z0-9-]*$/.exec(input);
    return isValid ? null : { lengthError: true };
  };

  campaignCodeVali = (control: AbstractControl): ValidationErrors | null => {
    let input = control.value || '';
    input = input.trim();
    const isValid = (input.length === 10 || input.length === 0) && /^[A-Za-z0-9-]*$/.exec(input);
    return isValid ? null : { lengthError: true };
  };

  dbs = {
    rmReferralCode: new FormControl('', [Validators.required, this.rmReferralCodeVali]),
    inputCustomerId: new FormControl('', [this.inputCustomerIdVali]),
    sourceCode: new FormControl('', [this.sourceCodeVali]),
    campaignCode: new FormControl('', [this.campaignCodeVali]),
  };
  preferredLanguageConfig = {
    options: [
      {
        label: 'common.chinese',
        value: PreferredLanguageType.Chinese,
        disable: true,
      },
      {
        label: 'common.english',
        value: PreferredLanguageType.English,
        disable: true,
      },
    ],
  };

  employerIdentifierControl: FormControl = new FormControl();
  employerIdentifierOptions = [
    { label: 'iff-er.employerName', value: EmployerIdentifierEnum.employerName },
    { label: 'sendIffInBatchSummary.eRNumber', value: EmployerIdentifierEnum.bRNumber },
  ];

  secondAgentPreferredLanguage?: string;
  secondAgentPreferredLanguageConfig = {
    options: [
      {
        label: 'common.chinese',
        value: PreferredLanguageType.Chinese,
        disable: true,
      },
      {
        label: 'common.english',
        value: PreferredLanguageType.English,
        disable: true,
      },
    ],
  };

  thirdAgentPreferredLanguage?: string;
  thirdAgentPreferredLanguageConfig = {
    options: [
      {
        label: 'common.chinese',
        value: PreferredLanguageType.Chinese,
        disable: true,
      },
      {
        label: 'common.english',
        value: PreferredLanguageType.English,
        disable: true,
      },
    ],
  };
  isToAgentConfirmation = false;

  currentStage = 1; // there are 5 stages now. 0: advise-empfref page; 1: declaration page; 2: iffForm page; 3: not-split-agent confirmation page; 4: split agent page; 5: split agent confirmation page
  constructor(
    private dialogRef: MatDialogRef<DeclarationIffErComponent>,
    private alert: CdsAlertService,
    public languageChangeService: LanguageChangeService,
    public iffService: IffService,
    private cdsPopup: CdsPopupService,
    private authenticationService: AuthenticationService,
    public cdsLangService: CdsLangService,
    private cdHttpServeService: CdHttpServeService,
    public agentChannelService: AgentChannelService
  ) {}

  ngAfterViewInit(): void {
    this.displayContent.nativeElement.addEventListener('scroll', this.onScoll);
  }

  ngOnDestroy(): void {
    this.errorSub?.unsubscribe();
    this.lanChangeSubscription?.unsubscribe();
    this.validateEmpfRefNoSubscription?.unsubscribe();
    this.agentSubmissionSubscription?.unsubscribe();
    this.secondAgentInfoSubscription?.unsubscribe();
    this.displayContent.nativeElement.removeEventListener('scroll', this.onScoll);
    this.secondAgentCodeValueSubscription?.unsubscribe();
    this.thirdAgentInfoSubscription?.unsubscribe();
  }
  commonButton: CdsButtonConfig = {
    size: 'md',
    style: 'primary',
  };

  commonButton2: CdsButtonConfig = {
    size: 'md',
    style: 'secondary',
    disabled: false,
  };

  iffErForm!: FormGroup;
  servicingAgentId?: string;
  multiAgentInfos?: AgentDetailInfo[];
  isSecondAgentConduct?: boolean;
  isThirdAgentConduct?: boolean;
  secondAgentRadioConfig: CdRadioConfig = {
    options: [
      {
        label: this.language === 'EN' ? 'Registered Email()' : '已登記的電郵地址（',
        value: 'E',
        disable: false,
      },
      {
        label: this.language === 'EN' ? 'Registered Mobile No.()' : '已登記的電話號碼（）',
        value: 'M',
        disable: false,
      },
    ],
  };

  secondAgentContactEmail = '';
  secondAgentContactPhone = '';
  thirdAgentContactEmail = '';
  thirdAgentContactPhone = '';
  thirdAgentRadioConfig: CdRadioConfig = {
    options: [
      {
        label: this.language === 'EN' ? 'Registered Email()' : '已登記的電郵地址（',
        value: 'E',
        disable: false,
      },
      {
        label: this.language === 'EN' ? 'Registered Mobile No.()' : '已登記的電話號碼（）',
        value: 'M',
        disable: false,
      },
    ],
  };

  agentInfoDataSubjectCallBack = (agentInfoResp: AgentInfoResponse) => {
    if (agentInfoResp && agentInfoResp.result === 0 && agentInfoResp.data && agentInfoResp.data.agentCode) {
      this.isSplitSecondAgent = true;
      this.secondAgentInfo = agentInfoResp.data;
      const secondAgentEmail = this.secondAgentInfo.emailAddress;
      const secondAgentPhoneNo = this.secondAgentInfo.mobileNumber;
      let labelOfEmail = this.language === 'EN' ? 'Registered Email' : '已登記的電郵地址';
      let labelOfPhone = this.language === 'EN' ? 'Registered Mobile No.' : '已登記的電話號碼';
      if (secondAgentEmail) {
        const charIndex = secondAgentEmail.indexOf('@');
        const firstPart = secondAgentEmail.substring(0, charIndex);
        const secondPart = secondAgentEmail.substring(charIndex + 1);
        const revisedEmail = firstPart.substring(0, 4) + '******' + '@' + secondPart;
        labelOfEmail = this.language === 'EN' ? 'Registered Email(' + revisedEmail + ')' : '已登記的電郵地址（' + revisedEmail + '）';
      }
      if (secondAgentPhoneNo) {
        const phoneNoMasked = secondAgentPhoneNo.substring(0, 4) + '****';
        labelOfPhone = this.language === 'EN' ? 'Registered Mobile No.(' + phoneNoMasked + ')' : '已登記的電話號碼（' + phoneNoMasked + '）';
      }

      this.secondAgentContactEmail = labelOfEmail;
      this.secondAgentContactPhone = labelOfPhone;
      this.secondAgentRadioConfig = {
        options: [
          { label: labelOfEmail, value: 'E', disable: !secondAgentEmail },
          { label: labelOfPhone, value: 'M', disable: !secondAgentPhoneNo },
        ],
      };
      this.secondAgentContactTypeSelected = labelOfEmail;
      if (secondAgentEmail) {
        this.secondAgentNotificationChannel = 'E';
      } else if (secondAgentPhoneNo) {
        this.secondAgentNotificationChannel = 'M';
      }

      // if split second agent code is valid, then try to validate third agent code if inputted
      const secondAgentCode = this.secondAgentCode.value;
      const thirdAgentCode = this.thirdAgentCode.value;
      if (thirdAgentCode) {
        if (this.servicingAgentCode === thirdAgentCode || thirdAgentCode === secondAgentCode) {
          this.thirdAgentCode.setErrors({ invalidSecondAgentCode: true });
          return;
        }

        this.cdHttpServeService
          .post<{
            agentCode: string;
            name: string;
            chineseName: string;
            errorCode?: number;
          }>('/ext/eb-ssms/customer-service/add-delegation/validate', {
            agentCode: agentCodeRemovePrefix0(String(thirdAgentCode)),
          })
          .subscribe({
            next: res => {
              if (res.result === 0) {
                this.iffService.getThirdAgentInfo(String(thirdAgentCode));
              } else {
                this.thirdAgentCode.markAsTouched();
                this.thirdAgentCode.setErrors({ invalidSplitAgentCode: true });
              }
            },
            error: err => {
              this.alert.error('Error!', err);
            },
          });
      } else {
        // if third agent code not inputted
        this.currentStage = 4;
        this.onSecondAgentContactTypeChange(this.secondAgentNotificationChannel);
        // we should reset some variables(may used before)
        this.isSplitThirdAgent = false;
        this.thirdAgentInfo = undefined;
        if (!this.conductedRegulatorActivityFlag.value) {
          this.isSecondAgentConduct = false;
          this.isThirdAgentConduct = false;
        } else {
          this.isSecondAgentConduct = undefined;
          this.isThirdAgentConduct = undefined;
        }
      }
    } else {
      this.secondAgentCode.markAsTouched();
      this.secondAgentCode.setErrors({ invalidSecondAgentCode: true });
    }
  };

  thirdAgentInfoDataSubjectCallBack = (agentInfoResp: AgentInfoResponse) => {
    if (agentInfoResp && agentInfoResp.result === 0 && agentInfoResp.data && agentInfoResp.data.agentCode) {
      this.isSplitThirdAgent = true;
      this.thirdAgentInfo = agentInfoResp.data;
      const thirdAgentInfoTmp = agentInfoResp.data;
      const thirdAgentEmail = thirdAgentInfoTmp.emailAddress;
      const thirdAgentPhoneNo = thirdAgentInfoTmp.mobileNumber;
      let labelOfEmail = this.language === 'EN' ? 'Registered Email' : '已登記的電郵地址';

      let labelOfPhone = this.language === 'EN' ? 'Registered Mobile No.' : '已登記的電話號碼';
      if (thirdAgentEmail) {
        const charIndex = thirdAgentEmail.indexOf('@');
        const firstPart = thirdAgentEmail.substring(0, charIndex);
        const secondPart = thirdAgentEmail.substring(charIndex + 1);
        const revisedEmail = firstPart.substring(0, 4) + '******' + '@' + secondPart;
        labelOfEmail = this.language === 'EN' ? 'Registered Email(' + revisedEmail + ')' : '已登記的電郵地址（' + revisedEmail + '）';
      }
      if (thirdAgentPhoneNo) {
        const phoneNoMasked = thirdAgentPhoneNo.substring(0, 4) + '****';
        labelOfPhone = this.language === 'EN' ? 'Registered Mobile No.(' + phoneNoMasked + ')' : '已登記的電話號碼（' + phoneNoMasked + '）';
      }
      this.thirdAgentContactEmail = labelOfEmail;
      this.thirdAgentContactPhone = labelOfPhone;
      this.thirdAgentRadioConfig = {
        options: [
          { label: labelOfEmail, value: 'E', disable: !thirdAgentEmail },
          { label: labelOfPhone, value: 'M', disable: !thirdAgentPhoneNo },
        ],
      };
      this.thirdAgentContactTypeSelected = labelOfEmail;

      // navigate to stage 4
      this.currentStage = 4;

      // reset to intial state
      if (!this.conductedRegulatorActivityFlag.value) {
        this.isSecondAgentConduct = false;
        this.isThirdAgentConduct = false;
      } else {
        this.isSecondAgentConduct = undefined;
        this.isThirdAgentConduct = undefined;
      }

      if (thirdAgentEmail) {
        this.thirdAgentNotificationChannel = 'E';
      } else if (thirdAgentPhoneNo) {
        this.thirdAgentNotificationChannel = 'M';
      }
      this.onSecondAgentContactTypeChange(this.secondAgentNotificationChannel);
      this.onThirdAgentContactTypeChange(this.thirdAgentNotificationChannel);
    } else {
      this.thirdAgentCode.markAsTouched();
      this.thirdAgentCode.setErrors({ invalidThirdAgentCode: true });
    }
  };

  validateEmpfRefNoSubjectCallBack = (resp: ValidateEmpfRefNoResponse) => {
    if (resp && resp.result === 0 && resp.data) {
      // the eMPF Ref No. have been submitted
      if (!resp.data.empfRefNoStatus) {
        const dialogRefEMPFRefNoUsed: MatDialogRef<EMPFRefNoUsedComponent> = this.cdsPopup.open(EMPFRefNoUsedComponent, {
          size: 'md',
        });
        dialogRefEMPFRefNoUsed.afterClosed().subscribe(data => {
          if (data.agree) {
            this.currentStage = 2;
            this.eMPFReferenceNo.markAsTouched();
            this.eMPFReferenceNo.setErrors({ usedEMPFRefNo: true });
          }
        });
        this.isSplitAgentFine = false;
      } else {
        // not-used eMPF Ref No, then call be API
        const agentInfoArr = [];
        const agentInfo = new AgentInfo();
        agentInfo.agentIdentityId = this.servicingAgentId;
        const isRegulatedAct = this.conductedRegulatorActivityFlag.value;
        agentInfo.regulatedAct = isRegulatedAct === null || isRegulatedAct === undefined ? false : isRegulatedAct;
        agentInfo.agentNotificationChannel = '';
        agentInfo.primaryAgent = true;
        agentInfoArr.push(agentInfo);
        const agentSubmissionRequest = new AgentSubmissionRequest();
        agentSubmissionRequest.empfRefNo = this.eMPFReferenceNo.value;
        // agentSubmissionRequest.firstNm =
        //   this.iffErForm.get('givenName')?.value;
        // agentSubmissionRequest.lastNm =
        //   this.iffErForm.get('surname')?.value;
        agentSubmissionRequest.channel = this.contactType.value;
        agentSubmissionRequest.countryCallingCode = this.countryCode?.value;
        agentSubmissionRequest.mobileNo = this.phoneNo?.value;
        agentSubmissionRequest.emailAddr = this.email?.value;
        agentSubmissionRequest.caseSplit = false;

        if (this.employerIdentifierControl.value === EmployerIdentifierEnum.employerName) {
          if (new RegExp('^[a-zA-Z\x20]*$').test(this.employerNameOrBrNo.value)) {
            agentSubmissionRequest.companyName = this.employerNameOrBrNo.value;
          } else {
            agentSubmissionRequest.companyNameChn = this.employerNameOrBrNo.value;
          }
        } else if (this.employerIdentifierControl.value === EmployerIdentifierEnum.bRNumber) {
          agentSubmissionRequest.businessRefNo = this.employerNameOrBrNo.value;
        }

        if (this.isSplitAgentFine) {
          const agentInfo2 = new AgentInfo();
          agentInfo2.agentid = this.secondAgentInfo?.agentId;
          agentInfo2.regulatedAct = this.isSecondAgentConduct === null || this.isSecondAgentConduct === undefined ? false : this.isSecondAgentConduct;
          agentInfo2.agentNotificationChannel = this.secondAgentNotificationChannel;
          agentInfo2.primaryAgent = false;
          if (agentInfo2.regulatedAct && this.secondAgentNotificationChannel === 'M') {
            agentInfo2.smsLanguage = this.secondAgentPreferredLanguage === PreferredLanguageType.English ? 0 : 1;
          }
          agentInfoArr.push(agentInfo2);

          // if user inputs third agent
          if (this.thirdAgentInfo) {
            const agentInfo3 = new AgentInfo();
            agentInfo3.agentid = this.thirdAgentInfo.agentId;
            agentInfo3.regulatedAct = this.isThirdAgentConduct === null || this.isThirdAgentConduct === undefined ? false : this.isThirdAgentConduct;
            agentInfo3.agentNotificationChannel = this.thirdAgentNotificationChannel;
            agentInfo3.primaryAgent = false;
            if (agentInfo3.regulatedAct && this.thirdAgentNotificationChannel === 'M') {
              agentInfo3.smsLanguage = this.thirdAgentPreferredLanguage === PreferredLanguageType.English ? 0 : 1;
            }
            agentInfoArr.push(agentInfo3);
          }

          agentSubmissionRequest.caseSplit = true;
        }
        agentSubmissionRequest.agentInfos = agentInfoArr;
        agentSubmissionRequest.domainType = SalesJourneyProdType.Corperate;
        agentSubmissionRequest.productType = SalesJourneyProdType.Corperate;

        if (this.isChannelDBS) {
          agentSubmissionRequest.dbs = {
            dbsRmReferralCd: this.dbs.rmReferralCode.value,
            dbsCustomerId: this.dbs.inputCustomerId.value,
            dbsCampaignCd: this.dbs.campaignCode.value,
            dbsSourceCd: this.dbs.sourceCode.value,
          };
        }

        if (agentSubmissionRequest.channel === 'M') {
          agentSubmissionRequest.smsLanguage = this.preferredLanguage.value === PreferredLanguageType.English ? 0 : 1;
        }

        this.iffService.agentSubmission(agentSubmissionRequest);
      }
    }
  };

  agentSubmissionSubjectCallBack = (resp: AgentSubmissionResponse) => {
    if (resp && resp.result === 0) {
      // not split case, iff submission success
      let msg = '';
      if (this.isToAgentConfirmation) {
        msg = 'sendIffInBatchSummary.popupScreenTitleForSplitAgent';
      } else {
        msg = 'sendIffInBatchSummary.popupScreenTitleCustomer';
      }
      const iffSubmissionSuccDialogRef: MatDialogRef<IffSubmissionSuccPopupComponent> = this.cdsPopup.open(IffSubmissionSuccPopupComponent, {
        size: 'md',
        data: {
          message: this.cdsLangService.translate(msg),
        },
      });
      iffSubmissionSuccDialogRef.afterClosed().subscribe(data => {
        if (data.agree) {
          this.dialogRef.close({ refresh: true });
        }
      });
    }
  };

  ngOnInit(): void {
    this.lanChangeSubscription = this.languageChangeService.lanSubject.subscribe(lan => {
      this.language = lan === 'en' ? 'EN' : 'TC';
    });

    this.errorSub = this.iffService.errorIffSubject.subscribe(errorMessage => {
      const alertTitle = 'Got Error!';
      this.alert.error(alertTitle, errorMessage);
    });

    this.secondAgentInfoSubscription = this.iffService.agentInfoDataSubject.subscribe(this.agentInfoDataSubjectCallBack);

    this.thirdAgentInfoSubscription = this.iffService.thirdAgentInfoDataSubject.subscribe(this.thirdAgentInfoDataSubjectCallBack);

    this.authenticationService.currentUserValue().then(res => {
      this.servicingAgentId = res.id + '';

      this.iffService.getAgentCode(this.servicingAgentId).subscribe({
        next: res => {
          if (res.result !== 0) {
            this.alert.warning('Warning!', `${res.message}`);
          } else {
            this.servicingAgentCode = +res.data.agentCode;
          }
        },
        error: err => {
          this.alert.error('Error!', err);
        },
      });
    });

    this.validateEmpfRefNoSubscription = this.iffService.validateEmpfRefNoSubject.subscribe(this.validateEmpfRefNoSubjectCallBack);

    this.agentSubmissionSubscription = this.iffService.agentSubmissionSubject.subscribe(this.agentSubmissionSubjectCallBack);
    this.iffErForm = new FormGroup(
      {
        employerNameOrBrNo: new FormControl(null, [Validators.required]),
        eMPFReferenceNo: new FormControl(null, [Validators.required, Validators.maxLength(50)]),
        conductedRegulatorActivityFlag: new FormControl(null, [Validators.required, Validators.maxLength(200)]),
        contactType: new FormControl(null, [Validators.required]),
        preferredLanguage: new FormControl(null, [this.preferredLanguageVali]),
        secondAgentCode: new FormControl(null, Validators.maxLength(6)),
        thirdAgentCode: new FormControl({ value: '', disabled: true }, Validators.maxLength(6)),
        isIntragroupTransfer: new FormControl({ value: false, disabled: false }),
      },
      this.countryCodeAndPhoneNoUnionValidate.bind(this)
      //{validators: this.countryCodeAndPhoneNoUnionValidate}
    );

    this.secondAgentCodeValueSubscription = this.secondAgentCode.valueChanges.subscribe(value => {
      if (value) {
        this.thirdAgentCode.enable();
      } else {
        this.thirdAgentCode.disable();
      }
    });
  }

  get isIntragroupTransfer() {
    return this.iffErForm.get('isIntragroupTransfer') as FormControl;
  }

  get contactType() {
    return this.iffErForm?.get('contactType') as FormControl;
  }

  preferredLanguageVali = (control: AbstractControl): ValidationErrors | null => {
    if ((this.contactType?.value === 'M' && control.value) || this.contactType?.value === 'E' || !this.contactType?.value) {
      return null;
    }
    return { formatError: true };
  };

  get employerNameOrBrNo() {
    return this.iffErForm.get('employerNameOrBrNo') as FormControl;
  }

  get employerNameOrBrNoControlRegExpLimit() {
    if (this.employerIdentifierControl.value === EmployerIdentifierEnum.employerName) {
      return '^[\u4E00-\u9FA5\x20]*$|^[a-zA-Z\x20]*$';
    } else if (this.employerIdentifierControl.value === EmployerIdentifierEnum.bRNumber) {
      return '^[A-Za-z0-9]*$';
    }
    return '';
  }

  employerIdentifierControlChange() {
    this.employerNameOrBrNo.setValue(null);
    this.employerNameOrBrNo.markAsUntouched();
  }

  get employerNameOrBrNoControlPlaceholder() {
    if (this.employerIdentifierControl.value === EmployerIdentifierEnum.employerName) {
      return 'iff-er.pleaseInputEmployerName';
    } else if (this.employerIdentifierControl.value === EmployerIdentifierEnum.bRNumber) {
      return 'iff-er.pleaseInputBRNumber';
    }
    return '';
  }

  toIffErForm() {
    if (!this.isScrollBottom) {
      return;
    }

    this.currentStage = 2;
  }

  countrycodeConfig: CdsDropdownConfig = {
    options: empfCountryOption,
    placeholder: '+852 (HK)',
    color: 'ml',
  };
  iconConfig: CdsIconConfig = {
    size: 'sm',
    color: 'default',
  };
  infoIcon = ActionIcons.info_1;

  get preferredLanguage() {
    return this.iffErForm.get('preferredLanguage') as FormControl;
  }

  resetIffErForm() {
    this.iffErForm.reset();
    // this.iffErForm.get('eMPFReferenceNo')?.setValue(null);
    // this.iffErForm.get('lastName')?.reset();
    // this.iffErForm.get('firstName')?.reset();
    // this.iffErForm.get('contactType')?.reset();
    // this.iffErForm.get('email')?.reset();
    // this.iffErForm.get('phoneNo')?.reset();
    this.preferredLanguage.reset();
    this.preferredLanguageConfig = {
      options: [
        {
          label: 'common.chinese',
          value: PreferredLanguageType.Chinese,
          disable: true,
        },
        {
          label: 'common.english',
          value: PreferredLanguageType.English,
          disable: true,
        },
      ],
    };
    this.cdRadioPreferredLanguage.refreshConfig();

    this.dbs.rmReferralCode.reset('');
    this.dbs.sourceCode.reset('');
    this.dbs.campaignCode.reset('');
    this.dbs.inputCustomerId.reset('');
    this.employerIdentifierControl.reset(null);
  }

  onClickInfoIcon() {
    this.currentStage = 0;
  }

  dbsInputHasErrors() {
    if (!this.isChannelDBS) {
      return false;
    }
    return (
      (this.dbs.rmReferralCode && this.dbs.rmReferralCode.dirty && !!this.dbs.rmReferralCode.errors) ||
      (this.dbs.campaignCode && this.dbs.campaignCode.dirty && !!this.dbs.campaignCode.errors) ||
      (this.dbs.sourceCode && this.dbs.sourceCode.dirty && !!this.dbs.sourceCode.errors) ||
      (this.dbs.inputCustomerId && this.dbs.inputCustomerId.dirty && !!this.dbs.inputCustomerId.errors)
    );
  }

  closeCurrentPopup() {
    if (this.currentStage === 1) {
      this.dialogRef.close();
    } else {
      const exitIffWarnDialogRef: MatDialogRef<ExitIffWarnPopupComponent> = this.cdsPopup.open(ExitIffWarnPopupComponent, {
        size: 'lg',
      });
      exitIffWarnDialogRef.afterClosed().subscribe(data => {
        if (data.agree) {
          this.dialogRef.close();
        }
      });
    }
  }

  inputBlur(e: string) {
    const eControl = this.iffErForm.get(e);
    if (eControl && eControl.value != null) {
      const temp = eControl.value + '';
      const value = temp.replace(/[\u4e00-\u9fa5]/gi, '');
      eControl.setValue(value.trim());
    }
  }

  get eMPFReferenceNo() {
    return this.iffErForm.get('eMPFReferenceNo') as FormControl;
  }

  get secondAgentCode() {
    return this.iffErForm.get('secondAgentCode') as FormControl;
  }

  get thirdAgentCode() {
    return this.iffErForm.get('thirdAgentCode') as FormControl;
  }

  onIffPartSubmit() {
    this.secondAgentNotificationChannel = '';
    this.thirdAgentNotificationChannel = '';
    this.eMPFReferenceNo.markAsTouched();
    this.employerNameOrBrNo.markAsTouched();
    this.contactType.markAsTouched();
    this.preferredLanguage.markAsTouched();
    this.conductedRegulatorActivityFlag.markAsTouched();
    const eMPFReferenceNo = this.eMPFReferenceNo.value;
    const contactType = this.contactType.value;
    if (contactType === 'E') {
      this.email?.markAsTouched();
    } else if (contactType === 'M') {
      this.phoneNo?.markAsTouched();
    } else {
      this.contactType.markAsTouched();
    }
    const validateEmpfRefNoRequest = new ValidateEmpfRefNoRequest();
    validateEmpfRefNoRequest.empfRefNo = eMPFReferenceNo;

    if (this.isChannelDBS) {
      if (this.dbs.rmReferralCode.errors) {
        this.dbs.rmReferralCode.markAsDirty();
        return;
      }
    }
    if (this.dbsInputHasErrors()) {
      return;
    }

    if (this.iffErForm.status === 'VALID') {
      const eMPFReferenceNo = this.eMPFReferenceNo.value;
      const validateEmpfRefNoRequest = new ValidateEmpfRefNoRequest();
      validateEmpfRefNoRequest.empfRefNo = eMPFReferenceNo;
      validateEmpfRefNoRequest.clientType = 'employer';
      this.cdHttpServeService
        .post<{ empfRefNoStatus: boolean }>('/ext/eb-ssms-sales-journey-service/generateGeneralLink/validateEmpfRefNo/flow2', validateEmpfRefNoRequest)
        .subscribe({
          next: res => {
            if (res.result === 0) {
              if (!res.data.empfRefNoStatus) {
                const dialogRefEMPFRefNoUsed: MatDialogRef<EMPFRefNoUsedComponent> = this.cdsPopup.open(EMPFRefNoUsedComponent, {
                  size: 'md',
                });
                dialogRefEMPFRefNoUsed.afterClosed().subscribe(data => {
                  if (data.agree) {
                    this.currentStage = 2;
                    this.eMPFReferenceNo.markAsTouched();
                    this.eMPFReferenceNo.setErrors({ usedEMPFRefNo: true });
                  }
                });
                this.isSplitAgentFine = false;
              } else {
                const secondAgentCode = this.secondAgentCode.value;
                if (secondAgentCode) {
                  if (this.servicingAgentCode === secondAgentCode) {
                    this.secondAgentCode.setErrors({ invalidSecondAgentCode: true });
                    return;
                  }

                  this.cdHttpServeService
                    .post<{
                      agentCode: string;
                      name: string;
                      chineseName: string;
                      errorCode?: number;
                    }>('/ext/eb-ssms/customer-service/add-delegation/validate', {
                      agentCode: agentCodeRemovePrefix0(String(secondAgentCode)),
                    })
                    .subscribe({
                      next: res => {
                        if (res.result === 0) {
                          this.iffService.getAgentInfo(String(secondAgentCode));
                        } else {
                          this.secondAgentCode.markAsTouched();
                          this.secondAgentCode.setErrors({ invalidSplitAgentCode: true });
                        }
                      },
                      error: err => {
                        this.alert.error('Error!', err);
                      },
                    });
                } else {
                  this.currentStage = 3;
                  this.isIntragroupTransfer.disable();
                  this.isSplitSecondAgent = false;
                  this.secondAgentInfo = undefined;
                  if (!this.conductedRegulatorActivityFlag.value) {
                    this.isSecondAgentConduct = false;
                  } else {
                    this.isSecondAgentConduct = undefined;
                  }
                }
              }
            } else {
              this.alert.error('Error!', res.message);
            }
          },
          error: err => {
            this.alert.error('Error!', err);
          },
        });
    }
  }

  //async validator for hk phone no
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  hkPhoneNoValidateAsync(formControl: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return new Promise<any>(resolve => {
      const hkPhoneNo: number = formControl.value;
      if (!this.isValidHKPhoneNo(hkPhoneNo)) {
        resolve({ invalidPhoneNo: true });
      } else {
        resolve(null);
      }
    });
  }

  hkPhoneNoValidate(formControl: AbstractControl): ValidationErrors | null | Observable<ValidationErrors | null> {
    const hkPhoneNo: number = formControl.value;
    if (!this.isValidHKPhoneNo(hkPhoneNo)) {
      return { invalidPhoneNo: true };
    } else {
      return null;
    }
  }

  isValidHKPhoneNo(num: number) {
    if (/^[4-9]\d{7}$/.test(num + '')) {
      return true;
    }
    return false;
  }

  isValidChiPhoneNo(num: number) {
    if (/^[1]\d{10}$/.test(num + '')) {
      return true;
    }
    return false;
  }

  countryCodeAndPhoneNoUnionValidate(formGroup: AbstractControl): ValidationErrors | null | Observable<ValidationErrors | null> {
    const contactType = formGroup.get('contactType')?.value;
    if (contactType === 'M') {
      const countryCode = formGroup.get('countryCode')?.value;
      const phoneNo = formGroup.get('phoneNo')?.value;
      // phoneNo type is number, need to change to string type here
      if (phoneNo === null || phoneNo === undefined) {
        return { required: true };
      } else if ((countryCode === '86' && !this.isValidChiPhoneNo(phoneNo)) || (countryCode === '852' && !this.isValidHKPhoneNo(phoneNo))) {
        return { invalidPhoneNo: true };
      }
    }
    return null;
  }

  emailFormControl?: FormControl;

  onContactTypeChange(val: string) {
    this.preferredLanguage.setValue(undefined);
    if (val === 'E') {
      this.iffErForm.removeControl('countryCode');
      this.iffErForm.removeControl('phoneNo');
      this.emailFormControl = new FormControl(null, [Validators.required, Validators.email]);
      this.iffErForm.addControl('email', this.emailFormControl);
      this.preferredLanguageConfig = {
        options: [
          {
            label: 'common.chinese',
            value: PreferredLanguageType.Chinese,
            disable: true,
          },
          {
            label: 'common.english',
            value: PreferredLanguageType.English,
            disable: true,
          },
        ],
      };
    } else if (val === 'M') {
      this.iffErForm.removeControl('email');
      const countryCodeFormControl = new FormControl(null, [Validators.required]);
      const phoneNoFormControl = new FormControl(null, [Validators.required]);
      this.iffErForm.addControl('countryCode', countryCodeFormControl);
      this.iffErForm.addControl('phoneNo', phoneNoFormControl);
      this.iffErForm.patchValue({ countryCode: '852' });
      this.preferredLanguageConfig = {
        options: [
          {
            label: 'common.chinese',
            value: PreferredLanguageType.Chinese,
            disable: false,
          },
          {
            label: 'common.english',
            value: PreferredLanguageType.English,
            disable: false,
          },
        ],
      };
    }
    this.cdRadioPreferredLanguage.refreshConfig();
  }

  attentionIcon = ActionIcons.megaphone;
  backToIffErForm() {
    this.isIntragroupTransfer.enable();
    this.currentStage = 2;
  }

  get email() {
    return this.iffErForm.get('email') as FormControl;
  }

  get phoneNo() {
    return this.iffErForm.get('phoneNo') as FormControl;
  }

  iffSubmission() {
    const contactType = this.contactType.value;
    if (contactType === 'E') {
      this.email?.markAsTouched();
    } else if (contactType === 'M') {
      this.phoneNo?.markAsTouched();
    } else {
      this.contactType.markAsTouched();
    }
    this.validateInputtedEMPFReferenceNo();
  }

  get conductedRegulatorActivityFlag() {
    return this.iffErForm.get('conductedRegulatorActivityFlag') as FormControl;
  }

  get disableIffSplitAgentContinue() {
    return (
      Boolean(
        (this.isSecondAgentConduct && this.secondAgentNotificationChannel === 'M' && !this.secondAgentPreferredLanguage) ||
          (this.isThirdAgentConduct && this.thirdAgentNotificationChannel === 'M' && !this.thirdAgentPreferredLanguage)
      ) ||
      (this.conductedRegulatorActivityFlag.value &&
        ((this.isSplitSecondAgent && this.isSecondAgentConduct === undefined) || (this.isSplitThirdAgent && this.isThirdAgentConduct === undefined)))
    );
  }

  iffSplitAgentContinue() {
    if (this.disableIffSplitAgentContinue) {
      return;
    }
    this.isIntragroupTransfer.disable();
    this.currentStage = 5;
  }

  onSecondAgentContactTypeChange(val: string) {
    this.secondAgentPreferredLanguage = undefined;
    if (val === 'E') {
      this.secondAgentContactTypeSelected = this.secondAgentContactEmail;
      this.secondAgentPreferredLanguageConfig = {
        options: [
          {
            label: 'common.chinese',
            value: PreferredLanguageType.Chinese,
            disable: true,
          },
          {
            label: 'common.english',
            value: PreferredLanguageType.English,
            disable: true,
          },
        ],
      };
    } else if (val === 'M') {
      this.secondAgentContactTypeSelected = this.secondAgentContactPhone;
      this.secondAgentPreferredLanguageConfig = {
        options: [
          {
            label: 'common.chinese',
            value: PreferredLanguageType.Chinese,
            disable: false,
          },
          {
            label: 'common.english',
            value: PreferredLanguageType.English,
            disable: false,
          },
        ],
      };
    }
    this.cdRadioSecondAgentPreferredLanguage?.refreshConfig();
  }

  onThirdAgentContactTypeChange(val: string) {
    this.thirdAgentPreferredLanguage = undefined;
    if (val === 'E') {
      this.thirdAgentContactTypeSelected = this.thirdAgentContactEmail;
      this.thirdAgentPreferredLanguageConfig = {
        options: [
          {
            label: 'common.chinese',
            value: PreferredLanguageType.Chinese,
            disable: true,
          },
          {
            label: 'common.english',
            value: PreferredLanguageType.English,
            disable: true,
          },
        ],
      };
    } else if (val === 'M') {
      this.thirdAgentContactTypeSelected = this.thirdAgentContactPhone;
      this.thirdAgentPreferredLanguageConfig = {
        options: [
          {
            label: 'common.chinese',
            value: PreferredLanguageType.Chinese,
            disable: false,
          },
          {
            label: 'common.english',
            value: PreferredLanguageType.English,
            disable: false,
          },
        ],
      };
    }
    this.cdRadioThirdAgentPreferredLanguage?.refreshConfig();
  }

  backToSplitAgentPage() {
    this.isIntragroupTransfer.enable();
    this.currentStage = 4;
  }

  get countryCode() {
    return this.iffErForm.get('countryCode') as FormControl;
  }

  validateInputtedEMPFReferenceNo() {
    this.isToAgentConfirmation = false;
    // const eMPFReferenceNo = this.iffErForm.get('eMPFReferenceNo')?.value;
    // const validateEmpfRefNoRequest = new ValidateEmpfRefNoRequest();
    // validateEmpfRefNoRequest.empfRefNo = eMPFReferenceNo;
    // this.iffService.validateEmpfRefNo(validateEmpfRefNoRequest);

    // not-used eMPF Ref No, then call be API
    const agentInfoArr = [];
    const agentInfo = new AgentInfo();
    agentInfo.agentIdentityId = this.servicingAgentId;
    const isRegulatedAct = this.conductedRegulatorActivityFlag.value;
    agentInfo.regulatedAct = isRegulatedAct === null || isRegulatedAct === undefined ? false : isRegulatedAct;
    agentInfo.agentNotificationChannel = '';
    agentInfo.primaryAgent = true;
    agentInfoArr.push(agentInfo);
    const agentSubmissionRequest = new AgentSubmissionRequest();
    agentSubmissionRequest.empfRefNo = this.eMPFReferenceNo.value;
    // agentSubmissionRequest.firstNm = this.iffErForm.get('givenName')?.value;
    // agentSubmissionRequest.lastNm = this.iffErForm.get('surname')?.value;
    agentSubmissionRequest.channel = this.contactType.value;
    agentSubmissionRequest.countryCallingCode = this.countryCode?.value;
    agentSubmissionRequest.mobileNo = this.phoneNo?.value;
    agentSubmissionRequest.emailAddr = this.email?.value;
    agentSubmissionRequest.caseSplit = false;

    if (this.employerIdentifierControl.value === EmployerIdentifierEnum.employerName) {
      if (new RegExp('^[a-zA-Z\x20]*$').test(this.employerNameOrBrNo.value)) {
        agentSubmissionRequest.companyName = this.employerNameOrBrNo.value;
      } else {
        agentSubmissionRequest.companyNameChn = this.employerNameOrBrNo.value;
      }
    } else if (this.employerIdentifierControl.value === EmployerIdentifierEnum.bRNumber) {
      agentSubmissionRequest.businessRefNo = this.employerNameOrBrNo.value;
    }

    if (this.isSplitAgentFine) {
      const agentInfo2 = new AgentInfo();
      agentInfo2.agentid = this.secondAgentInfo?.agentId;
      agentInfo2.regulatedAct = this.isSecondAgentConduct === null || this.isSecondAgentConduct === undefined ? false : this.isSecondAgentConduct;
      agentInfo2.agentNotificationChannel = this.secondAgentNotificationChannel;
      agentInfo2.primaryAgent = false;
      if (agentInfo2.regulatedAct && this.secondAgentNotificationChannel === 'M') {
        agentInfo2.smsLanguage = this.secondAgentPreferredLanguage === PreferredLanguageType.English ? 0 : 1;
      }
      agentInfoArr.push(agentInfo2);

      // if user inputs third agent
      if (this.thirdAgentInfo) {
        const agentInfo3 = new AgentInfo();
        agentInfo3.agentid = this.thirdAgentInfo.agentId;
        agentInfo3.regulatedAct = this.isThirdAgentConduct === null || this.isThirdAgentConduct === undefined ? false : this.isThirdAgentConduct;
        agentInfo3.agentNotificationChannel = this.thirdAgentNotificationChannel;
        agentInfo3.primaryAgent = false;
        if (agentInfo3.regulatedAct && this.thirdAgentNotificationChannel === 'M') {
          agentInfo3.smsLanguage = this.thirdAgentPreferredLanguage === PreferredLanguageType.English ? 0 : 1;
        }
        agentInfoArr.push(agentInfo3);
      }

      agentSubmissionRequest.caseSplit = true;
    }
    agentSubmissionRequest.agentInfos = agentInfoArr;
    agentSubmissionRequest.domainType = SalesJourneyProdType.Corperate;
    agentSubmissionRequest.productType = SalesJourneyProdType.Corperate;
    this.isToAgentConfirmation = agentInfoArr.some((info, index: number) => {
      if (index > 0) {
        return info.regulatedAct;
      }
      return false;
    });

    if (this.isChannelDBS) {
      agentSubmissionRequest.dbs = {
        dbsRmReferralCd: this.dbs.rmReferralCode.value,
        dbsCustomerId: this.dbs.inputCustomerId.value,
        dbsCampaignCd: this.dbs.campaignCode.value,
        dbsSourceCd: this.dbs.sourceCode.value,
      };
    }

    if (agentSubmissionRequest.channel === 'M') {
      agentSubmissionRequest.smsLanguage = this.preferredLanguage.value === PreferredLanguageType.English ? 0 : 1;
    }

    agentSubmissionRequest.intraGroup = this.isIntragroupTransfer.value;

    this.iffService.agentSubmission(agentSubmissionRequest);
  }

  isSplitAgentFine = false; // define if the split agent flow is successful
  iffSplitAgentSubmission() {
    this.isSplitAgentFine = true;
    this.validateInputtedEMPFReferenceNo();
  }

  gotItButton: CdsButtonConfig = {
    size: 'sm',
    style: 'primary',
    disabled: false,
  };
  backToForm() {
    this.currentStage = 2;
  }

  getAgentName(agent?: AgentDetailInfo) {
    if (!agent) {
      return '';
    }
    if (this.cdsLangService.currentLang === 'en') {
      return agent.agentName || agent.agentNameTC;
    } else {
      return agent.agentNameTC || agent.agentName;
    }
  }

  get isChannelDBS() {
    return this.agentChannelService.channel === AgentChannel.DBS;
  }

  get isDisabled() {
    if (this.agentChannelService.channel === AgentChannel.DBS) {
      return true;
    }
    if (this.agentChannelService.channel === AgentChannel.CORPORATE && this.agentChannelService.agentCode?.startsWith('400')) {
      return true;
    }
    return false;
  }
}
