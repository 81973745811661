<div class="row dropdown" [formGroup]="form" *ngIf="formReady">
  <div class="col-xs-12 l-pt-7">
    <span class="cds-h2-light">{{ 'list.serach-record' | lang }}</span>
  </div>
  <div class="col-xs-6 col-sm-3 col-md-3 col-lg-3 l-pt-4 l-pb-4">
    <cds-textfield
      formControlName="transferReferenceNo"
      [label]="'ee.transfer-reference-no' | lang"
      [placeholder]="'ee.input-transfer-reference-no' | lang"></cds-textfield>

    <div *ngIf="transferReferenceNo?.invalid && (transferReferenceNo?.dirty || transferReferenceNo?.touched)" class="l-pt-2">
      <cds-assistive-text *ngIf="transferReferenceNo?.errors?.['maxlength']" class="l-pt-2" [showIcon]="false">Max length is 15</cds-assistive-text>

      <cds-assistive-text
        *ngIf="transferReferenceNo?.errors?.['invalidNumberString']"
        class="l-pt-2"
        [showIcon]="false"
        >{{ transferReferenceNo?.errors?.['invalidNumberString'] }}</cds-assistive-text
      >
    </div>
  </div>

  <div class="col-xs-6 col-sm-3 col-md-3 col-lg-3 l-pt-4 l-pb-4">
    <cds-textfield
      formControlName="groupReferenceNo"
      [label]="'ee.group-reference-no' | lang"
      [placeholder]="'ee.input-group-reference-no' | lang"></cds-textfield>

    <div *ngIf="transferReferenceNo?.invalid && (groupReferenceNo?.dirty || groupReferenceNo?.touched)" class="l-pt-2">
      <cds-assistive-text *ngIf="groupReferenceNo?.errors?.['maxlength']" class="l-pt-2" [showIcon]="false">Max length is 15</cds-assistive-text>

      <cds-assistive-text
        *ngIf="groupReferenceNo?.errors?.['invalidNumberString']"
        class="l-pt-2"
        [showIcon]="false"
        >{{ groupReferenceNo?.errors?.['invalidNumberString'] }}</cds-assistive-text
      >
    </div>
  </div>

  <div class="col-xs-6 col-sm-3 col-md-3 col-lg-3 l-pt-4 l-pb-4">
    <cds-dropdown formControlName="statusCode" [config]="statusCodeConfig"> </cds-dropdown>
  </div>
  <div class="col-xs-6 col-sm-3 col-md-3 col-lg-3 l-pt-4 l-pb-4">
    <cds-dropdown formControlName="activityType" [config]="activityTypeConfig"></cds-dropdown>
  </div>
  <div class="col-xs-6 col-sm-3 col-md-3 col-lg-3 l-pt-4 l-pb-4">
    <cds-dropdown formControlName="fundTransactionFormType" [config]="fundTransactionFormTypeConfig"></cds-dropdown>
  </div>

  <div class="col-xs-6 col-sm-3 col-md-3 col-lg-3 l-pt-4 l-pb-4" *ngIf="showDatePicker">
    <cds-textfield
      formControlName="dateOfTransfer"
      placeholder="DD/MM/YYYY"
      label="Date of Transfer"
      [max]="nowTimeInstr"
      [cdsDatepicker]="dateOfTransferPicker">
    </cds-textfield>
    <cds-datepicker #dateOfTransferPicker="cdsDatepicker"></cds-datepicker>

    <div *ngIf="dateOfTransfer?.invalid && (dateOfTransfer?.dirty || dateOfTransfer?.touched)" class="l-pt-2">
      <cds-assistive-text *ngIf="dateOfTransfer?.errors?.['dateInvalid'] || dateOfTransfer?.errors?.['startLaterThanEnd']" class="l-pt-2" [showIcon]="false">{{
        'create.dateError' | lang
      }}</cds-assistive-text>
    </div>
  </div>

  <div class="col-xs-6 col-sm-3 col-md-3 col-lg-3 l-pt-4 l-pb-4" *ngIf="showDatePicker">
    <cds-textfield
      formControlName="originalRequestedSubmissionDate"
      placeholder="DD/MM/YYYY"
      label="Original Requested Submission Date"
      [max]="nowTimeInstr"
      [cdsDatepicker]="originalRequestedSubmissionDatePicker">
    </cds-textfield>
    <cds-datepicker #originalRequestedSubmissionDatePicker="cdsDatepicker"></cds-datepicker>

    <div
      *ngIf="originalRequestedSubmissionDate?.invalid && (originalRequestedSubmissionDate?.dirty || originalRequestedSubmissionDate?.touched)"
      class="l-pt-2">
      <cds-assistive-text
        *ngIf="originalRequestedSubmissionDate?.errors?.['dateInvalid'] || originalRequestedSubmissionDate?.errors?.['startLaterThanEnd']"
        class="l-pt-2"
        [showIcon]="false"
        >{{ 'create.dateError' | lang }}</cds-assistive-text
      >
    </div>
  </div>

  <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3 l-mt-2 l-pb-2 l-pt-2 btns">
    <cds-button
      class="l-mr-4 botton"
      [disabled]="searchButtonDisabled"
      [config]="searchButtonConfig"
      label="{{ 'list.search' | lang }}"
      (click)="search()"></cds-button>
    <cds-button class="botton" [disabled]="resetButtonDisabled" [config]="resetButtonConfig" label="{{ 'list.reset' | lang }}" (click)="reset()"></cds-button>
  </div>
</div>

<div class="row">
  <div class="col-xs-12 l-pt-7 l-pb-2">
    <span class="cds-h2-light">{{ 'list.record-list' | lang }}</span>
  </div>
  <div class="col-xs-12">
    <span class="cds-body1">Total {{ totalElements | number }} records</span>
  </div>

  <div class="col-xs-12 col-md-12">
    <div class="min-height">
      <app-data-table
        [frontPagination]="false"
        [isLoading]="false"
        [fixed]="false"
        [overlay]="true"
        [pageConfig]="pageConfig"
        [dataSource]="dataSource"
        [columnsConfig]="columnsConfig"
        (reloadTable)="reloadTable($event)">
        <ng-template appDtBodyCellDef="transferReferenceNo" let-element>
          <span (click)="goToDetail(element['transferReferenceNo'])" class="col-xs middle-xs l-plr-0">
            <span class="green-line pointer">
              <span class="cds-body1-bold">{{ element['transferReferenceNo'] }}</span>
            </span>
          </span>
        </ng-template>

        <ng-template appDtBodyCellDef="groupReferenceNo" let-element>
          <span class="cds-h5-bold font-weight-6">{{ element['groupReferenceNo'] }}</span>
        </ng-template>

        <ng-template appDtBodyCellDef="statusCode" let-element>
          <app-icon-status [value]="element.statusCode" [statusMapObj]="policyTransferStastusCodeMapObj"></app-icon-status>
        </ng-template>

        <ng-template appDtBodyCellDef="activityType" let-element>
          <span>{{ employerService.optionLabelRender | fn : element['activityType'] : activityTypeConfig.options }}</span>
        </ng-template>

        <ng-template appDtBodyCellDef="fundTransactionFormType" let-element>
          <span>{{ employerService.optionLabelRender | fn : element['fundTransactionFormType'] : fundTransactionFormTypeConfig.options }}</span>
        </ng-template>

        <ng-template appDtBodyCellDef="dateOfTransfer" let-element>
          <span>{{ element['dateOfTransfer'] | ssmsDate }}</span>
        </ng-template>

        <ng-template appDtBodyCellDef="originalRequestedSubmissionDate" let-element>
          <span>{{ originalDateRender | fn : element['originalRequestedSubmissionDate'] }}</span>
        </ng-template>
      </app-data-table>
    </div>
  </div>
</div>
