/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @angular-eslint/use-lifecycle-interface */
import { Component, ElementRef, HostListener, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { CdsDropdownConfig } from '@cds/ng-core/dropdown';
import { CdsPopupService, MatDialogRef } from '@cds/ng-web-components/popup';
import { CdsIconConfig } from '@cds/ng-core/icon';
import { IPaginationCount } from 'src/app/shared/pagination/pagination.dto';
import { FormControl, FormGroup } from '@angular/forms';
import { Subject, debounceTime, finalize } from 'rxjs';
import { ToastAlertService } from 'src/app/shared/toast-alert.service';
import { deepCopy } from 'src/app/utils/copy';
import { ContinuePopupComponent } from 'src/app/shared/continue-popup/continue-popup.component';
import { CdsLangService } from '@cds/ng-core/lang';
import { jsonToSheet } from 'src/app/utils/xlsx';
import { PermissionAccess, PermissionItem } from 'src/app/core/models/enum/permission.enum';
import { PermissionService } from 'src/app/core/services/permission.service';
import { CdMultidropdownTopping } from 'src/app/shared/cd-multidropdown/cd-multidropdown.model';
import { Sort } from '@cds/ng-data-table/sort';
import {
  getColor,
  formatReason,
  formatDate2,
  maxDate,
  minDate,
  PaymentStatusE,
  PaymentStatusSelected,
  configPrimary,
  configSecondary,
  ChequeSearchDTO,
  ChequeDTO,
  pageInit,
  I18N_KEY,
  BaseChequeSearch,
  ChequeSearch,
  PaymentMethodE,
  formatDate,
} from '../../benefit-payment';
import { ChequePrintingSaveSuccessPTWComponent } from '../cheque-printing-save-success/cheque-printing-save-success-ptw.component';
import { PaymentServicePTW } from '../../paymentPTW.service';
import { dateValidator } from 'src/app/core/validators';

@Component({
  selector: 'app-cheque-issuance-ptw',
  templateUrl: './cheque-issuance-ptw.component.html',
  styleUrls: ['./cheque-issuance-ptw.component.scss'],
})
export class ChequeIssuancePTWComponent implements OnInit {
  @ViewChild('tableBox', { static: false })
  tableBox?: ElementRef<HTMLDivElement>;

  constructor(
    private cdsPopup: CdsPopupService,
    private service: PaymentServicePTW,
    private toastAlert: ToastAlertService,
    private langService: CdsLangService,
    private permissionService: PermissionService,
    private elemRef: ElementRef
  ) {}

  PermissionAccess = PermissionAccess;
  PermissionItem = PermissionItem;

  getColor = getColor;

  formatReason = formatReason;

  formatDate = formatDate;
  formatDate2 = formatDate2;

  maxDate = maxDate;
  minDate = minDate;

  _editPaymentStatusMap = new Map([
    // [PaymentStatusE.PRINTED,{options:[{ label: 'Printed', value: PaymentStatusE.PRINTED },{ label: 'Unpresented', value: PaymentStatusE.UN_PRESENTED }]}],
    [
      PaymentStatusE.UN_PRESENTED,
      {
        options: [{ label: 'Unpresented', value: PaymentStatusE.UN_PRESENTED }],
      },
    ],
    [
      PaymentStatusE.HOLD_PAYMENT,
      {
        options: [
          { label: 'HoldPayment', value: PaymentStatusE.HOLD_PAYMENT },
          { label: 'StopPayment', value: PaymentStatusE.STOP_PAYMENT },
        ],
      },
    ],
    [
      PaymentStatusE.STOP_PAYMENT,
      {
        options: [
          { label: 'StopPayment', value: PaymentStatusE.STOP_PAYMENT },
          { label: 'ReissueComplete', value: PaymentStatusE.REISSUE_COMPLETE },
        ],
      },
    ],
  ]);

  _paymentStatusList: PaymentStatusSelected[] = [
    { label: 'Expired', value: PaymentStatusE.EXPIRED },
    { label: 'Hold Payment', value: PaymentStatusE.HOLD_PAYMENT, selected: true },
    { label: 'New', value: PaymentStatusE.NEW, selected: true },
    { label: 'Pending', value: PaymentStatusE.PENDING },
    { label: 'Presented', value: PaymentStatusE.PRESENTED },
    { label: 'Printed', value: PaymentStatusE.PRINTED },
    { label: 'Ready to Reissue', value: PaymentStatusE.READY_TO_REISSUE, selected: true },
    { label: 'Reissue', value: PaymentStatusE.REISSUE },
    { label: 'Reissue Completed', value: PaymentStatusE.REISSUE_COMPLETE },
    { label: 'Reissue Presented', value: PaymentStatusE.REISSUE_PRESENTED },
    { label: 'Reissue Reject', value: PaymentStatusE.REISSUE_REJECT },
    { label: 'Returned from EE', value: PaymentStatusE.RETURNED_FROM_EE },
    { label: 'Returned from ER', value: PaymentStatusE.RETURNED_FROM_ER },
    { label: 'Stop Payment', value: PaymentStatusE.STOP_PAYMENT },
    { label: 'Unpresented', value: PaymentStatusE.UN_PRESENTED },
  ];

  // _canCheckStatus: (string | undefined)[] = [
  //   PaymentStatusE.READY_TO_REISSUE,
  //   PaymentStatusE.NEW,
  //   PaymentStatusE.PRINTED,
  //   PaymentStatusE.HOLD_PAYMENT,
  //   PaymentStatusE.STOP_PAYMENT,
  //   PaymentStatusE.UN_PRESENTED,
  // ];
  _canEditStatus: (string | undefined)[] = [
    PaymentStatusE.NEW,
    PaymentStatusE.PRINTED,
    PaymentStatusE.READY_TO_REISSUE,
    PaymentStatusE.PRINTED,
    PaymentStatusE.UN_PRESENTED,
    PaymentStatusE.HOLD_PAYMENT,
    PaymentStatusE.STOP_PAYMENT,
  ];

  _toolBarPermission: PermissionItem[] = [PermissionItem.SHK_PAYMENT_ISSUANCE_PAYMENT_STAFF, PermissionItem.SHK_PAYMENT_ISSUANCE_PAYMENT_MANAGER];

  _reEditPermission: PermissionItem[] = [PermissionItem.SHK_PAYMENT_ISSUANCE_PAYMENT_MANAGER];
  _toolBar = false;
  _reEdit = false;

  // _permissionMap = new Map([
  //   [PermissionItem.SHK_PAYMENT_ISSUANCE_APPROVE_TO_PRINT.toString(), [PaymentStatusE.READY_TO_REISSUE, PaymentStatusE.NEW,PaymentStatusE.PRINTED,PaymentStatusE.HOLD_PAYMENT,PaymentStatusE.STOP_PAYMENT]]
  // ]);

  paymentSelectedList: CdMultidropdownTopping[] = [];

  searchButtonConfig = configPrimary();
  _searchDisable = false;

  resetButtonConfig = configSecondary();
  _resetDisable = false;

  _valid = false;

  resetEditConfig = configSecondary();

  _resetEditDisable = false;

  saveEditConfig = configPrimary();

  exportAllButtonConfig = configPrimary();

  exportSelectedButtonConfig = configSecondary();

  editSelectedButtonConfig = configPrimary();

  _stopPaymentDisable = true;
  _completePaymentDisable = true;

  notfoundConfig: CdsIconConfig = { color: '#8e90a2', size: 'md' };

  pageSelectSize: number[] = [10, 20, 50, 100];
  _pageSelected = 1;

  _totalCount = 0;
  searchDTO: ChequeSearchDTO = {
    size: this.pageSelectSize[this._pageSelected],
    page: 0,
  };
  displayedColumns: string[] = [
    'selected',
    'outChequeNo',
    'paymentStatus',
    'amt',
    'payeeName',
    'feedReceiveDate',
    'isReissue',
    'reissueReason',
    'payRefNo',
    'appRefNo',
    'exptPayIssue',
    'chequeIssueDate',
    'lastUpdateDate',
    'remarks',
  ];
  checkedDisplayedColumns: string[] = [
    'outChequeNo',
    'paymentStatus',
    'amt',
    'payeeName',
    'feedReceiveDate',
    'isReissue',
    'reissueReason',
    'payRefNo',
    'appRefNo',
    'exptPayIssue',
    'chequeIssueDate',
    'lastUpdateDate',
    'remarks',
  ];
  dataSource: ChequeDTO[] = [];
  originData: ChequeDTO[] = [];

  checkedDatasource: ChequeDTO[] = [];
  _checkedDatasourceMap: Map<string, ChequeDTO> = new Map();

  OriginCheckedDatasource: ChequeDTO[] = [];

  sheetHeader = {
    outChequeNo: 'Cheque No.',
    recordStatus: 'Payment Status',
    curr: 'Currency',
    amt: 'Transaction Amount',
    payeeName: 'Payee Name',
    feedReceiveDate: 'Payment Instruction File Date',
    isReissue: 'Reissuance',
    reissueReason: 'Reissurance Reason',
    payRefNo: 'Payment Ref No.',
    appRefNo: 'Application Reference No.',
    exptPayIssue: 'Expected Payment Issue Date on Statement',
    chequeIssueDate: 'Cheque Issue Date',
    lastUpdateDate: 'Status Update Date',
    remarks: 'Remarks',
  };

  isMobile = false;

  searchButtonDisabled = false;
  resetButtonDisabled = false;
  exportSelectedDisabled = true;
  editSelectedDisabled = false;
  exportMailMerge = true;

  _selectedAll = false;
  _edited = false;
  _confirmed = false;

  _inputNumber = 1;
  _progress = false;
  _downloading = false;
  scrollBarWidth = '15px';

  pageCount: IPaginationCount = pageInit();

  transactionAmount?: number = 0;

  I18N_KEY = I18N_KEY;

  _saveDisable = true;
  private search$ = new Subject<ChequeDTO>();

  form = new FormGroup({
    chequeIssueDateFrom: new FormControl('', [dateValidator()]),
    chequeIssueDateTo: new FormControl('', [dateValidator()]),
    outChequeNo: new FormControl('', []),
  });

  @Input() baseParam?: BaseChequeSearch;
  @Input() search?: number;

  _sortString = '';

  // chequeIssueDateFrom() {

  //   return this.searchDTO.chequeIssueDateFrom;
  // }

  // get chequeIssueDateTo() {
  //   const control = this.form?.get('chequeIssueDateTo');
  //   if (control) {
  //     return control.value;
  //   }
  //   return '';
  // }

  numberBetween(end: number, start?: number): number {
    start = start || 1;
    return Math.round(Math.random() * end) + start;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['search']) {
      this.pageCount = pageInit();
      if (Number(changes['search'].currentValue) > 0) {
        this._paymentStatusList.forEach(x => (x.selected = false));
      }
      this.loadTable();
    }
  }

  ngOnInit(): void {
    this.onResize();
    this._toolBarPermission.forEach(x => {
      this.permissionService.hasPermission(PermissionAccess.R, x).then(has => {
        this._toolBar = this._toolBar || has;
      });
    });
    this._reEditPermission.forEach(x => {
      this.permissionService.hasPermission(PermissionAccess.R, x).then(has => {
        this._reEdit = this._reEdit || has;
      });
    });

    // chq 输入防抖
    this.search$.pipe(debounceTime(300)).subscribe(item => {
      this.outChequeNoChange(item);
    });

    this.initForm();
    this.loadTable();
  }

  async validFn() {
    return this._valid && this.form.valid;
  }

  onSortChange(event: Sort) {
    if (event.direction) {
      this._sortString = event.active + ',' + event.direction;
    } else {
      this._sortString = '';
    }
    this.loadTable();
  }

  resetStatus() {
    this._paymentStatusList.forEach(x => (x.selected = false));
    this.loadTable();
  }

  searchFn() {
    this.validFn().then(r => {
      if (r) {
        this.pageCount = pageInit();
        this._paymentStatusList.forEach(x => (x.selected = false));
        this.loadTable();
      }
    });
  }

  outChqValidate(item: ChequeDTO) {
    if (item.canInputChequeNo && !item.outChequeNo) {
      item.cheqNoError = 'Please input the cheque no.';
    }
  }

  chqInput(item: ChequeDTO, event: any) {
    item.outChequeNo = event.target.value;
    this.search$.next(item);
  }

  outChequeNoChange(item: ChequeDTO) {
    if (!item.canInputChequeNo) {
      this._saveDisable = false;
      return;
    }
    if (item.outChequeNo == undefined || item.outChequeNo == '') {
      this._saveDisable = true;
      item.cheqNoError = 'Please input the cheque no.';
      return;
    }
    this._saveDisable = true;
    item.cheqNoError = 'checking...';
    const ex = this.checkedDatasource.find(x => x.id != item.id && x.outChequeNo == item.outChequeNo) != undefined;
    if (ex) {
      this._saveDisable = true;
      item.cheqNoError = 'Cheque No. allready existed';
      return;
    }
    return this.service
      .getByChqNo(item.outChequeNo)
      .pipe(
        finalize(() => {
          // this._progress = false;
        })
      )
      .subscribe(data => {
        if (data.result === 0 && data.data != null && item.id != data.data.id) {
          item.cheqNoError = 'Cheque No. allready existed';
        } else {
          item.cheqNoError = undefined;
        }
        this._saveConfigValidate();
      });
  }

  loadTable() {
    const params: ChequeSearch = this.getRequestParams();
    const listParms: ChequeSearchDTO = {
      // ...this.searchDTO,
      size: this.pageSelectSize[this._pageSelected],
      ...params,
      page: this.pageCount.current - 1,
      sort: this._sortString,
    };
    this.getAmount(params);
    this.getList(listParms);
  }

  getList(params: ChequeSearchDTO) {
    this._progress = true;
    this.service
      .getPaymentIssuance(params)
      .pipe(
        finalize(() => {
          this._progress = false;
        })
      )
      .subscribe(data => {
        if (data.result === 0 && data.data) {
          this._selectedAll = false;
          this.dataSource = data.data.empty ? [] : data.data.content;
          // this.dataSource.forEach(item => {
          //   item.opsPermission = this._canCheckStatus.filter(x => item.recordStatus == x).length > 0;
          // });
          this.originData = deepCopy(this.dataSource);

          this._totalCount = data.data.totalElements;
          if (this.pageCount.current != data.data.number + 1) {
            this.pageCount.current = data.data.number + 1;
          }
          if (this.pageCount.count !== data.data.totalPages) {
            this.pageCount.count = data.data.totalPages;
          }
          this.selectButtonsDisableRefresh();
        } else {
          this.toastError(data.message);
        }
      });
  }

  getAmount(params: ChequeSearch) {
    this.service
      .getPaymentIssuanceAmount(params)
      .pipe(
        finalize(() => {
          // this._progress = false;
        })
      )
      .subscribe(data => {
        if (data.result === 0) {
          // this._progress = false;
          this.transactionAmount = data.data;
        } else {
          this.toastError(data.message);
        }
      });
  }

  getRequestParams() {
    const params: ChequeSearchDTO = deepCopy(this.searchDTO);
    params.recordStatusList = this._paymentStatusList.filter(x => x.selected).map(x => x.value);
    params.chequeIssueDateFrom = this.formatDate(params.chequeIssueDateFrom);
    params.chequeIssueDateTo = this.formatDate(params.chequeIssueDateTo);
    params.payRefNo = this.baseParam?.payRefNo;
    params.appRefNo = this.baseParam?.appRefNo;
    params.instructionFileDateFrom = this.formatDate(this.baseParam?.instructionFileDateFrom);
    params.instructionFileDateTo = this.formatDate(this.baseParam?.instructionFileDateTo);
    params.payeeName = this.baseParam?.payeeName;
    params.isReissue = this.baseParam?.isReissue;
    params.schemeRegNoList = this.baseParam?.schemeRegNoList;
    if (!params.isReissue) {
      delete params.isReissue;
    }
    if (!params.payMethod) {
      params.payMethod = PaymentMethodE.CHQ;
    }

    return params;
  }

  getPaymentStatus = (value: string) => {
    let label = '';
    if (!value) return label;
    const obj = this._paymentStatusList.find(x => x.value === value);
    label = obj?.label || '';
    return label;
  };

  // formatDate(date?: string) {
  //   if (!date) {
  //     return '';
  //   }
  //   return DateTime.fromFormat(date, 'd/M/yyyy').toFormat('yyyy-MM-dd');
  // }

  initForm() {
    // const _recordStatusList = this.paymentStatusList.filter(x => this._canCheckStatus.indexOf(x.value) > -1);
    // this.form = new FormGroup({
    //   chequePrintDateStart: new FormControl('', [dateValidator()]),
    //   chequePrintDateEnd: new FormControl('', [dateValidator()]),
    //   // Cheque Number
    //   outChequeNo: new FormControl('', []),
    //   // recordStatusList: new FormControl([..._recordStatusList]),
    //   // payMethod: new FormControl('', []),
    //   // feedReceiveDate: new FormControl('', [dateValidator()]),
    //   payeeName: new FormControl('', []),
    //   isReissue: new FormControl(),
    // });
    this.searchDTO = {
      size: this.pageSelectSize[this._pageSelected],
      page: 0,
    };
  }

  backListClick() {
    this._confirmed = false;
  }

  confirmClick() {
    this._progress = true;
    this.service
      .changePaymentIssuance(this.checkedDatasource)
      .pipe(
        finalize(() => {
          this._progress = false;
        })
      )
      .subscribe(res => {
        if (res.result === 0) {
          this.popupWithReload();
        } else {
          this.toastError(res.message);
        }
      });
  }

  updateList(status: string) {
    this._progress = true;
    const _dataList: ChequeDTO[] = [];
    this.dataSource.forEach(item => {
      if (item.checked) {
        _dataList.push(deepCopy(item));
      }
    });
    _dataList.forEach(item => {
      item.recordStatus = PaymentStatusE[status as keyof typeof PaymentStatusE];
    });
    this.service
      .changePaymentIssuance(_dataList)
      .pipe(
        finalize(() => {
          this._progress = false;
        })
      )
      .subscribe(res => {
        if (res.result === 0) {
          this.loadTable();
        } else {
          this.toastError(res.message);
        }
      });
  }

  popupWithReload() {
    const popupRef: MatDialogRef<ChequePrintingSaveSuccessPTWComponent> = this.cdsPopup.open(ChequePrintingSaveSuccessPTWComponent, {
      size: 'md',
      data: {},
    });
    popupRef.afterClosed().subscribe(() => {
      this._edited = false;
      this.searchButtonDisabled = this._edited;
      this.resetButtonDisabled = this._edited;
      this._confirmed = false;
      this._selectedAll = false;
      this.loadTable();
    });
  }

  toConfirm() {
    if (this._saveDisable) {
      return;
    }
    this._confirmed = true;
  }

  selectButtonsDisableRefresh() {
    // if (this.dataSource.some(item => item.checked)) {
    //   this.exportSelectedDisabled = false;
    // } else {
    //   this.exportSelectedDisabled = true;
    // }
    // if (this.dataSource.some(item => item.checked && item.recordStatus === PaymentStatusE.APPROVED_TO_PRINT)) {
    //   this.editSelectedDisabled = false;
    // } else {
    //   this.editSelectedDisabled = true;
    // }
    const _checkedList = this.dataSource.filter(item => item.checked);
    // if(_checkedList.length == 0){
    //   return
    // }
    this._checkedDatasourceMap = new Map();
    _checkedList.forEach(item => {
      this._checkedDatasourceMap.set(item.id, item);
    });
    const _length = _checkedList.length > 0;
    this._stopPaymentDisable = !(_length && _checkedList.filter(item => item.recordStatus == PaymentStatusE.HOLD_PAYMENT).length == _checkedList.length);
    this._completePaymentDisable = !(_length && _checkedList.filter(item => item.recordStatus == PaymentStatusE.STOP_PAYMENT).length == _checkedList.length);
    this.editSelectedDisabled = !(_length && _checkedList.filter(item => this._canEditStatus.includes(item.recordStatus)).length == _checkedList.length);
    this.exportSelectedDisabled = !_length;
    // this.exportMailMergeTrigger();
    this.exportMailMerge = _checkedList.filter(x => x.recordStatus !== PaymentStatusE.NEW && x.recordStatus !== PaymentStatusE.READY_TO_REISSUE).length > 0;
    // _temp.filter(x => x.selected && x.value !== PaymentStatusE.NEW && x.value !== PaymentStatusE.READY_TO_REISSUE).length > 0
  }

  _editToolFun() {}

  trigger(index: number) {
    this.dataSource[index].checked = !this.dataSource[index].checked;
    this.selectButtonsDisableRefresh();
  }

  triggerAll() {
    this._selectedAll = !this._selectedAll;
    this.dataSource.forEach(item => {
      item.checked = this._selectedAll;
    });
    this.selectButtonsDisableRefresh();
  }

  sizeSelect(index: number) {
    this._pageSelected = index;
    this.searchDTO.size = this.pageSelectSize[index];
    this.pageCount = pageInit();
    this.loadTable();
  }

  paymentStatusSelecte(index: number) {
    this.pageCount = pageInit();
    this._paymentStatusList[index].selected = !this._paymentStatusList[index].selected;
    // this.exportMailMergeTrigger();
    this.loadTable();
  }

  exportMailMergeTrigger() {
    const _temp = this._paymentStatusList.filter(x => x.selected);
    this.exportMailMerge =
      _temp.length == 0 || _temp.filter(x => x.selected && x.value !== PaymentStatusE.NEW && x.value !== PaymentStatusE.READY_TO_REISSUE).length > 0;
  }

  exportPdf() {
    if (this.dataSource.length === 0 || this.exportMailMerge) {
      return;
    }
    const _checkedIds = this.dataSource.filter(x => x.checked).map(x => x.id);
    this._downloading = true;
    this.service
      .exportPdf(_checkedIds)
      .pipe(
        finalize(() => {
          // this._progress = false;
          this._downloading = false;
        })
      )
      .subscribe(res => {
        if (res.result === 0 && res.data) {
          this.loadTable();
          window.open(res.data, '_blank');
        } else {
          this.toastError('export Pdf error');
        }
      });
  }

  exportAll() {
    if (this.dataSource.length === 0 || this.exportMailMerge) {
      return;
    }
    const _statusList = [PaymentStatusE.NEW, PaymentStatusE.READY_TO_REISSUE];
    const _param: ChequeSearchDTO = {
      page: 0,
      size: 12000,
      recordStatusList: _statusList,
    };
    _param.payMethod = PaymentMethodE.CHQ;
    // this._progress = true;
    this._downloading = true;
    this.service
      .getPaymentIssuance(_param)
      .pipe(
        finalize(() => {
          // this._progress = false;
          this._downloading = false;
        })
      )
      .subscribe(res => {
        if (res.result === 0 && res.data?.content) {
          jsonToSheet(
            {
              fileName: 'SHK Cheque Printing Record List',
              fileType: 'csv',
              header: this.sheetHeader,
            },
            this.createData(res.data.content)
          );

          this.printCount(res.data.content);
        } else {
          this.toastError('no ready to print data');
        }
      });
  }

  printCount(data: ChequeDTO[]) {
    const ids = data.map(item => item.id);
    this.service.paymentIssuancePrintCount(ids).subscribe(res => {
      if (res.result === 0 && res.data) {
        this.loadTable();
      } else {
        this.toastError(res.message);
      }
    });
  }

  createData(data: ChequeDTO[]) {
    return data.map(item => {
      return {
        outChequeNo: item.outChequeNo,
        recordStatus: item.recordStatus,
        curr: item.curr,
        amt: item.amt,
        payeeName: item.payeeName,
        feedReceiveDate: this.formatDate2(item.feedReceiveDate),
        isReissue: item.isReissue,
        reissueReason: this.formatReason(item.reissueReason + ''),
        payRefNo: item.payRefNo,
        appRefNo: item.appRefNo,
        exptPayIssue: this.formatDate2(item.exptPayIssue),
        chequeIssueDate: this.formatDate2(item.chequeIssueDate),
        lastUpdateDate: this.formatDate2(item.lastUpdateDate),
        remarks: item.remarks,
      };
    });
  }

  exportSelected() {
    if (this.exportSelectedDisabled) {
      return;
    }
    this._downloading = true;
    const data = this.dataSource.filter(item => item.checked);

    jsonToSheet(
      {
        fileName: 'SHK Cheque Printing Record List',
        fileType: 'csv',
        header: this.sheetHeader,
      },
      this.createData(data)
    );
    this._downloading = false;
  }

  editTrigger(isCancel = false) {
    if (this.editSelectedDisabled) {
      return;
    }
    if (isCancel) {
      if (JSON.stringify(this.checkedDatasource) !== JSON.stringify(this.OriginCheckedDatasource)) {
        this.editReset(true);
      } else {
        this.doEditReset();
        this.editStatusToggle();
      }
    } else {
      this.checkedDatasource = [];
      this.dataSource.forEach(item => {
        if (item.checked) {
          const item2 = deepCopy(item);
          // item2.recordStatus = PaymentStatusE.UN_PRESENTED;
          if (this._editPaymentStatusMap.has(item2.recordStatus)) {
            const _config = this._editPaymentStatusMap.get(item2.recordStatus);
            if (_config) {
              item2.downConfig = this._returnStatusFilter(item2.acctType, _config, item2.recordStatus);
            }
          }
          if (item2.recordStatus == PaymentStatusE.PRINTED) {
            item2.canInputChequeNo = true;
          }
          if (item2.recordStatus == PaymentStatusE.UN_PRESENTED && this._reEdit) {
            item2.canInputChequeNo = true;
          }
          this.outChqValidate(item2);
          this.checkedDatasource.push(item2);
        }
      });
      this.OriginCheckedDatasource = deepCopy(this.checkedDatasource);
      this._saveConfigValidate();
      this.editStatusToggle();
    }
  }

  _saveConfigValidate() {
    this._saveDisable = this.checkedDatasource.find(x => x.cheqNoError) != undefined;
  }

  _returnStatusFilter(type: string, config: CdsDropdownConfig, itemStatus: PaymentStatusE) {
    const _config = deepCopy(config);
    if (itemStatus == PaymentStatusE.UN_PRESENTED && type == 'EE') {
      _config.options.push({ label: 'ReturnedFromEE', value: PaymentStatusE.RETURNED_FROM_EE });
    }
    if (itemStatus == PaymentStatusE.UN_PRESENTED && type == 'ER') {
      _config.options.push({ label: 'ReturnedFromER', value: PaymentStatusE.RETURNED_FROM_ER });
    }
    return _config;
  }

  reissueChnage(val: boolean) {
    this.searchDTO.isReissue = val ? 'Y' : undefined;
    this._valid = true;
  }
  validating() {
    if (!!this.searchDTO.chequeIssueDateFrom || !!this.searchDTO.chequeIssueDateTo || !!this.searchDTO.outChequeNo) {
      this._valid = true;
    } else {
      this._valid = false;
    }
  }

  inputChange(val: string, label: string) {
    switch (label) {
      case 'outChequeNo':
        this.searchDTO.outChequeNo = val;
        break;
      case 'payeeName':
        this.searchDTO.payeeName = val;
        break;
    }
    this.validating();
  }

  editReset(cancelEdit = false) {
    this.cdsPopup
      .open(ContinuePopupComponent, {
        size: 'sm',
        data: {
          message: this.langService.translate('common.action.cancel'),
          cancelButtonName: 'Back',
        },
      })
      .afterClosed()
      .subscribe(result => {
        if (result?.agree) {
          this.doEditReset();
          if (cancelEdit) {
            this.editStatusToggle();
          }
        }
      });
  }

  editStatusToggle() {
    this._edited = !this._edited;
    this.searchButtonDisabled = this._edited;
    this.resetButtonDisabled = this._edited;
  }

  doEditReset() {
    this.checkedDatasource = deepCopy(this.OriginCheckedDatasource);
    this._confirmed = false;
    this._saveConfigValidate();
  }

  reset() {
    this.initForm();
    this.form?.markAsUntouched();
    this.form?.markAsPristine();
    this.pageCount = pageInit();
    this.validating();
    this.loadTable();
  }

  prefill(value: string) {
    this.checkedDatasource = this.checkedDatasource.map((item, index) => {
      let zeroCount = 0;
      for (let i = 0; i < value.length; i++) {
        if (value[i] === '0') {
          zeroCount++;
        } else {
          break;
        }
      }
      if (item.canInputChequeNo) {
        item.outChequeNo = '0'.repeat(zeroCount) + (Number(value) + index);
        this.outChequeNoChange(item);
      }
      return item;
    });
  }

  toastError(errorMessage: string) {
    this.toastAlert.show('error', 'common.error', errorMessage, 5000);
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    if (window.innerWidth < 1024) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
    const contentWidth = this.elemRef.nativeElement.getElementsByClassName('row')[0].offsetWidth;
    const screenWidth = document.body.clientWidth;

    this.scrollBarWidth = screenWidth - contentWidth - (this.isMobile ? 0 : 80) + 'px';
  }

  get checkIsHaveScroll() {
    if (this.tableBox) {
      return this.tableBox.nativeElement.scrollWidth > this.tableBox.nativeElement.clientWidth;
    }
    return false;
  }
}
