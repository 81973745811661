import { AgentChannel } from 'src/app/core/models/enum/agent-channel.enum';
import { CommissionRate } from 'src/app/core/models/enum/commission-rate.enum';
import { ScaleType } from 'src/app/core/models/enum/commission-scale.enum';
import { CommissionScenario } from 'src/app/core/models/enum/commission-scenario.enum';

export const tab = [
  {
    label: 'New Business - MPF',
    value: 'nbm',
    selected: false,
  },
  {
    label: 'Individual Transfer - MPF',
    value: 'itm',
    selected: true,
  },
  {
    label: 'New Business - ORSO/ Macau',
    value: 'nbom',
    selected: false,
  },
];

export const MPFChannelTypes = [
  {
    label: 'Agency',
    value: AgentChannel.AGENCY,
  },
  {
    label: 'IA Broker',
    value: AgentChannel.IA_BROKER,
  },
  {
    label: 'Broker',
    value: AgentChannel.BROKER,
  },
  {
    label: 'SCB',
    value: AgentChannel.SCB,
  },
  {
    label: 'DBS',
    value: AgentChannel.DBS,
  },
  {
    label: 'Corporate',
    value: AgentChannel.CORPORATE,
  },
];
export const MPFCommissionRates = [
  {
    label: 'Full Comm',
    value: CommissionRate.FULL_COMM,
  },
  {
    label: 'Partial Comm 1',
    value: CommissionRate.PARTIAL_COMM1,
  },
  {
    label: 'Partial Comm 2',
    value: CommissionRate.PARTIAL_COMM2,
  },
  {
    label: 'Partial Comm 3',
    value: CommissionRate.PARTIAL_COMM3,
  },
  {
    label: 'No Comm',
    value: CommissionRate.NO_COMM,
  },
];
export const MacauCommissionRates = [
  {
    label: 'Full Comm',
    value: CommissionRate.FULL_COMM,
  },
  {
    label: 'No Comm',
    value: CommissionRate.NO_COMM,
  },
];
export const IndiTrChannelTypes = [
  {
    label: 'Agency',
    value: AgentChannel.AGENCY,
  },
  {
    label: 'IA Broker',
    value: AgentChannel.IA_BROKER,
  },
  {
    label: 'Broker',
    value: AgentChannel.BROKER,
  },
  {
    label: 'SCB',
    value: AgentChannel.SCB,
  },
  {
    label: 'DBS',
    value: AgentChannel.DBS,
  },
  {
    label: 'Corporate',
    value: AgentChannel.CORPORATE,
  },
  {
    label: 'Internal Transfer',
    value: AgentChannel.INTERNAL_TRANSFER,
  },
];
export const CommissionRates = [
  {
    label: 'Full Comm',
    value: CommissionRate.FULL_COMM,
  },
  {
    label: 'Partial Comm 1',
    value: CommissionRate.PARTIAL_COMM1,
  },
  {
    label: 'Partial Comm 2',
    value: CommissionRate.PARTIAL_COMM2,
  },
  {
    label: 'Partial Comm 3',
    value: CommissionRate.PARTIAL_COMM3,
  },
  {
    label: 'No Comm',
    value: CommissionRate.NO_COMM,
  },
  {
    label: 'WSM Partial Comm 1',
    value: CommissionRate.WSM_PARTIAL_COMM1,
  },
  {
    label: 'WSM Partial Comm 2',
    value: CommissionRate.WSM_PARTIAL_COMM2,
  },
  {
    label: 'WSM Partial Comm 3',
    value: CommissionRate.WSM_PARTIAL_COMM3,
  },
  {
    label: 'WSM Partial Comm 4',
    value: CommissionRate.WSM_PARTIAL_COMM4,
  },
  {
    label: 'SHK Partial Comm 1',
    value: CommissionRate.SHKP_PARTIAL_COMM1,
  },
  {
    label: 'SHK Partial Comm 2',
    value: CommissionRate.SHKP_PARTIAL_COMM2,
  },
  {
    label: 'SHK Partial Comm 3',
    value: CommissionRate.SHKP_PARTIAL_COMM3,
  },
];
export const MacauChannelTypes = [
  {
    label: 'Agency',
    value: AgentChannel.AGENCY,
  },
  {
    label: 'Broker',
    value: AgentChannel.BROKER,
  },
  {
    label: 'IA Broker',
    value: AgentChannel.IA_BROKER,
  },
  {
    label: 'Corporate',
    value: AgentChannel.CORPORATE,
  },
  {
    label: 'DBS',
    value: AgentChannel.DBS,
  },
  {
    label: 'SCB',
    value: AgentChannel.SCB,
  },
];

export const MacauScaleTypeMap = new Map([
  [ScaleType.FS_PC, 'commission.scale.type.fspc.name'],
  [ScaleType.FY, 'commission.scale.type.fy.name'],
  [ScaleType.FS_SF, 'commission.scale.type.fssf.name'],
  [ScaleType.RY_PC, 'commission.scale.type.rypc.name'],
  [ScaleType.TRF_SP_PC, 'commission.scale.type.trf_sppc.name'],
  [ScaleType.TRF_SP, 'commission.scale.type.trf_sp.name'],
  [ScaleType.PS_PC, 'commission.scale.type.pspc.name'],
  [ScaleType.PS, 'commission.scale.type.ps.name'],
  [ScaleType.PS_SF, 'commission.scale.type.pssf.name'],
]);

export const IndiTraScaleTypeMap = new Map([
  [ScaleType.IND_TRF_SP_PC, 'commission.scale.type.ind_trf_sppc.name'],
  [ScaleType.IND_TRF_SP, 'commission.scale.type.ind_trf_sp.name'],
  [ScaleType.IND_FS_PC, 'commission.scale.type.fspc.name'],
]);

export const MpfScaleTypeMap = new Map([
  [ScaleType.FS_PC, 'commission.scale.type.fspc.name'],
  [ScaleType.FY, 'commission.scale.type.fy.name'],
  [ScaleType.PS_PC, 'commission.scale.type.pspc.name'],
  [ScaleType.RY_PC, 'commission.scale.type.rypc.name'],
  [ScaleType.TRF_SP_PC, 'commission.scale.type.trf_sppc.name'],
  [ScaleType.PS_SF, 'commission.scale.type.pssf.name'],
  [ScaleType.FS_SF, 'commission.scale.type.fssf.name'],
  [ScaleType.PS, 'commission.scale.type.ps.name'],
  [ScaleType.TRF_SP, 'commission.scale.type.trf_sp.name'],
]);

export const IndiTraScenarios = [
  { label: 'MPF-ER(PAC): Individual Transfer', value: CommissionScenario.MPF_ERPAC_IND },
  { label: 'MPF-ER(PAC): Member Choice', value: CommissionScenario.MPF_ERPAC_MC },
  { label: 'MPF-SEP: Individual Transfer & MPF-SEP(PAC): Individual Transfer', value: CommissionScenario.MPF_SEP_IND },
  { label: 'MPF-SEP(PAC): Member Choice', value: CommissionScenario.MPF_SEP_PAC_MC },
  { label: 'MPF-TVC: Individual Transfer', value: CommissionScenario.MPF_TVC_IND },
  { label: 'MPF-PAP: Individual Transfer', value: CommissionScenario.MPF_PAP_IND },
  { label: 'MPF-PAP: Member Choice', value: CommissionScenario.MPF_PAP_MC },
  { label: 'MPF-SEP AGENT: Individual Transfer & MPF-SEP AGENT(PAC): Individual Transfer', value: CommissionScenario.MPF_ASEP_IND },
  { label: 'MPF-SEP AGENT(PAC): Member Choice', value: CommissionScenario.MPF_ASEP_PAC_MC },
  { label: 'MPF-ER(PAC): Min MPF Benefit', value: CommissionScenario.MPF_ERPAC_MIN_BENEFIT },
  { label: 'MPF-PAP: Min MPF Benefit', value: CommissionScenario.MPF_PAP_MIN_BENEFIT },
];

export const I18N_KEY = {
  COMMON_ACTION_CANCLE: 'common.action.cancel',
  COMMON_CANCEL: 'common.cancel',
  COMMON_CONTINUE: 'common.continue',
  COMMISSION_SCALE_CODE_INPUT: 'commission.pool.scale.recordCreate.input',
  COMMISSION_SCALE_CODE_EMPTY: 'commission.default.scale.code.empty',
};
