import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { CdsAlertService } from '@cds/ng-web-components/alert';
import { CdsPopupService } from '@cds/ng-web-components/popup';
import { NotifyService } from 'src/app/core/services/notify.service';
import { CdHttpServeService } from 'src/app/shared/cd-http-serve/cd-http-serve.service';
import { WillBeLostComponent } from 'src/app/shared/will-be-lost/will-be-lost.component';

@Component({
  selector: 'app-add-new-case',
  templateUrl: './add-new-case.component.html',
  styleUrls: ['./add-new-case.component.scss'],
})
export class AddNewCaseComponent implements OnInit {
  resetDatePicker = false;
  currentStep = 0;
  meetingDateMin = new Date(new Date().getTime() - 14 * 24 * 60 * 60 * 1000);
  employerAccountCodeErrCodeList: number[] = [];
  duplicateMeetingDates: string[] = [];
  successCases: string[] = [];
  failCases: string[] = [];

  form = new FormGroup({
    bookingReferenceNo: new FormControl('', [Validators.required]),
    employerAccountCode: new FormControl('', [Validators.required]),
    meetingDates: new FormArray([]),
  });

  constructor(
    private dialogRef: MatDialogRef<AddNewCaseComponent>,
    private cdHttpServeService: CdHttpServeService,
    private notifyService: NotifyService,
    private alert: CdsAlertService,
    private cdsPopup: CdsPopupService
  ) {}

  ngOnInit(): void {
    this.addNewMeetingDate();
  }

  asFormGroup(agent: AbstractControl) {
    return agent as FormGroup;
  }

  backToEdit() {
    this.currentStep = 0;
  }

  onAddNewMeetingDate() {
    if (this.isDisableAddNewMeetingDate) {
      return;
    }
    this.addNewMeetingDate();
  }

  private addNewMeetingDate() {
    this.meetingDateList.push(
      new FormGroup({
        date: new FormControl('', [Validators.required, this.meetingDateValidation.bind(this)]),
      })
    );
  }

  meetingDateValidation(option: AbstractControl): ValidationErrors | null {
    if (!option.value) {
      return null;
    }
    const temp = option.value.split('/');
    const selectedDate = new Date([temp[1], temp[0], temp[2]].join('/'));
    if (selectedDate.toString() === 'Invalid Date') {
      return { formatError: true };
    }

    if (selectedDate.getTime() < this.meetingDateMin.getTime() - 24 * 60 * 60 * 1000) {
      return { formatError: true };
    }
    return null;
  }

  get selectedMeetingDates() {
    const all: string[] = [];
    for (let i = 0; i < this.meetingDateList.length; i++) {
      all.push(this.meetingDateList.at(i).value.date);
    }
    return all;
  }

  isLocalDuplicate(meetingDate: string) {
    if (!meetingDate) {
      return false;
    }
    return this.selectedMeetingDates.indexOf(meetingDate) !== this.selectedMeetingDates.lastIndexOf(meetingDate);
  }

  deleteItem(i: number) {
    this.meetingDateList.removeAt(i);
  }

  get isDisableAddNewMeetingDate() {
    if (this.currentStep !== 0) {
      return true;
    }
    return this.meetingDateList.length >= 20;
  }

  get meetingDateList() {
    return this.form.controls['meetingDates'] as FormArray;
  }

  reset() {
    const popupRef: MatDialogRef<WillBeLostComponent> = this.cdsPopup.open(WillBeLostComponent, {
      size: 'md',
    });

    popupRef.afterClosed().subscribe(confirm => {
      if (confirm.agree) {
        this.employerAccountCodeErrCodeList = [];
        this.form.reset();
        this.bookingReferenceNo.setValue('');
        this.employerAccountCode.setValue('');
        for (let i = this.meetingDateList.length - 1; i >= 0; i--) {
          this.meetingDateList.removeAt(i);
        }
        this.addNewMeetingDate();
        this.onResetDatePicker();
      }
    });
  }

  private onResetDatePicker() {
    this.resetDatePicker = true;
    setTimeout(() => {
      this.resetDatePicker = false;
    }, 0);
  }

  get bookingReferenceNo() {
    return this.form.get('bookingReferenceNo') as FormControl;
  }

  get employerAccountCode() {
    return this.form.get('employerAccountCode') as FormControl;
  }

  add() {
    this.bookingReferenceNo.markAsDirty();
    this.employerAccountCode.markAsDirty();
    for (let i = 0; i < this.meetingDateList.length; i++) {
      this.meetingDateList.at(i).get('date')?.markAsDirty();
    }

    for (let i = 0; i < this.meetingDateList.length; i++) {
      if (this.isLocalDuplicate(this.meetingDateList.at(i).get('date')?.value)) {
        return;
      }
    }

    if (this.form.valid) {
      this.notifyService.screenLoadingSubject.next(true);
      this.employerAccountCodeErrCodeList = [];
      this.duplicateMeetingDates = [];
      const meetingDates = [];
      for (let i = 0; i < this.meetingDateList.length; i++) {
        meetingDates.push(this.meetingDateList.at(i).value.date);
      }
      this.cdHttpServeService
        .post<{ code: number; meetingDates?: string[] }[]>('/ext/eb-ssms-sales-journey-service/validateEmployerAcctCd', {
          employerAcctCd: this.employerAccountCode.value,
          meetingDates: meetingDates,
        })
        .subscribe({
          next: res => {
            if (res.result !== 0) {
              this.alert.warning('Warning!', `${res.message}`);
            } else {
              if (res.data && res.data.length > 0) {
                this.employerAccountCodeErrCodeList = res.data.map(item => {
                  if (item.code === 3) {
                    this.duplicateMeetingDates = item.meetingDates || [];
                  }
                  return item.code;
                });
              } else {
                this.currentStep = 1;
              }
            }
            this.notifyService.screenLoadingSubject.next(false);
          },
          error: err => {
            this.alert.error('Error!', err);
            this.notifyService.screenLoadingSubject.next(false);
          },
        });
    }
  }

  onClose() {
    this.dialogRef.close({ agree: false });
  }

  employerAccountCodeChange(event: string) {
    if (event === '') {
      this.employerAccountCodeErrCodeList = [];
    }
  }

  confirm() {
    const params = [];
    this.successCases = [];
    this.failCases = [];
    for (let i = 0; i < this.meetingDateList.length; i++) {
      params.push({
        bookingRefNo: this.bookingReferenceNo.value,
        employerAcctCd: this.employerAccountCode.value,
        meetingDate: this.meetingDateList.at(i).get('date')?.value,
      });
    }

    this.notifyService.screenLoadingSubject.next(true);
    this.cdHttpServeService
      .post<{
        successCases: {
          bookingRefNo: string;
          employerAcctCd: string;
          meetingDate: string;
        }[];
        failCases: {
          bookingRefNo: string;
          employerAcctCd: string;
          meetingDate: string;
        }[];
      }>('/ext/eb-ssms-sales-journey-service/addWsmSummaries', params)
      .subscribe({
        next: res => {
          if (res.result !== 0) {
            this.alert.warning('Warning!', `${res.message}`);
          } else {
            this.successCases = res.data.successCases.map(item => item.meetingDate);
            this.failCases = res.data.failCases.map(item => item.meetingDate);
            this.currentStep = 2;
          }
          this.notifyService.screenLoadingSubject.next(false);
        },
        error: err => {
          this.alert.error('Error!', err);
          this.notifyService.screenLoadingSubject.next(false);
        },
      });
  }

  gotIt() {
    this.dialogRef.close({ agree: true });
    this.currentStep = 0;
  }
}
