import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-permission-error',
  templateUrl: './permission-error.component.html',
  styleUrls: ['./permission-error.component.scss'],
})
export class PermissionErrorComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
