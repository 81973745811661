/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup, ValidationErrors } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { CDS_POPUP_DATA, CdsPopupService } from '@cds/ng-web-components/popup';
import { UploadCenterConfig } from 'src/app/core/models/upload-center/upload-center.model';
import { NotifyService } from 'src/app/core/services/notify.service';
import { WillBeLostComponent } from 'src/app/shared/will-be-lost/will-be-lost.component';
import { UploadAbmListPopupService } from '../upload-abm-list-popup/upload-abm-list-popup.service';
import { finalize } from 'rxjs';
import { UploadCenterService } from 'src/app/core/services/upload-center/upload-center.service';
import { CdPopupService } from 'src/app/shared/cd-popup';
import { ResponseResult } from 'src/app/core/models/response/response-result';

@Component({
  selector: 'app-upload-document',
  templateUrl: './upload-document.component.html',
  styleUrls: ['./upload-document.component.scss'],
})
export class UploadDocumentComponent implements OnInit {
  formGroup!: FormGroup;
  addedCaseNum = 0;
  removedCaseNum = 0;
  skipData = [];
  step = 1;
  displayedColumns = ['no', 'action', 'branchCode', 'districtName', 'abm'];

  get fileUploadInfoList() {
    return this.formGroup.get('fileUploadInfoList') as FormArray;
  }

  tranToFormGroup(uploadinfo: AbstractControl) {
    return uploadinfo as FormGroup;
  }

  getFileControl(uploadinfo: AbstractControl) {
    return this.tranToFormGroup(uploadinfo).get('fileControl') as FormControl;
  }

  getErrorMessage(uploadinfo: AbstractControl) {
    return this.tranToFormGroup(uploadinfo).get('errorMessage')?.value;
  }

  constructor(
    public dialogRef: MatDialogRef<UploadDocumentComponent>,
    private cdsPopup: CdsPopupService,
    @Inject(CDS_POPUP_DATA)
    public data: {
      currentSelectedConfig: UploadCenterConfig;
    },
    private notifyService: NotifyService,
    private uploadAbmListPopupService: UploadAbmListPopupService,
    private uploadCenterService: UploadCenterService,
    private cdPopupService: CdPopupService
  ) {}

  ngOnInit(): void {
    this.formGroup = new FormGroup(
      {
        fileUploadInfoList: new FormArray([]),
      },
      [
        (control: AbstractControl): ValidationErrors | null => {
          const fileUploadInfoList = control.get('fileUploadInfoList') as FormArray;
          const atLeastOneFile = fileUploadInfoList.controls.some(item => item.get('file')?.value);
          const noError = fileUploadInfoList.controls.every(item => !item.get('errorMessage')?.value);
          if (atLeastOneFile && noError) {
            return null;
          } else {
            return { invalid: true };
          }
        },
      ]
    );
    this.addItem();
  }

  addItem() {
    const fileControl = new FormControl();
    const errorMessage = new FormControl('');
    const file = new FormControl();
    this.fileUploadInfoList.push(
      new FormGroup({
        fileControl: fileControl,
        errorMessage: errorMessage,
        file: file,
      })
    );
    fileControl.valueChanges.subscribe((value: string) => {
      if (value === null) {
        errorMessage.setValue('');
        file.reset();
      }
    });
  }

  removeItem(index: number) {
    this.fileUploadInfoList.removeAt(index);
  }

  fileChange(event: any, uploadinfo: AbstractControl) {
    const info = this.tranToFormGroup(uploadinfo);
    const file = info.get('file');
    const errorMessage = info.get('errorMessage');
    if (file && errorMessage) {
      file.setValue(event?.target?.files[0]);
      if (file.value.size > 8 * 1024 * 1024) {
        errorMessage.setValue('Your file is exceeding the file size limit of 8MB.');
      } else if (!this.fileNameVerify(file)) {
        errorMessage.setValue('Invalid file name.');
      } else {
        errorMessage.setValue('');
        if (this.fileUploadInfoList.length < this.data.currentSelectedConfig.filesCount) {
          this.addItem();
        }
      }
    }
  }

  private fileNameVerify(file: AbstractControl) {
    if (file.value.name) {
      if (this.data.currentSelectedConfig.configName === 'ABM List') {
        return /^branch_portfolio_\d{4}(0[1-9]|1[0-2])(0[1-9]|[12][0-9]|3[01])$/.test(file.value.name.split('.')[0]);
      } else if (this.data.currentSelectedConfig.configName === '01_Campaigns_Campaign List') {
        return /^prestige_club_member_master_\d{4}(0[1-9]|1[0-2])(0[1-9]|[12][0-9]|3[01])$/.test(file.value.name.split('.')[0]);
      }
    }
    return false;
  }

  cancel() {
    if (this.formGroup.invalid) {
      this.dialogRef.close({ agree: false });
      return;
    }
    const popupRef: MatDialogRef<WillBeLostComponent> = this.cdsPopup.open(WillBeLostComponent, {
      size: 'sm',
    });
    popupRef.afterClosed().subscribe(({ agree }) => {
      if (agree) {
        this.dialogRef.close({ agree: false });
      }
    });
  }

  upload() {
    if (this.formGroup.valid) {
      this.notifyService.screenLoadingSubject.next(true);
      const file = this.fileUploadInfoList.controls[0].value.file;
      if (this.data.currentSelectedConfig.configName === 'ABM List') {
        this.uploadAbmListPopupService
          .uploadFile(file)
          .pipe(
            finalize(() => {
              this.notifyService.screenLoadingSubject.next(false);
            })
          )
          .subscribe(res => {
            if (res.result === 0) {
              this.step = 2;
              if (res.data.skip) {
                this.skipData = res.data.skip;
              }
              this.addedCaseNum = res.data.add ? res.data.add.length : 0;
              this.removedCaseNum = res.data.remove ? res.data.remove.length : 0;
            } else {
              this.tranToFormGroup(this.fileUploadInfoList.controls[0]).get('errorMessage')?.setValue('Invalid upload file');
            }
          });
      } else if (this.data.currentSelectedConfig.configName === '01_Campaigns_Campaign List') {
        this.uploadCenterService
          .uploadFile(file)
          .pipe(
            finalize(() => {
              this.notifyService.screenLoadingSubject.next(false);
            })
          )
          .subscribe(res => {
            if (res.result === ResponseResult.SUCCESS) {
              this.dialogRef.close({ agree: false });
              this.cdPopupService.openCommon({ data: { message: 'common.action.uploadSuccess' } });
            } else {
              this.tranToFormGroup(this.fileUploadInfoList.controls[0]).get('errorMessage')?.setValue('Invalid upload file');
            }
          });
      }
    }
  }
}
