<div class="row">
  <div class="col-xs-12 l-pt-7">
    <span class="cds-h2-light">{{ 'common.searchRecord' | lang }}</span>
  </div>
  <div class="row l-plr-0" [formGroup]="form" *ngIf="formReady">
    <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 l-pt-4 l-pb-4">
      <cds-textfield formControlName="workflowReferenceNo" label="Receipt Reference No." placeholder="Input Receipt Reference No."></cds-textfield>

      <div *ngIf="workflowReferenceNoControl?.invalid && (workflowReferenceNoControl?.dirty || workflowReferenceNoControl?.touched)" class="l-pt-2">
        <cds-assistive-text *ngIf="workflowReferenceNoControl?.errors?.['maxlength']" class="l-pt-2" [showIcon]="false">Max length is 35</cds-assistive-text>
      </div>
    </div>
    <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 l-pt-4 l-pb-4">
      <cds-textfield
        formControlName="paymentSubmissionReferenceNo"
        label="Payment Submission Reference No."
        placeholder="Input Payment Submission Reference No."></cds-textfield>

      <div
        *ngIf="paymentSubmissionReferenceNoControl?.invalid && (paymentSubmissionReferenceNoControl?.dirty || paymentSubmissionReferenceNoControl?.touched)"
        class="l-pt-2">
        <cds-assistive-text *ngIf="paymentSubmissionReferenceNoControl?.errors?.['maxlength']" class="l-pt-2" [showIcon]="false"
          >Max length is 50</cds-assistive-text
        >
      </div>
    </div>

    <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 l-pt-4 l-pb-4">
      <cds-dropdown formControlName="type" [config]="typeConfig"></cds-dropdown>
    </div>

    <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 l-pt-4 l-pb-4">
      <cds-dropdown formControlName="statusCode" [config]="statusCodeConfig"></cds-dropdown>
    </div>

    <div class="col-xs-6 col-sm-3 col-md-3 col-lg-3 l-pt-4 l-pb-4" *ngIf="isEr">
      <cds-textfield
        formControlName="memberAccountCode"
        [label]="'ee.member-account-code' | lang"
        [placeholder]="'ee.input-member-account-code' | lang"></cds-textfield>

      <div *ngIf="memberAccountCodeControl?.invalid && (memberAccountCodeControl?.dirty || memberAccountCodeControl?.touched)" class="l-pt-2">
        <cds-assistive-text
          *ngIf="memberAccountCodeControl?.errors?.['maxlength'] || memberAccountCodeControl?.errors?.['minlength']"
          class="l-pt-2"
          [showIcon]="false"
          >Max length is 15</cds-assistive-text
        >

        <cds-assistive-text
          *ngIf="memberAccountCodeControl?.errors?.['invalidNumberString']"
          class="l-pt-2"
          [showIcon]="false"
          >{{ memberAccountCodeControl?.errors?.['invalidNumberString'] }}</cds-assistive-text
        >
      </div>
    </div>

    <div *ngIf="showDatePicker" class="col-xs-12 col-sm-6 col-md-4 col-lg-3 l-pt-4 l-pb-4">
      <cds-textfield formControlName="createDate" placeholder="DD/MM/YYYY" label="Payment Received Date" [cdsDatepicker]="datePicker"> </cds-textfield>
      <cds-datepicker #datePicker="cdsDatepicker"></cds-datepicker>

      <div *ngIf="createDateControl?.invalid && (createDateControl?.dirty || createDateControl?.touched)" class="l-pt-2">
        <cds-assistive-text *ngIf="createDateControl?.errors?.['dateInvalid']" class="l-pt-2" [showIcon]="false">{{
          'create.dateError' | lang
        }}</cds-assistive-text>
      </div>
    </div>

    <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 l-mt-4 l-pb-4 bottom-xs l-pt-4">
      <cds-button
        class="l-mr-4 botton"
        [disabled]="searchButtonDisabled"
        [config]="searchButtonConfig"
        [label]="'list.search' | lang"
        (click)="search()"></cds-button>
      <cds-button class="botton" [disabled]="resetButtonDisabled" [config]="resetButtonConfig" [label]="'list.reset' | lang" (click)="reset()"></cds-button>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-xs-12 col-md-12 l-pb-3 l-d-f l-jc-sb l-mt-7 l-mb-4">
    <div>
      <div class="cds-h2-light l-ma-0">{{ 'common.recordList' | lang }}</div>
      <div class="cds-detail1-demibold">Total {{ numberOfElements | number }} records</div>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-xs-12 col-md-12">
    <app-data-table
      [frontPagination]="false"
      [isLoading]="isLoading"
      [fixed]="false"
      [overlay]="true"
      [pageConfig]="pageConfig"
      [dataSource]="dataSource"
      [columnsConfig]="columnsConfig"
      (reloadTable)="reloadTable($event)">
      <ng-template appDtBodyCellDef="memberAccountCode" let-element>
        <span class="click" (click)="toDetail(element)">{{ element['memberAccountCode'] }}</span>
      </ng-template>

      <ng-template appDtBodyCellDef="memberName" let-element>
        <span class="click" (click)="toDetail(element)">{{ element['memberName'] }}</span>
      </ng-template>

      <ng-template appDtBodyCellDef="statusCode" let-element>
        <app-icon-status [value]="element['statusCode']" [statusMapObj]="workflowStatusCodeMapObj"></app-icon-status>
      </ng-template>

      <ng-template appDtBodyCellDef="type" let-element>
        <span>{{ typeRender | fn : element['type'] }}</span>
      </ng-template>

      <ng-template appDtBodyCellDef="createDate" let-element>
        <span>{{ element['createDate'] | ssmsDate }}</span>
      </ng-template>

      <ng-template appDtBodyCellDef="followUpRejectDate" let-element>
        <span>{{ element['followUpRejectDate'] | ssmsDate }}</span>
      </ng-template>

      <ng-template appDtBodyCellDef="followUpRejectReason" let-element>
        <div class="reason">
          <div class="text-ellipsis">{{ element['followUpRejectReason'] || '-' }}</div>
          <div *ngIf="element['followUpRejectReason']" class="cursor-pointer l-ml-2" (click)="reasonPopup(element['followUpRejectReason'])">
            <cds-icon icon="arrow:fullscreen"></cds-icon>
          </div>
        </div>
      </ng-template>
    </app-data-table>
  </div>
</div>
