/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BasicResponse } from '../../models/response/response';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UploadCenterService {
  constructor(private http: HttpClient) {}

  uploadFile(file: File) {
    const formData = new FormData();
    formData.append('file', file);
    return this.http.post<BasicResponse<any>>(`${environment.apiPrefix}/ext/eb-ssms/agent-service/campaignList/upload`, formData);
  }
}
