import { deepCopy } from 'src/app/utils/copy';
import { FormControl } from '@angular/forms';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CdsIconConfig } from '@cds/ng-core/icon';
import { finalize } from 'rxjs';
import { ToastAlertService } from 'src/app/shared/toast-alert.service';
import { AccountInfoAmountRequest, BillingClass, BillReference, ContributionAmount, ContributionDetails } from '../../employer';
import { EmployerService } from '../../employer.service';
import { dateValidator } from 'src/app/shared/validators/validators';
import moment from 'moment';
import { DateTime } from 'luxon';

@Component({
  selector: 'app-bill-reference',
  templateUrl: './bill-reference.component.html',
  styleUrls: ['./bill-reference.component.scss'],
})
export class BillReferenceComponent implements OnInit, OnChanges {
  @Input() contributionTableDataSource: ContributionAmount[] = [];
  @Input() contributionDetails?: ContributionDetails;
  @Input() billingClass?: BillingClass;
  @Input() employerId?: string;
  @Input() payrollGroupId?: string;

  isLoading = false;

  data: BillReference[] = [];

  iconConfig: CdsIconConfig = {
    color: '#00A758',
  };

  public runDateFormControl: FormControl = new FormControl('');

  constructor(private route: ActivatedRoute, private router: Router, public service: EmployerService, private toastAlert: ToastAlertService) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes['contributionDetails'].currentValue !== undefined &&
      changes['contributionDetails'].currentValue.contributionPeriodStartDate !== changes['contributionDetails'].previousValue?.contributionPeriodStartDate
    ) {
      this.initFormControl();
      this.getData();
    }
  }

  initFormControl() {
    this.runDateFormControl = new FormControl('', [dateValidator('create.dateError')]);
  }

  toDetail(paymentSubmissionReferenceNo: string, paymentReferenceNo?: string) {
    this.router.navigate(['/employer/payment-record-detail'], {
      queryParams: {
        ...this.route.snapshot.queryParams,
        paymentSubmissionReferenceNo,
        paymentReferenceNo,
      },
    });
  }

  getData(date = '') {
    const params: AccountInfoAmountRequest = this.getRequestParams(date);
    this.isLoading = true;
    this.service
      .getAccountInfoBillReferenceList(params)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe(data => {
        if (data.result === 0 && data.data) {
          this.data = data.data;
          this.isLoading = false;
        } else {
          this.toastError(data.message);
        }
      });
  }

  getRequestParams(date = '') {
    const params: AccountInfoAmountRequest = {
      id: this.employerId,
      payrollGroupId: this.billingClass?.payrollGroupId,
      startDate: this.contributionDetails?.contributionPeriodStartDate,
      endDate: this.contributionDetails?.contributionPeriodEndDate,
      page: 0,
      size: 10000,
    };

    if (date) {
      params.runDate = this.dateTrans(date);
      // } else if (this.contributionDetails?.runDate) {
    } else {
      params.runDate = '';
    }
    return params;
  }

  onDateChange(date: string) {
    if (this.runDateFormControl.valid) {
      this.getData(date);
    }
  }

  toastError(errorMessage: string) {
    this.toastAlert.show('error', 'common.error', errorMessage, 5000);
  }

  dateTrans(date: string) {
    if (date) {
      return moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD');
    }

    return date;
  }

  getContributionDetails(data: ContributionDetails) {
    const details: ContributionDetails = deepCopy(data);
    details.contributionPeriodStartDate = this.contributionDetails?.contributionPeriodStartDate;
    details.contributionPeriodEndDate = this.contributionDetails?.contributionPeriodEndDate;
    return details;
  }

  tranToJsDate(date?: string) {
    if (!date) {
      return new Date();
    }
    return DateTime.fromFormat(date, 'yyyy-MM-dd').toJSDate();
  }
}
