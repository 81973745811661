import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-cd-download-progress',
  templateUrl: './cd-download-progress.component.html',
  styleUrls: ['./cd-download-progress.component.scss'],
})
export class CdDownloadProgressComponent implements OnInit {
  @Input() show = false;
  @Output() close1 = new EventEmitter<void>();
  constructor() {}

  ngOnInit() {}

  btnXOnClick() {
    this.close1.emit();
  }
}
