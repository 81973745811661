import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CdsLanguageModule } from '@cds/ng-core/lang';
import { CdsIconModule } from '@cds/ng-web-components/icon';
import { RowItemComponent } from './row-item.component';
import { CdsCheckboxModule } from '@cds/ng-web-components/checkbox';
import { CdsTooltipModule } from '@cds/ng-web-components/tooltip';
import { RowItemContentDefDirective, RowItemLabelDefDirective } from './row-item.component.directive';

@NgModule({
  declarations: [RowItemComponent, RowItemLabelDefDirective, RowItemContentDefDirective],
  imports: [CommonModule, CdsLanguageModule, CdsIconModule, CdsCheckboxModule, CdsTooltipModule],
  exports: [RowItemComponent, RowItemLabelDefDirective, RowItemContentDefDirective],
})
export class RowItemModule {}
