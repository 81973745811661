<cds-popup>
  <div class="l-pt-6 l-pb-6 background-grey">
    <div class="row">
      <div class="col-xs-12 col-md-12">
        <div class="cds-h2-light l-ma-0">
          {{ 'commission.pool.agents.alert.title' | lang }}
        </div>
      </div>
      <div class="col-xs-12 col-md-12 l-pt-3 l-pb-3">
        {{ 'commission.pool.agents.alert.addNew' | lang }}
        <br />
        <div class="col-xs-12 col-md-9 l-pt-6 l-plr-0">
          <input [disabled]="loading" type="text" maxlength="6" (input)="input($event)" placeholder="Input agent code" class="text-field" />
          <i class="icon">
            <cds-icon size="sm" icon="form:search"></cds-icon>
          </i>
        </div>
        <cds-assistive-text *ngIf="errorMsg" class="l-pt-3">
          {{ errorMsg | lang }}
        </cds-assistive-text>
      </div>
      <cds-progress *ngIf="loading" mode="indeterminate" class="l-pl-6"></cds-progress>
      <div *ngIf="!loading" class="col-xs-12 col-md-12 l-pt-6">
        <cds-button [config]="disagreeButtonConfig" size="sm" (click)="disagree()">{{ 'commission.pool.common.cancel' | lang }} </cds-button>
        <cds-button class="l-ml-3" size="sm" [disabled]="disableAdd" (click)="add()">{{ 'commission.pool.common.add' | lang }} </cds-button>
      </div>
    </div>
  </div>
</cds-popup>
