import { Component, OnInit } from '@angular/core';
import { AdminHeaderConfig } from '../../layout/admin-layout/admin-header/admin-header-config';
import { LayoutHeaderService } from 'src/app/core/services/layout-header.service';
import { EmployeeService } from '../employee.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastAlertService } from 'src/app/shared/toast-alert.service';
import {
  ClaimTypeReasonE,
  FundTransactionFormTypeE,
  PmtransferOptionTypeE,
  PolicyTransferDetails,
  PolicyTransferStatusCodeE,
  PolicyWithdrawalDetails,
  PptransferOptionTypeE,
  ReplyTypeE,
  SubAccountTypeE,
  SubmissionChannelE,
  SubmissionTypeE,
  WithdrawalPayee,
  claimTypeReasonMapObj,
  employeeStatusMapObj,
  fundTransactionFormTypeMapObj,
  pmtransferOptionTypeMapObj,
  policyTransferStastusCodeMapObj,
  policyWithdrawalStatusCodeMapObj,
  pptransferOptionTypeMapObj,
  replyTypeMapObj,
  subAccountTypeMapObj,
  submissionChannelMapObj,
  submissionTypeMapObj,
} from '../employee';
import { CdsDropdownConfig } from '@cds/ng-core/dropdown';
import { finalize } from 'rxjs';
import { ContactPersion, RelatedPersonTypeE } from '../../employer/employer';

@Component({
  selector: 'app-policy-withdrawal-details',
  templateUrl: './policy-withdrawal-details.component.html',
  styleUrls: ['./policy-withdrawal-details.component.scss'],
})
export class PolicyWithdrawalDetailsComponent implements OnInit {
  headerButton: AdminHeaderConfig = {
    title: 'ee.profile.detail.plus.title',
    backButton: {
      text: 'Member Profile Overview',
      url: '#',
      method: this.goBack.bind(this),
    },
  };
  withdrawalReferenceNo = '';

  id = '';

  md5 = '';

  detailData: PolicyWithdrawalDetails = {};

  employeeStatusMapObj = employeeStatusMapObj;

  policyWithdrawalStatusCodeMapObj = policyWithdrawalStatusCodeMapObj;

  dropdownConfig: CdsDropdownConfig = {
    label: 'Withdrawal Reference No.',
    options: [],
  };

  isLoading = false;

  bankInformationTableData: PolicyTransferDetails[] = [];

  rejectReasonTableData: string[] = [];

  rejectReasondisplayedColumns = ['rejectionReason'];

  constructor(
    private layoutHeaderService: LayoutHeaderService,
    public service: EmployeeService,
    private route: ActivatedRoute,
    private router: Router,
    private toastAlert: ToastAlertService
  ) {}

  ngOnInit(): void {
    this.layoutHeaderService.setHeaderButton(this.headerButton);
    this.withdrawalReferenceNo = this.route.snapshot.queryParams['withdrawalReferenceNo'];
    this.id = this.route.snapshot.queryParams['id'];
    this.md5 = this.route.snapshot.queryParams['md5'];
    // this.getRelatedPolicies();
    // this.getDetail();
  }

  onSelectChange(val: string) {
    if (!val) return;
    this.withdrawalReferenceNo = val;
    this.getDetail();
  }

  goBack() {
    this.router.navigate(['/employee/related-policy-detail'], {
      queryParams: { id: this.id, md5: this.md5 },
    });
  }

  toastError(errorMessage: string) {
    this.toastAlert.show('error', 'common.error', errorMessage, 5000);
  }

  getDetail() {
    this.isLoading = true;
    this.service
      .getWithdrawalDetails(this.withdrawalReferenceNo, this.id)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe(res => {
        if (res.result === 0 && res.data) {
          this.detailData = res.data;
          const referenceList = res.data.withdrawalReferenceList || [];
          this.dropdownConfig.options = referenceList.map(item => {
            return {
              label: `${item.withdrawalReferenceNo}`,
              value: item.withdrawalReferenceNo,
            };
          });
          this.dropdownConfig = { ...this.dropdownConfig };
        } else {
          this.toastError(res.message);
        }
      });
  }

  statusCodeRender(type: PolicyTransferStatusCodeE) {
    return policyTransferStastusCodeMapObj[type] || '';
  }

  fundTransactionFormTypeRender(type: FundTransactionFormTypeE) {
    return fundTransactionFormTypeMapObj[type] || '';
  }

  pmTransferOptionTypeRender(type: PmtransferOptionTypeE) {
    return pmtransferOptionTypeMapObj[type] || '';
  }

  ppTransferOptionTypeRender(type: PptransferOptionTypeE) {
    return pptransferOptionTypeMapObj[type] || '';
  }

  submissionTypeRender(type: SubmissionTypeE) {
    return submissionTypeMapObj[type] || '';
  }

  subAccountTypeRender(type: SubAccountTypeE) {
    return subAccountTypeMapObj[type] || '';
  }

  replyTypeRender(type: ReplyTypeE) {
    return replyTypeMapObj[type] || '';
  }

  nameRender(item: WithdrawalPayee) {
    return `Payment Out Reference No. ${item.paymentOutReferenceNo || ''}`;
  }

  submissionChannelRender(type: SubmissionChannelE) {
    return submissionChannelMapObj[type] || '';
  }

  claimTypeReasonRender(type: ClaimTypeReasonE) {
    return claimTypeReasonMapObj[type] || '';
  }

  getOroOrTibContactPersonList(list: ContactPersion[]) {
    return list.filter(item => {
      return (
        [
          RelatedPersonTypeE.ORO,
          RelatedPersonTypeE.TIB,
          RelatedPersonTypeE.PRIMARY_TIB,
          RelatedPersonTypeE.SECOND_ORO,
          RelatedPersonTypeE.SECOND_TIB,
          RelatedPersonTypeE.SECOND_ORO,
        ].indexOf(item.contactPersonType) > -1
      );
    });
  }

  getClaimantPersonList(list: ContactPersion[]) {
    return list.filter(item => {
      return [RelatedPersonTypeE.CLAIMANT].indexOf(item.contactPersonType) > -1;
    });
  }
}
