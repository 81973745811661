import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-cd-accordion',
  templateUrl: './cd-accordion.component.html',
  styleUrls: ['./cd-accordion.component.scss'],
})
export class CdAccordionComponent implements OnInit {
  @Input() noTopLine = false;
  constructor() {}

  ngOnInit(): void {}
}
