/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, HostBinding, Inject, OnInit } from '@angular/core';
import { CdPopupService, CdPopupSize, MAT_DIALOG_DATA } from '../cd-popup.service';

@Component({
  selector: 'app-cd-popup',
  templateUrl: './cd-popup.component.html',
  styleUrls: ['./cd-popup.component.scss'],
})
export class CdPopupComponent implements OnInit {
  size: CdPopupSize = CdPopupSize.SMALL;
  dialog: any;
  isShowClose = true;

  @HostBinding('class.cds-popup') isCdsPopup = true;

  @HostBinding('class.cds-popup-sm') get isSmall(): boolean {
    return this.size === CdPopupSize.SMALL;
  }
  @HostBinding('class.cds-popup-md') get isMedium(): boolean {
    return this.size === CdPopupSize.MEDIUM;
  }
  @HostBinding('class.cds-popup-lg') get isLarge(): boolean {
    return this.size === CdPopupSize.LARGE;
  }

  constructor(private cdPopup: CdPopupService, @Inject(MAT_DIALOG_DATA) private data: any) {}

  ngOnInit(): void {
    this.size = this.data.__cdsPopupSize;
    this.isShowClose = this.data.isShowClose;
    this.dialog = this.cdPopup.currentDialogRef;
  }

  close() {
    this.dialog.close();
  }
}
