/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit, ElementRef, ViewChild, OnDestroy } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors } from '@angular/forms';
import { filter, finalize, Subscription } from 'rxjs';
import { AgentProfileListService } from './agent-profile-list.service';
import { AgentProfileResponseData } from './agent-profile-response.model';
import { PageConfig } from 'src/app/shared/data-table';
import { AgentProfileRequest } from './agent-profile-request.model';
import { AGENT_CONTRACT_STATUS_OPTIONS, MPF_LICENSE_STATUS_OPTIONS } from './agent-profile.config';
import { NarrowSearchPopupComponent } from './narrow-search-popup/narrow-search-popup.component';
import { CdsPopupService } from '@cds/ng-web-components/popup';
import moment from 'moment';
import { aoaToSheet, FileType } from 'src/app/utils/xlsx';
import { CdMultidropdownTopping } from 'src/app/shared/cd-multidropdown/cd-multidropdown.model';
interface RequestParams {
  agentPersonCode: string;
  englishName: string;
  agentCode: string;
  branchCode: string;
  joinDateFrom: string;
  joinDateTo: string;
  contractTerminationDateFrom: string;
  contractTerminationDateTo: string;
  agentContractStatus: string;
  brnachHeadAgentCode: string;
  unitCode: string;
  directManagerCode: string;
  mPFLicenseStatus: string;
}

@Component({
  selector: 'app-agent-profile-list',
  templateUrl: './agent-profile-list.component.html',
  styleUrls: ['./agent-profile-list.component.scss'],
})
export class AgentProfileListComponent implements OnInit, OnDestroy {
  private agentProfileSubscription?: Subscription;

  agentContractStatusOptionList = AGENT_CONTRACT_STATUS_OPTIONS;
  mpfLicenseStatusOptionList = MPF_LICENSE_STATUS_OPTIONS;
  @ViewChild('tableBox', { static: false })
  tableBox?: ElementRef<HTMLDivElement>;
  requestParams!: RequestParams;
  resetDatePicker = false;
  agentProfileForm!: FormGroup;
  isAdvancedSearch = false;
  totalPages = 0;
  totalRecords = 0;
  pageData: AgentProfileResponseData[] = [];
  isLoading = false;
  isFirstLoad = true;
  recordsMoreThan30thousand = false;
  displayedColumns: string[] = [
    'agentPersonCode',
    'agentCode',
    'agentContractStatus',
    'englishName',
    'chineseName',
    'gender',
    'dob',
    'mobileTel',
    'officeTel',
    'email',
    'joiningDate',
    'contractEffectiveDate',
    'contractTerminationDate',
    'branchCode',
    'branchName',
    'location',
    'rankCode',
    'rankDescShort',
    'rankDesc',
  ];

  exportColumns: string[] = [
    'Agent Person Code',
    'Agent Code',
    'Agent Status',
    'English Name',
    'Chinese Name',
    'Gender',
    'DoB',
    'Mobile Tel.',
    'Office Tel.',
    'Email',
    'Joining Date',
    'Contract Effective Date',
    'Contract Termination Date',
    'Branch Code',
    'Branch Name',
    'Location',
    'Rank Code',
    'Rank Desc (Short)',
    'Rank Desc',
  ];

  agentContractStatusOrigin: CdMultidropdownTopping[] = [];
  mpfLicenseStatusOrigin: CdMultidropdownTopping[] = [];

  constructor(public agentProfileListService: AgentProfileListService, private cdsPopup: CdsPopupService) {}

  ngOnInit(): void {
    this.agentProfileForm = new FormGroup(
      {
        agentPersonCode: new FormControl(null),
        agentCode: new FormControl(null),
        englishName: new FormControl(null),
        agentContractStatus: new FormControl(null),
        joiningDateFrom: new FormControl(null, dateValidator),
        joiningDateTo: new FormControl(null, dateValidator),
        contractTerminationDateFrom: new FormControl(null, dateValidator),
        contractTerminationDateTo: new FormControl(null, dateValidator),
        branchCode: new FormControl(null),
        branchHeadAgentCode: new FormControl(null),
        unitCode: new FormControl(null),
        directManagerCode: new FormControl(null),
        mpfLicenseStatus: new FormControl(null),
      },
      g => {
        const joiningDateFromVal = g.get('joiningDateFrom')?.value;
        const joiningDateToVal = g.get('joiningDateTo')?.value;
        const contractTerminationDateFromVal = g.get('contractTerminationDateFrom')?.value;
        const contractTerminationDateToVal = g.get('contractTerminationDateTo')?.value;
        if (
          joiningDateFromVal != null &&
          joiningDateToVal != null &&
          moment(joiningDateToVal, 'DD/MM/YYYY', true).isValid() &&
          moment(joiningDateFromVal, 'DD/MM/YYYY', true).isValid() &&
          moment(joiningDateToVal, 'DD/MM/YYYY', true) < moment(joiningDateFromVal, 'DD/MM/YYYY', true)
        ) {
          return { invalidJoiningDateInput: true };
        }
        if (
          contractTerminationDateToVal != null &&
          contractTerminationDateFromVal != null &&
          moment(contractTerminationDateToVal, 'DD/MM/YYYY', true).isValid() &&
          moment(contractTerminationDateFromVal, 'DD/MM/YYYY', true).isValid() &&
          moment(contractTerminationDateToVal, 'DD/MM/YYYY', true) < moment(contractTerminationDateFromVal, 'DD/MM/YYYY', true)
        ) {
          return { contractTerminationDateInput: true };
        }
        return null;
      }
    );
    this.agentProfileSubscription = this.agentProfileListService.agentProfileSubject.subscribe(response => {
      if (this.isFirstLoad) {
        this.pageData = [];
        this.totalPages = 0;
        this.totalRecords = 0;
      } else {
        if (response.result != 0) {
          this.pageData = [];
          this.totalPages = 0;
          this.totalRecords = 0;
        } else if (response.data?.content) {
          const pageDataTmp = response.data.content;
          const totalPagesTmp = response.data ? response.data.totalPages : 0;
          const totalRecordsTmp = response.data ? response.data.totalElements : 0;
          if (totalRecordsTmp > 30000) {
            this.recordsMoreThan30thousand = true;
            this.cdsPopup.open(NarrowSearchPopupComponent, {
              size: 'md',
            });
          } else {
            this.pageData = pageDataTmp;
            this.totalPages = totalPagesTmp;
            this.totalRecords = totalRecordsTmp;
          }
        } else {
          this.pageData = [];
          this.totalPages = response.data ? response.data.totalPages : 0;
          this.totalRecords = response.data ? response.data.totalElements : 0;
        }
      }
      this.isLoading = false;
    });
  }

  onAgentProfileSearchSubmit() {
    this.isLoading = true;
    this.isFirstLoad = false;
    const params: AgentProfileRequest = this.createParams();
    this.agentProfileListService.queryAgentProfiles(params);
  }

  get currentPageData() {
    if (this.pageData.length === 0) {
      return [];
    }
    return this.pageData;
  }

  get isSearchDisable() {
    let tag = true; // search button default is disabled
    const controls = this.agentProfileForm.controls;
    const keys = Object.keys(controls);
    keys.forEach((key: string) => {
      const defaultValue = (controls[key] as FormControl).defaultValue;
      const controlValue = (controls[key] as FormControl).value || null;
      if (key === 'agentContractStatus' || key === 'mpfLicenseStatus') {
        if (defaultValue !== controlValue && controlValue.length != 0) {
          tag = false;
        }
      } else {
        if (defaultValue !== controlValue) {
          tag = false;
        }
      }
    });
    return tag || this.agentProfileForm.invalid;
  }

  onClickReset() {
    this.agentProfileForm.reset();
    this.agentContractStatusOrigin = [];
    this.mpfLicenseStatusOrigin = [];

    this.resetDatePicker = true;
    setTimeout(() => {
      this.resetDatePicker = false;
    }, 0);

    // reset all data to [], including the total pages and total records
    this.pageData = [];
    this.totalRecords = 0;
  }

  get checkIsHaveScroll() {
    if (this.tableBox) {
      return this.tableBox.nativeElement.scrollWidth > this.tableBox.nativeElement.clientWidth;
    }
    return false;
  }

  pageChange(num: number): void {
    this.pageConfig = { current: num };
    this.onAgentProfileSearchSubmit();
  }

  onAdvancedSearch() {
    this.isAdvancedSearch = !this.isAdvancedSearch;
    if (!this.isAdvancedSearch) {
      this.agentProfileForm.get('agentContractStatus')?.reset();
      this.agentContractStatusOrigin = [];
      this.agentProfileForm.get('mpfLicenseStatus')?.reset();
      this.mpfLicenseStatusOrigin = [];
    }
  }

  ngOnDestroy(): void {
    this.agentProfileSubscription?.unsubscribe();
  }
  pageConfig: PageConfig = {};
  createParams() {
    const formValue = this.getFormValue();
    const params: AgentProfileRequest = {
      page: (this.pageConfig.current || 1) - 1,
      size: 50,
      sort: 'agentPersonCode asc',
      ...formValue,
    };
    return params;
  }

  getFormValue() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const value: any = {};
    const tempValue = this.agentProfileForm.value;
    const keys: string[] = Object.keys(tempValue);
    keys.forEach(key => {
      if (key == 'agentContractStatus' || key == 'mpfLicenseStatus') {
        const selected = tempValue[key];
        if (selected != null) {
          const valueTmp: string[] = [];
          for (let i = 0; i < selected.length; i++) {
            valueTmp.push(selected[i].value);
          }
          value[key] = valueTmp;
        }
      } else if (key == 'joiningDateFrom' || key == 'joiningDateTo' || key == 'contractTerminationDateFrom' || key == 'contractTerminationDateTo') {
        const dateStr = tempValue[key];
        if (dateStr != null && dateStr !== '' && dateStr.trim().length == 10 && dateStr.substr(2, 1) == '/' && dateStr.substr(5, 1) == '/') {
          const day = dateStr.substr(0, 2);
          const month = dateStr.substr(3, 2);
          const year = dateStr.substr(6, 4);
          value[key] = year + month + day;
        }
      } else {
        const controlValue = tempValue[key];
        if (controlValue != '' && controlValue != null) {
          value[key] = controlValue.trim();
        }
      }
    });
    return value;
  }

  get isExportBtnDisabled() {
    let flag = false;
    if (this.pageData.length == 0) {
      flag = true;
    }
    return flag;
  }

  isDownLoading = false;
  exportResult() {
    if (this.isExportBtnDisabled) {
      return;
    }
    this.isDownLoading = true;
    const params: AgentProfileRequest = this.createParams();
    this.agentProfileListService
      .download(params)
      .pipe(
        finalize(() => {
          this.isDownLoading = false;
        }),
        filter(res => res.result === 0)
      )
      .subscribe(res => {
        if (this.isClickX) {
          this.isClickX = false;
          return;
        }

        const excelData = this.createExcelData(res.data?.agentProfiles || []);
        aoaToSheet(
          {
            fileName: `agent profile list ${moment().format('YYYYMMDD_HHmmss')}`,
            fileType: FileType.XLSX,
          },
          excelData
        );
      });
  }

  createExcelData(data: any[]) {
    const excelData: any[] = [];
    excelData.push(this.exportColumns);
    data.forEach(item => {
      const temp: any = [];
      this.displayedColumns.forEach(col => {
        temp.push(item[col]);
      });

      excelData.push(temp);
    });
    return excelData;
  }

  isClickX = false;
  onClickX() {
    this.isClickX = true;
    this.isDownLoading = false;
  }
}

function dateValidator(control: AbstractControl): ValidationErrors | null {
  if (!control.value) {
    return null;
  }
  const isDate = moment(control.value, 'DD/MM/YYYY', true).isValid();
  return isDate ? null : { error: 'invalid date' };
}
