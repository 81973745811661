import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PermissionAccess } from 'src/app/core/models/enum/permission.enum';
import { AdminLayoutComponent } from '../layout/admin-layout/admin-layout.component';
import { AgentPerformanceComponent } from './agent-performance.component';
import { AgentPerformanceHeadComponent } from 'src/app/views/shk/agent-performance-head/agent-performance-head.component';

const routes: Routes = [
  {
    path: 'agent-performance',
    component: AdminLayoutComponent,
    children: [
      { path: '', pathMatch: 'full', redirectTo: 'list' },
      {
        path: 'list',
        component: AgentPerformanceComponent,
        data: {
          title: 'termination.performanceTitle',
          roles: {
            SHK_AGENT_ASSIGNMENT_REPORT: PermissionAccess.R,
          },
        },
      },
      {
        path: 'head',
        component: AgentPerformanceHeadComponent,
        data: {
          title: 'agent-performance-head.title',
          roles: {
            SHK_AGENT_ASSIGNMENT_REPORT_AGENT_HEAD: PermissionAccess.R,
          },
        },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AgentPerformanceRoutingModule {}
