export enum CommissionRate {
  FULL_COMM = 'FULL_COMM',
  PARTIAL_COMM1 = 'PARTIAL_COMM1',
  PARTIAL_COMM2 = 'PARTIAL_COMM2',
  PARTIAL_COMM3 = 'PARTIAL_COMM3',
  NO_COMM = 'NO_COMM',
  WSM_PARTIAL_COMM1 = 'WSM_PARTIAL_COMM1',
  WSM_PARTIAL_COMM2 = 'WSM_PARTIAL_COMM2',
  WSM_PARTIAL_COMM3 = 'WSM_PARTIAL_COMM3',
  WSM_PARTIAL_COMM4 = 'WSM_PARTIAL_COMM4',
  SHKP_PARTIAL_COMM1 = 'SHKP_PARTIAL_COMM1',
  SHKP_PARTIAL_COMM2 = 'SHKP_PARTIAL_COMM2',
  SHKP_PARTIAL_COMM3 = 'SHKP_PARTIAL_COMM3',
}
