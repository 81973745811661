import { deepCopy } from 'src/app/utils/copy';
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { MENUS } from 'src/app/config/menu.config';
import { PermissionAccess } from '../models/enum/permission.enum';
import { Item, Menu } from '../models/menu';
import { Session } from '../models/user';
import { AuthenticationService } from './authentication.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PermissionService {
  constructor(private authService: AuthenticationService, private router: Router) {}

  menuFilter(menus: any[]) {
    const menuExclusion: string[] = environment.menuExclusion;
    const filterMenus: any[] = [];
    if (menus.length > 0) {
      menus.forEach(item => {
        if (item.subMenu && item.subMenu.length > 0) {
          item.subMenu = this.menuFilter(item.subMenu);
        }
        if ((!item.id && !item.subLink) || (item.id && !menuExclusion.includes(item.id) && item.subMenu.length > 0)) {
          filterMenus.push(item);
        }
        if (item.subLink && !menuExclusion.includes(item.subLink)) {
          filterMenus.push(item);
        }
      });
    }
    return filterMenus;
  }

  getMenuExclusions() {
    const menuExclusions: string[] = environment.menuExclusion;
    menuExclusions.forEach(menuExclusion => {
      MENUS.forEach(menu => {
        if (menuExclusion == menu.id) {
          menu.subMenu?.forEach(subMenu => {
            if (subMenu.subLink) menuExclusions.push(subMenu.subLink);
          });
        }
      });
    });
    return menuExclusions;
  }
  /**
   * init menus
   * @returns Promise<Menu[]>
   */
  async initMenu(): Promise<Menu[]> {
    return new Promise(resolve => {
      this.getUserInfo().then(userInfo => {
        const _MENUS: Menu[] = this.menuFilter(deepCopy(MENUS));
        _MENUS.forEach(m => {
          const subMenu: Item[] = [];
          m.subMenu?.forEach(sm => {
            this.router.config.forEach(route => {
              const pre = (route.path == '' ? '' : '/') + route.path;
              route.children?.forEach(c => {
                let tempPath = c.path;
                let smPath = sm.subLink;
                if (smPath?.indexOf('?') !== -1) {
                  smPath = smPath?.substring(0, smPath.lastIndexOf('?'));
                }
                if (tempPath?.indexOf(':') !== -1) {
                  const re = new RegExp('\\/\\:\\w+', 'g');
                  tempPath = tempPath?.replace(re, '');
                }
                const path = pre + (tempPath == '' ? '' : '/') + tempPath;
                if (smPath == path) {
                  const has = this.permissionFilter(c.data?.['roles'], userInfo);
                  if (has) {
                    subMenu.push(sm);
                  }
                }
              });
            });
          });
          m.subMenu = subMenu;
        });
        const _menus = _MENUS.filter(m => (m.subMenu ? m.subMenu?.length > 0 : false));
        resolve(_menus);
      });
    });
  }

  /**
   * Judge whether there is permission
   * @param roles expect value
   * @param userInfo user login info
   * @returns Promise<boolean>
   */
  hasPermission(role: string, permission: string): Promise<boolean> {
    const roles = { [permission]: role };
    return new Promise(resolve => {
      this.getUserInfo().then((userInfo: Session) => {
        resolve(this.permissionFilter(roles, userInfo));
      });
    });
  }

  /**
   * getUserInfo
   * @returns userInfo
   */
  async getUserInfo(): Promise<Session> {
    return this.authService.currentUserValue();
  }

  /**
   * Check permissions, userInfo you can call getUserInfo()
   * @param roles expect value
   * @param userInfo user login info
   * @returns boolean
   */
  permissionFilter(roles: object, userInfo: Session): boolean {
    // if page not set permission,page will show
    if (!roles) {
      return true;
    }
    if (!userInfo || !userInfo.permissions) {
      return false;
    }

    let flag = false;
    const userAccess = new Map(Object.entries(userInfo.permissions));
    Object.entries(roles).forEach(([role, permission]) => {
      switch (permission as unknown as keyof typeof PermissionAccess) {
        case PermissionAccess.R:
          flag = flag || this.hasR(userAccess.get(role));
          break;
        case PermissionAccess.W:
          flag = flag || this.hasW(userAccess.get(role));
          break;
        case PermissionAccess.E:
          flag = flag || this.hasE(userAccess.get(role));
          break;
      }
    });
    return flag;
  }

  /**
   * Check permissions, with no need to get userInfo
   * @param roles expect value
   * @returns Promise<boolean>
   */
  async permissionFilterAsync(roles: object): Promise<boolean> {
    const userInfo = await this.getUserInfo();
    return this.permissionFilter(roles, userInfo);
  }

  hasR(role: string): boolean {
    return this.hasW(role) || role == PermissionAccess.R;
  }

  hasW(role: string): boolean {
    return this.hasE(role) || role == PermissionAccess.W;
  }

  hasE(role: string): boolean {
    return role == PermissionAccess.E;
  }
}
