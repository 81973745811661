import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ageRange',
})
export class AgeRangePipe implements PipeTransform {
  transform(_value: number, lan: string): string {
    let ageRange = '';
    if (18 <= _value && _value <= 25) {
      ageRange = '18-25';
    } else if (26 <= _value && _value <= 35) {
      ageRange = '26-35';
    } else if (36 <= _value && _value <= 45) {
      ageRange = '36-45';
    } else if (46 <= _value && _value <= 55) {
      ageRange = '46-55';
    } else if (56 <= _value && _value <= 65) {
      ageRange = '56-65';
    } else if (66 <= _value) {
      if (lan === 'TC') {
        ageRange = '66以上';
      } else {
        ageRange = '66 or above';
      }
    }
    return ageRange;
  }
}
