<app-cd-page-title title="{{ 'commission.journal.title' | lang }}"> </app-cd-page-title>
<div class="row">
  <div class="col-xs-12 l-mb-8">
    <div class="cds-h2-light l-pt-7 l-mb-7">
      {{ 'commission.pool.common.searchRecord' | lang }}
    </div>
    <form class="l-mb-7" [formGroup]="quickSearchGroup" (ngSubmit)="search()">
      <div class="l-pb-2 div-border-bottom l-mb-5">
        <span class="cds-h4 cds-demibold cds-green-text">{{ 'commission.journal.quickSearchTitle' | lang }} </span>
      </div>
      <div class="l-d-f quick-search l-ai-fe">
        <div class="l-mr-7">
          <div class="l-p-rel input-box">
            <input
              [formControl]="referenceNo"
              class="cds-body-hero-light l-pr-7"
              placeholder="{{ 'commission.journal.referenceNo.placeholder' | lang }}"
              type="text" />
            <cds-icon icon="form:search"></cds-icon>
          </div>
          <cds-assistive-text *ngIf="referenceNo.errors && referenceNo.dirty">{{ referenceNo.errors['error'] | lang }}</cds-assistive-text>
        </div>
        <cds-button type="submit" [disabled]="quickSearchDisabled" [style]="'primary'">{{ 'common.quickSearch' | lang }} </cds-button>
      </div>
    </form>
    <form [formGroup]="formGroup" (ngSubmit)="search()">
      <div class="l-pb-2 div-border-bottom l-mb-5">
        <span class="cds-h4 cds-demibold cds-green-text">{{ 'common.filterSearch' | lang }} </span>
      </div>
      <p class="cds-body2-light cds-coral-text dark-1 l-mb-5 l-mt-5">
        {{ 'common.fillIn' | lang }}
      </p>
      <div class="div-border-bottom">
        <div class="row l-plr-0 l-pb-4">
          <div class="cds-h5 col-xs-12 col-md-12 l-plr-0">
            {{ 'common.dateRange' | lang }}
            <span class="cds-coral-text dark-1">*</span>
          </div>

          <div class="col-xs-12 col-md-6 col-lg-3 l-pl-0 l-pr-4 l-pb-4">
            <div *ngIf="isShowSearchDatePicker">
              <label class="cds-body2 font-color-light3 l-d-ib l-mb-3"
                >{{ 'commission.common.runDateFrom' | lang }} <span class="cds-coral-text dark-1">*</span></label
              >
              <cds-textfield class="l-pb-3" placeholder="DD/MM/YYYY" [cdsDatepicker]="fromDateFromPicker" [formControl]="runDateFrom"> </cds-textfield>
              <cds-datepicker #fromDateFromPicker="cdsDatepicker"></cds-datepicker>
            </div>
            <cds-assistive-text *ngIf="runDateFrom.errors && runDateFrom.dirty">{{ runDateFrom.errors['error'] | lang }}</cds-assistive-text>
            <cds-assistive-text *ngIf="formGroup.errors && formGroup.errors['dateRangeInComplete'] && formGroup.dirty && !runDateFrom.errors">{{
              formGroup.errors['dateRangeInComplete'] | lang
            }}</cds-assistive-text>
            <cds-assistive-text *ngIf="formGroup.errors && formGroup.errors['dateRangeInvalid'] && formGroup.dirty && !runDateFrom.errors">{{
              formGroup.errors['dateRangeInvalid'] | lang
            }}</cds-assistive-text>
          </div>

          <div class="l-pr-4 l-pt-7 desktop-md-hide">-</div>

          <div class="col-xs-12 col-md-6 col-lg-3 l-pl-0 l-pr-4 l-pb-4">
            <div *ngIf="isShowSearchDatePicker">
              <label class="cds-body2 font-color-light3 l-d-ib l-mb-3"
                >{{ 'commission.common.runDateTo' | lang }} <span class="cds-coral-text dark-1">*</span></label
              >
              <cds-textfield class="l-pb-3" placeholder="DD/MM/YYYY" [cdsDatepicker]="fromDateToPicker" [formControl]="runDateTo"> </cds-textfield>
              <cds-datepicker #fromDateToPicker="cdsDatepicker"></cds-datepicker>
            </div>
            <cds-assistive-text *ngIf="runDateTo.errors && runDateTo.dirty">{{ runDateTo.errors['error'] | lang }}</cds-assistive-text>
          </div>
        </div>

        <div class="row l-plr-0 l-pb-6">
          <div class="cds-h5 col-xs-12 col-md-12 l-plr-0">
            {{ 'commission.journal.criteriaType' | lang }}
          </div>

          <div class="col-xs-12 col-md-6 col-lg-3 l-pl-0 l-pr-4 l-pb-4">
            <app-cd-autocomplete
              [placeholder]="accountTypeConfig.placeholder"
              [label]="accountTypeConfig.label"
              [control]="accountType"
              [options]="accountTypeConfig.options"></app-cd-autocomplete>
          </div>

          <div class="col-xs-12 col-md-6 col-lg-3 l-pl-0 l-pr-4 l-pb-4">
            <app-cd-autocomplete
              [placeholder]="txnConfig.placeholder"
              [label]="txnConfig.label"
              [control]="txnCode"
              [options]="txnConfig.options"></app-cd-autocomplete>
          </div>

          <div class="col-xs-12 col-md-6 col-lg-3 l-pl-0 l-pr-4 l-pb-4">
            <app-cd-autocomplete
              [placeholder]="subActivityCdConfig.placeholder"
              [label]="subActivityCdConfig.label"
              [control]="subActivityCd"
              [options]="subActivityCdConfig.options"></app-cd-autocomplete>
          </div>
        </div>

        <div class="row l-plr-0 l-pb-5">
          <div class="cds-h5 col-xs-12 col-md-12 l-plr-0">
            {{ 'commission.common.specificNo' | lang }}
          </div>

          <div class="col-xs-12 col-md-6 col-lg-3 l-pl-0 l-pr-4 l-pb-4">
            <div>
              <label class="cds-body2 font-color-light3 l-d-ib l-mb-3">{{ 'commission.common.accountNo' | lang }}</label>
              <cds-textfield class="l-pb-3" [placeholder]="'commission.common.accountNo.placeholder' | lang" [formControl]="employerAccountCode">
              </cds-textfield>
              <cds-assistive-text *ngIf="employerAccountCode.errors && employerAccountCode.dirty">{{
                employerAccountCode.errors['error'] | lang
              }}</cds-assistive-text>
            </div>
          </div>

          <div class="col-xs-12 col-md-6 col-lg-3 l-pl-0 l-pr-4 l-pb-4">
            <div>
              <label class="cds-body2 font-color-light3 l-d-ib l-mb-3">{{ 'commission.common.memberNo' | lang }}</label>
              <cds-textfield class="l-pb-3" [placeholder]="'commission.common.placeholder.memberNo' | lang" [formControl]="memberAccountCode"> </cds-textfield>
              <cds-assistive-text *ngIf="memberAccountCode.errors && memberAccountCode.dirty">{{
                memberAccountCode.errors['error'] | lang
              }}</cds-assistive-text>
            </div>
          </div>

          <div class="col-xs-12 col-md-6 col-lg-3 l-pl-0 l-pr-4 l-pb-4">
            <div>
              <div class="l-d-f">
                <label class="cds-body2 font-color-light3 l-d-ib l-mb-3 l-mr-2">{{ 'common.journal.originalErMerCode' | lang }}</label>
                <cds-icon class="cursor-pointer" (click)="alertFutherInfo()" icon="action:info_1"></cds-icon>
              </div>
              <cds-textfield class="l-pb-3" [placeholder]="'common.journal.originalErMerCode.placeholder' | lang" [formControl]="originalErMerCode">
              </cds-textfield>
              <cds-assistive-text *ngIf="formGroup.errors && formGroup.errors['originalErMerCodeError'] && formGroup.dirty">{{
                formGroup.errors['originalErMerCodeError'] | lang
              }}</cds-assistive-text>
            </div>
          </div>

          <div class="col-xs-12 col-md-6 col-lg-3 l-pl-0 l-pr-4 l-pb-4">
            <div>
              <label class="cds-body2 font-color-light3 l-d-ib l-mb-3">{{ 'commission.common.agentCode' | lang }}</label>
              <cds-textfield class="l-pb-3" [placeholder]="'commission.common.agentCode.placeholder' | lang" [formControl]="agentCode"> </cds-textfield>
              <cds-assistive-text *ngIf="agentCode.errors && agentCode.dirty">{{ agentCode.errors['error'] | lang }}</cds-assistive-text>
            </div>
          </div>
        </div>
      </div>

      <div class="l-mt-7 l-mb-7">
        <div class="l-d-f l-ai-cen">
          <cds-checkbox [formControl]="noCommission"></cds-checkbox>
          <label class="l-ml-3 cds-body cds-dark-navy-text l-mb-0">{{ 'commission.journal.noCommission' | lang }}</label>
        </div>
        <!-- <cds-checkbox label="{{ 'commission.journal.noCommission' | lang }}" [formControl]="noCommission"> </cds-checkbox> -->
      </div>

      <div class="row l-plr-0 l-pb-3">
        <div class="col-xs-12 col-md-6 col-lg-3 l-plr-0 search-area-btn">
          <cds-button type="submit" [disabled]="searchDisabled" [style]="'primary'" size="md">{{ 'common.search' | lang }} </cds-button>
          <cds-button (click)="reset()" [style]="'secondary'" size="md">{{ 'common.reset' | lang }} </cds-button>
        </div>
      </div>
    </form>
  </div>
  <div class="col-xs-12" *ngIf="(previousSearchParams || isDataLoading) && !isOverMax">
    <app-cd-table-title class="l-pt-3 l-pb-3" [title]="'common.recordList' | lang" [total]="totalElements">
      <ng-template #tableAction>
        <cds-button (click)="download()" [disabled]="downloadDisabled" [style]="'secondary'" size="sm">
          <span>{{ 'common.download' | lang }}</span>
        </cds-button>
      </ng-template>
    </app-cd-table-title>

    <div class="table">
      <div class="loading-box" *ngIf="isDataLoading">
        <app-loading [overlay]="true"></app-loading>
      </div>
      <div *ngIf="totalElements">
        <table class="l-mb-4">
          <caption></caption>
          <thead>
            <tr scope="col" class="cds-white table-title">
              <th class="div-border-top div-border-bottom" *ngFor="let item of columns">
                <span class="cds-h6-demibold">{{ item.title | lang }}</span>
              </th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let item of data">
              <tr class="table-title">
                <td class="div-border-top div-border-bottom" *ngFor="let colum of columns">
                  <span class="cds-body">{{ item[colum.key] }}</span>
                </td>
              </tr>
              <tr class="cds-white table-title">
                <td [colSpan]="columns.length" class="table-expand-td l-pl-4">
                  <div class="cds-detail1 cds-italic cds-demibold l-mb-4">
                    {{ 'commission.journal.details' | lang }}
                  </div>
                  <div class="row l-mb-3 l-pl-0 l-pr-0">
                    <div class="col-xs-4 col-md-3 l-mb-4 l-pl-0 l-pr-0">
                      <label class="cds-body2 cds-dark-navy-text light-3 l-d-b l-mb-1">{{ 'commission.common.transactionReferenceNo' | lang }}</label>
                      <span class="cds-body1 details">{{ item['transactionReferenceNo'] }}</span>
                    </div>
                    <div class="col-xs-4 col-md-3 l-mb-4 l-pl-0 l-pr-0">
                      <label class="cds-body2 cds-dark-navy-text light-3 l-d-b l-mb-1">{{ 'commission.common.groupReferenceNo' | lang }}</label>
                      <span class="cds-body1 details">{{ item['groupReferenceNo'] }}</span>
                    </div>
                    <div class="col-xs-4 col-md-3 l-mb-4 l-pl-0 l-pr-0">
                      <label class="cds-body2 cds-dark-navy-text light-3 l-d-b l-mb-1">{{ 'commission.common.payReferenceNo' | lang }}</label>
                      <span class="cds-body1 details">{{ item['payReferenceNo'] }}</span>
                    </div>
                    <div class="col-xs-4 col-md-3 l-mb-4 l-pl-0 l-pr-0">
                      <label class="cds-body2 cds-dark-navy-text light-3 l-d-b l-mb-1">{{ 'common.journal.originalErMerCode' | lang }}</label>
                      <span class="cds-body1 details">{{ item['originalErMerCode'] }}</span>
                    </div>
                    <div class="col-xs-4 col-md-3 l-mb-4 l-pl-0 l-pr-0">
                      <label class="cds-body2 cds-dark-navy-text light-3 l-d-b l-mb-1">{{ 'commission.common.txn' | lang }}</label>
                      <span class="cds-body1 details">{{ item['txnCode'] }}</span>
                    </div>
                    <div class="col-xs-4 col-md-3 l-mb-4 l-pl-0 l-pr-0">
                      <label class="cds-body2 cds-dark-navy-text light-3 l-d-b l-mb-1">{{ 'commission.journal.txnEffectiveDate' | lang }}</label>
                      <span class="cds-body1 details">{{ item['txnEffectiveDate'] }}</span>
                    </div>
                    <div class="col-xs-4 col-md-3 l-mb-4 l-pl-0 l-pr-0">
                      <label class="cds-body2 cds-dark-navy-text light-3 l-d-b l-mb-1">{{ 'commission.common.contributionFrequency' | lang }}</label>
                      <span class="cds-body1 details">{{ item['contributionFrequency'] }}</span>
                    </div>
                    <div class="col-xs-4 col-md-3 l-mb-4 l-pl-0 l-pr-0">
                      <label class="cds-body2 cds-dark-navy-text light-3 l-d-b l-mb-1">{{ 'commission.common.totalPremiumReceived' | lang }}</label>
                      <span class="cds-body1 details">{{ item['totalPremiumReceived'] | mynumber : 2 }}</span>
                    </div>
                    <div class="col-xs-4 col-md-3 l-mb-4 l-pl-0 l-pr-0">
                      <label class="cds-body2 cds-dark-navy-text light-3 l-d-b">{{ 'commission.journal.commFlag' | lang }}</label>
                      <span class="cds-body1 details">{{ item['commFlag'] }}</span>
                    </div>
                    <div class="col-xs-4 col-md-3 l-mb-4 l-pl-0 l-pr-0">
                      <label class="cds-body2 cds-dark-navy-text light-3 l-d-b">{{ 'commission.common.pacFlag' | lang }}</label>
                      <span class="cds-body1 details">{{ item['pacFlag'] }}</span>
                    </div>
                    <div class="col-xs-4 col-md-3 l-mb-4 l-pl-0 l-pr-0">
                      <label class="cds-body2 cds-dark-navy-text light-3 l-d-b">{{ 'commission.common.processor' | lang }}</label>
                      <span class="cds-body1 details">{{ item['processor']?.name }}</span>
                    </div>
                    <div class="col-xs-4 col-md-3 l-mb-4 l-pl-0 l-pr-0">
                      <label class="cds-body2 cds-dark-navy-text light-3 l-d-b">{{ 'commission.common.commissionableAmount' | lang }}</label>
                      <span class="cds-body1 details">{{ item['commissionableAmount'] | mynumber : 2 }}</span>
                    </div>
                  </div>
                  <div class="cds-detail1 cds-italic cds-demibold l-mb-4">
                    {{ 'commission.common.agentDetails' | lang }}
                  </div>
                  <div class="child-table-box">
                    <table class="div-border child-table">
                      <caption></caption>
                      <thead>
                        <th class="div-border-top div-border-bottom" *ngFor="let columAgent of columnsAgent">
                          <span class="cds-body font-weight-6">{{ columAgent.title | lang }}</span>
                        </th>
                      </thead>
                      <tbody>
                        <tr *ngFor="let agent of item.list" class="cds-grey light" [class.div-border-bottom]="agent.isEnd">
                          <td *ngIf="agent.agentCodeCount" [rowSpan]="agent.agentCodeCount" class="rowspan div-border-top">
                            <span class="cds-body">{{ agent[columnsAgent[0].key] }}</span>
                          </td>
                          <td [class.div-border-top]="agent.agentCodeCount">
                            <span class="cds-body">{{ agent[columnsAgent[1].key] | mynumber : 3 }}%</span>
                          </td>
                          <td [class.div-border-top]="agent.agentCodeCount">
                            <span class="cds-body" *ngIf="!agent[columnsAgent[2].key]"></span>
                            <span class="cds-body" *ngIf="agent[columnsAgent[2].key]">{{ agent[columnsAgent[2].key] | mynumber : 4 }}%</span>
                          </td>
                          <td [class.div-border-top]="agent.agentCodeCount">
                            <span class="cds-body">{{ agent[columnsAgent[3].key] }}</span>
                          </td>
                          <td [class.div-border-top]="agent.agentCodeCount">
                            <span class="cds-body">{{ agent[columnsAgent[4].key] | mynumber : agent['amountDp'] }}</span>
                          </td>
                          <td [class.div-border-top]="agent.agentCodeCount">
                            <span class="cds-body">{{ agent[columnsAgent[5].key] | mynumber : 2 }}</span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </td>
              </tr>
            </ng-container>
          </tbody>
        </table>
        <app-pagination [useOf]="true" [pageCount]="pageCount" (pageChange)="pageChange($event)"> </app-pagination>
      </div>
      <div *ngIf="!totalElements && !isDataLoading">
        <app-no-result-found></app-no-result-found>
      </div>
    </div>
  </div>
</div>
<app-cd-download-progress [show]="isDownLoading"></app-cd-download-progress>
