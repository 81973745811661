/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { SortMode } from './data-table.model';
@Injectable()
export class DataTableService {
  sort(array: Array<any>, key: string, mode: string) {
    return array.sort((a: any, b: any) => {
      const aValue = a[key];
      const bValue = b[key];
      if (mode === SortMode.UP) {
        return this.compareStr(aValue, bValue);
      }
      if (mode === SortMode.DOWN) {
        return this.compareStr(bValue, aValue);
      }
      return 0;
    });
  }

  compareStr(a?: string | number, b?: string | number): number {
    let result = 0;
    if (!isNaN(Number(a)) && !isNaN(Number(b))) {
      result = Number(a) - Number(b);
    } else {
      const strA = a === undefined ? '' : a.toString();
      const strB = b === undefined ? '' : b.toString();
      const lengthStr = Math.max(strA.length, strB.length);
      for (let index = 0; index < lengthStr; index++) {
        result = this.compareChar(strA[index], strB[index]);
        if (result !== 0) {
          break;
        }
      }
    }
    console.log(a, result ? '>' : '<', b);
    return result;
  }

  compareChar(a: string | undefined, b: string | undefined) {
    if (typeof a === 'undefined') {
      return -1;
    }
    if (typeof b === 'undefined') {
      return 1;
    }
    if (this.isChineseChar(a) && this.isChineseChar(b)) {
      return a.localeCompare(b);
    }
    if (!this.isChineseChar(a) && this.isChineseChar(b)) {
      return -1;
    }
    if (this.isChineseChar(a) && !this.isChineseChar(b)) {
      return 1;
    }

    return a.charCodeAt(0) - b.charCodeAt(0);
  }

  isChineseChar(char: string) {
    return /^[\u4E00-\u9FA5]$/.test(char);
  }

  isUndefined(value: any): boolean {
    return typeof value == 'undefined';
  }

  validatePage(value: any): boolean {
    if (value === 0) {
      return false;
    }
    return /^[0-9]+$/.test(value);
  }

  validateTotalCount(value: any): boolean {
    return /^[0-9]+$/.test(value);
  }

  constructor() {}
}
