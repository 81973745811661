<div class="row col-xs-12 col-md-12 l-pb-6 l-pt-6 background-grey advise-empfstatus">
  <div>
    <p class="cds-h5-light l-ma-0 font-bold l-pl-7 l-pb-2">eMPF Status Flow</p>
    <ol style="height: 462px; overflow-y: auto">
      <!-- <li>
          <p class="cds-h5-light l-ma-0 font-bold l-pl-2 ">IFF Status Flow</p>
           </li> -->
      <li class="empfstatus-align l-pb-2">
        <div class="empfstatus-lightalign">
          <div class="flex-center" style="background-color: #06c7ba">1</div>
        </div>
        <div style="flex-direction: column; display: flex">
          <span class="empfstatus-keyword">eMPF Processing</span>
          <span class="empfstatus-normalword"> The record is currently under progress in eMPF </span>
        </div>
      </li>
      <li class="empfstatus-align l-pb-2">
        <div class="empfstatus-lightalign">
          <div class="flex-center" style="background-color: #0000c1">2</div>
        </div>
        <div style="flex-direction: column; display: flex">
          <span class="empfstatus-keyword">eMPF Follow-up</span>
          <span class="empfstatus-normalword">The record is currently under follow-up in eMPF </span>
        </div>
      </li>
      <li class="empfstatus-align l-pb-2">
        <div class="empfstatus-lightalign">
          <div class="flex-center" style="background-color: #a00e18">3</div>
        </div>
        <div style="flex-direction: column; display: flex">
          <span class="empfstatus-keyword">eMPF Application cancelled</span>
          <span class="empfstatus-normalword">The record is canclled in eMPF </span>
        </div>
      </li>
      <li class="empfstatus-align l-pb-2">
        <div class="empfstatus-lightalign">
          <div class="flex-center" style="background-color: #06874e">4</div>
        </div>
        <div style="flex-direction: column; display: flex">
          <span class="empfstatus-keyword">eMPF Completed</span>
          <span class="empfstatus-normalword">The record and onboarding are completed in eMPF </span>
        </div>
      </li>
      <li class="empfstatus-align l-pb-2">
        <div class="empfstatus-lightalign">
          <div class="flex-center" style="background-color: #8e90a2">5</div>
        </div>
        <div style="flex-direction: column; display: flex">
          <span class="empfstatus-keyword">eMPF Rejected</span>
          <span class="empfstatus-normalword">The record is being rejected in eMPF </span>
        </div>
      </li>
      <li class="empfstatus-align l-pb-2">
        <div class="empfstatus-lightalign">
          <div class="flex-center" style="background-color: #f49600">6</div>
        </div>
        <div style="flex-direction: column; display: flex">
          <span class="empfstatus-keyword">Pending eMPF data</span>
          <span class="empfstatus-normalword">The record is pending for eMPF data feed </span>
        </div>
      </li>
      <li class="empfstatus-align l-pb-2">
        <div class="empfstatus-lightalign">
          <div class="flex-center" style="background-color: #ec6453">7</div>
        </div>
        <div style="flex-direction: column; display: flex">
          <span class="empfstatus-keyword">Partially Completed</span>
          <span class="empfstatus-normalword">The record and onboarding are partially completed in eMPF </span>
        </div>
      </li>
      <li class="empfstatus-align l-pb-2">
        <div class="empfstatus-lightalign">
          <div class="flex-center" style="background-color: #604584">8</div>
        </div>
        <div style="flex-direction: column; display: flex">
          <span class="empfstatus-keyword">Not Applicable</span>
        </div>
      </li>
    </ol>

    <div class="row l-pt-6 col-xs-12 col-md-12 l-d-f l-jc-sb">
      <div></div>
      <cds-button label="Got it" (click)="backTosummary()" [config]="clearButton"></cds-button>
    </div>
  </div>
</div>
