import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { CdsButtonConfig } from '@cds/ng-core/button';
import { CdsOption } from '@cds/ng-core/configuration';
import { CdsLangService } from '@cds/ng-core/lang';
import { CdsRadioConfig } from '@cds/ng-core/radio';
import { CdsTableComponent } from '@cds/ng-data-table/table';
import { CdsAlertService } from '@cds/ng-web-components/alert';
import { CdsPopupService, CDS_POPUP_DATA } from '@cds/ng-web-components/popup';
import { finalize, Subscription } from 'rxjs';
import { PermissionAccess, PermissionItem } from 'src/app/core/models/enum/permission.enum';
import { PermissionService } from 'src/app/core/services/permission.service';
import { ContinuePopupComponent } from 'src/app/shared/continue-popup/continue-popup.component';
import { IPaginationCount } from 'src/app/shared/pagination/pagination.dto';
import { ToastAlertService } from 'src/app/shared/toast-alert.service';
import { descriptionValidator, numberStringValidator } from 'src/app/shared/validators/validators';
import { toPageableRequest } from 'src/app/utils/pagination.utils';
import { ErRecord, searchCompanyGroupRequest } from '../../employer';
import { CompanyGroupListRequest } from '../employer-profile-company-list';
import { EmployerProfileCompanyListService } from '../employer-profile-company-list.service';

const MIN_LENGTH = 2;

@Component({
  selector: 'app-edit-company-list',
  templateUrl: './edit-company-list.component.html',
  styleUrls: ['./edit-company-list.component.scss'],
})
export class EditCompanyListComponent implements OnInit {
  @ViewChild('table') table!: CdsTableComponent<ErRecord>;

  dataSource: ErRecord[] = [];

  addButtonConfig: CdsButtonConfig = { size: 'sm' };

  saveButtonConfig: CdsButtonConfig = { size: 'sm' };

  resetButtonConfig: CdsButtonConfig = { size: 'sm', style: 'secondary' };

  confirmButtonConfig: CdsButtonConfig = { size: 'sm' };

  backToEditButtonConfig: CdsButtonConfig = { size: 'sm', style: 'secondary' };

  companyRespOptions: CdsOption[] = [];

  selectedCompany: ErRecord | undefined = {
    id: '',
    employerName: '',
    employerChineseName: '',
  };

  codeTip = 'Please input numbers.';

  englishNameTip = 'Please input English, numbers, or special English characters.';

  chineseNameTip = 'Please input Chinese, numbers, or special characters.';

  form: FormGroup = new FormGroup({});

  searchForm: FormGroup = new FormGroup({});

  addDisabled = true;

  isLoading = false;

  show = true;

  ifShowAlert = false;

  alertValue = '';

  inEdit = true;

  pageCount: IPaginationCount = { current: 1, count: 1 };

  displayedColumnsInSummary: string[] = [
    'employerNo',
    'employerName',
    'totalLives',
    'action',
    // 'relationshipScore',
    // 'aggregateInvestedAmount',
    // 'annualizedContribution',
  ];

  displayedColumnsInEdit: string[] = [...this.displayedColumnsInSummary, 'delete'];

  radioConfig: CdsRadioConfig = {
    options: [{ label: '', value: 1 }],
  };

  subscription?: Subscription;

  cannotAddTip = 'The selected company is already in another linked company group, before adding, please remove it from the group first.';

  get resetDisabled() {
    if (this.data?.value) {
      return JSON.stringify(this.data.value) === JSON.stringify(this.dataSource);
    } else {
      return true;
    }
  }

  get saveDisabled() {
    if (this.data?.value) {
      return JSON.stringify(this.data.value) === JSON.stringify(this.dataSource);
    } else {
      return true;
    }
  }

  get employerNo() {
    return this.searchForm.get('employerNo') as FormControl;
  }

  get employerName() {
    return this.searchForm.get('employerName') as FormControl;
  }

  get employerChineseName() {
    return this.searchForm.get('employerChineseName') as FormControl;
  }

  searchUrl = 'customer-service/employer/searchCompanyGroup';

  searchParams: searchCompanyGroupRequest = {
    employerNo: '',
    employerName: '',
    employerChineseName: '',
    employerId: '',
  };

  selectedEr?: ErRecord;

  MIN_LENGTH = MIN_LENGTH;

  showSearchForm = true;

  haveDeletePermission = false;

  constructor(
    private dialogRef: MatDialogRef<EditCompanyListComponent>,
    private cdsPopup: CdsPopupService,
    private langService: CdsLangService,
    public companyService: EmployerProfileCompanyListService,
    private alert: CdsAlertService,
    private toastAlert: ToastAlertService,
    private permissionService: PermissionService,
    @Inject(CDS_POPUP_DATA)
    public data: {
      title: string;
      value: ErRecord[];
      id: string;
    }
  ) {}

  ngOnInit(): void {
    this.permissionService.hasPermission(PermissionAccess.E, PermissionItem.CUSTOMER_EMPLOYER_SUBSIDIARY).then(havePermission => {
      if (havePermission) {
        this.haveDeletePermission = true;
      }
      this.initSearchForm();
      this.initSearchParams();
      this.loadTable();
    });
  }

  init() {
    this.ifShowAlert = false;
    this.initForm();
    this.initSearchForm();
    this.initSearchParams();
  }

  loadTable() {
    if (!this.isLoading) {
      this.isLoading = true;
      // set 10000 to get all data
      const params: CompanyGroupListRequest = toPageableRequest(this.pageCount, 10000);
      params.id = this.data.id;
      params.showAmount = false;

      this.companyService
        .getCompanyGroupList(params)
        .pipe(
          finalize(() => {
            this.isLoading = false;
          })
        )
        .subscribe({
          next: res => {
            if (res.result === 0 && res.data) {
              this.dataSource = res.data.content || [];

              if (this.dataSource.length === 0) {
                this.dataSource = [...this.data.value];
              }

              this.initForm();
            } else {
              this.toastError(res.message);
            }
          },
          error: err => {
            console.error('Call role-list API error: ', err);
          },
        });
    }
  }

  initForm() {
    const group: { [key: string]: FormControl } = {};
    this.dataSource.forEach(element => {
      group[element.id] = new FormControl(this.isParentCompanySelectedRadio(element.parentCompany));

      group[this.getSummaryId(element.id)] = new FormControl(this.isParentCompanySelectedRadio(element.parentCompany));
    });
    this.form = new FormGroup(group);
  }

  initSearchForm() {
    this.showSearchForm = false;
    setTimeout(() => {
      this.showSearchForm = true;
      this.searchForm = new FormGroup({
        employerNo: new FormControl('', [Validators.minLength(MIN_LENGTH), Validators.maxLength(15), numberStringValidator()]),
        employerName: new FormControl('', [Validators.minLength(MIN_LENGTH), Validators.maxLength(200), descriptionValidator(this.englishNameTip)]),
        employerChineseName: new FormControl('', [
          Validators.minLength(MIN_LENGTH),
          Validators.maxLength(200),
          descriptionValidator(this.chineseNameTip, true, true),
        ]),
      });
    });
  }

  private companySearch(key: string) {
    if (!!key && key.trim().length > 0 && !this.isLoading) {
      this.companyRespOptions = [];
      if (this.subscription) {
        this.subscription.unsubscribe();
      }
      // this.isLoading = true;
      //
      this.subscription = this.companyService
        .searchCompany(key, this.data.id)
        .pipe(
          finalize(() => {
            this.isLoading = false;
          })
        )
        .subscribe({
          next: res => {
            if (res.result === 0 && res.data) {
              this.companyRespOptions = res.data.map(e => {
                return {
                  label: e.employerName,
                  details: e.employerNo,
                  value: {
                    ...e,
                    parentCompany: 'N',
                  },
                };
              });
            } else {
              this.toastError(res.message);
            }
          },
          error: err => {
            console.error(err);
          },
        });
    }
  }

  isParentCompanySelectedRadio(parentCompany: string | undefined) {
    return parentCompany === 'Y' ? 1 : undefined;
  }

  close() {
    if (!this.resetDisabled) {
      this.cdsPopup
        .open(ContinuePopupComponent, {
          size: 'sm',
          data: { message: this.langService.translate('common.action.cancel') },
        })
        .afterClosed()
        .subscribe(result => {
          if (result?.agree) {
            this.dialogRef.close();
          }
        });
    } else {
      this.dialogRef.close({
        agree: false,
      });
    }
  }

  searchChange(value: string, key: 'employerNo' | 'employerName' | 'employerChineseName') {
    const control = this.searchForm.get(key) as FormControl;
    this.searchParams[key] = control.invalid ? '' : value;
    this.searchParams = { ...this.searchParams };
    this.selectedEr = undefined;
    this.ifShowAlert = false;
  }

  initSearchParams() {
    this.searchParams = {
      employerNo: '',
      employerName: '',
      employerChineseName: '',
      employerId: this.data.id,
    };
  }

  selectChange(data: ErRecord) {
    this.selectedEr = data;
    this.ifShowAlert = false;
  }

  save() {
    if (this.saveDisabled) {
      return;
    }
    if (this.dataSource.length > 1) {
      const noParentCompany = this.dataSource.every(element => element.parentCompany !== 'Y');
      if (noParentCompany) {
        this.showAlert('Parent company should be selected.');
        return;
      }
    }
    this.inEdit = false;
    this.initForm();
  }

  reset() {
    if (this.resetDisabled) return;

    this.cdsPopup
      .open(ContinuePopupComponent, {
        size: 'sm',
        data: { message: this.langService.translate('common.action.cancel') },
      })
      .afterClosed()
      .subscribe(result => {
        if (result?.agree) {
          this.init();
        }
      });
  }

  resetSearch() {
    if (!this.searchForm.dirty) return;
    this.initSearchForm();
    this.initSearchParams();
    this.selectedEr = undefined;
    this.searchForm.markAsPristine();
    this.searchForm.markAsUntouched();
    this.ifShowAlert = false;
  }

  labelRenderFn = (key: 'employerNo' | 'employerName' | 'employerChineseName') => {
    return (data: ErRecord) => {
      if (key === 'employerNo') {
        return `${data.employerNo} - ${data.employerName}`;
      }
      return `${data.employerNo} - ${data[key]}`;
    };
  };

  companyNameRender(data: ErRecord) {
    return `${data.employerName}`;
  }

  chineseCompanyNameRender(data: ErRecord) {
    return `${data.employerChineseName}`;
  }

  delete(id: string) {
    const list: ErRecord[] = [];

    this.dataSource.forEach(element => {
      if (element.id !== id) {
        list.push(element);
      }
    });
    this.dataSource = list;
    this.checkAvailable();
  }

  radioChange(value: number, id: string) {
    this.dataSource = this.dataSource.map(element => {
      const data = { ...element };
      if (element.id === id) {
        data.parentCompany = 'Y';
      } else {
        data.parentCompany = 'N';
      }
      return data;
    });
    this.checkAvailable();
    this.initForm();
  }

  add() {
    if (this.selectedEr === undefined || this.searchForm.invalid) {
      return;
    }

    if (this.ifAlreadyExisted()) {
      this.showAlert(this.cannotAddTip);
      this.addDisabled = true;
      return;
    }
    this.isLoading = true;
    this.companyService
      .canAdd(this.data.id, this.selectedEr.id)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe({
        next: res => {
          if (res.result === 0 && this.selectedEr) {
            if (res.data === true) {
              this.dataSource = [...this.dataSource, this.selectedEr];
              this.checkAvailable();
              this.initForm();
              this.selectedEr = undefined;
            } else {
              this.showAlert(this.cannotAddTip);
            }
          } else {
            this.showAlert(res.message);
            this.addDisabled = true;
          }
        },
        error: err => {
          this.toastError(err.message);
        },
      });
  }

  checkAvailable() {
    // this.saveDisabled = false;
    this.ifShowAlert = false;
  }

  ifAlreadyExisted() {
    return this.dataSource.some(element => element.id === this.selectedEr?.id);
  }

  showAlert(value: string) {
    this.ifShowAlert = true;
    this.alertValue = value;
  }

  getDisplayedColumns(inEdit: boolean) {
    return inEdit && this.haveDeletePermission ? this.displayedColumnsInEdit : this.displayedColumnsInSummary;
  }

  getSummaryId(id: string) {
    return `summary-${id}`;
  }

  backToEdit() {
    this.inEdit = true;
    this.initForm();
  }

  confirm() {
    this.dialogRef.close({
      agree: true,
      data: this.dataSource,
    });
  }

  toastError(errorMessage: string) {
    this.toastAlert.show('error', 'common.error', errorMessage, 5000);
  }
}
