/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit } from '@angular/core';
import { CdsButtonConfig } from '@cds/ng-core/button';
import moment from 'moment';
import { finalize } from 'rxjs';
import { IPaginationCount } from 'src/app/shared/pagination/pagination.dto';
import { ToastAlertService } from 'src/app/shared/toast-alert.service';
import { CdsDropdownConfig } from '@cds/ng-core/dropdown';
import { DateTime } from 'luxon';
import { ColumnConfig, PageConfig, SortMode } from 'src/app/shared/data-table';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { dateValidator, descriptionValidator, startAndEndDateValidator } from 'src/app/shared/validators/validators';
import { deepCopy } from 'src/app/utils/copy';
import { Campaign, CampaignSearchRequest, CampaignTypeOptions, agentCommunicationOptions, productTypeOptions } from '../campaign';
import { ColumnSort } from '../../employer/employer';
import { EmployeeService } from '../../employee/employee.service';
import { EmployerService } from '../../employer/employer.service';
import { PermissionService } from 'src/app/core/services/permission.service';
import { PermissionAccess, PermissionItem } from 'src/app/core/models/enum/permission.enum';
import { BTN_CONFIG_PRIMARY, BTN_CONFIG_SECONDARY } from 'src/app/config/btn.config';
import { CdsPopupService } from '@cds/ng-web-components/popup';
import { AlertPopupComponent } from 'src/app/shared/alert-popup/alert-popup.component';
import { CdsLangService } from '@cds/ng-core/lang';
import { AddCampaignComponent } from '../add-campaign/add-campaign.component';
import { UploadCustomerListComponent } from '../upload-customer-list/upload-customer-list.component';
import { findOptionLabelByValue } from 'src/app/utils/utils';
import { CdsOption } from '@cds/ng-core/configuration';
import { yesOrNoFlagNumberOptions } from '../../employee/employee';

@Component({
  selector: 'app-campaign-master-list-member',
  templateUrl: './campaign-master-list-member.component.html',
  styleUrls: ['./campaign-master-list-member.component.scss'],
})
export class CampaignMasterListMemberComponent implements OnInit {
  searchButtonConfig: CdsButtonConfig = {
    size: 'sm',
  };

  resetButtonConfig: CdsButtonConfig = {
    size: 'sm',
    style: 'secondary',
  };

  exportButtonConfig: CdsButtonConfig = {
    size: 'sm',
    style: 'secondary',
  };

  get searchButtonDisabled() {
    return !this.form?.valid || !this.form.dirty;
  }

  get resetButtonDisabled() {
    return !this.form?.dirty;
  }

  exportButtonDisabled = true;

  isLoading = false;

  _totalPages = 0;

  totalElements = 0;

  pageCount: IPaginationCount = { current: 1, count: 1 };

  dataSource: Campaign[] = [];

  _number = 1;

  _inputNumber = 1;

  isMobile = false;

  showDatePicker = true;

  formReady = false;

  canEdit = false;

  campaignTypeConfig: CdsDropdownConfig = {
    label: 'Campaign Type',
    placeholder: 'Select Campaign Type',
    options: CampaignTypeOptions,
  };

  columnsConfig: ColumnConfig[] = [
    {
      key: 'campaignName',
      title: 'Campaign Name',
      sticky: true,
    },
    {
      key: 'campaignDescription',
      title: 'Campaign Description',
    },
    {
      key: 'campaignOwner',
      title: 'Campaign Owner',
    },
    {
      key: 'campaignType',
      title: 'Campaign Type',
    },
    {
      key: 'productTypes',
      title: 'Product Type',
    },
    {
      key: 'multipleAgentDisplay',
      title: 'Multiple Agent Display',
    },
    {
      key: 'displayToServicingAgent',
      title: 'Display the campaign to Servicing Agent',
    },
    {
      key: 'agentCommunication',
      title: 'Agent Communication',
    },
    {
      key: 'links',
      title: 'Links',
    },
  ];
  pageConfig: PageConfig = {
    current: 1,
    pageSize: 10,
    totalCount: 0,
  };

  tempSeardhParams: CampaignSearchRequest = {
    page: 1,
    size: 10,
  };

  nowTimeInstr = DateTime.now().toFormat('dd/MM/yyyy');

  maxTime = this.getNowTime();

  maxTimeInStr = DateTime.fromJSDate(this.maxTime).toFormat('dd/MM/yyyy');

  columnSort: ColumnSort = {
    key: 'createdAt',
    value: SortMode.DOWN,
  };

  form: FormGroup = new FormGroup({});

  campaignNameValidTip = 'Please input English, Chinese, numbers, or special characters';

  campaignOwnerValidTip = 'Please input English, Chinese, numbers, or special characters';

  get trusteeEmployerAccountNumberControl() {
    return this.form?.get('trusteeEmployerAccountNumber');
  }

  get campaignNameControl() {
    return this.form?.get('campaignName');
  }

  get campaignPeriodFromControl() {
    return this.form?.get('campaignPeriodFrom');
  }

  get campaignPeriodToControl() {
    return this.form?.get('campaignPeriodTo');
  }

  get campaignTypeControl() {
    return this.form?.get('campaignType');
  }

  get campaignOwnerControl() {
    return this.form?.get('campaignOwner');
  }

  addBtnCfg: CdsButtonConfig = BTN_CONFIG_SECONDARY;
  uploadBtnCfg: CdsButtonConfig = BTN_CONFIG_PRIMARY;

  productTypeOptions = productTypeOptions;
  agentCommunicationOptions = agentCommunicationOptions;
  CampaignTypeOptions = CampaignTypeOptions;
  yesOrNoFlagNumberOptions = yesOrNoFlagNumberOptions;

  findOptionLabelByValue = findOptionLabelByValue;

  constructor(
    public service: EmployeeService,
    private toastAlert: ToastAlertService,
    private cdsPopup: CdsPopupService,
    private permissionService: PermissionService,
    public employerService: EmployerService,
    private langService: CdsLangService
  ) {}

  ngOnInit(): void {
    this.getPermission();
    this.initForm();
    this.loadTable();
  }

  getPermission() {
    this.permissionService.hasPermission(PermissionAccess.W, PermissionItem.CUSTOMER_EMPLOYER).then(hasPermission => {
      this.canEdit = hasPermission;
    });
  }

  initForm() {
    this.form?.markAsUntouched();
    this.form?.markAsPristine();

    this.form = new FormGroup(
      {
        campaignName: new FormControl('', [Validators.maxLength(100), descriptionValidator(this.campaignNameValidTip, true)]),
        campaignPeriodFrom: new FormControl('', [dateValidator('create.dateError', '', '')]),
        campaignPeriodTo: new FormControl('', [dateValidator('create.dateError', '', '')]),
        campaignOwner: new FormControl('', [Validators.maxLength(200), descriptionValidator(this.campaignOwnerValidTip)]),
        campaignType: new FormControl(''),
      },
      { validators: startAndEndDateValidator('create.dateError', 'campaignPeriodFrom', 'campaignPeriodTo') }
    );
    this.formReady = false;
    setTimeout(() => {
      this.formReady = true;
    });
  }

  add() {
    this.cdsPopup
      .open(AddCampaignComponent, {
        size: 'lg',
      })
      .afterClosed()
      .subscribe(() => {
        this.loadTable();
      });
  }

  edit(campaign: Campaign) {
    this.cdsPopup
      .open(AddCampaignComponent, {
        size: 'lg',
        data: {
          campaign,
          editMode: true,
        },
      })
      .afterClosed()
      .subscribe(() => {
        this.loadTable();
      });
  }

  upload() {
    this.cdsPopup
      .open(UploadCustomerListComponent, {
        size: 'md',
      })
      .afterClosed()
      .subscribe(result => {
        if (result?.agree) {
          this.popupWithReload();
        } else {
          this.isLoading = false;
        }
      });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  reloadTable(event: any) {
    this.pageConfig = {
      ...this.pageConfig,
      current: event.current,
    };
    this.columnSort = event.column
      ? {
          key: event.column?.key,
          value: event.column?.sort,
        }
      : {
          key: 'createdAt',
          value: SortMode.DOWN,
        };

    this.loadTable();
  }

  loadTable() {
    const params: CampaignSearchRequest = this.getRequestParams();
    this.isLoading = true;
    this.exportButtonDisabled = true;
    this.service
      .getCampaignMemberList(params)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe(data => {
        if (data.result === 0 && data.data) {
          this.isLoading = false;
          this.dataSource = data.data.empty ? [] : data.data.content;
          this.totalElements = data.data.totalElements;
          this.pageConfig = {
            ...this.pageConfig,
            totalCount: data.data.totalElements,
          };
        } else {
          this.toastError(data.message);
        }
      });
  }

  getTempSearchParams() {
    const params: CampaignSearchRequest = {
      ...this.tempSeardhParams,
      ...(this.form.valid ? this.form.value : {}),
      page: (this.pageConfig.current as number) - 1,
      size: 10,
    };

    params.campaignPeriodFrom = params.campaignPeriodFrom ? this.dateTrans(params.campaignPeriodFrom) : '';
    params.campaignPeriodTo = params.campaignPeriodTo ? this.dateTrans(params.campaignPeriodTo) : '';

    return params;
  }

  getRequestParams() {
    const params: CampaignSearchRequest = {
      ...this.tempSeardhParams,
      page: (this.pageConfig.current as number) - 1,
      size: 10,
    };
    return params;
  }

  dateTrans(date: string) {
    if (date) {
      return moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD');
    }

    return date;
  }

  dateTransToDisplay(date: string) {
    if (date) {
      return moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY');
    }

    return date;
  }

  search() {
    if (this.searchButtonDisabled) {
      return;
    }
    this.pageConfig = {
      ...this.pageConfig,
      current: 1,
    };
    this.tempSeardhParams = deepCopy(this.getTempSearchParams());
    this.loadTable();
  }

  reset() {
    if (this.resetButtonDisabled) {
      return;
    }
    this.pageConfig = {
      ...this.pageConfig,
      current: 1,
    };

    this.tempSeardhParams = {
      page: 1,
      size: 10,
    };

    this.initForm();

    this.showDatePicker = false;
    setTimeout(() => {
      this.showDatePicker = true;
      this.loadTable();
    });
  }

  getMinTime() {
    return moment().subtract(5, 'year').add(1, 'day').toDate();
  }

  getNowTime() {
    return moment().toDate();
  }

  getTime(dateStr: string) {
    return moment(dateStr, 'DD/MM/YYYY').toDate();
  }

  getMaxFromDate = (toDateStr?: string) => {
    if (!toDateStr) {
      return null;
    }
    return moment(toDateStr, 'DD/MM/YYYY').toDate();
  };

  getMinToDate = (fromDateStr = '01/01/0000') => {
    return moment(fromDateStr, 'DD/MM/YYYY').toDate();
  };

  popupWithReload() {
    this.cdsPopup
      .open(AlertPopupComponent, {
        size: 'sm',
        data: {
          message: this.langService.translate('campaign.action.message.update.success.italic'),
          buttonName: this.langService.translate('common.gotItWithExclamation'),
        },
      })
      .afterClosed()
      .subscribe(() => {
        this.loadTable();
      });
  }

  listValueRender(list: any[], options: CdsOption[]) {
    if (!list) return '';
    return list
      .map(item => {
        return findOptionLabelByValue(item, options);
      })
      .join(' & ');
  }

  toastError(errorMessage: string) {
    this.toastAlert.show('error', 'common.error', errorMessage, 5000);
  }

  jumpTo(url: string) {
    window.open(url, '_blank');
  }
}
