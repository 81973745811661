<div class="matters-needing-attention" [ngClass]="[color]">
  <div class="column" [ngClass]="[color]"></div>
  <div class="content">
    <div class="icon-megaphone-box">
      <cds-icon [ngClass]="[color === 'red' ? 'icon-red' : '']" style="width: 30px; height: 30px" icon="action:megaphone"></cds-icon>
    </div>
    <div class="l-d-f l-fd-col">
      <div>
        <span class="cds-h6-demibold">
          {{ title }}
        </span>
      </div>
      <div class="l-mt-1">
        <span class="cds-detail2">{{ text }}</span>
      </div>
      <ng-content></ng-content>
    </div>
  </div>
</div>
