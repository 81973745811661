/* eslint-disable @typescript-eslint/no-explicit-any */
import { deepCopy } from 'src/app/utils/copy';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';
import { BasicResponse } from '../models/response/response';
import { ReportExtraction } from 'src/app/views/report-center/report-extraction/report-extraction';

@Injectable({
  providedIn: 'root',
})
export class ReportExtractionService {
  private fundContributionUurl = `${environment.apiPrefix}/ext/eb-ssms/reporting-service/api/v1/fund-contribution/`;
  private fundBalanceUrl = this.fundContributionUurl + 'fundBalance';
  private contributionHistoryUrl = this.fundContributionUurl + 'contribution-history';
  private certListUrl = this.fundContributionUurl + 'cert-list';
  private estimatedEmployeeyUrl = this.fundContributionUurl + 'estimated-employee';
  constructor(private http: HttpClient) {}

  getReport(params: ReportExtraction): Observable<BasicResponse<any[]>> {
    let url = '';
    switch (params.extractionType) {
      case 'FUND_BALANCE':
        url = this.fundBalanceUrl;
        break;
      case 'CONTRIBUTION_HISTORY':
        url = this.contributionHistoryUrl;
        break;
      case 'CERT_LIST':
        url = this.certListUrl;
        break;
      case 'ESTIMATED_EMPLOYEE':
        url = this.estimatedEmployeeyUrl;
        break;
    }

    const requestParams = deepCopy(params);
    delete requestParams.extractionType;

    // return this.http.get<BasicResponse<number>>(url, {
    //   params: filterParams(requestParams),
    // });

    return this.http.post<BasicResponse<any>>(url, requestParams);
  }
}
