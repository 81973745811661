<div class="l-pb-4 l-pt-4 l-pl-7 l-pr-7 l-d-f l-jc-fe">
  <cds-icon (click)="close()" size="md" class="pointer close" icon="action:cross"></cds-icon>
</div>
<div class="l-fg-1 filter-content">
  <div class="cds-h2-light l-mb-7">Filter({{ selectFilters.length }})</div>
  <ng-container *ngFor="let item of filters; index as i; last as isLast">
    <div *ngIf="item.list.length" [class.div-border-bottom]="!isLast" class="l-mb-6">
      <div class="cds-body1-demibold l-mb-5 l-d-f l-ai-cen">
        <span class="l-mr-3">{{ item.type | lang }}</span>
        <cds-icon *ngIf="item.open" (click)="toggleShow(i)" size="sm" class="pointer" icon="action:button_down"></cds-icon>
        <cds-icon *ngIf="!item.open" (click)="toggleShow(i)" size="sm" class="pointer" icon="action:button_up"></cds-icon>
      </div>
      <div *ngIf="item.open" class="l-d-f l-fw-w cds-body2 l-mb-5">
        <span
          *ngFor="let list of item.list; index as e"
          (click)="toggleSelected(i, e)"
          [class.chosed]="isFilterSelected(list.value)"
          class="pointer l-mr-2 l-mb-3 cds-white div-border filter-list">
          {{ list.label | lang }}
        </span>
      </div>
    </div>
  </ng-container>
</div>
<div class="cds-white l-pb-5 l-pt-5 l-pl-7 l-pr-7 l-d-f l-jc-fe">
  <cds-button (click)="reset()" type="submit" [disabled]="resetDisbaled" [style]="'secondary'" size="md">
    {{ 'common.reset' | lang }}
  </cds-button>
  <cds-button (click)="apply()" type="submit" class="l-ml-4" [style]="'primary'" size="md">
    {{ 'common.apply' | lang }}
  </cds-button>
</div>
