/* eslint-disable @typescript-eslint/no-explicit-any */
import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CdsChartColor, CdsChartColorMode, CdsDonutPieData } from '@cds/ng-charts/core';
import { CdsDonutChartType } from '@cds/ng-charts/donut';
import { CdsColorTheme, CdsSize } from '@cds/ng-core/configuration';
import { WidgetComponent } from '../widget.component';

@Component({
  selector: 'app-widgets-production-credit',
  templateUrl: './widgets-production-credit.component.html',
  styleUrls: ['./widgets-production-credit.component.scss'],
})
export class WidgetsProductionCreditComponent implements OnInit, AfterViewInit, OnDestroy, WidgetComponent {
  @Input() data: any;
  @ViewChild('productionCredit', { static: true }) productionCredit!: ElementRef;
  widthLess698 = false;

  constructor() {}

  ngOnInit(): void {}

  type: CdsDonutChartType = 'donut-a';
  size: CdsSize = 'sm';
  keylineWidth = 0;
  color: CdsColorTheme = 'ml';
  colorMode: CdsChartColorMode = 'categorical';
  firstYearPCData: CdsDonutPieData[] = [
    {
      label: 'a',
      value: 67,
    },
    {
      label: 'b',
      value: 33,
    },
  ];
  renewalPCData: CdsDonutPieData[] = [
    {
      label: 'a',
      value: 93,
    },
    {
      label: 'b',
      value: 7,
    },
  ];
  chartColors: CdsChartColor[] = [
    {
      r: 0,
      g: 167,
      b: 88,
      a: 1,
    },
    {
      r: 221,
      g: 222,
      b: 228,
      a: 1,
    },
  ];

  ngAfterViewInit(): void {
    this.resizeListener();
    window.addEventListener('resize', this.resizeListener);
  }

  private resizeListener = () => {
    this.widthLess698 = this.productionCredit.nativeElement.offsetWidth <= 698;
  };

  ngOnDestroy(): void {
    window.removeEventListener('resize', this.resizeListener);
  }
}
