/* eslint-disable @typescript-eslint/no-explicit-any */
export class LocalStorage {
  public localStorage: any;

  constructor() {
    if (!localStorage) {
      throw new Error('Current browser does not support Local Storage');
    }
    this.localStorage = localStorage;
  }

  public setMap(key: string, value: Map<any, any>): void {
    if (value == undefined) {
      return;
    }
    this.localStorage[key] = JSON.stringify(Object.fromEntries(value));
  }

  public getMap(key: string): any {
    if (this.localStorage[key]) {
      const map = new Map();
      const o = JSON.parse(this.localStorage[key]);
      for (const i in o) {
        map.set(i, o[i]);
      }
      return map;
    }
    return undefined;
  }

  public set(key: string, value: any): void {
    this.localStorage[key] = JSON.stringify(value);
  }

  public get(key: string): any {
    return JSON.parse(this.localStorage[key] || '{}');
  }

  public setArr(key: string, value: Array<any>): void {
    this.localStorage[key] = value;
  }

  public setObject(key: string, value: any): void {
    this.localStorage[key] = JSON.stringify(value);
  }

  public getObject(key: string): any {
    return JSON.parse(this.localStorage[key] || '{}');
  }

  public remove(key: string): any {
    this.localStorage.removeItem(key);
  }

  public removeAll(): any {
    this.localStorage.clear();
  }
}
