<div class="contribution-table" *ngIf="showTable || true">
  <cds-table [dataSource]="contributionTableDataSource" [class.full]="true">
    <!-- Category(space) -->
    <ng-container cdsColumnDef="category" sticky>
      <cds-header-cell *cdsHeaderCellDef></cds-header-cell>
      <cds-cell class="bold" *cdsCellDef="let element">{{ getCategoryTitle | fn : element.category | lang }}</cds-cell>
    </ng-container>

    <!-- ERMC -->
    <ng-container cdsColumnDef="ermc">
      <cds-header-cell *cdsHeaderCellDef>ERMC</cds-header-cell>
      <cds-cell *cdsCellDef="let element" (click)="popupDetail(element.category, 'ERMC', element.ermc)"
        ><span class="click">{{ element.ermc | number }}</span></cds-cell
      >
    </ng-container>

    <!-- EEMC -->
    <ng-container cdsColumnDef="eemc">
      <cds-header-cell *cdsHeaderCellDef>EEMC</cds-header-cell>
      <cds-cell *cdsCellDef="let element" (click)="popupDetail(element.category, 'EEMC', element.eemc)"
        ><span class="click">{{ element.eemc | number }}</span></cds-cell
      >
    </ng-container>

    <!-- Surcharge -->
    <ng-container cdsColumnDef="surcharge">
      <cds-header-cell *cdsHeaderCellDef>Surcharge</cds-header-cell>
      <cds-cell *cdsCellDef="let element" (click)="popupDetail(element.category, 'SURCHARGE', element.surcharge)"
        ><span class="click">{{ element.surcharge | number }}</span></cds-cell
      >
    </ng-container>

    <!-- ERVC -->
    <ng-container cdsColumnDef="ervc">
      <cds-header-cell *cdsHeaderCellDef>ERVC</cds-header-cell>
      <cds-cell *cdsCellDef="let element" (click)="popupDetail(element.category, 'ERVC', element.ervc)"
        ><span class="click">{{ element.ervc | number }}</span></cds-cell
      >
    </ng-container>

    <!-- EEVC -->
    <ng-container cdsColumnDef="eevc">
      <cds-header-cell *cdsHeaderCellDef>EEVC</cds-header-cell>
      <cds-cell *cdsCellDef="let element" (click)="popupDetail(element.category, 'EEVC', element.eevc)"
        ><span class="click">{{ element.eevc | number }}</span></cds-cell
      >
    </ng-container>

    <!-- ERVC2 -->
    <ng-container cdsColumnDef="ervc2">
      <cds-header-cell *cdsHeaderCellDef>ERVC2</cds-header-cell>
      <cds-cell *cdsCellDef="let element" (click)="popupDetail(element.category, 'ERVC2', element.ervc2)"
        ><span class="click">{{ element.ervc2 | number }}</span></cds-cell
      >
    </ng-container>

    <!-- ERVC3 -->
    <ng-container cdsColumnDef="ervc3">
      <cds-header-cell *cdsHeaderCellDef>ERVC3</cds-header-cell>
      <cds-cell *cdsCellDef="let element" (click)="popupDetail(element.category, 'ERVC3', element.ervc3)"
        ><span class="click">{{ element.ervc3 | number }}</span></cds-cell
      >
    </ng-container>

    <!-- ERVC4 -->
    <ng-container cdsColumnDef="ervc4">
      <cds-header-cell *cdsHeaderCellDef>ERVC4</cds-header-cell>
      <cds-cell *cdsCellDef="let element" (click)="popupDetail(element.category, 'ERVC4', element.ervc4)"
        ><span class="click">{{ element.ervc4 | number }}</span></cds-cell
      >
    </ng-container>

    <!-- ERVC5 -->
    <ng-container cdsColumnDef="ervc5">
      <cds-header-cell *cdsHeaderCellDef>ERVC5</cds-header-cell>
      <cds-cell *cdsCellDef="let element" (click)="popupDetail(element.category, 'ERVC5', element.ervc5)"
        ><span class="click">{{ element.ervc5 | number }}</span></cds-cell
      >
    </ng-container>

    <!-- ERVC6 -->
    <ng-container cdsColumnDef="ervc6">
      <cds-header-cell *cdsHeaderCellDef>ERVC6</cds-header-cell>
      <cds-cell *cdsCellDef="let element" (click)="popupDetail(element.category, 'ERVC6', element.ervc6)"
        ><span class="click">{{ element.ervc6 | number }}</span></cds-cell
      >
    </ng-container>

    <!-- ERVC7 -->
    <ng-container cdsColumnDef="ervc7">
      <cds-header-cell *cdsHeaderCellDef>ERVC7</cds-header-cell>
      <cds-cell *cdsCellDef="let element" (click)="popupDetail(element.category, 'ERVC7', element.ervc7)"
        ><span class="click">{{ element.ervc7 | number }}</span></cds-cell
      >
    </ng-container>

    <!-- EEVC2 -->
    <ng-container cdsColumnDef="eevc2">
      <cds-header-cell *cdsHeaderCellDef>EEVC2</cds-header-cell>
      <cds-cell *cdsCellDef="let element" (click)="popupDetail(element.category, 'EEVC2', element.eevc2)"
        ><span class="click">{{ element.eevc2 | number }}</span></cds-cell
      >
    </ng-container>

    <!-- TVC -->
    <ng-container cdsColumnDef="tvc">
      <cds-header-cell *cdsHeaderCellDef>TVC</cds-header-cell>
      <cds-cell *cdsCellDef="let element" (click)="popupDetail(element.category, 'TVC', element.tvc)"
        ><span class="click">{{ element.tvc | number }}</span></cds-cell
      >
    </ng-container>

    <!-- SVC -->
    <ng-container cdsColumnDef="svc">
      <cds-header-cell *cdsHeaderCellDef>SVC</cds-header-cell>
      <cds-cell *cdsCellDef="let element" (click)="popupDetail(element.category, 'SVC', element.svc)"
        ><span class="click">{{ element.svc | number }}</span></cds-cell
      >
    </ng-container>

    <!-- SEPMC -->
    <ng-container cdsColumnDef="sepmc">
      <cds-header-cell *cdsHeaderCellDef>SEPMC</cds-header-cell>
      <cds-cell *cdsCellDef="let element" (click)="popupDetail(element.category, 'SEPMC', element.sepmc)"
        ><span class="click">{{ element.sepmc | number }}</span></cds-cell
      >
    </ng-container>

    <!-- SEPVC -->
    <ng-container cdsColumnDef="sepvc">
      <cds-header-cell *cdsHeaderCellDef>SEPVC</cds-header-cell>
      <cds-cell *cdsCellDef="let element" (click)="popupDetail(element.category, 'SEPVC', element.sepvc)"
        ><span class="click">{{ element.sepvc | number }}</span></cds-cell
      >
    </ng-container>

    <cds-header-row *cdsHeaderRowDef="contributionTablDisplayedColumns"></cds-header-row>
    <cds-row *cdsRowDef="let row; columns: contributionTablDisplayedColumns"></cds-row>
  </cds-table>
</div>
