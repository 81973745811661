import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef, Renderer2, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CdsButtonConfig } from '@cds/ng-core/button';
import moment from 'moment';
import { employeeStatusMapObj } from '../employee';

@Component({
  selector: 'app-employee-profile-title',
  templateUrl: './employee-profile-title.component.html',
  styleUrls: ['./employee-profile-title.component.scss'],
})
export class EmployeeProfileTitleComponent implements OnInit {
  @Input() showBtn = false;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() detailData?: any;
  @Input() isPolicy = false;

  @Output() erProfileInfoDetailShow = new EventEmitter();
  @Output() stickyStatusChange = new EventEmitter();

  status = 'Active';

  btnConfig: CdsButtonConfig = {
    size: 'sm',
  };

  employeeStatusMapObj = employeeStatusMapObj;

  showQuickAccess = false;

  id = '';

  toolTipString = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur sit amet nisl ultricies, ultrices augue id, finibus diam. ';

  sticky = false;

  linkBtnConfig: CdsButtonConfig = {
    size: 'sm',
    style: 'secondary',
  };

  constructor(private router: Router, private route: ActivatedRoute, private cdr: ChangeDetectorRef, private renderer: Renderer2, private el: ElementRef) {}

  ngOnInit(): void {
    this.id = this.route.snapshot.queryParams['id'];

    const scrollDiv = document.getElementsByClassName('cds-navigation-content')[0] as HTMLElement;
    scrollDiv.onscroll = this.scrollFunction;
  }

  scrollFunction = () => {
    const scrollDiv = document.getElementsByClassName('cds-navigation-content')[0] as HTMLElement;
    const navigation = document.getElementById('ttt') as HTMLElement;
    const style = getComputedStyle(navigation);
    const height = parseInt(style.paddingTop) + +parseInt(style.height) + parseInt(style.paddingBottom) + (this.isPolicy ? 0 : 32);
    const hostElement = document.querySelector('app-employer-profile-title') as Element;
    if (scrollDiv.scrollTop > height) {
      this.sticky = true;
      this.forceRender();
      hostElement?.classList?.add('div-border-top');
    } else {
      this.sticky = false;
      hostElement?.classList?.remove('div-border-top');
      this.sticky = false;
      this.forceRender();
    }
  };

  goBack() {
    this.router.navigate(['/employer/profile-detail'], {
      queryParams: { id: this.id },
    });
  }

  quickAccess(status?: boolean) {
    if (status !== undefined) {
      this.showQuickAccess = status;
    } else {
      this.showQuickAccess = !this.showQuickAccess;
    }
  }

  updateDate(date: string) {
    if (!date) {
      return '';
    }
    return moment(date).utcOffset(8).format('h:mma DD MMM, YYYY');
  }

  forceRender() {
    this.cdr.detectChanges();
    this.stickyStatusChange.emit(this.sticky);
    const hostElement = this.el.nativeElement;
    this.renderer.setStyle(hostElement, 'box-shadow', this.sticky ? '0px 8px 6px -6px rgba(0, 0, 0, 0.1)' : '');
    this.renderer.setStyle(hostElement, 'border-top', this.sticky ? '1px solid #ededed' : '');
  }
}
