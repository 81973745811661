import { ReportingPermissionItem } from './permission.enum';

export const enum ReportingRolePermissionGroup {
  MPF = 'MPF',
  ORSO = 'ORSO',
  MACAU = 'MACAU',
  SHK = 'SHK',
}

export const ReportingRolePermissionGroupList: Map<ReportingRolePermissionGroup, Set<ReportingPermissionItem>> = new Map([
  [
    ReportingRolePermissionGroup.MPF,
    new Set<ReportingPermissionItem>([
      ReportingPermissionItem.MPF_GENERAL_REPORTS,
      ReportingPermissionItem.MPF_STAFF_REPORTS,
      ReportingPermissionItem.MPF_MIS_REPORTS,
      ReportingPermissionItem.MPF_MLFUB_REPORTS,
    ]),
  ],
  [
    ReportingRolePermissionGroup.ORSO,
    new Set<ReportingPermissionItem>([
      ReportingPermissionItem.ORSO_GENERAL_REPORTS,
      ReportingPermissionItem.ORSO_STAFF_REPORTS,
      ReportingPermissionItem.ORSO_MIS_REPORTS,
      ReportingPermissionItem.ORSO_MLFUB_REPORTS,
    ]),
  ],
  [
    ReportingRolePermissionGroup.MACAU,
    new Set<ReportingPermissionItem>([
      ReportingPermissionItem.MACAU_GENERAL_REPORTS,
      ReportingPermissionItem.MACAU_STAFF_REPORTS,
      ReportingPermissionItem.MACAU_MIS_REPORTS,
      ReportingPermissionItem.MACAU_MLFUB_REPORTS,
    ]),
  ],
  [
    ReportingRolePermissionGroup.SHK,
    new Set<ReportingPermissionItem>([
      ReportingPermissionItem.SHK_GENERAL_REPORTS,
      ReportingPermissionItem.SHK_STAFF_REPORTS,
      ReportingPermissionItem.SHK_MIS_REPORTS,
      ReportingPermissionItem.SHK_MLFUB_REPORTS,
    ]),
  ],
]);
