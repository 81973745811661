/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, DoCheck, Inject, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, ValidationErrors, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { CdsDropdownConfig } from '@cds/ng-core/dropdown';
import { CdsLangService } from '@cds/ng-core/lang';
import { CdsAlertService } from '@cds/ng-web-components/alert';
import { CDS_POPUP_DATA, CdsPopupService } from '@cds/ng-web-components/popup';
import { CdHttpServeService } from 'src/app/shared/cd-http-serve/cd-http-serve.service';
import { CdRadioComponent, CdRadioConfig } from 'src/app/shared/cd-radio/cd-radio.component';
import { ResendEiffSubmitSuccessComponent } from './resend-eiff-submit-success/resend-eiff-submit-success.component';
import { AgentChannel } from 'src/app/core/models/enum/agent-channel.enum';
import { ContactPersonContact, PreferredLanguageType } from '../case-summary/case-summary.model';
import { ResendEiffResendType, AreaCode, ResendEiffResendInfoRsp, ResendEiffResendSubmissionParams } from './resend-eiff.model';
import { CaseDetailsRsp } from '../case-details/case-details.model';
import { NotifyService } from 'src/app/core/services/notify.service';
import { AgentChannelService } from 'src/app/core/services/agent-channel.service';

@Component({
  selector: 'app-resend-eiff',
  templateUrl: './resend-eiff.component.html',
  styleUrls: ['./resend-eiff.component.scss'],
})
export class ResendEiffComponent implements OnInit, DoCheck {
  @ViewChild('cdRadio', { static: false }) cdRadio!: CdRadioComponent;
  @ViewChild('cdRadioPreferredLanguage') cdRadioPreferredLanguage?: CdRadioComponent;
  @ViewChild('cdRadioSecondAgentPreferredLanguage') cdRadioSecondAgentPreferredLanguage?: CdRadioComponent;
  @ViewChild('cdRadioThirdAgentPreferredLanguage') cdRadioThirdAgentPreferredLanguage?: CdRadioComponent;
  ResendType = ResendEiffResendType;
  PreferredLanguageType = PreferredLanguageType;
  private readonly CN_PHONE = /^1[345678]\d{9}$/;
  emailAndPhoneConfig: CdRadioConfig = {
    options: [
      {
        label: this.cdsLangService.currentLang === 'en' ? 'Email Address' : '電郵地址',
        value: 1,
        disable: false,
      },
      {
        label: this.cdsLangService.currentLang === 'en' ? 'Phone Number' : '手機號碼',
        value: 2,
        disable: false,
      },
    ],
  };
  emailAndPhoneValue = 1;

  disableChannelConfig: CdRadioConfig = {
    options: [
      {
        label: this.cdsLangService.currentLang === 'en' ? 'Registered email address' : '已登記的電郵地址',
        value: 1,
        disable: true,
      },
      {
        label: this.cdsLangService.currentLang === 'en' ? 'Registered phone number' : '已登記的手機號碼',
        value: 2,
        disable: true,
      },
    ],
  };

  countrycodeFormControl: FormControl = new FormControl(AreaCode.HK);
  emailAddressFormControl: FormControl = new FormControl('', [Validators.email]);
  countrycodeConfig: CdsDropdownConfig = {
    options: [
      { label: '+852 (HK)', value: AreaCode.HK },
      { label: '+853 (MA)', value: AreaCode.MA },
      { label: '+86 (CN)', value: AreaCode.CN },
    ],
    color: 'ml',
  };
  phoneNumberFormControl: FormControl = new FormControl('', [
    (option: AbstractControl) => {
      if (typeof option.value === 'string') {
        if (option.value.length === 0) {
          return {};
        }

        if ([AreaCode.HK, AreaCode.MA].includes(this.countrycodeFormControl.value)) {
          if (option.value.length === 8 && ['4', '5', '6', '7', '8', '9'].includes(option.value.substring(0, 1))) {
            return {};
          }
        }

        if ([AreaCode.CN].includes(this.countrycodeFormControl.value)) {
          if (option.value.length === 11) {
            const e = this.CN_PHONE.test(option.value);
            if (e) {
              return {};
            }
          }
        }
      }

      return { errorPhoneNumber: true };
    },
  ]);
  isShowPleaseInputPhoneNumber = false;
  isShowPleaseInputCustomerEmail = false;
  resendInfo?: ResendEiffResendInfoRsp;
  isReadOnlyAuthorizedPersonName = false;
  isReadOnlyAuthorizedPersonNameRadio = false;
  isReadOnlyAuthorizedPersonNameEmailAndPhone = false;
  currentStep = 1;
  dbs?: {
    dbsRmReferralCd: FormControl;
    dbsCustomerId: FormControl;
    dbsCampaignCd: FormControl;
    dbsSourceCd: FormControl;
  };

  preferredLanguage?: PreferredLanguageType;
  preferredLanguageConfig = {
    options: [
      {
        label: 'common.chinese',
        value: PreferredLanguageType.Chinese,
        disable: true,
      },
      {
        label: 'common.english',
        value: PreferredLanguageType.English,
        disable: true,
      },
    ],
  };

  secondAgentPreferredLanguage?: PreferredLanguageType;
  secondAgentPreferredLanguageConfig = {
    options: [
      {
        label: 'common.chinese',
        value: PreferredLanguageType.Chinese,
        disable: true,
      },
      {
        label: 'common.english',
        value: PreferredLanguageType.English,
        disable: true,
      },
    ],
  };

  thirdAgentPreferredLanguage?: PreferredLanguageType;
  thirdAgentPreferredLanguageConfig = {
    options: [
      {
        label: 'common.chinese',
        value: PreferredLanguageType.Chinese,
        disable: true,
      },
      {
        label: 'common.english',
        value: PreferredLanguageType.English,
        disable: true,
      },
    ],
  };

  contactPersonContacts: ContactPersonContact[] = [];

  constructor(
    private dialogRef: MatDialogRef<ResendEiffComponent>,
    public cdsLangService: CdsLangService,
    private cdHttpServeService: CdHttpServeService,
    private alert: CdsAlertService,
    @Inject(CDS_POPUP_DATA)
    public data: {
      salesJourneySummariesId: string;
      resendType: ResendEiffResendType;
      iffStatus: number;
      empfStatus: number;
    },
    public notifyService: NotifyService,
    private cdsPopup: CdsPopupService,
    private agentChannelService: AgentChannelService
  ) {}

  get isReadOnlyEmailAndPhone() {
    if (this.data.resendType === ResendEiffResendType.EE) {
      return ![0, 40, 50, 99].includes(this.data.empfStatus) && [10, 20].includes(this.data.iffStatus);
    } else {
      return !([0].includes(this.data.empfStatus) && [10, 20].includes(this.data.iffStatus));
    }
  }

  get isReadOnlyEmailForNull() {
    return this.data.empfStatus !== 0 && !this.emailAddressFormControl.value;
  }

  get isReadOnlyPhoneForNull() {
    return this.data.empfStatus !== 0 && !this.phoneNumberFormControl.value;
  }

  ngOnInit(): void {
    this.notifyService.screenLoadingSubject.next(true);

    this.countrycodeFormControl.valueChanges.subscribe(() => {
      this.phoneNumberFormControl.updateValueAndValidity();
    });

    this.phoneNumberFormControl.valueChanges.subscribe((val: string) => {
      if (val?.length > 0) {
        this.isShowPleaseInputPhoneNumber = false;
      } else {
        this.isShowPleaseInputPhoneNumber = true;
      }
    });

    this.emailAddressFormControl.valueChanges.subscribe((val: string) => {
      if (val?.length > 0) {
        this.isShowPleaseInputCustomerEmail = false;
      } else {
        this.isShowPleaseInputCustomerEmail = true;
      }
    });

    this.getResendInfo();
  }

  getResendInfo() {
    this.cdHttpServeService
      .post<ResendEiffResendInfoRsp>('/ext/eb-ssms-sales-journey-service/resend/info', {
        salesJourneySummaryId: this.data.salesJourneySummariesId,
      })
      .subscribe({
        next: res => {
          this.notifyService.screenLoadingSubject.next(false);
          if (res.result !== 0) {
            this.alert.warning('Warning!', `${res.message}`);
          } else {
            this.resendInfo = res.data;
            let isDisableChannelConfig = false;

            this.emailAddressFormControl.setValue(this.resendInfo.emailAddress);
            this.countrycodeFormControl.setValue(this.resendInfo.countryCallingCd || AreaCode.HK);
            this.phoneNumberFormControl.setValue(this.resendInfo.phoneNumber);
            if (this.data.resendType === ResendEiffResendType.EE) {
              this.emailAndPhoneValue = this.resendInfo.commChannel === 'E' ? 1 : this.resendInfo.commChannel === 'M' ? 2 : 0;
              this.cdRadio.refreshConfig();
              isDisableChannelConfig = (![0, 40, 50, 99].includes(this.data.empfStatus) || this.data.empfStatus === 0) && this.data.iffStatus === 20;
            } else {
              this.isReadOnlyAuthorizedPersonName = !(![0, 40, 50, 99].includes(this.data.empfStatus) && [10, 20].includes(this.data.iffStatus));
              this.isReadOnlyAuthorizedPersonNameRadio = this.data.empfStatus !== 0;
              this.isReadOnlyAuthorizedPersonNameEmailAndPhone = this.data.empfStatus === 0;
              this.getMemberContacts([this.resendInfo.customerId]);
            }

            switch (this.resendInfo.smsLanguage) {
              case 0:
                this.preferredLanguage = PreferredLanguageType.English;
                break;
              case 1:
                this.preferredLanguage = PreferredLanguageType.Chinese;
                break;
            }
            if (this.resendInfo.smsLanguage !== undefined) {
              this.preferredLanguageConfig = {
                options: [
                  {
                    label: 'common.chinese',
                    value: PreferredLanguageType.Chinese,
                    disable: false,
                  },
                  {
                    label: 'common.english',
                    value: PreferredLanguageType.English,
                    disable: false,
                  },
                ],
              };
              this.cdRadioPreferredLanguage?.refreshConfig();
            }

            if (this.resendInfo.agentInfos) {
              this.resendInfo.agentInfos.forEach((agentInfo, agentInfoIndex) => {
                let shortEmail = '';
                if (agentInfo.emailAddress) {
                  const ea = agentInfo.emailAddress || '';
                  const endIndex = ea.indexOf('@');
                  shortEmail = ea.substring(0, 4) + '*****' + ea.substring(endIndex);
                }
                let shortPhone = '';
                if (agentInfo.mobile) {
                  const mn = agentInfo.mobile || '';
                  let phoneArr = new Array((mn + '').length - 3);
                  phoneArr = phoneArr.fill('*');
                  shortPhone = mn.substring(0, 1) + phoneArr.join('') + mn.substring((mn + '').length - 2);
                }

                if (this.data.resendType === ResendEiffResendType.ER) {
                  isDisableChannelConfig = !(agentInfo.iffAgentStatusEnum === 1 && this.data.iffStatus === 10);
                }

                agentInfo.channelConfig = {
                  options: [
                    {
                      label:
                        this.cdsLangService.currentLang === 'en'
                          ? `Registered email address${agentInfo.emailAddress ? ' (' + shortEmail + ') ' : ''}`
                          : `已登記的電郵地址${agentInfo.emailAddress ? ' (' + shortEmail + ') ' : ''}`,
                      value: 1,
                      disable: isDisableChannelConfig || !agentInfo.emailAddress,
                    },
                    {
                      label:
                        this.cdsLangService.currentLang === 'en'
                          ? `Registered phone number${agentInfo.mobile ? ' (' + shortPhone + ') ' : ''}`
                          : `已登記的手機號碼${agentInfo.mobile ? ' (' + shortPhone + ') ' : ''}`,
                      value: 2,
                      disable: isDisableChannelConfig || !agentInfo.mobile,
                    },
                  ],
                };
                agentInfo.channelValue = agentInfo.agentNotificationChannel === 'E' ? 1 : agentInfo.agentNotificationChannel === 'M' ? 2 : 0;

                switch (agentInfo.smsLanguage) {
                  case 0:
                    if (agentInfoIndex === 0) {
                      this.secondAgentPreferredLanguage = PreferredLanguageType.English;
                    } else if (agentInfoIndex === 1) {
                      this.thirdAgentPreferredLanguage = PreferredLanguageType.English;
                    }
                    break;
                  case 1:
                    if (agentInfoIndex === 0) {
                      this.secondAgentPreferredLanguage = PreferredLanguageType.Chinese;
                    } else if (agentInfoIndex === 1) {
                      this.thirdAgentPreferredLanguage = PreferredLanguageType.Chinese;
                    }
                    break;
                }

                if (agentInfo.smsLanguage !== undefined) {
                  if (agentInfoIndex === 0) {
                    this.secondAgentPreferredLanguageConfig = {
                      options: [
                        {
                          label: 'common.chinese',
                          value: PreferredLanguageType.Chinese,
                          disable: isDisableChannelConfig || !agentInfo.mobile,
                        },
                        {
                          label: 'common.english',
                          value: PreferredLanguageType.English,
                          disable: isDisableChannelConfig || !agentInfo.mobile,
                        },
                      ],
                    };
                    this.cdRadioSecondAgentPreferredLanguage?.refreshConfig();
                  } else if (agentInfoIndex === 1) {
                    this.thirdAgentPreferredLanguageConfig = {
                      options: [
                        {
                          label: 'common.chinese',
                          value: PreferredLanguageType.Chinese,
                          disable: isDisableChannelConfig || !agentInfo.mobile,
                        },
                        {
                          label: 'common.english',
                          value: PreferredLanguageType.English,
                          disable: isDisableChannelConfig || !agentInfo.mobile,
                        },
                      ],
                    };
                    this.cdRadioThirdAgentPreferredLanguage?.refreshConfig();
                  }
                }
              });
            }

            this.emailAndPhoneConfig.options[0].disable = this.data.empfStatus !== 0 && !this.emailAddressFormControl.value;
            this.emailAndPhoneConfig.options[1].disable = this.data.empfStatus !== 0 && !this.phoneNumberFormControl.value;
            setTimeout(() => {
              this.cdRadio.refreshConfig();
              this.emailAndPhoneValue = this.resendInfo?.commChannel === 'E' ? 1 : 2;
            }, 0);

            this.dbs = {
              dbsRmReferralCd: new FormControl(this.resendInfo.dbs?.dbsRmReferralCd, [
                Validators.required,
                (control: AbstractControl): ValidationErrors | null => {
                  let input = control.value || '';
                  input = input.trim();
                  const isValid = input.length >= 6 && input.length <= 8;
                  return isValid ? null : { lengthError: true };
                },
              ]),
              dbsCustomerId: new FormControl(this.resendInfo.dbs?.dbsCustomerId, [
                (control: AbstractControl): ValidationErrors | null => {
                  let input = control.value || '';
                  input = input.trim();
                  const isValid = ((input.length >= 6 && input.length <= 8) || input.length === 0) && /^[A-Za-z0-9-]*$/.exec(input);
                  return isValid ? null : { lengthError: true };
                },
              ]),
              dbsSourceCd: new FormControl(this.resendInfo.dbs?.dbsSourceCd, [
                (control: AbstractControl): ValidationErrors | null => {
                  let input = control.value || '';
                  input = input.trim();
                  const isValid = (input.length === 10 || input.length === 0) && /^[A-Za-z0-9-]*$/.exec(input);
                  return isValid ? null : { lengthError: true };
                },
              ]),
              dbsCampaignCd: new FormControl(this.resendInfo.dbs?.dbsCampaignCd, [
                (control: AbstractControl): ValidationErrors | null => {
                  let input = control.value || '';
                  input = input.trim();
                  const isValid = (input.length === 10 || input.length === 0) && /^[A-Za-z0-9-]*$/.exec(input);
                  return isValid ? null : { lengthError: true };
                },
              ]),
            };
          }
        },
        error: err => {
          this.alert.error('Error!', err);
          this.notifyService.screenLoadingSubject.next(false);
        },
      });
  }

  getChannel(channelConfig?: CdRadioConfig, channelValue?: number) {
    return channelConfig?.options.find(o => o.value === channelValue)?.label;
  }

  resendSubmission() {
    this.notifyService.screenLoadingSubject.next(true);
    const splitAgentInfos =
      this.resendInfo?.agentInfos?.map((element, index) => {
        const a: any = {
          agentid: element.agentid,
          agentNotificationChannel: element.channelValue === 1 ? 'E' : element.channelValue === 2 ? 'M' : '',
        };

        if (element.regulatedAct && element.channelValue === 2) {
          if (index === 0) {
            a['smsLanguage'] = this.secondAgentPreferredLanguage === PreferredLanguageType.English ? 0 : 1;
          } else if (index === 1) {
            a['smsLanguage'] = this.thirdAgentPreferredLanguage === PreferredLanguageType.English ? 0 : 1;
          }
        }

        return a;
      }) || [];

    const params: ResendEiffResendSubmissionParams = {
      salesJourneySummaryId: this.data.salesJourneySummariesId,
      empfRefNo: this.resendInfo?.empfRefNo,
      communicationChannel: this.emailAndPhoneValue === 1 ? 'E' : this.emailAndPhoneValue === 2 ? 'M' : '',
      authPersonId: this.resendInfo?.authorizedPersonNameControl?.value,
      customerId: this.resendInfo?.customerId,
      splitAgentInfos: splitAgentInfos,
    };

    if (this.resendInfo?.regulatedAct && this.emailAndPhoneValue === 2) {
      params.smsLanguage = this.preferredLanguage === PreferredLanguageType.English ? 0 : 1;
    }

    if (this.emailAndPhoneValue === 1) {
      params.email = this.emailAddressFormControl.value;
    } else {
      params.countryCallingCode = this.countrycodeFormControl.value;
      params.mobile = this.phoneNumberFormControl.value;
    }

    if (this.isChannelDBS) {
      params.dbs = {
        dbsRmReferralCd: this.dbs?.dbsRmReferralCd.value,
        dbsCustomerId: this.dbs?.dbsCustomerId.value,
        dbsCampaignCd: this.dbs?.dbsCampaignCd.value,
        dbsSourceCd: this.dbs?.dbsSourceCd.value,
      };
    }

    this.cdHttpServeService.post('/ext/eb-ssms-sales-journey-service/resend/submission', params).subscribe({
      next: res => {
        if (res.result !== 0) {
          this.alert.warning('Warning!', `${res.message}`);
        } else {
          let msg = 'sendIffInBatchSummary.popupScreenTitleCustomer';
          if (this.data.iffStatus === 20) {
            msg = 'sendIffInBatchSummary.popupScreenTitleCustomer';
          } else if (this.data.iffStatus === 10) {
            msg = 'sendIffInBatchSummary.popupScreenTitleForSplitAgent';
          }

          const ref = this.cdsPopup.open(ResendEiffSubmitSuccessComponent, {
            size: 'sm',
            data: {
              message: this.cdsLangService.translate(msg),
            },
          });
          ref.afterClosed().subscribe(() => {
            this.dialogRef.close({ isRefresh: true });
          });
        }
        this.notifyService.screenLoadingSubject.next(false);
      },
      error: err => {
        this.alert.error('Error!', err);
        this.notifyService.screenLoadingSubject.next(false);
      },
    });
  }

  get isChannelDBS() {
    return this.agentChannelService.channel === AgentChannel.DBS;
  }

  get isDisabledContinue() {
    if (
      this.dbs &&
      ((this.dbs.dbsRmReferralCd.dirty && !!this.dbs.dbsRmReferralCd.errors) ||
        (this.dbs.dbsCustomerId.dirty && !!this.dbs.dbsCustomerId.errors) ||
        (this.dbs.dbsCampaignCd.dirty && !!this.dbs.dbsCampaignCd.errors) ||
        (this.dbs.dbsSourceCd.dirty && !!this.dbs.dbsSourceCd.errors))
    ) {
      return true;
    }

    if (this.resendInfo?.regulatedAct && this.emailAndPhoneValue === 2 && !this.preferredLanguage) {
      return true;
    }

    const b = this.resendInfo?.agentInfos?.some(
      (agent, index) =>
        agent.regulatedAct &&
        agent.channelValue === 2 &&
        ((index === 0 && !this.secondAgentPreferredLanguage) || (index === 1 && !this.thirdAgentPreferredLanguage))
    );
    if (b) {
      return true;
    }

    if (this.emailAndPhoneValue === 1) {
      return this.emailAddressFormControlValidatorFail || this.isShowPleaseInputCustomerEmail;
    } else {
      return this.phoneNumberFormControlValidatorFail || this.isShowPleaseInputPhoneNumber;
    }
  }

  getMemberContacts(customerIdList: string[]) {
    this.cdHttpServeService
      .post<
        {
          contactPersonContacts: ContactPersonContact[];
          employerId: string;
        }[]
      >(`/ext/eb-ssms-sales-journey-service/iffByRecordList/contacts/employer`, customerIdList)
      .subscribe({
        next: res => {
          if (res.result !== 0) {
            this.alert.warning('Warning!', `${res.message}`);
          } else {
            const a = res.data.find(d => d.employerId === customerIdList[0]);
            if (a && this.resendInfo) {
              this.resendInfo.authorizedPersonNameControl = new FormControl(null);
              this.resendInfo.authorizedPersonNameConfig = {
                options: [],
              };

              this.contactPersonContacts = a.contactPersonContacts;
              this.contactPersonContacts?.forEach(contact => {
                if (contact.contactPersonLastName === this.resendInfo?.lastName && contact.contactPersonFirstName === this.resendInfo?.firstName) {
                  this.resendInfo.authorizedPersonNameControl.setValue(contact.contactPersonId);
                  this.emailAddressFormControl.setValue(contact.emailAddr);
                  this.countrycodeFormControl.setValue(contact.countryCallingCode);
                  this.phoneNumberFormControl.setValue(contact.phoneNumber);
                }
                this.resendInfo?.authorizedPersonNameConfig?.options.push({
                  label: contact.contactPersonName,
                  value: contact.contactPersonId,
                });
              });

              if (this.resendInfo.authorizedPersonNameConfig) {
                this.resendInfo.authorizedPersonNameConfig.placeholder = 'Select Authorized Person Name';
              }
            }
          }
        },
        error: err => {
          this.alert.error('Error!', err);
        },
      });
  }

  close() {
    this.dialogRef.close({ isRefresh: false });
  }

  authorizedPersonNameControlChange() {
    if (this.contactPersonContacts && this.resendInfo) {
      const c = this.contactPersonContacts.filter(contact => contact.contactPersonId === this.resendInfo?.authorizedPersonNameControl.value);
      if (c && c.length === 1) {
        this.emailAddressFormControl.setValue(c[0].emailAddr);
        this.countrycodeFormControl.setValue(c[0].countryCallingCode);
        this.phoneNumberFormControl.setValue(c[0].phoneNumber);

        if (c[0].contactPersonLastName === this.resendInfo.lastName && c[0].contactPersonFirstName === this.resendInfo.firstName) {
          this.emailAndPhoneValue = this.resendInfo.commChannel === 'E' ? 1 : this.resendInfo.commChannel === 'M' ? 2 : 0;
        } else {
          this.emailAndPhoneValue = c[0].emailAddr ? 1 : c[0].countryCallingCode && c[0].phoneNumber ? 2 : 0;
        }
        this.emailAndPhoneConfig.options[0].disable = this.data.empfStatus !== 0 && !this.emailAddressFormControl.value;
        this.emailAndPhoneConfig.options[1].disable = this.data.empfStatus !== 0 && !this.phoneNumberFormControl.value;
        setTimeout(() => {
          this.cdRadio.refreshConfig();
        }, 0);

        if (!this.emailAndPhoneConfig.options[1].disable && this.emailAndPhoneValue === 2) {
          this.preferredLanguageConfig = {
            options: [
              {
                label: 'common.chinese',
                value: PreferredLanguageType.Chinese,
                disable: false,
              },
              {
                label: 'common.english',
                value: PreferredLanguageType.English,
                disable: false,
              },
            ],
          };
          this.preferredLanguage = undefined;
        } else {
          this.preferredLanguageConfig = {
            options: [
              {
                label: 'common.chinese',
                value: PreferredLanguageType.Chinese,
                disable: true,
              },
              {
                label: 'common.english',
                value: PreferredLanguageType.English,
                disable: true,
              },
            ],
          };
          this.preferredLanguage = undefined;
        }
        this.cdRadioPreferredLanguage?.refreshConfig();
      }
    }
  }

  get emailAddressFormControlValidatorFail() {
    return (this.emailAddressFormControl.touched || this.emailAddressFormControl.dirty) && !this.emailAddressFormControl.valid;
  }

  get phoneNumberFormControlValidatorFail() {
    return (this.phoneNumberFormControl.touched || this.phoneNumberFormControl.dirty) && !this.phoneNumberFormControl.valid;
  }

  getClientName(data?: CaseDetailsRsp) {
    if (!data) {
      return '';
    }

    if (this.cdsLangService.currentLang === 'en') {
      return data.clientNameEng || data.clientNameChn;
    } else {
      return data.clientNameChn || data.clientNameEng;
    }
  }

  getAgentName(agent?: { agentName: string; agentNameTc: string }) {
    if (!agent) {
      return '';
    }

    if (this.cdsLangService.currentLang === 'en') {
      return agent.agentName || agent.agentNameTc;
    } else {
      return agent.agentNameTc || agent.agentName;
    }
  }

  continue() {
    if (this.isDisabledContinue) {
      return;
    }

    if (this.isChannelDBS) {
      this.dbs?.dbsRmReferralCd.markAsDirty();
      if (this.dbs?.dbsRmReferralCd.errors) {
        return;
      }
    }

    if (this.emailAndPhoneValue === 1) {
      if (this.emailAddressFormControl.value.length === 0) {
        this.isShowPleaseInputCustomerEmail = true;
        return;
      }
    } else {
      if (this.phoneNumberFormControl.value.length === 0) {
        this.isShowPleaseInputPhoneNumber = true;
        return;
      }
    }
    this.currentStep = 2;
  }

  back() {
    if (this.currentStep === 1) {
      this.close();
    }
    this.currentStep = 1;
  }

  emailAndPhoneValueChange(value: number) {
    this.preferredLanguage = undefined;
    if (value === 1) {
      this.preferredLanguageConfig = {
        options: [
          {
            label: 'common.chinese',
            value: PreferredLanguageType.Chinese,
            disable: true,
          },
          {
            label: 'common.english',
            value: PreferredLanguageType.English,
            disable: true,
          },
        ],
      };
    } else if (value === 2) {
      this.preferredLanguageConfig = {
        options: [
          {
            label: 'common.chinese',
            value: PreferredLanguageType.Chinese,
            disable: false,
          },
          {
            label: 'common.english',
            value: PreferredLanguageType.English,
            disable: false,
          },
        ],
      };
    }
    this.cdRadioPreferredLanguage?.refreshConfig();
  }

  agentInfosChannelValueChange(i: number, value: number) {
    if (i === 0) {
      this.secondAgentPreferredLanguage = undefined;
      if (value === 1) {
        this.secondAgentPreferredLanguageConfig = {
          options: [
            {
              label: 'common.chinese',
              value: PreferredLanguageType.Chinese,
              disable: true,
            },
            {
              label: 'common.english',
              value: PreferredLanguageType.English,
              disable: true,
            },
          ],
        };
      } else if (value === 2) {
        this.secondAgentPreferredLanguageConfig = {
          options: [
            {
              label: 'common.chinese',
              value: PreferredLanguageType.Chinese,
              disable: false,
            },
            {
              label: 'common.english',
              value: PreferredLanguageType.English,
              disable: false,
            },
          ],
        };
      }
      this.cdRadioSecondAgentPreferredLanguage?.refreshConfig();
    } else if (i === 1) {
      this.thirdAgentPreferredLanguage = undefined;
      if (value === 1) {
        this.thirdAgentPreferredLanguageConfig = {
          options: [
            {
              label: 'common.chinese',
              value: PreferredLanguageType.Chinese,
              disable: true,
            },
            {
              label: 'common.english',
              value: PreferredLanguageType.English,
              disable: true,
            },
          ],
        };
      } else if (value === 2) {
        this.thirdAgentPreferredLanguageConfig = {
          options: [
            {
              label: 'common.chinese',
              value: PreferredLanguageType.Chinese,
              disable: false,
            },
            {
              label: 'common.english',
              value: PreferredLanguageType.English,
              disable: false,
            },
          ],
        };
      }
      this.cdRadioThirdAgentPreferredLanguage?.refreshConfig();
    }
  }

  isDisableCountrycodeFormControl() {
    const disable = this.isReadOnlyEmailAndPhone || this.isReadOnlyPhoneForNull;
    if (disable) {
      this.countrycodeFormControl.disable();
    } else {
      this.countrycodeFormControl.enable();
    }
  }

  ngDoCheck(): void {
    this.isDisableCountrycodeFormControl();
  }
}
