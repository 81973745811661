import { Component, OnInit, Input } from '@angular/core';
import { CdsIconConfig } from '@cds/ng-core/icon';
import { FormIcons } from '@cds/ng-themes';
@Component({
  selector: 'app-no-result-found',
  templateUrl: './no-result-found.component.html',
  styleUrls: ['./no-result-found.component.scss'],
})
export class NoResultFoundComponent implements OnInit {
  @Input() msg?: string;
  constructor() {}

  ngOnInit(): void {}

  noResultFoundIcon = FormIcons.search;

  iconConfig: CdsIconConfig = { color: '#8e90a2', size: 'md' };
}
