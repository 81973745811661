<div class="row l-pt-4 l-pb-4 l-mt-6">
  <div class="col-xs-12 cds-color-green title-container anchor-parent">
    <div class="anchor-element" id="parentCompany"></div>
    <span class="cds-h4-demibold l-mb-0">Parent/ Subsidiary Company List</span>
    <app-permission [role]="PermissionAccess.W" [permission]="PermissionItem.CUSTOMER_EMPLOYER_SUBSIDIARY">
      <div *ngIf="editable && isStaff" class="button-area" (click)="edit()">
        <div class="icon-size">
          <cds-icon size="sm" icon="action:edit"></cds-icon>
        </div>
        <span class="cds-h6-demibold l-pl-2">Edit</span>
      </div>
    </app-permission>
  </div>

  <div class="col-xs-12">
    <div class="p-line"></div>
  </div>

  <div class="col-xs-12 l-mb-4" *ngIf="isStaff">
    <div class="score-container">
      <div class="text l-mr-5">Total Relationship Score</div>
      <div class="score">{{ relationshipTotalCount?.totalRelationshipScore }}</div>
    </div>
  </div>

  <div class="col-xs-12 l-mb-4" *ngIf="isStaff">
    <table class="total-count-table" [border]="1" cellspacing="0">
      <thead>
        <tr>
          <th>Total Aggregate Invested Amount</th>
          <th>Total Annualized Contribution</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>HKD {{ relationshipTotalCount?.totalAggregateInvestedAmount | number : '1.2-2' }}</td>
          <td>HKD {{ relationshipTotalCount?.totalAnnualizedContribution | number : '1.2-2' }}</td>
        </tr>
      </tbody>
    </table>
  </div>

  <div class="table-container col-xs-12" [formGroup]="form">
    <app-loading *ngIf="isLoading" [overlayInPopup]="true"></app-loading>
    <cds-table [dataSource]="dataSource" [class.full]="true">
      <!-- Employer Account Code -->
      <ng-container cdsColumnDef="employerNo">
        <cds-header-cell *cdsHeaderCellDef> Employer Account Code </cds-header-cell>
        <cds-cell *cdsCellDef="let element">{{ element.employerNo }}</cds-cell>
      </ng-container>

      <!-- Employer Name -->
      <ng-container cdsColumnDef="employerName">
        <cds-header-cell *cdsHeaderCellDef> Employer Name </cds-header-cell>
        <cds-cell *cdsCellDef="let element">
          <div class="cds-body1-demibold l-mb-0 weight-600">
            {{ element.employerName }}
          </div>
          <div class="cds-body1-demibold l-mb-0 weight-600" *ngIf="element.employerChineseName">{{ element.employerChineseName }}</div>
          <div class="status-area" *ngIf="!!companyService.getStatus(element.employerStatus)">
            <div class="dot-icon" [style]="companyService.getStatusColorStyle(element.employerStatus)"></div>
            <div>{{ companyService.getStatus(element.employerStatus) }}</div>
          </div>
        </cds-cell>
      </ng-container>

      <!-- Total Lives -->
      <ng-container cdsColumnDef="totalLives">
        <cds-header-cell *cdsHeaderCellDef> Total Lives </cds-header-cell>
        <cds-cell *cdsCellDef="let element">
          {{ element.totalLives }}
        </cds-cell>
      </ng-container>

      <!-- Parent Company -->
      <ng-container cdsColumnDef="isParentCompany">
        <cds-header-cell *cdsHeaderCellDef> Parent Company </cds-header-cell>
        <cds-cell *cdsCellDef="let element" class="radio-cell">
          <cds-radio [config]="radioConfig" [formControlName]="element.id" [disabled]="true"></cds-radio>
        </cds-cell>
      </ng-container>

      <!-- Relationship Score -->
      <ng-container cdsColumnDef="relationshipScore">
        <cds-header-cell *cdsHeaderCellDef> Relationship Score </cds-header-cell>
        <cds-cell *cdsCellDef="let element">
          {{ element.relationshipScore }}
        </cds-cell>
      </ng-container>

      <!-- Aggregate Invested Amount -->
      <ng-container cdsColumnDef="aggregateInvestedAmount">
        <cds-header-cell *cdsHeaderCellDef> Aggregate Invested Amount </cds-header-cell>
        <cds-cell *cdsCellDef="let element"> HKD {{ element.aggregateInvestedAmount | number : '1.2-2' }} </cds-cell>
      </ng-container>

      <!-- Annualized Contribution -->
      <ng-container cdsColumnDef="annualizedContribution">
        <cds-header-cell *cdsHeaderCellDef> Annualized Contribution </cds-header-cell>
        <cds-cell *cdsCellDef="let element"> HKD {{ element.annualizedContribution | number : '1.2-2' }} </cds-cell>
      </ng-container>

      <cds-header-row *cdsHeaderRowDef="displayedColumns"></cds-header-row>

      <cds-row *cdsRowDef="let row; columns: displayedColumns"></cds-row>
    </cds-table>
  </div>

  <!-- pagenation -->
  <div *ngIf="dataSource.length" class="col-xs-12 l-pt-4 l-pb-4 l-d-f l-jc-sb">
    <div class="pagination">
      <app-pagination [pageCount]="pageCount" [isLoading]="isLoading" [useOf]="true" (pageChange)="loadTable()"> </app-pagination>
    </div>
  </div>
</div>
