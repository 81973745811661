/* eslint-disable @typescript-eslint/no-explicit-any */
import { trigger, state, style, transition, animate } from '@angular/animations';
import {
  AfterContentInit,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  TemplateRef,
} from '@angular/core';
import { CdsIconConfig } from '@cds/ng-core/icon';
import { ColumnItem, RowInfo, Sort } from '../table-expand';
import { TableExpandColComponent } from '../table-expand-col/table-expand-col.component';

@Component({
  selector: 'app-table-expand',
  templateUrl: './table-expand.component.html',
  styleUrls: ['./table-expand.component.scss'],
  animations: [
    trigger('openClose', [
      state(
        'detail-expanded',
        style({
          height: '*',
          paddingTop: 'var(--cds-spacing-05)',
          paddingBottom: 'var(--cds-spacing-05)',
          opacity: '1',
        })
      ),
      state(
        'detail-collapsed',
        style({
          height: '0px',
          overflow: 'hidden',
          opacity: '0',
        })
      ),
      state(
        'expanded',
        style({
          rotate: '90deg',
        })
      ),
      state(
        'collapsed',
        style({
          rotate: '0deg',
        })
      ),
      transition('detail-expanded <=> detail-collapsed', [animate('0.3s ease-in-out')]),
      transition('expanded <=> collapsed', [animate('0.3s')]),
    ]),
  ],
})
export class TableExpandComponent implements OnInit, OnChanges, AfterContentInit {
  @ContentChildren(TableExpandColComponent)
  cols?: QueryList<TableExpandColComponent>;

  @Input() expandIcon = true;
  public Sort = Sort;
  public ColumnItem?: ColumnItem;

  @Input() icon = 'action:button_right';
  @Input() color = '';
  @Input() mouseColor = '#EC6453';
  @Input() size = '32px';
  @Input() data?: any[];
  @Input() progress = false;
  @Input() expandTemplate?: TemplateRef<any>;
  @Input() multiSort = false;

  @Output() expandChange = new EventEmitter<number>();
  @Output() sortChange = new EventEmitter<ColumnItem>();

  _config: CdsIconConfig = {
    color: this.color,
  };

  notfoundConfig: CdsIconConfig = { color: '#8e90a2', size: 'md' };

  _rowInfoList?: RowInfo[];
  _headerList?: ColumnItem[];
  _sortMap = new Map();

  constructor() {}

  ngAfterContentInit(): void {
    this.initData();
  }

  initData() {
    if (!this.cols) {
      return;
    }
    const _rowListTemp: RowInfo[] = [];

    this.data?.forEach(item => {
      const _columnList: ColumnItem[] = [];
      const _rowMap = new Map(Object.entries(item));
      this.cols?.forEach(col => {
        const mapVal = _rowMap.get(col.prop);
        const _item: ColumnItem = {
          label: col.label,
          prop: col.prop,
          width: col.width,
          template: col.template,
          sort: col.sort,
          val: mapVal != undefined || mapVal != null ? String(_rowMap.get(col.prop)) : undefined,
        };
        _columnList.push(_item);
      });
      if (!this._headerList) {
        this._headerList = [..._columnList];
      }
      const _rowInfo: RowInfo = {
        expand: false,
        mouseIn: false,
        data: _columnList,
        expandClass: 'detail-collapsed',
      };
      _rowListTemp.push(_rowInfo);
    });
    this._rowInfoList = _rowListTemp;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['data']) {
      this.initData();
    }
  }

  sortChangeFun(sort: Sort, index: number) {
    if (this._headerList) {
      if (!this.multiSort) {
        this._headerList.forEach(item => {
          if (item.sort !== undefined) {
            item.sort = Sort.DEFAULT;
          }
        });
      }
      this._headerList[index].sort = sort;
      const _headItem = this._headerList[index];
      this._sortMap.set(_headItem.prop, _headItem.prop + ',' + _headItem.sort);
      let _sortString = '';
      this._sortMap.forEach(val => {
        _sortString += _sortString == '' ? val : '&sort=' + val;
      });
      _headItem.sortString = _sortString;
      this.sortChange.emit(_headItem);
    }
  }

  mouseenterFun(index: number) {
    if (!this._rowInfoList) {
      return;
    }
    this._rowInfoList[index].mouseIn = true;
  }

  mouseleaveFun(index: number) {
    if (!this._rowInfoList) {
      return;
    }
    this._rowInfoList[index].mouseIn = false;
  }

  trigger(index: number) {
    if (!this._rowInfoList) {
      return;
    }
    if (!this._rowInfoList[index].expand && !this.data?.[index]?.expandData) {
      this.expandChange.emit(index);
    }
    this._rowInfoList[index].expand = !this._rowInfoList[index].expand;
    setTimeout(() => {
      if (!this._rowInfoList) {
        return;
      }
      this._rowInfoList[index].expandClass = this._rowInfoList[index].expand ? 'detail-expanded' : 'detail-collapsed';
    }, 5);
  }

  ngOnInit(): void {}
}
