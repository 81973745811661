<div class="l-pb-3">
  <span class="cds-h2-light">{{ 'common.filterRecord' | lang }}</span>
</div>

<div>
  <span (click)="resetFilter()" class="cursor-pointer l-d-f">
    <cds-icon icon="arrow:update_recycle" [config]="{ color: '#00A758' }"></cds-icon>
    <span class="l-pl-3">{{ 'common.resetFilters' | lang }}</span>
  </span>
</div>

<div class="l-d-f l-pt-5 l-pb-5 l-ai-cen">
  <div class="filter-title">{{ 'commission.common.channel' | lang }}:</div>
  <div class="filter-item l-d-f">
    <div *ngFor="let item of channelType" [class.filter-item-selected]="item === channelTypeSelected" (click)="channelFilterClick(item)">
      <div>{{ item.label }}</div>
    </div>
  </div>
</div>

<div class="l-d-f l-pb-5 l-ai-cen">
  <div class="filter-title">{{ 'commission.default.scale.percentage.label' | lang }}:</div>
  <div class="filter-item l-d-f">
    <div *ngFor="let item of commissionRate" [class.filter-item-selected]="item === commissionRateSelected" (click)="accountFilterClick(item)">
      <div>{{ item.label }}</div>
    </div>
  </div>
</div>

<div class="l-pt-2">
  <div class="l-d-f l-jc-sb">
    <div>{{ 'common.total' | lang }} {{ totalElements }} {{ 'common.records' | lang }}</div>
    <div class="quick-search-width">
      <app-cd-search
        [isAutoComplete]="true"
        [options]="options"
        (search)="quickSearch($event)"
        placeholder="{{ 'commission.common.accountType.quickScenario' | lang }}"
        [control]="quickSearchFormcontrol">
      </app-cd-search>
    </div>
  </div>
  <div>
    <app-table-expand [expandTemplate]="template" [data]="disPlayData" [progress]="progress">
      <app-table-expand-col prop="scenarioLabel" label="{{ 'commission.default.scale.scenario.label' | lang }}"></app-table-expand-col>
      <app-table-expand-col prop="agentChannelLabel" label="{{ 'commission.common.channel' | lang }}"></app-table-expand-col>
      <app-table-expand-col prop="compensationRateLabel" label="{{ 'commission.default.scale.percentage.label' | lang }}"></app-table-expand-col>
    </app-table-expand>
    <div class="l-pt-4 l-pb-4" *ngIf="disPlayData.length">
      <app-pagination [pageCount]="pageCount" (pageChange)="pageChange($event)" [useOf]="true"> </app-pagination>
    </div>
  </div>
</div>

<ng-template #template let-element>
  <form [formGroup]="formGroup" class="l-pr-6 box-size-border">
    <div formArrayName="details">
      <app-permission [role]="permissionAccess" [permission]="permissionItem">
        <div class="l-pt-4 l-pl-4 l-pr-4 l-pb-5 l-d-f l-jc-sb l-ai-cen">
          <div class="l-d-f icon-button l-ai-cen" (click)="editScaleCode(element.detail)">
            <cds-icon size="sm" [icon]="'action:edit'"></cds-icon>
            <button class="button-border-none-font btn-text" *ngIf="!element.detail.isEdit">Edit Scale Code</button>
            <button class="button-border-none-font btn-text" *ngIf="element.detail.isEdit">Cancel Editing</button>
          </div>
        </div>
      </app-permission>
      <div class="row l-plr-0 div-border">
        <ng-container *ngFor="let item of element.detail.list" [formGroupName]="element.detail.index.toString()">
          <div class="col-xs-12 col-lg-4 row l-pa-4 div-border-right div-border-top">
            <div class="col-xs-12 col-lg-6 cds-demibold">
              {{ item.label | lang }}
            </div>

            <div class="col-xs-12 col-lg-6">
              <div *ngIf="!element.detail.isEdit">
                {{ details.controls[element.detail.index].get(item.key)?.value }}
              </div>

              <div *ngIf="element.detail.isEdit" class="l-pr-4">
                <input class="input-noborder" [formControlName]="item.key" (input)="scaleCodeChange(element.detail, item.key, $event)" />
                <cds-assistive-text
                  class="l-pt-3"
                  *ngIf="details.controls[element.detail.index].get(item.key)?.dirty && details.controls[element.detail.index].get(item.key)?.errors">
                  {{ details.controls[element.detail.index].get(item.key)?.errors?.['error'] | lang }}
                </cds-assistive-text>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
      <div class="l-pt-5 l-d-f" *ngIf="element.detail.isEdit">
        <cds-button [config]="{ size: 'sm', style: 'secondary' }" (click)="reset(element.detail)"> {{ 'common.reset' | lang }}</cds-button>
        <cds-button
          class="l-pl-3"
          [config]="{ size: 'sm', style: 'primary' }"
          (click)="save(element)"
          [disabled]="!details.controls[element.detail.index].dirty || details.controls[element.detail.index].invalid">
          {{ 'common.save' | lang }}</cds-button
        >
      </div>
    </div>
  </form>
</ng-template>
