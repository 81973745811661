<ng-container *ngFor="let item of data">
  <div class="l-d-f l-jc-sb l-pt-3 l-pb-3 card">
    <div class="l-pl-3">
      <span class="cds-body2-demibold">{{ item.title | lang }}</span>
    </div>
    <div class="l-d-f l-fd-row l-mr-3 l-ai-cen">
      <div class="round-tips cds-green l-mr-3 l-ai-cen" [class]="item.color"></div>
      <cds-icon icon="action:button_down"></cds-icon>
    </div>
  </div>
  <div class="l-pt-2 l-pb-2 l-pl-2 card cds-body2 l-ma-0" *ngFor="let list of item.children">
    <div class="l-d-f l-ai-cen">
      <cds-checkbox [checked]="list.checked" (change)="change($event, list)"></cds-checkbox>
      <label class="l-ml-3 cds-body cds-dark-navy-text l-mb-0">{{ list.title | lang }}</label>
    </div>
    <!-- <cds-checkbox [checked]="list.checked" [label]="list.title | lang" (change)="change($event, list)"></cds-checkbox> -->
  </div>
</ng-container>
