import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

import { CdsButtonConfig } from '@cds/ng-core/button';

@Component({
  selector: 'app-ggi-declaration-popup',
  templateUrl: './ggi-declaration-popup.component.html',
  styleUrls: ['./ggi-declaration-popup.component.scss'],
})
export class GgiDeclarationPopupComponent implements OnInit {
  isEr = true;
  isScrollBottom = false;
  commonButton: CdsButtonConfig = {
    size: 'md',
    style: 'primary',
  };

  constructor(private dialogRef: MatDialogRef<GgiDeclarationPopupComponent>) {}

  ngOnInit(): void {}

  closeCurrentPopup() {
    this.dialogRef.close({ agree: false });
  }

  continue() {
    if (this.isScrollBottom) {
      this.dialogRef.close({ agree: true });
    }
  }

  haveReachedTheBottom() {
    this.isScrollBottom = true;
  }
}
