/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { CdsButtonConfig } from '@cds/ng-core/button';
import { CdsDropdownConfig } from '@cds/ng-core/dropdown';
import { CdsLangService } from '@cds/ng-core/lang';
import { CdsPopupService } from '@cds/ng-web-components/popup';
import { finalize } from 'rxjs';
import { BTN_CONFIG_PRIMARY, BTN_CONFIG_SECONDARY } from 'src/app/config/btn.config';
import { PermissionAccess, PermissionItem } from 'src/app/core/models/enum/permission.enum';
import { IPageableRequest } from 'src/app/core/models/request';
import { PermissionService } from 'src/app/core/services/permission.service';
import { AlertPopupComponent } from 'src/app/shared/alert-popup/alert-popup.component';
import { ColumnConfig, PageConfig, SortMode } from 'src/app/shared/data-table';
import { ToastAlertService } from 'src/app/shared/toast-alert.service';
import { dateValidator } from '../../system/calendar/calendar.validators';
import { AgentSep, ColumnSort, EnrolmentStatusE, enrolmentStatusMapObj } from '../employer';
import { EmployerService } from '../employer.service';
import { UploadUnitPriceListFileComponent } from '../upload-unit-price-list-file/upload-unit-price-list-file.component';

@Component({
  selector: 'app-unit-price',
  templateUrl: './unit-price.component.html',
  styleUrls: ['./unit-price.component.scss'],
})
export class UnitPriceComponent implements OnInit {
  uploadBtnCfg: CdsButtonConfig = BTN_CONFIG_SECONDARY;
  addBtnCfg: CdsButtonConfig = BTN_CONFIG_PRIMARY;

  totalElements = 0;

  canEdit = true;

  isLoading = false;

  dataSource: AgentSep[] = [];

  pageConfig: PageConfig = {
    current: 1,
    pageSize: 10,
    totalCount: 0,
  };

  EnrolmentStatusE = EnrolmentStatusE;

  columnsConfig: ColumnConfig[] = [
    {
      key: 'fundCode',
      title: 'Fund Code',
      sort: SortMode.DEFAULT,
    },
    {
      key: 'date',
      title: 'Date',
      sort: SortMode.DEFAULT,
    },
    {
      key: 'unitPrice',
      title: 'Unit Price',
      sort: SortMode.DEFAULT,
    },
  ];

  columnSort: ColumnSort = {
    key: 'fundCode',
    value: SortMode.DOWN,
  };

  fundCOdeConfig: CdsDropdownConfig = {
    label: 'Fund Code',
    placeholder: 'Select Fund Code',
    options: [],
  };

  showDatePicker = true;

  enrolmentStatusMapObj = enrolmentStatusMapObj;

  form: FormGroup = new FormGroup({});

  get dateControl() {
    return this.form?.get('date');
  }

  get searchButtonDisabled() {
    return !this.form?.valid || !this.form.dirty;
  }

  get resetButtonDisabled() {
    return !this.form?.dirty;
  }

  searchButtonConfig: CdsButtonConfig = {
    size: 'sm',
  };

  resetButtonConfig: CdsButtonConfig = {
    size: 'sm',
    style: 'secondary',
  };

  constructor(
    private service: EmployerService,
    private cdsPopup: CdsPopupService,
    private toastAlert: ToastAlertService,
    private permissionService: PermissionService,
    private langService: CdsLangService
  ) {}

  ngOnInit(): void {
    this.initForm();
    this.loadTable();

    this.permissionService.hasPermission(PermissionAccess.W, PermissionItem.CUSTOMER_EMPLOYER).then(hasPermission => {
      this.canEdit = hasPermission;
    });
  }

  initForm() {
    this.form?.markAsUntouched();
    this.form?.markAsPristine();

    this.form = new FormGroup({
      fundCode: new FormControl(''),
      date: new FormControl('', [dateValidator('create.dateError')]),
    });
  }

  getRequestParams() {
    const params: IPageableRequest = {
      page: (this.pageConfig.current as number) - 1,
      size: 10,
    };
    params.sort = `${this.columnSort.key},${this.columnSort.value}`;
    return params;
  }

  loadTable() {
    return;
    const params: IPageableRequest = this.getRequestParams();
    this.isLoading = true;

    this.service
      .getAgentSepList(params)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe(data => {
        if (data.result === 0 && data.data) {
          this.isLoading = false;
          this.dataSource = data.data.empty ? [] : data.data.content;
          this.totalElements = data.data.totalElements;

          this.pageConfig = {
            ...this.pageConfig,
            totalCount: data.data.totalElements,
          };
        } else {
          this.toastError(data.message);
        }
      });
  }

  search() {
    if (this.searchButtonDisabled) {
      return;
    }
    this.pageConfig = {
      ...this.pageConfig,
      current: 1,
    };
    this.loadTable();
  }

  reset() {
    if (this.resetButtonDisabled) {
      return;
    }
    this.pageConfig = {
      ...this.pageConfig,
      current: 1,
    };

    this.initForm();

    this.showDatePicker = false;
    setTimeout(() => {
      this.showDatePicker = true;
      this.loadTable();
    });
  }

  upload() {
    this.cdsPopup
      .open(UploadUnitPriceListFileComponent, {
        size: 'md',
      })
      .afterClosed()
      .subscribe(result => {
        if (result?.agree) {
          this.popupWithReload();
        } else {
          this.isLoading = false;
        }
      });
  }

  popupWithReload() {
    this.cdsPopup
      .open(AlertPopupComponent, {
        size: 'sm',
        data: {
          message: this.langService.translate('common.action.uploadSuccess'),
          buttonName: this.langService.translate('common.gotItWithExclamation'),
        },
      })
      .afterClosed()
      .subscribe(() => {
        this.loadTable();
      });
  }

  reloadTable(event: any) {
    this.pageConfig = {
      ...this.pageConfig,
      current: event.current,
    };
    this.columnSort =
      event.column && event.column.sort !== SortMode.DEFAULT
        ? {
            key: event.column?.key,
            value: event.column?.sort,
          }
        : {
            key: 'sepCommDate',
            value: SortMode.DOWN,
          };
    this.loadTable();
  }

  toastError(errorMessage: string) {
    this.toastAlert.show('error', 'common.error', errorMessage, 5000);
  }
}
