import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpResponse,
  HttpDownloadProgressEvent,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { Observable, throwError, EMPTY, from } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { PermissionService } from '../services/permission.service';

@Injectable()
export class AuthHttpInterceptor implements HttpInterceptor {
  userInfo: any;

  constructor(private router: Router, public permission: PermissionService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return from(this.permission.getUserInfo()).pipe(
      switchMap(userInfo => {
        if (req.url && req.url.includes('/chatbot-service')) {
          this.userInfo = userInfo;
          var d = new Date();
          var timeZoneName = d.toLocaleString('en', { timeZoneName: 'short' }).split(' ').pop();

          let authReq = req.clone({
            headers: req.headers
              .set('Content-Type', 'application/json')
              .set('Time-Zone', d.getTimezoneOffset().toString())
              .set('app-prefix', 'backend')
              .set('language', 'en')
              .set('DisplayName', encodeURIComponent(userInfo?.displayName))
              .set('username', encodeURIComponent(userInfo?.displayName))
              .set('Email', userInfo?.email)
              .set('Id', userInfo?.id)
              .set('CurrentLastUpdate', userInfo?.currentLastUpdate?.toString())
              .set('Permissions', JSON.stringify(userInfo?.permissions)),
          });

          let token = 'secret';
          if (token) {
            authReq = authReq.clone({
              headers: authReq.headers.set('Authorization', userInfo?.token),
            });
          }

          return next.handle(authReq);
        } else {
          return next.handle(req);
        }
      })
    );
  }
}
