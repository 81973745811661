import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AgentInfo, AgentStatusE, agentStatusMapObj } from '../../employer';
import { EmployerService } from '../../employer.service';

@Component({
  selector: 'app-agent-info',
  templateUrl: './agent-info.component.html',
  styleUrls: ['./agent-info.component.scss'],
})
export class AgentInfoComponent implements OnInit, OnChanges {
  constructor(private service: EmployerService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['id']) {
      if (this.id) {
        this.getAgentInfo(this.id);
      }
    }
  }

  @Input() id: string | undefined;

  agentInfo?: AgentInfo;

  ngOnInit(): void {}

  toDetail() {}

  getAgentInfo(id: string) {
    this.service.getAgentInfo(id).subscribe(data => {
      if (data.result === 0) {
        this.agentInfo = data.data;
      }
    });
  }

  getStatusColorStyle(status: AgentStatusE | undefined) {
    if (!status) {
      return '';
    }
    return agentStatusMapObj[status]?.color;
  }

  getStatus(status: AgentStatusE | undefined) {
    if (!status) {
      return '';
    }
    return agentStatusMapObj[status]?.text;
  }
}
