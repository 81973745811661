<div class="user-confirm-pop">
  <div class="cds-h4-demibold">
    <span>{{ 'confirmation.alertText1' | lang }}</span>
    <span class="text2">{{ 'confirmation.alertText2' | lang }}</span>
    <span>{{ cdsLangService.currentLang === 'en' ? '.' : '。' }}</span>
  </div>
  <div class="btn-box">
    <cds-button [config]="agreeButtonConfig" (click)="disagree()" label="{{ 'backToEdit' | lang }}"></cds-button>
    <cds-button [config]="disagreeButtonConfig" (click)="agree()" label="{{ 'understandAndContinue' | lang }}"></cds-button>
  </div>
</div>
