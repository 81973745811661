import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-fund-benifit',
  templateUrl: './fund-benifit.component.html',
  styleUrls: ['./fund-benifit.component.scss'],
})
export class FundBenifitComponent implements OnInit {
  _index = 0;

  constructor() {}

  ngOnInit(): void {}

  selectedTabIndexChange(index: number) {
    console.log(index, '==========');
    this._index = index;
  }
}
