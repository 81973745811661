/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { IPaginationCount } from './pagination.dto';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppPaginationComponent implements AfterViewInit, OnChanges {
  private _pageCount: IPaginationCount = { current: 1, count: 1 };

  private _isViewInit = false;

  @ViewChild('inkBar') inkBar?: ElementRef<HTMLElement>;

  @Output() pageChange = new EventEmitter<number>();
  @Input() isShowInput = true;
  @Input() isLoading = false;
  @Input() useOf = false;
  @Input() textfieldAlwaysAvailable = false;
  @Input()
  set pageCount(iPageCount: IPaginationCount) {
    const _pageCount = iPageCount;
    if (_pageCount.count < 1) {
      _pageCount.count = 1;
    }
    if (_pageCount.current < 1) {
      _pageCount.current = 1;
    }
    if (this._pageCount.current != _pageCount.current) {
      this.pageChange.emit(_pageCount.current);
    }
    this._pageCount = _pageCount;
    this.updateInkBarPosition();
  }

  get pageCount(): IPaginationCount {
    return this._pageCount;
  }

  constructor(private elemRef: ElementRef, private cdr: ChangeDetectorRef) {}

  ngAfterViewInit(): void {
    this._isViewInit = true;
    this.updateInkBarPosition();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['pageCount']) {
      this.updateInkBarPosition();
    }
    if (changes['isLoading']) {
      console.debug(`isLoadingisLoadingisLoadingisLoading`);
    }
  }

  updateInkBarPosition() {
    if (!this._isViewInit) {
      return;
    }
    requestAnimationFrame(() => {
      const selectedElem = this.getSelectedElem();
      if (this.inkBar) {
        this.inkBar.nativeElement.style.left = selectedElem.offsetLeft + 'px';
        this.inkBar.nativeElement.style.width = selectedElem.offsetWidth + 'px';
      }
    });
  }

  clickPage(page: number) {
    console.debug(`clickPage=${page}`);
    // if (!this.isLoading) {

    // }
    this.selectPage(page);
  }

  selectPage(page: number, event?: any) {
    this.cdr.detach();
    console.debug(`selectPage=${page}`);

    if (page > this.pageCount.count) {
      page = this.pageCount.count;
    } else if (page < 1) {
      page = 1;
    }
    if (this.pageCount.current === page) {
      if (event) {
        event.target.value = page;
      }
      this.cdr.reattach();
      return;
    }
    this.pageCount.current = page;
    this.updateInkBarPosition();
    this.pageChange.emit(this._pageCount.current);
    this.cdr.reattach();
  }

  isPageShown(page: number) {
    if (page === 1 || page === this.pageCount.count) {
      return true;
    }
    if (this.pageCount.current - 2 <= page && this.pageCount.current + 2 >= page) {
      return true;
    }
    if (this.pageCount.current - 4 === 1 && page < this.pageCount.count) {
      return 1 < page;
    }
    if (this.pageCount.current + 4 >= this.pageCount.count) {
      return this.pageCount.current < page && page < this.pageCount.count;
    }
    return false;
  }

  getSelectedElem() {
    return this.elemRef.nativeElement.getElementsByClassName('selected')[0];
  }

  gotoPage(event: any) {
    const iPage = event.target.value || '';
    let searchPage = parseInt(iPage);
    if (!searchPage) {
      searchPage = 1;
    }
    console.debug(searchPage);
    this.selectPage(searchPage, event);
  }
}
