import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { CDS_POPUP_DATA } from '@cds/ng-web-components/popup';
import { DateTime } from 'luxon';
import { CommentHistory } from '../employer';
import { EmployerService } from '../employer.service';

@Component({
  selector: 'app-comments-history',
  templateUrl: './comments-history.component.html',
  styleUrls: ['./comments-history.component.scss'],
})
export class CommentsHistoryComponent implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<CommentsHistoryComponent>,
    @Inject(CDS_POPUP_DATA) public data: { customerId: string | undefined },
    private service: EmployerService
  ) {}

  dataList: CommentHistory[] = [];

  ngOnInit(): void {
    if (this.data.customerId) {
      this.getCommentsHistory(this.data.customerId);
    }
  }

  gotIt() {
    this.dialogRef.close({ agree: false });
  }

  getCommentsHistory(customerId: string) {
    this.service.getCommentsHistory(customerId).subscribe(data => {
      if (data.result === 0 && data.data) {
        this.dataList = data.data;
      }
    });
  }

  updateDateGen(value: string) {
    const dateTime = DateTime.fromISO(value);
    if (dateTime.isValid) {
      return dateTime.toFormat('hh:mma dd/MM/yyyy (Z') + 'T)';
    }
    return '';
  }
}
