import { SortMode } from 'src/app/shared/data-table/data-table.model';

export interface Inflation {
  salaryInflationFactor?: string;
}

export interface Scheme {
  id?: string;
  schemeNo?: string;
  registrationNo?: string;
  schemeCode?: string;
  name?: string;
  type?: string;
  pooledPolicyNumber?: string;
  lastMonthlyReportDate?: string;
  nextFinancialYearEnd?: string;
  mpfaAuditSignal?: boolean; //TRUE 1 FALSE 0
  currentInflation: Inflation;
  nextInflation?: Inflation;
  createTime?: string;
  lastUpdateTime?: string;
}

export const I18N_KEY = {
  SCHEME_NAME_REQUIRED: 'common.business.schemeNameRequired',
  REGISTATION_NO_REQUIRED: 'common.business.schemeCodeRequired',
  SCHEME_SALARY_WRN_CY: 'scheme.master.warn.SIFCY',
  SCHEME_SALARY_WRN_NY: 'scheme.master.warn.SIFNY',
  COMMON_EDIT: 'common.edit',
  COMMON_CANCLE_EDIT: 'common.cancel.editing',
  COMMON_ACTION_CANCLE: 'common.action.cancel',
  COMMON_CANCLE: 'common.cancel',
  COMMON_CONTINUE: 'common.continue',
  COMMON_ACTION_SUCCESS: 'common.action.success',
  COMMON_GOT: 'common.gotItWithExclamation',
  COMMON_ACTION_FAILE: 'common.action.failed',
  COMMON_BACK_EDIT: 'common.backToEdit',
};

export const SCHEME_TABLE_COLUMNS = [
  {
    key: 'schemeCode',
    title: 'common.business.schemeCode',
    sort: SortMode.UP,
    sticky: true,
  },
  { key: 'name', title: 'common.business.schemeName', stickyEnd: true },
  { key: 'currentInflation', title: 'common.business.salaryInflationFactorCy' },
];
