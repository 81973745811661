<app-cd-page-title [showBack]="true" [backLandingLabel]="'role.list' | lang" (backLanding)="back()" title="{{ 'role.edit.title' | lang }}"> </app-cd-page-title>
<div class="l-pt-5 row" style="text-align: center" *ngIf="isLoading">
  <div class="col-xs-12">
    <app-loading></app-loading>
  </div>
</div>
<div class="l-pt-5 row" *ngIf="!roldError && !isLoading">
  <div
    class="tab col-md-6 l-d-f l-jc-cen l-ai-cen"
    [class.tab-selected]="selectedTabIndex === i"
    *ngFor="let item of tab; let i = index"
    (click)="tabChange(i)">
    {{ item | lang }}
  </div>
  <div class="col-xs-12 l-mt-5 l-pa-0">
    <app-role-info-form *ngIf="selectedTabIndex === 0" [role]="roleName" [roleId]="roleId" [formPermissions]="formPermissions"></app-role-info-form>
    <app-role-info-user [roleId]="roleId" *ngIf="selectedTabIndex === 1"></app-role-info-user>
  </div>
</div>

<div *ngIf="roldError && !isLoading" class="l-pa-8">
  <app-no-result-found [msg]="'Role not found'"></app-no-result-found>
</div>
