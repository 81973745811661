import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PermissionAccess } from 'src/app/core/models/enum/permission.enum';
import { AdminLayoutComponent } from '../layout/admin-layout/admin-layout.component';
import { ActivityRecordsComponent } from './activity-records/activity-records.component';
import { AgentSepComponent } from './agent-sep/agent-sep.component';
import { EmployerListComponent } from './employer-list/employer-list.component';
import { EmployerProfileDetailComponent } from './employer-profile-detail/employer-profile-detail.component';
import { EmployerProfileComponent } from './employer-profile/employer-profile.component';
import { FundBenifitComponent } from './fund-benifit/fund-benifit.component';
import { PayrollGroupActivityRecordComponent } from './payroll-group-activity-record/payroll-group-activity-record.component';
import { PaymentRecordDetailsComponent } from './payroll-group-info/payment-record-details/payment-record-details.component';
import { PayrollGroupInfoComponent } from './payroll-group-info/payroll-group-info.component';
import { SqLogMasterListComponent } from './sq-log-master-list/sq-log-master-list.component';

const routes: Routes = [
  {
    path: 'employer',
    component: AdminLayoutComponent,
    children: [
      { path: '', pathMatch: 'full', redirectTo: 'list' },
      {
        path: 'profile',
        component: EmployerProfileComponent,
        data: {
          title: 'er.profile.title',
          roles: {
            CUSTOMER_EMPLOYER: PermissionAccess.R,
            CUSTOMER_EMPLOYER_INTERMEDIARY: PermissionAccess.R,
          },
        },
      },
      {
        path: 'list',
        component: EmployerListComponent,
        data: {
          title: 'er.list.title',
          roles: {
            CUSTOMER_EMPLOYER: PermissionAccess.R,
            CUSTOMER_EMPLOYER_INTERMEDIARY: PermissionAccess.R,
          },
        },
      },
      {
        path: 'profile-detail',
        component: EmployerProfileDetailComponent,
        data: {
          title: 'er.profile.title',
          roles: {
            CUSTOMER_EMPLOYER: PermissionAccess.R,
            CUSTOMER_EMPLOYER_INTERMEDIARY: PermissionAccess.R,
          },
        },
      },
      {
        path: 'payroll-group-info',
        component: PayrollGroupInfoComponent,
        data: {
          title: 'er.profile.title',
          roles: {
            CUSTOMER_EMPLOYER: PermissionAccess.R,
            CUSTOMER_EMPLOYER_INTERMEDIARY: PermissionAccess.R,
          },
        },
      },
      {
        path: 'payment-record-detail',
        component: PaymentRecordDetailsComponent,
        data: {
          title: 'payment.record-details',
          roles: {
            CUSTOMER_EMPLOYER: PermissionAccess.R,
          },
        },
      },
      {
        path: 'activity-record',
        component: ActivityRecordsComponent,
        data: {
          title: 'employer.activity-record-list',
          roles: {
            CUSTOMER_EMPLOYER_ACTIVITY_CODE: PermissionAccess.R,
          },
        },
      },
      {
        path: 'employer-activity-record',
        component: PayrollGroupActivityRecordComponent,
        data: {
          title: 'employer.er-activity-record',
          roles: {
            CUSTOMER_EMPLOYER_ACTIVITY_CODE: PermissionAccess.R,
          },
        },
      },
      {
        path: 'sq-log-master-list',
        component: SqLogMasterListComponent,
        data: {
          title: 'employer.sq-log-master-list',
          roles: {
            CUSTOMER_EMPLOYER_SQ: PermissionAccess.R,
          },
        },
      },
      {
        path: 'agent-sep',
        component: AgentSepComponent,
        data: {
          title: 'employer.agent-sep-list',
          roles: {
            CUSTOMER_EMPLOYER: PermissionAccess.R,
            CUSTOMER_EMPLOYER_INTERMEDIARY: PermissionAccess.R,
          },
        },
      },
      {
        path: 'fund-benifit',
        component: FundBenifitComponent,
        data: {
          title: 'employer.fund-benefit',
          roles: {
            CUSTOMER_EMPLOYER: PermissionAccess.R,
          },
        },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class EmployerRoutingModule {}
