/* eslint-disable @typescript-eslint/no-explicit-any */
import { AbstractControl, FormArray, FormGroup, ValidationErrors } from '@angular/forms';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, of } from 'rxjs';

import { CommissionAgentService } from 'src/app/core/services/commission/commission-agent.service';

import { COMM_FLAGS_OTHER, I18N_KEY } from './appeal-case.config';
import { ResponseResult } from 'src/app/core/models/response/response-result';

@Injectable({ providedIn: 'root' })
export class AgentCodeAsyncValidator {
  constructor(private commissionAgentService: CommissionAgentService) {}

  myValidate(editRow: any, formGroup: FormGroup, makeAppeal: any) {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      const value = control.value?.trim();
      if (!value || !control.dirty) {
        return of(null);
      }
      const isMPFPAP = editRow.accountType === 'MPF-PAP';
      const isPP = editRow.formType === 'PP';
      const isOther = formGroup.value.commFlag === COMM_FLAGS_OTHER.value;
      const fshkpcCode = formGroup.value.fshkpcCode;

      return this.commissionAgentService.getAgentByCode(value).pipe(
        map(res => {
          if (res && res.result === ResponseResult.SUCCESS && res.data) {
            const isAgency = res.data.agentChannel === 'AGENCY' || res.data.agentChannel === 'Agency';
            const index = (formGroup.controls['details'] as FormArray).controls.findIndex(
              controlItem => (controlItem as FormGroup).controls['agentCode'] == control
            );
            makeAppeal[index].agentChannel = res.data.agentChannel;
            makeAppeal[index].agentCode = value;
            if (isOther && fshkpcCode && (!isMPFPAP || !isPP || !isAgency)) {
              return { error: I18N_KEY.SCALE_CODE_LIMIT };
            }
            return null;
          }
          return { error: I18N_KEY.AGENT_CODE_INVALID };
        }),
        catchError(() => of(null))
      );
    };
  }
}
