import { AfterViewInit, Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { MatDialogRef } from '@angular/material/dialog';

import { CdsButtonConfig } from '@cds/ng-core/button';
import { CdsDropdownConfig } from '@cds/ng-core/dropdown';
import { CdsIconConfig } from '@cds/ng-core/icon';
import { ActionIcons } from '@cds/ng-themes';
import { CdsAlertService } from '@cds/ng-web-components/alert';
import { LanguageChangeService } from 'src/app/shared/language-change.service';
import { IffService } from '../../service/iff.service';
import { ValidateEmpfRefNoRequest } from '../../service/model/validate-empf-ref-no-request.model';
import { CdsPopupService, CDS_POPUP_DATA } from '@cds/ng-web-components/popup';
import { ExitIffWarnPopupComponent } from './exit-iff-warn-popup/exit-iff-warn-popup.component';
import { EMPFRefNoUsedComponent } from './e-mpf-ref-no-used/e-mpf-ref-no-used.component';
import { AgentInfo, AgentSubmissionRequest } from '../../service/model/agent-submission-request.model';
import { IffSubmissionSuccPopupComponent } from './iff-submission-succ-popup/iff-submission-succ-popup.component';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { AgentDetailInfo, AgentInfoResponse } from '../../service/model/agent-info-response.model';
import { empfCountryOption } from '../declaration-iff-er/empf-declaration-form.config';
import { CdsLangService } from '@cds/ng-core/lang';
import { CdHttpServeService } from 'src/app/shared/cd-http-serve/cd-http-serve.service';
import { agentCodeRemovePrefix0 } from 'src/app/utils/utils';
import { CdRadioComponent, CdRadioConfig } from 'src/app/shared/cd-radio/cd-radio.component';
import { AgentChannel } from 'src/app/core/models/enum/agent-channel.enum';
import { PreferredLanguageType, SalesJourneyProdType } from '../case-summary.model';
import { AgentChannelService } from 'src/app/core/services/agent-channel.service';
import { ValidateEmpfRefNoResponse } from '../../service/model/validate-empf-ref-no-response.model';
import { AgentSubmissionResponse } from '../../service/model/agent-submission-response.model';

@Component({
  selector: 'app-declaration-iff',
  templateUrl: './declaration-iff.component.html',
  styleUrls: ['./declaration-iff.component.scss'],
})
export class DeclarationIffComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('displayContent') displayContent!: ElementRef<HTMLDivElement>;
  @ViewChild('cdRadioPreferredLanguage') cdRadioPreferredLanguage!: CdRadioComponent;
  @ViewChild('cdRadioSecondAgentPreferredLanguage') cdRadioSecondAgentPreferredLanguage?: CdRadioComponent;
  PreferredLanguageType = PreferredLanguageType;
  language = 'EN';
  public lanChangeSubscription?: Subscription;
  public validateEmpfRefNoSubscription?: Subscription;
  public agentSubmissionSubscription?: Subscription;
  public secondAgentInfoSubscription?: Subscription;
  public errorSub?: Subscription;
  rmReferralCodeVali = (control: AbstractControl): ValidationErrors | null => {
    let input = control.value || '';
    input = input.trim();
    const isValid = input.length >= 6 && input.length <= 8;
    return isValid ? null : { lengthError: true };
  };
  inputCustomerIdVali = (control: AbstractControl): ValidationErrors | null => {
    let input = control.value || '';
    input = input.trim();
    const isValid = ((input.length >= 6 && input.length <= 8) || input.length === 0) && /^[A-Za-z0-9-]*$/.exec(input);
    return isValid ? null : { lengthError: true };
  };
  sourceCodeVali = (control: AbstractControl): ValidationErrors | null => {
    let input = control.value || '';
    input = input.trim();
    const isValid = (input.length === 10 || input.length === 0) && /^[A-Za-z0-9-]*$/.exec(input);
    return isValid ? null : { lengthError: true };
  };
  campaignCodeVali = (control: AbstractControl): ValidationErrors | null => {
    let input = control.value || '';
    input = input.trim();
    const isValid = (input.length === 10 || input.length === 0) && /^[A-Za-z0-9-]*$/.exec(input);
    return isValid ? null : { lengthError: true };
  };
  dbs = {
    rmReferralCode: new FormControl('', [Validators.required, this.rmReferralCodeVali]),
    inputCustomerId: new FormControl('', [this.inputCustomerIdVali]),
    sourceCode: new FormControl('', [this.sourceCodeVali]),
    campaignCode: new FormControl('', [this.campaignCodeVali]),
  };
  hKIDPassportNoConfig: CdsDropdownConfig = {
    options: [
      { label: this.cdsLangService.translate('iff.hkid'), value: '0' },
      { label: this.cdsLangService.translate('iff.passport'), value: '1' },
    ],
    placeholder: this.cdsLangService.translate('iff.pleaseSelectIDType'),
  };
  hKIDPassportNoFormControl = new FormControl();
  isScrollBottom = false;
  onScoll = () => {
    const scrollHeight = this.displayContent.nativeElement.scrollHeight;
    const clientHeight = this.displayContent.nativeElement.clientHeight;

    if (!this.isScrollBottom && this.displayContent.nativeElement.scrollTop > scrollHeight - clientHeight - 10) {
      this.isScrollBottom = true;
    }
  };
  currentStage = 1; // there are 5 stages now. 0: advise-empfref page; 1: declaration page; 2: iffForm page; 3: not-split-agent confirmation page; 4: split agent page; 5: split agent confirmation page
  preferredLanguageConfig = {
    options: [
      {
        label: 'common.chinese',
        value: PreferredLanguageType.Chinese,
        disable: true,
      },
      {
        label: 'common.english',
        value: PreferredLanguageType.English,
        disable: true,
      },
    ],
  };

  secondAgentPreferredLanguageConfig = {
    options: [
      {
        label: 'common.chinese',
        value: PreferredLanguageType.Chinese,
        disable: true,
      },
      {
        label: 'common.english',
        value: PreferredLanguageType.English,
        disable: true,
      },
    ],
  };

  constructor(
    private dialogRef: MatDialogRef<DeclarationIffComponent>,
    private alert: CdsAlertService,
    public languageChangeService: LanguageChangeService,
    private iffService: IffService,
    private cdsPopup: CdsPopupService,
    private authenticationService: AuthenticationService,
    public cdsLangService: CdsLangService,
    @Inject(CDS_POPUP_DATA)
    public data: {
      domain: string;
    },
    private cdHttpServeService: CdHttpServeService,
    private agentChannelService: AgentChannelService
  ) {}

  ngAfterViewInit(): void {
    this.displayContent.nativeElement.addEventListener('scroll', this.onScoll);
  }

  ngOnDestroy(): void {
    this.errorSub?.unsubscribe();
    this.lanChangeSubscription?.unsubscribe();
    this.validateEmpfRefNoSubscription?.unsubscribe();
    this.agentSubmissionSubscription?.unsubscribe();
    this.secondAgentInfoSubscription?.unsubscribe();
    this.displayContent.nativeElement.removeEventListener('scroll', this.onScoll);
  }
  commonButton: CdsButtonConfig = {
    size: 'md',
    style: 'primary',
  };

  commonButton2: CdsButtonConfig = {
    size: 'md',
    style: 'secondary',
    disabled: false,
  };

  iffForm!: FormGroup;
  servicingAgentId?: string;
  servicingAgentCode?: string;
  secondAgentInfo?: AgentDetailInfo;
  isSecondAgentConduct?: boolean;
  secondAgentRadioConfig: CdRadioConfig = {
    options: [
      {
        label: this.language === 'EN' ? 'Registered Email()' : '已登記的電郵地址（',
        value: 'E',
        disable: false,
      },
      {
        label: this.language === 'EN' ? 'Registered Mobile No.()' : '已登記的電話號碼（）',
        value: 'M',
        disable: false,
      },
    ],
  };
  secondAgentPreferredLanguage?: string;

  secondAgentContactEmail = '';
  secondAgentContactPhone = '';

  agentInfoDataSubjectCallBack = (agentInfoResp: AgentInfoResponse) => {
    if (agentInfoResp && agentInfoResp.result === 0 && agentInfoResp.data && agentInfoResp.data.agentCode) {
      this.secondAgentInfo = agentInfoResp.data;

      const secondAgentEmail = this.secondAgentInfo?.emailAddress;
      const secondAgentPhoneNo = this.secondAgentInfo?.mobileNumber;
      let labelOfEmail = this.language === 'EN' ? 'Registered Email' : '已登記的電郵地址';
      let labelOfPhone = this.language === 'EN' ? 'Registered Mobile No.' : '已登記的電話號碼';
      if (secondAgentEmail) {
        const charIndex = secondAgentEmail.indexOf('@');
        const firstPart = secondAgentEmail.substring(0, charIndex);
        const secondPart = secondAgentEmail.substring(charIndex + 1);
        const revisedEmail = firstPart.substring(0, 4) + '******' + '@' + secondPart;
        labelOfEmail = this.language === 'EN' ? 'Registered Email(' + revisedEmail + ')' : '已登記的電郵地址（' + revisedEmail + '）';
      }
      if (secondAgentPhoneNo) {
        const phoneNoMasked = secondAgentPhoneNo.substring(0, 4) + '****';
        labelOfPhone = this.language === 'EN' ? 'Registered Mobile No.(' + phoneNoMasked + ')' : '已登記的電話號碼（' + phoneNoMasked + '）';
      }

      this.secondAgentContactEmail = labelOfEmail;
      this.secondAgentContactPhone = labelOfPhone;
      this.secondAgentRadioConfig = {
        options: [
          { label: labelOfEmail, value: 'E', disable: !secondAgentEmail },
          { label: labelOfPhone, value: 'M', disable: !secondAgentPhoneNo },
        ],
      };
      this.secondAgentContactTypeSelected = labelOfEmail;
      if (!this.conductedRegulatorActivityFlag.value) {
        this.isSecondAgentConduct = false;
      } else {
        this.isSecondAgentConduct = undefined;
      }
      if (secondAgentEmail) {
        this.secondAgentNotificationChannel = 'E';
      } else if (secondAgentPhoneNo) {
        this.secondAgentNotificationChannel = 'M';
      }
      const eMPFReferenceNo = this.eMPFReferenceNo.value;
      const validateEmpfRefNoRequest = new ValidateEmpfRefNoRequest();
      validateEmpfRefNoRequest.empfRefNo = eMPFReferenceNo;
      validateEmpfRefNoRequest.clientType = 'member';
      this.cdHttpServeService
        .post<{ empfRefNoStatus: boolean }>(`/ext/eb-ssms-sales-journey-service/generateGeneralLink/validateEmpfRefNo/flow2`, validateEmpfRefNoRequest)
        .subscribe({
          next: resp => {
            if (resp && resp.result === 0 && resp.data) {
              // the eMPF Ref No. have been submitted
              if (!resp.data.empfRefNoStatus) {
                const dialogRefEMPFRefNoUsed: MatDialogRef<EMPFRefNoUsedComponent> = this.cdsPopup.open(EMPFRefNoUsedComponent, {
                  size: 'md',
                });
                dialogRefEMPFRefNoUsed.afterClosed().subscribe(data => {
                  if (data.agree) {
                    this.currentStage = 2;
                    this.eMPFReferenceNo.markAsTouched();
                    this.eMPFReferenceNo.setErrors({ usedEMPFRefNo: true });
                  }
                });
              } else {
                // go to split agent code stage
                this.currentStage = 4;
                this.onSecondAgentContactTypeChange(this.secondAgentNotificationChannel);
              }
            }
            this.isSplitAgentFine = false;
          },
          error: err => {
            this.alert.error('Error!', err);
            this.isSplitAgentFine = false;
          },
        });
    } else {
      this.splitAgentCode.markAsTouched();
      this.splitAgentCode.setErrors({ invalidSplitAgentCode: true });
    }
  };

  validateEmpfRefNoSubjectCallBack = (resp: ValidateEmpfRefNoResponse) => {
    if (resp && resp.result === 0 && resp.data) {
      // the eMPF Ref No. have been submitted
      if (!resp.data.empfRefNoStatus) {
        const dialogRefEMPFRefNoUsed: MatDialogRef<EMPFRefNoUsedComponent> = this.cdsPopup.open(EMPFRefNoUsedComponent, {
          size: 'md',
        });
        dialogRefEMPFRefNoUsed.afterClosed().subscribe(data => {
          if (data.agree) {
            this.currentStage = 2;
            this.eMPFReferenceNo.markAsTouched();
            this.eMPFReferenceNo.setErrors({ usedEMPFRefNo: true });
          }
        });
        this.isSplitAgentFine = false;
      } else {
        // when this.currentStage === 3 , it will continue to return true, so need this.currentStage === 2.
        if (this.currentStage === 2 && !this.splitAgentCode.value) {
          this.currentStage = 3;
        } else {
          // not-used eMPF Ref No, then call be API
          const agentInfoArr = [];
          const agentInfo = new AgentInfo();
          agentInfo.agentIdentityId = this.servicingAgentId;
          const isRegulatedAct = this.conductedRegulatorActivityFlag.value;
          agentInfo.regulatedAct = isRegulatedAct === null || isRegulatedAct === undefined ? false : isRegulatedAct;
          agentInfo.agentNotificationChannel = '';
          agentInfo.primaryAgent = true;
          agentInfoArr.push(agentInfo);
          const agentSubmissionRequest = new AgentSubmissionRequest();
          agentSubmissionRequest.empfRefNo = this.eMPFReferenceNo.value;
          // agentSubmissionRequest.productType = "Individual";
          agentSubmissionRequest.domainType = SalesJourneyProdType.Individual;
          // agentSubmissionRequest.firstNm =
          //   this.iffForm.get('firstName')?.value;
          agentSubmissionRequest.hkidOrPassportNo = this.hkidOrPassportNo.value;
          agentSubmissionRequest.idType = this.hKIDPassportNoFormControl.value;
          // agentSubmissionRequest.lastNm =
          //   this.iffForm.get('lastName')?.value;
          agentSubmissionRequest.channel = this.iffForm.get('contactType')?.value;
          agentSubmissionRequest.countryCallingCode = this.iffForm.get('countryCode')?.value;
          agentSubmissionRequest.mobileNo = this.iffForm.get('phoneNo')?.value;
          agentSubmissionRequest.emailAddr = this.iffForm.get('email')?.value;
          agentSubmissionRequest.caseSplit = false;
          if (this.isSplitAgentFine) {
            const agentInfo2 = new AgentInfo();
            agentInfo2.agentid = this.secondAgentInfo?.agentId;
            agentInfo2.regulatedAct = this.isSecondAgentConduct === null || this.isSecondAgentConduct === undefined ? false : this.isSecondAgentConduct;
            agentInfo2.agentNotificationChannel = this.secondAgentNotificationChannel;
            agentInfo2.primaryAgent = false;
            if (agentInfo2.regulatedAct && this.secondAgentNotificationChannel === 'M') {
              agentInfo2.smsLanguage = this.secondAgentPreferredLanguage === PreferredLanguageType.English ? 0 : 1;
            }
            agentInfoArr.push(agentInfo2);
            agentSubmissionRequest.caseSplit = true;
          }
          agentSubmissionRequest.agentInfos = agentInfoArr;
          if (this.data.domain === SalesJourneyProdType.SEP) {
            agentSubmissionRequest.domainType = SalesJourneyProdType.SEP;
            agentSubmissionRequest.productType = SalesJourneyProdType.SEP;
          }
          if (this.isChannelDBS) {
            agentSubmissionRequest.dbs = {
              dbsRmReferralCd: this.dbs.rmReferralCode.value,
              dbsCustomerId: this.dbs.inputCustomerId.value,
              dbsCampaignCd: this.dbs.campaignCode.value,
              dbsSourceCd: this.dbs.sourceCode.value,
            };
          }

          if (agentSubmissionRequest.channel === 'M') {
            agentSubmissionRequest.smsLanguage = this.preferredLanguage.value === PreferredLanguageType.English ? 0 : 1;
          }

          this.iffService.agentSubmission(agentSubmissionRequest);
        }
      }
    } else {
      this.alert.error('Error!', resp.message || 'generateGeneralLink/validateEmpfRefNo Error');
    }
  };

  agentSubmissionSubjectCallBack = (resp: AgentSubmissionResponse) => {
    if (resp && resp.result === 0) {
      // not split case, iff submission success
      let msg = '';
      if (this.isToAgentConfirmation) {
        msg = 'sendIffInBatchSummary.popupScreenTitleForSplitAgent';
      } else {
        msg = 'sendIffInBatchSummary.popupScreenTitleCustomer';
      }
      const iffSubmissionSuccDialogRef: MatDialogRef<IffSubmissionSuccPopupComponent> = this.cdsPopup.open(IffSubmissionSuccPopupComponent, {
        size: 'md',
        data: {
          message: this.cdsLangService.translate(msg),
        },
      });
      iffSubmissionSuccDialogRef.afterClosed().subscribe(data => {
        if (data.agree) {
          this.dialogRef.close({ refresh: true });
        }
      });
    }
    this.isSplitAgentFine = false;
  };

  ngOnInit(): void {
    this.lanChangeSubscription = this.languageChangeService.lanSubject.subscribe(lan => {
      this.language = lan === 'en' ? 'EN' : 'TC';
    });

    this.errorSub = this.iffService.errorIffSubject.subscribe(errorMessage => {
      const alertTitle = 'Got Error!';
      this.alert.error(alertTitle, errorMessage);
    });

    this.secondAgentInfoSubscription = this.iffService.agentInfoDataSubject.subscribe(this.agentInfoDataSubjectCallBack);

    this.authenticationService.currentUserValue().then(res => {
      this.servicingAgentId = res.id + '';

      this.iffService.getAgentCode(this.servicingAgentId).subscribe({
        next: res => {
          if (res.result !== 0) {
            this.alert.warning('Warning!', `${res.message}`);
          } else {
            this.servicingAgentCode = res.data.agentCode;
          }
        },
        error: err => {
          this.alert.error('Error!', err);
        },
      });
    });

    this.validateEmpfRefNoSubscription = this.iffService.validateEmpfRefNoSubject.subscribe(this.validateEmpfRefNoSubjectCallBack);

    this.agentSubmissionSubscription = this.iffService.agentSubmissionSubject.subscribe(this.agentSubmissionSubjectCallBack);

    this.iffForm = new FormGroup(
      {
        eMPFReferenceNo: new FormControl(null, [Validators.required]),
        conductedRegulatorActivityFlag: new FormControl(null, [Validators.required]),
        hkidOrPassportNo: new FormControl(null, [
          Validators.required,
          (control: AbstractControl): ValidationErrors | null => {
            if (this.hKIDPassportNoFormControl.value === '1') {
              return null;
            }
            let input: string = control.value || '';
            input = input.trim();
            const isValid: RegExpExecArray | null | boolean = /^[a-zA-Z]{1,2}\d{6}\([aA\d]\)$/.exec(input);
            return isValid ? null : { formatError: true };
          },
        ]),
        contactType: new FormControl(null, [Validators.required]),
        preferredLanguage: new FormControl(null, [
          (control: AbstractControl): ValidationErrors | null => {
            if (
              (this.iffForm?.get('contactType')?.value === 'M' && control.value) ||
              this.iffForm?.get('contactType')?.value === 'E' ||
              !this.iffForm?.get('contactType')?.value
            ) {
              return null;
            }
            return { formatError: true };
          },
        ]),
        splitAgentCode: new FormControl(null),
      },
      this.countryCodeAndPhoneNoUnionValidate.bind(this)
    );
  }

  get hkidOrPassportNo() {
    return this.iffForm.get('hkidOrPassportNo') as FormControl;
  }

  hKIDPassportNoChange() {
    this.hkidOrPassportNo.updateValueAndValidity();
  }

  toIFFForm() {
    if (!this.isScrollBottom) {
      return;
    }

    this.currentStage = 2;
  }

  hkidOrPassportNoInputBlur() {
    const value = this.hkidOrPassportNo.value?.replace(/[\u4e00-\u9fa5]/gi, '');
    if (value) {
      this.hkidOrPassportNo.setValue(value.trim());
    }

    if (this.hKIDPassportNoFormControl.value === '0') {
      if (/^[a-zA-Z]{1,2}\d{6}\([aA\d]\)$/.exec(value.trim())) {
        this.cdHttpServeService.post(`/ext/eb-ssms-sales-journey-service/hkidCheckDigit`, { hkId: value.trim() }).subscribe({
          next: res => {
            if (!(res.result === 0 && res.data)) {
              this.hkidOrPassportNo.setErrors({ formatError: true });
            }
          },
          error: err => {
            this.hkidOrPassportNo.setErrors({ formatError: true });
            this.alert.error('Error!', err);
          },
        });
      }
    }
  }

  get eMPFReferenceNo() {
    return this.iffForm.get('eMPFReferenceNo') as FormControl;
  }

  eMPFReferenceNoInputBlur() {
    const value: string = this.eMPFReferenceNo.value?.replace(/[\u4e00-\u9fa5]/gi, '');
    if (value) {
      this.eMPFReferenceNo.setValue(value.trim());
    }
  }

  get splitAgentCode() {
    return this.iffForm.get('splitAgentCode') as FormControl;
  }

  splitAgentCodeInputBlur() {
    const value: string = this.splitAgentCode.value?.replace(/[\u4e00-\u9fa5]/gi, '');
    if (value) {
      this.splitAgentCode.setValue(value.trim());
    }
  }

  getAgentName(agent?: AgentDetailInfo) {
    if (!agent) {
      return '';
    }
    if (this.cdsLangService.currentLang === 'en') {
      return agent.agentName || agent.agentNameTC;
    } else {
      return agent.agentNameTC || agent.agentName;
    }
  }

  countrycodeConfig: CdsDropdownConfig = {
    options: empfCountryOption,
    placeholder: '+852 (HK)',
    color: 'ml',
  };
  iconConfig: CdsIconConfig = {
    size: 'sm',
    color: 'default',
  };
  infoIcon = ActionIcons.info_1;

  get contactType() {
    return this.iffForm.get('contactType') as FormControl;
  }

  get email() {
    return this.iffForm.get('email') as FormControl;
  }

  get phoneNo() {
    return this.iffForm.get('phoneNo') as FormControl;
  }

  get preferredLanguage() {
    return this.iffForm.get('preferredLanguage') as FormControl;
  }

  get countryCode() {
    return this.iffForm.get('countryCode') as FormControl;
  }

  resetIffForm() {
    this.iffForm.reset();
    this.eMPFReferenceNo.setValue(null);
    this.hkidOrPassportNo.reset();
    this.contactType.reset();
    this.email?.reset();
    this.phoneNo?.reset();
    this.preferredLanguage.reset();
    this.dbs.rmReferralCode.reset('');
    this.dbs.sourceCode.reset('');
    this.dbs.campaignCode.reset('');
    this.dbs.inputCustomerId.reset('');
    this.hKIDPassportNoFormControl.reset();
    this.preferredLanguageConfig = {
      options: [
        {
          label: 'common.chinese',
          value: PreferredLanguageType.Chinese,
          disable: true,
        },
        {
          label: 'common.english',
          value: PreferredLanguageType.English,
          disable: true,
        },
      ],
    };
    this.cdRadioPreferredLanguage.refreshConfig();
  }

  onClickInfoIcon() {
    this.currentStage = 0;
  }

  closeCurrentPopup() {
    if (this.currentStage === 1) {
      this.dialogRef.close();
    } else {
      const exitIffWarnDialogRef: MatDialogRef<ExitIffWarnPopupComponent> = this.cdsPopup.open(ExitIffWarnPopupComponent, {
        size: 'lg',
      });
      exitIffWarnDialogRef.afterClosed().subscribe(data => {
        if (data.agree) {
          this.dialogRef.close();
        }
      });
    }
  }

  dbsInputHasErrors() {
    if (!this.isChannelDBS) {
      return false;
    }
    return (
      (this.dbs.rmReferralCode && this.dbs.rmReferralCode.dirty && !!this.dbs.rmReferralCode.errors) ||
      (this.dbs.campaignCode && this.dbs.campaignCode.dirty && !!this.dbs.campaignCode.errors) ||
      (this.dbs.sourceCode && this.dbs.sourceCode.dirty && !!this.dbs.sourceCode.errors) ||
      (this.dbs.inputCustomerId && this.dbs.inputCustomerId.dirty && !!this.dbs.inputCustomerId.errors)
    );
  }

  onIffPartSubmit() {
    const hkid = this.hkidOrPassportNo.value;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const idtype = this.hKIDPassportNoFormControl.value;

    if (idtype == 0) {
      this.cdHttpServeService.post(`/ext/eb-ssms-sales-journey-service/hkidCheckDigit`, { hkId: hkid }).subscribe({
        next: res => {
          if (res.result === 0) {
            if (res.data == false) {
              this.hkidOrPassportNo.setErrors({ formatError: true });
              return;
            }
            this.fromValue();
          }
        },
        error: err => {
          this.alert.error('Error!', err);
        },
      });
    } else {
      this.fromValue();
    }

    //console.info(this.iffForm.value);
  }

  get conductedRegulatorActivityFlag() {
    return this.iffForm.get('conductedRegulatorActivityFlag') as FormControl;
  }

  fromValue() {
    this.secondAgentNotificationChannel = '';
    this.eMPFReferenceNo.markAsTouched();
    this.hkidOrPassportNo.markAsTouched();
    this.contactType.markAsTouched();
    this.preferredLanguage.markAsTouched();
    this.conductedRegulatorActivityFlag.markAsTouched();
    const eMPFReferenceNo = this.eMPFReferenceNo.value;
    const contactType = this.contactType.value;
    if (contactType === 'E') {
      this.email?.markAsTouched();
    } else if (contactType === 'M') {
      this.phoneNo?.markAsTouched();
    } else {
      this.contactType.markAsTouched();
    }
    const validateEmpfRefNoRequest = new ValidateEmpfRefNoRequest();
    validateEmpfRefNoRequest.empfRefNo = eMPFReferenceNo;
    if (this.isChannelDBS) {
      if (this.dbs.rmReferralCode.errors) {
        this.dbs.rmReferralCode.markAsDirty();
        return;
      }
    }
    if (this.dbsInputHasErrors()) {
      return;
    }
    if (this.iffForm.status === 'VALID') {
      const splitAgentCode = this.splitAgentCode.value;
      if (splitAgentCode && splitAgentCode.trim() !== '') {
        if (this.servicingAgentCode === splitAgentCode.trim()) {
          this.splitAgentCode.setErrors({ invalidSplitAgentCode: true });
          return;
        }
        this.cdHttpServeService
          .post<{
            agentCode: string;
            name: string;
            chineseName: string;
            errorCode?: number;
          }>('/ext/eb-ssms/customer-service/add-delegation/validate', {
            agentCode: agentCodeRemovePrefix0(splitAgentCode.trim()),
          })
          .subscribe({
            next: res => {
              if (res.result === 0) {
                this.iffService.getAgentInfo(splitAgentCode.trim());
              } else {
                this.splitAgentCode.markAsTouched();
                this.splitAgentCode.setErrors({ invalidSplitAgentCode: true });
              }
            },
            error: err => {
              this.alert.error('Error!', err);
            },
          });
      } else {
        this.validateInputtedEMPFReferenceNo();
      }
    }
  }

  //async validator for hk phone no
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  hkPhoneNoValidateAsync(formControl: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return new Promise<any>(resolve => {
      const hkPhoneNo: number = formControl.value;
      if (!this.isValidHKPhoneNo(hkPhoneNo)) {
        resolve({ invalidPhoneNo: true });
      } else {
        resolve(null);
      }
    });
  }

  hkPhoneNoValidate(formControl: AbstractControl): ValidationErrors | null | Observable<ValidationErrors | null> {
    const hkPhoneNo: number = formControl.value;
    if (!this.isValidHKPhoneNo(hkPhoneNo)) {
      return { invalidPhoneNo: true };
    } else {
      return null;
    }
  }

  isValidHKPhoneNo(num: number) {
    if (/^[4-9]\d{7}$/.test(num + '')) {
      return true;
    }
    return false;
  }

  isValidChiPhoneNo(num: number) {
    if (/^[1]\d{10}$/.test(num + '')) {
      return true;
    }
    return false;
  }

  countryCodeAndPhoneNoUnionValidate(formGroup: AbstractControl): ValidationErrors | null | Observable<ValidationErrors | null> {
    const contactType = formGroup.get('contactType')?.value;
    if (contactType === 'M') {
      const countryCode = formGroup.get('countryCode')?.value;
      const phoneNo = formGroup.get('phoneNo')?.value;
      // phoneNo type is number, need to change to string type here
      if (phoneNo === null || phoneNo === undefined) {
        return { required: true };
      } else if ((countryCode === '86' && !this.isValidChiPhoneNo(phoneNo)) || (countryCode === '852' && !this.isValidHKPhoneNo(phoneNo))) {
        return { invalidPhoneNo: true };
      }
    }
    return null;
  }
  emailFormControl?: FormControl;

  onContactTypeChange(val: string) {
    this.preferredLanguage.setValue(undefined);
    if (val === 'E') {
      this.iffForm.removeControl('countryCode');
      this.iffForm.removeControl('phoneNo');
      this.emailFormControl = new FormControl(null, [Validators.required, Validators.email]);
      this.iffForm.addControl('email', this.emailFormControl);
      this.preferredLanguageConfig = {
        options: [
          {
            label: 'common.chinese',
            value: PreferredLanguageType.Chinese,
            disable: true,
          },
          {
            label: 'common.english',
            value: PreferredLanguageType.English,
            disable: true,
          },
        ],
      };
    } else if (val === 'M') {
      this.iffForm.removeControl('email');
      const countryCodeFormControl = new FormControl(null, [Validators.required]);
      const phoneNoFormControl = new FormControl(null, [Validators.required]);
      this.iffForm.addControl('countryCode', countryCodeFormControl);
      this.iffForm.addControl('phoneNo', phoneNoFormControl);
      this.iffForm.patchValue({ countryCode: '852' });
      this.preferredLanguageConfig = {
        options: [
          {
            label: 'common.chinese',
            value: PreferredLanguageType.Chinese,
            disable: false,
          },
          {
            label: 'common.english',
            value: PreferredLanguageType.English,
            disable: false,
          },
        ],
      };
    }
    this.cdRadioPreferredLanguage.refreshConfig();
  }

  // onCountryCodeChange(val:string){
  //   if(val ==="HK"){
  //     this.iffForm.get('phoneNo')?.addValidators(Validators.maxLength(6));
  //     this.iffForm.get('phoneNo')?.updateValueAndValidity();
  //   }
  // }

  attentionIcon = ActionIcons.megaphone;
  backToIffForm() {
    this.currentStage = 2;
  }

  // private customEmailValidator(formControl : AbstractControl): ValidationErrors|null{
  //   if(!formControl.value){
  //     //return {valid:null};
  //     console.info('in custom null');
  //     return null;
  //   }
  //   return Validators.email(formControl);
  // }

  // private customEmailValidator(control: AbstractControl): {[key: string]: any}|null {
  //   const emailError = Validators.email(control);
  //   // const error1 =  (control?.errors)!['required'];
  //   // const error2 =  (control?.errors)!['email'];
  //   // console.info(error1);
  //   // console.info(error2);
  //   if (control.value && emailError) {
  //     return {'email': {}};
  //   } else if (!control.value){
  //     return {'required': {}};
  //   } else {
  //     return null;
  //   }
  // }
  iffSubmission() {
    const contactType = this.contactType.value;
    if (contactType === 'E') {
      this.email?.markAsTouched();
    } else if (contactType === 'M') {
      this.phoneNo?.markAsTouched();
    } else {
      this.contactType.markAsTouched();
    }
    this.validateInputtedEMPFReferenceNo();
  }

  get disableIffSplitAgentContinue() {
    return (
      Boolean(this.isSecondAgentConduct && this.secondAgentNotificationChannel === 'M' && !this.secondAgentPreferredLanguage) ||
      (this.conductedRegulatorActivityFlag.value && this.isSecondAgentConduct === undefined)
    );
  }

  iffSplitAgentContinue() {
    if (this.disableIffSplitAgentContinue) {
      return;
    }
    this.currentStage = 5;
  }

  secondAgentContactTypeSelected = '';
  secondAgentNotificationChannel = '';
  onSecondAgentContactTypeChange(val: string) {
    this.secondAgentPreferredLanguage = undefined;
    if (val === 'E') {
      this.secondAgentContactTypeSelected = this.secondAgentContactEmail;
      this.secondAgentPreferredLanguageConfig = {
        options: [
          {
            label: 'common.chinese',
            value: PreferredLanguageType.Chinese,
            disable: true,
          },
          {
            label: 'common.english',
            value: PreferredLanguageType.English,
            disable: true,
          },
        ],
      };
    } else if (val === 'M') {
      this.secondAgentContactTypeSelected = this.secondAgentContactPhone;
      this.secondAgentPreferredLanguageConfig = {
        options: [
          {
            label: 'common.chinese',
            value: PreferredLanguageType.Chinese,
            disable: false,
          },
          {
            label: 'common.english',
            value: PreferredLanguageType.English,
            disable: false,
          },
        ],
      };
    }
    this.cdRadioSecondAgentPreferredLanguage?.refreshConfig();
  }

  backToSplitAgentPage() {
    this.currentStage = 4;
  }

  validateInputtedEMPFReferenceNo() {
    const eMPFReferenceNo = this.eMPFReferenceNo.value;
    const validateEmpfRefNoRequest = new ValidateEmpfRefNoRequest();
    validateEmpfRefNoRequest.empfRefNo = eMPFReferenceNo;
    validateEmpfRefNoRequest.clientType = 'member';
    this.iffService.validateEmpfRefNo(validateEmpfRefNoRequest);
  }

  isSplitAgentFine = false; // define if the split agent flow is successful
  isToAgentConfirmation = false;
  iffSplitAgentSubmission() {
    this.isSplitAgentFine = true;
    this.isToAgentConfirmation = false;
    // not-used eMPF Ref No, then call be API
    const agentInfoArr = [];
    const agentInfo = new AgentInfo();
    agentInfo.agentIdentityId = this.servicingAgentId;
    const isRegulatedAct = this.conductedRegulatorActivityFlag.value;
    agentInfo.regulatedAct = isRegulatedAct === null || isRegulatedAct === undefined ? false : isRegulatedAct;
    agentInfo.agentNotificationChannel = '';
    agentInfo.primaryAgent = true;
    agentInfoArr.push(agentInfo);
    const agentSubmissionRequest = new AgentSubmissionRequest();
    agentSubmissionRequest.empfRefNo = this.eMPFReferenceNo.value;
    // agentSubmissionRequest.productType = "Individual";
    agentSubmissionRequest.domainType = SalesJourneyProdType.Individual;
    // agentSubmissionRequest.firstNm = this.iffForm.get('firstName')?.value;
    agentSubmissionRequest.hkidOrPassportNo = this.hkidOrPassportNo.value;
    agentSubmissionRequest.idType = this.hKIDPassportNoFormControl.value;
    // agentSubmissionRequest.lastNm = this.iffForm.get('lastName')?.value;
    agentSubmissionRequest.channel = this.contactType.value;
    agentSubmissionRequest.countryCallingCode = this.countryCode?.value;
    agentSubmissionRequest.mobileNo = this.phoneNo?.value;
    agentSubmissionRequest.emailAddr = this.email?.value;
    agentSubmissionRequest.caseSplit = false;
    if (this.isSplitAgentFine) {
      const agentInfo2 = new AgentInfo();
      agentInfo2.agentid = this.secondAgentInfo?.agentId;
      agentInfo2.regulatedAct = this.isSecondAgentConduct === null || this.isSecondAgentConduct === undefined ? false : this.isSecondAgentConduct;
      this.isToAgentConfirmation = agentInfo2.regulatedAct;
      agentInfo2.agentNotificationChannel = this.secondAgentNotificationChannel;
      agentInfo2.primaryAgent = false;
      if (agentInfo2.regulatedAct && this.secondAgentNotificationChannel === 'M') {
        agentInfo2.smsLanguage = this.secondAgentPreferredLanguage === PreferredLanguageType.English ? 0 : 1;
      }
      agentInfoArr.push(agentInfo2);
      agentSubmissionRequest.caseSplit = true;
    }
    agentSubmissionRequest.agentInfos = agentInfoArr;

    if (this.data.domain === SalesJourneyProdType.SEP) {
      agentSubmissionRequest.domainType = SalesJourneyProdType.SEP;
      agentSubmissionRequest.productType = SalesJourneyProdType.SEP;
    }
    if (this.isChannelDBS) {
      agentSubmissionRequest.dbs = {
        dbsRmReferralCd: this.dbs.rmReferralCode.value,
        dbsCustomerId: this.dbs.inputCustomerId.value,
        dbsCampaignCd: this.dbs.campaignCode.value,
        dbsSourceCd: this.dbs.sourceCode.value,
      };
    }

    if (agentSubmissionRequest.channel === 'M') {
      agentSubmissionRequest.smsLanguage = this.preferredLanguage.value === PreferredLanguageType.English ? 0 : 1;
    }

    this.iffService.agentSubmission(agentSubmissionRequest);
  }

  gotItButton: CdsButtonConfig = {
    size: 'sm',
    style: 'primary',
    disabled: false,
  };
  backToForm() {
    this.currentStage = 2;
  }

  get isChannelDBS() {
    return this.agentChannelService.channel === AgentChannel.DBS;
  }

  get isDisabled() {
    if (this.agentChannelService.channel === AgentChannel.DBS) {
      return true;
    }
    if (this.agentChannelService.channel === AgentChannel.CORPORATE && this.agentChannelService.agentCode?.startsWith('400')) {
      return true;
    }
    return false;
  }
}
