/* eslint-disable @typescript-eslint/no-explicit-any */
import { AbstractControl, ValidationErrors } from '@angular/forms';
import { I18N_KEY } from './scheme-master.config';

export function validatorSchemeName(control: AbstractControl) {
  const value = !control.value ? '' : control.value.trim();
  if (!value) {
    return { errorMsg: I18N_KEY.SCHEME_NAME_REQUIRED };
  }
  return null;
}

export function validatorSchemeCode(control: AbstractControl): ValidationErrors | null {
  const value = !control.value ? '' : control.value.trim();
  if (!value) {
    return { errorMsg: I18N_KEY.REGISTATION_NO_REQUIRED };
  }
  return null;
}

export function validatorWarnSlIfFao(control: AbstractControl): ValidationErrors | null {
  if (control.value > 10 || control.value == 0) {
    return { errorMsg: I18N_KEY.SCHEME_SALARY_WRN_CY };
  }
  return null;
}

export function validatorWarnSlIfFaoNy(control: AbstractControl): ValidationErrors | null {
  if (control.value > 10 || control.value == 0) {
    return { errorMsg: I18N_KEY.SCHEME_SALARY_WRN_NY };
  }
  return null;
}
