/* eslint-disable @typescript-eslint/no-explicit-any */
import { ColumnConfig } from 'src/app/shared/data-table';

export const PAGE_SIZE = 10;
export const FILE_NAME = 'Audit History Report';

export const I18N_KEY = {
  DATE_INVALID: 'setting.auditHistory.date.invalid',
  DATE_RANGE_INVALID: 'setting.auditHistory.dateRange.invalid',
  DATE_RANGE_INCOMPLETE: 'commission.journal.dateRangeComplete',
  KEY_INVALID: 'setting.auditHistory.key.invalid',
  COMMON_GOT: 'common.gotItWithExclamation',
};

export const ServiceOptions: any[] = [{ label: 'Commission Service', value: 'commission-service' }];

export const AUDIT_HISTORY_COLUMNS: Array<ColumnConfig> = [
  { key: 'id', sticky: true, title: 'setting.auditHistory.id' },
  { key: 'timeStamp', title: 'setting.auditHistory.updateDate' },
  { key: 'key', title: 'setting.auditHistory.key' },
  { key: 'service', title: 'setting.auditHistory.service' },
  { key: 'payLoad', title: 'setting.auditHistory.payload' },
  { key: 'createBy', title: 'setting.auditHistory.createdBy' },
];
export const EXCEL_COLUMNS: Array<string> = ['ID', 'Update Date', 'Key', 'Service', 'Payload', 'Created By'];
