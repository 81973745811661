export interface AgentAndAssignedGroup {
  agent?: string;
  companyName?: string;
  id?: string;
  trusteeEmployerAccountNumber?: string;
  isDefault?: boolean;
  agentList?: string[];
  remarks?: string;
  agentName?: string;
}

export enum AddressTypeE {
  CR = 'Correspondence Address',
  BU = 'Business Address',
  RO = 'Registered Office Address',
  RE = 'Residential Address',
  OS = 'Overseas Settlement Address',
}
