import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { CdsIconConfig } from '@cds/ng-core/icon';
import { ActionIcons } from '@cds/ng-themes';
import { IffStatusFlowPopupComponent } from '../iff-status-flow-popup/iff-status-flow-popup.component';
import { CdsPopupService } from '@cds/ng-web-components/popup';
import { IffStatusService } from 'src/app/core/services/iff-status/iff-status.service';
import { EmpfAppStatusService } from 'src/app/core/services/empf-app-status/empf-app-status.service';
import { EmpfAppStatusPopupComponent } from '../empf-app-status-popup/empf-app-status-popup.component';
import { pageToTop } from 'src/app/utils/utils';
import { AddMpfReferenceNumberPopupComponent } from '../add-mpf-reference-number-popup/add-mpf-reference-number-popup.component';
import { NewResendEiffComponent } from '../new-resend-eiff/new-resend-eiff.component';
import { AddMdReferenceNumberPopupComponent } from '../add-md-reference-number-popup/add-md-reference-number-popup.component';

@Component({
  selector: 'app-empf-case-details',
  templateUrl: './empf-case-details.component.html',
  styleUrls: ['./empf-case-details.component.scss'],
})
export class EmpfCaseDetailsComponent implements OnInit {
  backErIcon = ActionIcons.button_left_filled;
  backErIconConfig: CdsIconConfig = {
    color: 'cta',
    size: 'sm',
  };
  agentMDFormCompletedCheck = new FormControl(true);
  clientMDFormCompletedCheck = new FormControl(true);
  eMPFCompletedCheck = new FormControl(false);
  agentIFFCompletedCheck = new FormControl(false);
  clientIFFCompletedCheck = new FormControl(false);
  iconConfig: CdsIconConfig = {
    size: 'sm',
    color: 'default',
  };
  infoIcon = ActionIcons.info_1;
  MDIFFStatus = 'Pending Split Agent(s)';
  empfStatus = 'Pending eMPF return data';

  constructor(
    private router: Router,
    private cdsPopup: CdsPopupService,
    public iffStatusService: IffStatusService,
    public empfAppStatusService: EmpfAppStatusService
  ) {}

  ngOnInit(): void {}

  onBack() {
    this.router.navigate(['/salesjourney/md-iff-summary']);
  }

  toTop() {
    pageToTop();
  }

  onDisplayAdviseIFF() {
    this.cdsPopup.open(IffStatusFlowPopupComponent, {
      size: 'sm',
    });
  }

  onDisplayAdviseEmpf() {
    this.cdsPopup.open(EmpfAppStatusPopupComponent, {
      size: 'sm',
    });
  }

  onOpenAddEmpfRefNoPopup() {
    this.cdsPopup.open(AddMpfReferenceNumberPopupComponent, {
      size: 'sm',
    });
  }

  onOpenAddMDRefNoPopup() {
    this.cdsPopup.open(AddMdReferenceNumberPopupComponent, {
      size: 'sm',
    });
  }

  onResendEiff() {
    this.cdsPopup.open(NewResendEiffComponent, {
      size: 'sm',
    });
  }

  onMDIFFStatusShowToggle(event: MouseEvent) {
    if (event.target) {
      event.target.dispatchEvent(new Event('click'));
    }
  }
}
