/* eslint-disable @typescript-eslint/no-this-alias */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { PermissionService } from 'src/app/core/services/permission.service';
import { TAB } from './adjustment.component.config';

@Component({
  selector: 'app-commission-adjustment',
  templateUrl: './adjustment.component.html',
  styleUrls: ['./adjustment.component.scss'],
})
export class AdjustmentComponent implements OnInit {
  isshowBack = false;
  commissionPoolId = '';
  referenceNo = '';

  tab: any[] = [];
  tabSelected: any;

  constructor(private router: Router, private route: ActivatedRoute, private permissionService: PermissionService) {}

  ngOnInit(): void {
    const promiseAll: Promise<any>[] = [];
    TAB.forEach(item => {
      promiseAll.push(this.permissionService.hasPermission(item.permissionAccess, item.permissionItem));
    });
    Promise.all(promiseAll).then(arr => {
      const tab: any[] = [];
      arr.forEach((item: any, index: number) => {
        if (item) tab.push(TAB[index]);
      });
      this.tab = tab;
      this.commissionPoolId = this.route.snapshot.paramMap.get('commissionPoolId') || '';
      const tabSelected = this.route.snapshot.paramMap.get('tabSelected');
      this.referenceNo = this.route.snapshot.paramMap.get('referenceNo') || '';
      this.tabSelected = this.tab.find(item => item.value === tabSelected) || this.tab[0];
      if (this.commissionPoolId || this.referenceNo) {
        this.isshowBack = true;
      }
    });
  }

  doBackMethod() {
    this.router.navigate(['/commission/pool-info/'], {
      queryParams: {
        commissionPoolId: this.commissionPoolId,
        tabType: this.tabSelected?.backTabType,
      },
    });
  }

  tabClick(tab: any) {
    this.tabSelected = tab;
  }
}
