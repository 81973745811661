import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SupervisorComponent } from './supervisor/supervisor.component';
import { AdminLayoutComponent } from '../layout/admin-layout/admin-layout.component';
import { PermissionAccess, PermissionItem } from 'src/app/core/models/enum';

const routes: Routes = [
  {
    path: 'chatbot-supervisor',
    children: [
      {
        path: 'dashboard',
        component: SupervisorComponent,
        data: {
          roles: {
            [PermissionItem.CHATBOT_SUPERVISOR]: PermissionAccess.R,
          },
        },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SupervisorRoutingModule {}
