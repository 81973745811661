import { Injectable } from '@angular/core';
import { CanLoad, Router, UrlTree } from '@angular/router';

import { CdsPopupService } from '@cds/ng-web-components/popup';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { CdWarningPopupComponent } from 'src/app/shared/cd-warning-popup/cd-warning-popup.component';

@Injectable()
export class ScreenCheckService implements CanLoad {
  constructor(private cdsPopup: CdsPopupService, private router: Router, private authenticationService: AuthenticationService) {}

  canLoad(): boolean | Promise<UrlTree | boolean> {
    return this.screenCheck();
  }

  private screenCheck(): boolean | Promise<UrlTree | boolean> {
    const isMobile = window.matchMedia('only screen and (max-width: 760px)').matches || window.screen.width < 500;
    if (isMobile) {
      return new Promise<UrlTree | boolean>(resolve => {
        this.cdsPopup.open(CdWarningPopupComponent, {
          size: 'md',
          data: {
            title: 'screen.largeScreenIsRecommended',
            isShowCloseIcon: true,
            // buttons: [
            //   {
            //     style: 'secondary',
            //     size: 'sm',
            //     text: 'logout.button',
            //     cb: () => {
            //       screenCheckRef.close();
            //       this.logout();
            //       resolve(this.router.createUrlTree(['/user/login']));
            //     },
            //   },
            // ],
            closeCallBack: () => {
              resolve(true);
            },
          },
        });
      });
    }
    return true;
  }
}
