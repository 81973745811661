import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'gender',
})
export class GenderPipe implements PipeTransform {
  transform(_value: string): string {
    let result = '';
    if (_value === 'M') {
      result = '男';
    } else if (_value === 'F') {
      result = '女';
    }
    return result;
  }
}
