import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminLayoutComponent } from '../layout/admin-layout/admin-layout.component';
import { EeProfileDetailComponent } from './ee-profile-detail/ee-profile-detail.component';
import { EeProfileDetailPlusComponent } from './ee-profile-detail-plus/ee-profile-detail-plus.component';
import { PermissionAccess } from 'src/app/core/models/enum/permission.enum';
import { EmployeeListComponent } from './employee-list/employee-list.component';
import { RelatedPolicyDetailComponent } from './related-policy-detail/related-policy-detail.component';
import { PolicyTransferDetailsComponent } from './policy-transfer-details/policy-transfer-details.component';
import { PolicyWithdrawalDetailsComponent } from './policy-withdrawal-details/policy-withdrawal-details.component';

const routes: Routes = [
  {
    path: 'employee',
    component: AdminLayoutComponent,
    children: [
      { path: '', pathMatch: 'full', redirectTo: 'list' },
      {
        path: 'profile',
        component: EeProfileDetailComponent,
        data: {
          title: 'ee.profile.detail.title',
          roles: {
            CUSTOMER_EMPLOYEE: PermissionAccess.R,
          },
        },
      },
      {
        path: 'list',
        component: EmployeeListComponent,
        data: {
          title: 'ee.profile.title',
          roles: {
            CUSTOMER_EMPLOYEE: PermissionAccess.R,
            CUSTOMER_EMPLOYEE_INTERMEDIARY: PermissionAccess.R,
          },
        },
      },
      {
        path: 'related-policy-detail',
        component: RelatedPolicyDetailComponent,
        data: {
          title: 'ee.profile.title',
          roles: {
            CUSTOMER_EMPLOYEE: PermissionAccess.R,
            CUSTOMER_EMPLOYEE_INTERMEDIARY: PermissionAccess.R,
          },
        },
      },
      {
        path: 'policy-transfer-detail',
        component: PolicyTransferDetailsComponent,
        data: {
          title: 'ee.transfer-record-details',
          roles: {
            CUSTOMER_EMPLOYEE: PermissionAccess.R,
          },
        },
      },
      {
        path: 'policy-withdrawal-detail',
        component: PolicyWithdrawalDetailsComponent,
        data: {
          title: 'ee.withdrawal-record-details',
          roles: {
            CUSTOMER_EMPLOYEE: PermissionAccess.R,
          },
        },
      },
      {
        path: 'profileplus',
        component: EeProfileDetailPlusComponent,
        data: {
          title: 'ee.profile.detail.plus.title',
          roles: {
            CUSTOMER_EMPLOYEE: PermissionAccess.R,
            CUSTOMER_EMPLOYEE_INTERMEDIARY: PermissionAccess.R,
          },
        },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class EmployeeRoutingModule {}
