import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CdsLanguageModule } from '@cds/ng-core/lang';
import { CdsIconModule } from '@cds/ng-web-components/icon';

import { SwUpdateAskComponent } from './sw-update-ask.component';

@NgModule({
  imports: [CommonModule, CdsIconModule, CdsLanguageModule],
  declarations: [SwUpdateAskComponent],
  exports: [SwUpdateAskComponent],
})
export class SwUpdateAskModule {}
