import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CdMultipleAccordionComponent } from './cd-multiple-accordion.component';
import { CdMultipleAccordionPanelComponent } from './cd-multiple-accordion-panel/cd-multiple-accordion-panel.component';
import { CdsIconModule } from '@cds/ng-web-components/icon';

@NgModule({
  declarations: [CdMultipleAccordionComponent, CdMultipleAccordionPanelComponent],
  exports: [CdMultipleAccordionComponent, CdMultipleAccordionPanelComponent],
  imports: [CommonModule, CdsIconModule],
})
export class CdMultipleAccordionModule {}
