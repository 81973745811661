import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class WsmCaseSummaryService {
  constructor() {}

  getWSMStatusData(wsmStatus: number) {
    const result = {
      color: '',
      text: '',
    };

    switch (wsmStatus) {
      case 10:
        result.color = '#00A758';
        result.text = 'common.active';
        break;

      case 90:
        result.color = '#8E90A2';
        result.text = 'common.expired';
        break;

      case 91:
        result.color = '#D03A39';
        result.text = 'common.cancelled';
        break;

      default:
        break;
    }

    return result;
  }
}
