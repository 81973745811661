<div class="l-mb-6"></div>

<app-employee-profile-title [detailData]="detailData" [showBtn]="true"></app-employee-profile-title>

<div class="row">
  <div class="col-xs-12">
    <div class="white-area row l-mb-7">
      <div class="info-area">
        <!-- <div class="flex l-jc-sb">
            <div>
              <div>
                <div class="cds-h4-bold item-title no-margin bold">{{ detailData.lastName }}{{ detailData.firstName }}</div>
                <div class="item-value cds-body1-demibold-margin bold">{{ detailData.lastZhName }}{{ detailData.firstZhName }}</div>
              </div>
              <div class="flex l-ai-cen l-mt-1">
                <app-icon-status [value]="detailData.status" [statusMapObj]="employeeStatusMapObj"></app-icon-status>
              </div>
              <div *ngIf="detailData?.optionLastUpdateDate" class="row middle-xs l-plr-0 l-pt-1 cds-body2">
                <span class="l-mr-1">Latest update from eMPF:</span>
                <span class="l-mr-1"> {{ updateDate | fn : detailData.optionLastUpdateDate }}</span>
                <span>(HKT)</span>
              </div>
            </div>
            <div>
              <cds-button [config]="linkBtnConfig">Link to e-CWS</cds-button>
            </div>
          </div> -->

        <div class="row l-plr-0">
          <app-row-item label="ee.member-empf-id" [data]="detailData.empfId" class="col-xs-6 col-sm-3 col-md-3 col-lg-3 l-plr-0"> </app-row-item>
          <app-row-item label="sendIffInBatchSummary.dob" [data]="detailData.dob | ssmsDate" class="col-xs-6 col-sm-3 col-md-3 col-lg-3 l-plr-0">
          </app-row-item>
          <app-row-item label="employee.ageRank" [data]="detailData.age" class="col-xs-6 col-sm-3 col-md-3 col-lg-3 l-plr-0"> </app-row-item>
          <app-row-item label="employee.gender" [data]="this.getGender(detailData.gender)" class="col-xs-6 col-sm-3 col-md-3 col-lg-3 l-plr-0"> </app-row-item>
          <app-row-item label="ee.pfofile.legal-id-type" [data]="detailData.legalIdType" class="col-xs-6 col-sm-3 col-md-3 col-lg-3 l-plr-0"> </app-row-item>
          <app-row-item label="ee.pfofile.legal-id" [data]="detailData.legalId" class="col-xs-6 col-sm-3 col-md-3 col-lg-3 l-plr-0"> </app-row-item>
          <app-row-item label="ee.loyalty-date" [data]="detailData.loyaltyDate | ssmsDate" class="col-xs-6 col-sm-3 col-md-3 col-lg-3 l-plr-0"> </app-row-item>
          <app-row-item label="ee.nationality" [data]="nationRender | fn : detailData.nationality" class="col-xs-6 col-sm-3 col-md-3 col-lg-3 l-plr-0">
          </app-row-item>
        </div>

        <div class="col-xs-12">
          <div class="p-line"></div>
        </div>

        <div class="cds-body1-demibold">Opt in Options ( Member Level)</div>

        <div class="cds-body2 label-color">Option & Last Update Date (DD/MM/YYYY)</div>

        <div class="content-item col-sm-3 no-padding">
          <cds-checkbox [checked]="detailData.memberAuthorization === 'Y' ? true : false" [disabled]="true" label="Member Authorization"> </cds-checkbox>
          <div class="cds-body1 no-margin margin-left-box">
            {{ detailData.optionLastUpdateDate | ssmsDate }}
          </div>
        </div>

        <div class="col-xs-12 l-mb-5 l-plr-0">
          <div class="p-line"></div>
        </div>

        <div class="cds-body1-demibold l-mb-4 font-weight-6">Bankruptcy Details</div>

        <div class="col-xs-12 l-plr-0">
          <div class="p-line"></div>
        </div>

        <div class="row l-plr-0 l-plr-0">
          <app-row-item
            label="ee.bankruptcy-receive-date"
            [data]="detailData.bankruptcyReceiveDate | ssmsDate"
            class="col-xs-6 col-sm-3 col-md-3 col-lg-3 l-plr-0">
          </app-row-item>
          <app-row-item label="ee.bankruptcy-date" [data]="detailData.bankruptcyDate | ssmsDate" class="col-xs-6 col-sm-3 col-md-3 col-lg-3 l-plr-0">
          </app-row-item>
          <app-row-item label="ee.discharge-date" [data]="detailData.dischargeDate | ssmsDate" class="col-xs-6 col-sm-3 col-md-3 col-lg-3 l-plr-0">
          </app-row-item>
          <app-row-item label="ee.bankruptcy-number" [data]="detailData.bankruptcyNumber" class="col-xs-6 col-sm-3 col-md-3 col-lg-3 l-plr-0"> </app-row-item>
          <app-row-item label="ee.oro-contact" [data]="detailData.oroContact" class="col-xs-6 col-sm-3 col-md-3 col-lg-3 l-plr-0"> </app-row-item>
        </div>

        <!-- end All row -->
      </div>
    </div>
  </div>
</div>

<div class="row l-mb-8">
  <div class="col-xs-12">
    <div class="l-mb-6">
      <app-campaign-information [md5]="md5"></app-campaign-information>
    </div>
  </div>
</div>

<div class="l-mb-8">
  <app-related-policy [id]="id" [md5]="md5"></app-related-policy>
</div>

<app-back-top></app-back-top>
