import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filterParams } from 'src/app/core/models/request';
import { PageResponse } from 'src/app/core/models/response/response';
import { environment } from 'src/environments/environment';
import { ColumnSort } from '../employer/employer';
import { TerminationRecord, TerminationSearchRequest } from '../termination/termination';

@Injectable({
  providedIn: 'root',
})
export class AgentPerformanceService {
  private customer_url = `${environment.apiPrefix}/ext/eb-ssms/customer-service/`;
  private terminatedMemberListUrl = this.customer_url + 'shkp/terminated-members/allList';
  private exportUrl = this.customer_url + 'shkp/terminated-members/getCsv';

  constructor(private http: HttpClient) {}

  getTerminatedMemberList(params: TerminationSearchRequest, columnSort?: ColumnSort): Observable<PageResponse<TerminationRecord>> {
    const requestParams: TerminationSearchRequest = {
      ...params,
      sort: columnSort ? `${columnSort.key},${columnSort.value}` : '',
    };
    return this.http.get<PageResponse<TerminationSearchRequest>>(this.terminatedMemberListUrl, {
      params: filterParams(requestParams),
    });
  }

  exportTerminatedMemberList(params: TerminationRecord): Observable<string> {
    return this.http.get<string>(this.exportUrl, {
      params: filterParams(params),
      responseType: 'text' as 'json',
    });
  }

  joinName(...params: string[]) {
    let name = '';
    params.forEach(item => {
      if (item !== undefined && item !== null) {
        name = name + item;
      }
    });
    return name;
  }
}
