<app-loading *ngIf="isLoading" [overlay]="true"></app-loading>

<div class="row relative">
  <div class="col-xs-12 l-mt-7 l-mb-4">
    <span class="cds-h2-light">Agent Case Re-assignment</span>
  </div>

  <div class="col-xs-12 cds-body2 remarks">
    <div>
      {{ 'agent.reassignment-remarks' | lang }}
    </div>
    <div>
      {{ 'agent.reassignment-remarks-content1' | lang }}
    </div>
    <div>
      {{ 'agent.reassignment-remarks-content2' | lang }}
    </div>
  </div>

  <div class="col-xs-12" [formGroup]="formGroup">
    <div class="agent-container">
      <div class="title-area col-xs-12">
        <cds-icon class="icon" icon="people:login_1" [config]="iconConfig"></cds-icon>
        <div class="text">Agent From</div>
      </div>
      <div class="content-area row l-plr-0">
        <div class="content col-md-12 col-lg-6">
          <div class="input-title">
            <div class="title-number">1</div>
            <div class="title-text">Input Original Agent Code</div>
          </div>
          <div class="input-area">
            <cds-textfield
              label=""
              class="textfield"
              placeholder="Input original agent code"
              (cdsChange)="originAgentCodeChange($event)"
              formControlName="originAgentCode"></cds-textfield>
            <div *ngIf="originAgentCodeControl?.invalid && originAgentCodeControl?.dirty">
              <cds-assistive-text *ngIf="originAgentCodeControl?.errors?.['required']" [showIcon]="false">Missing Agent Code.</cds-assistive-text>
              <cds-assistive-text
                *ngIf="originAgentCodeControl?.errors?.['agentCodeListInvalid']"
                [showIcon]="false"
                >{{ originAgentCodeControl.errors?.['agentCodeListInvalid'] | lang }}</cds-assistive-text
              >
              <cds-assistive-text *ngIf="originAgentCodeControl?.errors?.['agentCodeInvalid']" [showIcon]="false">Invalid Agent Code.</cds-assistive-text>
            </div>
            <div class="cds-detail2 tips">
              <div>*Outstanding case(s) can be reassigned to multiple agents, pls use comma as separator.</div>
              <div>E.g. From 123456 -> To 456789,654321</div>
            </div>
          </div>
        </div>
        <div class="content col-md-12 col-lg-6">
          <div class="input-title">
            <div class="title-number">2</div>
            <div class="title-text">Select Employer Account Name</div>
          </div>
          <div class="input-area multidropdown">
            <app-cd-multidropdown
              class="l-mb-5 col-xs-12 col-md-6"
              label=""
              placeholder="Select employer account name"
              (selectedChange)="employerAccountNameChange()"
              formControlName="employerAccountName"
              [toppingList]="customerOption"></app-cd-multidropdown>

            <div (click)="selectAll()" class="select-all l-mb-5 col-xs-12">
              <cds-icon
                *ngIf="ifSelectAll | fn : companyList?.length; else notSelectAll"
                icon="action:button_checkmark_outlined"
                [config]="selectIconConfig"></cds-icon>
              <ng-template #notSelectAll>
                <cds-icon icon="action:button_checkmark_outlined"></cds-icon>
              </ng-template>
              <span class="l-ml-2 cds-body2 bold l-mb-0">Apply to all Related Employer Account</span>
            </div>
          </div>
        </div>
        <div class="content col-xs-12">
          <div class="input-title">
            <div class="title-number">3</div>
            <div class="title-text">Choose the Member List</div>
          </div>
          <div class="radio-area">
            <cds-radio [config]="radioConfig" (cdsChange)="memberListChange()" formControlName="memberList"></cds-radio>
          </div>
        </div>

        <div class="content col-md-12 col-lg-6">
          <div class="input-title">
            <div class="title-number">4</div>
            <div class="title-text">Choose Specific Member Name</div>
          </div>
          <div class="input-area multidropdown">
            <app-cd-multidropdown
              class="l-mb-5 col-xs-12 col-md-6"
              label=""
              placeholder="Choose Specific Member Name"
              (selectedChange)="memberChange($event)"
              formControlName="memberName"
              [toppingList]="memberOption"></app-cd-multidropdown>
          </div>
        </div>
      </div>
    </div>

    <div class="total-area">
      <span>A total of</span>
      <span class="count"> {{ transferNum }} </span>
      <span>employees to be transferred</span>
    </div>

    <div class="agent-container">
      <div class="title-area col-xs-12">
        <cds-icon class="icon" icon="people:login_1" [config]="iconConfig"></cds-icon>
        <div class="text">Agent To</div>
      </div>
      <div class="content-area row l-plr-0">
        <div class="content col-md-12 col-lg-6">
          <div class="input-title">
            <div class="title-number">5</div>
            <div class="title-text">Input New Agent Code</div>
          </div>
          <div class="input-area">
            <cds-textfield
              label=""
              class="textfield"
              placeholder="Input new agent code"
              formControlName="newAgentCode"
              (cdsChange)="newAgentCodeChange($event)"></cds-textfield>
            <div *ngIf="newAgentCodeControl?.invalid && newAgentCodeControl?.dirty">
              <cds-assistive-text *ngIf="newAgentCodeControl?.errors?.['required']" [showIcon]="false">Missing Agent Code.</cds-assistive-text>
              <cds-assistive-text
                *ngIf="newAgentCodeControl?.errors?.['agentCodeListInvalid']"
                [showIcon]="false"
                >{{ newAgentCodeControl.errors?.['agentCodeListInvalid'] | lang }}</cds-assistive-text
              >
              <cds-assistive-text *ngIf="newAgentCodeControl?.errors?.['agentCodeInvalid']" [showIcon]="false">Invalid Agent Code.</cds-assistive-text>
            </div>
            <div *ngIf="formGroup?.invalid && formGroup?.dirty">
              <cds-assistive-text *ngIf="formGroup?.errors?.['agentCodeDuplicated']" [showIcon]="false"
                >Duplicated with Original Agent Code.</cds-assistive-text
              >
            </div>
            <div class="cds-detail2 tips">
              <div>*Outstanding case(s) can be reassigned to multiple agents, pls use comma as separator.</div>
              <div>E.g. From 123456 -> To 456789,654321</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="button-area l-mt-7">
      <cds-button class="l-mr-4" [config]="resetBtuuonConfig" [disabled]="resetDisabled" (click)="reset()">Reset</cds-button>
      <cds-button [disabled]="processDisabled" (click)="submit()">Process</cds-button>
    </div>
  </div>
</div>
