<div class="agent-profile-list">
  <div class="title-text-container">
    <span class="cds-h3-demibold">Agent Profile List</span>
  </div>
  <div class="content">
    <div>
      <span class="cds-h2-light">Search record</span>
    </div>
    <form [formGroup]="agentProfileForm" (ngSubmit)="onAgentProfileSearchSubmit()">
      <div class="row l-d-f padding-none">
        <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12 search-item">
          <span class="cds-body2" style="color: var(--cds-text-disabled)">Agent Person Code</span>
          <app-cd-input class="text-field" [group]="agentProfileForm" controlName="agentPersonCode" placeholder="Input Agent Person Code"></app-cd-input>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12 search-item">
          <span class="cds-body2" style="color: var(--cds-text-disabled)">English Name</span>
          <app-cd-input class="text-field" [group]="agentProfileForm" controlName="englishName" placeholder="Input English Name"></app-cd-input>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12 search-item">
          <span class="cds-body2" style="color: var(--cds-text-disabled)">Agent Code</span>
          <app-cd-input class="text-field" [group]="agentProfileForm" controlName="agentCode" placeholder="Input Agent Code"></app-cd-input>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12 search-item">
          <span class="cds-body2" style="color: var(--cds-text-disabled)">Branch Code</span>
          <app-cd-input class="text-field" [group]="agentProfileForm" controlName="branchCode" placeholder="Input Branch Code"></app-cd-input>
        </div>
        <div *ngIf="!resetDatePicker" class="col-lg-3 col-md-6 col-sm-12 col-xs-12 search-item">
          <cds-textfield
            formControlName="joiningDateFrom"
            type="text"
            placeholder="DD/MM/YYYY"
            label="Joining Date From"
            [cdsDatepicker]="pickerFromDate"></cds-textfield>
          <cds-datepicker #pickerFromDate="cdsDatepicker"></cds-datepicker>
        </div>
        <div *ngIf="!resetDatePicker" class="col-lg-3 col-md-6 col-sm-12 col-xs-12 search-item">
          <cds-textfield formControlName="joiningDateTo" type="text" placeholder="DD/MM/YYYY" label="To" [cdsDatepicker]="pickerToDate"></cds-textfield>
          <cds-datepicker #pickerToDate="cdsDatepicker"></cds-datepicker>
        </div>
        <div *ngIf="!resetDatePicker" class="col-lg-3 col-md-6 col-sm-12 col-xs-12 search-item">
          <cds-textfield
            formControlName="contractTerminationDateFrom"
            type="text"
            placeholder="DD/MM/YYYY"
            label="Contract Termination Date From"
            [cdsDatepicker]="pickerFromDate"></cds-textfield>
          <cds-datepicker #pickerFromDate="cdsDatepicker"></cds-datepicker>
        </div>
        <div *ngIf="!resetDatePicker" class="col-lg-3 col-md-6 col-sm-12 col-xs-12 search-item">
          <cds-textfield
            formControlName="contractTerminationDateTo"
            type="text"
            placeholder="DD/MM/YYYY"
            label="To"
            [cdsDatepicker]="pickerToDate"></cds-textfield>
          <cds-datepicker #pickerToDate="cdsDatepicker"></cds-datepicker>
          <cds-assistive-text *ngIf="agentProfileForm.get('contractTerminationDateTo')?.errors" class="l-mt-3">{{
            'common.invalidDate' | lang
          }}</cds-assistive-text>
        </div>
        <ng-container *ngIf="isAdvancedSearch">
          <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12 search-item" style="margin-top: 26px">
            <span class="cds-body2" style="color: var(--cds-text-disabled)">Agent Contract Status</span>
            <app-cd-multidropdown
              formControlName="agentContractStatus"
              [style.width]="'100%'"
              placeholder="Select Agent Contract Status"
              [toppingList]="agentContractStatusOptionList"
              [(selected)]="agentContractStatusOrigin"></app-cd-multidropdown>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12 search-item" style="margin-top: 26px">
            <span class="cds-body2" style="color: var(--cds-text-disabled)">Branch Head Agent Code</span>
            <app-cd-input
              class="text-field"
              [group]="agentProfileForm"
              controlName="branchHeadAgentCode"
              placeholder="Input Branch Head Agent Code"></app-cd-input>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12 search-item" style="margin-top: 26px">
            <span class="cds-body2" style="color: var(--cds-text-disabled)">Unit Code</span>
            <app-cd-input [group]="agentProfileForm" controlName="unitCode" class="text-field" placeholder="Input Unit Code"></app-cd-input>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12 search-item" style="margin-top: 26px">
            <span class="cds-body2" style="color: var(--cds-text-disabled)">Direct Manager Code</span>
            <app-cd-input [group]="agentProfileForm" controlName="directManagerCode" class="text-field" placeholder="Input Direct Manager Code"></app-cd-input>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12 search-item">
            <span class="cds-body2" style="color: var(--cds-text-disabled)">MPF License Status</span>
            <app-cd-multidropdown
              formControlName="mpfLicenseStatus"
              [style.width]="'100%'"
              placeholder="Select MPF License Status"
              [toppingList]="mpfLicenseStatusOptionList"
              [(selected)]="mpfLicenseStatusOrigin"></app-cd-multidropdown>
          </div>
        </ng-container>
      </div>

      <div class="l-d-f l-ai-cen">
        <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12 search-item bottom-box" style="margin-top: 2rem">
          <cds-button
            [disabled]="isSearchDisable"
            class="l-mr-4"
            [config]="{
              style: 'primary',
              size: 'sm',
              buttonType: 'submit'
            }"
            >{{ 'common.search1' | lang }}</cds-button
          >
          <cds-button [style]="'secondary'" size="sm" (click)="onClickReset()">{{ 'common.reset1' | lang }}</cds-button>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12 search-item l-d-f l-ai-cen">
          <div (click)="onAdvancedSearch()" class="l-d-f l-ai-cen advanced-search-box">
            <cds-icon *ngIf="!isAdvancedSearch" style="--cds-icon-color: var(--cds-bg-cta-primary)" icon="action:button_plus_filled"></cds-icon>
            <cds-icon *ngIf="isAdvancedSearch" style="--cds-icon-color: var(--cds-bg-cta-primary)" icon="action:button_minus_filled"></cds-icon>
            <div style="margin-left: 10px">
              <span class="cds-body2-demibold">Advanced Search</span>
            </div>
          </div>
          <div *ngIf="agentProfileForm?.errors && (agentProfileForm?.errors)!['invalidJoiningDateInput']">
            <cds-assistive-text type="error">{{ 'agent-profile.joiningDateFromGreaterThanTo' | lang }} </cds-assistive-text>
          </div>
          <div *ngIf="agentProfileForm?.errors && (agentProfileForm?.errors)!['contractTerminationDateInput']">
            <cds-assistive-text type="error">{{ 'agent-profile.contractTerminationDateFromGreaterThanTo' | lang }} </cds-assistive-text>
          </div>
        </div>
      </div>
    </form>
    <div class="l-d-f l-jc-sb l-ai-cen" style="margin-top: 3.5rem">
      <div>
        <div>
          <span class="cds-h2-light">Record list</span>
        </div>
        <div class="l-mt-2">
          <span class="cds-body1">Total {{ totalRecords }} records</span>
        </div>
      </div>
      <div>
        <cds-button [style]="'secondary'" [disabled]="isExportBtnDisabled" size="sm" (click)="exportResult()">Export Result</cds-button>
      </div>
    </div>

    <div class="l-mt-5">
      <app-no-result-found
        [msg]="'agent-profile.no_data_found'"
        [style.display]="(isFirstLoad || currentPageData.length === 0) && !isLoading ? 'block' : 'none'"></app-no-result-found>
      <div [style.display]="currentPageData.length > 0 ? 'flex' : 'none'" class="row l-plr-0">
        <div #tableBox class="table-wrapper" [ngClass]="[isLoading ? 'loading' : '']">
          <cds-table class="col-lg-12 row l-plr-0" [dataSource]="currentPageData" [class.full]="false">
            <cds-row *cdsRowDef="let row; index as i; columns: displayedColumns"></cds-row>
            <cds-header-row *cdsHeaderRowDef="displayedColumns"></cds-header-row>
            <ng-container cdsColumnDef="agentPersonCode" sticky>
              <cds-header-cell *cdsHeaderCellDef class="agent-person-code-width">
                <div class="prodtype-displaytooltip">
                  <span>Agent Person Code</span>
                </div>
              </cds-header-cell>
              <cds-cell *cdsCellDef="let element" class="agent-person-code-width">
                {{ element.agentPersonCode }}
              </cds-cell>
            </ng-container>
            <ng-container cdsColumnDef="agentCode">
              <cds-header-cell *cdsHeaderCellDef class="agent-code-width">Agent Code</cds-header-cell>
              <cds-cell *cdsCellDef="let element" class="agent-code-width">
                {{ element.agentCode }}
              </cds-cell>
            </ng-container>

            <ng-container cdsColumnDef="agentContractStatus">
              <cds-header-cell *cdsHeaderCellDef class="agent-status-width">Agent Status</cds-header-cell>
              <cds-cell *cdsCellDef="let element" class="agent-status-width">
                {{ element.agentContractStatus }}
              </cds-cell>
            </ng-container>

            <ng-container cdsColumnDef="englishName">
              <cds-header-cell *cdsHeaderCellDef>
                <div style="width: max-content">
                  <span>English Name</span>
                </div>
              </cds-header-cell>
              <cds-cell *cdsCellDef="let element">
                <div style="width: max-content">
                  <span>{{ element.englishName }}</span>
                </div>
              </cds-cell>
            </ng-container>

            <ng-container cdsColumnDef="chineseName">
              <cds-header-cell *cdsHeaderCellDef class="chinese-name-width">Chinese Name</cds-header-cell>
              <cds-cell *cdsCellDef="let element" class="chinese-name-width">
                {{ element.chineseName }}
              </cds-cell>
            </ng-container>
            <ng-container cdsColumnDef="gender">
              <cds-header-cell *cdsHeaderCellDef class="gender-width">Gender</cds-header-cell>
              <cds-cell *cdsCellDef="let element" class="gender-width">
                {{ element.gender }}
              </cds-cell>
            </ng-container>
            <ng-container cdsColumnDef="dob">
              <cds-header-cell *cdsHeaderCellDef class="dob-width">DoB</cds-header-cell>
              <cds-cell *cdsCellDef="let element" class="dob-width">
                {{ element.dob }}
              </cds-cell>
            </ng-container>
            <ng-container cdsColumnDef="mobileTel">
              <cds-header-cell *cdsHeaderCellDef class="mobile-tel-width">Mobile Tel.</cds-header-cell>
              <cds-cell *cdsCellDef="let element" class="mobile-tel-width">
                {{ element.mobileTel }}
              </cds-cell>
            </ng-container>
            <ng-container cdsColumnDef="officeTel">
              <cds-header-cell *cdsHeaderCellDef class="office-tel-width">Office Tel.</cds-header-cell>
              <cds-cell *cdsCellDef="let element" class="office-tel-width">
                {{ element.officeTel }}
              </cds-cell>
            </ng-container>
            <ng-container cdsColumnDef="email">
              <cds-header-cell *cdsHeaderCellDef>
                <div style="width: max-content">
                  <span> Email </span>
                </div>
              </cds-header-cell>
              <cds-cell *cdsCellDef="let element" style="word-break: break-all">
                <div style="width: max-content">
                  <span>
                    {{ element.email }}
                  </span>
                </div>
              </cds-cell>
            </ng-container>
            <ng-container cdsColumnDef="joiningDate">
              <cds-header-cell *cdsHeaderCellDef class="joining-date-width">Joining Date</cds-header-cell>
              <cds-cell *cdsCellDef="let element" class="joining-date-width">
                {{ element.joiningDate | ssmsDate }}
              </cds-cell>
            </ng-container>
            <ng-container cdsColumnDef="contractEffectiveDate">
              <cds-header-cell *cdsHeaderCellDef class="contract-effective-date-width">Contract Effective Date</cds-header-cell>
              <cds-cell *cdsCellDef="let element" class="contract-effective-date-width">
                {{ element.contractEffectiveDate | ssmsDate }}
              </cds-cell>
            </ng-container>
            <ng-container cdsColumnDef="contractTerminationDate">
              <cds-header-cell *cdsHeaderCellDef class="contract-termination-date-width">Contract Termination Date</cds-header-cell>
              <cds-cell *cdsCellDef="let element" class="contract-termination-date-width">
                {{ element.contractTerminationDate | ssmsDate }}
              </cds-cell>
            </ng-container>
            <ng-container cdsColumnDef="branchCode">
              <cds-header-cell *cdsHeaderCellDef class="branch-code-width">Branch Code</cds-header-cell>
              <cds-cell *cdsCellDef="let element" class="branch-code-width">
                {{ element.branchCode }}
              </cds-cell>
            </ng-container>
            <ng-container cdsColumnDef="branchName">
              <cds-header-cell *cdsHeaderCellDef>
                <div style="width: max-content">
                  <span> Branch Name </span>
                </div>
              </cds-header-cell>
              <cds-cell *cdsCellDef="let element">
                <div style="width: max-content">
                  <span>
                    {{ element.branchName }}
                  </span>
                </div>
              </cds-cell>
            </ng-container>
            <ng-container cdsColumnDef="location">
              <cds-header-cell *cdsHeaderCellDef class="location-width">Location</cds-header-cell>
              <cds-cell *cdsCellDef="let element" class="location-width">
                {{ element.location }}
              </cds-cell>
            </ng-container>
            <ng-container cdsColumnDef="rankCode">
              <cds-header-cell *cdsHeaderCellDef class="rank-code-width">Rank Code</cds-header-cell>
              <cds-cell *cdsCellDef="let element" class="rank-code-width">
                {{ element.rankCode }}
              </cds-cell>
            </ng-container>
            <ng-container cdsColumnDef="rankDescShort">
              <cds-header-cell *cdsHeaderCellDef class="rank-desc-short-width">Rank Desc (Short)</cds-header-cell>
              <cds-cell *cdsCellDef="let element" class="rank-desc-short-width">
                {{ element.rankDescShort }}
              </cds-cell>
            </ng-container>
            <ng-container cdsColumnDef="rankDesc">
              <cds-header-cell *cdsHeaderCellDef class="rank-desc-width">Rank Desc</cds-header-cell>
              <cds-cell *cdsCellDef="let element" class="rank-desc-width">
                {{ element.rankDesc }}
              </cds-cell>
            </ng-container>
          </cds-table>
          <div *ngIf="isLoading" class="progress-box">
            <cds-progress mode="indeterminate" [diameter]="60" [type]="'ring'" [text]="'Loading'" [color]="'ml'"></cds-progress>
          </div>
        </div>
        <div class="col-sm-12 l-mt-4">
          <app-pagination
            [pageCount]="{
              current: 1,
              count: totalPages
            }"
            (pageChange)="pageChange($event)">
          </app-pagination>
        </div>
      </div>
    </div>
  </div>
  <app-cd-download-progress [show]="isDownLoading" (close1)="onClickX()"></app-cd-download-progress>
</div>
