<div class="widgets-campaign-overview">
  <cds-table class="col-lg-12 row" [dataSource]="tableData" [class.full]="true">
    <cds-row *cdsRowDef="let row; index as i; columns: displayedColumns"></cds-row>
    <cds-header-row *cdsHeaderRowDef="displayedColumns"></cds-header-row>
    <ng-container cdsColumnDef="policyNo">
      <cds-header-cell *cdsHeaderCellDef class="policy-no-width"> <span class="cds-detail1-demibold">Policy No.</span></cds-header-cell>
      <cds-cell *cdsCellDef="let element" class="policy-no-width">
        <div class="policy-no-box">
          <span class="cds-body2-demibold">{{ element.policyNo }}</span>
        </div>
        <div class="l-d-f">
          <div>
            <span class="cds-detail1">{{ element.startDate }}</span>
          </div>
          <div class="l-ml-1 l-mr-1"><span>-</span></div>
          <div>
            <span class="cds-detail1">{{ element.endDate }}</span>
          </div>
        </div>
      </cds-cell>
    </ng-container>
    <ng-container cdsColumnDef="campaignDescription">
      <cds-header-cell *cdsHeaderCellDef class="campaign-description-width"><span class="cds-detail1-demibold">Campaign Description</span></cds-header-cell>
      <cds-cell *cdsCellDef="let element" class="campaign-description-width">
        <div>
          <span class="cds-body2">{{ element.campaignDescription }}</span>
        </div>
      </cds-cell>
    </ng-container>

    <ng-container cdsColumnDef="policyType">
      <cds-header-cell *cdsHeaderCellDef class="policy-type-width"><span class="cds-detail1-demibold">Policy Type</span></cds-header-cell>
      <cds-cell *cdsCellDef="let element" class="policy-type-width">
        <div>
          <span class="cds-body1">
            {{ element.policyType }}
          </span>
        </div>
      </cds-cell>
    </ng-container>
    <ng-container cdsColumnDef="eMPFID">
      <cds-header-cell *cdsHeaderCellDef class="empf-id-width"><span class="cds-detail1-demibold">eMPF ID</span></cds-header-cell>
      <cds-cell *cdsCellDef="let element" class="empf-id-width">
        <div>
          <div>
            <span class="cds-body1">
              {{ element.eMPFID }}
            </span>
          </div>
          <div>
            <span class="cds-detail2-demibold empf-id-box">{{ element.nameTc }}{{ element.name }}</span>
          </div>
        </div>
      </cds-cell>
    </ng-container>
    <ng-container cdsColumnDef="deliveryChannel">
      <cds-header-cell *cdsHeaderCellDef class="delivery-channel-width"><span class="cds-detail1-demibold">Delivery Channel</span></cds-header-cell>
      <cds-cell *cdsCellDef="let element" class="delivery-channel-width">
        <div class="l-d-f l-ai-cen">
          <div>
            <cds-icon *ngIf="element.deliveryChannel === DeliveryChannel.Email" icon="contact:email"></cds-icon>
            <cds-icon *ngIf="element.deliveryChannel === DeliveryChannel.SMS" icon="device:mobile"></cds-icon>
          </div>
          <div class="l-ml-3">
            <span class="cds-body2-demibold">{{ element.deliveryChannel }}</span>
          </div>
        </div>
      </cds-cell>
    </ng-container>
    <ng-container cdsColumnDef="campaignType">
      <cds-header-cell *cdsHeaderCellDef class="campaign-type-width"><span class="cds-detail1-demibold">Campaign Type</span></cds-header-cell>
      <cds-cell *cdsCellDef="let element" class="campaign-type-width">
        <div class="campaign-type-box">
          <span class="cds-detail1-demibold" style="color: var(--cds-text-inverse)">{{ element.campaignType }}</span>
        </div>
      </cds-cell>
    </ng-container>
  </cds-table>
</div>
