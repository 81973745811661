/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CDS_SIZE } from '@cds/ng-core/configuration';

import { CdsLangService } from '@cds/ng-core/lang';
import { CdsPopupService, MatDialogRef } from '@cds/ng-web-components/popup';
import { IndividualTransferService } from 'src/app/core/services/commission/commission-individual-transfer.service';
import { CommissionPoolScaleService } from 'src/app/core/services/commission/commission-pool-scale.service';
import { UserAgreementPopupComponent } from '../../user-agreement-popup.component';
import { str2Obj } from 'src/app/utils/utils';
import { ColumnConfig, PageConfig } from 'src/app/shared/data-table';
import { BLANK, COMM_FLAGS, PENDING_TRANSACTION_COLUMNS } from 'src/app/views/commission/adjustment/appeal-case/appeal-case.config';
import { CommissionAppealCaseService } from 'src/app/core/services/commission/commission-appeal-case.service';
import { finalize } from 'rxjs';
import { ResponseResult } from 'src/app/core/models/response/response-result';
import moment from 'moment';
import Big from 'big.js';
import { AccountTypes } from 'src/app/core/models/enum/account-type.enum';
import { PermissionAccess, PermissionItem } from 'src/app/core/models/enum/permission.enum';
import { PermissionService } from 'src/app/core/services/permission.service';

@Component({
  selector: 'app-individual-transfer',
  templateUrl: './individual-transfer.component.html',
  styleUrls: ['./individual-transfer.component.scss'],
})
export class IndividualTransferComponent implements OnInit {
  @Input() commissionPoolId = '';
  permissionAccess = PermissionAccess;
  permissionItem = PermissionItem;

  PAGE_SIZE = 10;
  errors: any;
  isSearchloading = false;
  previousSearchParams: any;
  pageConfig: PageConfig = {};
  columnsConfig: Array<ColumnConfig> = PENDING_TRANSACTION_COLUMNS;
  dataDisplayed: Array<any> = [];

  agents: any[] = [];
  a1 = '0';
  a2 = '0';
  a3 = '0';
  a4 = '0';
  a5 = '0';

  constructor(
    private commissionAppealCaseService: CommissionAppealCaseService,
    private individualService: IndividualTransferService,
    private poolScaleService: CommissionPoolScaleService,
    private cdsPopup: CdsPopupService,
    private langService: CdsLangService,
    private permissionService: PermissionService,
    private router: Router
  ) {}

  ngOnInit() {
    this.permissionService.hasPermission(PermissionAccess.W, PermissionItem.COMM_INFO_INDIVIDUAL_TRF).then(hasPermission => {
      if (!hasPermission) {
        this.columnsConfig = PENDING_TRANSACTION_COLUMNS.slice(1);
      }
    });
    this.getPoolAccumulations();

    this.individualService.getAgent(this.commissionPoolId).subscribe(resp => {
      if (resp && resp.result == 0 && resp.data) {
        this.agents = resp.data;
        this.agents.forEach(item => {
          if (item.agentAccumulations) {
            const agentAccumulations: any[] = item.agentAccumulations;
            if (agentAccumulations && agentAccumulations.length > 0) {
              agentAccumulations.forEach(a => {
                if (a.scaleType == 'IND_FS_PC') {
                  if (a.agentAccumulationType == 'CYTD') item.fsPcCytd = a.amount;
                  if (a.agentAccumulationType == 'PTD') item.fsPcPtd = a.amount;
                  if (a.agentAccumulationType == 'CAL_YTD') item.fsPcCal = a.amount;
                }
                if (a.scaleType == 'IND_TRF_SP_PC') {
                  if (a.agentAccumulationType == 'CYTD') item.trfSpPcCytd = a.amount;
                  if (a.agentAccumulationType == 'PTD') item.trfSpPcPtd = a.amount;
                  if (a.agentAccumulationType == 'CAL_YTD') item.trfSpPcCal = a.amount;
                }
                if (a.scaleType == 'IND_TRF_SP') {
                  if (a.agentAccumulationType == 'CYTD') item.trfSpCytd = a.amount;
                  if (a.agentAccumulationType == 'PTD') item.trfSpPtd = a.amount;
                  if (a.agentAccumulationType == 'CAL_YTD') item.trfSpCal = a.amount;
                }
              });
            }
          }
        });
      }
    });
    this.search({});
  }

  makeAppeal(row: any) {
    this.router.navigate([
      '/commission/adjustment',
      {
        commissionPoolId: row.commissionPoolId || this.commissionPoolId,
        referenceNo: row.referenceNo,
        tabSelected: 'appealCase',
        agentCode: row.agentCode,
      },
    ]);
  }

  search(params: any) {
    if (this.isSearchloading) {
      return;
    }
    this.previousSearchParams = null;
    this.pageConfig = {
      pageSize: this.PAGE_SIZE,
      current: 1,
    };
    const apiParams = { ...params, page: 0, size: this.PAGE_SIZE };
    if (this.commissionPoolId) {
      apiParams.commissionPoolId = this.commissionPoolId;
    }
    if (apiParams.receivedDateFrom) {
      apiParams.receivedDateFrom = moment(apiParams.receivedDateFrom, 'DD/MM/YYYY').format('YYYY-MM-DD');
    }
    if (apiParams.receivedDateTo) {
      apiParams.receivedDateTo = moment(apiParams.receivedDateTo, 'DD/MM/YYYY').format('YYYY-MM-DD');
    }
    this.getPendingTransationData(apiParams);
  }

  getPendingTransationData(params: any) {
    this.isSearchloading = true;
    this.commissionAppealCaseService
      .getPendingTransationData(params)
      .pipe(
        finalize(() => {
          this.isSearchloading = false;
        })
      )
      .subscribe(res => {
        if (res.result === ResponseResult.SUCCESS) {
          this.previousSearchParams = params;
          this.initTableData(res.data);
          this.errors = null;
        } else {
          this.initTableData({
            totalElements: 0,
            content: [],
          });
          this.errors = JSON.parse(res.message);
        }
      });
  }

  initTableData(data: any) {
    this.pageConfig = {
      ...this.pageConfig,
      totalCount: data.totalElements || 0,
    };
    const tempData = data.content || [];
    const dataDisplayed: Array<any> = [];
    tempData.forEach((item: any) => {
      item.commFlagLabel = item.commFlag && item.commFlag !== BLANK ? COMM_FLAGS[item.commFlag as unknown as keyof typeof COMM_FLAGS] : BLANK;
      if (item.receivedDate) {
        item.receivedDate = item.receivedDate ? moment(new Date(item.receivedDate)).format('DD/MM/YYYY') : '/';
      }
      if (item.appealDate) {
        item.appealDate = item.appealDate ? moment(new Date(item.appealDate)).format('DD/MM/YYYY') : '/';
      }
      if (item.cancelledOrRejectedDate) {
        item.cancelledOrRejectedDate = item.cancelledOrRejectedDate ? moment(new Date(item.cancelledOrRejectedDate)).format('DD/MM/YYYY') : '/';
      }
      item.isEditable = !item.cancelledOrRejectedDate;
      item.accountType = AccountTypes.find(list => list.value === item.accountType)?.label || item.accountType;
      item.details.forEach((detail: any) => {
        dataDisplayed.push({
          ...item,
          ...detail,
        });
      });
    });
    this.dataDisplayed = dataDisplayed;
  }

  pageChange(ev: any) {
    if (this.pageConfig.current !== ev.current) {
      this.pageConfig.current = ev.current;
      let params = { ...this.pageConfig, size: this.PAGE_SIZE, page: (this.pageConfig?.current || 1) - 1 };
      if (this.previousSearchParams) {
        params = { ...this.previousSearchParams, ...params };
      }
      this.getPendingTransationData(params);
    }
  }

  toAdjustmentAmount() {
    this.router.navigate([
      '/commission/adjustment',
      {
        commissionPoolId: this.commissionPoolId,
        tabSelected: 'individualTransfer',
      },
    ]);
  }

  getPoolAccumulations() {
    this.poolScaleService
      .getPoolAccumulations({
        commissionPoolId: this.commissionPoolId,
        regular: false,
      })
      .subscribe(res => {
        const resp = str2Obj(res.body, ['amount']);
        if (resp && resp.result == 0 && resp.data[0] && resp.data[0].commissionPoolScaleAccumulations) {
          const accumulations: any[] = resp.data[0].commissionPoolScaleAccumulations;
          accumulations.forEach(item => {
            const accumulation = item;
            item.amount = new Big(item.amount).round(2, Big.roundDown).toFixed(2);
            if (accumulation && accumulation.scaleType == 'IND_TRF_SP_PC' && accumulation.scaleAccumulationType == 'RELEASED_CYTD') {
              this.a1 = new Big(item.amount).toFixed(0);
            }
            if (accumulation && accumulation.scaleType == 'IND_TRF_SP_PC' && accumulation.scaleAccumulationType == 'PREMIUM_CYTD') {
              this.a2 = item.amount;
            }
            if (accumulation && accumulation.scaleType == 'IND_TRF_SP_PC' && accumulation.scaleAccumulationType == 'PREMIUM_PTD') {
              this.a3 = item.amount;
            }
            if (accumulation && accumulation.scaleType == 'IND_TRF_SP' && accumulation.scaleAccumulationType == 'PREMIUM_CYTD') {
              this.a4 = item.amount;
            }
            if (accumulation && accumulation.scaleType == 'IND_TRF_SP' && accumulation.scaleAccumulationType == 'PREMIUM_PTD') {
              this.a5 = item.amount;
            }
          });
        }
      });
  }

  alertAccountCodeInfo() {
    const msg = this.langService.translate('commission.appeal.accountCode.futherInfo');
    this.openPop(msg);
  }

  alertIndividual() {
    const msg = this.langService.translate('commission.individualTransfer.transferAgentAlert');
    this.openPop(msg);
  }

  alertPendingTransaction() {
    const msg = this.langService.translate('commission.individualTransfer.pendingTransactionAlert');
    this.openPop(msg);
  }

  openPop(msg: string) {
    const popupRef: MatDialogRef<UserAgreementPopupComponent> = this.cdsPopup.open(UserAgreementPopupComponent, {
      size: CDS_SIZE['SMALL'],
      data: {
        message: msg,
        continue: 'common.gotIt',
        type: 'alert',
        actionRight: true,
      },
    });
    popupRef.afterClosed().subscribe(() => {});
  }

  backTop() {
    document.getElementsByClassName('cds-navigation-content')[0].scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }
}
