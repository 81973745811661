<div class="content">
  <div *ngIf="label" class="cds-color-grey l-pt-2 l-pb-2" [class.disabled]="disabled">
    <span class="cds-body2">{{ label }}</span>
  </div>
  <div
    class="row l-plr-0 text-field"
    [ngClass]="{
      '': !editMode,
      'text-field-not-input': editMode,
      'text-field-input': _input,
      'text-field-input-disabled': disabled
    }">
    <div class="col-xs l-plr-0">
      <input
        [placeholder]="placeholder"
        [disabled]="!editMode || disabled"
        (focus)="cssTrigger(true)"
        (blur)="cssTrigger(false)"
        #inputBox
        (input)="inputVal(inputBox.value, true)"
        [value]="value" />
    </div>
    <div *ngIf="editMode && !showDropdownButton && value !== ''" class="pointer middle-xs">
      <cds-icon (click)="inputVal('', true)" width="20px" color="#8E90A2" icon="action:button_x_filled"></cds-icon>
    </div>
    <div *ngIf="editMode && showDropdownButton" class="pointer middle-xs" [class.disabled]="disabled">
      <cds-icon (click)="toggleSelectPanel()" width="26px" height="26px" [color]="disabled ? '#5e607366' : '#282B3E'" icon="action:button_down"></cds-icon>
    </div>
  </div>
  <div
    #selectPanelRef
    *ngIf="editMode && _input && inputValid && (optionList.length || isLoading) && (initLoad || value)"
    (scroll)="selectPanelonScroll()"
    class="select-panel l-mt-2">
    <ng-container *ngIf="optionList.length > 0">
      <div (mousedown)="inputVal((item ? item[optionKey] : '') || item, false, item)" *ngFor="let item of optionList" class="select-option l-pa-2 pointer">
        {{ labelRenderFn ? labelRenderFn(item) : (item ? item[optionKey] : '') || item }}
      </div>
    </ng-container>

    <div *ngIf="isLoading" class="loading">
      <cds-progress mode="indeterminate" type="ring" [diameter]="22">Loading</cds-progress>
    </div>
  </div>
</div>
