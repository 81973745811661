<app-cd-page-title title="{{ 'commission.holdOnReport.title' | lang }}"> </app-cd-page-title>
<div class="row">
  <div class="col-xs-12 l-pt-7 l-mb-7">
    <form [formGroup]="formGroup" (ngSubmit)="search()">
      <div class="cds-h2-light l-mb-4">
        {{ 'commission.pool.common.searchRecord' | lang }}
      </div>
      <div class="row l-plr-0">
        <cds-form-field class="col-xs-12 col-md-6 col-lg-3 l-pb-5 l-pl-0 l-pr-20">
          <cds-textfield [config]="accountNoConfig" [formControl]="accountNo"> </cds-textfield>
          <cds-assistive-text *ngIf="accountNo.errors">
            {{ accountNo.errors['error'] | lang }}
          </cds-assistive-text>
        </cds-form-field>
        <cds-form-field class="col-xs-12 col-md-6 col-lg-3 l-pb-5 l-pl-0 l-pr-20">
          <cds-textfield [config]="memberNoConfig" [formControl]="memberNo"> </cds-textfield>
          <cds-assistive-text *ngIf="memberNo.errors">
            {{ memberNo.errors['error'] | lang }}
          </cds-assistive-text>
        </cds-form-field>
        <div class="col-xs-12 col-md-6 col-lg-3 l-pb-5 l-pl-0 l-pr-20">
          <cds-dropdown [config]="accountTypeConfig" [formControl]="accountType"> </cds-dropdown>
        </div>
        <div class="col-xs-12 col-md-6 col-lg-3 l-pb-5 l-pl-0 l-pr-20">
          <cds-dropdown [config]="activityCodeConfig" [formControl]="subActivityCd"> </cds-dropdown>
        </div>
        <div class="col-xs-12 col-md-6 col-lg-3 l-pb-5 l-pl-0 l-pr-20">
          <cds-dropdown [config]="reasonConfig" [formControl]="holdOnReason"> </cds-dropdown>
        </div>
        <div class="col-xs-12 col-md-6 col-lg-3 l-pb-5 l-pl-0 l-pr-20 l-d-f l-ai-fe">
          <div class="l-d-f l-ai-cen">
            <cds-checkbox [formControl]="frozenPoolExtraction"></cds-checkbox>
            <label class="l-ml-3 cds-body cds-dark-navy-text l-mb-0">{{ 'commission.holdOnReport.frozen' | lang }}</label>
          </div>
          <!-- <cds-checkbox [config]="frozenConfig" [formControl]="frozenPoolExtraction"> </cds-checkbox> -->
        </div>
        <div class="col-xs-12 col-md-6 col-lg-3 l-pb-5 l-pl-0 l-pr-20">
          <div class="search-area-btn">
            <cds-button type="submit" [disabled]="isSearchDisable" size="md">{{ 'common.search' | lang }} </cds-button>
            <cds-button type="submit" (click)="reset()" [style]="'secondary'" size="md">{{ 'common.reset' | lang }} </cds-button>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="col-xs-12">
    <app-cd-table-title class="l-pt-3 l-pb-3" [title]="'common.recordList' | lang" [total]="getTotalCount"> </app-cd-table-title>
    <app-data-table
      [frontPagination]="false"
      [fixed]="false"
      [isLoading]="loading"
      (reloadTable)="pageChange($event)"
      [pageConfig]="pageConfig"
      [dataSource]="dataDisplayed"
      [columnsConfig]="columnsConfig">
    </app-data-table>
  </div>
</div>
