import { Injectable } from '@angular/core';
import { AgentChannel } from 'src/app/core/models/enum/agent-channel.enum';
import { AgentChannelService } from 'src/app/core/services/agent-channel.service';

@Injectable({
  providedIn: 'root',
})
export class SalesjourneyService {
  constructor(private agentChannelService: AgentChannelService) {}

  isScbBrokerCorporateChannel() {
    if (this.agentChannelService.channel && [AgentChannel.SCB, AgentChannel.BROKER, AgentChannel.CORPORATE].includes(this.agentChannelService.channel)) {
      return true;
    }
    return false;
  }
}
