import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { CdsPopupService } from '@cds/ng-web-components/popup';
import {
  ContributionAmount,
  ContributionAmountType,
  ContributionDetails,
  ContributionDetailsCategory,
  ContributionDetailsCategoryE,
} from '../../employer/employer';

@Component({
  selector: 'app-amount-table',
  templateUrl: './amount-table.component.html',
  styleUrls: ['./amount-table.component.scss'],
})
export class AmountTableComponent implements OnInit, OnChanges {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() contributionTableDataSource: any[] = [];
  @Input() contributionDetails?: ContributionDetails;

  contributionTablDisplayedColumns: string[] = [];

  showTable = false;

  constructor(private cdsPopup: CdsPopupService) {}

  ngOnInit(): void {
    this.genDisaplayedColums();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes['contributionTableDataSource'] !== undefined &&
      changes['contributionTableDataSource'].currentValue !== undefined &&
      changes['contributionTableDataSource'].currentValue
    ) {
      this.genDisaplayedColums();
    }
  }

  genDisaplayedColums() {
    this.showTable = false;
    this.contributionTablDisplayedColumns = [];

    this.contributionTableDataSource.forEach(lineData => {
      Object.keys(lineData).forEach(key => {
        if (key === 'category' || key === 'surcharge' || lineData[key as keyof ContributionAmount] === 0 || !!lineData[key as keyof ContributionAmount]) {
          if (this.contributionTablDisplayedColumns.indexOf(key) < 0) {
            this.contributionTablDisplayedColumns.push(key);
          }
        }
      });
    });
    //contributionTableDataSource如果里面的值为null赋值为0
    this.contributionTableDataSource.forEach(lineData => {
      this.contributionTablDisplayedColumns.forEach(key => {
        //lineData所有属性如果为null就赋值为0
        if (lineData[key as keyof ContributionAmount] === null && lineData[key as keyof ContributionAmount] !== undefined) {
          lineData[key as keyof ContributionAmount] = 0;
        }
      });
    });
    //把this.contributionTablDisplayedColumns里面的surcharge元素放在数组最后一个
    const index = this.contributionTablDisplayedColumns.indexOf('surcharge');
    if (index > -1) {
      this.contributionTablDisplayedColumns.splice(index, 1);
      this.contributionTablDisplayedColumns.push('surcharge');
    }

    setTimeout(() => {
      this.showTable = true;
    });
  }

  getCategoryTitle(category: ContributionDetailsCategory) {
    if (!category) {
      return '';
    }
    return ContributionDetailsCategoryE[category];
  }

  canClick(amount: number | string) {
    if (amount === '0' || !amount) {
      return false;
    }
    return true;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  popupDetail(category: ContributionDetailsCategory, type: ContributionAmountType, amount: number) {
    // console.log(category, type, amount);
  }
}
