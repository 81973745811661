import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-void-eiff-popup',
  templateUrl: './void-eiff-popup.component.html',
  styleUrls: ['./void-eiff-popup.component.scss'],
})
export class VoidEiffPopupComponent implements OnInit {
  constructor(private dialogRef: MatDialogRef<VoidEiffPopupComponent>) {}

  ngOnInit(): void {}

  cancel() {
    this.dialogRef.close({ agree: false });
  }

  confirm() {
    this.dialogRef.close({ agree: true });
  }
}
