/* eslint-disable @typescript-eslint/no-explicit-any */
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { CdsLangService } from '@cds/ng-core/lang';
import { CdsPopupService } from '@cds/ng-web-components/popup';
import { findOptionLabelByValue, str2Obj } from 'src/app/utils/utils';
import { BTN_CONFIG_SECONDARY } from '../../../../config/btn.config';
import { ScalePattern, ScaleType } from '../../../../core/models/enum/commission-scale.enum';
import { PermissionAccess, PermissionItem } from '../../../../core/models/enum/permission.enum';
import { CommissionScaleService } from '../../../../core/services/commission/commission-scale.service';
import { LayoutHeaderService } from '../../../../core/services/layout-header.service';
import { PermissionService } from '../../../../core/services/permission.service';
import { AdminHeaderConfig } from '../../../layout/admin-layout/admin-header/admin-header-config';
import { UserAgreementPopupComponent } from '../../user-agreement-popup.component';
import { CommissionBreakpointComponent } from '../commission-breakpoint/commission-breakpoint.component';
import { CommissionRecordDetailComponent } from '../commission-record-detail/commission-record-detail.component';
import { CALCULATION_TYPE_OPTIONS, DEFAULT_BREAKPOINT } from '../commission-scale.config';
import { BreakpointDetailVo, BreakpointVo, CommissionScaleVo } from '../commission-scale.model';
import Big from 'big.js';

@Component({
  selector: 'app-commission-scale-info',
  templateUrl: './commission-scale-info.component.html',
  styleUrls: ['./commission-scale-info.component.scss'],
})
export class CommissionScaleInfoComponent implements OnInit, AfterViewInit {
  private amountDefaultValue = '99999999999999999999.99';
  private createTitle = 'commission.scale.title.create';
  private editTitle = 'commission.scale.title.edit';

  /**button and its enabled properties */
  btnCfg = BTN_CONFIG_SECONDARY;
  isEditPage = false;
  isSubmitted = false;
  isSaveChangesEnable = false;

  /**header button config */
  headerButton: AdminHeaderConfig = {
    title: this.createTitle,
    backButton: {
      text: 'commission.scale.title.backToList',
      url: '/commission/scale-list',
    },
  };

  /**commission record detail */
  commissionItem: CommissionScaleVo = {
    id: '',
    scaleCode: '',
    scaleType: null,
    calculationType: null,
    crfRate: null,
    breakpointType: null,
    scalePattern: null,
  };
  editItem!: CommissionScaleVo;
  @ViewChild(CommissionRecordDetailComponent)
  commissionRecordDetail!: CommissionRecordDetailComponent;
  @ViewChild(CommissionBreakpointComponent)
  breakpoint!: CommissionBreakpointComponent;

  /**commission breakpoint */
  dataSource: BreakpointVo[] = [
    {
      yearNo: '99',
      isLocked: true,
      list: [JSON.parse(JSON.stringify(DEFAULT_BREAKPOINT)), JSON.parse(JSON.stringify(DEFAULT_BREAKPOINT)), JSON.parse(JSON.stringify(DEFAULT_BREAKPOINT))],
    },
  ];
  oldDataSource: BreakpointVo[] = [];

  constructor(
    private cdsPopup: CdsPopupService,
    private router: Router,
    private route: ActivatedRoute,
    private layoutHeaderService: LayoutHeaderService,
    private permissionService: PermissionService,
    private commissionScaleService: CommissionScaleService,
    private langService: CdsLangService
  ) {}

  ngOnInit() {
    const id = this.route.snapshot.queryParams['id'];
    if (!id) {
      //validate role permission
      this.permissionService
        .permissionFilterAsync({
          [PermissionItem.COMMISSION_SCALE_MANAGEMENT]: PermissionAccess.W,
        })
        .then(has => {
          if (!has) {
            this.router.navigate(['/permission-error']);
          }
        });
      this.layoutHeaderService.setHeaderButton(this.headerButton);
    } else {
      this.commissionScaleService.getById2(id).subscribe(response => {
        //add quota to numerical fields to prevent percision lost
        if (response && response.body) {
          const res = str2Obj(response.body, ['amount', 'percentage', 'min', 'max']);
          if (res && res.data) {
            this.editItem = res.data.info;
            if (!res.data.info.lastUpdateTime) {
              this.editItem.lastUpdateTime = this.editItem.createTime;
            }
            if (!res.data.info.crfRate) {
              this.editItem.crfRate = null;
            }
            // update header button config
            const title = this.langService.translate('commission.scale.title.edit.specify') || '';
            this.headerButton.title = title.concat(this.editItem.scaleCode);
            this.layoutHeaderService.setHeaderButton(this.headerButton);

            //check role permission
            this.permissionService
              .permissionFilterAsync({
                [PermissionItem.COMMISSION_SCALE_MANAGEMENT]: PermissionAccess.W,
              })
              .then(has => {
                if (has) {
                  this.headerButton.rightButton = {
                    text: 'common.edit',
                    icon: {
                      type: 'action:edit',
                    },
                    method: this.edit.bind(this),
                  };
                  this.layoutHeaderService.setHeaderButton(this.headerButton);
                }
              });

            const breakpoint = res.data.breakPoint;
            for (const [key, value] of Object.entries(breakpoint)) {
              const item: any = {};
              item.yearNo = key;
              item.isLocked = false;
              if (item.yearNo === '99') {
                item.isLocked = true;
              }

              item.list = [];
              const s: any = value;
              const len = Object.entries(s).length;
              for (const [, value2] of Object.entries(s)) {
                const j: any = {};
                const k: any = value2;

                if (!k.amount || len === 1) {
                  j.amount = null;
                } else {
                  j.amount = String(new Big(k.amount).round(2, Big.roundDown).toFixed(2));
                }
                j.percentage = String(k.percentage);
                item.list.push(j);
              }
              this.oldDataSource.push(item);
            }
            this.commissionItem = JSON.parse(JSON.stringify(this.editItem));
            this.dataSource = JSON.parse(JSON.stringify(this.oldDataSource));

            this.isEditPage = true;
            this.commissionRecordDetail.recordDetailEnable = true;
            this.breakpoint.breakpointEnable = true;
          }
        }
      });
    }
  }

  ngAfterViewInit(): void {
    const id = this.route.snapshot.queryParams['id'];
    if (!id) {
      this.commissionRecordDetail.recordDetailEnable = false;
      this.breakpoint.breakpointEnable = false;
    }
  }

  edit() {
    this.isEditPage = false;
    this.commissionRecordDetail.recordDetailEnable = true;
    this.breakpoint.breakpointEnable = true;
    this.fillRowWithSliding(this.commissionItem.scalePattern);

    this.headerButton.title = this.editTitle;
    const isMobile = window.matchMedia('only screen and (max-width: 760px)').matches || window.screen.width < 500;
    let iconType = 'action:edit';
    if (isMobile) {
      iconType = 'action:more_options';
    }
    this.headerButton.rightButton = {
      text: 'common.cancel.editing',
      icon: { type: iconType },
      method: this.cancelEdit.bind(this),
    };
    this.layoutHeaderService.setHeaderButton(this.headerButton);
    this.setDisabledOfFormGroup(false);
  }

  backToEdit() {
    this.isSubmitted = false;
    if (this.editItem) {
      this.headerButton.title = this.editTitle;
      this.headerButton.rightButton = {
        text: 'common.cancel.editing',
        icon: { type: 'action:edit' },
        method: this.cancelEdit.bind(this),
      };
      this.layoutHeaderService.setHeaderButton(this.headerButton);
    }
    this.fillRowWithSliding(this.commissionItem.scalePattern);
    this.setDisabledOfFormGroup(false);
  }

  cancelEdit() {
    const popupRef: MatDialogRef<UserAgreementPopupComponent> = this.cdsPopup.open(UserAgreementPopupComponent, {
      data: {
        message: 'common.action.cancel',
        cancel: 'common.cancel',
        continue: 'common.continue',
        type: 'confirm',
      },
    });
    popupRef.afterClosed().subscribe(confirm => {
      if (confirm.agree) {
        this.headerButton.title = 'Commission scale record - ' + this.editItem.scaleCode;
        this.headerButton.rightButton = {
          text: 'common.edit',
          icon: {
            type: 'action:edit',
          },
          method: this.edit.bind(this),
        };
        this.layoutHeaderService.setHeaderButton(this.headerButton);
        this.isEditPage = true;
        this.commissionItem = JSON.parse(JSON.stringify(this.editItem));
        this.dataSource = JSON.parse(JSON.stringify(this.oldDataSource));
        this.setDisabledOfFormGroup(true);
        this.setScaleInfoToOld();
      }
    });
  }

  submit() {
    if (!this.commissionRecordDetail.recordDetailEnable || !this.breakpoint.breakpointEnable) return;
    if (this.editItem && !this.isSaveChangesEnable) return;

    //validate empty code
    const scaleCode = this.commissionItem.scaleCode.replace(/\s+/g, '');
    if (!scaleCode.trim()) {
      const scaleCodeControl = this.commissionRecordDetail.recordDetailFormGroup.controls['scaleCode'];
      scaleCodeControl.patchValue('');
      scaleCodeControl.markAsTouched();
      scaleCodeControl.setErrors({ required: true });
      this.commissionRecordDetail.recordDetailEnable = false;
      this.checkSaveChangesEnable();
      return;
    }
    //validate scale type and calculation type manully when user only selects scale type
    const scaleType = this.commissionItem.scaleType;
    const calcType = this.commissionItem.calculationType;
    const isFYOrPC = scaleType === ScaleType.FY || scaleType === ScaleType.FS_PC || scaleType === ScaleType.PS_PC;
    if (!calcType && isFYOrPC) {
      this.commissionRecordDetail.recordDetailFormGroup.setErrors({
        invalidCalc: true,
      });
      this.commissionRecordDetail.recordDetailFormGroup.markAsTouched();
      this.commissionRecordDetail.recordDetailEnable = false;
      this.checkSaveChangesEnable();
      return;
    }

    // add warning poup
    let percentageWarningFlag = false;
    for (const data of this.dataSource) {
      const filterList = data.list.filter(item => (!!item.amount || !!item.percentage) && (Number(item.percentage) > 15 || Number(item.percentage) < 0.25));
      if (filterList.length > 0) {
        percentageWarningFlag = true;
        break;
      }
    }
    if (percentageWarningFlag) {
      const popupRef: MatDialogRef<UserAgreementPopupComponent> = this.cdsPopup.open(UserAgreementPopupComponent, {
        data: {
          message: 'commission.scale.confirm.percentage',
          cancel: 'common.cancel',
          continue: 'common.continue',
          type: 'confirm',
        },
      });
      popupRef.afterClosed().subscribe(confirm => {
        if (confirm.agree) {
          this.submitToConfirm();
        }
      });
    } else {
      this.submitToConfirm();
    }
  }

  confirm() {
    const commissionDto: any = {
      scaleCode: this.commissionItem.scaleCode.replace(/\s+/g, ''),
      scaleType: this.commissionItem.scaleType,
      scalePattern: this.commissionItem.scalePattern,
      breakpointType: this.commissionItem.breakpointType,
      crfRate: this.commissionItem.crfRate ? this.commissionItem.crfRate : 0,
      calculationType: this.commissionItem.calculationType ? this.commissionItem.calculationType : null,
    };

    const breakpoint: Map<any, any> = new Map();
    this.dataSource.forEach(data => {
      const point: Map<any, any> = new Map();
      data.list.forEach((item, index) => {
        const bk: any = {};
        bk.amount = item.amount;
        if (!item.amount) {
          bk.amount = this.amountDefaultValue;
        }
        bk.percentage = item.percentage;
        point.set(index + 1, bk);
      });
      breakpoint.set(data.yearNo, Object.fromEntries(point));
    });
    const params = {
      info: commissionDto,
      breakPoint: Object.fromEntries(breakpoint),
    };
    this.commissionScaleService.saveOrUpdate(this.editItem?.id, params).subscribe(resp => {
      const result = resp.result;
      let msg = 'commission.scale.confirm.create';
      if (this.editItem?.id) {
        msg = 'common.action.success';
      }
      const popData = {
        message: msg,
        type: 'alert',
        continue: 'common.gotIt',
      };
      if (!resp || !resp.data || result !== 0) {
        popData.message = 'common.action.failed';
        popData.continue = 'common.backToEdit';
      }
      const popupRef: MatDialogRef<UserAgreementPopupComponent> = this.cdsPopup.open(UserAgreementPopupComponent, { data: popData });
      popupRef.afterClosed().subscribe(confirm => {
        if (confirm.agree) {
          if (result == 0) {
            this.router.navigate(['/commission/scale-list']);
          } else {
            this.backToEdit();
          }
        }
      });
    });
  }

  //check 'saveChanges' button is enable or not
  checkSaveChangesEnable() {
    if (!this.editItem) return;
    if (!this.commissionRecordDetail.recordDetailEnable || !this.breakpoint.breakpointEnable) {
      this.isSaveChangesEnable = false;
      return;
    }

    //compare the record detail
    if (
      this.editItem.scaleCode !== this.commissionItem.scaleCode ||
      this.editItem.scaleType !== this.commissionItem.scaleType ||
      findOptionLabelByValue(this.editItem.calculationType || '', CALCULATION_TYPE_OPTIONS) !==
        findOptionLabelByValue(this.commissionItem.calculationType || '', CALCULATION_TYPE_OPTIONS) ||
      Number(this.editItem.crfRate) !== Number(this.commissionItem.crfRate) ||
      this.editItem.breakpointType !== this.commissionItem.breakpointType
    ) {
      this.isSaveChangesEnable = true;
      return;
    }

    //compare breakpoint's dataSource
    const dataSource2: BreakpointVo[] = [];
    this.dataSource.forEach(data => {
      const list: BreakpointDetailVo[] = [];
      this.filterEmptyRow(data).forEach(item => {
        const amt = item.amount !== null ? Number(item.amount).toString() : item.amount;
        list.push({
          amount: amt,
          percentage: Number(item.percentage).toString(),
        });
      });
      dataSource2.push({
        yearNo: data.yearNo,
        isLocked: data.isLocked,
        list: list,
      });
    });
    if (dataSource2.length !== this.oldDataSource.length) {
      this.isSaveChangesEnable = true;
      return;
    }

    dataSource2.sort((a, b) => (a.yearNo ? Number(a.yearNo) : 0) - (b.yearNo ? Number(b.yearNo) : 0));
    this.isSaveChangesEnable = JSON.stringify(dataSource2) !== JSON.stringify(this.oldDataSource);
  }

  private submitToConfirm() {
    this.dataSource.forEach(data => {
      data.list = this.filterEmptyRow(data);
    });
    this.dataSource = this.dataSource.filter(data => !!data.yearNo && data.list.length > 0);
    this.dataSource.sort((a, b) => (a.yearNo ? Number(a.yearNo) : 0) - (b.yearNo ? Number(b.yearNo) : 0));

    this.headerButton = {
      title: this.editItem ? this.editTitle : this.createTitle,
      backButton: {
        text: 'commission.scale.title.backToList',
        url: '/commission/scale-list',
      },
    };
    this.layoutHeaderService.setHeaderButton(this.headerButton);
    this.isSubmitted = true;
    this.commissionRecordDetail.recordDetailFormGroup.disable();
  }

  //filter empty row of breakpoint details
  private filterEmptyRow(data: BreakpointVo) {
    let filterList = data.list.filter(item => !!item.amount || !!item.percentage);

    if (filterList.length === 0) {
      filterList = [];
      if (data.yearNo) {
        filterList = [data.list[0]];
      } else {
        data.yearError = '';
        data.list.forEach(item => {
          item.amountError = '';
          item.percentageError = '';
        });
      }
    }
    return filterList;
  }

  //Edit mode，when click edit， if Scale Pattern is SLIDING and breakpoints item < 3, set breakpoints item = 3
  private fillRowWithSliding(value: ScalePattern | null | string) {
    if (value === ScalePattern.SLIDING) {
      this.dataSource.forEach(data => {
        let differ = data.list.length - 3;
        while (differ < 0) {
          data.list.push(JSON.parse(JSON.stringify(DEFAULT_BREAKPOINT)));
          differ++;
        }
      });
    }
  }

  //set `disabled` of FormGroup for commission record detail child component
  private setDisabledOfFormGroup(disabled: boolean) {
    const scalePatternControl = this.commissionRecordDetail.recordDetailFormGroup?.controls['scalePattern'];

    if (!disabled) {
      this.commissionRecordDetail.recordDetailFormGroup.enable();
      if (this.editItem) {
        scalePatternControl.disable();
      }
    } else {
      this.commissionRecordDetail.recordDetailFormGroup.disable();
    }
  }

  private setScaleInfoToOld() {
    const scaleCodeControl = this.commissionRecordDetail.recordDetailFormGroup?.controls['scaleCode'];
    const scaleTypeControl = this.commissionRecordDetail.recordDetailFormGroup?.controls['scaleType'];
    const calcTypeControl = this.commissionRecordDetail.recordDetailFormGroup?.controls['calcType'];
    const crfRateControl = this.commissionRecordDetail.recordDetailFormGroup?.controls['crfRate'];
    const breakpointTypeControl = this.commissionRecordDetail.recordDetailFormGroup?.controls['breakpointType'];

    scaleCodeControl.patchValue(this.editItem.scaleCode);
    scaleTypeControl.patchValue(this.editItem.scaleType);
    //reset calculation type options
    this.commissionRecordDetail.valueChange(this.editItem.scaleType, 'scaleType');
    calcTypeControl.patchValue(this.editItem.calculationType);
    crfRateControl.patchValue(this.editItem.crfRate);
    breakpointTypeControl.patchValue(this.editItem.breakpointType);
  }
}
