import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { SharedModule } from 'src/app/shared/shared.module';
import { CdsLanguageModule } from '@cds/ng-core/lang';
import { CdsButtonModule } from '@cds/ng-web-components/button';
import { CdsAssistiveTextModule } from '@cds/ng-web-components/assistive-text';
import { MatSelectModule } from '@angular/material/select';
import { CdsIconModule } from '@cds/ng-web-components/icon';

import { SchemeMasterComponent } from './scheme-master.component';
import { SchemeMasterEditComponent } from './scheme-master-edit/scheme-master-edit.component';
import { SchemeMasterCreateComponent } from './scheme-master-create/scheme-master-create.component';

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, SharedModule, CdsLanguageModule, CdsButtonModule, CdsAssistiveTextModule, MatSelectModule, CdsIconModule],
  declarations: [SchemeMasterComponent, SchemeMasterEditComponent, SchemeMasterCreateComponent],
})
export class SchemeMasterModule {}
