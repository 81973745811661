/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Input, OnInit } from '@angular/core';
import { WidgetComponent } from '../widget.component';
import { CdsAlertService } from '@cds/ng-web-components/alert';
import { CdHttpServeService } from 'src/app/shared/cd-http-serve/cd-http-serve.service';
import { Campaign } from 'src/app/core/models/widgets/campaign';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { IffService } from '../../salesjourney/service/iff.service';

@Component({
  selector: 'app-widgets-campaign-result',
  templateUrl: './widgets-campaign-result.component.html',
  styleUrls: ['./widgets-campaign-result.component.scss'],
})
export class WidgetsCampaignResultComponent implements OnInit, WidgetComponent {
  @Input() data: any;
  campaign?: Campaign;

  constructor(
    private cdHttpServeService: CdHttpServeService,
    private alert: CdsAlertService,
    private authenticationService: AuthenticationService,
    private iffService: IffService
  ) {}

  ngOnInit(): void {
    this.authenticationService.currentUserValue().then(res => {
      this.iffService.getAgentCode(res.id + '').subscribe({
        next: res => {
          if (res.result !== 0) {
            this.alert.warning('Warning!', `${res.message}`);
          } else {
            this.getCampaignByAgentcode(res.data.agentCode);
          }
        },
        error: err => {
          this.alert.error('Error!', err);
        },
      });
    });
  }

  getCampaignByAgentcode(agentCode: string) {
    this.cdHttpServeService.get<Campaign>(`/ext/eb-ssms/agent-service/campaignList/${agentCode}`, {}).subscribe({
      next: res => {
        if (res.result !== 0) {
          this.alert.warning('Warning!', `${res.message}`);
        } else {
          this.campaign = res.data;
        }
      },
      error: err => {
        this.alert.error('Error!', err);
      },
    });
  }
}
