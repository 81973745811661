import { Component, OnInit, Input } from '@angular/core';

export type MattersNeedingAttentionType = 'yellow' | 'red';

@Component({
  selector: 'app-matters-needing-attention',
  templateUrl: './matters-needing-attention.component.html',
  styleUrls: ['./matters-needing-attention.component.scss'],
})
export class MattersNeedingAttentionComponent implements OnInit {
  @Input() title!: string;
  @Input() text!: string;
  @Input() color: MattersNeedingAttentionType = 'yellow';

  constructor() {}

  ngOnInit(): void {}
}
