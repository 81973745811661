import { Component, OnInit } from '@angular/core';
import { CdsButtonConfig } from '@cds/ng-core/button';
import { MatDialogRef } from '@cds/ng-web-components/popup';

@Component({
  selector: 'app-advise-empfref',
  templateUrl: './advise-empfref.component.html',
  styleUrls: ['./advise-empfref.component.scss'],
})
export class AdviseEmpfrefComponent implements OnInit {
  clearButton: CdsButtonConfig = {
    size: 'sm',
    style: 'primary',
    disabled: false,
  };

  constructor(private dialogRef: MatDialogRef<AdviseEmpfrefComponent>) {}

  ngOnInit(): void {}

  backTosummary() {
    this.dialogRef.close({ agree: true });
  }
}
