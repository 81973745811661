import Swal from 'sweetalert2';
export const MessageTypes = {
  error: 'Error',
  info: 'Info',
  failure: 'Failure',
  success: 'Success',
  warning: 'Warning',
  question: 'Question',
};

export class Alerts {
  public static showErrorMessage(message: string, title?: string) {
    return Swal({
      title: title != null ? title : MessageTypes.error,
      text: message,
      type: 'error',
    });
  }

  public static showSuccessMessage(message: string, title?: string) {
    return Swal({
      title: title != null ? title : MessageTypes.success,
      text: message,
      type: 'success',
    });
  }

  public static showMessage(message: string, title?: string) {
    return Swal({
      title: title != null ? title : '',
      text: message,
    });
  }

  public static showInfoMessage(message: string, title?: string) {
    return Swal({
      title: title != null ? title : MessageTypes.info,
      text: message,
      type: 'info',
    });
  }

  public static showWarningMessage(message: string, showCancelButton: boolean, confirmButtonText: string, cancelButtonText: string, title?: string) {
    return Swal({
      title: title != null ? title : MessageTypes.warning,
      text: message,
      type: 'warning',
      showCancelButton: showCancelButton,
      confirmButtonText: confirmButtonText,
      cancelButtonText: cancelButtonText,
    });
  }

  public static showConfirmationMessage(confirmMessage?: string, confirmButtonText?: string, title?: string) {
    return Swal({
      title: confirmMessage != null ? confirmMessage : 'Are you sure to delete the record?',
      text: title,
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: confirmButtonText != null ? confirmButtonText : 'Delete',
      allowOutsideClick: false,
    });
  }

  public static showInfoMessageWithConfirmation(title?: string, html?: any, cancelButton?: boolean, confirmButtonText?: string) {
    return Swal({
      title: title != null ? title : '',
      html: html,
      type: 'info',
      showCancelButton: cancelButton,
      confirmButtonText: confirmButtonText ? confirmButtonText : 'OK',
      allowOutsideClick: false,
    });
  }

  public static showDeletedSuccessfully(message?: string, title?: string) {
    return Swal({
      title: title != null ? title : 'Deleted!',
      text: message != null ? message : 'Your record has been deleted.',
      type: 'success',
    });
  }
  public static showQustionMessage(message: string, showCancelButton: boolean, confirmButtonText: string, cancelButtonText: string, title?: string) {
    return Swal({
      title: title != null ? title : MessageTypes.question,
      text: message,
      type: 'question',
      showCancelButton: showCancelButton,
      confirmButtonText: confirmButtonText,
      cancelButtonText: cancelButtonText,
    });
  }
}
