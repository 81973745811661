/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Component,
  ContentChildren,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  OnChanges,
  AfterViewInit,
  OnDestroy,
  Output,
  QueryList,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { debounceTime, fromEvent, Subscription } from 'rxjs';

import { DataTableService } from './data-table.service';
import { DtHeaderCellDefDirective, DtBodyCellDefDirective } from './data-table.component.directive';

import { IPaginationCount } from '../pagination/pagination.dto';
import { ColumnConfig, PageConfig, SortMode } from './data-table.model';

@Component({
  selector: 'app-data-table',
  providers: [DataTableService],
  styleUrls: ['./data-table.component.scss'],
  templateUrl: './data-table.component.html',
})
export class DataTableComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  @ViewChild('tablebox', { static: false })
  tablebox!: ElementRef<HTMLElement>;
  subscribeScoll!: Subscription;
  public sortMode = SortMode;
  public displayedColumns: string[] = [];
  public activeClounm: ColumnConfig | null = null;
  public pageCount: IPaginationCount = { current: 1, count: 1 };
  public pageSize = 5;
  public totalNum = 0;
  public tempData: Array<any> = [];
  public disPlayData: Array<any> = [];

  @Input() isLoading = false;
  @Input() fixed = false;
  @Input() overlay = false;
  @Input() pageConfig: PageConfig = {
    current: 1,
    pageSize: 5,
    totalCount: 0,
  };
  @Input() isShowPagination = true;
  @Input() frontPagination = true;
  @Input() dataSource: Array<any> = [];
  @Input() columnsConfig: Array<ColumnConfig> = [];
  @Output() reloadTable = new EventEmitter<any>();
  @Output() frontReloadTable = new EventEmitter<any>();
  @ContentChildren(DtHeaderCellDefDirective)
  headerCellRefs!: QueryList<DtHeaderCellDefDirective>;
  @ContentChildren(DtBodyCellDefDirective)
  bodyCellRefs!: QueryList<DtBodyCellDefDirective>;

  constructor(private dataTableService: DataTableService) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['frontPagination']) {
      this.frontPaginationChange(changes['frontPagination'].currentValue);
    }
    if (changes['columnsConfig']) {
      this.setColumns(changes['columnsConfig'].currentValue);
    }
    if (changes['pageConfig']) {
      this.pageConfigChange(changes['pageConfig'].currentValue);
    }
    if (changes['dataSource']) {
      this.sourceDataChange(changes['dataSource'].currentValue);
    }
  }

  get tempBodyCellRefs() {
    const aObj: any = {};
    if (this.bodyCellRefs && this.bodyCellRefs.length) {
      this.bodyCellRefs.forEach(template => {
        const key: string = template.appDtBodyCellDef;
        aObj[key] = template;
      });
    }
    return aObj;
  }

  get tempHeaderCellRefs() {
    const aObj: any = {};
    if (this.headerCellRefs && this.headerCellRefs.length) {
      this.headerCellRefs.forEach(template => {
        const key: string = template.appDtHeaderCellDef;
        aObj[key] = template;
      });
    }
    return aObj;
  }

  frontPaginationChange(frontPagination: boolean) {
    this.frontPagination = frontPagination;
  }

  setColumns(columnsConfig: Array<ColumnConfig>): void {
    this.displayedColumns = [];
    columnsConfig.forEach((item: ColumnConfig) => {
      if (!this.displayedColumns.includes(item.key)) {
        this.displayedColumns.push(item.key);
      }
    });
  }

  pageConfigChange(pageConfig: PageConfig) {
    if (!this.frontPagination && this.dataTableService.validateTotalCount(pageConfig.totalCount)) {
      this.pageCount.current = 1;
      // this.activeClounm = null;
      // this.initColumnsConfig();
      this.setTotalNum(pageConfig.totalCount as number);
    }
    if (this.dataTableService.validatePage(pageConfig.current)) {
      this.pageCount.current = pageConfig.current as number;
    }
    if (this.dataTableService.validatePage(pageConfig.pageSize)) {
      this.pageSize = pageConfig.pageSize as number;
    }
    this.setPagination(this.pageCount.current, this.pageSize, this.totalNum);
    this.setTable();
  }

  setTotalNum(num: number) {
    this.totalNum = num;
  }

  setPageSize(num: number) {
    this.pageSize = num;
  }

  setPagination(page: number, pageSize: number, total: number): void {
    this.pageCount = {
      current: page,
      count: Math.ceil(total / pageSize),
    };
  }

  sourceDataChange(newData: Array<any>): void {
    if (this.frontPagination) {
      // this.activeClounm = null;
      // this.initColumnsConfig();
      this.pageCount.current = 1;
      this.setTotalNum(newData.length);
      this.setPagination(this.pageCount.current, this.pageSize, this.totalNum);
    }
    // this.checkAndcreateColumn();
    this.sort();
    this.setTable();
  }

  // checkAndcreateColumn() {
  //   if (this.displayedColumns && this.displayedColumns.length) {
  //     this.dataSource.forEach(item => {
  //       this.displayedColumns.forEach(key => {
  //         if (this.dataTableService.isUndefined(item[key])) {
  //           const keys = key.split('+');
  //           let str = '';
  //           keys.forEach(a => {
  //             if (!this.dataTableService.isUndefined(item[a])) {
  //               str += item[a];
  //             }
  //           });
  //           if (str !== '') {
  //             item[key] = str;
  //           }
  //         }
  //       });
  //     });
  //   }
  // }

  setTable(): void {
    if (this.frontPagination) {
      if (this.isShowPagination) {
        this.disPlayData = this.tempData.slice((this.pageCount.current - 1) * this.pageSize, this.pageCount.current * this.pageSize);
      } else {
        this.disPlayData = [...this.tempData];
      }
    } else {
      // this.tempData = [...this.dataSource];
      this.disPlayData = [...this.tempData];
    }
  }

  sortChange(column: ColumnConfig, sortType?: SortMode) {
    this.initColumnsConfig();
    column.sort = sortType ? sortType : column.sort;
    this.activeClounm = sortType === this.sortMode.DEFAULT ? null : column;

    if (this.frontPagination) {
      this.sort();
      this.setTable();
      return;
    }
    this.reloadTable.emit(this.createParam());
  }

  createParam() {
    const paramOnj: any = {
      pageSize: this.pageSize,
      ...this.pageCount,
    };
    if (this.activeClounm) {
      paramOnj.column = this.activeClounm;
    }
    return paramOnj;
  }

  initColumnsConfig(): void {
    this.columnsConfig.forEach(column => {
      if (column.sort) {
        column.sort = this.sortMode.DEFAULT;
      }
    });
  }

  sort() {
    const tempData = [...this.dataSource];
    if (!this.frontPagination) {
      this.tempData = tempData;
      return;
    }
    if (this.activeClounm) {
      const { key, sort } = this.activeClounm;
      this.tempData = this.dataTableService.sort(tempData, key, sort as string);
    } else {
      this.tempData = tempData;
    }
    this.frontReloadTable.emit(this.tempData);
  }

  pageChange(currentPage: number) {
    this.pageCount.current = currentPage;
    if (this.frontPagination) {
      this.setTable();
      // return;
    }
    this.reloadTable.emit({
      pageSize: this.pageSize,
      ...this.pageCount,
      column: this.activeClounm,
    });
  }

  ngAfterViewInit() {
    this.subscribeScoll = fromEvent(this.tablebox.nativeElement, 'scroll')
      .pipe(debounceTime(50))
      .subscribe((event: any) => {
        if (event.target.scrollLeft) {
          event.target.classList.add('shadow');
        } else {
          event.target.classList.remove('shadow');
        }
      });
  }

  ngOnDestroy() {
    this.subscribeScoll.unsubscribe();
  }
}
