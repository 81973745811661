import { Component, Input, OnInit } from '@angular/core';
import { PermissionCheckedStatus, PermissionDto, PermissionList, RoleAccessTableData } from 'src/app/config/role-access-table.config';
import { PermissionAccess, PermissionItem } from 'src/app/core/models/enum/permission.enum';
import { RolePermissionGroup } from 'src/app/core/models/enum/role.enum';
import { checkPermissionAccess } from 'src/app/utils/permission-utils';

@Component({
  selector: 'app-role-info-access-table',
  templateUrl: './role-info-access-table.component.html',
})
export class RoleInfoAccessTableComponent implements OnInit {
  permissionAccess = PermissionAccess;
  accessColumns: string[] = ['expand', 'permissionName', 'execute', 'edit', 'read', 'noAccess'];
  dataSource = new RoleAccessTableData(PermissionList);
  @Input() readOnly = false;
  @Input() data: Map<PermissionItem, PermissionAccess> = new Map();

  constructor() {}

  ngOnInit(): void {}

  private getGroupStatus(iExpectAccess: PermissionAccess | null, iGroupId: RolePermissionGroup): PermissionCheckedStatus {
    let total = 0;
    let select = 0;
    for (const p of this.dataSource.data.value) {
      if (p.parentId === iGroupId && p.permissionItem) {
        total++;
        const checkpermission = this.data?.get(p.permissionItem);
        if ((!iExpectAccess && !checkpermission) || (!!iExpectAccess && !!checkpermission && checkPermissionAccess(iExpectAccess, checkpermission))) {
          select++;
        }
      }
    }
    if (total == select) {
      return PermissionCheckedStatus.TOTAL;
    } else if (select > 0) {
      return PermissionCheckedStatus.PARTIALLY;
    }
    return PermissionCheckedStatus.NONE;
  }

  isCheck(iExpectAccess: PermissionAccess | null, iPermissionItem: PermissionItem): boolean {
    const checkpermission = this.data?.get(iPermissionItem);
    return (!iExpectAccess && !checkpermission) || (!!iExpectAccess && !!checkpermission && checkPermissionAccess(iExpectAccess, checkpermission));
  }

  isGroupCheck(iExpectAccess: PermissionAccess | null, iGroupId: RolePermissionGroup) {
    return this.getGroupStatus(iExpectAccess, iGroupId) === PermissionCheckedStatus.TOTAL;
  }

  isPartially(iExpectAccess: PermissionAccess | null, iGroupId: RolePermissionGroup): boolean {
    return this.getGroupStatus(iExpectAccess, iGroupId) === PermissionCheckedStatus.PARTIALLY;
  }

  private setPermissionAccess(iExpectAccess: PermissionAccess | null, isChecked: boolean): PermissionAccess | null {
    if (isChecked) {
      if (iExpectAccess == null) {
        return null;
      } else {
        return iExpectAccess;
      }
    } else if (iExpectAccess == PermissionAccess.E) {
      return PermissionAccess.W;
    } else if (iExpectAccess == PermissionAccess.W) {
      return PermissionAccess.R;
    }
    return null;
  }

  changeGroup(isChecked: boolean, iExpectAccess: PermissionAccess | null, row: PermissionDto) {
    const groupAccess = this.setPermissionAccess(iExpectAccess, isChecked);
    console.debug(`groupAccess=${groupAccess}`);
    this.dataSource.data.value.forEach(p => {
      if (p.parentId === row.groupItem && p.permissionItem) {
        if (!groupAccess) {
          this.data.delete(p.permissionItem);
        } else {
          const toPermission = this.data.get(p.permissionItem);
          if (!toPermission || !isChecked || !checkPermissionAccess(groupAccess, toPermission)) {
            this.data.set(p.permissionItem, groupAccess);
          }
        }
      }
    });
  }

  changePermission(isChecked: boolean, iExpectAccess: PermissionAccess | null, row: PermissionDto) {
    if (row.permissionItem) {
      const selectAccess = this.setPermissionAccess(iExpectAccess, isChecked);
      if (!selectAccess) {
        this.data.delete(row?.permissionItem);
      } else {
        this.data.set(row?.permissionItem, selectAccess);
      }
    }
  }

  //hidden permission item by 'expand' status of permission group
  hiddenRow(row: PermissionDto) {
    if (row.expand !== undefined) {
      return false;
    }
    const tableData = this.dataSource.data.value;
    const parentRow = tableData.filter(data => !data.parentId && data.groupItem === row.parentId)[0];
    return !parentRow.expand;
  }
}
