<div class="row l-pt-7">
  <div class="col-xs-12">
    <div class="l-mb-7 inner-html-des" innerHTML="{{ 'notification.audience.htmlDes' | lang }}"></div>

    <div>
      <div class="cds-h2-light">Upload file to manage audience list</div>

      <div class="cds-body2 l-mb-0">Choose Target Audience List</div>

      <div class="audience-area l-mb-6">
        <cds-dropdown
          class="dropdown l-mb-2"
          [formControl]="audienceFormControl"
          (change)="initLoad()"
          [config]="notificationServer.audienceCustomDropdownConfig"
          label="{{ 'notification.audience.upload' | lang }}">
        </cds-dropdown>
        <cds-button class="button l-mb-2" (click)="upload()">
          {{ 'notification.audience.upload' | lang }}
        </cds-button>
      </div>
    </div>

    <!-- page data -->
    <div class="relative">
      <app-loading *ngIf="isLoading" [overlayInPopup]="true"></app-loading>
      <div class="n-operation">
        <div class="n-record">
          <div class="cds-h2-light n-o-des v-center">
            {{ 'notification.record.list' | lang }}
          </div>
          <div class="cds-body1 n-o-record-count l-mb-2">
            {{ 'notification.record.total' | lang }} {{ totalElements }}
            {{ 'notification.record.total.des' | lang }}
          </div>
        </div>
        <div class="cds-body2 light" *ngIf="!!fileName">latest csv file name: “{{ fileName }}”</div>
      </div>
      <div>
        <div class="error-message-area" *ngIf="showProcessingMessage">
          <cds-icon class="l-mr-2" icon="action:warning" [config]="errorMessageIconConfig"></cds-icon>
          <div class="cds-body1-demibold">The uploaded audience list is being processed, please wait.</div>
        </div>
        <ng-container *ngIf="!showProcessingMessage">
          <app-no-result-found *ngIf="dataSource && !dataSource.length; else dataTableData"> </app-no-result-found>

          <ng-template #dataTableData>
            <div style="overflow: auto">
              <cds-table [dataSource]="dataSource" class="table" [class.full]="true">
                <!-- Client ID -->
                <ng-container cdsColumnDef="clientId" sticky>
                  <cds-header-cell *cdsHeaderCellDef> Client ID </cds-header-cell>
                  <cds-cell *cdsCellDef="let element">
                    <div *ngIf="element.clientId !== undefined" class="line-break">
                      {{ element.clientId }}
                    </div>
                    <div *ngIf="element.clientId === undefined" class="space-line"></div>
                  </cds-cell>
                </ng-container>

                <cds-header-row *cdsHeaderRowDef="displayedColumns"></cds-header-row>
                <cds-row class="cds-body1" *cdsRowDef="let row; columns: displayedColumns"></cds-row>
              </cds-table>
            </div>
            <!-- pagenation -->
            <div class="col-xs-12 col-md-12 l-pt-4 l-pb-4 l-d-f l-jc-sb">
              <app-pagination [pageCount]="pageCount" [isLoading]="isLoading" [useOf]="true" (pageChange)="loadTable()"> </app-pagination>
            </div>
          </ng-template>
        </ng-container>
      </div>
    </div>
  </div>
</div>
