/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { CdsButtonConfig } from '@cds/ng-core/button';
import { CdsDropdownConfig } from '@cds/ng-core/dropdown';
import { CdsLangService } from '@cds/ng-core/lang';
import { CdsPopupService, CDS_POPUP_DATA } from '@cds/ng-web-components/popup';
import { DateTime } from 'luxon';
import moment from 'moment';
import { finalize } from 'rxjs';
import { ContinuePopupComponent } from 'src/app/shared/continue-popup/continue-popup.component';
import { dateValidator } from 'src/app/shared/validators/validators';
import { deepCopy } from 'src/app/utils/copy';
import { Activity, ActivityTypeE, activityTypeptions } from '../employer';
import { EmployerService } from '../employer.service';

@Component({
  selector: 'app-edit-completed-activity',
  templateUrl: './edit-completed-activity.component.html',
  styleUrls: ['./edit-completed-activity.component.scss'],
})
export class EditCompletedActivityComponent implements OnInit {
  form: FormGroup = new FormGroup({});

  selectedActivity?: Activity;

  activityTypeDropdownConfig: CdsDropdownConfig = {
    label: 'Activity Type',
    options: activityTypeptions,
    placeholder: 'Select Activity Type',
  };

  descriptionDropdownConfig: CdsDropdownConfig = {
    label: 'Activity Description',
    options: [
      {
        label: 'ER0013  - Employer Seminar (Public Onsite) 001 ',
        value: '1',
      },
      {
        label: 'ER0023 - Employer Seminar (Onsite) 202',
        value: '2',
      },
      {
        label: 'ER0051 - Employer Seminar (Public Virtual) 012',
        value: '3',
      },
    ],
    placeholder: 'Select Activity Type',
  };

  showDatePicker = true;

  displayedColumns = ['activityCode', 'description', 'score'];

  originFormValue: Activity = {
    activityType: '',
    description: '',
    completionDate: '',
  };

  sendFormValue: Activity = {};

  dataSource: Activity[] = [];

  AddButtonConfig: CdsButtonConfig = { size: 'sm' };

  resetButtonConfig: CdsButtonConfig = { size: 'sm', style: 'secondary' };

  requestUrl = 'customer-service/employer/activity/completionSelect';

  requestParams = {
    employerId: this.data.employerId,
    activityType: ActivityTypeE.ALL,
  };

  isLoading = false;

  nowTime = this.getNowTime();

  minTime = this.getMinTime();

  nowDateInStr = DateTime.now().toFormat('dd/MM/yyyy');

  minTimeInStr = DateTime.fromJSDate(this.minTime).toFormat('dd/MM/yyyy');

  get activityType() {
    return this.form.get('activityType') as FormControl;
  }

  get description() {
    return this.form.get('description') as FormControl;
  }

  get completionDate() {
    return this.form.get('completionDate') as FormControl;
  }

  get addDisabled() {
    if (this.dataSource[0]?.activityCode && this.completionDate.valid) {
      return false;
    }
    return true;
  }

  constructor(
    private dialogRef: MatDialogRef<EditCompletedActivityComponent>,
    private cdsPopup: CdsPopupService,
    private langService: CdsLangService,
    private employerService: EmployerService,
    @Inject(CDS_POPUP_DATA)
    public data: {
      employerId: string;
      title: string;
      isEdit: boolean;
      activity: Activity;
    }
  ) {}

  ngOnInit(): void {
    this.initDataSource();
    this.initData();
    this.initForm();

    if (!this.data.isEdit) {
      this.description.disable();
    }
  }

  initData() {
    if (this.data.isEdit) {
      this.data.activity.createdAt = this.data.activity.completionDate;
      this.dataSource = [deepCopy(this.data.activity)];

      this.sendFormValue = {
        activityType: this.data.activity.activityType,
        description: this.data.activity.description,
        completionDate: moment(this.data.activity.completionDate).format('DD/MM/YYYY'),
      };

      this.originFormValue = deepCopy(this.sendFormValue);
    }
  }

  initDataSource() {
    this.dataSource = [
      {
        activityType: '',
        description: '',
        score: undefined,
        createdAt: '',
      },
    ];
  }

  initForm() {
    this.form = new FormGroup({
      activityType: new FormControl(''),
      description: new FormControl(''),
      completionDate: new FormControl('', [Validators.required, dateValidator('create.dateError', this.minTimeInStr, this.nowDateInStr)]),
    });

    if (this.data.isEdit) {
      setTimeout(() => {
        this.restore();
      });
    }

    // this.formChangeListener();
  }

  getMinTime() {
    return moment().subtract(5, 'year').toDate();
  }

  restore() {
    this.form.patchValue(this.originFormValue);
    if (!this.data.isEdit) {
      this.initDataSource();
    } else {
      this.dataSource = [deepCopy(this.data.activity)];
    }
    this.form.markAsPristine();
    this.form.markAsUntouched();
  }

  formChangeListener() {
    this.form.valueChanges.subscribe(() => {
      this.getSendFormValue();
      // const noFormChanged = Object.keys(this.sendFormValue).length === 0;
      // const haveEmptyString = Object.keys(this.form.value).some(key => this.form.value[key] === '');
      // this.addDisabled = noFormChanged || haveEmptyString;
      // this.resetDisabled = noFormChanged;
    });
  }

  getSendFormValue() {
    const value: Activity = {};
    Object.keys(this.originFormValue).forEach(key => {
      const currentFromValue: Activity = this.form.value;
      if ((currentFromValue as any)[key] !== (this.originFormValue as any)[key]) {
        (value as any)[key] = (currentFromValue as any)[key];
      }
    });
    this.sendFormValue = value;
  }

  reset() {
    if (!this.form.dirty) {
      return;
    }
    this.cdsPopup
      .open(ContinuePopupComponent, {
        size: 'sm',
        data: { message: this.langService.translate('common.action.cancel') },
      })
      .afterClosed()
      .subscribe(result => {
        if (result?.agree) {
          this.restore();
          if (!this.data.isEdit) {
            this.description.disable();
          }
          this.form.markAsUntouched();
          this.form.markAsPristine();
          this.showDatePicker = false;
          setTimeout(() => {
            this.showDatePicker = true;
          });
        }
      });
  }

  check() {
    const params: Activity = {
      employerId: this.data.employerId,
      activityId: this.selectedActivity?.id || this.data.activity.activityId,
      completionDate: this.dateTrans(this.completionDate.value),
    };
    this.isLoading = true;

    this.employerService
      .checkActivity(params)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe(res => {
        if (res.data === true) {
          this.cdsPopup
            .open(ContinuePopupComponent, {
              size: 'sm',
              data: {
                title: this.langService.translate('employer.er-activity-same-confirm'),
                cancelButtonName: this.langService.translate('confirm.backBotton'),
              },
            })
            .afterClosed()
            .subscribe(result => {
              if (result?.agree) {
                this.doAdd();
              }
            });
        } else {
          this.doAdd();
        }
      });
  }

  add() {
    if (this.addDisabled || !this.form.dirty) {
      return;
    }

    this.check();
  }

  doAdd() {
    this.dialogRef.close({
      agree: true,
      data: {
        employerId: this.data.employerId,
        activityId: this.selectedActivity?.id || this.data.activity.activityId,
        completionId: this.data.activity?.id,
        completionDate: this.dateTrans(this.completionDate.value),
      },
    });
  }

  activityTypeChange(value: ActivityTypeE) {
    if (value) {
      this.description.enable();
      this.form.patchValue({
        description: '',
        //completionDate: '',
      });
      this.initDataSource();
      this.requestParams = {
        employerId: this.data.employerId,
        activityType: value,
      };
      setTimeout(() => {
        this.description?.markAsUntouched();
        this.description?.markAsPristine();
      });
    }
  }

  selectChange(data: Activity) {
    this.selectedActivity = data;
    this.dataSource = [data];
  }

  dateTrans(date: string) {
    if (date) {
      return moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD');
    }

    return date;
  }

  labelRenderFn(data: Activity) {
    return `${data.activityCode} - ${data.description}`;
  }

  getNowTime() {
    return moment().toDate();
  }
  close() {
    if (this.form.dirty) {
      this.cdsPopup
        .open(ContinuePopupComponent, {
          size: 'sm',
          data: { message: this.langService.translate('common.action.cancel') },
        })
        .afterClosed()
        .subscribe(result => {
          if (result?.agree) {
            this.dialogRef.close();
          }
        });
    } else {
      this.dialogRef.close();
    }
  }
}
