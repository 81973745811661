import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { CdsButtonConfig } from '@cds/ng-core/button';

@Component({
  selector: 'app-e-mpf-ref-no-used',
  templateUrl: './e-mpf-ref-no-used.component.html',
  styleUrls: ['./e-mpf-ref-no-used.component.scss'],
})
export class EMPFRefNoUsedComponent implements OnInit {
  disagreeButtonConfig: CdsButtonConfig = {
    size: 'md',
    style: 'primary',
    disabled: false,
  };

  constructor(private dialogRef: MatDialogRef<EMPFRefNoUsedComponent>) {}

  ngOnInit(): void {}

  returnToEdit() {
    this.dialogRef.close({ agree: true });
  }
}
