<div class="row">
  <!-- page menu -->
  <div class="col-xs-12 l-mt-7 l-mb-2">
    <span class="cds-body1"
      >Lorem ipsum dolor sit amet. Qui autem impedit est tenetur reiciendis et sunt rerum qui quisquam animi et repudiandae rerum ab cumque dolore ut accusamus
      ipsum. Doloribus aliquid quo delectus atque aut corporis eius eum maiores optio in autem consequatur quo quidem officiis. Est nisis delectus et laboriosam
      galisum est nihil tempore quo beatae quibusdam. 33 velit omnis in dignissimos saepe aut atque consectetur et dolores mollitia in tenetur galisum nam illo
      eveniet!
    </span>
  </div>
  <div class="col-xs-12 col-md-12 l-pb-3 l-d-f l-jc-sb l-mt-7">
    <div>
      <div class="cds-h2-light l-ma-0">{{ 'common.recordList' | lang }}</div>
      <div class="cds-detail1-demibold">Total {{ totalElements }} records</div>
    </div>
    <div *ngIf="canEdit">
      <cds-button [config]="btnCfg" size="sm" (click)="add()" [disabled]="isLoading">
        <div class="button-area2">
          <div class="div-icon">+</div>
          <span class="l-ml-2">Add New Record</span>
        </div>
      </cds-button>
    </div>
  </div>
  <!-- page data -->
  <div class="col-xs-12 col-md-12">
    <app-loading *ngIf="isLoading" [overlay]="true"></app-loading>
    <div>
      <div class="table-container">
        <div class="table">
          <cds-table
            [dataSource]="dataSource"
            [class.full]="true"
            [cdsSortActive]="sort.active"
            [cdsSortDirection]="sort.direction"
            cdsSort
            (cdsSortChange)="onSortChange($event)">
            <!-- Trustee Employer Account Code -->
            <ng-container cdsColumnDef="trusteeEmployerAccountNumber">
              <cds-header-cell *cdsHeaderCellDef cds-sort-header>Employer Account Code and Name</cds-header-cell>
              <cds-cell *cdsCellDef="let element" class="line-break">
                <div class="code-and-name">
                  <div class="code">{{ element.trusteeEmployerAccountNumber }}</div>
                  <div class="name">{{ element.companyName }}</div>
                </div>
              </cds-cell>
            </ng-container>
            <!-- Agent Code -->
            <ng-container cdsColumnDef="agent">
              <cds-header-cell *cdsHeaderCellDef> Agent Code and Name </cds-header-cell>
              <cds-cell *cdsCellDef="let element" class="line-break">
                <div class="code-and-name">
                  <div class="code">{{ element.agent }}</div>
                  <div class="name" *ngIf="element.agentName">{{ element.agentName }}</div>
                  <div class="chineseName">{{ element.agentChineseName }}</div>
                </div>
              </cds-cell>
            </ng-container>
            <!--remraks-->
            <ng-container cdsColumnDef="remarks">
              <cds-header-cell *cdsHeaderCellDef> Remarks </cds-header-cell>
              <cds-cell *cdsCellDef="let element" class="line-break">
                {{ element.remarks || '/' }}
              </cds-cell>
            </ng-container>
            <!-- Action -->
            <ng-container cdsColumnDef="action">
              <cds-header-cell *cdsHeaderCellDef> Action </cds-header-cell>
              <cds-cell *cdsCellDef="let element">
                <div class="button-area" (click)="edit(element)">
                  <div class="icon-size">
                    <cds-icon size="sm" icon="action:edit"></cds-icon>
                  </div>
                  <span class="cds-h6-demibold l-pl-2">Edit</span>
                </div>
              </cds-cell>
            </ng-container>

            <cds-header-row *cdsHeaderRowDef="displayedColumns"></cds-header-row>
            <cds-row class="cds-body1" *cdsRowDef="let row; columns: displayedColumns"></cds-row>
          </cds-table>
        </div>
      </div>

      <div class="no-result-container" *ngIf="dataSource.length === 0; else paginationTemplate">
        <app-no-result-found> </app-no-result-found>
      </div>

      <!-- pagenation -->
      <ng-template #paginationTemplate>
        <div class="col-xs-12 col-md-12 l-pt-4 l-pb-4 l-d-f l-jc-sb">
          <app-pagination [pageCount]="pageCount" [isLoading]="isLoading" [useOf]="true" (pageChange)="loadTable()"> </app-pagination>
        </div>
      </ng-template>
    </div>
  </div>
</div>
