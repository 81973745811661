import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

import { CDS_POPUP_DATA } from '@cds/ng-web-components/popup';
import { BranchAgentResponse } from './branch-agent-response';

@Component({
  selector: 'app-agent-branch-pop',
  templateUrl: './agent-branch-pop.component.html',
  styleUrls: ['./agent-branch-pop.component.scss'],
})
export class AgentBranchPopComponent implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<AgentBranchPopComponent>,
    @Inject(CDS_POPUP_DATA)
    public data: {
      list: BranchAgentResponse[];
      language: string;
    }
  ) {
    this.filterRepeatData();
  }

  private filterRepeatData() {
    const agentCodeRecord: string[] = [];

    this.data.list = this.data.list.filter(item => {
      const need = item.agentCode !== undefined && !agentCodeRecord.includes(item.agentCode);
      if (need && item.agentCode !== undefined) {
        agentCodeRecord.push(item.agentCode);
      }
      return need;
    });
  }

  ngOnInit(): void {}

  agree() {
    this.dialogRef.close({ agree: true });
  }
  disagree() {
    this.dialogRef.close({ agree: false });
  }
}
