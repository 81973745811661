<div class="individuals">
  <div class="head-box">
    <div class="back-box">
      <cds-icon [ngClass]="[isConfirming ? 'disabled' : '']" (click)="onBack()" [config]="backIconConfig" [icon]="backIcon"> </cds-icon>
      <div class="back-title" [ngClass]="[isConfirming ? 'disabled' : '']">
        <span (click)="onBack()" class="cds-body2-demibold">Choose Product Type</span>
      </div>
    </div>
    <span class="head">IFF Paper Form</span>
  </div>

  <form [formGroup]="individualsForm">
    <div class="content-box">
      <div class="l-d-f l-ai-cen">
        <cds-icon style="width: 40px; height: 40px; --cds-icon-color: var(--cds-bg-ml)" icon="people:login_1"></cds-icon>
        <div class="l-ml-4">
          <span class="cds-h2-demibold" style="color: var(--cds-bg-ml)">Individuals</span>
        </div>
      </div>

      <div class="information-list-box">
        <div class="information-box">
          <div>
            <span class="cds-body-hero-light">eMPF Information</span>
          </div>
          <div class="item-list-box">
            <div class="item-box">
              <div style="width: 270px; line-height: 33px">
                <span class="cds-body1-demibold">Product type</span>
              </div>
              <div *ngIf="isConfirming" style="max-width: 265px; min-width: 265px; line-height: 33px">
                <span class="cds-body1">{{ individualsForm.get('productType')?.value }}</span>
              </div>
              <div *ngIf="!isConfirming" style="max-width: 265px; min-width: 265px">
                <app-cd-dropdown
                  [placeholder]="'Select Product Type'"
                  [group]="individualsForm"
                  controlName="productType"
                  [toppingList]="prodTypeDropdownConfig.options"></app-cd-dropdown>
                <cds-assistive-text *ngIf="individualsForm.get('productType')?.dirty && individualsForm.get('productType')?.hasError('required')" class="l-mt-1"
                  >Please select the product type</cds-assistive-text
                >
              </div>
            </div>
            <div class="item-box">
              <div style="width: 270px">
                <span class="cds-body1-demibold">eMPF Reference No.</span>
              </div>
              <div *ngIf="isConfirming" class="e-reference-no-width">
                <span class="cds-body1">{{ individualsForm.get('eMPFReferenceNo')?.value }}</span>
              </div>
              <div *ngIf="!isConfirming" class="offsets e-reference-no-width">
                <div class="l-d-f l-ai-cen">
                  <app-cd-input
                    class="e-reference-no-width"
                    (valueChange)="eMPFReferenceNoChange($event)"
                    [group]="individualsForm"
                    [controlName]="'eMPFReferenceNo'"
                    [placeholder]="'input eMPF Reference No.'"></app-cd-input>
                  <cds-icon (click)="handleIconClick()" class="input-e-reference-no" icon="action:info_1"></cds-icon>
                </div>
                <cds-assistive-text
                  *ngIf="individualsForm.get('eMPFReferenceNo')?.dirty && individualsForm.get('eMPFReferenceNo')?.hasError('required')"
                  class="l-mt-1"
                  >Please input the eMPF Reference No.</cds-assistive-text
                >
                <cds-assistive-text
                  *ngIf="individualsForm.get('eMPFReferenceNo')?.dirty && individualsForm.get('eMPFReferenceNo')?.hasError('haveExisted')"
                  class="l-mt-1"
                  >eMPF Ref. No. is being used, please verify and input a valid eMPF Ref. No.</cds-assistive-text
                >
              </div>
            </div>
            <div class="item-box">
              <div style="width: 270px">
                <span class="cds-body1-demibold">Paper IFF Agent Submission Date</span>
              </div>
              <div *ngIf="isConfirming" style="max-width: 265px; min-width: 265px">
                <span class="cds-body1">{{ individualsForm.get('paperIFFAgentSubmissionDateFrom')?.value }}</span>
              </div>
              <div *ngIf="!isConfirming && !resetDatePicker" class="offsets" style="max-width: 265px; min-width: 265px">
                <cds-textfield
                  class="paper-agent-submission-date"
                  type="text"
                  formControlName="paperIFFAgentSubmissionDateFrom"
                  placeholder="Paper IFF Agent Submission Date"
                  [cdsDatepicker]="pickerFromDate"
                  (click)="openPaperIFFAgentSubmissionFromDate()"
                  [max]="now"></cds-textfield>
                <cds-datepicker #pickerFromDate="cdsDatepicker"></cds-datepicker>
                <cds-assistive-text
                  *ngIf="
                    individualsForm.get('paperIFFAgentSubmissionDateFrom')?.dirty &&
                    individualsForm.get('paperIFFAgentSubmissionDateFrom')?.hasError('required')
                  "
                  class="l-mt-3"
                  >Please input the paper IFF agent submission date</cds-assistive-text
                >
                <cds-assistive-text
                  *ngIf="
                    individualsForm.get('paperIFFAgentSubmissionDateFrom')?.dirty &&
                    individualsForm.get('paperIFFAgentSubmissionDateFrom')?.hasError('formatError')
                  "
                  class="l-mt-3"
                  >{{ 'common.invalidDate' | lang }}</cds-assistive-text
                >
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="information-list-box">
        <div class="information-box">
          <div>
            <span class="cds-body-hero-light">Customer’s Information</span>
          </div>
          <div class="item-list-box">
            <div *ngIf="!(isConfirming && !customerEnglishSurname && !customerEnglishGivenName)" class="item-box">
              <div style="width: 270px">
                <span class="cds-body1-demibold">English Name :</span>
              </div>
              <div *ngIf="isConfirming" style="flex: 1">
                <span class="cds-body1">{{ customerEnglishSurname }} {{ customerEnglishGivenName }}</span>
              </div>
              <div *ngIf="!isConfirming" class="offsets" style="flex: 1">
                <div class="l-d-f">
                  <div style="max-width: 180px; min-width: 180px">
                    <app-cd-input
                      [isHasErrors]="isCustomerEnglishError && customerEnglishSurname === ''"
                      [(value)]="customerEnglishSurname"
                      [regExpLimit]="'^[a-zA-Z]*$'"
                      placeholder="Surname"></app-cd-input>
                  </div>
                  <div class="l-ml-6" style="max-width: 400px; min-width: 400px">
                    <app-cd-input
                      [isHasErrors]="isCustomerEnglishError && customerEnglishGivenName === ''"
                      [(value)]="customerEnglishGivenName"
                      [regExpLimit]="'^[a-zA-Z]*$'"
                      placeholder="Given Name"></app-cd-input>
                  </div>
                </div>
                <div *ngIf="isCustomerEnglishError" class="l-mt-1">
                  <cds-assistive-text>Please input the customer’s full name</cds-assistive-text>
                </div>
              </div>
            </div>
            <div *ngIf="!(isConfirming && !customerChinaSurname && !customerChinaGivenName)" class="item-box">
              <div style="width: 270px">
                <span class="cds-body1-demibold">Chinese Name : </span>
              </div>
              <div *ngIf="isConfirming" style="flex: 1">
                <span class="cds-body1">{{ customerChinaSurname }} {{ customerChinaGivenName }}</span>
              </div>
              <div *ngIf="!isConfirming" class="offsets" style="flex: 1">
                <div class="l-d-f">
                  <div style="max-width: 180px; min-width: 180px">
                    <app-cd-input
                      [isHasErrors]="isCustomerChinaError && customerChinaSurname === ''"
                      [(value)]="customerChinaSurname"
                      [regExpLimit]="'^[\u4E00-\u9FA5]*$'"
                      placeholder="Surname"></app-cd-input>
                  </div>
                  <div class="l-ml-6" style="max-width: 400px; min-width: 400px">
                    <app-cd-input
                      [isHasErrors]="isCustomerChinaError && customerChinaGivenName === ''"
                      [(value)]="customerChinaGivenName"
                      [regExpLimit]="'^[\u4E00-\u9FA5]*$'"
                      placeholder="Given Name"></app-cd-input>
                  </div>
                </div>
                <div *ngIf="isCustomerChinaError" class="l-mt-1">
                  <cds-assistive-text>Please input the customer’s full name</cds-assistive-text>
                </div>
              </div>
            </div>
            <div class="item-box">
              <div style="width: 270px">
                <span class="cds-body1-demibold">HKID/ Passport No.<b style="color: var(--cds-bg-cta-hover)">*</b> :</span>
              </div>
              <div *ngIf="isConfirming" class="l-d-f" style="flex: 1">
                <span class="cds-body1 l-mb-0"
                  >({{ individualsForm.get('idType')?.value === '0' ? 'HKID' : 'Passport No.' }}) {{ individualsForm.get('hkidOrPassportNo')?.value }}</span
                >
              </div>
              <div *ngIf="!isConfirming" class="l-d-f" style="flex: 1">
                <div class="offsets" style="max-width: 180px; min-width: 180px">
                  <app-cd-dropdown
                    [placeholder]="'iff.pleaseSelectIDType'"
                    [group]="individualsForm"
                    [controlName]="'idType'"
                    (selectedChange)="hKIDPassportNoChange()"
                    [toppingList]="idTypeConfig.options"></app-cd-dropdown>
                </div>
                <div class="l-ml-6 offsets" style="max-width: 400px; min-width: 400px">
                  <app-cd-input
                    [group]="individualsForm"
                    controlName="hkidOrPassportNo"
                    [placeholder]="'iff.pleaseInputHKIDPassportNo' | lang"
                    (valueChange)="hkidOrPassportNoChange($event)"></app-cd-input>
                  <div class="l-mt-3" *ngIf="individualsForm.get('hkidOrPassportNo')?.dirty && individualsForm.get('hkidOrPassportNo')?.hasError('required')">
                    <cds-assistive-text type="error">
                      <span>
                        {{ 'iff.pleaseInputHKIDPassportNo' | lang }}
                      </span>
                    </cds-assistive-text>
                  </div>
                  <div
                    class="l-mt-3"
                    *ngIf="individualsForm.get('hkidOrPassportNo')?.dirty && individualsForm.get('hkidOrPassportNo')?.hasError('formatError')">
                    <cds-assistive-text type="error">
                      <span>
                        {{ 'common.invalid' | lang : { p1: 'case-details.HKIDPassportNo' | lang } }}
                      </span>
                    </cds-assistive-text>
                  </div>
                </div>
              </div>
            </div>
            <div class="item-box">
              <div style="width: 270px">
                <span class="cds-body1-demibold">Client Contact Information<b style="color: var(--cds-bg-cta-hover)">*</b> :</span>
              </div>
              <div *ngIf="isConfirming">
                <span *ngIf="clientContact === 'E'" class="cds-body1">{{ individualsForm.get('clientEmailAddress')?.value }}</span>
                <span *ngIf="clientContact === 'M'" class="cds-body1"
                  >(+{{ individualsForm.get('clientCountryCode')?.value }}) {{ individualsForm.get('clientPhoneNumber')?.value }}</span
                >
              </div>
              <div *ngIf="!isConfirming">
                <div>
                  <app-cd-radio
                    class="cd-radio-margin-bottom-clear"
                    [(value)]="clientContact"
                    [config]="clientContactConfig"
                    (valueChange)="clientContactChange($event)"></app-cd-radio>
                  <div>
                    <cds-assistive-text class="l-mt-4" *ngIf="isTryConfirm && !clientContact">Please choose client contact information</cds-assistive-text>
                  </div>
                </div>
                <div>
                  <div *ngIf="clientContact === 'E'" class="l-mt-5 l-mb-3">
                    <cds-textfield ngDefaultControl [placeholder]="'common.email-address' | lang" formControlName="clientEmailAddress"></cds-textfield>
                    <cds-assistive-text
                      class="l-mt-4"
                      *ngIf="individualsForm.get('clientEmailAddress')?.dirty && individualsForm.get('clientEmailAddress')?.hasError('email')"
                      >{{ 'common.please-input-valid-customer-email' | lang }}</cds-assistive-text
                    >
                    <cds-assistive-text
                      class="l-mt-4"
                      *ngIf="individualsForm.get('clientEmailAddress')?.dirty && individualsForm.get('clientEmailAddress')?.hasError('required')"
                      >{{ 'common.please-input-customer-email' | lang }}</cds-assistive-text
                    >
                  </div>
                  <div *ngIf="clientContact === 'M'" class="l-d-f l-mt-5">
                    <cds-dropdown
                      formControlName="clientCountryCode"
                      [config]="countrycodeConfig"
                      (ngModelChange)="clientCountryCodeChange()"
                      ngDefaultControl></cds-dropdown>
                    <div>
                      <div class="l-ml-4" style="position: relative; top: -1px; width: 230px">
                        <app-cd-input
                          [type]="'number'"
                          ngDefaultControl
                          placeholder="Please input Client Phone Number"
                          [group]="individualsForm"
                          controlName="clientPhoneNumber"></app-cd-input>
                      </div>
                      <div class="l-ml-4 l-mt-1">
                        <cds-assistive-text
                          *ngIf="individualsForm.get('clientPhoneNumber')?.dirty && individualsForm.get('clientPhoneNumber')?.hasError('errorPhoneNumber')">
                          <span>
                            {{ 'common.please-input-valid-mobile-number' | lang }}
                          </span>
                        </cds-assistive-text>
                      </div>
                      <div class="l-ml-4 l-mt-1">
                        <cds-assistive-text
                          *ngIf="individualsForm.get('clientPhoneNumber')?.dirty && individualsForm.get('clientPhoneNumber')?.hasError('required')">
                          <span>
                            {{ 'common.please-input-phone-number' | lang }}
                          </span>
                        </cds-assistive-text>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="item-box" *ngIf="!(isConfirming && !individualsForm.get('preferredLanguage')?.value)">
              <div *ngIf="clientContact === 'M'" style="width: 270px">
                <span class="cds-body1-demibold">{{ 'sendIffInBatchSummary.preferredLanguage' | lang }}:</span>
              </div>
              <div *ngIf="isConfirming" style="flex: 1">
                <span class="cds-body1">{{ individualsForm.get('preferredLanguage')?.value }}</span>
              </div>
              <div *ngIf="!isConfirming && clientContact === 'M'" style="flex: 1">
                <app-cd-radio
                  class="cd-radio-margin-bottom-clear"
                  #cdRadioPreferredLanguage
                  (valueChange)="individualsForm.get('preferredLanguage')?.setValue($event)"
                  [value]="individualsForm.get('preferredLanguage')?.value"
                  [config]="preferredLanguageConfig"></app-cd-radio>
                <div
                  *ngIf="individualsForm.get('preferredLanguage')?.invalid && individualsForm.get('preferredLanguage')?.touched"
                  style="margin-top: var(--cds-spacing-01)">
                  <cds-assistive-text type="error"
                    >{{ 'common.plsSelect' | lang : { p1: 'sendIffInBatchSummary.preferredLanguage' | lang } }}
                  </cds-assistive-text>
                </div>
              </div>
            </div>
            <div class="item-box">
              <div>
                <span class="cds-detail2" style="color: var(--cds-color-dark-1-coral)">*請確保資料與客戶在eMPF上提供的資料一致</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="information-list-box">
        <div class="information-box">
          <div>
            <span class="cds-body-hero-light">Special Needs</span>
          </div>
          <div class="item-list-box">
            <div class="item-box">
              <span class="cds-body1-demibold" style="color: #000000; margin-bottom: 0"
                >Please confirm whether you are a client with Special Needs by checking the appropriate box below. </span
              >
            </div>
            <div *ngIf="isConfirming" class="item-box">
              <span class="cds-body1">{{
                confirmSpecialNeedsValue === 'y'
                  ? 'Yes, I have special needs.'
                  : 'No, I do not have special needs and this confirmation shall override any previous confirmation and/or any information about me, which may indicate otherwise.'
              }}</span>
            </div>
            <div *ngIf="!isConfirming" class="item-box">
              <app-cd-radio [(value)]="confirmSpecialNeedsValue" [config]="confirmSpecialNeedsConfig" [orientation]="'vertical'"></app-cd-radio>
              <cds-assistive-text class="l-mt-1" *ngIf="isTryConfirm && !confirmSpecialNeedsValue"> Please choose special needs </cds-assistive-text>
            </div>
            <ng-container *ngIf="confirmSpecialNeedsValue === 'y'">
              <div class="l-mt-7" style="margin-top: 40px">
                <span class="cds-body1-demibold" style="color: #000000; margin-bottom: 0"
                  >I have been advised to be accompanied by a companion and/or have an additional member of staff to witness the relevant sales process and
                  constituent fund selection process, and I have chosen:</span
                >
              </div>
              <div *ngIf="isConfirming" class="item-box">
                <span class="cds-body1" *ngIf="advisedSpecialNeedsValue === '1'">not to have any witness</span>
                <span class="cds-body1" *ngIf="advisedSpecialNeedsValue === '2'"
                  >to be accompanied by a companion/ to be accompanied by an additional member of staff as the witness</span
                >
              </div>
              <div *ngIf="!isConfirming" class="item-box l-d-f l-fd-col">
                <app-cd-radio [(value)]="advisedSpecialNeedsValue" [config]="advisedSpecialNeedsConfig" [orientation]="'vertical'"></app-cd-radio>
                <cds-assistive-text class="l-mt-1" *ngIf="isTryConfirm && !advisedSpecialNeedsValue"> Please choose </cds-assistive-text>
              </div>
            </ng-container>
          </div>
        </div>
      </div>

      <div class="information-list-box">
        <div class="information-box">
          <div>
            <span class="cds-body-hero-light">MPF Intermediary’s Information and Acknowledgement </span>
          </div>
          <div class="item-list-box">
            <div class="item-box">
              <div style="width: 270px">
                <span class="cds-body1-demibold">DBS<b style="color: var(--cds-bg-cta-hover)">*</b> :</span>
              </div>
              <div>
                <app-cd-radio
                  class="cd-radio-margin-bottom-clear"
                  #dbsReferredLanguage
                  [(value)]="isDBschannel"
                  [config]="dbsContactConfig"
                  (valueChange)="dbsContractChange($event)">
                </app-cd-radio>
                <div>
                  <cds-assistive-text class="l-mt-4" *ngIf="isTryConfirm && !isDBschannel">Please select DBS</cds-assistive-text>
                </div>
              </div>
            </div>
            <div class="item-box l-mt-0">
              <div style="width: 270px" class="l-mt-5">
                <span class="cds-body1-demibold">Servicing Agent Code</span>
              </div>
              <div *ngIf="isConfirming" class="l-mt-5" style="max-width: 560px; min-width: 265px; width: 560px">
                <span class="cds-body1"
                  >{{ tempServicingAgent?.agentCode }} {{ getAgentName(tempServicingAgent || undefined) }} (District Code:
                  {{ tempServicingAgent?.branchNo }})</span
                >
              </div>
              <div *ngIf="!isConfirming" class="offsets l-mt-5" style="max-width: 560px; min-width: 265px; width: 560px">
                <app-cd-input
                  [group]="individualsForm"
                  controlName="servicingAgentCode"
                  [type]="'number'"
                  maxlength="6"
                  placeholder="Please input agent code"></app-cd-input>
                <cds-assistive-text
                  class="l-mt-3"
                  *ngIf="individualsForm.get('servicingAgentCode')?.dirty && individualsForm.get('servicingAgentCode')?.hasError('required')">
                  Please input agent code
                </cds-assistive-text>
                <cds-assistive-text
                  class="l-mt-3"
                  *ngIf="individualsForm.get('servicingAgentCode')?.dirty && individualsForm.get('servicingAgentCode')?.hasError('unMatch')">
                  Invalid agent code
                </cds-assistive-text>
              </div>
            </div>
            <div class="item-box" style="flex-wrap: unset">
              <cds-checkbox formControlName="isServicingAgentCodePerformed"></cds-checkbox>
              <div class="l-ml-4">
                <span class="cds-body2" style="color: #000000"
                  >I have performed the MPF Intermediary Fulfillment requirements in carrying out the regulated activities with the customer.</span
                >
              </div>
            </div>
            <div [ngStyle]="{ display: isConfirming && individualsForm.get('splitAgentCode')?.value ? 'unset' : 'none' }">
              <div class="item-box l-mt-0">
                <div style="width: 270px" class="l-mt-5">
                  <span class="cds-body1-demibold">Split Agent Code</span>
                </div>
                <div class="l-mt-5" style="max-width: 560px; min-width: 265px; width: 560px">
                  <span class="cds-body1"
                    >{{ tempSplitAgent?.agentCode }} {{ getAgentName(tempSplitAgent || undefined) }} (District Code: {{ tempSplitAgent?.branchNo }})</span
                  >
                </div>
              </div>
              <div class="item-box" style="flex-wrap: unset">
                <cds-checkbox [disabled]="true" [checked]="individualsForm.get('isSplitAgentCodePerformed')?.value"></cds-checkbox>
                <div class="l-ml-4">
                  <span class="cds-body2" style="color: #000000"
                    >I have performed the MPF Intermediary Fulfillment requirements in carrying out the regulated activities with the customer.</span
                  >
                </div>
              </div>
            </div>
            <div [ngStyle]="{ display: !isConfirming ? 'unset' : 'none' }">
              <div class="item-box l-mt-0">
                <div style="width: 270px" class="l-mt-5">
                  <span class="cds-body1-demibold">Split Agent Code</span>
                </div>
                <div class="offsets l-mt-5" style="max-width: 560px; min-width: 265px; width: 560px">
                  <app-cd-input
                    [group]="individualsForm"
                    controlName="splitAgentCode"
                    [disable]="!!individualsForm.get('servicingAgentCode')?.hasError('required')"
                    [type]="'number'"
                    maxlength="6"
                    placeholder="Please input agent code"></app-cd-input>
                  <cds-assistive-text
                    class="l-mt-3"
                    *ngIf="individualsForm.get('splitAgentCode')?.dirty && individualsForm.get('splitAgentCode')?.hasError('unMatch')">
                    Invalid agent code
                  </cds-assistive-text>
                </div>
              </div>
              <div class="item-box" style="flex-wrap: unset">
                <cds-checkbox formControlName="isSplitAgentCodePerformed"></cds-checkbox>
                <div class="l-ml-4">
                  <span class="cds-body2" style="color: #000000"
                    >I have performed the MPF Intermediary Fulfillment requirements in carrying out the regulated activities with the customer.</span
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="information-box" *ngIf="isDBschannel === 'Y'">
            <div class="cds-body-hero-light">dbs</div>
            <div class="item-list-box" style="border: 0px">
              <div class="item-box l-mt-0">
                <div style="width: 270px" class="l-mt-5">
                  <span class="cds-body1-demibold">RM Referral Code:</span>
                </div>
                <div *ngIf="isConfirming" class="l-mt-5" style="max-width: 560px; min-width: 265px; width: 560px">
                  <span class="cds-body1">{{ individualsForm.get('rmReferralCode')?.value }} </span>
                </div>
                <div *ngIf="!isConfirming" class="offsets l-mt-5" style="max-width: 560px; min-width: 265px; width: 560px">
                  <app-cd-input
                    [type]="'number'"
                    [group]="individualsForm"
                    controlName="rmReferralCode"
                    [maxlength]="8"
                    ngDefaultControl
                    [placeholder]="'Input RM Referral Code'"></app-cd-input>

                  <div class="l-mt-1">
                    <cds-assistive-text
                      *ngIf="individualsForm.get('rmReferralCode')?.dirty && individualsForm.get('rmReferralCode')?.hasError('required'); else second"
                      type="error"
                      >Please input valid RM Referral Code
                    </cds-assistive-text>
                    <ng-template #second>
                      <cds-assistive-text
                        *ngIf="individualsForm.get('rmReferralCode')?.dirty && individualsForm.get('rmReferralCode')?.hasError('lengthError')"
                        type="error"
                        >Please input valid RM Referral Code
                      </cds-assistive-text>
                    </ng-template>
                  </div>
                </div>
              </div>

              <div class="item-box l-mt-0">
                <div style="width: 270px" class="l-mt-5">
                  <span class="cds-body1-demibold">Customer ID:</span>
                </div>
                <div *ngIf="isConfirming" class="l-mt-5" style="max-width: 560px; min-width: 265px; width: 560px">
                  <span class="cds-body1">{{ individualsForm.get('inputCustomerId')?.value }} </span>
                </div>
                <div *ngIf="!isConfirming" class="offsets l-mt-5" style="max-width: 560px; min-width: 265px; width: 560px">
                  <app-cd-input
                    [group]="individualsForm"
                    controlName="inputCustomerId"
                    [maxlength]="8"
                    [regExpLimit]="'^[A-Za-z0-9-]*$'"
                    ngDefaultControl
                    [placeholder]="'Input Customer ID (Optional)'"></app-cd-input>
                  <div class="l-mt-1">
                    <cds-assistive-text
                      *ngIf="individualsForm.get('inputCustomerId')?.dirty && individualsForm.get('inputCustomerId')?.hasError('lengthError')"
                      type="error"
                      >Please input valid Customer ID
                    </cds-assistive-text>
                  </div>
                </div>
              </div>

              <div class="item-box l-mt-0">
                <div style="width: 270px" class="l-mt-5">
                  <span class="cds-body1-demibold">Campaign Code:</span>
                </div>
                <div *ngIf="isConfirming" class="l-mt-5" style="max-width: 560px; min-width: 265px; width: 560px">
                  <span class="cds-body1">{{ individualsForm.get('campaignCode')?.value }} </span>
                </div>
                <div *ngIf="!isConfirming" class="offsets l-mt-5" style="max-width: 560px; min-width: 265px; width: 560px">
                  <app-cd-input
                    [group]="individualsForm"
                    controlName="campaignCode"
                    [maxlength]="10"
                    [regExpLimit]="'^[A-Za-z0-9-]*$'"
                    ngDefaultControl
                    [placeholder]="'Input Campaign Code (Optional)'"></app-cd-input>
                  <div class="l-mt-1">
                    <cds-assistive-text
                      *ngIf="individualsForm.get('campaignCode')?.dirty && individualsForm.get('campaignCode')?.hasError('lengthError')"
                      type="error"
                      >Please input valid Campaign Code
                    </cds-assistive-text>
                  </div>
                </div>
              </div>

              <div class="item-box l-mt-0">
                <div style="width: 270px" class="l-mt-5">
                  <span class="cds-body1-demibold">Source Code:</span>
                </div>
                <div *ngIf="isConfirming" class="l-mt-5" style="max-width: 560px; min-width: 265px; width: 560px">
                  <span class="cds-body1">{{ individualsForm.get('sourceCode')?.value }} </span>
                </div>
                <div *ngIf="!isConfirming" class="offsets l-mt-5" style="max-width: 560px; min-width: 265px; width: 560px">
                  <app-cd-input
                    [group]="individualsForm"
                    controlName="sourceCode"
                    [maxlength]="10"
                    [regExpLimit]="'^[A-Za-z0-9-]*$'"
                    ngDefaultControl
                    [placeholder]="'Input Source Code (Optional)'"></app-cd-input>
                  <div class="l-mt-1">
                    <cds-assistive-text
                      *ngIf="individualsForm.get('sourceCode')?.dirty && individualsForm.get('sourceCode')?.hasError('lengthError')"
                      type="error"
                      >Please input valid Source Code
                    </cds-assistive-text>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="!isConfirming" class="l-mt-6 l-d-f">
        <div>
          <cds-button (click)="reset()" [style]="'secondary'">{{ 'common.reset1' | lang }}</cds-button>
        </div>
        <div class="l-ml-4">
          <cds-button (click)="confirm()">{{ 'common.submit' | lang }}</cds-button>
        </div>
      </div>

      <div *ngIf="isConfirming" class="l-mt-6 l-d-f">
        <div>
          <cds-button (click)="backToEdit()" [style]="'secondary'">{{ 'common.backToEdit' | lang }}</cds-button>
        </div>
        <div class="l-ml-4">
          <cds-button (click)="submit()">{{ 'common.confirmAndSubmit' | lang }}</cds-button>
        </div>
      </div>
    </div>
  </form>
</div>
