import { Component, Inject, OnInit } from '@angular/core';

import { CdsSize, CdsStyle } from '@cds/ng-core/configuration';
import { CDS_POPUP_DATA, MatDialogRef } from '@cds/ng-web-components/popup';

interface CdWarningPopupButton {
  style: CdsStyle;
  size: CdsSize;
  text: string;
  cb: () => void;
}

@Component({
  selector: 'app-cd-warning-popup',
  templateUrl: './cd-warning-popup.component.html',
  styleUrls: ['./cd-warning-popup.component.scss'],
})
export class CdWarningPopupComponent implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<CdWarningPopupComponent>,
    @Inject(CDS_POPUP_DATA)
    public data: {
      isShowCloseIcon: boolean;
      title: string;
      buttons: CdWarningPopupButton[];
      messages: string[];
      closeCallBack?: () => void;
    }
  ) {}

  ngOnInit(): void {}

  close() {
    this.dialogRef.close();

    if (this.data.closeCallBack) {
      this.data.closeCallBack();
    }
  }
}
