<div class="wsm-case-summary">
  <div class="head-box">
    <div class="head">
      <span>Worksite Marketing Case Summary Page</span>
    </div>
  </div>
  <div class="content-box">
    <div>
      <span class="cds-body1"
        >Lorem ipsum dolor sit amet. Qui autem impedit est tenetur reiciendis et sunt rerum qui quisquam animi et repudiandae rerum ab cumque dolore ut
        accusamus ipsum. Doloribus aliquid quo delectus atque aut corporis eius eum maiores optio in autem consequatur quo quidem officiis.</span
      >
    </div>

    <div class="l-mt-7">
      <span class="cds-h2-light">Search record</span>
    </div>

    <div class="row l-d-f padding-none">
      <ng-container *ngIf="!isSuperAdmin">
        <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12 search-item">
          <span class="cds-body2" style="color: var(--cds-text-disabled)">{{ 'wsm-case-summary.employer-account-code' | lang }}</span>
          <app-cd-input
            class="text-field"
            [(value)]="requestParams.employerAcctCd"
            [placeholder]="'common.input' | lang : { p1: 'wsm-case-summary.employer-account-code' | lang }"></app-cd-input>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12 search-item">
          <span class="cds-body2" style="color: var(--cds-text-disabled)">{{ 'wsm-case-summary.booking-reference-no' | lang }}</span>
          <app-cd-input
            class="text-field"
            [(value)]="requestParams.bookingRefNo"
            [placeholder]="'common.input' | lang : { p1: 'wsm-case-summary.booking-reference-no' | lang }"></app-cd-input>
        </div>

        <app-date-range-box class="col-lg-6 col-xs-12 l-plr-0">
          <div *ngIf="!resetDatePicker" left>
            <cds-textfield
              class="prodtype-date-align"
              [(ngModel)]="requestParams.expiryDateFrom"
              #expiryDateFromVal
              (ngModelChange)="expiryDateFromValueChange(expiryDateFromVal.value)"
              type="text"
              placeholder="DD/MM/YYYY"
              [label]="'common.date-from' | lang : { p1: 'wsm-case-summary.expiry-date' | lang }"
              [cdsDatepicker]="pickerToDate"
              [max]="expiryDateFromMax"></cds-textfield>
            <cds-datepicker #pickerToDate="cdsDatepicker"></cds-datepicker>
            <cds-assistive-text *ngIf="expiryDateFromValError" class="l-mt-3">{{ 'common.invalidDate' | lang }}</cds-assistive-text>
          </div>
          <div *ngIf="!resetDatePicker" right>
            <cds-textfield
              class="prodtype-date-align"
              [(ngModel)]="requestParams.expiryDateTo"
              #expiryDateToVal
              (ngModelChange)="expiryDateToValueChange(expiryDateToVal.value)"
              type="text"
              placeholder="DD/MM/YYYY"
              [label]="'common.date-to' | lang"
              [cdsDatepicker]="pickerToDate"
              [min]="expiryDateToMin"></cds-textfield>
            <cds-datepicker #pickerToDate="cdsDatepicker"></cds-datepicker>
            <cds-assistive-text *ngIf="expiryDateToValError" class="l-mt-3">{{ 'common.invalidDate' | lang }}</cds-assistive-text>
          </div>
        </app-date-range-box>

        <app-date-range-box class="col-lg-6 col-xs-12 l-plr-0">
          <div *ngIf="!resetDatePicker" left>
            <cds-textfield
              class="prodtype-date-align"
              [(ngModel)]="requestParams.meetingDateFrom"
              #meetingDateFromVal
              (ngModelChange)="meetingDateFromValueChange(meetingDateFromVal.value)"
              type="text"
              placeholder="DD/MM/YYYY"
              [label]="'common.date-from' | lang : { p1: 'wsm-case-summary.meeting-date' | lang }"
              [cdsDatepicker]="pickerToDate"
              [max]="meetingDateFromMax"></cds-textfield>
            <cds-datepicker #pickerToDate="cdsDatepicker"></cds-datepicker>
            <cds-assistive-text *ngIf="meetingDateFromValError" class="l-mt-3">{{ 'common.invalidDate' | lang }}</cds-assistive-text>
          </div>
          <div *ngIf="!resetDatePicker" right>
            <cds-textfield
              class="prodtype-date-align"
              [(ngModel)]="requestParams.meetingDateTo"
              #meetingDateToVal
              (ngModelChange)="meetingDateToValueChange(meetingDateToVal.value)"
              type="text"
              placeholder="DD/MM/YYYY"
              [label]="'common.date-to' | lang"
              [cdsDatepicker]="pickerToDate"
              [min]="meetingDateToMin"></cds-textfield>
            <cds-datepicker #pickerToDate="cdsDatepicker"></cds-datepicker>
            <cds-assistive-text *ngIf="meetingDateToValError" class="l-mt-3">{{ 'common.invalidDate' | lang }}</cds-assistive-text>
          </div>
        </app-date-range-box>

        <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12 search-item bottom-box">
          <cds-button [disabled]="isDisableSearch || isLoading" size="sm" class="l-mr-4" (click)="onClickSearch()">Search</cds-button>
          <cds-button [style]="'secondary'" size="sm" (click)="onClickReset()">Reset</cds-button>
        </div>
      </ng-container>

      <ng-container *ngIf="isSuperAdmin">
        <div class="row l-plr-0 col-xs-12">
          <app-date-range-box class="col-lg-6 col-xs-12 l-plr-0">
            <div *ngIf="!resetDatePicker" left>
              <cds-textfield
                [(ngModel)]="requestParams.meetingDateFrom"
                #meetingDateFromVal
                (ngModelChange)="meetingDateFromValueChange(meetingDateFromVal.value)"
                type="text"
                placeholder="DD/MM/YYYY"
                [label]="'common.date-from' | lang : { p1: 'wsm-case-summary.meeting-date' | lang }"
                [cdsDatepicker]="pickerToDate"
                [max]="meetingDateFromMax"></cds-textfield>
              <cds-datepicker #pickerToDate="cdsDatepicker"></cds-datepicker>
              <cds-assistive-text *ngIf="meetingDateFromValError" class="l-mt-3">{{ 'common.invalidDate' | lang }}</cds-assistive-text>
            </div>
            <div *ngIf="!resetDatePicker" right>
              <cds-textfield
                [(ngModel)]="requestParams.meetingDateTo"
                #meetingDateToVal
                (ngModelChange)="meetingDateToValueChange(meetingDateToVal.value)"
                type="text"
                placeholder="DD/MM/YYYY"
                [label]="'common.date-to' | lang"
                [cdsDatepicker]="pickerToDate"
                [min]="meetingDateToMin"></cds-textfield>
              <cds-datepicker #pickerToDate="cdsDatepicker"></cds-datepicker>
              <cds-assistive-text *ngIf="meetingDateToValError" class="l-mt-3">{{ 'common.invalidDate' | lang }}</cds-assistive-text>
            </div>
          </app-date-range-box>
          <app-date-range-box class="col-lg-6 col-xs-12 l-plr-0">
            <div *ngIf="!resetDatePicker" left>
              <cds-textfield
                [(ngModel)]="requestParams.expiryDateFrom"
                #expiryDateFromVal
                (ngModelChange)="expiryDateFromValueChange(expiryDateFromVal.value)"
                type="text"
                placeholder="DD/MM/YYYY"
                [label]="'common.date-from' | lang : { p1: 'wsm-case-summary.expiry-date' | lang }"
                [cdsDatepicker]="pickerToDate"
                [max]="expiryDateFromMax"></cds-textfield>
              <cds-datepicker #pickerToDate="cdsDatepicker"></cds-datepicker>
              <cds-assistive-text *ngIf="expiryDateFromValError" class="l-mt-3">{{ 'common.invalidDate' | lang }}</cds-assistive-text>
            </div>
            <div *ngIf="!resetDatePicker" right>
              <cds-textfield
                [(ngModel)]="requestParams.expiryDateTo"
                #expiryDateToVal
                (ngModelChange)="expiryDateToValueChange(expiryDateToVal.value)"
                type="text"
                placeholder="DD/MM/YYYY"
                [label]="'common.date-to' | lang"
                [cdsDatepicker]="pickerToDate"
                [min]="expiryDateToMin"></cds-textfield>
              <cds-datepicker #pickerToDate="cdsDatepicker"></cds-datepicker>
              <cds-assistive-text *ngIf="expiryDateToValError" class="l-mt-3">{{ 'common.invalidDate' | lang }}</cds-assistive-text>
            </div>
          </app-date-range-box>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12 search-item">
          <span class="cds-body2" style="color: var(--cds-text-disabled)">{{ 'sales-support-summary.abm' | lang }}</span>
          <app-cd-multidropdown
            [style.width]="'100%'"
            [placeholder]="'common.plsSelect' | lang : { p1: 'sales-support-summary.abm' | lang }"
            [toppingList]="abmOptionList"
            [(selected)]="abmStatusoptionsOrigin"></app-cd-multidropdown>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12 search-item">
          <span class="cds-body2" style="color: var(--cds-text-disabled)">{{ 'wsm-case-summary.employer-account-code' | lang }}</span>
          <app-cd-input
            class="text-field"
            [(value)]="requestParams.employerAcctCd"
            [placeholder]="'common.input' | lang : { p1: 'wsm-case-summary.employer-account-code' | lang }"></app-cd-input>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12 search-item">
          <span class="cds-body2" style="color: var(--cds-text-disabled)">{{ 'wsm-case-summary.branch-code' | lang }}</span>
          <div class="branch-code-input-box">
            <app-cd-input
              class="text-field"
              [(value)]="branchCodeInput"
              [type]="'number'"
              [placeholder]="'common.input' | lang : { p1: 'wsm-case-summary.branch-code' | lang }"></app-cd-input>
            <div (click)="addToSearchAgentCodeList()" class="add-button">
              <span style="color: var(--cds-color-m-green)" class="cds-body1-demibold">Add</span>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12 search-item">
          <span class="cds-body2" style="color: var(--cds-text-disabled)">{{ 'wsm-case-summary.agent-code' | lang }}</span>
          <app-cd-input
            class="text-field"
            [(value)]="requestParams.agentCd"
            [placeholder]="'common.input' | lang : { p1: 'wsm-case-summary.agent-code' | lang }"></app-cd-input>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12 search-item">
          <span class="cds-body2" style="color: var(--cds-text-disabled)">{{ 'wsm-case-summary.booking-reference-no' | lang }}</span>
          <app-cd-input
            class="text-field"
            [(value)]="requestParams.bookingRefNo"
            [placeholder]="'common.input' | lang : { p1: 'wsm-case-summary.booking-reference-no' | lang }"></app-cd-input>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12 search-item bottom-box" style="position: relative; top: 10px">
          <cds-button [disabled]="isDisableSearch || isLoading" size="sm" class="l-mr-4" (click)="onClickSearch()">Search</cds-button>
          <cds-button [style]="'secondary'" size="sm" (click)="onClickReset()">Reset</cds-button>
        </div>
      </ng-container>
    </div>

    <div *ngIf="isSuperAdmin && searchBranchCodeList.length > 0" class="l-d-f l-ai-cen l-fw-w">
      <div class="l-mt-5">
        <span class="cds-body2" style="color: var(--cds-text-disabled)">District Code:</span>
      </div>
      <div *ngFor="let item of searchBranchCodeList" class="l-mt-5 branch-code-light">
        <div class="l-mr-2">
          <span class="cds-body2" style="color: var(--cds-text-inverse)">{{ item }}</span>
        </div>
        <cds-icon
          (click)="removeToSearchBranchCodeList(item)"
          style="--cds-icon-color: var(--cds-text-inverse)"
          class="cursor-pointer"
          icon="action:cross"></cds-icon>
      </div>
    </div>

    <div class="l-mt-7 l-d-f l-ai-cen l-jc-sb">
      <div>
        <div>
          <span class="cds-h2-light">Record list</span>
        </div>
        <div class="l-mt-2">
          <span class="cds-body1">Total {{ totalElements | number }} records</span>
        </div>
      </div>
      <cds-button *ngIf="!isSuperAdmin" size="sm" [style]="'secondary'" (click)="addCasePage()">Add Case</cds-button>
    </div>
    <div class="l-mt-5">
      <div *ngIf="isLoading && currentPageData.length === 0" class="progress-box l-d-f l-jc-cen">
        <cds-progress mode="indeterminate" [diameter]="60" [type]="'ring'" [text]="'Loading'" [color]="'ml'"></cds-progress>
      </div>
      <app-no-result-found msg="No matching record found" [style.display]="currentPageData.length === 0 && !isLoading ? 'block' : 'none'"></app-no-result-found>
      <div [style.display]="currentPageData.length > 0 ? 'unset' : 'none'" class="row l-plr-0">
        <div #tableBox class="table-wrapper" [ngClass]="[isLoading ? 'loading' : '']">
          <cds-table
            *ngIf="!resetTable"
            [cdsSortDirection]="'asc'"
            cdsSort
            (cdsSortChange)="onSortChange($event)"
            class="col-lg-12 row l-plr-0"
            [dataSource]="currentPageData"
            [class.full]="false">
            <cds-row *cdsRowDef="let row; index as i; columns: displayedColumns"></cds-row>
            <cds-header-row *cdsHeaderRowDef="displayedColumns"></cds-header-row>
            <ng-container cdsColumnDef="employerAcctCd" sticky>
              <cds-header-cell *cdsHeaderCellDef cds-sort-header [ngClass]="[checkIsHaveScroll ? 'shadow-right' : '']" class="employer-acct-cd-width">{{
                'wsm-case-summary.employer-account-code' | lang
              }}</cds-header-cell>
              <cds-cell *cdsCellDef="let element" [ngClass]="[checkIsHaveScroll ? 'shadow-right' : '']" class="employer-acct-cd-width">
                <span class="cds-body1">
                  {{ element.employerAcctCd }}
                </span>
              </cds-cell>
            </ng-container>
            <ng-container *ngIf="isSuperAdmin" cdsColumnDef="branchCd">
              <cds-header-cell *cdsHeaderCellDef cds-sort-header class="wsm-status-width">{{ 'wsm-case-summary.branch-code' | lang }}</cds-header-cell>
              <cds-cell *cdsCellDef="let element" class="wsm-status-width">
                <span class="cds-body1">
                  {{ element.branchCd }}
                </span>
              </cds-cell>
            </ng-container>
            <ng-container *ngIf="isSuperAdmin" cdsColumnDef="agentCd">
              <cds-header-cell *cdsHeaderCellDef cds-sort-header class="wsm-status-width">{{ 'wsm-case-summary.agent-code' | lang }}</cds-header-cell>
              <cds-cell *cdsCellDef="let element" class="wsm-status-width">
                <span class="cds-body1">
                  {{ element.agentCd }}
                </span>
              </cds-cell>
            </ng-container>
            <ng-container cdsColumnDef="bookingRefNo">
              <cds-header-cell *cdsHeaderCellDef cds-sort-header class="booking-r-width">{{ 'wsm-case-summary.booking-reference-no' | lang }}</cds-header-cell>
              <cds-cell *cdsCellDef="let element" class="booking-r-width">
                <span class="cds-body1">
                  {{ element.bookingRefNo }}
                </span>
              </cds-cell>
            </ng-container>
            <ng-container cdsColumnDef="meetingDate">
              <cds-header-cell *cdsHeaderCellDef cds-sort-header class="meeting-date-width">{{ 'wsm-case-summary.meeting-date' | lang }}</cds-header-cell>
              <cds-cell *cdsCellDef="let element" class="meeting-date-width">
                <span class="cds-body1">
                  {{ element.meetingDate }}
                </span>
              </cds-cell>
            </ng-container>
            <ng-container cdsColumnDef="expiryDate">
              <cds-header-cell *cdsHeaderCellDef cds-sort-header class="expiry-date-width">{{ 'wsm-case-summary.expiry-date' | lang }}</cds-header-cell>
              <cds-cell *cdsCellDef="let element" class="expiry-date-width">
                <span class="cds-body1">
                  {{ element.expiryDate }}
                </span>
              </cds-cell>
            </ng-container>
            <ng-container cdsColumnDef="wsmStatus">
              <cds-header-cell *cdsHeaderCellDef cds-sort-header class="expiry-date-width">{{ 'wsm-case-summary.status' | lang }}</cds-header-cell>
              <cds-cell *cdsCellDef="let element" class="expiry-date-width">
                <app-status-light
                  [color]="wsmCaseSummaryService.getWSMStatusData(element.wsmStatus).color"
                  [text]="wsmCaseSummaryService.getWSMStatusData(element.wsmStatus).text | lang"></app-status-light>
              </cds-cell>
            </ng-container>
          </cds-table>

          <div *ngIf="isLoading" class="progress-box">
            <cds-progress mode="indeterminate" [diameter]="60" [type]="'ring'" [text]="'Loading'" [color]="'ml'"></cds-progress>
          </div>
        </div>
        <div class="col-sm-12 l-mt-4">
          <app-pagination
            [pageCount]="{
              current: currentPageNumOrigin,
              count: totalPages
            }"
            (pageChange)="pageChange($event)">
          </app-pagination>
        </div>
      </div>
    </div>
  </div>
</div>
