import { Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { LayoutHeaderService } from 'src/app/core/services/layout-header.service';
import { PermissionAccess, PermissionItem } from 'src/app/core/models/enum/permission.enum';

@Component({
  selector: 'app-admin-header',
  templateUrl: './admin-header.component.html',
  styleUrls: ['./admin-header.component.scss'],
})
export class AdminHeaderComponent implements OnInit {
  permissionAccess = PermissionAccess.W;
  permissionItem = PermissionItem.COMMISSION_SCHEME_MASTER_RECORD;
  title = '';
  backIconType = 'action:button_left_filled';
  isShowButton = false;
  isShowBackButton = false;
  backButtonText = 'backButtonText';
  method = new BehaviorSubject(Event);
  buttonText = '';
  iconType = 'action:edit';
  detailDes = '';
  isShowDetail = false;

  constructor(private layoutHeaderService: LayoutHeaderService) {}

  ngOnInit(): void {
    if (this.detailDes === '') {
      this.isShowDetail = false;
    }
    this.layoutHeaderService.getTitle().subscribe(t => {
      this.title = t;
    });
    this.layoutHeaderService.buttonText.subscribe(t => {
      this.buttonText = t;
    });
    this.layoutHeaderService.isShowButton.subscribe(t => {
      this.isShowButton = t;
    });
    this.layoutHeaderService.iconType.subscribe(t => {
      this.iconType = t;
    });
    this.layoutHeaderService.isShowBackButton.subscribe(t => {
      this.isShowBackButton = t;
    });
    this.layoutHeaderService.backButtonText.subscribe(t => {
      this.backButtonText = t;
    });
    this.layoutHeaderService.detailText.subscribe(t => {
      this.detailDes = t;
      if (this.detailDes != '') {
        this.isShowDetail = true;
      } else {
        this.isShowDetail = false;
      }
    });
  }
  doMethod(): void {
    this.layoutHeaderService.doButtonMethod();
  }

  doBackMethod(): void {
    this.layoutHeaderService.doBackButtonMethod();
  }
}
