<div class="upload-center">
  <div class="title-box">
    <span class="cds-h4-demibold">Upload Center</span>
  </div>

  <div class="content">
    <div class="l-d-f l-ai-cen">
      <div>
        <cds-icon icon="form:form_search" style="width: 24px; height: 24px; --cds-icon-color: var(--cds-icon-selected-ml)"></cds-icon>
      </div>
      <div class="l-ml-4">
        <span class="cds-h2-light">File Locator</span>
      </div>
    </div>
    <div class="l-mt-7 l-d-f l-ai-cen">
      <div class="number-box">
        <div>
          <span class="cds-h6-demibold" style="color: var(--cds-text-disabled)">1</span>
        </div>
      </div>
      <div class="l-ml-3">
        <span class="cds-detail1-demibold" style="color: var(--cds-color-dark-1-green)">Input Location Name</span>
      </div>
    </div>

    <div class="search-box l-mt-3">
      <app-cd-search
        *ngIf="!isRefrushCdSearch"
        iconLocation="right"
        [options]="options"
        (search)="search($event)"
        placeholder="Input Location Name"
        [control]="formControl"></app-cd-search>
    </div>

    <div class="line"></div>

    <div *ngIf="currentSelectedConfig" class="upload-area">
      <div class="l-d-f l-ai-cen">
        <div>
          <cds-icon icon="arrow:upload" style="width: 24px; height: 24px; --cds-icon-color: var(--cds-icon-selected-ml)"></cds-icon>
        </div>
        <div class="l-ml-4">
          <span class="cds-h2-light">Upload</span>
        </div>
      </div>

      <div class="l-d-f l-ai-cen" style="margin-top: 1.875rem">
        <div class="number-box">
          <div>
            <span class="cds-h6-demibold" style="color: var(--cds-text-disabled)">2</span>
          </div>
        </div>
        <div class="l-ml-3">
          <span class="cds-detail1-demibold" style="color: var(--cds-color-dark-1-green)">Upload File</span>
        </div>
      </div>

      <div class="step-2-content">
        <div class="l-d-f l-ai-cen">
          <cds-button [style]="'secondary'" [size]="'sm'" (click)="uploadFile()">Upload File</cds-button>
          <div *ngIf="currentSelectedConfig?.templateDownload" class="l-d-f l-ai-cen cursor-pointer" style="margin-left: 2.25rem">
            <div>
              <cds-icon icon="arrow:download" style="--cds-icon-color: var(--cds-color-coral)"></cds-icon>
            </div>
            <div class="l-ml-2">
              <span class="cds-h6-demibold" style="color: var(--cds-color-coral)">Download File Template</span>
            </div>
          </div>
        </div>
        <div *ngIf="currentSelectedConfig?.showLastUpload" class="l-mt-4">
          <div>
            <span class="cds-body2">latest file name: “01_Campaigns_Campaign List_E101”</span>
          </div>
          <div class="l-mt-1">
            <span class="cds-body2">Last updated: 9:05am 25/05/2022 (HKT), by Jacky Chan</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
