export enum ApplicationStaus {
  Pending = 'Pending',
  Completed = 'Completed',
  eMPFFollowup = 'eMPFFollowup',
  eMPFRejected = 'eMPFRejected',
  eMPFProcessing = 'eMPFProcessing',
}

export enum IFFStatus {
  Pending = 'Pending',
  Completed = 'Completed',
  Declined = 'Declined',
  PendingCustomerSubmission = 'PeningCustomerSubmission',
  Pending2ndAgentSubmission = 'Pending2ndAgentSubmission',
}
