/* eslint-disable @typescript-eslint/no-explicit-any */

import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { RelatedPersonTypeE, ContactPersonView, Phone, PhoneTypeE, PhoneType, ContactPersion } from '../../employer';
import { ContactStatusE } from '../../employer-profile-company-list/employer-profile-company-list';
import { EmployerService } from '../../employer.service';

@Component({
  selector: 'app-contact-persion',
  templateUrl: './contact-persion.component.html',
  styleUrls: ['./contact-persion.component.scss'],
})
export class ContactPersionComponent implements OnInit, OnChanges {
  @Input() topTitle?: string;
  @Input() contactPersonList?: ContactPersion[] = [];

  RelatedPersonTypeE = RelatedPersonTypeE;

  updateDate = new Date();

  ContactStatusE = ContactStatusE;

  emailDataSource = [
    {
      email: 'abc@abc.com',
      updateDate: this.updateDate,
      status: 'ACTIVE',
    },
  ];

  phoneDataSource = [
    {
      type: 'Mobile',
      number: '(+852) 6123 4567',
      ext: '120',
      updateDate: this.updateDate,
      status: 'ACTIVE',
    },
    {
      type: 'Secondary Mobile',
      number: '(+852) 6123 4567',
      ext: '120',
      updateDate: this.updateDate,
      status: 'ACTIVE',
    },
    {
      type: 'Claimant Overseas Telephone',
      number: '(+852) 6123 4567',
      ext: '120',
      updateDate: this.updateDate,
      status: 'INVALID',
    },
  ];

  faxDataSource = [
    {
      type: 'Fax No.',
      number: '(+852) 6123 4567',
      ext: '120',
      updateDate: this.updateDate,
      status: 'ACTIVE',
    },
    {
      type: 'Claimant Overseas Telephone',
      number: '(+852) 6123 4567',
      ext: '',
      updateDate: this.updateDate,
      status: 'ACTIVE',
    },
  ];

  emailDisplayedColumns = ['email', 'updateDateAndStatus'];
  phoneDisplayedColumns = ['type', 'number', 'ext', 'updateDateAndStatus'];

  constructor(public service: EmployerService) {}

  contactPersonListView: ContactPersonView[] = [];

  Object = Object;

  PhoneTypeE = PhoneTypeE;

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['contactPersonList'].currentValue) {
      this.dataTrans();
    }
  }

  getRelatedPersonType(type: RelatedPersonTypeE) {
    return RelatedPersonTypeE[type as unknown as keyof typeof RelatedPersonTypeE];
  }

  isLast(i: number, list: any[]) {
    const lastIndex = list.length - 1;
    return i === lastIndex;
  }

  phoneGenerate(phone: Phone) {
    return `(+${phone.countryCode}) ${phone.phoneNo} ${phone.phoneExt ? `; ext. ${phone.phoneExt}` : ''}`;
  }

  dataTrans() {
    const contactPersonListView: ContactPersonView[] = [];
    const relatedPersonTYpeSortList = this.Object.keys(RelatedPersonTypeE);
    this.contactPersonList?.sort((a, b) => {
      const indexA = relatedPersonTYpeSortList.indexOf(a.contactPersonType);
      const indexB = relatedPersonTYpeSortList.indexOf(b.contactPersonType);
      return indexA - indexB;
    });
    this.contactPersonList?.forEach(item => {
      const phoneList = item.phoneList;
      const telephone = phoneList?.filter(item => item.typeCode === 'PHONE');
      const telephone2 = phoneList?.filter(item => item.typeCode === 'PHONE2');
      const telephone3 = phoneList?.filter(item => item.typeCode === 'PHONE3');
      const telephone4 = phoneList?.filter(item => item.typeCode === 'PHONE4');
      const mobile = phoneList?.filter(item => item.typeCode === 'MOBILE');
      const mobile2 = phoneList?.filter(item => item.typeCode === 'MOBILE2');
      const claimantOverseasTelephone = phoneList?.filter(item => item.typeCode === 'TEL_OVERSEAS');
      const claimantOverseasFaxno = phoneList?.filter(item => item.typeCode === 'FAX_OVERSEAS');
      const faxNo = phoneList?.filter(item => item.typeCode === 'FAX');

      const contactPersonView: ContactPersonView = {
        email: item.emailList,
        PHONE: telephone,
        PHONE2: telephone2,
        PHONE3: telephone3,
        PHONE4: telephone4,
        MOBILE: mobile,
        MOBILE2: mobile2,
        TEL_OVERSEAS: claimantOverseasTelephone,
        FAX_OVERSEAS: claimantOverseasFaxno,
        FAX: faxNo,
      };

      contactPersonListView.push(contactPersonView);
    });
    this.contactPersonListView = contactPersonListView;
  }

  getContactTypeStr(key: string) {
    if (key === 'email') {
      return 'Email';
    } else {
      return PhoneTypeE[key as PhoneType] || '';
    }
  }

  getPhonelistView = (index: number, key: string) => {
    return this.contactPersonListView[index][key as PhoneType];
  };

  getPhoneOrFaxList(list: Phone[], phoneMode = true) {
    return list.filter(item => {
      const isFax = ['FAX_OVERSEAS', 'FAX'].indexOf(item.typeCode) > -1;
      return phoneMode ? !isFax : isFax;
    });
  }

  nameRender(item: ContactPersion) {
    return `${item.lastName || ''} ${item.firstName || ''}`;
  }

  phoneTypeRender(type: PhoneType) {
    return PhoneTypeE[type];
  }

  fullPhoneNoRender(contryCode: string, phoneNo: string) {
    return contryCode ? `(+${contryCode})${phoneNo}` : phoneNo;
  }

  chineseNameRender(item: ContactPersion) {
    return `${item.chineseLastName || ''}${item.chineseFirstName || ''}`;
  }
}
