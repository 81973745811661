/* eslint-disable @typescript-eslint/no-explicit-any */
import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({ name: 'ssmsDateTime' })
export class SsmsDateTimePipe extends DatePipe implements PipeTransform {
  override transform(value: any, format?: any): any {
    let dateTime;
    if (value && format) {
      dateTime = super.transform(value, format);
    } else {
      dateTime = super.transform(value, 'h:mma dd/MM/yyyy');
    }
    if (dateTime) {
      return dateTime.toLowerCase();
    }
    return '';
  }
}
