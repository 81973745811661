import { Component, OnInit } from '@angular/core';

import { CdsOption } from '@cds/ng-core/configuration';

import { environment } from 'src/environments/environment';
import { LanguageChangeService } from '../language-change.service';

@Component({
  selector: 'app-language-switch',
  templateUrl: './language-switch.component.html',
  styleUrls: ['./language-switch.component.scss'],
})
export class LanguageSwitchComponent implements OnInit {
  languages: CdsOption[] = environment.languageList;
  isShowLanguageList = false;

  constructor(private languageChangeService: LanguageChangeService) {}

  changeLang(lang: string) {
    this.languageChangeService.setLang(lang);
    this.isShowLanguageList = false;
  }

  ngOnInit(): void {}

  toggle() {
    this.isShowLanguageList = !this.isShowLanguageList;
  }
}
