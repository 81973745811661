<div class="row">
  <div class="col-xs-12 l-mt-7 l-mb-2 -plr-0">
    <cds-tab-group type="box" (selectedTabIndexChange)="selectedTabIndexChange($event)">
      <cds-tab label="Interest Rate">
        <app-interest-rate [load]="_index === 0"></app-interest-rate>
      </cds-tab>
      <cds-tab label="Dividend Rate"> </cds-tab>
      <cds-tab label="Unit Price">
        <app-unit-price></app-unit-price>
      </cds-tab>
    </cds-tab-group>
  </div>
</div>
