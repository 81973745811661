<div class="declaration-iff">
  <div class="cds-popup-padding-top">
    <cds-icon class="close-current-popup" icon="action:cross" (click)="closeCurrentPopup()"></cds-icon>
  </div>

  <div>
    <div class="col-xs-12 col-md-12 l-pb-6 background-grey" [style.display]="currentStage === 0 ? 'block' : 'none'">
      <div class="row l-pt-6 test">
        <div class="col-xs-12 col-md-12">
          <div class="row padding-none test">
            <div class="col-xs-12 col-md-6">
              <div>
                <div class="cds-h5-light l-ma-0 font-bold">eMPF source</div>
              </div>
            </div>
            <div class="l-pt-6">
              <span>Lorem ipsum dolor sit amet. Qui autem impedit est tenetur</span>
              <span>reiciendis et sunt rerum qui quisquam animi et repudiandae rerum</span>
              <span>ab cumque dolore ut accusamus ipsum. Doloribus aliquid quo</span>
              <span>delectus atque aut corporis eius eum maiores optio in autem</span>
            </div>
            <div class="col-xs-12 col-md-6 l-pt-6">
              <img src="../../../../../assets/img/empf.png" alt="../../../../../assets/img/empf.png" />
            </div>
          </div>
        </div>
      </div>
      <div class="row l-pt-6">
        <cds-button label="Got it" (click)="backToForm()" [config]="gotItButton"></cds-button>
      </div>
    </div>
    <div class="display-all" [style.display]="currentStage === 1 ? 'block' : 'none'">
      <div class="l-pl-7">
        <span class="cds-h2-demibold">{{ 'Declaration.header' | lang }}</span>
      </div>
      <div #displayContent class="content">
        <div class="l-pl-7 l-mt-5">
          <span class="cds-body2 normal-word">
            {{ 'Declaration.headersub1' | lang }}
          </span>
          <br />
          <span class="cds-body2 l-pb-2 normal-word">
            {{ 'Declaration.headersub3' | lang }}
          </span>
          <br />
          <br />
          <span class="cds-body2 l-pt-2 l-pb-2 normal-word">
            {{ 'Declaration.bodydis' | lang }}
          </span>
          <br />
          <span class="cds-body2 l-pb-2 normal-word">
            {{ 'Declaration.bodycommon' | lang }}
          </span>
          <br />
          <span class="cds-body2 l-pb-2 normal-word">
            {{ 'Declaration.bodysuba' | lang }}
          </span>
          <br />
          <span class="cds-body2 l-pb-2 normal-word">
            {{ 'Declaration.bodysubb' | lang }}
          </span>
          <br />
          <span class="cds-body2 l-pb-2 normal-word">
            {{ 'Declaration.bodysubc' | lang }}
          </span>
          <br />
          <span class="cds-body2 l-pb-2 normal-word">
            {{ 'Declaration.bodysubd' | lang }}
          </span>
          <br />
          <br />
          <span class="cds-body2 l-pt-2 l-pb-2 normal-word">
            {{ 'Declaration.bodyadmin' | lang }}
          </span>
          <br />
          <span class="cds-body2 l-pb-2 normal-word">
            {{ 'Declaration.bodycommon' | lang }}
          </span>
          <br />
          <span class="cds-body2 l-pt-2 l-pb-2 normal-word">
            {{ 'Declaration.bodysube' | lang }}
          </span>
          <br />
          <span class="cds-body2 l-pb-2 normal-word">
            {{ 'Declaration.bodysubf' | lang }}
          </span>
          <br />
          <br />
          <span class="cds-body2 l-pt-2 l-pb-2 normal-word">
            {{ 'Declaration.bodyother' | lang }}
          </span>
          <br />
          <span class="cds-body2 l-pb-2 normal-word">
            {{ 'Declaration.bodycommon2' | lang }}
          </span>
          <br />
          <span class="cds-body2 l-pb-2 normal-word">
            {{ 'Declaration.bodysubg' | lang }}
          </span>
          <br />
          <span class="cds-body2 l-pb-2 normal-word">
            {{ 'Declaration.bodysubh' | lang }}
          </span>
          <br />
          <span class="cds-body2 l-pb-2 normal-word">
            {{ 'Declaration.bodysubi' | lang }}
          </span>
          <br />
          <br />
          <span class="cds-body2 l-pb-2 normal-word">
            {{ 'Declaration.bodyimportant' | lang }}
          </span>
          <br />
          <span class="cds-body2 l-pb-2 normal-word">
            {{ 'Declaration.bodynotice' | lang }}
          </span>
          <br />
          <br />
          <span class="cds-body2 l-pb-2 normal-word">
            {{ 'Declaration.bodynoticesub1' | lang }}
          </span>
          <br />
          <span class="cds-body2 l-pb-2 normal-word">
            {{ 'Declaration.bodynoticesuba' | lang }}
          </span>
          <br />
          <span class="cds-body2 l-pb-2 normal-word">
            {{ 'Declaration.bodynoticesubb' | lang }}
          </span>
          <br />
          <span class="cds-body2 l-pb-2 normal-word">
            {{ 'Declaration.bodynoticesubc' | lang }}
          </span>
          <br />
          <span class="cds-body2 l-pb-2 normal-word">
            {{ 'Declaration.bodynoticesubd' | lang }}
          </span>
          <br />
        </div>
      </div>
      <div class="l-pl-7 l-mb-2 l-pt-5 declaration-agreement-btn">
        <cds-button label="{{ 'Declaration.noticeread' | lang }}" (click)="toIFFForm()" [disabled]="!isScrollBottom" [config]="commonButton"></cds-button>
      </div>
    </div>
    <div [style.display]="currentStage === 2 ? 'block' : 'none'">
      <form [formGroup]="iffForm" (ngSubmit)="onIffPartSubmit()">
        <div class="row">
          <div class="cds-h2 col-md-12 l-pt-2">
            {{ 'iff.mpfIntermediaryDeclaration' | lang }}
          </div>
          <div class="col-md-12">
            <span class="cds-detail2">
              {{ 'iff.headerInform' | lang }}
            </span>
          </div>
        </div>
        <div class="row" style="margin-top: var(--cds-spacing-05)">
          <div class="col-md-4 l-pt-1">
            <div class="cds-body2-bold">
              {{ 'iff.eMPFReferenceNo' | lang }}
            </div>
          </div>
          <div class="col-md-8 row l-pt-1">
            <div class="col-md-11 l-plr-0">
              <input
                class="text-field"
                [maxlength]="50"
                formControlName="eMPFReferenceNo"
                placeholder="{{ 'iff.eMPFReferenceNoPlaceHolder' | lang }}"
                onkeyup="value=value.replace(/[\u4e00-\u9fa5]/ig,'')"
                (blur)="eMPFReferenceNoInputBlur()"
                ngDefaultControl />
              <div class="l-mt-1" *ngIf="iffForm.get('eMPFReferenceNo')!.invalid && iffForm.get('eMPFReferenceNo')?.touched">
                <cds-assistive-text *ngIf="iffForm.get('eMPFReferenceNo')?.errors && (iffForm.get('eMPFReferenceNo')?.errors)!['required']" type="error"
                  >{{ 'iff.eMPFReferenceNoError1' | lang }}
                </cds-assistive-text>
                <cds-assistive-text *ngIf="iffForm.get('eMPFReferenceNo')?.errors && (iffForm.get('eMPFReferenceNo')?.errors)!['usedEMPFRefNo']" type="error"
                  >{{ 'iff.eMPFReferenceNoError2' | lang }}
                </cds-assistive-text>
              </div>
            </div>
            <div class="col-md-1 l-mt-3">
              <cds-icon [icon]="infoIcon" [config]="iconConfig" (click)="onClickInfoIcon()"></cds-icon>
            </div>
          </div>
        </div>
        <div class="l-mt-1 l-ml-3">
          <div class="row">
            <app-regulated-activities
              style="width: 100%"
              #regulatedActivities
              [group]="iffForm"
              controlName="conductedRegulatorActivityFlag"
              ngDefaultControl></app-regulated-activities>
            <cds-assistive-text
              class="l-mt-1"
              *ngIf="iffForm.get('conductedRegulatorActivityFlag')?.invalid && iffForm.get('conductedRegulatorActivityFlag')?.touched"
              type="error"
              >{{ 'iff.pleaseSelectRelevantRegulatedActivities' | lang }}
            </cds-assistive-text>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4 l-pt-3 l-d-f l-ai-cen">
            <span class="cds-body2-bold cds-bold l-mb-0">{{ 'iff.hKIDPassportNo' | lang }}:</span>
          </div>
          <div class="col-md-3 l-pt-3" style="flex-basis: 28%; max-width: 28%">
            <cds-dropdown [config]="hKIDPassportNoConfig" [formControl]="hKIDPassportNoFormControl" (ngModelChange)="hKIDPassportNoChange()"></cds-dropdown>
            <div
              class="l-mt-1"
              *ngIf="iffForm.get('hkidOrPassportNo')?.touched && hKIDPassportNoFormControl.value !== '0' && hKIDPassportNoFormControl.value !== '1'">
              <cds-assistive-text type="error">
                <span>
                  {{ 'iff.pleaseSelectIDType' | lang }}
                </span>
              </cds-assistive-text>
            </div>
          </div>
          <div class="col-md-5" style="position: relative; top: 16px; flex-basis: 38%; max-width: 38%">
            <input
              onkeyup="value=value.replace(/[\u4e00-\u9fa5]/ig,'')"
              class="text-field"
              formControlName="hkidOrPassportNo"
              placeholder="{{ 'iff.pleaseInputHKIDPassportNo' | lang }}"
              (blur)="hkidOrPassportNoInputBlur()"
              ngDefaultControl />
            <div class="l-mt-1" *ngIf="!iffForm.get('hkidOrPassportNo')!.valid && iffForm.get('hkidOrPassportNo')?.touched">
              <div *ngIf="iffForm.get('hkidOrPassportNo')?.errors && (iffForm.get('hkidOrPassportNo')?.errors)!['required']; else hkidOrPassportNoBlock">
                <cds-assistive-text type="error">
                  <span>
                    {{ 'iff.pleaseInputHKIDPassportNo' | lang }}
                  </span>
                </cds-assistive-text>
              </div>

              <ng-template #hkidOrPassportNoBlock>
                <div *ngIf="iffForm.get('hkidOrPassportNo')?.errors && (iffForm.get('hkidOrPassportNo')?.errors)!['formatError']">
                  <cds-assistive-text type="error">
                    <span>
                      {{ 'common.invalid' | lang : { p1: 'case-details.HKIDPassportNo' | lang } }}
                    </span>
                  </cds-assistive-text>
                </div>
              </ng-template>
            </div>
          </div>
        </div>
        <div class="row" style="margin-top: var(--cds-spacing-05)">
          <div class="col-md-4">
            <div>
              <span class="cds-body2-bold">{{ 'iff.customerContactType1' | lang }}</span>
            </div>
            <div>
              <span class="cds-body2-bold">{{ 'iff.customerContactType2' | lang }}</span>
              <span class="cds-body2-bold" style="color: var(--cds-color-dark-1-coral)">*</span>:
            </div>
          </div>
          <div class="col-md-8">
            <div>
              <cds-radio
                class="cd-radio-margin-bottom-clear"
                [config]="{
                  options: [
                    {
                      label: 'iff.contactTypeEmail' | lang,
                      value: 'E'
                    },
                    {
                      label: 'iff.contactTypePhone' | lang,
                      value: 'M'
                    }
                  ]
                }"
                formControlName="contactType"
                (cdsChange)="onContactTypeChange($event)"
                ngDefaultControl></cds-radio>
              <div class="l-mt-1" *ngIf="!iffForm.get('contactType')!.valid && iffForm.get('contactType')?.touched">
                <cds-assistive-text *ngIf="!iffForm.value.contactType" type="error">{{ 'iff.contactTypeError' | lang }} </cds-assistive-text>
              </div>
            </div>
            <div></div>
            <div *ngIf="iffForm.value.contactType === 'M'" class="l-d-f l-fd-row l-mb-3 l-mt-5">
              <div class="col-md-4 l-plr-0">
                <cds-dropdown formControlName="countryCode" placeholder="+852 (HK)" [config]="countrycodeConfig" ngDefaultControl></cds-dropdown>
              </div>
              <div class="col-md-8 phone-no-box">
                <input
                  class="text-field"
                  type="number"
                  [maxlength]="8"
                  formControlName="phoneNo"
                  placeholder="{{ 'iff.phoneNoPlaceHolder' | lang }}"
                  ngDefaultControl />
                <div class="l-mt-1" *ngIf="iffForm.get('phoneNo')!.invalid && iffForm.get('phoneNo')?.touched">
                  <cds-assistive-text *ngIf="iffForm.get('phoneNo')?.errors && (iffForm.get('phoneNo')?.errors)!['required']" type="error"
                    >{{ 'iff.phoneNoError1' | lang }}
                  </cds-assistive-text>
                </div>
                <div class="l-mt-1" *ngIf="iffForm.get('phoneNo')?.touched && iffForm?.errors && (iffForm?.errors)!['invalidPhoneNo']">
                  <cds-assistive-text type="error">{{ 'iff.phoneNoError2' | lang }} </cds-assistive-text>
                </div>
              </div>
            </div>
            <div *ngIf="iffForm.value.contactType === 'E'" class="l-d-f l-fd-row l-mb-3 l-mt-5">
              <div class="col-md-12 l-plr-0">
                <input class="text-field" formControlName="email" placeholder="{{ 'iff.customerEmailPlaceHolder' | lang }}" ngDefaultControl />
                <div
                  class="l-mt-1"
                  *ngIf="iffForm.get('email')!.invalid && (iffForm.get('email')?.touched || iffForm.get('email')?.dirty) && !iffForm.get('email')?.value">
                  <cds-assistive-text *ngIf="iffForm.get('email')?.errors || (iffForm.get('email')?.errors)!['required']" type="error"
                    >{{ 'iff.emailError1' | lang }}
                  </cds-assistive-text>
                </div>
                <div class="l-mt-1" *ngIf="iffForm.get('email')?.errors && (iffForm.get('email')?.errors)!['email']">
                  <cds-assistive-text type="error">{{ 'iff.emailError2' | lang }} </cds-assistive-text>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ng-container *ngIf="isChannelDBS">
          <div class="row" style="margin-top: 16px">
            <div class="col-md-4 l-d-f l-ai-cen">
              <div>
                <span class="cds-body2-bold">RM Referral Code:</span>
              </div>
            </div>
            <div class="col-md-8">
              <app-cd-input
                [type]="'number'"
                [formControl]="dbs.rmReferralCode"
                [maxlength]="8"
                ngDefaultControl
                [placeholder]="'Input RM Referral Code'"></app-cd-input>
              <div class="l-mt-1">
                <cds-assistive-text *ngIf="dbs.rmReferralCode.dirty && dbs.rmReferralCode.hasError('required'); else second" type="error"
                  >Please input valid RM Referral Code
                </cds-assistive-text>
                <ng-template #second>
                  <cds-assistive-text *ngIf="dbs.rmReferralCode.dirty && dbs.rmReferralCode.hasError('lengthError')" type="error"
                    >Please input valid RM Referral Code
                  </cds-assistive-text>
                </ng-template>
              </div>
            </div>
          </div>
          <div class="row" style="margin-top: var(--cds-spacing-05)">
            <div class="col-md-4 l-d-f l-ai-cen">
              <div>
                <span class="cds-body2-bold">Customer ID:</span>
              </div>
            </div>
            <div class="col-md-8">
              <app-cd-input
                [formControl]="dbs.inputCustomerId"
                [maxlength]="8"
                [regExpLimit]="'^[A-Za-z0-9-]*$'"
                ngDefaultControl
                [placeholder]="'Input Customer ID (Optional)'"></app-cd-input>
              <div class="l-mt-1">
                <cds-assistive-text *ngIf="dbs.inputCustomerId.dirty && dbs.inputCustomerId.hasError('lengthError')" type="error"
                  >Please input valid Customer ID
                </cds-assistive-text>
              </div>
            </div>
          </div>
          <div class="row" style="margin-top: var(--cds-spacing-05)">
            <div class="col-md-4 l-d-f l-ai-cen">
              <div>
                <span class="cds-body2-bold">Campaign Code:</span>
              </div>
            </div>
            <div class="col-md-8">
              <app-cd-input
                [formControl]="dbs.campaignCode"
                [maxlength]="10"
                [regExpLimit]="'^[A-Za-z0-9-]*$'"
                ngDefaultControl
                [placeholder]="'Input Campaign Code (Optional)'"></app-cd-input>
              <div class="l-mt-1">
                <cds-assistive-text *ngIf="dbs.campaignCode.dirty && dbs.campaignCode.hasError('lengthError')" type="error"
                  >Please input valid Campaign Code
                </cds-assistive-text>
              </div>
            </div>
          </div>
          <div class="row" style="margin-top: var(--cds-spacing-05)">
            <div class="col-md-4 l-d-f l-ai-cen">
              <div>
                <span class="cds-body2-bold">Source Code:</span>
              </div>
            </div>
            <div class="col-md-8">
              <app-cd-input
                [formControl]="dbs.sourceCode"
                [maxlength]="10"
                [regExpLimit]="'^[A-Za-z0-9-]*$'"
                ngDefaultControl
                [placeholder]="'Input Source Code (Optional)'"></app-cd-input>
              <div class="l-mt-1">
                <cds-assistive-text *ngIf="dbs.sourceCode.dirty && dbs.sourceCode.hasError('lengthError')" type="error"
                  >Please input valid Source Code
                </cds-assistive-text>
              </div>
            </div>
          </div>
        </ng-container>
        <div class="row" style="margin-top: var(--cds-spacing-05)">
          <div class="col-md-12 l-d-f l-ai-cen">
            <span class="cds-detail2" style="color: var(--cds-color-dark-1-coral)">{{ 'iff.customerContactTypeRemark' | lang }}</span>
          </div>
        </div>
        <div class="row" style="margin-top: var(--cds-spacing-05)">
          <div class="col-md-4 l-d-f l-ai-cen">
            <div>
              <span class="cds-body2-bold"> {{ 'sendIffInBatchSummary.preferredLanguage' | lang }}: </span>
            </div>
          </div>
          <div class="col-md-8">
            <app-cd-radio
              class="cd-radio-margin-bottom-clear"
              #cdRadioPreferredLanguage
              (valueChange)="iffForm.get('preferredLanguage')?.setValue($event)"
              [value]="iffForm.get('preferredLanguage')?.value"
              [config]="preferredLanguageConfig"></app-cd-radio>
          </div>
        </div>
        <div
          *ngIf="iffForm.get('preferredLanguage')?.invalid && iffForm.get('preferredLanguage')?.touched"
          class="row"
          style="margin-top: var(--cds-spacing-01)">
          <div class="col-md-4 l-d-f l-ai-cen"></div>
          <div class="col-md-8">
            <cds-assistive-text type="error">{{ 'common.plsSelect' | lang : { p1: 'sendIffInBatchSummary.preferredLanguage' | lang } }} </cds-assistive-text>
          </div>
        </div>
        <div class="row" style="margin-top: var(--cds-spacing-05)">
          <div class="col-md-4 l-d-f l-ai-cen">
            <div>
              <span class="cds-body2-bold"> {{ 'iff.secondIntermediary' | lang }}: </span>
            </div>
          </div>
          <div class="col-md-8">
            <div>
              <input
                [ngClass]="[isDisabled ? 'text-field-disabled' : 'text-field']"
                type="text"
                [maxlength]="6"
                formControlName="splitAgentCode"
                [attr.disabled]="isDisabled ? '' : null"
                placeholder="{{ 'iff.splitAgentInfoPlaceHolder' | lang }}"
                onkeyup="value=value.replace(/[\u4e00-\u9fa5]/ig,'')"
                (blur)="splitAgentCodeInputBlur()"
                ngDefaultControl />
            </div>
            <div *ngIf="iffForm.get('splitAgentCode')!.invalid && iffForm.get('splitAgentCode')?.touched">
              <cds-assistive-text
                *ngIf="iffForm.get('splitAgentCode')?.errors || (iffForm.get('splitAgentCode')?.errors)!['invalidSplitAgentCode']"
                type="error"
                >{{ 'iff.invalidSecAgentCode' | lang }}
              </cds-assistive-text>
            </div>
          </div>
        </div>
        <div class="row l-pt-6 l-d-f">
          <div class="l-pl-3">
            <cds-button label="{{ 'iff.reset' | lang }}" (click)="resetIffForm()" [config]="commonButton2"></cds-button>
          </div>
          <div class="l-pl-3" *ngIf="iffForm.value.splitAgentCode">
            <cds-button
              label="{{ 'iff.continue' | lang }}"
              [config]="{
                style: 'primary',
                size: 'md',
                buttonType: 'submit'
              }"></cds-button>
          </div>
          <div class="l-pl-3" *ngIf="!iffForm.value.splitAgentCode">
            <cds-button
              label="{{ 'iff.submit' | lang }}"
              [config]="{
                style: 'primary',
                size: 'md',
                buttonType: 'submit'
              }"></cds-button>
          </div>
        </div>
      </form>
    </div>
    <div [style.display]="currentStage === 3 ? 'block' : 'none'">
      <div class="row">
        <div class="col-md-12 l-pt-6">
          <div class="cds-h2-bold">
            {{ 'iff.mpfIntermediaryDeclarationConfirmation' | lang }}
          </div>
        </div>
        <div class="col-md-12 l-d-f l-mb-6">
          <app-matters-needing-attention [color]="'red'" [title]="'iff.pleaseNoted' | lang">
            <ul class="l-mt-0 l-mb-0">
              <li>
                <span class="cds-detail1">{{ 'iff.splitAgentNote1' | lang }}</span>
              </li>
              <li>
                <span class="cds-detail1">{{ 'iff.splitAgentNote2' | lang }}</span>
              </li>
              <li>
                <span class="cds-detail1">{{ 'iff.splitAgentNote3HeadPart' | lang }}</span>
                <span class="cds-detail1 red-text">{{ 'iff.splitAgentNote3RedPart' | lang }}</span>
                <span class="cds-detail1">{{ 'iff.splitAgentNoteTailPart' | lang }}</span>
              </li>
            </ul>
          </app-matters-needing-attention>
        </div>
      </div>
      <div class="row l-pt-2">
        <div class="col-md-4">
          <div class="cds-body2-bold">
            {{ 'iff.confirmationEMPFRefNo' | lang }}
          </div>
        </div>
        <div class="col-md-8">
          <div>
            <span class="cds-body2-demibold">{{ iffForm.value.eMPFReferenceNo }}</span>
          </div>
          <div style="font-size: 14px; padding-top: 20px">
            <app-regulated-activities
              style="width: 100%"
              #regulatedActivities
              [disable]="true"
              [value]="iffForm.get('conductedRegulatorActivityFlag')?.value"
              ngDefaultControl></app-regulated-activities>
          </div>
        </div>
      </div>
      <div class="row l-pt-2">
        <div class="col-md-4">
          <div class="cds-body2-bold">{{ 'iff.hKIDPassportNo' | lang }}:</div>
        </div>
        <div class="col-md-8">
          <div>
            <span class="cds-body2-demibold">{{ iffForm.value.hkidOrPassportNo }} </span>
          </div>
        </div>
      </div>
      <div class="row l-pt-2">
        <div class="col-md-4">
          <div class="cds-body2-bold">
            {{ 'iff.customerContactType' | lang }}
          </div>
        </div>
        <div class="col-md-8">
          <div>
            <span class="cds-body2-demibold">{{
              iffForm.value.contactType === 'E' ? iffForm.value.email : '+(' + iffForm.value.countryCode + ') ' + iffForm.value.phoneNo
            }}</span>
          </div>
        </div>
      </div>
      <div *ngIf="iffForm.get('preferredLanguage')?.value" class="row l-pt-2">
        <div class="col-md-4">
          <div class="cds-body2-bold">{{ 'sendIffInBatchSummary.receivingLanguage' | lang }}:</div>
        </div>
        <div class="col-md-8">
          <div *ngIf="iffForm.get('preferredLanguage')?.value === PreferredLanguageType.Chinese">
            <span class="cds-body2-demibold">{{ 'common.chinese' | lang }}</span>
          </div>
          <div *ngIf="iffForm.get('preferredLanguage')?.value === PreferredLanguageType.English">
            <span class="cds-body2-demibold">{{ 'common.english' | lang }}</span>
          </div>
        </div>
      </div>
      <ng-container *ngIf="isChannelDBS">
        <div class="row l-pt-2">
          <div class="col-md-4">
            <div class="cds-body2-bold">RM Referral Code:</div>
          </div>
          <div class="col-md-8">
            <span class="cds-body2-demibold">{{ dbs.rmReferralCode.value || '-' }}</span>
          </div>
        </div>
        <div class="row l-pt-2">
          <div class="col-md-4">
            <div class="cds-body2-bold">Customer ID:</div>
          </div>
          <div class="col-md-8">
            <span class="cds-body2-demibold">{{ dbs.inputCustomerId.value || '-' }}</span>
          </div>
        </div>
        <div class="row l-pt-2">
          <div class="col-md-4">
            <div class="cds-body2-bold">Campaign Code:</div>
          </div>
          <div class="col-md-8">
            <span class="cds-body2-demibold">{{ dbs.campaignCode.value || '-' }}</span>
          </div>
        </div>
        <div class="row l-pt-2">
          <div class="col-md-4">
            <div class="cds-body2-bold">Source Code:</div>
          </div>
          <div class="col-md-8">
            <span class="cds-body2-demibold">{{ dbs.sourceCode.value || '-' }}</span>
          </div>
        </div>
      </ng-container>
      <div class="row l-pt-2 l-d-f">
        <div class="l-pl-3">
          <cds-button label="{{ 'iff.back' | lang }}" (click)="backToIffForm()" [config]="commonButton2"></cds-button>
        </div>
        <div class="l-pl-3">
          <cds-button label="{{ 'iff.confirmAndSubmit' | lang }}" (click)="iffSubmission()" [config]="commonButton"></cds-button>
          <div class="l-mt-4">
            <cds-assistive-text type="error">{{ 'sendIffInBatchSummary.noChanges' | lang }}</cds-assistive-text>
          </div>
        </div>
      </div>
    </div>

    <div [style.display]="currentStage === 4 ? 'block' : 'none'">
      <div class="row">
        <div class="cds-h2-bold col-md-12 l-pt-2 l-mb-4">
          {{ 'iff.mpfIntermediaryDeclaration' | lang }}
        </div>
      </div>
      <div class="row">
        <div class="cds-body1-light col-md-12">
          {{ 'iff.addMPFIntermediary' | lang }}
        </div>
      </div>
      <div class="row l-pt-2">
        <div class="col-md-4">
          <div class="cds-body2-bold">
            {{ 'iff.secondAgentCode' | lang }}
          </div>
        </div>
        <div class="col-md-8">
          <div>
            <span class="cds-body2-bold">{{ iffForm.value.splitAgentCode }}</span>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4">
          <div class="cds-body2-bold">
            {{ 'iff.secondAgentName' | lang }}
          </div>
        </div>
        <div class="col-md-8">
          <div>
            <span class="cds-body2-bold">{{ getAgentName(secondAgentInfo) }}</span>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 l-pt-2">
          <app-regulated-activities
            style="width: 100%"
            #regulatedActivities
            [disable]="!iffForm.get('conductedRegulatorActivityFlag')?.value"
            [(value)]="isSecondAgentConduct"
            ngDefaultControl></app-regulated-activities>
        </div>
      </div>
      <div class="l-pt-3 l-plr-0" *ngIf="isSecondAgentConduct">
        <div class="l-d-f l-mb-6 pillar-wrapper">
          <app-matters-needing-attention [title]="'iff.pleaseNoted' | lang">
            <div class="cds-detail2 l-d-f l-fd-col">
              <span class="cds-detail2 l-mt-1">
                {{ 'iff.splitAgentNotice' | lang }}
              </span>
            </div>
          </app-matters-needing-attention>
        </div>

        <div class="row">
          <div class="col-md-12 cds-body2">
            {{ 'iff.secondAgentContactDeclareHeader' | lang }}
          </div>
        </div>
        <div class="row">
          <div class="l-pl-3 l-pr-3 connect-radio-box">
            <app-cd-radio
              class="cd-radio-margin-bottom-clear"
              [(value)]="secondAgentNotificationChannel"
              [config]="secondAgentRadioConfig"
              (valueChange)="onSecondAgentContactTypeChange($event)"></app-cd-radio>
          </div>
        </div>

        <div class="row" style="margin-top: var(--cds-spacing-04)">
          <div class="col-md-3 l-d-f l-ai-cen">
            <div>
              <span class="cds-body2-bold">{{ 'sendIffInBatchSummary.preferredLanguage' | lang }}</span>
            </div>
          </div>
          <div class="col-md-9">
            <app-cd-radio
              class="cd-radio-margin-bottom-clear"
              #cdRadioSecondAgentPreferredLanguage
              [(value)]="secondAgentPreferredLanguage"
              [config]="secondAgentPreferredLanguageConfig"></app-cd-radio>
          </div>
        </div>
      </div>
      <div class="row l-pt-4 l-d-f">
        <div class="l-pl-3">
          <cds-button label="{{ 'iff.back' | lang }}" (click)="backToIffForm()" [config]="commonButton2"></cds-button>
        </div>
        <div class="l-pl-3">
          <cds-button
            [disabled]="disableIffSplitAgentContinue"
            label="{{ 'iff.continue' | lang }}"
            (click)="iffSplitAgentContinue()"
            [config]="commonButton"></cds-button>
        </div>
      </div>
    </div>

    <div [style.display]="currentStage === 5 ? 'block' : 'none'">
      <div class="row">
        <div class="col-md-12 l-pt-6">
          <div class="cds-h2-bold">
            {{ 'iff.mpfIntermediaryDeclarationConfirmation' | lang }}
          </div>
        </div>

        <div class="l-d-f l-mb-6 l-pl-3 l-pr-3">
          <app-matters-needing-attention [color]="'red'" [title]="'iff.pleaseNoted' | lang">
            <ul class="l-mt-0 l-mb-0">
              <li>
                <span class="cds-detail1">{{ 'iff.splitAgentNote1' | lang }}</span>
              </li>
              <li>
                <span class="cds-detail1">{{ 'iff.splitAgentNote2' | lang }}</span>
              </li>
              <li>
                <span class="cds-detail1">{{ 'iff.splitAgentNote3HeadPart' | lang }}</span>
                <span class="cds-detail1 red-text">{{ 'iff.splitAgentNote3RedPart' | lang }}</span>
                <span class="cds-detail1">{{ 'iff.splitAgentNoteTailPart' | lang }}</span>
              </li>
            </ul>
          </app-matters-needing-attention>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4">
          <div class="cds-body2-bold">
            {{ 'iff.confirmationEMPFRefNo' | lang }}
          </div>
        </div>
        <div class="col-md-8">
          <span class="cds-body2-bold">{{ iffForm.value.eMPFReferenceNo }}</span>
        </div>
      </div>

      <div class="row l-pt-1">
        <div class="col-md-4"></div>
        <div class="col-md-8">
          <app-regulated-activities
            style="width: 100%"
            #regulatedActivities
            [disable]="true"
            [value]="iffForm.get('conductedRegulatorActivityFlag')?.value"
            ngDefaultControl></app-regulated-activities>
        </div>
      </div>

      <div class="row l-pt-5">
        <div class="col-md-4">
          <div class="cds-body2-bold">{{ 'iff.hKIDPassportNo' | lang }}:</div>
        </div>
        <div class="col-md-8">
          <div class="cds-body2-bold">
            <span>{{ iffForm.value.hkidOrPassportNo }} </span>
          </div>
        </div>
      </div>
      <div class="row l-pt-2">
        <div class="col-md-4">
          <div class="cds-body2-bold">
            {{ 'iff.customerContactType' | lang }}
          </div>
        </div>
        <div class="col-md-8">
          <span class="cds-body2-bold">{{
            iffForm.value.contactType === 'E' ? iffForm.value.email : '+(' + iffForm.value.countryCode + ') ' + iffForm.value.phoneNo
          }}</span>
        </div>
      </div>
      <div *ngIf="iffForm.get('preferredLanguage')?.value" class="row l-pt-2">
        <div class="col-md-4">
          <div class="cds-body2-bold">{{ 'sendIffInBatchSummary.receivingLanguage' | lang }}:</div>
        </div>
        <div class="col-md-8">
          <div *ngIf="iffForm.get('preferredLanguage')?.value === PreferredLanguageType.Chinese">
            <span class="cds-body2-bold">{{ 'common.chinese' | lang }}</span>
          </div>
          <div *ngIf="iffForm.get('preferredLanguage')?.value === PreferredLanguageType.English">
            <span class="cds-body2-bold">{{ 'common.english' | lang }}</span>
          </div>
        </div>
      </div>
      <ng-container *ngIf="isChannelDBS">
        <div class="row l-pt-2">
          <div class="col-md-4">
            <div class="cds-body2-bold">RM Referral Code:</div>
          </div>
          <div class="col-md-8">
            <span class="cds-body2-bold">{{ dbs.rmReferralCode.value || '-' }}</span>
          </div>
        </div>
        <div class="row l-pt-2">
          <div class="col-md-4">
            <div class="cds-body2-bold">Customer ID:</div>
          </div>
          <div class="col-md-8">
            <span class="cds-body2-bold">{{ dbs.inputCustomerId.value || '-' }}</span>
          </div>
        </div>
        <div class="row l-pt-2">
          <div class="col-md-4">
            <div class="cds-body2-bold">Campaign Code:</div>
          </div>
          <div class="col-md-8">
            <span class="cds-body2-bold">{{ dbs.campaignCode.value || '-' }}</span>
          </div>
        </div>
        <div class="row l-pt-2">
          <div class="col-md-4">
            <div class="cds-body2-bold">Source Code:</div>
          </div>
          <div class="col-md-8">
            <span class="cds-body2-bold">{{ dbs.sourceCode.value || '-' }}</span>
          </div>
        </div>
      </ng-container>
      <div class="row l-pt-2">
        <div class="col-md-4">
          <div>
            <span class="cds-body2-bold">{{ 'iff.secondAgentName' | lang }}:</span>
          </div>
        </div>
        <div class="col-md-8">
          <span class="cds-body2-bold">{{ secondAgentInfo?.agentCode || '' }}</span>
          <div clas="l-pl-1" style="display: inline-block; margin-left: var(--cds-spacing-02)">
            <span class="cds-body2-bold">{{ getAgentName(secondAgentInfo) }}</span>
          </div>
        </div>
      </div>
      <div *ngIf="isSecondAgentConduct">
        <div class="l-mt-3">
          <div class="row">
            <div class="col-md-4"></div>
            <div class="col-md-8">
              <div>
                <span class="cds-body2-bold">{{ 'iff.splitAgentSelectedContactTypeLabel' | lang }}</span>
                <span class="l-pl-1"
                  ><span class="cds-body2-bold">{{ secondAgentContactTypeSelected }}</span></span
                >
              </div>
            </div>
          </div>
          <div *ngIf="secondAgentPreferredLanguage" class="l-mt-2">
            <div class="row">
              <div class="col-md-4"></div>
              <div class="col-md-8">
                <div class="l-d-f">
                  <div>
                    <span class="cds-body2-bold">{{ 'common.language-of' | lang : { p1: 'sendIffInBatchSummary.2ndMPFIntermediary' | lang } }} : </span>
                  </div>
                  <div class="l-pl-1">
                    <div style="display: inline" *ngIf="secondAgentPreferredLanguage === PreferredLanguageType.Chinese">
                      <span class="cds-body2-bold">{{ 'common.chinese' | lang }}</span>
                    </div>
                    <div style="display: inline" *ngIf="secondAgentPreferredLanguage === PreferredLanguageType.English">
                      <span class="cds-body2-bold">{{ 'common.english' | lang }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="l-mt-4">
        <div class="row">
          <div class="col-md-4"></div>
          <div class="col-md-8">
            <app-regulated-activities
              style="width: 100%"
              [value]="isSecondAgentConduct"
              #regulatedActivities
              [disable]="true"
              ngDefaultControl></app-regulated-activities>
          </div>
        </div>
      </div>
      <div class="row l-pt-5 l-d-f">
        <div class="l-pl-3">
          <cds-button label="{{ 'iff.back' | lang }}" (click)="backToSplitAgentPage()" [config]="commonButton2"></cds-button>
        </div>
        <div class="l-pl-3 l-d-f l-fd-col">
          <cds-button label="{{ 'iff.confirmAndSubmit' | lang }}" (click)="iffSplitAgentSubmission()" [config]="commonButton"></cds-button>
          <div class="l-mt-4">
            <cds-assistive-text type="error">{{ 'sendIffInBatchSummary.noChanges' | lang }}</cds-assistive-text>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
