import { Component, OnInit } from '@angular/core';
import { CdsOption } from '@cds/ng-core/configuration';
import { DateTime } from 'luxon';
import { LanguageChangeService } from 'src/app/shared/language-change.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-auth-layout',
  templateUrl: './auth-layout.component.html',
  styleUrls: ['./auth-layout.component.scss'],
})
export class AuthLayoutComponent implements OnInit {
  languages: CdsOption[] = environment.languageList;
  copyright = `© ${environment.organizationDay}-${DateTime.now().year} `;
  cdsIconConfig = { color: '#FFFFFF', size: 'sm' };
  constructor(private languageChangeService: LanguageChangeService) {}
  isOpen = false;
  ngOnInit(): void {}
  selectedValue = '';

  changeLang(lang: string) {
    this.languageChangeService.setLang(lang);
    this.selectedValue = lang;
    this.isOpen = false;
  }
}
