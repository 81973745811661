/* eslint-disable no-useless-escape */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, Validators } from '@angular/forms';
import { CdsOption } from '@cds/ng-core/configuration';
import { Subject } from 'rxjs';
import { CommissionScaleService } from 'src/app/core/services/commission/commission-scale.service';
import { ScalePattern, ScaleType } from '../../../../core/models/enum/commission-scale.enum';
import { BREAKPOINT_TYPE_OPTIONS, CALCULATION_TYPE_OPTIONS, DEFAULT_BREAKPOINT, SCALE_PATTERN_OPTIONS, SCALE_TYPE_OPTIONS } from '../commission-scale.config';
import { BreakpointVo, CommissionScaleVo } from '../commission-scale.model';
import {
  requiredScaleCode,
  existCodeAsyncValidator,
  invalidCalcTypeValidator,
  invalidCrfRateValidator,
  invalidSelectValidator,
} from '../commission-scale.validator';

@Component({
  selector: 'app-commission-record-detail',
  templateUrl: './commission-record-detail.component.html',
  styleUrls: ['./commission-record-detail.component.scss'],
})
export class CommissionRecordDetailComponent implements OnInit {
  recordDetailFormGroup = this.formBuilder.group(
    {
      scaleCode: [{ value: '', disabled: false }, [requiredScaleCode()], [existCodeAsyncValidator(this.commissionScaleService)]],
      scaleType: [{ value: '', disabled: false }, [Validators.required, invalidSelectValidator(SCALE_TYPE_OPTIONS)]],
      calcType: [{ value: '', disabled: false }, [invalidSelectValidator([])]],
      crfRate: [{ value: '', disabled: false }],
      breakpointType: [{ value: '', disabled: false }, [Validators.required, invalidSelectValidator(BREAKPOINT_TYPE_OPTIONS)]],
      scalePattern: [{ value: '', disabled: false }, [Validators.required, invalidSelectValidator(SCALE_PATTERN_OPTIONS)]],
    },
    { validators: [invalidCalcTypeValidator(), invalidCrfRateValidator()] }
  );
  recordDetailFormControl = {
    scaleType: {
      label: 'commission.scale.type.label',
      placeholder: 'commission.scale.type.placeholder',
    },
    calcType: {
      label: 'commission.calculation.type.label',
      placeholder: 'commission.calculation.type.placeholder',
    },
    breakpointType: {
      label: 'commission.breakpoint.type.label',
      placeholder: 'commission.breakpoint.type.placeholder',
    },
    scalePattern: {
      label: 'commission.scale.pattern.label',
      placeholder: 'commission.scale.pattern.placeholder',
    },
  };

  /**commission record detail service & commission breakpoint service */
  private _commissionItem!: CommissionScaleVo;
  private _editItem!: CommissionScaleVo;
  private _dataSource!: BreakpointVo[];
  private _isEditPage = false;
  private _isSubmitted = false;

  /**drop down options */
  breakpointTypeOpts = BREAKPOINT_TYPE_OPTIONS;
  scalePatternOpts = SCALE_PATTERN_OPTIONS;
  scaleTypeOpts = SCALE_TYPE_OPTIONS;
  calcTypeOpts: CdsOption[] = [];
  private calcTypeOpts2 = new Subject<CdsOption[]>();
  private calcTypeOpts2$ = this.calcTypeOpts2.asObservable();

  @Input()
  set isEditPage(value: boolean) {
    this._isEditPage = value;
    this.setDisabledOfInputControl();
    this.setCalcTypeOptions();
  }
  get isEditPage() {
    return this._isEditPage;
  }

  @Input()
  set isSubmitted(value: boolean) {
    this._isSubmitted = value;
    this.setDisabledOfInputControl();
    this.setCalcTypeOptions();
  }
  get isSubmitted() {
    return this._isSubmitted;
  }

  @Input()
  set commissionItem(item: CommissionScaleVo) {
    this._commissionItem = item;
  }
  get commissionItem() {
    return this._commissionItem;
  }

  @Input()
  set editItem(item: CommissionScaleVo) {
    this._editItem = item;
    this.initFormGroup();
  }
  get editItem() {
    return this._editItem;
  }

  @Input()
  set dataSource(data: BreakpointVo[]) {
    this._dataSource = data;
  }
  get dataSource() {
    return this._dataSource;
  }

  @Output() commissionItemChange = new EventEmitter();

  recordDetailEnable = false;

  constructor(private commissionScaleService: CommissionScaleService, private formBuilder: FormBuilder) {
    this.calcTypeOpts2$.subscribe(x => {
      this.calcTypeOpts = x;
      const calcTypeControl = this.GetControl('calcType');
      calcTypeControl.clearValidators();
      calcTypeControl.addValidators(invalidSelectValidator(this.calcTypeOpts));
      calcTypeControl.updateValueAndValidity();
    });
  }

  ngOnInit(): void {}

  crfRateBlur(event: any) {
    const val = event.target.value;
    if (!Number(val)) {
      event.target.value = '';
      this.GetControl('crfRate').patchValue('');
    }
  }

  valueChange(value?: any, controlName?: string) {
    if (controlName === 'scaleCode') {
      let valueTemp = value.target.value.toUpperCase().replace(/[^\a-\z\A-\Z0-9\ ]/g, '');
      const posEnd = value.target.selectionEnd;
      // 最後一位允許空格，截取前6位
      valueTemp = valueTemp.substring(0, 6);
      const control = this.GetControl(controlName);
      control.patchValue(valueTemp);
      control.markAsDirty();
      value.target.setSelectionRange(posEnd, posEnd);
    }
    if (controlName === 'crfRate') {
      const control = this.GetControl(controlName);
      control.patchValue(value);
      control.markAsDirty();
    }
    if (controlName === 'scaleType' || controlName === 'calcType') {
      this.setCalcTypeOptions();
    }
    if (controlName === 'scalePattern') {
      this.setBreakpoints(value);
    }

    this.commissionItem.scaleCode = this.GetControl('scaleCode').value;
    this.commissionItem.scaleType = this.GetControl('scaleType').value;
    this.commissionItem.calculationType = this.GetControl('calcType').value;
    this.commissionItem.crfRate = this.GetControl('crfRate').value;
    this.commissionItem.breakpointType = this.GetControl('breakpointType').value;
    this.commissionItem.scalePattern = this.GetControl('scalePattern').value;

    if (this.recordDetailFormGroup.status !== 'PENDING') {
      this.recordDetailEnable = this.recordDetailFormGroup.valid;
    }
    this.recordDetailFormGroup.statusChanges.subscribe(status => {
      if (status !== 'PENDING') {
        this.recordDetailEnable = this.recordDetailFormGroup.valid;
        this.commissionItemChange.emit(value);
      }
    });
    this.commissionItemChange.emit(value);
  }

  private GetControl(controlName: string): AbstractControl {
    return this.recordDetailFormGroup.controls[controlName];
  }

  private initFormGroup() {
    if (this.editItem) {
      const scaleCodeControl = this.GetControl('scaleCode') as FormControl;
      const scaleTypeControl = this.GetControl('scaleType') as FormControl;
      const calcTypeControl = this.GetControl('calcType') as FormControl;
      const crfRateControl = this.GetControl('crfRate') as FormControl;
      const breakpointTypeControl = this.GetControl('breakpointType') as FormControl;
      const scalePatternControl = this.GetControl('scalePattern') as FormControl;

      scaleCodeControl.patchValue(this.editItem.scaleCode.replace(/\s/g, ''));
      scaleCodeControl.markAsDirty();
      scaleCodeControl.addValidators([requiredScaleCode()]);
      scaleCodeControl.clearAsyncValidators();
      scaleCodeControl.addAsyncValidators(existCodeAsyncValidator(this.commissionScaleService, this.editItem.scaleCode?.replace(/\s/g, '')));
      scaleCodeControl.updateValueAndValidity();
      scaleCodeControl.disable();

      scaleTypeControl.patchValue(this.editItem.scaleType);
      scaleTypeControl.markAsDirty();
      this.setCalcTypeOptions();
      scaleTypeControl.disable();

      calcTypeControl.patchValue(this.editItem.calculationType);
      calcTypeControl.markAsDirty();
      calcTypeControl.disable();

      crfRateControl.patchValue(this.editItem.crfRate);
      crfRateControl.markAsDirty();
      crfRateControl.disable();

      breakpointTypeControl.patchValue(this.editItem.breakpointType);
      breakpointTypeControl.markAsDirty();
      breakpointTypeControl.disable();

      scalePatternControl.patchValue(this.editItem.scalePattern);
      scalePatternControl.markAsDirty();
      scalePatternControl.disable();
    }
  }

  private setDisabledOfInputControl() {
    if (this.isEditPage || this.isSubmitted) {
      this.GetControl('scaleCode').disable();
      this.GetControl('crfRate').disable();
    } else {
      this.GetControl('scaleCode').enable();
      this.GetControl('crfRate').enable();
    }
  }

  // set calculation type options
  private setCalcTypeOptions() {
    const scaleType = this.GetControl('scaleType').value;
    const isFYOrPC = scaleType === ScaleType.FY || scaleType === ScaleType.FS_PC || scaleType === ScaleType.PS_PC;
    if (isFYOrPC && this.calcTypeOpts.length === 0) {
      this.calcTypeOpts2.next(JSON.parse(JSON.stringify(CALCULATION_TYPE_OPTIONS)));
    }
    this.GetControl('calcType').enable();
    if (!isFYOrPC && !this.GetControl('calcType').value) {
      this.calcTypeOpts2.next([]);
      this.GetControl('calcType').disable();
    }
  }

  private setBreakpoints(value: any) {
    if (value === ScalePattern.FLAT) {
      this.dataSource.forEach(data => {
        data.list = [data.list[0]];

        data.list[0].amount = null;
        data.list[0].amountError = '';
        if (!data.list[0].percentage) {
          data.list[0].percentage = null;
        }
      });
    }
    if (value === ScalePattern.SLIDING) {
      this.dataSource.forEach(data => {
        let differ = data.list.length - 3;
        while (differ < 0) {
          data.list.push(JSON.parse(JSON.stringify(DEFAULT_BREAKPOINT)));
          differ++;
        }
      });
    }
  }
}
