import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PermissionAccess } from 'src/app/core/models/enum/permission.enum';
import { AdminLayoutComponent } from 'src/app/views/layout/admin-layout/admin-layout.component';
import { AgentReassignmentComponent } from './agent-reassignment/agent-reassignment.component';
import { AutoRolloverListComponent } from './auto-rollover/auto-rollover-list/auto-rollover-list.component';
import { AutoRolloverPaymentListComponent } from './auto-rollover/auto-rollover-payment-list/auto-rollover-payment-list.component';
const routes: Routes = [
  {
    path: 'shk',
    component: AdminLayoutComponent,
    children: [
      {
        path: 'auto-rollover/list',
        component: AutoRolloverListComponent,
        data: {
          title: 'rollover.list-title',
          roles: {
            SHK_AUTO_ROLLOVER_OPS_STAFF: PermissionAccess.R,
          },
        },
      },
      {
        path: 'auto-rollover-payment/list',
        component: AutoRolloverPaymentListComponent,
        data: {
          title: 'rollover.payment-list-title',
          roles: {
            SHK_AUTO_ROLLOVER_OPS_STAFF: PermissionAccess.R,
          },
        },
      },
      {
        path: 'agent-reassignment',
        component: AgentReassignmentComponent,
        data: {
          title: 'agent.reassignment-title',
          roles: {
            SHK_AGENT_ASSIGNMENT_REASSIGN: PermissionAccess.W,
          },
        },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ShkRoutingModule {}
