/* eslint-disable @typescript-eslint/no-explicit-any */
import { deepCopy } from 'src/app/utils/copy';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, FormArray, Validators, AbstractControl } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { CdsButtonConfig } from '@cds/ng-core/button';
import { CdsLangService } from '@cds/ng-core/lang';
import { CdsTableComponent } from '@cds/ng-data-table/table';
import { CdsPopupService } from '@cds/ng-web-components/popup';
import { DateTime } from 'luxon';
import moment from 'moment';
import { finalize } from 'rxjs';
import { PermissionAccess, PermissionItem } from 'src/app/core/models/enum/permission.enum';
import { PermissionService } from 'src/app/core/services/permission.service';
import { AlertPopupComponent } from 'src/app/shared/alert-popup/alert-popup.component';
import { ContinuePopupComponent } from 'src/app/shared/continue-popup/continue-popup.component';
import { dateValidator, hkidValidator, passportValidator } from 'src/app/shared/validators/validators';
import { AgentSep, AgentSepCheckResult, AgentSepCheckResultTypeE, AgentSepWarn, agentSepUserIdTypeConfig, agentSepUserIdTypeOptions } from '../employer';
import { EmployerService } from '../employer.service';
import { CdsIconConfig } from '@cds/ng-core/icon';
import { ToastAlertService } from 'src/app/shared/toast-alert.service';

@Component({
  selector: 'app-agent-sep-add',
  templateUrl: './agent-sep-add.component.html',
  styleUrls: ['./agent-sep-add.component.scss'],
})
export class AgentSepAddComponent implements OnInit {
  @ViewChild('table') table!: CdsTableComponent<AgentSep>;

  isLoading = false;
  inEdit = true;

  saveButtonConfig: CdsButtonConfig = { size: 'sm' };

  resetButtonConfig: CdsButtonConfig = { size: 'sm', style: 'secondary' };

  newData: AgentSep = {
    sepCommDate: '',
    hkid: '',
    userIdType: '',
    userId: '',
  };

  get resetDisabled() {
    return !this.form.dirty || this.isLoading;
  }

  get continueDisabled() {
    return !(this.form.dirty && this.form.valid) || this.isLoading;
  }

  displayedColumnsInSummary: string[] = ['number', 'sepCommDate', 'userId', 'dob'];

  displayedColumnsInEdit: string[] = [...this.displayedColumnsInSummary, 'delete'];

  haveDeletePermission = false;

  form: FormGroup = new FormGroup({});

  warnList: AgentSepWarn[] = [];

  nowTimeInstr = DateTime.now().toFormat('dd/MM/yyyy');

  maxTime = this.getNowTime();

  maxTimeInStr = DateTime.fromJSDate(this.maxTime).toFormat('dd/MM/yyyy');

  agentSepUserIdTypeConfig = agentSepUserIdTypeConfig;

  formReady = true;

  newDataIndex = 0;

  invalidHkidTip = 'Please input HKID with format XXXXXXX(X)';

  invalidPassportTip = 'Please enter the correct passport number';

  get tableArray() {
    return this.form?.get('tableArray') as FormArray;
  }

  checkResultInfoList: AgentSepCheckResult[] = [];

  AgentSepCheckResultTypeE = AgentSepCheckResultTypeE;

  warnIconConfig: CdsIconConfig = {
    color: '#D77D28',
  };

  errorIconConfig: CdsIconConfig = {
    color: '#C14A36',
  };

  get confirmDisabled() {
    return this.checkResultInfoList.some(item => item.type === AgentSepCheckResultTypeE.ERR);
  }

  checked = false;

  checkOk = false;

  _confirmFlag = false;

  showConfirm = false;

  showButton = true;

  constructor(
    private dialogRef: MatDialogRef<AgentSepAddComponent>,
    private cdsPopup: CdsPopupService,
    private langService: CdsLangService,
    private service: EmployerService,
    private permissionService: PermissionService,
    private toastAlert: ToastAlertService
  ) {}

  ngOnInit(): void {
    this.permissionService.hasPermission(PermissionAccess.E, PermissionItem.CUSTOMER_EMPLOYER).then(havePermission => {
      if (havePermission) {
        this.haveDeletePermission = true;
      }
    });
    this.initForm();
  }

  initForm() {
    this.form?.markAsUntouched();
    this.form?.markAsPristine();

    this.checkResultInfoList = [];
    this.warnList = [];

    this.form = new FormGroup({
      tableArray: new FormArray([]),
    });
    this.add();
  }

  getDisplayedColumns(inEdit: boolean) {
    return inEdit && this.haveDeletePermission ? this.displayedColumnsInEdit : this.displayedColumnsInSummary;
  }

  backToEdit() {
    this.inEdit = true;

    this.reloadButton();
  }

  continue() {
    if (this.continueDisabled) {
      return;
    }

    if (this.checkOk) {
      this.inEdit = false;
    } else {
      this.checkResultInfoList = [];
      this.warnList = [];
      this.check();
    }

    this.reloadButton();
  }

  getRequestList() {
    const list = this.tableArray.controls.map(item => {
      const params: AgentSep = deepCopy(item.value);
      if (params.userIdType == 'hkid') {
        params.hkid = params.userId;
        params.passport = '';
      } else {
        params.passport = params.userId;
        params.hkid = '';
      }
      params.sepCommDate = this.requestDateTrans(params.sepCommDate);
      params.dob = this.requestDateTrans(params.dob);
      delete params.userId;
      delete params.userIdType;
      return params;
    });
    return list;
  }

  delete(index: number) {
    this.tableArray?.removeAt(index);
  }

  confirm() {
    this.addRequest();

    this.reloadButton();
  }

  check() {
    const requestList = this.getRequestList();

    this.isLoading = true;

    this.showConfirm = false;

    this.service
      .agentSepAdd(requestList, true)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe({
        next: res => {
          if (res.result === 0 && res.data) {
            if (res.data.result === false) {
              this.checked = true;
              this.checkResultInfoList = res.data.tips || [];

              this.checkResultInfoList.forEach(item => {
                let key = '';
                switch (item.field) {
                  case '1':
                    key = 'sepCommDate';
                    break;
                  case '2':
                    key = 'userId';
                    break;
                  case '3':
                    key = 'dob';
                    break;
                }

                if (item.type === AgentSepCheckResultTypeE.ERR) {
                  this.tableArray
                    .at(item.row - 1)
                    ?.get(key)
                    ?.setErrors({ backendInvalid: item.message });
                } else if (item.type === AgentSepCheckResultTypeE.WARN) {
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  const warn: any = this.warnList[item.row - 1] || {
                    sepCommDate: '',
                    dob: '',
                    userIdType: '',
                    userId: '',
                  };
                  warn[key] = item.message;

                  this.showConfirm = true;

                  this.warnList[item.row - 1] = warn;
                }
              });

              this.form.updateValueAndValidity();

              console.log('tableArray', this.tableArray);
              console.log('warnList', this.warnList);

              if (this.checkResultInfoList.some(item => item.type === AgentSepCheckResultTypeE.ERR)) {
                this.checkOk = false;
                this.inEdit = true;
              } else if (this.checkResultInfoList.some(item => item.type === AgentSepCheckResultTypeE.WARN)) {
                this.checkOk = true;
              } else {
                this.checkOk = true;
                this.inEdit = false;
              }
            } else {
              this.inEdit = false;
            }
          } else {
            this.toastError(res.message);
          }
        },
      });
  }

  toastError(errorMessage: string) {
    this.toastAlert.show('error', 'common.error', errorMessage, 5000);
  }

  addRequest() {
    const requestList = this.getRequestList();

    this.isLoading = true;

    this.service
      .agentSepAdd(requestList)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe({
        next: res => {
          if (res.result === 0) {
            this.popupWithReload();
          } else {
            this.requestAlert(res?.message);
          }
        },
        error: err => {
          this.requestAlert(err?.message);
        },
      });
  }

  popupWithReload() {
    this.cdsPopup
      .open(AlertPopupComponent, {
        size: 'sm',
        data: {
          message: this.langService.translate('common.action.success.italic'),
          buttonName: this.langService.translate('common.gotItWithExclamation'),
        },
      })
      .afterClosed()
      .subscribe(() => {
        this.dialogRef.close({
          agree: true,
          reload: true,
        });
      });
  }

  requestAlert(message: string) {
    this.cdsPopup
      .open(AlertPopupComponent, {
        size: 'sm',
        data: {
          title: 'Error',
          message: `<span class='cds-h4-light'>${message}</span>`,
          buttonName: this.langService.translate('confirm'),
        },
      })
      .afterClosed()
      .subscribe(() => {});
  }

  reset() {
    if (this.resetDisabled) {
      return;
    }
    this.cdsPopup
      .open(ContinuePopupComponent, {
        size: 'sm',
        data: { message: this.langService.translate('common.action.cancel.italic') },
      })
      .afterClosed()
      .subscribe(result => {
        if (result?.agree) {
          this.initForm();
        }
      });

    this.reloadButton();
  }

  close() {
    if (!this.resetDisabled) {
      this.cdsPopup
        .open(ContinuePopupComponent, {
          size: 'sm',
          data: { message: this.langService.translate('common.action.cancel.italic') },
        })
        .afterClosed()
        .subscribe(result => {
          if (result?.agree) {
            this.dialogRef.close();
          }
        });
    } else {
      this.dialogRef.close();
    }
  }

  add() {
    this._confirmFlag = true;
    const data: AgentSep = deepCopy(this.newData);
    data.number = this.newDataIndex;
    this.newDataIndex++;

    const newFormGroup = new FormGroup({
      sepCommDate: new FormControl('', [Validators.required, dateValidator('create.dateError', '', this.maxTimeInStr)]),
      dob: new FormControl('', [Validators.required, dateValidator('create.dateError', '', this.maxTimeInStr)]),
      userIdType: new FormControl('hkid'),
      userId: new FormControl('', [Validators.required]),
    });

    this.tableArray.push(newFormGroup);
  }

  getNowTime() {
    return moment().toDate();
  }

  getControlValue = (key: string, index: number) => {
    return this.tableArray?.at(index)?.get(key)?.value;
  };

  getWarnMessage = (key: string, index: number) => {
    console.log('getWarnMessage', (this.warnList?.[index] as any)?.[key]);
    return (this.warnList?.[index] as any)?.[key];
  };

  getUserIdStr = (index: number) => {
    const typeValue = this.getControlValue('userIdType', index);
    const typeLabel = this.service.optionLabelRender(typeValue, agentSepUserIdTypeOptions);

    return `${typeLabel} ${this.getControlValue('userId', index)}`;
  };

  requestDateTrans(date?: string) {
    if (!date) return '';
    return DateTime.fromFormat(date, 'dd/MM/yyyy').toFormat('yyyy-MM-dd');
  }

  getControl(formControlName: string, index: number) {
    return this.tableArray.at(index).get(formControlName);
  }

  getMaxFromDate = (toDateStr?: string) => {
    if (!toDateStr) {
      return this.getNowTime();
    }
    return moment(toDateStr, 'DD/MM/YYYY').toDate();
  };

  dropdownChange(value: string, index: number) {
    const dropdownContorl = this.getControl('userId', index);

    dropdownContorl?.clearValidators();
    dropdownContorl?.setErrors({});
    dropdownContorl?.updateValueAndValidity();

    if (value === 'hkid') {
      dropdownContorl?.setValidators([Validators.required, Validators.maxLength(80), hkidValidator(this.invalidHkidTip)]);
    } else {
      dropdownContorl?.setValidators([Validators.required, Validators.maxLength(80), passportValidator(this.invalidPassportTip)]);
    }
  }

  userIdChange(input: string, control: AbstractControl | null) {
    const value = input.replace(/[\u4e00-\u9fa5]/gi, '');
    control?.setValue(value.trim());
  }

  onControlChange(key: string, index: number) {
    (this.warnList[index] as any)[key] = '';

    this.checkOk = false;
  }

  reloadButton = () => {
    this.showButton = false;
    setTimeout(() => {
      this.showButton = true;
    });
  };
}
