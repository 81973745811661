<div class="edit-agent-details">
  <div>
    <span class="cds-h2-light">New Commission Agent</span>
  </div>
  <div class="l-mt-3">
    <span class="cds-body1">Add new commission agent by typing agent code.</span>
  </div>
  <div class="input-search">
    <app-cd-input
      [(value)]="agentCode"
      (valueChange)="valueChange($event)"
      [isHasErrors]="!!errorMsg"
      [regExpLimit]="'^[0-9]{0,6}$'"
      [placeholder]="'Input agent code'"></app-cd-input>
    <div class="l-mt-2" *ngIf="errorMsg">
      <cds-assistive-text>
        {{ errorMsg }}
      </cds-assistive-text>
    </div>
    <cds-icon icon="form:search"></cds-icon>
  </div>
  <div style="margin-top: 79px">
    <cds-button class="btn" [style]="'secondary'" size="sm" (click)="onCancel()">Cancel</cds-button>
    <cds-button class="l-ml-4 btn" size="sm" (click)="add()">Add</cds-button>
  </div>
  <cds-icon class="close" icon="action:cross" (click)="onCancel()"></cds-icon>
</div>
