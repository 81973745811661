/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, HostListener, OnInit } from '@angular/core';
import { CdsButtonConfig } from '@cds/ng-core/button';
import moment from 'moment';
import { finalize } from 'rxjs';
import { IPaginationCount } from 'src/app/shared/pagination/pagination.dto';
import { ToastAlertService } from 'src/app/shared/toast-alert.service';

import { accountStatusDropdownOptions, agentFollowUpStatusDropdownOptions, EnrolmentRecord, EnrolmentSearchRequest } from '../enrolment/enrolment';
import { EnrolmentService } from '../enrolment/enrolment.service';
import { AgentPerformanceService } from './agent-performance-enrolment.service';
import { CdsDropdownConfig } from '@cds/ng-core/dropdown';
import { EmployerService } from '../employer/employer.service';
import { DateTime } from 'luxon';
import { ColumnSort, I18N_KEY } from '../employer/employer';
import { ColumnConfig, PageConfig, SortMode } from 'src/app/shared/data-table';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { dateValidator, descriptionValidator, numberStringValidator, startAndEndDateValidator } from 'src/app/shared/validators/validators';
import { deepCopy } from 'src/app/utils/copy';

@Component({
  selector: 'app-agent-performance-enrolment',
  templateUrl: './agent-performance-enrolment.component.html',
  styleUrls: ['./agent-performance-enrolment.component.scss'],
})
export class AgentPerformanceEnrolmentComponent implements OnInit {
  searchDTO: EnrolmentSearchRequest = {
    page: 0,
    size: 10,
    totalLives: '',
    trusteeEmployerAccountNumber: '',
    companyName: '',
    employDateStart: '',
    employDateEnd: '',
    agentCode: '',
  };

  exportParams: EnrolmentRecord = {};

  searchButtonConfig: CdsButtonConfig = {
    size: 'sm',
  };

  resetButtonConfig: CdsButtonConfig = {
    size: 'sm',
    style: 'secondary',
  };

  exportButtonConfig: CdsButtonConfig = {
    size: 'sm',
    style: 'secondary',
  };

  get searchButtonDisabled() {
    return !this.form?.valid || !this.form.dirty;
  }

  get resetButtonDisabled() {
    return !this.form?.dirty;
  }

  I18N_KEY = I18N_KEY;

  exportButtonDisabled = true;

  _progress = false;

  _totalPages = 0;

  _totalCount = 0;

  pageCount: IPaginationCount = { current: 1, count: 1 };

  _recordList: EnrolmentRecord[] = [];

  _number = 1;

  _inputNumber = 1;

  isMobile = false;

  showDatePicker = true;

  formReady = false;

  agentFollowUpStatusDropdownOptions = [...agentFollowUpStatusDropdownOptions];
  accountStatusDropdownOptions = [...accountStatusDropdownOptions];

  agentFollowUpStatusConfig: CdsDropdownConfig = {
    label: 'Agent Follow Up Status',
    placeholder: 'Select Agent Follow Up Status',
    options: [...agentFollowUpStatusDropdownOptions],
  };
  accountStatusConfig: CdsDropdownConfig = {
    label: 'Account Status',
    placeholder: 'Select Account Status',
    options: [...accountStatusDropdownOptions],
  };

  columnsConfig: ColumnConfig[] = [
    {
      key: 'dateOfEmploy',
      title: 'Date of Employment',
      sticky: true,
      sort: SortMode.DEFAULT,
    },
    {
      key: 'createdAt',
      title: 'Assignment Date',
      sticky: true,
      sort: SortMode.DEFAULT,
    },
    {
      key: 'trusteeEmployerAccountNumber',
      title: 'Employer Account Code and Name',
      stickyEnd: true,
      sort: SortMode.DEFAULT,
    },
    {
      key: 'empfMemberAccountNumber',
      title: 'Employee Name',
      sort: SortMode.DEFAULT,
    },
    {
      key: 'preassignedAgentCode',
      title: 'Preassigned Agent',
      sort: SortMode.DEFAULT,
    },
    {
      key: 'agentFollowUpStatus',
      title: 'Agent Follow Up Status',
    },

    // {
    //   key: 'accountStatus',
    //   title: 'Account Status',
    // },
  ];
  pageConfig: PageConfig = {
    current: 1,
    pageSize: 10,
    totalCount: 0,
  };

  tempSeardhParams: EnrolmentSearchRequest = {
    page: 1,
    size: 10,
  };

  nowTimeInstr = DateTime.now().toFormat('dd/MM/yyyy');

  maxTime = this.getNowTime();

  maxTimeInStr = DateTime.fromJSDate(this.maxTime).toFormat('dd/MM/yyyy');

  columnSort: ColumnSort = {
    key: 'createdAt',
    value: SortMode.DOWN,
  };

  form: FormGroup = new FormGroup({});

  employerNameValidTip = 'Please input English, Chinese, numbers, or special characters';

  get trusteeEmployerAccountNumberControl() {
    return this.form?.get('trusteeEmployerAccountNumber');
  }

  get companyNameControl() {
    return this.form?.get('companyName');
  }

  get employDateStartControl() {
    return this.form?.get('employDateStart');
  }

  get employDateEndControl() {
    return this.form?.get('employDateEnd');
  }

  get agentCodeControl() {
    return this.form?.get('agentCode');
  }

  get agentFollowUpStatusControl() {
    return this.form?.get('agentFollowUpStatus');
  }

  constructor(
    public service: AgentPerformanceService,
    public enrolmentService: EnrolmentService,
    private toastAlert: ToastAlertService,
    public employerService: EmployerService
  ) {}

  ngOnInit(): void {
    this.onResize();

    this.initForm();
    this.loadTable();
  }

  initForm() {
    this.form?.markAsUntouched();
    this.form?.markAsPristine();

    this.form = new FormGroup(
      {
        trusteeEmployerAccountNumber: new FormControl('', [Validators.maxLength(15), numberStringValidator()]),
        companyName: new FormControl('', [Validators.maxLength(200), descriptionValidator(this.employerNameValidTip, true)]),
        employDateStart: new FormControl('', [dateValidator('create.dateError', '', '')]),
        employDateEnd: new FormControl('', [dateValidator('create.dateError', '', '')]),
        agentCode: new FormControl('', [Validators.maxLength(20), numberStringValidator()]),
        agentFollowUpStatus: new FormControl(''),
      },
      { validators: startAndEndDateValidator('create.dateError', 'employDateStart', 'employDateEnd') }
    );
    this.formReady = false;
    setTimeout(() => {
      this.formReady = true;
    });
  }

  reloadTable(event: any) {
    this.pageConfig = {
      ...this.pageConfig,
      current: event.current,
    };
    this.columnSort = event.column
      ? {
          key: event.column?.key,
          value: event.column?.sort,
        }
      : {
          key: 'createdAt',
          value: SortMode.DOWN,
        };

    this.loadTable();
  }

  loadTable() {
    const params: EnrolmentSearchRequest = this.getRequestParams();
    this._progress = true;
    this.exportButtonDisabled = true;
    this.service
      .getEnrolmentMemberList(params, this.columnSort)
      .pipe(
        finalize(() => {
          this._progress = false;
        })
      )
      .subscribe(data => {
        if (data.result === 0 && data.data) {
          this._progress = false;
          this._recordList = data.data.empty ? [] : data.data.content;
          this._totalCount = data.data.totalElements;
          this.pageConfig = {
            ...this.pageConfig,
            totalCount: data.data.totalElements,
          };
        }
      });
  }

  getTempSearchParams() {
    const params: EnrolmentSearchRequest = {
      ...this.tempSeardhParams,
      ...(this.form.valid ? this.form.value : {}),
      page: (this.pageConfig.current as number) - 1,
      size: 10,
    };

    params.employDateStart = params.employDateStart ? this.dateTrans(params.employDateStart) : '';
    params.employDateEnd = params.employDateEnd ? this.dateTrans(params.employDateEnd) : '';

    return params;
  }

  getRequestParams() {
    const params: EnrolmentSearchRequest = {
      ...this.tempSeardhParams,
      page: (this.pageConfig.current as number) - 1,
      size: 10,
    };
    const exportParams: any = { ...params };
    delete exportParams.page;
    delete exportParams.size;
    delete exportParams.totalLives;
    this.exportParams = exportParams;
    return params;
  }

  dateTrans(date: string) {
    if (date) {
      return moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD');
    }

    return date;
  }

  search() {
    if (this.searchButtonDisabled) {
      return;
    }
    this.pageConfig = {
      ...this.pageConfig,
      current: 1,
    };
    this.tempSeardhParams = deepCopy(this.getTempSearchParams());
    this.loadTable();
  }

  reset() {
    if (this.resetButtonDisabled) {
      return;
    }
    this.pageConfig = {
      ...this.pageConfig,
      current: 1,
    };

    this.tempSeardhParams = {
      page: 1,
      size: 10,
    };

    this.initForm();

    this.showDatePicker = false;
    setTimeout(() => {
      this.showDatePicker = true;
      this.loadTable();
    });
  }

  onPageChange(page: number) {
    this.searchDTO.page = page - 1;
    this._inputNumber = page;
    this.loadTable();
  }

  onGoPage(num: number) {
    if (num < 1) {
      num = 1;
    }
    if (num > this._totalPages) {
      num = this._totalPages;
    }
    this._number = num;
    this._inputNumber = num;
  }

  getMinTime() {
    return moment().subtract(5, 'year').add(1, 'day').toDate();
  }

  getNowTime() {
    return moment().toDate();
  }

  getTime(dateStr: string) {
    return moment(dateStr, 'DD/MM/YYYY').toDate();
  }

  getMaxFromDate = (toDateStr = '31/12/9999') => {
    return moment(toDateStr, 'DD/MM/YYYY').toDate();
  };

  getMinToDate = (fromDateStr = '01/01/0000') => {
    return moment(fromDateStr, 'DD/MM/YYYY').toDate();
  };

  @HostListener('window:resize', ['$event'])
  onResize() {
    if (window.innerWidth < 845) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
  }
}
