export const IFF_STATUS = [
  {
    text: 'Pending MD_eIFF Record',
    info: undefined,
    color: 'var(--cds-color-light-1-violet)',
    value: 1,
  },
  {
    text: 'Pending eMPF Reference No.',
    info: undefined,
    color: 'var(--cds-color-gold)',
    value: 2,
  },
  {
    text: 'Pending Split Agent(s)',
    info: 'The IFF is pending for split agent acknowledgement',
    color: 'var(--cds-color-turquoise)',
    value: 3,
  },
  {
    text: 'Pending Customer Submission',
    info: 'The IFF is sent and pending for customer submission',
    color: 'var(--cds-color-jh-blue)',
    value: 4,
  },
  {
    text: 'Completed',
    info: 'The IFF form is received from customer',
    color: 'var(--cds-color-dark-1-green)',
    value: 5,
  },
  {
    text: 'Declined',
    info: undefined,
    color: 'var(--cds-color-dark-1-coral)',
    value: 6,
  },
  {
    text: 'Declined (Agent SEP/ Staff CA)',
    info: undefined,
    color: 'var(--cds-color-coral)',
    value: 7,
  },
  {
    text: 'Expired',
    info: undefined,
    color: 'var(--cds-color-light-4-dark-navy)',
    value: 8,
  },
  {
    text: 'Void',
    info: undefined,
    color: 'var(--cds-color-dark-navy)',
    value: 9,
  },
];
