/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { deepCopy } from 'src/app/utils/copy';
import { CdCryptoJsService } from '../cd-crypto-js/cd-crypto-js.service';

@Injectable({
  providedIn: 'root',
})
export class TempCacheService {
  private cache: { [key: string]: any } = {};

  constructor(private cdCryptoJsService: CdCryptoJsService) {
    window.addEventListener('beforeunload', () => {
      const ciphertext = this.cdCryptoJsService.encrypt(JSON.stringify(this.cache));
      localStorage.setItem('store', ciphertext);
    });
    const store = localStorage.getItem('store');
    if (store) {
      this.cache = JSON.parse(this.cdCryptoJsService.decrypt(store));
    }
  }

  setItem(key: string, data: any) {
    this.cache[key] = deepCopy(data);
  }

  getItem(key: string) {
    return deepCopy(this.cache[key]);
  }

  removeItem(key: string) {
    delete this.cache[key];
  }

  saveToLocalStorage() {
    const ciphertext = this.cdCryptoJsService.encrypt(JSON.stringify(this.cache));
    localStorage.setItem('store', ciphertext);
  }
}
