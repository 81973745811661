import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormControl, ValidationErrors, Validators } from '@angular/forms';
import { CdsOption } from '@cds/ng-core/configuration';
import { CdsDropdownConfig } from '@cds/ng-core/dropdown';
import { CdsLangService } from '@cds/ng-core/lang';
import { CdsPopupService } from '@cds/ng-web-components/popup';
import { CdsToastService } from '@cds/ng-web-components/toast';
import { debounceTime, distinctUntilChanged, finalize } from 'rxjs';
import { AclUserType } from 'src/app/core/models/enum/acl-user-type.enum';
import { Identity } from 'src/app/core/models/identity';
import { IAclUserRequest } from 'src/app/core/models/role';
import { RoleService } from 'src/app/core/services/role.service';
import { AlertPopupComponent } from 'src/app/shared/alert-popup/alert-popup.component';
import { IPaginationCount } from 'src/app/shared/pagination/pagination.dto';
import { UserPageableRequest } from '../../role.model';
import { ConfirmPopupComponent } from './../../../../../shared/confirm-popup/confirm-popup.component';

const unSelectAclUserValidator = () => {
  return (control: AbstractControl): ValidationErrors | null => {
    const input = control.value || '';
    if (typeof input !== 'string' && input.length > 2) {
      return null;
    }
    const isValid = input?.id;
    return isValid ? null : { unselectAclUser: true };
  };
};

@Component({
  selector: 'app-role-info-user',
  templateUrl: './role-info-user.component.html',
})
export class RoleInfoUserComponent implements OnInit {
  @Input() roleId: string | null = '';
  isLoading = false;
  aclUserLoading = false;
  aclUserRespOptions: CdsOption[] = [];
  aclUserType: AclUserType = AclUserType.INTERNAL_STAFF;
  aclUsetTypeDropdownConfig: CdsDropdownConfig = {
    options: [],
  };

  dataSource: Identity[] = [];
  selectedRows = new Set<Identity>();
  displayedColumns: string[] = ['username', 'email', 'userId'];
  pageCount: IPaginationCount = { count: 0, current: 0 };
  filterUser = '';

  aclNameFormControl = new FormControl(
    { value: '', disabled: this.aclUserLoading },
    {
      validators: [Validators.required, Validators.minLength(2), unSelectAclUserValidator()],
    }
  );

  constructor(private roleService: RoleService, private toast: CdsToastService, private cdsPopup: CdsPopupService, private langService: CdsLangService) {
    for (const item in AclUserType) {
      this.aclUsetTypeDropdownConfig.options.push({
        label: item,
        value: item,
      });
    }
  }

  ngOnInit(): void {
    this.aclNameFormControl.valueChanges.pipe(debounceTime(500), distinctUntilChanged()).subscribe(selectedValue => {
      if (!selectedValue?.id) {
        this.aclUserSearch(selectedValue);
      }
    });
    this.loadTable();
  }

  private popupWithReload() {
    this.cdsPopup
      .open(AlertPopupComponent, {
        size: 'sm',
        data: {
          message: 'common.action.success',
        },
      })
      .afterClosed()
      .subscribe(() => {
        this.reloadTable();
      });
  }

  addRoleUserEnable(): boolean {
    return !!this.roleId && !this.isLoading && !!this.aclNameFormControl?.value?.id;
  }

  aclUserFormReset(): void {
    if (this.aclNameFormControl?.touched || this.aclNameFormControl?.dirty) {
      this.aclNameFormControl.reset();
      this.aclNameFormControl.markAsPristine();
      this.aclNameFormControl.markAsUntouched();
    }
  }

  addUserToRole() {
    if (!!this.roleId && this.addRoleUserEnable()) {
      this.isLoading = true;
      this.roleService
        .addUserToRole({
          userId: this.aclNameFormControl.value.id,
          userType: this.aclUserType,
          roleId: this.roleId,
        })
        .pipe(
          finalize(() => {
            this.isLoading = false;
          })
        )
        .subscribe({
          next: res => {
            if (res.result === 0) {
              this.popupWithReload();
            } else {
              //FIXME convert result code to message
              this.toast.error(`Operation failed : ${res.message}`);
            }
          },
          error: err => {
            this.toast.error('Operation failed: ' + err);
            console.error(err);
          },
        });
    }
  }

  private aclUserSearch(key: string) {
    if (!!key && key.trim().length > 1 && !this.isLoading) {
      this.aclUserRespOptions = [];
      console.debug(`aclUserSearch=${key}`);
      this.aclUserLoading = true;
      const params: IAclUserRequest = {
        searchString: key,
        userType: this.aclUserType,
        page: 0,
        size: 100,
      };
      //
      this.roleService
        .searchAclUser(params)
        .pipe(
          finalize(() => {
            this.aclUserLoading = false;
          })
        )
        .subscribe({
          next: res => {
            if (res.result === 0 && res.data && res.data.content?.length > 0) {
              this.aclUserRespOptions = res.data.content.map(e => {
                let label = e.userName;
                if (e.agentKey) {
                  label += '('.concat(e.agentKey, ')');
                }
                return {
                  label: label,
                  details: e.email,
                  value: { id: e.id },
                };
              });
            }
          },
          error: err => {
            console.error(err);
          },
        });
    }
  }

  private reloadTable() {
    this.aclUserRespOptions = [];
    this.aclUserFormReset();
    this.pageCount.current = 1;
    this.loadTable();
  }
  loadTable() {
    if (!this.isLoading) {
      this.isLoading = true;
      const req: UserPageableRequest = {
        page: this.pageCount.current - 1,
        size: 100,
      };
      const filter = this.filterUser?.trim();
      if (filter?.length > 0) {
        req.userName = filter;
      }
      this.roleService
        .getIdentityByRoleId(this.roleId || '', req)
        .pipe(
          finalize(() => {
            this.isLoading = false;
          })
        )
        .subscribe({
          next: res => {
            if (res.result === 0 && res.data) {
              if (res.data.content?.length > 0) {
                this.pageCount = {
                  current: res.data.number + 1,
                  count: res.data.totalPages,
                };
                this.dataSource = res.data.content;
              } else {
                this.pageCount = { current: 1, count: 0 };
                this.dataSource = [];
              }
            }
          },
          error: err => {
            console.error(err);
          },
        });
    }
  }

  deleteUser(userId: string) {
    this.dataSource = this.dataSource.filter(data => data.id !== userId);
    if (!this.isLoading) {
      this.isLoading = true;
      this.cdsPopup
        .open(ConfirmPopupComponent, {
          size: 'sm',
          data: { title: this.langService.translate('role.confrim.delete') },
        })
        .afterClosed()
        .subscribe(result => {
          if (result?.agree) {
            this.roleService
              .deleteByRoleUser(userId)
              .pipe(
                finalize(() => {
                  this.isLoading = false;
                })
              )
              .subscribe({
                next: res => {
                  if (res.result === 0) {
                    this.popupWithReload();
                  }
                },
                error: err => {
                  console.error(err);
                },
              });
          } else {
            this.isLoading = false;
          }
        });
    }
  }
}
