import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LayoutHeaderService } from 'src/app/core/services/layout-header.service';
import { AdminHeaderConfig } from '../../layout/admin-layout/admin-header/admin-header-config';
import { EmployerProfileDetail } from '../employer';
import { EmployerService } from '../employer.service';

@Component({
  selector: 'app-employer-profile',
  templateUrl: './employer-profile.component.html',
  styleUrls: ['./employer-profile.component.scss'],
})
export class EmployerProfileComponent implements OnInit {
  constructor(private layoutHeaderService: LayoutHeaderService, private route: ActivatedRoute, private service: EmployerService) {}

  headerButton: AdminHeaderConfig = {
    title: 'er.profile.title',
    backButton: {
      text: 'Employer Enquiry Information Page',
      url: '/employer/list',
    },
  };

  employerProfileDetail?: EmployerProfileDetail;

  ngOnInit(): void {
    document.documentElement.scrollTop = 0;
    this.layoutHeaderService.setHeaderButton(this.headerButton);

    const _id = this.route.snapshot.queryParams['id'];
    if (_id && /.*/.test(_id)) {
      this.getProfileDetail(_id);
    }
  }

  getProfileDetail(id: string) {
    this.service.getProfileDetail(id).subscribe(data => {
      if (data.result === 0) {
        this.employerProfileDetail = data.data;
      }
    });
  }
}
