import { CdsOption } from '@cds/ng-core/configuration';
import { IPageableRequest } from 'src/app/core/models/request';

export interface TerminationRecord {
  id?: string;
  dateOfAssignment?: string;
  terminationDate?: string;
  terminationDateStart?: string;
  terminationDateEnd?: string;
  companyName?: string;
  chineseCompanyName?: string;
  empolyeeName?: string;
  empfMemberAccountNumber?: string;
  age?: number;
  gender?: string;
  employeeMobileNo?: string;
  emailAddress?: string;
  chineseFirstName?: string;
  chineseLastName?: string;
  firstName?: string;
  lastName?: string;
  primaryMobileNumber?: string;
  primaryMobileNumberCountryCode?: string;
  agent?: string;
  passportNumber?: string;
  dateOfBirth?: string;
  transferAmount?: string;
  autoRolloverDate?: string;
  agentName?: string;
  trusteeEmployerAccountNumber?: string;
  servicingAgentName?: string;
  servicingAgentCode?: string;
  preassignedAgentName?: string;
  preassignedAgentCode?: string;
  agentFollowUpStatus?: AgentFollowUpStatusE;
  accountStatus?: string;
  emailChiContent?: string;
  emailEngContent?: string;
  // wahtsAppContent?
  whatAppContent?: string;
  numberOfContact?: string;
  askClient?: boolean;
}

export interface TerminationSearchRequest extends IPageableRequest {
  trusteeEmployerAccountNumber?: string;
  companyName?: string;
  terminationDateStart?: string;
  totalLives?: string;
  terminationDateEnd?: string;
  agentCode?: string;
  agentFollowUpStatus?: AgentFollowUpStatusE;
  accountStatus?: string;
  sort?: string;
  id?: string;
}

export enum SortE {
  ASC = 'asc',
  DESC = 'desc',
  DEFAULT = '',
}

export enum SortIconE {
  UP = 'arrow:up',
  DOWN = 'arrow:down',
  SORT = 'arrow:sort',
}

export enum AgentFollowUpStatusE {
  OUTSTANDING = 'OUTSTANDING',
  INITIAL_CONTACT = 'INITIAL_CONTACT',
  SCHEDULE_MEET_UP = 'SCHEDULE_MEET_UP',
  CANNOT_REACH_CLIENT = 'CANNOT_REACH_CLIENT',
  REJECTED_BY_CLIENT = 'REJECTED_BY_CLIENT',
  COMPLETED = 'COMPLETED',
}

export const agentFollowUpStatusDropdownOptions: CdsOption[] = [
  // {
  //   label: 'Outstanding',
  //   value: AgentFollowUpStatusE.OUTSTANDING,
  // },
  {
    label: 'Initial Contact',
    value: AgentFollowUpStatusE.INITIAL_CONTACT,
  },
  {
    label: 'Schedule Meet Up',
    value: AgentFollowUpStatusE.SCHEDULE_MEET_UP,
  },
  {
    label: 'Cannot Reach Client',
    value: AgentFollowUpStatusE.CANNOT_REACH_CLIENT,
  },
  {
    label: 'Reject By Client',
    value: AgentFollowUpStatusE.REJECTED_BY_CLIENT,
  },
  {
    label: 'Completed',
    value: AgentFollowUpStatusE.COMPLETED,
  },
];

export enum AccountStatusE {
  PENDING = 'PENDING',
  GS = 'GS',
  TRANSFERRED_TO_OTHER_TRUSTEES_WITH_DRAWL = 'TRANSFERRED_TO_OTHER_TRUSTEES_WITH_DRAWL',
}

export const accountStatusDropdownOptions: CdsOption[] = [
  {
    label: 'Pending',
    value: AccountStatusE.PENDING,
  },
  {
    label: 'Transferred to GS',
    value: AccountStatusE.GS,
  },
  {
    label: 'Transferred to other Trustee / Withdrawal   ',
    value: AccountStatusE.TRANSFERRED_TO_OTHER_TRUSTEES_WITH_DRAWL,
  },
];

export const noOfContactOptions: CdsOption[] = [
  { label: '1', value: '1' },
  { label: '2', value: '2' },
  { label: '3', value: '3' },
  { label: '4', value: '4' },
  { label: '5', value: '5' },
];
