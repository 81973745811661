<cds-popup class="relative">
  <app-loading *ngIf="_loading" [overlayInPopup]="true"></app-loading>
  <cds-popup-title>
    <div class="l-pb-2">{{ 'interest-rate.interest-rate-hisotry-log' | lang }}</div>
    <div class="title-mini">{{ 'interest-rate.interest-rate-hisotry-log-tips' | lang }}</div>
  </cds-popup-title>
  <cds-popup-content>
    <div class="row l-plr-0 l-pt-2">
      <div class="col-xs-8 l-plr-0">
        <div class="cds-body2 cds-color-navy-light3">Fund Code</div>
        <div>{{ data.code + ' ' + data.name + ' ' + data.zhName }}</div>
      </div>
      <div class="col-xs-4 l-plr-0">
        <div class="cds-body2 cds-color-navy-light3">Month/ Year</div>
        <div>{{ data.recordSent | ssmsDate }}</div>
      </div>

      <app-table-expand *ngIf="!_loading" class="col-xs-8 l-prl-0 history" size="18px" [data]="dataList">
        <app-table-expand-col width="20px" [template]="index" prop="index" label="#"></app-table-expand-col>
        <app-table-expand-col [template]="sendDate" prop="recordSent" label="Effective Date"></app-table-expand-col>
        <app-table-expand-col [template]="rate" prop="value" label="Withdrawal Rate"> </app-table-expand-col>
      </app-table-expand>
      <ng-template #index let-index="index">
        <span class="l-p-2">{{ index + 1 }}</span>
      </ng-template>
      <ng-template #rate let-element>
        <span>{{ element.value | number : '1.2-2' }} %</span>
      </ng-template>
      <ng-template #sendDate let-element>
        <span>{{ element.recordSent | ssmsDate }}</span>
      </ng-template>
    </div>
  </cds-popup-content>
</cds-popup>
