import { PermissionAccess, PermissionItem } from './enum/permission.enum';

export class Session {
  id!: string;
  mcfId!: string;
  displayName!: string;
  email!: string;
  givenName!: string;
  surname!: string;
  userPrincipalName!: string;
  permissions?: Map<PermissionItem, PermissionAccess>;
  token!: string;
  refreshToken!: string;
  currentLastUpdate!: number;
}
