<div class="row">
  <div class="col-12">
    <cds-progress *ngIf="showProgress" mode="indeterminate" type="bar"></cds-progress>
    <div class="cds-body1 n-des">
      Lorem ipsum dolor sit amet. Qui autem impedit est tenetur reiciendis et sunt rerum qui quisquam animi et repudiandae rerum ab cumque dolore ut accusamus
      ipsum. Doloribus aliquid quo delectus atque aut corporis eius eum maiores optio in autem consequatur quo quidem officiis. Est nisi delectus et laboriosam
      galisum est nihil tempore quo beatae quibusdam. 33 velit omnis in dignissimos saepe aut atque consectetur et dolores mollitia in tenetur galisum nam illo
      eveniet!
    </div>

    <div class="cds-h2-light search-title v-center">Search data</div>
    <div class="search-area row">
      <div class="n-input-area col-sm-12 col-md-9 row">
        <div class="input-area col-sm-12 col-md-4">
          <cds-textfield [(ngModel)]="firstNm" [config]="searchfieldConfig"></cds-textfield>
        </div>
        <div class="input-area col-md-4 col-sm-12">
          <cds-textfield [(ngModel)]="lastNm" [config]="searchfieldConfig2"></cds-textfield>
        </div>
        <div class="input-area col-md-4 col-sm-12">
          <cds-textfield [(ngModel)]="trusteeMbrId" [config]="searchfieldConfig3"></cds-textfield>
        </div>
      </div>
      <div class="n-btn-area col-sm-12 col-md-3 row">
        <div class="btn-area col-sm-3">
          <cds-button (click)="doSearch()" [config]="searchBtnConfig"> </cds-button>
        </div>
        <div class="btn-area col-sm-3">
          <cds-button (click)="reset()" [config]="searchBtnConfig2"></cds-button>
        </div>
      </div>
    </div>
    <div class="n-operation">
      <div class="n-record">
        <div class="cds-h2-light n-o-des v-center">
          {{ 'ee.record.list' | lang }}
        </div>
        <div class="cds-body1 n-o-record-count">
          {{ 'ee.record.total' | lang }} {{ totalCount }}
          {{ 'ee.record.total.des' | lang }}
        </div>
      </div>
      <div class="n-o-btn v-center">
        <!-- <div class="button-area">
          <div class="icon-size">
            <cds-icon size="sm" [icon]="iconType"></cds-icon>
          </div>
          <button class="button-border-none-font" (click)="addEmploye()">
            AddEmploye
          </button>
        </div> -->
      </div>
    </div>

    <cds-table [dataSource]="employeeList" cdsSort (cdsSortChange)="onSortChange($event)" [class.full]="fullWidth">
      <!-- Position Column -->
      <ng-container cdsColumnDef="legalId">
        <!-- This particular sort header is disabled -->
        <cds-header-cell *cdsHeaderCellDef cds-sort-header disabled="true"> legalId </cds-header-cell>
        <cds-cell *cdsCellDef="let element">
          <a href="javascript:void(0)" (click)="goToDetail(element)">{{ element.legalId }}</a>
        </cds-cell>
      </ng-container>

      <!-- Name Column -->
      <ng-container cdsColumnDef="legalIdType">
        <cds-header-cell *cdsHeaderCellDef cds-sort-header> legalIdType </cds-header-cell>
        <cds-cell *cdsCellDef="let element">
          {{ element.legalIdType }}
        </cds-cell>
      </ng-container>

      <!-- Symbol Column -->
      <ng-container cdsColumnDef="engSurname">
        <cds-header-cell *cdsHeaderCellDef cds-sort-header> engSurname </cds-header-cell>
        <cds-cell *cdsCellDef="let element"> {{ element.engSurname }} </cds-cell>
      </ng-container>

      <ng-container cdsColumnDef="engGivenName">
        <cds-header-cell *cdsHeaderCellDef cds-sort-header> engGivenName </cds-header-cell>
        <cds-cell *cdsCellDef="let element">
          {{ element.engGivenName }}
        </cds-cell>
      </ng-container>

      <cds-header-row *cdsHeaderRowDef="displayedColumns"></cds-header-row>
      <cds-row *cdsRowDef="let row; columns: displayedColumns"></cds-row>
    </cds-table>

    <div class="n-pagenation v-center row">
      <div class="col-sm-1"></div>
      <div class="pagenation-area col-sm-11">
        <div class="page-choose">
          <!-- <app-pagination
            [pageCount]="pageCount"
            [useOf]="true"
            (pageChange)="pageChange($event)"
            [useOf]="true"></app-pagination> -->
          <div class="col-xs-12 col-md-12 l-pt-4 l-pb-4 l-d-f l-jc-sb">
            <app-pagination [pageCount]="pageCount" [isLoading]="isLoading" [textfieldAlwaysAvailable]="true" (pageChange)="pageChange()"> </app-pagination>
          </div>
        </div>
        <!-- <div class="page-input">
          <div style="margin-right: 10px" class="page-input-text">
            {{ 'ee.page' | lang }}
          </div>

          <cds-textfield
            [config]="txtfieldConfig"
            #pageNoSearchValue
            [(ngModel)]="pageindex"
            (change)="
              searchByInputPageNo(pageNoSearchValue.value)
            "></cds-textfield>

          <div style="margin-left: 10px" class="page-input-text">
            of {{ pageCount.count }}
          </div>
        </div> -->
      </div>
    </div>
    <div class="n-c-line last-line"></div>
  </div>
</div>
