<app-cd-page-title title="{{ 'IT Support' | lang }}"> </app-cd-page-title>
<div class="row">
  <div class="col-xs-12 col-md-12 l-pt-6">
    <div class="cds-h2 cds-light scheme-details">
      {{ 'Commission Down Time' | lang }}
    </div>
    <div class="row l-pa-0">
      <div class="col-xs-12 col-md-3 l-pl-0 l-pr-4">
        <label class="cds-body2 cds-dark-navy-text light-3 l-d-b l-mb-1">{{ 'Work flow Start Time' | lang }}</label>
        <app-cd-time-picker ngDefaultControl [formControl]="downTime"></app-cd-time-picker>
      </div>
      <div class="col-xs-12 col-md-3 l-pr-0">
        <cds-button class="l-mr-4" size="md" [style]="'secondary'" (click)="reset()">{{ 'common.reset' | lang }} </cds-button>
        <cds-button type="submit" [disabled]="isSaveDisable" size="md" (click)="save()">{{ 'common.save' | lang }} </cds-button>
      </div>
    </div>
  </div>
  <div class="col-xs-12 col-md-12 l-pt-7">
    <div class="cds-h2 cds-light scheme-details">
      {{ 'Customer Data Creation' | lang }}
    </div>
    <div class="row l-pa-0">
      <div class="col-xs-12 col-md-3 l-pl-0 l-pr-4 l-mb-4">
        <cds-file-upload
          ngDefaultControl
          (change)="fileChange($event)"
          [formControl]="formControl"
          [uploading]="uploading"
          acceptedFiles="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet">
        </cds-file-upload>
        <div class="error-message-area l-mt-2" *ngIf="errorMessage">
          <cds-icon class="l-mr-2" icon="action:warning" [config]="{ color: '#830A10', size: 'xs' }"></cds-icon>
          <div class="cds-body1-demibold">{{ errorMessage }}</div>
        </div>
      </div>
      <!-- <div class="col-xs-12 col-md-3 l-pl-0 l-pr-0">
        <cds-button type="submit" [disabled]="isUploadDisable" size="md" (click)="upload()">{{ 'common.upload' | lang }} </cds-button>
      </div> -->
    </div>
  </div>
</div>
