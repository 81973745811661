// export class producttypeResponse {
//   pendingsend?: number;
//   pendingcustomer?: number;
//   pendingAgent?: number;
//   result?: string;
// }
export class agentinfoResponse {
  result?: number;
  message?: string;
  data?: CheckResponseData;
}

export interface CheckResponseData {
  agentId?: string;
  agentCode?: string;
  agentName?: string;
  agentNameTC?: string;
  mobileNumber?: string;
  emailAddress?: string;
}
