import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-cd-multiple-accordion-panel',
  templateUrl: './cd-multiple-accordion-panel.component.html',
  styleUrls: ['./cd-multiple-accordion-panel.component.scss'],
})
export class CdMultipleAccordionPanelComponent implements OnInit {
  @Input() upIcon = 'action:button_down_outlined';
  @Input() downIcon = 'action:button_down_filled';
  expand = false;

  ngOnInit(): void {}

  trigger() {
    this.expand = !this.expand;
  }
}
