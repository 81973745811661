import { FormControl } from '@angular/forms';
import { Component, OnInit, Input } from '@angular/core';

import { CdsInputTypes } from '@cds/ng-core/textfield';

@Component({
  selector: 'app-cd-textfield',
  templateUrl: './cd-textfield.component.html',
  styleUrls: ['./cd-textfield.component.scss'],
})
export class CdTextfieldComponent implements OnInit {
  @Input() placeholder = '';
  @Input() disable = false;
  @Input() formControl!: FormControl;
  @Input() type: CdsInputTypes = 'text';

  constructor() {}

  ngOnInit(): void {}
}
