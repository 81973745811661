import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ee-profile-employment-info',
  templateUrl: './ee-profile-employment-info.component.html',
  styleUrls: ['./ee-profile-employment-info.component.scss'],
})
export class EeProfileEmploymentInfoComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
