import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CdsProgressModule } from '@cds/ng-web-components/progress';
import { CdsIconModule } from '@cds/ng-web-components/icon';
import { CdsLanguageModule } from '@cds/ng-core/lang';

import { CdDownloadProgressComponent } from './cd-download-progress.component';

@NgModule({
  imports: [CommonModule, CdsProgressModule, CdsIconModule, CdsLanguageModule],
  declarations: [CdDownloadProgressComponent],
  exports: [CdDownloadProgressComponent],
})
export class CdDownloadProgressModule {}
