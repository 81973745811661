<div class="empf-app-status-popup">
  <div class="content-box">
    <div>
      <span class="cds-body-hero-light">eMPF Status</span>
    </div>
    <div class="l-mt-6">
      <div *ngFor="let item of EMPF_APP_STATUS; let i = index" class="l-d-f l-mb-4">
        <div>
          <div class="round" [ngStyle]="{ 'background-color': item.color }">
            <div>
              <span class="cds-h6-demibold" style="color: var(--cds-text-inverse)">{{ i + 1 }}</span>
            </div>
          </div>
        </div>
        <div class="l-ml-3">
          <div class="text-box">
            <span class="cds-body1-demibold">{{ item.text }}</span>
          </div>
          <div class="l-mt-3">
            <span class="cds-body2">{{ item.info }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="line"></div>
  <div class="button-box" style="text-align: end">
    <cds-button [size]="'sm'" (click)="cancel()">Got it</cds-button>
  </div>
</div>
