<cds-navigation class="utility-header" bannerLogo="logo:Manulife_txt_rgb_HongKong">
  <!-- sidebar Start -->
  <cds-menu>
    <cds-menu-item #menuItem icon="contact:comment" routerLink="/chatbot-supervisor/dashboard" label="Supervisor"> </cds-menu-item>
  </cds-menu>
  <!-- sidebar End -->

  <!-- headbar Start -->
  <cds-navigation-utilities>
    <cds-navigation-lang-utility [(selected)]="selectedLang" [options]="langList" (selectedChange)="changeLang($event)"> </cds-navigation-lang-utility>
    <cds-navigation-button-utility label="{{ 'Logout' | lang }}" (cdsClick)="logout()"> </cds-navigation-button-utility>
  </cds-navigation-utilities>
  <!-- headbar End -->

  <!-- content container -->
  <cds-content id="contenContainer" style="height: 100%; display: flex; flex-direction: column">
    <div class="card-min-height p-3">
      <div class="row" style="align-items: end">
        <div class="col-lg-6 col-md-6 col-sm-3 col-7 pb-1 ps-1">
          <span style="font-weight: 600; font-size: 20px">Supervisor Dashbord</span>
          <app-cd-table-title [total]="rows.length" [showSubTitle]="true"></app-cd-table-title>
        </div>
        <app-data-table [dataSource]="rows" [columnsConfig]="headers">
          <ng-template appDtBodyCellDef="alertKeyword" let-element>
            <span class="" [innerHTML]="element.alertKeyword"> </span>
          </ng-template>
        </app-data-table>
      </div>
    </div>
  </cds-content>
  <!-- footer -->
  <!-- <app-cds-navigation-footer-local
      [config]="footerConfig"
      class="l-ma-0"></app-cds-navigation-footer-local> -->
  <div class="footer l-pl-7 l-pr-7">
    <div class="logo-wrapper">
      <cds-icon class="logo" icon="logo:Manulife_rgb_w" [config]="{ color: 'var(--cds-bg-info)' }"></cds-icon>
    </div>
    <div class="text-wrapper">
      <span class="text cds-detail1">{{ 'common.footerText' | lang }}</span>
    </div>
  </div>
</cds-navigation>
