<div class="d-flex">
  <div class="header-btn-lg pe-0">
    <div class="widget-content p-0">
      <div class="widget-content-wrapper">
        <div class="widget-content-left">
          <div class="btn-group" ngbDropdown>
            <button type="button" class="btn btn-link p-0 me-2" ngbDropdownToggle>
              <span>
                <div class="icon-wrapper icon-wrapper-alt rounded-circle">
                  <img width="42" src="./assets/chatbot-admin/images/avatars/4.jpg" alt="" class="rounded-circle" />
                </div>
              </span>
            </button>
            <div class="dropdown-menu-lg" ngbDropdownMenu style="left: -110px !important">
              <div class="dropdown-menu-header">
                <div class="dropdown-menu-header-inner bg-info">
                  <div class="menu-header-image opacity-2 dd-header-bg-6"></div>
                  <div class="menu-header-content text-start">
                    <div class="widget-content p-0">
                      <div class="widget-content-wrapper">
                        <div class="widget-content-left me-3">
                          <img width="42" src="./assets/chatbot-admin/images/avatars/4.jpg" alt="" class="rounded-circle" />
                        </div>
                        <div class="widget-content-right me-2">
                          <button class="btn-pill btn-shadow btn-shine btn btn-focus">Logout</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="scroll-area-xs">
                <!-- <perfect-scrollbar [autoPropagation]="true">
                  <ul class="nav flex-column">
                   
                    <li class="nav-item"><a [routerLink]="[]" class="nav-link">Recover Password</a></li>
                    <li class="nav-item-header nav-item">My Account</li>
                  </ul>
                </perfect-scrollbar> -->
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="widget-content-left ms-3 header-user-info">
          <div class="widget-heading">Robert Murdoch</div>
          <div class="widget-subheading">Angular Developer</div>
        </div> -->
        <!-- <div class="widget-content-right header-user-info ms-3">
          <button type="button" class="btn btn-shadow p-1 btn-info btn-sm" placement="bottom"
            ngbTooltip="Tooltip Example!">
            <fa-icon [icon]="faCalendar" class="me-1 ms-1"></fa-icon>
          </button>
        </div> -->
      </div>
    </div>
  </div>
  <!-- <div class="header-btn-lg">
    <button type="button" class="hamburger hamburger--elastic" [ngClass]="{'is-active' : globals.toggleDrawer}"
      (click)="toggleDrawer()">
      <span class="hamburger-box"><span class="hamburger-inner"></span></span>
    </button>
  </div> -->
</div>
