<div *ngIf="progress" class="app-loading-overlay">
  <cds-progress class="col-xs-12" mode="indeterminate" type="ring" [diameter]="60"></cds-progress>
</div>
<div class="table-content l-mt-4">
  <table *ngIf="_rowInfoList && _rowInfoList.length > 0">
    <!-- <app-loading *ngIf="progress" [overlay]="true"></app-loading> -->
    <caption></caption>
    <tr class="table-title l-pt-2 l-pb-2">
      <th scope="col" *ngIf="expandTemplate" class="icon-width"></th>
      <th scope="col" [ngClass]="[item.width ? '' : 'no-warp']" *ngFor="let item of _headerList; let index = index">
        <div class="l-d-f l-ai-cen l-jc-sb">
          <div class="l-d-ib">
            <span class="cds-h6-demibold">{{ item.label }}</span>
          </div>
          <div class="l-d-ib pointer l-pl-2 l-pr-2 l-pt-1">
            <ng-container *ngIf="item.sort === Sort.DEFAULT">
              <cds-icon (click)="sortChangeFun(Sort.UP, index)" class="" icon="arrow:sort"></cds-icon>
            </ng-container>
            <ng-container *ngIf="item.sort === Sort.UP">
              <cds-icon (click)="sortChangeFun(Sort.DOWN, index)" icon="arrow:up"></cds-icon>
            </ng-container>
            <ng-container *ngIf="item.sort === Sort.DOWN && multiSort">
              <cds-icon (click)="sortChangeFun(Sort.UP, index)" icon="arrow:down"></cds-icon>
            </ng-container>
            <ng-container *ngIf="item.sort === Sort.DOWN && !multiSort">
              <cds-icon (click)="sortChangeFun(Sort.DEFAULT, index)" icon="arrow:down"></cds-icon>
            </ng-container>
          </div>
        </div>
      </th>
    </tr>
    <ng-container *ngFor="let row of data; let index = index">
      <tr
        (mouseenter)="mouseenterFun(index)"
        (mouseleave)="mouseleaveFun(index)"
        [ngStyle]="{
          background: _rowInfoList[index].expand || _rowInfoList[index].mouseIn ? '#fff' : '#FAFAFA'
        }"
        [ngClass]="[_rowInfoList[index].expand ? 'line-bord-show' : 'line-bord']">
        <td (click)="trigger(index)" *ngIf="expandTemplate" class="icon-width pointer l-pl-4">
          <cds-icon
            [width]="size"
            [height]="size"
            [@openClose]="_rowInfoList[index].expand ? 'expanded' : 'collapsed'"
            [config]="_config"
            [icon]="icon"></cds-icon>
        </td>
        <td *ngFor="let cell of _rowInfoList[index].data">
          <div [ngStyle]="{ width: cell.width ? cell.width : '' }">
            <ng-container *ngIf="cell.template">
              <ng-container [ngTemplateOutlet]="cell.template" [ngTemplateOutletContext]="{ $implicit: row, index: index }"></ng-container>
            </ng-container>
            <ng-container *ngIf="!cell.template">
              {{ cell.val || '' }}
            </ng-container>
          </div>
        </td>
      </tr>
      <tr [ngClass]="[_rowInfoList[index].expand ? 'line-bord-show' : '', 'table-expand']" *ngIf="expandTemplate">
        <td>
          <div [@openClose]="_rowInfoList[index].expandClass" class="icon-width"></div>
        </td>
        <td [colSpan]="_rowInfoList[index].data.length">
          <div [@openClose]="_rowInfoList[index].expandClass">
            <ng-container
              [ngTemplateOutlet]="expandTemplate"
              [ngTemplateOutletContext]="{
                $implicit: row.expandData ? row.expandData : row,
                index: index
              }"></ng-container>
          </div>
        </td>
      </tr>
    </ng-container>
  </table>

  <div *ngIf="!progress && (!_rowInfoList || _rowInfoList.length === 0)" class="l-d-f l-jc-cen l-ai-cen l-pa-4">
    <div>
      <cds-icon class="mirror-rotate-h" [config]="notfoundConfig" icon="form:search"></cds-icon>
    </div>
    <div class="l-ml-6">
      <span class="cds-h2-light font-dark-navy">{{ 'common.noResultFound' | lang }}</span>
    </div>
  </div>
</div>
<ng-content></ng-content>
