import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { CDS_POPUP_DATA } from '@cds/ng-web-components/popup';
import { SubmitSuccessComponent } from '../../send-iff-in-batch-summary/submit-success/submit-success.component';

@Component({
  selector: 'app-er-submit-success',
  templateUrl: './er-submit-success.component.html',
  styleUrls: ['./er-submit-success.component.scss'],
})
export class ErSubmitSuccessComponent implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<SubmitSuccessComponent>,
    @Inject(CDS_POPUP_DATA)
    public data: {
      message: string;
    }
  ) {}

  ngOnInit(): void {}

  gotIt() {
    this.dialogRef.close(true);
  }
}
