import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subject, of } from 'rxjs';
import { catchError, tap, timeout } from 'rxjs/operators';

import { EmployeeResponse } from './employee-summary/employee-response.model';
import { EmployeeRequest } from './employee-summary/employee-request.model';
import { environment } from 'src/environments/environment';
import { EmployeeDelegationChangeRequest } from './employee-delegation-change/employee-delegation-change-request.model';
import { EmployeeDelegationChangeResponse } from './employee-delegation-change/employee-delegation-change-response.model';
import { AgentCodeBatchValidateResponse } from './employer-summary/agent-code-batch-validate-response';
import { agentCodeAddPrefix0, agentCodeRemovePrefix0 } from 'src/app/utils/utils';

@Injectable({
  providedIn: 'root',
})
export class AgentEmployeeDelegationService {
  searchEmployeeUrl = `${environment.apiPrefix}/ext/eb-ssms/customer-service/delegation/members/query`;
  eeDelegationChangeBatchValidateUrl = `${environment.apiPrefix}/ext/eb-ssms/customer-service/update-delegation/members/validation`;
  eeDelegationChangeSubmitUrl = `${environment.apiPrefix}/ext/eb-ssms/customer-service/update-delegation/members/submit`;

  employeeDataSubject = new Subject<EmployeeResponse>();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  errorEmployeeSubject = new Subject<any>();
  eeDelegationChangeBatchValidateSubject = new Subject<AgentCodeBatchValidateResponse>();
  eeDelegationChangeSubmitSubject = new Subject<AgentCodeBatchValidateResponse>();

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  constructor(private httpClient: HttpClient) {}

  getEmployees(request: EmployeeRequest) {
    this.httpClient
      .post(this.searchEmployeeUrl, request, this.httpOptions)
      .pipe(
        tap(() => console.info('fetched employees')),
        timeout(10000),
        catchError(this.handleError<EmployeeResponse>('getEmployers', new EmployeeResponse()))
      )
      .subscribe((resp: EmployeeResponse) => {
        if (resp.result === 0) {
          resp.data?.content?.forEach(c => {
            if (c.delegateAgentInfo?.agentCode) {
              c.delegateAgentInfo.agentCode = agentCodeAddPrefix0(c.delegateAgentInfo.agentCode);
            }
          });
        }

        this.employeeDataSubject.next(resp);
      });
  }

  handleError<T>(operation = 'operation', result?: T) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (error: any): Observable<T> => {
      console.info(`${operation} failed :${error.message}`);
      this.errorEmployeeSubject.next(`${error.message}`);
      return of(result as T);
    };
  }

  eeDelegationChangeBatchValidate(eeDelegationChangeRequestArr: EmployeeDelegationChangeRequest[]) {
    eeDelegationChangeRequestArr.forEach(eeDelegationChange => {
      if (eeDelegationChange.oldAgentCode) {
        eeDelegationChange.oldAgentCode = agentCodeRemovePrefix0(eeDelegationChange.oldAgentCode);
      }
      if (eeDelegationChange.targetAgentCode) {
        eeDelegationChange.targetAgentCode = agentCodeRemovePrefix0(eeDelegationChange.targetAgentCode);
      }
    });

    return this.httpClient
      .post(this.eeDelegationChangeBatchValidateUrl, eeDelegationChangeRequestArr, this.httpOptions)
      .pipe(
        tap(() => console.info('ee delegation change batch check')),
        timeout(10000),
        catchError(this.handleError<EmployeeDelegationChangeResponse>('ee delegation change batch check got error'))
      )
      .subscribe((resp: AgentCodeBatchValidateResponse) => {
        if (resp.result !== 0) {
          if (resp.data && resp.data.length > 0) {
            resp.data.forEach(element => {
              if (element.agent) {
                if (element.agent.agentCode) {
                  element.agent.agentCode = agentCodeAddPrefix0(element.agent.agentCode);
                }
              }

              if (element.targetAgent) {
                if (element.targetAgent.agentCode) {
                  element.targetAgent.agentCode = agentCodeAddPrefix0(element.targetAgent.agentCode);
                }
              }
            });
          }
        }

        this.eeDelegationChangeBatchValidateSubject.next(resp);
      });
  }

  eeDelegationChangeSubmit(eeDelegationChangeRequestArr: EmployeeDelegationChangeRequest[]) {
    eeDelegationChangeRequestArr.forEach(eeDelegationChange => {
      if (eeDelegationChange.oldAgentCode) {
        eeDelegationChange.oldAgentCode = agentCodeRemovePrefix0(eeDelegationChange.oldAgentCode);
      }
      if (eeDelegationChange.targetAgentCode) {
        eeDelegationChange.targetAgentCode = agentCodeRemovePrefix0(eeDelegationChange.targetAgentCode);
      }
    });

    return this.httpClient
      .post(this.eeDelegationChangeSubmitUrl, eeDelegationChangeRequestArr, this.httpOptions)
      .pipe(
        tap(() => console.info('ee delegation change submit')),
        timeout(10000),
        catchError(this.handleError<EmployeeDelegationChangeResponse>('ee delegation change submit got error'))
      )
      .subscribe((resp: AgentCodeBatchValidateResponse) => {
        if (resp.result !== 0) {
          if (resp.data && resp.data.length > 0) {
            resp.data.forEach(element => {
              if (element.agent) {
                if (element.agent.agentCode) {
                  element.agent.agentCode = agentCodeAddPrefix0(element.agent.agentCode);
                }
              }
            });
          }
        }

        this.eeDelegationChangeSubmitSubject.next(resp);
      });
  }
}
