/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CdsButtonConfig } from '@cds/ng-core/button';
import { CdsDropdownConfig } from '@cds/ng-core/dropdown';
import { finalize } from 'rxjs';
import { LayoutHeaderService } from 'src/app/core/services/layout-header.service';
import { ColumnConfig, PageConfig } from 'src/app/shared/data-table';
import { AdminHeaderConfig } from 'src/app/views/layout/admin-layout/admin-header/admin-header-config';
import {
  BankInformation,
  PaymentMemberLevel,
  PaymentMemberLevelRequest,
  PaymentRecordsDetailPaymentInfo,
  PaymentSubmission,
  ReiceiptRefNoStatusE,
  paymentMethodOptions,
  reiceiptRefNoStatusMapObj,
} from '../../employer';
import { EmployerService } from '../../employer.service';
import { deepCopy } from 'src/app/utils/copy';
import { DateTime } from 'luxon';
import { CdsOption } from '@cds/ng-core/configuration';
import { Employee } from 'src/app/views/employee/employee';

@Component({
  selector: 'app-payment-record-details',
  templateUrl: './payment-record-details.component.html',
  styleUrls: ['./payment-record-details.component.scss'],
})
export class PaymentRecordDetailsComponent implements OnInit {
  employerId = '';
  payrollGroupId = '';
  paymentSubmissionReferenceNo = '';
  receiptReferenceNo = '';
  reiceiptRefNoStatus?: ReiceiptRefNoStatusE;

  headerButton: AdminHeaderConfig = {
    title: 'payment.record-details',
    backButton: {
      text: 'Employer Payroll Group Info',
      url: '#',
      method: this.goBack.bind(this),
    },
  };

  paymentSubmission: PaymentSubmission = {};

  bankInformation: BankInformation = {};

  tempSeardhParams: PaymentMemberLevelRequest = {
    page: 0,
    size: 10,
  };

  bankInformationTableData: BankInformation[] = [];

  receiptReferenceNoControlControl = new FormControl();

  receiptReferenceNoDropdownConfig: CdsDropdownConfig = {
    label: 'Receipt Reference Number',
    options: [],
    placeholder: 'Select an option',
    color: 'ml',
  };

  displayedColumns = ['paymentMethod', 'chequeDate', 'chequeBankInDate', 'clearedDate', 'receivedDate', 'receivedAmount', 'chequeDirectMailingToeMpfFlag'];

  pageConfig: PageConfig = {
    current: 1,
    pageSize: 10,
    totalCount: 0,
  };

  numberOfElements = 0;

  searchButtonConfig: CdsButtonConfig = {
    size: 'sm',
  };

  resetButtonConfig: CdsButtonConfig = {
    size: 'sm',
    style: 'secondary',
  };

  get searchButtonDisabled() {
    return !this.form?.valid || !this.form.dirty;
  }

  resetButtonDisabled = true;

  get isLoading() {
    return this.basicInfoLoading || this.paymentInformationLoading || this.searchListLoading;
  }

  dataSource: PaymentMemberLevel[] = [];

  columnsConfig: ColumnConfig[] = [
    {
      key: 'memberAccountCode',
      title: 'Member Account Code',
    },
    {
      key: 'memberName',
      title: 'Member Name',
    },
    {
      key: 'contributionPeriod',
      title: 'Contribution Period',
    },
    {
      key: 'receivedAmount',
      title: 'Received Amount',
    },
  ];

  contributionPeriodConfig: CdsDropdownConfig = {
    label: 'Contribution Period',
    placeholder: 'Select Contribution Period',
    options: [],
  };

  form: FormGroup = new FormGroup({});

  formReady = false;

  showTable = true;

  basicInfoLoading = false;
  searchListLoading = false;
  paymentInformationLoading = false;
  contributionPeriodLoading = false;
  memberLoading = false;

  paymentMethodOptions = paymentMethodOptions;

  reiceiptRefNoStatusMapObj = reiceiptRefNoStatusMapObj;

  firstLoad = true;

  eceiptReferenceNoList: PaymentRecordsDetailPaymentInfo[] = [];

  get memberAccountCodeControl() {
    return this.form?.get('memberAccountCode');
  }

  get memberNameControl() {
    return this.form?.get('memberName');
  }

  constructor(private layoutHeaderService: LayoutHeaderService, public service: EmployerService, private route: ActivatedRoute, private router: Router) {}

  ngOnInit(): void {
    document.getElementsByClassName('cds-navigation-content')[0].scrollTop = 0;

    this.layoutHeaderService.setHeaderButton(this.headerButton);

    this.employerId = this.route.snapshot.queryParams['id'];
    this.payrollGroupId = this.route.snapshot.queryParams['payrollGroupId'];
    this.paymentSubmissionReferenceNo = this.route.snapshot.queryParams['paymentSubmissionReferenceNo'];
    this.receiptReferenceNo = this.route.snapshot.queryParams['receiptReferenceNo'];

    this.receiptReferenceNoControlControl.setValue(this.receiptReferenceNo ? this.receiptReferenceNo : '');

    this.initForm();
    this.getSearchList();
    this.getBaseInfo();
  }

  initForm() {
    this.form?.markAsUntouched();
    this.form?.markAsPristine();

    this.form = new FormGroup({
      memberAccountCode: new FormControl('', [Validators.maxLength(15)]),
      memberName: new FormControl('', [Validators.maxLength(200)]),
      contributionPeriod: new FormControl(''),
    });
    this.formReady = false;
    setTimeout(() => {
      this.formReady = true;
    });
  }

  getTempSearchParams() {
    const params: PaymentMemberLevelRequest = {
      ...this.tempSeardhParams,
      ...(this.form.valid ? this.form.value : {}),
      page: (this.pageConfig.current as number) - 1,
      size: 10,
    };

    if (params.contributionPeriod) {
      const [contributionPeriodFrom, contributionPeriodTo] = params.contributionPeriod.split(',');
      params.contributionPeriodFrom = contributionPeriodFrom;
      params.contributionPeriodTo = contributionPeriodTo;
      delete params.contributionPeriod;
    } else {
      delete params.contributionPeriodFrom;
      delete params.contributionPeriodTo;
    }

    return params;
  }

  getRequestParams() {
    const params: PaymentMemberLevelRequest = {
      ...this.tempSeardhParams,
      page: (this.pageConfig.current as number) - 1,
      size: 10,
      payrollGroupId: this.payrollGroupId,
      id: this.employerId,
      subRefNo: this.paymentSubmissionReferenceNo,
      receiptReferenceNo: this.receiptReferenceNo,
    };

    return params;
  }

  getBaseInfo() {
    this.basicInfoLoading = true;
    this.service
      .getPaymentRecordsDetailBaseInfo(this.paymentSubmissionReferenceNo, this.employerId, this.payrollGroupId)
      .pipe(
        finalize(() => {
          this.basicInfoLoading = false;
        })
      )
      .subscribe(res => {
        if (res.result === 0 && res.data) {
          this.paymentSubmission = this.baseInfoGenerator(res.data);
        }
      });
  }

  baseInfoGenerator(data?: PaymentSubmission[]) {
    if (!data) return {};
    const workflowReferenceNoList: string[] = [];
    const otherTransactionReferenceNoList: string[] = [];

    data.forEach(item => {
      if (item.workflowReferenceNo) {
        workflowReferenceNoList.push(item.workflowReferenceNo);
      }
      if (item.otherTransactionReferenceNo) {
        otherTransactionReferenceNoList.push(item.otherTransactionReferenceNo);
      }
    });
    return {
      ...data[0],
      workflowReferenceNoList,
      otherTransactionReferenceNoList,
    };
  }

  getSearchList() {
    this.searchListLoading = true;
    this.service
      .getPaymentRecordsDetailSearchList(this.paymentSubmissionReferenceNo, this.employerId, this.payrollGroupId)
      .pipe(
        finalize(() => {
          this.searchListLoading = false;
        })
      )
      .subscribe(res => {
        if (res.result === 0 && res.data) {
          this.eceiptReferenceNoList = res.data;
          this.receiptReferenceNoDropdownConfig.options = res.data.map(item => {
            return {
              label: item.receiptReferenceNo as string,
              value: item.receiptReferenceNo as string,
            };
          });

          this.receiptReferenceNoDropdownConfig = { ...this.receiptReferenceNoDropdownConfig };

          if (!this.receiptReferenceNo && this.receiptReferenceNoDropdownConfig.options.length) {
            this.receiptReferenceNo = this.receiptReferenceNoDropdownConfig.options[0].value;
            this.receiptReferenceNoControlControl.setValue(this.receiptReferenceNo);
          }

          this.reiceiptRefNoStatus = this.getReiceiptRefNoStatus();
          this.getPaymentInformation();
          this.getContributionPeriod();
        }
      });
  }

  getReiceiptRefNoStatus() {
    return this.eceiptReferenceNoList.find(item => item.receiptReferenceNo === this.receiptReferenceNo)?.status;
  }

  getContributionPeriod() {
    this.contributionPeriodLoading = true;
    this.service
      .getPaymentRecordsDetailContribution(this.paymentSubmissionReferenceNo, this.employerId, this.payrollGroupId, this.receiptReferenceNo)
      .pipe(
        finalize(() => {
          this.contributionPeriodLoading = false;
        })
      )
      .subscribe(res => {
        if (res.result === 0 && res.data) {
          // this.bankInformation = res.data;
          // this.bankInformationTableData = [this.bankInformation];
          const options: CdsOption[] = res.data.map(item => {
            return {
              label: `${this.dateTrans(item.contributionStart)}-${this.dateTrans(item.contributionEnd)}`,
              value: `${this.dateTransToRequest(item.contributionStart)},${this.dateTransToRequest(item.contributionEnd)}`,
            };
          });

          this.contributionPeriodConfig = {
            ...this.contributionPeriodConfig,
            options,
          };
        }
      });
  }

  dateTrans(value?: string) {
    if (!value) return '';
    return DateTime.fromISO(value).toFormat('dd/MM/yyyy');
  }

  dateTransToRequest(value?: string) {
    if (!value) return '';
    return DateTime.fromISO(value).toFormat('yyyy-MM-dd');
  }

  getPaymentInformation() {
    this.searchListLoading = true;
    this.service
      .getPaymentRecordsDetailPaymentInformation(this.paymentSubmissionReferenceNo, this.employerId, this.payrollGroupId, this.receiptReferenceNo)
      .pipe(
        finalize(() => {
          this.searchListLoading = false;
        })
      )
      .subscribe(res => {
        if (res.result === 0 && res.data) {
          this.bankInformation = res.data;
          this.bankInformationTableData = [this.bankInformation];
        }
      });
  }

  getMemberPageList() {
    this.memberLoading = true;

    const params: PaymentMemberLevelRequest = this.getRequestParams();

    this.service
      .getPaymentRecordsDetailMemberLevelList(params)
      .pipe(
        finalize(() => {
          this.memberLoading = false;
        })
      )
      .subscribe(data => {
        if (data.result === 0 && data.data) {
          this.dataSource = data.data.empty ? [] : data.data.content;
          this.numberOfElements = data.data.totalElements;

          this.pageConfig = {
            ...this.pageConfig,
            totalCount: data.data.totalElements,
          };
        }
      });
  }

  goBack() {
    this.router.navigate(['/employer/payroll-group-info'], {
      queryParams: {
        id: this.route.snapshot.queryParams['id'],
        entityCode: this.route.snapshot.queryParams['entityCode'],
        payrollGroupId: this.payrollGroupId,
        tab: 'paymentRecord',
      },
    });
  }

  onSelectChange(val: string) {
    if (!val) return;
    // if (this.firstLoad) {
    //   this.firstLoad = false;
    //   return;
    // }
    this.receiptReferenceNo = val;
    this.reiceiptRefNoStatus = this.getReiceiptRefNoStatus();
    this.getMemberPageList();
    this.getPaymentInformation();
    this.getContributionPeriod();
    this.reset();
  }

  search() {
    if (this.searchButtonDisabled) return;
    this.resetButtonDisabled = false;
    this.pageConfig = {
      current: 1,
      pageSize: 10,
      totalCount: 0,
    };
    this.tempSeardhParams = deepCopy(this.getTempSearchParams());
    this.getMemberPageList();
  }

  reset() {
    if (this.resetButtonDisabled) return;
    this.resetButtonDisabled = true;
    this.initForm();
    // reloadTable to reset sort
    this.showTable = false;
    setTimeout(() => {
      this.showTable = true;
      this.pageConfig = {
        current: 1,
        pageSize: 10,
        totalCount: 0,
      };
      this.tempSeardhParams = { page: 1, size: 10 };
      this.getMemberPageList();
    });
  }

  reloadTable(event: any) {
    this.pageConfig = {
      ...this.pageConfig,
      current: event.current,
    };
    this.getMemberPageList();
  }

  goToDetail(item: Employee) {
    this.router.navigate(['/employee/related-policy-detail'], {
      queryParams: {
        md5: item.md5,
        id: item.customerId,
      },
    });
  }
}
