/* eslint-disable @typescript-eslint/no-explicit-any */
import { ScaleType } from 'src/app/core/models/enum/commission-scale.enum';
import { AccumulationType } from 'src/app/core/models/enum/accumulation-type.enum';

export const ScaleMap = new Map();
ScaleMap.set(ScaleType.FS_PC, 'FS HKPC Scale');
ScaleMap.set(ScaleType.TRF_SP_PC, 'TRF SP HKPC Scale');
ScaleMap.set(ScaleType.FY, '1st Yr Comm Scale');
ScaleMap.set(ScaleType.FS_SF, 'FS Service Fee Scale');
ScaleMap.set(ScaleType.PS, 'PS Comm Scale');
ScaleMap.set(ScaleType.PS_SF, 'PS Service Fee Scale');
ScaleMap.set(ScaleType.PS_PC, 'PS HKPC Scale');
ScaleMap.set(ScaleType.TRF_SP, 'TRF SP Comm Scale');
ScaleMap.set(ScaleType.RY_PC, 'RY HKPC Scale');
ScaleMap.set(ScaleType.IND_TRF_SP_PC, 'Individual transfer Single Premium HKPC Scale');
ScaleMap.set(ScaleType.IND_TRF_SP, 'Individual transfer Single Premium Commission');

export const RecordDetailList = [
  {
    isClear: true,
    checked: true,
    scale: 'FS HKPC Scale',
    scaleType: ScaleType.FS_PC,
    scaleLabel: 'FS HKPC',
    scaleId: '',
    scaleCode: '',
    calcType: '',
    breakpointType: '',
    currPolYrCommRate: '',
    mthlyClawBack: '0/0',
    calcTypeDisplay: true,
    breakpointDisplay: true,
    currPolDisplay: true,
    accumulations: [
      {
        errorMsg: '',
        label: 'Current Plan High',
        accumulationType: AccumulationType.PLAN_HIGH,
        amount: '0',
      },
      {
        errorMsg: '',
        label: 'HKPC Released CYTD',
        accumulationType: AccumulationType.RELEASED_CYTD,
        amount: '0',
      },
      {
        errorMsg: '',
        label: 'Ann Prem CYTD',
        accumulationType: AccumulationType.ANNUAL_PREM_CYTD,
        amount: '0.00',
      },
      {
        errorMsg: '',
        label: 'Prem CYTD',
        accumulationType: AccumulationType.PREMIUM_CYTD,
        amount: '0.00',
      },
      {
        errorMsg: '',
        label: 'Prem PTD',
        accumulationType: AccumulationType.PREMIUM_PTD,
        amount: '0.00',
      },
    ],
    breakpoints: [],
  },
  {
    isClear: true,
    checked: true,
    scale: '1st Yr Comm Scale',
    scaleType: ScaleType.FY,
    scaleLabel: '1st Yr Comm',
    scaleCode: '',
    calcType: '',
    breakpointType: '',
    currPolYrCommRate: '',
    calcTypeDisplay: true,
    breakpointDisplay: true,
    currPolDisplay: true,
    accumulations: [
      {
        errorMsg: '',
        label: 'Prem CYTD',
        accumulationType: AccumulationType.PREMIUM_CYTD,
        amount: '0.00',
      },
      {
        errorMsg: '',
        label: 'Prem PTD',
        accumulationType: AccumulationType.PREMIUM_PTD,
        amount: '0.00',
      },
    ],
    breakpoints: [],
  },
  {
    isClear: true,
    checked: true,
    scale: 'FS Service Fee Scale',
    scaleType: ScaleType.FS_SF,
    scaleLabel: 'FS Service Fee',
    scaleCode: '',
    calcType: '',
    breakpointType: '',
    currPolYrCommRate: '',
    calcTypeDisplay: false,
    breakpointDisplay: true,
    currPolDisplay: true,
    accumulations: [
      {
        errorMsg: '',
        label: 'Gross Prem CYTD',
        accumulationType: AccumulationType.GROSS_PREM_CYTD,
        amount: '0.00',
      },
      {
        errorMsg: '',
        label: 'Prem CYTD',
        accumulationType: AccumulationType.PREMIUM_CYTD,
        amount: '0.00',
      },
      {
        errorMsg: '',
        label: 'Prem PTD',
        accumulationType: AccumulationType.PREMIUM_PTD,
        amount: '0.00',
      },
    ],
    breakpoints: [],
  },
  {
    isClear: true,
    checked: true,
    scale: 'RY HKPC Scale',
    scaleType: ScaleType.RY_PC,
    scaleLabel: 'RY HKPC',
    scaleCode: '',
    calcType: '',
    breakpointType: '',
    currPolYrCommRate: '',
    calcTypeDisplay: false,
    breakpointDisplay: true,
    currPolDisplay: true,
    accumulations: [
      {
        errorMsg: '',
        label: 'HKPC Released CYTD',
        accumulationType: AccumulationType.RELEASED_CYTD,
        amount: '0',
      },
      {
        errorMsg: '',
        label: 'Prem CYTD',
        accumulationType: AccumulationType.PREMIUM_CYTD,
        amount: '0.00',
      },
      {
        errorMsg: '',
        label: 'Prem PTD',
        accumulationType: AccumulationType.PREMIUM_PTD,
        amount: '0.00',
      },
    ],
    breakpoints: [],
  },
  {
    isClear: true,
    checked: true,
    scale: 'TRF SP HKPC Scale',
    scaleType: ScaleType.TRF_SP_PC,
    scaleLabel: 'TRF SP HKPC',
    scaleCode: '',
    calcType: '',
    breakpointType: '',
    currPolYrCommRate: '',
    calcTypeDisplay: false,
    breakpointDisplay: true,
    currPolDisplay: true,
    accumulations: [
      {
        errorMsg: '',
        label: 'HKPC Released CYTD',
        accumulationType: AccumulationType.RELEASED_CYTD,
        amount: '0',
      },
      {
        errorMsg: '',
        label: 'Prem CYTD',
        accumulationType: AccumulationType.PREMIUM_CYTD,
        amount: '0.00',
      },
      {
        errorMsg: '',
        label: 'Prem PTD',
        accumulationType: AccumulationType.PREMIUM_PTD,
        amount: '0.00',
      },
    ],
    breakpoints: [],
  },
  {
    isClear: true,
    checked: true,
    scale: 'TRF SP Comm Scale',
    scaleType: ScaleType.TRF_SP,
    scaleLabel: 'TRF SP Comm',
    scaleCode: '',
    calcType: '',
    breakpointType: '',
    currPolYrCommRate: '',
    calcTypeDisplay: false,
    breakpointDisplay: true,
    currPolDisplay: true,
    accumulations: [
      {
        errorMsg: '',
        label: 'Prem CYTD',
        accumulationType: AccumulationType.PREMIUM_CYTD,
        amount: '0.00',
      },
      {
        errorMsg: '',
        label: 'Prem PTD',
        accumulationType: AccumulationType.PREMIUM_PTD,
        amount: '0.00',
      },
    ],
    breakpoints: [],
  },
  {
    isClear: true,
    checked: true,
    scale: 'PS HKPC Scale',
    scaleType: ScaleType.PS_PC,
    scaleLabel: 'PS HKPC',
    scaleCode: '',
    breakpointType: '',
    currPolYrCommRate: '',
    calcTypeDisplay: true,
    breakpointDisplay: true,
    currPolDisplay: true,
    accumulations: [
      {
        errorMsg: '',
        label: 'Current Plan High',
        accumulationType: AccumulationType.PLAN_HIGH,
        amount: '0',
      },
      {
        errorMsg: '',
        label: 'HKPC Released CYTD',
        accumulationType: AccumulationType.RELEASED_CYTD,
        amount: '0',
      },
      {
        errorMsg: '',
        label: 'Ann Prem CYTD',
        accumulationType: AccumulationType.ANNUAL_PREM_CYTD,
        amount: '0.00',
      },
      {
        errorMsg: '',
        label: 'Prem CYTD',
        accumulationType: AccumulationType.PREMIUM_CYTD,
        amount: '0.00',
      },
      {
        errorMsg: '',
        label: 'Prem PTD',
        accumulationType: AccumulationType.PREMIUM_PTD,
        amount: '0.00',
      },
    ],
    breakpoints: [],
  },
  {
    isClear: true,
    checked: true,
    scale: 'PS Comm Scale',
    scaleType: ScaleType.PS,
    scaleLabel: 'PS Comm',
    scaleCode: '',
    breakpointType: '',
    currPolYrCommRate: '',
    calcTypeDisplay: true,
    breakpointDisplay: true,
    currPolDisplay: true,
    accumulations: [
      {
        errorMsg: '',
        label: 'Prem CYTD',
        accumulationType: AccumulationType.PREMIUM_CYTD,
        amount: '0.00',
      },
      {
        errorMsg: '',
        label: 'Prem PTD',
        accumulationType: AccumulationType.PREMIUM_PTD,
        amount: '0.00',
      },
    ],
    breakpoints: [],
  },
  {
    isClear: true,
    checked: true,
    scale: 'PS Service Fee Scale',
    scaleType: ScaleType.PS_SF,
    scaleLabel: 'PS Service Fee',
    scaleCode: '',
    breakpointType: '',
    currPolYrCommRate: '',
    calcTypeDisplay: false,
    breakpointDisplay: true,
    currPolDisplay: true,
    accumulations: [
      {
        errorMsg: '',
        label: 'SF Gross Prem CYTD',
        accumulationType: AccumulationType.GROSS_PREM_CYTD,
        amount: '0.00',
      },
      {
        errorMsg: '',
        label: 'Prem CYTD',
        accumulationType: AccumulationType.PREMIUM_CYTD,
        amount: '0.00',
      },
      {
        errorMsg: '',
        label: 'Prem PTD',
        accumulationType: AccumulationType.PREMIUM_PTD,
        amount: '0.00',
      },
    ],
    breakpoints: [],
  },
];
