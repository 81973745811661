import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CdsOption } from '@cds/ng-core/configuration';
import { CdsDropdownConfig } from '@cds/ng-core/dropdown';
import { CdsTabsType } from '@cds/ng-web-components/tabs';
import { CdsToastService } from '@cds/ng-web-components/toast';
import { LayoutHeaderService } from 'src/app/core/services/layout-header.service';
import { AdminHeaderConfig } from '../../layout/admin-layout/admin-header/admin-header-config';
import { EEProfileAddressResponse } from '../ee-modules/EEProfileAddress.module';
import { EEProfileContactResponse } from '../ee-modules/EEProfileContact.module';
import { EEProfileDetailResponse } from '../ee-modules/EEProfileDetail.module';
import { EEProfilePolicyResponse } from '../ee-modules/EEProfilePolicy.module';
import { EmployeeService } from '../employee.service';

@Component({
  selector: 'app-ee-profile-detail-plus',
  templateUrl: './ee-profile-detail-plus.component.html',
  styleUrls: ['./ee-profile-detail-plus.component.scss'],
})
export class EeProfileDetailPlusComponent implements OnInit {
  constructor(
    private service: EmployeeService,
    private toast: CdsToastService,
    private route: ActivatedRoute,
    private router: Router,
    private layoutHeaderService: LayoutHeaderService
  ) {}
  dropdownConfig: CdsDropdownConfig = {
    options: [],
  };

  tabType: CdsTabsType = 'line-medium';
  isShowContact = true;
  contactIcon = 'action:button_up_outlined';
  @ViewChild('conRotateIcon')
  conRotateIcon: ElementRef | undefined;
  policeList: EEProfilePolicyResponse[] = [];
  headerButton: AdminHeaderConfig = {
    title: 'ee.profile.detail.plus.title',
    backButton: {
      text: 'Employee Enquiry Information Page',
      url: '#',
      method: this.goBack.bind(this),
    },
  };
  default_group_no: string | null = '';
  policeOptionList: Array<CdsOption> = [];
  detailData: EEProfileDetailResponse = {};
  optionBasicInfo: EEProfilePolicyResponse = {};
  contactInfo: EEProfileContactResponse = {};
  addressList: EEProfileAddressResponse[] = [];
  legalId: string | null = '';
  legalIdType: string | null = '';
  id: string | null = '';
  ngOnInit(): void {
    this.legalId = this.route.snapshot.paramMap.get('legalId');
    this.legalIdType = this.route.snapshot.paramMap.get('legalIdType');
    this.default_group_no = this.route.snapshot.paramMap.get('group_no');
    this.id = this.route.snapshot.paramMap.get('id');
    if (!this.legalId || !this.legalIdType || !this.default_group_no || !this.id) {
      this.router.navigate(['/employee/list']);
      return;
    }
    this.layoutHeaderService.setHeaderButton(this.headerButton);
    this.getDetail(this.id);
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  showContact() {
    const obj = this.conRotateIcon?.nativeElement;

    if (obj.classList.contains('backrotate')) {
      obj.classList.add('gorotate');
      obj.classList.remove('backrotate');
      this.isShowContact = false;
      this.contactIcon = 'action:button_up_filled';
    } else {
      obj.classList.add('backrotate');
      obj.classList.remove('gorotate');
      this.isShowContact = true;
      this.contactIcon = 'action:button_up_outlined';
    }
  }

  getDetail(id: string) {
    this.service.getDetail(id).subscribe(res => {
      if (res.result === 0 && res.data) {
        this.detailData = res.data;
        if (this.detailData.policyList) {
          this.policeList = this.detailData.policyList;
          this.policeList.forEach(element => {
            const item: CdsOption = {
              label: 'Policy: ' + element.groupNo,
              value: element.groupNo,
            };
            this.dropdownConfig.options.push(item);
          });
        }
        if (this.default_group_no) {
          this.setBasicInfo(this.default_group_no);
        }
      } else {
        this.toast.error('getById API error: ' + res.message);
      }
    });
  }

  getContact(trusteeId: string, entityType: string) {
    this.service.getContact(trusteeId, entityType).subscribe(res => {
      if (res.result === 0 && res.data) {
        this.contactInfo = res.data;
        if (this.contactInfo.address) {
          this.addressList = this.contactInfo.address;
        }
      } else {
        this.toast.error('getById API error: ' + res.message);
      }
    });
  }

  optionChange(e: Event) {
    if (e) {
      this.setBasicInfo(e.toString());
    }
  }
  setBasicInfo(group_no: string) {
    this.detailData.policyList?.forEach(element => {
      if (element.groupNo && element.groupNo === group_no.toString()) {
        this.optionBasicInfo = element;
        if (this.optionBasicInfo.trusteeId && this.optionBasicInfo.entityType) {
          this.getContact(this.optionBasicInfo.trusteeId, this.optionBasicInfo.entityType);
        }
      }
    });
  }
  goBack() {
    this.router.navigate([
      '/employee/profile',
      {
        legalId: this.detailData.legalId,
        legalIdType: this.detailData.legalIdType,
      },
    ]);
  }
}
