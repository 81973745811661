import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-iff-completion-steps-popup',
  templateUrl: './iff-completion-steps-popup.component.html',
  styleUrls: ['./iff-completion-steps-popup.component.scss'],
})
export class IffCompletionStepsPopupComponent implements OnInit {
  constructor(private dialogRef: MatDialogRef<IffCompletionStepsPopupComponent>) {}

  ngOnInit(): void {}

  onCancel() {
    this.dialogRef.close({ agree: false });
  }
}
