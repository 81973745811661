<div class="row">
  <!-- <div class=""> -->
  <div class="cds-h2 delarationform-header col-md-12 l-pt-6">強積金中介人聲明</div>
  <!-- </div> -->
  <div class="cds-detail2 delarationform-headersub col-md-12">請填寫以下資料以完成聲明</div>
</div>
<div class="row">
  <div class="cds-body2 delarationform-body col-md-4 l-pt-6 text-bold">
    <div class="cds-body2 delarationform-body text-bold text-empfno">請輸入eMPF申請編號：</div>
  </div>
  <div class="col-md-8">
    <div>
      <app-cd-input
        class="text-field col-md-6"
        [(value)]="empfDeclarationForm.empfno.value"
        (valueChange)="empfnochange()"
        placeholder="輸入eMPF申請編號"></app-cd-input>
      <cds-assistive-text class="declare-check-message" *ngIf="empfDeclarationForm.empfno.error !== ''" type="error"
        >{{ empfDeclarationForm.empfno.error }}
      </cds-assistive-text>
      <!-- <cds-textfield
        class="text-field col-md-6"
        [formControl]="empfnocontrol"
         placeholder="輸入eMPF申請編號"></cds-textfield> -->
    </div>
    <!-- <cds-icon
    [icon]="infoIcon"
    [config]="iconConfig"></cds-icon> -->
    <div style="font-size: 14px">
      <!-- <span>在客戶進行受規管活動時，必須已履行強積金中介人履行職務要求。</span> -->
      <cds-checkbox label="在客戶進行受規管活動時，必須已履行強積金中介人履行職務要求"></cds-checkbox>
    </div>
  </div>
</div>
<div class="row">
  <div class="cds-body2 delarationform-body col-md-4 l-pt-6">客戶全名：</div>
  <div class="col-md-3">
    <div>
      <app-cd-input
        class="text-field col-md-3"
        [(value)]="empfDeclarationForm.surname.value"
        (valueChange)="surnamechange()"
        placeholder="輸入姓氏"></app-cd-input>
      <cds-assistive-text class="declare-check-message" *ngIf="empfDeclarationForm.surname.error !== ''" type="error"
        >{{ empfDeclarationForm.surname.error }}
      </cds-assistive-text>
    </div>
  </div>
  <div class="col-md-5">
    <div>
      <app-cd-input
        class="text-field col-md-5"
        [(value)]="empfDeclarationForm.givenname.value"
        (valueChange)="givennamechange()"
        placeholder="輸入名字"></app-cd-input>
      <cds-assistive-text class="declare-check-message" *ngIf="empfDeclarationForm.givenname.error !== ''" type="error"
        >{{ empfDeclarationForm.givenname.error }}
      </cds-assistive-text>
    </div>
  </div>
</div>
<div class="row l-pt-1">
  <div class="cds-body2 delarationform-body col-md-4">客戶電郵地址/手機號碼*:</div>
  <div class="col-md-8">
    <div>
      <cds-radio [config]="radioConfig" (change)="onModechange($event)"></cds-radio>
      <cds-assistive-text class="declare-check-message" *ngIf="empfDeclarationForm.noselect.error !== ''" type="error"
        >{{ empfDeclarationForm.noselect.error }}
      </cds-assistive-text>
    </div>
    <div></div>
    <div *ngIf="selectmode === 2" class="l-d-f l-fd-row">
      <div class="col-md-4 l-plr-0">
        <cds-dropdown placeholder="+852 (HK)" [config]="countrycodeConfig"></cds-dropdown>
      </div>
      <div class="col-md-8">
        <app-cd-input
          class="text-field"
          type="number"
          [maxlength]="8"
          [(value)]="empfDeclarationForm.phonenumber.value"
          (valueChange)="phonenumberchange()"
          placeholder="98765432">
        </app-cd-input>
        <cds-assistive-text class="declare-check-message" *ngIf="empfDeclarationForm.phonenumber.error !== ''" type="error"
          >{{ empfDeclarationForm.phonenumber.error }}
        </cds-assistive-text>
      </div>
    </div>
    <div *ngIf="selectmode === 1" class="l-d-f l-fd-row">
      <div class="col-md-12 l-plr-0">
        <app-cd-input
          [(value)]="empfDeclarationForm.emailaddress.value"
          (valueChange)="emailaddresschange()"
          class="text-field"
          placeholder="輸入客戶電郵地址 (e.g. abc@abc.com)"></app-cd-input>
        <cds-assistive-text class="declare-check-message" *ngIf="empfDeclarationForm.emailaddress.error !== ''" type="error"
          >{{ empfDeclarationForm.emailaddress.error }}
        </cds-assistive-text>
      </div>
    </div>
    <div class="cds-h4-light text-small">*請確保此聯絡資料與客戶在eMPF上提供的資料一致</div>
  </div>
</div>
<div class="row">
  <div class="cds-body2 delarationform-body col-md-4 l-pt-6">強積金中介人編號：</div>
  <div class="col-md-8">
    <div>
      <app-cd-input
        class="text-field col-md-8"
        type="number"
        [maxlength]="6"
        [(value)]="empfDeclarationForm.additionAgent.value"
        placeholder="輸入強積金中介人編號（選填）"></app-cd-input>
    </div>
  </div>
</div>
<div class="row l-pt-6 l-d-f">
  <div class="l-pl-3">
    <cds-button label="返 回" (click)="BackTosummary()" [config]="clearButton"></cds-button>
  </div>
  <div style="padding-left: 16px">
    <cds-button label="繼續" (click)="RouteTosubmit()" [config]="clearButton"></cds-button>
  </div>
</div>
