import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { CdsLangService } from '@cds/ng-core/lang';
import { CdsPopupService } from '@cds/ng-web-components/popup';
import { AlertPopupComponent } from 'src/app/shared/alert-popup/alert-popup.component';

@Component({
  selector: 'app-add-mpf-reference-number-popup',
  templateUrl: './add-mpf-reference-number-popup.component.html',
  styleUrls: ['./add-mpf-reference-number-popup.component.scss'],
})
export class AddMpfReferenceNumberPopupComponent implements OnInit {
  step = 1;
  formControl: FormControl = new FormControl('', {
    initialValueIsDefault: true,
  });

  constructor(private dialogRef: MatDialogRef<AddMpfReferenceNumberPopupComponent>, private cdsPopup: CdsPopupService, private langService: CdsLangService) {}

  ngOnInit(): void {}

  closeCurrentPopup() {
    this.dialogRef.close();
  }

  onReset() {
    this.step = 1;
    this.formControl.reset();
  }

  onSubmit() {
    this.dialogRef.close();
    this.cdsPopup
      .open(AlertPopupComponent, {
        size: 'sm',
        data: {
          message: this.langService.translate('common.action.success.italic'),
          buttonName: this.langService.translate('common.gotItWithExclamation'),
        },
      })
      .afterClosed()
      .subscribe(() => {
        this.dialogRef.close({
          agree: true,
          reload: true,
        });
      });
  }

  get isDisabledAdd() {
    return this.formControl.value === '';
  }

  onAdd() {
    if (this.isDisabledAdd) {
      return;
    }

    this.step = 2;
  }
}
