import { CdStepComponent } from './../../../shared/cd-stepper/cd-step/cd-step.component';
import { AfterViewInit, Component, DoCheck, ElementRef, Input, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';

import { CdsIconConfig } from '@cds/ng-core/icon';
import { ActionIcons, ArrowIcons } from '@cds/ng-themes';
import { CdsTextfieldConfig } from '@cds/ng-core/textfield';
import { CdsButtonConfig } from '@cds/ng-core/button';
import { EmployeeRequest } from '../employee-summary/employee-request.model';
import { CdsProgressType } from '@cds/ng-core/progress';
import { CdsColorTheme } from '@cds/ng-core/configuration';

import { AgentEmployeeResponseData } from '../agent-employee-response.model';
import { LanguageChangeService } from 'src/app/shared/language-change.service';
import { AgentEmployeeDelegationService } from '../agent-employee-delegation.service';
import { FormControl } from '@angular/forms';
import { CdsDropdownConfig } from '@cds/ng-core/dropdown';
import { CdsAlertService } from '@cds/ng-web-components/alert';
import { AddToItemComponent } from '../er-delegation-change/add-to-item/add-to-item.component';
import { CdsPopupService, MatDialogRef } from '@cds/ng-web-components/popup';
import { AgentCodeData } from '../employer-summary/check-agent-code-response';
import { CheckAgentCodeRequest } from '../employer-summary/check-agent-code-request';
import { AgentEmployerDelegationService } from '../agent-employer-delegation.service';
import { AgentBranchPopComponent } from '../agent-branch-pop/agent-branch-pop.component';
import { Agent, AgentCodeBatchValidateResponseData } from '../employer-summary/agent-code-batch-validate-response';
import { ChangeReason, EmployeeDelegationChangeRequest } from './employee-delegation-change-request.model';
import { UserConfirmPopComponent } from '../user-confirm-pop/user-confirm-pop.component';
import { UserSuccessSubmittedComponent } from '../user-success-submitted/user-success-submitted.component';
import { EmployeeDelegationChangePopComponent } from './employee-delegation-change-pop/employee-delegation-change-pop.component';
import { specialCharacterDecode } from 'src/app/utils/special-character';
import { CdsInlineAlertType } from '@cds/ng-web-components/inline-alert';
import { CdStepperComponent } from 'src/app/shared/cd-stepper/cd-stepper.component';
import { UserExitEditmodePopComponent } from '../user-exit-editmode-pop/user-exit-editmode-pop.component';
import { pageToTop } from 'src/app/utils/utils';

@Component({
  selector: 'app-employee-delegation-change',
  templateUrl: './employee-delegation-change.component.html',
  styleUrls: ['./employee-delegation-change.component.scss'],
})
export class EmployeeDelegationChangeComponent implements OnInit, OnDestroy, AfterViewInit, DoCheck {
  @ViewChild('stepper') stepper!: CdStepperComponent;
  @ViewChildren('step') stepList!: QueryList<CdStepComponent>;
  @ViewChild('tableBox', { static: false })
  tableBox?: ElementRef<HTMLDivElement>;
  @Input() nameValue = '';
  @Input() numberValue = '';
  @Input() delegateAgentInfoValue = '';
  @Input() fullWidth = true;
  @Input() color: CdsColorTheme = 'ml';
  @Input() pageCount = 1;
  @Input() page = 1;
  @Input() alertType: CdsInlineAlertType = 'error';

  language = 'EN';
  stepperTarget: 'pc' | 'ipad' = 'pc';

  backErIcon = ActionIcons.button_left_filled;
  employerId = '';
  employerName?: string;
  employerNameTC?: string;
  employeeTotalCount = 0;
  isShowProgress = true;
  cdsProgressType: CdsProgressType = 'ring';
  cdsProgressTxt = 'loading';
  cdsProgressColor: CdsColorTheme = 'ml';
  dataSource: AgentEmployeeResponseData[] = [];
  dataSourceInConfirmation: AgentEmployeeResponseData[] = [];
  fullDataSource: {
    data: AgentEmployeeResponseData[];
    totalElements: number;
    totalPages: number;
  }[] = [];
  allChecked = false;
  markPage = 1;
  searchValue = 1;
  resultState = '0';
  batchInputAgentCodeValid = true;
  batchInputAgentCodeErrorCode = 0;
  batchInputAgentCode = '';
  batchInputAgentName = '';
  batchInputAgentNameTC = '';
  clearDisabledState = true;
  submitDisabledState = true;
  addToItemState = true;
  private lanChangeSubscription?: Subscription;
  private delegationChangeEmployeeDataSubscription?: Subscription;
  isInWhichStage = 1;
  private errorSub?: Subscription;
  private eeDelegationChangeBatchValidateSubscription?: Subscription;
  private eeDelegationChangeSubmitSubscription?: Subscription;
  private agentCodeBatchApplySubscription?: Subscription;
  eeDelegationChangeDisplayedColumns: string[] = ['isSelected', 'employeeName', 'trusteeMbrId', 'delegateAgentInfo', 'newDelegateAgent', 'reasonOfChange'];
  eeDelegationChangeConfirmationDisplayedColumns: string[] = ['employeeName', 'trusteeMbrId', 'delegateAgentInfo', 'newDelegateAgent', 'reasonOfChange'];
  reasonOfChangeFormControl: FormControl = new FormControl();
  reasonOfChangeDropdownConfig: CdsDropdownConfig = {
    color: 'ml',
    type: 'default',
    placeholder: this.language === 'EN' ? 'Select reason of Change' : '選擇變更原因',
    options: [
      {
        label: this.language === 'EN' ? 'Customer complaint' : '客戶抱怨',
        value: 1,
      },
      {
        label: this.language === 'EN' ? 'Agent termination' : '代理終止',
        value: 2,
      },
      {
        label: this.language === 'EN' ? 'Performance issue' : '性能問題',
        value: 3,
      },
      {
        label: this.language === 'EN' ? 'Wrong agent code ' : '錯誤的代理代碼',
        value: 4,
      },
      {
        label: this.language === 'EN' ? 'Others ' : '其他原因',
        value: 5,
      },
    ],
  };

  agentCodeNameArr: {
    agentCode?: string;
    agentName?: string;
    agentNameTC?: string;
  }[] = [];

  private checkAgentCodeSubscription?: Subscription;
  reloadIcon = ArrowIcons.reload;
  stepperItems = [
    { en: 'Select Item(s)', zh: '選擇項目' },
    { en: 'Fill agent code', zh: '輸入代理人編碼' },
    { en: 'Select Reason', zh: '選擇原因' },
    { en: 'Add code to selected item(s)', zh: '新增編號至已選項目' },
    { en: 'Submission', zh: '提交' },
  ];

  onBackToRequestStatusSummaryPageClick() {
    this.router.navigate(['/agent/employer-history']);
  }

  constructor(
    private route: ActivatedRoute,
    private languageChangeService: LanguageChangeService,
    private agentEmployeeDelegationService: AgentEmployeeDelegationService,
    private alert: CdsAlertService,
    private cdsPopup: CdsPopupService,
    private router: Router,
    private agentEmployerDelegationService: AgentEmployerDelegationService
  ) {}

  ngOnDestroy(): void {
    this.lanChangeSubscription?.unsubscribe();
    this.delegationChangeEmployeeDataSubscription?.unsubscribe();
    this.errorSub?.unsubscribe();
    this.agentCodeBatchApplySubscription?.unsubscribe();
    this.clearItems();
  }

  ngOnInit(): void {
    this.employerId = String(this.route.snapshot.paramMap.get('employerId'));
    this.route.queryParams.subscribe(param => {
      this.employerName = specialCharacterDecode(decodeURI(param['en']));
      this.employerNameTC = specialCharacterDecode(decodeURI(param['zh']));
    });

    this.errorSub = this.agentEmployeeDelegationService.errorEmployeeSubject.subscribe(errorMessage => {
      const alertTitle = 'Got Error!';
      this.alert.error(alertTitle, errorMessage);
    });

    this.lanChangeSubscription = this.languageChangeService.lanSubject.subscribe(lan => {
      this.language = lan === 'en' ? 'EN' : 'TC';
    });

    this.delegationChangeEmployeeDataSubscription =
      // put datasource(by Subject next and subscribe method) in pagination pageChange method
      this.agentEmployeeDelegationService.employeeDataSubject.subscribe(response => {
        if (response.data && response.data.content) {
          this.dataSource = response.data.content;
          this.employeeTotalCount = response.data.totalElements;
          this.pageCount = response.data.totalPages;
          if (this.pageFirstInit) {
            for (let i = 0; i < this.pageCount; i++) {
              this.fullDataSource.push({
                data: [],
                totalElements: this.employeeTotalCount,
                totalPages: this.pageCount,
              });
            }
            this.pageFirstInit = false;
          }
          this.fullDataSource.splice(this.markPage - 1, 1, {
            data: this.dataSource,
            totalElements: this.employeeTotalCount,
            totalPages: this.pageCount,
          });
          this.allChecked = false;
        } else {
          this.dataSource = [];
          this.employeeTotalCount = response.data ? response.data.totalElements : 0;
          this.pageCount = response.data ? response.data.totalPages : 0;
        }
        this.isShowProgress = false;
      });

    this.agentCodeBatchApplySubscription = this.agentEmployerDelegationService.agentCodeBatchApplySubject.subscribe(resp => {
      if (resp.result === 0 && resp.data) {
        const checkedAgentcode = resp.data ? resp.data.agentCode : '';
        if (checkedAgentcode) {
          this.batchInputAgentCode = checkedAgentcode;
        }
        this.batchInputAgentCodeValid = true;
        this.agentCodeName(resp.data);
        this.isDisableSubmit = false;
        this.isDisableClear = false;
        const currentPages = this.fullDataSource.length;
        for (let i = 0; i < currentPages; i++) {
          const pageData: AgentEmployeeResponseData[] = this.fullDataSource[i]['data'];
          pageData
            .filter(element => element.isSelected)
            .forEach(element => {
              element.inputtedAgentCode = this.batchInputAgentCode;
              element.reasonOfChange = this.reasonOfChangeFormControl.value;
              element.inputtedReasonOfOthers = this.batchInputReasonOfOthers;
              element.flag = true;
              element.isSelected = false;
            });
        }
        this.batchInputAgentCode = '';
        this.batchInputAgentName = '';
        this.batchInputAgentNameTC = '';
        this.reasonOfChangeFormControl.setValue(0);
      } else {
        this.batchInputAgentCodeValid = false;
        if (resp.data && resp.data.errorCode) {
          this.batchInputAgentCodeErrorCode = resp.data.errorCode;
        }
      }
    });
    this.pageChange(1);
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if (window.matchMedia('only screen and (max-width: 1500px)').matches) {
        this.stepperTarget = 'ipad';
      } else {
        this.stepperTarget = 'pc';
      }

      if (this.stepperTarget === 'ipad') {
        this.stepper.setStepList(this.stepList);
      }

      this.refreshStep();
    }, 0);
  }

  backErIconConfig: CdsIconConfig = {
    color: 'cta',
    size: 'sm',
  };

  employeeNameTxtfieldConfig: CdsTextfieldConfig = {
    label: this.language === 'EN' ? 'Employee Name' : '雇員名稱',
    type: 'text',
    maxlength: 20,
    color: 'ml',
    placeholder: this.language === 'EN' ? 'Please input' : '輸入詳情',
  };

  numbersTxtfieldConfig: CdsTextfieldConfig = {
    label: this.language === 'EN' ? 'Cert No' : '僱員編號',
    type: 'text',
    placeholder: this.language === 'EN' ? 'Please input' : '輸入詳情',
  };

  delegateTxtfieldConfig: CdsTextfieldConfig = {
    label: this.language === 'EN' ? 'Delegated Agent Code' : '委託代理編號',
    type: 'text',
    placeholder: this.language === 'EN' ? 'Please input' : '輸入詳情',
  };

  primaryButtonConfig: CdsButtonConfig = {
    size: 'sm',
    style: 'primary',
  };

  secondaryButtonConfig: CdsButtonConfig = {
    size: 'sm',
    style: 'secondary',
  };

  myEmployeeRequest: EmployeeRequest = {
    page: 1,
    size: 10,
    language: this.language,
    sort: 'dateJoiningTheSchm desc,trusteeMbrId asc',
    isDelegated: 'Y',
    isCoolingPeriod: 'N',
  };

  colAgentTxtfieldConfig: CdsTextfieldConfig = {
    placeholder: this.language === 'EN' ? 'Input new agent code' : '輸入新的代理代碼',
    type: 'text',
  };

  nameChange(result: string): void {
    this.nameValue = result;
    if (result === '') {
      this.nameValue = '';
    }
  }

  numberChange(result: string): void {
    this.numberValue = result;
    if (result === '') {
      this.numberValue = '';
    }
  }

  delegateAgentChange(result: string): void {
    this.delegateAgentInfoValue = result;
    if (result === '') {
      this.delegateAgentInfoValue = '';
    }
  }

  employeeDelegationChangeSearch(): void {
    const popupRef: MatDialogRef<EmployeeDelegationChangePopComponent> = this.cdsPopup.open(EmployeeDelegationChangePopComponent);
    popupRef.afterClosed().subscribe(_data => {
      if (_data.agree) {
        if (this.nameValue == '' && this.numberValue == '' && this.delegateAgentInfoValue == '') {
          return;
        }
        this.myEmployeeRequest.employeeName = this.nameValue;
        this.myEmployeeRequest.trusteeMbrId = this.numberValue;

        this.myEmployeeRequest.page = 1;
        this.myEmployeeRequest.delegateAgentCode = this.delegateAgentInfoValue;
        this.isShowProgress = true;
        this.agentEmployeeDelegationService.getEmployees(this.myEmployeeRequest);
        this.fullDataSource = [];
        this.pageFirstInit = true;
      }
    });
  }

  clearData(): void {
    const popupRef: MatDialogRef<EmployeeDelegationChangePopComponent> = this.cdsPopup.open(EmployeeDelegationChangePopComponent);
    popupRef.afterClosed().subscribe(_data => {
      if (_data.agree) {
        this.nameValue = '';
        this.numberValue = '';
        this.delegateAgentInfoValue = '';
        this.myEmployeeRequest = {
          page: 1,
          size: 10,
          language: this.language,
          sort: 'dateJoiningTheSchm desc,trusteeMbrId asc',
          isDelegated: 'Y',
          isCoolingPeriod: 'N',
          employerId: this.employerId,
        };
        this.agentEmployeeDelegationService.getEmployees(this.myEmployeeRequest);
        this.fullDataSource = [];
        this.pageFirstInit = true;
      }
    });
  }

  computedIsHalfSelected() {
    let currentPageData = this.fullDataSource[this.markPage - 1] ? this.fullDataSource[this.markPage - 1].data : undefined;
    if (currentPageData === undefined) {
      currentPageData = [];
    }
    const isSelectedAll = currentPageData.every(item => item.isSelected);
    if (isSelectedAll) {
      return false;
    }

    const isSelectedSome = currentPageData.some(item => item.isSelected);
    if (isSelectedSome) {
      return true;
    }

    return false;
  }

  onHeaderCheckboxChange(val: boolean, whichPage: number) {
    this.allChecked = val;
    this.fullDataSource[whichPage - 1].data.forEach(element => {
      element.isSelected = val;
    });
    this.setClearBtnEnableOrNot();
    this.refreshStep();
  }

  setClearBtnEnableOrNot() {
    const newArr: AgentEmployeeResponseData[] = this.integrateToNewArr();
    const isSelected: boolean = newArr.some(element => element.isSelected); //check if any rows are selected
    if (isSelected) {
      this.isDisableClear = false;
    } else {
      this.isDisableClear = true;
    }
  }

  integrateToNewArr() {
    let newArr: AgentEmployeeResponseData[] = [];
    const currentPages = this.fullDataSource.length;
    for (let i = 0; i < currentPages; i++) {
      const pageData: AgentEmployeeResponseData[] = this.fullDataSource[i]['data'];
      if (pageData.length !== 0) {
        newArr = newArr.concat(pageData);
      }
    }
    return newArr;
  }

  onClickRowSelect(val: boolean, checkedIndex: number, pageNo: number) {
    this.fullDataSource[pageNo - 1].data[checkedIndex].isSelected = val;
    const isSomeNotChecked = this.fullDataSource[pageNo - 1]['data'].some(element => !element.isSelected);
    if (isSomeNotChecked) {
      this.allChecked = false;
    } else {
      this.allChecked = true;
    }
    this.setClearBtnEnableOrNot();
    this.refreshStep();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  delgtSearchByInputPageNo($event: any, pageNo: string) {
    $event.preventDefault();
    console.info($event, pageNo);
    console.info(parseInt(pageNo));
    if (isNaN(parseInt(pageNo))) {
      this.searchValue = 1;
    }
    this.pageChange(parseInt(pageNo));
  }

  batchAgentCodeChange(agentCode: string) {
    if (agentCode.length === 0) {
      this.batchInputAgentCodeValid = true;
    }
    this.batchInputAgentCode = agentCode;

    // if (agentCode.length === 6) {
    //   this.agentEmployerDelegationService
    //     .agentCodeValidate({ agentCode: this.batchInputAgentCode })
    //     .subscribe((res: CheckAgentCodeResponse) => {
    //       if (res.result !== 0) {
    //         this.batchInputAgentCodeValid = false;
    //         if (res.data && res.data.errorCode) {
    //           this.batchInputAgentCodeErrorCode = res.data.errorCode;
    //         }
    //       } else {
    //         this.batchInputAgentCodeValid = true;
    //         this.batchInputAgentName = res.data?.name || '';
    //         this.batchInputAgentNameTC = res.data?.chineseName || '';
    //       }
    //     });
    // }

    this.refreshStep();
  }

  eeDelegationChangeSubmit() {
    if (this.isDisableSubmit) {
      return;
    }

    // clear batch agent input and clear batch agent input error
    // this.batchInputAgentCode = '';
    // this.batchInputAgentCodeValid = true;
    const tmpData: {
      data: AgentEmployeeResponseData[];
      totalElements: number;
      totalPages: number;
    }[] = this.fullDataSource.filter(ele => ele.data.length > 0);
    let elementsToSubmitTmp: AgentEmployeeResponseData[] = [];
    let foundNotCompleteElement = false;
    for (const element of tmpData) {
      const pageData: AgentEmployeeResponseData[] = element['data'];
      if (pageData.length > 0) {
        elementsToSubmitTmp = elementsToSubmitTmp.concat(
          pageData.filter(_ele => {
            let agentCodeValidTmp = false;
            if (_ele.flag && _ele.inputtedAgentCode) {
              agentCodeValidTmp = true;
            }
            let reasonOfChangeValidTmp = false;
            if (_ele.reasonOfChange && ((_ele.reasonOfChange >= 1 && _ele.reasonOfChange <= 4) || (_ele.reasonOfChange === 5 && _ele.inputtedReasonOfOthers))) {
              reasonOfChangeValidTmp = true;
            }
            if (agentCodeValidTmp && reasonOfChangeValidTmp) {
              return true;
            }
            return false;
          })
        );
        for (const _row of pageData) {
          if (_row.flag && _row.inputtedAgentCode && !_row.reasonOfChange) {
            _row.isMissingReasonOfChange = true;
            foundNotCompleteElement = true;
          }
          if (!_row.inputtedAgentCode && _row.reasonOfChange) {
            _row.isMissingAgentCode = true;
            foundNotCompleteElement = true;
          }
        }
      }
    }
    if (foundNotCompleteElement || elementsToSubmitTmp.length === 0) {
      return;
    }
    const eeDelegationBatchCheck: EmployeeDelegationChangeRequest[] = [];

    elementsToSubmitTmp.forEach(element => {
      console.info(element.memberId);
      const ckData = new EmployeeDelegationChangeRequest();
      ckData.employerId = this.employerId;
      ckData.memberId = element.memberId;
      ckData.oldAgentCode = element.delegateAgentInfo.agentCode;
      ckData.targetAgentCode = element.inputtedAgentCode;
      const reasonTmp: ChangeReason = {
        code: element.reasonOfChange,
        message: element.inputtedReasonOfOthers,
      };
      ckData.reason = reasonTmp;
      eeDelegationBatchCheck.push(ckData);
    });
    this.eeDelegationChangeBatchValidateSubscription?.unsubscribe();
    this.agentEmployeeDelegationService.eeDelegationChangeBatchValidate(eeDelegationBatchCheck);
    this.eeDelegationChangeBatchValidateSubscription = this.agentEmployeeDelegationService.eeDelegationChangeBatchValidateSubject.subscribe(resp => {
      if (resp.result === 0) {
        this.navigateToConfirmation(elementsToSubmitTmp);
      } else {
        const eeDeleChResp: AgentCodeBatchValidateResponseData[] = resp.data ? resp.data : [];
        this.addAlertItems(this.filter2104Error(eeDeleChResp), elementsToSubmitTmp);
      }
    });
  }

  alertItems: {
    index: number;
    ifShow: boolean;
    message: string;
    messageTC: string;
  }[] = [];

  // check if found 2103 and 2104 the same time, ignore 2103, prompt 2104 error has high priority
  filter2104Error(data: AgentCodeBatchValidateResponseData[]) {
    const isFound2104 = data.some(_ele => _ele.errorCode === 2104);
    if (isFound2104) {
      return data.filter(_ele => _ele.errorCode === 2104);
    }
    return data;
  }

  addAlertItems(data: AgentCodeBatchValidateResponseData[], elementsToSubmitTmp: AgentEmployeeResponseData[]) {
    this.alertItems = [];
    const agentArr: Agent[] = [];
    for (let i = 0; i < data.length; i++) {
      let resultMsg = '';
      let resultMsgTC = '';
      if (data[i].errorCode === 2104) {
        const existsAgents1 = data[i].existAgents;
        const existsAgents2 = data[i].existAgents;
        let agentEn = existsAgents1?.map(existsAgent => existsAgent.agentCode + ' ' + existsAgent.name).join('、');
        const tempAgent = agentEn?.split('');
        tempAgent?.pop();
        agentEn = tempAgent?.join('');

        let agentTc = existsAgents2
          ?.map(existsAgent => {
            console.info(existsAgent.agentCode + ' ' + (existsAgent.chineseName ? existsAgent.chineseName : existsAgent.name));
            return existsAgent.agentCode + ' ' + (existsAgent.chineseName ? existsAgent.chineseName : existsAgent.name);
          })
          .join('、');
        const tempAgentTc = agentTc?.split('');
        tempAgentTc?.pop();
        agentTc = tempAgentTc?.join('');

        const limitAgentNum = data[i].limitAgent;
        resultMsg = data[i].employerInfo?.employerName + ' has been delegated to ' + limitAgentNum + ' agents including:' + agentEn;
        resultMsgTC = data[i].employerInfo?.employerName + ' 已被委況託 ' + limitAgentNum + ' 位代理人，包括:' + agentTc;
        this.alertItems.push({
          index: i,
          ifShow: true,
          message: resultMsg,
          messageTC: resultMsgTC,
        });
      } else if (data[i].errorCode === 2103) {
        const agentTmp = data[i].targetAgent;
        if (agentTmp) {
          agentArr.push(agentTmp);
        }
      }
    }
    if (agentArr.length > 0) {
      // add warning message for 2103(agent not in same branch)
      const popupRef: MatDialogRef<AgentBranchPopComponent> = this.cdsPopup.open(AgentBranchPopComponent, {
        data: { list: agentArr, language: this.language },
        size: 'md',
      });
      popupRef.afterClosed().subscribe(_data => {
        if (_data.agree) {
          this.navigateToConfirmation(elementsToSubmitTmp);
        }
      });
    }
  }

  navigateToConfirmation(elementsToSubmitTmp: AgentEmployeeResponseData[]) {
    this.isInWhichStage = 2;
    this.elementsToSubmit = elementsToSubmitTmp;
    this.addAgentNameForConfirmation(elementsToSubmitTmp);
    this.splitElements(elementsToSubmitTmp);
    this.pageChangeInConfirmation(1);
    this.searchValueEEDCConfirm = '1';
    pageToTop();
  }

  addAgentNameForConfirmation(employerResponseData: AgentEmployeeResponseData[]) {
    employerResponseData.forEach(ele => {
      const found = this.agentCodeNameArr.find(ac => ac.agentCode === ele.inputtedAgentCode);
      ele.toDelegateAgentName = found?.agentName;
      ele.toDelegateAgentNameTC = found?.agentNameTC;
    });
  }

  splitElements(employeeResponseData: AgentEmployeeResponseData[]) {
    const len = employeeResponseData.length;
    const splitPages = Math.ceil(len / 10);
    const splitPageSize = 10;
    localStorage.setItem('totalCountEEDCConfirm', len + '');
    localStorage.setItem('pageCountOfEEDCConfirm', splitPages + '');
    for (let i = 1; i <= splitPages; i++) {
      const strPerPage = JSON.stringify(this.arrayPage(employeeResponseData, i, splitPageSize));
      localStorage.setItem('page' + i, strPerPage);
    }
  }

  arrayPage(employeeResponseData: AgentEmployeeResponseData[], page: number, pageSize: number): AgentEmployeeResponseData[] {
    if (page <= 0) {
      return [];
    }
    const minIdx = pageSize * (page - 1);
    const maxIdx = pageSize * page;

    return employeeResponseData.slice(minIdx, maxIdx);
  }

  // DC = delegation change
  pageCountOfEEDCConfirm = 0;
  pageEEDCConfirm = 0;
  totalCountEEDCConfirm = 0;
  searchValueEEDCConfirm = '';

  pageChangeInConfirmation(num: number): void {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const pageInfoByNum = localStorage.getItem('page' + num)!;
    this.dataSourceInConfirmation = JSON.parse(pageInfoByNum);
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const totalItems = parseInt(localStorage.getItem('totalCountEEDCConfirm')!);
    this.pageCountOfEEDCConfirm = Math.ceil(totalItems / 10);
    this.totalCountEEDCConfirm = totalItems;
    this.pageEEDCConfirm = num;
    this.searchValueEEDCConfirm = num + '';
  }

  pageFirstInit = true;

  pageChange2(num: number): void {
    if (!this.pageFirstInit && num === this.page) {
      this.page = num;
      return;
    }
    this.page = num;
    // markPage is to record which page now, it's useful in checkbox click event
    this.markPage = num;
    this.searchValue = num;
    const pageData: {
      data: AgentEmployeeResponseData[];
      totalElements: number;
      totalPages: number;
    } = this.fullDataSource[num - 1];
    if (pageData && pageData['data'].length !== 0) {
      this.dataSource = pageData['data'];
      this.employeeTotalCount = pageData.totalElements;
      this.pageCount = pageData.totalPages;
      // check if there is item which is not checked in this page
      const isNotSelected: boolean = this.dataSource.some(element => !element.isSelected);
      if (isNotSelected) {
        this.allChecked = false;
      } else {
        this.allChecked = true;
      }
    } else {
      // if this page has not loaded data from BE
      this.myEmployeeRequest.employerId = this.employerId;
      this.myEmployeeRequest.page = num;
      this.agentEmployeeDelegationService.getEmployees(this.myEmployeeRequest);
      // put datasource(by Subject next and subscribe method) in pagination pageChange method
      this.agentEmployeeDelegationService.employeeDataSubject.subscribe(response => {
        if (response.data) {
          this.dataSource = response.data.content;
          this.employeeTotalCount = response.data.totalElements;
          this.pageCount = response.data.totalPages;
          this.isShowProgress = false;
          if (this.pageFirstInit) {
            for (let i = 0; i < this.pageCount; i++) {
              this.fullDataSource.push({
                data: [],
                totalElements: this.employeeTotalCount,
                totalPages: this.pageCount,
              });
            }
            this.pageFirstInit = false;
          }
          this.fullDataSource.splice(num - 1, 1, {
            data: this.dataSource,
            totalElements: this.employeeTotalCount,
            totalPages: this.pageCount,
          });
          console.info(this.fullDataSource, 88989898);
          this.allChecked = false;
        }
      });
    }
  }

  pageChange(num: number): void {
    if (!this.pageFirstInit && num === this.page) {
      this.page = num;
      return;
    }
    this.page = num;
    // markPage is to record which page now, it's useful in checkbox click event
    this.markPage = num;
    this.searchValue = num;
    const pageData: {
      data: AgentEmployeeResponseData[];
      totalElements: number;
      totalPages: number;
    } = this.fullDataSource[num - 1];
    if (pageData && pageData['data'].length !== 0) {
      this.dataSource = pageData['data'];
      this.employeeTotalCount = pageData.totalElements;
      this.pageCount = pageData.totalPages;
      // check if there is item which is not checked in this page
      const isNotSelected: boolean = this.dataSource.some(element => !element.isSelected);
      if (isNotSelected) {
        this.allChecked = false;
      } else {
        this.allChecked = true;
      }
    } else {
      // if this page has not loaded data from BE
      this.myEmployeeRequest.employerId = this.employerId;
      this.myEmployeeRequest.page = num;
      this.agentEmployeeDelegationService.getEmployees(this.myEmployeeRequest);
    }
  }

  eeDelegationChangeBackToEdit() {
    this.isInWhichStage = 1;
    this.elementsToSubmit = [];
    this.clearItems();
    setTimeout(() => {
      if (this.stepperTarget === 'ipad') {
        this.stepper.setStepList(this.stepList);
      }

      this.refreshStep();
    }, 0);
  }

  elementsToSubmit: AgentEmployeeResponseData[] = [];

  batchReasonOfChange?: number;
  batchInputReasonOfOthers?: string;
  batchReasonOfChangeOnChange(reason: number) {
    // when reason changed, should disable it first
    this.batchInputReasonOfOthers = '';
    if (reason === 5) {
      const dialogRef = this.cdsPopup.open(AddToItemComponent);
      dialogRef.afterClosed().subscribe((result: string | undefined) => {
        if (result != undefined) {
          if (typeof result === 'string' && result.trim().length > 0) {
            this.batchInputReasonOfOthers = result;
          }
        } else {
          this.reasonOfChangeFormControl.setValue(0);
          this.batchInputReasonOfOthers = '';
        }
        this.refreshStep();
      });
    }
    if (reason >= 1 && reason <= 5) {
      this.isDisableClear = false;
    }
    this.batchReasonOfChange = reason;
    setTimeout(() => {
      this.refreshStep();
    }, 0);
  }

  isDisableSubmit = true;
  isDisableClear = true;

  applyToAll() {
    if (this.isDisableBatchApply) {
      return;
    }

    this.batchInputAgentCode = this.batchInputAgentCode.trim();
    if (this.batchInputAgentCode.length > 0 && this.batchInputAgentCode.length < 6) {
      this.batchInputAgentCodeValid = false;
      this.batchInputAgentCodeErrorCode = 2199;
    } else {
      this.batchInputAgentCodeValid = true;

      const ckRequestTmp: CheckAgentCodeRequest = {};
      ckRequestTmp.agentCode = this.batchInputAgentCode.trim();
      const isThereAnyRowInAllPagesChecked = this.isThereCheckboxCheckedInAllPages();
      if (isThereAnyRowInAllPagesChecked) {
        this.agentEmployerDelegationService.agentCodeBatchApply(ckRequestTmp);
      } else {
        alert('no rows checked');
      }
    }
  }

  dlgtChangeClearData() {
    if (this.isDisableClear) {
      return;
    }

    const popupRef: MatDialogRef<UserExitEditmodePopComponent> = this.cdsPopup.open(UserExitEditmodePopComponent, {});
    popupRef.afterClosed().subscribe(data => {
      if (data.agree) {
        this.clearTableInputData();
        this.allChecked = false;
        this.isDisableClear = true;
        this.isDisableSubmit = true;
        this.batchInputAgentCode = '';
        this.batchInputAgentName = '';
        this.batchInputAgentNameTC = '';
        this.batchInputAgentCodeValid = true;
        this.reasonOfChangeFormControl.setValue(0);
        this.reasonOfChangeFormControl.disable();
        this.stepper.removeLightsOutStep(3);
        this.stepper.removeLightsOutStep(4);
      }
    });
  }

  clearTableInputData() {
    this.fullDataSource.forEach(element => {
      element.data.forEach(data => {
        data.reasonOfChange = undefined;
        data.inputtedAgentCode = '';
        data.inputtedReasonOfOthers = '';
        data.isSelected = false;
        data.flag = true; // this code is to clear agent code error message in table row
      });
    });
  }

  isThereCheckboxCheckedInAllPages() {
    const currentPages = this.fullDataSource.length;
    for (let i = 0; i < currentPages; i++) {
      const pageData: AgentEmployeeResponseData[] = this.fullDataSource[i]['data'];
      const isSelected: boolean = pageData.some(element => element.isSelected);
      if (isSelected) {
        return true;
      }
    }
    return false;
  }

  agentCodeTransfer(errorCode: number): {
    agentCodeCheckResult: string;
    agentCodeCheckResultTC: string;
  } {
    let agentCodeCheckResultByLan = '';
    let agentCodeCheckResultByLanTC = '';
    if (errorCode === 2101) {
      agentCodeCheckResultByLan = 'Agent code does not exist';
      agentCodeCheckResultByLanTC = '代理人編號不存在';
    } else if (errorCode === 2102) {
      agentCodeCheckResultByLan = 'Inactive agent';
      agentCodeCheckResultByLanTC = '無效代理人';
    } else if (errorCode === 2103) {
      agentCodeCheckResultByLan = 'Agent is not in same distinct';
      agentCodeCheckResultByLanTC = '代理人區域不相配';
    } else if (errorCode === 2199) {
      agentCodeCheckResultByLan = 'Agent code should be a 6 digits number, please add 0 in the front if the agent code is only 5 digits';
      agentCodeCheckResultByLanTC = '代理人編號應為六位數字, 如代理人編號只有五位數字, 請在編號前補上0';
    }
    return {
      agentCodeCheckResult: agentCodeCheckResultByLan,
      agentCodeCheckResultTC: agentCodeCheckResultByLanTC,
    };
  }

  agentCodeName(agentCodeData: AgentCodeData) {
    if (this.agentCodeNameArr.length === 0) {
      const agentCodeDataTmp = {
        agentCode: agentCodeData.agentCode,
        agentName: agentCodeData.name,
        agentNameTC: agentCodeData.chineseName,
      };
      this.agentCodeNameArr.push(agentCodeDataTmp);
    } else {
      const index = this.agentCodeNameArr.indexOf(agentCodeData);
      if (index === -1) {
        const agentCodeDataTmp = {
          agentCode: agentCodeData.agentCode,
          agentName: agentCodeData.name,
          agentNameTC: agentCodeData.chineseName,
        };
        this.agentCodeNameArr.push(agentCodeDataTmp);
      }
    }
  }

  clearItems() {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const lsPages = localStorage.getItem('pageCountOfEEDCConfirm');
    if (lsPages) {
      const pages = parseInt(lsPages);
      for (let i = 1; i <= pages; i++) {
        localStorage.removeItem('page' + i);
      }
    }
    localStorage.removeItem('pageCountOfEEDCConfirm');
    localStorage.removeItem('totalCountEEDCConfirm');
  }

  eeDelegationChangeConfirm() {
    const eeDCRequest: EmployeeDelegationChangeRequest[] = [];
    if (this.elementsToSubmit.length > 0) {
      const popupRef: MatDialogRef<UserConfirmPopComponent> = this.cdsPopup.open(UserConfirmPopComponent, {});

      popupRef.afterClosed().subscribe(data => {
        if (data.agree) {
          this.elementsToSubmit.forEach(element => {
            const submitDelegateRequestTmp = new EmployeeDelegationChangeRequest();
            submitDelegateRequestTmp.employerId = this.employerId;
            submitDelegateRequestTmp.memberId = element.memberId;
            submitDelegateRequestTmp.oldAgentCode = element.delegateAgentInfo.agentCode;
            submitDelegateRequestTmp.targetAgentCode = element.inputtedAgentCode;
            const reasonTmp: ChangeReason = {
              code: element.reasonOfChange,
              message: element.inputtedReasonOfOthers,
            };
            submitDelegateRequestTmp.reason = reasonTmp;

            eeDCRequest.push(submitDelegateRequestTmp);
          });
          this.eeDelegationChangeSubmitSubscription?.unsubscribe();
          this.agentEmployeeDelegationService.eeDelegationChangeSubmit(eeDCRequest);
          this.eeDelegationChangeSubmitSubscription = this.agentEmployeeDelegationService.eeDelegationChangeSubmitSubject.subscribe(resp => {
            if (resp.result === 0) {
              const popupRefSubmit: MatDialogRef<UserSuccessSubmittedComponent> = this.cdsPopup.open(UserSuccessSubmittedComponent, {});
              popupRefSubmit.afterClosed().subscribe(dataConfirm => {
                if (dataConfirm.agree) {
                  this.router.navigate(['/agent/employer-history']);
                }
              });
            } else if (resp.result !== 0) {
              //this.addAlertItems(this.checkResultList);
            }
          });
        }
      });
    }
  }

  dismissAlert(index: number) {
    this.alertItems[index].ifShow = false;
  }

  get isDisableBatchApply() {
    return !(
      this.batchInputAgentCode.length > 0 &&
      +this.reasonOfChangeFormControl.value > 0 &&
      +this.reasonOfChangeFormControl.value < 6 &&
      this.fullDataSource.some(d => d.data.some(employee => employee.isSelected))
    );
  }

  isDisableBatchApplyReasonOfChange() {
    const disable =
      this.batchInputAgentCode.length === 0 || !this.fullDataSource.some(d => d.data.some(employee => employee.isSelected)) || !this.batchInputAgentCodeValid;
    if (disable) {
      this.reasonOfChangeFormControl.disable();
    } else {
      this.reasonOfChangeFormControl.enable();
    }
  }

  ngDoCheck(): void {
    this.isDisableBatchApplyReasonOfChange();
  }

  get itemTotal() {
    let total = 0;

    this.fullDataSource.forEach(d => {
      d.data.forEach(employee => {
        if (employee.isSelected) {
          total++;
        }
      });
    });

    return total;
  }

  private refreshStep() {
    let stepNum = 1;

    if (this.fullDataSource.some(d => d.data.some(employee => employee.isSelected))) {
      stepNum = 2;
    }

    if (stepNum === 2 && this.batchInputAgentCode.length > 0) {
      stepNum = 3;
    }

    if (stepNum === 3 && this.reasonOfChangeFormControl.value && this.reasonOfChangeFormControl.value > 0) {
      stepNum = 4;
    }

    if (!this.isDisableSubmit) {
      stepNum = 5;

      if (this.fullDataSource.some(d => d.data.some(employee => employee.isSelected))) {
        if (this.batchInputAgentCode.length === 0) {
          this.stepper.setLightsOutStep(3);
        } else {
          this.stepper.removeLightsOutStep(3);
        }
        if (!this.reasonOfChangeFormControl.value) {
          this.stepper.setLightsOutStep(4);
        } else {
          this.stepper.removeLightsOutStep(4);
        }
      } else {
        this.stepper.removeLightsOutStep(3);
        this.stepper.removeLightsOutStep(4);
      }
    }

    this.stepper.setFinishStep(stepNum);
  }

  get isDisableBatchApplyAgentCode() {
    return !this.fullDataSource.some(d => d.data.some(employee => employee.isSelected));
  }

  get checkIsHaveScroll() {
    if (this.tableBox) {
      return this.tableBox.nativeElement.scrollWidth > this.tableBox.nativeElement.clientWidth;
    }
    return false;
  }
}
