export const AccountCodeExplain =
  "<div class='l-pl-4 l-pr-4'><span class='cds-h3-light l-pt-2'>Employer / Member Account Code</span><br><br>" +
  'This field is represented as Employer Account Code when the account type is <br>' +
  '• MPF-ER <br>' +
  '• ORSO-ER <br>' +
  '• MACAU-ER(PPS) <br>' +
  '• MACAU-CPF <br>' +
  '<br>' +
  'This field is represented as Member Account Code when the account type is <br><br>' +
  '• MPF-SEP <br>' +
  '• MPF-SEP(AGENT)  <br>' +
  '• MPF-FRC  <br>' +
  '• MPF-TVC <br>' +
  '• MPF-PAP <br>' +
  '• MACAU-PAP <br><br>' +
  'No member account code of the MPF-ER/ORSO-ER/MACAU-CPF/MACAU-ER(PPS) will be under the Related Parties of the Commission Pool<br>';

export const RejoinIntraGroupExplain = `
  <div class="l-mb-4">Once updated to Rejoin / Intra Group, it is not allowed to untick the group as NB Account.</div>
  <div>If user need IT patch the group back to NB Account, please consider this account setup within first year or not. If this account is setup within first year, please contact Finance team to special handling on the System Adjustment APE.</div>
  `;
