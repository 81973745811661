import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CdsLanguageModule } from '@cds/ng-core/lang';
import { CdsIconModule } from '@cds/ng-web-components/icon';
import { CdBackTextComponent } from './cd-back-text.component';

@NgModule({
  imports: [CommonModule, CdsIconModule, CdsLanguageModule],
  declarations: [CdBackTextComponent],
  exports: [CdBackTextComponent],
})
export class CdBackTextModule {}
