/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { CdsButtonConfig } from '@cds/ng-core/button';
import { finalize } from 'rxjs';
import { ColumnConfig, PageConfig, SortMode } from 'src/app/shared/data-table';
import { AccountBalance, PaymentMethodE, SuspenseRecord, SuspenseRecordSearchRequest, paymentMethodOptions } from '../../employer';
import { EmployerService } from '../../employer.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CdsDropdownConfig } from '@cds/ng-core/dropdown';
import { DateTime } from 'luxon';
import moment from 'moment';
import { dateValidator } from 'src/app/shared/validators/validators';
import { ToastAlertService } from 'src/app/shared/toast-alert.service';
import { findOptionLabelByValue } from 'src/app/utils/utils';
import { deepCopy } from 'src/app/utils/copy';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-suspense-record',
  templateUrl: './suspense-record.component.html',
  styleUrls: ['./suspense-record.component.scss'],
})
export class SuspenseRecordComponent implements OnInit, OnChanges {
  @Input() payrollGroupId?: string;
  @Input() id?: string;

  @ViewChild('tableBox', { static: false })
  tableBox?: ElementRef<HTMLDivElement>;

  data: AccountBalance = {};

  dataSource: SuspenseRecord[] = [];

  originColumnsConfig: ColumnConfig[] = [
    {
      key: 'receiptReferenceNo',
      title: 'Receipt Reference No.',
      sort: SortMode.DEFAULT,
      sticky: true,
    },
    {
      key: 'paymentSubmissionReferenceNo',
      title: 'Payment Submission Reference No.',
    },
    { key: 'paymentReceivedDate', title: 'Payment Received Date' },
    { key: 'receivedAmount', title: 'Received Amount' },
    { key: 'availableAmount', title: 'Available Amount' },
    { key: 'taggedAmount', title: 'Tagged Amount' },
    { key: 'overpaidAmount', title: 'Overpaid Amount' },
    { key: 'paymentMethod', title: 'Payment Method' },
  ];

  columnsConfig = deepCopy(this.originColumnsConfig);

  pageConfig: PageConfig = {
    current: 1,
    pageSize: 10,
    totalCount: 0,
  };

  numberOfElements = 0;

  searchButtonConfig: CdsButtonConfig = {
    size: 'sm',
  };

  resetButtonConfig: CdsButtonConfig = {
    size: 'sm',
    style: 'secondary',
  };

  get searchButtonDisabled() {
    return !this.form?.valid || !this.form.dirty;
  }

  resetButtonDisabled = true;

  showDatePicker = true;

  isLoading = false;

  dataLoading = false;

  _searched = false;

  nowTimeInstr = DateTime.now().toFormat('dd/MM/yyyy');

  form: FormGroup = new FormGroup({
    receiptReferenceNo: new FormControl('', [Validators.maxLength(35)]),
    paymentSubmissionReferenceNo: new FormControl('', [Validators.maxLength(50)]),
    paymentReceivedDate: new FormControl('', [dateValidator('create.dateError', '', this.nowTimeInstr)]),
    paymentMethod: new FormControl(''),
  });

  _valid = false;

  formReady = false;

  get receiptReferenceNoControl() {
    return this.form?.get('receiptReferenceNo');
  }

  get paymentSubmissionReferenceNoControl() {
    return this.form?.get('paymentSubmissionReferenceNo');
  }

  get paymentReceivedDateControl() {
    return this.form?.get('paymentReceivedDate');
  }

  get paymentMethodControl() {
    return this.form?.get('paymentMethod');
  }

  methodConfig: CdsDropdownConfig = {
    label: 'Payment Method',
    placeholder: 'Select Payment Method',
    options: paymentMethodOptions,
  };

  maxTime = this.getNowTime();

  searchDTO: SuspenseRecordSearchRequest = {
    page: 1,
    size: 10,
  };

  constructor(private service: EmployerService, private toastAlert: ToastAlertService, private router: Router, private route: ActivatedRoute) {}

  ngOnInit(): void {}

  inputChange(val: string, label: string) {
    if (val) {
      val = val.trim();
    }
    switch (label) {
      case 'receiptReferenceNo':
        this.searchDTO.receiptReferenceNo = val;
        break;
      case 'paymentSubmissionReferenceNo':
        this.searchDTO.paymentSubmissionReferenceNo = val;
        break;
      case 'paymentReceivedDate':
        this.searchDTO.paymentReceivedDate = val;
        break;
      case 'paymentMethod':
        this.searchDTO.paymentMethod = val;
        break;
    }
    this.validating();
  }

  validating() {
    if (
      !!this.searchDTO.receiptReferenceNo ||
      !!this.searchDTO.paymentSubmissionReferenceNo ||
      !!this.searchDTO.paymentReceivedDate ||
      !!this.searchDTO.paymentMethod
    ) {
      this._valid = true;
    } else {
      this._valid = false;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['payrollGroupId']?.currentValue && changes['payrollGroupId']?.currentValue !== changes['payrollGroupId']?.previousValue) {
      this.getData();
    }
  }

  getNowTime() {
    return moment().toDate();
  }

  getRequestParams() {
    const params: SuspenseRecordSearchRequest = {
      ...this.searchDTO,
      id: this.id || '',
      payrollGroupId: this.payrollGroupId,
      page: (this.pageConfig.current as number) - 1,
      size: 10,
    };

    if (params.paymentReceivedDate) {
      params.paymentReceivedDate = this.dateTrans(params.paymentReceivedDate);
    }

    if (!params.sort) {
      params.sort = 'rcv_ref_no,desc';
    }

    return params;
  }

  getData() {
    this.isLoading = true;
    this.service
      .getAccountBalance(this.getRequestParams())
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe(res => {
        if (res.result === 0 && res.data) {
          this.data = res.data;
          this.numberOfElements = res.data.records?.totalElements || 0;
          this.dataSource = res.data.records?.content || [];

          this.pageConfig = {
            ...this.pageConfig,
            totalCount: res.data.records?.totalElements,
          };
        } else {
          this.toastError(res.message);
        }
      });
  }

  toastError(errorMessage: string) {
    this.toastAlert.show('error', 'common.error', errorMessage, 5000);
  }

  dateTrans(date: string) {
    if (date) {
      return moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD');
    }

    return date;
  }

  pageInit() {
    return { current: 1, count: 0 };
  }

  searchFn() {
    this.validFn().then(r => {
      if (r) {
        this._searched = true;
        this.pageConfig = {
          ...this.pageConfig,
          current: 1,
        };
        this.getData();
      }
    });
  }

  async validFn() {
    return this._valid && this.form.valid;
  }

  reset() {
    if (!this._valid || !this._searched) {
      return;
    }
    this.searchDTO = {
      size: 10,
      page: 0,
    };
    this._searched = false;
    this.validating();
    this.getData();
  }

  reloadTable(event: any) {
    this.pageConfig = {
      ...this.pageConfig,
      current: event.current,
    };

    if (event.column) {
      this.searchDTO.sort = `${event.column.key === 'receiptReferenceNo' ? 'rcv_ref_no' : event.column.key},${event.column.sort}`;
    } else {
      delete this.searchDTO.sort;
    }

    this.getData();
  }

  totalCount(data: AccountBalance) {
    return Number(data.forfeitureAccount || 0) + Number(data.reservedAccount || 0) + Number(data.suspenseRecord || 0);
  }

  paymentMethodRender(type: PaymentMethodE) {
    if (!type) return '';
    return findOptionLabelByValue(type, paymentMethodOptions);
  }

  toDetail(paymentSubmissionReferenceNo: string, receiptReferenceNo?: string) {
    this.router.navigate(['/employer/payment-record-detail'], {
      queryParams: {
        ...this.route.snapshot.queryParams,
        paymentSubmissionReferenceNo,
        receiptReferenceNo,
        payrollGroupId: this.payrollGroupId,
      },
    });
  }

  get checkIsHaveScroll() {
    if (this.tableBox) {
      const _table = this.tableBox.nativeElement.querySelector('.table-box');
      if (_table) {
        return _table.scrollWidth > _table.clientWidth;
      }
    }
    return false;
  }
}
