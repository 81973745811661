/* eslint-disable @typescript-eslint/no-explicit-any */
import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CdsChartColorMode, CdsDonutPieData, CdsChartColor } from '@cds/ng-charts/core';
import { CdsDonutChartType } from '@cds/ng-charts/donut';
import { CdsSize, CdsColorTheme } from '@cds/ng-core/configuration';
import { WidgetComponent } from '../widget.component';
import { FormControl } from '@angular/forms';
import { CdsDropdownConfig } from '@cds/ng-core/dropdown';

@Component({
  selector: 'app-widgets-production-credit-by-district',
  templateUrl: './widgets-production-credit-by-district.component.html',
  styleUrls: ['./widgets-production-credit-by-district.component.scss'],
})
export class WidgetsProductionCreditByDistrictComponent implements OnInit, AfterViewInit, OnDestroy, WidgetComponent {
  @Input() data: any;
  @ViewChild('productionCredit', { static: true }) productionCredit!: ElementRef;
  widthLess698 = false;
  districtCode = new FormControl();
  districtCodeConfig: CdsDropdownConfig = {
    options: [
      { label: '001', value: 1 },
      { label: '002', value: 2 },
      { label: '003', value: 3 },
    ],
  };

  constructor() {}

  ngOnInit(): void {}

  type: CdsDonutChartType = 'donut-a';
  size: CdsSize = 'sm';
  keylineWidth = 0;
  color: CdsColorTheme = 'ml';
  colorMode: CdsChartColorMode = 'categorical';
  firstYearPCData: CdsDonutPieData[] = [
    {
      label: 'a',
      value: 67,
    },
    {
      label: 'b',
      value: 33,
    },
  ];
  renewalPCData: CdsDonutPieData[] = [
    {
      label: 'a',
      value: 93,
    },
    {
      label: 'b',
      value: 7,
    },
  ];
  chartColors: CdsChartColor[] = [
    {
      r: 0,
      g: 167,
      b: 88,
      a: 1,
    },
    {
      r: 221,
      g: 222,
      b: 228,
      a: 1,
    },
  ];

  ngAfterViewInit(): void {
    this.resizeListener();
    window.addEventListener('resize', this.resizeListener);
  }

  private resizeListener = () => {
    this.widthLess698 = this.productionCredit.nativeElement.offsetWidth <= 698;
  };

  ngOnDestroy(): void {
    window.removeEventListener('resize', this.resizeListener);
  }
}
