import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { Observable } from 'rxjs';
import { filterParams } from 'src/app/core/models/request';
import { PageResponse, BasicResponse } from 'src/app/core/models/response/response';
import { environment } from 'src/environments/environment';
import { InterestRateSearch, InterestRate, InterestRateFundDTO, FundCodeMapping, InterestRateHistory } from './employer';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class InterestRateService {
  private customerServiceUrl = `${environment.apiPrefix}/ext/eb-ssms/customer-service/`;
  private interestRateUrl = this.customerServiceUrl + 'interest-rate';
  private interestRateFindUrl = this.customerServiceUrl + 'interest-rate/find';
  private interestRateFundListUrl = this.customerServiceUrl + 'interest-rate/fundCodeMapping';
  private interestRateApproveUrl = this.customerServiceUrl + 'interest-rate/approve';
  private interestRateHistoryUrl = this.customerServiceUrl + 'interest-rate/history';

  constructor(@Inject(LOCALE_ID) public locale: string, private http: HttpClient) {}

  interestRatePage(page: InterestRateSearch): Observable<PageResponse<InterestRate>> {
    return this.http.get<PageResponse<InterestRate>>(this.interestRateUrl, {
      params: filterParams(page),
    });
  }

  interestRateSave(param: InterestRate): Observable<BasicResponse<boolean>> {
    return this.http.post<BasicResponse<boolean>>(this.interestRateUrl, param);
  }

  interestRateFind(request: InterestRateFundDTO): Observable<BasicResponse<InterestRate>> {
    return this.http.get<BasicResponse<InterestRate>>(this.interestRateFindUrl, {
      params: filterParams(request),
    });
  }

  listAllFund(): Observable<BasicResponse<FundCodeMapping[]>> {
    return this.http.get<BasicResponse<FundCodeMapping[]>>(this.interestRateFundListUrl);
  }
  interestRateApprove(param: InterestRate): Observable<BasicResponse<boolean>> {
    return this.http.post<BasicResponse<boolean>>(this.interestRateApproveUrl, param);
  }

  getHistoryList(request: InterestRateHistory): Observable<BasicResponse<InterestRateHistory[]>> {
    return this.http.get<BasicResponse<InterestRateHistory[]>>(this.interestRateHistoryUrl, {
      params: filterParams(request),
    });
  }
}
