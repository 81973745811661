/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Inject,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { CDS_CONFIG_SERVICE, CdsConfigService } from '@cds/ng-core/configuration';
import { CdsBaseComponent } from '@cds/ng-core/base';
import { CdsLogoIcons } from '@cds/ng-themes';
import { CdsFooterConfig, CdsFooterLegal, CdsFooterMenu } from '@cds/ng-web-components/navigation';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { CdsNavigationService } from '@cds/ng-web-components/navigation';

@Component({
  selector: 'app-cds-navigation-footer-local',
  templateUrl: './cds-navigation-footer.component.html',
  styleUrls: ['./cds-navigation-footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class CdsNavigationFooterLocalComponent extends CdsBaseComponent<CdsFooterConfig> implements OnInit, OnDestroy {
  @HostBinding('class') class = 'cds-navigation-footer';
  menu: CdsFooterMenu = { hidden: false, items: [] };
  connect = {
    hidden: false,
    items: ['Privacy', 'Legal', 'Terms and Conditions'],
    socialMedia: {
      facebook: true,
      youtube: true,
      linkedin: true,
      weChat: true,
    },
  };
  legal: CdsFooterLegal = {
    hidden: false,
    country: '',
    logo: '',
    htmlContent: ``,
  };
  isMdView$: Observable<boolean> = this.navService.isMdView$;
  selectedIndex = -1;
  selectedIndex$ = new BehaviorSubject(-1);
  showMenuItems = (index: number): Observable<boolean> => {
    return combineLatest([this.selectedIndex$, this.isMdView$]).pipe(
      takeUntil(this.onDestroy$),
      map(([selectedIndex, isMdView]) => {
        return isMdView ? selectedIndex === index : true;
      })
    );
  };

  @Output() menuClick = new EventEmitter();
  @Output() connectClick = new EventEmitter();
  @Output() socialMediaClick = new EventEmitter();

  constructor(
    @Optional()
    @Inject(CDS_CONFIG_SERVICE)
    configService: CdsConfigService<CdsFooterConfig>,
    cdr: ChangeDetectorRef,
    private navService: CdsNavigationService,
    private domSanitizer: DomSanitizer
  ) {
    super(configService, cdr);
  }

  setSelectedIndex(index: number) {
    let temp = index;
    if (index === this.selectedIndex) {
      temp = -1;
    }

    this.selectedIndex = temp;
    this.selectedIndex$.next(temp);
  }

  override ngOnInit(): void {
    super.ngOnInit();
  }

  override ngOnDestroy(): void {
    this.selectedIndex$.complete();
    super.ngOnDestroy();
  }

  getLegalLogo(): string {
    return this.legal ? this.legal.logo : CdsLogoIcons.Manulife_rgb_w;
  }

  sanitize(str: string) {
    return this.domSanitizer.bypassSecurityTrustHtml(str);
  }

  setProperties(config: any) {
    this.hidden = !!config.hidden;
    this.menu = config.menu;
    //this.connect = config.connect;
    this.legal = config.legal;
  }
}
