import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, map, Observable, Subject } from 'rxjs';
import { EmployeeResponse, EmployeeResponseData } from './employee-response.model';
import { EmployeeRequest } from './employee-request.model';
import { environment } from 'src/environments/environment';
import { CheckAgentCodeRequest } from '../employer-summary/check-agent-code-request';
import { CheckAgentCodeResponse } from '../employer-summary/check-agent-code-response';
import { EmployeeSaveModel } from './employee-save-model';
import { EmployeeCheckRequest } from './employee-check-request';
import { EmployeeSaveResponse } from './employee-save-response';
import { agentCodeAddPrefix0, agentCodeRemovePrefix0 } from 'src/app/utils/utils';
@Injectable({
  providedIn: 'root',
})
export class EmployeeDataService {
  sharData: BehaviorSubject<EmployeeResponse> = new BehaviorSubject(new EmployeeResponse());
  datas = new Observable<EmployeeResponse>();
  chekUrl = `${environment.apiPrefix}/ext/eb-ssms/customer-service/add-delegation/validate`;
  saveUrl = `${environment.apiPrefix}/ext/eb-ssms/customer-service/add-delegation/members/submit`;
  validateBatchUrl = `${environment.apiPrefix}/ext/eb-ssms/customer-service/add-delegation/validateBatch`;
  queryUrl = `${environment.apiPrefix}/ext/eb-ssms/customer-service/delegation/members/query`;
  request: EmployeeRequest = {};
  ckData = new Observable<CheckAgentCodeResponse>();
  employerId = '';
  companyName = '';

  constructor(private http: HttpClient) {}
  paramsSubject = new Subject<EmployeeRequest>();
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  langData(lang: string) {
    this.request.language = lang;
  }
  getSubject() {
    return this.sharData;
  }
  setEditReqest(value: EmployeeRequest) {
    this.request = value;
  }

  searchData(employeename: string, trusteeEmplrCd: string, age: string, gender: string, delegateAgentInfo: string) {
    this.request.employeeName = employeename;
    this.request.trusteeMbrId = trusteeEmplrCd;
    this.request.gender = gender;
    if (age != '') {
      const a = age.split('-');
      this.request.ageStart = Number(a[0]);
      this.request.ageEnd = Number(a[1]);
    } else {
      this.request.ageStart = undefined;
      this.request.ageEnd = undefined;
    }
    this.request.delegateAgentCode = delegateAgentInfo;
    this.request.page = 1;
    this.request.size = 10;
    // this.request.delegated='N'
    this.datas = this.http.post(this.queryUrl, this.request, this.httpOptions);
    this.datas.subscribe(resp => {
      if (resp.result === 0) {
        resp.data?.content?.forEach(c => {
          if (c.delegateAgentInfo?.agentCode) {
            c.delegateAgentInfo.agentCode = agentCodeAddPrefix0(c.delegateAgentInfo.agentCode);
          }
        });
      }

      this.sendData(resp);
    });
  }

  getSortData(sorts: string, code: string) {
    if (sorts != null && sorts != '') {
      this.request.sort = code + ' ' + sorts;
    } else {
      this.request.sort = '';
    }
    this.getData(this.request);
  }

  getPageData(pages: number, employeeId: string) {
    this.request.page = pages;
    this.request.size = 10;
    this.request.employerId = employeeId;
    // this.request.delegated='N'
    this.getData(this.request);
  }

  getData(any: EmployeeRequest) {
    this.datas = this.http.post(this.queryUrl, any, this.httpOptions);
    this.datas.subscribe(resp => {
      if (resp.result === 0) {
        resp.data?.content?.forEach(c => {
          if (c.delegateAgentInfo?.agentCode) {
            c.delegateAgentInfo.agentCode = agentCodeAddPrefix0(c.delegateAgentInfo.agentCode);
          }
        });
      }

      this.sendData(resp);
    });
  }

  getDataSource(emplyeeId: string): Observable<EmployeeResponse> {
    this.request.page = 1;
    this.request.size = 10;
    this.request.employerId = emplyeeId;
    return this.http.post(this.queryUrl, this.request, this.httpOptions).pipe(
      map((resp: EmployeeResponse) => {
        if (resp.result === 0) {
          resp.data?.content?.forEach(c => {
            if (c.delegateAgentInfo?.agentCode) {
              c.delegateAgentInfo.agentCode = agentCodeAddPrefix0(c.delegateAgentInfo.agentCode);
            }
          });
        }
        return resp;
      })
    );
  }

  sendData(value: EmployeeResponse) {
    this.sharData.next(value);
    // this.sharData.next(this.myTmpdata);
  }

  getDataList(): Observable<EmployeeResponse> {
    return this.sharData.asObservable();
  }

  getPageDataedit(pages: number, employee: string) {
    this.request.employerId = employee;
    this.request.page = pages;
    this.request.size = 10;
    this.request.isDelegated = 'N';
    this.request.isCoolingPeriod = 'N';
    this.getData(this.request);
  }

  checkData(val: CheckAgentCodeRequest): Observable<CheckAgentCodeResponse> {
    if (val.agentCode) {
      val.agentCode = agentCodeRemovePrefix0(val.agentCode);
    }
    this.ckData = this.http.post(this.chekUrl, val, this.httpOptions);
    return this.ckData;
  }

  erDelegationDataSub = new BehaviorSubject<EmployeeResponseData[]>([]);

  submitErDelegationData(erDelegationData: EmployeeResponseData[]) {
    this.erDelegationDataSub.next(erDelegationData);
  }

  getErDelegationData(): Observable<EmployeeResponseData[]> {
    return this.erDelegationDataSub.asObservable();
  }

  setEmployeeName(id: string, name: string) {
    this.employerId = id;
    this.companyName = name;
  }

  mycheckData: CheckAgentCodeResponse = {
    result: 0,
    message: 'sucess',
    data: {
      agentCode: '123',
      name: 'hsan',
    },
  };

  saveData(value: EmployeeSaveModel[]): Observable<EmployeeSaveResponse> {
    value.forEach(element => {
      if (element.agentCode) {
        element.agentCode = agentCodeRemovePrefix0(element.agentCode);
      }
    });

    return this.http.post(this.saveUrl, value, this.httpOptions);
  }

  checkAgentList(checkData: EmployeeCheckRequest[]): Observable<EmployeeSaveResponse> {
    checkData.forEach(element => {
      if (element.agentCode) {
        element.agentCode = agentCodeRemovePrefix0(element.agentCode);
      }
    });
    return this.http.post(this.validateBatchUrl, checkData, this.httpOptions);
  }
}

// export enum urlEnum {
//   chekUrl = 'https://eas-hk-dev-api.ap.manulife.com/ext/eb-ssms/customer-service/agent_info/validate',
//   queryUrl = 'https://eas-hk-dev-api.ap.manulife.com/ext/eb-ssms/customer-service/employee/query',
// }
