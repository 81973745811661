import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableExpandComponent } from './table-expand/table-expand.component';
import { TableExpandColComponent } from './table-expand-col/table-expand-col.component';
import { CdsLanguageModule } from '@cds/ng-core/lang';
import { CdsIconModule } from '@cds/ng-web-components/icon';
import { CdsProgressModule } from '@cds/ng-web-components/progress';

@NgModule({
  declarations: [TableExpandComponent, TableExpandColComponent],
  imports: [CommonModule, CdsLanguageModule, CdsIconModule, CdsProgressModule],
  exports: [TableExpandComponent, TableExpandColComponent],
})
export class ExpandTableModule {}
