export class AgentProfileResponse {
  result?: number;
  data?: AgentProfileResponseBody;
  message?: string;
}

export interface AgentProfileResponseBody {
  content: AgentProfileResponseData[];
  pageIndex: number;
  pageSize: number;
  pageable: Pageable;
  last: boolean;
  totalPages: number;
  totalElements: number;
  first: boolean;
  numberOfElements: number;
  size: number;
  number: number;
  empty: boolean;
}

export interface Pageable {
  pageNumber: number;
  pageSize: number;
  offset?: number;
  paged?: boolean;
  unpaged?: boolean;
}

export class AgentProfileResponseData {
  agentPersonCode?: string;
  agentCode?: string;
  englishName?: string;
  chineseName?: string;
  gender?: string;
  dob?: string;
  mobileTel?: string;
  officeTel?: string;
  email?: string;
  agentContractStatus?: string;
  joiningDate?: string;
  contractEffectiveDate?: string;
  contractTerminationDate?: string;
  rankCode?: string;
  rankDescShort?: string;
  rankDesc?: string;
  location?: string;
  branchCode?: string;
  branchName?: string;
  branchHeadAgentCode?: string;
  branchHeadAgentPersonCode?: string;
  branchHeadAgentName?: string;
  branchHeadAgentRankCode?: string;
  unitCode?: string;
  unitName?: string;
  directManagerCode?: string;
  directManagerName?: string;
  mpfLicenseStatus?: string;
  mpfLicenseNumber?: string;
  mpfLicenseEffectiveDate?: string;
  latestAgentRecord?: string;
}

export class AgentProfileDownloadResponse {
  result?: number;
  data?: AgentProfileDownloadResponseBody;
  message?: string;
}

export class AgentProfileDownloadResponseBody {
  count?: number;
  agentProfiles?: AgentProfileResponseBody[];
}
