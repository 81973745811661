import { Component, HostListener, OnInit } from '@angular/core';
import { CdsTextfieldConfig } from '@cds/ng-core/textfield';
import { Sort, SortDirection } from '@cds/ng-data-table/sort';
import { CdsToastService } from '@cds/ng-web-components/toast';
import { finalize } from 'rxjs';
import { IPaginationCount } from 'src/app/shared/pagination/pagination.dto';
import { ToastAlertService } from 'src/app/shared/toast-alert.service';
import { SortIconE, TerminationRecord } from '../termination';
import { TerminationService } from '../termination.service';

@Component({
  selector: 'app-termination-list',
  templateUrl: './termination-list.component.html',
  styleUrls: ['./termination-list.component.scss'],
})
export class TerminationListComponent implements OnInit {
  inProgress = false;

  inPutProgress = false;

  recordList: TerminationRecord[] = [];

  totalCount = 0;

  inputPageNum = 0;

  pageSize = 10;

  isMobile = false;

  sortIcon: SortIconE = SortIconE.SORT;

  txtfieldConfig: CdsTextfieldConfig = {
    type: 'number',
  };

  columList: string[] = [
    'Date of assignment',
    'Target Rollover Date',
    'Company Name',
    'Employee Name',
    'No. of Contact',
    'Ask Client',
    'Agent Follow Up Status',
  ];

  sort: Sort = {
    active: 'createdAt',
    direction: '',
  };

  pageCount: IPaginationCount = { current: 1, count: 1 };

  askClientTip = 'Ask client any other Manulife agent assigned before?';

  constructor(private service: TerminationService, private toast: CdsToastService, private toastAlert: ToastAlertService) {}

  ngOnInit(): void {
    this.onResize();
    this.getList();
  }

  getList() {
    this.inProgress = true;
    this.service
      .getPageList(this.pageCount.current, this.pageSize, this.sort)
      .pipe(
        finalize(() => {
          this.inProgress = false;
        })
      )
      .subscribe(data => {
        if (data.result === 0 && data.data) {
          this.inProgress = false;
          this.recordList = data.data.empty ? [] : this.service.recordListContentTrans(data.data.content);
          this.pageCount.current = data.data.number + 1;
          this.pageCount.count = data.data.totalPages;
          this.pageCount = { ...this.pageCount };
          this.totalCount = data.data.totalElements;
        } else {
          this.toastError(data.message);
        }
      });
  }

  getSortIcon(name: string) {
    if (name === this.sort.active) {
      switch (this.sort.direction) {
        case 'asc':
          return SortIconE.UP;
        case 'desc':
          return SortIconE.DOWN;
        case '':
          return SortIconE.SORT;
      }
    }
    return SortIconE.SORT;
  }

  sortChange(active: string) {
    if (active === this.sort.active) {
      let direction: SortDirection = '';
      switch (this.sort.direction) {
        case 'asc':
          direction = 'desc';
          break;
        case 'desc':
          direction = '';
          break;
        case '':
          direction = 'asc';
          break;
      }

      this.sort.direction = direction;
      this.sort = { ...this.sort };
    } else {
      this.sort = {
        active,
        direction: 'asc',
      };
    }

    this.getList();
  }

  statusChange(data: TerminationRecord) {
    this.inPutProgress = true;
    const params: TerminationRecord = {
      id: data.id,
      agentFollowUpStatus: data.agentFollowUpStatus,
      numberOfContact: data.numberOfContact,
      askClient: data.askClient,
    };
    this.service
      .putTerminatedMember(params)
      .pipe(
        finalize(() => {
          this.inPutProgress = false;
        })
      )
      .subscribe(data => {
        if (data.result === 0 && data.data) {
          this.getList();
        } else {
          this.toastError(data.message);
        }
      });
  }

  toastError(errorMessage: string) {
    this.toastAlert.show('error', 'common.error', errorMessage, 5000);
  }

  pageChange(currentPage: number) {
    this.pageCount.current = currentPage;
    this.getList();
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    if (window.innerWidth < 1024) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
  }
}
