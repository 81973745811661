import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { CdsLanguageModule } from '@cds/ng-core/lang';
import { CdsAssistiveTextModule } from '@cds/ng-web-components/assistive-text';
import { CdsProgressModule } from '@cds/ng-web-components/progress';

import { CdInputComponent } from './cd-input.component';
@NgModule({
  declarations: [CdInputComponent],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, CdsLanguageModule, CdsAssistiveTextModule, CdsProgressModule],
  exports: [CdInputComponent],
})
export class CdInputModule {}
