<div class="er-send-iff-in-batch-summary">
  <div [style.display]="currentStep === 1 ? 'block' : 'none'">
    <div class="send-iff-head">
      <app-cd-back-text [title]="'Sales Journey Summary Page'" (backCall)="goCaseSummary()"></app-cd-back-text>
      <div class="title-box">
        <span class="cds-h4-demibold">Send IFF by eMPF record</span>
      </div>
    </div>
    <div class="send-iff-content">
      <div>
        <span class="cds-h2-light">Send IFF by SSMS in Batch Summary</span>
      </div>
      <div *ngIf="dataList.length > 1" class="l-mt-2">
        <span class="cds-body1">Total {{ dataList.length }} records</span>
      </div>
      <div *ngIf="dataList.length > 1" class="alert-warning">
        <div class="icon-info-box">
          <cds-icon icon="action:info_1"></cds-icon>
        </div>
        <div class="alert-warning-text">
          <span class="cds-body2">Only non-split cases can be handled in batch</span>
        </div>
      </div>
      <div class="send-iff-table">
        <div class="table-header">
          <div class="table-header-cloumn" style="min-width: 264px; padding-left: var(--cds-spacing-07)">
            <div>
              <span class="cds-body1-demibold">{{ 'sendIffInBatchSummary.eMPFRef' | lang }}</span>
            </div>
            <div class="info-1-box">
              <cds-icon class="cursor-pointer" (click)="onDisplayAdviseRef()" icon="action:info_1"></cds-icon>
            </div>
          </div>
          <div class="table-header-cloumn" style="min-width: 140px">
            <div>
              <span class="cds-body1-demibold">{{ 'sendIffInBatchSummary.employerName' | lang }}</span>
            </div>
          </div>
          <div class="table-header-cloumn" style="min-width: 160px">
            <div>
              <span class="cds-body1-demibold">{{ 'sendIffInBatchSummary.eRNumber' | lang }}</span>
            </div>
          </div>
          <div class="table-header-cloumn" style="min-width: 300px">
            <div>
              <span class="cds-body1-demibold">{{ 'sendIffInBatchSummary.authorizedPersonName' | lang }}</span>
            </div>
          </div>
          <div class="table-header-cloumn" style="min-width: 190px">
            <div>
              <span class="cds-body1-demibold">{{ 'sendIffInBatchSummary.deliveryChannel' | lang }}</span>
            </div>
          </div>
          <div class="table-header-cloumn" style="min-width: 190px" [ngStyle]="{ flex: isChannelDBS ? 'unset' : '1' }">
            <div>
              <span class="cds-body1-demibold">{{ 'sendIffInBatchSummary.preferredLanguage' | lang }}</span>
            </div>
          </div>
          <div *ngIf="isChannelDBS" class="table-header-cloumn" style="min-width: 190px; flex: 1">
            <div>
              <span class="cds-body1-demibold">For DBS</span>
            </div>
          </div>
        </div>
        <div *ngFor="let item of dataList" style="position: relative">
          <div class="table-d">
            <div class="table-d-cloumn" style="min-width: 264px; padding-left: var(--cds-spacing-07)">
              <div>
                <span class="cds-body1-demibold">{{ item.eMPFRef }}</span>
              </div>
            </div>
            <div class="table-d-cloumn" style="min-width: 140px">
              <div style="width: 140px">
                <span class="cds-body1" [title]="getEmployerName(item)">{{ getEmployerName(item) }}</span>
              </div>
            </div>
            <div class="table-d-cloumn" style="min-width: 160px">
              <div>
                <span class="cds-body1">{{ item.BRNumber }}</span>
              </div>
            </div>
            <div class="table-d-cloumn" style="min-width: 300px">
              <div style="min-width: 250px">
                <cds-dropdown
                  (ngModelChange)="authorizedPersonNameControlChange(item)"
                  *ngIf="item.authorizedPersonNameConfigChi && item.authorizedPersonNameControl && cdsLangService.currentLang === 'zh'"
                  [config]="item.authorizedPersonNameConfigChi"
                  [formControl]="item.authorizedPersonNameControl"></cds-dropdown>
                <cds-dropdown
                  (ngModelChange)="authorizedPersonNameControlChange(item)"
                  *ngIf="item.authorizedPersonNameConfigEng && item.authorizedPersonNameControl && cdsLangService.currentLang === 'en'"
                  [config]="item.authorizedPersonNameConfigEng"
                  [formControl]="item.authorizedPersonNameControl"></cds-dropdown>
              </div>
            </div>
            <div class="table-d-cloumn" style="min-width: 190px">
              <div class="cd-radio-box">
                <div>
                  <app-cd-radio
                    [style.display]="item.authorizedPersonNameControl?.value ? 'none' : 'block'"
                    #deliveryChannelCdRadio
                    [config]="deliveryChannelConfig!"
                    [orientation]="'vertical'"></app-cd-radio>
                  <div class="cd-radio-icon-box">
                    <div class="icon-email">
                      <cds-icon class="l-ml-3" icon="contact:email"></cds-icon>
                    </div>
                    <div class="icon-mobile">
                      <cds-icon class="l-ml-3" icon="device:mobile"></cds-icon>
                    </div>
                  </div>
                </div>
                <div *ngFor="let contact of item.contactPersonContacts">
                  <app-cd-radio
                    #deliveryChannelCdRadio
                    [style.display]="item.authorizedPersonNameControl?.value === contact.contactPersonId ? 'block' : 'none'"
                    [(value)]="item.deliveryChannel"
                    (valueChange)="itemDeliveryChannelChange(item)"
                    [config]="contact.deliveryChannelConfig!"
                    [orientation]="'vertical'"></app-cd-radio>
                  <div class="cd-radio-icon-box">
                    <div class="icon-email">
                      <cds-icon class="l-ml-3" icon="contact:email"></cds-icon>
                    </div>
                    <div class="icon-mobile">
                      <cds-icon class="l-ml-3" icon="device:mobile"></cds-icon>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="table-d-cloumn" style="min-width: 190px" [ngStyle]="{ flex: isChannelDBS ? 'unset' : '1' }">
              <div *ngIf="item.preferredLanguageConfig" class="cd-radio-box">
                <app-cd-radio
                  #itemPreferredLanguage
                  [(value)]="item.preferredLanguage"
                  [config]="item.preferredLanguageConfig"
                  [orientation]="'vertical'"></app-cd-radio>
              </div>
            </div>
            <div *ngIf="isChannelDBS" class="table-d-cloumn" style="min-width: 190px; flex: 1">
              <div>
                <div *ngIf="item.rmReferralCode !== undefined">
                  <div>
                    <span class="cds-body2">RM Referral Code</span>
                  </div>
                  <app-cd-input
                    [type]="'number'"
                    [formControl]="item.rmReferralCode"
                    [maxlength]="8"
                    ngDefaultControl
                    [placeholder]="'Input RM Referral Code'"></app-cd-input>
                  <div class="l-mt-1">
                    <cds-assistive-text *ngIf="item.rmReferralCode.dirty && item.rmReferralCode.hasError('required'); else second" type="error"
                      >Please input valid RM Referral Code
                    </cds-assistive-text>
                    <ng-template #second>
                      <cds-assistive-text *ngIf="item.rmReferralCode.dirty && item.rmReferralCode.hasError('lengthError')" type="error"
                        >Please input valid RM Referral Code
                      </cds-assistive-text>
                    </ng-template>
                  </div>
                </div>
                <div *ngIf="item.inputCustomerId !== undefined" class="l-mt-2">
                  <div>
                    <span>Customer ID (Optional)</span>
                  </div>
                  <app-cd-input
                    [formControl]="item.inputCustomerId"
                    [maxlength]="8"
                    [regExpLimit]="'^[A-Za-z0-9-]*$'"
                    ngDefaultControl
                    [placeholder]="'Input Customer ID'"></app-cd-input>
                  <div class="l-mt-1">
                    <cds-assistive-text *ngIf="item.inputCustomerId.dirty && item.inputCustomerId.hasError('lengthError')" type="error"
                      >Please input valid Customer ID
                    </cds-assistive-text>
                  </div>
                </div>
                <div *ngIf="item.campaignCode !== undefined" class="l-mt-2">
                  <div>
                    <span>Campaign Code (Optional)</span>
                  </div>
                  <app-cd-input
                    [formControl]="item.campaignCode"
                    [maxlength]="10"
                    [regExpLimit]="'^[A-Za-z0-9-]*$'"
                    ngDefaultControl
                    [placeholder]="'Input Campaign Code'"></app-cd-input>
                  <div class="l-mt-1">
                    <cds-assistive-text *ngIf="item.campaignCode.dirty && item.campaignCode.hasError('lengthError')" type="error"
                      >Please input valid Campaign Code
                    </cds-assistive-text>
                  </div>
                </div>
                <div *ngIf="item.sourceCode !== undefined" class="l-mt-2">
                  <div>
                    <span>Source Code (Optional)</span>
                  </div>
                  <app-cd-input
                    [formControl]="item.sourceCode"
                    [maxlength]="10"
                    [regExpLimit]="'^[A-Za-z0-9-]*$'"
                    ngDefaultControl
                    [placeholder]="'Input Source Code'"></app-cd-input>
                  <div class="l-mt-1">
                    <cds-assistive-text *ngIf="item.sourceCode.dirty && item.sourceCode.hasError('lengthError')" type="error"
                      >Please input valid Source Code
                    </cds-assistive-text>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="intragroup-transfer-checkbox-box">
            <app-cd-intragroup-transfer-checkbox [formControl]="item.isIntragroupTransfer" ngDefaultControl></app-cd-intragroup-transfer-checkbox>
          </div>
        </div>
      </div>
      <div class="l-mt-5">
        <app-regulated-activities #regulatedActivities [formControl]="frControl" ngDefaultControl></app-regulated-activities>
      </div>
      <div *ngIf="dataList.length === 1" class="split-agent-box">
        <div>
          <span class="cds-h3-light">Split Agent</span>
        </div>
      </div>
      <div *ngIf="dataList.length === 1" class="agent-code-box-mt">
        <div>
          <span class="cds-body2">2nd Split Agent Code (optional):</span>
        </div>
        <div class="input-split-agent-code-box">
          <div *ngIf="dataList[0].secondAgentCode" class="l-d-f l-ai-cen">
            <div>
              <span class="cds-detail1-demibold" style="color: var(--cds-text-disabled)">{{ 'iff.secondAgentCode' | lang }} :</span>
            </div>
            <div class="l-ml-2">
              <span class="cds-h4-demibold">{{ secondAgentCodeAndErr.agentCode }}</span>
            </div>
          </div>
          <app-cd-input
            *ngIf="!dataList[0].secondAgentCode"
            [type]="'number'"
            maxlength="6"
            [disable]="isDisabled"
            [(value)]="secondAgentCodeAndErr.agentCode"
            (valueChange)="secondAgentCodeChange($event)"
            [placeholder]="'Input 2nd Split Agent Code (optional)'"></app-cd-input>
          <div class="l-mt-3">
            <cds-assistive-text *ngIf="secondAgentCodeAndErr.agentCodeErr">{{ 'sendIffInBatchSummary.invalidAgentCode' | lang }}</cds-assistive-text>
          </div>
        </div>
      </div>
      <div *ngIf="dataList.length === 1">
        <app-regulated-activities
          style="background-color: var(--cds-bg-section)"
          #regulatedActivities
          [disable]="isDisableSecondRegulatedControl1"
          [formControl]="secondRegulatedControl1"
          ngDefaultControl></app-regulated-activities>
      </div>
      <div *ngIf="dataList.length === 1" class="agent-code-box-mt">
        <div>
          <span class="cds-body2">3rd Split Agent Code (optional):</span>
        </div>
        <div class="input-split-agent-code-box">
          <div *ngIf="dataList[0].thirdAgentCode" class="l-d-f l-ai-cen">
            <div>
              <span class="cds-detail1-demibold" style="color: var(--cds-text-disabled)">{{ 'iff.secondAgentCode' | lang }} :</span>
            </div>
            <div class="l-ml-2">
              <span class="cds-h4-demibold">{{ thirdAgentCodeAndErr.agentCode }}</span>
            </div>
          </div>
          <app-cd-input
            *ngIf="!dataList[0].thirdAgentCode"
            [type]="'number'"
            maxlength="6"
            [disable]="this.secondAgentCodeAndErr.agentCode.length === 0"
            [(value)]="thirdAgentCodeAndErr.agentCode"
            (valueChange)="thirdAgentCodeChange($event)"
            [placeholder]="'Input 3rd Split Agent Code (optional)'"></app-cd-input>
          <div class="l-mt-3">
            <cds-assistive-text *ngIf="thirdAgentCodeAndErr.agentCodeErr">{{ 'sendIffInBatchSummary.invalidAgentCode' | lang }}</cds-assistive-text>
          </div>
        </div>
      </div>
      <div *ngIf="dataList.length === 1">
        <app-regulated-activities
          style="background-color: var(--cds-bg-section)"
          #regulatedActivities
          [disable]="isDisableSecondRegulatedControl2"
          [formControl]="secondRegulatedControl2"
          ngDefaultControl></app-regulated-activities>
      </div>
      <div *ngIf="dataList.length === 1" class="l-mt-6">
        <cds-assistive-text type="success">{{ 'sendIffInBatchSummary.maximum3agents' | lang }}</cds-assistive-text>
      </div>
      <div class="l-d-f l-mt-7">
        <div>
          <cds-button (click)="goBackWithData()" [style]="'secondary'">{{ 'common.back' | lang }}</cds-button>
        </div>
        <div class="l-ml-4">
          <cds-button *ngIf="dataList.length === 1" [disabled]="isDisableContinue || dbsInputHasErrors" (click)="stepOneContinue()">{{
            'common.continue' | lang
          }}</cds-button>
          <cds-button *ngIf="dataList.length > 1" [disabled]="isDisableContinue || dbsInputHasErrors" (click)="multipleContinue()">{{
            'common.continue' | lang
          }}</cds-button>
        </div>
      </div>
    </div>
  </div>

  <div class="current-step-2" [style.display]="currentStep === 2 ? 'block' : 'none'">
    <div class="title-box">
      <span class="cds-h4-demibold">Send IFF by eMPF record</span>
    </div>
    <div class="current-step-2-content">
      <ng-container *ngIf="secondAgent">
        <div>
          <span class="cds-h2-light">Contact Info of 2nd split agent</span>
        </div>
        <div class="info-box" [ngClass]="[!currentStep2FrControl1.value ? 'padding-bottom32' : '']">
          <div class="info-item">
            <div>
              <span class="cds-body2-demibold">{{ 'sendIffInBatchSummary.noNPFCode' | lang : { p1: 'sendIffInBatchSummary.2nd' | lang } }} :</span>
            </div>
            <div class="item-c-2">
              <span class="cds-body2-demibold">{{ secondAgent.agentCode }}</span>
            </div>
          </div>
          <div class="info-item">
            <div>
              <span class="cds-body2-demibold">{{ 'sendIffInBatchSummary.noMPFName' | lang : { p1: 'sendIffInBatchSummary.2nd' | lang } }} :</span>
            </div>
            <div class="item-c-2">
              <span class="cds-body2-demibold">{{ getAgentName(secondAgent) }}</span>
            </div>
          </div>
          <div class="l-d-f l-ai-cen">
            <app-regulated-activities #regulatedActivities [disable]="true" [formControl]="currentStep2FrControl1" ngDefaultControl></app-regulated-activities>
          </div>
          <ng-container *ngIf="currentStep2FrControl1.value">
            <div class="mna-box">
              <app-matters-needing-attention
                [title]="'sendIffInBatchSummary.importantNotes' | lang"
                [text]="'sendIffInBatchSummary.willBeSentVia' | lang : { p1: 'sendIffInBatchSummary.2nd' | lang }"></app-matters-needing-attention>
            </div>
            <div class="l-mt-7">
              <span class="cds-body2">{{ 'sendIffInBatchSummary.makeTheDeclaration' | lang : { p1: 'sendIffInBatchSummary.2nd' | lang } }}</span>
            </div>
            <div *ngIf="secondAgent && secondAgent.intermediaryContactMethodConfig" class="intermediary-contact-method-box">
              <app-cd-radio
                class="cd-radio-margin-bottom-clear"
                #secondAgentIntermediaryContactMethodCdRadio
                [(value)]="secondAgent.intermediaryContactMethodValue"
                (valueChange)="secondAgentIntermediaryContactMethodValueChange($event)"
                [config]="secondAgent.intermediaryContactMethodConfig"></app-cd-radio>
            </div>
            <div class="l-d-f">
              <div style="margin-top: 1.125rem">
                <span class="cds-body2-demibold">{{ 'sendIffInBatchSummary.preferredLanguage' | lang }}:</span>
              </div>
              <div class="l-ml-9">
                <app-cd-radio
                  class="cd-radio-margin-bottom-clear"
                  #secondAgentPreferredLanguage
                  [(value)]="secondAgentPreferredPreferred"
                  [config]="secondAgentPreferredLanguageConfig"></app-cd-radio>
              </div>
            </div>
          </ng-container>
        </div>
      </ng-container>
      <ng-container *ngIf="thirdAgent">
        <div class="l-mt-6">
          <span class="cds-h2-light">Contact Info of 3rd split agent</span>
        </div>
        <div class="info-box" [ngClass]="[!currentStep2FrControl2.value ? 'padding-bottom32' : '']">
          <div class="info-item">
            <div>
              <span class="cds-body2-demibold">{{ 'sendIffInBatchSummary.noNPFCode' | lang : { p1: 'sendIffInBatchSummary.3rd' | lang } }} :</span>
            </div>
            <div class="item-c-2">
              <span class="cds-body2-demibold">{{ thirdAgent.agentCode }}</span>
            </div>
          </div>
          <div class="info-item">
            <div>
              <span class="cds-body2-demibold">{{ 'sendIffInBatchSummary.noMPFName' | lang : { p1: 'sendIffInBatchSummary.3rd' | lang } }} :</span>
            </div>
            <div class="item-c-2">
              <span class="cds-body2-demibold">{{ getAgentName(thirdAgent) }}</span>
            </div>
          </div>
          <div class="l-d-f l-ai-cen">
            <app-regulated-activities #regulatedActivities [disable]="true" [formControl]="currentStep2FrControl2" ngDefaultControl></app-regulated-activities>
          </div>
          <ng-container *ngIf="currentStep2FrControl2.value">
            <div class="mna-box">
              <app-matters-needing-attention
                [title]="'sendIffInBatchSummary.importantNotes' | lang"
                [text]="'sendIffInBatchSummary.willBeSentVia' | lang : { p1: 'sendIffInBatchSummary.3rd' | lang }"></app-matters-needing-attention>
            </div>
            <div class="l-mt-7">
              <span class="cds-body2">{{ 'sendIffInBatchSummary.makeTheDeclaration' | lang : { p1: 'sendIffInBatchSummary.3rd' | lang } }}</span>
            </div>
            <div *ngIf="thirdAgent && thirdAgent.intermediaryContactMethodConfig" class="intermediary-contact-method-box">
              <app-cd-radio
                class="cd-radio-margin-bottom-clear"
                #thirdAgentIntermediaryContactMethodCdRadio
                [(value)]="thirdAgent.intermediaryContactMethodValue"
                (valueChange)="thirdAgentIntermediaryContactMethodValueChange($event)"
                [config]="thirdAgent.intermediaryContactMethodConfig"></app-cd-radio>
            </div>
            <div class="l-d-f">
              <div style="margin-top: 1.125rem">
                <span class="cds-body2-demibold">{{ 'sendIffInBatchSummary.preferredLanguage' | lang }}:</span>
              </div>
              <div class="l-ml-9">
                <app-cd-radio
                  class="cd-radio-margin-bottom-clear"
                  #thirdAgentPreferredLanguage
                  [(value)]="thirdAgentPreferredPreferred"
                  [config]="thirdAgentPreferredLanguageConfig"></app-cd-radio>
              </div>
            </div>
          </ng-container>
        </div>
      </ng-container>
      <div class="l-d-f l-mt-7">
        <div>
          <cds-button (click)="backOne()" [style]="'secondary'">{{ 'common.back' | lang }}</cds-button>
        </div>
        <div class="l-ml-4">
          <cds-button [disabled]="disableStepTwoContinue" (click)="stepTwoContinue()">{{ 'common.continue' | lang }}</cds-button>
        </div>
      </div>
    </div>
  </div>

  <div class="current-step-3" [style.display]="currentStep === 12 ? 'block' : 'none'">
    <div class="title-box">
      <span class="cds-h4-demibold">Send IFF by eMPF record</span>
    </div>
    <div class="current-step-3-content">
      <div>
        <span class="cds-h2-light">{{ 'sendIffInBatchSummary.miDeclarationConfirmation' | lang }}</span>
      </div>
      <div *ngIf="dataList.length > 1" class="l-mt-2">
        <span class="cds-body1">Total {{ dataList.length }} records</span>
      </div>
      <div class="l-mt-4">
        <app-matters-needing-attention [color]="'red'" [title]="'sendIffInBatchSummary.importantNotes' | lang">
          <ul class="l-mt-0 l-mb-0">
            <li>
              <span class="cds-detail1">{{ 'sendIffInBatchSummary.inOne' | lang }}</span>
            </li>
            <li>
              <span class="cds-detail1">{{ 'sendIffInBatchSummary.inTwo' | lang }}</span>
            </li>
            <li>
              <span class="cds-detail1 red-text">{{ 'sendIffInBatchSummary.inThree1' | lang }}</span>
              <span class="cds-detail1">{{ 'sendIffInBatchSummary.inThree2' | lang }}</span>
            </li>
          </ul>
        </app-matters-needing-attention>
      </div>
      <div class="send-iff-table">
        <div class="table-header">
          <div class="table-header-cloumn" style="min-width: 264px; padding-left: var(--cds-spacing-07)">
            <div>
              <span class="cds-body1-demibold">{{ 'sendIffInBatchSummary.eMPFRef' | lang }}</span>
            </div>
            <div class="info-1-box">
              <cds-icon class="cursor-pointer" (click)="onDisplayAdviseRef()" icon="action:info_1"></cds-icon>
            </div>
          </div>
          <div class="table-header-cloumn" style="min-width: 140px">
            <div>
              <span class="cds-body1-demibold">{{ 'sendIffInBatchSummary.employerName' | lang }}</span>
            </div>
          </div>
          <div class="table-header-cloumn" style="min-width: 160px">
            <div>
              <span class="cds-body1-demibold">{{ 'sendIffInBatchSummary.eRNumber' | lang }}</span>
            </div>
          </div>
          <div class="table-header-cloumn" style="min-width: 225px">
            <div>
              <span class="cds-body1-demibold">{{ 'sendIffInBatchSummary.authorizedPersonName' | lang }}</span>
            </div>
          </div>
          <div class="table-header-cloumn" style="min-width: 460px">
            <div>
              <span class="cds-body1-demibold">{{ 'sendIffInBatchSummary.authorizedPersonEmailAddress' | lang }}</span>
            </div>
          </div>
          <div class="table-header-cloumn" style="min-width: 460px" [ngStyle]="{ flex: isChannelDBS ? 'unset' : '1' }">
            <div>
              <span class="cds-body1-demibold">{{ 'sendIffInBatchSummary.preferredLanguage' | lang }}</span>
            </div>
          </div>
          <div *ngIf="isChannelDBS" class="table-header-cloumn" style="min-width: 190px; flex: 1">
            <div>
              <span class="cds-body1-demibold">For DBS</span>
            </div>
          </div>
        </div>
        <div *ngFor="let item of dataList" style="position: relative">
          <div class="table-d">
            <div class="table-d-cloumn current-step-12" style="min-width: 264px; padding-left: var(--cds-spacing-07)">
              <div>
                <span class="cds-body1-demibold">{{ item.eMPFRef }}</span>
              </div>
            </div>
            <div class="table-d-cloumn current-step-12" style="min-width: 140px">
              <div style="width: 140px">
                <span class="cds-body1" [title]="getEmployerName(item)">{{ getEmployerName(item) }}</span>
              </div>
            </div>
            <div class="table-d-cloumn current-step-12" style="min-width: 160px">
              <div>
                <span class="cds-body1">{{ item.BRNumber }}</span>
              </div>
            </div>
            <div class="table-d-cloumn current-step-12" style="min-width: 225px">
              <div style="min-width: 200px">
                {{ getAuthorizedPerson(item)?.contactPersonName }}
              </div>
            </div>
            <div class="table-d-cloumn current-step-12" style="min-width: 460px">
              <div *ngIf="item.deliveryChannel === DeliveryChannelType.Email">
                <div class="l-d-f l-ai-cen">
                  <div>
                    <span class="cds-body1">{{ 'common.email' | lang }}</span>
                  </div>
                  <div class="icon-email">
                    <cds-icon class="l-ml-3" icon="contact:email"></cds-icon>
                  </div>
                </div>
                <div class="l-mt-1">
                  <span class="cds-body1">{{ getAuthorizedPerson(item)?.emailAddr }}</span>
                </div>
              </div>
              <div *ngIf="item.deliveryChannel === DeliveryChannelType.SMS">
                <div class="l-d-f l-ai-cen">
                  <div>
                    <span class="cds-body1">{{ 'common.sms' | lang }}</span>
                  </div>
                  <div class="icon-email">
                    <cds-icon class="l-ml-3" icon="device:mobile"></cds-icon>
                  </div>
                </div>
                <div class="l-mt-1">
                  <span class="cds-body1">{{ getAuthorizedPerson(item)?.displayPhoneNumber }}</span>
                </div>
              </div>
            </div>
            <div class="table-d-cloumn current-step-12" style="min-width: 460px" [ngStyle]="{ flex: isChannelDBS ? 'unset' : '1' }">
              <div *ngIf="item.preferredLanguage === PreferredLanguageType.Chinese">
                <span class="cds-body1">{{ 'common.chinese' | lang }}</span>
              </div>
              <div *ngIf="item.preferredLanguage === PreferredLanguageType.English">
                <span class="cds-body1">{{ 'common.english' | lang }}</span>
              </div>
              <div *ngIf="!item.preferredLanguage">
                <span class="cds-body1">/</span>
              </div>
            </div>
            <div *ngIf="isChannelDBS" class="table-d-cloumn current-step-12" style="min-width: 190px; flex: 1">
              <div>
                <div *ngIf="item.rmReferralCode !== undefined">
                  <div>
                    <span class="cds-body2">RM Referral Code</span>
                  </div>
                  <div>
                    <span class="cds-body1">{{ item.rmReferralCode.value || '-' }}</span>
                  </div>
                </div>
                <div *ngIf="item.campaignCode !== undefined" class="l-mt-2">
                  <div>
                    <span class="cds-body2">Customer ID (Optional)</span>
                  </div>
                  <div>
                    <span class="cds-body1">{{ item.campaignCode.value || '-' }}</span>
                  </div>
                </div>
                <div *ngIf="item.sourceCode !== undefined" class="l-mt-2">
                  <div>
                    <span class="cds-body2">Campaign Code (Optional)</span>
                  </div>
                  <div>
                    <span class="cds-body1">{{ item.sourceCode.value || '-' }}</span>
                  </div>
                </div>
                <div *ngIf="item.inputCustomerId !== undefined" class="l-mt-2">
                  <div>
                    <span class="cds-body2">Source Code (Optional)</span>
                  </div>
                  <div>
                    <span class="cds-body1">{{ item.inputCustomerId.value || '-' }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="intragroup-transfer-checkbox-box current-step-12">
            <app-cd-intragroup-transfer-checkbox
              *ngIf="currentStep === 12"
              [formControl]="item.isIntragroupTransfer"
              ngDefaultControl></app-cd-intragroup-transfer-checkbox>
          </div>
        </div>
      </div>
    </div>
    <div class="l-d-f l-ai-cen l-ml-7">
      <app-regulated-activities #regulatedActivities [disable]="true" [formControl]="multipleFrControl" ngDefaultControl></app-regulated-activities>
    </div>
    <div class="l-mt-7 l-d-f l-ml-7">
      <div>
        <cds-button [style]="'secondary'" (click)="backStep1()">{{ 'common.back' | lang }}</cds-button>
      </div>
      <div class="l-ml-4 l-d-f l-fd-col">
        <cds-button (click)="submit()">{{ 'sendIffInBatchSummary.confirmAndSubmit' | lang }}</cds-button>
        <div class="l-mt-4">
          <cds-assistive-text type="error">{{ 'sendIffInBatchSummary.noChanges' | lang }}</cds-assistive-text>
        </div>
      </div>
    </div>
  </div>

  <div class="current-step-3" [style.display]="currentStep === 3 ? 'block' : 'none'">
    <div class="title-box">
      <span class="cds-h4-demibold">Send IFF by eMPF record</span>
    </div>
    <div class="current-step-3-content">
      <div>
        <span class="cds-h2-light">{{ 'sendIffInBatchSummary.miDeclarationConfirmation' | lang }}</span>
      </div>
      <div class="info-box">
        <div class="mna-box">
          <app-matters-needing-attention [color]="'red'" [title]="'sendIffInBatchSummary.importantNotes' | lang">
            <ul class="l-mt-0 l-mb-0">
              <li>
                <span class="cds-detail1">{{ 'sendIffInBatchSummary.inOne' | lang }}</span>
              </li>
              <li>
                <span class="cds-detail1">{{ 'sendIffInBatchSummary.inTwo' | lang }}</span>
              </li>
              <li>
                <span class="cds-detail1 red-text">{{ 'sendIffInBatchSummary.inThree1' | lang }}</span>
                <span class="cds-detail1">{{ 'sendIffInBatchSummary.inThree2' | lang }}</span>
              </li>
            </ul>
          </app-matters-needing-attention>
        </div>
        <div class="row-item l-mt-6">
          <div style="width: 330px">
            <span class="cds-body2-demibold">{{ 'sendIffInBatchSummary.eMPFReferenceNo' | lang }}:</span>
          </div>
          <div style="flex: 3" class="l-d-f l-fd-col">
            <div>
              <span class="cds-body2-demibold">{{ getSingleSelected.eMPFRef }}</span>
            </div>
            <div class="l-d-f" style="margin-top: 19px">
              <app-cd-intragroup-transfer-checkbox
                *ngIf="currentStep === 3"
                [formControl]="getSingleSelected.isIntragroupTransfer"
                ngDefaultControl></app-cd-intragroup-transfer-checkbox>
            </div>
            <div class="l-d-f" style="margin-top: 19px">
              <app-regulated-activities #regulatedActivities [disable]="true" [formControl]="primaryControl" ngDefaultControl></app-regulated-activities>
            </div>
          </div>
        </div>
        <div class="row-item l-mt-6">
          <div style="width: 330px">
            <span class="cds-body2-demibold">{{ 'sendIffInBatchSummary.employerName' | lang }}:</span>
          </div>
          <div style="flex: 3" class="l-d-f l-fd-col">
            <div>
              <span class="cds-body2-demibold">{{ getEmployerName(getSingleSelected) }}</span>
            </div>
          </div>
        </div>
        <div class="row-item l-mt-6">
          <div style="width: 330px">
            <span class="cds-body2-demibold">{{ 'sendIffInBatchSummary.eRNumber' | lang }}:</span>
          </div>
          <div style="flex: 3" class="l-d-f l-fd-col">
            <div>
              <span class="cds-body2-demibold">{{ getSingleSelected.BRNumber }}</span>
            </div>
          </div>
        </div>
        <div class="row-item l-mt-6">
          <div style="width: 330px">
            <span class="cds-body2-demibold">{{ 'sendIffInBatchSummary.authorizedPersonName' | lang : { p1: 'common.english' | lang } }}:</span>
          </div>
          <div style="flex: 3" class="l-d-f l-fd-col">
            <div>
              <span class="cds-body2-demibold">{{ getAuthorizedPerson(getSingleSelected)?.contactPersonName }}</span>
            </div>
          </div>
        </div>
        <div class="row-item l-mt-6">
          <div style="width: 330px">
            <span class="cds-body2-demibold">{{ 'sendIffInBatchSummary.apEmailPhone' | lang : { p1: 'common.english' | lang } }}:</span>
          </div>
          <div style="flex: 3" class="l-d-f l-fd-col">
            <div>
              <span class="cds-body2-demibold">{{
                getSingleSelected.deliveryChannel === 'Email'
                  ? getAuthorizedPerson(getSingleSelected)?.emailAddr
                  : getAuthorizedPerson(getSingleSelected)?.displayPhoneNumber
              }}</span>
            </div>
          </div>
        </div>
        <div *ngIf="getSingleSelected.preferredLanguage" class="row-item l-mt-6">
          <div style="width: 330px">
            <span class="cds-body2-demibold">{{ 'sendIffInBatchSummary.receivingLanguage' | lang }}:</span>
          </div>
          <div style="flex: 3" class="l-d-f l-fd-col">
            <div style="display: inline" *ngIf="getSingleSelected.preferredLanguage === PreferredLanguageType.Chinese">
              <span class="cds-body2-demibold">{{ 'common.chinese' | lang }}</span>
            </div>
            <div style="display: inline" *ngIf="getSingleSelected.preferredLanguage === PreferredLanguageType.English">
              <span class="cds-body2-demibold">{{ 'common.english' | lang }}</span>
            </div>
          </div>
        </div>
        <ng-container *ngIf="isChannelDBS">
          <div class="row-item l-mt-6">
            <div style="width: 330px">
              <div>
                <span class="cds-body2-demibold">RM Referral Code:</span>
              </div>
            </div>
            <div style="flex: 3" class="l-d-f l-fd-col">
              <div>
                <span class="cds-body2-demibold">{{ dataList[0].rmReferralCode?.value || '-' }}</span>
              </div>
            </div>
          </div>
          <div class="row-item l-mt-6">
            <div style="width: 330px">
              <div>
                <span class="cds-body2-demibold">Customer ID:</span>
              </div>
            </div>
            <div style="flex: 3" class="l-d-f l-fd-col">
              <div>
                <span class="cds-body2-demibold">{{ dataList[0].inputCustomerId?.value || '-' }}</span>
              </div>
            </div>
          </div>
          <div class="row-item l-mt-6">
            <div style="width: 330px">
              <div>
                <span class="cds-body2-demibold">Campaign Code:</span>
              </div>
            </div>
            <div style="flex: 3" class="l-d-f l-fd-col">
              <div>
                <span class="cds-body2-demibold">{{ dataList[0].campaignCode?.value || '-' }}</span>
              </div>
            </div>
          </div>
          <div class="row-item l-mt-6">
            <div style="width: 330px">
              <div>
                <span class="cds-body2-demibold">Source Code:</span>
              </div>
            </div>
            <div style="flex: 3" class="l-d-f l-fd-col">
              <div>
                <span class="cds-body2-demibold">{{ dataList[0].sourceCode?.value || '-' }}</span>
              </div>
            </div>
          </div>
        </ng-container>
        <div *ngIf="secondAgent" class="row-item l-mt-6">
          <div style="width: 330px">
            <span class="cds-body2-demibold">{{ 'sendIffInBatchSummary.2ndMPFIntermediary' | lang }}:</span>
          </div>
          <div style="flex: 3" class="l-d-f l-fd-col">
            <div>
              <span class="cds-body2-demibold">{{ secondAgent.agentCode }} {{ getAgentName(secondAgent) }}</span>
            </div>
            <div>
              <div class="l-mt-4" *ngIf="readonlySecondRegulatedControl1.value">
                <span class="cds-detail2-demibold"
                  >{{ 'sendIffInBatchSummary.contactMethod' | lang }}:
                  {{
                    (secondAgent.intermediaryContactMethodValue === DeliveryChannelType.Email
                      ? 'sendIffInBatchSummary.contactMethodEmail'
                      : 'sendIffInBatchSummary.contactMethodPhone'
                    ) | lang
                  }}
                  ({{
                    getIntermediaryContactMethod(secondAgent) || ''
                      | hideText : (secondAgent.intermediaryContactMethodValue === DeliveryChannelType.Email ? HideTextType.EMAIL : HideTextType.PHONE)
                  }})</span
                >
              </div>
              <div *ngIf="secondAgentPreferredPreferred" class="l-mt-3">
                <span class="cds-detail2-demibold">{{ 'common.language-of' | lang : { p1: 'sendIffInBatchSummary.2ndMPFIntermediary' | lang } }} : </span>
                <div style="display: inline" *ngIf="secondAgentPreferredPreferred === PreferredLanguageType.Chinese">
                  <span class="cds-detail2-demibold">{{ 'common.chinese' | lang }}</span>
                </div>
                <div style="display: inline" *ngIf="secondAgentPreferredPreferred === PreferredLanguageType.English">
                  <span class="cds-detail2-demibold">{{ 'common.english' | lang }}</span>
                </div>
              </div>
              <div class="l-d-f l-ai-cen l-mt-4">
                <app-regulated-activities
                  #regulatedActivities
                  [disable]="true"
                  [formControl]="readonlySecondRegulatedControl1"
                  ngDefaultControl></app-regulated-activities>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="thirdAgent" class="row-item l-mt-6">
          <div style="width: 330px">
            <span class="cds-body2-demibold">{{ 'sendIffInBatchSummary.3rdMPFIntermediary' | lang }}:</span>
          </div>
          <div style="flex: 3" class="l-d-f l-fd-col">
            <div>
              <span class="cds-body2-demibold">{{ thirdAgent.agentCode }} {{ getAgentName(thirdAgent) }}</span>
            </div>
            <div>
              <div class="l-mt-4" *ngIf="readonlySecondRegulatedControl2.value">
                <span class="cds-detail2-demibold"
                  >{{ 'sendIffInBatchSummary.contactMethod' | lang }}:
                  {{
                    (thirdAgent.intermediaryContactMethodValue === DeliveryChannelType.Email
                      ? 'sendIffInBatchSummary.contactMethodEmail'
                      : 'sendIffInBatchSummary.contactMethodPhone'
                    ) | lang
                  }}
                  ({{
                    getIntermediaryContactMethod(thirdAgent) || ''
                      | hideText : (thirdAgent.intermediaryContactMethodValue === DeliveryChannelType.Email ? HideTextType.EMAIL : HideTextType.PHONE)
                  }})</span
                >
              </div>
              <div *ngIf="thirdAgentPreferredPreferred" class="l-mt-3">
                <span class="cds-detail2-demibold">{{ 'common.language-of' | lang : { p1: 'sendIffInBatchSummary.3rdMPFIntermediary' | lang } }} : </span>
                <div style="display: inline" *ngIf="thirdAgentPreferredPreferred === PreferredLanguageType.Chinese">
                  <span class="cds-detail2-demibold">{{ 'common.chinese' | lang }}</span>
                </div>
                <div style="display: inline" *ngIf="thirdAgentPreferredPreferred === PreferredLanguageType.English">
                  <span class="cds-detail2-demibold">{{ 'common.english' | lang }}</span>
                </div>
              </div>
              <div class="l-d-f l-ai-cen l-mt-4">
                <app-regulated-activities
                  #regulatedActivities
                  [disable]="true"
                  [formControl]="readonlySecondRegulatedControl2"
                  ngDefaultControl></app-regulated-activities>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="l-mt-7 l-d-f">
        <div>
          <cds-button [style]="'secondary'" (click)="stepThreeBack()">{{ 'common.back.to' | lang }}</cds-button>
        </div>
        <div class="l-ml-4 l-d-f l-fd-col">
          <cds-button (click)="submit()">{{ 'sendIffInBatchSummary.confirmAndSubmit' | lang }}</cds-button>
          <div class="l-mt-4">
            <cds-assistive-text type="error">{{ 'sendIffInBatchSummary.noChanges' | lang }}</cds-assistive-text>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
