<div class="cds-footer-menu cds-dark-navy row" *ngIf="menu && !menu.hidden">
  <div class="col-xs-12 col-sm-12 col-md col-lg col-xl cds-footer-item" *ngFor="let menuItems of menu.items; let i = index">
    <div class="menu-item-header-container" (click)="setSelectedIndex(i)">
      <label class="cds-demibold cds-body1">{{ menuItems.header }}</label>
      <cds-icon icon="action:button_down" [class.opened]="selectedIndex === i" *ngIf="isMdView$ | async"></cds-icon>
    </div>
    <div class="divider"></div>
    <div class="cds-footer-list-item-container" *ngIf="showMenuItems(i) | async">
      <span class="cds-detail1 cds-footer-list-item" *ngFor="let item of menuItems.list" (click)="menuClick.emit(item)">{{ item | lang }}</span>
      <div class="divider"></div>
    </div>
  </div>
</div>

<div class="cds-footer-connect cds-dark-navy row" *ngIf="connect && !connect.hidden">
  <div class="col-sm-12 col-md-6">
    <span *ngFor="let item of connect.items" class="cds-detail1" (click)="connectClick.emit(item)">{{ item | lang }}</span>
  </div>
  <div class="col-sm-12 col-md-6 cds-footer-social-icons">
    <cds-icon icon="social_media:facebook" *ngIf="connect.socialMedia.facebook" (click)="socialMediaClick.emit('facebook')" [ignoreColor]="true"></cds-icon>
    <cds-icon icon="social_media:youtube" *ngIf="connect.socialMedia.youtube" (click)="socialMediaClick.emit('youtube')" [ignoreColor]="true"></cds-icon>
    <cds-icon icon="social_media:linkedin" *ngIf="connect.socialMedia.linkedin" (click)="socialMediaClick.emit('linkedin')" [ignoreColor]="true"></cds-icon>
    <cds-icon icon="social_media:wechat" *ngIf="connect.socialMedia.weChat" (click)="socialMediaClick.emit('weChat')" [ignoreColor]="true"></cds-icon>
  </div>
</div>

<div class="cds-footer-legal cds-dark-navy row" *ngIf="legal && !legal.hidden">
  <div class="col-sm-12 col-md-6">
    <cds-icon icon="logo:Manulife_rgb_w" color="#00A758"></cds-icon>
  </div>
  <div class="col-sm-12 col-md-6">
    <div [innerHTML]="sanitize(legal.htmlContent)" class="cds-detail1 legal-content"></div>
  </div>
</div>
