/* eslint-disable @typescript-eslint/no-explicit-any */
import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CdsOption } from '@cds/ng-core/configuration';
import { CdsLangService } from '@cds/ng-core/lang';
import { map, Observable, startWith } from 'rxjs';

@Component({
  selector: 'app-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss'],
})
export class AutocompleteComponent implements OnInit, AfterViewInit {
  @Input() group!: FormGroup;
  @Input() control: any;
  @Input()
  set nullable(value: boolean) {
    this._nullable = value;
  }
  get nullable() {
    return this._nullable;
  }

  @Input()
  set locked(value: boolean) {
    this._locked = value;
  }
  get locked() {
    return this._locked;
  }

  @Input()
  set disabled(value: boolean) {
    this._disabled = value;
  }
  get disabled() {
    return this._disabled;
  }

  @Input()
  set fieldErrMsg(value: any) {
    this._fieldErrMsg = value;
  }
  get fieldErrMsg() {
    return this._fieldErrMsg;
  }

  @Input()
  set controlName(value: string) {
    this._controlName = value;
  }
  get controlName() {
    return this._controlName;
  }

  @Input()
  set selectOptions(dataSource: Array<any> | Observable<any>) {
    this.GetControl().patchValue(this.GetControl().value);
    if (dataSource instanceof Observable) {
      dataSource.subscribe(result => {
        this._originalList = result;
        this.filterList = this.GetControl().valueChanges.pipe(
          startWith(''),
          map(name => this._filter(this._originalList, name))
        );
      });
    } else {
      this._originalList = dataSource;
      this.filterList = this.GetControl().valueChanges.pipe(
        startWith(''),
        map(name => this._filter(this._originalList, name))
      );
    }
  }

  @Output() valueChange = new EventEmitter();

  @ViewChild('inputBox') inputBox!: ElementRef<HTMLInputElement>;
  icon = 'action:button_down';
  filterList!: Observable<CdsOption[]>;

  /**label & placeholder */
  controlLabel = '';
  controlPlaceholder = '';

  constructor(private langService: CdsLangService) {}

  private _originalList: CdsOption[] = [];
  private _nullable = true;
  private _locked = false;
  private _disabled = false;
  private _fieldErrMsg = '';
  private _controlName = '';

  private GetControl() {
    return this.group.controls[this.controlName];
  }

  private _filter(optionSource: CdsOption[], value: string) {
    const filterObj = optionSource.find(x => x.value === value);
    if (filterObj) return [filterObj];
    return optionSource.filter(x => this.langService.translate(x.label).toLowerCase().includes(value?.toLowerCase()));
  }

  ngOnInit(): void {
    this.controlLabel = this.control.label;
    this.controlPlaceholder = this.control.placeholder;
  }

  ngAfterViewInit(): void {
    if (this.inputBox && this.inputBox.nativeElement) {
      this.inputBox.nativeElement.oninput = () => {
        this.valueChange.emit(this.inputBox.nativeElement.value);
      };
    }
  }

  displayFn(value: any) {
    const filterObj = this._originalList?.find(x => x.value === value);
    if (filterObj) return this.langService.translate(filterObj.label);
    return value;
  }

  optionSelected(event: any) {
    this.valueChange.emit(event.option.value);
  }

  iconClick() {
    if (this.icon === 'action:button_down') {
      this.icon = 'action:button_up';
      //trigger panel open event
      if (this.inputBox && this.inputBox.nativeElement) {
        this.inputBox.nativeElement.focus();
      }
    } else {
      this.icon = 'action:button_down';
    }
  }

  clearInput() {
    this.icon = 'action:button_down';
    this.GetControl().patchValue('');
    this.GetControl().markAsTouched();
    this.valueChange.emit('');
  }
}
