/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { CdsButtonConfig } from '@cds/ng-core/button';
import { CdsIconConfig } from '@cds/ng-core/icon';
import { CdsLangService } from '@cds/ng-core/lang';
import { CdsRadioConfig } from '@cds/ng-core/radio';
import { CdsPopupService } from '@cds/ng-web-components/popup';
import { finalize, Subscription } from 'rxjs';
import { AlertPopupComponent } from 'src/app/shared/alert-popup/alert-popup.component';
import { ContinuePopupComponent } from 'src/app/shared/continue-popup/continue-popup.component';
import { ToastAlertService } from 'src/app/shared/toast-alert.service';
import { agentCodeListValidator } from 'src/app/shared/validators/validators';
import {
  AgentReassignmentMemberCountRequet,
  AgentReassignmentMemberProcessRequet,
  Employer,
  MemberListTypeE,
  RelatedMemberRequest,
  RelatedMemberResponse,
} from '../shk';
import { ShkService } from '../shk.service';
import { CdMultidropdownTopping } from 'src/app/shared/cd-multidropdown/cd-multidropdown.model';

@Component({
  selector: 'app-agent-reassignment',
  templateUrl: './agent-reassignment.component.html',
  styleUrls: ['./agent-reassignment.component.scss'],
})
export class AgentReassignmentComponent implements OnInit {
  customerOption: CdMultidropdownTopping[] = [];
  memberOption: CdMultidropdownTopping[] = [];

  formGroup = new FormGroup({});

  iconConfig: CdsIconConfig = {
    color: '#00A758',
  };

  selectIconConfig: CdsIconConfig = {
    color: '#EC6453',
  };

  radioConfig: CdsRadioConfig = {
    options: [
      {
        label: 'Both Terminated and Enrolled Members',
        value: MemberListTypeE.BOTH,
      },
      {
        label: 'Only Terminated Members',
        value: MemberListTypeE.ONLY_TERMINATED,
      },
      {
        label: 'Only Enrolled Members',
        value: MemberListTypeE.ONLY_ENROLLED,
      },
    ],
  };

  get resetDisabled() {
    if (this.formGroup && this.formGroup.dirty) {
      return false;
    }
    return true;
  }

  get processDisabled() {
    if (this.memberOption.length == 0) {
      return true;
    }
    if (this.formGroup && this.formGroup.valid) {
      return false;
    }
    return true;
  }

  resetBtuuonConfig: CdsButtonConfig = {
    style: 'secondary',
  };

  isLoading = false;

  subscription?: Subscription;

  transferNum = 0;

  get originAgentCodeControl() {
    return this.formGroup.get('originAgentCode') as FormControl;
  }

  get originAgentCode() {
    return this.originAgentCodeControl?.value as string;
  }

  get employerAccountNameControl() {
    return this.formGroup.get('employerAccountName') as FormControl;
  }
  get memberNameControl() {
    return this.formGroup.get('memberName') as FormControl;
  }

  get companyList() {
    return (this.employerAccountNameControl?.value || []) as CdMultidropdownTopping[];
  }

  get selectMemberList() {
    return (this.memberNameControl?.value || []) as CdMultidropdownTopping[];
  }

  get memberListControl() {
    return this.formGroup.get('memberList') as FormControl;
  }

  get memberType() {
    return this.memberListControl?.value as MemberListTypeE;
  }

  get newAgentCodeControl() {
    return this.formGroup.get('newAgentCode') as FormControl;
  }

  get newAgentCode() {
    return this.newAgentCodeControl?.value as string;
  }

  constructor(private service: ShkService, private toastAlert: ToastAlertService, private cdsPopup: CdsPopupService, private langService: CdsLangService) {}

  ngOnInit(): void {
    this.initForm();
    this.disableControls();
  }

  initForm() {
    this.formGroup = new FormGroup(
      {
        originAgentCode: new FormControl('', {
          validators: [agentCodeListValidator(), Validators.required],
          initialValueIsDefault: true,
        }),
        employerAccountName: new FormControl([], {
          validators: [Validators.required],
          initialValueIsDefault: true,
        }),
        memberName: new FormControl([], {
          initialValueIsDefault: true,
        }),
        memberList: new FormControl('', {
          validators: [Validators.required],
          initialValueIsDefault: true,
        }),
        newAgentCode: new FormControl('', {
          validators: [agentCodeListValidator(), Validators.required],
          initialValueIsDefault: true,
        }),
      },
      { validators: this.agentCodeDuplicateValidator }
    );
  }

  disableControls() {
    this.newAgentCodeControl.disable();
  }

  selectAll() {
    const control = this.formGroup.get('employerAccountName');
    let value: CdMultidropdownTopping[] = [];
    if (!this.ifSelectAll(this.companyList?.length)) {
      value = [...this.customerOption];
    }
    control?.setValue(value);
    this.employerAccountNameChange();
  }

  ifSelectAll = (selectedLength?: number) => {
    if (!selectedLength) {
      return false;
    }
    if (selectedLength === this.customerOption.length) {
      return true;
    } else {
      return false;
    }
  };

  originAgentCodeChange(value: string) {
    this.employerAccountNameControl.reset();
    if (this.originAgentCodeControl.invalid) {
      this.setMultidropdown([]);
    } else {
      this.checkAgentCode(
        this.originAgentCodeControl,
        value,
        () => {
          this.getEmployer(value);
        },
        () => {
          this.setMultidropdown([]);
        }
      );
    }
  }

  newAgentCodeChange(value: string) {
    if (this.newAgentCodeControl.valid) {
      this.checkAgentCode(this.newAgentCodeControl, value);
    }
  }

  checkAgentCode(control: AbstractControl, value: string, passFun?: any, notPassFun?: any) {
    this.isLoading = true;
    this.service
      .agentReassignmentCheckAgentCode(value)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe(res => {
        if (res.result === 0) {
          if (res.data === true) {
            control.setErrors(null);
            if (passFun && typeof passFun === 'function') {
              passFun();
            }
          } else {
            control.setErrors({ agentCodeInvalid: true });
            if (notPassFun && typeof notPassFun === 'function') {
              notPassFun();
            }
          }
        } else {
          this.toastError(res.message);
        }
      });
  }

  getEmployer(value: string) {
    this.isLoading = true;
    this.service
      .getAgentReassignmentSelectEmployer(value)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe(res => {
        if (res.result === 0 && res.data) {
          this.setMultidropdown(res.data);
        } else {
          this.toastError(res.message);
        }
      });
  }

  setMultidropdown(value: Employer[]) {
    this.customerOption = value.map(item => {
      return {
        label: item.employerName,
        value: item.employerCode,
      };
    });

    this.employerAccountNameControl.setValue([]);
    this.employerAccountNameChange();
  }
  setMemberMultidropdown(value: RelatedMemberResponse[]) {
    if (value.length > 0) {
      this.memberOption = value.map(item => {
        return {
          label: item.member_name,
          value: item.id,
        };
      });
    } else {
      this.memberOption = [];
      this.memberNameControl.reset();
      this.memberNameControl.setValue([]);
    }
  }

  //value: CdMultidropdownTopping[]
  employerAccountNameChange() {
    this.transferNum = 0;
    this.newAgentCodeControl.disable();
    this.newAgentCodeControl.reset();
    this.memberListControl.reset();
    this.memberOption = [];
    this.memberNameControl?.reset();
    this.setMemberMultidropdown([]);
  }

  memberListChange() {
    if (this.companyList.length > 0) {
      this.newAgentCodeControl.enable();
      this.getMemberList();
    } else {
      this.newAgentCodeControl.reset();
      this.newAgentCodeControl.disable();
    }
    this.setMemberMultidropdown([]);
    this.memberNameControl.reset();
    this.memberNameControl.setValue([]);
    this.memberOption = [];
  }

  getMemberList() {
    const employerCodesStr = this.companyList
      .map(item => {
        return item.value;
      })
      .join(',');
    const params: RelatedMemberRequest = {
      employerCodes: employerCodesStr,
      chooseType: this.memberType,
      agentCodes: this.originAgentCode,
    };
    this.isLoading = true;
    this.service
      .getRelatedMember(params)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe(res => {
        if (res.result === 0 && res.data !== undefined) {
          this.transferNum = res.data.length;
          if (this.transferNum === 0) {
            this.newAgentCodeControl.reset();
            this.newAgentCodeControl.disable();
            this.setMemberMultidropdown([]);
          } else {
            this.setMemberMultidropdown(res.data);
            this.newAgentCodeControl.enable();
          }
        } else {
          this.toastError(res.message);
        }
      });
  }

  getMemberCount() {
    const employerCodesStr = this.companyList
      .map(item => {
        return item.value;
      })
      .join(',');
    const params: AgentReassignmentMemberCountRequet = {
      employerCodes: employerCodesStr,
      chooseMember: this.memberType,
      agentCodes: this.originAgentCode,
    };
    this.isLoading = true;
    this.service
      .getAgentReassignmentMemberCount(params)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe(res => {
        if (res.result === 0 && res.data !== undefined) {
          this.transferNum = res.data;
          if (this.transferNum === 0) {
            this.newAgentCodeControl.reset();
            this.newAgentCodeControl.disable();
          } else {
            this.newAgentCodeControl.enable();
          }
        } else {
          this.toastError(res.message);
        }
      });
  }

  submit() {
    if (this.processDisabled) {
      return;
    }

    const originAgentCodeStr = this.originAgentCode.split(',').join(', ');
    const companyListStr = this.companyList
      .map(item => {
        return item.label;
      })
      .join(', ');
    const newAgentCodeStr = this.newAgentCode.split(',').join(', ');

    this.cdsPopup
      .open(ContinuePopupComponent, {
        size: 'sm',
        data: {
          title: 'Below changes will be updated',
          message: `<span class="emphasize">${this.transferNum} employees</span> handled by agent <span class="emphasize">${originAgentCodeStr}</span> from <span class="emphasize">${companyListStr}</span> will be transferred to agent <span class="emphasize">${newAgentCodeStr}</span>.`,
          cancelButtonName: 'Back to Edit',
          continueButtonName: 'Confirm',
        },
      })
      .afterClosed()
      .subscribe(result => {
        if (result?.agree) {
          this.doSubmit();
        }
      });
  }

  doSubmit() {
    const agentCodes = this.originAgentCode.split(',');
    const employerCodes = this.companyList.map(item => {
      return item.value as string;
    });
    let memberIds: string[] = [];
    if (this.selectMemberList.length != 0) {
      memberIds = this.selectMemberList.map(item => {
        return item.value as string;
      });
    } else {
      memberIds = this.memberOption.map(item => {
        return item.value as string;
      });
    }

    const newAgentCodes = this.newAgentCode.split(',');
    const params: AgentReassignmentMemberProcessRequet = {
      memberIds,
      employerCodes,
      chooseType: this.memberType,
      agentCodes,
      newAgentCodes,
    };
    this.isLoading = true;
    this.service
      .getAgentReassignmentProcess(params)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe(res => {
        if (res.result === 0 && res.data === true) {
          this.popupWithReload();
        } else {
          this.toastError(res.message);
        }
      });
  }

  toastError(errorMessage: string) {
    this.toastAlert.show('error', 'common.error', errorMessage, 5000);
  }

  reset() {
    if (this.resetDisabled) {
      return;
    }

    this.cdsPopup
      .open(ContinuePopupComponent, {
        size: 'sm',
        data: { message: this.langService.translate('common.action.cancel') },
      })
      .afterClosed()
      .subscribe(result => {
        if (result?.agree) {
          this.doReset();
        }
      });
  }

  doReset() {
    this.transferNum = 0;
    this.setMultidropdown([]);
    this.setMemberMultidropdown([]);
    this.formGroup.reset();
    this.formGroup.markAsPristine();
    this.formGroup.markAsUntouched();
    this.disableControls();
  }

  popupWithReload() {
    this.cdsPopup
      .open(AlertPopupComponent, {
        size: 'sm',
        data: {
          message: this.langService.translate('common.action.message.update.success.italic'),
          buttonName: this.langService.translate('common.gotItWithExclamation'),
        },
      })
      .afterClosed()
      .subscribe(() => {
        this.doReset();
      });
  }

  agentCodeDuplicateValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    const originControl = control.get('originAgentCode');
    const newControl = control.get('newAgentCode');

    if (originControl?.valid && newControl?.valid) {
      const originList = originControl.value.split(',');
      const newList = newControl.value.split(',');
      const mergeList = [...originList, ...newList];
      if (new Set(mergeList).size !== mergeList.length) {
        return { agentCodeDuplicated: true };
      }
    }

    return null;
  };

  memberChange(value: CdMultidropdownTopping[]) {
    if (value.length > 0) {
      this.transferNum = value.length;
    } else {
      this.getMemberList();
    }
  }
}
