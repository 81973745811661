import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CampaignMasterListComponent } from './campaign-master-list/campaign-master-list.component';
import { CampaignRoutingModule } from './campaign-routing.module';
import { CdsTextfieldModule } from '@cds/ng-web-components/textfield';
import { CdsLanguageModule } from '@cds/ng-core/lang';
import { CdsButtonModule } from '@cds/ng-web-components/button';
import { CdsIconModule } from '@cds/ng-web-components/icon';
import { CdsPaginationModule } from '@cds/ng-web-components/pagination';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CdsPopupModule } from '@cds/ng-web-components/popup';
import { HttpClientModule } from '@angular/common/http';
import { CdsProgressModule } from '@cds/ng-web-components/progress';
import { CdsTableModule } from '@cds/ng-data-table/table';
import { CdsSortModule } from '@cds/ng-data-table/sort';
import { CdsCheckboxModule } from '@cds/ng-web-components/checkbox';
import { CdsTabsModule } from '@cds/ng-web-components/tabs';
import { CdsTooltipModule } from '@cds/ng-web-components/tooltip';
import { CdsTextareaModule } from '@cds/ng-web-components/textarea';
import { ExpandTableModule } from 'src/app/components/table/expand-table.module';
import { CdAccordionModule } from 'src/app/components/accordion/cd-accordion.module';
import { RowItemModule } from 'src/app/components/row-item/row-item.module';
import { CdsDropdownModule } from '@cds/ng-web-components/dropdown';
import { CdsDatepickerModule } from '@cds/ng-web-components/datepicker';
import { CdsSearchModule } from '@cds/ng-web-components/search';
import { MatRadioModule } from '@angular/material/radio';
import { CdsRadioModule } from '@cds/ng-web-components/radio';
import { LoadingModule } from 'src/app/shared/loading/loading.modules';
import { SharedModule } from 'src/app/shared/shared.module';
import { CdsPillModule } from '@cds/ng-web-components/pill';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CdsAccordionModule } from '@cds/ng-web-components/accordion';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSelectModule } from '@angular/material/select';
import { CdsAssistiveTextModule } from '@cds/ng-web-components/assistive-text';
import { CdsFormFieldModule } from '@cds/ng-web-components/form-field';
import { CdsFileUploadModule } from '@cds/ng-experimental/file-upload';
import { CdsInlineAlertModule } from '@cds/ng-web-components/inline-alert';
import { CampaignMasterListMemberComponent } from './campaign-master-list-member/campaign-master-list-member.component';
import { UploadCustomerListComponent } from './upload-customer-list/upload-customer-list.component';
import { AddCampaignComponent } from './add-campaign/add-campaign.component';

@NgModule({
  declarations: [CampaignMasterListComponent, CampaignMasterListMemberComponent, UploadCustomerListComponent, AddCampaignComponent],
  imports: [
    CommonModule,
    CampaignRoutingModule,
    CdsTextfieldModule,
    CdsLanguageModule,
    CdsIconModule,
    CdsButtonModule,
    CdsPaginationModule,
    FormsModule,
    CdsPopupModule,
    HttpClientModule,
    CdsProgressModule,
    CdsTableModule,
    CdsSortModule,
    CdsCheckboxModule,
    CdsTabsModule,
    CdsDropdownModule,
    CdsTooltipModule,
    ReactiveFormsModule,
    CdsTextareaModule,
    ExpandTableModule,
    CdAccordionModule,
    RowItemModule,
    CdsDatepickerModule,
    CdsSearchModule,
    MatRadioModule,
    CdsRadioModule,
    LoadingModule,
    SharedModule,
    CdsPillModule,
    BrowserAnimationsModule,
    CdsAccordionModule,
    MatAutocompleteModule,
    MatSelectModule,
    CdsAssistiveTextModule,
    CdsFormFieldModule,
    CdsFileUploadModule,
    CdsInlineAlertModule,
  ],
})
export class CampaignModule {}
