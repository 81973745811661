<div class="row l-mp-5">
  <div class="col-xs-12">
    <div class="inner-html-des l-mt-4" innerHTML="{{ 'notification.create.htmlDes' | lang }}"></div>
  </div>
</div>
<app-loading *ngIf="isLoading" [overlayInPopup]="true"></app-loading>
<div class="row line-break">
  <div class="col-xs-12">
    <p class="cds-h2-light">{{ 'confirm.message-summary' | lang }}</p>
  </div>
  <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 l-mb-4">
    <div class="p-tiips cds-detail1">{{ 'confirm.targetBroadcast' | lang }}</div>
    <div>{{ summaryDTO.targetBroadcastDate }}</div>
  </div>
  <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 l-mb-4">
    <div class="p-tiips cds-detail1">{{ 'confirm.targetBroadcastTime' | lang }}</div>
    <div>{{ summaryDTO.targetBroadcastTime }}</div>
  </div>
  <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 l-mb-4">
    <div class="p-tiips cds-detail1">{{ 'confirm.category' | lang }}</div>
    <div>{{ ca.get(summaryDTO.category) }}</div>
  </div>
  <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 l-mb-4">
    <div class="p-tiips cds-detail1">{{ 'confirm.priority' | lang }}</div>
    <div>{{ summaryDTO.priority }}</div>
  </div>
  <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 l-mb-4">
    <div class="p-tiips cds-detail1">{{ 'confirm.audienceList' | lang }}</div>
    <div>{{ notificationService.getAudienceLabel | fn : summaryDTO.audienceListName }}</div>
  </div>
  <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 l-mb-4">
    <div class="p-tiips cds-detail1">{{ 'confirm.pushCampaignId' | lang }}</div>
    <div class="line-break">{{ summaryDTO.pushCampaignId }}</div>
  </div>
  <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 l-mb-4">
    <div class="p-tiips cds-detail1">{{ 'confirm.pushCampaignId' | lang }}</div>
    <div class="line-break">{{ summaryDTO.pushCampaignId }}</div>
  </div>
</div>
<div class="row line-break">
  <div class="col-xs-12">
    <p class="cds-h6-demibold margin-2">{{ 'create.versionTitle.en' | lang }}</p>
  </div>
  <div class="col-xs-12 col-sm-12 col-md-10 col-lg-10 cds-body1">
    <div class="p-tiips">{{ 'create.subject.en' | lang }}</div>
    <div>{{ summaryDTO.subjectEn }}</div>
  </div>
  <div class="col-xs-12 col-sm-12 col-md-10 col-lg-10 cds-body1">
    <div class="p-tiips">{{ 'confirm.message.body.en' | lang }}</div>
    <div>{{ summaryDTO.messageBodyEn }}</div>
  </div>
</div>

<div class="row line-break">
  <div class="col-xs-10">
    <div class="p-line"></div>
  </div>
  <div class="col-xs-12">
    <p class="cds-h6-demibold margin-2">{{ 'create.versionTitle.zh' | lang }}</p>
  </div>
  <div class="col-xs-12 col-sm-12 col-md-10 col-lg-10 cds-body1">
    <div class="p-tiips">{{ 'create.subject.label.zh' | lang }}</div>
    <div>{{ summaryDTO.subjectCn }}</div>
  </div>
  <div class="col-xs-12 col-sm-12 col-md-10 col-lg-10 cds-body1">
    <div class="p-tiips">{{ 'confirm.message.body.zh' | lang }}</div>
    <div>{{ summaryDTO.messageBodyCn }}</div>
  </div>
</div>

<div class="row line-break">
  <div class="col-xs-10">
    <div class="p-line"></div>
  </div>
  <div class="col-xs-12 l-mt-6">
    <p class="cds-h5-demibold">{{ 'notification.deepLink' | lang }}</p>
  </div>
  <div class="col-xs-12 col-sm-12 col-md-10 col-lg-10 cds-body1">
    <div class="p-tiips">{{ 'create.link.en' | lang }}</div>
    <div>{{ summaryDTO.deepLinkUrl }}</div>
  </div>
</div>
<div class="row">
  <cds-button
    [fullWidth]="true"
    class="col-xs-12 col-sm-12 col-md-6 col-lg-2 margin-sbumit botton-padding"
    (click)="back()"
    [config]="backConfig"
    label="{{ 'confirm.backBotton' | lang }}"></cds-button>
  <cds-button
    [fullWidth]="true"
    class="col-xs-12 col-sm-12 col-md-6 col-lg-2 margin-sbumit botton-padding"
    (click)="confirm()"
    [config]="confirmConfig"
    label="{{ 'confirm.confirmBotton' | lang }}"></cds-button>
</div>
