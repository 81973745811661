<app-cd-page-title [showBack]="true" [backLandingLabel]="'reportingRole.list' | lang" (backLanding)="back()" title="{{ 'reportingRole.edit.title' | lang }}">
</app-cd-page-title>
<div class="row">
  <div class="col-xs-12 col-md-12 l-pt-6 l-pb-3 l-d-f">
    <div *ngFor="let item of tab" class="tab" [class.tab-selected]="item.selected" (click)="tabClick(item)">
      <div>{{ item.label | lang }}</div>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-xs-12" *ngIf="isLoading">
    <app-loading></app-loading>
  </div>
  <div *ngIf="!isLoading" class="col-xs-12 col-md-12 l-pt-5 l-pb-3" [ngSwitch]="tabSelected.value">
    <app-reporting-role-form
      *ngIf="tab[0].selected"
      [roleNameIn]="roleName"
      [roleHasPermission]="roleHasPermission"
      [rolePermissionGroup]="rolePermissionGroupList"
      (save)="save($event)"
      (delete)="deleteConfirm()"></app-reporting-role-form>
    <app-role-assign-users *ngIf="tab[1].selected" [roleId]="roleId"></app-role-assign-users>
  </div>
</div>
