import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SimplePopupComponent } from './simple-popup.component';
import { CdsButtonModule } from '@cds/ng-web-components/button';
import { CdsLanguageModule } from '@cds/ng-core/lang';
import { CdsPopupModule } from '@cds/ng-web-components/popup';

@NgModule({
  declarations: [SimplePopupComponent],
  exports: [SimplePopupComponent],
  imports: [CommonModule, CdsPopupModule, CdsLanguageModule, CdsButtonModule],
})
export class SimplePopupModule {}
