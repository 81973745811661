import { CdsButtonConfig } from '@cds/ng-core/button';
import { CdsDropdownConfig } from '@cds/ng-core/dropdown';
import moment from 'moment';
import { IPageableRequest } from 'src/app/core/models/request';
import { CdMultidropdownTopping } from 'src/app/shared/cd-multidropdown/cd-multidropdown.model';

export enum SchemeCode {
  ORSO = 'ORSO',
  MACAUPPS = 'MACAUPPS',
  MACAUCPF = 'MACAUCPF',
}
export interface PaymentStatusSelected {
  label: string;
  value: string;
  selected?: boolean;
}
export interface ChequeSearch {
  instructionFileDateFrom?: string;
  instructionFileDateTo?: string;
  chequeIssueDateFrom?: string;
  chequeIssueDateTo?: string;
  sequence?: string;
  recordStatusList?: CdMultidropdownTopping[] | string[];
  payMethod?: PaymentMethodE;
  payeeName?: string;
  isReissue?: string;
  checked?: boolean;
  payRefNo?: string;
  recordDateFrom?: string;
  recordDateTo?: string;
  txnType?: string;
  outChequeNo?: string;
  appRefNo?: string;
  schemeRegNoList?: string[];
}

export interface BaseChequeSearch {
  instructionFileDateFrom?: string;
  instructionFileDateTo?: string;
  payRefNo?: string;
  appRefNo?: string;
  payeeName?: string;
  isReissue?: string;
  schemeRegNoList?: string[];
}

export function pageInit() {
  return { current: 1, count: 0 };
}

export interface ChequeSearchDTO extends ChequeSearch, IPageableRequest {}

export interface ChequeDTO {
  id: string;
  checked?: boolean;
  opsPermission?: boolean;
  cheqNoError?: string;
  chequeIssueDate?: string;
  acctType?: string;
  sequence?: string;
  recordStatus?: PaymentStatusE;
  payRefNo?: PaymentMethodE;
  feedReceiveDate?: string;
  genDate?: string;
  // unknown
  paymentRefNo?: string;
  //  unknown
  amt?: string;
  fullName?: string;
  payeeName?: string;
  // unknown
  exptPayIssue?: string;
  customerId?: string;
  isReissue?: string;
  reissueRefNo?: string;
  reissueReason?: string;
  remarks?: string;
  outChequeNo?: string;
  addr1Room?: string;
  addr2Floor?: string;
  addr3Block?: string;
  addr4Bldg?: string;
  addr5street?: string;
  addr6Dist?: string;
  addr7City?: string;
  addr8Country?: string;
  lastUpdateDate?: string;
  appRefNo?: string;
  curr?: string;
  index?: number;
  downConfig?: CdsDropdownConfig;
  canInputChequeNo?: boolean;
}

export function maxDate(date?: string) {
  if (date) {
    return formatDate(date);
  }
  return '2099-01-01';
}

export function minDate(date?: string) {
  if (date) {
    return formatDate(date);
  }
  return '1901-01-01';
}

export function formatDate(date?: string) {
  if (date) {
    return moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD');
  }
  return '';
}

export function formatDate2(date?: string) {
  if (date) {
    return moment(date, 'YYYYMMDD').format('DD/MM/YYYY');
  }
  return '';
}

export const _reasonMap = new Map([
  [
    '1',
    'Invalid bank account due to invalid bank account number/bank account holder name/closed bank account (i.e. reissue payment as failed to deposit payment to provided bank account',
  ],
  ['2', 'Update on the payee name (i.e. client request to reissue payment with updates on payee name)'],
  ['3', 'Lost cheque (i.e. client request to reissue payment via e-payment as lost cheque)'],
  ['4', 'Bounced cheque (i.e. client request to reissue payment via e-payment as bounced cheque)'],
  ['5', 'Unpresented cheque  (i.e. client request to reissue payment via e-payment as cheque is unpresented after 6 months from cheque issued date)'],
  ['6', 'Others (i.e. Any other reason indicated by ER/Member)'],
]);

export function formatReason(val: string) {
  return _reasonMap.get(val);
}

export function getColor(status: string | undefined) {
  if (status === PaymentStatusE.NEW) {
    return '#F49600';
  }
  if (status === PaymentStatusE.REISSUE) {
    return '#EC6453';
  }
  if (status === PaymentStatusE.READY_TO_REISSUE) {
    return '#06C7BA';
  }
  if (status === PaymentStatusE.HOLD_PAYMENT) {
    return '#DDDEE4';
  }
  if (status === PaymentStatusE.PRINTED) {
    return '#00A758';
  }
  if (status === PaymentStatusE.UN_PRESENTED) {
    return '#046138';
  }
  if (status === PaymentStatusE.PRESENTED) {
    return '#1E1EE5';
  }
  if (status === PaymentStatusE.EXPIRED) {
    return '#8E90A2';
  }
  if (status === PaymentStatusE.RETURNED_FROM_EE) {
    return '#76B0FF';
  }
  if (status === PaymentStatusE.RETURNED_FROM_ER) {
    return '#2D69FF';
  }
  if (status === PaymentStatusE.REISSUE_REJECT) {
    return '#D03A39';
  }
  if (status === PaymentStatusE.REISSUE_COMPLETE) {
    return '#5CD790';
  }
  if (status === PaymentStatusE.REISSUE_PRESENTED) {
    return '#09847B';
  }
  if (status === PaymentStatusE.STOP_PAYMENT) {
    return '#34384B';
  }

  if (status === PaymentStatusE.COMPLETED) {
    return '#00A758';
  }
  if (status === PaymentStatusE.REJECTED) {
    return '#D03A39';
  }
  if (status === PaymentStatusE.PASSED_TO_BANK) {
    return '#1E1EE5';
  }
  if (status === PaymentStatusE.PENDING) {
    return '#DDDEE4';
  }
  return '';
}

export enum PaymentStatusE {
  NEW = 'NEW',
  PRINTED = 'PRINTED',
  UN_PRESENTED = 'UN_PRESENTED',
  PRESENTED = 'PRESENTED',
  EXPIRED = 'EXPIRED',
  REISSUE = 'REISSUE',
  REISSUE_REJECT = 'REISSUE_REJECT',
  PENDING = 'PENDING',
  READY_TO_REISSUE = 'READY_TO_REISSUE',
  HOLD_PAYMENT = 'HOLD_PAYMENT',
  STOP_PAYMENT = 'STOP_PAYMENT',
  REISSUE_COMPLETE = 'REISSUE_COMPLETE',
  RETURNED_FROM_EE = 'RETURNED_FROM_EE',
  RETURNED_FROM_ER = 'RETURNED_FROM_ER',
  REISSUE_PRESENTED = 'REISSUE_PRESENTED',
  COMPLETED = 'COMPLETED',
  REJECTED = 'REJECTED',
  PASSED_TO_BANK = 'PASSED_TO_BANK',
}

export enum PaymentMethodE {
  CHQ = 'CHQ',
  BTL = 'BTL',
  BTO = 'BTO',
}

export function configPrimary(): CdsButtonConfig {
  return { style: 'primary' };
}
export function configSecondary(): CdsButtonConfig {
  return { style: 'secondary' };
}
export function configTertiary(): CdsButtonConfig {
  return { style: 'tertiary' };
}

export const I18N_KEY = {
  INVALID_DATE: 'common.invalidDate',
  INVALID_DESCRIPTION: 'employer.invalid-description',
  INVALID_DESCRIPTION_CHINESE: 'employer.invalid-description-chinese',
  INVALID_USERNAME: 'employer.invalid-username',
  INVALID_AGENT_CODE: 'agent.invalide-agent-code',
};

export interface ScbStatementSearchDTO extends IPageableRequest {
  uploadDate?: string;
  fileName?: string;
}

export interface ScbStatementDTO {
  id?: string;
  uploadDate?: string;
  fileName?: string;
  createAt?: string;
  updatedBy?: string;
  uploadBy?: string;
}

export interface ScbUploadResponse {
  total?: number;
  updated?: number;
}
