import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { EmployerSummaryComponent } from './employer-summary/employer-summary.component';
import { EmployeeSummaryComponent } from './employee-summary/employee-summary.component';
import { AdminLayoutComponent } from '../layout/admin-layout/admin-layout.component';
import { EmployerHistoryComponent } from './employer-history/employer-history.component';
import { ErDelegationChangeComponent } from './er-delegation-change/er-delegation-change.component';
import { EmployeeDelegationChangeComponent } from './employee-delegation-change/employee-delegation-change.component';
import { PermissionAccess, PermissionItem } from 'src/app/core/models/enum/permission.enum';

const routes: Routes = [
  {
    path: 'agent',
    component: AdminLayoutComponent,
    children: [
      //{ path: '', redirectTo:"employer-summary",pathMatch:'full'},
      {
        path: 'employer-summary',
        component: EmployerSummaryComponent,
        data: {
          roles: {
            [PermissionItem.CUSTOMER_SERVICING_AGENT]: PermissionAccess.R,
          },
        },
      },
      {
        path: 'employee-summary/:employerId',
        component: EmployeeSummaryComponent,
        data: {
          roles: {
            [PermissionItem.CUSTOMER_SERVICING_AGENT]: PermissionAccess.R,
          },
        },
      },
      {
        path: 'er-delegation-change',
        component: ErDelegationChangeComponent,
        data: {
          roles: {
            [PermissionItem.CUSTOMER_SERVICING_AGENT]: PermissionAccess.R,
          },
        },
      },
      {
        path: 'employee-delegation-change/:employerId',
        component: EmployeeDelegationChangeComponent,
        data: {
          roles: {
            [PermissionItem.CUSTOMER_SERVICING_AGENT]: PermissionAccess.R,
          },
        },
      },
      {
        path: 'employer-history',
        component: EmployerHistoryComponent,
        data: {
          roles: {
            [PermissionItem.CUSTOMER_SERVICING_AGENT]: PermissionAccess.R,
          },
        },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AgentRoutingModule {}
