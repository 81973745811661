import { Component, OnInit, Input, AfterViewInit, ChangeDetectorRef, OnDestroy, ViewChild, ViewChildren, QueryList, ElementRef } from '@angular/core';
import { Subscription } from 'rxjs';

import { Sort, SortDirection } from '@cds/ng-data-table/sort';
import { CdsColorTheme } from '@cds/ng-core/configuration';
import { ActionIcons, ArrowIcons } from '@cds/ng-themes';
import { CdsProgressModeType, CdsProgressType } from '@cds/ng-core/progress';
import { CdsTextfieldConfig } from '@cds/ng-core/textfield';
import { CdsButtonConfig } from '@cds/ng-core/button';
import { CdsAlertService } from '@cds/ng-web-components/alert';
import { CdsIconConfig } from '@cds/ng-core/icon';
import { CdsInlineAlertType } from '@cds/ng-web-components/inline-alert';
import { CdsPopupService, MatDialogRef } from '@cds/ng-web-components/popup';
import { EmployerRequest } from '../employer-summary/employer-request.model';
import { AgentEmployerDelegationService } from '../agent-employer-delegation.service';
import { AgentEmployerResponseData, EmployerDelegateAgentInfo } from '../agent-employer-response.model';
import { SubmitDelegateRequest } from './submit-delegate-request';
import { Agent, AgentCodeBatchValidateResponseData } from './agent-code-batch-validate-response';
import { CheckAgentCodeRequest, EmployerCheckRequest } from './check-agent-code-request';
import { UserConfirmPopComponent } from '../user-confirm-pop/user-confirm-pop.component';
import { UserSuccessSubmittedComponent } from '../user-success-submitted/user-success-submitted.component';
import { UserExitEditmodePopComponent } from '../user-exit-editmode-pop/user-exit-editmode-pop.component';
import { LanguageChangeService } from 'src/app/shared/language-change.service';
import { AgentCodeData } from './check-agent-code-response';
import { ActivatedRoute, Router } from '@angular/router';
import { CdsLangService } from '@cds/ng-core/lang';
import { AgentBranchPopComponent } from '../agent-branch-pop/agent-branch-pop.component';
import { specialCharacter } from 'src/app/utils/special-character';
import { CdStepperComponent } from 'src/app/shared/cd-stepper/cd-stepper.component';
import { CdStepComponent } from 'src/app/shared/cd-stepper/cd-step/cd-step.component';
import { pageToTop } from 'src/app/utils/utils';
import { NotifyService } from 'src/app/core/services/notify.service';

@Component({
  selector: 'app-employer-summary',
  templateUrl: './employer-summary.component.html',
  styleUrls: ['./employer-summary.component.scss'],
})
export class EmployerSummaryComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChildren('tooltip') tooltipList!: QueryList<ElementRef<HTMLDivElement>>;
  @ViewChildren('step') stepList!: QueryList<CdStepComponent>;
  @ViewChild('tableBox', { static: false })
  tableBox?: ElementRef<HTMLDivElement>;
  @Input() color: CdsColorTheme = 'ml';
  @Input() pageCountDlgt = 1;
  @Input() pageDlgt = 1;
  @Input() searchValueDlgt = '1';
  @Input() searchValueSub = '1';
  @Input() pageCountSbms = 1;
  @Input() pageSbms = 1;
  @Input() searchValueSbms = '1';
  @Input() agentcodeValue = '';
  @Input() alertType: CdsInlineAlertType = 'error';
  @Input() fullWidth = true;
  @Input() pageCount = 1;
  @Input() page = 1;
  @Input() sortDirection: SortDirection = 'asc';
  @Input() searchBtnDisableState = true;
  @Input() editModel = false;
  stepperTarget: 'pc' | 'ipad' = 'pc';
  nameValue = '';
  numberValue = '';
  agentValue = '';
  enterShowProgress = true;

  dataSourceInSummary: AgentEmployerResponseData[] = [];
  dataSourceInDelegate: AgentEmployerResponseData[] = [];
  dataSourceInConfirmation: AgentEmployerResponseData[] = [];
  language = this.cdsLangService.currentLang === 'en' ? 'EN' : 'TC';
  itemsSelected = 0;
  constructor(
    private agentEmployerDelegationService: AgentEmployerDelegationService,
    private changeDetectorRef: ChangeDetectorRef,
    private alert: CdsAlertService,
    private cdsPopup: CdsPopupService,
    public languageChangeService: LanguageChangeService,
    private router: Router,
    private route: ActivatedRoute,
    private cdsLangService: CdsLangService,
    private notifyService: NotifyService
  ) {}

  public errorSub?: Subscription;
  moreOptions = ActionIcons.more_options;
  isShowButtonList = false;
  resetTable = false;

  ngOnDestroy(): void {
    this.employerDataInSummarySubscription?.unsubscribe();
    this.employerDataInDelegateSubscription?.unsubscribe();
    this.errorSub?.unsubscribe();
    this.lanChangeSubscription?.unsubscribe();
    this.checkAgentCodeSubscription?.unsubscribe();
    this.agentCodeBatchValidateSubscription?.unsubscribe();
    this.agentDelegationSubscription?.unsubscribe();
    this.clearItems();
  }
  isShowProgress = false;
  employerTotalCountInSummary = 0;
  indeterminate: CdsProgressModeType = 'indeterminate';
  cdsProgressType: CdsProgressType = 'ring';
  cdsProgressTxt = 'Loading';
  cdsProgressColor: CdsColorTheme = 'ml';
  updateIcon = ActionIcons.edit;
  public employerDataInSummarySubscription?: Subscription;
  public employerDataInDelegateSubscription?: Subscription;

  // variables from former delegate page
  employerTotalCountInDelegate = 0;
  canSearch = true;
  allChecked = false;
  totalCountSbms = 0;
  inputAgentCode = '';
  inputAgentCodeValid = true;
  inputAgentCodeErrorCode = 0;
  isInWhichStage = 0; // 0 = summary page, 1 = delegate page, 2 = confirmation page
  fullDataSource: {
    data: AgentEmployerResponseData[];
    totalElements: number;
    totalPages: number;
  }[] = [];
  elementsToSubmit: AgentEmployerResponseData[] = []; // works in confirmation page, if user will be sure to delegate them

  isDisableClear = true;
  markPage = 1;
  alertItems: {
    index: number;
    ifShow: boolean;
    message: string;
    messageTC: string;
  }[] = [];

  pageFirstInitInSummary = true;
  pageFirstInitInDelegate = true; // when page first init, will push empty arrays to fullDataSource
  lanChangeSubscription?: Subscription;
  checkAgentCodeSubscription?: Subscription;
  agentCodeBatchValidateSubscription?: Subscription;
  agentDelegationSubscription?: Subscription;
  myEmployerRequest: EmployerRequest = {
    page: 1,
    size: 10,
    language: this.language,
    sort: 'employerName asc',
  };

  agentCodeNameArr: {
    agentCode?: string;
    agentName?: string;
    agentNameTC?: string;
  }[] = [];

  reloadIcon = ArrowIcons.reload;
  @ViewChild('stepper') stepper!: CdStepperComponent;
  stepperItems = [
    { en: 'Select Item(s)', zh: '選擇項目' },
    { en: 'Fill agent code', zh: '輸入代理人編號' },
    { en: 'Add code to selected item(s)', zh: '新增編號至已選項目' },
    { en: 'Submission', zh: '提交' },
  ];

  ngOnInit(): void {
    this.isShowProgress = true;
    this.pageChangeInSummary(1);
    this.errorSub = this.agentEmployerDelegationService.errorEmployerSubject.subscribe(errorMessage => {
      const alertTitle = 'Got Error!';
      this.alert.error(alertTitle, errorMessage);
      this.isShowProgress = false;
      this.enterShowProgress = false;
      this.notifyService.screenLoadingSubject.next(false);
    });
    this.lanChangeSubscription = this.languageChangeService.lanSubject.subscribe(lan => {
      this.language = lan === 'en' ? 'EN' : 'TC';
      this.myEmployerRequest.language = this.language;
      this.cdsProgressTxt = lan === 'en' ? 'Loading' : '載入中';
    });
    this.employerDataInSummarySubscription = this.agentEmployerDelegationService.employerDataInSummarySubject.subscribe(response => {
      if (response.result != 0) {
        this.dataSourceInSummary = [];
        this.employerTotalCountInSummary = 0;
        this.pageCount = 0;
      } else if (response.data) {
        this.dataSourceInSummary = response.data ? response.data.content : [];
        this.employerTotalCountInSummary = response.data ? response.data.totalElements : 0;
        this.pageCount = response.data ? response.data.totalPages : 0;
      }
      this.isShowProgress = false;
      this.enterShowProgress = false;
      this.pageFirstInitInSummary = false;
    });

    this.employerDataInDelegateSubscription = this.agentEmployerDelegationService.employerDataInDelegateSubject.subscribe(response => {
      if (response.data && response.data.content) {
        this.dataSourceInDelegate = response.data.content;
        this.employerTotalCountInDelegate = response.data.totalElements;
        this.pageCountDlgt = response.data.totalPages;
        if (this.pageFirstInitInDelegate) {
          for (let i = 0; i < this.pageCountDlgt; i++) {
            this.fullDataSource.push({
              data: [],
              totalElements: this.employerTotalCountInDelegate,
              totalPages: this.pageCountDlgt,
            });
          }
          setTimeout(() => {
            if (this.stepperTarget === 'ipad') {
              this.stepper.setStepList(this.stepList);
            }

            this.refreshStep();
          }, 50);
          this.pageFirstInitInDelegate = false;
        }
        this.fullDataSource.splice(this.markPage - 1, 1, {
          data: this.dataSourceInDelegate,
          totalElements: this.employerTotalCountInDelegate,
          totalPages: this.pageCountDlgt,
        });
        this.allChecked = false;
      }
      this.isShowProgress = false;
      this.enterShowProgress = false;
    });
    if (document.getElementsByClassName('cds-navigation-content').length > 0) {
      document.getElementsByClassName('cds-navigation-content')[0].scrollTo(0, 0);
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      const menu = document.querySelector('aside.cds-menu-container') as HTMLElement;
      menu.style.display = 'none';
    }, 0);

    this.changeDetectorRef.detectChanges();

    const stopPropagation = (event: MouseEvent) => {
      event.stopPropagation();
    };

    this.tooltipList.changes.subscribe(() => {
      this.tooltipList.forEach(toolTip => {
        toolTip.nativeElement.removeEventListener('click', stopPropagation, false);
      });

      this.tooltipList.forEach(toolTip => {
        toolTip.nativeElement.addEventListener('click', stopPropagation, false);
      });
    });

    this.tooltipList.forEach(toolTip => {
      toolTip.nativeElement.addEventListener('click', stopPropagation, false);
    });

    if (window.matchMedia('only screen and (max-width: 1500px)').matches) {
      this.stepperTarget = 'ipad';
    } else {
      this.stepperTarget = 'pc';
    }
  }

  get checkIsHaveScroll() {
    if (this.tableBox) {
      return this.tableBox.nativeElement.scrollWidth > this.tableBox.nativeElement.clientWidth;
    }
    return false;
  }

  displayedColumnsInSummary: string[] = ['employerName', 'employerNo', 'employeeAmt', 'delegateAmt', 'canDelegateAmt', 'delegateAgentInfo'];

  onSortChange(event: Sort) {
    this.isShowProgress = true;
    let sortCondition = '';
    let active = '';
    let direction = '';
    if (event.active === 'canDelegateAmt') {
      active = 'nonDelegateAmt';
    } else {
      active = event.active;
    }
    sortCondition += active;
    sortCondition += ' ';
    if (event.direction === '') {
      sortCondition = 'employerName asc';
    } else {
      direction = event.direction;
      sortCondition += direction;
    }
    this.myEmployerRequest.sort = sortCondition;
    this.agentEmployerDelegationService.getEmployers(this.myEmployerRequest);
  }

  togglePage(isInWhichStage: number) {
    if (isInWhichStage === 0) {
      this.isInWhichStage = 1;
      this.editModel = true;
      this.pageChangeOnDelegateEr(1);
    } else {
      const popupRef: MatDialogRef<UserExitEditmodePopComponent> = this.cdsPopup.open(UserExitEditmodePopComponent, {});
      popupRef.afterClosed().subscribe(data => {
        if (data.agree) {
          this.fullDataSource = [];
          this.dataSourceInDelegate = [];
          // empty the batch input agent code text field
          this.inputAgentCode = '';
          // disable all the buttons in delegate page
          this.isDisableClear = true;
          this.isInWhichStage = 0;
          this.editModel = false;
          this.pageChangeInSummary(1);
          this.pageFirstInitInDelegate = true;
          this.pageFirstInitInSummary = true;
          this.isApplyToAllOnce = false;
          this.isShowProgress = true;
          this.inputAgentCodeValid = true;

          this.alertItems.forEach(element => {
            element.ifShow = false;
          });
        }
      });
    }
  }

  pageChangeInSummary(num: number): void {
    if (!this.pageFirstInitInSummary && num === this.page) {
      this.page = num;
      return;
    }
    this.myEmployerRequest.page = num;
    this.myEmployerRequest.hasNonDelegated = '';
    this.page = num;
    this.agentEmployerDelegationService.getEmployers(this.myEmployerRequest);
    this.isShowProgress = true;
  }

  searchByInputPageNoInSummary($event: Event, pageNo: string) {
    $event.preventDefault();
    let goToPage = 1;
    if (isNaN(parseInt(pageNo))) {
      goToPage = 1;
    } else if (+pageNo > this.pageCount) {
      goToPage = this.pageCount;
    } else {
      goToPage = +pageNo;
    }

    this.pageChangeInSummary(goToPage);
  }

  // from search er

  employerNameTxtfieldConfig: CdsTextfieldConfig = {
    label: this.language === 'EN' ? 'Employer Name' : '僱主名稱',
    type: 'text',
    color: 'ml',
    placeholder: this.language === 'EN' ? 'Please input' : '輸入詳情',
  };

  numbersTxtfieldConfig: CdsTextfieldConfig = {
    label: this.language === 'EN' ? 'Group No.' : '僱主編號',
    type: 'text',
    placeholder: this.language === 'EN' ? 'Please input' : '輸入詳情',
  };

  agentTxtfieldConfig: CdsTextfieldConfig = {
    label: this.language === 'EN' ? 'Delegated Agent Code' : '委託代理編號',
    type: 'text',
    placeholder: this.language === 'EN' ? 'Please input' : '輸入詳情',
  };

  employerSearch(): void {
    if (this.editModel || (this.nameValue === '' && this.numberValue === '' && this.agentValue === '')) {
      return;
    }
    this.page = 1;
    this.myEmployerRequest.page = 1;
    this.myEmployerRequest.employerName = this.nameValue.trim();
    this.myEmployerRequest.employerNo = this.numberValue.trim();
    this.myEmployerRequest.delegateAgentCode = this.agentValue.trim();
    this.agentEmployerDelegationService.getEmployers(this.myEmployerRequest);
    this.isShowProgress = true;
    this.onResetTable();
  }

  clearData(): void {
    if (this.editModel) {
      return;
    }
    this.isShowProgress = true;
    this.nameValue = '';
    this.numberValue = '';
    this.agentValue = '';
    this.myEmployerRequest = {
      page: 1,
      size: 10,
      language: this.language,
      sort: 'employerName asc',
    };
    this.page = 1;
    this.agentEmployerDelegationService.getEmployers(this.myEmployerRequest);
    this.searchBtnDisableState = true;
    this.onResetTable();
  }

  nameChange(result: string): void {
    this.nameValue = result;
    if (result === '') {
      this.searchCriteriaCheck();
    } else {
      this.searchBtnDisableState = false;
    }
  }

  numberChange(result: string): void {
    this.numberValue = result;
    if (result === '') {
      this.searchCriteriaCheck();
    } else {
      this.searchBtnDisableState = false;
    }
  }

  delegateAgentChange(result: string): void {
    this.agentValue = result;
    if (result === '') {
      this.searchCriteriaCheck();
    } else {
      this.searchBtnDisableState = false;
    }
  }

  searchCriteriaCheck() {
    if (this.nameValue === '' && this.numberValue === '' && this.agentValue === '') {
      this.searchBtnDisableState = true;
    }
  }

  iconConfig: CdsIconConfig = {
    size: 'sm',
    color: 'default',
  };
  infoIcon = ActionIcons.info_1;

  onHeaderCheckboxChange(val: boolean) {
    this.allChecked = val;
    this.dataSourceInDelegate.forEach(t => (t.isSelected = val));
    this.setClearBtnEnableOrNot();
    this.itemsSelected = this.computeElementsSelected();
  }

  onClickRowSelect(val: boolean, checkedIndex: number, pageNo: number) {
    this.dataSourceInDelegate[checkedIndex].isSelected = val;
    const isSomeNotChecked = this.fullDataSource[pageNo - 1]['data'].some(element => !element.isSelected);
    if (isSomeNotChecked) {
      this.allChecked = false;
    } else {
      this.allChecked = true;
    }
    if (this.isThereCheckboxCheckedInAllPages()) {
      // if ever apply to row once, and there are row(s) checked, 1,2,4 should be green
      if (this.isApplyToAllOnce) {
        this.stepper.setLightsOutStep(3);
        this.refreshStep();
      }
    } else {
      // if ever apply to row once, and there's no row(s) checked, 1,2,3,4 should be green
      if (this.isApplyToAllOnce) {
        this.stepper.removeLightsOutStep(3);
        this.refreshStep();
      }
    }
    this.setClearBtnEnableOrNot();
    this.itemsSelected = this.computeElementsSelected();
  }

  isThereCheckboxCheckedInAllPages() {
    const currentPages = this.fullDataSource.length;
    for (let i = 0; i < currentPages; i++) {
      const pageData: AgentEmployerResponseData[] = this.fullDataSource[i]['data'];
      const isSelected: boolean = pageData.some(element => element.isSelected);
      if (isSelected) {
        return true;
      }
    }
    return false;
  }

  computeElementsSelected() {
    let num = 0;
    const currentPages = this.fullDataSource.length;
    for (let i = 0; i < currentPages; i++) {
      const pageData: AgentEmployerResponseData[] = this.fullDataSource[i]['data'];
      const selectedCountPerPage = pageData.filter(element => element.isSelected).length;
      num += selectedCountPerPage;
    }
    if (this.isApplyToAllOnce) {
      this.refreshStep();
    } else {
      if (num > 0) {
        if (this.stepper.getFinishStep() === 1) {
          this.refreshStep();
        }
      } else {
        this.refreshStep();
      }
    }
    return num;
  }

  setClearBtnEnableOrNot() {
    const newArr: AgentEmployerResponseData[] = this.integrateToNewArr();
    const isSelected: boolean = newArr.some(element => element.isSelected);
    const haveAgentCode: boolean = newArr.some(element => !!element.agentCode);

    if (isSelected || haveAgentCode) {
      this.isDisableClear = false;
    } else {
      this.isDisableClear = true;
    }
  }

  integrateToNewArr() {
    let newArr: AgentEmployerResponseData[] = [];
    const currentPages = this.fullDataSource.length;
    for (let i = 0; i < currentPages; i++) {
      const pageData: AgentEmployerResponseData[] = this.fullDataSource[i]['data'];
      if (pageData.length !== 0) {
        newArr = newArr.concat(pageData);
      }
    }
    return newArr;
  }

  pageChangeOnDelegateEr(num: number): void {
    if (!this.pageFirstInitInDelegate && num === this.pageDlgt) {
      this.pageDlgt = num;
      return;
    }
    this.pageDlgt = num;
    // markPage is to record which page now, it's useful in checkbox click event
    this.markPage = num;
    this.searchValueDlgt = num + '';
    const pageData: {
      data: AgentEmployerResponseData[];
      totalElements: number;
      totalPages: number;
    } = this.fullDataSource[num - 1];
    if (pageData && pageData['data'].length !== 0) {
      this.dataSourceInDelegate = pageData['data'];
      this.employerTotalCountInDelegate = pageData.totalElements;
      this.pageCountDlgt = pageData.totalPages;
      // check if there is item which is not checked in this page
      const isNotSelected: boolean = this.dataSourceInDelegate.some(element => !element.isSelected);
      if (isNotSelected) {
        this.allChecked = false;
      } else {
        this.allChecked = true;
      }
    } else {
      // if this page has not loaded data from BE
      if (this.dataSourceInDelegate.length > 0) {
        this.isShowProgress = true;
      } else {
        this.enterShowProgress = true;
      }
      this.myEmployerRequest.page = num;
      this.myEmployerRequest.hasNonDelegated = 'Y';
      this.agentEmployerDelegationService.getEmployersInDelegate(this.myEmployerRequest);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  searchByInputPageNo($event: any, pageNo: string) {
    $event.preventDefault();
    const intPageNo: number = parseInt(pageNo);
    if (intPageNo > this.pageCountDlgt) {
      this.searchValueDlgt = '1';
      pageNo = '1';
    }
    this.pageChangeOnDelegateEr(parseInt(pageNo));
  }

  primaryButtonConfig: CdsButtonConfig = {
    size: 'sm',
    style: 'primary',
  };

  secondaryButtonConfig: CdsButtonConfig = {
    size: 'sm',
    style: 'secondary',
  };

  displayedColumnsInDelegate: string[] = ['isSelected', 'employerName', 'employerNo', 'employeeAmt', 'delegateAmt', 'nonDelegateAmt', 'delegateAgentInfo'];

  agentCodeName(agentCodeData: AgentCodeData) {
    if (this.agentCodeNameArr.length === 0) {
      const agentCodeDataTmp = {
        agentCode: agentCodeData.agentCode,
        agentName: agentCodeData.name,
        agentNameTC: agentCodeData.chineseName,
      };
      this.agentCodeNameArr.push(agentCodeDataTmp);
    } else {
      const index = this.agentCodeNameArr.indexOf(agentCodeData);
      if (index === -1) {
        const agentCodeDataTmp = {
          agentCode: agentCodeData.agentCode,
          agentName: agentCodeData.name,
          agentNameTC: agentCodeData.chineseName,
        };
        this.agentCodeNameArr.push(agentCodeDataTmp);
      }
    }
  }

  agentCodeTransfer(errorCode: number): {
    agentCodeCheckResult: string;
    agentCodeCheckResultTC: string;
  } {
    let agentCodeCheckResultByLan = '';
    let agentCodeCheckResultByLanTC = '';
    if (errorCode === 2101) {
      agentCodeCheckResultByLan = 'Agent code does not exist';
      agentCodeCheckResultByLanTC = '代理人編號不存在';
    } else if (errorCode === 2102) {
      agentCodeCheckResultByLan = 'Inactive agent';
      agentCodeCheckResultByLanTC = '無效代理人';
    } else if (errorCode === 2103) {
      agentCodeCheckResultByLan = 'Agent is not in same distinct';
      agentCodeCheckResultByLanTC = '代理人區域不相配';
    } else if (errorCode === 2199) {
      agentCodeCheckResultByLan = 'Agent code should be a 6 digits number, please add 0 in the front if the agent code is only 5 digits';
      agentCodeCheckResultByLanTC = '代理人編號應為六位數字, 如代理人編號只有五位數字, 請在編號前補上0';
    }
    return {
      agentCodeCheckResult: agentCodeCheckResultByLan,
      agentCodeCheckResultTC: agentCodeCheckResultByLanTC,
    };
  }

  valueChangeBatchAgent(agentCode: string) {
    agentCode = agentCode.trim();
    this.inputAgentCode = agentCode;

    if (agentCode.length === 0) {
      this.inputAgentCodeValid = true;
    }

    this.refreshStep();
  }

  isApplyToAllOnce = false;

  applyToAll() {
    if (this.isDisableBatchApplyAgentCode) {
      return;
    }

    const ckRequestTmp: CheckAgentCodeRequest = {};
    ckRequestTmp.agentCode = this.inputAgentCode.trim();
    const inputAgentCodeTrimed = this.inputAgentCode.trim();
    if (inputAgentCodeTrimed.length > 0 && inputAgentCodeTrimed.length <= 5) {
      this.inputAgentCodeValid = false;
      this.inputAgentCodeErrorCode = 2199;
      return;
    }
    this.checkAgentCodeSubscription?.unsubscribe();
    this.agentEmployerDelegationService.checkAgentCode(ckRequestTmp);
    this.checkAgentCodeSubscription = this.agentEmployerDelegationService.agentCodeResponseSubject.subscribe(resp => {
      if (resp.result === 0 && resp.data) {
        const checkedAgentcode = resp.data ? resp.data.agentCode : '';
        if (checkedAgentcode) {
          this.inputAgentCode = checkedAgentcode;
        }
        this.inputAgentCodeValid = true;
        this.agentCodeName(resp.data);
        this.isDisableClear = false;
        const currentPages = this.fullDataSource.length;
        for (let i = 0; i < currentPages; i++) {
          const pageData: AgentEmployerResponseData[] = this.fullDataSource[i]['data'];
          pageData
            .filter(element => element.isSelected)
            .forEach(element => {
              element.agentCode = this.inputAgentCode;
              element.flag = true;
              element.isSelected = false;
            });
          this.allChecked = false;
        }
        this.inputAgentCode = '';
        if (!this.isApplyToAllOnce) {
          this.isApplyToAllOnce = true;
        } else {
          this.stepper.removeLightsOutStep(3);
          this.refreshStep();
        }
        this.refreshStep();
        this.itemsSelected = 0;
      } else {
        this.inputAgentCodeValid = false;
        if (resp.data && resp.data.errorCode) {
          this.inputAgentCodeErrorCode = resp.data.errorCode;
        }
      }
    });
  }

  batchClearData() {
    const popupRef: MatDialogRef<UserExitEditmodePopComponent> = this.cdsPopup.open(UserExitEditmodePopComponent, {});
    popupRef.afterClosed().subscribe(data => {
      if (data.agree) {
        const currentPages = this.fullDataSource.length;
        for (let i = 0; i < currentPages; i++) {
          const pageData: AgentEmployerResponseData[] = this.fullDataSource[i]['data'];
          if (pageData.length !== 0) {
            pageData.forEach(element => {
              element.agentCode = '';
              element.isSelected = false;
              element.flag = true;
            });
          }
        }
        this.inputAgentCodeValid = true;
        this.inputAgentCode = ''; // set the batch agent code input field to ""
        this.allChecked = false;
        this.isDisableClear = true;
        this.stepper.removeLightsOutStep(3);
        this.refreshStep();
        this.itemsSelected = 0;
        this.isApplyToAllOnce = false; // reset means never applied

        this.alertItems.forEach(element => {
          element.ifShow = false;
        });
      }
    });
  }

  erDelegate() {
    if (this.isDisableSubmit) {
      return;
    }
    // clear batch agent input and clear batch agent input error
    this.inputAgentCode = '';
    this.inputAgentCodeValid = true;
    const tmpData: {
      data: AgentEmployerResponseData[];
      totalElements: number;
      totalPages: number;
    }[] = this.fullDataSource.filter(ele => ele.data.length > 0);
    let elementsToSubmitTmp: AgentEmployerResponseData[] = [];
    for (const element of tmpData) {
      const pageData: AgentEmployerResponseData[] = element['data'];
      if (pageData.length > 0) {
        elementsToSubmitTmp = elementsToSubmitTmp.concat(pageData.filter(_ele => _ele.flag === true && !!_ele.agentCode));
      }
    }
    if (elementsToSubmitTmp.length === 0) {
      return;
    }
    const checkAgentListTmp: EmployerCheckRequest[] = [];
    elementsToSubmitTmp.forEach(element => {
      const ckData = new EmployerCheckRequest();
      ckData.employerId = element.employerId;
      ckData.agentCode = element.agentCode;
      checkAgentListTmp.push(ckData);
    });

    this.agentCodeBatchValidateSubscription?.unsubscribe();
    this.agentEmployerDelegationService.agentCodeBatchValidate(checkAgentListTmp);
    this.agentCodeBatchValidateSubscription = this.agentEmployerDelegationService.agentCodeBatchValidateSubject.subscribe(resp => {
      if (resp.result === 0) {
        this.navigateToConfirmation(elementsToSubmitTmp);
      } else {
        const checkResultListTmp: AgentCodeBatchValidateResponseData[] = resp.data ? resp.data : [];
        this.addAlertItems(checkResultListTmp, elementsToSubmitTmp);
      }
    });
  }

  navigateToConfirmation(elementsToSubmitTmp: AgentEmployerResponseData[]) {
    this.isInWhichStage = 2;
    this.elementsToSubmit = elementsToSubmitTmp;
    this.addAgentNameForConfirmation(elementsToSubmitTmp);
    this.splitElements(elementsToSubmitTmp);
    this.pageChangeInConfirmation(1);
    this.searchValueSub = '1';
    pageToTop();
  }

  dismissAlert(index: number) {
    this.alertItems[index].ifShow = false;
  }

  addAlertItems(data: AgentCodeBatchValidateResponseData[], elementsToSubmitTmp: AgentEmployerResponseData[]) {
    this.alertItems = [];
    const agentArr: Agent[] = [];

    if (data.some(item => item.errorCode === 2104)) {
      data = data.filter(item => item.errorCode === 2104);
      for (let i = 0; i < data.length; i++) {
        let resultMsg = '';
        let resultMsgTC = '';
        if (data[i].errorCode === 2104) {
          const existsAgents1 = data[i].existAgents;
          const existsAgents2 = data[i].existAgents;
          let agentEn = existsAgents1?.map(existsAgent => existsAgent.agentCode + ' ' + existsAgent.name).join('、');
          const tempAgentEn = agentEn?.split('');
          tempAgentEn?.pop();
          agentEn = tempAgentEn?.join('');

          let agentTc = existsAgents2
            ?.map(existsAgent => {
              console.info(existsAgent.agentCode + ' ' + (existsAgent.chineseName ? existsAgent.chineseName : existsAgent.name));
              return existsAgent.agentCode + ' ' + (existsAgent.chineseName ? existsAgent.chineseName : existsAgent.name);
            })
            .join('、');
          const tempAgentTc = agentTc?.split('');
          tempAgentTc?.pop();
          agentTc = tempAgentTc?.join('');

          const limitAgentNum = data[i].limitAgent;
          if (agentEn) {
            resultMsg =
              'Each employer can maximum delegate 4 agents.' +
              data[i].employerInfo?.employerName +
              ' has been delegated to ' +
              limitAgentNum +
              ' agents including:' +
              agentEn;
          } else {
            resultMsg = 'Each employer can maximum delegate 4 agents.';
          }

          if (agentTc) {
            resultMsgTC = '每個僱主最多可委託4名代理人。' + data[i].employerInfo?.employerName + ' 已被委況託 ' + limitAgentNum + ' 位代理人，包括:' + agentTc;
          } else {
            resultMsgTC = '每個僱主最多可委託4名代理人。';
          }

          this.alertItems.push({
            index: i,
            ifShow: true,
            message: resultMsg,
            messageTC: resultMsgTC,
          });
        }
      }
      return;
    } else {
      for (let i = 0; i < data.length; i++) {
        if (data[i].errorCode === 2103) {
          const agentTmp = data[i].agent;
          if (agentTmp) {
            agentArr.push(agentTmp);
          }
        }
      }
    }

    if (agentArr.length > 0) {
      // add warning message for 2103(agent not in same branch)
      const popupRef: MatDialogRef<AgentBranchPopComponent> = this.cdsPopup.open(AgentBranchPopComponent, {
        size: 'md',
        data: { list: agentArr, language: this.language },
      });
      popupRef.afterClosed().subscribe(_data => {
        if (_data.agree) {
          this.navigateToConfirmation(elementsToSubmitTmp);
        }
      });
    }
  }

  splitElements(employerResponseData: AgentEmployerResponseData[]) {
    const len = employerResponseData.length;
    const splitPages = Math.ceil(len / 10);
    const splitPageSize = 10;
    localStorage.setItem('delegateTotal', len + '');
    localStorage.setItem('delegatePages', splitPages + '');
    for (let i = 1; i <= splitPages; i++) {
      const strPerPage = JSON.stringify(this.arrayPage(employerResponseData, i, splitPageSize));
      localStorage.setItem('page' + i, strPerPage);
    }
  }

  addAgentNameForConfirmation(employerResponseData: AgentEmployerResponseData[]) {
    employerResponseData.forEach(ele => {
      const found = this.agentCodeNameArr.find(ac => ac.agentCode === ele.agentCode);
      ele.toDelegateAgentName = found?.agentName;
      ele.toDelegateAgentNameTC = found?.agentNameTC;
    });
  }

  arrayPage(employerResponseData: AgentEmployerResponseData[], page: number, pageSize: number): AgentEmployerResponseData[] {
    if (page <= 0) {
      return [];
    }
    const minIdx = pageSize * (page - 1);
    const maxIdx = pageSize * page;

    return employerResponseData.slice(minIdx, maxIdx);
  }

  clearItems(): void {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const lsPages = localStorage.getItem('delegatePages');
    if (lsPages) {
      const pages = parseInt(lsPages);
      for (let i = 1; i <= pages; i++) {
        localStorage.removeItem('page' + i);
      }
    }
    localStorage.removeItem('delegatePages');
    localStorage.removeItem('delegateTotal');
  }

  displayedColumnsInConfirmation: string[] = ['employerName', 'employerNo', 'employeeAmt', 'delegateAmt', 'nonDelegateAmt', 'delegateAgentInfo'];

  pageChangeInConfirmation(num: number): void {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const pageInfoByNum = localStorage.getItem('page' + num)!;
    this.dataSourceInConfirmation = JSON.parse(pageInfoByNum);
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const totalItems = parseInt(localStorage.getItem('delegateTotal')!);
    this.pageCountSbms = Math.ceil(totalItems / 10);
    this.totalCountSbms = totalItems;
    this.pageSbms = num;
    this.searchValueSub = num + '';
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  searchByInputPageNoInConfirmation($event: any, pageNo: string) {
    $event.preventDefault();
    const intPageNo: number = parseInt(pageNo);
    if (intPageNo > this.pageCountSbms) {
      this.searchValueDlgt = '1';
      pageNo = '1';
    }
    this.pageChangeInConfirmation(parseInt(pageNo));
  }

  erBackToEdit() {
    this.isInWhichStage = 1;
    this.elementsToSubmit = [];
    this.clearItems();
    setTimeout(() => {
      if (this.stepperTarget === 'ipad') {
        this.stepper.setStepList(this.stepList);
      }

      this.refreshStep();
    }, 50);
  }

  erConfirm() {
    const submitDelegateRequestArr: SubmitDelegateRequest[] = [];
    if (this.elementsToSubmit.length > 0) {
      const popupRef: MatDialogRef<UserConfirmPopComponent> = this.cdsPopup.open(UserConfirmPopComponent, {});

      popupRef.afterClosed().subscribe(data => {
        if (data.agree) {
          this.elementsToSubmit.forEach(element => {
            const submitDelegateRequestTmp = new SubmitDelegateRequest();
            submitDelegateRequestTmp.employerId = element.employerId;
            submitDelegateRequestTmp.agentCode = element.agentCode;
            submitDelegateRequestArr.push(submitDelegateRequestTmp);
          });
          this.agentDelegationSubscription?.unsubscribe();
          this.notifyService.screenLoadingSubject.next(true);
          this.agentDelegationSubscription = this.agentEmployerDelegationService.confirmSubmitDelegation(submitDelegateRequestArr);
          this.agentEmployerDelegationService.agentDelegationSubject.subscribe({
            next: resp => {
              if (resp.result === 0) {
                const popupRefSubmit: MatDialogRef<UserSuccessSubmittedComponent> = this.cdsPopup.open(UserSuccessSubmittedComponent, {});
                popupRefSubmit.afterClosed().subscribe(dataConfirm => {
                  if (dataConfirm.agree) {
                    // page should reload
                    location.reload();
                  }
                });
              } else if (resp.result !== 0) {
                //this.addAlertItems(this.checkResultList);
              }
              this.notifyService.screenLoadingSubject.next(false);
            },
            error: () => {
              this.notifyService.screenLoadingSubject.next(false);
            },
          });
        }
      });
    }
  }

  jump() {
    window.open(
      this.cdsLangService.currentLang === 'en'
        ? 'https://ebmeter.com/minisite/download/campaign/delegation_golden_rule_en.pdf'
        : 'https://ebmeter.com/minisite/download/campaign/delegation_golden_rule.pdf'
    );
  }

  computedDelegateAgentInfoItem(item: EmployerDelegateAgentInfo) {
    return `${item.agentCode} ${this.language === 'EN' ? item.name : item.nameTC}`;
  }

  onCanDelegateAmtToggle(event: MouseEvent) {
    if (event.target && this.stepperTarget === 'pc') {
      event.target.dispatchEvent(new Event('click'));
    }
  }

  computedIsHalfSelected() {
    const isSelectedAll = this.dataSourceInDelegate.every(item => item.isSelected);
    if (isSelectedAll) {
      return false;
    }

    const isSelectedSome = this.dataSourceInDelegate.some(item => item.isSelected);
    if (isSelectedSome) {
      return true;
    }

    return false;
  }

  goDelegationChange() {
    this.isShowButtonList = false;
    this.router.navigate(['../', 'er-delegation-change'], {
      relativeTo: this.route,
    });
  }

  goDelegationChangeRequestLog() {
    this.isShowButtonList = false;
    this.router.navigate(['/agent/employer-history']);
  }

  navigateToEEAddDelegation(employerId: string, employerName: string, employerNameTC: string) {
    const employerNameParam = employerName ? employerName : '';
    const employerNameParamTC = employerNameTC ? employerNameTC : '';
    return (
      '/agent/employee-summary/' +
      employerId +
      '?en=' +
      encodeURI(specialCharacter(employerNameParam)) +
      '&zh=' +
      encodeURI(specialCharacter(employerNameParamTC))
    );
  }

  private onResetTable() {
    this.resetTable = true;
    setTimeout(() => {
      this.resetTable = false;
    }, 0);
  }

  refreshStep() {
    let stepNum = 1;

    if (this.fullDataSource.some(item => item.data.some(agentEmployerData => agentEmployerData.isSelected))) {
      stepNum = 2;
    }

    if (stepNum === 2 && this.inputAgentCode.length > 0) {
      stepNum = 3;
    }

    if (this.fullDataSource.some(item => item.data.some(agentEmployerData => agentEmployerData.agentCode && agentEmployerData.agentCode.length === 6))) {
      stepNum = 4;
    }

    this.stepper.setFinishStep(stepNum);
  }

  get isDisableBatchInput() {
    return !this.fullDataSource.some(item => item.data.some(agentEmployerData => agentEmployerData.isSelected));
  }

  get isDisableBatchApplyAgentCode() {
    return !(this.fullDataSource.some(item => item.data.some(agentEmployerData => agentEmployerData.isSelected)) && this.inputAgentCode.length > 0);
  }

  get isDisableSubmit() {
    return !this.fullDataSource.some(item => item.data.some(agentEmployerData => agentEmployerData.agentCode && agentEmployerData.agentCode.length === 6));
  }
}
