import { CdsButtonConfig } from '@cds/ng-core/button';

export const BTN_CONFIG_PRIMARY: CdsButtonConfig = {
  style: 'primary',
};

export const BTN_CONFIG_SECONDARY: CdsButtonConfig = {
  style: 'secondary',
};

export const BTN_CONFIG_TERTIARY: CdsButtonConfig = {
  style: 'tertiary',
};
