import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { WelcomeComponent } from './views/welcome/welcome.component';

import { ScreenCheckService } from './views/agent/screen-check/screen-check.service';
import { AdminLayoutComponent } from './views/layout/admin-layout/admin-layout.component';

const routes: Routes = [
  { path: '', redirectTo: 'user/login', pathMatch: 'full' },
  {
    path: 'home',
    component: AdminLayoutComponent,
    children: [
      { path: 'welcome', component: WelcomeComponent },
      { path: '', component: WelcomeComponent },
    ],
  },
  {
    path: 'user',
    loadChildren: () => import('./views/user/user.module').then(m => m.UserModule),
  },
  {
    path: 'error',
    loadChildren: () => import('./views/error/error.module').then(m => m.ErrorModule),
  },
  {
    path: 'success',
    loadChildren: () => import('./views/success/success.module').then(m => m.SuccessModule),
  },
  {
    path: 'agent',
    canLoad: [ScreenCheckService],
    loadChildren: () => import('./views/agent/agent.module').then(m => m.AgentModule),
  },
  // {
  //   path: 'system',
  //   loadChildren: () => import('./views/system/system.module').then(m => m.SystemModule),
  // },
  {
    path: 'chatbot',
    loadChildren: () => import('./views/chatbot/angent/angent.module').then(m => m.AngentModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { anchorScrolling: 'enabled' })],
  exports: [RouterModule],
  providers: [ScreenCheckService],
})
export class RoutesRoutingModule {}
