<cds-table [dataSource]="dataSource" [class.full]="true">
  <ng-container cdsColumnDef="expand">
    <cds-header-cell *cdsHeaderCellDef></cds-header-cell>
    <cds-cell *cdsCellDef="let element">
      <button *ngIf="element.expand !== undefined && !element.expand" class="icon-button" (click)="element['expand'] = !element['expand']">
        <cds-icon icon="action:button_right"></cds-icon>
      </button>
      <button *ngIf="element.expand !== undefined && element.expand" class="icon-button" (click)="element['expand'] = !element['expand']">
        <cds-icon icon="action:button_down"></cds-icon>
      </button>
    </cds-cell>
  </ng-container>

  <ng-container cdsColumnDef="permissionName">
    <cds-header-cell *cdsHeaderCellDef>{{ 'role.form.header.siteArea' | lang }} </cds-header-cell>
    <cds-cell *cdsCellDef="let permission">
      <div *ngIf="permission.groupItem; else subItemTitle">
        {{ permission.groupItem }}
      </div>
      <ng-template #subItemTitle>
        <div class="l-pl-5">
          {{ permission.permissionItem }}
        </div>
      </ng-template>
    </cds-cell>
  </ng-container>

  <ng-container cdsColumnDef="execute">
    <cds-header-cell *cdsHeaderCellDef>{{ 'role.form.header.execute' | lang }} </cds-header-cell>
    <cds-cell *cdsCellDef="let permission">
      <cds-checkbox
        [disabled]="readOnly"
        *ngIf="permission.groupItem; else subItemTempE"
        [checked]="isGroupCheck(permissionAccess.E, permission.groupItem)"
        [indeterminate]="isPartially(permissionAccess.E, permission.groupItem)"
        (change)="changeGroup($event, permissionAccess.E, permission)">
      </cds-checkbox>
      <ng-template #subItemTempE>
        <cds-checkbox
          [disabled]="readOnly"
          [checked]="isCheck(permissionAccess.E, permission.permissionItem)"
          (change)="changePermission($event, permissionAccess.E, permission)">
        </cds-checkbox>
      </ng-template>
    </cds-cell>
  </ng-container>

  <ng-container cdsColumnDef="edit">
    <cds-header-cell *cdsHeaderCellDef>
      {{ 'role.form.header.edit' | lang }}
    </cds-header-cell>
    <cds-cell *cdsCellDef="let permission">
      <cds-checkbox
        [disabled]="readOnly"
        *ngIf="!!permission.groupItem; else subItemTempW"
        [checked]="isGroupCheck(permissionAccess.W, permission.groupItem)"
        [indeterminate]="isPartially(permissionAccess.W, permission.groupItem)"
        (change)="changeGroup($event, permissionAccess.W, permission)">
      </cds-checkbox>
      <ng-template #subItemTempW>
        <cds-checkbox
          [disabled]="readOnly"
          [checked]="isCheck(permissionAccess.W, permission.permissionItem)"
          (change)="changePermission($event, permissionAccess.W, permission)">
        </cds-checkbox>
      </ng-template>
    </cds-cell>
  </ng-container>

  <ng-container cdsColumnDef="read">
    <cds-header-cell *cdsHeaderCellDef>
      {{ 'role.form.header.read' | lang }}
    </cds-header-cell>
    <cds-cell *cdsCellDef="let permission">
      <cds-checkbox
        [disabled]="readOnly"
        *ngIf="!!permission.groupItem; else subItemTempR"
        [checked]="isGroupCheck(permissionAccess.R, permission.groupItem)"
        [indeterminate]="isPartially(permissionAccess.R, permission.groupItem)"
        (change)="changeGroup($event, permissionAccess.R, permission)">
      </cds-checkbox>
      <ng-template #subItemTempR>
        <cds-checkbox
          [disabled]="readOnly"
          [checked]="isCheck(permissionAccess.R, permission.permissionItem)"
          (change)="changePermission($event, permissionAccess.R, permission)">
        </cds-checkbox>
      </ng-template>
    </cds-cell>
  </ng-container>

  <ng-container cdsColumnDef="noAccess">
    <cds-header-cell *cdsHeaderCellDef>
      {{ 'role.form.header.noAccess' | lang }}
    </cds-header-cell>
    <cds-cell *cdsCellDef="let permission">
      <div *ngIf="!!permission.groupItem; else subItemTempN">
        <cds-checkbox
          [disabled]="readOnly || isGroupCheck(null, permission.groupItem)"
          [checked]="isGroupCheck(null, permission.groupItem)"
          [indeterminate]="isPartially(null, permission.groupItem)"
          (change)="changeGroup($event, null, permission)">
        </cds-checkbox>
      </div>
      <ng-template #subItemTempN>
        <cds-checkbox
          [disabled]="readOnly || isCheck(null, permission.permissionItem)"
          [checked]="isCheck(null, permission.permissionItem)"
          (change)="changePermission($event, null, permission)">
        </cds-checkbox>
      </ng-template>
    </cds-cell>
  </ng-container>

  <cds-header-row *cdsHeaderRowDef="accessColumns"></cds-header-row>
  <cds-row
    *cdsRowDef="let row; let i = index; columns: accessColumns"
    [class.row-expaned]="row.expand === undefined"
    [ngStyle]="{ display: hiddenRow(row) ? 'none' : 'table-row' }">
  </cds-row>
  {{ data | json }}
</cds-table>
