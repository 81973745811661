<div class="row">
  <div class="col-xs-12 l-pt-7">
    <span class="cds-h2-light">{{ 'common.searchRecord' | lang }}</span>
  </div>

  <div [formGroup]="form" *ngIf="formReady">
    <div class="col-xs-12 l-pt-7">
      <span class="cds-h2-light">{{ 'list.serach-record' | lang }}</span>
    </div>
    <div class="row l-plr-0">
      <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 l-pt-4 l-pb-4">
        <cds-textfield formControlName="campaignName" label="Campaign Name" placeholder="Input Campaign Name"></cds-textfield>

        <div *ngIf="campaignNameControl?.invalid && (campaignNameControl?.dirty || campaignNameControl?.touched)" class="l-pt-2">
          <cds-assistive-text *ngIf="campaignNameControl?.errors?.['maxlength']" class="l-pt-2" [showIcon]="false">Max length is 100</cds-assistive-text>

          <cds-assistive-text
            *ngIf="campaignNameControl?.errors?.['descriptionInvalid']"
            class="l-pt-2"
            [showIcon]="false"
            >{{ campaignNameControl?.errors?.['descriptionInvalid'] }}</cds-assistive-text
          >
        </div>
      </div>
      <div class="row col-xs-12 col-sm-12 col-md-8 col-lg-6 l-pt-4 l-pb-4 l-plr-0 date-picker-area relative">
        <div class="col-xs-6 date-picker">
          <cds-textfield
            formControlName="campaignPeriodFrom"
            placeholder="DD/MM/YYYY"
            label="Termination Date From"
            [max]="getMaxFromDate | fn : campaignPeriodToControl?.value || 'maxTimeInStr'"
            [cdsDatepicker]="startPicker">
          </cds-textfield>
          <cds-datepicker #startPicker="cdsDatepicker"></cds-datepicker>

          <div *ngIf="campaignPeriodFromControl?.invalid && (campaignPeriodFromControl?.dirty || campaignPeriodFromControl?.touched)" class="l-pt-2">
            <cds-assistive-text
              *ngIf="campaignPeriodFromControl?.errors?.['dateInvalid'] || campaignPeriodFromControl?.errors?.['startLaterThanEnd']"
              class="l-pt-2"
              [showIcon]="false"
              >{{ 'create.dateError' | lang }}</cds-assistive-text
            >
          </div>
        </div>
        <div class="connector">-</div>
        <div class="col-xs-6 date-picker">
          <cds-textfield
            formControlName="campaignPeriodTo"
            placeholder="DD/MM/YYYY"
            label="To"
            [min]="getMinToDate | fn : campaignPeriodFromControl?.value"
            [cdsDatepicker]="endPicker">
          </cds-textfield>
          <cds-datepicker #endPicker="cdsDatepicker"></cds-datepicker>

          <div *ngIf="campaignPeriodToControl?.invalid && (campaignPeriodToControl?.dirty || campaignPeriodToControl?.touched)" class="l-pt-2">
            <cds-assistive-text
              *ngIf="campaignPeriodToControl?.errors?.['dateInvalid']  || campaignPeriodToControl?.errors?.['startLaterThanEnd']"
              class="l-pt-2"
              [showIcon]="false"
              >{{ 'create.dateError' | lang }}</cds-assistive-text
            >
          </div>
        </div>
      </div>

      <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 l-pt-4 l-pb-4">
        <cds-textfield formControlName="campaignOwner" label="Campaign Owner" placeholder="Input Campaign Owner"></cds-textfield>

        <div *ngIf="campaignOwnerControl?.invalid && (campaignOwnerControl?.dirty || campaignOwnerControl?.touched)" class="l-pt-2">
          <cds-assistive-text *ngIf="campaignOwnerControl?.errors?.['maxlength']" class="l-pt-2" [showIcon]="false">Max length is 200</cds-assistive-text>

          <cds-assistive-text
            *ngIf="campaignOwnerControl?.errors?.['descriptionInvalid']"
            class="l-pt-2"
            [showIcon]="false"
            >{{ campaignOwnerControl?.errors?.['descriptionInvalid'] }}</cds-assistive-text
          >
        </div>
      </div>

      <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 l-pt-4 l-pb-4 my-content">
        <cds-dropdown formControlName="campaignType" [config]="campaignTypeConfig"></cds-dropdown>
      </div>

      <div class="row col-xs-12 col-sm-12 col-md-6 col-lg-3 bottom-xs l-pb-4 l-plr-0">
        <cds-button
          class="l-mr-4 botton"
          [disabled]="searchButtonDisabled"
          [config]="searchButtonConfig"
          label="{{ 'list.search' | lang }}"
          (click)="search()"></cds-button>
        <cds-button
          class="botton"
          [disabled]="resetButtonDisabled"
          [config]="resetButtonConfig"
          label="{{ 'list.reset' | lang }}"
          (click)="reset()"></cds-button>
      </div>
    </div>
  </div>

  <div class="col-xs-12 col-md-12 l-pb-3 l-d-f l-jc-sb l-mt-7">
    <div>
      <div class="cds-h2-light l-ma-0">{{ 'common.recordList' | lang }}</div>
      <div class="cds-detail1-demibold">Total {{ totalElements }} records</div>
    </div>
    <div *ngIf="canEdit">
      <cds-button [config]="addBtnCfg" size="sm" class="l-mr-5" (click)="add()"> Add New Campaign </cds-button>
      <cds-button [config]="uploadBtnCfg" size="sm" (click)="upload()"> Upload Customer List </cds-button>
    </div>
  </div>

  <div class="col-xs-12 table-container" *ngIf="!isMobile">
    <app-data-table
      [frontPagination]="false"
      [isLoading]="isLoading"
      [pageConfig]="pageConfig"
      [fixed]="false"
      [overlay]="true"
      [dataSource]="dataSource"
      [columnsConfig]="columnsConfig"
      (reloadTable)="reloadTable($event)">
      <ng-template appDtBodyCellDef="campaignName" let-element>
        <div class="name-container">
          <div class="click l-mr-6" (click)="edit(element)">
            <cds-icon size="sm" icon="action:edit"></cds-icon>
          </div>
          <div>
            <div class="cds-body1 l-mb-0 font-weight-6">
              <span class="green-line">
                <span>{{ element.campaignId }} </span><span>{{ element.campaignName }}</span>
              </span>
            </div>
            <div class="cds-body2 l-mb-0 l-mt-1">
              <span>{{ dateTransToDisplay | fn : element.campaignPeriodFrom }} </span>-<span> {{ dateTransToDisplay | fn : element.campaignPeriodTo }}</span>
            </div>
          </div>
        </div>
      </ng-template>

      <ng-template appDtBodyCellDef="campaignType" let-element>
        <div>
          {{ findOptionLabelByValue | fn : element.campaignType : CampaignTypeOptions }}
        </div>
      </ng-template>

      <ng-template appDtBodyCellDef="productTypes" let-element>
        <div>
          {{ listValueRender | fn : element.productTypes : productTypeOptions }}
        </div>
      </ng-template>

      <ng-template appDtBodyCellDef="multipleAgentDisplay" let-element>
        <div>
          {{ findOptionLabelByValue | fn : element.multipleAgentDisplay : yesOrNoFlagNumberOptions }}
        </div>
      </ng-template>

      <ng-template appDtBodyCellDef="displayToServicingAgent" let-element>
        <div>
          {{ findOptionLabelByValue | fn : element.displayToServicingAgent : yesOrNoFlagNumberOptions }}
        </div>
      </ng-template>

      <ng-template appDtBodyCellDef="agentCommunication" let-element>
        <div class="communication-container">
          <div class="icon-area">
            <cds-icon size="sm" icon="device:mobile_laptop" class="l-mr-2"></cds-icon>
          </div>
          <span class="cds-detail1 l-mb-0 l-ml-1 font-weight-6">{{ listValueRender | fn : element.agentCommunication : agentCommunicationOptions }}</span>
        </div>
      </ng-template>

      <ng-template appDtBodyCellDef="links" let-element>
        <div class="click flex l-mb-2" *ngIf="element.workbasket" (click)="jumpTo(element.workbasket)">
          <cds-icon color="#EC6453" size="sm" icon="action:right_fill" class="l-mr-2"></cds-icon>
          <span class="cds-body2 l-mb-0 font-weight-6">Workbasket</span>
        </div>
        <div class="click flex" *ngIf="element.agentSam" (click)="jumpTo(element.agentSam)">
          <cds-icon color="#EC6453" size="sm" icon="action:right_fill" class="l-mr-2"></cds-icon>
          <span class="cds-body2 l-mb-0 font-weight-6">AgentSAM</span>
        </div>
      </ng-template>
    </app-data-table>
  </div>
</div>
