<div class="split-agent-notification-info-error">
  <div class="emotion-sad-box">
    <cds-icon class="icon-emotion-sad" icon="emotion:sad"></cds-icon>
  </div>
  <div>
    <span class="cds-h2-demibold">
      {{ 'common.splitAgentNotificationInfoError' | lang }}
    </span>
  </div>
</div>
