import { Component, EventEmitter, OnInit, Output, Input, AfterViewInit } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-cd-time-picker',
  templateUrl: './cd-time-picker.component.html',
  styleUrls: ['./cd-time-picker.component.scss'],
})
export class CdTimePickerComponent implements OnInit, AfterViewInit {
  _max?: number[] | null;
  _min?: number[] | null;
  @Input() formControl?: FormControl;
  @Input()
  set max(max: number[] | null) {
    this._max = max;
    if (this._max) {
      this.maxHour = this._max[0];
      this.maxMinute = this._max[1];
    } else {
      this.maxHour = undefined;
      this.maxMinute = undefined;
    }
  }
  @Input()
  set min(min: number[] | null) {
    this._min = min;
    if (this._min) {
      this.minHour = this._min[0];
      this.minMinute = this._min[1];
    } else {
      this.minHour = undefined;
      this.minMinute = undefined;
    }
  }
  @Input() error = false;
  @Output() timeChange = new EventEmitter<string>();
  @Output() openedCall = new EventEmitter<void>();
  hourList: string[] = [];
  minList: string[] = [];
  hour = '';
  minute = '';
  open = false;
  maxHour?: number;
  maxMinute?: number;
  minHour?: number;
  minMinute?: number;
  isEnableMinuteDisable = false;

  constructor() {}

  ngOnInit(): void {
    for (let i = 0; i < 24; i++) {
      this.hourList.push(`${i < 10 ? '0' + i : i}`);
    }

    for (let i = 0; i < 60; i++) {
      this.minList.push(`${i < 10 ? '0' + i : i}`);
    }
    if (this.formControl && this.formControl.value != null) {
      const [hours, minutes] = this.formControl.value.split(':');
      this.hour = hours;
      this.minute = minutes;

      if (this.formControl.value.includes(':')) {
        this.formControl.valueChanges.subscribe(val => {
          const [hours, minutes] = val.split(':');
          this.hour = hours;
          this.minute = minutes;
        });
      } else {
        this.setformControlValue('');
      }
    }
  }

  ngAfterViewInit(): void {}

  onOutsideEvent() {
    if (this.open) {
      this.open = false;
    }
  }

  onToggel() {
    this.open = !this.open;
    if (this.open) {
      this.openedCall.emit();
    }
  }

  selectedHour(hour: string) {
    this.hour = hour;
    this.minute = '00';
    if (this.minMinute !== undefined && this.minHour !== undefined) {
      if (+this.hour === this.minHour) {
        if (this.minMinute < 59) {
          this.minMinute++;
          this.minute = this.minMinute < 10 ? `0${this.minMinute}` : `${this.minMinute}`;
        }
      }
    }

    this.timeChange.emit(this.time);
    this.setformControlValue(this.time);

    if (this.maxHour && +this.hour === this.maxHour) {
      this.isEnableMinuteDisable = true;
    } else if (this.minHour && +this.hour === this.minHour) {
      this.isEnableMinuteDisable = true;
    } else {
      this.isEnableMinuteDisable = false;
    }
  }

  selectedMin(min: string) {
    this.minute = min;

    if (this.hour === '') {
      this.hour = '00';
    }

    this.timeChange.emit(this.time);
    this.setformControlValue(this.time);
  }

  setformControlValue(value: string) {
    this.formControl?.setValue(value);
    this.formControl?.markAsDirty();
    this.formControl?.markAsTouched();
  }

  clearValue() {
    this.hour = '';
    this.minute = '';
  }

  get time() {
    if (this.hour === '' || this.minute === '') {
      return '';
    }
    return `${this.hour}:${this.minute}`;
  }
}
