<div class="row">
  <div class="col-xs-12 l-pt-5 cds-color-green">
    <span class="cds-h5-demibold">Account Balance</span>
  </div>
  <div class="col-xs-12">
    <div class="p-line"></div>
  </div>
</div>

<div class="row">
  <div class="col-xs-12 l-pt-5 overflow-auto">
    <div class="count-container">
      <div>
        <div class="l-pb-2"><span class="cds-body2-demibold">Account Balance</span></div>
        <div class="row l-plr-0 middle-xs">
          <div class="l-pr-2"><span>HKD</span></div>
          <div class="color-green">
            <span class="cds-h2-demibold">{{ totalCount | fn : data | number : '1.2-2' }}</span>
          </div>
        </div>
      </div>
      <div class="l-pl-9 l-pr-9"><span class="cds-h2-demibold">=</span></div>

      <div>
        <div class="l-pb-2"><span class="cds-body2">Forfeiture Account</span></div>
        <div class="row l-plr-0 middle-xs">
          <div class="l-pr-2"><span class="cds-h5-demibold">HKD</span></div>
          <div>
            <span class="cds-h5-demibold">{{ data.forfeitureAccount | number : '1.2-2' }}</span>
          </div>
        </div>
      </div>

      <div class="l-pl-9 l-pr-9"><span class="cds-h2-demibold">+</span></div>

      <div>
        <div class="l-pb-2"><span class="cds-body2">Reserved Account</span></div>
        <div class="row l-plr-0 middle-xs">
          <div class="l-pr-2"><span class="cds-h5-demibold">HKD</span></div>
          <div>
            <span class="cds-h5-demibold">{{ data.reservedAccount | number : '1.2-2' }}</span>
          </div>
        </div>
      </div>

      <div class="l-pl-9 l-pr-9"><span class="cds-h2-demibold">+</span></div>

      <div>
        <div class="l-pb-2"><span class="cds-body2">Available Amount</span></div>
        <div class="row l-plr-0 middle-xs">
          <div class="l-pr-4"><span class="cds-h5-demibold">HKD</span></div>
          <div>
            <span class="cds-h5-demibold">{{ data.suspenseRecord | number : '1.2-2' }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-xs-12 l-pt-7">
    <span class="cds-h2-light">{{ 'er.search-record-for-suspense' | lang }}</span>
  </div>
  <div class="row col-xs-12 l-plr-0" [formGroup]="form">
    <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 l-pt-4 l-pb-4">
      <cds-textfield
        formControlName="receiptReferenceNo"
        #receiptReferenceNo
        [(ngModel)]="searchDTO.receiptReferenceNo"
        (input)="inputChange(receiptReferenceNo.value, 'receiptReferenceNo')"
        (compositionend)="inputChange(receiptReferenceNo.value, 'receiptReferenceNo')"
        label="Receipt Reference No."
        placeholder="Input Receipt Reference No."></cds-textfield>

      <div *ngIf="receiptReferenceNoControl?.invalid && (receiptReferenceNoControl?.dirty || receiptReferenceNoControl?.touched)" class="l-pt-2">
        <cds-assistive-text *ngIf="receiptReferenceNoControl?.errors?.['maxlength']" class="l-pt-2" [showIcon]="false">Max length is 35</cds-assistive-text>
      </div>
    </div>
    <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 l-pt-4 l-pb-4">
      <cds-textfield
        formControlName="paymentSubmissionReferenceNo"
        label="Payment Submission Reference No."
        #paymentSubmissionReferenceNo
        [(ngModel)]="searchDTO.paymentSubmissionReferenceNo"
        (input)="inputChange(paymentSubmissionReferenceNo.value, 'paymentSubmissionReferenceNo')"
        (compositionend)="inputChange(paymentSubmissionReferenceNo.value, 'paymentSubmissionReferenceNo')"
        placeholder="Input Payment Submission Reference No."></cds-textfield>

      <div
        *ngIf="paymentSubmissionReferenceNoControl?.invalid && (paymentSubmissionReferenceNoControl?.dirty || paymentSubmissionReferenceNoControl?.touched)"
        class="l-pt-2">
        <cds-assistive-text *ngIf="paymentSubmissionReferenceNoControl?.errors?.['maxlength']" class="l-pt-2" [showIcon]="false"
          >Max length is 50</cds-assistive-text
        >
      </div>
    </div>

    <div *ngIf="showDatePicker" class="col-xs-12 col-sm-6 col-md-4 col-lg-3 l-pt-4 l-pb-4">
      <cds-textfield
        formControlName="paymentReceivedDate"
        placeholder="DD/MM/YYYY"
        label="Payment Received Date"
        [max]="getNowTime()"
        [(ngModel)]="searchDTO.paymentReceivedDate"
        (ngModelChange)="validating()"
        [cdsDatepicker]="datePicker">
      </cds-textfield>
      <cds-datepicker #datePicker="cdsDatepicker"></cds-datepicker>

      <div *ngIf="paymentReceivedDateControl?.invalid && (paymentReceivedDateControl?.dirty || paymentReceivedDateControl?.touched)" class="l-pt-2">
        <cds-assistive-text *ngIf="paymentReceivedDateControl?.errors?.['dateInvalid']" class="l-pt-2" [showIcon]="false">{{
          'create.dateError' | lang
        }}</cds-assistive-text>
      </div>
    </div>

    <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 l-pt-4 l-pb-4">
      <cds-dropdown
        formControlName="paymentMethod"
        [(ngModel)]="searchDTO.paymentMethod"
        (ngModelChange)="inputChange($event, 'paymentMethod')"
        [config]="methodConfig"></cds-dropdown>
    </div>

    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 l-mt-4 l-pb-4 bottom-xs l-pt-4">
      <cds-button
        class="l-mr-4 botton"
        [disabled]="!form.valid || !_valid"
        [config]="searchButtonConfig"
        label="{{ 'list.search' | lang }}"
        (click)="searchFn()"></cds-button>
      <cds-button class="botton" [disabled]="!_searched" [config]="resetButtonConfig" label="{{ 'list.reset' | lang }}" (click)="reset()"></cds-button>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-xs-12 col-md-12 l-pb-3 l-d-f l-jc-sb l-mt-7 l-mb-4">
    <div>
      <div class="cds-h2-light l-ma-0">{{ 'common.recordList' | lang }}</div>
      <div class="cds-detail1-demibold">Total {{ numberOfElements | number }} records</div>
    </div>
  </div>
</div>

<div class="row">
  <div #tableBox class="col-xs-12 col-md-12 itable" [ngClass]="[checkIsHaveScroll ? 'shadow' : '']">
    <app-data-table
      [frontPagination]="false"
      [isLoading]="isLoading"
      [fixed]="false"
      [overlay]="true"
      [pageConfig]="pageConfig"
      [dataSource]="dataSource"
      [columnsConfig]="columnsConfig"
      (reloadTable)="reloadTable($event)">
      <ng-template class="test" appDtBodyCellDef="receiptReferenceNo" let-element>
        <span class="click" (click)="toDetail(element['paymentSubmissionReferenceNo'], element['receiptReferenceNo'])">{{
          element['receiptReferenceNo']
        }}</span>
      </ng-template>

      <ng-template appDtBodyCellDef="paymentSubmissionReferenceNo" let-element>
        <span class="click" (click)="toDetail(element['paymentSubmissionReferenceNo'])">{{ element['paymentSubmissionReferenceNo'] }}</span>
      </ng-template>

      <ng-template appDtBodyCellDef="paymentReceivedDate" let-element>
        <span *ngIf="element['paymentReceivedDate']">{{ element['paymentReceivedDate'] | ssmsDate }}</span>
      </ng-template>

      <ng-template appDtBodyCellDef="receivedAmount" let-element>
        <span *ngIf="element['receivedAmount']">{{ element['receivedAmount'] | number }}</span>
      </ng-template>

      <ng-template appDtBodyCellDef="suspenseAmount" let-element>
        <span *ngIf="element['suspenseAmount']">{{ element['suspenseAmount'] | number }}</span>
      </ng-template>

      <ng-template appDtBodyCellDef="availableAmount" let-element>
        <span *ngIf="element['availableAmount']">{{ element['availableAmount'] | number }}</span>
      </ng-template>

      <ng-template appDtBodyCellDef="taggedAmount" let-element>
        <span *ngIf="element['taggedAmount']">{{ element['taggedAmount'] | number }}</span>
      </ng-template>

      <ng-template appDtBodyCellDef="overpaidAmount" let-element>
        <span *ngIf="element['overpaidAmount']">{{ element['overpaidAmount'] | number }}</span>
      </ng-template>

      <ng-template appDtBodyCellDef="paymentMethod" let-element>
        <span>{{ paymentMethodRender | fn : element['paymentMethod'] }}</span>
      </ng-template>
    </app-data-table>
  </div>
</div>
