<div class="employee-summary">
  <div class="l-pt-6">
    <div class="title">
      <span class="cds-h3-bold dlgt-header">{{ 'employer.delegationPlatform' | lang }}</span>
      <!-- <cds-icon
        (click)="isShowButtonList = true"
        [icon]="moreOptions"
        class="more-options"></cds-icon> -->
      <!-- <div *ngIf="isShowButtonList" class="button-list">
        <span class="cds-detail2-demibold" (click)="goEEDelegationChange()">{{
          'delegationChange.delegationChangeInEELevel' | lang
        }}</span>
        <div class="line"></div>
        <span
          class="cds-detail2-demibold"
          (click)="goEEDelegationChangeRequestLog()"
          >{{ 'employer.delegationChangeRequestLog' | lang }}</span
        >
        <div class="mask" (click)="isShowButtonList = false"></div>
      </div> -->
    </div>
    <div class="row current-name">
      <span>
        <cds-icon
          (click)="onCackErIconClick()"
          class="dlgt-back-icon"
          [ngClass]="pageShowState === 1 ? '' : 'disable-back'"
          [config]="backErIconConfig"
          [icon]="backErIcon">
        </cds-icon>
      </span>
      <span (click)="onCackErIconClick()" class="cds-h4-demibold dlgt-back-employer-name" [ngClass]="pageShowState === 1 ? '' : 'disable-back'">{{
        language === 'EN' ? (employerName ? employerName : employerNameTC) : employerNameTC ? employerNameTC : employerName
      }}</span>
    </div>
    <div class="cds-body2 dlgt-header-desc">
      <div *ngIf="pageShowState !== 3">{{ 'employer.eeDesc' | lang }}</div>
      <div class="remarks-box">
        <div>{{ 'employer.remarks' | lang }} :</div>
        <ul class="remarks-desc-box">
          <li>
            <div class="remarks-desc2">
              <span>{{ 'employer.remarksDesc2.paragraph1' | lang }}</span>
              <span class="paragraph2">{{ 'employer.remarksDesc2.paragraph2' | lang }}</span>
            </div>
          </li>
          <li>
            <span>{{ 'employer.remarksDesc3' | lang }}</span>
          </li>
          <li>
            <span>{{ 'employer.remarksDesc4' | lang }}</span>
          </li>
          <li>
            <span>{{ 'employer.remarksDesc5_1' | lang }} </span>
            <span class="text-link" (click)="jump()">{{ 'employer.remarksDesc5_2' | lang }}</span>
            <span> {{ 'employer.remarksDesc5_3' | lang }}</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div *ngIf="pageShowState === 1">
    <div class="cds-body-hero-light dlgt-search-and-result">
      <span>{{ 'employer.search' | lang }} </span>
    </div>
    <div class="row search-box">
      <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12 search-box-item">
        <app-cd-input
          [(value)]="nameValue"
          (valueChange)="nameChange($event)"
          [placeholder]="'employer.searchPlaceHolder' | lang"
          [label]="'employee.employeeName' | lang"></app-cd-input>
      </div>
      <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12 search-box-item">
        <app-cd-input
          [(value)]="numberValue"
          (valueChange)="numberChange($event)"
          [placeholder]="'employer.searchPlaceHolder' | lang"
          [label]="'employee.no' | lang"></app-cd-input>
      </div>
      <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12 search-box-item">
        <app-cd-dropdown
          #aggCdDropdown
          *ngIf="language === 'EN'"
          [label]="'employee.ageGroup'"
          [placeholder]="'employer.checkAge'"
          [toppingList]="ageEnRangeDropdownConfig"
          (selectedChange)="ageChange($event)"></app-cd-dropdown>
        <app-cd-dropdown
          #aggCdDropdown
          *ngIf="language !== 'EN'"
          [label]="'employee.ageGroup'"
          [placeholder]="'employer.checkAge'"
          [toppingList]="ageZhRangeDropdownConfig"
          (selectedChange)="ageChange($event)"></app-cd-dropdown>
      </div>

      <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12 search-box-item">
        <app-cd-dropdown
          #genderCdDropdown
          *ngIf="language === 'EN'"
          [label]="'employee.gender'"
          [placeholder]="'employer.checkGender'"
          [toppingList]="genderEnDropdownConfig"
          (selectedChange)="genderChange($event)"></app-cd-dropdown>
        <app-cd-dropdown
          #genderCdDropdown
          *ngIf="language !== 'EN'"
          [label]="'employee.gender'"
          [placeholder]="'employer.checkGender'"
          [toppingList]="genderZhDropdownConfig"
          (selectedChange)="genderChange($event)"></app-cd-dropdown>
      </div>

      <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12 search-box-item">
        <!-- <cds-textfield
          [value]="delegateAgentInfoValue"
          #searcdelegateBox
          (input)="delegateAgentChange(searcdelegateBox.value)"
          label="{{ 'employee.delegateAgentInfo' | lang }}"
          placeholder="{{ 'employer.searchPlaceHolder' | lang }}"
          [config]="delegateTxtfieldConfig"></cds-textfield> -->

        <app-cd-input
          [(value)]="delegateAgentInfoValue"
          (valueChange)="delegateAgentChange($event)"
          [placeholder]="'employer.searchPlaceHolder' | lang"
          [label]="'employee.searchDelegateAgentInfo' | lang"></app-cd-input>
      </div>

      <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12 search-box-item">
        <div class="double-botton">
          <div>
            <cds-button
              [disabled]="searchBtnDisableState"
              [config]="primaryButtonConfig"
              label="{{ 'employer.searchBtnTxt' | lang }}"
              (click)="employeeSearch()"></cds-button>
          </div>
          <div>
            <cds-button [config]="secondaryButtonConfig" label="{{ 'employer.clear' | lang }}" (click)="clearData()"></cds-button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="pageShowState === 1">
    <div class="cds-body-hero-light dlgt-search-and-result">
      <span>{{ 'employer.result' | lang }}</span>
    </div>
    <div class="row" *ngIf="enterShowProgress || (isShowProgress && employeeTotalCount === 0)">
      <div class="col-lg-3"></div>
      <cds-progress
        class="col-lg-6"
        mode="indeterminate"
        [diameter]="60"
        [type]="cdsProgressType"
        [text]="cdsProgressTxt"
        [color]="cdsProgressColor"></cds-progress>
      <div class="col-lg-3"></div>
    </div>
    <div *ngIf="employeeTotalCount !== 0">
      <div class="employer-result-top l-ai-cen">
        <span class="cds-body2 l-mb-0"> {{ 'employer.total' | lang }} {{ employeeTotalCount }} {{ 'employer.items' | lang }}</span>
        <div class="right-handle">
          <cds-button [config]="secondaryButtonConfig" label="{{ 'employer.edit' | lang }}" (click)="togglePage()"></cds-button>
        </div>
      </div>

      <div class="result-table">
        <div class="progress-box" *ngIf="isShowProgress">
          <cds-progress mode="indeterminate" [diameter]="60" [type]="cdsProgressType" [text]="cdsProgressTxt" [color]="cdsProgressColor"></cds-progress>
        </div>
        <div #tableBox class="table-box" [ngClass]="[isShowProgress ? 'loading' : '']" *ngIf="employeeTotalCount !== 0">
          <cds-table
            *ngIf="!resetTable"
            [dataSource]="summaryDataSource"
            [cdsSortDirection]="sortDirection"
            cdsSort
            (cdsSortChange)="onSortChange($event)"
            [class.full]="fullWidth">
            <ng-container cdsColumnDef="isSelected">
              <cds-header-cell *cdsHeaderCellDef> </cds-header-cell>
              <cds-cell *cdsCellDef="let element"></cds-cell>
            </ng-container>

            <ng-container cdsColumnDef="employeeName">
              <cds-header-cell
                *cdsHeaderCellDef
                cds-sort-header
                [ngClass]="[checkIsHaveScroll ? 'shadow-right' : '']"
                class="employer-name-column-width no-border-right">
                {{ 'employee.employeeName' | lang }}
              </cds-header-cell>
              <cds-cell *cdsCellDef="let element" [ngClass]="[checkIsHaveScroll ? 'shadow-right' : '']" class="employer-name-column-width no-border-right">
                {{ this.language === 'EN' ? element.memberName : element.memberNameTC ? element.memberNameTC : element.memberName }}
              </cds-cell>
            </ng-container>

            <ng-container cdsColumnDef="trusteeMbrId">
              <cds-header-cell *cdsHeaderCellDef cds-sort-header class="trustee-mbr-id-column-width"> {{ 'employee.no' | lang }}</cds-header-cell>
              <cds-cell *cdsCellDef="let element" class="trustee-mbr-id-column-width">
                {{ element.trusteeMbrId }}
              </cds-cell>
            </ng-container>

            <ng-container cdsColumnDef="gender">
              <cds-header-cell *cdsHeaderCellDef cds-sort-header class="gender-column-width"> {{ 'employee.gender' | lang }}</cds-header-cell>
              <cds-cell *cdsCellDef="let element" class="gender-column-width">
                {{ language === 'EN' ? element.gender : (element.gender | gender) }}
              </cds-cell>
            </ng-container>

            <ng-container cdsColumnDef="age">
              <cds-header-cell *cdsHeaderCellDef cds-sort-header class="age-column-width"> {{ 'employee.ageRank' | lang }}</cds-header-cell>
              <cds-cell *cdsCellDef="let element" class="age-column-width">
                <!-- {{ element.age | ageRange: language }} -->
                {{ element.age }}
              </cds-cell>
            </ng-container>

            <ng-container cdsColumnDef="commencementDate">
              <cds-header-cell *cdsHeaderCellDef cds-sort-header class="date-joining-the-schm-column-width">
                {{ 'employee.dateJoiningTheSchm' | lang }}</cds-header-cell
              >
              <cds-cell *cdsCellDef="let element" class="date-joining-the-schm-column-width">
                {{ getforMateDate(element.commencementDate) }}
              </cds-cell>
            </ng-container>

            <ng-container cdsColumnDef="delegateAgentInfo">
              <cds-header-cell *cdsHeaderCellDef class="delegate-agent-info-column-width" [ngClass]="['no-border-left']">{{
                'employee.delegateAgentInfo' | lang
              }}</cds-header-cell>
              <cds-cell *cdsCellDef="let element" class="delegate-agent-info-column-width" [ngClass]="['no-border-left']">
                <div class="agent-name">
                  <span> {{ element.delegateAgentInfo ? element.delegateAgentInfo.agentCode : '' }}</span>
                  &nbsp;
                  <span
                    [title]="
                      element.delegateAgentInfo
                        ? this.language === 'EN'
                          ? element.delegateAgentInfo.name || element.delegateAgentInfo.nameTC
                          : element.delegateAgentInfo.nameTC || element.delegateAgentInfo.name
                        : ''
                    ">
                    {{
                      element.delegateAgentInfo
                        ? this.language === 'EN'
                          ? element.delegateAgentInfo.name || element.delegateAgentInfo.nameTC
                          : element.delegateAgentInfo.nameTC || element.delegateAgentInfo.name
                        : ''
                    }}</span
                  >
                </div>
              </cds-cell>
            </ng-container>

            <ng-container cdsColumnDef="effStartDate">
              <cds-header-cell *cdsHeaderCellDef cds-sort-header class="eff-start-date-column-width"> {{ 'employee.effStartDate' | lang }}</cds-header-cell>
              <cds-cell *cdsCellDef="let element" class="eff-start-date-column-width">
                {{ getforMateDate(element.effStartDate) }}
              </cds-cell>
            </ng-container>

            <cds-header-row *cdsHeaderRowDef="displayedColumns"></cds-header-row>
            <cds-row *cdsRowDef="let row; columns: displayedColumns"></cds-row>
          </cds-table>
        </div>
        <div class="row result-table-footer">
          <div class="col-sm-6 col-xs-12 footer-pagination">
            <cds-pagination [color]="color" [pageCount]="summaryPageCount" [page]="summaryPage" (pageChange)="pageChange($event)"> </cds-pagination>
          </div>
          <div class="col-sm-6 col-xs-12 page-search">
            <span> Page </span>
            <input class="input" [ngModel]="summaryPage" type="number" #pageNoSearchBox (keydown.enter)="searchByInputPageNo($event, pageNoSearchBox.value)" />
            <span> of {{ summaryPageCount }} </span>
          </div>
        </div>
      </div>
    </div>
    <app-no-result-found *ngIf="employeeTotalCount === 0 && !isShowProgress && !enterShowProgress"></app-no-result-found>
  </div>
  <!--  ==================================================delegate -->

  <div *ngIf="pageShowState === 2">
    <div class="cds-body-hero-light dlgt-search-and-result">
      <span>{{ 'employer.search' | lang }} </span>
    </div>
    <app-search-in-employee-delegation></app-search-in-employee-delegation>
    <div class="cds-body-hero-light dlgt-search-and-result">
      <span>{{ 'employer.result' | lang }}</span>
    </div>
    <div class="inline-alert-box" *ngFor="let item of alertItems; let i = index" ngDefaultControl [(ngModel)]="alertItems">
      <cds-inline-alert class="inline-alert" [type]="alertType" *ngIf="item.ifShow" [dismissable]="true" (dismiss)="dismissAlert(i)">
        {{ this.language === 'EN' ? item.message : item.messageTc }}
      </cds-inline-alert>
    </div>

    <div class="employer-result-top l-ai-cen">
      <span class="cds-body2 l-mb-0">
        {{ 'employer.total' | lang }} {{ delgtEmployeeTotalCount }}
        {{ 'employer.items' | lang }}
      </span>
      <div class="right-handle">
        <cds-button
          [config]="secondaryButtonConfig"
          label="{{ isEdit ? 'employer.cancelEdit' : ('employer.edit' | lang) }}"
          (click)="degtTogglePage()"></cds-button>
      </div>
    </div>

    <div class="row" *ngIf="enterShowProgress">
      <div class="col-lg-3"></div>
      <cds-progress
        class="col-lg-6"
        mode="indeterminate"
        [diameter]="60"
        [type]="cdsProgressType"
        [text]="cdsProgressTxt"
        [color]="cdsProgressColor"></cds-progress>
      <div class="col-lg-3"></div>
    </div>
    <div *ngIf="showTable">
      <div class="result-table">
        <div class="progress-box" *ngIf="isShowProgress">
          <cds-progress mode="indeterminate" [diameter]="60" [type]="cdsProgressType" [text]="cdsProgressTxt" [color]="cdsProgressColor"></cds-progress>
        </div>
        <div #tableBox class="table-box" *ngIf="dataSource.length > 0" [ngClass]="[isShowProgress ? 'loading' : '']">
          <cds-table [dataSource]="dataSource" [class.full]="fullWidth">
            <cds-row *cdsRowDef="let row; index as i; columns: dlgtDisplayedColumns"></cds-row>
            <cds-header-row *cdsHeaderRowDef="dlgtDisplayedColumns"></cds-header-row>

            <ng-container cdsColumnDef="isSelected" sticky>
              <cds-header-cell *cdsHeaderCellDef class="selected-column-width" [ngClass]="[checkIsHaveScroll ? 'shadow-right' : '', 'no-border-right']">
                <cds-checkbox
                  [checked]="allChecked"
                  [indeterminate]="computedIsHalfSelected()"
                  (change)="onHeaderCheckboxChange($event, markPage)"></cds-checkbox>
              </cds-header-cell>
              <cds-cell
                *cdsCellDef="let element; let row; index as i"
                [ngClass]="['selected-column-width', element.isSelected ? 'column-checked' : '', 'no-border-right', checkIsHaveScroll ? 'shadow-right' : '']">
                <cds-checkbox [checked]="element.isSelected" (change)="checkBoxClick($event, i, markPage)"></cds-checkbox>
              </cds-cell>
            </ng-container>

            <ng-container cdsColumnDef="employeeName">
              <cds-header-cell *cdsHeaderCellDef class="employer-name-column-width">
                {{ 'employee.employeeName' | lang }}
              </cds-header-cell>
              <cds-cell *cdsCellDef="let element" [ngClass]="['employer-name-column-width', element.isSelected ? 'column-checked' : '']">
                {{ this.language === 'EN' ? element.memberName : element.memberNameTC ? element.memberNameTC : element.memberName }}
              </cds-cell>
            </ng-container>

            <ng-container cdsColumnDef="trusteeMbrId">
              <cds-header-cell *cdsHeaderCellDef class="trustee-mbr-id-column-width"> {{ 'employee.no' | lang }}</cds-header-cell>
              <cds-cell *cdsCellDef="let element" [ngClass]="['trustee-mbr-id-column-width', element.isSelected ? 'column-checked' : '']">
                {{ element.trusteeMbrId }}
              </cds-cell>
            </ng-container>

            <ng-container cdsColumnDef="gender">
              <cds-header-cell *cdsHeaderCellDef class="gender-column-width"> {{ 'employee.gender' | lang }}</cds-header-cell>
              <cds-cell *cdsCellDef="let element" [ngClass]="['gender-column-width', element.isSelected ? 'column-checked' : '']">
                {{ language === 'EN' ? element.gender : (element.gender | gender) }}
              </cds-cell>
            </ng-container>

            <ng-container cdsColumnDef="age">
              <cds-header-cell *cdsHeaderCellDef class="age-column-width"> {{ 'employee.ageRank' | lang }}</cds-header-cell>
              <cds-cell *cdsCellDef="let element" [ngClass]="['age-column-width', element.isSelected ? 'column-checked' : '']">
                <!-- {{ element.age | ageRange: language }} -->
                {{ element.age }}
              </cds-cell>
            </ng-container>

            <ng-container cdsColumnDef="commencementDate">
              <cds-header-cell *cdsHeaderCellDef class="date-joining-the-schm-column-width"> {{ 'employee.dateJoiningTheSchm' | lang }}</cds-header-cell>
              <cds-cell *cdsCellDef="let element" [ngClass]="['date-joining-the-schm-column-width', element.isSelected ? 'column-checked' : '']">
                {{ getforMateDate(element.commencementDate) }}
              </cds-cell>
            </ng-container>

            <ng-container cdsColumnDef="delegateAgentInfo">
              <cds-header-cell *cdsHeaderCellDef class="delegate-agent-info-column-width" [ngClass]="['no-border-left']">
                {{ 'employer.eeDelegateAgentCode' | lang }}</cds-header-cell
              >
              <cds-cell
                *cdsCellDef="let element; let row; index as i"
                [ngClass]="['delegate-agent-info-column-width', element.isSelected ? 'column-checked' : '', 'no-border-left']">
                {{ element.checkdata }}
                <!-- <cds-form-field>
                  <cds-textfield
                    [disabled]="true"
                    value="{{ element.checkdata }}"
                    [config]="agentTxtfieldConfig"
                    #boxValue
                    (input)="checkAgentCode(boxValue.value, row, i, markPage)">
                  </cds-textfield>
                  <cds-assistive-text
                    class="dlgt-check-message"
                    *ngIf="element.flag === true"
                    type="error"
                    >{{
                      this.language === 'EN'
                        ? element.checkResult
                        : element.checkResultTc
                    }}
                  </cds-assistive-text>
                </cds-form-field> -->
              </cds-cell>
            </ng-container>
          </cds-table>
        </div>
        <div
          [ngStyle]="{
            'margin-bottom': stepperTarget === 'ipad' ? '270px' : '90px'
          }"
          [style.display]="!isShowProgress ? 'flex' : 'none'"
          class="row result-table-footer">
          <div class="col-sm-6 col-xs-12 footer-pagination">
            <cds-pagination [color]="color" [pageCount]="pageCount" [page]="page" (pageChange)="delgtPageChange($event)"> </cds-pagination>
          </div>
          <div class="col-sm-6 col-xs-12 page-search">
            <span>Page </span>
            <input class="input" [value]="searchValue" #pageNoSearchBox (keydown.enter)="delgtSearchByInputPageNo($event, pageNoSearchBox.value)" />
            <span> of {{ pageCount }} </span>
          </div>
        </div>
      </div>

      <div class="row bottom-popup" *ngIf="stepperTarget === 'ipad'">
        <div class="stepper-box" style="padding-left: 0; padding-right: 0">
          <app-cd-stepper [isFreeStyle]="true" class="ee-stepper" style="display: block" #stepper>
            <div freeStyle class="ipad-free-style">
              <div
                *ngFor="let item of stepTitle; let i = index"
                class="wrapper"
                [ngStyle]="{ width: i === 0 || i === 2 ? '45%' : '55%' }"
                [ngClass]="[i > 1 ? 'border-top' : '', i === 0 ? 'move-up-ten' : '']">
                <app-cd-step [ngClass]="i === 0 || i === 2 ? 'padding-left' : ''" #step>
                  <span [ngClass]="['cds-h6-demibold', step.finishStatus ? 'span-un' : 'span-fi']">{{ language === 'EN' ? item.en : item.tc }}</span>
                </app-cd-step>
                <div class="stepper-operate-ipad">
                  <div *ngIf="i === 0" class="padding-left">
                    <span class="cds-h6-demibold">{{ isSelectedNum }} {{ 'employee.itemTotal' | lang }}</span>
                  </div>

                  <div *ngIf="i === 1" class="fill-delegation-code l-mt-2">
                    <span class="cds-body2-demibold">{{ 'delegate.eedelegateTo' | lang }}</span>
                    <app-cd-input
                      class="cds-h3-light text-field l-mb-0"
                      [disable]="isApplyDisable"
                      [value]="dlgtNameValue"
                      #employerNameSearchBox
                      [maxlength]="6"
                      type="number"
                      (valueChange)="valueChange(employerNameSearchBox.value)">
                    </app-cd-input>
                    <cds-assistive-text class="l-mt-3" *ngIf="this.resultState !== '0' && this.resultState !== ''" type="error" style="width: 150%"
                      ><ng-container [ngSwitch]="this.resultState">
                        <span *ngSwitchCase="'2101'">{{ 'agentCode.notExists' | lang }}</span>
                        <span *ngSwitchCase="'2103'">
                          {{ 'agentCode.notSameDistrict' | lang }}
                        </span>
                        <span *ngSwitchCase="'2102'">
                          {{ 'agentCode.inactiveAgent' | lang }}
                        </span>
                        <span *ngSwitchCase="'2119'">
                          {{ 'agentCode.agentLenthEr' | lang }}
                        </span>
                        <span *ngSwitchCase="'21031'">
                          {{ 'agentCode.notHaveLicense' | lang }}
                        </span>
                        <span *ngSwitchCase="'2105'">{{ 'agentCode.invalidDelegatedAgentCode' | lang }}</span>
                      </ng-container>
                    </cds-assistive-text>
                  </div>
                  <div *ngIf="i === 2" class="apply-to-selected padding-left">
                    <cds-button
                      (click)="saveClick()"
                      [disabled]="isDisableBatchApplyAgentCode"
                      [config]="secondaryButtonConfig"
                      label="{{ 'delegate.applyToSelected' | lang }}">
                    </cds-button>
                  </div>
                  <!-- <div class="col-lg-1 start-lg col-sm-6 center-sm col-xs-6 center-xs">
                <cds-button
                  class="clear-button"
                  [disabled]="clearDisabledState"
                  (click)="dlgtClearData()"
                  [config]="primaryButtonConfig"
                  label="{{ 'employer.clear' | lang }}"></cds-button>
              </div> -->
                  <div *ngIf="i === 3" class="submit">
                    <cds-button
                      [disabled]="submitDisabledState"
                      (click)="erDelegate()"
                      [config]="primaryButtonConfig"
                      label="{{ 'delegate.submit' | lang }}"></cds-button>
                  </div>
                </div>
              </div>
            </div>
          </app-cd-stepper>
          <div class="step-clear step-clear-ipad" (click)="dlgtClearData()">
            <cds-icon class="step-icon" [icon]="reloadIcon"></cds-icon>
            <span class="cds-h6-demibold l-mb-0 step-clear-text"> {{ 'employee.reset' | lang }}</span>
          </div>
        </div>
      </div>

      <div class="row bottom-popup" *ngIf="stepperTarget === 'pc'">
        <div class="stepper-box">
          <app-cd-stepper class="ee-stepper" #stepper>
            <app-cd-step #step *ngFor="let item of stepTitle; let i = index">
              <span [ngClass]="['cds-h6-demibold', step.finishStatus ? 'span-un' : 'span-fi']">{{ language === 'EN' ? item.en : item.tc }}</span>
            </app-cd-step>
          </app-cd-stepper>
          <div class="step-clear" (click)="dlgtClearData()">
            <cds-icon class="step-icon" [icon]="reloadIcon"></cds-icon>
            <span class="cds-h6-demibold l-mb-0 step-clear-text"> {{ 'employee.reset' | lang }}</span>
          </div>
        </div>

        <div class="stepper-operate-pc">
          <div>
            <span class="cds-h6-demibold">{{ isSelectedNum }} {{ 'employee.itemTotal' | lang }}</span>
          </div>

          <div class="fill-delegation-code l-mt-2">
            <span class="cds-body2-demibold">{{ 'delegate.eedelegateTo' | lang }}</span>
            <app-cd-input
              class="cds-h3-light text-field l-mb-0"
              [disable]="isApplyDisable"
              [value]="dlgtNameValue"
              #employerNameSearchBox
              [maxlength]="6"
              type="number"
              (valueChange)="valueChange(employerNameSearchBox.value)">
            </app-cd-input>
            <cds-assistive-text class="l-mt-3" *ngIf="this.resultState !== '0' && this.resultState !== ''" type="error"
              ><ng-container [ngSwitch]="this.resultState">
                <span *ngSwitchCase="'2101'">{{ 'agentCode.notExists' | lang }}</span>
                <span *ngSwitchCase="'2103'">
                  {{ 'agentCode.notSameDistrict' | lang }}
                </span>
                <span *ngSwitchCase="'2102'">
                  {{ 'agentCode.inactiveAgent' | lang }}
                </span>
                <span *ngSwitchCase="'2119'">
                  {{ 'agentCode.agentLenthEr' | lang }}
                </span>
                <span *ngSwitchCase="'21031'">
                  {{ 'agentCode.notHaveLicense' | lang }}
                </span>
                <span *ngSwitchCase="'2105'">{{ 'agentCode.invalidDelegatedAgentCode' | lang }}</span>
              </ng-container>
            </cds-assistive-text>
          </div>
          <div class="apply-to-selected">
            <cds-button
              (click)="saveClick()"
              [disabled]="isDisableBatchApplyAgentCode"
              [config]="secondaryButtonConfig"
              label="{{ 'delegate.applyToSelected' | lang }}">
            </cds-button>
          </div>
          <!-- <div class="col-lg-1 start-lg col-sm-6 center-sm col-xs-6 center-xs">
            <cds-button
              class="clear-button"
              [disabled]="clearDisabledState"
              (click)="dlgtClearData()"
              [config]="primaryButtonConfig"
              label="{{ 'employer.clear' | lang }}"></cds-button>
          </div> -->
          <div class="submit">
            <cds-button
              [disabled]="submitDisabledState"
              (click)="erDelegate()"
              [config]="primaryButtonConfig"
              label="{{ 'delegate.submit' | lang }}"></cds-button>
          </div>
        </div>
      </div>
    </div>
    <app-no-result-found *ngIf="delgtEmployeeTotalCount === 0 && !isShowProgress && !enterShowProgress"></app-no-result-found>
  </div>

  <!-- ===========submit page============= -->
  <div *ngIf="pageShowState === 3">
    <div class="cds-body-hero-light dlgt-search-and-result">
      <span>{{ 'employer.ItemsToBeUpdated' | lang }}</span>
    </div>
    <div class="row" *ngIf="isSubShowProgress">
      <div class="col-lg-3"></div>
      <cds-progress
        class="col-lg-6"
        mode="indeterminate"
        [diameter]="60"
        [type]="cdsProgressType"
        [text]="cdsProgressTxt"
        [color]="cdsProgressColor"></cds-progress>
      <div class="col-lg-3"></div>
    </div>
    <div>
      <div class="employer-result-top">
        <span class="cds-body2">{{ 'employer.total' | lang }} {{ subTotalCount }} {{ 'employer.items' | lang }}</span>
      </div>

      <div class="result-table">
        <div #tableBox class="table-box" [ngClass]="[isShowProgress ? 'loading' : '']">
          <cds-table [dataSource]="subDataSource" [class.full]="fullWidth">
            <ng-container cdsColumnDef="employeeName">
              <cds-header-cell *cdsHeaderCellDef [ngClass]="[checkIsHaveScroll ? 'shadow-right' : '']" class="employer-name-column-width no-border-right">
                {{ 'employee.employeeName' | lang }}
              </cds-header-cell>
              <cds-cell *cdsCellDef="let element" [ngClass]="[checkIsHaveScroll ? 'shadow-right' : '']" class="employer-name-column-width no-border-right">
                {{ this.language === 'EN' ? element.memberName : element.memberNameTC ? element.memberNameTC : element.memberName }}
              </cds-cell>
            </ng-container>

            <ng-container cdsColumnDef="trusteeEmplrCd">
              <cds-header-cell *cdsHeaderCellDef> {{ 'employee.no' | lang }}</cds-header-cell>
              <cds-cell *cdsCellDef="let element">
                {{ element.trusteeMbrId }}
              </cds-cell>
            </ng-container>

            <ng-container cdsColumnDef="gender">
              <cds-header-cell *cdsHeaderCellDef class="gender-column-width"> {{ 'employee.gender' | lang }}</cds-header-cell>
              <cds-cell *cdsCellDef="let element" class="gender-column-width">
                {{ language === 'EN' ? element.gender : (element.gender | gender) }}
              </cds-cell>
            </ng-container>

            <ng-container cdsColumnDef="age">
              <cds-header-cell *cdsHeaderCellDef class="age-column-width"> {{ 'employee.ageRank' | lang }}</cds-header-cell>
              <cds-cell *cdsCellDef="let element" class="age-column-width">
                <!-- {{ element.age | ageRange: language }} -->
                {{ element.age }}
              </cds-cell>
            </ng-container>
            <ng-container cdsColumnDef="commencementDate">
              <cds-header-cell *cdsHeaderCellDef class="date-joining-the-schm-column-width"> {{ 'employee.dateJoiningTheSchm' | lang }}</cds-header-cell>
              <cds-cell *cdsCellDef="let element" class="date-joining-the-schm-column-width">
                {{ getforMateDate(element.commencementDate) }}
              </cds-cell>
            </ng-container>

            <ng-container cdsColumnDef="delegateAgentInfo">
              <cds-header-cell *cdsHeaderCellDef class="delegate-agent-info-column-width" [ngClass]="['no-border-left']">{{
                'employee.delegateAgentInfo' | lang
              }}</cds-header-cell>
              <cds-cell *cdsCellDef="let element" class="delegate-agent-info-column-width" [ngClass]="['no-border-left']">
                <div class="agent-name">
                  {{ element.delegateAgentInfo ? element.delegateAgentInfo.agentCode : '' }}
                  &nbsp;
                  {{
                    element.delegateAgentInfo
                      ? this.language === 'EN'
                        ? element.delegateAgentInfo.name || element.delegateAgentInfo.nameTC
                        : element.delegateAgentInfo.nameTC || element.delegateAgentInfo.name
                      : ''
                  }}
                </div>
              </cds-cell>
            </ng-container>

            <cds-header-row *cdsHeaderRowDef="subdisplayedColumns"></cds-header-row>
            <cds-row *cdsRowDef="let row; columns: subdisplayedColumns"></cds-row>
          </cds-table>
        </div>
        <div class="row result-table-footer">
          <div class="col-sm-6 col-xs-12 footer-pagination">
            <cds-pagination [color]="color" [pageCount]="subPageCount" [page]="subPage" (pageChange)="submitPageChange($event)"> </cds-pagination>
          </div>

          <div class="col-sm-6 col-xs-12 page-search">
            <span>Page </span>
            <input class="input" [value]="subSearchValue" #pageNoSearchBox (keydown.enter)="SubmitSearchByInputPageNo($event, pageNoSearchBox.value)" />
            <span> of {{ subPageCount }} </span>
          </div>
        </div>
      </div>
    </div>
    <div class="material-update">
      <div class="material-update-box">
        <cds-button [config]="secondaryButtonConfig" (click)="erBackToEdit()" label="{{ 'submission.backToEdit' | lang }}"></cds-button>
        <cds-button [config]="primaryButtonConfig" (click)="submitData()" label="{{ 'submission.update' | lang }}"> </cds-button>
      </div>
    </div>
  </div>
</div>
