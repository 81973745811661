import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ShkRoutingModule } from './shk-routing.module';
import { CdsDatepickerModule } from '@cds/ng-web-components/datepicker';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CdsLanguageModule } from '@cds/ng-core/lang';
import { CdsTextfieldModule } from '@cds/ng-web-components/textfield';
import { CdsDropdownModule } from '@cds/ng-web-components/dropdown';
import { CdsButtonModule } from '@cds/ng-web-components/button';
import { CdsIconModule } from '@cds/ng-web-components/icon';
import { CdsTableModule } from '@cds/ng-data-table/table';
import { CdsCheckboxModule } from '@cds/ng-web-components/checkbox';
import { CdsPaginationModule } from '@cds/ng-web-components/pagination';
import { CdsPopupModule } from '@cds/ng-web-components/popup';
import { CdsProgressModule } from '@cds/ng-web-components/progress';
import { SharedModule } from 'src/app/shared/shared.module';
import { AutoRolloverListComponent } from './auto-rollover/auto-rollover-list/auto-rollover-list.component';
import { AutoRolloverPaymentListComponent } from './auto-rollover/auto-rollover-payment-list/auto-rollover-payment-list.component';
import { CdsFormFieldModule } from '@cds/ng-web-components/form-field';
import { CdsAssistiveTextModule } from '@cds/ng-web-components/assistive-text';
import { AgentReassignmentComponent } from './agent-reassignment/agent-reassignment.component';
import { CdsRadioModule } from '@cds/ng-web-components/radio';
import { AgentPerformanceHeadComponent } from './agent-performance-head/agent-performance-head.component';
import { AgentPerformanceHeadEnrolmentComponent } from './agent-performance-head-enrolment/agent-performance-head-enrolment.component';
import { CdsTooltipModule } from '@cds/ng-web-components/tooltip';
import { CdsTabsModule } from '@cds/ng-web-components/tabs';
import { CdsFileUploadModule } from '@cds/ng-experimental/file-upload';
import { CdsSortModule } from '@cds/ng-data-table/sort';

@NgModule({
  declarations: [
    AutoRolloverListComponent,
    AutoRolloverPaymentListComponent,
    AgentReassignmentComponent,
    AgentPerformanceHeadComponent,
    AgentPerformanceHeadEnrolmentComponent,
  ],
  imports: [
    CommonModule,
    ShkRoutingModule,
    CdsDatepickerModule,
    FormsModule,
    ReactiveFormsModule,
    CdsLanguageModule,
    CdsTextfieldModule,
    CdsDropdownModule,
    CdsButtonModule,
    CdsIconModule,
    CdsTableModule,
    CdsCheckboxModule,
    CdsPaginationModule,
    CdsPopupModule,
    CdsProgressModule,
    SharedModule,
    CdsFormFieldModule,
    CdsAssistiveTextModule,
    CdsRadioModule,
    CdsTooltipModule,
    CdsTabsModule,
    CdsFileUploadModule,
    CdsSortModule,
  ],
})
export class ShkModule {}
