<cds-popup>
  <div class="l-pt-6 l-pb-6">
    <div class="row">
      <div class="col-xs-12 col-md-12">
        <div class="cds-h2-light l-ma-0">
          {{ 'commission.pool.related.add.title' | lang }}
        </div>
        <br />
        <div class="l-d-f">
          <div innerHtml="{{ 'commission.pool.related.add.introduction1' | lang }}"></div>
          <div style="width: 20px">
            <cds-icon class="l-pl-2" size="sm" (click)="accountExplain()" style="cursor: pointer; min-width: initial" icon="action:info_1"></cds-icon>
          </div>
        </div>
        <br />
        <span
          >{{ 'commission.pool.related.add.introduction2' | lang }}&nbsp;
          <span class="font-green">{{ this.commissionPoolId }}</span>
        </span>
      </div>
      <div class="col-xs-12 col-md-12 l-d-f l-pt-3">
        <div class="col-xs-9 col-md-9 l-pt-3 l-plr-0">
          <div class="col-xs-12 col-md-12 l-plr-0">
            <input
              id="search"
              #trigger="matAutocompleteTrigger"
              appAutocompletePosition="trigger"
              [(ngModel)]="queryPara"
              class="text-field"
              placeholder="{{ 'commission.pool.related.add.inputCodeOrName' | lang }} (min length 6)"
              (blur)="('action:button_down')"
              [matAutocomplete]="auto" />
            <i class="icon">
              <cds-icon size="sm" icon="form:search"></cds-icon>
            </i>
          </div>
          <mat-autocomplete #auto="matAutocomplete" (optionSelected)="optionSelected($event)" [displayWith]="displayFn.bind(this)">
            <mat-option class="div-border-bottom div-border-top" *ngFor="let option of options" [value]="option.customerId">
              <span>{{ option.no }}&nbsp;&nbsp;&nbsp;&nbsp;[{{ option.accountTypeStr }}]&nbsp;&nbsp;{{ option.name }}</span>
            </mat-option>
          </mat-autocomplete>
          <cds-progress *ngIf="loading" mode="indeterminate" class="l-pl-6 l-pt-3"></cds-progress>
          <cds-assistive-text class="l-pt-2" *ngIf="errorMsg">
            {{ errorMsg | lang }}
          </cds-assistive-text>
          <cds-assistive-text type="warning" class="l-pt-2" *ngIf="warningMsg">
            <span style="color: #d77d28">
              {{ warningMsg | lang }}
            </span>
          </cds-assistive-text>
        </div>
        <cds-button
          *ngIf="!loading"
          class="l-pl-3"
          [config]="btnCfg"
          size="sm"
          (click)="confirm()"
          [disabled]="(errorMsg !== null && errorMsg !== '') || account.customerId === null || account.customerId === ''">
          {{ 'commission.pool.common.add' | lang }}
        </cds-button>
      </div>
    </div>
  </div>
</cds-popup>
