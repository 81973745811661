/* eslint-disable @angular-eslint/directive-class-suffix */
/* eslint-disable @angular-eslint/no-host-metadata-property */
/* eslint-disable @angular-eslint/directive-selector */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Directive, Input, TemplateRef } from '@angular/core';

/**
 * td Cell definition for the Appapp-dt-table.
 * Captures the template of a column's data row cell as well as cell-specific properties.
 */
@Directive({
  selector: '[appDtHeaderCellDef]',
})
export class DtHeaderCellDefDirective {
  @Input() appDtHeaderCellDef!: string;
  constructor(public templateRef: TemplateRef<any>) {}
}

/**
 * th Cell definition for the Appapp-dt-table.
 * Captures the template of a column's data row cell as well as cell-specific properties.
 */
@Directive({
  selector: '[appDtBodyCellDef]',
})
export class DtBodyCellDefDirective {
  @Input() appDtBodyCellDef!: string;
  constructor(public templateRef: TemplateRef<any>) {}
}
