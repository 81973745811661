<div class="l-pa-7">
  <div class="l-mt-3 submit-success">
    <cds-icon class="button-checkmark" icon="arrow:history"></cds-icon>
  </div>
  <div class="l-mt-5">
    <div class="cds-h4-bold">
      {{ data.message }}
    </div>
  </div>

  <div class="l-mt-3">
    <cds-assistive-text type="error">{{ 'sendIffInBatchSummary.noChanges' | lang }}</cds-assistive-text>
  </div>
  <div class="l-pt-4">
    <cds-button [config]="buttonConfig" (click)="gotIt()" label="{{ 'iff.gotIt' | lang }}"></cds-button>
  </div>
</div>
