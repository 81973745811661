/* eslint-disable @typescript-eslint/no-explicit-any */
import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({ name: 'ssmsDate' })
export class SsmsDatePipe extends DatePipe implements PipeTransform {
  override transform(value: any, format?: any): any {
    if (value && format) {
      return super.transform(value, format);
    }
    if (value === undefined || value === null) {
      return '';
    }
    return super.transform(value, 'dd/MM/yyyy');
  }
}
