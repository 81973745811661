<app-cd-popup>
  <cds-popup-title><span class="cds-h2-light">Declined Items</span></cds-popup-title>
  <cds-popup-content>
    <div class="content l-pl-3 l-pr-3">
      <div class="l-pb-4">
        <div class="row l-plr-0 cds-white div-border-top div-border-bottom cds-h6 cds-demibold l-mb-0">
          <div class="col-xs-1 col-md-1 l-pb-2 l-pt-2 l-pl-4 l-pr-4"></div>
          <div class="col-xs-4 col-md-4 l-pb-2 l-pt-2 l-pl-4 l-pr-4 cds-h6-demibold">Event</div>
          <div class="col-xs-2 col-md-2 l-pb-2 l-pt-2 l-pl-4 l-pr-4">Type of Event</div>
          <div class="col-xs-2 col-md-2 l-pb-2 l-pt-2 l-pl-4 l-pr-4 div-border-right">Declined Date</div>
          <div class="col-xs-2 col-md-2 l-pb-2 l-pt-2 l-pl-4 l-pr-4">Action</div>
        </div>

        <ng-container *ngFor="let data of calendarService.declineEvents; let index = index">
          <div class="row l-plr-0 div-border-bottom cds-body1 l-mb-0">
            <div class="col-xs-1 col-md-1 l-pa-4">{{ index + 1 }}</div>
            <div class="col-xs-4 col-md-4 l-pa-4">{{ data.title }}</div>
            <div class="col-xs-2 col-md-2 l-pa-4" *ngIf="data.isNew">{{ 'New Event' | lang }}</div>
            <div class="col-xs-2 col-md-2 l-pa-4" *ngIf="!data.isNew">{{ 'Event Change' | lang }}</div>
            <div class="col-xs-2 col-md-2 l-pa-4 div-border-right">{{ data.declineTime }}</div>
            <div class="col-xs-2 col-md-2 l-pa-4">
              <div class="l-d-f l-as-cen l-ml-3 cursor-pointer" (click)="edit(data)">
                <cds-icon icon="action:edit"></cds-icon>
                <span class="cds-body2 cds-demibold l-pl-2 l-mb-0">Edit</span>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </cds-popup-content>
</app-cd-popup>
