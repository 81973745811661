import { Injectable } from '@angular/core';
import { CdsAlertComponent, CdsAlertService } from '@cds/ng-web-components/alert';

export type ToastAlertType = 'info' | 'success' | 'warning' | 'error';

@Injectable({
  providedIn: 'root',
})
export class ToastAlertService {
  private fun: () => void | null = () => {};
  constructor(private alert: CdsAlertService) {}

  show(type: ToastAlertType, title: string, message: string, delay?: number, dismiss?: () => void) {
    let componentRef: CdsAlertComponent;
    switch (type) {
      case 'info': {
        componentRef = this.alert.info(title, message);
        break;
      }
      case 'success': {
        componentRef = this.alert.success(title, message);
        delay ? (delay = 5000) : '';
        break;
      }
      case 'warning': {
        componentRef = this.alert.warning(title, message);
        break;
      }
      case 'error': {
        componentRef = this.alert.error(title, message);
        break;
      }
    }

    if (dismiss) {
      this.fun = dismiss;
      componentRef.dismiss.subscribe(() => {
        this.fun();
      });
    }

    if (delay) {
      const dismissTimerId = setTimeout(() => {
        componentRef.close();
        clearTimeout(dismissTimerId);
      }, delay);
    }
  }
}
