import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { CdsToastService } from '@cds/ng-web-components/toast';
import { AgentInfoResponse } from '../ee-modules/AgentInfo.module';
import { EmployeeService } from '../employee.service';

@Component({
  selector: 'app-ee-profile-agent-info',
  templateUrl: './ee-profile-agent-info.component.html',
  styleUrls: ['./ee-profile-agent-info.component.scss'],
})
export class EeProfileAgentInfoComponent implements OnInit, OnChanges {
  @Input() trusteeId: string | undefined = '';
  @Input() entityType: string | undefined = '';
  agentInfo: AgentInfoResponse[] = [];
  agentServiceList: AgentInfoResponse[] = [];
  constructor(private service: EmployeeService, private toast: CdsToastService) {}

  ngOnInit(): void {
    if (this.trusteeId && this.entityType) {
      this.getAgentInfo(this.trusteeId, this.entityType);
    }
  }
  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    if (changes['trusteeId'] !== undefined) {
      this.trusteeId = changes['trusteeId'].currentValue;
    }
    if (changes['entityType'] !== undefined) {
      this.entityType = changes['entityType'].currentValue;
    }
    if (this.trusteeId && this.entityType) {
      this.getAgentInfo(this.trusteeId, this.entityType);
    }
  }

  getAgentInfo(trusteeId: string, entityType: string) {
    this.service.getAgentInfo(trusteeId, entityType).subscribe(res => {
      if (res.result === 0 && res.data) {
        if (res.data.commission) {
          this.agentInfo = res.data.commission;
        }
      } else {
        this.toast.error('getById API error: ' + res.message);
      }
    });
  }
}
