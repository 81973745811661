import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CdsIconModule } from '@cds/ng-web-components/icon';
import { CdAccordionComponent } from './cd-accordion/cd-accordion.component';
import { CdAccordionPanelComponent } from './cd-accordion-panel/cd-accordion-panel.component';
import { TooltipIconModule } from 'src/app/shared/tooltip-icon/tooltip-icon.module';

@NgModule({
  declarations: [CdAccordionComponent, CdAccordionPanelComponent],
  imports: [CommonModule, CdsIconModule, TooltipIconModule],
  exports: [CdAccordionComponent, CdAccordionPanelComponent],
})
export class CdAccordionModule {}
