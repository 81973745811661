/* eslint-disable @typescript-eslint/no-explicit-any */
import { AbstractControl, ValidationErrors } from '@angular/forms';

export const whitespaceValidator = () => {
  return (control: AbstractControl): ValidationErrors | null => {
    const input = control.value || '';
    const trimInput = input.trim();
    const isValid = trimInput.length !== 0 && input == trimInput;
    return isValid ? null : { whitespaceValidator: true };
  };
};
