import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BaseLayoutComponent } from './Layout/base-layout/base-layout.component';
import { PagesLayoutComponent } from './Layout/pages-layout/pages-layout.component';
import { AuthGuard } from './_guard/authGuard';
import { PermissionAccess, PermissionItem } from 'src/app/core/models/enum/permission.enum';

// // Pages

const routes: Routes = [
  {
    path: 'chatbot-admin',
    component: BaseLayoutComponent,
    // runGuardsAndResolvers: 'always',
    children: [
      {
        path: '',
        loadChildren: () => import('./main/main.module').then(m => m.MainModule),
        // canActivate: [AuthGuard]
        data: {
          roles: {
            [PermissionItem.CHATBOT_ADMIN]: PermissionAccess.R,
          },
        },
      },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
      relativeLinkResolution: 'legacy',
    }),
  ],
  exports: [RouterModule],
})
export class ChatbotAdminRoutingModule {}
