import { Component, Inject } from '@angular/core';
import { CdsButtonConfig } from '@cds/ng-core/button';
import { CDS_POPUP_DATA, MatDialogRef } from '@cds/ng-web-components/popup';
@Component({
  selector: 'app-confirm-popup',
  templateUrl: './confirm-popup.component.html',
  styleUrls: ['./confirm-popup.component.scss'],
})
export class ConfirmPopupComponent {
  disagreeButtonConfig: CdsButtonConfig = { size: 'sm', style: 'secondary' };
  agreeButtonConfig: CdsButtonConfig = { size: 'sm' };

  constructor(
    private dialogRef: MatDialogRef<ConfirmPopupComponent>,
    @Inject(CDS_POPUP_DATA)
    public data: {
      title: string;
      message: string;
    }
  ) {}
  agree() {
    this.dialogRef.close({ agree: true });
  }
  close() {
    this.dialogRef.close({ agree: false });
  }
}
