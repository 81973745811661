import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { CdsLanguageModule } from '@cds/ng-core/lang';

import { CdTextareaComponent } from './cd-textarea.component';

@NgModule({
  imports: [CommonModule, FormsModule, ReactiveFormsModule, CdsLanguageModule],
  declarations: [CdTextareaComponent],
  exports: [CdTextareaComponent],
})
export class CdTextareaModule {}
