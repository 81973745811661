import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { CdsButtonConfig } from '@cds/ng-core/button';
import { CDS_POPUP_DATA } from '@cds/ng-web-components/popup';

@Component({
  selector: 'app-continue-popup',
  templateUrl: './continue-popup.component.html',
  styleUrls: ['./continue-popup.component.scss'],
})
export class ContinuePopupComponent implements OnInit {
  disagreeButtonConfig: CdsButtonConfig = { size: 'sm', style: 'secondary' };
  agreeButtonConfig: CdsButtonConfig = { size: 'sm' };

  constructor(
    private dialogRef: MatDialogRef<ContinuePopupComponent>,
    @Inject(CDS_POPUP_DATA)
    public data: {
      title: string;
      message: string;
      cancelButtonName: string;
      continueButtonName: string;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      closeFn: any;
    }
  ) {}

  ngOnInit(): void {}

  agree() {
    this.dialogRef.close({ agree: true });
  }
  close() {
    this.dialogRef.close({ agree: false });
    if (this.data.closeFn && typeof this.data.closeFn === 'function') {
      this.data.closeFn();
    }
  }
}
