/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Input, OnInit } from '@angular/core';
import { WidgetComponent } from '../widget.component';
import { IndividualType } from '../../salesjourney/case-summary/case-summary.component';
import { CdHttpServeService } from 'src/app/shared/cd-http-serve/cd-http-serve.service';
import { CdsAlertService } from '@cds/ng-web-components/alert';

interface ProductTypePendingItem {
  pendingToSend: number;
  pending2ndAgent: number;
  pendingCusSubmission: number;
}

interface ProductTypePending {
  TVC: ProductTypePendingItem;
  PA: ProductTypePendingItem;
  PAC: ProductTypePendingItem;
  FRC: ProductTypePendingItem;
  ER: ProductTypePendingItem;
  SEP: ProductTypePendingItem;
}

@Component({
  selector: 'app-widgets-pending-iff',
  templateUrl: './widgets-pending-iff.component.html',
  styleUrls: ['./widgets-pending-iff.component.scss'],
})
export class WidgetsPendingIffComponent implements OnInit, WidgetComponent {
  @Input() data: any;
  IndividualType = IndividualType;
  individualTypeSelected = IndividualType.PA;
  productTypePending?: ProductTypePending;

  constructor(private cdHttpServeService: CdHttpServeService, private alert: CdsAlertService) {}

  ngOnInit(): void {
    this.getProductTypeSummaries();
  }

  checkRecordDetailTag(value: IndividualType) {
    this.individualTypeSelected = value;
  }

  getProductTypeSummaries() {
    this.cdHttpServeService.get<ProductTypePending>(`/ext/eb-ssms-sales-journey-service/productTypeSummaries`, {}).subscribe({
      next: res => {
        if (res.result !== 0) {
          this.alert.warning('Warning!', `${res.message}`);
        } else {
          this.productTypePending = res.data;
        }
      },
      error: err => {
        this.alert.error('Error!', err);
      },
    });
  }

  getColor(num = 0) {
    if (num >= 100) {
      return 'greater-or-equal-to-100';
    } else if (num > 0) {
      return 'greater-to-0';
    } else if (num === 0) {
      return 'equal-to-0';
    }
    return 'equal-to-0';
  }
}
