import { Injectable } from '@angular/core';
import { IFF_STATUS } from 'src/app/config/iff-status.config';

@Injectable({
  providedIn: 'root',
})
export class IffStatusService {
  constructor() {}

  getIFFStatusInfo(val: string) {
    return IFF_STATUS.find(item => item.text === val);
  }
}
