<div class="bg">
  <cds-popup>
    <div class="row l-plr-0">
      <div class="col-xs-12 cds-color-green">
        <span class="cds-h5-demibold">{{ 'er.profile.edit-log-history' | lang }}</span>
      </div>
    </div>
    <ng-template #template let-element>
      <div class="row col-xs-12 l-plr-0">
        <div class="col-xs-12 l-pt-2 l-pb-2">
          <span class="cds-body2">{{ 'payment.comment-details' | lang }}</span>
        </div>
        <div class="col-xs-12">
          <span prop="detail">{{ element.comments }} </span>
        </div>
      </div>
    </ng-template>
    <app-table-expand size="18px" [expandTemplate]="template" [data]="dataList">
      <app-table-expand-col [template]="date" prop="updateDate" label="Last update"></app-table-expand-col>
      <app-table-expand-col prop="updateName" label="User"> </app-table-expand-col>
    </app-table-expand>
    <ng-template let-element #date>
      {{ updateDateGen | fn : element.updateDate }}
    </ng-template>
  </cds-popup>
</div>
