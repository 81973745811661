<ng-container *ngIf="showOrso">
  <div class="row l-pt-8">
    <div class="col-xs-12 cds-color-green l-plr-0">
      <span class="cds-h5-demibold">{{ 'payroll.vesting-rules' | lang }}</span>
    </div>
    <div class="col-xs-12 p-line"></div>
  </div>

  <app-cd-accordion>
    <app-cd-accordion-panel
      *ngFor="let item of vestingRuleOrsoList"
      [title]="'Vesting Class No. ' + item.vestingClassNo"
      titleClass="accordion-title"
      [miniTitle]="item.vestingClassName"
      miniTitleClass="accordion-mini-title">
      <div class="row col-xs-12 l-pt-4 l-pb-4">
        <div class="col-xs-12 l-plr-0">
          <span class="cds-body2 color-grey">{{ 'payment.no-pay-leave' | lang }}</span>
        </div>
        <div class="col-xs-12 l-plr-0">
          <span class="cds-body1">{{ noPayLeaveRender | fn : item.noPayLeave }}</span>
        </div>
      </div>

      <div class="col-xs-12 row l-plr-0">
        <div class="col-xs-12 col-sm-12 col-md-6">
          <cds-table [class.full]="true" [dataSource]="getNoPayLeaveTypeDataSource | fn : item">
            Vesting Type
            <ng-container cdsColumnDef="type">
              <cds-header-cell *cdsHeaderCellDef>Vesting Type</cds-header-cell>
              <cds-cell *cdsCellDef="let element">{{ element.type }}</cds-cell>
            </ng-container>

            Vesting %
            <ng-container cdsColumnDef="vesting">
              <cds-header-cell *cdsHeaderCellDef>Vesting % </cds-header-cell>
              <cds-cell *cdsCellDef="let element">{{ OrsoVestingRender | fn : element.vesting }}</cds-cell>
            </ng-container>

            <cds-header-row *cdsHeaderRowDef="noPayLeaveTypeDisplayedColumns"></cds-header-row>
            <cds-row class="cds-body1" *cdsRowDef="let row; columns: noPayLeaveTypeDisplayedColumns"></cds-row>
          </cds-table>
        </div>

        <div class="col-xs-12 col-sm-12 col-md-6">
          <cds-table [dataSource]="getNoPayLeaveRangeDataSource(item)" [class.full]="true">
            Type
            <ng-container cdsColumnDef="type">
              <cds-header-cell *cdsHeaderCellDef></cds-header-cell>
              <cds-cell *cdsCellDef="let element"
                ><span class="cds-detail1-demibold">{{ element.type }}</span></cds-cell
              >
            </ng-container>

            Minimum Vesting
            <ng-container cdsColumnDef="min">
              <cds-header-cell *cdsHeaderCellDef>Minimum Vesting</cds-header-cell>
              <cds-cell *cdsCellDef="let element">{{ element.min }}</cds-cell>
            </ng-container>

            Full Vesting
            <ng-container cdsColumnDef="full">
              <cds-header-cell *cdsHeaderCellDef>Full Vesting</cds-header-cell>
              <cds-cell *cdsCellDef="let element">
                {{ element.full }}
              </cds-cell>
            </ng-container>

            <cds-header-row *cdsHeaderRowDef="noPayLeaveRangeDisplayedColumns"></cds-header-row>
            <cds-row class="cds-body1" *cdsRowDef="let row; columns: noPayLeaveRangeDisplayedColumns"></cds-row>
          </cds-table>
        </div>
      </div>

      <div class="row col-xs-12 l-pt-8 l-pb-2">
        <div class="col-xs-12 l-plr-0">
          <span class="cds-body1 font-weight-6">{{ 'employer.vesting-rule-to-be-applied-upon-termination-reason' | lang }}</span>
        </div>
        <div class="col-xs-12 p-line"></div>
      </div>

      <div class="col-xs-12 row l-plr-0">
        <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3" *ngFor="let vestingItem of item.rules">
          <div class="cds-color-green cds-body2 font-weight-6 font-style-italic">{{ vestingItem.name }}</div>
          <cds-table [dataSource]="getVestingSchemeDataSource | fn : vestingItem.vestingSchemeBreakpoints" [class.full]="true">
            Break Point
            <ng-container cdsColumnDef="breakPoint">
              <cds-header-cell *cdsHeaderCellDef>YY/MM</cds-header-cell>
              <cds-cell *cdsCellDef="let element">{{ element?.breakPoint }}</cds-cell>
            </ng-container>

            vesting
            <ng-container cdsColumnDef="vestRt">
              <cds-header-cell *cdsHeaderCellDef>Vesting%</cds-header-cell>
              <cds-cell *cdsCellDef="let element">{{ element?.vestRt | percent : '1.2-2' }}</cds-cell>
            </ng-container>

            <cds-header-row *cdsHeaderRowDef="vestingSchemeDisplayedColumns"></cds-header-row>
            <cds-row class="cds-body1" *cdsRowDef="let row; columns: vestingSchemeDisplayedColumns"></cds-row>
          </cds-table>
          <div class="l-pt-4 l-pb-4" *ngIf="getVestingSchemeDataSource(vestingItem.vestingSchemeBreakpoints).length === 0">
            <app-no-result-found></app-no-result-found>
          </div>
        </div>
      </div>
    </app-cd-accordion-panel>
  </app-cd-accordion>
</ng-container>

<div *ngIf="vestingLoading" class="col-xs-12 l-pa-7 center-xs">
  <app-loading></app-loading>
</div>
<div *ngIf="voluntaryLoading" class="col-xs-12 l-pa-7 center-xs">
  <app-loading></app-loading>
</div>

<ng-container *ngIf="!showOrso">
  <div class="row l-pt-8">
    <div class="col-xs-12 cds-color-green l-plr-0">
      <span class="cds-h5-demibold">{{ 'payroll.vesting-rules' | lang }}</span>
    </div>
    <div class="col-xs-12 p-line"></div>
  </div>

  <app-no-result-found *ngIf="!vestingRuleMpfList.length && !vestingLoading"></app-no-result-found>

  <app-cd-accordion *ngIf="vestingRuleMpfList.length">
    <app-cd-accordion-panel
      *ngFor="let item of vestingRuleMpfList; index as itemIndex"
      [title]="'Vesting sequence no. ' + item.vestingSequenceNumber"
      titleClass="accordion-title"
      [miniTitle]="item.employerGradeMemberClass"
      miniTitleClass="accordion-mini-title">
      <div class="row col-xs-12 l-plr-0">
        <app-row-item label="er.profile.effective-date" [data]="item.effectiveDate | ssmsDate" class="col-xs-6 col-sm-3 col-md-3 col-lg-3"> </app-row-item>
        <app-row-item label="er.profile.effective-end-date" [data]="item.effectiveEndDate | ssmsDate" class="col-xs-6 col-sm-3 col-md-3 col-lg-3">
        </app-row-item>
        <app-row-item
          label="employer.calculation-of-complete-month-of-service"
          [data]="calMnthServiceTypRender | fn : item.calculationOfCompleteMonthOfService"
          class="col-xs-6 col-sm-3 col-md-3 col-lg-3">
        </app-row-item>
        <app-row-item
          label="employer.calculation-base-unit"
          [data]="calBaseUntRender | fn : item.calculationBaseUnit"
          class="col-xs-6 col-sm-3 col-md-3 col-lg-3">
        </app-row-item>
        <app-row-item label="employer.sub-account-type" [data]="item.subAccountType?.join(', ')" class="col-xs-6 col-sm-3 col-md-3 col-lg-3"> </app-row-item>
        <app-row-item label="employer.vc-normal-retirement-age" [data]="item.vcNormalRetirementAge" class="col-xs-6 col-sm-3 col-md-3 col-lg-3"> </app-row-item>
        <app-row-item label="employer.vc-early-retirement-age" [data]="item.vcEarlyRetirementAge" class="col-xs-6 col-sm-3 col-md-3 col-lg-3"> </app-row-item>
      </div>

      <div class="row col-xs-12 l-pt-8 l-pb-2">
        <div class="col-xs-12 l-plr-0">
          <span class="cds-body1 font-weight-6">{{ 'employer.vesting-rule-to-be-applied-upon-termination-reason' | lang }}</span>
        </div>
        <div class="col-xs-12 p-line"></div>
      </div>

      <div *ngIf="item?.terminationReasonList" class="col-xs-12 row l-plr-0">
        <div *ngFor="let reason of item.terminationReasonList" class="col-xs-12 col-sm-12 col-md-6 l-pb-4">
          <div *ngIf="reason.reasonHeader" class="cds-xs-12 cds-color-green cds-body2 font-weight-6 font-style-italic l-pt-2">
            {{ vestTermReasonsRedner(reason.reasonHeader) }}
          </div>
          <ng-container *ngIf="reason.reasonTable">
            <cds-table [dataSource]="reason.reasonTable" [class.full]="true">
              <ng-container cdsColumnDef="completedMonthOfServiceFrom">
                <cds-header-cell *cdsHeaderCellDef>Completed month of service from</cds-header-cell>
                <cds-cell *cdsCellDef="let element">{{ element.completedMonthOfServiceFrom | number : '2.0-0' }}</cds-cell>
              </ng-container>

              <ng-container cdsColumnDef="completedMonthOfServiceTo">
                <cds-header-cell *cdsHeaderCellDef>Completed month of service to</cds-header-cell>
                <cds-cell *cdsCellDef="let element">{{ element.completedMonthOfServiceTo | number : '2.0-0' }}</cds-cell>
              </ng-container>

              <ng-container cdsColumnDef="vestingRate">
                <cds-header-cell *cdsHeaderCellDef>Vesting%</cds-header-cell>
                <cds-cell *cdsCellDef="let element">{{ element.vestingRate | percent : '1.2-2' }}</cds-cell>
              </ng-container>

              <cds-header-row *cdsHeaderRowDef="vestingRuleDisplayedColumns"></cds-header-row>
              <cds-row class="cds-body1" *cdsRowDef="let row; columns: vestingRuleDisplayedColumns"></cds-row>
            </cds-table>
          </ng-container>
        </div>
      </div>
    </app-cd-accordion-panel>
  </app-cd-accordion>

  <div class="row l-pt-8">
    <div class="col-xs-12 cds-color-green l-plr-0">
      <span class="cds-h5-demibold">{{ 'employer.voluntary-contribution-rules' | lang }}</span>
    </div>
    <div class="col-xs-12 p-line"></div>
  </div>

  <app-no-result-found *ngIf="!voluntaryRuleList.length && !voluntaryLoading"></app-no-result-found>

  <app-cd-accordion *ngIf="voluntaryRuleList.length">
    <app-cd-accordion-panel
      *ngFor="let item of voluntaryRuleList; index as itemIndex"
      [title]="item.employerGradeMemberClass"
      titleClass="cds-h5-demibold font-weight-6 l-mb-0">
      <div class="row col-xs-12 l-pb-8 l-plr-0">
        <app-row-item label="er.profile.effective-date" [data]="item.effectiveDate | ssmsDate" class="col-xs-6 col-sm-3 col-md-3 col-lg-3"> </app-row-item>
        <app-row-item label="er.profile.effective-end-date" [data]="item.effectiveEndDate | ssmsDate" class="col-xs-6 col-sm-3 col-md-3 col-lg-3">
        </app-row-item>
        <app-row-item
          label="employer.definition-of-service"
          [data]="definitionOfServiceTypRender | fn : item.definitionOfService"
          class="col-xs-6 col-sm-3 col-md-3 col-lg-3">
        </app-row-item>
        <app-row-item label="employer.definition-of-income" [data]="incomeTypRender | fn : item.definitionOfIncome" class="col-xs-6 col-sm-3 col-md-3 col-lg-3">
        </app-row-item>
        <app-row-item label="employer.sub-account-type" [data]="item.subAccountType?.join(', ')" class="col-xs-6 col-sm-3 col-md-3 col-lg-3"> </app-row-item>
        <app-row-item
          label="employer.voluntary-contribution-rule-in-common-standard"
          [data]="item.voluntaryContributionRuleInCommonStandard"
          class="col-xs-6 col-sm-3 col-md-3 col-lg-3">
        </app-row-item>
        <ng-container *ngIf="item.definitionOfService === CalMnthServiceTypE.ADOE">
          <app-row-item
            label="employer.specified-period-type-of-adoe"
            [data]="calBaseUntRender | fn : item.specifiedPeriodTypeOfADOE"
            class="col-xs-6 col-sm-3 col-md-3 col-lg-3">
          </app-row-item>
          <app-row-item label="employer.value-of-specified-period-adoe" [data]="item.valueOfSpecifiedPeriodOfADOE" class="col-xs-6 col-sm-3 col-md-3 col-lg-3">
          </app-row-item>
        </ng-container>
      </div>

      <div class="row l-plr-0">
        <div class="col-xs-12">
          <div class="cds-xs-12 cds-color-green cds-body2 font-weight-6 font-style-italic">For Relevant Income only</div>
          <cds-table [dataSource]="item.relevantIncomeList || []" [class.full]="true">
            <ng-container cdsColumnDef="completedMonthOfServiceFrom">
              <cds-header-cell *cdsHeaderCellDef>
                {{item.relevantIncomeList?.[0]?.rangeOfTheRelevantIncomeAgeFrom  !== undefined?
                      'From range of relevant income/ age':'Completed month of service from'}}
              </cds-header-cell>
              <cds-cell *cdsCellDef="let element">
                {{(item.relevantIncomeList?.[0]?.rangeOfTheRelevantIncomeAgeFrom !== undefined?
                  element.rangeOfTheRelevantIncomeAgeFrom:element.completedMonthOfServiceFrom) | number : '2.0-0' }}</cds-cell
              >
            </ng-container>

            <ng-container cdsColumnDef="completedMonthOfServiceTo">
              <cds-header-cell *cdsHeaderCellDef>
                {{item.relevantIncomeList?.[0]?.rangeOfTheRelevantIncomeAgeTo !== undefined?
                    'To range of relevant income/ age':'Completed month of service to'}}</cds-header-cell
              >
              <cds-cell *cdsCellDef="let element">{{ (item.relevantIncomeList?.[0]?.rangeOfTheRelevantIncomeAgeTo !== undefined?
                  element.rangeOfTheRelevantIncomeAgeTo:element.completedMonthOfServiceTo) | number : '2.0-0' }}</cds-cell>
            </ng-container>

            <ng-container cdsColumnDef="contributionRate">
              <cds-header-cell *cdsHeaderCellDef>Contribution%</cds-header-cell>
              <cds-cell *cdsCellDef="let element">
                {{ element.contributionRate | percent : '1.2-2' }}
              </cds-cell>
            </ng-container>

            <ng-container cdsColumnDef="contributionAmount">
              <cds-header-cell *cdsHeaderCellDef>Contribution Amount</cds-header-cell>
              <cds-cell *cdsCellDef="let element">{{ (element.contributionAmount | number) || '/' }} </cds-cell>
            </ng-container>

            <ng-container cdsColumnDef="cappedAmount">
              <cds-header-cell *cdsHeaderCellDef>CappedAmount</cds-header-cell>
              <cds-cell *cdsCellDef="let element">{{ (element.cappedAmount | number) || '/' }}</cds-cell>
            </ng-container>

            <cds-header-row class="cds-detail2-demibold" *cdsHeaderRowDef="relevantIncomeDisplayedColumns"></cds-header-row>
            <cds-row class="cds-body2" *cdsRowDef="let row; columns: relevantIncomeDisplayedColumns"></cds-row>
          </cds-table>
        </div>
      </div>
    </app-cd-accordion-panel>
  </app-cd-accordion>
</ng-container>
