<app-loading [overlay]="true" *ngIf="isLoading"></app-loading>
<div class="row l-pt-8">
  <div class="title-img">
    <cds-icon size="md" color="#00A758" icon="people:login_1"></cds-icon>
  </div>
  <div class="flex l-jc-sb">
    <div>
      <div>
        <div class="cds-h4-bold item-title no-margin bold">{{ detailData.lastName }}{{ detailData.firstName }}</div>
        <div class="item-value cds-body1-demibold-margin bold">{{ detailData.lastZhName }}{{ detailData.firstZhName }}</div>
      </div>
      <div class="flex l-ai-cen l-mt-2 l-mb-2">
        <div class="dot-icon" [style]="service.getStatusColorStyle | fn : detailData.status : employeeStatusMapObj"></div>
        <div class="des-text cds-body1 no-margin margin-left-common">
          {{ service.getStatus | fn : detailData.status : employeeStatusMapObj }}
        </div>
      </div>
      <div *ngIf="detailData?.latestUpdate" class="row middle-xs l-plr-0 l-pt-1 cds-body2">
        <span class="l-mr-1">Member Account Code:</span>
        <span class="l-mr-1">
          <span>{{ detailData.memberAccountType }}: </span>
          <span>{{ detailData.memberAccountCode }}</span>
        </span>
      </div>
    </div>
  </div>

  <div class="col-xs-12 submission-container l-mb-7">
    <div class="row">
      <div class="row l-plr-0">
        <div class="title-icon">
          <cds-icon color="#00A758" width="40px" height="40px" icon="form:document"></cds-icon>
        </div>
        <div class="col-xs">
          <div class="row l-plr-0">
            <div class="col-xs-12">
              <div class="l-mb-1">
                <span class="cds-body2">Group Reference No.</span>
              </div>
              <div>
                <span class="cds-h4-demibold weight">
                  {{ detailData.groupReferenceNo }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-xs-12 p-line"></div>
  </div>

  <div class="row full-width">
    <app-row-item label=" " data=" " style="width: 54px"></app-row-item>
    <app-row-item label="Workflow Reference No." [data]="detailData.workflowReferenceNo" class="col-xs-6 col-sm-3 col-md-3 col-lg-3 l-plr-0"> </app-row-item>
  </div>
</div>

<div class="row">
  <div class="col-xs-12 col-sm-4 l-plr-0 l-mb-8">
    <cds-dropdown [(ngModel)]="withdrawalReferenceNo" [config]="dropdownConfig" (cdsChange)="onSelectChange($event)"></cds-dropdown>
  </div>
</div>

<div class="row l-mt-2 l-mb-8">
  <div class="col-xs-12 l-plr-0">
    <span class="cds-body-hero">Withdrawal Information</span>
  </div>
</div>

<div class="row">
  <div class="col-xs-12 l-plr-0">
    <div class="l-mb-1">
      <span class="cds-body1-demibold green-title">Basic Information</span>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-xs-12 l-plr-0 p-line"></div>
</div>

<div class="row">
  <app-row-item label="Withdrawal Status" class="col-xs-6 col-sm-3 col-md-3 col-lg-3 l-plr-0">
    <ng-template appRowItemContentDef>
      <app-icon-status [value]="detailData.statusCode" [statusMapObj]="policyWithdrawalStatusCodeMapObj"></app-icon-status>
    </ng-template>
  </app-row-item>
  <app-row-item label="Original Submission Date" [data]="detailData.originalSubmissionDate | ssmsDate" class="col-xs-6 col-sm-3 col-md-3 col-lg-3 l-plr-0">
  </app-row-item>
  <app-row-item
    label="Submission Channel"
    [data]="submissionChannelRender | fn : detailData.submissionChannel"
    class="col-xs-6 col-sm-3 col-md-3 col-lg-3 l-plr-0">
  </app-row-item>
  <app-row-item label="Claim Type Reason" [data]="claimTypeReasonRender | fn : detailData.claimTypeReason" class="col-xs-6 col-sm-3 col-md-3 col-lg-3 l-plr-0">
  </app-row-item>
  <app-row-item label="Partial Withdrawal Flag" [checked]="detailData.partialWithdrawalFlag === 'Y'" class="col-xs-6 col-sm-3 col-md-3 col-lg-3 l-plr-0">
  </app-row-item>
  <app-row-item
    label="Completed Receipt Date of Claim"
    [data]="detailData.completedReceiptDateOfClaim | ssmsDate"
    class="col-xs-6 col-sm-3 col-md-3 col-lg-3 l-plr-0">
  </app-row-item>
  <app-row-item label="Account Termination Flag" [checked]="detailData.accountTerminationFlag === 'Y'" class="col-xs-6 col-sm-3 col-md-3 col-lg-3 l-plr-0">
  </app-row-item>
</div>

<div class="row l-mt-6">
  <div class="col-xs-12 l-plr-0">
    <div class="l-mb-1 title-with-checkbox">
      <span class="cds-body1-demibold green-title">Standing Instruction</span>
      <cds-checkbox [disabled]="true" [checked]="detailData.standingInstructionFlag === 'Y'"></cds-checkbox>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-xs-12 l-plr-0 p-line"></div>
</div>

<div class="row">
  <app-row-item label="Effective Date" [data]="detailData.effectiveDate | ssmsDate" class="col-xs-6 col-sm-3 col-md-3 col-lg-3 l-plr-0"> </app-row-item>
  <app-row-item label="Frequency" [data]="detailData.frequency" class="col-xs-6 col-sm-3 col-md-3 col-lg-3 l-plr-0"> </app-row-item>
  <app-row-item label="Amount" [data]="detailData.amount | number : '1.2-2'" class="col-xs-6 col-sm-3 col-md-3 col-lg-3 l-plr-0"> </app-row-item>
</div>

<div class="row l-mt-6">
  <div class="col-xs-12 l-plr-0">
    <div class="l-mb-1 title-with-checkbox">
      <span class="cds-body1-demibold green-title">Permanent Departure Claim</span>
      <cds-checkbox [disabled]="true" [checked]="detailData.permanentDepartureClaimFlag === 'Y'"></cds-checkbox>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-xs-12 l-plr-0 p-line"></div>
</div>

<div class="row">
  <app-row-item label="Mpfa Consent Status Code" [data]="detailData.mpfaConsentStatusCode" class="col-xs-6 col-sm-3 col-md-3 col-lg-3 l-plr-0"> </app-row-item>
  <app-row-item label="Claim Code" [data]="detailData.claimCode" class="col-xs-6 col-sm-3 col-md-3 col-lg-3 l-plr-0"> </app-row-item>
  <app-row-item label="Permanent Departure Claim" [data]="detailData.pdpReportedDate" class="col-xs-6 col-sm-3 col-md-3 col-lg-3 l-plr-0"> </app-row-item>
  <app-row-item label="Mpfa PD No." [data]="detailData.mpfaPDNo" class="col-xs-6 col-sm-3 col-md-3 col-lg-3 l-plr-0"> </app-row-item>
  <app-row-item label="PDP Reported Date" [data]="detailData.pdpReportedDate | ssmsDate" class="col-xs-6 col-sm-3 col-md-3 col-lg-3 l-plr-0"> </app-row-item>
  <app-row-item label="Departure Date" [data]="detailData.departureDate | ssmsDate" class="col-xs-6 col-sm-3 col-md-3 col-lg-3 l-plr-0"> </app-row-item>
  <app-row-item label="Departure Reason" [data]="detailData.departureReason" class="col-xs-6 col-sm-3 col-md-3 col-lg-3 l-plr-0"> </app-row-item>
  <app-row-item label="Destination (Country)" [data]="detailData.destinationCountry" class="col-xs-6 col-sm-3 col-md-3 col-lg-3 l-plr-0"> </app-row-item>
  <app-row-item label="Mpfa Request Date" [data]="detailData.mpfaRequestDate | ssmsDate" class="col-xs-6 col-sm-3 col-md-3 col-lg-3 l-plr-0"> </app-row-item>
  <app-row-item label="Mpfa Response Date" [data]="detailData.mpfaResponseDate | ssmsDate" class="col-xs-6 col-sm-3 col-md-3 col-lg-3 l-plr-0"> </app-row-item>
</div>

<div class="row l-mt-6">
  <div class="col-xs-12 l-plr-0">
    <div class="l-mb-1 title-with-checkbox">
      <span class="cds-body1-demibold green-title">Bankruptcy (ORO/ TIB)</span>
      <cds-checkbox [disabled]="true" [checked]="detailData.bankruptcy === 'Y'"></cds-checkbox>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-xs-12 l-plr-0 p-line"></div>
</div>

<div class="row">
  <app-row-item label="ORO/ TIB Consent Status Code" [data]="detailData.oroTIBConsentStatusCode" class="col-xs-6 col-sm-3 col-md-3 col-lg-3 l-plr-0">
  </app-row-item>
  <app-row-item label="Pay Basis Type" [data]="detailData.payBasisType" class="col-xs-6 col-sm-3 col-md-3 col-lg-3 l-plr-0"> </app-row-item>
  <app-row-item label="Pay Basis - Value" [data]="detailData.payBasisValue" class="col-xs-6 col-sm-3 col-md-3 col-lg-3 l-plr-0"> </app-row-item>
  <app-row-item
    label="Adjusted Withdrawal Amount by ORO or other Party"
    [data]="detailData.adjustedWithdrawalAmountByOroOrOtherParty"
    class="col-xs-6 col-sm-3 col-md-3 col-lg-3 l-plr-0">
  </app-row-item>
  <app-row-item label="ORO Request Date" [data]="detailData.oroRequestDate | ssmsDate" class="col-xs-6 col-sm-3 col-md-3 col-lg-3 l-plr-0"> </app-row-item>
  <app-row-item label="ORO Response Date" [data]="detailData.oroResponseDate | ssmsDate" class="col-xs-6 col-sm-3 col-md-3 col-lg-3 l-plr-0"> </app-row-item>
  <app-row-item label="Return issue code for ORO" [data]="detailData.returnIssueCodeForOro" class="col-xs-6 col-sm-3 col-md-3 col-lg-3 l-plr-0"> </app-row-item>
</div>

<div class="row l-mt-6">
  <div class="col-xs-12 l-plr-0">
    <div class="l-mb-1">
      <span class="cds-body1-demibold green-title">ORO/ TIB</span>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-xs-12 l-plr-0 p-line"></div>
</div>

<app-contact-persion [contactPersonList]="getOroOrTibContactPersonList | fn : detailData.contactPersonList" topTitle=""></app-contact-persion>

<div class="row l-mt-6">
  <div class="col-xs-12 l-plr-0">
    <div class="l-mb-1">
      <span class="cds-body1-demibold green-title">Claimant</span>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-xs-12 l-plr-0 p-line"></div>
</div>

<app-contact-persion [contactPersonList]="getClaimantPersonList | fn : detailData.contactPersonList" topTitle=""></app-contact-persion>

<div class="row l-mt-6">
  <div class="col-xs-12 l-plr-0">
    <div class="l-mb-1">
      <span class="cds-body1-demibold green-title">Withdrawal Payee</span>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-xs-12 l-plr-0 p-line"></div>
</div>

<app-cd-accordion>
  <app-cd-accordion-panel
    *ngFor="let item of detailData.withdrawalPayeeList; index as itemIndex"
    [title]="nameRender | fn : item"
    titleClass="cds-h5-bold"
    [miniTitle]="item.paymentOutStatus"
    miniTitleClass="cds-body2-bold">
    <div class="col-xs-12 row">
      <app-row-item label="Payment Out Reference No." [data]="item.paymentOutReferenceNo" class="col-xs-6 col-sm-3 col-md-3 col-lg-3 l-plr-0"> </app-row-item>
      <app-row-item label="Payment Method" [data]="item.paymentMethod" class="col-xs-6 col-sm-3 col-md-3 col-lg-3 l-plr-0"> </app-row-item>
      <app-row-item label="Payment Amount" [data]="item.paymentAmount" class="col-xs-6 col-sm-3 col-md-3 col-lg-3 l-plr-0"> </app-row-item>
      <app-row-item label="Currency of Payment" [data]="item.currencyOfPayment" class="col-xs-6 col-sm-3 col-md-3 col-lg-3 l-plr-0"> </app-row-item>
      <app-row-item label="Payment Cleared Date" [data]="item.paymentClearedDate | ssmsDate" class="col-xs-6 col-sm-3 col-md-3 col-lg-3 l-plr-0">
      </app-row-item>
      <app-row-item label="Cheque Number" [data]="item.chequeNumber" class="col-xs-6 col-sm-3 col-md-3 col-lg-3 l-plr-0"> </app-row-item>
      <app-row-item label="Cheque Issue Date" [data]="item.chequeIssueDate | ssmsDate" class="col-xs-6 col-sm-3 col-md-3 col-lg-3 l-plr-0"> </app-row-item>
      <app-row-item label="Payment Reject Reason" [data]="item.paymentRejectReason" class="col-xs-6 col-sm-3 col-md-3 col-lg-3 l-plr-0"> </app-row-item>
    </div>
  </app-cd-accordion-panel>
</app-cd-accordion>
