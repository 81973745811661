import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { CdsButtonConfig } from '@cds/ng-core/button';
import { CdsOption } from '@cds/ng-core/configuration';
import { CdsPopupService } from '@cds/ng-web-components/popup';
import { DateTime } from 'luxon';
import { finalize } from 'rxjs';
import { PermissionAccess, PermissionItem } from 'src/app/core/models/enum/permission.enum';
import { ToastAlertService } from 'src/app/shared/toast-alert.service';
import { dateValidator } from 'src/app/shared/validators/validators';
import {
  BillingClass,
  ContributionAmount,
  ContributionAmountType,
  ContributionDetails,
  ContributionDetailsCategory,
  ContributionDetailsCategoryE,
  ContributionSortByE,
} from '../../employer';
import { EmployerService } from '../../employer.service';
import { OutstandingAmountOfPaymentPopupComponent } from '../outstanding-amount-of-payment-popup/outstanding-amount-of-payment-popup.component';
import { PermissionService } from 'src/app/core/services/permission.service';

@Component({
  selector: 'app-account-info',
  templateUrl: './account-info.component.html',
  styleUrls: ['./account-info.component.scss'],
})
export class AccountInfoComponent implements OnInit, OnChanges {
  @Input() billingClass?: BillingClass;
  @Input() employerId? = '';

  public get startDate(): string {
    return this.startDateFormControl.value;
  }

  ContributionDetailsCategoryE = ContributionDetailsCategoryE;

  contributionDetails: ContributionDetails = {
    contributionPeriodStartDate: '',
    contributionPeriodEndDate: '',
    billingReferenceNumber: '',
    billingStatusType: '',
    contributionFrequency: '',
    outstandingAmountOfPayment: 0,
    remittanceStatementReceivedDate: '',
    lives: 0,
    contLives: 0,
    billContributionAmount: undefined,
    reportedMemberContributionAmount: undefined,
    paidAmount: undefined,
    outstandingPaymentAmount: undefined,
  };

  searchButtonConfig: CdsButtonConfig = {
    size: 'sm',
  };

  resetButtonConfig: CdsButtonConfig = {
    size: 'sm',
    style: 'secondary',
  };

  nowTime = new Date();

  nowTimeInstr = DateTime.now().toFormat('dd/MM/yyyy');

  contributionTableDataSource: ContributionAmount[] = [];

  contributionTablDisplayedColumns = ['category', 'ermc', 'eemc', 'surcharge', 'ervc', 'eevc'];

  selectedContributionSortBy: ContributionSortByE = ContributionSortByE.All;

  contributionSortBy: CdsOption[] = [
    {
      label: 'All',
      value: ContributionSortByE.All,
    },
    {
      label: 'DC REPORTED – successfully reported to MPFA',
      value: ContributionSortByE.DC_REPORTED,
    },
    {
      label: 'DC NOT_REPORTED - Not reported to MPFA',
      value: ContributionSortByE.DC_NOT_REPORTED,
    },
    {
      label: 'Reported Contribution as 0',
      value: ContributionSortByE.Reported_Contribution_0,
    },
    {
      label: 'DC GENERATED - reported to MPFA, pending to validate',
      value: ContributionSortByE.DC_GENERATED,
    },
    {
      label: 'Fully Settled',
      value: ContributionSortByE.Fully_Settled,
    },
    {
      label: 'VC Outstanding Only',
      value: ContributionSortByE.VC_Outstanding_Only,
    },
  ];

  resetButtonDisabled = true;

  startDateFormControl = new FormControl('', [dateValidator('create.dateError', '', this.nowTimeInstr)]);

  get searchButtonDisabled() {
    return !this.startDateFormControl?.valid || !this.startDateFormControl?.dirty;
  }

  isLoading = false;

  showBillInfo = false;

  PermissionAccess = PermissionAccess;

  PermissionItem = PermissionItem;

  showDatePicker = true;

  isStaff = false;

  constructor(
    private cdsPopup: CdsPopupService,
    private employerService: EmployerService,
    private toastAlert: ToastAlertService,
    private permissionService: PermissionService
  ) {}

  ngOnInit(): void {
    this.permissionFilter();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['billingClass'].currentValue !== undefined && changes['billingClass'].currentValue !== changes['billingClass'].previousValue) {
      this.search(true);
    }
  }

  pillSelectedChange(selected: boolean, sortBy: ContributionSortByE) {
    if (selected) {
      this.selectedContributionSortBy = sortBy;
      this.filter();
    }
  }

  permissionFilter() {
    this.permissionService.hasPermission(PermissionAccess.R, PermissionItem.CUSTOMER_EMPLOYER).then(res => {
      this.isStaff = res;
    });
  }

  popupDetail(category: ContributionDetailsCategory, type: ContributionAmountType, amount: number) {
    if (amount === 0) {
      return;
    }
    this.cdsPopup.open(OutstandingAmountOfPaymentPopupComponent, {
      size: 'lg',
      data: {
        category,
        type,
        contributionDetails: this.contributionDetails,
      },
    });
  }

  toDetail() {}

  filter() {
    this.search(true);
  }

  search(directly = false, resetFilter = false) {
    if (this.searchButtonDisabled && !directly) {
      return;
    }

    if (this.billingClass === undefined) {
      return;
    }

    if (resetFilter) {
      this.selectedContributionSortBy = ContributionSortByE.All;
    }

    this.isLoading = true;

    this.employerService
      .getContributionDetails(this.employerId, this.selectedContributionSortBy, this.billingClass.payrollGroupId, this.startDate)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe(data => {
        if (data.result === 0) {
          if (data.data && data.data.contributionPeriodStartDate) {
            this.contributionDetails = data.data;
            this.contributionTableDataSource = this.employerService.contributionAmountTableDataTrans(data.data);
            this.showBillInfo = true;
          } else {
            this.showBillInfo = false;
          }
        } else {
          this.toastError(data.message);
        }
        if (!directly && this.outofPeriod()) {
          this.resetButtonDisabled = false;
        }
      });
  }

  outofPeriod() {
    const currentDate = DateTime.fromFormat(DateTime.now().toFormat('yyyy-MM-dd'), 'yyyy-MM-dd');
    const startDate = DateTime.fromFormat(this.contributionDetails?.contributionPeriodStartDate || '', 'yyyy-MM-dd');
    const endDate = DateTime.fromFormat(this.contributionDetails?.contributionPeriodEndDate || '', 'yyyy-MM-dd');
    if (currentDate < startDate || currentDate > endDate) {
      return true;
    }
    return false;
  }

  current() {
    return new Date();
  }

  reset() {
    if (this.resetButtonDisabled) {
      return;
    }
    this.resetButtonDisabled = true;
    this.startDateFormControl.setValue('');
    this.startDateFormControl.markAsPristine();
    // this.validating(this.startDate);
    this.showDatePicker = false;
    setTimeout(() => {
      this.showDatePicker = true;
      this.search(true, true);
    });
  }

  getCategoryTitle(category: ContributionDetailsCategory) {
    if (!category) {
      return '';
    }
    return ContributionDetailsCategoryE[category];
  }

  canClick(amount: number | string) {
    if (amount === 0 || amount === '0') {
      return false;
    }
    return true;
  }

  toastError(errorMessage: string) {
    this.toastAlert.show('error', 'common.error', errorMessage, 5000);
  }
}
