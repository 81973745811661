<div class="row dropdown" [formGroup]="form" *ngIf="formReady">
  <div class="col-xs-12 l-pt-7">
    <span class="cds-h2-light">{{ 'list.serach-record' | lang }}</span>
  </div>
  <div class="col-xs-6 col-sm-3 col-md-3 col-lg-3 l-pt-4 l-pb-4">
    <cds-textfield
      formControlName="withdrawalReferenceNo"
      [label]="'ee.withdrawal-reference-no' | lang"
      [placeholder]="'ee.input-withdrawal-reference-no' | lang"></cds-textfield>

    <div *ngIf="withdrawalReferenceNo?.invalid && (withdrawalReferenceNo?.dirty || withdrawalReferenceNo?.touched)" class="l-pt-2">
      <cds-assistive-text *ngIf="withdrawalReferenceNo?.errors?.['maxlength']" class="l-pt-2" [showIcon]="false">Max length is 15</cds-assistive-text>

      <cds-assistive-text
        *ngIf="withdrawalReferenceNo?.errors?.['invalidNumberString']"
        class="l-pt-2"
        [showIcon]="false"
        >{{ withdrawalReferenceNo?.errors?.['invalidNumberString'] }}</cds-assistive-text
      >
    </div>
  </div>

  <div class="col-xs-6 col-sm-3 col-md-3 col-lg-3 l-pt-4 l-pb-4">
    <cds-textfield
      formControlName="groupReferenceNo"
      [label]="'ee.group-reference-no' | lang"
      [placeholder]="'ee.input-group-reference-no' | lang"></cds-textfield>

    <div *ngIf="withdrawalReferenceNo?.invalid && (groupReferenceNo?.dirty || groupReferenceNo?.touched)" class="l-pt-2">
      <cds-assistive-text *ngIf="groupReferenceNo?.errors?.['maxlength']" class="l-pt-2" [showIcon]="false">Max length is 15</cds-assistive-text>

      <cds-assistive-text
        *ngIf="groupReferenceNo?.errors?.['invalidNumberString']"
        class="l-pt-2"
        [showIcon]="false"
        >{{ groupReferenceNo?.errors?.['invalidNumberString'] }}</cds-assistive-text
      >
    </div>
  </div>

  <div class="col-xs-6 col-sm-3 col-md-3 col-lg-3 l-pt-4 l-pb-4">
    <cds-textfield
      formControlName="paymentOutReferenceNo"
      [label]="'ee.payment-out-reference-no' | lang"
      [placeholder]="'ee.input-payment-out-reference-noda' | lang"></cds-textfield>

    <div *ngIf="withdrawalReferenceNo?.invalid && (paymentOutReferenceNo?.dirty || paymentOutReferenceNo?.touched)" class="l-pt-2">
      <cds-assistive-text *ngIf="paymentOutReferenceNo?.errors?.['maxlength']" class="l-pt-2" [showIcon]="false">Max length is 15</cds-assistive-text>

      <cds-assistive-text
        *ngIf="paymentOutReferenceNo?.errors?.['invalidNumberString']"
        class="l-pt-2"
        [showIcon]="false"
        >{{ paymentOutReferenceNo?.errors?.['invalidNumberString'] }}</cds-assistive-text
      >
    </div>
  </div>

  <div class="col-xs-6 col-sm-3 col-md-3 col-lg-3 l-pt-4 l-pb-4" *ngIf="showDatePicker">
    <cds-textfield
      formControlName="originalSubmissionDate"
      placeholder="DD/MM/YYYY"
      label="Date of Transfer"
      [max]="nowTimeInstr"
      [cdsDatepicker]="originalSubmissionDatePicker">
    </cds-textfield>
    <cds-datepicker #originalSubmissionDatePicker="cdsDatepicker"></cds-datepicker>

    <div *ngIf="originalSubmissionDate?.invalid && (originalSubmissionDate?.dirty || originalSubmissionDate?.touched)" class="l-pt-2">
      <cds-assistive-text
        *ngIf="originalSubmissionDate?.errors?.['dateInvalid'] || originalSubmissionDate?.errors?.['startLaterThanEnd']"
        class="l-pt-2"
        [showIcon]="false"
        >{{ 'create.dateError' | lang }}</cds-assistive-text
      >
    </div>
  </div>

  <div class="col-xs-6 col-sm-3 col-md-3 col-lg-3 l-pt-4 l-pb-4">
    <cds-dropdown formControlName="withdrawalStatus" [config]="statusCodeConfig"> </cds-dropdown>
  </div>
  <div class="col-xs-6 col-sm-3 col-md-3 col-lg-3 l-pt-4 l-pb-4">
    <cds-dropdown formControlName="paymentOutStatus" [config]="paymentOutStatusConfig"></cds-dropdown>
  </div>
  <div class="col-xs-6 col-sm-3 col-md-3 col-lg-3 l-pt-4 l-pb-4">
    <cds-dropdown formControlName="claimTypeReason" [config]="claimTypeReasonConfig"></cds-dropdown>
  </div>

  <div class="col-xs-6 col-sm-3 col-md-3 col-lg-3 l-pt-4 l-pb-4" *ngIf="showDatePicker">
    <cds-textfield
      formControlName="completedReceiptDateOfClaim"
      placeholder="DD/MM/YYYY"
      label="Original Requested Submission Date"
      [max]="nowTimeInstr"
      [cdsDatepicker]="completedReceiptDateOfClaimPicker">
    </cds-textfield>
    <cds-datepicker #completedReceiptDateOfClaimPicker="cdsDatepicker"></cds-datepicker>

    <div *ngIf="completedReceiptDateOfClaim?.invalid && (completedReceiptDateOfClaim?.dirty || completedReceiptDateOfClaim?.touched)" class="l-pt-2">
      <cds-assistive-text
        *ngIf="completedReceiptDateOfClaim?.errors?.['dateInvalid'] || completedReceiptDateOfClaim?.errors?.['startLaterThanEnd']"
        class="l-pt-2"
        [showIcon]="false"
        >{{ 'create.dateError' | lang }}</cds-assistive-text
      >
    </div>
  </div>

  <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3 l-mt-2 l-pb-2 l-pt-2 btns">
    <cds-button
      class="l-mr-4 botton"
      [disabled]="searchButtonDisabled"
      [config]="searchButtonConfig"
      label="{{ 'list.search' | lang }}"
      (click)="search()"></cds-button>
    <cds-button class="botton" [disabled]="resetButtonDisabled" [config]="resetButtonConfig" label="{{ 'list.reset' | lang }}" (click)="reset()"></cds-button>
  </div>
</div>

<div class="row">
  <div class="col-xs-12 l-pt-7 l-pb-2">
    <span class="cds-h2-light">{{ 'list.record-list' | lang }}</span>
  </div>
  <div class="col-xs-12">
    <span class="cds-body1">Total {{ totalElements | number }} records</span>
  </div>

  <div class="col-xs-12 col-md-12">
    <div class="min-height">
      <app-data-table
        [frontPagination]="false"
        [isLoading]="false"
        [fixed]="false"
        [overlay]="true"
        [pageConfig]="pageConfig"
        [dataSource]="dataSource"
        [columnsConfig]="columnsConfig"
        (reloadTable)="reloadTable($event)">
        <ng-template appDtBodyCellDef="withdrawalReferenceNo" let-element>
          <span (click)="goToDetail(element['withdrawalReferenceNo'])" class="col-xs middle-xs l-plr-0">
            <span class="green-line pointer">
              <span class="cds-body1-bold">{{ element['withdrawalReferenceNo'] }}</span>
            </span>
          </span>
        </ng-template>

        <ng-template appDtBodyCellDef="groupReferenceNo" let-element>
          <span class="cds-h5-bold font-weight-6 green-line pointer">{{ element['groupReferenceNo'] }}</span>
        </ng-template>

        <ng-template appDtBodyCellDef="claimTypeReason" let-element>
          <span>{{ claimTypeReasonRender | fn : element['claimTypeReason'] }}</span>
        </ng-template>

        <ng-template appDtBodyCellDef="withdrawalStatus" let-element>
          <app-icon-status [value]="element.withdrawalStatus" [statusMapObj]="policyWithdrawalStatusCodeMapObj"></app-icon-status>
        </ng-template>

        <!-- <ng-template appDtBodyCellDef="paymentOutReferenceNo" let-element>
          <app-icon-status [value]="element.paymentOutReferenceNo" [statusMapObj]="policyWithdrawalStatusCodeMapObj"></app-icon-status>
        </ng-template> -->

        <!-- <ng-template appDtBodyCellDef="paymentOutReferenceNo" let-element>
          <span>{{ employerService.optionLabelRender | fn : element['paymentOutReferenceNo'] : paymentOutStatusOptions }}</span>
        </ng-template> -->

        <ng-template appDtBodyCellDef="originalSubmissionDate" let-element>
          <span>{{ element['originalSubmissionDate'] | ssmsDate }}</span>
        </ng-template>

        <ng-template appDtBodyCellDef="completedReceiptDateOfClaim" let-element>
          <span>{{ element['completedReceiptDateOfClaim'] | ssmsDate }}</span>
        </ng-template>
      </app-data-table>
    </div>
  </div>
</div>
