/* eslint-disable @typescript-eslint/no-explicit-any */
import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { ResponseResult } from 'src/app/core/models/response/response-result';
import { CalendarService } from 'src/app/views/system/calendar/calendar.service';
import { CdPopupService, MatDialogRef, CDS_POPUP_DATA } from 'src/app/shared/cd-popup';
import { I18N_KEY_FOR_API_RES } from '../../calendar.config';

@Component({
  selector: 'app-edit-declined-commission',
  templateUrl: './edit-declined-commission.component.html',
  styleUrls: ['./edit-declined-commission.component.scss'],
})
export class EditDeclinedCommissionComponent implements OnInit {
  commissionEventInitValue: number[][] = [];

  saveParams: any; // call api params
  disabled = true; // for confirms button

  constructor(
    private dialogRef: MatDialogRef<EditDeclinedCommissionComponent>,
    private ref: ChangeDetectorRef,
    private cdsPopup: CdPopupService,
    public calendarService: CalendarService,
    @Inject(CDS_POPUP_DATA)
    public data: {
      event: any;
    }
  ) {}

  ngOnInit(): void {
    const events = this.data.event.events;
    this.initPreviousDeclineData(events);
  }

  initPreviousDeclineData(data: any[]) {
    if (data && data.length > 0) {
      data.forEach(item => {
        const data = new Date(item.eventDate);
        const eventMonth = data.getMonth();
        const eventDay = data.getDate();
        if (!this.commissionEventInitValue[eventMonth]) {
          this.commissionEventInitValue[eventMonth] = [];
        }
        this.commissionEventInitValue[eventMonth].push(eventDay);
        this.commissionEventInitValue[eventMonth].sort((a, b) => a - b);
      });
    }
  }

  commissionChange($event: any) {
    this.disabled = $event.invalid;
    this.saveParams = [];
    $event.value.forEach((item: string) => {
      this.saveParams.push({
        eventEndDate: `${this.calendarService.nextYear}-${item}`,
      });
    });
    this.ref.detectChanges();
  }

  cancle() {
    this.dialogRef.close();
  }

  reSubmit() {
    if (this.disabled) {
      return;
    }
    this.calendarService.addCommissionEvents(this.saveParams).subscribe(res => {
      if (res.result == ResponseResult.SUCCESS) {
        this.calendarService.declineEvents = this.calendarService.declineEvents.filter(item => item.eventType !== this.data.event.eventType);
        const popupRef: MatDialogRef<any> = this.cdsPopup.openCommon();
        popupRef.afterClosed().subscribe(() => {
          this.dialogRef.close();
        });
      } else {
        this.cdsPopup.openCommon({
          data: {
            message: I18N_KEY_FOR_API_RES[res.message] || res.message,
          },
        });
      }
    });
  }
}
