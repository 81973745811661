<cds-popup class="popup no-close-icon">
  <cds-popup-title>
    <div class="col-xs-12 cds-h2-light" *ngIf="inEdit">Edit Record</div>
    <div class="col-xs-12" *ngIf="!inEdit">
      <div class="cds-h2-light l-mb-2">Records Summary</div>
      <div class="cds-body1">Please check to confirm and edit the record.</div>
    </div>
    <div class="close-icon" (click)="close()">
      <cds-icon icon="action:cross"></cds-icon>
    </div>
  </cds-popup-title>

  <cds-popup-content>
    <div class="row l-plr-0">
      <div class="col-xs-12 info-area">
        <cds-icon icon="people:login_1" [config]="iconConfig"></cds-icon>
        <span class="l-ml-2 cds-h4-demibold">{{ data.agentCode }}</span>
        <span class="l-ml-4 cds-h4-demibold">{{ data.agentName }}</span>
        <span class="l-ml-2 cds-h4-demibold">{{ data.agentNameChi }}</span>
      </div>

      <div class="col-xs-12 l-mb-4">
        <div class="line"></div>
      </div>
    </div>

    <form class="relative">
      <app-loading *ngIf="isLoading" [overlayInPopup]="true"></app-loading>
      <div class="row l-plr-0" [formGroup]="form" [class.hidden]="!inEdit">
        <div class="col-md-6 col-xs-12 l-mb-6" *ngIf="showDatePicker">
          <cds-textfield
            label="SEP Commencement Date"
            formControlName="sepCommDate"
            (cdsChange)="onControlChange('sepCommDate')"
            placeholder="DD/MM/YYYY"
            [cdsDatepicker]="sepCommDatePicker"
            [max]="getMaxFromDate | fn : maxTimeInStr">
          </cds-textfield>
          <cds-datepicker #sepCommDatePicker="cdsDatepicker"></cds-datepicker>

          <div *ngIf="sepCommDateControl?.invalid" class="l-mt-2">
            <cds-assistive-text *ngIf="sepCommDateControl?.errors?.['required']" class="l-pt-2" [showIcon]="false"
              >Missing SEP Commencement Date</cds-assistive-text
            >
            <cds-assistive-text *ngIf="sepCommDateControl?.errors?.['dateInvalid'] || sepCommDateControl?.errors?.['startLaterThanEnd']" [showIcon]="false"
              >Please input SEP Commencement Date with format DD/MM/YYYY</cds-assistive-text
            >

            <cds-assistive-text
              *ngIf="sepCommDateControl?.errors?.['backendInvalid']"
              [showIcon]="false"
              >{{ sepCommDateControl?.errors?.['backendInvalid'] }}</cds-assistive-text
            >
          </div>

          <div *ngIf="getWarnMessage('sepCommDate')" class="warn-valid-message">
            <cds-assistive-text [showIcon]="false">{{ getWarnMessage('sepCommDate') }}</cds-assistive-text>
          </div>
        </div>

        <div class="col-md-6 col-xs-12 l-mb-6" *ngIf="showDatePicker">
          <cds-textfield
            formControlName="dob"
            placeholder="DD/MM/YYYY"
            label="Date of Birth"
            (cdsChange)="onControlChange('dob')"
            [max]="maxTime"
            [cdsDatepicker]="dobPicker">
          </cds-textfield>
          <cds-datepicker #dobPicker="cdsDatepicker"></cds-datepicker>

          <div *ngIf="dobControl?.invalid" class="l-mt-2">
            <cds-assistive-text *ngIf="dobControl?.errors?.['required']" class="l-pt-2" [showIcon]="false">Missing Date of Birth</cds-assistive-text>

            <cds-assistive-text *ngIf="dobControl?.errors?.['dateInvalid'] || dobControl?.errors?.['startLaterThanEnd']" class="l-pt-2" [showIcon]="false"
              >Please input Date of Birth with format DD/MM/YYYY</cds-assistive-text
            >

            <cds-assistive-text
              *ngIf="dobControl?.errors?.['backendInvalid']"
              [showIcon]="false"
              >{{ dobControl?.errors?.['backendInvalid'] }}</cds-assistive-text
            >
          </div>

          <div *ngIf="getWarnMessage('dob')" class="warn-valid-message">
            <cds-assistive-text [showIcon]="false">{{ getWarnMessage('dob') }}</cds-assistive-text>
          </div>
        </div>

        <div class="col-md-6 col-xs-12">
          <div class="id-input">
            <div class="id-input-type">
              <cds-dropdown
                *ngIf="showDatePicker"
                (change)="onControlChange('userId')"
                (cdsChange)="dropdownChange($event)"
                formControlName="userIdType"
                [config]="agentSepUserIdTypeConfig"></cds-dropdown>
            </div>
            <div class="id-input-text">
              <cds-textfield
                formControlName="userId"
                (change)="onControlChange('userId')"
                [placeholder]="userIdTypeControl?.value === 'hkid' ? 'A000000(0)' : 'Input Passport'"
                (cdsChange)="userIdChange($event)"></cds-textfield>
            </div>
          </div>
          <div *ngIf="userIdControl?.invalid" class="l-mt-2">
            <cds-assistive-text *ngIf="userIdControl?.errors?.['required']" class="l-pt-2" [showIcon]="false">Missing HKID/ Passport</cds-assistive-text>
            <cds-assistive-text *ngIf="userIdControl?.errors?.['maxlength']" class="l-pt-2" [showIcon]="false">Max length is 80.</cds-assistive-text>
            <cds-assistive-text
              *ngIf="userIdControl?.errors?.['hkidInvalid']"
              [showIcon]="false"
              >{{ userIdControl?.errors?.['hkidInvalid'] }}</cds-assistive-text
            >
            <cds-assistive-text
              *ngIf="userIdControl?.errors?.['passportInvalid']"
              [showIcon]="false"
              >{{ userIdControl?.errors?.['passportInvalid'] }}</cds-assistive-text
            >

            <cds-assistive-text
              *ngIf="userIdControl?.errors?.['backendInvalid']"
              [showIcon]="false"
              >{{ userIdControl?.errors?.['backendInvalid'] }}</cds-assistive-text
            >
          </div>

          <div *ngIf="getWarnMessage('userId')" class="warn-valid-message">
            <cds-assistive-text [showIcon]="false">{{ getWarnMessage('userId') }}</cds-assistive-text>
          </div>

          <div *ngIf="inEdit" class="cds-body2 l-mb-0 l-mt-2 tip-text">
            <div class="l-mb-6" *ngIf="userIdControl?.invalid"></div>
            <div>Please include bracket ( ) when typing HKID</div>
          </div>
        </div>
      </div>

      <div class="row l-plr-0" *ngIf="!inEdit">
        <div class="col-md-6 col-xs-12">
          <app-row-item label="employer.sep-commencement-date" [data]="sepCommDateControl?.value"> </app-row-item>
        </div>

        <div class="col-md-6 col-xs-12">
          <app-row-item label="employer.dob" [data]="dobControl?.value"> </app-row-item>
        </div>

        <div class="col-md-6 col-xs-12">
          <app-row-item label="employer.hkid-passport" [data]="userIdConfirmStr"> </app-row-item>
        </div>
      </div>
    </form>
  </cds-popup-content>

  <cds-popup-actions>
    <div class="col-xs-12" *ngIf="showButton">
      <ng-container *ngIf="inEdit">
        <cds-button [config]="resetButtonConfig" [disabled]="resetDisabled" (click)="reset()">{{ 'common.reset' | lang }}</cds-button>

        <cds-button [config]="saveButtonConfig" [disabled]="saveDisabled" (click)="continue()">{{ 'common.continue' | lang }}</cds-button>
      </ng-container>

      <ng-container *ngIf="!inEdit">
        <cds-button [config]="resetButtonConfig" (click)="backToEdit()">{{ 'common.backToEdit' | lang }}</cds-button>

        <cds-button [config]="saveButtonConfig" (click)="save()" [disabled]="!checkOk">{{ 'common.confirm' | lang }}</cds-button>
      </ng-container>
    </div>
  </cds-popup-actions>
</cds-popup>
