import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ScreenSizeService {
  isLargeScreen = false;
  isSmallScreen = false;
  screenSizeChanged = new Subject<void>();

  constructor() {
    this.checkScreenSize();
    window.addEventListener('resize', () => {
      this.checkScreenSize();
      this.screenSizeChanged.next();
    });
  }

  private checkScreenSize() {
    this.isLargeScreen = window.innerWidth > 430;
    this.isSmallScreen = window.innerWidth <= 430;
  }
}
