/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { BasicResponse } from '../models/response/response';

@Injectable({
  providedIn: 'root',
})
export class ReportingRoleServiceService {
  constructor(private http: HttpClient) {}

  getRoles(params: any): Observable<BasicResponse<any>> {
    return this.http.get<BasicResponse<any>>(`${environment.apiPrefix}${environment.identityServiceExt}/report/role/list`, {
      params: params,
    });
  }

  createRole(params: any): Observable<BasicResponse<any>> {
    return this.http.post<BasicResponse<any>>(`${environment.apiPrefix}${environment.identityServiceExt}/report/role/create`, params);
  }

  getRoleInfo(roleId: string): Observable<BasicResponse<any>> {
    return this.http.get<BasicResponse<any>>(`${environment.apiPrefix}${environment.identityServiceExt}/role/query/${roleId}`);
  }

  updateRoleInfo(params: any): Observable<BasicResponse<any>> {
    return this.http.post<BasicResponse<any>>(`${environment.apiPrefix}${environment.identityServiceExt}/report/role/update`, params);
  }

  deleteRole(roleId: string): Observable<BasicResponse<any>> {
    return this.http.delete<BasicResponse<any>>(`${environment.apiPrefix}${environment.identityServiceExt}/role/delete/${roleId}`);
  }

  getAclUsers(params: any): Observable<BasicResponse<any>> {
    return this.http.get<BasicResponse<any>>(`${environment.apiPrefix}${environment.identityServiceExt}/user/search-all`, {
      params: params,
    });
  }

  addUserToRole(params: any): Observable<BasicResponse<any>> {
    return this.http.post<BasicResponse<any>>(`${environment.apiPrefix}${environment.identityServiceExt}/user/assign`, params);
  }

  getIdentityByRoleId(params: any): Observable<BasicResponse<any>> {
    return this.http.get<BasicResponse<any>>(`${environment.apiPrefix}${environment.identityServiceExt}/user/query/${params.roleId}`, {
      params: params,
    });
  }

  deleteUser(id: string): Observable<BasicResponse<any>> {
    return this.http.delete<BasicResponse<any>>(`${environment.apiPrefix}${environment.identityServiceExt}/user/delete/${id}`);
  }
}
