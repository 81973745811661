import { DataSource } from '@angular/cdk/collections';
import { BehaviorSubject, Observable } from 'rxjs';
import { PermissionItem } from 'src/app/core/models/enum/permission.enum';
import { RolePermissionGroup, RolePermissionGroupList } from '../core/models/enum/role.enum';

export const enum PermissionCheckedStatus {
  TOTAL,
  PARTIALLY,
  NONE,
}
export interface PermissionDto {
  parentId?: RolePermissionGroup;
  groupItem?: RolePermissionGroup;
  permissionItem?: PermissionItem;
  expand?: boolean;
}
const permissionList: PermissionDto[] = [];

for (const [key, value] of RolePermissionGroupList) {
  permissionList.push({
    groupItem: key,
    expand: true,
  });
  value.forEach(p => {
    permissionList.push({
      parentId: key,
      permissionItem: p,
    });
  });
}
export const PermissionList: PermissionDto[] = permissionList;
export class RoleAccessTableData extends DataSource<PermissionDto> {
  constructor(private tableData: PermissionDto[]) {
    super();
  }
  data = new BehaviorSubject<PermissionDto[]>(this.tableData);
  connect(): Observable<PermissionDto[]> {
    return this.data;
  }
  disconnect() {}
}
