import { EmployerService } from './../employer.service';
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { CdsButtonConfig } from '@cds/ng-core/button';
import { CdsIconConfig } from '@cds/ng-core/icon';
import { CdsLangService } from '@cds/ng-core/lang';
import { CdsPopupService } from '@cds/ng-web-components/popup';
import { finalize } from 'rxjs';
import { CommontsPopupComponent } from '../commonts-popup/commonts-popup.component';
import { ToastAlertService } from 'src/app/shared/toast-alert.service';
import { AgentSepCheckResult, AgentSepCheckResultTypeE } from '../employer';

@Component({
  selector: 'app-upload-agent-sep-list-file',
  templateUrl: './upload-agent-sep-list-file.component.html',
  styleUrls: ['./upload-agent-sep-list-file.component.scss'],
})
export class UploadAgentSepListFileComponent implements OnInit {
  show = true;

  inEdit = true;

  cancelButtonConfig: CdsButtonConfig = { size: 'sm', style: 'secondary' };

  confirmButtonConfig: CdsButtonConfig = { size: 'sm' };

  uploadFormControl = new FormControl();

  warnIconConfig: CdsIconConfig = {
    color: '#D77D28',
  };

  errorIconConfig: CdsIconConfig = {
    color: '#C14A36',
  };

  file!: File;

  noFileSelect = true;

  fileAvailable = true;

  showFileErrorMessage = false;

  isLoading = false;

  errorMessageIconConfig: CdsIconConfig = {
    color: '#830A10',
    size: 'xs',
  };

  checked = false;

  checkOk = true;

  showButton = true;

  checkResultInfoList: AgentSepCheckResult[] = [];

  AgentSepCheckResultTypeE = AgentSepCheckResultTypeE;

  constructor(
    private dialogRef: MatDialogRef<UploadAgentSepListFileComponent>,
    private cdsPopup: CdsPopupService,
    private langService: CdsLangService,
    private service: EmployerService,
    private toastAlert: ToastAlertService
  ) {}

  ngOnInit(): void {
    this.uploadFormControl.valueChanges.subscribe(value => {
      if (value === null) {
        this.showFileErrorMessage = false;
        this.noFileSelect = true;
      } else {
        this.noFileSelect = false;
      }
    });
  }

  fileChange(event: any) {
    this.file = event?.target?.files[0];
    this.showFileErrorMessage = !this.ifFileAvailable(this.file);
    this.checked = false;
    this.checkOk = true;
    this.checkResultInfoList = [];
  }

  ifFileAvailable(file: any) {
    if (!file || !file.size || !file.type || !file.name) {
      return false;
    }

    // file size limit is 8MB, file name (with suffix) max length is 64
    if (file.size <= 8388608 && file.type === 'text/csv' && file.name.length <= 64) {
      return true;
    }

    return false;
  }

  confirm() {
    if (this.noFileSelect || this.showFileErrorMessage) {
      return;
    }
    if (this.checked && this.checkOk) {
      this.upload();
    } else {
      this.checkFile();
    }

    this.reloadButton();
  }

  reloadButton = () => {
    this.showButton = false;
    setTimeout(() => {
      this.showButton = true;
    });
  };

  checkFile() {
    this.isLoading = true;
    this.service
      .uploadAgentSepList(this.file, true)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe({
        next: res => {
          if (res.result === 0 && res.data) {
            if (res.data.result === false) {
              this.checked = true;
              this.checkResultInfoList = res.data.tips || [];

              if (this.checkResultInfoList.some(item => item.type === AgentSepCheckResultTypeE.ERR)) {
                this.checkOk = false;
              } else {
                this.checkOk = true;
              }
            } else {
              this.dialogRef.close({
                agree: true,
              });
            }
          } else {
            this.toastError(res.message);
          }
        },
      });
  }

  toastError(errorMessage: string) {
    this.toastAlert.show('error', 'common.error', errorMessage, 5000);
  }

  cancel() {
    if (this.noFileSelect) {
      this.dialogRef.close({
        agree: false,
      });
      return;
    }
    this.show = false;
    const popupRef: MatDialogRef<CommontsPopupComponent> = this.cdsPopup.open(CommontsPopupComponent, {
      size: 'md',
      data: {
        message: this.langService.translate('common.action.cancel'),
        noCloseIcon: true,
      },
    });
    popupRef.afterClosed().subscribe(data => {
      this.show = true;
      if (data.agree) {
        this.dialogRef.close({
          agree: false,
        });
      }
    });
  }

  upload() {
    this.isLoading = true;
    this.service
      .uploadAgentSepList(this.file)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe({
        next: data => {
          if (data.result === 0) {
            this.dialogRef.close({
              agree: true,
            });
          } else {
            this.toastError(data.message);
          }
        },
      });
  }
}
