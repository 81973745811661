export class EiffRequest {
  eMPFno?: number;
  isCheckedregular?: boolean;
  surnametc?: string;
  givennametc?: string;
  emailaddress?: string;
  phonenumber?: string;
  addAgentCode?: string;
}
export class AdditionAgent {
  agentId?: string;
  agentCode?: string;
  agentName?: string;
  agentNameTC?: string;
  mobileNumber?: string;
  emailAddress?: string;
}
