import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-cd-inline-alert',
  templateUrl: './cd-inline-alert.component.html',
  styleUrls: ['./cd-inline-alert.component.scss'],
})
export class CdInlineAlertComponent implements OnInit {
  type: 'error' | 'warning' = 'error';
  @Input() title?: string;
  @Input() text?: string;
  @Input() showCloseBtn = true;
  @Output() closeEvent = new EventEmitter<void>();

  constructor() {}

  ngOnInit(): void {}

  onClose() {
    this.closeEvent.emit();
  }
}
