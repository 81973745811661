<div class="l-pa-7 l-pt-5">
  <!-- start green title and white area -->
  <div class="cds-h4-demibold common-title margin-top-common green-title">Servicing Agent Information</div>

  <div class="acc-class">
    <app-cd-accordion class="no-padding">
      <app-cd-accordion-panel
        class="l-plr-0"
        *ngFor="let item of agentInfo"
        [title]="item.agentName"
        [subTitle]="'Trustee agent code: ' + item.agentCode"
        statusColor="#00a758"
        right="Active">
        <div class="acc-content">
          <div class="flex-row row no-padding margin-top-common">
            <div class="flex-column content-item col-sm-3 no-padding">
              <div class="cds-body2 no-margin title-color no-padding">MPF registration number</div>
              <div class="cds-body1 no-margin">{{ item.branchCode }}</div>
            </div>
          </div>
          <div class="flex-row row no-padding margin-top-common">
            <div class="flex-column content-item col-sm-3 no-padding">
              <div class="cds-body2 no-margin title-color no-padding">Branch Code</div>
              <div class="cds-body1 no-margin">{{ item.branchCode }}</div>
            </div>
            <div class="flex-column content-item col-sm-3 no-padding">
              <div class="cds-body2 no-margin title-color no-padding">Mobile</div>
              <div class="cds-body1 no-margin">{{ item.mobile }}</div>
            </div>
            <div class="flex-column content-item col-sm-3 no-padding">
              <div class="cds-body2 no-margin title-color no-padding">Office</div>
              <div class="cds-body1 no-margin">{{ item.office }}</div>
            </div>
            <div class="flex-column content-item col-sm-3 no-padding">
              <div class="cds-body2 no-margin title-color no-padding">Email</div>
              <div class="cds-body1 no-margin">{{ item.email }}</div>
            </div>
          </div>
        </div>
      </app-cd-accordion-panel>
    </app-cd-accordion>
  </div>
  <!-- end green title and white area -->

  <!-- start green title and white area -->
  <div class="cds-h4-demibold common-title margin-top-common green-title">Commission Agent Information</div>
  <div class="acc-class">
    <app-cd-accordion class="no-padding">
      <app-cd-accordion-panel
        class="l-plr-0"
        *ngFor="let item of agentInfo"
        [title]="item.agentName"
        [subTitle]="'Trustee agent code: ' + item.agentCode"
        statusColor="#00a758"
        right="Active">
        <div class="acc-content">
          <div class="flex-row row no-padding margin-top-common">
            <div class="flex-column content-item col-sm-3 no-padding">
              <div class="cds-body2 no-margin title-color no-padding">MPF registration number</div>
              <div class="cds-body1 no-margin">{{ item.branchCode }}</div>
            </div>
          </div>
          <div class="flex-row row no-padding margin-top-common">
            <div class="flex-column content-item col-sm-3 no-padding">
              <div class="cds-body2 no-margin title-color no-padding">Branch Code</div>
              <div class="cds-body1 no-margin">{{ item.branchCode }}</div>
            </div>
            <div class="flex-column content-item col-sm-3 no-padding">
              <div class="cds-body2 no-margin title-color no-padding">Mobile</div>
              <div class="cds-body1 no-margin">{{ item.mobile }}</div>
            </div>
            <div class="flex-column content-item col-sm-3 no-padding">
              <div class="cds-body2 no-margin title-color no-padding">Office</div>
              <div class="cds-body1 no-margin">{{ item.office }}</div>
            </div>
            <div class="flex-column content-item col-sm-3 no-padding">
              <div class="cds-body2 no-margin title-color no-padding">Email</div>
              <div class="cds-body1 no-margin">{{ item.email }}</div>
            </div>
          </div>
        </div>
      </app-cd-accordion-panel>
    </app-cd-accordion>
  </div>
  <!-- end green title and white area -->
</div>
