import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { RoleFormComponent } from './role-form.component';
import { CdsFormFieldModule } from '@cds/ng-web-components/form-field';
import { CdsTextfieldModule } from '@cds/ng-web-components/textfield';
import { CdsAssistiveTextModule } from '@cds/ng-web-components/assistive-text';
import { CdsLanguageModule } from '@cds/ng-core/lang';
import { CdsButtonModule } from '@cds/ng-web-components/button';
import { CdsTableModule } from '@cds/ng-data-table/table';
import { CdsIconModule } from '@cds/ng-web-components/icon';
import { CdsCheckboxModule } from '@cds/ng-web-components/checkbox';
@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    CdsLanguageModule,
    CdsFormFieldModule,
    CdsTextfieldModule,
    CdsAssistiveTextModule,
    CdsButtonModule,
    CdsTableModule,
    CdsIconModule,
    CdsCheckboxModule,
  ],
  declarations: [RoleFormComponent],
  exports: [RoleFormComponent],
})
export class RoleFormModule {}
