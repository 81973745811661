import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ConfigActions } from './ThemeOptions/store/config.actions';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';

import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';

// BOOTSTRAP COMPONENTS

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { LaddaModule } from 'angular2-ladda';
import { NgxLoadingModule } from 'ngx-loading';
import { RoundProgressModule } from 'angular-svg-round-progressbar';
// import {SweetAlert2Module} from '@toverux/ngx-sweetalert2';
import { ToastrModule } from 'ngx-toastr';
import { CalendarModule } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
// import { CountUpModule } from 'countup.js-angular2';
import { ImageCropperModule } from 'ngx-image-cropper';
import { NouisliderModule } from 'ng2-nouislider';
import { NgSelectModule } from '@ng-select/ng-select';
// import { SelectDropDownModule } from 'ngx-select-dropdown';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { JwBootstrapSwitchNg2Module } from 'jw-bootstrap-switch-ng2';
import { AngularEditorModule } from '@kolkov/angular-editor';
// import { TextMaskModule } from 'angular2-text-mask';
import { ClipboardModule } from 'ngx-clipboard';
import { TextareaAutosizeModule } from 'ngx-textarea-autosize';
import { ColorPickerModule } from 'ngx-color-picker';
import { DropzoneModule } from 'ngx-dropzone-wrapper';
import { DROPZONE_CONFIG } from 'ngx-dropzone-wrapper';
import { DropzoneConfigInterface } from 'ngx-dropzone-wrapper';

// ANGULAR MATERIAL COMPONENTS

import { MatCheckboxModule } from '@angular/material/checkbox';

// LAYOUT

import { BaseLayoutComponent } from './Layout/base-layout/base-layout.component';
import { AppsLayoutComponent } from './Layout/apps-layout/apps-layout.component';
import { PagesLayoutComponent } from './Layout/pages-layout/pages-layout.component';
import { ThemeOptions } from './theme-options';
import { OptionsDrawerComponent } from './ThemeOptions/options-drawer/options-drawer.component';
// import {PageTitleComponent} from './Layout/Components/page-title/page-title.component';

// HEADER

import { HeaderComponent } from './Layout/Components/header/header.component';
import { SearchBoxComponent } from './Layout/Components/header/elements/search-box/search-box.component';
import { MegamenuComponent } from './Layout/Components/header/elements/mega-menu/mega-menu.component';
import { MegapopoverComponent } from './Layout/Components/header/elements/mega-menu/elements/megapopover/megapopover.component';
import { UserBoxComponent } from './Layout/Components/header/elements/user-box/user-box.component';

// SIDEBAR

import { SidebarComponent } from './Layout/Components/sidebar/sidebar.component';
import { LogoComponent } from './Layout/Components/sidebar/elements/logo/logo.component';

// FOOTER

import { FooterComponent } from './Layout/Components/footer/footer.component';
import { FooterDotsComponent } from './Layout/Components/footer/elements/footer-dots/footer-dots.component';
import { FooterMenuComponent } from './Layout/Components/footer/elements/footer-menu/footer-menu.component';

// Pages

// Components

// Apex Charts
// Gauges Charts

import { GaugeModule } from 'angular-gauge';
import { TrendModule } from 'ngx-trend';
// import { AuthModule } from './auth/auth.module';
import { AuthGuard } from './_guard/authGuard';
import { DataService } from './_services/data.service';
import { DotsComponent } from './Layout/Components/header/elements/dots/dots.component';
import { DrawerComponent } from './Layout/Components/header/elements/drawer/drawer.component';
import { ChatbotAdminRoutingModule } from './chatbot-admin-routing.module';
import { CdsLangService, CdsLanguageModule } from '@cds/ng-core/lang';
import { CdsSortModule } from '@cds/ng-data-table/sort';
import { CdsTableModule } from '@cds/ng-data-table/table';
import { CdsAssistiveTextModule } from '@cds/ng-web-components/assistive-text';
import { CdsButtonModule } from '@cds/ng-web-components/button';
import { CdsCheckboxModule } from '@cds/ng-web-components/checkbox';
import { CdsDatepickerModule, CDS_DATE_FORMATS, DateAdapter } from '@cds/ng-web-components/datepicker';
import { CdsDropdownModule } from '@cds/ng-web-components/dropdown';
import { CdsFormFieldModule } from '@cds/ng-web-components/form-field';
import { CdsIconModule } from '@cds/ng-web-components/icon';
import { CdsLinkModule } from '@cds/ng-web-components/link';
import { CdsNavigationModule } from '@cds/ng-web-components/navigation';
import { CdsPaginationModule } from '@cds/ng-web-components/pagination';
import { CdsPopupModule } from '@cds/ng-web-components/popup';
import { CdsProgressModule } from '@cds/ng-web-components/progress';
import { CdsRadioModule } from '@cds/ng-web-components/radio';
import { CdsSearchModule } from '@cds/ng-web-components/search';
import { CdsStepperModule } from '@cds/ng-web-components/stepper';
import { CdsTabsModule } from '@cds/ng-web-components/tabs';
import { CdsTextfieldModule } from '@cds/ng-web-components/textfield';
import { SharedModule } from 'src/app/shared/shared.module';
import { AuthHttpInterceptor } from 'src/app/core/interceptors/auth.http-interceptor';

// Angular Material

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
};

const DEFAULT_DROPZONE_CONFIG: DropzoneConfigInterface = {
  // Change this to your upload POST address:
  url: 'https://httpbin.org/post',
  maxFilesize: 50,
  acceptedFiles: 'image/*',
};

@NgModule({
  declarations: [
    // LAYOUT
    BaseLayoutComponent,
    AppsLayoutComponent,
    PagesLayoutComponent,
    OptionsDrawerComponent,

    // HEADER

    HeaderComponent,
    SearchBoxComponent,
    MegamenuComponent,
    MegapopoverComponent,
    UserBoxComponent,
    DotsComponent,
    DrawerComponent,

    // SIDEBAR

    SidebarComponent,
    LogoComponent,

    // FOOTER

    FooterComponent,
    FooterDotsComponent,
    FooterMenuComponent,
    // // User Pages
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    ChatbotAdminRoutingModule,
    BrowserAnimationsModule,
    CommonModule,
    LoadingBarRouterModule,
    // Angular Bootstrap Components
    CdsLinkModule,
    CdsNavigationModule,
    CdsLanguageModule,
    CdsIconModule,
    CdsButtonModule,
    CdsTextfieldModule,
    CdsPaginationModule,
    CdsTableModule,
    CdsStepperModule,
    CdsCheckboxModule,
    CdsSearchModule,
    CdsPopupModule,
    CdsFormFieldModule,
    CdsAssistiveTextModule,
    CdsDropdownModule,
    CdsProgressModule,
    CdsTabsModule,
    CdsRadioModule,
    CdsDatepickerModule,
    CdsSortModule,
    PerfectScrollbarModule,
    NgbModule,
    FontAwesomeModule,
    LaddaModule,
    FormsModule,
    ReactiveFormsModule,
    NgxLoadingModule.forRoot({}),
    RoundProgressModule,
    ToastrModule.forRoot(),
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    // CountUpModule,
    SharedModule,
    ImageCropperModule,
    NouisliderModule,
    NgSelectModule,
    // SelectDropDownModule,
    NgMultiSelectDropDownModule.forRoot(),
    JwBootstrapSwitchNg2Module,
    AngularEditorModule,
    HttpClientModule,
    // TextMaskModule,
    ClipboardModule,
    TextareaAutosizeModule,
    ColorPickerModule,
    DropzoneModule,

    // Charts

    // Angular Material Components

    MatCheckboxModule,
  ],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      // DROPZONE_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
      // DEFAULT_DROPZONE_CONFIG,
    },
    ConfigActions,
    AuthGuard,
    ThemeOptions,
    DataService,
  ],
})
export class ChatbotAdminModule {
  // constructor(private ngRedux: NgRedux<ArchitectUIState>,
  //   private devTool: DevToolsExtension) {
  //   this.ngRedux.configureStore(
  //     rootReducer,
  //     {} as ArchitectUIState,
  //     [],
  //     [this.devTool.isEnabled() ? this.devTool.enhancer() : f => f]
  //   );
  // }
}
