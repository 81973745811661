<div class="row">
  <!-- <div class=""> -->
  <div class="cds-h2-bold text-bold col-md-12 l-pt-6">強積金中介人聲明資料確認</div>
  <!-- </div> -->
  <div class="col-md-12 div-container">
    <div class="div-border-container"></div>
    <div class="row l-pt-5 l-plr-0">
      <div class="col-md-1 text-bold">
        <cds-icon [icon]="infoIcon" [config]="iconConfig"></cds-icon>
      </div>
      <div class="col-md-10">
        <span class="1-pt-2 cds-h2-bold text-bold">注意事項</span>
        <ul class="text-small">
          <li>請小心核對所輸入的資料無誤，資料一經提交將不可更改。</li>
          <li>盡本人所知所信，本表格上所提供之資料均屬正確無訛且無缺漏。</li>
          <li>我明白在要項上屬具誤導性、虛假或不正確陳述時的後果。</li>
        </ul>
      </div>
    </div>
  </div>
</div>
<div class="row l-pt-6">
  <div class="col-md-4 text-bold">請輸入eMPF申請編號：</div>
  <div class="col-md-8">
    <div>
      <span>TRF - 87654321-123456</span>
    </div>
    <div style="font-size: 14px; padding-top: 20px">
      <cds-checkbox [checked]="true" [disabled]="true" label="在客戶進行受規管活動時，必須已履行強積金中介人履行職務要求"></cds-checkbox>
    </div>
  </div>
</div>
<div class="row l-pt-6">
  <div class="col-md-4 text-bold">客戶全名：</div>
  <div class="col-md-8">
    <div>
      <span>CHAN TAI LUNG</span>
    </div>
  </div>
</div>
<div class="row l-pt-6">
  <div class="col-md-4 text-bold">客戶電話/電郵地址：</div>
  <div class="col-md-8">
    <div>
      <span>(+852) 9876 5432</span>
    </div>
  </div>
</div>
<div class="row l-pt-6 l-d-f">
  <div class="l-pl-3">
    <cds-button label="返 回" (click)="BackTosummary()" [config]="clearButton"></cds-button>
  </div>
  <div style="padding-left: 16px">
    <cds-button label="確認並提交" [config]="clearButton"></cds-button>
  </div>
</div>
