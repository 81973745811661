<div class="cd-multidropdown" appOutsideClose (outsideEvent)="onOutsideEvent()" [class.with-label]="!!label">
  <div class="label cds-body2" *ngIf="!!label">
    {{ label }}
  </div>

  <div class="selected-val-box">
    <input
      [value]="getTitle"
      [ngClass]="['selected-val', disabled ? 'disabled-selected-val' : '']"
      type="text"
      readonly
      [placeholder]="placeholder | lang"
      (click)="onToggel()" />
    <cds-icon (click)="onToggel()" [ngClass]="['button-down', open ? 'up' : '', disabled ? 'disabled-button-down' : '']" icon="action:button_down"></cds-icon>
  </div>

  <div [ngClass]="['line', open ? 'line-light' : '', disabled ? 'disabled-line' : '']"></div>

  <div [ngClass]="['option-list', open ? 'open-list' : '', !!label ? 'top-position' : '']">
    <div class="option-item" *ngFor="let item of toppingList" (click)="refrushToppings(item)">
      <cds-checkbox [checked]="getCheck(item)" [label]="item.label"> </cds-checkbox>
      <div class="l-ml-7">
        <div *ngIf="item.subLabel">{{ item.subLabel }}</div>
        <div class="cds-detail1" *ngIf="item.exatraInfo">
          {{ item.exatraInfo }}
        </div>
      </div>
    </div>
  </div>
</div>
