import { Component, OnInit } from '@angular/core';

import { CdsButtonConfig } from '@cds/ng-core/button';
import { MatDialogRef } from '@cds/ng-web-components/popup';
@Component({
  selector: 'app-advise-iffstatus',
  templateUrl: './advise-iffstatus.component.html',
  styleUrls: ['./advise-iffstatus.component.scss'],
})
export class AdviseIffstatusComponent implements OnInit {
  constructor(private dialogRef: MatDialogRef<AdviseIffstatusComponent>) {}

  clearButton: CdsButtonConfig = {
    size: 'sm',
    style: 'primary',
    disabled: false,
  };
  ngOnInit(): void {}

  backTosummary() {
    this.dialogRef.close({ agree: true });
  }
}
