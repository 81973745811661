import { PolicyDetails } from './../employee';
import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ToastAlertService } from 'src/app/shared/toast-alert.service';
import { EmployeeService } from '../employee.service';
import { ActivatedRoute } from '@angular/router';
import { finalize } from 'rxjs';

@Component({
  selector: 'app-policy-detail',
  templateUrl: './policy-detail.component.html',
  styleUrls: ['./policy-detail.component.scss'],
})
export class PolicyDetailComponent implements OnInit, OnChanges {
  @Input() id = '';

  isLoading = false;
  data: PolicyDetails = {};

  constructor(public service: EmployeeService, private route: ActivatedRoute, private toastAlert: ToastAlertService) {}

  ngOnInit(): void {
    this.getData();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['id'].currentValue !== undefined && changes['id'].currentValue !== changes['id'].previousValue) {
      this.getData();
    }
  }

  getData() {
    if (!this.id) return;
    this.isLoading = true;
    this.service
      .getTabPolicyDetails(this.id)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe(res => {
        if (res.result === 0 && res.data) {
          this.data = res.data;
        } else {
          this.toastError(res.message);
        }
      });
  }

  toastError(errorMessage: string) {
    this.toastAlert.show('error', 'common.error', errorMessage, 5000);
  }

  getSubChannel(text?: string) {
    switch (text) {
      case 'WEB_PORTAL':
        return 'Web Portal';
      case 'MOBILE_APP':
        return 'Mobile App';
      case 'PAPER_FORM':
        return 'Paper form';
      case 'THIRD_PARTY_PORTAL':
        return 'Third Party Portal';
      case 'BATCH':
        return 'Batch';
      case 'KIOSK':
        return 'Kiosk';
      case 'CONTACT_CENTER':
        return 'Contact Center';
      case 'MPFA_PORTAL':
        return 'MPFA Portal';
      case 'TRUSTEE_PORTAL':
        return 'Trustee Portal';
      default:
        return text;
    }
  }

  getSourceOfFund(text?: string) {
    switch (text) {
      case 'SALARY':
        return 'Salary';
      case 'PERSON_SAVE':
        return 'Personal savings';
      case 'INHERITANCE':
        return 'Inheritance';
      case 'SALE_OF_PROPERTY':
        return 'Sale of property';
      case 'INV_RETURN_MATURED':
        return 'Investment return / Investment matured';
      case 'OTHERS':
        return 'Others';
      default:
        return text;
    }
  }
}
