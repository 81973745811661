<div class="row l-plr-0">
  <div class="col-xs-12 l-plr-0 l-pt-5">
    <span class="cds-h2-light">{{ 'list.search-record' | lang }}</span>
  </div>
</div>

<div class="row l-plr-0" *ngIf="form" [formGroup]="form">
  <div class="col-xs-6 l-pl-0 col-sm-3 col-md-3 col-lg-3 l-pt-4 l-pb-4">
    <app-autocomplete
      [group]="form"
      controlName="fundCode"
      [control]="label.fundCode"
      [selectOptions]="fundCodeConfig"
      [locked]="false"
      (valueChange)="selectedChange($event, 'fundCode')">
    </app-autocomplete>
  </div>

  <div class="col-xs-6 col-sm-3 col-md-3 col-lg-3 l-pt-4 l-pb-4">
    <cds-dropdown formControlName="year" [(ngModel)]="searchDTO.year" (ngModelChange)="selectedChange($event, 'year')" [config]="yearCodeConfig"></cds-dropdown>
  </div>

  <div class="row bottom-xs l-pl-4 l-pb-4">
    <div class="l-pr-4">
      <cds-button
        [fullWidth]="true"
        [disabled]="!form.valid || !_valid"
        [config]="searchButtonConfig"
        label="{{ 'list.search' | lang }}"
        (click)="searchFn()"></cds-button>
    </div>
    <div class="">
      <cds-button [fullWidth]="true" [disabled]="!_valid" [config]="resetButtonConfig" label="{{ 'list.reset' | lang }}" (click)="reset()"></cds-button>
    </div>
  </div>
</div>

<div class="row l-pt-7 l-plr-0">
  <div class="col-xs-12 l-pl-0 record-area">
    <div class="l-mr-6 l-mb-2">
      <div class="col-xs-12 l-pb-2 l-pl-0">
        <span class="cds-h2-light">{{ 'list.record-list' | lang }}</span>
      </div>
      <div class="col-xs-12 l-pl-0">
        <span class="cds-body1">{{ 'page.total' | lang }} {{ pageConfig.totalCount }}{{ 'page.records' | lang }}</span>
      </div>
    </div>
    <div>
      <cds-button [config]="addButtonConfig" label="{{ 'interest-rate.addLable' | lang }}" (click)="add()"></cds-button>
    </div>
  </div>
</div>

<div class="cds-color-green l-pt-2 l-pb-2">
  <span class="cds-body2-demibold">{{ 'interest-rate.latest-updated' | lang }}</span>
</div>

<div>
  <app-data-table
    [frontPagination]="false"
    [fixed]="false"
    [isLoading]="_loading"
    (reloadTable)="pageChange($event)"
    [pageConfig]="pageConfig"
    [dataSource]="dataList"
    [columnsConfig]="columnConfig">
    <ng-template appDtBodyCellDef="code" let-element>
      <div>{{ element.code }}</div>
      <div>
        <span *ngIf="element.name" class="l-pr-2">{{ element.name }}</span>
        <span>{{ element.zhName }}</span>
      </div>
    </ng-template>
    <ng-template appDtBodyCellDef="normalRate" let-element>
      <span [ngClass]="[element.normalRateOld && element.normalRate !== element.normalRateOld ? 'cds-color-green' : '']">{{ element.normalRate }}%</span>
    </ng-template>
    <ng-template appDtBodyCellDef="withdrawalRate" let-element>
      <div
        [ngClass]="['pointer row l-plr-0', element.withdrawalRateOld && element.withdrawalRate !== element.withdrawalRateOld ? 'cds-color-green' : '']"
        (click)="openHistory(element)">
        <span class="col-xs-4 l-pr-2 l-pl-0">{{ element.withdrawalRate }}%</span>
        <cds-icon [color]="element.withdrawalRateOld && element.withdrawalRate !== element.withdrawalRateOld ? '#00a758' : ''" icon="arrow:history"></cds-icon>
      </div>
    </ng-template>
    <ng-template appDtBodyCellDef="interimRate" let-element>
      <span [ngClass]="[element.interimRateOld && element.interimRate !== element.interimRateOld ? 'cds-color-green' : '']">{{ element.interimRate }}%</span>
    </ng-template>
    <ng-template appDtBodyCellDef="date" let-element>
      <div>
        <span>{{ element.month | number : '2.0-0' }}/{{ element.year }}</span>
      </div>
    </ng-template>
    <ng-template appDtBodyCellDef="status" let-element>
      <div>
        <ng-container *ngIf="element.status === 'PENDING'">
          <cds-button [config]="approveConfig" size="sm" label="{{ 'common.approve' | lang }}" (click)="approve(element)"></cds-button>
        </ng-container>
        <ng-container *ngIf="element.status !== 'PENDING'">
          {{ statusFormat(element.status) }}
        </ng-container>
      </div>
    </ng-template>
  </app-data-table>
</div>
<div class="l-mt-9"></div>
