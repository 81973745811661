<div *ngIf="currentStep === 0" class="add-new-case">
  <div [formGroup]="form" class="l-pl-7 l-pr-7 content-box">
    <div>
      <span class="cds-h2-light">Add New Case</span>
    </div>
    <div>
      <span class="cds-body2 cds-coral-text dark-1">*Random check will be performed after input</span>
    </div>
    <div class="l-d-f l-ai-fs">
      <div style="flex: 1">
        <div class="l-mt-5">
          <span class="cds-body2">{{ 'wsm-case-summary.booking-reference-no' | lang }}</span>
        </div>
        <div class="l-mt-1">
          <app-cd-input
            [group]="form"
            [controlName]="'bookingReferenceNo'"
            [placeholder]="'common.input' | lang : { p1: 'wsm-case-summary.booking-reference-no' | lang }"></app-cd-input>
          <cds-assistive-text *ngIf="form.get('bookingReferenceNo')?.dirty && form.get('bookingReferenceNo')?.hasError('required')" class="l-mt-2">{{
            'common.please-input' | lang : { p1: 'wsm-case-summary.booking-reference-no' | lang }
          }}</cds-assistive-text>
        </div>
      </div>
      <div style="width: var(--cds-spacing-05)"></div>
      <div style="flex: 1">
        <div class="l-mt-5">
          <span class="cds-body2">{{ 'wsm-case-summary.employer-account-code' | lang }}</span>
        </div>
        <div class="l-mt-1">
          <app-cd-input
            [isHasErrors]="employerAccountCodeErrCodeList.length > 0"
            [group]="form"
            [controlName]="'employerAccountCode'"
            (valueChange)="employerAccountCodeChange($event)"
            [placeholder]="'common.input' | lang : { p1: 'wsm-case-summary.employer-account-code' | lang }"></app-cd-input>
          <cds-assistive-text *ngIf="form.get('employerAccountCode')?.dirty && form.get('employerAccountCode')?.hasError('required')" class="l-mt-2">{{
            'common.please-input' | lang : { p1: 'wsm-case-summary.employer-account-code' | lang }
          }}</cds-assistive-text>

          <ng-container *ngIf="employerAccountCodeErrCodeList.includes(1) || employerAccountCodeErrCodeList.includes(2); else inc3">
            <cds-assistive-text *ngIf="employerAccountCodeErrCodeList.includes(1); else inc2" class="l-mt-2">{{
              'common.invalid' | lang : { p1: 'wsm-case-summary.employer-account-code' | lang }
            }}</cds-assistive-text>
            <ng-template #inc2>
              <cds-assistive-text *ngIf="employerAccountCodeErrCodeList.includes(2)" class="l-mt-2">{{
                'wsm-case-summary.non-servicing-error' | lang
              }}</cds-assistive-text>
            </ng-template>
          </ng-container>

          <ng-template #inc3>
            <cds-assistive-text *ngIf="employerAccountCodeErrCodeList.includes(3)" class="l-mt-2">Duplicate Record</cds-assistive-text>
          </ng-template>
        </div>
      </div>
    </div>
    <div class="meeting-date-warpper">
      <div class="header"><span class="cds-body2-bold">Meeting Date</span><span class="cds-coral-text dark-1">*</span></div>
      <div class="content">
        <div formArrayName="meetingDates">
          <div *ngFor="let meetingDate of meetingDateList.controls; let i = index">
            <div class="l-d-f" style="position: relative" [formGroupName]="i">
              <div class="meeting-date-no">
                <div>
                  <span class="cds-body1">{{ i + 1 }}</span>
                </div>
              </div>
              <div *ngIf="!resetDatePicker" class="offset-meeting-date">
                <cds-textfield
                  [ngClass]="[
                    isLocalDuplicate(asFormGroup(meetingDate).get('date')?.value) ? 'error-style' : '',
                    duplicateMeetingDates.includes(asFormGroup(meetingDate).get('date')?.value) ? 'error-style' : ''
                  ]"
                  class="prodtype-date-align"
                  style="position: relative; top: -7px"
                  formControlName="date"
                  #meetingDateVal
                  type="text"
                  placeholder="DD/MM/YYYY"
                  [min]="meetingDateMin"
                  [cdsDatepicker]="pickerToDate"></cds-textfield>
                <cds-datepicker #pickerToDate="cdsDatepicker"></cds-datepicker>
                <cds-assistive-text
                  *ngIf="asFormGroup(meetingDate).get('date')?.dirty && asFormGroup(meetingDate).get('date')?.hasError('formatError')"
                  class="l-mt-4"
                  >{{ 'common.invalidDate' | lang }}</cds-assistive-text
                >
                <cds-assistive-text
                  *ngIf="asFormGroup(meetingDate).get('date')?.dirty && asFormGroup(meetingDate).get('date')?.hasError('required')"
                  class="l-mt-4"
                  >{{ 'common.plsSelect' | lang : { p1: 'Meeting Date' } }}</cds-assistive-text
                >
                <cds-assistive-text *ngIf="isLocalDuplicate(asFormGroup(meetingDate).get('date')?.value); else network" class="l-mt-4"
                  >Duplicate Record</cds-assistive-text
                >
                <ng-template #network>
                  <cds-assistive-text *ngIf="duplicateMeetingDates.includes(asFormGroup(meetingDate).get('date')?.value)" class="l-mt-4"
                    >Duplicate Record</cds-assistive-text
                  >
                </ng-template>
              </div>
              <div class="meeting-date-del-btn">
                <cds-icon (click)="deleteItem(i)" *ngIf="i !== meetingDateList.controls.length - 1" class="cursor-pointer" icon="form:delete"></cds-icon>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="add-new-box l-d-f l-ai-cen">
        <div (click)="onAddNewMeetingDate()" class="cursor-pointer" style="margin-left: 20px">
          <cds-icon
            style="--cds-icon-color: {{ isDisableAddNewMeetingDate ? 'var(--cds-text-placeholder)' : 'var(--cds-color-coral)' }}"
            icon="action:button_plus_filled"></cds-icon>
        </div>
        <div (click)="onAddNewMeetingDate()" class="cursor-pointer" style="margin-left: 10px">
          <span class="cds-body2-demibold" [ngStyle]="{ color: isDisableAddNewMeetingDate ? 'var(--cds-text-placeholder)' : '' }">Add New</span>
        </div>
      </div>
    </div>
  </div>

  <div class="btn-box">
    <cds-button (click)="reset()" [style]="'secondary'" size="sm">{{ 'common.reset1' | lang }}</cds-button>
    <cds-button (click)="add()" class="l-ml-4" size="sm">{{ 'common.add' | lang }}</cds-button>
  </div>

  <div class="close-button">
    <cds-icon icon="action:cross" (click)="onClose()"></cds-icon>
  </div>
</div>

<div *ngIf="currentStep === 1" class="add-new-case">
  <div [formGroup]="form" class="l-pl-7 l-pr-7 l-pb-1 content-box">
    <div>
      <span class="cds-h2-light">New Case Confirmation</span>
    </div>
    <div class="l-mt-2">
      <span class="cds-body1-ita-local">Please confirm the below information. Random check will be performed after input.</span>
    </div>
    <div class="l-mt-5 l-d-f">
      <div style="width: 180px">
        <span class="cds-body2" style="color: var(--cds-text-disabled)">{{ 'wsm-case-summary.booking-reference-no' | lang }}:</span>
      </div>
      <div>
        <span class="cds-body1">{{ form.get('bookingReferenceNo')?.value }}</span>
      </div>
    </div>

    <div class="l-mt-5 l-d-f">
      <div style="width: 180px">
        <span class="cds-body2" style="color: var(--cds-text-disabled)">{{ 'wsm-case-summary.employer-account-code' | lang }}:</span>
      </div>
      <div>
        <span class="cds-body1">{{ form.get('employerAccountCode')?.value }}</span>
      </div>
    </div>

    <div class="meeting-date-warpper">
      <div class="header"><span class="cds-body2-bold">Meeting Date</span><span class="cds-coral-text dark-1">*</span></div>
      <div class="content">
        <div formArrayName="meetingDates">
          <div *ngFor="let meetingDate of meetingDateList.controls; let i = index">
            <div
              class="l-d-f"
              style="position: relative; border-bottom: 1px solid var(--cds-divider-default); border-right: 1px solid var(--cds-divider-default)"
              [formGroupName]="i">
              <div class="meeting-date-no" style="border-bottom: unset">
                <div>
                  <span class="cds-body1">{{ i + 1 }}</span>
                </div>
              </div>
              <div class="l-d-f l-ai-cen">
                <span>{{ asFormGroup(meetingDate).get('date')?.value }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="btn-box">
    <cds-button (click)="backToEdit()" [style]="'secondary'" size="sm">{{ 'common.backToEdit' | lang }}</cds-button>
    <cds-button (click)="confirm()" class="l-ml-4" size="sm">{{ 'common.confirm' | lang }}</cds-button>
  </div>

  <div class="close-button">
    <cds-icon icon="action:cross" (click)="onClose()"></cds-icon>
  </div>
</div>

<div *ngIf="currentStep === 2" class="add-new-case">
  <div [formGroup]="form" class="l-pl-7 l-pr-7 l-pb-1 content-box">
    <div>
      <span class="cds-h2-light">Your changes has been updated successfully.</span>
    </div>
    <div class="l-mt-2">
      <span class="cds-body1-ita-local">Please find the below information of failed and successful upload cases.</span>
    </div>
    <div class="l-mt-5 l-d-f">
      <div style="width: 180px">
        <span class="cds-body2" style="color: var(--cds-text-disabled)">{{ 'wsm-case-summary.booking-reference-no' | lang }}:</span>
      </div>
      <div>
        <span class="cds-body1">{{ form.get('bookingReferenceNo')?.value }}</span>
      </div>
    </div>

    <div class="l-mt-5 l-d-f">
      <div style="width: 180px">
        <span class="cds-body2" style="color: var(--cds-text-disabled)">{{ 'wsm-case-summary.employer-account-code' | lang }}:</span>
      </div>
      <div>
        <span class="cds-body1">{{ form.get('employerAccountCode')?.value }}</span>
      </div>
    </div>

    <div class="l-d-f">
      <div class="meeting-date-warpper">
        <div class="l-mt-3">
          <span class="cds-body1-demibold" style="font-style: italic; color: var(--cds-color-dark-1-coral)">Failed Cases:</span>
        </div>
        <div class="header l-mt-4"><span class="cds-body2-bold">Meeting Date</span><span class="cds-coral-text dark-1">*</span></div>
        <div class="content">
          <div *ngFor="let meetingDate of failCases; let i = index">
            <div
              class="l-d-f"
              style="position: relative; border-bottom: 1px solid var(--cds-divider-default); border-right: 1px solid var(--cds-divider-default)">
              <div class="meeting-date-no" style="border-bottom: unset">
                <div>
                  <span class="cds-body1">{{ i + 1 }}</span>
                </div>
              </div>
              <div class="l-d-f l-ai-cen">
                <span>{{ meetingDate }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="meeting-date-warpper l-ml-6">
        <div class="l-mt-3">
          <span class="cds-body1-demibold" style="font-style: italic; color: var(--cds-color-m-green)">Successful Cases:</span>
        </div>
        <div class="header l-mt-4"><span class="cds-body2-bold">Meeting Date</span><span class="cds-coral-text dark-1">*</span></div>
        <div class="content">
          <div *ngFor="let meetingDate of successCases; let i = index">
            <div
              class="l-d-f"
              style="position: relative; border-bottom: 1px solid var(--cds-divider-default); border-right: 1px solid var(--cds-divider-default)">
              <div class="meeting-date-no" style="border-bottom: unset">
                <div>
                  <span class="cds-body1">{{ i + 1 }}</span>
                </div>
              </div>
              <div class="l-d-f l-ai-cen">
                <span>{{ meetingDate }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="btn-box">
    <cds-button (click)="gotIt()" [style]="'secondary'" size="sm">{{ 'common.gotItWithExclamation' | lang }}</cds-button>
  </div>

  <div class="close-button">
    <cds-icon icon="action:cross" (click)="onClose()"></cds-icon>
  </div>
</div>
