/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, from, lastValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { getSessionByKey } from '../../utils/indexdb';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return from(this.handle(request, next));
  }

  async handle(req: HttpRequest<any>, next: HttpHandler) {
    if (
      req.url &&
      req.url.indexOf('/login') == -1 &&
      req.url.indexOf('/refresh-token') == -1 &&
      req.url.indexOf('/auth/token') == -1 &&
      (req.url.indexOf(`${environment.apiPrefix}`) !== -1 || req.url.indexOf(`localhost`) !== -1)
    ) {
      // if your getAuthToken() function declared as "async getAuthToken() {}"await this.auth.getAuthToken()
      return getSessionByKey().then(session => {
        if (session && session.token) {
          //fix me Authorization header name
          const authReq = req.clone({
            setHeaders: {
              Authorization: session.token,
              //        'access-token':
              //             'Bearer eyJhbGciOiJIUzUxMiJ9.eyJvcmdhbml6ZVR5cGUiOiJBR0VOQ1kiLCJpZGVudGl0eVR5cGUiOiJTWVNURU0iLCJvcmdhbml6ZSI6Im9yZ2FuaXplaWQiLCJpZGVudGl0eSI6IjM3ZTJhZmM3LTRlZGUtNDM0ZS05ZjkzLWQwZGFiNjZmMWE2YiIsIm5hbWUiOiJNYW51bGlmZSIsInBlcm1pdCI6eyJTWVNfSURFTlRJVFlfQUdFTlRfTUFOQUdFIjoiRSIsIkNVU1RPTUVSX0VNUExPWUVSX1NVQlNJRElBUlkiOiJFIiwiU1lTX0lERU5USVRZX1NUQUZGX01BTkFHRSI6IkUiLCJDVVNUT01FUl9NRU1CRVIiOiJFIiwiQ09NTUlTU0lPTl9TQ0hFTUVfTUFTVEVSX1JFQ09SRCI6IkUiLCJDT01NX0lORk9fTUFJTl9QQVJUSUVTX0NPTlRfQURKIjoiRSIsIlNZU19DQUxFTkRBUiI6IkUiLCJDVVNUT01FUl9FTVBMT1lFUl9TUEVDSUFMX09GRkVSIjoiRSIsIlNZU19DQUxFTkRBUl9SRVZJRVdFUiI6IkUiLCJDT01NSVNTSU9OX0pPVVJOQUwiOiJFIiwiQ1VTVE9NRVJfTU9CSUxFX1NVQlNDUklQVElPTiI6IkUiLCJDT01NSVNTSU9OX1NDQUxFX01BTkFHRU1FTlQiOiJFIiwiU0hLX0FHRU5UX0FTU0lHTk1FTlRfU1RBRkYiOiJFIiwiQ09NTV9JTkZPX01BSU5fQ09NTV9TVU1NQVJZIjoiRSIsIkNVU1RPTUVSX1NFUlZJQ0lOR19BR0VOVCI6IkUiLCJDT01NX0lORk9fSU5ESVZJRFVBTF9UUkYiOiJFIiwiU1lTX0NPTU1JU1NJT05fREVGQVVMVF9TQ0FMRSI6IkUiLCJDT01NX0lORk9fQUdFTlRfQ0hBTkdFIjoiRSIsIkNVU1RPTUVSX0VNUExPWUVSX1NRIjoiRSIsIlNZU19ST0xFX01BTkFHRSI6IkUiLCJDT01NX0lORk9fTUFJTl9QQVJUSUVTX09USEVSUyI6IkUiLCJDT01NX0lORk9fQUdFTlRfQ09NUCI6IkUiLCJDT01NX0lORk9fU0NBTEVfUkVDT1JEIjoiRSIsIkNVU1RPTUVSX0VNUExPWUVSIjoiRSJ9LCJzdGF0dXMiOiJBQ1RJVkUiLCJleHAiOjE3NjU1MzQ1NzF9.S3Ur8KC4leGcxbzUhkOecXwBuL6DCiVFbJv_EhTALdHPzoQuFThJ6fx2MEFnwkCdfIDyafHUln3OaSYY-bD9yw'
            },
          });
          return lastValueFrom(next.handle(authReq));
        }
        return lastValueFrom(next.handle(req));
      });
    }
    return lastValueFrom(next.handle(req));
  }
}
