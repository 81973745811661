<div class="row">
  <div class="col-xs-12 l-mt-7 cds-body1">
    Record the activities (including Employer, Member and Special Program) organized to Employer so that we can calculate the relationship score of the
    Employer, monitor the participation rate and encourage ER to participate.
  </div>

  <div class="col-xs-12 l-pt-4">
    <span class="cds-h2">Search record</span>
  </div>

  <div class="col-xs-12 row no-padding l-ai-cen l-mb-6">
    <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 l-pt-4 l-pb-4">
      <cds-textfield
        [(ngModel)]="searchDTO.activityCode"
        #activityCode="ngModel"
        maxlength="6"
        (input)="searchChange($event, 'activityCode')"
        label="Activity Code"
        placeholder="Input Acitivity Code"></cds-textfield>

      <div *ngIf="activityCode.invalid && activityCode.dirty" class="l-pt-1">
        <cds-assistive-text *ngIf="activityCode?.errors?.['maxlength']" class="l-pt-2" [showIcon]="false">Max length is 6</cds-assistive-text>
      </div>
    </div>
    <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 l-pt-4 l-pb-4">
      <cds-textfield
        [(ngModel)]="searchDTO.description"
        #description="ngModel"
        maxlength="50"
        [appDescriptionValidator]="I18N_KEY.INVALID_DESCRIPTION_CHINESE"
        [appDescriptionValidatorSupportChinese]="true"
        (input)="searchChange($event, 'description')"
        label="Activity Description"
        placeholder="Input Activity Description"></cds-textfield>

      <div *ngIf="description.invalid && description.dirty" class="l-pt-2">
        <cds-assistive-text *ngIf="description?.errors?.['maxlength']" class="l-pt-2" [showIcon]="false">Max length is 50</cds-assistive-text>
        <cds-assistive-text
          *ngIf="description?.errors?.['descriptionInvalid']"
          class="l-pt-2"
          [showIcon]="false"
          >{{ description?.errors?.['descriptionInvalid'] | lang }}</cds-assistive-text
        >
      </div>
    </div>

    <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 l-pt-4 l-pb-4">
      <cds-textfield
        [(ngModel)]="searchDTO.score"
        #score="ngModel"
        [appMaxValidator]="999"
        [appMinValidator]="0"
        type="number"
        (input)="searchChange($event, 'score')"
        (compositionend)="searchChange($event, 'score')"
        label="Activity Score"
        placeholder="Input Activity Score"></cds-textfield>

      <div *ngIf="score.invalid && score.dirty" class="l-pt-2">
        <cds-assistive-text *ngIf="score?.errors?.['max']" class="l-pt-2" [showIcon]="false">Max score is 999</cds-assistive-text>
        <cds-assistive-text *ngIf="score?.errors?.['min']" class="l-pt-2" [showIcon]="false">Min score is 0</cds-assistive-text>
      </div>
    </div>

    <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 l-pt-4 l-pb-4 activity-type">
      <cds-dropdown
        #activityType
        [(ngModel)]="searchDTO.activityType"
        (change)="activityChange($event)"
        [config]="activityTypeDropdownConfig"
        label="Payment Reference Number"></cds-dropdown>
    </div>

    <div *ngIf="showDatePicker" class="row col-xs-12 col-sm-12 col-md-8 col-lg-6 l-plr-0 date-picker-area relative">
      <div class="col-xs-6 date-picker">
        <cds-textfield
          [(ngModel)]="searchDTO.fromDate"
          (ngModelChange)="validating()"
          #createStartDate="ngModel"
          [appDateValidator]="I18N_KEY.INVALID_DATE"
          [appDateValidatorMinDate]="minTimeInStr"
          [appDateValidatorMaxDate]="searchDTO.toDate || nowTimeInstr"
          placeholder="DD/MM/YYYY"
          label="Date of Completion From"
          [min]="minTime"
          [max]="getMaxFromDate | fn : searchDTO.toDate"
          [cdsDatepicker]="startPicker">
        </cds-textfield>
        <cds-datepicker #startPicker="cdsDatepicker"></cds-datepicker>

        <div *ngIf="createStartDate.invalid && (createStartDate.dirty || createStartDate.touched)" class="l-pt-2">
          <cds-assistive-text
            *ngIf="createStartDate?.errors?.['dateInvalid']"
            class="l-pt-2"
            [showIcon]="false"
            >{{ createStartDate?.errors?.['dateInvalid'] | lang }}</cds-assistive-text
          >
        </div>
      </div>
      <div class="connector">-</div>
      <div class="col-xs-6 date-picker">
        <cds-textfield
          [(ngModel)]="searchDTO.toDate"
          (ngModelChange)="validating()"
          #createEndDate="ngModel"
          [appDateValidator]="I18N_KEY.INVALID_DATE"
          [appDateValidatorMinDate]="searchDTO.fromDate || minTimeInStr"
          [appDateValidatorMaxDate]="maxTimeInStr"
          placeholder="DD/MM/YYYY"
          label="To"
          [min]="getMinToDate | fn : searchDTO.fromDate"
          [max]="maxTime"
          [cdsDatepicker]="endPicker">
        </cds-textfield>

        <cds-datepicker #endPicker="cdsDatepicker"></cds-datepicker>

        <div *ngIf="createEndDate.invalid && (createEndDate.dirty || createEndDate.touched)" class="l-pt-2">
          <cds-assistive-text
            *ngIf="createEndDate?.errors?.['dateInvalid']"
            class="l-pt-2"
            [showIcon]="false"
            >{{ createEndDate?.errors?.['dateInvalid'] | lang }}</cds-assistive-text
          >
        </div>
      </div>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3 l-mt-4 l-pb-4 bottom-xs l-pt-4">
      <cds-button
        class="l-mr-4 botton"
        [config]="searchButtonConfig"
        label="{{ 'list.search' | lang }}"
        [disabled]="
          searchButtonDisabledFn
            | fn
              : activityCode?.control?.valid
              : description?.control?.valid
              : createStartDate?.control?.valid
              : createEndDate?.control?.valid
              : score?.control?.valid
              : isAllEmpty
        "
        (click)="search()"></cds-button>
      <cds-button class="botton" [config]="resetButtonConfig" [disabled]="resetButtonDisabled" label="{{ 'list.reset' | lang }}" (click)="reset()"></cds-button>
    </div>
  </div>

  <div class="col-xs-12 no-padding">
    <div class="col-xs-12 l-pt-5">
      <span class="cds-h4">Activity Records</span>
    </div>
    <div class="col-xs-12">
      <div class="p-line"></div>
    </div>
  </div>

  <div class="col-xs-12 row l-jc-sb l-ai-cen">
    <div class="l-mb-4">
      <div class="total-score">
        <div class="score-area">
          <cds-icon class="l-mr-4" color="#282B3E" size="md" icon="celebration:award"></cds-icon>
          <div>
            <div class="cds-body1 title">Total result score within the period / Total score summed within 5 years</div>
            <div class="cds-h4 text">
              {{ completionActivityScore }}<span class="denominator">/{{ erActivityScore }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="l-mb-4">
      <cds-button *ngIf="canEdit" [style]="'secondary'" size="sm" (click)="add()"> Update Completed Activity </cds-button>
    </div>
  </div>

  <div class="col-xs-12 l-mb-4">Total {{ numberOfElements | number }} records</div>

  <div class="col-xs-12 col-md-12 relative min-height">
    <app-data-table
      [frontPagination]="false"
      [isLoading]="isLoading"
      [fixed]="false"
      [overlay]="true"
      [pageConfig]="pageConfig"
      [dataSource]="dataSource"
      [columnsConfig]="columnsConfig"
      (reloadTable)="reloadTable($event)">
      <ng-template appDtBodyCellDef="completionDate" let-element>
        <span>{{ element['completionDate'] | date : 'dd/MM/yyyy' }}</span>
      </ng-template>

      <ng-template appDtBodyCellDef="action" let-element>
        <div class="button-area" (click)="edit(element)">
          <div class="icon-size">
            <cds-icon size="sm" icon="action:edit"></cds-icon>
          </div>
          <span class="cds-h6-demibold l-pl-2">Edit</span>
        </div>
      </ng-template>
    </app-data-table>
  </div>
</div>
