import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'mystring' })
export class MyStringPipe implements PipeTransform {
  transform(value: string | null) {
    if (!value) return null;

    let val = value,
      suffix = '';
    const index = value.indexOf('@');
    if (index > -1) {
      val = value.substring(0, index);
      suffix = value.substring(index, value.length);
    }

    if (val.length > 3) {
      const part1 = val.substring(0, 1);
      const part2 = val.substring(val.length - 3, val.length);
      return part1 + '***' + part2 + suffix;
    }

    return val + '**' + suffix;
  }
}
