<div class="row l-pt-5" *ngIf="topTitle !== ''">
  <div class="col-xs-12 cds-color-green l-plr-0">
    <span class="cds-h5-demibold">{{ topTitle ? topTitle : ('payroll.contact-person' | lang) }}</span>
  </div>
  <div class="col-xs-12 l-pt-4"></div>
</div>

<app-cd-accordion>
  <app-cd-accordion-panel
    *ngFor="let item of contactPersonList; index as itemIndex"
    [title]="nameRender | fn : item"
    titleClass="cds-h5-bold"
    [miniTitle]="chineseNameRender | fn : item"
    miniTitleClass="cds-body2-bold"
    [subTitle]="item.jobPosition"
    [right]="getRelatedPersonType | fn : item.contactPersonType">
    <div class="col-xs-12 row">
      <div class="col-xs-11 l-plr-0 l-pt-4">
        <div class="row l-pb-6" *ngIf="item.emailList.length">
          <div class="icon-title">
            <cds-icon icon="contact:email" class="icon"></cds-icon>
            <div class="cds-body1-demibold title">Email</div>
          </div>
          <cds-table [dataSource]="item.emailList" [class.full]="true">
            <!-- Email Address -->
            <ng-container cdsColumnDef="email">
              <cds-header-cell *cdsHeaderCellDef> Email Address </cds-header-cell>
              <cds-cell *cdsCellDef="let element">{{ element.emailAddress }}</cds-cell>
            </ng-container>
            <!-- Last Update Date -->
            <ng-container cdsColumnDef="updateDateAndStatus">
              <cds-header-cell *cdsHeaderCellDef> Last Update Date </cds-header-cell>
              <cds-cell *cdsCellDef="let element">
                <div class="date-and-status">
                  <div class="date">{{ element.lastUpdate | ssmsDate }}</div>
                  <div class="status-area">
                    <div class="dot-icon" [style]="service.getStatusColorStyle(element.status)"></div>
                    <div class="cds-body1 text">{{ service.getStatus(element.status) }}</div>
                  </div>
                </div>
              </cds-cell>
            </ng-container>
            <!-- Status -->
            <!-- <ng-container cdsColumnDef="status">
              <cds-header-cell *cdsHeaderCellDef></cds-header-cell>
              <cds-cell *cdsCellDef="let element">
                <div class="status-area">
                  <div class="dot-icon" [style]="service.getStatusColorStyle(element.status)"></div>
                  <div class="cds-body1 text">{{ service.getStatus(element.status) }}</div>
                </div>
              </cds-cell>
            </ng-container> -->
            <cds-header-row *cdsHeaderRowDef="emailDisplayedColumns"></cds-header-row>
            <cds-row class="cds-body1" *cdsRowDef="let row; columns: emailDisplayedColumns"></cds-row>
          </cds-table>
        </div>
        <div class="row l-pb-6" *ngIf="(getPhoneOrFaxList | fn : item.phoneList).length">
          <div class="icon-title">
            <cds-icon icon="contact:phone" class="icon"></cds-icon>
            <div class="cds-body1-demibold title">Phone</div>
          </div>
          <cds-table [dataSource]="getPhoneOrFaxList | fn : item.phoneList" [class.full]="true">
            <!-- type -->
            <ng-container cdsColumnDef="type">
              <cds-header-cell *cdsHeaderCellDef></cds-header-cell>
              <cds-cell *cdsCellDef="let element">{{ phoneTypeRender | fn : element.typeCode }}</cds-cell>
            </ng-container>
            <!-- Phone No. -->
            <ng-container cdsColumnDef="number">
              <cds-header-cell *cdsHeaderCellDef>Phone No.</cds-header-cell>
              <cds-cell *cdsCellDef="let element">{{ fullPhoneNoRender | fn : element.countryCode : element.phoneNo }}</cds-cell>
            </ng-container>
            <!-- Ext. -->
            <ng-container cdsColumnDef="ext">
              <cds-header-cell *cdsHeaderCellDef>Ext.</cds-header-cell>
              <cds-cell *cdsCellDef="let element">{{ element.phoneExt }}</cds-cell>
            </ng-container>
            <!-- Last Update Date -->
            <ng-container cdsColumnDef="updateDateAndStatus">
              <cds-header-cell *cdsHeaderCellDef> Last Update Date </cds-header-cell>
              <cds-cell *cdsCellDef="let element">
                <div class="date-and-status">
                  <div class="date">{{ element.lastUpdate | ssmsDate }}</div>
                  <div class="status-area">
                    <div class="dot-icon" [style]="service.getStatusColorStyle(element.status)"></div>
                    <div class="cds-body1 text">{{ service.getStatus(element.status) }}</div>
                  </div>
                </div>
              </cds-cell>
            </ng-container>
            <cds-header-row *cdsHeaderRowDef="phoneDisplayedColumns"></cds-header-row>
            <cds-row class="cds-body1" *cdsRowDef="let row; columns: phoneDisplayedColumns"></cds-row>
          </cds-table>
        </div>
        <div class="row l-pb-6" *ngIf="(getPhoneOrFaxList | fn : item.phoneList : false).length">
          <div class="icon-title">
            <cds-icon icon="device:fax" class="icon"></cds-icon>
            <div class="cds-body1-demibold title">Fax</div>
          </div>
          <cds-table [dataSource]="getPhoneOrFaxList | fn : item.phoneList : false" [class.full]="true">
            <!-- type -->
            <ng-container cdsColumnDef="type">
              <cds-header-cell *cdsHeaderCellDef></cds-header-cell>
              <cds-cell *cdsCellDef="let element">{{ phoneTypeRender | fn : element.typeCode }}</cds-cell>
            </ng-container>
            <!-- Phone No. -->
            <ng-container cdsColumnDef="number">
              <cds-header-cell *cdsHeaderCellDef>Phone No.</cds-header-cell>
              <cds-cell *cdsCellDef="let element">{{ fullPhoneNoRender | fn : element.countryCode : element.phoneNo }}</cds-cell>
            </ng-container>
            <!-- Ext. -->
            <ng-container cdsColumnDef="ext">
              <cds-header-cell *cdsHeaderCellDef>Ext.</cds-header-cell>
              <cds-cell *cdsCellDef="let element">{{ element.phoneExt }}</cds-cell>
            </ng-container>
            <!-- Last Update Date -->
            <ng-container cdsColumnDef="updateDateAndStatus">
              <cds-header-cell *cdsHeaderCellDef> Last Update Date </cds-header-cell>
              <cds-cell *cdsCellDef="let element">
                <div class="date-and-status">
                  <div class="date">{{ element.lastUpdate | ssmsDate }}</div>
                  <div class="status-area">
                    <div class="dot-icon" [style]="service.getStatusColorStyle(element.status)"></div>
                    <div class="cds-body1 text">{{ service.getStatus(element.status) }}</div>
                  </div>
                </div>
              </cds-cell>
            </ng-container>
            <cds-header-row *cdsHeaderRowDef="phoneDisplayedColumns"></cds-header-row>
            <cds-row class="cds-body1" *cdsRowDef="let row; columns: phoneDisplayedColumns"></cds-row>
          </cds-table>
        </div>
      </div>
    </div>
  </app-cd-accordion-panel>
</app-cd-accordion>
