import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { CdsButtonConfig } from '@cds/ng-core/button';
import { CdsDropdownConfig } from '@cds/ng-core/dropdown';
import { CdsIconConfig } from '@cds/ng-core/icon';
import { CdsRadioConfig } from '@cds/ng-core/radio';
import { ActionIcons } from '@cds/ng-themes';
import { CdsAlertService } from '@cds/ng-web-components/alert';
import { CdsPopupService } from '@cds/ng-web-components/popup';
import { Subscription } from 'rxjs';
import { empfCountryOption } from '../case-summary/declaration-iff-er/empf-declaration-form.config';
import { EmpfDeclarationConfirmationComponent } from '../empf-declaration-confirmation/empf-declaration-confirmation.component';
import { AgentinfoService } from './agentinfo.service';
import { AdditionAgent, EiffRequest } from './eiff-request.model';

@Component({
  selector: 'app-empf-declaration-form',
  templateUrl: './empf-declaration-form.component.html',
  styleUrls: ['./empf-declaration-form.component.scss'],
})
export class EmpfDeclarationFormComponent implements OnInit, OnDestroy {
  constructor(
    private dialogRef: MatDialogRef<EmpfDeclarationFormComponent>,
    private cdsPopup: CdsPopupService,
    private agentInfoService: AgentinfoService,
    private alert: CdsAlertService
  ) {}
  ngOnDestroy(): void {
    this.agentinfoScription?.unsubscribe();
    this.errorSub?.unsubscribe();
  }
  clearButton: CdsButtonConfig = {
    size: 'sm',
    style: 'primary',
    disabled: false,
  };
  // RadioformControl: FormControl = new FormControl('');
  empfnocontrol: FormControl = new FormControl('', [Validators.required, Validators.maxLength(50)]);
  selectmode = 0;
  agentcontactinfo = new AdditionAgent();
  result = 0;
  private errorSub?: Subscription;
  private agentinfoScription?: Subscription;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  empfDeclarationForm: any = {
    empfno: { value: null, error: '' },
    surname: { value: null, error: '' },
    givenname: { value: null, error: '' },
    noselect: { value: null, error: '' },
    emailaddress: { value: null, error: '' },
    phonenumber: { value: null, error: '' },
    additionAgent: { value: null, error: '' },
  };

  myEiffRequest = new EiffRequest();

  // Empfno:new FormControl('',[Validators.required,Validators.maxLength(50)]),

  // t = this.Eiffform.get('Empfno')
  radioConfig: CdsRadioConfig = {
    options: [
      { label: '電郵地址', value: 1 },
      { label: '電話號碼', value: 2 },
    ],
  };
  countrycodeConfig: CdsDropdownConfig = {
    options: empfCountryOption,
    placeholder: '+852 (HK)',
    color: 'ml',
  };
  iconConfig: CdsIconConfig = {
    size: 'sm',
    color: 'default',
  };
  infoIcon = ActionIcons.info_1;
  ngOnInit(): void {}
  closepop() {
    this.dialogRef.close({ agree: true });
  }
  onModechange(result: string) {
    this.empfDeclarationForm.noselect.error = '';
    this.selectmode = parseInt(result);
    if (this.selectmode === 1) {
      this.empfDeclarationForm.phonenumber.error = '';
      this.empfDeclarationForm.phonenumber.value = '';
    } else {
      this.empfDeclarationForm.emailaddress.error = '';
      this.empfDeclarationForm.emailaddress.value = '';
    }
  }
  empfnochange() {
    this.empfDeclarationForm.empfno.error = '';
  }
  surnamechange() {
    this.empfDeclarationForm.surname.error = '';
  }
  givennamechange() {
    this.empfDeclarationForm.givenname.error = '';
  }
  emailaddresschange() {
    this.empfDeclarationForm.emailaddress.error = '';
  }
  phonenumberchange() {
    this.empfDeclarationForm.phonenumber.error = '';
  }
  BackTosummary() {
    this.dialogRef.close({ agree: true });
  }
  validatedeclaration(): boolean {
    if (this.empfDeclarationForm.empfno.value === null || this.empfDeclarationForm.empfno.value === '') {
      this.empfDeclarationForm.empfno.error = '請輸入eMPF申請編號';
      return false;
    }
    if (this.empfDeclarationForm.surname.value === null || this.empfDeclarationForm.surname.value === '') {
      this.empfDeclarationForm.surname.error = '請輸入客戶全名';
      return false;
    }
    if (this.empfDeclarationForm.givenname.value === null || this.empfDeclarationForm.givenname.value === '') {
      this.empfDeclarationForm.givenname.error = '請輸入客戶全名';
      return false;
    }
    if (this.selectmode === 0) {
      this.empfDeclarationForm.noselect.error = '	請選擇客戶聯繫方式';
      return false;
    }
    if (this.selectmode === 1) {
      if (this.empfDeclarationForm.emailaddress.value === null || this.empfDeclarationForm.emailaddress.value === '') {
        this.empfDeclarationForm.emailaddress.error = '請輸入客戶電郵地址';
        return false;
      }
      const reg = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$/;
      if (!reg.test(this.empfDeclarationForm.emailaddress.value)) {
        this.empfDeclarationForm.emailaddress.error = '請輸入客戶電郵地址';
        return false;
      }
    } else {
      if (this.empfDeclarationForm.phonenumber.value === null || this.empfDeclarationForm.phonenumber.value === '') {
        this.empfDeclarationForm.phonenumber.error = '請輸入有效的手機號碼';
        return false;
      }
    }
    return true;
  }
  RouteTosubmit() {
    if (!this.validatedeclaration()) {
      return;
    }
    this.myEiffRequest.eMPFno = this.empfDeclarationForm.empfno.value;
    this.myEiffRequest.surnametc = this.empfDeclarationForm.surname.value;
    this.myEiffRequest.givennametc = this.empfDeclarationForm.givenname.value;
    this.myEiffRequest.phonenumber = this.empfDeclarationForm.phonenumber.value;
    this.myEiffRequest.emailaddress = this.empfDeclarationForm.emailaddress.value;
    this.myEiffRequest.addAgentCode = this.empfDeclarationForm.additionAgent.value;
    if (this.myEiffRequest.addAgentCode !== '' && this.myEiffRequest.addAgentCode !== undefined) {
      this.agentInfoService.GetagentinfoSummary(this.myEiffRequest.addAgentCode);
      this.errorSub = this.agentInfoService.erroragentinfoSubject.subscribe(errorMessage => {
        const alertTitle = 'Got Error!';
        this.alert.error(alertTitle, errorMessage);
      });
      this.agentinfoScription = this.agentInfoService.agentinfoDataSubject.subscribe(response => {
        if (response.result !== undefined) {
          this.result = response.result;
          if (response.data !== undefined) {
            this.agentcontactinfo = response.data;
          }
        }
      });
    }
    this.dialogRef.close({ agree: true });
    this.cdsPopup.open(EmpfDeclarationConfirmationComponent, {
      size: 'md',
      data: {
        message: 'The changes you’ve made will be lost, are you sure to continue?',
        cancel: 'Cancel',
        continue: 'Continue',
      },
    });
  }
}
