export enum AccumulationType {
  PLAN_HIGH = 'PLAN_HIGH',
  RELEASED_CYTD = 'RELEASED_CYTD',
  ANNUAL_PREM_CYTD = 'ANNUAL_PREM_CYTD',
  PREMIUM_CYTD = 'PREMIUM_CYTD',
  PREMIUM_PTD = 'PREMIUM_PTD',
  GROSS_PREM_CYTD = 'GROSS_PREM_CYTD',
  CYTD = 'CYTD',
  PTD = 'PTD',
  CAL_YTD = 'CAL_YTD',
}
