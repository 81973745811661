import { ColumnConfig } from 'src/app/shared/data-table';

export const PAGE_SIZE = 10;

export const I18N_KEY = {
  ACCOUNT_NO_INVALID: 'commission.common.accountNo.invalid',
  MEMBER_NO_INVALID: 'commission.common.memberNo.invalid',
  INVALID_CLEARED_DATE: 'Invalid Cleard Date',
};

export enum SchemeCode {
  GS = 'GS',
}
export const SchemeCodeOpts = [{ label: 'GS', value: SchemeCode.GS }];

export const SchemeCodeConfig = {
  label: 'common.business.schemeCode',
  options: SchemeCodeOpts,
};

export enum StatusCode {
  TAGGED = 'TAGGED',
  CLEARED = 'CLEARED',
  UNIDENTIFIED = 'UNIDENTIFIED',
}

export const StatusCodeOpts = [
  { label: 'TAGGED', value: StatusCode.TAGGED },
  { label: 'CLEARED', value: StatusCode.CLEARED },
  { label: 'UNIDENTIFIED', value: StatusCode.UNIDENTIFIED },
];

export const StatusCodeConfig = {
  label: 'common.business.statusCode',
  placeholder: 'common.business.statusCode.placeholder',
  options: StatusCodeOpts,
};

export enum PayMthd {
  BILL_PYM = 'BILL_PYM',
  CASHIER_ORDER = 'CASHIER_ORDER',
  DIRECT_DEBIT = 'DIRECT_DEBIT',
  PHYSICAL_CHEQUE = 'PHYSICAL_CHEQUE',
  MPFA_TRANSFER = 'MPFA_TRANSFER',
  E_CHEQUE = 'E_CHEQUE',
  FPS = 'FPS',
  CMU = 'CMU',
  OTHERS = 'OTHERS',
}

export const PayMthdOpts = [
  { label: 'BILL_PYM', value: PayMthd.BILL_PYM },
  { label: 'CASHIER_ORDER', value: PayMthd.CASHIER_ORDER },
  { label: 'DIRECT_DEBIT', value: PayMthd.DIRECT_DEBIT },
  { label: 'PHYSICAL_CHEQUE', value: PayMthd.PHYSICAL_CHEQUE },
  { label: 'MPFA_TRANSFER', value: PayMthd.MPFA_TRANSFER },
  { label: 'E_CHEQUE', value: PayMthd.E_CHEQUE },
  { label: 'FPS', value: PayMthd.FPS },
  { label: 'CMU', value: PayMthd.CMU },
  { label: 'OTHERS', value: PayMthd.OTHERS },
];

export const PayMthdConfig = {
  label: 'common.business.paymentMethod',
  placeholder: 'common.business.paymentMethod.placeholder',
  options: PayMthdOpts,
};

export const PAYMENT_DETAILS_COLUMNS: Array<ColumnConfig> = [
  { key: 'schemeCd', sticky: true, title: 'common.business.schemeCode' },
  { key: 'erAcctCd', stickyEnd: true, title: 'common.business.employerAcconutCode' },
  { key: 'payrollGroupId', title: 'common.business.payrollGroupID' },
  { key: 'mbAcctCd', title: 'common.business.memberAcconutCode' },
  { key: 'rcvRefNo', title: 'common.business.receiptReferenceNumber' },
  { key: 'statusCd', title: 'common.business.statusCode' },
  { key: 'paySubmitRefNo', title: 'common.business.paymentSubmissionReferenceNumber' },
  { key: 'payMthd', title: 'common.business.paymentMethod' },
  { key: 'rcvdAmt', title: 'common.business.receivedAmount' },
  { key: 'bnkCd', title: 'common.business.bankCode' },
  { key: 'branchCd', title: 'common.business.branchCode' },
  { key: 'bnkAcctNo', title: 'common.business.bankAccountNumber' },
  { key: 'payerNm', title: 'common.business.payerName' },
  { key: 'chqDate', title: 'common.business.chequeDate' },
  { key: 'chqBnkInDate', title: 'common.business.chequeBankInDate' },
  { key: 'othTxnRefNo', title: 'common.business.otherTransactionReferenceNumber' },
  { key: 'clearDate', title: 'common.business.clearedDate' },
  { key: 'rcvdDate', title: 'common.business.receivedDate' },
  { key: 'clkChqFlg', title: 'paymentJournal.clkChqFlg' },
  { key: 'splitfundingID', title: 'paymentJournal.splitfundingID' },
  { key: 'splitfundingFlag', title: 'paymentJournal.splitfundingFlag' },
  { key: 'trBnkCd', title: 'common.business.trusteeBankCode' },
  { key: 'trBranchCd', title: 'common.business.trusteeBranchBankCode' },
  { key: 'trBnkAcctNo', title: 'common.business.trusteeBankAccountNumber' },
  { key: 'wfRefNo', title: 'common.business.workflowReferenceNumber' },
  { key: 'reportNo', title: 'common.business.reportNo' },
  { key: 'currency', title: 'common.business.currency' },
];

export const EXCEL_COLUMNS = [
  'SCHEME CODE',
  'EMPLOYER ACCOUNT CODE',
  'PAYROLL GRPOUP ID',
  'MEMBER ACCOUNT CODE',
  'RECEIPT REFERENCE NO',
  'STATUS CODE',
  'PAYMENT SUBMISSSION REFERENCE NUMBER',
  'PAYMENT METHOD',
  'RECEIVED AMOUT',
  'BANK CODE',
  'BRANCH CODE',
  'BANK ACCOUNT NUMBER',
  'PAYER NAME',
  'CHEQUE DATE',
  'CHEQUE BANK-IN DATE',
  'OTHER TRANSACTION REFERENCE NUMBER',
  'CLEARED DATE',
  'RECEIVED DATE',
  'CHEQUE DIRECT MAILING TO EMPF FLAG',
  'SPLIT FUNDING ID',
  'SPLIT FUNDING (FLAG)',
  'TRUSTEE BANK CODE',
  'TRUSTEE BANK BRANCH CODE',
  'TRUSTEE BANK ACCOUNT NUMBER',
  'WORKFLOW REFERENCE NUMBER',
  'REPORT NO',
  'CURRENCY',
];
