import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { PermissionAccess, PermissionItem } from 'src/app/core/models/enum/permission.enum';
import { PermissionService } from 'src/app/core/services/permission.service';
import { BillingClass, ErBaseRequest, SchemeInfo } from '../../employer';
import { EmployerService } from '../../employer.service';

@Component({
  selector: 'app-scheme-info',
  templateUrl: './scheme-info.component.html',
  styleUrls: ['./scheme-info.component.scss'],
})
export class SchemeInfoComponent implements OnInit, OnChanges {
  isStaff = false;

  constructor(private service: EmployerService, private permissionService: PermissionService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['billingClass'].currentValue !== undefined && changes['billingClass'].currentValue !== changes['billingClass'].previousValue) {
      this.getSchemeInfo();
    }
  }

  @Input() billingClass?: BillingClass;
  @Input() employerId? = '';

  schemeInfo?: SchemeInfo;

  getSchemeInfo() {
    const _param: ErBaseRequest = {
      payrollGroupId: this.billingClass?.payrollGroupId,
      id: this.employerId,
    };
    this.service.getSchemeInfo(_param).subscribe(data => {
      if (data.result === 0 && data.data) {
        this.schemeInfo = data.data;
      }
    });
  }

  ngOnInit(): void {}

  permissionFilter() {
    this.permissionService.hasPermission(PermissionAccess.R, PermissionItem.CUSTOMER_EMPLOYER).then(res => {
      this.isStaff = res;
    });
  }
}
