import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-cd-fake-tabs',
  templateUrl: './cd-fake-tabs.component.html',
  styleUrls: ['./cd-fake-tabs.component.scss'],
})
export class CdFakeTabsComponent implements OnInit {
  @Input() options: string[] = [];
  @Input() selectedIndex = 0;
  @Output() selectedIndexChange = new EventEmitter<number>();

  constructor() {}

  ngOnInit(): void {}

  onChange(index: number) {
    this.selectedIndex = index;
    this.selectedIndexChange.emit(this.selectedIndex);
  }
}
