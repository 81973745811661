/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Inject, OnInit } from '@angular/core';
import { CDS_POPUP_DATA, MatDialogRef } from '../cd-popup.service';

@Component({
  selector: 'app-cd-popup-got',
  templateUrl: './cd-popup-got.component.html',
  styleUrls: ['./cd-popup-got.component.scss'],
})
export class CdPopupGotComponent implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<CdPopupGotComponent>,
    @Inject(CDS_POPUP_DATA)
    public data: {
      title: string;
      message: string;
      subMessage: string;
    }
  ) {}

  ngOnInit() {}

  gotIt() {
    this.dialogRef.close({ got: true });
  }
}
