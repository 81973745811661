<div #productionCredit class="widgets-production-credit-by-district">
  <div class="condition-box">
    <div>
      <cds-icon class="icon-high-performing-team" icon="strategic_priority:high_performing_team"></cds-icon>
    </div>
    <div style="margin-left: 1.375rem">
      <div>
        <span class="cds-body2">District Code</span>
      </div>
      <div>
        <cds-dropdown [ngStyle]="{ width: widthLess698 ? '228px' : '360px' }" [config]="districtCodeConfig" [formControl]="districtCode"></cds-dropdown>
      </div>
    </div>
  </div>

  <div class="pc-list" [ngClass]="{ 'width-less-698': widthLess698 }">
    <div class="first-year-pc">
      <cds-donut-chart
        class="chart"
        [type]="type"
        [size]="size"
        [keylineWidth]="keylineWidth"
        [data]="firstYearPCData"
        [color]="color"
        [colorMode]="colorMode"
        [chartColors]="chartColors">
        <cds-donut-title>
          <div>
            <span class="cds-h3-demibold">67%</span>
          </div>
        </cds-donut-title>
      </cds-donut-chart>
      <div class="info">
        <div>
          <span class="cds-detail2-bold production-name">First Year PC</span>
        </div>
        <div class="l-d-f l-mt-2 l-mb-1" style="align-items: baseline">
          <div>
            <span class="cds-h2-demibold">32,180</span>
          </div>
          <div class="l-ml-1">
            <span class="cds-body2-demibold">/</span>
          </div>
        </div>
        <div>
          <span class="cds-body1">50,000 PC</span>
        </div>
        <div>
          <span class="cds-detail1">Target submitted details</span>
        </div>
      </div>
    </div>

    <div class="renewal-pc">
      <cds-donut-chart
        class="chart"
        [type]="type"
        [size]="size"
        [keylineWidth]="keylineWidth"
        [data]="renewalPCData"
        [color]="color"
        [colorMode]="colorMode"
        [chartColors]="chartColors">
        <cds-donut-title>
          <div>
            <span class="cds-h3-demibold">93%</span>
          </div>
        </cds-donut-title>
      </cds-donut-chart>
      <div class="info">
        <div>
          <span class="cds-detail2-bold production-name">Renewal PC</span>
        </div>
        <div class="l-d-f l-mt-2 l-mb-1" style="align-items: baseline">
          <div>
            <span class="cds-h2-demibold">56,180</span>
          </div>
          <div class="l-ml-1">
            <span class="cds-body2-demibold">/</span>
          </div>
        </div>
        <div>
          <span class="cds-body1">60,000 PC</span>
        </div>
        <div>
          <span class="cds-detail1">Target submitted details</span>
        </div>
      </div>
    </div>
  </div>
</div>
