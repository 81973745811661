/* eslint-disable @typescript-eslint/no-explicit-any */
import { AbstractControl, AsyncValidator, ValidationErrors } from '@angular/forms';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, of } from 'rxjs';

import { CommissionScaleService } from 'src/app/core/services/commission/commission-scale.service';

import { I18N_KEY } from './add-scale-records.config';
import moment from 'moment';

export function dateValidator(effectiveDates?: string[]) {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value.toString().trim();
    if (!value) {
      return { error: I18N_KEY.EFFECTIVE_DATE_NULL };
    }
    if (!moment(value, 'DD/MM/YYYY', true).isValid()) {
      return { error: I18N_KEY.EFFECTIVE_DATE_INVALID };
    }
    const str = moment().format('DD/MM/YYYY');
    if (moment(value, 'DD/MM/YYYY').diff(moment(str, 'DD/MM/YYYY'), 'days') < 0) {
      return { error: I18N_KEY.EFFECTIVE_DATE_IS_FUTURE };
    }
    const strDate = moment(value, 'DD/MM/YYYY').format('YYYY-MM-DD');
    if (effectiveDates && effectiveDates.includes(strDate)) {
      return { error: I18N_KEY.EFFECTIVE_DATE_IS_DUPLICATE };
    }
    return null;
  };
}

export function scaleCodeValidator(defaultValue: string) {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value.trim();

    if (value === 'NOCOMM') {
      return null;
    }

    if (!value && !defaultValue) {
      return null;
    }

    if (!value && defaultValue) {
      return { error: I18N_KEY.SCALE_CODE_NULL };
    }

    // const reg = /^[a-zA-Z0-9]{1,6}$/;
    // if(!reg.test(value)) {
    //   return { error: I18N_KEY.SCALE_CODE_INVALID};
    // }

    return null;
  };
}

@Injectable({ providedIn: 'root' })
export class ScaleCodeAsyncValidator implements AsyncValidator {
  constructor(private commissionScaleService: CommissionScaleService) {}

  validate(control: AbstractControl): Observable<ValidationErrors | null> {
    const value = control.value.trim();
    if (!value || !control.dirty) {
      return of(null);
    }
    if (value === 'NOCOMM') {
      return of(null);
    }
    const searchForm = {
      page: 0,
      size: 1,
      scaleCodeSpecify: value,
    };
    return this.commissionScaleService.selectList(searchForm).pipe(
      map(res => {
        if (res.data && res.data.content && res.data.content.length) {
          return null;
        }
        return { error: I18N_KEY.SCALE_CODE_INVALID };
      }),
      catchError(() => of(null))
    );
  }
}
