/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { BasicResponse, IPageResponse } from '../../models/response/response';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CommissionJournalService {
  constructor(private http: HttpClient) {}

  getData(params: any): Observable<BasicResponse<IPageResponse<CommissionJournalItem>>> {
    return this.http.get<BasicResponse<IPageResponse<CommissionJournalItem>>>(
      `${environment.apiPrefix}${environment.commissionServiceExt}${'/commission-journal/list'}`,
      { params: params }
    );
  }

  download(params: any): Observable<BasicResponse<Array<CommissionJournalItem>>> {
    return this.http.get<BasicResponse<Array<CommissionJournalItem>>>(
      `${environment.apiPrefix}${environment.commissionServiceExt}${'/commission-journal/list-all'}`,
      {
        params: params,
      }
    );
  }

  getApeData(params: any): Observable<BasicResponse<IPageResponse<ApeJournalItem>>> {
    return this.http.get<BasicResponse<IPageResponse<ApeJournalItem>>>(`${environment.apiPrefix}${environment.commissionServiceExt}${'/ape-journal/list'}`, {
      params: params,
    });
  }
}

export interface CommissionJournalItem {
  runDate: string;
  employerAccountCode?: string;
  memberAccountCode?: string;
  accountType: string;
  payRollGroupId: string;
  subActivityCd: string;
  contStart?: string;
  contEnd?: string;
  transactionReferenceNo?: string;
  groupReferenceNo?: string;
  payReferenceNo?: string;
  commissionPoolNo: string;
  txnCode: string;
  txnEffectiveDate?: string;
  contributionFrequency?: string;
  totalPremiumReceived?: string;
  commFlag?: string;
  pacFlag?: string;
  processor?: Processor;
  commissionableAmount?: string;
  list: Array<CommissionJournalAgentItem>;
}
export interface CommissionJournalAgentItem {
  agentCode: string;
  share: number;
  scaleRate?: string;
  commissionCode: string;
  amount: number;
  amountPremium: number;
  agentName: string;
  [propName: string]: any;
}

export interface Processor {
  name: string;
}
export interface ApeJournalItem {
  runDate: string;
  txnEffectiveDate?: string;
  employerAccountCode?: string;
  memberAccountCode?: string;
  accountType: string;
  transactionType?: string;
  paySubmitRefNo?: string;
  groupTransRefNo?: string;
  pacFlag?: string;
  processor?: Processor;
  specialIndicator?: string;
  agentChannel?: string;
  branch?: string;
  agentCode?: string;
  share?: number;
  commissionCode?: string;
  apeType?: string;
  apeAmount?: number;
  apeSystemAdjust?: number;
  vsfAmount?: number;
  averageAumAmount?: number;
  trailerFee?: number;
}
