<app-cd-popup>
  <cds-popup-title *ngIf="data.title">{{ data.title | lang }}</cds-popup-title>
  <cds-popup-content>
    <div class="cds-h2-light" *ngIf="data.message" innerHtml="{{ data.message | lang }}"></div>
    <div class="cds-h2-light" *ngIf="!data.message" innerHtml="{{ 'common.deleteWarning' | lang }}"></div>
    <div *ngIf="data.subMessage" innerHtml="{{ data.subMessage | lang }}"></div>
  </cds-popup-content>
  <cds-popup-actions>
    <cds-button size="sm" [style]="'secondary'" (click)="cancle()">{{ 'common.cancel' | lang }} </cds-button>
    <cds-button size="sm" (click)="delete()">{{ 'common.delete' | lang }} </cds-button>
  </cds-popup-actions>
</app-cd-popup>
