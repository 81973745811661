<div class="cds-h4-demibold">
  {{ 'ee.opt.Customer-Campaign' | lang }}
</div>
<div class="table-container">
  <app-data-table
    [frontPagination]="false"
    [isLoading]="isLoading"
    [fixed]="false"
    [overlay]="true"
    [pageConfig]="pageConfig"
    [dataSource]="dataSource"
    [columnsConfig]="columnsConfig"
    (reloadTable)="reloadTable($event)">
    <!-- body templates -->
    <ng-template appDtBodyCellDef="campaignPeriod" let-element>
      <div class="min-width-15">
        <span>{{ dateTransToDisplay | fn : element.campaignPeriodFrom }} </span>-<span> {{ dateTransToDisplay | fn : element.campaignPeriodTo }}</span>
      </div>
    </ng-template>

    <ng-template appDtBodyCellDef="campaignType" let-element>
      <div>
        {{ findOptionLabelByValue | fn : element.campaignType : CampaignTypeOptions }}
      </div>
    </ng-template>

    <ng-template appDtBodyCellDef="productTypes" let-element>
      <div class="min-width-15">
        {{ listValueRender | fn : element.productTypes : productTypeOptions }}
      </div>
    </ng-template>

    <ng-template appDtBodyCellDef="agentCommunication" let-element>
      <div class="min-width-15">{{ listValueRender | fn : element.agentCommunication : agentCommunicationOptions }}</div>
    </ng-template>

    <ng-template appDtBodyCellDef="customerFirstResponseDate" let-element>
      <div>{{ element.customerFirstResponseDate | ssmsDate }}</div>
    </ng-template>

    <ng-template appDtBodyCellDef="customerLastResponseDate" let-element>
      <div>{{ element.customerLastResponseDate | ssmsDate }}</div>
    </ng-template>

    <ng-template appDtBodyCellDef="workbasket" let-element>
      <div class="click flex" *ngIf="element.workbasket" (click)="jumpTo(element.workbasket)">
        <cds-icon color="#EC6453" size="sm" icon="action:right_fill" class="l-mr-2"></cds-icon>
        <span class="cds-body2 l-mb-0 font-weight-6">Workbasket</span>
      </div>
    </ng-template>

    <ng-template appDtBodyCellDef="agentSam" let-element>
      <div class="click flex" *ngIf="element.agentSam" (click)="jumpTo(element.agentSam)">
        <cds-icon color="#EC6453" size="sm" icon="action:right_fill" class="l-mr-2"></cds-icon>
        <span class="cds-body2 l-mb-0 font-weight-6">AgentSAM</span>
      </div>
    </ng-template>
  </app-data-table>
</div>
