<ul class="header-megamenu nav">
  <li class="nav-item">
    <button [ngbPopover]="footerPopover" [autoClose]="'outside'" popoverClass="rm-max-width" type="button" class="btn btn-link dropdown-toggle">
      <span>
        <i class="nav-link-icon pe-7s-gift"></i>
        Footer Menu
      </span>
    </button>
  </li>
  <li class="nav-item">
    <div class="btn-group" ngbDropdown>
      <button type="button" class="btn btn-link dropdown-toggle-no-caret" ngbDropdownToggle>
        <span> Grid Menu </span>
      </button>
      <div class="dropdown-menu-lg" ngbDropdownMenu>
        <div class="dropdown-menu-header">
          <div class="dropdown-menu-header-inner bg-tempting-azure">
            <div class="menu-header-image dd-header-bg-1"></div>
            <div class="menu-header-content text-dark">
              <h5 class="menu-header-title">Two Column Grid</h5>
              <h6 class="menu-header-subtitle">Easy grid navigation inside popovers</h6>
            </div>
          </div>
        </div>
        <div class="grid-menu grid-menu-2col">
          <div class="g-0 row">
            <div class="col-sm-6">
              <button class="btn-icon-vertical btn-transition-text btn-transition btn-transition-alt pt-2 pb-2 btn btn-outline-dark">
                <i class="lnr-lighter text-dark opacity-7 btn-icon-wrapper mb-2"></i>Automation
              </button>
            </div>
            <div class="col-sm-6">
              <button class="btn-icon-vertical btn-transition-text btn-transition btn-transition-alt pt-2 pb-2 btn btn-outline-danger">
                <i class="lnr-construction text-danger opacity-7 btn-icon-wrapper mb-2"></i>Reports
              </button>
            </div>
            <div class="col-sm-6">
              <button class="btn-icon-vertical btn-transition-text btn-transition btn-transition-alt pt-2 pb-2 btn btn-outline-success">
                <i class="lnr-bus text-success opacity-7 btn-icon-wrapper mb-2"></i>Activity
              </button>
            </div>
            <div class="col-sm-6">
              <button class="btn-icon-vertical btn-transition-text btn-transition btn-transition-alt pt-2 pb-2 btn btn-outline-focus">
                <i class="lnr-gift text-focus opacity-7 btn-icon-wrapper mb-2"></i>Settings
              </button>
            </div>
          </div>
        </div>
        <ul class="nav flex-column">
          <li class="nav-item-divider nav-item"></li>
          <li class="nav-item-btn clearfix nav-item">
            <div class="float-start">
              <button class="btn btn-link btn-sm">Link Button</button>
            </div>
            <div class="float-end">
              <button class="btn-shadow btn btn-info btn-sm">Info Button</button>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </li>
</ul>
<ng-template #footerPopover>
  <div class="dropdown-mega-menu dropdown-mega-menu-sm">
    <div class="grid-menu grid-menu-2col">
      <div class="g-0 row">
        <div class="col-sm-6 col-xl-6">
          <ul class="nav flex-column">
            <li class="nav-item-header nav-item">Overview</li>
            <li class="nav-item">
              <a [routerLink]="[]" class="nav-link">
                <i class="nav-link-icon lnr-inbox"></i>
                <span>Contacts</span>
              </a>
            </li>
            <li class="nav-item">
              <a [routerLink]="[]" class="nav-link">
                <i class="nav-link-icon lnr-book"></i>
                <span>Incidents</span>
                <div class="ms-auto badge rounded-pill bg-danger">5</div>
              </a>
            </li>
            <li class="nav-item">
              <a [routerLink]="[]" class="nav-link">
                <i class="nav-link-icon lnr-picture"></i>
                <span>Companies</span>
              </a>
            </li>
            <li class="nav-item">
              <a disabled="disabled" [routerLink]="[]" class="nav-link disabled">
                <i class="nav-link-icon lnr-file-empty"></i>
                <span>Dashboards</span>
              </a>
            </li>
          </ul>
        </div>
        <div class="col-sm-6 col-xl-6">
          <ul class="nav flex-column">
            <li class="nav-item-header nav-item">Sales &amp; Marketing</li>
            <li class="nav-item">
              <a [routerLink]="[]" class="nav-link">Queues</a>
            </li>
            <li class="nav-item">
              <a [routerLink]="[]" class="nav-link">Resource Groups</a>
            </li>
            <li class="nav-item">
              <a [routerLink]="[]" class="nav-link"
                >Goal Metrics
                <div class="ms-auto badge bg-warning">3</div>
              </a>
            </li>
            <li class="nav-item">
              <a [routerLink]="[]" class="nav-link">Campaigns</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</ng-template>
