<div class="report-center">
  <div class="report-center-text-container">
    <span class="cds-h3-bold">Report Center</span>
  </div>

  <div class="search-and-download-report-box l-d-f l-ai-fs">
    <div class="icon-box">
      <cds-icon icon="form:form_search" class="icon-form-search"></cds-icon>
    </div>
    <div class="search-right l-ml-5">
      <div>
        <span class="cds-h2-light">Search and Download Report</span>
      </div>
      <div>
        <span class="cds-body2">You may search the report by keywords, like report categories, report type, report code, in report name.</span>
      </div>
      <div class="l-mt-5 l-d-f l-as-cen l-jc-sb">
        <div class="l-fg-1">
          <app-cd-search
            iconLocation="right"
            [options]="options"
            (search)="search($event)"
            placeholder="Input Report Name"
            [control]="formControl"></app-cd-search>
        </div>
        <div *ngIf="isDev" class="cds-body2 l-mb-0 l-ml-4 l-d-f pointer l-as-cen div-border filter-btn" (click)="openFilter()">
          <cds-icon icon="action:filter"></cds-icon>
          <span class="l-ml-1">Filter({{ filters.length }})</span>
        </div>
      </div>
    </div>
  </div>

  <ng-container *ngIf="searchValue && searchValue.length > 0">
    <div class="l-ml-7 l-mt-7 file-list-text-box">
      <span class="cds-body-hero-light">File list</span>
    </div>

    <div [ngStyle]="{ 'overflow-x': isShowProgress ? 'auto' : 'unset' }">
      <div class="result-table" [ngStyle]="{ width: resultTableIsFitContent ? 'fit-content' : 'unset' }">
        <div class="table-box" [ngClass]="[isShowProgress ? 'loading' : '']">
          <cds-table #filesTable [dataSource]="dataSource">
            <ng-container cdsColumnDef="reportDate">
              <cds-header-cell *cdsHeaderCellDef class="report-date-column-width"> Report Date (DD/MM/YYYY) </cds-header-cell>
              <cds-cell *cdsCellDef="let element" class="report-date-column-width">
                {{ element.reportDate }}
              </cds-cell>
            </ng-container>

            <ng-container cdsColumnDef="fileName">
              <cds-header-cell *cdsHeaderCellDef class="file-name-column-width">File Name</cds-header-cell>
              <cds-cell *cdsCellDef="let element" class="file-name-column-width">
                {{ element.fileName }}
              </cds-cell>
            </ng-container>

            <ng-container cdsColumnDef="action" stickyEnd>
              <cds-header-cell *cdsHeaderCellDef class="action-column-width">Action</cds-header-cell>
              <cds-cell *cdsCellDef="let element" class="action-column-width">
                <div [ngStyle]="{ 'pointer-events': isDownloading ? 'none' : 'unset' }" (click)="downloadFile(element)" class="l-d-f l-ai-cen download">
                  <div>
                    <cds-icon icon="arrow:download" class="icon-arrow-download"></cds-icon>
                  </div>
                  <div class="l-ml-2">
                    <span class="cds-h6-demibold" style="color: var(--cds-color-m-green)">Download</span>
                  </div>
                </div>
              </cds-cell>
            </ng-container>

            <cds-header-row *cdsHeaderRowDef="displayedColumns"></cds-header-row>
            <cds-row *cdsRowDef="let row; columns: displayedColumns"></cds-row>
          </cds-table>
        </div>
        <div *ngIf="isShowProgress" class="progress-box">
          <cds-progress mode="indeterminate" [diameter]="60" [type]="cdsProgressType" [text]="cdsProgressTxt" [color]="cdsProgressColor"></cds-progress>
        </div>
      </div>
    </div>

    <div class="row result-table-footer" [style]="{ 'max-width': resultTableFooterMaxWidth + 'px' }">
      <div class="col-sm-6 col-xs-12 footer-pagination">
        <cds-pagination [color]="'ml'" [pageCount]="pageCount" [page]="currentPage" (pageChange)="pageChange($event)"></cds-pagination>
      </div>
      <div class="col-sm-6 col-xs-12 page-search">
        <span>Page </span>
        <input class="input" type="number" [value]="currentPage" #pageNoSearchBox (keydown.enter)="searchByInputPageNo($event, pageNoSearchBox.value)" />
        <span> of {{ pageCount }} </span>
      </div>
    </div>
  </ng-container>

  <div class="download-progress" *ngIf="isDownloading">
    <div class="l-d-f l-ai-cen font-weight-6">
      <cds-icon class="l-mr-4" size="md" icon="form:save_file"></cds-icon>
      <div class="l-fg-1 l-pb-2">
        <div class="l-mb-3">File Downloading...</div>
        <cds-progress type="bar" [value]="percentComplete.value"></cds-progress>
      </div>
    </div>
    <cds-icon (click)="closeDownload()" size="md" style="cursor: pointer" icon="action:button_x"></cds-icon>
  </div>
</div>
