import { CdRadioConfig } from 'src/app/shared/cd-radio/cd-radio.component';
import { VoidInfo } from './case-details.component';

export interface AgentsInfoRsp {
  agentCd: string;
  agentId: string;
  agentName: string;
  agentNameChn: string;
  iffAgentStatus: string;
  iffAgentStatusEnum: number;
  iffAgentSubDt: string;
  primaryAgent: boolean;
  regulatedAct: boolean;
  channelConfig?: CdRadioConfig;
  channelValue?: number;
  servicingAgent: boolean;
  commissionPercentage: number;
  hkpcPercentage: number;
}

export interface CaseDetailsRsp {
  hkidOrPassportNo: string;
  empfHkidOrPassportNo: string;
  clientEmail: string;
  clientNameChn: string;
  clientNameEng: string;
  clientPhoneNumber: string;
  empfRefNo: string;
  empfStatus: string;
  empfStatusEnum: number;
  empfSubDt: string;
  firstIffSubDt: string;
  iffClientName: string;
  iffDeliveryChannel: string;
  iffStatus: string;
  iffStatusEnum: number;
  iffSubDt: string;
  latestIffSubDt: string;
  agentsInfos: AgentsInfoRsp[];
  couldResend: boolean;
  iffDeclineExpireReasons: {
    code: number;
    errorMsg: string;
    hintMsg: string;
  }[];
  resendIffSubDt: string;
  dbs: {
    dbsCampaignCd: string;
    dbsCustomerId: string;
    dbsRmReferralCd: string;
    dbsSourceCd: string;
  };
  voidIffVo?: VoidInfo;
  paperIff: boolean;
  paperIffHandlerName: string;
  empfId: string;
  memberAcctCd: string;
}

export const RejectReasonsMap = {
  1: '01 - Missing / Incorrect HKID or passport number',
  2: '02 - Missing / Incorrect name of scheme member',
  3: '03 - Incorrect membership number under Original Scheme',
  4: '04 - Incorrect Original Scheme Registration Number',
  5: '05 - Missing / Mismatch member signature',
  6: '06 - No such member record in Original Scheme',
  7: '07 - Member account terminated',
  9: '09 - Preserved to a personal account',
  10: '10 - Transfer request has been cancelled by the member',
  11: '11 - Member has multiple accounts in Original Scheme',
  12: '12 - Missing / Invalid option in arrangement of voluntary contributions',
  13: '13 - Missing Notice of Termination',
  14: '14 - No selection of which Sub-account(s) to be transferred out',
  15: '15 - Member has ceased employment',
  16: '16 - Unmatched data and image file',
  17: '17 - Member still under employment   ',
  18: '18 - Missing page of transfer form',
  19: '19 - No Member Signature Specimen   ',
  99: '99 - Other-please specify',
  20: '20 - EEMC_In excess of number election allowed',
  21: '21 - EEMC_Account contained nil balance',
  22: '22 - EEVC_VC not allow to transfer out because not fulfill the transfer conditions in the governing rules',
  23: '23 - EEVC_Account contained nil balance',
  24: '24 - FRMC_Account contained nil balance',
  25: '25 - FR VC_ VC not allowed to transfer out because not fulfill the transfer conditions in governing rules',
  26: '26 - FRVC_Account contained nil balance',
  27: '27 - All selected sub accounts contained nil balance',
  28: "28 - Member's account is a contribution account / self-employed account but P(C) form is received",
  29: "29 - Member's account is a personal account / self-employed account but P(P) form is received Original",
};
