import { CdsLanguageModule } from '@cds/ng-core/lang';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CdsButtonModule } from '@cds/ng-web-components/button';

import { ErrorRoutingModule } from './error-routing.module';
import { TimeoutComponent } from './timeout/timeout.component';
import { PermissionErrorComponent } from './permission-error/permission-error.component';

@NgModule({
  declarations: [TimeoutComponent, PermissionErrorComponent],
  imports: [CommonModule, CdsButtonModule, ErrorRoutingModule, CdsLanguageModule],
})
export class ErrorModule {}
