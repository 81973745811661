export enum ResponseResult {
  SUCCESS = 0,
  DECLINED = 1,
  ERROR = 2,
  NOT_FOUND = 3,
  INVALID_PARAMETER = 4,
  TIMEOUT = 5,
  DATABASE_ERROR = 6,
  UNKNOWN = 7,
  ENCRYPTION_ERROR = 8,
  DECRYPTION_ERROR = 9,
  PROCESSING = 11,
  // FIXME to be added
}
