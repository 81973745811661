import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AdminLayoutComponent } from '../layout/admin-layout/admin-layout.component';
import { PermissionAccess, PermissionItem } from 'src/app/core/models/enum/permission.enum';
import { ReportCenterComponent } from './report-center/report-center.component';
import { AgentProfileListComponent } from './agent-profile-list/agent-profile-list.component';
import { ReportExtractionComponent } from './report-extraction/report-extraction.component';

const routes: Routes = [
  {
    path: 'report-center',
    component: AdminLayoutComponent,
    children: [
      {
        path: '',
        component: ReportCenterComponent,
        data: {
          roles: {
            [PermissionItem.CUSTOMER_MEMBER]: PermissionAccess.R,
          },
        },
      },
      {
        path: 'agent-profile-list',
        component: AgentProfileListComponent,
        data: {
          roles: {
            [PermissionItem.CUSTOMER_MEMBER]: PermissionAccess.R,
          },
        },
      },
      {
        path: 'report-extraction',
        component: ReportExtractionComponent,
        data: {
          title: 'commission.report.title',
          roles: {
            [PermissionItem.CUSTOMER_EMPLOYER]: PermissionAccess.R,
          },
        },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ReportCenterRoutingModule {}
