import { CdsButtonModule } from '@cds/ng-web-components/button';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BackTopComponent } from './back-top.component';
import { CdsLanguageModule } from '@cds/ng-core/lang';

@NgModule({
  declarations: [BackTopComponent],
  imports: [CommonModule, CdsLanguageModule, CdsButtonModule],
  exports: [BackTopComponent],
})
export class BackTopModule {}
