import { Component, OnInit } from '@angular/core';
import { Summary } from '../summary';
import { CdsButtonConfig } from '@cds/ng-core/button';
import { Router } from '@angular/router';
import { MatDialogRef, CdsPopupService } from '@cds/ng-web-components/popup';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { CdsToastService } from '@cds/ng-web-components/toast';
import { SummaryService } from '../summary.service';
import { NotificationDataService } from '../notificationlist/notification-data.service';
import { finalize } from 'rxjs';
import { ToastAlertService } from 'src/app/shared/toast-alert.service';
import { ContinuePopupComponent } from 'src/app/shared/continue-popup/continue-popup.component';
import { CdsLangService } from '@cds/ng-core/lang';
import { DateTime } from 'luxon';

@Component({
  selector: 'app-summary-confirm',
  templateUrl: './summary-confirm.component.html',
  styleUrls: ['./summary-confirm.component.scss'],
})
export class SummaryConfirmComponent implements OnInit {
  summaryDTO: Summary = {};

  categoryMap = {
    HCM_002: 'OFFER_AND_PROMOTIONS',
    HCM_004: 'MARKET_INSIGHTS',
  };
  ca = new Map();
  backConfig: CdsButtonConfig = {
    size: 'sm',
    style: 'secondary',
  };
  confirmConfig: CdsButtonConfig = {
    size: 'sm',
  };

  isLoading = false;
  constructor(
    private router: Router,
    private cdsPopup: CdsPopupService,
    private service: SummaryService,
    private toast: CdsToastService,
    public notificationService: NotificationDataService,
    private toastAlert: ToastAlertService,
    private langService: CdsLangService
  ) {}

  ngOnInit(): void {
    // fix scroll bar not scroll top
    document.documentElement.scrollTop = 0;
    if (!localStorage['summaryDTO']) {
      this.router.navigate(['/mobile-subscription/list']);
    }
    this.summaryDTO = JSON.parse(localStorage['summaryDTO'] || '{}');
    this.ca = new Map(Object.entries(this.categoryMap));
  }
  back = () => {
    this.router.navigate(['/mobile-subscription/create']);
  };

  timeValid() {
    const target = DateTime.fromFormat(this.summaryDTO.targetBroadcastDate + ' ' + this.summaryDTO.targetBroadcastTime, 'd/M/yyyy hh:mm');
    const now = DateTime.now();
    return now.plus({ minutes: 59 }) < target;
  }

  confirm() {
    if (this.timeValid()) {
      this.create();
    } else {
      this.cdsPopup
        .open(ContinuePopupComponent, {
          size: 'md',
          data: {
            message: this.langService.translate('notification.popup.timeInvalid'),
            cancelButtonName: this.langService.translate('confirm.backBotton'),
            closeFn: this.back,
          },
        })
        .afterClosed()
        .subscribe(result => {
          if (result?.agree) {
            this.create();
          }
        });
    }
  }

  create() {
    this.isLoading = true;
    this.service
      .create(this.summaryDTO)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe({
        next: res => {
          if (res.result == 0) {
            this.isLoading = false;
            localStorage.removeItem('summaryDTO');
            const popupRef: MatDialogRef<ConfirmDialogComponent> = this.cdsPopup.open(ConfirmDialogComponent, {
              size: 'md',
              data: {},
            });
            popupRef.afterClosed().subscribe(() => {
              this.router.navigate(['/mobile-subscription/list']);
            });
          } else {
            this.toastError(res.message);
          }
        },
        error: err => {
          this.toastError(err);
        },
      });
  }

  toastError(errorMessage: string) {
    this.toastAlert.show('error', 'common.error', errorMessage, 5000);
  }
}
