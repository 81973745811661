<cds-popup [class.hidden]="!show" class="relative">
  <cds-popup-title>{{ 'notification.uploadDocument' | lang }}</cds-popup-title>
  <cds-popup-title *ngIf="!inEdit">Summary</cds-popup-title>

  <cds-popup-content>
    <div class="cds-h6-demibold l-mb-4">
      {{ 'notification.fileRequirements' | lang }}
    </div>
    <div class="cds-detail1">
      {{ 'notification.fileSizeRequirements' | lang }}
    </div>
    <div class="cds-detail1 l-mb-6">
      {{ 'notification.fileSuffixRequirement' | lang }}
    </div>

    <div class="upload-area l-mb-4" [class.file-error]="showFileErrorMessage">
      <cds-file-upload
        #upload
        [disabled]="true"
        [formControl]="uploadFormControl"
        (change)="fileChange($event)"
        errorMessage="Invalid file uploaded."
        acceptedFiles="text/csv">
      </cds-file-upload>
      <div class="error-message-area l-mt-2" *ngIf="showFileErrorMessage">
        <cds-icon class="l-mr-2" icon="action:warning" [config]="errorMessageIconConfig"></cds-icon>
        <div class="cds-body1-demibold">Invalid file selected.</div>
      </div>
    </div>

    <div class="l-mb-7">
      <div *ngFor="let item of checkResultInfoList" class="error-message">
        <div class="icon">
          <cds-icon class="icon" *ngIf="item.type === AgentSepCheckResultTypeE.WARN" icon="action:caution" class="l-mr-2" [config]="warnIconConfig"></cds-icon>
          <cds-icon class="icon" *ngIf="item.type === AgentSepCheckResultTypeE.ERR" icon="action:caution" class="l-mr-2" [config]="errorIconConfig"></cds-icon>
        </div>
        <span [style.color]="item.type === AgentSepCheckResultTypeE.WARN ? '#D77D28' : '#C14A36'">{{ item.message }}</span>
      </div>
    </div>

    <app-loading *ngIf="isLoading" [overlayInPopup]="true"></app-loading>
  </cds-popup-content>

  <cds-popup-actions>
    <ng-container>
      <cds-button [config]="cancelButtonConfig" (click)="cancel()">{{ 'common.cancel' | lang }}</cds-button>

      <cds-button [config]="confirmButtonConfig" (click)="confirm()" [disabled]="noFileSelect || showFileErrorMessage || !checkOk">{{
        'common.confirm' | lang
      }}</cds-button>
    </ng-container>
  </cds-popup-actions>
</cds-popup>
