import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { CdsLanguageModule } from '@cds/ng-core/lang';
import { CdsSortModule } from '@cds/ng-data-table/sort';
import { CdsTableModule } from '@cds/ng-data-table/table';
import { CdsAccordionModule } from '@cds/ng-web-components/accordion';
import { CdsAssistiveTextModule } from '@cds/ng-web-components/assistive-text';
import { CdsButtonModule } from '@cds/ng-web-components/button';
import { CdsCheckboxModule } from '@cds/ng-web-components/checkbox';
import { CdsDropdownModule } from '@cds/ng-web-components/dropdown';
import { CdsIconModule } from '@cds/ng-web-components/icon';
import { CdsInlineAlertModule } from '@cds/ng-web-components/inline-alert';
import { CdsLinkModule } from '@cds/ng-web-components/link';
import { CdsPaginationModule } from '@cds/ng-web-components/pagination';
import { CdsPopupModule } from '@cds/ng-web-components/popup';
import { CdsProgressModule } from '@cds/ng-web-components/progress';
import { CdsSearchModule } from '@cds/ng-web-components/search';
import { CdsTextfieldModule } from '@cds/ng-web-components/textfield';
import { CdsTooltipModule } from '@cds/ng-web-components/tooltip';
import { CdsTextareaModule } from '@cds/ng-web-components/textarea';
import { CdsDatepickerModule } from '@cds/ng-web-components/datepicker';

import { AgeRangePipe } from './age-range.pipe';
import { GenderPipe } from './gender.pipe';

import { SharedModule } from '../../shared/shared.module';
import { AgentRoutingModule } from './agent-routing.module';

import { EmployeeSummaryComponent } from './employee-summary/employee-summary.component';
import { EmployerSummaryComponent } from './employer-summary/employer-summary.component';

import { UserConfirmPopComponent } from './user-confirm-pop/user-confirm-pop.component';
import { UserExitEditmodePopComponent } from './user-exit-editmode-pop/user-exit-editmode-pop.component';
import { UserSuccessSubmittedComponent } from './user-success-submitted/user-success-submitted.component';
import { CdsFormFieldModule } from '@cds/ng-web-components/form-field';
import { ErDelegationChangeComponent } from './er-delegation-change/er-delegation-change.component';
import { EmployeeDelegationChangeComponent } from './employee-delegation-change/employee-delegation-change.component';
// import { ComponentsModule } from 'src/app/components/components.module';
import { AddToItemComponent } from './er-delegation-change/add-to-item/add-to-item.component';
import { EmployerHistoryComponent } from './employer-history/employer-history.component';
import { SearchInEmployeeDelegationComponent } from './search-in-employee-delegation/search-in-employee-delegation.component';
import { AgentBranchPopComponent } from './agent-branch-pop/agent-branch-pop.component';
import { ErChangeSummaryDetailPopComponent } from './er-change-summary-detail-pop/er-change-summary-detail-pop.component';

import { ReasonOfChangePipe } from './reason-of-change.pipe';
import { MatDialogModule } from '@angular/material/dialog';
import { EmployeeDelegationChangePopComponent } from './employee-delegation-change/employee-delegation-change-pop/employee-delegation-change-pop.component';

@NgModule({
  declarations: [
    EmployerSummaryComponent,
    EmployeeSummaryComponent,
    UserExitEditmodePopComponent,
    UserConfirmPopComponent,
    UserSuccessSubmittedComponent,
    GenderPipe,
    AgeRangePipe,
    ErDelegationChangeComponent,
    EmployeeDelegationChangeComponent,
    AddToItemComponent,
    EmployerHistoryComponent,
    SearchInEmployeeDelegationComponent,
    AgentBranchPopComponent,
    ErChangeSummaryDetailPopComponent,
    ReasonOfChangePipe,
    EmployeeDelegationChangePopComponent,
  ],
  imports: [
    AgentRoutingModule,
    SharedModule,

    HttpClientModule,
    CdsIconModule,
    CdsDropdownModule,
    FormsModule,
    CdsButtonModule,
    CdsTextfieldModule,
    CdsTableModule,
    CdsSortModule,
    CdsPaginationModule,
    CdsSearchModule,
    ReactiveFormsModule,
    CdsAssistiveTextModule,
    CdsCheckboxModule,
    CdsAccordionModule,
    CdsLinkModule,
    CommonModule,
    CdsLanguageModule,
    CdsProgressModule,
    CdsPopupModule,
    OverlayModule,
    CdsInlineAlertModule,
    CdsTooltipModule,
    CdsFormFieldModule,
    // ComponentsModule,
    CdsTextareaModule,
    CdsDatepickerModule,
    MatDialogModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AgentModule {
  constructor() {}
}
