<div id="ttt" *ngIf="title" class="admin-header div-border-top div-border-bottom">
  <div class="row">
    <div *ngIf="isShowBackButton" class="back-btn-area button-area col-12">
      <div class="icon-size">
        <cds-icon size="sm" color="#ff6666" [icon]="backIconType" (click)="doBackMethod()"></cds-icon>
      </div>
      <button class="button-border-none-font" (click)="doBackMethod()">
        {{ backButtonText | lang }}
      </button>
    </div>
  </div>
  <div class="row">
    <div class="title-area header-row col-12 l-d-f l-jc-sb l-plr-0">
      <div class="header-title col-12">
        <span class="cds-h4-demibold">{{ title | lang }}</span>
      </div>

      <app-permission [role]="permissionAccess" [permission]="permissionItem">
        <div *ngIf="isShowButton" class="title-btn button-area" (click)="doMethod()">
          <div class="icon-size">
            <cds-icon size="sm" [icon]="iconType"></cds-icon>
          </div>
          <button class="button-border-none-font btn-text">
            {{ buttonText | lang }}
          </button>
        </div>
      </app-permission>
    </div>
  </div>
  <div class="row" *ngIf="isShowDetail">
    <div class="cds-body2 detail-des col-12">
      {{ detailDes | lang }}
    </div>
  </div>
</div>
