import { Component, Input, OnInit } from '@angular/core';
import { CdsButtonConfig } from '@cds/ng-core/button';
import { CdsColorTheme } from '@cds/ng-core/configuration';
import { CdsTextfieldConfig } from '@cds/ng-core/textfield';
import { DateTime } from 'luxon';
import { EeProfileListModel } from './ee-profile-list-model';
import { EeProfileListService } from './ee-profile-list.service';
import { Router } from '@angular/router';
import { IPaginationCount } from 'src/app/shared/pagination/pagination.dto';

@Component({
  selector: 'app-ee-profile-list',
  templateUrl: './ee-profile-list.component.html',
  styleUrls: ['./ee-profile-list.component.scss'],
})
export class EeProfileListComponent implements OnInit {
  @Input() fullWidth = true;
  displayedColumns: string[] = ['legalId', 'legalIdType', 'engSurname', 'engGivenName'];

  employeeList: EeProfileListModel[] = [];
  iconType = 'action:edit';
  showProgress = true;
  totalCount = 0;
  isLoading = false;
  firstNm = '';
  lastNm = '';
  trusteeMbrId = '';
  config: CdsButtonConfig = {
    label: 'Create Message',
    size: 'md',
    style: 'secondary',
  };
  searchBtnConfig: CdsButtonConfig = {
    label: 'Search',
    size: 'md',
    style: 'tertiary',
    buttonType: 'button',
  };

  searchBtnConfig2: CdsButtonConfig = {
    label: 'Reset',
    size: 'md',
    style: 'tertiary',
  };
  pageindex = 1;
  color: CdsColorTheme = 'ml';
  pageCount: IPaginationCount = { current: 1, count: 1 };
  pageSize = 10;
  txtfieldConfig: CdsTextfieldConfig = {
    type: 'number',
  };
  searchfieldConfig: CdsTextfieldConfig = {
    label: 'legalId',
    type: 'text',
  };
  searchfieldConfig2: CdsTextfieldConfig = {
    label: 'engSurname',
    type: 'text',
  };
  searchfieldConfig3: CdsTextfieldConfig = {
    label: 'sex',
    type: 'text',
  };
  pageindexChange = 1;
  constructor(public eeProfileListService: EeProfileListService, public router: Router) {}

  ngOnInit(): void {
    this.totalCount = this.employeeList.length;
    this.getList(1, this.pageSize);
  }

  pageChange() {
    this.getList(this.pageCount.current, this.pageSize);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unused-vars
  onSortChange(event: any) {}

  formateDate(date: string) {
    return DateTime.fromISO(date).toFormat('yyyy-MM-dd');
  }
  doSearch() {
    this.getList(this.pageCount.current, this.pageSize);
  }
  reset() {
    this.firstNm = '';
    this.lastNm = '';
    this.trusteeMbrId = '';
    this.getList(1, this.pageSize);
  }
  getList(pageIndex: number, pageSize: number) {
    // this.showProgress = true;
    this.isLoading = true;
    const list = this.eeProfileListService.getList(pageIndex, pageSize, this.firstNm, this.lastNm, this.trusteeMbrId);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    list.subscribe((data: any) => {
      this.isLoading = false;
      // this.showProgress = false;
      if (data.result == 0 && data.message == 'SUCCESS') {
        this.employeeList = data.data.content;
        this.totalCount = data.data.totalElements;
        this.pageCount.current = data.data.pageable.pageNumber + 1;
        this.pageCount.count = data.data.totalPages;
      }
    });
  }
  goToDetail(item: EeProfileListModel) {
    this.router.navigate(['/employee/profile', { legalId: item.legalId, legalIdType: item.legalIdType, id: item.id }]);
  }
}
