import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CdsDropdownConfig } from '@cds/ng-core/dropdown';
import { CdsIconConfig } from '@cds/ng-core/icon';
import { ActionIcons } from '@cds/ng-themes';
import { CdsAlertService } from '@cds/ng-web-components/alert';
import { CdsPopupService, MatDialogRef } from '@cds/ng-web-components/popup';
import { CdHttpServeService } from 'src/app/shared/cd-http-serve/cd-http-serve.service';
import { cdsPopupBackdropOntouchmovePreventDefault, cdsPopupBackdropUnOntouchmovePreventDefault } from 'src/app/utils/utils';
import { AdviseEmpfrefComponent } from '../case-summary/advise-empfref/advise-empfref.component';
import { AdviseEmpfstatusComponent } from '../case-summary/advise-empfstatus/advise-empfstatus.component';
import { AdviseIffstatusComponent } from '../case-summary/advise-iffstatus/advise-iffstatus.component';
import { EE_DECLINED_REASON_OPTIONS, EMPFSTATUS_TYPE_OPTIONS, ER_DECLINED_REASON_OPTIONS, IFFSTATUS_TYPE_OPTIONS } from '../case-summary/case-summary.config';

import { CasesummaryService } from '../case-summary/casesummary.service';
import { CdMultidropdownTopping } from 'src/app/shared/cd-multidropdown/cd-multidropdown.model';
import { SalesJourneyProdType } from '../case-summary/case-summary.model';
import { CdsOption } from '@cds/ng-core/configuration';
import { EEProdType } from '../iff-paper-form/individuals/individuals.model';

enum IndividualType {
  ALL = 'all',
  PA = 'PA',
  TVC = 'TVC',
  FRC = 'FRC',
}

enum IFFStatusTag {
  ALL = 'all',
  Completed = 'Completed',
  Declined = 'Declined',
  NONE = 'NONE',
}

interface RecordItem {
  empfRefNo: string;
  districtCd: string;
  agentCd: string;
  productType: string;
  iffExpiryDate: string;
  iffStatus: string;
  iffStatusEnum: number;
  IFFStatus: string;
  empfStatus: string;
  empfStatusEnum: number;
  eMPFAppStatus: string;
  splitCase: boolean;
  declineReasons: string;
  salesJourneyStatusEnum: number;
  salesJourneySummaryId: string;
}

interface EERecordItem extends RecordItem {
  clientName: string;
  hkidOrPassportNo: string;
}

interface ERRecordItem extends RecordItem {
  companyName: string;
  businessRefNo: string;
}

interface ReqeuestParams {
  page: number;
  size: number;
  empfRefNo: string;
  agentCd: string;
  districtCd: string;
  iffExpiryDateFrom: string;
  iffExpiryDateTo: string;
  eiffStatus: number[];
  empfStatus: number[];
  ABMs: string[];
  declineReasons: number[];
  productTypes?: string[];
  identityId: string;
  domain: SalesJourneyProdType;
}

interface EEReqeuestParams extends ReqeuestParams {
  clientName: string;
  hkidOrPassportNo: string;
}

interface ERReqeuestParams extends ReqeuestParams {
  companyName: string;
  brNo: string;
}

interface BaseParams {
  prodTypeFormControl: FormControl;
  prodTypeDropdownConfig: CdsDropdownConfig;
  resetDatePicker: boolean;
  iFFExpiryDateFromValueError: boolean;
  iFFExpiryDateToValueError: boolean;
  individualTypeSelected: IndividualType;
  iffStatusTagSelected: IFFStatusTag;
  isLoading: boolean;
  currentPageNumOrigin: number;
  totalElements: number;
  totalPages: number;
  allPageData: EERecordItem[][] | ERRecordItem[][];
  iffStatusoptionsOrigin: CdMultidropdownTopping[];
  appStatusoptionsOrigin: CdMultidropdownTopping[];
  abmStatusoptionsOrigin: CdMultidropdownTopping[];
  declinedReasonOptionsOrigin: CdMultidropdownTopping[];
  iFFExpiryDateFromValMax: Date;
  iFFExpiryDateFromValMin: Date;
  iFFExpiryDateToValMax: Date;
  iFFExpiryDateToValMin: Date;
  requestParams: EEReqeuestParams | ERReqeuestParams;
  requestParamsForPageChange?: EEReqeuestParams | ERReqeuestParams;
}

@Component({
  selector: 'app-paper-summary',
  templateUrl: './paper-summary.component.html',
  styleUrls: ['./paper-summary.component.scss'],
})
export class PaperSummaryComponent implements OnInit {
  @ViewChild('tableBox', { static: false })
  tableBox?: ElementRef<HTMLDivElement>;
  SalesJourneyProdType = SalesJourneyProdType;
  IndividualType = IndividualType;
  IFFStatusTag = IFFStatusTag;
  backIconConfig: CdsIconConfig = {
    color: 'cta',
    size: 'sm',
  };
  backIcon = ActionIcons.button_left_filled;
  infoIcon = ActionIcons.info_1;
  iconConfig: CdsIconConfig = {
    size: 'sm',
    color: 'default',
  };
  iffStatusoptionList = IFFSTATUS_TYPE_OPTIONS;
  appStatusoptionList = EMPFSTATUS_TYPE_OPTIONS;
  abmOptionList: CdsOption[] = [];
  declinedReasonOptionList = EE_DECLINED_REASON_OPTIONS;
  declinedReasonOptionListER = ER_DECLINED_REASON_OPTIONS;
  private earliestDate = new Date('1970/01/01');
  eeDisplayedColumns: string[] = [
    'empfRefNo',
    'districtCd',
    'agentCd',
    'productType',
    'clientName',
    'hkidOrPassportNo',
    'iffExpiryDate',
    'iffStatusEnum',
    'empfStatusEnum',
    'splitCase',
    'declineReasons',
  ];
  erDisplayedColumns: string[] = [
    'empfRefNo',
    'districtCd',
    'agentCd',
    'companyName',
    'businessRefNo',
    'iffExpiryDate',
    'iffStatusEnum',
    'empfStatusEnum',
    'splitCase',
    'declineReasons',
  ];
  baseParamsList: BaseParams[] = [];
  baseParams!: BaseParams;

  constructor(
    private cdsPopup: CdsPopupService,
    public caseSummaryService: CasesummaryService,
    private router: Router,
    private route: ActivatedRoute,
    private cdHttpServeService: CdHttpServeService,
    private alert: CdsAlertService
  ) {}

  // fakeTabIndexChange(index: number) {
  //   this.baseParams = this.baseParamsList[index];

  //   if (this.currentPageData.length === 0) {
  //     const params = this.getFilterParams();
  //     this.search(params);
  //   }
  // }

  private initRequestParams() {
    this.baseParams.requestParams = {
      page: 1,
      size: 50,
      domain: this.baseParams.prodTypeFormControl.value,
      empfRefNo: '',
      clientName: '',
      agentCd: '',
      districtCd: '',
      iffExpiryDateFrom: '',
      iffExpiryDateTo: '',
      hkidOrPassportNo: '',
      eiffStatus: [],
      empfStatus: [],
      ABMs: [],
      declineReasons: [],
      productTypes: [],
      identityId: '',
      companyName: '',
      brNo: '',
    };
  }

  @HostListener('document:keydown.enter', ['$event'])
  onDocumentEnter(): void {
    this.onClickSearch();
  }

  prodTypeChange(value: SalesJourneyProdType) {
    if (this.baseParams.requestParams.domain === value) {
      return;
    }

    this.reset();

    this.baseParams.requestParams.domain = value;
    if (this.baseParams.requestParams.domain === SalesJourneyProdType.Individual) {
      delete this.baseParams.requestParams.productTypes;
    } else if (this.baseParams.requestParams.domain === SalesJourneyProdType.SEP) {
      this.baseParams.requestParams.productTypes = ['SEP'];
    } else if (this.baseParams.requestParams.domain === SalesJourneyProdType.Corperate) {
      this.baseParams.requestParams.productTypes = ['ER'];
    }

    const params = this.getFilterParams();
    this.search(params);
  }

  ngOnInit(): void {
    this.baseParamsList = [
      {
        prodTypeFormControl: new FormControl(SalesJourneyProdType.Individual),
        prodTypeDropdownConfig: {
          options: [
            {
              label: 'Individuals (PA, TVC, SVC)',
              value: SalesJourneyProdType.Individual,
            },
            { label: 'Corporates (ER)', value: SalesJourneyProdType.Corperate },
            { label: 'Self- Employed Person (SEP)', value: SalesJourneyProdType.SEP },
          ],
          color: 'ml',
        },
        resetDatePicker: false,
        iFFExpiryDateFromValueError: false,
        iFFExpiryDateToValueError: false,
        individualTypeSelected: IndividualType.ALL,
        iffStatusTagSelected: IFFStatusTag.ALL,
        isLoading: false,
        currentPageNumOrigin: 1,
        totalElements: 0,
        totalPages: 0,
        allPageData: [],
        iffStatusoptionsOrigin: [],
        appStatusoptionsOrigin: [],
        abmStatusoptionsOrigin: [],
        declinedReasonOptionsOrigin: [],
        iFFExpiryDateFromValMax: new Date('9000/01/01'),
        iFFExpiryDateFromValMin: this.earliestDate,
        iFFExpiryDateToValMax: new Date('9000/01/01'),
        iFFExpiryDateToValMin: this.earliestDate,
        requestParams: {
          page: 1,
          size: 50,
          domain: SalesJourneyProdType.Individual,
          empfRefNo: '',
          clientName: '',
          agentCd: '',
          districtCd: '',
          iffExpiryDateFrom: '',
          iffExpiryDateTo: '',
          hkidOrPassportNo: '',
          eiffStatus: [],
          empfStatus: [],
          ABMs: [],
          declineReasons: [],
          productTypes: [],
          identityId: '',
          companyName: '',
          brNo: '',
        },
      },
    ];

    this.baseParams = this.baseParamsList[0];
    delete this.baseParams.requestParams.productTypes;
    const params = this.getFilterParams();
    if (this.route.snapshot.paramMap.get('productType') === EEProdType.SEP) {
      this.baseParams.prodTypeFormControl.setValue(SalesJourneyProdType.SEP);
      this.baseParams.requestParams.productTypes = ['SEP'];
      this.prodTypeChange(this.baseParams.prodTypeFormControl.value);
    } else if (this.route.snapshot.paramMap.get('productType') === 'ER') {
      this.baseParams.prodTypeFormControl.setValue(SalesJourneyProdType.Corperate);
      this.baseParams.requestParams.productTypes = ['ER'];
      this.prodTypeChange(this.baseParams.prodTypeFormControl.value);
    } else {
      this.baseParams.prodTypeFormControl.setValue(SalesJourneyProdType.Individual);
      delete this.baseParams.requestParams.productTypes;
      this.search(params);
    }
    // this.search(params);
    this.getABMList();
  }

  getABMList() {
    this.cdHttpServeService.get<{ abm: string }[]>('/ext/eb-ssms-sales-journey-service/agent-branch/abm/list', {}).subscribe({
      next: res => {
        if (res.result !== 0) {
          this.alert.warning('Warning!', `${res.message}`);
        } else {
          res.data.forEach(e => {
            this.abmOptionList.push({ label: e.abm, value: e.abm });
          });
        }
      },
      error: err => {
        this.alert.error('Error!', err);
      },
    });
  }

  goCaseDetails(item: RecordItem) {
    const queryParams = { from: 'branchLeaveSummary' };
    this.router.navigate([
      '/salesjourney',
      this.baseParams.prodTypeFormControl.value === SalesJourneyProdType.Corperate ? 'case-details-er' : 'case-details',
      item.salesJourneySummaryId,
      item.empfRefNo,
      queryParams,
    ]);
  }

  get currentPageIndex() {
    return this.baseParams.currentPageNumOrigin - 1;
  }

  get currentPageData() {
    if (this.baseParams.allPageData.length === 0) {
      return [];
    }
    return this.baseParams.allPageData[this.currentPageIndex];
  }

  get EECurrentPageData() {
    if (this.baseParams.allPageData.length === 0) {
      return [] as EERecordItem[];
    }
    return this.baseParams.allPageData[this.currentPageIndex] as EERecordItem[];
  }

  get ERCurrentPageData() {
    if (this.baseParams.allPageData.length === 0) {
      return [] as ERRecordItem[];
    }
    return this.baseParams.allPageData[this.currentPageIndex] as ERRecordItem[];
  }

  checkIndividualTypeTag(value: IndividualType) {
    this.baseParams.individualTypeSelected = value;
    if (value === IndividualType.ALL) {
      delete this.baseParams.requestParams.productTypes;
    } else {
      this.baseParams.requestParams.productTypes = [value];
    }
    this.baseParams.requestParams.page = 1;
    const params = this.getFilterParams();
    this.search(params);
  }

  checkIFFStatusTag(value: IFFStatusTag) {
    this.baseParams.iffStatusTagSelected = value;

    if (value === IFFStatusTag.ALL) {
      this.baseParams.iffStatusoptionsOrigin = [];
    } else if (value === IFFStatusTag.Completed) {
      this.baseParams.iffStatusoptionsOrigin = [
        {
          label: 'Completed',
          value: 30,
        },
      ];
    } else if (value === IFFStatusTag.Declined) {
      this.baseParams.iffStatusoptionsOrigin = [
        {
          label: 'Declined',
          value: 99,
        },
      ];
    }

    this.baseParams.requestParams;
    this.baseParams.requestParams.page = 1;
    const params = this.getFilterParams();
    this.search(params);
  }

  get checkIsHaveScroll() {
    if (this.tableBox) {
      return this.tableBox.nativeElement.scrollWidth > this.tableBox.nativeElement.clientWidth;
    }
    return false;
  }

  onDisplayAdviseRef() {
    this.cdsPopup.open(AdviseEmpfrefComponent, {
      size: 'lg',
    });
  }

  onDisplayAdviseIFF() {
    const popupRef: MatDialogRef<AdviseIffstatusComponent> = this.cdsPopup.open(AdviseIffstatusComponent, {
      size: 'sm',
    });
    popupRef.afterClosed().subscribe(() => {
      cdsPopupBackdropUnOntouchmovePreventDefault();
    });
    popupRef.afterOpened().subscribe(() => {
      cdsPopupBackdropOntouchmovePreventDefault();
    });
  }

  onDisplayAdviseEmpf() {
    const popupRef: MatDialogRef<AdviseEmpfstatusComponent> = this.cdsPopup.open(AdviseEmpfstatusComponent, {
      size: 'sm',
      data: {
        message: 'The changes you’ve made will be lost, are you sure to continue?',
        cancel: 'Cancel',
        continue: 'Continue',
      },
    });
    popupRef.afterClosed().subscribe(() => {
      cdsPopupBackdropUnOntouchmovePreventDefault();
    });
    popupRef.afterOpened().subscribe(() => {
      cdsPopupBackdropOntouchmovePreventDefault();
    });
  }

  private onResetDatePicker() {
    this.baseParams.iFFExpiryDateFromValMax = new Date('9000/01/01');
    this.baseParams.iFFExpiryDateFromValMin = this.earliestDate;
    this.baseParams.iFFExpiryDateToValMax = new Date('9000/01/01');
    this.baseParams.iFFExpiryDateToValMin = this.earliestDate;

    this.baseParams.resetDatePicker = true;
    setTimeout(() => {
      this.baseParams.resetDatePicker = false;
    }, 0);
  }

  pageChange(num: number): void {
    if (this.baseParams.requestParamsForPageChange) {
      this.baseParams.requestParamsForPageChange.page = num;
      this.search(this.baseParams.requestParamsForPageChange);
    }
  }

  iFFExpiryDateFromValueChange(val: string) {
    if (val === '') {
      this.baseParams.iFFExpiryDateFromValueError = false;
      return;
    }
    this.baseParams.requestParams.iffExpiryDateFrom = val;
    const temp = this.baseParams.requestParams.iffExpiryDateFrom.split('/');
    const selectedDate = new Date([temp[1], temp[0], temp[2]].join('/'));
    if (selectedDate.toString() === 'Invalid Date') {
      this.baseParams.iFFExpiryDateFromValueError = true;
      return;
    }
    if (this.baseParams.requestParams.iffExpiryDateTo) {
      const tempTo = this.baseParams.requestParams.iffExpiryDateTo.split('/');
      const tempToDate = new Date([tempTo[1], tempTo[0], tempTo[2]].join('/'));
      if (selectedDate > tempToDate) {
        this.baseParams.iFFExpiryDateFromValueError = true;
        return;
      }
    }
    this.baseParams.iFFExpiryDateFromValueError = false;
    this.baseParams.iFFExpiryDateToValMin = new Date(selectedDate.getTime());
  }

  iFFExpiryDateToValueChange(val: string) {
    if (val === '') {
      this.baseParams.iFFExpiryDateToValueError = false;
      return;
    }
    this.baseParams.requestParams.iffExpiryDateTo = val;
    const temp = this.baseParams.requestParams.iffExpiryDateTo.split('/');
    const selectedDate = new Date([temp[1], temp[0], temp[2]].join('/'));
    if (selectedDate.toString() === 'Invalid Date') {
      this.baseParams.iFFExpiryDateToValueError = true;
      return;
    }
    if (this.baseParams.requestParams.iffExpiryDateFrom) {
      const tempFrom = this.baseParams.requestParams.iffExpiryDateFrom.split('/');
      const tempFromDate = new Date([tempFrom[1], tempFrom[0], tempFrom[2]].join('/'));
      if (selectedDate < tempFromDate) {
        this.baseParams.iFFExpiryDateToValueError = true;
        return;
      }
    }
    this.baseParams.iFFExpiryDateToValueError = false;
    this.baseParams.iFFExpiryDateFromValMax = new Date([temp[1], temp[0], temp[2]].join('/'));
  }

  onClickSearch() {
    if (this.isDisableSearch || this.baseParams.isLoading) {
      return;
    }

    this.baseParams.requestParams.page = 1;
    this.baseParams.requestParams.size = 50;
    const params = this.getFilterParams();

    if (params.eiffStatus) {
      if (params.eiffStatus.length === 0) {
        this.baseParams.iffStatusTagSelected = IFFStatusTag.ALL;
      } else if (params.eiffStatus.length === 1 && params.eiffStatus[0] === 99) {
        this.baseParams.iffStatusTagSelected = IFFStatusTag.Declined;
      } else if (params.eiffStatus.length === 1 && params.eiffStatus[0] === 30) {
        this.baseParams.iffStatusTagSelected = IFFStatusTag.Completed;
      } else {
        this.baseParams.iffStatusTagSelected = IFFStatusTag.NONE;
      }
    }

    this.search(params);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  search(params: any) {
    this.baseParams.requestParamsForPageChange = params;
    this.baseParams.isLoading = true;
    this.cdHttpServeService
      .post<{
        content: EERecordItem[] | ERRecordItem[];
        totalElements: number;
        number: number;
        totalPages: number;
      }>(
        this.baseParams.requestParams.domain === SalesJourneyProdType.Corperate
          ? '/ext/eb-ssms-sales-journey-service/employerSalesJourneySummaries/branchLevel'
          : '/ext/eb-ssms-sales-journey-service/memberSalesJourneySummaries/branchLevel',
        params
      )
      .subscribe({
        next: res => {
          if (res.result !== 0) {
            this.alert.warning('Warning!', `${res.message}`);
            this.EECurrentPageData.length = 0;
            this.ERCurrentPageData.length = 0;
          } else {
            res.data.content = res.data.content || [];
            res.data.content.forEach(element => {
              element.IFFStatus = String(element.iffStatusEnum);
              element.eMPFAppStatus = String(element.empfStatusEnum);
            });

            this.baseParams.currentPageNumOrigin = res.data.number;
            this.baseParams.allPageData[this.currentPageIndex] = res.data.content;
            this.baseParams.totalElements = res.data.totalElements;
            this.baseParams.totalPages = res.data.totalPages;
          }
          this.baseParams.isLoading = false;
        },
        error: err => {
          this.alert.error('Error!', err);
          this.baseParams.isLoading = false;
        },
      });
  }

  private reset() {
    this.onResetDatePicker();
    this.initRequestParams();

    this.baseParams.individualTypeSelected = IndividualType.ALL;
    this.baseParams.iffStatusTagSelected = IFFStatusTag.ALL;
    this.baseParams.appStatusoptionsOrigin = [];
    this.baseParams.iffStatusoptionsOrigin = [];
    this.baseParams.abmStatusoptionsOrigin = [];
    this.baseParams.declinedReasonOptionsOrigin = [];
    this.baseParams.iFFExpiryDateFromValueError = false;
    this.baseParams.iFFExpiryDateToValueError = false;
  }

  private getFilterParams() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const params: any = {};
    for (const key in this.baseParams.requestParams) {
      if (Object.prototype.hasOwnProperty.call(this.baseParams.requestParams, key)) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const val = (this.baseParams.requestParams as any)[key];
        if (val !== undefined && val !== null && val !== '' && !(Object.prototype.toString.call(val) === '[object Array]' && val.length === 0)) {
          if (typeof val === 'string') {
            params[key] = val.trim();
          } else {
            params[key] = val;
          }
        }
      }
    }
    if (this.baseParams.appStatusoptionsOrigin.length > 0) {
      params.empfStatus = this.baseParams.appStatusoptionsOrigin.map(item => item.value);
    }
    if (this.baseParams.iffStatusoptionsOrigin.length > 0) {
      params.eiffStatus = this.baseParams.iffStatusoptionsOrigin.map(item => item.value);
    }
    if (this.baseParams.abmStatusoptionsOrigin.length > 0) {
      params.ABMs = this.baseParams.abmStatusoptionsOrigin.map(item => item.value);
    }
    if (this.baseParams.declinedReasonOptionsOrigin.length > 0) {
      params.declineReasons = this.baseParams.declinedReasonOptionsOrigin.map(item => item.value);
    }
    if (this.isERReqeuestParams(this.baseParams.requestParams)) {
      delete params.clientName;
      delete params.hkidOrPassportNo;
    } else {
      delete params.companyName;
      delete params.businessRefNo;
    }
    return params;
  }

  onClickReset() {
    this.reset();

    if (this.baseParams.requestParams.domain === SalesJourneyProdType.Individual) {
      delete this.baseParams.requestParams.productTypes;
    } else if (this.baseParams.requestParams.domain === SalesJourneyProdType.SEP) {
      this.baseParams.requestParams.productTypes = ['SEP'];
    } else if (this.baseParams.requestParams.domain === SalesJourneyProdType.Corperate) {
      this.baseParams.requestParams.productTypes = ['ER'];
    }

    const params = this.getFilterParams();
    this.search(params);
  }

  get isDisableSearch() {
    if (this.isERReqeuestParams(this.baseParams.requestParams)) {
      return (
        (this.baseParams.requestParams.empfRefNo === '' &&
          this.baseParams.requestParams.companyName === '' &&
          this.baseParams.requestParams.agentCd === '' &&
          this.baseParams.requestParams.districtCd === '' &&
          this.baseParams.requestParams.iffExpiryDateFrom === '' &&
          this.baseParams.requestParams.iffExpiryDateTo === '' &&
          this.baseParams.requestParams.brNo === '' &&
          this.baseParams.appStatusoptionsOrigin.length === 0 &&
          this.baseParams.iffStatusoptionsOrigin.length === 0 &&
          this.baseParams.abmStatusoptionsOrigin.length === 0 &&
          this.baseParams.declinedReasonOptionsOrigin.length === 0 &&
          this.baseParams.requestParams.identityId === '') ||
        this.baseParams.iFFExpiryDateFromValueError ||
        this.baseParams.iFFExpiryDateToValueError
      );
    } else {
      return (
        (this.baseParams.requestParams.empfRefNo === '' &&
          this.baseParams.requestParams.clientName === '' &&
          this.baseParams.requestParams.agentCd === '' &&
          this.baseParams.requestParams.districtCd === '' &&
          this.baseParams.requestParams.iffExpiryDateFrom === '' &&
          this.baseParams.requestParams.iffExpiryDateTo === '' &&
          this.baseParams.requestParams.hkidOrPassportNo === '' &&
          this.baseParams.appStatusoptionsOrigin.length === 0 &&
          this.baseParams.iffStatusoptionsOrigin.length === 0 &&
          this.baseParams.abmStatusoptionsOrigin.length === 0 &&
          this.baseParams.declinedReasonOptionsOrigin.length === 0 &&
          this.baseParams.requestParams.identityId === '') ||
        this.baseParams.iFFExpiryDateFromValueError ||
        this.baseParams.iFFExpiryDateToValueError
      );
    }
  }

  isERReqeuestParams(params: EEReqeuestParams | ERReqeuestParams): params is ERReqeuestParams {
    return params.domain === SalesJourneyProdType.Corperate;
  }
}
