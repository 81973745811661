import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { CdsIconModule } from '@cds/ng-web-components/icon';
import { CdsPopupModule } from '@cds/ng-web-components/popup';
import { CdPopupContinueComponent } from './cd-popup-continue/cd-popup-continue.component';
import { CdPopupGotComponent } from './cd-popup-got/cd-popup-got.component';
import { CdPopupComponent } from './cd-popup/cd-popup.component';
import { CdsLanguageModule } from '@cds/ng-core/lang';
import { CdsButtonModule } from '@cds/ng-web-components/button';
import { CdPopupDeleteComponent } from './cd-popup-delete/cd-popup-delete.component';

@NgModule({
  declarations: [CdPopupComponent, CdPopupGotComponent, CdPopupContinueComponent, CdPopupDeleteComponent],
  imports: [CommonModule, MatDialogModule, CdsIconModule, CdsPopupModule, CdsLanguageModule, CdsButtonModule],
  exports: [CdPopupComponent, CdsPopupModule, CdPopupGotComponent, CdPopupContinueComponent, CdPopupDeleteComponent],
})
export class CdPopupModule {}
