import { Injectable } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { AgentEmployerResponse } from './agent-employer-response.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EmployerRequest } from './employer-summary/employer-request.model';
import { environment } from 'src/environments/environment';

import { catchError, map, tap, timeout } from 'rxjs/operators';
import { CheckAgentCodeRequest, EmployerCheckRequest } from './employer-summary/check-agent-code-request';
import { CheckAgentCodeResponse } from './employer-summary/check-agent-code-response';
import { AgentCodeBatchValidateResponse } from './employer-summary/agent-code-batch-validate-response';
import { SubmitDelegateRequest } from './employer-summary/submit-delegate-request';
import { SubmitDelegateResponse } from './employer-summary/submit-delegate-response';
import { agentCodeAddPrefix0, agentCodeRemovePrefix0 } from 'src/app/utils/utils';

@Injectable({
  providedIn: 'root',
})
export class AgentEmployerDelegationService {
  employerDataInSummarySubject = new Subject<AgentEmployerResponse>();
  employerDataInDelegateSubject = new Subject<AgentEmployerResponse>();
  agentCodeResponseSubject = new Subject<CheckAgentCodeResponse>();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  errorEmployerSubject = new Subject<any>();
  agentCodeBatchValidateSubject = new Subject<AgentCodeBatchValidateResponse>();
  agentCodeBatchApplySubject = new Subject<CheckAgentCodeResponse>();
  agentDelegationSubject = new Subject<SubmitDelegateResponse>();

  constructor(private httpClient: HttpClient) {}

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  public searchEmployerUrl = `${environment.apiPrefix}/ext/eb-ssms/customer-service/delegation/employer/query`;
  public checkAgentCodeUrl = `${environment.apiPrefix}/ext/eb-ssms/customer-service/add-delegation/validate`;
  public agentCodeBatchValidateUrl = `${environment.apiPrefix}/ext/eb-ssms/customer-service/add-delegation/validateBatch`;
  public confirmSubmitDelegationUrl = `${environment.apiPrefix}/ext/eb-ssms/customer-service/add-delegation/employers/submit`;

  getEmployers(employerRequest: EmployerRequest) {
    const errorEmployerResponse = new AgentEmployerResponse(2, 'ERROR');
    this.httpClient
      .post(this.searchEmployerUrl, employerRequest, this.httpOptions)
      .pipe(
        tap(() => console.info('fetched employers')),
        timeout(environment.httpTimeout),
        catchError(this.handleError<AgentEmployerResponse>('getEmployers', errorEmployerResponse))
      )
      .subscribe((resp: AgentEmployerResponse) => {
        if (resp.result === 0) {
          if (resp.data) {
            resp.data.content?.forEach(c => {
              c.delegateAgentInfo?.forEach(element => {
                element.agentCode = agentCodeAddPrefix0(element.agentCode);
              });
            });
          }
        }

        this.employerDataInSummarySubject.next(resp);
      });
  }

  getEmployersInDelegate(employerRequest: EmployerRequest) {
    const errorEmployerResponse = new AgentEmployerResponse(2, 'ERROR');
    this.httpClient
      .post(this.searchEmployerUrl, employerRequest, this.httpOptions)
      .pipe(
        tap(() => console.info('fetched employers')),
        timeout(environment.httpTimeout),
        catchError(this.handleError<AgentEmployerResponse>('getEmployers', errorEmployerResponse))
      )
      .subscribe((resp: AgentEmployerResponse) => {
        if (resp.result === 0) {
          if (resp.data) {
            resp.data.content?.forEach(c => {
              c.delegateAgentInfo?.forEach(element => {
                element.agentCode = agentCodeAddPrefix0(element.agentCode);
              });
            });
          }
        }

        this.employerDataInDelegateSubject.next(resp);
      });
  }

  handleError<T>(operation = 'operation', result?: T) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (error: any): Observable<T> => {
      console.info(`${operation} failed :${error.message}`);
      this.errorEmployerSubject.next(`${error.message}`);
      return of(result as T);
    };
  }

  checkAgentCode(checkAgentCodeRequest: CheckAgentCodeRequest) {
    if (checkAgentCodeRequest.agentCode) {
      checkAgentCodeRequest.agentCode = agentCodeRemovePrefix0(checkAgentCodeRequest.agentCode);
    }
    return this.httpClient
      .post(this.checkAgentCodeUrl, checkAgentCodeRequest, this.httpOptions)
      .pipe(
        tap(() => console.info('check agent code')),
        timeout(environment.httpTimeout),
        catchError(this.handleError<CheckAgentCodeResponse>('check agent code'))
      )
      .subscribe((resp: CheckAgentCodeResponse) => {
        if (resp.result === 0 && resp.data?.agentCode) {
          resp.data.agentCode = agentCodeAddPrefix0(resp.data?.agentCode);
        }

        this.agentCodeResponseSubject.next(resp);
      });
  }

  agentCodeBatchValidate(employerCheckRequestArr: EmployerCheckRequest[]) {
    employerCheckRequestArr.forEach(employer => {
      if (employer.agentCode) {
        employer.agentCode = agentCodeRemovePrefix0(employer.agentCode);
      }
    });

    return this.httpClient
      .post(this.agentCodeBatchValidateUrl, employerCheckRequestArr, this.httpOptions)
      .pipe(
        tap(() => console.info('check agent code list')),
        timeout(environment.httpTimeout),
        catchError(this.handleError<AgentCodeBatchValidateResponse>('check agent code list'))
      )
      .subscribe((resp: AgentCodeBatchValidateResponse) => {
        if (resp.result !== 0) {
          if (resp.data) {
            resp.data.forEach(d => {
              if (d.agent?.agentCode) {
                d.agent.agentCode = agentCodeAddPrefix0(d.agent.agentCode);
              }

              if (d.existAgents) {
                d.existAgents.forEach(a => {
                  if (a.agentCode) {
                    a.agentCode = agentCodeAddPrefix0(a.agentCode);
                  }
                });
              }

              if (d.targetAgent?.agentCode) {
                d.targetAgent.agentCode = agentCodeAddPrefix0(d.targetAgent?.agentCode);
              }
            });
          }
        }

        this.agentCodeBatchValidateSubject.next(resp);
      });
  }

  confirmSubmitDelegation(submitDelegateRequestArr: SubmitDelegateRequest[]) {
    submitDelegateRequestArr.forEach(delegate => {
      if (delegate.agentCode) {
        delegate.agentCode = agentCodeRemovePrefix0(delegate.agentCode);
      }
    });

    return this.httpClient
      .post(this.confirmSubmitDelegationUrl, submitDelegateRequestArr, this.httpOptions)
      .pipe(
        tap(() => console.info('confirm submit delegation')),
        timeout(environment.httpTimeout),
        catchError(this.handleError<SubmitDelegateResponse>('confirm submit delegation'))
      )
      .subscribe((resp: SubmitDelegateResponse) => {
        this.agentDelegationSubject.next(resp);
      });
  }

  agentCodeBatchApply(checkAgentCodeRequest: CheckAgentCodeRequest) {
    if (checkAgentCodeRequest.agentCode) {
      checkAgentCodeRequest.agentCode = agentCodeRemovePrefix0(checkAgentCodeRequest.agentCode);
    }
    return this.httpClient
      .post(this.checkAgentCodeUrl, checkAgentCodeRequest, this.httpOptions)
      .pipe(
        tap(() => console.info('batch apply checking agent code')),
        timeout(environment.httpTimeout),
        catchError(this.handleError<CheckAgentCodeResponse>('batch apply checking agent code'))
      )
      .subscribe((resp: CheckAgentCodeResponse) => {
        if (resp.result === 0 && resp.data?.agentCode) {
          resp.data.agentCode = agentCodeAddPrefix0(resp.data.agentCode);
        }

        this.agentCodeBatchApplySubject.next(resp);
      });
  }

  agentCodeValidate(checkAgentCodeRequest: CheckAgentCodeRequest) {
    if (checkAgentCodeRequest.agentCode) {
      checkAgentCodeRequest.agentCode = agentCodeRemovePrefix0(checkAgentCodeRequest.agentCode);
    }
    return this.httpClient.post(this.checkAgentCodeUrl, checkAgentCodeRequest, this.httpOptions).pipe(
      tap(() => console.info('batch apply checking agent code')),
      timeout(environment.httpTimeout),
      map((resp: CheckAgentCodeResponse) => {
        if (resp.result === 0 && resp.data?.agentCode) {
          resp.data.agentCode = agentCodeAddPrefix0(resp.data.agentCode);
        }
        return resp;
      }),
      catchError(this.handleError<CheckAgentCodeResponse>('batch apply checking agent code'))
    );
  }
}
