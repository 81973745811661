import { CdsDropdownConfig } from '@cds/ng-core/dropdown';
import { deepCopy } from 'src/app/utils/copy';
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CdsButtonConfig } from '@cds/ng-core/button';
import { CdsLangService } from '@cds/ng-core/lang';
import { CdsPopupService, CDS_POPUP_DATA, MatDialogRef } from '@cds/ng-web-components/popup';
import { finalize } from 'rxjs';
import { AlertPopupComponent } from 'src/app/shared/alert-popup/alert-popup.component';
import { PermissionAccess, PermissionItem } from 'src/app/core/models/enum/permission.enum';
import { ContinuePopupComponent } from 'src/app/shared/continue-popup/continue-popup.component';
import { dateValidator, descriptionValidator, idValidator, startAndEndDateValidator } from 'src/app/shared/validators/validators';
import { DateTime } from 'luxon';
import { CdsIconConfig } from '@cds/ng-core/icon';
import moment from 'moment';
import { Campaign, CampaignTypeOptions, productTypeOptions, agentCommunicationOptions } from '../campaign';
import { YesOrNoFlagNumberE, yesOrNoFlagNumberOptions } from '../../employee/employee';
import { findOptionLabelByValue } from 'src/app/utils/utils';
import { EmployeeService } from '../../employee/employee.service';
import { CdMultidropdownTopping } from 'src/app/shared/cd-multidropdown/cd-multidropdown.model';

@Component({
  selector: 'app-add-campaign',
  templateUrl: './add-campaign.component.html',
  styleUrls: ['./add-campaign.component.scss'],
})
export class AddCampaignComponent implements OnInit {
  confirmButtonConfig: CdsButtonConfig = { size: 'sm' };

  saveButtonConfig: CdsButtonConfig = { size: 'sm' };

  resetButtonConfig: CdsButtonConfig = { size: 'sm', style: 'secondary' };

  backToEditButtonConfig: CdsButtonConfig = { size: 'sm', style: 'secondary' };

  originFormValue: Campaign = {};

  formSendValue: Campaign = {};

  PermissionAccess = PermissionAccess;

  PermissionItem = PermissionItem;

  showDatePicker = true;

  inEdit = true;

  invalidHkidTip = 'Please input HKID with format XXXXXXX(X)';

  invalidPassportTip = 'Please enter the correct passport number';

  form: FormGroup = new FormGroup({});

  isLoading = false;

  nowTime = DateTime.now();

  nowTimeInstr = this.nowTime.toFormat('dd/MM/yyyy');

  maxTime = this.getNowTime();

  maxTimeInStr = DateTime.fromJSDate(this.maxTime).toFormat('dd/MM/yyyy');

  yesOrNoFlagNumberOptions = yesOrNoFlagNumberOptions;

  findOptionLabelByValue = findOptionLabelByValue;

  CampaignTypeOptions = CampaignTypeOptions;

  productTypeOptions = productTypeOptions;

  agentCommunicationOptions = agentCommunicationOptions;

  get saveDisabled() {
    return !(this.form.dirty && this.form.valid) || this.isLoading;
    // return false;
  }

  get resetDisabled() {
    return !this.form.dirty || this.isLoading;
  }

  get campaignIdControl() {
    return this.form.get('campaignId');
  }

  get campaignNameControl() {
    return this.form.get('campaignName');
  }

  get campaignOwnerControl() {
    return this.form.get('campaignOwner');
  }

  get campaignPeriodFromControl() {
    return this.form.get('campaignPeriodFrom');
  }

  get campaignPeriodFromValue() {
    return this.campaignPeriodFromControl?.value || '';
  }

  get campaignPeriodToControl() {
    return this.form.get('campaignPeriodTo');
  }

  get campaignPeriodToValue() {
    return this.campaignPeriodToControl?.value || '';
  }

  get campaignDescriptionControl() {
    return this.form.get('campaignDescription');
  }

  get campaignTypeControl() {
    return this.form.get('campaignType');
  }

  get productTypesControl() {
    return this.form.get('productTypes');
  }

  get multipleAgentDisplayControl() {
    return this.form.get('multipleAgentDisplay');
  }

  get displayToServicingAgentControl() {
    return this.form.get('displayToServicingAgent');
  }

  get agentCommunicationControl() {
    return this.form.get('agentCommunication');
  }

  get workbasketControl() {
    return this.form.get('workbasket');
  }

  get agentSamControl() {
    return this.form.get('agentSam');
  }

  get multipleAgentDisplayDisabled() {
    return this.displayToServicingAgentControl?.value !== YesOrNoFlagNumberE.Y;
  }

  campaignTypeConfig: CdsDropdownConfig = {
    label: this.langService.translate('campaign.campaign-type'),
    options: CampaignTypeOptions,
    placeholder: this.langService.translate('campaign.select-campaign-type'),
  };

  productTypeConfig: CdsDropdownConfig = {
    label: this.langService.translate('campaign.product-type'),
    options: [],
    placeholder: this.langService.translate('campaign.select-product-type'),
  };

  multipleAgentDisplayConfig: CdsDropdownConfig = {
    label: this.langService.translate('campaign.multiple-agent-display'),
    options: yesOrNoFlagNumberOptions,
    placeholder: this.langService.translate('campaign.select-multiple-agent-display'),
  };

  displayCampaignToServicingAgentConfig: CdsDropdownConfig = {
    label: this.langService.translate('campaign.display-the-campaign'),
    options: yesOrNoFlagNumberOptions,
    placeholder: this.langService.translate('campaign.select-display-the-campaign'),
  };

  agentCommunicationConfig: CdsDropdownConfig = {
    label: this.langService.translate('campaign.agent-communication'),
    options: [],
    placeholder: this.langService.translate('campaign.agent-communication'),
  };

  iconConfig: CdsIconConfig = {
    color: '#00A758',
    size: 'sm',
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  checkResultInfoList: any[] = [];

  // AgentSepCheckResultTypeE = AgentSepCheckResultTypeE;

  checked = false;

  checkOk = true;

  warnIconConfig: CdsIconConfig = {
    color: '#D77D28',
  };

  errorIconConfig: CdsIconConfig = {
    color: '#C14A36',
  };

  warningTitle = 'The same agent was enrolled before.';
  warningText = 'Existing records will be replaced by clicking confirm to proceed.';

  constructor(
    private dialogRef: MatDialogRef<AddCampaignComponent>,
    private cdsPopup: CdsPopupService,
    private langService: CdsLangService,
    private service: EmployeeService,
    @Inject(CDS_POPUP_DATA)
    public data: {
      campaign: Campaign;
      editMode: boolean;
    }
  ) {}

  ngOnInit() {
    this.initForm();
    // console.log('data:', this.data);
  }

  initData() {
    this.originFormValue = {
      // agent: this.data.value.agent ? this.data.value.agent : '',
      // companyName: this.data.value.companyName ? this.data.value.companyName : '',
      // trusteeEmployerAccountNumber: this.data.value.trusteeEmployerAccountNumber ? this.data.value.trusteeEmployerAccountNumber : '',
      // remarks: this.data.value.remarks ? this.data.value.remarks : '',
    };
  }

  initForm() {
    this.form?.markAsUntouched();
    this.form?.markAsPristine();
    const initData = this.data.editMode
      ? this.originFormDataTrans(this.data.campaign)
      : {
          campaignId: '',
          campaignName: '',
          campaignOwner: '',
          campaignPeriodFrom: '',
          campaignPeriodTo: '',
          campaignDescription: '',
          campaignType: '',
          productTypes: [],
          multipleAgentDisplay: YesOrNoFlagNumberE.N,
          displayToServicingAgent: YesOrNoFlagNumberE.Y,
          agentCommunication: [],
          workbasket: '',
          agentSam: '',
        };

    this.form = new FormGroup(
      {
        campaignId: new FormControl(initData.campaignId, [Validators.required, Validators.maxLength(50), idValidator('employer.invalid-description')]),
        campaignName: new FormControl(initData.campaignName, [Validators.required, Validators.maxLength(100), idValidator('employer.invalid-description')]),
        campaignOwner: new FormControl(initData.campaignOwner, [
          Validators.required,
          Validators.maxLength(200),
          descriptionValidator('employer.invalid-description'),
        ]),
        campaignPeriodFrom: new FormControl(initData.campaignPeriodFrom, [Validators.required, dateValidator('create.dateError')]),
        campaignPeriodTo: new FormControl(initData.campaignPeriodTo, [Validators.required, dateValidator('create.dateError')]),
        campaignDescription: new FormControl(initData.campaignDescription, [
          Validators.required,
          Validators.maxLength(200),
          descriptionValidator('employer.invalid-description'),
        ]),
        campaignType: new FormControl(initData.campaignType, [Validators.required]),
        productTypes: new FormControl(initData.productTypes, [Validators.required]),
        multipleAgentDisplay: new FormControl(initData.multipleAgentDisplay, [Validators.required]),
        displayToServicingAgent: new FormControl(initData.displayToServicingAgent, [Validators.required]),
        agentCommunication: new FormControl(initData.agentCommunication, [Validators.required]),
        workbasket: new FormControl(initData.workbasket, [Validators.maxLength(500), idValidator('employer.invalid-description')]),
        agentSam: new FormControl(initData.agentSam, [Validators.maxLength(500), idValidator('employer.invalid-description')]),
      },
      { validators: startAndEndDateValidator('create.dateError', 'campaignPeriodFrom', 'campaignPeriodTo') }
    );
  }

  // changeFormImmediately(key: string, value: string) {
  //   if (value && typeof value === 'string') {
  //     this.form.get(key)?.setValue(value.trim());
  //   }
  // }

  getRequestParams() {
    const params: Campaign = deepCopy(this.form.value);
    params.productTypes = this.getMultiSelectRequestValue(params.productTypes as CdMultidropdownTopping[]);
    params.agentCommunication = this.getMultiSelectRequestValue(params.agentCommunication as CdMultidropdownTopping[]);
    params.campaignPeriodFrom = this.requestDateTrans(params.campaignPeriodFrom);
    params.campaignPeriodTo = this.requestDateTrans(params.campaignPeriodTo);
    if (this.data.editMode) {
      params.id = this.data.campaign.id;
    }
    return params;
  }

  continue() {
    if (this.saveDisabled) {
      return;
    }
    this.inEdit = false;
    this.checkResultInfoList = [];
    this.checked = false;
    this.checkOk = true;
  }

  save() {
    if (this.saveDisabled) {
      return;
    }
    this.doSave();
  }

  check() {
    const params = this.getRequestParams();

    this.isLoading = true;

    this.service
      .campaignAdd(params)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe({
        next: res => {
          if (res.result === 0 && res.data) {
            if (res.data.result === false) {
              this.checked = true;
              this.checkResultInfoList = res.data.tips || [];
            } else {
              this.popupWithReload();
            }
          } else {
            this.requestAlert(res.message);
          }
        },
        error: err => {
          this.requestAlert(err.message);
        },
      });
  }

  doSave() {
    const params = this.getRequestParams();

    this.isLoading = true;

    const ovservable = this.data.editMode ? this.service.campaignEdit : this.service.campaignAdd;

    ovservable(params)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe({
        next: res => {
          if (res.result === 0) {
            this.popupWithReload();
          } else {
            this.requestAlert(res.message);
          }
        },
        error: err => {
          this.requestAlert(err.message);
        },
      });
  }

  reset() {
    if (this.resetDisabled) {
      return;
    }
    this.cdsPopup
      .open(ContinuePopupComponent, {
        size: 'sm',
        data: { message: this.langService.translate('common.action.cancel.italic') },
      })
      .afterClosed()
      .subscribe(result => {
        if (result?.agree) {
          this.showDatePicker = false;
          setTimeout(() => {
            this.showDatePicker = true;
            this.initForm();
          });
        }
      });
  }

  delete() {
    this.cdsPopup
      .open(ContinuePopupComponent, {
        size: 'sm',
        data: {
          message: this.langService.translate(
            this.data.campaign.isEngaged === YesOrNoFlagNumberE.Y ? 'campaign.delete-engaged-confirm' : 'campaign.delete-confirm'
          ),
          continueButtonName: this.langService.translate('common.delete'),
        },
      })
      .afterClosed()
      .subscribe(result => {
        if (result?.agree) {
          this.isLoading = true;
          this.service
            .campaignDelete(this.data.campaign?.id as string)
            .pipe(
              finalize(() => {
                this.isLoading = false;
              })
            )
            .subscribe({
              next: res => {
                if (res.result === 0) {
                  this.popupWithReload();
                } else {
                  this.requestAlert(res.message);
                }
              },
              error: err => {
                this.requestAlert(err.message);
              },
            });
        }
      });
  }

  getNowTime() {
    return moment().toDate();
  }

  getMaxFromDate = (toDateStr = '31/12/9999') => {
    return moment(toDateStr, 'DD/MM/YYYY').toDate();
  };

  getMinToDate = (fromDateStr = '01/01/0000') => {
    return moment(fromDateStr, 'DD/MM/YYYY').toDate();
  };

  close() {
    if (!this.resetDisabled) {
      this.cdsPopup
        .open(ContinuePopupComponent, {
          size: 'sm',
          data: { message: this.langService.translate('common.action.cancel.italic') },
        })
        .afterClosed()
        .subscribe(result => {
          if (result?.agree) {
            this.dialogRef.close();
          }
        });
    } else {
      this.dialogRef.close();
    }
  }

  requestAlert(message: string) {
    this.cdsPopup
      .open(AlertPopupComponent, {
        size: 'sm',
        data: {
          title: 'Error',
          message: `<span class='cds-h4-light'>${message}</span>`,
          buttonName: this.langService.translate('confirm'),
        },
      })
      .afterClosed()
      .subscribe(() => {});
  }

  popupWithReload() {
    this.cdsPopup
      .open(AlertPopupComponent, {
        size: 'sm',
        data: {
          message: this.langService.translate('common.action.success.italic'),
          buttonName: this.langService.translate('common.gotItWithExclamation'),
        },
      })
      .afterClosed()
      .subscribe(() => {
        this.dialogRef.close({
          agree: true,
          reload: true,
        });
      });
  }

  dateTrans(date?: string) {
    if (!date) return '';
    return DateTime.fromFormat(date, 'yyyy-MM-dd').toFormat('dd/MM/yyyy');
  }

  requestDateTrans(date?: string) {
    if (!date) return '';
    return DateTime.fromFormat(date, 'dd/MM/yyyy').toFormat('yyyy-MM-dd');
  }

  backToEdit() {
    this.inEdit = true;
  }

  displayToServicingAgentChange(value: YesOrNoFlagNumberE) {
    if (value === YesOrNoFlagNumberE.N) {
      this.multipleAgentDisplayControl?.setValue(YesOrNoFlagNumberE.N);
    }
  }

  multiSelectRender(data: CdMultidropdownTopping[]) {
    if (!data) return '';
    return data.map(item => item.label).join(', ');
  }

  getMultiSelectRequestValue(data: CdMultidropdownTopping[]) {
    if (!data) return '';
    return data.map(item => item.value);
  }

  multidropdownToppingTrans(list: number[], options: CdMultidropdownTopping[]) {
    if (!list) return [];
    return options.filter(item => list.includes(item.value));
  }

  originFormDataTrans(data: Campaign) {
    if (!data) return {};
    const result = deepCopy(data);
    result.productTypes = this.multidropdownToppingTrans(result.productTypes, productTypeOptions);
    result.agentCommunication = this.multidropdownToppingTrans(result.agentCommunication, agentCommunicationOptions);
    result.campaignPeriodFrom = this.dateTrans(result.campaignPeriodFrom);
    result.campaignPeriodTo = this.dateTrans(result.campaignPeriodTo);
    return result;
  }
}
