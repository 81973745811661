import { Component, OnInit } from '@angular/core';
import { CdsButtonConfig } from '@cds/ng-core/button';
import { CdsColorTheme } from '@cds/ng-core/configuration';
import { CdsLangService } from '@cds/ng-core/lang';

import { CdsProgressType } from '@cds/ng-core/progress';
import { Sort, SortDirection } from '@cds/ng-data-table/sort';
import { CdsAlertService } from '@cds/ng-web-components/alert';
import { CdsPopupService } from '@cds/ng-web-components/popup';
import { CdsToastService } from '@cds/ng-web-components/toast';
import { finalize } from 'rxjs';
import { BTN_CONFIG_TERTIARY } from 'src/app/config/btn.config';
import { PermissionAccess, PermissionItem } from 'src/app/core/models/enum/permission.enum';
import { IPageableRequest } from 'src/app/core/models/request';
import { LayoutHeaderService } from 'src/app/core/services/layout-header.service';
import { PermissionService } from 'src/app/core/services/permission.service';
import { AlertPopupComponent } from 'src/app/shared/alert-popup/alert-popup.component';
import { IPaginationCount } from 'src/app/shared/pagination/pagination.dto';
import { toPageableRequest } from 'src/app/utils/pagination.utils';
import { AgentAndAssignedGroup } from './agent-list';
import { AgentListAddComponent } from './agent-list-add/agent-list-add.component';
import { AgentListEditComponent } from './agent-list-edit/agent-list-edit.component';
import { AgentListService } from './agent-list.service';

@Component({
  selector: 'app-agent-list',
  templateUrl: './agent-list.component.html',
  styleUrls: ['./agent-list.component.scss'],
})
export class AgentListComponent implements OnInit {
  btnCfg: CdsButtonConfig = BTN_CONFIG_TERTIARY;
  displayedColumns: string[] = ['trusteeEmployerAccountNumber', 'agent', 'remarks'];
  totalElements = 0;
  pageCount: IPaginationCount = { current: 0, count: 0 };
  dataSource: AgentAndAssignedGroup[] = [];
  isLoading = false;
  cdsProgressType: CdsProgressType = 'ring';
  cdsProgressTxt = 'loading';
  cdsProgressColor: CdsColorTheme = 'ml';
  sortDirection: SortDirection = '';
  sort: Sort = {
    active: 'trusteeEmployerAccountNumber',
    direction: 'asc',
  };
  canEdit = false;

  constructor(
    private agentListService: AgentListService,
    private cdsPopup: CdsPopupService,
    private toast: CdsToastService,
    private langService: CdsLangService,
    private layoutHeaderService: LayoutHeaderService,
    private alert: CdsAlertService,
    private permissionService: PermissionService
  ) {}

  ngOnInit(): void {
    this.layoutHeaderService.setTitle('agentList.title');
    this.loadTable();
    this.permissionService.hasPermission(PermissionAccess.W, PermissionItem.SHK_AGENT_ASSIGNMENT_MAPPING).then(havePermission => {
      if (havePermission) {
        this.canEdit = true;
        this.displayedColumns.push('action');
      }
    });
  }

  loadTable() {
    if (!this.isLoading) {
      const params: IPageableRequest = this.addSortParam(toPageableRequest(this.pageCount, 15));
      this.isLoading = true;
      this.agentListService
        .findAll(params)
        .pipe(
          finalize(() => {
            this.isLoading = false;
          })
        )
        .subscribe({
          next: res => {
            if (res.result === 0 && res.data) {
              this.totalElements = res.data.totalElements || 0;
              this.dataSource = res.data.content || [];
              if (this.pageCount.current != res.data.number + 1) {
                this.pageCount.current = res.data.number + 1;
              }
              if (this.pageCount.count !== res.data.totalPages) {
                this.pageCount.count = res.data.totalPages;
              }
            } else {
              this.toast.error(res.message);
            }
          },
          error: err => {
            console.error('Call company API error: ', err);
          },
        });
    }
  }

  getManageRolePath(roleId?: string) {
    return roleId ? `/system/role-info?roleId=${roleId}` : '/system/role-create';
  }

  add() {
    this.cdsPopup
      .open(AgentListAddComponent, {
        size: 'md',
      })
      .afterClosed()
      .subscribe(result => {
        if (result?.agree) {
          this.loadTable();
        }
      });
  }

  edit(data: AgentAndAssignedGroup) {
    this.cdsPopup
      .open(AgentListEditComponent, {
        size: 'md',
        data: {
          title: this.langService.translate('common.editRecord'),
          value: data,
        },
      })
      .afterClosed()
      .subscribe(result => {
        if (result?.agree) {
          this.loadTable();
        } else if (result?.delete) {
          this.loadTable();
        } else {
          this.isLoading = false;
        }
      });
  }

  private popupWithReload() {
    this.cdsPopup
      .open(AlertPopupComponent, {
        size: 'sm',
        data: {
          message: this.langService.translate('common.action.success.italic'),
          buttonName: this.langService.translate('common.gotItWithExclamation'),
        },
      })
      .afterClosed()
      .subscribe(() => {
        this.loadTable();
      });
  }

  onSortChange(event: Sort) {
    this.sortDirection = event.direction;
    this.sort = event;
    this.loadTable();
  }

  addSortParam(originParams: IPageableRequest) {
    const params: IPageableRequest = { ...originParams };

    params.sort = `${this.sort.active},${this.sort.direction}`;

    return params;
  }

  requestAlert(message: string) {
    this.alert.error('Got Error', message);
  }
}
