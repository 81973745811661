<div class="row l-pt-8 l-pb-8">
  <div class="col-xs-12 cds-color-green">
    <span class="cds-h4-demibold">{{ 'er.profile.general-information' | lang }}</span>
  </div>
  <div class="col-xs-12">
    <div class="p-line"></div>
  </div>
  <app-row-item label="ee.scheme-joined-date" [data]="data.schemeJoinedDate | ssmsDate" class="col-xs-6 col-sm-3 col-md-3 col-lg-3"> </app-row-item>
  <app-row-item label="ee.original-scheme-joined-date" [data]="data.originalSchemeJoinedDate | ssmsDate" class="col-xs-6 col-sm-3 col-md-3 col-lg-3">
  </app-row-item>
  <app-row-item label="ee.termination-completion-Date" [data]="data.terminationCompletionDate | ssmsDate" class="col-xs-6 col-sm-3 col-md-3 col-lg-3">
  </app-row-item>
  <app-row-item label="ee.previous-ca-member-code" [data]="data.previousCAMemberCode" class="col-xs-6 col-sm-3 col-md-3 col-lg-3"> </app-row-item>
  <app-row-item label="ee.pfofile.default_pa" [checked]="data.defaultPA === 'Y'" class="col-xs-6 col-sm-3 col-md-3 col-lg-3"> </app-row-item>
  <app-row-item label="ee.pfofile.ab_offer" [checked]="data.abOffer === 'Y'" class="col-xs-6 col-sm-3 col-md-3 col-lg-3"> </app-row-item>
  <app-row-item label="ee.scheme-merge-signal" [data]="data.schemeMergeSignal" class="col-xs-6 col-sm-3 col-md-3 col-lg-3"> </app-row-item>
</div>

<div class="row l-pb-8">
  <div class="col-xs-12 cds-color-green">
    <span class="cds-h4-demibold">{{ 'er.profile.enrolment-info' | lang }}</span>
  </div>
  <div class="col-xs-12">
    <div class="p-line"></div>
  </div>

  <app-row-item label="ee.enrolement-reference-no" class="col-xs-6 col-sm-3 col-md-3 col-lg-3">
    <ng-template appRowItemContentDef>
      <span class="green-pointer"> {{ data.enrolmentReferenceNo }}</span>
    </ng-template>
  </app-row-item>

  <app-row-item label="ee.enrolement-submission-channel" [data]="getSubChannel(data.enrolmentSubmissionChannel)" class="col-xs-6 col-sm-3 col-md-3 col-lg-3">
  </app-row-item>
  <app-row-item label="ee.enrolement-compeletion-date" [data]="data.enrolmentCompletionDate | ssmsDate" class="col-xs-6 col-sm-3 col-md-3 col-lg-3">
  </app-row-item>
  <app-row-item label="ee.application-received-date" [data]="data.applicationReceivedDate | ssmsDate" class="col-xs-6 col-sm-3 col-md-3 col-lg-3">
  </app-row-item>
  <app-row-item label="ee.declaration-compeletion-date" [data]="data.declarationCompletionDate | ssmsDate" class="col-xs-6 col-sm-3 col-md-3 col-lg-3">
  </app-row-item>
</div>

<app-cd-accordion>
  <app-cd-accordion-panel title="Others">
    <app-row-item
      label="ee.sourceOfFundWealth"
      [data]="data.sourceOfFundWealth === null || data.sourceOfFundWealth === '' ? 'Others' : getSourceOfFund(data.sourceOfFundWealth)"
      class="col-xs-12">
    </app-row-item>
    <app-row-item label="ee.otherSourceOfFundWealth" [data]="data.otherSourceOfFundWealth" class="col-xs-12"> </app-row-item>
    <app-row-item label="ee.originOfInitialSourceOfWealth" [data]="data.originOfInitialSourceOfWealth" class="col-xs-12"> </app-row-item>
    <!-- <app-row-item label="ee.originOfOnGoingSourceOfFundsWealth" [data]="data.originOfOnGoingSourceOfFundsWealth" class="col-xs-12"> </app-row-item> -->
  </app-cd-accordion-panel>
</app-cd-accordion>
