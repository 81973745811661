/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IPageableRequest } from 'src/app/core/models/request';
import { PageResponse } from 'src/app/core/models/response/response';
import { environment } from 'src/environments/environment';
import { AgentAndAssignedGroup } from './agent-list';

const filterParams = (iObj?: any): HttpParams => {
  if (iObj) {
    Object.keys(iObj).forEach(key => iObj[key] === undefined && delete iObj[key]);
    return new HttpParams({
      fromObject: iObj as any,
    });
  }
  return new HttpParams();
};

@Injectable({
  providedIn: 'root',
})
export class AgentListService {
  constructor(private http: HttpClient) {}

  private getApiPath(...strings: string[]) {
    return environment.apiPrefix.concat('/ext/eb-ssms/customer-service').concat(...strings);
  }
  private getAgentAipPath(iStr: string) {
    return this.getApiPath('/shkp/terminated-members/agent-mappings', iStr);
  }

  findAll(params: IPageableRequest): Observable<PageResponse<AgentAndAssignedGroup>> {
    return this.http.get<PageResponse<AgentAndAssignedGroup>>(this.getAgentAipPath(''), {
      params: filterParams(params),
    });
  }

  add(agentAndAssignedGroup: AgentAndAssignedGroup): Observable<PageResponse<boolean>> {
    return this.http.post<PageResponse<boolean>>(this.getAgentAipPath(''), agentAndAssignedGroup);
  }

  edit(agentAndAssignedGroup: AgentAndAssignedGroup): Observable<PageResponse<boolean>> {
    return this.http.put<PageResponse<boolean>>(this.getAgentAipPath(''), agentAndAssignedGroup);
  }

  delete(id: string): Observable<PageResponse<AgentAndAssignedGroup>> {
    return this.http.delete<PageResponse<AgentAndAssignedGroup>>(this.getAgentAipPath(`?id=${id}`));
  }
}
