import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ValidateEmpfRefNoRequest } from 'src/app/views/salesjourney/service/model/validate-empf-ref-no-request.model';
import { environment } from 'src/environments/environment';
import { catchError, timeout } from 'rxjs/operators';
import { Observable, of, Subject } from 'rxjs';
import { ValidateEmpfRefNoResponse } from './model/validate-empf-ref-no-response.model';
import { AgentSubmissionRequest } from './model/agent-submission-request.model';
import { AgentSubmissionResponse } from './model/agent-submission-response.model';
import { AgentInfoResponse, MultiAgentInfosResponse } from './model/agent-info-response.model';
import { CdHttpServeService } from 'src/app/shared/cd-http-serve/cd-http-serve.service';

@Injectable({
  providedIn: 'root',
})
export class IffService {
  validateEmpfRefNoUrl = `${environment.apiPrefix}/ext/eb-ssms-sales-journey-service/generateGeneralLink/validateEmpfRefNo/flow2`;
  agentSubmissionUrl = `${environment.apiPrefix}/ext/eb-ssms-sales-journey-service/generateGeneralLink/primaryAgentSubmission`;
  checkAgentUrl = `${environment.apiPrefix}/ext/eb-ssms/agent-service/agent/information`;
  checkMultiAgentsUrl = `${environment.apiPrefix}/ext/eb-ssms/agent-service/agent/infos/agentCd`;
  constructor(private httpClient: HttpClient, private cdHttpServeService: CdHttpServeService) {}
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  validateEmpfRefNoSubject = new Subject<ValidateEmpfRefNoResponse>();
  agentSubmissionSubject = new Subject<AgentSubmissionResponse>();
  agentInfoDataSubject = new Subject<AgentInfoResponse>();
  multiAgentInfosSubject = new Subject<MultiAgentInfosResponse>();
  thirdAgentInfoDataSubject = new Subject<AgentInfoResponse>();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  errorIffSubject = new Subject<any>();

  validateEmpfRefNo(validateEmpfRefNoRequest: ValidateEmpfRefNoRequest) {
    this.httpClient
      .post(this.validateEmpfRefNoUrl, validateEmpfRefNoRequest, this.httpOptions)
      .pipe(timeout(10000), catchError(this.handleError<ValidateEmpfRefNoResponse>('validate Empf Ref No got error')))
      .subscribe((resp: ValidateEmpfRefNoResponse) => {
        this.validateEmpfRefNoSubject.next(resp);
      });
  }

  agentSubmission(agentSubmissionRequest: AgentSubmissionRequest) {
    this.httpClient
      .post(this.agentSubmissionUrl, agentSubmissionRequest, this.httpOptions)
      .pipe(timeout(1000 * 60), catchError(this.handleError<AgentSubmissionResponse>('agent submission got error')))
      .subscribe((resp: AgentSubmissionResponse) => {
        this.agentSubmissionSubject.next(resp);
      });
  }

  getAgentInfo(agentcode: string) {
    this.httpClient
      .get(this.checkAgentUrl, { params: { agentInfo: agentcode } })
      .pipe(timeout(5000), catchError(this.handleError<AgentInfoResponse>('Get Agent(split case) got error')))
      .subscribe(resp => {
        this.agentInfoDataSubject.next(resp);
      });
  }

  getThirdAgentInfo(agentcode: string) {
    this.httpClient
      .get(this.checkAgentUrl, { params: { agentInfo: agentcode } })
      .pipe(timeout(5000), catchError(this.handleError<AgentInfoResponse>('Get third Agent(split case) got error')))
      .subscribe(resp => {
        this.thirdAgentInfoDataSubject.next(resp);
      });
  }

  getMultiAgentInfos(agentcodes: string[]) {
    this.httpClient
      .post(this.checkMultiAgentsUrl, agentcodes)
      .pipe(timeout(5000), catchError(this.handleError<MultiAgentInfosResponse>('Get multi Agent information(for ER split case) got error')))
      .subscribe(resp => {
        this.multiAgentInfosSubject.next(resp);
      });
  }

  handleError<T>(operation = 'operation', result?: T) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (error: any): Observable<T> => {
      console.info(`${operation} failed :${error.message}`);
      this.errorIffSubject.next(`${error.message}`);
      return of(result as T);
    };
  }

  getAgentCode(identityId: string) {
    return this.cdHttpServeService.get<{ agentCode: string }>('/ext/eb-ssms/agent-service/agent/getAgentCode', { identityId });
  }
}
