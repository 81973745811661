import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { CdsButtonConfig } from '@cds/ng-core/button';
import { CDS_POPUP_DATA } from '@cds/ng-web-components/popup';

@Component({
  selector: 'app-commonts-popup',
  templateUrl: './commonts-popup.component.html',
  styleUrls: ['./commonts-popup.component.scss'],
})
export class CommontsPopupComponent implements OnInit {
  @Input() title = 'The changes you’ve made will be lost, are you sure to continue?';

  agreeButtonConfig: CdsButtonConfig = {
    style: 'secondary',
  };
  disagreeButtonConfig: CdsButtonConfig = {
    style: 'primary',
  };
  constructor(
    private dialogRef: MatDialogRef<CommontsPopupComponent>,
    @Inject(CDS_POPUP_DATA)
    public data: {
      name: string;
      message: string;
      noCloseIcon: string;
    }
  ) {}

  ngOnInit(): void {}

  agree() {
    this.dialogRef.close({ agree: false });
  }
  disagree() {
    this.dialogRef.close({ agree: true });
  }
}
