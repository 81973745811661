<cds-popup>
  <cds-popup-title *ngIf="data.title">{{ data.title }}</cds-popup-title>
  <cds-popup-content>
    <div innerHtml="{{ data.message }}"></div>
  </cds-popup-content>
  <cds-popup-actions>
    <cds-button [config]="disagreeButtonConfig" (click)="close()">
      <ng-container *ngIf="data.cancelButtonName">{{ data.cancelButtonName }}</ng-container>
      <ng-container *ngIf="!data.cancelButtonName">{{ 'common.cancel' | lang }}</ng-container>
    </cds-button>
    <cds-button [config]="agreeButtonConfig" (click)="agree()">
      <ng-container *ngIf="data.continueButtonName">{{ data.continueButtonName }}</ng-container>
      <ng-container *ngIf="!data.continueButtonName">{{ 'common.continue' | lang }}</ng-container>
    </cds-button>
  </cds-popup-actions>
</cds-popup>
