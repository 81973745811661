import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PermissionAccess, PermissionItem } from 'src/app/core/models/enum/permission.enum';
import { PermissionService } from 'src/app/core/services/permission.service';
import { EmployerProfileDetail, EmployerStatusE } from '../employer';

@Component({
  selector: 'app-employer-detail-quick-access',
  templateUrl: './employer-detail-quick-access.component.html',
  styleUrls: ['./employer-detail-quick-access.component.scss'],
})
export class EmployerDetailQuickAccessComponent implements OnInit {
  @Input() employerProfileDetail?: EmployerProfileDetail;

  @Output() changeShowQuickAccess = new EventEmitter<boolean>();
  @Output() erProfileInfoDetailShow = new EventEmitter();

  isInternalStaff = false;

  PermissionAccess = PermissionAccess;

  PermissionItem = PermissionItem;

  get showTerminationDetail() {
    const status = this.employerProfileDetail?.employerStatus;
    return status === EmployerStatusE.TERMD || status === EmployerStatusE.TERMG;
  }

  constructor(private router: ActivatedRoute, private permissionService: PermissionService) {}

  ngOnInit(): void {
    this.permissionService.hasPermission(PermissionAccess.R, PermissionItem.CUSTOMER_EMPLOYER).then(hasPermission => {
      if (hasPermission) {
        this.isInternalStaff = true;
      }
    });
  }

  anchorScrollTo(id: string, isErProfileInfoDetailItem = false) {
    if (isErProfileInfoDetailItem) {
      this.erProfileInfoDetailShow.emit();
    }
    this.changeShowQuickAccess.emit(false);

    setTimeout(() => {
      window.location.hash = '';
      window.location.hash = id;
      setTimeout(() => {
        window.location.hash = '';
      });
    });
  }
}
