/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { CdsButtonConfig } from '@cds/ng-core/button';
import { CdsPopupService, MatDialogRef } from '@cds/ng-web-components/popup';
import { BTN_CONFIG_SECONDARY } from 'src/app/config/btn.config';

import { UserAgreementPopupComponent } from 'src/app/views/commission/user-agreement-popup.component';
import { CommissionAgentService } from 'src/app/core/services/commission/commission-agent.service';
import { toISODate, fromISO } from 'src/app/utils/dateUtils';
import { texts } from 'src/app/views/commission/summary/pool-info.model';
import { deepCopy } from 'src/app/utils/copy';

interface Agent {
  id: string;
  agentName: string;
  agentCode: string;
  sellingAgent: boolean;
  sellingAgentWarning?: string;
  shareHkpc: string;
  shareCommission: string;
  saReportDate: string;
  agentCodeError?: string;
  shareHkpcError?: string;
  shareCommissionError?: string;
  saReportDateError?: string;
  saReportDateWarning?: string;
  updateDisplay?: boolean;
}
@Component({
  selector: 'app-agent-record-edit',
  templateUrl: './agent-record-edit.component.html',
  styleUrls: ['./agent-record-edit.component.scss'],
})
export class AgentRecordEditComponent implements OnInit {
  constructor(private cdsPopup: CdsPopupService, private agentService: CommissionAgentService, private router: Router, private route: ActivatedRoute) {}

  commissionPoolId = '';
  currentEffectiveDate = '';
  btnCfg: CdsButtonConfig = BTN_CONFIG_SECONDARY;

  dataSource: Agent[] = [];
  originalAgents: Agent[] = [];

  editForm: any = {
    effectiveDate: '',
  };
  loading = false;
  isConfirm = false;
  disableSave = true;
  isErrorDisplay = false;
  warningMsg = '';

  doBackMethod() {
    this.router.navigate(['/commission/pool-info/'], {
      queryParams: {
        commissionPoolId: this.commissionPoolId,
        tabType: 'agents',
      },
    });
  }

  update() {
    if (JSON.stringify(this.originalAgents) !== JSON.stringify(this.dataSource)) {
      this.cancelAlert().then(res => {
        if (res.agree) {
          this.isConfirm = false;
          this.disableSave = true;
          this.editForm.effectiveDate = this.currentEffectiveDate;
        }
      });
    } else {
      this.isConfirm = false;
      this.editForm.effectiveDate = this.currentEffectiveDate;
    }
  }

  formChange(input: any, i: number) {
    this.disableSave = true;
    this.warningMsg = '';
    const amountPattern = texts.datePattern;
    switch (input) {
      case 'item.sellingAgent':
        this.dataSource[i].sellingAgentWarning = '';
        this.originalAgents.forEach(item => {
          if (item.agentCode == this.dataSource[i].agentCode && item.sellingAgent !== this.dataSource[i].sellingAgent)
            this.dataSource[i].sellingAgentWarning = 'Selling Agent: Different with current record';
        });
        break;
      case 'item.saReportDate':
        this.dataSource[i].saReportDateError = '';
        this.dataSource[i].saReportDateWarning = '';
        if (this.dataSource[i].saReportDate && !amountPattern.test(this.dataSource[i].saReportDate)) {
          this.dataSource[i].saReportDateError = 'Invalid SA Report Rec’d Date';
        } else if (this.dataSource[i].saReportDate && !toISODate(this.dataSource[i].saReportDate)) {
          this.dataSource[i].saReportDateError = 'Invalid SA Report Rec’d Date';
        }
        if (!this.dataSource[i].saReportDateError) {
          this.originalAgents.forEach(item => {
            if (item.agentCode == this.dataSource[i].agentCode && item.saReportDate !== this.dataSource[i].saReportDate)
              this.dataSource[i].saReportDateWarning = 'SA Report Rec‘d Date: Different with current record';
          });
        }
        break;
    }
    this.formValidate();
  }

  formValidate() {
    if (this.dataSource.length > 0 && this.checkAgents() && JSON.stringify(this.originalAgents) !== JSON.stringify(this.dataSource)) {
      this.disableSave = false;
    } else {
      this.disableSave = true;
    }
  }

  checkAgents() {
    let b = true;
    for (let i = 0; i < this.dataSource.length; i++) {
      if (!this.dataSource[i].saReportDateError) {
        b = true;
      } else {
        b = false;
        break;
      }
    }
    return b;
  }

  reset() {
    this.cancelAlert().then(res => {
      if (res && res.agree) {
        this.disableSave = true;
      }
    });
  }

  cancelAlert(): Promise<any> {
    return new Promise(resolve => {
      const popupRef: MatDialogRef<UserAgreementPopupComponent> = this.cdsPopup.open(UserAgreementPopupComponent, {
        data: {
          message: texts.cancelMsg,
          cancel: texts.cancel,
          continue: texts.continue,
          type: 'confirm',
        },
      });
      popupRef.afterClosed().subscribe(confirm => {
        if (confirm && confirm.agree) {
          this.editForm.effectiveDate = '';
          this.dataSource = deepCopy(this.originalAgents);
          this.warningMsg = '';
          resolve({ agree: true });
        }
        resolve({ agree: false });
      });
    });
  }

  backToEdit() {
    this.isConfirm = false;
  }

  save() {
    if (this.disableSave) {
      return;
    }
    this.isConfirm = true;
    const l1: any[] = [];
    const l2: any[] = [];
    this.originalAgents.forEach(i => {
      this.dataSource.forEach(j => {
        if (i.agentCode == j.agentCode) {
          l1.push({ date: i.saReportDate, sellingAgent: i.sellingAgent });
          l2.push({ date: j.saReportDate, sellingAgent: j.sellingAgent });
        }
      });
    });
    if (l1.length > 0 && l2.length > 0 && JSON.stringify(l1) != JSON.stringify(l2)) {
      this.warningMsg = 'commission.pool.agents.create.saChangeWarning';
    }
  }

  confirm() {
    const form: any = {};
    form.commissionPoolId = this.commissionPoolId;
    const agents: any[] = [];
    this.dataSource.forEach(item => {
      const agent = {
        id: item.id,
        agentCode: item.agentCode,
        sellingAgent: item.sellingAgent,
        saReportDate: item.saReportDate ? toISODate(item.saReportDate) : '',
      };
      agents.push(agent);
    });
    form.agents = agents;
    this.loading = true;
    this.agentService.addAgent(false, form).subscribe(resp => {
      this.loading = false;
      let popData, result: number;
      if (resp && resp.result == 0) {
        popData = {
          message: texts.alertSuccessMsg,
          continue: texts.alertSuccessContinue,
          type: 'alert',
        };
        result = resp.result;
      } else {
        popData = {
          message: texts.alertFailedMsg,
          continue: texts.alertFailedContinue,
          type: 'alert',
          params: { respMsg: resp.message },
        };
        result = resp.result;
      }
      const popupRef: MatDialogRef<UserAgreementPopupComponent> = this.cdsPopup.open(UserAgreementPopupComponent, { data: popData });
      popupRef.afterClosed().subscribe(confirm => {
        if (confirm.agree) {
          if (result == 0) {
            this.router.navigate(['/commission/pool-info/'], {
              queryParams: {
                commissionPoolId: this.commissionPoolId,
                tabType: 'agents',
              },
            });
          } else {
            this.backToEdit();
          }
        }
      });
    });
  }

  ngOnInit(): void {
    this.commissionPoolId = this.route.snapshot.queryParams['commissionPoolId'];
    const agentDetailId = this.route.snapshot.queryParams['agentDetailId'];
    this.currentEffectiveDate = this.route.snapshot.queryParams['currentEffectiveDate'];
    console.log(this.commissionPoolId + ' ' + agentDetailId + '' + this.currentEffectiveDate);
    this.loading = true;
    this.agentService
      .getRegularItems({
        id: agentDetailId,
        commissionPoolId: this.commissionPoolId,
      })
      .subscribe(resp => {
        this.loading = false;
        if (resp && resp.result == 0 && resp.data.content) {
          const agentItems: any[] = resp.data.content;
          agentItems.forEach(item => {
            this.dataSource.push({
              id: item.id,
              agentName: item.agentName,
              agentCode: item.agentCode,
              sellingAgent: item.sellingAgent,
              sellingAgentWarning: '',
              shareHkpc: item.shareHkpc,
              shareCommission: item.shareCommission,
              saReportDate: item.saReportDate ? fromISO(item.saReportDate) : '',
              saReportDateError: '',
              saReportDateWarning: '',
              updateDisplay: true,
            });
          });
          this.originalAgents = deepCopy(this.dataSource);
        }
      });
  }
}
