import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize } from 'rxjs';
import { PermissionAccess, PermissionItem } from 'src/app/core/models/enum/permission.enum';
import { ResponseResult } from 'src/app/core/models/response/response-result';
import { RoleService } from 'src/app/core/services/role.service';

@Component({
  selector: 'app-role-info',
  styleUrls: ['./role-info.component.scss'],
  templateUrl: './role-info.component.html',
})
export class RoleInfoComponent implements OnInit {
  roldError = false;
  isLoading = false;
  roleId = '';
  roleName = '';
  formPermissions = new Map<PermissionItem, PermissionAccess>();

  selectedTabIndex = 0;
  tab = ['role.edit.tab.nameAccessRight', 'role.edit.tab.assignUsers'];

  constructor(private router: Router, private roleService: RoleService, private route: ActivatedRoute) {}

  ngOnInit(): void {
    const roleId = this.route.snapshot?.queryParams['roleId'];
    if (!roleId) {
      this.back();
      return;
    }
    this.roleId = roleId;
    this.getRoleInfo();
  }

  getRoleInfo() {
    this.isLoading = true;
    this.roleService
      .getById(this.roleId)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe({
        next: res => {
          if (res.result === ResponseResult.SUCCESS && res.data) {
            this.roleName = res.data.roleName;
            this.formPermissions = res.data.permissions || new Map<PermissionItem, PermissionAccess>();
          } else {
            this.roldError = true;
          }
        },
        error: () => {
          this.roldError = true;
        },
      });
  }

  back() {
    this.router.navigate(['/system/role-list']);
  }

  tabChange(tabIndex: number) {
    if (tabIndex === this.selectedTabIndex) return;
    this.selectedTabIndex = tabIndex;
  }

  errorEvent() {
    this.roldError = true;
  }
}
