/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-it-support',
  templateUrl: './it-support.component.html',
  styleUrls: ['./it-support.component.scss'],
})
export class ItSupportComponent implements OnInit {
  downTime = new FormControl('22:00', {
    initialValueIsDefault: true,
  });
  formControl: FormControl = new FormControl();

  file!: File;
  uploading = false;
  errorMessage = '';

  get isSaveDisable() {
    return !this.downTime.dirty;
  }

  get isUploadDisable(): boolean {
    return !this.file || !!this.errorMessage;
  }

  constructor() {}

  ngOnInit() {}

  save() {
    console.log(this.downTime.value);
  }

  reset() {
    this.downTime.reset();
  }

  fileChange(event: any) {
    this.file = event?.target?.files[0];
    if (
      !this.file ||
      !this.file.size ||
      !this.file.type ||
      !this.file.name ||
      this.file.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    ) {
      this.errorMessage = 'Invalid file selected.';
      return;
    }
    if (this.file.size > 10485760) {
      this.errorMessage = 'Upload file size not exceeding 10MB';
      return;
    }
    this.errorMessage = '';
    this.upload();
  }

  upload() {
    this.uploading = true;
    // this.commissionApeManualAdjustService
    //   .uploadFile(this.file)
    //   .pipe(
    //     finalize(() => {
    //       this.uploading = false;
    //     })
    //   )
    //   .subscribe(res => {
    //     if (res.result == 0) {
    //       const popConfig = {
    //         message: this.I18N_KEY.COMMON_ACTION_SUCCESS,
    //         continue: this.I18N_KEY.COMMON_GOT,
    //         type: 'alert',
    //       };

    //       const popupRef: MatDialogRef<UserAgreementPopupComponent> = this.cdsPopup.open(UserAgreementPopupComponent, { data: popConfig });
    //       popupRef
    //         .afterClosed()
    //         .pipe(filter(confirm => confirm && confirm.agree))
    //         .subscribe(() => {
    //           this.dialogRef.close({
    //             agree: true,
    //           });
    //         });
    //     } else {
    //       this.errorMessage = res.message;
    //       this.showFileErrorMessage = true;
    //     }
    //   });
  }
}
