import { AccountType } from 'src/app/core/models/enum/account-type.enum';
import { ScaleType } from 'src/app/core/models/enum/commission-scale.enum';
import { AccumulationType } from 'src/app/core/models/enum/accumulation-type.enum';

const adjustArray0: string[] = [ScaleType.IND_TRF_SP_PC, ScaleType.IND_TRF_SP, 'POLICY'];
const adjustArray1: string[] = [ScaleType.IND_FS_PC, ScaleType.IND_TRF_SP_PC, ScaleType.IND_TRF_SP, 'POLICY'];

export const AccountAdjMap = new Map([
  [AccountType.MPF_ER, adjustArray0],
  [AccountType.MPF_SEP, adjustArray0],
  [AccountType.MPF_SEP_AGENT, adjustArray0],
  [AccountType.MACAU_PAP, adjustArray0],
  [AccountType.MPF_TVC, adjustArray0],
  [AccountType.MPF_PAP, adjustArray1],
]);

export const AdjustTypes = [
  {
    label: '01: FS HKPC (Individual Transfer)',
    value: ScaleType.IND_FS_PC,
    correspondingScale: '',
    ptd: 'FS HKPC PTD',
  },
  {
    label: '03: TRF SP HKPC (Individual Transfer)',
    value: ScaleType.IND_TRF_SP_PC,
    correspondingScale: 'TRF SP HKPC Scale',
    ptd: 'TRF SP HKPC PTD',
  },
  {
    label: '13: TRF SP COMM (Individual Transfer)',
    value: ScaleType.IND_TRF_SP,
    correspondingScale: 'TRF SP COMM Scale',
    ptd: 'TRF SP COMM PTD',
  },
  {
    label: '20: Policy count (Individual Transfer)',
    value: 'POLICY',
    correspondingScale: '',
    ptd: '',
  },
];

export const AdjustAccumulations = [
  {
    scaleType: ScaleType.IND_TRF_SP_PC,
    agents: [
      {
        adjustType: 'agent',
        accumulationType: AccumulationType.CAL_YTD,
      },
      {
        adjustType: 'agent',
        accumulationType: AccumulationType.CYTD,
      },
      {
        adjustType: 'agent',
        accumulationType: AccumulationType.PTD,
      },
    ],
    scales: [
      {
        adjustType: 'agent',
        accumulationType: AccumulationType.RELEASED_CYTD,
      },
      {
        adjustType: 'scale',
        accumulationType: AccumulationType.PREMIUM_CYTD,
      },
      {
        adjustType: 'scale',
        accumulationType: AccumulationType.PREMIUM_PTD,
      },
    ],
  },
  {
    scaleType: ScaleType.IND_FS_PC,
    agents: [
      {
        adjustType: 'agent',
        accumulationType: AccumulationType.CAL_YTD,
      },
      {
        adjustType: 'agent',
        accumulationType: AccumulationType.CYTD,
      },
      {
        adjustType: 'agent',
        accumulationType: AccumulationType.PTD,
      },
    ],
    scales: [],
  },
  {
    scaleType: ScaleType.IND_TRF_SP,
    agents: [
      {
        adjustType: 'agent',
        accumulationType: AccumulationType.CAL_YTD,
      },
      {
        adjustType: 'agent',
        accumulationType: AccumulationType.CYTD,
      },
      {
        adjustType: 'agent',
        accumulationType: AccumulationType.PTD,
      },
    ],
    scales: [
      {
        adjustType: 'scale',
        accumulationType: AccumulationType.PREMIUM_CYTD,
      },
      {
        adjustType: 'scale',
        accumulationType: AccumulationType.PREMIUM_PTD,
      },
    ],
  },
];

export const Transactions = [
  {
    scaleType: ScaleType.IND_TRF_SP_PC,
    share: '100',
    scaleRate: '0',
    txnCode: 'COMMISSION_ADJUSTMENT',
    commissionCode: 'TRANSFER_PC',
  },
  {
    scaleType: ScaleType.IND_FS_PC,
    share: '100',
    scaleRate: '0',
    txnCode: 'COMMISSION_ADJUSTMENT',
    commissionCode: 'MANUAL_ADJUSTMENT_OR_CLAWBACK',
  },
  {
    scaleType: ScaleType.IND_TRF_SP,
    share: '100',
    scaleRate: '0',
    txnCode: 'COMMISSION_ADJUSTMENT',
    commissionCode: 'TRANSFER_COMMISSION',
  },
];
