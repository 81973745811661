<div class="l-pa-7">
  <div class="cds-h2-bold l-mt-5">
    {{ 'iff.exitWarnMessage' | lang }}
  </div>

  <div>
    <cds-button [config]="disagreeButtonConfig" (click)="disagree()" label="{{ 'iff.cancel' | lang }}"></cds-button>
    <cds-button class="l-ml-4" [config]="agreeButtonConfig" (click)="agree()" label="{{ 'iff.okContinue' | lang }}"></cds-button>
  </div>
</div>
