/* eslint-disable @typescript-eslint/no-explicit-any */

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { filterParams } from 'src/app/core/models/request';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class InputAutocompleteService {
  private base_url = `${environment.apiPrefix}/ext/eb-ssms/`;

  constructor(private http: HttpClient) {}

  get(url: string, params: any = {}, page = 0, pageSize = 10, resDataInContent: boolean): Observable<any> {
    return this.http
      .get<any>(this.base_url + url, {
        params: filterParams({
          ...params,
          size: pageSize,
          page,
        }),
      })
      .pipe(
        map(res => {
          return (resDataInContent ? res.data.content : res.data) || [];
        })
      );
  }
}
