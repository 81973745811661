import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CdsLanguageModule } from '@cds/ng-core/lang';
import { CdsAssistiveTextModule } from '@cds/ng-web-components/assistive-text';
import { CdsButtonModule } from '@cds/ng-web-components/button';
import { CdsIconModule } from '@cds/ng-web-components/icon';
import { CdsProgressModule } from '@cds/ng-web-components/progress';
import { CdsTextfieldModule } from '@cds/ng-web-components/textfield';

import { LoginComponent } from './login/login.component';
import { MyStringPipe } from './login/my-string.pipe';
import { UserRoutingModule } from './user-routing.module';

@NgModule({
  declarations: [LoginComponent, MyStringPipe],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    OverlayModule,

    CdsAssistiveTextModule,
    CdsButtonModule,
    CdsIconModule,
    CdsLanguageModule,
    CdsProgressModule,
    CdsTextfieldModule,

    UserRoutingModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class UserModule {
  constructor() {}
}
