/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Input, OnInit, AfterViewInit, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

import { CdsOrientation } from '@cds/ng-core/configuration';
import { CdsRadioConfig } from '@cds/ng-core/radio';

export type CdRadioConfig = CdsRadioConfig & {
  options: {
    label: string;
    value: string | number | boolean;
    disable: boolean;
  }[];
};

@Component({
  selector: 'app-cd-radio',
  templateUrl: './cd-radio.component.html',
  styleUrls: ['./cd-radio.component.scss'],
})
export class CdRadioComponent implements OnInit, AfterViewInit {
  @Input() value?: any;
  @Output() valueChange = new EventEmitter<any>();
  @Input() orientation: CdsOrientation = 'horizontal';
  @Input() config!: CdRadioConfig;
  @Input() formControl?: FormControl;
  @ViewChild('cdRadio', { static: false }) cdRadio?: ElementRef<HTMLElement>;
  @Input() group!: FormGroup;
  @Input() controlName = '';

  disableList: boolean[] = [];

  constructor() {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.refreshConfig();
  }

  refreshConfig() {
    setTimeout(() => {
      this.disableList = this.config.options.map((item: { disable: boolean }) => item.disable);

      const tempList = this.cdRadio?.nativeElement.getElementsByTagName('mat-radio-button');
      if (tempList) {
        for (let i = 0; i < tempList.length; i++) {
          if (this.disableList[i]) {
            tempList.item(i)?.classList.add('disable');
          } else {
            tempList.item(i)?.classList.remove('disable');
          }
        }
      }
    }, 0);
  }
}
