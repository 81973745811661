import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { catchError, Observable, of, Subject, timeout } from 'rxjs';
import { CaseSummaryRequest } from './casesummary-request.model';
import { CaseSummaryResponse } from './casesummary-response.model';

@Injectable({
  providedIn: 'root',
})
export class CasesummaryService {
  searchcasesummaryUrl = `${environment.apiPrefix}/ext/eb-ssms-sales-journey-service/memberSalesJourneySummaries`;
  casesummaryDataSubject = new Subject<CaseSummaryResponse>();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  errorcasesummarySubject = new Subject<any>();

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  constructor(private httpClient: HttpClient) {}
  getCaseSummary(request: CaseSummaryRequest) {
    this.httpClient
      .post(this.searchcasesummaryUrl, request, this.httpOptions)
      .pipe(timeout(5000), catchError(this.handleError<CaseSummaryResponse>(new CaseSummaryResponse(2, 'ERROR'))))
      .subscribe(resp => {
        this.casesummaryDataSubject.next(resp);
      });
  }
  handleError<T>(result?: T) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (error: any): Observable<T> => {
      this.errorcasesummarySubject.next(`${error.message}`);
      return of(result as T);
    };
  }

  wsmStatus(item: { wsmStatus: number }) {
    const result = { color: '', text: '' };

    switch (item.wsmStatus) {
      case 10:
        result.color = '#00A758';
        result.text = 'active';
        break;
      case 90:
        result.color = '#F49600';
        result.text = 'expired';
        break;
    }

    return result;
  }

  iffStatus(item: { IFFStatus: string }) {
    const result = { color: '', text: '' };

    switch (item.IFFStatus) {
      case '0':
        result.color = '#F49600';
        result.text = 'eIFFStatus.pendingToSend';
        break;

      case '10':
        result.color = '#06C7BA';
        result.text = 'eIFFStatus.pendingSplitAgent';
        break;

      case '20':
        result.color = '#0000C1';
        result.text = 'eIFFStatus.pendingCustomerSubmission';
        break;

      case '30':
        result.color = '#06874E';
        result.text = 'eIFFStatus.completed';
        break;

      case '40':
        result.color = '#8E90A2';
        result.text = 'eIFFStatus.expired';
        break;

      case '97':
        result.color = '#282b3e';
        result.text = 'eIFFStatus.void';
        break;

      case '98':
        result.color = '#EC6453';
        result.text = 'eIFFStatus.declinedAgent';
        break;

      case '99':
        result.color = '#D03A39';
        result.text = 'eIFFStatus.declined';
        break;
    }

    return result;
  }

  empfAppStatus(item: { eMPFAppStatus: string }) {
    const result = { color: '', text: '' };

    switch (item.eMPFAppStatus) {
      case '0':
        result.color = '#F49600';
        result.text = 'Pending eMPF data';
        break;

      case '10':
        result.color = '#06C7BA';
        result.text = 'eMPF Processing';
        break;

      case '20':
        result.color = '#0000C1';
        result.text = 'eMPF Follow-up';
        break;

      case '30':
        result.color = '#06874E';
        result.text = 'eMPF Completed';
        break;

      case '31':
        result.color = '#EC6453';
        result.text = 'Partially completed';
        break;

      case '40':
        result.color = '#8E90A2';
        result.text = 'eMPF Rejected';
        break;

      case '50':
        result.color = '#A00E18';
        result.text = 'eMPF Application cancelled';
        break;

      case '99':
        result.color = '#604584';
        result.text = 'Not Applicable';
        break;
    }

    return result;
  }

  declineReason(list?: string[] | string, type: 'EE' | 'ER' = 'EE', from?: string) {
    if (!list) {
      return [];
    }
    if (typeof list === 'string') {
      list = list.split(',');
    }
    if (list.length === 0) {
      return [];
    }

    const result: string[] = [];
    list.forEach(element => {
      switch (element) {
        case '1':
          result.push('Mismatch Ref. No');
          break;
        case '2':
          result.push('Mismatch HKID/ Passport No.');
          break;
        case '3':
          result.push('Mismatch Employer Name');
          break;
        case '4':
          result.push('Mismatch BR Number');
          break;
        case '6':
          result.push('Mismatch Agent Information');
          break;
        case '7':
          if (type === 'EE') {
            result.push('Mismatch Client Contact');
          } else {
            result.push('Mismatch Authorized Person Contact');
          }
          break;
        case '8':
          result.push('Declined due to reject application in eMPF portal');
          break;
      }
      if (from !== 'sales-support-summary' && from !== 'branch-level-summary') {
        switch (element) {
          case '91':
            result.push('Pending to Send');
            break;
          case '92':
            result.push('Pending Split Agent(s)');
            break;
          case '93':
            result.push('Pending Customer Submission');
            break;
        }
      }
    });

    return result;
  }

  differentAgentStatus(item: { iffStatusEnum: number }) {
    const result = { color: '', text: '' };

    switch (item.iffStatusEnum) {
      case 0:
        result.color = '';
        result.text = 'N/A';
        break;
      case 1:
        result.color = '#f49600';
        result.text = 'different-agent-status.pending';
        break;
      case 10:
        result.color = '#00a758';
        result.text = 'different-agent-status.submitted';
        break;
      case 20:
        result.color = '#5e6073';
        result.text = 'different-agent-status.expired';
        break;
      case 11:
        result.color = '#0000c1';
        result.text = 'different-agent-status.notRequired';
        break;
    }

    return result;
  }

  transferStatus(item: { transferStatusEnum: number }) {
    const result = { color: '', text: '' };

    switch (item.transferStatusEnum) {
      case 0:
        result.color = '#f49600';
        result.text = 'Transfer Processing';
        break;

      case 10:
        result.color = '#0000c1';
        result.text = 'Transfer Follow up';
        break;

      case 20:
        result.color = '#00a758';
        result.text = 'Transfer Completed';
        break;

      case 30:
        result.color = '#a00e18';
        result.text = 'Transfer Cancelled';
        break;

      case 40:
        result.color = '#8e90a2';
        result.text = 'Transfer Rejected';
        break;
    }

    return result;
  }
}
