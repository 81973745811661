<div>
  <form class="l-pb-7" [formGroup]="formGroup" (ngSubmit)="onSearch()">
    <div class="row l-plr-0">
      <div class="col-xs-12 col-md-6 col-lg-3 l-pr-4 l-pb-4 l-pl-0 l-d-f">
        <cds-form-field class="l-fg-1">
          <cds-textfield
            placeholder="DD/MM/YYYY"
            [label]="'commission.appeal.pendingTransaction.receivedDateFrom' | lang"
            [cdsDatepicker]="fromDatePicker"
            ngDefaultControl
            formControlName="receivedDateFrom">
          </cds-textfield>
          <cds-datepicker #fromDatePicker="cdsDatepicker"></cds-datepicker>
          <cds-assistive-text *ngIf="receivedDateFrom.errors && receivedDateFrom.dirty">{{ receivedDateFrom.errors['error'] | lang }}</cds-assistive-text>
          <cds-assistive-text *ngIf="formGroup.errors && formGroup.dirty">{{ formGroup.errors['error'] | lang }}</cds-assistive-text>
        </cds-form-field>
        <div class="l-fg-0 l-pl-4 l-pb-4 l-pt-6">-</div>
      </div>

      <cds-form-field class="col-xs-12 col-md-6 col-lg-3 l-pb-4 l-pr-20 l-pl-0">
        <cds-textfield
          placeholder="DD/MM/YYYY"
          [label]="'commission.appeal.pendingTransaction.receivedDateTo' | lang"
          [cdsDatepicker]="toDatePicker"
          ngDefaultControl
          formControlName="receivedDateTo">
        </cds-textfield>
        <cds-datepicker #toDatePicker="cdsDatepicker"></cds-datepicker>
        <cds-assistive-text *ngIf="receivedDateTo.errors && receivedDateTo.dirty">{{ receivedDateTo.errors['error'] | lang }}</cds-assistive-text>
      </cds-form-field>

      <cds-form-field class="col-xs-12 col-md-6 col-lg-3 l-pb-4 l-pr-20 l-pl-0">
        <cds-textfield
          [placeholder]="'commission.common.referenceNo.placeholder' | lang"
          [label]="'commission.common.referenceNo' | lang"
          [formControl]="referenceNo">
        </cds-textfield>
        <cds-assistive-text *ngIf="referenceNo.errors">
          {{ 'commission.appeal.referenceNo.notFound' | lang }}
        </cds-assistive-text>
      </cds-form-field>

      <cds-form-field class="col-xs-12 col-md-6 col-lg-3 l-pb-4 l-pr-20 l-pl-0">
        <cds-textfield
          [placeholder]="'commission.common.agentCode.placeholder' | lang"
          [label]="'commission.appeal.pendingTransaction.agentCode' | lang"
          [formControl]="agentCode">
        </cds-textfield>
        <cds-assistive-text *ngIf="agentCode.errors">
          {{ 'commission.common.agentCode.notFound' | lang }}
        </cds-assistive-text>
      </cds-form-field>

      <div class="col-xs-12 col-md-6 col-lg-3 l-pb-4 l-pr-20 l-pl-0">
        <div class="l-d-f">
          <label class="cds-body2 font-color-light3 l-d-ib l-mb-0 l-mr-2">
            {{ 'commission.appeal.pendingTransaction.accountNo' | lang }}
          </label>
          <cds-icon class="cursor-pointer" (click)="alertFutherInfo()" icon="action:info_1"></cds-icon>
        </div>
        <cds-textfield class="l-mb-4" [placeholder]="'commission.appeal.pendingTransaction.accountNoPlaceHolder' | lang" [formControl]="accountNo">
        </cds-textfield>
        <cds-assistive-text *ngIf="accountNo.errors">
          {{ 'commission.appeal.pendingTransaction.accountNo.notFound' | lang }}
        </cds-assistive-text>
      </div>

      <div class="col-xs-12 col-md-6 col-lg-3 l-pb-4 l-pr-20 l-pl-0 l-d-f">
        <div class="l-d-f l-ai-cen">
          <cds-checkbox [formControl]="cancelledOrRejected"></cds-checkbox>
          <label class="l-ml-3 cds-body1 cds-dark-navy-text l-mb-0">{{ 'commission.appeal.pendingTransaction.cancelled' | lang }}</label>
        </div>
        <!-- <cds-checkbox [label]="'commission.appeal.pendingTransaction.cancelled' | lang" [formControl]="cancelledOrRejected"> </cds-checkbox> -->
      </div>

      <div class="col-xs-12 col-md-6 col-lg-3 l-pb-4 l-pl-0">
        <div class="search-area-btn">
          <cds-button type="submit" [disabled]="isSearchDisable" size="md">{{ 'common.search' | lang }} </cds-button>
          <cds-button type="submit" (click)="reset()" [style]="'secondary'" size="md">{{ 'common.reset' | lang }} </cds-button>
        </div>
      </div>
    </div>
  </form>
</div>
