import { Component, Input, OnInit } from '@angular/core';
import { ChatUser } from '../json/canned';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ViewChatComponent } from './view-chat/view-chat.component';
import { ChatService } from 'src/app/views/chatbot-admin/_services/chat.service';
import { IPaginationCount } from 'src/app/shared/pagination/pagination.dto';

@Component({
  selector: 'app-chat-history',
  templateUrl: './chat-history.component.html',
  styleUrls: ['./chat-history.component.scss'],
})
export class ChatHistoryComponent implements OnInit {
  public pageCount: IPaginationCount = { current: 1, count: 1 };
  @Input() frontPagination = true;

  loading: boolean = false;
  count = 0;
  rows = [];
  filterRows = [];
  headers = [
    { key: 'agent', title: 'CSO' },
    { key: 'user', title: 'Agent' },
    { key: 'date', title: 'Date' },
    { key: 'schemeCd', title: 'Action' },
  ];
  modalOptions: NgbModalOptions = {};
  modalRef: any;

  constructor(public modalService: NgbModal, public chatServices: ChatService) {}

  ngOnInit(): void {
    this.getChatHistory();
  }

  view(item: any) {
    this.openFormModal(item);
  }

  searchChat(event: any) {
    const searchTerm = event.target.value.toLowerCase();

    debugger;
    this.filterRows = this.rows.filter(
      (chat: any) => chat.user.toLowerCase().includes(searchTerm.toLowerCase()) || chat.agent.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }

  getChatHistory() {
    this.chatServices.getAllRoomForHistory().subscribe(resp => {
      if (resp) {
        console.log(resp);
        this.rows = resp
          .map((item: any) => {
            if (item.users && item.status == 'CLOSED') {
              let agent = item.users.find((x: any) => x.type != 'CHATBOT_CSO')?.name;
              let obj = {
                id: item.roomId,
                user: item.users.length == 1 ? item.users[0].name : item.users.length > 1 ? item.users.find((x: any) => x.type === 'CHATBOT_CSO')?.name : '-',
                agent: agent ? agent : '-',
                date: item.users[0].msgDate,
              };
              return obj;
            }
            return null;
          })
          .filter((item: any) => item !== null)
          .sort((a: any, b: any) => new Date(b.date).getTime() - new Date(a.date).getTime());
      }

      this.filterRows = this.rows;
      console.log(this.rows);
    });
  }

  openFormModal(item: any) {
    this.modalOptions.backdrop = 'static';
    this.modalOptions.keyboard = false;
    this.modalOptions.size = 'lg';
    this.modalOptions.centered = true;
    this.modalRef = this.modalService.open(ViewChatComponent, this.modalOptions);
    this.modalRef.componentInstance.data = item;
    this.modalRef.result.then((result: any) => {}).catch((error: any) => {});
  }

  pageChange(currentPage: number) {
    this.pageCount.current = currentPage;
    // if (this.frontPagination) {
    //   this.setTable();
    //   // return;
    // }
    // this.reloadTable.emit({
    //   pageSize: this.pageSize,
    //   ...this.pageCount,
    //   column: this.activeClounm,
    // });
  }

  Delete(val: any) {
    debugger;
  }
  Edit(val: any) {
    debugger;
  }
}
