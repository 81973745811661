<div class="row" [formGroup]="recordDetailFormGroup">
  <div class="col-xs-12 col-md-3 l-pb-5">
    <div class="cds-body2 cds-dark-navy-text light-3">
      {{ 'commission.scale.code.label' | lang }}
      <span *ngIf="!isSubmitted && !isEditPage" class="cds-coral-text dark-1">*</span>
    </div>
    <input
      [ngClass]="{
        'text-field': !isSubmitted && !isEditPage,
        'input-no-border': isSubmitted || isEditPage
      }"
      placeholder="{{ 'commission.scale.code.placeholder' | lang }}"
      type="text"
      #scaleCode
      (input)="valueChange($event, 'scaleCode')"
      formControlName="scaleCode" />
    <ng-container
      *ngIf="
        (recordDetailFormGroup.controls['scaleCode'].touched || recordDetailFormGroup.controls['scaleCode'].dirty) &&
        recordDetailFormGroup.controls['scaleCode'].errors
      ">
      <cds-assistive-text *ngIf="recordDetailFormGroup.controls['scaleCode'].errors?.['required']">
        {{ 'Missing scale code' }}
      </cds-assistive-text>
      <cds-assistive-text *ngIf="recordDetailFormGroup.controls['scaleCode'].errors?.['existCode']">
        {{ 'commission.scale.code.error' | lang }}
      </cds-assistive-text>
    </ng-container>
  </div>
  <div class="col-xs-12 col-md-3 l-pb-5">
    <app-autocomplete
      [group]="recordDetailFormGroup"
      controlName="scaleType"
      [control]="recordDetailFormControl.scaleType"
      [selectOptions]="scaleTypeOpts"
      [nullable]="false"
      [locked]="isSubmitted || isEditPage"
      (valueChange)="valueChange($event, 'scaleType')"></app-autocomplete>
    <ng-container
      *ngIf="
        (recordDetailFormGroup.controls['scaleType'].touched || recordDetailFormGroup.controls['scaleType'].dirty) &&
        recordDetailFormGroup.controls['scaleType'].errors
      ">
      <cds-assistive-text *ngIf="recordDetailFormGroup.controls['scaleType'].errors?.['required']">
        {{ 'Missing scale code' }}
      </cds-assistive-text>
      <cds-assistive-text *ngIf="recordDetailFormGroup.controls['scaleType'].errors?.['invalidSelect']">
        {{ 'common.select.invalid' | lang }}
      </cds-assistive-text>
    </ng-container>
    <ng-container
      *ngIf="
        (recordDetailFormGroup.touched || recordDetailFormGroup.dirty) && recordDetailFormGroup.errors && !recordDetailFormGroup.controls['scaleType'].errors
      ">
      <cds-assistive-text *ngIf="recordDetailFormGroup.errors?.['invalidCalc']">
        {{ 'commission.scale.type.error.invalidCalc' | lang }}
      </cds-assistive-text>
    </ng-container>
  </div>
  <div class="col-xs-12 col-md-3 l-pb-5">
    <app-autocomplete
      [disabled]="recordDetailFormGroup.controls['calcType'].disabled"
      [group]="recordDetailFormGroup"
      controlName="calcType"
      [control]="recordDetailFormControl.calcType"
      [selectOptions]="calcTypeOpts"
      [locked]="isSubmitted || isEditPage"
      (valueChange)="valueChange($event, 'calcType')">
    </app-autocomplete>
    <ng-container
      *ngIf="
        (recordDetailFormGroup.controls['calcType'].touched || recordDetailFormGroup.controls['calcType'].dirty) &&
        recordDetailFormGroup.controls['calcType'].errors
      ">
      <cds-assistive-text *ngIf="recordDetailFormGroup.controls['calcType'].errors?.['invalidSelect']">
        {{ 'common.select.invalid' | lang }}
      </cds-assistive-text>
    </ng-container>
  </div>
  <div class="col-xs-12 col-md-3 l-pb-5">
    <div class="cds-body2 cds-dark-navy-text light-3">
      {{ 'commission.cpf.rate.label' | lang }}
    </div>
    <input
      [ngClass]="{
        'text-field': !isSubmitted && !isEditPage,
        'input-no-border': isSubmitted || isEditPage
      }"
      placeholder="{{ isSubmitted || isEditPage ? '' : ('commission.cpf.rate.placeholder' | lang) }}"
      type="text"
      formControlName="crfRate"
      #crfRate
      oninput="value=value.match(/(^(\d){1,2}\.(\d){0,3})|(^(\d){1,2})/g) || ''"
      (input)="valueChange(crfRate.value, 'crfRate')"
      (blur)="crfRateBlur($event)" />
    <ng-container *ngIf="(recordDetailFormGroup.touched || recordDetailFormGroup.dirty) && recordDetailFormGroup.errors">
      <cds-assistive-text *ngIf="recordDetailFormGroup.errors?.['invalidCrfRate']">
        {{ 'commission.crfRate.error.invalidType' | lang }}
      </cds-assistive-text>
    </ng-container>
  </div>
  <div class="col-xs-12 col-md-3 l-pb-5">
    <app-autocomplete
      [group]="recordDetailFormGroup"
      controlName="breakpointType"
      [control]="recordDetailFormControl.breakpointType"
      [selectOptions]="breakpointTypeOpts"
      [nullable]="false"
      [locked]="isSubmitted || isEditPage"
      (valueChange)="valueChange()"></app-autocomplete>
    <ng-container
      *ngIf="
        (recordDetailFormGroup.controls['breakpointType'].touched || recordDetailFormGroup.controls['breakpointType'].dirty) &&
        recordDetailFormGroup.controls['breakpointType'].errors
      ">
      <cds-assistive-text *ngIf=" recordDetailFormGroup.controls['breakpointType'].errors?.['required']">
        {{ 'Missing breakpoint type' }}
      </cds-assistive-text>
      <cds-assistive-text *ngIf="recordDetailFormGroup.controls['breakpointType'].errors?.['invalidSelect']">
        {{ 'common.select.invalid' | lang }}
      </cds-assistive-text>
    </ng-container>
  </div>
  <div class="col-xs-12 col-md-3 l-pb-5">
    <app-autocomplete
      [group]="recordDetailFormGroup"
      controlName="scalePattern"
      [control]="recordDetailFormControl.scalePattern"
      [selectOptions]="scalePatternOpts"
      [nullable]="false"
      [locked]="isSubmitted || !!editItem"
      (valueChange)="valueChange($event, 'scalePattern')"></app-autocomplete>
    <ng-container
      *ngIf="
        (recordDetailFormGroup.controls['scalePattern'].touched || recordDetailFormGroup.controls['scalePattern'].dirty) &&
        recordDetailFormGroup.controls['scalePattern'].errors
      ">
      <cds-assistive-text *ngIf="recordDetailFormGroup.controls['scalePattern'].errors?.['required']">
        {{ 'Missing scale pattern' }}
      </cds-assistive-text>
      <cds-assistive-text *ngIf="recordDetailFormGroup.controls['scalePattern'].errors?.['invalidSelect']">
        {{ 'common.select.invalid' | lang }}
      </cds-assistive-text>
    </ng-container>
  </div>
</div>
