import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { CdsButtonConfig } from '@cds/ng-core/button';
import { CdsOption } from '@cds/ng-core/configuration';
import { CdsDropdownConfig } from '@cds/ng-core/dropdown';
import { CdsRadioConfig } from '@cds/ng-core/radio';
import { DateTime } from 'luxon';
import moment from 'moment';
import { BTN_CONFIG_TERTIARY } from 'src/app/config/btn.config';
import { ColumnConfig } from 'src/app/shared/data-table';
import { dateValidator } from 'src/app/shared/validators/validators';
import { MONTH_OPTS } from 'src/app/config/month.config';
import { ToastAlertService } from 'src/app/shared/toast-alert.service';
import { EmployeeService } from '../employee.service';
import {
  DcMsgTypeE,
  dcMsgTypeMapObj,
  DcReasonTypeE,
  dcReasonTypeMapObj,
  DefaultContribution,
  DefaultContributionRequest,
  PaidAmount,
  RectifyTypeE,
  rectifyTypeMapObj,
  Surcharge,
} from '../employee';
import { finalize } from 'rxjs';

@Component({
  selector: 'app-employee-default-contribution',
  templateUrl: './employee-default-contribution.component.html',
  styleUrls: ['./employee-default-contribution.component.scss'],
})
export class EmployeeDefaultContributionComponent implements OnInit, OnChanges {
  @Input() id?: string;

  radioConfig: CdsRadioConfig = {
    options: [
      { label: 'Contribution Date', value: 'DAY' },
      { label: 'Contribution Month', value: 'MONTH' },
    ],
  };

  form = new FormGroup({});

  data: DefaultContribution[] = [];

  paidAmountDataSource: PaidAmount[] = [];

  surchargeDataSource: Surcharge[] = [];

  monthControlInit = true;
  yearControlInit = true;

  get dateControl() {
    return this.form.get('date');
  }

  paidAmountColumnsConfig: ColumnConfig[] = [
    {
      key: 'type',
      title: '',
    },
    {
      key: 'ermc',
      title: 'ERMC',
    },
    {
      key: 'eemc',
      title: 'EEMC',
    },
    {
      key: 'mcPaidDate',
      title: 'Paid Date',
    },
    {
      key: 'ervc',
      title: 'ERVC',
    },
    {
      key: 'eevc',
      title: 'EEVC',
    },
    {
      key: 'vcPaidDate',
      title: 'Paid Date',
    },
  ];

  surchargeColumnsConfig: ColumnConfig[] = [
    { key: 'surchargeER', title: 'Surcharge (ER)' },
    { key: 'surchargeEE', title: 'Surcharge (EE)' },
    { key: 'surchargePaidDate', title: 'Surcharge Paid Date' },
    { key: 'surchargeDueDate	', title: 'Surcharge Due Date' },
  ];

  moreBtnCfg: CdsButtonConfig = BTN_CONFIG_TERTIARY;

  maxTime = this.getNowTime();

  maxTimeInStr = DateTime.fromJSDate(this.maxTime).toFormat('dd/MM/yyyy');

  get searchBy() {
    return this.form.get('searchBy')?.value || '';
  }

  get monthControl() {
    return this.form.get('month');
  }

  get yearControl() {
    return this.form.get('year');
  }

  monthCfg: CdsDropdownConfig = this.getMonthCfg();

  yearCfg: CdsDropdownConfig = this.getYearCfg();

  get currentMonth() {
    return new Date().getMonth();
  }

  get currentYear() {
    return new Date().getFullYear();
  }

  get currentDate() {
    return DateTime.now().toFormat('dd/MM/yyyy');
  }

  isLoading = false;

  showMore = false;

  constructor(private service: EmployeeService, private toastAlert: ToastAlertService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['id'].currentValue !== undefined && changes['id'].currentValue !== changes['id'].previousValue) {
      this.getDefaultContribution();
    }
  }

  ngOnInit(): void {
    this.initForm();
    this.getDefaultContribution();
  }

  initForm() {
    this.form = new FormGroup({
      searchBy: new FormControl('DAY'),
      date: new FormControl(this.currentDate, [dateValidator('create.dateError', '', this.maxTimeInStr)]),
      month: new FormControl(this.currentMonth),
      year: new FormControl(this.currentYear),
    });
  }

  getDefaultContribution() {
    if (!this.id) return;
    const params: DefaultContributionRequest = {
      startDate: this.searchBy === 'DAY' ? this.dateTrans(this.dateControl?.value) : '',
      id: this.id,
      // erAcctCd: this.employerProfileDetail?.employerAccountCode,
      type: this.searchBy === 'DAY' ? 'day' : 'month',
      month: this.searchBy === 'DAY' ? '' : this.getConMnth(),
    };
    this.isLoading = true;
    this.showMore = false;
    this.service
      .getDefaultContribution(params)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe(res => {
        if (res.result === 0) {
          this.data = res.data || [];
        } else {
          this.toastError(res.message);
        }
      });
  }

  onDateChange() {
    if (this.dateControl?.valid) {
      this.getDefaultContribution();
    }
  }

  radioChange() {
    this.getDefaultContribution();
    this.monthControlInit = true;
    this.yearControlInit = true;
  }

  createMonthOpts(): CdsOption[] {
    if (this.yearControl?.value !== this.currentYear) {
      return [...MONTH_OPTS];
    }
    return MONTH_OPTS.slice(0, this.currentMonth + 1);
  }

  createYearOpts(): CdsOption[] {
    const result = [];
    for (let i = this.currentYear; i >= 1970; i--) {
      result.push({
        label: i.toString(),
        value: i,
      });
    }
    return result;
  }

  dateYearChange(value?: number) {
    this.monthCfg = this.getMonthCfg();
    if (this.currentYear === value && this.monthControl?.value > this.currentMonth) {
      this.monthControl?.setValue(undefined);
    } else if (value !== undefined && !this.yearControlInit) {
      this.getDefaultContribution();
    }
    this.yearControlInit = false;
  }

  dateMonthChange(value?: number) {
    if (value !== undefined && !this.monthControlInit) {
      this.getDefaultContribution();
    }
    this.monthControlInit = false;
  }

  dateTrans(date: string) {
    if (date) {
      return moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD');
    }

    return date;
  }

  getConMnth() {
    if (this.monthControl?.value === '') return '';
    return DateTime.fromObject({ year: this.yearControl?.value, month: this.monthControl?.value + 1 }).toFormat('yyyy-MM');
  }

  getNowTime() {
    return moment().toDate();
  }

  getMaxFromDate = (toDateStr?: string) => {
    if (!toDateStr) {
      return this.getNowTime();
    }
    return moment(toDateStr, 'DD/MM/YYYY').toDate();
  };

  getMonthCfg(): CdsDropdownConfig {
    return { options: this.createMonthOpts() };
  }

  getYearCfg(): CdsDropdownConfig {
    return { options: this.createYearOpts() };
  }

  clickShowMore() {
    this.showMore = true;
  }

  toastError(errorMessage: string) {
    this.toastAlert.show('error', 'common.error', errorMessage, 5000);
  }

  getPaidAmountDataSource(data: DefaultContribution) {
    if (!data) return [];
    const dataSource: PaidAmount[] = [
      {
        type: 'On time',
        ...data.onTimeTb,
      },
      {
        type: 'Late',
        ...data.lateTb,
      },
    ];
    return dataSource;
  }

  getsSrchargeDataSource(data: DefaultContribution) {
    if (!data) return [];
    const dataSource: Surcharge[] = [
      {
        surchargeER: data.surchargeER,
        surchargeEE: data.surchargeEE,
        surchargePaidDate: data.surchargePaidDate,
        surchargeDueDate: data.surchargeDueDate,
      },
    ];
    return dataSource;
  }

  messageTypeRender(type: DcMsgTypeE) {
    return dcMsgTypeMapObj[type] || '';
  }

  dcReasonRender(type: DcReasonTypeE) {
    return dcReasonTypeMapObj[type] || '';
  }

  rectifyTypeRender(type: RectifyTypeE) {
    return rectifyTypeMapObj[type] || '';
  }
}
