/* eslint-disable @typescript-eslint/no-explicit-any */
import { AbstractControl, ValidationErrors } from '@angular/forms';

export const requiredValidator = (errors?: ValidationErrors) => {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.dirty) {
      return null;
    }
    const value = control.value?.trim();
    if (!value) {
      return errors || { required: true };
    }
    return null;
  };
};
