<div class="advise-transfer-status" appOutsideClose (outsideEvent)="closeCurrentPopup()">
  <div class="content">
    <div>
      <span class="cds-body1-bold">Transfer status</span>
    </div>
    <div class="l-d-f l-ai-cen first-light-mt">
      <div class="light" style="background-color: #f49600"></div>
      <div class="l-ml-3">
        <span class="cds-body1-demibold">Transfer Processing</span>
      </div>
    </div>
    <div class="l-d-f l-ai-cen l-mt-3">
      <div class="light" style="background-color: #00a758"></div>
      <div class="l-ml-3">
        <span class="cds-body1-demibold">Transfer Completed</span>
      </div>
    </div>
    <div class="l-d-f l-ai-cen l-mt-3">
      <div class="light" style="background-color: #a00e18"></div>
      <div class="l-ml-3">
        <span class="cds-body1-demibold">Transfer Cancelled</span>
      </div>
    </div>
    <div class="l-d-f l-ai-cen l-mt-3">
      <div class="light" style="background-color: #0000c1"></div>
      <div class="l-ml-3">
        <span class="cds-body1-demibold">Transfer Follow up</span>
      </div>
    </div>
    <div class="l-d-f l-ai-cen l-mt-3">
      <div class="light" style="background-color: #8e90a2"></div>
      <div class="l-ml-3">
        <span class="cds-body1-demibold">Transfer Rejected</span>
      </div>
    </div>
  </div>

  <div class="l-d-f l-jc-fe l-mt-4 button-box">
    <cds-button label="Got it" (click)="closeCurrentPopup()" [style]="'primary'" [size]="'sm'"></cds-button>
  </div>
</div>
