import { Injectable } from '@angular/core';
import { APIPaths } from '../_common/constant';
import { Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Alerts } from '../_common/alerts';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CannedService {
  constructor(public httpClient: HttpClient) {}

  getCanedResponse() {
    let onSuccess = (data: any) => {
      if (data) {
        return data;
      } else {
        Alerts.showErrorMessage(data.meta.message);
      }
    };
    return this.httpClient.get(APIPaths.getCanedResponse).pipe(
      map(value => value),
      map(onSuccess)
    );
  }

  getSingleCannde(id: any) {
    let onSuccess = (data: any) => {
      if (data) {
        return data;
      } else {
        Alerts.showErrorMessage(data.meta.message);
      }
    };
    return this.httpClient.get(APIPaths.getCanedResponse + '/' + id).pipe(
      map(value => value),
      map(onSuccess)
    );
  }

  addCanedResponse(model: any) {
    let onSuccess = (data: any) => {
      if (data) {
        Alerts.showSuccessMessage('Message Added Successfully');
        return true;
      } else {
        Alerts.showErrorMessage(data.meta.message);
        return false;
      }
    };
    if (model?.id) {
      return this.httpClient.put(APIPaths.getCanedResponse + '/' + model.id, model).pipe(
        map(value => value),
        map(onSuccess)
      );
    } else {
      return this.httpClient.post(APIPaths.getCanedResponse, model).pipe(
        map(value => value),
        map(onSuccess)
      );
    }
  }

  getAgentCanedResponse() {
    let onSuccess = (data: any) => {
      if (data) {
        return data;
      } else {
        Alerts.showErrorMessage(data.meta.message);
      }
    };
    return this.httpClient.get(APIPaths.getAgentcanned).pipe(
      map(value => value),
      map(onSuccess)
    );
  }

  getAgetnList() {
    let onSuccess = (data: any) => {
      if (data) {
        return data;
      } else {
        Alerts.showErrorMessage(data.meta.message);
      }
    };
    return this.httpClient.get(APIPaths.getAgetnList).pipe(
      map(value => value),
      map(onSuccess)
    );
  }

  getAllAgetnList() {
    let onSuccess = (data: any) => {
      if (data) {
        return data;
      } else {
        Alerts.showErrorMessage(data.meta.message);
      }
    };
    return this.httpClient.get(APIPaths.getAllAgetnList).pipe(
      map(value => value),
      map(onSuccess)
    );
  }

  deleteAgentCanned(id: any) {
    let onSuccess = (data: any) => {
      debugger;
      return true;
      // if (data) {
      //   return data;
      // } else {
      //   Alerts.showErrorMessage(data.meta.message);
      // }
    };
    return this.httpClient.delete(APIPaths.getAgentcanned + '/' + id).pipe(
      map(value => value),
      map(onSuccess)
    );
  }

  deleteCanned(id: any) {
    let onSuccess = (data: any) => {
      return true;
      // if (data) {
      //   return data;
      // } else {
      //   Alerts.showErrorMessage(data.meta.message);
      // }
    };
    return this.httpClient.delete(APIPaths.getCanedResponse + '/' + id).pipe(
      map(value => value),
      map(onSuccess)
    );
  }

  deleteAutoMessage(id: any) {
    let onSuccess = (data: any) => {
      return true;
      // if (data) {
      //   return data;
      // } else {
      //   Alerts.showErrorMessage(data.meta.message);
      // }
    };
    return this.httpClient.delete(APIPaths.autoResponse + '/' + id).pipe(
      map(value => value),
      map(onSuccess)
    );
  }

  deleteAgreement(id: any) {
    let onSuccess = (data: any) => {
      return true;
      // if (data) {
      //   return data;
      // } else {
      //   Alerts.showErrorMessage(data.meta.message);
      // }
    };
    return this.httpClient.delete(APIPaths.agreements + '/' + id).pipe(
      map(value => value),
      map(onSuccess)
    );
  }

  transferChat(geentID: any, item: any) {
    let onSuccess = (data: any) => {
      if (data) {
        return data;
      } else {
        Alerts.showErrorMessage(data.meta.message);
      }
    };
    return this.httpClient.put(APIPaths.transferChat + `/${item.roomId}/${geentID}`, null).pipe(
      map(value => value),
      map(onSuccess)
    );
  }

  getSingleAgentCannde(id: any) {
    let onSuccess = (data: any) => {
      if (data) {
        return data;
      } else {
        Alerts.showErrorMessage(data.meta.message);
      }
    };
    return this.httpClient.get(APIPaths.getAgentcanned + '/' + id).pipe(
      map(value => value),
      map(onSuccess)
    );
  }

  addAgentCanedResponse(model: any) {
    let onSuccess = (data: any) => {
      if (data) {
        Alerts.showSuccessMessage('Message Added Successfully');
        return true;
      } else {
        Alerts.showErrorMessage(data.meta.message);
        return false;
      }
    };
    if (model?.id) {
      return this.httpClient.put(APIPaths.getAgentcanned + '/' + model.id, model).pipe(
        map(value => value),
        map(onSuccess)
      );
    } else {
      return this.httpClient.post(APIPaths.getAgentcanned, model).pipe(
        map(value => value),
        map(onSuccess)
      );
    }
  }

  addSubCanedResponse(model: any) {
    let onSuccess = (data: any) => {
      if (data) {
        Alerts.showSuccessMessage('Message Added Successfully');

        return true;
      } else {
        Alerts.showErrorMessage(data.meta.message);
        return false;
      }
    };

    return this.httpClient.post(APIPaths.subresponse + '/' + model.cannedId, model).pipe(
      map(value => value),
      map(onSuccess)
    );
  }
  getSubCanedResponse() {
    let onSuccess = (data: any) => {
      if (data) {
        return data;
      } else {
        Alerts.showErrorMessage(data.meta.message);
      }
    };
    return this.httpClient.get(APIPaths.getsubCanedResponse).pipe(
      map(value => value),
      map(onSuccess)
    );
  }
  // Auto response message
  getAutoResponse() {
    let onSuccess = (data: any) => {
      if (data) {
        return data;
      } else {
        Alerts.showErrorMessage(data.meta.message);
      }
    };
    return this.httpClient.get(APIPaths.autoResponse).pipe(
      map(value => value),
      map(onSuccess)
    );
  }

  addAutoResponse(model: any) {
    let onSuccess = (data: any) => {
      if (data) {
        Alerts.showSuccessMessage('Add Successfully');
        return data;
      } else {
        Alerts.showErrorMessage(data.meta.message);
      }
    };
    if (model.id) {
      return this.httpClient.put(APIPaths.autoResponse + `/${model.id}`, model).pipe(
        map(value => value),
        map(onSuccess)
      );
    } else {
      return this.httpClient.post(APIPaths.autoResponse, model).pipe(
        map(value => value),
        map(onSuccess)
      );
    }
  }

  getSingleAutoResponse(id: any) {
    let onSuccess = (data: any) => {
      if (data) {
        return data;
      } else {
        Alerts.showErrorMessage(data.meta.message);
      }
    };
    return this.httpClient.get(APIPaths.autoResponse + `/${id}`).pipe(
      map(value => value),
      map(onSuccess)
    );
  }
  // Services Agreement Apis
  getServicesAgreement() {
    let onSuccess = (data: any) => {
      if (data) {
        return data;
      } else {
        Alerts.showErrorMessage(data.meta.message);
      }
    };
    return this.httpClient.get(APIPaths.agreements).pipe(
      map(value => value),
      map(onSuccess)
    );
  }

  addServicesAgreement(model: any) {
    let onSuccess = (data: any) => {
      if (data) {
        Alerts.showSuccessMessage('Add Successfully');
        return data;
      } else {
        Alerts.showErrorMessage(data.meta.message);
      }
    };
    if (model.id) {
      return this.httpClient.put(APIPaths.agreements + `/${model.id}`, model).pipe(
        map(value => value),
        map(onSuccess)
      );
    } else {
      return this.httpClient.post(APIPaths.agreements, model).pipe(
        map(value => value),
        map(onSuccess)
      );
    }
  }

  getSingleServicesAgreement(id: any) {
    let onSuccess = (data: any) => {
      if (data) {
        return data;
      } else {
        Alerts.showErrorMessage(data.meta.message);
      }
    };
    return this.httpClient.get(APIPaths.agreements + `/${id}`).pipe(
      map(value => value),
      map(onSuccess)
    );
  }

  closeChat(modal: any, item: any, closeType: any) {
    let onSuccess = (data: any) => {
      if (data) {
        return data;
      } else {
        Alerts.showErrorMessage(data.meta.message);
      }
    };
    return this.httpClient.post(APIPaths.closeChat + item.roomId + '/' + closeType, modal).pipe(
      map(value => value),
      map(onSuccess)
    );
  }

  getReport(item: any) {
    let onSuccess = (data: any) => {
      if (data) {
        debugger;
        return data;
      } else {
        Alerts.showErrorMessage(data.meta.message);
      }
    };
    return this.httpClient.get(APIPaths.getReports + `?month=${item?.month}&year=${item?.year}&agent=${item?.agent}`, { responseType: 'blob' }).pipe(
      map(value => value),
      map(onSuccess)
    );
  }

  addStaticData(model: any) {
    let onSuccess = (data: any) => {
      if (data) {
        Alerts.showSuccessMessage('Add Successfully');
        return data;
      } else {
        Alerts.showErrorMessage(data.meta.message);
      }
    };
    if (model.id) {
      return this.httpClient.put(APIPaths.staticData + `/${model.id}`, model).pipe(
        map(value => value),
        map(onSuccess)
      );
    } else {
      return this.httpClient.post(APIPaths.staticData, model).pipe(
        map(value => value),
        map(onSuccess)
      );
    }
  }

  getStaticDataById(id: any) {
    let onSuccess = (data: any) => {
      if (data) {
        return data;
      } else {
        Alerts.showErrorMessage(data.meta.message);
      }
    };
    return this.httpClient.get(APIPaths.staticData + `/${id}`).pipe(
      map(value => value),
      map(onSuccess)
    );
  }

  getStaticDataByParentId(id: any) {
    let onSuccess = (data: any) => {
      if (data) {
        return data;
      } else {
        Alerts.showErrorMessage(data.meta.message);
      }
    };
    return this.httpClient.get(APIPaths.staticData + `/type/${id}`).pipe(
      map(value => value),
      map(onSuccess)
    );
  }

  getStaticData() {
    let onSuccess = (data: any) => {
      if (data) {
        return data;
      } else {
        Alerts.showErrorMessage(data.meta.message);
      }
    };
    return this.httpClient.get(APIPaths.staticData).pipe(
      map(value => value),
      map(onSuccess)
    );
  }

  deleteStaticData(id: any) {
    let onSuccess = (data: any) => {
      return true;
    };
    return this.httpClient.delete(APIPaths.staticData + `/${id}`).pipe(
      map(value => value),
      map(onSuccess)
    );
  }

  getSupervisorData() {
    let onSuccess = (data: any) => {
      if (data) {
        return data;
      } else {
        Alerts.showErrorMessage(data.meta.message);
      }
    };
    return this.httpClient.get(APIPaths.supervisor).pipe(
      map(value => value),
      map(onSuccess)
    );
  }
}
