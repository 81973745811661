import { CdsOption } from '@cds/ng-core/configuration';

export const IFFSTATUS_TYPE_OPTIONS: CdsOption[] = [
  { label: 'Pending to Send', value: 0 },
  { label: 'Pending Split Agent(s)', value: 10 },
  { label: 'Pending Customer Submission', value: 20 },
  {
    label: 'Completed',
    value: 30,
  },
  {
    label: 'Expired',
    value: 40,
  },
  {
    label: 'Declined (Agent SEP/ Staff CA)',
    value: 98,
  },
  {
    label: 'Declined',
    value: 99,
  },
  {
    label: 'Void',
    value: 97,
  },
];
export const EMPFSTATUS_TYPE_OPTIONS: CdsOption[] = [
  {
    label: 'Pending eMPF data',
    value: 0,
  },
  {
    label: 'eMPF Processing',
    value: 10,
  },
  {
    label: 'eMPF Follow-up',
    value: 20,
  },
  {
    label: 'eMPF Completed',
    value: 30,
  },
  {
    label: 'Partially completed',
    value: 31,
  },
  {
    label: 'eMPF Rejected',
    value: 40,
  },
  {
    label: 'eMPF Application cancelled',
    value: 50,
  },
  {
    label: 'Not Applicable',
    value: 99,
  },
];

export const EE_DECLINED_REASON_OPTIONS: CdsOption[] = [
  {
    label: 'Mismatch Ref. No',
    value: '1',
  },
  {
    label: 'Mismatch HKID/ Passport No.',
    value: '2',
  },
  {
    label: 'Mismatch Agent Information',
    value: '6',
  },
  {
    label: 'Mismatch Client Contact',
    value: '7',
  },
  {
    label: 'Declined due to reject application in eMPF portal',
    value: '8',
  },
];

export const ER_DECLINED_REASON_OPTIONS: CdsOption[] = [
  {
    label: 'Mismatch Ref. No',
    value: '1',
  },
  {
    label: 'Mismatch Employer Name',
    value: '3',
  },
  {
    label: 'Mismatch BR Number',
    value: '4',
  },
  {
    label: 'Mismatch Agent Information',
    value: '6',
  },
  {
    label: 'Mismatch Authorized Person Contact',
    value: '7',
  },
  {
    label: 'Declined due to reject application in eMPF portal',
    value: '8',
  },
];
