/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { CdsAlertService } from '@cds/ng-web-components/alert';
import { CdsPopupService } from '@cds/ng-web-components/popup';
import { CdHttpServeService } from 'src/app/shared/cd-http-serve/cd-http-serve.service';
import { getFilterParams } from 'src/app/utils/utils';
import { AddNewCaseComponent } from './add-new-case/add-new-case.component';
import { PermissionAccess, PermissionItem } from 'src/app/core/models/enum/permission.enum';
import { PermissionService } from 'src/app/core/services/permission.service';
import { Sort } from '@cds/ng-data-table/sort';
import { WsmCaseSummaryService } from './wsm-case-summary.service';
import { WsmCaseSummaryRecordItem, WsmCaseSummaryReqeuestParams } from './wsm-case-summary.model';
import { CdsOption } from '@cds/ng-core/configuration';
import { CdMultidropdownTopping } from 'src/app/shared/cd-multidropdown/cd-multidropdown.model';

@Component({
  selector: 'app-wsm-case-summary',
  templateUrl: './wsm-case-summary.component.html',
  styleUrls: ['./wsm-case-summary.component.scss'],
})
export class WsmCaseSummaryComponent implements OnInit {
  @ViewChild('tableBox', { static: false })
  tableBox?: ElementRef<HTMLDivElement>;
  requestParams!: WsmCaseSummaryReqeuestParams;
  requestParamsForPageChange?: WsmCaseSummaryReqeuestParams;
  resetDatePicker = false;
  expiryDateFromValError = false;
  expiryDateToValError = false;
  meetingDateFromValError = false;
  meetingDateToValError = false;
  totalElements = 0;
  isLoading = false;
  currentPageNumOrigin = 1;
  allPageData: WsmCaseSummaryRecordItem[][] = [];
  agentDisplayedColumns = ['employerAcctCd', 'bookingRefNo', 'meetingDate', 'expiryDate', 'wsmStatus'];
  superAdminDisplayedColumns = ['employerAcctCd', 'branchCd', 'agentCd', 'bookingRefNo', 'meetingDate', 'expiryDate', 'wsmStatus'];
  displayedColumns = this.agentDisplayedColumns;
  searchBranchCodeList: string[] = [];
  totalPages = 0;
  expiryDateFromMax: Date = new Date('9000/01/01');
  expiryDateToMin: Date = new Date('2000/01/01');
  meetingDateFromMax: Date = new Date('9000/01/01');
  meetingDateToMin: Date = new Date('2000/01/01');
  isSuperAdmin = false;
  branchCodeInput = '';
  resetTable = false;
  abmOptionList: CdsOption[] = [];
  abmStatusoptionsOrigin: CdMultidropdownTopping[] = [];
  constructor(
    private cdHttpServeService: CdHttpServeService,
    private alert: CdsAlertService,
    private cdsPopup: CdsPopupService,
    private permissionService: PermissionService,
    public wsmCaseSummaryService: WsmCaseSummaryService
  ) {}

  ngOnInit(): void {
    this.initRequestParams();
    this.requestParams.page = 1;
    this.requestParams.size = 50;
    this.getABMList();
    this.permissionService.hasPermission(PermissionAccess.W, PermissionItem.SALES_JOURNEY_SUPER_ADMIN).then(hasPermission => {
      this.hasSalesJourneySuperAdminWCallBack(hasPermission);
    });
  }

  getABMList() {
    this.cdHttpServeService.get<{ abm: string }[]>('/ext/eb-ssms-sales-journey-service/agent-branch/abm/list', {}).subscribe({
      next: res => {
        if (res.result !== 0) {
          this.alert.warning('Warning!', `${res.message}`);
        } else {
          res.data.forEach(e => {
            this.abmOptionList.push({ label: e.abm, value: e.abm });
          });
        }
      },
      error: err => {
        this.alert.error('Error!', err);
      },
    });
  }

  hasSalesJourneySuperAdminWCallBack(hasPermission: boolean) {
    this.isSuperAdmin = hasPermission;
    if (this.isSuperAdmin) {
      this.displayedColumns = this.superAdminDisplayedColumns;
      const params = getFilterParams(this.requestParams);
      this.search(params);
    } else {
      this.permissionService.hasPermission(PermissionAccess.R, PermissionItem.SALES_JOURNEY_SUPER_USER).then(hasPermission => {
        this.hasSalesJourneySuperAdminRCallBack(hasPermission);
      });
    }
  }

  hasSalesJourneySuperAdminRCallBack(hasPermission: boolean) {
    this.isSuperAdmin = hasPermission;
    if (this.isSuperAdmin) {
      this.displayedColumns = this.superAdminDisplayedColumns;
      const params = getFilterParams(this.requestParams);
      this.search(params);
    } else {
      const params = getFilterParams(this.requestParams);
      this.search(params);
    }
  }

  get currentPageIndex() {
    return this.currentPageNumOrigin - 1;
  }

  get currentPageData() {
    if (this.allPageData.length === 0) {
      return [];
    }
    return this.allPageData[this.currentPageIndex];
  }

  get checkIsHaveScroll() {
    if (this.tableBox) {
      return this.tableBox.nativeElement.scrollWidth > this.tableBox.nativeElement.clientWidth;
    }
    return false;
  }

  private initRequestParams() {
    this.branchCodeInput = '';
    this.requestParams = {
      page: 1,
      size: 50,
      bookingRefNo: '',
      employerAcctCd: '',
      branchCodes: [],
      ABMs: [],
      agentCd: '',
      meetingDateFrom: '',
      meetingDateTo: '',
      expiryDateFrom: '',
      expiryDateTo: '',
      sort: '',
    };
  }

  addToSearchAgentCodeList() {
    if (this.branchCodeInput) {
      if (!this.searchBranchCodeList.includes(this.branchCodeInput)) {
        this.searchBranchCodeList.push(this.branchCodeInput);
        this.branchCodeInput = '';
      }
    }
  }

  removeToSearchBranchCodeList(item: string) {
    this.searchBranchCodeList = this.searchBranchCodeList.filter(val => val !== item);
  }

  meetingDateFromValueChange(val: string) {
    if (val === '') {
      this.meetingDateFromValError = false;
      this.meetingDateToMin = new Date('2000/01/01');
      return;
    }
    this.requestParams.meetingDateFrom = val;
    const temp = this.requestParams.meetingDateFrom.split('/');
    const selectedDate = new Date([temp[1], temp[0], temp[2]].join('/'));
    if (selectedDate.toString() === 'Invalid Date') {
      this.meetingDateFromValError = true;
      return;
    }
    if (this.requestParams.meetingDateTo) {
      const tempTo = this.requestParams.meetingDateTo.split('/');
      const tempToDate = new Date([tempTo[1], tempTo[0], tempTo[2]].join('/'));
      if (selectedDate > tempToDate) {
        this.meetingDateFromValError = true;
        return;
      }
    }

    this.meetingDateFromValError = false;
    this.meetingDateToMin = selectedDate;
  }

  meetingDateToValueChange(val: string) {
    if (val === '') {
      this.meetingDateToValError = false;
      this.meetingDateFromMax = new Date('9000/01/01');
      return;
    }
    this.requestParams.meetingDateTo = val;
    const temp = this.requestParams.meetingDateTo.split('/');
    const selectedDate = new Date([temp[1], temp[0], temp[2]].join('/'));
    if (selectedDate.toString() === 'Invalid Date') {
      this.meetingDateToValError = true;
      return;
    }
    if (this.requestParams.meetingDateFrom) {
      const tempFrom = this.requestParams.meetingDateFrom.split('/');
      const tempFromDate = new Date([tempFrom[1], tempFrom[0], tempFrom[2]].join('/'));
      if (selectedDate < tempFromDate) {
        this.meetingDateToValError = true;
        return;
      }
    }

    this.meetingDateToValError = false;
    this.meetingDateFromMax = selectedDate;
  }

  expiryDateFromValueChange(val: string) {
    if (val === '') {
      this.expiryDateFromValError = false;
      this.expiryDateToMin = new Date('2000/01/01');
      return;
    }
    this.requestParams.expiryDateFrom = val;
    const temp = this.requestParams.expiryDateFrom.split('/');
    const selectedDate = new Date([temp[1], temp[0], temp[2]].join('/'));
    if (selectedDate.toString() === 'Invalid Date') {
      this.expiryDateFromValError = true;
      return;
    }
    if (this.requestParams.expiryDateTo) {
      const tempTo = this.requestParams.expiryDateTo.split('/');
      const tempToDate = new Date([tempTo[1], tempTo[0], tempTo[2]].join('/'));
      if (selectedDate > tempToDate) {
        this.expiryDateFromValError = true;
        return;
      }
    }

    this.expiryDateFromValError = false;
    this.expiryDateToMin = selectedDate;
  }

  expiryDateToValueChange(val: string) {
    if (val === '') {
      this.expiryDateToValError = false;
      this.expiryDateFromMax = new Date('9000/01/01');
      return;
    }
    this.requestParams.expiryDateTo = val;
    const temp = this.requestParams.expiryDateTo.split('/');
    const selectedDate = new Date([temp[1], temp[0], temp[2]].join('/'));
    if (selectedDate.toString() === 'Invalid Date') {
      this.expiryDateToValError = true;
      return;
    }
    if (this.requestParams.expiryDateFrom) {
      const tempFrom = this.requestParams.expiryDateFrom.split('/');
      const tempFromDate = new Date([tempFrom[1], tempFrom[0], tempFrom[2]].join('/'));
      if (selectedDate < tempFromDate) {
        this.expiryDateToValError = true;
        return;
      }
    }

    this.expiryDateToValError = false;
    this.expiryDateFromMax = selectedDate;
  }

  onClickSearch() {
    if (this.isDisableSearch || this.isLoading) {
      return;
    }
    this.requestParams.page = 1;
    this.requestParams.size = 50;
    const params = getFilterParams(this.requestParams);
    params['branchCodes'] = [...this.searchBranchCodeList];
    this.search(params);
    this.onResetTable();
  }

  onClickReset() {
    this.reset();

    const params = getFilterParams(this.requestParams);
    params['branchCodes'] = [...this.searchBranchCodeList];
    this.search(params);
    this.onResetTable();
  }

  private reset() {
    this.expiryDateFromValError = false;
    this.expiryDateToValError = false;
    this.meetingDateFromValError = false;
    this.meetingDateToValError = false;
    this.searchBranchCodeList = [];
    this.abmStatusoptionsOrigin = [];
    this.initRequestParams();
    this.onResetDatePicker();
    this.expiryDateFromMax = new Date('9000/01/01');
    this.expiryDateToMin = new Date('2000/01/01');
    this.meetingDateFromMax = new Date('9000/01/01');
    this.meetingDateToMin = new Date('2000/01/01');
  }

  get isDisableSearch() {
    if (this.isSuperAdmin) {
      return (
        this.requestParams.bookingRefNo === '' &&
        this.requestParams.employerAcctCd === '' &&
        this.requestParams.meetingDateFrom === '' &&
        this.requestParams.meetingDateTo === '' &&
        this.requestParams.expiryDateFrom === '' &&
        this.requestParams.expiryDateTo === '' &&
        this.searchBranchCodeList.length === 0 &&
        this.abmStatusoptionsOrigin.length === 0 &&
        this.requestParams.agentCd === ''
      );
    } else {
      return (
        this.requestParams.bookingRefNo === '' &&
        this.requestParams.employerAcctCd === '' &&
        this.requestParams.meetingDateFrom === '' &&
        this.abmStatusoptionsOrigin.length === 0 &&
        this.requestParams.meetingDateTo === '' &&
        this.requestParams.expiryDateFrom === '' &&
        this.requestParams.expiryDateTo === ''
      );
    }
  }

  addCasePage() {
    this.cdsPopup
      .open(AddNewCaseComponent, {
        size: 'lg',
      })
      .afterClosed()
      .subscribe(({ agree }) => {
        if (agree) {
          this.ngOnInit();
        }
      });
  }

  pageChange(num: number): void {
    if (this.requestParamsForPageChange) {
      this.requestParamsForPageChange.page = num;
      this.search(this.requestParamsForPageChange);
    }
  }

  search(params: any) {
    params['callType'] = this.isSuperAdmin ? 'superAdmin' : 'agent';
    if (this.abmStatusoptionsOrigin.length > 0) {
      params.ABMs = this.abmStatusoptionsOrigin.map(item => item.value);
    }
    this.requestParamsForPageChange = params;
    this.isLoading = true;
    this.cdHttpServeService
      .post<{
        content: WsmCaseSummaryRecordItem[];
        totalElements: number;
        number: number;
        totalPages: number;
      }>('/ext/eb-ssms-sales-journey-service/wsmSummaries', params)
      .subscribe({
        next: res => {
          if (res.result !== 0) {
            this.alert.warning('Warning!', `${res.message}`);
          } else {
            res.data.content = res.data.content || [];
            this.currentPageNumOrigin = res.data.number;
            this.allPageData[this.currentPageIndex] = res.data.content;
            this.totalElements = res.data.totalElements;
            this.totalPages = res.data.totalPages;
          }
          this.isLoading = false;
        },
        error: err => {
          this.alert.error('Error!', err);
          this.isLoading = false;
        },
      });
  }

  private onResetTable() {
    this.resetTable = true;
    this.requestParams.sort = '';
    setTimeout(() => {
      this.resetTable = false;
    }, 0);
  }

  private onResetDatePicker() {
    this.resetDatePicker = true;
    setTimeout(() => {
      this.resetDatePicker = false;
    }, 0);
  }

  onSortChange(event: Sort) {
    if (event.direction === '') {
      this.requestParams.sort = '';
    } else {
      this.requestParams.sort = `${event.active} ${event.direction}`;
    }

    this.requestParams.page = 1;
    this.requestParams.size = 50;
    const params = getFilterParams(this.requestParams);
    this.search(params);
  }
}
