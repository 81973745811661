import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-select-product-type',
  templateUrl: './select-product-type.component.html',
  styleUrls: ['./select-product-type.component.scss'],
})
export class SelectProductTypeComponent implements OnInit {
  constructor(private router: Router) {}

  ngOnInit(): void {}

  goIndividual() {
    this.router.navigate(['/salesjourney/iff-paper-form/individuals']);
  }

  goEmployer() {
    this.router.navigate(['/salesjourney/iff-paper-form/employer']);
  }
}
