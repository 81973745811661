<div class="agent-confirmation">
  <div class="top">
    <div class="logo-wrapper">
      <cds-icon class="logo" icon="logo:Manulife_rgb_HongKong"></cds-icon>
    </div>

    <div class="language-box">
      <app-language-switch></app-language-switch>
    </div>
  </div>

  <div class="form-title-box">
    <span class="cds-h4-demibold">{{ 'agentConfirmation.formTitle' | lang }}</span>
  </div>

  <div *ngIf="!isEr" class="client-info">
    <div>
      <cds-icon class="icon-people" icon="people:login_1"></cds-icon>
    </div>

    <div class="l-ml-5 client-info-right">
      <div>
        <span class="cds-detail2-demibold" style="color: var(--cds-text-disabled)">{{ 'agentConfirmation.clientHKIDPassportNo' | lang }}</span>
      </div>
      <div class="name-field-box">
        <span class="cds-h5-demibold">{{ splitAgentNotificationInfoBody?.hkidOrPassportNo }}</span>
      </div>
      <div class="l-d-f">
        <div>
          <span class="cds-detail2-demibold">{{ 'agentConfirmation.clientContact' | lang }}</span>
        </div>
        <div class="l-ml-2">
          <span class="cds-detail2-demibold">{{ splitAgentNotificationInfoBody?.clientContact }}</span>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="isEr" class="employer-info">
    <div class="l-d-f">
      <div>
        <cds-icon class="icon-office" icon="environment:office_building"></cds-icon>
      </div>

      <div class="l-ml-5 client-info-right">
        <ng-container *ngIf="getCompanyName(splitAgentNotificationInfoBody)">
          <div>
            <span class="cds-detail2-demibold" style="color: var(--cds-text-disabled)">{{ 'erAgentConfirmation.employerName' | lang }}</span>
          </div>
          <div class="name-field-box">
            <span class="cds-h5-demibold">{{ getCompanyName(splitAgentNotificationInfoBody) }}</span>
          </div>
          <div *ngIf="splitAgentNotificationInfoBody?.brNo" class="l-d-f">
            <div>
              <span class="cds-detail2-demibold">{{ 'erAgentConfirmation.bRNumber' | lang }} :</span>
            </div>
            <div class="l-ml-2">
              <span class="cds-detail2-demibold">{{ splitAgentNotificationInfoBody?.brNo }}</span>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="!getCompanyName(splitAgentNotificationInfoBody)">
          <div>
            <span class="cds-detail2-demibold" style="color: var(--cds-text-disabled)">{{ 'erAgentConfirmation.bRNumber' | lang }}</span>
          </div>
          <div class="name-field-box">
            <span class="cds-h5-demibold">{{ splitAgentNotificationInfoBody?.brNo }}</span>
          </div>
        </ng-container>
      </div>
    </div>
  </div>

  <div class="application-info">
    <div class="application-info-title-box">
      <span class="cds-h4-demibold">{{ 'agentConfirmation.applicationInformation' | lang }}</span>
    </div>
    <div class="empf-box">
      <div class="left">
        <span *ngIf="!isEr" class="cds-detail2-demibold" style="color: var(--cds-text-disabled)">{{ 'agentConfirmation.eMPFRef' | lang }}</span>
        <span *ngIf="isEr" class="cds-detail2-demibold" style="color: var(--cds-text-disabled)">{{ 'erAgentConfirmation.eMPFReferenceNo' | lang }}</span>
      </div>
      <div class="right">
        <span class="cds-body2-demibold">{{ splitAgentNotificationInfoBody?.empfRefNo }}</span>
      </div>
    </div>
    <div class="servicing-agent-box" *ngFor="let agent of agentInfos; let i = index">
      <div class="left">
        <span *ngIf="i === 0" class="cds-detail2-demibold" style="color: var(--cds-text-disabled)"
          >{{ getNumOfServicingAgent(i) }}{{ 'agentConfirmation.servicingAgentInfo' | lang }}</span
        >
      </div>
      <div class="right l-d-f">
        <div>
          <span class="cds-body2-demibold">{{ agent.agentCd }}</span>
        </div>
        <div class="l-ml-3">
          <span class="cds-body2-demibold">{{ getAgentName(agent) }}</span>
        </div>
      </div>
    </div>
  </div>

  <div class="declaration-contents-box">
    <div>
      <span class="cds-h4-demibold">{{ 'agentConfirmation.MPFIntermediaryStatement' | lang }}</span>
    </div>
    <div class="cds-body1 l-mb-0">
      <div class="l-mt-5">
        <span>{{ 'agentConfirmation.declarationContents1' | lang }}</span>
      </div>
      <div class="l-mt-5">
        <span class="cds-body1-demibold">{{ 'agentConfirmation.declarationContents2' | lang }}</span>
      </div>
      <div class="l-mt-5">
        <span>{{ 'agentConfirmation.iHave1' | lang }}</span>
      </div>
      <div class="l-d-f">
        <div>
          <span>a) </span>
        </div>
        <div class="l-ml-1">
          <span>{{ 'agentConfirmation.declarationContents3' | lang }}</span>
        </div>
      </div>
      <div class="l-d-f">
        <div>
          <span>b) </span>
        </div>
        <div class="l-ml-1">
          <span>{{ 'agentConfirmation.declarationContents4' | lang }}</span>
        </div>
      </div>
      <div class="l-d-f">
        <div>
          <span>c) </span>
        </div>
        <div class="l-ml-1">
          <span>{{ 'agentConfirmation.declarationContents5' | lang }}</span>
        </div>
      </div>
      <div class="l-d-f">
        <div>
          <span>d) </span>
        </div>
        <div class="l-ml-1">
          <span>{{ 'agentConfirmation.declarationContents6' | lang }}</span>
        </div>
      </div>
      <div class="l-mt-5">
        <span class="cds-body1-demibold">{{ 'agentConfirmation.declarationContents7' | lang }}</span>
      </div>
      <div class="l-mt-5">
        <span>{{ 'agentConfirmation.iHave1' | lang }}</span>
      </div>
      <div class="l-d-f">
        <div>
          <span>e) </span>
        </div>
        <div class="l-ml-1">
          <span>{{ 'agentConfirmation.declarationContents8' | lang }}</span>
        </div>
      </div>
      <div class="l-d-f">
        <div>
          <span>f) </span>
        </div>
        <div class="l-ml-1">
          <span>{{ 'agentConfirmation.declarationContents9' | lang }}</span>
        </div>
      </div>
      <div class="l-mt-5">
        <span class="cds-body1-demibold">{{ 'agentConfirmation.declarationContents10' | lang }}</span>
      </div>
      <div class="l-mt-5">
        <span>{{ 'agentConfirmation.iHave2' | lang }}</span>
      </div>
      <div class="l-d-f">
        <div>
          <span>g) </span>
        </div>
        <div class="l-ml-1">
          <span>{{ 'agentConfirmation.declarationContents11' | lang }}</span>
        </div>
      </div>
      <div class="l-d-f">
        <div>
          <span>h) </span>
        </div>
        <div class="l-ml-1">
          <span>{{ 'agentConfirmation.declarationContents12' | lang }}</span>
        </div>
      </div>
      <div class="l-d-f">
        <div>
          <span>i) </span>
        </div>
        <div class="l-ml-1">
          <span>{{ 'agentConfirmation.declarationContents13' | lang }}</span>
        </div>
      </div>
      <div class="l-mt-5">
        <span>{{ 'agentConfirmation.declarationContents14' | lang }}</span>
      </div>
      <div class="l-mt-5">
        <span>{{ 'agentConfirmation.declarationContents15' | lang }}</span>
      </div>
      <div class="l-d-f">
        <div>
          <span>a) </span>
        </div>
        <div class="l-ml-1">
          <span>{{ 'agentConfirmation.declarationContents16' | lang }}</span>
        </div>
      </div>
      <div class="l-d-f">
        <div>
          <span>b) </span>
        </div>
        <div class="l-ml-1">
          <span>{{ 'agentConfirmation.declarationContents17' | lang }}</span>
        </div>
      </div>
      <div class="l-d-f">
        <div>
          <span>c) </span>
        </div>
        <div class="l-ml-1">
          <span>{{ 'agentConfirmation.declarationContents18' | lang }}</span>
        </div>
      </div>
      <div class="l-d-f">
        <div>
          <span>d) </span>
        </div>
        <div class="l-ml-1">
          <span>{{ 'agentConfirmation.declarationContents19' | lang }}</span>
        </div>
      </div>
      <div class="l-mt-5">
        <span>{{ 'agentConfirmation.declarationContents20' | lang }}</span>
      </div>
      <div>
        <span>{{ 'agentConfirmation.declarationContents21' | lang }}</span>
      </div>
      <div>
        <span>{{ 'agentConfirmation.declarationContents22' | lang }}</span>
      </div>
      <div class="l-mt-7 l-d-f l-ai-cen">
        <cds-checkbox [checked]="isUnderstood" (change)="isUnderstood = !isUnderstood"></cds-checkbox>
        <span *ngIf="!isEr" class="l-ml-4">{{ 'agentConfirmation.readAndUnderstood' | lang }}</span>
        <span *ngIf="isEr" class="l-ml-4">{{ 'erAgentConfirmation.readAndUnderstood' | lang }}</span>
      </div>
    </div>
  </div>

  <div class="l-mt-7">
    <cds-button (click)="onSubmission()" [style]="'primary'" size="md">{{ 'confirmation.submit' | lang }}</cds-button>
  </div>
</div>
