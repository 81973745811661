/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

import { CdsButtonConfig } from '@cds/ng-core/button';
import { BTN_CONFIG_SECONDARY } from 'src/app/config/btn.config';

import { CdsPopupService, MatDialogRef } from '@cds/ng-web-components/popup';
import { UserAgreementPopupComponent } from '../user-agreement-popup.component';

import { deepCopy } from 'src/app/utils/copy';
import { toISODate, fromISOMonthYear, diffMonths, getMonthFirstByMonthYear, getMonthLastByMonthYear, getMonthLastByNow } from 'src/app/utils/dateUtils';
import { CommissionPoolService } from 'src/app/core/services/commission/commission-pool.service';
import { CommissionPoolScaleService } from 'src/app/core/services/commission/commission-pool-scale.service';
import { CommissionRelatedPartiesService } from 'src/app/core/services/commission/commission-related-parties.service';
import { CommissionAgentService } from 'src/app/core/services/commission/commission-agent.service';

import { CommissionCommonService } from 'src/app/core/services/commission/commission-common.service';
import { AgentChannel } from 'src/app/core/models/enum/agent-channel.enum';
import { PermissionItem, PermissionAccess } from 'src/app/core/models/enum/permission.enum';
import { COMMISSION_POOL_TAB, texts } from './pool-info.model';
import { AccumulationType } from 'src/app/core/models/enum/accumulation-type.enum';
import { str2Obj } from 'src/app/utils/utils';
import Big from 'big.js';
import { CommissionPoolStatus } from 'src/app/core/models/enum';

@Component({
  selector: 'app-commission-pool-info',
  templateUrl: './pool-info.component.html',
  styleUrls: ['./pool-info.component.scss'],
})
export class CommissionPoolInfoComponent implements OnInit, OnDestroy {
  btnCfg: CdsButtonConfig = BTN_CONFIG_SECONDARY;

  tab = COMMISSION_POOL_TAB;

  role = PermissionAccess.W;
  permission = PermissionItem.COMM_INFO_MAIN_COMM_SUMMARY;

  subscription: Subscription;
  subscription2: Subscription;
  subscription3: Subscription;

  commissionPoolId = '';

  constructor(
    private cdsPopup: CdsPopupService,
    private router: Router,
    private route: ActivatedRoute,
    private poolService: CommissionPoolService,
    private poolScaleService: CommissionPoolScaleService,
    private relatedPartiesService: CommissionRelatedPartiesService,
    private commonService: CommissionCommonService,
    private agentService: CommissionAgentService
  ) {
    this.subscription = commonService.relatedParties$.subscribe(() => {
      this.getPoolInfo();
    });
    this.subscription2 = commonService.scale$.subscribe(() => {
      this.getPoolInfo();
    });
    this.subscription3 = commonService.applySummary$.subscribe(() => {
      this.commonService.announceSummary(this.summary);
    });
  }

  ngOnInit(): void {
    const tabType = this.route.snapshot.queryParams['tabType'];
    this.commissionPoolId = this.route.snapshot.queryParams['commissionPoolId'];
    if (tabType) {
      this.tabClick({ value: tabType });
    }
    if (!this.commissionPoolId) {
      this.backLanding();
    }
    this.getPoolInfo();
  }

  getPoolInfo() {
    this.editStatus = false;
    this.poolService.getById(this.commissionPoolId).subscribe(resp => {
      if (resp && resp.data && resp.result == 0) {
        this.summary = resp.data;
        this.commonService.announceSummary(this.summary);
      }
      this.loading = false;
    });
  }

  editStatus = false;
  saveDisabled = true;
  loading = true;
  disableInput = false;
  isConfirm = false;

  fcMonthError = '';
  fcMonthNotEdit = '';
  frozenError = '';
  wmBonusError = '';

  summary = {
    id: '',
    commencementDate: '',
    firstContributionMonth: '',
    nextCommYearStart: '',
    caseCount: '',
    frozen: true,
    waiveMonthlyBonus: true,
    oneStPc: '',
    reconHitDate: '',
    mainParty: '',
    subscriptionFee: '',
  };
  summaryForm: any = {};

  edit() {
    this.summaryForm = deepCopy(this.summary);
    this.summaryForm.firstContributionMonth = fromISOMonthYear(this.summaryForm.firstContributionMonth);
    this.fcMonthError = '';
    this.fcMonthNotEdit = '';
    this.frozenError = '';
    this.wmBonusError = '';
    this.disableInput = false;
    this.editStatus = true;
    this.isConfirm = false;
    this.saveDisabled = true;
    this.loading = true;
    this.poolScaleService.getScaleRecordCurrentInfo(this.commissionPoolId).subscribe(res => {
      this.loading = false;
      if (res) {
        const resp = str2Obj(res.body, ['amount']);
        if (resp && resp.result == 0 && resp.data && resp.data.commissionPoolScaleAccumulations) {
          const accumulations: any[] = resp.data.commissionPoolScaleAccumulations;
          accumulations.forEach(accumulation => {
            if (accumulation) {
              const type = accumulation.scaleAccumulationType;
              const amount = new Big(accumulation.amount);
              const zero = new Big(0);
              if (type == AccumulationType.PREMIUM_PTD && !amount.eq(zero)) {
                this.disableInput = true;
                this.fcMonthNotEdit = texts.notEditReleased;
              }
            }
          });
        }
      }
      if (!this.disableInput) {
        this.loading = true;
        this.relatedPartiesService.typesValidate(this.commissionPoolId, 'fcMonthNotEdit').then(res => {
          this.loading = false;
          this.editStatus = true;
          if (!res.validate) {
            this.disableInput = true;
            this.fcMonthNotEdit = res.msg;
          }
        });
      }
    });
  }

  monthYearReg = /(^(1)[0-2]{1}\/(20)(\d){2})|(^(0)[1-9]{1}\/(20)(\d){2})$/;
  input(event: any) {
    this.fcMonthError = '';
    const obj = <HTMLInputElement>document.getElementById('firstContributionMonth');
    if (obj) {
      const value = event.target.value.match(
        /(^(1)[0-2]{1}\/(20)(\d){1,2})|(^(0)[1-9]{1}\/(20)(\d){1,2})|(^(1)[0-2]{1}\/(2)?(0)?)|(^(0)[1-9]{1}\/(2)?(0)?)|(^(0)[1-9]{1})|(^(1)[0-2]{1})|(^[0-1]{1})/g
      );
      obj.value = value ? value : '';
      this.summaryForm.firstContributionMonth = String(obj.value);
      const pos = event.target.selectionEnd;
      event.target.setSelectionRange(pos, pos);
      if (this.monthYearReg.test(value)) {
        this.change('fcMonth');
      } else {
        this.saveDisabled = true;
      }
    }
  }

  change(field?: string) {
    switch (field) {
      case 'fcMonth':
        if (!this.disableInput && !this.monthYearReg.test(this.summaryForm.firstContributionMonth)) {
          this.fcMonthError = texts.monthYearFormat;
        } else if (!this.disableInput && this.summaryForm.firstContributionMonth) {
          const obj = diffMonths(this.summaryForm.commencementDate, getMonthLastByMonthYear(this.summaryForm.firstContributionMonth));
          const obj2 = diffMonths(getMonthLastByNow(), getMonthLastByMonthYear(this.summaryForm.firstContributionMonth));
          if (obj && obj.months >= 7) {
            this.fcMonthError = texts.fcMonthLaterSix;
          } else if (obj && (obj.months < 0 || obj.days < 0)) {
            this.fcMonthError = texts.notEditBackDay;
          } else if (obj2 && obj2.months < 0) {
            this.fcMonthError = texts.notEditBackDay;
          }
        }
        break;
      case 'frozen':
        this.frozenError = '';
        if (this.summaryForm.frozen) {
          this.loading = true;
          this.relatedPartiesService.typesValidate(this.commissionPoolId, 'frozen').then(res => {
            this.loading = false;
            if (!res.validate) {
              this.frozenError = res.msg;
            }
            this.changeDisable();
          });
        }
        break;
      case 'wmBonus':
        this.wmBonusError = '';
        if (this.summaryForm.waiveMonthlyBonus) {
          this.loading = true;
          this.relatedPartiesService.typesValidate(this.commissionPoolId, 'wmBonus').then(res => {
            if (!res.validate) {
              this.loading = false;
              this.wmBonusError = res.msg;
              this.changeDisable();
            } else {
              this.agentBroker();
            }
          });
        }
        break;
    }
    this.changeDisable();
  }

  agentBroker() {
    this.agentService.listRegular(this.commissionPoolId).then(res => {
      const resp: any = res;
      if (resp && resp.recordList) {
        const recordList: any[] = resp.recordList;
        recordList.forEach(item => {
          if (item.status == CommissionPoolStatus.CURRENT || item.status == CommissionPoolStatus.FUTURE) {
            const param = {
              id: item.id,
              commissionPoolId: this.commissionPoolId,
            };
            this.agentService.getRegularItems(param).subscribe(res2 => {
              this.loading = false;
              if (res2 && res2.result == 0 && res2.data.content) {
                const agentItems: any[] = res2.data.content;
                agentItems.forEach(agent => {
                  if (agent.type != AgentChannel.BROKER && agent.type != AgentChannel.IA_BROKER) {
                    this.wmBonusError = 'Claw Back / Waive Bonus is Y, but commission agent isn’t broker channel';
                  }
                });
              }
              this.changeDisable();
            });
          }
        });
      }
    });
  }

  changeDisable() {
    const summary = deepCopy(this.summary);
    summary.firstContributionMonth = fromISOMonthYear(this.summary.firstContributionMonth);
    if (!this.fcMonthError && !this.frozenError && !this.wmBonusError && JSON.stringify(summary) != JSON.stringify(this.summaryForm)) {
      this.saveDisabled = false;
    } else {
      this.saveDisabled = true;
    }
  }

  cancelEdit() {
    const popupRef: MatDialogRef<UserAgreementPopupComponent> = this.cdsPopup.open(UserAgreementPopupComponent, {
      data: {
        message: texts.cancelMsg,
        cancel: texts.cancel,
        continue: texts.continue,
        type: 'confirm',
      },
    });
    popupRef.afterClosed().subscribe(confirm => {
      if (confirm && confirm.agree) {
        this.summaryForm = deepCopy(this.summary);
        this.summaryForm.firstContributionMonth = fromISOMonthYear(this.summaryForm.firstContributionMonth);
        this.editStatus = false;
        this.disableInput = false;
        this.fcMonthError = '';
      }
    });
  }

  reset() {
    this.summaryForm = deepCopy(this.summary);
    this.summaryForm.firstContributionMonth = fromISOMonthYear(this.summaryForm.firstContributionMonth);
    this.fcMonthError = '';
    this.frozenError = '';
    this.wmBonusError = '';
    this.change();
  }

  save() {
    if (this.saveDisabled) {
      return;
    }
    this.summaryForm.firstContributionMonth = toISODate(getMonthFirstByMonthYear(this.summaryForm.firstContributionMonth));
    this.isConfirm = true;
  }

  backToEdit() {
    this.summaryForm.firstContributionMonth = fromISOMonthYear(this.summaryForm.firstContributionMonth);
    this.editStatus = true;
    this.isConfirm = false;
    this.change();
  }

  confirm() {
    this.loading = true;
    const params: any = {
      frozen: this.summaryForm.frozen,
      waiveMonthlyBonus: this.summaryForm.waiveMonthlyBonus,
    };
    if (this.summaryForm.firstContributionMonth != this.summary.firstContributionMonth) {
      params.firstContributionMonth = this.summaryForm.firstContributionMonth;
    }
    this.poolService.saveOrUpdate(this.summaryForm.id, params).subscribe(resp => {
      this.loading = false;
      let popData, result: number;
      if (resp && resp.result == 0) {
        popData = {
          message: texts.alertSuccessMsg,
          continue: texts.alertSuccessContinue,
          type: 'alert',
        };
        result = resp.result;
      } else {
        popData = {
          message: texts.alertFailedMsg,
          continue: texts.alertFailedContinue,
          type: 'alert',
          params: { respMsg: resp.message },
        };
        result = resp.result;
      }
      const popupRef: MatDialogRef<UserAgreementPopupComponent> = this.cdsPopup.open(UserAgreementPopupComponent, { data: popData });
      popupRef.afterClosed().subscribe(confirm => {
        if (confirm && confirm.agree) {
          if (result == 0) {
            this.editStatus = false;
            this.getPoolInfo();
          }
        }
      });
    });
  }

  tabClick(i: any) {
    this.tab.forEach(j => {
      const item: any = j;
      if (item.value == i.value) {
        item.selected = true;
      } else {
        item.selected = false;
      }
    });
  }

  backLanding() {
    this.router.navigate(['/commission/pool-list']);
  }

  ngOnDestroy() {
    // prevent memory leak when component destroyed
    this.subscription.unsubscribe();
    this.subscription2.unsubscribe();
    this.subscription3.unsubscribe();
  }
}
