<cds-popup class="popup no-close-icon">
  <cds-popup-title>
    <div *ngIf="!data.isEdit">Update Completed Activity</div>
    <div *ngIf="data.isEdit">Edit Completed Activity Record</div>
    <div class="close-icon" (click)="close()">
      <cds-icon icon="action:cross"></cds-icon>
    </div>
  </cds-popup-title>

  <cds-popup-content class="relative">
    <app-loading *ngIf="isLoading" [overlayInPopup]="true"></app-loading>

    <form [formGroup]="form" class="row l-plr-0">
      <cds-dropdown
        class="col-xs-6"
        formControlName="activityType"
        [config]="activityTypeDropdownConfig"
        (change)="activityTypeChange($event)"
        label="Payment Reference Number"></cds-dropdown>

      <ng-container>
        <app-dynamic-input-autocomplete
          label="Activity Description"
          class="col-xs-6 description-control"
          placeholder="Input Activity Description"
          [requestUrl]="requestUrl"
          [requestParams]="requestParams"
          [initLoad]="true"
          optionKey="description"
          [showDropdownButton]="true"
          (selectChange)="selectChange($event)"
          [labelRenderFn]="labelRenderFn"
          formControlName="description"></app-dynamic-input-autocomplete>
      </ng-container>

      <div class="col-xs-12">
        <cds-table [dataSource]="dataSource" class="table" [class.full]="true">
          <!-- Activity Code -->
          <ng-container cdsColumnDef="activityCode">
            <cds-header-cell *cdsHeaderCellDef> Activity Code </cds-header-cell>
            <cds-cell *cdsCellDef="let element">
              <div class="line-break">
                {{ element.activityCode }}
              </div>
            </cds-cell>
          </ng-container>

          <!-- Description -->
          <ng-container cdsColumnDef="description">
            <cds-header-cell *cdsHeaderCellDef> Description </cds-header-cell>
            <cds-cell *cdsCellDef="let element">
              <div class="line-break">
                <div>
                  <div>
                    {{ element.description }}
                  </div>
                  <div *ngIf="element.description" class="cds-detail2">
                    Last Submission Date (DD/MM/YYYY):
                    {{ element.createdAt | date : 'dd/MM/yyyy' }}
                  </div>
                </div>
              </div>
            </cds-cell>
          </ng-container>

          <!-- Score -->
          <ng-container cdsColumnDef="score">
            <cds-header-cell *cdsHeaderCellDef> Score </cds-header-cell>
            <cds-cell *cdsCellDef="let element">
              <div class="line-break">
                {{ element.score }}
              </div>
            </cds-cell>
          </ng-container>

          <cds-header-row *cdsHeaderRowDef="displayedColumns"></cds-header-row>
          <cds-row class="cds-body1" *cdsRowDef="let row; columns: displayedColumns"></cds-row>
        </cds-table>
      </div>

      <div *ngIf="showDatePicker" class="col-xs-12 col-sm-12 col-md-6 col-xl-3 l-mb-4 l-mt-4">
        <cds-textfield
          formControlName="completionDate"
          class="l-mb-2"
          placeholder="DD/MM/YYYY"
          label="Date of Completion"
          [min]="minTime"
          [max]="nowTime"
          [cdsDatepicker]="picker">
        </cds-textfield>
        <cds-datepicker #picker="cdsDatepicker"></cds-datepicker>
        <ng-container *ngIf="completionDate?.dirty">
          <cds-assistive-text *ngIf="completionDate?.errors?.['required']">Missing Date of Completion. </cds-assistive-text>
          <cds-assistive-text *ngIf="completionDate?.errors?.['dateInvalid']">Invalid Date. </cds-assistive-text>
        </ng-container>
      </div>
    </form>
  </cds-popup-content>

  <cds-popup-actions>
    <cds-button [config]="resetButtonConfig" [disabled]="!form.dirty" (click)="reset()">{{ 'common.reset' | lang }}</cds-button>

    <cds-button [config]="AddButtonConfig" [disabled]="addDisabled || !form.dirty" (click)="add()">{{ data.isEdit ? 'Update' : 'Add' }}</cds-button>
  </cds-popup-actions>
</cds-popup>
