export class AgentEmployerResponse {
  result?: number;
  message?: string;
  data?: AgentEmployerResponseBody;

  constructor(result: number, message: string) {
    this.result = result;
    this.message = message;
  }
}

export interface AgentEmployerResponseBody {
  content: AgentEmployerResponseData[];
  pageable: Pageable;
  last: boolean;
  totalPages: number;
  totalElements: number;
  first: boolean;
  numberOfElements: number;
  size: number;
  number: number;
  empty: boolean;
}

export interface Pageable {
  pageNumber: number;
  pageSize: number;
  offset?: number;
  paged?: boolean;
  unpaged?: boolean;
}

export interface AgentEmployerResponseData {
  isSelected?: boolean;
  employerId: string;
  employerName: string;
  employerNameTC: string;
  employerNo: string;
  employeeAmt: number;
  nonDelegateAmt: number;
  delegateAmt: number;
  agentCode?: string;
  flag: boolean;
  agentCodeCheckResult?: string;
  agentCodeCheckResultTC?: string;
  delegateAgentInfo: EmployerDelegateAgentInfo[];
  toDelegateAgentName?: string;
  toDelegateAgentNameTC?: string;
}

export interface EmployerDelegateAgentInfo {
  agentCode: string;
  name: string;
  nameTC: string;
}
