<div class="row auto-rollover-container">
  <div>
    <div class="col-xs-12 l-pt-7">
      <span class="cds-h2-light">{{ 'list.serach-record' | lang }}</span>
    </div>
    <div class="row no-padding search-container" [formGroup]="form" *ngIf="formReady">
      <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 l-pt-4 l-pb-4">
        <cds-textfield
          formControlName="empfMemberAccountNumber"
          label="eMPF Member Account Number"
          placeholder="Input eMPF Member Account Number"></cds-textfield>

        <div
          *ngIf="empfMemberAccountNumberControl?.invalid && (empfMemberAccountNumberControl?.dirty || empfMemberAccountNumberControl?.touched)"
          class="l-pt-2">
          <cds-assistive-text
            *ngIf="empfMemberAccountNumberControl?.errors?.['idInvalid']"
            class="l-pt-2"
            [showIcon]="false"
            >{{ empfMemberAccountNumberControl?.errors?.['idInvalid'] | lang }}</cds-assistive-text
          >
        </div>
      </div>

      <div *ngIf="showDatePicker" class="row col-xs-12 col-sm-12 col-md-8 col-lg-6 l-pt-4 l-pb-4 l-plr-0 date-picker-area relative">
        <div class="col-xs-6 date-picker">
          <cds-textfield
            formControlName="createStartDate"
            placeholder="DD/MM/YYYY"
            label="Create Date From"
            [max]="getMaxFromDate | fn : createEndDateValue || nowTimeInstr"
            [cdsDatepicker]="startPicker">
          </cds-textfield>
          <cds-datepicker #startPicker="cdsDatepicker"></cds-datepicker>

          <div *ngIf="createStartDateControl?.invalid && (createStartDateControl?.dirty || createStartDateControl?.touched)" class="l-pt-2">
            <cds-assistive-text
              *ngIf="createStartDateControl?.errors?.['dateInvalid'] || createStartDateControl?.errors?.['startLaterThanEnd']"
              class="l-pt-2"
              [showIcon]="false"
              >{{ 'create.dateError' | lang }}</cds-assistive-text
            >
          </div>
        </div>
        <div class="connector">-</div>
        <div class="col-xs-6 date-picker">
          <cds-textfield
            formControlName="createEndDate"
            placeholder="DD/MM/YYYY"
            label="To"
            [min]="getMinToDate | fn : createStartDateValue"
            [max]="maxTime"
            [cdsDatepicker]="endPicker">
          </cds-textfield>
          <cds-datepicker #endPicker="cdsDatepicker"></cds-datepicker>

          <div *ngIf="createEndDateControl?.invalid && (createEndDateControl?.dirty || createEndDateControl?.touched)" class="l-pt-2">
            <cds-assistive-text
              *ngIf="createEndDateControl?.errors?.['dateInvalid'] || createEndDateControl?.errors?.['startLaterThanEnd']"
              class="l-pt-2"
              [showIcon]="false"
              >{{ 'create.dateError' | lang }}</cds-assistive-text
            >
          </div>
        </div>
      </div>
      <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 l-pt-4 l-pb-4">
        <cds-textfield formControlName="hkid" label="Hong Kong ID No." placeholder="Input Hong Kong ID No."></cds-textfield>

        <div *ngIf="hkidControl?.invalid && (hkidControl?.dirty || hkidControl?.touched)" class="l-pt-2">
          <cds-assistive-text
            *ngIf="hkidControl?.errors?.['idInvalid']"
            class="l-pt-2"
            [showIcon]="false"
            >{{ hkidControl?.errors?.['idInvalid'] | lang }}</cds-assistive-text
          >
        </div>
      </div>
      <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 l-pt-4 l-pb-4">
        <cds-textfield formControlName="passport" label="Passport No." placeholder="Input Passport No."></cds-textfield>

        <div *ngIf="passportControl?.invalid && (passportControl?.dirty || passportControl?.touched)" class="l-pt-2">
          <cds-assistive-text
            *ngIf="passportControl?.errors?.['idInvalid']"
            class="l-pt-2"
            [showIcon]="false"
            >{{ passportControl?.errors?.['idInvalid'] | lang }}</cds-assistive-text
          >
        </div>
      </div>

      <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 l-pt-4 l-pb-4">
        <cds-dropdown formControlName="status" [config]="statusConfig"></cds-dropdown>
      </div>

      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 l-pb-4 bottom-xs search-button-container">
        <cds-button
          class="l-mr-4 botton"
          [config]="searchButtonConfig"
          [disabled]="searchButtonDisabled"
          label="{{ 'list.search' | lang }}"
          (click)="search()"></cds-button>
        <cds-button
          class="botton"
          [config]="resetButtonConfig"
          [disabled]="resetButtonDisabled"
          label="{{ 'list.reset' | lang }}"
          (click)="reset()"></cds-button>
      </div>
    </div>
  </div>

  <!-- page data -->
  <div class="col-xs-12 col-md-12 l-pb-3 l-d-f l-jc-sb l-mt-7 l-mb-4">
    <div>
      <div class="cds-h2-light l-ma-0">{{ 'common.recordList' | lang }}</div>
      <div class="cds-detail1-demibold">Total {{ numberOfElements | number }} records</div>
    </div>
  </div>

  <div class="col-xs-12 col-md-12">
    <div class="min-height">
      <app-data-table
        [frontPagination]="false"
        [isLoading]="isLoading"
        [fixed]="false"
        [overlay]="true"
        [pageConfig]="pageConfig"
        [dataSource]="dataSource"
        [columnsConfig]="columnsConfig"
        (reloadTable)="reloadTable($event)">
        <!-- header templates -->
        <ng-template appDtHeaderCellDef="select">
          <div>
            <cds-checkbox
              [checked]="selectAll"
              [indeterminate]="isIndeterminate"
              [disabled]="selectStatus === undefined"
              (change)="handleChange($event)"></cds-checkbox>
          </div>
        </ng-template>
        <ng-template appDtHeaderCellDef="erAccountNo" let-element>
          <div class="min-width">{{ element.title }}</div>
        </ng-template>
        <ng-template appDtHeaderCellDef="empfMemberAccountNumber" let-element>
          <div class="min-width">{{ element.title }}</div>
        </ng-template>

        <!-- body templates -->
        <ng-template appDtBodyCellDef="createdAt" let-element>
          {{ element['createdAt'] | date : 'dd/MM/yyyy' }}
        </ng-template>

        <ng-template appDtBodyCellDef="select" let-element let-num="index">
          <cds-checkbox (change)="trigger(num, $event)" [checked]="element.checked" [disabled]="isDisabled | fn : selectStatus : element.status"></cds-checkbox>
        </ng-template>

        <ng-template appDtBodyCellDef="erAccountNo" let-element>
          <div class="code-column">
            <div>{{ element.erAccountNo }}</div>
          </div>
        </ng-template>

        <ng-template appDtBodyCellDef="empfMemberAccountNumber" let-element>
          <div class="code-column">
            <div>{{ element.empfMemberAccountNumber }}</div>
          </div>
        </ng-template>

        <ng-template appDtBodyCellDef="firstName" let-element
          ><div class="name-column">{{ element.firstName }}</div></ng-template
        >
        <ng-template appDtBodyCellDef="lastName" let-element
          ><div class="name-column">{{ element.lastName }}</div></ng-template
        >
        <ng-template appDtBodyCellDef="hkidNo" let-element>
          <div>
            {{ element.hkidNo }}<span *ngIf="element.hkidCheckDigit">({{ element.hkidCheckDigit }})</span>
          </div>
        </ng-template>
        <ng-template appDtBodyCellDef="passportNumber" let-element
          ><div class="name-column">{{ element.passportNumber }}</div></ng-template
        >
        <ng-template appDtBodyCellDef="paymentReceivedDate" let-element>
          {{ element['paymentReceivedDate'] | date : 'dd/MM/yyyy' }}
        </ng-template>
        <ng-template appDtBodyCellDef="dealingDate" let-element>
          {{ element['dealingDate'] | date : 'dd/MM/yyyy' }}
        </ng-template>
        <!-- <ng-template appDtBodyCellDef="ermc" let-element>
          <div>{{ element.ermc || 0 | number : '1.2-2' }}</div>
        </ng-template>
        <ng-template appDtBodyCellDef="ervc1" let-element>
          <div>{{ element.ervc1 || 0 | number : '1.2-2' }}</div>
        </ng-template>
        <ng-template appDtBodyCellDef="ervc2" let-element>
          <div>{{ element.ervc2 || 0 | number : '1.2-2' }}</div>
        </ng-template>
        <ng-template appDtBodyCellDef="eemc" let-element>
          <div>{{ element.eemc || 0 | number : '1.2-2' }}</div>
        </ng-template>
        <ng-template appDtBodyCellDef="eevc" let-element>
          <div>{{ element.eevc || 0 | number : '1.2-2' }}</div>
        </ng-template>
        <ng-template appDtBodyCellDef="er_ex_orso" let-element>
          <div class="min-content-width">{{ element.er_ex_orso || 0 | number : '1.2-2' }}</div>
        </ng-template>
        <ng-template appDtBodyCellDef="ee_ex_orso" let-element>
          <div class="min-content-width">{{ element.ee_ex_orso || 0 | number : '1.2-2' }}</div>
        </ng-template>
        <ng-template appDtBodyCellDef="former_mc" let-element>
          <div>{{ element.former_mc || 0 | number : '1.2-2' }}</div>
        </ng-template>
        <ng-template appDtBodyCellDef="former_vc" let-element>
          <div>{{ element.former_vc || 0 | number : '1.2-2' }}</div>
        </ng-template>
        <ng-template appDtBodyCellDef="paymentAmount" let-element>
          <div>HKD {{ element.paymentAmount || 0 | number : '1.2-2' }}</div>
        </ng-template> -->
      </app-data-table>
    </div>
  </div>
</div>

<div class="operation-bar" *ngIf="selectStatus !== undefined && selectData.length > 0" [style.right]="scrollBarWidth" [style.left]="isMobile ? 0 : '80px'">
  <div class="cds-h4 l-mb-0" *ngIf="!isMobile">
    Please select record(s) to
    <span *ngIf="selectStatus === AutoRolloverStatsE.READY">hold</span><span *ngIf="selectStatus === AutoRolloverStatsE.HOLD">unhold</span>
  </div>
  <div class="button-area">
    <div class="cds-h5 l-mb-0 font-weight-6 l-mr-6">
      <span>{{ selectData.length }}</span> item(s) selected
    </div>
    <cds-button *ngIf="selectStatus === AutoRolloverStatsE.READY" (click)="changeStatus(true)" [disabled]="isLoading">Hold</cds-button>
    <cds-button *ngIf="selectStatus === AutoRolloverStatsE.HOLD" (click)="changeStatus(false)" [disabled]="isLoading">Unhold</cds-button>
  </div>
</div>
