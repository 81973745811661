import { Component, Inject, OnInit } from '@angular/core';
import { CdsSize, CdsStyle } from '@cds/ng-core/configuration';
import { CDS_POPUP_DATA } from '@cds/ng-web-components/popup';

interface CdWarningPopupButton {
  style: CdsStyle;
  size: CdsSize;
  text: string;
  cb: () => void;
}

@Component({
  selector: 'app-simple-popup',
  templateUrl: './simple-popup.component.html',
  styleUrls: ['./simple-popup.component.scss'],
})
export class SimplePopupComponent implements OnInit {
  constructor(
    @Inject(CDS_POPUP_DATA)
    public data: {
      message: string;
      buttons: CdWarningPopupButton[];
    }
  ) {}

  ngOnInit(): void {}
}
