import { RouterModule, Routes } from '@angular/router';
import { AdminLayoutComponent } from '../layout/admin-layout/admin-layout.component';
import { NgModule } from '@angular/core';
import { UploadCenterComponent } from './upload-center/upload-center.component';
import { PermissionAccess, PermissionItem } from 'src/app/core/models/enum';

const routes: Routes = [
  {
    path: 'upload-center',
    component: AdminLayoutComponent,
    children: [
      {
        path: '',
        component: UploadCenterComponent,
        data: {
          roles: {
            [PermissionItem.SALES_JOURNEY_SALES_SUPPORT]: PermissionAccess.R,
          },
        },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class UploadCenterRoutingModule {}
