import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { CdsToastService } from '@cds/ng-web-components/toast';
import { EEProfileOthersResponse } from '../ee-modules/EEProfileOthers.module';
import { EmployeeService } from '../employee.service';

@Component({
  selector: 'app-ee-profile-others',
  templateUrl: './ee-profile-others.component.html',
  styleUrls: ['./ee-profile-others.component.scss'],
})
export class EeProfileOthersComponent implements OnInit, OnChanges {
  @Input() trusteeId: string | undefined = '';
  @Input() entityType: string | undefined = '';
  othersInfo: EEProfileOthersResponse = {};
  constructor(private service: EmployeeService, private toast: CdsToastService) {}

  ngOnInit(): void {
    if (this.trusteeId && this.entityType) {
      this.getOthersInfo(this.trusteeId, this.entityType);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    if (changes['trusteeId'] !== undefined) {
      this.trusteeId = changes['trusteeId'].currentValue;
    }
    if (changes['entityType'] !== undefined) {
      this.entityType = changes['entityType'].currentValue;
    }
    if (this.trusteeId && this.entityType) {
      this.getOthersInfo(this.trusteeId, this.entityType);
    }
  }

  getOthersInfo(trusteeId: string, entityType: string) {
    this.service.getOthersInfo(trusteeId, entityType).subscribe(res => {
      if (res.result === 0 && res.data) {
        this.othersInfo = res.data;
      } else {
        this.toast.error('getById API error: ' + res.message);
      }
    });
  }
}
