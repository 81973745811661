import { CdsOption } from '@cds/ng-core/configuration';
import { IPageableRequest } from 'src/app/core/models/request';
import { CdMultidropdownTopping } from 'src/app/shared/cd-multidropdown/cd-multidropdown.model';
import { YesOrNoFlagNumberE } from '../employee/employee';

export interface CampaignSearchRequest extends IPageableRequest {
  campaignName?: string;
  campaignPeriodFrom?: string;
  campaignPeriodTo?: string;
  campaignOwner?: string;
  campaignType?: CampaignTypeE;
  pageChannel?: string;
  md5?: string;
}

export interface Campaign {
  id?: string;
  campaignId?: string;
  campaignName?: string;
  campaignPeriodFrom?: string;
  campaignPeriodTo?: string;
  campaignOwner?: string;
  campaignType?: CampaignTypeE;
  campaignDescription?: string;
  productTypes?: ProductTypeE[] | CdMultidropdownTopping[] | string;
  multipleAgentDisplay?: YesOrNoFlagNumberE;
  displayToServicingAgent?: YesOrNoFlagNumberE;
  agentCommunication?: AgentCommunicationE[] | CdMultidropdownTopping[] | string;
  workbasket?: string;
  agentSam?: string;
  customerCommunicationChannel?: string;
  customerPreferenceLanguage?: string;
  memberAccountCode?: string;
  policyType?: string;
  agentCommunicationChannel?: string;
  assignedAgent?: string;
  messageDelivered?: string;
  opened?: string;
  customerFirstResponseDate?: string;
  customerLastResponseDate?: string;
  isEngaged?: YesOrNoFlagNumberE;
}

export interface CustomerListFileCheckRes {
  messageType?: CustomerListUploadErrorMessageTypeE;
  campaignErrorInfoList?: CampaignErrorInfo[];
  campaignId?: string;
}

export interface CampaignErrorInfo {
  serialNumber?: number;
  campaignName?: string;
  memberAccountCode?: string;
}

export interface CustomerListCheckResult {
  row: number;
  message: string;
}

export enum CampaignTypeE {
  SALES = 0,
  SERVICE = 1,
  SURVEY = 2,
  DIGITAL = 3,
  STRENGTHEN_RELATIONSHIP = 4,
  OTHERS = 5,
}

export const CampaignTypeOptions: CdsOption[] = [
  {
    label: 'Sales',
    value: CampaignTypeE.SALES,
  },
  {
    label: 'Service',
    value: CampaignTypeE.SERVICE,
  },
  {
    label: 'Survey',
    value: CampaignTypeE.SURVEY,
  },
  {
    label: 'Digital',
    value: CampaignTypeE.DIGITAL,
  },
  {
    label: 'Strengthen Relationship',
    value: CampaignTypeE.STRENGTHEN_RELATIONSHIP,
  },
  {
    label: 'Others',
    value: CampaignTypeE.OTHERS,
  },
];

export enum ProductTypeE {
  MPF = 0,
  ORSO = 1,
  MACAU = 2,
}

export const productTypeOptions: CdMultidropdownTopping[] = [
  {
    label: 'MPF',
    value: ProductTypeE.MPF,
  },
  {
    label: 'ORSO',
    value: ProductTypeE.ORSO,
  },
  {
    label: 'Macau',
    value: ProductTypeE.MACAU,
  },
];

export enum AgentCommunicationE {
  WORKBASKET = 0,
  ANS = 1,
  WHATS_APP = 2,
  EMAIL = 3,
  AGENT_SAM = 4,
  NA = 5,
}

export const agentCommunicationOptions: CdMultidropdownTopping[] = [
  {
    label: 'Workbasket',
    value: AgentCommunicationE.WORKBASKET,
  },
  {
    label: 'ANS',
    value: AgentCommunicationE.ANS,
  },
  {
    label: 'WhatsApp',
    value: AgentCommunicationE.WHATS_APP,
  },
  {
    label: 'Email',
    value: AgentCommunicationE.EMAIL,
  },
  {
    label: 'Agent SAM',
    value: AgentCommunicationE.AGENT_SAM,
  },
  {
    label: 'N/A',
    value: AgentCommunicationE.NA,
  },
];

export enum CustomerListUploadErrorMessageTypeE {
  INCORRECT_NAME = 1,
  ONLY_EXCEL = 2,
  WRONG_MEMBER_ACCOUNT_CODE = 3,
  MISSING_MEMBER_ACCOUNT_CODE = 4,
  WRONG_CAMPAIGN_ID = 5,
  MISSIONG_CAMPAIGN_ID = 6,
}

export const customerListUploadErrorMessageTypeMap = new Map([
  [CustomerListUploadErrorMessageTypeE.INCORRECT_NAME, 'campaign.incorrect-file-name'],
  [CustomerListUploadErrorMessageTypeE.ONLY_EXCEL, 'campaign.only-excel'],
  [CustomerListUploadErrorMessageTypeE.WRONG_MEMBER_ACCOUNT_CODE, 'campaign.wrong-member-account-code'],
  [CustomerListUploadErrorMessageTypeE.MISSING_MEMBER_ACCOUNT_CODE, 'campaign.missiong-member-account-code'],
  [CustomerListUploadErrorMessageTypeE.WRONG_CAMPAIGN_ID, 'campaign.wrong-campaign-id'],
  [CustomerListUploadErrorMessageTypeE.MISSIONG_CAMPAIGN_ID, 'campaign.upload-missing-campaign-id'],
]);
