<app-loading [overlay]="true" *ngIf="isLoading"></app-loading>

<app-cd-accordion>
  <app-cd-accordion-panel title="Contact">
    <div class="col-xs-12 row l-plr-0">
      <div class="col-xs-11 l-plr-0 l-pt-4">
        <div class="row" *ngFor="let key of Object.keys(contactPersonView)"></div>

        <div class="row l-pb-6" *ngIf="(getPhoneOrFaxList | fn : data.phoneList)?.length">
          <div class="icon-title">
            <cds-icon icon="contact:phone" class="icon"></cds-icon>
            <div class="cds-body1-demibold title">Phone</div>
          </div>
          <cds-table [dataSource]="contactSort | fn : (getPhoneOrFaxList | fn : data.phoneList)" [class.full]="true">
            <!-- type -->
            <ng-container cdsColumnDef="type">
              <cds-header-cell *cdsHeaderCellDef></cds-header-cell>
              <cds-cell *cdsCellDef="let element">{{ phoneTypeRender | fn : element.typeCode }}</cds-cell>
            </ng-container>
            <!-- Phone No. -->
            <ng-container cdsColumnDef="number">
              <cds-header-cell *cdsHeaderCellDef>Phone No.</cds-header-cell>
              <cds-cell *cdsCellDef="let element">{{ fullPhoneNoRender | fn : element.countryCode : element.phoneNo }}</cds-cell>
            </ng-container>
            <!-- Ext. -->
            <ng-container cdsColumnDef="ext">
              <cds-header-cell *cdsHeaderCellDef>Ext.</cds-header-cell>
              <cds-cell *cdsCellDef="let element">{{ element.phoneExt }}</cds-cell>
            </ng-container>
            <!-- Last Update Date -->
            <ng-container cdsColumnDef="updateDateAndStatus">
              <cds-header-cell *cdsHeaderCellDef> Last Update Date </cds-header-cell>
              <cds-cell *cdsCellDef="let element">
                <div class="date-and-status">
                  <div class="date">{{ element.lastUpdate | ssmsDate }}</div>
                  <div class="status-area">
                    <div class="dot-icon" [style]="service.getStatusColorStyle | fn : element?.status : inValidStatusMapObj"></div>
                    <div class="cds-body1 text">{{ service.getStatus | fn : element?.status : inValidStatusMapObj }}</div>
                  </div>
                </div>
              </cds-cell>
            </ng-container>
            <cds-header-row *cdsHeaderRowDef="phoneDisplayedColumns"></cds-header-row>
            <cds-row class="cds-body1" *cdsRowDef="let row; columns: phoneDisplayedColumns"></cds-row>
          </cds-table>
        </div>
        <div class="row l-pb-6" *ngIf="data.emailList?.length">
          <div class="icon-title">
            <cds-icon icon="contact:email" class="icon"></cds-icon>
            <div class="cds-body1-demibold title">Email</div>
          </div>
          <cds-table [dataSource]="contactSort | fn : data.emailList" [class.full]="true">
            <!-- Email Address -->
            <ng-container cdsColumnDef="email">
              <cds-header-cell *cdsHeaderCellDef> Email Address </cds-header-cell>
              <cds-cell *cdsCellDef="let element">{{ element.emailAddress }}</cds-cell>
            </ng-container>
            <!-- Last Update Date -->
            <ng-container cdsColumnDef="updateDateAndStatus">
              <cds-header-cell *cdsHeaderCellDef> Last Update Date </cds-header-cell>
              <cds-cell *cdsCellDef="let element">
                <div class="date-and-status">
                  <div class="date">{{ element.lastUpdate | ssmsDate }}</div>
                  <div class="status-area">
                    <div class="dot-icon" [style]="service.getStatusColorStyle | fn : element?.status : inValidStatusMapObj"></div>
                    <div class="cds-body1 text">{{ service.getStatus | fn : element?.status : inValidStatusMapObj }}</div>
                  </div>
                </div>
              </cds-cell>
            </ng-container>
            <!-- Status -->
            <!-- <ng-container cdsColumnDef="status">
              <cds-header-cell *cdsHeaderCellDef></cds-header-cell>
              <cds-cell *cdsCellDef="let element">
                <div class="status-area">
                  <div class="dot-icon" [style]="service.getStatusColorStyle(element.status)"></div>
                  <div class="cds-body1 text">{{ service.getStatus(element.status) }}</div>
                </div>
              </cds-cell>
            </ng-container> -->
            <cds-header-row *cdsHeaderRowDef="emailDisplayedColumns"></cds-header-row>
            <cds-row class="cds-body1" *cdsRowDef="let row; columns: emailDisplayedColumns"></cds-row>
          </cds-table>
        </div>
        <div class="row l-pb-6" *ngIf="(getPhoneOrFaxList | fn : data.phoneList : false)?.length">
          <div class="icon-title">
            <cds-icon icon="device:fax" class="icon"></cds-icon>
            <div class="cds-body1-demibold title">Fax</div>
          </div>
          <cds-table [dataSource]="contactSort | fn : (getPhoneOrFaxList | fn : data.phoneList : false)" [class.full]="true">
            <!-- type -->
            <ng-container cdsColumnDef="type">
              <cds-header-cell *cdsHeaderCellDef></cds-header-cell>
              <cds-cell *cdsCellDef="let element">{{ phoneTypeRender | fn : element.typeCode }}</cds-cell>
            </ng-container>
            <!-- Phone No. -->
            <ng-container cdsColumnDef="number">
              <cds-header-cell *cdsHeaderCellDef>Phone No.</cds-header-cell>
              <cds-cell *cdsCellDef="let element">{{ fullPhoneNoRender | fn : element.countryCode : element.phoneNo }}</cds-cell>
            </ng-container>
            <!-- Ext. -->
            <ng-container cdsColumnDef="ext">
              <cds-header-cell *cdsHeaderCellDef>Ext.</cds-header-cell>
              <cds-cell *cdsCellDef="let element">{{ element.phoneExt }}</cds-cell>
            </ng-container>
            <!-- Last Update Date -->
            <ng-container cdsColumnDef="updateDateAndStatus">
              <cds-header-cell *cdsHeaderCellDef> Last Update Date </cds-header-cell>
              <cds-cell *cdsCellDef="let element">
                <div class="date-and-status">
                  <div class="date">{{ element.lastUpdate | ssmsDate }}</div>
                  <div class="status-area">
                    <div class="dot-icon" [style]="service.getStatusColorStyle | fn : element?.status : inValidStatusMapObj"></div>
                    <div class="cds-body1 text">{{ service.getStatus | fn : element?.status : inValidStatusMapObj }}</div>
                  </div>
                </div>
              </cds-cell>
            </ng-container>
            <cds-header-row *cdsHeaderRowDef="phoneDisplayedColumns"></cds-header-row>
            <cds-row class="cds-body1" *cdsRowDef="let row; columns: phoneDisplayedColumns"></cds-row>
          </cds-table>
        </div>
        <div class="row l-pb-6" *ngIf="data.addressList?.length">
          <div class="icon-title">
            <cds-icon icon="contact:location" class="icon"></cds-icon>
            <div class="cds-body1-demibold title">Address</div>
          </div>
          <cds-table [dataSource]="contactSort | fn : data.addressList" [class.full]="true">
            <!-- Phone No. -->
            <ng-container cdsColumnDef="address">
              <cds-header-cell *cdsHeaderCellDef>Adress</cds-header-cell>
              <cds-cell *cdsCellDef="let element">
                <div class="cds-body1 l-mb-0">
                  <span>{{ addressRender | fn : element }}</span>
                </div>
              </cds-cell>
            </ng-container>
            <!-- type -->
            <ng-container cdsColumnDef="type">
              <cds-header-cell *cdsHeaderCellDef>
                <div class="flex l-ai-cen">
                  <div class="l-mr-2">Type</div>
                  <div class="click" (click)="typePopup()">
                    <cds-icon icon="action:info_1"></cds-icon>
                  </div>
                </div>
              </cds-header-cell>
              <cds-cell *cdsCellDef="let element">{{ element.addressType }}</cds-cell>
            </ng-container>
            <!-- Ext. -->
            <!-- <ng-container cdsColumnDef="ext">
              <cds-header-cell *cdsHeaderCellDef>Ext.</cds-header-cell>
              <cds-cell *cdsCellDef="let element">{{ element.ext }}</cds-cell>
            </ng-container> -->
            <!-- Last Update Date -->
            <ng-container cdsColumnDef="updateDateAndStatus">
              <cds-header-cell *cdsHeaderCellDef> Last Update Date </cds-header-cell>
              <cds-cell *cdsCellDef="let element">
                <div class="date-and-status">
                  <div class="date">{{ element.lastUpdate | ssmsDate }}</div>
                  <div class="status-area">
                    <div class="dot-icon" [style]="service.getStatusColorStyle | fn : element?.status : inValidStatusMapObj"></div>
                    <div class="cds-body1 text">{{ service.getStatus | fn : element?.status : inValidStatusMapObj }}</div>
                  </div>
                </div>
              </cds-cell>
            </ng-container>
            <cds-header-row *cdsHeaderRowDef="addressDisplayedColumns"></cds-header-row>
            <cds-row class="cds-body1" *cdsRowDef="let row; columns: addressDisplayedColumns"></cds-row>
          </cds-table>
        </div>
      </div>

      <div class="col-xs-12">
        <div class="row l-pb-6">
          <div class="icon-title">
            <div class="cds-body1-demibold title">Way of Communication</div>
          </div>
          <app-row-item
            label="er.profile.communication-type"
            [data]="communicationTypeRender | fn : data.communicationType"
            class="col-xs-6 col-sm-3 col-md-3 col-lg-3">
          </app-row-item>
          <app-row-item label="er.profile.language-type" [data]="languageTypeRender | fn : data.languageType" class="col-xs-6 col-sm-3 col-md-3 col-lg-3">
          </app-row-item>
        </div>
      </div>

      <div class="col-xs-12">
        <div class="row l-pb-6">
          <div class="col-xs-12">
            <div class="cds-body1-demibold l-mb-3">Consent</div>
          </div>
          <app-row-item class="col-xs-6 col-sm-3 col-md-3 col-lg-3">
            <ng-template appRowItemLabelDef>
              <div class="flex">
                <cds-checkbox class="l-mr-4" [checked]="data.enotification === 'Y'" [disabled]="true"></cds-checkbox>
                <div class="flex-column">
                  <div class="cds-body1 l-mb-0">{{ 'ee.e-notification' | lang }}</div>
                  {{ data.lastUpdate | ssmsDate }}
                </div>
              </div>
            </ng-template>
          </app-row-item>

          <app-row-item class="col-xs-6 col-sm-3 col-md-3 col-lg-3">
            <ng-template appRowItemLabelDef>
              <div class="flex">
                <cds-checkbox class="l-mr-4" [checked]="data.estatementOptInFlag === 'Y'" [disabled]="true"></cds-checkbox>
                <div class="flex-column">
                  <div class="cds-body1 l-mb-0">{{ 'ee.e-statement-opt-in-flag' | lang }}</div>
                  {{ data.lastUpdate | ssmsDate }}
                </div>
              </div>
            </ng-template>
          </app-row-item>

          <app-row-item class="col-xs-6 col-sm-3 col-md-3 col-lg-3">
            <ng-template appRowItemLabelDef>
              <div class="flex">
                <cds-checkbox class="l-mr-4" [checked]="data.memberAuthorization === 'Y'" [disabled]="true"></cds-checkbox>
                <div class="flex-column">
                  <div class="cds-body1 l-mb-0">{{ 'ee.member-authorization' | lang }}</div>
                  {{ data.memberAuthorizationLastUpdate | ssmsDate }}
                </div>
              </div>
            </ng-template>
          </app-row-item>
        </div>
      </div>
    </div>

    <div class="col-xs-12">
      <div class="row l-pb-6">
        <div class="col-xs-12">
          <div class="cds-body1-demibold l-mb-3">Opt in Channel</div>
          <div class="cds-body2 l-mb-3">Option & Last Update Date (DD/MM/YYYY)</div>
        </div>
        <app-row-item class="col-xs-6 col-sm-3 col-md-3 col-lg-3">
          <ng-template appRowItemLabelDef>
            <div class="flex">
              <cds-checkbox class="l-mr-4" [checked]="data.all === 'Y'" [disabled]="true"></cds-checkbox>
              <div class="flex-column">
                <div class="cds-body1 l-mb-0">{{ 'ee.opt.all' | lang }}</div>
                <div>{{ data.lastUpdate | ssmsDate }}</div>
              </div>
            </div>
          </ng-template>
        </app-row-item>

        <app-row-item class="col-xs-6 col-sm-3 col-md-3 col-lg-3">
          <ng-template appRowItemLabelDef>
            <div class="flex">
              <cds-checkbox class="l-mr-4" [checked]="data.directMailing === 'Y'" [disabled]="true"></cds-checkbox>
              <div class="flex-column">
                <div class="cds-body1 l-mb-0">{{ 'ee.opt.direct-mailing' | lang }}</div>
                <div>{{ data.lastUpdate | ssmsDate }}</div>
              </div>
            </div>
          </ng-template>
        </app-row-item>

        <app-row-item class="col-xs-6 col-sm-3 col-md-3 col-lg-3">
          <ng-template appRowItemLabelDef>
            <div class="flex">
              <cds-checkbox class="l-mr-4" [checked]="data.email === 'Y'" [disabled]="true"></cds-checkbox>
              <div class="flex-column">
                <div class="cds-body1 l-mb-0">{{ 'ee.opt.email' | lang }}</div>
                <div>{{ data.lastUpdate | ssmsDate }}</div>
              </div>
            </div>
          </ng-template>
        </app-row-item>

        <app-row-item class="col-xs-6 col-sm-3 col-md-3 col-lg-3">
          <ng-template appRowItemLabelDef>
            <div class="flex">
              <cds-checkbox class="l-mr-4" [checked]="data.sms === 'Y'" [disabled]="true"></cds-checkbox>
              <div class="flex-column">
                <div class="cds-body1 l-mb-0">{{ 'ee.opt.sms' | lang }}</div>
                <div>{{ data.lastUpdate | ssmsDate }}</div>
              </div>
            </div>
          </ng-template>
        </app-row-item>

        <app-row-item class="col-xs-6 col-sm-3 col-md-3 col-lg-3">
          <ng-template appRowItemLabelDef>
            <div class="flex">
              <cds-checkbox class="l-mr-4" [checked]="data.telemarketing === 'Y'" [disabled]="true"></cds-checkbox>
              <div class="flex-column">
                <div class="cds-body1 l-mb-0">{{ 'ee.opt.telemarkeing' | lang }}</div>
                {{ data.lastUpdate | ssmsDate }}
              </div>
            </div>
          </ng-template>
        </app-row-item>
      </div>
    </div>
  </app-cd-accordion-panel>
</app-cd-accordion>
