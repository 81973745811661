import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, Observable, of, Subject, timeout } from 'rxjs';
import { Injectable } from '@angular/core';
import { ProductTypeResponse } from './producttype-response';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ProductTypeSummaryService {
  searchProducttypeUrl = `${environment.apiPrefix}/ext/eb-ssms-sales-journey-service/productTypeSummaries`;
  producttypeDataSubject = new Subject<ProductTypeResponse>();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  errorproducttypeSubject = new Subject<any>();

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  constructor(private httpClient: HttpClient) {}
  getProducttypeSummary() {
    this.httpClient
      .get(this.searchProducttypeUrl, this.httpOptions)
      .pipe(timeout(5000), catchError(this.handleError<ProductTypeResponse>(new ProductTypeResponse())))
      .subscribe(resp => {
        this.producttypeDataSubject.next(resp);
      });
  }
  handleError<T>(result?: T) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (error: any): Observable<T> => {
      this.errorproducttypeSubject.next(`${error.message}`);
      return of(result as T);
    };
  }
}
