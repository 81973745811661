export class AgentSubmissionRequest {
  empfRefNo?: string;
  productType?: string;
  domainType?: string;
  companyName?: string;
  companyNameChn?: string;
  idType?: '0' | '1';
  hkidOrPassportNo?: string;
  businessRefNo?: string;
  channel?: string;
  countryCallingCode?: string;
  mobileNo?: string;
  emailAddr?: string;
  caseSplit?: boolean;
  agentInfos?: AgentInfo[];
  dbs?: {
    dbsRmReferralCd: string;
    dbsCustomerId: string;
    dbsCampaignCd: string;
    dbsSourceCd: string;
  };
  smsLanguage?: 0 | 1;
  intraGroup?: boolean;
}

export class AgentInfo {
  agentIdentityId?: string;
  agentid?: string;
  regulatedAct?: boolean;
  agentNotificationChannel?: string | null;
  primaryAgent?: boolean;
  smsLanguage?: 0 | 1;
}
