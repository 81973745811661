export class ErChangeSummaryData {
  serialDetailNo?: string;
  employerName?: string;
  language?: string;
  status?: string;
  sumbitAgent?: Agents;
  oldAgent?: Agents;
}

export interface Agents {
  agentNameTC?: string;
  agentName?: string;
  agentCode?: string;
}
