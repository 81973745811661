<cds-popup>
  <cds-popup-content>
    <div class="cds-body-hero">Address Type Definition:</div>

    <app-data-table [isShowPagination]="false" [fixed]="false" [overlay]="true" [dataSource]="dataSource" [columnsConfig]="columnsConfig"> </app-data-table>
  </cds-popup-content>

  <cds-popup-actions>
    <cds-button [config]="buttonConfig" (click)="close()">{{ 'commission.pool.common.alertSuccessContinue' | lang }}</cds-button>
  </cds-popup-actions>
</cds-popup>
