<div class="l-d-f l-ai-cen language-switch">
  <cds-icon icon="general:globe" (click)="toggle()"></cds-icon>
  <cds-icon [icon]="isShowLanguageList ? 'action:button_up' : 'action:down'" (click)="toggle()"></cds-icon>

  <div *ngIf="isShowLanguageList" class="button-list">
    <ng-container *ngFor="let language of languages">
      <span class="cds-detail2-demibold language-text" (click)="changeLang(language.value)">{{ language.label }}</span>
    </ng-container>

    <div class="mask" (click)="isShowLanguageList = false"></div>
  </div>
</div>
