import { PermissionItem } from './permission.enum';

export const enum RolePermissionGroup {
  SYSTEM = 'SYSTEM',
  CUSTOMER = 'CUSTOMER',
  COMMISSION = 'COMMISSION',
  SHK = 'SHK',
  SALES_JOURNEY = 'SALES_JOURNEY',
  CHATBOT = 'CHATBOT',
}

export const RolePermissionGroupList: Map<RolePermissionGroup, Set<PermissionItem>> = new Map([
  [
    RolePermissionGroup.SYSTEM,
    new Set<PermissionItem>([
      PermissionItem.SYS_ROLE_MANAGE,
      PermissionItem.SYS_IDENTITY_AGENT_MANAGE,
      PermissionItem.SYS_IDENTITY_STAFF_MANAGE,
      PermissionItem.SYS_CALENDAR,
      PermissionItem.SYS_CALENDAR_REVIEWER,
      PermissionItem.SYS_CALENDAR_APPROVE,
      PermissionItem.SYS_COMMISSION_DEFAULT_SCALE,
      PermissionItem.SYS_AUDIT_LOG,
    ]),
  ],
  [
    RolePermissionGroup.CUSTOMER,
    new Set<PermissionItem>([
      PermissionItem.CUSTOMER_EMPLOYER,
      PermissionItem.CUSTOMER_EMPLOYER_SQ,
      PermissionItem.CUSTOMER_EMPLOYER_SUBSIDIARY,
      PermissionItem.CUSTOMER_EMPLOYER_ADDITIONAL_INFORMATION,
      PermissionItem.CUSTOMER_EMPLOYER_ACTIVITY_CODE,
      PermissionItem.CUSTOMER_MEMBER,
      PermissionItem.CUSTOMER_MOBILE_SUBSCRIPTION,
      PermissionItem.CUSTOMER_SERVICING_AGENT,
      PermissionItem.CUSTOMER_EMPLOYER_INTERMEDIARY,
      PermissionItem.CUSTOMER_EMPLOYEE,
      PermissionItem.CUSTOMER_EMPLOYEE_INTERMEDIARY,
    ]),
  ],
  [
    RolePermissionGroup.COMMISSION,
    new Set<PermissionItem>([
      PermissionItem.COMMISSION_SCALE_MANAGEMENT,
      PermissionItem.COMMISSION_SCHEME_MASTER_RECORD,
      PermissionItem.COMM_INFO_AGENT_CHANGE,
      PermissionItem.COMM_INFO_AGENT_COMP,
      PermissionItem.COMM_INFO_INDIVIDUAL_TRF,
      PermissionItem.COMM_INFO_MAIN_COMM_SUMMARY,
      PermissionItem.COMM_INFO_MAIN_PARTIES_CONT_ADJ,
      PermissionItem.COMM_INFO_MAIN_PARTIES_OTHERS,
      PermissionItem.COMM_INFO_SCALE_RECORD,
      PermissionItem.COMMISSION_JOURNAL,
      PermissionItem.COMM_HOLD_ON_COMPENSATION_REPORT,
      PermissionItem.COMMISSION_ERROR_LOG,
      PermissionItem.COMM_ADJ_NORMAL,
      PermissionItem.COMM_ADJ_INDIVIDUAL,
      PermissionItem.COMM_ADJ_APPEAL,
    ]),
  ],
  [
    RolePermissionGroup.SHK,
    new Set<PermissionItem>([
      PermissionItem.SHK_AGENT_ASSIGNMENT_AGENT,
      PermissionItem.SHK_AUTO_ROLLOVER_OPS_STAFF,
      PermissionItem.SHK_PAYMENT_ISSUANCE_PAYMENT_ADMIN,
      PermissionItem.SHK_PAYMENT_ISSUANCE_PAYMENT_STAFF,
      PermissionItem.SHK_PAYMENT_ISSUANCE_PAYMENT_MANAGER,
      PermissionItem.SHK_AGENT_ASSIGNMENT_MAPPING,
      PermissionItem.SHK_AGENT_ASSIGNMENT_REPORT,
      PermissionItem.SHK_AGENT_ASSIGNMENT_REPORT_AGENT_HEAD,
      PermissionItem.SHK_AGENT_ASSIGNMENT_REASSIGN,
    ]),
  ],
  [
    RolePermissionGroup.SALES_JOURNEY,
    new Set<PermissionItem>([
      PermissionItem.SALES_JOURNEY_SALES_SUPPORT,
      PermissionItem.SALES_JOURNEY_AGENT,
      PermissionItem.SALES_JOURNEY_SUPER_USER,
      PermissionItem.SALES_JOURNEY_SUPER_ADMIN,
      PermissionItem.SALES_JOURNEY_BRANCH_LEVEL,
    ]),
  ],
  [
    RolePermissionGroup.CHATBOT,
    new Set<PermissionItem>([PermissionItem.CHATBOT_CSO, PermissionItem.CHATBOT_ADMIN, PermissionItem.CHATBOT_SUPERVISOR, PermissionItem.CHATBOT_AGENT]),
  ],
]);
