/* eslint-disable @typescript-eslint/no-explicit-any */
import { Directive, TemplateRef } from '@angular/core';

@Directive({
  selector: '[appRowItemLabelDef]',
})
export class RowItemLabelDefDirective {
  constructor(public templateRef: TemplateRef<any>) {}
}

@Directive({
  selector: '[appRowItemContentDef]',
})
export class RowItemContentDefDirective {
  constructor(public templateRef: TemplateRef<any>) {}
}
