<div class="different-agent-status" appOutsideClose (outsideEvent)="outsideEvent()">
  <div class="content">
    <div>
      <span class="cds-body1-bold">Different Agent Status</span>
    </div>
    <div class="l-d-f l-ai-cen first-light-mt">
      <div class="light" style="background-color: #f49600"></div>
      <div class="l-ml-3">
        <span class="cds-body1-demibold">{{ 'different-agent-status.pending' | lang }}</span>
      </div>
    </div>
    <div class="l-d-f l-ai-cen l-mt-3">
      <div class="light" style="background-color: #00a758"></div>
      <div class="l-ml-3">
        <span class="cds-body1-demibold">{{ 'different-agent-status.submitted' | lang }}</span>
      </div>
    </div>
    <div class="l-d-f l-ai-cen l-mt-3">
      <div class="light" style="background-color: #5e6073"></div>
      <div class="l-ml-3">
        <span class="cds-body1-demibold">{{ 'different-agent-status.expired' | lang }}</span>
      </div>
    </div>
    <div class="l-d-f l-ai-cen l-mt-3">
      <div class="light" style="background-color: #0000c1"></div>
      <div class="l-ml-3">
        <span class="cds-body1-demibold">{{ 'different-agent-status.notRequired' | lang }}</span>
      </div>
    </div>
    <div class="l-d-f l-ai-cen l-mt-3">
      <span class="cds-body1-demibold">N/A</span>
    </div>
  </div>

  <div class="l-d-f l-jc-fe l-mt-4 button-box">
    <cds-button label="Got it" (click)="outsideEvent()" [style]="'primary'" [size]="'sm'"></cds-button>
  </div>
</div>
