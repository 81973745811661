import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors } from '@angular/forms';
import { dateValidator } from 'src/app/shared/validators/validators';

@Directive({
  selector: '[appDateValidator]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: DateValidatorDirective,
      multi: true,
    },
  ],
})
export class DateValidatorDirective {
  /** Error message key */
  @Input('appDateValidator') description = '';
  // DD/MM/YYYY
  @Input('appDateValidatorMinDate') minDate?: string;
  // DD/MM/YYYY
  @Input('appDateValidatorMaxDate') maxDate?: string;

  validate(control: AbstractControl): ValidationErrors | null {
    return this.description ? dateValidator(this.description, this.minDate, this.maxDate)(control) : null;
  }
}
