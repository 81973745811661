<div class="row-span">
  <div class="col-xs-12 div-bg-white l-mt-6 l-pa-8">
    <div class="col-xs-12">
      <div class="cds-h2-light l-mt-0">
        {{ 'role.edit.tab.assignUsers' | lang }}
      </div>
      <div class="cds-detail1-demibold">
        {{ 'role.edit.tab.assignUsers.notes' | lang }}
      </div>
    </div>
    <div class="row-span">
      <div class="col-md-6 col-xs-12 l-pt-5--mobile">
        <cds-search
          [options]="aclUserRespOptions"
          placeholder="{{ 'role.step2.user.assign' | lang }}"
          [formControl]="aclNameFormControl"
          [appDisableFormControl]="aclUserLoading">
        </cds-search>
        <cds-assistive-text class="l-pt-5" *ngIf="aclNameFormControl?.invalid && (aclNameFormControl?.dirty || aclNameFormControl?.touched)">
          <div *ngIf="aclNameFormControl?.errors?.['required']">Minimum 2 characters.</div>
          <div *ngIf="aclNameFormControl?.errors?.['minlength']; else customerAclUserError">Minimum 2 characters.</div>
          <ng-template #customerAclUserError>
            <div *ngIf="aclUserRespOptions.length > 0 && aclNameFormControl?.errors?.['unselectAclUser']; else userNotFoundError">please select user</div>
            <ng-template #userNotFoundError> User not found </ng-template>
          </ng-template>
        </cds-assistive-text>
      </div>
      <div class="col-md-3 col-xs-12 l-pt-5--mobile">
        <cds-dropdown [(ngModel)]="aclUserType" (ngModelChange)="aclUserFormReset()" [config]="aclUsetTypeDropdownConfig" [disabled]="isLoading"></cds-dropdown>
      </div>
      <div class="col-md-3 col-xs-12 l-pt-5--mobile">
        <cds-button size="sm" *ngIf="roleId" [disabled]="!addRoleUserEnable()" (click)="addUserToRole()">Add User</cds-button>
      </div>
    </div>
  </div>
</div>
<div class="row-span">
  <div class="col-xs-12 col-md-8 l-pt-5">
    <div class="cds-h2-light">{{ 'role.step2.userList' | lang }}</div>
  </div>
  <div class="col-xs-12 col-md-4 l-pt-5">
    <cds-search [(ngModel)]="filterUser" (ngModelChange)="loadTable()" placeholder="{{ 'role.step2.user.search' | lang }}"></cds-search>
  </div>
  <div class="col-xs-12 col-md-12">
    <app-loading *ngIf="isLoading" [overlay]="true"></app-loading>
    <div>
      <app-no-result-found *ngIf="dataSource.length === 0; else dataTableData"></app-no-result-found>
      <ng-template #dataTableData>
        <cds-table [dataSource]="dataSource" [class.full]="true">
          <!-- Role Column -->
          <ng-container cdsColumnDef="username">
            <cds-header-cell *cdsHeaderCellDef>
              {{ 'user.table.username' | lang }}
            </cds-header-cell>
            <cds-cell *cdsCellDef="let element">
              {{ element.identity.userName }}
            </cds-cell>
          </ng-container>

          <ng-container cdsColumnDef="email">
            <cds-header-cell *cdsHeaderCellDef>
              {{ 'user.table.email' | lang }}
            </cds-header-cell>
            <cds-cell *cdsCellDef="let element">
              {{ element.identity.userEmail }}
            </cds-cell>
          </ng-container>

          <ng-container cdsColumnDef="userId">
            <cds-header-cell *cdsHeaderCellDef>
              {{ 'table.action' | lang }}
            </cds-header-cell>
            <cds-cell *cdsCellDef="let element">
              <div class="l-d-f l-jc-fs">
                <cds-link
                  [config]="{
                    label: 'common.delete' | lang,
                    size: 'sm',
                    icon: 'form:delete'
                  }"
                  [disabled]="true"
                  (click)="deleteUser(element.id)">
                </cds-link>
              </div>
            </cds-cell>
          </ng-container>
          <cds-header-row *cdsHeaderRowDef="displayedColumns"></cds-header-row>
          <cds-row class="cds-body1" *cdsRowDef="let row; columns: displayedColumns"></cds-row>
        </cds-table>
        <!-- pagination -->
        <div class="col-xs-12 col-md-12 l-pt-4 l-pb-4 l-d-f l-jc-sb">
          <app-pagination [pageCount]="pageCount" (pageChange)="loadTable()" [isLoading]="isLoading"> </app-pagination>
        </div>
      </ng-template>
    </div>
  </div>
</div>
