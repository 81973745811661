import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ActionState } from '../../chatbot-admin/_common/_interface';
import { DataService } from '../../chatbot-admin/_services/data.service';
import { CdsPopupService } from '@cds/ng-web-components/popup';
import { CannedService } from '../../chatbot-admin/_services/canned.service';
import { environment } from 'src/environments/environment';
import { CdsOption } from '@cds/ng-core/configuration';
import { LoginType } from '../../user/login/login.component';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { LanguageChangeService } from 'src/app/shared/language-change.service';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-supervisor',
  templateUrl: './supervisor.component.html',
  styleUrls: ['./supervisor.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SupervisorComponent implements OnInit {
  loading: boolean = false;
  count = 0;
  rows = [];
  modalOptions: NgbModalOptions = {};
  modalRef: any;
  stateModel: ActionState = { data: null, isNew: false, isReadonly: false, isEdit: false };
  headers = [
    { key: 'agentName', title: 'CSO' },
    { key: 'status', title: 'Status' },
    { key: 'activeChats', title: 'Active Chats' },
    { key: 'timeEngaged', title: 'Time Engaged' },
    { key: 'lastMessageTime', title: 'Last Message Time' },
  ];

  selectedLang = 'en';
  langList: CdsOption[] = environment.languageList;
  private sharData: Subject<string> = new Subject();

  constructor(
    public modalService: NgbModal,
    public dataService: DataService,
    public cannedService: CannedService,
    private languageChangeService: LanguageChangeService,
    private authenticationService: AuthenticationService,
    private router: Router,
    private cdsPopup: CdsPopupService
  ) {}

  changeLang(lang: string) {
    this.selectedLang = lang;
    this.languageChangeService.setLang(lang);
    this.sendData(lang);
  }

  sendData(value: string) {
    this.sharData.next(value);
  }

  logout() {
    let loginType = localStorage.getItem('loginType') as LoginType;
    if (!loginType) {
      loginType = 'sso';
    }
    this.authenticationService.logout(loginType, this.router);
  }

  ngOnInit(): void {
    this.getSupervisorData();

    const styleTags = document.getElementsByTagName('style');
    for (let i = 0; i < styleTags.length; i++) {
      const styleTag = styleTags[i] as any;
      if (styleTag.textContent.includes('chatbot-admin-style-marker-deletion-prohibited')) {
        if (styleTag.hasAttribute('media')) {
          styleTag.removeAttribute('media');
        }
      }
    }
  }

  ngOnDestroy(): void {
    const styleTags = document.getElementsByTagName('style');
    for (let i = 0; i < styleTags.length; i++) {
      const styleTag = styleTags[i] as any;
      if (styleTag.textContent.includes('chatbot-admin-style-marker-deletion-prohibited')) {
        styleTag.setAttribute('media', 'max-width: 1px');
      }
    }
  }

  getSupervisorData() {
    this.loading = true;
    this.cannedService.getSupervisorData().subscribe(resp => {
      this.loading = false;
      if (resp) {
        this.rows = resp;
      }
    });
  }
}
