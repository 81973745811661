<app-cd-popup>
  <cds-popup-content>
    <div class="l-pt-7">
      <div class="cds-body-hero-light l-mb-2">{{ 'commission.default.scale.autoChangeList.title' | lang }}</div>
      <div class="cds-body2 l-mb-5">{{ 'commission.default.scale.autoChangeList.tip' | lang }}</div>
      <div class="cds-body2 l-mb-5">
        <div>Auto updating will be occurred in below 3 scenarios:</div>
        <ul>
          <li>
            <p>At the end of first contribution month:</p>
            <p>
              Total reporting amount on the “First Contribution date” of the main party is accumulated
              <span class="cds-demibold">equal or larger</span> than $10,000.00
            </p>
          </li>
          <li>
            <p>At the end of the next month of first contribution month and no auto update in previous month:</p>
            <p>
              Total reporting amount on the “First Contribution date” of the main party is accumulated
              <span class="cds-demibold">equal or larger</span> than $10,000.00
            </p>
          </li>
          <li>
            <p>At the end of the next month of first contribution month and auto updated in previous month:</p>
            <p>
              Total reporting amount on the “First Contribution date” of the main party is accumulated
              <span class="cds-demibold">less</span> than $10,000.00
            </p>
          </li>
        </ul>
      </div>
      <div class="row l-plr-0">
        <table class="col-xs-12 col-md-12 cds-body2" style="border-collapse: collapse">
          <caption></caption>
          <thead>
            <tr>
              <td class="div-border l-pa-4">Subscription Fee</td>
              <td class="div-border l-pa-4">
                <span>[Original Rate]</span>
                <p>FS HKPC Scale</p>
              </td>
              <td class="div-border l-pa-4">
                <span>[Original Rate]</span>
                <p>1st Year Comm Scale</p>
              </td>
              <td class="div-border l-pa-4">
                <span>[+0.5% Rate]</span>
                <p>FS HKPC Scale</p>
              </td>
              <td class="div-border l-pa-4">
                <span>[+0.5% Rate]</span>
                <p>1st Year Comm Scale</p>
              </td>
            </tr>
          </thead>
          <tbody class="cds-demibold">
            <tr class="cds-grey light">
              <td class="div-border l-pa-4">0</td>
              <td class="div-border l-pa-4">M56PC (2.5%)</td>
              <td class="div-border l-pa-4">M56FY (2.5%)</td>
              <td class="div-border l-pa-4">M68PC (3%)</td>
              <td class="div-border l-pa-4">M68FY (3%)</td>
            </tr>
            <tr class="cds-grey light">
              <td class="div-border l-pa-4">1</td>
              <td class="div-border l-pa-4">M68PC (3%)</td>
              <td class="div-border l-pa-4">M68FY (3%)</td>
              <td class="div-border l-pa-4">M76PC (3.5%)</td>
              <td class="div-border l-pa-4">M301FY (3.5%)</td>
            </tr>
            <tr class="cds-grey light">
              <td class="div-border l-pa-4">2</td>
              <td class="div-border l-pa-4">M76PC (3.5%)</td>
              <td class="div-border l-pa-4">M301FY (3.5%)</td>
              <td class="div-border l-pa-4">M88PC (4%)</td>
              <td class="div-border l-pa-4">M287FY (4%)</td>
            </tr>
            <tr class="cds-grey light">
              <td class="div-border l-pa-4">3</td>
              <td class="div-border l-pa-4">M88PC (4%)</td>
              <td class="div-border l-pa-4">M287FY (4%)</td>
              <td class="div-border l-pa-4">M99PC (4.5%)</td>
              <td class="div-border l-pa-4">M99FY (4.5%)</td>
            </tr>
            <tr class="cds-grey light">
              <td class="div-border l-pa-4">4</td>
              <td class="div-border l-pa-4">M99PC (4.5%)</td>
              <td class="div-border l-pa-4">M99FY (4.5%)</td>
              <td class="div-border l-pa-4">M00PC (5%)</td>
              <td class="div-border l-pa-4">M00FYR (5%)</td>
            </tr>
            <tr class="cds-grey light">
              <td class="div-border l-pa-4">5</td>
              <td class="div-border l-pa-4">M00PC (5%)</td>
              <td class="div-border l-pa-4">M00FYR (5%)</td>
              <td class="div-border l-pa-4">M306PC (5.5%)</td>
              <td class="div-border l-pa-4">M306FYR (5.5%)</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </cds-popup-content>
  <cds-popup-actions>
    <cds-button (click)="gotIt()" [style]="'primary'" size="sm">{{ 'common.gotItWithExclamation' | lang }}</cds-button>
  </cds-popup-actions>
</app-cd-popup>
