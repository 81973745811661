/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { CdsButtonConfig } from '@cds/ng-core/button';
import { CdsDropdownConfig } from '@cds/ng-core/dropdown';
import { CdsLangService } from '@cds/ng-core/lang';
import { CdsTextfieldConfig } from '@cds/ng-core/textfield';
import { CdsPopupService } from '@cds/ng-web-components/popup';
import { ContinuePopupComponent } from 'src/app/shared/continue-popup/continue-popup.component';
import { descriptionValidator } from 'src/app/shared/validators/validators';
import { activityTypeptions } from '../employer';

@Component({
  selector: 'app-add-activity',
  templateUrl: './add-activity.component.html',
  styleUrls: ['./add-activity.component.scss'],
})
export class AddActivityComponent implements OnInit {
  form: FormGroup = new FormGroup({});

  activityTypeDropdownConfig: CdsDropdownConfig = {
    label: 'Activity Type',
    options: activityTypeptions,
    placeholder: 'Select Activity Type',
  };

  scoreTxtfieldConfig: CdsTextfieldConfig = {
    label: 'Score',
    placeholder: 'Input Score',
    type: 'number',
  };

  descriptionTxtfieldConfig: CdsTextfieldConfig = {
    label: 'Activity Description',
    placeholder: 'Input Activity Description',
    type: 'text',
  };

  AddButtonConfig: CdsButtonConfig = { size: 'sm' };

  resetButtonConfig: CdsButtonConfig = { size: 'sm', style: 'secondary' };

  resetDisabled = true;

  descriptionTip = 'Please input English, Chinese, numbers, or special characters';

  get activityType() {
    return this.form.get('activityType') as FormControl;
  }

  get score() {
    return this.form.get('score') as FormControl;
  }

  get description() {
    return this.form.get('description') as FormControl;
  }

  constructor(private dialogRef: MatDialogRef<AddActivityComponent>, private cdsPopup: CdsPopupService, private langService: CdsLangService) {}

  ngOnInit() {
    this.initForm();
  }

  initForm() {
    this.form = new FormGroup({
      activityType: new FormControl('', Validators.required),
      score: new FormControl('', [Validators.required, Validators.max(999), Validators.min(0)]),
      description: new FormControl('', [Validators.required, Validators.maxLength(50), descriptionValidator(this.descriptionTip, true)]),
    });

    this.formChangeListener();
  }

  formChangeListener() {
    this.form.valueChanges.subscribe(() => {
      // if every field is '', the reset button disabled
      this.resetDisabled = Object.keys(this.form.value).every(key => this.form.value[key] === '');
    });
  }

  descriptionChange(value: string) {
    if (!value) {
      return;
    }
    this.description?.setValue(value.trim());
  }

  reset() {
    if (this.resetDisabled) {
      return;
    }
    this.cdsPopup
      .open(ContinuePopupComponent, {
        size: 'sm',
        data: { message: this.langService.translate('common.action.cancel') },
      })
      .afterClosed()
      .subscribe(result => {
        if (result?.agree) {
          this.form.patchValue({
            activityType: '',
            score: '',
            description: '',
          });
          this.form.markAsPristine();
        }
      });
  }

  add() {
    if (!this.form.valid) {
      return;
    }

    this.dialogRef.close({
      agree: true,
      formValue: this.form.value,
    });
  }

  close() {
    if (!this.resetDisabled) {
      this.cdsPopup
        .open(ContinuePopupComponent, {
          size: 'sm',
          data: { message: this.langService.translate('common.action.cancel') },
        })
        .afterClosed()
        .subscribe(result => {
          if (result?.agree) {
            this.dialogRef.close();
          }
        });
    } else {
      this.dialogRef.close();
    }
  }
  scoreChange(value: any) {
    if (typeof value !== 'string') return;

    this.score.setValue(Math.round(Math.abs(Number(value))).toString());
  }
}
