<div class="l-pt-8 l-pb-7">
  <span class="cds-h2-light l-pt-2">{{ 'commission.individualTransfer.title' | lang }}</span>
</div>

<div>
  <div class="l-d-f l-ai-cen l-pb-6">
    <span class="cds-body-hero font-green font-weight-6 l-mb-0">
      {{ 'commission.individualTransfer.transaction' | lang }}
    </span>
  </div>
  <div class="l-pa-7 cds-white l-mb-5 div-border">
    <div class="font-size-30 font-weight-6 l-mb-5">TRF SP HKPC</div>
    <div class="l-pr-5 l-pl-5 l-pt-4">
      <div class="row l-plr-0">
        <div class="col-xs-5 col-md-5 cds-body2 cds-dark-navy-text light-3">HKPC Released CYTD</div>
        <div class="col-xs-7 col-md-7 cds-body1">{{ a1 || 0 | mynumber }}</div>
      </div>
      <div class="row l-plr-0">
        <div class="col-xs-5 col-md-5 cds-body2 cds-dark-navy-text light-3">Prem CYTD</div>
        <div class="col-xs-7 col-md-7 cds-body1">{{ a2 || 0 | mynumber : 2 }}</div>
      </div>
      <div class="row l-plr-0">
        <div class="col-xs-5 col-md-5 cds-body2 cds-dark-navy-text light-3">Prem PTD</div>
        <div class="col-xs-7 col-md-7 cds-body1">{{ a3 || 0 | mynumber : 2 }}</div>
      </div>
    </div>
  </div>
  <div class="l-pa-7 cds-white l-mb-5 div-border">
    <div class="font-size-30 font-weight-6 l-mb-5">TRF SP Comm</div>
    <div class="l-pr-5 l-pl-5 l-pt-4">
      <div class="row l-plr-0">
        <div class="col-xs-5 col-md-5 cds-body2 cds-dark-navy-text light-3">Prem CYTD</div>
        <div class="col-xs-7 col-md-7 cds-body1">{{ a4 || 0 | mynumber : 2 }}</div>
      </div>
      <div class="row l-plr-0">
        <div class="col-xs-5 col-md-5 cds-body2 cds-dark-navy-text light-3">Prem PTD</div>
        <div class="col-xs-7 col-md-7 cds-body1">{{ a5 || 0 | mynumber : 2 }}</div>
      </div>
    </div>
  </div>
</div>

<div class="content-title-padding-top" *ngIf="agents.length">
  <div class="l-d-f l-ai-cen l-pb-2">
    <span class="cds-body-hero font-green font-bold l-mb-0">
      {{ 'commission.individualTransfer.transferAgent' | lang }}
    </span>
    <cds-icon (click)="alertIndividual()" class="l-pl-3 cursor-pointer" size="sm" icon="action:info_1"></cds-icon>
  </div>

  <div>
    <div class="l-mt-5 cds-white div-border" *ngFor="let agent of agents; let i = index">
      <div class="l-pl-6 l-pb-5 l-pt-6 l-ml-3 l-mr-3 l-d-f div-border-bottom">
        <div class="l-pr-5">
          <cds-icon size="md" icon="people:login_1"></cds-icon>
        </div>
        <div>
          <div>
            <span class="font-weight-6 font-size-18 l-d-ib l-mr-5">{{ agent.agentCode }}</span>
            <span class="font-weight-6 font-size-18">{{ agent.agentName }}</span>
          </div>
          <div class="l-pt-3">
            <span>Pool Join Date: {{ agent.poolJoinDate | ssmsDate }}</span>
          </div>
          <div class="l-pt-3 l-d-f cursor-pointer" (click)="toAdjustmentAmount()">
            <cds-icon size="sm" color="#EC6453" icon="action:button_right_filled"></cds-icon>
            <span class="cds-body2 cds-demibold l-ml-2 l-mb-0">Make commission adjustment</span>
          </div>
        </div>
      </div>

      <div class="div-border-bottom l-pa-6">
        <div class="row l-plr-0">
          <div class="col-xs-6 col-md-3">
            <span class="cds-body2 cds-dark-navy-text light-3">Branch</span>
            <div class="l-pt-2">{{ agent.branch }}</div>
          </div>
          <div class="col-xs-6 col-md-3">
            <span class="cds-body2 cds-dark-navy-text light-3">Type</span>
            <div class="l-pt-2">{{ agent.type }}</div>
          </div>
        </div>
      </div>

      <div class="div-border-top l-pl-7 l-pb-7 l-pr-7 l-pt-6">
        <div class="font-weight-6 l-mb-7">
          <span class="cds-body-hero cds-h4-demibold">Agent Compensation</span>
        </div>
        <div class="row l-plr-0 l-mb-7">
          <div class="col-xs-12 col-md-4 l-plr-0 div-border-right">
            <div class="l-pr-8">
              <div class="cds-body-hero">CYTD</div>
              <div>
                <div class="l-pt-4 l-pr-8 l-d-f l-jc-sb">
                  <div class="cds-body2 cds-dark-navy-text light-3">FS HKPC CYTD</div>
                  <div>{{ agent.fsPcCytd || 0 | mynumber }}</div>
                </div>
                <div class="l-pt-4 l-pr-8 l-d-f l-jc-sb">
                  <div class="cds-body2 cds-dark-navy-text light-3">TRF SP HKPC CYTD</div>
                  <div>{{ agent.trfSpPcCytd || 0 | mynumber }}</div>
                </div>
                <div class="l-pt-4 l-pr-8 l-d-f l-jc-sb">
                  <div class="cds-body2 cds-dark-navy-text light-3">TRF SP Comm CYTD</div>
                  <div>{{ agent.trfSpCytd || 0 | mynumber : 2 }}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xs-12 col-md-4 l-plr-0 div-border-right">
            <div class="l-pl-8">
              <div class="cds-body-hero">PTD</div>
              <div>
                <div class="l-pt-4 l-pr-8 l-d-f l-jc-sb">
                  <div class="cds-body2 cds-dark-navy-text light-3">FS HKPC PTD</div>
                  <div>{{ agent.fsPcPtd || 0 | mynumber }}</div>
                </div>
                <div class="l-pt-4 l-pr-8 l-d-f l-jc-sb">
                  <div class="cds-body2 cds-dark-navy-text light-3">TRF SP HKPC PTD</div>
                  <div>{{ agent.trfSpPcPtd || 0 | mynumber }}</div>
                </div>
                <div class="l-pt-4 l-pr-8 l-d-f l-jc-sb">
                  <div class="cds-body2 cds-dark-navy-text light-3">TRF SP Comm PTD</div>
                  <div>{{ agent.trfSpPtd || 0 | mynumber : 2 }}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xs-12 col-md-4 l-plr-0">
            <div class="l-pl-8">
              <div class="cds-body-hero">CAL YTD</div>
              <div>
                <div class="l-pt-4 l-pr-8 l-d-f l-jc-sb">
                  <div class="cds-body2 cds-dark-navy-text light-3">FS HKPC Cal YTD</div>
                  <div>{{ agent.fsPcCal || 0 | mynumber }}</div>
                </div>
                <div class="l-pt-4 l-pr-8 l-d-f l-jc-sb">
                  <div class="cds-body2 cds-dark-navy-text light-3">TRF SP HKPC Cal YTD</div>
                  <div>{{ agent.trfSpPcCal || 0 | mynumber }}</div>
                </div>
                <div class="l-pt-4 l-pr-8 l-d-f l-jc-sb">
                  <div class="cds-body2 cds-dark-navy-text light-3">TRF SP Comm Cal YTD</div>
                  <div>{{ agent.trfSpCal || 0 | mynumber : 2 }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="content-title-padding-top">
  <div class="l-d-f l-ai-cen l-pb-6">
    <span class="cds-body-hero font-green font-weight-6 l-mb-0">
      {{ 'commission.individualTransfer.pendingTransaction.title' | lang }}
    </span>
    <cds-icon (click)="alertPendingTransaction()" class="l-pl-3 cursor-pointer" size="sm" icon="action:info_1"></cds-icon>
  </div>
  <app-search-record-transaction (search)="search($event)" [errors]="errors"></app-search-record-transaction>

  <app-data-table
    [frontPagination]="false"
    [fixed]="false"
    [pageConfig]="pageConfig"
    (reloadTable)="pageChange($event)"
    [isLoading]="isSearchloading"
    [dataSource]="dataDisplayed"
    [columnsConfig]="columnsConfig">
    <ng-template appDtHeaderCellDef="accountNo" let-element>
      <div>{{ element.title | lang }}</div>
      <div><cds-icon (click)="alertAccountCodeInfo()" class="cursor-pointer" icon="action:info_1"></cds-icon></div>
    </ng-template>
    <ng-template appDtBodyCellDef="edit" let-element>
      <cds-icon *ngIf="element.isEditable" class="cursor-pointer" (click)="makeAppeal(element)" size="sm" icon="action:edit"></cds-icon>
    </ng-template>
    <ng-template appDtBodyCellDef="referenceNo" let-element>
      <span class="cds-demibold">
        {{ element['referenceNo'] }}
      </span>
    </ng-template>
    <ng-template appDtBodyCellDef="accountType" let-element>
      <span class="line-no-wrap">
        {{ element['accountType'] }}
      </span>
    </ng-template>
    <ng-template appDtBodyCellDef="commFlagLabel" let-element>
      <span class="line-no-wrap">
        {{ element['commFlagLabel'] }}
      </span>
    </ng-template>
    <ng-template appDtBodyCellDef="sharingPC" let-element>
      <span> {{ element['sharingPC'] | mynumber : '3' }}% </span>
    </ng-template>
    <ng-template appDtBodyCellDef="sharingComm" let-element>
      <span> {{ element['sharingComm'] | mynumber : '3' }}% </span>
    </ng-template>
  </app-data-table>
</div>

<div class="l-mt-6">
  <cds-button (click)="backTop()" [style]="'secondary'" size="md">{{ 'common.backToTop' | lang }}</cds-button>
</div>
