import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminLayoutComponent } from '../layout/admin-layout/admin-layout.component';
import { PermissionAccess } from 'src/app/core/models/enum/permission.enum';
import { ChequePrintingListComponent } from './shkp/cheque-printing-list/cheque-printing-list.component';
import { ScbStatementComponent } from './shkp/scb-statement/scb-statement/scb-statement.component';
import { ChequePrintingListPTWComponent } from './ptw/cheque-printing-list/cheque-printing-list-ptw.component';

const routes: Routes = [
  {
    path: '',
    component: AdminLayoutComponent,
    children: [
      { path: '', pathMatch: 'full', redirectTo: 'shkp/benefit-payment/list' },
      {
        path: 'shkp/benefit-payment/list',
        component: ChequePrintingListComponent,
        data: {
          title: 'cheque.printing-list-title',
          roles: {
            SHK_PAYMENT_ISSUANCE_PAYMENT_ADMIN: PermissionAccess.R,
            SHK_PAYMENT_ISSUANCE_PAYMENT_STAFF: PermissionAccess.R,
            SHK_PAYMENT_ISSUANCE_PAYMENT_MANAGER: PermissionAccess.R,
          },
        },
      },
      {
        path: 'shkp/scb-statement-upload',
        component: ScbStatementComponent,
        data: {
          title: 'cheque.scb-statement-upload-title',
          roles: {
            SHK_PAYMENT_ISSUANCE_PAYMENT_ADMIN: PermissionAccess.R,
            SHK_PAYMENT_ISSUANCE_PAYMENT_STAFF: PermissionAccess.R,
            SHK_PAYMENT_ISSUANCE_PAYMENT_MANAGER: PermissionAccess.R,
          },
        },
      },
      {
        path: 'ptw/benefit-payment/list',
        component: ChequePrintingListPTWComponent,
        data: {
          title: 'cheque.printing-list-ptw-title',
          roles: {
            SHK_PAYMENT_ISSUANCE_PAYMENT_ADMIN: PermissionAccess.R,
          },
        },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class BenefitPaymentRoutingModule {}
