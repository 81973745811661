/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-icon-status',
  templateUrl: './icon-status.component.html',
  styleUrls: ['./icon-status.component.scss'],
})
export class IconStatusComponent implements OnInit {
  @Input() value?: any;
  @Input() statusMapObj?: any;

  colorStyle = '';

  text = '';

  constructor() {}

  ngOnInit(): void {}

  getStatusColorStyle(value: string, mapObj: any) {
    if (!value) {
      return '';
    }
    return `background-color: ${mapObj[value]?.color};`;
  }

  getStatus(value: string, mapObj: any) {
    if (!value) {
      return '';
    }
    return mapObj[value]?.text;
  }
}
