import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CdsIconModule } from '@cds/ng-web-components/icon';
import { CdsLanguageModule } from '@cds/ng-core/lang';

import { CdPageTitleComponent } from './cd-page-title.component';

@NgModule({
  imports: [CommonModule, CdsIconModule, CdsLanguageModule],
  declarations: [CdPageTitleComponent],
  exports: [CdPageTitleComponent],
})
export class CdPageTitleModule {}
