/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { CdsButtonConfig } from '@cds/ng-core/button';
import { finalize } from 'rxjs';
import { ColumnConfig, PageConfig } from 'src/app/shared/data-table';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CdsDropdownConfig } from '@cds/ng-core/dropdown';
import { DateTime } from 'luxon';
import moment from 'moment';
import { dateValidator, numberStringValidator } from 'src/app/shared/validators/validators';
import { ToastAlertService } from 'src/app/shared/toast-alert.service';
import { findOptionLabelByValue } from 'src/app/utils/utils';
import { deepCopy } from 'src/app/utils/copy';
import { ActivatedRoute, Router } from '@angular/router';
import { CdsPopupService } from '@cds/ng-web-components/popup';
import {
  EmpfWorkflow,
  EmpfWorkflowRequest,
  WorkflowStatusCodeE,
  WorkflowTypeE,
  workflowStatusCodeMapObj,
  workflowStatusCodeOptions,
  workflowTypeOptions,
} from '../employee/employee';
import { EmployeeService } from '../employee/employee.service';
import { ClosePopupComponent } from './close-popup/close-popup.component';
import { EmployerService } from '../employer/employer.service';

@Component({
  selector: 'app-empf-workflow',
  templateUrl: './empf-workflow.component.html',
  styleUrls: ['./empf-workflow.component.scss'],
})
export class EmpfWorkflowComponent implements OnInit, OnChanges {
  @Input() id?: string;
  @Input() isEr = false;

  dataSource: EmpfWorkflow[] = [];

  workflowStatusCodeMapObj = workflowStatusCodeMapObj;

  originColumnsConfig: ColumnConfig[] = [];

  columnsConfig = deepCopy(this.originColumnsConfig);

  pageConfig: PageConfig = {
    current: 1,
    pageSize: 10,
    totalCount: 0,
  };

  numberOfElements = 0;

  searchButtonConfig: CdsButtonConfig = {
    size: 'sm',
  };

  resetButtonConfig: CdsButtonConfig = {
    size: 'sm',
    style: 'secondary',
  };

  get searchButtonDisabled() {
    return !this.form?.valid || !this.form.dirty;
  }

  resetButtonDisabled = true;

  showDatePicker = true;

  isLoading = false;

  dataLoading = false;

  form: FormGroup = new FormGroup({});

  formReady = false;

  get workflowReferenceNoControl() {
    return this.form?.get('workflowReferenceNo');
  }

  get paymentSubmissionReferenceNoControl() {
    return this.form?.get('paymentSubmissionReferenceNo');
  }

  get typeControl() {
    return this.form?.get('type');
  }

  get statusCodeControl() {
    return this.form?.get('statusCode');
  }

  get memberAccountCodeControl() {
    return this.form?.get('memberAccountCode');
  }

  get createDateControl() {
    return this.form?.get('createDate');
  }

  typeConfig: CdsDropdownConfig = {
    label: 'Type',
    placeholder: 'Select Type',
    options: workflowTypeOptions,
  };

  statusCodeConfig: CdsDropdownConfig = {
    label: 'Status Code',
    placeholder: 'Select Status Code',
    options: workflowStatusCodeOptions,
  };

  nowTimeInstr = DateTime.now().toFormat('dd/MM/yyyy');

  maxTime = this.getNowTime();

  tempSeardhParams: EmpfWorkflowRequest = {
    page: 1,
    size: 10,
  };

  constructor(
    private employeeService: EmployeeService,
    private employerService: EmployerService,
    private toastAlert: ToastAlertService,
    private router: Router,
    private route: ActivatedRoute,
    private cdsPopup: CdsPopupService
  ) {}

  ngOnInit(): void {
    // this.initForm();
    this.originColumnsConfig = this.isEr
      ? [
          {
            key: 'workflowReferenceNo',
            title: 'Workflow Reference No.',
            // sort: SortMode.DEFAULT,
            sticky: true,
          },
          {
            key: 'memberAccountCode',
            title: 'Member Account Code',
          },
          { key: 'memberName', title: 'Member Name' },
          { key: 'memberEmpfId', title: 'Member eMPF ID' },
          { key: 'statusCode', title: 'Status Code' },
          { key: 'type', title: 'Type' },
          { key: 'createDate', title: 'Creation Date' },
          { key: 'followUpRejectDate', title: 'Follow-Up / Reject Date' },
          { key: 'followUpRejectReason', title: 'Follow-Up / Reject Reason' },
        ]
      : [
          {
            key: 'workflowReferenceNo',
            title: 'Workflow Reference No.',
            // sort: SortMode.DEFAULT,
            sticky: true,
          },
          { key: 'statusCode', title: 'Status Code' },
          { key: 'type', title: 'Type' },
          { key: 'createDate', title: 'Creation Date' },
          { key: 'followUpRejectDate', title: 'Follow-Up / Reject Date' },
          { key: 'followUpRejectReason', title: 'Follow-Up / Reject Reason' },
        ];
    this.columnsConfig = deepCopy(this.originColumnsConfig);
  }

  initForm(workflowReferenceNo = '') {
    this.form?.markAsUntouched();
    this.form?.markAsPristine();

    this.form = new FormGroup({
      workflowReferenceNo: new FormControl(workflowReferenceNo, [Validators.maxLength(35)]),
      paymentSubmissionReferenceNo: new FormControl('', [Validators.maxLength(50)]),
      type: new FormControl(''),
      statusCode: new FormControl(''),
      memberAccountCode: new FormControl('', [Validators.maxLength(15), numberStringValidator()]),
      createDate: new FormControl('', [dateValidator('create.dateError', '', this.nowTimeInstr)]),
    });
    this.formReady = false;
    setTimeout(() => {
      this.formReady = true;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['id']?.currentValue && changes['id']?.currentValue !== changes['id']?.previousValue) {
      const workflowReferenceNo = this.route.snapshot.queryParams['workflowReferenceNo'] || '';
      this.initForm(workflowReferenceNo);
      if (workflowReferenceNo) {
        this.resetButtonDisabled = false;
        this.tempSeardhParams = deepCopy(this.getTempSearchParams());
      }
      this.getData();
    }
  }

  getNowTime() {
    return moment().toDate();
  }

  getTempSearchParams() {
    const params: EmpfWorkflowRequest = {
      ...this.tempSeardhParams,
      ...(this.form.valid ? this.form.value : {}),
      page: (this.pageConfig.current as number) - 1,
      size: 10,
    };
    return params;
  }

  getRequestParams() {
    const params: EmpfWorkflowRequest = {
      ...this.tempSeardhParams,
      // id: this.id || '',
      page: (this.pageConfig.current as number) - 1,
      size: 10,
    };

    if (params.createDate) {
      params.createDate = this.dateTrans(params.createDate);
    }

    if (!params.sort) {
      // params.sort = 'createDate,desc';
    }

    return params;
  }

  getData() {
    this.isLoading = true;

    const observable = this.isEr ? this.employerService.getEmpfWorkflowList : this.employeeService.getEmpfWorkflowList;

    observable(this.getRequestParams())
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe(res => {
        if (res.result === 0 && res.data) {
          this.numberOfElements = res.data?.totalElements || 0;
          // this.dataSource = res.data?.content || [];
          // fake data
          this.dataSource = [
            {
              workflowReferenceNo: '123',
              memberAccountCode: '123',
              memberName: '123',
              memberEmpfId: '123',
              statusCode: WorkflowStatusCodeE.CANCEL,
              type: WorkflowTypeE.COUNTRIBUTION,
              createDate: '1989-06-04',
              followUpRejectDate: '1989-06-04',
              followUpRejectReason: '',
            },
            {
              workflowReferenceNo: '123',
              memberAccountCode: '123',
              memberName: '123',
              memberEmpfId: '123',
              statusCode: WorkflowStatusCodeE.CANCEL,
              type: WorkflowTypeE.COUNTRIBUTION,
              createDate: '1989-06-04',
              followUpRejectDate: '1989-06-04',
              followUpRejectReason:
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur sit amet nisl ultricies, ultrices augue Curabiturid, Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur sit amet nisl ultricies, ultrices augue Curabiturid',
            },
          ];

          this.pageConfig = {
            ...this.pageConfig,
            totalCount: res.data?.totalElements,
          };
        } else {
          this.toastError(res.message);
        }
      });
  }

  toastError(errorMessage: string) {
    this.toastAlert.show('error', 'common.error', errorMessage, 5000);
  }

  dateTrans(date: string) {
    if (date) {
      return moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD');
    }

    return date;
  }

  search() {
    if (this.searchButtonDisabled) {
      return;
    }
    this.resetButtonDisabled = false;

    this.pageConfig = {
      ...this.pageConfig,
      current: 1,
    };
    this.tempSeardhParams = deepCopy(this.getTempSearchParams());
    this.getData();
  }

  reset() {
    if (this.resetButtonConfig.disabled) {
      return;
    }
    this.resetButtonDisabled = true;

    this.pageConfig = {
      ...this.pageConfig,
      current: 1,
    };

    this.tempSeardhParams = { page: 1, size: 10 };

    this.columnsConfig = deepCopy(this.originColumnsConfig);

    this.initForm();

    this.clearUrlParams();

    // reload datepicker dom to clear the selected date
    this.showDatePicker = false;
    setTimeout(() => {
      this.showDatePicker = true;
      this.getData();
    });
  }

  clearUrlParams() {
    const urlTree = this.router.parseUrl(this.router.url);
    urlTree.queryParams['tab'] = '';
    this.router.navigateByUrl(urlTree);
  }

  reloadTable(event: any) {
    this.pageConfig = {
      ...this.pageConfig,
      current: event.current,
    };

    // if (event.column) {
    //   this.tempSeardhParams.sort = `${event.column.key === 'workflowReferenceNo' ? 'rcv_ref_no' : event.column.key},${event.column.sort}`;
    // } else {
    //   delete this.tempSeardhParams.sort;
    // }

    this.getData();
  }

  typeRender(type: WorkflowTypeE) {
    if (!type) return '';
    return findOptionLabelByValue(type, workflowTypeOptions);
  }

  toDetail(item: EmpfWorkflow) {
    this.router.navigate(['/employee/profile'], {
      queryParams: {
        md5: item.md5,
      },
    });
  }

  reasonPopup(message: string) {
    this.cdsPopup.open(ClosePopupComponent, {
      size: 'sm',
      data: {
        message,
      },
    });
  }
}
