import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors } from '@angular/forms';
import { descriptionValidator } from 'src/app/shared/validators/validators';

@Directive({
  selector: '[appDescriptionValidator]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: DescriptionValidatorDirective,
      multi: true,
    },
  ],
})
export class DescriptionValidatorDirective {
  /** Error message key */
  @Input('appDescriptionValidator') description = '';
  @Input('appDescriptionValidatorSupportChinese') supportChinese = false;

  validate(control: AbstractControl): ValidationErrors | null {
    return this.description ? descriptionValidator(this.description, this.supportChinese)(control) : null;
  }
}
