import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NotificationlistComponent } from './notificationlist/notificationlist.component';
import { CdsButtonModule } from '@cds/ng-web-components/button';
import { CdsIconModule } from '@cds/ng-web-components/icon';
import { CdsPaginationModule } from '@cds/ng-web-components/pagination';
import { CdsTextfieldModule } from '@cds/ng-web-components/textfield';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CdsPopupModule } from '@cds/ng-web-components/popup';
import { NotififacionpopupComponent } from './notififacionpopup/notififacionpopup.component';
import { HttpClientModule } from '@angular/common/http';
import { CdsProgressModule } from '@cds/ng-web-components/progress';
import { CdsLanguageModule } from '@cds/ng-core/lang';
import { MobileSubscriptionRoutingModule } from './mobile-subscription.module';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { SummaryConfirmComponent } from './summary-confirm/summary-confirm.component';
import { SummaryCreateComponent } from './summary-create/summary-create.component';
import { CdsAssistiveTextModule } from '@cds/ng-web-components/assistive-text';
import { CdsDatepickerModule, CDS_DATE_FORMATS, DateAdapter } from '@cds/ng-web-components/datepicker';
import { CdsDropdownModule } from '@cds/ng-web-components/dropdown';
import { CdsFormFieldModule } from '@cds/ng-web-components/form-field';
import { CdsTextareaModule } from '@cds/ng-web-components/textarea';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { MY_FORMATS } from 'src/app/config/datePicker';
import { ManageAudienceListComponent } from './manage-audience-list/manage-audience-list.component';
import { UploadAudienceListFileComponent } from './upload-audience-list-file/upload-audience-list-file.component';
import { CdsFileUploadModule } from '@cds/ng-experimental/file-upload';
import { LoadingModule } from 'src/app/shared/loading/loading.modules';
import { NoResultFoundModule } from 'src/app/shared/no-result-found/no-result-found.module';
import { CdsTableModule } from '@cds/ng-data-table/table';
import { AppPaginationModule } from 'src/app/shared/pagination/pagination.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { CdFormAssistiveComponent } from 'src/app/views/mobile-subscription/cd-form-assistive/cd-form-assistive.component';
import { PreviewAudienceListPopupComponent } from './preview-audience-list-popup/preview-audience-list-popup.component';
@NgModule({
  declarations: [
    NotificationlistComponent,
    NotififacionpopupComponent,
    SummaryCreateComponent,
    SummaryConfirmComponent,
    ConfirmDialogComponent,
    ManageAudienceListComponent,
    UploadAudienceListFileComponent,
    CdFormAssistiveComponent,
    PreviewAudienceListPopupComponent,
  ],
  imports: [
    CommonModule,
    MobileSubscriptionRoutingModule,
    CdsIconModule,
    CdsPaginationModule,
    CdsPopupModule,
    HttpClientModule,
    CdsProgressModule,
    CommonModule,
    CdsDropdownModule,
    CdsTextfieldModule,
    FormsModule,
    CdsLanguageModule,
    CdsButtonModule,
    ReactiveFormsModule,
    CdsAssistiveTextModule,
    CdsFormFieldModule,
    CdsDatepickerModule,
    CdsTextareaModule,
    CdsFileUploadModule,
    LoadingModule,
    NoResultFoundModule,
    CdsTableModule,
    AppPaginationModule,
    SharedModule,
  ],
  providers: [
    { provide: CDS_DATE_FORMATS, useValue: MY_FORMATS },
    { provide: DateAdapter, useClass: MomentDateAdapter },
  ],
})
export class NotificationModule {}
