import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'reasonOfChange',
})
export class ReasonOfChangePipe implements PipeTransform {
  transform(_value: number, lan: string): unknown {
    let reasonOfChangeResult = '';
    if (lan === 'EN') {
      if (_value === 1) {
        reasonOfChangeResult = 'Customer complaint';
      } else if (_value === 2) {
        reasonOfChangeResult = 'Agent termination';
      } else if (_value === 3) {
        reasonOfChangeResult = 'Performance issue';
      } else if (_value === 4) {
        reasonOfChangeResult = 'Wrong agent code';
      } else if (_value === 5) {
        reasonOfChangeResult = 'Others';
      }
    } else {
      if (_value === 1) {
        reasonOfChangeResult = '客戶抱怨';
      } else if (_value === 2) {
        reasonOfChangeResult = '代理終止';
      } else if (_value === 3) {
        reasonOfChangeResult = '性能問題';
      } else if (_value === 4) {
        reasonOfChangeResult = '錯誤的代理代碼';
      } else if (_value === 5) {
        reasonOfChangeResult = '其他原因';
      }
    }
    return reasonOfChangeResult;
  }
}
