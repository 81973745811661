/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ErBaseRequest, PaymentDetail } from '../../employer';
import { EmployerService } from '../../employer.service';

@Component({
  selector: 'app-payment-details',
  templateUrl: './payment-details.component.html',
  styleUrls: ['./payment-details.component.scss'],
})
export class PaymentDetailsComponent implements OnInit, OnChanges {
  constructor(private service: EmployerService) {}

  @Input() payrollGroupId?: string;
  @Input() employerId?: string;
  @Input() isStaff = false;

  data?: PaymentDetail;

  _frequencyMap = new Map([
    ['DAILY', 'Daily'],
    ['WEEKLY', 'Weekly'],
    ['SEMI_MONTHLY', 'Semi-Monthly'],
    ['MONTHLY', 'Monthly'],
    ['BI_MONTHLY', 'Bi-Monthly'],
    ['QUARTERLY', 'Quarterly'],
    ['SEMI_ANNUALLY', 'Half-Yearly'],
    ['ANNUALLY', 'Annually'],
    ['FORTNIGHTLY', 'Fortnightly'],
  ]);

  _ddlCodeMap = new Map([
    ['RECEIVED', 'Received'],
    ['READY_TO_BANK', 'Ready to bank'],
    ['SUBMITTED_TO_BANK', 'Submitted to bank'],
    ['PENDING_AML', 'Pending AML'],
    ['ACTIVE', 'Active'],
    ['REJECTED', 'Rejected'],
    ['INACTIVE', 'Inactive'],
    ['PAYER_FAIL', 'Payer Fail'],
  ]);

  _debtorMap = new Map([
    ['BR', 'Business Registration'],
    ['EDU', 'Education Institution'],
    ['IRD', 'Inland Revenue Department (Charitable Organisations)'],
    ['TU', 'Registry of Trade Unions'],
    ['SO', 'Societies Office of Hong Kong Police'],
    ['OTHERS', 'Others'],
    ['PASSPORT', 'Passport'],
    ['CI', 'Certificate of Incorporation'],
    ['HKID', 'HKID'],
  ]);

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (
      this.employerId &&
      changes['payrollGroupId'].currentValue !== undefined &&
      changes['payrollGroupId'].currentValue !== changes['payrollGroupId'].previousValue
    ) {
      this.getBillingClassInfo();
    }
  }

  getType(key: any, map: Map<any, string>) {
    if (key == undefined) {
      return '';
    }
    return map.get(key) || '';
  }

  _frequencyType(key?: string) {
    if (key == undefined) {
      return '';
    }
    return this._frequencyMap.get(key) || '';
  }

  getBillingClassInfo() {
    const _param: ErBaseRequest = {
      payrollGroupId: this.payrollGroupId,
      id: this.employerId,
    };
    this.service.getBillingClassInfo(_param).subscribe(data => {
      if (data.result === 0) {
        this.data = data.data;
      }
    });
  }
}
