import { OnChanges, SimpleChanges } from '@angular/core';
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CdsButtonConfig } from '@cds/ng-core/button';
import { DateTime } from 'luxon';
import moment from 'moment';
import { finalize } from 'rxjs';
import { Sort } from 'src/app/components/table/table-expand';
import { ColumnConfig, PageConfig, SortMode } from 'src/app/shared/data-table';
import { ToastAlertService } from 'src/app/shared/toast-alert.service';
import { dateValidator, startAndEndDateValidator } from 'src/app/shared/validators/validators';
import { deepCopy } from 'src/app/utils/copy';
import { paymentMethodOptions, PaymentRecord, PaymentRecordSearchRequest } from '../../employer';
import { EmployerService } from '../../employer.service';

@Component({
  selector: 'app-payment-record',
  templateUrl: './payment-record.component.html',
  styleUrls: ['./payment-record.component.scss'],
})
export class PaymentRecordComponent implements OnChanges {
  @Input() payrollGroupId?: string;
  @Input() employerId?: string;

  searchDTO: PaymentRecordSearchRequest = {
    page: 0,
    size: 10,
    paymentSubmissionReferenceNo: '',
    receiptReferenceNo: '',
    contributionPeriodFrom: '',
    contributionPeriodTo: '',
    billReferenceNo: '',
  };

  pageConfig: PageConfig = {
    current: 1,
    pageSize: 10,
    totalCount: 0,
  };

  numberOfElements = 0;

  searchButtonConfig: CdsButtonConfig = {
    size: 'sm',
  };

  resetButtonConfig: CdsButtonConfig = {
    size: 'sm',
    style: 'secondary',
  };

  showDatePicker = true;

  isLoading = false;

  paymentRecordList: PaymentRecord[] = [];

  columnsConfig: ColumnConfig[] = [
    {
      key: 'paymentSubmissionReferenceNo',
      title: 'Payment Submission Reference No.',
      sort: SortMode.DEFAULT,
      sticky: true,
    },
    { key: 'receiptReferenceNo', title: 'Receipt Reference No.' },
    { key: 'billReferenceNo', title: 'Bill Reference No.' },
    { key: 'contributionPeriod', title: 'Contribution Period' },
    { key: 'statusCode', title: 'Status Code' },
    { key: 'paymentMethod', title: 'Payment Method' },
    { key: 'receivedAmount', title: 'Received Amount' },
    { key: 'chequeDate', title: 'Cheque Date' },
    { key: 'chequeBankInDate', title: 'Cheque bank-in Date' },
  ];

  tempSeardhParams: PaymentRecordSearchRequest = {
    page: 1,
    size: 10,
  };

  form: FormGroup = new FormGroup({});

  formReady = false;

  nowTimeInstr = DateTime.now().toFormat('dd/MM/yyyy');

  maxTime = this.getNowTime();

  maxTimeInStr = DateTime.fromJSDate(this.maxTime).toFormat('dd/MM/yyyy');

  paymentMethodOptions = paymentMethodOptions;

  get paymentSubmissionReferenceNoControl() {
    return this.form?.get('paymentSubmissionReferenceNo');
  }
  get receiptReferenceNoControl() {
    return this.form?.get('receiptReferenceNo');
  }

  get billReferenceNoControl() {
    return this.form?.get('billReferenceNo');
  }

  get contributionPeriodFromControl() {
    return this.form?.get('contributionPeriodFrom');
  }

  get contributionPeriodToControl() {
    return this.form?.get('contributionPeriodTo');
  }

  get contributionPeriodFromValue() {
    return this.contributionPeriodFromControl?.value;
  }

  get contributionPeriodToValue() {
    return this.contributionPeriodToControl?.value;
  }

  get paymentSubmissionReferenceNoValue() {
    return this.paymentSubmissionReferenceNoControl?.value;
  }

  get receiptReferenceNoValue() {
    return this.receiptReferenceNoControl?.value;
  }

  get billReferenceNoValue() {
    return this.billReferenceNoControl?.value;
  }

  get contributionEndValue() {
    return this.contributionPeriodToControl?.value;
  }

  get searchButtonDisabled() {
    return !this.form?.valid || !this.form.dirty || this.isLoading;
  }

  resetButtonDisabled = true;

  constructor(public service: EmployerService, private route: ActivatedRoute, private router: Router, private toastAlert: ToastAlertService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['payrollGroupId']?.currentValue && changes['payrollGroupId']?.currentValue !== changes['payrollGroupId']?.previousValue && this.employerId) {
      this.reset(true);
    }
  }

  initForm() {
    this.form?.markAsUntouched();
    this.form?.markAsPristine();

    this.form = new FormGroup(
      {
        paymentSubmissionReferenceNo: new FormControl('', [Validators.maxLength(50)]),
        receiptReferenceNo: new FormControl('', [Validators.maxLength(35)]),
        contributionPeriodFrom: new FormControl('', [dateValidator('create.dateError', '', '')]),
        contributionPeriodTo: new FormControl('', [dateValidator('create.dateError', '', '')]),
        billReferenceNo: new FormControl('', [Validators.maxLength(50)]),
      },
      { validators: startAndEndDateValidator('create.dateError', 'contributionPeriodFrom', 'contributionPeriodTo') }
    );
    this.formReady = false;
    setTimeout(() => {
      this.formReady = true;
    });
  }

  getPageList() {
    this.isLoading = true;
    const params: PaymentRecordSearchRequest = this.getRequestParams();
    this.service
      .getPaymentRcordList(params)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe(data => {
        if (data.result === 0 && data.data) {
          this.paymentRecordList = data.data.empty ? [] : data.data.content;
          this.numberOfElements = data.data.totalElements;

          this.pageConfig = {
            ...this.pageConfig,
            totalCount: data.data.totalElements,
          };
        } else {
          this.toastError(data.message);
        }
      });
  }

  getRequestParams() {
    const params: PaymentRecordSearchRequest = {
      ...this.tempSeardhParams,
      id: this.employerId,
      payrollGroupId: this.payrollGroupId,
      page: (this.pageConfig.current as number) - 1,
      size: 10,
    };

    params.contributionPeriodFrom = params.contributionPeriodFrom ? this.dateTrans(params.contributionPeriodFrom) : '';
    params.contributionPeriodTo = params.contributionPeriodTo ? this.dateTrans(params.contributionPeriodTo) : '';

    return params;
  }

  dateTrans(date: string) {
    if (date) {
      return moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD');
    }

    return date;
  }

  getTime(dateStr: string) {
    if (!dateStr) return undefined;
    return moment(dateStr, 'DD/MM/YYYY').toDate();
  }

  search() {
    if (this.searchButtonDisabled) {
      return;
    }
    this.resetButtonDisabled = false;
    this.pageConfig = {
      current: 1,
      pageSize: 10,
      totalCount: 0,
    };
    this.tempSeardhParams = deepCopy(this.getTempSearchParams());
    this.getPageList();
  }

  getTempSearchParams() {
    const params: PaymentRecordSearchRequest = {
      ...this.tempSeardhParams,
      ...(this.form.valid ? this.form.value : {}),
      page: (this.pageConfig.current as number) - 1,
      size: 10,
    };

    if (!params.sort) {
      params.sort = 'paymentSubmissionReferenceNo,desc';
    }

    return params;
  }

  reset(ignoreResetButton = false) {
    if (this.resetButtonDisabled && !ignoreResetButton) {
      return;
    }

    this.resetButtonDisabled = true;

    this.initForm();

    // reload datepicker dom to clear the selected date
    this.formReady = false;
    setTimeout(() => {
      this.formReady = true;
      this.pageConfig = {
        current: 1,
        pageSize: 10,
        totalCount: 0,
      };
      this.tempSeardhParams = { page: 1, size: 10 };
      this.getPageList();
    });
  }

  reloadTable(event: any) {
    this.pageConfig = {
      ...this.pageConfig,
      current: event.current,
    };

    if (event.column && event.column.sort !== Sort.DEFAULT) {
      this.tempSeardhParams.sort = `${event.column.key === 'paymentSubmissionReferenceNo' ? 'paymentSubmissionReferenceNo' : event.column.key},${
        event.column.sort
      }`;
    } else {
      this.tempSeardhParams.sort = 'paymentSubmissionReferenceNo,desc';
    }

    this.getPageList();
  }

  toastError(errorMessage: string) {
    this.toastAlert.show('error', 'common.error', errorMessage, 5000);
  }

  toDetail(paymentSubmissionReferenceNo: string, receiptReferenceNo?: string) {
    this.router.navigate(['/employer/payment-record-detail'], {
      queryParams: {
        ...this.route.snapshot.queryParams,
        paymentSubmissionReferenceNo,
        receiptReferenceNo,
        payrollGroupId: this.payrollGroupId,
      },
    });
  }

  getNowTime() {
    return moment().toDate();
  }

  getMaxFromDate = (toDateStr?: string) => {
    if (!toDateStr) {
      return this.getNowTime();
    }
    return moment(toDateStr, 'DD/MM/YYYY').toDate();
  };

  getMinToDate = (fromDateStr = '01/01/0000') => {
    return moment(fromDateStr, 'DD/MM/YYYY').toDate();
  };
}
