import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { CdsAlertService } from '@cds/ng-web-components/alert';
import { CdsPopupService } from '@cds/ng-web-components/popup';
import { Observable } from 'rxjs';
import { Sort } from 'src/app/components/table/table-expand';
import { Annuncement } from 'src/app/core/models/annuncement/annuncement.model';
import { NotifyService } from 'src/app/core/services/notify.service';
import { ScreenSizeService } from 'src/app/core/services/screen-size/screen-size.service';
import { CdHttpServeService } from 'src/app/shared/cd-http-serve/cd-http-serve.service';
import { ConfirmPopupComponent } from 'src/app/shared/confirm-popup/confirm-popup.component';

@Component({
  selector: 'app-annuncement-center',
  templateUrl: './annuncement-center.component.html',
  styleUrls: ['./annuncement-center.component.scss'],
})
export class AnnuncementCenterComponent implements OnInit, AfterViewInit {
  Sort = Sort;
  announcementCmsPageListReqeuest = {
    page: 1,
    size: 10,
  };
  currentPageNumOrigin = 1;
  allPageData: Annuncement[][] = [];
  totalElements = 0;
  totalPages = 0;
  formControl: FormControl = new FormControl(false);
  expandMap: { [key: number]: boolean } = {};
  isLoading = false;

  ngAfterViewInit() {}

  get currentPageIndex() {
    return this.currentPageNumOrigin - 1;
  }

  get currentPageData() {
    if (this.allPageData.length === 0) {
      return [];
    }
    return this.allPageData[this.currentPageIndex] || [];
  }

  constructor(
    private cdHttpServeService: CdHttpServeService,
    private alert: CdsAlertService,
    private router: Router,
    private cdsPopup: CdsPopupService,
    private notifyService: NotifyService,
    public screenSizeService: ScreenSizeService
  ) {}

  toggleContent(item: Annuncement) {
    this.expandMap[item.announcementId] = !this.expandMap[item.announcementId];
  }

  ngOnInit(): void {
    this.queryAnnouncementList();
  }

  onCreateAnnouncement() {
    this.router.navigate(['annuncement/announcement-details-create']);
  }

  queryAnnouncementList() {
    this.isLoading = true;
    this.cdHttpServeService
      .post<{
        content: Annuncement[];
        totalElements: number;
        number: number;
        totalPages: number;
      }>('/ext/eb-ssms-sales-journey-service/announcement/cms/pageList', this.announcementCmsPageListReqeuest)
      .subscribe({
        next: res => {
          if (res.result !== 0) {
            this.alert.warning('Warning!', `${res.message}`);
          } else {
            res.data.content = res.data.content || [];
            res.data.content.forEach(element => {
              this.expandMap[element.announcementId] = false;
            });
            this.currentPageNumOrigin = res.data.number;
            this.allPageData[this.currentPageIndex] = res.data.content;
            this.totalElements = res.data.totalElements;
            this.totalPages = res.data.totalPages;

            const currentAllPageData = this.allPageData.length;
            if (this.totalPages > currentAllPageData) {
              for (let i = 0; i < this.totalPages - currentAllPageData; i++) {
                this.allPageData.push([]);
              }
            } else if (this.totalPages < currentAllPageData) {
              for (let i = 0; i < currentAllPageData - this.totalPages; i++) {
                this.allPageData.pop();
              }
            }
          }
          this.isLoading = false;
        },
        error: err => {
          this.alert.error('Error!', err);
          this.isLoading = false;
        },
      });
  }

  pageChange(num: number): void {
    this.announcementCmsPageListReqeuest.page = num;
    this.queryAnnouncementList();
  }

  checkBefore(status: boolean | number) {
    return new Observable<boolean>(observer => {
      this.cdsPopup
        .open(ConfirmPopupComponent, {
          size: 'sm',
          data: {
            message: status ? 'annuncement.center.turnOffConfirm' : 'annuncement.center.turnOnConfirm',
          },
        })
        .afterClosed()
        .subscribe(({ agree }) => {
          observer.next(agree);
        });
      return () => {};
    });
  }

  updateAnnouncementStatus(annuncement: Annuncement) {
    this.cdHttpServeService
      .post('/ext/eb-ssms-sales-journey-service/announcement/cms/updateStatus', {
        announcementId: annuncement.announcementId,
        status: annuncement.status ? 1 : 0,
      })
      .subscribe({
        next: res => {
          if (res.result !== 0) {
            this.alert.warning('Warning!', `${res.message}`);
          }
        },
        error: err => {
          this.alert.error('Error!', err);
        },
      });
  }

  recallAnnouncement(announcementId: number) {
    this.cdsPopup
      .open(ConfirmPopupComponent, {
        size: 'sm',
        data: {
          message: 'annuncement.center.recallAnnouncementConfirm',
        },
      })
      .afterClosed()
      .subscribe(({ agree }) => {
        if (agree) {
          this.deleteAnnuncement(announcementId);
        }
      });
  }

  deleteAnnuncement(announcementId: number) {
    this.notifyService.screenLoadingSubject.next(true);
    this.cdHttpServeService
      .post('/ext/eb-ssms-sales-journey-service/announcement/cms/delete', {
        announcementId: announcementId,
        deleted: 1,
      })
      .subscribe({
        next: res => {
          if (res.result !== 0) {
            this.alert.warning('Warning!', `${res.message}`);
          } else {
            this.queryAnnouncementList();
          }
          this.notifyService.screenLoadingSubject.next(false);
        },
        error: err => {
          this.alert.error('Error!', err);
          this.notifyService.screenLoadingSubject.next(false);
        },
      });
  }

  edit(announcementId: number) {
    this.router.navigate([`annuncement/announcement-details-edit/${announcementId}`]);
  }
}
