import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import packageInfo from '../../package.json';
import { ActivatedRoute, NavigationEnd, NavigationError, Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import { AuthenticationService } from './core/services/authentication.service';
import { ConsoleToogleServiceService } from './core/services/console-toogle-service.service';
import { LayoutHeaderService } from './core/services/layout-header.service';
import { PermissionService } from './core/services/permission.service';
import { NotifyService } from './core/services/notify.service';

@Component({
  selector: 'app-root',
  template: `
    <div *ngIf="isScreenLoading" class="screen-loading">
      <cds-progress mode="indeterminate" [diameter]="60" [type]="'ring'" [text]="'common.loading' | lang" [color]="'ml'"></cds-progress>
    </div>
    <app-sw-update-ask *ngIf="isShowSwUpdate" (closeEmit)="isShowSwUpdate = false"></app-sw-update-ask>
    <router-outlet></router-outlet>
  `,
  styles: [
    `
      ::ng-deep cds-table {
        cds-header-cell {
          vertical-align: middle;
        }
        .cds-sort-header .cds-sort-header-container {
          justify-content: unset;
          .cds-sort-header-arrow svg.none {
            opacity: unset;
          }
          .cds-sort-header-arrow svg {
            width: 20px;
          }
        }
      }

      ::ng-deep .cdk-overlay-pane:has(.mat-select-panel-wrap > .cds-dropdown-panel),
      ::ng-deep .cdk-overlay-pane:has(.mat-select-panel-wrap > .mat-select-panel) {
        transform: translateX(-16px) translateY(6px) !important;

        .mat-select-panel {
          margin-top: 2.25rem !important;
        }
      }

      ::ng-deep cds-link.cds-link.hyperlink a > span:nth-child(2) {
        color: var(--cds-text-main);
      }

      ::ng-deep cds-link.cds-link.hyperlink.no-action {
        cursor: text;

        a {
          pointer-events: none;

          & > span:nth-child(2) {
            color: var(--cds-text-main);
            cursor: auto;
            border-bottom: unset;
          }
        }
      }

      .screen-loading {
        width: 100vw;
        position: absolute;
        height: 100vh;
        z-index: 9999;
        display: flex;
        text-align: center;
        justify-content: center;
        background-color: hsl(0deg 0% 0% / 60%);
      }
    `,
  ],
})
export class AppComponent implements OnInit {
  title = 'Manulife - SSMS';
  isShowSwUpdate = false;
  isScreenLoading = false;

  constructor(
    private consoleToogleService: ConsoleToogleServiceService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private layoutHeaderService: LayoutHeaderService,
    private authService: AuthenticationService,
    private permissionService: PermissionService,
    private swUpdate: SwUpdate,
    private metaService: Meta,
    private notifyService: NotifyService
  ) {
    // the lang to use, if the lang isn't available, it will use the current loader to get them
    this.consoleToogleService.disableConsoleInProduction();
    this.metaService.updateTag({
      name: 'version',
      content: packageInfo.version,
    });
  }

  ngOnInit() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationError) {
        this.router.navigate(['/error/response-error']);
      } else if (event instanceof NavigationEnd) {
        const rt = this.getChild(this.activatedRoute);
        rt.data.subscribe(data => {
          this.layoutHeaderService.setTitle(data['title']);
          if (this.permissionService.getMenuExclusions().includes(event.url)) {
            this.router.navigate(['/error/response-error']);
          }
          this.permissionService.permissionFilterAsync(data['roles']).then(has => {
            if (!has) {
              this.router.navigate(['/permission-error']);
            }
          });
          if (event && event.url.indexOf('/user/session-time-out') == -1 && event.url.indexOf('/user/login')) {
            this.authService.currentUserValue().then(data => {
              if (data.token === undefined) {
                this.authService.setRouterUrl(event.url);
                this.router.navigate(['/user/login']);
              } else {
                this.authService.workerRefresh();
              }
            });
          }
        });
      }
    });
    this.swUpdate.versionUpdates.subscribe(evt => {
      switch (evt.type) {
        case 'VERSION_READY':
        case 'VERSION_INSTALLATION_FAILED':
          this.isShowSwUpdate = true;
          break;
      }
    });

    if (this.swUpdate.isEnabled) {
      let checkTimes = 0;
      this.swUpdate.checkForUpdate();
      const interval = setInterval(() => {
        checkTimes++;
        this.swUpdate.checkForUpdate();
        if (checkTimes >= 1) {
          clearInterval(interval);
        }
      }, 5000);
    }

    this.notifyService.screenLoadingSubject.subscribe((boo: boolean) => {
      setTimeout(() => {
        this.isScreenLoading = boo;
      }, 0);
    });
  }

  getChild(activatedRoute: ActivatedRoute): ActivatedRoute {
    if (activatedRoute.firstChild) {
      return this.getChild(activatedRoute.firstChild);
    } else {
      return activatedRoute;
    }
  }
}
