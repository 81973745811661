/* eslint-disable @typescript-eslint/no-explicit-any */
import { AbstractControl, FormControl, ValidationErrors } from '@angular/forms';
import moment from 'moment';

export const dateValidator = (errors?: ValidationErrors) => {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) {
      return null;
    }
    const isDate = moment(control.value, 'DD/MM/YYYY', true).isValid();
    return isDate ? null : errors || { dateValidator: true };
  };
};

export const dateRangeInCompleteValidator = (fromDateKey: string, toDateKey: string, errors?: ValidationErrors) => {
  return (control: AbstractControl): ValidationErrors | null => {
    const fromDate = control.get(fromDateKey) as FormControl;
    const toDate = control.get(toDateKey) as FormControl;
    if (!fromDate || !toDate) {
      return null;
    }
    if ((!toDate.value && fromDate.value && fromDate.valid) || (toDate.valid && toDate.value && !fromDate.value)) {
      return errors || { dateRangeInCompleteValidator: true };
    }
    return null;
  };
};

export const dateRangeInvalidValidator = (fromDateKey: string, toDateKey: string, errors?: ValidationErrors) => {
  return (control: AbstractControl): ValidationErrors | null => {
    const fromDate = control.get(fromDateKey) as FormControl;
    const toDate = control.get(toDateKey) as FormControl;
    if (!fromDate || !toDate) {
      return null;
    }
    if (fromDate.valid && toDate.valid && fromDate.value && toDate.value) {
      if (moment(fromDate.value, 'DD/MM/YYYY') > moment(toDate.value, 'DD/MM/YYYY')) {
        return errors || { dateRangeInvalidValidator: true };
      }
    }
    return null;
  };
};

export const compareTime = (time1: string, time2: string) => {
  const [hours1, minutes1] = time1.split(':').map(Number);
  const [hours2, minutes2] = time2.split(':').map(Number);

  const totalMinutes1 = hours1 * 60 + minutes1;
  const totalMinutes2 = hours2 * 60 + minutes2;

  if (totalMinutes1 < totalMinutes2) {
    return -1;
  } else if (totalMinutes1 > totalMinutes2) {
    return 1;
  } else {
    return 0;
  }
};

export const datetimeRangeInvalidValidator = (fromDateKey: string, toDateKey: string, fromTimeKey: string, toTimeKey: string, errors?: ValidationErrors) => {
  return (control: AbstractControl): ValidationErrors | null => {
    const fromDate = control.get(fromDateKey) as FormControl;
    const toDate = control.get(toDateKey) as FormControl;
    if (toDate.errors) {
      if (fromDate.errors && fromDate.errors['dateError']) {
        fromDate.setErrors(null);
      }
      return null;
    }
    if (fromDate.errors && !fromDate.errors['dateError']) {
      return null;
    }
    if (moment(fromDate.value, 'DD/MM/YYYY') > moment(toDate.value, 'DD/MM/YYYY')) {
      fromDate.setErrors(errors || null);
    } else if (moment(fromDate.value, 'DD/MM/YYYY').isSame(moment(toDate.value, 'DD/MM/YYYY'), 'day')) {
      fromDate.setErrors(null);
      const fromTime = control.get(fromTimeKey) as FormControl;
      const toTime = control.get(toTimeKey) as FormControl;
      if (fromTime.value && toTime.value) {
        const comparison = compareTime(fromTime.value, toTime.value);
        if (comparison !== -1) {
          fromDate.setErrors(errors || null);
        }
      }
    } else if (moment(fromDate.value, 'DD/MM/YYYY') < moment(toDate.value, 'DD/MM/YYYY')) {
      fromDate.setErrors(null);
    }
    return null;
  };
};

export const addMinutesToTime = (time: string, minutesToAdd: number) => {
  const [hours, minutes] = time.split(':').map(Number);
  const totalMinutes = hours * 60 + minutes + minutesToAdd;

  const newHours = Math.floor(totalMinutes / 60) % 24;
  const newMinutes = totalMinutes % 60;

  const newTime = `${String(newHours).padStart(2, '0')}:${String(newMinutes).padStart(2, '0')}`;

  return newTime;
};

export const isSameOrAfterToday = (errors?: ValidationErrors) => {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) {
      return null;
    }
    const date = moment(control.value, 'DD/MM/YYYY', true);
    const today = moment().startOf('day');
    return date.isSameOrAfter(today) ? null : errors || { dateValidator: true };
  };
};

export const typhoonDateValiator = (errors?: ValidationErrors) => {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;
    if (!value) {
      return null;
    }
    const hasError = moment().diff(moment(value, 'DD/MM/YYYY'), 'days') < 1 || moment().diff(moment(value, 'DD/MM/YYYY'), 'days') > 7;
    return hasError ? errors || { typhoonDateValiator: true } : null;
  };
};
