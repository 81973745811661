import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { CdsButtonConfig } from '@cds/ng-core/button';
import { MatDialogRef } from '@cds/ng-web-components/popup';

import { LanguageChangeService } from 'src/app/shared/language-change.service';

@Component({
  selector: 'app-user-success-submitted',
  templateUrl: './user-success-submitted.component.html',
  styleUrls: ['./user-success-submitted.component.scss'],
})
export class UserSuccessSubmittedComponent implements OnInit, OnDestroy {
  language = 'EN';
  lanSubscription?: Subscription;
  agreeButtonConfig: CdsButtonConfig = {
    size: 'sm',
    style: 'primary',
    disabled: false,
  };
  constructor(private dialogRef: MatDialogRef<UserSuccessSubmittedComponent>, private languageChangeService: LanguageChangeService) {}

  ngOnInit(): void {
    this.lanSubscription = this.languageChangeService.lanSubject.subscribe(lan => {
      this.language = lan === 'en' ? 'EN' : 'TC';
    });
  }

  ngOnDestroy(): void {
    this.lanSubscription?.unsubscribe();
  }

  agree() {
    this.dialogRef.close({ agree: true });
  }
}
