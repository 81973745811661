import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CdsLanguageModule } from '@cds/ng-core/lang';
import { CdsPopupModule } from '@cds/ng-web-components/popup';
import { CdsButtonModule } from '@cds/ng-web-components/button';

import { ConfirmPopupComponent } from './confirm-popup.component';

@NgModule({
  imports: [CommonModule, CdsPopupModule, CdsLanguageModule, CdsButtonModule],
  declarations: [ConfirmPopupComponent],
  exports: [ConfirmPopupComponent],
})
export class ConfirmPopupModule {}
