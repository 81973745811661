/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { BasicResponse, IPageResponse } from 'src/app/core/models/response/response';

@Injectable({
  providedIn: 'root',
})
export class SchemeMasterService {
  constructor(private http: HttpClient) {}

  selectList(params: any): Observable<BasicResponse<IPageResponse<SchemeMasterListItem>>> {
    return this.http.get<BasicResponse<IPageResponse<SchemeMasterListItem>>>(
      environment.apiPrefix.concat(environment.commissionServiceExt).concat('/scheme-master/list'),
      { params: params }
    );
  }

  getById(id: string): Observable<BasicResponse<SchemeMaster>> {
    return this.http.get<BasicResponse<SchemeMaster>>(`${environment.apiPrefix}${environment.commissionServiceExt}/scheme-master/info/${id}`);
  }

  update(params: any): Observable<BasicResponse<SchemeMaster>> {
    return this.http.post<BasicResponse<SchemeMaster>>(`${environment.apiPrefix}${environment.commissionServiceExt}/scheme-master/update`, params);
  }

  save(params: any): Observable<BasicResponse<SchemeMaster>> {
    return this.http.post<BasicResponse<SchemeMaster>>(`${environment.apiPrefix}${environment.commissionServiceExt}/scheme-master/add`, params);
  }
}

export interface SchemeMasterListItem {
  id: string;
  name: string;
  registrationNo: string;
  schemeCode: string;
  type: string;
  schemeNo: string;
  status: string;
  currentInflation: SalaryInflation;
}

export interface SalaryInflation {
  id: string;
  financialStartDateHkt: string;
  salaryInflationFactor: string | number;
  status: string;
}

export interface SchemeMaster extends SchemeMasterListItem {
  currentInflation: SalaryInflationDetail;
  nextInflation: SalaryInflationDetail;
  createTime: string;
  lastUpdateTime: string;
  version: number;
}

export interface SalaryInflationDetail extends SalaryInflation {
  createTime: string;
  version: number;
}
