<cds-popup [class.hidden]="!show" class="relative">
  <cds-popup-title>{{ 'campaign.upload-member-engagement-list' | lang }}</cds-popup-title>

  <cds-popup-content>
    <div class="cds-h6-demibold l-mb-4">
      {{ 'notification.fileRequirements' | lang }}
    </div>
    <div class="cds-detail1 l-mb-8">
      {{ 'campaign.upload-file-tip' | lang }}
    </div>

    <div class="l-mb-7">
      <div *ngFor="let item of checkResultInfoList" class="error-message">
        <div class="l-mb-4">
          <app-cd-inline-alert [showCloseBtn]="false" [title]="warningTitleRender | fn : item" [text]="warningTextRender | fn : item"></app-cd-inline-alert>

          <div *ngIf="item.messageType === CustomerListUploadErrorMessageTypeE.WRONG_MEMBER_ACCOUNT_CODE">
            <div class="failed-cases">Failed Cases:</div>

            <cds-table class="border-top" [dataSource]="tableDataTrans | fn : item.campaignErrorInfoList" [class.full]="true">
              <!-- Serial Number -->
              <ng-container cdsColumnDef="serialNumber">
                <cds-header-cell *cdsHeaderCellDef></cds-header-cell>
                <cds-cell *cdsCellDef="let element">{{ element.serialNumber }}</cds-cell>
              </ng-container>

              <!-- Campaign Name -->
              <ng-container cdsColumnDef="campaignName">
                <cds-header-cell *cdsHeaderCellDef> Campaign Name </cds-header-cell>
                <cds-cell *cdsCellDef="let element">{{ element.campaignName }}</cds-cell>
              </ng-container>

              <!-- Member Account Code -->
              <ng-container cdsColumnDef="memberAccountCode">
                <cds-header-cell *cdsHeaderCellDef> Member Account Code </cds-header-cell>
                <cds-cell *cdsCellDef="let element">{{ element.memberAccountCode }}</cds-cell>
              </ng-container>

              <cds-header-row *cdsHeaderRowDef="displayedColumns"></cds-header-row>
              <cds-row class="cds-body1" *cdsRowDef="let row; columns: displayedColumns"></cds-row>
            </cds-table>
          </div>
        </div>
      </div>
    </div>

    <div class="upload-area l-mb-4" [class.file-error]="showFileErrorMessage">
      <cds-file-upload
        #upload
        [disabled]="true"
        [formControl]="uploadFormControl"
        (change)="fileChange($event)"
        errorMessage="Invalid file uploaded."
        acceptedFiles="text/xlsx">
      </cds-file-upload>
    </div>

    <cds-form-field>
      <cds-assistive-text *ngIf="!fileValid.type">{{ 'campaign.only-excel' | lang }}</cds-assistive-text>
      <cds-assistive-text *ngIf="!fileValid.type && !fileValid.name">{{ 'campaign.incorrect-file-name' | lang }}</cds-assistive-text>
      <cds-assistive-text *ngIf="!fileValid.size">{{ 'campaign.maximum-file-size' | lang }}</cds-assistive-text>
    </cds-form-field>

    <app-loading *ngIf="isLoading" [overlayInPopup]="true"></app-loading>
  </cds-popup-content>

  <cds-popup-actions>
    <ng-container>
      <cds-button [config]="cancelButtonConfig" (click)="cancel()">{{ 'common.cancel' | lang }}</cds-button>

      <cds-button [config]="confirmButtonConfig" (click)="confirm()" [disabled]="noFileSelect || showFileErrorMessage || !checkOk">{{
        'common.upload' | lang
      }}</cds-button>
    </ng-container>
  </cds-popup-actions>
</cds-popup>
