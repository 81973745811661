<div class="row container" [formGroup]="form">
  <div class="col-xs-12">
    <div class="content">
      <div class="search-item">
        <div class="cds-body2 l-mr-5 label">Search By:</div>
        <cds-radio [config]="radioConfig" formControlName="searchBy" (cdsChange)="radioChange($event)"></cds-radio>
      </div>
      <div class="search-item" [class.hidden]="searchBy === 'MONTH'">
        <div class="cds-body2 l-mr-8 label">Date:</div>
        <div class="l-pb-4 l-plr-0 date-picker">
          <cds-textfield
            formControlName="date"
            placeholder="DD/MM/YYYY"
            (cdsChange)="onDateChange()"
            label=""
            [max]="getMaxFromDate | fn : maxTimeInStr"
            [cdsDatepicker]="datePicker">
          </cds-textfield>

          <cds-datepicker #datePicker="cdsDatepicker"></cds-datepicker>

          <div *ngIf="dateControl?.invalid && (dateControl?.dirty || dateControl?.touched)" class="l-pt-2">
            <cds-assistive-text *ngIf="dateControl?.errors?.['dateInvalid']" class="l-pt-2" [showIcon]="false">{{
              'create.dateError' | lang
            }}</cds-assistive-text>
          </div>
        </div>
      </div>

      <div class="search-item" [class.hidden]="searchBy === 'DAY'">
        <div class="cds-body2 l-mr-8 label">Month:</div>
        <div class="l-pb-4 l-plr-0 date-filter-container">
          <div class="date-filter">
            <cds-dropdown formControlName="month" [config]="monthCfg" (change)="dateMonthChange($event)" class="l-pr-5 month-selector"></cds-dropdown>
            <cds-dropdown formControlName="year" *ngIf="yearCfg" (change)="dateYearChange($event)" [config]="yearCfg"></cds-dropdown>
          </div>
        </div>
      </div>

      <div class="no-result" *ngIf="isLoading">
        <app-loading [overlay]="true"></app-loading>
      </div>

      <div class="no-result" *ngIf="!data.length">
        <app-no-result-found></app-no-result-found>
      </div>

      <ng-container *ngIf="data.length">
        <ng-container *ngFor="let item of data; index as index">
          <div class="period-container" *ngIf="index === 0 || showMore">
            <div class="period">
              <span class="cds-h4-demibold">{{ 'account.contribution-period' | lang }}</span>
              <span class="cds-h4-demibold">{{ item.contributionPeriodStartDate | ssmsDate }}</span>
              <span class="cds-h4-demibold"> - </span>
              <span class="cds-h4-demibold">{{ item.contributionPeriodEndDate | ssmsDate }}</span>
            </div>

            <div class="cds-color-green l-pt-5 l-mb-5 anchor-parent">
              <div class="anchor-element" id="paymentNotice"></div>
              <span class="cds-h5-demibold">{{ 'account.payment-notice' | lang }}</span>
            </div>

            <div class="table-container">
              <table class="table">
                <app-data-table
                  [frontPagination]="true"
                  [isShowPagination]="false"
                  [fixed]="false"
                  [overlay]="true"
                  [dataSource]="item.erProfilePaymentNoticeListResponses || []"
                  [columnsConfig]="columnsConfig">
                  <ng-template appDtBodyCellDef="noticeIssueDate" let-element>
                    {{ element['noticeIssueDate'] | date : 'dd/MM/yyyy' }}
                  </ng-template>

                  <ng-template appDtBodyCellDef="firstPaymentDueDate" let-element>
                    {{ element['firstPaymentDueDate'] | date : 'dd/MM/yyyy' }}
                  </ng-template>

                  <ng-template appDtBodyCellDef="secondPaymentDueDate" let-element>
                    {{ element['secondPaymentDueDate'] | date : 'dd/MM/yyyy' }}
                  </ng-template>
                </app-data-table>
              </table>
            </div>

            <div class="cds-color-green l-pt-5 l-mb-5 anchor-parent">
              <div class="anchor-element" id="appealDetails"></div>
              <span class="cds-h5-demibold">{{ 'account.appeal-details' | lang }}</span>
            </div>

            <div class="table-container">
              <app-no-result-found
                *ngIf="
                  !item?.erProfilePaymentNoticeListResponses || !item?.erProfilePaymentNoticeListResponses?.length;
                  else appealDetailTable
                "></app-no-result-found>
              <ng-template #appealDetailTable>
                <table class="table">
                  <caption></caption>
                  <!-- header -->
                  <tr class="table-title l-pt-2 l-pb-2 cds-dark-navy-text light-3">
                    <th *ngFor="let item of apealDetailsColumnsConfig">{{ item.title }}</th>
                  </tr>
                  <ng-container *ngFor="let element of item?.erProfilePaymentNoticeListResponses; let index = index">
                    <!-- line data -->
                    <tr [class.border-bottom]="!element.appealResultText">
                      <td class="main-text">
                        {{ element.appealSubmissionDate | ssmsDate }}
                      </td>
                      <td>
                        {{ element.appealResultStatus }}
                      </td>
                      <td>
                        {{ element.dateOfAppealResult | ssmsDate }}
                      </td>
                      <td>
                        {{ element.mpfaObjectionCaseNumber }}
                      </td>
                    </tr>
                    <!-- line data appealResultText -->
                    <tr *ngIf="!!element.appealResultText" class="border-bottom">
                      <td colspan="4" class="remark-td cds-detail2-demibold" l-pt-0>
                        <div class="remark-area line-break">
                          <div>
                            <div class="remarks">Appeal Result Text:</div>
                            <div>{{ element.appealResultText }}</div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </ng-container>
                </table>
              </ng-template>
            </div>
          </div>
        </ng-container>
      </ng-container>

      <div class="col-xs-12 l-mt-5 more-button-area" *ngIf="data.length && !showMore">
        <cds-button [config]="moreBtnCfg" size="sm" (click)="clickShowMore()">
          <div class="button-area">
            <div class="div-icon">+</div>
            <span class="l-ml-2">View More Records</span>
          </div>
        </cds-button>
      </div>
    </div>
  </div>
</div>

<!-- <div class="row">
  <div class="col-xs-12 cds-color-green l-pt-5 anchor-parent">
    <div class="anchor-element" id="paymentNotice"></div>
    <span class="cds-h5-demibold">{{ 'account.payment-notice' | lang }}</span>
  </div>


  <div class="col-xs-12 p-line"></div>
  <div class="col-xs-12 p-line"></div>
</div>



<div class="row">
  <app-row-item label="account.type-of-notice" data="{{ data?.typeOfNotice }}" class="col-xs-6 col-sm-3 col-md-3 col-lg-3"> </app-row-item>
  <app-row-item
    label="account.payment-notice-received-date"
    data="{{ data?.paymentNoticeReceivedDate | ssmsDate }}"
    class="col-xs-6 col-sm-3 col-md-3 col-lg-3">
  </app-row-item>
  <app-row-item label="account.notice-reference-number" data="{{ data?.noticeReferenceNumber }}" class="col-xs-6 col-sm-3 col-md-3 col-lg-3"> </app-row-item>
  <app-row-item label="account.notice-issue-date" data="{{ data?.noticeIssueDate | ssmsDate }}" class="col-xs-6 col-sm-3 col-md-3 col-lg-3"> </app-row-item>
  <app-row-item label="account.first-payment-due-date" data="{{ data?.firstPaymentDueDate | ssmsDate }}" class="col-xs-6 col-sm-3 col-md-3 col-lg-3">
  </app-row-item>
  <app-row-item label="account.second-payment-due-date" data="{{ data?.secondPaymentDueDate | ssmsDate }}" class="col-xs-6 col-sm-3 col-md-3 col-lg-3">
  </app-row-item>
</div>

<div class="row">
  <div class="col-xs-12 cds-color-green l-pt-5 anchor-parent">
    <div class="anchor-element" id="appealDetails"></div>
    <span class="cds-h5-demibold">{{ 'account.appeal-details' | lang }}</span>
  </div>
  <div class="col-xs-12 p-line"></div>
</div>

<div class="row">
  <app-row-item label="account.appeal-submission-date" data="{{ data?.appealSubmissionDate | ssmsDate }}" class="col-xs-6 col-sm-3 col-md-3 col-lg-3">
  </app-row-item>
  <app-row-item label="account.appeal-result-status" data="{{ data?.appealResultStatus }}" class="col-xs-6 col-sm-3 col-md-3 col-lg-3"> </app-row-item>
  <app-row-item label="account.date-of-appeal-result" data="{{ data?.dateOfAppealResult | ssmsDate }}" class="col-xs-6 col-sm-3 col-md-3 col-lg-3">
  </app-row-item>
  <app-row-item label="account.mpfa-objection-case-no" data="{{ data?.mpfaObjectionCaseNumber }}" class="col-xs-6 col-sm-3 col-md-3 col-lg-3"> </app-row-item>
  <app-row-item label="account.appeal-result" data="{{ data?.appealResultText }}" class="col-xs-12"> </app-row-item>
</div> -->
