import { Component, Inject, Input, LOCALE_ID, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CdsButtonConfig } from '@cds/ng-core/button';
import { CdsColorTheme } from '@cds/ng-core/configuration';
import { CdsTextfieldConfig } from '@cds/ng-core/textfield';
import { ActionIcons, ArrowIcons } from '@cds/ng-themes';
import { LanguageChangeService } from 'src/app/shared/language-change.service';
import { Subscription } from 'rxjs';
import { EmployerHistoryRequest } from './employer-history-request';
import { EmployerHistroyService } from './employer-histroy.service';

import { EmployerInfo, HistoryResponseData, Items } from './history-response';
import { CdsLangService } from '@cds/ng-core/lang';
import { CdsIconConfig } from '@cds/ng-core/icon';
import { formatDate } from '@angular/common';
import { ErChangeSummaryDetailPopComponent } from '../er-change-summary-detail-pop/er-change-summary-detail-pop.component';
import { CdsPopupService } from '@cds/ng-web-components/popup';
import { FormControl } from '@angular/forms';
import { CdsDropdownConfig } from '@cds/ng-core/dropdown';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CdsAlertService } from '@cds/ng-web-components/alert';
import { ErChangeSummaryData } from '../er-change-summary-detail-pop/er-chenge-summary-data';
import moment from 'moment';
import { CdsProgressType } from '@cds/ng-core/progress';

@Component({
  selector: 'app-employer-history',
  templateUrl: './employer-history.component.html',
  styleUrls: ['./employer-history.component.scss'],
})
export class EmployerHistoryComponent implements OnInit, OnDestroy {
  @Input() fullWidth = true;
  @Input() color: CdsColorTheme = 'ml';
  @Input() summaryPageCount = 1;
  @Input() summaryPage = 1;

  resetDatePicker = false;
  ifShow = false;
  language = this.cdsLangService.currentLang === 'en' ? 'EN' : 'TC';
  employerName = '';
  groupNo = '';
  dlgtAgentCode = '';
  employerTotalCount = 0;
  DataSource = [];
  reqState = '';
  startDt = '';
  endDt = '';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  summaryList: HistoryResponseData[] = [];
  rightArrowIcon = ArrowIcons.right;
  detailIcon = ActionIcons.button_right_outlined;
  myRqeuest: EmployerHistoryRequest = {
    page: 1,
    size: 10,
    language: this.language,
    sort: 'requestCreateDate desc',
  };
  allSortErIcon = ArrowIcons.sort;
  ascSortIcon = ArrowIcons.up;
  descSortIcon = ArrowIcons.down;
  erNameSort = '';
  groupNoSort = '';
  createSort = '';
  statusSort = '';
  sortIcon = '';
  cdsProgressType: CdsProgressType = 'ring';
  cdsProgressTxt = 'loading';
  cdsProgressColor: CdsColorTheme = 'ml';
  summaryIsShowProgress = true;
  lanChangeSubscription?: Subscription;
  private employerHistroyScription?: Subscription;
  private errorSub?: Subscription;
  fixTextRemains = false;

  constructor(
    private router: Router,
    public employerHistroyService: EmployerHistroyService,
    private languageChangeService: LanguageChangeService,
    private cdsLangService: CdsLangService,
    private cdsPopup: CdsPopupService,
    @Inject(LOCALE_ID) public locale: string,
    private alert: CdsAlertService,
    public dialog: MatDialog
  ) {}
  ngOnDestroy(): void {
    this.employerHistroyScription?.unsubscribe();
    this.lanChangeSubscription?.unsubscribe();
    this.errorSub?.unsubscribe();
  }

  ngOnInit(): void {
    this.employerHistroyScription = this.employerHistroyService.employeeDataSubject.subscribe(response => {
      if (response.result != 0) {
        this.summaryList = [];
        this.employerTotalCount = 0;
        this.summaryPageCount = 0;
      } else {
        this.summaryList = response.data ? response.data.content : [];
        this.employerTotalCount = response.data ? response.data.totalElements : 0;
        this.summaryPageCount = response.data ? response.data.totalPages : 0;
        if (this.summaryList) {
          this.summaryList.forEach(element => {
            element.isShowDetail = false;
          });
        }
        console.log(this.summaryList);
      }
      this.summaryIsShowProgress = false;
    });

    this.lanChangeSubscription = this.languageChangeService.lanSubject.subscribe(languge => {
      this.language = languge === 'en' ? 'EN' : 'TC';
      this.myRqeuest.language = this.language;
      this.fixTextRemains = true;
      setTimeout(() => {
        this.fixTextRemains = false;
      }, 0);
    });

    this.errorSub = this.employerHistroyService.errorEmployeeSubject.subscribe(errorMessage => {
      const alertTitle = 'Got Error!';
      this.alert.error(alertTitle, errorMessage);
    });
    this.setCreateDate();
  }

  backErIconConfig: CdsIconConfig = {
    color: 'cta',
    size: 'sm',
  };

  employerNameTxtfieldConfig: CdsTextfieldConfig = {
    label: this.language === 'EN' ? 'Employer Name' : '僱主名稱',
    type: 'text',
    maxlength: 20,
    color: 'ml',
    placeholder: this.language === 'EN' ? 'Input employer name' : '輸入雇主名稱',
  };

  numbersTxtfieldConfig: CdsTextfieldConfig = {
    label: this.language === 'EN' ? 'Group No.' : '僱主編號',
    type: 'text',
    placeholder: this.language === 'EN' ? 'Input group no.' : '輸入組編號',
  };

  agentTxtfieldConfig: CdsTextfieldConfig = {
    label: this.language === 'EN' ? 'Delegated Agent Code' : '委託代理編號',
    type: 'text',
    placeholder: this.language === 'EN' ? 'Input delegated agent code' : '輸入代理代碼',
  };

  stateFormControl: FormControl = new FormControl();
  statusEnRangeDropdownConfig: CdsDropdownConfig = {
    label: 'Request Status',
    options: [
      { label: 'Approved', value: '2' },
      { label: 'Declined', value: '4' },
      { label: 'Partially Approved', value: '3' },
      { label: 'Pending DD Approval', value: '1' },
    ],
    placeholder: 'Select Request Status',
    color: 'ml',
  };

  statusZhRangeDropdownConfig: CdsDropdownConfig = {
    label: '请求状态',
    options: [
      { label: '審核通過', value: '2' },
      { label: '拒絕', value: '4' },
      { label: '部分批准', value: '3' },
      { label: '待定 DD 批准', value: '1' },
    ],
    placeholder: '選擇請求狀態',
    color: 'ml',
  };

  primaryButtonConfig: CdsButtonConfig = {
    size: 'sm',
    style: 'primary',
  };

  secondaryButtonConfig: CdsButtonConfig = {
    size: 'sm',
    style: 'secondary',
  };

  displayedColumns: string[] = ['employerName', 'groupNo', 'requestCreatedDate', 'requestStatus'];

  nameChange(result: string): void {
    this.employerName = result;
    if (result === '') {
      this.employerName = '';
    }
    this.searchCriteriaCheck();
  }

  groupNoChange(result: string): void {
    this.groupNo = result;
    if (result === '') {
      this.groupNo = '';
    }
    this.searchCriteriaCheck();
  }

  delegateAgentChange(result: string): void {
    this.dlgtAgentCode = result;
    if (result === '') {
      this.dlgtAgentCode = '';
    }
    this.searchCriteriaCheck();
  }

  reqStatusChange(result: string): void {
    this.reqState = result;
    if (result === null || result === '') {
      this.reqState = '';
    }
    this.searchCriteriaCheck();
  }
  startTime = '';
  endTime = '';

  startTimeChange(event: string) {
    this.startTime = event;
  }

  endTimeChange(event: string) {
    this.endTime = event;
  }

  setCreateDate() {
    this.myRqeuest.createDtEd = formatDate(new Date(), 'yyyy-MM-dd', this.locale);
    const endDate = String(moment().subtract(30, 'day'));
    this.myRqeuest.createDtSt = formatDate(endDate, 'yyyy-MM-dd', this.locale);
  }

  searchCriteriaCheck(): void {
    if (this.employerName === '' && this.groupNo === '' && this.dlgtAgentCode === '' && this.reqState === '') {
      this.ifShow = false;
    }
  }

  employerSearch() {
    if (this.searchBtnDisableState) {
      return;
    }
    this.trimReq();
    this.summaryIsShowProgress = true;
    this.employerHistroyService.getEmployees(this.myRqeuest);

    this.erNameSort = '';
    this.groupNoSort = '';
    this.createSort = '';
    this.statusSort = '';
  }

  trimReq() {
    if (this.startTime != '' && this.endTime != '') {
      const start = this.startTime.split('/');
      const end = this.endTime.split('/');

      this.myRqeuest.createDtSt = formatDate(new Date([start[1], start[0], start[2]].join('/')), 'yyyy-MM-dd', this.locale);
      this.myRqeuest.createDtEd = formatDate(new Date([end[1], end[0], end[2]].join('/')), 'yyyy-MM-dd', this.locale);
    }
    this.myRqeuest.employerName = this.employerName;
    this.myRqeuest.employerNo = this.groupNo;
    this.myRqeuest.delegateAgentCode = this.dlgtAgentCode;
    this.myRqeuest.status = this.reqState;
  }

  clearData() {
    this.startTime = '';
    this.endTime = '';
    this.stateFormControl.setValue('');
    this.reqState = '';
    this.employerName = '';
    this.groupNo = '';
    this.dlgtAgentCode = '';
    this.myRqeuest.page = 1;
    this.myRqeuest.size = 10;
    this.myRqeuest.sort = 'requestCreateDate desc';
    this.myRqeuest.language = this.language;
    this.summaryIsShowProgress = true;
    this.trimReq();
    this.setCreateDate();
    this.pageChange(1);

    this.onResetDatePicker();

    this.erNameSort = '';
    this.groupNoSort = '';
    this.createSort = '';
    this.statusSort = '';
  }

  addDlgtRequest() {
    this.router.navigate(['agent/er-delegation-change']);
  }

  forwordDetil(info: EmployerInfo, detailNo: string, detail: Items) {
    const detailReq = new ErChangeSummaryData();
    detailReq.serialDetailNo = detailNo;
    detailReq.language = this.language;
    detailReq.employerName = this.language === 'EN' ? info.employerName : info.employerNameTC ? info.employerNameTC : info.employerName;
    detailReq.oldAgent = detail.oldAgent;
    detailReq.sumbitAgent = detail.targetAgent;

    // const employerNameTC = info.employerNameTC
    //   ? info.employerNameTC
    //   : info.employerName
    //   ? info.employerName
    //   : '';
    // this.router.navigate(['agent/employee-summary/' + info.id], {
    //   queryParams: { en: info.employerName, zh: employerNameTC },
    // });

    const popupRef: MatDialogRef<ErChangeSummaryDetailPopComponent> = this.cdsPopup.open(ErChangeSummaryDetailPopComponent, {
      size: 'lg',
      data: { list: detailReq },
    });
    popupRef.afterClosed().subscribe(data => {
      console.log('data', data);
    });

    // const dialogRef = this.dialog.open(ErChangeSummaryDetailPopComponent, {
    //   data: { list: detailReq },
    // });
    // dialogRef.afterClosed().subscribe(result => {
    //   console.log(`Dialog result: ${result}`);
    // });
  }

  getforMateDate(dates: string): string {
    return formatDate(dates, 'dd/MM/yyyy', this.locale);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  doClickDetail(e: any, model: any) {
    const obj = e.target.getElementsByClassName('icon')[0];
    if (obj.classList.contains('backrotate')) {
      obj.classList.add('gorotate');
      obj.classList.remove('backrotate');
      e.target.classList.add('short-des');
      model.isShowDetail = true;
    } else {
      obj.classList.add('backrotate');
      obj.classList.remove('gorotate');
      e.target.classList.remove('short-des');
      model.isShowDetail = false;
    }
  }

  pageChange(num: number): void {
    this.summaryPage = num;
    this.myRqeuest.page = num;
    this.employerHistroyService.getEmployees(this.myRqeuest);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  searchByInputPageNo($event: any, pageNo: string) {
    $event.preventDefault();
    let goToPage = 1;
    if (isNaN(parseInt(pageNo))) {
      goToPage = 1;
    } else if (+pageNo > this.summaryPageCount) {
      goToPage = this.summaryPageCount;
    } else {
      goToPage = +pageNo;
    }

    this.pageChange(goToPage);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSortChange(sort: any) {
    switch (sort.active) {
      case 'employerName':
        this.groupNoSort = '';
        this.createSort = '';
        this.statusSort = '';
        this.erNameSort === '' ? (this.erNameSort = 'asc') : this.erNameSort === 'asc' ? (this.erNameSort = 'desc') : (this.erNameSort = '');
        break;
      case 'employerNo':
        this.erNameSort = '';
        this.createSort = '';
        this.statusSort = '';
        this.groupNoSort === '' ? (this.groupNoSort = 'asc') : this.groupNoSort === 'asc' ? (this.groupNoSort = 'desc') : (this.groupNoSort = '');
        break;
      case 'requestCreateDate':
        this.erNameSort = '';
        this.groupNoSort = '';
        this.statusSort = '';
        this.createSort === '' ? (this.createSort = 'asc') : this.createSort === 'asc' ? (this.createSort = 'desc') : (this.createSort = '');
        break;
      case 'status':
        this.erNameSort = '';
        this.groupNoSort = '';
        this.createSort = '';
        this.statusSort === '' ? (this.statusSort = 'asc') : this.statusSort === 'asc' ? (this.statusSort = 'desc') : (this.statusSort = '');
        break;
      default:
        this.erNameSort = '';
        this.groupNoSort = '';
        this.createSort = '';
        this.statusSort = '';
        break;
    }

    //  const sor= this.erNameSort ===2 ? "employerName asc" : this.erNameSort === 3 ? "employerName desc" : "";
    const empSort = sort.active == '' ? 'requestCreateDate desc' : sort.active + ' ' + sort.direction;
    this.myRqeuest.sort = empSort;
    this.employerHistroyService.getEmployees(this.myRqeuest);
  }

  private onResetDatePicker() {
    this.resetDatePicker = true;
    setTimeout(() => {
      this.resetDatePicker = false;
    }, 0);
  }

  get searchBtnDisableState() {
    return !(
      this.employerName.length > 0 ||
      this.groupNo.length > 0 ||
      this.dlgtAgentCode.length > 0 ||
      this.stateFormControl.value?.length > 0 ||
      (this.startTime.length > 0 && this.endTime.length > 0)
    );
  }
}
