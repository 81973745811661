export class CaseSummaryResponse {
  result?: number;
  message?: string;
  data?: CaseSummaryResponseBody;

  constructor(result: number, message: string) {
    this.result = result;
    this.message = message;
  }
}

export interface CaseSummaryResponseBody {
  content: CaseSummaryResponseData[];
  pageable: Pageable;
  last: boolean;
  totalPages: number;
  totalElements: number;
  first: boolean;
  numberOfElements: number;
  size: number;
  number: number;
  empty: boolean;
}

export interface Pageable {
  pageNumber: number;
  pageSize: number;
  offset?: number;
  paged?: boolean;
  unpaged?: boolean;
}

export interface CaseSummaryResponseData {
  salesJourneySummaryId: string;
  customerId?: string;
  empfRefNo: string;
  productType: string;
  clientFirstName: string;
  clientFirstNameTC: string;
  clientLastName: string;
  clientLastNameTC: string;
  dateOfBirth: string;
  hkidOrPassportNo: string;
  iffDeclarationDate: string;
  iffStatus: number;
  iffStatusName: string;
  empfStatus: number;
  empfStatusName: string;
  salesJourneyStatus: number;
  splitCase: boolean;
}
