import { Component, OnDestroy, OnInit } from '@angular/core';

import { CdsButtonConfig } from '@cds/ng-core/button';
import { MatDialogRef } from '@cds/ng-web-components/popup';
import { Subscription } from 'rxjs';
import { LanguageChangeService } from 'src/app/shared/language-change.service';

@Component({
  selector: 'app-user-exit-editmode-pop',
  templateUrl: './user-exit-editmode-pop.component.html',
  styleUrls: ['./user-exit-editmode-pop.component.scss'],
})
export class UserExitEditmodePopComponent implements OnInit, OnDestroy {
  language = 'EN';
  lanSubscription?: Subscription;

  constructor(public languageChangeService: LanguageChangeService, private dialogRef: MatDialogRef<UserExitEditmodePopComponent>) {}

  agreeButtonConfig: CdsButtonConfig = {
    label: this.language === 'EN' ? 'Confirm and Continue' : '確認繼續',
    size: 'sm',
    style: 'primary',
    disabled: false,
  };

  disagreeButtonConfig: CdsButtonConfig = {
    label: this.language === 'EN' ? 'Cancel' : '取消',
    size: 'sm',
    style: 'primary',
    disabled: false,
  };

  ngOnInit(): void {
    this.lanSubscription = this.languageChangeService.lanSubject.subscribe(lan => {
      this.language = lan === 'en' ? 'EN' : 'TC';
    });
  }

  ngOnDestroy(): void {
    this.lanSubscription?.unsubscribe();
  }

  agree() {
    this.dialogRef.close({ agree: true });
  }

  disagree() {
    this.dialogRef.close({ agree: false });
  }
}
