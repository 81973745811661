<div class="row p-tab l-pt-8">
  <div class="col-xs-12">
    <app-cd-fake-tabs #cdFakeTabs [options]="['Employer', 'Member']" (selectedIndexChange)="fakeTabIndexChange($event)"></app-cd-fake-tabs>
  </div>
</div>
<div [class.hidden]="tabIndex !== 0">
  <div class="row">
    <div class="col-xs-12">Todo</div>
  </div>
</div>
<app-campaign-master-list-member [class.hidden]="tabIndex !== 1"></app-campaign-master-list-member>
