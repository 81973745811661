<div class="l-pa-7 l-pt-5">
  <div class="card">
    <div class="top">
      <div class="icon-area">
        <cds-icon icon="environment:office_building"></cds-icon>
      </div>
      <div class="text-area">
        <div class="label cds-body2">Trustee Employer</div>
        <div class="value cds-body1-demibold row no-padding">
          <div class="no-padding l-mr-4">{{ '12345678' }}</div>
          <div class="no-padding l-mr-4">{{ 'Winwin Limited' }}</div>
          <div class="no-padding l-mr-4">{{ '盈盈有限公司' }}</div>
        </div>
      </div>
    </div>
    <div class="bottom">
      <div class="space-width no-padding"></div>
      <div class="content-container">
        <div class="row no-padding">
          <div class="label cds-body2 col-xs-12 col-sm-6 col-md-6 col-lg-6 no-padding">Commencement date</div>
          <div class="cds-body1 col-6 col-xs-12 col-sm-6 col-md-6 col-lg-6 no-padding">2022-02-16</div>
        </div>

        <div class="row no-padding">
          <div class="cds-body2 label col-xs-12 col-sm-6 col-md-6 col-lg-6 no-padding">Status</div>
          <div class="status-area col-xs-12 col-sm-6 col-md-6 col-lg-6 no-padding">
            <div class="dot-icon"></div>
            <div class="cds-body1">Active</div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="line l-mb-6"></div>

  <div class="cds-body1-demibold">Important Dates</div>

  <div class="line l-mb-4"></div>

  <div class="date-area row no-padding">
    <app-row-item class="col-xs-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 no-padding" [label]="'Date joining the scheme'" [data]="'12/04/2016'"></app-row-item>
    <app-row-item class="col-xs-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 no-padding" [label]="'Date of employment'" [data]="'12/04/2016'"></app-row-item>
    <app-row-item class="col-xs-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 no-padding" [label]="'Original employment date'" [data]="'12/04/2016'"></app-row-item>
    <app-row-item class="col-xs-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 no-padding" [label]="'Expatriate visa issue date'" [data]="'12/04/2016'"></app-row-item>
  </div>

  <div class="line l-mb-6"></div>

  <div class="cds-body1-demibold">Signal</div>

  <div class="line l-mb-4"></div>

  <div class="signal-area row no-padding">
    <cds-checkbox class="col-xs-12 col-sm-6 col-md-4 col-lg-3 no-padding" [checked]="true" [disabled]="true" [label]="'MPF Trf Ben Signal'"> </cds-checkbox>
    <cds-checkbox class="col-xs-12 col-sm-6 col-md-4 col-lg-3 no-padding" [checked]="false" [disabled]="true" [label]="'O/S Cont Signal'"> </cds-checkbox>
    <cds-checkbox class="col-xs-12 col-sm-6 col-md-4 col-lg-3 no-padding" [checked]="false" [disabled]="true" [label]="'RS Term Signal'"> </cds-checkbox>
  </div>
</div>
