<div class="row">
  <app-loading *ngIf="isLoading" [overlayInPopup]="true"></app-loading>
  <!-- <div class="col-xs-12 l-mt-7 l-mb-2">
    <span class="cds-body1"
      >Lorem ipsum dolor sit amet. Qui autem impedit est tenetur reiciendis et sunt rerum qui quisquam animi et repudiandae rerum ab cumque dolore ut accusamus
      ipsum. Doloribus aliquid quo delectus atque aut corporis eius eum maiores optio in autem consequatur quo quidem officiis. Est nisi delectus et laboriosam
      galisum est nihil tempore quo beatae quibusdam. 33 velit omnis in dignissimos saepe aut atque consectetur et dolores mollitia in tenetur galisum nam illo
      eveniet!
    </span>
  </div> -->

  <div class="col-xs-12 l-pt-7">
    <span class="cds-h2-light">{{ 'list.serach-record' | lang }}</span>
  </div>
  <div class="row no-padding search-container col-xs-12" [formGroup]="form" *ngIf="formReady">
    <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 l-pt-4 l-pb-4">
      <cds-textfield formControlName="employerAccountCode" label="Employer Account Code" placeholder="Input Employer Account Code"></cds-textfield>

      <div *ngIf="employerAccountCodeControl?.invalid && (employerAccountCodeControl?.dirty || employerAccountCodeControl?.touched)" class="l-pt-2">
        <cds-assistive-text *ngIf="employerAccountCodeControl?.errors?.['maxlength']" class="l-pt-2" [showIcon]="false">Max length is 15</cds-assistive-text>

        <cds-assistive-text
          *ngIf="employerAccountCodeControl?.errors?.['invalidNumberString']"
          class="l-pt-2"
          [showIcon]="false"
          >{{ employerAccountCodeControl?.errors?.['invalidNumberString'] }}</cds-assistive-text
        >
      </div>
    </div>

    <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 l-pt-4 l-pb-4">
      <cds-textfield formControlName="employerName" label="Employer Name" placeholder="Input Employer Name"></cds-textfield>

      <div *ngIf="employerNameControl?.invalid && (employerNameControl?.dirty || employerNameControl?.touched)" class="l-pt-2">
        <cds-assistive-text *ngIf="employerNameControl?.errors?.['maxlength']" class="l-pt-2" [showIcon]="false">Max length is 200</cds-assistive-text>

        <cds-assistive-text
          *ngIf="employerNameControl?.errors?.['descriptionInvalid']"
          class="l-pt-2"
          [showIcon]="false"
          >{{ employerNameControl?.errors?.['descriptionInvalid'] }}</cds-assistive-text
        >
      </div>
    </div>

    <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 l-pt-4 l-pb-4">
      <cds-textfield formControlName="sqReferenceNo" label="SQ Reference No." placeholder="Input SQ Reference No."></cds-textfield>

      <div *ngIf="sqReferenceNoControl?.invalid && (sqReferenceNoControl?.dirty || sqReferenceNoControl?.touched)" class="l-pt-2">
        <cds-assistive-text *ngIf="sqReferenceNoControl?.errors?.['maxlength']" class="l-pt-2" [showIcon]="false">Max length is 50</cds-assistive-text>

        <cds-assistive-text
          *ngIf="sqReferenceNoControl?.errors?.['descriptionInvalid']"
          class="l-pt-2"
          [showIcon]="false"
          >{{ sqReferenceNoControl?.errors?.['descriptionInvalid'] }}</cds-assistive-text
        >
      </div>
    </div>

    <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 l-pt-4 l-pb-4">
      <cds-dropdown formControlName="quoteStatus" [config]="statusConfig"></cds-dropdown>
    </div>

    <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 l-pt-4 l-pb-4">
      <cds-dropdown formControlName="quoteType" [config]="quoteTypeConfig"></cds-dropdown>
    </div>

    <div *ngIf="showDatePicker" class="row col-xs-12 col-sm-12 col-md-8 col-lg-6 l-pt-4 l-pb-4 l-plr-0 date-picker-area relative">
      <div class="col-xs-6 date-picker">
        <cds-textfield
          formControlName="sqSubmissionDateFrom"
          placeholder="DD/MM/YYYY"
          label="SQ Submission Date From"
          [max]="getMaxFromDate | fn : sqSubmissionDateToValue || nowTimeInstr"
          [cdsDatepicker]="startPicker">
        </cds-textfield>
        <cds-datepicker #startPicker="cdsDatepicker"></cds-datepicker>

        <div *ngIf="sqSubmissionDateFromControl?.invalid && (sqSubmissionDateFromControl?.dirty || sqSubmissionDateFromControl?.touched)" class="l-pt-2">
          <cds-assistive-text
            *ngIf="sqSubmissionDateFromControl?.errors?.['dateInvalid'] || sqSubmissionDateFromControl?.errors?.['startLaterThanEnd']"
            class="l-pt-2"
            [showIcon]="false"
            >{{ 'create.dateError' | lang }}</cds-assistive-text
          >
        </div>
      </div>
      <div class="connector">-</div>
      <div class="col-xs-6 date-picker">
        <cds-textfield
          formControlName="sqSubmissionDateTo"
          placeholder="DD/MM/YYYY"
          label="To"
          [min]="getMinToDate | fn : sqSubmissionDateFromValue"
          [max]="maxTime"
          [cdsDatepicker]="endPicker">
        </cds-textfield>
        <cds-datepicker #endPicker="cdsDatepicker"></cds-datepicker>

        <div *ngIf="sqSubmissionDateToControl?.invalid && (sqSubmissionDateToControl?.dirty || sqSubmissionDateToControl?.touched)" class="l-pt-2">
          <cds-assistive-text
            *ngIf="sqSubmissionDateToControl?.errors?.['dateInvalid'] || sqSubmissionDateToControl?.errors?.['startLaterThanEnd']"
            class="l-pt-2"
            [showIcon]="false"
            >{{ 'create.dateError' | lang }}</cds-assistive-text
          >
        </div>
      </div>
    </div>

    <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 l-pb-4 bottom-xs search-button-container">
      <cds-button
        class="l-mr-4 botton"
        [config]="searchButtonConfig"
        [disabled]="searchButtonDisabled"
        label="{{ 'list.search' | lang }}"
        (click)="search()"></cds-button>
      <cds-button class="botton" [config]="resetButtonConfig" [disabled]="resetButtonDisabled" label="{{ 'list.reset' | lang }}" (click)="reset()"></cds-button>
    </div>
  </div>

  <div class="col-xs-12 col-md-12 l-pb-3 l-d-f l-jc-sb l-mt-7 l-mb-4 l-ai-cen">
    <div class="col-xs-6 l-plr-0">
      <div class="col-xs-12 l-pt-4 l-plr-0">
        <span class="cds-h2-light l-ma-0">{{ 'common.recordList' | lang }}</span>
      </div>
      <div class="col-xs-12 l-plr-0">
        <span class="cds-detail1-demibold">Total {{ numberOfElements | number }} records</span>
      </div>
    </div>
    <div class="l-pt-4">
      <app-permission [role]="PermissionAccess.W" [permission]="PermissionItem.CUSTOMER_EMPLOYER_SQ">
        <cds-button [fullWidth]="false" [config]="exportButtonConfig" label="{{ 'common.export-result' | lang }}" (click)="export()"></cds-button>
      </app-permission>
    </div>
  </div>

  <ng-template #greenLineTemplate let-element>
    <div (click)="goToDetail(element.customerId)" class="col-xs middle-xs l-plr-0">
      <div>
        <span class="green-line pointer">
          <span class="cds-body1-demibold">{{ element['employerName'] }}</span>
        </span>
      </div>
      <div>
        <span class="green-line pointer">
          <span class="cds-body1-demibold">{{ element['employerChineseName'] }}</span>
        </span>
      </div>
    </div>
  </ng-template>

  <ng-template #sqSubmissionDateTemplate let-element>
    {{ element.sqSubmissionDate | ssmsDate }}
  </ng-template>

  <ng-template #quoteStatusTemplate let-element>
    {{ service.optionLabelRender | fn : element.quoteStatus : statusConfig.options }}
  </ng-template>

  <ng-template #template let-element>
    <div class="row col-xs-12 l-plr-0">
      <app-row-item
        label="employer.quote-type"
        [data]="service.optionLabelRender | fn : element.quoteType : quoteTypeConfig.options"
        class="col-xs-6 col-sm-6 col-md-4 col-lg-4 l-pt-4 l-pb-4"></app-row-item>
      <app-row-item
        label="employer.last-status-update-date"
        [data]="element.lastStatusUpdatedDate | ssmsDate"
        class="col-xs-6 col-sm-6 col-md-4 col-lg-4 l-pt-4 l-pb-4"></app-row-item>
      <app-row-item label="account.remarks" [data]="element.remarks" class="col-xs-12 l-pt-4 l-pb-4"></app-row-item>
    </div>
  </ng-template>

  <div class="col-xs-12">
    <app-table-expand (sortChange)="sortChange($event)" [expandTemplate]="template" [data]="dataSource">
      <app-table-expand-col [sort]="Sort.DEFAULT" prop="sqReferenceNo" label="SQ Reference No."></app-table-expand-col>
      <app-table-expand-col [sort]="Sort.DEFAULT" prop="employerAccountCode" label="Employer Account Code"></app-table-expand-col>
      <app-table-expand-col [sort]="Sort.DEFAULT" [template]="greenLineTemplate" prop="employerName" label="Employer Name"></app-table-expand-col>
      <app-table-expand-col
        [sort]="Sort.DEFAULT"
        [template]="sqSubmissionDateTemplate"
        prop="sqSubmissionDate"
        label="SQ Submission Date"></app-table-expand-col>
      <app-table-expand-col [template]="quoteStatusTemplate" prop="quoteStatus" label="Status"></app-table-expand-col>
    </app-table-expand>
  </div>

  <div *ngIf="dataSource.length > 0" class="col-xs-12">
    <div class="col-xs-12 col-md-12 l-pt-4 l-pb-4 l-d-f l-jc-sb">
      <app-pagination [pageCount]="pageCount" [isLoading]="isLoading" [useOf]="true" (pageChange)="pageChange($event)"> </app-pagination>
    </div>
  </div>
</div>
