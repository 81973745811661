/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit, AfterViewInit, OnDestroy, HostListener, ViewChild, ElementRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { CdsDropdownConfig } from '@cds/ng-core/dropdown';
import { ActionIcons } from '@cds/ng-themes';
import { CdsIconConfig } from '@cds/ng-core/icon';
import { AdviseEmpfrefComponent } from '../case-summary/advise-empfref/advise-empfref.component';
import { CdsPopupService } from '@cds/ng-web-components/popup';
import { CdsAlertService } from '@cds/ng-web-components/alert';
import { CdHttpServeService } from 'src/app/shared/cd-http-serve/cd-http-serve.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifyService } from 'src/app/core/services/notify.service';
import { pageToTop } from 'src/app/utils/utils';
import {
  SuperAdminEEReqeuestParams,
  SuperAdminERReqeuestParams,
  SuperAdminEERecordItem,
  SuperAdminERRecordItem,
  SuperAdminRecordItem,
} from './super-admin.model';
import { SalesJourneyProdType } from '../case-summary/case-summary.model';
import { EEProdType } from '../iff-paper-form/individuals/individuals.model';

@Component({
  selector: 'app-super-admin',
  templateUrl: './super-admin.component.html',
  styleUrls: ['./super-admin.component.scss'],
})
export class SuperAdminComponent implements OnInit, AfterViewInit, OnDestroy {
  SalesJourneyProdType = SalesJourneyProdType;
  @ViewChild('tableBox', { static: false })
  tableBox?: ElementRef<HTMLDivElement>;
  prodTypeFormControl: FormControl = new FormControl(SalesJourneyProdType.Individual);
  prodTypeDropdownConfig: CdsDropdownConfig = {
    options: [
      {
        label: 'Individual(PA,TVC,FRC)',
        value: SalesJourneyProdType.Individual,
      },
      { label: 'Corperate(ER)', value: SalesJourneyProdType.Corperate },
      { label: 'SEP', value: SalesJourneyProdType.SEP },
    ],
    color: 'ml',
  };
  requestParams!: SuperAdminEEReqeuestParams | SuperAdminERReqeuestParams;
  requestParamsForPageChange?: SuperAdminEEReqeuestParams | SuperAdminERReqeuestParams;
  totalElements = 0;
  isLoading = false;
  currentPageNumOrigin = 1;
  allPageData: SuperAdminEERecordItem[][] | SuperAdminERRecordItem[][] = [];
  infoIcon = ActionIcons.info_1;
  iconConfig: CdsIconConfig = {
    size: 'sm',
    color: 'default',
  };
  eeDisplayedColumns = ['empfRefNo', 'agentCd', 'productType', 'hkidOrPassportNo', 'memberAccountCd', 'clientPhoneNo', 'clientEmailAddr'];
  erDisplayedColumns = ['empfRefNo', 'agentCd', 'productType', 'employerName', 'brNo', 'employerAccountCd', 'apPhoneNo', 'apEmailAddr'];

  totalPages = 0;

  constructor(
    private cdsPopup: CdsPopupService,
    private router: Router,
    private route: ActivatedRoute,
    private cdHttpServeService: CdHttpServeService,
    private alert: CdsAlertService,
    private notifyService: NotifyService
  ) {}

  ngOnInit(): void {
    this.initRequestParams();
    if (this.route.snapshot.paramMap.get('productType') === 'ER') {
      this.prodTypeFormControl.setValue('ER');
    } else if (this.route.snapshot.paramMap.get('productType') === EEProdType.SEP) {
      this.prodTypeFormControl.setValue('SEP');
    }
    this.prodTypeChange(this.prodTypeFormControl.value);
  }

  private resizeListener = () => {
    const isMobile = window.matchMedia('only screen and (max-width: 1023px)').matches || window.screen.width < 1023;
    if (isMobile) {
      this.notifyService.notifyHideNavigationLang();
    }
  };

  ngAfterViewInit(): void {
    pageToTop();
    this.notifyService.notifyHideNavigationLang();
    window.addEventListener('resize', this.resizeListener);
  }

  ngOnDestroy(): void {
    this.notifyService.notifyDisplayNavigationLang();
    window.removeEventListener('resize', this.resizeListener);
  }

  onDisplayAdviseRef() {
    this.cdsPopup.open(AdviseEmpfrefComponent, {
      size: 'lg',
    });
  }

  pageChange(num: number): void {
    if (this.requestParamsForPageChange) {
      this.requestParamsForPageChange.page = num;
      this.search(this.requestParamsForPageChange);
    }
  }

  goCaseDetails(item: SuperAdminRecordItem) {
    const queryParams = {
      from: 'superAdmin',
      productType: this.prodTypeFormControl.value,
    };
    this.router.navigate([
      '/salesjourney',
      this.prodTypeFormControl.value === SalesJourneyProdType.Corperate ? 'case-details-er' : 'case-details',
      item.salesJourneySummaryId,
      item.empfRefNo,
      queryParams,
    ]);
  }

  private initRequestParams() {
    this.requestParams = {
      page: 1,
      size: 50,
      empfRefNo: '',
      hkidOrPassportNo: '',
      memberAccountCd: '',
      agentCd: '',
      clientPhoneNo: '',
      clientEmailAddr: '',
      productTypes: [],
      identityId: '',
      domain: this.prodTypeFormControl.value,
      brNo: '',
      employerAccountCd: '',
      employerName: '',
      apPhoneNo: '',
      apEmailAddr: '',
    };
  }

  prodTypeChange(value: SalesJourneyProdType) {
    this.prodTypeFormControl.setValue(value);
    this.reset();
    this.requestParams.domain = value;
    if (this.requestParams.domain === SalesJourneyProdType.Individual) {
      delete this.requestParams.productTypes;
    } else if (this.requestParams.domain === SalesJourneyProdType.SEP) {
      this.requestParams.productTypes = ['SEP'];
    } else if (this.requestParams.domain === SalesJourneyProdType.Corperate) {
      this.requestParams.productTypes = ['ER'];
    }

    const params = this.getFilterParams();
    this.search(params);
  }

  private getFilterParams() {
    const params: any = {};
    for (const key in this.requestParams) {
      if (Object.prototype.hasOwnProperty.call(this.requestParams, key)) {
        const val = (this.requestParams as any)[key];
        if (val !== undefined && val !== null && val !== '' && !(Object.prototype.toString.call(val) === '[object Array]' && val.length === 0)) {
          if (typeof val === 'string') {
            params[key] = val.trim();
          } else {
            params[key] = val;
          }
        }
      }
    }

    return params;
  }

  private reset() {
    this.initRequestParams();
  }

  private search(params: any) {
    this.requestParamsForPageChange = params;
    this.isLoading = true;
    this.cdHttpServeService
      .post<{
        content: SuperAdminEERecordItem[] | SuperAdminERRecordItem[];
        totalElements: number;
        number: number;
        totalPages: number;
      }>(
        !this.isERReqeuestParams(params)
          ? '/ext/eb-ssms-sales-journey-service/memberSalesJourneySummaries/superAdmin'
          : '/ext/eb-ssms-sales-journey-service/employerSalesJourneySummaries/superAdmin',
        params
      )
      .subscribe({
        next: res => {
          if (res.result !== 0) {
            this.alert.warning('Warning!', `${res.message}`);
          } else {
            res.data.content = res.data.content || [];
            this.currentPageNumOrigin = res.data.number;
            this.allPageData[this.currentPageIndex] = res.data.content;
            this.totalElements = res.data.totalElements;
            this.totalPages = res.data.totalPages;
          }
          this.isLoading = false;
        },
        error: err => {
          this.alert.error('Error!', err);
          this.isLoading = false;
        },
      });
  }

  @HostListener('document:keydown.enter', ['$event'])
  onDocumentEnter(): void {
    this.onClickSearch();
  }

  onClickSearch() {
    if (this.isDisableSearch || this.isLoading) {
      return;
    }
    this.requestParams.page = 1;
    this.requestParams.size = 50;
    const params = this.getFilterParams();
    this.search(params);
  }

  onClickReset() {
    this.reset();

    if (this.requestParams.domain === SalesJourneyProdType.Individual) {
      delete this.requestParams.productTypes;
    } else if (this.requestParams.domain === SalesJourneyProdType.SEP) {
      this.requestParams.productTypes = ['SEP'];
    } else if (this.requestParams.domain === SalesJourneyProdType.Corperate) {
      this.requestParams.productTypes = ['ER'];
    }

    const params = this.getFilterParams();
    this.search(params);
  }

  get checkIsHaveScroll() {
    if (this.tableBox) {
      return this.tableBox.nativeElement.scrollWidth > this.tableBox.nativeElement.clientWidth;
    }
    return false;
  }

  get isDisableSearch() {
    if (!this.isERReqeuestParams(this.requestParams)) {
      return (
        this.requestParams.empfRefNo === '' &&
        this.requestParams.hkidOrPassportNo === '' &&
        this.requestParams.memberAccountCd === '' &&
        this.requestParams.agentCd === '' &&
        this.requestParams.clientPhoneNo === '' &&
        this.requestParams.clientEmailAddr === ''
      );
    } else {
      return (
        this.requestParams.empfRefNo === '' &&
        this.requestParams.brNo === '' &&
        this.requestParams.employerAccountCd === '' &&
        this.requestParams.employerName === '' &&
        this.requestParams.agentCd === '' &&
        this.requestParams.apPhoneNo === '' &&
        this.requestParams.apEmailAddr === ''
      );
    }
  }

  get currentPageIndex() {
    return this.currentPageNumOrigin - 1;
  }

  get currentPageData() {
    if (this.allPageData.length === 0) {
      return [];
    }
    return this.allPageData[this.currentPageIndex];
  }

  get eeCurrentPageData() {
    if (this.allPageData.length === 0) {
      return [];
    }
    return this.allPageData[this.currentPageIndex] as SuperAdminEERecordItem[];
  }

  get erCurrentPageData() {
    if (this.allPageData.length === 0) {
      return [];
    }
    return this.allPageData[this.currentPageIndex] as SuperAdminERRecordItem[];
  }

  isERReqeuestParams(params: SuperAdminEEReqeuestParams | SuperAdminERReqeuestParams): params is SuperAdminERReqeuestParams {
    return params.domain === SalesJourneyProdType.Corperate;
  }
}
