/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, Observable, of } from 'rxjs';
import { Summary } from 'src/app/views/mobile-subscription/summary';
import { DateTime } from 'luxon';
import { environment } from 'src/environments/environment';

class R {
  result!: number;
  data?: any;
  message!: string;
}

@Injectable({
  providedIn: 'root',
})
export class SummaryService {
  private host = `${environment.apiPrefix}/ext/eb-ssms/customer-service/`;

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  constructor(private httpClient: HttpClient) {}

  create(dto: Summary): Observable<R> {
    const targetBroadcastDate = this.fomateDate(`${dto.targetBroadcastDate} ${dto.targetBroadcastTime}`);
    return this.httpClient.post<R>(this.host + 'notification', { ...dto, targetBroadcastDate }).pipe(catchError(this.handleError<R>('create')));
  }

  getAudiences(): Observable<R> {
    return this.httpClient.get<R>(this.host + 'notification/audiences');
  }

  fomateDate(date: string) {
    return DateTime.fromFormat(date, 'd/M/yyyy H:m').toFormat('yyyy-MM-dd HH:mm:ss');
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
