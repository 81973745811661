import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { CdsOption } from '@cds/ng-core/configuration';
import { CdsPopupService } from '@cds/ng-web-components/popup';
import { UploadDocumentComponent } from '../upload-document/upload-document.component';
import { CdHttpServeService } from 'src/app/shared/cd-http-serve/cd-http-serve.service';
import { UploadCenterConfig } from 'src/app/core/models/upload-center/upload-center.model';
import { CdsAlertService } from '@cds/ng-web-components/alert';
import { PermissionService } from 'src/app/core/services/permission.service';
import { PermissionAccess } from 'src/app/core/models/enum';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-upload-center',
  templateUrl: './upload-center.component.html',
  styleUrls: ['./upload-center.component.scss'],
})
export class UploadCenterComponent implements OnInit {
  formControl: FormControl = new FormControl();
  options: CdsOption[] = [];
  uploadCenterConfigs: UploadCenterConfig[] = [];
  currentSelectedConfig?: UploadCenterConfig;
  isRefrushCdSearch = false;

  constructor(
    private cdsPopup: CdsPopupService,
    private cdHttpServeService: CdHttpServeService,
    private alert: CdsAlertService,
    private permissionService: PermissionService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.getCampaignByAgentcode().then(() => {
      const isOpenUploadABMList = this.route.snapshot.paramMap.get('isOpenUploadABMList') || false;
      const abm = this.options.find(item => item.value === 'ABM List');
      if (isOpenUploadABMList && abm) {
        this.formControl.setValue(abm);
        const configItem = this.uploadCenterConfigs.find(item => item.configName === 'ABM List');
        this.currentSelectedConfig = configItem;
        this.refrushCdSearch();
        this.uploadFile();
      }
    });
  }

  private refrushCdSearch() {
    this.isRefrushCdSearch = true;
    setTimeout(() => {
      this.isRefrushCdSearch = false;
    }, 0);
  }

  search(option: CdsOption) {
    this.currentSelectedConfig = undefined;
    if (typeof option === 'string' && !this.uploadCenterConfigs.some(item => item.configName === option)) {
      this.formControl.setValue(option);
      return;
    }
    let val = '';
    if (typeof option === 'string' && this.uploadCenterConfigs.some(item => item.configName === option)) {
      val = option;
      this.formControl.setValue(val);
    } else {
      val = option.value;
    }
    const configItem = this.uploadCenterConfigs.find(item => item.configName === val);
    this.currentSelectedConfig = configItem;
  }

  uploadFile() {
    this.cdsPopup.open(UploadDocumentComponent, {
      size: 'lg',
      data: {
        currentSelectedConfig: this.currentSelectedConfig,
      },
    });
  }

  getCampaignByAgentcode() {
    return new Promise<void>((resolve, reject) => {
      this.cdHttpServeService.get<UploadCenterConfig[]>(`/ext/eb-ssms-sales-journey-service/api/v1/uploadCenter/configs`, {}).subscribe({
        next: async res => {
          if (res.result !== 0) {
            this.alert.warning('Warning!', `${res.message}`);
            reject();
          } else {
            this.uploadCenterConfigs = res.data;
            const tempOptions = await Promise.all(
              this.uploadCenterConfigs.map(async item => {
                const hasPermission = await this.permissionService.hasPermission(PermissionAccess.R, item.configRole);
                if (hasPermission) {
                  return {
                    label: item.configName,
                    value: item.configName,
                  };
                }
                return undefined;
              })
            );
            this.options = tempOptions.filter(Boolean) as CdsOption[];
            resolve();
          }
        },
        error: err => {
          this.alert.error('Error!', err);
          reject();
        },
      });
    });
  }
}
