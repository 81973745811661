<div #quickLinks class="widgets-quick-links">
  <div class="link-item" [ngClass]="{ 'fixed-col-3': isFixedCol3 }">
    <div class="icon-box">
      <cds-icon icon="arrow:exchange"></cds-icon>
    </div>
    <div class="text">
      <span class="cds-detail1 cursor-pointer">TransferSimple</span>
    </div>
  </div>
  <div class="link-item" [ngClass]="{ 'fixed-col-3': isFixedCol3 }">
    <div class="icon-box">
      <cds-icon icon="presentation:checklist"></cds-icon>
    </div>
    <div class="text">
      <span class="cds-detail1 cursor-pointer">eMPF Portal</span>
    </div>
  </div>
  <div class="link-item" [ngClass]="{ 'fixed-col-3': isFixedCol3 }">
    <div class="icon-box">
      <cds-icon icon="form:document"></cds-icon>
    </div>
    <div class="text">
      <span class="cds-detail1 cursor-pointer">eMPF forms</span>
    </div>
  </div>
  <div class="link-item" [ngClass]="{ 'fixed-col-3': isFixedCol3 }">
    <div class="icon-box">
      <cds-icon icon="form:easy_application"></cds-icon>
    </div>
    <div class="text">
      <span class="cds-detail1 cursor-pointer">MPF Hub</span>
    </div>
  </div>
  <div class="link-item" [ngClass]="{ 'fixed-col-3': isFixedCol3 }">
    <div class="icon-box">
      <cds-icon icon="action:share"></cds-icon>
    </div>
    <div class="text">
      <span class="cds-detail1 cursor-pointer">Agent Sharing Page</span>
    </div>
  </div>
  <div *ngIf="isShowUpdateABMList" (click)="onClickUploadABMList()" class="link-item" [ngClass]="{ 'fixed-col-3': isFixedCol3 }">
    <div class="icon-box">
      <cds-icon icon="arrow:update_recycle"></cds-icon>
    </div>
    <div class="text">
      <span class="cds-detail1 cursor-pointer">Update ABM List</span>
    </div>
  </div>
</div>
