<div class="empf-case-details">
  <div class="back-to-case-summary l-d-f">
    <cds-icon (click)="onBack()" class="cursor-pointer" [config]="backErIconConfig" [icon]="backErIcon"> </cds-icon>
    <div class="l-ml-2 cursor-pointer">
      <span class="cds-body2-demibold" (click)="onBack()"> MD_IFF Summary </span>
    </div>
  </div>

  <div class="div-border-bottom">
    <div class="case-details-header">
      <span class="cds-h4-demibold"> eMPF case details </span>
    </div>
  </div>

  <div class="l-pl-7 l-pt-7 l-pr-7 l-pb-7">
    <div>
      <span class="cds-body-hero">Item Checklist</span>
    </div>
    <div class="l-mt-5 l-d-f l-ai-cen">
      <div class="l-d-f l-ai-cen">
        <cds-checkbox class="check-list" [formControl]="agentMDFormCompletedCheck"></cds-checkbox>
        <div style="margin-left: 1.25rem">
          <span class="cds-h6-demibold">Agent MD Form Completed</span>
        </div>
      </div>
      <div class="l-d-f l-ai-cen l-ml-5">
        <cds-checkbox class="check-list" [formControl]="clientMDFormCompletedCheck"></cds-checkbox>
        <div style="margin-left: 1.25rem">
          <span class="cds-h6-demibold">Client MD Form Completed</span>
        </div>
      </div>
      <div class="l-d-f l-ai-cen l-ml-5">
        <cds-checkbox class="check-list" [formControl]="eMPFCompletedCheck"></cds-checkbox>
        <div style="margin-left: 1.25rem">
          <span class="cds-h6-demibold">eMPF Completed</span>
        </div>
      </div>
      <div class="l-d-f l-ai-cen l-ml-5">
        <cds-checkbox class="check-list" [formControl]="agentIFFCompletedCheck"></cds-checkbox>
        <div style="margin-left: 1.25rem">
          <span class="cds-h6-demibold">Agent IFF Completed</span>
        </div>
      </div>
      <div class="l-d-f l-ai-cen l-ml-5">
        <cds-checkbox class="check-list" [formControl]="clientIFFCompletedCheck"></cds-checkbox>
        <div style="margin-left: 1.25rem">
          <span class="cds-h6-demibold">Client IFF Completed</span>
        </div>
      </div>
    </div>
    <div class="mdiff-details-box">
      <div class="left" style="background-color: var(--cds-color-m-green)">
        <span class="cds-h4-demibold" style="color: var(--cds-color-white)">MD_IFF Details</span>
      </div>
      <div class="right">
        <div class="l-d-f l-fd-col">
          <div>
            <span class="cds-body2" style="color: var(--cds-color-light-3-dark-navy)">MD Reference Number</span>
          </div>
          <div class="l-mt-1">
            <span class="cds-h2-demibold">MD120382714</span>
          </div>
        </div>
        <div class="line l-mt-5"></div>
        <div class="item-box">
          <div class="item">
            <div class="l-d-f l-ai-cen">
              <div>
                <span class="cds-body2" style="color: var(--cds-color-light-3-dark-navy)">MD_IFF status</span>
              </div>
              <cds-icon class="note l-ml-1" (click)="onDisplayAdviseIFF()" [icon]="infoIcon" [config]="iconConfig"></cds-icon>
            </div>
            <div class="l-mt-1 l-d-f l-ai-cen">
              <div class="lamp" [ngStyle]="{ 'background-color': iffStatusService.getIFFStatusInfo(MDIFFStatus)?.color }"></div>
              <div class="l-ml-3">
                <span
                  [cdsTooltip]="'Pending the following split agent submission： 345678 Lau Tai Man'"
                  (mouseenter)="onMDIFFStatusShowToggle($event)"
                  (mouseleave)="onMDIFFStatusShowToggle($event)"
                  class="cds-body1-demibold mdiff-status-text"
                  style="cursor: context-menu"
                  >Pending split agent(s)</span
                >
              </div>
            </div>
          </div>
          <div class="item">
            <div>
              <span class="cds-body2" style="color: var(--cds-color-light-3-dark-navy)">First eIFF submission time & date (DD/MM/YYYY)</span>
            </div>
            <div class="l-mt-1">
              <span class="cds-body1-demibold">09:00 am, 01/10/2022 (HKT)</span>
            </div>
          </div>
          <div class="declined-info-box l-mt-3">
            <div class="declined-info">
              <div class="l-d-f l-ai-cen">
                <cds-icon icon="contact:question"></cds-icon>
                <div class="l-ml-2">
                  <span class="cds-detail1" style="color: var(--cds-color-light-3-dark-navy)">Declined</span>
                </div>
              </div>
              <div class="l-mt-2">
                <span class="cds-body2-demibold" style="color: var(--cds-color-dark-1-coral)">Mismatched HKID/ Passport No,</span>
              </div>
              <div>
                <span class="cds-body2-demibold" style="color: var(--cds-color-dark-1-coral)">Mismatched Client Contact</span>
              </div>
              <div class="l-mt-2">
                <span class="cds-detail2 italic">*Please check email for the details and further actions.</span>
              </div>
            </div>
            <div class="background"></div>
          </div>
          <div class="item">
            <div>
              <span class="cds-body2" style="color: var(--cds-color-light-3-dark-navy)">Client Name</span>
            </div>
            <div class="l-mt-1">
              <span class="cds-body1-demibold">CHAN TAI MAN</span>
            </div>
          </div>
          <div class="item">
            <div>
              <span class="cds-body2" style="color: var(--cds-color-light-3-dark-navy)">Delivery channel of the eIFF</span>
            </div>
            <div class="l-mt-1">
              <span class="cds-body1-demibold">SMS: (+852) 9876 5432</span>
            </div>
          </div>
          <div class="item">
            <div>
              <span class="cds-body2" style="color: var(--cds-color-light-3-dark-navy)">Resend time & date</span>
            </div>
            <div class="l-mt-1">
              <span class="cds-body1-demibold">N/A</span>
            </div>
          </div>
        </div>
        <div class="l-mt-5">
          <cds-button [style]="'secondary'" size="sm" (click)="onResendEiff()">Resend eIFF</cds-button>
        </div>
        <div class="l-d-f l-ai-cen l-mt-3">
          <div>
            <span class="cds-body2">You may resend in</span>
          </div>
          <div class="l-ml-2">
            <span class="cds-body2" style="color: var(--cds-color-dark-1-green)">0:53 mins.</span>
          </div>
        </div>
        <div class="l-mt-5">
          <cds-button [style]="'secondary'" size="sm" (click)="onOpenAddMDRefNoPopup()">Match MD Reference No.</cds-button>
        </div>
      </div>
    </div>

    <div class="empf-details-box">
      <div class="left" style="background-color: var(--cds-color-jh-blue)">
        <span class="cds-h4-demibold" style="color: var(--cds-color-white)">eMPF Details</span>
      </div>
      <div class="right">
        <div class="l-d-f l-fd-col">
          <div>
            <span class="cds-body2" style="color: var(--cds-color-light-3-dark-navy)">eMPF Transaction Reference Number</span>
          </div>
          <div class="l-mt-1">
            <span class="cds-h2-demibold">N/A</span>
          </div>
        </div>
        <div class="line l-mt-5"></div>
        <div class="item-box">
          <div class="item">
            <div class="l-d-f l-ai-cen">
              <div>
                <span class="cds-body2" style="color: var(--cds-color-light-3-dark-navy)">eMPF status</span>
              </div>
              <cds-icon class="note l-ml-1" (click)="onDisplayAdviseEmpf()" [icon]="infoIcon" [config]="iconConfig"></cds-icon>
            </div>
            <div class="l-mt-1 l-d-f l-ai-cen">
              <div class="lamp" [ngStyle]="{ 'background-color': empfAppStatusService.getEmpfAppStatusInfo(empfStatus)?.color }"></div>
              <div class="l-ml-3">
                <span class="cds-body1-demibold">Pending eMPF return data</span>
              </div>
            </div>
          </div>
          <div class="item">
            <div>
              <span class="cds-body2" style="color: var(--cds-color-light-3-dark-navy)">eMPF submission date & time (DD/MM/YYYY)</span>
            </div>
            <div class="l-mt-1">
              <span class="cds-body1-demibold">09:00 am, 01/10/2022 (HKT)</span>
            </div>
          </div>
          <div class="item">
            <div>
              <span class="cds-body2" style="color: var(--cds-color-light-3-dark-navy)">Client Name</span>
            </div>
            <div class="l-mt-1">
              <span class="cds-body1-demibold">N/A</span>
            </div>
          </div>
          <div class="item">
            <div>
              <span class="cds-body2" style="color: var(--cds-color-light-3-dark-navy)">Client DOB (DD/MM/YYYY) from eMPF</span>
            </div>
            <div class="l-mt-1">
              <span class="cds-body1-demibold">N/A</span>
            </div>
          </div>
          <div class="item">
            <div>
              <span class="cds-body2" style="color: var(--cds-color-light-3-dark-navy)">Phone Number from eMPF</span>
            </div>
            <div class="l-mt-1">
              <span class="cds-body1-demibold">N/A</span>
            </div>
          </div>
          <div class="item">
            <div>
              <span class="cds-body2" style="color: var(--cds-color-light-3-dark-navy)">Email from eMPF</span>
            </div>
            <div class="l-mt-1">
              <span class="cds-body1-demibold">N/A</span>
            </div>
          </div>
        </div>
        <div class="l-mt-5">
          <cds-button [style]="'secondary'" size="sm" (click)="onOpenAddEmpfRefNoPopup()">Match MPF Reference No.</cds-button>
        </div>
      </div>
    </div>

    <div class="servicing-agent-box">
      <div class="left" style="background-color: var(--cds-color-violet)">
        <span class="cds-h4-demibold" style="color: var(--cds-color-white)">Servicing Agent</span>
      </div>
      <div class="right">
        <div>
          <span class="cds-body2" style="color: var(--cds-color-light-3-dark-navy)">Servicing Agent</span>
        </div>
        <div class="l-d-f l-ai-cen l-mt-1">
          <div>
            <span class="cds-body1-demibold">345271</span>
          </div>
          <div class="l-ml-4">
            <span class="cds-body1-demibold">CHEUNG SIU MING</span>
          </div>
        </div>
        <div class="l-mt-5">
          <cds-checkbox [checked]="false" [disabled]="true" label="在客戶進行受規管活動時，必須已履行強積金中介人履行職務要求。"></cds-checkbox>
        </div>
      </div>
    </div>

    <div class="split-agent-box">
      <div class="left" style="background-color: var(--cds-color-violet)">
        <span class="cds-h4-demibold" style="color: var(--cds-color-white)">Split Agent</span>
      </div>
      <div class="right">
        <div>
          <span class="cds-body2" style="color: var(--cds-color-light-3-dark-navy)">Split Agent</span>
        </div>
        <div class="l-d-f l-ai-cen l-mt-1">
          <div>
            <span class="cds-body1-demibold">345271</span>
          </div>
          <div class="l-ml-4">
            <span class="cds-body1-demibold">CHEUNG SIU MING</span>
          </div>
        </div>
        <div class="l-mt-5">
          <cds-checkbox [checked]="false" [disabled]="true" label="在客戶進行受規管活動時，必須已履行強積金中介人履行職務要求。"></cds-checkbox>
        </div>
      </div>
    </div>

    <div class="l-mt-7">
      <div>
        <span class="cds-h4-demibold">Transfer Details</span>
      </div>

      <div class="transfer-details-item">
        <div>
          <span class="cds-h5-demibold">1</span>
        </div>
        <div class="right">
          <div>
            <span class="cds-body2" style="color: var(--cds-color-light-3-dark-navy)">Product Type</span>
          </div>
          <div class="l-mt-1">
            <span class="cds-h4-demibold">PA</span>
          </div>
          <div class="line l-mt-5"></div>
          <div class="l-mt-5">
            <span class="cds-body2" style="color: var(--cds-color-light-3-dark-navy)">Transfer Status* (Source: eMPF Portal)</span>
          </div>
          <div class="l-mt-1 l-d-f l-ai-cen">
            <div class="lamp" [ngStyle]="{ 'background-color': empfAppStatusService.getEmpfAppStatusInfo(empfStatus)?.color }"></div>
            <div class="l-ml-3">
              <span class="cds-body1-demibold mdiff-status-text">Completed</span>
            </div>
          </div>
          <div class="transfer-table">
            <div class="table-head">
              <div></div>
              <div class="table-head-item">
                <span class="cds-body2">Original Scheme</span>
              </div>
              <div class="table-head-item">
                <span class="cds-body2">New (MIL) Scheme</span>
              </div>
            </div>
            <div class="table-row">
              <div>
                <span class="cds-detail1">Trustee Name</span>
              </div>
              <div>
                <span class="cds-body2-demibold">AIA Company (Trustee) Limited</span>
              </div>
              <div>
                <span class="cds-body2-demibold">Manulife</span>
              </div>
            </div>
            <div class="table-row">
              <div>
                <span class="cds-detail1">Scheme name</span>
              </div>
              <div>
                <span class="cds-body2-demibold">AIA MPF - Prime Value Choice</span>
              </div>
              <div>
                <span class="cds-body2-demibold">Manulife Global Select (MPF) Scheme</span>
              </div>
            </div>
            <div class="table-row">
              <div>
                <span class="cds-detail1">Scheme member account no.</span>
              </div>
              <div>
                <span class="cds-body2-demibold">001234456789</span>
              </div>
              <div>
                <span class="cds-body2-demibold">98765432100</span>
              </div>
            </div>
            <div class="table-row table-row-end">
              <div>
                <span class="cds-detail1">Account type</span>
              </div>
              <div>
                <span class="cds-body2-demibold">Personal Account</span>
              </div>
              <div>
                <span class="cds-body2-demibold">PAC Account</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="transfer-details-item">
        <div>
          <span class="cds-h5-demibold">2</span>
        </div>
        <div class="right">
          <div>
            <span class="cds-body2" style="color: var(--cds-color-light-3-dark-navy)">Product Type</span>
          </div>
          <div class="l-mt-1">
            <span class="cds-h4-demibold">PA</span>
          </div>
          <div class="line l-mt-5"></div>
          <div class="l-mt-5">
            <span class="cds-body2" style="color: var(--cds-color-light-3-dark-navy)">Transfer Status* (Source: eMPF Portal)</span>
          </div>
          <div class="l-mt-1 l-d-f l-ai-cen">
            <div class="lamp" [ngStyle]="{ 'background-color': empfAppStatusService.getEmpfAppStatusInfo(empfStatus)?.color }"></div>
            <div class="l-ml-3">
              <span class="cds-body1-demibold mdiff-status-text">Completed</span>
            </div>
          </div>
          <div class="transfer-table">
            <div class="table-head">
              <div></div>
              <div class="table-head-item">
                <span class="cds-body2">Original Scheme</span>
              </div>
              <div class="table-head-item">
                <span class="cds-body2">New (MIL) Scheme</span>
              </div>
            </div>
            <div class="table-row">
              <div>
                <span class="cds-detail1">Trustee Name</span>
              </div>
              <div>
                <span class="cds-body2-demibold">AIA Company (Trustee) Limited</span>
              </div>
              <div>
                <span class="cds-body2-demibold">Manulife</span>
              </div>
            </div>
            <div class="table-row">
              <div>
                <span class="cds-detail1">Scheme name</span>
              </div>
              <div>
                <span class="cds-body2-demibold">AIA MPF - Prime Value Choice</span>
              </div>
              <div>
                <span class="cds-body2-demibold">Manulife Global Select (MPF) Scheme</span>
              </div>
            </div>
            <div class="table-row">
              <div>
                <span class="cds-detail1">Scheme member account no.</span>
              </div>
              <div>
                <span class="cds-body2-demibold">001234456789</span>
              </div>
              <div>
                <span class="cds-body2-demibold">98765432100</span>
              </div>
            </div>
            <div class="table-row table-row-end">
              <div>
                <span class="cds-detail1">Account type</span>
              </div>
              <div>
                <span class="cds-body2-demibold">Personal Account</span>
              </div>
              <div>
                <span class="cds-body2-demibold">PAC Account</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="l-mt-7">
      <span class="cds-body2" style="color: var(--cds-color-light-3-dark-navy)"
        >*Remarks: If you have any enquiry, please contact eMPF platform at enquiry@eMPF.com.</span
      >
    </div>

    <div class="l-mt-7">
      <cds-button [style]="'secondary'" size="sm" (click)="toTop()">Back to Top</cds-button>
    </div>
  </div>
</div>
