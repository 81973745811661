import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { CdsIconConfig } from '@cds/ng-core/icon';
import { ActionIcons } from '@cds/ng-themes';
import { IffStatusFlowPopupComponent } from '../iff-status-flow-popup/iff-status-flow-popup.component';
import { CdsPopupService } from '@cds/ng-web-components/popup';
import { IffStatusService } from 'src/app/core/services/iff-status/iff-status.service';
import { EmpfAppStatusService } from 'src/app/core/services/empf-app-status/empf-app-status.service';
import { EmpfAppStatusPopupComponent } from '../empf-app-status-popup/empf-app-status-popup.component';
import { pageToTop } from 'src/app/utils/utils';
import { AddMpfReferenceNumberPopupComponent } from '../add-mpf-reference-number-popup/add-mpf-reference-number-popup.component';
import { NewResendEiffComponent } from '../new-resend-eiff/new-resend-eiff.component';
import { AddMdReferenceNumberPopupComponent } from '../add-md-reference-number-popup/add-md-reference-number-popup.component';
import { ResendEiffResendType } from '../resend-eiff/resend-eiff.model';
import { PermissionService } from 'src/app/core/services/permission.service';
import { PermissionAccess, PermissionItem } from 'src/app/core/models/enum';

type RolePriority = 'SuperAdmin' | 'SalesSupport' | 'SalesJourneyAgent';

@Component({
  selector: 'app-empf-case-details',
  templateUrl: './empf-case-details.component.html',
  styleUrls: ['./empf-case-details.component.scss'],
})
export class EmpfCaseDetailsComponent implements OnInit {
  backErIcon = ActionIcons.button_left_filled;
  backErIconConfig: CdsIconConfig = {
    color: 'cta',
    size: 'sm',
  };
  agentMDFormCompletedCheck = new FormControl(true);
  clientMDFormCompletedCheck = new FormControl(true);
  eMPFCompletedCheck = new FormControl(false);
  agentIFFCompletedCheck = new FormControl(false);
  clientIFFCompletedCheck = new FormControl(false);
  iconConfig: CdsIconConfig = {
    size: 'sm',
    color: 'default',
  };
  infoIcon = ActionIcons.info_1;
  MDIFFStatus = 'Pending Split Agent(s)';
  empfStatus = 'Pending eMPF return data';
  isOpenDbs = false;
  hasSalesSupportRole = false;
  hasSalesJourneyAgentRole = false;
  hasSalesJourneySuperAdmin = false;
  rolePriority?: RolePriority;

  rejectReasons = [
    {
      title: '01 - Missing / Incorrect HKID or passport number',
      content:
        'Lorem ipsum dolor sit amet. Qui autem impedit est tenetur reiciendis et sunt rerum qui quisquam animi et repudiandae rerum ab cumque dolore ut accusamus ipsum. Doloribus aliquid quo delectus atque aut corporis eius eum maiores optio in autem consequatur quo quidem officiis.',
      isExpand: false,
    },
    {
      title: '05 - Missing / Mismatch member signature',
      content:
        'Lorem ipsum dolor sit amet. Qui autem impedit est tenetur reiciendis et sunt rerum qui quisquam animi et repudiandae rerum ab cumque dolore ut accusamus ipsum. Doloribus aliquid quo delectus atque aut corporis eius eum maiores optio in autem consequatur quo quidem officiis.',
      isExpand: false,
    },
    {
      title: '12 - Missing / Invalid option in arrangement of voluntary contributions',
      content:
        'Lorem ipsum dolor sit amet. Qui autem impedit est tenetur reiciendis et sunt rerum qui quisquam animi et repudiandae rerum ab cumque dolore ut accusamus ipsum. Doloribus aliquid quo delectus atque aut corporis eius eum maiores optio in autem consequatur quo quidem officiis.',
      isExpand: false,
    },
  ];

  constructor(
    private router: Router,
    private cdsPopup: CdsPopupService,
    public iffStatusService: IffStatusService,
    public empfAppStatusService: EmpfAppStatusService,
    private permissionService: PermissionService
  ) {}

  ngOnInit(): void {
    this.initRole();
  }

  private async initRole() {
    this.hasSalesJourneySuperAdmin = await this.permissionService.hasPermission(PermissionAccess.R, PermissionItem.SALES_JOURNEY_SUPER_ADMIN);
    this.hasSalesSupportRole = await this.permissionService.hasPermission(PermissionAccess.R, PermissionItem.SALES_JOURNEY_SALES_SUPPORT);
    this.hasSalesJourneyAgentRole = await this.permissionService.hasPermission(PermissionAccess.R, PermissionItem.SALES_JOURNEY_AGENT);
    if (this.hasSalesJourneySuperAdmin) {
      this.rolePriority = 'SuperAdmin';
    } else if (this.hasSalesSupportRole) {
      this.rolePriority = 'SalesSupport';
    } else if (this.hasSalesJourneyAgentRole) {
      this.rolePriority = 'SalesJourneyAgent';
    }
  }

  onBack() {
    this.router.navigate(['/salesjourney/md-iff-summary']);
  }

  toTop() {
    pageToTop();
  }

  onDisplayAdviseIFF() {
    this.cdsPopup.open(IffStatusFlowPopupComponent, {
      size: 'sm',
    });
  }

  onDisplayAdviseEmpf() {
    this.cdsPopup.open(EmpfAppStatusPopupComponent, {
      size: 'sm',
    });
  }

  onOpenAddEmpfRefNoPopup() {
    this.cdsPopup.open(AddMpfReferenceNumberPopupComponent, {
      size: 'sm',
    });
  }

  onOpenAddMDRefNoPopup() {
    this.cdsPopup.open(AddMdReferenceNumberPopupComponent, {
      size: 'sm',
    });
  }

  onResendEiff() {
    this.cdsPopup.open(NewResendEiffComponent, {
      size: 'sm',
      data: {
        resendType: ResendEiffResendType.EE,
      },
    });
  }

  onMDIFFStatusShowToggle(event: MouseEvent) {
    if (event.target) {
      event.target.dispatchEvent(new Event('click'));
    }
  }

  onToggleDbs() {
    this.isOpenDbs = !this.isOpenDbs;
  }
}
