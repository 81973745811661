/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { BasicResponse } from 'src/app/core/models/response/response';

@Injectable({
  providedIn: 'root',
})
export class CommissionPoolScaleService {
  constructor(private http: HttpClient) {}

  createScaleDetails(params: any): Observable<BasicResponse<any>> {
    return this.http.post<BasicResponse<any>>(`${environment.apiPrefix}${environment.commissionServiceExt}/commission-pool-scale/add`, params);
  }

  getScaleRecordList(params: any): Observable<BasicResponse<any>> {
    return this.http.get<BasicResponse<any>>(`${environment.apiPrefix}${environment.commissionServiceExt}/commission-pool-scale/list`, { params: params });
  }

  getPoolAccumulations(params: any): Observable<any> {
    return this.http.get(`${environment.apiPrefix}${environment.commissionServiceExt}/commission-pool-scale/list-commission-pool-accumulations`, {
      params: params,
      observe: 'response',
      responseType: 'text',
    });
  }

  getScaleRecordInfo(id: string): Observable<any> {
    return this.http.get(`${environment.apiPrefix}${environment.commissionServiceExt}/commission-pool-scale/info/${id}`, {
      observe: 'response',
      responseType: 'text',
    });
  }

  getScaleRecordCurrentInfo(commissionPoolId: string): Observable<any> {
    return this.http.get(`${environment.apiPrefix}${environment.commissionServiceExt}/commission-pool-scale/currentInfo/${commissionPoolId}`, {
      observe: 'response',
      responseType: 'text',
    });
  }

  editScaleInfo(params: any): Observable<BasicResponse<any>> {
    return this.http.post<BasicResponse<any>>(`${environment.apiPrefix}${environment.commissionServiceExt}/commission-pool-scale/update`, params);
  }

  deleteHistory(id: string): Observable<BasicResponse<any>> {
    return this.http.get<BasicResponse<any>>(`${environment.apiPrefix}${environment.commissionServiceExt}/commission-pool-scale/delete/${id}`);
  }
}
