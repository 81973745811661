<div class="ggi-declaration-popup">
  <div class="top">
    <cds-icon icon="action:cross" (click)="closeCurrentPopup()"></cds-icon>
  </div>

  <div class="display-all" [style.display]="!isEr ? 'block' : 'none'">
    <div class="l-pl-7">
      <span class="cds-h2-demibold">
        {{ 'Declaration.header' | lang }}
      </span>
    </div>
    <div appBottomDetection (haveReachedTheBottom)="haveReachedTheBottom()" class="l-pl-7 content">
      <span class="cds-body2 normal-word">
        {{ 'Declaration.headersub1' | lang }}
      </span>
      <br />
      <span class="cds-body2 l-pb-2 normal-word">
        {{ 'Declaration.headersub3' | lang }}
      </span>
      <br />
      <br />
      <span class="cds-body2 l-pt-2 l-pb-2 normal-word">
        {{ 'Declaration.bodydis' | lang }}
      </span>
      <br />
      <span class="cds-body2 l-pb-2 normal-word">
        {{ 'Declaration.bodycommon' | lang }}
      </span>
      <br />
      <span class="cds-body2 l-pb-2 normal-word">
        {{ 'Declaration.bodysuba' | lang }}
      </span>
      <br />
      <span class="cds-body2 l-pb-2 normal-word">
        {{ 'Declaration.bodysubb' | lang }}
      </span>
      <br />
      <span class="cds-body2 l-pb-2 normal-word">
        {{ 'Declaration.bodysubc' | lang }}
      </span>
      <br />
      <span class="cds-body2 l-pb-2 normal-word">
        {{ 'Declaration.bodysubd' | lang }}
      </span>
      <br />
      <br />
      <span class="cds-body2 l-pt-2 l-pb-2 normal-word">
        {{ 'Declaration.bodyadmin' | lang }}
      </span>
      <br />
      <span class="cds-body2 l-pb-2 normal-word">
        {{ 'Declaration.bodycommon' | lang }}
      </span>
      <br />
      <span class="cds-body2 l-pt-2 l-pb-2 normal-word">
        {{ 'Declaration.bodysube' | lang }}
      </span>
      <br />
      <span class="cds-body2 l-pb-2 normal-word">
        {{ 'Declaration.bodysubf' | lang }}
      </span>
      <br />
      <br />
      <span class="cds-body2 l-pt-2 l-pb-2 normal-word">
        {{ 'Declaration.bodyother' | lang }}
      </span>
      <br />
      <span class="cds-body2 l-pb-2 normal-word">
        {{ 'Declaration.bodycommon2' | lang }}
      </span>
      <br />
      <span class="cds-body2 l-pb-2 normal-word">
        {{ 'Declaration.bodysubg' | lang }}
      </span>
      <br />
      <span class="cds-body2 l-pb-2 normal-word">
        {{ 'Declaration.bodysubh' | lang }}
      </span>
      <br />
      <span class="cds-body2 l-pb-2 normal-word">
        {{ 'Declaration.bodysubi' | lang }}
      </span>
      <br />
      <br />
      <span class="cds-body2 l-pb-2 normal-word">
        {{ 'Declaration.bodyimportant' | lang }}
      </span>
      <br />
      <span class="cds-body2 l-pb-2 normal-word">
        {{ 'Declaration.bodynotice' | lang }}
      </span>
      <br />
      <br />
      <span class="cds-body2 l-pb-2 normal-word">
        {{ 'Declaration.bodynoticesub1' | lang }}
      </span>
      <br />
      <span class="cds-body2 l-pb-2 normal-word">
        {{ 'Declaration.bodynoticesuba' | lang }}
      </span>
      <br />
      <span class="cds-body2 l-pb-2 normal-word">
        {{ 'Declaration.bodynoticesubb' | lang }}
      </span>
      <br />
      <span class="cds-body2 l-pb-2 normal-word">
        {{ 'Declaration.bodynoticesubc' | lang }}
      </span>
      <br />
      <span class="cds-body2 l-pb-2 normal-word">
        {{ 'Declaration.bodynoticesubd' | lang }}
      </span>
      <br />
    </div>
    <div class="l-pl-7 l-mb-2 l-pt-5 declaration-agreement-btn">
      <cds-button label="{{ 'Declaration.noticeread' | lang }}" (click)="continue()" [disabled]="!isScrollBottom" [config]="commonButton"></cds-button>
    </div>
  </div>

  <div class="display-all" [style.display]="isEr ? 'block' : 'none'">
    <div class="l-pl-7">
      <span class="cds-h2-demibold">
        {{ 'Declaration.header' | lang }}
      </span>
    </div>
    <div appBottomDetection (haveReachedTheBottom)="haveReachedTheBottom()" class="l-pl-7 content">
      <span class="cds-body2 normal-word">
        {{ 'Declaration.headersub1' | lang }}
      </span>
      <br />
      <span class="cds-body2 l-pb-2 normal-word">
        {{ 'Declaration.headersub3' | lang }}
      </span>
      <br />
      <br />
      <span class="cds-body2 l-pt-2 l-pb-2 normal-word">
        {{ 'Declaration.bodydis' | lang }}
      </span>
      <br />
      <span class="cds-body2 l-pb-2 normal-word">
        {{ 'Declaration.bodycommon' | lang }}
      </span>
      <br />
      <span class="cds-body2 l-pb-2 normal-word">
        {{ 'Declaration.bodysuba' | lang }}
      </span>
      <br />
      <span class="cds-body2 l-pb-2 normal-word">
        {{ 'Declaration.bodysubb' | lang }}
      </span>
      <br />
      <span class="cds-body2 l-pb-2 normal-word">
        {{ 'Declaration.bodysubc' | lang }}
      </span>
      <br />
      <span class="cds-body2 l-pb-2 normal-word">
        {{ 'Declaration.bodysubd' | lang }}
      </span>
      <br />
      <br />
      <span class="cds-body2 l-pt-2 l-pb-2 normal-word">
        {{ 'Declaration.bodyadmin' | lang }}
      </span>
      <br />
      <span class="cds-body2 l-pb-2 normal-word">
        {{ 'Declaration.bodycommon' | lang }}
      </span>
      <br />
      <span class="cds-body2 l-pt-2 l-pb-2 normal-word">
        {{ 'Declaration.bodysube' | lang }}
      </span>
      <br />
      <span class="cds-body2 l-pb-2 normal-word">
        {{ 'Declaration.bodysubf' | lang }}
      </span>
      <br />
      <br />
      <span class="cds-body2 l-pt-2 l-pb-2 normal-word">
        {{ 'Declaration.bodyother' | lang }}
      </span>
      <br />
      <span class="cds-body2 l-pb-2 normal-word">
        {{ 'Declaration.bodycommon2' | lang }}
      </span>
      <br />
      <span class="cds-body2 l-pb-2 normal-word">
        {{ 'Declaration.bodysubg' | lang }}
      </span>
      <br />
      <span class="cds-body2 l-pb-2 normal-word">
        {{ 'Declaration.bodysubh' | lang }}
      </span>
      <br />
      <span class="cds-body2 l-pb-2 normal-word">
        {{ 'Declaration.bodysubi' | lang }}
      </span>
      <br />
      <br />
      <span class="cds-body2 l-pb-2 normal-word">
        {{ 'Declaration.bodyimportant' | lang }}
      </span>
      <br />
      <span class="cds-body2 l-pb-2 normal-word">
        {{ 'Declaration.bodynotice' | lang }}
      </span>
      <br />
      <br />
      <span class="cds-body2 l-pb-2 normal-word">
        {{ 'Declaration.bodynoticesub1' | lang }}
      </span>
      <br />
      <span class="cds-body2 l-pb-2 normal-word">
        {{ 'Declaration.bodynoticesuba' | lang }}
      </span>
      <br />
      <span class="cds-body2 l-pb-2 normal-word">
        {{ 'Declaration.bodynoticesubb' | lang }}
      </span>
      <br />
      <span class="cds-body2 l-pb-2 normal-word">
        {{ 'Declaration.bodynoticesubc' | lang }}
      </span>
      <br />
      <span class="cds-body2 l-pb-2 normal-word">
        {{ 'Declaration.bodynoticesubd' | lang }}
      </span>
      <br />
    </div>
    <div class="l-pl-7 l-mb-2 l-pt-5 declaration-agreement-btn">
      <cds-button label="{{ 'Declaration.noticeread' | lang }}" (click)="continue()" [disabled]="!isScrollBottom" [config]="commonButton"></cds-button>
    </div>
  </div>
</div>
