import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthLayoutComponent } from '../layout/auth-layout/auth-layout.component';
import { SplitAgentNotificationInfoErrorComponent } from '../salesjourney/agent-confirmation/split-agent-notification-info-error/split-agent-notification-info-error.component';
import { PermissionErrorComponent } from './permission-error/permission-error.component';
import { ResponseErrorComponent } from './response-error/response-error.component';
import { TimeoutComponent } from './timeout/timeout.component';

const routes: Routes = [
  {
    path: '',
    component: AuthLayoutComponent,
    // canDeactivate: [],
    children: [
      { path: 'timeout', component: TimeoutComponent },
      { path: 'permission-error', component: PermissionErrorComponent },
      { path: 'response-error', component: ResponseErrorComponent },
      {
        path: 'split-agent-notification-info-error',
        component: SplitAgentNotificationInfoErrorComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ErrorRoutingModule {}
