/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CdsToastService } from '@cds/ng-web-components/toast';
import { finalize } from 'rxjs';
import { ReportingRolePermissionGroupList } from 'src/app/core/models/enum/reporting-role.enum';
import { ResponseResult } from 'src/app/core/models/response/response-result';
import { ReportingRoleServiceService } from 'src/app/core/services/reporting-role-service.service';

@Component({
  selector: 'app-reporting-role-create',
  templateUrl: './reporting-role-create.component.html',
  styleUrls: ['./reporting-role-create.component.scss'],
})
export class ReportingRoleCreateComponent implements OnInit {
  rolePermissionGroupList = ReportingRolePermissionGroupList;
  isLoading = false;
  constructor(private router: Router, private reportingRoleServiceService: ReportingRoleServiceService, private toast: CdsToastService) {}

  ngOnInit() {}

  back() {
    this.router.navigate(['/system/reporting-role-list']);
  }

  save(param: any) {
    this.isLoading = true;
    this.reportingRoleServiceService
      .createRole(param)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(res => {
        if (res.result === ResponseResult.SUCCESS) {
          this.router.navigate(['/system/reporting-role-info'], {
            queryParams: { roleId: res.data?.roleId },
          });
        } else {
          this.toast.error(`Create role failed:${res.message}`);
        }
      });
  }
}
