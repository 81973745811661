import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { EmpfWorkflowComponent } from './empf-workflow.component';
import { ClosePopupComponent } from './close-popup/close-popup.component';

@NgModule({
  declarations: [EmpfWorkflowComponent, ClosePopupComponent],
  exports: [EmpfWorkflowComponent, ClosePopupComponent],
  imports: [CommonModule, SharedModule],
})
export class EmpfWorkflowModule {}
