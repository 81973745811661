<div class="row">
  <div class="col-xs-12 col-md-9">
    <div class="border-grey cds-white pt-3 l-pb-3 l-mt-3" *ngFor="let details of dataSource; let i = index">
      <div class="row l-plr-0">
        <div class="col-xs-12 col-md-12 l-pt-4">
          <table class="cds-table full cds-grey light table-border border-top">
            <caption></caption>
            <tr class="cds-white">
              <th scope="col" colspan="3">
                <div class="row l-plr-0 l-d-f l-ai-cen">
                  <div class="col-xs-1 col-md-3 l-plr-0 font-hidden-auto">
                    {{ 'commission.breakpoints.no' | lang }}
                  </div>
                  <div class="col-xs-5 col-md-4 l-plr-0 padding-left-03 text-left">
                    {{ 'commission.breakpoints.amount' | lang }}
                  </div>
                  <div class="col-xs-5 col-md-4 l-plr-0 padding-left-03 text-left">{{ 'commission.breakpoints.percentage' | lang }}(%)</div>
                </div>
              </th>
            </tr>
            <ng-container *ngFor="let item of details.list; let j = index">
              <tr #breakpointBox>
                <td colspan="3">
                  <div class="row l-plr-0">
                    <div class="col-xs-1 col-md-3 text-center">{{ j + 1 }}</div>
                    <div class="col-xs-5 col-md-4 l-plr-0 padding-left-03 text-left">
                      <input
                        [disabled]="commissionItem.scalePattern === 'FLAT' || isSubmitted || isEditPage"
                        [ngClass]="{
                          'text-field': commissionItem.scalePattern !== 'FLAT' && !isSubmitted && !isEditPage,
                          'text-field-disable': commissionItem.scalePattern === 'FLAT' && !isSubmitted && !isEditPage,
                          'input-no-border': isSubmitted || isEditPage
                        }"
                        placeholder="{{ !isSubmitted && !isEditPage ? ('commission.amount' | lang) : '' }}"
                        type="text"
                        oninput="value=value.match(/(^(\d){1,20}\.(\d){0,2})|(^(\d){1,20})/g) || ''"
                        value="{{ item.amount | mynumber : '2' }}"
                        #amount
                        (change)="breakpointChange('amount', $event, i, item, j)" />
                      <cds-assistive-text *ngIf="!!item.amountError && (!!item.amount || !!item.percentage)">{{ item.amountError }}</cds-assistive-text>
                    </div>
                    <div class="col-xs-5 col-md-4 l-plr-0 padding-left-03 text-left">
                      <div>
                        <input
                          class="text-field"
                          [disabled]="isSubmitted || isEditPage"
                          [ngClass]="{
                            'text-field': !isSubmitted && !isEditPage,
                            'input-no-border': isSubmitted || isEditPage
                          }"
                          placeholder="%"
                          type="text"
                          oninput="value=value.match(/(^(\d){1,2}\.(\d){0,4})|(^(\d){1,2})/g) || ''"
                          value="{{ item.percentage | mynumber : '4' }}"
                          #percentage
                          (change)="breakpointChange('percentage', $event, i, item, j)" />
                        <cds-assistive-text
                          *ngIf="!!item.percentageError && (!!item.amount || !!item.percentage || item.percentageError.indexOf('FIRST') > -1)">
                          {{ item.percentageError }}
                        </cds-assistive-text>
                      </div>
                    </div>
                    <div class="col-xs-1 col-md-1 l-plr-0 l-d-f l-ai-cen">
                      <app-icon-button
                        *ngIf="j !== 0 && !isSubmitted && !isEditPage"
                        class="l-pl-2"
                        icon="action:button_x_filled"
                        [iconCfg]="deleteIconCfg"
                        (funCall)="addOrDeleteBreakpoint(i, j)">
                      </app-icon-button>
                    </div>
                  </div>
                </td>
              </tr>
            </ng-container>
          </table>
          <div class="l-pt-3 l-d-f l-ai-cen" *ngIf="!isSubmitted && !isEditPage">
            <app-icon-button
              icon="action:button_plus_outlined"
              [iconCfg]="plusIconCfg"
              buttonText="commission.breakpoint.add"
              [disabled]="commissionItem.scalePattern === 'FLAT' || dataSource[i].list.length === 10"
              (funCall)="addOrDeleteBreakpoint(i)">
            </app-icon-button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-xs-12 col-md-3"></div>
</div>
