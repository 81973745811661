<cds-popup class="success-dialog">
  <cds-popup-title
    >{{ 'cheque.list.the-changes-you' | lang }}
    <span class="cds-h2-ita">{{ 'cheque.list.will-be-lost' | lang }}</span>
    {{ 'cheque.list.sure-to-continue' | lang }}
  </cds-popup-title>
  <cds-popup-content> </cds-popup-content>
  <cds-popup-actions>
    <cds-button label="{{ 'confirm.CancelButton' | lang }}" [config]="agreeButtonConfig" (click)="agree()"></cds-button>
    <cds-button [config]="disagreeButtonConfig" (click)="disagree()" label="{{ 'confirm.confirmBotton' | lang }}"></cds-button>
  </cds-popup-actions>
</cds-popup>
