<cds-popup *ngIf="data.type === 'confirm'">
  <cds-popup-title>{{ data.title | lang : params }}</cds-popup-title>
  <cds-popup-content>
    <div innerHtml="{{ data.message | lang : params }}"></div>
  </cds-popup-content>
  <cds-popup-actions>
    <cds-button [config]="agreeButtonConfig" (click)="agree()">{{ data.continue | lang }} </cds-button>
    <cds-button [config]="disagreeButtonConfig" (click)="disagree()">{{ data.cancel | lang }} </cds-button>
  </cds-popup-actions>
</cds-popup>

<cds-popup *ngIf="data.type === 'alert'">
  <cds-popup-title>{{ data.title | lang : params }}</cds-popup-title>
  <cds-popup-content>
    <div innerHtml="{{ data.message | lang : params }}"></div>
  </cds-popup-content>
  <div [class.float-right]="data.actionRight" class="l-pt-4">
    <cds-popup-actions>
      <cds-button [config]="agreeButtonConfig" (click)="agree()">{{ data.continue | lang }} </cds-button>
    </cds-popup-actions>
  </div>
</cds-popup>

<cds-popup *ngIf="data.type === 'loading'">
  <cds-progress mode="indeterminate"></cds-progress>
</cds-popup>
