<div class="l-pa-7 l-pt-5">
  <!-- start balance date area  -->
  <div class="cds-h4-demibold common-title margin-top-common green-title">
    {{ 'ee.profile.Fund-Balance' | lang }}
  </div>
  <!-- icon-area -->
  <div class="flex-row margin-top-common">
    <div class="add-icon">
      <cds-icon size="sm" icon="action:calendar"></cds-icon>
    </div>
    <div class="cds-h5-demibold des-text no-margin margin-left-common">Effective Date: 01/08/2022</div>
  </div>
  <!-- end-icon-area -->
  <div class="flex-row row no-padding margin-top-common">
    <div class="flex-column content-item col-sm-3 no-padding">
      <div class="cds-body2 no-margin title-color no-padding">Fund Balance - Total</div>
      <div class="cds-body1 no-margin">{{ fundInfo.fundBalanceTotal }}</div>
    </div>
    <div class="flex-column content-item col-sm-3 no-padding">
      <div class="cds-body2 no-margin title-color no-padding">
        {{ 'ee.profile.Fund-currency' | lang }}
      </div>
      <div class="cds-body1 no-margin">{{ fundInfo.fundCurrency }}</div>
    </div>
  </div>
  <!-- end balance date area -->

  <!-- start green title and white area -->
  <div class="cds-h4-demibold common-title margin-top-common green-title">
    {{ 'ee.opt.Fund-balance-and-allocation' | lang }}
  </div>
  <div class="acc-class">
    <app-cd-accordion class="no-padding">
      <app-cd-accordion-panel class="l-plr-0" *ngFor="let item of fundList" [title]="item.fundName">
        <div class="acc-content">
          <!-- 1th -->
          <div class="flex-row row no-padding margin-top-common">
            <div class="flex-column content-item col-sm-4 no-padding">
              <div class="cds-body2 no-margin title-color no-padding">
                {{ 'ee.profile.Offer-price' | lang }}
              </div>
              <div class="cds-body1 no-margin">{{ item.unitPrice }}</div>
            </div>
            <div class="flex-column content-item col-sm-4 no-padding">
              <div class="cds-body2 no-margin title-color no-padding">
                {{ 'ee.profile.Unit-balance-of-the-invested-fund' | lang }}
              </div>
              <div class="cds-body1 no-margin">{{ item.noOfUnit }}</div>
            </div>
            <div class="flex-column content-item col-sm-4 no-padding">
              <div class="cds-body2 no-margin title-color no-padding">
                {{ 'ee.profile.Fund-Balance' | lang }}
              </div>
              <div class="cds-body1 no-margin">{{ item.fundBalance }}</div>
            </div>
          </div>
          <!-- start FSEE etc area -->
          <div class="fund-area flex-column margin-top-common">
            <!-- <div class="cds-body2 fund-title title-color">
              Latest update: 9:05am 25/02/2022 (+8T)
            </div> -->
            <div class="flex-row row no-padding margin-top-common">
              <div class="flex-column content-item col-sm-6 no-padding">
                <div class="flex-row">
                  <div class="flex-row cds-body2 no-margin fund-title-color no-padding v-center">FSEE/EEMC %</div>
                  <div class="cds-body1 no-margin fund-title-color-sub margin-left-common">
                    {{ item.fSEE_EEMCPercent }}
                  </div>
                </div>
                <div class="flex-row margin-top-common">
                  <div class="flex-row cds-body2 no-margin fund-title-color no-padding v-center">FSER/ERMC %</div>
                  <div class="cds-body1 no-margin fund-title-color-sub margin-left-common">
                    {{ item.fSER_ERMCPercent }}
                  </div>
                </div>
              </div>
              <div class="flex-row content-item col-sm-6 no-padding v-center">
                <div class="div-col-line no-padding"></div>
                <div class="flex-column content-item col-sm-6 no-padding margin-left-common">
                  <div class="flex-row">
                    <div class="flex-row cds-body2 no-margin fund-title-color no-padding v-center">PSEE/EEVC %</div>
                    <div class="cds-body1 no-margin fund-title-color-sub margin-left-common">
                      {{ item.pSEE_EEVCPercent }}
                    </div>
                  </div>
                  <div class="flex-row margin-top-common">
                    <div class="flex-row cds-body2 no-margin fund-title-color no-padding v-center">PSER/ERVC %</div>
                    <div class="cds-body1 no-margin fund-title-color-sub margin-left-common">
                      {{ item.pSER_ERVCPercent }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- end FSEE etc area -->
        </div>
      </app-cd-accordion-panel>
    </app-cd-accordion>
  </div>
</div>
<!-- end green title and white area -->
