import { SortDirection } from '@angular/material/sort';
import { IPageableRequest, ISortObj } from 'src/app/core/models/request';
import { IPageResponse } from './../core/models/response/response';
import { IPaginationCount } from './../shared/pagination/pagination.dto';

export const toPageableRequest = (
  iCount: IPaginationCount,
  iSize: number,
  sort?: string,
  sortDirection?: SortDirection,
  sortExtra?: ISortObj[],
  filter?: string
): IPageableRequest => {
  return {
    page: iCount.current - 1,
    size: iSize,
    sort: sort,
    sortDirection: sortDirection,
    sortExtra: sortExtra,
    filter: filter,
  };
};

export const toPageCount = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  iResponse: IPageResponse<any>
): IPaginationCount => {
  return { current: iResponse.number + 1, count: iResponse.totalPages };
};
