import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NotificationlistComponent } from './notificationlist/notificationlist.component';
import { AdminLayoutComponent } from '../layout/admin-layout/admin-layout.component';
import { SummaryCreateComponent } from './summary-create/summary-create.component';
import { SummaryConfirmComponent } from './summary-confirm/summary-confirm.component';
import { ManageAudienceListComponent } from './manage-audience-list/manage-audience-list.component';
import { PermissionAccess } from 'src/app/core/models/enum/permission.enum';
const routes: Routes = [
  {
    path: 'mobile-subscription',
    component: AdminLayoutComponent,
    children: [
      { path: '', pathMatch: 'full', redirectTo: 'list' },
      {
        path: 'list',
        component: NotificationlistComponent,
        data: {
          title: 'notification.create.title',
          roles: {
            CUSTOMER_MOBILE_SUBSCRIPTION: PermissionAccess.R,
          },
        },
      },
      {
        path: 'create',
        component: SummaryCreateComponent,
        data: {
          title: 'summary.create.title',
          roles: {
            CUSTOMER_MOBILE_SUBSCRIPTION: PermissionAccess.W,
          },
        },
      },
      {
        path: 'confirm',
        component: SummaryConfirmComponent,
        data: {
          title: 'summary.create.title',
          roles: {
            CUSTOMER_MOBILE_SUBSCRIPTION: PermissionAccess.W,
          },
        },
      },
      {
        path: 'manage-audience-list',
        component: ManageAudienceListComponent,
        data: {
          title: 'notification.audience.manage',
          roles: {
            CUSTOMER_MOBILE_SUBSCRIPTION: PermissionAccess.R,
          },
        },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class MobileSubscriptionRoutingModule {}
