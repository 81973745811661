import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { SharedModule } from 'src/app/shared/shared.module';
import { CdsLanguageModule } from '@cds/ng-core/lang';
import { CdsTextfieldModule } from '@cds/ng-web-components/textfield';
import { CdsAssistiveTextModule } from '@cds/ng-web-components/assistive-text';
import { CdsButtonModule } from '@cds/ng-web-components/button';
import { CdsDropdownModule } from '@cds/ng-web-components/dropdown';
import { CdsCheckboxModule } from '@cds/ng-web-components/checkbox';
import { CdsFormFieldModule } from '@cds/ng-web-components/form-field';
import { HoldOnReportComponent } from './hold-on-report.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SharedModule,
    CdsLanguageModule,
    CdsAssistiveTextModule,
    CdsButtonModule,
    CdsTextfieldModule,
    CdsDropdownModule,
    CdsCheckboxModule,
    CdsFormFieldModule,
  ],
  declarations: [HoldOnReportComponent],
})
export class HoldOnReportModule {}
