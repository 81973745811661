<div class="employer-summary l-pt-6">
  <div class="title">
    <span class="cds-h3-bold dlgt-header">{{ 'employer.delegationPlatform' | lang }}</span>
  </div>
  <div class="div-border-top">
    <div class="cds-body2-demibold dlgt-header-desc">
      <div *ngIf="isInWhichStage !== 2">
        {{ 'employer.delegationPlatformDesc' | lang }}
      </div>
      <div class="dlgt-header-desc-content">
        <div>{{ 'employer.remarks' | lang }} :</div>
        <ul class="remarks-desc-box">
          <li>
            <div class="remarks-desc2">
              <span>{{ 'employer.remarksDesc2.paragraph1' | lang }}</span>
              <span class="paragraph2">{{ 'employer.remarksDesc2.paragraph2' | lang }}</span>
            </div>
          </li>
          <li>
            <span>{{ 'employer.remarksDesc3' | lang }}</span>
          </li>
          <li>
            <span>{{ 'employer.remarksDesc4' | lang }}</span>
          </li>
          <li>
            <span>{{ 'employer.remarksDesc5_1' | lang }} </span>
            <span class="text-link" (click)="jump()">{{ 'employer.remarksDesc5_2' | lang }}</span>
            <span> {{ 'employer.remarksDesc5_3' | lang }}</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div>
    <span class="cds-body-hero-light dlgt-search-and-result">{{ 'employer.search' | lang }} </span>
  </div>
  <div class="row search-box">
    <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12 search-box-item">
      <app-cd-input
        [disable]="editModel"
        [(value)]="nameValue"
        (valueChange)="nameChange($event)"
        [placeholder]="'employer.searchPlaceHolder' | lang"
        [label]="'employer.employerName' | lang"></app-cd-input>
    </div>
    <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12 search-box-item">
      <app-cd-input
        [disable]="editModel"
        [(value)]="numberValue"
        (valueChange)="numberChange($event)"
        [placeholder]="'employer.searchPlaceHolder' | lang"
        [label]="'employer.no' | lang"></app-cd-input>
    </div>
    <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12 search-box-item">
      <app-cd-input
        [disable]="editModel"
        [(value)]="agentValue"
        (valueChange)="delegateAgentChange($event)"
        [placeholder]="'employer.searchPlaceHolder' | lang"
        [label]="'employer.delegateAgencode' | lang"></app-cd-input>
    </div>
    <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12 search-box-item">
      <div class="double-botton">
        <div>
          <cds-button
            [disabled]="searchBtnDisableState || editModel"
            [config]="primaryButtonConfig"
            label="{{ 'employer.searchBtnTxt' | lang }}"
            (click)="employerSearch()"></cds-button>
        </div>
        <div>
          <cds-button [disabled]="editModel" [config]="secondaryButtonConfig" label="{{ 'employer.clear' | lang }}" (click)="clearData()"></cds-button>
        </div>
      </div>
    </div>
  </div>
  <div class="cds-body-hero-light dlgt-search-and-result">
    {{ (isInWhichStage !== 2 ? 'employer.result' : 'employer.ItemsToBeUpdated') | lang }}
  </div>
  <div *ngIf="isInWhichStage === 0">
    <div class="row" *ngIf="enterShowProgress || (isShowProgress && employerTotalCountInSummary === 0)">
      <div class="col-lg-3"></div>
      <cds-progress
        class="col-lg-6"
        mode="indeterminate"
        [diameter]="60"
        [type]="cdsProgressType"
        [text]="cdsProgressTxt"
        [color]="cdsProgressColor"></cds-progress>
      <div class="col-lg-3"></div>
    </div>
    <div *ngIf="employerTotalCountInSummary !== 0">
      <div class="employer-result-top l-ai-cen">
        <span class="cds-body2-demibold l-mb-0">{{ 'employer.total' | lang }} {{ employerTotalCountInSummary }} {{ 'employer.items' | lang }}</span>
        <div class="right-handle">
          <cds-button [config]="secondaryButtonConfig" label="{{ 'employer.edit' | lang }}" (click)="togglePage(isInWhichStage)"></cds-button>
        </div>
      </div>
      <div class="result-table">
        <div class="progress-box" *ngIf="isShowProgress">
          <cds-progress mode="indeterminate" [diameter]="60" [type]="cdsProgressType" [text]="cdsProgressTxt" [color]="cdsProgressColor"></cds-progress>
        </div>
        <div class="table-box" [ngClass]="[isShowProgress ? 'loading' : '']" #tableBox>
          <cds-table
            *ngIf="!resetTable"
            [dataSource]="dataSourceInSummary"
            [cdsSortDirection]="sortDirection"
            cdsSort
            (cdsSortChange)="onSortChange($event)"
            [class.full]="fullWidth">
            <ng-container cdsColumnDef="employerName">
              <cds-header-cell
                *cdsHeaderCellDef
                cds-sort-header
                [ngClass]="[checkIsHaveScroll ? 'shadow-right' : '']"
                class="employer-name-column-width no-border-right">
                {{ 'employer.employerName' | lang }}
              </cds-header-cell>
              <cds-cell *cdsCellDef="let element" [ngClass]="[checkIsHaveScroll ? 'shadow-right' : '']" class="employer-name-column-width no-border-right">
                <cds-link
                  [config]="{
                    type: 'hyperlink',
                    label:
                      language === 'EN'
                        ? element.employerName
                          ? element.employerName
                          : element.employerNameTC
                        : element.employerNameTC
                        ? element.employerNameTC
                        : element.employerName,
                    href: navigateToEEAddDelegation(element.employerId, element.employerName, element.employerNameTC)
                  }">
                </cds-link>
              </cds-cell>
            </ng-container>

            <ng-container cdsColumnDef="employerNo">
              <cds-header-cell *cdsHeaderCellDef cds-sort-header class="company-no-column-width"> {{ 'employer.no' | lang }}</cds-header-cell>
              <cds-cell *cdsCellDef="let element" class="company-no-column-width">
                {{ element.employerNo }}
              </cds-cell>
            </ng-container>

            <ng-container cdsColumnDef="employeeAmt">
              <cds-header-cell *cdsHeaderCellDef cds-sort-header class="employee-amt-column-width"> {{ 'employer.employeeNum' | lang }}</cds-header-cell>
              <cds-cell *cdsCellDef="let element" class="employee-amt-column-width">
                {{ element.employeeAmt }}
              </cds-cell>
            </ng-container>

            <ng-container cdsColumnDef="delegateAmt">
              <cds-header-cell *cdsHeaderCellDef cds-sort-header class="delegate-amt-column-width"> {{ 'employer.delegateAmt' | lang }}</cds-header-cell>
              <cds-cell *cdsCellDef="let element" class="delegate-amt-column-width">
                {{ element.delegateAmt }}
              </cds-cell>
            </ng-container>
            <ng-container cdsColumnDef="canDelegateAmt">
              <cds-header-cell *cdsHeaderCellDef cds-sort-header class="can-delegate-amt-column-width" [ngClass]="['no-border-left']">
                {{ 'employer.nonDelegateAmt' | lang }}
                <div #tooltip>
                  <cds-icon
                    (mouseenter)="onCanDelegateAmtToggle($event)"
                    (mouseleave)="onCanDelegateAmtToggle($event)"
                    [icon]="infoIcon"
                    [config]="iconConfig"
                    [cdsTooltip]="
                      language === 'EN'
                        ? 'Non-delegated new employees with account setup within 90 days will not be counted'
                        : '帳戶於90天內成立但沒有被委託的新僱員將不會計算在內'
                    "></cds-icon>
                </div>
              </cds-header-cell>
              <cds-cell *cdsCellDef="let element" class="can-delegate-amt-column-width" [ngClass]="['no-border-left']">
                {{ element.nonDelegateAmt }}
              </cds-cell>
            </ng-container>

            <ng-container cdsColumnDef="delegateAgentInfo">
              <cds-header-cell *cdsHeaderCellDef class="delegate-agent-info-column-width">{{ 'employer.delegateAgentInfo' | lang }}</cds-header-cell>
              <cds-cell *cdsCellDef="let element" class="delegate-agent-info-column-width">
                <div class="item">
                  <div title="{{ computedDelegateAgentInfoItem(item) }}" class="delegate-agent-info-item" *ngFor="let item of element.delegateAgentInfo">
                    <span>{{ item.agentCode }}</span>
                    <span class="agent-name">{{ language === 'EN' ? item.name : item.nameTC ? item.nameTC : item.name }}</span>
                  </div>
                </div>
              </cds-cell>
            </ng-container>
            <cds-header-row *cdsHeaderRowDef="displayedColumnsInSummary"></cds-header-row>
            <cds-row *cdsRowDef="let row; columns: displayedColumnsInSummary"></cds-row>
          </cds-table>
        </div>
        <div class="row result-table-footer">
          <div class="col-sm-6 col-xs-12 footer-pagination">
            <cds-pagination [color]="color" [pageCount]="pageCount" [page]="page" (pageChange)="pageChangeInSummary($event)"> </cds-pagination>
          </div>
          <div class="col-sm-6 col-xs-12 page-search">
            <span>Page </span>
            <input
              class="input"
              [ngModel]="page"
              type="number"
              #pageNoSearchBox
              (keydown.enter)="searchByInputPageNoInSummary($event, pageNoSearchBox.value)" />
            <span>of {{ pageCount }}</span>
          </div>
        </div>
      </div>
    </div>
    <app-no-result-found *ngIf="employerTotalCountInSummary === 0 && !isShowProgress"></app-no-result-found>
  </div>
  <div *ngIf="isInWhichStage === 1">
    <div class="inline-alert-box" *ngFor="let item of alertItems; let i = index">
      <cds-inline-alert class="inline-alert" [type]="alertType" *ngIf="item.ifShow" [dismissable]="true" (dismiss)="dismissAlert(i)">
        {{ language === 'EN' ? item.message : item.messageTC }}
      </cds-inline-alert>
    </div>

    <div class="employer-result-top l-ai-cen">
      <span class="cds-body2-demibold l-mb-0">{{ 'employer.total' | lang }} {{ employerTotalCountInDelegate }} {{ 'employer.items' | lang }}</span>
      <div class="right-handle">
        <cds-button [config]="secondaryButtonConfig" label="{{ 'employer.cancelEdit' | lang }}" (click)="togglePage(isInWhichStage)"></cds-button>
      </div>
    </div>
    <div class="row" *ngIf="enterShowProgress">
      <div class="col-lg-3"></div>
      <cds-progress
        class="col-lg-6"
        mode="indeterminate"
        [diameter]="60"
        [type]="cdsProgressType"
        [text]="cdsProgressTxt"
        [color]="cdsProgressColor"></cds-progress>
      <div class="col-lg-3"></div>
    </div>
    <div *ngIf="employerTotalCountInDelegate !== 0 && dataSourceInDelegate.length > 0">
      <div class="result-table">
        <div class="progress-box" *ngIf="isShowProgress">
          <cds-progress mode="indeterminate" [diameter]="60" [type]="cdsProgressType" [text]="cdsProgressTxt" [color]="cdsProgressColor"></cds-progress>
        </div>
        <div class="table-box" [ngClass]="[isShowProgress ? 'loading' : '']" #tableBox>
          <cds-table *ngIf="employerTotalCountInDelegate !== 0" [dataSource]="dataSourceInDelegate" [class.full]="fullWidth">
            <cds-row *cdsRowDef="let row; index as i; columns: displayedColumnsInDelegate"></cds-row>
            <cds-header-row *cdsHeaderRowDef="displayedColumnsInDelegate"></cds-header-row>

            <ng-container cdsColumnDef="isSelected" sticky>
              <cds-header-cell *cdsHeaderCellDef [ngClass]="[checkIsHaveScroll ? 'shadow-right' : '']" class="selected-column-width no-border-right">
                <cds-checkbox [checked]="allChecked" [indeterminate]="computedIsHalfSelected()" (change)="onHeaderCheckboxChange($event)"></cds-checkbox>
              </cds-header-cell>
              <cds-cell
                *cdsCellDef="let element; let row; index as i"
                [ngClass]="['selected-column-width', 'no-border-right', checkIsHaveScroll ? 'shadow-right' : '', element.isSelected ? 'column-checked' : '']">
                <cds-checkbox [checked]="element.isSelected" (change)="onClickRowSelect($event, i, markPage)"> </cds-checkbox>
              </cds-cell>
            </ng-container>

            <ng-container cdsColumnDef="employerName">
              <cds-header-cell *cdsHeaderCellDef class="employer-name-column-width" [ngClass]="[checkIsHaveScroll ? 'shadow-right' : '']">
                {{ 'employer.employerName' | lang }}
              </cds-header-cell>
              <cds-cell
                *cdsCellDef="let element"
                [ngClass]="['employer-name-column-width', element.isSelected ? 'column-checked' : '', checkIsHaveScroll ? 'shadow-right' : '']">
                <cds-link
                  class="no-action"
                  [config]="{
                    type: 'hyperlink',
                    label:
                      language === 'EN'
                        ? element.employerName
                          ? element.employerName
                          : element.employerNameTC
                        : element.employerNameTC
                        ? element.employerNameTC
                        : element.employerName,
                    href: navigateToEEAddDelegation(element.employerId, element.employerName, element.employerNameTC)
                  }"></cds-link>
              </cds-cell>
            </ng-container>

            <ng-container cdsColumnDef="employerNo">
              <cds-header-cell *cdsHeaderCellDef class="company-no-column-width"> {{ 'employer.no' | lang }}</cds-header-cell>
              <cds-cell *cdsCellDef="let element" [ngClass]="['company-no-column-width', element.isSelected ? 'column-checked' : '']">
                {{ element.employerNo }}
              </cds-cell>
            </ng-container>

            <ng-container cdsColumnDef="employeeAmt">
              <cds-header-cell *cdsHeaderCellDef class="employee-amt-column-width"> {{ 'employer.employeeNum' | lang }}</cds-header-cell>
              <cds-cell *cdsCellDef="let element" [ngClass]="['employee-amt-column-width', element.isSelected ? 'column-checked' : '']">
                {{ element.employeeAmt }}
              </cds-cell>
            </ng-container>

            <ng-container cdsColumnDef="delegateAmt">
              <cds-header-cell *cdsHeaderCellDef class="delegate-amt-column-width"> {{ 'employer.delegateAmt' | lang }}</cds-header-cell>
              <cds-cell *cdsCellDef="let element" [ngClass]="['delegate-amt-column-width', element.isSelected ? 'column-checked' : '']">
                {{ element.delegateAmt }}
              </cds-cell>
            </ng-container>
            <ng-container cdsColumnDef="nonDelegateAmt">
              <cds-header-cell *cdsHeaderCellDef class="non-delegate-amt-column-width" [ngClass]="['no-border-left']">
                <div class="can-delegate-amt-column-item">
                  {{ 'employer.nonDelegateAmt' | lang }}
                  <div #tooltip>
                    <cds-icon
                      (mouseenter)="onCanDelegateAmtToggle($event)"
                      (mouseleave)="onCanDelegateAmtToggle($event)"
                      [icon]="infoIcon"
                      [config]="iconConfig"
                      [cdsTooltip]="
                        language === 'EN'
                          ? 'Non-delegated new employees with account setup within 90 days will not be counted'
                          : '帳戶於90天內成立但沒有被委託的新僱員將不會計算在內'
                      "></cds-icon>
                  </div>
                </div>
              </cds-header-cell>
              <cds-cell *cdsCellDef="let element" [ngClass]="['non-delegate-amt-column-width', element.isSelected ? 'column-checked' : '', 'no-border-left']">
                {{ element.nonDelegateAmt }}
              </cds-cell>
            </ng-container>

            <ng-container cdsColumnDef="delegateAgentInfo">
              <cds-header-cell *cdsHeaderCellDef class="delegate-agent-info-column-width"> {{ 'employer.delegateAgentCode' | lang }}</cds-header-cell>
              <cds-cell
                *cdsCellDef="let element; let row; index as i"
                [ngClass]="['delegate-agent-info-column-width', element.isSelected ? 'column-checked' : '']">
                {{ element.agentCode }}
              </cds-cell>
            </ng-container>
          </cds-table>
        </div>
        <div
          [ngStyle]="{
            'margin-bottom': stepperTarget === 'ipad' ? '270px' : '90px'
          }"
          class="row result-table-footer">
          <div class="col-sm-6 col-xs-12 footer-pagination">
            <cds-pagination [color]="color" [pageCount]="pageCountDlgt" [page]="pageDlgt" (pageChange)="pageChangeOnDelegateEr($event)"></cds-pagination>
          </div>
          <div class="col-sm-6 col-xs-12 page-search">
            <span>Page </span>
            <input class="input" [value]="searchValueDlgt" #pageNoSearchBox (keydown.enter)="searchByInputPageNo($event, pageNoSearchBox.value)" />
            <span> of {{ pageCountDlgt }} </span>
          </div>
        </div>
      </div>
      <div class="row bottom-popup" *ngIf="stepperTarget === 'ipad'">
        <div class="stepper-box" style="padding-left: 0; padding-right: 0">
          <app-cd-stepper [isFreeStyle]="true" class="er-stepper" style="display: block" #stepper>
            <div freeStyle class="ipad-free-style">
              <div
                *ngFor="let item of stepperItems; let i = index"
                class="wrapper"
                [ngStyle]="{ width: i === 0 || i === 2 ? '45%' : '55%' }"
                [ngClass]="[i > 1 ? 'border-top' : '', i === 0 ? 'move-up-ten' : '']">
                <app-cd-step [ngClass]="i === 0 || i === 2 ? 'padding-left' : ''" #step>
                  <span [ngClass]="['cds-h6-demibold', step.finishStatus ? 'span-un' : 'span-fi']">{{ language === 'EN' ? item['en'] : item['zh'] }}</span>
                </app-cd-step>
                <div pendant class="stepper-operate-pad">
                  <div *ngIf="i === 0" class="padding-left">
                    <span class="cds-h6-demibold">{{ itemsSelected }} {{ 'employee.itemTotal' | lang }}</span>
                  </div>
                  <div class="fill-delegation-code" *ngIf="i === 1">
                    <span class="cds-body2-demibold">{{ 'delegate.delegateTo' | lang }}</span>
                    <app-cd-input
                      class="cds-h3-light text-field"
                      [disable]="isDisableBatchInput"
                      [value]="inputAgentCode"
                      #employerNameSearchBox
                      [maxlength]="6"
                      type="number"
                      (valueChange)="valueChangeBatchAgent(employerNameSearchBox.value)">
                    </app-cd-input>
                    <cds-assistive-text *ngIf="!inputAgentCodeValid" type="error" style="width: 150%">
                      <span *ngIf="inputAgentCodeErrorCode === 2101">{{ 'agentCode.notExists' | lang }} </span>
                      <span *ngIf="inputAgentCodeErrorCode === 2102">{{ 'agentCode.inactiveAgent' | lang }} </span>
                      <span *ngIf="inputAgentCodeErrorCode === 2103">{{ 'agentCode.notSameDistrict' | lang }} </span>
                      <span *ngIf="inputAgentCodeErrorCode === 2199">{{ 'agentCode.lengthShouldBe6' | lang }} </span>
                      <span *ngIf="inputAgentCodeErrorCode === 21031">
                        {{ 'agentCode.notHaveLicense' | lang }}
                      </span>
                    </cds-assistive-text>
                  </div>
                  <div class="apply-to-selected" *ngIf="i === 2" class="padding-left">
                    <cds-button [config]="secondaryButtonConfig" [disabled]="isDisableBatchApplyAgentCode" (click)="applyToAll()">{{
                      'delegate.applyToSelected' | lang
                    }}</cds-button>
                  </div>
                  <div class="submit" *ngIf="i === 3">
                    <cds-button [config]="primaryButtonConfig" [disabled]="isDisableSubmit" (click)="erDelegate()">{{ 'delegate.submit' | lang }}</cds-button>
                  </div>
                </div>
              </div>
            </div>
          </app-cd-stepper>
          <div class="step-clear step-clear-ipad" (click)="batchClearData()">
            <cds-icon class="step-icon" [icon]="reloadIcon"></cds-icon>
            <span class="cds-h6-demibold l-mb-0 step-clear-text"> {{ 'employer.reset' | lang }}</span>
          </div>
        </div>
      </div>
      <div class="row bottom-popup" *ngIf="stepperTarget === 'pc'">
        <div class="stepper-box">
          <app-cd-stepper class="er-stepper" #stepper>
            <app-cd-step #step *ngFor="let item of stepperItems; let i = index">
              <span [ngClass]="['cds-h6-demibold', step.finishStatus ? 'span-un' : 'span-fi']">{{ language === 'EN' ? item['en'] : item['zh'] }}</span>
            </app-cd-step>
          </app-cd-stepper>
          <div class="step-clear" (click)="batchClearData()">
            <cds-icon class="step-icon" [icon]="reloadIcon"></cds-icon>
            <span class="cds-h6-demibold l-mb-0 step-clear-text"> {{ 'employer.reset' | lang }}</span>
          </div>
        </div>

        <div class="stepper-operate-pc">
          <div>
            <span class="cds-h6-demibold">{{ itemsSelected }} {{ 'employee.itemTotal' | lang }}</span>
          </div>
          <div class="fill-delegation-code">
            <span class="cds-body2-demibold">{{ 'delegate.delegateTo' | lang }}</span>
            <app-cd-input
              class="cds-h3-light text-field"
              [disable]="isDisableBatchInput"
              [value]="inputAgentCode"
              #employerNameSearchBox
              [maxlength]="6"
              type="number"
              (valueChange)="valueChangeBatchAgent(employerNameSearchBox.value)">
            </app-cd-input>
            <cds-assistive-text *ngIf="!inputAgentCodeValid" type="error">
              <span *ngIf="inputAgentCodeErrorCode === 2101">{{ 'agentCode.notExists' | lang }} </span>
              <span *ngIf="inputAgentCodeErrorCode === 2102">{{ 'agentCode.inactiveAgent' | lang }} </span>
              <span *ngIf="inputAgentCodeErrorCode === 2103">{{ 'agentCode.notSameDistrict' | lang }} </span>
              <span *ngIf="inputAgentCodeErrorCode === 2199">{{ 'agentCode.lengthShouldBe6' | lang }} </span>
              <span *ngIf="inputAgentCodeErrorCode === 21031">
                {{ 'agentCode.notHaveLicense' | lang }}
              </span>
              <span *ngIf="inputAgentCodeErrorCode === 2105">{{ 'agentCode.invalidDelegatedAgentCode' | lang }} </span>
            </cds-assistive-text>
          </div>
          <div class="apply-to-selected">
            <cds-button [config]="secondaryButtonConfig" [disabled]="isDisableBatchApplyAgentCode" (click)="applyToAll()">{{
              'delegate.applyToSelected' | lang
            }}</cds-button>
          </div>
          <div class="submit">
            <cds-button [config]="primaryButtonConfig" [disabled]="isDisableSubmit" (click)="erDelegate()">{{ 'delegate.submit' | lang }}</cds-button>
          </div>
        </div>
      </div>
    </div>
    <app-no-result-found *ngIf="employerTotalCountInDelegate === 0 && !isShowProgress && !enterShowProgress"> </app-no-result-found>
  </div>
  <div *ngIf="isInWhichStage === 2">
    <div>
      <div class="employer-result-top">
        <span class="dlg-er-total">{{ 'employer.total' | lang }} {{ totalCountSbms }} {{ 'employer.items' | lang }}</span>
      </div>
      <div class="result-table">
        <div class="table-box" #tableBox>
          <cds-table [dataSource]="dataSourceInConfirmation" [class.full]="fullWidth">
            <cds-row *cdsRowDef="let row; index as i; columns: displayedColumnsInConfirmation"></cds-row>
            <cds-header-row *cdsHeaderRowDef="displayedColumnsInConfirmation"></cds-header-row>

            <ng-container cdsColumnDef="employerName">
              <cds-header-cell *cdsHeaderCellDef [ngClass]="[checkIsHaveScroll ? 'shadow-right' : '']" class="employer-name-column-width no-border-right">
                {{ 'employer.employerName' | lang }}
              </cds-header-cell>
              <cds-cell *cdsCellDef="let element" [ngClass]="[checkIsHaveScroll ? 'shadow-right' : '']" class="employer-name-column-width no-border-right">
                <cds-link
                  class="no-action"
                  [config]="{
                    type: 'hyperlink',
                    label:
                      language === 'EN'
                        ? element.employerName
                          ? element.employerName
                          : element.employerNameTC
                        : element.employerNameTC
                        ? element.employerNameTC
                        : element.employerName,
                    href: navigateToEEAddDelegation(element.employerId, element.employerName, element.employerNameTC)
                  }"></cds-link>
              </cds-cell>
            </ng-container>

            <ng-container cdsColumnDef="employerNo">
              <cds-header-cell *cdsHeaderCellDef class="company-no-column-width"> {{ 'employer.no' | lang }}</cds-header-cell>
              <cds-cell *cdsCellDef="let element" class="company-no-column-width">
                {{ element.employerNo }}
              </cds-cell>
            </ng-container>

            <ng-container cdsColumnDef="employeeAmt">
              <cds-header-cell *cdsHeaderCellDef class="employee-amt-column-width"> {{ 'employer.employeeNum' | lang }}</cds-header-cell>
              <cds-cell *cdsCellDef="let element" class="employee-amt-column-width">
                {{ element.employeeAmt }}
              </cds-cell>
            </ng-container>

            <ng-container cdsColumnDef="delegateAmt">
              <cds-header-cell *cdsHeaderCellDef class="delegate-amt-column-width"> {{ 'employer.delegateAmt' | lang }}</cds-header-cell>
              <cds-cell *cdsCellDef="let element" class="delegate-amt-column-width">
                {{ element.delegateAmt }}
              </cds-cell>
            </ng-container>
            <ng-container cdsColumnDef="nonDelegateAmt">
              <cds-header-cell *cdsHeaderCellDef class="non-delegate-amt-column-width" [ngClass]="'no-border-left'">
                <div class="can-delegate-amt-column-item">
                  {{ 'employer.nonDelegateAmt' | lang }}
                  <div #tooltip>
                    <cds-icon
                      (mouseenter)="onCanDelegateAmtToggle($event)"
                      (mouseleave)="onCanDelegateAmtToggle($event)"
                      [icon]="infoIcon"
                      [config]="iconConfig"
                      [cdsTooltip]="
                        language === 'EN'
                          ? 'Non-delegated new employees with account setup within 90 days will not be counted'
                          : '帳戶於90天內成立但沒有被委託的新僱員將不會計算在內'
                      "></cds-icon>
                  </div>
                </div>
              </cds-header-cell>
              <cds-cell *cdsCellDef="let element" class="non-delegate-amt-column-width" [ngClass]="'no-border-left'">
                {{ element.nonDelegateAmt }}
              </cds-cell>
            </ng-container>

            <ng-container cdsColumnDef="delegateAgentInfo">
              <cds-header-cell *cdsHeaderCellDef class="delegate-agent-info-column-width"> {{ 'employer.delegateAgentInfo' | lang }}</cds-header-cell>
              <cds-cell *cdsCellDef="let element; index as i" class="delegate-agent-info-column-width">
                <div class="item">
                  <div class="delegate-agent-info-item">
                    <span>{{ element.agentCode }}</span>
                    <span
                      [title]="
                        language === 'EN'
                          ? element.toDelegateAgentName
                            ? element.toDelegateAgentName
                            : element.toDelegateAgentNameTC
                          : element.toDelegateAgentNameTC
                          ? element.toDelegateAgentNameTC
                          : element.toDelegateAgentName
                      "
                      class="agent-name"
                      >{{
                        language === 'EN'
                          ? element.toDelegateAgentName
                            ? element.toDelegateAgentName
                            : element.toDelegateAgentNameTC
                          : element.toDelegateAgentNameTC
                          ? element.toDelegateAgentNameTC
                          : element.toDelegateAgentName
                      }}</span
                    >
                  </div>
                </div>
              </cds-cell>
            </ng-container>
          </cds-table>
        </div>
        <div class="row result-table-footer">
          <div class="col-sm-6 col-xs-12 footer-pagination">
            <cds-pagination [color]="color" [pageCount]="pageCountSbms" [page]="pageSbms" (pageChange)="pageChangeInConfirmation($event)"></cds-pagination>
          </div>
          <div class="col-sm-6 col-xs-12 page-search">
            <span>Page </span>
            <input class="input" [value]="searchValueSub" #pageNoSearchBox (keydown.enter)="searchByInputPageNoInConfirmation($event, pageNoSearchBox.value)" />
            <span> of {{ pageCountSbms }} </span>
          </div>
        </div>
      </div>
    </div>
    <div class="material-update">
      <div class="material-update-box">
        <cds-button label="{{ 'submission.backToEdit' | lang }}" (click)="erBackToEdit()" [config]="secondaryButtonConfig"></cds-button>
        <cds-button label="{{ 'submission.update' | lang }}" (click)="erConfirm()" [config]="primaryButtonConfig"> </cds-button>
      </div>
    </div>
  </div>
</div>
