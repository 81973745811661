/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpParams } from '@angular/common/http';
import { SortDirection } from '@angular/material/sort';

export interface ISortObj {
  sort: string;
  sortDirection: SortDirection;
}

// request
export interface IPageableRequest {
  page: number;
  size: number;
  sort?: string;
  sortDirection?: SortDirection;
  sortExtra?: ISortObj[];
  filter?: string;
}

export function filterParams(iObj?: any): HttpParams {
  if (iObj) {
    Object.keys(iObj).forEach(key => (iObj[key] === undefined || iObj[key] === '' || iObj[key] === null) && delete iObj[key]);
    return new HttpParams({
      fromObject: iObj as any,
    });
  }
  return new HttpParams();
}
