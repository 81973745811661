/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Inject, OnInit } from '@angular/core';
import { CdsLangService } from '@cds/ng-core/lang';
import { CdsPopupService, CDS_POPUP_DATA, MatDialogRef } from '@cds/ng-web-components/popup';
import { finalize } from 'rxjs';
import { EmployeeService } from '../../employee.service';
import { ToastAlertService } from 'src/app/shared/toast-alert.service';

import { PaymentTransactionResponse, FundInformationRecords } from '../../employee';

@Component({
  selector: 'app-payment-transaction-pop',
  templateUrl: './payment-transaction-pop.component.html',
  styleUrls: ['./payment-transaction-pop.component.scss'],
})
export class paymentTransactionPopComponent implements OnInit {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any

  get title() {
    return '';
  }

  transactionsRefInfo = [];

  paymentTransactionResponse: PaymentTransactionResponse = {
    list: this.transactionsRefInfo,
    firstName: '',
    lastName: '',
    firstZhName: '',
    lastZhName: '',
    mbAccountCode: '',
  };

  isLoading = false;

  constructor(
    private dialogRef: MatDialogRef<paymentTransactionPopComponent>,
    private cdsPopup: CdsPopupService,
    private langService: CdsLangService,

    @Inject(CDS_POPUP_DATA)
    public data: {
      transactionRefNo: string;
      paySubmitRefNo: string;
      id: string;
    },
    public service: EmployeeService,
    private toastAlert: ToastAlertService
  ) {}

  ngOnInit() {
    this.getInvestedFundInfo();
  }

  getInvestedFundInfo() {
    if (!this.data.id) return;
    this.isLoading = true;
    this.service
      .getPaymentTransaction(this.data.id, this.data.transactionRefNo, this.data.paySubmitRefNo)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe(res => {
        if (res.result === 0 && res.data) {
          this.paymentTransactionResponse = res.data;
        } else {
          this.toastError(res.message);
        }
      });
  }

  titleGen(data: FundInformationRecords) {
    let result = '';
    if (data.fundCode) {
      result = `${data.fundCode}    `;
    } else {
      return '';
    }

    if (data.fundEnName) {
      result = result + ' ' + data.fundEnName;
    }

    if (data.fundZhName) {
      result = result + ' ' + data.fundZhName;
    }
    return result;
  }

  close() {
    this.dialogRef.close();
  }

  toastError(errorMessage: string) {
    this.toastAlert.show('error', 'common.error', errorMessage, 5000);
  }
}
