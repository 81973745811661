import { Component, Inject, OnInit } from '@angular/core';
import {
  EmployerProfileDetail,
  I18N_KEY,
  MemberOptionFrequencyE,
  MspArrangementE,
  MultiServiceProvidersE,
  SpecialManagement,
} from 'src/app/views/employer/employer';
import { CdsDropdownConfig } from '@cds/ng-core/dropdown';
import { CdsButtonConfig } from '@cds/ng-core/button';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { EmployerService } from '../employer.service';
import { CdsTextfieldConfig } from '@cds/ng-core/textfield';
import { CdsPopupService, CDS_POPUP_DATA, MatDialogRef } from '@cds/ng-web-components/popup';
import { ContinuePopupComponent } from 'src/app/shared/continue-popup/continue-popup.component';
import { CdsLangService } from '@cds/ng-core/lang';
import { deepCopy } from 'src/app/utils/copy';
import { PermissionAccess, PermissionItem } from 'src/app/core/models/enum/permission.enum';
import { dateValidator, descriptionValidator, duplicatedValidator, nameValidator } from 'src/app/shared/validators/validators';
import { finalize } from 'rxjs';
import moment from 'moment';
import { AlertPopupComponent } from 'src/app/shared/alert-popup/alert-popup.component';
import { ToastAlertService } from 'src/app/shared/toast-alert.service';
import { CdsIconConfig } from '@cds/ng-core/icon';
import { DateTime } from 'luxon';

@Component({
  selector: 'app-special-management-popup',
  templateUrl: './special-management-popup.component.html',
  styleUrls: ['./special-management-popup.component.scss'],
})
export class SpecialManagementPopupComponent implements OnInit {
  requestUrl = 'identity-service/user/search-all';

  requestUrl1 = 'customer-service/employer/list';

  requestParams = {
    userType: 'INTERNAL_STAFF',
  };

  form = new FormGroup({});

  get multiNationalClient() {
    return this.form.get('multiNationalClient') as FormGroup;
  }

  get multiRelationshipManager() {
    return this.form.get('multiRelationshipManager') as FormControl;
  }

  get multiRelationshipTeamManager() {
    return this.form.get('multiRelationshipTeamManager') as FormControl;
  }

  get relationshipManager() {
    return this.form.get('relationshipManager') as FormControl;
  }

  get relationshipTeamManager() {
    return this.form.get('relationshipTeamManager') as FormControl;
  }

  get cso() {
    return this.form.get('cso') as FormControl;
  }

  get csoTeamManager() {
    return this.form.get('csoTeamManager') as FormControl;
  }

  get mspArrangement() {
    return this.form.get('mspArrangement') as FormControl;
  }

  get arrangementDescription() {
    return this.form.get('arrangementDescription') as FormControl;
  }

  get memberOptionFrequency() {
    return this.form.get('memberOptionFrequency') as FormControl;
  }

  get optFrequencyDescription() {
    return this.form.get('optFrequencyDescription') as FormControl;
  }

  get lastOptDate() {
    return this.form.get('lastOptDate') as FormControl;
  }

  get providerNames() {
    return this.form.get('providerNames') as FormArray;
  }

  formReady = false;

  resetDisabled = false;
  saveDisabled = false;

  showArrangementDescription = true;
  showOptFrequencyDescription = true;

  PermissionAccess = PermissionAccess;
  PermissionItem = PermissionItem;

  MspArrangementE = MspArrangementE;

  MemberOptionFrequencyE = MemberOptionFrequencyE;

  originDataSource: SpecialManagement = {
    multiNationalClient: true,
    multiRelationshipManager: '',
    multiRelationshipTeamManager: '',

    providerNames: [],
    relationshipManager: '',
    relationshipTeamManager: '',
    cso: '',
    csoTeamManager: '',
    mspArrangement: '',
    arrangementDescription: '',
    memberOptionFrequency: '',
    optFrequencyDescription: '',
    lastOptDate: '',
  };

  dataSource: SpecialManagement = deepCopy(this.originDataSource);

  isLoading = false;

  id!: string;

  addIconConfig: CdsIconConfig = {
    size: 'sm',
    color: '#EC6453',
  };

  get currentDate() {
    return new Date();
  }

  get currentDateInStr() {
    return DateTime.now().toFormat('dd/MM/yyyy');
  }

  constructor(
    private fb: FormBuilder,
    public service: EmployerService,
    private cdsPopup: CdsPopupService,
    private langService: CdsLangService,
    private toastAlert: ToastAlertService,
    private dialogRef: MatDialogRef<SpecialManagementPopupComponent>,

    @Inject(CDS_POPUP_DATA)
    public data: {
      employerProfileDetail?: EmployerProfileDetail;
    }
  ) {}

  ngOnInit(): void {
    this.getData();
  }

  getData() {
    this.isLoading = true;
    this.service
      .getSpecialOffer(this.data.employerProfileDetail?.id as string)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe(data => {
        if (data.result === 0 && data.data && data.data.content && data.data.content.length) {
          this.originDataSource = data.data.content[0];
          this.originDataSource.lastOptDate = moment(this.originDataSource.lastOptDate).format('DD/MM/YYYY');
          this.dataSource = deepCopy(this.originDataSource);
          this.desShowHandle();
          this.initForm();
        } else {
          this.initForm();
        }
      });
  }

  initForm() {
    this.formReady = false;

    this.form = this.fb.group({
      multiNationalClient: new FormControl(this.dataSource.multiNationalClient, {
        initialValueIsDefault: true,
      }),
      multiRelationshipManager: new FormControl(this.dataSource.multiRelationshipManager, {
        validators: [Validators.required, Validators.maxLength(200), Validators.minLength(2), nameValidator(I18N_KEY.INVALID_USERNAME, true)],
        initialValueIsDefault: true,
      }),
      multiRelationshipTeamManager: new FormControl(this.dataSource.multiRelationshipTeamManager, {
        validators: [Validators.required, Validators.maxLength(200), Validators.minLength(2), nameValidator(I18N_KEY.INVALID_USERNAME, true)],
        initialValueIsDefault: true,
      }),

      providerNames: this.fb.array(
        this.dataSource.providerNames
          ? this.dataSource.providerNames.map(item => {
              return this.fb.control(item.provider, {
                validators: [Validators.required, duplicatedValidator(I18N_KEY.PROVIDER_DULPLICATED)],
                initialValueIsDefault: true,
              });
            })
          : []
      ),

      relationshipManager: new FormControl(this.dataSource.relationshipManager, {
        validators: [Validators.required, Validators.maxLength(200), Validators.minLength(2), nameValidator(I18N_KEY.INVALID_USERNAME, true)],
        initialValueIsDefault: true,
      }),
      relationshipTeamManager: new FormControl(this.dataSource.relationshipTeamManager, {
        validators: [Validators.required, Validators.maxLength(200), Validators.minLength(2), nameValidator(I18N_KEY.INVALID_USERNAME, true)],
        initialValueIsDefault: true,
      }),
      cso: new FormControl(this.dataSource.cso, {
        validators: [Validators.required, Validators.maxLength(200), Validators.minLength(2), nameValidator(I18N_KEY.INVALID_USERNAME, true)],
        initialValueIsDefault: true,
      }),
      csoTeamManager: new FormControl(this.dataSource.csoTeamManager, {
        validators: [Validators.required, Validators.maxLength(200), Validators.minLength(2), nameValidator(I18N_KEY.INVALID_USERNAME, true)],
        initialValueIsDefault: true,
      }),
      mspArrangement: new FormControl(this.dataSource.mspArrangement, {
        validators: Validators.required,
        initialValueIsDefault: true,
      }),
      arrangementDescription: new FormControl(
        {
          value: this.dataSource.arrangementDescription,
          disabled: this.dataSource.mspArrangement !== MspArrangementE.OTHERS,
        },
        {
          validators: [Validators.required, Validators.maxLength(200), descriptionValidator(I18N_KEY.INVALID_DESCRIPTION)],
          initialValueIsDefault: true,
        }
      ),
      memberOptionFrequency: new FormControl(this.dataSource.memberOptionFrequency, {
        validators: Validators.required,
        initialValueIsDefault: true,
      }),
      optFrequencyDescription: new FormControl(
        {
          value: this.dataSource.optFrequencyDescription,
          disabled: this.dataSource.memberOptionFrequency !== MemberOptionFrequencyE.OTHERS_PLEASE_SPECIFY,
        },
        {
          validators: [Validators.required, Validators.maxLength(200), descriptionValidator(I18N_KEY.INVALID_DESCRIPTION)],
          initialValueIsDefault: true,
        }
      ),
      lastOptDate: new FormControl(this.dataSource.lastOptDate, {
        validators: [Validators.required, dateValidator(I18N_KEY.INVALID_DATE, '', this.currentDateInStr)],
        initialValueIsDefault: true,
      }),
    });

    this.multiNationalClient.disable();

    if (!this.multiNationalClient.value) {
      this.multiRelationshipManager.disable();
      this.multiRelationshipTeamManager.disable();
    } else {
      this.multiRelationshipManager.enable();
      this.multiRelationshipTeamManager.enable();
    }

    setTimeout(() => {
      this.formReady = true;
    });
  }

  _isEdit = false;

  mspArrangementConfig: CdsDropdownConfig = {
    // placeholder: 'Choose MSP Arrangement',
    options: [
      {
        label: '1. Both cont and assets at MIL',
        value: MspArrangementE.BOTH_CONT_AND_ASSETS_AT_MIL,
      },
      {
        label: '2. Cont at MIL but asset at other provider(s)',
        value: MspArrangementE.CONT_AT_MIL_BUT_ASSET_AT_OTHER_PROVIDER,
      },
      {
        label: '3. Both cont and asset to other provider(s)',
        value: MspArrangementE.BOTH_CONT_AND_ASSET_TO_OTHER_PROVIDER,
      },
      {
        label: '4. Cont to other provider(s) but asset at MIL',
        value: MspArrangementE.CONT_TO_OTHER_PROVIDER_BUT_ASSET_AT_MIL,
      },
      {
        label: '5. Others, please specify',
        value: MspArrangementE.OTHERS,
      },
      {
        label: '6. Information not available',
        value: MspArrangementE.INFORMATION_NOT_AVAILABLE,
      },
    ],
  };

  memberOptionFrequencyConfig: CdsDropdownConfig = {
    // placeholder: 'Choose Member Option Frequency',
    options: [
      {
        label: '1. One-off at inception',
        value: MemberOptionFrequencyE.ONE_OFF_AT_INCEPTION,
      },
      {
        label: '2. Annual',
        value: MemberOptionFrequencyE.ANNUAL,
      },
      {
        label: '3. Semi-annual',
        value: MemberOptionFrequencyE.SEMI_ANNUAL,
      },
      {
        label: '4. Others, please specify',
        value: MemberOptionFrequencyE.OTHERS_PLEASE_SPECIFY,
      },
      {
        label: '5. Information not available',
        value: MemberOptionFrequencyE.INFORMATION_NOT_AVAILABLE,
      },
    ],
  };

  arrangementDescriptionTextfieldConfig: CdsTextfieldConfig = {
    // placeholder: 'Input Arrangement Description',
    type: 'text',
  };

  optFrequencyDescriptionTextfieldConfig: CdsTextfieldConfig = {
    // placeholder: 'Input Opt Frequency Description',
    type: 'text',
  };

  providersConfig: CdsDropdownConfig = {
    placeholder: 'Choose Multi-Service Provider',
    options: [
      {
        label: 'AIA Company (Trustee) Limited',
        value: MultiServiceProvidersE.AIA_COMPANY_TRUSTEE_LIMITED,
      },
      {
        label: 'Bank of Communications Trustee Limited',
        value: MultiServiceProvidersE.BANK_OF_COMMUNICATIONS_TRUSTEE_LIMITED,
      },
      {
        label: 'Bank Consortium Trust Company Limited',
        value: MultiServiceProvidersE.BANK_CONSORTIUM_TRUST_COMPANY_LIMITED,
      },
      {
        label: 'Bank of East Asia (Trustees) Limited',
        value: MultiServiceProvidersE.BANK_OF_EAST_ASIA_TRUSTEES_LIMITED,
      },
      {
        label: 'BOCI-Prudential Trustee Limited',
        value: MultiServiceProvidersE.BOCI_PRUDENTIAL_TRUSTEE_LIMITED,
      },
      {
        label: 'China Life Trustees Limited',
        value: MultiServiceProvidersE.CHINA_LIFE_TRUSTEES_LIMITED,
      },
      {
        label: 'HSBC Provident Fund Trustee (Hong Kong) Limited',
        value: MultiServiceProvidersE.HSBC_PROVIDENT_FUND_TRUSTEE_HONG_KONG_LIMITED,
      },
      {
        label: 'Manulife Provident Funds Trust Company Limited',
        value: MultiServiceProvidersE.MANULIFE_PROVIDENT_FUNDS_TRUST_COMPANY_LIMITED,
      },
      {
        label: 'Principal Trust Company (Asia) Limited',
        value: MultiServiceProvidersE.PRINCIPAL_TRUST_COMPANY_ASIA_LIMITED,
      },
      {
        label: 'RBC Investor Services Trust Hong Kong Limited',
        value: MultiServiceProvidersE.RBC_INVESTOR_SERVICES_TRUST_HONG_KONG_LIMITED,
      },
      {
        label: 'Sun Life Pension Trust Limited',
        value: MultiServiceProvidersE.SUN_LIFE_PENSION_TRUST_LIMITED,
      },
      {
        label: 'Sun Life Trustee Company Limited',
        value: MultiServiceProvidersE.SUN_LIFE_TRUSTEE_COMPANY_LIMITED,
      },
      {
        label: 'YF Life Trustees Limited',
        value: MultiServiceProvidersE.YF_LIFE_TRUSTEES_LIMITED,
      },
      {
        label: 'Information not available',
        value: MultiServiceProvidersE.INFORMATION_NOT_AVAILABLE,
      },
    ],
  };

  addProviders(value = '') {
    this.providerNames?.push(this.fb.control(value, [Validators.required, duplicatedValidator(I18N_KEY.PROVIDER_DULPLICATED)]));
  }

  removeProvider(index: number) {
    this.providerNames?.removeAt(index);
    this.form.markAsDirty();
  }

  getControlValue = (groupName: string, controlName: string) => {
    this.form.get(groupName)?.get(controlName)?.value;
  };

  agreeButtonConfig: CdsButtonConfig = {
    style: 'secondary',
  };
  disagreeButtonConfig: CdsButtonConfig = {
    style: 'primary',
  };

  showDatePicker = true;

  reset() {
    if (!this.form.dirty) {
      return;
    }
    this.cdsPopup
      .open(ContinuePopupComponent, {
        size: 'sm',
        data: { message: this.langService.translate('common.action.cancel') },
      })
      .afterClosed()
      .subscribe(result => {
        if (result?.agree) {
          // reload datepicker dom to clear the selected date
          this.showDatePicker = false;
          setTimeout(() => {
            this.showDatePicker = true;
            this.doReset();
          });
        }
      });
  }

  doReset() {
    this.dataSource = deepCopy(this.originDataSource);
    this.form.reset();
    const providersFormArray = this.form.get('providerNames') as FormArray;
    providersFormArray.clear();
    this.dataSource.providerNames?.forEach(item => {
      this.addProviders(item.provider);
    }),
      this.form.markAsPristine();
  }

  submit() {
    if (!this.form.valid || !this.form.dirty) {
      return;
    }
    const params = deepCopy(this.form.value);
    params.providers = params.providerNames;
    params.lastOptDate = this.service.formateDate(params.lastOptDate);
    params.id = this.originDataSource.id;
    params.employerId = this.data.employerProfileDetail?.id;
    params.version = this.originDataSource.version;

    this.service
      .editSpecialOffer(params)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe(res => {
        if (res.result === 0) {
          this.popupWithReload();
        } else {
          this.toastError(res.message);
        }
      });
  }

  editTrigger() {
    if (this._isEdit) {
      if (!this.form.dirty) {
        this._isEdit = false;
        this.multiNationalClient.disable();
      } else {
        this.cdsPopup
          .open(ContinuePopupComponent, {
            size: 'sm',
            data: { message: this.langService.translate('common.action.cancel') },
          })
          .afterClosed()
          .subscribe(result => {
            if (result?.agree) {
              this.doReset();
              this._isEdit = false;
              this.multiNationalClient.disable();
            }
          });
      }
    } else {
      this._isEdit = true;
      this.multiNationalClient.enable();
    }

    // if (this._isEdit) {
    //   this.multiNationalClient.enable();
    // } else {
    //   this.multiNationalClient.disable();
    // }
  }

  multiNationalClientChange(value: boolean) {
    if (value) {
      this.multiRelationshipManager?.enable();
      this.multiRelationshipTeamManager?.enable();
    } else {
      this.multiRelationshipManager?.setValue('');
      this.multiRelationshipTeamManager?.setValue('');
      this.multiRelationshipManager?.disable();
      this.multiRelationshipTeamManager?.disable();
    }
  }

  mspArrangementChange(value: string) {
    const control = this.form.get('arrangementDescription');
    if (value === MspArrangementE.OTHERS) {
      control?.enable();
      this.showArrangementDescription = true;
    } else {
      control?.setValue('');
      control?.disable();
      this.showArrangementDescription = false;
    }
  }

  optionFrequencyChange(value: string) {
    const control = this.form.get('optFrequencyDescription');
    if (value === MemberOptionFrequencyE.OTHERS_PLEASE_SPECIFY) {
      control?.enable();
      this.showOptFrequencyDescription = true;
    } else {
      control?.setValue('');
      control?.disable();
      this.showOptFrequencyDescription = false;
    }
  }

  popupWithReload() {
    this.cdsPopup
      .open(AlertPopupComponent, {
        size: 'sm',
        data: {
          message: this.langService.translate('common.action.success'),
          buttonName: this.langService.translate('common.gotItWithExclamation'),
        },
      })
      .afterClosed()
      .subscribe(() => {
        this._isEdit = false;
        this.getData();
      });
  }
  providerChange() {
    this.providerNames?.controls.forEach(item => {
      item.updateValueAndValidity();
    });
  }

  desShowHandle() {
    this.mspArrangementChange(this.dataSource.mspArrangement);
    this.optionFrequencyChange(this.dataSource.memberOptionFrequency);
  }

  toastError(errorMessage: string) {
    this.toastAlert.show('error', 'common.error', errorMessage, 5000);
  }

  close() {
    if (this.form.dirty && this._isEdit) {
      this.cdsPopup
        .open(ContinuePopupComponent, {
          size: 'sm',
          data: { message: this.langService.translate('common.action.cancel') },
        })
        .afterClosed()
        .subscribe(result => {
          if (result?.agree) {
            this.dialogRef.close();
          }
        });
    } else {
      this.dialogRef.close();
    }
  }
}
