import { Injectable } from '@angular/core';
import { IffDeclinedReasonList } from './iff-declined-dashboard.config';

@Injectable({
  providedIn: 'root',
})
export class IffDeclinedDashboardService {
  constructor() {}

  iffDeclinedReasonMatch(value: string) {
    const result = {
      text: '',
      color: '',
    };
    const data = IffDeclinedReasonList.filter(item => item.value === value);
    if (data.length === 1) {
      result.text = data[0].label;
      switch (data[0].value) {
        case '1':
          result.color = 'rgb(0 167 88)';
          break;
        case '2':
          result.color = 'rgb(0, 0, 193)';
          break;
        case '3':
          result.color = 'rgb(236, 100, 83)';
          break;
        case '4':
          result.color = 'rgb(40, 43, 62)';
          break;
        case '6':
          result.color = 'rgb(215, 125, 40)';
          break;
      }
      return result;
    }
    return null;
  }
}
