import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AnnouncementDetailsComponent } from './announcement-details.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { CdsButtonModule } from '@cds/ng-web-components/button';
import { CdsIconModule } from '@cds/ng-web-components/icon';
import { CdsSwitchModule } from '@cds/ng-web-components/switch';
import { SharedModule } from 'src/app/shared/shared.module';
import { CdsLanguageModule } from '@cds/ng-core/lang';
import { CdsTextareaModule } from '@cds/ng-web-components/textarea';
import { CdsDatepickerModule } from '@cds/ng-web-components/datepicker';
import { CdsAssistiveTextModule } from '@cds/ng-web-components/assistive-text';

@NgModule({
  declarations: [AnnouncementDetailsComponent],
  imports: [
    CommonModule,
    CdsButtonModule,
    SharedModule,
    CdsSwitchModule,
    FormsModule,
    ReactiveFormsModule,
    MatSlideToggleModule,
    CdsIconModule,
    CdsLanguageModule,
    CdsTextareaModule,
    CdsDatepickerModule,
    CdsAssistiveTextModule,
  ],
})
export class AnnouncementDetailsModule {}
