<div class="delegation-change">
  <div class="head-box">
    <ng-container *ngIf="currentSite === SelfRouter.employerList">
      <div class="back-box">
        <cds-icon (click)="onBack()" [config]="backIconConfig" [icon]="backIcon"> </cds-icon>
        <span (click)="onBack()" class="cds-body2-demibold back-title">{{ 'delegationChange.backToRequestStatusSummaryPage' | lang }}</span>
      </div>
      <span class="head">{{ 'delegationChange.addDelegationChangeRequest' | lang }}</span>
    </ng-container>

    <ng-container *ngIf="currentSite === SelfRouter.change">
      <div class="back-box">
        <cds-icon (click)="onBackToEmployerList()" [config]="backIconConfig" [icon]="backIcon"> </cds-icon>
        <span (click)="onBackToEmployerList()" class="cds-body2-demibold back-title">{{ 'delegationChange.backToEmployerList' | lang }}</span>
      </div>
      <span class="head">{{ 'delegationChange.addDelegationChangeRequest' | lang }}</span>
    </ng-container>

    <span *ngIf="currentSite === SelfRouter.changeSummary" class="head">{{ 'delegationChange.delegationChangeRequestSummary' | lang }}</span>
  </div>

  <ng-container [ngSwitch]="currentSite">
    <div class="content" *ngSwitchCase="SelfRouter.employerList">
      <div class="info-box">
        <span class="info">{{ 'delegationChange.changeInfo' | lang }}</span>
      </div>

      <div class="search-wraper">
        <div class="cds-h2-light search-record">
          {{ 'delegationChange.searchRecord' | lang }}
        </div>
        <div class="row search-box">
          <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12 columns-item">
            <app-cd-input
              [label]="'delegationChange.employerName' | lang"
              [placeholder]="'delegationChange.inputEmployerName' | lang"
              [(value)]="employerQueryParams.employerName">
            </app-cd-input>
          </div>

          <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12 columns-item">
            <app-cd-input
              [label]="'delegationChange.groupNo' | lang"
              [placeholder]="'delegationChange.inputGroupNo' | lang"
              [(value)]="employerQueryParams.employerNo">
            </app-cd-input>
          </div>

          <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12 columns-item">
            <app-cd-input
              [label]="'delegationChange.delegatedAgentCode' | lang"
              [placeholder]="'delegationChange.inputDelegatedAgentCode' | lang"
              [(value)]="employerQueryParams.delegateAgentCode">
            </app-cd-input>
          </div>

          <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12 columns-item search-bottoms">
            <cds-button [style]="'primary'" size="sm" (click)="search()">{{ 'delegationChange.search' | lang }}</cds-button>
            <cds-button [style]="'secondary'" size="sm" (click)="reset()">{{ 'delegationChange.reset' | lang }}</cds-button>
          </div>
        </div>
      </div>

      <div class="progress-box" [style.display]="isShowProgress ? 'block' : 'none'">
        <cds-progress mode="indeterminate" type="ring"></cds-progress>
      </div>
      <div class="request-list" [style.display]="isShowProgress ? 'none' : 'flex'">
        <span class="cds-h2-light list-title">{{ 'delegationChange.delegationChangeRequestList' | lang }}</span>

        <span class="cds-body1 total-records">{{ 'delegationChange.total' | lang }} {{ totalRecords }} {{ 'delegationChange.records' | lang }}</span>

        <app-no-result-found [style.display]="currentPageData.length === 0 ? 'block' : 'none'"></app-no-result-found>

        <div [style.display]="currentPageData.length > 0 ? 'block' : 'none'">
          <div #tableBox class="table-box">
            <cds-table *ngIf="!resetTable" [dataSource]="currentPageData" cdsSort (cdsSortChange)="onSortChange($event)" [class.full]="true">
              <ng-container cdsColumnDef="isSelected" sticky>
                <cds-header-cell *cdsHeaderCellDef [ngClass]="['selected-column-width', 'no-border-right']">
                  <cds-checkbox
                    [checked]="delegationChangeListIsAllChecked()"
                    [indeterminate]="delegationChangeListIsHalfChecked()"
                    (change)="onAllCheckedChange($event)">
                  </cds-checkbox>
                </cds-header-cell>
                <cds-cell
                  *cdsCellDef="let element; let row; index as i"
                  [ngClass]="['selected-column-width', element.isSelected ? 'column-checked' : '', 'no-border-right']">
                  <cds-checkbox [checked]="element.isSelected" (change)="onRowCheckOfDelegationChangeList($event, element)"> </cds-checkbox>
                </cds-cell>
              </ng-container>

              <ng-container cdsColumnDef="employerName" sticky>
                <cds-header-cell
                  *cdsHeaderCellDef
                  cds-sort-header
                  [ngClass]="['employer-name-column-width', 'no-border-right', checkIsHaveScroll ? 'shadow-right' : '']"
                  >{{ 'delegationChange.employerName' | lang }}
                </cds-header-cell>
                <cds-cell
                  *cdsCellDef="let element"
                  [ngClass]="[
                    'employer-name-column-width',
                    element.isSelected ? 'column-checked' : '',
                    'no-border-right',
                    checkIsHaveScroll ? 'shadow-right' : ''
                  ]">
                  <cds-link
                    [config]="{
                      type: 'hyperlink',
                      label:
                        employerQueryParams.language === 'EN'
                          ? element.employerName
                            ? element.employerName
                            : element.employerNameTC
                          : element.employerNameTC
                          ? element.employerNameTC
                          : element.employerName,
                      size: 'sm',
                      href: getGoEEPath(element.employerId, element.employerName, element.employerNameTC)
                    }">
                  </cds-link>
                </cds-cell>
              </ng-container>

              <ng-container cdsColumnDef="groupNum">
                <cds-header-cell *cdsHeaderCellDef cds-sort-header [ngClass]="['group-num-column-width']"
                  >{{ 'delegationChange.groupNum' | lang }}
                </cds-header-cell>
                <cds-cell *cdsCellDef="let element" [ngClass]="['group-num-column-width', element.isSelected ? 'column-checked' : '']">
                  {{ element.groupNum }}
                </cds-cell>
              </ng-container>

              <ng-container cdsColumnDef="numOfEmployee">
                <cds-header-cell *cdsHeaderCellDef cds-sort-header [ngClass]="['num-of-employee-column-width']"
                  >{{ 'delegationChange.numOfEmployee' | lang }}
                </cds-header-cell>
                <cds-cell *cdsCellDef="let element" [ngClass]="['num-of-employee-column-width', element.isSelected ? 'column-checked' : '']">
                  {{ element.numOfEmployee }}
                </cds-cell>
              </ng-container>

              <ng-container cdsColumnDef="noOfEmployeeDelegated">
                <cds-header-cell *cdsHeaderCellDef cds-sort-header [ngClass]="['no-of-employee-delegated-column-width', 'sticky-end-line', 'no-border-left']">
                  <div class="l-d-f">
                    {{ 'delegationChange.noOfEmployeeDelegated' | lang }}
                    <div #tooltip class="l-as-cen">
                      <cds-icon
                        (mouseenter)="onCanDelegateAmtToggle($event)"
                        (mouseleave)="onCanDelegateAmtToggle($event)"
                        [icon]="infoIcon"
                        [config]="iconConfig"
                        [cdsTooltip]="'delegationChange.numOfEmployeeDelegatedChangeDescription' | lang"></cds-icon>
                    </div>
                  </div>
                </cds-header-cell>
                <cds-cell
                  *cdsCellDef="let element"
                  [ngClass]="['no-of-employee-delegated-column-width', 'sticky-end-line', element.isSelected ? 'column-checked' : '', 'no-border-left']">
                  {{ element.noOfEmployeeDelegated }}
                </cds-cell>
              </ng-container>

              <ng-container cdsColumnDef="delegatedAgents">
                <cds-header-cell *cdsHeaderCellDef [ngClass]="['delegated-agents-column-width', 'border-left-unset']"
                  >{{ 'delegationChange.delegatedAgents' | lang }}
                </cds-header-cell>
                <cds-cell
                  *cdsCellDef="let element"
                  [ngClass]="['delegated-agents-column-width', 'border-left-unset', element.isSelected ? 'column-checked' : '']">
                  <div class="delegated-agents-box">
                    <div class="delegated-agents-item" *ngFor="let item of element.delegatedAgents">
                      <span>{{ item.agentCode }}</span>
                      <span
                        class="name"
                        [title]="employerQueryParams.language === 'EN' ? (item.name ? item.name : item.nameTC) : item.nameTC ? item.nameTC : item.name"
                        >{{ employerQueryParams.language === 'EN' ? (item.name ? item.name : item.nameTC) : item.nameTC ? item.nameTC : item.name }}</span
                      >
                    </div>
                  </div>
                </cds-cell>
              </ng-container>

              <cds-header-row *cdsHeaderRowDef="displayedColumns"></cds-header-row>
              <cds-row *cdsRowDef="let row; columns: displayedColumns"></cds-row>
            </cds-table>
          </div>
          <div class="l-mt-4">
            <app-pagination
              [pageCount]="{
                current: currentPageNumOrigin,
                count: allPageData.length
              }"
              (pageChange)="onPageChange($event)">
            </app-pagination>
          </div>
        </div>
      </div>
    </div>

    <div class="content" *ngSwitchCase="SelfRouter.change">
      <div class="info-box">
        <span class="info">{{ 'delegationChange.changeInfo' | lang }}</span>
      </div>

      <div class="change-request-wraper">
        <span class="cds-h2-light">{{ 'delegationChange.changeRequest' | lang }}</span>

        <div class="list">
          <div class="list-head">
            <div class="checkbox-colums"></div>
            <div class="ds-body1-demibold employer-name-colums">
              {{ 'delegationChange.employerName' | lang }}
            </div>
            <div class="cds-body1-demibold group-no-colums">
              {{ 'delegationChange.groupNo' | lang }}
            </div>
            <div class="cds-body1-demibold no-of-employee-delegated-colums">
              {{ 'delegationChange.noOfEmployeeDelegated' | lang }}
              <div #tooltip class="l-as-cen">
                <cds-icon
                  (mouseenter)="onCanDelegateAmtToggle($event)"
                  (mouseleave)="onCanDelegateAmtToggle($event)"
                  [icon]="infoIcon"
                  [config]="iconConfig"
                  [cdsTooltip]="'delegationChange.numOfEmployeeDelegatedChangeDescription' | lang"></cds-icon>
              </div>
            </div>
          </div>
          <div class="l-d-f l-jc-cen l-mt-4" *ngIf="updateDelegationDelegatedAgentsData.length === 0">
            <cds-progress mode="indeterminate" type="ring"></cds-progress>
          </div>
          <div class="list-content">
            <div class="item-box" *ngFor="let data of updateDelegationDelegatedAgentsData">
              <div class="item">
                <div class="checkbox-colums">
                  <ng-container [ngSwitch]="data.isExpend">
                    <cds-icon *ngSwitchCase="true" (click)="toggleExpand(data)" class="buttom-icon" [icon]="buttonDownIcon"></cds-icon>
                    <cds-icon *ngSwitchCase="false" (click)="toggleExpand(data)" class="buttom-icon" [icon]="buttonRightIcon"> </cds-icon>
                  </ng-container>
                  <cds-checkbox
                    [checked]="isDelegatedAgentsAllChecked(data)"
                    [indeterminate]="isDelegatedAgentsHalfChecked(data)"
                    (change)="updateDelegatedAgentsAllChecked(data, $event)">
                  </cds-checkbox>
                </div>
                <div class="employer-name-colums">
                  <span class="ds-body1-demibold">{{
                    this.employerQueryParams.language === 'EN'
                      ? data.employerInfo.employerName
                        ? data.employerInfo.employerName
                        : data.employerInfo.employerNameTC
                      : data.employerInfo.employerNameTC
                      ? data.employerInfo.employerNameTC
                      : data.employerInfo.employerName
                  }}</span>
                </div>
                <div class="cds-body1-demibold group-no-colums">
                  {{ data.employerInfo.employerNo }}
                </div>
                <div class="cds-body1-demibold no-of-employee-delegated-colums">
                  {{ data.employerInfo.delegateAmt }}
                </div>
              </div>
              <div [hidden]="!data.isExpend" class="item-expand">
                <div *ngFor="let un of data.unavailable" class="cds-body1-demibold already-submitted">
                  {{ 'delegationChange.caseIsAlreadySubmitted1' | lang }}
                  <span class="cds-body1-ita">{{ un.oldAgent.agentCode }}</span>
                  {{ 'delegationChange.caseIsAlreadySubmitted2' | lang }}
                </div>

                <div *ngFor="let delegateItem of data.delegateInfos" class="sub-item">
                  <div class="checkbox-colums">
                    <div class="empty"></div>
                    <cds-checkbox [checked]="delegateItem.isSeleted" (change)="delegateItemSeleted(delegateItem, $event)"> </cds-checkbox>
                  </div>
                  <div class="sub-item-right">
                    <div class="delegated-agent-change">
                      <span class="cds-body2-ita left">{{ 'delegationChange.delegatedAgentChange' | lang }}</span>
                      <div class="existing-delegated-agent center">
                        <span class="cds-body2">{{ 'delegationChange.existingDelegatedAgent' | lang }}</span>
                        <span class="cds-body1">{{ delegateItem.agentCode }}</span>
                        <span
                          class="cds-body1 text-overflow-ellipsis"
                          [title]="
                            this.employerQueryParams.language === 'EN'
                              ? delegateItem.agentName
                                ? delegateItem.agentName
                                : delegateItem.agentNameTC
                              : delegateItem.agentNameTC
                              ? delegateItem.agentNameTC
                              : delegateItem.agentName
                          "
                          >{{
                            this.employerQueryParams.language === 'EN'
                              ? delegateItem.agentName
                                ? delegateItem.agentName
                                : delegateItem.agentNameTC
                              : delegateItem.agentNameTC
                              ? delegateItem.agentNameTC
                              : delegateItem.agentName
                          }}</span
                        >
                      </div>
                      <div class="icon-box">
                        <cds-icon [icon]="rightArrowIcon"></cds-icon>
                      </div>
                      <div class="right">
                        <span>{{ 'delegationChange.inputNewDelegatedAgentCode' | lang }}</span>
                        <span>{{ delegateItem.newDelegatedAgentCode }}</span>
                        <!-- <app-cd-input
                          #newDelegatedAgent
                          type="number"
                          [label]="'delegationChange.newDelegatedAgent' | lang"
                          [placeholder]="
                            'delegationChange.inputNewDelegatedAgentCode' | lang
                          "
                          [disable]="true"
                          [maxlength]="6"
                          [asyncValidator]="
                            asyncAgentCodeValidate(delegateItem)
                          "
                          [(value)]="
                            delegateItem.newDelegatedAgentCode
                          "></app-cd-input> -->
                        <span
                          [title]="
                            this.employerQueryParams.language === 'EN'
                              ? delegateItem.newDelegatedAgentName
                                ? delegateItem.newDelegatedAgentName
                                : delegateItem.newDelegatedAgentNameTC
                              : delegateItem.newDelegatedAgentNameTC
                              ? delegateItem.newDelegatedAgentNameTC
                              : delegateItem.newDelegatedAgentName
                          "
                          class="cds-body1 l-mb-0 text-overflow-ellipsis">
                          {{
                            this.employerQueryParams.language === 'EN'
                              ? delegateItem.newDelegatedAgentName
                                ? delegateItem.newDelegatedAgentName
                                : delegateItem.newDelegatedAgentNameTC
                              : delegateItem.newDelegatedAgentNameTC
                              ? delegateItem.newDelegatedAgentNameTC
                              : delegateItem.newDelegatedAgentName
                          }}
                        </span>
                        <span
                          *ngIf="delegateItem.newDelegatedAgentCode.length === 0 && delegateItem.isShowMissAgentCode"
                          class="missing-new-delegated-agent-code"
                          >{{ 'delegationChange.missingNewDelegatedAgentCode' | lang }}</span
                        >
                      </div>
                    </div>

                    <div class="num-of-employee-involved">
                      <span class="cds-body2-ita left">{{ 'delegationChange.numOfEmployeeInvolved' | lang }}</span>
                      <span class="cds-body1">{{ delegateItem.couldUpdateDelegateAmt }}</span>
                    </div>

                    <div class="reason-of-agent-change">
                      <span class="cds-body2-ita left">{{ 'delegationChange.reasonOfAgentChange' | lang }}</span>
                      <div class="center">
                        <span>{{ getReasonOfChange(delegateItem.selectReasonCodeOfChange) }}</span>
                        <!-- <cds-dropdown
                        [disabled]="true"
                          [config]="reasonOfAgentChangeConfig"
                          [(ngModel)]="delegateItem.selectReasonCodeOfChange"
                          (ngModelChange)="delegateInfoValidate(delegateItem)">
                        </cds-dropdown> -->
                      </div>
                    </div>

                    <div [hidden]="delegateItem.selectReasonCodeOfChange !== 5" class="input-the-other-reason-of-change">
                      <span>{{ delegateItem.inputReasonOfChange }}</span>
                      <!-- <app-cd-input
                        [maxlength]="100"
                        [(value)]="delegateItem.inputReasonOfChange"
                        [disable]="true"
                        (valueChange)="delegateInfoValidate(delegateItem)"
                        [placeholder]="
                          'delegationChange.inputTheOtherReasonOfChange' | lang
                        ">
                      </app-cd-input> -->
                    </div>
                    <span *ngIf="delegateItem.isShowMissReason" class="missing-reason-code">{{ 'delegationChange.missingReasonCode' | lang }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="content" *ngSwitchCase="SelfRouter.changeSummary">
      <div class="info-box change-summary-info">
        <span class="info">{{ 'delegationChange.changeSummaryInfo1' | lang }}</span>
        <span [ngClass]="['info', this.employerQueryParams.language === 'EN' ? 'info-waring' : '']">{{ 'delegationChange.changeSummaryInfo2' | lang }}</span>
        <span [ngClass]="['info', this.employerQueryParams.language === 'EN' ? '' : 'info-waring']">{{ 'delegationChange.changeSummaryInfo3' | lang }}</span>
      </div>

      <div class="change-request-wraper">
        <span class="cds-h2-light">{{ 'delegationChange.delegationChangeRequestSummary' | lang }}</span>

        <div class="list">
          <div class="list-head">
            <div class="checkbox-colums"></div>
            <div class="ds-body1-demibold employer-name-colums">
              {{ 'delegationChange.employerName' | lang }}
            </div>
            <div class="cds-body1-demibold group-no-colums">
              {{ 'delegationChange.groupNo' | lang }}
            </div>
            <div class="cds-body1-demibold no-of-employee-delegated-colums">
              {{ 'delegationChange.noOfEmployeeDelegated' | lang }}
              <div #tooltip class="l-as-cen">
                <cds-icon
                  (mouseenter)="onCanDelegateAmtToggle($event)"
                  (mouseleave)="onCanDelegateAmtToggle($event)"
                  [icon]="infoIcon"
                  [config]="iconConfig"
                  [cdsTooltip]="'delegationChange.numOfEmployeeDelegatedChangeDescription' | lang"></cds-icon>
              </div>
            </div>
            <div class="filling-colums"></div>
          </div>
          <div class="list-content">
            <div class="item-box" *ngFor="let data of editedDelegationDelegatedAgentsShowData">
              <div class="item">
                <div class="checkbox-colums">
                  <ng-container [ngSwitch]="data.isExpend">
                    <cds-icon *ngSwitchCase="true" (click)="toggleExpand(data)" class="buttom-icon" [icon]="buttonDownIcon"></cds-icon>
                    <cds-icon *ngSwitchCase="false" (click)="toggleExpand(data)" class="buttom-icon" [icon]="buttonRightIcon"> </cds-icon>
                  </ng-container>
                </div>
                <div class="employer-name-colums">
                  <span class="ds-body1-demibold">{{
                    this.employerQueryParams.language === 'EN'
                      ? data.employerInfo.employerName
                        ? data.employerInfo.employerName
                        : data.employerInfo.employerNameTC
                      : data.employerInfo.employerNameTC
                      ? data.employerInfo.employerNameTC
                      : data.employerInfo.employerName
                  }}</span>
                </div>
                <div class="cds-body1-demibold group-no-colums">
                  {{ data.employerInfo.employerNo }}
                </div>
                <div class="cds-body1-demibold no-of-employee-delegated-colums">
                  {{ data.employerInfo.delegateAmt }}
                </div>
                <div class="filling-colums"></div>
              </div>
              <div [hidden]="!data.isExpend" class="item-expand">
                <div *ngFor="let delegateItem of data.delegateInfos" class="sub-item">
                  <div class="checkbox-colums"></div>
                  <div class="sub-item-right">
                    <div class="delegated-agent-change">
                      <span class="cds-body2-ita left">{{ 'delegationChange.delegatedAgentChange' | lang }}</span>
                      <div class="existing-delegated-agent center">
                        <span class="cds-body2">{{ 'delegationChange.existingDelegatedAgent' | lang }}</span>
                        <span class="cds-body1">{{ delegateItem.agentCode }}</span>
                        <span
                          class="cds-body1 text-overflow-ellipsis"
                          [title]="
                            employerQueryParams.language === 'EN'
                              ? delegateItem.agentName
                                ? delegateItem.agentName
                                : delegateItem.agentNameTC
                              : delegateItem.agentNameTC
                              ? delegateItem.agentNameTC
                              : delegateItem.agentName
                          "
                          >{{
                            employerQueryParams.language === 'EN'
                              ? delegateItem.agentName
                                ? delegateItem.agentName
                                : delegateItem.agentNameTC
                              : delegateItem.agentNameTC
                              ? delegateItem.agentNameTC
                              : delegateItem.agentName
                          }}</span
                        >
                      </div>
                      <div class="icon-box">
                        <cds-icon [icon]="rightArrowIcon"></cds-icon>
                      </div>
                      <div class="right">
                        <span class="cds-body2 l-mb-0">{{ 'delegationChange.newDelegatedAgent' | lang }}</span>
                        <span class="cds-body1 l-mb-0">{{ delegateItem.newDelegatedAgentCode }}</span>
                        <span
                          [title]="
                            this.employerQueryParams.language === 'EN'
                              ? delegateItem.newDelegatedAgentName
                                ? delegateItem.newDelegatedAgentName
                                : delegateItem.newDelegatedAgentNameTC
                              : delegateItem.newDelegatedAgentNameTC
                              ? delegateItem.newDelegatedAgentNameTC
                              : delegateItem.newDelegatedAgentName
                          "
                          class="cds-body1 l-mb-0 text-overflow-ellipsis">
                          {{
                            this.employerQueryParams.language === 'EN'
                              ? delegateItem.newDelegatedAgentName
                                ? delegateItem.newDelegatedAgentName
                                : delegateItem.newDelegatedAgentNameTC
                              : delegateItem.newDelegatedAgentNameTC
                              ? delegateItem.newDelegatedAgentNameTC
                              : delegateItem.newDelegatedAgentName
                          }}
                        </span>
                      </div>
                    </div>

                    <div class="num-of-employee-involved">
                      <span class="cds-body2-ita left">{{ 'delegationChange.numOfEmployeeInvolved' | lang }}</span>
                      <span class="cds-body1 center">{{ delegateItem.couldUpdateDelegateAmt }}</span>
                    </div>

                    <div class="reason-of-agent-change">
                      <span class="cds-body2-ita left">{{ 'delegationChange.reasonOfAgentChange' | lang }}</span>
                      <div class="center">
                        <span *ngIf="delegateItem.selectReasonCodeOfChange === 5" class="cds-body1">{{ delegateItem.inputReasonOfChange }}</span>
                        <span *ngIf="delegateItem.selectReasonCodeOfChange !== 5" class="cds-body1">{{
                          getReasonOfChange(delegateItem.selectReasonCodeOfChange)
                        }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="l-mt-4">
          <app-pagination
            [pageCount]="{
              current: 1,
              count: editedDelegationDelegatedAgentsDataPageCount
            }"
            (pageChange)="editedDataPageChange($event)">
          </app-pagination>
        </div>
      </div>

      <div class="bottom-botton">
        <cds-button class="btn" (click)="onBackChange()" [style]="'secondary'" size="md">{{ 'backToEdit' | lang }}</cds-button>
        <cds-button class="btn" (click)="confirm()" [style]="'primary'" size="md">{{ 'confirm' | lang }}</cds-button>
      </div>
    </div>
  </ng-container>

  <ng-container [ngSwitch]="currentSite">
    <div *ngSwitchCase="SelfRouter.employerList" class="delegation-change-footer">
      <div class="employer-list">
        <span class="cds-h4 unset-margin-bottom">{{ 'delegationChange.pleaseSelectRecordsToContinue' | lang }}</span>
        <div class="footer-right">
          <span class="cds-h5-demibold unset-margin-bottom">{{ getTotalCheckedNumber }} {{ 'delegationChange.itemsSelected' | lang }}</span>
          <cds-button [style]="'primary'" size="md" [disabled]="isDisabledContinue" (click)="onGoChange()">{{ 'delegationChange.continue' | lang }}</cds-button>
        </div>
      </div>
    </div>

    <div *ngSwitchCase="SelfRouter.change" class="delegation-change-footer self-router-change">
      <ng-container *ngIf="stepperTarget === 'ipad'">
        <div class="stepper-box" style="padding-left: 0; padding-right: 0">
          <app-cd-stepper [isFreeStyle]="true" class="er-stepper" style="display: block" #stepper>
            <div freeStyle class="ipad-free-style">
              <div
                *ngFor="let item of stepperItems; let i = index"
                class="wrapper"
                [ngStyle]="{
                  width: i === 0 || i === 2 || i === 3 ? '35%' : i === 1 ? '50%' : '30%'
                }"
                [ngClass]="[i > 1 ? 'border-top' : '', i === 2 ? 'move-up-two' : '']">
                <app-cd-step [ngClass]="i === 0 || i === 2 ? 'padding-left' : ''" #step>
                  <span [ngClass]="['cds-h6-demibold', step.finishStatus ? 'span-un' : 'span-fi']">{{
                    employerQueryParams.language === 'EN' ? item['en'] : item['zh']
                  }}</span>
                </app-cd-step>
                <div pendant class="stepper-operate-ipad l-pt-4 l-pb-4">
                  <div *ngIf="i === 0" class="padding-left">
                    <span class="cds-h6-demibold">{{ updateDelegationSelectedTotal }} {{ 'employee.itemTotal' | lang }}</span>
                  </div>
                  <div *ngIf="i === 1" class="new-delegated-agent-code">
                    <span>{{ 'delegationChange.newDelegatedAgentCode' | lang }}</span>
                    <div class="batch-input-agent-code">
                      <app-cd-input
                        #newDelegatedAgent
                        type="number"
                        [maxlength]="6"
                        [(value)]="newDelegatedAgentCodeSummary"
                        (valueChange)="onNewDelegatedAgentCodeSummaryChange($event)"
                        [disable]="disabledNewDelegatedAgentCodeInput">
                      </app-cd-input>
                      <!-- [asyncValidator]="asyncAgentCodeValidate()" -->
                      <cds-assistive-text
                        *ngIf="newDelegatedAgentCodeSummary.length > 0 && agentCodeErrMsg.length > 0"
                        class="error-info"
                        style="width: 180%"
                        type="error">
                        <div class="cds-body2">
                          {{ agentCodeErrMsg | lang }}
                        </div>
                      </cds-assistive-text>
                      <span
                        [title]="
                          this.employerQueryParams.language === 'EN'
                            ? newDelegatedAgentNameSummary
                              ? newDelegatedAgentNameSummary
                              : newDelegatedAgentNameTCSummary
                            : newDelegatedAgentNameTCSummary
                            ? newDelegatedAgentNameTCSummary
                            : newDelegatedAgentNameSummary
                        "
                        class="cds-body1 l-mb-0 text-overflow-ellipsis">
                        {{
                          this.employerQueryParams.language === 'EN'
                            ? newDelegatedAgentNameSummary
                              ? newDelegatedAgentNameSummary
                              : newDelegatedAgentNameTCSummary
                            : newDelegatedAgentNameTCSummary
                            ? newDelegatedAgentNameTCSummary
                            : newDelegatedAgentNameSummary
                        }}
                      </span>
                    </div>
                  </div>

                  <div *ngIf="i === 2" class="select-reason-of-change padding-left">
                    <span>{{ 'delegationChange.selectReasonOfChange' | lang }}</span>
                    <cds-dropdown
                      [disabled]="disableReasonOfAgentChangeDropdown"
                      [config]="reasonOfAgentChangeConfig"
                      [ngModel]="reasonCodeOfAgentChangeControlSummary"
                      (change)="reasonOfAgentChangeControlSummaryChange($event)">
                    </cds-dropdown>
                  </div>
                  <div *ngIf="i === 3" class="add-to-item" [style.whiteSpace]="'nowrap'">
                    <cds-button [disabled]="disableAddToItem" [config]="secondaryButtonConfig" (click)="addToItem()"
                      >{{ 'delegationChange.addToItem' | lang }}
                    </cds-button>
                  </div>
                  <div *ngIf="i === 4" class="submit">
                    <cds-button [disabled]="disableSubmit" [config]="primaryButtonConfig" (click)="submit()">{{ 'delegationChange.submit' | lang }}</cds-button>
                  </div>
                </div>
              </div>
            </div>
          </app-cd-stepper>

          <div class="step-clear step-clear-ipad" (click)="resetDelegationChange()">
            <cds-icon class="step-icon" [icon]="reloadIcon"></cds-icon>
            <span class="cds-h6-demibold l-mb-0 step-clear-text"> {{ 'employer.reset' | lang }}</span>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="stepperTarget === 'pc'">
        <div class="stepper-box">
          <app-cd-stepper class="er-stepper" #stepper>
            <app-cd-step #step *ngFor="let item of stepperItems; let i = index">
              <span [ngClass]="['cds-h6-demibold', step.finishStatus ? 'span-un' : 'span-fi']">{{
                employerQueryParams.language === 'EN' ? item['en'] : item['zh']
              }}</span>
            </app-cd-step>
          </app-cd-stepper>

          <div class="step-clear" (click)="resetDelegationChange()">
            <cds-icon class="step-icon" [icon]="reloadIcon"></cds-icon>
            <span class="cds-h6-demibold l-mb-0 step-clear-text"> {{ 'employer.reset' | lang }}</span>
          </div>
        </div>

        <div class="stepper-operate l-pt-4 l-pb-4">
          <div>
            <span class="cds-h6-demibold">{{ updateDelegationSelectedTotal }} {{ 'employee.itemTotal' | lang }}</span>
          </div>
          <div class="new-delegated-agent-code">
            <span>{{ 'delegationChange.newDelegatedAgentCode' | lang }}</span>
            <div class="batch-input-agent-code">
              <app-cd-input
                #newDelegatedAgent
                type="number"
                [maxlength]="6"
                [(value)]="newDelegatedAgentCodeSummary"
                (valueChange)="onNewDelegatedAgentCodeSummaryChange($event)"
                [disable]="disabledNewDelegatedAgentCodeInput">
              </app-cd-input>
              <!-- [asyncValidator]="asyncAgentCodeValidate()" -->
              <cds-assistive-text *ngIf="newDelegatedAgentCodeSummary.length > 0 && agentCodeErrMsg.length > 0" class="error-info" type="error">
                <div class="cds-body2">
                  {{ agentCodeErrMsg | lang }}
                </div>
              </cds-assistive-text>
              <span
                [title]="
                  this.employerQueryParams.language === 'EN'
                    ? newDelegatedAgentNameSummary
                      ? newDelegatedAgentNameSummary
                      : newDelegatedAgentNameTCSummary
                    : newDelegatedAgentNameTCSummary
                    ? newDelegatedAgentNameTCSummary
                    : newDelegatedAgentNameSummary
                "
                class="cds-body1 l-mb-0 text-overflow-ellipsis">
                {{
                  this.employerQueryParams.language === 'EN'
                    ? newDelegatedAgentNameSummary
                      ? newDelegatedAgentNameSummary
                      : newDelegatedAgentNameTCSummary
                    : newDelegatedAgentNameTCSummary
                    ? newDelegatedAgentNameTCSummary
                    : newDelegatedAgentNameSummary
                }}
              </span>
            </div>
          </div>

          <div class="select-reason-of-change">
            <span>{{ 'delegationChange.selectReasonOfChange' | lang }}</span>
            <cds-dropdown
              [disabled]="disableReasonOfAgentChangeDropdown"
              [config]="reasonOfAgentChangeConfig"
              [ngModel]="reasonCodeOfAgentChangeControlSummary"
              (change)="reasonOfAgentChangeControlSummaryChange($event)">
            </cds-dropdown>
          </div>
          <div class="add-to-item" [style.whiteSpace]="'nowrap'">
            <cds-button [disabled]="disableAddToItem" [config]="secondaryButtonConfig" (click)="addToItem()"
              >{{ 'delegationChange.addToItem' | lang }}
            </cds-button>
          </div>
          <div class="submit">
            <cds-button [disabled]="disableSubmit" [config]="primaryButtonConfig" (click)="submit()">{{ 'delegationChange.submit' | lang }}</cds-button>
          </div>
        </div>
      </ng-container>
    </div>
  </ng-container>
</div>
