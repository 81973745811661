import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filterParams } from 'src/app/core/models/request';
import { PageResponse } from 'src/app/core/models/response/response';
import { environment } from 'src/environments/environment';
import { ColumnSort } from '../employer/employer';
import { EnrolmentRecord, EnrolmentSearchRequest } from '../enrolment/enrolment';

@Injectable({
  providedIn: 'root',
})
export class AgentPerformanceService {
  private customer_url = `${environment.apiPrefix}/ext/eb-ssms/customer-service/`;
  private enrolmentMemberListUrl = this.customer_url + 'shkp/enrolment-members/allList';
  //http://localhost:8082/shkp/enrolment-members/allList
  constructor(private http: HttpClient) {}

  getEnrolmentMemberList(params: EnrolmentSearchRequest, columnSort?: ColumnSort): Observable<PageResponse<EnrolmentRecord>> {
    const requestParams: EnrolmentSearchRequest = {
      ...params,
      sort: columnSort ? `${columnSort.key},${columnSort.value}` : '',
    };
    //http://localhost:8082/shkp/enrolment-members/allList
    return this.http.get<PageResponse<EnrolmentSearchRequest>>(this.enrolmentMemberListUrl, {
      params: filterParams(requestParams),
    });
  }

  joinName(...params: string[]) {
    let name = '';
    params.forEach(item => {
      if (item !== undefined && item !== null) {
        name = name + item;
      }
    });
    return name;
  }
}
