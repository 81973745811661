import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { CdsButtonConfig } from '@cds/ng-core/button';
import { CdsOption } from '@cds/ng-core/configuration';
import { CdsPopupService } from '@cds/ng-web-components/popup';
import { DateTime } from 'luxon';
import { finalize } from 'rxjs';
import { PermissionAccess, PermissionItem } from 'src/app/core/models/enum/permission.enum';
import { ToastAlertService } from 'src/app/shared/toast-alert.service';
import { dateValidator } from 'src/app/shared/validators/validators';

import {
  BillingClass,
  ContributionAmount,
  ContributionAmountType,
  ContributionDetails,
  ContributionDetailsCategory,
  ContributionDetailsCategoryE,
  ContributionSortByE,
  SuspenseRecord,
} from '../../employer/employer';
import { EmployerService } from '../../employer/employer.service';
import { OutstandingAmountOfPaymentPopupComponent } from '../../employer/payroll-group-info/outstanding-amount-of-payment-popup/outstanding-amount-of-payment-popup.component';
import { EmployeeService } from '../employee.service';

@Component({
  selector: 'app-contribution-records',
  templateUrl: './contribution-records.component.html',
  styleUrls: ['./contribution-records.component.scss'],
})
export class ContributionRecordsComponent implements OnInit, OnChanges {
  @Input() billingClass?: BillingClass = {
    id: '',
    payrollGroupName: 'groupName',
    addressList: [],
  };
  @Input() customerId? = '';

  public get startDate(): string {
    return this.startDateFormControl.value;
  }

  requestStartDate = '';

  // requestStartDate = '21/06/2023';

  runDate = '';

  ContributionDetailsCategoryE = ContributionDetailsCategoryE;

  isReset = false;

  contributionDetails: ContributionDetails = {
    contributionPeriodStartDate: '',
    contributionPeriodEndDate: '',
    billingReferenceNumber: '',
    billingStatusType: '',
    contributionFrequency: '',
    outstandingAmountOfPayment: 0,
    remittanceStatementReceivedDate: '',
    lives: 0,
    contLives: 0,
    billContributionAmount: undefined,
    reportedMemberContributionAmount: undefined,
    paidAmount: undefined,
    outstandingPaymentAmount: undefined,
  };

  searchButtonConfig: CdsButtonConfig = {
    size: 'sm',
  };

  resetButtonConfig: CdsButtonConfig = {
    size: 'sm',
    style: 'secondary',
  };

  nowTime = new Date();

  nowTimeInstr = DateTime.now().toFormat('dd/MM/yyyy');

  suspenseRecordList: SuspenseRecord[] = [
    {
      id: 'S20220902HHMMSS',
      paymentSubmissionReferenceNo: '00020901001',
      paymentReferenceNo: '00020901001',
      paymentReceivedDate: '12/08/2022',
      suspenseAmount: 1000,
      avaliableAmount: 0,
      taggedAmount: 1000,
    },
    {
      id: 'S20220903HHMMSS',
      paymentSubmissionReferenceNo: '00020901001',
      paymentReferenceNo: '00020901001',
      paymentReceivedDate: '12/08/2022',
      suspenseAmount: 1000,
      avaliableAmount: 0,
      taggedAmount: 1000,
    },
  ];

  contributionTableDataSource: ContributionAmount[] = [];

  contributionTablDisplayedColumns = ['category', 'ermc', 'eemc', 'surcharge', 'ervc', 'eevc'];

  selectedContributionSortBy: ContributionSortByE = ContributionSortByE.All;

  contributionSortBy: CdsOption[] = [
    {
      label: 'All',
      value: ContributionSortByE.All,
    },
    {
      label: 'DC REPORTED – successfully reported to MPFA',
      value: ContributionSortByE.DC_REPORTED,
    },
    {
      label: 'DC NOT_REPORTED - Not reported to MPFA',
      value: ContributionSortByE.DC_NOT_REPORTED,
    },
    {
      label: 'Reported Contribution as 0',
      value: ContributionSortByE.Reported_Contribution_0,
    },
    {
      label: 'DC GENERATED - reported to MPFA, pending to validate',
      value: ContributionSortByE.DC_GENERATED,
    },
    {
      label: 'Fully Settled',
      value: ContributionSortByE.Fully_Settled,
    },
    {
      label: 'VC Outstanding Only',
      value: ContributionSortByE.VC_Outstanding_Only,
    },
  ];

  get resetButtonDisabled() {
    return !this.startDate && !this.runDate;
  }

  startDateFormControl = new FormControl('', [dateValidator('create.dateError', '', this.nowTimeInstr)]);

  get searchButtonDisabled() {
    return !this.startDateFormControl?.valid || !this.startDateFormControl?.dirty;
  }

  isLoading = false;

  showBillInfo = false;

  PermissionAccess = PermissionAccess;

  PermissionItem = PermissionItem;

  showDatePicker = true;

  constructor(
    private cdsPopup: CdsPopupService,
    private employerService: EmployerService,
    private employeeService: EmployeeService,
    private toastAlert: ToastAlertService
  ) {}

  ngOnInit(): void {
    this.initLoad();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['customerId'].currentValue !== undefined && changes['customerId'].currentValue !== changes['customerId'].previousValue) {
      this.search(false, true);
    }
  }

  pillSelectedChange(selected: boolean, sortBy: ContributionSortByE) {
    if (selected) {
      this.selectedContributionSortBy = sortBy;
      this.filter();
    }
  }

  popupDetail(category: ContributionDetailsCategory, type: ContributionAmountType, amount: number) {
    if (amount === 0) {
      return;
    }
    this.cdsPopup.open(OutstandingAmountOfPaymentPopupComponent, {
      size: 'lg',
      data: {
        category,
        type,
        contributionDetails: this.contributionDetails,
      },
    });
  }

  toDetail() {}

  filter() {
    this.search(true);
  }

  searchButtonClick() {
    if (this.startDateFormControl.valid) {
      this.requestStartDate = this.startDate;
      this.search(false, true);
    }
  }

  initLoad() {
    if (this.billingClass === undefined) {
      return;
    }
    this.isLoading = true;
    this.employeeService
      .getContributionRecords(this.customerId, this.billingClass.payrollGroupId, this.requestStartDate, this.runDate)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe(data => {
        if (data.result === 0) {
          if (data.data && data.data.contributionPeriodStartDate) {
            this.contributionDetails = data.data || {};
            this.contributionTableDataSource = this.employerService.contributionAmountTableDataTrans(data.data);
            this.showBillInfo = true;
          } else {
            this.showBillInfo = false;
          }
        } else {
          this.toastError(data.message);
        }
      });
  }

  search(directly = false, resetFilter = false) {
    if (this.searchButtonDisabled && !directly) {
      return;
    }

    if (this.billingClass === undefined) {
      return;
    }

    if (resetFilter) {
      this.selectedContributionSortBy = ContributionSortByE.All;
    }

    this.isLoading = true;
    //MPF-EE-9000027-795082 MPF-MBR-10000024-410102
    this.employeeService
      .getContributionRecords(this.customerId, this.billingClass.payrollGroupId, this.requestStartDate, this.runDate)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe(data => {
        if (data.result === 0) {
          if (data.data && data.data.contributionPeriodStartDate) {
            this.contributionDetails = data.data || {};
            this.contributionTableDataSource = this.employerService.contributionAmountTableDataTrans(data.data);
            this.showBillInfo = true;
          } else {
            this.showBillInfo = false;
          }
        } else {
          this.toastError(data.message);
        }
        // if (!directly && this.outofPeriod()) {
        // }
      });
  }

  outofPeriod() {
    const currentDate = DateTime.fromFormat(DateTime.now().toFormat('yyyy-MM-dd'), 'yyyy-MM-dd');
    const startDate = DateTime.fromFormat(this.contributionDetails?.contributionPeriodStartDate || '', 'yyyy-MM-dd');
    const endDate = DateTime.fromFormat(this.contributionDetails?.contributionPeriodEndDate || '', 'yyyy-MM-dd');
    if (currentDate < startDate || currentDate > endDate) {
      return true;
    }
    return false;
  }

  current() {
    return new Date();
  }

  reset() {
    if (this.resetButtonDisabled) {
      return;
    }
    this.startDateFormControl.setValue('');
    this.startDateFormControl.markAsPristine();
    this.requestStartDate = '';
    this.runDate = '';
    this.showDatePicker = false;
    this.isReset = true;
    setTimeout(() => {
      this.showDatePicker = true;
      this.search(true, true);
    });
  }

  getCategoryTitle(category: ContributionDetailsCategory) {
    if (!category) {
      return '';
    }
    return ContributionDetailsCategoryE[category];
  }

  canClick(amount: number | string) {
    if (amount === 0 || amount === '0') {
      return false;
    }
    return true;
  }

  toastError(errorMessage: string) {
    this.toastAlert.show('error', 'common.error', errorMessage, 5000);
  }

  runDateChange(value: string) {
    this.runDate = value;
    this.isReset = false;
    this.search(true);
  }

  resetDate() {
    this.runDate = '';
  }
}
