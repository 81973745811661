/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import Big from 'big.js';
import { generateUUID } from 'src/app/utils/uuid';
import { AdjustmentService } from 'src/app/core/services/commission/commission-adjustment.service';
import { ScaleType } from 'src/app/core/models/enum/commission-scale.enum';

@Injectable({ providedIn: 'root' })
export class Validator {
  constructor(private adjustmentService: AdjustmentService) {}

  negativeValidation(list: any[], b: boolean, i: number, form: any) {
    const adjustItems: any[] = list[i].adjustItems;
    if (adjustItems) {
      list[i].negativeOn = b;
      adjustItems.forEach(item => {
        if (item.amount) {
          if (item.amount > 0 && b) {
            item.amount = new Big(item.amount).neg();
          }
          if (item.amount < 0 && !b) {
            item.amount = new Big(item.amount).abs();
          }
          if (item.adjustType && item.adjustType.indexOf('PC') == -1) {
            item.amount = String(new Big(item.amount).toFixed(2));
          }
        }
        if (item.amountPremium) {
          if (item.amountPremium > 0 && b) {
            item.amountPremium = String(new Big(item.amountPremium).neg().toFixed(2));
          }
          if (item.amountPremium < 0 && !b) {
            item.amountPremium = String(new Big(item.amountPremium).abs().toFixed(2));
          }
        }
      });
    }
    return this.requestCheck(list, form);
  }

  memberValidation(list: any[], value: any, i: number, form: any) {
    list[i].accountCode = value;
    list[i].memberError = '';
    if (!value && form.accountType == 'MPF_ER') {
      list[i].memberError = 'Member Account Code [PAC Flag] is blank';
    }
    list.forEach(item => {
      if (item.accountCode && form.accountType == 'MPF_SEP' && item.accountCode != form.accountCode) {
        item.memberError = 'Member Account Code on [PAC Flag] isn’t equal the number of searched MPF-SEP';
      }
      if (item.accountCode && form.accountType == 'MPF_SEP_AGENT' && item.accountCode != form.accountCode) {
        item.memberError = 'Member Account Code on [PAC Flag] isn’t equal the number of searched MPF-SEP (AGENT)';
      }
    });
    return this.requestCheck(list, form);
  }

  agentCodeValidation(list: any[], value: any, i: number, j: number, form: any) {
    list[i].agentCode = value;
    list[i].agentCodeError = '';
    if (!value) {
      list[i].agentCodeError = 'Agent code is blank';
    }
    return this.requestCheck(list, form);
  }

  adjustTypeValidation(list: any[], adjustTypes: any[], value: any, i: number, j: number, form: any): Promise<boolean> {
    list.forEach(item => {
      const adjustItems: any[] = item.adjustItems;
      adjustItems.forEach(subItem => {
        subItem.adjustTypeError = '';
      });
    });
    if (list[i].adjustItems[j].adjustTypes2.length == 0) {
      list[i].adjustItems[j].adjustTypeError = 'Input Adjust type is Invalid';
    }
    list[i].adjustItems[j].adjustType = '';
    list[i].adjustItems[j].correspondingScale = '';
    list[i].adjustItems[j].ptd = '';
    adjustTypes.forEach(item => {
      if (item.label == value) {
        list[i].adjustItems[j].adjustType = item.value;
        list[i].adjustItems[j].correspondingScale = item.correspondingScale;
        list[i].adjustItems[j].ptd = item.ptd;
      }
    });
    value = list[i].adjustItems[j].adjustType;
    if (value) {
      list[i].adjustItems[j].hide = false;
      const amount: string = list[i].adjustItems[j].amount;
      const amountPremium: string = list[i].adjustItems[j].amountPremium;
      if (amount) {
        if (value.indexOf('PC') !== -1) {
          list[i].adjustItems[j].amount = new Big(amount).toFixed(0);
        } else {
          list[i].adjustItems[j].amount = new Big(amount).toFixed(2);
        }
      }
      if (amountPremium && amountPremium !== '-') {
        list[i].adjustItems[j].amountPremium = new Big(amountPremium).toFixed(2);
      }
      if (value.indexOf('POLICY') !== -1 || value == 'IND_FS_PC') {
        list[i].adjustItems[j].hide = true;
        list[i].adjustItems[j].amount = '0.00';
        list[i].adjustItems[j].amountPremium = '0.00';
      }
    }
    return this.requestCheck(list, form);
  }

  amountValidation(list: any[], value: any, i: number, j: number, form: any): Promise<boolean> {
    const adjustType: string = list[i].adjustItems[j].adjustType;
    if (adjustType && adjustType.indexOf('PC') !== -1) {
      list[i].adjustItems[j].amount = new Big(value).toFixed(0);
    } else {
      list[i].adjustItems[j].amount = new Big(value).toFixed(2);
    }
    return this.requestCheck(list, form);
  }

  amountPremiumValidation(list: any[], value: any, i: number, j: number, form: any): Promise<boolean> {
    list[i].adjustItems[j].amountPremium = new Big(value).toFixed(2);
    return this.requestCheck(list, form);
  }

  formValidation(list: any[], form: any, response?: boolean) {
    if (!response) {
      const map = new Map();
      const set = new Set();
      list.forEach(item => {
        const adjustItems: any[] = item.adjustItems;
        adjustItems.forEach(subItem => {
          subItem.adjustTypeError = '';
          subItem.adjustTypeWarning = '';
          if (subItem.adjustType) {
            if (!set.has(item.agentCode + subItem.adjustType)) {
              set.add(item.agentCode + subItem.adjustType);
            } else {
              subItem.adjustTypeError = 'occur one more time in this adjustment';
            }
          }
          subItem.amountError = '';
          subItem.amountWarning = '';
          subItem.amountPremiumError = '';
          subItem.amountPremiumWarning = '';
          if (!subItem.hide) {
            const key: string = item.agentCode + subItem.adjustType;
            if (!map.get(key)) {
              map.set(key, { adjustTypeStr: subItem.adjustTypeStr, amountPremium: subItem.amountPremium });
            }
            const adjustTypeMap = new Map([
              [ScaleType.FS_PC, ScaleType.FY],
              [ScaleType.PS_PC, ScaleType.PS],
              [ScaleType.TRF_SP_PC, ScaleType.TRF_SP],
              [ScaleType.RY_PC, ScaleType.FS_SF],
              [ScaleType.IND_TRF_SP_PC, ScaleType.IND_TRF_SP],

              [ScaleType.FY, ScaleType.FS_PC],
              [ScaleType.PS, ScaleType.PS_PC],
              [ScaleType.TRF_SP, ScaleType.TRF_SP_PC],
              [ScaleType.FS_SF, ScaleType.RY_PC],
              [ScaleType.IND_TRF_SP, ScaleType.IND_TRF_SP_PC],
            ]);
            const relatedAdjustType = adjustTypeMap.get(subItem.adjustType);
            if (relatedAdjustType) {
              const obj: any = map.get(item.agentCode + String(relatedAdjustType));
              if (subItem.adjustTypeStr && obj && !new Big(obj.amountPremium).eq(subItem.amountPremium)) {
                subItem.amountPremiumError =
                  (item.agentCode ? item.agentCode + ': ' : '') + 'The Premium amount should be the same in ' + obj.adjustTypeStr + '&' + subItem.adjustTypeStr;
              }
            }
          }
        });
      });
    }
    let b = false;
    list.forEach(item => {
      if (item.memberError) {
        b = true;
      }
      if (form.type == 2) {
        if (form.accountType == 'MPF_ER' && !item.accountCode) {
          b = true;
        }
      }
      if (!item.agentCode) {
        b = true;
      }
      if (item.agentCodeError) {
        b = true;
      }
      if (item.agentCode && item.agentCode.length < 6) {
        b = true;
      }
      const adjustItems: any[] = item.adjustItems;
      adjustItems.forEach(subItem => {
        if (!subItem.adjustType || !subItem.amount || !subItem.amountPremium) {
          b = true;
        }
        if (subItem.amount == 0 && subItem.amountPremium == 0) {
          b = true;
        }
        if (subItem.adjustTypeError) {
          b = true;
        }
        if (subItem.amountPremiumError) {
          b = true;
        }
        if (subItem.amountError) {
          b = true;
        }
      });
    });
    return b;
  }

  requestId = '';
  requestCheck(list: any[], form: any): Promise<any> {
    return new Promise(resolve => {
      if (this.formValidation(list, form)) {
        resolve({ disable: true });
      } else {
        const params: any = this.mapList(list, form);
        this.requestId = generateUUID();
        console.log('request Id: ' + this.requestId);
        params.requestId = this.requestId;
        this.adjustmentService.check(params).subscribe(res => {
          if (res.requestId == this.requestId) {
            if (res && res.result == 0 && res.data) {
              this.reverseList(list, res.data, params.type);
              resolve({ disable: this.formValidation(list, form, true) });
            } else {
              resolve({ disable: true, errorMsg: res.message });
            }
          }
        });
      }
    });
  }

  mapList(list: any[], form: any) {
    const params: any[] = [];
    list.forEach(i => {
      const adjustItems: any[] = i.adjustItems;
      adjustItems.forEach(j => {
        const id = generateUUID();
        j.id = id;
        params.push({
          id: id,
          commissionPoolId: form.commissionPoolId,
          accountCode: i.accountCode,
          agentCode: i.agentCode,
          adjustType: j.adjustType,
          amount: j.amount,
          amountPremium: j.amountPremium,
          adjustTypeError: 0,
          amountError: 0,
          premiumError: 0,
        });
      });
    });
    return { list: params, type: form.type, searchAccountCode: form.accountCode, searchAccountType: form.accountType };
  }

  reverseList(list: any[], resList: any[], type: number) {
    list.forEach(i => {
      const adjustItems: any[] = i.adjustItems;
      adjustItems.forEach(j => {
        resList.forEach(item => {
          if (j.id == item.id) {
            i.memberError = '';
            i.agentCodeError = '';
            j.adjustTypeError = '';
            j.adjustTypeWarning = '';
            j.amountError = '';
            j.amountWarning = '';
            j.amountPremiumError = '';
            j.amountPremiumWarning = '';
            if (item.memberError == 1) {
              i.memberError = 'Member Account Code on [PAC Flag] isn’t one of the member of searched MPF-ER';
            }
            if (item.agentCodeError == 1) {
              i.agentCodeError = 'Agent code is Invalid';
            }
            if (item.duplicateError == 1) {
              j.adjustTypeError = 'occur one more time in this adjustment';
            }
            if (item.adjustTypeError == 2) {
              j.adjustTypeWarning = j.adjustTypeStr + ': The corresponding scale is without any scale code or the scale code is equal to NOCOMM';
            }
            if (item.amountError == 1) {
              j.amountError =
                'The reverse value on “Adjust Amount” is larger than ' +
                j.ptd +
                ' field on this Agent Code in the ' +
                (type == 1 ? 'Agent Compensation Section' : 'Individual Transfer Section');
            }
            if (item.amountError == 1 && type == 1 && item.agentCodeError == 2) {
              j.amountError =
                'Not allow to revise the negative value on Adjust Amount, this Agent Code isn’t one of the regular agent in the Agent Compensation Section';
            }
            if (item.amountError == 2) {
              j.amountWarning =
                'The reverse value on “Adjust Amount” is larger than ' +
                j.ptd +
                ' field on this Agent Code in the ' +
                (type == 1 ? 'Agent Compensation Section' : 'Individual Transfer Section');
            }
            if (item.amountPremiumError == 1) {
              j.amountPremiumError =
                'The reverse value on “Premium Adjust” is larger than “Prem PTD” field on ' +
                j.correspondingScale +
                ' in ' +
                (type == 1 ? 'Scale Record Detail section' : 'Individual Transfer section');
            }
            if (item.amountPremiumError == 2) {
              j.amountPremiumWarning =
                'The reverse value on “Premium Adjust” is larger than “Prem PTD” field on ' +
                j.correspondingScale +
                ' in ' +
                (type == 1 ? 'Scale Record Detail section' : 'Individual Transfer section');
            }
          }
        });
      });
    });
  }
}
