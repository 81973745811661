import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CdsAlertService } from '@cds/ng-web-components/alert';

@Injectable({
  providedIn: 'root',
})
export class DownloadFileService {
  private percentComplete?: { value: number };

  constructor(private alert: CdsAlertService, private http: HttpClient) {}

  public downloadFile(fileUrl: string, fileName: string, percentComplete: { value: number }, closeDownloadEvent: { event: (() => void) | null }) {
    return new Promise<void>(resolve => {
      this.percentComplete = percentComplete;
      const xhr = new XMLHttpRequest();
      closeDownloadEvent.event = () => {
        xhr.abort();
      };
      this.getBlob(fileUrl, xhr)
        .then(blob => {
          this.saveFile(blob, fileName);
          resolve();
        })
        .catch(errMsg => {
          this.alert.error('Error!', errMsg);
          resolve();
        });
    });
  }

  private getBlob(fileUrl: string, xhr: XMLHttpRequest) {
    return new Promise<Blob>((resolve, reject) => {
      xhr.open('GET', fileUrl, true);
      xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
      xhr.setRequestHeader('Access-Control-Allow-Methods', 'POST, GET, OPTIONS');
      xhr.setRequestHeader('mode', 'no-cors');
      xhr.addEventListener(
        'progress',
        evt => {
          if (evt.lengthComputable) {
            const percentComplete = evt.loaded / evt.total;
            if (this.percentComplete) {
              this.percentComplete.value = percentComplete * 100;
            }
          }
        },
        false
      );
      xhr.responseType = 'blob';
      xhr.onload = () => {
        if (xhr.status === 200) {
          resolve(xhr.response);
        } else {
          reject(`${xhr.status}`);
        }
      };
      xhr.addEventListener('error', err => {
        reject(`${err.loaded} bytes transferred`);
      });
      xhr.send();
    });
  }

  private saveFile(blob: Blob, fileName: string) {
    const link = document.createElement('a');
    const body = document.querySelector('body');
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;

    link.style.display = 'none';
    body?.appendChild(link);

    link.click();
    body?.removeChild(link);

    window.URL.revokeObjectURL(link.href);
  }
  public downLoad(url: string, fileName: string) {
    const link = document.createElement('a');
    const body = document.querySelector('body');
    link.href = url;
    link.download = fileName;

    link.style.display = 'none';
    body?.appendChild(link);

    link.click();
    body?.removeChild(link);

    window.URL.revokeObjectURL(link.href);
  }
  public downLoadNew(url: string, fileName: string) {
    this.http
      .get(url, {
        responseType: 'arraybuffer',
        headers: {
          mode: 'no-cors',
        },
      })
      .subscribe(buffer => {
        const e = new Blob([buffer], {
          type: 'application/octet-stream',
          // 'Content-Disposition': 'attachment'
        });
        const link = window.URL.createObjectURL(e);
        this.downLoad(link, fileName);
      });
  }
}
