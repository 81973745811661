import { CdRadioConfig } from 'src/app/shared/cd-radio/cd-radio.component';
import { FormControl } from '@angular/forms';
import { DeliveryChannelType } from 'src/app/core/models/enum/delivery-channel-type.enum';
import { CdsDropdownConfig } from '@cds/ng-core/dropdown';
export interface CaseSummary {
  salesJourneySummaryId: string;
  empfRefNo: string;
  productType: string;
  clientFirstName: string;
  clientFirstNameTC: string;
  clientLastName: string;
  clientLastNameTC: string;
  dateOfBirth: string;
  iffDeclarationDate: string;
  iffStatus: number;
  iffStatusName: string;
  empfStatus: number;
  empfStatusName: string;
  salesJourneyStatus: number;
  splitCase: number;
}

export interface CaseSummaryDS {
  isSelected: boolean;
  customerId?: string;
  isDisable: boolean;
  salesJourneySummaryId: string;
  eMPFRef: string;
  ProductType: string;
  ClientName: string;
  ClientNameTC: string;
  hkidOrPassportNo: string;
  IFFDeclarationDate: string;
  IFFStatus: string;
  eMPFAppStatus: string;
  SplitCase: boolean;
  countryCallingCode?: string;
  displayPhoneNumber?: string;
  emailAddr?: string;
  memberId?: string;
  phoneNumber?: string;
  deliveryChannelConfig?: CdRadioConfig;
  deliveryChannel?: DeliveryChannelType;
  preferredLanguageConfig?: CdRadioConfig;
  preferredLanguage?: PreferredLanguageType;
  secondAgentCode?: string;
  rmReferralCode?: FormControl;
  campaignCode?: FormControl;
  sourceCode?: FormControl;
  inputCustomerId?: FormControl;
}

export type ParentElement = {
  classList: DOMTokenList;
  parentElement: ParentElement;
};

export enum PreferredLanguageType {
  Chinese = 'Chinese',
  English = 'English',
}

export enum SalesJourneyProdType {
  Individual = 'IND',
  Corperate = 'ER',
  SEP = 'SEP',
}

export interface ContactPersonContact {
  contactPersonFirstName: string;
  contactPersonId: string;
  contactPersonLastName: string;
  contactPersonName: string;
  contactPersonNameChi: string;
  countryCallingCode?: string;
  displayPhoneNumber?: string;
  emailAddr?: string;
  phoneNumber?: string;
  deliveryChannelConfig?: CdRadioConfig;
}

export interface EmployerColumns {
  isSelected: boolean;
  isDisable: boolean;
  customerId?: string;
  eMPFRef: string;
  EmployerName: string;
  EmployerNameTC: string;
  BRNumber: string;
  IFFDeclarationDate: string;
  IFFStatus: string;
  eMPFAppStatus: string;
  SplitCase: boolean;
  salesJourneySummaryId: string;
  contactPersonContacts?: ContactPersonContact[];
  authorizedPersonNameControl?: FormControl;
  authorizedPersonNameConfigEng?: CdsDropdownConfig;
  authorizedPersonNameConfigChi?: CdsDropdownConfig;
  deliveryChannel?: string | number;
  secondAgentCode?: string;
  thirdAgentCode?: string;
  rmReferralCode?: FormControl;
  campaignCode?: FormControl;
  sourceCode?: FormControl;
  inputCustomerId?: FormControl;
  preferredLanguageConfig?: CdRadioConfig;
  preferredLanguage?: PreferredLanguageType;
  isIntragroupTransfer?: FormControl;
}
