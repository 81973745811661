<div class="cds-white l-pa-4 l-pb-6 l-mb-5">
  <div>
    <div class="cds-h2 cds-light l-mb-0">
      {{ 'role.edit.tab.assignUsers' | lang }}
    </div>
    <p class="cds-body1 l-mb-5">{{ 'role.edit.tab.assignUsers.notes' | lang }}</p>
  </div>
  <div class="row l-pa-0">
    <cds-form-field class="col-xs-12 col-md-4 l-pa-0">
      <cds-search [options]="aclNames" placeholder="{{ 'role.step2.user.assign' | lang }}" [formControl]="aclName" [appDisableFormControl]="aclUserLoading">
      </cds-search>
      <cds-assistive-text class="l-pt-5" *ngIf="aclName.invalid && (aclName.dirty || aclName.touched)">
        <div *ngIf="aclName.errors?.['required']">Minimum 2 characters.</div>
        <div *ngIf="aclName.errors?.['minlength']; else customerAclUserError">Minimum 2 characters.</div>
        <ng-template #customerAclUserError>
          <div *ngIf="aclNames.length > 0 && aclName.errors?.['unselectAclUser']; else userNotFoundError">please select user</div>
          <ng-template #userNotFoundError> User not found </ng-template>
        </ng-template>
      </cds-assistive-text>
    </cds-form-field>
    <cds-form-field class="col-xs-12 col-md-4 l-pl-4">
      <cds-dropdown
        [(ngModel)]="aclUserType"
        (ngModelChange)="aclUserFormReset()"
        [config]="aclUsetTypeDropdownConfig"
        [disabled]="aclUserLoading"></cds-dropdown>
    </cds-form-field>
    <cds-form-field class="col-xs-12 col-md-4 l-pl-4">
      <cds-button size="sm" *ngIf="roleId" [disabled]="!addRoleUserEnable()" (click)="addUserToRole()">Add User</cds-button>
    </cds-form-field>
  </div>
</div>
<div>
  <app-cd-table-title [title]="'role.step2.userList' | lang" [total]="pageConfig.totalCount ? pageConfig.totalCount : 0">
    <ng-template #tableAction>
      <cds-search [(ngModel)]="searchValue" (ngModelChange)="search()" placeholder="{{ 'role.step2.user.search' | lang }}"></cds-search>
    </ng-template>
  </app-cd-table-title>
  <app-data-table
    [frontPagination]="false"
    [isLoading]="loading"
    [fixed]="false"
    [overlay]="false"
    [pageConfig]="pageConfig"
    [dataSource]="dataDisplayed"
    [columnsConfig]="columnsConfig"
    (reloadTable)="pageChange($event)">
    <ng-template appDtBodyCellDef="delete" let-element>
      <cds-link
        [config]="{
          label: 'common.delete' | lang,
          size: 'sm',
          icon: 'form:delete'
        }"
        [disabled]="true"
        (click)="deleteUserConfirm(element.id)">
      </cds-link>
    </ng-template>
  </app-data-table>
</div>
