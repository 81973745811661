import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CdsButtonConfig } from '@cds/ng-core/button';
import { CdsIconConfig } from '@cds/ng-core/icon';
import { CdsLangService } from '@cds/ng-core/lang';
import { ActionIcons } from '@cds/ng-themes';
import { CdsAlertService } from '@cds/ng-web-components/alert';
import { CdsPopupService } from '@cds/ng-web-components/popup';
import { Subscription } from 'rxjs';
import { AgentChannel } from 'src/app/core/models/enum/agent-channel.enum';
import { PermissionAccess, PermissionItem } from 'src/app/core/models/enum/permission.enum';
import { ScreenType } from 'src/app/core/models/enum/screen.enum';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { NotifyService } from 'src/app/core/services/notify.service';
import { PermissionService } from 'src/app/core/services/permission.service';
import { CdHttpServeService } from 'src/app/shared/cd-http-serve/cd-http-serve.service';
import { LanguageChangeService } from 'src/app/shared/language-change.service';
import { cdsPopupBackdropOntouchmovePreventDefault, cdsPopupBackdropUnOntouchmovePreventDefault, numRanking, pageToTop } from 'src/app/utils/utils';
import { VoidInfo } from '../case-details/case-details.component';
import { AdviseIffstatusComponent } from '../case-summary/advise-iffstatus/advise-iffstatus.component';
import { AdviseTransferStatusComponent } from '../case-summary/advise-transfer-status/advise-transfer-status.component';
import { CasesummaryService } from '../case-summary/casesummary.service';
import { ResendEiffComponent } from '../resend-eiff/resend-eiff.component';
import { ResendEiffResendType } from '../resend-eiff/resend-eiff.model';
import { IffService } from '../service/iff.service';
import { EmpfDetail } from '../service/model/empf-details.model';
import { DifferentAgentStatusComponent } from './../different-agent-status/different-agent-status.component';
import { ErCaseDetailsRsp } from './case-details-er.model';
import { VoidEiffPopupComponent } from '../sales-support-summary/void-eiff-popup/void-eiff-popup.component';
import { FormControl } from '@angular/forms';

interface ErTransferDetailsItemRsp {
  newMILScheme: {
    newTrusteeName: string;
    schemeMemberAccNum: string;
    schemeName: string;
  };
  originalScheme: {
    originalTrusteeName: string;
    schemeMemberAccNum: string;
    schemeName: string;
  };
  transferStatus: string;
  transferStatusEnum: number;
}

@Component({
  selector: 'app-case-details-er',
  templateUrl: './case-details-er.component.html',
  styleUrls: ['./case-details-er.component.scss'],
})
export class CaseDetailsErComponent implements OnInit, AfterViewInit, OnDestroy {
  numRanking = numRanking;
  PermissionItem = PermissionItem;
  PermissionAccess = PermissionAccess;
  ScreenType = ScreenType;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public languageChangeService: LanguageChangeService,
    public cdsLangService: CdsLangService,
    public caseSummaryService: CasesummaryService,
    private cdHttpServeService: CdHttpServeService,
    private alert: CdsAlertService,
    private cdsPopup: CdsPopupService,
    private notifyService: NotifyService,
    private permissionService: PermissionService,
    private authenticationService: AuthenticationService,
    private iffService: IffService
  ) {}
  backErIcon = ActionIcons.button_left_filled;
  lanChangeSubscription?: Subscription;
  backErIconConfig: CdsIconConfig = {
    color: 'cta',
    size: 'sm',
  };
  language = this.cdsLangService.currentLang === 'en' ? 'EN' : 'TC';
  secondaryButtonConfig: CdsButtonConfig = {
    size: 'sm',
    style: 'secondary',
  };
  transferDetails: ErTransferDetailsItemRsp[] = [];
  empfDetailsSubscription?: Subscription;
  erCaseDetails?: ErCaseDetailsRsp;
  isIntragroupTransfer = new FormControl({ value: false, disabled: true });
  empfRefNo?: string;
  salesJourneySummariesId?: string;
  from?: string;
  isSendS2 = false;
  isShowSend = false;
  isServicingAgent = false;
  salesSupportSummaryTab?: string;
  servicingAgentChannel?: AgentChannel;
  disableEditAgentByChannel = false;
  screenType: ScreenType = ScreenType.PC;
  disableVoidEIFF = false;

  ngOnInit(): void {
    this.lanChangeSubscription = this.languageChangeService.lanSubject.subscribe(lan => {
      this.language = lan === 'en' ? 'EN' : 'TC';
    });
    this.salesJourneySummariesId = this.route.snapshot.paramMap.get('salesJourneySummariesId') || '';
    this.empfRefNo = this.route.snapshot.paramMap.get('empfRefNo') || '';
    this.from = this.route.snapshot.paramMap.get('from') || '';
    this.salesSupportSummaryTab = this.route.snapshot.paramMap.get('salesSupportSummaryTab') || '';

    if (this.salesJourneySummariesId) {
      const params = { salesJourneySummaryId: this.salesJourneySummariesId };
      this.cdHttpServeService.post<ErCaseDetailsRsp>('/ext/eb-ssms-sales-journey-service/employerSalesJourneySummaries/caseDetails', params).subscribe({
        next: res => {
          if (res.result !== 0) {
            this.alert.warning('Warning!', `${res.message}`);
          } else {
            this.erCaseDetails = res.data;
            this.isIntragroupTransfer.setValue(this.erCaseDetails?.intraGroup || false);
            this.getServicingAgentChannel();
            this.authenticationService.currentUserValue().then(res => {
              const servicingAgentId = res.id + '';
              this.iffService.getAgentCode(servicingAgentId).subscribe({
                next: res => {
                  if (res.result !== 0) {
                    this.alert.warning('Warning!', `${res.message}`);
                  } else {
                    const agentList = this.erCaseDetails?.agentsInfos?.filter(agent => agent.agentCd === res.data.agentCode);
                    this.isServicingAgent = Boolean(agentList && agentList[0]?.servicingAgent);
                  }
                },
                error: err => {
                  this.alert.error('Error!', err);
                },
              });
            });
          }
        },
        error: err => {
          this.alert.error('Error!', err);
        },
      });
    }

    if (this.empfRefNo) {
      const empfRefNo = this.empfRefNo;
      const params = { empfRefNo: empfRefNo };
      this.cdHttpServeService
        .post<ErTransferDetailsItemRsp[]>('/ext/eb-ssms-sales-journey-service/employerSalesJourneySummaries/transferDetails', params)
        .subscribe({
          next: res => {
            if (res.result !== 0) {
              this.alert.warning('Warning!', `${res.message}`);
            } else {
              this.transferDetails = res.data;
            }
          },
          error: err => {
            this.alert.error('Error!', err);
          },
        });
    }

    this.checkSendS2();
  }

  private resizeListener = () => {
    const isPad = window.matchMedia('only screen and (max-width: 1023px)').matches || window.screen.width < 1023;
    if (isPad) {
      this.screenType = ScreenType.PAD;
      this.notifyService.notifyHideNavigationLang();
    } else {
      this.screenType = ScreenType.PC;
    }
  };

  ngAfterViewInit(): void {
    Promise.all([
      this.permissionService.hasPermission(PermissionAccess.W, PermissionItem.SALES_JOURNEY_AGENT),
      this.permissionService.hasPermission(PermissionAccess.W, PermissionItem.CUSTOMER_MEMBER),
    ]).then(hasPermission => {
      this.isShowSend = hasPermission[0] || hasPermission[1];
    });
    pageToTop();
    if (this.from === 'superAdmin') {
      this.resizeListener();
      this.notifyService.notifyHideNavigationLang();
      window.addEventListener('resize', this.resizeListener);
      this.languageChangeService.setLang('en');
    }
  }

  ngOnDestroy(): void {
    if (this.from === 'superAdmin') {
      this.notifyService.notifyDisplayNavigationLang();
      window.removeEventListener('resize', this.resizeListener);
    }
  }

  onBackToCaseSummaryClick() {
    this.router.navigate(['/salesjourney/product-type-summary/case-summary']);
  }

  onBackToSalesSupportSummaryClick() {
    const queryParams = { productType: 'ER', salesSupportSummaryTab: this.salesSupportSummaryTab };
    this.router.navigate(['/salesjourney/sales-support-summary', queryParams]);
  }

  onBackTBranchLebvelClick() {
    const queryParams = { productType: 'ER' };
    this.router.navigate(['/salesjourney/app-paper-summary', queryParams]);
  }

  onBackIffDeclinedDashboard() {
    this.router.navigate(['/salesjourney/iff-declined-dashboard']);
  }

  onBackSuperAdmin() {
    const queryParams = { productType: 'ER' };
    this.router.navigate(['/salesjourney/super-admin', queryParams]);
  }

  backToTop() {
    document.getElementsByClassName('cds-navigation-content')[0].scrollTo(0, 0);
  }

  getAgentName(agent?: { agentName: string; agentNameChn: string }) {
    if (!agent) {
      return '';
    }
    if (this.cdsLangService.currentLang === 'en') {
      return agent.agentName || agent.agentNameChn;
    } else {
      return agent.agentNameChn || agent.agentName;
    }
  }

  get primaryAgentInfo() {
    const a = this.erCaseDetails?.agentsInfos?.filter(item => item.primaryAgent);
    if (a && a.length > 0) {
      return a[0];
    }
    return undefined;
  }

  get splitAgentInfos() {
    const a = this.erCaseDetails?.agentsInfos?.filter(item => !item.primaryAgent);
    if (a && a.length > 0) {
      return a;
    }
    return [];
  }

  get getEmployerName() {
    if (!this.erCaseDetails) {
      return '';
    }
    if (this.cdsLangService.currentLang === 'en') {
      return this.erCaseDetails?.companyNameEng || this.erCaseDetails?.companyNameChn;
    } else {
      return this.erCaseDetails?.companyNameChn || this.erCaseDetails?.companyNameEng;
    }
  }

  get getAuthPersonOne() {
    if (this.erCaseDetails && this.erCaseDetails.empfAuthPersonsInfos?.length > 0) {
      return this.erCaseDetails.empfAuthPersonsInfos[0];
    }
    return undefined;
  }

  get getAuthPersonTwo() {
    if (this.erCaseDetails && this.erCaseDetails.empfAuthPersonsInfos?.length > 1) {
      return this.erCaseDetails.empfAuthPersonsInfos[1];
    }
    return undefined;
  }

  get getEiffStatusRemarks() {
    if (this.empfRefNo !== this.erCaseDetails?.empfRefNo) {
      return 'No case matched with eMPF data';
    } else if (this.empfRefNo === this.erCaseDetails?.empfRefNo) {
      return 'Wrong agent mapped';
    }
    // 'Client Name mismatched'
    return '';
  }

  openTransferStatusStatus() {
    this.cdsPopup.open(AdviseTransferStatusComponent, {
      size: 'sm',
    });
  }

  get isShowSendeIFFBtn() {
    if (this.erCaseDetails && this.erCaseDetails.iffStatusEnum === 0 && ![50, 40, 99].includes(this.erCaseDetails.empfStatusEnum)) {
      return true;
    }
    return false;
  }

  get isShowResendeIFFBtn() {
    if (this.erCaseDetails && ![0, 40, 99].includes(this.erCaseDetails.iffStatusEnum) && ![50, 40, 99].includes(this.erCaseDetails.empfStatusEnum)) {
      return true;
    }
    return false;
  }

  getAuthPersonName(item?: { apName: string; apNameChi: string }) {
    if (!item) {
      return '';
    }
    if (this.cdsLangService.currentLang === 'en') {
      return item.apName || item.apNameChi;
    } else {
      return item.apNameChi || item.apName;
    }
  }

  openDifferentAgentStatus() {
    this.cdsPopup.open(DifferentAgentStatusComponent, {
      size: 'sm',
    });
  }

  onDisplayAdviseIFF() {
    this.cdsPopup.open(AdviseIffstatusComponent, {
      size: 'sm',
      data: {
        message: 'The changes you’ve made will be lost, are you sure to continue?',
        cancel: 'Cancel',
        continue: 'Continue',
      },
    });
  }

  iffStatus(item: EmpfDetail) {
    const result = { color: '', text: '' };

    switch (item.iffStatus) {
      case '0':
        result.color = 'var(--cds-color-gold)';
        result.text = 'Pending to send';
        break;

      case '10':
        result.color = 'var(--cds-color-turquoise)';
        result.text = 'Pending 2nd Agent Submission (for split case)';
        break;

      case '20':
        result.color = 'var(--cds-color-jh-blue)';
        result.text = 'Pending customer submission';
        break;

      case '30':
        result.color = 'var(--cds-color-dark-1-green)';
        result.text = 'Completed';
        break;

      case '99':
        result.color = 'var(--cds-color-light-4-dark-navy)';
        result.text = 'Declined';
        break;
    }

    return result;
  }

  openResendEIFF() {
    const ref = this.cdsPopup.open(ResendEiffComponent, {
      size: 'md',
      data: {
        salesJourneySummariesId: this.salesJourneySummariesId,
        resendType: ResendEiffResendType.ER,
        iffStatus: this.erCaseDetails?.iffStatusEnum,
        empfStatus: this.erCaseDetails?.empfStatusEnum,
      },
    });

    ref.afterClosed().subscribe((a?: { isRefresh: boolean }) => {
      if (a?.isRefresh) {
        location.reload();
      }
      cdsPopupBackdropUnOntouchmovePreventDefault();
    });

    ref.afterOpened().subscribe(() => {
      cdsPopupBackdropOntouchmovePreventDefault();
    });
  }

  openSendEIFF() {
    this.router.navigate(['/salesjourney/er-send-iff-in-batch-summary', { empfRefNo: this.erCaseDetails?.empfRefNo }]);
  }

  get tipText() {
    if (this.erCaseDetails?.iffStatusEnum === 10 || this.erCaseDetails?.iffDeclineExpireReasons?.some(item => item.code === 92)) {
      let a = 'Pending the following split agent submission : ';
      const tempList = this.splitAgentInfos.filter(item => [1, 20].includes(item.iffAgentStatusEnum));
      tempList.forEach((agent, index) => {
        a += `${agent.agentCd} ${this.getAgentName(agent)}`;
        if (index < tempList.length - 1) {
          a += ', ';
        }
      });

      return a;
    }
    return undefined;
  }

  isOfDeclined(code: number) {
    return [1, 2, 3, 4, 5, 6, 8].includes(code);
  }

  isOfExpired(code: number) {
    return [91, 92, 93].includes(code);
  }

  get isShowVoidIFFBtn() {
    if (this.erCaseDetails && [0, 10, 20, 97].includes(this.erCaseDetails.iffStatusEnum) && this.erCaseDetails.empfStatusEnum !== undefined) {
      return true;
    }
    return false;
  }

  onDisplayVoidEiffRef() {
    if (this.erCaseDetails?.voidIffVo?.voided || this.disableVoidEIFF) {
      return;
    }

    const ref = this.cdsPopup.open(VoidEiffPopupComponent, {
      size: 'sm',
    });

    ref.afterClosed().subscribe(data => {
      if (data.agree) {
        this.voidCase();
      }
    });
  }

  voidCase() {
    if (this.salesJourneySummariesId && this.empfRefNo) {
      const params = {
        salesJourneySummaryId: this.salesJourneySummariesId,
        empfRefNo: this.empfRefNo,
      };
      this.notifyService.screenLoadingSubject.next(true);
      this.cdHttpServeService.post<VoidInfo>('/ext/eb-ssms-sales-journey-service/voidCase', params).subscribe({
        next: res => {
          if (res.result !== 0) {
            this.alert.warning('Warning!', `${res.message}`);
          } else {
            if (res.data && this.erCaseDetails) {
              this.erCaseDetails.voidIffVo = res.data;

              if (this.erCaseDetails.voidIffVo && this.erCaseDetails.voidIffVo.voided) {
                this.erCaseDetails.iffStatusEnum = 97;
              }
            }
          }
          this.notifyService.screenLoadingSubject.next(false);
        },
        error: err => {
          this.alert.error('Error!', err);
          this.notifyService.screenLoadingSubject.next(false);
        },
      });
    }
  }

  checkSendS2() {
    if (this.salesJourneySummariesId) {
      const params = { summaryId: this.salesJourneySummariesId };
      this.notifyService.screenLoadingSubject.next(true);
      this.cdHttpServeService
        .get<{
          send: boolean;
        }>('/ext/eb-ssms-sales-journey-service/salesJourney/info', params)
        .subscribe({
          next: res => {
            if (res.result !== 0) {
              this.alert.warning('Warning!', `${res.message}`);
            } else {
              this.isSendS2 = res.data.send;
            }
            this.notifyService.screenLoadingSubject.next(false);
          },
          error: err => {
            this.alert.error('Error!', err);
            this.notifyService.screenLoadingSubject.next(false);
          },
        });
    }
  }

  goEditAgentDetails() {
    if (this.disableEditAgentByChannel) {
      return;
    }

    const queryParams = { type: 'ER' };
    this.router.navigate(['/salesjourney/edit-agent-details', this.salesJourneySummariesId, queryParams]);
  }

  getServicingAgentChannel() {
    if (this.primaryAgentInfo && this.primaryAgentInfo.agentCd) {
      this.cdHttpServeService
        .post<
          {
            agentCode: string;
            inputAgentType: string;
            channel: string;
            servicingAgentCode: string;
            commissionAgentCode: string;
          }[]
        >(`/ext/eb-ssms/agent-service/api/v1/agent-code-conversion`, [this.primaryAgentInfo.agentCd])
        .subscribe(res => {
          this.servicingAgentChannel = res.data[0].channel as AgentChannel;
          this.disableEditAgentByChannel = [AgentChannel.BROKER, AgentChannel.CORPORATE].includes(this.servicingAgentChannel);

          if (this.primaryAgentInfo && this.primaryAgentInfo.agentCd) {
            this.disableVoidEIFF =
              this.servicingAgentChannel === AgentChannel.DBS ||
              this.servicingAgentChannel === AgentChannel.SCB ||
              (this.servicingAgentChannel === AgentChannel.CORPORATE &&
                this.primaryAgentInfo.agentCd.startsWith('400') &&
                this.primaryAgentInfo.agentCd !== '400003');
          }
        });
    }
  }
}
