import { CdsOption } from '@cds/ng-core/configuration';

export const IffDeclinedReasonList: CdsOption[] = [
  {
    label: 'Case CANNOT match with eMPF data',
    value: '1',
  },
  {
    label: 'Client HKID/ Passport No. mismatched with eMPF data',
    value: '2',
  },
  {
    label: 'Employer Name mismatched with eMPF data',
    value: '3',
  },
  {
    label: 'BR No. mismatched with eMPF data',
    value: '4',
  },
  {
    label: 'Agent information mismatched with eMPF data',
    value: '6',
  },
];

export const ProductTypeList: CdsOption[] = [
  {
    label: 'ER',
    value: 'ER',
  },
  {
    label: 'PA',
    value: 'PA',
  },
  {
    label: 'SEP',
    value: 'SEP',
  },
  {
    label: 'TVC',
    value: 'TVC',
  },
  {
    label: 'FRC',
    value: 'FRC',
  },
];
