export const I18N_KEY = {
  REFERENCE_NO_NULL: 'commission.appeal.referenceNo.notFound',
  AGENT_CODE_NULL: 'commission.common.agentCode.notFound',
  ACCOUNT_CODE_NULL: 'commission.appeal.pendingTransaction.accountNo.notFound',
  ACCOUNT_CODE_FURTHER_INFO: 'commission.appeal.accountCode.futherInfo',
  FROM_RECEIVED_DATE_INVALID: 'commission.appeal.fromReceivedDate.invalid',
  TO_RECEIVED_DATE_INVALID: 'commission.appeal.toReceivedDate.invalid',
  INVALID_DATE_FROM_TO: 'commission.common.dateRangeInvalid',
  INVALID_DATE_RANGE: 'commission.appeal.pendingTransaction.invalidDateRange',
};
