/* eslint-disable @typescript-eslint/no-explicit-any */
import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';

import { CdsColorTheme, CdsOption } from '@cds/ng-core/configuration';
import { CdsProgressType } from '@cds/ng-core/progress';
import { CdsAlertService } from '@cds/ng-web-components/alert';

import { CdHttpServeService } from 'src/app/shared/cd-http-serve/cd-http-serve.service';
import { CdPopupService, CdPopupSize, MatDialogRef } from 'src/app/shared/cd-popup';
import { DownloadFileService } from 'src/app/shared/download-file/download-file.service';
import { ReportFilterComponent } from './report-filter/report-filter.component';
import { environment } from 'src/environments/environment';
interface FileInfo {
  reportDate: string;
  fileName: string;
  action: string;
  id: string;
}

@Component({
  selector: 'app-report-center',
  templateUrl: './report-center.component.html',
  styleUrls: ['./report-center.component.scss'],
})
export class ReportCenterComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('filesTable', { static: false }) filesTable?: any;
  formControl: FormControl = new FormControl();
  options: CdsOption[] = [];
  dataSource: FileInfo[] = [];
  displayedColumns: string[] = ['reportDate', 'fileName', 'action'];
  pageCount = 0;
  currentPage = 1;
  cdsProgressType: CdsProgressType = 'ring';
  cdsProgressTxt = 'Loading';
  cdsProgressColor: CdsColorTheme = 'ml';
  isShowProgress = false;
  resultTableIsFitContent = false;
  percentComplete = { value: 0 };
  isDownloading = false;
  searchValue?: string;
  resultTableFooterMaxWidth = 895;
  closeDownloadEvent: { event: (() => void) | null } = {
    event: null,
  };
  filters: string[] = [];
  isDev = environment.profile === 'dev'; // delete after filter fun complete

  constructor(
    private cdHttpServeService: CdHttpServeService,
    private downloadFileService: DownloadFileService,
    private alert: CdsAlertService,
    private cdPopup: CdPopupService
  ) {}

  ngOnInit(): void {
    this.getReportConfigs();
  }

  ngAfterViewInit(): void {
    this.resultTableIsFitContent = document.documentElement.clientWidth >= this.resultTableFooterMaxWidth;
    window.onresize = () => {
      this.resultTableIsFitContent = document.documentElement.clientWidth >= this.resultTableFooterMaxWidth;
    };
  }

  openFilter() {
    const popupRef: MatDialogRef<ReportFilterComponent> = this.cdPopup.open(ReportFilterComponent, {
      data: {
        filters: this.filters,
      },
      size: CdPopupSize.FULL,
    });
    popupRef.afterClosed().subscribe(filters => {
      this.filters = filters;
    });
  }

  downloadFile(file: FileInfo) {
    if (this.isDev) {
      this.cdHttpServeService.get<any>(`/ext/eb-ssms/reporting-service/api/v1/report-file/download/${file.id}`, {}).subscribe({
        next: res => {
          if (res.result !== 0) {
            this.alert.warning('Warning!', `${res.message}`);
          } else {
            this.downloadFileService.downLoadNew(atob(res.data), file.fileName);
            // this.downloadFileService.downLoad(atob(res.data), file.fileName);
          }
        },
        error: err => {
          this.alert.error('Error!', err);
        },
      });
      return;
    }
    this.downloadFileFs(file);
  }

  downloadFileFs(file: FileInfo) {
    this.isDownloading = true;
    this.percentComplete = { value: 0 };
    this.downloadFileService.downloadFile(file.action, file.fileName, this.percentComplete, this.closeDownloadEvent).then(() => {
      this.isDownloading = false;
    });
  }

  closeDownload() {
    if (this.closeDownloadEvent.event) {
      this.closeDownloadEvent.event();
    }
    this.isDownloading = false;
  }

  ngOnDestroy(): void {
    window.onresize = null;
  }

  get currentPageIndex() {
    return this.currentPage - 1;
  }

  search(option: CdsOption) {
    this.searchValue = option.value;
    this.currentPage = 1;
    this.getReportFiles();
  }

  pageChange(num: number) {
    if (this.currentPage === num) {
      return;
    }
    this.currentPage = num;
    this.getReportFiles();
  }

  searchByInputPageNo($event: any, pageNo: string) {
    $event.preventDefault();
    this.currentPage = +pageNo;
    if (this.currentPage < 1) {
      this.currentPage = 1;
    } else if (this.currentPage > this.pageCount) {
      this.currentPage = this.pageCount;
    }
    this.getReportFiles();
  }

  getReportConfigs() {
    this.cdHttpServeService
      .post<
        {
          id: string;
          reportId: string;
          reportName: string;
          allowedRoles: string[];
        }[]
      >('/ext/eb-ssms/reporting-service/api/v1/report-configs/query', {
        userRoles: ['INTERNAL_STAFF', 'MANAGER'],
      })
      .subscribe({
        next: res => {
          if (res.result !== 0) {
            console.warn('Waring!', `${res.message} ${res.data}`);
            this.alert.warning('Warning!', `${res.message} ${res.data}`);
          } else {
            this.options = res.data.map(item => {
              return { label: item.reportName, value: item.reportId };
            });
          }
        },
        error: error => {
          console.error('error---', error);
          this.alert.error('Error!', error);
        },
      });
  }

  getReportFiles() {
    this.isShowProgress = true;
    this.cdHttpServeService
      .post<{
        content: {
          downloadUrl: string;
          fileName: string;
          id: string;
          reportDate: string;
          reportId: string;
        }[];
        totalPages: number;
      }>('/ext/eb-ssms/reporting-service/api/v1/report-files/query', {
        page: this.currentPageIndex,
        size: 10,
        sort: 'reportDate desc',
        reportId: this.searchValue,
      })
      .subscribe({
        next: res => {
          if (res.result !== 0) {
            console.warn('Waring!', `${res.message} ${res.data}`);
            this.alert.warning('Warning!', `${res.message} ${res.data}`);
          } else {
            this.dataSource =
              res.data.content?.map(item => {
                const dList = item.reportDate.split('-');
                return {
                  reportDate: `${dList[2]}/${dList[1]}/${dList[0]}`,
                  fileName: item.fileName,
                  action: item.downloadUrl,
                  id: item.id,
                };
              }) || [];
            this.pageCount = res.data.totalPages;
          }
          this.isShowProgress = false;

          setTimeout(() => {
            this.resultTableFooterMaxWidth = this.filesTable._elementRef.nativeElement.clientWidth;
            this.resultTableIsFitContent = document.documentElement.clientWidth - 177 >= this.resultTableFooterMaxWidth;
            window.onresize = null;
            window.onresize = () => {
              this.resultTableIsFitContent = document.documentElement.clientWidth - 177 >= this.resultTableFooterMaxWidth;
            };
          }, 0);
        },
        error: error => {
          this.isShowProgress = false;
          console.error('error---', error);
          this.alert.error('Error!', error);
        },
      });
  }
}
