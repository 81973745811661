import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class EeProfileListService {
  private common_url = `${environment.apiPrefix}/ext/eb-ssms/customer-service/EE-Profile`;
  private getList_api = this.common_url + '/list';
  constructor(private http: HttpClient) {}

  getList(pageIndex: number, pageSize: number, firstNm: string, lastNm: string, status: string) {
    pageIndex = pageIndex - 1;
    return new Observable(observable => {
      this.http
        .get(this.getList_api + '?&size=' + pageSize + '&page=' + pageIndex + '&legalId=' + firstNm + '&engSurname=' + lastNm + '&sex=' + status)
        .subscribe(data => observable.next(data));
    });
  }
}
