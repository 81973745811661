/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { CdsButtonConfig } from '@cds/ng-core/button';
import { CdsIconConfig } from '@cds/ng-core/icon';
import { CdsLangService } from '@cds/ng-core/lang';
import { CdsPopupService, CDS_POPUP_DATA } from '@cds/ng-web-components/popup';
import { finalize } from 'rxjs';
import { ContinuePopupComponent } from 'src/app/shared/continue-popup/continue-popup.component';
import { ToastAlertService } from 'src/app/shared/toast-alert.service';
import { UploadAudienceListFileComponent } from 'src/app/views/mobile-subscription/upload-audience-list-file/upload-audience-list-file.component';
import { ScbStatementUploadSuccessComponent } from '../scb-statement-upload-success/scb-statement-upload-success.component';
import { PaymentServiceSHKP } from '../../../paymentSHKP.service';

@Component({
  selector: 'app-scb-statement-upload',
  templateUrl: './scb-statement-upload.component.html',
  styleUrls: ['./scb-statement-upload.component.scss'],
})
export class ScbStatementUploadComponent implements OnInit {
  show = true;

  cancelButtonConfig: CdsButtonConfig = { size: 'sm', style: 'secondary' };

  confirmButtonConfig: CdsButtonConfig = { size: 'sm' };

  uploadFormControl = new FormControl();

  file!: File;

  noFileSelect = true;

  fileAvailable = true;

  errorMsg = '';

  isLoading = false;

  errorMessageIconConfig: CdsIconConfig = {
    color: '#830A10',
    size: 'xs',
  };

  constructor(
    private dialogRef: MatDialogRef<UploadAudienceListFileComponent>,
    private cdsPopup: CdsPopupService,
    private langService: CdsLangService,
    private service: PaymentServiceSHKP,
    private toastAlert: ToastAlertService,
    @Inject(CDS_POPUP_DATA)
    public data: {
      audienceList: string;
    }
  ) {}

  ngOnInit(): void {
    this.uploadFormControl.valueChanges.subscribe(value => {
      if (value === null) {
        this.errorMsg = '';
        this.noFileSelect = true;
      } else {
        this.noFileSelect = false;
      }
    });
  }

  fileChange(event: any) {
    this.file = event?.target?.files[0];
    this.errorMsg = this.ifFileAvailable(this.file);
    if (this.errorMsg !== '') {
      return;
    }

    this.errorMsg = 'checking...';
    this.service
      .fileNameCheck(this.file.name)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe(res => {
        if (res.result === 0 && res.data) {
          this.errorMsg = 'There is a file with the same name. Please update the file name.';
        } else {
          this.errorMsg = '';
        }
      });
  }

  ifFileAvailable(file: any) {
    // return 'Please upload a file less than 10 MB';
    if (!file || !file.size || !file.type || !file.name) {
      return 'Invalid file selected.';
    }
    if (file.type !== 'text/csv') {
      return 'Please upload a csv file.';
    }
    // file size limit is 8MB, file name (with suffix)
    if (file.size >= 8388608 && file.type == 'text/csv') {
      return 'Please upload a file less than 10 MB';
    }

    return '';
  }

  confirm() {
    if (this.noFileSelect || this.errorMsg) {
      return;
    }

    this.upload();
  }

  upload() {
    this.isLoading = true;
    this.service
      .uploadScb(this.file)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe(res => {
        if (res.result === 0) {
          this.cdsPopup.open(ScbStatementUploadSuccessComponent, {
            size: 'md',
            data: {
              total: res.data?.total,
              updated: res.data?.updated,
            },
          });
          this.dialogRef.close({
            agree: true,
          });
        } else {
          this.toastError(res.message);
        }
      });
  }

  toastError(errorMessage: string) {
    this.toastAlert.show('error', 'common.error', errorMessage, 5000);
  }

  cancel() {
    if (this.noFileSelect) {
      this.dialogRef.close({
        agree: false,
      });
      return;
    }
    this.show = false;
    this.cdsPopup
      .open(ContinuePopupComponent, {
        size: 'md',
        data: {
          message: this.langService.translate('common.action.cancel'),
          // cancelButtonName: 'Back',
        },
      })
      .afterClosed()
      .subscribe(result => {
        this.show = true;
        if (result?.agree) {
          this.dialogRef.close({
            agree: false,
          });
        }
      });
  }
}
