/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { CdsButtonConfig } from '@cds/ng-core/button';
import { CdsOption } from '@cds/ng-core/configuration';
import { CdsDropdownConfig } from '@cds/ng-core/dropdown';
import { CdsLangService } from '@cds/ng-core/lang';
import { CdsTextfieldConfig } from '@cds/ng-core/textfield';
import { CdsTableComponent } from '@cds/ng-data-table/table';
import { CdsPopupService } from '@cds/ng-web-components/popup';
import { DateTime } from 'luxon';
import moment from 'moment';
import { finalize } from 'rxjs';
import { BTN_CONFIG_TERTIARY } from 'src/app/config/btn.config';
import { PermissionAccess, PermissionItem } from 'src/app/core/models/enum/permission.enum';
import { ResponseResult } from 'src/app/core/models/response/response-result';
import { PermissionService } from 'src/app/core/services/permission.service';
import { AlertPopupComponent } from 'src/app/shared/alert-popup/alert-popup.component';
import { ContinuePopupComponent } from 'src/app/shared/continue-popup/continue-popup.component';
import { ColumnConfig } from 'src/app/shared/data-table';
import { IPaginationCount } from 'src/app/shared/pagination/pagination.dto';
import { ToastAlertService } from 'src/app/shared/toast-alert.service';
import { deepCopy } from 'src/app/utils/copy';
import { toPageableRequest } from 'src/app/utils/pagination.utils';
import { MONTH_OPTS } from 'src/app/config/month.config';
import { QuoteTypeE, SpecialQuote, SpecialQuoteRequest, SpecialQuoteStatusOptions, SpecialQuoteTypeOptions } from '../employer';
import { EmployerService } from '../employer.service';
import { AddSpecialQuoteComponent } from './add-special-quote/add-special-quote.component';

@Component({
  selector: 'app-employer-profile-special-quote',
  templateUrl: './employer-profile-special-quote.component.html',
  styleUrls: ['./employer-profile-special-quote.component.scss'],
})
export class EmployerProfileSpecialQuoteComponent implements OnInit, OnChanges {
  @ViewChild('table') table!: CdsTableComponent<SpecialQuote>;

  @Input() id?: string;

  btnCfg: CdsButtonConfig = BTN_CONFIG_TERTIARY;

  isLoading = false;

  SpecialQuoteTypeOptions = SpecialQuoteTypeOptions;

  SpecialQuoteStatusOptions = SpecialQuoteStatusOptions;

  originDataSource: SpecialQuote[] = [];

  dataSource: SpecialQuote[] = [];

  txtfieldConfig: CdsTextfieldConfig = {
    placeholder: 'Input SQ Ref No.',
    type: 'text',
  };

  dropdownConfig: CdsDropdownConfig = {
    options: SpecialQuoteTypeOptions,
  };

  displayedColumnsWithoutPermission = ['referenceNo', 'type', 'submissionDate'];

  displayedColumns = [...this.displayedColumnsWithoutPermission, 'action'];

  columnsConfig: ColumnConfig[] = [
    { key: 'referenceNo', title: 'SQ Reference No.' },
    { key: 'type', title: 'Quote Type' },
    { key: 'submissionDate', title: 'SQ Submission Date' },
    { key: 'status', title: 'Status' },
    { key: 'updateDate', title: 'Last Status Update Date' },
  ];

  secondaryBtnCfg: CdsButtonConfig = {
    size: 'sm',
    style: 'secondary',
  };

  noLoadMonth = true;

  canEdit = false;

  PermissionAccess = PermissionAccess;

  PermissionItem = PermissionItem;

  pageCount: IPaginationCount = { current: 1, count: 1 };

  monthCfg: CdsDropdownConfig = {
    options: this.createMonthOpts(),
  };

  yearCfg: CdsDropdownConfig = {
    options: [],
  };

  formGroup = new FormGroup({
    month: new FormControl('All', []),
    year: new FormControl('All', []),
  });

  allowLoadTable = false;

  constructor(
    public employerService: EmployerService,
    private cdsPopup: CdsPopupService,
    private langService: CdsLangService,
    private permissionService: PermissionService,
    private toastAlert: ToastAlertService
  ) {}

  ngOnInit(): void {
    this.permissionService.hasPermission(PermissionAccess.W, PermissionItem.CUSTOMER_EMPLOYER_SQ).then(hasPermission => {
      this.canEdit = hasPermission;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['id']) {
      if (this.id) {
        this.getYears();
      }
    }
  }

  getYears() {
    if (this.id) {
      this.isLoading = true;
      this.employerService
        .getSpecialQuoteSubmissionYears(this.id)
        .pipe(
          finalize(() => {
            this.isLoading = false;
          })
        )
        .subscribe(res => {
          if (res.result === 0 && res.data) {
            const currentYear = new Date().getFullYear();
            const minimumYear = res.data.length ? Math.min.apply(null, res.data) : currentYear;
            const options: CdsOption[] = [
              {
                label: 'All',
                value: 'All',
              },
            ];

            for (let i = currentYear; i >= minimumYear; i--) {
              options.push({
                label: i.toString(),
                value: i,
              });
            }

            this.yearCfg = {
              options,
            };
            const yearFormControl = this.formGroup.get('year');
            const selectYear = yearFormControl?.value as number;
            this.allowLoadTable = false;
            if (selectYear < minimumYear) {
              yearFormControl?.setValue('All');
            }
            this.loadTable();
          } else {
            this.toastError(res.message);
          }
        });
    }
  }

  loadTable() {
    if (this.id) {
      this.isLoading = true;
      const params: SpecialQuoteRequest = toPageableRequest(this.pageCount, 5);
      const selectMonth = this.formGroup.get('month')?.value;
      const selectYear = this.formGroup.get('year')?.value;

      if (selectMonth !== 'All') {
        params.sqSubmissionDateMonth = selectMonth + 1;
      }
      if (selectYear !== 'All') {
        params.sqSubmissionDateYear = this.formGroup.get('year')?.value;
      }
      this.employerService
        .getSpecialQuoteList(this.id, params)
        .pipe(
          finalize(() => {
            this.isLoading = false;
            this.allowLoadTable = true;
          })
        )
        .subscribe(res => {
          if (res.result === 0 && res.data) {
            // if current page has no data, reload the last page which has data
            if (!res.data.content?.length && res.data.totalPages !== 0) {
              // this will trigger loadTable()
              this.pageCount = {
                current: res.data.totalPages,
                count: res.data.totalPages,
              };
            } else {
              this.originDataSource = deepCopy(this.dataTrans(res.data.content || []));
              this.dataSource = deepCopy(this.originDataSource);
              if (this.pageCount.current != res.data.number + 1) {
                this.pageCount.current = res.data.number + 1;
              }
              if (this.pageCount.count !== res.data.totalPages) {
                this.pageCount.count = res.data.totalPages;
              }
              this.pageCount = { ...this.pageCount };
            }
          } else {
            this.toastError(res.message);
          }
        });
    }
  }

  dataTrans(data: SpecialQuote[]) {
    return data.map(item => {
      item.sqSubmissionDate = item.sqSubmissionDate ? moment(item.sqSubmissionDate).format('DD/MM/YYYY') : '';
      item.lastStatusUpdatedDate = item.lastStatusUpdatedDate ? moment(item.lastStatusUpdatedDate).format('DD/MM/YYYY') : '';
      item.showAllRemarks = false;
      item.showAllButton = false;
      return item;
    });
  }

  edit(id?: string) {
    const alreadyInEdit = this.dataSource.some(item => item.id !== id && item.inEdit);
    if (alreadyInEdit) {
      this.cdsPopup
        .open(ContinuePopupComponent, {
          size: 'sm',
          data: { message: this.langService.translate('common.action.cancel') },
        })
        .afterClosed()
        .subscribe(result => {
          if (result?.agree) {
            this.changeEditStaus(id as string);
          }
        });
    } else {
      this.changeEditStaus(id as string);
    }
  }

  dateMonthChange() {
    const selectMonth = this.formGroup.get('month')?.value;
    const selectyear = this.formGroup.get('year')?.value;

    if (selectMonth === 'All' && selectyear == 'All') {
      this.noLoadMonth = true;
    } else {
      this.noLoadMonth = false;
    }

    if (this.allowLoadTable) {
      this.loadTable();
    }
  }

  dateYearChange() {
    const monthFormControl = this.formGroup.get('month');
    const selectYear = this.formGroup.get('year')?.value;
    //const selectmonth = this.formGroup.get('month')?.value;
    // eslint-disable-next-line no-debugger

    if (selectYear === 'All') {
      monthFormControl?.setValue('All');
      this.noLoadMonth = true;
    } else {
      this.noLoadMonth = false;
    }

    if (this.allowLoadTable) {
      this.loadTable();
    }
  }

  changeEditStaus(id: string) {
    this.dataSource.forEach(item => {
      if (item.id === id) {
        item.inEdit = true;
      } else {
        item.inEdit = false;
      }
    });

    this.dataSource = [...this.dataSource];
  }

  delete(data: SpecialQuote) {
    this.isLoading = true;
    this.employerService
      .deleteSpecialQuote(this.id as string, data.id as string)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe({
        next: res => {
          if (res.result === 0) {
            this.popupWithReload();
          } else {
            this.toastError(res.message);
          }
        },
      });
  }

  save = (id: string, quoteType: QuoteTypeE, sqReferenceNo: string, sqSubmissionDate: string) => {
    const ifSaveButtonDisabled = this.ifSaveButtonDisabled(id, quoteType, sqReferenceNo, sqSubmissionDate);
    if (ifSaveButtonDisabled) {
      return;
    }
    this.doSave(id);
  };

  doSave(id: string) {
    const lineData = this.getLineData(id, this.dataSource);
    const params: SpecialQuote = {
      id,
      customerId: this.id,
      sqReferenceNo: lineData?.sqReferenceNo,
      quoteType: lineData?.quoteType,
      sqSubmissionDate: this.paramDateTrans(lineData?.sqSubmissionDate),
    };
    this.isLoading = true;
    if (this.id !== undefined) {
      this.employerService
        .saveSpecialQuote(this.id, params)
        .pipe(
          finalize(() => {
            this.isLoading = false;
          })
        )
        .subscribe({
          next: res => {
            if (res.result === 0) {
              if (lineData) {
                lineData.inEdit = false;
              }
              this.popupWithReload();
            } else {
              this.toastError(res.message);
            }
          },
        });
    }
  }

  reset = (id: string, quoteType: QuoteTypeE, sqReferenceNo: string, sqSubmissionDate: string) => {
    const ifResetButtonDisabled = this.ifResetButtonDisabled(id, quoteType, sqReferenceNo, sqSubmissionDate);
    if (ifResetButtonDisabled) {
      return;
    }
    this.doReset(id);
  };

  doReset(id: string) {
    this.cdsPopup
      .open(ContinuePopupComponent, {
        size: 'sm',
        data: { message: this.langService.translate('common.action.cancel') },
      })
      .afterClosed()
      .subscribe(result => {
        if (result?.agree) {
          const lineData = this.getLineData(id, this.dataSource);
          const originLineData = this.getLineData(id, this.originDataSource);
          if (lineData && originLineData) {
            lineData.inEdit = true;
            lineData.quoteType = originLineData.quoteType;
            lineData.sqReferenceNo = originLineData.sqReferenceNo;
            lineData.sqSubmissionDate = originLineData.sqSubmissionDate;
            this.dataSource = [...this.dataSource];
          }
        }
      });
  }

  getLineData(id: string, dataSource: SpecialQuote[]) {
    if (!id || !dataSource) {
      return null;
    }
    const lineData = dataSource.find(lineData => lineData.id === id);
    return lineData;
  }

  private popupWithReload() {
    this.cdsPopup
      .open(AlertPopupComponent, {
        size: 'sm',
        data: {
          message: this.langService.translate('common.action.success'),
          buttonName: this.langService.translate('common.gotItWithExclamation'),
        },
      })
      .afterClosed()
      .subscribe(() => {
        this.getYears();
      });
  }

  addParamGen(value: SpecialQuote) {
    return {
      ...value,
      sqSubmissionDate: this.paramDateTrans(value.sqSubmissionDate),
      customerId: this.id,
      remarks: value.showRemarks ? value.remarks : '',
    };
  }

  paramDateTrans(dateStr?: string) {
    if (!dateStr) {
      return '';
    }
    return DateTime.fromFormat(dateStr, 'd/M/yyyy').toFormat('yyyy-MM-dd');
  }

  ifResetButtonDisabled = (id: string, quoteType: QuoteTypeE, sqReferenceNo: string, sqSubmissionDate: string) => {
    const originLineData = this.getLineData(id, this.originDataSource);
    if (
      originLineData &&
      quoteType === originLineData.quoteType &&
      sqReferenceNo === originLineData.sqReferenceNo &&
      sqSubmissionDate === originLineData.sqSubmissionDate
    ) {
      return true;
    }
    return false;
  };

  ifSaveButtonDisabled = (id: string, quoteType: QuoteTypeE, sqReferenceNo: string, sqSubmissionDate: string) => {
    const ifResetButtonDisabled = this.ifResetButtonDisabled(id, quoteType, sqReferenceNo, sqSubmissionDate);
    if (ifResetButtonDisabled) {
      return true;
    }
    if (quoteType !== undefined && sqReferenceNo && sqSubmissionDate) {
      return false;
    }
    return true;
  };

  add(data?: SpecialQuote) {
    const editMode = !!data;
    this.cdsPopup
      .open(AddSpecialQuoteComponent, {
        size: 'md',
        data: {
          id: this.id,
          specialQuote: {
            ...data,
            showRemarks: !!data?.remarks,
          },
          editMode: editMode,
        },
      })
      .afterClosed()
      .subscribe(result => {
        if (result?.agree && this.id !== undefined) {
          if (result.isDelete) {
            this.delete(data as SpecialQuote);
          } else {
            const params = this.addParamGen(result.params);
            const requestObservable = editMode ? this.employerService.saveSpecialQuote(this.id, params) : this.employerService.addSpecialQuote(this.id, params);
            requestObservable
              .pipe(
                finalize(() => {
                  this.isLoading = false;
                })
              )
              .subscribe({
                next: res => {
                  if (res.result === ResponseResult.SUCCESS) {
                    this.popupWithReload();
                  } else {
                    this.toastError(res.message);
                  }
                },
              });
          }
        } else {
          this.isLoading = false;
        }
      });
  }

  remarksToggle = (index: number) => {
    this.dataSource[index].showAllRemarks = !this.dataSource[index].showAllRemarks;
    this.dataSource = [...this.dataSource];
  };

  showButton = (fold: boolean, element: SpecialQuote) => {
    element.showAllButton = fold;
    this.dataSource = [...this.dataSource];
  };

  createMonthOpts(): CdsOption[] {
    return [{ label: 'All', value: 'All', month: '00', days: 0 }, ...MONTH_OPTS];
  }

  createYearOpts(): CdsOption[] {
    const currentYear = new Date().getFullYear();
    const result = [];
    for (let i = currentYear; i >= 1970; i--) {
      result.push({
        label: i.toString(),
        value: i,
      });
    }
    return result;
  }

  toastError(errorMessage: string) {
    this.toastAlert.show('error', 'common.error', errorMessage, 5000);
  }
}
