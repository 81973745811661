<app-cd-page-title title="{{ 'commission.pool.payout.title' | lang }}"> </app-cd-page-title>
<div class="l-pb-6">
  <div class="row">
    <div class="col-xs-12 col-md-12 l-d-f l-jc-sb l-pt-6 l-pb-6">
      {{ 'commission.pool.payout.tips' | lang }}
    </div>
    <div class="col-xs-12 col-md-12 col-sm-12 col-lg-12 l-pt-3 l-pb-3">
      <span class="cds-h2-light">{{ 'commission.pool.common.searchRecord' | lang }}</span>
      <div class="row l-plr-0 l-pt-4">
        <div class="col-xs-12 col-md-6 col-sm-6 col-lg-3 l-pb-5 l-pl-0 l-pr-6">
          <div class="cds-body2 cds-dropdown-label ng-star-inserted label-color">
            {{ 'commission.pool.payout.commissionPoolNumber' | lang }}
          </div>
          <input
            [(ngModel)]="searchForm.id"
            (input)="inputId($event)"
            (ngModelChange)="searchChange('input')"
            class="text-field"
            placeholder="Input commission pool number" />
        </div>
        <div class="col-xs-12 col-md-6 col-sm-6 col-lg-3 l-pb-5 l-pl-0 l-pr-6">
          <div class="cds-body2 cds-dropdown-label ng-star-inserted label-color l-d-f">
            {{ 'commission.pool.payout.accountCode' | lang }}
            <cds-icon (click)="alert('searchCode')" class="l-pl-1" size="sm" style="cursor: pointer; min-width: initial" icon="action:info_1"></cds-icon>
          </div>
          <input
            [(ngModel)]="searchForm.accountCode"
            (ngModelChange)="searchChange('input')"
            (input)="inputAccountCode($event)"
            class="text-field"
            placeholder="Input {{ 'commission.pool.payout.accountCode' | lang }}" />
        </div>
        <div class="col-xs-12 col-md-6 col-sm-6 col-lg-3 l-pb-5 l-pl-0 l-pr-6">
          <div class="cds-body2 cds-dropdown-label ng-star-inserted label-color l-d-f">
            {{ 'commission.pool.payout.accountName' | lang }}
            <cds-icon (click)="alert('searchName')" class="l-pl-1" size="sm" style="cursor: pointer; min-width: initial" icon="action:info_1"></cds-icon>
          </div>
          <input
            [(ngModel)]="searchForm.accountName"
            (input)="inputAccountName($event)"
            (ngModelChange)="searchChange('input')"
            class="text-field"
            placeholder="Input account name" />
        </div>
        <div class="col-xs-12 col-md-6 col-sm-6 col-lg-3 l-pb-5 l-pl-0 l-pr-6">
          <div class="l-pt-5 l-d-f l-ai-cen">
            <cds-checkbox [(ngModel)]="searchForm.currentEffective" (ngModelChange)="searchChange('input')"></cds-checkbox>
            <label class="l-ml-3 cds-body2 cds-dark-navy-text light-4 l-mb-0">{{ 'commission.pool.payout.showCurrentEffectivePoolOnly' | lang }}</label>
          </div>
          <!-- <cds-checkbox
            class="cds-body2 cds-dropdown-label ng-star-inserted label-color l-pt-5"
            [(ngModel)]="searchForm.currentEffective"
            (ngModelChange)="searchChange('input')"
            label="{{ 'commission.pool.payout.showCurrentEffectivePoolOnly' | lang }}"></cds-checkbox> -->
        </div>
        <div class="col-xs-12 col-md-6 col-sm-12 col-lg-3 l-pb-5 l-pl-0 l-pr-6">
          <div class="search-area-btn">
            <cds-button [disabled]="searchDisable" [config]="btnCfg" size="md" (click)="search()">{{ 'commission.pool.common.search' | lang }} </cds-button>
            <cds-button [config]="btnCfg" size="md" (click)="reset()">{{ 'commission.pool.common.reset' | lang }} </cds-button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="loading" class="row">
    <div class="l-pa-4">
      <cds-progress mode="indeterminate"></cds-progress>
    </div>
  </div>

  <div *ngIf="!loading" class="row">
    <div class="col-xs-12 col-md-12 l-d-f l-jc-sb l-pt-4 div-border-top">
      <div class="cds-h2-light">
        {{ 'commission.pool.common.recordList' | lang }}
        <div class="cds-detail1-demibold">
          <span
            >{{ 'commission.pool.common.total' | lang }}
            {{ totalCounts | mynumber }}
            {{ 'commission.pool.common.records' | lang }}</span
          >
        </div>
      </div>
    </div>
    <div class="col-xs-12 col-md-12 l-pt-4 l-pb-3" style="overflow: auto">
      <table class="cds-table full div-border-top" style="border-collapse: collapse">
        <caption></caption>
        <tr style="background: white">
          <th scope="col" class="padding-48 header">
            {{ 'commission.pool.common.poolNumber' | lang }}
          </th>
          <th scope="col" class="padding-16 header l-d-f">
            {{ 'commission.pool.payout.accountCode' | lang }}
            <cds-icon (click)="alert('thAccountCode')" class="l-pl-1" size="sm" style="cursor: pointer; min-width: initial" icon="action:info_1"></cds-icon>
          </th>
          <th scope="col" class="padding-16 header">
            {{ 'commission.pool.payout.th.type' | lang }}
          </th>
          <th scope="col" class="padding-16 header">
            {{ 'commission.pool.payout.th.accountName' | lang }}
          </th>
          <th scope="col" class="padding-16 header">
            {{ 'commission.pool.payout.th.activeLastDate' | lang }}
          </th>
        </tr>
        <ng-container *ngFor="let item of dataSource; let i = index">
          <tr class="div-border-top div-border-bottom">
            <td class="padding-48">
              <span class="cds-body1-demibold db-click" (click)="dbClick(item)">
                {{ item.id | slice : 0 : 8 }}
              </span>
            </td>
            <td class="padding-16">
              <div class="td-text" *ngFor="let customer of item.commissionPoolCustomers; let j = index">
                {{ customer.accountCode }}
              </div>
            </td>
            <td class="padding-16">
              <div class="td-text" *ngFor="let customer of item.commissionPoolCustomers; let j = index">
                {{ customer.accountTypeStr }}
              </div>
            </td>
            <td class="padding-16">
              <div class="td-text2" *ngFor="let customer of item.commissionPoolCustomers; let j = index">
                {{ customer.accountName }}
              </div>
            </td>
            <td class="padding-16">
              <div *ngFor="let customer of item.commissionPoolCustomers; let j = index">
                <div *ngIf="customer.lastDate">
                  {{ customer.lastDate | ssmsDate }}
                </div>
                <div *ngIf="!customer.lastDate">Active</div>
              </div>
            </td>
          </tr>
        </ng-container>
      </table>
      <app-no-result-found *ngIf="!dataSource || dataSource.length === 0"></app-no-result-found>
    </div>
  </div>
  <app-pagination [pageCount]="pageCount" (pageChange)="pageChange($event)" [useOf]="true"></app-pagination>

  <div class="row">
    <div class="col-xs-12 col-md-12 l-d-f l-jc-sb l-pt-4 div-border-top">
      <div class="cds-h2-light l-d-f">
        Create New Pool
        <cds-icon (click)="alert('createPool')" class="l-pl-1" size="sm" style="cursor: pointer; min-width: initial" icon="action:info_1"></cds-icon>
      </div>
    </div>
    <cds-progress *ngIf="createLoading" mode="indeterminate" style="padding-left: 50px; padding-top: 25px"></cds-progress>

    <div *ngIf="!createLoading" class="col-xs-12 col-md-12">
      <div class="row l-plr-0">
        <div class="l-pt-4 col-xs-12 col-md-6 col-sm-6 col-lg-3 l-plr-0">
          <div class="cds-body2 cds-dropdown-label ng-star-inserted label-color">
            Account Type
            <span class="color-red">*</span>
          </div>
          <mat-select
            #mySelect
            appSelectCloseDirective="mySelect"
            class="text-field pt-1"
            [class.my-placeholder]="!addForm.accountType"
            placeholder="Select account type"
            [(ngModel)]="addForm.accountType"
            (ngModelChange)="createChange()">
            <mat-option class="div-border-top div-border-bottom option" *ngFor="let option of accountTypes" [value]="option.value">
              {{ option.label | lang }}
            </mat-option>
          </mat-select>
        </div>
        <div class="l-pt-4 col-xs-12 col-md-6 col-sm-6 col-lg-3">
          <div class="cds-body2 cds-dropdown-label ng-star-inserted label-color l-d-f">
            <div>
              Employer/Member Account Code
              <span class="color-red">*</span>
            </div>
            <cds-icon (click)="alert('createAccountCode')" class="l-pl-1" size="sm" style="cursor: pointer; min-width: initial" icon="action:info_1"></cds-icon>
          </div>
          <input
            placeholder="Input Employer/Member Account Code"
            (input)="inputAccountCode2($event)"
            (change)="createChange()"
            [(ngModel)]="addForm.accountCode"
            class="text-field" />
          <cds-assistive-text *ngIf="addForm.createCodeError" class="l-pt-3">
            {{ addForm.createCodeError | lang }}
          </cds-assistive-text>
        </div>
      </div>
      <cds-assistive-text *ngIf="addForm.createError" class="l-pt-3">
        {{ addForm.createError | lang }}
      </cds-assistive-text>
      <div class="l-pt-4 col-xs-12 col-md-12 l-plr-0">
        <cds-button class="l-pt-6" [disabled]="addForm.disabled" [config]="btnCfg" size="md" (click)="create2()"> Create pool </cds-button>
        <cds-button class="l-pt-6 l-pl-4" *ngIf="addForm.accountType || addForm.accountCode" [config]="btnCfg" size="md" (click)="reset2()"> Reset </cds-button>
      </div>
    </div>
  </div>
</div>
