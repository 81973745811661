/* eslint-disable @typescript-eslint/no-explicit-any */

export interface ColumnItem {
  label: string;
  prop: string;
  width?: string;
  val?: string;
  sort?: Sort;
  sortString?: string;
  template?: any;
}

export interface RowInfo {
  expand: boolean;
  mouseIn: boolean;
  data: ColumnItem[];
  expandClass: string;
}

export enum Sort {
  DEFAULT = 'none',
  UP = 'asc',
  DOWN = 'desc',
}
