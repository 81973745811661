import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-date-range-box',
  templateUrl: './date-range-box.component.html',
  styleUrls: ['./date-range-box.component.scss'],
})
export class DateRangeBoxComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
