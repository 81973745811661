<cds-popup class="popup no-close-icon">
  <cds-popup-title>
    <div class="row l-plr-0">
      <div *ngIf="data.editMode" class="col-xs-12 col-sm-6">{{ 'campaign.edit-campaign' | lang }}</div>
      <div *ngIf="!data.editMode" class="col-xs-12 col-sm-6">{{ (inEdit ? 'campaign.add-new-campaign' : 'campaign.add-new-campaign-summary') | lang }}</div>

      <div *ngIf="data.editMode" class="col-xs-12 col-sm-6" (click)="delete()">
        <div class="button-area">
          <cds-icon icon="form:delete" color="#d03a39"></cds-icon>
          <div class="cds-h6-demibold l-pl-1 l-mb-0">Delete Record</div>
        </div>
      </div>

      <!-- <div *ngIf="data.editMode" (click)="delete()">
        <cds-icon icon="form:delete" color="#d03a39"></cds-icon>
        <div>Delete Record</div>
      </div> -->
    </div>

    <div class="close-icon" (click)="close()">
      <cds-icon icon="action:cross"></cds-icon>
    </div>
  </cds-popup-title>

  <cds-popup-content>
    <div class="relative">
      <app-loading *ngIf="isLoading" [overlayInPopup]="true"></app-loading>

      <div class="row l-plr-0" [formGroup]="form" *ngIf="inEdit">
        <div class="col-xs-12 row l-plr-0 l-pb-5">
          <div class="col-xs-12 col-sm-6">
            <cds-textfield
              formControlName="campaignId"
              [label]="'campaign.campaign-id' | lang"
              [placeholder]="'campaign.input-campaign-id' | lang"></cds-textfield>

            <div *ngIf="campaignIdControl?.invalid && (campaignIdControl?.dirty || campaignIdControl?.touched)" class="l-pt-2">
              <cds-assistive-text *ngIf="campaignIdControl?.errors?.['required']" class="l-pt-2" [showIcon]="false">{{
                'campaign.missing-campaign-id' | lang
              }}</cds-assistive-text>

              <cds-assistive-text *ngIf="campaignIdControl?.errors?.['maxlength']" class="l-pt-2" [showIcon]="false">{{
                'common.max-length-is' | lang : { p1: '50' }
              }}</cds-assistive-text>

              <cds-assistive-text
                *ngIf="campaignIdControl?.errors?.['descriptionInvalid']"
                class="l-pt-2"
                [showIcon]="false"
                >{{ campaignIdControl?.errors?.['descriptionInvalid'] }}</cds-assistive-text
              >
            </div>
          </div>
        </div>

        <div class="col-xs-12 col-sm-6 l-pb-5">
          <cds-textfield
            formControlName="campaignName"
            [label]="'campaign.campaign-name' | lang"
            [placeholder]="'campaign.input-campaign-name' | lang"></cds-textfield>

          <div *ngIf="campaignNameControl?.invalid && (campaignNameControl?.dirty || campaignNameControl?.touched)" class="l-pt-2">
            <cds-assistive-text *ngIf="campaignNameControl?.errors?.['required']" class="l-pt-2" [showIcon]="false">{{
              'campaign.missing-campaign-name' | lang
            }}</cds-assistive-text>

            <cds-assistive-text *ngIf="campaignNameControl?.errors?.['maxlength']" class="l-pt-2" [showIcon]="false">{{
              'common.max-length-is' | lang : { p1: '100' }
            }}</cds-assistive-text>

            <cds-assistive-text
              *ngIf="campaignNameControl?.errors?.['idInvalid']"
              class="l-pt-2"
              [showIcon]="false"
              >{{ campaignNameControl?.errors?.['idInvalid'] | lang }}</cds-assistive-text
            >
          </div>
        </div>

        <div class="col-xs-12 col-sm-6 l-pb-5">
          <cds-textfield
            formControlName="campaignOwner"
            [label]="'campaign.campaign-owner' | lang"
            [placeholder]="'campaign.input-campaign-owner' | lang"></cds-textfield>

          <div *ngIf="campaignOwnerControl?.invalid && (campaignOwnerControl?.dirty || campaignOwnerControl?.touched)" class="l-pt-2">
            <cds-assistive-text *ngIf="campaignOwnerControl?.errors?.['required']" class="l-pt-2" [showIcon]="false">{{
              'campaign.missing-campaign-owner' | lang
            }}</cds-assistive-text>

            <cds-assistive-text *ngIf="campaignOwnerControl?.errors?.['maxlength']" class="l-pt-2" [showIcon]="false">{{
              'common.max-length-is' | lang : { p1: '200' }
            }}</cds-assistive-text>

            <cds-assistive-text
              *ngIf="campaignOwnerControl?.errors?.['idInvalid']"
              class="l-pt-2"
              [showIcon]="false"
              >{{ campaignOwnerControl?.errors?.['idInvalid'] | lang }}</cds-assistive-text
            >
          </div>
        </div>

        <div *ngIf="showDatePicker" class="row col-xs-12 col-sm-12 l-pb-5 l-plr-0 date-picker-area relative">
          <div class="col-xs-6 date-picker">
            <cds-textfield
              formControlName="campaignPeriodFrom"
              placeholder="DD/MM/YYYY"
              [label]="'campaign.campaign-period-from' | lang"
              [max]="getMaxFromDate | fn : campaignPeriodToValue"
              [cdsDatepicker]="startPicker">
            </cds-textfield>
            <cds-datepicker #startPicker="cdsDatepicker"></cds-datepicker>

            <div *ngIf="campaignPeriodFromControl?.invalid && (campaignPeriodFromControl?.dirty || campaignPeriodFromControl?.touched)" class="l-pt-2">
              <cds-assistive-text *ngIf="campaignPeriodFromControl?.errors?.['required']" class="l-pt-2" [showIcon]="false">{{
                'campaign.missing-campaign-period-from' | lang
              }}</cds-assistive-text>

              <cds-assistive-text
                *ngIf="campaignPeriodFromControl?.errors?.['dateInvalid'] || campaignPeriodFromControl?.errors?.['startLaterThanEnd']"
                class="l-pt-2"
                [showIcon]="false"
                >{{ 'create.dateError' | lang }}</cds-assistive-text
              >
            </div>
          </div>
          <div class="connector">-</div>
          <div class="col-xs-6 date-picker">
            <cds-textfield
              formControlName="campaignPeriodTo"
              placeholder="DD/MM/YYYY"
              [label]="'common.date-to' | lang"
              [min]="getMinToDate | fn : campaignPeriodFromValue"
              [cdsDatepicker]="endPicker">
            </cds-textfield>
            <cds-datepicker #endPicker="cdsDatepicker"></cds-datepicker>

            <div *ngIf="campaignPeriodToControl?.invalid && (campaignPeriodToControl?.dirty || campaignPeriodToControl?.touched)" class="l-pt-2">
              <cds-assistive-text *ngIf="campaignPeriodToControl?.errors?.['required']" class="l-pt-2" [showIcon]="false">{{
                'campaign.missing-campaign-period-to' | lang
              }}</cds-assistive-text>
              <cds-assistive-text
                *ngIf="campaignPeriodToControl?.errors?.['dateInvalid'] || campaignPeriodToControl?.errors?.['startLaterThanEnd']"
                class="l-pt-2"
                [showIcon]="false"
                >{{ 'create.dateError' | lang }}</cds-assistive-text
              >
            </div>
          </div>
        </div>

        <div class="col-xs-12 l-pb-5">
          <cds-textarea formControlName="campaignDescription" [label]="'campaign.campaign-description' | lang" [maxlength]="200"></cds-textarea>
          <div *ngIf="campaignDescriptionControl?.invalid && campaignDescriptionControl?.dirty" class="l-pt-1">
            <cds-assistive-text *ngIf="campaignDescriptionControl?.errors?.['required']" class="l-pt-2" [showIcon]="false">{{
              'campaign.missing-campaign-description' | lang
            }}</cds-assistive-text>
            <cds-assistive-text *ngIf="campaignDescriptionControl?.errors?.['maxlength']" class="l-pt-2" [showIcon]="false">{{
              'common.max-length-is' | lang : { p1: '200' }
            }}</cds-assistive-text>
          </div>
        </div>

        <div class="col-xs-12 col-sm-6 l-pb-5">
          <cds-dropdown formControlName="campaignType" [config]="campaignTypeConfig"> </cds-dropdown>

          <div *ngIf="campaignTypeControl?.invalid && (campaignTypeControl?.dirty || campaignTypeControl?.touched)" class="l-pt-2">
            <cds-assistive-text *ngIf="campaignTypeControl?.errors?.['required']" class="l-pt-2" [showIcon]="false">{{
              'campaign.select-campaign-type' | lang
            }}</cds-assistive-text>
          </div>
        </div>

        <div class="col-xs-12 col-sm-6 l-mb-5">
          <app-cd-multidropdown
            formControlName="productTypes"
            [label]="'campaign.product-type' | lang"
            [placeholder]="'campaign.select-product-type' | lang"
            [style.width]="'100%'"
            [toppingList]="productTypeOptions"></app-cd-multidropdown>

          <div *ngIf="productTypesControl?.invalid && (productTypesControl?.dirty || productTypesControl?.touched)" class="l-pt-2">
            <cds-assistive-text *ngIf="productTypesControl?.errors?.['required']" class="l-pt-2" [showIcon]="false">{{
              'campaign.select-product-type' | lang
            }}</cds-assistive-text>
          </div>
        </div>

        <div class="col-xs-12 col-sm-6 l-mb-5">
          <cds-dropdown formControlName="multipleAgentDisplay" [disabled]="multipleAgentDisplayDisabled" [config]="multipleAgentDisplayConfig"> </cds-dropdown>

          <div *ngIf="multipleAgentDisplayControl?.invalid && (multipleAgentDisplayControl?.dirty || multipleAgentDisplayControl?.touched)" class="l-pt-2">
            <cds-assistive-text *ngIf="multipleAgentDisplayControl?.errors?.['required']" class="l-pt-2" [showIcon]="false">{{
              'campaign.select-display-the-campaign' | lang
            }}</cds-assistive-text>
          </div>
        </div>

        <div class="col-xs-12 col-sm-6 l-mb-5">
          <cds-dropdown
            formControlName="displayToServicingAgent"
            (cdsChange)="displayToServicingAgentChange($event)"
            [config]="displayCampaignToServicingAgentConfig">
          </cds-dropdown>

          <div
            *ngIf="displayToServicingAgentControl?.invalid && (displayToServicingAgentControl?.dirty || displayToServicingAgentControl?.touched)"
            class="l-pt-2">
            <cds-assistive-text *ngIf="displayToServicingAgentControl?.errors?.['required']" class="l-pt-2" [showIcon]="false">{{
              'campaign.select-display-the-campaign' | lang
            }}</cds-assistive-text>
          </div>
        </div>

        <div class="col-xs-12 col-sm-6 l-mb-5">
          <app-cd-multidropdown
            formControlName="agentCommunication"
            [label]="'campaign.agent-communication' | lang"
            [placeholder]="'campaign.select-agent-communication' | lang"
            [style.width]="'100%'"
            [toppingList]="agentCommunicationOptions"></app-cd-multidropdown>

          <div *ngIf="agentCommunicationControl?.invalid && (agentCommunicationControl?.dirty || agentCommunicationControl?.touched)" class="l-pt-2">
            <cds-assistive-text *ngIf="agentCommunicationControl?.errors?.['required']" class="l-pt-2" [showIcon]="false">{{
              'campaign.select-agent-communication' | lang
            }}</cds-assistive-text>
          </div>
        </div>

        <div class="col-xs-12 l-mb-5">
          <cds-textfield formControlName="workbasket" [label]="'campaign.workbasket' | lang" [placeholder]="'campaign.input-workbasket' | lang"></cds-textfield>

          <div *ngIf="workbasketControl?.invalid && (workbasketControl?.dirty || workbasketControl?.touched)" class="l-pt-2">
            <cds-assistive-text *ngIf="workbasketControl?.errors?.['maxlength']" class="l-pt-2" [showIcon]="false">{{
              'common.max-length-is' | lang : { p1: '500' }
            }}</cds-assistive-text>

            <cds-assistive-text
              *ngIf="workbasketControl?.errors?.['idInvalid']"
              class="l-pt-2"
              [showIcon]="false"
              >{{ workbasketControl?.errors?.['idInvalid'] | lang }}</cds-assistive-text
            >
          </div>
        </div>

        <div class="col-xs-12 l-mb-5">
          <cds-textfield formControlName="agentSam" [label]="'campaign.agentsam' | lang" [placeholder]="'campaign.input-agentsam' | lang"></cds-textfield>

          <div *ngIf="agentSamControl?.invalid && (agentSamControl?.dirty || agentSamControl?.touched)" class="l-pt-2">
            <cds-assistive-text *ngIf="agentSamControl?.errors?.['maxlength']" class="l-pt-2" [showIcon]="false">{{
              'common.max-length-is' | lang : { p1: '500' }
            }}</cds-assistive-text>

            <cds-assistive-text
              *ngIf="agentSamControl?.errors?.['idInvalid']"
              class="l-pt-2"
              [showIcon]="false"
              >{{ agentSamControl?.errors?.['idInvalid'] | lang }}</cds-assistive-text
            >
          </div>
        </div>
      </div>

      <div class="col-xs-12" *ngIf="!inEdit">
        <div class="row-layout">
          <div class="label">{{ 'campaign.campaign-id' | lang }}</div>
          <div class="value">
            {{ campaignIdControl?.value }}
          </div>
        </div>

        <div class="row-layout">
          <div class="label">{{ 'campaign.campaign-name' | lang }}</div>
          <div class="value">
            {{ campaignNameControl?.value }}
          </div>
        </div>

        <div class="row-layout">
          <div class="label">{{ 'campaign.campaign-owner' | lang }}</div>
          <div class="value">
            {{ campaignOwnerControl?.value }}
          </div>
        </div>

        <div class="row-layout">
          <div class="label">{{ 'campaign-period' | lang }}</div>
          <div class="value">{{ campaignPeriodFromControl?.value }} - {{ campaignPeriodToControl?.value }}</div>
        </div>

        <div class="row-layout">
          <div class="label">{{ 'campaign.campaign-description' | lang }}</div>
          <div class="value">
            {{ campaignDescriptionControl?.value }}
          </div>
        </div>

        <div class="row-layout">
          <div class="label">{{ 'campaign.campaign-type' | lang }}</div>
          <div class="value">
            {{ findOptionLabelByValue | fn : campaignTypeControl?.value : CampaignTypeOptions }}
          </div>
        </div>

        <div class="row-layout">
          <div class="label">{{ 'campaign.product-type' | lang }}</div>
          <div class="value">
            {{ multiSelectRender | fn : productTypesControl?.value }}
          </div>
        </div>

        <div class="row-layout">
          <div class="label">{{ 'campaign.multiple-agent-display' | lang }}</div>
          <div class="value">
            {{ findOptionLabelByValue | fn : multipleAgentDisplayControl?.value : yesOrNoFlagNumberOptions }}
          </div>
        </div>

        <div class="row-layout">
          <div class="label">{{ 'campaign.display-the-campaign' | lang }}</div>
          <div class="value">
            {{ findOptionLabelByValue | fn : displayToServicingAgentControl?.value : yesOrNoFlagNumberOptions }}
          </div>
        </div>

        <div class="row-layout">
          <div class="label">{{ 'campaign.agent-communication' | lang }}</div>
          <div class="value">
            {{ multiSelectRender | fn : agentCommunicationControl?.value }}
          </div>
        </div>

        <div class="row-layout">
          <div class="label">{{ 'campaign.workbasket' | lang }}</div>
          <div class="value">
            {{ workbasketControl?.value }}
          </div>
        </div>

        <div class="row-layout">
          <div class="label">{{ 'campaign.agentsam' | lang }}</div>
          <div class="value">
            {{ agentSamControl?.value }}
          </div>
        </div>
      </div>
    </div>
  </cds-popup-content>

  <cds-popup-actions>
    <div class="col-xs-12">
      <ng-container *ngIf="inEdit">
        <cds-button [config]="resetButtonConfig" [disabled]="resetDisabled" (click)="reset()">{{ 'common.reset' | lang }}</cds-button>

        <cds-button *ngIf="!data.editMode" [config]="saveButtonConfig" [disabled]="saveDisabled" (click)="continue()">{{
          'common.continue' | lang
        }}</cds-button>

        <cds-button *ngIf="data.editMode" [config]="saveButtonConfig" [disabled]="saveDisabled" (click)="save()">{{ 'common.confirm' | lang }}</cds-button>
      </ng-container>

      <ng-container *ngIf="!inEdit">
        <cds-button [config]="resetButtonConfig" (click)="backToEdit()">{{ 'common.backToEdit' | lang }}</cds-button>

        <cds-button [config]="saveButtonConfig" (click)="save()">{{ 'common.confirm' | lang }}</cds-button>
      </ng-container>
    </div>
  </cds-popup-actions>
</cds-popup>
