import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fn',
})
export class FnPipe implements PipeTransform {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  transform(fn: (...args: any[]) => any, ...args: any[]) {
    return fn(...args);
  }
}
