import { CanActivate } from '@angular/router';
import { Injectable } from '@angular/core';

import { CdsAlertService } from '@cds/ng-web-components/alert';

import { CdHttpServeService } from 'src/app/shared/cd-http-serve/cd-http-serve.service';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { IffService } from './service/iff.service';
import { AgentChannel } from 'src/app/core/models/enum/agent-channel.enum';
import { AgentChannelService } from 'src/app/core/services/agent-channel.service';

@Injectable()
export class SalesJourneyGuard implements CanActivate {
  private agentCodeConversion = `/ext/eb-ssms/agent-service/api/v1/agent-code-conversion`;

  constructor(
    private cdHttpServeService: CdHttpServeService,
    private authenticationService: AuthenticationService,
    private iffService: IffService,
    private alert: CdsAlertService,
    private agentChannelService: AgentChannelService
  ) {}

  canActivate(): boolean {
    this.authenticationService.currentUserValue().then(res => {
      const servicingAgentId = res.id + '';
      this.iffService.getAgentCode(servicingAgentId).subscribe({
        next: res => {
          if (res.result !== 0) {
            this.alert.warning('Warning!', `${res.message}`);
          } else {
            const servicingAgentCode = res.data ? res.data.agentCode : undefined;
            if (servicingAgentCode) {
              this.cdHttpServeService
                .post<
                  {
                    agentCode: string;
                    inputAgentType: string;
                    channel: string;
                    servicingAgentCode: string;
                    commissionAgentCode: string;
                  }[]
                >(this.agentCodeConversion, [servicingAgentCode])
                .subscribe(res => {
                  this.agentChannelService.channel = res.data[0].channel as AgentChannel;
                  this.agentChannelService.agentCode = res.data[0].agentCode;
                });
            }
          }
        },
        error: err => {
          this.alert.error('Error!', err);
        },
      });
    });

    return true;
  }
}
