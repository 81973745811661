import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ErBaseRequest, FormulaClass } from '../../employer';
import { EmployerService } from '../../employer.service';
import { finalize } from 'rxjs';

@Component({
  selector: 'app-formula-class',
  templateUrl: './formula-class.component.html',
  styleUrls: ['./formula-class.component.scss'],
})
export class FormulaClassComponent implements OnInit, OnChanges {
  constructor(private service: EmployerService) {}

  @Input() payrollGroupId?: string;
  @Input() employerId?: string;

  dataList: FormulaClass[] = [];
  _loading = false;

  _contTableColumns = ['service', 'val', 'flatAmount'];

  _rounSignalMap = new Map([
    [0, 'NA'],
    [1, 'NEAR DOLLAR'],
    [2, 'NEXT HI DOLLAR'],
    [3, 'NEXT LO DOLLAR'],
  ]);
  _noPayLeaveMap = new Map([
    [0, 'NA'],
    [1, 'COUNT'],
    [2, 'NOT COUNT'],
  ]);

  _erContTypeMap = new Map([
    ['S', 'SAME EE'],
    ['D', 'DIFFERENT EE'],
    ['N', 'NO ER CONT'],
  ]);

  _contCalcMap = new Map([
    [0, 'NA'],
    [1, 'AUTO'],
    [2, 'SPFD FIX'],
    [9, 'SPFD VAR'],
  ]);

  _contTableMap = new Map([
    [0, 'No Breakpoint'],
    [1, 'Salary'],
    [4, 'Job Category'],
    [10, 'Service'],
    [11, 'Participation'],
    [12, 'Age'],
    [13, 'Sex'],
  ]);

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (
      this.employerId &&
      changes['payrollGroupId'].currentValue !== undefined &&
      changes['payrollGroupId'].currentValue !== changes['payrollGroupId'].previousValue
    ) {
      this.getData();
    }
  }

  formatRoundSignal(key?: number) {
    if (key == undefined) {
      return '';
    }
    return this._rounSignalMap.get(key) || '';
  }

  formatNopayLeave(key?: number) {
    if (key == undefined) {
      return '';
    }
    return this._noPayLeaveMap.get(key) || '';
  }

  formatType(key?: number) {
    if (key == undefined) {
      return '';
    }
    return this._contCalcMap.get(key) || '';
  }

  formatTypeER(key?: string) {
    if (key == undefined) {
      return '';
    }
    return this._erContTypeMap.get(key) || '';
  }

  formatContTableMap(key?: number) {
    if (key == undefined) {
      return '';
    }
    return this._contTableMap.get(key) || '';
  }

  getData() {
    const _param: ErBaseRequest = {
      payrollGroupId: this.payrollGroupId,
      id: this.employerId,
    };
    this._loading = true;
    this.service
      .getFurmulaClassList(_param)
      .pipe(
        finalize(() => {
          this._loading = false;
        })
      )
      .subscribe(data => {
        if (data.result === 0) {
          this.dataList = data.data ? data.data : [];
        }
      });
  }
}
