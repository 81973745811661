import { Injectable } from '@angular/core';
import { AgentChannel } from '../models/enum/agent-channel.enum';

@Injectable({
  providedIn: 'root',
})
export class AgentChannelService {
  channel?: AgentChannel;
  agentCode?: string;

  constructor() {}
}
