import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PermissionAccess } from 'src/app/core/models/enum/permission.enum';
import { AdminLayoutComponent } from 'src/app/views/layout/admin-layout/admin-layout.component';
import { CampaignMasterListComponent } from './campaign-master-list/campaign-master-list.component';
const routes: Routes = [
  {
    path: 'campaign',
    component: AdminLayoutComponent,
    children: [
      { path: '', pathMatch: 'full', redirectTo: 'list' },
      {
        path: 'list',
        component: CampaignMasterListComponent,
        data: {
          title: 'campaign.campaign-master-list',
          roles: {
            CUSTOMER_EMPLOYER: PermissionAccess.R,
            CUSTOMER_EMPLOYER_INTERMEDIARY: PermissionAccess.R,
            CUSTOMER_EMPLOYEE: PermissionAccess.R,
            CUSTOMER_EMPLOYEE_INTERMEDIARY: PermissionAccess.R,
          },
        },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CampaignRoutingModule {}
