<div *ngIf="label" class="cds-body2 cds-dropdown-label l-mb-3">{{ label | lang }}<span class="cds-coral-text dark-1" *ngIf="required">*</span></div>
<div class="cd-autocomplete">
  <mat-form-field class="example-full-width" appearance="fill">
    <input
      (blur)="onBlur()"
      (focus)="onFocus()"
      type="text"
      matInput
      placeholder="{{ (placeholder ? placeholder : '') | lang }}"
      class="text-field"
      [formControl]="autoCompleteControl"
      [matAutocomplete]="auto"
      #trigger="matAutocompleteTrigger"
      appCdAutocompletePosition="trigger" />
    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" (closed)="onClosed()" (optionSelected)="optionSelectedFn($event)">
      <mat-option *ngFor="let option of optionsDisplayed" [value]="option">
        {{ option.label | lang }}
      </mat-option>
    </mat-autocomplete>
    <div class="cd-autcomplete-arrow-wrapper" [class.not-empty]="optionSelected">
      <div class="cd-autcomplete-select-arrow"></div>
      <cds-icon [config]="{ color: '#8E90A2' }" icon="action:button_x_filled" (click)="clearInput()"></cds-icon>
    </div>
  </mat-form-field>
</div>
