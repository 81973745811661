import { OnInit, OnDestroy, AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

import { Observable, Subject } from 'rxjs';
import { take, debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-cd-input',
  templateUrl: './cd-input.component.html',
  styleUrls: ['./cd-input.component.scss'],
})
export class CdInputComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() group!: FormGroup;
  @Input() controlName = '';
  @Input() value = '';
  @Output() valueChange = new EventEmitter<string>();
  @Input() formControl?: FormControl;
  @ViewChild('theInput', { static: false })
  theInput!: ElementRef<HTMLInputElement>;
  @Input() label = '';
  @Input() placeholder = '';
  @Input() disable = false;
  @Input() type: 'text' | 'number' = 'text';
  @Input() regExpLimit?: string;
  @Input() maxlength: number | string | null = null;
  @Input() validator: ((val: string) => string | null) | null = null;
  @Input() asyncValidator: ((val: string) => Observable<string | null> | null) | null = null;
  @Input() isHasErrors = false;
  @Output() blurEvent = new EventEmitter<void>();
  isFocus = false;
  changeSubject = new Subject<string>();
  private _asyncValidatorLoading = false;
  get asyncValidatorLoading() {
    return this._asyncValidatorLoading;
  }

  errorInfo = '';
  asyncErrorInfo = '';

  constructor() {}

  ngOnInit() {
    this.changeSubject.pipe(debounceTime(300), distinctUntilChanged()).subscribe(val => {
      this.changeSubjecthandle(val);
    });
  }

  changeSubjecthandle(val: string) {
    if (this.asyncValidator) {
      this._asyncValidatorLoading = true;
      const ob = this.asyncValidator(val);
      if (ob) {
        ob.pipe(take(1)).subscribe(res => {
          if (res) {
            this.asyncErrorInfo = res;
          } else {
            this.asyncErrorInfo = '';
          }
          this._asyncValidatorLoading = false;
        });
      } else {
        this._asyncValidatorLoading = false;
        this.asyncErrorInfo = '';
      }
    }
  }

  onBlur() {
    this.blurEvent.emit();
  }

  ngOnDestroy() {
    this.changeSubject.unsubscribe();
  }

  ngAfterViewInit() {
    if (this.theInput && this.theInput.nativeElement) {
      this.theInput.nativeElement.onfocus = () => {
        this.isFocus = true;
      };
      this.theInput.nativeElement.onblur = () => {
        this.isFocus = false;
      };
    }

    if (this.formControl) {
      if (this.formControl.value != null) {
        this.value = this.formControl.value;
      }
      this.formControl.valueChanges.subscribe(val => {
        this.value = val;
      });
    }
  }

  change(val: string) {
    if (this.regExpLimit && !new RegExp(this.regExpLimit).test(val)) {
      this.theInput.nativeElement.value = this.value;
      if (this.group) {
        this.group.controls[this.controlName].setValue(this.value);
      }
      return;
    }

    if (this.type === 'number' && isNaN(+val)) {
      this.theInput.nativeElement.value = this.value;
      if (this.group) {
        this.group.controls[this.controlName].setValue(this.value);
      }
      return;
    }

    this.value = val;
    this.valueChange.emit(this.value);
    this.changeSubject.next(this.value);
    this.formControl?.setValue(this.value);
    this.formControl?.markAsDirty();
    this.formControl?.markAsTouched();

    if (this.validator) {
      const val = this.validator(this.value);
      if (val) {
        this.errorInfo = val;
      } else {
        this.errorInfo = '';
      }
    }
  }

  clearErrorInfo() {
    this.errorInfo = '';
    this.asyncErrorInfo = '';
  }
}
