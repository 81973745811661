import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-sent-eiff-popup',
  templateUrl: './sent-eiff-popup.component.html',
  styleUrls: ['./sent-eiff-popup.component.scss'],
})
export class SentEiffPopupComponent implements OnInit {
  constructor(private dialogRef: MatDialogRef<SentEiffPopupComponent>) {}

  ngOnInit(): void {}

  onGotIt() {
    this.dialogRef.close();
  }
}
