export enum CommissionScenario {
  MPF_ERPAC_IND = 'MPF_ERPAC_IND',
  MPF_ERPAC_MC = 'MPF_ERPAC_MC',
  MPF_SEP_IND = 'MPF_SEP_IND',
  MPF_SEP_PAC_MC = 'MPF_SEP_PAC_MC',
  MPF_TVC_IND = 'MPF_TVC_IND',
  MPF_PAP_IND = 'MPF_PAP_IND',
  MPF_PAP_MC = 'MPF_PAP_MC',
  MPF_ASEP_IND = 'MPF_ASEP_IND',
  MPF_ASEP_PAC_MC = 'MPF_ASEP_PAC_MC',
  MPF_ERPAC_MIN_BENEFIT = 'MPF_ERPAC_MIN_BENEFIT',
  MPF_PAP_MIN_BENEFIT = 'MPF_PAP_MIN_BENEFIT',
}
