import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatTabsModule } from '@angular/material/tabs';
import { CdsIconModule } from '@cds/ng-web-components/icon';
import { CdsPaginationModule } from '@cds/ng-web-components/pagination';
import { CdsTextfieldModule } from '@cds/ng-web-components/textfield';
import { AppPaginationComponent } from './pagination.component';

@NgModule({
  declarations: [AppPaginationComponent],
  imports: [CommonModule, CdsIconModule, MatTabsModule, CdsPaginationModule, CdsTextfieldModule],
  exports: [AppPaginationComponent],
})
export class AppPaginationModule {}
