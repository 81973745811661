import { Component, Inject, OnInit } from '@angular/core';
import { CDS_POPUP_DATA } from '@cds/ng-web-components/popup';
import { InterestRate, InterestRateHistory, RateType } from '../../employer';
import { finalize } from 'rxjs';
import { InterestRateService } from '../../interest-rate.service';

@Component({
  selector: 'app-interest-rate-history-popup',
  templateUrl: './interest-rate-history-popup.component.html',
  styleUrls: ['./interest-rate-history-popup.component.scss'],
})
export class InterestRateHistoryPopupComponent implements OnInit {
  constructor(
    @Inject(CDS_POPUP_DATA)
    public data: InterestRate,
    private service: InterestRateService
  ) {}

  _loading = false;
  dataList: InterestRateHistory[] = [];

  ngOnInit(): void {
    this.getList();
  }

  getList() {
    this._loading = true;
    const param: InterestRateHistory = {
      fundId: this.data.fundId,
      type: RateType.WITHDRAWAL_RATE,
      month: this.data.month,
      year: this.data.year,
    };
    this.service
      .getHistoryList(param)
      .pipe(
        finalize(() => {
          this._loading = false;
        })
      )
      .subscribe(data => {
        if (data.result === 0) {
          this.dataList = data.data ? data.data : [];
        }
      });
  }
}
