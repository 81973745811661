/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { CdsButtonConfig } from '@cds/ng-core/button';

import { BTN_CONFIG_SECONDARY } from 'src/app/config/btn.config';
import { SchemeMasterListItem, SchemeMasterService } from 'src/app/core/services/commission/scheme-master.service';
import { ColumnConfig, PageConfig } from 'src/app/shared/data-table';
import { PermissionAccess, PermissionItem } from 'src/app/core/models/enum/permission.enum';

import { SCHEME_TABLE_COLUMNS } from './scheme-master.config';
@Component({
  selector: 'app-scheme-master',
  templateUrl: './scheme-master.component.html',
  styleUrls: ['./scheme-master.component.scss'],
})
export class SchemeMasterComponent implements OnInit {
  permissionAccess = PermissionAccess.W;
  permissionItem = PermissionItem.COMMISSION_SCHEME_MASTER_RECORD;

  loading = false;
  overlay = false;
  pageConfig: PageConfig = {
    current: 1,
    pageSize: 10,
    totalCount: 0,
  };
  dataDisplayed: SchemeMasterListItem[] = [];
  columnsConfig: Array<ColumnConfig> = SCHEME_TABLE_COLUMNS;

  sortColumn: ColumnConfig | null = null;
  btnCfg: CdsButtonConfig = BTN_CONFIG_SECONDARY;

  constructor(private router: Router, private schemeService: SchemeMasterService) {}

  ngOnInit(): void {
    this.sortColumn = this.columnsConfig[0];
    this.getData();
  }

  getData() {
    this.loading = true;
    const param = this.createParam();
    this.schemeService.selectList(param).subscribe(resp => {
      this.loading = false;
      this.overlay = true;
      let dataList: SchemeMasterListItem[] = [];
      if (resp && resp.data && resp.result == 0) {
        const data = resp.data;
        this.pageConfig = {
          ...this.pageConfig,
          totalCount: data.totalElements,
        };
        if (data.content) {
          dataList = data.content.map((item: SchemeMasterListItem) => {
            if (item.currentInflation) {
              item.currentInflation.salaryInflationFactor = Number(item.currentInflation.salaryInflationFactor).toFixed(3);
            }
            return item;
          });
        }
      }
      this.dataDisplayed = dataList;
    });
  }

  createParam() {
    const param: any = {};
    param.page = (this.pageConfig.current as number) - 1;
    param.size = this.pageConfig.pageSize;
    if (this.sortColumn) {
      param.sort = `${this.sortColumn.key},${this.sortColumn.sort}`;
    }
    return param;
  }

  schemeClick(item: any) {
    this.router.navigate(['/commission/scheme-edit', { id: item.id }]);
  }

  reloadTable(event: any) {
    this.sortColumn = null;
    if (event.column) {
      this.sortColumn = event.column;
    }
    this.pageConfig = {
      ...this.pageConfig,
      current: event.current,
    };
    this.getData();
  }

  create() {
    this.router.navigate(['/commission/scheme-create']);
  }
}
