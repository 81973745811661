/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { CdsButtonConfig } from '@cds/ng-core/button';
import { CdsSearchConfig } from '@cds/ng-core/search';
import { finalize } from 'rxjs';
import { ColumnConfig, PageConfig } from 'src/app/shared/data-table';
import { EeProfileListModel } from 'src/app/views/employee/ee-profile-list/ee-profile-list-model';
import { BillingClass, ContributionDetails, MemberLevel, RelevantIncome, Surcharge } from '../../employer';
import { EmployerService } from '../../employer.service';
import { PermissionService } from 'src/app/core/services/permission.service';
import { PermissionAccess, PermissionItem } from 'src/app/core/models/enum/permission.enum';

@Component({
  selector: 'app-member-level',
  templateUrl: './member-level.component.html',
  styleUrls: ['./member-level.component.scss'],
})
export class MemberLevelComponent implements OnInit, OnChanges {
  @Input() contributionDetails?: ContributionDetails;
  @Input() billingClass?: BillingClass;
  @Input() payrollGroupId?: string;
  @Input() employerId?: string;

  isStaff = false;

  constructor(private service: EmployerService, public router: Router, private permissionService: PermissionService) {}

  config: CdsSearchConfig = {
    options: [],
    placeholder: 'Search by member name or member account code',
  };

  searchButtonConfig: CdsButtonConfig = {
    size: 'sm',
    disabled: true,
  };

  RelevantIncomeList: RelevantIncome[] = [
    {
      id: 'RI - Relevant Income',
      frequencyTypeId: 'CM - Monthly (Calendar Month)',
      income: '30,000',
      contributionType: 'RGCTB - Contribution',
      effectiveDate: '01/09/2022 - 30/09/2022',
    },
  ];

  SurchargeList: Surcharge[] = [
    {
      surchargeEr: '500',
      surchargeEe: '500',
      paidDate: '04/10/2022',
      dudate: '06/10/2022',
    },
  ];

  dataSource: MemberLevel[] = [];

  memberList: any[] = [1, 2, 3];
  _number = 1;
  _inputNumber = 1;
  _totalPages = 0;
  _progress = false;

  searchDTO: any = {
    searchKey: '',
  };

  pageConfig: PageConfig = {
    current: 1,
    pageSize: 10,
    totalCount: 0,
  };

  columnsConfig: ColumnConfig[] = [
    {
      key: 'memberAccountCode',
      title: 'Member Account Code',
    },
    {
      key: 'memberName',
      title: 'Member Name',
    },
    {
      key: 'totalPaidContribution',
      title: 'Total Paid Contribution',
    },
  ];

  ngOnInit(): void {
    // this.getMemberPageList();
    this.permissionFilter();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes['contributionDetails'].currentValue !== undefined &&
      changes['contributionDetails'].currentValue.contributionPeriodStartDate !== changes['contributionDetails'].previousValue?.contributionPeriodStartDate
    ) {
      this.searchDTO = {
        searchKey: '',
      };
      this.search();
    }
  }

  permissionFilter() {
    this.permissionService.hasPermission(PermissionAccess.R, PermissionItem.CUSTOMER_EMPLOYER).then(res => {
      this.isStaff = res;
    });
  }

  memberSearchChange() {
    this.searchButtonConfig = {
      size: 'sm',
      disabled: !this.searchDTO.searchKey,
    };
    if (!this.searchDTO.searchKey) {
      this.search();
    }
  }

  search() {
    this.pageConfig = {
      current: 1,
      pageSize: 10,
      totalCount: 0,
    };
    this.getMemberPageList();
  }
  getMemberPageList() {
    this.searchDTO = {
      ...this.searchDTO,
      page: (this.pageConfig.current as number) - 1,
      size: this.pageConfig.pageSize,
      startDate: this.contributionDetails?.contributionPeriodStartDate,
      endDate: this.contributionDetails?.contributionPeriodEndDate,
      payrollGroupId: this.payrollGroupId,
      id: this.employerId,
    };
    this._progress = true;
    this.service
      .getAccountInfoMemberLevelList(this.searchDTO)
      .pipe(
        finalize(() => {
          this._progress = false;
        })
      )
      .subscribe(data => {
        if (data.result === 0 && data.data) {
          this.dataSource = data.data.empty ? [] : data.data.content;
          this._totalPages = data.data.totalPages;

          this.pageConfig = {
            ...this.pageConfig,
            totalCount: data.data.totalElements,
          };
        }
      });
  }

  reloadTable(event: any) {
    this.pageConfig = {
      ...this.pageConfig,
      current: event.current,
      // totalCount: event.
    };
    this.getMemberPageList();
  }

  goToDetail(item: EeProfileListModel) {
    if (!this.isStaff) return;
    this.router.navigate(['/employee/profile', { legalId: item.legalId, legalIdType: item.legalIdType, id: item.id }]);
  }
}
