import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { finalize } from 'rxjs';
import { ToastAlertService } from 'src/app/shared/toast-alert.service';
import { AgentInfo, AgentInfoDetail, agentStatusMapObj } from '../../employer/employer';

import { EmployeeService } from '../employee.service';

@Component({
  selector: 'app-agent-info',
  templateUrl: './agent-info.component.html',
  styleUrls: ['./agent-info.component.scss'],
})
export class AgentInfoComponent implements OnInit, OnChanges {
  servicingAgentData: AgentInfoDetail[] = [];

  commissionAgentData: AgentInfoDetail[] = [];

  delegatedAgentData: AgentInfoDetail[] = [];

  @Input() id = '';

  isLoading = false;

  agentStatusMapObj = agentStatusMapObj;

  data: AgentInfo = {
    servicingAgent: [],
    commissionAgent: [],
    delegatedAgent: [],
  };

  constructor(public service: EmployeeService, private toastAlert: ToastAlertService, private route: ActivatedRoute) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['id'].currentValue !== undefined && changes['id'].currentValue !== changes['id'].previousValue) {
      this.getDetail();
    }
  }

  ngOnInit(): void {
    this.getDetail();
  }

  getDetail() {
    if (!this.id) return;
    this.isLoading = true;
    this.service
      .gePolicyAgentInfo(this.id)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe(res => {
        if (res.result === 0 && res.data) {
          this.data = res.data;
          this.servicingAgentData = this.data.servicingAgent || [];
          this.commissionAgentData = this.data.commissionAgent || [];
          this.delegatedAgentData = this.data.delegatedAgent || [];
        } else {
          this.toastError(res.message);
        }
      });
  }

  titleGen(data: AgentInfoDetail) {
    return `${data.agentCode ? data.agentCode : ''}     ${data.agentName ? data.agentName : ''} ${data.agentNameChi ? data.agentNameChi : ''}`;
  }

  toastError(errorMessage: string) {
    this.toastAlert.show('error', 'common.error', errorMessage, 5000);
  }
}
