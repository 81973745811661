import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { requiredValidator, dateValidator, typhoonDateValiator } from 'src/app/core/validators';

import { CalendarService } from 'src/app/views/system/calendar/calendar.service';
import { I18N_KEY } from '../../calendar.config';

@Component({
  selector: 'app-add-typhoon-event',
  templateUrl: './add-typhoon-event.component.html',
  styleUrls: ['./add-typhoon-event.component.scss'],
})
export class AddTyphoonEventComponent implements OnInit {
  @Output() valueChange = new EventEmitter();

  formGroup = new FormGroup({
    eventDate: new FormControl('', {
      validators: [
        requiredValidator({ error: I18N_KEY.DATE_REQUIRED }),
        dateValidator({ error: I18N_KEY.INVALID_DATE_FORMAT }),
        typhoonDateValiator({ error: I18N_KEY.INVALID_TYPHOON_DATE }),
      ],
    }),
  });

  constructor(private calendarService: CalendarService) {}

  ngOnInit() {
    const control = this.eventDate;
    if (control) {
      control.valueChanges.subscribe(value => {
        this.valueChange.emit({
          value: this.calendarService.formatTime(value),
          invalid: control.invalid,
        });
      });
    }
  }

  get eventDate() {
    return this.formGroup.get('eventDate');
  }
}
