import { EmployerService } from './../../employer.service';
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Inject, OnInit } from '@angular/core';
import { CDS_POPUP_DATA } from '@cds/ng-web-components/popup';
import { ColumnConfig, PageConfig } from 'src/app/shared/data-table';
import {
  AccountInfoAmountRequest,
  BillingClass,
  ContributionAmount,
  ContributionAmountType,
  ContributionAmountTypeE,
  ContributionDetails,
  ContributionDetailsCategory,
  ContributionDetailsCategoryE,
  ContributionSortByE,
  contributionStatusOptions,
  MemberAmount,
  rectifiedTypeOptions,
} from '../../employer';
import { finalize } from 'rxjs';
import { ToastAlertService } from 'src/app/shared/toast-alert.service';

@Component({
  selector: 'app-outstanding-amount-of-payment-popup',
  templateUrl: './outstanding-amount-of-payment-popup.component.html',
  styleUrls: ['./outstanding-amount-of-payment-popup.component.scss'],
})
export class OutstandingAmountOfPaymentPopupComponent implements OnInit {
  dataSource: MemberAmount[] = [];

  isLoading = false;

  pageConfig: PageConfig = {
    current: 1,
    pageSize: 5,
    totalCount: 1,
  };

  columnsConfig: ColumnConfig[] = [
    {
      key: 'memberCode',
      title: 'Member Account Code',
      // sticky: true,
    },
    {
      key: 'memberName',
      title: 'Member Name',
      sticky: true,
    },
    {
      key: 'amount',
      title: '',
    },
    {
      key: 'contributionStatus',
      title: 'Status of Default Contribution',
    },
    {
      key: 'rectifiedType',
      title: 'Rectified Type',
    },
  ];

  displayedColumns = [
    'trusteeMember',
    'amountOfPayment',
    // 'statusOfDefaultContribution',
    // 'rectifiedType'
  ];

  ContributionAmountTypeE = ContributionAmountTypeE;

  ContributionDetailsCategoryE = ContributionDetailsCategoryE;

  total = 0;

  contributionAmount: ContributionAmount = {};

  memberAmountList: MemberAmount[] = [];

  contributionStatusOptions = contributionStatusOptions;

  rectifiedTypeOptions = rectifiedTypeOptions;

  constructor(
    @Inject(CDS_POPUP_DATA)
    public data: {
      byBillReference: boolean;
      category: ContributionDetailsCategory;
      type: ContributionAmountType;
      amount: number;
      contributionDetails: ContributionDetails;
      billingClass: BillingClass;
      employerId: string;
      selectedContributionSortBy: ContributionSortByE;
    },
    public service: EmployerService,
    private toastAlert: ToastAlertService
  ) {}

  ngOnInit(): void {
    this.total = this.data.amount || 0;
    this.loadTable();
  }

  loadTable() {
    const params: AccountInfoAmountRequest = this.getRequestParams();
    this.isLoading = true;
    this.service
      .getAccountInfoAmountList(params)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe(data => {
        if (data.result === 0 && data.data) {
          this.isLoading = false;
          this.dataSource = data.data.empty ? [] : data.data.content;
          this.pageConfig = {
            ...this.pageConfig,
            totalCount: data.data.totalElements,
          };
        } else {
          this.toastError(data.message);
        }
      });
  }

  getRequestParams() {
    const params: AccountInfoAmountRequest = {
      amountType: this.getAmountType(this.data.category),
      subAcctType: this.data.type.toUpperCase(),
      erAcctCd: this.data.contributionDetails.employerAccountCode,
      id: this.data.employerId,
      filterType: this.data.selectedContributionSortBy || 'All',
      payrollGroupId: this.data.billingClass.payrollGroupId,
      startDate: this.data.contributionDetails.contributionPeriodStartDate,
      endDate: this.data.contributionDetails.contributionPeriodEndDate,
      page: (this.pageConfig.current as number) - 1,
      size: 5,
    };

    if (this.data.byBillReference) {
      params.runDate = this.data.contributionDetails.runDate;
      params.billRefNo = this.data.contributionDetails.billReferenceNumber;
    }
    return params;
  }

  getAmountType(category: string) {
    switch (category) {
      case 'memberContributionAmountForTheBill':
        return 'Bill_Contribution_Amount';
      case 'reportedMemberContributionAmount':
        return 'Reported_Contribution_Amount';
      case 'paidContributionAmount':
        return 'Paid_Amount';
      case 'outstandingAmountOfThePayment':
        return 'Outstanding_Payment_Amount';
      default:
        return '';
    }
  }

  toastError(errorMessage: string) {
    this.toastAlert.show('error', 'common.error', errorMessage, 5000);
  }

  reloadTable(event: any) {
    this.pageConfig = {
      ...this.pageConfig,
      current: event.current,
    };
    this.loadTable();
  }
}
