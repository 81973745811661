<div class="row l-pt-6">
  <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 l-pt-4 l-pb-4 l-mr-4" *ngIf="showDatePicker">
    <cds-textfield
      [formControl]="startDateFormControl"
      placeholder="DD/MM/YYYY"
      label="{{ 'account.select-contribution-period-start-date' | lang }}"
      [max]="nowTime"
      [cdsDatepicker]="picker">
    </cds-textfield>
    <cds-datepicker #picker="cdsDatepicker"></cds-datepicker>

    <div *ngIf="startDateFormControl?.invalid && (startDateFormControl?.dirty || startDateFormControl?.touched)" class="l-pt-2">
      <cds-assistive-text
        *ngIf="startDateFormControl?.errors?.['dateInvalid'] || startDateFormControl?.errors?.['startLaterThanEnd']"
        class="l-pt-2"
        [showIcon]="false"
        >{{ 'create.dateError' | lang }}</cds-assistive-text
      >
    </div>
  </div>

  <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 row l-plr-0">
    <div class="search-padding l-pb-2 l-pt-5">
      <cds-button
        [fullWidth]="true"
        [config]="searchButtonConfig"
        label="{{ 'list.search' | lang }}"
        [disabled]="searchButtonDisabled"
        (click)="search(false, true)"></cds-button>
    </div>
    <div class="search-padding l-pb-2 l-pt-5">
      <cds-button
        [fullWidth]="true"
        [config]="resetButtonConfig"
        label="{{ 'list.reset' | lang }}"
        [disabled]="resetButtonDisabled"
        (click)="reset()"></cds-button>
    </div>
  </div>
</div>

<app-loading *ngIf="isLoading" [overlay]="true"></app-loading>
<div class="l-mt-6">
  <app-no-result-found *ngIf="!showBillInfo"></app-no-result-found>
</div>

<div class="row" *ngIf="showBillInfo">
  <div class="col-xs-12 l-pt-4 l-pb-4">
    <span class="cds-h3-demibold">{{ 'account.contribution-period' | lang }}</span>
    <span class="cds-h3-demibold">{{ contributionDetails.contributionPeriodStartDate | ssmsDate }}</span>
    <span class="cds-h3-demibold"> - </span>
    <span class="cds-h3-demibold">{{ contributionDetails.contributionPeriodEndDate | ssmsDate }}</span>
  </div>
</div>

<div class="row" *ngIf="showBillInfo">
  <div class="col-xs-12 l-pt-5 cds-color-green">
    <span class="cds-h5-demibold">{{ (isStaff ? 'account.employer-contribution-details' : 'account.employer-contribution-details-for-agent') | lang }}</span>
  </div>
  <div class="col-xs-12">
    <div class="p-line"></div>
  </div>
</div>

<div class="row" *ngIf="showBillInfo">
  <div class="col-xs-12">
    <div class="col-xs-12 row basic-pad l-pt-4">
      <div class="l-mr-2 l-pt-1">
        <cds-icon color="#282B3E" width="20px" height="20px" icon="finance:bill"></cds-icon>
      </div>
      <div class="col-xs row l-plr-0">
        <div class="col-xs-12">
          <span class="cds-body1-demibold">{{ 'account.bill-information' | lang }}</span>
        </div>
        <!-- <div class="col-xs-12">
          <span class="cds-body2">Billing Reference Number: {{ contributionDetails.billingReferenceNumber }}</span>
        </div> -->
      </div>
    </div>

    <div class="detail-pad">
      <div class="col-xs-12 row no-padding l-pb-4">
        <!-- <app-row-item
          label="account.billing-status-type"
          [data]="contributionDetails.billingStatusType"
          class="col-xs-6 col-sm-6 col-md-4 col-lg-3">
        </app-row-item> -->
        <app-row-item label="account.no-of-lives" [data]="contributionDetails.lives | number" class="col-xs-6 col-sm-6 col-md-4 col-lg-3"> </app-row-item>
        <app-row-item label="account.contribution-frequency" [data]="contributionDetails.contributionFrequency" class="col-xs-6 col-sm-6 col-md-4 col-lg-3">
        </app-row-item>
        <!-- <app-row-item
          label="account.outstanding-amount-payment"
          [data]="contributionDetails.outstandingAmountOfPayment | number"
          class="col-xs-6 col-sm-6 col-md-4 col-lg-3">
        </app-row-item>
        <app-row-item
          label="account.remittance-statement-received-date"
          [data]="
            contributionDetails.remittanceStatementReceivedDate | ssmsDate
          "
          class="col-xs-6 col-sm-6 col-md-4 col-lg-3">
        </app-row-item> -->
      </div>

      <div class="icon-label">
        <cds-icon icon="finance:cash_benefit"></cds-icon>
        <div class="l-pr-4"></div>
        <div>{{ 'account.contribution-details' | lang }}</div>
      </div>

      <div class="col-xs-12 l-pb-4">
        <!-- <app-row-item
          label="account.no-of-lives"
          [data]="contributionDetails.lives | number"
          class="col-xs-6 col-sm-6 col-md-4 col-lg-3">
        </app-row-item> -->
        <!-- <app-row-item
          label="no-of-lives"
          [data]="contributionDetails.contLives | number"
          class="col-xs-6 col-sm-6 col-md-4 col-lg-3">
        </app-row-item> -->
        <!-- <div class="cds-body2 italic">
          Summary of Contribution Details for
          {{ contributionDetails.contributionPeriodStartDate | ssmsDate }} (as of today)
        </div> -->
        <div>
          <span class="cds-body2 l-mr-3">Filter by:</span>
          <cds-pill
            *ngFor="let item of contributionSortBy"
            [selected]="item.value === selectedContributionSortBy"
            (selectedChange)="pillSelectedChange($event, item.value)"
            >{{ item.label }}</cds-pill
          >
        </div>
      </div>

      <div class="l-pt-4 l-pb-8">
        <app-amount-table
          [contributionTableDataSource]="contributionTableDataSource"
          [contributionDetails]="contributionDetails"
          [selectedContributionSortBy]="selectedContributionSortBy"
          [billingClass]="billingClass"
          [employerId]="employerId">
        </app-amount-table>
      </div>
    </div>
  </div>
</div>

<ng-container *ngIf="showBillInfo">
  <app-permission [role]="PermissionAccess.R" [permission]="PermissionItem.CUSTOMER_EMPLOYER">
    <app-bill-reference [contributionDetails]="contributionDetails" [employerId]="employerId" [billingClass]="billingClass"></app-bill-reference>
  </app-permission>

  <app-member-level [contributionDetails]="contributionDetails" [payrollGroupId]="billingClass?.payrollGroupId" [employerId]="employerId"></app-member-level>
</ng-container>
