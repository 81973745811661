import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { finalize } from 'rxjs';
import { ToastAlertService } from 'src/app/shared/toast-alert.service';
import { agentStatusMapObj } from '../../employer/employer';

import { EmployeeService } from '../employee.service';
import { CdsButtonConfig } from '@cds/ng-core/button';
import { DateTime } from 'luxon';
import { ColumnSort, I18N_KEY } from '../../employer/employer';
import { ColumnConfig, PageConfig, SortMode } from 'src/app/shared/data-table';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { dateValidator, descriptionValidator } from 'src/app/shared/validators/validators';
import { deepCopy } from 'src/app/utils/copy';
import { IPaginationCount } from 'src/app/shared/pagination/pagination.dto';
import { CdsDropdownConfig } from '@cds/ng-core/dropdown';
import moment from 'moment';
import {
  TransactionStatusCds,
  TransactionActivityTypes,
  TransactionSubActivityTypes,
  TransactionRecordResponse,
  TransactionRecordRequest,
  InvestedFundInformationResponse,
  InvestedFundInfo,
  FundBalanceResponse,
} from '../employee';
import { PermissionAccess, PermissionItem } from 'src/app/core/models/enum/permission.enum';
import { Sort } from 'src/app/components/table/table-expand';
import { PermissionService } from 'src/app/core/services/permission.service';
import { CdsPopupService } from '@cds/ng-web-components/popup';
import { paymentTransactionPopComponent } from './payment-transaction-pop/payment-transaction-pop.component';
@Component({
  selector: 'app-fund-info',
  templateUrl: './fund-info.component.html',
  styleUrls: ['./fund-info.component.scss'],
})
export class FundInfoComponent implements OnInit, OnChanges {
  @Input() id = '';

  isLoading = false;

  agentStatusMapObj = agentStatusMapObj;

  config: CdsButtonConfig = {
    size: 'sm',
    style: 'secondary',
  };

  investedFundInformationResponseData: InvestedFundInformationResponse = {
    asOfDate: '',
    investedFundInfo: [],
  };

  fundBalanceData: FundBalanceResponse = {
    fundBalanceList: [],
  };

  searchDTO: TransactionRecordRequest = {
    page: 0,
    size: 10,
  };

  exportParams: TransactionRecordResponse = {};

  searchButtonConfig: CdsButtonConfig = {
    size: 'sm',
  };

  resetButtonConfig: CdsButtonConfig = {
    size: 'sm',
    style: 'secondary',
  };

  exportButtonConfig: CdsButtonConfig = {
    size: 'sm',
    style: 'secondary',
  };

  get searchButtonDisabled() {
    return !this.form?.valid || !this.form.dirty;
  }

  get resetButtonDisabled() {
    return !this.form?.dirty;
  }

  I18N_KEY = I18N_KEY;

  exportButtonDisabled = true;

  _progress = false;

  _totalPages = 0;

  _totalCount = 0;

  pageCount: IPaginationCount = { current: 1, count: 1 };

  _recordList: TransactionRecordResponse[] = [];

  _number = 1;

  _inputNumber = 1;

  isMobile = false;

  showDatePicker = true;

  formReady = false;

  isStaff = false;

  TransactionStatusCds = [...TransactionStatusCds];
  TransactionActivityTypes = [...TransactionActivityTypes];

  StatusCodeConfig: CdsDropdownConfig = {
    label: 'Status code',
    placeholder: 'Select Status code',
    options: [...TransactionStatusCds],
  };
  ActivityConfig: CdsDropdownConfig = {
    label: 'Activity Type',
    placeholder: 'Select Activity Type',
    options: [...TransactionActivityTypes],
  };
  SubActivityConfig: CdsDropdownConfig = {
    label: 'Sub Activity Type',
    placeholder: 'Select Sub Activity Type',
    options: [...TransactionSubActivityTypes],
  };

  displayedColumns: string[] = ['referenceNo', 'groupReferenceNo', 'statusCode', 'activityType', 'subActivityType', 'createDate'];

  columnsConfig: ColumnConfig[] = [
    {
      key: 'referenceNo',
      title: 'Reference No.',
      sticky: true,
      sort: SortMode.DEFAULT,
    },
    {
      key: 'groupReferenceNo',
      title: 'Group Reference No.',
      sticky: true,
      //sort: SortMode.DEFAULT,
    },
    {
      key: 'statusCode',
      title: 'Status Code',
      stickyEnd: true,
      //sort: SortMode.DEFAULT,
    },
    {
      key: 'activityType',
      title: 'Activity Type',
      //sort: SortMode.DEFAULT,
    },
    {
      key: 'subActivityType',
      title: 'Sub Activity Type',
      //sort: SortMode.DEFAULT,
    },
    {
      key: 'effectiveDate',
      title: 'Effective Date',
    },
    {
      key: 'createDate',
      title: 'Created Date',
    },
  ];
  pageConfig: PageConfig = {
    current: 1,
    pageSize: 10,
    totalCount: 0,
  };

  tempSeardhParams: TransactionRecordRequest = {
    page: 1,
    size: 10,
  };

  nowTimeInstr = DateTime.now().toFormat('dd/MM/yyyy');

  maxTime = this.getNowTime();

  maxTimeInStr = DateTime.fromJSDate(this.maxTime).toFormat('dd/MM/yyyy');

  columnSort: ColumnSort = {
    key: 'createdAt',
    value: SortMode.DOWN,
  };

  form: FormGroup = new FormGroup({});

  employerNameValidTip = 'Please input English, Chinese, numbers, or special characters';

  get groupReferenceNoControl() {
    return this.form?.get('groupReferenceNo');
  }

  get referenceNoControl() {
    return this.form?.get('referenceNo');
  }

  get statusCodeControl() {
    return this.form?.get('statusCode');
  }

  get activityTypeControl() {
    return this.form?.get('activityType');
  }

  get subActivityTypeControl() {
    return this.form?.get('subActivityType');
  }

  get effectiveDateControl() {
    return this.form?.get('effectiveDate');
  }

  get createdDateControl() {
    return this.form?.get('createdDate');
  }

  constructor(
    public service: EmployeeService,
    private toastAlert: ToastAlertService,
    private route: ActivatedRoute,
    private permissionService: PermissionService,
    private cdsPopup: CdsPopupService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['id'].currentValue !== undefined && changes['id'].currentValue !== changes['id'].previousValue) {
      this.getInvestedFundInfo();
    }
  }

  ngOnInit(): void {
    this.permissionFilter();
    this.getFundBalance();
    this.getInvestedFundInfo();
    this.initForm();
    this.loadTable();
  }

  getInvestedFundInfo() {
    if (!this.id) return;
    this.isLoading = true;
    this.service
      .getInvestedFundInformationResponse(this.id)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe(res => {
        if (res.result === 0 && res.data) {
          this.investedFundInformationResponseData = res.data;
        } else {
          this.toastError(res.message);
        }
      });
  }

  getFundBalance() {
    if (!this.id) return;
    this.isLoading = true;
    this.service
      .getFundBalance(this.id)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe(res => {
        if (res.result === 0 && res.data) {
          this.fundBalanceData = res.data;
        } else {
          this.toastError(res.message);
        }
      });
  }

  titleGen(data: InvestedFundInfo) {
    if (data.fundCode && data.fundName) {
      return `${data.fundCode}     ${data.fundName}`;
    }

    return '';
  }

  toastError(errorMessage: string) {
    this.toastAlert.show('error', 'common.error', errorMessage, 5000);
  }

  dateTrans(date: string) {
    if (date) {
      return moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD');
    }

    return date;
  }

  search() {
    if (this.searchButtonDisabled) {
      return;
    }
    this.pageConfig = {
      ...this.pageConfig,
      current: 1,
    };
    this.tempSeardhParams = deepCopy(this.getTempSearchParams());
    this.loadTable();
  }

  reset() {
    if (this.resetButtonDisabled) {
      return;
    }
    this.pageConfig = {
      ...this.pageConfig,
      current: 1,
    };

    this.tempSeardhParams = {
      page: 1,
      size: 10,
    };

    this.initForm();

    this.showDatePicker = false;
    setTimeout(() => {
      this.showDatePicker = true;
      this.loadTable();
    });
  }

  onPageChange(page: number) {
    this.searchDTO.page = page - 1;
    this._inputNumber = page;
    this.loadTable();
  }

  onGoPage(num: number) {
    if (num < 1) {
      num = 1;
    }
    if (num > this._totalPages) {
      num = this._totalPages;
    }
    this._number = num;
    this._inputNumber = num;
  }

  getMinTime() {
    return moment().subtract(5, 'year').add(1, 'day').toDate();
  }

  getNowTime() {
    return moment().toDate();
  }

  getTime(dateStr: string) {
    return moment(dateStr, 'DD/MM/YYYY').toDate();
  }

  getMaxFromDate = (toDateStr = '31/12/9999') => {
    return moment(toDateStr, 'DD/MM/YYYY').toDate();
  };

  getMinToDate = (fromDateStr = '01/01/0000') => {
    return moment(fromDateStr, 'DD/MM/YYYY').toDate();
  };

  initForm() {
    this.form?.markAsUntouched();
    this.form?.markAsPristine();

    this.form = new FormGroup({
      groupReferenceNo: new FormControl('', [Validators.maxLength(15)]),
      referenceNo: new FormControl('', [Validators.maxLength(200), descriptionValidator(this.employerNameValidTip, true)]),
      effectiveDate: new FormControl('', [dateValidator('create.dateError', '', '')]),
      createdDate: new FormControl('', [dateValidator('create.dateError', '', '')]),
      statusCode: new FormControl('', []),
      subActivityType: new FormControl('', []),
      activityType: new FormControl('', []),
    });
    this.formReady = false;
    setTimeout(() => {
      this.formReady = true;
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  reloadTable(event: any) {
    const temp = event.column?.key?.replace('referenceNo', 'ref_no');

    if (event.sort !== Sort.DEFAULT) {
      this.tempSeardhParams.sort = `${temp},${event.column?.sort}`;
    } else {
      delete this.tempSeardhParams.sort;
    }

    this.pageConfig = {
      ...this.pageConfig,
      current: event.current,
    };
    // this.columnSort = event.column
    //   ? {
    //       key: event.column?.key,
    //       value: event.column?.sort,
    //     }
    //   : {
    //       key: 'createdAt',
    //       value: SortMode.DOWN,
    //     };

    this.loadTable();
  }

  loadTable() {
    const params: TransactionRecordRequest = this.getRequestParams();
    this._progress = true;
    this.exportButtonDisabled = true;
    this.service
      .getFundInfoTransactionRecordPageList(params)
      .pipe(
        finalize(() => {
          this._progress = false;
        })
      )
      .subscribe(data => {
        if (data.result === 0 && data.data) {
          this._progress = false;
          this._recordList = data.data.empty ? [] : data.data.content;
          this._totalCount = data.data.totalElements;
          this.pageConfig = {
            ...this.pageConfig,
            totalCount: data.data.totalElements,
          };
        }
      });
  }

  getTempSearchParams() {
    const params: TransactionRecordRequest = {
      ...this.tempSeardhParams,
      ...(this.form.valid ? this.form.value : {}),
      page: (this.pageConfig.current as number) - 1,
      size: 10,
    };

    params.createdDate = params.createdDate ? this.dateTrans(params.createdDate) : '';
    params.effectiveDate = params.effectiveDate ? this.dateTrans(params.effectiveDate) : '';
    return params;
  }

  getRequestParams() {
    const params: TransactionRecordRequest = {
      ...this.tempSeardhParams,
      page: (this.pageConfig.current as number) - 1,
      size: 10,
      id: this.id,
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const exportParams: any = { ...params };
    delete exportParams.page;
    delete exportParams.size;
    delete exportParams.totalLives;
    this.exportParams = exportParams;
    return params;
  }

  expandAll() {
    this.investedFundInformationResponseData.investedFundInfo?.forEach(item => {
      item.show = !item.show;
    });
  }

  goToPopUp(item: TransactionRecordResponse) {
    console.log(item);

    this.cdsPopup
      .open(paymentTransactionPopComponent, {
        size: 'lg',
        data: {
          transactionRefNo: item.referenceNo,
          paySubmitRefNo: '',
          id: this.id,
        },
      })
      .afterClosed()
      .subscribe(result => {
        if (result?.agree) {
          this.loadTable();
        }
      });
  }

  getStatusColor(statusCode: string) {
    switch (statusCode) {
      case 'IN_PROGRESS':
        return '#F49600';

      case 'COMPLETE':
        return '#00A758';

      case 'INVEST_ORDER_PEND':
        return '#9D8DBC';

      case 'NIGHT_BATCH_PEND':
        return '#6F5693';

      case 'CANCEL':
        return '#EC6453';

      case 'REJECT':
        return '#424559';

      case 'ONHOLD':
        return '#0000C1';

      default:
        return '#ff0000';
    }
  }

  getFormType(item: string | undefined) {
    switch (item) {
      case 'PP':
        return 'PP - Employee Choice Arrangement ("ECA")';

      case 'PM':
        return "PM - Scheme Member's Request for Fund Transfer";

      case 'PC':
        return "PC - Scheme Member's Request for Account Consolidation";

      case 'PT':
        return "PT - Scheme member's request for Transfer Tax Deductible Voluntary Contribution";

      case 'PE':
        return "PE - Employer's request for fund transfer form";

      case 'OT':
        return 'OT - Other than the above';

      default:
        return '';
    }
  }

  permissionFilter() {
    this.permissionService.hasPermission(PermissionAccess.R, PermissionItem.CUSTOMER_EMPLOYER).then(res => {
      this.isStaff = res;
    });
  }
}
