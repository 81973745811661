/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, ContentChild, Input, OnInit, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-cd-table-title',
  templateUrl: './cd-table-title.component.html',
  styleUrls: ['./cd-table-title.component.scss'],
})
export class CdTableTitleComponent implements OnInit {
  @Input() showSubTitle = true;
  @Input() total = 0;
  @Input() title = '';

  @ContentChild('tableAction', { static: true }) action!: TemplateRef<any>;

  constructor() {}

  ngOnInit() {}
}
