import { deepCopy } from 'src/app/utils/copy';
import { FormControl } from '@angular/forms';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CdsIconConfig } from '@cds/ng-core/icon';
import { ToastAlertService } from 'src/app/shared/toast-alert.service';
import { dateValidator } from 'src/app/shared/validators/validators';
import moment from 'moment';
import { DateTime } from 'luxon';
import { ContributionAmount, ContributionDetails, EeBillReference } from '../../employer/employer';
import { EmployerService } from '../../employer/employer.service';

@Component({
  selector: 'app-bill-reference',
  templateUrl: './bill-reference.component.html',
  styleUrls: ['./bill-reference.component.scss'],
})
export class BillReferenceComponent implements OnInit, OnChanges {
  @Input() contributionTableDataSource: ContributionAmount[] = [];
  @Input() contributionDetails?: ContributionDetails;
  @Input() isReset = false;
  @Output() runDateChange = new EventEmitter<string>();

  isLoading = false;

  data: EeBillReference[] = [];

  iconConfig: CdsIconConfig = {
    color: '#00A758',
  };

  public runDateFormControl: FormControl = new FormControl('');

  constructor(private route: ActivatedRoute, private router: Router, public service: EmployerService, private toastAlert: ToastAlertService) {}

  ngOnInit(): void {
    this.initFormControl();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['isReset'] !== undefined && changes['isReset'].currentValue !== undefined && changes['isReset'].currentValue) {
      this.runDateFormControl = new FormControl('');
    }

    if (
      changes['contributionDetails'] !== undefined &&
      changes['contributionDetails'].currentValue !== undefined &&
      changes['contributionDetails'].currentValue.billReferenceNos !== changes['contributionDetails'].previousValue?.billReferenceNos
    ) {
      this.data = (this.contributionDetails?.billReferenceNos as EeBillReference[]) || [];
    }
  }

  initFormControl() {
    this.runDateFormControl = new FormControl('', [
      dateValidator('create.dateError', this.contributionDetails?.contributionPeriodStartDate, this.contributionDetails?.contributionPeriodEndDate),
    ]);
  }

  toDetail(paymentSubmissionReferenceNo: string, paymentReferenceNo?: string) {
    this.router.navigate(['/employer/payment-record-detail'], {
      queryParams: {
        ...this.route.snapshot.queryParams,
        paymentSubmissionReferenceNo,
        paymentReferenceNo,
      },
    });
  }

  onDateChange(date: string) {
    if (this.runDateFormControl.valid) {
      this.runDateChange.emit(date);
    }
  }

  toastError(errorMessage: string) {
    this.toastAlert.show('error', 'common.error', errorMessage, 5000);
  }

  dateTrans(date: string) {
    if (date) {
      return moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD');
    }

    return date;
  }

  getContributionDetails(data: ContributionDetails) {
    const details: ContributionDetails = deepCopy(data);
    details.contributionPeriodStartDate = this.contributionDetails?.contributionPeriodStartDate;
    details.contributionPeriodEndDate = this.contributionDetails?.contributionPeriodEndDate;
    return details;
  }

  tranToJsDate(date?: string) {
    if (!date) {
      return new Date();
    }
    return DateTime.fromFormat(date, 'yyyy-MM-dd').toJSDate();
  }

  getStringList(value: string) {
    let list: string[] = [];
    if (value) {
      list = value.split(',');
    }
    return list;
  }
}
