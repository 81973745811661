<div class="row col-xs-12 col-md-12 l-pb-6 l-pt-6 background-grey advise-iffstatus" appOutsideClose (outsideEvent)="backTosummary()">
  <div style="width: 100%">
    <p class="cds-h5-light l-ma-0 font-bold l-pl-7 l-pb-2">IFF Status Flow</p>
    <div class="l-d-f l-fd-col l-ai-fs l-ml-7" style="height: 462px; overflow-y: auto">
      <li class="iffstatus-align l-pb-2">
        <div class="iffstatus-lightalign">
          <div class="flex-center" style="background-color: #f49600">1</div>
        </div>
        <div style="flex-direction: column; display: flex">
          <span class="iffstatus-keyword">{{ 'eIFFStatus.pendingToSend' | lang }}</span>
          <span class="iffstatus-normalword">The IFF form</span>
        </div>
      </li>
      <li class="iffstatus-align l-pb-2">
        <div class="iffstatus-lightalign">
          <div class="flex-center" style="background-color: #06c7ba">2</div>
        </div>
        <div style="flex-direction: column; display: flex">
          <span class="iffstatus-keyword">{{ 'eIFFStatus.pendingSplitAgent' | lang }} </span>
          <span class="iffstatus-normalword">The IFF is pending for split agent acknowledgement </span>
        </div>
      </li>
      <li class="iffstatus-align l-pb-2">
        <div class="iffstatus-lightalign">
          <div class="flex-center" style="background-color: #0000c1">3</div>
        </div>
        <div style="flex-direction: column; display: flex">
          <span class="iffstatus-keyword">{{ 'eIFFStatus.pendingCustomerSubmission' | lang }}</span>
          <span class="iffstatus-normalword">The IFF is sent and pending for customer submission </span>
        </div>
      </li>
      <li class="iffstatus-align l-pb-2">
        <div class="iffstatus-lightalign">
          <div class="flex-center" style="background-color: #06874e">4</div>
        </div>
        <div style="flex-direction: column; display: flex">
          <span class="iffstatus-keyword">{{ 'eIFFStatus.completed' | lang }}</span>
          <span class="iffstatus-normalword">The IFF form is received from customer </span>
        </div>
      </li>
      <li class="iffstatus-align l-pb-2">
        <div class="iffstatus-lightalign">
          <div class="flex-center" style="background-color: #d03a39">5</div>
        </div>
        <div style="flex-direction: column; display: flex">
          <span class="iffstatus-keyword">{{ 'eIFFStatus.declined' | lang }}</span>
        </div>
      </li>
      <li class="iffstatus-align l-pb-2">
        <div class="iffstatus-lightalign">
          <div class="flex-center" style="background-color: #ec6453">6</div>
        </div>
        <div style="flex-direction: column; display: flex">
          <span class="iffstatus-keyword">Declined (Agent SEP/ Staff CA)</span>
        </div>
      </li>
      <li class="iffstatus-align l-pb-2">
        <div class="iffstatus-lightalign">
          <div class="flex-center" style="background-color: #8e90a2">7</div>
        </div>
        <div style="flex-direction: column; display: flex">
          <span class="iffstatus-keyword">{{ 'eIFFStatus.expired' | lang }}</span>
        </div>
      </li>
      <li class="iffstatus-align l-pb-2">
        <div class="iffstatus-lightalign">
          <div class="flex-center" style="background-color: #282b3e">8</div>
        </div>
        <div style="flex-direction: column; display: flex">
          <span class="iffstatus-keyword">Void</span>
        </div>
      </li>
    </div>

    <div class="row l-pt-6 col-xs-12 col-md-12 l-d-f l-jc-sb">
      <div></div>
      <cds-button label="Got it" (click)="backTosummary()" [config]="clearButton"></cds-button>
    </div>
  </div>
</div>
