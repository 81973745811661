<cds-popup class="popup no-close-icon" *ngIf="show">
  <cds-popup-title>
    <div class="row l-plr-0">
      <div class="col-xs-12">{{ inEdit ? 'Edit Parent/ Subsidiary Company List' : 'Summary' }}</div>
    </div>

    <div class="close-icon" (click)="close()">
      <cds-icon icon="action:cross"> </cds-icon>
    </div>
  </cds-popup-title>

  <cds-popup-content>
    <app-loading *ngIf="isLoading" [overlayInPopup]="true"></app-loading>
    <div class="row l-plr-0 l-pb-4" [formGroup]="searchForm" *ngIf="showSearchForm && inEdit">
      <cds-form-field class="col-xs-12 col-md-6">
        <app-dynamic-input-autocomplete
          label="Employer Account Code"
          placeholder="Input Employer Account Code"
          [requestUrl]="searchUrl"
          [requestParams]="searchParams"
          [initLoad]="false"
          optionKey="employerNo"
          [showDropdownButton]="true"
          [searchUntilFocus]="true"
          [minLength]="MIN_LENGTH"
          [inputValid]="employerNo.valid"
          (valueChange)="searchChange($event, 'employerNo')"
          (selectChange)="selectChange($event)"
          [labelRenderFn]="labelRenderFn('employerNo')"
          formControlName="employerNo"></app-dynamic-input-autocomplete>
        <ng-container *ngIf="employerNo.invalid && (employerNo.dirty || employerNo.touched)">
          <cds-assistive-text *ngIf="employerNo?.errors?.['minlength']" [showIcon]="false">Minimum 2 characters.</cds-assistive-text>
          <cds-assistive-text *ngIf="employerNo?.errors?.['maxlength']" [showIcon]="false">Max length is 15.</cds-assistive-text>
          <cds-assistive-text *ngIf="employerNo?.errors?.['invalidNumberString']" [showIcon]="false">Please input numbers.</cds-assistive-text>
        </ng-container>
      </cds-form-field>

      <cds-form-field class="col-xs-12 col-md-6">
        <app-dynamic-input-autocomplete
          label="English Company Name"
          placeholder="Input English Company Name"
          [requestUrl]="searchUrl"
          [requestParams]="searchParams"
          [initLoad]="false"
          optionKey="employerName"
          [showDropdownButton]="true"
          [searchUntilFocus]="true"
          [minLength]="MIN_LENGTH"
          [inputValid]="employerName.valid"
          (valueChange)="searchChange($event, 'employerName')"
          (selectChange)="selectChange($event)"
          [labelRenderFn]="labelRenderFn('employerName')"
          formControlName="employerName"></app-dynamic-input-autocomplete>
        <ng-container *ngIf="employerName.invalid && (employerName.dirty || employerName.touched)">
          <cds-assistive-text *ngIf="employerName?.errors?.['minlength']" [showIcon]="false">Minimum 2 characters.</cds-assistive-text>
          <cds-assistive-text *ngIf="employerName?.errors?.['maxlength']" [showIcon]="false">Max length is 200.</cds-assistive-text>
          <cds-assistive-text
            *ngIf="employerName?.errors?.['descriptionInvalid']"
            [showIcon]="false"
            >{{ employerName.errors?.['descriptionInvalid'] | lang }}</cds-assistive-text
          >
        </ng-container>
      </cds-form-field>

      <cds-form-field class="col-xs-12 col-md-6">
        <app-dynamic-input-autocomplete
          label="Chinese Company Name"
          placeholder="Input Chinese Company Name"
          [requestUrl]="searchUrl"
          [requestParams]="searchParams"
          [initLoad]="false"
          optionKey="employerChineseName"
          [showDropdownButton]="true"
          [searchUntilFocus]="true"
          [minLength]="MIN_LENGTH"
          [inputValid]="employerChineseName.valid"
          (valueChange)="searchChange($event, 'employerChineseName')"
          (selectChange)="selectChange($event)"
          [labelRenderFn]="labelRenderFn('employerChineseName')"
          formControlName="employerChineseName"></app-dynamic-input-autocomplete>
        <ng-container *ngIf="employerChineseName.invalid && (employerChineseName.dirty || employerChineseName.touched)">
          <cds-assistive-text *ngIf="employerChineseName?.errors?.['minlength']" [showIcon]="false">Minimum 2 characters.</cds-assistive-text>
          <cds-assistive-text *ngIf="employerChineseName?.errors?.['maxlength']" [showIcon]="false">Max length is 200.</cds-assistive-text>
          <cds-assistive-text
            *ngIf="employerChineseName?.errors?.['descriptionInvalid']"
            [showIcon]="false"
            >{{ employerChineseName.errors?.['descriptionInvalid'] | lang }}</cds-assistive-text
          >
        </ng-container>
      </cds-form-field>
      <div class="col-xs-12 col-sm-6 search-button-area l-pt-4">
        <cds-button [config]="addButtonConfig" [disabled]="selectedEr === undefined || searchForm.invalid" (click)="add()">Add</cds-button>
        <cds-button class="l-ml-4" [config]="resetButtonConfig" [disabled]="!searchForm.dirty" (click)="resetSearch()">Reset</cds-button>
      </div>
    </div>

    <div class="alert-area row l-plr-0" *ngIf="ifShowAlert">
      <app-inline-alert class="col-xs-12" [value]="alertValue"></app-inline-alert>
    </div>

    <div class="row l-plr-0">
      <div class="table-container col-xs-12" [formGroup]="form">
        <cds-table [dataSource]="dataSource" [class.full]="true" #table>
          <!-- Employer Account Code-->
          <ng-container cdsColumnDef="employerNo">
            <cds-header-cell *cdsHeaderCellDef>Employer Account Code</cds-header-cell>
            <cds-cell *cdsCellDef="let element">{{ element.employerNo }}</cds-cell>
          </ng-container>
          <!-- Employer Name -->
          <ng-container cdsColumnDef="employerName">
            <cds-header-cell *cdsHeaderCellDef> Employer Name </cds-header-cell>
            <cds-cell *cdsCellDef="let element">
              <div class="cds-body1-demibold l-mb-0 weight-600">
                {{ element.employerName }}
                <span *ngIf="element.employerChineseName" class="l-pl-1"> {{ element.employerChineseName }}</span>
              </div>
              <div class="status-area">
                <div class="dot-icon" [style]="companyService.getStatusColorStyle(element.employerStatus)"></div>
                <div>{{ companyService.getStatus(element.employerStatus) }}</div>
              </div>
            </cds-cell>
          </ng-container>
          <!-- Total Lives -->
          <ng-container cdsColumnDef="totalLives">
            <cds-header-cell *cdsHeaderCellDef> Total Lives </cds-header-cell>
            <cds-cell *cdsCellDef="let element">
              {{ element.totalLives }}
            </cds-cell>
          </ng-container>
          <!-- Parent Company -->
          <ng-container cdsColumnDef="action">
            <cds-header-cell *cdsHeaderCellDef> Parent Company </cds-header-cell>
            <cds-cell *cdsCellDef="let element" class="radio-cell">
              <!-- radio in edit -->
              <cds-radio *ngIf="inEdit" [config]="radioConfig" [formControlName]="element.id" (change)="radioChange($event, element.id)"> </cds-radio>
              <!-- radio in summary -->
              <cds-radio *ngIf="!inEdit" [config]="radioConfig" [formControlName]="getSummaryId(element.id)" [disabled]="true"> </cds-radio>
            </cds-cell>
          </ng-container>

          <!-- Relationship Score -->
          <ng-container cdsColumnDef="relationshipScore">
            <cds-header-cell *cdsHeaderCellDef> Relationship Score </cds-header-cell>
            <cds-cell *cdsCellDef="let element">
              {{ element.relationshipScore }}
            </cds-cell>
          </ng-container>

          <!-- Aggregate Invested Amount -->
          <ng-container cdsColumnDef="aggregateInvestedAmount">
            <cds-header-cell *cdsHeaderCellDef> Aggregate Invested Amount </cds-header-cell>
            <cds-cell *cdsCellDef="let element">
              {{ element.aggregateInvestedAmount ? 'HKD ' + (element.aggregateInvestedAmount | number : '1.2-2') : '' }}
            </cds-cell>
          </ng-container>

          <!-- Annualized Contribution -->
          <ng-container cdsColumnDef="annualizedContribution">
            <cds-header-cell *cdsHeaderCellDef> Annualized Contribution </cds-header-cell>
            <cds-cell *cdsCellDef="let element">
              {{ element.aggregateInvestedAmount ? 'HKD ' + (element.annualizedContribution | number : '1.2-2') : '' }}
            </cds-cell>
          </ng-container>

          <ng-container cdsColumnDef="delete">
            <cds-header-cell *cdsHeaderCellDef></cds-header-cell>
            <cds-cell *cdsCellDef="let element">
              <cds-icon icon="action:button_x_filled" (click)="delete(element.id)"></cds-icon>
            </cds-cell>
          </ng-container>

          <cds-header-row *cdsHeaderRowDef="getDisplayedColumns(inEdit)"></cds-header-row>

          <cds-row *cdsRowDef="let row; columns: getDisplayedColumns(inEdit)"></cds-row>
        </cds-table>
      </div>
    </div>
  </cds-popup-content>

  <cds-popup-actions>
    <div class="row l-plr-0 full-width">
      <div class="col-xs-12 button-area">
        <ng-container *ngIf="inEdit">
          <cds-button [config]="resetButtonConfig" [disabled]="resetDisabled" (click)="reset()">{{ 'common.reset' | lang }}</cds-button>

          <cds-button [config]="saveButtonConfig" [disabled]="saveDisabled" (click)="save()">{{ 'common.save' | lang }}</cds-button>
        </ng-container>

        <ng-container *ngIf="!inEdit">
          <cds-button [config]="backToEditButtonConfig" (click)="backToEdit()">{{ 'common.backToEdit' | lang }}</cds-button>

          <cds-button [config]="confirmButtonConfig" (click)="confirm()">{{ 'common.confirm' | lang }}</cds-button>
        </ng-container>
      </div>
    </div>
  </cds-popup-actions>
</cds-popup>
