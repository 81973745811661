export const FILTER = [
  {
    type: 'Agent',
    open: true,
    list: [
      {
        label: 'Agent Assignment',
        value: 'agent-assignment',
      },
      {
        label: 'Agent Details',
        value: 'agent-details',
      },
    ],
  },
  {
    type: 'Product',
    open: false,
    list: [],
  },
  {
    type: 'MLFUB Report',
    open: false,
    list: [
      {
        label: 'Report',
        value: 'report',
      },
      {
        label: 'Summary',
        value: 'summary',
      },
      {
        label: 'Cash Transfer',
        value: 'cash-transfer',
      },
      {
        label: 'Financial Journal',
        value: 'financial-journal',
      },
      {
        label: 'GPMM/HKGA Transaction',
        value: 'GPMM/HKGA Transaction',
      },
      {
        label: 'Redemption Report',
        value: 'Redemption Report',
      },
      {
        label: 'Subscription Report',
        value: 'Subscription Report',
      },
      {
        label: 'Fund Switching',
        value: 'Fund Switching',
      },
      {
        label: 'Fund Order',
        value: 'Fund Order',
      },
      {
        label: 'Fund Movement',
        value: 'Fund Movement',
      },
      {
        label: 'Dividend',
        value: 'Dividend',
      },
      {
        label: 'Dividend Rates',
        value: 'Dividend Rates',
      },
      {
        label: 'Contract Note',
        value: 'Contract Note',
      },
      {
        label: 'Fund Price Upload',
        value: 'Fund Price Upload',
      },
      {
        label: 'Fund Order Details',
        value: 'Fund Order Details',
      },
    ],
  },
  {
    type: 'Member Authorization',
    open: false,
    list: [],
  },
  {
    type: 'DBS',
    open: false,
    list: [],
  },
];
