<app-cd-page-title
  [showBack]="true"
  [backLandingLabel]="'Commission Info Maintainence Detail' | lang"
  (backLanding)="backLanding()"
  title="{{ 'commission.pool.scale.recordCreate.title' | lang }}">
</app-cd-page-title>
<div class="row">
  <div class="col-xs-12 col-md-12">
    <h3>
      {{ 'commission.pool.scale.recordCreate.information' | lang }}
    </h3>
    <p>
      <span class="font-bold l-mr-2">{{ 'commission.pool.common.poolNumber' | lang }}:</span>
      <span class="font-bold">{{ commissionPoolId }}</span>
    </p>
  </div>
</div>
<form [formGroup]="formGroup" class="row">
  <div class="col-xs-12 col-md-12">
    <h3>
      {{ 'commission.pool.scale.recordCreate.newScaleSetup' | lang }}
    </h3>
  </div>

  <div class="col-xs-12 col-md-4 l-pr-7">
    <label class="cds-textfield-label cds-detail1 font-label">{{ 'commission.pool.scale.recordCreate.scaleEffectiveDate' | lang }}</label>
    <div *ngIf="isEdit && isShowDatepiker">
      <cds-textfield placeholder="DD/MM/YYYY" [cdsDatepicker]="datePicker" ngDefaultControl formControlName="effectiveDate"> </cds-textfield>
      <cds-datepicker #datePicker="cdsDatepicker"></cds-datepicker>
      <div>
        <cds-assistive-text *ngIf="effectiveDate.errors?.['error'] && effectiveDate.dirty" class="l-pt-3">
          {{ effectiveDate.errors?.['error'] | lang }}
        </cds-assistive-text>
      </div>
    </div>
    <div *ngIf="!isEdit" class="l-mt-2">{{ effectiveDate.value }}</div>
  </div>
  <div class="col-xs-12 col-md-4 l-mb-6">
    <label class="cds-textfield-label cds-detail1 font-label">{{ 'commission.pool.scale.recordCreate.alertAlarm' | lang }}</label>
    <div class="l-pt-2">
      <div class="l-d-f l-ai-cen">
        <cds-checkbox ngDefaultControl formControlName="alarmChecked"></cds-checkbox>
        <label class="l-ml-3 cds-body1 cds-dark-navy-text l-mb-0">{{ 'commission.pool.scale.recordCreate.setAlertAlarm' | lang }}</label>
      </div>
      <!-- <cds-checkbox ngDefaultControl label="{{ 'commission.pool.scale.recordCreate.setAlertAlarm' | lang }}" formControlName="alarmChecked"> </cds-checkbox> -->
    </div>
  </div>
  <div class="row col-xs-12 col-md-12" formArrayName="scaleCode">
    <div class="col-xs-12 col-md-8 l-plr-0 l-d-f l-jc-cen" *ngIf="loading">
      <cds-progress mode="indeterminate" class="l-pl-6"></cds-progress>
    </div>
    <div class="col-xs-12 col-md-8 l-plr-0" *ngIf="!loading">
      <div class="div-border cds-white l-pa-4">
        <div class="row l-plr-0 div-border-top div-border-bottom l-pt-2 l-pb-2">
          <div class="col-xs-3 col-md-3">
            <span class="cds-body1-demibold">{{ 'commission.pool.scale.recordCreate.scale' | lang }}</span>
          </div>
          <div class="col-xs-6 col-md-6">
            <span class="cds-body1-demibold">{{ 'commission.pool.scale.recordCreate.scaleCode' | lang }}</span>
          </div>
          <div class="col-xs-3 col-md-3" *ngIf="isEdit">
            <span class="cds-body1-demibold">{{ 'commission.pool.scale.recordCreate.action' | lang }}</span>
          </div>
        </div>

        <div class="row l-plr-0 div-border-bottom l-pt-4 l-pb-5" *ngFor="let item of dataSource; let index = index">
          <div class="col-xs-3 col-md-3">
            <div class="cds-body2 font-label-color l-mb-3">
              {{ 'commission.pool.scale.recordCreate.scale' | lang }}
            </div>
            <div>
              <span class="cds-body1-regular">{{ item.scale | lang }}</span>
            </div>
          </div>
          <div class="col-xs-6 col-md-6">
            <div class="cds-body2 font-label-color l-mb-2">
              {{ 'commission.pool.scale.recordCreate.scaleCode' | lang }}
            </div>
            <div *ngIf="isEdit">
              <cds-textfield
                class="cds-body1-regular"
                (input)="scaleCodeInput($event, index)"
                [placeholder]="'commission.pool.scale.recordCreate.input' | lang"
                ngDefaultControl
                [formControlName]="index.toString()">
              </cds-textfield>
              <div *ngIf="scaleCode.controls[index].errors && scaleCodeItemChange(index)" class="l-pt-2 l-mt-2">
                <cds-assistive-text> {{ item.scale | lang }}{{ scaleCode.controls[index].errors?.['error'] | lang }} </cds-assistive-text>
              </div>
            </div>
            <div *ngIf="!isEdit">{{ scaleCode.controls[index].value }}</div>
          </div>
          <div class="col-xs-3 col-md-3 l-d-f l-ai-cen font-bold" *ngIf="isEdit">
            <span style="color: #ec6453; cursor: pointer" (click)="clear(index)">{{ 'commission.pool.scale.recordCreate.clear' | lang }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xs-12 col-md-12 l-mt-5 l-pl-0" *ngIf="isEdit">
      <cds-button type="submit" class="l-mr-3" (click)="reset()" [disabled]="isResetDisable" [style]="'secondary'" size="md"
        >{{ 'common.reset' | lang }}
      </cds-button>
      <cds-button [disabled]="isSaveDisable" type="submit" size="md" (click)="save()">{{ 'common.save' | lang }} </cds-button>
    </div>
    <div class="col-xs-12 col-md-12 l-mt-5 l-pl-0" *ngIf="!isEdit">
      <cds-button type="submit" size="md" (click)="backToEdit()">{{ 'common.backToEdit' | lang }} </cds-button>
      <cds-button type="submit" [disabled]="isSaveing" class="l-ml-3" (click)="confirm()" [style]="'secondary'" size="md"
        >{{ 'common.confirm' | lang }}
      </cds-button>
    </div>
  </div>
</form>
