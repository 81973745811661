/* eslint-disable no-extra-boolean-cast */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CdsIconConfig } from '@cds/ng-core/icon';

@Component({
  selector: 'app-icon-button',
  templateUrl: './icon-button.component.html',
  styleUrls: ['./icon-button.component.scss'],
})
export class IconButtonComponent implements OnInit {
  @Input() iconCfg: CdsIconConfig = { size: 'sm' };
  @Input() icon = '';
  @Input() buttonText = '';
  @Input() buttonTextClass = 'cds-body2-demibold';
  @Input() hiddenButtonText = false;
  @Input() disabled = false;
  @Input() stopFunCallPropagation = false;
  @Output() funCall = new EventEmitter();
  disabledIconCfg: CdsIconConfig = { color: '#ededed' };

  constructor() {}

  ngOnInit(): void {}

  click(event: any) {
    if (this.disabled) return;
    if (this.stopFunCallPropagation) event.stopPropagation();
    this.funCall.emit();
  }
}
