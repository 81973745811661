<cds-popup class="popup no-close-icon">
  <app-loading *ngIf="isLoading" [overlayInPopup]="true"></app-loading>
  <cds-popup-title>
    <div class="col-xs-12">{{ title }}</div>
    <div class="close-icon" (click)="close()">
      <cds-icon icon="action:cross"></cds-icon>
    </div>
  </cds-popup-title>

  <cds-popup-content>
    <div class="col-xs-12 flex-row">
      <div class="left-icon">
        <cds-icon color="#00A758" width="60px" height="60px" icon="people:login_1"></cds-icon>
      </div>
      <div class="right-content flex-col l-ml-5 justify-content-between">
        <div class="r-name">
          <span class="cds-h4-demibold">{{ paymentTransactionResponse.lastName }}&nbsp;{{ paymentTransactionResponse.firstName }}</span>
          <span class="cds-body1-demibold"> {{ paymentTransactionResponse.lastZhName }}{{ paymentTransactionResponse.firstZhName }} </span>
        </div>
        <div calss="r-code">{{ paymentTransactionResponse.mbAccountCode }}</div>
      </div>
    </div>
    <div class="col-xs-12">
      <div class="p-line"></div>
    </div>
    <div class="col-xs-12 flex-col" *ngFor="let item of this.paymentTransactionResponse.list">
      <div class="m-sub-title m-g">{{ 'ee.fund.referenceNo' | lang }}&nbsp;{{ item.refNo }}</div>
      <div class="m-area m-g">
        <div class="l-f-t">{{ 'ee.fund.createDate' | lang }}</div>
        <div class="l-r-t m-g-l">{{ item.createDate | ssmsDate }}</div>
      </div>
      <div class="m-area m-g">
        <div class="l-f-t">{{ 'ee.fund.effectiveDate' | lang }}</div>
        <div class="l-r-t m-g-l">{{ item.effectiveDate | ssmsDate }}</div>
      </div>
      <div class="m-area m-g">
        <div class="l-f-t">{{ 'ee.fund.originalRequestSubmissionDateAndTime' | lang }}</div>
        <div class="l-r-t m-g-l">{{ item.originalRequestedSubmissionDateAndTime | ssmsDate }}</div>
      </div>
      <div class="m-g">
        <div class="p-line"></div>
      </div>
      <div class="col-xs-12 flex-row">
        <div class="flex-col ac-content justify-content-between">
          <div class="ac-title">{{ 'ee.fund.activityType' | lang }}</div>
          <div calss="ac-value">{{ item.activityType }}</div>
        </div>

        <div class="flex-col ac-content l-ml-10 justify-content-between">
          <div class="ac-title">{{ 'ee.fund.subActivityType' | lang }}</div>
          <div calss="ac-value ">{{ item.subActivityType }}</div>
        </div>

        <div class="flex-col ac-content l-ml-10 justify-content-between">
          <div class="ac-title">{{ 'ee.fund.subAccountType' | lang }}</div>
          <div calss="ac-value ">{{ item.subAccountType }}</div>
        </div>
      </div>
      <div class="m-g">
        <div class="p-line"></div>
      </div>

      <app-cd-accordion [noTopLine]="true">
        <app-cd-accordion-panel
          [title]="titleGen | fn : fundInformationRecord"
          *ngFor="let fundInformationRecord of item.fundInformationRecords"
          rightDesTitle="Unit Price"
          [rightDesValue]="fundInformationRecord.unitPrice | number : '1.2-2'"
          [borderStyle]="true">
          <div class="fund-area">
            <table class="fund-table">
              <tr class="tb-title l-pa-2">
                <td class="b-r"></td>
                <td class="b-r br-title">Fund Unit</td>
                <td class="b-r br-title">Fund Amount</td>
              </tr>
              <tr class="l-pa-2" *ngFor="let fundBaseInfoItem of fundInformationRecord.fundBaseInfo">
                <td class="b-r br-bg br-title">{{ fundBaseInfoItem.fundType }}</td>
                <td class="b-r br-bg">{{ fundBaseInfoItem.fundUnit | number : '1.2-2' }}</td>
                <td class="b-r br-bg">
                  {{ fundBaseInfoItem.fundAmount | number : '1.2-2' }}
                </td>
              </tr>
            </table>
          </div>
        </app-cd-accordion-panel>
      </app-cd-accordion>
    </div>
  </cds-popup-content>
</cds-popup>
