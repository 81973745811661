/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgModel } from '@angular/forms';
import { CdsButtonConfig } from '@cds/ng-core/button';
import { CdsOption } from '@cds/ng-core/configuration';
import { CdsLangService } from '@cds/ng-core/lang';
import { CdsTextfieldConfig } from '@cds/ng-core/textfield';
import { CdsPopupService } from '@cds/ng-web-components/popup';
import { DateTime } from 'luxon';
import moment from 'moment';
import { finalize } from 'rxjs';
import { PermissionAccess, PermissionItem } from 'src/app/core/models/enum/permission.enum';
import { LayoutHeaderService } from 'src/app/core/services/layout-header.service';
import { PermissionService } from 'src/app/core/services/permission.service';
import { AlertPopupComponent } from 'src/app/shared/alert-popup/alert-popup.component';
import { ContinuePopupComponent } from 'src/app/shared/continue-popup/continue-popup.component';
import { IPaginationCount } from 'src/app/shared/pagination/pagination.dto';
import { ToastAlertService } from 'src/app/shared/toast-alert.service';
import { deepCopy } from 'src/app/utils/copy';
import { toPageableRequest } from 'src/app/utils/pagination.utils';
import { AddActivityComponent } from '../add-activity/add-activity.component';
import { Activity, ActivitySearchRequest, ActivitySearchRequestKey, activityTypeAllOptions, ActivityTypeE, I18N_KEY } from '../employer';
import { EmployerService } from '../employer.service';

@Component({
  selector: 'app-activity-records',
  templateUrl: './activity-records.component.html',
  styleUrls: ['./activity-records.component.scss'],
})
export class ActivityRecordsComponent implements OnInit {
  // @ViewChild('activityCode') activityCode!: NgModel;
  // @ViewChild('description') description!: NgModel;
  @ViewChild('createStartDate') createStartDate!: NgModel;
  @ViewChild('createEndDate') createEndDate!: NgModel;
  // @ViewChild('score') score!: NgModel;

  @ViewChild('elementDescription') elementDescription?: NgModel;
  @ViewChild('elementScore') elementScore?: NgModel;

  searchDTO: ActivitySearchRequest = {
    page: 0,
    size: 10,
    activityCode: '',
    description: '',
    createStartDate: '',
    createEndDate: '',
    score: '',
    activityType: ActivityTypeE.ALL,
  };

  searchParams: ActivitySearchRequest = deepCopy(this.searchDTO);

  searchButtonConfig: CdsButtonConfig = {
    size: 'sm',
    // disabled: true,
  };

  resetButtonConfig: CdsButtonConfig = {
    size: 'sm',
    style: 'secondary',
    // disabled: true,
  };

  showDatePicker = true;

  isLoading = false;

  numberOfElements = 0;

  pageCount: IPaginationCount = { current: 1, count: 1 };

  selectedActivityType: ActivityTypeE = ActivityTypeE.ALL;

  activityTypes: CdsOption[] = activityTypeAllOptions;

  displayedColumnsWithoutPermission = ['activityCode', 'description', 'score', 'createdAt'];

  displayedColumns = [...this.displayedColumnsWithoutPermission, 'action'];

  originDataSource: Activity[] = [];

  dataSource: Activity[] = [];

  descriptionTxtfieldConfig: CdsTextfieldConfig = {
    placeholder: 'Input Activity Description',
    type: 'text',
  };

  scoreTxtfieldConfig: CdsTextfieldConfig = {
    placeholder: 'Input Activity Score',
    type: 'number',
  };

  secondaryBtnCfg: CdsButtonConfig = {
    size: 'sm',
    style: 'secondary',
  };

  PermissionAccess = PermissionAccess;

  PermissionItem = PermissionItem;

  I18N_KEY = I18N_KEY;

  canEdit = false;

  nowTimeInstr = DateTime.now().toFormat('dd/MM/yyyy');

  minTime = this.getMinTime();

  minTimeInStr = DateTime.fromJSDate(this.minTime).toFormat('dd/MM/yyyy');

  maxTime = this.getNowTime();

  maxTimeInStr = DateTime.fromJSDate(this.maxTime).toFormat('dd/MM/yyyy');

  searchButtonDisabled = false;

  isAllEmpty = true;

  resetButtonDisabled = true;

  elementSaveButtonDisabled = true;

  elementResetButtonDisabled = true;

  constructor(
    public employerService: EmployerService,
    private cdsPopup: CdsPopupService,
    private langService: CdsLangService,
    private permissionService: PermissionService,
    private toastAlert: ToastAlertService,
    private layoutHeaderService: LayoutHeaderService
  ) {}

  ngOnInit(): void {
    this.layoutHeaderService.doHidenBackButton();
    this.permissionService.hasPermission(PermissionAccess.W, PermissionItem.CUSTOMER_EMPLOYER_ACTIVITY_CODE).then(hasPermission => {
      this.canEdit = hasPermission;
    });

    this.loadTable();
  }

  searchChange(event: any, key: ActivitySearchRequestKey) {
    if (event?.target?.value) {
      this.searchDTO[key] = event?.target?.value;
    } else if (typeof event == 'string' || typeof event == undefined) {
      this.searchDTO[key] = event;
    }
    //const val = event?.target?.value;

    if (
      this.searchDTO.activityCode == '' &&
      this.searchDTO.description == '' &&
      this.searchDTO.createStartDate == '' &&
      this.searchDTO.createEndDate == '' &&
      this.searchDTO.score == ''
    ) {
      this.isAllEmpty = true;
    } else {
      this.isAllEmpty = false;
    }
  }

  getMinTime() {
    return moment().subtract(5, 'year').add(1, 'day').toDate();
  }

  getTime(dateStr: string) {
    return moment(dateStr, 'DD/MM/YYYY').toDate();
  }

  search() {
    if (this.searchButtonDisabled) {
      return;
    }
    if (this.isAllEmpty) {
      return;
    }

    this.pageCount = { current: 1, count: 1 };

    this.searchParams = deepCopy(this.searchDTO);

    this.loadTable();

    this.resetButtonDisabled = false;
  }

  reset() {
    if (this.resetButtonDisabled) {
      return;
    }
    this.selectedActivityType = ActivityTypeE.ALL;
    this.isAllEmpty = true;

    this.searchDTO = {
      page: 0,
      size: 10,
      activityCode: '',
      createStartDate: '',
      createEndDate: '',
      description: '',
      score: '',
      activityType: this.searchDTO.activityType,
    };

    this.searchParams = deepCopy(this.searchDTO);
    // reload DatePicker to avoid select current day bug
    this.showDatePicker = false;
    setTimeout(() => {
      this.showDatePicker = true;
    });

    this.pageCount = { current: 1, count: 1 };

    this.loadTable();
  }

  getRequestParams() {
    let params: ActivitySearchRequest = toPageableRequest(this.pageCount, 10);
    params = {
      ...this.searchParams,
      ...params,
    };

    params.createStartDate = params.createStartDate ? this.dateTrans(params.createStartDate) : '';
    params.createEndDate = params.createEndDate ? this.dateTrans(params.createEndDate) : '';
    params.activityType = this.selectedActivityType;
    return params;
  }

  dateTrans(date: string) {
    if (date) {
      return moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD');
    }

    return date;
  }

  loadTable() {
    const params: ActivitySearchRequest = this.getRequestParams();
    this.isLoading = true;
    this.employerService
      .getActivityList(params)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe(data => {
        if (data.result === 0 && data.data) {
          this.isLoading = false;
          this.dataSource = data.data.empty ? [] : data.data.content;
          this.originDataSource = deepCopy(this.dataSource);
          this.numberOfElements = data.data.totalElements;
          this.pageCount.current = data.data.number + 1;
          this.pageCount.count = data.data.totalPages;
          this.pageCount = { ...this.pageCount };
        }
      });
  }

  pillSelectedChange(selected: boolean, type: ActivityTypeE) {
    if (selected) {
      if (this.selectedActivityType !== type) {
        this.selectedActivityType = type;
        this.loadTable();
      }
    }
  }
  add() {
    this.cdsPopup
      .open(AddActivityComponent, {
        size: 'md',
      })
      .afterClosed()
      .subscribe(result => {
        if (result?.agree) {
          this.employerService
            .addActivity(result.formValue)
            .pipe(
              finalize(() => {
                this.isLoading = false;
              })
            )
            .subscribe(res => {
              if (res.result === 0) {
                this.popupWithReload();
              }
            });
        } else {
          this.isLoading = false;
        }
      });
  }

  edit(activityCode: string) {
    const alreadyInEditItem = this.dataSource.find(item => item.activityCode !== activityCode && item.inEdit);
    if (alreadyInEditItem) {
      const alreadyInEditItemActivityCode = alreadyInEditItem.activityCode as string;
      // this.cdsPopup
      //   .open(ContinuePopupComponent, {
      //     size: 'sm',
      //     data: { message: this.langService.translate('common.action.cancel') },
      //   })
      //   .afterClosed()
      //   .subscribe(result => {
      //     if (result?.agree) {
      //       this.changeEditStaus(activityCode);
      //     }
      //   });
      const lineData = this.getLineData(alreadyInEditItemActivityCode, this.dataSource);
      const originLineData = this.getLineData(alreadyInEditItemActivityCode, this.originDataSource);
      if (lineData && originLineData) {
        lineData.description = originLineData.description;
        lineData.score = originLineData.score;
      }
      this.changeEditStaus(activityCode);
    } else {
      this.changeEditStaus(activityCode);
    }
    // this.changeEditStaus(activityCode);
  }

  changeEditStaus(activityCode: string) {
    this.dataSource.forEach(item => {
      if (item.activityCode === activityCode) {
        item.inEdit = true;
      } else {
        item.inEdit = false;
      }
    });

    this.dataSource = [...this.dataSource];
  }

  delete(id: string, activityCode: string) {
    this.cdsPopup
      .open(ContinuePopupComponent, {
        size: 'sm',
        data: {
          title: 'Are you sure you want to delete this activity ?',
          continueButtonName: this.langService.translate('common.delete'),
        },
      })
      .afterClosed()
      .subscribe(result => {
        if (result?.agree) {
          this.isLoading = true;
          this.employerService
            .deleteActivity(id)
            .pipe(
              finalize(() => {
                this.isLoading = false;
              })
            )
            .subscribe({
              next: res => {
                if (res.result === 0) {
                  this.popupWithReload();
                } else if (res.result === 2) {
                  this.deleteError(activityCode);
                } else {
                  this.toastAlert.show('error', 'common.error', res.message, 5000);
                }
              },
            });
        } else {
          this.isLoading = false;
        }
      });
  }

  deleteError(activityCode: string) {
    this.cdsPopup
      .open(AlertPopupComponent, {
        size: 'sm',
        data: {
          message: this.langService.translate('employer.delete-error'),
          buttonName: this.langService.translate('common.gotItWithExclamation'),
        },
      })
      .afterClosed()
      .subscribe(() => {
        this.resetDataSource(activityCode, true);
      });
  }

  save = (id: string, activityCode: string, version: number) => {
    if (this.elementSaveButtonDisabled) {
      return;
    }
    this.doSave(id, activityCode, version);
  };

  doSave(id: string, activityCode: string, version: number) {
    const lineData = this.getLineData(activityCode, this.dataSource);
    const params: Activity = {
      id,
      description: lineData?.description,
      score: lineData?.score,
      version,
    };
    this.isLoading = true;

    this.employerService
      .editActivity(params)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe({
        next: res => {
          if (res.result === 0) {
            if (lineData) {
              lineData.inEdit = false;
            }
            this.popupWithReload();
          } else {
            this.toastAlert.show('error', 'common.error', res.message, 5000);
          }
        },
      });
  }

  ifResetButtonDisabled = (activityCode: string, description: string, score: number) => {
    const originLineData = this.getLineData(activityCode, this.originDataSource);
    if (originLineData && description === originLineData.description && score === originLineData.score) {
      this.elementResetButtonDisabled = true;
      return true;
    }
    this.elementResetButtonDisabled = false;
    return false;
  };

  getLineData(activityCode: string, dataSource: Activity[]) {
    if (!activityCode || !dataSource) {
      return null;
    }
    const lineData = dataSource.find(lineData => lineData.activityCode === activityCode);
    return lineData;
  }

  ifSaveButtonDisabled = (activityCode: string, description: string, score: number, descriptionValid: boolean, scoreValid: boolean) => {
    const ifResetButtonDisabled = this.ifResetButtonDisabled(activityCode, description, score);
    if (ifResetButtonDisabled) {
      this.elementSaveButtonDisabled = true;
      return true;
    }
    if (description && score && descriptionValid && scoreValid) {
      this.elementSaveButtonDisabled = false;
      return false;
    }
    this.elementSaveButtonDisabled = true;
    return true;
  };

  popupWithReload() {
    this.cdsPopup
      .open(AlertPopupComponent, {
        size: 'sm',
        data: {
          message: this.langService.translate('common.action.success'),
          buttonName: this.langService.translate('common.gotItWithExclamation'),
        },
      })
      .afterClosed()
      .subscribe(() => {
        this.loadTable();
      });
  }

  resetLine = (activityCode: string, description: string, score: number) => {
    const ifResetButtonDisabled = this.ifResetButtonDisabled(activityCode, description, score);
    if (ifResetButtonDisabled) {
      return;
    }
    this.doReset(activityCode);
  };

  doReset(activityCode: string) {
    this.cdsPopup
      .open(ContinuePopupComponent, {
        size: 'sm',
        data: { message: this.langService.translate('common.action.cancel') },
      })
      .afterClosed()
      .subscribe(result => {
        if (result?.agree) {
          this.resetDataSource(activityCode);
        }
      });
  }

  resetDataSource(activityCode: string, resetInEdit = false) {
    const lineData = this.getLineData(activityCode, this.dataSource);
    const originLineData = this.getLineData(activityCode, this.originDataSource);
    if (lineData && originLineData) {
      lineData.inEdit = true;
      lineData.description = originLineData.description;
      lineData.score = originLineData.score;
      if (resetInEdit) {
        lineData.inEdit = false;
      }
      this.dataSource = [...this.dataSource];
    }
  }

  pageChange(currentPage: number) {
    this.pageCount.current = currentPage;
    this.loadTable();
  }

  getNowTime() {
    return moment().toDate();
  }

  getMaxFromDate = (toDateStr?: string) => {
    if (!toDateStr) {
      return this.getNowTime();
    }
    return moment(toDateStr, 'DD/MM/YYYY').toDate();
  };

  getMinToDate = (fromDateStr = '01/01/0000') => {
    return moment(fromDateStr, 'DD/MM/YYYY').toDate();
  };

  searchButtonDisabledFn = (activityCodeValid: any, description: any, createStartDate: any, createEndDate: any, score: any, isAllEmpty: any) => {
    if (isAllEmpty) {
      this.resetButtonDisabled = true;
      return true;
    } else {
      const invalid = [activityCodeValid, description, createStartDate, createEndDate, score].some(item => !item);
      this.searchButtonDisabled = invalid;

      return this.searchButtonDisabled;
    }
  };

  scoreChange(value: any, element: any) {
    if (typeof value !== 'string') return;

    element.score = Math.round(Math.abs(Number(value))).toString();
  }
}
