import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CdsIconConfig } from '@cds/ng-core/icon';
import { ActionIcons } from '@cds/ng-themes';

@Component({
  selector: 'app-cd-back-text',
  templateUrl: './cd-back-text.component.html',
  styleUrls: ['./cd-back-text.component.scss'],
})
export class CdBackTextComponent implements OnInit {
  @Input() title?: string;
  @Output() backCall = new EventEmitter<void>();
  backIconConfig: CdsIconConfig = {
    color: 'cta',
    size: 'sm',
  };
  backIcon = ActionIcons.button_left_filled;

  constructor() {}

  ngOnInit(): void {}

  onBack() {
    this.backCall.emit();
  }
}
