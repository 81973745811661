import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cd-multiple-accordion',
  templateUrl: './cd-multiple-accordion.component.html',
  styleUrls: ['./cd-multiple-accordion.component.scss'],
})
export class CdMultipleAccordionComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
