/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Inject } from '@angular/core';
import { MatDialogRef, CDS_POPUP_DATA } from '@cds/ng-web-components/popup';
import { CdsButtonConfig } from '@cds/ng-core/button';

@Component({
  selector: 'app-user-agreement-popup',
  templateUrl: './user-agreement-popup.component.html',
  styleUrls: ['./user-agreement-popup.component.scss'],
})
export class UserAgreementPopupComponent {
  disagreeButtonConfig: CdsButtonConfig = { size: 'sm', style: 'secondary' };
  agreeButtonConfig: CdsButtonConfig = { size: 'sm' };

  constructor(
    private dialogRef: MatDialogRef<UserAgreementPopupComponent>,
    @Inject(CDS_POPUP_DATA)
    public data: {
      title: string;
      message: string;
      cancel: string;
      continue: string;
      type: string;
      params: any;
      actionRight: boolean;
    }
  ) {
    if (data.params) this.params = data.params;
  }
  params = {};

  agree() {
    this.dialogRef.close({ agree: true });
  }
  disagree() {
    this.dialogRef.close({ agree: false });
  }
}
