import { trigger, state, style, transition, animate } from '@angular/animations';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CdsDropdownConfig } from '@cds/ng-core/dropdown';
import { CdsIconConfig } from '@cds/ng-core/icon';
import { deepCopy } from 'src/app/utils/copy';
import { agentFollowUpStatusDropdownOptions, AgentFollowUpStatusE, noOfContactOptions, TerminationRecord } from '../../termination';
import { TerminationService } from '../../termination.service';

@Component({
  selector: 'app-expand-item-mobile',
  templateUrl: './expand-item-mobile.component.html',
  styleUrls: ['./expand-item-mobile.component.scss'],
  animations: [
    trigger('openClose', [
      state(
        'detail-expaned',
        style({
          height: '*',
          paddingTop: 'var(--cds-spacing-03)',
          opacity: '1',
        })
      ),
      state(
        'detail-collapsed',
        style({
          height: '0px',
          overflow: 'hidden',
          opacity: '0',
        })
      ),
      state(
        'expanded',
        style({
          rotate: '90deg',
        })
      ),
      state(
        'collapsed',
        style({
          rotate: '-90deg',
        })
      ),
      transition('detail-expaned <=> detail-collapsed', [animate('0.3s ease-in-out')]),
      transition('expanded <=> collapsed', [animate('0.3s')]),
    ]),
  ],
})
export class ExpandItemMobileComponent implements OnInit {
  noOfContactConfig: CdsDropdownConfig = {
    label: '',
    options: noOfContactOptions,
  };

  clearIconConfig: CdsIconConfig = {
    size: 'sm',
    color: '#8e90a2',
  };
  constructor(public service: TerminationService) {}

  @Input() expand = false;
  @Input() icon = 'action:button_right';
  @Input() color = '';
  @Input() mouseColor = '#EC6453';
  @Input() size = '32px';
  @Input() data: TerminationRecord = {};

  @Output() statusChange = new EventEmitter<TerminationRecord>();

  _config: CdsIconConfig = {
    color: this.color,
  };
  _iconClass = 'collapsed';
  _detailClass = 'detail-collapsed';

  dropdownConfig: CdsDropdownConfig = {
    label: '',
    options: agentFollowUpStatusDropdownOptions,
  };

  dropdownConfig2: CdsDropdownConfig = {
    label: '',
    options: agentFollowUpStatusDropdownOptions.filter(item => item.value !== AgentFollowUpStatusE.COMPLETED),
  };

  ngOnInit(): void {}

  askedLientChange(value: boolean, data: TerminationRecord) {
    if (value !== data.askClient) {
      const dataTmp: TerminationRecord = deepCopy(data);
      dataTmp.askClient = value;
      if (!value && dataTmp.agentFollowUpStatus === AgentFollowUpStatusE.COMPLETED) {
        dataTmp.agentFollowUpStatus = AgentFollowUpStatusE.OUTSTANDING;
      }
      this.statusChange.emit(dataTmp);
    }
  }

  noOfContactChange(value: string, data: TerminationRecord) {
    if (!!value && value !== data.numberOfContact) {
      const dataTmp = deepCopy(data);
      dataTmp.numberOfContact = value;
      this.statusChange.emit(dataTmp);
    }
  }

  agentFollowUpStatusChange(value: AgentFollowUpStatusE, data: TerminationRecord) {
    if (!!value && value !== data.agentFollowUpStatus) {
      const dataTmp = deepCopy(data);
      dataTmp.agentFollowUpStatus = value;
      this.statusChange.emit(dataTmp);
    }
  }

  trigger() {
    this.expand = !this.expand;
    this._detailClass = this.expand ? 'detail-expaned' : 'detail-collapsed';
    this._iconClass = this.expand ? 'expanded' : 'collapsed';
  }

  clearDropdown(data: TerminationRecord) {
    if (!data.numberOfContact) return;
    const dataTmp: TerminationRecord = deepCopy(data);
    dataTmp.numberOfContact = '0';
    dataTmp.askClient = false;
    if (dataTmp.agentFollowUpStatus === AgentFollowUpStatusE.COMPLETED) {
      dataTmp.agentFollowUpStatus = AgentFollowUpStatusE.OUTSTANDING;
    }
    this.statusChange.emit(dataTmp);
  }

  clearDropdown2(data: TerminationRecord) {
    const dataTmp: TerminationRecord = deepCopy(data);
    dataTmp.agentFollowUpStatus = AgentFollowUpStatusE.OUTSTANDING;
    this.statusChange.emit(dataTmp);
  }
}
