<div class="col-xs l-plr-0 l-pt-2 l-pb-2">
  <ng-container *ngIf="!labelRef">
    <div *ngIf="toolTipString !== ''" class="col-xs row l-plr-0 cds-color-navy-light3 middle-xs">
      <div class="l-mr-2">
        <span class="cds-body2">{{ label | lang }}</span>
      </div>
      <cds-icon
        class="icon-margin"
        icon="action:info_1"
        (mouseenter)="onCanDelegateAmtToggle($event)"
        (mouseleave)="onCanDelegateAmtToggle($event)"
        [cdsTooltip]="toolTipString"></cds-icon>
    </div>
    <div *ngIf="toolTipString === ''" class="cds-color-navy-light3">
      <span class="cds-body2">{{ label | lang }}</span>
    </div>
  </ng-container>

  <!-- custom label -->
  <div *ngIf="!!labelRef" class="l-mt-1 min-height">
    <ng-container [ngTemplateOutlet]="labelRef.templateRef"></ng-container>
  </div>

  <ng-container *ngIf="!contentRef">
    <div *ngIf="icon !== undefined" (click)="call()" class="row l-plr-0 l-mt-1 pointer">
      <span class="cds-body1 l-mb-0">{{ data }}</span>
      <span class="l-mr-2"></span>
      <cds-icon class="amount-icon" width="20px" height="20px" [color]="iconColor" [icon]="icon"></cds-icon>
    </div>
    <div *ngIf="icon === undefined && checked === undefined" class="l-mt-1">
      <span class="cds-body1">{{ data || '&nbsp;' }}</span>
    </div>
    <div *ngIf="icon === undefined && checked !== undefined" class="l-mt-1">
      <cds-checkbox *ngIf="!checked" [config]="noCheckConfig"></cds-checkbox>
      <cds-checkbox *ngIf="checked" [config]="checkedConfig"></cds-checkbox>
    </div>
  </ng-container>

  <!-- custom content -->
  <div *ngIf="!!contentRef" class="l-mt-1 min-height">
    <ng-container [ngTemplateOutlet]="contentRef.templateRef"></ng-container>
  </div>
</div>
