import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CdsLanguageModule } from '@cds/ng-core/lang';
import { CdsRadioModule } from '@cds/ng-web-components/radio';

import { CdRadioComponent } from './cd-radio.component';

@NgModule({
  imports: [CommonModule, FormsModule, ReactiveFormsModule, CdsLanguageModule, CdsRadioModule],
  declarations: [CdRadioComponent],
  exports: [CdRadioComponent],
})
export class CdRadioModule {}
