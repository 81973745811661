<div class="row">
  <div class="col-xs-12 l-pt-5">
    <span class="cds-h2">{{ 'list.search-record' | lang }}</span>
  </div>
</div>

<div class="row" [formGroup]="form">
  <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3 l-pt-4 l-pb-4">
    <cds-textfield
      class="col-xs l-plr-0"
      formControlName="uploadDate"
      placeholder="DD/MM/YYYY"
      label="Upload Date"
      [(ngModel)]="searchDTO.uploadDate"
      (ngModelChange)="validating()"
      [cdsDatepicker]="picker3">
    </cds-textfield>
    <cds-datepicker #picker3="cdsDatepicker"></cds-datepicker>
    <cds-assistive-text class="l-pt-3" *ngIf="form.get('uploadDate')?.invalid">{{ 'create.dateError' | lang }} </cds-assistive-text>
  </div>
  <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3 l-pt-4 l-pb-4">
    <cds-textfield
      formControlName="fileName"
      [(ngModel)]="searchDTO.fileName"
      (input)="inputChange(fileName.value, 'fileName')"
      #fileName
      label="{{ 'scb.file-name' | lang }}"
      placeholder="{{ 'scb.file-name-input' | lang }}"></cds-textfield>
  </div>

  <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 l-mt-4 l-pb-4 bottom-xs l-pt-2">
    <cds-button class="l-mr-4 botton" [config]="searchButtonConfig" label="{{ 'list.search' | lang }}" (click)="search()"></cds-button>
    <cds-button class="botton" [config]="resetButtonConfig" label="{{ 'list.reset' | lang }}" (click)="reset()"></cds-button>
  </div>
</div>

<div class="row between-xs middle-xs l-pt-4">
  <div class="col-xs-6 row l-plr-0">
    <div class="col-xs-12">
      <span class="cds-h2-light l-ma-0">{{ 'common.recordList' | lang }}</span>
    </div>
    <div class="col-xs-12">
      <span class="cds-detail1-demibold">Total {{ pageConfig.totalCount }} records</span>
    </div>
  </div>
  <cds-button [config]="uploadButtonConfig" [disabled]="!_canUpload" label="{{ 'scb.upload-statement' | lang }}" (click)="uploadTrigger()"></cds-button>
</div>

<div class="row">
  <div class="col-xs-12 l-pt-4">
    <app-data-table
      [frontPagination]="false"
      [isLoading]="_progress"
      [pageConfig]="pageConfig"
      [fixed]="false"
      [overlay]="true"
      [dataSource]="dataList"
      [columnsConfig]="columnsConfig"
      (reloadTable)="reloadTable($event)">
      <ng-template appDtBodyCellDef="uploadDate" let-element>
        {{ element.uploadDate | ssmsDate }}
      </ng-template>
    </app-data-table>
  </div>
</div>
