import { CdsOption } from '@cds/ng-core/configuration';

/* eslint-disable @typescript-eslint/no-explicit-any */
export const numberHandler = (n: number): string => {
  if (n < 0) return 'error';
  if (n > 100) return 'error';
  if (n === 100) return 'S+';
  if (n > 97) return 'S1';
  if (n > 94) return 'S2';
  if (n > 90) return 'S3';
  if (n > 85) return 'A+1';
  if (n > 80) return 'A+2';
  if (n > 75) return 'A1';
  if (n > 70) return 'A2';
  if (n > 65) return 'B+1';
  if (n > 60) return 'B+2';
  if (n > 50) return 'B';
  if (n > 40) return 'C+';
  if (n > 30) return 'C';
  if (n > 20) return 'D+';
  if (n > 10) return 'D';
  if (n > 5) return 'E';
  return 'E-';
};

/**
 * convert response text with numbers to objects
 * @param source response text
 * @param fields number fields array
 * @returns
 */
export function str2Obj(source: string, fields: string[]) {
  let obj: any = {};
  if (!source) return;
  fields.forEach(field => {
    if (!field) return;
    const regStr = '(' + field + '\\s*"\\s*:\\s*)(\\d+\\.?\\d+)(\\s*,?)';
    const reg = new RegExp(regStr, 'g');
    source = source.replace(reg, '$1"$2"$3');
  });
  try {
    obj = JSON.parse(source);
  } catch (Error) {
    console.error('str2Obj failed: source string is not a valid object string.');
  }

  return obj;
}

export function agentCodeRemovePrefix0(agentCode: string) {
  let code = '';
  let need = false;
  agentCode.split('').forEach(element => {
    if (!need && element !== '0') {
      need = true;
    }

    if (need) {
      code += element;
    }
  });

  return code;
}

export function agentCodeAddPrefix0(agentCode: string) {
  if (agentCode && agentCode.length < 6) {
    const len = 6 - agentCode.length;
    for (let i = 0; i < len; i++) {
      agentCode = '0' + agentCode;
    }
  }

  return agentCode;
}

export function findOptionLabelByValue(value: any, options: CdsOption[]) {
  let label = '';
  if ((!value && value !== 0) || !options || (options && options.length === 0)) return label;

  const obj = options.find(x => x.value === value);
  label = obj?.label || '';
  return label;
}

export function pageToTop() {
  const navigationContent = document.querySelector('.cds-navigation-content');
  if (navigationContent) {
    navigationContent.scrollTop = 0;
  }
}

export function numRanking(n: number) {
  let result = 'N/A';
  if (n < 1) {
    return result;
  }
  n = Math.floor(n);
  const endN = n % 10;
  switch (endN) {
    case 1:
      if (n > 10 && n < 20) {
        result = n + 'th';
      } else {
        result = n + 'st';
      }
      break;
    case 2:
      if (n > 10 && n < 20) {
        result = n + 'th';
      } else {
        result = n + 'nd';
      }
      break;
    case 3:
      if (n > 10 && n < 20) {
        result = n + 'th';
      } else {
        result = n + 'rd';
      }
      break;
    default:
      result = n + 'th';
      break;
  }
  return result;
}

export function cdsPopupBackdropOntouchmovePreventDefault() {
  const cs = document.getElementsByClassName('cds-popup-backdrop');
  if (cs && cs.length) {
    const c = cs.item(0);
    const d = c as HTMLDivElement;
    d.ontouchmove = e => {
      e.preventDefault();
    };
  }
}

export function cdsPopupBackdropUnOntouchmovePreventDefault() {
  const cs = document.getElementsByClassName('cds-popup-backdrop');
  if (cs && cs.length) {
    const c = cs.item(0);
    const d = c as HTMLDivElement;
    d.ontouchmove = null;
  }
}

export function getFilterParams(requestParams: any) {
  const params: any = {};
  for (const key in requestParams) {
    if (Object.prototype.hasOwnProperty.call(requestParams, key)) {
      const val = (requestParams as any)[key];
      if (val !== undefined && val !== null && val !== '' && !(Object.prototype.toString.call(val) === '[object Array]' && val.length === 0)) {
        if (typeof val === 'string') {
          params[key] = val.trim();
        } else {
          params[key] = val;
        }
      }
    }
  }

  return params;
}

export function keepDecimalDigitFull(value: number, decimalDigit: number) {
  const tempN = Math.pow(10, decimalDigit);
  let s_x = (Math.round(value * tempN) / tempN).toString();
  let pos_decimal = s_x.indexOf('.');

  if (pos_decimal < 0) {
    pos_decimal = s_x.length;
    s_x += '.';
  }
  while (s_x.length <= pos_decimal + decimalDigit) {
    s_x += '0';
  }
  return s_x;
}

export function trimObjectStringValues(obj: Record<string, any>): Record<string, any> {
  const trimmedObj: Record<string, any> = {};

  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      if (typeof obj[key] === 'string') {
        trimmedObj[key] = obj[key].trim();
      } else {
        trimmedObj[key] = obj[key];
      }
    }
  }

  return trimmedObj;
}

export function paramsObj2paramsUrlStr(params: Record<string, any>): string {
  Object.keys(params).forEach(key => (params[key] === undefined || params[key] === '') && delete params[key]);

  let paramsStr = '';

  Object.keys(params).forEach((key, index) => {
    paramsStr = `${paramsStr}${index === 0 ? '?' : '&'}${key}=${params[key]}`;
  });

  return paramsStr;
}

export function copyText(value: string) {
  if (value === undefined) return;
  const input = document.createElement('input');
  input.style.opacity = '0';
  input.style.position = 'fixed';
  input.style.left = '-100%';
  input.style.top = '-100%';
  input.value = value;
  document.body.appendChild(input);
  input.select();
  document.execCommand('copy');
  document.body.removeChild(input);
}
