/* eslint-disable @typescript-eslint/no-explicit-any */
function find(list: Array<any>, f: any) {
  return list.filter(f)[0];
}

type _typeObj = any | [] | Date;

export function deepCopy(obj: _typeObj, cache?: Array<any>) {
  if (cache === void 0) cache = [];

  if (obj === null || typeof obj !== 'object') {
    return obj;
  }

  const Constructor: any = obj.constructor;
  if (Constructor === RegExp) {
    return new Constructor(obj);
  }
  if (Constructor === Date) {
    return new Constructor(obj);
  }

  const hit = find(cache, function (c: any) {
    return c.original === obj;
  });
  if (hit) {
    return hit.copy;
  }

  const copy: any = Array.isArray(obj) ? [] : {};
  cache.push({
    original: obj,
    copy: obj,
  });

  Object.keys(obj).forEach(key => {
    const _key = key as keyof typeof obj;
    copy[_key] = deepCopy(obj[_key], cache);
  });

  return copy;
}
