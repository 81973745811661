<div class="l-pb-4">
  <div class="row l-plr-0 cds-white div-border-top div-border-bottom">
    <div class="col-xs-2 col-md-2 l-pb-2 l-pt-2 l-pl-4 l-pr-4"></div>
    <div class="col-xs-9 col-md-9 l-pb-2 l-pt-2 l-pl-4 l-pr-4">{{ 'calendar.event.date' | lang }}<span class="cds-coral-text dark-1">*</span></div>
  </div>
  <form [formGroup]="formGroup">
    <div class="row l-plr-0 div-border-bottom">
      <div class="col-xs-2 col-md-2 l-pt-4 l-pb-4 l-pl-4 l-pr-4 l-d-f l-ai-cen">1</div>
      <div class="col-xs-9 col-md-9 l-pt-3 l-pl-4 l-pr-8">
        <cds-textfield placeholder="Input Date" [cdsDatepicker]="picker" formControlName="eventDate"> </cds-textfield>
        <cds-datepicker #picker="cdsDatepicker"></cds-datepicker>
        <div style="min-height: 32px">
          <cds-assistive-text class="l-pt-3" *ngIf="eventDate?.errors && eventDate?.dirty">
            {{ eventDate?.errors?.['error'] | lang}}
          </cds-assistive-text>
        </div>
      </div>
    </div>
  </form>
</div>
