<app-loading [overlay]="true" *ngIf="isLoading"></app-loading>

<div class="row l-pt-6 l-plr-0">
  <div class="col-xs-12 row middle-xs basic-pad">
    <div class="l-mr-2">
      <cds-icon color="#282B3E" width="20px" height="20px" icon="environment:office_building"></cds-icon>
    </div>
    <div>
      <span class="cds-body-demibold">{{ 'ee.company-information' | lang }}</span>
    </div>
  </div>

  <div class="col-xs-12 row bg-white l-pt-2 l-pb-7">
    <div class="col-xs-12 row">
      <div class="col-xs-12 l-pt-4 l-mb-4">
        <div class="cds-body-hero-demibold bold l-mb-4">
          <span class="l-mr-4">{{ data.companyInfo?.companyEnglishName }}</span>
          <span>{{ data.companyInfo?.companyChineseName }}</span>
        </div>
        <div class="type-and-status">
          <span class="type l-mr-4" *ngIf="data.companyInfo?.companyType">{{ data.companyInfo?.companyType }}</span>
          <span *ngIf="data.companyInfo?.statusCode"
            ><app-icon-status [value]="data.companyInfo?.statusCode" [statusMapObj]="employerStatusMapObj"></app-icon-status
          ></span>
        </div>
      </div>

      <app-row-item
        label="er.profile.certificate-incorporation-number"
        [data]="data.companyInfo?.certificateOfIncorporationNumber"
        class="col-xs-6 col-sm-3 col-md-3 col-lg-3">
      </app-row-item>
      <app-row-item label="er.profile.data-of-inco" [data]="data.companyInfo?.dateOfIncorporation | ssmsDate" class="col-xs-6 col-sm-3 col-md-3 col-lg-3">
      </app-row-item>
      <app-row-item label="er.profile.place-incorporation" [data]="data.companyInfo?.placeOfIncorporation" class="col-xs-6 col-sm-3 col-md-3 col-lg-3">
      </app-row-item>
      <app-row-item
        label="er.profile.commencement-date"
        [data]="data.companyInfo?.commencementDate | ssmsDate"
        class="col-xs-6 col-sm-3 col-md-3 col-lg-3"></app-row-item>
      <app-row-item
        label="er.profile.registration-type"
        [data]="this.service.getRegistrationType(data.companyInfo?.registrationType)"
        class="col-xs-6 col-sm-3 col-md-3 col-lg-3">
      </app-row-item>
      <app-row-item label="agent.mpf-registration-number" [data]="data.companyInfo?.registrationNumber" class="col-xs-6 col-sm-3 col-md-3 col-lg-3">
      </app-row-item>
      <app-row-item label="account.branch-number" [data]="data.companyInfo?.branchNumber" class="col-xs-6 col-sm-3 col-md-3 col-lg-3"> </app-row-item>
    </div>
  </div>

  <ng-container *ngIf="data.showEmploymentInfo">
    <div class="col-xs-12 cds-body1 bold l-mt-4 l-mb-0">Employment Information</div>

    <div class="col-xs-9 p-line"></div>

    <div class="col-xs-9 row l-plr-0">
      <app-row-item label="ee.profile.Employee-Type" [data]="data.employmentInfo?.employeeType" class="col-xs-6 col-sm-3 col-md-3 col-lg-3"> </app-row-item>
      <app-row-item
        label="ee.original-employment-date"
        [data]="data.employmentInfo?.originalEmploymentDate | ssmsDate"
        class="col-xs-6 col-sm-3 col-md-3 col-lg-3"></app-row-item>
      <app-row-item
        label="ee.date-of-employment"
        [data]="data.employmentInfo?.dateOfEmployment | ssmsDate"
        class="col-xs-6 col-sm-3 col-md-3 col-lg-3"></app-row-item>
      <app-row-item
        label="ee.opt.Last-Date-of-Employment"
        [data]="data.employmentInfo?.lastDateOfEmployment | ssmsDate"
        class="col-xs-6 col-sm-3 col-md-3 col-lg-3"></app-row-item>
      <app-row-item
        label="ee.expatriate-visa-issue-date"
        [data]="data.employmentInfo?.expartriateVisaIssueDate | ssmsDate"
        class="col-xs-6 col-sm-3 col-md-3 col-lg-3"></app-row-item>
      <app-row-item label="ee.department-code" [data]="data.employmentInfo?.departmentCode" class="col-xs-6 col-sm-3 col-md-3 col-lg-3"> </app-row-item>
      <app-row-item label="ee.staff-number" [data]="data.employmentInfo?.staffNumber" class="col-xs-6 col-sm-3 col-md-3 col-lg-3"> </app-row-item>
    </div>
  </ng-container>

  <div class="col-xs-12 cds-color-green l-mt-6">
    <span class="cds-h4-demibold">{{ 'ee.vesting-rules' | lang }}</span>
  </div>
  <div class="col-xs-12">
    <div class="p-line"></div>
  </div>
  <div class="col-xs-12 rule-container">
    <span class="cds-body2 l-mr-4">Employer grade/member class:</span>
    <div class="manage-button" (click)="goToVesting(data)">
      <cds-icon color="#ec6453" icon="action:button_right_outlined" class="l-mr-3"></cds-icon>
      <span class="cds-body2 bold">Manage</span>
    </div>
  </div>

  <div class="col-xs-12 cds-color-green l-mt-6">
    <span class="cds-h4-demibold">{{ 'ee.voluntary-contribution-rules' | lang }}</span>
  </div>
  <div class="col-xs-12">
    <div class="p-line"></div>
  </div>
  <div class="col-xs-12 rule-container">
    <span class="cds-body2 l-mr-4">Employer grade/member class:</span>
    <div class="manage-button" (click)="goToVesting(data)">
      <cds-icon color="#ec6453" icon="action:button_right_outlined" class="l-mr-3"></cds-icon>
      <span class="cds-body2 bold">Manage</span>
    </div>
  </div>
</div>
