/* eslint-disable @typescript-eslint/no-explicit-any */
import { AbstractControl, ValidationErrors } from '@angular/forms';

export const fsHKPCValidator = (errors?: ValidationErrors) => {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value?.trim();
    if (value) {
      return errors || { agentCodeValidator: true };
    }
    return null;
  };
};
