import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { CdsPopupService } from '@cds/ng-web-components/popup';
import { CdRadioComponent, CdRadioConfig } from 'src/app/shared/cd-radio/cd-radio.component';
import { SentEiffPopupComponent } from './sent-eiff-popup/sent-eiff-popup.component';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { PreferredLanguageType } from '../case-summary/case-summary.model';
import { CdsDropdownConfig } from '@cds/ng-core/dropdown';
import { empfCountryOption } from '../case-summary/declaration-iff-er/empf-declaration-form.config';
import { clientPhoneNumberVali } from 'src/app/core/validators/common.validator';

@Component({
  selector: 'app-new-resend-eiff',
  templateUrl: './new-resend-eiff.component.html',
  styleUrls: ['./new-resend-eiff.component.scss'],
})
export class NewResendEiffComponent implements OnInit {
  @ViewChild('cdRadioPreferredLanguage') cdRadioPreferredLanguage?: CdRadioComponent;
  PreferredLanguageType = PreferredLanguageType;
  regulationConfig: CdRadioConfig = {
    options: [
      {
        label: '是',
        value: 'Y',
        disable: true,
      },
      {
        label: '否',
        value: 'N',
        disable: true,
      },
    ],
  };
  secondIntermediaryContactInformationConfig: CdRadioConfig = {
    options: [
      {
        label: '已登記的電郵地址(wink******@manulife.com)',
        value: 'E',
        disable: false,
      },
      {
        label: '已登記的手機號碼 (+852) 6188 ****',
        value: 'M',
        disable: false,
      },
    ],
  };
  secondIntermediaryLangConfig: CdRadioConfig = {
    options: [
      {
        label: '中文',
        value: 'zh',
        disable: false,
      },
      {
        label: '英文',
        value: 'en',
        disable: false,
      },
    ],
  };
  secondIntermediaryLang = 'en';
  secondIntermediaryContactInformation = 'M';
  regulation = 'Y';
  isShowAllInfo = false;
  isEdit = false;
  iffForm!: FormGroup;
  preferredLanguageConfig = {
    options: [
      {
        label: 'common.chinese',
        value: PreferredLanguageType.Chinese,
        disable: true,
      },
      {
        label: 'common.english',
        value: PreferredLanguageType.English,
        disable: true,
      },
    ],
  };
  countrycodeConfig: CdsDropdownConfig = {
    options: empfCountryOption,
    placeholder: '+852 (HK)',
    color: 'ml',
  };
  step = 1;
  oldCountryCode = '852';
  oldPhoneNo = '87878787';
  oldEmail = 'abc@gmail.com';

  get showCountryCode() {
    return '+' + (this.countryCode?.value || this.oldCountryCode);
  }

  get showPhoneNo() {
    return this.phoneNo?.value || this.oldPhoneNo;
  }

  get showEmail() {
    return this.email?.value || this.oldEmail;
  }

  get contactType() {
    return this.iffForm.get('contactType') as FormControl;
  }

  get email() {
    return this.iffForm.get('email') as FormControl;
  }

  get phoneNo() {
    return this.iffForm.get('phoneNo') as FormControl;
  }

  get countryCode() {
    return this.iffForm.get('countryCode') as FormControl;
  }

  get preferredLanguage() {
    return this.iffForm.get('preferredLanguage') as FormControl;
  }

  constructor(private dialogRef: MatDialogRef<NewResendEiffComponent>, private cdsPopup: CdsPopupService) {}

  ngOnInit(): void {
    this.iffForm = new FormGroup({
      contactType: new FormControl('M', [Validators.required]),
      preferredLanguage: new FormControl(PreferredLanguageType.English, [
        (control: AbstractControl): ValidationErrors | null => {
          if (
            (this.iffForm?.get('contactType')?.value === 'M' && control.value) ||
            this.iffForm?.get('contactType')?.value === 'E' ||
            !this.iffForm?.get('contactType')?.value
          ) {
            return null;
          }
          return { formatError: true };
        },
      ]),
    });
  }

  clientCountryCodeChange() {
    this.phoneNo.updateValueAndValidity();
  }

  onContactTypeChange(val: string) {
    this.preferredLanguage.setValue(undefined);
    if (val === 'E') {
      this.iffForm.removeControl('countryCode');
      this.iffForm.removeControl('phoneNo');
      const emailFormControl = new FormControl(null, [Validators.required, Validators.email]);
      this.iffForm.addControl('email', emailFormControl);
      this.preferredLanguageConfig = {
        options: [
          {
            label: 'common.chinese',
            value: PreferredLanguageType.Chinese,
            disable: true,
          },
          {
            label: 'common.english',
            value: PreferredLanguageType.English,
            disable: true,
          },
        ],
      };
    } else if (val === 'M') {
      this.iffForm.removeControl('email');
      const countryCodeFormControl = new FormControl(null, [Validators.required]);
      const phoneNoFormControl = new FormControl(null, [Validators.required, clientPhoneNumberVali(countryCodeFormControl)]);
      this.iffForm.addControl('countryCode', countryCodeFormControl);
      this.iffForm.addControl('phoneNo', phoneNoFormControl);
      this.iffForm.patchValue({ countryCode: '852' });
      this.preferredLanguageConfig = {
        options: [
          {
            label: 'common.chinese',
            value: PreferredLanguageType.Chinese,
            disable: false,
          },
          {
            label: 'common.english',
            value: PreferredLanguageType.English,
            disable: false,
          },
        ],
      };
    }
    this.cdRadioPreferredLanguage?.refreshConfig();
  }

  closeCurrentPopup() {
    this.dialogRef.close();
  }

  onContinue() {
    if (this.iffForm.invalid && this.isEdit) {
      return;
    }

    this.isEdit = false;
    this.isShowAllInfo = true;
    this.step = 2;
  }

  onSubmit() {
    this.cdsPopup.open(SentEiffPopupComponent, {
      size: 'sm',
    });
    this.closeCurrentPopup();
  }

  onEdit() {
    this.isEdit = true;
    this.isShowAllInfo = true;
    this.step = 1;
    this.contactType.reset('');
    this.onContactTypeChange('');
  }

  onBackEdit() {
    this.isEdit = true;
    this.isShowAllInfo = true;
    this.step = 1;
  }
}
