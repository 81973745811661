/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { BasicResponse, IPageResponse } from '../../models/response/response';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CommissionAppealCaseService {
  constructor(private http: HttpClient) {}

  getPendingTransationData(params: any): Observable<BasicResponse<IPageResponse<MakeAppealItem>>> {
    return this.http.get<BasicResponse<IPageResponse<MakeAppealItem>>>(
      `${environment.apiPrefix}${environment.commissionServiceExt}${'/customer-transaction/pending/list'}`,
      {
        params: params,
      }
    );
  }

  saveMakeAppeal(params: any): Observable<BasicResponse<IPageResponse<MakeAppealItem>>> {
    return this.http.post<BasicResponse<IPageResponse<MakeAppealItem>>>(
      `${environment.apiPrefix}${environment.commissionServiceExt}${'/commission-adjustment/appeal-case-adj'}`,
      params
    );
  }
}
export interface MakeAppealItem {
  receivedDate: string;
  referenceNo: string;
  accountNo: string;
  accountType: string;
  formType: string;
  commFlag: string;
  trfsppcCode: string;
  trfspCode: string;
  fshkpcCode: string;
  cancelledOrRejectedDate?: string;
  appealDate?: string;
  details: Array<MakeAppealAgentItem>;
}
export interface MakeAppealAgentItem {
  agentChannel: string;
  agentCode: string;
  sharingComm: number;
  sharingPC: number;
}
