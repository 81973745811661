import { Directive, OnDestroy } from '@angular/core';
import { MatSelect } from '@angular/material/select';

@Directive({
  selector: '[appSelectCloseDirective]',
  exportAs: 'appSelectCloseDirective',
})
export class MatSelectCloseDirective implements OnDestroy {
  public constructor(private matSelect: MatSelect) {
    window.addEventListener('touchmove', this.scrollEvent, true);
  }

  public ngOnDestroy(): void {
    window.removeEventListener('touchmove', this.scrollEvent, true);
  }

  private scrollEvent = (): void => {
    console.log('matSelect touchmove');
    if (this.matSelect == null) {
      return;
    }
    if (this.matSelect.panelOpen) {
      console.log('matSelect panelOpen');
      this.matSelect.close();
    }
  };
}
