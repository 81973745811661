/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit, ViewChild, AfterViewInit, OnDestroy, DoCheck } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MatDialogRef } from '@angular/material/dialog';
import { Subscription, from, switchMap } from 'rxjs';

import { CdsDropdownConfig } from '@cds/ng-core/dropdown';
import { CdsIconConfig } from '@cds/ng-core/icon';
import { CdsLangService } from '@cds/ng-core/lang';
import { ActionIcons } from '@cds/ng-themes';
import { CdsPopupService } from '@cds/ng-web-components/popup';
import { CdsDatepickerComponent } from '@cds/ng-web-components/datepicker';
import { CdsAlertService } from '@cds/ng-web-components/alert';

import { CdRadioComponent, CdRadioConfig } from 'src/app/shared/cd-radio/cd-radio.component';
import { LanguageChangeService } from 'src/app/shared/language-change.service';
import { fromJSDateFormat } from 'src/app/utils/dateUtils';
import { ResetWarningComponent } from '../reset-warning/reset-warning.component';
import { CdHttpServeService } from 'src/app/shared/cd-http-serve/cd-http-serve.service';
import { agentCodeRemovePrefix0 } from 'src/app/utils/utils';
import { getSessionByKey } from 'src/app/utils/indexdb';
import { UpdatedSuccessfullyComponent } from 'src/app/shared/updated-successfully/updated-successfully.component';
import { NotifyService } from 'src/app/core/services/notify.service';
import { SimplePopupComponent } from 'src/app/shared/simple-popup/simple-popup.component';
import { AgentChannel } from 'src/app/core/models/enum/agent-channel.enum';
import { empfCountryOption } from '../../case-summary/declaration-iff-er/empf-declaration-form.config';
import { PreferredLanguageType } from '../../case-summary/case-summary.model';
import { AdviseEmpfrefComponent } from '../../case-summary/advise-empfref/advise-empfref.component';
import { AgentDetailInfo } from '../../service/model/agent-info-response.model';
import { Session } from 'src/app/core/models/user';

interface SubmissionParams {
  identityId: string;
  formType: string;
  productType: string;
  empfRefNo: string;
  employerName?: string;
  chnEmployerName?: string;
  brNo?: string;
  apCommChannel: string;
  apCountryCode?: string;
  apPhoneNumber?: string;
  apEmailAddr?: string;
  specialNeeds: boolean;
  paperFormSubmissionDate: string;
  agentInfos: {
    agentId?: string;
    agentCd?: string;
    servcingAgent: boolean;
    splitAgent: boolean;
    acknowledged: boolean;
  }[];
  smsLanguage?: 0 | 1;
  intraGroup: boolean;
  dbs?: {
    dbsRmReferralCd?: string;
    dbsCustomerId?: string;
    dbsCampaignCd?: string;
    dbsSourceCd?: string;
  };
}

interface TempAgentType {
  agentCode: string;
  name: string;
  chineseName: string;
  branchNo?: string;
}

@Component({
  selector: 'app-employer',
  templateUrl: './employer.component.html',
  styleUrls: ['./employer.component.scss'],
})
export class EmployerComponent implements OnInit, AfterViewInit, OnDestroy, DoCheck {
  @ViewChild('pickerFromDate', { static: false }) paperIFFAgentSubmissionFromDate?: CdsDatepickerComponent<string>;
  @ViewChild('cdRadioPreferredLanguage') cdRadioPreferredLanguage!: CdRadioComponent;
  @ViewChild('dbsReferredLanguage') dbsReferredLanguage!: CdRadioComponent;
  backIconConfig: CdsIconConfig = {
    color: 'cta',
    size: 'sm',
  };
  backIcon = ActionIcons.button_left_filled;
  language = 'EN';
  confirmSpecialNeedsValue = 'n';
  confirmSpecialNeedsConfig: CdRadioConfig = {
    options: [
      {
        label:
          'No, I do not have special needs and this confirmation shall override any previous confirmation and/or any information about me, which may indicate otherwise.',
        value: 'n',
        disable: false,
      },
      {
        label: 'Yes, I have special needs. ',
        value: 'y',
        disable: false,
      },
    ],
  };
  advisedSpecialNeedsValue = '1';
  advisedSpecialNeedsConfig: CdRadioConfig = {
    options: [
      {
        label: 'not to have any witness',
        value: '1',
        disable: false,
      },
      {
        label: 'to be accompanied by a companion/ to be accompanied by an additional member of staff as the witness',
        value: '2',
        disable: false,
      },
    ],
  };
  isConfirming = false;

  lanChangeSubscription?: Subscription;
  now = new Date();
  employerFormForm!: FormGroup;
  authorizedContactConfig: CdRadioConfig = {
    options: [
      {
        label: 'Email Address',
        value: 'E',
        disable: false,
      },
      {
        label: 'Phone Number',
        value: 'M',
        disable: false,
      },
    ],
  };
  dbsContactConfig: CdRadioConfig = {
    options: [
      {
        label: 'Yes',
        value: 'Y',
        disable: false,
      },
      {
        label: 'No',
        value: 'N',
        disable: false,
      },
    ],
  };
  authorizedContact = '';
  employerNameOrBRNumberConfig: CdRadioConfig = {
    options: [
      {
        label: 'Employer Name',
        value: 'E',
        disable: false,
      },
      {
        label: 'BR Number',
        value: 'B',
        disable: false,
      },
    ],
  };
  employerNameOrBRNumber = '';
  countrycodeConfig: CdsDropdownConfig = {
    options: empfCountryOption,
    placeholder: '+852 (HK)',
    color: 'ml',
  };
  private readonly CN_PHONE = /^1[345678]\d{9}$/;
  paperIFFAgentSubmissionTimeMax: number[] | null = null;
  isTryConfirm = false;
  resetDatePicker = false;
  isDBschannel = '';
  splitAgents = [
    {
      name: '',
      chineseName: '',
      code: new FormControl(null),
      isMatchError: false,
      branchNo: '',
      isSplitAgentCodePerformed: new FormControl(false),
    },
    {
      name: '',
      chineseName: '',
      code: new FormControl(null),
      isMatchError: false,
      branchNo: '',
      isSplitAgentCodePerformed: new FormControl(false),
    },
  ];
  tempServicingAgent: TempAgentType | null = null;
  preferredLanguageConfig = {
    options: [
      {
        label: 'common.chinese',
        value: PreferredLanguageType.Chinese,
        disable: true,
      },
      {
        label: 'common.english',
        value: PreferredLanguageType.English,
        disable: true,
      },
    ],
  };

  constructor(
    private router: Router,
    private languageChangeService: LanguageChangeService,
    public cdsLangService: CdsLangService,
    private cdsPopup: CdsPopupService,
    private cdHttpServeService: CdHttpServeService,
    private notifyService: NotifyService,
    private alert: CdsAlertService
  ) {}

  lanSubjectChange = (lan: string) => {
    this.language = lan === 'en' ? 'EN' : 'TC';
  };

  empFormPaperIFFAgentSubmissionDateFromVali = (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) {
      return null;
    }
    const temp = control.value.split('/');
    const selectedDate = new Date([temp[1], temp[0], temp[2]].join('/'));
    if (selectedDate.toString() === 'Invalid Date') {
      return { formatError: true };
    } else {
      return null;
    }
  };

  dbsContractChange(val: string) {
    this.isDBschannel = val;
    if (val === 'N') {
      this.employerFormForm.get('rmReferralCode')?.reset();
      this.employerFormForm.get('inputCustomerId')?.reset();
      this.employerFormForm.get('campaignCode')?.reset();
      this.employerFormForm.get('sourceCode')?.reset();
    }
  }

  get authorizedCountryCode() {
    return this.employerFormForm.get('authorizedCountryCode') as FormControl;
  }

  empFormAuthorizedPhoneNumberVali = (option: AbstractControl): ValidationErrors | null => {
    if (!option.value) {
      return null;
    }

    if (typeof option.value === 'string') {
      if (['852', '853'].includes(this.authorizedCountryCode.value)) {
        if (option.value.length === 8 && ['4', '5', '6', '7', '8', '9'].includes(option.value.substring(0, 1))) {
          return null;
        }
      }

      if (['86'].includes(this.authorizedCountryCode.value)) {
        if (option.value.length === 11) {
          const e = this.CN_PHONE.test(option.value);
          if (e) {
            return null;
          }
        }
      }

      if (!['852', '853', '86'].includes(this.authorizedCountryCode.value)) {
        return null;
      }
    }

    return { errorPhoneNumber: true };
  };

  empFormPreferredLanguageVali = (control: AbstractControl): ValidationErrors | null => {
    if ((this.authorizedContact === 'M' && control.value) || this.authorizedContact === 'E' || !this.authorizedContact) {
      return null;
    }
    return { formatError: true };
  };

  rmReferralCodeVali = (control: AbstractControl): ValidationErrors | null => {
    let input = control.value || '';
    input = input.trim();
    const isValid = input.length >= 6 && input.length <= 8;
    return isValid ? null : { lengthError: true };
  };
  inputCustomerIdVali = (control: AbstractControl): ValidationErrors | null => {
    let input = control.value || '';
    input = input.trim();
    const isValid = ((input.length >= 6 && input.length <= 8) || input.length === 0) && /^[A-Za-z0-9-]*$/.exec(input);
    return isValid ? null : { lengthError: true };
  };
  sourceCodeVali = (control: AbstractControl): ValidationErrors | null => {
    let input = control.value || '';
    input = input.trim();
    const isValid = (input.length === 10 || input.length === 0) && /^[A-Za-z0-9-]*$/.exec(input);
    return isValid ? null : { lengthError: true };
  };
  campaignCodeVali = (control: AbstractControl): ValidationErrors | null => {
    let input = control.value || '';
    input = input.trim();
    const isValid = (input.length === 10 || input.length === 0) && /^[A-Za-z0-9-]*$/.exec(input);
    return isValid ? null : { lengthError: true };
  };

  get servicingAgentCode() {
    return this.employerFormForm.get('servicingAgentCode') as FormControl;
  }

  get isIntragroupTransfer() {
    return this.employerFormForm.get('isIntragroupTransfer') as FormControl;
  }

  ngOnInit(): void {
    this.lanChangeSubscription = this.languageChangeService.lanSubject.subscribe(this.lanSubjectChange);

    this.employerFormForm = new FormGroup({
      eMPFReferenceNo: new FormControl('', [Validators.required]),
      paperIFFAgentSubmissionDateFrom: new FormControl('', [Validators.required, this.empFormPaperIFFAgentSubmissionDateFromVali]),
      employerName: new FormControl(null, [Validators.required]),
      brNo: new FormControl(null, [Validators.required]),
      authorizedEmailAddress: new FormControl(null, [Validators.required, Validators.email]),
      authorizedCountryCode: new FormControl('852', [Validators.required]),
      authorizedPhoneNumber: new FormControl('', [Validators.required, this.empFormAuthorizedPhoneNumberVali]),
      servicingAgentCode: new FormControl(null, [Validators.required]),
      isServicingAgentCodePerformed: new FormControl({ value: false, disabled: false }),
      preferredLanguage: new FormControl(null, [this.empFormPreferredLanguageVali]),
      isIntragroupTransfer: new FormControl({ value: false, disabled: false }),
      rmReferralCode: new FormControl('', [Validators.required, this.rmReferralCodeVali]),
      inputCustomerId: new FormControl('', [this.inputCustomerIdVali]),
      sourceCode: new FormControl('', [this.sourceCodeVali]),
      campaignCode: new FormControl('', [this.campaignCodeVali]),
    });

    this.servicingAgentCode.valueChanges.subscribe(res => {
      if (res === '') {
        this.splitAgents.forEach(splitAgent => {
          splitAgent.code.setValue(null);
          splitAgent.isSplitAgentCodePerformed.setValue(false);
          splitAgent.name = '';
          splitAgent.chineseName = '';
          splitAgent.isMatchError = false;
        });
      }
    });
  }

  ngAfterViewInit() {
    this.setPaperAgentSubmissionDateReadonly();
  }

  private setPaperAgentSubmissionDateReadonly() {
    const list = document.getElementsByClassName('paper-agent-submission-date');
    if (list.length === 1) {
      list.item(0)?.getElementsByTagName('input').item(0)?.setAttribute('readonly', '');
    }
  }

  openPaperIFFAgentSubmissionFromDate() {
    this.paperIFFAgentSubmissionFromDate?.open();
  }

  get authorizedPhoneNumber() {
    return this.employerFormForm.get('authorizedPhoneNumber') as FormControl;
  }

  authorizedCountryCodeChange() {
    this.authorizedPhoneNumber.updateValueAndValidity();
  }

  ngOnDestroy(): void {
    this.lanChangeSubscription?.unsubscribe();
  }

  onBack() {
    this.router.navigate(['/salesjourney/iff-paper-form/select-product-type']);
  }

  handleIconClick() {
    this.cdsPopup.open(AdviseEmpfrefComponent, {
      size: 'lg',
    });
  }

  openSimplePopup(value: string) {
    this.notifyService.screenLoadingSubject.next(false);
    const ref = this.cdsPopup.open(SimplePopupComponent, {
      size: 'md',
      data: {
        message: value,
        buttons: [
          {
            style: 'primary',
            size: 'sm',
            text: 'common.gotItWithExclamation',
            cb: () => {
              ref.close();
            },
          },
        ],
      },
    });
  }

  get rmReferralCode() {
    return this.employerFormForm.get('rmReferralCode') as FormControl;
  }
  get inputCustomerId() {
    return this.employerFormForm.get('inputCustomerId') as FormControl;
  }

  get campaignCode() {
    return this.employerFormForm.get('campaignCode') as FormControl;
  }

  get sourceCode() {
    return this.employerFormForm.get('sourceCode') as FormControl;
  }

  confirm() {
    this.eMPFReferenceNo.markAsDirty();
    this.paperIFFAgentSubmissionDateFrom.markAsDirty();
    this.authorizedEmailAddress.markAsDirty();
    this.authorizedPhoneNumber.markAsDirty();
    this.employerName.markAsDirty();
    this.brNo.markAsDirty();
    this.servicingAgentCode.markAsDirty();
    this.preferredLanguage.markAsTouched();
    this.isTryConfirm = true;
    if (this.isDBschannel != '') {
      this.rmReferralCode.markAsDirty();
      this.inputCustomerId.markAsDirty();
      this.campaignCode.markAsDirty();
      this.sourceCode.markAsDirty();
    }

    const boo = this.isAllowPast();
    if (boo) {
      this.notifyService.screenLoadingSubject.next(true);
      this.dbsContactConfig.options.forEach((e: { disable: boolean }) => {
        e.disable = true;
      });
      this.dbsReferredLanguage.refreshConfig();
      this.cdHttpServeService
        .post<{ empfRefNoStatus: boolean }>('/ext/eb-ssms-sales-journey-service/generateGeneralLink/validateEmpfRefNo/paperFrom', {
          empfRefNo: this.eMPFReferenceNo.value,
          clientType: 'employer',
        })
        .subscribe({
          next: res => {
            if (res.result === 0) {
              if (res.data.empfRefNoStatus) {
                this.eMPFReferenceNo.setErrors(null);
                const servicingAgentCd = this.servicingAgentCode.value;
                if (servicingAgentCd) {
                  this.cdHttpServeService
                    .post<
                      {
                        agentCode: string;
                        inputAgentType: string;
                        channel: string;
                        servicingAgentCode: string;
                        commissionAgentCode: string;
                      }[]
                    >(`/ext/eb-ssms/agent-service/api/v1/agent-code-conversion`, [servicingAgentCd])
                    .subscribe(res => {
                      const splitAgentList = this.splitAgents.filter(splitAgent => !!splitAgent.code.value);
                      // if (res.data[0].channel === AgentChannel.DBS && splitAgentList.length > 0) {
                      //   this.notifyService.screenLoadingSubject.next(false);
                      //   const ref = this.cdsPopup.open(SimplePopupComponent, {
                      //     size: 'md',
                      //     data: {
                      //       message: 'Split agent is not allowed for DBS channel',
                      //       buttons: [
                      //         {
                      //           style: 'primary',
                      //           size: 'sm',
                      //           text: 'common.gotItWithExclamation',
                      //           cb: () => {
                      //             ref.close();
                      //           },
                      //         },
                      //       ],
                      //     },
                      //   });
                      //   return;
                      // }
                      if (res.data[0].channel === AgentChannel.DBS && splitAgentList.length > 0) {
                        this.openSimplePopup('Split agent is not allowed for DBS channel');
                        return;
                      }
                      if (res.data[0].channel === AgentChannel.SCB || res.data[0].channel === AgentChannel.BROKER) {
                        this.openSimplePopup('IFF is not required for this sales channel');
                        return;
                      }
                      if (res.data[0].channel === AgentChannel.CORPORATE && !res.data[0].agentCode.startsWith('400')) {
                        this.openSimplePopup('IFF is not required for this sales channel');
                        return;
                      }
                      if (res.data[0].channel === AgentChannel.CORPORATE && res.data[0].agentCode === '400003') {
                        this.openSimplePopup('IFF is not required for this sales channel');
                        return;
                      }
                      if (res.data[0].channel === AgentChannel.CORPORATE && res.data[0].agentCode.startsWith('400') && splitAgentList.length > 0) {
                        this.openSimplePopup('Split agent is not allowed for CORPORATE channel');
                        return;
                      }

                      this.tempServicingAgent = null;
                      this.validateAgentCode(servicingAgentCd).then(res => {
                        this.validateAgentCodeCallBack(servicingAgentCd, res);
                      });
                    });
                } else {
                  this.notifyService.screenLoadingSubject.next(false);
                }
              } else {
                this.notifyService.screenLoadingSubject.next(false);
                this.eMPFReferenceNo.setErrors({ haveExisted: true });
              }
            } else {
              this.notifyService.screenLoadingSubject.next(false);
              this.alert.error('Error!', res.message);
            }
          },
          error: err => {
            this.notifyService.screenLoadingSubject.next(false);
            this.alert.error('Error!', err);
          },
        });
    }
  }

  setIsConfirmingToTrue() {
    this.isConfirming = true;
    this.isServicingAgentCodePerformed.disable();
    this.isIntragroupTransfer.disable();
  }

  setIsConfirmingToFalse() {
    this.isConfirming = false;
    this.isServicingAgentCodePerformed.enable();
    this.isIntragroupTransfer.enable();
  }

  validateAgentCodeCallBack(
    servicingAgentCd: string,
    res:
      | false
      | {
          agentCode: string;
          name: string;
          chineseName: string;
          errorCode?: number | undefined;
        }
  ) {
    if (res) {
      this.tempServicingAgent = res;

      this.servicingAgentCode.setErrors(null);
      const splitAgentList = this.splitAgents.filter(splitAgent => !!splitAgent.code.value);
      if (splitAgentList.length === 0) {
        this.notifyService.screenLoadingSubject.next(false);
        this.setIsConfirmingToTrue();
      } else {
        let repeatSplitAgentList = splitAgentList.filter(item => item.code.value === servicingAgentCd);
        if (repeatSplitAgentList.length > 0) {
          repeatSplitAgentList.forEach(element => {
            element.isMatchError = true;
          });
          this.notifyService.screenLoadingSubject.next(false);
        } else {
          repeatSplitAgentList = splitAgentList.filter((item, index) => splitAgentList.findIndex(c => item.code.value === c.code.value) !== index);
          if (repeatSplitAgentList.length > 0) {
            repeatSplitAgentList.forEach(element => {
              element.isMatchError = true;
            });
            this.notifyService.screenLoadingSubject.next(false);
          } else {
            this.cdHttpServeService
              .post<
                {
                  agentCode: string;
                  name: string;
                  chineseName: string;
                  errorCode?: number;
                }[]
              >(
                '/ext/eb-ssms/customer-service/add-delegation/validateBatch',
                splitAgentList.map(agent => ({ agentCode: agentCodeRemovePrefix0(agent.code.value) }))
              )
              .subscribe({
                next: res => {
                  if (res.result === 0) {
                    for (let i = 0; i < res.data.length; i++) {
                      const agent = res.data[i];
                      if (agent.errorCode) {
                        splitAgentList[i].isMatchError = true;
                      } else {
                        splitAgentList[i].isMatchError = false;
                        splitAgentList[i].chineseName = agent.chineseName;
                        splitAgentList[i].name = agent.name;
                      }
                    }
                    if (splitAgentList.every(item => !item.isMatchError)) {
                      this.setIsConfirmingToTrue();
                    }
                  } else if (res.result === 2100) {
                    res.data.forEach(element => {
                      const newElement = element as unknown as { agent: { agentCode: string } };
                      const temp = splitAgentList.find(item => item.code.value === newElement.agent.agentCode);
                      if (temp) {
                        temp.isMatchError = true;
                      }
                    });
                  }
                  this.notifyService.screenLoadingSubject.next(false);
                },
                error: () => {
                  this.notifyService.screenLoadingSubject.next(false);
                },
              });
          }
        }
      }

      const temp: any[] = [];
      if (this.tempServicingAgent) {
        temp.push(this.tempServicingAgent);
      }
      splitAgentList.forEach(element => {
        temp.push(element);
      });
      this.getAgentBranchCds(temp);
    } else {
      this.servicingAgentCode.setErrors({ unMatch: true });
      this.notifyService.screenLoadingSubject.next(false);
    }
  }

  getAgentBranchCds(tempAgentList: (TempAgentType & { code: FormControl })[]) {
    const agentCodes = [];
    for (const agent of tempAgentList) {
      if (!agent.agentCode) {
        agentCodes.push(agent.code.value);
      } else {
        agentCodes.push(agent.agentCode);
      }
    }
    if (agentCodes.length === 0) {
      return;
    }
    this.cdHttpServeService
      .post<
        {
          agentCode: string;
          branchNo: string;
        }[]
      >('/ext/eb-ssms/agent-service/agent/branchCd', agentCodes)
      .subscribe({
        next: res => {
          if (res.result === 0) {
            for (let i = 0; i < res.data.length; i++) {
              const filAgent = tempAgentList.filter(
                tempAgent => tempAgent.agentCode === res.data[i].agentCode || tempAgent.code?.value === res.data[i].agentCode
              );
              if (filAgent.length > 0) {
                filAgent[0].branchNo = res.data[i].branchNo;
              }
            }
          }
        },
        error: err => {
          this.alert.error('Error!', err);
        },
      });
  }

  validateAgentCode(splitAgentCode: string) {
    return new Promise<
      | false
      | {
          agentCode: string;
          name: string;
          chineseName: string;
          errorCode?: number;
        }
    >(resolve => {
      this.cdHttpServeService
        .post<{
          agentCode: string;
          name: string;
          chineseName: string;
          errorCode?: number;
        }>('/ext/eb-ssms/customer-service/add-delegation/validate', {
          agentCode: agentCodeRemovePrefix0(splitAgentCode.trim()),
        })
        .subscribe({
          next: res => {
            if (res.result === 0) {
              resolve(res.data);
            } else {
              // this.alert.error('Error!', res.message);
              resolve(false);
            }
          },
          error: () => {
            // this.alert.error('Error!', err);
            resolve(false);
          },
        });
    });
  }

  getAgentInformation(agentCode: string) {
    return new Promise<AgentDetailInfo | null>(resolve => {
      this.cdHttpServeService.get<AgentDetailInfo>('/ext/eb-ssms/agent-service/agent/information', { agentInfo: agentCode }).subscribe({
        next: res => {
          if (res.result === 0) {
            resolve(res.data);
          } else {
            this.alert.error('Error!', res.message);
            resolve(null);
          }
        },
        error: err => {
          this.alert.error('Error!', err);
          resolve(null);
        },
      });
    });
  }

  submissionForSubmit(servicingAgent: AgentDetailInfo | null, splitAgentList: (AgentDetailInfo | null)[], res: Session) {
    const agentInfos = [];
    if (servicingAgent) {
      agentInfos.push({
        agentId: servicingAgent.agentId,
        agentCd: servicingAgent.agentCode,
        servcingAgent: true,
        splitAgent: false,
        acknowledged: this.isServicingAgentCodePerformed.value,
      });
    }
    splitAgentList.forEach(element => {
      if (element) {
        agentInfos.push({
          agentId: element.agentId,
          agentCd: element.agentCode,
          servcingAgent: false,
          splitAgent: true,
          acknowledged: this.splitAgents.find(item => item.code.value === element.agentCode)?.isSplitAgentCodePerformed.value,
        });
      }
    });
    const identityId = res.id;
    const dList = this.paperIFFAgentSubmissionDateFrom.value.split('/');
    const paperFormSubmissionDate = `${dList[2]}-${dList[1]}-${dList[0]} 00:00:00`;
    const params: SubmissionParams = {
      productType: 'ER',
      identityId,
      formType: 'ER',
      empfRefNo: this.eMPFReferenceNo.value,
      apCommChannel: this.authorizedContact,
      apCountryCode: this.authorizedCountryCode.value,
      apPhoneNumber: this.authorizedPhoneNumber.value,
      apEmailAddr: this.authorizedEmailAddress.value,
      specialNeeds: this.confirmSpecialNeedsValue === 'y',
      paperFormSubmissionDate,
      agentInfos,
      intraGroup: this.isIntragroupTransfer.value,
      dbs: {
        dbsRmReferralCd: this.rmReferralCode.value,
        dbsCustomerId: this.inputCustomerId.value,
        dbsCampaignCd: this.campaignCode.value,
        dbsSourceCd: this.sourceCode.value,
      },
    };

    if (this.employerNameOrBRNumber === 'E') {
      if (new RegExp('^[a-zA-Z\x20]*$').test(this.employerName.value)) {
        params.employerName = this.employerName.value;
      } else {
        params.chnEmployerName = this.employerName.value;
      }
    } else if (this.employerNameOrBRNumber === 'B') {
      params.brNo = this.brNo.value;
    }
    if (this.authorizedContact === 'E') {
      delete params['apCountryCode'];
      delete params['apPhoneNumber'];
    } else if (this.authorizedContact === 'M') {
      delete params['apEmailAddr'];
      params['smsLanguage'] = this.preferredLanguage.value === PreferredLanguageType.English ? 0 : 1;
    }
    return this.cdHttpServeService.post<{ salesJourneySummariesId: string }>('/ext/eb-ssms-sales-journey-service/paperFormIff/submission', params);
  }

  submitAllGetAgentInformationCb = (res: (AgentDetailInfo | null)[]) => {
    let servicingAgent: AgentDetailInfo | null = null;
    const splitAgentList: (AgentDetailInfo | null)[] = [];
    res.forEach((element, index) => {
      if (index === 0) {
        servicingAgent = element;
      } else if (index > 0) {
        splitAgentList.push(element);
      }
    });
    from(getSessionByKey())
      .pipe(
        switchMap(res => {
          return this.submissionForSubmit(servicingAgent, splitAgentList, res);
        })
      )
      .subscribe({
        next: this.submitCb,
        error: err => {
          this.alert.error('Error!', err);
          this.notifyService.screenLoadingSubject.next(false);
        },
      });
  };

  submitCb = (res: { result: number; message: string }) => {
    if (res.result === 0) {
      const ref = this.cdsPopup.open(UpdatedSuccessfullyComponent, {
        size: 'md',
      });
      ref.afterClosed().subscribe(() => {
        this.router.navigate(['/salesjourney/sales-support-summary', { productType: 'ER' }]);
      });
    } else {
      this.alert.error('Error!', res.message);
    }
    this.notifyService.screenLoadingSubject.next(false);
  };

  submit() {
    this.notifyService.screenLoadingSubject.next(true);

    const promiseList: Promise<AgentDetailInfo | null>[] = [];
    if (this.servicingAgentCode.value) {
      promiseList.push(this.getAgentInformation(this.servicingAgentCode.value));
    }

    this.splitAgents.forEach(element => {
      if (element.code.value) {
        promiseList.push(this.getAgentInformation(element.code.value));
      }
    });
    Promise.all(promiseList).then(this.submitAllGetAgentInformationCb);
  }

  backToEdit() {
    this.setIsConfirmingToFalse();
    this.dbsContactConfig.options.forEach((e: { disable: boolean }) => {
      e.disable = false;
    });
    this.dbsReferredLanguage.refreshConfig();
  }

  reset() {
    const popupRef: MatDialogRef<ResetWarningComponent> = this.cdsPopup.open(ResetWarningComponent, {
      size: 'sm',
    });
    popupRef.afterClosed().subscribe(confirm => {
      if (confirm.agree) {
        this.employerFormForm.reset();
        this.isIntragroupTransfer.reset({ value: false, disabled: false });
        this.authorizedCountryCode.setValue('852');
        this.employerNameOrBRNumber = '';
        this.isTryConfirm = false;
        this.confirmSpecialNeedsValue = 'n';
        this.advisedSpecialNeedsValue = '1';
        this.resetDatePicker = true;
        this.authorizedContact = '';
        this.isDBschannel = '';
        this.splitAgents = [
          {
            name: '',
            chineseName: '',
            code: new FormControl(null),
            isMatchError: false,
            branchNo: '',
            isSplitAgentCodePerformed: new FormControl(false),
          },
          {
            name: '',
            chineseName: '',
            code: new FormControl(null),
            isMatchError: false,
            branchNo: '',
            isSplitAgentCodePerformed: new FormControl(false),
          },
        ];
        setTimeout(() => {
          this.resetDatePicker = false;
          setTimeout(() => {
            this.setPaperAgentSubmissionDateReadonly();
          }, 0);
        }, 0);
        this.preferredLanguage.reset();
        this.preferredLanguageConfig = {
          options: [
            {
              label: 'common.chinese',
              value: PreferredLanguageType.Chinese,
              disable: true,
            },
            {
              label: 'common.english',
              value: PreferredLanguageType.English,
              disable: true,
            },
          ],
        };
        this.cdRadioPreferredLanguage.refreshConfig();
      }
    });
  }

  authorizedContactChange(val: string) {
    this.preferredLanguage.setValue(undefined);
    if (val === 'E') {
      this.preferredLanguageConfig = {
        options: [
          {
            label: 'common.chinese',
            value: PreferredLanguageType.Chinese,
            disable: true,
          },
          {
            label: 'common.english',
            value: PreferredLanguageType.English,
            disable: true,
          },
        ],
      };
    } else if (val === 'M') {
      this.preferredLanguageConfig = {
        options: [
          {
            label: 'common.chinese',
            value: PreferredLanguageType.Chinese,
            disable: false,
          },
          {
            label: 'common.english',
            value: PreferredLanguageType.English,
            disable: false,
          },
        ],
      };
    }

    this.cdRadioPreferredLanguage.refreshConfig();
  }

  get preferredLanguage() {
    return this.employerFormForm.get('preferredLanguage') as FormControl;
  }

  get authorizedEmailAddress() {
    return this.employerFormForm.get('authorizedEmailAddress') as FormControl;
  }

  get employerName() {
    return this.employerFormForm.get('employerName') as FormControl;
  }

  get brNo() {
    return this.employerFormForm.get('brNo') as FormControl;
  }

  isAllowPast() {
    if (!this.eMPFReferenceNo.valid || !this.paperIFFAgentSubmissionDateFrom.valid || !this.servicingAgentCode.valid || !this.preferredLanguage.valid) {
      return false;
    }
    if (this.isDBschannel === 'Y' && (!this.rmReferralCode.valid || !this.campaignCode.valid || !this.inputCustomerId.valid || !this.sourceCode.valid)) {
      return false;
    }
    if (this.isDBschannel === '') {
      return false;
    }
    if (this.authorizedContact === '' || this.employerNameOrBRNumber === '') {
      return false;
    }
    if (this.authorizedContact === 'E' && !this.authorizedEmailAddress.valid) {
      return false;
    } else if (this.authorizedContact === 'M' && !this.authorizedPhoneNumber.valid) {
      return false;
    }
    if (this.employerNameOrBRNumber === 'E' && !this.employerName.valid) {
      return false;
    } else if (this.employerNameOrBRNumber === 'B' && !this.brNo.valid) {
      return false;
    }

    return true;
  }

  get eMPFReferenceNo() {
    return this.employerFormForm.get('eMPFReferenceNo') as FormControl;
  }

  eMPFReferenceNoChange(val: string) {
    if (/[\u4e00-\u9fa5]/gi.test(val)) {
      this.eMPFReferenceNo.setValue(val.replace(/[\u4e00-\u9fa5]/gi, ''));
    }
  }

  get paperIFFAgentSubmissionDateFrom() {
    return this.employerFormForm.get('paperIFFAgentSubmissionDateFrom') as FormControl;
  }

  refreshPaperIFFAgentSubmissionTimeMax() {
    this.now = new Date();
    if (fromJSDateFormat(this.now) === this.paperIFFAgentSubmissionDateFrom.value) {
      this.paperIFFAgentSubmissionTimeMax = [this.now.getHours(), this.now.getMinutes()];
    } else {
      this.paperIFFAgentSubmissionTimeMax = null;
    }
  }

  getAgentName(obj?: { name: string; chineseName: string }) {
    if (!obj) {
      return 'N/A';
    }
    if (this.cdsLangService.currentLang === 'en') {
      return obj.name || obj.chineseName;
    } else {
      return obj.chineseName || obj.name;
    }
  }

  addNewSplitAgent() {
    if (this.splitAgents.length >= 2) {
      return;
    }

    this.splitAgents.push({
      name: '',
      chineseName: '',
      code: new FormControl(null),
      isMatchError: false,
      branchNo: '',
      isSplitAgentCodePerformed: new FormControl(false),
    });
  }

  authorizedCountryCodeCodeChange() {
    this.authorizedPhoneNumber.updateValueAndValidity();
  }

  get isServicingAgentCodePerformed() {
    return this.employerFormForm.get('isServicingAgentCodePerformed') as FormControl;
  }

  isDisabledSplitAgentCodePerformed() {
    const disable = this.isConfirming || !(!this.servicingAgentCode.hasError('required') && !!this.isServicingAgentCodePerformed.value);
    this.splitAgents.forEach(element => {
      if (disable) {
        element.isSplitAgentCodePerformed.disable();
      } else {
        element.isSplitAgentCodePerformed.enable();
      }
    });
  }

  ngDoCheck() {
    this.isDisabledSplitAgentCodePerformed();
  }
}
