import { IPageableRequest } from 'src/app/core/models/request';

export interface EmployerStatusData {
  text: string;
  chineseText?: string;
  color: string;
}

export enum ContactStatusE {
  ACTIVE = 'ACTIVE',
  INVALID = 'INVALID',
}

export const ContactStatusMapObj = {
  [ContactStatusE.INVALID]: {
    text: 'Invalid',
    color: '#8E90A2',
  },
  [ContactStatusE.ACTIVE]: {
    text: 'Active',
    color: '#00A758',
  },
};

export interface CompanyGroupListRequest extends IPageableRequest {
  id?: string;
  showAmount?: boolean;
}
