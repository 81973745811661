import { Inject, Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';
import { CdsLangService, LangCode } from '@cds/ng-core/lang';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';

@Injectable({
  providedIn: 'root',
})
export class LanguageChangeService {
  lanSubject = new BehaviorSubject<LangCode>('en');
  initLanNotice = new BehaviorSubject<LangCode>('en');

  constructor(public lang: CdsLangService, private _adapter: DateAdapter<string>, @Inject(MAT_DATE_LOCALE) private _locale: string) {
    this.init();
  }

  private init() {
    const lang = localStorage.getItem('language');
    this.initLanNotice.next(lang || 'en');
    this.setLang(lang || 'en');
  }

  setLang(lang: LangCode) {
    this.lang.setCurrentLang(lang);
    this.lanSubject.next(lang);
    this.initLanNotice.next(lang);
    localStorage.setItem('language', lang);

    if (lang === 'en') {
      this.en();
    } else {
      this.zh();
    }
  }

  zh() {
    this._locale = 'zh-HK';
    this._adapter.setLocale(this._locale);
  }

  en() {
    this._locale = 'en';
    this._adapter.setLocale(this._locale);
  }
}
