export class Helper {
  constructor() {}

  public static maskPII(value: number | string): string {
    if (typeof value === 'number' || (typeof value === 'string' && !isNaN(parseFloat(value)))) {
      const stringValue = value.toString();
      // Check if the length is greater than 6
      if (stringValue.length > 6) {
        // Mask all but the last 4 digits
        return '*'.repeat(stringValue.length - 4) + stringValue.slice(-4);
      } else {
        // For shorter values, return the original value
        return stringValue;
      }
    } else {
      // For other types, return the original value
      return String(value);
    }
  }
}
