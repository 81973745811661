import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SupervisorRoutingModule } from './supervisor-routing.module';
import { SupervisorComponent } from './supervisor/supervisor.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CdsLanguageModule } from '@cds/ng-core/lang';
import { CdsButtonModule } from '@cds/ng-web-components/button';
import { CdsIconModule } from '@cds/ng-web-components/icon';
import { CdsNavigationModule } from '@cds/ng-web-components/navigation';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { LanguageSwitchModule } from 'src/app/shared/language-switch/language-switch.module';
import { AngentRoutingModule } from '../chatbot/angent/angent-routing.module';

@NgModule({
  declarations: [SupervisorComponent],
  imports: [
    CommonModule,
    AngentRoutingModule,
    CdsIconModule,
    LanguageSwitchModule,
    CdsLanguageModule,
    FormsModule,
    CdsNavigationModule,
    NgSelectModule,
    ReactiveFormsModule,
    SharedModule,
    NgbModule,
    CdsButtonModule,
    SupervisorRoutingModule,
  ],
})
export class SupervisorModule {}
