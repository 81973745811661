import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from 'src/app/shared/shared.module';
import { CdsTableModule } from '@cds/ng-data-table/table';

import { CdsLanguageModule } from '@cds/ng-core/lang';
import { CdsButtonModule } from '@cds/ng-web-components/button';
import { CdsTabsModule } from '@cds/ng-web-components/tabs';

import { RoleFormModule } from '../shared/role-form/role-form.module';
import { RoleAssignUsersModule } from '../shared/role-assign-users/role-assign-users.module';

import { ReportingRoleComponent } from './reporting-role.component';
import { ReportingRoleCreateComponent } from './reporting-role-create/reporting-role-create.component';
import { ReportingRoleInfoComponent } from './reporting-role-info/reporting-role-info.component';
import { ReportingRoleFormComponent } from './reporting-role-form/reporting-role-form.component';
import { CdsCheckboxModule } from '@cds/ng-web-components/checkbox';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    CdsLanguageModule,
    CdsCheckboxModule,
    CdsButtonModule,
    CdsTabsModule,
    RoleFormModule,
    RoleAssignUsersModule,
    CdsTableModule,
  ],
  declarations: [ReportingRoleComponent, ReportingRoleCreateComponent, ReportingRoleInfoComponent, ReportingRoleFormComponent],
})
export class ReportingRoleModule {}
