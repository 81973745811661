import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { CDS_POPUP_DATA, MatDialogRef } from '@cds/ng-web-components/popup';
import { finalize, Subscription } from 'rxjs';
import { IPageableRequest } from 'src/app/core/models/request';
import { IPaginationCount } from 'src/app/shared/pagination/pagination.dto';
import { toPageableRequest } from 'src/app/utils/pagination.utils';
import { NotificationDataService } from '../notificationlist/notification-data.service';
import { AudienceList } from '../notificationlist/notificationmodel';

@Component({
  selector: 'app-preview-audience-list-popup',
  templateUrl: './preview-audience-list-popup.component.html',
  styleUrls: ['./preview-audience-list-popup.component.scss'],
})
export class PreviewAudienceListPopupComponent implements OnInit, OnDestroy {
  fileNameLoading = false;

  audienceListLoading = false;

  fileName = '';

  audienListName = '';

  totalElements = 0;

  dataSource?: AudienceList[];

  displayedColumns: string[] = ['clientId'];

  pageCount: IPaginationCount = { current: 1, count: 1 };

  navigationStartSubscription?: Subscription;

  constructor(
    private dialogRef: MatDialogRef<PreviewAudienceListPopupComponent>,
    private notificationService: NotificationDataService,
    private router: Router,
    @Inject(CDS_POPUP_DATA)
    public data: {
      id: string;
      listName: string;
    }
  ) {}

  ngOnInit(): void {
    this.getAudienListName();
    this.getFileName();
    this.audienceListLoading = true;
    this.loadTable();
    this.listenNavigationStart();
  }

  ngOnDestroy(): void {
    if (this.navigationStartSubscription) {
      this.navigationStartSubscription.unsubscribe();
    }
  }

  listenNavigationStart() {
    this.navigationStartSubscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.dialogRef.close();
      }
    });
  }

  getAudienListName() {
    const option = this.notificationService.audienceCustomDropdownConfig.options.find(item => item.value === this.data.listName);
    if (option) {
      this.audienListName = option.label;
    }
  }

  getFileName() {
    this.fileNameLoading = true;
    this.notificationService
      .getPushNotificationFileName(this.data.id)
      .pipe(
        finalize(() => {
          this.fileNameLoading = false;
        })
      )
      .subscribe({
        next: res => {
          if (res.result === 0) {
            this.fileName = res.data;
          }
        },
      });
  }

  loadTable() {
    const params: IPageableRequest = toPageableRequest(this.pageCount, 10);
    this.notificationService
      .getAudienceListSendHistory({
        ...params,
        pushNotificationSummaryId: this.data.id,
        sort: 'orderIndex',
      })
      .pipe(
        finalize(() => {
          this.audienceListLoading = false;
        })
      )
      .subscribe({
        next: res => {
          if (res.result === 0 && res.data) {
            this.totalElements = res.data.totalElements || 0;
            this.dataSource = res.data.content || [];
            if (this.pageCount.current != res.data.number + 1) {
              this.pageCount.current = res.data.number + 1;
            }
            if (this.pageCount.count !== res.data.totalPages) {
              this.pageCount.count = res.data.totalPages;
            }
          }
        },
        error: () => {
          this.dataSource = [];
        },
      });
  }
}
