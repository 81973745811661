import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { catchError, Observable, of, Subject, tap, timeout } from 'rxjs';
import { EmployeeRequest } from '../employee-summary/employee-request.model';
import { HistoryResponse } from './history-response';
import { agentCodeAddPrefix0 } from 'src/app/utils/utils';

@Injectable({
  providedIn: 'root',
})
export class EmployerHistroyService {
  searchEmployeeUrl = `${environment.apiPrefix}/ext/eb-ssms/customer-service/update-delegation/summary/query`;
  employeeDataSubject = new Subject<HistoryResponse>();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  errorEmployeeSubject = new Subject<any>();

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  constructor(private httpClient: HttpClient) {}

  getEmployees(request: EmployeeRequest) {
    this.httpClient
      .post(this.searchEmployeeUrl, request, this.httpOptions)
      .pipe(
        tap(() => console.info('fetched employees')),
        timeout(5000),
        catchError(this.handleError<HistoryResponse>('getEmployers', new HistoryResponse()))
      )
      .subscribe((resp: HistoryResponse) => {
        resp.data?.content?.forEach(c => {
          c.items?.forEach(item => {
            if (item.submitAgent?.agentCode) {
              item.submitAgent.agentCode = agentCodeAddPrefix0(item.submitAgent.agentCode);
            }
            if (item.oldAgent?.agentCode) {
              item.oldAgent.agentCode = agentCodeAddPrefix0(item.oldAgent.agentCode);
            }
            if (item.targetAgent?.agentCode) {
              item.targetAgent.agentCode = agentCodeAddPrefix0(item.targetAgent.agentCode);
            }
          });
        });

        this.employeeDataSubject.next(resp);
      });
  }

  handleError<T>(operation = 'operation', result?: T) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (error: any): Observable<T> => {
      console.error(error);
      console.info(`${operation} failed :${error.message}`);
      this.errorEmployeeSubject.next(`${error.message}`);
      return of(result as T);
    };
  }
}
