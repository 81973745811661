/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { PolicyTermination, TerminationReasonE, TerminationStatusE, terminationReasonMapObj, terminationStatusMapObj } from '../employee';
import { EmployeeService } from '../employee.service';
import { finalize } from 'rxjs';
import { ToastAlertService } from 'src/app/shared/toast-alert.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-policy-termination',
  templateUrl: './policy-termination.component.html',
  styleUrls: ['./policy-termination.component.scss'],
})
export class PolicyTerminationComponent implements OnInit, OnChanges {
  @Input() id = '';

  terminationReferenceNo = '';
  terminationStatus?: TerminationStatusE;

  TerminationStatusE = TerminationStatusE;

  options = [
    {
      refNo: '123',
      statusCd: 'reject',
    },
    {
      refNo: '123',
      statusCd: 'new',
    },
  ];

  foods: any[] = [
    { value: 'steak-0', viewValue: 'Steak' },
    { value: 'pizza-1', viewValue: 'Pizza' },
    { value: 'tacos-2', viewValue: 'Tacos' },
  ];

  detailData: PolicyTermination = {};

  isLoading = false;

  constructor(public service: EmployeeService, private toastAlert: ToastAlertService, public router: Router) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['id'].currentValue !== undefined && changes['id'].currentValue !== changes['id'].previousValue) {
      this.getList();
    }
  }

  ngOnInit(): void {
    this.getList();
  }

  selectChange(value: any) {
    console.log('selectChange:', value);
  }

  onSelectChange(val: any) {
    console.log('onSelectChange:', val);
    if (!val) return;
    this.terminationReferenceNo = val;
    this.getDetail();
  }

  getList() {
    this.isLoading = true;
    this.service
      .getterminationDetails(this.terminationReferenceNo, this.id)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe(res => {
        if (res.result === 0 && res.data) {
          this.detailData = res.data;
          // const referenceList = res.data.withdrawalReferenceList || [];
          // this.dropdownConfig.options = referenceList.map(item => {
          //   return {
          //     label: `${item.withdrawalReferenceNo}`,
          //     value: item.withdrawalReferenceNo,
          //   };
          // });
          // this.dropdownConfig = { ...this.dropdownConfig };
        } else {
          // this.toastError(res.message);
        }
      });
  }

  getDetail() {
    this.isLoading = true;
    this.service
      .getterminationDetails(this.terminationReferenceNo, this.id)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe(res => {
        if (res.result === 0 && res.data) {
          this.detailData = res.data;
          // const referenceList = res.data.withdrawalReferenceList || [];
          // this.dropdownConfig.options = referenceList.map(item => {
          //   return {
          //     label: `${item.withdrawalReferenceNo}`,
          //     value: item.withdrawalReferenceNo,
          //   };
          // });
          // this.dropdownConfig = { ...this.dropdownConfig };
        } else {
          // this.toastError(res.message);
        }
      });
  }

  terminationReasonRender(type: TerminationReasonE) {
    return terminationReasonMapObj[type] || '';
  }

  terminationStatusRender(type: TerminationStatusE) {
    return terminationStatusMapObj[type] || '';
  }

  toastError(errorMessage: string) {
    this.toastAlert.show('error', 'common.error', errorMessage, 5000);
  }

  jumpToWorkflow(item: PolicyTermination) {
    console.log(item);
    this.router.navigate([
      '/employee/related-policy-detail',
      {
        // todo, policy id
        id: 'MPF-EE-1337203-4681',
        // todo, ee md5
        md5: '6ffe054fcb13eb502e616ce76a735d95',
        workflowReferenceNo: item.wfRefNo,
        tab: 'empfWorkflow',
      },
    ]);
  }
}
