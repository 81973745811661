/* eslint-disable @typescript-eslint/no-explicit-any */
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'mynumber' })
export class MyNumberPipe implements PipeTransform {
  transform(value: number | string | null, ...args: any[]) {
    if (value !== 0 && !value) return null;

    value = value.toString().replace(/,/g, '');
    const val = Number(value);
    if (Number.isNaN(val)) return value;

    //format value
    const percision = args[0];

    if (val === 0) {
      if (!percision || percision == 0) {
        return '0';
      }
      return `0.${this.fill('', percision)}`;
    }

    const index = value.indexOf('.');
    let integer = '',
      decimal = '';
    if (index > -1) {
      integer = value.substring(0, index);
      decimal = value.substring(index + 1, value.length);
    } else {
      integer = value;
    }

    integer = this.format(integer);
    decimal = this.fill(decimal, percision);
    if (!decimal) {
      return integer;
    }
    return integer + '.' + decimal;
  }

  private fill(value: string, length: number) {
    if (value.length === length) {
      return value;
    }
    if (value.length > length) {
      return value.substring(0, length);
    }
    let len = length - value.length;
    while (len > 0) {
      value += '0';
      len--;
    }
    return value;
  }

  private format(num: string) {
    const reg = /(0*)([1-9]+\d+)/g;
    const str = num.replace(reg, '$2');
    const res = str.replace(/\d+/, function (n) {
      return n.replace(/(\d)(?=(\d{3})+$)/g, function ($1) {
        return $1 + ',';
      });
    });
    return res;
  }
}
