/* eslint-disable @typescript-eslint/no-explicit-any */
import { FormControl, FormGroup } from '@angular/forms';
import { Component, Inject, OnInit } from '@angular/core';
import { ResponseResult } from 'src/app/core/models/response/response-result';
import { CalendarService } from 'src/app/views/system/calendar/calendar.service';
import { CdPopupService, MatDialogRef, CDS_POPUP_DATA, CdPopupType } from 'src/app/shared/cd-popup';
import { I18N_KEY, I18N_KEY_FOR_API_RES } from '../../calendar.config';
import { dateValidator, typhoonDateValiator } from 'src/app/core/validators';
import { ActionEnum } from 'src/app/core/models/enum';

@Component({
  selector: 'app-edit-declined-weather',
  templateUrl: './edit-declined-weather.component.html',
  styleUrls: ['./edit-declined-weather.component.scss'],
})
export class EditDeclinedWeatherComponent implements OnInit {
  originalDate = '';
  withDrawlDate = '';
  title = '';
  newDate = '';
  reviewId = '';
  commitId = '';
  isDelete = false;

  formGroup = new FormGroup({
    reviseEventDate: new FormControl('', {
      validators: [dateValidator({ error: I18N_KEY.INVALID_DATE_FORMAT }), typhoonDateValiator({ error: I18N_KEY.INVALID_TYPHOON_DATE })],
    }),
    withdrawEventChange: new FormControl(false),
  });

  get reviseEventDate() {
    return this.formGroup.get('reviseEventDate') as FormControl;
  }

  get withdrawEventChange() {
    return this.formGroup.get('withdrawEventChange') as FormControl;
  }

  get disabled() {
    return !this.formGroup.dirty || this.formGroup.invalid || this.calendarService.formatTime(this.reviseEventDate.value) === this.newDate;
  }

  constructor(
    private dialogRef: MatDialogRef<EditDeclinedWeatherComponent>,
    private cdsPopup: CdPopupService,
    public calendarService: CalendarService,
    @Inject(CDS_POPUP_DATA)
    public data: {
      event: any;
    }
  ) {
    this.formGroup.valueChanges.subscribe(() => {
      this.reviseEventDate.enable({
        onlySelf: true,
        emitEvent: false,
      });
      this.withdrawEventChange.enable({
        onlySelf: true,
        emitEvent: false,
      });
      if (this.reviseEventDate.value) {
        this.withdrawEventChange.disable({
          onlySelf: true,
          emitEvent: false,
        });
      }
      if (this.withdrawEventChange.value) {
        this.reviseEventDate.disable({
          onlySelf: true,
          emitEvent: false,
        });
      }
    });
  }

  ngOnInit(): void {
    const event = this.data.event;
    this.title = event.title;
    this.commitId = event.commitId;
    this.newDate = event.eventDate;
    this.withDrawlDate = event.eventDate;
    this.reviewId = event.id;
    this.originalDate = event.originalDate;
    this.isDelete = event.eventAction === ActionEnum.DELETE;
    if (this.isDelete) {
      this.newDate = I18N_KEY.NORMAL_WORKING_DAY;
      this.originalDate = event.eventDate;
    }
  }

  cancle() {
    const popupRef: MatDialogRef<any> = this.cdsPopup.openCommon({
      type: CdPopupType.CONTINUE,
      data: {
        message: I18N_KEY.COMMON_ACTION_CANCLE,
      },
    });
    popupRef.afterClosed().subscribe(res => {
      if (res.continue) this.dialogRef.close();
    });
  }

  reSubmit() {
    if (this.disabled) {
      return;
    }
    const saveParams: any = {
      eventDate: this.calendarService.formatTime(this.reviseEventDate.value) || this.withDrawlDate,
      commitId: this.commitId,
      withdrawEventChange: this.withdrawEventChange.value,
    };
    this.calendarService.resubmitReportingEvents(saveParams).subscribe(res => {
      const popupRef: MatDialogRef<any> = this.cdsPopup.openCommon({
        data: {
          message: I18N_KEY_FOR_API_RES[res.message] || res.message,
        },
      });
      popupRef.afterClosed().subscribe(() => {
        if (res.result == ResponseResult.SUCCESS) {
          this.calendarService.declineEvents = this.calendarService.declineEvents.filter(item => item.id !== this.reviewId);
          this.dialogRef.close({
            eventType: this.data.event.eventTypeKey,
          });
        }
      });
    });
  }
}
