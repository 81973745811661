import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CdsLanguageModule } from '@cds/ng-core/lang';
import { CdsIconModule } from '@cds/ng-web-components/icon';

import { LanguageSwitchComponent } from './language-switch.component';

@NgModule({
  imports: [CommonModule, CdsIconModule, CdsLanguageModule],
  declarations: [LanguageSwitchComponent],
  exports: [LanguageSwitchComponent],
})
export class LanguageSwitchModule {}
