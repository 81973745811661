/* eslint-disable @typescript-eslint/no-explicit-any */
import { AfterViewInit, Component, OnInit, ElementRef, ViewChild, QueryList, ViewChildren } from '@angular/core';
import { CdsIconConfig } from '@cds/ng-core/icon';
import { ActionIcons } from '@cds/ng-themes';
import { IffDeclinedReasonList, ProductTypeList } from './iff-declined-dashboard.config';
import { AdviseEmpfrefComponent } from '../case-summary/advise-empfref/advise-empfref.component';
import { CdsPopupService, MatDialogRef } from '@cds/ng-web-components/popup';
import { getFilterParams } from 'src/app/utils/utils';
import moment from 'moment';
import { CdsChartColor } from '@cds/ng-charts/core';
import { CdsLangService } from '@cds/ng-core/lang';
import { TrusteeDetailsPopupComponent } from 'src/app/components/trustee-details-popup/trustee-details-popup.component';
import { CdHttpServeService } from 'src/app/shared/cd-http-serve/cd-http-serve.service';
import { CdsAlertService } from '@cds/ng-web-components/alert';
import { FileType, aoaToSheet } from 'src/app/utils/xlsx';
import { IffDeclinedDashboardService } from './iff-declined-dashboard.service';
import { Router } from '@angular/router';
import { CdMultidropdownTopping } from 'src/app/shared/cd-multidropdown/cd-multidropdown.model';
import { SalesJourneyProdType } from '../case-summary/case-summary.model';
import { ResolvePopupComponent } from './resolve-popup/resolve-popup.component';
import { NotifyService } from 'src/app/core/services/notify.service';
import { CdsOption } from '@cds/ng-core/configuration';

enum ResolvedStatus {
  all = 0,
  resolved = 1,
  unresolved = 2,
}

interface RequestParams {
  resolvedStatus: ResolvedStatus;
  iffDeclineDateFrom: string;
  iffDeclineDateTo: string;
  iffExpiryDateFrom: string;
  iffExpiryDateTo: string;
  productType: string[];
  districtCode: string[];
  agentCode: string;
  ABMs: string[];
  declineReasons: number[];
  empfStatus: number[];
  page: number;
  size: number;
}

interface RecordItem {
  empfRefNo: string;
  districtCd: string;
  agentCd: string;
  productType: string;
  clientOrEmployerName: string;
  hkidOrPassportNoOrBrNo: string;
  declineReason: string[];
  iffDeclineDt: string;
  iffExpiryDt: string;
  iffSubChannel: 'paper IFF' | 'eIFF';
  transferInDetails: string;
  salesJourneySummaryId: string;
  resolvedBy: string;
  resolvedDt: string;
  resovled: boolean;
}

interface DeclinedReasonData {
  color: string;
  declinedReason: string;
  noOfCases: number;
  percentage: string;
}

@Component({
  selector: 'app-iff-declined-dashboard',
  templateUrl: './iff-declined-dashboard.component.html',
  styleUrls: ['./iff-declined-dashboard.component.scss'],
})
export class IffDeclinedDashboardComponent implements OnInit, AfterViewInit {
  ResolvedStatus = ResolvedStatus;
  @ViewChild('tableBox', { static: false })
  tableBox?: ElementRef<HTMLDivElement>;
  @ViewChildren('tooltip') tooltipList!: QueryList<ElementRef<HTMLDivElement>>;
  iffDeclinedReasonList = IffDeclinedReasonList;
  productTypeList = ProductTypeList;
  excelDisplayedColumns = [
    'empfRefNo',
    'districtCd',
    'agentCd',
    'productType',
    'clientOrEmployerName',
    'hkidOrPassportNoOrBrNo',
    'declineReason',
    'iffDeclineDt',
    'iffExpiryDt',
    'iffSubChannel',
    'transferInDetails',
    'emailTriggeredBy',
    'emailTriggeredDt',
    'resolvedBy',
    'resolvedDt',
  ];
  displayedColumns = [
    'empfRefNo',
    'districtCd',
    'agentCd',
    'productType',
    'clientOrEmployerName',
    'hkidOrPassportNoOrBrNo',
    'declineReason',
    'iffDeclineDt',
    'iffExpiryDt',
    'iffSubChannel',
    'transferInDetails',
    'action',
  ];
  exportColumns = [
    'eMPF Ref#',
    'District Code',
    'Agent Code',
    'Product Type',
    'Client / Employer name',
    'HKID/Passport No.',
    'Declined reason',
    'IFF Declined Date',
    'IFF Expiry Date',
    'IFF submission channel',
    'Transfer-in Details',
    'Who Triggered The Email',
    'Email Triggered Date',
    'Who Resolved It',
    'Resolved Date',
  ];
  data?: {
    label: string;
    value: number;
    tooltip?: string;
  }[];
  isLoadPieChart = true;
  declinedReasonData: DeclinedReasonData[] = [
    {
      color: 'rgba(0, 167, 88, 1)',
      declinedReason: 'iff-declined-dashboard.case-cannot-match-with-e-data',
      noOfCases: 0,
      percentage: 'N/A',
    },
    {
      color: 'rgba(0, 0, 193, 1)',
      declinedReason: 'iff-declined-dashboard.client-hp-no-mis-with-e-data',
      noOfCases: 0,
      percentage: 'N/A',
    },
    {
      color: 'rgba(236, 100, 83, 1)',
      declinedReason: 'iff-declined-dashboard.employer-name-mismatched-with-eMPF-ddata',
      noOfCases: 0,
      percentage: 'N/A',
    },
    {
      color: 'rgba(40, 43, 62, 1)',
      declinedReason: 'iff-declined-dashboard.br-no-mis-with-e-data',
      noOfCases: 0,
      percentage: 'N/A',
    },
    {
      color: 'rgba(215, 125, 40, 1)',
      declinedReason: 'iff-declined-dashboard.agent-inf-mis-with-e-data',
      noOfCases: 0,
      percentage: 'N/A',
    },
  ];
  abmStatusoptionsOrigin: CdMultidropdownTopping[] = [];
  declinedReasonColumns: string[] = ['declinedReason', 'noOfCases', 'percentage'];
  requestParams!: RequestParams;
  abmOptionList: CdsOption[] = [];
  requestParamsForPageChange?: RequestParams;
  infoIcon = ActionIcons.info_1;
  iconConfig: CdsIconConfig = {
    size: 'sm',
    color: 'default',
  };
  iffDeclineDateFromMax!: Date;
  iffDeclineDateToMax!: Date;
  iffDeclineDateToMin!: Date;
  iffExpiryDateFromMax: Date = new Date('9000/01/01');
  iffExpiryDateToMin: Date = new Date('2000/01/01');
  resetDatePicker = false;
  chartColors: CdsChartColor[] = [
    { r: 0, g: 167, b: 88 },
    { r: 0, g: 0, b: 193 },
    { r: 236, g: 100, b: 83 },
    { r: 40, g: 43, b: 62 },
    { r: 215, g: 125, b: 40 },
  ];

  iffDeclinedReasonOrigin: CdMultidropdownTopping[] = [];
  productTypeOrigin: CdMultidropdownTopping[] = [];
  isLoading = false;
  allPageData: RecordItem[][] = [];
  currentPageNumOrigin = 1;
  totalElements = 0;
  totalPages = 0;
  districtCodeValue = '';
  iffDeclineDateFromError = false;
  iffDeclineDateToError = false;
  iffExpiryDateFromError = false;
  iffExpiryDateToError = false;
  today = new Date();
  readonly thirtyToToday = moment(new Date(new Date().getTime() - 30 * 24 * 60 * 60 * 1000).getTime())
    .startOf('day')
    .toDate();
  isCsvDownLoading = false;
  isCsvClickX = false;
  total = 0;
  resolveStatusSelected = ResolvedStatus.all;
  stepperTarget: 'pc' | 'ipad' = 'pc';
  uniqueCaseCount?: number;

  constructor(
    private cdsPopup: CdsPopupService,
    public cdsLangService: CdsLangService,
    private cdHttpServeService: CdHttpServeService,
    private alert: CdsAlertService,
    public iffDeclinedDashboardService: IffDeclinedDashboardService,
    private router: Router,
    private notifyService: NotifyService
  ) {}

  loadMailtoUrl(iffDeclinedReasons: string, referenceNo: string, productType: string) {
    const mailtoUrl =
      'mailto:?subject=請跟進被駁回的電子中介人履行職務表格！&body=' +
      //   'Dear Agent:' +
      //   '%0D%0A%0D%0A%0D%0APreviously you had been informed that a submitted MPF MPF Intermediary Fulfillment Form is rejected, please follow up as soon as possible to secure your rights as a servicing agent.' +
      //   '%0D%0A%0D%0AThe below submitted MPF Intermediary Fulfillment Form is rejected due to ' +
      //   iffDeclinedReasons +
      //   '. Please click below link now to login to iPension http://www.ssms.com.hk' +
      //   '%0D%0A%0D%0Aand submit the eIFF again.' +
      //   '%0D%0A%0D%0AThe eMPF application information are listed as follows.' +
      //   '%0D%0A%0D%0A%0D%0AClient Name:' +
      //   '%0D%0A%0D%0AeMPF Reference no.: ' +
      //   referenceNo +
      //   '%0D%0A%0D%0AProduct Type: ' +
      //   productType +
      //   '%0D%0A%0D%0A%0D%0APlease refer to the following declined reasons and follow-up with client:' +
      //   '%0D%0A%0D%0A%0D%0A*List of possible declined reasons:' +
      //   '%0D%0A%0D%0A        1.Client HKID/Passport No. mismatched with eMPF data' +
      //   '%0D%0A        2.Agent information mismatched with eMPF data' +
      //   '%0D%0A        3.eMPF reference No. mismatched with eMPF data' +
      //   '%0D%0A%0D%0A%0D%0AYours faithfully,' +
      //   '%0D%0A%0D%0AHong Kong Retirement';
      // return mailtoUrl;

      '代理人:' +
      '%0D%0A%0D%0A較早前已通知您已遞交的電子中介人履行職務表格被駁回，請盡快跟進以保障您的中介人權益。' +
      '%0D%0A以下已遞交的電子中介人履行職務表格因 ' +
      iffDeclinedReasons +
      ' 而被駁回。' +
      '%0D%0A%0D%0A請您立即按以下連結登入iPension http://www.ssms.com.hk 及重新為此客戶確認强積金中介人聲明。請於遞交前小心核對所提供的資訊與積金易平台上客戶提近的申請資訊一致。' +
      '%0D%0A以下列出了被駁回的eMPF申請資訊詳情：' +
      '%0D%0A%0D%0A客戶名稱：' +
      '%0D%0A積金易參考編號：' +
      referenceNo +
      '%0D%0A戶口類別：' +
      productType;
    // '%0D%0A%0D%0A請參考下列被駁回的原因及與客戶跟進:';
    return mailtoUrl;
  }

  openOutlook(element: RecordItem) {
    const reasons = element.declineReason.map(reason => this.iffDeclinedDashboardService.iffDeclinedReasonMatch(reason)?.text);
    window.location.href = this.loadMailtoUrl(reasons.join(', '), element.empfRefNo, element.productType);
    this.cdHttpServeService
      .post('/ext/eb-ssms-sales-journey-service/iffDeclineSummaries/emailTrigger', {
        salesJourneySummariesId: element.salesJourneySummaryId,
        empfRefNo: element.empfRefNo,
      })
      .subscribe({
        next: res => {
          if (res.result !== 0) {
            this.alert.warning('Warning!', `${res.message}`);
          } else {
            if (this.requestParamsForPageChange) {
              this.requestParamsForPageChange.page = 1;
              this.search(this.requestParamsForPageChange);
            }
          }
        },
        error: err => {
          this.alert.error('Error!', err);
        },
      });
  }

  get currentPageIndex() {
    return this.currentPageNumOrigin - 1;
  }

  get currentPageData() {
    if (this.allPageData.length === 0) {
      return [];
    }
    return this.allPageData[this.currentPageIndex];
  }

  get checkIsHaveScroll() {
    if (this.tableBox) {
      return this.tableBox.nativeElement.scrollWidth > this.tableBox.nativeElement.clientWidth;
    }
    return false;
  }

  ngOnInit(): void {
    this.getPieData();
    this.getUniqueCaseCount();
    this.initRequestParams();
    this.getABMList();
    const params = getFilterParams(this.requestParams);
    this.search(params);
  }

  getABMList() {
    this.cdHttpServeService.get<{ abm: string }[]>('/ext/eb-ssms-sales-journey-service/agent-branch/abm/list', {}).subscribe({
      next: res => {
        if (res.result !== 0) {
          this.alert.warning('Warning!', `${res.message}`);
        } else {
          res.data.forEach(e => {
            this.abmOptionList.push({ label: e.abm, value: e.abm });
          });
        }
      },
      error: err => {
        this.alert.error('Error!', err);
      },
    });
  }

  onDisplayAdviseRef() {
    this.cdsPopup.open(AdviseEmpfrefComponent, {
      size: 'lg',
    });
  }

  onDisplayTrusteeDetailsPopup() {
    this.cdsPopup.open(TrusteeDetailsPopupComponent, {
      size: 'lg',
    });
  }

  addToSearchDistrictCodeList() {
    if (this.districtCodeValue) {
      if (!this.requestParams.districtCode.includes(this.districtCodeValue)) {
        this.requestParams.districtCode.push(this.districtCodeValue);
        this.districtCodeValue = '';
      }
    }
  }

  removeSearchDistrictCodeList(item: string) {
    this.requestParams.districtCode = this.requestParams.districtCode.filter(val => val !== item);
  }

  goCaseDetails(item: RecordItem) {
    const queryParams = {
      from: 'iffDeclinedDashboard',
      productType: item.productType,
    };
    this.router.navigate([
      '/salesjourney',
      item.productType === SalesJourneyProdType.Corperate ? 'case-details-er' : 'case-details',
      item.salesJourneySummaryId,
      item.empfRefNo,
      queryParams,
    ]);
  }

  pageChange(num: number): void {
    if (this.requestParamsForPageChange) {
      this.requestParamsForPageChange.page = num;
      this.search(this.requestParamsForPageChange);
    }
  }

  search(params: RequestParams) {
    const productType = this.productTypeOrigin.map(item => item.value);
    if (productType.length > 0) {
      params.productType = productType;
    }
    if (this.abmStatusoptionsOrigin.length > 0) {
      params.ABMs = this.abmStatusoptionsOrigin.map(item => item.value);
    }
    const declineReasons = this.iffDeclinedReasonOrigin.map(item => item.value);
    if (declineReasons.length > 0) {
      params.declineReasons = declineReasons;
    }
    this.requestParamsForPageChange = params;
    this.isLoading = true;
    this.cdHttpServeService
      .post<{
        content: RecordItem[];
        totalElements: number;
        number: number;
        totalPages: number;
      }>('/ext/eb-ssms-sales-journey-service/iffDeclineSummaries', params)
      .subscribe({
        next: res => {
          if (res.result !== 0) {
            this.alert.warning('Warning!', `${res.message}`);
          } else {
            res.data.content = res.data.content || [];
            this.currentPageNumOrigin = res.data.number;
            this.allPageData[this.currentPageIndex] = res.data.content;
            this.totalElements = res.data.totalElements;
            this.totalPages = res.data.totalPages;
          }
          this.isLoading = false;
        },
        error: err => {
          this.alert.error('Error!', err);
          this.isLoading = false;
        },
      });
  }

  ngAfterViewInit(): void {
    if (window.matchMedia('only screen and (max-width: 1500px)').matches) {
      this.stepperTarget = 'ipad';
    } else {
      this.stepperTarget = 'pc';
    }

    const stopPropagation = (event: MouseEvent) => {
      event.stopPropagation();
    };

    this.tooltipList.changes.subscribe(() => {
      this.tooltipList.forEach(toolTip => {
        toolTip.nativeElement.removeEventListener('click', stopPropagation, false);
      });

      this.tooltipList.forEach(toolTip => {
        toolTip.nativeElement.addEventListener('click', stopPropagation, false);
      });
    });

    this.tooltipList.forEach(toolTip => {
      toolTip.nativeElement.addEventListener('click', stopPropagation, false);
    });
  }

  getPieData() {
    this.cdHttpServeService
      .post<{
        '0': number;
        '1': number;
        '2': number;
        '3': number;
        '4': number;
        '6': number;
      }>('/ext/eb-ssms-sales-journey-service/iffDeclineSummaries/pieData', {})
      .subscribe({
        next: res => {
          if (res.result !== 0) {
            this.alert.warning('Warning!', `${res.message}`);
          } else {
            const data = res.data;
            this.total = data['1'] + data['2'] + data['3'] + data['4'] + data['6'];

            setTimeout(() => {
              this.data = [
                {
                  label: '',
                  tooltip: this.cdsLangService.translate('iff-declined-dashboard.case-cannot-match-with-e-data'),
                  value: data['1'],
                },
                {
                  label: '',
                  tooltip: this.cdsLangService.translate('iff-declined-dashboard.client-hp-no-mis-with-e-data'),
                  value: data['2'],
                },
                {
                  label: '',
                  tooltip: this.cdsLangService.translate('iff-declined-dashboard.employer-name-mismatched-with-eMPF-ddata'),
                  value: data['3'],
                },
                {
                  label: '',
                  tooltip: this.cdsLangService.translate('iff-declined-dashboard.br-no-mis-with-e-data'),
                  value: data['4'],
                },
                {
                  label: '',
                  tooltip: this.cdsLangService.translate('iff-declined-dashboard.agent-inf-mis-with-e-data'),
                  value: data['6'],
                },
              ];
            }, 0);

            this.declinedReasonData = [
              {
                color: 'rgba(0, 167, 88, 1)',
                declinedReason: 'iff-declined-dashboard.case-cannot-match-with-e-data',
                noOfCases: data['1'],
                percentage: this.declinedReasonDataPercentage(data['1'], this.total),
              },
              {
                color: 'rgba(0, 0, 193, 1)',
                declinedReason: 'iff-declined-dashboard.client-hp-no-mis-with-e-data',
                noOfCases: data['2'],
                percentage: this.declinedReasonDataPercentage(data['2'], this.total),
              },
              {
                color: 'rgba(236, 100, 83, 1)',
                declinedReason: 'iff-declined-dashboard.employer-name-mismatched-with-eMPF-ddata',
                noOfCases: data['3'],
                percentage: this.declinedReasonDataPercentage(data['3'], this.total),
              },
              {
                color: 'rgba(40, 43, 62, 1)',
                declinedReason: 'iff-declined-dashboard.br-no-mis-with-e-data',
                noOfCases: data['4'],
                percentage: this.declinedReasonDataPercentage(data['4'], this.total),
              },
              {
                color: 'rgba(215, 125, 40, 1)',
                declinedReason: 'iff-declined-dashboard.agent-inf-mis-with-e-data',
                noOfCases: data['6'],
                percentage: this.declinedReasonDataPercentage(data['6'], this.total),
              },
            ];
          }
          this.isLoadPieChart = false;
        },
        error: err => {
          this.alert.error('Error!', err);
          this.isLoadPieChart = false;
        },
      });
  }

  getUniqueCaseCount() {
    this.cdHttpServeService.post<{ count: number }>('/ext/eb-ssms-sales-journey-service/iffDeclineSummaries/uniqueCaseCount', {}).subscribe({
      next: res => {
        if (res.result !== 0) {
          this.alert.warning('Warning!', `${res.message}`);
        } else {
          this.uniqueCaseCount = res.data.count;
        }
      },
      error: err => {
        this.alert.error('Error!', err);
      },
    });
  }

  declinedReasonDataPercentage(num: number, total: number) {
    if (total === 0) {
      return `0 %`;
    } else {
      return `${Math.round((num / total) * 100)} %`;
    }
  }

  initRequestParams() {
    this.iffDeclineDateFromMax = this.today;
    this.iffDeclineDateToMax = this.today;
    this.iffDeclineDateToMin = this.thirtyToToday;
    this.iffDeclineDateFromError = false;
    this.iffDeclineDateToError = false;
    this.iffExpiryDateFromError = false;
    this.iffExpiryDateToError = false;
    this.districtCodeValue = '';
    this.requestParams = {
      page: 1,
      size: 50,
      iffDeclineDateFrom: moment(this.thirtyToToday).format('DD/MM/YYYY'),
      iffDeclineDateTo: moment(this.today).format('DD/MM/YYYY'),
      iffExpiryDateFrom: '',
      iffExpiryDateTo: '',
      productType: [],
      districtCode: [],
      ABMs: [],
      agentCode: '',
      declineReasons: [],
      empfStatus: [],
      resolvedStatus: ResolvedStatus.all,
    };
  }

  get isDisableSearch() {
    return (
      (this.requestParams.iffDeclineDateFrom === '' &&
        this.requestParams.iffDeclineDateTo === '' &&
        this.requestParams.iffExpiryDateFrom === '' &&
        this.requestParams.iffExpiryDateTo === '' &&
        this.productTypeOrigin.length === 0 &&
        this.requestParams.districtCode.length === 0 &&
        this.requestParams.agentCode === '' &&
        this.requestParams.ABMs.length === 0 &&
        this.iffDeclinedReasonOrigin.length === 0) ||
      this.requestParams.iffDeclineDateFrom === ''
    );
  }

  onClickReset() {
    this.resolveStatusSelected = ResolvedStatus.all;
    this.productTypeOrigin = [];
    this.iffDeclinedReasonOrigin = [];
    this.requestParams.districtCode = [];
    this.requestParams.ABMs = [];
    this.abmStatusoptionsOrigin.length = 0;
    this.onResetDatePicker();
    this.initRequestParams();
    const params = getFilterParams(this.requestParams);
    this.search(params);
  }

  onResetDatePicker() {
    this.resetDatePicker = true;
    setTimeout(() => {
      this.resetDatePicker = false;
    }, 0);
  }

  onClickSearch() {
    if (this.isDisableSearch || this.isLoading) {
      return;
    }
    this.resolveStatusSelected = ResolvedStatus.all;
    this.requestParams.page = 1;
    const params = getFilterParams(this.requestParams);
    this.search(params);
  }

  iffDeclineDateFromChange(value: string) {
    if (value === '') {
      this.iffDeclineDateFromError = false;
      return;
    }
    this.requestParams.iffDeclineDateFrom = value;
    const temp = this.requestParams.iffDeclineDateFrom.split('/');
    const selectedDate = new Date([temp[1], temp[0], temp[2]].join('/'));

    if (selectedDate.toString() === 'Invalid Date' || selectedDate > this.today) {
      this.iffDeclineDateFromError = true;
      return;
    }
    if (this.requestParams.iffDeclineDateTo) {
      const tempTo = this.requestParams.iffDeclineDateTo.split('/');
      const tempToDate = new Date([tempTo[1], tempTo[0], tempTo[2]].join('/'));
      if (selectedDate > tempToDate) {
        this.iffDeclineDateFromError = true;
        return;
      }
    }
    this.iffDeclineDateFromError = false;

    this.iffDeclineDateToMin = selectedDate;
  }

  iffDeclineDateToChange(value: string) {
    if (value === '') {
      this.iffDeclineDateToError = false;
      return;
    }
    this.requestParams.iffDeclineDateTo = value;
    const temp = this.requestParams.iffDeclineDateTo.split('/');
    const selectedDate = new Date([temp[1], temp[0], temp[2]].join('/'));
    if (selectedDate.toString() === 'Invalid Date' || selectedDate > this.today || selectedDate < this.iffDeclineDateToMin) {
      this.iffDeclineDateToError = true;
      return;
    }
    if (this.requestParams.iffDeclineDateFrom) {
      const tempFrom = this.requestParams.iffDeclineDateFrom.split('/');
      const tempFromDate = new Date([tempFrom[1], tempFrom[0], tempFrom[2]].join('/'));
      if (selectedDate < tempFromDate) {
        this.iffDeclineDateToError = true;
        return;
      }
    }
    this.iffDeclineDateToError = false;

    this.iffDeclineDateFromMax = selectedDate;
  }

  iffExpiryDateFromChange(value: string) {
    if (value === '') {
      this.iffExpiryDateFromError = false;
      return;
    }
    this.requestParams.iffExpiryDateFrom = value;
    const temp = this.requestParams.iffExpiryDateFrom.split('/');
    const selectedDate = new Date([temp[1], temp[0], temp[2]].join('/'));

    if (selectedDate.toString() === 'Invalid Date') {
      this.iffExpiryDateFromError = true;
      return;
    }
    if (this.requestParams.iffExpiryDateTo) {
      const tempTo = this.requestParams.iffExpiryDateTo.split('/');
      const tempToDate = new Date([tempTo[1], tempTo[0], tempTo[2]].join('/'));
      if (selectedDate > tempToDate) {
        this.iffExpiryDateFromError = true;
        return;
      }
    }
    this.iffExpiryDateFromError = false;

    this.iffExpiryDateToMin = selectedDate;
  }

  iffExpiryDateToChange(value: string) {
    if (value === '') {
      this.iffExpiryDateToError = false;
      return;
    }
    this.requestParams.iffExpiryDateTo = value;
    const temp = this.requestParams.iffExpiryDateTo.split('/');
    const selectedDate = new Date([temp[1], temp[0], temp[2]].join('/'));

    if (selectedDate.toString() === 'Invalid Date') {
      this.iffExpiryDateToError = true;
      return;
    }
    if (this.requestParams.iffExpiryDateFrom) {
      const tempFrom = this.requestParams.iffExpiryDateFrom.split('/');
      const tempFromDate = new Date([tempFrom[1], tempFrom[0], tempFrom[2]].join('/'));
      if (selectedDate < tempFromDate) {
        this.iffExpiryDateToError = true;
        return;
      }
    }
    this.iffExpiryDateToError = false;

    this.iffExpiryDateFromMax = selectedDate;
  }

  get isExportBtnDisabled() {
    return this.currentPageData.length === 0;
  }

  exportResult() {
    if (this.isExportBtnDisabled) {
      return;
    }
    this.isCsvDownLoading = true;
    this.cdHttpServeService
      .post<RecordItem[]>('/ext/eb-ssms-sales-journey-service/iffDeclineSummaries/download', this.requestParamsForPageChange || {})
      .subscribe({
        next: res => {
          if (this.isCsvClickX) {
            this.isCsvClickX = false;
            return;
          }
          if (res.result !== 0) {
            this.alert.warning('Warning!', `${res.message}`);
          } else {
            const excelData = this.createExcelData(res.data || []);
            aoaToSheet(
              {
                fileName: `IFF declined list ${moment().format('YYYYMMDD_HHmmss')}`,
                fileType: FileType.XLSX,
              },
              excelData
            );
          }
          this.isCsvDownLoading = false;
          this.isCsvClickX = false;
        },
        error: err => {
          this.alert.error('Error!', err);
          this.isCsvDownLoading = false;
          this.isCsvClickX = false;
        },
      });
  }

  createExcelData(data: any[]) {
    const excelData: any[] = [];
    excelData.push(this.exportColumns);
    data.forEach(item => {
      const temp: string[] = [];
      this.excelDisplayedColumns.forEach(col => {
        if (col === 'declineReason') {
          if (item['declineReason']) {
            let reasons = '';
            item['declineReason'].forEach((reason: string, index: number) => {
              if (index > 0) {
                reasons += '\n';
              }
              reasons += this.getDeclineReasonNameByValue(reason);
            });
            temp.push(reasons);
          }
        } else {
          temp.push(item[col]);
        }
      });
      excelData.push(temp);
    });
    return excelData;
  }

  getDeclineReasonNameByValue(value: string) {
    const d = IffDeclinedReasonList.filter(item => item.value === value);
    if (d.length === 1) {
      return d[0].label;
    }
    return 'N/A';
  }

  onClickX() {
    this.isCsvClickX = true;
    this.isCsvDownLoading = false;
  }

  checkedResolveStatusTag(resolvedStatus: ResolvedStatus) {
    this.resolveStatusSelected = resolvedStatus;
    this.requestParams.page = 1;
    this.requestParams.resolvedStatus = resolvedStatus;
    const params = getFilterParams(this.requestParams);
    this.search(params);
  }

  onCanDelegateAmtToggle(event: MouseEvent) {
    if (event.target && this.stepperTarget === 'pc') {
      event.target.dispatchEvent(new Event('click'));
    }
  }

  resolve(salesJourneySummaryId: string) {
    const popupRef: MatDialogRef<ResolvePopupComponent> = this.cdsPopup.open(ResolvePopupComponent, {
      size: 'sm',
    });

    popupRef.afterClosed().subscribe(confirm => {
      if (confirm.agree) {
        this.callResolveApi(salesJourneySummaryId);
      }
    });
  }

  callResolveApi(salesJourneySummaryId: string) {
    this.notifyService.screenLoadingSubject.next(true);
    this.cdHttpServeService
      .post('/ext/eb-ssms-sales-journey-service/iffDeclineSummaries/resolve', {
        salesJourneySummariesId: salesJourneySummaryId,
      })
      .subscribe({
        next: res => {
          if (res.result !== 0) {
            this.alert.warning('Warning!', `${res.message}`);
          } else {
            if (this.requestParamsForPageChange) {
              this.requestParamsForPageChange.page = 1;
              this.search(this.requestParamsForPageChange);
            }
          }
          this.notifyService.screenLoadingSubject.next(false);
        },
        error: err => {
          this.alert.error('Error!', err);
          this.notifyService.screenLoadingSubject.next(false);
        },
      });
  }
}
