import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-resolve-popup',
  templateUrl: './resolve-popup.component.html',
  styleUrls: ['./resolve-popup.component.scss'],
})
export class ResolvePopupComponent implements OnInit {
  constructor(private dialogRef: MatDialogRef<ResolvePopupComponent>) {}

  ngOnInit(): void {}

  cancel() {
    this.dialogRef.close({ agree: false });
  }

  continue() {
    this.dialogRef.close({ agree: true });
  }
}
