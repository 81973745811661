import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { catchError, timeout } from 'rxjs/operators';
import { Observable, of, Subject } from 'rxjs';
import { EmpfDetailsResponse } from './model/empf-details.model';
import { TransferDetailsResponse } from './model/transfer-details.model';
import { CaseDetailRequest } from './model/case-detail-request.model';

@Injectable({
  providedIn: 'root',
})
export class CaseDetailsService {
  empfDetailsUrl = `${environment.apiPrefix}/ext/eb-ssms-sales-journey-service//memberSalesJourneySummaries/empfDetails`;
  transferDetailsUrl = `${environment.apiPrefix}/ext/eb-ssms-sales-journey-service/memberSalesJourneySummaries/transferDetails`;

  constructor(private httpClient: HttpClient) {}
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  empfDetailsSubject = new Subject<EmpfDetailsResponse>();
  transferDetailsSubject = new Subject<TransferDetailsResponse>();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  errorCaseDetailSubject = new Subject<any>();

  getEmpfDetails(caseDetailRequest: CaseDetailRequest) {
    this.httpClient
      .post(this.empfDetailsUrl, caseDetailRequest, this.httpOptions)
      .pipe(timeout(10000), catchError(this.handleError<EmpfDetailsResponse>()))
      .subscribe((resp: EmpfDetailsResponse) => {
        this.empfDetailsSubject.next(resp);
      });
  }

  getTransferDetails(caseDetailRequest: CaseDetailRequest) {
    this.httpClient
      .post(this.transferDetailsUrl, caseDetailRequest, this.httpOptions)
      .pipe(timeout(10000), catchError(this.handleError<TransferDetailsResponse>()))
      .subscribe((resp: TransferDetailsResponse) => {
        this.transferDetailsSubject.next(resp);
      });
  }

  handleError<T>(result?: T) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (error: any): Observable<T> => {
      this.errorCaseDetailSubject.next(`${error.message}`);
      return of(result as T);
    };
  }
}
