<div class="branch-level-summary">
  <div class="head-box">
    <span class="head">All eIFF Summary</span>
  </div>

  <div class="content-box">
    <div>
      <div>
        <span class="cds-body2">Choose product type</span>
      </div>

      <div class="l-mt-4">
        <cds-dropdown
          class="choose-product-type-dropdown"
          (change)="prodTypeChange($event)"
          [formControl]="baseParams.prodTypeFormControl"
          [config]="baseParams.prodTypeDropdownConfig"></cds-dropdown>
      </div>

      <div class="l-mt-7">
        <span class="cds-h2-light">Search record</span>
      </div>

      <div class="row l-d-f padding-none" *ngIf="baseParams.requestParams">
        <ng-container *ngIf="!isERReqeuestParams(baseParams.requestParams)">
          <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12 search-item">
            <span class="cds-body2" style="color: var(--cds-text-disabled)">{{ 'sales-support-summary.eMPF-ref' | lang }}</span>
            <app-cd-input
              class="text-field"
              [(value)]="baseParams.requestParams.empfRefNo"
              [placeholder]="'common.input' | lang : { p1: 'sales-support-summary.eMPF-ref' | lang }"></app-cd-input>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12 search-item">
            <span class="cds-body2" style="color: var(--cds-text-disabled)">{{ 'sales-support-summary.client-name' | lang }}</span>
            <app-cd-input
              class="text-field"
              [(value)]="baseParams.requestParams.clientName"
              [placeholder]="'common.input' | lang : { p1: 'sales-support-summary.client-name' | lang }"></app-cd-input>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12 search-item">
            <span class="cds-body2" style="color: var(--cds-text-disabled)">{{ 'sales-support-summary.agent-code' | lang }}</span>
            <app-cd-input
              class="text-field"
              [(value)]="baseParams.requestParams.agentCd"
              [placeholder]="'common.input' | lang : { p1: 'sales-support-summary.agent-code' | lang }"></app-cd-input>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12 search-item">
            <span class="cds-body2" style="color: var(--cds-text-disabled)">{{ 'sales-support-summary.district-code' | lang }}</span>
            <app-cd-input
              class="text-field"
              [(value)]="baseParams.requestParams.districtCd"
              [placeholder]="'common.input' | lang : { p1: 'sales-support-summary.district-code' | lang }"></app-cd-input>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12 search-item">
            <span class="cds-body2" style="color: var(--cds-text-disabled)">{{ 'sales-support-summary.abm' | lang }}</span>
            <app-cd-multidropdown
              [style.width]="'100%'"
              [placeholder]="'common.plsSelect' | lang : { p1: 'sales-support-summary.abm' | lang }"
              [toppingList]="abmOptionList"
              [(selected)]="baseParams.abmStatusoptionsOrigin"></app-cd-multidropdown>
          </div>
          <app-date-range-box class="col-lg-6 col-xs-12 l-plr-0">
            <div *ngIf="!baseParams.resetDatePicker" left>
              <cds-textfield
                class="prodtype-date-align"
                [(ngModel)]="baseParams.requestParams.iffExpiryDateFrom"
                #iFFExpiryDateFromVal
                (ngModelChange)="iFFExpiryDateFromValueChange(iFFExpiryDateFromVal.value)"
                type="text"
                placeholder="MM/DD/YYYY"
                [label]="'sales-support-summary.iFF-expiry-date-from' | lang"
                [cdsDatepicker]="pickerFromDate"
                [max]="baseParams.iFFExpiryDateFromValMax"
                [min]="baseParams.iFFExpiryDateFromValMin"></cds-textfield>
              <cds-datepicker #pickerFromDate="cdsDatepicker"></cds-datepicker>
              <cds-assistive-text *ngIf="baseParams.iFFExpiryDateFromValueError" class="l-mt-3">{{ 'common.invalidDate' | lang }}</cds-assistive-text>
            </div>
            <div *ngIf="!baseParams.resetDatePicker" right>
              <cds-textfield
                class="prodtype-date-align"
                [(ngModel)]="baseParams.requestParams.iffExpiryDateTo"
                #iFFExpiryDateToVal
                (ngModelChange)="iFFExpiryDateToValueChange(iFFExpiryDateToVal.value)"
                type="text"
                placeholder="MM/DD/YYYY"
                [label]="'sales-support-summary.iFF-expiry-date-to' | lang"
                [cdsDatepicker]="pickerToDate"
                [max]="baseParams.iFFExpiryDateToValMax"
                [min]="baseParams.iFFExpiryDateToValMin"></cds-textfield>
              <cds-datepicker #pickerToDate="cdsDatepicker"></cds-datepicker>
              <cds-assistive-text *ngIf="baseParams.iFFExpiryDateToValueError" class="l-mt-3">{{ 'common.invalidDate' | lang }}</cds-assistive-text>
            </div>
          </app-date-range-box>
          <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12 search-item">
            <span class="cds-body2" style="color: var(--cds-text-disabled)">{{ 'sales-support-summary.HKID-or-passport-no' | lang }}</span>
            <app-cd-input
              class="text-field"
              [(value)]="baseParams.requestParams.hkidOrPassportNo"
              [placeholder]="'common.input' | lang : { p1: 'sales-support-summary.HKID-or-passport-no' | lang }"></app-cd-input>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12 search-item">
            <span class="cds-body2" style="color: var(--cds-text-disabled)">{{ 'sales-support-summary.eIFF-status' | lang }}</span>
            <app-cd-multidropdown
              [style.width]="'100%'"
              [placeholder]="'common.plsSelect' | lang : { p1: 'sales-support-summary.eIFF-status' | lang }"
              [toppingList]="iffStatusoptionList"
              [(selected)]="baseParams.iffStatusoptionsOrigin"></app-cd-multidropdown>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12 search-item">
            <span class="cds-body2" style="color: var(--cds-text-disabled)">{{ 'sales-support-summary.declined-reason' | lang }}</span>
            <app-cd-multidropdown
              [style.width]="'100%'"
              [toppingList]="declinedReasonOptionList"
              [(selected)]="baseParams.declinedReasonOptionsOrigin"
              [placeholder]="'common.plsSelect' | lang : { p1: 'sales-support-summary.declined-reason' | lang }"></app-cd-multidropdown>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12 search-item">
            <span class="cds-body2" style="color: var(--cds-text-disabled)">{{ 'sales-support-summary.application-status-on-eMPF-portal' | lang }}</span>
            <app-cd-multidropdown
              [style.width]="'100%'"
              [toppingList]="appStatusoptionList"
              [(selected)]="baseParams.appStatusoptionsOrigin"
              [placeholder]="'common.plsSelect' | lang : { p1: 'sales-support-summary.application-status-on-eMPF-portal' | lang }"></app-cd-multidropdown>
          </div>
        </ng-container>

        <ng-container *ngIf="isERReqeuestParams(baseParams.requestParams)">
          <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12 search-item">
            <span class="cds-body2" style="color: var(--cds-text-disabled)">{{ 'sales-support-summary.eMPF-ref' | lang }}</span>
            <app-cd-input
              class="text-field"
              [(value)]="baseParams.requestParams.empfRefNo"
              [placeholder]="'common.input' | lang : { p1: 'sales-support-summary.eMPF-ref' | lang }"></app-cd-input>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12 search-item">
            <span class="cds-body2" style="color: var(--cds-text-disabled)">{{ 'sales-support-summary.employer-name' | lang }}</span>
            <app-cd-input
              class="text-field"
              [(value)]="baseParams.requestParams.companyName"
              [placeholder]="'common.input' | lang : { p1: 'sales-support-summary.employer-name' | lang }"></app-cd-input>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12 search-item">
            <span class="cds-body2" style="color: var(--cds-text-disabled)">{{ 'sales-support-summary.agent-code' | lang }}</span>
            <app-cd-input
              class="text-field"
              [(value)]="baseParams.requestParams.agentCd"
              [placeholder]="'common.input' | lang : { p1: 'sales-support-summary.agent-code' | lang }"></app-cd-input>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12 search-item">
            <span class="cds-body2" style="color: var(--cds-text-disabled)">{{ 'sales-support-summary.district-code' | lang }}</span>
            <app-cd-input
              class="text-field"
              [(value)]="baseParams.requestParams.districtCd"
              [placeholder]="'common.input' | lang : { p1: 'sales-support-summary.district-code' | lang }"></app-cd-input>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12 search-item">
            <span class="cds-body2" style="color: var(--cds-text-disabled)">{{ 'sales-support-summary.abm' | lang }}</span>
            <app-cd-multidropdown
              [style.width]="'100%'"
              [placeholder]="'common.plsSelect' | lang : { p1: 'sales-support-summary.abm' | lang }"
              [toppingList]="abmOptionList"
              [(selected)]="baseParams.abmStatusoptionsOrigin"></app-cd-multidropdown>
          </div>
          <app-date-range-box class="col-lg-6 col-xs-12 l-plr-0">
            <div *ngIf="!baseParams.resetDatePicker" left>
              <cds-textfield
                class="prodtype-date-align"
                [(ngModel)]="baseParams.requestParams.iffExpiryDateFrom"
                #iFFExpiryDateFromVal
                (ngModelChange)="iFFExpiryDateFromValueChange(iFFExpiryDateFromVal.value)"
                type="text"
                placeholder="MM/DD/YYYY"
                [label]="'sales-support-summary.iFF-expiry-date-from' | lang"
                [cdsDatepicker]="pickerFromDate"
                [max]="baseParams.iFFExpiryDateFromValMax"
                [min]="baseParams.iFFExpiryDateFromValMin"></cds-textfield>
              <cds-datepicker #pickerFromDate="cdsDatepicker"></cds-datepicker>
              <cds-assistive-text *ngIf="baseParams.iFFExpiryDateFromValueError" class="l-mt-3">{{ 'common.invalidDate' | lang }}</cds-assistive-text>
            </div>
            <div *ngIf="!baseParams.resetDatePicker" right>
              <cds-textfield
                class="prodtype-date-align"
                [(ngModel)]="baseParams.requestParams.iffExpiryDateTo"
                #iFFExpiryDateToVal
                (ngModelChange)="iFFExpiryDateToValueChange(iFFExpiryDateToVal.value)"
                type="text"
                placeholder="MM/DD/YYYY"
                [label]="'sales-support-summary.iFF-expiry-date-to' | lang"
                [cdsDatepicker]="pickerToDate"
                [max]="baseParams.iFFExpiryDateToValMax"
                [min]="baseParams.iFFExpiryDateToValMin"></cds-textfield>
              <cds-datepicker #pickerToDate="cdsDatepicker"></cds-datepicker>
              <cds-assistive-text *ngIf="baseParams.iFFExpiryDateToValueError" class="l-mt-3">{{ 'common.invalidDate' | lang }}</cds-assistive-text>
            </div>
          </app-date-range-box>
          <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12 search-item">
            <span class="cds-body2" style="color: var(--cds-text-disabled)">{{ 'sales-support-summary.business-registration-number' | lang }}</span>
            <app-cd-input
              class="text-field"
              [(value)]="baseParams.requestParams.brNo"
              [placeholder]="'common.input' | lang : { p1: 'sales-support-summary.business-registration-number' | lang }"></app-cd-input>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12 search-item">
            <span class="cds-body2" style="color: var(--cds-text-disabled)">{{ 'sales-support-summary.eIFF-status' | lang }}</span>
            <app-cd-multidropdown
              [style.width]="'100%'"
              [placeholder]="'common.plsSelect' | lang : { p1: 'sales-support-summary.eIFF-status' | lang }"
              [toppingList]="iffStatusoptionList"
              [(selected)]="baseParams.iffStatusoptionsOrigin"></app-cd-multidropdown>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12 search-item">
            <span class="cds-body2" style="color: var(--cds-text-disabled)">{{ 'sales-support-summary.declined-reason' | lang }}</span>
            <app-cd-multidropdown
              [style.width]="'100%'"
              [toppingList]="declinedReasonOptionListER"
              [(selected)]="baseParams.declinedReasonOptionsOrigin"
              [placeholder]="'common.plsSelect' | lang : { p1: 'sales-support-summary.declined-reason' | lang }"></app-cd-multidropdown>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12 search-item">
            <span class="cds-body2" style="color: var(--cds-text-disabled)">{{ 'sales-support-summary.application-status-on-eMPF-portal' | lang }}</span>
            <app-cd-multidropdown
              [style.width]="'100%'"
              [toppingList]="appStatusoptionList"
              [(selected)]="baseParams.appStatusoptionsOrigin"
              [placeholder]="'common.plsSelect' | lang : { p1: 'sales-support-summary.application-status-on-eMPF-portal' | lang }"></app-cd-multidropdown>
          </div>
        </ng-container>

        <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12 search-item bottom-box">
          <cds-button [disabled]="isDisableSearch || baseParams.isLoading" size="sm" class="l-mr-4" (click)="onClickSearch()">{{
            'common.search1' | lang
          }}</cds-button>
          <cds-button [style]="'secondary'" size="sm" (click)="onClickReset()">{{ 'common.reset1' | lang }}</cds-button>
        </div>
      </div>

      <div style="margin-top: 3.5rem">
        <span class="cds-h2-light">Record list</span>
      </div>

      <div class="l-mt-2">
        <span class="cds-body1">Total {{ baseParams.totalElements | number }} records</span>
      </div>

      <div class="l-d-f l-ai-cen l-mt-5" *ngIf="baseParams.prodTypeFormControl.value === SalesJourneyProdType.Individual">
        <div class="font-label l-mr-4">Product Type:</div>
        <div
          [ngClass]="['button-style', baseParams.individualTypeSelected === IndividualType.ALL ? 'checked' : '']"
          (click)="checkIndividualTypeTag(IndividualType.ALL)">
          All Product Type
        </div>
        <div
          [ngClass]="['button-style', baseParams.individualTypeSelected === IndividualType.PA ? 'checked' : '']"
          (click)="checkIndividualTypeTag(IndividualType.PA)">
          PA
        </div>
        <div
          [ngClass]="['button-style', baseParams.individualTypeSelected === IndividualType.TVC ? 'checked' : '']"
          (click)="checkIndividualTypeTag(IndividualType.TVC)">
          TVC
        </div>
        <div
          [ngClass]="['button-style', baseParams.individualTypeSelected === IndividualType.FRC ? 'checked' : '']"
          (click)="checkIndividualTypeTag(IndividualType.FRC)">
          FRC
        </div>
      </div>

      <div class="l-d-f l-ai-cen l-mt-2">
        <div class="font-label l-mr-7">IFF status:</div>
        <div [ngClass]="['button-style', baseParams.iffStatusTagSelected === IFFStatusTag.ALL ? 'checked' : '']" (click)="checkIFFStatusTag(IFFStatusTag.ALL)">
          All IFF status
        </div>
        <div
          [ngClass]="['button-style', baseParams.iffStatusTagSelected === IFFStatusTag.Completed ? 'checked' : '']"
          (click)="checkIFFStatusTag(IFFStatusTag.Completed)">
          Completed
        </div>
        <div
          [ngClass]="['button-style', baseParams.iffStatusTagSelected === IFFStatusTag.Declined ? 'checked' : '']"
          (click)="checkIFFStatusTag(IFFStatusTag.Declined)">
          Declined
        </div>
      </div>

      <div class="l-mt-5">
        <span class="cds-body2" style="color: var(--cds-text-disabled)">*The bold and starred code represents Servicing Agent</span>
      </div>

      <div class="l-mt-5">
        <app-no-result-found
          [msg]="'common.noPtsAreFound'"
          [style.display]="currentPageData.length === 0 && !baseParams.isLoading ? 'block' : 'none'"></app-no-result-found>
        <div [style.display]="currentPageData.length > 0 ? 'flex' : 'none'" class="row l-plr-0">
          <div #tableBox class="table-wrapper" [ngClass]="[baseParams.isLoading ? 'loading' : '']">
            <cds-table
              *ngIf="!isERReqeuestParams(baseParams.requestParams)"
              class="col-lg-12 row l-plr-0"
              [dataSource]="EECurrentPageData"
              [class.full]="false">
              <cds-row *cdsRowDef="let row; index as i; columns: eeDisplayedColumns"></cds-row>
              <cds-header-row *cdsHeaderRowDef="eeDisplayedColumns"></cds-header-row>
              <ng-container cdsColumnDef="empfRefNo" sticky>
                <cds-header-cell *cdsHeaderCellDef class="e-width" [ngClass]="[checkIsHaveScroll ? 'shadow-right' : '']">
                  <div class="prodtype-displaytooltip">
                    <span>{{ 'sales-support-summary.eMPF-ref' | lang }}</span>
                    <cds-icon class="note" (click)="onDisplayAdviseRef()" [icon]="infoIcon" [config]="iconConfig"></cds-icon>
                  </div>
                </cds-header-cell>
                <cds-cell *cdsCellDef="let element" class="e-width" [ngClass]="[checkIsHaveScroll ? 'shadow-right' : '']">
                  <app-cd-fake-link style="word-break: break-all" (callBack)="goCaseDetails(element)" [text]="element.empfRefNo"></app-cd-fake-link>
                </cds-cell>
              </ng-container>

              <ng-container cdsColumnDef="districtCd">
                <cds-header-cell *cdsHeaderCellDef class="district-code-width">{{ 'sales-support-summary.district-code' | lang }}</cds-header-cell>
                <cds-cell *cdsCellDef="let element" class="district-code-width">
                  <div *ngFor="let item of element.districtCd">{{ item }}</div>
                </cds-cell>
              </ng-container>

              <ng-container cdsColumnDef="agentCd">
                <cds-header-cell *cdsHeaderCellDef class="agent-code-width">{{ 'sales-support-summary.agent-code' | lang }}</cds-header-cell>
                <cds-cell *cdsCellDef="let element" class="agent-code-width">
                  <div *ngFor="let item of element.agentCd; let i = index">
                    <span
                      [ngClass]="{
                        'service-agent': i === 0
                      }">
                      {{ item }}{{ i === 0 ? '*' : '' }}
                    </span>
                  </div>
                </cds-cell>
              </ng-container>

              <ng-container cdsColumnDef="productType">
                <cds-header-cell *cdsHeaderCellDef class="product-type-width">{{ 'sales-support-summary.product-type' | lang }}</cds-header-cell>
                <cds-cell *cdsCellDef="let element" class="product-type-width">
                  {{ element.productType }}
                </cds-cell>
              </ng-container>

              <ng-container cdsColumnDef="clientName">
                <cds-header-cell *cdsHeaderCellDef class="client-name-width">{{ 'sales-support-summary.client-name' | lang }}</cds-header-cell>
                <cds-cell *cdsCellDef="let element" class="client-name-width">
                  {{ element.clientName }}
                </cds-cell>
              </ng-container>
              <ng-container cdsColumnDef="hkidOrPassportNo">
                <cds-header-cell *cdsHeaderCellDef class="hkid-or-passport-no-width">{{ 'sales-support-summary.HKID-or-passport-no' | lang }}</cds-header-cell>
                <cds-cell *cdsCellDef="let element" class="hkid-or-passport-no-width">
                  {{ element.hkidOrPassportNo }}
                </cds-cell>
              </ng-container>
              <ng-container cdsColumnDef="iffExpiryDate">
                <cds-header-cell *cdsHeaderCellDef class="eiff-expiry-date-width">{{ 'sales-support-summary.eIFF-expiry-date' | lang }}</cds-header-cell>
                <cds-cell *cdsCellDef="let element" class="eiff-expiry-date-width">
                  {{ element.iffExpiryDate }}
                </cds-cell>
              </ng-container>
              <ng-container cdsColumnDef="iffStatusEnum">
                <cds-header-cell *cdsHeaderCellDef class="eiff-status-width">
                  <div class="prodtype-displaytooltip">
                    <span>{{ 'sales-support-summary.eIFF-status' | lang }}</span>
                    <cds-icon class="note" (click)="onDisplayAdviseIFF()" [icon]="infoIcon" [config]="iconConfig"></cds-icon>
                  </div>
                </cds-header-cell>
                <cds-cell *cdsCellDef="let element" class="eiff-status-width">
                  <app-status-light
                    [color]="caseSummaryService.iffStatus(element).color"
                    [text]="caseSummaryService.iffStatus(element).text | lang"></app-status-light>
                </cds-cell>
              </ng-container>
              <ng-container cdsColumnDef="empfStatusEnum">
                <cds-header-cell *cdsHeaderCellDef class="empf-status-width">
                  <div class="prodtype-displaytooltip">
                    <span>
                      {{ 'sales-support-summary.application-status-on-eMPF-portal' | lang }}
                    </span>
                    <cds-icon class="note" (click)="onDisplayAdviseEmpf()" [icon]="infoIcon" [config]="iconConfig"></cds-icon>
                  </div>
                </cds-header-cell>
                <cds-cell *cdsCellDef="let element" class="empf-status-width">
                  <app-status-light
                    [color]="caseSummaryService.empfAppStatus(element).color"
                    [text]="caseSummaryService.empfAppStatus(element).text"></app-status-light>
                </cds-cell>
              </ng-container>
              <ng-container cdsColumnDef="splitCase">
                <cds-header-cell *cdsHeaderCellDef class="split-case-width">{{ 'sales-support-summary.split-case' | lang }}</cds-header-cell>
                <cds-cell *cdsCellDef="let element" [ngSwitch]="element.splitCase" class="split-case-width">
                  <div *ngSwitchCase="true">
                    <cds-icon class="prodtype-splitcaselogo" icon="general:collaboration"></cds-icon>
                  </div>
                  <div *ngSwitchDefault>
                    <cds-icon class="prodtype-splitcaselogo no-split-case" icon="general:collaboration"></cds-icon>
                  </div>
                </cds-cell>
              </ng-container>
              <ng-container cdsColumnDef="declineReasons">
                <cds-header-cell *cdsHeaderCellDef class="declined-reason-width">{{ 'sales-support-summary.decline-reason' | lang }}</cds-header-cell>
                <cds-cell *cdsCellDef="let element" class="declined-reason-width">
                  <span *ngFor="let item of caseSummaryService.declineReason(element.declineReasons, 'EE', 'branch-level-summary')">{{ item }}</span>
                </cds-cell>
              </ng-container>
            </cds-table>
            <cds-table *ngIf="isERReqeuestParams(baseParams.requestParams)" class="col-lg-12 row l-plr-0" [dataSource]="ERCurrentPageData" [class.full]="false">
              <cds-row *cdsRowDef="let row; index as i; columns: erDisplayedColumns"></cds-row>
              <cds-header-row *cdsHeaderRowDef="erDisplayedColumns"></cds-header-row>
              <ng-container cdsColumnDef="empfRefNo" sticky>
                <cds-header-cell *cdsHeaderCellDef class="e-width" [ngClass]="[checkIsHaveScroll ? 'shadow-right' : '']">
                  <div class="prodtype-displaytooltip">
                    <span>{{ 'sales-support-summary.eMPF-ref' | lang }}</span>
                    <cds-icon class="note" (click)="onDisplayAdviseRef()" [icon]="infoIcon" [config]="iconConfig"></cds-icon>
                  </div>
                </cds-header-cell>
                <cds-cell *cdsCellDef="let element" class="e-width" [ngClass]="[checkIsHaveScroll ? 'shadow-right' : '']">
                  <app-cd-fake-link style="word-break: break-all" (callBack)="goCaseDetails(element)" [text]="element.empfRefNo"></app-cd-fake-link>
                </cds-cell>
              </ng-container>
              <ng-container cdsColumnDef="districtCd">
                <cds-header-cell *cdsHeaderCellDef class="district-code-width">{{ 'sales-support-summary.district-code' | lang }}</cds-header-cell>
                <cds-cell *cdsCellDef="let element" class="district-code-width">
                  <div *ngFor="let item of element.districtCd">{{ item }}</div>
                </cds-cell>
              </ng-container>
              <ng-container cdsColumnDef="agentCd">
                <cds-header-cell *cdsHeaderCellDef class="agent-code-width">{{ 'sales-support-summary.agent-code' | lang }}</cds-header-cell>
                <cds-cell *cdsCellDef="let element" class="agent-code-width">
                  <div *ngFor="let item of element.agentCd; let i = index">
                    <span
                      [ngClass]="{
                        'service-agent': i === 0
                      }">
                      {{ item }}{{ i === 0 ? '*' : '' }}
                    </span>
                  </div>
                </cds-cell>
              </ng-container>
              <ng-container cdsColumnDef="companyName">
                <cds-header-cell *cdsHeaderCellDef class="client-name-width">{{ 'sales-support-summary.employer-name' | lang }}</cds-header-cell>
                <cds-cell *cdsCellDef="let element" class="client-name-width">
                  {{ element.companyName }}
                </cds-cell>
              </ng-container>
              <ng-container cdsColumnDef="businessRefNo">
                <cds-header-cell *cdsHeaderCellDef class="hkid-or-passport-no-width">{{
                  'sales-support-summary.business-registration-number' | lang
                }}</cds-header-cell>
                <cds-cell *cdsCellDef="let element" class="hkid-or-passport-no-width">
                  {{ element.businessRefNo }}
                </cds-cell>
              </ng-container>
              <ng-container cdsColumnDef="iffExpiryDate">
                <cds-header-cell *cdsHeaderCellDef class="eiff-expiry-date-width">{{ 'sales-support-summary.eIFF-expiry-date' | lang }}</cds-header-cell>
                <cds-cell *cdsCellDef="let element" class="eiff-expiry-date-width">
                  {{ element.iffExpiryDate }}
                </cds-cell>
              </ng-container>
              <ng-container cdsColumnDef="iffStatusEnum">
                <cds-header-cell *cdsHeaderCellDef class="eiff-status-width">
                  <div class="prodtype-displaytooltip">
                    <span>{{ 'sales-support-summary.eIFF-status' | lang }}</span>
                    <cds-icon class="note" (click)="onDisplayAdviseIFF()" [icon]="infoIcon" [config]="iconConfig"></cds-icon>
                  </div>
                </cds-header-cell>
                <cds-cell *cdsCellDef="let element" class="eiff-status-width">
                  <app-status-light
                    [color]="caseSummaryService.iffStatus(element).color"
                    [text]="caseSummaryService.iffStatus(element).text | lang"></app-status-light>
                </cds-cell>
              </ng-container>
              <ng-container cdsColumnDef="empfStatusEnum">
                <cds-header-cell *cdsHeaderCellDef class="empf-status-width">
                  <div class="prodtype-displaytooltip">
                    {{ 'sales-support-summary.application-status-on-eMPF-portal' | lang }}
                    <cds-icon class="note" (click)="onDisplayAdviseEmpf()" [icon]="infoIcon" [config]="iconConfig"></cds-icon>
                  </div>
                </cds-header-cell>
                <cds-cell *cdsCellDef="let element" class="empf-status-width">
                  <app-status-light
                    [color]="caseSummaryService.empfAppStatus(element).color"
                    [text]="caseSummaryService.empfAppStatus(element).text"></app-status-light>
                </cds-cell>
              </ng-container>
              <ng-container cdsColumnDef="splitCase">
                <cds-header-cell *cdsHeaderCellDef class="split-case-width">{{ 'sales-support-summary.split-case' | lang }}</cds-header-cell>
                <cds-cell *cdsCellDef="let element" [ngSwitch]="element.splitCase" class="split-case-width">
                  <div *ngSwitchCase="true">
                    <cds-icon class="prodtype-splitcaselogo" icon="general:collaboration"></cds-icon>
                  </div>
                  <div *ngSwitchDefault>
                    <cds-icon class="prodtype-splitcaselogo no-split-case" icon="general:collaboration"></cds-icon>
                  </div>
                </cds-cell>
              </ng-container>
              <ng-container cdsColumnDef="declineReasons">
                <cds-header-cell *cdsHeaderCellDef class="declined-reason-width">{{ 'sales-support-summary.decline-reason' | lang }}</cds-header-cell>
                <cds-cell *cdsCellDef="let element" class="declined-reason-width">
                  <span *ngFor="let item of caseSummaryService.declineReason(element.declineReasons, 'ER', 'branch-level-summary')">{{ item }}</span>
                </cds-cell>
              </ng-container>
            </cds-table>
            <div *ngIf="baseParams.isLoading" class="progress-box">
              <cds-progress mode="indeterminate" [diameter]="60" [type]="'ring'" [text]="'Loading'" [color]="'ml'"></cds-progress>
            </div>
          </div>
          <div class="col-sm-12 l-mt-4">
            <app-pagination
              [pageCount]="{
                current: baseParams.currentPageNumOrigin,
                count: baseParams.totalPages
              }"
              (pageChange)="pageChange($event)">
            </app-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
