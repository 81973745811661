/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { CdsOption } from '@cds/ng-core/configuration';
import { CdsLangService } from '@cds/ng-core/lang';

@Component({
  selector: 'app-cd-autocomplete',
  templateUrl: './cd-autocomplete.component.html',
  styleUrls: ['./cd-autocomplete.component.scss'],
})
export class CdAutocompleteComponent implements OnInit {
  @Input() placeholder?: string;
  @Input() label?: string;
  @Input() required = false;

  @Input() control?: FormControl;
  @Output() valueChange = new EventEmitter();

  @Input()
  set options(options: CdsOption[]) {
    this.optionsSource = options.map(option => {
      option.label = this.langService.translate(option.label);
      return option;
    });
    this.optionsDisplayed = this._filter('');
  }

  // @ViewChild('inputBox') inputBox!: ElementRef<HTMLInputElement>;
  optionsSource: CdsOption[] = [];
  optionsDisplayed: CdsOption[] = [];
  autoCompleteControl = new FormControl('');
  optionSelected: CdsOption | '' = '';
  isFocus = false;

  onBlur() {
    this.isFocus = false;
    const value = this.autoCompleteControl.value;
    if (typeof value === 'string' && !this.optionsDisplayed.length) {
      this.autoCompleteControl.setValue(this.optionSelected);
    }
  }

  onFocus() {
    this.isFocus = true;
    let value = this.autoCompleteControl.value;
    if (typeof value !== 'string') {
      value = value.label;
    }
    this.optionsDisplayed = value ? this._filter(value as string) : this.optionsSource.slice();
  }

  onClosed() {
    const value = this.autoCompleteControl.value;
    if (typeof value === 'string') {
      this.autoCompleteControl.setValue(this.optionSelected);
    }
  }

  optionSelectedFn(ev: any) {
    this.optionSelected = ev.option.value;
    this.valueChange.emit(ev.option.value);
  }

  constructor(private langService: CdsLangService) {}

  ngOnInit() {
    this.autoCompleteControl.valueChanges.subscribe(value => {
      let name = value;
      if (typeof value !== 'string') {
        if (this.control && this.control.value !== value.value) {
          this.control.setValue(value.value);
        }
        name = value?.label;
      }
      if (this.isFocus) {
        this.optionsDisplayed = value ? this._filter(name as string) : this.optionsSource.slice();
      }
    });
    if (this.control) {
      this.control.valueChanges.subscribe(value => {
        const opt = this.optionsSource.find(option => option.value === value);
        if (opt) {
          this.autoCompleteControl.setValue(opt);
          this.optionSelected = opt;
        } else {
          this.autoCompleteControl.setValue('');
          this.optionSelected = '';
        }
      });
      if (this.control.value) {
        const value = this.control.value;
        const opt = this.optionsSource.find(option => option.value === value);
        if (opt) {
          this.autoCompleteControl.setValue(opt);
          this.optionSelected = opt;
        }
      }
    }
  }

  private _filter(name: string): CdsOption[] {
    const filterValue = name.toLowerCase();
    return this.optionsSource.filter(option => option.label.toLowerCase().includes(filterValue));
  }

  displayFn(option: CdsOption) {
    return option ? option.label : '';
  }

  clearInput() {
    this.autoCompleteControl.patchValue('');
    this.optionSelected = '';
    if (this.control) {
      this.control.setValue(this.control.defaultValue);
    }
    this.valueChange.emit('');
  }
}
