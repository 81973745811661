/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { CdsButtonConfig } from '@cds/ng-core/button';
import moment from 'moment';
import { finalize } from 'rxjs';
import { PageConfig, ColumnConfig } from 'src/app/shared/data-table';
import { dateValidator } from 'src/app/core/validators';
import { ScbStatementUploadComponent } from '../scb-statement-upload/scb-statement-upload.component';
import { CdsPopupService, MatDialogRef } from '@cds/ng-web-components/popup';
import { PermissionAccess, PermissionItem } from 'src/app/core/models/enum/permission.enum';
import { PermissionService } from 'src/app/core/services/permission.service';
import { ScbStatementDTO, ScbStatementSearchDTO } from '../../../benefit-payment';
import { PaymentServiceSHKP } from '../../../paymentSHKP.service';

@Component({
  selector: 'app-scb-statement',
  templateUrl: './scb-statement.component.html',
  styleUrls: ['./scb-statement.component.scss'],
})
export class ScbStatementComponent implements OnInit {
  constructor(private service: PaymentServiceSHKP, private cdsPopup: CdsPopupService, private permissionService: PermissionService) {}

  PermissionAccess = PermissionAccess;

  PermissionItem = PermissionItem;

  form = new FormGroup({
    uploadDate: new FormControl('', [dateValidator()]),
    fileName: new FormControl('', []),
  });
  _showDatePicker = true;

  uploadButtonConfig: CdsButtonConfig = {
    size: 'sm',
  };

  searchButtonConfig: CdsButtonConfig = {
    size: 'sm',
    disabled: true,
  };

  approveButtonConfig: CdsButtonConfig = {
    size: 'sm',
    style: 'secondary',
  };
  resetButtonConfig: CdsButtonConfig = {
    size: 'sm',
    style: 'secondary',
    disabled: true,
  };
  exportButtonConfig: CdsButtonConfig = {
    size: 'lg',
    style: 'secondary',
  };
  searchDTO: ScbStatementSearchDTO = {
    page: 0,
    size: 10,
  };

  pageConfig: PageConfig = {
    current: 1,
    pageSize: 10,
    totalCount: 0,
  };
  columnsConfig: ColumnConfig[] = [
    { key: 'uploadDate', title: 'Upload Date' },
    { key: 'fileName', title: 'File Name' },
    { key: 'uploadBy', title: 'Uploaded By' },
  ];

  _progress = false;

  dataList: ScbStatementDTO[] = [];

  _canUpload = false;

  ngOnInit(): void {
    this.permissionService.hasPermission(PermissionAccess.R, PermissionItem.SHK_PAYMENT_ISSUANCE_PAYMENT_STAFF).then(has => {
      this._canUpload = this._canUpload || has;
    });
    this.permissionService.hasPermission(PermissionAccess.R, PermissionItem.SHK_PAYMENT_ISSUANCE_PAYMENT_MANAGER).then(has => {
      this._canUpload = this._canUpload || has;
    });
    this.loadTable();
  }

  reloadTable(event: any) {
    this.pageConfig = {
      ...this.pageConfig,
      current: event.current,
    };
    this.loadTable();
  }

  loadTable() {
    const params: ScbStatementSearchDTO = this.getRequestParams();
    this._progress = true;
    this.service
      .getScbStatmentList(params)
      .pipe(
        finalize(() => {
          this._progress = false;
        })
      )
      .subscribe(data => {
        if (data.result === 0 && data.data) {
          this._progress = false;
          this.dataList = data.data.empty ? [] : data.data.content;
          this.pageConfig = {
            ...this.pageConfig,
            totalCount: data.data?.totalElements,
          };
        }
      });
  }

  getRequestParams() {
    const params: ScbStatementSearchDTO = { ...this.searchDTO };
    params.uploadDate = this.dateTrans(params.uploadDate);
    params.page = (this.pageConfig.current as number) - 1;
    return params;
  }

  dateTrans(date?: string) {
    if (!date) {
      return '';
    }
    return moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD');
  }

  inputChange(val: string, label: string) {
    if (val == undefined) {
      return;
    }
    switch (label) {
      case 'fileName':
        this.searchDTO.fileName = val;
        break;
      default:
        break;
    }
    this.validating();
  }
  validating() {
    if (!!this.searchDTO.uploadDate || !!this.searchDTO.fileName) {
      this.searchButtonConfig = {
        size: 'sm',
        disabled: false,
      };
      this.resetButtonConfig = {
        size: 'sm',
        style: 'secondary',
        disabled: false,
      };
    } else {
      this.searchButtonConfig = {
        size: 'sm',
        disabled: true,
      };
      this.resetButtonConfig = {
        size: 'sm',
        style: 'secondary',
        disabled: true,
      };
    }
    if (!this.form.valid) {
      this.searchButtonConfig = {
        size: 'sm',
        disabled: true,
      };
    }
  }

  uploadTrigger() {
    if (!this._canUpload) {
      return;
    }
    const popupRef: MatDialogRef<ScbStatementUploadComponent> = this.cdsPopup.open(ScbStatementUploadComponent, {
      size: 'md',
      data: {},
    });
    popupRef.afterClosed().subscribe(data => {
      if (data.agree) {
        this.loadTable();
      }
    });
  }
  search() {
    if (this.searchButtonConfig.disabled) {
      return;
    }
    this.loadTable();
  }

  reset() {
    this.searchDTO = {
      page: 0,
      size: 10,
    };
    this.validating();
    this.loadTable();
  }
}
