/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Session } from '../core/models/user';
import { dbName, sessionTable, version, commissionTable, tableList, employerSystemType } from './indexdb.model';
import { sessionId } from './indexdb.model';
import { DateTime } from 'luxon';

export function openTable(name: string) {
  // return openDB(dbName, employerSystemType, version);
  const openRequest = indexedDB.open(dbName, version);
  openRequest.onupgradeneeded = function () {
    const db = openRequest.result;
    if (!db.objectStoreNames.contains(name)) {
      db.createObjectStore(employerSystemType, { keyPath: 'id' });
    }
  };
}

// export async function getSystemType(systemId: string): Promise<string> {
//   return openEmployerSystemType().then(db => {
//     return getDataByKey(db, employerSystemType, systemId)
//     .then(res => {
//       return JSON.parse(res.systemId);
//     })
//       .catch(() => {});
//   });
// }

// export async function putSystemType(val:any): Promise<any> {
//   return openEmployerSystemType().then(async db => {
//     return addData(db, employerSystemType, val);
//   });
// }

export function openCommissionTable(): Promise<any> {
  return openDB(dbName, commissionTable, version);
}

export async function getCommissionByKey(scale: string): Promise<any> {
  return openCommissionTable().then(db => {
    return getDataByKey(db, commissionTable, scale)
      .then(res => {
        return JSON.parse(res.commission);
      })
      .catch(() => {});
  });
}

export async function addCommissionByKey(scale: string, commission: any): Promise<any> {
  return openCommissionTable().then(async db => {
    await deleteByKey(db, commissionTable, scale);
    return addData(db, commissionTable, {
      id: scale,
      commission: JSON.stringify(commission),
    });
  });
}

export async function updateCommissionByKey(scale: string, commission: any): Promise<any> {
  return openCommissionTable().then(db =>
    updateDB(db, commissionTable, {
      id: scale,
      commission: JSON.stringify(commission),
    })
  );
}

export async function deleteCommissionByKey(scale: string): Promise<any> {
  return openSessionTable().then(db => {
    return deleteByKey(db, commissionTable, scale);
  });
}

export function openSessionTable(): Promise<any> {
  return openDB(dbName, sessionTable, version);
}

export async function getSessionByKey(): Promise<Session> {
  return openSessionTable().then(db => {
    return getDataByKey(db, sessionTable, sessionId).then(sessionDto => {
      if (sessionDto) {
        const session: Session = JSON.parse(sessionDto.sessionInfo);
        return session;
      }
      return new Session();
    });
  });
}

export async function updateSessionByKey(sessionInfo: Session): Promise<any> {
  return openSessionTable().then(db =>
    updateDB(db, sessionTable, {
      id: sessionId,
      sessionInfo: JSON.stringify(sessionInfo),
    })
  );
}

export async function addSessionByKey(sessionInfo: Session): Promise<any> {
  return openSessionTable().then(async db => {
    await deleteSessionByKey();
    return addData(db, sessionTable, {
      id: sessionId,
      sessionInfo: JSON.stringify(sessionInfo),
    });
  });
}

export async function deleteSessionByKey(): Promise<any> {
  return openSessionTable().then(db => {
    return deleteByKey(db, sessionTable, sessionId);
  });
}

export function deleteDB(): Promise<any> {
  return deleteDBAll(dbName);
}

export function openDB(dbName: string, storeName: string, version: number): Promise<any> {
  return new Promise((resolve, reject) => {
    let db;
    const request = indexedDB.open(dbName, version);
    request.onsuccess = function (event: any) {
      db = event.target.result;
      resolve(db);
    };

    request.onerror = function (event) {
      reject(event);
    };

    request.onupgradeneeded = function (event: any) {
      db = event.target.result;
      for (let i = 0; i < tableList.length; i++) {
        if (!db.objectStoreNames.contains(tableList[i])) {
          db.createObjectStore(tableList[i], { keyPath: 'id' });
          //can create other index,default index is main key
          //objectStore.createIndex('name', 'name', { unique: false })
        }
      }
    };
  });
}

export function addData(db: any, storeName: any, data: any): Promise<any> {
  return new Promise((resolve, reject) => {
    const nowTime = DateTime.now().toMillis();
    const request = db.transaction([storeName], 'readwrite').objectStore(storeName).add(data);

    request.onsuccess = function (event: any) {
      const end = DateTime.now().toMillis();
      resolve(event);
    };

    request.onerror = function (event: any) {
      const end = DateTime.now().toMillis();
      throw new Error(event.target.error);
      reject(event);
    };
  });
}

export function getDataByKey(db: any, storeName: any, key: any): Promise<any> {
  return new Promise((resolve, reject) => {
    const transaction = db.transaction([storeName]);
    const objectStore = transaction.objectStore(storeName);
    const request = objectStore.get(key);

    request.onerror = function (event: any) {
      console.log('getDataByKey error');
      reject(event);
    };

    request.onsuccess = function (event: any) {
      resolve(request.result);
    };
  });
}

export function getCommissionList(): Promise<any> {
  return openCommissionTable().then(db => {
    return cursorGetData(db, commissionTable)
      .then(res => {
        return res;
      })
      .catch(() => {});
  });
}

export function cursorGetData(db: any, storeName: any): Promise<any> {
  const list: any[] = [];
  const store = db.transaction(storeName, 'readwrite').objectStore(storeName);
  const request = store.openCursor();
  return new Promise((resolve, reject) => {
    request.onsuccess = function (e: any) {
      const cursor = e.target.result;
      if (cursor) {
        list.push(cursor.value);
        cursor.continue();
      } else {
        resolve(list);
      }
    };
    request.onerror = function (e: any) {
      reject(e);
    };
  });
}

export function getDataByIndex(db: any, storeName: any, indexName: any, indexValue: any): Promise<any> {
  const store = db.transaction(storeName, 'readwrite').objectStore(storeName);
  const request = store.index(indexName).get(indexValue);
  return new Promise((resolve, reject) => {
    request.onerror = function (e: any) {
      console.log('getDataByIndex error');
      reject(e);
    };
    request.onsuccess = function (e: any) {
      resolve(e.target.result);
    };
  });
}

export function cursorGetDataByIndex(db: any, storeName: any, indexName: any, indexValue: any): Promise<any> {
  const list: any[] = [];
  const store = db.transaction(storeName, 'readwrite').objectStore(storeName);
  const request = store.index(indexName).openCursor(IDBKeyRange.only(indexValue));
  return new Promise((resolve, reject) => {
    request.onsuccess = function (e: any) {
      const cursor = e.target.result;
      if (cursor) {
        list.push(cursor.value);
        cursor.continue();
      } else {
        resolve(list);
      }
    };
    request.onerror = function (ev: any) {
      reject(ev);
    };
  });
}

export function updateDB(db: any, storeName: any, data: any): Promise<any> {
  const request = db.transaction([storeName], 'readwrite').objectStore(storeName).put(data);

  return new Promise((resolve, reject) => {
    request.onsuccess = function (ev: any) {
      resolve(ev);
    };

    request.onerror = function (ev: any) {
      resolve(ev);
    };
  });
}

export function deleteByKey(db: any, storeName: any, id: any): Promise<any> {
  return new Promise((resolve, reject) => {
    const request = db.transaction([storeName], 'readwrite').objectStore(storeName).delete(id);
    request.onsuccess = function (ev: any) {
      resolve(ev);
    };
    request.onerror = function (ev: any) {
      console.log('deleteByKey error');
      resolve(ev);
    };
  });
}

export function deleteDBAll(dbName: any): Promise<any> {
  return new Promise((resolve, reject) => {
    const deleteRequest = indexedDB.deleteDatabase(dbName);
    deleteRequest.onerror = function (event) {
      console.log('del fail');
      reject(deleteRequest);
    };
    deleteRequest.onsuccess = function (event) {
      resolve(deleteRequest);
    };
  });
}

export function closeDB(db: any) {
  db.close();
}

export default {
  openDB,
  addData,
  getDataByKey,
  cursorGetData,
  getDataByIndex,
  cursorGetDataByIndex,
  updateDB,
  deleteDB,
  deleteDBAll,
  closeDB,
};
