import { Injectable } from '@angular/core';
/* eslint-disable @typescript-eslint/no-var-requires */
const CryptoJS = require('crypto-js');

@Injectable({
  providedIn: 'root',
})
export class CdCryptoJsService {
  constructor() {}

  encrypt(data: string) {
    const ciphertext = CryptoJS.AES.encrypt(data, '12272023').toString();
    return ciphertext;
  }

  decrypt(ciphertext: string): string {
    const bytes = CryptoJS.AES.decrypt(ciphertext, '12272023');
    const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
    return decryptedData;
  }
}
