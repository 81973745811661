export enum CommissionCode {
  TRANSFER_COMMISSION = 112,
  FIRST_YEAR_COMMISSION = 115,
  SERVICE_FEE = 133,
  MANUAL_ADJUSTMENT_OR_CLAWBACK = 140,
  NEW_BUSINESS_PC = 141,
  RENEWAL_PC = 142,
  CLAW_BACK_CORE_PC = 143,
  CASE_COUNT = 148,
  TRANSFER_PC = 149,
  APE_VSF = 123,
  TRAILER_FEE = 136,
}

export enum CommissionPCCode {
  MANUAL_ADJUSTMENT_OR_CLAWBACK = 140,
  NEW_BUSINESS_PC = 141,
  RENEWAL_PC = 142,
  CLAW_BACK_CORE_PC = 143,
  TRANSFER_PC = 149,
}
