import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class DataService {
  private dataSource = new BehaviorSubject(null);
  currentData = this.dataSource.asObservable();

  constructor() {}
  passData(data: any) {
    this.dataSource.next(data);
  }

  //for form state
  private formValueSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  setFormValue(value: any) {
    this.formValueSubject.next(value);
  }

  getFormValue() {
    return this.formValueSubject.getValue();
  }

  public model: any;

  public getModel() {
    return this.model;
  }
  public setModel(model: any): void {
    this.model = model;
  }
  public resetModel() {
    this.model = null;
    return this.model;
  }
}
