export const I18N_KEY = {
  COMMON_SUCCESS: 'common.action.success',
  COMMON_FAILED: 'commission.pool.common.alertFailedMsg',
  COMMON_GOTIT: 'common.gotIt',
  EFFECTIVE_DATE_NULL: 'commission.pool.scale.recordCreate.scaleEffectiveDateNull',
  EFFECTIVE_DATE_INVALID: 'commission.pool.scale.recordCreate.scaleEffectiveDateInvalid',
  EFFECTIVE_DATE_IS_FUTURE: 'commission.pool.scale.recordCreate.scaleEffectiveDateFuture',
  EFFECTIVE_DATE_IS_DUPLICATE: 'commission.pool.scale.recordCreate.scaleEffectiveDateDuplicate',
  SCALE_CODE_NULL: 'commission.pool.scale.recordCreate.scaleCodeNull',
  SCALE_CODE_INVALID: 'commission.pool.scale.recordCreate.scaleCodeInvalid',
};
