<div class="row">
  <div class="col-xs-12 l-pt-7">
    <span class="cds-h2-light">{{ 'list.serach-record' | lang }}</span>
  </div>
  <div class="row l-plr-0" [formGroup]="form" *ngIf="formReady">
    <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 l-pt-4 l-pb-4">
      <cds-textfield
        formControlName="paymentSubmissionReferenceNo"
        label="Payment Submission Reference No."
        placeholder="Input Payment Submission Ref No."></cds-textfield>

      <div
        *ngIf="paymentSubmissionReferenceNoControl?.invalid && (paymentSubmissionReferenceNoControl?.dirty || paymentSubmissionReferenceNoControl?.touched)"
        class="l-pt-2">
        <cds-assistive-text *ngIf="paymentSubmissionReferenceNoControl?.errors?.['maxlength']" class="l-pt-2" [showIcon]="false"
          >Max length is 50</cds-assistive-text
        >
      </div>
    </div>
    <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 l-pt-4 l-pb-4">
      <cds-textfield formControlName="receiptReferenceNo" label="Receipt Reference No." placeholder="Input Receipt Reference No."></cds-textfield>

      <div *ngIf="receiptReferenceNoControl?.invalid && (receiptReferenceNoControl?.dirty || receiptReferenceNoControl?.touched)" class="l-pt-2">
        <cds-assistive-text *ngIf="receiptReferenceNoControl?.errors?.['maxlength']" class="l-pt-2" [showIcon]="false">Max length is 35</cds-assistive-text>
      </div>
    </div>

    <div class="row col-xs-12 col-sm-12 col-md-8 col-lg-6 l-pt-4 l-pb-4 l-plr-0 date-picker-area relative">
      <div class="col-xs-6 date-picker">
        <cds-textfield
          #contributionPeriodFromRef
          formControlName="contributionPeriodFrom"
          placeholder="DD/MM/YYYY"
          label="Contribution Period From"
          [max]="getTime | fn : contributionPeriodToRef?.value"
          [cdsDatepicker]="startPicker">
        </cds-textfield>
        <cds-datepicker #startPicker="cdsDatepicker"></cds-datepicker>

        <div *ngIf="contributionPeriodFromControl?.invalid && (contributionPeriodFromControl?.dirty || contributionPeriodFromControl?.touched)" class="l-pt-2">
          <cds-assistive-text
            *ngIf="contributionPeriodFromControl?.errors?.['dateInvalid'] || contributionPeriodFromControl?.errors?.['startLaterThanEnd']"
            class="l-pt-2"
            [showIcon]="false"
            >{{ 'create.dateError' | lang }}</cds-assistive-text
          >
        </div>
      </div>
      <div class="connector">-</div>
      <div class="col-xs-6 date-picker">
        <cds-textfield
          #contributionPeriodToRef
          formControlName="contributionPeriodTo"
          placeholder="DD/MM/YYYY"
          label="To"
          [min]="getMinToDate | fn : contributionPeriodFromRef?.value"
          [cdsDatepicker]="endPicker">
        </cds-textfield>
        <cds-datepicker #endPicker="cdsDatepicker"></cds-datepicker>

        <div *ngIf="contributionPeriodToControl?.invalid && (contributionPeriodToControl?.dirty || contributionPeriodToControl?.touched)" class="l-pt-2">
          <cds-assistive-text
            *ngIf="contributionPeriodToControl?.errors?.['dateInvalid'] || contributionPeriodToControl?.errors?.['startLaterThanEnd']"
            class="l-pt-2"
            [showIcon]="false"
            >{{ 'create.dateError' | lang }}</cds-assistive-text
          >
        </div>
      </div>
    </div>

    <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 l-pt-4 l-pb-4">
      <cds-textfield formControlName="billReferenceNo" label="Bill Reference No." placeholder="Input Bill Reference No."></cds-textfield>

      <div *ngIf="billReferenceNoControl?.invalid && (billReferenceNoControl?.dirty || billReferenceNoControl?.touched)" class="l-pt-2">
        <cds-assistive-text *ngIf="billReferenceNoControl?.errors?.['maxlength']" class="l-pt-2" [showIcon]="false">Max length is 50</cds-assistive-text>
      </div>
    </div>

    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 l-pb-4 bottom-xs l-pt-4">
      <cds-button class="l-mr-4 botton" [config]="searchButtonConfig" [disabled]="searchButtonDisabled" [label]="'list.search' | lang" (click)="search()">
      </cds-button>

      <cds-button class="botton" [config]="resetButtonConfig" [disabled]="resetButtonDisabled" [label]="'list.reset' | lang" (click)="reset()"> </cds-button>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-xs-12 l-mt-7 l-mb-2">
    <span class="cds-h2-light">{{ 'list.record-list' | lang }}</span>
  </div>
  <div class="col-xs-12 l-mb-3">
    <span class="cds-body1">Total {{ numberOfElements }} records</span>
  </div>
</div>

<div class="row">
  <div class="col-xs-12 col-md-12 min-height">
    <app-data-table
      [frontPagination]="false"
      [isLoading]="isLoading"
      [fixed]="false"
      [overlay]="true"
      [pageConfig]="pageConfig"
      [dataSource]="paymentRecordList"
      [columnsConfig]="columnsConfig"
      (reloadTable)="reloadTable($event)">
      <ng-template appDtBodyCellDef="paymentSubmissionReferenceNo" let-element>
        <span class="click" (click)="toDetail(element['paymentSubmissionReferenceNo'])">{{ element['paymentSubmissionReferenceNo'] }}</span>
      </ng-template>

      <ng-template appDtBodyCellDef="contributionPeriod" let-element>
        <div class="contribution-period-cell">
          <span>{{ element['contributionStart'] | date : 'dd/MM/yyyy' }}</span>
          <span *ngIf="element['contributionStart'] && element['contributionEnd']"> - </span>
          <span>{{ element['contributionEnd'] | date : 'dd/MM/yyyy' }}</span>
        </div>
      </ng-template>

      <ng-template appDtBodyCellDef="receiptReferenceNo" let-element>
        <span class="click" (click)="toDetail(element['paymentSubmissionReferenceNo'], element['receiptReferenceNo'])">{{
          element['receiptReferenceNo']
        }}</span>
      </ng-template>

      <ng-template appDtBodyCellDef="paymentMethod" let-element>
        <span>{{ service.optionLabelRender | fn : element.paymentMethod : paymentMethodOptions }}</span>
      </ng-template>

      <ng-template appDtBodyCellDef="receivedAmount" let-element>
        <span>{{ element['receivedAmount'] | number : '1.2-2' }}</span>
      </ng-template>

      <ng-template appDtBodyCellDef="chequeDate" let-element>
        <span>{{ element['chequeDate'] | date : 'dd/MM/yyyy' }}</span>
      </ng-template>

      <ng-template appDtBodyCellDef="chequeBankInDate" let-element>
        <span>{{ element['chequeBankInDate'] | date : 'dd/MM/yyyy' }}</span>
      </ng-template>
    </app-data-table>
  </div>
</div>
