<div style="height: calc(100vh - 192px); overflow: auto" class="row cds-grey light l-plr-0 l-pb-4 l-pt-4" *ngIf="!isShowOTP; else otpTemplate">
  <div class="content half-height">
    <div class="width">
      <div style="font-weight: 600" class="font-title l-pb-2">
        {{ 'login.title' | lang }}
      </div>
      <div class="l-pb-4"></div>
      <!--      <div style="font-size: 1rem; font-weight: 400" class="l-pb-6">-->
      <!--        {{ 'login.title.comment' | lang }}-->
      <!--      </div>-->
      <div *ngIf="isInternal || profile === 'sit' || profile === 'dev'">
        <cds-button class="font-weight-demibold" [fullWidth]="true" size="md" (click)="loginWithSSO()">
          {{ 'login.sso.button' | lang }}
        </cds-button>
        <div style="padding-top: 16px">
          <span class="cds-h5">&nbsp;&nbsp;&nbsp;&nbsp;{{ 'login.line' | lang }}&nbsp;&nbsp;&nbsp;&nbsp; </span>
        </div>
      </div>
      <div *ngIf="!isInternal">
        <div *ngIf="mCFMode">
          <cds-button class="font-weight-demibold" [fullWidth]="true" size="md" (click)="redirectMCF()">
            {{ 'login.mcf.button' | lang }}
          </cds-button>
        </div>
        <div *ngIf="pwdLogin">
          <span class="cds-h5">&nbsp;&nbsp;&nbsp;&nbsp;{{ 'login.line' | lang }}&nbsp;&nbsp;&nbsp;&nbsp;</span>
          <br />
          <div class="cds-body1 l-ma-0">
            {{ 'login.subtitle.comment' | lang }}
          </div>
          <br />
          <div class="cds-body2 font-label l-ma-0 l-pb-1">
            {{ 'login.username.label' | lang }}
          </div>
          <input
            class="input-field"
            placeholder="{{ 'login.username.placeholder' | lang }}"
            type="text"
            [(ngModel)]="loginForm.username"
            (ngModelChange)="loginFormChange()" />

          <div class="cds-body2 font-label l-ma-0 l-pb-1 l-pt-5">
            {{ 'login.password.label' | lang }}
          </div>
          <div class="password-box">
            <input
              class="input-field l-pt-1 l-pb-3"
              placeholder="{{ 'login.password.placeholder' | lang }}"
              [type]="inputType"
              [(ngModel)]="loginForm.passwd"
              (ngModelChange)="loginFormChange()" />
            <div class="password-icon">
              <cds-icon class="action-icon" [icon]="masked ? 'action:eye_2' : 'action:eye_1'" (click)="toggleMask()"> </cds-icon>
            </div>
          </div>
          <cds-assistive-text *ngIf="!!loginForm.loginErr" class="l-pt-3">{{ loginForm.loginErr }} </cds-assistive-text>
          <div class="l-pt-6 l-pb-2">
            <cds-button
              class="font-weight-demibold"
              [disabled]="!isLoginEnable"
              [config]="loginBtnCfg"
              [fullWidth]="true"
              size="md"
              (click)="loginWithManutouch()"
              >{{ 'login.button' | lang }}
            </cds-button>
          </div>
          <!-- <div class="l-pt-3">
            <a
              class="cds-body1-demibold a-link pointer cds-body1-demibold"
              target="_blank"
              >{{ 'login.forgot.password' | lang }}</a
            >
          </div> -->
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #otpTemplate>
  <div class="row cds-grey light sm-logo">
    <div class="col-xs-12 col-md-7 l-pb-4 md-title sm-header">
      <div class="cds-h1-demibold l-pt-6 l-pb-7 l-ma-0 cds-h1-demibold">
        {{ 'login.otp.title' | lang }}
      </div>
      <div class="cds-body1 l-pb-3 l-ma-0">
        {{ 'login.otp.subtitle' | lang }}
      </div>
      <div class="cds-body1 l-pt-2 l-pb-7 l-plr-0 l-ma-0 md-display-flex">
        <div class="l-d-f">
          <cds-icon icon="action:check"></cds-icon>
          <div>&nbsp;&nbsp;{{ 'login.otp.email.comment' | lang }}</div>
        </div>
        <div class="l-d-f">
          <div class="sm-display-block">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
          <div>&nbsp;&nbsp;({{ OTPUser.emailAddress | mystring }})</div>
        </div>
      </div>
      <div class="cds-body2 font-label l-mb-0">
        {{ 'login.otp.label' | lang }}
      </div>
      <div class="l-pt-3 l-pb-3">
        <input
          class="text-field sm-text-field-width l-pl-3 l-pb-2"
          type="text"
          oninput="value = value.replace(/[^\d]/g,'');if(value.length > 6) value = value.slice(0,6)"
          [(ngModel)]="otp"
          (ngModelChange)="changeOTP($event)" />
      </div>

      <div class="l-pt-2 l-ma-0 l-d-f l-jc-fs cds-body1">
        <div>{{ 'login.otp.resend.title' | lang }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
        <cds-progress *ngIf="isShowLoading; else resendDiv" mode="indeterminate"></cds-progress>
        <ng-template #resendDiv>
          <div class="font-label" [class.resend-label]="second === '0-1' || isResendEnable" (click)="requestOTP(undefined, true)">
            {{ 'login.otp.resend' | lang }}
          </div>
          <div *ngIf="second !== '0-1' && !isResendEnable" class="font-color-dark-green">({{ second }}{{ 'login.otp.second' | lang }})</div>
          <cds-assistive-text *ngIf="!!otpErr" class="l-pl-3">{{ otpErr }} </cds-assistive-text>
        </ng-template>
      </div>
      <br />
    </div>
    <div class="col-xs-0 col-md-5 md-title sm-header"></div>
    <div class="col-xs-12 col-md-2 md-title sm-header l-pt-3">
      <cds-button [config]="btnCfg" [fullWidth]="true" label="{{ 'common.back' | lang }}" (click)="backToLogin()"> </cds-button>
    </div>
    <div class="col-xs-12 col-md-2 md-title sm-header l-pt-3">
      <cds-button [fullWidth]="true" [disabled]="!isContinueEnable" label="{{ 'common.continue' | lang }}" (click)="loginWithOTP()"> </cds-button>
    </div>
    <div class="col-xs-12 col-md-8 md-title sm-header"></div>
    <div class="col-xs-12 col-md-12 md-title sm-header l-pt-7 l-pb-9"></div>
  </div>
</ng-template>
