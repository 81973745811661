<cds-popup class="popup no-close-icon">
  <cds-popup-title
    ><div>Add New Activity</div>
    <div class="close-icon" (click)="close()">
      <cds-icon icon="action:cross"></cds-icon></div
  ></cds-popup-title>

  <cds-popup-content>
    <form [formGroup]="form" class="row l-plr-0">
      <cds-form-field class="col-xs-12 col-md-6">
        <cds-dropdown formControlName="activityType" [config]="activityTypeDropdownConfig"> </cds-dropdown>
        <ng-container *ngIf="activityType.invalid && (activityType.dirty || activityType.touched)">
          <cds-assistive-text *ngIf="activityType?.errors?.['required']" [showIcon]="false">Activity Type Required</cds-assistive-text>
        </ng-container>
      </cds-form-field>

      <cds-form-field class="col-xs-12 col-md-6">
        <cds-textfield [config]="scoreTxtfieldConfig" formControlName="score" (change)="scoreChange($event)"></cds-textfield>
        <ng-container *ngIf="score.invalid && (score.dirty || score.touched)">
          <cds-assistive-text *ngIf="score?.errors?.['required']" [showIcon]="false">Missing Score</cds-assistive-text>
          <cds-assistive-text *ngIf="score?.errors?.['max']" [showIcon]="false">Max Score is 999</cds-assistive-text>
          <cds-assistive-text *ngIf="score?.errors?.['min']" [showIcon]="false">Min Score is 0</cds-assistive-text>
        </ng-container>
      </cds-form-field>

      <cds-form-field class="col-xs-12 col-md-6">
        <cds-textfield [config]="descriptionTxtfieldConfig" formControlName="description" (change)="descriptionChange($event)"></cds-textfield>
        <ng-container *ngIf="description.invalid && (description.dirty || description.touched)">
          <cds-assistive-text *ngIf="description?.errors?.['required']" [showIcon]="false">Missing Activity Description</cds-assistive-text>
          <cds-assistive-text *ngIf="description?.errors?.['maxlength']" [showIcon]="false">Max length is 50</cds-assistive-text>
          <cds-assistive-text
            *ngIf="description?.errors?.['descriptionInvalid']"
            [showIcon]="false"
            >{{ description.errors?.['descriptionInvalid'] | lang }}</cds-assistive-text
          >
        </ng-container>
      </cds-form-field>
    </form>
  </cds-popup-content>

  <cds-popup-actions>
    <cds-button [config]="resetButtonConfig" [disabled]="resetDisabled" (click)="reset()">{{ 'common.reset' | lang }}</cds-button>

    <cds-button [config]="AddButtonConfig" [disabled]="!form.valid" (click)="add()">Add</cds-button>
  </cds-popup-actions>
</cds-popup>
