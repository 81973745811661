<cds-progress *ngIf="loading" mode="indeterminate" style="padding-left: 100px; padding-top: 50px"></cds-progress>
<div *ngIf="!loading" class="l-pb-6 row l-plr-0" id="index">
  <div class="col-xs-12 col-md-12 l-plr-0">
    <div class="col-xs-12 col-md-11 card l-pa-5">
      <label class="cds-body2 cds-dark-navy-text light-3">Search account for commission adjustment.</label>
      <div class="l-d-f row l-plr-0">
        <div class="l-pt-4 col-xs-12 col-md-6 col-lg-4 col-sm-6 pl-0">
          <div class="cds-body2 cds-dark-navy-text light-3">Account Type</div>
          <mat-select
            #mySelect
            appSelectCloseDirective="mySelect"
            class="text-field pt-1"
            [class.my-placeholder]="!searchForm.accountType"
            placeholder="Select account type"
            [(ngModel)]="searchForm.accountType"
            (ngModelChange)="searchChange()">
            <mat-option class="div-border-top div-border-bottom option" *ngFor="let option of accountTypes" [value]="option.value">
              {{ option.label | lang }}
            </mat-option>
          </mat-select>
        </div>
        <div class="l-pt-4 col-xs-12 col-md-6 col-lg-4 col-sm-6 pl-0">
          <div class="cds-body2 cds-dark-navy-text light-3 l-d-f">
            Employer/Member Account Code
            <cds-icon (click)="alert()" class="l-pl-1" size="sm" style="cursor: pointer; min-width: initial" icon="action:info_1"></cds-icon>
          </div>
          <input
            placeholder="Input Employer/Member Account Code"
            [(ngModel)]="searchForm.accountCode"
            (input)="searchChange($event)"
            [class.text-field-error]="searchForm.errorMsg"
            maxlength="8"
            class="text-field" />
        </div>
        <cds-button class="l-pt-5" [disabled]="searchForm.searchDisabled" [config]="btnCfg" size="md" (click)="search()">Search </cds-button>
        <cds-button class="l-ml-4 l-pt-5" [config]="btnCfg" size="md" (click)="reset(true)">Reset</cds-button>
      </div>
      <cds-assistive-text class="l-pt-2" *ngIf="searchForm.errorMsg">
        {{ searchForm.errorMsg | lang }}
      </cds-assistive-text>
      <cds-assistive-text class="l-pt-2" *ngIf="searchForm.warningMsg" type="warning">
        {{ searchForm.warningMsg | lang }}
      </cds-assistive-text>
    </div>
    <br />
    <br />
  </div>

  <div *ngIf="searchForm.commissionPoolId" class="col-xs-12 col-md-12">
    <span class="cds-h3-light l-pt-3"> Make Adjustment </span>
    <div class="col-xs-12 col-md-12 l-pt-6 l-plr-0">
      <div class="l-d-f">
        <div style="padding: 0px 15px 15px 0px">
          <cds-icon size="md" color="var(--cds-color-m-green)" icon="environment:office_building"></cds-icon>
        </div>
        <div class="l-fg-1">
          <span class="cds-h5-demibold">{{ searchForm.accountName }}</span>
          <br />
          <br />
          <div class="div-border-top">
            <br />
            <div *ngIf="searchForm.accountCode && searchForm.accountTypeStr">
              <span style="color: #8e90a2; font-size: 14px">Account No. & Type</span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ searchForm.accountCode }},{{
                searchForm.accountTypeStr
              }}
            </div>
            <br />
            <div><span style="color: #8e90a2; font-size: 14px">Commission Pool No.</span> &nbsp;&nbsp;&nbsp;{{ searchForm.commissionPoolId }}</div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <br />
      <div class="col-xs-12 col-md-12 l-plr-0" style="overflow: auto">
        <ng-container *ngFor="let item of dataSource; let i = index">
          <div class="card l-mt-4">
            <div class="l-pt-4 l-pb-4 l-pr-4 l-d-f" style="float: right">
              <cds-switch [config]="item.config" [disabled]="isConfirm" (change)="selectNegative($event, i)"></cds-switch>
            </div>
            <table class="cds-table full div-border-top" style="border-collapse: collapse">
              <caption></caption>
              <tr>
                <td scope="col" class="border-column l-pa-4 font-bold" width="20%">Agent code</td>
                <td scope="col" class="border-column l-pa-4 font-bold" width="28%">Adjust Type</td>
                <td scope="col" class="border-column l-pa-4 font-bold">Adjust Amount</td>
                <td scope="col" class="border-column l-pa-4 font-bold">Premium Adjust</td>
                <td *ngIf="!isConfirm" class="border-column l-pa-4 font-bold">Action</td>
              </tr>
              <ng-container *ngFor="let subItem of item.adjustItems; let j = index">
                <tr>
                  <td class="border-right l-pa-4">
                    <div *ngIf="j === 0">
                      <div *ngIf="isConfirm" class="l-pt-2">
                        <span>{{ item.agentCode }}</span>
                      </div>
                      <div *ngIf="!isConfirm" class="col-xs-12 col-md-12 l-plr-0">
                        <input
                          (input)="inputAgentCode($event, i, j)"
                          [(ngModel)]="item.agentCode"
                          class="text-field l-pt-1"
                          [class.text-field-error]="item.agentCodeError"
                          placeholder="Input agent code"
                          maxlength="6" />
                      </div>
                    </div>
                  </td>
                  <td class="border-column l-pa-4">
                    <div *ngIf="isConfirm" class="l-pt-2">
                      <span>{{ subItem.adjustTypeStr }}</span>
                    </div>
                    <div *ngIf="!isConfirm" class="col-xs-12 col-md-12 l-plr-0">
                      <input
                        #trigger="matAutocompleteTrigger"
                        appAutocompletePosition="trigger"
                        (input)="inputAdjustType($event, i, j)"
                        [(ngModel)]="subItem.adjustTypeStr"
                        class="text-field l-pt-1"
                        [class.text-field-error]="subItem.adjustTypeError"
                        maxlength="6"
                        placeholder="Select Adjust type"
                        [matAutocomplete]="auto2" />
                      <i class="icon">
                        <cds-icon size="sm" icon="action:button_down"></cds-icon>
                      </i>
                    </div>
                    <mat-autocomplete #auto2="matAutocomplete" (optionSelected)="selectAdjustType($event.option.value, i, j)">
                      <mat-option class="div-border-top div-border-bottom option" *ngFor="let option of subItem.adjustTypes2" [value]="option.label">
                        {{ option.label | lang }}
                      </mat-option>
                    </mat-autocomplete>
                  </td>
                  <td class="border-column l-pa-4">
                    <div *ngIf="isConfirm" class="l-pt-2">
                      <span>{{ subItem.amount }}</span>
                    </div>
                    <input
                      [disabled]="item.negativeOn"
                      *ngIf="!isConfirm"
                      oninput="value=value.match(/(^(\d){0,20}\.(\d){0,4})|(^(\d){0,20})/g) || ''"
                      [(ngModel)]="subItem.amount"
                      placeholder="Input adjust amount"
                      class="text-field l-pt-1"
                      [class.text-field-error]="subItem.amountError"
                      (change)="inputAmount($event, i, j)" />
                  </td>
                  <td class="border-column l-pa-4">
                    <div *ngIf="isConfirm && !subItem.hide" class="l-pt-2">
                      <span>{{ subItem.amountPremium }}</span>
                    </div>
                    <input
                      [disabled]="item.negativeOn"
                      *ngIf="!isConfirm && !subItem.hide"
                      oninput="value=value.match(/(^(\d){0,20}\.(\d){0,4})|(^(\d){0,20})/g) || ''"
                      [(ngModel)]="subItem.amountPremium"
                      placeholder="Input premium"
                      [class.text-field-error]="subItem.amountPremiumError"
                      class="text-field l-pt-1"
                      (change)="inputAmountPremium($event, i, j)" />
                  </td>
                  <td *ngIf="!isConfirm" class="border-column l-pa-4 cds-detail1">
                    <div style="color: red; cursor: pointer" (click)="delete(i, j)">
                      <span>Delete</span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="border-right l-pl-4 l-pr-4" valign="top">
                    <cds-assistive-text class="" *ngIf="item.agentCodeError && j === 0" style="top: 0">
                      {{ item.agentCodeError | lang }}
                    </cds-assistive-text>
                  </td>
                  <td class="border-right l-pl-4 l-pr-4" valign="top">
                    <cds-assistive-text class="" *ngIf="subItem.adjustTypeError">
                      {{ subItem.adjustTypeStr }}&nbsp;{{ subItem.adjustTypeError | lang }}
                    </cds-assistive-text>
                    <cds-assistive-text type="warning" *ngIf="subItem.adjustTypeWarning">
                      {{ subItem.adjustTypeWarning | lang }}
                    </cds-assistive-text>
                  </td>
                  <td class="border-right l-pl-4 l-pr-4" valign="top">
                    <cds-assistive-text *ngIf="subItem.amountError">
                      {{ subItem.amountError | lang }}
                    </cds-assistive-text>
                    <cds-assistive-text *ngIf="subItem.amountWarning" type="warning">
                      {{ subItem.amountWarning | lang }}
                    </cds-assistive-text>
                  </td>
                  <td class="border-right l-pl-4 l-pr-4" valign="top">
                    <cds-assistive-text *ngIf="subItem.amountPremiumError">
                      {{ subItem.amountPremiumError | lang }}
                    </cds-assistive-text>
                    <cds-assistive-text *ngIf="subItem.amountPremiumWarning" type="warning">
                      {{ subItem.amountPremiumWarning | lang }}
                    </cds-assistive-text>
                  </td>
                </tr>
              </ng-container>
              <tr *ngIf="!isConfirm" class="div-border-bottom">
                <td style="border-right: 1px solid var(--cds-color-grey)"></td>
                <td class="border-column" colspan="3">
                  <div class="l-pl-3 l-pt-4 l-pb-4">
                    <div class="l-d-f">
                      <div class="icon-add" style="cursor: pointer" (click)="addAdjust(i)">
                        <cds-icon size="sm" color="white" icon="action:button_plus"></cds-icon>
                      </div>
                      <span class="font-bold l-pl-3">Add Adjust Type</span>
                    </div>
                  </div>
                </td>
              </tr>
            </table>
          </div>
        </ng-container>
      </div>
      <div *ngIf="!isConfirm" class="col-xs-12 col-md-12 l-mt-4 card l-pa-5">
        <div class="l-d-f">
          <div class="icon-add" style="cursor: pointer" (click)="addAgent()">
            <cds-icon size="sm" color="white" icon="action:button_plus"></cds-icon>
          </div>
          <span class="font-bold l-pl-3">Add agent</span>
        </div>
      </div>
      <div *ngIf="isConfirm && searchForm.policyWarning" class="col-xs-12 col-md-12 l-mt-4 card l-pa-5">
        <cds-assistive-text type="warning">{{ searchForm.policyWarning | lang }} </cds-assistive-text>
      </div>
      <div *ngIf="!isConfirm" class="l-pt-6 l-pb-3 l-d-f">
        <cds-button size="md" [config]="btnCfg" (click)="reset(false)">Reset</cds-button>
        <cds-button [disabled]="saveDisabled" size="md" class="l-ml-3" (click)="save()">Save</cds-button>
      </div>
      <div *ngIf="isConfirm" class="l-pt-6 l-pb-3 l-d-f">
        <cds-button size="md" [config]="btnCfg" (click)="backToEdit()">Back to edit</cds-button>
        <cds-button size="md" class="l-ml-3" (click)="confirm()">confirm</cds-button>
      </div>
    </div>
  </div>
</div>
