export enum TransactionType {
  INTERNAL_TRANSFER = 'INTERNAL_TRANSFER',
  TRANSFER = 'TRANSFER',
  ENROLMENT = 'ENROLMENT',
  APE_ANN_PREM = 'APE_ANN_PREM',
  APE_CONTR_REGULAR = 'APE_CONTR_REGULAR',
  APE_CONTR_LUMPSUM = 'APE_CONTR_LUMPSUM',
  APE_TRF_IN_PE = 'APE_TRF_IN_PE',
  APE_TRF_IN_PM = 'APE_TRF_IN_PM',
  APE_TRF_IN_PC = 'APE_TRF_IN_PC',
  APE_TRF_IN_PP = 'APE_TRF_IN_PP',
  APE_TRF_IN_PT = 'APE_TRF_IN_PT',
  APE_COMM_ADJUST = 'APE_COMM_ADJUST',
  SYSTEM_ADJUST_APE = 'SYSTEM_ADJUST_APE',
  MANUAL_ADJUST_112 = 'MANUAL_ADJUST_112',
  MANUAL_ADJUST_141 = 'MANUAL_ADJUST_141',
  SYSTEM_TRAILER_FEE = 'SYSTEM_TRAILER_FEE',
  MANUAL_ADJUST_136 = 'MANUAL_ADJUST_136',
}
