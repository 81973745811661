<div class="submit-success">
  <div>
    <cds-icon class="icon-history" icon="arrow:history"></cds-icon>
  </div>
  <div class="l-mt-5">
    <span class="cds-h4-demibold">{{ data.message }}</span>
  </div>
  <div class="l-mt-3">
    <cds-assistive-text type="error">{{ 'sendIffInBatchSummary.noChanges' | lang }}</cds-assistive-text>
  </div>
  <div class="l-mt-4">
    <cds-button (click)="gotIt()">{{ 'common.mingBai' | lang }}</cds-button>
  </div>
</div>
