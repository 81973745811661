import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AnnuncementCenterModule } from './annuncement-center/annuncement-center/annuncement-center.module';
import { AnnouncementRoutingModule } from './annuncement-routing.module';
import { AnnouncementDetailsModule } from './announcement-details/announcement-details.module';

@NgModule({
  imports: [CommonModule, AnnouncementRoutingModule, AnnuncementCenterModule, AnnouncementDetailsModule],
})
export class AnnuncementModule {}
