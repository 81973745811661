/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable, OnDestroy } from '@angular/core';
import { APIPaths } from '../_common/constant';
import { Observable, Subject } from 'rxjs';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
declare const SockJS: any;
// eslint-disable-next-line @typescript-eslint/no-unused-vars
declare const Stomp: any;
declare const StompJs: any;
// var destination = '/queue/someQueueName';

@Injectable({
  providedIn: 'root',
})
export class MessageService implements OnDestroy {
  public stompClient: any;
  public msg: any = [];
  private receivedMessagesSubject = new Subject<string>();
  subscription: any;
  constructor() {}

  // async initializeWebSocketConnection(roomId: any, username: string) {
  //   const serverUrl = APIPaths.websocket;
  //   const socket = new SockJS(serverUrl);
  //   this.stompClient = Stomp.over(socket);
  //   this.stompClient.connect({}, (frame: any) => {
  //     this.stompClient.subscribe(`/topic/public/${roomId}`, (message: any) => {
  //       // Tell your username to the server
  //       const receivedMessage = JSON.parse(message.body);
  //       var d = new Date();
  //       receivedMessage.time =  d.getHours() +':'+ d.getMinutes() +':'+ d.getSeconds()
  //       this.receivedMessagesSubject.next(receivedMessage);
  //     });
  //   });
  // }

  // sendMessage(message: any, roomId: any) {
  //   this.stompClient.send(`/app/chat.sendMessage/${roomId}`, {}, JSON.stringify(message));
  // }

  // joinMessage(message: any, roomId: any) {
  //   this.stompClient.send(`/app/chat.addUser/${roomId}`, {}, JSON.stringify(message));
  // }

  // receiveMessages(): Observable<string> {
  //   return this.receivedMessagesSubject.asObservable();
  // }

  async initializeWebSocketConnection(roomId: any, username: string) {
    this.stompClient = null;
    this.stompClient = new StompJs.Client();
    this.stompClient.webSocketFactory = () => new WebSocket(APIPaths.websocket);
    this.stompClient.activate();

    this.stompClient.onConnect = (frame: any) => {
      console.log('Connected: ' + frame);
      if (this.subscription) {
        this.subscription.unsubscribe(); // Unsubscribe from previous room
      }
      this.subscription = this.stompClient.subscribe(`/topic/public/${roomId}`, (message: any) => {
        const receivedMessage = JSON.parse(message.body);
        this.receivedMessagesSubject.next(receivedMessage);
      });
    };

    this.stompClient.onWebSocketError = (error: any) => {
      console.error('Error with websocket', error);
    };

    this.stompClient.onStompError = (frame: any) => {
      console.error('Broker reported error: ' + frame.headers['message']);
      console.error('Additional details: ' + frame.body);
    };
  }

  sendMessage(message: any, roomId: any) {
    //this.stompClient.send(`/app/chat.sendMessage/${roomId}`, {}, JSON.stringify(message));
    this.stompClient.publish({
      destination: `/app/chat.sendMessage/${roomId}`,
      body: JSON.stringify(message),
    });
  }

  joinMessage(message: any, roomId: any) {
    this.stompClient.publish({
      destination: `/app/chat.addUser/${roomId}`,
      body: JSON.stringify(message),
    });
    // this.stompClient.send(`/app/chat.addUser/${roomId}`, {}, JSON.stringify(message));
  }

  leaveRoom(message: any, roomId: any) {
    this.stompClient.publish({
      destination: `/app/chat.closeChat/${roomId}`,
      body: JSON.stringify(message),
    });
  }

  receiveMessages(): Observable<string> {
    return this.receivedMessagesSubject.asObservable();
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
