/* eslint-disable @typescript-eslint/no-explicit-any */
import { AbstractControl, ValidationErrors } from '@angular/forms';

export const agentCodeValidator = (errors?: ValidationErrors) => {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value?.trim();
    if (!value) {
      return null;
    }
    const reg = /^[0-9]{1,6}$/;
    if (!reg.test(value)) {
      return errors || { agentCodeValidator: true };
    }
    return null;
  };
};
