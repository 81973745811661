<div class="employer-summary l-pt-6">
  <div class="title">
    <span class="dlgt-header">{{ 'hostory.delegationPlatform' | lang }}</span>
  </div>
  <div class="div-border-top">
    <div class="dlgt-header-desc">
      <div>{{ 'hostory.delegationPlatformDesc' | lang }}</div>
    </div>
  </div>
  <div>
    <span class="dlgt-search-and-result">{{ 'hostory.searchRecord' | lang }} </span>
  </div>
  <div class="row search-box">
    <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12 search-box-item">
      <!-- <cds-textfield
        [value]="employerName"
        #employerNameSearchBox
        (input)="nameChange(employerNameSearchBox.value)"
        label="{{ 'employer.employerName' | lang }}"
        placeholder="{{ 'historyHolder.employer' | lang }}"
        style="max-width: 400px"
        [config]="employerNameTxtfieldConfig"></cds-textfield> -->

      <app-cd-input
        [(value)]="employerName"
        (valueChange)="nameChange($event)"
        [placeholder]="'historyHolder.employer' | lang"
        [label]="'employee.employeeName' | lang"></app-cd-input>
    </div>
    <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12 search-box-item">
      <!-- <cds-textfield
        [value]="groupNo"
        #employerNoSearchBox
        (input)="groupNoChange(employerNoSearchBox.value)"
        label="{{ 'employer.no' | lang }}"
        placeholder="{{ 'historyHolder.groupNo' | lang }}"
        style="max-width: 400px"
        [config]="numbersTxtfieldConfig">
      </cds-textfield> -->

      <app-cd-input
        [(value)]="groupNo"
        (valueChange)="groupNoChange($event)"
        [placeholder]="'historyHolder.groupNo' | lang"
        style="max-width: 400px"
        [label]="'employer.no' | lang"></app-cd-input>
    </div>
    <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12 search-box-item">
      <!-- <cds-textfield
        [value]="dlgtAgentCode"
        #agentCodeBox
        (input)="delegateAgentChange(agentCodeBox.value)"
        placeholder="{{ 'historyHolder.agent' | lang }}"
        label="{{ 'employer.delegateAgencode' | lang }}"
        style="max-width: 400px"
        [config]="agentTxtfieldConfig"></cds-textfield> -->

      <app-cd-input
        [(value)]="dlgtAgentCode"
        (valueChange)="delegateAgentChange($event)"
        [placeholder]="'historyHolder.agent' | lang"
        style="max-width: 400px"
        [label]="'employer.delegateAgencode' | lang"></app-cd-input>
    </div>

    <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12 search-box-item">
      <cds-dropdown
        *ngIf="language === 'EN'"
        [formControl]="stateFormControl"
        (change)="reqStatusChange($event)"
        placeholder="{{ 'employer.checkPlaceHolder' | lang }}"
        [config]="statusEnRangeDropdownConfig"
        label="{{ 'employee.ageRank' | lang }}"></cds-dropdown>
      <cds-dropdown
        *ngIf="language !== 'EN'"
        [formControl]="stateFormControl"
        (change)="reqStatusChange($event)"
        placeholder="{{ 'employer.checkPlaceHolder' | lang }}"
        [config]="statusZhRangeDropdownConfig"
        label="{{ 'employee.ageRank' | lang }}"></cds-dropdown>
    </div>

    <div *ngIf="!resetDatePicker" class="col-lg-3 col-md-6 col-sm-12 col-xs-12 search-box-item">
      <cds-textfield
        [ngModel]="startTime"
        (ngModelChange)="startTimeChange($event)"
        placeholder="DD/MM/YYYY"
        label="{{ 'histroy.createSt' | lang }}"
        [cdsDatepicker]="picker">
      </cds-textfield>
      <cds-datepicker #picker="cdsDatepicker"></cds-datepicker>
    </div>

    <div *ngIf="!resetDatePicker" class="col-lg-3 col-md-6 col-sm-12 col-xs-12 search-box-item">
      <cds-textfield
        [ngModel]="endTime"
        (ngModelChange)="endTimeChange($event)"
        placeholder="DD/MM/YYYY"
        label="{{ 'histroy.createEd' | lang }}"
        [cdsDatepicker]="pickerEnd">
      </cds-textfield>
      <cds-datepicker #pickerEnd="cdsDatepicker"></cds-datepicker>
    </div>

    <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12 search-box-item">
      <div class="double-botton">
        <div>
          <cds-button
            [disabled]="searchBtnDisableState"
            [config]="primaryButtonConfig"
            label="{{ 'employer.searchBtnTxt' | lang }}"
            (click)="employerSearch()"></cds-button>
        </div>
        <div>
          <cds-button [config]="secondaryButtonConfig" label="{{ 'delegationChange.reset' | lang }}" (click)="clearData()"></cds-button>
        </div>
      </div>
    </div>
  </div>

  <div class="dlgt-search-and-result">
    <div class="total-handle">
      <span>{{ 'hostory.requestStatus' | lang }}</span>
      <span class="total-items"> {{ 'employer.total' | lang }} {{ employerTotalCount }} {{ 'employer.items' | lang }}</span>
    </div>
    <div class="right-batton">
      <cds-button (click)="addDlgtRequest()" [config]="secondaryButtonConfig" label="{{ 'delegate.addDelegationChangeRequest' | lang }}"> </cds-button>
    </div>
  </div>

  <div class="row" *ngIf="summaryIsShowProgress">
    <div class="col-lg-3"></div>
    <cds-progress class="col-lg-6" mode="indeterminate" [type]="cdsProgressType" [text]="cdsProgressTxt" [color]="cdsProgressColor"></cds-progress>
    <div class="col-lg-3"></div>
  </div>

  <div *ngIf="employerTotalCount !== 0" [style.visibility]="fixTextRemains ? 'hidden' : 'visible'" class="cds-h6 n-t-title-area row v-center">
    <div class="n-t-title col-sm-1"></div>
    <div class="n-t-title col-sm-3 sort-head">
      {{ 'hostory.employerName' | lang }}

      <div *ngIf="erNameSort === ''" class="sort-handle pointer" (click)="onSortChange({ active: 'employerName', direction: 'asc' })">
        <cds-icon class="update-icon" [icon]="allSortErIcon"></cds-icon>
      </div>
      <div *ngIf="erNameSort === 'asc'" class="sort-handle pointer" (click)="onSortChange({ active: 'employerName', direction: 'desc' })">
        <cds-icon class="update-icon" [icon]="ascSortIcon"></cds-icon>
      </div>
      <div *ngIf="erNameSort === 'desc'" class="sort-handle pointer" (click)="onSortChange({ active: '', direction: '' })">
        <cds-icon class="update-icon" [icon]="descSortIcon"></cds-icon>
      </div>
    </div>
    <div class="n-t-title col-sm-3 sort-head">
      {{ 'hostory.groupNo' | lang }}

      <div *ngIf="groupNoSort === ''" class="group-sort pointer" (click)="onSortChange({ active: 'employerNo', direction: 'asc' })">
        <cds-icon class="update-icon" [icon]="allSortErIcon"></cds-icon>
      </div>
      <div *ngIf="groupNoSort === 'asc'" class="group-sort pointer" (click)="onSortChange({ active: 'employerNo', direction: 'desc' })">
        <cds-icon class="update-icon" [icon]="ascSortIcon"></cds-icon>
      </div>
      <div *ngIf="groupNoSort === 'desc'" class="group-sort pointer" (click)="onSortChange({ active: '', direction: '' })">
        <cds-icon class="update-icon" [icon]="descSortIcon"></cds-icon>
      </div>
    </div>
    <div class="n-t-title col-sm-3 sort-head">
      {{ 'hostory.requestCreatedDate' | lang }}

      <div *ngIf="createSort === ''" class="create-sort pointer" (click)="onSortChange({ active: 'requestCreateDate', direction: 'asc' })">
        <cds-icon class="update-icon" [icon]="allSortErIcon"></cds-icon>
      </div>
      <div *ngIf="createSort === 'asc'" class="create-sort pointer" (click)="onSortChange({ active: 'requestCreateDate', direction: 'desc' })">
        <cds-icon class="update-icon" [icon]="ascSortIcon"></cds-icon>
      </div>
      <div *ngIf="createSort === 'desc'" class="create-sort pointer" (click)="onSortChange({ active: '', direction: '' })">
        <cds-icon class="update-icon" [icon]="descSortIcon"></cds-icon>
      </div>
    </div>
    <div class="n-t-title col-sm-2 sort-head">
      {{ 'hostory.reqStatus' | lang }}

      <div *ngIf="statusSort === ''" class="status-sort pointer" (click)="onSortChange({ active: 'status', direction: 'asc' })">
        <cds-icon class="update-icon" [icon]="allSortErIcon"></cds-icon>
      </div>
      <div *ngIf="statusSort === 'asc'" class="status-sort pointer" (click)="onSortChange({ active: 'status', direction: 'desc' })">
        <cds-icon class="update-icon" [icon]="ascSortIcon"></cds-icon>
      </div>
      <div *ngIf="statusSort === 'desc'" class="status-sort pointer" (click)="onSortChange({ active: '', direction: '' })">
        <cds-icon class="update-icon" [icon]="descSortIcon"></cds-icon>
      </div>
    </div>
  </div>

  <div [style.visibility]="fixTextRemains ? 'hidden' : 'visible'" class="n-t-content">
    <div class="n-t-content-area" *ngFor="let item of summaryList; index as i">
      <div (click)="doClickDetail($event, item)" class="cds-body1 n-t-content-short-des v-center row">
        <div class="n-icon-area col-sm-1">
          <div class="icon backrotate">
            <cds-icon icon="action:button_right" width="32px" height="32px"></cds-icon>
          </div>
        </div>
        <div class="n-short-des col-sm-3">
          <span class="cds-body1-demibold">
            {{
              this.language === 'EN'
                ? item.employerInfo.employerName
                  ? item.employerInfo.employerName
                  : item.employerInfo.employerNameTC
                : item.employerInfo.employerNameTC
                ? item.employerInfo.employerNameTC
                : item.employerInfo.employerName
            }}
          </span>
        </div>
        <div class="n-short-des col-sm-3">
          {{ item.employerInfo.employerNo }}
        </div>
        <div class="n-short-des col-sm-3">
          {{ item.requestCreateDate | ssmsDate }}
        </div>
        <div class="n-short-des col-sm-2">
          <div [ngSwitch]="item.status">
            <div *ngSwitchCase="'Approved'" class="prodtype-lightalign">
              <div style="display: flex; align-items: center">
                <div class="light green"></div>
              </div>
              <div>{{ 'erChangeSummary.approved' | lang }}</div>
            </div>

            <div *ngSwitchCase="'Declined'" class="prodtype-lightalign">
              <div class="prodtype-lightacontrol">
                <div class="light declined"></div>
              </div>
              <div>{{ 'erChangeSummary.declined' | lang }}</div>
            </div>

            <div *ngSwitchCase="'Partially Approved'" class="prodtype-lightalign">
              <div class="prodtype-lightacontrol">
                <div class="light turquoise"></div>
              </div>
              <div>{{ 'erChangeSummary.partiallyApproved' | lang }}</div>
            </div>

            <div *ngSwitchCase="'Pending DD Approval'" class="prodtype-lightalign">
              <div class="prodtype-lightacontrol">
                <div class="light orange"></div>
              </div>
              <div>{{ 'erChangeSummary.pendingDDApproval' | lang }}</div>
            </div>

            <div *ngSwitchDefault class="prodtype-lightalign">
              <div class="prodtype-lightacontrol">
                <div class="light orange"></div>
              </div>
              <div>{{ 'erChangeSummary.pendingToSend' | lang }}</div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="item.isShowDetail">
        <div *ngFor="let detil of item.items; index as i">
          <div class="n-t-content-detail-des row">
            <div class="col-sm-1"></div>
            <div class="col-sm-3 n-detail-content">
              <div class="detail-title v-center cds-body2 dlgt-agent-change">
                {{ 'hostoryDetail.agentChange' | lang }}
              </div>
              <div class="detail-title v-center cds-body2" style="height: 50px; align-items: center"></div>
              <!-- <div class="detail-title v-center cds-body1">1 &nbsp;</div> -->
              <div class="detail-title v-center cds-body2 dlgt-agent-employee-invo">
                {{ 'hostoryDetail.status' | lang }}
              </div>
              <div class="detail-title v-center cds-body2 dlgt-agent-employee-invo">
                {{ 'hostoryDetail.employeeInvolved' | lang }}
              </div>
              <div class="detail-title v-center cds-body2 dlgt-agent-employee-invo">
                {{ 'hostoryDetail.resonAgent' | lang }}
              </div>
            </div>

            <div class="col-sm-3 n-detail-content">
              <div class="detail-title v-center cds-body2 exiting-delgt-agt">
                {{ 'hostoryDetail.existAgent' | lang }}
              </div>
              <div class="detail-title v-center cds-body2" style="height: 50px">
                <div class="agent-light">{{ detil.oldAgent.agentCode }}</div>
                <div class="agent-light-ts">
                  {{
                    this.language === 'EN'
                      ? detil.oldAgent.agentName
                        ? detil.oldAgent.agentName
                        : detil.oldAgent.agentNameTC
                      : detil.oldAgent.agentNameTC
                      ? detil.oldAgent.agentNameTC
                      : detil.oldAgent.agentName
                  }}
                </div>
              </div>
              <div class="detail-title v-center cds-body2">
                <div [ngSwitch]="detil.status">
                  <div *ngSwitchCase="'Approved'" class="prodtype-lightalign">
                    <div style="display: flex; align-items: center">
                      <div class="light green"></div>
                    </div>
                    <div class="dlgt-detail-agent-status">
                      {{ 'erChangeSummary.approved' | lang }}
                    </div>
                  </div>

                  <div *ngSwitchCase="'Pending DD Approval'" class="prodtype-lightalign">
                    <div class="prodtype-lightacontrol">
                      <div class="light orange"></div>
                    </div>
                    <div class="dlgt-detail-agent-status">
                      {{ 'erChangeSummary.pendingDDApproval' | lang }}
                    </div>
                  </div>

                  <div *ngSwitchCase="'Partially Approved'" class="prodtype-lightalign">
                    <div class="prodtype-lightacontrol">
                      <div class="light turquoise"></div>
                    </div>
                    <div class="dlgt-detail-agent-status">
                      {{ 'erChangeSummary.partiallyApproved' | lang }}
                    </div>
                  </div>

                  <div *ngSwitchCase="'Declined'" class="prodtype-lightalign">
                    <div class="prodtype-lightacontrol">
                      <div class="light declined"></div>
                    </div>
                    <div class="dlgt-detail-agent-status">
                      {{ 'erChangeSummary.declined' | lang }}
                    </div>
                  </div>

                  <div *ngSwitchDefault class="prodtype-lightalign">
                    <div class="prodtype-lightacontrol">
                      <div class="light"></div>
                    </div>
                    <div class="dlgt-detail-agent-status"></div>
                  </div>
                </div>
              </div>
              <div class="detail-title v-center cds-body2">
                <div class="dlgt-agent-change-reason">
                  {{ detil.noOfMemberInvolved }}
                </div>
                <div class="right-handle" (click)="forwordDetil(item.employerInfo, detil.serialDetailNo!, detil)">
                  <cds-icon class="update-icon" [icon]="detailIcon" [config]="backErIconConfig"></cds-icon>
                  <span class="update-icon"> {{ 'histroy.detailView' | lang }}</span>
                </div>
              </div>
              <div class="detail-title v-center cds-body2 dlgt-agent-change-reason">
                <!-- {{ detil.reasonText }} -->
                {{ this.language === 'EN' ? detil.reasonText : detil.reasonTextTC ? detil.reasonTextTC : detil.reasonText }}
              </div>
            </div>

            <div class="col-sm-1 n-detail-content">
              <div class="detail-title v-center cds-body2"></div>
              <div class="detail-title v-center cds-body2"></div>
              <div class="detail-title v-center cds-body2">
                <div class="icon-box">
                  <cds-icon [icon]="rightArrowIcon"></cds-icon>
                </div>
              </div>
            </div>

            <div class="col-sm-3 n-detail-content">
              <div class="detail-title v-center cds-body2 dlgt-new-agent">
                {{ 'hostoryDetail.newAgent' | lang }}
              </div>
              <div class="detail-title v-center cds-body2" style="height: 50px">
                <div class="agent-light">{{ detil.targetAgent.agentCode }}</div>
                <div class="agent-light-ts">
                  {{
                    this.language === 'EN'
                      ? detil.targetAgent.agentName
                        ? detil.targetAgent.agentName
                        : detil.targetAgent.agentNameTC
                      : detil.targetAgent.agentNameTC
                      ? detil.targetAgent.agentNameTC
                      : detil.targetAgent.agentName
                  }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-no-result-found *ngIf="employerTotalCount === 0 && !summaryIsShowProgress"></app-no-result-found>
  <div class="row result-table-footer">
    <div class="col-sm-6 col-xs-12 footer-pagination">
      <cds-pagination [color]="color" [pageCount]="summaryPageCount" [page]="summaryPage" (pageChange)="pageChange($event)"> </cds-pagination>
    </div>
    <div class="col-sm-6 col-xs-12 page-search">
      <span> Page </span>
      <input class="input" [ngModel]="summaryPage" type="number" #pageNoSearchBox (keydown.enter)="searchByInputPageNo($event, pageNoSearchBox.value)" />
      <span> of {{ summaryPageCount }} </span>
    </div>
  </div>
</div>
