/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit } from '@angular/core';
import { CdPopupService, CdPopupSize, MatDialogRef } from 'src/app/shared/cd-popup';
import { EditDeclinedWeatherComponent } from './edit-declined-weather/edit-declined-weather.component';
import { EditDeclinedCommissionComponent } from './edit-declined-commission/edit-declined-commission.component';

import { CalendarService } from 'src/app/views/system/calendar/calendar.service';
import { EventTypeKey } from '../calendar.model';

@Component({
  selector: 'app-event-declined',
  templateUrl: './event-declined.component.html',
  styleUrls: ['./event-declined.component.scss'],
})
export class EventDeclinedComponent implements OnInit {
  constructor(private dialogRef: MatDialogRef<EventDeclinedComponent>, private cdsPopup: CdPopupService, public calendarService: CalendarService) {}

  ngOnInit(): void {}

  edit(data: any) {
    if (data.eventType == EventTypeKey.COMMISSION) {
      const popupRef: MatDialogRef<EditDeclinedCommissionComponent> = this.cdsPopup.open(EditDeclinedCommissionComponent, {
        size: CdPopupSize.LARGE,
        data: { event: data },
      });
      popupRef.afterClosed().subscribe(() => {
        this.closePopup();
      });
    }
    if (data.eventType == EventTypeKey.TYPHOON) {
      const popupRef: MatDialogRef<EditDeclinedWeatherComponent> = this.cdsPopup.open(EditDeclinedWeatherComponent, {
        size: CdPopupSize.LARGE,
        panelClass: ['calendar-popup-bg'],
        data: { event: data },
      });
      popupRef.afterClosed().subscribe(() => {
        this.closePopup();
      });
    }
  }

  closePopup() {
    if (!this.calendarService.declineEvents.length) {
      this.dialogRef.close();
    }
  }
}
