import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AgentComponent } from './agent/agent.component';
import { PermissionAccess, PermissionItem } from 'src/app/core/models/enum/permission.enum';

const routes: Routes = [
  {
    path: 'chatbot',
    children: [
      {
        path: 'cso',
        component: AgentComponent,
        data: {
          roles: {
            [PermissionItem.CHATBOT_CSO]: PermissionAccess.R,
          },
        },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AngentRoutingModule {}
