/* eslint-disable @typescript-eslint/no-explicit-any */
import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CdsButtonConfig } from '@cds/ng-core/button';
import { CdsIconConfig } from '@cds/ng-core/icon';
import { CdsLangService } from '@cds/ng-core/lang';
import { ActionIcons } from '@cds/ng-themes';
import { CdsAlertService } from '@cds/ng-web-components/alert';
import { CdsPopupService } from '@cds/ng-web-components/popup';
import { Subscription, from, switchMap, throwError } from 'rxjs';
import { AgentChannel } from 'src/app/core/models/enum/agent-channel.enum';
import { PermissionAccess, PermissionItem } from 'src/app/core/models/enum/permission.enum';
import { ScreenType } from 'src/app/core/models/enum/screen.enum';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { NotifyService } from 'src/app/core/services/notify.service';
import { PermissionService } from 'src/app/core/services/permission.service';
import { LanguageChangeService } from 'src/app/shared/language-change.service';
import { getSessionByKey } from 'src/app/utils/indexdb';
import { cdsPopupBackdropOntouchmovePreventDefault, cdsPopupBackdropUnOntouchmovePreventDefault, pageToTop } from 'src/app/utils/utils';
import { AdviseIffstatusComponent } from '../case-summary/advise-iffstatus/advise-iffstatus.component';
import { AdviseTransferStatusComponent } from '../case-summary/advise-transfer-status/advise-transfer-status.component';
import { CasesummaryService } from '../case-summary/casesummary.service';
import { DifferentAgentStatusComponent } from '../different-agent-status/different-agent-status.component';
import { ResendEiffComponent } from '../resend-eiff/resend-eiff.component';
import { ResendEiffResendType } from '../resend-eiff/resend-eiff.model';
import { CaseDetailsService } from '../service/case-details.service';
import { IffService } from '../service/iff.service';
import { CaseDetailRequest } from '../service/model/case-detail-request.model';
import { CdHttpServeService } from './../../../shared/cd-http-serve/cd-http-serve.service';
import { AgentsInfoRsp, CaseDetailsRsp, RejectReasonsMap } from './case-details.model';
import { VoidEiffPopupComponent } from '../sales-support-summary/void-eiff-popup/void-eiff-popup.component';

interface TransferDetailsItemRsp {
  empfRefNo: string;
  newMILScheme: {
    newTrusteeName: string;
    schemeName: string;
    schemeMemberAccNum: string;
    accType: string;
  };
  number: number;
  originalScheme: {
    accType: string;
    originalTrusteeName: string;
    schemeMemberAccNum: string;
    schemeName: string;
  };
  productType: string;
  transferStatus: string;
  transferStatusEnum: number;
  rejectReasons: { rejectReason1: number; rejectReason2: number; rejectReason3: number; rejectReason4: number; rejectReason5: number };
}

export interface VoidInfo {
  voidAble: boolean;
  voidTime: string;
  voided: boolean;
  voidAgentName: string;
  voidAgentCode: string;
}

@Component({
  selector: 'app-case-details',
  templateUrl: './case-details.component.html',
  styleUrls: ['./case-details.component.scss'],
})
export class CaseDetailsComponent implements OnInit, AfterViewInit, OnDestroy {
  PermissionItem = PermissionItem;
  PermissionAccess = PermissionAccess;
  servicingAgentChannel?: AgentChannel;
  ScreenType = ScreenType;
  disableEditAgentByChannel = false;
  disableVoidEIFF = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private caseDetailsService: CaseDetailsService,
    public languageChangeService: LanguageChangeService,
    public cdsLangService: CdsLangService,
    private cdsPopup: CdsPopupService,
    public caseSummaryService: CasesummaryService,
    private cdHttpServeService: CdHttpServeService,
    private alert: CdsAlertService,
    private notifyService: NotifyService,
    private permissionService: PermissionService,
    private authenticationService: AuthenticationService,
    private iffService: IffService
  ) {}

  caseDetails?: CaseDetailsRsp;
  backErIcon = ActionIcons.button_left_filled;
  lanChangeSubscription?: Subscription;
  backErIconConfig: CdsIconConfig = {
    color: 'cta',
    size: 'sm',
  };
  language = this.cdsLangService.currentLang === 'en' ? 'EN' : 'TC';
  secondaryButtonConfig: CdsButtonConfig = {
    size: 'sm',
    style: 'secondary',
  };
  salesJourneySummariesId?: string;
  empfRefNo?: string;
  from?: string;
  productType?: string;
  salesSupportSummaryTab?: string;
  isSendS2 = false;
  isShowSend = false;
  isServicingAgent = false;
  screenType: ScreenType = ScreenType.PC;

  transferDetails: TransferDetailsItemRsp[] = [];
  empfDetailsSubscription?: Subscription;
  ngOnInit(): void {
    this.lanChangeSubscription = this.languageChangeService.lanSubject.subscribe(lan => {
      this.language = lan === 'en' ? 'EN' : 'TC';
    });
    this.salesJourneySummariesId = this.route.snapshot.paramMap.get('salesJourneySummariesId') || '';
    this.empfRefNo = this.route.snapshot.paramMap.get('empfRefNo') || '';
    this.from = this.route.snapshot.paramMap.get('from') || '';
    this.productType = this.route.snapshot.paramMap.get('productType') || '';
    this.salesSupportSummaryTab = this.route.snapshot.paramMap.get('salesSupportSummaryTab') || '';

    from(getSessionByKey())
      .pipe(
        switchMap(res => {
          const identityId = res.id;
          if (this.salesJourneySummariesId) {
            const params = {
              salesJourneySummaryId: this.salesJourneySummariesId,
              identityId,
            };
            return this.cdHttpServeService.post<CaseDetailsRsp>('/ext/eb-ssms-sales-journey-service/memberSalesJourneySummaries/caseDetails', params);
          } else {
            return throwError('salesJourneySummariesId is not found');
          }
        })
      )
      .subscribe({
        next: res => {
          if (res.result !== 0) {
            this.alert.warning('Warning!', `${res.message}`);
          } else {
            this.caseDetails = res.data;
            this.getServicingAgentChannel();
            this.authenticationService.currentUserValue().then(res => {
              const servicingAgentId = res.id + '';
              this.iffService.getAgentCode(servicingAgentId).subscribe({
                next: res => {
                  if (res.result !== 0) {
                    this.alert.warning('Warning!', `${res.message}`);
                  } else {
                    const agentList = this.caseDetails?.agentsInfos?.filter(agent => agent.agentCd === res.data.agentCode);
                    this.isServicingAgent = Boolean(agentList && agentList[0]?.servicingAgent);
                  }
                },
                error: err => {
                  this.alert.error('Error!', err);
                },
              });
            });
          }
        },
        error: err => {
          this.alert.error('Error!', err);
        },
      });
    this.callTransferDetails();
    this.checkSendS2();
  }

  getServicingAgentChannel() {
    if (this.primaryAgentInfo && this.primaryAgentInfo.agentCd) {
      this.cdHttpServeService
        .post<
          {
            agentCode: string;
            inputAgentType: string;
            channel: string;
            servicingAgentCode: string;
            commissionAgentCode: string;
          }[]
        >(`/ext/eb-ssms/agent-service/api/v1/agent-code-conversion`, [this.primaryAgentInfo.agentCd])
        .subscribe(res => {
          this.servicingAgentChannel = res.data[0].channel as AgentChannel;
          this.disableEditAgentByChannel = [AgentChannel.SCB, AgentChannel.BROKER, AgentChannel.CORPORATE].includes(this.servicingAgentChannel);

          if (this.primaryAgentInfo && this.primaryAgentInfo.agentCd) {
            this.disableVoidEIFF =
              this.servicingAgentChannel === AgentChannel.DBS ||
              (this.servicingAgentChannel === AgentChannel.CORPORATE &&
                this.primaryAgentInfo.agentCd.startsWith('400') &&
                this.primaryAgentInfo.agentCd !== '400003');
          }
        });
    }
  }

  callTransferDetails() {
    if (this.empfRefNo) {
      const params = { empfRefNo: this.empfRefNo };
      this.cdHttpServeService
        .post<TransferDetailsItemRsp[]>('/ext/eb-ssms-sales-journey-service/memberSalesJourneySummaries/transferDetails', params)
        .subscribe({
          next: res => {
            if (res.result !== 0) {
              this.alert.warning('Warning!', `${res.message}`);
            } else {
              this.transferDetails = res.data;
            }
          },
          error: err => {
            this.alert.error('Error!', err);
          },
        });
    }
  }

  rejectReasonsNum(rejectReasons: { rejectReason1: number; rejectReason2: number; rejectReason3: number; rejectReason4: number; rejectReason5: number }) {
    const reasons = [];
    if (rejectReasons.rejectReason1) {
      reasons.push(rejectReasons.rejectReason1);
    }
    if (rejectReasons.rejectReason2) {
      reasons.push(rejectReasons.rejectReason2);
    }
    if (rejectReasons.rejectReason3) {
      reasons.push(rejectReasons.rejectReason3);
    }
    if (rejectReasons.rejectReason4) {
      reasons.push(rejectReasons.rejectReason4);
    }
    if (rejectReasons.rejectReason5) {
      reasons.push(rejectReasons.rejectReason5);
    }
    return reasons;
  }

  getRejectReason(reasonNum: number) {
    const val = (RejectReasonsMap as any)[reasonNum];
    return val || 'N/A';
  }

  private resizeListener = () => {
    const isPad = window.matchMedia('only screen and (max-width: 1023px)').matches || window.screen.width < 1023;
    if (isPad) {
      this.screenType = ScreenType.PAD;
      this.notifyService.notifyHideNavigationLang();
    } else {
      this.screenType = ScreenType.PC;
    }
  };

  ngAfterViewInit(): void {
    Promise.all([
      this.permissionService.hasPermission(PermissionAccess.W, PermissionItem.SALES_JOURNEY_AGENT),
      this.permissionService.hasPermission(PermissionAccess.W, PermissionItem.CUSTOMER_MEMBER),
    ]).then(hasPermission => {
      this.isShowSend = hasPermission[0] || hasPermission[1];
    });
    pageToTop();
    if (this.from === 'superAdmin') {
      this.resizeListener();
      this.notifyService.notifyHideNavigationLang();
      window.addEventListener('resize', this.resizeListener);
      this.languageChangeService.setLang('en');
    }
  }

  ngOnDestroy(): void {
    if (this.from === 'superAdmin') {
      this.notifyService.notifyDisplayNavigationLang();
      window.removeEventListener('resize', this.resizeListener);
    }
  }

  get getClientName() {
    if (!this.caseDetails) {
      return 'N/A';
    }
    if (this.cdsLangService.currentLang === 'en') {
      return this.caseDetails.clientNameEng || this.caseDetails.clientNameChn;
    } else {
      return this.caseDetails.clientNameChn || this.caseDetails.clientNameEng;
    }
  }

  get primaryAgentInfo() {
    if (this.caseDetails && this.caseDetails.agentsInfos) {
      const list = this.caseDetails.agentsInfos.filter(element => element.primaryAgent);
      if (list && list.length > 0) {
        return list[0];
      }
    }
    return undefined;
  }

  get splitAgentInfos() {
    if (this.caseDetails && this.caseDetails.agentsInfos) {
      const list = this.caseDetails.agentsInfos.filter(element => !element.primaryAgent);
      return list;
    }
    return [];
  }

  get getEiffStatusRemarks() {
    if (this.empfRefNo !== this.caseDetails?.empfRefNo) {
      return 'No case matched with eMPF data';
    } else if (this.empfRefNo === this.caseDetails?.empfRefNo) {
      return 'Wrong agent mapped';
    }
    // 'Client Name mismatched'
    return '';
  }

  get isShowVoidIFFBtn() {
    if (this.caseDetails && [0, 10, 20, 97].includes(this.caseDetails.iffStatusEnum) && this.caseDetails.empfStatusEnum !== undefined) {
      return true;
    }
    return false;
  }

  get isShowSendeIFFBtn() {
    if (this.caseDetails && this.caseDetails.iffStatusEnum === 0 && ![50, 40, 99].includes(this.caseDetails.empfStatusEnum)) {
      return true;
    }
    return false;
  }

  get isShowResendeIFFBtn() {
    if (this.caseDetails && ![0, 40, 99].includes(this.caseDetails.iffStatusEnum) && ![50, 40, 99].includes(this.caseDetails.empfStatusEnum)) {
      return true;
    }
    return false;
  }

  goEditAgentDetails() {
    if (this.disableEditAgentByChannel) {
      return;
    }
    this.router.navigate(['/salesjourney/edit-agent-details', this.salesJourneySummariesId, { type: 'EE' }]);
  }

  onDisplayVoidEiffRef() {
    if (this.caseDetails?.voidIffVo?.voided || this.disableVoidEIFF) {
      return;
    }

    const ref = this.cdsPopup.open(VoidEiffPopupComponent, {
      size: 'sm',
    });

    ref.afterClosed().subscribe(data => {
      if (data.agree) {
        this.voidCase();
      }
    });
  }

  voidCase() {
    if (this.salesJourneySummariesId && this.empfRefNo) {
      this.notifyService.screenLoadingSubject.next(true);
      this.cdHttpServeService
        .post<VoidInfo>('/ext/eb-ssms-sales-journey-service/voidCase', {
          salesJourneySummaryId: this.salesJourneySummariesId,
          empfRefNo: this.empfRefNo,
        })
        .subscribe({
          next: res => {
            if (res.result !== 0) {
              this.alert.warning('Warning!', `${res.message}`);
            } else {
              if (res.data && this.caseDetails) {
                this.caseDetails.voidIffVo = res.data;

                if (this.caseDetails.voidIffVo && this.caseDetails.voidIffVo.voided) {
                  this.caseDetails.iffStatusEnum = 97;
                }
              }
            }
            this.notifyService.screenLoadingSubject.next(false);
          },
          error: err => {
            this.alert.error('Error!', err);
            this.notifyService.screenLoadingSubject.next(false);
          },
        });
    }
  }

  onDisplayAdviseIFF() {
    const ref = this.cdsPopup.open(AdviseIffstatusComponent, {
      size: 'sm',
      data: {
        message: 'The changes you’ve made will be lost, are you sure to continue?',
        cancel: 'Cancel',
        continue: 'Continue',
      },
    });

    ref.afterOpened().subscribe(() => {
      cdsPopupBackdropOntouchmovePreventDefault();
    });

    ref.afterClosed().subscribe(() => {
      cdsPopupBackdropUnOntouchmovePreventDefault();
    });
  }

  openDifferentAgentStatus() {
    const ref = this.cdsPopup.open(DifferentAgentStatusComponent, {
      size: 'sm',
    });

    ref.afterOpened().subscribe(() => {
      cdsPopupBackdropOntouchmovePreventDefault();
    });

    ref.afterClosed().subscribe(() => {
      cdsPopupBackdropUnOntouchmovePreventDefault();
    });
  }

  openTransferStatusStatus() {
    const ref = this.cdsPopup.open(AdviseTransferStatusComponent, {
      size: 'sm',
    });

    ref.afterOpened().subscribe(() => {
      cdsPopupBackdropOntouchmovePreventDefault();
    });

    ref.afterClosed().subscribe(() => {
      cdsPopupBackdropUnOntouchmovePreventDefault();
    });
  }

  onBackToCaseSummaryClick() {
    this.router.navigate(['/salesjourney/product-type-summary/case-summary']);
  }

  onBackToSalesSupportSummaryClick() {
    const queryParams = { productType: this.productType, salesSupportSummaryTab: this.salesSupportSummaryTab };
    this.router.navigate(['/salesjourney/sales-support-summary', queryParams]);
  }

  onBackTBranchLebvelClick() {
    const queryParams = { productType: this.productType };
    this.router.navigate(['/salesjourney/app-paper-summary', queryParams]);
  }

  onBackSuperAdmin() {
    const queryParams = { productType: this.productType };
    this.router.navigate(['/salesjourney/super-admin', queryParams]);
  }

  onBackIffDeclinedDashboard() {
    this.router.navigate(['/salesjourney/iff-declined-dashboard']);
  }

  backToTop() {
    document.getElementsByClassName('cds-navigation-content')[0].scrollTo(0, 0);
  }

  getEmpfDetails(caseDetailRequest: CaseDetailRequest) {
    this.caseDetailsService.getEmpfDetails(caseDetailRequest);
  }

  getTransferDetails(caseDetailRequest: CaseDetailRequest) {
    this.caseDetailsService.getTransferDetails(caseDetailRequest);
  }

  getAgentName(agent?: AgentsInfoRsp) {
    if (!agent) {
      return 'N/A';
    }
    if (this.cdsLangService.currentLang === 'en') {
      return agent.agentName || agent.agentNameChn;
    } else {
      return agent.agentNameChn || agent.agentName;
    }
  }

  openResendEIFF() {
    const ref = this.cdsPopup.open(ResendEiffComponent, {
      size: 'md',
      data: {
        salesJourneySummariesId: this.salesJourneySummariesId,
        resendType: ResendEiffResendType.EE,
        iffStatus: this.caseDetails?.iffStatusEnum,
        empfStatus: this.caseDetails?.empfStatusEnum,
      },
    });
    ref.afterClosed().subscribe(({ isRefresh }) => {
      if (isRefresh) {
        location.reload();
      }
      cdsPopupBackdropUnOntouchmovePreventDefault();
    });

    ref.afterOpened().subscribe(() => {
      cdsPopupBackdropOntouchmovePreventDefault();
    });
  }

  openSendEIFF() {
    this.router.navigate(['/salesjourney/send-iff-in-batch-summary', { empfRefNo: this.caseDetails?.empfRefNo }]);
  }

  get tipText() {
    if (this.caseDetails?.iffStatusEnum === 10 || this.caseDetails?.iffDeclineExpireReasons?.some(item => item.code === 92)) {
      let a = 'Pending the following split agent submission : ';
      const tempList = this.splitAgentInfos.filter(item => [1, 20].includes(item.iffAgentStatusEnum));
      tempList.forEach((agent, index) => {
        a += `${agent.agentCd} ${this.getAgentName(agent)}`;
        if (index < tempList.length - 1) {
          a += ', ';
        }
      });

      return a;
    }
    return undefined;
  }

  isOfDeclined(code: number) {
    return [1, 2, 3, 4, 5, 6, 8].includes(code);
  }

  isOfExpired(code: number) {
    return [91, 92, 93].includes(code);
  }

  checkSendS2() {
    if (this.salesJourneySummariesId) {
      const params = { summaryId: this.salesJourneySummariesId };
      this.notifyService.screenLoadingSubject.next(true);
      this.cdHttpServeService
        .get<{
          send: boolean;
        }>('/ext/eb-ssms-sales-journey-service/salesJourney/info', params)
        .subscribe({
          next: res => {
            if (res.result !== 0) {
              this.alert.warning('Warning!', `${res.message}`);
            } else {
              this.isSendS2 = res.data.send;
            }
            this.notifyService.screenLoadingSubject.next(false);
          },
          error: err => {
            this.alert.error('Error!', err);
            this.notifyService.screenLoadingSubject.next(false);
          },
        });
    }
  }
}
