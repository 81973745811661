<cds-popup>
  <cds-popup-title>
    <div class="cds-h4-demibold title">
      <span>{{ ContributionAmountTypeE[data.type] + ' - ' }} </span>
      <span>{{ ContributionDetailsCategoryE[data.category] | lang }}</span>
    </div>
    <div class="cds-h5-demibold">
      <span class="cds-h5-demibold">{{ 'account.contribution-period' | lang }}</span>
      <span class="cds-h5-demibold">{{ data.contributionDetails.contributionPeriodStartDate | ssmsDate }}</span>
      <span class="cds-h5-demibold"> - </span>
      <span class="cds-h5-demibold">{{ data.contributionDetails.contributionPeriodEndDate | ssmsDate }}</span>
    </div>
  </cds-popup-title>

  <cds-popup-content>
    <div class="cds-body2 l-mb-5">
      Total {{ ContributionDetailsCategoryE[data.category] | lang }} (ER level)
      <span class="cds-body1 l-ml-6">${{ data.amount | number }}</span>
    </div>

    <div class="table l-mt-4 l-mb-6">
      <app-data-table
        [frontPagination]="false"
        [isLoading]="isLoading"
        [fixed]="false"
        [overlay]="true"
        [pageConfig]="pageConfig"
        [dataSource]="dataSource"
        [columnsConfig]="columnsConfig"
        (reloadTable)="reloadTable($event)">
        <ng-template appDtHeaderCellDef="amount" let-element>
          <div>
            <span>{{ ContributionAmountTypeE[data.type] + ' - ' }} </span>
            <span>{{ ContributionDetailsCategoryE[data.category] | lang }}</span>
          </div>
        </ng-template>
        <ng-template appDtBodyCellDef="memberName" let-element>
          <span>
            <span class="l-mr-2">{{ element.lastName }}</span>
            <span>{{ element.firstName }}</span>
            <span *ngIf="element.chineseLastName || element.chineseFirstName">
              (<span>{{ element.chineseLastName }}</span
              ><span>{{ element.chineseFirstName }}</span
              >)
            </span>
          </span>
        </ng-template>
        <ng-template appDtBodyCellDef="amount" let-element>
          <div>
            {{ element.amount | number }}
          </div>
        </ng-template>

        <ng-template appDtBodyCellDef="contributionStatus" let-element>
          <div>
            {{ service.optionLabelRender | fn : element.contributionStatus : contributionStatusOptions }}
          </div>
        </ng-template>

        <ng-template appDtBodyCellDef="rectifiedType" let-element>
          <div>
            {{ service.optionLabelRender | fn : element.rectifiedType : rectifiedTypeOptions }}
          </div>
        </ng-template>
      </app-data-table>
    </div>
  </cds-popup-content>

  <cds-popup-actions> </cds-popup-actions>
</cds-popup>
