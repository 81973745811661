<div class="l-pa-7 l-pt-5">
  <div class="flex-column">
    <div class="flex-row info-out-area">
      <div class="title-img">
        <cds-icon size="md" color="#00A758" icon="people:login_1"></cds-icon>
      </div>
      <div class="flex-column info-area">
        <div class="flex-column item-area">
          <div class="cds-h4-demibold item-title no-margin">{{ this.detailData.engSurname }}{{ this.detailData.engGivenName }}</div>
          <div class="item-value cds-body1 no-margin">{{ this.detailData.chiSurname }}{{ this.detailData.chiGivenName }}</div>
        </div>
        <div class="flex-row dot-area">
          <div class="dot-icon"></div>
          <div class="des-text cds-body1 no-margin margin-left-common">
            {{ 'ee.opt.active' | lang }}
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="common-title cds-body2-demibold title-color margin-top-common">Choose an policy</div>
  <div class="select-area margin-top-common">
    <cds-dropdown [config]="dropdownConfig" (change)="optionChange($event)" [(ngModel)]="default_group_no"></cds-dropdown>
  </div>
  <div class="flex-row white-area margin-top-common">
    <div class="add-icon margin-left-common">
      <cds-icon size="sm" icon="form:document"></cds-icon>
    </div>
    <div class="cds-h5-demibold des-text no-margin margin-left-common">Basic information</div>
  </div>

  <div class="flex-row white-area white-margin-top-common">
    <div class="flex-column white-content">
      <div class="flex-row row no-padding margin-top-common">
        <div class="flex-column content-item col-sm-3 no-padding">
          <div class="cds-body2 no-margin title-color no-padding">
            {{ 'ee.pfofile.trustee-member-id' | lang }}
          </div>
          <div class="cds-body1 no-margin">
            {{ this.optionBasicInfo.trusteeId }}
          </div>
        </div>
        <div class="flex-column content-item col-sm-3 no-padding">
          <div class="cds-body2 no-margin title-color no-padding">
            {{ 'ee.pfofile.group_no' | lang }}
          </div>
          <div class="cds-body1 no-margin">
            {{ this.optionBasicInfo.groupNo }}
          </div>
        </div>
        <div class="flex-column content-item col-sm-3 no-padding">
          <div class="cds-body2 no-margin title-color no-padding">
            {{ 'ee.pfofile.cert_no' | lang }}
          </div>
          <div class="cds-body1 no-margin">
            {{ this.optionBasicInfo.certNo }}
          </div>
        </div>
        <div class="flex-column content-item col-sm-3 no-padding">
          <div class="cds-body2 no-margin title-color no-padding">{{ 'ee.pfofile.Status-Change-Date' | lang }} (DD/MM/YYYY)</div>
          <div class="cds-body1 no-margin">
            {{ this.optionBasicInfo.statusChangeDate }}
          </div>
        </div>
      </div>
      <!-- 2th -->
      <div class="flex-row row no-padding margin-top-common">
        <div class="flex-column content-item col-sm-3 no-padding">
          <div class="cds-body2 no-margin title-color no-padding">
            {{ 'ee.opt.Currency' | lang }}
          </div>
          <div class="cds-body1 no-margin">
            {{ this.optionBasicInfo.currency }}
          </div>
        </div>
        <div class="flex-column content-item col-sm-3 no-padding">
          <div class="cds-body2 no-margin title-color no-padding">
            {{ 'ee.pfofile.account_type' | lang }}
          </div>
          <div class="cds-body1 no-margin">
            {{ this.optionBasicInfo.accountType }}
          </div>
        </div>
        <div class="flex-column content-item col-sm-3 no-padding">
          <div class="cds-body1 no-margin">
            {{ 'ee.pfofile.default_pa' | lang }}
          </div>
          <cds-checkbox [checked]="optionBasicInfo.defaultPa === 'Y' ? true : false" [disabled]="true"> </cds-checkbox>
        </div>
        <div class="flex-column content-item col-sm-3 no-padding">
          <div class="cds-body1 no-margin">
            {{ 'ee.pfofile.ab_offer' | lang }}
          </div>
          <cds-checkbox [checked]="optionBasicInfo.abOffer === 'Y' ? true : false" [disabled]="true"> </cds-checkbox>
        </div>
      </div>
      <!-- 3th -->
      <div class="flex-row row no-padding margin-top-common">
        <div class="flex-column content-item col-sm-3 no-padding">
          <div class="cds-body2 no-margin title-color no-padding">
            {{ 'ee.pfofile.LS-Flag' | lang }}
          </div>
          <div class="cds-body1 no-margin">
            {{ this.optionBasicInfo.lsFlag }}
          </div>
        </div>
        <div class="flex-column content-item col-sm-3 no-padding">
          <div class="cds-body2 no-margin title-color no-padding">
            {{ 'ee.pfofile.scheme_type' | lang }}
          </div>
          <div class="cds-body1 no-margin">
            {{ this.optionBasicInfo.schemeType }}
          </div>
        </div>
        <div class="flex-column content-item col-sm-3 no-padding">
          <div class="cds-body2 no-margin title-color no-padding">
            {{ 'ee.profile.PAC' | lang }}
          </div>
          <div class="cds-body1 no-margin">
            {{ this.optionBasicInfo.pacGroup_certNumber }}
          </div>
        </div>
        <div class="flex-column content-item col-sm-3 no-padding">
          <div class="cds-body2 no-margin title-color no-padding">
            {{ 'ee.profile.Employee-Type' | lang }}
          </div>
          <div class="cds-body1 no-margin">
            {{ this.optionBasicInfo.employeeType }}
          </div>
        </div>
      </div>
      <!-- 3th end -->
    </div>
  </div>
  <!-- 2th rotate white area -->
  <div class="flex-row white-area margin-top-common click-area" (click)="showContact()">
    <div class="rotate-icon margin-left-common backrotate" #conRotateIcon>
      <cds-icon class="icon-color" color="#ff6666" size="sm" [icon]="contactIcon"></cds-icon>
    </div>
    <div class="cds-h5-demibold des-text no-margin margin-left-common">
      {{ 'ee.profile.Contact' | lang }}
    </div>
  </div>
  <!-- rotate top area end -->
  <div *ngIf="isShowContact" class="flex-row white-area white-margin-top-common">
    <div class="flex-column white-content">
      <!-- icon-area -->
      <div class="flex-row margin-top-common">
        <div class="add-icon">
          <cds-icon size="sm" icon="contact:phone"></cds-icon>
        </div>
        <div class="cds-body1-demibold des-text no-margin margin-left-common">
          {{ 'ee.profile.Direct-contact' | lang }}
        </div>
      </div>
      <!-- end-icon-area -->
      <div class="flex-row row no-padding margin-top-common">
        <div class="flex-column content-item col-sm-3 no-padding">
          <div class="cds-body2 no-margin title-color no-padding">
            {{ 'ee.profile.primary-member-mobile' | lang }}
          </div>
          <div class="cds-body1 no-margin">
            {{ this.contactInfo.primaryMemberMobile }}
          </div>
        </div>
        <div class="flex-column content-item col-sm-3 no-padding">
          <div class="cds-body2 no-margin title-color no-padding">
            {{ 'ee.profile.Secondary-Member-Mobile' | lang }}
          </div>
          <div class="cds-body1 no-margin">
            {{ this.contactInfo.secondaryMemberMobile }}
          </div>
        </div>
        <div class="flex-column content-item col-sm-3 no-padding">
          <div class="cds-body2 no-margin title-color no-padding">
            {{ 'ee.profile.Primary-Member-Residential' | lang }}
          </div>
          <div class="cds-body1 no-margin">
            {{ this.contactInfo.primaryMemberResidential }}
          </div>
        </div>
        <div class="flex-column content-item col-sm-3 no-padding">
          <div class="cds-body2 no-margin title-color no-padding">
            {{ 'ee.profile.Secondary-Member-Residential' | lang }}
          </div>
          <div class="cds-body1 no-margin">
            {{ this.contactInfo.secondaryMemberResidential }}
          </div>
        </div>
      </div>
      <!-- 2th -->
      <div class="flex-row row no-padding margin-top-common">
        <div class="flex-column content-item col-sm-3 no-padding">
          <div class="cds-body2 no-margin title-color no-padding">
            {{ 'ee.profile.Member-Fax' | lang }}
          </div>
          <div class="cds-body1 no-margin">
            {{ this.contactInfo.memberFax }}
          </div>
        </div>
        <div class="flex-column content-item col-sm-3 no-padding">
          <div class="cds-body2 no-margin title-color no-padding">
            {{ 'ee.profile.Member-Email' | lang }}
          </div>
          <div class="cds-body1 no-margin">
            {{ this.contactInfo.memberEmail }}
          </div>
        </div>
      </div>
      <!-- 3th address table-->
      <div class="base-line margin-top-common grey-line"></div>
      <div class="flex-row row no-padding div-table-title-area">
        <div class="flex-row col-sm-6 no-padding">
          <div class="table-icon">
            <cds-icon size="sm" icon="contact:location"></cds-icon>
          </div>
          <div class="cds-body1-demibold des-text no-margin margin-left-common">
            {{ 'ee.profile.Direct-contact' | lang }}
          </div>
        </div>
        <div class="col-sm-3 cds-body1-demibold">
          {{ 'ee.profile.Type' | lang }}
        </div>
        <div class="col-sm-3 cds-body1-demibold">
          {{ 'ee.profile.Effective-date-of-address' | lang }}
        </div>
      </div>
      <div class="base-line grey-line"></div>
      <div *ngFor="let item of addressList; index as i">
        <div class="flex-row row no-padding div-table-content-area">
          <div class="cds-body1 flex-row col-sm-6 no-padding no-margin item-padding-left">
            {{ item.memberAddress }}
          </div>
          <div class="cds-body1 col-sm-3 no-margin">{{ item.addressType }}</div>
          <div class="cds-body1 col-sm-3 no-margin">
            {{ item.effectiveDateOfTheAddress }}
          </div>
        </div>
        <div class="base-line grey-line"></div>
      </div>
      <!-- 3th end -->
    </div>
  </div>
  <div class="tab-area margin-top-common">
    <cds-tab-group [type]="tabType">
      <cds-tab label="Agent Info"
        ><app-ee-profile-agent-info [trusteeId]="optionBasicInfo.trusteeId" [entityType]="optionBasicInfo.entityType"></app-ee-profile-agent-info
      ></cds-tab>

      <cds-tab label="Employment information">
        <app-ee-profile-employment-info> </app-ee-profile-employment-info>
      </cds-tab>

      <cds-tab label="Fund information"
        ><app-ee-profile-fund-info [trusteeId]="optionBasicInfo.trusteeId" [entityType]="optionBasicInfo.entityType"></app-ee-profile-fund-info
      ></cds-tab>

      <cds-tab label="Others"><app-ee-profile-others></app-ee-profile-others></cds-tab>
    </cds-tab-group>
  </div>
  <!-- tab-area -->
</div>
