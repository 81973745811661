import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { catchError, Observable, of, Subject, timeout } from 'rxjs';
import { agentinfoResponse } from './agentinfo-response';

@Injectable({
  providedIn: 'root',
})
export class AgentinfoService {
  searchAgentinfoUrl = `${environment.apiPrefix}/ext/eb-ssms/agent-service/agent/information`;
  agentinfoDataSubject = new Subject<agentinfoResponse>();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  erroragentinfoSubject = new Subject<any>();

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  constructor(private httpClient: HttpClient) {}
  GetagentinfoSummary(agentcode: string) {
    this.httpClient
      .get(this.searchAgentinfoUrl, { params: { agentInfo: agentcode } })
      .pipe(timeout(5000), catchError(this.handleError<agentinfoResponse>('getAgentinfo', new agentinfoResponse())))
      .subscribe(resp => {
        this.agentinfoDataSubject.next(resp);
      });
  }
  handleError<T>(operation = 'operation', result?: T) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (error: any): Observable<T> => {
      console.info(`${operation} failed :${error.message}`);
      this.erroragentinfoSubject.next(`${error.message}`);
      return of(result as T);
    };
  }
}
