import { CdsPillModule } from '@cds/ng-web-components/pill';
import { CdsDatepickerModule } from '@cds/ng-web-components/datepicker';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { EmployeeRoutingModule } from './employee-routing.module';
import { EeProfileDetailComponent } from './ee-profile-detail/ee-profile-detail.component';

import { EeProfileListComponent } from './ee-profile-list/ee-profile-list.component';
import { CdsButtonModule } from '@cds/ng-web-components/button';
import { CdsIconModule } from '@cds/ng-web-components/icon';
import { CdsPaginationModule } from '@cds/ng-web-components/pagination';
import { CdsTextfieldModule } from '@cds/ng-web-components/textfield';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CdsPopupModule } from '@cds/ng-web-components/popup';
import { HttpClientModule } from '@angular/common/http';
import { CdsProgressModule } from '@cds/ng-web-components/progress';
import { CdsLanguageModule } from '@cds/ng-core/lang';
import { CdsTableModule } from '@cds/ng-data-table/table';
import { CdsSortModule } from '@cds/ng-data-table/sort';
import { CdsCheckboxModule } from '@cds/ng-web-components/checkbox';
import { CdsAccordionModule } from '@cds/ng-web-components/accordion';
import { EeProfileDetailPlusComponent } from './ee-profile-detail-plus/ee-profile-detail-plus.component';
import { CdsDropdownModule } from '@cds/ng-web-components/dropdown';
import { CdsTabsModule } from '@cds/ng-web-components/tabs';
import { EeProfileAgentInfoComponent } from './ee-profile-agent-info/ee-profile-agent-info.component';
import { EeProfileFundInfoComponent } from './ee-profile-fund-info/ee-profile-fund-info.component';
import { EeProfileOthersComponent } from './ee-profile-others/ee-profile-others.component';
import { CdAccordionModule } from 'src/app/components/accordion/cd-accordion.module';
import { EeProfileEmploymentInfoComponent } from './ee-profile-employment-info/ee-profile-employment-info.component';
import { RowItemModule } from 'src/app/components/row-item/row-item.module';
import { AppPaginationModule } from 'src/app/shared/pagination/pagination.module';
import { EmployeeListComponent } from './employee-list/employee-list.component';
import { CdsAssistiveTextModule } from '@cds/ng-web-components/assistive-text';
import { SharedModule } from 'src/app/shared/shared.module';
import { CampaignInformationComponent } from './campaign-information/campaign-information.component';
import { RelatedPolicyComponent } from './related-policy/related-policy.component';
import { RelatedPolicyDetailComponent } from './related-policy-detail/related-policy-detail.component';
import { ContactComponent } from './contact-persion/contact-persion.component';
import { AddressTypeDefinitionPopupComponent } from './address-type-definition-popup/address-type-definition-popup.component';
import { PolicyDetailComponent } from './policy-detail/policy-detail.component';
import { AgentInfoComponent } from './agent-info/agent-info.component';
import { EmploymentInfoComponent } from './employment-info/employment-info.component';
import { ContributionRecordsComponent } from './contribution-records/contribution-records.component';
import { BillReferenceComponent } from './bill-reference/bill-reference.component';
import { AmountTableComponent } from './amount-table/amount-table.component';
import { EmployeeDefaultContributionComponent } from './employee-default-contribution/employee-default-contribution.component';
import { CdsRadioModule } from '@cds/ng-web-components/radio';
import { PolicyTransferComponent } from './policy-transfer/policy-transfer.component';
import { PolicyTransferDetailsComponent } from './policy-transfer-details/policy-transfer-details.component';
import { PolicyWithDrawalComponent } from './policy-withdrawal/policy-withdrawl.component';
import { PolicyWithdrawalDetailsComponent } from './policy-withdrawal-details/policy-withdrawal-details.component';
import { EmployerModule } from '../employer/employer.module';
import { PolicyTerminationComponent } from './policy-termination/policy-termination.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { EmployeeProfileTitleComponent } from './employee-profile-title/employee-profile-title.component';
import { EmpfWorkflowModule } from '../empf-workflow/empf-workflow.module';
import { FundInfoComponent } from './fund-info/fund-info.component';
import { paymentTransactionPopComponent } from './fund-info/payment-transaction-pop/payment-transaction-pop.component';
@NgModule({
  declarations: [
    EeProfileDetailComponent,
    EeProfileListComponent,
    EeProfileDetailPlusComponent,
    EeProfileAgentInfoComponent,
    EeProfileFundInfoComponent,
    EeProfileOthersComponent,
    EeProfileEmploymentInfoComponent,
    EmployeeListComponent,
    CampaignInformationComponent,
    RelatedPolicyComponent,
    RelatedPolicyDetailComponent,
    ContactComponent,
    AddressTypeDefinitionPopupComponent,
    PolicyDetailComponent,
    AgentInfoComponent,
    EmploymentInfoComponent,
    ContributionRecordsComponent,
    BillReferenceComponent,
    AmountTableComponent,
    EmployeeDefaultContributionComponent,
    PolicyTransferComponent,
    PolicyTransferDetailsComponent,
    PolicyWithDrawalComponent,
    PolicyWithdrawalDetailsComponent,
    PolicyTerminationComponent,
    EmployeeProfileTitleComponent,
    FundInfoComponent,
    paymentTransactionPopComponent,
  ],
  imports: [
    CommonModule,
    EmployeeRoutingModule,
    ReactiveFormsModule,
    CdsButtonModule,
    CdsIconModule,
    CdsPaginationModule,
    CdsTextfieldModule,
    FormsModule,
    CdsPopupModule,
    HttpClientModule,
    CdsProgressModule,
    CdsLanguageModule,
    CdsTableModule,
    CdsSortModule,
    CdsCheckboxModule,
    CdsAccordionModule,
    CdsDropdownModule,
    CdsTabsModule,
    CdAccordionModule,
    RowItemModule,
    CdsAssistiveTextModule,
    AppPaginationModule,
    SharedModule,
    CdsDatepickerModule,
    CdsPillModule,
    CdsRadioModule,
    EmployerModule,
    MatFormFieldModule,
    MatSelectModule,
    EmpfWorkflowModule,
  ],
})
export class EmployeeModule {}
