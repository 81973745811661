/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CdsButtonConfig } from '@cds/ng-core/button';
import { CdsLangService } from '@cds/ng-core/lang';
import { CdsTextfieldConfig } from '@cds/ng-core/textfield';
import { CdsPopupService, CDS_POPUP_DATA, MatDialogRef } from '@cds/ng-web-components/popup';
import { finalize } from 'rxjs';
import { AlertPopupComponent } from 'src/app/shared/alert-popup/alert-popup.component';
import { PermissionAccess, PermissionItem } from 'src/app/core/models/enum/permission.enum';
import { ContinuePopupComponent } from 'src/app/shared/continue-popup/continue-popup.component';
import { AgentAndAssignedGroup } from '../agent-list';
import { agentCodeListValidator } from 'src/app/shared/validators/validators';
import { AgentListService } from '../agent-list.service';
import { deepCopy } from 'src/app/utils/copy';

@Component({
  selector: 'app-agent-list-edit',
  templateUrl: './agent-list-edit.component.html',
  styleUrls: ['./agent-list-edit.component.scss'],
})
export class AgentListEditComponent implements OnInit {
  confirmButtonConfig: CdsButtonConfig = { size: 'sm' };

  saveButtonConfig: CdsButtonConfig = { size: 'sm' };

  resetButtonConfig: CdsButtonConfig = { size: 'sm', style: 'secondary' };

  backToEditButtonConfig: CdsButtonConfig = { size: 'sm', style: 'secondary' };

  originFormValue: AgentAndAssignedGroup = {};

  formSendValue: AgentAndAssignedGroup = {};

  PermissionAccess = PermissionAccess;

  PermissionItem = PermissionItem;

  agentTextfieldConfig: CdsTextfieldConfig = {
    label: 'Agent Code',
    placeholder: 'Input Agent Code',
    type: 'text',
  };

  accountNumbertxtfieldConfig: CdsTextfieldConfig = {
    label: 'Employer Account Code',
    placeholder: 'Input Employer Account Code',
    type: 'text',
  };

  companyNametxtfieldConfig: CdsTextfieldConfig = {
    label: 'Company Name',
    placeholder: 'Input Company Name',
    type: 'text',
  };

  form: FormGroup = new FormGroup({});

  isLoading = false;

  get saveDisabled() {
    return !(this.form.dirty && this.form.valid) || this.isLoading;
  }

  get resetDisabled() {
    return !this.form.dirty || this.isLoading;
  }

  get agent() {
    return this.form.get('agent');
  }

  get trusteeEmployerAccountNumber() {
    return this.form.get('trusteeEmployerAccountNumber');
  }

  get companyName() {
    return this.form.get('companyName');
  }

  get remarks() {
    return this.form.get('remarks');
  }

  constructor(
    private dialogRef: MatDialogRef<AgentListEditComponent>,
    private cdsPopup: CdsPopupService,
    private langService: CdsLangService,
    private agentListService: AgentListService,
    @Inject(CDS_POPUP_DATA)
    public data: {
      title: string;
      value: AgentAndAssignedGroup;
    }
  ) {}

  ngOnInit() {
    this.initData();
    this.initForm();
  }

  initData() {
    this.originFormValue = {
      agent: this.data.value.agent ? this.data.value.agent : '',
      companyName: this.data.value.companyName ? this.data.value.companyName : '',
      trusteeEmployerAccountNumber: this.data.value.trusteeEmployerAccountNumber ? this.data.value.trusteeEmployerAccountNumber : '',
      remarks: this.data.value.remarks ? this.data.value.remarks : '',
    };
  }

  initForm() {
    this.form = new FormGroup({
      agent: new FormControl('', [agentCodeListValidator(), Validators.required]),
      trusteeEmployerAccountNumber: new FormControl('', [Validators.maxLength(100), Validators.required]),
      companyName: new FormControl('', [Validators.maxLength(300), Validators.required]),
      remarks: new FormControl('', [Validators.maxLength(500)]),
    });

    this.restore();

    this.formChangeListener();
  }

  changeFormImmediately(key: string, value: string) {
    if (value && typeof value === 'string') {
      this.form.get(key)?.setValue(value.trim());
    }
  }

  formChangeListener() {
    this.form.valueChanges.subscribe(() => {
      this.getSendFormValue();
    });
  }

  getSendFormValue() {
    const value: AgentAndAssignedGroup = {};
    Object.keys(this.originFormValue).forEach(key => {
      const currentFromValue: AgentAndAssignedGroup = this.form.value;
      if ((currentFromValue as any)[key] !== (this.originFormValue as any)[key]) {
        (value as any)[key] = (currentFromValue as any)[key];
      }
    });
    this.formSendValue = value;
  }

  restore() {
    this.form.setValue(this.originFormValue);
    this.form.markAsPristine();
    this.form.markAsUntouched();
  }

  save() {
    if (this.saveDisabled) {
      return;
    }
    const agentAndAssignedGroup: AgentAndAssignedGroup = deepCopy(this.form.value);
    const split = agentAndAssignedGroup.agent?.split(',');
    agentAndAssignedGroup.agentList = split;
    agentAndAssignedGroup.id = this.data.value.id;
    this.isLoading = true;
    this.agentListService
      .edit(agentAndAssignedGroup)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe({
        next: res => {
          if (res.result === 0) {
            this.popupWithReload();
          } else {
            this.requestAlert(res.message);
          }
        },
        error: err => {
          this.requestAlert(err.message);
        },
      });
  }

  reset() {
    if (this.resetDisabled) {
      return;
    }
    this.cdsPopup
      .open(ContinuePopupComponent, {
        size: 'sm',
        data: { message: this.langService.translate('common.action.cancel.italic') },
      })
      .afterClosed()
      .subscribe(result => {
        if (result?.agree) {
          this.restore();
        }
      });
  }

  delete() {
    this.cdsPopup
      .open(ContinuePopupComponent, {
        size: 'sm',
        data: {
          message: this.langService.translate('agent.delete-tip'),
          continueButtonName: this.langService.translate('common.delete'),
        },
      })
      .afterClosed()
      .subscribe(result => {
        if (result?.agree) {
          this.isLoading = true;
          this.agentListService
            .delete(this.data.value.id as string)
            .pipe(
              finalize(() => {
                this.isLoading = false;
              })
            )
            .subscribe({
              next: res => {
                if (res.result === 0) {
                  this.popupWithReload();
                } else {
                  this.requestAlert(res.message);
                }
              },
              error: err => {
                this.requestAlert(err.message);
              },
            });
        }
      });
  }

  close() {
    if (!this.resetDisabled) {
      this.cdsPopup
        .open(ContinuePopupComponent, {
          size: 'sm',
          data: { message: this.langService.translate('common.action.cancel.italic') },
        })
        .afterClosed()
        .subscribe(result => {
          if (result?.agree) {
            this.dialogRef.close();
          }
        });
    } else {
      this.dialogRef.close();
    }
  }

  requestAlert(message: string) {
    this.cdsPopup
      .open(AlertPopupComponent, {
        size: 'sm',
        data: {
          title: 'Error',
          message: `<span class='cds-h4-light'>${message}</span>`,
          buttonName: this.langService.translate('confirm'),
        },
      })
      .afterClosed()
      .subscribe(() => {});
  }

  popupWithReload() {
    this.cdsPopup
      .open(AlertPopupComponent, {
        size: 'sm',
        data: {
          message: this.langService.translate('common.action.success.italic'),
          buttonName: this.langService.translate('common.gotItWithExclamation'),
        },
      })
      .afterClosed()
      .subscribe(() => {
        this.dialogRef.close({
          agree: true,
          reload: true,
        });
      });
  }
}
