<div class="super-admin">
  <div class="head-box">
    <span class="head">eIFF Summary</span>
  </div>

  <div class="content-box">
    <div>
      <span class="cds-body2">Choose product type</span>
    </div>

    <div class="l-mt-4">
      <div class="choose-product-type-dropdown">
        <app-cd-dropdown
          [placeholder]="'Select Product Type'"
          [selected]="prodTypeFormControl.value"
          (selectedChange)="prodTypeChange($event)"
          [toppingList]="prodTypeDropdownConfig.options"></app-cd-dropdown>
      </div>
    </div>

    <div class="l-mt-7">
      <span class="cds-h2-light">Search record</span>
    </div>

    <div class="row l-d-f padding-none" *ngIf="requestParams">
      <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12 search-item">
        <span class="cds-body2" style="color: var(--cds-text-disabled)">eMPF Reference #</span>
        <app-cd-input class="text-field" [(value)]="requestParams.empfRefNo" placeholder="Input eMPF Reference #"></app-cd-input>
      </div>

      <ng-container *ngIf="isERReqeuestParams(requestParams)">
        <div *ngIf="prodTypeFormControl.value === SalesJourneyProdType.Corperate" class="col-lg-3 col-md-6 col-sm-12 col-xs-12 search-item">
          <span class="cds-body2" style="color: var(--cds-text-disabled)">BR Number</span>
          <app-cd-input class="text-field" [(value)]="requestParams.brNo" placeholder="Input BR Number"></app-cd-input>
        </div>

        <div *ngIf="prodTypeFormControl.value === SalesJourneyProdType.Corperate" class="col-lg-3 col-md-6 col-sm-12 col-xs-12 search-item">
          <span class="cds-body2" style="color: var(--cds-text-disabled)">Employer Account Code</span>
          <app-cd-input class="text-field" [(value)]="requestParams.employerAccountCd" placeholder="Input Employer Account Code"></app-cd-input>
        </div>

        <div *ngIf="prodTypeFormControl.value === SalesJourneyProdType.Corperate" class="col-lg-3 col-md-6 col-sm-12 col-xs-12 search-item">
          <span class="cds-body2" style="color: var(--cds-text-disabled)">Employer Name</span>
          <app-cd-input class="text-field" [(value)]="requestParams.employerName" placeholder="Input Employer Name"></app-cd-input>
        </div>
      </ng-container>

      <ng-container *ngIf="!isERReqeuestParams(requestParams)">
        <div *ngIf="prodTypeFormControl.value !== SalesJourneyProdType.Corperate" class="col-lg-3 col-md-6 col-sm-12 col-xs-12 search-item">
          <span class="cds-body2" style="color: var(--cds-text-disabled)">HKID/ Passport No.</span>
          <app-cd-input class="text-field" [(value)]="requestParams.hkidOrPassportNo" placeholder="Input HKID/ Passport No."></app-cd-input>
        </div>

        <div *ngIf="prodTypeFormControl.value !== SalesJourneyProdType.Corperate" class="col-lg-3 col-md-6 col-sm-12 col-xs-12 search-item">
          <span class="cds-body2" style="color: var(--cds-text-disabled)">Member Account Code</span>
          <app-cd-input class="text-field" [(value)]="requestParams.memberAccountCd" placeholder="Input Member Account Code"></app-cd-input>
        </div>
      </ng-container>

      <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12 search-item">
        <span class="cds-body2" style="color: var(--cds-text-disabled)">Agent Code</span>
        <app-cd-input class="text-field" [(value)]="requestParams.agentCd" placeholder="Input Agent Code"></app-cd-input>
      </div>

      <ng-container *ngIf="!isERReqeuestParams(requestParams)">
        <div *ngIf="prodTypeFormControl.value !== SalesJourneyProdType.Corperate" class="col-lg-3 col-md-6 col-sm-12 col-xs-12 search-item">
          <span class="cds-body2" style="color: var(--cds-text-disabled)">Client’s Phone Number</span>
          <app-cd-input class="text-field" [(value)]="requestParams.clientPhoneNo" placeholder="Input Client’s Phone Number"></app-cd-input>
        </div>

        <div *ngIf="prodTypeFormControl.value !== SalesJourneyProdType.Corperate" class="col-lg-3 col-md-6 col-sm-12 col-xs-12 search-item">
          <span class="cds-body2" style="color: var(--cds-text-disabled)">Client’s Email address</span>
          <app-cd-input class="text-field" [(value)]="requestParams.clientEmailAddr" placeholder="Input Client’s Email address"></app-cd-input>
        </div>
      </ng-container>

      <ng-container *ngIf="isERReqeuestParams(requestParams)">
        <div *ngIf="prodTypeFormControl.value === SalesJourneyProdType.Corperate" class="col-lg-3 col-md-6 col-sm-12 col-xs-12 search-item">
          <span class="cds-body2" style="color: var(--cds-text-disabled)">Authorized Person’s Phone Number</span>
          <app-cd-input class="text-field" [(value)]="requestParams.apPhoneNo" placeholder="Input Phone Number"></app-cd-input>
        </div>

        <div *ngIf="prodTypeFormControl.value === SalesJourneyProdType.Corperate" class="col-lg-3 col-md-6 col-sm-12 col-xs-12 search-item">
          <span class="cds-body2" style="color: var(--cds-text-disabled)">Authorized Person’s Email address</span>
          <app-cd-input class="text-field" [(value)]="requestParams.apEmailAddr" placeholder="Input Email Address"></app-cd-input>
        </div>
      </ng-container>

      <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12 search-item bottom-box">
        <cds-button [disabled]="isDisableSearch || isLoading" size="sm" class="l-mr-4" (click)="onClickSearch()">Search</cds-button>
        <cds-button [style]="'secondary'" size="sm" (click)="onClickReset()">Reset</cds-button>
      </div>
    </div>

    <div style="margin-top: 3.5rem">
      <span class="cds-h2-light">Record list</span>
    </div>

    <div class="l-mt-2">
      <span class="cds-body1">Total {{ totalElements | number }} records</span>
    </div>

    <div class="l-mt-5">
      <span class="cds-body2" style="color: var(--cds-text-disabled)">*The bold and starred code represents Servicing Agent</span>
    </div>

    <div class="l-mt-5">
      <div *ngIf="isLoading && currentPageData.length === 0" class="progress-box l-d-f l-jc-cen">
        <cds-progress mode="indeterminate" [diameter]="60" [type]="'ring'" [text]="'Loading'" [color]="'ml'"></cds-progress>
      </div>
      <app-no-result-found msg="No matching record found" [style.display]="currentPageData.length === 0 && !isLoading ? 'block' : 'none'"></app-no-result-found>
      <div [style.display]="currentPageData.length > 0 ? 'unset' : 'none'" class="row l-plr-0">
        <div #tableBox class="table-wrapper" [ngClass]="[isLoading ? 'loading' : '']">
          <cds-table *ngIf="!isERReqeuestParams(requestParams)" class="col-lg-12 row l-plr-0" [dataSource]="eeCurrentPageData" [class.full]="false">
            <cds-row *cdsRowDef="let row; index as i; columns: eeDisplayedColumns"></cds-row>
            <cds-header-row *cdsHeaderRowDef="eeDisplayedColumns"></cds-header-row>
            <ng-container cdsColumnDef="empfRefNo" sticky>
              <cds-header-cell *cdsHeaderCellDef class="e-width" [ngClass]="[checkIsHaveScroll ? 'shadow-right' : '']">
                <div class="prodtype-displaytooltip">
                  <span>eMPF Reference #</span>
                  <cds-icon class="note" (click)="onDisplayAdviseRef()" [icon]="infoIcon" [config]="iconConfig"></cds-icon>
                </div>
              </cds-header-cell>
              <cds-cell *cdsCellDef="let element" class="e-width" [ngClass]="[checkIsHaveScroll ? 'shadow-right' : '']">
                <app-cd-fake-link style="word-break: break-all" (callBack)="goCaseDetails(element)" [text]="element.empfRefNo"></app-cd-fake-link>
              </cds-cell>
            </ng-container>

            <ng-container cdsColumnDef="agentCd">
              <cds-header-cell *cdsHeaderCellDef class="agent-code-width">Agent Code</cds-header-cell>
              <cds-cell *cdsCellDef="let element" class="agent-code-width">
                <div *ngFor="let item of element.agentCds; let i = index">
                  <span
                    [ngClass]="{
                      'service-agent': i === 0
                    }">
                    {{ item }}{{ i === 0 ? '*' : '' }}
                  </span>
                </div>
              </cds-cell>
            </ng-container>

            <ng-container cdsColumnDef="productType">
              <cds-header-cell *cdsHeaderCellDef class="product-type-width">Product Type</cds-header-cell>
              <cds-cell *cdsCellDef="let element" class="product-type-width">
                {{ element.productType }}
              </cds-cell>
            </ng-container>

            <ng-container cdsColumnDef="hkidOrPassportNo">
              <cds-header-cell *cdsHeaderCellDef class="hkid-or-passport-no-width">Client HKID/ Passport No.</cds-header-cell>
              <cds-cell *cdsCellDef="let element" class="hkid-or-passport-no-width">
                {{ element.hkidOrPassportNo }}
              </cds-cell>
            </ng-container>

            <ng-container cdsColumnDef="memberAccountCd">
              <cds-header-cell *cdsHeaderCellDef class="member-account-code-width">Member Account Code</cds-header-cell>
              <cds-cell *cdsCellDef="let element" class="member-account-code-width">
                {{ element.memberAccountCd }}
              </cds-cell>
            </ng-container>

            <ng-container cdsColumnDef="clientPhoneNo">
              <cds-header-cell *cdsHeaderCellDef class="client-phone-no-width">Client's phone No.</cds-header-cell>
              <cds-cell *cdsCellDef="let element" class="client-phone-no-width">
                {{ element.clientPhoneNo }}
              </cds-cell>
            </ng-container>

            <ng-container cdsColumnDef="clientEmailAddr">
              <cds-header-cell *cdsHeaderCellDef class="client-email-addr-width">Client's email address</cds-header-cell>
              <cds-cell *cdsCellDef="let element" class="client-email-addr-width">
                {{ element.clientEmailAddr }}
              </cds-cell>
            </ng-container>
          </cds-table>

          <cds-table *ngIf="isERReqeuestParams(requestParams)" class="col-lg-12 row l-plr-0" [dataSource]="erCurrentPageData" [class.full]="false">
            <cds-row *cdsRowDef="let row; index as i; columns: erDisplayedColumns"></cds-row>
            <cds-header-row *cdsHeaderRowDef="erDisplayedColumns"></cds-header-row>
            <ng-container cdsColumnDef="empfRefNo" sticky>
              <cds-header-cell *cdsHeaderCellDef class="e-width" [ngClass]="[checkIsHaveScroll ? 'shadow-right' : '']">
                <div class="prodtype-displaytooltip">
                  <span>eMPF Reference #</span>
                  <cds-icon class="note" (click)="onDisplayAdviseRef()" [icon]="infoIcon" [config]="iconConfig"></cds-icon>
                </div>
              </cds-header-cell>
              <cds-cell *cdsCellDef="let element" class="e-width" [ngClass]="[checkIsHaveScroll ? 'shadow-right' : '']">
                <app-cd-fake-link style="word-break: break-all" (callBack)="goCaseDetails(element)" [text]="element.empfRefNo"></app-cd-fake-link>
              </cds-cell>
            </ng-container>

            <ng-container cdsColumnDef="agentCd">
              <cds-header-cell *cdsHeaderCellDef class="agent-code-width">Agent Code</cds-header-cell>
              <cds-cell *cdsCellDef="let element" class="agent-code-width">
                <div *ngFor="let item of element.agentCds; let i = index">
                  <span
                    [ngClass]="{
                      'service-agent': i === 0
                    }">
                    {{ item }}{{ i === 0 ? '*' : '' }}
                  </span>
                </div>
              </cds-cell>
            </ng-container>

            <ng-container cdsColumnDef="productType">
              <cds-header-cell *cdsHeaderCellDef class="product-type-width">Product Type</cds-header-cell>
              <cds-cell *cdsCellDef="let element" class="product-type-width">
                {{ element.productType }}
              </cds-cell>
            </ng-container>

            <ng-container cdsColumnDef="employerName">
              <cds-header-cell *cdsHeaderCellDef class="employer-name-width">Employer Name</cds-header-cell>
              <cds-cell *cdsCellDef="let element" class="employer-name-width">
                {{ element.employerName }}
              </cds-cell>
            </ng-container>

            <ng-container cdsColumnDef="brNo">
              <cds-header-cell *cdsHeaderCellDef class="br-no-width">BR Number</cds-header-cell>
              <cds-cell *cdsCellDef="let element" class="br-no-width">
                {{ element.brNo }}
              </cds-cell>
            </ng-container>

            <ng-container cdsColumnDef="employerAccountCd">
              <cds-header-cell *cdsHeaderCellDef class="employer-account-ode-width">Employer Account Code</cds-header-cell>
              <cds-cell *cdsCellDef="let element" class="employer-account-ode-width">
                {{ element.employerAccountCd }}
              </cds-cell>
            </ng-container>

            <ng-container cdsColumnDef="apPhoneNo">
              <cds-header-cell *cdsHeaderCellDef class="authorizedphone-no-width">Authorized Person’s Phone Number</cds-header-cell>
              <cds-cell *cdsCellDef="let element" class="authorizedphone-no-width">
                {{ element.apPhoneNo }}
              </cds-cell>
            </ng-container>

            <ng-container cdsColumnDef="apEmailAddr">
              <cds-header-cell *cdsHeaderCellDef class="authorized-email-address-width">Authorized Person’s Email address</cds-header-cell>
              <cds-cell *cdsCellDef="let element" class="authorized-email-address-width">
                {{ element.apEmailAddr }}
              </cds-cell>
            </ng-container>
          </cds-table>
          <div *ngIf="isLoading" class="progress-box">
            <cds-progress mode="indeterminate" [diameter]="60" [type]="'ring'" [text]="'Loading'" [color]="'ml'"></cds-progress>
          </div>
        </div>
        <div class="col-sm-12 l-mt-4">
          <app-pagination
            [pageCount]="{
              current: currentPageNumOrigin,
              count: totalPages
            }"
            (pageChange)="pageChange($event)">
          </app-pagination>
        </div>
      </div>
    </div>
  </div>
</div>
