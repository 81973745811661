<div class="row relative">
  <div class="col-xs-12 l-mt-7 l-mb-2">
    <span class="cds-body1"
      >Record the activities (including Employer, Member and Special Program) organized to Employer so that we can calculate the relationship score of the
      Employer, monitor the participation rate and encourage ER to participate.
    </span>
  </div>

  <div>
    <div class="col-xs-12 l-pt-7">
      <span class="cds-h2-light">{{ 'list.serach-record' | lang }}</span>
    </div>
    <div class="row l-ai-cen no-padding">
      <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 l-pt-4 l-pb-4">
        <cds-textfield
          [(ngModel)]="searchDTO.activityCode"
          #activityCode="ngModel"
          maxlength="6"
          (input)="searchChange($event, 'activityCode')"
          label="Activity Code"
          placeholder="Input Acitivity Code"></cds-textfield>

        <div *ngIf="activityCode.invalid && activityCode.dirty" class="l-pt-1">
          <cds-assistive-text *ngIf="activityCode?.errors?.['maxlength']" class="l-pt-2" [showIcon]="false">Max length is 6</cds-assistive-text>
        </div>
      </div>

      <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 l-pt-4 l-pb-4">
        <cds-textfield
          [(ngModel)]="searchDTO.description"
          #description="ngModel"
          maxlength="50"
          [appDescriptionValidator]="I18N_KEY.INVALID_DESCRIPTION_CHINESE"
          [appDescriptionValidatorSupportChinese]="true"
          (input)="searchChange($event, 'description')"
          label="Activity Description"
          placeholder="Input Activity Description"></cds-textfield>

        <div *ngIf="description.invalid && description.dirty" class="l-pt-2">
          <cds-assistive-text *ngIf="description?.errors?.['maxlength']" class="l-pt-2" [showIcon]="false">Max length is 50</cds-assistive-text>
          <cds-assistive-text
            *ngIf="description?.errors?.['descriptionInvalid']"
            class="l-pt-2"
            [showIcon]="false"
            >{{ description?.errors?.['descriptionInvalid'] | lang }}</cds-assistive-text
          >
        </div>
      </div>
      <div *ngIf="showDatePicker" class="row col-xs-12 col-sm-12 col-md-8 col-lg-6 l-pt-4 l-pb-4 l-plr-0 date-picker-area relative">
        <div class="col-xs-6 date-picker">
          <cds-textfield
            [(ngModel)]="searchDTO.createStartDate"
            #createStartDate="ngModel"
            [appDateValidator]="I18N_KEY.INVALID_DATE"
            [appDateValidatorMaxDate]="searchDTO.createEndDate || nowTimeInstr"
            placeholder="DD/MM/YYYY"
            label="Create Date From"
            (change)="searchChange($event, 'createStartDate')"
            [max]="getMaxFromDate | fn : searchDTO.createEndDate"
            [cdsDatepicker]="startPicker">
          </cds-textfield>

          <cds-datepicker #startPicker="cdsDatepicker"></cds-datepicker>

          <div *ngIf="createStartDate.invalid && (createStartDate.dirty || createStartDate.touched)" class="l-pt-2">
            <cds-assistive-text
              *ngIf="createStartDate?.errors?.['dateInvalid']"
              class="l-pt-2"
              [showIcon]="false"
              >{{ createStartDate?.errors?.['dateInvalid'] | lang }}</cds-assistive-text
            >
          </div>
        </div>
        <div class="connector">-</div>
        <div class="col-xs-6 date-picker">
          <cds-textfield
            [(ngModel)]="searchDTO.createEndDate"
            #createEndDate="ngModel"
            [appDateValidator]="I18N_KEY.INVALID_DATE"
            [appDateValidatorMinDate]="searchDTO.createStartDate"
            [appDateValidatorMaxDate]="maxTimeInStr"
            placeholder="DD/MM/YYYY"
            label="To"
            [min]="getMinToDate | fn : searchDTO.createStartDate"
            [max]="maxTime"
            (change)="searchChange($event, 'createEndDate')"
            [cdsDatepicker]="endPicker">
          </cds-textfield>

          <cds-datepicker #endPicker="cdsDatepicker"></cds-datepicker>

          <div *ngIf="createEndDate.invalid && (createEndDate.dirty || createEndDate.touched)" class="l-pt-2">
            <cds-assistive-text
              *ngIf="createEndDate?.errors?.['dateInvalid']"
              class="l-pt-2"
              [showIcon]="false"
              >{{ createEndDate?.errors?.['dateInvalid'] | lang }}</cds-assistive-text
            >
          </div>
        </div>
      </div>
      <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 l-pt-4 l-pb-4">
        <cds-textfield
          [(ngModel)]="searchDTO.score"
          #score="ngModel"
          [appMaxValidator]="999"
          [appMinValidator]="0"
          type="number"
          (input)="searchChange($event, 'score')"
          (compositionend)="searchChange($event, 'score')"
          label="Activity Score"
          placeholder="Input Activity Score"></cds-textfield>

        <div *ngIf="score.invalid && score.dirty" class="l-pt-2">
          <cds-assistive-text *ngIf="score?.errors?.['max']" class="l-pt-2" [showIcon]="false">Max score is 999</cds-assistive-text>
          <cds-assistive-text *ngIf="score?.errors?.['min']" class="l-pt-2" [showIcon]="false">Min score is 0</cds-assistive-text>
        </div>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 l-mt-4 l-pb-4 bottom-xs l-pt-4">
        <cds-button
          class="l-mr-4 botton"
          [config]="searchButtonConfig"
          label="{{ 'list.search' | lang }}"
          [disabled]="
            searchButtonDisabledFn
              | fn
                : activityCode?.control?.valid
                : description?.control?.valid
                : createStartDate?.control?.valid
                : createEndDate?.control?.valid
                : score?.control?.valid
                : isAllEmpty
          "
          (click)="search()"></cds-button>
        <cds-button
          class="botton"
          [config]="resetButtonConfig"
          label="{{ 'list.reset' | lang }}"
          [disabled]="resetButtonDisabled"
          (click)="reset()"></cds-button>
      </div>
    </div>
  </div>

  <app-loading *ngIf="isLoading" [overlayInPopup]="true"></app-loading>

  <!-- page data -->
  <div class="col-xs-12 col-md-12 l-pb-3 l-d-f l-jc-sb l-mt-7 l-mb-4">
    <div>
      <div class="cds-h2-light l-ma-0">{{ 'common.recordList' | lang }}</div>
      <div class="cds-detail1-demibold">Total {{ numberOfElements }} records</div>
    </div>
  </div>

  <div class="col-xs-12 row l-jc-sb">
    <div class="l-mb-5">
      <span class="cds-body2 l-mr-3">Activity Type:</span>
      <cds-pill *ngFor="let item of activityTypes" [selected]="item.value === selectedActivityType" (selectedChange)="pillSelectedChange($event, item.value)">{{
        item.label
      }}</cds-pill>
    </div>

    <div class="l-mb-5" *ngIf="canEdit">
      <cds-button [style]="'secondary'" size="sm" (click)="add()"> Add Activity </cds-button>
    </div>
  </div>

  <ng-container>
    <app-no-result-found class="col-xs-12 l-mb-4" *ngIf="dataSource && !dataSource.length; else dataTableData"> </app-no-result-found>
  </ng-container>

  <ng-template #dataTableData>
    <div class="table-container col-xs-12">
      <cds-table #table [dataSource]="dataSource" [class.full]="true">
        <!-- Activity Code -->
        <ng-container cdsColumnDef="activityCode">
          <cds-header-cell *cdsHeaderCellDef> Activity Code </cds-header-cell>
          <cds-cell *cdsCellDef="let element">
            <span>{{ element.activityCode }}</span>
          </cds-cell>
        </ng-container>

        <!-- Decription -->
        <ng-container cdsColumnDef="description">
          <cds-header-cell *cdsHeaderCellDef> Decription </cds-header-cell>
          <cds-cell *cdsCellDef="let element">
            <div *ngIf="!element.inEdit">
              {{ element.description }}
            </div>
            <div *ngIf="element.inEdit" class="description-input">
              <cds-textfield
                [config]="descriptionTxtfieldConfig"
                maxlength="50"
                required
                [appDescriptionValidator]="I18N_KEY.INVALID_DESCRIPTION_CHINESE"
                [appDescriptionValidatorSupportChinese]="true"
                [(ngModel)]="element.description"
                #elementDescription="ngModel"></cds-textfield>
              <div *ngIf="elementDescription.invalid && elementDescription.dirty" class="l-pt-2">
                <cds-assistive-text *ngIf="elementDescription?.errors?.['required']" class="l-pt-2" [showIcon]="false"
                  >Missing Activity Description</cds-assistive-text
                >
                <cds-assistive-text *ngIf="elementDescription?.errors?.['maxlength']" class="l-pt-2" [showIcon]="false">Max length is 50</cds-assistive-text>
                <cds-assistive-text
                  *ngIf="elementDescription?.errors?.['descriptionInvalid']"
                  class="l-pt-2"
                  [showIcon]="false"
                  >{{ elementDescription?.errors?.['descriptionInvalid'] | lang }}</cds-assistive-text
                >
              </div>
            </div>
          </cds-cell>
        </ng-container>

        <!-- Score -->
        <ng-container cdsColumnDef="score">
          <cds-header-cell *cdsHeaderCellDef> Score </cds-header-cell>
          <cds-cell *cdsCellDef="let element">
            <div *ngIf="!element.inEdit">
              {{ element.score }}
            </div>
            <div *ngIf="element.inEdit" class="score-input">
              <cds-textfield
                [config]="scoreTxtfieldConfig"
                [(ngModel)]="element.score"
                #elementScore="ngModel"
                (change)="scoreChange($event, element)"
                required
                [appMaxValidator]="999"
                [appMinValidator]="0"></cds-textfield>

              <div *ngIf="elementScore.invalid && elementScore.dirty" class="l-pt-2">
                <cds-assistive-text *ngIf="elementScore?.errors?.['required']" class="l-pt-2" [showIcon]="false">Missing Activity Score</cds-assistive-text>
                <cds-assistive-text *ngIf="elementScore?.errors?.['max']" class="l-pt-2" [showIcon]="false">Max score is 999</cds-assistive-text>
                <cds-assistive-text *ngIf="elementScore?.errors?.['min']" class="l-pt-2" [showIcon]="false">Min score is 0</cds-assistive-text>
              </div>
            </div>
          </cds-cell>
        </ng-container>

        <!-- Create Date -->
        <ng-container cdsColumnDef="createdAt">
          <cds-header-cell *cdsHeaderCellDef> Create Date </cds-header-cell>
          <cds-cell *cdsCellDef="let element">
            <span>
              {{ element.createdAt | date : 'dd/MM/yyyy' }}
            </span>
          </cds-cell>
        </ng-container>

        <!-- Action -->
        <ng-container cdsColumnDef="action">
          <cds-header-cell *cdsHeaderCellDef> Action </cds-header-cell>
          <cds-cell *cdsCellDef="let element">
            <div *ngIf="!element.inEdit">
              <div class="button-area" (click)="edit(element.activityCode)">
                <div class="icon-size">
                  <cds-icon size="sm" icon="action:edit"></cds-icon>
                </div>
                <span class="cds-h6-demibold l-pl-2">Edit</span>
              </div>
            </div>
            <div *ngIf="element.inEdit" class="action-button-area flex">
              <app-permission [role]="PermissionAccess.E" [permission]="PermissionItem.CUSTOMER_EMPLOYER_ACTIVITY_CODE">
                <cds-button size="sm" class="delete-button l-mr-1" (click)="delete(element.id, element.activityCode)">Delete</cds-button>
              </app-permission>

              <cds-button
                [config]="secondaryBtnCfg"
                (click)="save(element.id, element.activityCode, element.version)"
                [disabled]="
                  ifSaveButtonDisabled
                    | fn : element.activityCode : element.description : element.score : elementDescription?.control?.valid : elementScore?.control?.valid
                "
                >Save</cds-button
              >

              <cds-button
                size="sm"
                class="reset-button"
                (click)="resetLine(element.activityCode, element.description, element.score)"
                [disabled]="ifResetButtonDisabled | fn : element.activityCode : element.description : element.score"
                >Reset</cds-button
              >
            </div>
          </cds-cell>
        </ng-container>

        <cds-header-row *cdsHeaderRowDef="canEdit ? displayedColumns : displayedColumnsWithoutPermission"></cds-header-row>

        <cds-row *cdsRowDef="let row; columns: canEdit ? displayedColumns : displayedColumnsWithoutPermission"></cds-row>
      </cds-table>
    </div>

    <!-- pagenation -->
    <div class="col-xs-12 col-md-12 l-pt-4 l-pb-4 l-d-f l-jc-sb">
      <app-pagination [pageCount]="pageCount" [isLoading]="isLoading" [useOf]="true" (pageChange)="pageChange($event)"> </app-pagination>
    </div>
  </ng-template>
</div>
