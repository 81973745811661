import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CdsLanguageModule } from '@cds/ng-core/lang';
import { AgentListComponent } from './agent-list.component';
import { CdsButtonModule } from '@cds/ng-web-components/button';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CdsDropdownModule } from '@cds/ng-web-components/dropdown';
import { CdsIconModule } from '@cds/ng-web-components/icon';
import { CdsLinkModule } from '@cds/ng-web-components/link';
import { CdsSearchModule } from '@cds/ng-web-components/search';
import { CdsPaginationModule } from '@cds/ng-web-components/pagination';
import { AppPaginationModule } from 'src/app/shared/pagination/pagination.module';
import { LoadingModule } from 'src/app/shared/loading/loading.modules';
import { NoResultFoundModule } from 'src/app/shared/no-result-found/no-result-found.module';
import { CdsTableModule } from '@cds/ng-data-table/table';
import { AgentListRoutingModule } from './agent-list-routing.module';
import { AgentListAddComponent } from './agent-list-add/agent-list-add.component';
import { CdsPopupModule } from '@cds/ng-web-components/popup';
import { CdsTextfieldModule } from '@cds/ng-web-components/textfield';
import { RowItemModule } from 'src/app/components/row-item/row-item.module';
import { AgentListEditComponent } from './agent-list-edit/agent-list-edit.component';
import { CdsSortModule } from '@cds/ng-data-table/sort';
import { PermissionModule } from 'src/app/shared/permission/permission.module';
import { CdsTextareaModule } from '@cds/ng-web-components/textarea';
import { CdsAssistiveTextModule } from '@cds/ng-web-components/assistive-text';

@NgModule({
  declarations: [AgentListComponent, AgentListAddComponent, AgentListEditComponent],
  imports: [
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    CdsDropdownModule,
    CdsButtonModule,
    CdsIconModule,
    CdsLinkModule,
    CdsSearchModule,
    CdsPaginationModule,
    CdsLanguageModule,
    CdsTableModule,
    CdsTextfieldModule,
    AppPaginationModule,
    LoadingModule,
    NoResultFoundModule,
    AgentListRoutingModule,
    CdsPopupModule,
    RowItemModule,
    CdsSortModule,
    PermissionModule,
    CdsTextareaModule,
    CdsAssistiveTextModule,
  ],
})
export class AgentListModule {}
