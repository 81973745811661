<div class="declaration-iff-er">
  <div class="cds-popup-padding-top">
    <cds-icon icon="action:cross" (click)="closeCurrentPopup()"></cds-icon>
  </div>

  <div>
    <div class="col-xs-12 col-md-12 l-pb-6 background-grey" [style.display]="currentStage === 0 ? 'block' : 'none'">
      <div class="row l-pt-6 test">
        <div class="col-xs-12 col-md-12">
          <div class="row padding-none test">
            <div class="col-xs-12 col-md-6">
              <div>
                <div class="cds-h5-light l-ma-0 font-bold">eMPF source</div>
              </div>
            </div>
            <div class="l-pt-6">
              <span>Lorem ipsum dolor sit amet. Qui autem impedit est tenetur</span>
              <span>reiciendis et sunt rerum qui quisquam animi et repudiandae rerum</span>
              <span>ab cumque dolore ut accusamus ipsum. Doloribus aliquid quo</span>
              <span>delectus atque aut corporis eius eum maiores optio in autem</span>
            </div>
            <div class="col-xs-12 col-md-6 l-pt-6">
              <img src="../../../../../assets/img/empf.png" alt="../../../../../assets/img/empf.png" />
            </div>
          </div>
        </div>
      </div>
      <div class="row l-pt-6">
        <cds-button label="Got it" (click)="backToForm()" [config]="gotItButton"></cds-button>
      </div>
    </div>
    <div class="display-all" [style.display]="currentStage === 1 ? 'block' : 'none'">
      <div class="l-pl-7">
        <span class="cds-h2-demibold">{{ 'Declaration.header' | lang }}</span>
      </div>
      <div #displayContent class="content">
        <div class="l-pl-7 l-mt-5">
          <span class="cds-body2 normal-word">
            {{ 'Declaration.headersub1' | lang }}
          </span>
          <br />
          <span class="cds-body2 l-pb-2 normal-word">
            {{ 'Declaration.headersub3' | lang }}
          </span>
          <br />
          <br />
          <span class="cds-body2 l-pt-2 l-pb-2 normal-word">
            {{ 'Declaration.bodydis' | lang }}
          </span>
          <br />
          <span class="cds-body2 l-pb-2 normal-word">
            {{ 'Declaration.bodycommon' | lang }}
          </span>
          <br />
          <span class="cds-body2 l-pb-2 normal-word">
            {{ 'Declaration.bodysuba' | lang }}
          </span>
          <br />
          <span class="cds-body2 l-pb-2 normal-word">
            {{ 'Declaration.bodysubb' | lang }}
          </span>
          <br />
          <span class="cds-body2 l-pb-2 normal-word">
            {{ 'Declaration.bodysubc' | lang }}
          </span>
          <br />
          <span class="cds-body2 l-pb-2 normal-word">
            {{ 'Declaration.bodysubd' | lang }}
          </span>
          <br />
          <br />
          <span class="cds-body2 l-pt-2 l-pb-2 normal-word">
            {{ 'Declaration.bodyadmin' | lang }}
          </span>
          <br />
          <span class="cds-body2 l-pb-2 normal-word">
            {{ 'Declaration.bodycommon' | lang }}
          </span>
          <br />
          <span class="cds-body2 l-pt-2 l-pb-2 normal-word">
            {{ 'Declaration.bodysube' | lang }}
          </span>
          <br />
          <span class="cds-body2 l-pb-2 normal-word">
            {{ 'Declaration.bodysubf' | lang }}
          </span>
          <br />
          <br />
          <span class="cds-body2 l-pt-2 l-pb-2 normal-word">
            {{ 'Declaration.bodyother' | lang }}
          </span>
          <br />
          <span class="cds-body2 l-pb-2 normal-word">
            {{ 'Declaration.bodycommon2' | lang }}
          </span>
          <br />
          <span class="cds-body2 l-pb-2 normal-word">
            {{ 'Declaration.bodysubg' | lang }}
          </span>
          <br />
          <span class="cds-body2 l-pb-2 normal-word">
            {{ 'Declaration.bodysubh' | lang }}
          </span>
          <br />
          <span class="cds-body2 l-pb-2 normal-word">
            {{ 'Declaration.bodysubi' | lang }}
          </span>
          <br />
          <br />
          <span class="cds-body2 l-pb-2 normal-word">
            {{ 'Declaration.bodyimportant' | lang }}
          </span>
          <br />
          <span class="cds-body2 l-pb-2 normal-word">
            {{ 'Declaration.bodynotice' | lang }}
          </span>
          <br />
          <br />
          <span class="cds-body2 l-pb-2 normal-word">
            {{ 'Declaration.bodynoticesub1' | lang }}
          </span>
          <br />
          <span class="cds-body2 l-pb-2 normal-word">
            {{ 'Declaration.bodynoticesuba' | lang }}
          </span>
          <br />
          <span class="cds-body2 l-pb-2 normal-word">
            {{ 'Declaration.bodynoticesubb' | lang }}
          </span>
          <br />
          <span class="cds-body2 l-pb-2 normal-word">
            {{ 'Declaration.bodynoticesubc' | lang }}
          </span>
          <br />
          <span class="cds-body2 l-pb-2 normal-word">
            {{ 'Declaration.bodynoticesubd' | lang }}
          </span>
          <br />
        </div>
      </div>
      <div class="l-pl-7 l-mb-2 l-pt-5 declaration-agreement-btn">
        <cds-button label="{{ 'Declaration.noticeread' | lang }}" (click)="toIffErForm()" [disabled]="!isScrollBottom" [config]="commonButton"></cds-button>
      </div>
    </div>
    <div [style.display]="currentStage === 2 ? 'block' : 'none'">
      <form [formGroup]="iffErForm" (ngSubmit)="onIffPartSubmit()">
        <div class="row">
          <div class="cds-h2 col-md-12 l-pt-2">
            {{ 'iff.mpfIntermediaryDeclaration' | lang }}
          </div>
          <div class="col-md-12">
            <span class="cds-detail2">
              {{ 'iff.headerInform' | lang }}
            </span>
          </div>
        </div>
        <div class="row" style="margin-top: var(--cds-spacing-05)">
          <div class="col-md-4 l-pt-1">
            <div class="cds-body2-bold">
              {{ 'iff.eMPFReferenceNo' | lang }}
            </div>
          </div>
          <div class="col-md-8 row l-pt-1">
            <div class="col-md-11 l-plr-0">
              <input
                class="text-field"
                [maxlength]="50"
                formControlName="eMPFReferenceNo"
                placeholder="{{ 'iff.eMPFReferenceNoPlaceHolder' | lang }}"
                onkeyup="value=value.replace(/[\u4e00-\u9fa5]/ig,'')"
                (blur)="inputBlur('eMPFReferenceNo')"
                ngDefaultControl />
              <div class="l-mt-1" *ngIf="iffErForm.get('eMPFReferenceNo')!.invalid && iffErForm.get('eMPFReferenceNo')?.touched">
                <cds-assistive-text *ngIf="iffErForm.get('eMPFReferenceNo')?.errors && (iffErForm.get('eMPFReferenceNo')?.errors)!['required']" type="error"
                  >{{ 'iff.eMPFReferenceNoError1' | lang }}
                </cds-assistive-text>
                <cds-assistive-text
                  *ngIf="iffErForm.get('eMPFReferenceNo')?.errors && (iffErForm.get('eMPFReferenceNo')?.errors)!['usedEMPFRefNo']"
                  type="error"
                  >{{ 'iff.eMPFReferenceNoError2' | lang }}
                </cds-assistive-text>
              </div>
            </div>
            <div class="col-md-1 l-mt-3">
              <cds-icon [icon]="infoIcon" [config]="iconConfig" (click)="onClickInfoIcon()"></cds-icon>
            </div>
          </div>
        </div>
        <div class="row l-pt-1">
          <app-cd-intragroup-transfer-checkbox class="l-ml-3" [formControl]="isIntragroupTransfer" ngDefaultControl></app-cd-intragroup-transfer-checkbox>
        </div>
        <div class="row l-pt-5">
          <div class="cds-body2 col-md-12">
            <app-regulated-activities
              style="width: 100%"
              #regulatedActivities
              [group]="iffErForm"
              controlName="conductedRegulatorActivityFlag"
              ngDefaultControl></app-regulated-activities>
            <cds-assistive-text
              class="l-mt-1"
              *ngIf="iffErForm.get('conductedRegulatorActivityFlag')?.invalid && iffErForm.get('conductedRegulatorActivityFlag')?.touched"
              type="error"
              >{{ 'iff.pleaseSelectRelevantRegulatedActivities' | lang }}
            </cds-assistive-text>
          </div>
        </div>

        <div class="row" style="margin-top: 12px">
          <div class="col-md-4">
            <div class="cds-body2-bold">{{ 'sendIffInBatchSummary.employerNameOrBrNo' | lang }} :</div>
          </div>
          <div class="col-md-8">
            <cds-radio
              class="cd-radio-margin-bottom-clear"
              [config]="{
                options: employerIdentifierOptions
              }"
              [formControl]="employerIdentifierControl"
              (ngModelChange)="employerIdentifierControlChange()"
              ngDefaultControl></cds-radio>
            <div class="l-mt-1" *ngIf="iffErForm.get('employerNameOrBrNo')?.touched">
              <cds-assistive-text *ngIf="!employerIdentifierControl.value"> Please select the Employer identifier </cds-assistive-text>
            </div>
          </div>
          <div class="col-md-4"></div>
          <div *ngIf="employerIdentifierControl.value" class="col-md-8 l-mb-5" style="position: relative; top: -4px">
            <div>
              <app-cd-input
                [formControl]="employerNameOrBrNo"
                [regExpLimit]="employerNameOrBrNoControlRegExpLimit"
                ngDefaultControl
                [placeholder]="employerNameOrBrNoControlPlaceholder | lang"></app-cd-input>
            </div>
            <div class="l-mt-1" *ngIf="iffErForm.get('employerNameOrBrNo')?.touched && iffErForm.get('employerNameOrBrNo')?.invalid">
              <cds-assistive-text *ngIf="employerIdentifierControl.value && (iffErForm.get('employerNameOrBrNo')?.errors)!['required']" type="error"
                ><span *ngIf="employerIdentifierControl.value === EmployerIdentifierEnum.employerName">{{ 'iff-er.employerNameErrMsg1' | lang }}</span>
                <span *ngIf="employerIdentifierControl.value === EmployerIdentifierEnum.bRNumber">{{ 'iff-er.businessRegNoErrMsg1' | lang }}</span>
              </cds-assistive-text>
            </div>
          </div>
        </div>
        <div class="row" style="margin-top: 12px">
          <div class="col-md-4">
            <div>
              <span class="cds-body2-bold">{{ 'iff-er.authPersonMobileEmail1' | lang }}</span>
            </div>
            <div>
              <span class="cds-body2-bold">{{ 'iff-er.authPersonMobileEmail2' | lang }} :</span>
            </div>
          </div>
          <div class="col-md-8">
            <div>
              <cds-radio
                class="cd-radio-margin-bottom-clear"
                [config]="{
                  options: [
                    {
                      label: 'iff.contactTypeEmail' | lang,
                      value: 'E'
                    },
                    {
                      label: 'iff.contactTypePhone' | lang,
                      value: 'M'
                    }
                  ]
                }"
                formControlName="contactType"
                (cdsChange)="onContactTypeChange($event)"
                ngDefaultControl></cds-radio>
              <div class="l-mt-1" *ngIf="!iffErForm.get('contactType')!.valid && iffErForm.get('contactType')?.touched">
                <cds-assistive-text *ngIf="!iffErForm.value.contactType" type="error">{{ 'iff.contactTypeError' | lang }} </cds-assistive-text>
              </div>
            </div>
            <div></div>
            <div *ngIf="iffErForm.value.contactType === 'E'" class="l-d-f l-fd-row l-mb-3 l-mt-2">
              <div class="col-md-12 l-plr-0">
                <input
                  class="text-field"
                  [placeholder]="'common.input' | lang : { p1: 'iff-er.authorizedPersonEmail' | lang }"
                  formControlName="email"
                  ngDefaultControl />
                <div
                  class="l-mt-1"
                  *ngIf="
                    iffErForm.get('email')!.invalid && (iffErForm.get('email')?.touched || iffErForm.get('email')?.dirty) && !iffErForm.get('email')?.value
                  ">
                  <cds-assistive-text *ngIf="iffErForm.get('email')?.errors || (iffErForm.get('email')?.errors)!['required']" type="error"
                    >{{ 'iff-er.authPersonEmailErrMsg1' | lang }}
                  </cds-assistive-text>
                </div>
                <div class="l-mt-1" *ngIf="iffErForm.get('email')?.errors && (iffErForm.get('email')?.errors)!['email']">
                  <cds-assistive-text type="error">{{ 'iff-er.authPersonEmailErrMsg1' | lang }} </cds-assistive-text>
                </div>
              </div>
            </div>
            <div *ngIf="iffErForm.value.contactType === 'M'" class="l-d-f l-fd-row l-mb-3 l-mt-2">
              <div class="col-md-4 l-plr-0">
                <cds-dropdown formControlName="countryCode" placeholder="+852 (HK)" [config]="countrycodeConfig" ngDefaultControl></cds-dropdown>
              </div>
              <div class="col-md-8 phone-no-box">
                <input
                  class="text-field"
                  type="number"
                  [placeholder]="'common.input' | lang : { p1: 'iff-er.authorizedPersonMobile' | lang }"
                  formControlName="phoneNo"
                  ngDefaultControl />
                <div class="l-mt-1" *ngIf="iffErForm.get('phoneNo')!.invalid && iffErForm.get('phoneNo')?.touched">
                  <cds-assistive-text *ngIf="iffErForm.get('phoneNo')?.errors && (iffErForm.get('phoneNo')?.errors)!['required']" type="error"
                    >{{ 'iff-er.authPersonMobileErrMsg1' | lang }}
                  </cds-assistive-text>
                </div>
                <div class="l-mt-1" *ngIf="iffErForm.get('phoneNo')?.touched && iffErForm?.errors && (iffErForm?.errors)!['invalidPhoneNo']">
                  <cds-assistive-text type="error">{{ 'iff-er.authPersonMobileErrMsg2' | lang }} </cds-assistive-text>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ng-container *ngIf="isChannelDBS">
          <div class="row" style="margin-top: 16px">
            <div class="col-md-4 l-d-f l-ai-cen">
              <div>
                <span class="cds-body2-bold">RM Referral Code:</span>
              </div>
            </div>
            <div class="col-md-8">
              <app-cd-input
                [type]="'number'"
                [formControl]="dbs.rmReferralCode"
                [maxlength]="8"
                ngDefaultControl
                [placeholder]="'Input RM Referral Code'"></app-cd-input>
              <div class="l-mt-1">
                <cds-assistive-text *ngIf="dbs.rmReferralCode.dirty && dbs.rmReferralCode.hasError('required'); else second" type="error"
                  >Please input valid RM Referral Code
                </cds-assistive-text>
                <ng-template #second>
                  <cds-assistive-text *ngIf="dbs.rmReferralCode.dirty && dbs.rmReferralCode.hasError('lengthError')" type="error"
                    >Please input valid RM Referral Code
                  </cds-assistive-text>
                </ng-template>
              </div>
            </div>
          </div>
          <div class="row" style="margin-top: var(--cds-spacing-05)">
            <div class="col-md-4 l-d-f l-ai-cen">
              <div>
                <span class="cds-body2-bold">Customer ID:</span>
              </div>
            </div>
            <div class="col-md-8">
              <app-cd-input
                [formControl]="dbs.inputCustomerId"
                [maxlength]="8"
                [regExpLimit]="'^[A-Za-z0-9-]*$'"
                ngDefaultControl
                [placeholder]="'Input Customer ID (Optional)'"></app-cd-input>
              <div class="l-mt-1">
                <cds-assistive-text *ngIf="dbs.inputCustomerId.dirty && dbs.inputCustomerId.hasError('lengthError')" type="error"
                  >Please input valid Customer ID
                </cds-assistive-text>
              </div>
            </div>
          </div>
          <div class="row" style="margin-top: var(--cds-spacing-05)">
            <div class="col-md-4 l-d-f l-ai-cen">
              <div>
                <span class="cds-body2-bold">Campaign Code:</span>
              </div>
            </div>
            <div class="col-md-8">
              <app-cd-input
                [formControl]="dbs.campaignCode"
                [maxlength]="10"
                [regExpLimit]="'^[A-Za-z0-9-]*$'"
                ngDefaultControl
                [placeholder]="'Input Campaign Code (Optional)'"></app-cd-input>
              <div class="l-mt-1">
                <cds-assistive-text *ngIf="dbs.campaignCode.dirty && dbs.campaignCode.hasError('lengthError')" type="error"
                  >Please input valid Campaign Code
                </cds-assistive-text>
              </div>
            </div>
          </div>
          <div class="row" style="margin-top: var(--cds-spacing-05)">
            <div class="col-md-4 l-d-f l-ai-cen">
              <div>
                <span class="cds-body2-bold">Source Code:</span>
              </div>
            </div>
            <div class="col-md-8">
              <app-cd-input
                [formControl]="dbs.sourceCode"
                [maxlength]="10"
                [regExpLimit]="'^[A-Za-z0-9-]*$'"
                ngDefaultControl
                [placeholder]="'Input Source Code (Optional)'"></app-cd-input>
              <div class="l-mt-1">
                <cds-assistive-text *ngIf="dbs.sourceCode.dirty && dbs.sourceCode.hasError('lengthError')" type="error"
                  >Please input valid Source Code
                </cds-assistive-text>
              </div>
            </div>
          </div>
        </ng-container>
        <div class="row" style="margin-top: var(--cds-spacing-05)">
          <div class="col-md-12 l-d-f l-ai-cen">
            <span class="cds-detail2" style="color: var(--cds-color-dark-1-coral)">{{ 'iff.customerContactTypeRemark' | lang }}</span>
          </div>
        </div>
        <div class="row" style="margin-top: var(--cds-spacing-05)">
          <div class="col-md-4 l-d-f l-ai-cen">
            <div>
              <span class="cds-body2-bold"> {{ 'sendIffInBatchSummary.preferredLanguage' | lang }}: </span>
            </div>
          </div>
          <div class="col-md-8">
            <app-cd-radio
              class="cd-radio-margin-bottom-clear"
              #cdRadioPreferredLanguage
              (valueChange)="iffErForm.get('preferredLanguage')?.setValue($event)"
              [value]="iffErForm.get('preferredLanguage')?.value"
              [config]="preferredLanguageConfig"></app-cd-radio>
          </div>
        </div>
        <div
          *ngIf="iffErForm.get('preferredLanguage')?.invalid && iffErForm.get('preferredLanguage')?.touched"
          class="row"
          style="margin-top: var(--cds-spacing-01)">
          <div class="col-md-4 l-d-f l-ai-cen"></div>
          <div class="col-md-8">
            <cds-assistive-text type="error">{{ 'common.plsSelect' | lang : { p1: 'sendIffInBatchSummary.preferredLanguage' | lang } }} </cds-assistive-text>
          </div>
        </div>
        <div class="row" style="margin-top: var(--cds-spacing-05)">
          <div class="col-md-4 l-d-f l-ai-cen">
            <div>
              <span class="cds-body2-bold"> {{ 'iff-er.secondAgentCode' | lang }}: </span>
            </div>
          </div>
          <div class="col-md-8">
            <div>
              <input
                [ngClass]="[isDisabled ? 'text-field-disabled' : 'text-field']"
                type="number"
                formControlName="secondAgentCode"
                [attr.disabled]="isDisabled ? '' : null"
                onkeyup="value=value.replace(/[\u4e00-\u9fa5]/ig,'')"
                (blur)="inputBlur('secondAgentCode')"
                placeholder="{{ 'iff-er.secondAgentCodePlaceHolder' | lang }}"
                ngDefaultControl />
            </div>
            <div class="l-mt-1" *ngIf="iffErForm.get('secondAgentCode')!.invalid && iffErForm.get('secondAgentCode')?.touched">
              <cds-assistive-text
                *ngIf="iffErForm.get('secondAgentCode')?.errors || (iffErForm.get('secondAgentCode')?.errors)!['invalidSecondAgentCode']"
                type="error"
                >{{ 'iff-er.secondAgentCodeNotFoundError' | lang }}
              </cds-assistive-text>
            </div>
          </div>
        </div>
        <div class="row" style="margin-top: var(--cds-spacing-05)">
          <div class="col-md-4 l-d-f l-ai-cen">
            <div>
              <span class="cds-body2-bold"> {{ 'iff-er.thirdAgentCode' | lang }}: </span>
            </div>
          </div>
          <div class="col-md-8">
            <div>
              <input
                [ngClass]="[isChannelDBS ? 'text-field-disabled' : 'text-field']"
                type="number"
                [maxlength]="6"
                formControlName="thirdAgentCode"
                [attr.disabled]="isChannelDBS ? '' : null"
                onkeyup="value=value.replace(/[\u4e00-\u9fa5]/ig,'')"
                (blur)="inputBlur('thirdAgentCode')"
                placeholder="{{ 'iff-er.thirdAgentCodePlaceHolder' | lang }}"
                ngDefaultControl />
            </div>
            <div class="l-mt-1" *ngIf="iffErForm.get('thirdAgentCode')!.invalid && iffErForm.get('thirdAgentCode')?.touched">
              <cds-assistive-text
                *ngIf="iffErForm.get('thirdAgentCode')?.errors || (iffErForm.get('thirdAgentCode')?.errors)!['invalidThirdAgentCode']"
                type="error"
                >{{ 'iff-er.thirdAgentCodeNotFoundError' | lang }}
              </cds-assistive-text>
            </div>
          </div>
        </div>
        <div class="row l-pt-6 l-d-f">
          <div class="l-pl-3">
            <cds-button label="{{ 'iff.reset' | lang }}" (click)="resetIffErForm()" [config]="commonButton2"></cds-button>
          </div>
          <div class="l-pl-3" *ngIf="iffErForm.value.secondAgentCode">
            <cds-button
              label="{{ 'iff.continue' | lang }}"
              [config]="{
                style: 'primary',
                size: 'md',
                buttonType: 'submit'
              }"></cds-button>
          </div>
          <div class="l-pl-3" *ngIf="!iffErForm.value.secondAgentCode">
            <cds-button
              label="{{ 'iff.submit' | lang }}"
              [config]="{
                style: 'primary',
                size: 'md',
                buttonType: 'submit'
              }"></cds-button>
          </div>
        </div>
      </form>
    </div>
    <div [style.display]="currentStage === 3 ? 'block' : 'none'">
      <div class="row">
        <div class="col-md-12 l-pt-6">
          <div class="cds-h2-bold">
            {{ 'iff.mpfIntermediaryDeclarationConfirmation' | lang }}
          </div>
        </div>
        <div class="col-md-12 l-d-f l-mb-6">
          <app-matters-needing-attention [color]="'red'" [title]="'iff.pleaseNoted' | lang">
            <ul class="l-mt-0 l-mb-0">
              <li>
                <span class="cds-detail1">{{ 'iff.splitAgentNote1' | lang }}</span>
              </li>
              <li>
                <span class="cds-detail1">{{ 'iff.splitAgentNote2' | lang }}</span>
              </li>
              <li>
                <span class="cds-detail1">{{ 'iff.splitAgentNote3HeadPart' | lang }}</span>
                <span class="cds-detail1 red-text">{{ 'iff.splitAgentNote3RedPart' | lang }}</span>
                <span class="cds-detail1">{{ 'iff.splitAgentNoteTailPart' | lang }}</span>
              </li>
            </ul>
          </app-matters-needing-attention>
        </div>
      </div>
      <div class="row l-pt-2">
        <div class="col-md-4">
          <div class="cds-body2-bold">
            {{ 'iff.confirmationEMPFRefNo' | lang }}
          </div>
        </div>
        <div class="col-md-8">
          <div>
            <span class="cds-body2-demibold">{{ iffErForm.value.eMPFReferenceNo }}</span>
          </div>
          <div *ngIf="currentStage === 3" class="l-mt-4">
            <app-cd-intragroup-transfer-checkbox [formControl]="isIntragroupTransfer"></app-cd-intragroup-transfer-checkbox>
          </div>
          <div style="font-size: 14px; padding-top: 20px">
            <app-regulated-activities
              style="width: 100%"
              #regulatedActivities
              [disable]="true"
              [value]="iffErForm.value.conductedRegulatorActivityFlag"
              ngDefaultControl></app-regulated-activities>
          </div>
        </div>
      </div>
      <div class="row l-pt-2">
        <div class="col-md-4">
          <div class="cds-body2-bold">Employer identifier:</div>
        </div>
        <div class="col-md-8">
          <div>
            <span class="cds-body2-demibold"
              >{{
                employerIdentifierControl.value === EmployerIdentifierEnum.employerName
                  ? 'Employer Name'
                  : employerIdentifierControl.value === EmployerIdentifierEnum.bRNumber
                  ? 'BR Number'
                  : 'N/A'
              }}
            </span>
          </div>
        </div>
      </div>
      <div *ngIf="employerIdentifierControl.value === EmployerIdentifierEnum.employerName" class="row l-pt-2">
        <div class="col-md-4">
          <div class="cds-body2-bold">{{ 'iff-er.employerName' | lang }}:</div>
        </div>
        <div class="col-md-8">
          <div>
            <span class="cds-body2-demibold">{{ iffErForm.value.employerNameOrBrNo }} </span>
          </div>
        </div>
      </div>
      <div *ngIf="employerIdentifierControl.value === EmployerIdentifierEnum.bRNumber" class="row l-pt-2">
        <div class="col-md-4">
          <div class="cds-body2-bold">{{ 'iff-er.businessRegNo' | lang }}:</div>
        </div>
        <div class="col-md-8">
          <div>
            <span class="cds-body2-demibold">{{ iffErForm.value.employerNameOrBrNo }} </span>
          </div>
        </div>
      </div>
      <div class="row l-pt-2">
        <div class="col-md-4">
          <div class="cds-body2-bold">
            {{ 'iff.customerContactType' | lang }}
          </div>
        </div>
        <div class="col-md-8">
          <div>
            <span class="cds-body2-demibold">{{
              iffErForm.value.contactType === 'E' ? iffErForm.value.email : iffErForm.value.countryCode + ' ' + iffErForm.value.phoneNo
            }}</span>
          </div>
        </div>
      </div>
      <div *ngIf="iffErForm.get('preferredLanguage')?.value" class="row l-pt-2">
        <div class="col-md-4">
          <div class="cds-body2-bold">{{ 'sendIffInBatchSummary.receivingLanguage' | lang }}:</div>
        </div>
        <div class="col-md-8">
          <div *ngIf="iffErForm.get('preferredLanguage')?.value === PreferredLanguageType.Chinese">
            <span class="cds-body2-demibold">{{ 'common.chinese' | lang }}</span>
          </div>
          <div *ngIf="iffErForm.get('preferredLanguage')?.value === PreferredLanguageType.English">
            <span class="cds-body2-demibold">{{ 'common.english' | lang }}</span>
          </div>
        </div>
      </div>
      <ng-container *ngIf="isChannelDBS">
        <div class="row l-pt-2">
          <div class="col-md-4">
            <div class="cds-body2-bold">RM Referral Code:</div>
          </div>
          <div class="col-md-8">
            <span class="cds-body2-demibold">{{ dbs.rmReferralCode.value || '-' }}</span>
          </div>
        </div>
        <div class="row l-pt-2">
          <div class="col-md-4">
            <div class="cds-body2-bold">Customer ID:</div>
          </div>
          <div class="col-md-8">
            <span class="cds-body2-demibold">{{ dbs.inputCustomerId.value || '-' }}</span>
          </div>
        </div>
        <div class="row l-pt-2">
          <div class="col-md-4">
            <div class="cds-body2-bold">Campaign Code:</div>
          </div>
          <div class="col-md-8">
            <span class="cds-body2-demibold">{{ dbs.campaignCode.value || '-' }}</span>
          </div>
        </div>
        <div class="row l-pt-2">
          <div class="col-md-4">
            <div class="cds-body2-bold">Source Code:</div>
          </div>
          <div class="col-md-8">
            <span class="cds-body2-demibold">{{ dbs.sourceCode.value || '-' }}</span>
          </div>
        </div>
      </ng-container>
      <div class="row l-pt-2 l-d-f">
        <div class="l-pl-3">
          <cds-button label="{{ 'iff.back' | lang }}" (click)="backToIffErForm()" [config]="commonButton2"></cds-button>
        </div>
        <div class="l-pl-3">
          <cds-button label="{{ 'iff.confirmAndSubmit' | lang }}" (click)="iffSubmission()" [config]="commonButton"></cds-button>
          <div class="l-mt-4">
            <cds-assistive-text type="error">{{ 'sendIffInBatchSummary.noChanges' | lang }}</cds-assistive-text>
          </div>
        </div>
      </div>
    </div>

    <div [style.display]="currentStage === 4 ? 'block' : 'none'">
      <div *ngIf="isSplitSecondAgent">
        <div class="row">
          <div class="cds-h2-bold col-md-12 l-pt-2 l-mb-4">
            {{ 'iff.mpfIntermediaryDeclaration' | lang }}
          </div>
        </div>
        <div class="row l-pt-2">
          <div class="col-md-4">
            <div class="cds-body2-bold">
              {{ 'iff-er.secondAgentCodeNo' | lang }}
            </div>
          </div>
          <div class="col-md-8">
            <div>
              <span class="cds-body2-bold">{{ iffErForm.value.secondAgentCode }}</span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <div class="cds-body2-bold">
              {{ 'iff-er.secondAgentName' | lang }}
            </div>
          </div>
          <div class="col-md-8">
            <div>
              <span class="cds-body2-bold">{{ getAgentName(secondAgentInfo) }}</span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 l-pt-2">
            <app-regulated-activities
              style="width: 100%"
              #regulatedActivities
              [disable]="!iffErForm.get('conductedRegulatorActivityFlag')?.value"
              [(value)]="isSecondAgentConduct"
              ngDefaultControl></app-regulated-activities>
          </div>
        </div>
        <div class="l-pt-3 l-plr-0" *ngIf="isSecondAgentConduct">
          <div class="l-d-f l-mb-6 pillar-wrapper">
            <app-matters-needing-attention [title]="'iff-er.importantNotes' | lang">
              <div class="cds-detail2 l-d-f l-fd-col">
                <span class="cds-detail2 l-mt-1">
                  {{ 'iff-er.secondNotes' | lang }}
                </span>
              </div>
            </app-matters-needing-attention>
          </div>
          <div class="row">
            <div class="col-md-12 cds-body2">
              {{ 'iff.secondAgentContactDeclareHeader' | lang }}
            </div>
          </div>
          <div class="row">
            <div class="l-pl-3 l-pr-3 connect-radio-box">
              <app-cd-radio
                class="cd-radio-margin-bottom-clear"
                [(value)]="secondAgentNotificationChannel"
                [config]="secondAgentRadioConfig"
                (valueChange)="onSecondAgentContactTypeChange($event)"></app-cd-radio>
            </div>
          </div>
          <div class="row" style="margin-top: var(--cds-spacing-04)">
            <div class="col-md-3 l-d-f l-ai-cen">
              <div>
                <span class="cds-body2-bold">{{ 'sendIffInBatchSummary.preferredLanguage' | lang }}</span>
              </div>
            </div>
            <div class="col-md-9">
              <app-cd-radio
                class="cd-radio-margin-bottom-clear"
                #cdRadioSecondAgentPreferredLanguage
                [(value)]="secondAgentPreferredLanguage"
                [config]="secondAgentPreferredLanguageConfig"></app-cd-radio>
            </div>
          </div>
        </div>
      </div>

      <div class="l-mt-5" *ngIf="isSplitThirdAgent">
        <div class="row l-pt-2">
          <div class="col-md-4">
            <div class="cds-body2-bold">
              {{ 'iff-er.thirdAgentCodeNo' | lang }}
            </div>
          </div>
          <div class="col-md-8">
            <div>
              <span class="cds-body2-bold">{{ iffErForm.value.thirdAgentCode }}</span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <div class="cds-body2-bold">
              {{ 'iff-er.thirdAgentName' | lang }}
            </div>
          </div>
          <div class="col-md-8">
            <div>
              <span class="cds-body2-bold">{{ getAgentName(thirdAgentInfo) }}</span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 l-pt-2">
            <app-regulated-activities
              style="width: 100%"
              #regulatedActivities
              [disable]="!iffErForm.get('conductedRegulatorActivityFlag')?.value"
              [(value)]="isThirdAgentConduct"
              ngDefaultControl></app-regulated-activities>
          </div>
        </div>
        <div class="l-pt-3 l-plr-0" *ngIf="isThirdAgentConduct">
          <div class="l-d-f l-mb-6 pillar-wrapper">
            <app-matters-needing-attention [title]="'iff-er.importantNotes' | lang">
              <div class="cds-detail2 l-d-f l-fd-col">
                <span class="cds-detail2 l-mt-1">
                  {{ 'iff-er.thirdNotes' | lang }}
                </span>
              </div>
            </app-matters-needing-attention>
          </div>

          <div class="row">
            <div class="col-md-12 cds-body2">
              {{ 'iff-er.thirdAgentContactDeclareHeader' | lang }}
            </div>
          </div>
          <div class="row">
            <div class="l-pl-3 l-pr-3 connect-radio-box">
              <app-cd-radio
                class="cd-radio-margin-bottom-clear"
                [(value)]="thirdAgentNotificationChannel"
                [config]="thirdAgentRadioConfig"
                (valueChange)="onThirdAgentContactTypeChange($event)"></app-cd-radio>
            </div>
          </div>
          <div class="row" style="margin-top: var(--cds-spacing-04)">
            <div class="col-md-3 l-d-f l-ai-cen">
              <div>
                <span class="cds-body2-bold">{{ 'sendIffInBatchSummary.preferredLanguage' | lang }}</span>
              </div>
            </div>
            <div class="col-md-9">
              <app-cd-radio
                class="cd-radio-margin-bottom-clear"
                #cdRadioThirdAgentPreferredLanguage
                [(value)]="thirdAgentPreferredLanguage"
                [config]="thirdAgentPreferredLanguageConfig"></app-cd-radio>
            </div>
          </div>
        </div>
      </div>

      <div class="row l-pt-4 l-d-f">
        <div class="l-pl-3">
          <cds-button label="{{ 'iff.back' | lang }}" (click)="backToIffErForm()" [config]="commonButton2"></cds-button>
        </div>
        <div class="l-pl-3">
          <cds-button
            [disabled]="disableIffSplitAgentContinue"
            label="{{ 'iff.continue' | lang }}"
            (click)="iffSplitAgentContinue()"
            [config]="commonButton"></cds-button>
        </div>
      </div>
    </div>

    <div [style.display]="currentStage === 5 ? 'block' : 'none'">
      <div class="row">
        <div class="col-md-12 l-pt-6">
          <div class="cds-h2-bold">
            {{ 'iff.mpfIntermediaryDeclarationConfirmation' | lang }}
          </div>
        </div>

        <div class="l-d-f l-mb-6 l-pl-3 l-pr-3">
          <app-matters-needing-attention [color]="'red'" [title]="'iff.pleaseNoted' | lang">
            <ul class="l-mt-0 l-mb-0">
              <li>
                <span class="cds-detail1">{{ 'iff.splitAgentNote1' | lang }}</span>
              </li>
              <li>
                <span class="cds-detail1">{{ 'iff.splitAgentNote2' | lang }}</span>
              </li>
              <li>
                <span class="cds-detail1">{{ 'iff.splitAgentNote3HeadPart' | lang }}</span>
                <span class="cds-detail1 red-text">{{ 'iff.splitAgentNote3RedPart' | lang }}</span>
                <span class="cds-detail1">{{ 'iff.splitAgentNoteTailPart' | lang }}</span>
              </li>
            </ul>
          </app-matters-needing-attention>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4">
          <div class="cds-body2-bold">
            {{ 'iff.confirmationEMPFRefNo' | lang }}
          </div>
        </div>
        <div class="col-md-8">
          <span class="cds-body2-bold">{{ iffErForm.value.eMPFReferenceNo }}</span>
          <div *ngIf="currentStage === 5" class="l-mt-4 l-mb-4">
            <app-cd-intragroup-transfer-checkbox [formControl]="isIntragroupTransfer"></app-cd-intragroup-transfer-checkbox>
          </div>
        </div>
      </div>

      <div class="row l-pt-1">
        <div class="col-md-4"></div>
        <div class="col-md-8">
          <app-regulated-activities
            style="width: 100%"
            #regulatedActivities
            [disable]="true"
            [value]="iffErForm.value.conductedRegulatorActivityFlag"
            ngDefaultControl></app-regulated-activities>
        </div>
      </div>

      <div class="row l-pt-2">
        <div class="col-md-4">
          <div class="cds-body2-bold">Employer identifier:</div>
        </div>
        <div class="col-md-8">
          <div>
            <span class="cds-body2-demibold"
              >{{
                employerIdentifierControl.value === EmployerIdentifierEnum.employerName
                  ? 'Employer Name'
                  : employerIdentifierControl.value === EmployerIdentifierEnum.bRNumber
                  ? 'BR Number'
                  : 'N/A'
              }}
            </span>
          </div>
        </div>
      </div>
      <div *ngIf="employerIdentifierControl.value === EmployerIdentifierEnum.employerName" class="row l-pt-2">
        <div class="col-md-4">
          <div class="cds-body2-bold">{{ 'iff-er.employerName' | lang }}:</div>
        </div>
        <div class="col-md-8">
          <div>
            <span class="cds-body2-demibold">{{ iffErForm.value.employerNameOrBrNo }} </span>
          </div>
        </div>
      </div>
      <div *ngIf="employerIdentifierControl.value === EmployerIdentifierEnum.bRNumber" class="row l-pt-2">
        <div class="col-md-4">
          <div class="cds-body2-bold">{{ 'iff-er.businessRegNo' | lang }}:</div>
        </div>
        <div class="col-md-8">
          <div>
            <span class="cds-body2-demibold">{{ iffErForm.value.employerNameOrBrNo }} </span>
          </div>
        </div>
      </div>

      <div class="row l-pt-2">
        <div class="col-md-4">
          <div class="cds-body2-bold">
            {{ 'iff-er.authPersonMobileEmail1' | lang }}
            {{ 'iff-er.authPersonMobileEmail2' | lang }}:
          </div>
        </div>
        <div class="col-md-8">
          <span class="cds-body2-bold">{{
            iffErForm.value.contactType === 'E' ? iffErForm.value.email : iffErForm.value.countryCode + ' ' + iffErForm.value.phoneNo
          }}</span>
        </div>
      </div>

      <div *ngIf="iffErForm.get('preferredLanguage')?.value" class="row l-pt-2">
        <div class="col-md-4">
          <div class="cds-body2-bold">{{ 'sendIffInBatchSummary.receivingLanguage' | lang }}:</div>
        </div>
        <div class="col-md-8">
          <div *ngIf="iffErForm.get('preferredLanguage')?.value === PreferredLanguageType.Chinese">
            <span class="cds-body2-demibold">{{ 'common.chinese' | lang }}</span>
          </div>
          <div *ngIf="iffErForm.get('preferredLanguage')?.value === PreferredLanguageType.English">
            <span class="cds-body2-demibold">{{ 'common.english' | lang }}</span>
          </div>
        </div>
      </div>

      <ng-container *ngIf="isChannelDBS">
        <div class="row l-pt-2">
          <div class="col-md-4">
            <div class="cds-body2-bold">RM Referral Code:</div>
          </div>
          <div class="col-md-8">
            <span class="cds-body2-bold">{{ dbs.rmReferralCode.value || '-' }}</span>
          </div>
        </div>
        <div class="row l-pt-2">
          <div class="col-md-4">
            <div class="cds-body2-bold">Customer ID:</div>
          </div>
          <div class="col-md-8">
            <span class="cds-body2-bold">{{ dbs.inputCustomerId.value || '-' }}</span>
          </div>
        </div>
        <div class="row l-pt-2">
          <div class="col-md-4">
            <div class="cds-body2-bold">Campaign Code:</div>
          </div>
          <div class="col-md-8">
            <span class="cds-body2-bold">{{ dbs.campaignCode.value || '-' }}</span>
          </div>
        </div>
        <div class="row l-pt-2">
          <div class="col-md-4">
            <div class="cds-body2-bold">Source Code:</div>
          </div>
          <div class="col-md-8">
            <span class="cds-body2-bold">{{ dbs.sourceCode.value || '-' }}</span>
          </div>
        </div>
      </ng-container>

      <div>
        <div class="row l-pt-2">
          <div class="col-md-4">
            <span class="cds-body2-bold">{{ 'iff-er.secondAgentCode' | lang }} :</span>
          </div>
          <div class="col-md-8">
            <span class="cds-body2-bold">{{ secondAgentInfo?.agentCode || '' }}</span>
            <span clas="l-pl-1"
              ><span class="cds-body2-bold l-ml-1">{{ getAgentName(secondAgentInfo) }}</span></span
            >
          </div>
        </div>
        <div *ngIf="isSecondAgentConduct">
          <div class="l-mt-4">
            <div class="row">
              <div class="col-md-4"></div>
              <div class="col-md-8">
                <div class="l-d-f">
                  <div>
                    <span class="cds-body2-bold">{{ 'iff.splitAgentSelectedContactTypeLabel' | lang }}</span>
                  </div>
                  <span class="l-pl-1"
                    ><span class="cds-body2-bold">{{ secondAgentContactTypeSelected }}</span></span
                  >
                </div>
              </div>
            </div>
            <div *ngIf="secondAgentPreferredLanguage" class="l-mt-2">
              <div class="row">
                <div class="col-md-4"></div>
                <div class="col-md-8">
                  <div class="l-d-f">
                    <div>
                      <span class="cds-body2-bold">{{ 'common.language-of' | lang : { p1: 'sendIffInBatchSummary.2ndMPFIntermediary' | lang } }} : </span>
                    </div>
                    <div class="l-pl-1" *ngIf="secondAgentPreferredLanguage === PreferredLanguageType.Chinese">
                      <span class="cds-body2-bold">{{ 'common.chinese' | lang }}</span>
                    </div>
                    <div class="l-pl-1" *ngIf="secondAgentPreferredLanguage === PreferredLanguageType.English">
                      <span class="cds-body2-bold">{{ 'common.english' | lang }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="l-mt-4">
          <div class="row">
            <div class="col-md-4"></div>
            <div class="col-md-8">
              <app-regulated-activities
                style="width: 100%"
                #regulatedActivities
                [disable]="true"
                [value]="isSecondAgentConduct"
                ngDefaultControl></app-regulated-activities>
            </div>
          </div>
        </div>
      </div>

      <div class="l-mt-5" *ngIf="isSplitThirdAgent">
        <div class="row l-pt-2">
          <div class="col-md-4">
            <span class="cds-body2-bold">{{ 'iff-er.thirdAgentCode' | lang }} :</span>
          </div>
          <div class="col-md-8">
            <span class="cds-body2-bold">{{ thirdAgentInfo?.agentCode || '' }}</span>
            <span clas="l-pl-1"
              ><span class="cds-body2-bold l-ml-1">{{ getAgentName(thirdAgentInfo) }}</span></span
            >
          </div>
        </div>
        <div *ngIf="isThirdAgentConduct">
          <div class="l-mt-4">
            <div class="row">
              <div class="col-md-4"></div>
              <div class="col-md-8">
                <div class="l-d-f">
                  <div>
                    <span class="cds-body2-bold">{{ 'iff.splitAgentSelectedContactTypeLabel' | lang }}</span>
                  </div>
                  <span class="l-pl-1"
                    ><span class="cds-body2-bold">{{ thirdAgentContactTypeSelected }}</span></span
                  >
                </div>
              </div>
            </div>
            <div *ngIf="thirdAgentPreferredLanguage" class="l-mt-2">
              <div class="row">
                <div class="col-md-4"></div>
                <div class="col-md-8">
                  <div class="l-d-f">
                    <div>
                      <span class="cds-body2-bold">{{ 'common.language-of' | lang : { p1: 'sendIffInBatchSummary.3rdMPFIntermediary' | lang } }} : </span>
                    </div>
                    <div class="l-pl-1" *ngIf="thirdAgentPreferredLanguage === PreferredLanguageType.Chinese">
                      <span class="cds-body2-bold">{{ 'common.chinese' | lang }}</span>
                    </div>
                    <div class="l-pl-1" *ngIf="thirdAgentPreferredLanguage === PreferredLanguageType.English">
                      <span class="cds-body2-bold">{{ 'common.english' | lang }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="l-mt-4">
          <div class="row">
            <div class="col-md-4"></div>
            <div class="col-md-8">
              <app-regulated-activities
                style="width: 100%"
                #regulatedActivities
                [disable]="true"
                [value]="isThirdAgentConduct"
                ngDefaultControl></app-regulated-activities>
            </div>
          </div>
        </div>
      </div>

      <div class="row l-pt-5 l-d-f">
        <div class="l-pl-3">
          <cds-button label="{{ 'iff.back' | lang }}" (click)="backToSplitAgentPage()" [config]="commonButton2"></cds-button>
        </div>
        <div class="l-pl-3 l-d-f l-fd-col">
          <cds-button label="{{ 'iff.confirmAndSubmit' | lang }}" (click)="iffSplitAgentSubmission()" [config]="commonButton"></cds-button>
          <div class="l-mt-4">
            <cds-assistive-text type="error">{{ 'sendIffInBatchSummary.noChanges' | lang }}</cds-assistive-text>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
