export enum ScaleType {
  FS_PC = 'FS_PC',
  TRF_SP_PC = 'TRF_SP_PC',
  FY = 'FY',
  FS_SF = 'FS_SF',
  PS = 'PS',
  PS_SF = 'PS_SF',
  PS_PC = 'PS_PC',
  TRF_SP = 'TRF_SP',
  RY_PC = 'RY_PC',
  IND_TRF_SP_PC = 'IND_TRF_SP_PC',
  IND_TRF_SP = 'IND_TRF_SP',
  IND_FS_PC = 'IND_FS_PC',
}

export enum ScalePattern {
  SLIDING = 'SLIDING',
  FLAT = 'FLAT',
}

export enum CalculationType {
  FY = 'FY',
  PH = 'PH',
}

export enum BreakpointType {
  P = 'POLICY_TO_DATE',
  Y = 'YEARLY_RENEWABLE',
}
