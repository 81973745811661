/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Input, OnInit } from '@angular/core';
import { WidgetComponent } from '../widget.component';

enum DeliveryChannel {
  Email = 'Email',
  SMS = 'SMS',
}

enum CampaignType {
  Sale = 'Sale',
  Marketing = 'Marketing',
}

enum PolicyType {
  PA = 'PA',
  CA = 'CA',
}

interface TableData {
  policyNo: string;
  startDate: string;
  endDate: string;
  campaignDescription: string;
  policyType: PolicyType;
  eMPFID: string;
  name: string;
  nameTc: string;
  deliveryChannel: DeliveryChannel;
  campaignType: CampaignType;
}

@Component({
  selector: 'app-widgets-campaign-overview',
  templateUrl: './widgets-campaign-overview.component.html',
  styleUrls: ['./widgets-campaign-overview.component.scss'],
})
export class WidgetsCampaignOverviewComponent implements OnInit, WidgetComponent {
  @Input() data: any;
  DeliveryChannel = DeliveryChannel;

  displayedColumns: string[] = ['policyNo', 'campaignDescription', 'policyType', 'eMPFID', 'deliveryChannel', 'campaignType'];
  tableData: TableData[] = [
    {
      policyNo: '04241777',
      startDate: '15/09/2021',
      endDate: '10/11/2024',
      campaignDescription: 'Lusce in metus risus. Pellentesque est enim, ',
      policyType: PolicyType.PA,
      eMPFID: 'M112431743261',
      name: '陳大文',
      nameTc: 'Chan Tai Man',
      deliveryChannel: DeliveryChannel.Email,
      campaignType: CampaignType.Sale,
    },
    {
      policyNo: '04241777',
      startDate: '15/09/2021',
      endDate: '10/11/2024',
      campaignDescription: 'Lusce in metus risus. Pellentesque est enim, ',
      policyType: PolicyType.PA,
      eMPFID: 'M112431743261',
      name: '陳大文',
      nameTc: 'Chan Tai Man',
      deliveryChannel: DeliveryChannel.SMS,
      campaignType: CampaignType.Sale,
    },
    {
      policyNo: '04241777',
      startDate: '15/09/2021',
      endDate: '10/11/2024',
      campaignDescription: 'Lusce in metus risus. Pellentesque est enim, ',
      policyType: PolicyType.PA,
      eMPFID: 'M112431743261',
      name: '陳大文',
      nameTc: 'Chan Tai Man',
      deliveryChannel: DeliveryChannel.Email,
      campaignType: CampaignType.Sale,
    },
    {
      policyNo: '04241777',
      startDate: '15/09/2021',
      endDate: '10/11/2024',
      campaignDescription: 'Lusce in metus risus. Pellentesque est enim, ',
      policyType: PolicyType.PA,
      eMPFID: 'M112431743261',
      name: '陳大文',
      nameTc: 'Chan Tai Man',
      deliveryChannel: DeliveryChannel.SMS,
      campaignType: CampaignType.Sale,
    },
    {
      policyNo: '04241777',
      startDate: '15/09/2021',
      endDate: '10/11/2024',
      campaignDescription: 'Lusce in metus risus. Pellentesque est enim, ',
      policyType: PolicyType.PA,
      eMPFID: 'M112431743261',
      name: '陳大文',
      nameTc: 'Chan Tai Man',
      deliveryChannel: DeliveryChannel.Email,
      campaignType: CampaignType.Sale,
    },
  ];

  constructor() {}

  ngOnInit(): void {}
}
