import { trigger, state, style, transition, animate } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';
import { CdsIconConfig } from '@cds/ng-core/icon';
import { TerminationRecord } from '../../termination/termination';
import { AgentPerformanceService } from '../agent-performance.service';

@Component({
  selector: 'app-expand-item-mobile',
  templateUrl: './expand-item-mobile.component.html',
  styleUrls: ['./expand-item-mobile.component.scss'],
  animations: [
    trigger('openClose', [
      state(
        'detail-expaned',
        style({
          height: '*',
          paddingTop: 'var(--cds-spacing-03)',
          opacity: '1',
        })
      ),
      state(
        'detail-collapsed',
        style({
          height: '0px',
          overflow: 'hidden',
          opacity: '0',
        })
      ),
      state(
        'expanded',
        style({
          rotate: '90deg',
        })
      ),
      state(
        'collapsed',
        style({
          rotate: '-90deg',
        })
      ),
      transition('detail-expaned <=> detail-collapsed', [animate('0.3s ease-in-out')]),
      transition('expanded <=> collapsed', [animate('0.3s')]),
    ]),
  ],
})
export class ExpandItemMobileComponent implements OnInit {
  constructor(public service: AgentPerformanceService) {}

  @Input() expand = false;
  @Input() icon = 'action:button_right';
  @Input() color = '';
  @Input() mouseColor = '#EC6453';
  @Input() size = '32px';
  @Input() data: TerminationRecord = {};

  _config: CdsIconConfig = {
    color: this.color,
  };
  _iconClass = 'collapsed';
  _detailClass = 'detail-collapsed';

  ngOnInit(): void {}

  trigger() {
    this.expand = !this.expand;
    this._detailClass = this.expand ? 'detail-expaned' : 'detail-collapsed';
    this._iconClass = this.expand ? 'expanded' : 'collapsed';
  }
}
