import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { CdsIconConfig } from '@cds/ng-core/icon';
import { ActionIcons } from '@cds/ng-themes';
import { LeastOneWarningComponent } from './least-one-warning/least-one-warning.component';
import { MatDialogRef } from '@angular/material/dialog';
import { CdsPopupService } from '@cds/ng-web-components/popup';
import { WillBeLostComponent } from 'src/app/shared/will-be-lost/will-be-lost.component';
import { AddNewCommissionPopupComponent } from './add-new-commission-popup/add-new-commission-popup.component';
import { AbstractControl, FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { CdsRadioConfig } from '@cds/ng-core/radio';
import { from, switchMap } from 'rxjs';
import { getSessionByKey } from 'src/app/utils/indexdb';
import { CaseDetailsRsp } from '../case-details/case-details.model';
import { CdHttpServeService } from 'src/app/shared/cd-http-serve/cd-http-serve.service';
import { CdsAlertService } from '@cds/ng-web-components/alert';
import { CdsLangService } from '@cds/ng-core/lang';
import { UpdatedSuccessfullyComponent } from 'src/app/shared/updated-successfully/updated-successfully.component';
import { keepDecimalDigitFull, pageToTop } from 'src/app/utils/utils';
import { NotifyService } from 'src/app/core/services/notify.service';
import { LanguageChangeService } from 'src/app/shared/language-change.service';
import { ErCaseDetailsRsp } from '../case-details-er/case-details-er.model';
import { SalesjourneyService } from '../salesjourney.service';
import { CasesummaryService } from '../case-summary/casesummary.service';

@Component({
  selector: 'app-edit-agent-details',
  templateUrl: './edit-agent-details.component.html',
  styleUrls: ['./edit-agent-details.component.scss'],
})
export class EditAgentDetailsComponent implements OnInit, AfterViewInit, OnDestroy {
  isConfirming = false;
  backIconConfig: CdsIconConfig = {
    color: 'cta',
    size: 'sm',
  };
  backIcon = ActionIcons.button_left_filled;
  targetType?: 'EE' | 'ER';
  form = new FormGroup({
    agentList: new FormArray([]),
  });
  servicingAgentRadioConfig: CdsRadioConfig = {
    options: [{ label: '', value: true }],
  };
  isErrPercentage100 = false;
  isErrEquallySplit = false;
  isErrSmallerOne = false;

  private errPercentage100Values: string[] = [];
  get errPercentage100() {
    return `The ${this.errPercentage100Values.join(', ')} percentage must add up to 100% in total.`;
  }
  private errPercentage100SubValues: string[] = [];
  get errPercentage100Sub() {
    return `Please check the distribution of ${this.errPercentage100SubValues.join(', ')}`;
  }

  private errEquallySplitValues: string[] = [];
  get errEquallySplit() {
    return `The ${this.errEquallySplitValues.join(', ')} percentage of the servicing agent and split agent must be equally split.`;
  }
  private errEquallySplitSubValues: string[] = [];
  get errEquallySplitSub() {
    return `Please check the distribution of ${this.errEquallySplitSubValues.join(', ')}`;
  }

  salesJourneySummariesId?: string;
  caseDetails?: CaseDetailsRsp;
  erCaseDetails?: ErCaseDetailsRsp;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public caseSummaryService: CasesummaryService,
    private cdsPopup: CdsPopupService,
    private fb: FormBuilder,
    private cdHttpServeService: CdHttpServeService,
    private alert: CdsAlertService,
    public cdsLangService: CdsLangService,
    private location: Location,
    private notifyService: NotifyService,
    private languageChangeService: LanguageChangeService,
    private salesjourneyService: SalesjourneyService
  ) {}

  get agentList() {
    return this.form.controls['agentList'] as FormArray;
  }

  private resizeListener = () => {
    const isMobile = window.matchMedia('only screen and (max-width: 1023px)').matches || window.screen.width < 1023;
    if (isMobile) {
      this.notifyService.notifyHideNavigationLang();
    }
  };

  ngAfterViewInit(): void {
    pageToTop();
    this.notifyService.notifyHideNavigationLang();
    window.addEventListener('resize', this.resizeListener);
    this.languageChangeService.setLang('en');
  }

  ngOnDestroy(): void {
    this.notifyService.notifyDisplayNavigationLang();
    window.removeEventListener('resize', this.resizeListener);
  }

  ngOnInit(): void {
    if (this.disableEditAgentByChannel()) {
      this.router.navigate(['/permission-error']);
      return;
    }

    this.salesJourneySummariesId = this.route.snapshot.paramMap.get('salesJourneySummariesId') || undefined;
    this.targetType = (this.route.snapshot.paramMap.get('type') as 'EE' | 'ER') || undefined;

    if (this.salesJourneySummariesId) {
      this.notifyService.screenLoadingSubject.next(true);
      if (this.targetType === 'EE') {
        from(getSessionByKey())
          .pipe(
            switchMap(res => {
              const identityId = res.id;
              return this.cdHttpServeService.post<CaseDetailsRsp>('/ext/eb-ssms-sales-journey-service/memberSalesJourneySummaries/caseDetails', {
                salesJourneySummaryId: this.salesJourneySummariesId,
                identityId,
              });
            })
          )
          .subscribe({
            next: this.postMemberCaseDetailsCallBack,
            error: err => {
              this.alert.error('Error!', err);
              this.notifyService.screenLoadingSubject.next(false);
            },
          });
      } else if (this.targetType === 'ER') {
        from(getSessionByKey())
          .pipe(
            switchMap(res => {
              const identityId = res.id;
              return this.cdHttpServeService.post<ErCaseDetailsRsp>('/ext/eb-ssms-sales-journey-service/employerSalesJourneySummaries/caseDetails', {
                salesJourneySummaryId: this.salesJourneySummariesId,
                identityId,
              });
            })
          )
          .subscribe({
            next: this.postEmployerCaseDetailsCallBack,
            error: err => {
              this.alert.error('Error!', err);
              this.notifyService.screenLoadingSubject.next(false);
            },
          });
      }
    }
  }

  postEmployerCaseDetailsCallBack = (res: { result: number; message: string; data: ErCaseDetailsRsp }) => {
    if (res.result !== 0) {
      this.alert.warning('Warning!', `${res.message}`);
    } else {
      this.form = new FormGroup({
        agentList: new FormArray([]),
      });
      let isAlreadyHaveServicingAgent = false;
      this.erCaseDetails = res.data;
      const servicingAgentIndex = this.erCaseDetails.agentsInfos.findIndex(item => item.servicingAgent);
      const servicingAgent = this.erCaseDetails.agentsInfos[servicingAgentIndex];
      this.erCaseDetails.agentsInfos.splice(servicingAgentIndex, 1);
      this.erCaseDetails.agentsInfos.unshift(servicingAgent);
      this.erCaseDetails.agentsInfos.forEach(agent => {
        this.agentList.push(
          this.fb.group({
            agentId: this.fb.control(agent.agentId),
            agentCd: this.fb.control(agent.agentCd),
            agentName: this.fb.control(agent.agentName),
            agentNameChn: this.fb.control(agent.agentNameChn),
            servicingAgent: this.fb.control(isAlreadyHaveServicingAgent ? false : agent.servicingAgent),
            shareHKPC: this.fb.control(agent.hkpcPercentage ? keepDecimalDigitFull(agent.hkpcPercentage, 3) + '' : ''),
            shareCommission: this.fb.control(agent.commissionPercentage ? keepDecimalDigitFull(agent.commissionPercentage, 3) + '' : ''),
          })
        );
        if (agent.servicingAgent) {
          isAlreadyHaveServicingAgent = true;
        }
      });
    }
    this.notifyService.screenLoadingSubject.next(false);
  };

  postMemberCaseDetailsCallBack = (res: { result: number; message: string; data: CaseDetailsRsp }) => {
    if (res.result !== 0) {
      this.alert.warning('Warning!', `${res.message}`);
    } else {
      this.form = new FormGroup({
        agentList: new FormArray([]),
      });
      let isAlreadyHaveServicingAgent = false;
      this.caseDetails = res.data;
      const servicingAgentIndex = this.caseDetails.agentsInfos.findIndex(item => item.servicingAgent);
      const servicingAgent = this.caseDetails.agentsInfos[servicingAgentIndex];
      this.caseDetails.agentsInfos.splice(servicingAgentIndex, 1);
      this.caseDetails.agentsInfos.unshift(servicingAgent);
      this.caseDetails.agentsInfos.forEach(agent => {
        this.agentList.push(
          this.fb.group({
            agentId: this.fb.control(agent.agentId),
            agentCd: this.fb.control(agent.agentCd),
            agentName: this.fb.control(agent.agentName),
            agentNameChn: this.fb.control(agent.agentNameChn),
            servicingAgent: this.fb.control(isAlreadyHaveServicingAgent ? false : agent.servicingAgent),
            shareHKPC: this.fb.control(agent.hkpcPercentage ? keepDecimalDigitFull(agent.hkpcPercentage, 3) + '' : ''),
            shareCommission: this.fb.control(agent.commissionPercentage ? keepDecimalDigitFull(agent.commissionPercentage, 3) + '' : ''),
          })
        );
        if (agent.servicingAgent) {
          isAlreadyHaveServicingAgent = true;
        }
      });
    }
    this.notifyService.screenLoadingSubject.next(false);
  };

  onBack() {
    if (this.isConfirming) {
      return;
    }
    this.location.back();
  }

  servicingAgentChange(index: number, current: AbstractControl) {
    if (this.asFormGroup(current).controls['servicingAgent'].value === false) {
      return;
    }

    let i = 0;
    for (const agent of this.agentList.controls) {
      if (i !== index) {
        this.asFormGroup(agent).controls['servicingAgent'].setValue(false);
      }
      i++;
    }
  }

  reset() {
    const popupRef: MatDialogRef<WillBeLostComponent> = this.cdsPopup.open(WillBeLostComponent, {
      size: 'md',
    });

    popupRef.afterClosed().subscribe(confirm => {
      if (confirm.agree) {
        this.form = new FormGroup({
          agentList: new FormArray([]),
        });
        let isAlreadyHaveServicingAgent = false;
        if (this.targetType === 'EE') {
          this.caseDetails?.agentsInfos.forEach(agent => {
            this.agentList.push(
              this.fb.group({
                agentId: this.fb.control(agent.agentId),
                agentCd: this.fb.control(agent.agentCd),
                agentName: this.fb.control(agent.agentName),
                agentNameChn: this.fb.control(agent.agentNameChn),
                servicingAgent: this.fb.control(isAlreadyHaveServicingAgent ? false : agent.servicingAgent),
                shareHKPC: this.fb.control(agent.hkpcPercentage ? keepDecimalDigitFull(agent.hkpcPercentage, 3) + '' : ''),
                shareCommission: this.fb.control(agent.commissionPercentage ? keepDecimalDigitFull(agent.commissionPercentage, 3) + '' : ''),
              })
            );
            if (agent.servicingAgent) {
              isAlreadyHaveServicingAgent = true;
            }
          });
        } else if (this.targetType === 'ER') {
          this.erCaseDetails?.agentsInfos.forEach(agent => {
            this.agentList.push(
              this.fb.group({
                agentId: this.fb.control(agent.agentId),
                agentCd: this.fb.control(agent.agentCd),
                agentName: this.fb.control(agent.agentName),
                agentNameChn: this.fb.control(agent.agentNameChn),
                servicingAgent: this.fb.control(isAlreadyHaveServicingAgent ? false : agent.servicingAgent),
                shareHKPC: this.fb.control(agent.hkpcPercentage ? keepDecimalDigitFull(agent.hkpcPercentage, 3) + '' : ''),
                shareCommission: this.fb.control(agent.commissionPercentage ? keepDecimalDigitFull(agent.commissionPercentage, 3) + '' : ''),
              })
            );
            if (agent.servicingAgent) {
              isAlreadyHaveServicingAgent = true;
            }
          });
        }
        this.isErrEquallySplit = false;
        this.isErrPercentage100 = false;
      }
    });
  }

  backToEdit() {
    this.isConfirming = false;
  }

  confirm() {
    const agentInfos = [];
    for (const agent of this.agentList.controls) {
      agentInfos.push({
        agentId: this.asFormGroup(agent).controls['agentId'].value,
        agentCd: this.asFormGroup(agent).controls['agentCd'].value,
        servicingAgent: this.asFormGroup(agent).controls['servicingAgent'].value,
        agentCommissionPt: +this.asFormGroup(agent).controls['shareCommission'].value,
        agentPcPt: +this.asFormGroup(agent).controls['shareHKPC'].value,
      });
    }

    if (this.salesJourneySummariesId) {
      const params = {
        salesJourneySummaryId: this.salesJourneySummariesId,
        empfRefNo: this.targetType === 'EE' ? this.caseDetails?.empfRefNo : this.erCaseDetails?.empfRefNo,
        agentInfos: agentInfos,
      };
      this.notifyService.screenLoadingSubject.next(true);
      this.cdHttpServeService.post('/ext/eb-ssms-sales-journey-service/commissionAgentAppeal/update', params).subscribe({
        next: res => {
          if (res.result !== 0) {
            this.alert.warning('Warning!', `${res.message}`);
          } else {
            const ref = this.cdsPopup.open(UpdatedSuccessfullyComponent, {
              size: 'md',
            });
            ref.afterClosed().subscribe(() => {
              this.location.back();
            });
          }
          this.notifyService.screenLoadingSubject.next(false);
        },
        error: err => {
          this.alert.error('Error!', err);
          this.notifyService.screenLoadingSubject.next(false);
        },
      });
    }
  }

  clearErrPercentage100() {
    this.isErrPercentage100 = false;

    if (!this.isErrEquallySplit && !this.isErrSmallerOne) {
      for (const agent of this.agentList.controls) {
        this.asFormGroup(agent).controls['shareHKPC'].setErrors(null);
        this.asFormGroup(agent).controls['shareCommission'].setErrors(null);
      }
    }
  }

  clearErrEquallySplit() {
    this.isErrEquallySplit = false;

    if (!this.isErrPercentage100 && !this.isErrSmallerOne) {
      for (const agent of this.agentList.controls) {
        this.asFormGroup(agent).controls['shareHKPC'].setErrors(null);
        this.asFormGroup(agent).controls['shareCommission'].setErrors(null);
      }
    }
  }

  clearErrSmallerOne() {
    this.isErrSmallerOne = false;

    if (!this.isErrPercentage100 && !this.isErrEquallySplit) {
      for (const agent of this.agentList.controls) {
        this.asFormGroup(agent).controls['shareHKPC'].setErrors(null);
        this.asFormGroup(agent).controls['shareCommission'].setErrors(null);
      }
    }
  }

  save() {
    if (this.isDisableSave) {
      return;
    }

    this.errPercentage100Values = [];
    this.errPercentage100SubValues = [];
    this.errEquallySplitValues = [];
    this.errEquallySplitSubValues = [];
    let sumShareHKPC = 0;
    let sumShareCommission = 0;
    this.isErrEquallySplit = false;
    this.isErrPercentage100 = false;
    this.isErrSmallerOne = false;
    for (const agent of this.agentList.controls) {
      if (this.asFormGroup(agent).controls['shareHKPC'].value === '') {
        this.asFormGroup(agent).controls['shareHKPC'].setValue('0.000');
      }
      if (this.asFormGroup(agent).controls['shareCommission'].value === '') {
        this.asFormGroup(agent).controls['shareCommission'].setValue('0.000');
      }
      const shareHKPCVal = +this.asFormGroup(agent).controls['shareHKPC'].value;
      sumShareHKPC += shareHKPCVal;
      const shareCommissionVal = +this.asFormGroup(agent).controls['shareCommission'].value;
      sumShareCommission += shareCommissionVal;

      if (shareHKPCVal < 1) {
        this.isErrSmallerOne = true;
        this.asFormGroup(agent).controls['shareHKPC'].markAsDirty();
        this.asFormGroup(agent).controls['shareHKPC'].setErrors({ format: true });
      }
      if (shareCommissionVal < 1) {
        this.isErrSmallerOne = true;
        this.asFormGroup(agent).controls['shareCommission'].markAsDirty();
        this.asFormGroup(agent).controls['shareCommission'].setErrors({ format: true });
      }
    }
    if (sumShareHKPC !== 100) {
      this.isErrPercentage100 = true;
      for (const agent of this.agentList.controls) {
        this.asFormGroup(agent).controls['shareHKPC'].markAsDirty();
        this.asFormGroup(agent).controls['shareHKPC'].setErrors({ format: true });
      }
      this.errPercentage100Values.push('Share PC');
      this.errPercentage100SubValues.push('hkpc%');
    }
    if (sumShareCommission !== 100) {
      this.isErrPercentage100 = true;
      for (const agent of this.agentList.controls) {
        this.asFormGroup(agent).controls['shareCommission'].markAsDirty();
        this.asFormGroup(agent).controls['shareCommission'].setErrors({ format: true });
      }
      this.errPercentage100Values.push('Share commission');
      this.errPercentage100SubValues.push('commission%');
    }
    if (this.agentList.controls.length >= 2) {
      if (this.targetType === 'EE') {
        if (
          sumShareHKPC === 100 &&
          ((this.agentList.controls[0] as FormGroup).controls['shareHKPC'].value !== '50.000' ||
            (this.agentList.controls[1] as FormGroup).controls['shareHKPC'].value !== '50.000')
        ) {
          this.isErrEquallySplit = true;
          this.errEquallySplitValues.push('Share PC');
          this.errEquallySplitSubValues.push('hkpc%');
          for (const agent of this.agentList.controls) {
            this.asFormGroup(agent).controls['shareHKPC'].markAsDirty();
            this.asFormGroup(agent).controls['shareHKPC'].setErrors({ format: true });
          }
        }
        if (
          sumShareCommission === 100 &&
          ((this.agentList.controls[0] as FormGroup).controls['shareCommission'].value !== '50.000' ||
            (this.agentList.controls[1] as FormGroup).controls['shareCommission'].value !== '50.000')
        ) {
          this.isErrEquallySplit = true;
          this.errEquallySplitValues.push('Share commission');
          this.errEquallySplitSubValues.push('commission%');
          for (const agent of this.agentList.controls) {
            this.asFormGroup(agent).controls['shareCommission'].markAsDirty();
            this.asFormGroup(agent).controls['shareCommission'].setErrors({ format: true });
          }
        }
      }
    }

    if (this.isErrEquallySplit) {
      return;
    }
    if (this.isErrPercentage100) {
      return;
    }
    if (this.isErrSmallerOne) {
      return;
    }

    this.isConfirming = true;
    pageToTop();
  }

  delete(index: number) {
    if (this.agentList.length <= 1) {
      this.cdsPopup.open(LeastOneWarningComponent, {
        size: 'md',
      });
      return;
    }

    const isDeleted = this.asFormGroup(this.agentList.controls[index]).controls['servicingAgent'].value === true;
    this.agentList.removeAt(index);
    if (isDeleted) {
      setTimeout(() => {
        this.asFormGroup(this.agentList.at(0)).controls['servicingAgent'].setValue(true);
      }, 0);
    }
  }

  get isDisableAddNewSplitAgent() {
    if (this.isConfirming) {
      return true;
    }
    if (this.targetType === 'EE') {
      return this.agentList.length >= 2;
    } else {
      return this.agentList.length >= 10;
    }
  }

  addNewSplitAgent() {
    if (this.isDisableAddNewSplitAgent) {
      return;
    }

    const agentCodeList = [];
    for (const agent of this.agentList.controls) {
      agentCodeList.push(this.asFormGroup(agent).controls['agentCd'].value);
    }

    const popupRef: MatDialogRef<AddNewCommissionPopupComponent> = this.cdsPopup.open(AddNewCommissionPopupComponent, {
      size: 'md',
      data: {
        agentCodeList: agentCodeList,
      },
    });

    popupRef.afterClosed().subscribe(confirm => {
      if (confirm.agree) {
        this.agentList.push(
          this.fb.group({
            agentId: this.fb.control(confirm.agent.agentId),
            agentCd: this.fb.control(confirm.agent.agentCode),
            agentName: this.fb.control(confirm.agent.name),
            agentNameChn: this.fb.control(confirm.agent.chineseName),
            servicingAgent: this.fb.control(false),
            shareHKPC: this.fb.control(''),
            shareCommission: this.fb.control(''),
          })
        );
      }
    });
  }

  asFormGroup(agent: AbstractControl) {
    return agent as FormGroup;
  }

  getAgentNameFormControl(agent: AbstractControl) {
    const agentName = this.asFormGroup(agent).controls['agentName'].value;
    const agentNameChn = this.asFormGroup(agent).controls['agentNameChn'].value;

    if (this.cdsLangService.currentLang === 'en') {
      return agentName || agentNameChn;
    } else {
      return agentNameChn || agentName;
    }
  }

  shareHKPCBlur(agent: AbstractControl) {
    if (this.asFormGroup(agent).controls['shareHKPC'].value !== '') {
      let shareHKPC = String(Math.round(+this.asFormGroup(agent).controls['shareHKPC'].value * 1000) / 1000);

      if (shareHKPC.includes('.')) {
        if (shareHKPC.length - 1 - shareHKPC.indexOf('.') < 3) {
          const needN = 3 - (shareHKPC.length - 1 - shareHKPC.indexOf('.'));
          for (let i = 0; i < needN; i++) {
            shareHKPC += '0';
          }
        }
      } else {
        shareHKPC += '.000';
      }

      this.asFormGroup(agent).controls['shareHKPC'].setValue(shareHKPC);
      if (this.asFormGroup(agent).controls['shareCommission'].value === '') {
        this.asFormGroup(agent).controls['shareCommission'].setValue(shareHKPC);
      }
    }
  }

  shareCommissionBlur(agent: AbstractControl) {
    if (this.asFormGroup(agent).controls['shareCommission'].value !== '') {
      let shareCommission = String(Math.round(+this.asFormGroup(agent).controls['shareCommission'].value * 1000) / 1000);

      if (shareCommission.includes('.')) {
        if (shareCommission.length - 1 - shareCommission.indexOf('.') < 3) {
          const needN = 3 - (shareCommission.length - 1 - shareCommission.indexOf('.'));
          for (let i = 0; i < needN; i++) {
            shareCommission += '0';
          }
        }
      } else {
        shareCommission += '.000';
      }

      this.asFormGroup(agent).controls['shareCommission'].setValue(shareCommission);
      if (this.asFormGroup(agent).controls['shareHKPC'].value === '') {
        this.asFormGroup(agent).controls['shareHKPC'].setValue(shareCommission);
      }
    }
  }

  get isDisableSave() {
    return !this.form.valid;
  }

  isShowItemWarning(agent: AbstractControl) {
    return (
      this.targetType === 'ER' &&
      this.isConfirming &&
      this.asFormGroup(agent).controls['shareHKPC'].value !== this.asFormGroup(agent).controls['shareCommission'].value
    );
  }

  disableEditAgentByChannel() {
    return this.salesjourneyService.isScbBrokerCorporateChannel();
  }
}
