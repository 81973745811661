<cds-popup>
  <cds-popup-title
    ><div class="font-area">
      {{ 'notification.popup.title1' | lang }}
      <div class="special-font">&nbsp;{{ 'notification.popup.title2' | lang }} &nbsp;</div>
      {{ 'notification.popup.title3' | lang }} {{ data.name }}?
    </div></cds-popup-title
  >
  <cds-popup-content> *{{ 'notification.popup.content' | lang }} </cds-popup-content>
  <cds-popup-actions>
    <cds-button label="{{ 'confirm.CancelButton' | lang }}" [config]="agreeButtonConfig" (click)="agree()"></cds-button>
    <cds-button [config]="disagreeButtonConfig" (click)="disagree()" label="{{ 'confirm.confirmBotton' | lang }}"></cds-button>
  </cds-popup-actions>
</cds-popup>
