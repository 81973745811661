import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';

import { PermissionService } from 'src/app/core/services/permission.service';

@Component({
  selector: 'app-permission',
  templateUrl: './permission.component.html',
  styleUrls: ['./permission.component.scss'],
})
export class PermissionComponent implements OnInit {
  show = false;
  @Input() role = '';
  @Input() permission = '';

  constructor(private permissionService: PermissionService, private ref: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.getPermission();
  }

  getPermission() {
    this.permissionService.hasPermission(this.role, this.permission).then(hasPermission => {
      this.show = hasPermission;
      this.ref.detectChanges();
    });
  }
}
