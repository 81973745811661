import { EventEmitter, OnChanges, Output, SimpleChanges } from '@angular/core';
/* eslint-disable @typescript-eslint/no-explicit-any */

import { Component, Input, OnInit } from '@angular/core';
import { CdsPopupService } from '@cds/ng-web-components/popup';
import { finalize } from 'rxjs';
import { ToastAlertService } from 'src/app/shared/toast-alert.service';
import {
  RelatedPersonTypeE,
  ContactPersonView,
  Phone,
  PhoneTypeE,
  PhoneType,
  SchemeInfo,
  CommunicationTypeE,
  communicationTypeMapObj,
  LanguageTypeE,
  languageTypeMapObj,
  inValidStatusMapObj,
  AddressDTO,
} from '../../employer/employer';
import { ContactStatusE } from '../../employer/employer-profile-company-list/employer-profile-company-list';
import { AddressTypeDefinitionPopupComponent } from '../address-type-definition-popup/address-type-definition-popup.component';
import { Contact, ContactInfo } from '../employee';
import { EmployeeService } from '../employee.service';
import { deepCopy } from 'src/app/utils/copy';
// import { ContactStatusE } from '../../../employer-profile-company-list/employer-profile-company-list';
// import { EmployerService } from '../../../employer.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact-persion.component.html',
  styleUrls: ['./contact-persion.component.scss'],
})
export class ContactComponent implements OnInit, OnChanges {
  @Input() id = '';
  @Output() gainData = new EventEmitter();

  RelatedPersonTypeE = RelatedPersonTypeE;

  updateDate = new Date();

  ContactStatusE = ContactStatusE;

  emailDataSource = [
    {
      email: 'abc@abc.com',
      updateDate: this.updateDate,
      status: 'ACTIVE',
    },
  ];

  phoneDataSource = [
    {
      type: 'Mobile',
      number: '(+852) 6123 4567',
      ext: '120',
      updateDate: this.updateDate,
      status: 'ACTIVE',
    },
    {
      type: 'Secondary Mobile',
      number: '(+852) 6123 4567',
      ext: '120',
      updateDate: this.updateDate,
      status: 'ACTIVE',
    },
    {
      type: 'Claimant Overseas Telephone',
      number: '(+852) 6123 4567',
      ext: '120',
      updateDate: this.updateDate,
      status: 'INVALID',
    },
  ];

  addressDataSource = [
    {
      type: 'Fax No.',
      address: 'Flat 11, 10/F, Block A, ABC Building, ABC Street, Tsing Yi, Hong Kong',
      ext: '120',
      updateDate: this.updateDate,
      status: 'ACTIVE',
    },
    {
      type: 'Claimant Overseas Telephone',
      address: 'Flat 13, 10/F, Block A, ABC Building, ABC Street, Tsing Yi, Hong Kong',
      ext: '',
      updateDate: this.updateDate,
      status: 'ACTIVE',
    },
  ];

  phoneDisplayedColumns = ['type', 'number', 'ext', 'updateDateAndStatus'];
  emailDisplayedColumns = ['email', 'updateDateAndStatus'];
  addressDisplayedColumns = ['address', 'type', 'updateDateAndStatus'];

  inValidStatusMapObj = inValidStatusMapObj;

  isLoading = false;

  data: ContactInfo = {
    phoneList: [],
    emailList: [],
    addressList: [],
  };

  constructor(public service: EmployeeService, private cdsPopup: CdsPopupService, private toastAlert: ToastAlertService) {}

  @Input() schemeInfo?: SchemeInfo;

  contactPersonView: ContactPersonView = {};

  Object = Object;

  PhoneTypeE = PhoneTypeE;

  ngOnInit(): void {
    // this.getDetail();
  }

  getDetail() {
    if (!this.id) return;
    this.isLoading = true;
    this.service
      .getContactInfo(this.id)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe(res => {
        if (res.result === 0 && res.data) {
          this.data = res.data;
          this.gainData.emit(this.data);
        } else {
          this.toastError(res.message);
        }
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['id'].currentValue && changes['id'].currentValue !== changes['id'].previousValue) {
      this.getDetail();
    }
  }

  toastError(errorMessage: string) {
    this.toastAlert.show('error', 'common.error', errorMessage, 5000);
  }

  getRelatedPersonType(type: RelatedPersonTypeE) {
    return RelatedPersonTypeE[type as unknown as keyof typeof RelatedPersonTypeE];
  }

  isLast(i: number, list: any[]) {
    const lastIndex = list.length - 1;
    return i === lastIndex;
  }

  phoneGenerate(phone: Phone) {
    return `(+${phone.countryCode}) ${phone.phoneNo} ${phone.phoneExt ? `; ext. ${phone.phoneExt}` : ''}`;
  }

  dataTrans() {
    const contactPersonListView: ContactPersonView[] = [];
    this.schemeInfo?.contactPersonList.forEach(item => {
      const phoneList = item.phoneList;
      const telephone = phoneList?.filter(item => item.typeCode === 'PHONE');
      const mobile = phoneList?.filter(item => item.typeCode === 'MOBILE');
      const mobile2 = phoneList?.filter(item => item.typeCode === 'MOBILE2');
      const claimantOverseasTelephone = phoneList?.filter(item => item.typeCode === 'TEL_OVERSEAS');
      const claimantOverseasFaxno = phoneList?.filter(item => item.typeCode === 'FAX_OVERSEAS');
      const faxNo = phoneList?.filter(item => item.typeCode === 'FAX');

      const contactPersonView: ContactPersonView = {
        email: item.emailList,
        PHONE: telephone,
        MOBILE: mobile,
        MOBILE2: mobile2,
        TEL_OVERSEAS: claimantOverseasTelephone,
        FAX_OVERSEAS: claimantOverseasFaxno,
        FAX: faxNo,
      };

      contactPersonListView.push(contactPersonView);
    });
    // this.contactPersonListView = contactPersonListView;
  }

  getContactTypeStr(key: string) {
    if (key === 'email') {
      return 'Email';
    } else {
      return PhoneTypeE[key as PhoneType] || '';
    }
  }

  phoneTypeRender(type: PhoneType) {
    return PhoneTypeE[type];
  }

  fullPhoneNoRender(contryCode: string, phoneNo: string) {
    return contryCode ? `(+${contryCode})${phoneNo}` : phoneNo;
  }

  typePopup() {
    this.cdsPopup.open(AddressTypeDefinitionPopupComponent, {
      size: 'sm',
    });
  }

  communicationTypeRender(type: CommunicationTypeE) {
    return communicationTypeMapObj[type] || '';
  }

  languageTypeRender(type: LanguageTypeE) {
    return languageTypeMapObj[type] || '';
  }

  getPhoneOrFaxList(list: Phone[], phoneMode = true) {
    return list.filter(item => {
      const isFax = ['FAX_OVERSEAS', 'FAX'].indexOf(item.typeCode) > -1;
      return phoneMode ? !isFax : isFax;
    });
  }

  addressRender(address: AddressDTO) {
    let list = [address.room, address.floor, address.block, address.building, address.street, address.district, address.city];

    if (address.zhAddrFlg === 'N') {
      list.push(address.country);
    } else {
      if (address.formatTyp === 'STD') {
        list.reverse();
      }
      list.unshift(address.country);
    }

    list = list.filter(item => !!item);

    return list.join(address.zhAddrFlg === 'Y' && address.formatTyp === 'STD' ? '' : ', ');
  }

  contactSort(list: Contact[]) {
    const sortedList = deepCopy(list);
    sortedList.sort((a: Contact, b: Contact) => {
      const dateA: Date = new Date(a.lastUpdate);
      const dateB: Date = new Date(b.lastUpdate);
      if (dateA < dateB) {
        return 1;
      } else if (dateA > dateB) {
        return -1;
      } else {
        return 0;
      }
    });
    const activeList = sortedList.filter((item: Contact) => item.status === 'ACTIVE');
    const invalidList = sortedList.filter((item: Contact) => item.status === 'INVALID');
    const othersList = sortedList.filter((item: Contact) => item.status !== 'INVALID' && item.status !== 'ACTIVE');
    return [...activeList, ...invalidList, ...othersList];
  }

  getLanguageType(item: string | undefined) {
    switch (item) {
      case 'EN':
        return 'English';
      case 'ZH-HK':
        return 'Traditional Chinese';
      case 'ZH-CN':
        return 'Simplified Chinese';
      case 'BI':
        return 'Bilingual';
      default:
        return item;
    }
  }
}
