<div class="cb-chatbot" *ngIf="isShowChatBot">
  <div class="cb-chatbot-header">
    <div class="cb-chatbot-header-start">
      <div class="cb-bot-icon cb-chatbot-small">
        <svg width="21" height="20" class="cb-bot">
          <use xlink:href="./assets/images/sprite.svg#cb-bot"></use>
        </svg>
      </div>
      <div class="cb-chatbot-header-meta">
        <h2 [ngStyle]="{ height: roomInfo?.group_no ? '15px' : 'auto' }">iPension chatbot</h2>
        <span
          >{{ roomInfo?.group_no && roomInfo?.group_no != 'null' ? roomInfo?.group_no + ' -' : '' }}
          {{ roomInfo?.cert_no && roomInfo?.cert_no != 'null' ? roomInfo?.cert_no : '' }}</span
        >
      </div>
    </div>
    <div class="cb-close-chatbot" (click)="onHideChatbot()">
      <svg width="44" height="43" class="cb-close">
        <use xlink:href="./assets/images/sprite.svg#cb-close"></use>
      </svg>
    </div>
  </div>
  <div class="cb-chatbot-body">
    <!-- Start Connection Failed -->
    <div class="cb-no-internet" *ngIf="noCsoAvailable">
      <svg width="185" height="185" class="cb-signals">
        <use xlink:href="./assets/images/sprite.svg#cb-signals"></use>
      </svg>
      <h2>Connection Failed</h2>
      <p>
        All CSOs are currently busy. <br />
        Please try again later.
      </p>
      <p style="padding: 20px 40px">
        You can send your question to
        <a href="mailto:doc@manulife.com"
          ><b>{{ 'doc@manulife.com' }}</b></a
        >
        and our CSOs will reply to you within 2 working days.
      </p>

      <div>
        <a href="mailto:doc@manulife.com">
          <button class="cb-btn" style="margin-right: 10px; background: green; border-radius: 10px; border: none">Email now</button>
        </a>
        <button class="cb-btn" (click)="exitChat()" style="margin-right: 10px; background: rgb(230, 59, 59); border-radius: 10px; border: none">
          Exit Chat
        </button>
      </div>
    </div>

    <div class="cb-no-internet" *ngIf="allCsobusy">
      <svg width="185" height="185" class="cb-signals">
        <use xlink:href="./assets/images/sprite.svg#cb-signals"></use>
      </svg>
      <h2>Session Full</h2>
      <p>Please come back in 30 mins<br /></p>
      <p style="padding: 20px 40px">
        You can send your question to
        <a href="mailto:doc@manulife.com"
          ><b>{{ 'doc@manulife.com' }}</b></a
        >
        and our CSOs will reply to you within 2 working days.
      </p>

      <div>
        <a href="mailto:doc@manulife.com">
          <button class="cb-btn" style="margin-right: 10px; background: green; border-radius: 10px; border: none">Email now</button>
        </a>
        <button class="cb-btn" (click)="exitChat()" style="margin-right: 10px; background: rgb(230, 59, 59); border-radius: 10px; border: none">
          Exit Chat
        </button>
      </div>
    </div>

    <div class="cb-no-internet" *ngIf="chatlogs?.roomId">
      <svg width="185" height="185" class="cb-signals">
        <use xlink:href="./assets/images/sprite.svg#cb-close"></use>
      </svg>
      <h2>Chat is Close</h2>
      <p>
        Your previous Chat has been Closed <br />
        Please try new chat.
      </p>
      <div style="padding: 20px">
        <button class="cb-btn" (click)="exitChat()" style="margin-right: 10px; background: rgb(230, 59, 59); border-radius: 10px; border: none">
          Back to Home
        </button>
      </div>
    </div>

    <!-- End Connection Failed -->

    <!-- Start Intro Carousel -->
    <div class="cb-intro-carousel-container" *ngIf="!agrement && !showNext">
      <div class="cb-intro-carousel">
        <div class="cb-intro-carousel-img">
          <img
            src="https://images.unsplash.com/photo-1684487747720-1ba29cda82f8?q=80&w=500&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
            alt="" />
        </div>
        <div class="cb-intro-carousel-meta">
          <h2>{{ lang == 'en' ? wecloemsg.en.head : wecloemsg.zh.head }}</h2>
          <p [innerHTML]="lang == 'en' ? wecloemsg.en.msg : wecloemsg.zh.msg" style="margin-top: 0px"></p>
        </div>
      </div>
    </div>

    <!-- End Intro Carousel -->

    <!-- Start Terms -->
    <div *ngIf="!agrement && showNext" style="height: 422px">
      <div class="cb-chatbot-body-inner" style="max-height: 100%; overflow: auto">
        <div class="cb-terms">
          <div class="cb-intro-carousel-meta">
            <p [innerHTML]="lang == 'en' ? introJson[index]?.text : introJson[index]?.chineseText"></p>
          </div>
        </div>
        <div>
          <ul class="cb-carousel-pagination" style="justify-content: center">
            <li [ngClass]="{ 'cb-active': introJson[i]?.active }" *ngFor="let item of introJson; let i = index" (click)="showNext = true"></li>
          </ul>
        </div>
      </div>
      <div class="cb-terms-close" style="justify-content: space-between; position: ABSOLUTE; bottom: 0; width: 100%; display: flex">
        <span style="display: flex">
          <span style="margin-right: 5px">
            <cds-button [disabled]="index + 1 == introJson.length" [size]="'sm'" [style]="'primary'" (click)="inrecmentIndex()">
              {{ lang == 'en' ? 'Next' : '下一步' }}</cds-button
            >
          </span>
          <cds-button [type]="'submit'" *ngIf="index > 0" [size]="'sm'" [style]="'primary'" (click)="previousIndex()">
            {{ lang == 'en' ? 'Previous' : '上一步' }}</cds-button
          >
        </span>

        <cds-button
          [type]="'submit'"
          *ngIf="introJson.length == 0 || index + 1 == introJson.length"
          [size]="'sm'"
          [style]="'primary'"
          (click)="agrement = true; getCanedResponse()"
          >{{ lang == 'en' ? 'Start' : '開始' }}</cds-button
        >

        <!-- <div class="cb-btn cb-btn-primary" *ngIf="introJson.length== 0 ||  index + 1 == introJson.length"
          (click)="agrement = true; getCanedResponse()"> {{lang== 'en'? 'Start' : '開始' }} </div> -->
      </div>
    </div>
    <!-- End Terms -->

    <!-- Start Chatbot Messsages -->
    <div class="cb-chatbot-view-outer" *ngIf="agrement && !noCsoAvailable && !chatlogs?.roomId">
      <div class="cb-chatbot-view" #messageContainer>
        <!-- <div class="cb-chatbot-message cb-chatbot-chat" *ngFor="let item of chats">
                  <div class="cb-bot-icon cb-chatbot-small">
                      <svg width="21" height="20" class="cb-bot">
                          <use xlink:href="./assets/images/sprite.svg#cb-bot"></use>
                      </svg>
                  </div>
                  <div class="cb-chatbot-message-meta">
                      <div class="cb-chatbot-content">{{item?.msg}}</div>
                      <div class="cb-chatbot-date">8:26 PM</div>
                  </div>
              </div> -->

        <ng-container *ngFor="let obj of chatJson">
          <div *ngIf="obj.userType === 'USER'" class="cb-chatbot-message cb-you" style="display: block">
            <div *ngIf="obj.type === 'CHAT'" class="cb-chatbot-content" [innerHTML]="obj.content"></div>
            <div
              *ngIf="obj.type === 'CHAT' && (obj.alerten || obj.alertzh)"
              class="cb-chatbot-content alertMsg"
              [innerHTML]="lang == 'en' ? obj.alerten : obj.alertzh"></div>

            <div *ngIf="obj.type === 'CHAT'" class="cb-chatbot-date" style="float: right">{{ obj?.msgDate | date : 'dd/MM/yyyy HH:mm' }}</div>

            <div class="cb-chatbot-message cb-you" *ngIf="obj.type === 'FILE'">
              <div class="cb-message-img">
                <img [src]="getPath(obj.content)" alt="" style="height: 100px !important; width: 100px !important" />
                <div class="cb-chatbot-date" style="text-align: right">
                  {{ obj?.msgDate | date : 'dd/MM/yyyy HH:mm' }}
                </div>
              </div>
            </div>
          </div>
          <div class="cb-chatbot-message cb-chatbot-chat" *ngIf="obj.userType === 'AGENT'">
            <div class="cb-bot-icon cb-chatbot-small">
              <svg width="21" height="20" class="cb-bot">
                <use xlink:href="./assets/images/sprite.svg#cb-bot"></use>
              </svg>
            </div>
            <div class="cb-chatbot-message-meta" *ngIf="obj.content && obj.type === 'CHAT'">
              <p style="text-align: left">{{ obj.sender }}</p>
              <div class="cb-chatbot-content" [innerHTML]="obj.content"></div>
              <div
                *ngIf="obj.type === 'CHAT' && (obj.alerten || obj.alertzh)"
                class="cb-chatbot-content alertMsg"
                [innerHTML]="lang == 'en' ? obj.alerten : obj.alertzh"></div>
              <div class="cb-chatbot-date">{{ obj?.msgDate | date : 'dd/MM/yyyy HH:mm' }}</div>
            </div>
            <div class="cb-chatbot-message-meta" *ngIf="obj.content && obj.type === 'JOIN'">
              <p style="text-align: center"></p>
              <div class="cb-chatbot-content" style="background-color: #f7b924">
                <b>{{ obj.sender }}</b>
                {{
                  lang == 'en'
                    ? '
                accept the chat'
                    : ' 接受聊天'
                }}
              </div>
              <div class="cb-chatbot-date">{{ obj?.msgDate | date : 'dd/MM/yyyy HH:mm' }}</div>
            </div>
            <div class="cb-chatbot-message-meta" *ngIf="obj.type === 'FILE'">
              <p style="text-align: left">{{ obj.sender }}</p>
              <div class="cb-message-img">
                <img [src]="getPath(obj.content)" alt="" style="height: 100px !important; width: 100px !important" />
                <div class="cb-chatbot-date" style="text-align: left">
                  {{ obj?.msgDate | date : 'dd/MM/yyyy HH:mm' }}
                </div>
              </div>
            </div>
          </div>

          <!-- <ul class="cb-btns-group" *ngIf="obj.responses.length>0">
                      <li *ngFor="let res of obj.responses">
                          <div class="cb-btn cb-btn-primary-outline" (click)="getresponce(res)">{{res.msg}}</div>
                      </li>
                  </ul> -->
        </ng-container>
        <ul class="cb-btns-group">
          <ng-container>
            <li *ngIf="chatJson.length == 0">
              <div
                class="cb-btn staticText"
                [innerHTML]="
                  lang == 'en'
                    ? 'Hi, good day. I will try my best to answer your questions about MPF business. Please select below topics of enquiry'
                    : '您好，我會盡我所能為您解答強積金業務方面的疑問。請選擇以下詢問主題。'
                "></div>
            </li>
            <li *ngFor="let item of chats; let i = index">
              <div class="cb-btn cb-btn-primary-outline" (click)="getresponce(item)" [innerHTML]="lang == 'en' ? item?.msg : item?.chineseMsg"></div>
            </li>
          </ng-container>
        </ul>
        <ul class="cb-btns-group" *ngIf="!liveChat">
          <li *ngIf="showBack">
            <div class="cb-btn cb-btn-primary-outline" (click)="backToMessage()">
              {{ lang == 'en' ? 'Return to home directory' : '返回主目錄' }}
            </div>
          </li>
          <li *ngIf="showBack">
            <div class="cb-btn cb-btn-primary-outline" (click)="backToPrevousMessage()">
              {{ lang == 'en' ? 'Return to previous directory' : '返回上一個目錄' }}
            </div>
          </li>
          <li *ngIf="showLiveButton">
            <div class="cb-btn cb-btn-primary-outline" (click)="checkGroupNo()">
              {{ lang == 'en' ? 'Chat with CSO' : '與 CSO 對話' }}
            </div>
          </li>
        </ul>
        <!-- <div class="cb-chatbot-message cb-chatbot-chat">
                  <div class="cb-bot-icon cb-chatbot-small">
                      <svg width="21" height="20" class="cb-bot">
                          <use xlink:href="./assets/images/sprite.svg#cb-bot"></use>
                      </svg>
                  </div>
                  <div class="cb-chatbot-message-meta">
                      <div class="cb-chatbot-content">Lorem Ipsum is simply dummy text of the printing and typesetting
                          industry.</div>
                      <div class="cb-chatbot-date">8:26 PM</div>
                  </div>
              </div> -->
      </div>
      <div class="cb-chatbot-footer cb-messages-meta" *ngIf="liveChat && groupNumber">
        <div (click)="showModal()">
          <label>
            <cds-icon icon="action:list"></cds-icon>
          </label>
          <!-- <label for="attachment">
            <svg width="24" height="24">
              <use xlink:href="./assets/images/sprite.svg#cb-attachment"></use>
            </svg>
          </label>
          <input id="attachment" (change)="onFileChange($event)" type="file" name="attachment" /> -->
        </div>
        <div class="cb-send-message">
          <input id="chatMessage" type="text" placeholder="Write to send message" />
        </div>
        <div class="cb-send" (click)="sendMessage()">
          <svg width="28" height="28">
            <use xlink:href="./assets/images/sprite.svg#cb-send"></use>
          </svg>
        </div>
      </div>
      <div class="cb-chatbot-footer cb-messages-meta" style="display: block" *ngIf="liveChat && !groupNumber">
        <p style="padding: 0px 20px 5px">{{ this.lang == 'en' ? 'Customer Policy Number' : '請輸入強積金成員的帳號' }}</p>
        <div style="display: flex">
          <!-- <div> Please Enter Group No and Cert. No.</div> -->
          <div class="cb-send-message">
            <input id="chatMessage" type="text" minlength="8" maxlength="20" [(ngModel)]="groupNo" placeholder="Group No." style="width: 30%" />
          </div>

          <div class="cb-send-message">
            <input id="chatMessage" type="text" minlength="6" maxlength="20" [(ngModel)]="certNo" placeholder="Cert. No" style="width: 40%" />
          </div>
          <div class="cb-send" (click)="sendGroupNo()">
            <svg width="28" height="28">
              <use xlink:href="./assets/images/sprite.svg#cb-send"></use>
            </svg>
          </div>
        </div>
      </div>
    </div>

    <!-- End Chatbot Messsages -->
  </div>
  <div *ngIf="!agrement && !showNext">
    <ul class="cb-btn-group" style="padding-top: 5px">
      <li>
        <cds-button [size]="'sm'" [style]="'primary'" (click)="showNext = true"> {{ lang == 'en' ? 'Next' : '下一步' }}</cds-button>
      </li>
    </ul>
    <!-- <p class="cb-copyright">By continuing, you are agree to our Privacy Policy & Terms of Use</p> -->
  </div>
</div>

<div class="cb-bot-icon">
  <svg width="31" height="30" class="cb-bot" *ngIf="isShowChatBotIcon" (click)="onShowChatbot()">
    <use xlink:href="./assets/images/sprite.svg#cb-bot"></use>
  </svg>
  <svg width="44" height="43" class="cb-close" *ngIf="isShowChatBotCloseIcon" (click)="onHideChatbot()">
    <use xlink:href="./assets/images/sprite.svg#cb-close"></use>
  </svg>
</div>

<div class="cb-userchatbot" *ngIf="showPopUp">
  <div class="row">
    <div class="col-12">
      <div class="cb-userchatbot-header">
        <ul class="userchatbot">
          <li class="fileUpload">
            <label for="attachment"> File Upload </label>
            <input id="attachment" (change)="onFileChange($event)" type="file" name="attachment" />
          </li>
          <li (click)="onCloseChatModal()">Close Chat</li>
        </ul>
      </div>
    </div>
  </div>
</div>
