<div class="col-xs-12 col-md-12 l-pt-6 l-d-f l-jc-sb">
  <span class="cds-h2-light l-pt-2">{{ 'commission.pool.scale.recordDetails.title' | lang }}</span>
  <cds-button *ngIf="roleW | permission : permissionN | async" [config]="btnCfg" size="md" (click)="createRecord()"
    >{{ 'commission.pool.scale.recordDetails.addScaleRecords' | lang }}
  </cds-button>
</div>

<cds-progress *ngIf="loading" mode="indeterminate" class="l-pl-6"></cds-progress>

<div *ngIf="!loading">
  <div class="col-xs-12 col-md-12 l-pb-7 padding-none">
    <div class="row l-plr-0">
      <div class="col-xs-12 col-md-1 font-label l-pt-2">
        {{ 'commission.pool.scale.recordDetails.filterBy' | lang }}
      </div>
      <div class="col-xs-12 col-md-10 row padding-none">
        <cds-pill [selected]="checkedAll" (click)="checkRecordDetailTag('all')">{{ 'commission.pool.scale.recordDetails.all' | lang }} </cds-pill>
        <div *ngFor="let item of recordInfoList; let i = index">
          <cds-pill [selected]="item.checked" (click)="checkRecordDetailTag(item.scale)">{{ item.scale }}</cds-pill>
        </div>
      </div>
    </div>
  </div>

  <div class="col-xs-12 col-md-12">
    <div class="col-xs-12 col-md-12 l-d-f l-jc-sb record-history">
      <div class="record-history-padding l-fg-1">
        <mat-form-field appearance="fill" style="width: 100%">
          <mat-select
            #mySelect
            appSelectCloseDirective="mySelect"
            [(ngModel)]="scaleRecord.id"
            class="record-history-select"
            msInfiniteScroll
            (infiniteScroll)="getScaleRecordList()"
            [complete]="totalPages === recordHistoryPage || totalPages === 0"
            (selectionChange)="getScaleRecordInfo()">
            <mat-select-trigger class="l-d-f">
              {{ scaleRecord.effectiveDate | ssmsDate }}, {{ 'commission.pool.common.createBy' | lang }}&nbsp; {{ scaleRecord.createBy.name }}&nbsp;&nbsp;
              <cds-icon *ngIf="scaleRecord.sendEmail" icon="action:notification"></cds-icon>
            </mat-select-trigger>
            <mat-option
              class="record-history-option"
              [disabled]="!item.active"
              [class.disabled]="!item.active"
              *ngFor="let item of recordHistoryList; let i = index"
              [value]="item.id">
              <div class="l-d-f l-jc-sb">
                <mat-icon class="l-d-f">
                  {{ item.effectiveDate | ssmsDate }}, {{ 'commission.pool.common.createBy' | lang }}&nbsp; {{ item.createBy.name }}&nbsp;&nbsp; &nbsp;&nbsp;
                  <cds-icon *ngIf="item.sendEmail" icon="action:notification"></cds-icon>
                </mat-icon>
                <span class="font-grey" *ngIf="item.status === 'CURRENT' && item.active"> Current effective scale </span>
                <span class="font-grey" *ngIf="item.status === 'DELETED'">
                  Deleted on {{ item.lastUpdateTime | ssmsDate }}, by
                  {{ item.lastUpdateName }}
                </span>
              </div>
            </mat-option>
            <cds-progress *ngIf="scrollLoading" mode="indeterminate" class="l-pl-2"></cds-progress>
          </mat-select>
        </mat-form-field>
        <div style="position: relative; top: -42px" class="cds-body2 cds-dark-navy-text light-3 l-pl-3">
          {{ 'commission.pool.scale.recordDetails.effectiveDate' | lang }}&nbsp;&nbsp;
        </div>
      </div>
      <div *ngIf="deleteDisplay && (roleE | permission : permissionN | async)" class="record-menu l-d-f">
        <cds-icon [matMenuTriggerFor]="menu" size="sm" icon="action:more_options"></cds-icon>
      </div>
      <mat-menu #menu="matMenu">
        <div class="menu-item" (click)="deleteRecord()">
          <cds-icon size="sm" icon="form:delete"></cds-icon>
          <div class="l-pl-2">
            {{ 'commission.pool.scale.recordDetails.deleteRecord' | lang }}
          </div>
        </div>
      </mat-menu>
    </div>
  </div>

  <cds-progress *ngIf="scaleInfoLoading" mode="indeterminate" class="l-pl-8 l-pt-4"></cds-progress>
  <div *ngIf="scaleRecord.id && !scaleInfoLoading" class="col-xs-12 col-md-12 padding-none">
    <ng-container *ngFor="let item of recordInfoList; let i = index">
      <div *ngIf="item.checked" class="col-xs-12 col-md-12 l-mt-5">
        <div class="col-xs-12 col-md-12 card l-pt-3 l-pb-3 l-pl-3 l-pr-3">
          <div class="col-xs-12 col-md-12">
            <div class="col-xs-12 col-md-12 l-pt-4 l-pb-4 l-pr-4">
              <div class="l-d-f l-jc-sb" style="text-align: left">
                <div class="l-pr-9">
                  <span class="cds-h2-demibold">{{ item.scale }}</span>
                  <br />
                </div>
                <div class="l-pt-3" *ngIf="editDisplay && (roleW | permission : permissionN | async)">
                  <div *ngIf="!editRecordDetailStatus" (click)="editRecordDetail(item)" class="button-edit">
                    <cds-icon class="l-pr-2" [height]="'50'" icon="action:edit"></cds-icon>
                    <div class="cds-h6-demibold-16 display-max-screen l-pt-1">
                      {{ 'commission.pool.common.edit' | lang }}
                    </div>
                  </div>
                  <div *ngIf="editRecordDetailStatus && recordDetailForm.scaleType === item.scaleType" (click)="cancelEditRecordDetail()" class="button-edit">
                    <cds-icon class="l-pr-2" [height]="'50'" icon="action:edit"></cds-icon>
                    <div class="cds-h6-demibold-16 display-max-screen l-pt-1">
                      {{ 'commission.pool.common.cancelEdit' | lang }}
                    </div>
                  </div>
                </div>
              </div>
              <div style="float: right">
                <cds-assistive-text *ngIf="editError" class="l-pt-3">
                  {{ editError | lang }}
                </cds-assistive-text>
              </div>
            </div>

            <div class="cds-body2 cds-dark-navy-text light-3 l-pt-3 l-d-f row l-plr-0">
              <div class="cds-textfield-label cds-body2 cds-dark-navy-text light-3 col-xs-12 col-md-3">
                {{ 'commission.pool.scale.recordDetails.scaleCode' | lang }}:
              </div>
              <div class="font-bold col-xs-12 col-md-3">{{ item.scaleCode }}</div>
            </div>
          </div>
          <div class="col-xs-12 col-md-12 l-pt-3 l-pb-3">
            <div class="row l-plr-0">
              <div *ngIf="item.calcTypeDisplay" class="col-xs-12 col-md-3 l-pt-3 l-pb-3 text-left">
                <div class="padding-5">
                  <label class="cds-textfield-label cds-body2 cds-dark-navy-text light-3">{{
                    'commission.pool.scale.recordDetails.calculationType' | lang
                  }}</label>
                  <div class="padding-5">{{ item.calcType | lang }}</div>
                </div>
              </div>
              <div *ngIf="item.breakpointDisplay" class="col-xs-12 col-md-3 l-pt-3 l-pb-3 text-left">
                <div class="padding-5">
                  <label class="cds-textfield-label cds-body2 cds-dark-navy-text light-3">{{
                    'commission.pool.scale.recordDetails.breakpointType' | lang
                  }}</label>
                  <div class="padding-5">{{ item.breakpointType | lang }}</div>
                </div>
              </div>
              <div *ngIf="item.mthlyClawBack && item.scaleType === 'FS_PC'" class="col-xs-12 col-md-3 l-pt-3 l-pb-3 text-left">
                <div class="padding-5">
                  <label class="cds-textfield-label cds-body2 cds-dark-navy-text light-3">{{ 'Monthly Claw Back Amount' | lang }}</label>
                  <div *ngIf="!editRecordDetailStatus" class="padding-5">
                    {{ item.clawBackAmount }}
                  </div>
                  <div *ngIf="editRecordDetailStatus && recordDetailForm.scaleType === item.scaleType">
                    <input
                      *ngIf="!isConfirm"
                      maxlength="24"
                      oninput="value=value.match(/(^(\d){0,20})/g) || ''"
                      class="text-field padding-5"
                      [(ngModel)]="recordDetailForm.clawBackAmount"
                      (change)="inputClawBackAmount($event)" />
                    <div *ngIf="isConfirm" class="padding-5">
                      {{ recordDetailForm.clawBackAmount }}
                    </div>
                    <cds-assistive-text *ngIf="recordDetailForm.clawBackAmountError" class="l-pt-3">
                      {{ recordDetailForm.clawBackAmountError | lang }}
                    </cds-assistive-text>
                  </div>
                </div>
              </div>
              <div *ngIf="item.mthlyClawBack && item.scaleType === 'FS_PC'" class="col-xs-12 col-md-3 l-pt-3 l-pb-3 text-left">
                <div class="padding-5">
                  <label class="cds-textfield-label cds-body2 cds-dark-navy-text light-3"
                    >{{ 'Monthly Claw Back Months' | lang }}
                    <span class="cds-demibold">[AnnuaLize Flag]</span>
                  </label>
                  <div *ngIf="!editRecordDetailStatus" class="padding-5">
                    {{ item.clawBackMonth }}&nbsp;&nbsp;
                    <span *ngIf="item.annualizeFlagStr" class="cds-demibold">{{ item.annualizeFlagStr }}</span>
                  </div>
                  <div *ngIf="editRecordDetailStatus && recordDetailForm.scaleType === item.scaleType">
                    <input
                      *ngIf="!isConfirm"
                      maxlength="24"
                      oninput="value=value.match(/(^(\d){0,2})/g) || ''"
                      class="text-field padding-5"
                      [(ngModel)]="recordDetailForm.clawBackMonth"
                      (change)="inputClawBackMonth($event)" />
                    <cds-assistive-text *ngIf="recordDetailForm.clawBackMonthError" class="l-pt-3">
                      {{ recordDetailForm.clawBackMonthError | lang }}
                    </cds-assistive-text>
                    <div *ngIf="isConfirm" class="padding-5">
                      {{ recordDetailForm.clawBackMonth }}
                    </div>
                  </div>
                </div>
              </div>
              <div *ngIf="item.currPolDisplay" class="col-xs-12 col-md-3 l-pt-3 l-pb-3 text-left">
                <div class="padding-5">
                  <label class="cds-textfield-label cds-body2 cds-dark-navy-text light-3">{{
                    'commission.pool.scale.recordDetails.currCommRate' | lang
                  }}</label>
                  <div class="padding-5">{{ item.currPolYrCommRate }}</div>
                </div>
              </div>
              <div
                *ngIf="!editRecordDetailStatus || (editRecordDetailStatus && recordDetailForm.scaleType !== item.scaleType)"
                class="col-xs-12 col-md-12 l-pt-6 l-pb-3 div-border-top">
                <div *ngFor="let accumulation of item.accumulations" class="col-xs-12 col-md-12 l-pt-3 l-pb-3">
                  <div class="row">
                    <div class="col-xs-6 col-md-6 cds-body2 cds-dark-navy-text light-3">
                      {{ accumulation.label }}
                    </div>
                    <div
                      *ngIf="accumulation.accumulationType === accumulationType.PLAN_HIGH || accumulation.accumulationType === accumulationType.RELEASED_CYTD"
                      class="col-xs-4 col-md-4">
                      {{ accumulation.amount | mynumber }}
                    </div>
                    <div
                      *ngIf="
                        !(accumulation.accumulationType === accumulationType.PLAN_HIGH || accumulation.accumulationType === accumulationType.RELEASED_CYTD)
                      "
                      class="col-xs-4 col-md-4">
                      {{ accumulation.amount | mynumber : 2 }}
                    </div>
                  </div>
                </div>
              </div>

              <div *ngIf="editRecordDetailStatus && recordDetailForm.scaleType === item.scaleType" class="col-xs-12 col-md-12 l-pt-6 l-pb-3 div-border-top">
                <div *ngFor="let accumulation of recordDetailForm.accumulations; let i = index" class="col-xs-12 col-md-12 l-pt-3 l-pb-3">
                  <div class="row">
                    <div class="col-xs-6 col-md-6 cds-body2 cds-dark-navy-text light-3">
                      {{ accumulation.label }}
                    </div>
                    <div *ngIf="isConfirm" class="col-xs-4 col-md-4">
                      {{ accumulation.amount }}
                    </div>
                    <div *ngIf="!isConfirm" class="col-xs-4 col-md-4">
                      <input
                        maxlength="24"
                        oninput="value=value.match(/(^\-?(\d){0,20}\.(\d){0,2})|(^\-?(\d){0,20})/g) || ''"
                        class="text-field"
                        [(ngModel)]="accumulation.amount"
                        (change)="inputAccumulation($event, accumulation.accumulationType)" />
                      <cds-assistive-text *ngIf="accumulation.errorMsg" class="l-pt-3">
                        {{ accumulation.errorMsg | lang }}
                      </cds-assistive-text>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-xs-12 col-md-12">
                <div *ngFor="let details of item.breakpoints; let i = index" class="col-xs-12 col-md-10 l-pt-3 l-pb-3 l-mt-3 card">
                  <div class="row padding-none">
                    <div class="col-xs-12 col-md-10 l-pt-3 l-pb-3 div-border-top" style="overflow: auto">
                      <table class="cds-table full cds-grey light" style="border-collapse: collapse">
                        <tr style="background: white">
                          <td class="l-pt-3 l-pb-5 font-bold font-center">
                            {{ 'commission.pool.scale.recordDetails.noOfBreakpoints' | lang }}
                          </td>
                          <td class="l-pt-3 l-pb-5 font-bold">
                            {{ 'commission.pool.scale.recordDetails.breakpointsAmount' | lang }}
                          </td>
                          <td class="l-pt-3 l-pb-5 font-bold">
                            {{ 'commission.pool.scale.recordDetails.percentage' | lang }}
                          </td>
                        </tr>
                        <ng-container *ngFor="let item of details.list; let j = index">
                          <tr class="border-top">
                            <td class="l-pt-3 l-pb-3 font-center">
                              {{ item.breakpoints }}
                            </td>
                            <td class="l-pt-3 l-pb-3">
                              <div *ngIf="item.displayAmount">
                                {{ item.amount | mynumber }}
                              </div>
                            </td>
                            <td class="l-pt-3 l-pb-3">
                              {{ item.percentage }}
                            </td>
                          </tr>
                        </ng-container>
                      </table>
                    </div>
                  </div>
                </div>
                <div class="col-xs-12 col-md-12 l-plr-0" *ngIf="editRecordDetailStatus && recordDetailForm.scaleType === item.scaleType">
                  <div *ngIf="!isConfirm" class="l-pt-3 l-pb-3 l-mt-3 l-d-f l-plr-0">
                    <cds-button [config]="btnCfg" size="md" (click)="reset()">{{ 'commission.pool.common.reset' | lang }} </cds-button>
                    <cds-button [disabled]="saveDisabled" class="l-ml-3" size="md" (click)="editScaleInfoSave()"
                      >{{ 'commission.pool.common.save' | lang }}
                    </cds-button>
                  </div>
                  <div *ngIf="isConfirm" class="l-pt-3 l-pb-3 l-mt-3 l-d-f l-plr-0">
                    <cds-button [config]="btnCfg" size="md" (click)="backToEdit()">{{ 'commission.pool.common.backToEdit' | lang }} </cds-button>
                    <cds-button [disabled]="saveDisabled" class="l-ml-3" size="md" (click)="confirm()"
                      >{{ 'commission.pool.common.confirm' | lang }}
                    </cds-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>

  <div class="l-mt-6 col-xs-12 col-md-12">
    <cds-button (click)="backTop()" [style]="'secondary'" size="md">{{ 'common.backToTop' | lang }}</cds-button>
  </div>
</div>
