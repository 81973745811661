<div class="row search-box">
  <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12 search-box-item">
    <!-- <cds-textfield
      [value]="nameValue"
      #searchBox
      (input)="nameChange(searchBox.value)"
      [disabled]="true"
      label="{{ 'employee.employeeName' | lang }}"
      placeholder="{{ 'employer.searchPlaceHolder' | lang }}"
      [config]="employeeNameTxtfieldConfig"></cds-textfield> -->

    <app-cd-input
      [disable]="true"
      [(value)]="nameValue"
      (valueChange)="nameChange($event)"
      [placeholder]="'employer.searchPlaceHolder' | lang"
      [label]="'employee.employeeName' | lang"></app-cd-input>
  </div>
  <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12 search-box-item">
    <!-- <cds-textfield
      [value]="numberValue"
      #searchBumberBox
      [disabled]="true"
      (input)="numberChange(searchBumberBox.value)"
      label="{{ 'employee.no' | lang }}"
      placeholder="{{ 'employer.searchPlaceHolder' | lang }}"
      [config]="numbersTxtfieldConfig">
    </cds-textfield> -->

    <app-cd-input
      [disable]="true"
      [(value)]="numberValue"
      (valueChange)="numberChange($event)"
      [placeholder]="'employer.searchPlaceHolder' | lang"
      [label]="'employee.no' | lang"></app-cd-input>
  </div>
  <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12 search-box-item">
    <cds-dropdown
      *ngIf="language === 'EN'"
      [formControl]="ageFormControl"
      (change)="ageChange($event)"
      placeholder="{{ 'employer.checkPlaceHolder' | lang }}"
      [config]="ageEnRangeDropdownConfig"
      label="{{ 'employee.ageRank' | lang }}"></cds-dropdown>
    <cds-dropdown
      *ngIf="language !== 'EN'"
      [formControl]="ageFormControl"
      (change)="ageChange($event)"
      placeholder="{{ 'employer.checkPlaceHolder' | lang }}"
      [config]="ageZhRangeDropdownConfig"
      label="{{ 'employee.ageRank' | lang }}"></cds-dropdown>
  </div>

  <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12 search-box-item">
    <cds-dropdown
      *ngIf="language === 'EN'"
      [formControl]="genderControl"
      (change)="genderChange($event)"
      label="{{ 'employee.gender' | lang }}"
      placeholder="{{ 'employer.checkPlaceHolder' | lang }}"
      [config]="genderEnDropdownConfig"></cds-dropdown>
    <cds-dropdown
      *ngIf="language !== 'EN'"
      [formControl]="genderControl"
      (change)="genderChange($event)"
      label="{{ 'employee.gender' | lang }}"
      placeholder="{{ 'employer.checkPlaceHolder' | lang }}"
      [config]="genderZhDropdownConfig"></cds-dropdown>
  </div>

  <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12 search-box-item">
    <!-- <cds-textfield
      [value]="delegateAgentInfo"
      #searcdelegateBox
      [disabled]="true"
      (input)="delegateAgentChange(searcdelegateBox.value)"
      label="{{ 'employee.delegateAgentInfo' | lang }}"
      placeholder="{{ 'employer.searchPlaceHolder' | lang }}"
      [config]="delegateTxtfieldConfig"></cds-textfield> -->

    <app-cd-input
      [disable]="true"
      [(value)]="delegateAgentInfo"
      (valueChange)="delegateAgentChange($event)"
      [placeholder]="'employer.searchPlaceHolder' | lang"
      [label]="'employee.searchDelegateAgentInfo' | lang"></app-cd-input>
  </div>

  <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12 search-box-item">
    <div class="double-botton">
      <div>
        <cds-button [disabled]="true" [config]="employeeButtonConfig" label="{{ 'employer.searchBtnTxt' | lang }}" (click)="employeeSearch()"></cds-button>
      </div>
      <div>
        <cds-button [disabled]="true" [config]="clearButton" label="{{ 'employer.clear' | lang }}" (click)="clearData()"></cds-button>
      </div>
    </div>
  </div>
</div>
