/* eslint-disable @typescript-eslint/no-explicit-any */
import { DateTime } from 'luxon';

export function format_3(date: string) {
  return fromISO(date, 'yyyy-MM-dd HH:mm:ss');
}

export function fromISO(date: any, formatter?: string) {
  if (formatter) {
    return DateTime.fromISO(date).toFormat(formatter);
  }
  return DateTime.fromISO(date).toFormat('dd/MM/yyyy');
}

export function fromISOMonthYear(date: any) {
  const d: string = DateTime.fromISO(date).toFormat('dd/MM/yyyy');
  if (d && d.indexOf('/') != -1) {
    const arr = d.split('/');
    if (arr && arr.length == 3) {
      return arr[1] + '/' + arr[2];
    }
  }
  return '';
}

export function fromJSDate(date: any) {
  return DateTime.fromJSDate(date);
}

export function fromJSDateFormat(date: any) {
  return DateTime.fromJSDate(date).toFormat('dd/MM/yyyy');
}

export function toMillis(date: any) {
  return fromJSDate(date).toMillis();
}

export function millisSubtract(date1: any, date2: any) {
  return fromJSDate(date1).toMillis() - fromJSDate(date2).toMillis();
}

export function toISO(date: any, formatter?: string) {
  if (formatter) {
    return DateTime.fromFormat(date, formatter).toISO();
  }
  return DateTime.fromFormat(date, 'dd/MM/yyyy').toISO();
}

export function toISODate(date: any, formatter?: string) {
  if (formatter) {
    return DateTime.fromFormat(date, formatter).toISODate();
  }
  return DateTime.fromFormat(date, 'dd/MM/yyyy').toISODate();
}

export function formatDateFr(date: string) {
  return DateTime.fromISO(date).setLocale('fr').toLocaleString(DateTime.DATE_SHORT);
}

export function formatDateTimeFr(date: string) {
  return DateTime.fromISO(date).setLocale('fr').toLocaleString(DateTime.DATETIME_SHORT);
}

export function diffISOToJsDate(date: string, date2: Date): any {
  return diffMonths(toISO(date), fromJSDate(date2));
}

export function diffISOToISO(date: string, date2: string): any {
  return diffMonths(toISO(date), toISO(date2));
}

export function beforeCurrent(date: any): any {
  const obj: any = diffMonths(date, new Date());
  if (obj.months > 0 || obj.days >= 1) {
    return true;
  }
  return false;
}

export function diffMonths(date: any, date2: any): any {
  if (!date || !date2) {
    return null;
  }
  let d1: any = DateTime.now(),
    d2: any = DateTime.now();
  if (DateTime.fromISO(date).isValid) {
    d1 = DateTime.fromISO(date);
  } else if (fromJSDate(date).isValid) {
    d1 = fromJSDate(date);
  } else if (DateTime.fromISO(toISO(date) as any).isValid) {
    d1 = DateTime.fromISO(toISO(date) as any);
  }

  if (DateTime.fromISO(date2).isValid) {
    d2 = DateTime.fromISO(date2);
  } else if (fromJSDate(date2).isValid) {
    d2 = fromJSDate(date2);
  } else if (DateTime.fromISO(toISO(date2) as any).isValid) {
    d2 = DateTime.fromISO(toISO(date2) as any);
  }
  return d2.diff(d1, ['months', 'days']).toObject();
}

export function getMonthFirstByMonthYear(str: any): string {
  if (/(^(1)[0-2]{1}\/(\d){4})|(^(0)[1-9]{1}\/(\d){4})$/.test(str)) {
    return '01/' + str;
  }
  return '';
}

export function getMonthLastByMonthYear(str: any): string {
  if (/(^(1)[0-2]{1}\/(\d){4})|(^(0)[1-9]{1}\/(\d){4})$/.test(str)) {
    const arr = String(str).split('/');
    const maxDay = new Date(Number(arr[1]), Number(arr[0]), 0).getDate();
    return maxDay + '/' + str;
  }
  return '';
}

export function getMonthLastByNow(): string {
  const now: Date = new Date();
  const month = now.getMonth() + 1;
  let monthStr = '';
  if (month < 10) {
    monthStr = '0' + month;
  } else {
    monthStr = String(month);
  }
  return getMonthLastByMonthYear(monthStr + '/' + now.getFullYear());
}
