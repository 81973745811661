<div class="date-range">
  <div class="date-item-left">
    <ng-content select="[left]"></ng-content>
  </div>
  <div class="date-center">
    <span>-</span>
  </div>
  <div class="date-item-right">
    <ng-content select="[right]"></ng-content>
  </div>
</div>
