<div class="row l-pt-4 l-pb-4">
  <div class="col-xs-12 cds-color-green anchor-parent">
    <div class="anchor-element" id="generalInformation"></div>
    <span class="cds-h4-demibold">{{ 'er.profile.general-information' | lang }}</span>
  </div>
  <div class="col-xs-12">
    <div class="p-line"></div>
  </div>

  <div class="col-xs-12 l-mb-4" *ngIf="isStaff">
    <div class="score-container">
      <div class="text l-mr-5">Total Relationship Score</div>
      <div class="score">{{ relationshipTotalCount?.totalRelationshipScore }}</div>
    </div>
  </div>

  <div class="col-xs-12 l-mb-4 score-table">
    <table cellspacing="0">
      <tbody>
        <tr>
          <td *ngIf="isStaff">
            <div class="flex">
              <span class="l-mr-2">{{ 'er.profile.annualized-contribution' | lang }}</span>
              <app-tooltip-icon tooltipString="Contribution paid of last month x 12"></app-tooltip-icon>
            </div>
          </td>
          <td *ngIf="isStaff">
            <div class="flex">
              <span class="l-mr-2">{{ 'er.profile.aggregate' | lang }}</span>
              <app-tooltip-icon tooltipString="Sum of “Total Balance” of all invested funds of the ER"></app-tooltip-icon>
            </div>
          </td>
          <td>
            <div class="flex">
              <span class="l-mr-2">{{ 'er.profile.total-lives' | lang }}</span>
            </div>
          </td>
          <td *ngIf="isStaff">
            <div class="flex">
              <span class="l-mr-2">{{ 'er.profile.vip-code' | lang }}</span>
              <app-tooltip-icon tooltipString="Team code"></app-tooltip-icon>
            </div>
          </td>
        </tr>
        <tr>
          <td *ngIf="isStaff">HKD {{ data?.annualizedContribution | number : '1.2-2' }}</td>
          <td *ngIf="isStaff">
            <div class="link-area" (click)="showInfo()">
              <span class="l-mr-2">HKD {{ data?.aggregateInvestedAmount | number : '1.2-2' }}</span>
              <cds-icon class="amount-icon" width="20px" height="20px" color="#282B3E" icon="arrow:fullscreen"></cds-icon>
            </div>
          </td>
          <td>{{ data?.totalLives | number }}</td>
          <td *ngIf="isStaff">{{ data?.vipCode || 'NB' }}</td>
        </tr>
      </tbody>
    </table>
  </div>

  <div class="col-xs-12">
    <div class="p-line"></div>
  </div>

  <!-- <app-row-item label="er.profile.total-lives" data="{{ data?.totalLives }}" class="col-xs-6 col-sm-3 col-md-3 col-lg-3"> </app-row-item> -->
  <app-row-item label="er.profile.member-empf-id" data="{{ data?.empfId }}" class="col-xs-6 col-sm-3 col-md-3 col-lg-3"> </app-row-item>
  <app-row-item label="er.profile.employer-account-code" data="{{ data?.employerAccountCode }}" class="col-xs-6 col-sm-3 col-md-3 col-lg-3"> </app-row-item>
  <app-row-item label="er.profile.original-employer-code" data="{{ data?.originalEmployerCode }}" class="col-xs-6 col-sm-3 col-md-3 col-lg-3"> </app-row-item>
  <app-row-item
    label="er.profile.commencement-date"
    data="{{ data?.commencementDate | ssmsDate }}"
    class="col-xs-6 col-sm-3 col-md-3 col-lg-3"
    toolTipString="Group Effective Date">
  </app-row-item>

  <app-row-item label="er.profile.01.line-bussiness" data="{{ data?.lineOfBusiness }}" class="col-xs-6 col-sm-3 col-md-3 col-lg-3"> </app-row-item>
  <app-row-item label="er.profile.account-type" [data]="data?.accountType" class="col-xs-6 col-sm-3 col-md-3 col-lg-3"> </app-row-item>
  <app-row-item *ngIf="isStaff" label="er.profile.company-type" [data]="companyTypeRender | fn : data?.companyType" class="col-xs-6 col-sm-3 col-md-3 col-lg-3">
  </app-row-item>
  <app-row-item
    *ngIf="isStaff"
    label="er.profile.business-nature"
    [data]="businessNatureRender | fn : data?.businessNature"
    class="col-xs-6 col-sm-3 col-md-3 col-lg-3">
  </app-row-item>

  <app-row-item
    label="er.profile.mpfa-reference-number"
    toolTipString="Same as MPFA Participation no."
    data="{{ data?.mpfaReferenceNumber }}"
    class="col-xs-6 col-sm-3 col-md-3 col-lg-3">
  </app-row-item>
  <app-row-item label="er.profile.registration-type" [data]="registrationTypeRender | fn : data?.registrationType" class="col-xs-6 col-sm-3 col-md-3 col-lg-3">
  </app-row-item>
  <app-row-item label="er.profile.registration-number" toolTipString="BR no." data="{{ data?.registrationNumber }}" class="col-xs-6 col-sm-3 col-md-3 col-lg-3">
  </app-row-item>
  <app-row-item
    *ngIf="isStaff"
    label="er.profile.branch-no"
    data="{{ data?.branchNo }}"
    toolTipString="Branch Number of Business Registration Number"
    class="col-xs-6 col-sm-3 col-md-3 col-lg-3">
  </app-row-item>

  <app-row-item *ngIf="isStaff" label="er.profile.data-of-inco" data="{{ data?.dateOfIncorporation | ssmsDate }}" class="col-xs-6 col-sm-3 col-md-3 col-lg-3">
  </app-row-item>
  <app-row-item
    *ngIf="isStaff"
    label="er.profile.certificate-incorporation-number"
    [data]="data?.certificateOfIncorporationNumber"
    class="col-xs-6 col-sm-3 col-md-3 col-lg-3">
  </app-row-item>
  <app-row-item *ngIf="isStaff" label="er.profile.place-incorporation" [data]="data?.placeOfIncorporation" class="col-xs-6 col-sm-3 col-md-3 col-lg-3">
  </app-row-item>
  <app-row-item label="payroll.fund-currency" [data]="data?.currency" class="col-xs-6 col-sm-3 col-md-3 col-lg-3"> </app-row-item>

  <app-row-item label="er.profile.employer-status" data="{{ employerStatusRender | fn : data?.employerStatus }}" class="col-xs-6 col-sm-3 col-md-3 col-lg-3">
  </app-row-item>
  <app-row-item
    label="er.profile.last-update-date"
    data="{{ (data?.lastUpdatedDateOfEmployerStatus | ssmsDate) || '01/01/1900' }}"
    class="col-xs-6 col-sm-3 col-md-3 col-lg-3">
  </app-row-item>

  <app-row-item
    label="employer.scheme-merge-signal"
    *ngIf="isStaff"
    [data]="schemeMergeSignalRender | fn : data?.schemeMergeSignal"
    toolTipString="LS Flag"
    class="col-xs-6 col-sm-3 col-md-3 col-lg-3">
  </app-row-item>

  <app-row-item
    *ngIf="isStaff"
    label="er.profile.lsp-offset-seq"
    [data]="lspspoffsetSeqRender | fn : data?.lsp_spOffsetSequence"
    class="col-xs-6 col-sm-3 col-md-3 col-lg-3">
  </app-row-item>

  <app-row-item
    label="er.profile.employer-enrolment-channel"
    [data]="submitChannelRender | fn : data?.enrolmentSubmissionChannel"
    class="col-xs-6 col-sm-3 col-md-3 col-lg-3">
  </app-row-item>

  <app-row-item label="employer.enrolement-reference-number" [data]="data?.enrolmentReferenceNumber" class="col-xs-6 col-sm-3 col-md-3 col-lg-3">
  </app-row-item>
  <div class="col-xs-6 col-sm-3 col-md-3 col-lg-3" *ngIf="isStaff">
    <div class="cds-body2 l-mt-2 l-mb-0">{{ 'employer.workflow-reference-No' | lang }}</div>
    <span class="cds-body1 link">{{ data?.workflowReferenceNo }}</span>
  </div>

  <div class="col-xs-12">
    <div class="p-line"></div>
  </div>

  <!-- <app-row-item label="er.profile.vip-code" data="{{ data?.vipCode || 'NB' }}" toolTipString="Team Code" class="col-xs-6 col-sm-3 col-md-3 col-lg-3">
  </app-row-item>

  <app-row-item
    label="er.profile.totalRelationshipScore"
    [data]="relationshipTotalCount?.totalRelationshipScore?.toString()"
    class="col-xs-6 col-sm-3 col-md-3 col-lg-3">
  </app-row-item>

  <app-row-item
    label="er.profile.annualized-contribution"
    data="HKD {{ data?.annualizedContribution | number : '1.2-2' }}"
    toolTipString="Contribution paid of last month x 12 "
    class="col-xs-6 col-sm-3 col-md-3 col-lg-3">
  </app-row-item> -->

  <!-- <app-row-item
    label="er.profile.aggregate"
    icon="arrow:fullscreen"
    (funCall)="showInfo()"
    data="HKD {{ data?.aggregateInvestedAmount | number : '1.2-2' }}"
    toolTipString="Sum of “Total Balance” of all invested funds of the ER"
    class="col-xs-6 col-sm-3 col-md-3 col-lg-3">
  </app-row-item>

  <app-row-item *ngIf="isStaff" label="er.profile.mpf-exempt-no" data="{{ data?.mpfExemptNo }}" class="col-xs-6 col-sm-3 col-md-3 col-lg-3"> </app-row-item>

  <app-row-item *ngIf="isStaff" label="er.profile.subsid-no" data="{{ data?.subsidNo }}" class="col-xs-6 col-sm-3 col-md-3 col-lg-3"> </app-row-item>

  <app-row-item *ngIf="isStaff" label="er.profile.orso-date" data="{{ data?.orsoRegDate | ssmsDate }}" class="col-xs-6 col-sm-3 col-md-3 col-lg-3">
  </app-row-item>

  <app-row-item
    *ngIf="isStaff"
    label="payroll.orso-pap-sig"
    [data]="orsoAppSignalRender | fn : data?.orsoAppSignal"
    class="col-xs-6 col-sm-3 col-md-3 col-lg-3">
  </app-row-item> -->

  <div class="col-xs-12 l-mt-2" *ngIf="isStaff">
    <div class="cds-body1-demibold l-mb-4 bold">{{ 'er.profile.commission-information' | lang }}</div>
    <div class="cds-body2">
      {{ 'er.profile.commission-pool-number' | lang }}
      <span class="db-click cds-body1" (click)="gotoPool(data?.commissionPoolNumber)">{{ poolIdRender | fn : data?.commissionPoolNumber }}</span>
    </div>
  </div>
</div>

<div class="row l-pt-4 l-pb-4 anchor-parent" *ngIf="showTerminationDetail">
  <div class="anchor-element" id="terminationDetails"></div>
  <div class="col-xs-12 cds-color-green">
    <span class="cds-h4-demibold">{{ 'er.profile.termination-details' | lang }}</span>
  </div>
  <div class="col-xs-12">
    <div class="p-line"></div>
  </div>
  <app-row-item label="er.profile.scheme-date" data="{{ data?.schemeTerminationEffectiveDate | ssmsDate }}" class="col-xs-6 col-sm-3 col-md-3 col-lg-3">
  </app-row-item>
  <app-row-item label="er.profile.terminating-date" data="{{ data?.terminationCompletionDate | ssmsDate }}" class="col-xs-6 col-sm-3 col-md-3 col-lg-3">
  </app-row-item>
</div>

<ng-container *ngIf="detailShow">
  <div class="row l-pt-4 l-pb-4" *ngIf="isStaff">
    <div class="col-xs-12 cds-color-green anchor-parent">
      <div class="anchor-element" id="communicationMethod"></div>
      <span class="cds-h4-demibold">{{ 'er.profile.communication-method' | lang }}</span>
    </div>
    <div class="col-xs-12">
      <div class="p-line"></div>
    </div>

    <app-row-item label="er.profile.language-type" [data]="languageTypeRender | fn : data?.languageType" class="col-xs-6 col-sm-3 col-md-3 col-lg-3">
    </app-row-item>
    <app-row-item
      label="er.profile.communication-type"
      [data]="communicationTypeRender | fn : data?.communicationType"
      class="col-xs-6 col-sm-3 col-md-3 col-lg-3">
    </app-row-item>

    <app-row-item label="er.profile.consent-direct-marketing" [checked]="data?.directMarketingConsentFlag === 'Y'" class="col-xs-6 col-sm-3 col-md-3 col-lg-3">
    </app-row-item>
  </div>

  <div *ngIf="haveActivityCodePermission && isStaff" class="row l-pt-4 l-pb-4">
    <div class="col-xs-12 row middle-xs">
      <div class="cds-color-green l-mr-8 anchor-parent">
        <div class="anchor-element" id="activityCode"></div>
        <span class="cds-h4-demibold">{{ 'employer.activity-record' | lang }}</span>
      </div>
    </div>

    <div class="col-xs-12">
      <div class="p-line"></div>
    </div>

    <div class="total-score">
      <div class="score-area">
        <cds-icon class="l-mr-4" color="#282B3E" size="md" icon="celebration:award"></cds-icon>
        <div>
          <div class="cds-body1 title">Activity Score within 5 years:</div>
          <div class="cds-h4 text">{{ activityScore }}</div>
        </div>
      </div>

      <div class="col-xs-12">
        <div class="p-line"></div>
      </div>

      <div class="link-area" (click)="goToActivityCodeDetail(data?.id)">
        <cds-icon class="l-mr-2" color="#EC6453" icon="action:button_right_outlined"></cds-icon>
        <div class="text">View Completed Activity Records List</div>
      </div>
    </div>
  </div>

  <div class="row l-pt-4 l-pb-3" *ngIf="isStaff">
    <div class="col-xs-12 row middle-xs">
      <div class="cds-color-green l-mr-8 anchor-parent">
        <div class="anchor-element" id="comments"></div>
        <span class="cds-h4-demibold">{{ 'er.profile.comments' | lang }}</span>
      </div>

      <app-permission [role]="PermissionAccess.W" [permission]="PermissionItem.CUSTOMER_EMPLOYER">
        <div *ngIf="!commentsShow" (click)="editTrigger()" class="row l-plr-0 middle-xs pointer">
          <cds-icon class="l-mr-2" width="20px" height="20px" icon="action:edit"></cds-icon>
          <div class="icon-margin">
            <span class="cds-h6-demibold">Edit</span>
          </div>
        </div>
      </app-permission>

      <div *ngIf="!commentsShow" class="l-mr-8"></div>

      <div *ngIf="!commentsShow" (click)="showCommentsLog()" class="row l-plr-0 l-mr-4 middle-xs pointer">
        <cds-icon class="l-mr-2" width="20px" height="20px" icon="form:form_search"></cds-icon>
        <div class="icon-margin">
          <span class="cds-h6-demibold">View edit log history</span>
        </div>
      </div>

      <div *ngIf="commentsShow" (click)="editTrigger()" class="row l-plr-0 middle-xs pointer l-mr-8">
        <cds-icon class="l-mr-2" width="20px" height="20px" icon="action:edit"></cds-icon>
        <div class="icon-margin">
          <span class="cds-h6-demibold">Cancel Editing</span>
        </div>
      </div>
    </div>
    <div class="col-xs-12">
      <div class="p-line"></div>
    </div>

    <div *ngIf="commentsShow" class="col-xs-12 l-pt-2 l-pb-2">
      <div class="col-xs-12 row l-plr-0 l-pb-5">
        <!-- <textarea #comments [(ngModel)]="commentsData" (input)="validating(comments.value)" (compositionend)="validating(comments.value)" [maxlength]="1000"></textarea> -->
        <cds-textarea label="Edit Comment" [(ngModel)]="commentsData" (cdsChange)="validating($event)" [maxRows]="5" [maxlength]="1000"></cds-textarea>
      </div>
      <div class="row col-xs-12 l-plr-0 l-pb-4">
        <div class="l-mr-8">
          <cds-button (click)="reset()" [config]="resetConfig" label="{{ 'create.botton.reset' | lang }}"></cds-button>
        </div>
        <div>
          <cds-button (click)="confirm()" [config]="confirmConfig" label="{{ 'create.botton.submit' | lang }}"></cds-button>
        </div>
      </div>
    </div>

    <div *ngIf="!commentsShow" class="col-xs-12 l-pb-5">
      <div class="l-mb-5 cds-color-navy-light4">
        Latest update: <span>{{ updateDateGen | fn : data?.comments?.updatedAt }}</span
        >, by <span>{{ data?.comments?.updatedName }}</span>
      </div>
      <div>
        <span class="cds-body">{{ data?.comments?.comments }}</span>
      </div>
    </div>
  </div>
  <!-- <div class="row">
    <div *ngIf="!commentsShow" class="col-xs-12 l-pb-5">
      <div class="l-mb-5 cds-color-navy-light4">
        Latest update: <span>{{ updateDateGen | fn : data?.comments?.updatedAt }}</span
        >, by <span>{{ data?.comments?.updatedName }}</span>
      </div>
      <div>
        <span class="cds-body">{{ data?.comments?.comments }}</span>
      </div>
    </div>
  </div> -->

  <app-cd-accordion *ngIf="isStaff">
    <app-cd-accordion-panel title="{{ 'er.profile.others' | lang }}">
      <div class="row">
        <div class="col-xs-12 row l-plr-0">
          <app-row-item label="er.profile.source-fund" [data]="sourceOfFundAndWealthRender | fn : data?.sourceOfFund_wealth" class="col-xs-12 l-pt-2 l-pb-2">
          </app-row-item>

          <app-row-item label="er.profile.other-source-fund" [data]="data?.otherSourceOfFund_wealth" class="col-xs-12 l-pt-2 l-pb-2"> </app-row-item>

          <app-row-item
            label="er.profile.origin-initial-source"
            [data]="data?.originOfInitialSourceOfWealth"
            class="col-xs-12 l-pt-2 l-pb-2"
            toolTipString="For employer established for less than 3 years">
          </app-row-item>

          <app-row-item label="er.profile.origin-no-going-source" [data]="data?.originOfOnGoingSourceOfFunds_wealth" class="col-xs-12 l-pt-2 l-pb-2">
          </app-row-item>
        </div>
      </div>
    </app-cd-accordion-panel>
  </app-cd-accordion>

  <div class="row l-pt-4 l-pb-4" *ngIf="haveSpecialOfferPermission">
    <div class="col-xs-12 cds-color-green anchor-parent">
      <div class="anchor-element" id="specialOfferManagement"></div>
      <span class="cds-h4-demibold">{{ 'er.profile.special-offer-management' | lang }}</span>
    </div>
    <div class="col-xs-12">
      <div class="p-line"></div>
    </div>
    <div (click)="showOfferManagement()" class="row col-xs-12 l-mr-4 middle-xs pointer">
      <cds-icon class="l-mr-2" width="20px" height="20px" icon="form:form_search"></cds-icon>
      <div class="icon-margin">
        <span class="cds-h6-demibold">View Client Additional Information Details</span>
      </div>
    </div>
  </div>
</ng-container>

<div class="row l-pt-4 l-pb-8 l-pb-8" *ngIf="isStaff">
  <div class="col-xs-12">
    <div class="p-line"></div>
  </div>
  <div (click)="detailTrigger()" *ngIf="!detailShow" class="row col-xs-12 middle-xs l-pt-4 pointer">
    <div class="l-mr-2">
      <cds-icon color="#EC6453" icon="action:button_plus_outlined"></cds-icon>
    </div>
    <div>
      <span class="cds-body2-demibold">{{ 'er.profile.view-all-info' | lang }}</span>
    </div>
  </div>
  <div (click)="detailTrigger()" *ngIf="detailShow" class="row col-xs-12 middle-xs l-pt-4 pointer">
    <div class="l-mr-2">
      <cds-icon color="#EC6453" icon="action:button_minus_outlined"></cds-icon>
    </div>
    <div>
      <span class="cds-body2-demibold">{{ 'er.profile.view-less' | lang }}</span>
    </div>
  </div>
</div>
