import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { CdsColorTheme } from '@cds/ng-core/configuration';
import { CdsIconConfig } from '@cds/ng-core/icon';
import { ActionIcons, ArrowIcons, EnvironmentIcons } from '@cds/ng-themes';
import { CDS_POPUP_DATA } from '@cds/ng-web-components/popup';
import { Subscription } from 'rxjs';
import { EmployeeResponseData } from '../employee-summary/employee-response.model';
import { EmployerHistoryRequest } from '../employer-history/employer-history-request';
import { Items } from './er-change-summary-detail-response';
import { ErChangeSummaryDetailService } from './er-change-summary-detail.service';
import { Agents, ErChangeSummaryData } from './er-chenge-summary-data';

@Component({
  selector: 'app-er-change-summary-detail-pop',
  templateUrl: './er-change-summary-detail-pop.component.html',
  styleUrls: ['./er-change-summary-detail-pop.component.scss'],
})
export class ErChangeSummaryDetailPopComponent implements OnInit, OnDestroy {
  @Input() fullWidth = true;
  @Input() color: CdsColorTheme = 'ml';
  language = '';
  @Input() summaryPageCount = 1;
  @Input() summaryPage = 1;

  myRequest: EmployerHistoryRequest = { page: 1, size: 20 };
  employerDetailTotalCount = 0;
  private employerHistroyDetailScription?: Subscription;
  dataSource: Items[] = [];
  dataSourceTmp: EmployeeResponseData[] = [];

  totalcon = EnvironmentIcons.office_building;
  agentheadIcon = ArrowIcons.right;
  conselIcon = ActionIcons.button_x;
  status = '';
  targetAgent: Agents = {};
  oldAgent: Agents = {};
  employerName = '';

  constructor(
    public dialogRef: MatDialogRef<ErChangeSummaryDetailPopComponent>,
    // private elementRef: ElementRef,
    public erChangeSummaryDetailService: ErChangeSummaryDetailService,
    @Inject(CDS_POPUP_DATA)
    public data: { list: ErChangeSummaryData }
  ) {}

  ngOnDestroy(): void {
    this.employerHistroyDetailScription?.unsubscribe();
  }

  ngOnInit(): void {
    this.myRequest = this.data.list;
    this.myRequest.page = 1;
    this.myRequest.size = 20;
    this.language = this.data.list.language ? this.data.list.language : 'EN';
    this.targetAgent = this.data.list.sumbitAgent || {};
    this.oldAgent = this.data.list.oldAgent || {};
    this.employerName = this.data.list.employerName || '';
    this.status = this.data.list.status || '';

    document.getElementsByClassName('mat-dialog-container')[0]?.scrollTo(0, 0);
    this.pageChange(1);
    this.employerHistroyDetailScription = this.erChangeSummaryDetailService.employeeDataSubject.subscribe(response => {
      if (response.result != 0) {
        this.dataSource = [];
        this.employerDetailTotalCount = 0;
        this.summaryPageCount = 0;
      } else {
        this.dataSource = response.data && response.data.content ? response.data.content[0].items : [];
        this.employerDetailTotalCount = response.data ? response.data.totalElements : 0;
        this.summaryPageCount = response.data ? response.data.totalPages : 0;

        this.dataSource.forEach(d => {
          if (d.employeeData?.joinTheProgramDate) {
            const dateList = d.employeeData.joinTheProgramDate.split('-');
            d.employeeData.joinTheProgramDate = [dateList[2], dateList[1], dateList[0]].join('/');
          }
        });
      }
    });
  }

  closeDialog() {
    this.dialogRef.close('Pizza!');
  }
  totalIconConfig: CdsIconConfig = {
    size: 'md',
  };

  conselIconConfig: CdsIconConfig = {
    size: 'sm',
  };

  displayedColumns: string[] = ['certificateNumber', 'employeeName', 'sex', 'age', 'joinTheProgramDate', 'joinTheProgramDate'];

  pageChange(num: number): void {
    console.log('page');
    this.myRequest.page = num;
    this.summaryPage = num;
    this.erChangeSummaryDetailService.getDetailList(this.myRequest);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  searchByInputPageNo($event: any, pageNo: string) {
    $event.preventDefault();
    let goToPage = 1;
    if (isNaN(parseInt(pageNo))) {
      goToPage = 1;
    } else if (+pageNo > this.summaryPageCount) {
      goToPage = this.summaryPageCount;
    } else {
      goToPage = +pageNo;
    }

    this.pageChange(goToPage);
  }
  showTable = true;
  closePop() {
    this.dialogRef.close({ agree: false });
  }
}
