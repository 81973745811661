<app-loading *ngIf="isLoading"></app-loading>
<form [formGroup]="form" (ngSubmit)="saveRole()" *ngIf="!isLoading">
  <!-- create role template -->
  <div class="row-span">
    <div class="col-xs-8">
      <cds-textfield label="{{ 'role.name' | lang }}" placeholder="{{ 'role.name.placeholder' | lang }}" formControlName="roleName" type="text" required>
      </cds-textfield>
      <cds-assistive-text class="l-pt-3" *ngIf="roleName?.invalid && (roleName?.dirty || roleName?.touched)">
        <div *ngIf="roleName?.errors?.['required']">input is required</div>
        <div *ngIf="roleName?.errors?.['minlength']">Must be at least 3 characters long.</div>
        <div *ngIf="roleName?.errors?.['whitespace']">Begin or end with blank space is not allowed</div>
      </cds-assistive-text>
    </div>
    <div class="col-xs-4">
      <cds-button size="sm" (click)="deleteRole()" *ngIf="roleId" [disabled]="isLoading">Delete</cds-button>
    </div>
  </div>

  <div class="col-xs-12 l-pt-5">
    <span class="cds-h2-light">{{ 'role.accessRight' | lang }}</span>
  </div>
  <div class="col-xs-12">
    <span class="cds-detail1-demibold">{{ 'role.accessRight.notes' | lang }}</span>
  </div>
  <div class="col-xs-12" style="overflow: auto">
    <app-role-info-access-table [data]="formPermissions"></app-role-info-access-table>
  </div>
  <br /><br />
  <div class="col-xs-12">
    <cds-button [disabled]="!submittable()" [config]="{ style: 'primary', buttonType: 'submit' }" label="{{ 'common.save' | lang }}"></cds-button>
  </div>
</form>
