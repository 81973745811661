export class EmployeeDelegationChangeRequest {
  employerId?: string;
  memberId?: string;
  oldAgentCode?: string;
  targetAgentCode?: string;
  reason?: ChangeReason;
}

export interface ChangeReason {
  code?: number;
  message?: string;
}
