import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { CdsButtonConfig } from '@cds/ng-core/button';
import { CDS_POPUP_DATA } from '@cds/ng-web-components/popup';

@Component({
  selector: 'app-scb-statement-upload-success',
  templateUrl: './scb-statement-upload-success.component.html',
  styleUrls: ['./scb-statement-upload-success.component.scss'],
})
export class ScbStatementUploadSuccessComponent implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<ScbStatementUploadSuccessComponent>,
    @Inject(CDS_POPUP_DATA)
    public data: {
      total: number;
      updated: number;
    }
  ) {}

  disagreeButtonConfig: CdsButtonConfig = {
    style: 'primary',
  };

  agree() {
    this.dialogRef.close({ agree: false });
  }

  ngOnInit(): void {}
}
