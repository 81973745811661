import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-cd-switch',
  templateUrl: './cd-switch.component.html',
  styleUrls: ['./cd-switch.component.scss'],
})
export class CdSwitchComponent {
  _value!: boolean;
  @Input()
  set value(value: boolean | number) {
    this._value = Boolean(value);
  }
  get value() {
    return this._value;
  }

  @Output() valueChange = new EventEmitter<boolean>();

  @Input() beforeChange = new Observable<boolean>(observer => {
    observer.next(true);
    return () => {};
  });

  onModelChange(value: boolean) {
    this.value = value;
    this.valueChange.emit(value);
  }
}
