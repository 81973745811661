<div *ngIf="!group" class="regulated-activities-box">
  <div>
    <span class="cds-detail2">有關受規管活動</span>
  </div>
  <div class="l-mt-4">
    <span class="cds-body1-demibold">{{ 'sendIffInBatchSummary.havePerformedMPF2' | lang }}</span>
  </div>
  <div>
    <app-cd-radio
      *ngIf="formControl"
      class="cd-radio-margin-bottom-clear"
      #cdRadio
      [formControl]="formControl"
      [config]="config"
      ngDefaultControl></app-cd-radio>
    <app-cd-radio
      *ngIf="!formControl"
      class="cd-radio-margin-bottom-clear"
      #cdRadio
      [value]="value"
      (valueChange)="valueChange.emit($event)"
      [config]="config"
      ngDefaultControl></app-cd-radio>
  </div>
</div>
<div *ngIf="group && controlName" class="regulated-activities-box">
  <div>
    <span class="cds-detail2">有關受規管活動</span>
  </div>
  <div class="l-mt-4">
    <span class="cds-body1-demibold">{{ 'sendIffInBatchSummary.havePerformedMPF2' | lang }}</span>
  </div>
  <div>
    <app-cd-radio class="cd-radio-margin-bottom-clear" #cdRadio [group]="group" [controlName]="controlName" [config]="config" ngDefaultControl></app-cd-radio>
  </div>
</div>
