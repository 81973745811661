import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, CDS_POPUP_DATA } from '@cds/ng-web-components/popup';
import { CdsButtonConfig } from '@cds/ng-core/button';

@Component({
  selector: 'app-notififacionpopup',
  templateUrl: './notififacionpopup.component.html',
  styleUrls: ['./notififacionpopup.component.scss'],
})
export class NotififacionpopupComponent implements OnInit {
  agreeButtonConfig: CdsButtonConfig = {
    style: 'secondary',
  };
  disagreeButtonConfig: CdsButtonConfig = {
    style: 'primary',
  };
  constructor(private dialogRef: MatDialogRef<NotififacionpopupComponent>, @Inject(CDS_POPUP_DATA) public data: { name: string }) {}

  ngOnInit(): void {}

  agree() {
    this.dialogRef.close({ agree: false });
  }
  disagree() {
    this.dialogRef.close({ agree: true });
  }
}
