<div class="row">
  <!-- page menu -->
  <div class="col-xs-12 l-mt-7 l-mb-2">
    <span class="cds-body1"
      >This is a page to maintain the Manulife Agent list. If there is newly onboard agent, Operation team either upload enrolment excel in iPension or input
      directly on screen.
    </span>
  </div>
  <div class="col-xs-12 col-md-12 l-pb-3 l-d-f l-jc-sb l-mt-7">
    <div>
      <div class="cds-h2-light l-ma-0">{{ 'common.recordList' | lang }}</div>
      <div class="cds-detail1-demibold">Total {{ totalElements }} records</div>
    </div>
    <div *ngIf="canEdit && showButton">
      <cds-button [config]="uploadBtnCfg" size="sm" class="l-mr-5" (click)="upload()" [disabled]="isLoading"> Upload csv file </cds-button>
      <cds-button [config]="addBtnCfg" size="sm" (click)="add()" [disabled]="isLoading"> Add records </cds-button>
    </div>
  </div>

  <div class="col-xs-12 col-md-12">
    <div class="min-height">
      <app-data-table
        [frontPagination]="false"
        [isLoading]="isLoading"
        [fixed]="false"
        [overlay]="true"
        [pageConfig]="pageConfig"
        [dataSource]="dataSource"
        [columnsConfig]="canEdit ? columnsConfigWithEditPermission : columnsConfig"
        (reloadTable)="reloadTable($event)">
        <ng-template appDtBodyCellDef="agentName" let-element>
          <span>{{ element['agentName'] }}</span>
          <span *ngIf="element['agentNameChi']" class="l-ml-2">{{ element['agentNameChi'] }}</span>
        </ng-template>

        <ng-template appDtBodyCellDef="dob" let-element>
          {{ element['dob'] | ssmsDate }}
        </ng-template>

        <ng-template appDtBodyCellDef="hkid" let-element>
          {{ element['hkid'] || element['passport'] }}
        </ng-template>

        <ng-template appDtBodyCellDef="sepCommDate" let-element>
          {{ element['sepCommDate'] | ssmsDate }}
        </ng-template>

        <ng-template appDtBodyCellDef="createdAt" let-element>
          {{ element['createdAt'] | ssmsDate }}
        </ng-template>

        <ng-template appDtBodyCellDef="status" let-element>
          <app-icon-status [value]="element['status']" [statusMapObj]="enrolmentStatusMapObj"> </app-icon-status>
        </ng-template>

        <ng-template appDtBodyCellDef="action" let-element>
          <div *ngIf="element['status'] === EnrolmentStatusE.PENDING" class="button-area" (click)="edit(element)">
            <div class="icon-size">
              <cds-icon size="sm" icon="action:edit"></cds-icon>
            </div>
            <span class="cds-h6-demibold l-pl-2">Edit</span>
          </div>
        </ng-template>
      </app-data-table>
    </div>
  </div>
</div>
