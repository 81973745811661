<app-loading [overlay]="true" *ngIf="isLoading"></app-loading>

<div class="row l-pt-8 l-pb-4">
  <div class="l-d-f l-ai-cen detail-cursor">
    <div class="icon-size">
      <cds-icon size="sm" class="icon-color" color="orangered" icon="action:button_right_filled"></cds-icon>
    </div>
    <span class="l-ml-1">{{ 'ee.fund.viewPrivilegeRatesDetails' | lang }}</span>
  </div>
</div>

<div class="row l-pt-8 l-pb-4">
  <div class="col-xs-12 cds-color-green">
    <span class="cds-h4-demibold">{{ 'ee.fund.fundBalance' | lang }}</span>
  </div>
  <div class="col-xs-12">
    <div class="p-line"></div>
  </div>

  <div class="col-xs-12 date-area l-d-f l-ai-cen">
    <div class="icon-date-size">
      <cds-icon size="sm" class="icon-date-color" icon="action:calendar"></cds-icon>
    </div>
    <span class="l-ml-1">{{ 'ee.fund.asOfDate' | lang }} : {{ this.fundBalanceData.asOfDate | ssmsDate }}</span>
  </div>
  <div class="col-xs-12">
    <div class="p-line"></div>
  </div>
</div>

<div class="row">
  <div class="col-xs-12 overflow-auto">
    <div class="count-container">
      <div class="total">
        <div class="title">{{ 'ee.fund.fundBalanceTotal' | lang }}</div>
        <div class="content">
          <div class="unit">{{ this.fundBalanceData.currency }}</div>
          <div class="number cds-color-green">{{ this.fundBalanceData.fundBalanceTotal | number : '1.2-2' }}</div>
        </div>
      </div>

      <div class="operator">=</div>

      <div class="item">
        <div class="title">{{ 'ee.fund.netContributionsNetTransferIn' | lang }}</div>
        <div class="content">
          <span>{{ this.fundBalanceData.currency }}</span>
          <span>{{ this.fundBalanceData.netContributionsAndNetTransferIn | number : '1.2-2' }}</span>
        </div>
        <div class="sub-title">{{ 'ee.fund.sinceInception' | lang }}</div>
      </div>

      <div class="operator">+</div>

      <div class="item">
        <div class="title">{{ 'ee.fund.accountGainLoss' | lang }}</div>
        <div class="content">
          <span>{{ this.fundBalanceData.currency }}</span>
          <span>({{ this.fundBalanceData.netContributionsAndNetTransferIn | number : '1.2-2' }})</span>
        </div>
        <div class="sub-title">{{ 'ee.fund.sinceInception' | lang }}</div>
      </div>
    </div>

    <div class="m-content" *ngIf="!isStaff">
      <div class="m-sub-title m-g">{{ 'ee.fund.including' | lang }}</div>
      <div class="m-g">
        <div class="p-line"></div>
      </div>
      <div class="m-area m-g">
        <div class="l-f-t">{{ 'ee.fund.totalContributionAssetTransferIn' | lang }}</div>
        <div class="l-r-t m-g-l">{{ this.fundBalanceData.currency }} {{ this.fundBalanceData.totalContributionOrAssetTransferIn }}</div>
      </div>
      <div class="m-area m-g">
        <div class="l-f-t">{{ 'ee.fund.totalWithdrawalAssetTransferOut' | lang }}</div>
        <div class="l-r-t m-g-l">{{ this.fundBalanceData.currency }} {{ this.fundBalanceData.totalWithdrawalAssetTransferOut }}</div>
      </div>
      <div class="m-sub-title m-g">{{ 'ee.fund.lastTransferIn' | lang }}</div>
      <div class="m-g">
        <div class="p-line"></div>
      </div>
      <div class="m-area m-g">
        <div class="l-f-t">{{ 'ee.fund.dateOfTransfer' | lang }}</div>
        <div class="l-r-t m-g-l">{{ this.fundBalanceData.currency }} {{ this.fundBalanceData.dateOfTransfer }}</div>
      </div>
      <div class="m-area m-g">
        <div class="l-f-t">{{ 'ee.fund.fundTransactionFormType' | lang }}</div>
        <div class="l-r-t m-g-l">{{ this.fundBalanceData.currency }} {{ getFormType(this.fundBalanceData.fundTransactionFormType) }}</div>
      </div>
    </div>
  </div>
</div>

<div class="row l-mt-5" style="margin-top: 20px">
  <div class="col-xs-12 overflow-auto">
    <table class="fund-table">
      <tr class="tb-title l-pa-2">
        <td class="b-r">Fund Code/ Fund Name</td>
        <td class="b-r">Mandatory Contribution (Employer and Employee)</td>
        <td>Voluntary Contribution (Employer and Employee)</td>
        <td>Former Employment Mandatory Contribution</td>
        <td>Former Employment Voluntary Contribution</td>
      </tr>
      <tr class="l-pa-2" *ngFor="let item of this.fundBalanceData.fundBalanceList">
        <td class="b-r">{{ item.fundCodeOrFundName }}</td>
        <td class="b-r">
          <div class="tb-content l-d-f l-ai-cen">
            <div class="tb-item l-d-f l-fd-col l-jc-sa">
              <div class="item-top">ERMC</div>
              <div class="item-bottom l-mt-2">{{ item.currency }}: {{ item.mandatoryContributionForERMC }}</div>
            </div>
            <div class="tb-item l-d-f l-fd-col l-jc-sa">
              <div class="item-top">EEMC</div>
              <div class="item-bottom l-mt-2">{{ item.currency }}: {{ item.mandatoryContributionForEEMC }}</div>
            </div>
          </div>
        </td>
        <td>
          <div class="tb-content l-d-f l-ai-cen">
            <div class="tb-item l-d-f l-fd-col l-jc-sa">
              <div class="item-top">ERVC</div>
              <div class="item-bottom l-mt-2">{{ item.currency }}: {{ item.voluntaryContributionERVC }}</div>
            </div>
            <div class="tb-item l-d-f l-fd-col l-jc-sa">
              <div class="item-top">EEVC</div>
              <div class="item-bottom l-mt-2">{{ item.currency }}: {{ item.voluntaryContributionEEVC }}</div>
            </div>
          </div>
        </td>
        <td>
          <div class="tb-item l-d-f l-fd-col l-jc-sa">
            <div class="item-top">ERMC</div>
            <div class="item-bottom l-mt-2">{{ item.currency }}: {{ item.formerEmploymentMandatoryContributionERMC }}</div>
          </div>
        </td>
        <td>
          <div class="tb-item l-d-f l-fd-col l-jc-sa">
            <div class="item-top">ERMC</div>
            <div class="item-bottom l-mt-2">{{ item.currency }}: {{ item.formerEmploymentVoluntaryContributionERMC }}</div>
          </div>
        </td>
      </tr>
    </table>
  </div>
</div>

<ng-container>
  <div class="row l-pt-8 l-pb-4">
    <div class="col-xs-12 cds-color-green l-d-f l-ai-cen l-jc-sb">
      <span class="cds-h4-demibold">{{ 'ee.fund.investedFundInformation' | lang }}</span>
      <div class="row col-xs-offset-3">
        <cds-button (click)="expandAll()" [config]="config">Expand All</cds-button>
      </div>
    </div>
    <div class="col-xs-12">
      <div class="p-line"></div>
    </div>

    <div class="col-xs-12 date-area l-d-f l-ai-cen">
      <div class="icon-date-size">
        <cds-icon size="sm" class="icon-date-color" icon="action:calendar"></cds-icon>
      </div>
      <span class="l-ml-1">{{ 'ee.fund.asOfDate' | lang }} : {{ this.investedFundInformationResponseData.asOfDate | ssmsDate }}</span>
    </div>
    <div class="col-xs-12">
      <div class="p-line"></div>
    </div>
  </div>

  <app-cd-accordion *ngFor="let item of this.investedFundInformationResponseData.investedFundInfo">
    <app-cd-accordion-panel [title]="titleGen | fn : item" [expand]="item.show">
      <app-row-item label="Unit Price" [data]="item.unitPrice" class="col-xs-6 col-sm-3 col-md-3 col-lg-3"> </app-row-item>
      <app-row-item label="No.of Unit" [data]="item.noOfUnit" class="col-xs-6 col-sm-3 col-md-3 col-lg-3"> </app-row-item>
      <app-row-item label="Fund Balance" [data]="item.fundBalance" class="col-xs-6 col-sm-3 col-md-3 col-lg-3"> </app-row-item>
      <app-row-item label="Fund Currency" [data]="item.fundCurrency" class="col-xs-6 col-sm-3 col-md-3 col-lg-3"> </app-row-item>
      <app-row-item label="EE New BU Flag" [data]="item.eeNewBUFlag" [checked]="false" class="col-xs-12 col-sm-12 col-md-12 col-lg-12"> </app-row-item>
      <div class="fund-area">
        <!-- <div class="cds-body2 fund-title title-color">
          Latest update: 9:05am 25/02/2022 (+8T)
        </div> -->

        <div class="l-d-f l-fd-col l-jc-sb content-item no-padding area-block">
          <div class="l-d-f">
            <div class="l-d-f cds-body2 no-margin fund-title-color no-padding v-center">FSEE/EEMC %</div>
            <div class="cds-body1 no-margin fund-title-color-sub margin-left-common">
              {{ item.fseeoreemc | percent : '1.2-2' }}
            </div>
          </div>
          <div class="l-d-f margin-top-common">
            <div class="l-d-f cds-body2 no-margin fund-title-color no-padding v-center">FSER/ERMC %</div>
            <div class="cds-body1 no-margin fund-title-color-sub margin-left-common">
              {{ item.fserorermc | percent : '1.2-2' }}
            </div>
          </div>
        </div>

        <div class="div-col-line margin-left-common"></div>

        <div class="l-d-f l-fd-col l-jc-sb content-item no-padding margin-left-common area-block">
          <div class="l-d-f">
            <div class="l-d-f cds-body2 no-margin fund-title-color no-padding v-center">PSEE/EEVC %</div>
            <div class="cds-body1 no-margin fund-title-color-sub margin-left-common">
              {{ item.pseeoreevc | percent : '1.2-2' }}
            </div>
          </div>
          <div class="l-d-f margin-top-common">
            <div class="l-d-f cds-body2 no-margin fund-title-color no-padding v-center">PSER/ERVC %</div>
            <div class="cds-body1 no-margin fund-title-color-sub margin-left-common">
              {{ item.pserorervc | percent : '1.2-2' }}
            </div>
          </div>
        </div>
      </div>
    </app-cd-accordion-panel>
  </app-cd-accordion>
</ng-container>

<div class="row l-pt-8 l-pb-4">
  <div class="col-xs-12 cds-color-green l-d-f l-ai-cen">
    <span class="cds-h4-demibold">Transaction Record</span>
  </div>
  <div class="col-xs-12">
    <div class="p-line"></div>
  </div>
</div>

<div class="row">
  <div [formGroup]="form" *ngIf="formReady">
    <div class="col-xs-12 l-pt-7">
      <span class="cds-h2-light">{{ 'list.serach-record' | lang }}</span>
    </div>
    <div class="row l-plr-0">
      <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 l-pt-4 l-pb-4">
        <cds-textfield formControlName="referenceNo" label="Reference No." placeholder="Input Reference No"></cds-textfield>

        <div *ngIf="referenceNoControl?.invalid && (referenceNoControl?.dirty || referenceNoControl?.touched)" class="l-pt-2">
          <cds-assistive-text *ngIf="referenceNoControl?.errors?.['maxlength']" class="l-pt-2" [showIcon]="false">Max length is 15</cds-assistive-text>

          <cds-assistive-text
            *ngIf="referenceNoControl?.errors?.['invalidNumberString']"
            class="l-pt-2"
            [showIcon]="false"
            >{{ referenceNoControl?.errors?.['invalidNumberString'] }}</cds-assistive-text
          >
        </div>
      </div>
      <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 l-pt-4 l-pb-4">
        <cds-textfield formControlName="groupReferenceNo" label="Group Reference No" placeholder="Group Reference No"></cds-textfield>

        <div *ngIf="groupReferenceNoControl?.invalid && (groupReferenceNoControl?.dirty || groupReferenceNoControl?.touched)" class="l-pt-2">
          <cds-assistive-text *ngIf="groupReferenceNoControl?.errors?.['maxlength']" class="l-pt-2" [showIcon]="false">Max length is 200</cds-assistive-text>

          <cds-assistive-text
            *ngIf="groupReferenceNoControl?.errors?.['descriptionInvalid']"
            class="l-pt-2"
            [showIcon]="false"
            >{{ groupReferenceNoControl?.errors?.['descriptionInvalid'] }}</cds-assistive-text
          >
        </div>
      </div>

      <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 l-pt-4 l-pb-4 my-content">
        <cds-dropdown formControlName="statusCode" [config]="StatusCodeConfig"></cds-dropdown>
      </div>
      <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 l-pt-4 l-pb-4 my-content">
        <cds-dropdown formControlName="activityType" [config]="ActivityConfig"></cds-dropdown>
      </div>
      <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 l-pt-4 l-pb-4 my-content">
        <cds-dropdown formControlName="subActivityType" [config]="SubActivityConfig"></cds-dropdown>
      </div>
      <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 l-pt-4 l-pb-4 my-content date-picker-area relative">
        <div class="col-xs-12 date-picker">
          <cds-textfield formControlName="effectiveDate" placeholder="DD/MM/YYYY" label="Effective Date" [max]="getMaxFromDate" [cdsDatepicker]="startPicker">
          </cds-textfield>
          <cds-datepicker #startPicker="cdsDatepicker"></cds-datepicker>
        </div>
      </div>

      <div class="row col-xs-12 col-sm-6 col-md-4 col-lg-3 l-pt-4 l-pb-4 date-picker-area relative">
        <div class="col-xs-12 date-picker">
          <cds-textfield formControlName="createdDate" placeholder="DD/MM/YYYY" label="Created Date" [max]="getMaxFromDate" [cdsDatepicker]="startPicker2">
          </cds-textfield>
          <cds-datepicker #startPicker2="cdsDatepicker"></cds-datepicker>
        </div>
      </div>

      <div class="row col-xs-12 col-sm-12 col-md-6 col-lg-3 bottom-xs l-pb-4 l-plr-0">
        <cds-button
          class="l-mr-4 botton"
          [disabled]="searchButtonDisabled"
          [config]="searchButtonConfig"
          label="{{ 'list.search' | lang }}"
          (click)="search()"></cds-button>
        <cds-button
          class="botton"
          [disabled]="resetButtonDisabled"
          [config]="resetButtonConfig"
          label="{{ 'list.reset' | lang }}"
          (click)="reset()"></cds-button>
      </div>
    </div>
  </div>

  <div class="col-xs-12 col-md-12 l-pb-3 l-d-f l-jc-sb l-mt-7 relative">
    <div>
      <div class="cds-h2-light l-ma-0">{{ 'common.recordList' | lang }}</div>
      <div class="cds-detail1-demibold">Total {{ _totalCount | number }} records</div>
    </div>
  </div>

  <div class="col-xs-12 table-container">
    <app-data-table
      [frontPagination]="false"
      [isLoading]="_progress"
      [pageConfig]="pageConfig"
      [fixed]="false"
      [overlay]="true"
      [dataSource]="_recordList"
      [columnsConfig]="columnsConfig"
      (reloadTable)="reloadTable($event)">
      <ng-template appDtBodyCellDef="referenceNo" let-element>
        <div class="min-width-300 code-and-name">
          <span class="green-line pointer" (click)="goToPopUp(element)">{{ element.referenceNo }}</span>
        </div>
      </ng-template>
      <ng-template appDtBodyCellDef="groupReferenceNo" let-element>
        <div class="min-width-300 code-and-name">
          <div class="code">{{ element['groupReferenceNo'] }}</div>
        </div>
      </ng-template>

      <ng-template appDtBodyCellDef="statusCode" let-element>
        <div class="min-width-300 l-d-f l-ai-cen">
          <div class="color-dot" [style]="'--custom-color:' + getStatusColor(element['statusCode']) + ';'"></div>
          <div class="code l-ml-2">{{ element['statusCode'] }}</div>
        </div>
      </ng-template>

      <ng-template appDtBodyCellDef="activityType" let-element>
        <div class="min-width-200 code-and-name">
          <div class="code">{{ element.activityType }}</div>
        </div>
      </ng-template>

      <ng-template appDtBodyCellDef="subActivityType" let-element>
        <div class="min-width-200 code-and-name">
          <div class="code">{{ element.subActivityType }}</div>
        </div>
      </ng-template>

      <ng-template appDtBodyCellDef="effectiveDate" let-element>
        {{ element['effectiveDate'] | ssmsDate }}
      </ng-template>
      <ng-template appDtBodyCellDef="createDate" let-element>
        <div class="l-d-f l-ai-cen">
          <span class="l-ml-1"> {{ element['createDate'] | ssmsDate }}</span>

          <div class="icon-size l-ml-8 detail-cursor" (click)="goToPopUp(element)">
            <cds-icon size="sm" class="icon-color" color="orangered" icon="action:right_outlined"></cds-icon>
          </div>
        </div>
      </ng-template>
    </app-data-table>
  </div>
</div>
