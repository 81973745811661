import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CdsIconModule } from '@cds/ng-web-components/icon';
import { CdsProgressModule } from '@cds/ng-web-components/progress';
import { InputAutocompleteComponent } from './input-autocomplete.component';

@NgModule({
  declarations: [InputAutocompleteComponent],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, CdsIconModule, CdsProgressModule],
  exports: [InputAutocompleteComponent],
})
export class DynamicAutocompleteModule {}
