/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { BasicResponse } from '../../models/response/response';

@Injectable({
  providedIn: 'root',
})
export class OtpService {
  constructor(private http: HttpClient) {}

  login(params: any): Observable<BasicResponse<any>> {
    return this.http.post<BasicResponse<any>>(environment.apiPrefix.concat(environment.manutouchServiceInt).concat('/authority/login'), params);
  }

  requestOTP(params: any): Observable<BasicResponse<any>> {
    return this.http.post<BasicResponse<any>>(environment.apiPrefix.concat(environment.manutouchServiceInt).concat('/authority/otp/request'), params);
  }

  verifyOTP(params: any): Observable<BasicResponse<any>> {
    return this.http.post<BasicResponse<any>>(environment.apiPrefix.concat(environment.manutouchServiceInt).concat('/authority/otp/verify'), params);
  }
}
