import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MatAutocompleteModule } from '@angular/material/autocomplete';

import { CdsLanguageModule } from '@cds/ng-core/lang';
import { CdsIconModule } from '@cds/ng-web-components/icon';
import { CdDropdownComponent } from './cd-dropdown.component';
import { MatInputModule } from '@angular/material/input';
import { CdAutocompleteModule } from '../cd-autocomplete/cd-autocomplete.module';

@NgModule({
  imports: [CommonModule, FormsModule, ReactiveFormsModule, MatAutocompleteModule, CdsIconModule, CdsLanguageModule, MatInputModule, CdAutocompleteModule],
  declarations: [CdDropdownComponent],
  exports: [CdDropdownComponent],
})
export class CdDropdownModule {}
