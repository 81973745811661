import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CdsIconModule } from '@cds/ng-web-components/icon';
import { CdsTableModule } from '@cds/ng-data-table/table';
import { CdsLanguageModule } from '@cds/ng-core/lang';
import { LoadingModule } from '../loading/loading.modules';
import { AppPaginationModule } from '../pagination/pagination.module';
import { NoResultFoundModule } from './../no-result-found/no-result-found.module';

import { DataTableComponent } from './data-table.component';

import { DtHeaderCellDefDirective, DtBodyCellDefDirective } from './data-table.component.directive';
@NgModule({
  declarations: [DataTableComponent, DtHeaderCellDefDirective, DtBodyCellDefDirective],
  imports: [CommonModule, AppPaginationModule, LoadingModule, NoResultFoundModule, CdsTableModule, CdsIconModule, CdsLanguageModule],
  exports: [DataTableComponent, DtHeaderCellDefDirective, DtBodyCellDefDirective],
})
export class DataTableModule {}
