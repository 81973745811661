import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { CdsIconModule } from '@cds/ng-web-components/icon';
import { CdsSearchModule } from '@cds/ng-web-components/search';
import { CdsTableModule } from '@cds/ng-data-table/table';
import { CdsPaginationModule } from '@cds/ng-web-components/pagination';
import { CdsProgressModule } from '@cds/ng-web-components/progress';

import { ReportCenterComponent } from './report-center/report-center.component';
import { ReportCenterRoutingModule } from './report-center-routing.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { AgentProfileListComponent } from './agent-profile-list/agent-profile-list.component';
import { CdInputModule } from 'src/app/components/cd-input/cd-input.module';
import { CdsDatepickerModule } from '@cds/ng-web-components/datepicker';
import { CdsLanguageModule } from '@cds/ng-core/lang';
import { CdsAssistiveTextModule } from '@cds/ng-web-components/assistive-text';
import { CdsButtonModule } from '@cds/ng-web-components/button';
import { NarrowSearchPopupComponent } from './agent-profile-list/narrow-search-popup/narrow-search-popup.component';
import { ReportFilterComponent } from './report-center/report-filter/report-filter.component';
import { ReportExtractionComponent } from './report-extraction/report-extraction.component';
import { CdsDropdownModule } from '@cds/ng-web-components/dropdown';

@NgModule({
  declarations: [ReportCenterComponent, AgentProfileListComponent, NarrowSearchPopupComponent, ReportFilterComponent, ReportExtractionComponent],
  imports: [
    CommonModule,
    ReportCenterRoutingModule,
    FormsModule,
    SharedModule,
    ReactiveFormsModule,
    CdsIconModule,
    CdsSearchModule,
    CdsTableModule,
    CdsPaginationModule,
    CdsProgressModule,
    CdInputModule,
    CdsDatepickerModule,
    CdsLanguageModule,
    CdsAssistiveTextModule,
    CdsButtonModule,
    CdsDropdownModule,
  ],
})
export class ReportCenterModule {}
