import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-cd-intragroup-transfer-checkbox',
  templateUrl: './cd-intragroup-transfer-checkbox.component.html',
  styleUrls: ['./cd-intragroup-transfer-checkbox.component.scss'],
})
export class CdIntragroupTransferCheckboxComponent implements OnInit {
  @Input() formControl?: FormControl;

  constructor() {}

  ngOnInit(): void {}
}
