import { Component, OnInit } from '@angular/core';
import { CdsPopupService, MatDialogRef } from '@cds/ng-web-components/popup';
import { UserAgreementPopupComponent } from 'src/app/views/commission/user-agreement-popup.component';
import { I18N_KEY } from '../../calendar.config';

@Component({
  selector: 'app-review-report-number',
  templateUrl: './review-report-number.component.html',
  styleUrls: ['./review-report-number.component.scss'],
})
export class ReviewReportNumberComponent implements OnInit {
  constructor(private dialogRef: MatDialogRef<ReviewReportNumberComponent>, private cdsPopup: CdsPopupService) {}

  ngOnInit(): void {}

  decline() {
    const popupRef: MatDialogRef<UserAgreementPopupComponent> = this.cdsPopup.open(UserAgreementPopupComponent, {
      size: 'md',
      data: {
        message: I18N_KEY.CALENDAR_DECLINE_MSG,
        cancel: I18N_KEY.COMMON_CANCEL,
        continue: I18N_KEY.CALENDAR_DECLINE,
        type: 'confirm',
      },
    });
    popupRef.afterClosed().subscribe(confirm => {
      if (confirm.agree) {
        this.approveRequest(false);
      }
    });
  }

  onSubmit() {
    this.approveRequest(true);
  }

  approveRequest(isApprove: boolean) {
    const popConfig = {
      message: I18N_KEY.COMMON_ACTION_SUCCESS,
      continue: I18N_KEY.COMMON_GOT,
      type: 'alert',
    };

    const popupRef: MatDialogRef<UserAgreementPopupComponent> = this.cdsPopup.open(UserAgreementPopupComponent, { data: popConfig });
    popupRef.afterClosed().subscribe(confirm => {
      if (confirm.agree) {
        this.dialogRef.close({
          agree: true,
          isApprove,
        });
      }
    });
  }
}
