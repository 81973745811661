/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { BasicResponse } from 'src/app/core/models/response/response';

@Injectable({
  providedIn: 'root',
})
export class CommissionDefaultScaleService {
  scenario: any;
  accountType: any;
  constructor(private http: HttpClient) {}

  getList(params: any): Observable<BasicResponse<any>> {
    return this.http.get<BasicResponse<any>>(`${environment.apiPrefix}${environment.commissionServiceExt}/commission-default-scale/list`, { params: params });
  }

  update(params: any): Observable<BasicResponse<any>> {
    return this.http.post<BasicResponse<any>>(`${environment.apiPrefix}${environment.commissionServiceExt}/commission-default-scale/update`, params);
  }

  getScaleCode(params: any): Observable<BasicResponse<any>> {
    return this.http.get<BasicResponse<any>>(`${environment.apiPrefix}${environment.commissionServiceExt}/commission-scale/list`, { params: params });
  }
}
