<div class="col-xs-12 l-mb-2 container">
  <div class="l-pb-4 l-pt-4 col-xs-12" [class.line-bord-show]="expand">
    <div (click)="trigger()" class="pointer title-container">
      <div class="expand-icon">
        <cds-icon [width]="size" [height]="size" [@openClose]="_iconClass" [config]="_config" [icon]="icon"></cds-icon>
      </div>

      <div class="l-pt-2 l-pb-2">
        <div class="cds-body1-demibold font-weight">{{ 'termination.list.eMpfAcount' | lang }}:</div>
        <div class="line-break">{{ data.empfMemberAccountNumber }}</div>
      </div>
    </div>

    <div class="l-pt-2 l-pb-2 cds-body2">
      <div>
        {{ 'termination.list.dateOfAssignment' | lang }}:
        <span>{{ data.dateOfAssignment | date : 'dd/MM/yyyy' }}</span>
      </div>
    </div>

    <div class="l-pb-6 line-bord-show cds-body2">
      <div>
        {{ 'termination.list.terminationDate' | lang }}:
        <span>{{ data.terminationDate | date : 'dd/MM/yyyy' }}</span>
      </div>
    </div>

    <app-row-item label="termination.list.CompanyName" data="{{ data.companyName + ' ' + data.chineseCompanyName }}" class="l-pt-4 l-pb-4"> </app-row-item>

    <app-row-item label="termination.list.EmployeeName" data="{{ data.empolyeeName }}" class="l-pt-4 l-pb-4"> </app-row-item>

    <app-row-item label="termination.list.targetRolloverDate" data="{{ data.autoRolloverDate | date : 'dd/MM/yyyy' }}" class="l-pt-4 l-pb-4"> </app-row-item>

    <div class="l-plr-0 l-pt-2 l-mb-0 cds-body2">No. of Contact</div>
    <!-- do not use [(ngModel)] -->
    <div class="dropdown-area">
      <cds-dropdown
        class="dropdown"
        [config]="noOfContactConfig"
        [ngModel]="data.numberOfContact"
        (ngModelChange)="noOfContactChange($event, data)"></cds-dropdown>
      <cds-icon class="clear-icon" icon="action:button_x_filled" (click)="clearDropdown(data)" [config]="clearIconConfig"></cds-icon>
    </div>

    <div class="l-plr-0 l-pt-2 l-mb-1 cds-body2">Asked Client?</div>
    <!-- do not use [(ngModel)] -->
    <cds-checkbox
      class="l-mb-3"
      [ngModel]="data.askClient"
      (ngModelChange)="askedLientChange($event, data)"
      [disabled]="!data.numberOfContact"
      label=""></cds-checkbox>

    <div class="l-plr-0 l-pt-2 l-mb-0 cds-body2">Agent Follow Up Status</div>
    <!-- do not use [(ngModel)] -->
    <div class="dropdown-area2">
      <cds-dropdown
        class="dropdown2"
        [config]="data.askClient ? dropdownConfig : dropdownConfig2"
        [ngModel]="data.agentFollowUpStatus"
        (ngModelChange)="agentFollowUpStatusChange($event, data)"></cds-dropdown>
      <cds-icon class="clear-icon" icon="action:button_x_filled" (click)="clearDropdown2(data)" [config]="clearIconConfig"></cds-icon>
    </div>
  </div>

  <div [@openClose]="_detailClass" class="col-xs-12">
    <div class="l-mt-4 l-mb-2">
      <span class="cds-body1-ita font-weight font-italic">{{ 'termination.list.detailTitle' | lang }}</span>
    </div>

    <app-row-item label="termination.list.employeeAge" data="{{ data.age }}" class="l-pt-4 l-pb-4"> </app-row-item>

    <app-row-item label="termination.list.employeeGender" data="{{ data.gender }}" class="l-pt-4 l-pb-4"> </app-row-item>

    <app-row-item
      label="termination.list.employeeMobileNo"
      data="{{ data.employeeMobileNo }}"
      icon="social_media:whatsapp"
      iconColor="#00A758"
      (funCall)="service.redirectToWhatsapp(data)"
      class="l-pt-4">
    </app-row-item>

    <app-row-item label="termination.list.employeeEmailAddress" class="col-xs-3 l-pt-4 l-pb-4">
      <ng-template appRowItemContentDef>
        <div class="flex">
          <span class="">{{ data.emailAddress }}</span>
          <cds-icon
            class="icon-margin l-ml-1 icon-color"
            icon="contact:email"
            color="#fff"
            (click)="service.redirectToEmail(data, 'zh')"
            cdsTooltip="chinese"></cds-icon>
          <span class="l-ml-1">Chinese</span>
          <cds-icon
            class="icon-margin l-ml-1 icon-color"
            icon="contact:email"
            color="#fff"
            cdsTooltip="English"
            (click)="service.redirectToEmail(data, 'en')"></cds-icon>
          <span class="l-ml-1">English</span>
        </div>
      </ng-template>
    </app-row-item>
  </div>
</div>
