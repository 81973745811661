import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { CdsButtonConfig } from '@cds/ng-core/button';
import { ColumnConfig } from 'src/app/shared/data-table';

@Component({
  selector: 'app-address-type-definition-popup',
  templateUrl: './address-type-definition-popup.component.html',
  styleUrls: ['./address-type-definition-popup.component.scss'],
})
export class AddressTypeDefinitionPopupComponent implements OnInit {
  buttonConfig: CdsButtonConfig = { size: 'sm' };

  columnsConfig: ColumnConfig[] = [
    {
      key: 'type',
      title: '',
    },
    {
      key: 'definition',
      title: 'Definition',
    },
  ];

  dataSource = [
    {
      type: 'CORRESP',
      definition: 'Correspondence Address',
    },
    {
      type: 'RESIDENTIAL',
      definition: 'Residential Address',
    },
    {
      type: 'REGISTERED',
      definition: 'Registered Office Address ',
    },
    {
      type: 'BUSINESS',
      definition: 'Business Address ',
    },
    {
      type: 'OVERSEAS',
      definition: 'Overseas Settlement Address',
    },
    {
      type: 'SALES_PROPERTY',
      definition: 'Address of the Sale of Property',
    },
  ];

  constructor(private dialogRef: MatDialogRef<AddressTypeDefinitionPopupComponent>) {}

  ngOnInit(): void {}

  close() {
    this.dialogRef.close();
  }
}
