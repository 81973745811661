<div class="cd-download-progress" *ngIf="show">
  <div class="l-d-f l-ai-cen font-weight-6">
    <cds-icon class="l-mr-4" size="md" icon="form:save_file"></cds-icon>
    <div class="l-fg-1 l-pb-2">
      <div class="l-mb-3">File Downloading...</div>
      <cds-progress type="bar" mode="indeterminate"></cds-progress>
    </div>
  </div>
  <cds-icon (click)="btnXOnClick()" size="md" style="cursor: pointer" icon="action:button_x"></cds-icon>
</div>
