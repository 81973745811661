import { Directive, Output, EventEmitter, ElementRef, AfterViewInit, OnDestroy } from '@angular/core';

import { Subscription, fromEvent } from 'rxjs';

import { ParentElement } from 'src/app/views/salesjourney/case-summary/case-summary.model';

@Directive({
  selector: '[appOutsideClose]',
})
export class OutsideCloseDirective implements AfterViewInit, OnDestroy {
  @Output() outsideEvent = new EventEmitter();
  private unDocumentClick!: Subscription;
  private clickIsClose = true;

  constructor(private el: ElementRef) {}

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.unDocumentClick = fromEvent(document, 'click').subscribe(this.clickFun.bind(this));
    }, 0);
  }

  clickFun(event: Event) {
    this.clickIsClose = true;
    const newEvent = event as unknown as {
      target: { classList: DOMTokenList; parentElement: ParentElement };
    };
    this.checkStyle(newEvent.target, () => {
      this.clickIsClose = false;
    });
    if (this.clickIsClose) {
      this.outsideEvent.emit();
    }
  }

  private checkStyle(element: ParentElement, callback: () => void) {
    if (element !== this.el.nativeElement) {
      if (element.parentElement) {
        this.checkStyle(element.parentElement, callback);
      }
    } else {
      callback();
    }
  }

  ngOnDestroy(): void {
    this.unDocumentClick.unsubscribe();
  }
}
