<ng-container [formGroup]="form" *ngIf="formReady">
  <div class="row l-mb-4">
    <div class="type-select l-pt-7">
      <cds-icon icon="form:form_search" [config]="iconConfig" width="100px" height="100px"></cds-icon>
      <cds-dropdown formControlName="extractionType" [config]="extractionTypeConfig" (cdsChange)="onTypeChange($event)"></cds-dropdown>
    </div>
  </div>

  <div class="row">
    <div class="col-xs-12 l-pt-7">
      <span class="cds-h2-light">{{ 'commission.report-extraction.search' | lang }}</span>
    </div>
    <div class="row no-padding search-container">
      <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 l-pt-4 l-pb-4">
        <cds-textfield
          formControlName="employerAccountCode"
          label="Employer Account No."
          placeholder="Input Employer Account No."
          (cdsChange)="employerAccountCodeChange($event)"></cds-textfield>

        <div *ngIf="employerAccountCodeControl?.invalid && (employerAccountCodeControl?.dirty || employerAccountCodeControl?.touched)" class="l-pt-2 absolute">
          <cds-assistive-text *ngIf="employerAccountCodeControl?.errors?.['required']" class="l-pt-2" [showIcon]="false"
            >Please input Employer Account Code</cds-assistive-text
          >
        </div>
      </div>

      <div class="row col-xs-12 col-sm-12 col-md-8 col-lg-6 l-pt-4 l-pb-4 l-plr-0 date-picker-area relative">
        <div class="col-xs-6 date-picker">
          <cds-textfield formControlName="payrollGroupIdFrom" label="Payroll Group ID From" placeholder="Input Payroll Group ID From"></cds-textfield>

          <div *ngIf="payrollGroupIdFromControl?.invalid && (payrollGroupIdFromControl?.dirty || payrollGroupIdFromControl?.touched)" class="l-pt-2 absolute">
            <cds-assistive-text
              *ngIf="payrollGroupIdFromControl?.errors?.['invalidRange']"
              class="l-pt-2"
              [showIcon]="false"
              >{{ payrollGroupIdFromControl?.errors?.['invalidRange'] | lang }}</cds-assistive-text
            >

            <cds-assistive-text
              *ngIf="payrollGroupIdFromControl?.errors?.['invalidNumberString']"
              class="l-pt-2"
              [showIcon]="false"
              >{{ payrollGroupIdFromControl?.errors?.['invalidNumberString'] }}</cds-assistive-text
            >
          </div>
        </div>
        <div class="connector">-</div>
        <div class="col-xs-6 date-picker">
          <cds-textfield formControlName="payrollGroupIdIn" label="To" placeholder="Input Payroll Group ID To"></cds-textfield>

          <div *ngIf="payrollGroupIdInControl?.invalid && (payrollGroupIdInControl?.dirty || payrollGroupIdInControl?.touched)" class="l-pt-2 absolute">
            <cds-assistive-text
              *ngIf="payrollGroupIdInControl?.errors?.['invalidRange']"
              class="l-pt-2"
              [showIcon]="false"
              >{{ payrollGroupIdInControl?.errors?.['invalidRange'] | lang }}</cds-assistive-text
            >

            <cds-assistive-text
              *ngIf="payrollGroupIdInControl?.errors?.['invalidNumberString']"
              class="l-pt-2"
              [showIcon]="false"
              >{{ payrollGroupIdInControl?.errors?.['invalidNumberString'] }}</cds-assistive-text
            >
          </div>
        </div>
      </div>
      <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 l-pt-4 l-pb-4" *ngIf="showDatePicker">
        <cds-textfield
          formControlName="contributionPeriodStartDate"
          placeholder="DD/MM/YYYY"
          label="Contribution Period Start Date"
          [cdsDatepicker]="contributionDatePicker">
        </cds-textfield>
        <cds-datepicker #contributionDatePicker="cdsDatepicker"></cds-datepicker>

        <div
          *ngIf="contributionPeriodStartDateControl?.invalid && (contributionPeriodStartDateControl?.dirty || contributionPeriodStartDateControl?.touched)"
          class="l-pt-2">
          <cds-assistive-text *ngIf="contributionPeriodStartDateControl?.errors?.['dateInvalid']" class="l-pt-2" [showIcon]="false">{{
            'create.dateError' | lang
          }}</cds-assistive-text>
          <cds-assistive-text *ngIf="contributionPeriodStartDateControl?.errors?.['required']" class="l-pt-2" [showIcon]="false"
            >Please input Contribution Period Start Date</cds-assistive-text
          >
        </div>
      </div>
      <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 l-pt-4 l-pb-4" *ngIf="showDatePicker">
        <cds-textfield formControlName="effectiveDate" placeholder="DD/MM/YYYY" label="Effective Date" [cdsDatepicker]="effectiveDatePicker"> </cds-textfield>
        <cds-datepicker #effectiveDatePicker="cdsDatepicker"></cds-datepicker>

        <div *ngIf="effectiveDateControl?.invalid && (effectiveDateControl?.dirty || effectiveDateControl?.touched)" class="l-pt-2">
          <cds-assistive-text
            *ngIf="effectiveDateControl?.errors?.['dateInvalid'] || effectiveDateControl?.errors?.['startLaterThanEnd']"
            class="l-pt-2"
            [showIcon]="false"
            >{{ 'create.dateError' | lang }}</cds-assistive-text
          >

          <cds-assistive-text *ngIf="effectiveDateControl?.errors?.['required']" class="l-pt-2" [showIcon]="false"
            >Please input Effective Date</cds-assistive-text
          >
        </div>
      </div>
    </div>
  </div>

  <div class="row l-mt-2">
    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 l-pb-4 bottom-xs search-button-container">
      <cds-button
        class="l-mr-4 botton"
        [config]="downloadButtonConfig"
        [disabled]="downloadButtonDisabled || isLoading"
        label="Download"
        (click)="download()"></cds-button>
      <cds-button class="botton" [config]="resetButtonConfig" [disabled]="resetButtonDisabled" label="{{ 'list.reset' | lang }}" (click)="reset()"></cds-button>
    </div>
  </div>
</ng-container>

<app-cd-download-progress [show]="isLoading"></app-cd-download-progress>
