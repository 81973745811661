import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CdsLanguageModule } from '@cds/ng-core/lang';
import { CdsIconModule } from '@cds/ng-web-components/icon';
import { NoResultFoundComponent } from './no-result-found.component';
@NgModule({
  declarations: [NoResultFoundComponent],
  imports: [CommonModule, CdsIconModule, CdsLanguageModule],
  exports: [NoResultFoundComponent],
})
export class NoResultFoundModule {}
