import { Directive, ElementRef, EventEmitter, Input, Output } from '@angular/core';

@Directive({
  selector: '[appTextEllipsis]',
})
export class TextEllipsisDirective {
  @Input('appTextEllipsis') set text(text: string) {
    this.setHtml(text);
  }

  // px
  @Input('appTextEllipsisContentMaxWidth') contentMaxWidth!: number;

  // px
  @Input('appTextEllipsisContentMaxHeight') contentMaxHeight = 16;

  @Output() ellipsisChange = new EventEmitter();

  fold = true;

  constructor(private elementRef: ElementRef) {}

  setHtml(text: string) {
    const container = this.elementRef.nativeElement;
    const content = document.createElement('span');

    content.innerText = text;
    content.style.display = 'inline';

    container.appendChild(content);

    setTimeout(() => {
      if (content.offsetWidth >= this.contentMaxWidth || content.offsetHeight > this.contentMaxHeight) {
        this.ellipsisChange.emit(true);
      } else {
        this.ellipsisChange.emit(false);
      }
    });
  }
}
