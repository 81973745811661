/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CdsToastService } from '@cds/ng-web-components/toast';
import { finalize } from 'rxjs';
import { PermissionAccess, PermissionItem } from 'src/app/core/models/enum';
import { ResponseResult } from 'src/app/core/models/response/response-result';
import { ReportingRoleServiceService } from 'src/app/core/services/reporting-role-service.service';
import { ColumnConfig, PageConfig } from 'src/app/shared/data-table';

@Component({
  selector: 'app-reporting-role',
  templateUrl: './reporting-role.component.html',
  styleUrls: ['./reporting-role.component.scss'],
})
export class ReportingRoleComponent implements OnInit {
  permissionAccess = PermissionAccess.W;
  permissionItem = PermissionItem.SYS_ROLE_MANAGE;

  loading = false;
  dataDisplayed: any[] = [];
  columnsConfig: Array<ColumnConfig> = [
    { key: 'roleName', title: 'role.list.table.role' },
    { key: 'userCounts', title: 'role.list.table.noUser' },
  ];
  pageConfig: PageConfig = {
    current: 1,
    pageSize: 3,
    totalCount: 0,
  };

  get total() {
    return this.pageConfig.totalCount || 0;
  }

  constructor(private router: Router, private reportingRoleServiceService: ReportingRoleServiceService, private toast: CdsToastService) {}

  ngOnInit() {
    this.getData();
  }

  getData() {
    this.loading = true;
    this.reportingRoleServiceService
      .getRoles({
        page: (this.pageConfig.current as number) - 1,
        size: this.pageConfig.pageSize as number,
      })
      .pipe(
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe({
        next: res => {
          const data = res.data;
          if (res.result === ResponseResult.SUCCESS && data) {
            this.pageConfig = {
              ...this.pageConfig,
              totalCount: data.totalElements,
            };
            this.dataDisplayed = data.content || [];
          } else {
            this.toast.error(res.message);
          }
        },
      });
  }

  create() {
    this.router.navigate(['/system/reporting-role-create']);
  }

  edit(role: any) {
    this.router.navigate(['/system/reporting-role-info'], {
      queryParams: { roleId: role.roleId },
    });
  }

  reloadTable(event: any) {
    if (this.pageConfig.current !== event.current) {
      this.pageConfig.current = event.current;
      this.getData();
    }
  }
}
