<cds-popup class="relative">
  <app-loading *ngIf="_loadding" [overlayInPopup]="true"></app-loading>
  <cds-popup-title>{{ 'interest-rate.add-interest-rate' | lang }}</cds-popup-title>
  <cds-popup-content *ngIf="form" [formGroup]="form">
    <div class="row l-plr-0">
      <div class="col-xs l-pl-0">
        <div class="cds-body2 cds-color-navy-light3">{{ 'interest-rate.fund-code' | lang }}</div>
        <cds-dropdown
          formControlName="fundCode"
          [(ngModel)]="interestRateDTO.code"
          (ngModelChange)="inputChange($event, 'fundCode')"
          [config]="fundConfig"></cds-dropdown>
      </div>
      <div class="select-width l-pr-2">
        <div class="cds-body2 cds-color-navy-light3">{{ 'interest-rate.month-year' | lang }}</div>
        <cds-dropdown
          formControlName="month"
          [(ngModel)]="interestRateDTO.month"
          (ngModelChange)="inputChange($event, 'month')"
          [config]="monthConfig"></cds-dropdown>
      </div>
      <div class="select-width l-pr-2">
        <div class="cds-body2 cds-color-navy-light3">&nbsp;</div>
        <cds-dropdown
          formControlName="year"
          [(ngModel)]="interestRateDTO.year"
          (ngModelChange)="inputChange($event, 'year')"
          [config]="yearConfig"></cds-dropdown>
      </div>
    </div>
    <div class="row l-plr-0 l-pt-4">
      <div class="l-pb-2">
        <span class="cds-body2-ita">{{ 'interest-rate.interest-rate' | lang }}</span>
      </div>
    </div>
    <div class="row col-xs-12 l-pb-4 l-plr-0 interest-rate">
      <table>
        <tr>
          <td><span class="cds-body2-demibold">Normal Rate</span></td>
          <td><span class="cds-body2-demibold">Withdrawal Rate</span></td>
          <td><span class="cds-body2-demibold">Interim Rate</span></td>
          <td style="width: 100px"></td>
        </tr>
        <tr class="div-bg-light-grey">
          <td>
            <cds-textfield
              formControlName="normalRate"
              placeholder="0.00 %"
              [(ngModel)]="interestRateDTO.normalRate"
              type="number"
              (ngModelChange)="inputChange(normalRate.value, 'normalRate')"
              #normalRate></cds-textfield>
          </td>
          <td>
            <cds-textfield
              formControlName="withdrawalRate"
              placeholder="0.00 %"
              [(ngModel)]="interestRateDTO.withdrawalRate"
              type="number"
              (ngModelChange)="inputChange(withdrawalRate.value, 'withdrawalRate')"
              #withdrawalRate></cds-textfield>
          </td>
          <td>
            <cds-textfield
              formControlName="interimRate"
              placeholder="0.00 %"
              [(ngModel)]="interestRateDTO.interimRate"
              type="number"
              (ngModelChange)="inputChange(interimRate.value, 'interimRate')"
              #interimRate></cds-textfield>
          </td>
          <td></td>
        </tr>
      </table>
      <ng-container *ngIf="_error.size > 0">
        <cds-assistive-text *ngFor="let item of _error" class="l-pt-3 col-xs-12">{{ item }} </cds-assistive-text>
      </ng-container>
      <cds-assistive-text class="l-pt-3 col-xs-12" *ngIf="form.get('normalRate')?.invalid">{{form.get('normalRate')?.['errors']?.['msg']}} </cds-assistive-text>
      <cds-assistive-text class="l-pt-3 col-xs-12" *ngIf="form.get('withdrawalRate')?.invalid"
        >{{form.get('withdrawalRate')?.['errors']?.['msg']}}
      </cds-assistive-text>
      <cds-assistive-text class="l-pt-3 col-xs-12" *ngIf="form.get('interimRate')?.invalid"
        >{{form.get('interimRate')?.['errors']?.['msg']}}
      </cds-assistive-text>
    </div>
  </cds-popup-content>
  <cds-popup-actions class="l-pt-4 l-pb-2">
    <cds-button [fullWidth]="true" class="button-width" label="{{ 'common.reset' | lang }}" [config]="resetConfig" (click)="reset()"></cds-button>
    <cds-button
      [fullWidth]="true"
      class="button-width"
      label="{{ 'common.continue' | lang }}"
      [config]="saveConfig"
      [disabled]="!form.valid || !_valid"
      (click)="save()"></cds-button>
  </cds-popup-actions>
</cds-popup>
