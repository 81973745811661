/* eslint-disable no-useless-escape */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl, FormGroup } from '@angular/forms';
import { finalize } from 'rxjs';

import { IPaginationCount } from 'src/app/shared/pagination/pagination.dto';
import { PermissionAccess, PermissionItem } from 'src/app/core/models/enum/permission.enum';
import { ResponseResult } from 'src/app/core/models/response/response-result';

import { CommissionScaleService, ScaleCodeListItem } from 'src/app/core/services/commission/commission-scale.service';
import { SCALE_CODE_COLUMNS, SCALE_PATTERN_CONFIG, SCALE_PATTERN_OPTIONS, SCALE_TYPE_CONFIG, SCALE_TYPE_OPTIONS } from './commission-scale.config';
import { invalidSelectValidator } from './commission-scale.validator';

@Component({
  selector: 'app-commission-scale-list',
  templateUrl: './commission-scale-list.component.html',
  styleUrls: ['./commission-scale-list.component.scss'],
})
export class CommissionScaleListComponent implements OnInit {
  permissionItem = PermissionItem.COMMISSION_SCALE_MANAGEMENT;
  permissionAccess = PermissionAccess.W;
  scaleTypeOpts = SCALE_TYPE_OPTIONS;
  scalePatternOpts = SCALE_PATTERN_OPTIONS;
  SCALE_TYPE_CONFIG = SCALE_TYPE_CONFIG;
  SCALE_PATTERN_CONFIG = SCALE_PATTERN_CONFIG;

  previousSearchParams: any;

  formGroup: FormGroup = new FormGroup({
    scaleCode: new FormControl('', {
      initialValueIsDefault: true,
    }),
    scaleType: new FormControl('', {
      initialValueIsDefault: true,
      validators: [invalidSelectValidator(this.scaleTypeOpts)],
    }),
    scalePattern: new FormControl('', {
      initialValueIsDefault: true,
      validators: [invalidSelectValidator(this.scalePatternOpts)],
    }),
    scalePercentageFrom: new FormControl('', {
      initialValueIsDefault: true,
    }),
    scalePercentageTo: new FormControl('', {
      initialValueIsDefault: true,
    }),
  });

  // table related
  isLoading = false;
  codeSortIcon = '';
  typeSortIcon = '';
  patternSortIcon = '';
  displayedColumns: string[] = SCALE_CODE_COLUMNS;
  dataSource: ScaleCodeListItem[] = [];
  sort: any[] = [];
  pageCount: IPaginationCount = { current: 1, count: 1 };
  totalElements = 0;

  get isSearchEnable() {
    const haveSearchParam =
      this.scaleCode.value || this.scaleType.value || this.scalePattern.value || this.scalePercentageFrom.value || this.scalePercentageTo.value;
    return haveSearchParam && !this.formGroup.invalid;
  }

  get scaleCode() {
    return this.formGroup.get('scaleCode') as FormControl;
  }

  get scaleType() {
    return this.formGroup.get('scaleType') as FormControl;
  }

  get scalePattern() {
    return this.formGroup.get('scalePattern') as FormControl;
  }

  get scalePercentageFrom() {
    return this.formGroup.get('scalePercentageFrom') as FormControl;
  }

  get scalePercentageTo() {
    return this.formGroup.get('scalePercentageTo') as FormControl;
  }

  constructor(private router: Router, private commissionScaleService: CommissionScaleService) {}

  ngOnInit() {
    this.loadTable();
  }

  search() {
    if (!this.isSearchEnable) return;
    this.previousSearchParams = null;
    this.pageCount = { current: 1, count: 1 };
    this.loadTable();
  }

  reset() {
    this.previousSearchParams = null;
    this.sort = [];
    this.codeSortIcon = '';
    this.typeSortIcon = '';
    this.patternSortIcon = '';
    this.formGroup.reset();
    this.pageCount = { current: 1, count: 1 };
    this.loadTable();
  }

  pageChange() {
    this.loadTable();
  }

  onSortChange(event: any) {
    const field = event.active;
    const direction = event.direction;

    switch (field) {
      case SCALE_CODE_COLUMNS[0]: {
        this.codeSortIcon = direction;
        break;
      }
      case SCALE_CODE_COLUMNS[1]: {
        this.typeSortIcon = direction;
        break;
      }
      case SCALE_CODE_COLUMNS[2]: {
        this.patternSortIcon = direction;
        break;
      }
    }
    const oldIndex = this.sort.findIndex(x => x.active === field);
    if (oldIndex > -1) {
      this.sort.splice(oldIndex, 1);
    }
    if (direction) {
      this.sort.unshift({ active: field, direction: direction });
    }
    this.loadTable();
  }

  dbClick(id: string) {
    this.router.navigate(['/commission/scale-info'], {
      queryParams: { id: id },
    });
  }

  createSortArr() {
    const sortArr: string[] = [];
    this.sort.forEach(order => {
      const sortStr = order.active + ',' + order.direction;
      sortArr.push(sortStr);
    });
    return sortArr;
  }

  createParams() {
    const params: any = {
      page: this.pageCount.current - 1,
      size: 10,
      status: 'VALID',
      sort: this.createSortArr(),
    };
    if (this.previousSearchParams) {
      return { ...this.previousSearchParams, ...params };
    }
    const formValue = this.getFormValue();
    return { ...params, ...formValue };
  }

  getFormValue() {
    const value: any = {};
    const tempValue = this.formGroup.value;
    const keys: string[] = Object.keys(tempValue);
    keys.forEach(key => {
      const controlValue = tempValue[key].trim();
      if (controlValue) {
        value[key] = controlValue;
      }
    });
    return value;
  }

  loadTable() {
    this.isLoading = true;
    const searchParam = this.createParams();
    this.commissionScaleService
      .selectList(searchParam)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe(resp => {
        if (resp.result === ResponseResult.SUCCESS && resp.data) {
          this.previousSearchParams = { ...searchParam };
          const data = resp.data;
          this.totalElements = data.totalElements;
          this.pageCount = {
            current: data.number + 1,
            count: data.totalPages,
          };
          const dataSource: ScaleCodeListItem[] = data.content || [];
          this.dataSource = dataSource;
        }
        this.isLoading = false;
      });
  }

  scaleCodeInput(event: any) {
    // 允許輸入字母、數字、空格
    let value = event.target.value.toUpperCase().replace(/[^\a-\z\A-\Z0-9\ ]/g, '');
    const posEnd = event.target.selectionEnd;
    value = value.substring(0, 6);
    event.target.value = value;
    this.scaleCode.setValue(value);
    event.target.setSelectionRange(posEnd, posEnd);
  }

  scalePercentageFromInput(event: any) {
    const value = this.handleScalePercentageEvt(event);
    this.scalePercentageFrom.setValue(value);
  }

  scalePercentageToInput(event: any) {
    const value = this.handleScalePercentageEvt(event);
    this.scalePercentageTo.setValue(value);
  }

  handleScalePercentageEvt(event: any) {
    // 允許輸入字母、數字、空格
    let value = event.target.value.match(/(^(\d){1,2}\.(\d){0,4})|(^(\d){1,2})/g) || '';
    const posEnd = event.target.selectionEnd;
    if (value) {
      value = value[0];
    }
    event.target.value = value;
    event.target.setSelectionRange(posEnd, posEnd);
    return value;
  }
}
