import { FormControl } from '@angular/forms';
import { CdsDropdownConfig } from '@cds/ng-core/dropdown';
import { CdRadioConfig } from 'src/app/shared/cd-radio/cd-radio.component';

export enum AreaCode {
  HK = '852',
  MA = '853',
  CN = '86',
}

export enum ResendEiffResendType {
  ER,
  EE,
}

export interface ResendEiffResendInfoRsp {
  empfRefNo: string;
  firstName: string;
  lastName: string;
  hkidOrPassportNo: string;
  countryCallingCd: string;
  phoneNumber: string;
  emailAddress: string;
  commChannel: string;
  specialNeeds: number;
  regulatedAct: boolean;
  companyName: string;
  brNo: string;
  customerId: string;
  authorizedPersonNameControl: FormControl;
  authorizedPersonNameConfig: CdsDropdownConfig;
  agentInfos: {
    agentid: string;
    agentCd: string;
    regulatedAct: boolean;
    agentNotificationChannel: string;
    emailAddress: string;
    mobile: string;
    agentNameTc: string;
    agentName: string;
    channelConfig?: CdRadioConfig;
    channelValue?: number;
    iffAgentStatusEnum: number;
    smsLanguage?: 0 | 1;
  }[];
  dbs?: {
    dbsRmReferralCd: string;
    dbsCustomerId: string;
    dbsCampaignCd: string;
    dbsSourceCd: string;
  };
  smsLanguage?: 0 | 1;
}

export interface ResendEiffResendSubmissionParams {
  salesJourneySummaryId: string;
  empfRefNo?: string;
  communicationChannel: string;
  authPersonId: string;
  email?: string;
  countryCallingCode?: string;
  mobile?: string;
  customerId?: string;
  splitAgentInfos: {
    agentid: string;
    agentNotificationChannel: string;
  }[];
  dbs?: {
    dbsRmReferralCd: string;
    dbsCustomerId: string;
    dbsCampaignCd: string;
    dbsSourceCd: string;
  };
  smsLanguage?: 0 | 1;
}
