<app-cd-page-title
  [showBack]="true"
  [backLandingLabel]="('scheme.master.title' | lang) + ' ' + ('common.recordList' | lang)"
  (backLanding)="backLanding()"
  title="{{ 'scheme.master.createTitle' | lang }}">
</app-cd-page-title>
<div class="row">
  <div class="col-xs-12 col-md-12 l-mt-7 l-pb-6 content-box">
    <form [formGroup]="formGroup">
      <div *ngIf="!review">
        <div class="cds-h2 cds-light l-mb-3 scheme-details">
          {{ 'scheme.master.details' | lang }}
        </div>

        <div class="l-mb-5">
          <p class="cds-body1 l-ma-0 l-mb-4">{{ 'scheme.master.create.fillIn' | lang }}</p>
          <p class="cds-body1 cds-coral-text dark-1 l-ma-0">{{ 'common.fillIn' | lang }}</p>
        </div>

        <div class="row l-plr-0 l-pb-6 scheme-details-item">
          <div class="col-xs-12 col-md-6">
            <div class="cds-body2 cds-dropdown-label label-color">{{ 'common.business.schemeName' | lang }}<span class="cds-coral-text dark-1">*</span></div>
            <input
              (input)="schemeNameInput($event)"
              (change)="schemeNameChange($event)"
              maxlength="50"
              [placeholder]="'common.business.schemeNamePlaceholder' | lang"
              class="text-field"
              type="text"
              name="schemeName"
              formControlName="schemeName" />
            <div class="l-pt-2">
              <cds-assistive-text *ngIf="schemeName?.dirty && schemeName?.errors?.['errorMsg']">
                {{ schemeName.errors?.['errorMsg'] | lang }}
              </cds-assistive-text>
            </div>
          </div>
          <div class="col-xs-12 col-md-6">
            <div class="cds-body2 cds-dropdown-label label-color">{{ 'common.business.schemeCode' | lang }}<span class="cds-coral-text dark-1">*</span></div>
            <input
              maxlength="100"
              placeholder="{{ 'common.business.schemeCodePlaceholder' | lang }}"
              class="text-field"
              type="text"
              name="schemeCode"
              formControlName="schemeCode" />
            <div class="l-pt-2 error-msg">
              <cds-assistive-text *ngIf="schemeCode?.dirty && schemeCode?.errors?.['errorMsg']">
                {{ schemeCode.errors?.['errorMsg'] | lang}}
              </cds-assistive-text>
            </div>
          </div>
        </div>

        <div class="l-pt-5 l-mb-4 salary">
          <div class="cds-h2 cds-light">
            {{ 'common.business.salaryInflationFactor' | lang }}
          </div>
        </div>

        <div class="l-d-ib salary-content div-border cds-white l-pa-5 l-mb-7">
          <div class="cds-body2 cds-dropdown-label label-color">
            {{ 'common.business.salaryInflationFactorCy' | lang }}
          </div>
          <div>
            <div class="l-d-f l-ai-cen">
              <div class="cds-h2 cds-light l-mb-0">
                <input
                  (change)="salaryInflationFactorChange($event)"
                  (input)="salaryInflationFactorInput($event)"
                  placeholder="0.000"
                  class="text-field"
                  type="text"
                  name="salaryInflationFactor"
                  formControlName="salaryInflationFactor" />
              </div>
              <div class="l-pr-3 l-pl-5">%</div>
            </div>
          </div>
          <cds-assistive-text class="l-pt-2" *ngIf="salaryInflationFactor?.errors?.['errorMsg']">
            {{ salaryInflationFactor.errors?.['errorMsg'] | lang }}
          </cds-assistive-text>
          <div class="cds-body2 cds-dark-navy-text light-3 l-mt-3 l-mb-0">
            {{ 'common.business.salaryInflationFactor' | lang }}
          </div>
        </div>

        <div class="l-d-f btn-box">
          <div>
            <cds-button [fullWidth]="true" [config]="btnCfg" size="md" (click)="reset()">{{ 'common.reset' | lang }} </cds-button>
          </div>
          <div class="l-ml-3">
            <cds-button
              [fullWidth]="true"
              size="md"
              [disabled]="formGroup.pending || !formGroup.dirty || !formGroup.touched || formGroup.invalid"
              (click)="submit()"
              >{{ 'common.submit' | lang }}
            </cds-button>
          </div>
        </div>
      </div>

      <div *ngIf="review">
        <div class="cds-h2 cds-light l-mb-3 scheme-details">
          {{ 'common.review' | lang }}
        </div>

        <div class="row l-plr-0 l-pb-6">
          <div class="col-xs-12 col-md-6 l-pl-0 l-pr-5 l-mb-5">
            <div class="cds-body2 cds-dropdown-label label-color">{{ 'common.business.schemeName' | lang }}</div>
            <div class="l-pt-1 cds-body1">
              {{ schemeName.value }}
            </div>
          </div>

          <div class="col-xs-12 col-md-6 l-pl-0 l-pr-5 l-mb-5">
            <div class="cds-body2 cds-dropdown-label label-color">{{ 'common.business.schemeCode' | lang }}</div>
            <div class="l-pt-1 cds-body1">
              {{ schemeCode.value }}
            </div>
          </div>
        </div>

        <div class="l-pt-5 l-mb-4 salary">
          <div class="cds-h2 cds-light">
            {{ 'common.business.salaryInflationFactor' | lang }}
          </div>
        </div>

        <div class="l-d-ib salary-content div-border cds-white l-pa-5 l-mb-7">
          <div class="cds-body2 cds-dropdown-label label-color">
            {{ 'common.business.salaryInflationFactorCy' | lang }}
          </div>
          <div>
            <div class="cds-h2 cds-demibold l-mb-0">{{ salaryInflationFactor.value ? salaryInflationFactor.value : 0.0 }}%</div>
          </div>
          <cds-assistive-text class="l-pt-2" type="warning" *ngIf="salaryInflationFactor?.errors?.['errorMsg']">
            {{ salaryInflationFactor.errors?.['errorMsg'] | lang}}
          </cds-assistive-text>
          <div class="cds-body2 cds-dark-navy-text light-3 l-mt-3 l-mb-0">
            {{ 'common.business.salaryInflationFactor' | lang }}
          </div>
        </div>

        <div class="l-d-f btn-box">
          <div>
            <cds-button [fullWidth]="true" [config]="btnCfg" size="md" (click)="backToEdit()" [disabled]="isAdding"
              >{{ 'common.backToEdit' | lang }}
            </cds-button>
          </div>
          <div class="l-ml-3">
            <cds-button [fullWidth]="true" size="md" (click)="confirm()" [disabled]="isAdding">{{ 'common.confirm' | lang }} </cds-button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
