<div class="row l-pt-6">
  <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 l-pt-4 l-pb-4 l-mr-4" *ngIf="showDatePicker">
    <cds-textfield
      [formControl]="startDateFormControl"
      placeholder="DD/MM/YYYY"
      label="{{ 'account.select-contribution-period-start-date' | lang }}"
      [max]="nowTime"
      [cdsDatepicker]="picker">
    </cds-textfield>
    <cds-datepicker #picker="cdsDatepicker"></cds-datepicker>

    <div *ngIf="startDateFormControl?.invalid && (startDateFormControl?.dirty || startDateFormControl?.touched)" class="l-pt-2">
      <cds-assistive-text
        *ngIf="startDateFormControl?.errors?.['dateInvalid'] || startDateFormControl?.errors?.['startLaterThanEnd']"
        class="l-pt-2"
        [showIcon]="false"
        >{{ 'create.dateError' | lang }}</cds-assistive-text
      >
    </div>
  </div>

  <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 row l-plr-0">
    <div class="search-padding l-pb-2 l-pt-5 l-mr-4">
      <cds-button
        [fullWidth]="true"
        [config]="searchButtonConfig"
        label="{{ 'list.search' | lang }}"
        [disabled]="searchButtonDisabled"
        (click)="searchButtonClick()"></cds-button>
    </div>
    <div class="search-padding l-pb-2 l-pt-5">
      <cds-button
        [fullWidth]="true"
        [config]="resetButtonConfig"
        label="{{ 'list.reset' | lang }}"
        [disabled]="resetButtonDisabled"
        (click)="reset()"></cds-button>
    </div>
  </div>
</div>

<app-loading *ngIf="isLoading" [overlay]="true"></app-loading>
<div class="l-mt-6">
  <app-no-result-found *ngIf="!showBillInfo"></app-no-result-found>
</div>

<div class="row" *ngIf="showBillInfo">
  <div class="col-xs-12 l-pt-4 l-pb-4">
    <span class="cds-h3-demibold">{{ 'account.contribution-period' | lang }}</span>
    <span class="cds-h3-demibold">{{ contributionDetails.contributionPeriodStartDate | ssmsDate }}</span>
    <span class="cds-h3-demibold"> - </span>
    <span class="cds-h3-demibold">{{ contributionDetails.contributionPeriodEndDate | ssmsDate }}</span>
  </div>
</div>

<div class="row" *ngIf="showBillInfo">
  <div class="col-xs-12 l-pt-5 cds-color-green">
    <span class="cds-h5-demibold">{{ 'account.employer-contribution-details' | lang }}</span>
  </div>
  <div class="col-xs-12">
    <div class="p-line"></div>
  </div>
</div>

<div class="row" *ngIf="showBillInfo">
  <div class="col-xs-12">
    <div class="col-xs-12 row basic-pad l-pt-4 l-pb-4">
      <div class="l-mr-2 l-pt-1">
        <cds-icon color="#282B3E" width="20px" height="20px" icon="finance:bill"></cds-icon>
      </div>
      <div class="col-xs row l-plr-0">
        <div class="col-xs-12">
          <span class="cds-body1-demibold">{{ 'account.bill-information' | lang }}</span>
        </div>
        <!-- <div class="col-xs-12">
          <span class="cds-body2">Billing Reference Number: {{ contributionDetails.billingReferenceNumber }}</span>
        </div> -->
      </div>
    </div>

    <div class="detail-pad l-pb-8">
      <div class="col-xs-12 row no-padding">
        <app-row-item label="account.contribution-frequency" [data]="contributionDetails.contributionFrequency" class="col-xs-6 col-sm-6 col-md-4 col-lg-3">
        </app-row-item>
      </div>

      <div class="icon-label">
        <cds-icon icon="finance:cash_benefit"></cds-icon>
        <div class="l-pr-4"></div>
        <div>{{ 'account.contribution-details' | lang }}</div>
      </div>

      <div class="l-pt-4 l-pb-8">
        <app-amount-table [contributionTableDataSource]="contributionTableDataSource" [contributionDetails]="contributionDetails"> </app-amount-table>
      </div>

      <div class="col-xs-12 row no-padding l-pb-4">
        <app-row-item label="account.cdr" [data]="contributionDetails.cdr" class="col-xs-6 col-sm-6 col-md-4 col-lg-3"> </app-row-item>

        <app-row-item label="account.vc-cdr" [data]="contributionDetails.vcCdr" class="col-xs-6 col-sm-6 col-md-4 col-lg-3"> </app-row-item>
      </div>

      <div class="icon-label">
        <cds-icon icon="finance:cash_2"></cds-icon>
        <div class="l-pr-4"></div>
        <div>{{ 'account.relevant-income-or-basic-salary' | lang }}</div>
      </div>

      <div class="col-xs-12 l-mb-4 score-table">
        <table cellspacing="0">
          <tbody>
            <tr>
              <td>
                <div class="flex">
                  <span class="l-mr-2">{{ 'ee.salary-type-id' | lang }}</span>
                </div>
              </td>
              <td>
                <div class="flex">
                  <span class="l-mr-2">{{ 'ee.income-or-basic-salary' | lang }}</span>
                </div>
              </td>
            </tr>
            <tr>
              <td>{{ contributionDetails.salaryTypeId }}</td>
              <td>{{ contributionDetails.incomeOrBasicSalary | number }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

<ng-container *ngIf="showBillInfo">
  <app-permission [role]="PermissionAccess.R" [permission]="PermissionItem.CUSTOMER_EMPLOYEE">
    <app-bill-reference
      [contributionTableDataSource]="contributionTableDataSource"
      [contributionDetails]="contributionDetails"
      (runDateChange)="runDateChange($event)"
      [isReset]="isReset">
    </app-bill-reference>
  </app-permission>
</ng-container>
