<cds-popup>
  <cds-popup-title *ngIf="data.title">{{ data.title | lang : params }}</cds-popup-title>
  <cds-popup-content>
    <div innerHtml="{{ data.message | lang : params }}"></div>
  </cds-popup-content>
  <cds-popup-actions>
    <cds-button *ngIf="!data.buttonName" [config]="agreeButtonConfig" (click)="close()">{{ 'common.close' | lang | titlecase }}</cds-button>
    <cds-button *ngIf="data.buttonName" [config]="agreeButtonConfig" (click)="close()">{{ data.buttonName | lang }}</cds-button>
  </cds-popup-actions>
</cds-popup>
