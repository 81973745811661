import { Component, OnDestroy, OnInit, ViewChild, AfterViewInit, DoCheck } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MatDialogRef } from '@angular/material/dialog';
import { Subscription, from, switchMap } from 'rxjs';

import { CdsDropdownConfig } from '@cds/ng-core/dropdown';
import { CdsIconConfig } from '@cds/ng-core/icon';
import { CdsLangService } from '@cds/ng-core/lang';
import { ActionIcons } from '@cds/ng-themes';
import { CdsPopupService } from '@cds/ng-web-components/popup';
import { CdsDatepickerComponent } from '@cds/ng-web-components/datepicker';
import { CdsAlertService } from '@cds/ng-web-components/alert';

import { CdRadioComponent, CdRadioConfig } from 'src/app/shared/cd-radio/cd-radio.component';
import { LanguageChangeService } from 'src/app/shared/language-change.service';
import { ResetWarningComponent } from '../reset-warning/reset-warning.component';
import { CdHttpServeService } from 'src/app/shared/cd-http-serve/cd-http-serve.service';
import { agentCodeRemovePrefix0 } from 'src/app/utils/utils';
import { getSessionByKey } from 'src/app/utils/indexdb';
import { UpdatedSuccessfullyComponent } from 'src/app/shared/updated-successfully/updated-successfully.component';
import { EEProdType } from './individuals.model';
import { NotifyService } from 'src/app/core/services/notify.service';
import { Session } from 'src/app/core/models/user';
import { AgentChannel } from 'src/app/core/models/enum/agent-channel.enum';
import { SimplePopupComponent } from 'src/app/shared/simple-popup/simple-popup.component';
import { empfCountryOption } from '../../case-summary/declaration-iff-er/empf-declaration-form.config';
import { PreferredLanguageType } from '../../case-summary/case-summary.model';
import { AdviseEmpfrefComponent } from '../../case-summary/advise-empfref/advise-empfref.component';
import { AgentDetailInfo } from '../../service/model/agent-info-response.model';

interface SubmissionParams {
  productType: EEProdType;
  identityId: string;
  formType: string;
  empfRefNo: string;
  clientFirstNmEn?: string;
  clientLastNmEn?: string;
  clientFirstNmChn?: string;
  clientLastNmChn?: string;
  idType: number;
  hkidOrPassportNo: string;
  clientCommChannel?: string;
  countryCode?: string;
  phoneNumber?: string;
  emailAddr?: string;
  specialNeeds: boolean;
  paperFormSubmissionDate: string;
  agentInfos: {
    agentId?: string;
    agentCd?: string;
    servcingAgent: boolean;
    splitAgent: boolean;
    acknowledged: boolean;
  }[];
  smsLanguage?: 0 | 1;
  dbs?: {
    dbsRmReferralCd?: string;
    dbsCustomerId?: string;
    dbsCampaignCd?: string;
    dbsSourceCd?: string;
  };
}

interface TempAgentType {
  agentCode: string;
  name: string;
  chineseName: string;
  branchNo?: string;
}

@Component({
  selector: 'app-individuals',
  templateUrl: './individuals.component.html',
  styleUrls: ['./individuals.component.scss'],
})
export class IndividualsComponent implements OnInit, OnDestroy, AfterViewInit, DoCheck {
  @ViewChild('pickerFromDate', { static: false }) paperIFFAgentSubmissionFromDate?: CdsDatepickerComponent<string>;
  @ViewChild('cdRadioPreferredLanguage') cdRadioPreferredLanguage!: CdRadioComponent;
  @ViewChild('dbsReferredLanguage') dbsReferredLanguage!: CdRadioComponent;
  backIconConfig: CdsIconConfig = {
    color: 'cta',
    size: 'sm',
  };
  backIcon = ActionIcons.button_left_filled;
  language = 'EN';
  confirmSpecialNeedsValue = 'n';
  confirmSpecialNeedsConfig: CdRadioConfig = {
    options: [
      {
        label:
          'No, I do not have special needs and this confirmation shall override any previous confirmation and/or any information about me, which may indicate otherwise.',
        value: 'n',
        disable: false,
      },
      {
        label: 'Yes, I have special needs. ',
        value: 'y',
        disable: false,
      },
    ],
  };
  advisedSpecialNeedsValue = '1';
  advisedSpecialNeedsConfig: CdRadioConfig = {
    options: [
      {
        label: 'not to have any witness',
        value: '1',
        disable: false,
      },
      {
        label: 'to be accompanied by a companion/ to be accompanied by an additional member of staff as the witness',
        value: '2',
        disable: false,
      },
    ],
  };
  isConfirming = false;

  lanChangeSubscription?: Subscription;
  now = new Date();
  idTypeConfig: CdsDropdownConfig = {
    options: [
      { label: this.cdsLangService.translate('iff.hkid'), value: '0' },
      { label: this.cdsLangService.translate('iff.passport'), value: '1' },
    ],
    placeholder: this.cdsLangService.translate('iff.pleaseSelectIDType'),
  };
  individualsForm!: FormGroup;
  clientContactConfig: CdRadioConfig = {
    options: [
      {
        label: 'Email Address',
        value: 'E',
        disable: false,
      },
      {
        label: 'Phone Number',
        value: 'M',
        disable: false,
      },
    ],
  };
  dbsContactConfig: CdRadioConfig = {
    options: [
      {
        label: 'Yes',
        value: 'Y',
        disable: false,
      },
      {
        label: 'No',
        value: 'N',
        disable: false,
      },
    ],
  };
  clientContact = '';
  countrycodeConfig: CdsDropdownConfig = {
    options: empfCountryOption,
    placeholder: '+852 (HK)',
    color: 'ml',
  };
  private readonly CN_PHONE = /^1[345678]\d{9}$/;
  customerEnglishSurname = '';
  customerEnglishGivenName = '';
  customerChinaSurname = '';
  customerChinaGivenName = '';
  isTryConfirm = false;
  resetDatePicker = false;
  isDBschannel = '';
  prodTypeDropdownConfig: CdsDropdownConfig = {
    options: [
      {
        label: EEProdType.PA,
        value: EEProdType.PA,
      },
      {
        label: EEProdType.TVC,
        value: EEProdType.TVC,
      },
      {
        label: EEProdType.FRC,
        value: EEProdType.FRC,
      },
      {
        label: EEProdType.SEP,
        value: EEProdType.SEP,
      },
    ],
    placeholder: 'Select Product Type',
    color: 'ml',
  };
  tempServicingAgent: TempAgentType | null = null;
  tempSplitAgent: TempAgentType | null = null;
  preferredLanguageConfig = {
    options: [
      {
        label: 'common.chinese',
        value: PreferredLanguageType.Chinese,
        disable: true,
      },
      {
        label: 'common.english',
        value: PreferredLanguageType.English,
        disable: true,
      },
    ],
  };

  constructor(
    private router: Router,
    private languageChangeService: LanguageChangeService,
    public cdsLangService: CdsLangService,
    private cdsPopup: CdsPopupService,
    private cdHttpServeService: CdHttpServeService,
    private notifyService: NotifyService,
    private alert: CdsAlertService
  ) {}

  lanSubjectChange = (lan: string) => {
    this.language = lan === 'en' ? 'EN' : 'TC';
  };

  indFormPaperIFFAgentSubmissionDateFromVali = (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) {
      return null;
    }
    const temp = control.value.split('/');
    const selectedDate = new Date([temp[1], temp[0], temp[2]].join('/'));
    if (selectedDate.toString() === 'Invalid Date') {
      return { formatError: true };
    } else {
      return null;
    }
  };

  indFormHkidOrPassportNoVali = (control: AbstractControl): ValidationErrors | null => {
    if (this.individualsForm?.get('idType')?.value === '1') {
      return null;
    }
    if (control.value === '' || control.value == undefined) {
      return null;
    }
    let input: string = control.value;
    input = input.trim();
    const isValid: RegExpExecArray | null | boolean = /^[a-zA-Z]{1,2}\d{6}\([aA\d]\)$/.exec(input);
    return isValid ? null : { formatError: true };
  };

  get clientCountryCode() {
    return this.individualsForm.get('clientCountryCode') as FormControl;
  }

  idFormClientPhoneNumberVali = (option: AbstractControl): ValidationErrors | null => {
    if (!option.value) {
      return null;
    }

    if (typeof option.value === 'string') {
      if (['852', '853'].includes(this.clientCountryCode.value)) {
        if (option.value.length === 8 && ['4', '5', '6', '7', '8', '9'].includes(option.value.substring(0, 1))) {
          return null;
        }
      }

      if (['86'].includes(this.clientCountryCode.value)) {
        if (option.value.length === 11) {
          const e = this.CN_PHONE.test(option.value);
          if (e) {
            return null;
          }
        }
      }

      if (!['852', '853', '86'].includes(this.clientCountryCode.value)) {
        return null;
      }
    }

    return { errorPhoneNumber: true };
  };

  idFormPreferredLanguageVali = (control: AbstractControl): ValidationErrors | null => {
    if ((this.clientContact === 'M' && control.value) || this.clientContact === 'E' || !this.clientContact) {
      return null;
    }
    return { formatError: true };
  };

  rmReferralCodeVali = (control: AbstractControl): ValidationErrors | null => {
    let input = control.value || '';
    input = input.trim();
    const isValid = input.length >= 6 && input.length <= 8;
    return isValid ? null : { lengthError: true };
  };
  inputCustomerIdVali = (control: AbstractControl): ValidationErrors | null => {
    let input = control.value || '';
    input = input.trim();
    const isValid = ((input.length >= 6 && input.length <= 8) || input.length === 0) && /^[A-Za-z0-9-]*$/.exec(input);
    return isValid ? null : { lengthError: true };
  };
  sourceCodeVali = (control: AbstractControl): ValidationErrors | null => {
    let input = control.value || '';
    input = input.trim();
    const isValid = (input.length === 10 || input.length === 0) && /^[A-Za-z0-9-]*$/.exec(input);
    return isValid ? null : { lengthError: true };
  };
  campaignCodeVali = (control: AbstractControl): ValidationErrors | null => {
    let input = control.value || '';
    input = input.trim();
    const isValid = (input.length === 10 || input.length === 0) && /^[A-Za-z0-9-]*$/.exec(input);
    return isValid ? null : { lengthError: true };
  };

  dbs = {
    rmReferralCode: new FormControl('', [Validators.required, this.rmReferralCodeVali]),
    inputCustomerId: new FormControl('', [this.inputCustomerIdVali]),
    sourceCode: new FormControl('', [this.sourceCodeVali]),
    campaignCode: new FormControl('', [this.campaignCodeVali]),
  };

  ngOnInit(): void {
    this.lanChangeSubscription = this.languageChangeService.lanSubject.subscribe(this.lanSubjectChange);

    this.individualsForm = new FormGroup({
      productType: new FormControl(null, [Validators.required]),
      eMPFReferenceNo: new FormControl('', [Validators.required]),
      paperIFFAgentSubmissionDateFrom: new FormControl('', [Validators.required, this.indFormPaperIFFAgentSubmissionDateFromVali]),
      conductedRegulatorActivityFlag: new FormControl(null),
      idType: new FormControl('0'),
      hkidOrPassportNo: new FormControl('', [Validators.required, this.indFormHkidOrPassportNoVali]),
      clientEmailAddress: new FormControl(null, [Validators.required, Validators.email]),
      clientCountryCode: new FormControl('852', [Validators.required]),
      clientPhoneNumber: new FormControl('', [Validators.required, this.idFormClientPhoneNumberVali]),
      servicingAgentCode: new FormControl(null, [Validators.required]),
      splitAgentCode: new FormControl(null),
      isServicingAgentCodePerformed: new FormControl(false),
      isSplitAgentCodePerformed: new FormControl(false),
      preferredLanguage: new FormControl(null, [this.idFormPreferredLanguageVali]),
      rmReferralCode: new FormControl('', [Validators.required, this.rmReferralCodeVali]),
      inputCustomerId: new FormControl('', [this.inputCustomerIdVali]),
      sourceCode: new FormControl('', [this.sourceCodeVali]),
      campaignCode: new FormControl('', [this.campaignCodeVali]),
    });

    this.servicingAgentCode.valueChanges.subscribe(this.servicingAgentCodeValueChanges);
  }

  servicingAgentCodeValueChanges = (res: string) => {
    if (res === '') {
      this.splitAgentCode.setValue('');
      this.isSplitAgentCodePerformed.setValue(false);
    }
  };

  get servicingAgentCode() {
    return this.individualsForm.get('servicingAgentCode') as FormControl;
  }

  get splitAgentCode() {
    return this.individualsForm.get('splitAgentCode') as FormControl;
  }

  get isSplitAgentCodePerformed() {
    return this.individualsForm.get('isSplitAgentCodePerformed') as FormControl;
  }

  ngAfterViewInit() {
    this.setPaperAgentSubmissionDateReadonly();
  }

  private setPaperAgentSubmissionDateReadonly() {
    const list = document.getElementsByClassName('paper-agent-submission-date');
    if (list.length === 1) {
      list.item(0)?.getElementsByTagName('input').item(0)?.setAttribute('readonly', '');
    }
  }

  openPaperIFFAgentSubmissionFromDate() {
    this.paperIFFAgentSubmissionFromDate?.open();
  }

  clientCountryCodeChange() {
    this.clientPhoneNumber.updateValueAndValidity();
  }

  get clientPhoneNumber() {
    return this.individualsForm.get('clientPhoneNumber') as FormControl;
  }

  hKIDPassportNoChange() {
    this.hkidOrPassportNo.updateValueAndValidity();
  }

  get hkidOrPassportNo() {
    return this.individualsForm.get('hkidOrPassportNo') as FormControl;
  }

  ngOnDestroy(): void {
    this.lanChangeSubscription?.unsubscribe();
  }

  onBack() {
    if (this.isConfirming) {
      return;
    }
    this.router.navigate(['/salesjourney/iff-paper-form/select-product-type']);
  }

  handleIconClick() {
    this.cdsPopup.open(AdviseEmpfrefComponent, {
      size: 'lg',
    });
  }

  get idType() {
    return this.individualsForm.get('idType') as FormControl;
  }

  hkidOrPassportNoChange(event: string) {
    const value = this.hkidOrPassportNo.value.replace(/[\u4e00-\u9fa5]/gi, '');
    if (event !== value) {
      this.hkidOrPassportNo.setValue(value);
    }

    if (/^[a-zA-Z]{1,2}\d{6}\([aA\d]\)$/.exec(value)) {
      if (this.idType.value === '0') {
        this.cdHttpServeService.post(`/ext/eb-ssms-sales-journey-service/hkidCheckDigit`, { hkId: value.trim() }).subscribe({
          next: res => {
            if (!(res.result === 0 && res.data)) {
              this.hkidOrPassportNo.setErrors({ formatError: true });
            }
          },
          error: err => {
            this.hkidOrPassportNo.setErrors({ formatError: true });
            this.alert.error('Error!', err);
          },
        });
      }
    }
  }

  get productType() {
    return this.individualsForm.get('productType') as FormControl;
  }

  get eMPFReferenceNo() {
    return this.individualsForm.get('eMPFReferenceNo') as FormControl;
  }

  get paperIFFAgentSubmissionDateFrom() {
    return this.individualsForm.get('paperIFFAgentSubmissionDateFrom') as FormControl;
  }

  get clientEmailAddress() {
    return this.individualsForm.get('clientEmailAddress') as FormControl;
  }

  get preferredLanguage() {
    return this.individualsForm.get('preferredLanguage') as FormControl;
  }

  get rmReferralCode() {
    return this.individualsForm.get('rmReferralCode') as FormControl;
  }
  get inputCustomerId() {
    return this.individualsForm.get('inputCustomerId') as FormControl;
  }

  get campaignCode() {
    return this.individualsForm.get('campaignCode') as FormControl;
  }

  get sourceCode() {
    return this.individualsForm.get('sourceCode') as FormControl;
  }

  confirm() {
    this.productType.markAsDirty();
    this.eMPFReferenceNo.markAsDirty();
    this.paperIFFAgentSubmissionDateFrom.markAsDirty();
    this.idType.markAsTouched();
    this.isTryConfirm = true;
    this.hkidOrPassportNo.markAsDirty();
    this.clientEmailAddress.markAsDirty();
    this.clientPhoneNumber.markAsDirty();
    this.servicingAgentCode.markAsDirty();
    this.preferredLanguage.markAsTouched();
    if (this.isDBschannel != '') {
      this.rmReferralCode.markAsDirty();
      this.inputCustomerId.markAsDirty();
      this.campaignCode.markAsDirty();
      this.sourceCode.markAsDirty();
    }
    const boo = this.isAllowPast();

    if (boo) {
      const hkid = this.hkidOrPassportNo.value;
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const idtype = this.idType.value;
      this.dbsContactConfig.options.forEach((e: { disable: boolean }) => {
        e.disable = true;
      });
      this.dbsReferredLanguage.refreshConfig();

      if (idtype == 0) {
        this.cdHttpServeService.post(`/ext/eb-ssms-sales-journey-service/hkidCheckDigit`, { hkId: hkid }).subscribe({
          next: res => {
            if (res.result === 0) {
              if (res.data === false) {
                this.hkidOrPassportNo.setErrors({ formatError: true });
                return;
              }
              this.paperFormValue();
            }
          },
          error: err => {
            this.alert.error('Error!', err);
          },
        });
      } else {
        this.paperFormValue();
      }
    }
  }

  openSimplePopup(value: string) {
    this.notifyService.screenLoadingSubject.next(false);
    const ref = this.cdsPopup.open(SimplePopupComponent, {
      size: 'md',
      data: {
        message: value,
        buttons: [
          {
            style: 'primary',
            size: 'sm',
            text: 'common.gotItWithExclamation',
            cb: () => {
              ref.close();
            },
          },
        ],
      },
    });
  }

  paperFormValue() {
    this.notifyService.screenLoadingSubject.next(true);
    this.cdHttpServeService
      .post<{ empfRefNoStatus: boolean }>('/ext/eb-ssms-sales-journey-service/generateGeneralLink/validateEmpfRefNo/paperFrom', {
        empfRefNo: this.eMPFReferenceNo.value,
        clientType: 'member',
      })
      .subscribe({
        next: res => {
          if (res.result === 0) {
            if (res.data.empfRefNoStatus) {
              this.eMPFReferenceNo.setErrors(null);

              if (this.servicingAgentCode.value) {
                this.cdHttpServeService
                  .post<
                    {
                      agentCode: string;
                      inputAgentType: string;
                      channel: string;
                      servicingAgentCode: string;
                      commissionAgentCode: string;
                    }[]
                  >(`/ext/eb-ssms/agent-service/api/v1/agent-code-conversion`, [this.servicingAgentCode.value])
                  .subscribe(res => {
                    // if (res.data[0].channel === AgentChannel.DBS && this.splitAgentCode.value) {
                    //   this.notifyService.screenLoadingSubject.next(false);
                    //   const ref = this.cdsPopup.open(SimplePopupComponent, {
                    //     size: 'md',
                    //     data: {
                    //       message: 'Split agent is not allowed for DBS channel',
                    //       buttons: [
                    //         {
                    //           style: 'primary',
                    //           size: 'sm',
                    //           text: 'common.gotItWithExclamation',
                    //           cb: () => {
                    //             ref.close();
                    //           },
                    //         },
                    //       ],
                    //     },
                    //   });
                    //   return;
                    // }
                    if (res.data[0].channel === AgentChannel.DBS && this.splitAgentCode.value) {
                      this.openSimplePopup('Split agent is not allowed for DBS channel');
                      return;
                    }
                    if (res.data[0].channel === AgentChannel.SCB || res.data[0].channel === AgentChannel.BROKER) {
                      this.openSimplePopup('IFF is not required for this sales channel');
                      return;
                    }
                    if (res.data[0].channel === AgentChannel.CORPORATE && !res.data[0].agentCode.startsWith('400')) {
                      this.openSimplePopup('IFF is not required for this sales channel');
                      return;
                    }
                    if (res.data[0].channel === AgentChannel.CORPORATE && res.data[0].agentCode === '400003') {
                      this.openSimplePopup('IFF is not required for this sales channel');
                      return;
                    }
                    if (res.data[0].channel === AgentChannel.CORPORATE && res.data[0].agentCode.startsWith('400') && this.splitAgentCode.value) {
                      this.openSimplePopup('Split agent is not allowed for CORPORATE channel');
                      return;
                    }

                    const agentCodeList = [this.servicingAgentCode.value, this.splitAgentCode.value].filter(agentCode => !!agentCode);
                    this.tempServicingAgent = null;
                    this.tempSplitAgent = null;
                    Promise.all(agentCodeList.map(agentCode => this.validateAgentCode(agentCode))).then(this.loadAgentByValidate);
                  });
              }
            } else {
              this.notifyService.screenLoadingSubject.next(false);
              this.eMPFReferenceNo.setErrors({ haveExisted: true });
            }
          } else {
            this.notifyService.screenLoadingSubject.next(false);
            this.alert.error('Error!', res.message);
          }
        },
        error: err => {
          this.notifyService.screenLoadingSubject.next(false);
          this.alert.error('Error!', err);
        },
      });
  }

  loadAgentByValidate = (
    res: (
      | false
      | {
          agentCode: string;
          name: string;
          chineseName: string;
          errorCode?: number | undefined;
        }
    )[]
  ) => {
    this.notifyService.screenLoadingSubject.next(false);
    if (res.every(item => item)) {
      if (this.servicingAgentCode.value === this.splitAgentCode.value) {
        this.splitAgentCode.setErrors({ unMatch: true });
      } else {
        res.forEach((element, index) => {
          if (index === 0 && element) {
            this.tempServicingAgent = element;
          } else if (index === 1 && element) {
            this.tempSplitAgent = element;
          }
        });
        const temp = [];
        if (this.tempServicingAgent) {
          temp.push(this.tempServicingAgent);
        }
        if (this.tempSplitAgent) {
          temp.push(this.tempSplitAgent);
        }
        this.getAgentBranchCds(temp);
        this.isConfirming = true;
      }
    } else {
      res.forEach((element, index) => {
        if (!element) {
          if (index === 0) {
            this.servicingAgentCode.setErrors({ unMatch: true });
          } else if (index === 1) {
            this.splitAgentCode.setErrors({ unMatch: true });
          }
        }
      });
    }
  };

  getAgentBranchCds(tempAgentList: TempAgentType[]) {
    const agentCodes = [];
    for (const agent of tempAgentList) {
      agentCodes.push(agent.agentCode);
    }
    if (agentCodes.length === 0) {
      return;
    }
    this.cdHttpServeService
      .post<
        {
          agentCode: string;
          branchNo: string;
        }[]
      >('/ext/eb-ssms/agent-service/agent/branchCd', agentCodes)
      .subscribe({
        next: res => {
          if (res.result === 0) {
            for (let i = 0; i < res.data.length; i++) {
              const filAgent = tempAgentList.filter(tempAgent => tempAgent.agentCode === res.data[i].agentCode);
              if (filAgent.length > 0) {
                filAgent[0].branchNo = res.data[i].branchNo;
              }
            }
          }
        },
        error: err => {
          this.alert.error('Error!', err);
        },
      });
  }

  eMPFReferenceNoChange(val: string) {
    if (/[\u4e00-\u9fa5]/gi.test(val)) {
      this.eMPFReferenceNo.setValue(val.replace(/[\u4e00-\u9fa5]/gi, ''));
    }
  }

  validateAgentCode(splitAgentCode: string) {
    return new Promise<
      | false
      | {
          agentCode: string;
          name: string;
          chineseName: string;
          errorCode?: number;
        }
    >(resolve => {
      this.cdHttpServeService
        .post<{
          agentCode: string;
          name: string;
          chineseName: string;
          errorCode?: number;
        }>('/ext/eb-ssms/customer-service/add-delegation/validate', {
          agentCode: agentCodeRemovePrefix0(splitAgentCode.trim()),
        })
        .subscribe({
          next: res => {
            if (res.result === 0) {
              resolve(res.data);
            } else {
              // this.alert.error('Error!', res.message);
              resolve(false);
            }
          },
          error: () => {
            // this.alert.error('Error!', err);
            resolve(false);
          },
        });
    });
  }

  getAgentInformation(agentCode: string) {
    return new Promise<AgentDetailInfo | null>(resolve => {
      this.cdHttpServeService.get<AgentDetailInfo>('/ext/eb-ssms/agent-service/agent/information', { agentInfo: agentCode }).subscribe({
        next: res => {
          if (res.result === 0) {
            resolve(res.data);
          } else {
            this.alert.error('Error!', res.message);
            resolve(null);
          }
        },
        error: err => {
          this.alert.error('Error!', err);
          resolve(null);
        },
      });
    });
  }

  submit() {
    this.notifyService.screenLoadingSubject.next(true);

    const promiseList: Promise<AgentDetailInfo | null>[] = [];
    if (this.servicingAgentCode.value) {
      promiseList.push(this.getAgentInformation(this.servicingAgentCode.value));
    }
    if (this.splitAgentCode.value) {
      promiseList.push(this.getAgentInformation(this.splitAgentCode.value));
    }
    Promise.all(promiseList).then(this.getAgentInformationAllCallBack);
  }

  servicingAgentFrApiAgentInformation: AgentDetailInfo | null = null;
  splitAgentFrApiAgentInformation: AgentDetailInfo | null = null;
  getAgentInformationAllCallBack = (res: (AgentDetailInfo | null)[]) => {
    this.servicingAgentFrApiAgentInformation = null;
    this.splitAgentFrApiAgentInformation = null;
    res.forEach((element, index) => {
      if (element) {
        if (index === 0) {
          this.servicingAgentFrApiAgentInformation = element;
        } else if (index === 1) {
          this.splitAgentFrApiAgentInformation = element;
        }
      }
    });
    from(getSessionByKey())
      .pipe(switchMap(this.getIdentityIdAlreadySubmission))
      .subscribe({
        next: res => {
          if (res.result === 0) {
            const ref = this.cdsPopup.open(UpdatedSuccessfullyComponent, {
              size: 'md',
            });
            ref.afterClosed().subscribe(() => {
              this.router.navigate(['/salesjourney/sales-support-summary', { productType: this.productType.value }]);
            });
          } else {
            this.alert.error('Error!', res.message);
          }
          this.notifyService.screenLoadingSubject.next(false);
        },
        error: err => {
          this.alert.error('Error!', err);
          this.notifyService.screenLoadingSubject.next(false);
        },
      });
  };

  get isServicingAgentCodePerformed() {
    return this.individualsForm.get('isServicingAgentCodePerformed') as FormControl;
  }

  getIdentityIdAlreadySubmission = (res: Session) => {
    const agentInfos = [];
    if (this.servicingAgentFrApiAgentInformation) {
      agentInfos.push({
        agentId: this.servicingAgentFrApiAgentInformation.agentId,
        agentCd: this.servicingAgentFrApiAgentInformation.agentCode,
        servcingAgent: true,
        splitAgent: false,
        acknowledged: this.isServicingAgentCodePerformed.value,
      });
    }
    if (this.splitAgentFrApiAgentInformation) {
      agentInfos.push({
        agentId: this.splitAgentFrApiAgentInformation.agentId,
        agentCd: this.splitAgentFrApiAgentInformation.agentCode,
        servcingAgent: false,
        splitAgent: true,
        acknowledged: this.isSplitAgentCodePerformed.value,
      });
    }
    const identityId = res.id;

    const dList = this.paperIFFAgentSubmissionDateFrom.value.split('/');
    const paperFormSubmissionDate = `${dList[2]}-${dList[1]}-${dList[0]} 00:00:00`;
    const params: SubmissionParams = {
      productType: this.productType.value,
      identityId,
      formType: 'MBR',
      empfRefNo: this.eMPFReferenceNo.value,
      clientFirstNmEn: this.customerEnglishGivenName,
      clientLastNmEn: this.customerEnglishSurname,
      clientFirstNmChn: this.customerChinaGivenName,
      clientLastNmChn: this.customerChinaSurname,
      idType: +this.idType.value,
      hkidOrPassportNo: this.hkidOrPassportNo.value,
      clientCommChannel: this.clientContact,
      countryCode: this.clientCountryCode.value,
      phoneNumber: this.clientPhoneNumber.value,
      emailAddr: this.clientEmailAddress.value,
      specialNeeds: this.confirmSpecialNeedsValue === 'y',
      paperFormSubmissionDate,
      agentInfos,
      dbs: {
        dbsRmReferralCd: this.rmReferralCode.value,
        dbsCustomerId: this.inputCustomerId.value,
        dbsCampaignCd: this.campaignCode.value,
        dbsSourceCd: this.sourceCode.value,
      },
    };
    if (!this.customerEnglishGivenName) {
      delete params['clientFirstNmEn'];
    }
    if (!this.customerEnglishSurname) {
      delete params['clientLastNmEn'];
    }
    if (!this.customerChinaGivenName) {
      delete params['clientFirstNmChn'];
    }
    if (!this.customerChinaSurname) {
      delete params['clientLastNmChn'];
    }
    if (this.clientContact === 'E') {
      delete params['countryCode'];
      delete params['phoneNumber'];
    } else if (this.clientContact === 'M') {
      delete params['emailAddr'];
      params['smsLanguage'] = this.preferredLanguage.value === PreferredLanguageType.English ? 0 : 1;
    }
    return this.cdHttpServeService.post<{ salesJourneySummariesId: string }>('/ext/eb-ssms-sales-journey-service/paperFormIff/submission', params);
  };

  backToEdit() {
    this.isConfirming = false;
    this.dbsContactConfig.options.forEach((e: { disable: boolean }) => {
      e.disable = false;
    });
    this.dbsReferredLanguage.refreshConfig();
  }

  dbsContractChange(val: string) {
    this.isDBschannel = val;
    if (val === 'N') {
      this.individualsForm.get('rmReferralCode')?.reset();
      this.individualsForm.get('inputCustomerId')?.reset();
      this.individualsForm.get('campaignCode')?.reset();
      this.individualsForm.get('sourceCode')?.reset();
    }
  }

  clientContactChange(val: string) {
    this.preferredLanguage.setValue(undefined);
    if (val === 'E') {
      this.preferredLanguageConfig = {
        options: [
          {
            label: 'common.chinese',
            value: PreferredLanguageType.Chinese,
            disable: true,
          },
          {
            label: 'common.english',
            value: PreferredLanguageType.English,
            disable: true,
          },
        ],
      };
    } else if (val === 'M') {
      this.preferredLanguageConfig = {
        options: [
          {
            label: 'common.chinese',
            value: PreferredLanguageType.Chinese,
            disable: false,
          },
          {
            label: 'common.english',
            value: PreferredLanguageType.English,
            disable: false,
          },
        ],
      };
    }

    this.cdRadioPreferredLanguage?.refreshConfig();
  }

  reset() {
    const popupRef: MatDialogRef<ResetWarningComponent> = this.cdsPopup.open(ResetWarningComponent, {
      size: 'sm',
    });
    popupRef.afterClosed().subscribe(this.resetWarningComponentPopupCloseCall);
  }

  resetWarningComponentPopupCloseCall = (confirm: { agree: boolean }) => {
    if (confirm.agree) {
      this.individualsForm.reset();
      this.productType.setValue(null);
      this.customerEnglishSurname = '';
      this.customerEnglishGivenName = '';
      this.customerChinaSurname = '';
      this.customerChinaGivenName = '';
      this.clientCountryCode.setValue('852');
      this.idType.setValue('0');
      this.isTryConfirm = false;
      this.confirmSpecialNeedsValue = 'n';
      this.advisedSpecialNeedsValue = '1';
      this.resetDatePicker = true;
      this.clientContact = '';
      this.isDBschannel = '';
      setTimeout(() => {
        this.resetDatePicker = false;
        setTimeout(() => {
          this.setPaperAgentSubmissionDateReadonly();
        }, 0);
      }, 0);
      this.preferredLanguage.reset();
      this.preferredLanguageConfig = {
        options: [
          {
            label: 'common.chinese',
            value: PreferredLanguageType.Chinese,
            disable: true,
          },
          {
            label: 'common.english',
            value: PreferredLanguageType.English,
            disable: true,
          },
        ],
      };
      this.cdRadioPreferredLanguage.refreshConfig();
    }
  };

  isAllowPast() {
    if (
      !this.productType.valid ||
      !this.eMPFReferenceNo.valid ||
      !this.paperIFFAgentSubmissionDateFrom.valid ||
      !this.servicingAgentCode.valid ||
      !this.splitAgentCode.valid ||
      !this.preferredLanguage.valid ||
      this.isCustomerEnglishError ||
      this.isCustomerChinaError
    ) {
      return false;
    }
    if (this.isDBschannel === 'Y' && (!this.rmReferralCode.valid || !this.campaignCode.valid || !this.inputCustomerId.valid || !this.sourceCode.valid)) {
      return false;
    }
    if (this.idType.value === '0' && !this.hkidOrPassportNo.valid) {
      return false;
    }
    if (this.isDBschannel === '') {
      return false;
    }
    if (this.clientContact === '') {
      return false;
    }
    if (this.clientContact === 'E' && !this.clientEmailAddress.valid) {
      return false;
    } else if (this.clientContact === 'M' && !this.clientPhoneNumber.valid) {
      return false;
    }

    return true;
  }

  get isCustomerEnglishError() {
    if (!this.isTryConfirm) {
      return false;
    }
    if (!this.customerEnglishSurname && !this.customerEnglishGivenName && !this.customerChinaSurname && !this.customerChinaGivenName) {
      return true;
    } else if ((this.customerEnglishSurname && !this.customerEnglishGivenName) || (!this.customerEnglishSurname && this.customerEnglishGivenName)) {
      return true;
    }
    return false;
  }

  get isCustomerChinaError() {
    if (!this.isTryConfirm) {
      return false;
    }
    if (!this.customerEnglishSurname && !this.customerEnglishGivenName && !this.customerChinaSurname && !this.customerChinaGivenName) {
      return true;
    } else if ((this.customerChinaSurname && !this.customerChinaGivenName) || (!this.customerChinaSurname && this.customerChinaGivenName)) {
      return true;
    }
    return false;
  }

  getAgentName(obj?: { name: string; chineseName: string }) {
    if (!obj) {
      return 'N/A';
    }
    if (this.cdsLangService.currentLang === 'en') {
      return obj.name || obj.chineseName;
    } else {
      return obj.chineseName || obj.name;
    }
  }

  isDisableServicingAgentCodePerformed() {
    if (this.isConfirming) {
      this.isServicingAgentCodePerformed.disable();
    } else {
      this.isServicingAgentCodePerformed.enable();
    }
  }

  isDisableSplitAgentCodePerformed() {
    const disable =
      this.isConfirming ||
      !(!this.individualsForm.get('servicingAgentCode')?.hasError('required') && this.individualsForm.get('isServicingAgentCodePerformed')?.value);

    if (disable) {
      this.isSplitAgentCodePerformed.disable();
    } else {
      this.isSplitAgentCodePerformed.enable();
    }
  }

  ngDoCheck() {
    this.isDisableServicingAgentCodePerformed();
    this.isDisableSplitAgentCodePerformed();
  }
}
