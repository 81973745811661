/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Input, OnInit } from '@angular/core';
import { finalize } from 'rxjs';
import { ColumnConfig, PageConfig } from 'src/app/shared/data-table';
import { ToastAlertService } from 'src/app/shared/toast-alert.service';
import { CampaignInformationRequest } from '../employee';
import { EmployeeService } from '../employee.service';
import moment from 'moment';
import { Campaign, CampaignSearchRequest, CampaignTypeOptions, agentCommunicationOptions, productTypeOptions } from '../../campaign/campaign';
import { CdsOption } from '@cds/ng-core/configuration';
import { findOptionLabelByValue } from 'src/app/utils/utils';

@Component({
  selector: 'app-campaign-information',
  templateUrl: './campaign-information.component.html',
  styleUrls: ['./campaign-information.component.scss'],
})
export class CampaignInformationComponent implements OnInit {
  @Input() md5 = '';
  @Input() inPolicy = false;

  isLoading = false;

  pageConfig: PageConfig = {
    current: 1,
    pageSize: 10,
    totalCount: 0,
  };

  dataSource: Campaign[] = [];

  baseColumnsConfig: ColumnConfig[] = [
    {
      key: 'campaignId',
      title: 'Campaign ID',
      sticky: true,
    },
    {
      key: 'campaignName',
      title: 'Campaign Name',
    },
    {
      key: 'campaignDescription',
      title: 'Campaign Description',
    },
    {
      key: 'campaignOwner',
      title: 'Campaign Owner',
    },
    {
      key: 'campaignPeriod',
      title: 'Campaign Period',
    },
    {
      key: 'campaignType',
      title: 'Campaign Type',
    },
    {
      key: 'productTypes',
      title: 'Product Type',
    },
    {
      key: 'customerCommunicationChannel',
      title: 'Customer Communication Channel',
    },
    {
      key: 'customerPreferenceLanguage',
      title: 'Customer Preference Language',
    },
    {
      key: 'mbAcctCd',
      title: 'Member Account Code',
    },
    {
      key: 'policyType',
      title: 'Policy Type',
    },
    {
      key: 'agentCommunication',
      title: 'Agent Communication Channel',
    },
  ];

  columnsConfigInProfile: ColumnConfig[] = [
    ...this.baseColumnsConfig,
    {
      key: 'assignedAgent',
      title: 'Assigned Agent',
    },
    {
      key: 'messageDelivered',
      title: 'Message Delivered',
    },
    {
      key: 'opened',
      title: 'Opened',
    },
    {
      key: 'customerFirstResponseDate',
      title: 'Customer First Response Date',
    },
    {
      key: 'customerLastResponseDate',
      title: 'Customer LastResponse Date',
    },
  ];

  columnsConfigInPolicy: ColumnConfig[] = [
    ...this.baseColumnsConfig,
    {
      key: 'workbasket',
      title: 'Workbasket',
    },
    {
      key: 'agentSam',
      title: 'Agent Sam',
    },
    {
      key: 'messageDelivered',
      title: 'Message Delivered',
    },
    {
      key: 'opened',
      title: 'Opened',
    },
  ];

  columnsConfig: ColumnConfig[] = [];

  productTypeOptions = productTypeOptions;
  agentCommunicationOptions = agentCommunicationOptions;
  CampaignTypeOptions = CampaignTypeOptions;

  findOptionLabelByValue = findOptionLabelByValue;

  constructor(private service: EmployeeService, private toastAlert: ToastAlertService) {}

  ngOnInit(): void {
    this.columnsConfig = this.inPolicy ? this.columnsConfigInPolicy : this.columnsConfigInProfile;
    this.loadTable();
  }

  loadTable() {
    const params: CampaignSearchRequest = this.getRequestParams();
    this.isLoading = true;

    this.service
      .getCampaignMemberList(params)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe(data => {
        if (data.result === 0 && data.data) {
          this.isLoading = false;
          this.dataSource = data.data.empty ? [] : data.data.content;
          // this.numberOfElements = data.data.totalElements;
          this.pageConfig = {
            ...this.pageConfig,
            totalCount: data.data.totalElements,
          };
        } else {
          this.toastError(data.message);
        }
      });
  }

  getRequestParams() {
    const params: CampaignInformationRequest = {
      md5: this.md5,
      pageChannel: 'ee',
      page: (this.pageConfig.current as number) - 1,
      size: 10,
    };
    return params;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  reloadTable(event: any) {
    this.pageConfig = {
      ...this.pageConfig,
      current: event.current,
    };
    this.loadTable();
  }

  toastError(errorMessage: string) {
    this.toastAlert.show('error', 'common.error', errorMessage, 5000);
  }

  dateTransToDisplay(date: string) {
    if (date) {
      return moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY');
    }

    return date;
  }

  listValueRender(list: any[], options: CdsOption[]) {
    if (!list) return '';
    return list
      .map(item => {
        return findOptionLabelByValue(item, options);
      })
      .join(' & ');
  }

  jumpTo(url: string) {
    window.open(url, '_blank');
  }
}
