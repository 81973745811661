<app-cd-page-title
  [showBack]="true"
  [backLandingLabel]="'commission.pool.agents.create.backText' | lang"
  (backLanding)="doBackMethod()"
  title="{{ 'commission.pool.agents.create.title' | lang }}">
</app-cd-page-title>
<div class="l-pb-6">
  <div class="row">
    <div class="col-xs-12 col-md-12 l-pt-6">
      <div class="cds-h5-light l-pt-2 l-pb-2" style="font-weight: 400">
        {{ 'commission.pool.summary.commissionPoolNumber' | lang }}:&nbsp;&nbsp;&nbsp;&nbsp;
        <span class="font-bold">{{ commissionPoolId }}</span>
      </div>

      <div class="l-mb-6">
        <span *ngIf="!isConfirm" class="cds-h2-light"> Update Selling Agent or SA Report Rec’d Date </span>
        <span *ngIf="isConfirm" class="cds-h2-light"> Update Selling Agent or SA Report Rec’d Date Summary </span>
      </div>

      <cds-progress *ngIf="loading" mode="indeterminate" class="l-pl-6"></cds-progress>

      <div *ngIf="!loading">
        <div class="col-xs-12 col-md-12 l-pt-3 l-pb-3 div-border-top card" style="overflow: auto">
          <table class="table-width col-xs-12 col-md-12 cds-table full div-border-top div-border-bottom">
            <caption></caption>
            <tr class="div-border-bottom">
              <th scope="col" class="padding-24 font-bold text-left" width="100">
                {{ 'commission.pool.agents.create.agent' | lang }}
              </th>
              <th scope="col" class="padding-24 font-bold text-left" width="150">
                {{ 'commission.pool.agents.create.sellingAgent' | lang }}
              </th>
              <th scope="col" class="padding-24 font-bold text-left">
                <div style="width: 50%">
                  {{ 'commission.pool.agents.create.reportDate' | lang }}
                </div>
              </th>
            </tr>
            <ng-container *ngFor="let item of dataSource; let i = index">
              <tr class="border-top">
                <td class="padding-24-bottom-5 cds-detail1" width="150">
                  <div class="cds-body2 cds-dark-navy-text light-3">
                    {{ item.agentCode }}
                  </div>
                  <div class="l-pt-2">
                    <span>{{ item.agentName }}</span>
                  </div>
                </td>
                <td class="padding-24-bottom-5 cds-detail1">
                  <div class="cds-body2 cds-dark-navy-text light-3">
                    {{ 'commission.pool.agents.create.sellingAgent' | lang }}
                  </div>
                  <div class="l-pt-2">
                    <cds-checkbox
                      [(ngModel)]="item.sellingAgent"
                      (ngModelChange)="formChange('item.sellingAgent', i)"
                      [disabled]="isConfirm"
                      label=""></cds-checkbox>
                  </div>
                </td>
                <td class="cds-detail1 padding-24-bottom-5">
                  <div style="width: 50%">
                    <div class="cds-body2 cds-dark-navy-text light-3">
                      {{ 'commission.pool.agents.create.reportDate' | lang }}
                    </div>
                    <div *ngIf="isConfirm" class="l-pt-2">
                      <span>{{ item.saReportDate }}</span>
                    </div>
                    <div *ngIf="!isConfirm" style="width: 80%">
                      <cds-textfield
                        onKeypress="return(/(\S)/.test(String.fromCharCode(event.keyCode)))"
                        [(ngModel)]="item.saReportDate"
                        placeholder="DD/MM/YYYY"
                        [cdsDatepicker]="picker2"
                        (ngModelChange)="formChange('item.saReportDate', i)">
                      </cds-textfield>
                      <cds-datepicker #picker2="cdsDatepicker"></cds-datepicker>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td class="l-pb-5 l-pl-2"></td>
                <td class="l-pb-5 l-pl-2">
                  <cds-assistive-text type="warning" *ngIf="item.sellingAgentWarning">
                    <span style="color: #d77d28">
                      {{ item.sellingAgentWarning | lang }}
                    </span>
                  </cds-assistive-text>
                </td>
                <td class="l-pb-5 l-pl-2">
                  <cds-assistive-text type="warning" *ngIf="item.saReportDateWarning">
                    <span style="color: #d77d28">
                      {{ item.saReportDateWarning | lang }}
                    </span>
                  </cds-assistive-text>
                  <cds-assistive-text *ngIf="item.saReportDateError">
                    {{ item.saReportDateError | lang }}
                  </cds-assistive-text>
                </td>
              </tr>
            </ng-container>
          </table>
          <div *ngIf="dataSource.length === 0" class="col-xs-12 col-md-12">
            <app-no-result-found></app-no-result-found>
          </div>
          <cds-assistive-text type="warning" class="l-pt-2" *ngIf="warningMsg">
            <span style="color: #d77d28">
              {{ warningMsg | lang }}
            </span>
          </cds-assistive-text>
        </div>
        <div *ngIf="!isConfirm" class="col-xs-12 col-md-12 l-pt-6 l-pb-3 l-d-f l-plr-0">
          <cds-button size="md" [config]="btnCfg" (click)="reset()">{{ 'commission.pool.common.reset' | lang }} </cds-button>
          <cds-button size="md" class="l-ml-3" (click)="save()" [disabled]="disableSave">{{ 'commission.pool.common.save' | lang }} </cds-button>
        </div>
        <div *ngIf="isConfirm" class="col-xs-12 col-md-12 l-pt-6 l-pb-3 l-d-f l-plr-0">
          <cds-button size="md" [config]="btnCfg" (click)="backToEdit()">{{ 'commission.pool.common.backToEdit' | lang }} </cds-button>
          <cds-button size="md" class="l-ml-3" (click)="confirm()">{{ 'commission.pool.common.confirm' | lang }} </cds-button>
        </div>
      </div>
    </div>
  </div>
</div>
