export const EMPF_APP_STATUS = [
  {
    text: 'eMPF Processing',
    info: 'The record is currently under progress in eMPF',
    color: 'var(--cds-color-turquoise)',
    value: 1,
  },
  {
    text: 'eMPF Follow-up',
    info: 'The record is currently under follow-up in eMPF',
    color: 'var(--cds-color-jh-blue)',
    value: 2,
  },
  {
    text: 'eMPF Application cancelled',
    info: 'The record is canclled in eMPF',
    color: 'var(--cds-color-dark-2-coral)',
    value: 3,
  },
  {
    text: 'eMPF Completed',
    info: 'The record and onboarding are completed in eMPF',
    color: 'var(--cds-color-dark-1-green)',
    value: 4,
  },
  {
    text: 'eMPF Rejected',
    info: 'The record is being rejected in eMPF',
    color: 'var(--cds-color-light-4-dark-navy)',
    value: 5,
  },
  {
    text: 'Pending eMPF data',
    info: 'The record is pending for eMPF data feed',
    color: 'var(--cds-color-gold)',
    value: 6,
  },
  {
    text: 'Partially Completed',
    info: 'The record and onboarding are partially completed in eMPF',
    color: 'var(--cds-color-coral)',
    value: 7,
  },
  {
    text: 'Not Applicable',
    info: undefined,
    color: 'var(--cds-color-violet)',
    value: 8,
  },
];
