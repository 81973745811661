import {
  Component,
  Input,
  LOCALE_ID,
  OnInit,
  Inject,
  ChangeDetectorRef,
  AfterViewInit,
  OnDestroy,
  ViewChild,
  ViewChildren,
  QueryList,
  ElementRef,
} from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Location, formatDate } from '@angular/common';

import { Sort, SortDirection } from '@cds/ng-data-table/sort';
import { CdsColorTheme } from '@cds/ng-core/configuration';
import { ActionIcons, ArrowIcons } from '@cds/ng-themes';
import { CdsLangService } from '@cds/ng-core/lang';
import { CdsIconConfig } from '@cds/ng-core/icon';
import { CdsProgressType } from '@cds/ng-core/progress';
import { CdsTextfieldConfig } from '@cds/ng-core/textfield';
import { CdsButtonConfig } from '@cds/ng-core/button';

import { AgentEmployeeDelegationService } from '../agent-employee-delegation.service';
import { EmployeeRequest } from './employee-request.model';
import { EmployeeDataService } from './employee-data.service';
import { AgentEmployeeResponseData } from '../agent-employee-response.model';
import { EmployeeResponse, EmployeeResponseData } from './employee-response.model';
import { CheckAgentCodeRequest } from '../employer-summary/check-agent-code-request';
import { CheckAgentCodeResponse } from '../employer-summary/check-agent-code-response';
import { BehaviorSubject, Subscription } from 'rxjs';
import { UserExitEditmodePopComponent } from '../user-exit-editmode-pop/user-exit-editmode-pop.component';
import { CdsPopupService, MatDialogRef } from '@cds/ng-web-components/popup';
import { CdsInlineAlertType } from '@cds/ng-web-components/inline-alert';
import { EmployeeCheckRequest } from './employee-check-request';
import { CheckResponseData } from './employee-save-response';
import { EmployeeSaveModel } from './employee-save-model';
import { UserSuccessSubmittedComponent } from '../user-success-submitted/user-success-submitted.component';
import { UserConfirmPopComponent } from '../user-confirm-pop/user-confirm-pop.component';
import { LanguageChangeService } from 'src/app/shared/language-change.service';
import { AgentBranchPopComponent } from '../agent-branch-pop/agent-branch-pop.component';
import { BranchAgentResponse } from '../agent-branch-pop/branch-agent-response';
import { CdsAlertService } from '@cds/ng-web-components/alert';
import { specialCharacterDecode } from 'src/app/utils/special-character';
import { CdStepperComponent } from 'src/app/shared/cd-stepper/cd-stepper.component';
import { agentCodeAddPrefix0, pageToTop } from 'src/app/utils/utils';
import { CdStepComponent } from 'src/app/shared/cd-stepper/cd-step/cd-step.component';
import { CdDropdownComponent } from 'src/app/shared/cd-dropdown/cd-dropdown.component';
import { NotifyService } from 'src/app/core/services/notify.service';

@Component({
  selector: 'app-employee-summary',
  templateUrl: './employee-summary.component.html',
  styleUrls: ['./employee-summary.component.scss'],
})
export class EmployeeSummaryComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('tableBox', { static: false })
  tableBox?: ElementRef<HTMLDivElement>;
  summaryIsEdit = false;
  summaryDataSource: AgentEmployeeResponseData[] = [];
  language = this.cdsLangService.currentLang === 'en' ? 'EN' : 'TC';
  employerId = '';
  cdsProgressType: CdsProgressType = 'ring';
  cdsProgressTxt = 'Loading';
  cdsProgressColor: CdsColorTheme = 'ml';
  summaryToggleToWhichPage = '';
  editSearch = false;
  myEmployeeRequest: EmployeeRequest = {
    page: 1,
    size: 10,
    language: this.language,
    sort: 'commencementDate desc,trusteeMbrId asc',
    isCoolingPeriod: 'ALL',
  };
  ifShow = false;
  updateIcon = ActionIcons.edit;
  employeeTotalCount = 0;
  backErIcon = ActionIcons.button_left_filled;
  pageShowState = 1; //1  summary page ; 2  delegate page ; 3 submit page
  private employerDataSubscription?: Subscription;
  //delegete variable
  totalpage = 0;
  delgtEmployeeTotalCount = 0;
  isEdit = true;
  allChecked = false;
  dataSource: EmployeeResponseData[] = [];
  dlgtNameValue = '';
  agentValue = '';
  elementsToSubmit: EmployeeResponseData[] = [];
  toggleToWhichPage = '';
  ckRequest: CheckAgentCodeRequest = {};
  checkState = true;
  ckResultData: CheckAgentCodeResponse = {};
  resultState = '';
  messages = '';
  markPage = 1;
  dataSubcription = new BehaviorSubject(new EmployeeResponse());
  isShowProgress = true;
  isSubmitState = true;
  companyName = '';
  totalCount = 0;
  clearDisabledState = true;
  alertItems: {
    index: number;
    ifShow: boolean;
    message: string;
    messageTc: string;
  }[] = [];
  inputAgentcode = '';
  isSubShowProgress = true;
  enterShowProgress = true;

  fullDataSource: { data: EmployeeResponseData[]; page: number }[] = [{ data: [], page: 0 }];
  boxInputNum = 0;
  isSelectedNum = 0;
  pageInitState = true;
  subDataState = true;
  checkAgentList: EmployeeCheckRequest[] = [];
  checkResultList: CheckResponseData[] = [];
  branchList: BranchAgentResponse[] = [];

  // submit variable
  submitDataList: EmployeeSaveModel[] = [];
  subPage = 1;
  subPageCount = 1;
  subSearchValue = '1';
  subEmployerTotalCount = 0;
  subTotalCount = 0;
  subDataSource: EmployeeResponseData[] = [];
  resetTable = false;

  @Input() fullWidth = true;
  @Input() color: CdsColorTheme = 'ml';
  @Input() summaryPageCount = 1;
  @Input() summaryPage = 1;
  @Input() sortDirection: SortDirection = 'asc';
  @Input() searchBtnDisableState = true;
  @Input() nameValue = '';
  @Input() numberValue = '';
  @Input() ageValue = '';
  @Input() genderValue = '';
  @Input() delegateAgentInfoValue = '';
  @Input() nameSearch = '名称';
  @Input() editModel = false;
  @Input() searchPlaceHolder?: string;
  private lanChangeSubscription?: Subscription;
  private errorSub?: Subscription;
  private agentEmployeeDelegation?: Subscription;
  stepperTarget: 'pc' | 'ipad' = 'pc';

  //delegate input
  @Input() pageCount = 1;
  @Input() page = 1;
  @Input() searchValue = '1';
  @Input() agentcodeValue = '';
  @Input() alertType: CdsInlineAlertType = 'error';
  showTable = true;
  isShowButtonList = false;
  moreOptions = ActionIcons.more_options;
  reloadIcon = ArrowIcons.reload;
  summaryPageState = true;
  @ViewChild('stepper') stepper!: CdStepperComponent;
  @ViewChildren('step') stepList!: QueryList<CdStepComponent>;
  @ViewChild('aggCdDropdown', { static: false })
  aggCdDropdown!: CdDropdownComponent;
  @ViewChild('genderCdDropdown', { static: false })
  genderCdDropdown!: CdDropdownComponent;
  paramTmp: Params = {};
  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private cdsLangService: CdsLangService,
    private agentEmployeeDelegationService: AgentEmployeeDelegationService,
    private location: Location,
    private route: ActivatedRoute,
    private employeeDataService: EmployeeDataService,
    public languageChangeService: LanguageChangeService,
    private cdsPopup: CdsPopupService,
    @Inject(LOCALE_ID) public locale: string,
    private alert: CdsAlertService,
    private router: Router,
    private notifyService: NotifyService
  ) {}
  ngOnDestroy(): void {
    this.agentEmployeeDelegation?.unsubscribe();
    this.lanChangeSubscription?.unsubscribe();
    // this.dataSubcription?.unsubscribe();
    this.employerDataSubscription?.unsubscribe();
    this.errorSub?.unsubscribe();
    this.employerDataSubscription?.unsubscribe();
    this.clearSubmitData();
  }
  stepTitle: { en: string; tc: string }[] = [];
  ngOnInit(): void {
    document.getElementsByClassName('cds-navigation-content')[0]?.scrollTo(0, 0);
    this.employerId = String(this.route.snapshot.paramMap.get('employerId'));
    this.myEmployeeRequest.employerId = this.employerId;
    this.myEmployeeRequest.language = this.language;
    this.agentEmployeeDelegationService.getEmployees(this.myEmployeeRequest);
    this.agentEmployeeDelegation = this.agentEmployeeDelegationService.employeeDataSubject.subscribe(response => {
      if (response.result != 0) {
        this.summaryDataSource = [];
        this.employeeTotalCount = 0;
        this.summaryPageCount = 0;
      } else {
        this.summaryDataSource = response.data ? response.data.content : [];
        this.employeeTotalCount = response.data ? response.data.totalElements : 0;
        this.summaryPageCount = response.data ? response.data.totalPages : 0;
      }
      this.isShowProgress = false;
      this.enterShowProgress = false;
      this.summaryPageState = true;
    });

    this.errorSub = this.agentEmployeeDelegationService.errorEmployeeSubject.subscribe(errorMessage => {
      const alertTitle = 'Got Error!';
      this.alert.error(alertTitle, errorMessage);
      this.isShowProgress = false;
      this.enterShowProgress = false;
    });

    this.lanChangeSubscription = this.languageChangeService.lanSubject.subscribe(languge => {
      this.language = languge === 'en' ? 'EN' : 'TC';
      this.myEmployeeRequest.language = this.language;
      this.cdsProgressTxt = languge === 'en' ? 'Loading' : '載入中';
    });
    this.dataSubcription = this.employeeDataService.getSubject();

    this.route.queryParams.subscribe(param => {
      this.paramTmp = param;
      this.employerName = specialCharacterDecode(decodeURI(param['en']));
      this.employerNameTC = specialCharacterDecode(decodeURI(param['zh']));
    });

    this.employerDataSubscription = this.dataSubcription.subscribe(response => {
      if (response.data !== undefined) {
        this.dataSource = response.data?.content;
        this.delgtEmployeeTotalCount = response.data?.totalElements;
        this.pageCount = Math.ceil(this.delgtEmployeeTotalCount / 10);
        this.showTable = response.data?.totalElements === 0 ? false : true;
        this.markPage = this.page;
        this.searchValue = this.page + '';
        this.initFullData(this.pageCount);
        this.fullDataSource.splice(this.page - 1, 1, {
          data: this.dataSource,
          page: this.page,
        });
        this.ckSelected(this.page);
        this.enterShowProgress = false;
        this.isShowProgress = false;
      }
    });
    this.stepTitle = [
      { en: 'Select Item(s)', tc: '選擇項目' },
      { en: 'Fill agent code', tc: '輸入代理人編號' },
      { en: 'Add code to selected item(s)', tc: '新增編號至已選項目' },
      { en: 'Submission', tc: '提交' },
    ];
  }
  employerName?: string;
  employerNameTC?: string;

  ngAfterViewInit(): void {
    setTimeout(() => {
      const menu = document.querySelector('aside.cds-menu-container') as HTMLElement;
      menu.style.display = 'none';
    }, 0);

    this.changeDetectorRef.detectChanges();

    if (window.matchMedia('only screen and (max-width: 1500px)').matches) {
      this.stepperTarget = 'ipad';
    } else {
      this.stepperTarget = 'pc';
    }
  }

  displayedColumns: string[] = ['employeeName', 'trusteeMbrId', 'gender', 'age', 'commencementDate', 'delegateAgentInfo', 'effStartDate'];
  dlgtDisplayedColumns: string[] = ['isSelected', 'employeeName', 'trusteeMbrId', 'gender', 'age', 'commencementDate', 'delegateAgentInfo'];
  subdisplayedColumns: string[] = ['employeeName', 'trusteeEmplrCd', 'gender', 'age', 'commencementDate', 'delegateAgentInfo'];

  onSortChange(event: Sort) {
    this.isShowProgress = true;
    let sortCondition = '';
    const active = event.active;
    let direction = '';
    sortCondition += active;
    sortCondition += ' ';
    if (event.direction === '') {
      sortCondition = 'commencementDate desc,trusteeMbrId asc';
    } else {
      direction = event.direction;
      sortCondition += direction;
    }
    this.myEmployeeRequest.isCoolingPeriod = 'ALL';
    this.myEmployeeRequest.employerId = this.employerId;
    this.myEmployeeRequest.sort = sortCondition;
    this.agentEmployeeDelegationService.getEmployees(this.myEmployeeRequest);
  }

  getforMateDate(date?: string): string {
    if (!date) {
      return '';
    }
    return formatDate(date, 'dd/MM/yyyy', this.locale);
  }

  pageChange(num: number): void {
    if (this.summaryPageState && num === this.summaryPage) {
      this.summaryPage = num;
      return;
    }
    this.isShowProgress = true;
    this.summaryPageState = true;
    console.log('change page', this.editSearch);
    this.myEmployeeRequest.isCoolingPeriod = 'ALL';
    this.myEmployeeRequest.isDelegated = '';
    this.summaryPage = num;
    this.myEmployeeRequest.page = num;
    this.myEmployeeRequest.employerId = this.employerId;
    this.agentEmployeeDelegationService.getEmployees(this.myEmployeeRequest);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  searchByInputPageNo($event: any, pageNo: string) {
    $event.preventDefault();
    let goToPage = 1;
    if (isNaN(parseInt(pageNo))) {
      goToPage = 1;
    } else if (+pageNo > this.summaryPageCount) {
      goToPage = this.summaryPageCount;
    } else {
      goToPage = +pageNo;
    }

    this.pageChange(goToPage);
  }

  backErIconConfig: CdsIconConfig = {
    color: 'cta',
    size: 'sm',
  };

  onCackErIconClick() {
    if (this.pageShowState === 1) {
      this.location.back();
    }
  }

  togglePage() {
    this.employeeDataService.setEditReqest(this.myEmployeeRequest);
    this.pageShowState = 2;
    this.searchBtnDisableState = true;
    this.ifShow = false;
    this.editSearch = true;
    this.showTable = true;

    setTimeout(() => {
      if (this.stepperTarget === 'ipad') {
        this.stepper.setStepList(this.stepList);
      }

      this.refreshStep();
    }, 0);
  }

  // from ee search

  employeeNameTxtfieldConfig: CdsTextfieldConfig = {
    label: this.language === 'EN' ? 'Employee Name' : '雇員名稱',
    type: 'text',
    maxlength: 20,
    color: 'ml',
    placeholder: this.language === 'EN' ? 'Please input' : '輸入詳情',
  };

  numbersTxtfieldConfig: CdsTextfieldConfig = {
    label: this.language === 'EN' ? 'Cert No' : '僱員編號',
    type: 'text',
    placeholder: this.language === 'EN' ? 'Please input' : '輸入詳情',
  };

  delegateTxtfieldConfig: CdsTextfieldConfig = {
    label: this.language === 'EN' ? 'Delegated Agent Code' : '委託代理編號',
    type: 'text',
    placeholder: this.language === 'EN' ? 'Please input' : '輸入詳情',
  };

  ageEnRangeDropdownConfig = [
    { label: '18-25', value: '18-25' },
    { label: '26-35', value: '26-35' },
    { label: '36-45', value: '36-45' },
    { label: '46-55', value: '46-55' },
    { label: '56-65', value: '56-65' },
    { label: '66 or above', value: '66-' },
  ];

  ageZhRangeDropdownConfig = [
    { label: '18-25', value: '18-25' },
    { label: '26-35', value: '26-35' },
    { label: '36-45', value: '36-45' },
    { label: '46-55', value: '46-55' },
    { label: '56-65', value: '56-65' },
    { label: '66以上', value: '66-' },
  ];

  genderEnDropdownConfig = [
    { label: 'Male', value: 'M' },
    { label: 'Female', value: 'F' },
  ];

  genderZhDropdownConfig = [
    { label: '男', value: 'M' },
    { label: '女', value: 'F' },
  ];

  employeeSearch(): void {
    if (this.nameValue == '' && this.numberValue == '' && this.ageValue == '' && this.genderValue == '' && this.delegateAgentInfoValue == '') {
      return;
    }
    if (this.editSearch) {
      return;
    }
    this.isShowProgress = true;
    this.ifShow = true;
    this.myEmployeeRequest.employeeName = this.nameValue;
    this.myEmployeeRequest.trusteeMbrId = this.numberValue;
    this.myEmployeeRequest.gender = this.genderValue;
    if (this.ageValue != '' && this.ageValue !== null) {
      const ageRankTmp = this.ageValue.split('-');
      this.myEmployeeRequest.ageStart = +ageRankTmp[0];
      if (ageRankTmp[1] !== '') {
        this.myEmployeeRequest.ageEnd = +ageRankTmp[1];
      }
    }
    this.myEmployeeRequest.page = 1;
    this.summaryPage = 1;
    this.myEmployeeRequest.delegateAgentCode = this.delegateAgentInfoValue;
    this.agentEmployeeDelegationService.getEmployees(this.myEmployeeRequest);

    this.onResetTable();
  }

  clearData(): void {
    if (this.pageShowState === 2) {
      return;
    }
    this.aggCdDropdown.clearValue();
    this.genderCdDropdown.clearValue();
    this.isShowProgress = true;
    this.ifShow = false;
    this.nameValue = '';
    this.numberValue = '';
    this.ageValue = '';
    this.genderValue = '';
    this.delegateAgentInfoValue = '';
    this.summaryPage = 1;
    this.searchBtnDisableState = true;
    this.myEmployeeRequest = new EmployeeRequest();
    this.myEmployeeRequest.page = 1;
    this.myEmployeeRequest.size = 10;
    this.myEmployeeRequest.language = this.language;
    this.myEmployeeRequest.employerId = this.employerId;
    this.myEmployeeRequest.isCoolingPeriod = 'ALL';
    this.myEmployeeRequest.sort = 'commencementDate desc,trusteeMbrId asc';
    this.agentEmployeeDelegationService.getEmployees(this.myEmployeeRequest);

    this.onResetTable();
  }

  primaryButtonConfig: CdsButtonConfig = {
    size: 'sm',
    style: 'primary',
  };

  secondaryButtonConfig: CdsButtonConfig = {
    size: 'sm',
    style: 'secondary',
  };

  searchCriteriaCheck(): void {
    if (this.nameValue === '' && this.numberValue === '' && this.ageValue === '' && this.genderValue === '' && this.delegateAgentInfoValue === '') {
      this.ifShow = false;
      this.searchBtnDisableState = true;
    }
  }

  nameChange(result: string): void {
    if (this.editSearch) {
      return;
    }
    this.nameValue = result;
    this.employeeDataService.request.employeeName = result;
    if (result === '') {
      this.nameValue = '';
    } else {
      this.searchBtnDisableState = false;
    }
    this.searchCriteriaCheck();
  }

  numberChange(result: string): void {
    if (this.editSearch) {
      return;
    }
    this.numberValue = result;
    if (result === '') {
      this.numberValue = '';
    } else {
      this.searchBtnDisableState = false;
    }
    this.searchCriteriaCheck();
  }

  ageChange(result: string): void {
    if (this.editSearch) {
      return;
    }
    this.ageValue = result;
    if (result === null || result === '') {
      this.ageValue = '';
    } else {
      this.searchBtnDisableState = false;
    }
    this.searchCriteriaCheck();
  }

  genderChange(result: string): void {
    if (this.editSearch) {
      return;
    }
    this.genderValue = result;
    if (result === null || result === '') {
      this.genderValue = '';
    } else {
      this.searchBtnDisableState = false;
    }
    this.searchCriteriaCheck();
  }

  delegateAgentChange(result: string): void {
    if (this.editSearch) {
      return;
    }
    this.delegateAgentInfoValue = result;
    if (result === '') {
      this.delegateAgentInfoValue = '';
    } else {
      this.searchBtnDisableState = false;
    }
    this.searchCriteriaCheck();
  }

  dismissAlert(index: number) {
    this.alertItems[index].ifShow = false;
  }

  degtTogglePage() {
    const popupRef: MatDialogRef<UserExitEditmodePopComponent> = this.cdsPopup.open(UserExitEditmodePopComponent, {});
    popupRef.afterClosed().subscribe(data => {
      if (data.agree) {
        this.ClearData();
        this.editSearch = false;
        this.fullDataSource.forEach(element => {
          element.data.length = 0;
        });
        if (this.nameValue !== '' || this.numberValue !== '' || this.ageValue !== '' || this.genderValue !== '' || this.delegateAgentInfoValue !== '') {
          this.ifShow = true;
          this.searchBtnDisableState = false;
        }
        this.pageShowState = 1;
        this.summaryPageState = false;
      }
    });
  }

  onHeaderCheckboxChange(val: boolean, whichPage: number) {
    this.allChecked = val;
    if (val) {
      // this.boxInputNum = this.boxInputNum + 10;
      this.fullDataSource[whichPage - 1].data.forEach(el => {
        if (!el.isSelected) {
          this.boxInputNum = this.boxInputNum + 1;
          this.isSelectedNum = this.isSelectedNum + 1;
        }
      });
    } else {
      this.fullDataSource[whichPage - 1].data.forEach(el => {
        if (el.isSelected) {
          this.boxInputNum = this.boxInputNum - 1;
          this.isSelectedNum = this.isSelectedNum - 1;
        }
      });
    }

    this.fullDataSource[whichPage - 1].data.forEach(element => {
      element.isSelected = val;
    });

    const finishNum = this.stepper.getFinishStep();
    if (finishNum === 4) {
      if (val) {
        this.stepper.setLightsOutStep(3);
      } else if (this.isSelectedNum === 0) {
        this.stepper.removeLightsOutStep(3);
      }
      return;
    }
    this.refreshStep();
  }

  checkBoxClick(val: boolean, checkedIndex: number, pageNo: number) {
    console.log('cjeck', this.fullDataSource);
    this.fullDataSource[pageNo - 1].data[checkedIndex].isSelected = val;
    this.ckSelected(pageNo);
    if (val) {
      this.boxInputNum = this.boxInputNum + 1;
      this.isSelectedNum = this.isSelectedNum + 1;
    } else {
      this.boxInputNum = this.boxInputNum - 1;
      this.isSelectedNum = this.isSelectedNum - 1;
    }

    const finishNum = this.stepper.getFinishStep();
    if (finishNum === 4) {
      if (val) {
        this.stepper.setLightsOutStep(3);
      } else if (this.isSelectedNum === 0) {
        this.stepper.removeLightsOutStep(3);
      }
      return;
    }
    this.refreshStep();
  }

  ckSelected(num: number) {
    if (this.fullDataSource[num - 1].data == undefined) {
      this.fullDataSource[num - 1].data = [];
    }
    let isThereNotChecked = this.fullDataSource[num - 1].data.some(isThereSome => !isThereSome.isSelected);
    if (this.fullDataSource[num - 1].data.length === 0) {
      isThereNotChecked = true;
    }
    if (isThereNotChecked) {
      this.allChecked = false;
    } else {
      this.allChecked = true;
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  delgtSearchByInputPageNo($event: any, pageNo: string) {
    $event.preventDefault();
    console.info($event, pageNo);
    console.info(parseInt(pageNo));
    if (isNaN(parseInt(pageNo))) {
      this.searchValue = '1';
    }
    this.delgtPageChange(parseInt(pageNo));
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  checkAgentCode(agentValue: string, row: any, index: number, page: number) {
    const value = agentValue.trim();
    if (value.length > 0 && value.length < 6) {
      this.fullDataSource[page - 1].data[index].flag = true;
      this.fullDataSource[page - 1].data[index].checkResult = 'agent code length should be 6';
      this.fullDataSource[page - 1].data[index].checkResultTc = '代理人編號應為六位數字';
      return;
    }

    if (value.length === 0) {
      this.fullDataSource[page - 1].data[index].flag = undefined;
      this.fullDataSource[page - 1].data[index].checkdata = value;
      let submistState = false;
      let clearState = false;
      this.fullDataSource.forEach(element => {
        element.data.forEach(data => {
          if (data.flag) {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            submistState = true;
          }
          // eslint-disable-next-line no-extra-boolean-cast
          if (!!data.checkdata) {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            clearState = true;
          }
        });
      });
      this.clearDisabledState = clearState ? false : true;
      return;
    }
    this.ckRequest.agentCode = value;
    // this.ckRequest.identityId = this.identityId;
    this.employeeDataService.checkData(this.ckRequest).subscribe(resp => {
      const flag = resp.result == 0 ? false : true;
      this.fullDataSource[page - 1].data[index].flag = flag;
      this.fullDataSource[page - 1].data[index].checkdata = value;
      if (this.fullDataSource[page - 1].data[index].delegateAgentInfo === undefined) {
        this.fullDataSource[page - 1].data[index].delegateAgentInfo = {};
      }
      this.fullDataSource[page - 1].data[index].delegateAgentInfo.agentCode = resp.data?.agentCode ? agentCodeAddPrefix0(resp.data?.agentCode) : '';
      this.fullDataSource[page - 1].data[index].delegateAgentInfo.name = resp.data?.name || '';
      this.fullDataSource[page - 1].data[index].delegateAgentInfo.nameTC = resp.data?.chineseName || '';
      if (flag) {
        const mesg =
          resp.data?.errorCode === 2101
            ? 'Agent code does not exist'
            : resp.data?.errorCode === 2102
            ? 'Inactive agent'
            : resp.data?.errorCode === 2103
            ? 'Agent is not in same branch'
            : 'agent code is error';

        const mesgTc =
          resp.data?.errorCode === 2101
            ? '代理人編號不存在'
            : resp.data?.errorCode === 2102
            ? '無效代理人'
            : resp.data?.errorCode === 2103
            ? '代理人區域不相配'
            : '編碼異常';
        this.fullDataSource[page - 1].data[index].checkResult = mesg;
        this.fullDataSource[page - 1].data[index].checkResultTc = mesgTc;
      }
      let submistState = false;
      this.fullDataSource.forEach(element => {
        element.data.forEach(data => {
          if (data.flag) {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            submistState = true;
          }
        });
      });
      this.clearDisabledState = false;
    });
  }

  agentTxtfieldConfig: CdsTextfieldConfig = {
    placeholder: '',
    type: 'text',
  };

  applyToAllTxtfieldConfig: CdsTextfieldConfig = {
    placeholder: '',
    type: 'text',
    label: this.language === 'zh' ? '把所選雇主委托給：' : 'Delegate agent to selected employers:',
  };

  delgtPageChange(num: number): void {
    this.page = num;
    console.log('isshow', this.showTable);
    if (this.fullDataSource[num - 1] !== undefined && this.fullDataSource[num - 1].page == num && this.fullDataSource[num - 1].data.length !== 0) {
      this.ckSelected(num);
      this.markPage = num;
      this.searchValue = num + '';
      this.dataSource = this.fullDataSource[num - 1].data;
      return;
    }
    this.isShowProgress = true;
    this.employeeDataService.getPageDataedit(num, this.employerId);
    // this.employerDataSubscription?.unsubscribe();
    // put datasource(by Subject next and subscribe method) in pagination pageChange method
    // this.employerDataSubscription = this.dataSubcription.subscribe(response => {
    //   // if (dataSub !== undefined) {
    //   //   dataSub.unsubscribe();
    //   // }

    //   if (response.data !== undefined) {
    //     this.dataSource = response.data?.content;
    //     this.delgtEmployeeTotalCount = response.data?.totalElements;
    //     this.pageCount = Math.ceil(this.delgtEmployeeTotalCount / 10);
    //     this.showTable = response.data?.totalElements === 0 ? false : true;
    //     this.markPage = num;
    //     this.searchValue = num + '';
    //     this.initFullData(this.pageCount);
    //     this.fullDataSource.splice(num - 1, 1, {
    //       data: this.dataSource,
    //       page: num,
    //     });
    //     this.ckSelected(num);
    //   }
    //   this.isShowProgress = false;
    //   console.log('fullData', this.fullDataSource);
    // });
  }
  initFullData(num: number) {
    if (this.pageInitState) {
      for (let i = 0; i <= num; i++) {
        this.fullDataSource.splice(i - 1, 1, { data: [], page: i });
      }
    }
    this.pageInitState = false;
  }

  valueChange(agentValue: string) {
    const value = agentValue.trim();
    this.dlgtNameValue = value;
    if (value.length === 0) {
      this.resultState = '';
      this.checkState = true;
    }

    this.refreshStep();
  }

  saveClick() {
    if (this.isDisableBatchApplyAgentCode) {
      return;
    }

    const value = this.dlgtNameValue;
    if ((value.length > 0 && value.length < 6) || value.length > 6) {
      this.resultState = '2119';
      return;
    }
    if (value.length === 0) {
      this.resultState = '';
      return;
    }
    // this.ckRequest.identityId = this.identityId;
    this.ckRequest.agentCode = value;
    this.employeeDataService.checkData(this.ckRequest).subscribe(resp => {
      this.ckResultData = resp;
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      this.resultState = this.ckResultData.result!.toString();
      const flag = resp.result == 0 ? false : true;
      this.checkState = flag;
      this.messages = resp.message ? resp.message : '';
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      if (resp.data?.agentCode !== undefined) {
        this.dlgtNameValue = agentCodeAddPrefix0(resp.data.agentCode);
      }
      if (flag) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        this.resultState = String(resp.data!.errorCode);
        // if (!this.submitDisabledState) {
        //   this.stepper.setFinishStep(4);
        // }
        return;
      } else {
        this.stepper.removeLightsOutStep(3);
      }

      this.fullDataSource.forEach(element => {
        element.data.forEach(data => {
          if (data.isSelected) {
            data.checkdata = this.ckResultData.data?.agentCode ? agentCodeAddPrefix0(this.ckResultData.data?.agentCode) : undefined;
            data.flag = false;
            console.log(this.ckResultData);
            if (data.delegateAgentInfo === undefined) {
              data.delegateAgentInfo = {};
            }
            data.delegateAgentInfo.agentCode = this.ckResultData.data?.agentCode ? agentCodeAddPrefix0(this.ckResultData.data?.agentCode) : '';
            data.delegateAgentInfo.name = this.ckResultData.data?.name || '';
            data.delegateAgentInfo.nameTC = this.ckResultData.data?.chineseName || '';
            data.isSelected = false;
          }
        });
      });
      this.dlgtNameValue = '';
      if (!this.checkState) {
        this.clearDisabledState = false;
      }

      this.refreshStep();
      this.isSelectedNum = 0;
    });
  }

  dlgtClearData() {
    const popupRef: MatDialogRef<UserExitEditmodePopComponent> = this.cdsPopup.open(UserExitEditmodePopComponent, {});
    popupRef.afterClosed().subscribe(data => {
      if (data.agree) {
        this.ClearData();
      }
    });
  }

  ClearData() {
    this.fullDataSource.forEach(element => {
      element.data.forEach(data => {
        data.checkdata = '';
        data.flag = undefined;
        data.isSelected = false;
      });
    });
    this.dlgtNameValue = '';
    this.allChecked = false;
    this.resultState = '';
    this.dlgtNameValue = '';
    this.clearDisabledState = true;
    this.checkState = true;
    this.boxInputNum = 0;
    this.elementsToSubmit.length = 0;
    this.isSelectedNum = 0;
    setTimeout(() => {
      if (this.stepper) {
        this.stepper.removeLightsOutStep(3);
        this.refreshStep();
      }
    }, 0);
    this.alertItems.forEach(element => {
      element.ifShow = false;
    });
  }

  erDelegate() {
    this.branchList.length = 0;
    this.elementsToSubmit.length = 0;
    this.checkAgentList.length = 0;
    let checkSumitState = false;
    this.fullDataSource.forEach(element => {
      element.data.forEach(data => {
        if (
          // data.isSelected &&  remove isSelected  11/11
          data.checkdata === '' &&
          data.checkdata === undefined
        ) {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          checkSumitState = true;
        }
        if (data.flag) {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          checkSumitState = true;
        }
        if (data.checkdata === '' || data.checkdata === undefined) {
          return;
        }
        const pushState = this.elementsToSubmit.some(el => el.memberId === data.memberId);
        if (!pushState) {
          this.elementsToSubmit.push(data);
        }
      });
    });
    if (this.elementsToSubmit.length === 0 || checkSumitState) {
      return;
    }
    this.subDataState = true;
    this.checkResultList.length = 0;
    this.elementsToSubmit.forEach(element => {
      const ckData = new EmployeeCheckRequest();
      ckData.memberId = element.memberId;
      ckData.agentCode = element.checkdata;
      ckData.employerId = this.employerId;
      this.checkAgentList.push(ckData);
    });
    this.employeeDataService.checkAgentList(this.checkAgentList).subscribe(resp => {
      if (resp.result !== 0) {
        if (resp.data && resp.data.length > 0) {
          resp.data.forEach(d => {
            if (d.agent?.agentCode) {
              d.agent.agentCode = agentCodeAddPrefix0(d.agent?.agentCode);
            }

            if (d.existAgents) {
              d.existAgents.forEach(a => {
                if (a.agentCode) {
                  a.agentCode = agentCodeAddPrefix0(a.agentCode);
                }
              });
            }
          });
        }

        this.subDataState = false;
        if (resp.data == null) {
          resp.data = [];
        }

        if (resp.data.some(item => item.errorCode === 2104)) {
          this.checkResultList.push(...resp.data);
        }

        resp.data.forEach(element => {
          if (element.errorCode === 2103) {
            this.branchList.push(element.agent ? element.agent : {});
          }
        });
        if (this.checkResultList.length > 0) {
          this.addAlartErItmes(this.checkResultList);
          return;
        }
        const popupRef: MatDialogRef<AgentBranchPopComponent> = this.cdsPopup.open(AgentBranchPopComponent, {
          data: { list: this.branchList, language: this.language },
          size: 'md',
        });
        popupRef.afterClosed().subscribe(data => {
          if (data.agree) {
            this.employeeDataService.submitErDelegationData(this.elementsToSubmit);
            this.splitElements(this.elementsToSubmit);
            this.pageShowState = 3;
          }
        });
        return;
      }

      if (this.subDataState === true) {
        // this.addElements(this.dataSource, this.elementsToSubmit);
        this.employeeDataService.submitErDelegationData(this.elementsToSubmit);
        this.splitElements(this.elementsToSubmit);
        this.pageShowState = 3;
        pageToTop();
      } else {
        this.checkResultList = resp.data ? resp.data : [];
        this.addAlartErItmes(this.checkResultList);
      }
    });
  }

  splitElements(employeeResponseData: EmployeeResponseData[]) {
    const len = this.elementsToSubmit.length;
    const splitPages = Math.ceil(len / 10);
    const splitPageSize = 10;
    localStorage.setItem('delegateTotal_ee', len + '');
    localStorage.setItem('delegatePages_ee', splitPages + '');
    for (let i = 1; i <= splitPages; i++) {
      const strPerPage = JSON.stringify(this.arrayPage(employeeResponseData, i, splitPageSize));
      localStorage.setItem('page_ee' + i, strPerPage);
    }
  }
  arrayPage(employeeResponseData: EmployeeResponseData[], page: number, pageSize: number): EmployeeResponseData[] {
    if (page <= 0) {
      return [];
    }
    const minIdx = pageSize * (page - 1);
    const maxIdx = pageSize * page;
    return employeeResponseData.slice(minIdx, maxIdx);
  }

  addAlartErItmes(data: CheckResponseData[]) {
    this.alertItems.length = 0;
    if (data.length > 0) {
      let resultMsg = '';
      let resultMsgTc = '';
      if (data.some(item => item.errorCode === 2104)) {
        let agentEn = '';
        let agentTc = '';
        let limitAgent = 0;
        data.forEach(d => {
          if (d.errorCode === 2104) {
            limitAgent = d.limitAgent || 0;
            d.existAgents?.forEach(element => {
              const agentName = element.name || element.chineseName;
              agentEn = agentEn + agentCodeAddPrefix0(element.agentCode || '') + ' ' + agentName + '、';
              const agentNameTc = element.chineseName || element.name;
              agentTc = agentTc + agentCodeAddPrefix0(element.agentCode || '') + ' ' + agentNameTc + '、';
            });
          }
        });
        const tempAgentEn = agentEn?.split('');
        tempAgentEn?.pop();
        agentEn = tempAgentEn?.join('');
        const tempAgentTc = agentTc?.split('');
        tempAgentTc?.pop();
        agentTc = tempAgentTc?.join('');
        // eslint-disable-next-line @typescript-eslint/no-unused-vars

        if (agentEn) {
          resultMsg =
            'Each employer can maximum delegate 4 agents.' + this.employerName + ' has been delegated to ' + limitAgent + ' agents including:' + agentEn;
        } else {
          resultMsg = 'Each employer can maximum delegate 4 agents.';
        }

        const name = this.employerNameTC ? this.employerNameTC : this.employerName;
        // eslint-disable-next-line @typescript-eslint/no-unused-vars

        if (agentTc) {
          resultMsgTc = '每個僱主最多可委託4名代理人。' + name + ' 已被委況託 ' + limitAgent + ' 位代理人，包括:' + agentTc;
        } else {
          resultMsgTc = '每個僱主最多可委託4名代理人。';
        }

        this.alertItems.push({
          index: 0,
          ifShow: true,
          message: resultMsg,
          messageTc: resultMsgTc,
        });
      }

      // if (data.some(item => item.errorCode === 2104)) {
      //   let agentEn = '';
      //   let agentTc = '';
      //   data.forEach(element => {
      //     // eslint-disable-next-line @typescript-eslint/no-unused-vars
      //     agentEn = agentEn + agentCodeAddPrefix0(element.agent?.agentCode || '') + element.agent?.name + '、';
      //     const agentName = element.agent?.chineseName === undefined ? '' : element.agent?.chineseName;
      //     agentTc = agentTc + agentCodeAddPrefix0(element.agent?.agentCode || '') + agentName + '、';
      //   });
      //   const tempAgentEn = agentEn?.split('');
      //   tempAgentEn?.pop();
      //   agentEn = tempAgentEn?.join('');
      //   const tempAgentTc = agentTc?.split('');
      //   tempAgentTc?.pop();
      //   agentTc = tempAgentTc?.join('');

      //   if (agentEn) {
      //     resultMsg = 'Each employer can maximum delegate 4 agents.' + this.employerName + ' has been delegated to ' + 4 + ' agents including:' + agentEn;
      //   } else {
      //     resultMsg = 'Each employer can maximum delegate 4 agents.';
      //   }
      //   const name = this.employerNameTC ? this.employerNameTC : this.employerName;
      //   // eslint-disable-next-line @typescript-eslint/no-unused-vars

      //   if (agentTc) {
      //     resultMsgTc = '每個僱主最多可委託4名代理人。' + name + ' 已被委況託 ' + 4 + ' 位代理人，包括:' + agentTc;
      //   } else {
      //     resultMsgTc = '每個僱主最多可委託4名代理人。';
      //   }
      // }
    }
  }

  addAlertItems(data: CheckResponseData[]) {
    this.alertItems.length = 0;
    for (let i = 0; i < data.length; i++) {
      let resultMsg = '';
      if (data[i].errorCode === 2104) {
        let agentEn = '';
        let agentTc = '';
        data[i].existAgents?.forEach(element => {
          agentEn = agentEn + element.agentCode + element.name + '、';
          const agentName = element.chineseName === undefined ? '' : element.chineseName;
          agentTc = agentTc + element.agentCode + agentName + '、';
        });
        const tempAgentEn = agentEn?.split('');
        tempAgentEn?.pop();
        agentEn = tempAgentEn?.join('');
        const tempAgentTc = agentTc?.split('');
        tempAgentTc?.pop();
        agentTc = tempAgentTc?.join('');

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        if (this.language === 'EN') {
          if (agentEn) {
            resultMsg =
              'Each employer can maximum delegate 4 agents. ' +
              data[i].employeeInfo?.employeeName +
              ' has been delegated to ' +
              data[i].limitAgent +
              ' agents including:' +
              agentEn;
          } else {
            resultMsg = 'Each employer can maximum delegate 4 agents.	';
          }
        } else {
          if (agentTc) {
            resultMsg =
              '每個僱主最多可委託4名代理人。' + data[i].employeeInfo?.employeeName + ' 已被委況託 ' + data[i].limitAgent + ' 位代理人，包括:' + agentTc;
          } else {
            resultMsg = '每個僱主最多可委託4名代理人。';
          }
        }
      }
      this.alertItems.push({
        index: i,
        ifShow: true,
        message: resultMsg,
        messageTc: resultMsg,
      });
    }
  }

  erBackToEdit() {
    this.pageShowState = 2;
    this.resultState = '';
    this.clearSubmitData();
    setTimeout(() => {
      if (this.stepperTarget === 'ipad') {
        this.stepper.setStepList(this.stepList);
      }

      this.refreshStep();
    }, 0);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  SubmitSearchByInputPageNo($event: any, pageNo: string) {
    $event.preventDefault();
    console.info($event, pageNo);
    console.info(parseInt(pageNo));
    if (isNaN(parseInt(pageNo))) {
      this.subSearchValue = '1';
    }
    this.submitPageChange(Number(pageNo));
  }

  submitPageChange(num: number): void {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const pageInfoByNum: string = localStorage.getItem('page_ee' + num)!;
    this.subDataSource = JSON.parse(pageInfoByNum);
    const totalItems: number = parseInt(
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      localStorage.getItem('delegateTotal_ee')!
    );
    this.subPage = num;
    this.subSearchValue = num + '';
    this.subPageCount = Math.ceil(totalItems / 10);
    this.subTotalCount = totalItems;
    this.isSubShowProgress = false;
  }

  clearSubmitData(): void {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const pages = parseInt(localStorage.getItem('delegatePages_ee') || '0');

    for (let i = 1; i <= pages; i++) {
      localStorage.removeItem('page_ee' + i);
    }
    localStorage.removeItem('delegatePages_ee');
    localStorage.removeItem('delegateTotal_ee');
  }

  submitData() {
    const popupRef: MatDialogRef<UserConfirmPopComponent> = this.cdsPopup.open(UserConfirmPopComponent, {
      data: {},
    });
    popupRef.afterClosed().subscribe(data => {
      if (data.agree) {
        this.submitDataList.length = 0;
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const pages = parseInt(localStorage.getItem('delegatePages_ee')!);
        for (let i = 1; i <= pages; i++) {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          const pageInfoByNum: string = localStorage.getItem('page_ee' + i)!;
          this.subDataSource = JSON.parse(pageInfoByNum);
          this.subDataSource.forEach(element => {
            const response = new EmployeeSaveModel();
            response.agentCode = element.checkdata;
            response.memberId = element.memberId;
            // response.identityId = this.identityId;
            response.employerId = this.employerId;
            this.submitDataList.push(response);
          });
        }
        console.log('submistData', this.submitDataList);
        this.notifyService.screenLoadingSubject.next(true);
        this.employeeDataService.saveData(this.submitDataList).subscribe(
          resp => {
            if (resp.result !== 0) {
              this.pageShowState = 2;
              this.checkResultList = resp.data ? resp.data : [];
              this.isSubmitState = true;
              this.addAlartErItmes(this.checkResultList);
            } else {
              const popupRefSubmit: MatDialogRef<UserSuccessSubmittedComponent> = this.cdsPopup.open(UserSuccessSubmittedComponent, {});
              popupRefSubmit.afterClosed().subscribe(dataConfirm => {
                if (dataConfirm.agree) {
                  location.reload();
                }
              });
            }
            this.notifyService.screenLoadingSubject.next(false);
          },
          error => {
            alert(error);
            this.notifyService.screenLoadingSubject.next(false);
          }
        );
      }
    });
  }

  jump() {
    window.open(
      this.cdsLangService.currentLang === 'en'
        ? 'https://ebmeter.com/minisite/download/campaign/delegation_golden_rule_en.pdf'
        : 'https://ebmeter.com/minisite/download/campaign/delegation_golden_rule.pdf'
    );
  }

  computedIsHalfSelected() {
    let currentPageData = this.fullDataSource[this.markPage - 1].data;
    if (currentPageData === undefined) {
      currentPageData = [];
    }
    const isSelectedAll = currentPageData.every(item => item.isSelected);
    if (isSelectedAll) {
      return false;
    }

    const isSelectedSome = currentPageData.some(item => item.isSelected);
    if (isSelectedSome) {
      return true;
    }

    return false;
  }

  goEEDelegationChange() {
    this.isShowButtonList = false;
    const employerNameParam = this.paramTmp['en'] ? this.paramTmp['en'] : '';
    const employerNameParamTC = this.paramTmp['zh'] ? this.paramTmp['zh'] : '';
    this.router.navigate(['/agent/employee-delegation-change', this.employerId], { queryParams: { en: employerNameParam, zh: employerNameParamTC } });
  }

  goEEDelegationChangeRequestLog() {
    this.isShowButtonList = false;
    this.router.navigate(['/agent/employer-history']);
  }

  private onResetTable() {
    this.resetTable = true;
    setTimeout(() => {
      this.resetTable = false;
    }, 0);
  }

  private refreshStep() {
    let stepNum = 1;

    if (this.fullDataSource.some(item => item.data.some(employeeData => employeeData.isSelected))) {
      stepNum = 2;
    }

    if (stepNum === 2 && this.dlgtNameValue.length > 0) {
      stepNum = 3;
    }

    if (this.fullDataSource.some(item => item.data.some(employeeData => employeeData.checkdata && employeeData.checkdata.length === 6))) {
      stepNum = 4;
    }

    this.stepper.setFinishStep(stepNum);
  }

  get isApplyDisable() {
    return !this.fullDataSource.some(item => item.data.some(agentEmployerData => agentEmployerData.isSelected));
  }

  get isDisableBatchApplyAgentCode() {
    return !(this.fullDataSource.some(item => item.data.some(agentEmployerData => agentEmployerData.isSelected)) && this.dlgtNameValue.length > 0);
  }

  get submitDisabledState() {
    return !this.fullDataSource.some(item => item.data.some(employeeData => employeeData.checkdata && employeeData.checkdata.length === 6));
  }

  get checkIsHaveScroll() {
    if (this.tableBox) {
      return this.tableBox.nativeElement.scrollWidth > this.tableBox.nativeElement.clientWidth;
    }
    return false;
  }
  open = true;
  onToggel() {}
}
