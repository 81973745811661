<div class="row l-pt-8">
  <div class="col-xs-12 cds-color-green">
    <span class="cds-h5-demibold">{{ 'er.charge-information' | lang }}</span>
  </div>
  <div class="col-xs-12 p-line"></div>
</div>

<div *ngIf="_loading" class="col-xs-12 center-xs loading-box">
  <app-loading [overlay]="true"></app-loading>
</div>

<div class="row l-pt-6 l-pb-6">
  <div class="col-xs-12">
    <div class="color-grey">
      <span class="cds-body1">{{ 'er.choose-charge-type' | lang }}</span>
    </div>
    <div class="row col-xs-12 l-plr-0">
      <div
        class="page-size l-mt-2 l-mb-2 l-mr-4 pointer"
        *ngFor="let item of _typeList; let i = index"
        [ngClass]="_typeSelected === i ? 'size-selected' : ''"
        (click)="typeSelecte(i)">
        <span class="cds-body2">{{ item.label }}</span>
      </div>
    </div>
  </div>
</div>

<app-no-result-found *ngIf="_deteList.length === 0"></app-no-result-found>

<div class="row l-pt-2" *ngIf="_deteList.length > 0">
  <div class="col-xs-12">
    <div (click)="_detailTrigger()" class="drop-down pointer">
      <div class="row l-plr-0 middle-xs">
        <div class="col-xs l-plr-0">
          <div class="l-pb-2">
            <span class="cds-detail2">{{ _typeList[_typeSelected].dropName }}</span>
          </div>
          <div>
            <span class="cds-h5-demibold">{{ formatDate(_deteList[_dateSelected]) }}</span>
          </div>
        </div>
        <div><cds-icon icon="action:button_down"></cds-icon></div>
      </div>
    </div>
    <div *ngIf="_detailsShow" class="detail-container l-mt-2">
      <ng-container *ngFor="let item of _deteList; let i = index">
        <div (click)="select(i)" class="drop-down-item pointer middle-xs l-mr-5">
          <span class="cds-body1">{{ formatDate(item) }}</span>
        </div>
      </ng-container>
    </div>
  </div>
</div>

<ng-container *ngFor="let chargeInfo of dataList">
  <div class="row l-pt-7">
    <app-row-item label="er.flat-rate-date" [data]="formatDate(chargeInfo.flatRateDate)" class="col-xs-6 col-sm-3 col-md-3 col-lg-3"> </app-row-item>
    <app-row-item label="er.charge-application-type" [data]="formatType(chargeInfo.chargeApplicationType)" class="col-xs-6 col-sm-3 col-md-3 col-lg-3">
    </app-row-item>
  </div>

  <app-no-result-found *ngIf="!chargeInfo.chargeList && !_loading"></app-no-result-found>

  <div class="l-pt-7 l-pb-4 l-pl-2 l-pr-2">
    <app-cd-accordion *ngIf="chargeInfo.chargeList && chargeInfo.chargeList.length">
      <app-cd-accordion-panel
        *ngFor="let item of chargeInfo.chargeList; index as itemIndex"
        [title]="_typeList[_typeSelected].codeName"
        titleClass="accordion-title"
        [miniTitle]="item.chargeCode"
        miniTitleClass="accordion-mini-title">
        <ng-container *ngIf="_typeSelected === 0 || _typeSelected === 1">
          <div class="row col-xs-12 l-plr-0">
            <app-row-item label="er.deduct-type" [data]="_debuctType(item.deductType)" class="col-xs-6 col-sm-3 col-md-3 col-lg-3"> </app-row-item>
            <app-row-item
              label="er.exp-charge-last-policy-year"
              [data]="(item.expChargeLastPolicyYear || 0) * 100 | number : '1.3-3'"
              class="col-xs-6 col-sm-3 col-md-3 col-lg-3">
            </app-row-item>
            <app-row-item
              label="er.exp-charge-current-policy-year"
              [data]="(item.expChargeCurrentPolicyYear || 0) * 100 | number : '1.3-3'"
              class="col-xs-6 col-sm-3 col-md-3 col-lg-3">
            </app-row-item>
            <app-row-item
              label="er.contribution-last-anniversary"
              [data]="item.contributionLastAnniversary | number : '1.2-2'"
              class="col-xs-6 col-sm-3 col-md-3 col-lg-3">
            </app-row-item>
            <app-row-item label="er.cont-year-to-date" [data]="item.contYearToDate | number : '1.2-2'" class="col-xs-6 col-sm-3 col-md-3 col-lg-3">
            </app-row-item>
            <app-row-item label="er.invest-acct-fee" [data]="item.investAcctFee | number : '1.2-2'" class="col-xs-6 col-sm-3 col-md-3 col-lg-3"> </app-row-item>
            <app-row-item
              label="er.expenses-charge-waiver-amount"
              [data]="item.expensesChargeWaiverAmount | number"
              class="col-xs-6 col-sm-3 col-md-3 col-lg-3">
            </app-row-item>
            <app-row-item label="er.breakpoint-type" [data]="_brakPointType(item.breakpointType)" class="col-xs-6 col-sm-3 col-md-3 col-lg-3"> </app-row-item>
          </div>

          <div class="row col-xs-12 l-pt-8 l-pb-2">
            <div class="col-xs-6 l-plr-0">
              <table>
                <tr>
                  <th></th>
                  <th class="border-right-non">Breakpoint</th>
                  <th class="border-left-non">%</th>
                </tr>
                <ng-container *ngIf="item.breakpointList">
                  <tr *ngFor="let row of item.breakpointList; let no = index">
                    <td class="width-600">
                      <ng-container *ngIf="item.breakpointList.length > 1">
                        {{ no === 0 ? '1st' : no === item.breakpointList.length - 1 ? 'Over' : 'Next' }}
                      </ng-container>
                      <ng-container *ngIf="item.breakpointList.length === 1"> Over </ng-container>
                    </td>
                    <td class="border-right-non">{{ row.breakpoint | number }}</td>
                    <td class="border-left-non">{{ (row.percentage || 0) * 100 | number : '1.3-3' }}</td>
                  </tr>
                </ng-container>
              </table>
              <div class="l-pt-4 l-pb-4" *ngIf="!item.breakpointList">
                <app-no-result-found></app-no-result-found>
              </div>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="_typeSelected === 2">
          <div class="row col-xs-12 l-plr-0">
            <app-row-item label="er.deduct-type" [data]="_debuctType(item.deductType)" class="col-xs-6 col-sm-3 col-md-3 col-lg-3"> </app-row-item>
            <app-row-item label="er.per-life-charge" [data]="item.perLifeCharge | number : '1.2-2'" class="col-xs-6 col-sm-3 col-md-3 col-lg-3"> </app-row-item>
            <app-row-item label="er.min" [data]="item.min | number : '1.2-2'" class="col-xs-6 col-sm-3 col-md-3 col-lg-3"> </app-row-item>
            <app-row-item label="er.max" [data]="item.max | number : '1.2-2'" class="col-xs-6 col-sm-3 col-md-3 col-lg-3"> </app-row-item>
            <app-row-item
              label="er.life-count-charge-waiver-amount"
              [data]="item.lifeCountChargeWaiverAmount | number : '1.2-2'"
              class="col-xs-6 col-sm-3 col-md-3 col-lg-3">
            </app-row-item>
            <app-row-item label="er.policy-fee-type" [data]="_policyFreeType(item.policyFeeType)" class="col-xs-6 col-sm-3 col-md-3 col-lg-3"> </app-row-item>
            <app-row-item label="er.policy-fee" [data]="item.policyFee | number : '1.2-2'" class="col-xs-6 col-sm-3 col-md-3 col-lg-3"> </app-row-item>
            <app-row-item label="er.invest-acct-fee" [data]="item.investAcctFee | number : '1.2-2'" class="col-xs-6 col-sm-3 col-md-3 col-lg-3"> </app-row-item>
          </div>
        </ng-container>
      </app-cd-accordion-panel>
    </app-cd-accordion>
  </div>
</ng-container>
