<div *ngIf="!group" class="cd-dropdown">
  <div>
    <span class="cds-body2 label">{{ label | lang }}</span>
  </div>
  <div (click)="blur()" class="selected-val-box" [ngClass]="[disabled ? 'disable-selected' : '']">
    <mat-form-field style="width: 100%" appearance="fill">
      <input
        #myInput
        [formControl]="valueControl"
        type="text"
        [ngClass]="['selected-input', showVaule.length > 0 ? 'opacity' : '']"
        [placeholder]="placeholder | lang"
        matInput
        [matAutocomplete]="auto"
        #trigger="matAutocompleteTrigger"
        appCdAutocompletePosition="trigger" />
      <mat-autocomplete #auto="matAutocomplete" (closed)="blur()">
        <mat-option *ngFor="let option of toppingList" [value]="option.value">
          {{ option.label }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <span class="selected-val">
      {{ showVaule }}
    </span>
    <div (click)="myInput.click()" class="mask"></div>
    <cds-icon [ngClass]="['button-down', open ? 'up' : '', disabled ? 'disabled-button-down' : '']" icon="action:button_down"></cds-icon>
  </div>
  <div [ngClass]="['line', open ? 'line-light' : '', disabled ? 'disabled-line' : '']"></div>
</div>

<div *ngIf="group" class="cd-dropdown">
  <div>
    <span class="cds-body2 label">{{ label | lang }}</span>
  </div>
  <div [formGroup]="group" (click)="blur()" class="selected-val-box" [ngClass]="[disabled ? 'disable-selected' : '']">
    <mat-form-field style="width: 100%" appearance="fill">
      <input
        #myInput
        [formControlName]="controlName"
        type="text"
        [ngClass]="['selected-input', showVaule.length > 0 ? 'opacity' : '']"
        [placeholder]="placeholder | lang"
        matInput
        [matAutocomplete]="auto"
        #trigger="matAutocompleteTrigger"
        appCdAutocompletePosition="trigger" />
      <mat-autocomplete #auto="matAutocomplete" (closed)="blur()">
        <mat-option *ngFor="let option of toppingList" [value]="option.value">
          {{ option.label }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <span class="selected-val">
      {{ showVaule }}
    </span>
    <div (click)="myInput.click()" class="mask"></div>
    <cds-icon [ngClass]="['button-down', open ? 'up' : '', disabled ? 'disabled-button-down' : '']" icon="action:button_down"></cds-icon>
  </div>
  <div [ngClass]="['line', open ? 'line-light' : '', disabled ? 'disabled-line' : '']"></div>
</div>
