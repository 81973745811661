import { CdsOption } from '@cds/ng-core/configuration';

export const AGENT_CONTRACT_STATUS_OPTIONS: CdsOption[] = [
  { label: 'ACTIVE', value: '01' },
  { label: 'TERMINATE', value: '02' },
  { label: 'TRANSFER', value: '03' },
  {
    label: 'RETIRED',
    value: '04',
  },
  {
    label: 'DECEASED',
    value: '05',
  },
  {
    label: 'DISABLE',
    value: '06',
  },
  {
    label: 'LOSS',
    value: '07',
  },
  {
    label: 'DUMMY',
    value: '08',
  },
];

export const MPF_LICENSE_STATUS_OPTIONS: CdsOption[] = [
  { label: 'Registered', value: 'Registered' },
  { label: 'Transition', value: 'Transition' },
  { label: 'Pass the exam', value: 'Pass the exam' },
  {
    label: 'NA',
    value: 'NA',
  },
];
