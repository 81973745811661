/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { BasicResponse } from 'src/app/core/models/response/response';
import { map, timeout } from 'rxjs/operators';
import { CommissionPoolStatus } from 'src/app/core/models/enum';

@Injectable({
  providedIn: 'root',
})
export class CommissionAgentService {
  constructor(private http: HttpClient) {}

  addAgent(isAdd: boolean, params: any): Observable<BasicResponse<any>> {
    if (isAdd) {
      return this.http.post<BasicResponse<any>>(`${environment.apiPrefix}${environment.commissionServiceExt}/commission-pool-agent/add`, params);
    } else {
      return this.http.post<BasicResponse<any>>(`${environment.apiPrefix}${environment.commissionServiceExt}/commission-pool-agent/update`, params);
    }
  }

  getRegularList(page: number, commissionPoolId: string): Observable<BasicResponse<any>> {
    const params = {
      page: page,
      size: 9999,
      commissionPoolId: commissionPoolId,
      sort: 'effectiveDate',
      order: 'asc',
      all: true,
    };
    return this.http.get<BasicResponse<any>>(`${environment.apiPrefix}${environment.commissionServiceExt}/commission-pool-agent/list-regular`, {
      params: params,
    });
  }

  getRegularItems(params: any): Observable<BasicResponse<any>> {
    return this.http.get<BasicResponse<any>>(`${environment.apiPrefix}${environment.commissionServiceExt}/commission-pool-agent/list-regular-items`, {
      params: params,
    });
  }

  getAgentRecordCurrentInfo(commissionPoolId: string): Observable<any> {
    return this.http.get(`${environment.apiPrefix}${environment.commissionServiceExt}/commission-pool-agent/currentInfo/${commissionPoolId}`, {
      observe: 'response',
      responseType: 'text',
    });
  }

  getAgentByCode(agentCode: string): Observable<BasicResponse<any>> {
    return this.http.get<BasicResponse<any>>(`${environment.apiPrefix}${environment.commissionServiceExt}/commission-pool-agent/agent/${agentCode}`).pipe(
      timeout(10000),
      map(evt => {
        return evt;
      })
    );
  }

  delete(id: string): Observable<BasicResponse<any>> {
    return this.http.get<BasicResponse<any>>(`${environment.apiPrefix}${environment.commissionServiceExt}/commission-pool-agent/delete/${id}`);
  }

  listRegular(commissionPoolId: string, p?: number, records?: any[]) {
    const agentRecord = { id: '', millis: 0, createBy: { name: '' } };
    let recordList: any[] = records ? records : [];
    const page: number = p ? p : 0;
    let totalPages = 0;
    return new Promise(resolve => {
      this.getRegularList(page, commissionPoolId).subscribe(resp => {
        if (resp && resp.result == 0 && resp.data.content) {
          totalPages = resp.data.totalPages;
          recordList = recordList.concat(resp.data.content);
          recordList.forEach(item => {
            if (item.status == CommissionPoolStatus.CURRENT && item.active) {
              agentRecord.id = item.id;
              agentRecord.millis = item.millis;
              agentRecord.createBy = item.createBy;
            }
            if (!item.active) {
              item.status = CommissionPoolStatus.DELETED;
              if (item.lastUpdateBy) {
                item.lastUpdateName = item.lastUpdateBy.name;
              }
            }
          });
        }
        resolve({
          totalPages: totalPages,
          recordList: recordList,
          agentRecord: agentRecord,
        });
      });
    });
  }
}
