import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Category, EventTypeCheck } from 'src/app/views/system/calendar/calendar.model';

@Component({
  selector: 'app-event-check',
  templateUrl: './event-check.component.html',
  styleUrls: ['./event-check.component.scss'],
})
export class EventCheckComponent implements OnInit {
  @Input() data!: Array<Category>;
  @Output() eventChange = new EventEmitter();
  constructor() {}

  ngOnInit(): void {}

  change(checked: boolean, list: EventTypeCheck): void {
    list.checked = checked;
    this.eventChange.emit(list);
  }
}
