/* eslint-disable @typescript-eslint/no-explicit-any */
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { AgentDetailInfo } from './../service/model/agent-info-response.model';
import { LanguageChangeService } from 'src/app/shared/language-change.service';
import { CdsLangService } from '@cds/ng-core/lang';
import { Component, OnInit, ViewChild, ViewChildren, QueryList, AfterViewInit } from '@angular/core';
import { AbstractControl, FormControl, ValidationErrors, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CdsPopupService } from '@cds/ng-web-components/popup';
import { CdRadioComponent, CdRadioConfig } from 'src/app/shared/cd-radio/cd-radio.component';
import { SubmitSuccessComponent } from './submit-success/submit-success.component';
import { CaseSummaryDS, PreferredLanguageType } from '../case-summary/case-summary.model';
import { CdHttpServeService } from 'src/app/shared/cd-http-serve/cd-http-serve.service';
import { CdsAlertService } from '@cds/ng-web-components/alert';
import { agentCodeRemovePrefix0, pageToTop } from 'src/app/utils/utils';
import { HideTextType } from 'src/app/shared/hide-text/hide-text.pipe';
import { AdviseEmpfrefComponent } from '../case-summary/advise-empfref/advise-empfref.component';
import { IffService } from '../service/iff.service';
import { TempCacheService } from 'src/app/shared/temp-cache/temp-cache.service';
import { AgentChannel } from 'src/app/core/models/enum/agent-channel.enum';
import { DeliveryChannelType } from 'src/app/core/models/enum/delivery-channel-type.enum';
import { MemberContactData } from './send-iff-in-batch-summary.model';
import { RegulatedActivitiesComponent } from '../regulated-activities/regulated-activities.component';
import { NotifyService } from 'src/app/core/services/notify.service';
import { AgentChannelService } from 'src/app/core/services/agent-channel.service';

@Component({
  selector: 'app-send-iff-in-batch-summary',
  templateUrl: './send-iff-in-batch-summary.component.html',
  styleUrls: ['./send-iff-in-batch-summary.component.scss'],
})
export class SendIffInBatchSummaryComponent implements OnInit, AfterViewInit {
  @ViewChild('intermediaryContact') intermediaryContact?: CdRadioComponent;
  @ViewChildren('itemDeliveryChannel')
  deliveryChannelList?: QueryList<CdRadioComponent>;
  @ViewChildren('itemPreferredLanguage')
  preferredLanguageList?: QueryList<CdRadioComponent>;
  @ViewChild('splitAgentPreferredLanguage') splitAgentPreferredLanguage?: CdRadioComponent;
  PreferredLanguageType = PreferredLanguageType;
  @ViewChildren('regulatedActivities')
  regulatedActivitiesList?: QueryList<RegulatedActivitiesComponent>;

  dataList: CaseSummaryDS[] = [];
  frControl = new FormControl(undefined);
  regulatedControl = new FormControl(undefined);
  currentStep = 1;
  currentStep2FrControl = new FormControl(false);
  intermediaryContactMethodConfig: CdRadioConfig = {
    options: [
      {
        label: this.cdsLangService.translate('sendIffInBatchSummary.contactMethodEmail'),
        value: DeliveryChannelType.Email,
        disable: true,
      },
      {
        label: this.cdsLangService.translate('sendIffInBatchSummary.contactMethodPhone'),
        value: DeliveryChannelType.SMS,
        disable: true,
      },
    ],
  };
  intermediaryContactMethodValue?: DeliveryChannelType;
  splitAgent = '';
  secondAgent?: AgentDetailInfo;
  HideTextType = HideTextType;
  DeliveryChannelType = DeliveryChannelType;
  secondReqControl = new FormControl(false);
  primaryReqControl = new FormControl(false);
  multipleFrControl = new FormControl(false);
  private servicingAgentId = '';
  servicingAgentCode?: string;

  private addDelegationValidate = '/ext/eb-ssms/customer-service/add-delegation/validate';
  private searchAgentinfoUrl = `/ext/eb-ssms/agent-service/agent/information`;
  splitAgentCodeOneErr = false;
  _isDisableContinue = false;
  private empfRefNo?: string;

  splitAgentPreferredPreferred?: string;
  splitAgentPreferredLanguageConfig = {
    options: [
      {
        label: 'common.chinese',
        value: PreferredLanguageType.Chinese,
        disable: true,
      },
      {
        label: 'common.english',
        value: PreferredLanguageType.English,
        disable: true,
      },
    ],
  };

  constructor(
    private router: Router,
    private cdsPopup: CdsPopupService,
    public cdsLangService: CdsLangService,
    public languageChangeService: LanguageChangeService,
    private cdHttpServeService: CdHttpServeService,
    private alert: CdsAlertService,
    private authenticationService: AuthenticationService,
    private notifyService: NotifyService,
    private iffService: IffService,
    private route: ActivatedRoute,
    private tempCacheService: TempCacheService,
    public agentChannelService: AgentChannelService
  ) {}

  ngAfterViewInit(): void {
    pageToTop();
  }

  ngOnInit(): void {
    this.languageChangeService.lanSubject.subscribe(() => {
      if (this.secondAgent) {
        this.refreshIntermediaryContact(this.secondAgent);
      }

      if (this.regulatedActivitiesList) {
        this.regulatedActivitiesList.forEach(d => {
          d.refreshConfig();
        });
      }
    });

    this.empfRefNo = this.route.snapshot.paramMap.get('empfRefNo') || undefined;
    if (this.empfRefNo) {
      const data = this.tempCacheService.getItem(`case-summary-ee-${this.empfRefNo}`);
      if (data) {
        this.dataList = [data as CaseSummaryDS];
      }
    } else {
      const data = localStorage.getItem('case-summary-selected-data');
      if (data) {
        this.dataList = JSON.parse(data);
      }
    }

    if (this.dataList.length > 0) {
      const customerIdList: string[] = [];
      this.dataList.forEach(element => {
        if (element.customerId) {
          customerIdList.push(element.customerId);
        }
        element.rmReferralCode = new FormControl('', [Validators.required, this.rmReferralCodeVali]);
        element.inputCustomerId = new FormControl('', [this.inputCustomerIdVali]);
        element.sourceCode = new FormControl('', [this.sourceCodeVali]);
        element.campaignCode = new FormControl('', [this.campaignCodeVali]);
      });
      this.postMemberContacts(customerIdList);

      if (this.dataList.length === 1 && this.dataList[0].SplitCase) {
        const salesJourneySummaryId = this.dataList[0].salesJourneySummaryId;
        if (salesJourneySummaryId) {
          this.getAgentCode(salesJourneySummaryId).then(agentCodeList => {
            if (agentCodeList && agentCodeList.length > 0) {
              this.dataList[0].secondAgentCode = agentCodeList[0];
              this.splitAgent = agentCodeList[0];
            }
          });
        }
      }
    }

    this.regulatedControl.valueChanges.subscribe((val: boolean) => {
      this.currentStep2FrControl.setValue(val);
      this.secondReqControl.setValue(val);
    });

    this.frControl.valueChanges.subscribe((val: boolean) => {
      this.primaryReqControl.setValue(val);
      this.multipleFrControl.setValue(val);
      this.regulatedControl.setValue(undefined);
      if (!val && this.splitAgent) {
        this.regulatedControl.setValue(false);
      }
    });

    this.authenticationService.currentUserValue().then(res => {
      this.servicingAgentId = res.id + '';

      this.iffService.getAgentCode(this.servicingAgentId).subscribe({
        next: res => {
          if (res.result !== 0) {
            this.alert.warning('Warning!', `${res.message}`);
          } else {
            this.servicingAgentCode = res.data.agentCode;
          }
        },
        error: err => {
          this.alert.error('Error!', err);
        },
      });
    });
  }

  rmReferralCodeVali = (control: AbstractControl): ValidationErrors | null => {
    let input = control.value || '';
    input = input.trim();
    const isValid = input.length >= 6 && input.length <= 8;
    return isValid ? null : { lengthError: true };
  };

  inputCustomerIdVali = (control: AbstractControl): ValidationErrors | null => {
    let input = control.value || '';
    input = input.trim();
    const isValid = ((input.length >= 6 && input.length <= 8) || input.length === 0) && /^[A-Za-z0-9-]*$/.exec(input);
    return isValid ? null : { lengthError: true };
  };

  sourceCodeVali = (control: AbstractControl): ValidationErrors | null => {
    let input = control.value || '';
    input = input.trim();
    const isValid = (input.length === 10 || input.length === 0) && /^[A-Za-z0-9-]*$/.exec(input);
    return isValid ? null : { lengthError: true };
  };

  campaignCodeVali = (control: AbstractControl): ValidationErrors | null => {
    let input = control.value || '';
    input = input.trim();
    const isValid = (input.length === 10 || input.length === 0) && /^[A-Za-z0-9-]*$/.exec(input);
    return isValid ? null : { lengthError: true };
  };

  get dbsInputHasErrors() {
    if (!this.isChannelDBS) {
      return false;
    }
    return !this.dataList.every(item => {
      return !(
        (item.rmReferralCode && item.rmReferralCode.dirty && item.rmReferralCode.errors) ||
        (item.campaignCode && item.campaignCode.dirty && item.campaignCode.errors) ||
        (item.sourceCode && item.sourceCode.dirty && item.sourceCode.errors) ||
        (item.inputCustomerId && item.inputCustomerId.dirty && item.inputCustomerId.errors)
      );
    });
  }

  getAgentCode(salesJourneySummaryId: string) {
    return new Promise<string[]>(resolve => {
      if (salesJourneySummaryId) {
        this.cdHttpServeService.post<string[]>('/ext/eb-ssms-sales-journey-service/iffByRecordList/empfSplitAgentCd', { salesJourneySummaryId }).subscribe({
          next: res => {
            if (res.result !== 0) {
              this.alert.warning('Warning!', `${res.message}`);
            } else {
              resolve(res.data);
            }
          },
          error: err => {
            this.alert.error('Error!', err);
          },
        });
      }
    });
  }

  goCaseSummary() {
    localStorage.removeItem('case-summary-search-params');
    this.router.navigate(['/salesjourney/product-type-summary/case-summary']);
  }

  goBackWithData() {
    this.router.navigate(['/salesjourney/product-type-summary/case-summary']);
  }

  submit() {
    let isToAgentConfirmation = false;
    const data = this.dataList.map(element => {
      let deliveryChannel = '';
      if (element.deliveryChannel) {
        deliveryChannel = element.deliveryChannel === DeliveryChannelType.Email ? 'E' : element.deliveryChannel === DeliveryChannelType.SMS ? 'M' : '';
      }
      const agentInfos: any[] = [
        {
          agentIdentityId: this.servicingAgentId,
          regulatedAct: this.primaryReqControl.value,
          primaryAgent: true,
          agentNotificationChannel: deliveryChannel,
        },
      ];

      if (this.secondAgent && this.secondAgent.agentId) {
        const a: any = {
          agentid: this.secondAgent.agentId,
          regulatedAct: this.secondReqControl.value,
          primaryAgent: false,
        };
        isToAgentConfirmation = a.regulatedAct;
        agentInfos.push(a);
        if (this.regulatedControl.value) {
          a.agentNotificationChannel =
            this.intermediaryContactMethodValue === DeliveryChannelType.Email
              ? 'E'
              : this.intermediaryContactMethodValue === DeliveryChannelType.SMS
              ? 'M'
              : '';
          if (this.intermediaryContactMethodValue === DeliveryChannelType.SMS) {
            a['smsLanguage'] = this.splitAgentPreferredPreferred === PreferredLanguageType.English ? 0 : 1;
          }
        }
      }

      const result: any = {
        salesJourneySummaryId: element.salesJourneySummaryId,
        empfRefNo: element.eMPFRef,
        caseSplit: agentInfos.length > 1,
        emailAddr: element.emailAddr,
        countryCallingCode: element.countryCallingCode,
        mobile: element.phoneNumber,
        clientCommunicationChannel:
          element.deliveryChannel === DeliveryChannelType.Email ? 'E' : element.deliveryChannel === DeliveryChannelType.SMS ? 'M' : '',
        agentInfos,
        dbs:
          element.rmReferralCode && element.rmReferralCode.value
            ? {
                dbsRmReferralCd: element.rmReferralCode.value,
                dbsCustomerId: element.inputCustomerId?.value,
                dbsCampaignCd: element.campaignCode?.value,
                dbsSourceCd: element.sourceCode?.value,
              }
            : undefined,
      };

      if (element.deliveryChannel === DeliveryChannelType.SMS) {
        result['smsLanguage'] = element.preferredLanguage === PreferredLanguageType.English ? 0 : 1;
      }

      if (!result.mobile) {
        delete result.mobile;
        delete result.countryCallingCode;
      }
      if (!result.emailAddr) {
        delete result.emailAddr;
      }
      if (!this.isChannelDBS) {
        delete result.dbs;
      }
      return result;
    });
    this.notifyService.screenLoadingSubject.next(true);
    this.cdHttpServeService.post(`/ext/eb-ssms-sales-journey-service/iffByRecordList/submissions`, data).subscribe({
      next: res => {
        if (res.result === 0) {
          let msg = '';
          if (isToAgentConfirmation) {
            msg = 'sendIffInBatchSummary.popupScreenTitleForSplitAgent';
          } else {
            msg = 'sendIffInBatchSummary.popupScreenTitleCustomer';
          }
          const ref = this.cdsPopup.open(SubmitSuccessComponent, {
            data: {
              message: this.cdsLangService.translate(msg),
            },
          });
          ref.afterClosed().subscribe(agree => {
            if (agree) {
              localStorage.removeItem('case-summary-search-params');
              this.router.navigate(['/salesjourney/product-type-summary/case-summary']);
            }
          });
        } else {
          this.alert.error('Error!', res.message);
        }
        this.notifyService.screenLoadingSubject.next(false);
      },
      error: err => {
        this.notifyService.screenLoadingSubject.next(false);
        this.alert.error('Error!', err);
      },
    });
  }

  get isDisableRegulated() {
    return !this.frControl.value || this.splitAgent.length === 0;
  }

  onDisplayAdviseRef() {
    this.cdsPopup.open(AdviseEmpfrefComponent, {
      size: 'lg',
    });
  }

  stepOneContinue() {
    if (this.isDisableContinue || this.dbsInputHasErrors) {
      return;
    }
    if (this.isChannelDBS) {
      if (this.dataList[0].rmReferralCode?.errors) {
        this.dataList[0].rmReferralCode?.markAsDirty();
        return;
      }
    }
    if (!this.splitAgent) {
      this.secondAgent = undefined;
      this.currentStep = 3;
      pageToTop();
      return;
    } else if (this.splitAgent === this.servicingAgentCode) {
      this.splitAgentCodeOneErr = true;
      return;
    }

    this._isDisableContinue = true;
    this.cdHttpServeService
      .post<{
        agentCode: string;
        name: string;
        chineseName: string;
        errorCode?: number;
      }>(this.addDelegationValidate, {
        agentCode: agentCodeRemovePrefix0(this.splitAgent),
      })
      .subscribe({
        next: res => {
          if (res.result !== 0) {
            this.splitAgentCodeOneErr = true;
          } else {
            this.splitAgentCodeOneErr = false;
            this.goStep2();
          }
          this._isDisableContinue = false;
        },
        error: err => {
          this.alert.error('Error!', err);
          this._isDisableContinue = false;
        },
      });
  }

  multipleContinue() {
    if (this.isDisableContinue || this.dbsInputHasErrors) {
      return;
    }

    if (this.isChannelDBS) {
      let isStop = false;
      for (let i = 0; i < this.dataList.length; i++) {
        if (this.dataList[i].rmReferralCode?.errors) {
          this.dataList[i].rmReferralCode?.markAsDirty();
          isStop = true;
        }
      }
      if (isStop) {
        return;
      }
    }

    this.currentStep = 12;
    pageToTop();
  }

  goStep2() {
    this.cdHttpServeService
      .get<AgentDetailInfo>(this.searchAgentinfoUrl, {
        agentInfo: agentCodeRemovePrefix0(this.splitAgent),
      })
      .subscribe({
        next: res => {
          if (res.result !== 0) {
            this.alert.warning('Warning!', `${res.message}`);
          } else {
            this.refreshIntermediaryContact(res.data);
            this.currentStep = 2;
            pageToTop();
          }
        },
        error: err => {
          this.alert.error('Error!', err);
          this._isDisableContinue = false;
        },
      });
  }

  refreshIntermediaryContact(secondAgent: AgentDetailInfo) {
    this.secondAgent = secondAgent;
    let shortEmail = '';
    if (this.secondAgent.emailAddress) {
      const ea = this.secondAgent.emailAddress;
      const endIndex = ea.indexOf('@');
      shortEmail = ea.substring(0, 4) + '*****' + ea.substring(endIndex);
    }

    let shortPhone = '';
    if (this.secondAgent.mobileNumber) {
      const mn = this.secondAgent.mobileNumber;
      shortPhone = mn.substring(0, 4) + '****';
    }

    this.intermediaryContactMethodConfig = {
      options: [
        {
          label: this.cdsLangService.translate('sendIffInBatchSummary.contactMethodEmail') + (this.secondAgent.emailAddress ? `(${shortEmail})` : ''),
          value: DeliveryChannelType.Email,
          disable: !this.secondAgent.emailAddress,
        },
        {
          label: this.cdsLangService.translate('sendIffInBatchSummary.contactMethodPhone') + (this.secondAgent.mobileNumber ? `(${shortPhone})` : ''),
          value: DeliveryChannelType.SMS,
          disable: !this.secondAgent.mobileNumber,
        },
      ],
    };

    if (this.intermediaryContactMethodValue === undefined) {
      if (this.secondAgent.emailAddress) {
        this.intermediaryContactMethodValue = DeliveryChannelType.Email;
      } else if (this.secondAgent.mobileNumber) {
        this.intermediaryContactMethodValue = DeliveryChannelType.SMS;
      }
      this.intermediaryContactMethodValueChange(this.intermediaryContactMethodValue);
    }

    this.intermediaryContact?.refreshConfig();
  }

  getAgentName(agent?: AgentDetailInfo) {
    if (!agent) {
      return '';
    }
    if (this.cdsLangService.currentLang === 'en') {
      return agent.agentName || agent.agentNameTC;
    } else {
      return agent.agentNameTC || agent.agentName;
    }
  }

  get disabledStepTwoContinue() {
    return !(
      this.intermediaryContactMethodValue === DeliveryChannelType.Email ||
      (this.intermediaryContactMethodValue === DeliveryChannelType.SMS && this.splitAgentPreferredPreferred)
    );
  }

  stepTwoContinue() {
    if (this.disabledStepTwoContinue) {
      return;
    }

    this.currentStep = 3;
    pageToTop();
  }

  stepThreeBack() {
    if (!this.secondAgent) {
      this.currentStep = 1;
      pageToTop();
    } else {
      this.currentStep = 2;
      pageToTop();
    }
  }

  stepTwoBack() {
    this.currentStep = 1;
    pageToTop();
  }

  getClientName(item: CaseSummaryDS | null) {
    if (!item) {
      return 'N/A';
    }
    if (this.cdsLangService.currentLang === 'en') {
      return item.ClientName || item.ClientNameTC;
    } else {
      return item.ClientNameTC || item.ClientName;
    }
  }

  get getSingleSelected() {
    return this.dataList.length > 0 ? this.dataList[0] : null;
  }

  get getSingleSelectedDeliveryChannel() {
    if (!this.getSingleSelected) {
      return '';
    }
    if (this.getSingleSelected.deliveryChannel === DeliveryChannelType.Email) {
      return this.getSingleSelected.emailAddr;
    } else {
      return this.getSingleSelected.displayPhoneNumber;
    }
  }

  get getSingleSelectedPreferredLanguageType() {
    if (!this.getSingleSelected) {
      return '';
    }
    return this.getSingleSelected.preferredLanguage;
  }

  postMemberContacts(customerIdList: string[]) {
    this.cdHttpServeService.post<MemberContactData[]>(`/ext/eb-ssms-sales-journey-service/iffByRecordList/contacts/member`, customerIdList).subscribe({
      next: res => {
        if (res.result !== 0) {
          this.alert.warning('Warning!', `${res.message}`);
        } else {
          this.dataList.forEach(item => {
            const a = res.data.find(d => d.memberId === item.customerId);
            if (a) {
              item.countryCallingCode = a.countryCallingCode;
              item.displayPhoneNumber = a.displayPhoneNumber;
              item.emailAddr = a.emailAddr;
              item.memberId = a.memberId;
              item.phoneNumber = a.phoneNumber;
            }
            item.deliveryChannelConfig = {
              options: [
                {
                  label: 'common.email',
                  value: DeliveryChannelType.Email,
                  disable: true,
                },
                {
                  label: 'common.sms',
                  value: DeliveryChannelType.SMS,
                  disable: true,
                },
              ],
            };
            if (item.phoneNumber) {
              item.deliveryChannelConfig.options[1].disable = false;
              item.deliveryChannel = DeliveryChannelType.SMS;
            }
            if (item.emailAddr) {
              item.deliveryChannelConfig.options[0].disable = false;
              item.deliveryChannel = DeliveryChannelType.Email;
            }

            item.preferredLanguageConfig = {
              options: [
                {
                  label: 'common.chinese',
                  value: PreferredLanguageType.Chinese,
                  disable: true,
                },
                {
                  label: 'common.english',
                  value: PreferredLanguageType.English,
                  disable: true,
                },
              ],
            };
          });

          if (this.deliveryChannelList) {
            this.deliveryChannelList.forEach(d => {
              d.refreshConfig();
            });
          }
        }
      },
      error: err => {
        this.alert.error('Error!', err);
      },
    });
  }

  itemDeliveryChannelChange(item: CaseSummaryDS) {
    item.preferredLanguage = undefined;
    if (item.deliveryChannel === DeliveryChannelType.SMS) {
      item.preferredLanguageConfig = {
        options: [
          {
            label: 'common.chinese',
            value: PreferredLanguageType.Chinese,
            disable: false,
          },
          {
            label: 'common.english',
            value: PreferredLanguageType.English,
            disable: false,
          },
        ],
      };
    } else if (item.deliveryChannel === DeliveryChannelType.Email) {
      item.preferredLanguageConfig = {
        options: [
          {
            label: 'common.chinese',
            value: PreferredLanguageType.Chinese,
            disable: true,
          },
          {
            label: 'common.english',
            value: PreferredLanguageType.English,
            disable: true,
          },
        ],
      };
    }
    if (this.preferredLanguageList) {
      this.preferredLanguageList.forEach(d => {
        d.refreshConfig();
      });
    }
  }

  get isDisableContinue() {
    return (
      this._isDisableContinue ||
      !this.dataList.every(item => {
        return item.deliveryChannel === DeliveryChannelType.Email || (item.deliveryChannel === DeliveryChannelType.SMS && item.preferredLanguage);
      }) ||
      (this.frControl.value !== true && this.frControl.value !== false) ||
      (this.frControl.value === true && this.splitAgent && this.regulatedControl.value !== true && this.regulatedControl.value !== false)
    );
  }

  intermediaryContactMethodValueChange(value?: DeliveryChannelType) {
    this.splitAgentPreferredPreferred = undefined;
    if (value === DeliveryChannelType.SMS) {
      this.splitAgentPreferredLanguageConfig = {
        options: [
          {
            label: 'common.chinese',
            value: PreferredLanguageType.Chinese,
            disable: false,
          },
          {
            label: 'common.english',
            value: PreferredLanguageType.English,
            disable: false,
          },
        ],
      };
    } else if (value === DeliveryChannelType.Email) {
      this.splitAgentPreferredLanguageConfig = {
        options: [
          {
            label: 'common.chinese',
            value: PreferredLanguageType.Chinese,
            disable: true,
          },
          {
            label: 'common.english',
            value: PreferredLanguageType.English,
            disable: true,
          },
        ],
      };
    }
    if (this.splitAgentPreferredLanguage) {
      this.splitAgentPreferredLanguage.refreshConfig();
    }
  }

  splitAgentChange(val: string) {
    if (val.length === 0) {
      this.splitAgentCodeOneErr = false;
      this.regulatedControl.setValue(undefined);
    } else {
      if (!this.frControl.value) {
        this.regulatedControl.setValue(false);
      }
    }
  }

  getIntermediaryContactMethod(agent?: AgentDetailInfo) {
    if (this.intermediaryContactMethodValue === DeliveryChannelType.Email) {
      return agent?.emailAddress;
    } else {
      return agent?.mobileNumber;
    }
  }

  get isChannelDBS() {
    return this.agentChannelService.channel === AgentChannel.DBS;
  }

  get isDisabled() {
    if (this.agentChannelService.channel === AgentChannel.DBS) {
      return true;
    }
    if (this.agentChannelService.channel === AgentChannel.CORPORATE && this.agentChannelService.agentCode?.startsWith('400')) {
      return true;
    }
    return false;
  }
}
