export const dbName = 'indexdb';
export const sessionTable = 'sessionTable';
export const commissionTable = 'commissionTable';
export const roleTable = 'roleTable';
export const version = 1;
export const sessionId = 'sessionInfo';
export const employerSystemType = 'employerSystemType';

export const tableList = [sessionTable, commissionTable, roleTable];

export interface SessionDto {
  id: string;
  sessionInfo: string;
}

export interface commissionDto {
  id: string;
  commission: string;
}

export interface RoleDto {
  id: string;
  role: string;
}
