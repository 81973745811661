<div class="l-d-f l-jc-sb l-mb-5">
  <div>
    <div class="cds-h2 cds-light l-ma-0">
      <span>{{ title }}</span>
    </div>
    <div class="cds-body1 l-mt-2 l-mb-0" *ngIf="showSubTitle" style="color: #000">
      {{ 'common.total' | lang }} {{ total | number }}
      {{ 'common.records' | lang }}
    </div>
  </div>
  <div *ngIf="action" class="l-d-f l-jc-fe l-as-cen">
    <ng-content *ngTemplateOutlet="action"></ng-content>
  </div>
</div>
