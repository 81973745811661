/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CdsButtonConfig } from '@cds/ng-core/button';
import { CdsDropdownConfig } from '@cds/ng-core/dropdown';
import { CdsIconConfig } from '@cds/ng-core/icon';
import { CdsLangService } from '@cds/ng-core/lang';
import { CdsPopupService } from '@cds/ng-web-components/popup';
import { DateTime } from 'luxon';
import moment from 'moment';
import { finalize } from 'rxjs';
import { ReportExtractionService } from 'src/app/core/services/report-extraction.service';
import { PermissionService } from 'src/app/core/services/permission.service';
import { AlertPopupComponent } from 'src/app/shared/alert-popup/alert-popup.component';
import { PageConfig } from 'src/app/shared/data-table';
import { ToastAlertService } from 'src/app/shared/toast-alert.service';
import { dateValidator, numberStringValidator, rangeValidator } from 'src/app/shared/validators/validators';
import { jsonToSheet } from 'src/app/utils/xlsx';
import { ReportExtraction } from './report-extraction';

@Component({
  selector: 'app-report-extraction',
  templateUrl: './report-extraction.component.html',
  styleUrls: ['./report-extraction.component.scss'],
})
export class ReportExtractionComponent implements OnInit {
  pageConfig: PageConfig = {
    current: 1,
    pageSize: 10,
    totalCount: 0,
  };

  iconConfig: CdsIconConfig = {
    color: '#00A758',
  };

  downloadButtonConfig: CdsButtonConfig = {
    size: 'sm',
  };

  resetButtonConfig: CdsButtonConfig = {
    size: 'sm',
    style: 'secondary',
  };

  showDatePicker = true;

  isLoading = false;

  numberOfElements = 0;

  dataSource: ReportExtraction[] = [];

  nowTimeInstr = DateTime.now().toFormat('dd/MM/yyyy');

  maxTime = this.getNowTime();

  maxTimeInStr = DateTime.fromJSDate(this.maxTime).toFormat('dd/MM/yyyy');

  selectAll = false;

  isIndeterminate = false;

  isMobile = false;

  scrollBarWidth = '15px';

  // selectStatus?: AutoRolloverStatsE;

  // AutoRolloverStatsE = AutoRolloverStatsE;

  form: FormGroup = new FormGroup({});

  formReady = false;

  extractionTypeConfig: CdsDropdownConfig = {
    label: 'Choose extraction type',
    placeholder: '',
    options: [
      { label: 'Fund Balance', value: 'FUND_BALANCE' },
      { label: 'Contribution History', value: 'CONTRIBUTION_HISTORY' },
      { label: 'Cert List', value: 'CERT_LIST' },
      // { label: 'Hold', value: 'Hold' },
      // { label: "Estimated Employee's  Accrued Benefit", value: 'ESTIMATED_EMPLOYEE' },
    ],
  };

  get employerAccountCodeControl() {
    return this.form?.get('employerAccountCode');
  }

  get payrollGroupIdFromControl() {
    return this.form?.get('payrollGroupIdFrom');
  }

  get payrollGroupIdInControl() {
    return this.form?.get('payrollGroupIdIn');
  }

  get contributionPeriodStartDateControl() {
    return this.form?.get('contributionPeriodStartDate');
  }

  get effectiveDateControl() {
    return this.form?.get('effectiveDate');
  }

  get downloadButtonDisabled() {
    return !this.form?.valid || !this.form.dirty;
  }

  get resetButtonDisabled() {
    return !this.form?.dirty;
  }

  get extractionType() {
    return this.form?.get('extractionType')?.value;
  }

  get contributionPeriodStartDateDisabled() {
    return this.extractionType !== 'CONTRIBUTION_HISTORY';
  }

  get effectiveDateDisabled() {
    return this.extractionType === 'CERT_LIST';
  }

  fundBalanceSheetHeader = {
    extractionDate: 'EXTRACTION DATE',
    employerAccountCode: 'EMPLOYER ACCOUNT CODE',
    payrollGroupId: 'PAYROLL GROUP ID',
    fundCd: 'FUND CODE',
    enNm: 'FUND NAME (ENGLISH)',
    untCnt: 'UNIT BALANCE OF INVESTED FUND',
    fundBalance: 'FUND BALANCE',
    bigPrice: 'BID PRICE',
    effDate: 'EFFECTIVE DATE',
  };
  contributionHistorySheetHeader = {
    extractionDate: 'EXTRACTION DATE',
    erAcctCd: 'EMPLOYER ACCOUNT CODE',
    payrollGroupId: 'PAYROLL GROUP ID',
    mbAcctCd: 'MEMBER ACCOUNT CODE',
    lastNm: 'LAST NAME',
    firstNm: 'FIRST NAME',
    conPrdStartDate: 'CONTRIBUTION PERIOD START DATE',
    conPrdEndDate: 'CONTRIBUTION PERIOD END DATE',
    dateJoinScheme: 'SCHEME JOINED DATE',
    mpfaDcRetdDate: 'DEFAULT CONTRIBUTION REPORTED DATE (TO MPFA)',
    mcOntimePayDate: 'MC - ON-TIME PAID DATE',
    vcOntimePayDate: 'VC - ON-TIME PAID DATE',
    riSlryAmt: 'RI',
    bsSlryAmt: 'BASIC SALARY',
    billConAmtEemc: 'BILL CONTRIBUTION AMOUNT (EEMC)',
    billConAmtErmc: 'BILL CONTRIBUTION AMOUNT (ERMC)',
    billConAmtSC: 'BILL CONTRIBUTION AMOUNT (SC)',
    billConAmtEevc: 'BILL CONTRIBUTION AMOUNT (EEVC)',
    billConAmtEevc2: 'BILL CONTRIBUTION AMOUNT (EEVC2) ',
    billConAmtErvc: 'BILL CONTRIBUTION AMOUNT (ERVC)',
    billConAmtErvc2: 'BILL CONTRIBUTION AMOUNT (ERVC2)',
    billConAmtErvc3: 'BILL CONTRIBUTION AMOUNT (ERVC3)',
    billConAmtErvc4: 'BILL CONTRIBUTION AMOUNT (ERVC4)',
    billConAmtErvc5: 'BILL CONTRIBUTION AMOUNT (ERVC5)',
    billConAmtErvc6: 'BILL CONTRIBUTION AMOUNT (ERVC6)',
    billConAmtErvc7: 'BILL CONTRIBUTION AMOUNT (ERVC7)',
    paidAmtEmmc: 'PAID AMOUNT (EEMC)',
    paidAmtErmc: 'PAID AMOUNT (ERMC)',
    paidAmtSC: 'PAID AMOUNT (SC)',
    paidAmtEevc: 'PAID AMOUNT (EEVC)',
    paidAmtEevc2: 'PAID AMOUNT (EEVC2)',
    paidAmtErvc: 'PAID AMOUNT (ERVC)',
    paidAmtErvc2: 'PAID AMOUNT (ERVC2)',
    paidAmtErvc3: 'PAID AMOUNT (ERVC3)',
    paidAmtErvc4: 'PAID AMOUNT (ERVC4)',
    paidAmtErvc5: 'PAID AMOUNT (ERVC5)',
    paidAmtErvc6: 'PAID AMOUNT (ERVC6)',
    paidAmtErvc7: 'PAID AMOUNT (ERVC7)',
    mbmcOntimePayAmt: 'MBMC – ON-TIME PAID AMOUNT',
    ermcOntimePayAmt: 'ERMC – ON-TIME PAID AMOUNT',
    statusCd: 'APPEAL RESULT STATUS',
    surPayDate: 'SURCHARGE PAID DATE',
    cdr: 'CDR',
    vcCdr: 'VC CDR',
  };

  certListSheetHeader = {
    extractionDate: 'EXTRACTION DATE',
    erAcctCd: 'EMPLOYER ACCOUNT CODE',
    companyNm: 'COMPANY NAME',
    lastNm: 'LAST NAME',
    firstNm: 'FIRST NAME',
    statusCd: 'MEMBER STATUS',
    mbAcctCd: 'MEMBER ACCOUNT CODE',
    payrollGroupId: 'PAYROLL GROUP ID',
    // todo
    idNumber: 'HK ID/ PASSPORT',
    dob: 'DATE OF BIRTH',
    dateOfEmploy: 'DATE OF EMPLOYMENT',
    vestEntitleDate: 'VESTING ENTITLEMENT START DATE',
    termDate: 'TERMINATION COMPLETION DATE',
    extraField2: 'EXM EE 1ST CONT DATE',
    eeTyp: 'EMPLOYEE TYPE',
    jobPosition: 'JOB POSITION',
    erGrdMbClss: 'EMPLOYER GRADE',
    eeLspSpEntitleAmt: 'EE - LSP/SP Amount Entitled',
    erPayAmt: 'TO ER PAYMENT AMOUNT',
    // erLspSpPaidAmt: 'ER - LSP/SP Amount Paid',
    // untCnt: 'UNVEST ER AMOUNT',
    // estimatedMonth: 'ESTIMATED MONTH OF SERVICES',
  };

  EstimatedEmployeeHeader = {
    extractionDate: 'EXTRACTION DATE',
    companyNm: 'COMPANY NAME',
    erAcctCd: 'EMPLOYER ACCOUNT CODE',
    payrollGroupId: 'MEMBER ACCOUNT CODE',
    memberNm: 'MEMBER NAME',
    idNumber: 'HKID/ PASSPORT',
    // need confirm
    ermc: 'EMPLOYER MANDATORY (ESTIMATE ACCRUED BENEFITS)',
    ervc: 'ERVC – EMPLOYER VOLUNTARY (ESTIMATE ACCURED BENEFIT)',
    ervc2: 'ERVC2 – EMPLOYER VOLUNTARY (ESTIMATE ACCURED BENEFIT)',
    ervc3: 'ERVC3 – EMPLOYER VOLUNTARY (ESTIMATE ACCURED BENEFIT)',
    ervc4: 'ERVC4 – EMPLOYER VOLUNTARY (ESTIMATE ACCURED BENEFIT)',
    ervc5: 'ERVC5 – EMPLOYER VOLUNTARY (ESTIMATE ACCURED BENEFIT)',
    ervc6: 'ERVC6 – EMPLOYER VOLUNTARY (ESTIMATE ACCURED BENEFIT)',
    ervc7: 'ERVC7 – EMPLOYER VOLUNTARY (ESTIMATE ACCURED BENEFIT)',
    vestRt: 'VESTING % AS OF DD/MM/YYYY',
    // todo
    vestAmount: 'ESTIMATE VESTED BENEFIT BASED ON VESTING %',
  };

  constructor(
    private service: ReportExtractionService,
    private cdsPopup: CdsPopupService,
    private langService: CdsLangService,
    private toastAlert: ToastAlertService,
    private permissionService: PermissionService
  ) {}

  ngOnInit(): void {
    this.permissionCheck();
    this.initForm();
  }

  initForm() {
    this.form?.markAsUntouched();
    this.form?.markAsPristine();

    this.form = new FormGroup(
      {
        extractionType: new FormControl('FUND_BALANCE'),
        employerAccountCode: new FormControl('', [Validators.required]),
        payrollGroupIdFrom: new FormControl('', [numberStringValidator()]),
        payrollGroupIdIn: new FormControl('', [numberStringValidator()]),
        contributionPeriodStartDate: new FormControl('', [dateValidator('create.dateError')]),
        effectiveDate: new FormControl('', [Validators.required, dateValidator('create.dateError')]),
      },
      { validators: rangeValidator('commission.report-extraction.invalid-range', 'payrollGroupIdFrom', 'payrollGroupIdIn') }
    );
    this.formReady = false;
    setTimeout(() => {
      this.formReady = true;
    });
  }

  getRequestParams() {
    const params: ReportExtraction = {
      ...this.form.value,
    };
    params.contributionPeriodStartDate = this.dateTrans(params.contributionPeriodStartDate);
    params.effectiveDate = this.dateTrans(params.effectiveDate);
    return this.removeEmptyItems(params);
  }

  removeEmptyItems(obj: any): any {
    return Object.entries(obj).reduce((acc: any, [key, value]) => {
      if (value !== '' && value !== undefined && value !== null) {
        acc[key] = value;
      }
      return acc;
    }, {});
  }

  dateTrans(date?: string) {
    if (date) {
      return moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD');
    }

    return '';
  }

  getData() {
    const params: ReportExtraction = this.getRequestParams();
    this.isLoading = true;
    this.isIndeterminate = false;
    this.selectAll = false;
    this.service
      .getReport(params)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe(data => {
        if (data.result === 0 && data.data) {
          // const list = data.data.filter(item => {
          //   return ![1312735, 1312743, 1312750, 1312750, 1318716, 2978633, 3576030, 3472628].some(code => code === item.employerAccountCode);
          // });
          if (data.data.length === 0) {
            this.toastAlert.show('info', 'common.info', 'No data found', 5000);
            return;
          }
          jsonToSheet(
            {
              fileName: this.getFileName(),
              fileType: 'csv',
              header: this.getHeader(),
            },
            this.convertToString(this.createData(data.data))
          );
        } else {
          this.toastError(data.message);
        }
      });
  }

  convertToString(arr: Record<string, any>[]): Record<string, string>[] {
    return arr.map(obj => {
      const convertedObj: Record<string, string> = {};

      for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
          if (obj[key] !== undefined && obj[key] !== null) {
            convertedObj[key] = String(obj[key]);
          } else {
            convertedObj[key] = '';
          }
        }
      }

      return convertedObj;
    });
  }

  createData(data: any[]) {
    const type = this.form.value.extractionType;
    switch (type) {
      case 'FUND_BALANCE':
        return this.createFundBalanceData(data);
      case 'CONTRIBUTION_HISTORY':
        return this.createContributionHistoryData(data);
      case 'CERT_LIST':
        return this.createCertListData(data);
      case 'ESTIMATED_EMPLOYEE':
        return this.createEstimatedEmployeeData(data);
      default:
        return [];
    }
  }

  formatDate(date: string, format: string) {
    if (!date) {
      return '';
    }
    return DateTime.fromFormat(date, format).toFormat('yyyyMMdd');
  }

  formatIsoDate(date: string) {
    if (!date) {
      return '';
    }
    return DateTime.fromISO(date).toFormat('yyyyMMdd');
  }

  createFundBalanceData(data: any[]) {
    return data.map(item => {
      return {
        extractionDate: this.formatDate(item.extractionDate, 'yyyy-MM-dd'),
        employerAccountCode: item.employerAccountCode,
        payrollGroupId: item.payrollGroupId,
        fundCd: item.fundCd,
        enNm: item.enNm,
        untCnt: item.untCnt,
        fundBalance: item.fundBalance,
        bigPrice: item.bigPrice,
        effDate: this.formatIsoDate(item.effDate),
      };
    });
  }

  createContributionHistoryData(data: any[]) {
    return data.map(item => {
      return {
        extractionDate: this.formatDate(item.extractionDate, 'yyyy-MM-dd'),
        erAcctCd: item.erAcctCd,
        payrollGroupId: item.payrollGroupId,
        mbAcctCd: item.mbAcctCd,
        lastNm: item.lastNm,
        firstNm: item.firstNm,
        conPrdStartDate: this.formatDate(item.conPrdStartDate, 'yyyy-MM-dd'),
        conPrdEndDate: this.formatDate(item.conPrdEndDate, 'yyyy-MM-dd'),
        dateJoinScheme: this.formatDate(item.dateJoinScheme, 'yyyy-MM-dd'),
        mpfaDcRetdDate: this.formatDate(item.mpfaDcRetdDate, 'yyyy-MM-dd'),
        mcOntimePayDate: this.formatDate(item.mcOntimePayDate, 'yyyy-MM-dd'),
        vcOntimePayDate: this.formatDate(item.vcOntimePayDate, 'yyyy-MM-dd'),
        riSlryAmt: item.riSlryAmt,
        bsSlryAmt: item.bsSlryAmt,
        billConAmtEemc: item.billConAmtEemc,
        billConAmtErmc: item.billConAmtErmc,
        billConAmtSC: item.billConAmtSC,
        billConAmtEevc: item.billConAmtEevc,
        billConAmtEevc2: item.billConAmtEevc2,
        billConAmtErvc: item.billConAmtErvc,
        billConAmtErvc2: item.billConAmtErvc2,
        billConAmtErvc3: item.billConAmtErvc3,
        billConAmtErvc4: item.billConAmtErvc4,
        billConAmtErvc5: item.billConAmtErvc5,
        billConAmtErvc6: item.billConAmtErvc6,
        billConAmtErvc7: item.billConAmtErvc7,
        paidAmtEmmc: item.paidAmtEmmc,
        paidAmtErmc: item.paidAmtErmc,
        paidAmtSC: item.paidAmtSC,
        paidAmtEevc: item.paidAmtEevc,
        paidAmtEevc2: item.paidAmtEevc2,
        paidAmtErvc: item.paidAmtErvc,
        paidAmtErvc2: item.paidAmtErvc2,
        paidAmtErvc3: item.paidAmtErvc3,
        paidAmtErvc4: item.paidAmtErvc4,
        paidAmtErvc5: item.paidAmtErvc5,
        paidAmtErvc6: item.paidAmtErvc6,
        paidAmtErvc7: item.paidAmtErvc7,
        mbmcOntimePayAmt: item.mbmcOntimePayAmt,
        ermcOntimePayAmt: item.ermcOntimePayAmt,
        statusCd: item.statusCd,
        surPayDate: this.formatDate(item.surPayDate, 'yyyy-MM-dd'),
        cdr: item.cdr,
        vcCdr: item.vcCdr,
      };
    });
  }

  createCertListData(data: any[]) {
    return data.map(item => {
      return {
        extractionDate: this.formatDate(item.extractionDate, 'yyyy-MM-dd'),
        erAcctCd: item.erAcctCd,
        companyNm: item.companyNm,
        lastNm: item.lastNm,
        firstNm: item.firstNm,
        statusCd: item.statusCd,
        mbAcctCd: item.mbAcctCd,
        payrollGroupId: item.payrollGroupId,
        idNumber: item.idNumber,
        dob: this.formatDate(item.dob, 'yyyy-MM-dd'),
        dateOfEmploy: this.formatDate(item.dateOfEmploy, 'yyyy-MM-dd'),
        vestEntitleDate: this.formatDate(item.vestEntitleDate, 'yyyy-MM-dd'),
        termDate: this.formatDate(item.termDate, 'yyyy-MM-dd'),
        extraField2: this.formatDate(item.extraField2, 'yyyy-MM-dd'),
        eeTyp: item.eeTyp,
        jobPosition: item.jobPosition,
        erGrdMbClss: item.erGrdMbClss,
        eeLspSpEntitleAmt: item.eeLspSpEntitleAmt,
        erPayAmt: item.erPayAmt,
        // erLspSpPaidAmt: item.erLspSpPaidAmt,
        // untCnt: item.untCnt,
        // estimatedMonth: item.estimatedMonth,
      };
    });
  }

  createEstimatedEmployeeData(data: any[]) {
    return data.map(item => {
      return {
        extractionDate: this.formatDate(item.extractionDate, 'yyyy-MM-dd'),
        companyNm: item.companyNm,
        erAcctCd: item.erAcctCd,
        payrollGroupId: item.payrollGroupId,
        memberNm: item.memberNm,
        idNumber: item.idNumber,
        ermc: item.ermc,
        ervc: item.ervc,
        ervc2: item.ervc2,
        ervc3: item.ervc3,
        ervc4: item.ervc4,
        ervc5: item.ervc5,
        ervc6: item.ervc6,
        ervc7: item.ervc7,
        vestRt: item.vestRt,
        vestAmount: item.vestAmount,
      };
    });
  }

  getFileName() {
    const type = this.form.value.extractionType;
    switch (type) {
      case 'FUND_BALANCE':
        return 'Fund Balance';
      case 'CONTRIBUTION_HISTORY':
        return 'Contribution History Report';
      case 'CERT_LIST':
        return 'Cert List';
      case 'ESTIMATED_EMPLOYEE':
        return "Estimated Employee's Accrued Benefit Report";
      default:
        return '';
    }
  }

  getHeader() {
    const type = this.form.value.extractionType;
    switch (type) {
      case 'FUND_BALANCE':
        return this.fundBalanceSheetHeader;
      case 'CONTRIBUTION_HISTORY':
        return this.contributionHistorySheetHeader;
      case 'CERT_LIST':
        return this.certListSheetHeader;
      case 'ESTIMATED_EMPLOYEE':
        return this.EstimatedEmployeeHeader;
      default:
        return {};
    }
  }

  getTime(dateStr: string) {
    return moment(dateStr, 'DD/MM/YYYY').toDate();
  }

  download() {
    if (this.downloadButtonDisabled) {
      this.employerAccountCodeControl?.markAsTouched();
      this.effectiveDateControl?.markAsTouched();
      return;
    }

    this.pageConfig = {
      ...this.pageConfig,
      current: 1,
    };
    this.getData();
  }

  reset() {
    if (this.resetButtonDisabled) {
      return;
    }
    this.pageConfig = {
      ...this.pageConfig,
      current: 1,
    };

    this.initForm();

    this.showDatePicker = false;
    setTimeout(() => {
      this.showDatePicker = true;
    });
  }

  getNowTime() {
    return moment().toDate();
  }

  getMaxFromDate = (toDateStr?: string) => {
    if (!toDateStr) {
      return this.getNowTime();
    }
    return moment(toDateStr, 'DD/MM/YYYY').toDate();
  };

  getMinToDate = (fromDateStr = '01/01/0000') => {
    return moment(fromDateStr, 'DD/MM/YYYY').toDate();
  };

  private popupWithReload() {
    this.cdsPopup
      .open(AlertPopupComponent, {
        size: 'sm',
        data: {
          message: this.langService.translate('common.action.message.update.success.italic'),
          buttonName: this.langService.translate('common.gotItWithExclamation'),
        },
      })
      .afterClosed()
      .subscribe(() => {
        this.getData();
      });
  }

  toastError(errorMessage: string) {
    this.toastAlert.show('error', 'common.error', errorMessage, 5000);
  }

  permissionCheck() {
    // this.permissionService.hasPermission(PermissionAccess.W, PermissionItem.SHK_AUTO_ROLLOVER_OPS_STAFF).then(hasPermission => {
    //   if (hasPermission) {
    //     this.columnsConfig = [
    //       {
    //         key: 'select',
    //         title: '',
    //         sticky: true,
    //       },
    //       ...this.columnsConfig,
    //     ];
    //   }
    // });
  }

  onTypeChange(type: string) {
    if (type === 'FUND_BALANCE' || type === 'ESTIMATED_EMPLOYEE') {
      this.effectiveDateControl?.setValidators([Validators.required, dateValidator('create.dateError')]);
    } else {
      this.effectiveDateControl?.setValidators([dateValidator('create.dateError')]);
    }

    if (type === 'CONTRIBUTION_HISTORY') {
      this.contributionPeriodStartDateControl?.enable();
      this.contributionPeriodStartDateControl?.setValidators([Validators.required, dateValidator('create.dateError')]);
    } else {
      this.contributionPeriodStartDateControl?.setValidators([dateValidator('create.dateError')]);
      this.contributionPeriodStartDateControl?.setValue(undefined);
      this.contributionPeriodStartDateControl?.disable();
      this.contributionPeriodStartDateControl?.markAsPristine();
      this.contributionPeriodStartDateControl?.markAsUntouched();
    }
    this.effectiveDateControl?.updateValueAndValidity();
    this.contributionPeriodStartDateControl?.updateValueAndValidity();

    if (type === 'CERT_LIST' || type === 'CONTRIBUTION_HISTORY') {
      this.effectiveDateControl?.setValue(undefined);
      this.effectiveDateControl?.disable();
      this.effectiveDateControl?.markAsPristine();
      this.effectiveDateControl?.markAsUntouched();
    } else {
      this.effectiveDateControl?.enable();
    }

    this.showDatePicker = false;
    setTimeout(() => {
      this.showDatePicker = true;
    });
  }

  employerAccountCodeChange(value: string) {
    if (!value) return;
    this.employerAccountCodeControl?.setValue(value.trim());
  }
}
