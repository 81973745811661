<div class="card-min-height p-3">
  <div class="row" style="align-items: end">
    <span style="font-weight: 600; font-size: 20px"> Chat History</span>
    <app-cd-table-title [total]="rows.length" [showSubTitle]="true"></app-cd-table-title>
    <div class="form-group pb-2">
      <input type="text" class="form-control" style="padding: 0.7rem" placeholder="Search User" (keyup)="searchChat($event)" />
    </div>
    <app-data-table [dataSource]="filterRows" [columnsConfig]="headers">
      <ng-template appDtBodyCellDef="date" let-element>
        {{ element.date | date : 'dd/MM/yyyy HH:mm' }}
      </ng-template>
      <ng-template appDtBodyCellDef="schemeCd" let-element>
        <div class="l-d-f l-ai-cen">
          <a (click)="view(element)" style="margin-right: 15px"> <cds-icon icon="action:eye_1"></cds-icon></a>
        </div>
      </ng-template>
    </app-data-table>
  </div>
</div>
