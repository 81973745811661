import { Component, OnInit } from '@angular/core';
import { CdsDropdownConfig } from '@cds/ng-core/dropdown';
import { FundCodeMapping, InterestRate, InterestRateFundDTO } from '../../employer';
import { AbstractControl, FormControl, FormGroup, ValidationErrors } from '@angular/forms';
import { CdsButtonConfig } from '@cds/ng-core/button';
import { finalize } from 'rxjs';
import { MatDialogRef } from '@angular/material/dialog';
import { InterestRateService } from '../../interest-rate.service';

@Component({
  selector: 'app-interest-rate-add-popup',
  templateUrl: './interest-rate-add-popup.component.html',
  styleUrls: ['./interest-rate-add-popup.component.scss'],
})
export class InterestRateAddPopupComponent implements OnInit {
  constructor(private service: InterestRateService, private dialogRef: MatDialogRef<InterestRateAddPopupComponent>) {}

  form = new FormGroup({
    fundCode: new FormControl('', []),
    year: new FormControl(),
    month: new FormControl(),
    normalRate: new FormControl('', [this.amtValidator()]),
    withdrawalRate: new FormControl('', [this.amtValidator()]),
    interimRate: new FormControl('', [this.amtValidator()]),
  });

  interestRateDTO: InterestRate = {};
  _fundList: FundCodeMapping[] = [];

  fundConfig: CdsDropdownConfig = {
    placeholder: 'Select Fund',
    options: [],
  };

  monthConfig: CdsDropdownConfig = {
    placeholder: 'Month',
    options: [
      { label: '01', value: 1 },
      { label: '02', value: 2 },
      { label: '03', value: 3 },
      { label: '04', value: 4 },
      { label: '05', value: 5 },
      { label: '06', value: 6 },
      { label: '07', value: 7 },
      { label: '08', value: 8 },
      { label: '09', value: 9 },
      { label: '10', value: 10 },
      { label: '11', value: 11 },
      { label: '12', value: 12 },
    ],
  };
  yearConfig: CdsDropdownConfig = {
    placeholder: 'Year',
    options: [
      { label: '2023', value: 2023 },
      { label: '2024', value: 2024 },
    ],
  };

  resetConfig: CdsButtonConfig = {
    style: 'secondary',
    size: 'sm',
  };
  saveConfig: CdsButtonConfig = {
    style: 'primary',
    size: 'sm',
  };
  _loadding = false;

  formatter = new Intl.NumberFormat('en', {
    useGrouping: false,
    minimumFractionDigits: 4,
    maximumFractionDigits: 4,
  });

  _error: Set<string> = new Set();
  _waring: Set<string> = new Set();
  _valid = false;

  ngOnInit(): void {
    this.listAllFund();
    this.initForm();
  }

  validating() {
    const date = new Date();
    const monthSelected = this.interestRateDTO.month;
    const yearSelected = this.interestRateDTO.year;
    const code = this.interestRateDTO.code;
    const _error: Set<string> = new Set();
    const _waring: Set<string> = new Set();

    if (this.interestRateDTO.normalRate) {
      const _normalRate = Number.parseFloat(this.interestRateDTO.normalRate);
      if (!this.interestRateDTO.month) {
        _error.add('For Normal Rate, month must be current month – 1');
      }
      if (monthSelected == 12 && date.getFullYear() - 1 != yearSelected) {
        _error.add('For Normal Rate, month must be current month – 1');
      }
      if (date.getFullYear() == yearSelected && date.getMonth() != monthSelected) {
        _error.add('For Normal Rate, month must be current month – 1');
      }
      const codeList1 = ['DHK121', 'SHK122'];
      if (code && codeList1.includes(code)) {
        _waring.add('NORMAL RATE = 0 - IS THIS CORRECT ?');
      }
      const codeList2 = ['DHK005', 'DUS005', 'DHK015', 'DUS015'];
      if (code && codeList2.includes(code)) {
        this._error.add('INTEREST RATE DATE NOT = BLANK AND NM % = BLANK');
      }
      if (_normalRate < 5 || _normalRate > 15) {
        this._waring.add('NM OR GTEE INTEREST RATE IS NOT WITHIN THE RANGE OF 5 - 15 % - IS THIS CORRECT ?');
      }
      const codeList3 = ['DHK121', 'SHK122', 'DHK005', 'DUS005', 'DHK015', 'DUS015', 'DUS067', 'DHK067', 'SHK078'];
      if (code && codeList3.includes(code) && _normalRate > 19) {
        _error.add('Normal Rate is not within the range of 0 - 19 %');
      }
      const codeLis4 = ['DHK005', 'DUS005', 'DHK015', 'DUS015'];
      if (code && codeLis4.includes(code) && !this.interestRateDTO.withdrawalRate) {
        _waring.add('Withdrawal Rate = BLANK AND Normal Rate NOT = BLANK');
      }
      const codeList5 = ['DHK121', 'SHK122', 'SHK078'];
      if (code && codeList5.includes(code) && this.interestRateDTO.recordSent) {
        _error.add('	NOT ALLOWED TO CHANGE AS NORMAL / GTEE RATE FOR MONTH (XX) HAS BEEN PASSED TO EMPF/ IFAST. ');
      }
    }

    if (this.interestRateDTO.withdrawalRate) {
      const _withdrawalRate = Number.parseFloat(this.interestRateDTO.withdrawalRate);
      if (_withdrawalRate == 0) {
        _error.add('WDRL INTEREST RATE = 0');
      }
      const codeList1 = ['DHK121', 'DHK067', 'DUS067', 'SHK122', 'SHK078'];
      if (code && codeList1.includes(code) && _withdrawalRate > 5) {
        _waring.add('WDRL INTEREST RATE IS GREATER THAN 5 % - IS THIS CORRECT ?');
      }
    }

    if (this.interestRateDTO.interimRate) {
      const _interimRate = Number.parseFloat(this.interestRateDTO.interimRate);
      const codeList1 = ['DHK005', 'DUS005', 'DHK015', 'DUS015'];
      if (code && codeList1.includes(code) && _interimRate == 0) {
        _error.add('INTERIM % = 0');
      }
    }

    if (this.interestRateDTO.normalRate && this.interestRateDTO.interimRate) {
      const _normalRate = Number.parseFloat(this.interestRateDTO.normalRate);
      const _interimRate = Number.parseFloat(this.interestRateDTO.interimRate);
      const codeList1 = ['DHK005', 'DUS005', 'DHK015', 'DUS015'];
      if (code && codeList1.includes(code) && _interimRate <= _normalRate) {
        _waring.add('INTERIM % IS NOT GREATER THAN NORMAL % - IS THIS CORRECT ?');
      }
    }
    if (this.interestRateDTO.withdrawalRate || this.interestRateDTO.interimRate) {
      if (this.interestRateDTO.month != monthSelected && this.interestRateDTO.year != yearSelected) {
        _error.add('For Withdrawal Rate/ Interim Rate, month must be current month');
      }
    }
    if (
      this.interestRateDTO.code &&
      this.interestRateDTO.month &&
      this.interestRateDTO.year &&
      (this.interestRateDTO.normalRate || this.interestRateDTO.withdrawalRate || this.interestRateDTO.interimRate)
    ) {
      this._valid = true;
    } else {
      this._valid = false;
    }
    this._error = _error;
    this._waring = _waring;
    this._valid = this._valid && this._error.size == 0;
  }

  amtValidator() {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value?.trim();
      if (!value) {
        return {};
      }
      const reg = /^[0-9]{1,2}\.*[0-9]{0,4}$/; // eslint-disable-line
      if (!reg.test(value)) {
        return { msg: 'Invalid percent for Normal Rate' };
      }
      return {};
    };
  }

  initForm() {
    this.interestRateDTO.year = new Date().getFullYear();
  }

  listAllFund() {
    this.service
      .listAllFund()
      .pipe(
        finalize(() => {
          // this._loading = false;
        })
      )
      .subscribe(data => {
        if (data.result === 0 && data.data) {
          this._fundList = data.data;
          this.fundConfig.options = data.data.map(x => {
            return {
              label: x.code + ' ' + x.name + ' ' + x.zhName,
              value: x.code,
            };
          });
          this.fundConfig = { ...this.fundConfig };
        }
      });
  }

  reset() {
    this.interestRateDTO = {};
  }
  save() {
    if (!this.form.valid || !this._valid) {
      return;
    }
    this._loadding = true;
    this.service
      .interestRateSave(this.interestRateDTO)
      .pipe(
        finalize(() => {
          this._loadding = false;
        })
      )
      .subscribe(data => {
        if (data.result === 0 && data.data) {
          this.dialogRef.close({ data: true });
        }
      });
  }
  findInterestRate() {
    if (!this.interestRateDTO.code || !this.interestRateDTO.year || !this.interestRateDTO.month) {
      return;
    }
    const parm: InterestRateFundDTO = {
      fundCode: this.interestRateDTO.code,
      year: this.interestRateDTO.year,
      month: this.interestRateDTO.month,
    };
    this.service
      .interestRateFind(parm)
      .pipe(
        finalize(() => {
          // this._loading = false;
        })
      )
      .subscribe(data => {
        if (data.result === 0) {
          this.interestRateDTO.id = data.data ? data.data.id : undefined;
          this.interestRateDTO.normalRate = data.data ? this.formatAmt(data.data.normalRate) : undefined;
          this.interestRateDTO.withdrawalRate = data.data ? this.formatAmt(data.data.withdrawalRate) : undefined;
          this.interestRateDTO.interimRate = data.data ? this.formatAmt(data.data.interimRate) : undefined;
        }
      });
  }
  inputChange(val: string, type: string) {
    switch (type) {
      case 'fundCode':
        this.interestRateDTO.code = val;
        this.interestRateDTO.fundId = this._fundList.find(x => x.code == val)?.id;
        this.findInterestRate();
        break;
      case 'year':
        this.interestRateDTO.year = Number.parseInt(val);
        this.findInterestRate();
        break;
      case 'month':
        this.interestRateDTO.month = Number.parseInt(val);
        this.findInterestRate();
        break;
      case 'normalRate':
        this.interestRateDTO.normalRate = this.formatAmt(val);
        break;
      case 'withdrawalRate':
        this.interestRateDTO.withdrawalRate = this.formatAmt(val);
        break;
      case 'interimRate':
        this.interestRateDTO.interimRate = this.formatAmt(val);
        break;
      default:
    }
    this.validating();
  }

  formatAmt(val: string | undefined) {
    if (!val) {
      return;
    }
    return this.formatter.format(Number.parseFloat(val));
  }
}
