/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { CdsButtonConfig } from '@cds/ng-core/button';
import { CdsPopupService, MatDialogRef } from '@cds/ng-web-components/popup';
import moment from 'moment';
import { finalize } from 'rxjs';
import { BTN_CONFIG_SECONDARY } from 'src/app/config/btn.config';
import { PermissionAccess, PermissionItem } from 'src/app/core/models/enum';
import { ResponseResult } from 'src/app/core/models/response/response-result';
import { SchemeMasterService } from 'src/app/core/services/commission/scheme-master.service';
import { UserAgreementPopupComponent } from '../../user-agreement-popup.component';

import { I18N_KEY } from '../scheme-master.config';
import { validatorSchemeName, validatorWarnSlIfFaoNy } from '../scheme-master.validators';

@Component({
  selector: 'app-scheme-master-edit',
  templateUrl: './scheme-master-edit.component.html',
  styleUrls: ['./scheme-master-edit.component.scss'],
})
export class SchemeMasterEditComponent implements OnInit {
  permissionAccess = PermissionAccess.W;
  permissionItem = PermissionItem.COMMISSION_SCHEME_MASTER_RECORD;

  btnCfg: CdsButtonConfig = BTN_CONFIG_SECONDARY;
  INIT_VALUE_SIF = '0.000';
  BACK_URL = '/commission/scheme-list';
  id: string;

  currentYearFrom = '';
  currentYearTo = '';
  nextYearTo = '';
  nextYearFrom = '';
  formGroup: FormGroup;

  actionText = I18N_KEY.COMMON_EDIT;
  isReviewMode = false;

  scheme: any = {
    name: '',
    type: '',
    schemeNo: '',
    currentInflation: { salaryInflationFactor: '' },
    nextInflation: { salaryInflationFactor: '' },
    createTime: '',
    lastUpdateTime: '',
  };
  salaryInflationFactorClass = {};
  isEdit = false;
  isConfirm = false;
  nextSIF = true;
  isUpdating = false;

  constructor(private cdsPopup: CdsPopupService, private router: Router, private route: ActivatedRoute, private schemeService: SchemeMasterService) {
    this.formGroup = new FormGroup({
      schemeName: new FormControl('', [validatorSchemeName]),
      salaryInflationFactor: new FormControl(this.INIT_VALUE_SIF),
    });
    this.id = this.route.snapshot.paramMap.get('id') || '';
  }

  ngOnInit(): void {
    if (!this.id) {
      this.backLanding();
      return;
    }
    this.schemeService.getById(this.id).subscribe(res => {
      if (res && res.data) {
        this.scheme = res.data;
        if (!this.scheme.nextInflation) {
          this.nextSIF = false;
          this.scheme.nextInflation = this.scheme.currentInflation;
        }
        this.setFormValue();
        this.initDate();
        this.setHeaderDetailMode();
      }
    });
  }

  backLanding() {
    this.router.navigate([this.BACK_URL]);
  }

  get schemeName() {
    return this.formGroup.get('schemeName') as FormControl;
  }

  get salaryInflationFactor() {
    return this.formGroup.get('salaryInflationFactor') as FormControl;
  }

  initDate() {
    const cyFromDate = new Date(this.scheme.currentInflation.financialStartDateHkt);
    const mounth = cyFromDate.getMonth();
    const day = cyFromDate.getDate();
    let year = cyFromDate.getFullYear();
    if (mounth > 10 && day > 30) {
      year = year + 1;
    }
    const cyToDate = new Date(`${year}-12-30`);
    this.currentYearFrom = moment(cyFromDate).format('DD MMM yyyy');
    this.currentYearTo = moment(cyToDate).format('DD MMM yyyy');
    if (!this.nextSIF) {
      this.nextYearFrom = moment(`${year}-12-31`).format('DD MMM yyyy');
      this.nextYearTo = moment(`${year + 1}-12-30`).format('DD MMM yyyy');
    } else {
      const nyFromDate = new Date(this.scheme.nextInflation.financialStartDateHkt);
      const nextyear = nyFromDate.getFullYear();
      this.nextYearFrom = moment(`${nextyear}-12-31`).format('DD MMM yyyy');
      this.nextYearTo = moment(`${nextyear + 1}-12-30`).format('DD MMM yyyy');
    }
  }

  setFormValue() {
    this.formGroup.reset({
      salaryInflationFactor: this.INIT_VALUE_SIF,
    });
    this.schemeName.setValue(this.scheme.name);
    const salaryInflationFactorNy: any = this.scheme.nextInflation?.salaryInflationFactor;
    if (salaryInflationFactorNy) {
      this.salaryInflationFactor.setValue(parseFloat(salaryInflationFactorNy).toFixed(3));
    }
  }

  setHeaderDetailMode() {
    this.actionText = I18N_KEY.COMMON_EDIT;
  }

  setHeaderEditMode() {
    this.actionText = I18N_KEY.COMMON_CANCLE_EDIT;
    this.isReviewMode = false;
  }

  setHeaderReviewMode() {
    this.isReviewMode = true;
  }

  schemeNameInput(event: any) {
    let value = event.target.value;
    const pos = event.target.selectionEnd;
    value = value.toUpperCase();
    if (value === this.schemeName.value) {
      return;
    }
    this.schemeName.setValue(value);
    event.target.setSelectionRange(pos, pos);
  }

  schemeNameChange(event: any) {
    const value = event.target.value.trim();
    if (value === this.schemeName.value) {
      return;
    }
    this.schemeName.setValue(value);
  }

  salaryInflationFactorInput(event: any) {
    let value = event.target.value;
    value = value.match(/(^(\d){1,2}\.(\d){0,2}([0-9]){0,1})|(^(\d){1,2})/g); // 只允許數字2位小數點3位
    const pos = event.target.selectionEnd;
    this.salaryInflationFactor.setValue(value ? value[0] : '');
    event.target.setSelectionRange(pos, pos);
  }

  salaryInflationFactorChange(event: any) {
    let value = event.target.value;
    value = value.match(/(^(\d){1,2}\.(\d){0,2}([0-9]){0,1})|(^(\d){1,2})/g); // 只允許數字2位小數點3位
    value = parseFloat(value ? value[0] : 0).toFixed(3);
    if (value === this.salaryInflationFactor.value) {
      return;
    }
    this.salaryInflationFactor.setValue(value);
  }

  iconClick() {
    if (this.isEdit) {
      this.cancelEdit();
    } else {
      this.edit();
    }
  }

  edit() {
    this.salaryInflationFactor.removeValidators([validatorWarnSlIfFaoNy]);
    this.setHeaderEditMode();
    this.isEdit = true;
  }

  cancelEdit() {
    const popupRef: MatDialogRef<UserAgreementPopupComponent> = this.cdsPopup.open(UserAgreementPopupComponent, {
      data: {
        message: I18N_KEY.COMMON_ACTION_CANCLE,
        cancel: I18N_KEY.COMMON_CANCLE,
        continue: I18N_KEY.COMMON_CONTINUE,
        type: 'confirm',
      },
    });
    popupRef.afterClosed().subscribe(confirm => {
      if (confirm && confirm.agree) {
        this.reset();
        this.isEdit = false;
        this.isConfirm = false;
        this.setHeaderDetailMode();
      }
    });
  }

  // addSIF() {
  //   this.nextSIF = true;
  // }

  get isSaveAvailable() {
    if (this.schemeName.value != this.scheme.name) {
      return true;
    }
    // if (!this.scheme.nextInflation && this.nextSIF) {
    //   return true;
    // }
    if (this.scheme.nextInflation && this.scheme.nextInflation.salaryInflationFactor != this.salaryInflationFactor.value) {
      return true;
    }
    return false;
  }

  reset() {
    this.setFormValue();
    // if (!this.scheme.nextInflation) {
    //   this.nextSIF = false;
    // }
  }

  save() {
    if (!this.isSaveAvailable) {
      return;
    }
    this.salaryInflationFactorClass = {};
    const salaryInflationFactorCy = this.scheme.currentInflation.salaryInflationFactor;
    const salaryInflationFactorNy = this.salaryInflationFactor.value || 0;
    // if (this.nextSIF) {
    //   this.salaryInflationFactorClass = {
    //     'font-green': Number(salaryInflationFactorNy) > Number(Number(salaryInflationFactorCy)),
    //     'font-red': Number(salaryInflationFactorNy) < Number(Number(salaryInflationFactorCy)),
    //   };
    // }
    this.salaryInflationFactorClass = {
      'font-green': Number(salaryInflationFactorNy) > Number(Number(salaryInflationFactorCy)),
      'font-red': Number(salaryInflationFactorNy) < Number(Number(salaryInflationFactorCy)),
    };
    this.salaryInflationFactor.setValidators([validatorWarnSlIfFaoNy]);
    this.salaryInflationFactor.setValue(this.salaryInflationFactor.value);
    this.isEdit = false;
    this.isConfirm = true;
    this.setHeaderReviewMode();
  }

  backToEdit() {
    if (this.isUpdating) {
      return;
    }
    this.salaryInflationFactor.removeValidators([validatorWarnSlIfFaoNy]);
    this.isEdit = true;
    this.isConfirm = false;
    this.setHeaderEditMode();
  }

  confirm() {
    if (this.isUpdating) {
      return;
    }
    this.isUpdating = true;
    const params: any = {
      id: this.id,
      name: this.schemeName.value,
    };
    // if (this.nextSIF) {
    //   params.nextSalaryInflationFactor = this.salaryInflationFactor.value;
    // }
    params.nextSalaryInflationFactor = this.salaryInflationFactor.value;
    this.schemeService
      .update(params)
      .pipe(
        finalize(() => {
          this.isUpdating = false;
        })
      )
      .subscribe(resp => {
        let popData, result: number;
        if (resp && resp.data && resp.result == ResponseResult.SUCCESS) {
          popData = {
            message: I18N_KEY.COMMON_ACTION_SUCCESS,
            continue: I18N_KEY.COMMON_GOT,
            type: 'alert',
          };
          result = resp.result;
        } else {
          popData = {
            message: I18N_KEY.COMMON_ACTION_FAILE,
            continue: I18N_KEY.COMMON_BACK_EDIT,
            type: 'alert',
          };
          result = resp.result;
        }
        const popupRef: MatDialogRef<UserAgreementPopupComponent> = this.cdsPopup.open(UserAgreementPopupComponent, { data: popData });
        popupRef.afterClosed().subscribe(confirm => {
          if (confirm && confirm.agree) {
            if (result == ResponseResult.SUCCESS) {
              this.router.navigate([this.BACK_URL]);
            } else {
              this.backToEdit();
            }
          }
        });
      });
  }
}
