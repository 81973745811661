import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { CDS_POPUP_DATA, CdsPopupService } from '@cds/ng-web-components/popup';

@Component({
  selector: 'app-close-popup',
  templateUrl: './close-popup.component.html',
  styleUrls: ['./close-popup.component.scss'],
})
export class ClosePopupComponent implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<ClosePopupComponent>,
    private cdsPopup: CdsPopupService,
    @Inject(CDS_POPUP_DATA)
    public data: {
      message: string;
    }
  ) {}

  ngOnInit(): void {}

  close() {
    this.dialogRef.close({
      agree: true,
    });
  }
}
