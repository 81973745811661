import { CdsOption } from '@cds/ng-core/configuration';

export interface MonthConfig extends CdsOption {
  month: string;
  days: number;
}

export const MONTH_OPTS: MonthConfig[] = [
  { label: 'common.month.january', value: 0, month: '01', days: 31 },
  { label: 'common.month.february', value: 1, month: '02', days: 28 },
  { label: 'common.month.march', value: 2, month: '03', days: 31 },
  { label: 'common.month.april', value: 3, month: '04', days: 30 },
  { label: 'common.month.may', value: 4, month: '05', days: 31 },
  { label: 'common.month.june', value: 5, month: '06', days: 30 },
  { label: 'common.month.july', value: 6, month: '07', days: 31 },
  { label: 'common.month.august', value: 7, month: '08', days: 31 },
  { label: 'common.month.september', value: 8, month: '09', days: 30 },
  { label: 'common.month.october', value: 9, month: '10', days: 31 },
  { label: 'common.month.november', value: 10, month: '11', days: 30 },
  { label: 'common.month.december', value: 11, month: '12', days: 31 },
];
