<!-- <div
  [ngClass]="{
    'closed-sidebar': globals.toggleSidebar,
    'closed-sidebar-md': globals.toggleSidebarMobile,
    'settings-open': globals.toggleThemeOptions,
    'closed-sidebar-open': globals.sidebarHover || globals.toggleSidebarMobile,
    'header-menu-open': globals.toggleHeaderMobile,
    'drawer-open': globals.toggleDrawer,
    'fixed-footer': globals.toggleFixedFooter
  }"
  [class]="'app-container app-theme-white'">
  <app-header></app-header>
  <app-sidebar></app-sidebar>
  <div class="app-main__outer">
    <div class="app-main__inner p-3">
      <div [@architectUIAnimation]="o.isActivated ? o.activatedRoute : ''">
        <router-outlet #o="outlet"></router-outlet>
      </div>
    </div>
    <app-footer></app-footer>
  </div> -->
<!-- <app-options-drawer></app-options-drawer> -->
<!-- <app-drawer></app-drawer> -->
<!-- <div class="sidebar-menu-overlay" (click)="toggleSidebarMobile()"></div>
</div> -->

<cds-navigation class="utility-header" bannerLogo="logo:Manulife_txt_rgb_HongKong">
  <!-- sidebar Start -->
  <cds-menu>
    <cds-menu-item #menuItem icon="contact:comment" routerLink="/chatbot-admin/canned" label="Canned"> </cds-menu-item>
    <cds-menu-item #menuItem icon="contact:comment" routerLink="/chatbot-admin/dialogue-flow" label="Dialogue Flow"> </cds-menu-item>
    <cds-menu-item #menuItem icon="transport:subway" routerLink="/chatbot-admin/auto-response" label="Auto Response"> </cds-menu-item>
    <cds-menu-item #menuItem icon="form:statement" routerLink="/chatbot-admin/agrement" label="Message Board"> </cds-menu-item>
    <cds-menu-item #menuItem icon="form:form_simple" routerLink="/chatbot-admin/pii-keyword" label="PII Keyword "> </cds-menu-item>
    <cds-menu-item #menuItem icon="form:form_simple" routerLink="/chatbot-admin/chat-definition" label="Chat Definition"> </cds-menu-item>
    <!-- <cds-menu-item #menuItem icon="work:career_advancement" routerLink="/chatbot-admin/users"  label="Users">
    </cds-menu-item> -->
  </cds-menu>
  <!-- sidebar End -->

  <!-- headbar Start -->
  <cds-navigation-utilities>
    <cds-navigation-lang-utility
      [ngStyle]="{ display: isHideNavigationLang ? 'none' : 'unset' }"
      [(selected)]="selectedLang"
      [options]="langList"
      (selectedChange)="changeLang($event)">
    </cds-navigation-lang-utility>
    <cds-navigation-button-utility label="{{ 'Logout' | lang }}" (cdsClick)="logout()"> </cds-navigation-button-utility>
  </cds-navigation-utilities>
  <!-- headbar End -->

  <!-- content container -->
  <cds-content id="contenContainer" style="height: 100%; display: flex; flex-direction: column">
    <!-- <app-admin-header></app-admin-header> -->
    <div class="layout-content div-bg-light-grey">
      <div class="app-main__outer">
        <div class="app-main__inner p-0">
          <div [@architectUIAnimation]="o.isActivated ? o.activatedRoute : ''">
            <router-outlet #o="outlet"></router-outlet>
          </div>
        </div>
      </div>
    </div>
  </cds-content>
  <!-- footer -->
  <!-- <app-cds-navigation-footer-local
    [config]="footerConfig"
    class="l-ma-0"></app-cds-navigation-footer-local> -->
  <div class="footer l-pl-7 l-pr-7">
    <div class="logo-wrapper">
      <cds-icon class="logo" icon="logo:Manulife_rgb_w" [config]="{ color: 'var(--cds-bg-info)' }"></cds-icon>
    </div>
    <div class="text-wrapper">
      <span class="text cds-detail1">{{ 'common.footerText' | lang }}</span>
    </div>
  </div>
</cds-navigation>

<!-- <ngx-loading-bar [color]="'#3f6ad8'"></ngx-loading-bar> -->
