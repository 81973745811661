<div class="sent-eiff-popup">
  <cds-icon icon="action:button_checkmark" class="icon-checkmark"></cds-icon>
  <div style="margin-top: 83px">
    <span class="cds-h4-demibold">已發送eIFF申請給客戶確認，請留意電郵更新。</span>
  </div>
  <div class="l-mt-3 l-d-f l-ai-cen">
    <div>
      <cds-icon icon="action:warning" class="icon-warning"></cds-icon>
    </div>
    <div class="l-ml-2">
      <span class="cds-detail1" style="color: var(--cds-color-dark-2-coral)">聲明一經提交後將不得更改</span>
    </div>
  </div>
  <div class="l-mt-4">
    <cds-button size="sm" class="got-it-btn" (click)="onGotIt()">了解</cds-button>
  </div>
</div>
