/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { BasicResponse, IPageResponse } from '../../models/response/response';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CommissionErrorLogService {
  constructor(private http: HttpClient) {}

  getData(params: any): Observable<BasicResponse<IPageResponse<CommissionErrorLogItem>>> {
    return this.http.get<BasicResponse<IPageResponse<CommissionErrorLogItem>>>(
      `${environment.apiPrefix}${environment.commissionServiceExt}${'/commission-error-log/list'}`,
      {
        params: params,
      }
    );
  }
}

export interface CommissionErrorLogItem {
  id: string;
  occurDate: string;
  errSign: string;
  errMsg: string;
  referenceNo: string;
  commissionPoolId?: string;
  employerAccountCode?: string;
  memberAccountCode?: string;
  accountType: string;
  payRollGroupNo?: string;
  contStartDate?: string;
  vipCode?: string;
  txnCode?: string;
}
