<ng-container *ngIf="form && key">
  <ng-container *ngIf="form.get(key)?.invalid">
    <cds-assistive-text *ngIf="(form.get(key)?.errors)!['maxlength']" class="l-pt-2">
      <ng-container *ngIf="msg">
        {{ msg }}
      </ng-container>
      <ng-container *ngIf="!msg"> Exceeds character limit </ng-container>
    </cds-assistive-text>
    <cds-assistive-text class="l-pt-2" *ngIf="(form.get(key)?.errors)!['msg']">
      {{ (form.get(key)?.errors)!['msg'] }}
    </cds-assistive-text>
  </ng-container>
</ng-container>
