<cds-popup class="success-dialog">
  <cds-popup-title
    >{{ 'cheque.list.your-message' | lang }}
    <span class="cds-h2-ita">{{ 'cheque.list.update-success' | lang }}</span>
  </cds-popup-title>
  <cds-popup-content> </cds-popup-content>
  <cds-popup-actions>
    <cds-button label="{{ 'confirm.popup.gotIt' | lang }}" (click)="gotIt()"></cds-button>
  </cds-popup-actions>
</cds-popup>
