import { PermissionAccess, PermissionItem } from 'src/app/core/models/enum';

export const TAB = [
  {
    label: 'Normal Compensation',
    value: 'normalCompensation',
    backTabType: 'agents',
    permissionAccess: PermissionAccess.W,
    permissionItem: PermissionItem.COMM_ADJ_NORMAL,
  },
  {
    label: 'Individual Transfer',
    value: 'individualTransfer',
    backTabType: 'agents',
    permissionAccess: PermissionAccess.W,
    permissionItem: PermissionItem.COMM_ADJ_INDIVIDUAL,
  },
  {
    label: 'Appeal Case',
    value: 'appealCase',
    backTabType: 'individualTransfer',
    permissionAccess: PermissionAccess.W,
    permissionItem: PermissionItem.COMM_ADJ_APPEAL,
  },
];
