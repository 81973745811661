<app-cd-page-title
  [showBack]="true"
  [backLandingLabel]="'commission.pool.agents.create.backText' | lang"
  (backLanding)="doBackMethod()"
  title="{{ 'commission.pool.agents.create.title' | lang }}">
</app-cd-page-title>
<div class="l-pb-6">
  <div class="row" id="errorDisplay">
    <div class="col-xs-12 col-md-12 l-pt-6">
      <div class="cds-h5-light l-pt-2 l-pb-2" style="font-weight: 400">
        {{ 'commission.pool.summary.commissionPoolNumber' | lang }}:&nbsp;&nbsp;&nbsp;&nbsp;
        <span class="font-bold">{{ commissionPoolId }}</span>
      </div>

      <div class="l-mb-0">
        <span *ngIf="editStatus" class="cds-h2-light">
          {{ 'commission.pool.agents.create.commissionAgentChange' | lang }}
        </span>
        <span *ngIf="!editStatus" class="cds-h2-light">
          {{ 'commission.pool.agents.create.commissionAgentChangeRecordSummary' | lang }}
        </span>
      </div>

      <div class="col-xs-12 col-md-4 l-pt-4 l-pb-6 padding-none">
        <div class="col-xs-12 col-md-8 l-pt-3 padding-none">
          <div class="cds-body2 cds-dark-navy-text light-3">
            {{ 'commission.pool.agents.create.agentEffectiveDate' | lang }}
          </div>
          <div *ngIf="editStatus">
            <cds-textfield
              onKeypress="return(/(\S)/.test(String.fromCharCode(event.keyCode)))"
              [(ngModel)]="editForm.effectiveDate"
              placeholder="DD/MM/YYYY"
              [cdsDatepicker]="picker"
              (ngModelChange)="formChange('effectiveDate', 0)">
            </cds-textfield>
            <cds-datepicker [value]="editForm.effectiveDate" #picker="cdsDatepicker"></cds-datepicker>
          </div>
          <cds-assistive-text *ngIf="editForm.effectiveDateError" class="l-pt-3">
            {{ editForm.effectiveDateError | lang }}
          </cds-assistive-text>
          <span *ngIf="!editStatus">{{ editForm.effectiveDate }}</span>
        </div>
      </div>
      <div *ngIf="isErrorDisplay" class="col-xs-9 col-md-9 l-pt-3 l-pb-3 l-mb-3 div-border-top card2 l-d-f l-jc-sb">
        <div class="l-d-f">
          <cds-icon size="sm" icon="general:voltage" class="l-pt-2 l-pr-2"></cds-icon>
          <div>
            <span class="cds-body1-ita" style="color: #a00e18">{{ 'commission.pool.agents.create.percentageWarning1' | lang }}</span>
            <br />
            <span class="cds-detail2">{{ 'commission.pool.agents.create.percentageWarning2' | lang }}</span>
          </div>
        </div>
        <cds-icon size="sm" icon="action:cross" style="cursor: pointer" (click)="closeErrorAlert()"></cds-icon>
      </div>
      <cds-progress *ngIf="loading" mode="indeterminate" class="l-pl-6"></cds-progress>
      <div *ngIf="!loading">
        <div class="col-xs-12 col-md-12 l-pt-3 l-pb-3 div-border-top card" style="overflow: auto">
          <table class="col-xs-12 col-md-12 cds-table full div-border-top div-border-bottom" style="border-collapse: collapse">
            <caption></caption>
            <tr class="div-border-bottom">
              <th scope="col" class="padding-24 font-bold text-left" width="150">
                {{ 'commission.pool.agents.create.agent' | lang }}
              </th>
              <th scope="col" class="padding-24 font-bold text-left">
                {{ 'commission.pool.agents.create.sellingAgent' | lang }}
              </th>
              <th scope="col" class="padding-24 font-bold text-left">
                {{ 'commission.pool.agents.create.shareHKPC' | lang }}
              </th>
              <th scope="col" class="padding-24 font-bold text-left">
                {{ 'commission.pool.agents.create.shareCommission' | lang }}
              </th>
              <th scope="col" class="padding-24 font-bold text-left">
                {{ 'commission.pool.agents.create.reportDate' | lang }}
              </th>
              <th scope="col" *ngIf="editStatus" class="padding-24 font-bold text-left">
                {{ 'commission.pool.common.action' | lang }}
              </th>
            </tr>
            <ng-container *ngFor="let item of dataSource; let i = index">
              <tr class="border-top">
                <td class="padding-24-bottom-5 cds-detail1" width="150">
                  <div class="cds-body2 cds-dark-navy-text light-3">
                    {{ item.agentCode }}
                  </div>
                  <div class="l-pt-2">
                    <span>{{ item.agentName }}</span>
                  </div>
                </td>
                <td class="padding-24-bottom-5 cds-detail1">
                  <div class="cds-body2 cds-dark-navy-text light-3">
                    {{ 'commission.pool.agents.create.sellingAgent' | lang }}
                  </div>
                  <div class="l-pt-2">
                    <cds-checkbox
                      [(ngModel)]="item.sellingAgent"
                      (ngModelChange)="formChange('item.sellingAgent', i)"
                      [disabled]="!editStatus"
                      label=""></cds-checkbox>
                  </div>
                </td>
                <td class="padding-24-bottom-5 cds-detail1">
                  <div class="cds-body2 cds-dark-navy-text light-3">
                    {{ 'commission.pool.agents.create.shareHKPC' | lang }}
                  </div>
                  <div *ngIf="!editStatus" class="l-pt-2">
                    <span>{{ item.shareHkpc }}</span>
                  </div>
                  <div *ngIf="editStatus" class="l-d-f">
                    <input
                      class="text-field"
                      [(ngModel)]="item.shareHkpc"
                      placeholder="{{ 'commission.pool.agents.create.inputShareHKPC' | lang }}"
                      oninput="value=value.match(/(^(\d){0,2}\.(\d){0,3})|(^(100.000|100.00|100.0|100|[0-9][0-9]?))/g) || ''"
                      (change)="formChange('item.shareHkpc', i)" />
                    <span class="l-pt-3">&nbsp;%</span>
                  </div>
                </td>
                <td class="padding-24-bottom-5 cds-detail1">
                  <div class="cds-body2 cds-dark-navy-text light-3">
                    {{ 'commission.pool.agents.create.shareCommission' | lang }}
                  </div>
                  <div *ngIf="!editStatus" class="l-pt-2">
                    <span>{{ item.shareCommission }}</span>
                  </div>
                  <div *ngIf="editStatus" class="l-d-f">
                    <input
                      class="text-field"
                      [(ngModel)]="item.shareCommission"
                      placeholder="{{ 'commission.pool.agents.create.inputShareCommission' | lang }}"
                      oninput="value=value.match(/(^(\d){0,2}\.(\d){0,3})|(^(100.000|100.00|100.0|100|[0-9][0-9]?))/g) || ''"
                      (change)="formChange('item.shareCommission', i)" />
                    <span class="l-pt-3">&nbsp;%</span>
                  </div>
                </td>
                <td class="cds-detail1 padding-24-bottom-5">
                  <div class="cds-body2 cds-dark-navy-text light-3">
                    {{ 'commission.pool.agents.create.reportDate' | lang }}
                  </div>
                  <div *ngIf="!editStatus" class="l-pt-2">
                    <span>{{ item.saReportDate }}</span>
                  </div>
                  <div *ngIf="editStatus" style="width: 80%">
                    <cds-textfield
                      onKeypress="return(/(\S)/.test(String.fromCharCode(event.keyCode)))"
                      [(ngModel)]="item.saReportDate"
                      placeholder="DD/MM/YYYY"
                      [cdsDatepicker]="picker2"
                      (ngModelChange)="formChange('item.saReportDate', i)">
                    </cds-textfield>
                    <cds-datepicker #picker2="cdsDatepicker"></cds-datepicker>
                  </div>
                </td>
                <td *ngIf="editStatus" class="padding-24-bottom-5 cds-detail1">
                  <span style="color: red; cursor: pointer" (click)="delete(i)">
                    {{ 'commission.pool.common.delete' | lang }}
                  </span>
                </td>
              </tr>
              <tr>
                <td class="l-pb-5 l-pl-2"></td>
                <td class="l-pb-5 l-pl-2">
                  <cds-assistive-text type="warning" *ngIf="item.sellingAgentWarning">
                    <span style="color: #d77d28">
                      {{ item.sellingAgentWarning | lang }}
                    </span>
                  </cds-assistive-text>
                </td>
                <td class="l-pb-5 l-pl-2">
                  <cds-assistive-text *ngIf="item.shareHkpcError">
                    {{ item.shareHkpcError | lang }}
                  </cds-assistive-text>
                </td>
                <td class="l-pb-5 l-pl-2">
                  <cds-assistive-text *ngIf="item.shareCommissionError">
                    {{ item.shareCommissionError | lang }}
                  </cds-assistive-text>
                </td>
                <td class="l-pb-5 l-pl-2">
                  <cds-assistive-text *ngIf="item.saReportDateError">
                    {{ item.saReportDateError | lang }}
                  </cds-assistive-text>
                  <cds-assistive-text type="warning" *ngIf="item.saReportDateWarning">
                    <span style="color: #d77d28">
                      {{ item.saReportDateWarning | lang }}
                    </span>
                  </cds-assistive-text>
                </td>
                <td class="l-pb-5 l-pl-2"></td>
              </tr>
            </ng-container>
          </table>
          <div *ngIf="dataSource.length === 0" class="col-xs-12 col-md-12">
            <app-no-result-found></app-no-result-found>
          </div>
          <cds-assistive-text type="warning" class="l-pt-2" *ngIf="warningMsg">
            <span style="color: #d77d28">
              {{ warningMsg | lang }}
            </span>
          </cds-assistive-text>
          <div *ngIf="editStatus" class="col-xs-12 col-md-12 l-pt-4 l-pb-4 l-d-f div-border-top" style="background: white">
            <div class="l-d-f l-pl-4" (click)="addNew()" style="cursor: pointer">
              <div class="icon-add">
                <cds-icon size="sm" color="white" icon="action:button_plus"></cds-icon>
              </div>
              <span class="font-bold l-pl-3">{{ 'commission.pool.agents.create.addNew' | lang }}</span>
            </div>
          </div>
        </div>
        <div *ngIf="editStatus" class="col-xs-12 col-md-12 l-pt-6 l-pb-3 l-d-f l-plr-0">
          <cds-button size="md" [config]="btnCfg" (click)="reset()">{{ 'commission.pool.common.reset' | lang }} </cds-button>
          <cds-button size="md" class="l-ml-3" (click)="save()" [disabled]="disableSave">{{ 'commission.pool.common.save' | lang }} </cds-button>
        </div>
        <div *ngIf="!editStatus" class="col-xs-12 col-md-12 l-pt-6 l-pb-3 l-d-f l-plr-0">
          <cds-button size="md" [config]="btnCfg" (click)="backToEdit()">{{ 'commission.pool.common.backToEdit' | lang }} </cds-button>
          <cds-button size="md" class="l-ml-3" (click)="confirm()">{{ 'commission.pool.common.confirm' | lang }} </cds-button>
        </div>
      </div>
    </div>
  </div>
</div>
