<div class="cd-multiple-accordion-panel">
  <div class="l-d-f l-ai-cen cursor-pointer header" (click)="trigger()">
    <cds-icon class="expand-icon up-icon" *ngIf="expand" [icon]="upIcon"></cds-icon>
    <cds-icon class="expand-icon down-icon" *ngIf="!expand" [icon]="downIcon"></cds-icon>
    <div class="l-ml-4" style="width: 100%">
      <ng-content select="[header]"></ng-content>
    </div>
  </div>
  <div class="content" [ngClass]="[expand ? 'expand' : 'no-expand']">
    <ng-content select="[content]"></ng-content>
  </div>
</div>
