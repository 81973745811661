import { Component, Inject, OnInit } from '@angular/core';
import { CdsButtonConfig } from '@cds/ng-core/button';
import { CDS_POPUP_DATA, MatDialogRef } from '@cds/ng-web-components/popup';
import { AgentProductSummary } from '../../product-type-summary/product-type-summary.model';

@Component({
  selector: 'app-display-details-by-product-type',
  templateUrl: './display-details-by-product-type.component.html',
  styleUrls: ['./display-details-by-product-type.component.scss'],
})
export class DisplayDetailsByProductTypeComponent implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<DisplayDetailsByProductTypeComponent>,
    @Inject(CDS_POPUP_DATA)
    public data: {
      count: {
        pa: AgentProductSummary;
        tvc: AgentProductSummary;
        frc: AgentProductSummary;
        er: AgentProductSummary;
        sep: AgentProductSummary;
      };
      productType: string;
    }
  ) {}
  clearButton: CdsButtonConfig = {
    size: 'sm',
    style: 'primary',
    disabled: false,
  };
  detailsByProductType: {
    pendingsend?: number;
    pendingcustomer?: number;
    pendingAgent?: number;
  } = { pendingsend: 0, pendingcustomer: 0, pendingAgent: 0 };
  ngOnInit(): void {
    const productType = this.data.productType;
    if (productType === 'PA') {
      this.detailsByProductType = this.data.count.pa;
    } else if (productType === 'TVC') {
      this.detailsByProductType = this.data.count.tvc;
    } else if (productType === 'FRC') {
      this.detailsByProductType = this.data.count.frc;
    } else if (productType === 'ER') {
      this.detailsByProductType = this.data.count.er;
    } else if (productType === 'SEP') {
      this.detailsByProductType = this.data.count.sep;
    }
  }
  gotIt() {
    this.dialogRef.close({ agree: true });
  }
}
