<cds-popup class="popup">
  <cds-popup-content>
    <div class="cds-body1">Audience List:</div>
    <div class="large-title">{{ audienListName }}</div>
    <div class="cds-body2">
      latest csv file name: “<span>{{ fileName }}</span
      >”
    </div>
    <!-- page data -->
    <div>
      <app-loading *ngIf="fileNameLoading || audienceListLoading" [overlayInPopup]="true"></app-loading>
      <div>
        <app-no-result-found *ngIf="dataSource && !dataSource.length; else dataTableData"> </app-no-result-found>

        <ng-template #dataTableData>
          <div style="overflow: auto">
            <cds-table [dataSource]="dataSource || []" class="table" [class.full]="true">
              <!-- Client ID -->
              <ng-container cdsColumnDef="clientId" sticky>
                <cds-header-cell *cdsHeaderCellDef> Client ID </cds-header-cell>
                <cds-cell *cdsCellDef="let element">
                  <div *ngIf="element.clientId !== undefined" class="line-break">
                    {{ element.clientId }}
                  </div>
                  <div *ngIf="element.clientId === undefined" class="space-line"></div>
                </cds-cell>
              </ng-container>

              <cds-header-row *cdsHeaderRowDef="displayedColumns"></cds-header-row>
              <cds-row class="cds-body1" *cdsRowDef="let row; columns: displayedColumns"></cds-row>
            </cds-table>
          </div>
          <!-- pagenation -->
          <div class="col-xs-12 col-md-12 l-pt-4 l-pb-4 l-d-f l-jc-sb">
            <app-pagination
              [pageCount]="pageCount"
              [isLoading]="audienceListLoading"
              [textfieldAlwaysAvailable]="true"
              [useOf]="true"
              (pageChange)="loadTable()">
            </app-pagination>
          </div>
        </ng-template>
      </div>
    </div>
  </cds-popup-content>
</cds-popup>
