<app-loading [overlay]="true" *ngIf="isLoading"></app-loading>
<div class="row l-pt-8">
  <div class="title-img">
    <cds-icon size="md" color="#00A758" icon="people:login_1"></cds-icon>
  </div>
  <div class="flex l-jc-sb">
    <div>
      <div>
        <div class="cds-h4-bold item-title no-margin bold">{{ detailData.lastName }}{{ detailData.firstName }}</div>
        <div class="item-value cds-body1-demibold-margin bold">{{ detailData.lastZhName }}{{ detailData.firstZhName }}</div>
      </div>
      <div class="flex l-ai-cen l-mt-1">
        <div class="dot-icon" [style]="service.getStatusColorStyle | fn : detailData?.status : employeeStatusMapObj"></div>
        <div class="des-text cds-body1 no-margin margin-left-common">
          {{ service.getStatus | fn : detailData.status : employeeStatusMapObj }}
        </div>
      </div>
      <div *ngIf="detailData?.latestUpdate" class="row middle-xs l-plr-0 l-pt-1 cds-body2">
        <span class="l-mr-1">Member Account Code:</span>
        <span class="l-mr-1">
          <span>{{ detailData.memberAccountType }}: </span>
          <span>{{ detailData.memberAccountCode }}</span>
        </span>
      </div>
    </div>
  </div>

  <div class="col-xs-12 submission-container l-mb-7">
    <div class="row">
      <div class="row l-plr-0">
        <div class="title-icon">
          <cds-icon color="#00A758" width="40px" height="40px" icon="form:document"></cds-icon>
        </div>
        <div class="col-xs">
          <div class="row l-plr-0">
            <div class="col-xs-12">
              <div class="l-mb-1">
                <span class="cds-body2">Group Reference No.</span>
              </div>
              <div>
                <span class="cds-h4-demibold weight">
                  {{ detailData.groupReferenceNo }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-xs-12 p-line"></div>
    </div>

    <div class="row">
      <app-row-item label=" " data=" " style="width: 54px"></app-row-item>
      <app-row-item label="ee.unique-transfer-case-no" [data]="detailData.uniqueTransferCaseNo" class="col-xs-6 col-sm-3 col-md-3 col-lg-3"> </app-row-item>
      <app-row-item label="employer.workflow-reference-No" [data]="detailData.workflowReferenceNo" class="col-xs-6 col-sm-3 col-md-3 col-lg-3"> </app-row-item>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-xs-12 col-sm-4 l-plr-0 l-mb-8">
    <cds-dropdown [(ngModel)]="transferReferenceNo" [config]="dropdownConfig" (cdsChange)="onSelectChange($event)"></cds-dropdown>
  </div>
</div>

<div class="row">
  <div class="col-xs-12 l-plr-0">
    <span class="cds-body-hero">Transfer Information</span>
  </div>
</div>

<div class="row">
  <div class="col-xs-12 information-container l-mb-8">
    <div class="row">
      <div class="row l-plr-0">
        <div class="">
          <cds-icon color="#00A758" width="20px" height="20px" icon="finance:transfer_of_existing_assets"></cds-icon>
        </div>
        <div>
          <div class="row l-plr-0">
            <div class="col-xs-12">
              <div class="l-mb-1">
                <span class="cds-body1-demibold green-title">Transfer-In</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-xs-12 p-line"></div>
    </div>

    <div class="row">
      <div class="col-xs-12 l-pt-2">
        <span class="cds-body1-bold">{{ 'payroll.basic-information' | lang }}</span>
      </div>
    </div>

    <div class="row">
      <div class="col-xs-12 p-line"></div>
    </div>

    <div class="row">
      <app-row-item label="ee.transfer-status" [data]="statusCodeRender | fn : detailData.statusCode" class="col-xs-6 col-sm-3 col-md-3 col-lg-3">
      </app-row-item>
      <app-row-item
        label="ee.form-transaction-form-type"
        [data]="fundTransactionFormTypeRender | fn : detailData.fundTransactionFormType"
        class="col-xs-6 col-sm-3 col-md-3 col-lg-3">
      </app-row-item>
      <app-row-item
        label="ee.pm-transfer-option"
        [data]="pmTransferOptionTypeRender | fn : detailData.pmTransferOption"
        class="col-xs-6 col-sm-3 col-md-3 col-lg-3">
      </app-row-item>
      <app-row-item
        label="ee.pp-transfer-option"
        [data]="ppTransferOptionTypeRender | fn : detailData.ppTransferOption"
        class="col-xs-6 col-sm-3 col-md-3 col-lg-3">
      </app-row-item>
    </div>

    <div class="row l-mt-6 table-container">
      <cds-table [dataSource]="bankInformationTableData" style="width: 1063px" class="table">
        <!-- Submission Type -->
        <ng-container cdsColumnDef="submissionType">
          <cds-header-cell *cdsHeaderCellDef> Submission Type </cds-header-cell>
          <cds-cell *cdsCellDef="let element" class="line-break">{{ submissionTypeRender | fn : element.submissionType }}</cds-cell>
        </ng-container>

        <!-- Date of Transfer -->
        <ng-container cdsColumnDef="dateOfTransfer">
          <cds-header-cell *cdsHeaderCellDef> Date of Transfer </cds-header-cell>
          <cds-cell *cdsCellDef="let element" class="line-break">{{ element.dateOfTransfer | ssmsDate }}</cds-cell>
        </ng-container>

        <!-- Original Requested Submission Date and Time -->
        <ng-container cdsColumnDef="originalRequestedSubmissionDate">
          <cds-header-cell *cdsHeaderCellDef> Original Requested Submission Date and Time </cds-header-cell>
          <cds-cell *cdsCellDef="let element" class="line-break">{{ element.originalRequestedSubmissionDate | ssmsDate : 'dd/MM/yyyy, hh:mm:ss' }}</cds-cell>
        </ng-container>

        <!-- Sub-Account Type -->
        <ng-container cdsColumnDef="subAccountType">
          <cds-header-cell *cdsHeaderCellDef> Sub-Account Type </cds-header-cell>
          <cds-cell *cdsCellDef="let element" class="line-break">{{ subAccountTypeRender | fn : element.subAccountType }}</cds-cell>
        </ng-container>

        <!-- Intra- group Transaction Flag -->
        <ng-container cdsColumnDef="intraGroupTransactionFlag">
          <cds-header-cell *cdsHeaderCellDef> Intra- group Transaction Flag </cds-header-cell>
          <cds-cell *cdsCellDef="let element" class="line-break">
            <cds-checkbox [disabled]="true" [checked]="element.intraGroupTransactionFlag === 'Y'"></cds-checkbox>
          </cds-cell>
        </ng-container>

        <!-- Complete Document Receipt Date -->
        <ng-container cdsColumnDef="completeDocumentReceiptDate">
          <cds-header-cell *cdsHeaderCellDef> Complete Document Receipt Date </cds-header-cell>
          <cds-cell *cdsCellDef="let element" class="line-break">{{ element.completeDocumentReceiptDate | ssmsDate }}</cds-cell>
        </ng-container>

        <!-- Transfer Last Follow-up Date -->
        <ng-container cdsColumnDef="transferLastFollowUpDate">
          <cds-header-cell *cdsHeaderCellDef> Transfer Last Follow-up Date </cds-header-cell>
          <cds-cell *cdsCellDef="let element" class="line-break">{{ element.transferLastFollowUpDate | ssmsDate }}</cds-cell>
        </ng-container>

        <cds-header-row *cdsHeaderRowDef="displayedColumns"></cds-header-row>
        <cds-row class="cds-body1" *cdsRowDef="let row; columns: displayedColumns"></cds-row>
      </cds-table>
    </div>

    <div class="row">
      <div class="col-xs-12 p-line"></div>
    </div>

    <div class="row">
      <div class="col-xs-12 l-pt-2">
        <span class="cds-body1-bold">{{ 'ee.transfer-in-remarks' | lang }}</span>
      </div>
    </div>

    <div class="row">
      <div class="col-xs-12 p-line"></div>
    </div>

    <div class="row">
      <app-row-item label="ee.transfer-out-member-account-code" [data]="detailData.transferOutMemberAccountCode" class="col-xs-6 col-sm-3 col-md-3 col-lg-3">
      </app-row-item>
      <app-row-item label="ee.transfer-out-scheme-names" [data]="detailData.transferOutSchemeName" class="col-xs-6 col-sm-3 col-md-3 col-lg-3"> </app-row-item>
      <app-row-item
        label="ee.transfer-out-scheme-registration-no"
        [data]="detailData.transferOutSchemeRegistrationNumber"
        class="col-xs-6 col-sm-3 col-md-3 col-lg-3">
      </app-row-item>
      <app-row-item label="ee.original-scheme" [data]="detailData.transferOutOriginalScheme" class="col-xs-6 col-sm-3 col-md-3 col-lg-3"> </app-row-item>
    </div>

    <div class="row">
      <div class="col-xs-12 l-pt-2">
        <span class="cds-body1-bold">{{ 'ee.rejection-information' | lang }}</span>
      </div>
    </div>

    <div class="row">
      <div class="col-xs-12 p-line"></div>
    </div>

    <div class="row l-mt-6 table-container">
      <cds-table [dataSource]="rejectReasonTableData" style="width: 1063px" class="table">
        <!-- Rejection Reason -->
        <ng-container cdsColumnDef="rejectionReason">
          <cds-header-cell *cdsHeaderCellDef>{{ 'ee.rejection-reason' | lang }}</cds-header-cell>
          <cds-cell *cdsCellDef="let element" class="line-break">{{ element.rejectionReason || ' ' }}</cds-cell>
        </ng-container>

        <cds-header-row *cdsHeaderRowDef="rejectReasondisplayedColumns"></cds-header-row>
        <cds-row class="cds-body1" *cdsRowDef="let row; columns: rejectReasondisplayedColumns"></cds-row>
      </cds-table>
    </div>

    <div class="row l-mt-4">
      <app-row-item label="ee.remark-of-rejection-reason" [data]="detailData.remarkOfRejectionReason" class="col-xs-12"> </app-row-item>
      <app-row-item label="ee.reply-type" [data]="replyTypeRender | fn : detailData.replyType" class="col-xs-12"> </app-row-item>
    </div>
  </div>
</div>
