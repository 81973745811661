/* eslint-disable @angular-eslint/use-lifecycle-interface */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, ElementRef, HostListener, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { CdsButtonConfig } from '@cds/ng-core/button';
import { CdsDropdownConfig } from '@cds/ng-core/dropdown';
import { CdsIconConfig } from '@cds/ng-core/icon';
import { finalize } from 'rxjs';
import { IPaginationCount } from 'src/app/shared/pagination/pagination.dto';
import { ToastAlertService } from 'src/app/shared/toast-alert.service';
import { jsonToSheet } from 'src/app/utils/xlsx';

import { Sort } from '@cds/ng-data-table/sort';
import {
  getColor,
  formatDate2,
  formatReason,
  PaymentMethodE,
  PaymentStatusSelected,
  PaymentStatusE,
  pageInit,
  ChequeSearchDTO,
  BaseChequeSearch,
  ChequeDTO,
  ChequeSearch,
  formatDate,
} from '../../benefit-payment';
import { PaymentServicePTW } from '../../paymentPTW.service';

@Component({
  selector: 'app-bank-transfer-ptw',
  templateUrl: './bank-transfer-ptw.component.html',
  styleUrls: ['./bank-transfer-ptw.component.scss'],
})
export class BankTransferPTWComponent implements OnInit {
  constructor(private service: PaymentServicePTW, private toastAlert: ToastAlertService, private elemRef: ElementRef) {}

  @ViewChild('tableBox', { static: false })
  tableBox?: ElementRef<HTMLDivElement>;

  getColor = getColor;
  formatDate = formatDate;
  formatDate2 = formatDate2;
  formatReason = formatReason;

  transferTypeConfig: CdsDropdownConfig = {
    label: 'Choose Bank Transfer Type',
    placeholder: 'Select Payment Method',
    options: [
      { label: 'Local Bank Transfer', value: PaymentMethodE.BTL },
      { label: 'Oversea  Bank Transfer', value: PaymentMethodE.BTO },
    ],
  };

  _paymentStatusList: PaymentStatusSelected[] = [
    { label: 'Completed', value: PaymentStatusE.COMPLETED },
    { label: 'New', value: PaymentStatusE.NEW, selected: true },
    { label: 'Passed to Bank', value: PaymentStatusE.PASSED_TO_BANK },
    { label: 'Pending', value: PaymentStatusE.PENDING },
    { label: 'Ready to reissue', value: PaymentStatusE.READY_TO_REISSUE },
    { label: 'Reissue', value: PaymentStatusE.REISSUE },
    { label: 'Reissue Reject', value: PaymentStatusE.REISSUE_REJECT },
    { label: 'Rejected', value: PaymentStatusE.REJECTED },
  ];

  pageSelectSize: number[] = [10, 20, 50, 100];
  _pageSelected = 1;
  pageCount: IPaginationCount = pageInit();
  searchDTO: ChequeSearchDTO = {
    size: this.pageSelectSize[this._pageSelected],
    page: 0,
    payMethod: PaymentMethodE.BTL,
  };
  isMobile = false;
  notfoundConfig: CdsIconConfig = { color: '#8e90a2', size: 'md' };

  _totalCount = 0;
  _progress = false;
  _downloading = false;

  displayedColumns: string[] = [
    'selected',
    'payeeName',
    'paymentStatus',
    'amt',
    'feedReceiveDate',
    'isReissue',
    'reissueReason',
    'payRefNo',
    'appRefNo',
    'exptPayIssue',
    'chequeIssueDate',
    'lastUpdateDate',
  ];
  sheetHeader = {
    payeeName: 'Payee Name',
    recordStatus: 'Payment Status',
    curr: 'Currency',
    amt: 'Amount',
    feedReceiveDate: 'Payment Instruction File Date',
    isReissue: 'Reissuance',
    reissueReason: 'Reissurance Reason',
    payRefNo: 'Payment Ref No.',
    appRefNo: 'Application Reference No.',
    exptPayIssue: '	Expected Payment Issue Date on Statement',
    chequeIssueDate: 'Bank Value Date',
    lastUpdateDate: 'Status Update Date',
  };

  exportAllButtonConfig: CdsButtonConfig = {
    size: 'sm',
    style: 'secondary',
  };
  exportSelectedButtonConfig: CdsButtonConfig = {
    size: 'sm',
    style: 'secondary',
  };

  _selectedAll = false;

  @Input() baseParam?: BaseChequeSearch;
  @Input() search?: number;

  dataSource: ChequeDTO[] = [];
  _checkedDatasourceMap: Map<string, ChequeDTO> = new Map();
  scrollBarWidth = '15px';

  _sortString = '';

  ngOnInit(): void {
    // this.loadTable();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['search'] && !changes['search'].firstChange) {
      this.pageCount = pageInit();
      if (Number(changes['search'].currentValue) > 0) {
        this._paymentStatusList.forEach(x => (x.selected = false));
      }
      this.loadTable();
    }
  }

  onSortChange(event: Sort) {
    if (event.direction) {
      this._sortString = event.active + ',' + event.direction;
    } else {
      this._sortString = '';
    }
    this.loadTable();
  }

  resetStatus() {
    this._paymentStatusList.forEach(x => (x.selected = false));
    this.loadTable();
  }

  typeChange(val: PaymentMethodE) {
    this.searchDTO.payMethod = val;
    this.loadTable();
  }

  // reloadTable(event: any) {
  //   // this.pageConfig = {
  //   //   ...this.pageConfig,
  //   //   current: event.current,
  //   // };
  //   this.loadTable();
  // }

  sizeSelect(index: number) {
    this._pageSelected = index;
    this.searchDTO.size = this.pageSelectSize[index];
    this.pageCount = pageInit();
    // this.pageConfig.pageSize = this.pageSelectSize[index];
    this.loadTable();
  }

  getPaymentStatus = (value: string) => {
    let label = '';
    if (!value) return label;
    const obj = this._paymentStatusList.find(x => x.value === value);
    label = obj?.label || '';
    return label;
  };

  trigger(index: number) {
    this.dataSource[index].checked = !this.dataSource[index].checked;
    this.selectButtonsDisableRefresh();
  }
  triggerAll() {
    this._selectedAll = !this._selectedAll;
    this.dataSource.forEach(item => {
      item.checked = this._selectedAll;
    });
    this.selectButtonsDisableRefresh();
  }
  selectButtonsDisableRefresh() {
    const _checkedList = this.dataSource.filter(item => item.checked);
    // if(_checkedList.length == 0){
    //   return
    // }
    this._checkedDatasourceMap = new Map();
    _checkedList.forEach(item => {
      this._checkedDatasourceMap.set(item.id, item);
    });
    // const _length = _checkedList.length > 0;
    // this._stopPaymentDisable = !(_length && _checkedList.filter(item => item.recordStatus == PaymentStatusE.HOLD_PAYMENT).length == _checkedList.length);
    // this._completePaymentDisable = !(_length && _checkedList.filter(item => item.recordStatus == PaymentStatusE.STOP_PAYMENT).length == _checkedList.length);
    // this.editSelectedDisabled = !(
    //   _length && _checkedList.filter(item => item.recordStatus == PaymentStatusE.HOLD_PAYMENT || PaymentStatusE.STOP_PAYMENT).length == _checkedList.length
    // );
    // this.exportSelectedDisabled = !_length;
  }

  exportSelected() {
    this._downloading = true;
    const data = this.dataSource.filter(item => item.checked);

    jsonToSheet(
      {
        fileName: 'SHK Cheque Printing Record List',
        fileType: 'csv',
        header: this.sheetHeader,
      },
      this.createData(data)
    );
    this._downloading = false;
  }

  exportAll() {
    if (!this.dataSource.length) {
      return;
    }
    const params: ChequeSearch = this.getRequestParams();
    const _param: ChequeSearchDTO = {
      ...this.searchDTO,
      ...params,
      page: 0,
      size: 12000,
    };
    _param.payMethod = PaymentMethodE.BTL;
    this._progress = true;
    this.service
      .getPaymentIssuance(_param)
      .pipe(
        finalize(() => {
          this._progress = false;
        })
      )
      .subscribe(res => {
        if (res.result === 0 && res.data?.content) {
          jsonToSheet(
            {
              fileName: 'SHK Cheque Printing Record List',
              fileType: 'csv',
              header: this.sheetHeader,
            },
            this.createData(res.data.content)
          );
        } else {
          this.toastError('no ready to print data');
        }
      });
  }

  createData(data: ChequeDTO[]) {
    return data.map(item => {
      return {
        payeeName: item.payeeName,
        recordStatus: item.recordStatus,
        curr: item.curr,
        amt: item.amt,
        feedReceiveDate: this.formatDate2(item.feedReceiveDate),
        isReissue: item.isReissue,
        reissueReason: this.formatReason(item.reissueReason + ''),
        payRefNo: item.payRefNo,
        appRefNo: item.appRefNo,
        exptPayIssue: this.formatDate2(item.exptPayIssue),
        chequeIssueDate: this.formatDate2(item.chequeIssueDate),
        lastUpdateDate: this.formatDate2(item.lastUpdateDate),
      };
    });
  }

  getRequestParams() {
    const params: ChequeSearchDTO = this.searchDTO;
    params.recordStatusList = this._paymentStatusList.filter(x => x.selected).map(x => x.value);
    params.payRefNo = this.baseParam?.payRefNo;
    params.appRefNo = this.baseParam?.appRefNo;
    params.instructionFileDateFrom = this.formatDate(this.baseParam?.instructionFileDateFrom);
    params.instructionFileDateTo = this.formatDate(this.baseParam?.instructionFileDateTo);
    params.payeeName = this.baseParam?.payeeName;
    params.isReissue = this.baseParam?.isReissue;
    if (!params.isReissue) {
      delete params.isReissue;
    }
    if (!params.payMethod) {
      params.payMethod = PaymentMethodE.BTL;
    }
    return params;
  }

  getList(params: ChequeSearchDTO) {
    this._progress = true;
    this.service
      .getPaymentIssuance(params)
      .pipe(
        finalize(() => {
          this._progress = false;
        })
      )
      .subscribe(data => {
        if (data.result === 0 && data.data) {
          this.dataSource = data.data.empty ? [] : data.data.content;
          this._totalCount = data.data.totalElements;
          if (this.pageCount.current != data.data.number + 1) {
            this.pageCount.current = data.data.number + 1;
          }
          if (this.pageCount.count !== data.data.totalPages) {
            this.pageCount.count = data.data.totalPages;
          }
          // this.pageConfig = {
          //   ...this.pageConfig,
          //   totalCount: data.data.totalElements,
          // };
          // console.log(this.pageConfig);
        } else {
          this.toastError(data.message);
        }
      });
  }

  toastError(errorMessage: string) {
    this.toastAlert.show('error', 'common.error', errorMessage, 5000);
  }

  loadTable() {
    const params: ChequeSearch = this.getRequestParams();
    const listParms: ChequeSearchDTO = {
      ...this.searchDTO,
      ...params,
      page: this.pageCount.current - 1,
      sort: this._sortString,
      // size: this.pageConfig.pageSize ? this.pageConfig.pageSize : 10,
    };

    this.getList(listParms);
  }

  paymentStatusSelecte(index: number) {
    this.pageCount = pageInit();
    this._paymentStatusList[index].selected = !this._paymentStatusList[index].selected;
    this.loadTable();
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    if (window.innerWidth < 1024) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
    const contentWidth = this.elemRef.nativeElement.getElementsByClassName('row')[0].offsetWidth;
    const screenWidth = document.body.clientWidth;

    this.scrollBarWidth = screenWidth - contentWidth - (this.isMobile ? 0 : 80) + 'px';
  }

  get checkIsHaveScroll() {
    if (this.tableBox) {
      return this.tableBox.nativeElement.scrollWidth > this.tableBox.nativeElement.clientWidth;
    }
    return false;
  }
}
