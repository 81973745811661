<div class="cd-step">
  <div class="l-d-f l-ai-cen">
    <div [ngClass]="['spherical', 'cds-h6-demibold', finishStatus ? 'finish' : '']">
      {{ label }}
    </div>
    <div [ngClass]="['l-ml-3']">
      <ng-content></ng-content>
    </div>
  </div>
</div>
