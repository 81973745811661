<div class="resend-eiff">
  <cds-icon class="close-icon" icon="action:cross" (click)="close()"></cds-icon>
  <div class="resend-eiff-popup">
    <div class="popup-content">
      <span class="cds-h2-demibold" *ngIf="currentStep === 1">{{ 'resend-eiff.resendIff' | lang }}</span>
      <span class="cds-h2-demibold" *ngIf="currentStep === 2">{{ 'resend-eiff.resendIffConfirmation' | lang }}</span>
      <div>
        <div class="l-mt-5">
          <app-matters-needing-attention [color]="'red'" [title]="'resend-eiff.importantNotes' | lang">
            <ul class="l-mt-0 l-mb-0">
              <li>
                <span class="cds-detail1">{{ 'iff.splitAgentNote1' | lang }}</span>
              </li>
              <li>
                <span class="cds-detail1">{{ 'iff.splitAgentNote2' | lang }}</span>
              </li>
              <li>
                <span class="cds-detail1">{{ 'iff.splitAgentNote3HeadPart' | lang }}</span>
                <span class="cds-detail1 red-text">{{ 'iff.splitAgentNote3RedPart' | lang }}</span>
                <span class="cds-detail1">{{ 'iff.splitAgentNoteTailPart' | lang }}</span>
              </li>
            </ul>
          </app-matters-needing-attention>
        </div>

        <div class="item" style="margin-top: 1.25rem">
          <div style="flex: 1">
            <span class="cds-body2-demibold">{{ 'resend-eiff.eMPF Reference No.' | lang }} :</span>
          </div>
          <div style="flex: 2">
            <span class="cds-body2-demibold">{{ resendInfo?.empfRefNo }}</span>
          </div>
        </div>
        <div class="item" style="margin-top: 1.125rem">
          <div style="flex: 1"></div>
          <div class="l-d-f l-ai-cen" style="flex: 2">
            <cds-checkbox [disabled]="true" [checked]="resendInfo?.regulatedAct || false"></cds-checkbox>
            <div class="l-ml-4">
              <span class="cds-detail2">{{ 'sendIffInBatchSummary.havePerformedMPF' | lang }}</span>
            </div>
          </div>
        </div>
        <div *ngIf="data.resendType === ResendType.EE" class="item l-mt-5">
          <div style="flex: 1">
            <span class="cds-body2-demibold">{{ 'iff.hKIDPassportNo' | lang }} :</span>
          </div>
          <div style="flex: 2">
            <span *ngIf="resendInfo" class="cds-body2-demibold">{{ resendInfo.hkidOrPassportNo }}</span>
          </div>
        </div>

        <ng-container *ngIf="currentStep === 2">
          <div *ngIf="data.resendType === ResendType.EE" class="item l-mt-5">
            <div style="flex: 1">
              <span class="cds-body2-demibold">{{ 'sendIffInBatchSummary.emailAndPhone' | lang }} :</span>
            </div>
            <div style="flex: 2">
              <div *ngIf="emailAndPhoneValue === 1">
                {{ emailAddressFormControl.value }}
              </div>
              <div *ngIf="emailAndPhoneValue === 2">
                {{ '(' + countrycodeFormControl.value + ')' + phoneNumberFormControl.value }}
              </div>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="currentStep === 1">
          <div *ngIf="data.resendType === ResendType.EE" class="item l-mt-5">
            <div style="flex: 1">
              <span class="cds-body2-demibold">{{ 'sendIffInBatchSummary.emailAndPhone' | lang }} :</span>
            </div>
            <div style="flex: 2">
              <app-cd-radio
                #cdRadio
                class="email-and-phone-radio"
                [config]="emailAndPhoneConfig"
                [(value)]="emailAndPhoneValue"
                (valueChange)="emailAndPhoneValueChange($event)"></app-cd-radio>
            </div>
          </div>
          <div *ngIf="data.resendType === ResendType.EE" class="item l-mt-5">
            <div style="flex: 1"></div>
            <div style="flex: 2">
              <div style="position: relative" *ngIf="emailAndPhoneValue === 1">
                <app-cd-textfield
                  ngDefaultControl
                  class="email-address"
                  [disable]="isReadOnlyEmailAndPhone || isReadOnlyEmailForNull"
                  [placeholder]="'common.email-address' | lang"
                  [formControl]="emailAddressFormControl"></app-cd-textfield>
                <cds-assistive-text class="email-address-fail" *ngIf="emailAddressFormControlValidatorFail">{{
                  'common.please-input-valid-customer-email' | lang
                }}</cds-assistive-text>
                <cds-assistive-text class="email-address-fail" *ngIf="isShowPleaseInputCustomerEmail">{{
                  'common.please-input-customer-email' | lang
                }}</cds-assistive-text>
              </div>
              <div style="position: relative" *ngIf="emailAndPhoneValue === 2" class="l-d-f">
                <cds-dropdown [formControl]="countrycodeFormControl" [config]="countrycodeConfig" ngDefaultControl></cds-dropdown>
                <div class="phone-number">
                  <app-cd-textfield
                    ngDefaultControl
                    [disable]="isReadOnlyEmailAndPhone || isReadOnlyPhoneForNull"
                    [placeholder]="'common.phone-number' | lang"
                    [formControl]="phoneNumberFormControl"
                    [type]="'number'"></app-cd-textfield>
                  <cds-assistive-text class="phone-number-fail" *ngIf="phoneNumberFormControlValidatorFail">{{
                    'common.please-input-valid-mobile-number' | lang
                  }}</cds-assistive-text>
                  <cds-assistive-text class="phone-number-fail" *ngIf="isShowPleaseInputPhoneNumber">{{
                    'common.please-input-customer-mobile-number' | lang
                  }}</cds-assistive-text>
                </div>
              </div>
            </div>
          </div>
        </ng-container>

        <div *ngIf="data.resendType === ResendType.ER && resendInfo?.companyName" class="item l-mt-5">
          <div style="flex: 1">
            <span class="cds-body2-demibold">{{ 'case-details-er.employer-name' | lang }} :</span>
          </div>
          <div style="flex: 2">
            <span class="cds-body2-demibold">{{ resendInfo?.companyName }}</span>
          </div>
        </div>
        <div *ngIf="data.resendType === ResendType.ER && resendInfo?.brNo" class="item l-mt-5">
          <div style="flex: 1">
            <span class="cds-body2-demibold">{{ 'sendIffInBatchSummary.eRNumber' | lang }} :</span>
          </div>
          <div style="flex: 2">
            <span class="cds-body2-demibold">{{ resendInfo?.brNo }}</span>
          </div>
        </div>
        <div
          *ngIf="
            data.resendType === ResendType.ER &&
            data.empfStatus !== 0 &&
            (isReadOnlyAuthorizedPersonName || (!isReadOnlyAuthorizedPersonName && resendInfo?.authorizedPersonNameControl))
          "
          class="item l-mt-5">
          <div style="flex: 1">
            <span class="cds-body2-demibold">{{ 'sendIffInBatchSummary.authorizedPersonName' | lang }} :</span>
          </div>
          <div style="flex: 2" *ngIf="resendInfo">
            <span *ngIf="isReadOnlyAuthorizedPersonName" class="cds-body2-demibold">{{ resendInfo.lastName + ' ' + resendInfo.firstName }}</span>
            <cds-dropdown
              *ngIf="!isReadOnlyAuthorizedPersonName && resendInfo.authorizedPersonNameControl"
              [disabled]="currentStep === 2"
              (ngModelChange)="authorizedPersonNameControlChange()"
              style="width: 88%"
              [formControl]="resendInfo.authorizedPersonNameControl"
              [config]="resendInfo.authorizedPersonNameConfig"
              ngDefaultControl></cds-dropdown>
          </div>
        </div>

        <ng-container *ngIf="currentStep === 1">
          <div *ngIf="data.resendType === ResendType.ER" class="item l-mt-5">
            <div style="flex: 1">
              <span class="cds-body2-demibold">{{ 'sendIffInBatchSummary.apEmailPhone' | lang }} :</span>
            </div>
            <div style="flex: 2">
              <app-cd-radio
                #cdRadio
                class="email-and-phone-radio"
                [config]="emailAndPhoneConfig"
                [(value)]="emailAndPhoneValue"
                (valueChange)="emailAndPhoneValueChange($event)"></app-cd-radio>
            </div>
          </div>

          <div *ngIf="data.resendType === ResendType.ER" class="item l-mt-5">
            <div style="flex: 1"></div>
            <div style="flex: 2">
              <div style="position: relative" *ngIf="emailAndPhoneValue === 1">
                <app-cd-textfield
                  ngDefaultControl
                  class="email-address"
                  [placeholder]="'common.email-address' | lang"
                  [disable]="isReadOnlyEmailAndPhone || isReadOnlyEmailForNull"
                  [formControl]="emailAddressFormControl"></app-cd-textfield>
                <cds-assistive-text class="email-address-fail" *ngIf="emailAddressFormControlValidatorFail">{{
                  'common.please-input-valid-customer-email' | lang
                }}</cds-assistive-text>
                <cds-assistive-text class="email-address-fail" *ngIf="isShowPleaseInputCustomerEmail">{{
                  'common.please-input-customer-email' | lang
                }}</cds-assistive-text>
              </div>
              <div style="position: relative" *ngIf="emailAndPhoneValue === 2" class="l-d-f">
                <cds-dropdown
                  [disabled]="isReadOnlyEmailAndPhone || isReadOnlyPhoneForNull"
                  [formControl]="countrycodeFormControl"
                  [config]="countrycodeConfig"
                  ngDefaultControl></cds-dropdown>
                <div class="phone-number">
                  <app-cd-textfield
                    ngDefaultControl
                    [disable]="isReadOnlyEmailAndPhone || isReadOnlyPhoneForNull"
                    [placeholder]="'common.phone-number' | lang"
                    [formControl]="phoneNumberFormControl"
                    [type]="'number'"></app-cd-textfield>
                  <cds-assistive-text class="phone-number-fail" *ngIf="phoneNumberFormControlValidatorFail">{{
                    'common.please-input-valid-mobile-number' | lang
                  }}</cds-assistive-text>
                  <cds-assistive-text class="phone-number-fail" *ngIf="isShowPleaseInputPhoneNumber">{{
                    'common.please-input-customer-mobile-number' | lang
                  }}</cds-assistive-text>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="currentStep === 2">
          <div *ngIf="data.resendType === ResendType.ER" class="item l-mt-5">
            <div style="flex: 1">
              <span class="cds-body2-demibold">{{ 'sendIffInBatchSummary.apEmailPhone' | lang }} :</span>
            </div>
            <div style="flex: 2">
              <div *ngIf="emailAndPhoneValue === 1">
                {{ emailAddressFormControl.value }}
              </div>
              <div *ngIf="emailAndPhoneValue === 2">
                {{ '(' + countrycodeFormControl.value + ')' + phoneNumberFormControl.value }}
              </div>
            </div>
          </div>
        </ng-container>

        <div class="item l-mt-4">
          <div style="flex: 1"></div>
          <div style="flex: 2">
            <span *ngIf="currentStep === 1" class="cds-detail2" style="color: var(--cds-text-main)">{{ 'resend-eiff.pleaseEnsure' | lang }}</span>
            <cds-assistive-text *ngIf="currentStep === 2" type="success">{{ 'resend-eiff.ensureTheContact' | lang }}</cds-assistive-text>
          </div>
        </div>

        <div class="item l-mt-5">
          <ng-container *ngIf="currentStep === 1">
            <div style="flex: 1">
              <div>
                <span class="cds-body2-demibold"> {{ 'sendIffInBatchSummary.preferredLanguage' | lang }}: </span>
              </div>
            </div>
            <div style="flex: 2">
              <app-cd-radio
                class="cd-radio-margin-bottom-clear"
                #cdRadioPreferredLanguage
                [(value)]="preferredLanguage"
                [config]="preferredLanguageConfig"></app-cd-radio>
            </div>
          </ng-container>
          <ng-container *ngIf="currentStep === 2 && preferredLanguage">
            <div style="flex: 1">
              <div>
                <span class="cds-body2-demibold"> {{ 'sendIffInBatchSummary.receivingLanguage' | lang }}: </span>
              </div>
            </div>
            <div style="flex: 2">
              <div *ngIf="preferredLanguage === PreferredLanguageType.Chinese">
                <span class="cds-body2-demibold">{{ 'common.chinese' | lang }}</span>
              </div>
              <div *ngIf="preferredLanguage === PreferredLanguageType.English">
                <span class="cds-body2-demibold">{{ 'common.english' | lang }}</span>
              </div>
            </div>
          </ng-container>
        </div>

        <ng-container *ngIf="isChannelDBS && dbs">
          <div class="item l-mt-6">
            <div style="flex: 1">
              <div>
                <span class="cds-body2-bold">RM Referral Code:</span>
              </div>
            </div>
            <div style="flex: 2">
              <span *ngIf="currentStep === 2">{{ dbs.dbsRmReferralCd.value || '-' }}</span>
              <app-cd-input
                *ngIf="currentStep === 1"
                [type]="'number'"
                [formControl]="dbs.dbsRmReferralCd"
                [maxlength]="8"
                ngDefaultControl
                [placeholder]="'Input RM Referral Code'"></app-cd-input>
              <div class="l-mt-1">
                <cds-assistive-text *ngIf="dbs.dbsRmReferralCd.dirty && dbs.dbsRmReferralCd.hasError('required'); else second" type="error"
                  >Please input valid RM Referral Code
                </cds-assistive-text>
                <ng-template #second>
                  <cds-assistive-text *ngIf="dbs.dbsRmReferralCd.dirty && dbs.dbsRmReferralCd.hasError('lengthError')" type="error"
                    >Please input valid RM Referral Code
                  </cds-assistive-text>
                </ng-template>
              </div>
            </div>
          </div>
          <div class="item l-mt-6">
            <div style="flex: 1">
              <div class="cds-body2-bold">Customer ID:</div>
            </div>
            <div style="flex: 2">
              <span *ngIf="currentStep === 2">{{ dbs.dbsCustomerId.value || '-' }}</span>
              <app-cd-input
                *ngIf="currentStep === 1"
                [formControl]="dbs.dbsCustomerId"
                [maxlength]="8"
                [regExpLimit]="'^[A-Za-z0-9-]*$'"
                ngDefaultControl
                [placeholder]="'Input Customer ID'"></app-cd-input>
              <div class="l-mt-1">
                <cds-assistive-text *ngIf="dbs.dbsCustomerId.dirty && dbs.dbsCustomerId.hasError('lengthError')" type="error"
                  >Please input valid Customer ID
                </cds-assistive-text>
              </div>
            </div>
          </div>
          <div class="item l-mt-6">
            <div style="flex: 1">
              <div class="cds-body2-bold">Campaign Code:</div>
            </div>
            <div style="flex: 2">
              <span *ngIf="currentStep === 2">{{ dbs.dbsCampaignCd.value || '-' }}</span>
              <app-cd-input
                *ngIf="currentStep === 1"
                [formControl]="dbs.dbsCampaignCd"
                [maxlength]="10"
                [regExpLimit]="'^[A-Za-z0-9-]*$'"
                ngDefaultControl
                [placeholder]="'Input Campaign Code'"></app-cd-input>
              <div class="l-mt-1">
                <cds-assistive-text *ngIf="dbs.dbsCampaignCd.dirty && dbs.dbsCampaignCd.hasError('lengthError')" type="error"
                  >Please input valid Campaign Code
                </cds-assistive-text>
              </div>
            </div>
          </div>
          <div class="item l-mt-6">
            <div style="flex: 1">
              <div class="cds-body2-bold">Source Code:</div>
            </div>
            <div style="flex: 2">
              <span *ngIf="currentStep === 2">{{ dbs.dbsSourceCd.value || '-' }}</span>
              <app-cd-input
                *ngIf="currentStep === 1"
                [formControl]="dbs.dbsSourceCd"
                [maxlength]="10"
                [regExpLimit]="'^[A-Za-z0-9-]*$'"
                ngDefaultControl
                [placeholder]="'Input Source Code'"></app-cd-input>
              <div class="l-mt-1">
                <cds-assistive-text *ngIf="dbs.dbsSourceCd.dirty && dbs.dbsSourceCd.hasError('lengthError')" type="error"
                  >Please input valid Source Code
                </cds-assistive-text>
              </div>
            </div>
          </div>
          <div class="item l-mt-4">
            <div class="l-pl-3">
              <span class="cds-detail2" style="color: var(--cds-color-dark-1-coral)">*請確保資料與客戶在eMPF上提供的資料一致 </span>
            </div>
          </div>
        </ng-container>

        <div class="l-mt-7 l-mb-6" *ngFor="let item of resendInfo?.agentInfos; let i = index">
          <div>
            <span class="cds-h4">{{
              'resend-eiff.informationOnMPFIntermediaries'
                | lang
                  : {
                      p1: ['resend-eiff.second', 'resend-eiff.third'][i] | lang
                    }
            }}</span>
          </div>
          <div class="line l-mt-2"></div>
          <div class="item l-mt-5">
            <div style="flex: 1">
              <span class="cds-body2-demibold">{{ (i === 0 ? 'iff.secondAgentCode' : 'iff-er.thirdAgentCode') | lang }} :</span>
            </div>
            <div style="flex: 2">
              <span class="cds-body2-demibold">{{ item.agentCd }}</span>
            </div>
          </div>
          <div class="item l-mt-5">
            <div style="flex: 1">
              <span class="cds-body2-demibold">{{ (i === 0 ? 'iff.secondAgentName' : 'iff-er.thirdAgentName') | lang }} :</span>
            </div>
            <div style="flex: 2">
              <span class="cds-body2-demibold">{{ getAgentName(item) }}</span>
            </div>
          </div>
          <div class="l-d-f l-ai-cen l-mt-5">
            <cds-checkbox [disabled]="true" [checked]="item.regulatedAct"></cds-checkbox>
            <div class="l-ml-4">
              <span class="cds-detail2">{{ 'sendIffInBatchSummary.havePerformedMPF' | lang }}</span>
            </div>
          </div>
          <div *ngIf="item.regulatedAct" class="item l-mt-5">
            <div style="flex: 1">
              <span class="cds-body2-demibold" style="display: block; width: 68%">{{ 'resend-eiff.chooseAndMakeDeclaration' | lang }}</span>
            </div>
            <div style="flex: 2">
              <ng-container *ngIf="currentStep === 1">
                <app-cd-radio
                  *ngIf="item.channelConfig"
                  class="contact-radio"
                  [config]="item.channelConfig"
                  [(value)]="item.channelValue"
                  (valueChange)="agentInfosChannelValueChange(i, $event)"
                  [orientation]="'vertical'"></app-cd-radio>
                <app-cd-radio *ngIf="!item.channelConfig" class="contact-radio" [config]="disableChannelConfig" [orientation]="'vertical'"></app-cd-radio>
              </ng-container>
              <ng-container *ngIf="currentStep === 2">
                <span>{{ getChannel(item.channelConfig, item.channelValue) }}</span>
              </ng-container>
            </div>
          </div>
          <div *ngIf="item.regulatedAct" class="item l-mt-5">
            <ng-container *ngIf="i === 0">
              <ng-container *ngIf="currentStep === 1">
                <div style="flex: 1">
                  <div>
                    <span class="cds-body2-demibold"> {{ 'sendIffInBatchSummary.preferredLanguage' | lang }}: </span>
                  </div>
                </div>
                <div style="flex: 2">
                  <app-cd-radio
                    class="cd-radio-margin-bottom-clear"
                    #cdRadioSecondAgentPreferredLanguage
                    [(value)]="secondAgentPreferredLanguage"
                    [config]="secondAgentPreferredLanguageConfig"></app-cd-radio>
                </div>
              </ng-container>
              <ng-container *ngIf="currentStep === 2 && secondAgentPreferredLanguage">
                <div style="flex: 1">
                  <div>
                    <span class="cds-body2-demibold"> {{ 'sendIffInBatchSummary.receivingLanguage' | lang }}: </span>
                  </div>
                </div>
                <div style="flex: 2">
                  <div *ngIf="secondAgentPreferredLanguage === PreferredLanguageType.Chinese">
                    <span class="cds-body2-demibold">{{ 'common.chinese' | lang }}</span>
                  </div>
                  <div *ngIf="secondAgentPreferredLanguage === PreferredLanguageType.English">
                    <span class="cds-body2-demibold">{{ 'common.english' | lang }}</span>
                  </div>
                </div>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="i === 1">
              <ng-container *ngIf="currentStep === 1">
                <div style="flex: 1">
                  <div>
                    <span class="cds-body2-demibold"> {{ 'sendIffInBatchSummary.preferredLanguage' | lang }}: </span>
                  </div>
                </div>
                <div style="flex: 2">
                  <app-cd-radio
                    class="cd-radio-margin-bottom-clear"
                    #cdRadioThirdAgentPreferredLanguage
                    [(value)]="thirdAgentPreferredLanguage"
                    [config]="thirdAgentPreferredLanguageConfig"></app-cd-radio>
                </div>
              </ng-container>
              <ng-container *ngIf="currentStep === 2 && thirdAgentPreferredLanguage">
                <div style="flex: 1">
                  <div>
                    <span class="cds-body2-demibold"> {{ 'sendIffInBatchSummary.receivingLanguage' | lang }}: </span>
                  </div>
                </div>
                <div style="flex: 2">
                  <div *ngIf="thirdAgentPreferredLanguage === PreferredLanguageType.Chinese">
                    <span class="cds-body2-demibold">{{ 'common.chinese' | lang }}</span>
                  </div>
                  <div *ngIf="thirdAgentPreferredLanguage === PreferredLanguageType.English">
                    <span class="cds-body2-demibold">{{ 'common.english' | lang }}</span>
                  </div>
                </div>
              </ng-container>
            </ng-container>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="currentStep === 1" class="botton-list">
      <cds-button [style]="'secondary'" (click)="back()">{{ 'common.back' | lang }}</cds-button>
      <cds-button [disabled]="isDisabledContinue" (click)="continue()">{{ 'common.continue' | lang }}</cds-button>
    </div>
    <div *ngIf="currentStep === 2" class="botton-list">
      <cds-button [style]="'secondary'" (click)="back()">{{ 'common.back' | lang }}</cds-button>
      <cds-button (click)="resendSubmission()">{{ 'common.confirmAndSubmit' | lang }}</cds-button>
    </div>
  </div>
</div>
