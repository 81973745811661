/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';

import { CdsButtonConfig } from '@cds/ng-core/button';
import { CdsLangService } from '@cds/ng-core/lang';
import { CdsPopupService, MatDialogRef } from '@cds/ng-web-components/popup';
import { finalize } from 'rxjs';
import { BTN_CONFIG_SECONDARY } from 'src/app/config/btn.config';
import { SchemeMasterService } from 'src/app/core/services/commission/scheme-master.service';
import { UserAgreementPopupComponent } from '../../user-agreement-popup.component';
import { validatorSchemeName, validatorSchemeCode, validatorWarnSlIfFao } from '../scheme-master.validators';
@Component({
  selector: 'app-scheme-master-create',
  templateUrl: './scheme-master-create.component.html',
  styleUrls: ['./scheme-master-create.component.scss'],
})
export class SchemeMasterCreateComponent implements OnInit {
  INIT_VALUE_SIF = '0.000';
  btnCfg: CdsButtonConfig = BTN_CONFIG_SECONDARY;
  review = false;
  formGroup: FormGroup;
  isAdding = false;

  constructor(private cdsPopup: CdsPopupService, private cdsLangService: CdsLangService, private router: Router, private schemeService: SchemeMasterService) {
    this.formGroup = new FormGroup({
      schemeName: new FormControl('', [validatorSchemeName]),
      schemeCode: new FormControl('', [validatorSchemeCode]),
      salaryInflationFactor: new FormControl(this.INIT_VALUE_SIF),
    });
  }

  ngOnInit(): void {}

  backLanding() {
    this.router.navigate(['/commission/scheme-list']);
  }

  get schemeName() {
    return this.formGroup.get('schemeName') as FormControl;
  }

  get schemeCode() {
    return this.formGroup.get('schemeCode') as FormControl;
  }

  get salaryInflationFactor() {
    return this.formGroup.get('salaryInflationFactor') as FormControl;
  }

  schemeNameInput(event: any) {
    let value = event.target.value;
    const pos = event.target.selectionEnd;
    value = value.toUpperCase();
    if (value === this.schemeName.value) {
      return;
    }
    this.schemeName.setValue(value);
    event.target.setSelectionRange(pos, pos);
  }

  schemeNameChange(event: any) {
    const value = event.target.value.trim();
    if (value === this.schemeName.value) {
      return;
    }
    this.schemeName.setValue(value);
  }

  salaryInflationFactorInput(event: any) {
    let value = event.target.value;
    value = value.match(/(^(\d){1,2}\.(\d){0,2}([0-9]){0,1})|(^(\d){1,2})/g); // 只允許數字2位小數點3位
    const pos = event.target.selectionEnd;
    this.salaryInflationFactor.setValue(value ? value[0] : '');
    event.target.setSelectionRange(pos, pos);
  }

  salaryInflationFactorChange(event: any) {
    let value = event.target.value;
    value = value.match(/(^(\d){1,2}\.(\d){0,2}([0-9]){0,1})|(^(\d){1,2})/g); // 只允許數字2位小數點3位
    value = parseFloat(value ? value : 0).toFixed(3);
    if (value === this.salaryInflationFactor.value) {
      return;
    }
    this.salaryInflationFactor.setValue(value);
  }

  reset() {
    this.formGroup.reset({
      salaryInflationFactor: this.INIT_VALUE_SIF,
    });
  }

  submit() {
    if (this.formGroup.invalid) {
      return;
    }
    this.salaryInflationFactor.setValidators([validatorWarnSlIfFao]);
    this.salaryInflationFactor.setValue(this.salaryInflationFactor.value);
    this.review = true;
  }

  backToEdit() {
    if (this.isAdding) {
      return;
    }
    this.salaryInflationFactor.removeValidators([validatorWarnSlIfFao]);
    this.salaryInflationFactor.setValue(this.salaryInflationFactor.value);
    this.review = false;
  }

  confirm() {
    if (this.isAdding) {
      return;
    }
    this.isAdding = true;
    const params = {
      name: this.schemeName.value,
      schemeCode: this.schemeCode.value,
      currentSalaryInflationFactor: this.salaryInflationFactor.value,
    };
    this.schemeService
      .save(params)
      .pipe(
        finalize(() => {
          this.isAdding = false;
        })
      )
      .subscribe(resp => {
        let popData, result: number;
        if (resp && resp.data && resp.result == 0) {
          popData = {
            message: 'common.action.success',
            continue: 'common.gotItWithExclamation',
            type: 'alert',
          };
          result = resp.result;
        } else {
          popData = {
            message: `${this.cdsLangService.translate('common.action.failed')}<br><span class="cds-h2-light">${resp.message}</span>`,
            continue: 'common.backToEdit',
            type: 'alert',
          };
          result = resp.result;
        }
        const popupRef: MatDialogRef<UserAgreementPopupComponent> = this.cdsPopup.open(UserAgreementPopupComponent, { data: popData });
        popupRef.afterClosed().subscribe(confirm => {
          if (confirm && confirm.agree) {
            if (result == 0) {
              this.router.navigate(['/commission/scheme-list']);
            } else {
              this.backToEdit();
            }
          }
        });
      });
  }
}
