/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit, HostListener, ChangeDetectorRef, AfterViewInit, OnDestroy, DebugElement, ViewChild, ViewEncapsulation } from '@angular/core';
import { Subject, Subscription, finalize, switchMap } from 'rxjs';
import { Imsg } from '../../user/user.component';
import { ChatService } from '../../_service/chat.service';
import { MessageService } from '../../_service/message.service';
import { Alerts } from '../../_common/alerts';
import { Helper } from '../../_common/helper';
import { DataService } from 'src/app/views/chatbot-admin/_services/data.service';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { MatDialogRef } from '@angular/material/dialog';
import { CdsPopupService } from '@cds/ng-web-components/popup';
import { AddEditCannedComponent } from 'src/app/views/chatbot-admin/main/canned/add-edit-canned/add-edit-canned.component';
import { CdsDialogService, CdsDialogPosition, CdsDialogType, CdsDialogPositionStylesBuilder } from '@cds/ng-experimental/dialog';
import { CloseChatComponent } from '../close-chat/close-chat.component';
import { PermissionService } from 'src/app/core/services/permission.service';
import { CdsSwitchConfig } from '@cds/ng-core/switch';
import { FormControl } from '@angular/forms';
import { APIPaths } from '../../_common/constant';
import { TransferChatComponent } from '../transfer-chat/transfer-chat.component';
import { IffService } from 'src/app/views/salesjourney/service/iff.service';
import { LoginType } from 'src/app/views/user/login/login.component';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { CdsOption } from '@cds/ng-core/configuration';
import { LanguageChangeService } from 'src/app/shared/language-change.service';
import { IdleService } from 'src/app/views/chatbot-admin/_services/idle.service';
import { CdsDropdownConfig } from '@cds/ng-core/dropdown';

@Component({
  selector: 'app-agent',
  templateUrl: './agent.component.html',
  styleUrls: ['../../styles.scss', './agent.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AgentComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('messageContainer') messageContainer: any;
  chats: Imsg[] = [];
  showAgent = true;
  agentCode: any;
  loading = false;
  showChat = false;
  Awaiting = false;
  Archive = false;
  roomId: any;
  agrement = false;
  message: any;
  chatJson: any = [];
  cannedMessageDDL: any = [];
  input: any = '';
  modalOptions: NgbModalOptions = {};
  modalRef: any;
  receivedMessages: string[] = [];
  subscription: any;
  users: any;
  langList: CdsOption[] = environment.languageList;
  selectedLang = 'en';
  private sharData: Subject<string> = new Subject();
  label = 'Online';
  tabIndx: number = 0;
  config: CdsSwitchConfig = {
    size: 'sm',
    label: this.label,
    value: true,
  };
  agentName: any = '';
  roomInfo: any = null;
  agentInfo: any;
  cannedMessage = null;
  messagesSubscription: Subscription = new Subscription();
  isActive = true;
  activeChat: any = [];
  pendingChat: any = [];
  closeChat: any = [];
  closeChatFilter: any = [];
  botChat: any = [];
  lang = '';
  agentStatus: boolean = false;
  status: any = 0;
  intervalId: any;
  dropdownConfig: CdsDropdownConfig = {
    options: [
      { label: 'Online', value: 1 },
      { label: 'Offline', value: 0 },
    ],
  };
  @ViewChild('switchControl') switchControl: any; // Adjust type if needed
  formControl: FormControl = new FormControl(); // Initialize as needed

  // Method to set active value
  setActiveValue(value: boolean) {
    this.formControl.setValue(value);
  }
  constructor(
    public chatService: ChatService,
    private messageService: MessageService,
    private dataService: DataService,
    public cdsDialogService: CdsDialogService,
    public modalService: NgbModal,
    public cdk: ChangeDetectorRef,
    public permissionService: PermissionService,
    public IffService: IffService,
    private languageChangeService: LanguageChangeService,
    private authenticationService: AuthenticationService,
    private router: Router,
    public languageService: LanguageChangeService,
    private idleService: IdleService
  ) {
    this.languageService.initLanNotice.subscribe(res => {
      this.lang = res;
      // Alerts.showErrorMessage('You are idle for 5 minutes, Please click on the screen to continue');
    });
    this.getActiveChatList();
    this.getPendingChatList();
    this.getCloseChatList();
    this.getBotChatList();
    this.getMessage();
    this.recallFucntion();
  }

  changeLang(lang: string) {
    this.selectedLang = lang;
    this.languageChangeService.setLang(lang);
    this.sendData(lang);
  }

  sendData(value: string) {
    this.sharData.next(value);
  }

  logout() {
    let loginType = localStorage.getItem('loginType') as LoginType;
    if (!loginType) {
      loginType = 'sso';
    }
    this.authenticationService.logout(loginType, this.router);
  }

  ngOnInit(): void {
    this.getIdleAgent();
    this.idleService.onCSOIdle.subscribe(() => {
      this.notifyIdle();
    });

    this.messagesSubscription = this.messageService.receiveMessages().subscribe(messages => {
      console.log('Received messages:', messages);
      this.chatJson.push(messages);
      this.scrollToBottom();
      this.cdk.detectChanges();
    });

    this.permissionService.getUserInfo().then(info => {
      this.IffService.getAgentCode(info.id).subscribe({
        next: (res: any) => {
          if (res?.result !== 0) {
          } else {
            this.agentInfo = info;
            this.getAgentInfo();
            this.agentCode = res.data.agentCode;
          }
        },
      });
    });

    const styleTags = document.getElementsByTagName('style');
    for (let i = 0; i < styleTags.length; i++) {
      const styleTag = styleTags[i] as any;
      if (styleTag.textContent.includes('chatbot-admin-style-marker-deletion-prohibited')) {
        if (styleTag.hasAttribute('media')) {
          styleTag.removeAttribute('media');
        }
      }
    }
  }

  notifyIdle() {
    let msg = this.lang == 'en' ? decodeURIComponent(this.idleObject?.msg) : decodeURIComponent(this.idleObject.chineseMsg);
    this.activeChat.forEach((item: any) => {
      this.messageService.initializeWebSocketConnection(item.roomId, 'Agent!');
      setTimeout(() => {
        const mode = {
          type: 'CHAT',
          content: Helper.maskPII(msg),
          userId: this.agentInfo.id,
          userType: 'AGENT',
          sender: this.agentCode,
        };
        this.messageService.sendMessage(mode, item.roomId);
      }, 1000);
      this.showChat = false;
    });
  }

  searchChat(event: any) {
    const searchTerm = event.target.value.toLowerCase();

    this.closeChatFilter = this.closeChat.filter((chat: any) => chat.users.some((user: any) => user.name.toLowerCase().includes(searchTerm)));
  }

  ngOnDestroy(): void {
    this.messagesSubscription.unsubscribe();
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }

    const styleTags = document.getElementsByTagName('style');
    for (let i = 0; i < styleTags.length; i++) {
      const styleTag = styleTags[i] as any;
      if (styleTag.textContent.includes('chatbot-admin-style-marker-deletion-prohibited')) {
        styleTag.setAttribute('media', 'max-width: 1px');
      }
    }
  }

  ngAfterViewInit(): void {
    //  setTimeout(() => {
    // const menu = document.querySelector('aside.cds-menu-container') as HTMLElement;
    // menu.style.display = 'none';
    //   const appAgent = document.querySelector('app-agent') as HTMLElement;
    //   if (appAgent.parentElement) {
    //     appAgent.parentElement.style.background = 'white';
    //   }
    // }, 0);
    // setTimeout(() => {
    //   const menu = document.querySelector('aside.cds-menu-container') as HTMLElement;
    //   menu.style.display = 'none';
    //   const head = document.getElementsByClassName('cds-navigation-toolbar');
    //   if (head.length > 0) {
    //     (head[1] as HTMLElement).style.display = 'none';
    //   }
    // }, 1000);
  }

  isCloseByAgent: boolean = false;

  onClickChat(item: any) {
    this.isCloseByAgent = item.status == 'CLOSED_BY_AGENT' ? true : false;
    this.isShowChatBot = false;
    this.agentName = item.users[0].name;
    this.Awaiting = false;
    this.Archive = false;
    this.showAgent = true;
    this.showChat = true;
    this.roomId = item.roomId;
    this.gerRoomInfo();
    this.chatService.getUserChatHistory(item.roomId).subscribe(resp => {
      if (resp) {
        this.chatJson = resp || [];
        this.messageService.initializeWebSocketConnection(item.roomId, 'Agent!');
        this.scrollToBottom();
      }
    });
  }

  onClickAwaitingChat(item: any) {
    this.agentName = item.users[0].name;
    this.showAgent = true;
    this.showChat = true;
    this.roomId = item.roomId;
    this.Awaiting = true;
    this.Archive = true;
    this.chatJson = [];
    this.gerRoomInfo();
    this.chatService.getUserChatHistory(item.roomId).subscribe(resp => {
      if (resp) {
        this.chatJson = resp || [];
      }
    });
  }

  onClickArchiveChat(item: any) {
    this.agentName = item.users[0].name;
    this.showAgent = true;
    this.showChat = true;
    this.roomId = item.roomId;
    this.Awaiting = false;
    this.Archive = true;
    this.chatJson = [];
    this.gerRoomInfo();
    this.chatService.getUserChatHistory(item.roomId).subscribe(resp => {
      if (resp) {
        this.chatJson = resp || [];
        this.messageService.initializeWebSocketConnection(item.roomId, 'Agent!');
      }
    });
  }

  updateStatus(event: any) {
    debugger;
    if (event != 1 && event != 0) {
      return;
    }
    this.cdk.detectChanges();
    this.loading = true;
    this.agentStatus = event == 1 ? true : false;
    const model = {
      userStatus: event == 1 ? 'ONLINE' : 'OFFLINE',
    };
    return this.chatService
      .changeUserStatus(this.agentInfo.id, model)
      .pipe(
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe((data: any) => {
        if (data) {
          Alerts.showSuccessMessage(`You status is ${event == 1 ? 'ONLINE' : 'OFFLINE'}`);
        } else {
          Alerts.showErrorMessage(data.message);
        }
      });
  }

  getMessage() {
    return this.chatService
      .getAgentCanedResponse()
      .pipe(
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe((data: any) => {
        if (data) {
          this.cannedMessageDDL = data;
        } else {
          Alerts.showErrorMessage(data.message);
        }
      });
  }

  onShowChats() {
    this.showAgent = true;
    this.showChat = true;
  }

  onCloseChatModal(model?: any, content?: any) {
    this.isShowChatBot = false;
    if (this.agentInfo?.permissions && this.agentInfo?.permissions?.CHATBOT_CSO) {
      let obj = this.activeChat.find((x: any) => x.roomId == this.roomId);
      let item = {
        users: obj.users,
        roomId: obj.roomId,
        certificateNumber: this.roomInfo.cert_no == 'null' || !this.roomInfo.cert_no ? '' : this.roomInfo.cert_no,
        policyNumber: this.roomInfo.group_no == 'null' || !this.roomInfo.group_no ? '' : this.roomInfo.group_no,
      };
      this.dataService.setModel(item);
      this.modalOptions.backdrop = 'static';
      this.modalOptions.keyboard = false;
      this.modalOptions.size = 'lg';
      this.modalOptions.centered = true;
      this.modalRef = this.modalService.open(CloseChatComponent, this.modalOptions);
      this.modalRef.result
        .then((result: any) => {
          this.getActiveChatList();
          this.getCloseChatList();
          this.isCloseByAgent = false;
          this.chatJson = [];
          this.leaveRoom();
          this.showChat = false;
        })
        .catch((error: any) => {});
    } else {
      Alerts.showErrorMessage('You do not have permission to close chat');
    }
  }

  onTrasnferChat(model?: any, content?: any) {
    let item = this.activeChat.find((x: any) => x.roomId == this.roomId);
    this.dataService.setModel(item);
    this.modalOptions.backdrop = 'static';
    this.modalOptions.keyboard = false;
    this.modalOptions.size = 'lg';
    this.modalOptions.centered = true;
    this.modalRef = this.modalService.open(TransferChatComponent, this.modalOptions);
    this.modalRef.result
      .then((result: any) => {
        this.getActiveChatList();
        this.getCloseChatList();
        this.chatJson = [];
        this.showChat = false;
      })
      .catch((error: any) => {});
  }

  getObjectFilePath(content: any): string {
    const parsedContent = JSON.parse(content);
    return parsedContent?.filePath;
  }

  tabChange(item: any) {
    this.isShowChatBot = false;
    this.isCloseByAgent = false;
    console.log(item);
    console.log(item.tab.label.includes('Active'));
    this.chatJson = [];
    this.showChat = false;

    if (item.index == 0) {
      this.getActiveChatList();
    }
    if (item.index == 1) {
      this.getPendingChatList();
    }
    if (item.index == 2) {
      this.Archive = true;
      this.getCloseChatList();
    }
    if (item.index == 3) {
      // this. = true
      this.getBotChatList();
    }
  }

  joinChat(roomId: any) {
    this.isShowChatBot = false;
    if (this.activeChat.length < 3) {
      this.roomId = roomId;
      let i = this.pendingChat.findIndex((x: any) => x.roomId == roomId);
      const model = {
        status: 'ACTIVE',
      };
      this.chatService.changeChatStatus(this.roomId, model).subscribe(resp => {
        if (resp) {
          this.showAgent = true;
          this.showChat = true;
          this.chatJson = resp.messages || [];
          this.Awaiting = false;
          this.getActiveChatList();
          this.getPendingChatList();
          this.messageService.initializeWebSocketConnection(this.roomId, 'Agent!');
          setTimeout(() => {
            const mode = {
              type: 'JOIN',
              content: `${this.agentName} is joined the chat`,
              userId: this.agentInfo.id,
              userType: 'AGENT',
              sender: this.agentCode,
            };
            this.messageService.sendMessage(mode, this.roomId);
          }, 1000);

          this.cdk.detectChanges();
        }
      });
    } else {
      Alerts.showErrorMessage('You can not join more than 3 chats at a time');
    }
  }

  getActiveChatList() {
    this.chatService.getActiveChatList().subscribe(resp => {
      if (resp) {
        this.activeChat = resp;
      }
    });
  }

  getPendingChatList() {
    this.chatService.getPendingChatList().subscribe(resp => {
      if (resp) {
        this.pendingChat = resp;
      }
    });
  }

  getCloseChatList() {
    this.chatService.getCloseChatList().subscribe(resp => {
      if (resp) {
        this.closeChat = resp;
        this.closeChatFilter = resp;
      }
    });
  }
  getBotChatList() {
    this.chatService.getBotChatList().subscribe(resp => {
      if (resp) {
        this.botChat = resp;
      }
    });
  }

  getUserChatHistory(item: any) {
    item;
  }

  async sendMessage() {
    if (this.input) {
      const mode = {
        type: 'CHAT',
        content: Helper.maskPII(this.input),
        userId: this.agentInfo.id,
        userType: 'AGENT',
        sender: this.agentCode,
      };
      this.messageService.sendMessage(mode, this.roomId);
      this.input = '';
    }
  }

  leaveRoom() {
    const mode = {
      type: 'LEAVE',
      content: '',
      userId: this.agentInfo.id,
      userType: 'AGENT',
      sender: this.agentCode,
    };
    this.messageService.leaveRoom(mode, this.roomId);
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.sendMessage();
    }
  }
  scrollToBottom(): void {
    try {
      setTimeout(() => {
        if (this.messageContainer?.nativeElement) this.messageContainer.nativeElement.scrollTop = this.messageContainer.nativeElement.scrollHeight + 350;
      }, 300);
    } catch (err) {}
  }

  selectMessage(event: any) {
    this.isShowChatBot = false;
    this.cannedMessage = null;
    let mode = {
      type: 'CHAT',
      content: this.lang == 'en' ? event.ans : event.chineseAns,
      userId: this.agentInfo?.id,
      userType: 'AGENT',
      sender: this.agentInfo?.displayName,
    };
    this.messageService.sendMessage(mode, this.roomId);
  }

  fileChange() {
    this.isShowChatBot = false;
    document.getElementById('file')?.click();
  }

  onFileChange(event: any) {
    this.isShowChatBot = false;
    this.uploadFile(event.target.files[0]);
    event.target.value = '';
  }
  uploadFile(file: any) {
    /* eslint-disable @typescript-eslint/no-this-alias */
    const that = this;
    const xhr = new XMLHttpRequest();
    const formData = new FormData();
    formData.append('file', file);
    this.fileUploaded(xhr, that, (data: any) => {
      data;
    });

    if (xhr.upload) {
      this.updateProgress(xhr, (data: any) => {
        // this.statusBar = data;
        data;
      });
    }
    xhr.open('POST', APIPaths.upload, true);
    xhr.setRequestHeader('Authorization', `${this.agentInfo.token}`);
    xhr.setRequestHeader('DisplayName', encodeURIComponent(this.agentInfo?.displayName));
    xhr.setRequestHeader('Email', this.agentInfo?.email);
    xhr.setRequestHeader('Id', this.agentInfo?.id);
    xhr.setRequestHeader('CurrentLastUpdate', this.agentInfo?.currentLastUpdate.toString());
    xhr.setRequestHeader('Permissions', JSON.stringify(this.agentInfo?.permissions));
    xhr.send(formData);
  }

  fileUploaded(xhr: any, that: any, callback: any) {
    callback;
    xhr.onreadystatechange = function (e: any) {
      e;
      if (xhr.readyState === 4) {
        //Done
        if (xhr.status === 200) {
          //Loading/Done
          debugger;
          const response = JSON.parse(xhr.response);
          const mode = {
            type: 'FILE',
            content: xhr.response,
            userId: that.agentInfo?.id,
            userType: 'AGENT',
            sender: that.agentInfo?.displayName,
          };
          that.messageService.sendMessage(mode, that.roomId);
        }
      } else if (xhr.readyState === 1) {
        //loading
      }
    };
  }

  updateProgress(xhr: any, callback: any) {
    xhr.upload.onprogress = function (e: any) {
      if (e.lengthComputable) {
        this.statusBar = (e.loaded / e.total) * 100;
        callback((e.loaded / e.total) * 100);
      }
    };
    xhr.upload.onloadstart = function (e: any) {
      e;
      callback(0);
    };
    xhr.upload.onloadend = function (e: any) {
      e;
      callback(100);
    };
  }

  isShowChatBot = false;

  showModal() {
    this.isShowChatBot = !this.isShowChatBot;
  }

  gerRoomInfo() {
    this.chatService.getRoomInfo(this.roomId).subscribe(resp => {
      console.log(resp);
      this.roomInfo = resp.room;
    });
  }

  getAgentInfo() {
    this.chatService.getAgentInfo(this.agentInfo?.id).subscribe(resp => {
      console.log(resp);
      debugger;
      this.agentStatus = resp.userStatus == 'ONLINE' ? true : false;
      this.status = resp.userStatus == 'ONLINE' ? 1 : 0;
      this.config.value = true;
      this.cdk.detectChanges();
    });
  }

  idleObject: any;
  getIdleAgent() {
    this.chatService.getAlert().subscribe((resp: any) => {
      this.idleObject = resp.find((item: any) => item.autoMsgType == 'IDLE');
      let sec = this.idleObject?.minutes * 60 * 1000;
      this.idleService.startSCOListening(sec);
    });
  }

  recallFucntion() {
    this.intervalId = setInterval(() => {
      this.getActiveChatList();
      this.getPendingChatList();
      this.getCloseChatList();
      this.getBotChatList();
    }, 10000);
  }
}
