import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-cd-textarea',
  templateUrl: './cd-textarea.component.html',
  styleUrls: ['./cd-textarea.component.scss'],
})
export class CdTextareaComponent implements OnInit {
  @Input() label = '';
  @Input() placeholder = '';
  @Input() maxlength?: number;
  @Input() minRows?: number;
  @Input() maxRows = 8;
  @Input() value = '';
  @Input() height?: string;
  @Input() fontSize?: string;
  @Output() valueChange = new EventEmitter<string>();

  constructor() {}

  ngOnInit(): void {}
}
