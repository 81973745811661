<div class="announcement-center">
  <div class="title-box">
    <span class="cds-h4-demibold">Announcement Center</span>
  </div>

  <div class="record-list-box">
    <div>
      <div>
        <span class="cds-h2-light">Record List</span>
      </div>
      <div class="l-mt-2">
        <span class="cds-body1">Total {{ totalElements }} records</span>
      </div>
    </div>
    <div>
      <cds-button [style]="'secondary'" size="sm" (click)="onCreateAnnouncement()">Create Announcement</cds-button>
    </div>
  </div>

  <ng-template #template let-element>
    <div style="width: 80vw">
      <div>
        <span class="cds-body2">Announcement Details</span>
      </div>
      <div class="l-mt-2">
        <div>
          <span class="cds-body1 line-break">{{ element.announcementBodyEng }}</span>
        </div>
      </div>
      <div class="l-mt-2">
        <div>
          <span class="cds-body2 line-break">{{ element.announcementBodyChi }}</span>
        </div>
      </div>
      <div class="l-d-if l-mt-5 cursor-pointer" (click)="recallAnnouncement(element.announcementId)">
        <div>
          <cds-icon icon="form:delete" style="--cds-icon-color: var(--cds-color-dark-1-coral)"></cds-icon>
        </div>
        <div class="l-ml-2">
          <span class="cds-h6-demibold" style="color: var(--cds-color-dark-1-coral)">Recall Announcement</span>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #createDate let-element>
    <div>
      <span class="cds-body1">{{ element.createDate | date : 'dd/MM/yyyy' }}</span>
    </div>
  </ng-template>

  <ng-template #effectiveDate let-element>
    <div>
      <div>
        <span class="cds-body1">{{ element.effectiveFrom | date : 'dd/MM/yyyy HH:mm' }} to </span>
      </div>
      <div>
        <span class="cds-body1">{{ element.effectiveTo | date : 'dd/MM/yyyy HH:mm' }}</span>
      </div>
    </div>
  </ng-template>

  <ng-template #announcementSubject let-element>
    <div>
      <div>
        <span class="cds-body1" style="word-wrap: break-word">{{ element.announcementSubjectEng }}</span>
      </div>
      <div class="l-mt-1">
        <span class="cds-body2" style="word-wrap: break-word">{{ element.announcementSubjectChi }}</span>
      </div>
    </div>
  </ng-template>

  <ng-template #status let-element>
    <div class="l-d-f">
      <div>
        <span class="cds-body1-light">{{ element.status ? 'ON' : 'OFF' }}</span>
      </div>
      <div class="l-ml-4">
        <app-cd-switch
          [beforeChange]="checkBefore(element.status)"
          [(value)]="element.status"
          (valueChange)="updateAnnouncementStatus(element)"></app-cd-switch>
      </div>
    </div>
  </ng-template>

  <ng-template #action let-element>
    <div class="l-d-if cursor-pointer" (click)="edit(element.announcementId)">
      <div>
        <cds-icon icon="action:edit"></cds-icon>
      </div>
      <div class="l-ml-2">
        <span class="cds-h6-demibold">Edit</span>
      </div>
    </div>
  </ng-template>

  <div *ngIf="screenSizeService.isLargeScreen" class="table-expand-box">
    <div *ngIf="isLoading" class="progress-box l-d-f l-jc-cen">
      <cds-progress mode="indeterminate" [diameter]="60" [type]="'ring'" [text]="'Loading'" [color]="'ml'"></cds-progress>
    </div>
    <app-no-result-found [style.display]="currentPageData.length === 0 && !isLoading ? 'block' : 'none'"></app-no-result-found>
    <div [style.display]="currentPageData.length > 0 ? 'unset' : 'none'" class="row l-plr-0">
      <app-table-expand [ngClass]="[isLoading ? 'loading' : '']" [expandTemplate]="template" [data]="currentPageData">
        <app-table-expand-col [template]="createDate" prop="createDate" label="Create Date"></app-table-expand-col>
        <app-table-expand-col [template]="effectiveDate" prop="effectiveFrom" label="Effective Date"></app-table-expand-col>
        <app-table-expand-col [template]="announcementSubject" prop="announcementSubjectEng" label="Announcement Subject"></app-table-expand-col>
        <app-table-expand-col [template]="status" prop="status" label="Display"></app-table-expand-col>
        <app-table-expand-col [template]="action" label="Action"></app-table-expand-col>
      </app-table-expand>

      <div *ngIf="currentPageData.length > 0" class="col-sm-12 div-border-bottom prodtype-pageformat">
        <app-pagination
          [pageCount]="{
            current: currentPageNumOrigin,
            count: allPageData.length
          }"
          (pageChange)="pageChange($event)">
        </app-pagination>
      </div>
    </div>
  </div>

  <div *ngIf="screenSizeService.isSmallScreen" class="table-expand-box">
    <div *ngIf="isLoading && currentPageData.length === 0" class="progress-box l-d-f l-jc-cen">
      <cds-progress mode="indeterminate" [diameter]="60" [type]="'ring'" [text]="'Loading'" [color]="'ml'"></cds-progress>
    </div>
    <app-no-result-found [style.display]="currentPageData.length === 0 && !isLoading ? 'block' : 'none'"></app-no-result-found>
    <div *ngFor="let element of currentPageData" class="m-expand-box-item" [ngClass]="[isLoading ? 'loading' : '']">
      <div class="m-header">
        <div class="l-d-f l-jc-sb l-ai-cen">
          <div class="l-d-f l-fd-col">
            <div>
              <span class="cds-body1-demibold" style="word-wrap: break-word">{{ element.announcementSubjectEng }}</span>
            </div>
            <div>
              <span class="cds-body1-demibold" style="word-wrap: break-word">{{ element.announcementSubjectChi }}</span>
            </div>
          </div>
          <div>
            <cds-icon
              icon="action:button_up"
              class="icon-button-up"
              [ngClass]="{ down: expandMap[element.announcementId] }"
              (click)="toggleContent(element)"></cds-icon>
          </div>
        </div>
        <div class="l-mt-4">
          <span class="cds-detail1">Create date: {{ element.createDate | date : 'dd/MM/yyyy' }}</span>
        </div>
        <div class="l-mt-2">
          <span class="cds-detail1">Target Broadcast Date & Time:</span>
        </div>
        <div>
          <span class="cds-detail1">{{ element.effectiveFrom | date : 'dd/MM/yyyy HH:mm' }} to {{ element.effectiveTo | date : 'dd/MM/yyyy HH:mm' }}</span>
        </div>
        <div class="line l-mt-4"></div>
        <div class="l-d-f l-ai-cen l-mt-4">
          <div>
            <span class="cds-body1-light">{{ element.status ? 'ON' : 'OFF' }}</span>
          </div>
          <div class="l-ml-4">
            <app-cd-switch
              [beforeChange]="checkBefore(element.status)"
              [(value)]="element.status"
              (valueChange)="updateAnnouncementStatus(element)"></app-cd-switch>
          </div>
        </div>
      </div>
      <div class="expend-content" [ngClass]="{ expanded: expandMap[element.announcementId] }">
        <div class="line l-mt-4"></div>
        <div class="l-mt-5">
          <span class="cds-body2">Message Details</span>
        </div>
        <div class="cds-body2">
          <span class="cds-body1 line-break">{{ element.announcementBodyEng }}</span>
        </div>
        <div class="l-mt-2">
          <span class="cds-body1 line-break">{{ element.announcementBodyChi }}</span>
        </div>
        <div class="l-mt-5 l-d-if l-ai-cen cursor-pointer">
          <div>
            <cds-icon
              icon="form:delete"
              style="--cds-icon-color: var(--cds-color-dark-1-coral)"
              (click)="recallAnnouncement(element.announcementId)"></cds-icon>
          </div>
          <div class="l-ml-2">
            <span class="cds-h6-demibold" style="color: var(--cds-color-dark-1-coral)" (click)="recallAnnouncement(element.announcementId)"
              >Recall Announcement</span
            >
          </div>
        </div>
        <div class="line l-mt-5"></div>
        <div class="l-mt-7 l-d-if l-ai-cen cursor-pointer" (click)="edit(element.announcementId)">
          <div>
            <cds-icon icon="action:edit"></cds-icon>
          </div>
          <div class="l-ml-2">
            <span class="cds-h6-demibold">Edit</span>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="currentPageData.length > 0" class="col-sm-12 div-border-bottom prodtype-pageformat">
      <app-pagination
        [pageCount]="{
          current: currentPageNumOrigin,
          count: allPageData.length
        }"
        (pageChange)="pageChange($event)">
      </app-pagination>
    </div>
  </div>
</div>
