import { Component, OnInit } from '@angular/core';
import { AdminHeaderConfig } from '../../layout/admin-layout/admin-header/admin-header-config';
import { LayoutHeaderService } from 'src/app/core/services/layout-header.service';
import { EmployeeService } from '../employee.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastAlertService } from 'src/app/shared/toast-alert.service';
import {
  FundTransactionFormTypeE,
  PmtransferOptionTypeE,
  PolicyTransferDetails,
  PolicyTransferStatusCodeE,
  PptransferOptionTypeE,
  ReplyTypeE,
  SubAccountTypeE,
  SubmissionTypeE,
  employeeStatusMapObj,
  fundTransactionFormTypeMapObj,
  pmtransferOptionTypeMapObj,
  policyTransferStastusCodeMapObj,
  pptransferOptionTypeMapObj,
  reasonMapObj,
  replyTypeMapObj,
  subAccountTypeMapObj,
  submissionTypeMapObj,
} from '../employee';
import { CdsDropdownConfig } from '@cds/ng-core/dropdown';
import { finalize } from 'rxjs';

@Component({
  selector: 'app-policy-transfer-details',
  templateUrl: './policy-transfer-details.component.html',
  styleUrls: ['./policy-transfer-details.component.scss'],
})
export class PolicyTransferDetailsComponent implements OnInit {
  headerButton: AdminHeaderConfig = {
    title: 'ee.profile.detail.plus.title',
    backButton: {
      text: 'Member Profile Overview',
      url: '#',
      method: this.goBack.bind(this),
    },
  };
  transferReferenceNo = '';

  id = '';

  md5 = '';

  detailData: PolicyTransferDetails = {};

  employeeStatusMapObj = employeeStatusMapObj;

  dropdownConfig: CdsDropdownConfig = {
    label: 'Transfer Reference No.',
    options: [],
  };

  isLoading = false;

  bankInformationTableData: PolicyTransferDetails[] = [];

  rejectReasonTableData: string[] = [];

  displayedColumns = [
    'submissionType',
    'dateOfTransfer',
    'originalRequestedSubmissionDate',
    'subAccountType',
    'intraGroupTransactionFlag',
    'completeDocumentReceiptDate',
    'transferLastFollowUpDate',
  ];

  rejectReasondisplayedColumns = ['rejectionReason'];

  constructor(
    private layoutHeaderService: LayoutHeaderService,
    public service: EmployeeService,
    private route: ActivatedRoute,
    private router: Router,
    private toastAlert: ToastAlertService
  ) {}

  ngOnInit(): void {
    this.layoutHeaderService.setHeaderButton(this.headerButton);
    this.transferReferenceNo = this.route.snapshot.queryParams['transferReferenceNo'];
    this.id = this.route.snapshot.queryParams['id'];
    this.md5 = this.route.snapshot.queryParams['md5'];
    // this.getRelatedPolicies();
    // this.getDetail();
  }

  onSelectChange(val: string) {
    if (!val) return;
    this.transferReferenceNo = val;
    this.getDetail();
  }

  goBack() {
    this.router.navigate(['/employee/related-policy-detail'], {
      queryParams: { id: this.id, md5: this.md5 },
    });
  }

  toastError(errorMessage: string) {
    this.toastAlert.show('error', 'common.error', errorMessage, 5000);
  }

  getDetail() {
    this.isLoading = true;
    this.service
      .getTransferDetails(this.transferReferenceNo, this.id)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe(res => {
        if (res.result === 0 && res.data) {
          this.detailData = res.data;
          const referenceList = res.data.transferReferenceList || [];
          this.bankInformationTableData = this.getBankInformationTableData();
          this.dropdownConfig.options = referenceList.map(item => {
            return {
              label: `${item.transferReferenceNo}`,
              value: item.transferReferenceNo,
            };
          });
          this.dropdownConfig = { ...this.dropdownConfig };
        } else {
          this.toastError(res.message);
        }
      });
  }

  getBankInformationTableData() {
    return [
      {
        submissionType: this.detailData.submissionType,
        dateOfTransfer: this.detailData.dateOfTransfer,
        originalRequestedSubmissionDate: this.detailData.originalRequestedSubmissionDate,
        subAccountType: this.detailData.subAccountType,
        intraGroupTransactionFlag: this.detailData.intraGroupTransactionFlag,
        completeDocumentReceiptDate: this.detailData.completeDocumentReceiptDate,
        transferLastFollowUpDate: this.detailData.transferLastFollowUpDate,
      },
    ];
  }

  getRejectReasonTableData() {
    if (!this.detailData.rejectionReason) return [];
    return this.detailData.rejectionReason.map(item => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return { rejectReason: `"${item}"-${(reasonMapObj as any)[item]}` };
    });
  }

  statusCodeRender(type: PolicyTransferStatusCodeE) {
    return policyTransferStastusCodeMapObj[type] || '';
  }

  fundTransactionFormTypeRender(type: FundTransactionFormTypeE) {
    return fundTransactionFormTypeMapObj[type] || '';
  }

  pmTransferOptionTypeRender(type: PmtransferOptionTypeE) {
    return pmtransferOptionTypeMapObj[type] || '';
  }

  ppTransferOptionTypeRender(type: PptransferOptionTypeE) {
    return pptransferOptionTypeMapObj[type] || '';
  }

  submissionTypeRender(type: SubmissionTypeE) {
    return submissionTypeMapObj[type] || '';
  }

  subAccountTypeRender(type: SubAccountTypeE) {
    return subAccountTypeMapObj[type] || '';
  }

  replyTypeRender(type: ReplyTypeE) {
    return replyTypeMapObj[type] || '';
  }
}
