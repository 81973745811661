<div class="employer">
  <div class="head-box">
    <div class="back-box">
      <cds-icon [ngClass]="[isConfirming ? 'disabled' : '']" (click)="onBack()" [config]="backIconConfig" [icon]="backIcon"> </cds-icon>
      <div [ngClass]="[isConfirming ? 'disabled' : '']" class="back-title">
        <span (click)="onBack()" class="cds-body2-demibold">Choose Product Type</span>
      </div>
    </div>
    <span class="head">IFF Paper Form</span>
  </div>

  <form [formGroup]="employerFormForm">
    <div class="content-box">
      <div class="l-d-f l-ai-cen">
        <cds-icon style="width: 40px; height: 40px; --cds-icon-color: var(--cds-bg-ml)" icon="environment:office_building"></cds-icon>
        <div class="l-ml-4">
          <span class="cds-h2-demibold" style="color: var(--cds-bg-ml)">Employer</span>
        </div>
      </div>

      <div class="information-list-box">
        <div class="information-box">
          <div>
            <span class="cds-body-hero-light">eMPF Information</span>
          </div>
          <div class="item-list-box">
            <div class="item-box">
              <div style="width: 270px">
                <span class="cds-body1-demibold">eMPF Reference No.</span>
              </div>
              <div *ngIf="isConfirming" class="e-reference-no-width">
                <span class="cds-body1">{{ employerFormForm.get('eMPFReferenceNo')?.value }}</span>
              </div>
              <div *ngIf="!isConfirming" class="offsets e-reference-no-width">
                <div class="l-d-f l-ai-cen">
                  <app-cd-input
                    class="e-reference-no-width"
                    (valueChange)="eMPFReferenceNoChange($event)"
                    [group]="employerFormForm"
                    [controlName]="'eMPFReferenceNo'"
                    [placeholder]="'input eMPF Reference No.'"></app-cd-input>
                  <cds-icon (click)="handleIconClick()" class="input-e-reference-no" icon="action:info_1"></cds-icon>
                </div>
                <cds-assistive-text
                  *ngIf="employerFormForm.get('eMPFReferenceNo')?.dirty && employerFormForm.get('eMPFReferenceNo')?.hasError('required')"
                  class="l-mt-1"
                  >Please input the eMPF Reference No.</cds-assistive-text
                >
                <cds-assistive-text
                  *ngIf="employerFormForm.get('eMPFReferenceNo')?.dirty && employerFormForm.get('eMPFReferenceNo')?.hasError('haveExisted')"
                  class="l-mt-1"
                  >eMPF Ref. No. is being used, please verify and input a valid eMPF Ref. No.</cds-assistive-text
                >
              </div>
            </div>
            <div class="item-box">
              <div style="width: 270px">
                <span class="cds-body1-demibold">Paper IFF Agent Submission Date</span>
              </div>
              <div *ngIf="isConfirming" style="max-width: 265px; min-width: 265px">
                <span class="cds-body1">{{ employerFormForm.get('paperIFFAgentSubmissionDateFrom')?.value }}</span>
              </div>
              <div *ngIf="!isConfirming && !resetDatePicker" class="offsets" style="max-width: 265px; min-width: 265px">
                <cds-textfield
                  class="paper-agent-submission-date"
                  type="text"
                  formControlName="paperIFFAgentSubmissionDateFrom"
                  placeholder="Paper IFF Agent Submission Date"
                  [cdsDatepicker]="pickerFromDate"
                  (click)="openPaperIFFAgentSubmissionFromDate()"
                  [max]="now"></cds-textfield>
                <cds-datepicker #pickerFromDate="cdsDatepicker"></cds-datepicker>
                <cds-assistive-text
                  *ngIf="
                    employerFormForm.get('paperIFFAgentSubmissionDateFrom')?.dirty &&
                    employerFormForm.get('paperIFFAgentSubmissionDateFrom')?.hasError('required')
                  "
                  class="l-mt-3"
                  >Please input the paper IFF agent submission date</cds-assistive-text
                >
                <cds-assistive-text
                  *ngIf="
                    employerFormForm.get('paperIFFAgentSubmissionDateFrom')?.dirty &&
                    employerFormForm.get('paperIFFAgentSubmissionDateFrom')?.hasError('formatError')
                  "
                  class="l-mt-3"
                  >{{ 'common.invalidDate' | lang }}</cds-assistive-text
                >
              </div>
            </div>
            <div class="item-box">
              <app-cd-intragroup-transfer-checkbox [formControl]="isIntragroupTransfer" ngDefaultControl></app-cd-intragroup-transfer-checkbox>
            </div>
          </div>
        </div>
      </div>

      <div class="information-list-box">
        <div class="information-box">
          <div>
            <span class="cds-body-hero-light">Employer’s Information</span>
          </div>
          <div class="item-list-box">
            <div class="item-box l-mt-0">
              <div style="width: 326px" class="l-mt-5">
                <span class="cds-body1-demibold">Employer Name/BR Number<b style="color: var(--cds-bg-cta-hover)">*</b> : </span>
              </div>
              <div class="l-mt-5" *ngIf="isConfirming">
                <span *ngIf="employerNameOrBRNumber === 'E'" class="cds-body1">{{ employerFormForm.get('employerName')?.value }}</span>
                <span *ngIf="employerNameOrBRNumber === 'B'" class="cds-body1">{{ employerFormForm.get('brNo')?.value }}</span>
              </div>
              <div class="l-mt-5" *ngIf="!isConfirming">
                <div>
                  <app-cd-radio class="cd-radio-margin-bottom-clear" [(value)]="employerNameOrBRNumber" [config]="employerNameOrBRNumberConfig"></app-cd-radio>
                  <div>
                    <cds-assistive-text class="l-mt-4" *ngIf="isTryConfirm && !employerNameOrBRNumber"
                      >Please input the Employer Name or Business/Other Registration No.</cds-assistive-text
                    >
                  </div>
                </div>
                <div>
                  <div *ngIf="employerNameOrBRNumber === 'E'" class="l-mt-5">
                    <app-cd-input
                      [group]="employerFormForm"
                      controlName="employerName"
                      regExpLimit="^[\u4E00-\u9FA5\x20]*$|^[a-zA-Z\x20]*$"
                      ngDefaultControl
                      [placeholder]="'Employer Name'"></app-cd-input>
                    <cds-assistive-text
                      class="l-mt-4"
                      *ngIf="employerFormForm.get('employerName')?.dirty && employerFormForm.get('employerName')?.hasError('required')"
                      >Please input the Employer Name</cds-assistive-text
                    >
                  </div>
                  <div *ngIf="employerNameOrBRNumber === 'B'" class="l-mt-5">
                    <app-cd-input
                      [group]="employerFormForm"
                      controlName="brNo"
                      regExpLimit="^[A-Za-z0-9]*$"
                      ngDefaultControl
                      [placeholder]="'Business/Other Registration No.'"></app-cd-input>
                    <cds-assistive-text class="l-mt-4" *ngIf="employerFormForm.get('brNo')?.dirty && employerFormForm.get('brNo')?.hasError('required')"
                      >Please input the Business/Other Registration No.</cds-assistive-text
                    >
                  </div>
                </div>
              </div>
            </div>
            <div class="item-box l-mt-0">
              <div style="width: 326px" class="l-mt-5">
                <span class="cds-body1-demibold">Authorized Person contact information<b style="color: var(--cds-bg-cta-hover)">*</b> :</span>
              </div>
              <div *ngIf="isConfirming" class="l-mt-5">
                <span *ngIf="authorizedContact === 'E'" class="cds-body1">{{ employerFormForm.get('authorizedEmailAddress')?.value }}</span>
                <span *ngIf="authorizedContact === 'M'" class="cds-body1"
                  >(+{{ employerFormForm.get('authorizedCountryCode')?.value }}) {{ employerFormForm.get('authorizedPhoneNumber')?.value }}</span
                >
              </div>
              <div *ngIf="!isConfirming" class="l-mt-5">
                <div>
                  <app-cd-radio
                    class="cd-radio-margin-bottom-clear"
                    [(value)]="authorizedContact"
                    [config]="authorizedContactConfig"
                    (valueChange)="authorizedContactChange($event)"></app-cd-radio>
                  <div>
                    <cds-assistive-text class="l-mt-4" *ngIf="isTryConfirm && !authorizedContact">Please choose client contact information</cds-assistive-text>
                  </div>
                </div>
                <div>
                  <div *ngIf="authorizedContact === 'E'" class="l-mt-5">
                    <cds-textfield ngDefaultControl [placeholder]="'common.email-address' | lang" formControlName="authorizedEmailAddress"></cds-textfield>
                    <cds-assistive-text
                      class="l-mt-4"
                      *ngIf="employerFormForm.get('authorizedEmailAddress')?.dirty && employerFormForm.get('authorizedEmailAddress')?.hasError('email')"
                      >{{ 'common.please-input-valid-customer-email' | lang }}</cds-assistive-text
                    >
                    <cds-assistive-text
                      class="l-mt-4"
                      *ngIf="employerFormForm.get('authorizedEmailAddress')?.dirty && employerFormForm.get('authorizedEmailAddress')?.hasError('required')"
                      >{{ 'common.please-input-customer-email' | lang }}</cds-assistive-text
                    >
                  </div>
                  <div *ngIf="authorizedContact === 'M'" class="l-d-f l-mt-5">
                    <div style="width: 116px">
                      <app-cd-dropdown
                        [placeholder]="countrycodeConfig.placeholder || ''"
                        [group]="employerFormForm"
                        [controlName]="'authorizedCountryCode'"
                        (selectedChange)="authorizedCountryCodeCodeChange()"
                        [toppingList]="countrycodeConfig.options"></app-cd-dropdown>
                    </div>
                    <div>
                      <div class="l-ml-4" style="position: relative; top: -1px; width: 230px">
                        <app-cd-input
                          [type]="'number'"
                          ngDefaultControl
                          placeholder="Please input Client Phone Number"
                          [group]="employerFormForm"
                          controlName="authorizedPhoneNumber"></app-cd-input>
                      </div>
                      <div class="l-ml-4 l-mt-1">
                        <cds-assistive-text
                          *ngIf="
                            employerFormForm.get('authorizedPhoneNumber')?.dirty && employerFormForm.get('authorizedPhoneNumber')?.hasError('errorPhoneNumber')
                          ">
                          <span>
                            {{ 'common.please-input-valid-mobile-number' | lang }}
                          </span>
                        </cds-assistive-text>
                      </div>
                      <div class="l-ml-4 l-mt-1">
                        <cds-assistive-text
                          *ngIf="employerFormForm.get('authorizedPhoneNumber')?.dirty && employerFormForm.get('authorizedPhoneNumber')?.hasError('required')">
                          <span>
                            {{ 'common.please-input-phone-number' | lang }}
                          </span>
                        </cds-assistive-text>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="!(isConfirming && !employerFormForm.get('preferredLanguage')?.value)" class="item-box">
              <div *ngIf="authorizedContact === 'M'" style="width: 326px">
                <span class="cds-body1-demibold">{{ 'sendIffInBatchSummary.preferredLanguage' | lang }}:</span>
              </div>
              <div *ngIf="isConfirming" style="flex: 1">
                <span class="cds-body1">{{ employerFormForm.get('preferredLanguage')?.value }}</span>
              </div>
              <div *ngIf="!isConfirming && authorizedContact === 'M'" style="flex: 1">
                <app-cd-radio
                  class="cd-radio-margin-bottom-clear"
                  #cdRadioPreferredLanguage
                  (valueChange)="employerFormForm.get('preferredLanguage')?.setValue($event)"
                  [value]="employerFormForm.get('preferredLanguage')?.value"
                  [config]="preferredLanguageConfig"></app-cd-radio>
                <div
                  *ngIf="employerFormForm.get('preferredLanguage')?.invalid && employerFormForm.get('preferredLanguage')?.touched"
                  style="margin-top: var(--cds-spacing-01)">
                  <cds-assistive-text type="error"
                    >{{ 'common.plsSelect' | lang : { p1: 'sendIffInBatchSummary.preferredLanguage' | lang } }}
                  </cds-assistive-text>
                </div>
              </div>
            </div>
            <div class="item-box">
              <div>
                <span class="cds-detail2" style="color: var(--cds-color-dark-1-coral)">*請確保資料與客戶在eMPF上提供的資料一致</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="information-list-box">
        <div class="information-box">
          <div>
            <span class="cds-body-hero-light">Special Needs</span>
          </div>
          <div class="item-list-box">
            <div class="item-box">
              <span class="cds-body1-demibold" style="color: #000000; margin-bottom: 0"
                >Please confirm whether you are a client with Special Needs by checking the appropriate box below. </span
              >
            </div>
            <div *ngIf="isConfirming" class="item-box">
              <span class="cds-body1">{{
                confirmSpecialNeedsValue === 'y'
                  ? 'Yes, I have special needs.'
                  : 'No, I do not have special needs and this confirmation shall override any previous confirmation and/or any information about me, which may indicate otherwise.'
              }}</span>
            </div>
            <div *ngIf="!isConfirming" class="item-box">
              <app-cd-radio [(value)]="confirmSpecialNeedsValue" [config]="confirmSpecialNeedsConfig" [orientation]="'vertical'"></app-cd-radio>
              <cds-assistive-text class="l-mt-1" *ngIf="isTryConfirm && !confirmSpecialNeedsValue"> Please choose special needs </cds-assistive-text>
            </div>
            <ng-container *ngIf="confirmSpecialNeedsValue === 'y'">
              <div class="l-mt-7" style="margin-top: 40px">
                <span class="cds-body1-demibold" style="color: #000000; margin-bottom: 0"
                  >I have been advised to be accompanied by a companion and/or have an additional member of staff to witness the relevant sales process and
                  constituent fund selection process, and I have chosen:</span
                >
              </div>
              <div *ngIf="isConfirming" class="item-box">
                <span class="cds-body1" *ngIf="advisedSpecialNeedsValue === '1'">not to have any witness</span>
                <span class="cds-body1" *ngIf="advisedSpecialNeedsValue === '2'"
                  >to be accompanied by a companion/ to be accompanied by an additional member of staff as the witness</span
                >
              </div>
              <div *ngIf="!isConfirming" class="item-box l-d-f l-fd-col">
                <app-cd-radio [(value)]="advisedSpecialNeedsValue" [config]="advisedSpecialNeedsConfig" [orientation]="'vertical'"></app-cd-radio>
                <cds-assistive-text class="l-mt-1" *ngIf="isTryConfirm && !advisedSpecialNeedsValue"> Please choose </cds-assistive-text>
              </div>
            </ng-container>
          </div>
        </div>
      </div>

      <div class="information-list-box">
        <div class="information-box">
          <div>
            <span class="cds-body-hero-light">MPF Intermediary’s Information and Acknowledgement </span>
          </div>
          <div class="item-list-box">
            <div class="item-box">
              <div style="width: 270px">
                <span class="cds-body1-demibold">DBS<b style="color: var(--cds-bg-cta-hover)">*</b> :</span>
              </div>
              <div>
                <app-cd-radio
                  class="cd-radio-margin-bottom-clear"
                  #dbsReferredLanguage
                  [(value)]="isDBschannel"
                  [config]="dbsContactConfig"
                  (valueChange)="dbsContractChange($event)">
                </app-cd-radio>
                <div>
                  <cds-assistive-text class="l-mt-4" *ngIf="isTryConfirm && !isDBschannel">Please select DBS</cds-assistive-text>
                </div>
              </div>
            </div>
            <div class="item-box l-mt-0">
              <div style="width: 270px" class="l-mt-5">
                <span class="cds-body1-demibold">Servicing Agent Code</span>
              </div>
              <div *ngIf="isConfirming" class="l-mt-5" style="max-width: 560px; min-width: 265px; width: 560px">
                <span class="cds-body1"
                  >{{ tempServicingAgent?.agentCode }} {{ getAgentName(tempServicingAgent || undefined) }} (District Code:
                  {{ tempServicingAgent?.branchNo }})</span
                >
              </div>
              <div *ngIf="!isConfirming" class="offsets l-mt-5" style="max-width: 560px; min-width: 265px; width: 560px">
                <app-cd-input
                  [group]="employerFormForm"
                  controlName="servicingAgentCode"
                  [type]="'number'"
                  maxlength="6"
                  placeholder="Input Agent Code"></app-cd-input>
                <cds-assistive-text
                  class="l-mt-3"
                  *ngIf="employerFormForm.get('servicingAgentCode')?.dirty && employerFormForm.get('servicingAgentCode')?.hasError('required')">
                  Please input agent code
                </cds-assistive-text>
                <cds-assistive-text
                  class="l-mt-3"
                  *ngIf="employerFormForm.get('servicingAgentCode')?.dirty && employerFormForm.get('servicingAgentCode')?.hasError('unMatch')">
                  Invalid agent code
                </cds-assistive-text>
              </div>
            </div>
            <div class="item-box" style="flex-wrap: unset; margin-top: var(--cds-spacing-04)">
              <cds-checkbox formControlName="isServicingAgentCodePerformed"></cds-checkbox>
              <div class="l-ml-4">
                <span class="cds-body2" style="color: #000000"
                  >I have performed the MPF Intermediary Fulfillment requirements in carrying out the regulated activities with the customer.</span
                >
              </div>
            </div>
            <ng-container *ngFor="let splitAgent of splitAgents">
              <div [ngStyle]="{ display: isConfirming && splitAgent.code.value ? 'unset' : 'none' }">
                <div class="item-box l-mt-0">
                  <div style="width: 270px" class="l-mt-5">
                    <span class="cds-body1-demibold">Split Agent Code</span>
                  </div>
                  <div class="l-mt-5" style="max-width: 560px; min-width: 265px; width: 560px">
                    <span class="cds-body1"
                      >{{ splitAgent?.code?.value }} {{ getAgentName(splitAgent || undefined) }} (District Code: {{ splitAgent?.branchNo }})</span
                    >
                  </div>
                </div>
                <div class="item-box" style="flex-wrap: unset; margin-top: var(--cds-spacing-04)">
                  <cds-checkbox [disabled]="true" [checked]="splitAgent.isSplitAgentCodePerformed.value"></cds-checkbox>
                  <div class="l-ml-4">
                    <span class="cds-body2" style="color: #000000"
                      >I have performed the MPF Intermediary Fulfillment requirements in carrying out the regulated activities with the customer.</span
                    >
                  </div>
                </div>
              </div>
              <div [ngStyle]="{ display: !isConfirming ? 'unset' : 'none' }">
                <div class="item-box l-mt-0">
                  <div style="width: 270px" class="l-mt-5">
                    <span class="cds-body1-demibold">Split Agent Code</span>
                  </div>
                  <div class="offsets l-mt-5" style="max-width: 560px; min-width: 265px; width: 560px">
                    <app-cd-input
                      ngDefaultControl
                      [formControl]="splitAgent.code"
                      (valueChange)="splitAgent.isMatchError = false"
                      [disable]="!!employerFormForm.get('servicingAgentCode')?.hasError('required')"
                      [type]="'number'"
                      maxlength="6"
                      placeholder="Input Agent Code (Optional)"></app-cd-input>
                    <cds-assistive-text class="l-mt-3" *ngIf="splitAgent.isMatchError"> Invalid agent code </cds-assistive-text>
                  </div>
                </div>
                <div class="item-box" style="flex-wrap: unset; margin-top: var(--cds-spacing-04)">
                  <cds-checkbox [formControl]="splitAgent.isSplitAgentCodePerformed"></cds-checkbox>
                  <div class="l-ml-4">
                    <span class="cds-body2" style="color: #000000"
                      >I have performed the MPF Intermediary Fulfillment requirements in carrying out the regulated activities with the customer.</span
                    >
                  </div>
                </div>
              </div>
            </ng-container>
            <!-- <div *ngIf="!isConfirming" class="item-box add-new-split-agent-top-border">
              <div (click)="addNewSplitAgent()" class="l-d-f l-ai-cen add-new-split-agent-box">
                <cds-icon
                  style="--cds-icon-color: {{ splitAgents.length >= 9 ? 'var(--cds-text-placeholder)' : 'var(--cds-bg-cta-primary)' }}"
                  icon="action:button_plus_filled"></cds-icon>
                <div style="margin-left: 10px">
                  <span class="cds-body2-demibold" [ngStyle]="{ color: splitAgents.length >= 9 ? 'var(--cds-text-placeholder)' : '' }"
                    >Add new split agent</span
                  >
                </div>
              </div>
            </div> -->
          </div>
          <div class="information-box" *ngIf="isDBschannel === 'Y'">
            <div class="cds-body-hero-light">dbs</div>
            <div class="item-list-box" style="border: 0px">
              <div class="item-box l-mt-0">
                <div style="width: 270px" class="l-mt-5">
                  <span class="cds-body1-demibold">RM Referral Code:</span>
                </div>
                <div *ngIf="isConfirming" class="l-mt-5" style="max-width: 560px; min-width: 265px; width: 560px">
                  <span class="cds-body1">{{ employerFormForm.get('rmReferralCode')?.value }} </span>
                </div>
                <div *ngIf="!isConfirming" class="offsets l-mt-5" style="max-width: 560px; min-width: 265px; width: 560px">
                  <app-cd-input
                    [type]="'number'"
                    [group]="employerFormForm"
                    controlName="rmReferralCode"
                    [maxlength]="8"
                    ngDefaultControl
                    [placeholder]="'Input RM Referral Code'"></app-cd-input>

                  <div class="l-mt-1">
                    <cds-assistive-text
                      *ngIf="employerFormForm.get('rmReferralCode')?.dirty && employerFormForm.get('rmReferralCode')?.hasError('required'); else second"
                      type="error"
                      >Please input valid RM Referral Code
                    </cds-assistive-text>
                    <ng-template #second>
                      <cds-assistive-text
                        *ngIf="employerFormForm.get('rmReferralCode')?.dirty && employerFormForm.get('rmReferralCode')?.hasError('lengthError')"
                        type="error"
                        >Please input valid RM Referral Code
                      </cds-assistive-text>
                    </ng-template>
                  </div>
                </div>
              </div>

              <div class="item-box l-mt-0">
                <div style="width: 270px" class="l-mt-5">
                  <span class="cds-body1-demibold">Customer ID:</span>
                </div>
                <div *ngIf="isConfirming" class="l-mt-5" style="max-width: 560px; min-width: 265px; width: 560px">
                  <span class="cds-body1">{{ employerFormForm.get('inputCustomerId')?.value }} </span>
                </div>
                <div *ngIf="!isConfirming" class="offsets l-mt-5" style="max-width: 560px; min-width: 265px; width: 560px">
                  <app-cd-input
                    [group]="employerFormForm"
                    controlName="inputCustomerId"
                    [maxlength]="8"
                    [regExpLimit]="'^[A-Za-z0-9-]*$'"
                    ngDefaultControl
                    [placeholder]="'Input Customer ID (Optional)'"></app-cd-input>
                  <div class="l-mt-1">
                    <cds-assistive-text
                      *ngIf="employerFormForm.get('inputCustomerId')?.dirty && employerFormForm.get('inputCustomerId')?.hasError('lengthError')"
                      type="error"
                      >Please input valid Customer ID
                    </cds-assistive-text>
                  </div>
                </div>
              </div>

              <div class="item-box l-mt-0">
                <div style="width: 270px" class="l-mt-5">
                  <span class="cds-body1-demibold">Campaign Code:</span>
                </div>
                <div *ngIf="isConfirming" class="l-mt-5" style="max-width: 560px; min-width: 265px; width: 560px">
                  <span class="cds-body1">{{ employerFormForm.get('campaignCode')?.value }} </span>
                </div>
                <div *ngIf="!isConfirming" class="offsets l-mt-5" style="max-width: 560px; min-width: 265px; width: 560px">
                  <app-cd-input
                    [group]="employerFormForm"
                    controlName="campaignCode"
                    [maxlength]="10"
                    [regExpLimit]="'^[A-Za-z0-9-]*$'"
                    ngDefaultControl
                    [placeholder]="'Input Campaign Code (Optional)'"></app-cd-input>
                  <div class="l-mt-1">
                    <cds-assistive-text
                      *ngIf="employerFormForm.get('campaignCode')?.dirty && employerFormForm.get('campaignCode')?.hasError('lengthError')"
                      type="error"
                      >Please input valid Campaign Code
                    </cds-assistive-text>
                  </div>
                </div>
              </div>

              <div class="item-box l-mt-0">
                <div style="width: 270px" class="l-mt-5">
                  <span class="cds-body1-demibold">Source Code:</span>
                </div>
                <div *ngIf="isConfirming" class="l-mt-5" style="max-width: 560px; min-width: 265px; width: 560px">
                  <span class="cds-body1">{{ employerFormForm.get('sourceCode')?.value }} </span>
                </div>
                <div *ngIf="!isConfirming" class="offsets l-mt-5" style="max-width: 560px; min-width: 265px; width: 560px">
                  <app-cd-input
                    [group]="employerFormForm"
                    controlName="sourceCode"
                    [maxlength]="10"
                    [regExpLimit]="'^[A-Za-z0-9-]*$'"
                    ngDefaultControl
                    [placeholder]="'Input Source Code (Optional)'"></app-cd-input>
                  <div class="l-mt-1">
                    <cds-assistive-text
                      *ngIf="employerFormForm.get('sourceCode')?.dirty && employerFormForm.get('sourceCode')?.hasError('lengthError')"
                      type="error"
                      >Please input valid Source Code
                    </cds-assistive-text>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="!isConfirming" class="l-mt-6 l-d-f">
        <div>
          <cds-button (click)="reset()" [style]="'secondary'">{{ 'common.reset1' | lang }}</cds-button>
        </div>
        <div class="l-ml-4">
          <cds-button (click)="confirm()">{{ 'common.submit' | lang }}</cds-button>
        </div>
      </div>

      <div *ngIf="isConfirming" class="l-mt-6 l-d-f">
        <div>
          <cds-button (click)="backToEdit()" [style]="'secondary'">{{ 'common.backToEdit' | lang }}</cds-button>
        </div>
        <div class="l-ml-4">
          <cds-button (click)="submit()">{{ 'common.confirmAndSubmit' | lang }}</cds-button>
        </div>
      </div>
    </div>
  </form>
</div>
