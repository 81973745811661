import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-auto-change-list',
  templateUrl: './auto-change-list.component.html',
  styleUrls: ['./auto-change-list.component.scss'],
})
export class AutoChangeListComponent implements OnInit {
  constructor(private dialogRef: MatDialogRef<AutoChangeListComponent>) {}

  ngOnInit() {}

  gotIt() {
    this.dialogRef.close();
  }
}
