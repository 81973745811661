<div class="simple-popup">
  <div>
    <span class="cds-body-hero" style="font-weight: 300">{{ data.message | lang }}</span>
  </div>
  <div class="l-mt-7">
    <div class="botton-list">
      <cds-button *ngFor="let button of data.buttons" [style]="button.style" [size]="button.size" (click)="button.cb()">{{ button.text | lang }}</cds-button>
    </div>
  </div>
</div>
