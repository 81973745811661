/* eslint-disable @typescript-eslint/no-explicit-any */
import { AbstractControl, FormControl, ValidationErrors } from '@angular/forms';

export const journalValidator = (errors?: ValidationErrors) => {
  return (control: AbstractControl): ValidationErrors | null => {
    const accountType = control.get('accountType') as FormControl;
    const originalErMerCode = control.get('originalErMerCode') as FormControl;
    if (!accountType.value && originalErMerCode.value) {
      return errors || { journalValidator: true };
    }
    return null;
  };
};
