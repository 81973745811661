/* eslint-disable @typescript-eslint/no-explicit-any */
import { deepCopy } from 'src/app/utils/copy';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { CdsButtonConfig } from '@cds/ng-core/button';
import { CdsDropdownConfig } from '@cds/ng-core/dropdown';
import { ColumnSort } from '../../employer/employer';
import { CdsCheckboxConfig } from '@cds/ng-core/checkbox';
import { Router } from '@angular/router';
import { PermissionAccess, PermissionItem } from 'src/app/core/models/enum/permission.enum';
import { Sort } from 'src/app/components/table/table-expand';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { dateValidator } from 'src/app/shared/validators/validators';
import { finalize } from 'rxjs';
import { ToastAlertService } from 'src/app/shared/toast-alert.service';
import { CurrencyE, ErSearchRequest } from '../../employer/employer';
import { EmployerService } from '../../employer/employer.service';
import { DateTime } from 'luxon';
import { EmployeeService } from '../employee.service';
import {
  ClaimTypeReasonE,
  PolicyTransfer,
  PolicyTransferRequest,
  PolicyWithdrawalRequest,
  claimTypeReasonMapObj,
  claimTypeReasonOptions,
  paymentOutStatusOptions,
  policyWithdrawalStatusCodeMapObj,
  policyWithdrawalStatusCodeOptions,
} from '../employee';
import { ColumnConfig, PageConfig, SortMode } from 'src/app/shared/data-table';

@Component({
  selector: 'app-policy-withdrawal',
  templateUrl: './policy-withdrawal.component.html',
  styleUrls: ['./policy-withdrawal.component.scss'],
})
export class PolicyWithDrawalComponent implements OnInit, OnChanges {
  @Input() id = '';

  permissionAccess = PermissionAccess;
  permissionItem = PermissionItem;
  public Sort = Sort;
  form: FormGroup = new FormGroup({});
  formReady = false;
  showTable = true;

  showDatePicker = true;

  employerNameValidTip = 'Please input English, Chinese, numbers, or special characters';

  get searchButtonDisabled() {
    return !this.form?.valid || !this.form.dirty;
  }

  resetButtonDisabled = true;

  get withdrawalReferenceNo() {
    return this.form?.get('withdrawalReferenceNo');
  }

  get groupReferenceNo() {
    return this.form?.get('groupReferenceNo');
  }

  get paymentOutReferenceNo() {
    return this.form?.get('paymentOutReferenceNo');
  }

  get originalSubmissionDate() {
    return this.form?.get('originalSubmissionDate');
  }

  get withdrawalStatus() {
    return this.form?.get('withdrawalStatus');
  }

  get paymentOutStatus() {
    return this.form?.get('paymentOutStatus');
  }

  get claimTypeReason() {
    return this.form?.get('claimTypeReason');
  }

  get completedReceiptDateOfClaim() {
    return this.form?.get('completedReceiptDateOfClaim');
  }

  tempSeardhParams: ErSearchRequest = {
    page: 1,
    size: 10,
  };

  policyWithdrawalStatusCodeMapObj = policyWithdrawalStatusCodeMapObj;

  paymentOutStatusOptions = paymentOutStatusOptions;

  constructor(private service: EmployeeService, private router: Router, private toastAlert: ToastAlertService, public employerService: EmployerService) {}

  statusCodeConfig: CdsDropdownConfig = {
    label: 'Withdrawal Status',
    placeholder: 'Select Withdrawal Status',
    options: policyWithdrawalStatusCodeOptions,
  };

  paymentOutStatusConfig: CdsDropdownConfig = {
    label: 'Payment Out Status',
    placeholder: 'Select Payment Out Status',
    options: paymentOutStatusOptions,
  };

  claimTypeReasonConfig: CdsDropdownConfig = {
    label: 'Completed Receipt Date of Claim',
    placeholder: 'Select Completed Receipt Date of Claim',
    options: claimTypeReasonOptions,
  };

  nowTimeInstr = DateTime.now().toFormat('dd/MM/yyyy');

  isStaff = false;

  searchButtonConfig: CdsButtonConfig = {
    size: 'sm',
  };

  resetButtonConfig: CdsButtonConfig = {
    size: 'sm',
    style: 'secondary',
  };

  totalElements = 0;

  dataSource: PolicyTransfer[] = [];

  pageConfig: PageConfig = {
    current: 1,
    pageSize: 10,
    totalCount: 0,
  };

  columnSort: ColumnSort = {
    key: 'refNo',
    value: SortMode.UP,
  };

  columnsConfig: ColumnConfig[] = [
    {
      key: 'withdrawalReferenceNo',
      title: 'Withdrawal Reference No.',
      sort: SortMode.DEFAULT,
    },
    {
      key: 'groupReferenceNo',
      title: 'Group Reference No.',
    },
    {
      key: 'paymentOutReferenceNo',
      title: 'Payment Out Reference No.',
    },
    {
      key: 'claimTypeReason',
      title: 'Claim Type Reason',
    },
    {
      key: 'withdrawalStatus',
      title: 'Withdrawal Status',
    },
    {
      key: 'paymentOutStatus',
      title: 'Payment Out Status',
    },
    {
      key: 'originalSubmissionDate',
      title: 'Original Submission Date',
    },
    {
      key: 'completedReceiptDateOfClaim',
      title: 'Completed Receipt Date of Claim',
    },
  ];

  isLoading = false;

  noCheckConfig: CdsCheckboxConfig = {
    disabled: true,
    checked: false,
  };

  checkedConfig: CdsCheckboxConfig = {
    disabled: true,
    checked: true,
  };

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['id'].currentValue !== undefined && changes['id'].currentValue !== changes['id'].previousValue) {
      this.loadTable();
    }
  }

  ngOnInit(): void {
    document.documentElement.scrollTop = 0;
    this.isLoading = true;
    this.initForm();
    this.loadTable();
  }

  initForm() {
    this.form?.markAsUntouched();
    this.form?.markAsPristine();

    this.form = new FormGroup({
      withdrawalReferenceNo: new FormControl(''),
      groupReferenceNo: new FormControl(''),
      paymentOutReferenceNo: new FormControl(''),
      originalSubmissionDate: new FormControl('', [dateValidator('create.dateError', '', this.nowTimeInstr)]),
      withdrawalStatus: new FormControl(''),
      paymentOutStatus: new FormControl(''),
      claimTypeReason: new FormControl(''),
      completedReceiptDateOfClaim: new FormControl('', [dateValidator('create.dateError', '', this.nowTimeInstr)]),
    });
    this.formReady = false;
    setTimeout(() => {
      this.formReady = true;
    });
  }

  search() {
    if (this.searchButtonDisabled) return;
    this.resetButtonDisabled = false;
    this.tempSeardhParams = deepCopy(this.getTempSearchParams());
    this.loadTable();
  }

  goToDetail(withdrawalReferenceNo?: string) {
    this.router.navigate(['/employee/policy-withdrawal-detail'], {
      queryParams: {
        withdrawalReferenceNo,
        id: this.id,
      },
    });
  }

  reset() {
    if (this.resetButtonDisabled) return;
    this.resetButtonDisabled = true;
    this.initForm();
    // reloadTable to reset sort
    this.showTable = false;
    this.showDatePicker = false;
    setTimeout(() => {
      this.showTable = true;
      this.showDatePicker = true;
      this.tempSeardhParams = { page: 1, size: 10 };
      this.loadTable();
    });
  }

  loadTable() {
    this.isLoading = true;

    const params: ErSearchRequest = this.getRequestParams();

    this.service
      .getPolicyWithdrawalList(params)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe(data => {
        if (data.result === 0 && data.data) {
          this.isLoading = false;
          this.dataSource = data.data.empty ? [] : data.data.content;
          this.totalElements = data.data.totalElements;

          this.pageConfig = {
            ...this.pageConfig,
            totalCount: data.data.totalElements,
          };
        } else {
          this.toastError(data.message);
        }
      });
  }

  getTempSearchParams(sort = '') {
    const params: PolicyTransferRequest = {
      ...this.tempSeardhParams,
      ...(this.form.valid ? this.form.value : {}),
      page: (this.pageConfig.current as number) - 1,
      size: 10,
    };

    if (sort) {
      params.sort = sort;
    }
    return params;
  }

  getRequestParams() {
    const params: PolicyWithdrawalRequest = {
      ...this.tempSeardhParams,
      page: (this.pageConfig.current as number) - 1,
      size: 10,
      id: this.id,
    };
    params.sort = `${this.columnSort.key},${this.columnSort.value}`;
    params.originalSubmissionDate = params.originalSubmissionDate ? this.dateTrans(params.originalSubmissionDate) : '';
    params.completedReceiptDateOfClaim = params.completedReceiptDateOfClaim ? this.dateTrans(params.completedReceiptDateOfClaim) : '';
    return params;
  }

  toastError(errorMessage: string) {
    this.toastAlert.show('error', 'common.error', errorMessage, 5000);
  }

  amountCUrrency(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const currencyControl = control.get('aggregateInvestedAmountCurrency');
      const amountFromControl = control.get('aggregateInvestedAmountFrom');
      const amountToControl = control.get('aggregateInvestedAmountTo');
      const currency = currencyControl?.value;
      const amountFrom = amountFromControl?.value;
      const amountTo = amountToControl?.value;

      const fromErrors = amountFromControl?.errors ? deepCopy(amountFromControl?.errors) : {};
      const toErrors = amountToControl?.errors ? deepCopy(amountToControl?.errors) : {};

      if (currency !== CurrencyE.HKD && amountFrom === '' && amountTo === '') {
        fromErrors['required'] = true;
        toErrors['required'] = true;

        amountFromControl?.markAsTouched();
        amountToControl?.markAsTouched();

        amountFromControl?.setErrors(fromErrors);
        amountToControl?.setErrors(toErrors);

        return null;
      }

      delete fromErrors['required'];
      delete toErrors['required'];
      amountFromControl?.setErrors(Object.keys(fromErrors).length === 0 ? null : fromErrors);
      amountToControl?.setErrors(Object.keys(toErrors).length === 0 ? null : toErrors);

      return null;
    };
  }

  reloadTable(event: any) {
    this.pageConfig = {
      ...this.pageConfig,
      current: event.current,
    };
    this.columnSort =
      event.column && event.column.sort !== SortMode.DEFAULT
        ? {
            key: event.column?.key === 'withdrawalReferenceNo' ? 'refNo' : event.column?.key,
            value: event.column?.sort,
          }
        : {
            key: 'refNo',
            value: SortMode.UP,
          };
    this.loadTable();
  }

  dateTrans(date: string) {
    if (date) {
      return DateTime.fromFormat(date, 'dd/MM/yyyy').toFormat('yyyy-MM-dd');
    }

    return date;
  }

  claimTypeReasonRender(type: ClaimTypeReasonE) {
    return claimTypeReasonMapObj[type] || '';
  }
}
