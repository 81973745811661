import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { CdsLanguageModule } from '@cds/ng-core/lang';
import { CdsAssistiveTextModule } from '@cds/ng-web-components/assistive-text';
import { CdsIconModule } from '@cds/ng-web-components/icon';
import { AutocompleteComponent } from './autocomplate.component';
import { AutocompletePositionDirective } from './autocomplete-position';

@NgModule({
  declarations: [AutocompletePositionDirective, AutocompleteComponent],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, MatAutocompleteModule, CdsAssistiveTextModule, CdsLanguageModule, CdsIconModule],
  exports: [AutocompletePositionDirective, AutocompleteComponent],
})
export class AutocompleteModule {}
