import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ConsoleToogleServiceService {
  constructor() {}

  disableConsoleInProduction(): void {
    console.log(environment.profile);
    if (environment.profile == 'uat' || environment.profile == 'prod') {
      console.log = function (): void {};
      console.debug = function (): void {};
      console.warn = function (): void {};
      console.info = function (): void {};
    }
  }
}
