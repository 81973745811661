import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DragDropModule } from '@angular/cdk/drag-drop';

import { CdsLanguageModule } from '@cds/ng-core/lang';
import { CdsButtonModule } from '@cds/ng-web-components/button';
import { SharedModule } from 'src/app/shared/shared.module';
import { WelcomeComponent } from './welcome.component';
import { UserComponent } from '../chatbot/user/user.component';
import { WidgetsModule } from '../widgets/widgets.module';
import { DynamicContentModule } from '../dynamic-content/dynamic-content.module';
import { CdsIconModule } from '@cds/ng-web-components/icon';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CdsDialogModule } from '@cds/ng-experimental/dialog';
import { MatDialogModule } from '@angular/material/dialog';
import { CloseChatConfirmComponent } from '../chatbot/close-chat-confirm/close-chat-confirm.component';

@NgModule({
  imports: [
    CommonModule,
    CdsLanguageModule,
    CdsButtonModule,
    SharedModule,
    DragDropModule,
    WidgetsModule,
    DynamicContentModule,
    CdsIconModule,
    FormsModule,
    MatDialogModule,
    ReactiveFormsModule,
    CdsDialogModule,
  ],
  declarations: [WelcomeComponent, UserComponent, CloseChatConfirmComponent],
  entryComponents: [CloseChatConfirmComponent],
})
export class WelcomeModule {}
