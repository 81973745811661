import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filterParams } from 'src/app/core/models/request';
import { BasicResponse, PageResponse } from 'src/app/core/models/response/response';
import { environment } from 'src/environments/environment';
import {
  AgentPerformanceHeadDTO,
  AgentPerformanceHeadSearchDTO,
  AgentReassignmentMemberCountRequet,
  AgentReassignmentMemberProcessRequet,
  AutoRollover,
  AutoRolloverSearchRequest,
  Employer,
  RelatedMemberResponse,
  RelatedMemberRequest,
} from './shk';

@Injectable({
  providedIn: 'root',
})
export class ShkService {
  private customer_url = `${environment.apiPrefix}/ext/eb-ssms/customer-service/`;
  private autoRolloverListUrl = this.customer_url + 'shkp/auto-rollover/member-record/list';
  private autoRolloverPaymentListUrl = this.customer_url + 'shkp/auto-rollover/transfer-record/list';
  private autoRolloverPaymentHoldUrl = this.customer_url + 'shkp/auto-rollover/transfer-record/hold';
  private autoRolloverPaymentUnholdUrl = this.customer_url + 'shkp/auto-rollover/transfer-record/unHold';
  private autoRolloverMemberHoldUrl = this.customer_url + 'shkp/auto-rollover/member-record/hold';
  private autoRolloverMemberUnholdUrl = this.customer_url + 'shkp/auto-rollover/member-record/unHold';
  private agentReassignmentSelectEmployer = this.customer_url + 'shkp/agent-reassignment/selectEmployer';
  private agentReassignmentCheckAgentCodeUrl = this.customer_url + 'shkp/agent-reassignment/checkAgentCode';
  private agentReassignmentMemberCount = this.customer_url + 'shkp/agent-reassignment/memberCount';
  private agentReassignmentProcess = this.customer_url + 'shkp/agent-reassignment/process';
  private agentPerformanceHeadUrl = this.customer_url + 'shkp/terminated-members/headList';
  private agentPerformanceEnrolmentHeadUrl = this.customer_url + 'shkp/enrolment-members/headList';
  private getRelatedMemberUrl = this.customer_url + 'shkp/agent-reassignment/getRelatedMembers';

  constructor(private http: HttpClient) {}

  getAutoRolloverList(params: AutoRolloverSearchRequest): Observable<PageResponse<AutoRollover>> {
    return this.http.get<PageResponse<AutoRollover>>(this.autoRolloverListUrl, {
      params: filterParams(params),
    });
  }

  getAutoRolloverPaymentList(params: AutoRolloverSearchRequest): Observable<PageResponse<AutoRollover>> {
    return this.http.get<PageResponse<AutoRollover>>(this.autoRolloverPaymentListUrl, {
      params: filterParams(params),
    });
  }

  changeMemberStatus(isHold: boolean, ids: string[]) {
    return this.http.post<BasicResponse<boolean>>(`${isHold ? this.autoRolloverMemberHoldUrl : this.autoRolloverMemberUnholdUrl}`, { ids });
  }
  //http://localhost:8082/shkp/agent-reassignment/selectEmployer
  getAgentReassignmentSelectEmployer(agentCodes: string): Observable<BasicResponse<Employer[]>> {
    return this.http.get<BasicResponse<Employer[]>>(this.agentReassignmentSelectEmployer, {
      params: { agentCodes },
    });
  }
  //http://localhost:8082/shkp/agent-reassignment/getRelatedMembers
  getRelatedMember(params: RelatedMemberRequest): Observable<BasicResponse<RelatedMemberResponse[]>> {
    return this.http.get<BasicResponse<RelatedMemberResponse[]>>(this.getRelatedMemberUrl, {
      params: filterParams(params),
    });
  }

  agentReassignmentCheckAgentCode(agentCodes: string): Observable<BasicResponse<boolean>> {
    return this.http.get<BasicResponse<boolean>>(this.agentReassignmentCheckAgentCodeUrl, {
      params: { agentCodes },
    });
  }

  getAgentReassignmentMemberCount(params: AgentReassignmentMemberCountRequet): Observable<BasicResponse<number>> {
    return this.http.get<BasicResponse<number>>(this.agentReassignmentMemberCount, {
      params: { ...params },
    });
  }

  //'http://localhost:8082/shkp/agent-reassignment/process'
  getAgentReassignmentProcess(params: AgentReassignmentMemberProcessRequet) {
    return this.http.post<BasicResponse<boolean>>(this.agentReassignmentProcess, params);
  }

  getAgentPerformanceHeadList(params: AgentPerformanceHeadSearchDTO) {
    const requestParams: AgentPerformanceHeadSearchDTO = {
      ...params,
      // sort: columnSort ? `${columnSort.key},${columnSort.value}` : '',
    };
    return this.http.get<BasicResponse<AgentPerformanceHeadDTO[]>>(this.agentPerformanceHeadUrl, {
      params: filterParams(requestParams),
    });
  }
  getAgentPerformanceHeadEnrolmentList(params: AgentPerformanceHeadSearchDTO) {
    const requestParams: AgentPerformanceHeadSearchDTO = {
      ...params,
      // sort: columnSort ? `${columnSort.key},${columnSort.value}` : '',
    };
    //http://localhost:8082/shkp/enrolment-members/allList
    return this.http.get<BasicResponse<AgentPerformanceHeadDTO[]>>(this.agentPerformanceEnrolmentHeadUrl, {
      params: filterParams(requestParams),
    });
  }
  changePaymentStatus(isHold: boolean, ids: string[]) {
    return this.http.post<BasicResponse<boolean>>(`${isHold ? this.autoRolloverPaymentHoldUrl : this.autoRolloverPaymentUnholdUrl}`, { ids });
  }
}
