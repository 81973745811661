import { CdsOption } from '@cds/ng-core/configuration';
import { BreakpointType, CalculationType, ScalePattern, ScaleType } from 'src/app/core/models/enum/commission-scale.enum';

export const SCALE_TYPE_OPTIONS: CdsOption[] = [
  { label: 'commission.scale.type.fspc', value: ScaleType.FS_PC },
  { label: 'commission.scale.type.fssf', value: ScaleType.FS_SF },
  { label: 'commission.scale.type.fy', value: ScaleType.FY },
  { label: 'commission.scale.type.ps', value: ScaleType.PS },
  { label: 'commission.scale.type.pspc', value: ScaleType.PS_PC },
  { label: 'commission.scale.type.pssf', value: ScaleType.PS_SF },
  { label: 'commission.scale.type.rypc', value: ScaleType.RY_PC },
  { label: 'commission.scale.type.trf_sp', value: ScaleType.TRF_SP },
  { label: 'commission.scale.type.trf_sppc', value: ScaleType.TRF_SP_PC },
];

export const SCALE_PATTERN_OPTIONS: CdsOption[] = [
  { label: 'commission.scale.pattern.flat', value: ScalePattern.FLAT },
  { label: 'commission.scale.pattern.sliding', value: ScalePattern.SLIDING },
];

export const CALCULATION_TYPE_OPTIONS: CdsOption[] = [
  { label: 'commission.calculation.type.fy', value: CalculationType.FY },
  { label: 'commission.calculation.type.ph', value: CalculationType.PH },
];

export const BREAKPOINT_TYPE_OPTIONS: CdsOption[] = [
  { label: 'commission.breakpoint.type.p', value: BreakpointType.P },
  { label: 'commission.breakpoint.type.y', value: BreakpointType.Y },
];

export const SCALE_CODE_COLUMNS = ['scaleCode', 'scaleType', 'scalePattern'];

export const DEFAULT_BREAKPOINT = {
  amount: null,
  percentage: null,
};

export const SCALE_TYPE_CONFIG = {
  label: 'commission.scale.type.label',
  placeholder: 'commission.scale.type.placeholder',
};
export const SCALE_PATTERN_CONFIG = {
  label: 'commission.scale.pattern.label',
  placeholder: 'commission.scale.pattern.placeholder',
};
