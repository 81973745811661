import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { CdsPopupService } from '@cds/ng-web-components/popup';
import { DataService } from '../../_services/data.service';
import { CannedService } from '../../_services/canned.service';
import { ActionState } from '../../_common/_interface';

@Component({
  selector: 'app-supervisor-dashboard',
  templateUrl: './supervisor-dashboard.component.html',
  styleUrls: ['./supervisor-dashboard.component.scss'],
})
export class SupervisorDashboardComponent implements OnInit {
  loading: boolean = false;
  count = 0;
  rows = [];
  modalOptions: NgbModalOptions = {};
  modalRef: any;
  stateModel: ActionState = { data: null, isNew: false, isReadonly: false, isEdit: false };
  headers = [
    { key: 'agentName', title: 'CSO' },
    { key: 'status', title: 'Status' },
    { key: 'activeChats', title: 'Active Chats' },
    { key: 'timeEngaged', title: 'Time Engaged' },
    { key: 'lastMessageTime', title: 'Last Message Time' },
  ];

  constructor(public modalService: NgbModal, public dataService: DataService, public cannedService: CannedService, private cdsPopup: CdsPopupService) {}

  ngOnInit(): void {
    this.getSupervisorData();
  }

  getSupervisorData() {
    this.loading = true;
    this.cannedService.getSupervisorData().subscribe(resp => {
      this.loading = false;
      if (resp) {
        this.rows = resp;
      }
    });
  }
}
