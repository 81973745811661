import { Directive, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appDisableFormControl]',
  exportAs: 'appDisableFormControl',
})
export class DisableFormControlDirective {
  @Input() set appDisableFormControl(condition: boolean) {
    if (condition) {
      this.ngControl.control?.disable();
    } else {
      this.ngControl.control?.enable();
    }
  }

  constructor(private ngControl: NgControl) {}
}
