export class ErChanegSummaryDetailResponse {
  result?: number;
  data?: HistoryResponseBody;
  message?: string;
}

export interface HistoryResponseBody {
  content: HistoryResponseData[];
  pageable: PageResponseData;
  last: boolean;
  totalPages: number;
  totalElements: number;
  first: boolean;
  numberOfElements: number;
  size: number;
  number: number;
  empty: boolean;
}

export interface HistoryResponseData {
  requestCreateDate: string;
  status?: string;
  employerInfo: EmployerInfo;
  items: Items[];
  isShowDetail: boolean;
}

export interface Items {
  submitAgent: Agents;
  oldAgent: Agents;
  targetAgent: Agents;
  employeeData: employeeInfo;
  reason: reason;
}

export interface employeeInfo {
  employeeId?: string;
  employeeName?: string;
  employeeNameTC?: string;
  sex?: string;
  joinTheProgramDate?: string;
  certificateNumber?: string;
  age?: number;
}

export interface reason {
  code?: number;
  message?: string;
}

export interface Agents {
  agentNameTC?: string;
  agentName?: string;
  agentCode?: string;
}

export interface EmployerInfo {
  id?: string;
  employerNo?: string;
  employerName?: string;
  employerNameTC?: string;
}

export interface PageResponseData {
  pageNumber: number;
  pageSize: number;
  offset?: number;
  paged?: boolean;
  unpaged?: boolean;
}
