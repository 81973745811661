export interface ColumnConfig {
  key: string;
  title: string;
  sort?: SortMode;
  sticky?: boolean;
  stickyEnd?: boolean;
}

export interface PageConfig {
  pageSize?: number;
  totalCount?: number;
  current?: number;
}

export enum SortMode {
  DEFAULT = 'none',
  UP = 'asc',
  DOWN = 'desc',
}
