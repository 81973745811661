// idle.service.ts
import { Injectable, NgZone } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class IdleService {
  private timeoutInMs: any; // 3 minutes
  private userTimeoutInMs: any; // 3 minutes
  private idleCSOTimer: any;
  public onCSOIdle = new Subject<void>();
  private idleUserTimer: any;
  public onUserIdle = new Subject<void>();

  constructor(private ngZone: NgZone) {}

  public startSCOListening(timeoutInMs: any) {
    this.timeoutInMs = timeoutInMs;
    ['click', 'mousemove', 'keypress'].forEach(event => {
      window.addEventListener(event, () => this.resetSCOTimer(), true);
    });
    this.resetSCOTimer();
  }

  private resetSCOTimer() {
    if (this.idleCSOTimer) {
      clearTimeout(this.idleCSOTimer);
    }
    this.ngZone.runOutsideAngular(() => {
      this.idleCSOTimer = setTimeout(() => {
        this.ngZone.run(() => this.onCSOIdle.next());
      }, this.timeoutInMs);
    });
  }

  public startUserListening(userTimeoutInMs: number) {
    this.userTimeoutInMs = userTimeoutInMs;
    this.resetTimer();
  }

  private resetTimer() {
    if (this.idleUserTimer) {
      clearTimeout(this.idleUserTimer);
    }
    this.ngZone.runOutsideAngular(() => {
      this.idleUserTimer = setTimeout(() => {
        this.ngZone.run(() => this.onUserIdle.next());
      }, this.userTimeoutInMs);
    });
  }

  public clearTimer() {
    if (this.idleUserTimer) {
      clearTimeout(this.idleUserTimer);
    }
  }
}
