import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { CdsLangService } from '@cds/ng-core/lang';
import { CdsRadioConfig } from '@cds/ng-core/radio';
import { CdsAlertService } from '@cds/ng-web-components/alert';
import { CdsPopupService } from '@cds/ng-web-components/popup';
import { CdsToastService } from '@cds/ng-web-components/toast';
import { finalize } from 'rxjs';
import { PermissionAccess, PermissionItem } from 'src/app/core/models/enum/permission.enum';
import { PermissionService } from 'src/app/core/services/permission.service';
import { AlertPopupComponent } from 'src/app/shared/alert-popup/alert-popup.component';
import { IPaginationCount } from 'src/app/shared/pagination/pagination.dto';
import { toPageableRequest } from 'src/app/utils/pagination.utils';
import { EmployerProfileDetail, ErRecord, RelationshipTotalCount } from '../employer';
import { EditCompanyListComponent } from './edit-company-list/edit-company-list.component';
import { CompanyGroupListRequest } from './employer-profile-company-list';
import { EmployerProfileCompanyListService } from './employer-profile-company-list.service';

@Component({
  selector: 'app-employer-profile-company-list',
  templateUrl: './employer-profile-company-list.component.html',
  styleUrls: ['./employer-profile-company-list.component.scss'],
})
export class EmployerProfileCompanyListComponent implements OnInit {
  @Input() employerDetaildata?: EmployerProfileDetail;
  @Input() relationshipTotalCount?: RelationshipTotalCount = {};

  @Output() updateTotalScore = new EventEmitter();

  dataSource: ErRecord[] = [];
  totoalCountDataSource: RelationshipTotalCount[] = [];
  displayedColumns: string[] = [
    'employerNo',
    'employerName',
    'totalLives',
    'isParentCompany',
    'relationshipScore',
    'aggregateInvestedAmount',
    'annualizedContribution',
  ];
  displayedTotalCountColumns: string[] = ['totalAggregateInvestedAmount', 'totalAnnualizedContribution'];
  isLoading = false;
  form: FormGroup = new FormGroup({});
  radioConfig: CdsRadioConfig = {
    options: [{ label: '', value: 1 }],
  };
  isChecked = true;

  editable = false;

  id: string | undefined;

  PermissionAccess = PermissionAccess;

  PermissionItem = PermissionItem;

  pageCount: IPaginationCount = { current: 1, count: 1 };

  isStaff = false;

  constructor(
    private cdsPopup: CdsPopupService,
    private toast: CdsToastService,
    private langService: CdsLangService,
    public companyService: EmployerProfileCompanyListService,
    private alert: CdsAlertService,
    private permissionService: PermissionService
  ) {}

  ngOnInit(): void {
    this.id = this.employerDetaildata?.id;
    this.permissionFilter();
    this.loadTable();
  }

  permissionFilter() {
    this.permissionService.hasPermission(PermissionAccess.R, PermissionItem.CUSTOMER_EMPLOYER).then(res => {
      this.isStaff = res;
    });
  }

  loadTable() {
    if (!this.isLoading && this.id !== undefined) {
      this.isLoading = true;
      const params: CompanyGroupListRequest = toPageableRequest(this.pageCount, 10);
      params.id = this.id;
      params.showAmount = true;

      this.companyService
        .getCompanyGroupList(params)
        .pipe(
          finalize(() => {
            this.isLoading = false;
          })
        )
        .subscribe({
          next: res => {
            if (res.result === 0 && res.data) {
              this.dataSource = res.data.content || [];

              if (this.pageCount.current != res.data.number + 1) {
                this.pageCount.current = res.data.number + 1;
              }
              if (this.pageCount.count !== res.data.totalPages) {
                this.pageCount.count = res.data.totalPages;
              }
              this.pageCount = { ...this.pageCount };
              this.ifEditable();
              if (this.dataSource.length === 0) {
                this.noCompanyLinkedHandler();
              }
              this.initForm();
            } else {
              this.toast.error(res.message);
            }
          },
          error: err => {
            console.error('Call role-list API error: ', err);
          },
        });
    }
  }

  // ngOnChanges(changes: SimpleChanges): void {
  //   if (changes['relationshipTotalCount'] && changes['relationshipTotalCount'].currentValue) {
  //     this.totoalCountDataSource = [changes['relationshipTotalCount'].currentValue];
  //   }
  // }

  ifEditable() {
    const noParentCompany = this.dataSource.every(element => element.parentCompany !== 'Y');
    const isParentCompany = this.dataSource.some(element => element.id === this.id && element.parentCompany === 'Y');
    this.editable = noParentCompany || isParentCompany;
  }

  noCompanyLinkedHandler() {
    if (this.employerDetaildata) {
      const currentCompanyObj: ErRecord = {
        id: this.employerDetaildata.id,
        employerNo: this.employerDetaildata.employerAccountCode,
        employerName: this.employerDetaildata.companyName,
        employerChineseName: this.employerDetaildata.companyName,
        totalLives: this.employerDetaildata.totalLives,
        relationshipScore: this.employerDetaildata.relationshipScore,
        employerStatus: this.employerDetaildata.employerStatus,
        parentCompany: 'N',
      };

      this.dataSource = [currentCompanyObj];
    }
  }

  initForm() {
    const group: { [key: string]: FormControl } = {};
    this.dataSource.forEach(element => {
      group[element.id] = new FormControl(this.isParentCompanySelectedRadio(element.parentCompany));
    });
    this.form = new FormGroup(group);
  }

  isParentCompanySelectedRadio(parentCompany: string | undefined) {
    return parentCompany === 'Y' ? 1 : undefined;
  }

  private popupWithReload() {
    this.cdsPopup
      .open(AlertPopupComponent, {
        size: 'sm',
        data: {
          message: this.langService.translate('common.action.success.italic'),
          buttonName: this.langService.translate('common.gotItWithExclamation'),
        },
      })
      .afterClosed()
      .subscribe(() => {
        this.loadTable();
        this.updateTotalScore.emit();
      });
  }

  syncDataSource() {
    // stay in sync with edit page data
    return this.dataSource.map(item => {
      item.relationshipScore = null;
      item.aggregateInvestedAmount = null;
      item.annualizedContribution = null;
      return item;
    });
  }

  edit() {
    this.cdsPopup
      .open(EditCompanyListComponent, {
        size: 'lg',
        data: {
          value: this.syncDataSource(),
          id: this.id,
        },
      })
      .afterClosed()
      .subscribe(result => {
        if (result?.agree && this.id) {
          this.companyService
            .commit(result.data, this.id)
            .pipe(
              finalize(() => {
                this.isLoading = false;
              })
            )
            .subscribe({
              next: res => {
                if (res.result === 0) {
                  this.popupWithReload();
                } else {
                  this.requestAlert(res.message);
                }
              },
              error: err => {
                this.requestAlert(err.message);
              },
            });
        } else {
          this.isLoading = false;
        }
      });
  }

  requestAlert(message: string) {
    this.alert.error('Got Error', message);
  }
}
