<app-cd-page-title title="{{ 'role.list.title' | lang }}"> </app-cd-page-title>

<div class="row">
  <app-cd-table-title class="col-xs-12 col-md-12 l-pt-3 l-pb-3" [title]="'role.list' | lang" [total]="pageConfig.totalCount ? pageConfig.totalCount : 0">
    <ng-template #tableAction>
      <app-permission [role]="permissionAccess" [permission]="permissionItem">
        <cds-button [config]="btnCfg" size="sm" (click)="create()">
          {{ 'role.create.title' | lang }}
        </cds-button>
      </app-permission>
    </ng-template>
  </app-cd-table-title>
  <app-data-table
    class="col-xs-12 col-md-12"
    [frontPagination]="false"
    [isLoading]="loading"
    [fixed]="false"
    [overlay]="overlay"
    [pageConfig]="pageConfig"
    [dataSource]="dataDisplayed"
    [columnsConfig]="columnsConfig"
    (reloadTable)="reloadTable($event)">
    <ng-template appDtBodyCellDef="roleName" let-element>
      <app-permission [role]="permissionAccess" [permission]="permissionItem">
        <span class="cds-body1-demibold break-word db-click" (click)="edit(element)">{{ element['roleName'] }}</span>
        <span none-permission class="cds-body1-demibold break-word">{{ element['roleName'] }}</span>
      </app-permission>
    </ng-template>
  </app-data-table>
</div>
