<app-cd-page-title
  [showBack]="true"
  [backLandingLabel]="('scheme.master.title' | lang) + ' ' + ('common.recordList' | lang)"
  (backLanding)="backLanding()"
  title="{{ ('scheme.master.title' | lang) + '-' + scheme.schemeCode }}">
  <ng-template #rightContent>
    <app-permission [role]="permissionAccess" [permission]="permissionItem">
      <div class="l-d-f icon-button l-ai-cen" *ngIf="!isReviewMode" (click)="iconClick()">
        <cds-icon size="sm" class="mobile-show" [icon]="'action:edit'" *ngIf="!isEdit"></cds-icon>
        <cds-icon class="pc-show" size="sm" [icon]="'action:edit'"></cds-icon>
        <cds-icon size="sm" class="mobile-show" [icon]="'action:more_options'" *ngIf="isEdit"></cds-icon>
        <button class="button-border-none-font btn-text">
          {{ actionText | lang }}
        </button>
      </div>
    </app-permission>
  </ng-template>
</app-cd-page-title>
<div class="row l-pb-6 content-box">
  <div class="col-xs-12 col-md-12 l-pt-4 l-mb-7 record-detail">
    <div class="cds-body2 l-mb-0">
      <span>{{ 'common.record.time.create' | lang }}: {{ scheme.createTime | ssmsDateTime }}({{ 'common.HKT' | lang }})</span>
      <span class="l-ml-4 l-mr-4">|</span>
      <span>{{ 'common.record.time.update' | lang }}: {{ scheme.lastUpdateTime | ssmsDateTime }}({{ 'common.HKT' | lang }})</span>
    </div>
  </div>
  <div class="col-xs-12 col-md-12">
    <form [formGroup]="formGroup">
      <div class="cds-h2 cds-light l-mb-5 scheme-details">
        <div *ngIf="!isConfirm">
          {{ 'scheme.master.details' | lang }}
        </div>
        <div *ngIf="isConfirm">
          {{ 'common.review' | lang }}
        </div>
      </div>

      <div class="row l-plr-0 l-mb-9 scheme-details-item">
        <div class="col-xs-12 col-md-6 l-pl-0 l-pr-20 l-mb-5">
          <div class="col-xs-12 col-md-8 l-plr-0 cds-body2 cds-dark-navy-text light-3 l-mb-1">{{ 'common.business.schemeName' | lang }}</div>
          <div *ngIf="!isEdit" class="col-xs-12 col-md-8 l-plr-0 cds-body1 l-mb-0">
            <span *ngIf="!isConfirm">{{ scheme.name }}</span>
            <span *ngIf="isConfirm">{{ schemeName.value }}</span>
          </div>
          <div *ngIf="isEdit" class="col-xs-12 col-md-8 l-plr-0 cds-body1 l-mb-0">
            <input
              (input)="schemeNameInput($event)"
              (change)="schemeNameChange($event)"
              maxlength="50"
              [placeholder]="'common.business.schemeNamePlaceholder' | lang"
              class="text-field"
              type="text"
              name="schemeName"
              formControlName="schemeName" />
            <cds-assistive-text class="l-pt-2" *ngIf="schemeName.dirty && schemeName.errors?.['errorMsg']">
              {{ schemeName.errors?.['errorMsg'] | lang}}
            </cds-assistive-text>
          </div>
        </div>
        <div class="col-xs-12 col-md-6 l-pl-0 l-pr-20 l-mb-5">
          <div class="col-xs-12 col-md-8 l-plr-0 cds-body2 cds-dark-navy-text light-3 l-mb-1">{{ 'common.business.schemeCode' | lang }}</div>
          <div class="col-xs-12 col-md-8 l-plr-0 cds-body1 l-mb-0">
            {{ scheme.schemeCode }}
          </div>
        </div>
      </div>

      <div class="l-mb-7 salary" *ngIf="scheme">
        <div class="cds-h2-light l-mb-5">
          {{ 'common.business.salaryInflationFactor' | lang }}
        </div>
        <div class="l-d-ib salary-content" style="min-width: 40%">
          <div class="div-border cds-white l-pa-5 l-d-f l-jc-sb">
            <div class="l-pr-7 salary-content-tem">
              <div class="cds-detail2 mobile-show">
                <span>{{ 'scheme.master.salaryInflationFactor.tips' | lang : { p1: currentYearFrom, p2: currentYearTo } }}</span>
                ({{ 'common.business.salaryInflationFactorCy' | lang }})
              </div>
              <div class="cds-detail2 pc-show">{{ 'scheme.master.salaryInflationFactor.tips' | lang : { p1: currentYearFrom, p2: currentYearTo } }}</div>
              <div class="cds-body-hero cds-demibold l-mb-2 l-mt-2 l-pt-3 l-pb-3">
                {{ scheme.currentInflation.salaryInflationFactor | number : '1.3-3' }}&nbsp;%
              </div>
              <div class="cds-body2 cds-dark-navy-text light-3 pc-show">
                {{ 'common.business.salaryInflationFactorCy' | lang }}
              </div>
            </div>
            <div class="arrow-right-box l-mr-7 pc-show">
              <cds-icon icon="arrow:right"></cds-icon>
            </div>

            <div class="salary-content-tem salary-content-tem-right">
              <div class="cds-detail2 mobile-show">
                <span>{{ 'scheme.master.salaryInflationFactor.tips' | lang : { p1: nextYearFrom, p2: nextYearTo } }}</span>
                ({{ 'common.business.salaryInflationFactorNy' | lang }})
              </div>
              <div class="cds-detail2 pc-show">{{ 'scheme.master.salaryInflationFactor.tips' | lang : { p1: nextYearFrom, p2: nextYearTo } }}</div>
              <div *ngIf="!isEdit" class="cds-body-hero cds-demibold l-mb-2 l-mt-2 l-pt-3 l-pb-3">
                <div *ngIf="isConfirm">
                  <span [class]="salaryInflationFactorClass"> {{ salaryInflationFactor.value | number : '1.3-3' }}% </span>
                  <div>
                    <cds-assistive-text class="l-pt-2" type="warning" *ngIf="salaryInflationFactor.errors?.['errorMsg']">
                      {{ salaryInflationFactor.errors?.['errorMsg'] | lang}}
                    </cds-assistive-text>
                  </div>
                </div>
                <div *ngIf="!isConfirm">{{ scheme.nextInflation?.salaryInflationFactor | number : '1.3-3' }}%</div>
              </div>
              <div *ngIf="isEdit" class="l-pt-2 l-pb-2">
                <div class="l-d-f l-as-cen">
                  <div class="cds-h2 cds-light l-mb-0">
                    <input
                      style="width: 136px"
                      (change)="salaryInflationFactorChange($event)"
                      (input)="salaryInflationFactorInput($event)"
                      placeholder="0.000"
                      class="text-field"
                      type="text"
                      name="salaryInflationFactor"
                      formControlName="salaryInflationFactor" />
                  </div>
                  <div class="col-xs-3 col-md-3 l-pl-3 l-d-f l-as-cen">%</div>
                </div>
              </div>
              <div class="cds-body2 cds-dark-navy-text light-3 pc-show">
                {{ 'common.business.salaryInflationFactorNy' | lang }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="l-d-f btn-box">
        <div>
          <cds-button [fullWidth]="true" *ngIf="isEdit" [config]="btnCfg" size="md" (click)="reset()">{{ 'common.reset' | lang }} </cds-button>
        </div>
        <div class="l-ml-3">
          <cds-button [fullWidth]="true" *ngIf="isEdit" [disabled]="!isSaveAvailable" size="md" (click)="save()">{{ 'common.saveChanges' | lang }} </cds-button>
        </div>
        <div>
          <cds-button [fullWidth]="true" *ngIf="isConfirm" [config]="btnCfg" size="md" (click)="backToEdit()" [disabled]="isUpdating"
            >{{ 'common.backToEdit' | lang }}
          </cds-button>
        </div>
        <div class="l-ml-3">
          <cds-button [fullWidth]="true" [fullWidth]="true" *ngIf="isConfirm" size="md" (click)="confirm()" [disabled]="isUpdating"
            >{{ 'common.confirm' | lang }}
          </cds-button>
        </div>
      </div>
    </form>
  </div>
</div>
