/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';
import { BasicResponse, IPageResponse } from 'src/app/core/models/response/response';
import { BreakpointType, CalculationType, ScalePattern, ScaleType } from 'src/app/core/models/enum/commission-scale.enum';

@Injectable({
  providedIn: 'root',
})
export class CommissionScaleService {
  constructor(private http: HttpClient) {}

  selectList(params: any): Observable<BasicResponse<IPageResponse<ScaleCodeListItem>>> {
    return this.http.get<BasicResponse<any>>(environment.apiPrefix.concat(environment.commissionServiceExt).concat('/commission-scale/list'), {
      params: params,
    });
  }

  getById2(scaleId: string) {
    const url = `${environment.apiPrefix}${environment.commissionServiceExt}/commission-scale/info/${scaleId}`;
    return this.http.get(url, {
      observe: 'response',
      responseType: 'text',
    });
  }

  saveOrUpdate(id: string | undefined, params: any): Observable<BasicResponse<any>> {
    if (id) {
      return this.updateScale(id, params);
    }
    return this.saveScale(params);
  }

  saveScale(params: any): Observable<BasicResponse<any>> {
    return this.http.post<BasicResponse<any>>(environment.apiPrefix.concat(environment.commissionServiceExt).concat('/commission-scale/add'), params);
  }

  updateScale(id: string, params: any): Observable<BasicResponse<any>> {
    return this.http.post<BasicResponse<any>>(`${environment.apiPrefix}${environment.commissionServiceExt}/commission-scale/update/${id}`, params);
  }
}

export interface ScaleCodeListItem {
  id: string;
  scaleCode: string;
  calculationType: CalculationType;
  breakpointType: BreakpointType;
  scalePattern: ScalePattern;
  scaleType: ScaleType;
  crfRate: number;
  status: string;
}
