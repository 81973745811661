<div class="container-fluid p-0">
  <div class="card">
    <div class="card-header-tab card-header space-content" style="justify-content: space-between">
      <div class="card-header-title font-size-lg text-capitalize fw-normal">Transfer Chat</div>
      <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
      <div class="form-body">
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label class="required">Agent List:</label>
              <ng-select
                class="font-small-1"
                [multiple]="false"
                [items]="agentDDL"
                bindLabel="name"
                [(ngModel)]="agentID"
                bindValue="id"
                placeholder="Choose One Option"
                [clearable]="true">
              </ng-select>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <cds-button [disabled]="loading" [size]="'sm'" [style]="'secondary'" (click)="activeModal.dismiss('Cross click')">Cancel</cds-button>
      <cds-button
        [type]="'submit'"
        [ngClass]="{ 'm-loader m-loader--right m-loader--info': loading }"
        [disabled]="loading || !agentID"
        [size]="'sm'"
        [style]="'primary'"
        >Transfer</cds-button
      >
    </div>
  </div>
</div>
