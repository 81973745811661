import { PermissionAccess } from '../core/models/enum/permission.enum';

const permissionAccessToNum = (iPermissionAccess?: PermissionAccess | null) => {
  switch (iPermissionAccess) {
    case PermissionAccess.R:
      return 1;
    case PermissionAccess.W:
      return 2;
    case PermissionAccess.E:
      return 3;
    default:
      return 0;
  }
};

const checkPermission = (expet: number, check?: PermissionAccess | null) => {
  return permissionAccessToNum(check) >= expet;
};

export const checkPermissionAccess = (expet: PermissionAccess, check?: PermissionAccess | null) => {
  return checkPermission(permissionAccessToNum(expet), check);
};

export const hasR = (check?: PermissionAccess | null) => {
  return checkPermission(1, check);
};

export const hasW = (check?: PermissionAccess | null) => {
  return checkPermission(2, check);
};

export const hasE = (check?: PermissionAccess | null) => {
  return checkPermission(3, check);
};
