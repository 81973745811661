import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-trustee-details-popup',
  templateUrl: './trustee-details-popup.component.html',
  styleUrls: ['./trustee-details-popup.component.scss'],
})
export class TrusteeDetailsPopupComponent implements OnInit {
  items = [
    {
      shortForm: 'AIA',
      definition: 'AIA Company (Trustee) Limited',
    },
    {
      shortForm: 'BCT',
      definition: 'Bank Consortium Trust Company Limited',
    },
    {
      shortForm: 'BCOM',
      definition: 'Bank of Communications Trustee Limited',
    },
    {
      shortForm: 'BOCI',
      definition: 'BOCI-Prudential Trustee Limited',
    },
    {
      shortForm: 'CHNL',
      definition: 'China Life Trustees Limited',
    },
    {
      shortForm: 'HSBC',
      definition: 'HSBC Provident Fund Trustee (Hong Kong) Limited',
    },
    {
      shortForm: 'HTHK',
      definition: 'HSBC Institutional Trust Services (Asia) Limited',
    },
    {
      shortForm: 'MANL',
      definition: 'Manulife Provident Funds Trust Company Limited',
    },
    {
      shortForm: 'PRIN',
      definition: 'Principal Trust Company (Asia) Limited',
    },
    {
      shortForm: 'DEX',
      definition: 'Standard Chartered Trustee (Hong Kong) Limited',
    },
    {
      shortForm: 'SUN',
      definition: 'Sun Life Trustee Company Limited',
    },
    {
      shortForm: 'FWD',
      definition: 'Sun Life Pension Trust Limited',
    },
    {
      shortForm: 'MASS',
      definition: 'YF Life Trustees Limited',
    },
  ];

  constructor(private dialogRef: MatDialogRef<TrusteeDetailsPopupComponent>) {}

  ngOnInit(): void {}

  gotIt() {
    this.dialogRef.close();
  }
}
