<div class="iff-completion-steps-popup">
  <div>
    <span class="cds-body-hero">IFF Completion Steps</span>
  </div>
  <div class="l-d-f l-ai-cen l-mt-6">
    <div class="round l-d-f l-ai-cen l-jc-cen">
      <div>
        <span class="cds-h6-demibold" style="color: var(--cds-text-inverse)">1</span>
      </div>
    </div>
    <div class="l-ml-3">
      <span class="cds-h6-demibold">Agent MD Form Completed</span>
    </div>
  </div>
  <div class="l-d-f l-ai-cen l-mt-5">
    <div class="round l-d-f l-ai-cen l-jc-cen">
      <div>
        <span class="cds-h6-demibold" style="color: var(--cds-text-inverse)">2</span>
      </div>
    </div>
    <div class="l-ml-3">
      <span class="cds-h6-demibold">Client MD Form Completed</span>
    </div>
  </div>
  <div class="l-d-f l-ai-cen l-mt-5">
    <div class="round l-d-f l-ai-cen l-jc-cen">
      <div>
        <span class="cds-h6-demibold" style="color: var(--cds-text-inverse)">3</span>
      </div>
    </div>
    <div class="l-ml-3">
      <span class="cds-h6-demibold">eMPF Completed</span>
    </div>
  </div>
  <div class="l-d-f l-ai-cen l-mt-5">
    <div class="round l-d-f l-ai-cen l-jc-cen">
      <div>
        <span class="cds-h6-demibold" style="color: var(--cds-text-inverse)">4</span>
      </div>
    </div>
    <div class="l-ml-3">
      <span class="cds-h6-demibold">Agent IFF Completed</span>
    </div>
  </div>
  <div class="l-d-f l-ai-cen l-mt-5">
    <div class="round l-d-f l-ai-cen l-jc-cen">
      <div>
        <span class="cds-h6-demibold" style="color: var(--cds-text-inverse)">5</span>
      </div>
    </div>
    <div class="l-ml-3">
      <span class="cds-h6-demibold">Client IFF Completed</span>
    </div>
  </div>
  <div class="button-box">
    <cds-button size="sm" (click)="onCancel()">Got it</cds-button>
  </div>
</div>
