import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { CdsButtonConfig } from '@cds/ng-core/button';
import { CdsIconConfig } from '@cds/ng-core/icon';
import { EmotionIcons } from '@cds/ng-themes';
import { CDS_POPUP_DATA } from '@cds/ng-web-components/popup';

@Component({
  selector: 'app-iff-submission-succ-popup',
  templateUrl: './iff-submission-succ-popup.component.html',
  styleUrls: ['./iff-submission-succ-popup.component.scss'],
})
export class IffSubmissionSuccPopupComponent implements OnInit {
  buttonConfig: CdsButtonConfig = {
    size: 'md',
    style: 'primary',
    disabled: false,
  };

  happyFaceIconConfig: CdsIconConfig = {
    size: 'md',
  };
  happyFace = EmotionIcons.happy;
  constructor(
    private dialogRef: MatDialogRef<IffSubmissionSuccPopupComponent>,
    @Inject(CDS_POPUP_DATA)
    public data: {
      message: string;
    }
  ) {}

  ngOnInit(): void {}

  gotIt() {
    this.dialogRef.close({ agree: true });
  }
}
