import { Pipe, PipeTransform } from '@angular/core';

export enum HideTextType {
  EMAIL = 'EMAIL',
  PHONE = 'PHONE',
}

@Pipe({
  name: 'hideText',
})
export class HideTextPipe implements PipeTransform {
  transform(value: string, type: HideTextType): unknown {
    if (type === HideTextType.EMAIL) {
      const endIndex = value.indexOf('@');
      return value.substring(0, 4) + '*****' + value.substring(endIndex);
    } else if (type === HideTextType.PHONE) {
      let phoneArr = new Array((value + '').length - 3);
      phoneArr = phoneArr.fill('*');
      return value.substring(0, 1) + phoneArr.join('') + value.substring((value + '').length - 2);
    }
    return value;
  }
}
