/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { Subject } from 'rxjs';

import { MatDialogRef } from '@angular/material/dialog';
import { CdsPopupService } from '@cds/ng-web-components/popup';
import { MsalService } from '@azure/msal-angular';
import { AuthenticationResult } from '@azure/msal-browser';

import { DateTime } from 'luxon';
import { BTN_CONFIG_PRIMARY, BTN_CONFIG_SECONDARY } from '../../../config/btn.config';
import { Session } from '../../../core/models/user';
import { AuthenticationService } from '../../../core/services/authentication.service';
import { OtpService } from '../../../core/services/otp/otp.service';
import { ToastAlertService } from '../../../shared/toast-alert.service';
import { addSessionByKey, deleteDB } from '../../../utils/indexdb';
import { UserAgreementPopupComponent } from '../../commission/user-agreement-popup.component';
import { OTPChannel } from './otp-channel.enum';
import { environment } from 'src/environments/environment';
import { CdsLangService } from '@cds/ng-core/lang';

export type LoginType = 'otp' | 'sso' | 'mcf';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  loginBtnCfg = BTN_CONFIG_PRIMARY;
  btnCfg = BTN_CONFIG_SECONDARY;

  //otp login
  isInternal = false;
  isLoginEnable = false;
  isShowOTP = false;
  isShowLoading = false;
  isContinueEnable = false;
  isResendEnable = false;
  masked = true;

  inputType = 'password';
  second = '60';
  otp = '';
  otpErr = '';
  OTPUser: any = {};
  loginForm: any = {
    username: '',
    passwd: '',
    loginErr: '',
  };

  profile = environment.profile;
  mCFMode = environment.mCFMode;
  pwdLogin = environment.pwdLogin;
  mCFAPISuffix = '/auth/mcf/token';

  private readonly _destroying$ = new Subject<void>();
  private SSO_API_SUFFIX = '/auth/token';
  private OTP_LOGIN_API_SUFFIX = '/auth/otp/token';
  private DIALOG_DELAY = 5000;

  constructor(
    private authService: MsalService,
    private cdsPopup: CdsPopupService,
    private authenticationService: AuthenticationService,
    private router: Router,
    private route: ActivatedRoute,
    private otpService: OtpService,
    private toastAlert: ToastAlertService,
    private langService: CdsLangService
  ) {}

  ngOnInit(): void {
    this.authenticationService.currentUserValue().then(data => {
      if (data.token !== undefined) {
        this.router.navigate(['/home']);
      } else {
        const afterUrl: string = window.location.href;
        if (afterUrl && environment.internalUrl && afterUrl.indexOf(environment.internalUrl) !== -1) {
          this.isInternal = true;
        } else {
          this.isInternal = false;
        }
        this.loginWithMCF();
      }
    });
  }

  redirectMCF() {
    const url = environment.mcfUrl + '&lang=' + (this.langService.currentLang && this.langService.currentLang == 'zh' ? 'zh-hant' : 'en');
    window.location.href = url;
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

  loginFormChange() {
    this.loginForm.loginErr = '';
    this.isLoginEnable = Boolean(this.loginForm.username && this.loginForm.passwd);
  }

  loginWithSSO() {
    const popupRef = this.authenticationService.getLoadingDialogRef();
    this.authenticationService.loginWithSSO().subscribe({
      next: (result: AuthenticationResult) => {
        this.authService.instance.setActiveAccount(result.account);
        this.authenticateToken('sso', result.accessToken, popupRef, this.SSO_API_SUFFIX);
      },
      error: error => {
        console.log(error);
        popupRef.close();
      },
    });
  }

  loginWithMCF() {
    const afterUrl: string = window.location.search;
    if (afterUrl != null && afterUrl != '' && afterUrl.indexOf('?') != -1 && afterUrl.indexOf('mcf_token') != -1) {
      let paramsStr = '';
      if (afterUrl.length > afterUrl.indexOf('?')) {
        paramsStr = afterUrl.slice(afterUrl.indexOf('?') + 1, afterUrl.length);
      }
      let params: string[] = [];
      if (afterUrl.indexOf('&') != -1) {
        params = paramsStr.split('&');
      } else {
        params.push(paramsStr);
      }
      if (params && params.length > 0) {
        let accessToken = '';
        let mcfRefreshToken = '';
        let a = 0;
        params.forEach(item => {
          if (item.indexOf('mcf_token') != -1) a = 1;
          if (item.indexOf('mcf_refresh_token') != -1) a = 2;
          if (item && item.indexOf('=') != -1 && a != 0 && ((a == 1 && !accessToken) || (a == 2 && !mcfRefreshToken))) {
            let index = 0;
            for (let i = 0; i < item.length; i++) {
              if (item[i] == '=') {
                index = i;
                break;
              }
            }
            if (index > 0 && item.length > index + 1) {
              if (a == 1) accessToken = item.slice(index + 1, item.length);
              if (a == 2) mcfRefreshToken = item.slice(index + 1, item.length);
              if (item.indexOf('lang') != -1 && item.slice(index + 1, item.length) == 'zh-hant') {
                this.langService.setCurrentLang('zh');
              }
            } else {
              window.alert('mcf token pattern error');
            }
          }
        });
        if (accessToken && mcfRefreshToken) {
          const popupRef = this.authenticationService.getLoadingDialogRef();
          this.authenticateToken('mcf', accessToken, popupRef, this.mCFAPISuffix, mcfRefreshToken);
        } else {
          window.alert('mcf token url error');
        }
      }
    }
  }

  loginWithManutouch() {
    if (!this.isLoginEnable) return;
    this.loginForm.loginErr = '';
    const popupRef = this.authenticationService.getLoadingDialogRef();
    const username = this.loginForm.username;
    const password = this.loginForm.passwd;
    this.otpService.login({ userId: username, password: password }).subscribe({
      next: res => {
        if (res.result !== 0) {
          popupRef.close();
          this.isShowOTP = false;
          this.loginForm.loginErr = res.message;
        } else {
          this.OTPUser = {
            agentKey: res.data.agentKey,
            emailAddress: res.data.emailAddress,
            phoneNum: res.data.phoneNum,
            sessionToken: res.data.sessionToken,
          };
          this.requestOTP(popupRef);
          this.isShowOTP = true;
        }
      },
      error: err => {
        this.handleError(err.message, 'manutouch', popupRef);
      },
    });
  }

  loginWithOTP() {
    this.otpErr = '';
    const popupRef = this.authenticationService.getLoadingDialogRef();
    this.otpService.verifyOTP({ sessionToken: this.OTPUser.sessionToken, otp: this.otp }).subscribe({
      next: res => {
        if (res.result !== 0) {
          this.otpErr = res.message;
          popupRef.close();
        } else {
          const accessToken = res.data.accessToken;
          this.authenticateToken('otp', accessToken, popupRef, this.OTP_LOGIN_API_SUFFIX);
        }
      },
      error: err => {
        this.handleError(err.message, 'otp', popupRef);
      },
    });
  }

  requestOTP(popupRef?: any, pageResend?: boolean) {
    if (pageResend && this.second !== '0-1' && !this.isResendEnable) return;
    this.otp = '';
    this.otpErr = '';
    this.isShowLoading = true;
    this.otpService
      .requestOTP({
        sessionToken: this.OTPUser.sessionToken,
        channel: OTPChannel.E,
      })
      .subscribe({
        next: res => {
          if (res.result !== 0) {
            popupRef?.close();
            this.isShowLoading = false;
            this.isResendEnable = true;
            this.otpErr = res.message;
          } else {
            popupRef?.close();
            this.countDown();
            this.OTPUser.sessionToken = res.data.sessionToken;
            this.isShowLoading = false;
            this.isResendEnable = false;
          }
        },
        error: err => {
          this.isShowLoading = false;
          this.isResendEnable = true;
          this.handleError(err.message, 'otp', popupRef);
        },
      });
  }

  changeOTP(value: number) {
    this.otpErr = '';
    this.isContinueEnable = !!value && String(value).length === 6;
  }

  backToLogin() {
    this.OTPUser = {};
    this.isShowOTP = false;
  }

  toggleMask() {
    this.masked = !this.masked;
    this.inputType = this.masked ? 'password' : 'text';
  }

  private countDown() {
    // every one minute can resend OTP (unit: seconds)
    let clock = 60;
    const myInterval = setInterval(() => {
      if (clock == -1) {
        clearInterval(myInterval);
      }
      this.second = String(clock < 10 ? '0' + clock : clock);
      clock--;
    }, 1000);
  }

  private authenticateToken(
    loginType: LoginType,
    accessToken: string,
    popupRef: MatDialogRef<UserAgreementPopupComponent>,
    apiSuffix: string,
    mcfRefreshToken?: string
  ) {
    this.authenticationService.authenticateToken(apiSuffix, accessToken, mcfRefreshToken).subscribe({
      next: async res => {
        const end = DateTime.now().toMillis();
        console.log('login backend response time:' + end);
        popupRef.close();
        if (res.result === 0) {
          const userInfo: Session = res.data;
          if (userInfo) {
            const targetUrl = sessionStorage.getItem('targetUrl') || '/home';
            const i = targetUrl.indexOf('?');
            const queryParams: { [key: string]: string } = {};
            if (i > -1) {
              const searchParams = new URLSearchParams(targetUrl.substring(i));
              searchParams.forEach((value, key) => {
                queryParams[key] = value;
              });
            }
            userInfo.currentLastUpdate = DateTime.now().toMillis();
            await addSessionByKey(userInfo).then(() => {
              this.router.navigate([targetUrl.substring(0, i === -1 ? undefined : i)], { queryParams });
            });
          }

          localStorage.setItem('loginType', loginType);
        } else {
          deleteDB();
          let msg = res.message;
          switch (res.result) {
            case 2008:
              msg = 'login.errorMsg.mcf2008';
              break;
            case 2010:
              msg = 'login.errorMsg.mcf2010';
              break;
            case 2019:
              msg = 'login.errorMsg.mcf2019';
              break;
          }
          this.handleError(msg, loginType, popupRef);
        }
      },
      error: err => {
        this.handleError(err.message, loginType, popupRef);
      },
    });
  }

  private handleError(message: string, loginType: string, popupRef?: any) {
    popupRef?.close();
    let errMsg = message;
    if (message.indexOf(':') !== -1) {
      errMsg = message.substring(message.lastIndexOf(':') + 2, message.length);
    }
    if (loginType === 'sso') {
      this.router.navigate(['/user/login']);
      this.toastAlert.show('error', 'common.error', errMsg, this.DIALOG_DELAY);
    } else if (loginType === 'manutouch') {
      this.loginForm.loginErr = errMsg;
    } else if (loginType === 'mcf') {
      //this.toastAlert.show('error', 'common.error', errMsg, this.DIALOG_DELAY);
      this.cdsPopup.open(UserAgreementPopupComponent, {
        size: 'md',
        data: {
          message: message,
          continue: 'common.gotIt',
          type: 'alert',
        },
      });
    } else if (loginType === 'otp') {
      if (errMsg.indexOf('401') > -1) {
        this.backToLogin();
        this.toastAlert.show('error', 'common.error', errMsg, this.DIALOG_DELAY);
      } else {
        this.otpErr = errMsg;
      }
    }
  }
}
