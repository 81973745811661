export enum NbvRatio {
  MPF_ER_AC = 'MPF_ER_AC',
  MPF_ER_TA = 'MPF_ER_TA',
  MPF_PAP_MPF_ER_IT = 'MPF_PAP_MPF_ER_IT',
  MPF_TVC = 'MPF_TVC',
  MPF_SEP = 'MPF_SEP',
  MPF_FRC = 'MPF_FRC',
  ORSO_ER_AC = 'ORSO_ER_AC',
  ORSO_ER_TA = 'ORSO_ER_TA',
}
