export class EmployeeResponse {
  result?: number;
  data?: EmployeeResponseBody;
  message?: string;
}

export interface EmployeeResponseBody {
  content: EmployeeResponseData[];
  pageable: PageResponseData;
  last: boolean;
  totalPages: number;
  totalElements: number;
  first: boolean;
  numberOfElements: number;
  size: number;
  number: number;
  empty: boolean;
}

export interface EmployeeResponseData {
  flag?: boolean;
  checkdata?: string;
  checkResult?: string;
  checkResultTc: string;
  isSelected?: boolean;
  memberId: string;
  memberName: string;
  memberNameTC: string;
  trusteeEmplrCd: string;
  trusteeMbrId: string;
  gender: string;
  age: number;
  dateJoiningTheSchm: string;
  effStartDate: string;
  delegateAgentInfo: EmployeeDelegateAgentInfo;
}

export interface EmployeeDelegateAgentInfo {
  agentCode?: string;
  name?: string;
  nameTC?: string;
}

export interface PageResponseData {
  pageNumber: number;
  pageSize: number;
  offset?: number;
  paged?: boolean;
  unpaged?: boolean;
}
