<cds-popup [class.hidden]="!show" class="relative">
  <cds-popup-title>{{ 'notification.uploadDocument' | lang }}</cds-popup-title>
  <cds-popup-title *ngIf="!inEdit">Summary</cds-popup-title>

  <cds-popup-content>
    <div class="cds-h6-demibold l-mb-4">
      {{ 'notification.fileRequirements' | lang }}
    </div>
    <div class="cds-detail1">
      {{ 'notification.fileSizeRequirements' | lang }}
    </div>
    <div class="cds-detail1 l-mb-6">
      {{ 'notification.fileSuffixRequirement' | lang }}
    </div>

    <div class="upload-area l-mb-8" [class.file-error]="showFileErrorMessage">
      <cds-file-upload
        #upload
        [disabled]="true"
        [formControl]="uploadFormControl"
        (change)="fileChange($event)"
        errorMessage="Invalid file uploaded."
        acceptedFiles="text/csv">
      </cds-file-upload>
      <div class="l-pt-2">
        <cds-assistive-text *ngIf="showFileErrorMessage">Invalid file selected.</cds-assistive-text>
      </div>
    </div>
    <app-loading *ngIf="isLoading" [overlayInPopup]="true"></app-loading>
  </cds-popup-content>

  <cds-popup-actions>
    <ng-container>
      <cds-button [config]="cancelButtonConfig" (click)="cancel()">{{ 'common.cancel' | lang }}</cds-button>

      <cds-button [config]="confirmButtonConfig" (click)="confirm()" [disabled]="noFileSelect || showFileErrorMessage">{{
        'common.confirm' | lang
      }}</cds-button>
    </ng-container>
  </cds-popup-actions>
</cds-popup>
