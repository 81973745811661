/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit } from '@angular/core';
import { AgentPerformanceHeadDTO, AgentPerformanceHeadSearchDTO } from 'src/app/views/shk/shk';
import { CdsDropdownConfig } from '@cds/ng-core/dropdown';
import moment from 'moment';
import { CdsButtonConfig } from '@cds/ng-core/button';
import { ColumnConfig, SortMode } from 'src/app/shared/data-table';
import { finalize } from 'rxjs';
import { ShkService } from 'src/app/views/shk/shk.service';
import { aoaToSheet, FileType } from 'src/app/utils/xlsx';
import { ColumnSort, I18N_KEY } from '../../employer/employer';
import { DateTime } from 'luxon';
import { ColumnItem, Sort } from 'src/app/components/table/table-expand';
import { Router } from '@angular/router';
import { IPaginationCount } from 'src/app/shared/pagination/pagination.dto';
import { FormControl, FormGroup } from '@angular/forms';
import { numberStringValidator } from 'src/app/shared/validators/validators';
import { dateValidator } from 'src/app/core/validators';

@Component({
  selector: 'app-agent-performance-head-enrolment',
  templateUrl: './agent-performance-head-enrolment.component.html',
  styleUrls: ['./agent-performance-head-enrolment.component.scss'],
})
export class AgentPerformanceHeadEnrolmentComponent implements OnInit {
  constructor(private service: ShkService, private router: Router) {}

  periodConfig: CdsDropdownConfig = {
    label: 'Period',
    placeholder: 'Select Period',
    options: [
      { label: 'Last 7 days', value: 6 },
      { label: 'Last 30 days', value: 29 },
      { label: 'Last 60 days', value: 59 },
      { label: 'Last 90 days', value: 89 },
    ],
  };
  showDatePicker = true;

  I18N_KEY = I18N_KEY;

  _valid = false;

  form = new FormGroup({
    agentCode: new FormControl('', [numberStringValidator()]),
    period: new FormControl(''),
    assignmentDateFrom: new FormControl('', [dateValidator()]),
    assignmentDateTo: new FormControl('', [dateValidator()]),
  });

  nowTimeInstr = DateTime.now().toFormat('dd/MM/yyyy');

  maxTime = this.getNowTime();

  maxTimeInStr = DateTime.fromJSDate(this.maxTime).toFormat('dd/MM/yyyy');

  searchButtonConfig: CdsButtonConfig = {
    size: 'sm',
  };

  resetButtonConfig: CdsButtonConfig = {
    size: 'sm',
    style: 'secondary',
  };
  exportButtonConfig: CdsButtonConfig = {
    size: 'lg',
    style: 'secondary',
  };
  searchDTO: AgentPerformanceHeadSearchDTO = {
    page: 0,
    size: 10,
    period: 6,
    sort: 'agentCode,desc',
  };

  columnsConfig: ColumnConfig[] = [
    { key: 'agentCode', title: 'Agent Code', sort: SortMode.DEFAULT },
    { key: 'agentName', title: 'Agent Name', sort: SortMode.DEFAULT },
    { key: 'outStanding', title: 'Outstanding', sort: SortMode.DEFAULT },
    { key: 'initialContact', title: 'Initial Contact', sort: SortMode.DEFAULT },
    { key: 'scheduleMeetUp', title: 'Schedule Meet Up', sort: SortMode.DEFAULT },
    { key: 'canNotReachClient', title: 'Cannot Reach Client', sort: SortMode.DEFAULT },
    { key: 'rejectedByClient', title: 'Rejected by Client', sort: SortMode.DEFAULT },
    { key: 'completed', title: 'Completed', sort: SortMode.DEFAULT },
    { key: 'total', title: 'Total', sort: SortMode.DEFAULT },
    { key: 'transferToGs', title: 'Transfer to GS (exclude Auto rollover)', sort: SortMode.DEFAULT },
  ];

  expandColumnsConfig: ColumnConfig[] = [
    { key: 'noOfContact', title: 'No. of Contact' },
    { key: 'noOfCases', title: 'No. of Cases' },
  ];

  EXCEL_COLUMNS: string[] = [
    'Agent Code',
    'Agent Name',
    'Agent Chinese Name',
    'No. of contact=1',
    'No. of contact=2',
    'No. of contact=3',
    'No. of contact=4',
    'No. of contact=5',
    'Outstanding',
    'Initial Contact',
    'Scheule meet up',
    'Cannot reach client',
    'Rejected by client',
    'Completed',
    'Total',
    'Transfer to GS (exclude Auto rollover)',
  ];
  _progress = false;

  dataList: AgentPerformanceHeadDTO[] = [];

  exportDataList: AgentPerformanceHeadDTO[] = [];

  columnSort: ColumnSort = {
    key: 'agentCode',
    value: SortMode.UP,
  };

  Sort = Sort;

  _pageCount: IPaginationCount = { current: 1, count: 1 };
  _size = 10;

  ngOnInit(): void {
    this.inputChange('6', 'period');
    this.loadTable();
  }

  exportData() {
    const params: AgentPerformanceHeadSearchDTO = this.getRequestParams(true);
    this._progress = true;
    this.service
      .getAgentPerformanceHeadEnrolmentList(params)
      .pipe(
        finalize(() => {
          this._progress = false;
        })
      )
      .subscribe(data => {
        if (data.result === 0 && data.data) {
          const excelData = this.createExcelData(data.data);
          aoaToSheet(
            {
              fileName: `"agent-performance-head "${moment().format('YYYYMMDD_HHMMSS')}`,
              fileType: FileType.CSV,
            },
            excelData
          );
        }
      });
  }

  createExcelData(data: AgentPerformanceHeadDTO[]) {
    const excelData: any[] = [];
    excelData.push(this.EXCEL_COLUMNS);
    data.forEach(item => {
      const temp = [];
      const expandData = this.getExpandData(item.contactMap);
      temp.push(item.agentCode);
      temp.push(item.agentName || '');
      temp.push(item.agentNameChi || '');
      temp.push(expandData[0].noOfCases);
      temp.push(expandData[1].noOfCases);
      temp.push(expandData[2].noOfCases);
      temp.push(expandData[3].noOfCases);
      temp.push(expandData[4].noOfCases);
      temp.push(item.outStanding);
      temp.push(item.initialContact);
      temp.push(item.scheduleMeetUp);
      temp.push(item.canNotReachClient);
      temp.push(item.rejectedByClient);
      temp.push(item.completed);
      temp.push(item.total);
      excelData.push(temp);
    });
    return excelData;
  }

  frontReloadTable(dataList: AgentPerformanceHeadDTO[]) {
    this.exportDataList = [...dataList];
  }

  loadTable() {
    // request all Data on one time, frontend pagination and sorting
    const params: AgentPerformanceHeadSearchDTO = this.getRequestParams();
    this._progress = true;
    this.service
      .getAgentPerformanceHeadEnrolmentList(params)
      .pipe(
        finalize(() => {
          this._progress = false;
        })
      )
      .subscribe(data => {
        if (data.result === 0 && data.data) {
          this._progress = false;
          this.dataList = data.data.slice((this._pageCount.current - 1) * this._size, this._pageCount.current * this._size);
          this._pageCount.count = data.data.length % this._size == 0 ? data.data.length / this._size : Math.trunc(data.data.length / this._size) + 1;
        }
      });
  }

  getRequestParams(isExport = false) {
    const params: AgentPerformanceHeadSearchDTO = { ...this.searchDTO };
    params.page = (this._pageCount.current as number) - 1;
    params.assignmentDateToShow = undefined;
    params.assignmentDateFromShow = undefined;
    params.period = undefined;
    params.size = this._size;
    if (isExport) {
      // get all data
      params.size = 100000;
    }
    return params;
  }
  dateTrans(date: string) {
    if (date) {
      return moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD');
    }

    return date;
  }

  inputChange(val: string, label: string) {
    console.log(this.form);
    if (val == undefined) {
      return;
    }
    if (typeof val === 'object') {
      return;
    }
    switch (label) {
      case 'agentCode':
        this.searchDTO.agentCode = val;
        break;
      case 'period':
        this.searchDTO.assignmentDateFrom = String(moment().subtract(val, 'days').format('YYYY-MM-DD'));
        this.searchDTO.assignmentDateTo = String(moment().format('YYYY-MM-DD'));
        this.searchDTO.assignmentDateFromShow = String(moment().subtract(val, 'days').format('DD/MM/YYYY'));
        this.searchDTO.assignmentDateToShow = String(moment().format('DD/MM/YYYY'));
        // this.loadTable()
        break;
      case 'assignmentDateFrom':
        this.searchDTO.period = undefined;
        this.searchDTO.assignmentDateFrom = moment(val, 'DD/MM/YYYY').format('YYYY-MM-DD');
        break;
      case 'assignmentDateTo':
        this.searchDTO.period = undefined;
        this.searchDTO.assignmentDateTo = moment(val, 'DD/MM/YYYY').format('YYYY-MM-DD');
        break;
      default:
        break;
    }
    this.validating();
  }
  validating() {
    if (!!this.searchDTO.agentCode || !!this.searchDTO.assignmentDateFrom || !!this.searchDTO.assignmentDateTo) {
      this._valid = true;
    } else {
      this._valid = false;
    }
    // if (!!this.searchDTO.agentCode || !!this.searchDTO.assignmentDateFrom || !!this.searchDTO.assignmentDateTo) {
    //   this.searchButtonConfig = {
    //     size: 'sm',
    //     disabled: false,
    //   };
    //   this.resetButtonConfig = {
    //     size: 'sm',
    //     style: 'secondary',
    //     disabled: false,
    //   };
    // } else {
    //   this.searchButtonConfig = {
    //     size: 'sm',
    //     disabled: true,
    //   };
    //   this.resetButtonConfig = {
    //     size: 'sm',
    //     style: 'secondary',
    //     disabled: true,
    //   };
    // }
  }

  async validFn() {
    return this._valid && this.form.valid;
  }

  search() {
    this.validFn().then(r => {
      if (r) {
        this._pageCount = { current: 1, count: 1 };
        this.loadTable();
      }
    });
  }

  reset() {
    this.searchDTO = {
      page: 0,
      size: this._size,
      sort: 'agentCode,desc',
    };

    this._pageCount = { current: 1, count: 1 };

    // reload datepicker dom to clear the selected date
    this.showDatePicker = false;
    setTimeout(() => {
      this.showDatePicker = true;
      this.validating();
      this.loadTable();
    });
  }

  getTime(dateStr: string) {
    return moment(dateStr, 'DD/MM/YYYY').toDate();
  }

  getNowTime() {
    return moment().toDate();
  }
  getMaxFromDate = (toDateStr?: string) => {
    if (!toDateStr) {
      return this.getNowTime();
    }
    return moment(toDateStr, 'DD/MM/YYYY').toDate();
  };

  getMinToDate = (fromDateStr = '01/01/0000') => {
    return moment(fromDateStr, 'DD/MM/YYYY').toDate();
  };

  sortChange(columnItem: ColumnItem) {
    if (columnItem.sort !== Sort.DEFAULT) {
      this.searchDTO.sort = `${columnItem.prop},${columnItem.sort}`;
    } else {
      this.searchDTO.sort = 'agentCode,desc';
    }
    this.loadTable();
  }

  expandRow(index: number) {
    if (this.dataList && this.dataList[index]) {
      this.dataList[index].expandData = this.getExpandData(this.dataList[index].contactMap);
    }
  }

  getExpandData(contactMap: any): any[] {
    return ['1', '2', '3', '4', '5'].map(key => {
      return {
        noOfContact: key,
        noOfCases: contactMap && contactMap[key] ? contactMap[key] : 0,
      };
    });
  }
}
