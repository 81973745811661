<div class="employee-summary">
  <div class="l-pt-6">
    <div class="title l-pb-3 l-pl-7">
      <div class="icon l-pb-3" (click)="onBackToRequestStatusSummaryPageClick()">
        <cds-icon [config]="backErIconConfig" [icon]="backErIcon"> </cds-icon>

        <span class="navi-back cds-body2-demibold l-pl-3">{{ 'delegationChange.backToRequestStatusSummaryPage' | lang }}</span>
      </div>
      <div>
        <span class="head">{{ 'delegationChange.addDelegationChangeRequest' | lang }}</span>
      </div>
    </div>
  </div>
  <div *ngIf="isInWhichStage === 1">
    <div class="l-pl-7">
      <div>
        <span class="cds-h3-demibold employer-name l-pt-3">{{
          language === 'EN' ? (employerName ? employerName : employerNameTC) : employerNameTC ? employerNameTC : employerName
        }}</span>
      </div>
      <div class="info l-pt-5">
        <div>{{ 'delegationChange.eeDelegationChangeDesc' | lang }}</div>
      </div>
    </div>
    <div>
      <div class="l-pl-7">
        <div class="search-record">
          <span>{{ 'delegationChange.searchRecord' | lang }} </span>
        </div>
        <div class="row search-box">
          <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12 columns-item">
            <app-cd-input
              [value]="nameValue"
              #searchBox
              (input)="nameChange(searchBox.value)"
              label="{{ 'employee.employeeName' | lang }}"
              placeholder="{{ 'delegationChange.searchEmployeeNamePlaceHolder' | lang }}"
              [config]="employeeNameTxtfieldConfig"></app-cd-input>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12 columns-item">
            <app-cd-input
              [value]="numberValue"
              #searchBumberBox
              (input)="numberChange(searchBumberBox.value)"
              label="{{ 'delegationChange.certNo' | lang }}"
              placeholder="{{ 'delegationChange.searchCertNoPlaceHolder' | lang }}"
              [config]="numbersTxtfieldConfig">
            </app-cd-input>
          </div>

          <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12 columns-item">
            <app-cd-input
              [value]="delegateAgentInfoValue"
              #searcdelegateBox
              type="number"
              (input)="delegateAgentChange(searcdelegateBox.value)"
              label="{{ 'employee.searchDelegateAgentInfo' | lang }}"
              placeholder="{{ 'delegationChange.inputDelegatedAgentCode' | lang }}"
              [config]="delegateTxtfieldConfig"></app-cd-input>
          </div>

          <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12 columns-item search-bottoms">
            <cds-button [config]="primaryButtonConfig" label="{{ 'delegationChange.search' | lang }}" (click)="employeeDelegationChangeSearch()"></cds-button>
            <cds-button [config]="secondaryButtonConfig" label="{{ 'delegationChange.reset' | lang }}" (click)="clearData()"></cds-button>
          </div>
        </div>
      </div>
      <div class="l-pl-7">
        <div class="inline-alert-box" *ngFor="let item of alertItems; let i = index">
          <cds-inline-alert class="inline-alert" [type]="alertType" *ngIf="item.ifShow" [dismissable]="true" (dismiss)="dismissAlert(i)">
            {{ language === 'EN' ? item.message : item.messageTC }}
          </cds-inline-alert>
        </div>
        <div class="cds-h2-light l-mt-8">
          <span>{{ 'delegationChange.employeeList' | lang }}</span>
        </div>
        <div class="l-mt-3">
          <span class="total-item">
            {{ 'employer.total' | lang }} {{ employeeTotalCount }}
            {{ 'employer.items' | lang }}
          </span>
        </div>

        <div class="row" *ngIf="isShowProgress">
          <div class="col-lg-3"></div>
          <cds-progress class="col-lg-6" mode="indeterminate" [type]="cdsProgressType" [text]="cdsProgressTxt" [color]="cdsProgressColor"></cds-progress>
          <div class="col-lg-3"></div>
        </div>
        <div *ngIf="!isShowProgress && employeeTotalCount !== 0">
          <div class="result-table">
            <div #tableBox class="table-box">
              <cds-table [dataSource]="dataSource" [class.full]="fullWidth">
                <cds-row *cdsRowDef="let row; index as i; columns: eeDelegationChangeDisplayedColumns"></cds-row>
                <cds-header-row *cdsHeaderRowDef="eeDelegationChangeDisplayedColumns"></cds-header-row>

                <ng-container cdsColumnDef="isSelected" sticky>
                  <cds-header-cell *cdsHeaderCellDef class="selected-column-width" [ngClass]="['no-border-right']">
                    <cds-checkbox
                      [checked]="allChecked"
                      [indeterminate]="computedIsHalfSelected()"
                      (change)="onHeaderCheckboxChange($event, markPage)"></cds-checkbox>
                  </cds-header-cell>
                  <cds-cell
                    *cdsCellDef="let element; let row; index as i"
                    [ngClass]="['selected-column-width', element.isSelected ? 'column-checked' : '', 'no-border-right']">
                    <cds-checkbox [checked]="element.isSelected" (change)="onClickRowSelect($event, i, markPage)"></cds-checkbox>
                  </cds-cell>
                </ng-container>

                <ng-container cdsColumnDef="employeeName" sticky>
                  <cds-header-cell
                    *cdsHeaderCellDef
                    class="employee-name-column-width"
                    [ngClass]="['no-border-right', checkIsHaveScroll ? 'shadow-right' : '']">
                    {{ 'employee.employeeName' | lang }}
                  </cds-header-cell>
                  <cds-cell
                    *cdsCellDef="let element"
                    [ngClass]="[
                      'employee-name-column-width',
                      element.isSelected ? 'column-checked' : '',
                      'no-border-right',
                      checkIsHaveScroll ? 'shadow-right' : ''
                    ]">
                    {{
                      this.language === 'EN'
                        ? element.memberName
                          ? element.memberName
                          : element.memberNameTC
                        : element.memberNameTC
                        ? element.memberNameTC
                        : element.memberName
                    }}
                  </cds-cell>
                </ng-container>

                <ng-container cdsColumnDef="trusteeMbrId">
                  <cds-header-cell *cdsHeaderCellDef class="trustee-mbr-id-column-width"> {{ 'delegationChange.certNo' | lang }}</cds-header-cell>
                  <cds-cell *cdsCellDef="let element" [ngClass]="['trustee-mbr-id-column-width', element.isSelected ? 'column-checked' : '']">
                    {{ element.trusteeMbrId }}
                  </cds-cell>
                </ng-container>

                <ng-container cdsColumnDef="delegateAgentInfo">
                  <cds-header-cell *cdsHeaderCellDef class="delegate-agent-info-column-width"> {{ 'employer.delegateAgentInfo' | lang }}</cds-header-cell>
                  <cds-cell *cdsCellDef="let element" [ngClass]="['delegate-agent-info-column-width', element.isSelected ? 'column-checked' : '']">
                    <div class="delegate-agent-info-item">
                      <span class="agent-code-name"
                        >{{ element.delegateAgentInfo?.agentCode }}
                        {{
                          language === 'EN'
                            ? element.delegateAgentInfo?.name
                            : element.delegateAgentInfo?.nameTC
                            ? element.delegateAgentInfo?.nameTC
                            : element.delegateAgentInfo?.name
                        }}
                      </span>
                    </div>
                  </cds-cell>
                </ng-container>

                <ng-container cdsColumnDef="newDelegateAgent">
                  <cds-header-cell *cdsHeaderCellDef class="new-delegate-agent-column-width">
                    {{ 'delegationChange.newDelegatedAgentCode' | lang }}</cds-header-cell
                  >
                  <cds-cell
                    *cdsCellDef="let element; let row; index as i"
                    [ngClass]="['new-delegate-agent-column-width', element.isSelected ? 'column-checked' : '']">
                    {{ element.inputtedAgentCode }}
                    <cds-assistive-text *ngIf="element.isMissingAgentCode" class="dlgt-er-check-message" type="error">
                      {{ 'delegationChange.missingNewDelegatedAgentCode' | lang }}
                    </cds-assistive-text>
                  </cds-cell>
                </ng-container>

                <ng-container cdsColumnDef="reasonOfChange">
                  <cds-header-cell *cdsHeaderCellDef class="reason-of-change-column-width"> {{ 'delegationChange.reasonOfChange' | lang }}</cds-header-cell>
                  <cds-cell
                    *cdsCellDef="let element; let row; index as i"
                    [ngClass]="['reason-of-change-column-width', element.isSelected ? 'column-checked' : '']">
                    <div class="reason-of-change">
                      {{ element.reasonOfChange | reasonOfChange : language }}
                    </div>
                    <div class="input-the-other-reason-of-change">
                      {{ element.inputtedReasonOfOthers }}
                    </div>
                    <cds-assistive-text *ngIf="element.isMissingReasonOfChange" class="dlgt-er-check-message" type="error">
                      {{ 'delegationChange.missingReasonCode' | lang }}
                    </cds-assistive-text>
                  </cds-cell>
                </ng-container>
              </cds-table>
            </div>
            <div class="row result-table-footer l-pt-3 l-pl-1">
              <div class="col-sm-6 col-xs-12 footer-pagination">
                <cds-pagination [color]="color" [pageCount]="pageCount" [page]="page" (pageChange)="pageChange($event)"> </cds-pagination>
              </div>
              <div class="col-sm-6 col-xs-12 page-search">
                <span>Page </span>
                <input class="input" [value]="searchValue" #pageNoSearchBox (keydown.enter)="delgtSearchByInputPageNo($event, pageNoSearchBox.value)" />
                <span> of {{ pageCount }} </span>
              </div>
            </div>
          </div>
        </div>
        <app-no-result-found *ngIf="employeeTotalCount === 0 && !isShowProgress"> </app-no-result-found>
      </div>
      <div class="delegation-change-footer">
        <ng-container *ngIf="stepperTarget === 'pc'">
          <div class="stepper-box">
            <app-cd-stepper class="er-stepper" #stepper>
              <app-cd-step #step *ngFor="let item of stepperItems; let i = index">
                <span [ngClass]="['cds-h6-demibold', step.finishStatus ? 'span-un' : 'span-fi']">{{ language === 'EN' ? item['en'] : item['zh'] }}</span>
              </app-cd-step>
            </app-cd-stepper>

            <div class="step-clear" (click)="dlgtChangeClearData()">
              <cds-icon class="step-icon" [icon]="reloadIcon"></cds-icon>
              <span class="cds-h6-demibold l-mb-0 step-clear-text"> {{ 'employer.reset' | lang }}</span>
            </div>
          </div>

          <div class="stepper-operate l-pt-4 l-pb-4">
            <div>
              <span class="cds-h6-demibold">{{ itemTotal }} {{ 'employee.itemTotal' | lang }}</span>
            </div>
            <div class="new-delegated-agent-code">
              <span>{{ 'delegationChange.newDelegatedAgentCode' | lang }}</span>
              <div class="batch-input-agent-code">
                <app-cd-input
                  type="number"
                  [disable]="isDisableBatchApplyAgentCode"
                  [(value)]="batchInputAgentCode"
                  #batchInputAgentCodeBox
                  [maxlength]="6"
                  (valueChange)="batchAgentCodeChange(batchInputAgentCodeBox.value)"></app-cd-input>
              </div>
              <cds-assistive-text *ngIf="!batchInputAgentCodeValid" type="error">
                <span *ngIf="batchInputAgentCodeErrorCode === 2101">{{ 'agentCode.notExists' | lang }} </span>
                <span *ngIf="batchInputAgentCodeErrorCode === 2102">{{ 'agentCode.inactiveAgent' | lang }} </span>
                <span *ngIf="batchInputAgentCodeErrorCode === 2103">{{ 'agentCode.notSameDistrict' | lang }} </span>
                <span *ngIf="batchInputAgentCodeErrorCode === 2199">{{ 'agentCode.lengthShouldBe6' | lang }} </span>
                <span *ngIf="batchInputAgentCodeErrorCode === 21031">
                  {{ 'agentCode.notHaveLicense' | lang }}
                </span>
              </cds-assistive-text>
              <span *ngIf="batchInputAgentCodeValid">{{
                this.language === 'EN'
                  ? batchInputAgentName
                    ? batchInputAgentName
                    : batchInputAgentNameTC
                  : batchInputAgentNameTC
                  ? batchInputAgentNameTC
                  : batchInputAgentName
              }}</span>
            </div>
            <div class="select-reason-of-change">
              <span>{{ 'delegationChange.selectReasonOfChange' | lang }}</span>
              <cds-dropdown
                placeholder="{{ 'delegationChange.selectReasonOfChange' | lang }}"
                [config]="reasonOfChangeDropdownConfig"
                [formControl]="reasonOfChangeFormControl"
                (cdsChange)="batchReasonOfChangeOnChange($event)"></cds-dropdown>
            </div>
            <div class="add-to-item" [style.whiteSpace]="'nowrap'">
              <cds-button
                (click)="applyToAll()"
                [disabled]="isDisableBatchApply"
                size="md"
                [style]="'secondary'"
                label="{{ 'delegationChange.addToItem' | lang }}">
              </cds-button>
            </div>
            <div class="submit">
              <cds-button
                [disabled]="isDisableSubmit"
                (click)="eeDelegationChangeSubmit()"
                size="md"
                [style]="'secondary'"
                label="{{ 'delegate.submit' | lang }}"></cds-button>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="stepperTarget === 'ipad'">
          <div class="stepper-box" style="padding-left: 0; padding-right: 0">
            <app-cd-stepper [isFreeStyle]="true" class="er-stepper" style="display: block" #stepper>
              <div freeStyle class="ipad-free-style">
                <div
                  *ngFor="let item of stepperItems; let i = index"
                  class="wrapper"
                  [ngStyle]="{
                    width: i === 0 || i === 2 || i === 3 ? '35%' : i === 1 ? '50%' : '30%'
                  }"
                  [ngClass]="[i > 1 ? 'border-top' : '', i === 2 ? 'move-up-two' : '']">
                  <app-cd-step [ngClass]="i === 0 || i === 2 ? 'padding-left' : ''" #step>
                    <span [ngClass]="['cds-h6-demibold', step.finishStatus ? 'span-un' : 'span-fi']">{{ language === 'EN' ? item['en'] : item['zh'] }}</span>
                  </app-cd-step>
                  <div pendant class="stepper-operate-ipad l-pt-4 l-pb-4">
                    <div *ngIf="i === 0" class="padding-left">
                      <span class="cds-h6-demibold">{{ itemTotal }} {{ 'employee.itemTotal' | lang }}</span>
                    </div>
                    <div *ngIf="i === 1" class="new-delegated-agent-code-ipad">
                      <span>{{ 'delegationChange.newDelegatedAgentCode' | lang }}</span>
                      <div class="batch-input-agent-code-ipad">
                        <app-cd-input
                          type="number"
                          [disable]="isDisableBatchApplyAgentCode"
                          [(value)]="batchInputAgentCode"
                          #batchInputAgentCodeBox
                          [maxlength]="6"
                          (valueChange)="batchAgentCodeChange(batchInputAgentCodeBox.value)"></app-cd-input>
                      </div>
                      <cds-assistive-text *ngIf="!batchInputAgentCodeValid" type="error">
                        <span *ngIf="batchInputAgentCodeErrorCode === 2101">{{ 'agentCode.notExists' | lang }} </span>
                        <span *ngIf="batchInputAgentCodeErrorCode === 2102">{{ 'agentCode.inactiveAgent' | lang }} </span>
                        <span *ngIf="batchInputAgentCodeErrorCode === 2103">{{ 'agentCode.notSameDistrict' | lang }} </span>
                        <span *ngIf="batchInputAgentCodeErrorCode === 2199">{{ 'agentCode.lengthShouldBe6' | lang }} </span>
                        <span *ngIf="batchInputAgentCodeErrorCode === 21031">
                          {{ 'agentCode.notHaveLicense' | lang }}
                        </span>
                      </cds-assistive-text>
                      <span *ngIf="batchInputAgentCodeValid">{{
                        this.language === 'EN'
                          ? batchInputAgentName
                            ? batchInputAgentName
                            : batchInputAgentNameTC
                          : batchInputAgentNameTC
                          ? batchInputAgentNameTC
                          : batchInputAgentName
                      }}</span>
                    </div>
                    <div *ngIf="i === 2" class="select-reason-of-change padding-left">
                      <span>{{ 'delegationChange.selectReasonOfChange' | lang }}</span>
                      <cds-dropdown
                        placeholder="{{ 'delegationChange.selectReasonOfChange' | lang }}"
                        [config]="reasonOfChangeDropdownConfig"
                        [formControl]="reasonOfChangeFormControl"
                        (cdsChange)="batchReasonOfChangeOnChange($event)"></cds-dropdown>
                    </div>
                    <div *ngIf="i === 3" class="add-to-item" [style.whiteSpace]="'nowrap'">
                      <cds-button
                        (click)="applyToAll()"
                        [disabled]="isDisableBatchApply"
                        size="md"
                        [style]="'secondary'"
                        label="{{ 'delegationChange.addToItem' | lang }}">
                      </cds-button>
                    </div>
                    <div *ngIf="i === 4" class="submit">
                      <cds-button
                        [disabled]="isDisableSubmit"
                        (click)="eeDelegationChangeSubmit()"
                        size="md"
                        [style]="'secondary'"
                        label="{{ 'delegate.submit' | lang }}"></cds-button>
                    </div>
                  </div>
                </div>
              </div>
            </app-cd-stepper>

            <div class="step-clear step-clear-ipad" (click)="dlgtChangeClearData()">
              <cds-icon class="step-icon" [icon]="reloadIcon"></cds-icon>
              <span class="cds-h6-demibold l-mb-0 step-clear-text"> {{ 'employer.reset' | lang }}</span>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>

  <div *ngIf="isInWhichStage === 2">
    <div class="l-pl-7">
      <div>
        <span class="cds-h3-demibold ee-dlgt-change-employer-name l-pt-7">{{
          language === 'EN' ? (employerName ? employerName : employerNameTC) : employerNameTC ? employerNameTC : employerName
        }}</span>
      </div>
      <div class="content l-pt-5">
        <div class="info-box change-summary-info">
          <span class="info">{{ 'delegationChange.changeSummaryInfo1' | lang }}</span>
          <span [ngClass]="['info', this.language === 'EN' ? 'info-warning' : '']">{{ 'delegationChange.changeSummaryInfo2' | lang }}</span>
          <span [ngClass]="['info', this.language === 'EN' ? '' : 'info-warning']">{{ 'delegationChange.changeSummaryInfo3' | lang }}</span>
        </div>
      </div>

      <div class="l-pt-7">
        <span class="cds-h2-light">{{ 'delegationChange.eeDelegationChangeRequestSummary' | lang }}</span>
      </div>
    </div>
    <div class="employee-result-top">
      <span class="total-item">
        {{ 'employer.total' | lang }}
        {{ totalCountEEDCConfirm }}
        {{ 'employer.items' | lang }}
      </span>
    </div>

    <div *ngIf="employeeTotalCount !== 0">
      <div class="result-table l-ml-7">
        <div #tableBox class="table-box">
          <cds-table [dataSource]="dataSourceInConfirmation" [class.full]="fullWidth">
            <cds-row *cdsRowDef="let row; index as i; columns: eeDelegationChangeConfirmationDisplayedColumns"></cds-row>
            <cds-header-row *cdsHeaderRowDef="eeDelegationChangeConfirmationDisplayedColumns"></cds-header-row>

            <ng-container cdsColumnDef="employeeName" sticky>
              <cds-header-cell *cdsHeaderCellDef [ngClass]="[checkIsHaveScroll ? 'shadow-right' : '']" class="employer-name-column-width no-border-right">
                {{ 'employee.employeeName' | lang }}
              </cds-header-cell>
              <cds-cell class="no-border-right" [ngClass]="[checkIsHaveScroll ? 'shadow-right' : '']" *cdsCellDef="let element">
                {{ this.language === 'EN' ? element.memberName : element.memberNameTC ? element.memberNameTC : element.memberName }}
              </cds-cell>
            </ng-container>

            <ng-container cdsColumnDef="trusteeMbrId">
              <cds-header-cell *cdsHeaderCellDef class="trustee-mbr-id-column-width"> {{ 'employee.no' | lang }}</cds-header-cell>
              <cds-cell *cdsCellDef="let element">
                {{ element.trusteeMbrId }}
              </cds-cell>
            </ng-container>

            <ng-container cdsColumnDef="delegateAgentInfo">
              <cds-header-cell *cdsHeaderCellDef class="delegate-agent-info-column-width"> {{ 'delegationChange.delegatedAgents' | lang }}</cds-header-cell>
              <cds-cell *cdsCellDef="let element; let row; index as i">
                {{ element.delegateAgentInfo.agentCode }}
              </cds-cell>
            </ng-container>

            <ng-container cdsColumnDef="newDelegateAgent">
              <cds-header-cell *cdsHeaderCellDef class="delegate-agent-info-column-width">
                {{ 'delegationChange.newDelegatedAgentCode' | lang }}</cds-header-cell
              >
              <cds-cell *cdsCellDef="let element; let row; index as i">
                {{ element.inputtedAgentCode }}
              </cds-cell>
            </ng-container>

            <ng-container cdsColumnDef="reasonOfChange">
              <cds-header-cell *cdsHeaderCellDef class="delegate-agent-info-column-width"> {{ 'delegationChange.reasonOfChange' | lang }}</cds-header-cell>
              <cds-cell *cdsCellDef="let element; let row; index as i">
                {{ element.reasonOfChange | reasonOfChange : language }}<br />
                {{ element.inputtedReasonOfOthers }}
              </cds-cell>
            </ng-container>
          </cds-table>
        </div>
        <div class="row result-table-footer">
          <div class="col-sm-6 col-xs-12 footer-pagination">
            <cds-pagination [color]="color" [pageCount]="pageCountOfEEDCConfirm" [page]="pageEEDCConfirm" (pageChange)="pageChange($event)"> </cds-pagination>
          </div>
          <div class="col-sm-6 col-xs-12 page-search">
            <span>Page </span>
            <input
              class="input"
              [value]="searchValueEEDCConfirm"
              #pageNoSearchBoxConfirm
              (keydown.enter)="delgtSearchByInputPageNo($event, pageNoSearchBoxConfirm.value)" />
            <span> of {{ pageCount }} </span>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom-botton l-pl-7">
      <cds-button
        label="{{ 'backToEdit' | lang }}"
        (click)="eeDelegationChangeBackToEdit()"
        size="md"
        class="btn"
        [config]="secondaryButtonConfig"></cds-button>
      <cds-button label="{{ 'confirm' | lang }}" (click)="eeDelegationChangeConfirm()" size="md" class="btn" [config]="primaryButtonConfig"> </cds-button>
    </div>
  </div>
</div>
