<app-loading [overlay]="true" *ngIf="isLoading"></app-loading>
<div class="row">
  <div class="col-xs-12 submission-container l-mb-8">
    <div class="row">
      <div class="row l-plr-0">
        <div class="title-icon">
          <cds-icon color="#00A758" width="40px" height="40px" icon="form:document"></cds-icon>
        </div>
        <div class="col-xs">
          <div class="row l-plr-0">
            <div class="col-xs-12">
              <div class="l-mb-1">
                <span class="cds-body2">Payment Submission Reference No.</span>
              </div>
              <div>
                <span class="cds-h4-demibold weight">
                  {{ paymentSubmission.paymentSubmissionReferenceNo }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-xs-12 p-line"></div>
    </div>

    <div class="row">
      <app-row-item label="common.company" class="col-xs-6 col-sm-3 col-md-3 col-lg-3">
        <ng-template appRowItemContentDef>
          <span class="cds-h6 font-weight-6 l-mb-0 l-mr-4">{{ paymentSubmission.employerAccountCode }}</span>
          <span class="cds-h6 font-weight-6 l-mb-0 l-mr-2">{{ paymentSubmission.companyEnName }}</span>
          <span class="cds-h6 font-weight-6 l-mb-0 l-mr-2">{{ paymentSubmission.companyZhName }}</span>
        </ng-template>
      </app-row-item>
      <app-row-item label="Workflow Reference No." class="col-xs-6 col-sm-3 col-md-3 col-lg-3">
        <ng-template appRowItemContentDef>
          <span *ngFor="let number of paymentSubmission.workflowReferenceNoList; index as i" class="cds-body1">
            <span class="click">{{ number }}</span
            ><span class="l-mr-2" *ngIf="paymentSubmission.workflowReferenceNoList && i !== paymentSubmission.workflowReferenceNoList.length - 1">, </span>
          </span>
        </ng-template>
      </app-row-item>
      <app-row-item label="Other Transaction Reference No." class="col-xs-6 col-sm-3 col-md-3 col-lg-3">
        <ng-template appRowItemContentDef>
          <span *ngFor="let number of paymentSubmission.otherTransactionReferenceNoList; index as i" class="cds-body1">
            <span>{{ number }}</span
            ><span
              class="l-mr-2"
              *ngIf="paymentSubmission.otherTransactionReferenceNoList && i !== paymentSubmission.otherTransactionReferenceNoList.length - 1"
              >,
            </span>
          </span>
        </ng-template>
      </app-row-item>
    </div>
  </div>

  <div class="dropdown-container l-mb-8">
    <cds-dropdown
      [formControl]="receiptReferenceNoControlControl"
      [config]="receiptReferenceNoDropdownConfig"
      (cdsChange)="onSelectChange($event)"></cds-dropdown>
  </div>

  <div class="col-xs-12 l-mb-6 l-plr-0">
    <div class="row l-plr-0">
      <app-row-item label="Receipt Status">
        <ng-template appRowItemContentDef>
          <app-icon-status [value]="reiceiptRefNoStatus" [statusMapObj]="reiceiptRefNoStatusMapObj"></app-icon-status>
        </ng-template>
      </app-row-item>
    </div>
  </div>

  <div class="col-xs-12">
    <span class="cds-body-hero">Payment Information</span>
  </div>

  <div class="col-xs-12 information-container l-mb-4">
    <div class="row">
      <div class="row l-plr-0">
        <div class="">
          <cds-icon color="#282B3E" width="20px" height="20px" icon="finance:bank"></cds-icon>
        </div>
        <div>
          <div class="row l-plr-0">
            <div class="col-xs-12">
              <div class="l-mb-1">
                <span class="cds-body1-demibold">Payer’s Bank information</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-xs-12 p-line"></div>
    </div>

    <div class="row">
      <app-row-item label="payment.bank-code" [data]="bankInformation.bankCode" class="l-mr-32"> </app-row-item>
      <app-row-item label="agent.branch-code" [data]="bankInformation.branchCode" class="l-mr-32"> </app-row-item>
      <app-row-item label="payment.bank-account-no" [data]="bankInformation.bankAccountNo" class="l-mr-32"> </app-row-item>
      <app-row-item label="Payer Name" [data]="bankInformation.payerName" class="l-mr-32"> </app-row-item>
    </div>

    <div class="row l-mt-6 table-container">
      <cds-table [dataSource]="bankInformationTableData" class="table full">
        <!-- Payment Method -->
        <ng-container cdsColumnDef="paymentMethod">
          <cds-header-cell *cdsHeaderCellDef> Payment Method </cds-header-cell>
          <cds-cell *cdsCellDef="let element" class="line-break">{{ service.optionLabelRender | fn : element.paymentMethod : paymentMethodOptions }}</cds-cell>
        </ng-container>

        <!-- Cheque Date -->
        <ng-container cdsColumnDef="chequeDate">
          <cds-header-cell *cdsHeaderCellDef> Cheque Date </cds-header-cell>
          <cds-cell *cdsCellDef="let element" class="line-break">{{ element.chequeDate | ssmsDate }}</cds-cell>
        </ng-container>

        <!-- Cheque Bank-In Date -->
        <ng-container cdsColumnDef="chequeBankInDate">
          <cds-header-cell *cdsHeaderCellDef> Cheque Bank-In Date </cds-header-cell>
          <cds-cell *cdsCellDef="let element" class="line-break">{{ element.chequeBankInDate | ssmsDate }}</cds-cell>
        </ng-container>

        <!-- Payment Cleared Date -->
        <ng-container cdsColumnDef="clearedDate">
          <cds-header-cell *cdsHeaderCellDef> Cleared Date </cds-header-cell>
          <cds-cell *cdsCellDef="let element" class="line-break">{{ element.clearedDate | ssmsDate }}</cds-cell>
        </ng-container>

        <!-- Payment Received Date -->
        <ng-container cdsColumnDef="receivedDate">
          <cds-header-cell *cdsHeaderCellDef> Received Date </cds-header-cell>
          <cds-cell *cdsCellDef="let element" class="line-break">{{ element.receivedDate | ssmsDate }}</cds-cell>
        </ng-container>

        <!-- Received Amount -->
        <ng-container cdsColumnDef="receivedAmount">
          <cds-header-cell *cdsHeaderCellDef> Received Amount </cds-header-cell>
          <cds-cell *cdsCellDef="let element" class="line-break">
            <span *ngIf="element.receivedAmount !== undefined">HKD </span>
            <span>{{ element.receivedAmount | number : '1.2-2' }}</span>
          </cds-cell>
        </ng-container>

        <!-- Cheque Direct Mailing to eMPF Flag -->
        <ng-container cdsColumnDef="chequeDirectMailingToeMpfFlag">
          <cds-header-cell *cdsHeaderCellDef> Cheque Direct Mailing to eMPF Flag </cds-header-cell>
          <cds-cell *cdsCellDef="let element" class="line-break">
            <cds-checkbox [disabled]="true" [checked]="element.chequeDirectMailingToeMpfFlag === 'Y'"></cds-checkbox>
          </cds-cell>
        </ng-container>

        <cds-header-row *cdsHeaderRowDef="displayedColumns"></cds-header-row>
        <cds-row class="cds-body1" *cdsRowDef="let row; columns: displayedColumns"></cds-row>
      </cds-table>
    </div>
  </div>

  <div class="col-xs-12 information-container l-mb-8">
    <div class="row">
      <div class="row l-plr-0">
        <div class="">
          <cds-icon color="#282B3E" width="20px" height="20px" icon="finance:bank"></cds-icon>
        </div>
        <div>
          <div class="row l-plr-0">
            <div class="col-xs-12">
              <div class="l-mb-1">
                <span class="cds-body1-demibold">Trustee’s Bank information</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-xs-12 p-line"></div>
    </div>

    <div class="row">
      <app-row-item label="payment.bank-code" [data]="bankInformation.trusteeBankCode" class="l-mr-32"> </app-row-item>
      <app-row-item label="agent.branch-code" [data]="bankInformation.trusteeBranchCode" class="l-mr-32"> </app-row-item>
      <app-row-item label="payment.bank-account-no" [data]="bankInformation.trusteeBankAccountNo" class="l-mr-32"> </app-row-item>
    </div>
  </div>

  <div class="col-xs-12 l-pt-7">
    <span class="cds-h2-light">Member Level Records</span>
  </div>

  <div class="col-xs-12 row l-plr-0 relative" [formGroup]="form" *ngIf="formReady">
    <app-loading [overlay]="true" *ngIf="contributionPeriodLoading"></app-loading>
    <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 l-pt-4 l-pb-4">
      <cds-textfield formControlName="memberAccountCode" label="Member Account Code" placeholder="Input Member Account Code"></cds-textfield>

      <div *ngIf="memberAccountCodeControl?.invalid && (memberAccountCodeControl?.dirty || memberAccountCodeControl?.touched)" class="l-pt-2">
        <cds-assistive-text *ngIf="memberAccountCodeControl?.errors?.['maxlength']" class="l-pt-2" [showIcon]="false">Max length is 15</cds-assistive-text>
      </div>
    </div>
    <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 l-pt-4 l-pb-4">
      <cds-textfield formControlName="memberName" label="Member Name" placeholder="Input Member Name"></cds-textfield>

      <div *ngIf="memberNameControl?.invalid && (memberNameControl?.dirty || memberNameControl?.touched)" class="l-pt-2">
        <cds-assistive-text *ngIf="memberNameControl?.errors?.['maxlength']" class="l-pt-2" [showIcon]="false">Max length is 200</cds-assistive-text>
      </div>
    </div>
    <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 l-pt-4 l-pb-4">
      <cds-dropdown formControlName="contributionPeriod" [config]="contributionPeriodConfig"></cds-dropdown>
    </div>

    <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 l-mt-4 l-pb-4 bottom-xs l-pt-4">
      <div style="width: 300px">
        <cds-button
          class="l-mr-4 botton"
          [disabled]="searchButtonDisabled"
          [config]="searchButtonConfig"
          [label]="'list.search' | lang"
          (click)="search()"></cds-button>
        <cds-button class="botton" [disabled]="resetButtonDisabled" [config]="resetButtonConfig" [label]="'list.reset' | lang" (click)="reset()"></cds-button>
      </div>
    </div>
  </div>

  <div class="col-xs-12 col-md-12 l-pb-3 l-d-f l-jc-sb l-mt-5 l-mb-3">
    <div>
      <div class="cds-body1 l-mb-0">Total {{ numberOfElements | number }} records</div>
    </div>
  </div>

  <div class="col-xs-12 min-height">
    <app-data-table
      *ngIf="showTable"
      [frontPagination]="false"
      [isLoading]="memberLoading"
      [fixed]="false"
      [overlay]="true"
      [pageConfig]="pageConfig"
      [dataSource]="dataSource"
      [columnsConfig]="columnsConfig"
      (reloadTable)="reloadTable($event)">
      <ng-template appDtBodyCellDef="memberName" let-element>
        <span (click)="goToDetail(element)" class="cds-body1 l-mb-0 font-weight-6">
          <div>
            <span class="text-underline">{{ element.lastName }} </span>
            <span class="text-underline">{{ element.firstName }}</span>
          </div>
          <div>
            <span class="text-underline">{{ element.lastZhName }}</span>
            <span class="text-underline">{{ element.firstZhName }}</span>
          </div>
        </span>
      </ng-template>

      <ng-template appDtBodyCellDef="contributionPeriod" let-element>
        <span>{{ element['contributionStart'] | ssmsDate }}</span>
        -
        <span>{{ element['contributionEnd'] | ssmsDate }}</span>
      </ng-template>

      <ng-template appDtBodyCellDef="receivedAmount" let-element>
        <span *ngIf="element.receivedAmount !== undefined">HKD </span>
        <span>{{ element.receivedAmount | number : '1.2-2' }}</span>
      </ng-template>
    </app-data-table>
  </div>
</div>
