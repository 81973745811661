/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { CdsOption } from '@cds/ng-core/configuration';
import { CdsDropdownConfig } from '@cds/ng-core/dropdown';
import { ColumnConfig, PageConfig } from 'src/app/shared/data-table';
import { configPrimary, configSecondary, initPage, InterestRate, InterestRateSearch, InterestRateStatus } from '../../employer';
import { CdsPopupService, MatDialogRef } from '@cds/ng-web-components/popup';
import { finalize } from 'rxjs';
import { InterestRateAddPopupComponent } from '../interest-rate-add-popup/interest-rate-add-popup.component';
import { InterestRateHistoryPopupComponent } from '../interest-rate-history-popup/interest-rate-history-popup.component';
import { InterestRateService } from '../../interest-rate.service';

@Component({
  selector: 'app-interest-rate',
  templateUrl: './interest-rate.component.html',
  styleUrls: ['./interest-rate.component.scss'],
})
export class InterestRateComponent implements OnInit, OnChanges {
  constructor(private service: InterestRateService, private cdsPopup: CdsPopupService) {}

  @Input() load = false;

  form = new FormGroup({
    fundCode: new FormControl('', []),
    year: new FormControl(),
  });

  searchDTO: InterestRateSearch = initPage();

  fundCodeConfig: CdsOption[] = [];

  label = {
    fundCode: {
      label: 'Fund Code ',
      placeholder: 'Select Fund Code',
    },
    year: {
      label: 'Calendar Year',
      placeholder: 'Select Calendar Year',
    },
  };

  yearCodeConfig: CdsDropdownConfig = {
    label: 'Calendar Year',
    placeholder: 'Select Calendar Year',
    options: [
      { label: '2023', value: 2023 },
      { label: '2024', value: 2024 },
    ],
  };

  searchButtonConfig = configPrimary();
  _searchDisable = false;

  resetButtonConfig = configSecondary();
  _resetDisable = false;

  addButtonConfig = configPrimary();

  approveConfig = configSecondary();

  _valid = false;

  _loading = false;

  pageConfig: PageConfig = {
    current: 1,
    totalCount: 0,
    pageSize: 10,
  };

  columnConfig: ColumnConfig[] = [
    { title: 'Fund Code', key: 'code' },
    { title: 'Month / Year', key: 'date' },
    { title: 'Normal Rate', key: 'normalRate' },
    { title: 'Withdrawal Rate', key: 'withdrawalRate' },
    { title: 'Interim Rate', key: 'interimRate' },
    { title: 'Approval', key: 'status' },
  ];

  dataList: InterestRate[] = [];

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['load'].currentValue) {
      this.listAllFund();
      this.loadTable();
    }
  }

  ngOnInit(): void {}

  listAllFund() {
    this.service
      .listAllFund()
      .pipe(
        finalize(() => {
          // this._loading = false;
        })
      )
      .subscribe(data => {
        if (data.result === 0 && data.data) {
          this.fundCodeConfig = data.data.map(x => {
            return {
              label: x.code + ' ' + x.name + ' ' + x.zhName,
              value: x.code,
            };
          });
        }
      });
  }

  pageChange(ev: any) {
    if (this.pageConfig.current !== ev.current) {
      this.pageConfig.current = ev.current;
      this.loadTable();
    }
  }

  statusFormat(status: InterestRateStatus) {
    let re = '';
    switch (status) {
      case InterestRateStatus.PENDING:
        re = 'Pending approval';
        break;
      case InterestRateStatus.APPROVED:
        re = 'Approved';
        break;
      default:
    }
    return re;
  }
  approve(item: InterestRate) {
    this._loading = true;
    const param: InterestRate = {
      id: item.id,
      status: InterestRateStatus.APPROVED,
    };
    this.service
      .interestRateApprove(param)
      .pipe(
        finalize(() => {
          this._loading = false;
        })
      )
      .subscribe(data => {
        if (data.result === 0 && data.data) {
          this.loadTable();
        }
      });
  }

  openHistory(item: InterestRate) {
    this.cdsPopup.open(InterestRateHistoryPopupComponent, {
      size: 'md',
      data: item,
    });
  }

  add() {
    const popupRef: MatDialogRef<InterestRateAddPopupComponent> = this.cdsPopup.open(InterestRateAddPopupComponent, {
      size: 'md',
      data: {},
    });
    popupRef.afterClosed().subscribe(data => {
      if (data) {
        this.loadTable();
      }
    });
  }

  searchFn() {
    if (this._valid) {
      this.loadTable();
    }
  }

  reset() {
    this.searchDTO = initPage();
    this.loadTable();
  }

  getRequestParams() {
    const params: InterestRateSearch = { ...this.searchDTO };
    params.page = (this.pageConfig.current as number) - 1;
    return params;
  }

  loadTable() {
    const params: InterestRateSearch = this.getRequestParams();
    this._loading = true;
    this.service
      .interestRatePage(params)
      .pipe(
        finalize(() => {
          this._loading = false;
        })
      )
      .subscribe(data => {
        if (data.result === 0) {
          this.dataList = data.data ? data.data.content : [];
          this.pageConfig = {
            ...this.pageConfig,
            totalCount: data.data ? data.data.totalElements : 0,
          };
        }
      });
  }

  selectedChange(val: string, type: string) {
    switch (type) {
      case 'fundCode':
        this.searchDTO.fundCode = val;
        break;
      case 'year':
        this.searchDTO.year = val;
        break;
      default:
    }
    this.validating();
  }

  validating() {
    if (!!this.searchDTO.fundCode || !!this.searchDTO.year) {
      this._valid = true;
    } else {
      this._valid = false;
    }
  }
}
