import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CdsButtonConfig } from '@cds/ng-core/button';
import { CdsLangService } from '@cds/ng-core/lang';
import { CdsTextfieldConfig } from '@cds/ng-core/textfield';
import { CdsPopupService, MatDialogRef } from '@cds/ng-web-components/popup';
import { finalize } from 'rxjs';
import { AlertPopupComponent } from 'src/app/shared/alert-popup/alert-popup.component';
import { ContinuePopupComponent } from 'src/app/shared/continue-popup/continue-popup.component';
import { agentCodeListValidator } from 'src/app/shared/validators/validators';
import { AgentAndAssignedGroup } from 'src/app/views/agent-list/agent-list';
import { AgentListService } from '../agent-list.service';

@Component({
  selector: 'app-agent-list-add',
  templateUrl: './agent-list-add.component.html',
  styleUrls: ['./agent-list-add.component.scss'],
})
export class AgentListAddComponent implements OnInit {
  confirmButtonConfig: CdsButtonConfig = { size: 'sm' };

  saveButtonConfig: CdsButtonConfig = { size: 'sm' };

  resetButtonConfig: CdsButtonConfig = { size: 'sm', style: 'secondary' };

  backToEditButtonConfig: CdsButtonConfig = { size: 'sm', style: 'secondary' };

  inEdit = true;

  get title() {
    return this.inEdit ? 'Add Record' : 'Record Summary';
  }

  get saveDisabled() {
    return !(this.form.dirty && this.form.valid) || this.isLoading;
  }

  get resetDisabled() {
    return !this.form.dirty || this.isLoading;
  }

  agentTextfieldConfig: CdsTextfieldConfig = {
    label: 'Agent Code',
    placeholder: 'Input Agent Code',
    type: 'text',
  };

  accountNumbertxtfieldConfig: CdsTextfieldConfig = {
    label: 'Employer Account Code',
    placeholder: 'Input Employer Account Code',
    type: 'text',
  };

  companyNametxtfieldConfig: CdsTextfieldConfig = {
    label: 'Company Name',
    placeholder: 'Input Company Name',
    type: 'text',
  };

  form: FormGroup = new FormGroup({});

  isLoading = false;

  get agent() {
    return this.form.get('agent');
  }

  get trusteeEmployerAccountNumber() {
    return this.form.get('trusteeEmployerAccountNumber');
  }

  get companyName() {
    return this.form.get('companyName');
  }

  get remarks() {
    return this.form.get('remarks');
  }

  constructor(
    private dialogRef: MatDialogRef<AgentListAddComponent>,
    private cdsPopup: CdsPopupService,
    private langService: CdsLangService,
    private agentListService: AgentListService
  ) {}

  ngOnInit() {
    this.initForm();
  }

  initForm() {
    this.form = new FormGroup({
      agent: new FormControl('', [agentCodeListValidator(), Validators.required]),
      trusteeEmployerAccountNumber: new FormControl('', [Validators.maxLength(100), Validators.required]),
      companyName: new FormControl('', [Validators.maxLength(300), Validators.required]),
      remarks: new FormControl('', [Validators.maxLength(500)]),
    });
  }

  changeFormImmediately(key: string, value: string) {
    if (value && typeof value === 'string') {
      this.form.get(key)?.setValue(value.trim());
    }
  }

  confirm() {
    if (this.isLoading) return;
    const agentAndAssignedGroup: AgentAndAssignedGroup = this.form.value;
    const split = agentAndAssignedGroup.agent?.split(',');
    agentAndAssignedGroup.agentList = split;

    this.isLoading = true;

    this.agentListService
      .add(agentAndAssignedGroup)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe({
        next: res => {
          if (res.result === 0) {
            this.popupWithReload();
          } else {
            this.requestAlert(res.message);
          }
        },
        error: err => {
          this.requestAlert(err.message);
        },
      });
  }

  save() {
    if (this.saveDisabled) {
      return;
    }
    this.inEdit = false;
  }

  reset() {
    if (this.resetDisabled) {
      return;
    }

    this.cdsPopup
      .open(ContinuePopupComponent, {
        size: 'sm',
        data: { message: this.langService.translate('common.action.cancel.italic') },
      })
      .afterClosed()
      .subscribe(result => {
        if (result?.agree) {
          this.form.setValue({
            agent: '',
            trusteeEmployerAccountNumber: '',
            companyName: '',
            remarks: '',
          });
          this.form.markAsPristine();
          this.form.markAsUntouched();
        }
      });
  }

  backToEdit() {
    if (this.isLoading) return;
    this.inEdit = true;
  }

  close() {
    if (!this.resetDisabled) {
      this.cdsPopup
        .open(ContinuePopupComponent, {
          size: 'sm',
          data: { message: this.langService.translate('common.action.cancel.italic') },
        })
        .afterClosed()
        .subscribe(result => {
          if (result?.agree) {
            this.dialogRef.close();
          }
        });
    } else {
      this.dialogRef.close();
    }
  }

  requestAlert(message: string) {
    this.cdsPopup
      .open(AlertPopupComponent, {
        size: 'sm',
        data: {
          title: 'Error',
          message: `<span class='cds-h4-light'>${message}</span>`,
          buttonName: this.langService.translate('confirm'),
        },
      })
      .afterClosed()
      .subscribe(() => {});
  }

  popupWithReload() {
    this.cdsPopup
      .open(AlertPopupComponent, {
        size: 'sm',
        data: {
          message: this.langService.translate('common.action.success.italic'),
          buttonName: this.langService.translate('common.gotItWithExclamation'),
        },
      })
      .afterClosed()
      .subscribe(() => {
        this.dialogRef.close({
          agree: true,
          reload: true,
        });
      });
  }
}
