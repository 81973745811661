<div [formGroup]="group">
  <div class="cds-body2 cds-body2 cds-dark-navy-text light-3">
    {{ controlLabel | lang }}
    <span class="font-error" *ngIf="!nullable && !locked">*</span>
  </div>
  <div class="autocomplete-box">
    <input
      [ngClass]="{
        'text-field': !locked,
        'input-no-border': locked,
        'text-field-error': fieldErrMsg,
        'disable-selected': disabled
      }"
      type="text"
      placeholder="{{ (locked ? '' : controlPlaceholder) | lang }}"
      #inputBox
      [formControlName]="controlName"
      (blur)="icon = 'action:button_down'"
      [matAutocomplete]="auto"
      #trigger="matAutocompleteTrigger"
      appAutocompletePosition="trigger" />
    <mat-autocomplete
      class="autocomplete-border-panel"
      autoActiveFirstOption
      #auto="matAutocomplete"
      (optionSelected)="optionSelected($event)"
      (opened)="icon = 'action:button_up'"
      (closed)="icon = 'action:button_down'"
      [displayWith]="displayFn.bind(this)">
      <mat-option *ngFor="let option of filterList | async" [value]="option.value">
        {{ option.label | lang }}
      </mat-option>
    </mat-autocomplete>
    <cds-assistive-text *ngIf="fieldErrMsg">{{ fieldErrMsg }}</cds-assistive-text>
    <cds-icon *ngIf="!locked" class="autocomplete-select-icon" [icon]="icon" (click)="iconClick()"></cds-icon>
    <cds-icon
      *ngIf="!locked && inputBox.value"
      class="autocomplete-clear-icon"
      icon="action:button_x_filled"
      [config]="{ color: '#8E90A2' }"
      (click)="clearInput()">
    </cds-icon>
  </div>
</div>
