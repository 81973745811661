import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors } from '@angular/forms';
import { maxValidator } from 'src/app/shared/validators/validators';

@Directive({
  selector: '[appMaxValidator]',
  providers: [{ provide: NG_VALIDATORS, useExisting: MaxValidatorDirective, multi: true }],
})
export class MaxValidatorDirective {
  @Input('appMaxValidator') max = 0;

  validate(control: AbstractControl): ValidationErrors | null {
    return maxValidator(this.max)(control);
  }
}
