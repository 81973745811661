<div class="row l-plr-0">
  <cds-form-field class="col-xs-12 col-md-4 l-pl-0">
    <cds-textfield [label]="'role.name' | lang" [placeholder]="'role.name.placeholder' | lang" [formControl]="roleName"> </cds-textfield>
    <cds-assistive-text *ngIf="roleName.errors && roleName.dirty">
      <div *ngIf="roleName.errors?.['required']">input is required</div>
      <div *ngIf="roleName.errors?.['minlength']">Must be at least 3 characters long.</div>
    </cds-assistive-text>
  </cds-form-field>
  <div class="col-xs-12 col-md-4 l-pl-0" *ngIf="roleNameIn">
    <cds-button [style]="'primary'" size="sm" (click)="onDelete()">
      {{ 'common.delete' | lang }}
    </cds-button>
  </div>
</div>
<div class="l-pt-4">
  <div class="cds-h2 cds-light l-mb-0">
    {{ 'role.accessRight' | lang }}
  </div>
  <p class="cds-body1 l-mb-4">{{ 'role.accessRight.notes' | lang }}</p>
  <div>
    <cds-table [dataSource]="dispalyData" [class.full]="true">
      <cds-header-row *cdsHeaderRowDef="accessColumns"></cds-header-row>
      <cds-row *cdsRowDef="let row; let i = index; columns: accessColumns" [ngStyle]="{ display: hiddenRow(row) ? 'none' : 'table-row' }"> </cds-row>

      <ng-container cdsColumnDef="expand">
        <cds-header-cell *cdsHeaderCellDef></cds-header-cell>
        <cds-cell *cdsCellDef="let element">
          <button *ngIf="element.expand !== undefined && !element.expand" class="icon-button" (click)="element['expand'] = !element['expand']">
            <cds-icon icon="action:button_right"></cds-icon>
          </button>
          <button *ngIf="element.expand !== undefined && element.expand" class="icon-button" (click)="element['expand'] = !element['expand']">
            <cds-icon icon="action:button_down"></cds-icon>
          </button>
        </cds-cell>
      </ng-container>

      <ng-container cdsColumnDef="permissionName">
        <cds-header-cell *cdsHeaderCellDef>{{ 'role.form.header.siteArea' | lang }} </cds-header-cell>
        <cds-cell *cdsCellDef="let permission">
          <div [ngClass]="{ 'l-pl-4': permission.expand === undefined }">
            {{ permission.permissionItem }}
          </div>
        </cds-cell>
      </ng-container>

      <ng-container cdsColumnDef="execute">
        <cds-header-cell *cdsHeaderCellDef>{{ 'role.form.header.execute' | lang }} </cds-header-cell>
        <cds-cell *cdsCellDef="let permission">
          <cds-checkbox
            *ngIf="!permission.parentId; else subItemTempE"
            [checked]="isGroupCheck(permissionAccess.E, permission.permissionItem)"
            [indeterminate]="isPartially(permissionAccess.E, permission.permissionItem)"
            (change)="changeGroup($event, permissionAccess.E, permission)">
          </cds-checkbox>
          <ng-template #subItemTempE>
            <cds-checkbox
              [checked]="isCheck(permissionAccess.E, permission.permissionItem)"
              (change)="changePermission($event, permissionAccess.E, permission)">
            </cds-checkbox>
          </ng-template>
        </cds-cell>
      </ng-container>

      <ng-container cdsColumnDef="edit">
        <cds-header-cell *cdsHeaderCellDef>
          {{ 'role.form.header.edit' | lang }}
        </cds-header-cell>
        <cds-cell *cdsCellDef="let permission">
          <cds-checkbox
            *ngIf="!permission.parentId; else subItemTempW"
            [checked]="isGroupCheck(permissionAccess.W, permission.permissionItem)"
            [indeterminate]="isPartially(permissionAccess.W, permission.permissionItem)"
            (change)="changeGroup($event, permissionAccess.W, permission)">
          </cds-checkbox>
          <ng-template #subItemTempW>
            <cds-checkbox
              [checked]="isCheck(permissionAccess.W, permission.permissionItem)"
              (change)="changePermission($event, permissionAccess.W, permission)">
            </cds-checkbox>
          </ng-template>
        </cds-cell>
      </ng-container>

      <ng-container cdsColumnDef="read">
        <cds-header-cell *cdsHeaderCellDef>
          {{ 'role.form.header.read' | lang }}
        </cds-header-cell>
        <cds-cell *cdsCellDef="let permission">
          <cds-checkbox
            *ngIf="!permission.parentId; else subItemTempR"
            [checked]="isGroupCheck(permissionAccess.R, permission.permissionItem)"
            [indeterminate]="isPartially(permissionAccess.R, permission.permissionItem)"
            (change)="changeGroup($event, permissionAccess.R, permission)">
          </cds-checkbox>
          <ng-template #subItemTempR>
            <cds-checkbox
              [checked]="isCheck(permissionAccess.R, permission.permissionItem)"
              (change)="changePermission($event, permissionAccess.R, permission)">
            </cds-checkbox>
          </ng-template>
        </cds-cell>
      </ng-container>

      <ng-container cdsColumnDef="noAccess">
        <cds-header-cell *cdsHeaderCellDef>
          {{ 'role.form.header.noAccess' | lang }}
        </cds-header-cell>
        <cds-cell *cdsCellDef="let permission">
          <div *ngIf="!permission.parentId; else subItemTempN">
            <cds-checkbox
              [disabled]="isGroupCheck(null, permission.permissionItem)"
              [checked]="isGroupCheck(null, permission.permissionItem)"
              [indeterminate]="isPartially(null, permission.permissionItem)"
              (change)="changeGroup($event, null, permission)">
            </cds-checkbox>
          </div>
          <ng-template #subItemTempN>
            <cds-checkbox
              [disabled]="isCheck(null, permission.permissionItem)"
              [checked]="isCheck(null, permission.permissionItem)"
              (change)="changePermission($event, null, permission)">
            </cds-checkbox>
          </ng-template>
        </cds-cell>
      </ng-container>
    </cds-table>
  </div>
  <div class="l-pt-4">
    <cds-button [disabled]="saveDisable" [style]="'primary'" size="md" (click)="onSave()">
      {{ 'common.save' | lang }}
    </cds-button>
  </div>
</div>
