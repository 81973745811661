/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Inject, OnInit } from '@angular/core';
import { CDS_POPUP_DATA, MatDialogRef } from 'src/app/shared/cd-popup';
import { FILTER } from './report-filter.component.config';

@Component({
  selector: 'app-report-filter',
  templateUrl: './report-filter.component.html',
  styleUrls: ['./report-filter.component.scss'],
})
export class ReportFilterComponent implements OnInit {
  filters: any[] = FILTER;
  selectFilters: string[] = [];

  constructor(
    private dialogRef: MatDialogRef<ReportFilterComponent>,
    @Inject(CDS_POPUP_DATA)
    public data: {
      filters: string[];
    }
  ) {
    this.selectFilters = [...this.data.filters];
  }

  ngOnInit() {}

  get resetDisbaled() {
    return !this.selectFilters.length;
  }

  isFilterSelected(value: string) {
    return this.selectFilters.includes(value);
  }

  close() {
    this.dialogRef.close(this.data.filters);
  }

  toggleShow(index: number) {
    this.filters[index].open = !this.filters[index].open;
  }

  toggleSelected(index: number, listIndex: number) {
    const value = this.filters[index].list[listIndex].value;
    const isSelected = this.isFilterSelected(value);
    if (isSelected) {
      this.selectFilters.splice(this.selectFilters.indexOf(value), 1);
      return;
    }
    this.selectFilters.push(value);
  }

  reset() {
    this.data.filters = [];
    this.selectFilters = [];
  }

  apply() {
    this.dialogRef.close(this.selectFilters);
  }
}
