import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CdsLanguageModule } from '@cds/ng-core/lang';

import { CdStepperComponent } from './cd-stepper.component';
import { CdStepComponent } from './cd-step/cd-step.component';

@NgModule({
  imports: [CommonModule, CdsLanguageModule],
  declarations: [CdStepperComponent, CdStepComponent],
  exports: [CdStepperComponent, CdStepComponent],
})
export class CdStepperModule {}
