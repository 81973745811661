import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AdminLayoutComponent } from '../layout/admin-layout/admin-layout.component';

import { PermissionAccess, PermissionItem } from '../../core/models/enum/permission.enum';

import { DetailsComponent } from './details/details.component';

const routes: Routes = [
  {
    path: 'payment',
    component: AdminLayoutComponent,
    children: [
      {
        path: 'details',
        component: DetailsComponent,
        data: {
          roles: {
            [PermissionItem.COMMISSION_SCALE_MANAGEMENT]: PermissionAccess.R,
          },
        },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PaymentRoutingModule {}
