import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';

import { fromEvent, Subscription } from 'rxjs';

import { ParentElement } from '../case-summary.model';
import { CdsButtonConfig } from '@cds/ng-core/button';
import { MatDialogRef } from '@cds/ng-web-components/popup';

@Component({
  selector: 'app-advise-empfstatus',
  templateUrl: './advise-empfstatus.component.html',
  styleUrls: ['./advise-empfstatus.component.scss'],
})
export class AdviseEmpfstatusComponent implements OnInit, AfterViewInit, OnDestroy {
  unDocumentClick!: Subscription;
  private clickIsClose = true;

  constructor(private dialogRef: MatDialogRef<AdviseEmpfstatusComponent>) {}
  clearButton: CdsButtonConfig = {
    size: 'sm',
    style: 'primary',
    disabled: false,
  };
  ngOnInit(): void {}
  backTosummary() {
    //this.router.navigate(['/agent/cs']);
    this.dialogRef.close({ agree: true });
    //this.disagree();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.unDocumentClick = fromEvent(document, 'click').subscribe(event => {
        this.clickIsClose = true;
        const newEvent = event as unknown as {
          target: { classList: DOMTokenList; parentElement: ParentElement };
        };
        this.checkStyle(newEvent.target, () => {
          this.clickIsClose = false;
        });
        if (this.clickIsClose) {
          this.clickIsClose = true;
          this.backTosummary();
        }
      });
    }, 0);
  }

  checkStyle(element: ParentElement, callback: () => void) {
    if (!element.classList.contains('advise-empfstatus')) {
      if (element.parentElement) {
        this.checkStyle(element.parentElement, callback);
      }
    } else {
      callback();
    }
  }

  ngOnDestroy(): void {
    this.unDocumentClick?.unsubscribe();
  }
}
