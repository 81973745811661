<div class="row l-plr-0" [formGroup]="form">
  <div class="col-xs-12 l-pt-7 l-plr-0 l-mb-4">
    <span class="cds-h2-light">{{ 'list.serach-record' | lang }}</span>
  </div>

  <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 l-pb-4">
    <cds-dropdown formControlName="quoteType" [config]="fundCOdeConfig"></cds-dropdown>
  </div>

  <div *ngIf="showDatePicker" class="col-xs-12 col-sm-6 col-md-4 col-lg-3 l-pt-4 l-pb-4 date-picker">
    <cds-textfield formControlName="date" placeholder="DD/MM/YYYY" label="Date" [cdsDatepicker]="datePicker"> </cds-textfield>
    <cds-datepicker #datePicker="cdsDatepicker"></cds-datepicker>

    <div *ngIf="dateControl?.invalid && (dateControl?.dirty || dateControl?.touched)" class="l-pt-2">
      <cds-assistive-text *ngIf="dateControl?.errors?.['dateInvalid']" class="l-pt-2" [showIcon]="false">{{ 'create.dateError' | lang }}</cds-assistive-text>
    </div>
  </div>

  <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 l-pb-4 bottom-xs search-button-container">
    <cds-button
      class="l-mr-4 botton"
      [config]="searchButtonConfig"
      [disabled]="searchButtonDisabled"
      label="{{ 'list.search' | lang }}"
      (click)="search()"></cds-button>
    <cds-button class="botton" [config]="resetButtonConfig" [disabled]="resetButtonDisabled" label="{{ 'list.reset' | lang }}" (click)="reset()"></cds-button>
  </div>

  <div class="col-xs-12 col-md-12 l-pb-3 l-d-f l-jc-sb l-mt-7 l-plr-0">
    <div>
      <div class="cds-h2-light l-ma-0">{{ 'common.recordList' | lang }}</div>
      <div class="cds-detail1-demibold">Total {{ totalElements }} records</div>
    </div>
    <div *ngIf="canEdit">
      <cds-button [config]="uploadBtnCfg" size="sm" class="l-mr-5" (click)="upload()" [disabled]="isLoading"> Upload Unit Price List </cds-button>
    </div>
  </div>

  <div class="col-xs-12 col-md-12 l-plr-0">
    <div class="min-height">
      <app-data-table
        [frontPagination]="false"
        [isLoading]="isLoading"
        [fixed]="false"
        [overlay]="true"
        [pageConfig]="pageConfig"
        [dataSource]="dataSource"
        [columnsConfig]="columnsConfig"
        (reloadTable)="reloadTable($event)">
        <ng-template appDtBodyCellDef="fundCode" let-element>
          <div>{{ element['fundCode'] }}</div>
          <div>
            <span>{{ element['name'] }}</span>
            <span class="l-ml-2">{{ element['chineseName'] }}</span>
          </div>
        </ng-template>

        <ng-template appDtBodyCellDef="date" let-element>
          {{ element['date'] | ssmsDate }}
        </ng-template>

        <ng-template appDtBodyCellDef="unitPrice" let-element>
          {{ element['unitPrice'] | number }}
        </ng-template>
      </app-data-table>
    </div>
  </div>
</div>
