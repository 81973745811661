import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { CdsIconModule } from '@cds/ng-web-components/icon';
import { CdsAssistiveTextModule } from '@cds/ng-web-components/assistive-text';
import { CdsPopupModule } from '@cds/ng-web-components/popup';
import { CdsLanguageModule } from '@cds/ng-core/lang';
import { CdsButtonModule } from '@cds/ng-web-components/button';
import { SharedModule } from 'src/app/shared/shared.module';

import { CommissionDefaultScaleComponent } from './commission-default-scale.component';
import { NewBusinessMpfComponent } from './new-business-mpf/new-business-mpf.component';
import { IndividualTransferMpfComponent } from './individual-transfer-mpf/individual-transfer-mpf.component';
import { NewBusinessMacauComponent } from './new-business-macau/new-business-macau.component';
import { AutoChangeListComponent } from './new-business-macau/auto-change-list/auto-change-list.component';

@NgModule({
  imports: [
    CommonModule,
    CdsPopupModule,
    CdsLanguageModule,
    CdsIconModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    CdsAssistiveTextModule,
    CdsButtonModule,
  ],
  declarations: [CommissionDefaultScaleComponent, NewBusinessMpfComponent, IndividualTransferMpfComponent, NewBusinessMacauComponent, AutoChangeListComponent],
  exports: [CommissionDefaultScaleComponent, NewBusinessMpfComponent, IndividualTransferMpfComponent, NewBusinessMacauComponent, AutoChangeListComponent],
})
export class CommissionDefaultScaleModule {}
