<div class="app-er-change-summary-detail-pop">
  <div class="tb-close">
    <cds-icon class="icon-close" (click)="closePop()" icon="action:button_x"></cds-icon>
  </div>
  <div>
    <div class="cds-h2-light">
      {{ 'erChangeSummary.employeeDelegationSummary' | lang }}
    </div>
    <div>
      <div>
        <div class="pop-header row">
          <div class="col-sm-1">
            <cds-icon class="header-icon" [icon]="totalcon" [config]="totalIconConfig"></cds-icon>
          </div>
          <div class="header-text col-sm-7">
            <div class="cds-body1-demibold pop-company">{{ employerName }}</div>
            <div>
              <div [ngSwitch]="status">
                <div *ngSwitchCase="'Approved'" class="prodtype-lightalign">
                  <div style="display: flex; align-items: center">
                    <div class="light green"></div>
                  </div>
                  <div class="agent-status cds-body2">
                    {{ 'erChangeSummary.approved' | lang }}
                  </div>
                </div>

                <div *ngSwitchCase="'Declined'" class="prodtype-lightalign">
                  <div class="prodtype-lightacontrol">
                    <div class="light declined"></div>
                  </div>
                  <div class="agent-status cds-body2">
                    {{ 'erChangeSummary.declined' | lang }}
                  </div>
                </div>

                <div *ngSwitchCase="'Partially Approved'" class="prodtype-lightalign">
                  <div class="prodtype-lightacontrol">
                    <div class="light turquoise"></div>
                  </div>
                  <div class="agent-status cds-body2">
                    {{ 'erChangeSummary.partiallyApproved' | lang }}
                  </div>
                </div>

                <div *ngSwitchCase="'Pending DD Approval'" class="prodtype-lightalign">
                  <div class="prodtype-lightacontrol">
                    <div class="light orange"></div>
                  </div>
                  <div class="agent-status cds-body2">
                    {{ 'erChangeSummary.pendingDDApproval' | lang }}
                  </div>
                </div>

                <div *ngSwitchDefault class="prodtype-lightalign">
                  <div class="prodtype-lightacontrol">
                    <div class="light orange"></div>
                  </div>
                  <div class="agent-status cds-body2">
                    {{ 'erChangeSummary.pendingToSend' | lang }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="agent-header row">
          <div class="col-sm-1"></div>
          <div class="pop-agent col-sm-4">
            <div class="degt-agent cds-body2">
              {{ 'delegationChange.existingDelegatedAgent' | lang }}
            </div>
            <div class="degt-agent-code cds-body1">
              {{ oldAgent.agentCode }}
            </div>
            <div class="cds-body2">
              {{
                language === 'EN'
                  ? oldAgent.agentName
                    ? oldAgent.agentName
                    : oldAgent.agentNameTC
                  : oldAgent.agentNameTC
                  ? oldAgent.agentNameTC
                  : oldAgent.agentName
              }}
            </div>
          </div>
          <div class="col-sm-1">
            <cds-icon class="pop-agent-icon" [icon]="agentheadIcon"></cds-icon>
          </div>
          <div class="pop-agent col-sm-4">
            <div class="degt-agent cds-body2">
              {{ 'delegationChange.newDelegatedAgent' | lang }}
            </div>
            <div class="degt-agent-code cds-body1">
              {{ targetAgent.agentCode }}
            </div>
            <div class="cds-body2">
              {{
                language === 'EN'
                  ? targetAgent.agentName
                    ? targetAgent.agentName
                    : targetAgent.agentNameTC
                  : targetAgent.agentNameTC
                  ? targetAgent.agentNameTC
                  : targetAgent.agentName
              }}
            </div>
          </div>
        </div>

        <div class="cds-h6 n-t-title-area row v-center">
          <div class="n-t-title col-sm-2 sort-head">
            {{ 'erChangeSummary.certNo' | lang }}
          </div>
          <div class="n-t-title col-sm-3 sort-head">
            {{ 'erChangeSummary.employeeName' | lang }}
          </div>
          <div class="n-t-title col-sm-1 sort-head">
            {{ 'erChangeSummary.sex' | lang }}
          </div>
          <div class="n-t-title col-sm-1 sort-head">
            {{ 'erChangeSummary.age' | lang }}
          </div>
          <div class="n-t-title col-sm-2 sort-head">
            {{ 'erChangeSummary.programDate' | lang }}
          </div>
          <div class="n-t-title col-sm-3 sort-head">
            {{ 'erChangeSummary.reason' | lang }}
          </div>
        </div>

        <div class="n-t-content">
          <div class="n-t-content-area" *ngFor="let item of dataSource; index as i">
            <div class="cds-body1 n-t-content-short-des v-center row">
              <div class="n-short-des col-sm-2">
                <span class="body-font"> {{ item.employeeData.certificateNumber }}</span>
              </div>
              <div class="n-short-des col-sm-3">
                <span class="body-font">
                  {{
                    language === 'EN'
                      ? item.employeeData.employeeName
                        ? item.employeeData.employeeName
                        : item.employeeData.employeeNameTC
                      : item.employeeData.employeeNameTC
                      ? item.employeeData.employeeNameTC
                      : item.employeeData.employeeName
                  }}</span
                >
              </div>
              <div class="n-short-des col-sm-1">
                {{ item.employeeData.sex }}
              </div>
              <div class="n-short-des col-sm-1">
                {{ item.employeeData.age }}
              </div>
              <div class="n-short-des col-sm-2">
                {{ item.employeeData.joinTheProgramDate }}
              </div>
              <div class="table-reson col-sm-3">
                <div *ngIf="item.reason.code === 5">{{ 'delegationChange.other' | lang }}:</div>
                <div>{{ item.reason.message }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="row result-table-footer">
          <div class="col-sm-6 col-xs-12 footer-pagination">
            <cds-pagination [color]="color" [pageCount]="summaryPageCount" [page]="summaryPage" (pageChange)="pageChange($event)"> </cds-pagination>
          </div>
          <div class="col-sm-6 col-xs-12 page-search">
            <span> Page </span>
            <input class="input" [ngModel]="summaryPage" type="number" #pageNoSearchBox (keydown.enter)="searchByInputPageNo($event, pageNoSearchBox.value)" />
            <span> of {{ summaryPageCount }} </span>
          </div>
        </div>
      </div>
    </div>

    <!-- <cds-popup>
  <cds-popup-content>
    <div class="result-table">
      <div class="table-box">
        <cds-table [dataSource]="dataSourceTmp" [class.full]="fullWidth">
          <ng-container cdsColumnDef="employeeName">
            <cds-header-cell
              *cdsHeaderCellDef
              class="employer-name-column-width">
              {{ 'employee.employeeName' | lang }}
            </cds-header-cell>
            <cds-cell
              *cdsCellDef="let element"
              class="employer-name-column-width">
              {{
                this.language === 'EN'
                  ? element.memberName
                  : element.memberNameTC
                  ? element.memberNameTC
                  : element.memberName
              }}
            </cds-cell>
          </ng-container>

          <ng-container cdsColumnDef="trusteeMbrId">
            <cds-header-cell
              *cdsHeaderCellDef
              class="trustee-mbr-id-column-width">
              {{ 'employee.no' | lang }}</cds-header-cell
            >
            <cds-cell
              *cdsCellDef="let element"
              class="trustee-mbr-id-column-width">
              {{ element.trusteeMbrId }}
            </cds-cell>
          </ng-container>

          <ng-container cdsColumnDef="gender">
            <cds-header-cell *cdsHeaderCellDef class="gender-column-width">
              {{ 'employee.gender' | lang }}</cds-header-cell
            >
            <cds-cell *cdsCellDef="let element" class="gender-column-width">
              {{
                language === 'EN' ? element.gender : (element.gender | gender)
              }}
            </cds-cell>
          </ng-container>

          <ng-container cdsColumnDef="age">
            <cds-header-cell *cdsHeaderCellDef class="age-column-width">
              {{ 'employee.ageRank' | lang }}</cds-header-cell
            >
            <cds-cell *cdsCellDef="let element" class="age-column-width">
              {{ element.age | ageRange: language }}
            </cds-cell>
          </ng-container>

          <ng-container cdsColumnDef="dateJoiningTheSchm">
            <cds-header-cell
              *cdsHeaderCellDef
              class="date-joining-the-schm-column-width">
              {{ 'employee.dateJoiningTheSchm' | lang }}</cds-header-cell
            >
            <cds-cell
              *cdsCellDef="let element"
              class="date-joining-the-schm-column-width">
              {{ element.dateJoiningTheSchm | date: 'dd-MM-yyyy' }}
            </cds-cell>
          </ng-container>

          <ng-container cdsColumnDef="delegateAgentInfo">
            <cds-header-cell
              *cdsHeaderCellDef
              class="delegate-agent-info-column-width"
              >{{ 'employee.delegateAgentInfo' | lang }}</cds-header-cell
            >
            <cds-cell
              *cdsCellDef="let element"
              class="delegate-agent-info-column-width">
              <div class="agent-name">
                {{
                  element.delegateAgentInfo
                    ? element.delegateAgentInfo.agentCode
                    : ''
                }}
                &nbsp;
                {{
                  element.delegateAgentInfo
                    ? this.language === 'EN'
                      ? element.delegateAgentInfo.name
                      : element.delegateAgentInfo.nameTC
                    : ''
                }}
              </div>
            </cds-cell>
          </ng-container>

          <cds-header-row *cdsHeaderRowDef="displayedColumns"></cds-header-row>
          <cds-row *cdsRowDef="let row; columns: displayedColumns"></cds-row>
        </cds-table>
      </div>
      <div class="row result-table-footer">
        <div class="col-sm-6 col-xs-12 footer-pagination">
          <cds-pagination
            [color]="color"
            [pageCount]="summaryPageCount"
            [page]="summaryPage"
            (pageChange)="pageChange($event)">
          </cds-pagination>
        </div>
        <div class="col-sm-6 col-xs-12 page-search">
          <span> Page </span>
          <input
            class="input"
            [ngModel]="summaryPage"
            type="number"
            #pageNoSearchBox
            (keydown.enter)="
              searchByInputPageNo($event, pageNoSearchBox.value)
            " />
          <span> of {{ summaryPageCount }} </span>
        </div>
      </div>
    </div>
  </cds-popup-content>
  <cds-popup-actions>
  </cds-popup-actions>
</cds-popup> -->
    <!-- <mat-dialog-title> axx</mat-dialog-title>
<mat-dialog-content>
  <div class="result-table">
    <div class="table-box">
      <cds-table [dataSource]="dataSourceTmp" [class.full]="fullWidth">
        <ng-container cdsColumnDef="employeeName">
          <cds-header-cell *cdsHeaderCellDef class="employer-name-column-width">
            {{ 'employee.employeeName' | lang }}
          </cds-header-cell>
          <cds-cell
            *cdsCellDef="let element"
            class="employer-name-column-width">
            {{
              this.language === 'EN'
                ? element.memberName
                : element.memberNameTC
                ? element.memberNameTC
                : element.memberName
            }}
          </cds-cell>
        </ng-container>

        <ng-container cdsColumnDef="trusteeMbrId">
          <cds-header-cell
            *cdsHeaderCellDef
            class="trustee-mbr-id-column-width">
            {{ 'employee.no' | lang }}</cds-header-cell
          >
          <cds-cell
            *cdsCellDef="let element"
            class="trustee-mbr-id-column-width">
            {{ element.trusteeMbrId }}
          </cds-cell>
        </ng-container>

        <ng-container cdsColumnDef="gender">
          <cds-header-cell *cdsHeaderCellDef class="gender-column-width">
            {{ 'employee.gender' | lang }}</cds-header-cell
          >
          <cds-cell *cdsCellDef="let element" class="gender-column-width">
            {{ language === 'EN' ? element.gender : (element.gender | gender) }}
          </cds-cell>
        </ng-container>

        <ng-container cdsColumnDef="age">
          <cds-header-cell *cdsHeaderCellDef class="age-column-width">
            {{ 'employee.ageRank' | lang }}</cds-header-cell
          >
          <cds-cell *cdsCellDef="let element" class="age-column-width">
            {{ element.age | ageRange: language }}
          </cds-cell>
        </ng-container>

        <ng-container cdsColumnDef="dateJoiningTheSchm">
          <cds-header-cell
            *cdsHeaderCellDef
            class="date-joining-the-schm-column-width">
            {{ 'employee.dateJoiningTheSchm' | lang }}</cds-header-cell
          >
          <cds-cell
            *cdsCellDef="let element"
            class="date-joining-the-schm-column-width">
            {{ element.dateJoiningTheSchm | date: 'dd-MM-yyyy' }}
          </cds-cell>
        </ng-container>

        <ng-container cdsColumnDef="delegateAgentInfo">
          <cds-header-cell
            *cdsHeaderCellDef
            class="delegate-agent-info-column-width"
            >{{ 'employee.delegateAgentInfo' | lang }}</cds-header-cell
          >
          <cds-cell
            *cdsCellDef="let element"
            class="delegate-agent-info-column-width">
            <div class="agent-name">
              {{
                element.delegateAgentInfo
                  ? element.delegateAgentInfo.agentCode
                  : ''
              }}
              &nbsp;
              {{
                element.delegateAgentInfo
                  ? this.language === 'EN'
                    ? element.delegateAgentInfo.name
                    : element.delegateAgentInfo.nameTC
                  : ''
              }}
            </div>
          </cds-cell>
        </ng-container>

        <cds-header-row *cdsHeaderRowDef="displayedColumns"></cds-header-row>
        <cds-row *cdsRowDef="let row; columns: displayedColumns"></cds-row>
      </cds-table>
    </div>
    <div class="row result-table-footer">
      <div class="col-sm-6 col-xs-12 footer-pagination">
        <cds-pagination
          [color]="color"
          [pageCount]="summaryPageCount"
          [page]="summaryPage"
          (pageChange)="pageChange($event)">
        </cds-pagination>
      </div>
      <div class="col-sm-6 col-xs-12 page-search">
        <span> Page </span>
        <input
          class="input"
          [ngModel]="summaryPage"
          type="number"
          #pageNoSearchBox
          (keydown.enter)="
            searchByInputPageNo($event, pageNoSearchBox.value)
          " />
        <span> of {{ summaryPageCount }} </span>
      </div>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Cancel</button>
</mat-dialog-actions> -->
  </div>
</div>
