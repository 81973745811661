<app-cd-popup>
  <cds-popup-title><div class="cds-h2-light">Follow-Up / Reject Reason</div></cds-popup-title>
  <cds-popup-content>
    <div class="cds-body1">
      {{ data.message }}
    </div>
  </cds-popup-content>
  <cds-popup-actions>
    <cds-button style="secondary" size="sm" (click)="close()">{{ 'common.close' | lang }}</cds-button>
  </cds-popup-actions>
</app-cd-popup>
