import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { CdsButtonConfig } from '@cds/ng-core/button';
import { CdsIconConfig } from '@cds/ng-core/icon';
import { ActionIcons } from '@cds/ng-themes';

@Component({
  selector: 'app-empf-declaration-confirmation',
  templateUrl: './empf-declaration-confirmation.component.html',
  styleUrls: ['./empf-declaration-confirmation.component.scss'],
})
export class EmpfDeclarationConfirmationComponent implements OnInit {
  constructor(private dialogRef: MatDialogRef<EmpfDeclarationConfirmationComponent>) {}
  clearButton: CdsButtonConfig = {
    size: 'sm',
    style: 'primary',
    disabled: false,
  };
  iconConfig: CdsIconConfig = {
    size: 'sm',
    color: 'default',
  };
  infoIcon = ActionIcons.megaphone;
  ngOnInit(): void {}
  BackTosummary() {
    this.dialogRef.close({ agree: true });
  }
}
