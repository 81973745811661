<app-cd-page-title
  [showBack]="isshowBack"
  [backLandingLabel]="'Commission Info Maintainence Record List' | lang"
  (backLanding)="doBackMethod()"
  title="{{ 'Commission Adjustment' | lang }}">
</app-cd-page-title>

<div class="row">
  <div class="col-xs-12 clo-md-12 l-pt-56 l-mb-7">
    <div class="l-d-f">
      <div [class.tab-selected]="item.value === tabSelected.value" class="tab l-fg-1" *ngFor="let item of tab; let i = index" (click)="tabClick(item)">
        <span class="cds-body1-demibold">{{ item.label | lang }}</span>
      </div>
    </div>
  </div>
  <div class="col-xs-12 clo-md-12" *ngIf="tabSelected">
    <app-normal-compensation *ngIf="tabSelected.value === 'normalCompensation'" commissionPoolId="{{ commissionPoolId }}"> </app-normal-compensation>
    <app-ad-individual-transfer *ngIf="tabSelected.value === 'individualTransfer'" commissionPoolId="{{ commissionPoolId }}"> </app-ad-individual-transfer>
    <app-appeal-case *ngIf="tabSelected.value === 'appealCase'" commissionPoolId="{{ commissionPoolId }}"> </app-appeal-case>
  </div>
</div>
