import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DescriptionValidatorDirective } from './description-validator/description.directive';
import { DateValidatorDirective } from './date-validator/date-validator.directive';
import { MinValidatorDirective } from './min-validator/min-validator.directive';
import { MaxValidatorDirective } from './max-validator/max-validator.directive';

@NgModule({
  declarations: [DescriptionValidatorDirective, DateValidatorDirective, MinValidatorDirective, MaxValidatorDirective],
  imports: [CommonModule],
  exports: [DescriptionValidatorDirective, DateValidatorDirective, MinValidatorDirective, MaxValidatorDirective],
})
export class ValidatorsModule {}
