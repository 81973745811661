import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { CDS_POPUP_DATA } from '@cds/ng-web-components/popup';

@Component({
  selector: 'app-commonts-success',
  templateUrl: './commonts-success.component.html',
  styleUrls: ['./commonts-success.component.scss'],
})
export class CommontsSuccessComponent implements OnInit {
  constructor(private dialogRef: MatDialogRef<CommontsSuccessComponent>, @Inject(CDS_POPUP_DATA) public data: { name: string }) {}

  ngOnInit(): void {}

  gotIt() {
    this.dialogRef.close({ agree: false });
  }
}
