import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { CDS_POPUP_DATA } from '@cds/ng-web-components/popup';
import { ERInvestedFundsInformation } from '../employer';

@Component({
  selector: 'app-employer-profile-invested',
  templateUrl: './employer-profile-invested.component.html',
  styleUrls: ['./employer-profile-invested.component.scss'],
})
export class EmployerProfileInvestedComponent implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<EmployerProfileInvestedComponent>,
    @Inject(CDS_POPUP_DATA)
    public data: { investedFundsData: ERInvestedFundsInformation[] }
  ) {}

  investedFundsData?: ERInvestedFundsInformation[] = [];

  ngOnInit(): void {
    this.investedFundsData = this.data.investedFundsData;
  }

  gotIt() {
    this.dialogRef.close({ agree: false });
  }
}
