import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CdsLanguageModule } from '@cds/ng-core/lang';
import { CdsIconModule } from '@cds/ng-web-components/icon';

import { MattersNeedingAttentionComponent } from './matters-needing-attention.component';

@NgModule({
  imports: [CommonModule, CdsIconModule, CdsLanguageModule],
  declarations: [MattersNeedingAttentionComponent],
  exports: [MattersNeedingAttentionComponent],
})
export class MattersNeedingAttentionModule {}
