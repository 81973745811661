<app-cd-popup>
  <!-- <cds-popup-title><div class="cds-h2-light">Review New Event Added</div></cds-popup-title> -->
  <cds-popup-content>
    <div class="content">
      <div class="cds-h2-light l-mb-0 l-pb-1">
        {{ 'Review New Event Added' | lang }}
      </div>
      <div class="row l-plr-0 l-mt-4 l-mb-2">
        <div class="col-xs-12 col-md-11 l-d-f alert l-pl-5 l-pr-5 l-pt-4 l-pb-4">
          <cds-icon class="cds-demibold l-pr-3" size="sm" icon="action:megaphone"></cds-icon>
          <div>
            <div class="cds-demibold l-pb-1">
              {{ 'common.alert' | lang }}
            </div>
            <div>
              {{ 'calendar.approve.alert' | lang }}
            </div>
          </div>
        </div>
      </div>
      <div class="l-pt-4">
        <div class="col-xs-12 col-md-8 l-plr-0 cds-body2-demibold l-pb-3">Event Name</div>
        <div class="cds-h4 l-mb-0">Report No.</div>
      </div>

      <div class="row l-mt-5 l-plr-0">
        <div class="col-xs-12 col-md-6">
          <div class="row l-plr-0 div-border-top div-border-bottom l-d-f cds-white">
            <div class="col-xs-5 col-md-5 l-pb-3 l-pt-3 l-pl-5"><span class="cds-body1-demibold">Event Date</span></div>
            <div class="col-xs-7 col-md-7 l-pb-3 l-pt-3 l-pl-5">
              <span class="cds-body1-demibold">Report No.</span>
            </div>
          </div>
        </div>
      </div>

      <div class="row l-plr-0">
        <div class="col-xs-12 col-md-6">
          <div class="row l-plr-0 div-border-bottom">
            <div class="col-xs-5 col-md-5 l-pt-4 l-pb-4 l-pl-4 l-pr-4 l-d-f l-ai-cen">05/01/2023</div>
            <div class="col-xs-7 col-md-7 l-pt-3 l-pl-4 l-pr-8">5</div>
          </div>
          <div class="row l-plr-0 div-border-bottom">
            <div class="col-xs-5 col-md-5 l-pt-4 l-pb-4 l-pl-4 l-pr-4 l-d-f l-ai-cen">06/01/2022</div>
            <div class="col-xs-7 col-md-7 l-pt-3 l-pl-4 l-pr-8">6</div>
          </div>
          <div class="row l-plr-0 div-border-bottom">
            <div class="col-xs-5 col-md-5 l-pt-4 l-pb-4 l-pl-4 l-pr-4 l-d-f l-ai-cen">06/01/2022</div>
            <div class="col-xs-7 col-md-7 l-pt-3 l-pl-4 l-pr-8">6</div>
          </div>
          <div class="row l-plr-0 div-border-bottom">
            <div class="col-xs-5 col-md-5 l-pt-4 l-pb-4 l-pl-4 l-pr-4 l-d-f l-ai-cen">06/01/2022</div>
            <div class="col-xs-7 col-md-7 l-pt-3 l-pl-4 l-pr-8">6</div>
          </div>
          <div class="row l-plr-0 div-border-bottom">
            <div class="col-xs-5 col-md-5 l-pt-4 l-pb-4 l-pl-4 l-pr-4 l-d-f l-ai-cen">06/01/2022</div>
            <div class="col-xs-7 col-md-7 l-pt-3 l-pl-4 l-pr-8">6</div>
          </div>
          <div class="row l-plr-0 div-border-bottom">
            <div class="col-xs-5 col-md-5 l-pt-4 l-pb-4 l-pl-4 l-pr-4 l-d-f l-ai-cen">06/01/2022</div>
            <div class="col-xs-7 col-md-7 l-pt-3 l-pl-4 l-pr-8">6</div>
          </div>
          <div class="row l-plr-0 div-border-bottom">
            <div class="col-xs-5 col-md-5 l-pt-4 l-pb-4 l-pl-4 l-pr-4 l-d-f l-ai-cen">06/01/2022</div>
            <div class="col-xs-7 col-md-7 l-pt-3 l-pl-4 l-pr-8">6</div>
          </div>
        </div>
      </div>
    </div>
  </cds-popup-content>
  <cds-popup-actions>
    <cds-button [style]="'secondary'" size="sm" (click)="decline()">{{ 'common.decline' | lang }}</cds-button>
    <cds-button size="sm" (click)="onSubmit()">{{ 'common.approve' | lang }}</cds-button>
  </cds-popup-actions>
</app-cd-popup>
