<div class="cd-time-picker" appOutsideClose (outsideEvent)="onOutsideEvent()">
  <div class="selected-val-box" [ngClass]="[error ? 'error' : '']">
    <input [ngClass]="['selected-val']" [value]="time" type="text" readonly placeholder="HH:MM" (click)="onToggel()" />
    <cds-icon (click)="onToggel()" [ngClass]="['button-down', open ? 'up' : '']" icon="action:button_down"></cds-icon>
  </div>

  <div [ngClass]="['line', open ? 'line-light' : '', error ? 'error' : '']"></div>

  <div [ngClass]="['option-list', open ? 'open-list' : '']">
    <div class="list">
      <span
        (click)="selectedHour(item)"
        class="item"
        [ngClass]="{
          selected: hour === item,
          disable:
            (maxHour !== undefined && +item > maxHour) ||
            (minHour !== undefined && +item < minHour) ||
            (minHour !== undefined && minMinute === 59 && minHour === +item)
        }"
        *ngFor="let item of hourList"
        >{{ item }}</span
      >
    </div>
    <div class="list">
      <span
        (click)="selectedMin(item)"
        class="item"
        [ngClass]="{
          selected: minute === item,
          disable: isEnableMinuteDisable && ((maxMinute !== undefined && +item >= maxMinute) || (minMinute !== undefined && +item <= minMinute))
        }"
        *ngFor="let item of minList"
        >{{ item }}</span
      >
    </div>
  </div>
</div>
