/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from '../services/authentication.service';

@Injectable()
export class DefaultInterceptor implements HttpInterceptor {
  constructor(private authenticationService: AuthenticationService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (
      req.url &&
      req.url.indexOf('/login') == -1 &&
      req.url.indexOf('/refresh-token') == -1 &&
      req.url.indexOf('/auth/token') == -1 &&
      req.url.indexOf(`${environment.apiPrefix}`) !== -1
    ) {
      this.authenticationService.workerRefresh();
    }
    return next.handle(req);
  }
}
