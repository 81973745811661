import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-reset-warning',
  templateUrl: './reset-warning.component.html',
  styleUrls: ['./reset-warning.component.scss'],
})
export class ResetWarningComponent implements OnInit {
  constructor(private dialogRef: MatDialogRef<ResetWarningComponent>) {}

  ngOnInit(): void {}

  onCancel() {
    this.dialogRef.close({ agree: false });
  }

  onContinue() {
    this.dialogRef.close({ agree: true });
  }
}
