/* eslint-disable @typescript-eslint/no-explicit-any */
import { AfterViewInit, Component, ElementRef, HostListener, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { CdsButtonConfig } from '@cds/ng-core/button';
import { CdsDropdownConfig } from '@cds/ng-core/dropdown';
import { CdsLangService } from '@cds/ng-core/lang';
import { CdsPopupService } from '@cds/ng-web-components/popup';
import { DateTime } from 'luxon';
import moment from 'moment';
import { finalize } from 'rxjs';
import { PermissionAccess, PermissionItem } from 'src/app/core/models/enum/permission.enum';
import { PermissionService } from 'src/app/core/services/permission.service';
import { AlertPopupComponent } from 'src/app/shared/alert-popup/alert-popup.component';
import { ColumnConfig, PageConfig } from 'src/app/shared/data-table';
import { IPaginationCount } from 'src/app/shared/pagination/pagination.dto';
import { ToastAlertService } from 'src/app/shared/toast-alert.service';
import { dateValidator, idValidator, startAndEndDateValidator } from 'src/app/shared/validators/validators';
import { AddressTypeE } from 'src/app/views/agent-list/agent-list';
import { AutoRollover, AutoRolloverSearchRequest, AutoRolloverStatsE } from '../../shk';
import { ShkService } from '../../shk.service';

@Component({
  selector: 'app-auto-rollover-list',
  templateUrl: './auto-rollover-list.component.html',
  styleUrls: ['./auto-rollover-list.component.scss'],
})
export class AutoRolloverListComponent implements OnInit, AfterViewInit {
  searchDTO: AutoRolloverSearchRequest = {
    page: 0,
    size: 10,
  };

  pageConfig: PageConfig = {
    current: 1,
    pageSize: 10,
    totalCount: 0,
  };

  columnsConfig: ColumnConfig[] = [
    {
      key: 'createdAt',
      title: 'Create Date',
      sticky: true,
    },
    {
      key: 'erAccountNo',
      title: 'Trustee Employer Account Code',
      sticky: true,
    },
    {
      key: 'empfMemberAccountNumber',
      title: 'eMPF Member Account Number',
      sticky: true,
    },
    {
      key: 'status',
      title: 'Status',
      sticky: true,
    },
    { key: 'hkidNo', title: 'Hong Kong ID Number' },
    { key: 'passportNumber', title: 'Passport Number' },
    { key: 'firstName', title: 'First Name' },
    { key: 'lastName', title: 'Last Name' },
    { key: 'chineseFirstName', title: 'Chinese First Name' },
    { key: 'chineseLastName', title: 'Chinese Last Name' },
    { key: 'transactionDate', title: 'Transaction Date' },
    { key: 'dealingDate', title: 'Dealing Date' },
    // { key: 'transactionId', title: 'Transaction ID (PK)' },
    // { key: 'nationality1FullName', title: 'Nationality 1' },
    // { key: 'nationality2FullName', title: 'Nationality 2' },
    // { key: 'nationality3FullName', title: 'Nationality 3' },
    // { key: 'gender', title: 'Gender' },
    // { key: 'dateOfBirth', title: 'Date of Birth' },
    // { key: 'bankruptcy', title: 'Bankruptcy' },
    // { key: 'bankruptcyReceivedDate', title: 'Bankruptcy Received Date' },
    // { key: 'bankruptcyDate', title: 'Bankruptcy Date' },
    // { key: 'dischargeDate', title: 'Discharge Date' },
    // { key: 'bankruptcyNumber', title: 'Bankruptcy number' },
    // { key: 'oroContact', title: 'ORO contact' },
    // { key: 'primaryMobileNumber', title: 'Primary Mobile Number' },
    // { key: 'secondaryMobileNumber', title: 'Secondary Mobile Number' },
    // { key: 'primaryContactNumber', title: 'Primary Contact Number' },
    // { key: 'secondaryContactNumber', title: 'Secondary Contact Number' },
    // { key: 'faxNumber', title: 'Fax Number' },
    // { key: 'emailAddress', title: 'Email Address' },
    // { key: 'addressType', title: 'Address Type' },
    // { key: 'addressLine1Room', title: 'Room' },
    // { key: 'addressLine2Floor', title: 'Block' },
    // { key: 'addressLine3Block', title: 'Floor' },
    // { key: 'addressLine4Building', title: 'Building' },
    // { key: 'addressLine5Street', title: 'Street' },
    // { key: 'addressLine6District', title: 'District' },
    // { key: 'addressLine7City', title: 'City' },
    // { key: 'addressLine8CountryFullName', title: 'Country' },
    // { key: 'postalCode', title: 'Postal Code' },
    // { key: 'tinCountry1FullName', title: 'Country Identification No.' },
    // { key: 'tinNo1', title: 'Identification No.' },
    // {
    //   key: 'noTinReason1',
    //   title: 'Reason without tax identification number',
    // },
    // {
    //   key: 'furtherTinReason1',
    //   title: 'Further reason tax identification number',
    // },
    // { key: 'tinCountry2FullName', title: 'Country Identification No.' },
    // { key: 'tinNo2', title: 'Identification No.' },
    // {
    //   key: 'noTinReason2',
    //   title: 'Reason without tax identification number',
    // },
    // {
    //   key: 'furtherTinReason2',
    //   title: 'Further reason tax identification number',
    // },
    // { key: 'tinCountry3FullName', title: 'Country Identification No.' },
    // { key: 'tinNo3', title: 'Identification No.' },
    // {
    //   key: 'noTinReason3',
    //   title: 'Reason without tax identification number',
    // },
    // {
    //   key: 'furtherTinReason3',
    //   title: 'Further reason tax identification number',
    // },
    // { key: 'tinCountry4FullName', title: 'Country Identification No.' },
    // { key: 'tinNo4', title: 'Identification No.' },
    // {
    //   key: 'noTinReason4',
    //   title: 'Reason without tax identification number',
    // },
    // {
    //   key: 'furtherTinReason4',
    //   title: 'Further reason tax identification number',
    // },
    // { key: 'tinCountry5FullName', title: 'Country Identification No.' },
    // { key: 'tinNo5', title: 'Identification No.' },
    // {
    //   key: 'noTinReason5',
    //   title: 'Reason without tax identification number',
    // },
    // {
    //   key: 'furtherTinReason5',
    //   title: 'Further reason tax identification number',
    // },
    // { key: 'effectiveDate', title: 'Effective Date' },
    // { key: 'expiredDate', title: 'Expired Date' },
    { key: 'crsReceivedDate', title: 'CRS Received Date' },
    { key: 'accountOpenDate', title: 'Account Open Date' },
    { key: 'companyName', title: 'Company Name' },
    { key: 'chineseCompanyName', title: 'Chinese Company Name' },
    { key: 'memberType', title: 'Indicator for Member Type' },
  ];

  AddressTypeE = AddressTypeE;

  searchButtonConfig: CdsButtonConfig = {
    size: 'sm',
  };

  resetButtonConfig: CdsButtonConfig = {
    size: 'sm',
    style: 'secondary',
  };

  showDatePicker = true;

  isLoading = false;

  numberOfElements = 0;

  pageCount: IPaginationCount = { current: 1, count: 1 };

  dataSource: AutoRollover[] = [];

  nowTimeInstr = DateTime.now().toFormat('dd/MM/yyyy');

  maxTime = this.getNowTime();

  maxTimeInStr = DateTime.fromJSDate(this.maxTime).toFormat('dd/MM/yyyy');

  selectAll = false;

  isIndeterminate = false;

  isMobile = false;

  scrollBarWidth = '15px';

  selectStatus?: AutoRolloverStatsE;

  AutoRolloverStatsE = AutoRolloverStatsE;

  form: FormGroup = new FormGroup({});

  formReady = false;

  statusConfig: CdsDropdownConfig = {
    label: 'Status',
    placeholder: 'Select Status',
    options: [
      { label: 'New', value: 'New' },
      { label: 'Ready', value: 'Ready' },
      { label: 'Submitted', value: 'Submitted' },
      { label: 'Hold', value: 'Hold' },
      { label: 'Completed', value: 'Completed' },
      { label: 'Error', value: 'Error' },
    ],
  };

  get allAvailableData() {
    return this.dataSource.filter(item => item.status === this.selectStatus);
  }

  get selectData() {
    return this.dataSource.filter(item => item.checked);
  }

  get empfMemberAccountNumberControl() {
    return this.form?.get('empfMemberAccountNumber');
  }

  get hkidControl() {
    return this.form?.get('hkid');
  }

  get passportControl() {
    return this.form?.get('passport');
  }

  get createStartDateControl() {
    return this.form?.get('createStartDate');
  }

  get createEndDateControl() {
    return this.form?.get('createEndDate');
  }

  get createStartDateValue() {
    return this.createStartDateControl?.value;
  }

  get createEndDateValue() {
    return this.createEndDateControl?.value;
  }

  get searchButtonDisabled() {
    return !this.form?.valid || !this.form.dirty;
  }

  get resetButtonDisabled() {
    return !this.form?.dirty;
  }

  constructor(
    private service: ShkService,
    private cdsPopup: CdsPopupService,
    private langService: CdsLangService,
    private toastAlert: ToastAlertService,
    private elemRef: ElementRef,
    private permissionService: PermissionService
  ) {}

  ngOnInit(): void {
    this.permissionCheck();
    this.initForm();
    this.loadTable();
  }

  ngAfterViewInit(): void {
    this.onResize();
  }

  initForm() {
    this.form?.markAsUntouched();
    this.form?.markAsPristine();

    this.form = new FormGroup(
      {
        empfMemberAccountNumber: new FormControl('', [idValidator('common.please-input-a-valid-value')]),
        createStartDate: new FormControl('', [dateValidator('create.dateError', '', this.nowTimeInstr)]),
        createEndDate: new FormControl('', [dateValidator('create.dateError', '', this.nowTimeInstr)]),
        hkid: new FormControl('', [idValidator('common.please-input-a-valid-value')]),
        passport: new FormControl('', [idValidator('common.please-input-a-valid-value')]),
        status: new FormControl(),
      },
      { validators: startAndEndDateValidator('create.dateError', 'createStartDate', 'createEndDate') }
    );
    this.formReady = false;
    setTimeout(() => {
      this.formReady = true;
    });
  }

  getRequestParams() {
    const params: AutoRolloverSearchRequest = {
      ...this.form.value,
      page: (this.pageConfig.current as number) - 1,
      size: 10,
    };
    params.createStartDate = params.createStartDate ? this.dateTrans(params.createStartDate) : '0001-01-01';
    params.createEndDate = params.createEndDate ? this.dateTrans(params.createEndDate) : moment().format('YYYY-MM-DD');
    params.sort = 'createdAt,desc';
    return params;
  }

  dateTrans(date: string) {
    if (date) {
      return moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD');
    }

    return date;
  }

  loadTable() {
    const params: AutoRolloverSearchRequest = this.getRequestParams();
    this.isLoading = true;
    this.selectStatus = undefined;
    this.isIndeterminate = false;
    this.selectAll = false;
    this.service
      .getAutoRolloverList(params)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe(data => {
        if (data.result === 0 && data.data) {
          this.isLoading = false;
          this.dataSource = data.data.empty ? [] : data.data.content;
          this.numberOfElements = data.data.totalElements;

          this.pageConfig = {
            ...this.pageConfig,
            totalCount: data.data.totalElements,
          };
          this.selectStatusRefresh();
        } else {
          this.toastError(data.message);
        }
      });
  }

  getTime(dateStr: string) {
    return moment(dateStr, 'DD/MM/YYYY').toDate();
  }

  search() {
    if (this.searchButtonDisabled) {
      return;
    }
    this.pageConfig = {
      ...this.pageConfig,
      current: 1,
    };
    this.loadTable();
  }

  reset() {
    if (this.resetButtonDisabled) {
      return;
    }
    this.pageConfig = {
      ...this.pageConfig,
      current: 1,
    };

    this.initForm();

    this.showDatePicker = false;
    setTimeout(() => {
      this.showDatePicker = true;
      this.loadTable();
    });
  }

  reloadTable(event: any) {
    this.pageConfig = {
      ...this.pageConfig,
      current: event.current,
    };
    this.loadTable();
  }

  getNowTime() {
    return moment().toDate();
  }

  getMaxFromDate = (toDateStr?: string) => {
    if (!toDateStr) {
      return this.getNowTime();
    }
    return moment(toDateStr, 'DD/MM/YYYY').toDate();
  };

  getMinToDate = (fromDateStr = '01/01/0000') => {
    return moment(fromDateStr, 'DD/MM/YYYY').toDate();
  };

  handleChange(newValue: boolean) {
    if (this.selectAll !== newValue) {
      this.selectAll = newValue;
      this.allAvailableData.forEach(item => {
        item.checked = this.selectAll;
      });
      if (!newValue) {
        this.selectStatusRefresh();
      }
      this.updateIndeterminate();
    }
    this.onResize();
  }

  selectStatusRefresh() {
    const readyAmount = this.dataSource.filter(item => item.status === AutoRolloverStatsE.READY).length;
    const holdAmount = this.dataSource.filter(item => item.status === AutoRolloverStatsE.HOLD).length;
    if (readyAmount > 0 && holdAmount === 0) {
      this.selectStatus = AutoRolloverStatsE.READY;
    } else if (readyAmount === 0 && holdAmount > 0) {
      this.selectStatus = AutoRolloverStatsE.HOLD;
    } else {
      this.selectStatus = undefined;
    }
  }

  updateIndeterminate(): void {
    this.isIndeterminate = this.allAvailableData.length > 0 && this.allAvailableData.some(item => item.checked) && !this.selectAll;
  }

  trigger(index: number, value: boolean) {
    this.dataSource[index].checked = value;
    if (value) {
      this.selectStatus = this.dataSource[index].status;
    } else if (this.allAvailableData.length === 0 || this.allAvailableData.every(item => !item.checked)) {
      this.selectStatusRefresh();
    }
    this.selectAll = this.allAvailableData.length > 0 && this.allAvailableData.every(item => item.checked);
    this.updateIndeterminate();
    this.onResize();
  }

  isDisabled(selectStatus: AutoRolloverStatsE, status: AutoRolloverStatsE) {
    if (selectStatus === undefined && (status === AutoRolloverStatsE.READY || status === AutoRolloverStatsE.HOLD)) {
      return false;
    }
    return selectStatus !== status;
  }

  changeStatus(isHold: boolean) {
    const ids: string[] = this.selectData.map(item => item.id);
    this.isLoading = true;
    this.service
      .changeMemberStatus(isHold, ids)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe(res => {
        if (res.result === 0) {
          this.popupWithReload();
        } else {
          this.toastError(res.message);
        }
      });
  }

  addressTypeRender = (value: keyof typeof AddressTypeE) => {
    return AddressTypeE[value] || '';
  };

  private popupWithReload() {
    this.cdsPopup
      .open(AlertPopupComponent, {
        size: 'sm',
        data: {
          message: this.langService.translate('common.action.message.update.success.italic'),
          buttonName: this.langService.translate('common.gotItWithExclamation'),
        },
      })
      .afterClosed()
      .subscribe(() => {
        this.loadTable();
      });
  }

  toastError(errorMessage: string) {
    this.toastAlert.show('error', 'common.error', errorMessage, 5000);
  }

  permissionCheck() {
    this.permissionService.hasPermission(PermissionAccess.W, PermissionItem.SHK_AUTO_ROLLOVER_OPS_STAFF).then(hasPermission => {
      if (hasPermission) {
        this.columnsConfig = [
          {
            key: 'select',
            title: '',
            sticky: true,
          },
          ...this.columnsConfig,
        ];
      }
    });
  }

  onCanDelegateAmtToggle(event: MouseEvent) {
    if (event.target) {
      event.target.dispatchEvent(new Event('click'));
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    if (window.innerWidth < 1024) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }

    const contentWidth = this.elemRef.nativeElement.getElementsByClassName('auto-rollover-container')[0].offsetWidth;
    const screenWidth = document.body.clientWidth;

    this.scrollBarWidth = screenWidth - contentWidth - (this.isMobile ? 0 : 80) + 'px';
  }
}
