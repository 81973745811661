import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-tooltip-icon',
  templateUrl: './tooltip-icon.component.html',
  styleUrls: ['./tooltip-icon.component.scss'],
})
export class TooltipIconComponent implements OnInit {
  @Input() tooltipString = '';

  constructor() {}

  ngOnInit(): void {}

  onCanDelegateAmtToggle(event: MouseEvent) {
    if (event.target) {
      event.target.dispatchEvent(new Event('click'));
    }
  }
}
