<div class="row">
  <div class="col-xs-12 l-mt-7 cds-color-green anchor-parent">
    <div class="anchor-element" id="agentInfo"></div>
    <span class="cds-h4-demibold">{{ 'er.profile.agent-info' | lang }}</span>
  </div>
  <div class="col-xs-12">
    <div class="p-line"></div>
  </div>
</div>

<div class="row l-pt-5 l-pb-5">
  <div class="col-xs-12">
    <span class="cds-body1-demibold">{{ 'agent.servicing-agent-information' | lang }}</span>
  </div>
</div>

<app-cd-accordion *ngIf="!!agentInfo?.servicingAgent">
  <app-cd-accordion-panel
    title="{{ agentInfo?.servicingAgent?.agentName }}"
    subTitle="{{ 'agent.agent-code' | lang }} {{ agentInfo?.servicingAgent?.agentCode }}"
    [right]="getStatus | fn : agentInfo?.servicingAgent?.status"
    [statusColor]="getStatusColorStyle | fn : agentInfo?.servicingAgent?.status">
    <div class="col-xs-12 l-plr-0">
      <div class="row">
        <app-row-item label="agent.mpf-registration-number" data="{{ agentInfo?.servicingAgent?.registrationNumber }}" class="col-xs-12"> </app-row-item>
        <app-row-item label="agent.branch-code" data="{{ agentInfo?.servicingAgent?.branchCode }}" class="col-xs-6 col-sm-3 col-md-3 col-lg-3"> </app-row-item>
        <app-row-item label="agent.mobile" data="{{ agentInfo?.servicingAgent?.mobile }}" class="col-xs-6 col-sm-3 col-md-3 col-lg-3"> </app-row-item>
        <app-row-item label="agent.office" data="{{ agentInfo?.servicingAgent?.office }}" class="col-xs-6 col-sm-3 col-md-3 col-lg-3"> </app-row-item>
        <app-row-item label="agent.email" data="{{ agentInfo?.servicingAgent?.email }}" class="col-xs-6 col-sm-3 col-md-3 col-lg-3"> </app-row-item>
      </div>
    </div>
  </app-cd-accordion-panel>
</app-cd-accordion>

<app-no-result-found *ngIf="!agentInfo?.servicingAgent"></app-no-result-found>

<div class="row l-pt-5 l-pb-5">
  <div class="col-xs-12">
    <span class="cds-body1-demibold">{{ 'agent.commission-agent-information' | lang }}</span>
  </div>
</div>

<ng-container *ngIf="agentInfo?.commissionAgent?.length">
  <app-cd-accordion *ngFor="let item of agentInfo?.commissionAgent">
    <app-cd-accordion-panel
      title="{{ item?.agentName }}"
      subTitle="{{ 'agent.agent-code' | lang }} {{ item?.agentCode }}"
      [right]="getStatus | fn : item?.status"
      [statusColor]="getStatusColorStyle | fn : item?.status">
      <div class="col-xs-12 l-plr-0">
        <div class="row">
          <app-row-item label="agent.mpf-registration-number" data="{{ item?.registrationNumber }}" class="col-xs-12"> </app-row-item>
          <app-row-item label="agent.branch-code" data="{{ item?.branchCode }}" class="col-xs-6 col-sm-3 col-md-3 col-lg-3"> </app-row-item>
          <app-row-item label="agent.mobile" data="{{ item?.mobile }}" class="col-xs-6 col-sm-3 col-md-3 col-lg-3"> </app-row-item>
          <app-row-item label="agent.office" data="{{ item?.office }}" class="col-xs-6 col-sm-3 col-md-3 col-lg-3"> </app-row-item>
          <app-row-item label="agent.email" data="{{ item?.email }}" class="col-xs-6 col-sm-3 col-md-3 col-lg-3"> </app-row-item>

          <!-- <div class="row col-xs-12 middle-xs l-pt-4 l-pb-4 pointer">
            <div class="l-mr-2">
              <cds-icon class="button-x-filled" color="#EC6453" icon="action:button_right_filled"></cds-icon>
            </div>
            <div (click)="toDetail()">
              <span class="cds-body2-demibold">{{ 'agent.view-commission-details' | lang }}</span>
            </div>
          </div> -->
        </div>
      </div>
    </app-cd-accordion-panel>
  </app-cd-accordion>
</ng-container>

<app-no-result-found *ngIf="!agentInfo?.commissionAgent?.length"></app-no-result-found>
