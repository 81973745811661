import { RouterModule, Routes } from '@angular/router';
import { AdminLayoutComponent } from '../layout/admin-layout/admin-layout.component';
import { AnnuncementCenterComponent } from './annuncement-center/annuncement-center/annuncement-center.component';
import { NgModule } from '@angular/core';
import { AnnouncementDetailsComponent } from './announcement-details/announcement-details.component';
import { PermissionAccess, PermissionItem } from 'src/app/core/models/enum/permission.enum';

const routes: Routes = [
  {
    path: 'annuncement',
    component: AdminLayoutComponent,
    children: [
      {
        path: 'announcement-center',
        component: AnnuncementCenterComponent,
        data: {
          roles: {
            [PermissionItem.SALES_JOURNEY_SUPER_ADMIN]: PermissionAccess.R,
          },
        },
      },
      {
        path: 'announcement-details-edit/:announcementId',
        component: AnnouncementDetailsComponent,
        data: {
          roles: {
            [PermissionItem.SALES_JOURNEY_SUPER_ADMIN]: PermissionAccess.R,
          },
        },
      },
      {
        path: 'announcement-details-create',
        component: AnnouncementDetailsComponent,
        data: {
          roles: {
            [PermissionItem.SALES_JOURNEY_SUPER_ADMIN]: PermissionAccess.R,
          },
        },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AnnouncementRoutingModule {}
