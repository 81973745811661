import { Pipe, PipeTransform } from '@angular/core';
import { CdsOption } from '@cds/ng-core/configuration';
import { findOptionLabelByValue } from 'src/app/utils/utils';

/**
 * transfer value to label form CdsOptions
 */
@Pipe({ name: 'transfer2Label' })
export class Transfer2LabelPipe implements PipeTransform {
  transform(value: string | null, args: CdsOption[]) {
    if (!value || !args) return '';

    return findOptionLabelByValue(value, args);
  }
}
