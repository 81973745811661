<div [class]="'app-sidebar sidebar-shadow '" (mouseover)="sidebarHoverMouseIn()" (mouseout)="sidebarHoverMouseOut()">
  <div class="app-header__logo">
    <div class="" style="font-size: xx-large; font-family: cursive">ChataBot</div>
    <div class="header__pane ms-auto">
      <button type="button" class="hamburger close-sidebar-btn hamburger--elastic" [ngClass]="{ 'is-active': globals.toggleSidebar }" (click)="toggleSidebar()">
        <span class="hamburger-box">
          <span class="hamburger-inner"></span>
        </span>
      </button>
    </div>
  </div>
  <div class="app-sidebar-content" style="background: linear-gradient(to bottom, #fff 50%, #ccc 100%)">
    <div class="app-sidebar-scroll">
      <perfect-scrollbar [autoPropagation]="true" class="scroll-container" [scrollIndicators]="true" style="max-width: 600px; max-height: 690px">
        <div class="v-sidebar-menu vsm-default mt-2">
          <div class="vsm-list py-2" style="position: relative; height: inherit">
            <div style="height: calc(100vh - 260px); overflow-y: auto" class="mt-2">
              <div class="vsm-item pb-2 pe-1">
                <a routerLinkActive="active-item" routerLink="/chatbot-admin/canned" class="vsm-link">
                  <i class="vsm-icon pe-7s-browser"></i>
                  <span class="vsm-title">Canned</span>
                </a>
              </div>
              <!-- <div class="vsm-item pb-2 pe-1">
                <a routerLinkActive="active-item" routerLink="/sub-response" class="vsm-link">
                  <i class="vsm-icon pe-7s-browser"></i>
                  <span class="vsm-title">Sub Response</span>
                </a>
              </div> -->
              <div class="vsm-item pb-2 pe-1">
                <a routerLinkActive="active-item" routerLink="/chatbot-admin/history" class="vsm-link">
                  <i class="vsm-icon pe-7s-browser"></i>
                  <span class="vsm-title">Chat history</span>
                </a>
              </div>
              <div class="vsm-item pb-2 pe-1">
                <a routerLinkActive="active-item" routerLink="/chatbot-admin/auto-response" class="vsm-link">
                  <i class="vsm-icon pe-7s-browser"></i>
                  <span class="vsm-title">Auto Response</span>
                </a>
              </div>
              <div class="vsm-item pb-2 pe-1">
                <a routerLinkActive="active-item" routerLink="/chatbot-admin/agrement" class="vsm-link">
                  <i class="vsm-icon pe-7s-browser"></i>
                  <span class="vsm-title">Serice Agrement</span>
                </a>
              </div>
              <div class="vsm-item pb-2 pe-1">
                <a routerLinkActive="active-item" routerLink="/chatbot-admin/pii-keyword" class="vsm-link">
                  <i class="vsm-icon pe-7s-browser"></i>
                  <span class="vsm-title">PII Keyword</span>
                </a>
              </div>
              <div class="vsm-item pb-2 pe-1">
                <a routerLinkActive="active-item" routerLink="/chatbot-admin/reports" class="vsm-link">
                  <i class="vsm-icon pe-7s-browser"></i>
                  <span class="vsm-title">Report</span>
                </a>
              </div>
              <div class="vsm-item pb-2 pe-1">
                <a routerLinkActive="active-item" routerLink="/chatbot-admin/users" class="vsm-link">
                  <i class="vsm-icon pe-7s-browser"></i>
                  <span class="vsm-title">Users</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </perfect-scrollbar>
    </div>
  </div>
</div>
