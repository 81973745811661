<div class="row container" [formGroup]="form">
  <div class="col-xs-12">
    <div class="content">
      <div class="search-item">
        <div class="cds-body2 l-mr-5 label">Search By:</div>
        <cds-radio [config]="radioConfig" formControlName="searchBy"></cds-radio>
      </div>
      <div class="search-item" [class.hidden]="searchBy === 'MONTH'">
        <div class="cds-body2 l-mr-8 label">Date:</div>
        <div class="l-pb-4 l-plr-0 date-picker">
          <cds-textfield
            formControlName="date"
            placeholder="DD/MM/YYYY"
            (cdsChange)="onDateChange()"
            label=""
            [max]="getMaxFromDate | fn : maxTimeInStr"
            [cdsDatepicker]="datePicker">
          </cds-textfield>

          <cds-datepicker #datePicker="cdsDatepicker"></cds-datepicker>

          <div *ngIf="dateControl?.invalid && (dateControl?.dirty || dateControl?.touched)" class="l-pt-2">
            <cds-assistive-text *ngIf="dateControl?.errors?.['dateInvalid']" class="l-pt-2" [showIcon]="false">{{
              'create.dateError' | lang
            }}</cds-assistive-text>
          </div>
        </div>
      </div>

      <div class="search-item" [class.hidden]="searchBy === 'DAY'">
        <div class="cds-body2 l-mr-8 label">Month:</div>
        <div class="l-pb-4 l-plr-0 date-filter-container">
          <div class="date-filter" *ngIf="searchBy !== 'DAY'">
            <cds-dropdown formControlName="month" [config]="monthCfg" (cdsChange)="dateMonthChange($event)" class="l-pr-5 month-selector"></cds-dropdown>
            <cds-dropdown formControlName="year" *ngIf="yearCfg" (cdsChange)="dateYearChange($event)" [config]="yearCfg"></cds-dropdown>
          </div>
        </div>
      </div>

      <div class="no-result" *ngIf="isLoading">
        <app-loading [overlay]="true"></app-loading>
      </div>

      <div class="no-result" *ngIf="!data.length">
        <app-no-result-found></app-no-result-found>
      </div>

      <ng-container *ngIf="data.length">
        <ng-container *ngFor="let item of data; index as index">
          <div class="period-container" *ngIf="index === 0 || showMore">
            <div class="period">
              <span class="cds-h4-demibold">{{ 'account.contribution-period' | lang }}</span>
              <span class="cds-h4-demibold">{{ item.startDate | ssmsDate }}</span>
              <span class="cds-h4-demibold"> - </span>
              <span class="cds-h4-demibold">{{ item.endDate | ssmsDate }}</span>
            </div>

            <div class="l-pt-5 l-mb-5">
              <table>
                <tbody>
                  <tr>
                    <td>Default Contribution Reported Date (To MPFA)</td>
                    <td>{{ item.defaultContributionReportedDateToMPFA | ssmsDate }}</td>
                  </tr>
                  <tr>
                    <td>Message Type of Default Contribution Reporting</td>
                    <td>{{ messageTypeRender | fn : item.messageTypeofDefaultContributionReporting }}</td>
                  </tr>
                  <tr>
                    <td>Submission Type of Default Contribution Reporting</td>
                    <td>{{ item.submissionTypeofDefaultContributionReporting }}</td>
                  </tr>
                  <tr>
                    <td>Default contribution reason</td>
                    <td>{{ dcReasonRender | fn : item.defaultContributionReason }}</td>
                  </tr>
                  <tr>
                    <td>Rectified type</td>
                    <td>{{ rectifyTypeRender | fn : item.rectifiedType }}</td>
                  </tr>
                  <tr>
                    <td>Untraceable Defaulter Flag</td>
                    <td>
                      <cds-checkbox [disabled]="true" [checked]="item.untraceableDefaulterFlag === 'Y'"></cds-checkbox>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="cds-color-green l-mb-5">
              <span class="cds-h5-demibold">{{ 'account.contribution-details' | lang }}</span>
            </div>

            <div class="cds-body1">Paid Amount</div>

            <div class="l-mb-5">
              <table>
                <tbody>
                  <tr>
                    <td>Member Contribution Due Date</td>
                    <td>{{ item.memberContributionDueDate | ssmsDate }}</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="table-container">
              <table class="table paid-amount-table">
                <app-data-table
                  [frontPagination]="true"
                  [isShowPagination]="false"
                  [fixed]="false"
                  [overlay]="true"
                  [dataSource]="getPaidAmountDataSource | fn : item"
                  [columnsConfig]="paidAmountColumnsConfig">
                  <ng-template appDtHeaderCellDef="type" class="border-right" let-element> </ng-template>
                  <ng-template appDtBodyCellDef="type" class="border-right" let-element>
                    <div class="font-weight-6">
                      {{ element['type'] }}
                    </div>
                  </ng-template>

                  <ng-template appDtBodyCellDef="ermc" let-element>
                    {{ element['ermc'] | number }}
                  </ng-template>

                  <ng-template appDtBodyCellDef="eemc" let-element>
                    {{ element['eemc'] | number }}
                  </ng-template>

                  <ng-template appDtBodyCellDef="mcPaidDate" let-element>
                    {{ element['mcPaidDate'] | date : 'dd/MM/yyyy' }}
                  </ng-template>

                  <ng-template appDtBodyCellDef="ervc" let-element>
                    {{ element['ervc'] | number }}
                  </ng-template>

                  <ng-template appDtBodyCellDef="eevc" let-element>
                    {{ element['eevc'] | number }}
                  </ng-template>

                  <ng-template appDtBodyCellDef="vcPaidDate" let-element>
                    {{ element['vcPaidDate'] | date : 'dd/MM/yyyy' }}
                  </ng-template>
                </app-data-table>
              </table>
            </div>

            <div class="cds-color-green l-pt-5 l-mb-5">
              <span class="cds-h5-demibold">{{ 'account.surcharge' | lang }}</span>
            </div>

            <div class="table-container">
              <table class="table">
                <app-data-table
                  [frontPagination]="true"
                  [isShowPagination]="false"
                  [fixed]="false"
                  [overlay]="true"
                  [dataSource]="getsSrchargeDataSource | fn : item"
                  [columnsConfig]="surchargeColumnsConfig">
                  <ng-template appDtBodyCellDef="surchargeER" let-element>
                    {{ element['surchargeER'] | number }}
                  </ng-template>

                  <ng-template appDtBodyCellDef="surchargeEE" let-element>
                    {{ element['surchargeEE'] | number }}
                  </ng-template>

                  <ng-template appDtBodyCellDef="surchargePaidDate" let-element>
                    {{ element['surchargePaidDate'] | date : 'dd/MM/yyyy' }}
                  </ng-template>

                  <ng-template appDtBodyCellDef="surchargeDueDate" let-element>
                    {{ element['surchargeDueDate'] | date : 'dd/MM/yyyy' }}
                  </ng-template>
                </app-data-table>
              </table>
            </div>

            <div class="l-mt-6 cds-body1-bold">Remarks</div>

            <div class="p-line"></div>

            <app-row-item label="Remarks of Default Contribution" [data]="item.remarksOfDefaultContribution"></app-row-item>

            <app-row-item label="Mpfa Remarks" [data]="item.mpfaRemarks"></app-row-item>
          </div>
        </ng-container>
      </ng-container>

      <div class="col-xs-12 l-mt-5 more-button-area" *ngIf="data.length && !showMore">
        <cds-button [config]="moreBtnCfg" size="sm" (click)="clickShowMore()">
          <div class="button-area">
            <div class="div-icon">+</div>
            <span class="l-ml-2">View More Records</span>
          </div>
        </cds-button>
      </div>
    </div>
  </div>
</div>
