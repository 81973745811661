<div class="cd-textarea">
  <div class="l-d-f l-jc-sb cds-body2 l-mb-0">
    <span>{{ label }}</span>
    <span *ngIf="maxlength">{{ value.length }}/{{ maxlength }}</span>
  </div>
  <div class="l-mt-2" [style.height]="height">
    <textarea
      [(ngModel)]="value"
      (ngModelChange)="valueChange.emit(value)"
      class="the-textarea cds-body1 l-mb-0 l-pa-4"
      [style.fontSize]="fontSize"
      [placeholder]="placeholder"
      maxlength="{{ maxlength }}"
      maxrow></textarea>
  </div>
</div>
