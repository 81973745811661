/* eslint-disable @typescript-eslint/no-explicit-any */
import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';

import { CdsLangService } from '@cds/ng-core/lang';
import { CdsOption } from '@cds/ng-core/configuration';

@Component({
  selector: 'app-cd-search',
  templateUrl: './cd-search.component.html',
  styleUrls: ['./cd-search.component.scss'],
})
export class CdSearchComponent implements OnInit, AfterViewInit {
  @ViewChild('iconLocationRightXIcon', { static: false }) iconLocationRightXIcon?: ElementRef<HTMLDivElement>;
  @ViewChild('iconLocationRightSearchIcon', { static: false }) iconLocationRightSearchIcon?: ElementRef<HTMLDivElement>;
  @Input() isAutoComplete = true;
  @Input() dynamic = false;
  @Input() iconLocation: 'between' | 'right' = 'between';

  @Input() placeholder = '';
  @Input()
  set options(options: CdsOption[]) {
    if (this.isAutoComplete) {
      this.optionsSource = options.map(option => {
        option.label = this.langService.translate(option.label);
        return option;
      });
      this.optionsDisplayed = this._filter('');
    }
  }

  @Output() search = new EventEmitter();
  @Input() control?: FormControl = new FormControl('');

  optionsSource: CdsOption[] = [];
  optionsDisplayed: CdsOption[] = [];
  autoCompleteControl = new FormControl('');
  isFocus = false;

  constructor(private langService: CdsLangService) {}

  ngAfterViewInit(): void {
    if (this.iconLocationRightXIcon) {
      this.iconLocationRightXIcon.nativeElement.onclick = e => {
        e.stopPropagation();
      };
    }

    if (this.iconLocationRightSearchIcon) {
      this.iconLocationRightSearchIcon.nativeElement.onclick = e => {
        e.stopPropagation();
      };
    }
  }

  ngOnInit() {
    this.autoCompleteControl.valueChanges.subscribe(value => {
      let name = value;
      if (this.isAutoComplete) {
        if (this.dynamic) {
          if (typeof value === 'string') {
            this.control?.setValue(value);
          }
        } else {
          if (typeof value !== 'string') {
            if (this.control && this.control.value !== value.value) {
              this.control.setValue(value.value);
            }
            name = value?.label;
          }
          if (this.isFocus) {
            this.optionsDisplayed = value ? this._filter(name as string) : this.optionsSource.slice();
          }
        }
      } else {
        this.control?.setValue(value);
      }
    });
    if (!this.isAutoComplete || this.dynamic) {
      this.optionsSource = [];
      this.optionsDisplayed = [];
    }
    if (this.control) {
      if (this.control.value) {
        this.autoCompleteControl.setValue(this.control.value);
      }
      this.control.valueChanges.subscribe(value => {
        if (!value && value !== this.autoCompleteControl.value) {
          this.autoCompleteControl.setValue('');
        }
      });
    }
  }

  onSearch() {
    if (this.isAutoComplete) {
      return;
    }
    this.search.emit(this.autoCompleteControl.value);
  }

  onBlur() {
    this.isFocus = false;
  }

  onFocus() {
    if (this.isAutoComplete) {
      this.isFocus = true;
      let value = this.autoCompleteControl.value;
      if (typeof value !== 'string') {
        value = value.label;
      }
      this.optionsDisplayed = value ? this._filter(value as string) : this.optionsSource.slice();
    } else {
      this.optionsSource = [];
      this.optionsDisplayed = [];
    }
  }

  optionSelectedFn(ev: any) {
    this.search.emit(ev.option.value);
  }

  displayFn(option: CdsOption) {
    return option ? option.label : '';
  }

  clearInput() {
    if (this.autoCompleteControl.value === '') {
      return;
    }
    this.autoCompleteControl.patchValue('');
    if (this.dynamic) {
      this.optionsSource = [];
    }
    this.optionsDisplayed = [];
    if (this.control) {
      this.control.setValue('');
    }
    this.search.emit('');
  }

  private _filter(name: string): CdsOption[] {
    const filterValue = name.toLowerCase();
    return this.optionsSource.filter(option => option.label.toLowerCase().includes(filterValue));
  }

  iconSearch() {
    const option = this.optionsSource.find(opt => opt.label === this.autoCompleteControl.value);
    this.search.emit(option || this.autoCompleteControl.value);
  }
}
