<div class="edit-agent-details">
  <div class="head-box">
    <div class="back-box">
      <cds-icon [ngClass]="[isConfirming ? 'disabled' : '']" (click)="onBack()" [config]="backIconConfig" [icon]="backIcon"> </cds-icon>
      <div class="back-title" [ngClass]="[isConfirming ? 'disabled' : '']">
        <span (click)="onBack()" class="cds-body2-demibold">eMPF Case Details</span>
      </div>
    </div>
    <span class="head">Edit Agent Details</span>
  </div>

  <div class="content">
    <div class="transaction-box">
      <div class="transaction-top">
        <div>
          <cds-icon class="form-search" icon="form:form_search" [config]="{ color: 'default', size: 'md' }"></cds-icon>
        </div>
        <div class="l-d-f l-fd-col l-ml-5">
          <div>
            <span class="cds-body2">{{ 'case-details.eMPFTransactionReferenceNumberLabel' | lang }}</span>
          </div>
          <div class="l-mt-1">
            <div *ngIf="targetType === 'EE'">
              <span class="cds-h4-demibold">{{ caseDetails?.empfRefNo }}</span>
            </div>
            <div *ngIf="targetType === 'ER'">
              <span class="cds-h4-demibold">{{ erCaseDetails?.empfRefNo }}</span>
            </div>
          </div>
          <div class="l-d-f l-ai-cen l-mt-3">
            <div class="l-mr-4">
              <span class="cds-body2">eMPF status:</span>
            </div>
            <app-status-light
              *ngIf="targetType === 'EE'"
              [color]="
                caseSummaryService.empfAppStatus({
                  eMPFAppStatus: caseDetails?.empfStatusEnum + ''
                }).color
              "
              [text]="
                caseSummaryService.empfAppStatus({
                  eMPFAppStatus: caseDetails?.empfStatusEnum + ''
                }).text
              "></app-status-light>
            <app-status-light
              *ngIf="targetType === 'ER'"
              [color]="
                caseSummaryService.empfAppStatus({
                  eMPFAppStatus: erCaseDetails?.empfStatusEnum + ''
                }).color
              "
              [text]="
                caseSummaryService.empfAppStatus({
                  eMPFAppStatus: erCaseDetails?.empfStatusEnum + ''
                }).text
              "></app-status-light>
          </div>

          <div class="l-d-f l-ai-cen l-pt-3">
            <div>
              <span class="cds-body2">eMPF ID:</span>
            </div>
            <div class="l-ml-6">
              <div *ngIf="targetType === 'EE'">
                <span class="cds-body1-demibold">{{ caseDetails?.empfId || 'N/A' }}</span>
              </div>
              <div *ngIf="targetType === 'ER'">
                <span class="cds-body1-demibold">{{ erCaseDetails?.empfId || 'N/A' }}</span>
              </div>
            </div>
          </div>

          <div *ngIf="targetType === 'EE'" class="l-d-f l-ai-cen l-pt-3">
            <div>
              <span class="cds-body2">Employee Account Code:</span>
            </div>
            <div class="l-ml-6">
              <span class="cds-body1-demibold">{{ caseDetails?.memberAcctCd || 'N/A' }}</span>
            </div>
          </div>
          <div *ngIf="targetType === 'ER'" class="l-d-f l-ai-cen l-pt-3">
            <div>
              <span class="cds-body2">Employer Account Code:</span>
            </div>
            <div class="l-ml-6">
              <span class="cds-body1-demibold">{{ erCaseDetails?.employerAcctCd || 'N/A' }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="l-d-f l-ai-cen l-pt-4 e-submission-date">
        <div>
          <span class="cds-body2">{{ 'case-details.submissionDateTimeLabel' | lang }} </span>
        </div>
        <div *ngIf="targetType === 'EE'" class="l-ml-6">
          <span class="cds-body1-demibold">{{ caseDetails?.empfSubDt | date : 'dd/MM/yyyy' }}</span>
          <span *ngIf="caseDetails?.empfSubDt" class="cds-body1-demibold">(HKT)</span>
        </div>
        <div *ngIf="targetType === 'ER'" class="l-ml-6">
          <span class="cds-body1-demibold">{{ erCaseDetails?.empfSubDt | date : 'dd/MM/yyyy' }}</span>
          <span *ngIf="erCaseDetails?.empfSubDt" class="cds-body1-demibold">(HKT)</span>
        </div>
      </div>
    </div>

    <div class="agent-details-box">
      <div style="margin-top: 66px">
        <span class="cds-h2-light">Agent Details</span>
      </div>
      <div class="l-mt-5">
        <app-cd-inline-alert
          *ngIf="isErrPercentage100"
          (closeEvent)="clearErrPercentage100()"
          [type]="'error'"
          [title]="errPercentage100"
          [text]="errPercentage100Sub"></app-cd-inline-alert>
      </div>
      <div class="l-mt-5">
        <app-cd-inline-alert
          *ngIf="isErrEquallySplit"
          (closeEvent)="clearErrEquallySplit()"
          [type]="'error'"
          [title]="errEquallySplit"
          [text]="errEquallySplitSub"></app-cd-inline-alert>
      </div>
      <div class="l-mt-5">
        <app-cd-inline-alert
          *ngIf="isErrSmallerOne"
          (closeEvent)="clearErrSmallerOne()"
          [type]="'error'"
          [title]="'HKPC and commission % cannot be< 1.000%'"></app-cd-inline-alert>
      </div>
      <div class="agent-details-edit-box">
        <div class="header">
          <div class="agent-width header-border-base">
            <div>
              <span class="cds-body1-demibold">Agent</span>
            </div>
          </div>
          <div class="servicing-agent-width header-border-base">
            <div>
              <span class="cds-body1-demibold">Servicing Agent</span>
            </div>
          </div>
          <div class="share-hkpc-width header-border-base">
            <div>
              <span class="cds-body1-demibold">Share HKPC</span>
            </div>
          </div>
          <div class="share-commission-width header-border-base">
            <div>
              <span class="cds-body1-demibold">Share Commission</span>
            </div>
          </div>
          <div class="action-width header-border-base">
            <div *ngIf="!isConfirming">
              <span class="cds-body1-demibold">Action</span>
            </div>
          </div>
        </div>
        <div [formGroup]="form">
          <div formArrayName="agentList">
            <div *ngFor="let agent of agentList.controls; let i = index">
              <div style="position: relative" [formGroupName]="i">
                <div class="item">
                  <div
                    class="agent-width item-border-base l-d-f l-fd-col l-jc-sb"
                    [ngStyle]="{ 'padding-bottom': isShowItemWarning(agent) ? '3rem' : '1.25rem' }">
                    <div>
                      <span class="cds-body1">{{ asFormGroup(agent).get('agentCd')?.value }}</span>
                    </div>
                    <div>
                      <span class="cds-body1">{{ getAgentNameFormControl(agent) }}</span>
                    </div>
                  </div>
                  <div
                    class="servicing-agent-width item-border-base l-d-f l-fd-col l-jc-sb"
                    [ngStyle]="{ 'padding-bottom': isShowItemWarning(agent) ? '3rem' : '1.25rem' }">
                    <div>
                      <span class="cds-body2">Servicing Agent</span>
                    </div>
                    <div>
                      <cds-radio
                        [ngClass]="isConfirming ? 'disabled' : ''"
                        [ngStyle]="{ 'pointer-events': isConfirming ? 'none' : 'unset' }"
                        [disabled]="isConfirming"
                        (ngModelChange)="servicingAgentChange(i, agent)"
                        [config]="servicingAgentRadioConfig"
                        formControlName="servicingAgent"></cds-radio>
                    </div>
                  </div>
                  <div
                    class="share-hkpc-width item-border-base l-d-f l-fd-col l-jc-sb"
                    [ngStyle]="{ 'padding-bottom': isShowItemWarning(agent) ? '3rem' : '1.25rem' }">
                    <div>
                      <span class="cds-body2">Share HKPC</span>
                    </div>
                    <div class="input-percentage">
                      <app-cd-input
                        *ngIf="!isConfirming"
                        [type]="'number'"
                        [group]="asFormGroup(agent)"
                        controlName="shareHKPC"
                        (blurEvent)="shareHKPCBlur(agent)"></app-cd-input>
                      <span style="width: 97px" *ngIf="isConfirming">{{ asFormGroup(agent).controls['shareHKPC'].value }}</span>
                      <div class="l-ml-3">
                        <span class="cds-body1">%</span>
                      </div>
                    </div>
                  </div>
                  <div
                    class="share-commission-width item-border-base l-d-f l-fd-col l-jc-sb"
                    [ngStyle]="{ 'padding-bottom': isShowItemWarning(agent) ? '3rem' : '1.25rem' }">
                    <div>
                      <span class="cds-body2">Share Commission</span>
                    </div>
                    <div class="input-percentage">
                      <app-cd-input
                        *ngIf="!isConfirming"
                        [type]="'number'"
                        [group]="asFormGroup(agent)"
                        controlName="shareCommission"
                        (blurEvent)="shareCommissionBlur(agent)"></app-cd-input>
                      <span style="width: 97px" *ngIf="isConfirming">{{ asFormGroup(agent).controls['shareCommission'].value }}</span>
                      <div class="l-ml-3">
                        <span class="cds-body1">%</span>
                      </div>
                    </div>
                  </div>
                  <div class="action-width item-border-base" [ngStyle]="{ 'padding-bottom': isShowItemWarning(agent) ? '3rem' : '1.25rem' }">
                    <div *ngIf="!isConfirming" class="cursor-pointer">
                      <span (click)="delete(i)" class="cds-body2-demibold" style="color: var(--cds-text-cta)"> Delete </span>
                    </div>
                  </div>
                </div>
                <div class="item-warning" *ngIf="isShowItemWarning(agent)">
                  <cds-assistive-text type="warning"
                    >Please note that the Share HKPC percentage is not the same as the Share Commission percentage.</cds-assistive-text
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="l-d-f l-ai-cen l-pt-4 l-pl-3">
        <div (click)="addNewSplitAgent()" class="cursor-pointer">
          <cds-icon
            style="--cds-icon-color: {{ isDisableAddNewSplitAgent ? 'var(--cds-text-placeholder)' : 'var(--cds-bg-cta-primary)' }}"
            icon="action:button_plus_filled"></cds-icon>
        </div>
        <div (click)="addNewSplitAgent()" class="cursor-pointer" style="margin-left: 10px">
          <span class="cds-body2-demibold" [ngStyle]="{ color: isDisableAddNewSplitAgent ? 'var(--cds-text-placeholder)' : '' }">Add new split agent</span>
        </div>
      </div>
    </div>

    <div *ngIf="!isConfirming" class="l-mt-7">
      <cds-button (click)="reset()" [style]="'secondary'" size="sm">Reset</cds-button>
      <cds-button [disabled]="isDisableSave" (click)="save()" class="l-ml-4" size="sm">Save</cds-button>
    </div>

    <div *ngIf="isConfirming" class="l-mt-7">
      <cds-button (click)="backToEdit()" [style]="'secondary'" size="sm">Back to edit</cds-button>
      <cds-button [disabled]="isDisableSave" (click)="confirm()" class="l-ml-4" size="sm">Confirm</cds-button>
    </div>
  </div>
</div>
