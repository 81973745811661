/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BasicResponse } from 'src/app/core/models/response/response';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class IndividualTransferService {
  constructor(private http: HttpClient) {}

  getTransaction(id: string): Observable<BasicResponse<any>> {
    const url = `${environment.apiPrefix}${environment.commissionServiceExt}/commission-pool-individual-transfer/transaction-records/${id}`;
    return this.http.get<BasicResponse<any>>(url);
  }

  getAgent(id: string): Observable<BasicResponse<any>> {
    const url = `${environment.apiPrefix}${environment.commissionServiceExt}/commission-pool-individual-transfer/individual-agent-records/${id}`;
    return this.http.get<BasicResponse<any>>(url);
  }
}
