<div class="l-d-f l-ai-cen" [class.pointer]="!disabled" (click)="click($event)">
  <div>
    <cds-icon *ngIf="!disabled" class="default" [icon]="icon" [config]="iconCfg"></cds-icon>
    <cds-icon *ngIf="disabled" class="default" [icon]="icon" [config]="disabledIconCfg"></cds-icon>
  </div>
  <div class="l-pl-1">
    <span class="{{ buttonTextClass }}" [class.font-disable]="disabled" [class.display-none]="hiddenButtonText">
      {{ buttonText | lang }}
    </span>
  </div>
</div>
