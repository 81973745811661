<div *ngIf="step === 1" class="upload-document-popup-box">
  <div [formGroup]="formGroup" class="content">
    <span class="upload-document-text">Upload document</span>
    <div class="l-mt-6">
      <span class="file-requirements-text">File requirements</span>
    </div>
    <div style="margin-top: 20px">
      <div class="l-d-f l-fd-col">
        <div>
          <span class="requirements-info">• A maximum of 1 file with file size limit of 8MB can be uploaded</span>
        </div>
        <div class="l-mt-1" *ngIf="data.currentSelectedConfig.configName === '01_Campaigns_Campaign List'">
          <span class="requirements-info">• File name: prestige_club_member_master_YYYYMMDD</span>
        </div>
        <div class="l-mt-1" *ngIf="data.currentSelectedConfig.configName === 'ABM List'">
          <span class="requirements-info">• File name: branch_portfolio_YYYYMMDD</span>
        </div>
      </div>
    </div>
    <div formArrayName="fileUploadInfoList" class="l-d-f l-fw-w file-upload-info-list">
      <div *ngFor="let uploadinfo of fileUploadInfoList.controls; let i = index" class="l-mr-5 l-mt-5">
        <cds-file-upload
          [formControl]="getFileControl(uploadinfo)"
          (change)="fileChange($event, uploadinfo)"
          [errorMessage]="getErrorMessage(uploadinfo)"
          acceptedFiles="text/csv"></cds-file-upload>
        <div class="error-message-area l-mt-2" *ngIf="getErrorMessage(uploadinfo).length > 0">
          <cds-icon class="l-mr-2 warning" icon="action:warning" style="--cds-icon-color: #c14a36"></cds-icon>
          <div class="error-message-text">{{ getErrorMessage(uploadinfo) }}</div>
        </div>
      </div>
    </div>
  </div>
  <div class="line"></div>
  <div class="botton-list">
    <cds-button (click)="cancel()" [style]="'secondary'" size="sm">{{ 'common.cancel' | lang }}</cds-button>
    <cds-button (click)="upload()" [disabled]="formGroup.invalid" size="sm">{{ 'common.upload' | lang }}</cds-button>
  </div>
  <cds-icon class="close-icon" icon="action:cross" (click)="cancel()"></cds-icon>
</div>

<div *ngIf="step === 2" class="upload-abm-list-success-popup-box">
  <div class="content">
    <div>
      <span class="updated">Your changes has been updated</span>
      <span class="updated successfully"> successfully.</span>
    </div>
    <div class="successfully-info l-mt-2">
      <span>A total of</span>
      <span class="num"> {{ addedCaseNum }} cases are added</span>
      <span> and</span>
      <span class="num"> {{ removedCaseNum }} cases are removed</span>
      <span> successfully</span>
    </div>
    <ng-container *ngIf="skipData.length > 0">
      <div class="l-mt-7">
        <app-matters-needing-attention [color]="'yellow'" [title]="'Failed to update below cases due to error occured'">
          <div>
            <span class="attention-info">Please review and upload a new list if needed</span>
          </div>
        </app-matters-needing-attention>
      </div>
      <div class="l-mt-5">
        <div>
          <span class="failed-cases-text">Failed Cases:</span>
        </div>
        <div class="l-mt-4 table-wrapper">
          <cds-table class="row l-plr-0" [dataSource]="skipData" [class.full]="false">
            <cds-row *cdsRowDef="let row; index as i; columns: displayedColumns"></cds-row>
            <cds-header-row *cdsHeaderRowDef="displayedColumns"></cds-header-row>
            <ng-container cdsColumnDef="no">
              <cds-header-cell *cdsHeaderCellDef class="no-width"></cds-header-cell>
              <cds-cell *cdsCellDef="let element; index as i" class="no-width">
                <div>
                  <span class="cds-body1">{{ i + 1 }}</span>
                </div>
              </cds-cell>
            </ng-container>
            <ng-container cdsColumnDef="action">
              <cds-header-cell *cdsHeaderCellDef class="action-width">Action</cds-header-cell>
              <cds-cell *cdsCellDef="let element" class="action-width">
                <div>
                  <span class="cds-body1">{{ element.action }}</span>
                </div>
              </cds-cell>
            </ng-container>
            <ng-container cdsColumnDef="branchCode">
              <cds-header-cell *cdsHeaderCellDef class="branch-code-width">Branch Code</cds-header-cell>
              <cds-cell *cdsCellDef="let element" class="branch-code-width">
                <div>
                  <span class="cds-body1">{{ element.branchCode }}</span>
                </div>
              </cds-cell>
            </ng-container>
            <ng-container cdsColumnDef="districtName">
              <cds-header-cell *cdsHeaderCellDef class="district-name-width">District Name</cds-header-cell>
              <cds-cell *cdsCellDef="let element" class="district-name-width">
                <div>
                  <span class="cds-body1">{{ element.districtName }}</span>
                </div>
              </cds-cell>
            </ng-container>
            <ng-container cdsColumnDef="abm">
              <cds-header-cell *cdsHeaderCellDef class="abm-width">ABM</cds-header-cell>
              <cds-cell *cdsCellDef="let element" class="abm-width">
                <div>
                  <span class="cds-body1">{{ element.abm }}</span>
                </div>
              </cds-cell>
            </ng-container>
          </cds-table>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="skipData.length === 0">
      <div class="l-mt-6">
        <cds-button (click)="dialogRef.close({ agree: false })" size="sm">Got it !</cds-button>
      </div>
    </ng-container>
  </div>

  <ng-container *ngIf="skipData.length > 0">
    <div class="line"></div>
    <div class="botton-list">
      <cds-button (click)="dialogRef.close({ agree: false })" size="sm">Got it !</cds-button>
    </div>
  </ng-container>
</div>
