import { Component, Input, SimpleChanges, OnChanges } from '@angular/core';
import { CdsPopupService } from '@cds/ng-web-components/popup';
import {
  BillingClass,
  ContributionAmount,
  ContributionAmountType,
  ContributionDetails,
  ContributionDetailsCategory,
  ContributionDetailsCategoryE,
  ContributionSortByE,
} from '../../employer';
import { OutstandingAmountOfPaymentPopupComponent } from '../outstanding-amount-of-payment-popup/outstanding-amount-of-payment-popup.component';

@Component({
  selector: 'app-amount-table',
  templateUrl: './amount-table.component.html',
  styleUrls: ['./amount-table.component.scss'],
})
export class AmountTableComponent implements OnChanges {
  @Input() contributionTableDataSource: ContributionAmount[] = [];
  @Input() contributionDetails?: ContributionDetails;
  @Input() billingClass?: BillingClass;
  @Input() employerId?: string;
  @Input() selectedContributionSortBy?: ContributionSortByE;
  @Input() byBillReference = false;

  contributionTablDisplayedColumns: string[] = [];

  showTable = false;

  constructor(private cdsPopup: CdsPopupService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['contributionTableDataSource']?.currentValue !== changes['contributionTableDataSource']?.previousValue) {
      this.genDisaplayedColums();
    }
    // if (changes['runDate'].currentValue) {
    //   this.genDisaplayedColums(changes['runDate'].currentValue);
    // }
  }

  genDisaplayedColums() {
    this.showTable = false;
    this.contributionTablDisplayedColumns = [];
    const firstLineData = this.contributionTableDataSource[0];
    Object.keys(firstLineData).forEach(key => {
      if (
        key === 'category' ||
        key === 'surcharge' ||
        firstLineData[key as keyof ContributionAmount] === 0 ||
        !!firstLineData[key as keyof ContributionAmount]
      ) {
        this.contributionTablDisplayedColumns.push(key);
      }
    });
    setTimeout(() => {
      this.showTable = true;
    });
  }

  getCategoryTitle(category: ContributionDetailsCategory) {
    if (!category) {
      return '';
    }
    return ContributionDetailsCategoryE[category];
  }

  canClick(amount: number | string) {
    if (amount === '0' || !amount) {
      return false;
    }
    return true;
  }

  popupDetail(category: ContributionDetailsCategory, type: ContributionAmountType, amount: number) {
    if (amount === undefined || amount === null) {
      return;
    }
    this.cdsPopup.open(OutstandingAmountOfPaymentPopupComponent, {
      size: 'lg',
      data: {
        category,
        type,
        amount,
        contributionDetails: this.contributionDetails,
        billingClass: this.billingClass,
        employerId: this.employerId,
        selectedContributionSortBy: this.selectedContributionSortBy,
        byBillReference: this.byBillReference,
      },
    });
  }
}
