/* eslint-disable @typescript-eslint/no-explicit-any */
export enum AgentChannel {
  DBS = 'DBS',
  SCB = 'SCB',
  BROKER = 'BROKER',
  IA_BROKER = 'IA_BROKER',
  CORPORATE = 'CORPORATE',
  AGENCY = 'AGENCY',
  INTERNAL_TRANSFER = 'INTERNAL_TRANSFER',
  NULL = 'NULL',
}

export const CommissionAgentChannelMap = new Map([
  [AgentChannel.DBS, 'DBS'],
  [AgentChannel.SCB, 'SCB'],
  [AgentChannel.BROKER, 'BROKER'],
  [AgentChannel.IA_BROKER, 'BROKER'],
  [AgentChannel.CORPORATE, 'CORPORATE'],
  [AgentChannel.AGENCY, 'AGENCY'],
  [AgentChannel.INTERNAL_TRANSFER, 'INTERNAL_TRANSFER'],
  [AgentChannel.NULL, 'NULL'],
]);

export const ApeJournalChannels: any[] = [
  { label: 'AGENCY', value: AgentChannel.AGENCY },
  { label: 'IA BROKER', value: AgentChannel.IA_BROKER },
  { label: 'BROKER', value: AgentChannel.BROKER },
  { label: 'SCB', value: AgentChannel.SCB },
  { label: 'DBS', value: AgentChannel.DBS },
  { label: 'CORPORATE', value: AgentChannel.CORPORATE },
];
