/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormControl, ValidationErrors, Validators } from '@angular/forms';
import { CdsOption } from '@cds/ng-core/configuration';
import { CdsDropdownConfig } from '@cds/ng-core/dropdown';
import { CdsPopupService } from '@cds/ng-web-components/popup';
import { CdsToastService } from '@cds/ng-web-components/toast';
import { debounceTime, distinctUntilChanged, finalize, Subject } from 'rxjs';
import { AclUserType } from 'src/app/core/models/enum/acl-user-type.enum';
import { ResponseResult } from 'src/app/core/models/response/response-result';
import { ReportingRoleServiceService } from 'src/app/core/services/reporting-role-service.service';
import { AlertPopupComponent } from 'src/app/shared/alert-popup/alert-popup.component';
import { ConfirmPopupComponent } from 'src/app/shared/confirm-popup/confirm-popup.component';
import { ColumnConfig, PageConfig } from 'src/app/shared/data-table';

const unSelectAclUserValidator = () => {
  return (control: AbstractControl): ValidationErrors | null => {
    const input = control.value || '';
    if (typeof input !== 'string' && input.length > 2) {
      return null;
    }
    const isValid = input?.id;
    return isValid ? null : { unselectAclUser: true };
  };
};

@Component({
  selector: 'app-role-assign-users',
  templateUrl: './role-assign-users.component.html',
  styleUrls: ['./role-assign-users.component.scss'],
})
export class RoleAssignUsersComponent implements OnInit {
  @Input() roleId = '';

  aclUserLoading = false;
  isLoading = false;

  aclNames: CdsOption[] = [];
  aclName = new FormControl(
    { value: '', disabled: this.aclUserLoading },
    {
      validators: [Validators.required, Validators.minLength(2), unSelectAclUserValidator()],
    }
  );

  aclUserType: AclUserType = AclUserType.INTERNAL_STAFF;
  aclUsetTypeDropdownConfig: CdsDropdownConfig = {
    options: [],
  };

  searchValue = '';
  searchParam$ = new Subject<any>();
  lastSearchkey = '';
  loading = false;
  pageConfig: PageConfig = {
    current: 1,
    pageSize: 10,
    totalCount: 0,
  };
  dataDisplayed: any[] = [];
  columnsConfig: Array<ColumnConfig> = [
    { key: 'userName', title: 'user.table.username' },
    { key: 'userEmail', title: 'user.table.email' },
    { key: 'delete', title: 'table.action' },
  ];

  constructor(private cdsPopup: CdsPopupService, private toast: CdsToastService, private reportingRoleServiceService: ReportingRoleServiceService) {
    for (const item in AclUserType) {
      this.aclUsetTypeDropdownConfig.options.push({
        label: item,
        value: item,
      });
    }
    this.searchParam$.pipe(debounceTime(500), distinctUntilChanged()).subscribe(key => {
      this.pageConfig = {
        current: 1,
        pageSize: 10,
        totalCount: 0,
      };
      this.loadTable(key);
    });
  }

  ngOnInit() {
    this.aclName.valueChanges.pipe(debounceTime(500), distinctUntilChanged()).subscribe(selectedValue => {
      if (!selectedValue?.id) {
        this.aclUserSearch(selectedValue);
      }
    });
    // this.loadTable();
  }

  aclUserFormReset(): void {
    if (this.aclName.touched || this.aclName.dirty) {
      this.aclName.reset();
      this.aclName.markAsPristine();
      this.aclName.markAsUntouched();
    }
  }

  private aclUserSearch(key: string) {
    if (!!key && key.trim().length > 1 && !this.aclUserLoading) {
      this.aclNames = [];
      this.aclUserLoading = true;
      const params = {
        searchString: key,
        userType: this.aclUserType,
        page: 0,
        size: 100,
      };
      this.reportingRoleServiceService
        .getAclUsers(params)
        .pipe(
          finalize(() => {
            this.aclUserLoading = false;
          })
        )
        .subscribe({
          next: res => {
            if (res.result === 0 && res.data && res.data.content?.length > 0) {
              this.aclNames = res.data.content.map((e: any) => {
                let label = e.userName;
                if (e.agentKey) {
                  label += '('.concat(e.agentKey, ')');
                }
                return {
                  label: label,
                  details: e.email,
                  value: { id: e.id },
                };
              });
            }
          },
          error: err => {
            console.error(err);
          },
        });
    }
  }

  addRoleUserEnable() {
    return !!this.roleId && !this.isLoading && !!this.aclName?.value?.id;
  }

  addUserToRole() {
    if (!this.addRoleUserEnable()) {
      return;
    }
    this.aclUserLoading = true;
    this.reportingRoleServiceService
      .addUserToRole({
        userId: this.aclName.value.id,
        userType: this.aclUserType,
        roleId: this.roleId,
      })
      .pipe(
        finalize(() => {
          this.aclUserLoading = false;
        })
      )
      .subscribe(res => {
        if (res.result === ResponseResult.SUCCESS) {
          this.popupWithReload();
        } else {
          this.toast.error(`Operation failed : ${res.message}`);
        }
      });
  }

  private popupWithReload() {
    this.cdsPopup
      .open(AlertPopupComponent, {
        size: 'sm',
        data: {
          message: 'common.action.success',
        },
      })
      .afterClosed()
      .subscribe(() => {
        this.pageConfig = {
          current: 1,
          pageSize: 10,
          totalCount: 0,
        };
        const searchKey = this.searchValue ? this.searchValue.trim() : '';
        this.loadTable(searchKey);
      });
  }

  search() {
    const searchKey = this.searchValue ? this.searchValue.trim() : '';
    this.searchParam$.next(searchKey);
  }

  loadTable(searchKey: string) {
    this.isLoading = true;
    this.reportingRoleServiceService
      .getIdentityByRoleId({
        page: (this.pageConfig.current as number) - 1,
        size: 10,
        roleId: this.roleId,
        userName: searchKey,
      })
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe(res => {
        this.lastSearchkey = searchKey;
        const tempData = res.data.content || [];
        this.pageConfig = {
          ...this.pageConfig,
          totalCount: res.data.totalElements,
        };
        this.dataDisplayed = tempData.map((item: any) => {
          return {
            id: item.id,
            roleId: item.roleId,
            userId: item.userId,
            userEmail: item.identity.userEmail,
            userName: item.identity.userName,
          };
        });
      });
  }

  pageChange(event: any) {
    this.pageConfig = {
      ...this.pageConfig,
      current: event.current,
    };
    this.loadTable(this.lastSearchkey);
  }

  deleteUserConfirm(id: string) {
    this.cdsPopup
      .open(ConfirmPopupComponent, {
        size: 'sm',
        data: { title: 'role.confrim.delete' },
      })
      .afterClosed()
      .subscribe(result => {
        if (result?.agree) {
          this.delete(id);
        } else {
          this.isLoading = false;
        }
      });
  }

  delete(id: string) {
    this.reportingRoleServiceService.deleteUser(id).subscribe(res => {
      if (res.result === ResponseResult.SUCCESS) {
        this.popupWithReload();
      } else {
        this.toast.error(`Operation failed : ${res.message}`);
      }
    });
  }
}
