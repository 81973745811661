<div class="l-d-f row" *ngIf="pageCount && pageCount.current > 0 && pageCount.count > 0">
  <div class="col-xs-12 l-jc-fs l-pa-0 col-sm-12 center-xs center-sm start-md" [ngClass]="isShowInput ? 'col-md-9' : 'col-md-12'">
    <div class="cds-pagination">
      <section class="cds-pagination-container">
        <ul>
          <li class="arrow-container" aria-label="Previous" [class.disabled]="pageCount.current === 1" (click)="selectPage(pageCount.current - 1)">
            <cds-icon icon="action:button_left"></cds-icon>
          </li>
          <ng-container>
            <li
              *ngIf="pageCount.current > 2"
              class="page"
              [class.selected]="false"
              [class.right-border]="true"
              (click)="selectPage(1); $event.preventDefault()">
              1
            </li>
            <li *ngIf="pageCount.current > 4" class="ellipsis">...</li>
            <li
              *ngIf="pageCount.current > 3"
              class="page"
              [class.selected]="false"
              [class.right-border]="true"
              (click)="selectPage(pageCount.current - 2); $event.preventDefault()">
              {{ pageCount.current - 2 }}
            </li>
            <li
              *ngIf="pageCount.current > 1"
              class="page"
              [class.selected]="false"
              [class.right-border]="true"
              (click)="selectPage(pageCount.current - 1); $event.preventDefault()">
              {{ pageCount.current - 1 }}
            </li>
            <li class="page" [class.selected]="true" [class.right-border]="true" (click)="selectPage(pageCount.current); $event.preventDefault()">
              {{ pageCount.current }}
            </li>
            <li
              *ngIf="pageCount.current < pageCount.count"
              class="page"
              [class.selected]="false"
              [class.right-border]="true"
              (click)="selectPage(pageCount.current + 1); $event.preventDefault()">
              {{ pageCount.current + 1 }}
            </li>
            <li
              *ngIf="pageCount.current + 1 < pageCount.count"
              class="page"
              [class.selected]="false"
              [class.right-border]="true"
              (click)="selectPage(pageCount.current + 2); $event.preventDefault()">
              {{ pageCount.current + 2 }}
            </li>

            <li *ngIf="pageCount.current + 3 < pageCount.count" class="ellipsis">...</li>
            <li
              *ngIf="pageCount.current + 2 < pageCount.count"
              class="page"
              [class.selected]="false"
              [class.right-border]="true"
              (click)="selectPage(pageCount.count); $event.preventDefault()">
              {{ pageCount.count }}
            </li>
          </ng-container>
          <li class="arrow-container" aria-label="Next" (click)="selectPage(pageCount.current + 1)" [class.disabled]="pageCount.current === pageCount.count">
            <cds-icon icon="action:button_right"></cds-icon>
          </li>
        </ul>
        <div class="ink-bar" #inkBar></div>
      </section>
    </div>
  </div>
  <ng-container *ngIf="isShowInput">
    <div class="app-pagination-input col-xs-12 col-md-3 l-ai-cen l-d-f l-jc-fe l-pa-0 col-sm-12 center-xs center-sm end-md">
      <span class="l-pt-4">Page</span>
      <span class="l-pl-3 l-pr-3"
        ><cds-textfield
          [disabled]="isLoading && !textfieldAlwaysAvailable"
          [config]="{
            type: 'number'
          }"
          value="{{ pageCount.current }}"
          #searchPage
          (keydown.enter)="gotoPage($event); $event.preventDefault()"></cds-textfield
      ></span>
      <span class="l-pt-4 flex">
        <span class="l-mr-1">{{ useOf ? 'of' : '/' }}</span
        >{{ pageCount.count }}</span
      >
    </div>
  </ng-container>
</div>
