import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-cd-form-assistive',
  templateUrl: './cd-form-assistive.component.html',
  styleUrls: ['./cd-form-assistive.component.scss'],
})
export class CdFormAssistiveComponent implements OnInit {
  @Input() form?: FormGroup;
  @Input() msg?: string;
  @Input() key?: string;
  constructor() {}

  ngOnInit(): void {}
}
