import { Component, Input, OnInit } from '@angular/core';
import { CdsIconConfig } from '@cds/ng-core/icon';

@Component({
  selector: 'app-inline-alert',
  templateUrl: './inline-alert.component.html',
  styleUrls: ['./inline-alert.component.scss'],
})
export class InlineAlertComponent implements OnInit {
  @Input() value = '';

  iconConfig: CdsIconConfig = {
    color: '#fff',
  };

  constructor() {}

  ngOnInit(): void {}
}
