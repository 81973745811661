import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BenefitPaymentRoutingModule } from './benefit-payment-routing.module';
import { CdsDatepickerModule } from '@cds/ng-web-components/datepicker';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CdsLanguageModule } from '@cds/ng-core/lang';
import { CdsSortModule } from '@cds/ng-data-table/sort';
import { CdsTableModule } from '@cds/ng-data-table/table';
import { CdsFileUploadModule } from '@cds/ng-experimental/file-upload';
import { CdsAssistiveTextModule } from '@cds/ng-web-components/assistive-text';
import { CdsButtonModule } from '@cds/ng-web-components/button';
import { CdsCheckboxModule } from '@cds/ng-web-components/checkbox';
import { CdsDropdownModule } from '@cds/ng-web-components/dropdown';
import { CdsFormFieldModule } from '@cds/ng-web-components/form-field';
import { CdsIconModule } from '@cds/ng-web-components/icon';
import { CdsPaginationModule } from '@cds/ng-web-components/pagination';
import { CdsPopupModule } from '@cds/ng-web-components/popup';
import { CdsProgressModule } from '@cds/ng-web-components/progress';
import { CdsRadioModule } from '@cds/ng-web-components/radio';
import { CdsTabsModule } from '@cds/ng-web-components/tabs';
import { CdsTextfieldModule } from '@cds/ng-web-components/textfield';
import { CdsTooltipModule } from '@cds/ng-web-components/tooltip';
import { SharedModule } from 'src/app/shared/shared.module';
import { BankTransferComponent } from './shkp/bank-transfer/bank-transfer.component';
import { ChequeIssuanceComponent } from './shkp/cheque-issuance/cheque-issuance.component';
import { ChequePrintingBackPopupComponent } from './shkp/cheque-printing-back-popup/cheque-printing-back-popup.component';
import { ChequePrintingListComponent } from './shkp/cheque-printing-list/cheque-printing-list.component';
import { ChequePrintingSaveSuccessComponent } from './shkp/cheque-printing-save-success/cheque-printing-save-success.component';
import { ScbStatementUploadSuccessComponent } from './shkp/scb-statement/scb-statement-upload-success/scb-statement-upload-success.component';
import { ScbStatementUploadComponent } from './shkp/scb-statement/scb-statement-upload/scb-statement-upload.component';
import { ScbStatementComponent } from './shkp/scb-statement/scb-statement/scb-statement.component';
import { ChequePrintingListPTWComponent } from './ptw/cheque-printing-list/cheque-printing-list-ptw.component';
import { BankTransferPTWComponent } from './ptw/bank-transfer/bank-transfer-ptw.component';
import { ChequeIssuancePTWComponent } from './ptw/cheque-issuance/cheque-issuance-ptw.component';
import { ChequePrintingBackPopupPTWComponent } from './ptw/cheque-printing-back-popup/cheque-printing-back-popup-ptw.component';
import { ChequePrintingSaveSuccessPTWComponent } from './ptw/cheque-printing-save-success/cheque-printing-save-success-ptw.component';

@NgModule({
  declarations: [
    ChequePrintingListComponent,
    ChequePrintingSaveSuccessComponent,
    ChequePrintingBackPopupComponent,
    ChequeIssuanceComponent,
    BankTransferComponent,
    ScbStatementComponent,
    ScbStatementUploadComponent,
    ScbStatementUploadSuccessComponent,
    ChequePrintingListPTWComponent,
    ChequePrintingSaveSuccessPTWComponent,
    ChequePrintingBackPopupPTWComponent,
    ChequeIssuancePTWComponent,
    BankTransferPTWComponent,
  ],
  imports: [
    CommonModule,
    BenefitPaymentRoutingModule,
    CdsDatepickerModule,
    FormsModule,
    ReactiveFormsModule,
    CdsLanguageModule,
    CdsTextfieldModule,
    CdsDropdownModule,
    CdsButtonModule,
    CdsIconModule,
    CdsTableModule,
    CdsCheckboxModule,
    CdsPaginationModule,
    CdsPopupModule,
    CdsProgressModule,
    SharedModule,
    CdsFormFieldModule,
    CdsAssistiveTextModule,
    CdsRadioModule,
    CdsTooltipModule,
    CdsTabsModule,
    CdsFileUploadModule,
    CdsSortModule,
  ],
})
export class BenefitPaymentModule {}
