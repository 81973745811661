import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NotifyService {
  private hideNavigationLang = new Subject<boolean>();
  screenLoadingSubject = new Subject<boolean>();

  getHideNavigationLang() {
    return this.hideNavigationLang;
  }

  notifyHideNavigationLang() {
    this.hideNavigationLang.next(true);
  }

  notifyDisplayNavigationLang() {
    this.hideNavigationLang.next(false);
  }
}
