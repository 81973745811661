<div class="l-pa-7 l-pt-5">
  <!-- 1th content -->
  <div class="flex-row">
    <!-- start green title  -->
    <div class="cds-h4-demibold common-title margin-top-common green-title">
      {{ 'ee.opt.Standing-Instruction' | lang }}
    </div>
    <cds-checkbox class="margin-left-common" [checked]="true" [disabled]="true"> </cds-checkbox>
  </div>
  <div class="base-line grey-line"></div>
  <!-- 1th -->
  <div class="flex-row row no-padding margin-top-common">
    <div class="flex-column content-item col-md-4-8 no-padding">
      <div class="cds-body2 no-margin title-color no-padding">Fund Code</div>
      <div class="cds-body1 no-margin">12/04/1990</div>
    </div>
    <div class="flex-column content-item col-md-4-8 no-padding">
      <div class="cds-body2 no-margin title-color no-padding">
        {{ 'ee.opt.Standing-instruction-effective-date' | lang }}
      </div>
      <div class="cds-body1 no-margin">12/04/1990</div>
    </div>
    <div class="flex-column content-item col-md-4-8 no-padding">
      <div class="cds-body2 no-margin title-color no-padding">
        {{ 'ee.opt.Standing-instruction-frequency' | lang }}
      </div>
      <div class="cds-body1 no-margin">12/04/1990</div>
    </div>
    <div class="flex-column content-item col-md-4-8 no-padding">
      <div class="cds-body2 no-margin title-color no-padding">
        {{ 'ee.opt.Amount-or-unit' | lang }}
      </div>
      <div class="cds-body1 no-margin">12/04/1990</div>
    </div>
  </div>
  <!--end 1th content -->

  <!-- 2th content -->
  <div class="flex-row">
    <!-- start green title  -->
    <div class="cds-h4-demibold common-title margin-top-common green-title">
      {{ 'ee.opt.Termination' | lang }}
    </div>
    <cds-checkbox class="margin-left-common" [checked]="true" [disabled]="true"> </cds-checkbox>
  </div>
  <div class="base-line grey-line"></div>
  <!-- 2th -->
  <div class="flex-row row no-padding margin-top-common">
    <div class="flex-column content-item col-md-4-8 no-padding">
      <div class="cds-body2 no-margin title-color no-padding">
        {{ 'ee.profile.CEE-Last-date-of-employment' | lang }}
      </div>
      <div class="cds-body1 no-margin">12/04/1990</div>
    </div>
    <div class="flex-column content-item col-md-4-8 no-padding">
      <div class="cds-body2 no-margin title-color no-padding">
        {{ 'ee.profile.Cert-Term-Process-Date' | lang }}
      </div>
      <div class="cds-body1 no-margin">12/04/1990</div>
    </div>
    <div class="flex-column content-item col-md-4-8 no-padding">
      <div class="cds-body2 no-margin title-color no-padding">
        {{ 'ee.opt.LSP_SP_MC' | lang }}
      </div>
      <div class="cds-body1 no-margin">12/04/1990</div>
    </div>
    <div class="flex-column content-item col-md-4-8 no-padding">
      <div class="cds-body2 no-margin title-color no-padding">
        {{ 'ee.opt.LSP_SP_VC' | lang }}
      </div>
      <div class="cds-body1 no-margin">12/04/1990</div>
    </div>
    <div class="flex-column content-item col-md-4-8 no-padding">
      <div class="cds-body2 no-margin title-color no-padding">Amount payable to EE</div>
      <div class="cds-body1 no-margin">12/04/1990</div>
    </div>
  </div>

  <div class="flex-row row no-padding margin-top-common">
    <div class="flex-column content-item col-md-4-8 no-padding">
      <div class="cds-body2 no-margin title-color no-padding">LSP/SP Type Code</div>
      <div class="cds-body1 no-margin">12/04/1990</div>
    </div>
    <div class="flex-column content-item col-md-4-8 no-padding">
      <div class="cds-body2 no-margin title-color no-padding">LSP/SP - Payee</div>
      <div class="cds-body1 no-margin">12/04/1990</div>
    </div>
    <div class="flex-column content-item col-md-4-8 no-padding">
      <div class="cds-body2 no-margin title-color no-padding">Related Group No</div>
      <div class="cds-body1 no-margin">12/04/1990</div>
    </div>
    <div class="flex-column content-item col-md-4-8 no-padding">
      <div class="cds-body2 no-margin title-color no-padding">CGI Term Date</div>
      <div class="cds-body1 no-margin">12/04/1990</div>
    </div>
    <div class="flex-column content-item col-md-4-8 no-padding">
      <div class="cds-body2 no-margin title-color no-padding">Notice of Termination (NOT) Notification Date</div>
      <div class="cds-body1 no-margin">12/04/1990</div>
    </div>
  </div>

  <div class="flex-row row no-padding margin-top-common">
    <div class="flex-column content-item col-md-4-8 no-padding">
      <div class="cds-body2 no-margin title-color no-padding">Unvest ER</div>
      <div class="cds-body1 no-margin">12/04/1990</div>
    </div>
    <div class="flex-column content-item col-md-4-8 no-padding">
      <div class="cds-body2 no-margin title-color no-padding">Termination Date</div>
      <div class="cds-body1 no-margin">12/04/1990</div>
    </div>
    <div class="flex-column content-item col-md-4-8 no-padding">
      <div class="cds-body2 no-margin title-color no-padding">Termination Type</div>
      <div class="cds-body1 no-margin">12/04/1990</div>
    </div>
    <div class="flex-column content-item col-md-4-8 no-padding">
      <div class="cds-body2 no-margin title-color no-padding">Notice of Termination (NOT) Notification Date</div>
      <div class="cds-body1 no-margin">12/04/1990</div>
    </div>
    <div class="flex-column content-item col-md-4-8 no-padding">
      <div class="cds-body2 no-margin title-color no-padding">CDR Bypass Sig</div>
      <div class="cds-body1 no-margin">12/04/1990</div>
    </div>
  </div>

  <div class="flex-row row no-padding margin-top-common">
    <div class="flex-column content-item col-md-4-8 no-padding">
      <div class="cds-body2 no-margin title-color no-padding">Quotation Signal</div>
      <div class="cds-body1 no-margin">12/04/1990</div>
    </div>
    <div class="flex-column content-item col-md-4-8 no-padding">
      <div class="cds-body2 no-margin title-color no-padding">Overpaid Cont Sig</div>
      <div class="cds-body1 no-margin">12/04/1990</div>
    </div>
    <div class="flex-column content-item col-md-4-8 no-padding">
      <div class="cds-body2 no-margin title-color no-padding">Delete Signal</div>
      <div class="cds-body1 no-margin">12/04/1990</div>
    </div>
    <div class="flex-column content-item col-md-4-8 no-padding">
      <div class="cds-body2 no-margin title-color no-padding">Freeze Signal</div>
      <div class="cds-body1 no-margin">12/04/1990</div>
    </div>
    <div class="flex-column content-item col-md-4-8 no-padding">
      <div class="cds-body2 no-margin title-color no-padding">Special Flag</div>
      <div class="cds-body1 no-margin">12/04/1990</div>
    </div>
  </div>

  <div class="flex-row row no-padding margin-top-common">
    <div class="flex-column content-item col-md-4-8 no-padding">
      <div class="cds-body2 no-margin title-color no-padding">Irrecoverable Cont Signal</div>
      <div class="cds-body1 no-margin">12/04/1990</div>
    </div>
    <div class="flex-column content-item col-md-4-8 no-padding">
      <div class="cds-body2 no-margin title-color no-padding">LS To GS Redemption</div>
      <div class="cds-body1 no-margin">12/04/1990</div>
    </div>
    <div class="flex-column content-item col-md-4-8 no-padding">
      <div class="cds-body2 no-margin title-color no-padding">ER Vol Spec Vest %</div>
      <div class="cds-body1 no-margin">12/04/1990</div>
    </div>
  </div>

  <!--end 2th content -->

  <!-- 3th content -->
  <div class="flex-row">
    <!-- start green title  -->
    <div class="cds-h4-demibold common-title margin-top-common green-title">Transfer-out</div>
  </div>
  <div class="base-line grey-line"></div>
  <!-- 2th -->
  <div class="flex-row row no-padding margin-top-common">
    <div class="flex-column content-item col-md-4-8 no-padding">
      <div class="cds-body2 no-margin title-color no-padding">Tx 405/406 Status</div>
      <div class="cds-body1 no-margin">12/04/1990</div>
    </div>
    <div class="flex-column content-item col-md-4-8 no-padding">
      <div class="cds-body2 no-margin title-color no-padding">Status Eff Date</div>
      <div class="cds-body1 no-margin">12/04/1990</div>
    </div>
    <div class="flex-column content-item col-md-4-8 no-padding">
      <div class="cds-body2 no-margin title-color no-padding">ePASS ID</div>
      <div class="cds-body1 no-margin">12/04/1990</div>
    </div>
    <div class="flex-column content-item col-md-4-8 no-padding">
      <div class="cds-body2 no-margin title-color no-padding">PM/W Complete</div>
      <div class="cds-body1 no-margin">12/04/1990</div>
    </div>
    <div class="flex-column content-item col-md-4-8 no-padding">
      <div class="cds-body2 no-margin title-color no-padding">Transfer Type</div>
      <div class="cds-body1 no-margin">12/04/1990</div>
    </div>
  </div>

  <div class="flex-row row no-padding margin-top-common">
    <div class="flex-column content-item col-md-4-8 no-padding">
      <div class="cds-body2 no-margin title-color no-padding">Scheme Code</div>
      <div class="cds-body1 no-margin">12/04/1990</div>
    </div>
    <div class="flex-column content-item col-md-4-8 no-padding">
      <div class="cds-body2 no-margin title-color no-padding">Current year ECA Transfer-in</div>
      <div class="cds-body1 no-margin">12/04/1990</div>
    </div>
    <div class="flex-column content-item col-md-4-8 no-padding">
      <div class="cds-body2 no-margin title-color no-padding">ECA Transfer-in Date</div>
      <div class="cds-body1 no-margin">12/04/1990</div>
    </div>
    <div class="flex-column content-item col-md-4-8 no-padding">
      <div class="cds-body2 no-margin title-color no-padding">Transfer In Account</div>
      <div class="cds-body1 no-margin">12/04/1990</div>
    </div>
    <div class="flex-column content-item col-md-4-8 no-padding">
      <div class="cds-body2 no-margin title-color no-padding">Transfer In Group/Cert</div>
      <div class="cds-body1 no-margin">12/04/1990</div>
    </div>
  </div>

  <div class="flex-row row no-padding margin-top-common">
    <div class="flex-column content-item col-md-4-8 no-padding">
      <div class="cds-body2 no-margin title-color no-padding">VC Signal</div>
      <div class="cds-body1 no-margin">12/04/1990</div>
    </div>
  </div>

  <!--end 3th content -->

  <!-- 4th content -->
  <div class="flex-row">
    <!-- start green title  -->
    <div class="cds-h4-demibold common-title margin-top-common green-title">Withdrawal</div>
  </div>
  <div class="base-line grey-line"></div>
  <div class="cds-body1-demibold common-title margin-top-common cds-body1-demibold">More Details</div>
  <div class="base-line grey-line"></div>
  <!-- 2th -->
  <div class="flex-row row no-padding margin-top-common">
    <div class="flex-column content-item col-md-4-8 no-padding">
      <div class="cds-body2 no-margin title-color no-padding">Death Evid Sig</div>
      <div class="cds-body1 no-margin">12/04/1990</div>
    </div>
    <div class="flex-column content-item col-md-4-8 no-padding">
      <div class="cds-body2 no-margin title-color no-padding">Retirement Opt</div>
      <div class="cds-body1 no-margin">12/04/1990</div>
    </div>
    <div class="flex-column content-item col-md-4-8 no-padding">
      <div class="cds-body2 no-margin title-color no-padding">Withdrawal Pending</div>
      <div class="cds-body1 no-margin">12/04/1990</div>
    </div>
    <div class="flex-column content-item col-md-4-8 no-padding">
      <div class="cds-body2 no-margin title-color no-padding">Withdrawal Reason</div>
      <div class="cds-body1 no-margin">12/04/1990</div>
    </div>
    <div class="flex-column content-item col-md-4-8 no-padding">
      <div class="cds-body2 no-margin title-color no-padding">Autopay Bank No</div>
      <div class="cds-body1 no-margin">12/04/1990</div>
    </div>
  </div>

  <div class="flex-row row no-padding margin-top-common">
    <div class="flex-column content-item col-md-4-8 no-padding">
      <div class="cds-body2 no-margin title-color no-padding">Branch No</div>
      <div class="cds-body1 no-margin">12/04/1990</div>
    </div>
    <div class="flex-column content-item col-md-4-8 no-padding">
      <div class="cds-body2 no-margin title-color no-padding">Account No</div>
      <div class="cds-body1 no-margin">12/04/1990</div>
    </div>
    <div class="flex-column content-item col-md-4-8 no-padding">
      <div class="cds-body2 no-margin title-color no-padding">Withdrawal Type</div>
      <div class="cds-body1 no-margin">12/04/1990</div>
    </div>
    <div class="flex-column content-item col-md-4-8 no-padding">
      <div class="cds-body2 no-margin title-color no-padding">Disbursement Type</div>
      <div class="cds-body1 no-margin">12/04/1990</div>
    </div>
    <div class="flex-column content-item col-md-4-8 no-padding">
      <div class="cds-body2 no-margin title-color no-padding">Date of Death/Departure</div>
      <div class="cds-body1 no-margin">12/04/1990</div>
    </div>
  </div>

  <div class="flex-row row no-padding margin-top-common">
    <div class="flex-column content-item col-md-4-8 no-padding">
      <div class="cds-body2 no-margin title-color no-padding">Personal Rep Name</div>
      <div class="cds-body1 no-margin">12/04/1990</div>
    </div>
    <div class="flex-column content-item col-md-4-8 no-padding">
      <div class="cds-body2 no-margin title-color no-padding">Personal Rep Address</div>
      <div class="cds-body1 no-margin">12/04/1990</div>
    </div>
  </div>

  <!--end 4th content -->
</div>
