/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { PermissionAccess, PermissionItem } from 'src/app/core/models/enum';
import { checkPermissionAccess } from 'src/app/utils/permission-utils';
export const enum PermissionCheckedStatus {
  TOTAL,
  PARTIALLY,
  NONE,
}
@Component({
  selector: 'app-reporting-role-form',
  templateUrl: './reporting-role-form.component.html',
  styleUrls: ['./reporting-role-form.component.scss'],
})
export class ReportingRoleFormComponent implements OnInit {
  @Input() roleNameIn = '';
  @Input() rolePermissionGroup: any;
  @Input() roleHasPermission: any;
  @Output() save = new EventEmitter<any>();
  @Output() delete = new EventEmitter<any>();

  checkedPermission: any = {};
  permissionAccess = PermissionAccess;
  dispalyData: any[] = [];
  roleName = new FormControl('', [Validators.required, Validators.minLength(3)]);
  accessColumns: any[] = ['expand', 'permissionName', 'read', 'noAccess'];
  constructor() {}

  ngOnInit() {
    if (this.rolePermissionGroup) {
      for (const [key, value] of this.rolePermissionGroup) {
        this.dispalyData.push({
          permissionItem: key,
          expand: true,
        });
        value.forEach((p: any) => {
          this.dispalyData.push({
            parentId: key,
            permissionItem: p,
          });
        });
      }
    }
    if (this.roleNameIn) {
      this.roleName.setValue(this.roleNameIn);
      this.roleName.markAsDirty();
    }
    if (this.roleHasPermission) {
      this.checkedPermission = { ...this.roleHasPermission };
    }
  }

  get saveDisable() {
    return !this.roleName.dirty || this.roleName.invalid;
  }

  onDelete() {
    this.delete.emit();
  }

  onSave() {
    this.save.emit({
      roleName: this.roleName.value.trim(),
      permissions: this.checkedPermission,
    });
  }

  hiddenRow(row: any) {
    if (row.expand !== undefined) {
      return false;
    }
    const groupRow = this.dispalyData.find((item: any) => item.permissionItem === row.parentId);
    return !groupRow.expand;
  }

  getGroupStatus(espectAccess: PermissionAccess | null, groupId: string) {
    let totalPermission = 0;
    let selectPermission = 0;
    this.dispalyData.forEach(item => {
      if (item.parentId && item.parentId === groupId) {
        totalPermission++;
        const checkpermission = this.checkedPermission[item.permissionItem];
        if ((!espectAccess && !checkpermission) || (espectAccess && checkpermission && checkPermissionAccess(espectAccess, checkpermission))) {
          selectPermission++;
        }
      }
    });
    if (totalPermission === selectPermission) {
      return PermissionCheckedStatus.TOTAL;
    }
    if (selectPermission > 0) {
      return PermissionCheckedStatus.PARTIALLY;
    }
    return PermissionCheckedStatus.NONE;
  }

  isGroupCheck(espectAccess: PermissionAccess | null, groupId: string) {
    return this.getGroupStatus(espectAccess, groupId) === PermissionCheckedStatus.TOTAL;
  }

  isCheck(iExpectAccess: PermissionAccess | null, iPermissionItem: PermissionItem): boolean {
    const checkpermission = this.checkedPermission[iPermissionItem];
    return (!iExpectAccess && !checkpermission) || (iExpectAccess && checkpermission && checkPermissionAccess(iExpectAccess, checkpermission));
  }

  isPartially(iExpectAccess: PermissionAccess | null, iGroupId: string): boolean {
    return this.getGroupStatus(iExpectAccess, iGroupId) === PermissionCheckedStatus.PARTIALLY;
  }

  private setPermissionAccess(iExpectAccess: PermissionAccess | null, isChecked: boolean): PermissionAccess | null {
    if (isChecked) {
      return iExpectAccess;
    }
    if (iExpectAccess == PermissionAccess.E) {
      return PermissionAccess.W;
    }
    if (iExpectAccess == PermissionAccess.W) {
      return PermissionAccess.R;
    }
    return null;
  }

  changeGroup(isChecked: boolean, iExpectAccess: PermissionAccess | null, row: any) {
    const groupAccess = this.setPermissionAccess(iExpectAccess, isChecked);
    this.dispalyData.forEach(p => {
      if (p.parentId && p.parentId === row.permissionItem) {
        if (!groupAccess) {
          delete this.checkedPermission[p.permissionItem];
        } else {
          const toPermission = this.checkedPermission[p.permissionItem];
          if (!toPermission || !isChecked || !checkPermissionAccess(groupAccess, toPermission)) {
            this.checkedPermission[p.permissionItem] = groupAccess;
          }
        }
      }
    });
  }

  changePermission(isChecked: boolean, iExpectAccess: PermissionAccess | null, row: any) {
    if (row.permissionItem) {
      const selectAccess = this.setPermissionAccess(iExpectAccess, isChecked);
      if (!selectAccess) {
        delete this.checkedPermission[row.permissionItem];
      } else {
        this.checkedPermission[row.permissionItem] = selectAccess;
      }
    }
  }
}
