import { Component, OnInit } from '@angular/core';
import { CdsDropdownConfig } from '@cds/ng-core/dropdown';
import { CdsButtonConfig } from '@cds/ng-core/button';
import { FormControl, Validators, FormGroup, AbstractControl } from '@angular/forms';
import { Router } from '@angular/router';
import { DateTime } from 'luxon';
import { Summary } from '../summary';
import { SummaryService } from '../summary.service';
import { finalize } from 'rxjs';
import { CdsOption } from '@cds/ng-core/configuration';
import { NotificationDataService } from '../notificationlist/notification-data.service';
import { AdminHeaderConfig } from '../../layout/admin-layout/admin-header/admin-header-config';
import { LayoutHeaderService } from 'src/app/core/services/layout-header.service';

@Component({
  selector: 'app-summary-create',
  templateUrl: './summary-create.component.html',
  styleUrls: ['./summary-create.component.scss'],
})
export class SummaryCreateComponent implements OnInit {
  form: FormGroup = new FormGroup({
    subjectEn: new FormControl('', [Validators.maxLength(100), this.englishValidator]),
    subjectZh: new FormControl('', [Validators.maxLength(50)]),
    bodyEn: new FormControl('', [Validators.maxLength(300), this.englishValidator]),
    bodyZh: new FormControl('', [Validators.maxLength(150)]),
    urlLimitEn: new FormControl('', [Validators.maxLength(1000), this.urlValidator]),
    urlLimitZh: new FormControl('', [Validators.maxLength(1000), this.urlValidator]),
    dateLimit: new FormControl('', [this.dateValidator]),
    pushCampaignId: new FormControl('', [Validators.maxLength(20)]),
  });

  urlValidator(option: AbstractControl): object {
    if (option.value == undefined) {
      return {};
    }
    const re = new RegExp('[\u4E00-\u9FA5]+', 'g');
    if (re.test(option.value)) {
      return { msg: 'Invalid deep link URL.' };
    } else {
      return {};
    }
  }

  englishValidator(option: AbstractControl): object {
    if (option.value == undefined) {
      return {};
    }
    const re = new RegExp('[\u4E00-\u9FA5]+', 'g');
    if (re.test(option.value)) {
      return { msg: 'Please input English' };
    } else {
      return {};
    }
  }

  chineseValidator(option: AbstractControl): object {
    if (option.value == undefined) {
      return {};
    }
    const re = new RegExp('[A-Za-z]+', 'g');
    if (re.test(option.value)) {
      return { msg: 'Please input chinese' };
    } else {
      return {};
    }
  }

  dateValidator(option: AbstractControl): object {
    if (option.value == undefined) {
      return {};
    }
    const selectTime = DateTime.fromFormat(option.value, 'd/M/yyyy');
    // when 22:00, can still chose today
    const currentTime = DateTime.now().plus({ minutes: -1 });
    if (!selectTime.isValid) {
      return { msg: 'date error' };
    }
    if (selectTime < currentTime.startOf('day') || (selectTime.equals(currentTime.startOf('day')) && currentTime.hour >= 21)) {
      return { msg: 'date error' };
    }
    return {};
  }

  summaryDTO: Summary = {};

  resetConfig: CdsButtonConfig = {
    size: 'sm',
    style: 'secondary',
    disabled: true,
  };
  confirmConfig: CdsButtonConfig = {
    size: 'sm',
    disabled: true,
  };
  targetBroadcastTimeConfig: CdsDropdownConfig = {
    label: 'Target Broadcast Time',
    placeholder: 'HH:MM',
    options: [],
  };
  categoryConfig: CdsDropdownConfig = {
    label: 'Category',
    placeholder: 'Select Category',
    options: [
      // { label: 'UPDATES', value: 'HCM_001' },
      { label: 'OFFER_AND_PROMOTIONS', value: 'HCM_002' },
      // { label: 'FUND_PRICE_ALERT', value: 'HCM_003' },
      { label: 'MARKET_INSIGHTS', value: 'HCM_004' },
    ],
  };
  priorityConfig: CdsDropdownConfig = {
    label: 'Priority',
    placeholder: 'Select Priority',
    options: [
      { label: 'Low', value: 'Low' },
      { label: 'Medium', value: 'Medium' },
      { label: 'High', value: 'High' },
    ],
  };

  audienceListOptions: CdsOption[] = [];

  audienceListConfig: CdsDropdownConfig = {
    label: 'Audience List',
    placeholder: 'Select Audience List',
    options: [],
  };

  isLoading = false;

  headerButton: AdminHeaderConfig = {
    title: 'summary.create.title',
    backButton: {
      text: 'notification.list',
      url: '/mobile-subscription/list',
    },
  };

  showDatePicker = true;

  date = DateTime.now();

  jsDate = new Date();

  constructor(
    private router: Router,
    private service: SummaryService,
    private notificationService: NotificationDataService,
    private layoutHeaderService: LayoutHeaderService
  ) {}

  ngOnInit(): void {
    this.layoutHeaderService.setHeaderButton(this.headerButton);
    this.summaryDTO = JSON.parse(localStorage['summaryDTO'] || '{}');
    this.setDateAndTime();
    this.getAudienceOptions();
    this.validating();
  }

  getAudienceOptions() {
    this.isLoading = true;
    this.service
      .getAudiences()
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe({
        next: res => {
          if (res.result === 0 && res.data && res.data.length) {
            this.audienceListOptions = this.optiosnGen(res.data);
            if (this.summaryDTO.category && this.summaryDTO.audienceListName) {
              this.categoryChange(this.summaryDTO.category, false);
            }
          }
        },
        error: err => {
          console.error('Call audience API error: ', err);
        },
      });
  }

  optiosnGen(data: string[]): CdsOption[] {
    return data.map(value => {
      return {
        label: this.notificationService.getAudienceLabel(value),
        value,
      };
    });
  }

  setDateAndTime() {
    let date = this.date;
    // 21:00 can select today
    if (date > date.startOf('day').plus({ hours: 21, minutes: 1 })) {
      date = date.plus({ days: 1 });
    }

    this.jsDate = date.toJSDate();
    if (this.summaryDTO.targetBroadcastDate) {
      setTimeout(() => {
        this.setTimeConfig(this.summaryDTO.targetBroadcastDate as string);
        this.validating();
      });
    }
  }

  setTimeConfig(value: string) {
    let options: CdsOption[] = [];
    if (this.form.get('dateLimit')?.valid) {
      // 3:00 can select 4:00, so limit is 59 minutes
      const currentTime = DateTime.now().plus({ minutes: -1 });
      const selectTime = DateTime.fromFormat(value, 'dd/MM/yyyy')?.startOf('day');

      let startHour = 0;

      if (selectTime.equals(this.date.startOf('day'))) {
        startHour = currentTime.hour + 2;
      }

      options = this.getTimeOptions(startHour);
    }

    this.targetBroadcastTimeConfig = {
      ...this.targetBroadcastTimeConfig,
      options,
    };
  }

  getTimeOptions(currentHour = 8) {
    const options: CdsOption[] = [];
    const min = currentHour > 8 ? currentHour : 8;
    const max = 22;
    for (let index = min; index <= max; index++) {
      const time = `${index < 10 ? '0' : ''}${index}:00`;
      options.push({
        label: time,
        value: time,
      });
    }
    return options;
  }

  reset() {
    this.summaryDTO = {};
    this.resetConfig = {
      size: 'sm',
      disabled: true,
    };
    this.confirmConfig = {
      size: 'sm',
      disabled: true,
    };
    this.audienceListConfig = {
      label: 'Audience List',
      placeholder: 'Select Audience List',
      options: [],
    };
    this.targetBroadcastTimeConfig = {
      ...this.targetBroadcastTimeConfig,
      options: [],
    };
    localStorage.removeItem('summaryDTO');

    // reload datepicker dom to clear the selected date
    this.showDatePicker = false;
    setTimeout(() => {
      this.showDatePicker = true;
    });
  }
  submit() {
    if (this.confirmConfig.disabled) {
      return;
    }
    console.log(this.summaryDTO);
    localStorage.setItem('summaryDTO', JSON.stringify(this.summaryDTO));
    this.router.navigate(['/mobile-subscription/confirm']);
  }

  validating() {
    const timeAvailable = this.selectTimeAvailable();

    if (this.summaryDTO.targetBroadcastDate == '') {
      this.summaryDTO.targetBroadcastDate = undefined;
    }
    if (
      !!this.summaryDTO.targetBroadcastDate ||
      !!this.summaryDTO.targetBroadcastTime ||
      !!this.summaryDTO.category ||
      !!this.summaryDTO.priority ||
      !!this.summaryDTO.pushCampaignId ||
      !!this.summaryDTO.subjectEn ||
      !!this.summaryDTO.messageBodyEn ||
      !!this.summaryDTO.subjectCn ||
      !!this.summaryDTO.messageBodyCn ||
      !!this.summaryDTO.deepLinkUrl ||
      !!this.summaryDTO.audienceListName
    ) {
      this.resetConfig = {
        size: 'sm',
        disabled: false,
        style: 'secondary',
      };
    }
    if (
      !!this.summaryDTO.targetBroadcastDate &&
      !!this.summaryDTO.targetBroadcastTime &&
      !!this.summaryDTO.category &&
      !!this.summaryDTO.priority &&
      !!this.summaryDTO.audienceListName &&
      !!this.summaryDTO.pushCampaignId &&
      !!this.summaryDTO.subjectEn &&
      !!this.summaryDTO.messageBodyEn &&
      !!this.summaryDTO.subjectCn &&
      !!this.summaryDTO.messageBodyCn &&
      !!this.summaryDTO.deepLinkUrl &&
      this.form.valid &&
      timeAvailable
    ) {
      this.confirmConfig = {
        size: 'sm',
        disabled: false,
      };
    } else {
      this.confirmConfig = {
        size: 'sm',
        disabled: true,
      };
    }
    if (!this.form.valid) {
      this.confirmConfig = {
        size: 'sm',
        disabled: true,
      };
    }
  }

  categoryChange(value: string, clearSelect = true) {
    if (!value) {
      return;
    }
    if (clearSelect) {
      this.summaryDTO.audienceListName = '';
    }
    if (value === 'HCM_002') {
      this.audienceListConfig = {
        ...this.audienceListConfig,
        options: this.audienceListOptions,
      };
    } else if (value === 'HCM_004') {
      this.audienceListConfig = {
        ...this.audienceListConfig,
        options: [
          {
            label: this.notificationService.mobileSubscriptionList,
            value: this.notificationService.mobileSubscriptionList,
          },
        ],
      };
    }
  }

  campaignIdChange(value: string) {
    if (value === undefined) {
      return;
    }
    this.form.get('pushCampaignId')?.setValue(value.trim());
  }

  selectTimeAvailable() {
    return this.targetBroadcastTimeConfig.options.some(item => item.value === this.summaryDTO.targetBroadcastTime);
  }
}
