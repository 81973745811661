<cds-popup class="popup no-close-icon">
  <cds-popup-title>
    <div class="col-xs-12">{{ data.title }}</div>
    <div class="close-icon" (click)="close()">
      <cds-icon icon="action:cross"></cds-icon>
    </div>
  </cds-popup-title>

  <cds-popup-content>
    <form [formGroup]="form" class="relative">
      <app-loading *ngIf="isLoading" [overlayInPopup]="true"></app-loading>
      <div class="row l-plr-0">
        <div class="col-xs-12">
          <cds-textfield
            #agentTextfield
            [config]="agentTextfieldConfig"
            (change)="changeFormImmediately('agent', $event)"
            formControlName="agent"></cds-textfield>
          <div *ngIf="agent?.invalid && agent?.dirty" class="l-pt-1">
            <cds-assistive-text *ngIf="agent?.errors?.['required']" class="l-pt-2" [showIcon]="false">Missing Agent Code.</cds-assistive-text>
            <cds-assistive-text
              *ngIf="agent?.errors?.['agentCodeListInvalid']"
              class="l-pt-2"
              [showIcon]="false"
              >{{ agent?.errors?.['agentCodeListInvalid'] | lang }}</cds-assistive-text
            >
          </div>
          <span class="comment">*Case(s) can be reassigned to multiple agents, pls use comma as separator. E.g. From 123456 -> To 456789,654321</span>
        </div>

        <div class="col-md-6 col-xs-12 l-mb-6">
          <cds-textfield
            #accountNumbertxtfield
            [config]="accountNumbertxtfieldConfig"
            [disabled]="!!data.value.isDefault"
            (change)="changeFormImmediately('trusteeEmployerAccountNumber', $event)"
            formControlName="trusteeEmployerAccountNumber"></cds-textfield>
          <div *ngIf="trusteeEmployerAccountNumber?.invalid && trusteeEmployerAccountNumber?.dirty" class="l-pt-1">
            <cds-assistive-text *ngIf="trusteeEmployerAccountNumber?.errors?.['required']" class="l-pt-2" [showIcon]="false"
              >Misssing Employer Account Code.</cds-assistive-text
            >
            <cds-assistive-text *ngIf="trusteeEmployerAccountNumber?.errors?.['maxlength']" class="l-pt-2" [showIcon]="false"
              >Max length is 100.</cds-assistive-text
            >
          </div>
        </div>

        <div class="col-md-6 col-xs-12 l-mb-6">
          <cds-textfield
            #companyNameTextfield
            [config]="companyNametxtfieldConfig"
            (change)="changeFormImmediately('companyName', $event)"
            formControlName="companyName"></cds-textfield>
          <div *ngIf="companyName?.invalid && companyName?.dirty" class="l-pt-1">
            <cds-assistive-text *ngIf="companyName?.errors?.['required']" class="l-pt-2" [showIcon]="false">Missing Company Name.</cds-assistive-text>
            <cds-assistive-text *ngIf="companyName?.errors?.['maxlength']" class="l-pt-2" [showIcon]="false">Max length is 300.</cds-assistive-text>
          </div>
        </div>

        <div class="col-xs-12">
          <cds-textarea
            class="remarks-textarea"
            #remarksTextfield
            label="Remarks"
            formControlName="remarks"
            (change)="changeFormImmediately('remarks', $event)"
            [maxlength]="500"></cds-textarea>
          <div *ngIf="remarks?.invalid && remarks?.dirty" class="l-pt-1">
            <cds-assistive-text *ngIf="remarks?.errors?.['required']" class="l-pt-2" [showIcon]="false">Missing Remarks.</cds-assistive-text>
            <cds-assistive-text *ngIf="remarks?.errors?.['maxlength']" class="l-pt-2" [showIcon]="false">Max length is 500.</cds-assistive-text>
          </div>
        </div>
      </div>
    </form>

    <app-permission [role]="PermissionAccess.E" [permission]="PermissionItem.SHK_AGENT_ASSIGNMENT_MAPPING">
      <div *ngIf="!data.value.isDefault" class="button-area col-xs-12" (click)="delete()">
        <div class="icon-size">
          <cds-icon size="sm" icon="form:delete"></cds-icon>
        </div>
        <span class="cds-h6-demibold l-pl-1">Delete Record</span>
      </div>
    </app-permission>
  </cds-popup-content>

  <cds-popup-actions>
    <div class="col-xs-12">
      <cds-button [config]="resetButtonConfig" [disabled]="resetDisabled" (click)="reset()">{{ 'common.reset' | lang }}</cds-button>

      <cds-button [config]="saveButtonConfig" [disabled]="saveDisabled" (click)="save()">{{ 'common.save' | lang }}</cds-button>
    </div>
  </cds-popup-actions>
</cds-popup>
