import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { CdsButtonConfig } from '@cds/ng-core/button';
import { CdsDropdownConfig } from '@cds/ng-core/dropdown';
import { CdsLangService } from '@cds/ng-core/lang';
import { CdsTextfieldConfig } from '@cds/ng-core/textfield';
import { Subscription } from 'rxjs';
import { EmployeeDataService } from '../employee-summary/employee-data.service';
import { LanguageChangeService } from 'src/app/shared/language-change.service';

@Component({
  selector: 'app-search-in-employee-delegation',
  templateUrl: './search-in-employee-delegation.component.html',
  styleUrls: ['./search-in-employee-delegation.component.scss'],
})
export class SearchInEmployeeDelegationComponent implements OnInit {
  ifshow = false;
  language = 'en';
  agevalue = '18-28';

  @Input() searchBtnDisableState = true;

  @Input() nameValue = '';
  @Input() numberValue = '';
  @Input() ageValue = '';
  @Input() gender = '';
  @Input() delegateAgentInfo = '';
  @Input() nameSearch = '名称';
  @Input() editModel = false;

  lanChangeSubscription?: Subscription;

  constructor(private employeeDataService: EmployeeDataService, private cdsLangService: CdsLangService, private languageChangeService: LanguageChangeService) {}

  ngOnInit(): void {
    this.language = this.cdsLangService.currentLang === 'en' ? 'EN' : 'TC';
    this.lanChangeSubscription = this.languageChangeService.lanSubject.subscribe(languge => {
      this.language = languge === 'en' ? 'EN' : 'TC';
    });
    this.nameValue = this.employeeDataService.request.employeeName ? this.employeeDataService.request.employeeName : '';
    this.numberValue = this.employeeDataService.request.trusteeMbrId ? this.employeeDataService.request.trusteeMbrId : '';
    this.delegateAgentInfo = this.employeeDataService.request.delegateAgentCode ? this.employeeDataService.request.delegateAgentCode : '';
    this.gender = this.employeeDataService.request.gender ? this.employeeDataService.request.gender : '';
    this.genderControl.setValue(this.gender);
    this.ageValue = this.employeeDataService.request.ageStart + '-' + this.employeeDataService.request.ageEnd;
    this.ageFormControl.setValue(this.ageValue);
  }

  employeeNameTxtfieldConfig: CdsTextfieldConfig = {
    label: this.language === 'en' ? 'Employee Name' : '雇員名稱',
    type: 'text',
    maxlength: 20,
    color: 'ml',
    placeholder: this.language === 'en' ? 'Please input' : '輸入詳情',
  };

  numbersTxtfieldConfig: CdsTextfieldConfig = {
    label: this.language === 'en' ? 'Cert No' : '僱員編號',
    type: 'text',
    placeholder: this.language === 'en' ? 'Please input' : '輸入詳情',
  };

  delegateTxtfieldConfig: CdsTextfieldConfig = {
    label: this.language === 'en' ? 'Delegated Agent Code' : '委託代理編號',
    type: 'text',
    placeholder: this.language === 'en' ? 'Please input' : '輸入詳情',
  };

  ageFormControl: FormControl = new FormControl({ value: undefined, disabled: true });
  ageEnRangeDropdownConfig: CdsDropdownConfig = {
    label: 'Age Group',
    options: [
      { label: '18-25', value: '18-25' },
      { label: '26-35', value: '26-35' },
      { label: '36-45', value: '36-45' },
      { label: '46-55', value: '46-55' },
      { label: '56-65', value: '56-65' },
      { label: '66 or above', value: '66-' },
    ],
    placeholder: 'Select an option',
    color: 'ml',
  };

  ageZhRangeDropdownConfig: CdsDropdownConfig = {
    label: '年齡組別',
    options: [
      { label: '18-25', value: '18-25' },
      { label: '26-35', value: '26-35' },
      { label: '36-45', value: '36-45' },
      { label: '46-55', value: '46-55' },
      { label: '56-65', value: '56-65' },
      { label: '66以上', value: '66-' },
    ],
    placeholder: '選擇年齡組別',
    color: 'ml',
  };

  genderControl: FormControl = new FormControl({ value: undefined, disabled: true });
  genderEnDropdownConfig: CdsDropdownConfig = {
    label: 'Gender',
    options: [
      { label: 'Male', value: 'M' },
      { label: 'Female', value: 'F' },
    ],
    color: 'ml',
    placeholder: 'Select an option',
  };

  genderZhDropdownConfig: CdsDropdownConfig = {
    label: '性別',
    options: [
      { label: '男', value: 'M' },
      { label: '女', value: 'F' },
    ],
    color: 'ml',
    placeholder: '選擇性別',
  };

  employeeSearch(): void {
    return;
  }

  getTrimData(value: string): string {
    return value.replace(/^(\s|\xA0)+|(\s|\xA0)+$/g, '');
  }

  clearData(): void {}

  @Input() employeeButtonConfig: CdsButtonConfig = {
    size: 'sm',
    style: 'primary',
  };

  clearButton: CdsButtonConfig = {
    size: 'sm',
    style: 'primary',
  };

  @Input() searchPlaceHolder?: string;

  nameChange(result: string): void {
    this.searchBtnDisableState = false;
    this.nameValue = result;
    if (result == null || result == '') {
      this.nameValue = '';
    }
    this.checkData(result);
  }

  checkData(result: string): void {
    if (result == null || result == '') {
      if (this.nameValue == '' && this.numberValue == '' && this.ageValue == '' && this.gender == '' && this.delegateAgentInfo == '') {
        this.ifshow = false;
        this.searchBtnDisableState = true;
      }
    }
  }

  numberChange(result: string): void {
    this.searchBtnDisableState = false;
    this.numberValue = result;
    if (result == null || result == '') {
      this.numberValue = '';
    }
    this.checkData(result);
  }

  ageChange(result: string): void {
    this.searchBtnDisableState = false;
    this.ageValue = result;
    if (result == null || result == '') {
      this.ageValue = '';
    }
    this.checkData(result);
  }

  genderChange(result: string): void {
    this.searchBtnDisableState = false;
    this.gender = result;
    if (result == null || result == '') {
      this.gender = '';
    }
    this.checkData(result);
  }

  delegateAgentChange(result: string): void {
    this.searchBtnDisableState = false;
    this.delegateAgentInfo = result;
    if (result == null || result == '') {
      this.delegateAgentInfo = '';
    }
    this.checkData(result);
  }

  searchState(result: string): void {
    console.log(result);
  }
}
