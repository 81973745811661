/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CdsPopupService } from '@cds/ng-web-components/popup';
import { CdsToastService } from '@cds/ng-web-components/toast';
import { finalize } from 'rxjs';
import { ReportingRolePermissionGroupList } from 'src/app/core/models/enum/reporting-role.enum';
import { ResponseResult } from 'src/app/core/models/response/response-result';
import { ReportingRoleServiceService } from 'src/app/core/services/reporting-role-service.service';
import { AlertPopupComponent } from 'src/app/shared/alert-popup/alert-popup.component';
import { ConfirmPopupComponent } from 'src/app/shared/confirm-popup/confirm-popup.component';

@Component({
  selector: 'app-reporting-role-info',
  templateUrl: './reporting-role-info.component.html',
  styleUrls: ['./reporting-role-info.component.scss'],
})
export class ReportingRoleInfoComponent implements OnInit {
  rolePermissionGroupList = ReportingRolePermissionGroupList;
  roleId = '';
  roleName = '';
  roleHasPermission = {};
  tab = [
    {
      label: 'role.edit.tab.nameAccessRight',
      selected: false,
    },
    {
      label: 'role.edit.tab.assignUsers',
      selected: true,
    },
  ];
  tabSelected: any;
  isLoading = false;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private cdsPopup: CdsPopupService,
    private reportingRoleServiceService: ReportingRoleServiceService,
    private toast: CdsToastService
  ) {}

  ngOnInit() {
    this.roleId = this.route.snapshot.queryParams['roleId'] || '';
    if (!this.roleId) {
      this.back();
      return;
    }
    this.getRoleInfo();
    this.tabClick(this.tab[0]);
  }

  getRoleInfo() {
    this.isLoading = true;
    this.reportingRoleServiceService
      .getRoleInfo(this.roleId)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(res => {
        if (res.result === ResponseResult.SUCCESS) {
          this.initInfo(res.data);
        }
      });
  }

  initInfo(info: any) {
    if (info) {
      this.roleName = info.roleName;
      this.roleHasPermission = info.permissions || {};
    }
  }

  back() {
    this.router.navigate(['/system/reporting-role-list']);
  }

  save(param: any) {
    this.isLoading = true;
    param.roleId = this.roleId;
    this.reportingRoleServiceService
      .updateRoleInfo(param)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(res => {
        if (res.result === ResponseResult.SUCCESS) {
          this.initInfo(res.data);
        } else {
          this.toast.error(`Create role failed:${res.message}`);
        }
      });
  }

  tabClick(tab: any) {
    this.tab.forEach((item: any) => {
      item.selected = false;
    });
    tab.selected = true;
    this.tabSelected = tab;
  }

  deleteConfirm() {
    this.cdsPopup
      .open(ConfirmPopupComponent, {
        size: 'sm',
        data: { title: 'role.confrim.delete' },
      })
      .afterClosed()
      .subscribe(result => {
        if (result.agree) {
          this.delete();
        }
      });
  }

  delete() {
    this.isLoading = false;
    this.reportingRoleServiceService
      .deleteRole(this.roleId)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(res => {
        if (res.result === ResponseResult.SUCCESS) {
          this.popupWithRedirect();
        } else {
          this.toast.error('Operation failed: ' + res.message);
        }
      });
  }
  private popupWithRedirect() {
    this.cdsPopup
      .open(AlertPopupComponent, {
        size: 'sm',
        data: {
          title: 'common.action.changeSuccess',
        },
      })
      .afterClosed()
      .subscribe(() => {
        this.router.navigate(['/system/reporting-role-list']);
      });
  }
}
