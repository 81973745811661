import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ChargeInfoClass, ChargeInfoRequest, ChargeInfoSelected } from '../../employer';
import { EmployerService } from '../../employer.service';
import { finalize } from 'rxjs';
import * as moment from 'moment';

@Component({
  selector: 'app-charge-info',
  templateUrl: './charge-info.component.html',
  styleUrls: ['./charge-info.component.scss'],
})
export class ChargeInfoComponent implements OnInit, OnChanges {
  constructor(private service: EmployerService) {}

  @Input() payrollGroupId?: string;
  @Input() employerId?: string;

  _typeList: ChargeInfoSelected[] = [
    {
      label: 'Future Service Charge',
      value: '0',
      dropName: 'Future Service Charge Effective Date (MMM YYY)',
      codeName: 'Future Service Charge Code',
      debuctTypeMap: new Map([
        [0, 'FS CHR  DED  NA'],
        [1, 'FS  CHR SEP'],
        [2, 'FS  CHR EE ER OPT'],
        [3, 'FS CHR  ER EE'],
        [4, 'FS  CHR ER OPT'],
        [5, 'FS CHR ER'],
      ]),
    },
    {
      label: 'Past Service Charge',
      value: '1',
      dropName: 'Past Service Charge Effective Date (MMM YYY)',
      codeName: 'Past Service Charge Code',
      debuctTypeMap: new Map([
        [0, 'PS CHR  DED NA'],
        [1, 'PS CHR SEP'],
        [3, 'PS  CHR ER EE'],
        [5, 'PS CHR ER'],
      ]),
    },
    {
      label: 'Anniversary Charge',
      value: '2',
      dropName: 'Anniversary Charge Effective Date (MMM YYY)',
      codeName: 'Anniversary Charge Code',
      debuctTypeMap: new Map([
        [0, 'ANNIV CHR DED NA'],
        [1, 'ANNIV CHR SEP'],
        [2, 'ANNIV CHR EE ER OPT'],
        [3, 'ANNIV CHR ER EE'],
        [4, 'ANNIV CHR ER OPT'],
        [5, 'ANNIV CHR ER'],
      ]),
    },
  ];
  _typeSelected = 0;

  _deteList: string[] = [];
  _dateSelected = 0;
  _detailsShow = false;

  dataList: ChargeInfoClass[] = [];

  _loading = false;

  _chargeAppTypeMap = new Map([
    [1, 'APPLIC  CASH  PAYMENT'],
    [2, 'APPLIC  BILLED CONT'],
  ]);

  _brakPointTypeMap = new Map([
    [0, 'NA'],
    [1, 'CURRENT POLICY YEAR CONT'],
    [2, 'ACCUM CONT'],
  ]);

  _policyFreeTypeMap = new Map([
    [' ', ' '],
    ['A', 'ANNUAL FEE'],
  ]);

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (
      this.employerId &&
      changes['payrollGroupId'].currentValue !== undefined &&
      changes['payrollGroupId'].currentValue !== changes['payrollGroupId'].previousValue
    ) {
      this.getDateList();
    }
  }

  _policyFreeType(key?: string) {
    if (key == undefined) {
      return 'FEE TYPE NA';
    }
    return this._policyFreeTypeMap.get(key) || 'FEE TYPE NA';
  }

  _brakPointType(key?: number) {
    if (key == undefined) {
      return '';
    }
    return this._brakPointTypeMap.get(key) || '';
  }

  _debuctType(key?: number) {
    if (key == undefined) {
      return '';
    }
    return this._typeList[this._typeSelected].debuctTypeMap.get(key) || '';
  }

  formatType(key?: number) {
    if (key == undefined) {
      return '';
    }
    return this._chargeAppTypeMap.get(key) || '';
  }

  formatDate(val?: string) {
    if (!val) {
      return '';
    }
    let _temp = val.substring(1);
    if (val.startsWith('1')) {
      _temp = '19' + _temp;
    }
    if (val.startsWith('2')) {
      _temp = '20' + _temp;
    }
    return moment(_temp).format('MMM YYYY');
  }

  getDateList() {
    const _param: ChargeInfoRequest = {
      payrollGroupId: this.payrollGroupId,
      id: this.employerId,
      chargeApplicationType: this._typeList[this._typeSelected].value,
    };
    this._loading = true;
    this.service
      .getChargeInformationEffectiveDate(_param)
      .pipe(
        finalize(() => {
          this._loading = false;
        })
      )
      .subscribe(data => {
        if (data.result === 0) {
          this._deteList = data.data ? data.data : [];
          this.getData();
        }
      });
  }

  getData() {
    this.dataList = [];
    if (this._deteList.length == 0) {
      return;
    }
    const _param: ChargeInfoRequest = {
      payrollGroupId: this.payrollGroupId,
      id: this.employerId,
      chargeApplicationType: this._typeList[this._typeSelected].value,
      effectiveDate: this._deteList[this._dateSelected],
    };
    this._loading = true;
    this.service
      .getChargeInformation(_param)
      .pipe(
        finalize(() => {
          this._loading = false;
        })
      )
      .subscribe(data => {
        if (data.result === 0) {
          this.dataList = data.data ? data.data : [];
        }
      });
  }

  _detailTrigger() {
    this._detailsShow = !this._detailsShow;
  }

  select(index: number) {
    this._dateSelected = index;
    this._detailTrigger();
    this.getDateList();
  }

  typeSelecte(index: number) {
    this._typeSelected = index;
    this.getDateList();
  }
}
