<div class="card-min-height p-3">
  <div class="row" style="align-items: end">
    <div class="col-lg-6 col-md-6 col-sm-3 col-7 pb-1 ps-1">
      <span style="font-weight: 600; font-size: 20px">Chatbot / Live Chat</span>
      <app-cd-table-title [total]="rows.length" [showSubTitle]="true"></app-cd-table-title>
    </div>

    <cds-tab-group [type]="'line-small'" [selectedIndex]="tab" (selectedTabChange)="tabChange($event)">
      <cds-tab [selected]="tab == 0" label="Active"> </cds-tab>
      <cds-tab [selected]="tab == 1" label="Pending "> </cds-tab>
      <cds-tab [selected]="tab == 2" label="Archive "> </cds-tab>
      <cds-tab [selected]="tab == 3" label="Bot"> </cds-tab>
    </cds-tab-group>
    <app-data-table [dataSource]="rows" [columnsConfig]="headers">
      <ng-template appDtBodyCellDef="cso" let-element>
        <span class="" [innerHTML]="findUser(element.users, 0)"> </span>
      </ng-template>
      <ng-template appDtBodyCellDef="agent" let-element>
        <span class="" [innerHTML]="findUser(element.users, 1)"> </span>
      </ng-template>
    </app-data-table>
  </div>
</div>
