import { Injectable } from '@angular/core';
import { APIPaths } from '../_common/constant';
import { Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Alerts } from '../_common/alerts';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ChatService {
  constructor(public httpClient: HttpClient) {}

  getChatHistory() {
    let onSuccess = (data: any) => {
      if (data) {
        return data;
      } else {
        Alerts.showErrorMessage(data.meta.message);
      }
    };
    return this.httpClient.get(APIPaths.gethistory).pipe(
      map(value => value),
      map(onSuccess)
    );
  }

  getAllRoomForHistory() {
    let onSuccess = (data: any) => {
      if (data) {
        return data;
      } else {
        Alerts.showErrorMessage(data.meta.message);
      }
    };
    return this.httpClient.get(APIPaths.getAllRoomForHistory).pipe(
      map(value => value),
      map(onSuccess)
    );
  }
}
