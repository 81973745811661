<div class="col-xs-12 l-plr-0">
  <div class="row col-xs-12 l-pt-4 l-pb-4 middle-xs" [ngClass]="[expand ? 'line-bord-show' : 'line-bord']">
    <div (click)="trigger()" class="icon-width pointer">
      <cds-icon [width]="size" [height]="size" [@openClose]="_iconClass" [config]="_config" [icon]="icon"></cds-icon>
    </div>
    <div class="col-xs-2">
      {{ data.dateOfAssignment | date : 'dd/MM/yyyy' }}
    </div>
    <!-- <div class="col-xs-2">
      {{ data.terminationDate | date : 'dd/MM/yyyy' }}
    </div> -->
    <div class="col-xs-2">
      {{ data.dateOfEmploy | date : 'dd/MM/yyyy' }}
    </div>
    <div class="col-xs line-break">
      <span class="l-mr-1">{{ data.companyName }}</span
      ><span>{{ data.chineseCompanyName }}</span>
    </div>
    <div class="col-xs line-break">
      {{ data.empolyeeName }}
    </div>
    <div class="col-xs line-break">
      <!-- do not use [(ngModel)] -->
      <div class="dropdown-area">
        <cds-dropdown
          class="dropdown"
          [config]="noOfContactConfig"
          [ngModel]="data.numberOfContact"
          (ngModelChange)="noOfContactChange($event, data)"></cds-dropdown>
        <cds-icon class="clear-icon" icon="action:button_x_filled" (click)="clearDropdown(data)" [config]="clearIconConfig"></cds-icon>
      </div>
    </div>
    <div class="col-xs line-break">
      <!-- do not use [(ngModel)] -->
      <cds-checkbox [ngModel]="data.askClient" (ngModelChange)="askedLientChange($event, data)" [disabled]="!data.numberOfContact" label=""></cds-checkbox>
    </div>
    <div class="col-xs-2 line-break">
      <!-- do not use [(ngModel)] -->
      <div class="dropdown-area2">
        <cds-dropdown
          class="dropdown2"
          [config]="data.askClient ? dropdownConfig : dropdownConfig2"
          [ngModel]="data.agentFollowUpStatus"
          (ngModelChange)="agentFollowUpStatusChange($event, data)"></cds-dropdown>
        <cds-icon class="clear-icon" icon="action:button_x_filled" (click)="clearDropdown2(data)" [config]="clearIconConfig"></cds-icon>
      </div>
    </div>
  </div>
  <div [@openClose]="_detailClass" class="row col-xs-12 expand-detail">
    <div class="col-xs-12 l-mt-7 l-mb-2">
      <span class="cds-body1-ita ita-font">{{ 'termination.list.detailTitle' | lang }}</span>
    </div>
    <app-row-item label="termination.list.eMpfAcount" data="{{ data.empfMemberAccountNumber }}" class="col-xs-3 l-pt-4 l-pb-4"></app-row-item>
    <app-row-item label="termination.list.employeeAge" data="{{ data.age }}" class="col-xs l-pt-4 l-pb-4"></app-row-item>
    <app-row-item label="termination.list.employeeGender" data="{{ data.gender }}" class="col-xs l-pt-4 l-pb-4"></app-row-item>
    <app-row-item label="termination.list.employeeMobileNo" data="{{ data.employeeMobileNo }}" class="col-xs-3 l-pt-4 l-pb-4">
      <ng-template appRowItemContentDef>
        <div class="flex">
          <span class="">{{ data.employeeMobileNo }}</span>
          <cds-icon
            class="icon-margin l-ml-1 icon-color"
            icon="social_media:whatsapp"
            color="#00A758"
            (click)="service.redirectToWhatsapp(data)"></cds-icon></div></ng-template
    ></app-row-item>
    <app-row-item label="termination.list.employeeEmailAddress" class="col-xs-3 l-pt-4 l-pb-4">
      <ng-template appRowItemContentDef>
        <div class="flex">
          <span class="">{{ data.emailAddress }}</span>
          <cds-icon
            class="icon-margin l-ml-1 icon-color"
            icon="contact:email"
            color="#fff"
            (click)="service.redirectToEmail(data, 'zh')"
            cdsTooltip="chinese"></cds-icon>
          <span class="l-ml-1">Chinese</span>
          <cds-icon
            class="icon-margin l-ml-1 icon-color"
            icon="contact:email"
            color="#fff"
            cdsTooltip="English"
            (click)="service.redirectToEmail(data, 'en')"></cds-icon>
          <span class="l-ml-1">English</span>
        </div>
      </ng-template>
    </app-row-item>

    <!-- <app-row-item label="termination.list.dateOfEmploy" [data]="data.dateOfEmploy | ssmsDate" class="col-xs-12 l-pt-4 l-pb-4"></app-row-item> -->
  </div>
</div>
