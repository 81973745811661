<div class="l-pb-6">
  <div class="row">
    <div class="col-xs-12 col-md-12 l-d-f l-jc-sb l-pt-5 l-pb-5">
      <div class="cds-h4-demibold l-ma-0 cds-h4-demibold">
        {{ 'calendar.page.title' | lang }}
      </div>
    </div>
  </div>

  <div class="row div-border-top">
    <div class="col-xs-12 col-md-12 l-pt-6 l-pb-5 l-d-f l-plr-0">
      <div *ngFor="let item of tab" class="l-pr-8 tab" [class.tab-selected]="item.selected" (click)="tabClick(item)">
        <div class="full">{{ item.label | lang }}</div>
      </div>
    </div>

    <div *ngIf="tab[0].selected" class="col-xs-12 col-md-12 l-d-f l-plr-0">
      <app-calendar-component class="col-xs-12 col-md-12"></app-calendar-component>
    </div>

    <div *ngIf="tab[1].selected" class="col-xs-12 col-md-12 l-plr-0">
      <app-event-edit-list></app-event-edit-list>
    </div>
  </div>
</div>
