export function specialCharacter(_char: string) {
  return _char
    .replace('+', '%2B')
    .replace(' ', '%20')
    .replace('/', '%2F')
    .replace('?', '%3F')
    .replace('%', '%25')
    .replace('#', '%23')
    .replace('&', '%26')
    .replace('=', '%3D');
}

export function specialCharacterDecode(_char: string) {
  return _char
    .replace('%2B', '+')
    .replace('%20', ' ')
    .replace('%2F', '/')
    .replace('%3F', '?')
    .replace('%25', '%')
    .replace('%23', '#')
    .replace('%26', '&')
    .replace('%3D', '=');
}
