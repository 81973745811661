/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Sort } from '@cds/ng-data-table/sort';
import { Observable } from 'rxjs';
import { BasicResponse, PageResponse } from 'src/app/core/models/response/response';
import { environment } from 'src/environments/environment';
import { EnrolmentRecord } from './enrolment';

@Injectable({
  providedIn: 'root',
})
export class EnrolmentService {
  private getPageListUrl = `${environment.apiPrefix}/ext/eb-ssms/customer-service/shkp/enrolment-members`;
  //private getPageListUrl = `http://localhost:8082/shkp/enrolment-members`;
  //private getPageListUrl = `http://localhost:8082/shkp/terminated-members`;
  constructor(private http: HttpClient) {}

  getPageList(page: number, size: number, sort: Sort): Observable<PageResponse<EnrolmentRecord>> {
    let sortParam = '';

    const params: {
      page: number;
      size: number;
      sort?: string;
    } = {
      page: page - 1,
      size,
    };

    if (sort.active) {
      if (sort.direction) {
        sortParam = `${sort.active},${sort.direction}`;
      } else {
        sortParam = 'createdAt,desc';
      }
      params.sort = sortParam;
    }

    return this.http.get<PageResponse<EnrolmentRecord>>(this.getPageListUrl, {
      params,
    });
  }

  putTerminatedMember(data: EnrolmentRecord): Observable<BasicResponse<boolean>> {
    return this.http.put<BasicResponse<boolean>>(this.getPageListUrl, data);
  }

  recordListContentTrans(content: EnrolmentRecord[]) {
    return content.map(item => {
      const firstName = item.firstName || '';
      const lastName = item.lastName || '';
      const chineseFirstName = item.chineseFirstName || '';
      const chineseLastName = item.chineseLastName || '';
      const empolyeeName = `${firstName || ''} ${lastName || ''} ${chineseLastName || ''}${chineseFirstName || ''}`;
      const dateOfEmploy = item.dateOfEmploy || '';
      let employeeMobileNo = '';
      if (!item.primaryMobileNumberCountryCode) {
        employeeMobileNo = `${item.primaryMobileNumber || ''}`;
      } else {
        employeeMobileNo = `(+${item.primaryMobileNumberCountryCode})${item.primaryMobileNumber || ''}`;
      }

      return {
        ...item,
        empolyeeName,
        employeeMobileNo,
        dateOfEmploy,
      };
    });
  }

  getUrlContent(data: EnrolmentRecord, type: string) {
    if (type === 'en' && data.emailEngContent) {
      const content = (data as any)['emailEngContent'];
      return encodeURIComponent(content);
      //return encodeURIComponent("Mr/Ms. xxx, My name is [Name of agent] (Agent code: [Agent code]) and I am from Manulife. I am notified by SHKP MPF about your termination. Manulife is appointed by SHKP MPF to assist you to handle your accrued benefits arrangement. I am your servicing representative.I have tried to connect with you but fail. You may visit our website to understand Manulife Global Select MPF Scheme. Welcome to contact me anytime if there is any question. I will contact you again later.For details please visit: https://www.manulife.com.hk/zh-hk/individual/products/mpf/manulife-global-select-scheme/personal-account.html xxx先生/女士，我是您的宏利服務代表[代理人姓名] (代理人編號: [Agent code])。 新地強積金通知我有關您的終止事宜。宏利獲新地強積金委任，協助你處理累算權益安排。 我曾嘗試與您聯繫，可惜未能成功。您可以按以下連結了解宏利環球精選強積金計劃。 如有任何問題，歡迎隨時與我聯繫。\n我稍後會再次與您聯繫。\n詳情請查看:https://www.manulife.com.hk/zh-hk/individual/products/mpf/manulife-global-select-scheme/personal-account.html");
    }
    if (type === 'zh' && data.emailChiContent) {
      const content = (data as any)['emailChiContent'];
      return encodeURIComponent(content);
      //return encodeURIComponent("Mr/Ms. xxx, My name is [Name of agent] (Agent code: [Agent code]) and I am from Manulife. I am notified by SHKP MPF about your termination. Manulife is appointed by SHKP MPF to assist you to handle your accrued benefits arrangement. I am your servicing representative.I have tried to connect with you but fail. You may visit our website to understand Manulife Global Select MPF Scheme. Welcome to contact me anytime if there is any question. I will contact you again later.For details please visit: https://www.manulife.com.hk/zh-hk/individual/products/mpf/manulife-global-select-scheme/personal-account.html xxx先生/女士，我是您的宏利服務代表[代理人姓名] (代理人編號: [Agent code])。 新地強積金通知我有關您的終止事宜。宏利獲新地強積金委任，協助你處理累算權益安排。 我曾嘗試與您聯繫，可惜未能成功。您可以按以下連結了解宏利環球精選強積金計劃。 如有任何問題，歡迎隨時與我聯繫。\n我稍後會再次與您聯繫。\n詳情請查看:https://www.manulife.com.hk/zh-hk/individual/products/mpf/manulife-global-select-scheme/personal-account.html");
    }
    return '';
  }

  redirectToWhatsapp(data: EnrolmentRecord) {
    //     data.emailContent = `感謝您表達對宏利強積金個人帳戶的興趣。

    // 我們誠邀您整合個人帳戶至宏利，宏利提供全面的基金平台作強積金投資。只需簡單幾步，您便可開立宏利個人帳戶及/或轉移強積金資產，以便管理。

    // https://www.manulife-campaign.com.hk/redirect/tc/pa_consolidation/1047/email?data=b5ztG8zZlWUGBit2Jn25QeYfYPD%2BhN97IWJl6t9C3Eua1V8TfS6Qjux26adBIxkWWLQMv%2FDkyBFIJNoAMTZANw%3D%3D;

    // Thank you for your interest in the Manulife Personal Account.

    // We cordially invite you to consolidate personal accounts with Manulife. Manulife provides a comprehensive fund platform for MPF investment. With just a few steps, you can open a Manulife Personal Account and/or transfer MPF assets for easy management.

    // https://www.manulife-campaign.com.hk/redirect/en/pa_consolidation/1048/email?data=MdgePgh7afB9mokk%2BxaTmMggBYFf2SdLdJCcGClzrnk5fPu%2BANuuL0gFCzVD%2FGAo1gDsDU%2FYBX%2FudlvalvMRLA%3D%3D`;
    const content = (data as any)['whatAppContent'];
    const text = encodeURIComponent(content);

    const url = `https://wa.me/${data.primaryMobileNumberCountryCode}${data.primaryMobileNumber}?text=${text}`;
    console.log('url:', url);
    window.open(url, '_blank');
  }

  redirectToEmail(data: EnrolmentRecord, type: string) {
    let subject = '';
    if (type === 'en') {
      subject = 'eMPF enrolment for SHKP MPF ESS contribution account';
    }
    if (type === 'zh') {
      subject = '積金易平台登記 新地強積金僱主營辦計劃強積金供款帳戶';
    }
    const body = this.getUrlContent(data, type);
    const url = `mailto:${data.emailAddress}?subject=${subject}&body=${body}`;
    console.log('url:->>>>>' + url);

    window.open(url, '_blank');
  }
}
