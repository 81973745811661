import { AfterViewInit, Component, Input, OnInit, Type, ViewChild } from '@angular/core';
import { WidgetHostDirective } from 'src/app/shared/directive/widgetHost/widget-host.directive';
import { WidgetsQuickLinksComponent } from '../widgets/widgets-quick-links/widgets-quick-links.component';
import { WidgetsProductionCreditComponent } from '../widgets/widgets-production-credit/widgets-production-credit.component';
import { WidgetsPendingIffComponent } from '../widgets/widgets-pending-iff/widgets-pending-iff.component';
import { WidgetsCampaignResultComponent } from '../widgets/widgets-campaign-result/widgets-campaign-result.component';
import { WidgetsCampaignOverviewComponent } from '../widgets/widgets-campaign-overview/widgets-campaign-overview.component';
import { WidgetsProductionCreditByDistrictComponent } from '../widgets/widgets-production-credit-by-district/widgets-production-credit-by-district.component';
/* eslint-disable @typescript-eslint/no-explicit-any */

@Component({
  selector: 'app-dynamic-content',
  templateUrl: './dynamic-content.component.html',
  styleUrls: ['./dynamic-content.component.scss'],
})
export class DynamicContentComponent implements OnInit, AfterViewInit {
  @Input() componentName!: string;
  @Input() data: any;
  @ViewChild(WidgetHostDirective, { static: true }) appWidgetHost!: WidgetHostDirective;

  private componentMap: { [key: string]: Type<any> } = {
    WidgetsQuickLinksComponent: WidgetsQuickLinksComponent,
    WidgetsProductionCreditComponent: WidgetsProductionCreditComponent,
    WidgetsPendingIffComponent: WidgetsPendingIffComponent,
    WidgetsCampaignResultComponent: WidgetsCampaignResultComponent,
    WidgetsCampaignOverviewComponent: WidgetsCampaignOverviewComponent,
    WidgetsProductionCreditByDistrictComponent: WidgetsProductionCreditByDistrictComponent,
  };

  constructor() {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.loadComponent();
  }

  loadComponent() {
    const viewContainerRef = this.appWidgetHost.viewContainerRef;
    viewContainerRef.clear();

    const componentRef = viewContainerRef.createComponent(this.componentMap[this.componentName]);
    componentRef.instance.data = this.data;
  }
}
