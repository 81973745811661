/* eslint-disable @typescript-eslint/no-explicit-any */
import { AbstractControl, AsyncValidator, ValidationErrors } from '@angular/forms';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, of } from 'rxjs';

import { CommissionScaleService } from 'src/app/core/services/commission/commission-scale.service';

@Injectable({ providedIn: 'root' })
export class ScaleCodeAsyncValidator implements AsyncValidator {
  constructor(private commissionScaleService: CommissionScaleService) {}

  validate(control: AbstractControl): Observable<ValidationErrors | null> {
    const value = control.value?.trim();
    if (!value || !control.dirty) {
      return of(null);
    }
    if (value === 'NOCOMM') {
      return of(null);
    }
    const searchForm = {
      page: 0,
      size: 1,
      scaleCodeSpecify: value,
    };
    return this.commissionScaleService.selectList(searchForm).pipe(
      map(res => {
        if (res.data && res.data.content && res.data.content.length) {
          return null;
        }
        return { error: 'commission.common.scaleCode.invalid' };
      }),
      catchError(() => of(null))
    );
  }
}
