import { Component, ContentChild, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CdsCheckboxConfig } from '@cds/ng-core/checkbox';
import { RowItemContentDefDirective, RowItemLabelDefDirective } from './row-item.component.directive';

@Component({
  selector: 'app-row-item',
  templateUrl: './row-item.component.html',
  styleUrls: ['./row-item.component.scss'],
})
export class RowItemComponent implements OnInit {
  @Input() label = '';
  @Input() data?: string | number | null = '';
  @Input() icon?: string;
  @Output() funCall = new EventEmitter();
  @Input() checked?: boolean;
  @Input() toolTipString = '';
  @Input() iconColor = '#282B3E';

  @ContentChild(RowItemLabelDefDirective) labelRef!: RowItemLabelDefDirective;
  @ContentChild(RowItemContentDefDirective) contentRef!: RowItemContentDefDirective;

  noCheckConfig: CdsCheckboxConfig = {
    disabled: true,
    checked: false,
  };

  checkedConfig: CdsCheckboxConfig = {
    disabled: true,
    checked: true,
  };
  constructor() {}

  ngOnInit(): void {}

  call() {
    this.funCall.emit();
  }

  onCanDelegateAmtToggle(event: MouseEvent) {
    if (event.target) {
      event.target.dispatchEvent(new Event('click'));
    }
  }
}
