import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-different-agent-status',
  templateUrl: './different-agent-status.component.html',
  styleUrls: ['./different-agent-status.component.scss'],
})
export class DifferentAgentStatusComponent implements OnInit {
  constructor(private dialogRef: MatDialogRef<DifferentAgentStatusComponent>) {}

  ngOnInit(): void {}

  outsideEvent() {
    this.dialogRef.close();
  }
}
