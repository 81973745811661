<div class="row">
  <div class="col-xs-12 l-pt-5">
    Lorem ipsum dolor sit amet. Qui autem impedit est tenetur reiciendis et sunt rerum qui quisquam animi et repudiandae rerum ab cumque dolore ut accusamus
    ipsum. Doloribus aliquid quo delectus atque aut corporis eius eum maiores optio in autem consequatur quo quidem officiis. Est nisi delectus et laboriosam
    galisum est nihil tempore quo beatae quibusdam. 33 velit omnis in dignissimos saepe aut atque consectetur et dolores mollitia in tenetur galisum nam illo
    eveniet!
  </div>
</div>
<div class="row">
  <div class="col-xs-12 l-pt-5">
    <span class="cds-h2">{{ 'list.search-record' | lang }}</span>
  </div>
</div>

<div class="row" *ngIf="form" [formGroup]="form">
  <div class="col-xs-6 col-sm-3 col-md-3 col-lg-3 l-pt-4 l-pb-4">
    <cds-textfield
      formControlName="agentCode"
      [(ngModel)]="searchDTO.agentCode"
      #agentCode
      (input)="inputChange(agentCode.value, 'agentCode')"
      label="{{ 'agent-performance-head.agent-code' | lang }}"
      placeholder="{{ 'agent-performance-head.agent-code-input' | lang }}"></cds-textfield>
    <cds-assistive-text class="l-pt-3" *ngIf="form.get('agentCode')?.invalid">{{ form.get('agentCode')?.errors?.['invalidNumberString'] }}</cds-assistive-text>
  </div>
  <div class="col-xs-6 col-sm-3 col-md-3 col-lg-3 l-pt-4 l-pb-4 my-content">
    <cds-dropdown
      formControlName="period"
      class="xs-margin-bottom"
      [(ngModel)]="searchDTO.period"
      (ngModelChange)="validating()"
      (change)="inputChange($event, 'period')"
      [config]="periodConfig"></cds-dropdown>
  </div>
  <div *ngIf="showDatePicker" class="row col-xs-12 col-sm-12 col-md-8 col-lg-6 l-pt-4 l-pb-4 l-plr-0 date-picker-area relative">
    <div class="col-xs-6 date-picker">
      <cds-textfield
        formControlName="assignmentDateFrom"
        [(ngModel)]="searchDTO.assignmentDateFromShow"
        [appDateValidator]="I18N_KEY.INVALID_DATE"
        [appDateValidatorMaxDate]="searchDTO.assignmentDateToShow || nowTimeInstr"
        (ngModelChange)="validating()"
        (change)="inputChange($event, 'assignmentDateFrom')"
        placeholder="DD/MM/YYYY"
        label="Assignment Date From"
        [max]="getMaxFromDate | fn : searchDTO.assignmentDateToShow"
        [cdsDatepicker]="startPicker">
      </cds-textfield>
      <cds-datepicker #startPicker="cdsDatepicker"></cds-datepicker>
      <cds-assistive-text class="l-pt-3" *ngIf="form.get('assignmentDateFrom')?.invalid">{{ 'create.dateError' | lang }} </cds-assistive-text>
    </div>
    <div class="connector">-</div>
    <div class="col-xs-6 date-picker">
      <cds-textfield
        formControlName="assignmentDateTo"
        [(ngModel)]="searchDTO.assignmentDateToShow"
        [appDateValidator]="I18N_KEY.INVALID_DATE"
        [appDateValidatorMinDate]="searchDTO.assignmentDateFromShow"
        [appDateValidatorMaxDate]="maxTimeInStr"
        (ngModelChange)="validating()"
        (change)="inputChange($event, 'assignmentDateTo')"
        placeholder="DD/MM/YYYY"
        label="To"
        [min]="getMinToDate | fn : searchDTO.assignmentDateFromShow"
        [max]="maxTime"
        [cdsDatepicker]="endPicker">
      </cds-textfield>
      <cds-datepicker #endPicker="cdsDatepicker"></cds-datepicker>

      <cds-assistive-text class="l-pt-3" *ngIf="form.get('assignmentDateTo')?.invalid">{{ 'create.dateError' | lang }} </cds-assistive-text>
    </div>
  </div>

  <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 l-mt-4 l-pb-4 bottom-xs l-pt-2">
    <cds-button
      class="l-mr-4 botton"
      [config]="searchButtonConfig"
      [disabled]="!form.valid || !_valid"
      label="{{ 'list.search' | lang }}"
      (click)="search()"></cds-button>
    <cds-button class="botton" [config]="resetButtonConfig" [disabled]="!_valid" label="{{ 'list.reset' | lang }}" (click)="reset()"></cds-button>
  </div>
</div>

<div class="row between-xs">
  <div class="col-xs-6 row l-plr-0">
    <div class="col-xs-12 l-pt-4">
      <span class="cds-h2-light l-ma-0">{{ 'common.recordList' | lang }}</span>
    </div>
    <div class="col-xs-12">
      <span class="cds-detail1-demibold">Total {{ dataList.length }} records</span>
    </div>
  </div>
  <div class="l-pt-4">
    <cds-button [fullWidth]="false" [config]="exportButtonConfig" label="{{ 'common.export-result' | lang }}" (click)="exportData()"></cds-button>
  </div>
</div>

<div class="row">
  <div class="col-xs-12 l-pt-4 table-container">
    <!-- frontend pagination and sorting-->
    <!-- <app-data-table
      [frontPagination]="true"
      [isLoading]="_progress"
      [pageConfig]="pageConfig"
      [fixed]="false"
      [overlay]="true"
      [dataSource]="dataList"
      [columnsConfig]="columnsConfig"
      (frontReloadTable)="frontReloadTable($event)">
      <ng-template appDtBodyCellDef="agentName" let-element>
        <span>{{ element['agentName'] }}</span>
        <span *ngIf="element['agentNameChi']"> ({{ element['agentNameChi'] }}) </span>
      </ng-template>
    </app-data-table> -->

    <app-table-expand
      (sortChange)="sortChange($event)"
      [progress]="_progress"
      (expandChange)="expandRow($event)"
      [expandTemplate]="expandTemplate"
      [data]="dataList">
      <app-table-expand-col [sort]="Sort.DEFAULT" prop="agentCode" label="Agent Code" width="80px"></app-table-expand-col>
      <app-table-expand-col [sort]="Sort.DEFAULT" [template]="greenLine" prop="agentName" label="Agent Name" width="300px"></app-table-expand-col>
      <app-table-expand-col [sort]="Sort.DEFAULT" prop="outStanding" label="Outstanding"></app-table-expand-col>
      <app-table-expand-col [sort]="Sort.DEFAULT" prop="initialContact" label="Initial Contact" width="60px"></app-table-expand-col>
      <app-table-expand-col [sort]="Sort.DEFAULT" prop="scheduleMeetUp" label="Schedule Meet Up" width="60px"></app-table-expand-col>
      <app-table-expand-col [sort]="Sort.DEFAULT" prop="canNotReachClient" label="Cannot Reach Client" width="60px"></app-table-expand-col>
      <app-table-expand-col [sort]="Sort.DEFAULT" prop="rejectedByClient" label="Rejected by Client" width="60px"></app-table-expand-col>
      <app-table-expand-col [sort]="Sort.DEFAULT" prop="completed" label="Completed" width="60px"></app-table-expand-col>
      <app-table-expand-col [sort]="Sort.DEFAULT" prop="total" label="Total" width="60px"></app-table-expand-col>
      <app-table-expand-col [sort]="Sort.DEFAULT" prop="transferToGs" label="Transfer to GS (exclude Auto rollover)" width="80px"></app-table-expand-col>
    </app-table-expand>
  </div>
</div>

<div *ngIf="dataList && dataList.length" class="row l-pt-4 middle-xs">
  <app-pagination [pageCount]="_pageCount" [isLoading]="_progress" [textfieldAlwaysAvailable]="true" [useOf]="true" (pageChange)="loadTable()">
  </app-pagination>
</div>

<ng-template #expandTemplate let-element>
  <div class="row col-xs-12 l-plr-0">
    <div class="cds-detail1 col-xs-12 l-plr-0 italic bold l-mb-4">Contact Details</div>
    <div class="col-xs-12 l-plr-0 expand-table">
      <app-data-table
        *ngIf="element && element.length"
        [overlay]="false"
        [dataSource]="element || []"
        [isShowPagination]="false"
        [columnsConfig]="expandColumnsConfig">
      </app-data-table>
    </div>
  </div>
</ng-template>

<ng-template #greenLine let-element>
  <span>{{ element['agentName'] }}</span>
  <span *ngIf="element['agentNameChi']"> &nbsp;{{ element['agentNameChi'] }} </span>
</ng-template>
