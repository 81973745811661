import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CdsLanguageModule } from '@cds/ng-core/lang';
import { CdsTableModule } from '@cds/ng-data-table/table';
import { CdsAssistiveTextModule } from '@cds/ng-web-components/assistive-text';
import { CdsButtonModule } from '@cds/ng-web-components/button';
import { CdsCheckboxModule } from '@cds/ng-web-components/checkbox';
import { CdsDropdownModule } from '@cds/ng-web-components/dropdown';
import { CdsIconModule } from '@cds/ng-web-components/icon';
import { CdsLinkModule } from '@cds/ng-web-components/link';
import { CdsPaginationModule } from '@cds/ng-web-components/pagination';
import { CdsPopupModule } from '@cds/ng-web-components/popup';
import { CdsProgressModule } from '@cds/ng-web-components/progress';
import { CdsSearchModule } from '@cds/ng-web-components/search';
import { CdsTabsModule } from '@cds/ng-web-components/tabs';
import { CdsTextfieldModule } from '@cds/ng-web-components/textfield';
import { DataTableModule } from 'src/app/shared/data-table';
import { AppPaginationModule } from 'src/app/shared/pagination/pagination.module';
import { SharedModule } from 'src/app/shared/shared.module';

import { RoleCreateComponent } from './role/role-create/role-create.component';
import { RoleInfoComponent } from './role/role-info/role-info.component';
import { RoleListComponent } from './role/role-list/role-list.component';
import { RoleInfoAccessTableComponent } from './role/shared/role-info-access-table/role-info-access-table.component';
import { RoleInfoFormComponent } from './role/shared/role-info-form/role-info-form.component';
import { RoleInfoUserComponent } from './role/shared/role-info-user/role-info-user.component';
import { SystemRoutingModule } from './system-routing.module';
import { ExpandTableModule } from 'src/app/components/table/expand-table.module';

import { CommissionDefaultScaleModule } from './commission-default-scale/commission-default-scale.module';
import { ReportingRoleModule } from './reporting-role/reporting-role.module';
import { AuditHistoryModule } from './audit-history/audit-history.module';
import { CalendarModule } from './calendar/calendar.module';
import { ItSupportModule } from './it-support/it-support.module';
@NgModule({
  declarations: [RoleInfoFormComponent, RoleInfoComponent, RoleInfoUserComponent, RoleListComponent, RoleCreateComponent, RoleInfoAccessTableComponent],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    SystemRoutingModule,

    CdsDropdownModule,
    CdsButtonModule,
    CdsIconModule,
    CdsTableModule,
    CdsLinkModule,
    CdsSearchModule,
    CdsTabsModule,
    CdsCheckboxModule,
    CdsPaginationModule,
    CdsLanguageModule,
    CdsTextfieldModule,
    CdsAssistiveTextModule,
    CdsPopupModule,
    CdsProgressModule,

    SharedModule,
    DataTableModule,
    AppPaginationModule,
    ExpandTableModule,
    CalendarModule,
    CommissionDefaultScaleModule,
    ReportingRoleModule,
    AuditHistoryModule,
    ItSupportModule,
  ],
})
export class SystemModule {}
