import { AfterViewInit, Directive, ElementRef, EventEmitter, OnDestroy, Output } from '@angular/core';

@Directive({
  selector: '[appBottomDetection]',
})
export class BottomDetectionDirective implements AfterViewInit, OnDestroy {
  private isHaveReachedTheBottom = false;
  @Output() arriveBottom = new EventEmitter<void>();
  @Output() haveReachedTheBottom = new EventEmitter<void>();

  constructor(private el: ElementRef<HTMLElement>) {}

  ngAfterViewInit(): void {
    this.el.nativeElement.addEventListener('scroll', this.onScroll);
  }

  onScroll = () => {
    const scrollHeight = this.el.nativeElement.scrollHeight;
    const clientHeight = this.el.nativeElement.clientHeight;

    if (this.el.nativeElement.scrollTop > scrollHeight - clientHeight - 10) {
      this.arriveBottom.emit();

      if (!this.isHaveReachedTheBottom) {
        this.isHaveReachedTheBottom = true;
        this.haveReachedTheBottom.emit();
      }
    }
  };

  ngOnDestroy(): void {
    this.el.nativeElement.removeEventListener('scroll', this.onScroll);
  }
}
