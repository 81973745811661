/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { CdsButtonConfig } from '@cds/ng-core/button';
import { CdsPopupService, MatDialogRef } from '@cds/ng-web-components/popup';
import { BTN_CONFIG_SECONDARY } from 'src/app/config/btn.config';

import { UserAgreementPopupComponent } from 'src/app/views/commission/user-agreement-popup.component';
import { AgentAddAlertComponent } from 'src/app/views/commission/summary/agent/agent-record-add/agent-add-alert.component';
import { CommissionAgentService } from 'src/app/core/services/commission/commission-agent.service';
import { toISODate, fromISO, beforeCurrent } from 'src/app/utils/dateUtils';
import { texts } from 'src/app/views/commission/summary/pool-info.model';
import { deepCopy } from 'src/app/utils/copy';
import Big from 'big.js';

interface Agent {
  id: string;
  agentName: string;
  agentCode: string;
  sellingAgent: boolean;
  sellingAgentWarning?: string;
  shareHkpc: string;
  shareCommission: string;
  saReportDate: string;
  agentCodeError?: string;
  shareHkpcError?: string;
  shareCommissionError?: string;
  saReportDateError?: string;
  saReportDateWarning?: string;
  updateDisplay?: boolean;
}
@Component({
  selector: 'app-agent-record-add',
  templateUrl: './agent-record-add.component.html',
  styleUrls: ['./agent-record-add.component.scss'],
})
export class AgentRecordAddComponent implements OnInit {
  constructor(private cdsPopup: CdsPopupService, private agentService: CommissionAgentService, private router: Router, private route: ActivatedRoute) {}

  commissionPoolId = '';
  currentEffectiveDate = '';
  btnCfg: CdsButtonConfig = BTN_CONFIG_SECONDARY;

  dataSource: Agent[] = [];
  originalAgents: Agent[] = [];

  editForm: any = {
    effectiveDate: '',
  };
  loading = false;
  editStatus = true;
  disableSave = true;
  isErrorDisplay = false;
  warningMsg = '';

  doBackMethod() {
    this.router.navigate(['/commission/pool-info/'], {
      queryParams: {
        commissionPoolId: this.commissionPoolId,
        tabType: 'agents',
      },
    });
  }

  addNew() {
    const popupRef: MatDialogRef<AgentAddAlertComponent> = this.cdsPopup.open(AgentAddAlertComponent, {
      size: 'md',
      data: {
        commissionPoolId: this.commissionPoolId,
        agentList: this.dataSource,
      },
    });
    popupRef.afterClosed().subscribe(confirm => {
      if (confirm && confirm.agree && confirm.agent) {
        this.dataSource.push({
          id: confirm.agent.identityId,
          agentName: confirm.agent.name,
          agentCode: confirm.agent.agentCode,
          sellingAgent: true,
          shareHkpc: '',
          shareCommission: '',
          saReportDate: '',
        });
        this.warningMsg = '';
        this.formValidate();
      }
    });
  }

  update() {
    if (JSON.stringify(this.originalAgents) !== JSON.stringify(this.dataSource)) {
      this.cancelAlert().then(res => {
        if (res.agree) {
          this.editStatus = true;
          this.disableSave = true;
          this.editForm.effectiveDate = this.currentEffectiveDate;
        }
      });
    } else {
      this.editStatus = true;
      this.editForm.effectiveDate = this.currentEffectiveDate;
    }
  }

  delete(i: number) {
    this.dataSource.splice(i, 1);
    this.formChange('', 0);
  }

  closeErrorAlert() {
    this.isErrorDisplay = false;
    this.formValidate();
  }

  formChange(input: any, i: number) {
    this.warningMsg = '';
    const amountPattern = texts.datePattern;
    switch (input) {
      case 'effectiveDate':
        this.editForm.effectiveDateError = '';
        if (!this.editForm.effectiveDate) {
          this.editForm.effectiveDateError = 'commission.pool.agents.create.pleaseInputDate';
          this.formValidate();
          return;
        }
        if (!toISODate(this.editForm.effectiveDate)) {
          this.editForm.effectiveDateError = 'commission.pool.common.invalidEffectiveDate';
          this.formValidate();
          return;
        }
        if (!amountPattern.test(this.editForm.effectiveDate)) {
          this.editForm.effectiveDateError = 'commission.pool.common.invalidEffectiveDate';
          this.formValidate();
          return;
        }
        if (beforeCurrent(this.editForm.effectiveDate)) {
          this.editForm.effectiveDateError = 'commission.pool.agents.create.notBackDate';
          this.formValidate();
          return;
        }
        this.loading = true;
        this.agentService.getRegularList(0, this.commissionPoolId).subscribe(resp => {
          this.loading = false;
          if (resp && resp.result == 0 && resp.data.content) {
            const list: any[] = resp.data.content;
            list.forEach(item => {
              if (fromISO(item.effectiveDate) == this.editForm.effectiveDate && item.active) {
                this.editForm.effectiveDateError = 'Duplicate "Agent effective date"';
                this.formValidate();
                return;
              }
            });
          }
        });
        break;
      case 'item.sellingAgent':
        this.dataSource[i].sellingAgentWarning = '';
        this.originalAgents.forEach(item => {
          if (item.agentCode == this.dataSource[i].agentCode && item.sellingAgent !== this.dataSource[i].sellingAgent) {
            this.dataSource[i].sellingAgentWarning = 'Selling Agent: Different with current record';
          }
        });
        break;
      case 'item.shareHkpc':
        this.dataSource[i].shareHkpcError = '';
        if (!this.dataSource[i].shareHkpc) {
          this.dataSource[i].shareHkpcError = texts.shareHkpcRequired;
        } else {
          this.dataSource[i].shareHkpc = new Big(this.dataSource[i].shareHkpc).toFixed(3);
        }
        break;
      case 'item.shareCommission':
        this.dataSource[i].shareCommissionError = '';
        if (!this.dataSource[i].shareCommission) {
          this.dataSource[i].shareCommissionError = texts.shareCommissionRequired;
        } else {
          this.dataSource[i].shareCommission = new Big(this.dataSource[i].shareCommission).toFixed(3);
        }
        break;
      case 'item.saReportDate':
        this.dataSource[i].saReportDateError = '';
        this.dataSource[i].saReportDateWarning = '';
        if (this.dataSource[i].saReportDate && !amountPattern.test(this.dataSource[i].saReportDate)) {
          this.dataSource[i].saReportDateError = 'Invalid SA Report Rec’d Date';
        } else if (this.dataSource[i].saReportDate && !toISODate(this.dataSource[i].saReportDate)) {
          this.dataSource[i].saReportDateError = 'Invalid SA Report Rec’d Date';
        }
        if (!this.dataSource[i].saReportDateError) {
          this.originalAgents.forEach(item => {
            if (item.agentCode == this.dataSource[i].agentCode && item.saReportDate !== this.dataSource[i].saReportDate)
              this.dataSource[i].saReportDateWarning = 'SA Report Rec‘d Date: Different with current record';
          });
        }
        break;
    }
    this.formValidate();
  }

  formValidate() {
    if (
      this.dataSource.length > 0 &&
      this.editForm.effectiveDate &&
      !this.editForm.effectiveDateError &&
      this.checkAgents() &&
      (JSON.stringify(this.originalAgents) !== JSON.stringify(this.dataSource) || this.currentEffectiveDate !== this.editForm.effectiveDate)
    ) {
      this.disableSave = false;
    } else {
      this.disableSave = true;
    }
  }

  checkAgents() {
    let b = true;
    for (let i = 0; i < this.dataSource.length; i++) {
      if (
        !this.dataSource[i].agentCodeError &&
        !this.dataSource[i].shareHkpcError &&
        !this.dataSource[i].shareCommissionError &&
        !this.dataSource[i].saReportDateError &&
        this.dataSource[i].shareHkpc &&
        this.dataSource[i].shareCommission &&
        !(Number(this.dataSource[i].shareHkpc) == 0 && Number(this.dataSource[i].shareCommission) == 0)
      ) {
        b = true;
      } else {
        b = false;
        break;
      }
    }
    return b;
  }

  reset() {
    this.cancelAlert().then(res => {
      if (res && res.agree) {
        this.disableSave = true;
      }
    });
  }

  cancelAlert(): Promise<any> {
    return new Promise(resolve => {
      const popupRef: MatDialogRef<UserAgreementPopupComponent> = this.cdsPopup.open(UserAgreementPopupComponent, {
        data: {
          message: texts.cancelMsg,
          cancel: texts.cancel,
          continue: texts.continue,
          type: 'confirm',
        },
      });
      popupRef.afterClosed().subscribe(confirm => {
        if (confirm && confirm.agree) {
          this.editForm.effectiveDate = '';
          this.editForm.effectiveDateError = '';
          this.dataSource = deepCopy(this.originalAgents);
          this.warningMsg = '';
          resolve({ agree: true });
        }
        resolve({ agree: false });
      });
    });
  }

  backToEdit() {
    this.editStatus = true;
  }

  save() {
    if (this.disableSave) {
      return;
    }
    const list: any[] = this.dataSource;
    let shareHkpc = new Big(0);
    let shareCommission = new Big(0);
    list.forEach(item => {
      if (item.shareHkpc) {
        shareHkpc = shareHkpc.plus(new Big(item.shareHkpc));
      }
      if (item.shareCommission) {
        shareCommission = shareCommission.plus(new Big(item.shareCommission));
      }
    });
    if (!shareHkpc.eq(new Big(100)) || !shareCommission.eq(new Big(100))) {
      this.isErrorDisplay = true;
      if (document && document.getElementById(`errorDisplay`)) {
        const errorDisplay = document.getElementById(`errorDisplay`);
        if (errorDisplay) {
          errorDisplay.scrollIntoView();
          this.disableSave = true;
        }
      }
    } else {
      this.isErrorDisplay = false;
      this.editStatus = false;
      if (JSON.stringify(this.dataSource) == JSON.stringify(this.originalAgents)) {
        this.warningMsg = 'The commission agent combination is same as previous, please note';
      }
      const l1: any[] = [];
      const l2: any[] = [];
      this.originalAgents.forEach(i => {
        this.dataSource.forEach(j => {
          if (i.agentCode == j.agentCode) {
            l1.push({ date: i.saReportDate, sellingAgent: i.sellingAgent });
            l2.push({ date: j.saReportDate, sellingAgent: j.sellingAgent });
          }
        });
      });
      if (l1.length > 0 && l2.length > 0 && JSON.stringify(l1) != JSON.stringify(l2)) {
        this.warningMsg = 'commission.pool.agents.create.saChangeWarning';
      }
    }
  }

  confirm() {
    const form: any = {};
    form.commissionPoolId = this.commissionPoolId;
    form.effectiveDate = toISODate(this.editForm.effectiveDate);
    const agents: Agent[] = [];
    this.loading = true;
    this.dataSource.forEach(item => {
      const agent = {
        id: item.id,
        agentName: item.agentName,
        agentCode: item.agentCode,
        sellingAgent: item.sellingAgent,
        shareHkpc: item.shareHkpc,
        shareCommission: item.shareCommission,
        saReportDate: item.saReportDate ? toISODate(item.saReportDate) : '',
      };
      agents.push(agent as any);
    });
    form.agents = agents;
    this.agentService.addAgent(true, form).subscribe(resp => {
      this.loading = false;
      let popData, result: number;
      if (resp && resp.result == 0) {
        popData = {
          message: texts.alertSuccessMsg,
          continue: texts.alertSuccessContinue,
          type: 'alert',
        };
        result = resp.result;
      } else {
        popData = {
          message: texts.alertFailedMsg,
          continue: texts.alertFailedContinue,
          type: 'alert',
          params: { respMsg: resp.message },
        };
        result = resp.result;
      }
      const popupRef: MatDialogRef<UserAgreementPopupComponent> = this.cdsPopup.open(UserAgreementPopupComponent, { data: popData });
      popupRef.afterClosed().subscribe(confirm => {
        if (confirm.agree) {
          if (result == 0) {
            this.router.navigate(['/commission/pool-info/'], {
              queryParams: {
                commissionPoolId: this.commissionPoolId,
                tabType: 'agents',
              },
            });
          } else {
            this.backToEdit();
          }
        }
      });
    });
  }

  ngOnInit(): void {
    this.commissionPoolId = this.route.snapshot.queryParams['commissionPoolId'];
    const agentDetailId = this.route.snapshot.queryParams['agentDetailId'];
    this.currentEffectiveDate = this.route.snapshot.queryParams['currentEffectiveDate'];
    this.loading = true;
    this.agentService
      .getRegularItems({
        id: agentDetailId,
        commissionPoolId: this.commissionPoolId,
      })
      .subscribe(resp => {
        this.loading = false;
        if (resp && resp.result == 0 && resp.data.content) {
          const agentItems: any[] = resp.data.content;
          agentItems.forEach(item => {
            this.dataSource.push({
              id: item.id,
              agentName: item.agentName,
              agentCode: item.agentCode,
              sellingAgent: item.sellingAgent,
              shareHkpc: new Big(item.shareHkpc).toFixed(3),
              shareCommission: new Big(item.shareCommission).toFixed(3),
              saReportDate: item.saReportDate ? fromISO(item.saReportDate) : '',
              updateDisplay: true,
            });
          });
          this.originalAgents = deepCopy(this.dataSource);
        }
      });
  }
}
