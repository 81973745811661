import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { EMPF_APP_STATUS } from 'src/app/config/empf-app-status.config';

@Component({
  selector: 'app-empf-app-status-popup',
  templateUrl: './empf-app-status-popup.component.html',
  styleUrls: ['./empf-app-status-popup.component.scss'],
})
export class EmpfAppStatusPopupComponent implements OnInit {
  EMPF_APP_STATUS = EMPF_APP_STATUS;

  constructor(private dialogRef: MatDialogRef<EmpfAppStatusPopupComponent>) {}

  ngOnInit(): void {}

  cancel() {
    this.dialogRef.close();
  }
}
