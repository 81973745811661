import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors } from '@angular/forms';
import { minValidator } from 'src/app/shared/validators/validators';

@Directive({
  selector: '[appMinValidator]',
  providers: [{ provide: NG_VALIDATORS, useExisting: MinValidatorDirective, multi: true }],
})
export class MinValidatorDirective {
  @Input('appMinValidator') min = 0;

  validate(control: AbstractControl): ValidationErrors | null {
    return minValidator(this.min)(control);
  }
}
