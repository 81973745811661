/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

export interface CdDropdownTopping {
  label: string;
  value: string;
}

@Component({
  selector: 'app-cd-dropdown',
  templateUrl: './cd-dropdown.component.html',
  styleUrls: ['./cd-dropdown.component.scss'],
})
export class CdDropdownComponent implements OnInit {
  @Input() group!: FormGroup;
  @Input() controlName = '';
  @Input() label = '';
  @Input() placeholder = '';
  @Input() toppingList: CdDropdownTopping[] = [];
  @Input() disabled = false;
  private _selected?: string;
  @Input()
  set selected(selected: string) {
    this._selected = selected;
    this.valueControl.setValue(this._selected);
  }
  @Output() selectedChange = new EventEmitter<any>();
  valueControl = new FormControl('');
  open = false;
  toppings: CdDropdownTopping[] = [];
  constructor() {}

  ngOnInit(): void {
    this.valueControl.valueChanges.subscribe(() => {
      this.selectedChange.emit(this.valueControl.value);
    });

    if (this.group) {
      this.group.controls[this.controlName].valueChanges.subscribe(() => {
        this.selectedChange.emit(this.valueControl.value);
      });
    }
  }

  get showVaule() {
    const value = this.group ? this.group.controls[this.controlName].value : this.valueControl.value;
    const option = this.toppingList.filter(item => item.value === value);
    if (option.length > 0) {
      return option[0].label;
    }
    return '';
  }

  blur() {
    setTimeout(() => {
      if (document.activeElement instanceof HTMLElement) {
        document.activeElement.blur();
      }
    }, 0);
  }

  clearValue() {
    this.valueControl.setValue('');
  }
}
