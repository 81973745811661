import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CdsIconConfig } from '@cds/ng-core/icon';
import { CdsLangService } from '@cds/ng-core/lang';
import { CdsAlertService } from '@cds/ng-web-components/alert';
import { CdsPopupService } from '@cds/ng-web-components/popup';
import { CdsToastService } from '@cds/ng-web-components/toast';
import { finalize, Subscription } from 'rxjs';
import { IPageableRequest } from 'src/app/core/models/request';
import { ResponseResult } from 'src/app/core/models/response/response-result';
import { LayoutHeaderService } from 'src/app/core/services/layout-header.service';
import { AlertPopupComponent } from 'src/app/shared/alert-popup/alert-popup.component';
import { IPaginationCount } from 'src/app/shared/pagination/pagination.dto';
import { toPageableRequest } from 'src/app/utils/pagination.utils';
import { AdminHeaderConfig } from '../../layout/admin-layout/admin-header/admin-header-config';
import { NotificationDataService } from '../notificationlist/notification-data.service';
import { AudienceList } from '../notificationlist/notificationmodel';
import { UploadAudienceListFileComponent } from '../upload-audience-list-file/upload-audience-list-file.component';

@Component({
  selector: 'app-manage-audience-list',
  templateUrl: './manage-audience-list.component.html',
  styleUrls: ['./manage-audience-list.component.scss'],
})
export class ManageAudienceListComponent implements OnInit, OnDestroy {
  totalElements = 0;

  isLoading = false;

  showProcessingMessage = false;

  audienceFormControl = new FormControl('List 1');

  dataSource: AudienceList[] = [];

  displayedColumns: string[] = ['clientId'];

  pageCount: IPaginationCount = { current: 1, count: 1 };

  fileName = '';

  headerButton: AdminHeaderConfig = {
    title: 'notification.audience.manage',
    backButton: {
      text: 'notification.list',
      url: '/mobile-subscription/list',
    },
  };

  errorMessageIconConfig: CdsIconConfig = {
    color: '#a75900',
    size: 'xs',
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  timmer?: any;

  getFileNameSubscription?: Subscription;

  getAudienceListSubscription?: Subscription;

  constructor(
    private cdsPopup: CdsPopupService,
    private route: ActivatedRoute,
    public notificationServer: NotificationDataService,
    private langService: CdsLangService,
    private alert: CdsAlertService,
    private toast: CdsToastService,
    private layoutHeaderService: LayoutHeaderService
  ) {}

  ngOnInit(): void {
    this.layoutHeaderService.setHeaderButton(this.headerButton);
    const queryParamsValue = this.route.snapshot.queryParams['value'];
    if (queryParamsValue) {
      this.audienceFormControl.setValue(queryParamsValue);
      window.history.replaceState(null, '', window.location.pathname);
    }
    this.loadTable();
  }

  loadTable() {
    if (!this.isLoading) {
      const params: IPageableRequest = toPageableRequest(this.pageCount, 10);
      this.isLoading = true;
      this.getFileName();
      this.getAudienceListSubscription = this.notificationServer
        .getAudienceList({
          ...params,
          listName: this.audienceFormControl.value,
          sort: 'orderIndex',
        })
        .pipe(
          finalize(() => {
            this.isLoading = false;
          })
        )
        .subscribe({
          next: res => {
            if (res.result === 0 && res.data) {
              this.showProcessingMessage = false;
              this.totalElements = res.data.totalElements || 0;
              this.dataSource = res.data.content || [];
              if (this.pageCount.current != res.data.number + 1) {
                this.pageCount.current = res.data.number + 1;
              }
              if (this.pageCount.count !== res.data.totalPages) {
                this.pageCount.count = res.data.totalPages;
              }
              this.pageCount = { ...this.pageCount };
            } else if (res.result === ResponseResult.PROCESSING) {
              this.showProcessingMessage = true;
              this.totalElements = 0;
              this.timmer = setTimeout(() => {
                this.initLoad();
              }, 60000);
            }
          },
          error: () => {
            this.dataSource = [];
          },
        });
    }
  }

  getFileName() {
    this.fileName = '';
    this.getFileNameSubscription = this.notificationServer.getFileName(this.audienceFormControl.value).subscribe({
      next: res => {
        if (res.result === 0) {
          this.fileName = res.data;
        } else {
          this.toast.error(res.message);
        }
      },
      error: err => {
        console.error('Call audience-list API error: ', err);
      },
    });
  }

  upload() {
    this.cdsPopup
      .open(UploadAudienceListFileComponent, {
        size: 'md',
        data: {
          audienceList: this.audienceFormControl.value,
        },
      })
      .afterClosed()
      .subscribe(result => {
        if (result?.agree) {
          this.popupWithReload();
        } else {
          this.isLoading = false;
        }
      });
  }

  initLoad() {
    this.clearTimer();
    this.clearSubscriptions();
    this.isLoading = false;
    this.pageCount = { current: 1, count: 1 };
    this.dataSource = [];
    this.loadTable();
  }

  popupWithReload() {
    this.cdsPopup
      .open(AlertPopupComponent, {
        size: 'sm',
        data: {
          message: this.langService.translate('common.action.uploadSuccess'),
          buttonName: this.langService.translate('common.gotItWithExclamation'),
        },
      })
      .afterClosed()
      .subscribe(() => {
        this.initLoad();
      });
  }

  requestAlert(message: string) {
    this.alert.error('Got Error', message);
  }

  clearTimer() {
    if (this.timmer) {
      clearTimeout(this.timmer);
    }
  }

  clearSubscriptions() {
    if (this.getFileNameSubscription) {
      this.getFileNameSubscription.unsubscribe();
      this.getFileNameSubscription = undefined;
    }
    if (this.getAudienceListSubscription) {
      this.getAudienceListSubscription.unsubscribe();
      this.getAudienceListSubscription = undefined;
    }
  }

  ngOnDestroy(): void {
    this.clearTimer();
    this.clearSubscriptions();
  }
}
