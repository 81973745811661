import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AnnuncementCenterComponent } from './annuncement-center.component';
import { CdsButtonModule } from '@cds/ng-web-components/button';
import { SharedModule } from 'src/app/shared/shared.module';
import { CdsSwitchModule } from '@cds/ng-web-components/switch';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { CdsIconModule } from '@cds/ng-web-components/icon';
import { CdsLanguageModule } from '@cds/ng-core/lang';

@NgModule({
  declarations: [AnnuncementCenterComponent],
  imports: [
    CommonModule,
    CdsButtonModule,
    SharedModule,
    CdsSwitchModule,
    FormsModule,
    ReactiveFormsModule,
    MatSlideToggleModule,
    CdsIconModule,
    CdsLanguageModule,
  ],
})
export class AnnuncementCenterModule {}
