/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CdsOption } from '@cds/ng-core/configuration';
import { CdsIconConfig } from '@cds/ng-core/icon';

@Component({
  selector: 'app-drop-down',
  templateUrl: './drop-down.component.html',
  styleUrls: ['./drop-down.component.scss'],
})
export class DropDownComponent implements OnInit {
  @Input() field!: any;
  @Input() options: CdsOption[] = [];
  @Input() nullable = true;
  @Input() locked = false;
  @Input() label = '';
  @Input() placeholder = '';
  @Output() valueChange = new EventEmitter();
  iconCfg: CdsIconConfig = { color: '#8E90A2' };

  constructor() {}

  ngOnInit(): void {}

  clearSelection() {
    this.field = '';
    this.valueChange.emit(this.field);
  }

  change(event: any) {
    this.field = event;
    this.valueChange.emit(this.field);
  }
}
