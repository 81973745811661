<app-cd-popup>
  <cds-popup-title
    ><span class="cds-h2-light">{{ 'calendar.action.declined' | lang }}</span></cds-popup-title
  >
  <cds-popup-content>
    <div class="content l-pl-1 l-pr-3">
      <div class="l-pb-5">
        <div class="row l-plr-0">
          <div class="col-xs-12 col-md-12 l-plr-0 l-pb-3 cds-body2-demibold">{{ 'calendar.event.name' | lang }}</div>
          <div class="col-xs-12 col-md-12 l-plr-0 cds-demibold l-pb-3 cds-h4">{{ title }}</div>
        </div>
        <form [formGroup]="formGroup" class="row l-plr-0">
          <div class="col-md-12 cds-white l-plr-0">
            <div class="row l-plr-0 div-border-top div-border-bottom cds-body1-demibold">
              <div *ngIf="originalDate" class="col-xs-3 col-md-3 l-pb-2 l-pt-2 l-pl-3 l-pr-3 l-pl-2">{{ 'calendar.event.originalDate' | lang }}</div>
              <div class="col-xs-3 col-md-3 l-pb-2 l-pt-2 l-pl-3 l-pr-3 l-d-f l-as-cen">
                <cds-icon *ngIf="originalDate" icon="arrow:right"></cds-icon><span class="l-pl-2">{{ 'calendar.event.submittedDate' | lang }}</span>
              </div>
              <div class="col-xs-6 col-md-6 l-pb-2 l-pt-2 l-pl-3 l-pr-3 l-d-f l-as-cen">
                <cds-icon icon="arrow:right"></cds-icon><span class="l-pl-2">{{ 'calendar.event.reviseDate' | lang }}</span>
              </div>
            </div>
            <div class="row l-plr-0 cds-white div-border-bottom cds-body1 l-mb-0">
              <div *ngIf="originalDate" class="col-xs-3 col-md-3 l-pb-4 l-pt-4 l-pl-3 l-pr-3 l-pl-2">{{ originalDate | date : 'dd/MM/yyyy' }}</div>
              <div class="col-xs-3 col-md-3 l-pb-4 l-pt-4 l-pl-3 l-pr-3">
                <div class="l-d-f l-as-cen">
                  <cds-icon *ngIf="originalDate" icon="arrow:right"></cds-icon>
                  <span class="l-pl-2" *ngIf="!isDelete">{{ newDate | date : 'dd/MM/yyyy' }}</span>
                  <span class="l-pl-2" *ngIf="isDelete">{{ newDate | lang }}</span>
                </div>
              </div>
              <div class="col-xs-6 col-md-6 l-pb-5 l-pt-4 l-pl-3 l-pr-3 l-d-f l-as-cen">
                <div class="l-pr-3"><cds-icon icon="arrow:right"></cds-icon></div>
                <div>
                  <cds-textfield placeholder="DD/MM/YYYY" [cdsDatepicker]="picker" formControlName="reviseEventDate"> </cds-textfield>
                  <cds-datepicker #picker="cdsDatepicker"></cds-datepicker>
                  <cds-assistive-text class="l-pt-4" *ngIf="reviseEventDate?.errors && reviseEventDate?.dirty">
                    {{ reviseEventDate.errors?.['error'] | lang}}
                  </cds-assistive-text>
                </div>
              </div>
            </div>
          </div>
          <div class="l-pt-5 cds-body2-demibold">
            <cds-checkbox label="{{ 'calendar.event.withdrawDate' | lang }}" formControlName="withdrawEventChange"></cds-checkbox>
          </div>
        </form>
      </div>
    </div>
  </cds-popup-content>
  <cds-popup-actions>
    <cds-button [style]="'secondary'" size="sm" (click)="cancle()">{{ 'common.cancel' | lang }}</cds-button>
    <cds-button size="sm" [disabled]="disabled" (click)="reSubmit()">{{ 'common.resubmit' | lang }}</cds-button>
  </cds-popup-actions>
</app-cd-popup>
