<cds-popup>
  <cds-popup-title></cds-popup-title>
  <cds-popup-content class="cds-h3-light">
    {{ 'delegationChange.searchClearAlertDialogWarningPart1' | lang }}
  </cds-popup-content>
  <cds-popup-content class="cds-h2-ita">
    {{ 'delegationChange.searchClearAlertDialogWarningPart2' | lang }}
  </cds-popup-content>
  <cds-popup-content class="cds-h3-light">
    {{ 'delegationChange.searchClearAlertDialogWarningPart3' | lang }}
  </cds-popup-content>
  <cds-popup-actions>
    <cds-button [config]="disagreeButtonConfig" (click)="disagree()" label="{{ 'confirmation.cancel' | lang }}"></cds-button>
    <cds-button [config]="agreeButtonConfig" (click)="agree()" label="{{ 'delegationChange.continue' | lang }}"></cds-button>
  </cds-popup-actions>
</cds-popup>
