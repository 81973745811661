import { Injectable } from '@angular/core';
import { ArchitectUIState } from './index';

@Injectable()
export class ConfigActions {
  static CONFIG_GET = 'CONFIG_GET';
  static CONFIG_UPDATE = 'CONFIG_UPDATE';

  constructor() {}

  getConfig() {
    // this.ngRedux.dispatch({
    //   type: ConfigActions.CONFIG_GET,
    // });
  }

  updateConfig(config: any): void {
    // this.ngRedux.dispatch({
    //   type: ConfigActions.CONFIG_UPDATE,
    //   payload: config
    // });
  }
}
