<div class="row">
  <div class="col-xs-12 l-pt-5 cds-color-green">
    <span class="cds-h5-demibold">{{ 'er.profile.payroll-details' | lang }}</span>
  </div>
  <div class="col-xs-12">
    <div class="p-line"></div>
  </div>
</div>

<div class="row">
  <div class="col-xs-10 row l-plr-0 l-pt-2 l-pb-2">
    <div class="col-xs-4">
      <span class="cds-body2">{{ 'payment.contribution-frequency' | lang }}</span>
    </div>
    <div class="col-xs-4">
      <span class="cds-body1">{{ _frequencyType(data?.contributionFrequency) }}</span>
    </div>
  </div>
  <ng-container *ngIf="isStaff">
    <div class="col-xs-10 row l-plr-0 l-pt-2 l-pb-2">
      <div class="col-xs-4">
        <span class="cds-body2">{{ 'payment.first-contribution-date' | lang }}</span>
      </div>
      <div class="col-xs-4">
        <span class="cds-body1">{{ data?.firstContributionDate | ssmsDate }}</span>
      </div>
    </div>
    <div class="col-xs-10 row l-plr-0 l-pt-2 l-pb-2">
      <div class="col-xs-4">
        <span class="cds-body2">{{ 'payment.second-contribution-date' | lang }}</span>
      </div>
      <div class="col-xs-4">
        <span class="cds-body1">{{ data?.secondContributionDate | ssmsDate }}</span>
      </div>
    </div>
  </ng-container>
</div>

<div class="row">
  <div class="col-xs-12 l-pt-5 cds-color-green">
    <span class="cds-h5-demibold">{{ 'payment.dda-bank-account-info' | lang }}</span>
  </div>
  <div class="col-xs-12">
    <div class="p-line"></div>
  </div>
</div>

<div class="row">
  <div class="col-xs-10 row l-plr-0 l-pt-2 l-pb-2">
    <div class="col-xs-4">
      <span class="cds-body2">{{ 'payment.default-payment-method' | lang }}</span>
    </div>
    <div class="col-xs-4">
      <span class="cds-body1">{{ data?.ddaDefaultPaymentMethodFlag === 'Y' ? 'Yes' : 'No' }}</span>
    </div>
  </div>
  <div class="col-xs-10 row l-plr-0 l-pt-2 l-pb-2">
    <div class="col-xs-4">
      <span class="cds-body2">{{ 'payment.status' | lang }}</span>
    </div>
    <div class="col-xs-4">
      <span class="cds-body1">{{ getType(data?.status, _ddlCodeMap) }}</span>
    </div>
  </div>
  <!-- <div class="col-xs-10 row l-plr-0 l-pt-2 l-pb-2">
    <div class="col-xs-4">
      <span class="cds-body2">{{ 'payment.limit-mode' | lang }}</span>
    </div>
    <div class="col-xs-4">
      <span class="cds-body1">{{ data?.limitMod }}</span>
    </div>
  </div> -->
</div>

<ng-container *ngIf="isStaff">
  <div class="row l-pt-5">
    <div class="col-xs-10">
      <div class="col-xs-12 row middle-xs basic-pad">
        <div class="l-mr-2">
          <cds-icon color="#282B3E" width="20px" height="20px" icon="finance:bank"></cds-icon>
        </div>
        <div class="l-pt-1">
          <span class="cds-body1-demibold">{{ 'payment.bank-infomation' | lang }}</span>
        </div>
      </div>
      <div class="col-xs-12 row detail-pad">
        <div class="col-xs-12">
          <div class="col-xs-12 l-pt-4 l-pb-4 row">
            <div class="col-xs-12 cds-color-navy-light3">
              <span class="cds-body2">{{ 'payment.bank-holder-name' | lang }}</span>
            </div>
            <div class="col-xs-12 l-pt-2">
              <span class="cds-body1">{{ data?.bankHolderName }}</span>
            </div>
          </div>
        </div>
        <div class="col-xs-12 row">
          <div class="col-xs l-pt-4 l-pb-4">
            <div class="col-xs-12 cds-color-navy-light3">
              <span class="cds-body2">{{ 'payment.bank-code' | lang }}</span>
            </div>
            <div class="col-xs-12 l-pt-2">
              <span class="cds-body1">{{ data?.bankCode }}</span>
            </div>
          </div>
          <div class="col-xs l-pt-4 l-pb-4">
            <div class="col-xs-12 cds-color-navy-light3">
              <span class="cds-body2">{{ 'payment.branch-number-bank' | lang }}</span>
            </div>
            <div class="col-xs-12 l-pt-2">
              <span class="cds-body1">{{ data?.branchCodeOfBank }}</span>
            </div>
          </div>
          <div class="col-xs l-pt-4 l-pb-4">
            <div class="col-xs-12 cds-color-navy-light3">
              <span class="cds-body2">{{ 'payment.bank-account-no' | lang }}</span>
            </div>
            <div class="col-xs-12 l-pt-2">
              <span class="cds-body1">{{ data?.bankAccountNumber }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row l-pt-5">
    <div class="col-xs-10 row">
      <div class="l-mr-6">
        <span class="cds-body1-demibold">{{ 'payment.debtor-reference' | lang }}</span>
      </div>
    </div>
    <div class="col-xs-10 p-line"></div>
  </div>

  <div class="row">
    <div class="col-xs-10 row l-plr-0">
      <div class="col-xs-6 col-sm-3 col-md-3 col-lg-3 l-pt-2 l-pb-2">
        <div class="cds-color-navy-light3">
          <span class="cds-body2">{{ 'payment.debtor-reference-type' | lang }}</span>
        </div>
        <div class="l-pt-1">
          <span class="cds-body1">{{ getType(data?.debtorReferenceType, _debtorMap) }}</span>
        </div>
      </div>
      <div class="col-xs-6 col-sm-3 col-md-3 col-lg-3 l-pt-2 l-pb-2">
        <div class="cds-color-navy-light3">
          <span class="cds-body2">{{ 'payment.debtor-reference-number' | lang }}</span>
        </div>
        <div class="l-pt-1">
          <span class="cds-body1">{{ data?.debtorReferenceNumber }}</span>
        </div>
      </div>
    </div>
  </div>

  <div class="row l-pt-5">
    <div class="col-xs-10 row">
      <div class="l-mr-6">
        <span class="cds-body1-demibold">{{ 'payment.dda-status' | lang }}</span>
      </div>
    </div>
    <div class="col-xs-10 p-line"></div>
  </div>

  <div class="row">
    <div class="col-xs-10 row l-plr-0">
      <div class="col-xs-6 col-sm-3 col-md-3 col-lg-3 l-pt-2 l-pb-2">
        <div class="cds-color-navy-light3">
          <span class="cds-body2">{{ 'payment.last-update-date' | lang }}</span>
        </div>
        <div class="l-pt-1">
          <span class="cds-body1">{{ data?.lastUpdateDateOfDdaStatus | ssmsDate }}</span>
        </div>
      </div>
      <div class="col-xs-6 col-sm-3 col-md-3 col-lg-3 l-pt-2 l-pb-2">
        <div class="cds-color-navy-light3">
          <span class="cds-body2">{{ 'payment.debit-day' | lang }}</span>
        </div>
        <div class="l-pt-1">
          <span class="cds-body1">{{ data?.debitDay }}</span>
        </div>
      </div>
      <div class="col-xs-6 col-sm-3 col-md-3 col-lg-3 l-pt-2 l-pb-2">
        <div class="cds-color-navy-light3">
          <span class="cds-body2">{{ 'payment.maximum-limit' | lang }}</span>
        </div>
        <div class="l-pt-1">
          <span class="cds-body1">{{ data?.paymentMaximumLimit | number }}</span>
        </div>
      </div>
      <div class="col-xs-6 col-sm-3 col-md-3 col-lg-3 l-pt-2 l-pb-2">
        <div class="cds-color-navy-light3">
          <span class="cds-body2">{{ 'payment.dda-expiry-date' | lang }}</span>
        </div>
        <div class="l-pt-1">
          <span class="cds-body1">{{ data?.ddaExpiryDate | ssmsDate }}</span>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<div class="row l-pt-5"></div>
<!-- 
<div class="row">
  <div class="col-xs-12 l-pt-5 cds-color-green">
    <span class="cds-h5-demibold">{{ 'payment.third-Party-cheque' | lang }}</span>
  </div>
  <div class="col-xs-12">
    <div class="p-line"></div>
  </div>
</div>

<div class="row">
  <div class="col-xs-10 row l-plr-0">
    <div class="col-xs-6 col-sm-3 col-md-3 col-lg-3 l-pt-2 l-pb-2">
      <div class="cds-color-navy-light3">
        <span class="cds-body2">{{ 'payment.bank-no' | lang }}</span>
      </div>
      <div class="l-pt-1">
        <span class="cds-body1">{{ data?.bankNo }}</span>
      </div>
    </div>
    <div class="col-xs-6 col-sm-3 col-md-3 col-lg-3 l-pt-2 l-pb-2">
      <div class="cds-color-navy-light3">
        <span class="cds-body2">{{ 'payment.branch-no' | lang }}</span>
      </div>
      <div class="l-pt-1">
        <span class="cds-body1">{{ data?.branchNo }}</span>
      </div>
    </div>
    <div class="col-xs-6 col-sm-3 col-md-3 col-lg-3 l-pt-2 l-pb-2">
      <div class="cds-color-navy-light3">
        <span class="cds-body2">{{ 'payment.account-no' | lang }}</span>
      </div>
      <div class="l-pt-1">
        <span class="cds-body1">{{ data?.accountNo }}</span>
      </div>
    </div>
  </div>
</div> -->
