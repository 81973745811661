<div class="cd-waring-popup-box">
  <div class="cd-waring-popup">
    <span class="cds-body-hero">{{ data.title | lang }}</span>
    <div class="l-mt-4">
      <div *ngFor="let msg of data.messages" class="cds-body-hero cds-body1 l-mb-0">
        {{ msg }}
      </div>
    </div>
    <div class="botton-list">
      <cds-button *ngFor="let button of data.buttons" [style]="button.style" [size]="button.size" (click)="button.cb()">{{ button.text | lang }}</cds-button>
    </div>
  </div>
  <!-- <cds-icon
    *ngIf="data.isShowCloseIcon"
    class="close-icon"
    icon="action:cross"
    (click)="close()"></cds-icon> -->
</div>
