import { Component, Input, OnInit } from '@angular/core';
import { CdsButtonConfig } from '@cds/ng-core/button';
import { CdsCheckboxConfig } from '@cds/ng-core/checkbox';
import { PermissionAccess, PermissionItem } from 'src/app/core/models/enum/permission.enum';
import { PermissionService } from 'src/app/core/services/permission.service';
import { AllocationMethodE, ForfeitureHandlingE, Fund, FundPercent, SchemeInfo } from '../../employer';

@Component({
  selector: 'app-invested-funds-info',
  templateUrl: './invested-funds-info.component.html',
  styleUrls: ['./invested-funds-info.component.scss'],
})
export class InvestedFundsInfoComponent implements OnInit {
  isStaff = false;

  constructor(private permissionService: PermissionService) {}

  @Input() schemeInfo?: SchemeInfo;

  noConfig: CdsCheckboxConfig = {
    disabled: true,
  };

  hasConfig: CdsCheckboxConfig = {
    disabled: true,
    checked: true,
  };

  config: CdsButtonConfig = {
    size: 'sm',
    style: 'secondary',
  };

  ngOnInit(): void {
    this.permissionFilter();
  }

  permissionFilter() {
    this.permissionService.hasPermission(PermissionAccess.R, PermissionItem.CUSTOMER_EMPLOYER).then(res => {
      this.isStaff = res;
    });
  }

  expandAll() {
    this.schemeInfo?.fundList?.forEach(item => {
      item.show = !item.show;
    });
  }

  forfeitureHandlingRender(type: string) {
    return ForfeitureHandlingE[type as keyof typeof ForfeitureHandlingE] || '';
  }

  allocationMethodRender(type: string) {
    return AllocationMethodE[type as keyof typeof AllocationMethodE] || '';
  }

  keyListOfFundPercent(obj?: FundPercent) {
    if (!obj) return [];
    return Object.keys(obj).filter(key => obj[key] !== null);
  }

  fundPercentNumber(obj: FundPercent, key: string) {
    return obj[key];
  }

  fundPercentLabel(key: string) {
    switch (key) {
      case 'bonusFtr':
        return 'Bonus Ftr';
      case 'eemc':
        return 'FSEE/EEMC';
      case 'ermc':
        return 'FSER/ERMC';
      case 'eevc':
        return 'PSEE/EEVC';
      case 'ervc':
        return 'PSER/ERVC';
    }
    return key.toUpperCase();
  }

  showInvestedFundsInformation(activeLives: number, fundList: Fund[], isStaff: boolean) {
    if (!fundList?.length) {
      return false;
    }

    if (isStaff) {
      return true;
    }

    if (!isStaff && activeLives > 1) {
      return true;
    }

    return false;
  }

  gotoPrivilegeRatesDetails() {}
}
