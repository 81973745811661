/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, AbstractControl } from '@angular/forms';

import moment from 'moment';

import { CalendarService } from 'src/app/views/system/calendar/calendar.service';
import { PermissionService } from 'src/app/core/services/permission.service';
import { ResponseResult } from 'src/app/core/models/response/response-result';
import { PermissionAccess, PermissionItem } from 'src/app/core/models/enum/permission.enum';

import { I18N_KEY_FOR_API_RES, I18N_KEY } from '../calendar.config';
import { CdPopupService, MatDialogRef, CDS_POPUP_DATA, CdPopupType } from 'src/app/shared/cd-popup';
import { requiredValidator, dateValidator, typhoonDateValiator } from 'src/app/core/validators';
import { ActionEnum } from 'src/app/core/models/enum';

@Component({
  selector: 'app-event-details',
  templateUrl: './event-details.component.html',
  styleUrls: ['./event-details.component.scss'],
})
export class EventDetailsComponent implements OnInit {
  permissionAccess = PermissionAccess;
  permissionItem = PermissionItem;
  hasPermission = false;
  isEditAble = true;
  isShowDatePicker = true;
  formGroup: FormGroup;

  constructor(
    private fb: FormBuilder,
    private cdsPopup: CdPopupService,
    private calendarService: CalendarService,
    private dialogRef: MatDialogRef<EventDetailsComponent>,
    @Inject(CDS_POPUP_DATA)
    public data: {
      event: any;
    },
    private permissionService: PermissionService
  ) {
    const { end, editable } = data.event;
    this.isEditAble = editable;
    this.formGroup = this.fb.group({
      eventDate: new FormControl(
        {
          value: moment(end, 'DD/MM/YYYY').format('DD/MM/YYYY'),
          disabled: !this.isEditAble,
        },
        {
          initialValueIsDefault: true,
          validators: [
            requiredValidator({ error: I18N_KEY.DATE_REQUIRED }),
            dateValidator({ error: I18N_KEY.INVALID_DATE_FORMAT }),
            typhoonDateValiator({ error: I18N_KEY.INVALID_TYPHOON_DATE }),
          ],
        }
      ),
    });
  }

  ngOnInit(): void {
    this.permissionService.hasPermission(PermissionAccess.W, PermissionItem.SYS_CALENDAR).then(hasPermission => {
      this.hasPermission = hasPermission;
    });
  }

  get eventDate() {
    return this.formGroup.get('eventDate') as AbstractControl;
  }

  get saveDisabled() {
    return !this.isEditAble || this.eventDate.invalid || this.eventDate.value === moment(this.data.event.end, 'DD/MM/YYYY').format('DD/MM/YYYY');
  }

  get resetDisabled() {
    return this.eventDate.value === moment(this.data.event.end, 'DD/MM/YYYY').format('DD/MM/YYYY');
  }

  returnToNormal() {
    const popupRef: MatDialogRef<any> = this.cdsPopup.openCommon({
      type: CdPopupType.CONTINUE,
      data: {
        message: I18N_KEY.EVENT_RETURN_CONFIRM,
        continue: I18N_KEY.CALENDAR_CONFIRM,
      },
    });
    popupRef.afterClosed().subscribe(res => {
      if (res.continue) this.returnToNormalRequest(this.data.event);
    });
  }

  returnToNormalRequest(eventToDelete: any) {
    const rst$ = this.calendarService.returnToNormal({
      eventDate: eventToDelete.eventStr,
    });
    rst$.subscribe(res => {
      if (res.result === ResponseResult.SUCCESS) {
        const popupRef: MatDialogRef<any> = this.cdsPopup.openCommon();
        popupRef.afterClosed().subscribe(() => {
          this.dialogRef.close({
            event: this.data.event,
            actionType: ActionEnum.DELETE,
          });
        });
      } else {
        this.cdsPopup.openCommon({
          data: {
            message: I18N_KEY_FOR_API_RES[res.message] || res.message,
          },
        });
      }
    });
  }

  reset() {
    if (this.resetDisabled) {
      return;
    }
    this.isShowDatePicker = false;
    setTimeout(() => {
      this.isShowDatePicker = true; // fix datePicker bug
    });
    const popupRef: MatDialogRef<any> = this.cdsPopup.openCommon({
      type: CdPopupType.CONTINUE,
      data: {
        message: I18N_KEY.COMMON_ACTION_CANCLE,
      },
    });
    popupRef.afterClosed().subscribe(confirm => {
      if (confirm.continue) {
        this.formGroup.reset();
      }
    });
  }

  createParams() {
    return {
      commitId: this.data.event.commitId,
      eventDate: this.calendarService.formatTime(this.eventDate.value),
      // eventId: this.data.event.id,
      // eventType: this.data.event.eventType,
      // eventStartDate: this.calendarService.formatTime(this.eventDate.value),
      // eventEndDate: this.calendarService.formatTime(this.eventDate.value),
    };
  }

  onSubmit() {
    if (this.saveDisabled) {
      return;
    }
    const params = this.createParams();
    const rst$ = this.calendarService.addReportingEvents(params);
    rst$.subscribe((data: any) => {
      const popupRef: MatDialogRef<any> = this.cdsPopup.openCommon({
        data: {
          message: I18N_KEY_FOR_API_RES[data.message] || data.message,
        },
      });
      popupRef.afterClosed().subscribe(() => {
        if (data.result === ResponseResult.SUCCESS) {
          this.dialogRef.close({
            event: this.data.event,
            actionType: ActionEnum.UPDATE,
          });
        }
      });
    });
  }
}
