<div class="l-pa-7 delegated-agents-branch-err">
  <div class="cds-h2-light l-mb-0 title">
    {{ 'branch.notSameBranchEnsureCommunicated' | lang }}
  </div>
  <div class="head l-d-f l-mt-5">
    <span class="new-delegated-agent">{{ 'delegationChange.newDelegatedAgentLower' | lang }}</span>
    <!-- <span class="branch-code">{{
      'delegationChange.branchCodeLower' | lang
    }}</span> -->
  </div>
  <div class="content-box">
    <div *ngFor="let item of data.list" class="l-d-f l-mt-2">
      <div class="new-delegated-agent-value">
        <span class="cds-body1 l-mb-0">{{ item.agentCode }}</span>
        <div class="name cds-body1 l-mb-0 l-ml-5">
          {{ data.language === 'EN' ? (item.name ? item.name : item.chineseName) : item.chineseName ? item.chineseName : item.name }}
        </div>
        <div [style.width]="'3.25rem'"></div>
      </div>
      <!-- <span class="branch-code-value cds-body1 l-mb-0">{{
        item.branchCode
      }}</span> -->
    </div>
  </div>

  <div class="l-mt-6">
    <cds-button (click)="agree()" [style]="'secondary'" size="md">{{ 'branch.understandAndProceed' | lang }}</cds-button>
    <!-- <cds-button class="l-ml-4" (click)="agree()" [style]="'primary'" size="md"
      >{{ 'delegationChange.continue' | lang }}
    </cds-button> -->
  </div>
</div>
