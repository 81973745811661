import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CdsLanguageModule } from '@cds/ng-core/lang';

import { CdTableTitleComponent } from './cd-table-title.component';

@NgModule({
  imports: [CommonModule, CdsLanguageModule],
  declarations: [CdTableTitleComponent],
  exports: [CdTableTitleComponent],
})
export class CdTableTitleModule {}
