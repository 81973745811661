import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AgentProfileRequest } from './agent-profile-request.model';
import { AgentProfileDownloadResponse, AgentProfileResponse } from './agent-profile-response.model';
import { catchError, tap, timeout } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Observable, of, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AgentProfileListService {
  agentProfileSubject = new Subject<AgentProfileResponse>();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  errorSubject = new Subject<any>();
  constructor(private httpClient: HttpClient) {}

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  //private searchAgentProfileUrl = `http://localhost:8093/api/v1/agent-master/query`;
  //private downloadAgentProfileUrl = `http://localhost:8093/api/v1/agent-master/download`;
  private searchAgentProfileUrl = `${environment.apiPrefix}/ext/eb-ssms/reporting-service/api/v1/agent-master/query`;
  private downloadAgentProfileUrl = `${environment.apiPrefix}/ext/eb-ssms/reporting-service/api/v1/agent-master/download`;

  queryAgentProfiles(agentProfileRequest: AgentProfileRequest) {
    const errorAgentProfileResponse = new AgentProfileResponse();
    this.httpClient
      .post(this.searchAgentProfileUrl, agentProfileRequest, this.httpOptions)
      .pipe(
        tap(() => console.info('fetched agent profiles')),
        timeout(environment.httpTimeout),
        catchError(this.handleError<AgentProfileResponse>('queryAgentProfiles', errorAgentProfileResponse))
      )
      .subscribe((resp: AgentProfileResponse) => {
        this.agentProfileSubject.next(resp);
      });
  }

  handleError<T>(operation = 'operation', result?: T) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (error: any): Observable<T> => {
      console.error(error);
      console.info(`${operation} failed :${error.message}`);
      this.errorSubject.next(`${error.message}`);
      return of(result as T);
    };
  }

  download(agentProfileRequest: AgentProfileRequest): Observable<AgentProfileDownloadResponse> {
    return this.httpClient.post<AgentProfileDownloadResponse>(this.downloadAgentProfileUrl, agentProfileRequest, this.httpOptions);
  }
}
