/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';

import { timeout, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';

import { environment } from 'src/environments/environment';

interface PostT<T> {
  data: T;
  message: string;
  result: number;
}

@Injectable({
  providedIn: 'root',
})
export class CdHttpServeService {
  private readonly apiPrefix = environment.apiPrefix;

  constructor(private httpClient: HttpClient) {}

  get<T>(url: string, params: any) {
    return this.httpClient.get<PostT<T>>(`${this.apiPrefix}${url}`, { params }).pipe(
      timeout(environment.httpTimeout),
      catchError((err: HttpErrorResponse) => {
        return throwError(() => err.message);
      })
    );
  }

  post<T>(url: string, body: object) {
    return this.httpClient
      .post<PostT<T>>(`${this.apiPrefix}${url}`, body, {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      })
      .pipe(
        timeout(environment.httpTimeout),
        catchError((err: HttpErrorResponse) => {
          return throwError(() => err.message);
        })
      );
  }
}
