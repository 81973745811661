/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, OnChanges } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

import { CdsPopupService, MatDialogRef } from '@cds/ng-web-components/popup';
import { CDS_SIZE } from '@cds/ng-core/configuration';
import { I18N_KEY } from './search-record-transaction.component.config';
import { UserAgreementPopupComponent } from 'src/app/views/commission/user-agreement-popup.component';
import {
  letterAndNumberValidator,
  dateRangeInCompleteValidator,
  dateRangeInvalidValidator,
  dateValidator,
  agentCodeValidator,
  numberValidator,
} from 'src/app/core/validators';

@Component({
  selector: 'app-search-record-transaction',
  templateUrl: './search-record-transaction.component.html',
  styleUrls: ['./search-record-transaction.component.scss'],
})
export class SearchRecordTransactionComponent implements OnInit, OnChanges {
  @Output() search = new EventEmitter();
  @Input() errors: any;

  formGroup = new FormGroup(
    {
      receivedDateFrom: new FormControl('', {
        validators: [dateValidator({ error: I18N_KEY.FROM_RECEIVED_DATE_INVALID })],
        initialValueIsDefault: true,
      }),
      receivedDateTo: new FormControl('', {
        validators: [dateValidator({ error: I18N_KEY.TO_RECEIVED_DATE_INVALID })],
        initialValueIsDefault: true,
      }),
      referenceNo: new FormControl('', {
        validators: [
          letterAndNumberValidator({
            error: I18N_KEY.REFERENCE_NO_NULL,
          }),
        ],
        initialValueIsDefault: true,
      }),
      accountNo: new FormControl('', {
        validators: [
          numberValidator({
            error: I18N_KEY.ACCOUNT_CODE_NULL,
          }),
        ],
        initialValueIsDefault: true,
      }),
      agentCode: new FormControl('', {
        validators: [
          agentCodeValidator({
            error: I18N_KEY.AGENT_CODE_NULL,
          }),
        ],
        initialValueIsDefault: true,
      }),
      cancelledOrRejected: new FormControl(false, {
        initialValueIsDefault: true,
      }),
    },
    [
      dateRangeInCompleteValidator('receivedDateFrom', 'receivedDateTo', { error: I18N_KEY.INVALID_DATE_RANGE }),
      dateRangeInvalidValidator('receivedDateFrom', 'receivedDateTo', { error: I18N_KEY.INVALID_DATE_FROM_TO }),
    ]
  );

  get receivedDateFrom() {
    return this.formGroup.get('receivedDateFrom') as FormControl;
  }

  get receivedDateTo() {
    return this.formGroup.get('receivedDateTo') as FormControl;
  }

  get referenceNo() {
    return this.formGroup.get('referenceNo') as FormControl;
  }

  get accountNo() {
    return this.formGroup.get('accountNo') as FormControl;
  }

  get agentCode() {
    return this.formGroup.get('agentCode') as FormControl;
  }

  get cancelledOrRejected() {
    return this.formGroup.get('cancelledOrRejected') as FormControl;
  }

  get isSearchDisable() {
    let tag = true;
    const controls = this.formGroup.controls;
    const keys = Object.keys(controls);
    keys.forEach((key: string) => {
      const defaultValue = (controls[key] as FormControl).defaultValue;
      const controlValue = (controls[key] as FormControl).value;
      const value = key === 'cancelledOrRejected' ? controlValue : controlValue.trim();
      if (defaultValue !== value) {
        tag = false;
      }
    });
    return tag || this.formGroup.invalid;
  }

  constructor(private cdsPopup: CdsPopupService) {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['errors'].currentValue) {
      const errors = changes['errors'].currentValue;
      Object.keys(errors).forEach(key => {
        this.formGroup.controls[key]?.setErrors({
          error: errors[key],
        });
      });
    }
  }

  onSearch() {
    const params = this.createParams();
    this.search.emit(params);
  }

  reset() {
    this.formGroup.reset();
    this.onSearch();
  }

  createParams() {
    const formValue = this.getFormValue();
    return formValue;
  }

  getFormValue() {
    const value: any = {};
    const tempValue = this.formGroup.value;
    const keys: string[] = Object.keys(tempValue);
    keys.forEach(key => {
      const controlValue = key === 'cancelledOrRejected' ? tempValue[key] : tempValue[key].trim();
      if (controlValue !== '') {
        value[key] = controlValue;
      }
    });
    return value;
  }

  alertFutherInfo() {
    const popupRef: MatDialogRef<UserAgreementPopupComponent> = this.cdsPopup.open(UserAgreementPopupComponent, {
      size: CDS_SIZE['SMALL'],
      data: {
        message: I18N_KEY.ACCOUNT_CODE_FURTHER_INFO,
        continue: 'common.gotIt',
        type: 'alert',
        actionRight: true,
      },
    });
    popupRef.afterClosed().subscribe(() => {});
  }
}
