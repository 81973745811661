import { Component, EventEmitter, OnInit, Output } from '@angular/core';

import { CdsLangService } from '@cds/ng-core/lang';

@Component({
  selector: 'app-sw-update-ask',
  templateUrl: './sw-update-ask.component.html',
  styleUrls: ['./sw-update-ask.component.scss'],
})
export class SwUpdateAskComponent implements OnInit {
  @Output() closeEmit = new EventEmitter<void>();

  constructor(private cdsLangService: CdsLangService) {}

  ngOnInit(): void {}

  close() {
    this.closeEmit.emit();
  }

  refresh() {
    window.location.reload();
  }

  getTextByLang(lang: string) {
    return this.cdsLangService.translate(lang);
  }
}
