<cds-popup class="success-dialog">
  <cds-popup-title
    >{{ 'confirm.popup.title1' | lang }}
    <span class="cds-h2-ita">{{ 'confirm.popup.title2' | lang }}</span>
  </cds-popup-title>
  <cds-popup-content> </cds-popup-content>
  <cds-popup-actions>
    <cds-button label="{{ 'confirm.popup.gotIt' | lang }}" (click)="gotIt()"></cds-button>
  </cds-popup-actions>
</cds-popup>
