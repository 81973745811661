/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Inject } from '@angular/core';
import { CdsButtonConfig } from '@cds/ng-core/button';
import { CDS_POPUP_DATA, MatDialogRef } from '@cds/ng-web-components/popup';
@Component({
  selector: 'app-alert-popup',
  templateUrl: './alert-popup.component.html',
  styleUrls: ['./alert-popup.component.scss'],
})
export class AlertPopupComponent {
  disagreeButtonConfig: CdsButtonConfig = { size: 'sm', style: 'secondary' };
  agreeButtonConfig: CdsButtonConfig = { size: 'sm' };

  constructor(
    private dialogRef: MatDialogRef<AlertPopupComponent>,
    @Inject(CDS_POPUP_DATA)
    public data: {
      title: string;
      message: string;
      buttonName: string;
      params: any;
    }
  ) {
    if (data.params) this.params = data.params;
  }
  params = {};

  close() {
    this.dialogRef.close({ agree: true });
  }
}
