/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CdsDropdownConfig } from '@cds/ng-core/dropdown';
import { Observable } from 'rxjs';
import { filterParams } from 'src/app/core/models/request';
import { BasicResponse, PageResponse } from 'src/app/core/models/response/response';
import { environment } from 'src/environments/environment';
import { AudienceList } from './notificationmodel';

class R {
  result!: number;
  data?: any;
  message!: string;
}

@Injectable({
  providedIn: 'root',
})
export class NotificationDataService {
  private common_url = `${environment.apiPrefix}/ext/eb-ssms/customer-service/notification`;
  private get_summaryList_api = this.common_url + '/list'; //查询所有通知
  private cancel_api = this.common_url + '/cancel/';
  private uploadApi = this.common_url + '/audience/upload';
  private audienceListApi = this.common_url + '/audience/list';
  private audienceListSendHistoryApi = this.common_url + '/audience/sendHistory';
  private fileNameApi = this.common_url + '/audience/latestFileName';
  private pushNotificationFileName = this.common_url + '/audience/fileName';
  private getSubscribedUrl = this.common_url + '/subscribed';
  readonly mobileSubscriptionList = 'Mobile Subscription List';

  constructor(private http: HttpClient) {}

  audienceDropdownConfig: CdsDropdownConfig = {
    color: 'ml',
    type: 'default',
    options: [
      {
        label: this.mobileSubscriptionList,
        value: this.mobileSubscriptionList,
      },
      { label: 'Exclusive Offer (List 1)', value: 'List 1' },
      { label: 'Exclusive Offer (List 2)', value: 'List 2' },
      { label: 'Exclusive Offer (List 3)', value: 'List 3' },
      { label: 'Exclusive Offer (List 4)', value: 'List 4' },
      { label: 'Exclusive Offer (List 5)', value: 'List 5' },
    ],
  };

  audienceCustomDropdownConfig: CdsDropdownConfig = {
    color: 'ml',
    type: 'default',
    options: [
      { label: 'Exclusive Offer (List 1)', value: 'List 1' },
      { label: 'Exclusive Offer (List 2)', value: 'List 2' },
      { label: 'Exclusive Offer (List 3)', value: 'List 3' },
      { label: 'Exclusive Offer (List 4)', value: 'List 4' },
      { label: 'Exclusive Offer (List 5)', value: 'List 5' },
    ],
  };

  getAudienceLabel = (value?: string) => {
    if (!value) {
      return '';
    }
    const option = this.audienceDropdownConfig.options.find(item => value === item.value);
    if (option) {
      return option.label;
    }
    return '';
  };

  getSummaryList(pageIndex: number, pageSize: number, audienceList: string) {
    // const headers = new HttpHeaders().set(
    //   'Content-type',
    //   'application/json; charset=UTF-8'
    // );
    pageIndex = pageIndex - 1;
    return this.http.get(
      this.get_summaryList_api + '?&size=' + pageSize + '&page=' + pageIndex + '&audienceListName=' + audienceList + '&sort=targetBroadcastDate,desc'
    );
  }

  getSubscribed(): Observable<BasicResponse<any>> {
    return this.http.get<BasicResponse<any>>(this.getSubscribedUrl);
  }

  cancelStatus(id: string) {
    return new Observable(observable => {
      this.http.post(this.cancel_api + id, {}).subscribe(data => observable.next(data));
    });
  }

  getAudienceList(params: any): Observable<PageResponse<AudienceList>> {
    return this.http.get<PageResponse<AudienceList>>(this.audienceListApi, {
      params: filterParams(params),
    });
  }

  getAudienceListSendHistory(params: any): Observable<PageResponse<AudienceList>> {
    return this.http.get<PageResponse<AudienceList>>(this.audienceListSendHistoryApi, {
      params: filterParams(params),
    });
  }

  getFileName(audienceListName: string): Observable<R> {
    return this.http.get<R>(this.fileNameApi, {
      params: {
        listName: audienceListName,
      },
    });
  }

  getPushNotificationFileName(id: string): Observable<R> {
    return this.http.get<R>(this.pushNotificationFileName, {
      params: {
        pushNotificationSummaryId: id,
      },
    });
  }

  upload(filePath: File, selectedAudience: string) {
    const formData = new FormData();
    formData.append('file', filePath);
    return new Observable(observable => {
      this.http
        .post(this.uploadApi, formData, {
          params: {
            listName: selectedAudience,
          },
        })
        .subscribe(data => observable.next(data));
    });
  }

  testFunction() {
    return 2;
  }
}
