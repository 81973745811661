import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { CdsLangService } from '@cds/ng-core/lang';
import { CdsAlertService } from '@cds/ng-web-components/alert';
import { from, switchMap } from 'rxjs';

import { CdHttpServeService } from 'src/app/shared/cd-http-serve/cd-http-serve.service';
import { getSessionByKey } from 'src/app/utils/indexdb';
import { SplitAgentNotificationInfoBody, SplitAgentInfo } from './split-agent-notification-info-resolve/split-agent-notification-info-resolve.model';

@Component({
  selector: 'app-agent-confirmation',
  templateUrl: './agent-confirmation.component.html',
  styleUrls: ['./agent-confirmation.component.scss'],
})
export class AgentConfirmationComponent implements OnInit, AfterViewInit {
  summaryId = '';
  iffId = '';
  iffVersionNo = '';
  isUnderstood = false;
  splitAgentNotificationInfoBody?: SplitAgentNotificationInfoBody;
  primaryAgent?: SplitAgentInfo;

  private readonly submissionUrl = '/ext/eb-ssms-sales-journey-service/splitAgentNotification/submission';

  constructor(
    private route: ActivatedRoute,
    public cdsLangService: CdsLangService,
    private cdHttpServeService: CdHttpServeService,
    private alert: CdsAlertService,
    private router: Router //   private cdsPopup: CdsPopupService
  ) {}

  ngOnInit() {
    const language = this.route.snapshot.queryParams['language'];
    this.cdsLangService.setCurrentLang(language === 'en' ? 'en' : 'zh');

    this.route.data.subscribe(({ splitAgentNotificationInfo }) => {
      this.splitAgentNotificationInfoBody = splitAgentNotificationInfo.body;
      if (this.splitAgentNotificationInfoBody) {
        const index = this.splitAgentNotificationInfoBody.agentInfos.findIndex(agent => agent.primaryAgent);
        this.primaryAgent = this.splitAgentNotificationInfoBody.agentInfos[index];
        this.splitAgentNotificationInfoBody.agentInfos = this.splitAgentNotificationInfoBody.agentInfos.filter(agent => !agent.primaryAgent);
      }

      this.summaryId = splitAgentNotificationInfo.summaryId;
      this.iffId = splitAgentNotificationInfo.iffId;
      this.iffVersionNo = splitAgentNotificationInfo.iffVersionNo;
    });
  }

  ngAfterViewInit(): void {
    Promise.resolve().then(() => {
      (document.getElementsByClassName('cds-navigation-toolbar')[0] as HTMLElement).style.display = 'none';
      (document.getElementsByClassName('cds-menu-container')[0] as HTMLElement).style.display = 'none';
    });
  }

  get agentInfos(): SplitAgentInfo[] {
    if (this.primaryAgent && this.splitAgentNotificationInfoBody) {
      return [this.primaryAgent, ...this.splitAgentNotificationInfoBody.agentInfos];
    }
    return [];
  }

  get isEr() {
    return this.splitAgentNotificationInfoBody?.summaryType === 'ER';
  }

  getNumOfServicingAgent(index: number) {
    let val = '';

    switch (index) {
      case 1:
        val = `${this.cdsLangService.translate('agentConfirmation.second')}${this.cdsLangService.currentLang === 'en' ? ' ' : ''}`;
        break;

      case 2:
        val = `${this.cdsLangService.translate('agentConfirmation.third')}${this.cdsLangService.currentLang === 'en' ? ' ' : ''}`;
        break;

      default:
        break;
    }

    return val;
  }

  onSubmission() {
    // if (!this.isUnderstood) {
    //   return;
    // }

    from(getSessionByKey())
      .pipe(
        switchMap(res => {
          const identityId = res.id;
          return this.cdHttpServeService.post(this.submissionUrl, {
            salesJourneySummaryId: this.summaryId,
            iffId: this.iffId,
            empfRefNo: this.splitAgentNotificationInfoBody?.empfRefNo,
            rgltdActAck: this.isUnderstood,
            identityId,
            acknowledged: this.isUnderstood,
          });
        })
      )
      .subscribe(res => {
        if (res.result === 0) {
          this.router.navigate(['/success/split-agent-notification-info-success']);
        } else {
          this.alert.error('Error', res.message);
        }
      });
  }

  getAgentName(agent: SplitAgentInfo) {
    return this.cdsLangService.currentLang === 'en' ? agent.agentName || agent.agentNameTc : agent.agentNameTc || agent.agentName;
  }

  getCompanyName(splitAgentNotificationInfoBody?: SplitAgentNotificationInfoBody) {
    if (!splitAgentNotificationInfoBody) {
      return '';
    }

    return this.cdsLangService.currentLang === 'en'
      ? splitAgentNotificationInfoBody.companyName || splitAgentNotificationInfoBody.companyNameZh
      : splitAgentNotificationInfoBody.companyNameZh || splitAgentNotificationInfoBody.companyName;
  }
}
