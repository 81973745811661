/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, ContentChild, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-cd-page-title',
  templateUrl: './cd-page-title.component.html',
  styleUrls: ['./cd-page-title.component.scss'],
})
export class CdPageTitleComponent implements OnInit {
  @Input() title = '';
  @Input() showBack = false;
  @Output() backLanding = new EventEmitter<any>();
  @Input() backLandingLabel = 'commission.pool.common.backToLanding';

  @ContentChild('rightContent', { static: true })
  rightContent!: TemplateRef<any>;
  @ContentChild('pageAction', { static: true }) pageAction!: TemplateRef<any>;

  constructor() {}

  ngOnInit() {}

  goBack() {
    this.backLanding.emit();
  }
}
