<app-cd-page-title title="{{ 'commission.default.scale.title' | lang }}"> </app-cd-page-title>
<div class="row">
  <div class="col-xs-12 col-md-12 l-pt-6 l-pb-3 l-d-f">
    <div *ngFor="let item of tab" class="tab" [class.tab-selected]="item.selected" (click)="tabClick(item)">
      <div>{{ item.label | lang }}</div>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-xs-12 col-md-12 l-pt-5 l-pb-3" [ngSwitch]="tabSelected.value">
    <app-new-business-mpf *ngSwitchCase="'nbm'"></app-new-business-mpf>
    <app-individual-transfer-mpf *ngSwitchDefault></app-individual-transfer-mpf>
    <app-new-business-macau *ngSwitchCase="'nbom'"></app-new-business-macau>
  </div>
</div>
