<cds-popup [class.hidden]="!show" class="relative">
  <cds-popup-title>{{ 'scb.upload-statement' | lang }}</cds-popup-title>

  <cds-popup-content>
    <div class="cds-h6-demibold l-mb-4">
      {{ 'notification.fileRequirements' | lang }}
    </div>
    <div class="cds-detail1">
      {{ 'scb.file-requirements-tips' | lang }}
    </div>

    <div class="upload-area l-mb-8">
      <cds-file-upload
        #upload
        [disabled]="true"
        [formControl]="uploadFormControl"
        (change)="fileChange($event)"
        errorMessage="Invalid file uploaded."
        acceptedFiles="text/csv">
      </cds-file-upload>
      <div class="l-pt-2">
        <cds-assistive-text *ngIf="errorMsg">{{ errorMsg }}</cds-assistive-text>
      </div>
    </div>
    <app-loading *ngIf="isLoading" [overlayInPopup]="true"></app-loading>
  </cds-popup-content>

  <cds-popup-actions>
    <ng-container>
      <cds-button [config]="cancelButtonConfig" (click)="cancel()">{{ 'common.cancel' | lang }}</cds-button>

      <cds-button [config]="confirmButtonConfig" (click)="confirm()" [disabled]="noFileSelect || !!errorMsg">{{ 'common.confirm' | lang }}</cds-button>
    </ng-container>
  </cds-popup-actions>
</cds-popup>
