<div class="case-details">
  <div *ngIf="!from" class="back-to-case-summary l-d-f">
    <cds-icon (click)="onBackToCaseSummaryClick()" class="dlgt-back-icon" [config]="backErIconConfig" [icon]="backErIcon"> </cds-icon>
    <div class="l-ml-2">
      <span class="cds-body2-demibold back-text" (click)="onBackToCaseSummaryClick()">
        {{ 'case-details.backToSalesJourneySummaryPage' | lang }}
      </span>
    </div>
  </div>

  <div *ngIf="from === 'salesSupportSummary'" class="back-to-case-summary l-d-f">
    <cds-icon (click)="onBackToSalesSupportSummaryClick()" class="dlgt-back-icon" [config]="backErIconConfig" [icon]="backErIcon"> </cds-icon>
    <div class="l-ml-2">
      <span class="cds-body2-demibold back-text" (click)="onBackToSalesSupportSummaryClick()"> Sales Support Summary Page </span>
    </div>
  </div>

  <div *ngIf="from === 'branchLeaveSummary'" class="back-to-case-summary l-d-f">
    <cds-icon (click)="onBackTBranchLebvelClick()" class="dlgt-back-icon" [config]="backErIconConfig" [icon]="backErIcon"> </cds-icon>
    <div class="l-ml-2">
      <span class="cds-body2-demibold back-text" (click)="onBackTBranchLebvelClick()"> branch level Summary Page </span>
    </div>
  </div>

  <div *ngIf="from === 'superAdmin'" class="back-to-case-summary l-d-f">
    <cds-icon (click)="onBackSuperAdmin()" class="dlgt-back-icon" [config]="backErIconConfig" [icon]="backErIcon"> </cds-icon>
    <div class="l-ml-2">
      <span class="cds-body2-demibold back-text" (click)="onBackSuperAdmin()">Super Admin Page</span>
    </div>
  </div>

  <div *ngIf="from === 'iffDeclinedDashboard'" class="back-to-case-summary l-d-f">
    <cds-icon (click)="onBackIffDeclinedDashboard()" class="dlgt-back-icon" [config]="backErIconConfig" [icon]="backErIcon"> </cds-icon>
    <div class="l-ml-2">
      <span class="cds-body2-demibold back-text" (click)="onBackIffDeclinedDashboard()">IFF Declined Dashboard Page</span>
    </div>
  </div>

  <div class="div-border-bottom">
    <div class="case-details-header">
      <span class="cds-h4-demibold">
        {{ 'case-details.caseDetailsHeader' | lang }}
      </span>
    </div>
  </div>

  <div class="content">
    <div class="transaction-box">
      <div class="transaction-top">
        <div>
          <cds-icon class="form-search" icon="form:form_search" [config]="{ color: 'default', size: 'md' }"></cds-icon>
        </div>
        <div class="l-d-f l-fd-col l-ml-5">
          <div>
            <span class="cds-body2">{{ 'case-details.eMPFTransactionReferenceNumberLabel' | lang }}</span>
          </div>
          <div class="l-mt-1">
            <span class="cds-h4-demibold">{{ caseDetails?.empfRefNo }}</span>
          </div>
          <div class="l-d-f l-ai-cen l-mt-3">
            <div class="l-mr-4">
              <span class="cds-body2">eMPF status:</span>
            </div>
            <app-status-light
              [color]="
                caseSummaryService.empfAppStatus({
                  eMPFAppStatus: caseDetails?.empfStatusEnum + ''
                }).color
              "
              [text]="
                caseSummaryService.empfAppStatus({
                  eMPFAppStatus: caseDetails?.empfStatusEnum + ''
                }).text
              "></app-status-light>
          </div>
          <app-permission [role]="PermissionAccess.W" [permission]="PermissionItem.SALES_JOURNEY_SUPER_ADMIN">
            <div *ngIf="from === 'superAdmin'" class="l-d-f l-ai-cen l-pt-3">
              <div>
                <span class="cds-body2">eMPF ID:</span>
              </div>
              <div class="l-ml-6">
                <span class="cds-body1-demibold">{{ caseDetails?.empfId || 'N/A' }}</span>
              </div>
            </div>
            <div *ngIf="from === 'superAdmin'" class="l-d-f l-ai-cen l-pt-3">
              <div>
                <span class="cds-body2">Employee Account Code:</span>
              </div>
              <div class="l-ml-6">
                <span class="cds-body1-demibold">{{ caseDetails?.memberAcctCd || 'N/A' }}</span>
              </div>
            </div>
          </app-permission>
          <div class="l-d-f l-ai-cen l-pt-3">
            <div>
              <span class="cds-body2">{{ 'case-details.submissionDateTimeLabel' | lang }} </span>
            </div>
            <div class="l-ml-6">
              <span class="cds-body1-demibold">{{ caseDetails?.empfSubDt | date : 'dd/MM/yyyy' }}</span>
              <span *ngIf="caseDetails?.empfSubDt" class="cds-body1-demibold">(HKT)</span>
            </div>
          </div>
        </div>
      </div>
      <div class="client-details">
        <div class="l-d-f l-ai-cen">
          <div style="flex: 3">
            <span class="cds-h4-demibold" style="margin-right: var(--cds-spacing-05)">{{ 'case-details.eMPFReturnedData' | lang }}</span>
          </div>
          <div style="flex: 4" class="l-as-fs">
            <div>
              <span class="cds-body2">{{ 'case-details.clientNameLabel' | lang }}</span>
            </div>
            <div style="width: 70%; word-break: break-all">
              <span class="cds-body1-demibold">{{ getClientName || 'N/A' }}</span>
            </div>
          </div>
          <div style="flex: 4" class="l-as-fs">
            <div>
              <span class="cds-body2">{{ 'case-details.HKIDPassportNo' | lang }}</span>
            </div>
            <div>
              <div *ngIf="caseDetails?.empfHkidOrPassportNo" style="width: 70%; word-break: break-all">
                <span class="cds-body1-demibold">{{ caseDetails?.empfHkidOrPassportNo }}</span>
              </div>
              <div *ngIf="!caseDetails?.empfHkidOrPassportNo">
                <span class="cds-body1-demibold">N/A</span>
              </div>
            </div>
          </div>
        </div>
        <div class="l-d-f l-mt-5 l-ai-fs">
          <div style="flex: 3"></div>
          <div style="flex: 4">
            <div>
              <span class="cds-body2">{{ 'case-details.phoneNumberFromEMPF' | lang }}</span>
            </div>
            <div style="width: 70%; word-break: break-all">
              <span class="cds-body1-demibold">{{ caseDetails?.clientPhoneNumber || 'N/A' }}</span>
            </div>
          </div>
          <div style="flex: 4">
            <div>
              <span class="cds-body2">{{ 'case-details.emailFromeMPF' | lang }}</span>
            </div>
            <div style="width: 70%; word-break: break-all">
              <span class="cds-body1-demibold">{{ caseDetails?.clientEmail || 'N/A' }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="details-box">
      <div class="eiff-details">
        <div class="l-d-f l-ai-cen">
          <div style="flex: 3">
            <span class="cds-h4-demibold" style="margin-right: var(--cds-spacing-05)">{{ 'case-details.eIFFSubmittedDetails' | lang }}</span>
          </div>
          <div style="flex: 8">
            <div>
              <span class="cds-body2">{{ 'case-details.HKIDPassportNo' | lang }}</span>
            </div>
            <div style="width: 70%; word-break: break-all" class="l-mt-1">
              <span class="cds-body1-demibold">{{ caseDetails?.hkidOrPassportNo || 'N/A' }}</span>
            </div>
          </div>
        </div>
        <div *ngIf="caseDetails?.dbs" class="l-d-f l-ai-fs l-mt-5">
          <div style="flex: 3"></div>
          <div style="flex: 4">
            <div>
              <span class="cds-body2">RM Referral Code</span>
            </div>
            <div class="l-mt-1" style="width: 70%; word-break: break-all">
              <span class="cds-body1-demibold">{{ caseDetails?.dbs?.dbsRmReferralCd || 'N/A' }}</span>
            </div>
          </div>
          <div style="flex: 4">
            <div>
              <span class="cds-body2">Customer ID</span>
            </div>
            <div class="l-mt-1">
              <span class="cds-body1-demibold">{{ caseDetails?.dbs?.dbsCustomerId || 'N/A' }}</span>
            </div>
          </div>
        </div>
        <div *ngIf="caseDetails?.dbs" class="l-d-f l-ai-fs l-mt-5">
          <div style="flex: 3"></div>
          <div style="flex: 4">
            <div>
              <span class="cds-body2">Campaign Code</span>
            </div>
            <div class="l-mt-1" style="width: 70%; word-break: break-all">
              <span class="cds-body1-demibold">{{ caseDetails?.dbs?.dbsCampaignCd || 'N/A' }}</span>
            </div>
          </div>
          <div style="flex: 4">
            <div>
              <span class="cds-body2">Source Code</span>
            </div>
            <div class="l-mt-1">
              <span class="cds-body1-demibold">{{ caseDetails?.dbs?.dbsSourceCd || 'N/A' }}</span>
            </div>
          </div>
        </div>
        <div class="l-d-f l-ai-fs l-mt-5">
          <div style="flex: 3"></div>
          <div style="flex: 4">
            <div class="l-d-f l-ai-cen">
              <div>
                <span class="cds-body2">{{ 'case-details.eIFFStatusLabel' | lang }}</span>
              </div>
              <div class="l-ml-1">
                <cds-icon class="cursor-pointer" icon="action:info_1" (click)="onDisplayAdviseIFF()"></cds-icon>
              </div>
            </div>
            <div style="width: 70%; word-break: break-all" class="l-mt-1" *ngIf="caseDetails">
              <app-status-light
                [tipText]="tipText"
                [color]="
                  caseSummaryService.iffStatus({
                    IFFStatus: caseDetails.iffStatusEnum + ''
                  }).color
                "
                [text]="
                  caseSummaryService.iffStatus({
                    IFFStatus: caseDetails.iffStatusEnum + ''
                  }).text | lang
                "></app-status-light>
            </div>
          </div>
          <div style="flex: 4">
            <div>
              <span class="cds-body2">First eIFF submission time & date (DD/MM/YYYY)</span>
            </div>
            <div class="l-mt-1">
              <span class="cds-body1-demibold">{{
                caseDetails?.firstIffSubDt ? (caseDetails?.firstIffSubDt | date : 'hh:mm a, dd/MM/yyyy') + '(HKT)' : 'N/A'
              }}</span>
            </div>
          </div>
        </div>

        <ng-container
          *ngIf="caseDetails?.iffDeclineExpireReasons && (caseDetails?.iffDeclineExpireReasons)![0].code === 7; else iffDeclineExpireReasonsElseBlock">
          <div class="l-d-f l-mt-5">
            <div style="flex: 3"></div>
            <div style="flex: 8">
              <div class="e-status-remarks-box">
                <div class="l-mt-2">
                  <span class="cds-body2-demibold" style="color: var(--cds-color-dark-2-gold)">{{ (caseDetails?.iffDeclineExpireReasons)![0].errorMsg }}</span>
                </div>
                <div class="l-mt-2">
                  <span class="cds-detail2-ita authorized-person" style="color: var(--cds-text-main)">{{
                    (caseDetails?.iffDeclineExpireReasons)![0].hintMsg
                  }}</span>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
        <ng-template #iffDeclineExpireReasonsElseBlock>
          <ng-container *ngFor="let item of caseDetails?.iffDeclineExpireReasons">
            <div class="l-d-f l-mt-5" *ngIf="isOfDeclined(item.code)">
              <div style="flex: 3"></div>
              <div style="flex: 8">
                <div class="e-status-remarks-box">
                  <div class="l-d-f l-ai-cen">
                    <div>
                      <cds-icon style="--cds-icon-color: var(--cds-text-disabled)" icon="contact:question"></cds-icon>
                    </div>
                    <div class="l-ml-2">
                      <span class="cds-body2" style="color: var(--cds-text-disabled)">Declined</span>
                    </div>
                  </div>
                  <div class="l-mt-2">
                    <span class="cds-body2-demibold" style="color: var(--cds-color-dark-1-coral)">{{ item.errorMsg }}</span>
                  </div>
                  <div class="l-mt-2">
                    <span class="cds-detail2-ita authorized-person" style="color: var(--cds-text-main)">{{ item.hintMsg }}</span>
                  </div>
                </div>
              </div>
            </div>

            <div class="l-d-f l-mt-5" *ngIf="isOfExpired(item.code)">
              <div style="flex: 3"></div>
              <div style="flex: 8">
                <div class="e-status-remarks-box">
                  <div class="l-d-f l-ai-cen">
                    <div>
                      <cds-icon style="--cds-icon-color: var(--cds-text-disabled)" icon="contact:question"></cds-icon>
                    </div>
                    <div class="l-ml-2">
                      <span class="cds-body2" style="color: var(--cds-text-disabled)">After 45 days Expired</span>
                    </div>
                  </div>
                  <div class="l-mt-2">
                    <span class="cds-body1-demibold" style="color: var(--cds-text-main)">{{ item.errorMsg }}</span>
                  </div>
                  <div class="l-mt-2">
                    <span class="cds-body2-ita authorized-person" style="color: var(--cds-text-main)">{{ item.hintMsg }}</span>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </ng-template>

        <div class="l-d-f l-ai-fs l-mt-5">
          <div style="flex: 3"></div>
          <div style="flex: 4">
            <div>
              <span class="cds-body2">Delivery channel of the eIFF</span>
            </div>
            <div class="l-mt-1" style="width: 70%; word-break: break-all">
              <span class="cds-body1-demibold">{{ caseDetails?.iffDeliveryChannel || 'N/A' }}</span>
            </div>
          </div>
          <div style="flex: 4">
            <div>
              <span class="cds-body2">Resend time & date</span>
            </div>
            <div class="l-mt-1">
              <span class="cds-body1-demibold">{{
                caseDetails?.resendIffSubDt ? (caseDetails?.resendIffSubDt | date : 'hh:mm a, dd/MM/yyyy') + '(HKT)' : 'N/A'
              }}</span>
            </div>
          </div>
        </div>
        <ng-container *ngIf="from === 'salesSupportSummary' && isShowVoidIFFBtn">
          <app-permission [role]="PermissionAccess.W" [permission]="PermissionItem.SALES_JOURNEY_SALES_SUPPORT">
            <div class="l-d-f l-ai-cen l-pt-7 l-mt-7 l-mb-4 void-box">
              <div style="flex: 3">
                <span class="cds-h4-demibold">Void Handling</span>
              </div>
              <div class="l-d-f l-ai-cen" style="flex: 8">
                <div style="width: 112px; min-width: 112px">
                  <cds-button
                    [disabled]="!!((caseDetails?.voidIffVo && caseDetails?.voidIffVo?.voided) || disableVoidEIFF)"
                    [style]="'secondary'"
                    size="sm"
                    (click)="onDisplayVoidEiffRef()"
                    >Void eIFF</cds-button
                  >
                </div>
                <div style="margin-left: 26px" *ngIf="caseDetails?.voidIffVo && caseDetails?.voidIffVo?.voided">
                  <span class="cds-body2" style="color: var(--cds-text-placeholder)"
                    >Voided by: {{ caseDetails?.voidIffVo?.voidTime | date : 'h:mma dd/MM/yyyy (+8T)' }}, by
                    {{ caseDetails?.voidIffVo?.voidAgentName || 'N/A' }}</span
                  >
                </div>
              </div>
            </div>
          </app-permission>
        </ng-container>
        <ng-container *ngIf="(!from || from === 'branchLeaveSummary') && isServicingAgent && isShowSend">
          <div *ngIf="isShowSendeIFFBtn" class="l-d-f l-ai-cen l-mt-5">
            <div style="flex: 3"></div>
            <div style="flex: 4">
              <div>
                <cds-button [style]="'secondary'" size="sm" (click)="openSendEIFF()">Send eIFF</cds-button>
              </div>
            </div>
            <div style="flex: 4"></div>
          </div>
          <div *ngIf="caseDetails?.couldResend" class="l-d-f l-ai-cen l-mt-5">
            <div style="flex: 3"></div>
            <div style="flex: 4">
              <div>
                <cds-button (click)="openResendEIFF()" [style]="'secondary'" size="sm">Resend eIFF</cds-button>
              </div>
            </div>
            <div style="flex: 4"></div>
          </div>
        </ng-container>

        <div *ngIf="caseDetails?.paperIff" class="l-d-f l-ai-cen l-mt-5 l-pt-5 paper-iff-box">
          <div style="flex: 3">
            <span class="cds-h4-demibold">Paper IFF</span>
          </div>
          <div style="flex: 4" class="l-as-fs">
            <div>
              <span class="cds-body2">Handler Name</span>
            </div>
            <div class="l-mt-1" style="width: 70%; word-break: break-all">
              <span class="cds-body1-demibold">{{ caseDetails?.paperIffHandlerName || 'N/A' }}</span>
            </div>
          </div>
          <div style="flex: 4" class="l-as-fs">
            <div>
              <span class="cds-body2">Submission Date and Time</span>
            </div>
            <div class="l-mt-1">
              <span class="cds-body1-demibold">{{
                caseDetails?.latestIffSubDt ? (caseDetails?.latestIffSubDt | date : 'hh:mm a, dd/MM/yyyy') + '(HKT)' : 'N/A'
              }}</span>
            </div>
          </div>
        </div>
      </div>

      <div class="eiff-details">
        <div class="agent-details l-d-f l-ai-cen">
          <div>
            <span class="cds-h4-demibold">{{ 'case-details.agentDetailsLabel' | lang }}</span>
          </div>
          <app-permission [role]="PermissionAccess.W" [permission]="PermissionItem.SALES_JOURNEY_SUPER_ADMIN">
            <div *ngIf="from === 'superAdmin' && !isSendS2 && caseDetails?.iffStatusEnum !== 0" class="l-ml-5">
              <cds-icon (click)="goEditAgentDetails()" [disabled]="disableEditAgentByChannel" class="cursor-pointer" icon="action:edit"></cds-icon>
            </div>
          </app-permission>
        </div>

        <div *ngIf="screenType === ScreenType.PAD && from === 'superAdmin'; else pcServicingAgent" class="servicing-agent-pad">
          <div>
            <span class="cds-body2" style="color: var(--cds-color-light-3-dark-navy)">{{ 'case-details.servicingAgentLabel' | lang }}</span>
          </div>
          <div class="l-mt-5 l-d-f">
            <div class="link-box">
              <span class="cds-body1-demibold">
                <cds-link
                  [config]="{
                    type: 'hyperlink',
                    label: primaryAgentInfo ? primaryAgentInfo.agentCd : '',
                    href: '#'
                  }">
                </cds-link>
              </span>
            </div>
            <div class="l-ml-3">
              <span class="cds-body1-demibold">{{ getAgentName(primaryAgentInfo) }}</span>
            </div>
          </div>
          <div class="l-mt-3">
            <cds-checkbox
              [checked]="primaryAgentInfo ? primaryAgentInfo.regulatedAct : false"
              [disabled]="true"
              label="{{ 'case-details.regularActivityLabel' | lang }}"></cds-checkbox>
          </div>
          <div class="l-d-f l-mt-3">
            <div>
              <div>
                <span class="cds-body2">Share HKPC</span>
              </div>
              <div>
                <span class="cds-body-hero-demibold" style="color: var(--cds-color-m-green)">{{
                  primaryAgentInfo?.hkpcPercentage ? primaryAgentInfo?.hkpcPercentage + '%' : '-'
                }}</span>
              </div>
            </div>
            <div class="l-ml-7">
              <div>
                <span class="cds-body2">Share Commission</span>
              </div>
              <div>
                <span class="cds-body-hero-demibold" style="color: var(--cds-color-m-green)">{{
                  primaryAgentInfo?.commissionPercentage ? primaryAgentInfo?.commissionPercentage + '%' : '-'
                }}</span>
              </div>
            </div>
          </div>
        </div>
        <ng-template #pcServicingAgent>
          <div class="servicing-agent">
            <div class="l-d-f l-ai-cen">
              <div style="flex: 3">
                <span class="cds-body2" style="color: var(--cds-color-light-3-dark-navy)">{{ 'case-details.servicingAgentLabel' | lang }}</span>
              </div>
              <div class="l-d-f" [ngStyle]="{ flex: from === 'superAdmin' ? 5 : 8 }">
                <div class="link-box">
                  <span class="cds-body1-demibold">
                    <cds-link
                      [config]="{
                        type: 'hyperlink',
                        label: primaryAgentInfo ? primaryAgentInfo.agentCd : '',
                        href: '#'
                      }">
                    </cds-link>
                  </span>
                </div>
                <div class="l-ml-3">
                  <span class="cds-body1-demibold">{{ getAgentName(primaryAgentInfo) }}</span>
                </div>
              </div>
              <div *ngIf="from === 'superAdmin'" style="flex: 3" class="l-d-f">
                <div style="flex: 1; text-align: end"><span class="cds-body2">Share HKPC</span></div>
                <div style="flex: 1; text-align: end" class="l-ml-5"><span class="cds-body2">Share Commission</span></div>
              </div>
            </div>
            <div class="l-d-f l-ai-cen l-mt-2">
              <div style="flex: 3"></div>
              <div [ngStyle]="{ flex: from === 'superAdmin' ? 5 : 8 }">
                <cds-checkbox
                  [checked]="primaryAgentInfo ? primaryAgentInfo.regulatedAct : false"
                  [disabled]="true"
                  label="{{ 'case-details.regularActivityLabel' | lang }}"></cds-checkbox>
              </div>
              <div *ngIf="from === 'superAdmin'" style="flex: 3" class="l-d-f">
                <div style="flex: 1; text-align: end">
                  <span class="cds-body-hero-demibold" style="color: var(--cds-color-m-green)">{{
                    primaryAgentInfo?.hkpcPercentage ? primaryAgentInfo?.hkpcPercentage + '%' : '-'
                  }}</span>
                </div>
                <div style="flex: 1; text-align: end" class="l-ml-5">
                  <span class="cds-body-hero-demibold" style="color: var(--cds-color-m-green)">{{
                    primaryAgentInfo?.commissionPercentage ? primaryAgentInfo?.commissionPercentage + '%' : '-'
                  }}</span>
                </div>
              </div>
            </div>
          </div>
        </ng-template>

        <div *ngIf="screenType === ScreenType.PAD && from === 'superAdmin'; else pcSplitAgent">
          <div class="split-agent-pad" *ngFor="let splitAgent of splitAgentInfos; let i = index">
            <div>
              <span class="cds-body2" style="color: var(--cds-color-light-3-dark-navy)">{{ 'case-details.splitAgentLabel' | lang }}</span>
            </div>
            <div class="l-mt-5 l-d-f">
              <div class="link-box">
                <span class="cds-body1-demibold">
                  <cds-link
                    [config]="{
                      type: 'hyperlink',
                      label: splitAgent ? splitAgent.agentCd : '',
                      href: '#'
                    }">
                  </cds-link>
                </span>
              </div>
              <div class="l-ml-3">
                <span class="cds-body1-demibold">{{ getAgentName(splitAgent) }}</span>
              </div>
            </div>
            <div class="l-mt-3">
              <cds-checkbox
                [checked]="splitAgent ? splitAgent.regulatedAct : false"
                [disabled]="true"
                label="{{ 'case-details.regularActivityLabel' | lang }}"></cds-checkbox>
            </div>
            <div class="l-d-f l-mt-3">
              <div>
                <div>
                  <span class="cds-body2">Share HKPC</span>
                </div>
                <div>
                  <span class="cds-body-hero-demibold" style="color: var(--cds-color-m-green)">{{
                    splitAgent?.hkpcPercentage ? splitAgent?.hkpcPercentage + '%' : '-'
                  }}</span>
                </div>
              </div>
              <div class="l-ml-7">
                <div>
                  <span class="cds-body2">Share Commission</span>
                </div>
                <div>
                  <span class="cds-body-hero-demibold" style="color: var(--cds-color-m-green)">{{
                    splitAgent?.commissionPercentage ? splitAgent?.commissionPercentage + '%' : '-'
                  }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ng-template #pcSplitAgent>
          <div class="split-agent" *ngFor="let splitAgent of splitAgentInfos; let i = index">
            <div class="l-d-f l-ai-cen">
              <div style="flex: 3">
                <span class="cds-body2" style="color: var(--cds-color-light-3-dark-navy)">{{ 'case-details.splitAgentLabel' | lang }}</span>
              </div>
              <div class="l-d-f" [ngStyle]="{ flex: from === 'superAdmin' ? 5 : 8 }">
                <div class="link-box">
                  <span class="cds-body1-demibold">
                    <cds-link
                      [config]="{
                        type: 'hyperlink',
                        label: splitAgent ? splitAgent.agentCd : '',
                        href: '#'
                      }">
                    </cds-link>
                  </span>
                </div>
                <div class="l-ml-3">
                  <span class="cds-body1-demibold">{{ getAgentName(splitAgent) }}</span>
                </div>
              </div>
              <div *ngIf="from === 'superAdmin'" style="flex: 3" class="l-d-f">
                <div style="flex: 1; text-align: end"><span class="cds-body2">Share HKPC</span></div>
                <div style="flex: 1; text-align: end" class="l-ml-5"><span class="cds-body2">Share Commission</span></div>
              </div>
            </div>
            <div class="l-d-f l-ai-cen l-mt-2">
              <div style="flex: 3"></div>
              <div [ngStyle]="{ flex: from === 'superAdmin' ? 5 : 8 }">
                <cds-checkbox
                  [checked]="splitAgent ? splitAgent.regulatedAct : false"
                  [disabled]="true"
                  label="{{ 'case-details.regularActivityLabel' | lang }}"></cds-checkbox>
              </div>
              <div *ngIf="from === 'superAdmin'" style="flex: 3" class="l-d-f">
                <div style="flex: 1; text-align: end">
                  <span class="cds-body-hero-demibold" style="color: var(--cds-color-m-green)">{{
                    splitAgent?.hkpcPercentage ? splitAgent?.hkpcPercentage + '%' : '-'
                  }}</span>
                </div>
                <div style="flex: 1; text-align: end" class="l-ml-5">
                  <span class="cds-body-hero-demibold" style="color: var(--cds-color-m-green)">{{
                    splitAgent?.commissionPercentage ? splitAgent?.commissionPercentage + '%' : '-'
                  }}</span>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </div>

      <div class="l-mt-9">
        <span class="cds-h4-demibold">{{ 'case-details.transferDetailsHeader' | lang }}</span>
      </div>

      <div class="l-mt-5 transfer-details-box">
        <div class="transfer-details-row">
          <div class="transfer-details-base">
            <div class="column-no">
              <span class="cds-body1">#</span>
            </div>
            <div class="column-product-type">
              <span class="cds-body1">{{ 'case-details.productTypeLabel' | lang }}</span>
            </div>
            <div class="column-original-scheme">
              <span class="cds-body1">{{ 'case-details.originalSchemeLabel' | lang }}</span>
            </div>
            <div class="column-new-scheme">
              <span class="cds-body1">{{ 'case-details.newManulifeSchemeLabel' | lang }}</span>
            </div>
            <div class="column-transfer-status">
              <div class="l-d-f l-fd-col">
                <div class="l-d-f l-ai-cen">
                  <div class="l-mr-1">
                    <span class="cds-body1" style="white-space: nowrap">{{ 'case-details.transferStatusLabel' | lang }}</span>
                  </div>
                  <cds-icon class="cursor-pointer" (click)="openTransferStatusStatus()" icon="action:info_1"></cds-icon>
                </div>
                <div>
                  <span class="cds-body2" style="white-space: nowrap">(Source: eMPF Portal)</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ng-container *ngFor="let transferDetail of transferDetails; let i = index">
          <div class="transfer-details-row">
            <div class="transfer-details-base">
              <div class="column-no" style="align-self: flex-start">
                <span class="cds-body1">{{ i + 1 }}</span>
              </div>
              <div class="column-product-type" style="align-self: flex-start">
                <span class="cds-body1">{{ transferDetail.productType }}</span>
              </div>
              <div class="column-original-scheme" style="align-self: flex-start">
                <div>
                  <div class="transfer-detail-item-title" style="margin-top: 0">
                    <span class="cds-body2">Origianl trustee name</span>
                  </div>
                  <div class="transfer-detail-item-value">
                    <span class="cds-body1-demibold">{{ transferDetail.originalScheme.originalTrusteeName }}</span>
                  </div>
                </div>
                <div>
                  <div class="transfer-detail-item-title">
                    <span class="cds-body2">Scheme name</span>
                  </div>
                  <div class="transfer-detail-item-value">
                    <span class="cds-body1-demibold">{{ transferDetail.originalScheme.schemeName }}</span>
                  </div>
                </div>
                <div>
                  <div class="transfer-detail-item-title">
                    <span class="cds-body2">Scheme member account number</span>
                  </div>
                  <div class="transfer-detail-item-value">
                    <span class="cds-body1-demibold">{{ transferDetail.originalScheme.schemeMemberAccNum }}</span>
                  </div>
                </div>
              </div>
              <div class="column-new-scheme">
                <div>
                  <div class="transfer-detail-item-title" style="margin-top: 0">
                    <span class="cds-body2">New trustee name</span>
                  </div>
                  <div class="transfer-detail-item-value">
                    <span class="cds-body1-demibold">{{ transferDetail.newMILScheme.newTrusteeName }}</span>
                  </div>
                </div>
                <div>
                  <div class="transfer-detail-item-title">
                    <span class="cds-body2">Scheme name</span>
                  </div>
                  <div class="transfer-detail-item-value">
                    <span class="cds-body1-demibold">{{ transferDetail.newMILScheme.schemeName }}</span>
                  </div>
                </div>
                <div>
                  <div class="transfer-detail-item-title">
                    <span class="cds-body2">Scheme member account number</span>
                  </div>
                  <div class="transfer-detail-item-value">
                    <span class="cds-body1-demibold">{{ transferDetail.newMILScheme.schemeMemberAccNum }}</span>
                  </div>
                </div>
                <div>
                  <div class="transfer-detail-item-title">
                    <span class="cds-body2">Account type</span>
                  </div>
                  <div class="transfer-detail-item-value">
                    <span class="cds-body1-demibold">{{ transferDetail.newMILScheme.accType }}</span>
                  </div>
                </div>
              </div>
              <div class="column-transfer-status" style="align-self: flex-start">
                <div class="l-d-f l-ai-cen">
                  <div class="l-mr-1">
                    <app-status-light
                      [color]="
                        caseSummaryService.transferStatus({
                          transferStatusEnum: transferDetail.transferStatusEnum
                        }).color
                      "
                      [text]="
                        caseSummaryService.transferStatus({
                          transferStatusEnum: transferDetail.transferStatusEnum
                        }).text | lang
                      "></app-status-light>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="transferDetail.transferStatusEnum === 40" class="transfer-details-reject-reason">
              <div class="reject-reason-item">
                <div class="empty"></div>
                <div class="reject-reason-content" style="border-top: 1px solid var(--cds-divider-default)">
                  <div class="l-pt-2 l-pb-2 l-pl-4 l-pr-4">
                    <span class="cds-body1">#</span>
                  </div>
                  <div class="l-pt-2 l-pb-2 l-pl-4 l-pr-4">
                    <span class="cds-body2">Reject Reason(s)</span>
                  </div>
                </div>
              </div>

              <div *ngFor="let reasonNum of rejectReasonsNum(transferDetail.rejectReasons); let i = index" class="reject-reason-item">
                <div class="empty"></div>
                <div class="reject-reason-content">
                  <div class="l-pt-2 l-pb-2 l-pl-4 l-pr-4">
                    <span class="cds-body2">{{ i + 1 }}</span>
                  </div>
                  <div class="l-pt-2 l-pb-2 l-pl-4 l-pr-4">
                    <span class="cds-body2-demibold">{{ getRejectReason(reasonNum) }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
  <div class="l-ml-7 l-mt-4">
    <span class="cds-body2">{{ 'case-details-er.notice' | lang }}</span>
  </div>
  <div class="l-ml-7 l-mt-5">
    <cds-button [config]="secondaryButtonConfig" label="{{ 'common.backToTop' | lang }}" (click)="backToTop()"></cds-button>
  </div>
</div>
