<app-cd-popup>
  <cds-popup-content>
    <div class="content l-pl-2 l-pr-2">
      <div class="cds-h2-light l-mb-0 l-pb-1">
        {{ (isCreate ? 'calendar.event.review' : 'calendar.event.review.change') | lang }}
      </div>
      <div class="row l-plr-0 l-mt-4 l-mb-2">
        <div class="col-xs-12 col-md-11 l-d-f alert l-pl-5 l-pr-5 l-pt-4 l-pb-4">
          <cds-icon class="cds-demibold l-pr-3" size="sm" icon="action:megaphone"></cds-icon>
          <div>
            <div class="cds-demibold l-pb-1">
              {{ 'common.alert' | lang }}
            </div>
            <div>
              {{ 'calendar.approve.alert' | lang }}
            </div>
          </div>
        </div>
      </div>
      <div class="l-pt-4">
        <div class="col-xs-12 col-md-8 l-plr-0 cds-demibold cds-dropdown-label ng-star-inserted label-color l-pb-3">
          {{ 'calendar.event.name' | lang }}
        </div>
        <div class="cds-h3-light l-mb-0">{{ title | lang }}</div>
      </div>
      <div class="row l-plr-0 l-pt-4" *ngIf="originalDate || newDate">
        <div class="row l-plr-0 l-pt-4 col-xs-12 col-md-8">
          <div class="col-xs-12 col-md-12 col-lg-11 l-plr-0 l-pb-4">
            <div class="row l-plr-0">
              <div *ngIf="originalDate" class="col-xs-5 col-md-5 l-pb-2 l-pt-2 l-pl-4 l-pr-4 cds-white div-border-top div-border-bottom">
                {{ 'calendar.event.originalDate' | lang }}
              </div>
              <div *ngIf="originalDate" class="col-xs-2 col-md-2 l-pb-2 l-pt-2 l-pl-4 l-pr-4 cds-white div-border-top div-border-bottom">
                <cds-icon icon="arrow:right"></cds-icon>
              </div>
              <div class="col-xs-5 col-md-5 l-pb-2 l-pt-2 l-pl-4 l-pr-4 cds-white div-border-top div-border-bottom">
                {{ 'calendar.event.newDate' | lang }}
              </div>
            </div>
            <div>
              <div class="row l-plr-0">
                <div *ngIf="originalDate" class="col-xs-5 col-md-5 l-pb-2 l-pt-2 l-pl-4 l-pr-4 div-border-bottom cds-white">
                  {{ originalDate | lang }}
                </div>
                <div *ngIf="originalDate" class="col-xs-2 col-md-2 l-pb-2 l-pt-2 l-pl-4 l-pr-4 div-border-bottom cds-white">
                  <cds-icon icon="arrow:right"></cds-icon>
                </div>
                <div class="col-xs-5 col-md-5 l-pb-2 l-pt-2 l-pl-4 l-pr-4 div-border-bottom cds-white">
                  {{ newDate | lang }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </cds-popup-content>
  <cds-popup-actions>
    <cds-button [style]="'secondary'" size="sm" (click)="decline()">{{ 'common.decline' | lang }}</cds-button>
    <cds-button size="sm" (click)="onSubmit()">{{ 'common.approve' | lang }}</cds-button>
  </cds-popup-actions>
</app-cd-popup>
