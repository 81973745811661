/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { Sort } from '../table-expand';

@Component({
  selector: 'app-table-expand-col',
  templateUrl: './table-expand-col.component.html',
  styleUrls: ['./table-expand-col.component.scss'],
})
export class TableExpandColComponent implements OnInit {
  constructor() {}

  @Input() label = '';
  @Input() prop = '';
  @Input() width?: string;
  @Input() sort?: Sort;
  @Input() template?: TemplateRef<any>;

  ngOnInit(): void {}
}
