import { CdsDropdownConfig } from '@cds/ng-core/dropdown';
import { deepCopy } from 'src/app/utils/copy';
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CdsButtonConfig } from '@cds/ng-core/button';
import { CdsLangService } from '@cds/ng-core/lang';
import { CdsTextfieldConfig } from '@cds/ng-core/textfield';
import { CdsPopupService, CDS_POPUP_DATA, MatDialogRef } from '@cds/ng-web-components/popup';
import { finalize } from 'rxjs';
import { AlertPopupComponent } from 'src/app/shared/alert-popup/alert-popup.component';
import { PermissionAccess, PermissionItem } from 'src/app/core/models/enum/permission.enum';
import { ContinuePopupComponent } from 'src/app/shared/continue-popup/continue-popup.component';
import { dateValidator, hkidValidator, passportValidator } from 'src/app/shared/validators/validators';
import { EmployerService } from '../employer.service';
import { AgentSep, AgentSepCheckResult, AgentSepCheckResultTypeE, agentSepUserIdTypeOptions } from '../employer';
import { DateTime } from 'luxon';
import { CdsIconConfig } from '@cds/ng-core/icon';
import moment from 'moment';

@Component({
  selector: 'app-agent-sep-edit',
  templateUrl: './agent-sep-edit.component.html',
  styleUrls: ['./agent-sep-edit.component.scss'],
})
export class AgentSepEditComponent implements OnInit {
  confirmButtonConfig: CdsButtonConfig = { size: 'sm' };

  saveButtonConfig: CdsButtonConfig = { size: 'sm' };

  resetButtonConfig: CdsButtonConfig = { size: 'sm', style: 'secondary' };

  backToEditButtonConfig: CdsButtonConfig = { size: 'sm', style: 'secondary' };

  originFormValue: AgentSep = {};

  formSendValue: AgentSep = {};

  PermissionAccess = PermissionAccess;

  PermissionItem = PermissionItem;

  showDatePicker = true;

  inEdit = true;

  showButton = true;

  agentTextfieldConfig: CdsTextfieldConfig = {
    label: 'Agent Code',
    placeholder: 'Input Agent Code',
    type: 'text',
  };

  accountNumbertxtfieldConfig: CdsTextfieldConfig = {
    label: 'Employer Account Code',
    placeholder: 'Input Employer Account Code',
    type: 'text',
  };

  companyNametxtfieldConfig: CdsTextfieldConfig = {
    label: 'Company Name',
    placeholder: 'Input Company Name',
    type: 'text',
  };

  invalidHkidTip = 'Please input HKID with format XXXXXXX(X)';

  invalidPassportTip = 'Please enter the correct passport number';

  form: FormGroup = new FormGroup({});

  isLoading = false;

  nowTime = DateTime.now();

  nowTimeInstr = this.nowTime.toFormat('dd/MM/yyyy');

  maxTime = this.getNowTime();

  maxTimeInStr = DateTime.fromJSDate(this.maxTime).toFormat('dd/MM/yyyy');

  get saveDisabled() {
    return !(this.form.dirty && this.form.valid) || this.isLoading;
  }

  get resetDisabled() {
    return !this.form.dirty || this.isLoading;
  }

  get sepCommDateControl() {
    return this.form.get('sepCommDate');
  }

  get dobControl() {
    return this.form.get('dob');
  }

  get userIdTypeControl() {
    return this.form.get('userIdType');
  }

  get userIdControl() {
    return this.form.get('userId');
  }

  agentSepUserIdTypeOptions = agentSepUserIdTypeOptions;

  agentSepUserIdTypeConfig: CdsDropdownConfig = {
    label: 'HKID/Passport',
    options: agentSepUserIdTypeOptions,
    placeholder: '',
  };

  get userIdConfirmStr() {
    const typeValue = this.userIdTypeControl?.value;
    const typeLabel = this.service.optionLabelRender(typeValue, agentSepUserIdTypeOptions);

    return `(${typeLabel}) ${this.userIdControl?.value}`;
  }

  iconConfig: CdsIconConfig = {
    color: '#00A758',
    size: 'sm',
  };

  checkResultInfoList: AgentSepCheckResult[] = [];

  AgentSepCheckResultTypeE = AgentSepCheckResultTypeE;

  checked = false;

  checkOk = true;

  warnIconConfig: CdsIconConfig = {
    color: '#D77D28',
  };

  errorIconConfig: CdsIconConfig = {
    color: '#C14A36',
  };

  warningTitle = 'The same agent was enrolled before.';
  warningText = 'Existing records will be replaced by clicking confirm to proceed.';

  warn: any = {
    sepCommDate: '',
    dob: '',
    userIdType: '',
    userId: '',
  };

  constructor(
    private dialogRef: MatDialogRef<AgentSepEditComponent>,
    private cdsPopup: CdsPopupService,
    private langService: CdsLangService,
    private service: EmployerService,
    @Inject(CDS_POPUP_DATA)
    public data: AgentSep
  ) {}

  ngOnInit() {
    this.initForm();
  }

  initForm() {
    this.form?.markAsUntouched();
    this.form?.markAsPristine();

    this.checkResultInfoList = [];
    this.warn = {
      sepCommDate: '',
      dob: '',
      userIdType: '',
      userId: '',
    };

    this.form = new FormGroup({
      sepCommDate: new FormControl(this.dateTrans(this.data.sepCommDate), [Validators.required, dateValidator('create.dateError', '', this.maxTimeInStr)]),
      dob: new FormControl(this.dateTrans(this.data.sepCommDate), [Validators.required, dateValidator('create.dateError', '', this.maxTimeInStr)]),
      userIdType: new FormControl(this.data.hkid ? 'hkid' : 'passport'),
      userId: new FormControl(this.data.hkid || this.data.passport, [Validators.required]),
    });
  }

  changeFormImmediately(key: string, value: string) {
    if (value && typeof value === 'string') {
      this.form.get(key)?.setValue(value.trim());
    }
  }

  getSendFormValue() {
    const value: AgentSep = {};
    Object.keys(this.originFormValue).forEach(key => {
      const currentFromValue: AgentSep = this.form.value;
      if ((currentFromValue as any)[key] !== (this.originFormValue as any)[key]) {
        (value as any)[key] = (currentFromValue as any)[key];
      }
    });
    this.formSendValue = value;
  }

  getRequestParams(isCheck = false) {
    const params: AgentSep = deepCopy(this.form.value);
    if (params.userIdType == 'hkid') {
      params.hkid = params.userId;
      params.passport = '';
    } else {
      params.passport = params.userId;
      params.hkid = '';
    }
    params.id = this.data.id;
    params.sepCommDate = this.requestDateTrans(params.sepCommDate);
    params.dob = this.requestDateTrans(params.dob);
    delete params.userId;
    delete params.userIdType;
    params.check = isCheck;
    return params;
  }

  continue() {
    if (this.saveDisabled) {
      return;
    }

    this.reloadButton();

    if (this.checkOk) {
      this.inEdit = false;
    } else {
      this.checkResultInfoList = [];
      this.warn = {
        sepCommDate: '',
        dob: '',
        userIdType: '',
        userId: '',
      };
      this.check();
    }
  }

  save() {
    this.doSave();

    this.reloadButton();
  }

  check() {
    const params = this.getRequestParams(true);

    this.isLoading = true;

    this.service
      .agentSepEdit(params)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe({
        next: res => {
          if (res.result === 0 && res.data) {
            if (res.data.result === false) {
              this.checked = true;
              this.checkResultInfoList = res.data.tips || [];

              this.checkResultInfoList.forEach(item => {
                let key = '';
                switch (item.field) {
                  case '1':
                    key = 'sepCommDate';
                    break;
                  case '2':
                    key = 'userId';
                    break;
                  case '3':
                    key = 'dob';
                    break;
                }

                if (item.type === AgentSepCheckResultTypeE.ERR) {
                  this.form.get(key)?.setErrors({ backendInvalid: item.message });
                } else if (item.type === AgentSepCheckResultTypeE.WARN) {
                  this.warn[key] = item.message;
                }
              });

              this.form.updateValueAndValidity();

              console.log('form', this.form, this.warn);

              if (this.checkResultInfoList.some(item => item.type === AgentSepCheckResultTypeE.ERR)) {
                this.checkOk = false;
                this.inEdit = true;
              } else if (this.checkResultInfoList.some(item => item.type === AgentSepCheckResultTypeE.WARN)) {
                this.checkOk = true;
              } else {
                this.checkOk = true;
                this.inEdit = false;
              }
            } else {
              this.checkOk = true;
              this.inEdit = false;
            }
          } else {
            this.requestAlert(res.message);
          }
        },
        error: err => {
          this.requestAlert(err.message);
        },
      });
  }

  doSave() {
    const params = this.getRequestParams();

    this.isLoading = true;

    this.service
      .agentSepEdit(params)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe({
        next: res => {
          if (res.result === 0) {
            this.popupWithReload();
          } else {
            this.requestAlert(res.message);
          }
        },
        error: err => {
          this.requestAlert(err.message);
        },
      });
  }

  reset() {
    if (this.resetDisabled) {
      return;
    }

    this.reloadButton();

    this.cdsPopup
      .open(ContinuePopupComponent, {
        size: 'sm',
        data: { message: this.langService.translate('common.action.cancel.italic') },
      })
      .afterClosed()
      .subscribe(result => {
        if (result?.agree) {
          this.showDatePicker = false;
          setTimeout(() => {
            this.showDatePicker = true;
            this.initForm();
          });
        }
      });
  }

  delete() {
    this.cdsPopup
      .open(ContinuePopupComponent, {
        size: 'sm',
        data: {
          message: this.langService.translate('agent.delete-tip'),
          continueButtonName: this.langService.translate('common.delete'),
        },
      })
      .afterClosed()
      .subscribe(result => {
        if (result?.agree) {
          this.isLoading = true;
          this.service
            .agentSepDelete(this.data.id as string)
            .pipe(
              finalize(() => {
                this.isLoading = false;
              })
            )
            .subscribe({
              next: res => {
                if (res.result === 0) {
                  this.popupWithReload();
                } else {
                  this.requestAlert(res.message);
                }
              },
              error: err => {
                this.requestAlert(err.message);
              },
            });
        }
      });
  }

  getNowTime() {
    return moment().toDate();
  }

  getMaxFromDate = (toDateStr?: string) => {
    if (!toDateStr) {
      return this.getNowTime();
    }
    return moment(toDateStr, 'DD/MM/YYYY').toDate();
  };

  close() {
    if (!this.resetDisabled) {
      this.cdsPopup
        .open(ContinuePopupComponent, {
          size: 'sm',
          data: { message: this.langService.translate('common.action.cancel.italic') },
        })
        .afterClosed()
        .subscribe(result => {
          if (result?.agree) {
            this.dialogRef.close();
          }
        });
    } else {
      this.dialogRef.close();
    }
  }

  requestAlert(message: string) {
    this.cdsPopup
      .open(AlertPopupComponent, {
        size: 'sm',
        data: {
          title: 'Error',
          message: `<span class='cds-h4-light'>${message}</span>`,
          buttonName: this.langService.translate('confirm'),
        },
      })
      .afterClosed()
      .subscribe(() => {});
  }

  popupWithReload() {
    this.cdsPopup
      .open(AlertPopupComponent, {
        size: 'sm',
        data: {
          message: this.langService.translate('common.action.success.italic'),
          buttonName: this.langService.translate('common.gotItWithExclamation'),
        },
      })
      .afterClosed()
      .subscribe(() => {
        this.dialogRef.close({
          agree: true,
          reload: true,
        });
      });
  }

  dateTrans(date?: string) {
    if (!date) return '';
    return DateTime.fromFormat(date, 'yyyy-MM-dd').toFormat('dd/MM/yyyy');
  }

  requestDateTrans(date?: string) {
    if (!date) return '';
    return DateTime.fromFormat(date, 'dd/MM/yyyy').toFormat('yyyy-MM-dd');
  }

  backToEdit() {
    this.inEdit = true;

    this.reloadButton();
  }

  dropdownChange(value: string) {
    const dropdownContorl = this.userIdControl;
    dropdownContorl?.clearValidators();
    dropdownContorl?.setErrors({});
    dropdownContorl?.updateValueAndValidity();

    if (value === 'hkid') {
      dropdownContorl?.setValidators([Validators.required, Validators.maxLength(80), hkidValidator(this.invalidHkidTip)]);
    } else {
      dropdownContorl?.setValidators([Validators.required, Validators.maxLength(80), passportValidator(this.invalidPassportTip)]);
    }
  }

  userIdChange(input: string) {
    const value = input.replace(/[\u4e00-\u9fa5]/gi, '');
    this.userIdControl?.setValue(value.trim());
  }

  getWarnMessage = (key: string) => {
    return this.warn[key];
  };

  onControlChange(key: string) {
    (this.warn as any)[key] = '';

    this.checkOk = false;
  }

  reloadButton = () => {
    this.showButton = false;
    setTimeout(() => {
      this.showButton = true;
    });
  };
}
