/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CdPopupContinueComponent } from './cd-popup-continue/cd-popup-continue.component';
import { CdPopupDeleteComponent } from './cd-popup-delete/cd-popup-delete.component';
import { CdPopupGotComponent } from './cd-popup-got/cd-popup-got.component';

export { MatDialogRef, MAT_DIALOG_DATA };
export const CDS_POPUP_DATA = MAT_DIALOG_DATA;

export interface CdPopupConfig {
  size?: string;
  isShowClose?: boolean;
  type?: string;
  panelClass?: string[];
  data?: { [key: string]: any };
}

export enum CdPopupSize {
  SMALL = 'sm',
  MEDIUM = 'md',
  LARGE = 'lg',
  FULL = 'full',
}

export enum CdPopupType {
  GOT = 'GOT',
  CONTINUE = 'CONTINUE',
  DELETE = 'DELETE',
  CONFIRM = 'CONFIRM',
  WARNING = 'WARNING',
}

@Injectable({
  providedIn: 'root',
})
export class CdPopupService {
  currentDialogRef: any;

  constructor(private dialog: MatDialog) {}

  open(component: any, config?: CdPopupConfig): MatDialogRef<any> {
    const data = config ? config.data || {} : {};
    const isShowClose = config ? (config.isShowClose === undefined ? true : config.isShowClose) : true;
    const panelClass = config ? config.panelClass || [] : [];
    const size = config ? config.size || CdPopupSize.SMALL : CdPopupSize.SMALL;

    this.currentDialogRef = this.dialog.open(component, {
      disableClose: true,
      autoFocus: false,
      maxWidth: '100%',
      maxHeight: '100%',
      panelClass: ['cds-popup-container', `cds-popup-${size}`, ...panelClass],
      data: {
        ...data,
        isShowClose,
        __cdsPopupSize: size,
      },
    });

    return this.currentDialogRef;
  }
  openCommon(config?: CdPopupConfig): MatDialogRef<any> {
    let component: any = CdPopupGotComponent;
    const data = config ? config.data || {} : {};
    const isShowClose = config ? (config.isShowClose === undefined ? true : config.isShowClose) : true;
    const panelClass = config ? config.panelClass || [] : [];
    const size = config ? config.size || CdPopupSize.SMALL : CdPopupSize.SMALL;
    const type = config ? config.type || CdPopupType.GOT : CdPopupType.GOT;

    if (type === CdPopupType.GOT) {
      component = CdPopupGotComponent;
    }

    if (type === CdPopupType.CONTINUE) {
      component = CdPopupContinueComponent;
    }

    if (type === CdPopupType.DELETE) {
      component = CdPopupDeleteComponent;
    }

    this.currentDialogRef = this.dialog.open(component, {
      disableClose: true,
      autoFocus: false,
      maxWidth: '100%',
      maxHeight: '100%',
      panelClass: ['cds-popup-container', `cds-popup-${size}`, ...panelClass],
      data: {
        ...data,
        isShowClose,
        __cdsPopupSize: size,
      },
    });

    return this.currentDialogRef;
  }
}
