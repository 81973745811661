/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit } from '@angular/core';
import { CdsButtonConfig } from '@cds/ng-core/button';
import { CdsLangService } from '@cds/ng-core/lang';
import { CdsPopupService } from '@cds/ng-web-components/popup';
import { finalize } from 'rxjs';
import { BTN_CONFIG_PRIMARY, BTN_CONFIG_SECONDARY } from 'src/app/config/btn.config';
import { PermissionAccess, PermissionItem } from 'src/app/core/models/enum/permission.enum';
import { IPageableRequest } from 'src/app/core/models/request';
import { PermissionService } from 'src/app/core/services/permission.service';
import { AlertPopupComponent } from 'src/app/shared/alert-popup/alert-popup.component';
import { ColumnConfig, PageConfig, SortMode } from 'src/app/shared/data-table';
import { ToastAlertService } from 'src/app/shared/toast-alert.service';
import { AgentSepAddComponent } from '../agent-sep-add/agent-sep-add.component';
import { AgentSepEditComponent } from '../agent-sep-edit/agent-sep-edit.component';
import { AgentSep, ColumnSort, EnrolmentStatusE, enrolmentStatusMapObj } from '../employer';
import { EmployerService } from '../employer.service';
import { UploadAgentSepListFileComponent } from '../upload-agent-sep-list-file/upload-agent-sep-list-file.component';

@Component({
  selector: 'app-agent-sep',
  templateUrl: './agent-sep.component.html',
  styleUrls: ['./agent-sep.component.scss'],
})
export class AgentSepComponent implements OnInit {
  uploadBtnCfg: CdsButtonConfig = BTN_CONFIG_SECONDARY;
  addBtnCfg: CdsButtonConfig = BTN_CONFIG_PRIMARY;

  totalElements = 0;

  canEdit = true;

  isLoading = false;

  showButton = true;

  dataSource: AgentSep[] = [];

  pageConfig: PageConfig = {
    current: 1,
    pageSize: 20,
    totalCount: 0,
  };

  EnrolmentStatusE = EnrolmentStatusE;

  columnsConfig: ColumnConfig[] = [
    {
      key: 'agentCode',
      title: 'Agent Code',
      sort: SortMode.DEFAULT,
    },
    {
      key: 'agentName',
      title: 'Agent Name',
      sort: SortMode.DEFAULT,
    },
    {
      key: 'sepCommDate',
      title: 'SEP Commencement Date',
      sort: SortMode.DEFAULT,
    },
    {
      key: 'dob',
      title: 'Date of Birth',
      sort: SortMode.DEFAULT,
    },
    {
      key: 'hkid',
      title: 'HKID/ Passport',
      sort: SortMode.DEFAULT,
    },
    {
      key: 'mbAcctCd',
      title: 'Member Account Code',
      sort: SortMode.DEFAULT,
    },
    {
      key: 'createdAt',
      title: 'Created Date',
      sort: SortMode.DEFAULT,
    },
    {
      key: 'status',
      title: 'Status',
      sort: SortMode.DEFAULT,
    },
  ];

  columnsConfigWithEditPermission: ColumnConfig[] = [
    ...this.columnsConfig,
    {
      key: 'action',
      title: 'Action',
    },
  ];

  columnSort: ColumnSort = {
    key: 'sepCommDate',
    value: SortMode.DOWN,
  };

  enrolmentStatusMapObj = enrolmentStatusMapObj;

  constructor(
    private service: EmployerService,
    private cdsPopup: CdsPopupService,
    private toastAlert: ToastAlertService,
    private permissionService: PermissionService,
    private langService: CdsLangService
  ) {}

  ngOnInit(): void {
    this.loadTable();

    this.permissionService.hasPermission(PermissionAccess.W, PermissionItem.CUSTOMER_EMPLOYER).then(hasPermission => {
      this.canEdit = hasPermission;
    });
  }

  getRequestParams() {
    const params: IPageableRequest = {
      page: (this.pageConfig.current as number) - 1,
      size: 20,
    };
    params.sort = `${this.columnSort.key},${this.columnSort.value}`;
    return params;
  }

  loadTable() {
    const params: IPageableRequest = this.getRequestParams();
    this.isLoading = true;

    this.service
      .getAgentSepList(params)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe(data => {
        if (data.result === 0 && data.data) {
          this.isLoading = false;
          this.dataSource = data.data.empty ? [] : data.data.content;
          this.totalElements = data.data.totalElements;

          this.pageConfig = {
            ...this.pageConfig,
            totalCount: data.data.totalElements,
          };
        } else {
          this.toastError(data.message);
        }
      });
  }

  add() {
    this.cdsPopup
      .open(AgentSepAddComponent, {
        size: 'lg',
      })
      .afterClosed()
      .subscribe(() => {
        this.loadTable();
      });

    this.reloadButton();
  }

  upload() {
    this.cdsPopup
      .open(UploadAgentSepListFileComponent, {
        size: 'md',
      })
      .afterClosed()
      .subscribe(result => {
        if (result?.agree) {
          this.popupWithReload();
        } else {
          this.isLoading = false;
        }
      });

    this.reloadButton();
  }

  popupWithReload() {
    this.cdsPopup
      .open(AlertPopupComponent, {
        size: 'sm',
        data: {
          message: this.langService.translate('common.action.uploadSuccess'),
          buttonName: this.langService.translate('common.gotItWithExclamation'),
        },
      })
      .afterClosed()
      .subscribe(() => {
        this.loadTable();
      });
  }

  edit(element: AgentSep) {
    this.cdsPopup
      .open(AgentSepEditComponent, {
        size: 'md',
        data: element,
      })
      .afterClosed()
      .subscribe(() => {
        this.loadTable();
      });
  }

  reloadTable(event: any) {
    this.pageConfig = {
      ...this.pageConfig,
      current: event.current,
    };
    this.columnSort =
      event.column && event.column.sort !== SortMode.DEFAULT
        ? {
            key: event.column?.key,
            value: event.column?.sort,
          }
        : {
            key: 'sepCommDate',
            value: SortMode.DOWN,
          };
    this.loadTable();
  }

  toastError(errorMessage: string) {
    this.toastAlert.show('error', 'common.error', errorMessage, 5000);
  }

  reloadButton = () => {
    this.showButton = false;
    setTimeout(() => {
      this.showButton = true;
    });
  };
}
