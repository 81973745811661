<div class="row l-pt-4 l-pb-4 l-mt-7">
  <div class="col-xs-12 cds-color-green title-container anchor-parent">
    <div class="anchor-element" id="specialQuote"></div>
    <span class="cds-h4-demibold">Special Quote</span>
  </div>

  <div class="col-xs-12">
    <div class="p-line"></div>
  </div>

  <div class="col-xs-12">
    <div>
      <div class="date-filter-container">
        <div class="date-filter" [formGroup]="formGroup">
          <div class="l-mr-3 label">SQ Submission Date:</div>
          <cds-dropdown
            formControlName="month"
            [config]="monthCfg"
            (change)="dateMonthChange()"
            [disabled]="noLoadMonth"
            class="l-pr-5 month-selector"
            [ngClass]="{ 'text-gray': noLoadMonth }"></cds-dropdown>
          <cds-dropdown formControlName="year" *ngIf="yearCfg" (change)="dateYearChange()" [disabled]="isLoading" [config]="yearCfg"></cds-dropdown>
        </div>
      </div>

      <div *ngIf="isLoading" class="col-xs-12 l-plr-0">
        <div class="progress">
          <cds-progress mode="indeterminate" type="bar"></cds-progress>
        </div>
      </div>

      <div class="table-container">
        <table class="table">
          <caption></caption>
          <!-- header -->
          <tr class="table-title l-pt-2 l-pb-2">
            <th *ngFor="let item of columnsConfig">{{ item.title }}</th>
            <th *ngIf="canEdit" class="border-left bold">Action</th>
          </tr>
          <ng-container *ngFor="let element of dataSource; let index = index">
            <!-- line data -->
            <tr [class.border-bottom]="!element.remarks">
              <td class="main-text">
                {{ element.sqReferenceNo }}
              </td>
              <td>
                {{ employerService.optionLabelRender | fn : element.quoteType : SpecialQuoteTypeOptions }}
              </td>
              <td>
                {{ element.sqSubmissionDate }}
              </td>
              <td>
                {{ employerService.optionLabelRender | fn : element.quoteStatus : SpecialQuoteStatusOptions }}
              </td>
              <td>
                {{ element.lastStatusUpdatedDate }}
              </td>
              <ng-container *ngIf="canEdit">
                <td *ngIf="!!element.remarks" rowspan="2" class="border-left edit-area">
                  <div class="button-area" (click)="add(element)">
                    <div class="icon-size">
                      <cds-icon size="sm" icon="action:edit"></cds-icon>
                    </div>
                    <span class="cds-h6-demibold l-pl-2">Edit</span>
                  </div>
                </td>
                <td *ngIf="!element.remarks" class="border-left edit-area">
                  <div class="button-area" (click)="add(element)">
                    <div class="icon-size">
                      <cds-icon size="sm" icon="action:edit"></cds-icon>
                    </div>
                    <span class="cds-h6-demibold l-pl-2">Edit</span>
                  </div>
                </td>
              </ng-container>
            </tr>
            <!-- line data remarks -->
            <tr *ngIf="!!element.remarks" class="border-bottom">
              <td colspan="5" class="remark-td cds-detail2-demibold" l-pt-0>
                <div class="remark-area line-break">
                  <div
                    [appTextEllipsis]="element.remarks"
                    [appTextEllipsisContentMaxWidth]="1145"
                    (ellipsisChange)="showButton($event, element)"
                    [class.hidde]="!element.showAllRemarks">
                    <span class="remarks">Remarks: </span>
                  </div>

                  <div class="button bold" *ngIf="element.showAllButton" (click)="remarksToggle(index)">
                    {{ element.showAllRemarks ? 'Show Less' : 'Show All' }}
                  </div>
                </div>
              </td>
            </tr>
          </ng-container>
        </table>

        <div class="col-xs-12" *ngIf="!dataSource.length && !isLoading">
          <div class="no-result">
            <app-no-result-found></app-no-result-found>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- pagenation -->
  <div *ngIf="dataSource.length" class="col-xs-12 l-pt-4 l-pb-4 l-d-f l-jc-sb">
    <div class="pagination">
      <app-pagination [pageCount]="pageCount" [isLoading]="isLoading" [useOf]="true" (pageChange)="loadTable()"> </app-pagination>
    </div>
  </div>

  <div class="col-xs-12 l-mt-5" *ngIf="canEdit">
    <cds-button [config]="btnCfg" size="sm" (click)="add()" [disabled]="isLoading">
      <div class="button-area">
        <div class="div-icon">+</div>
        <span class="l-ml-2">Add Special Quote</span>
      </div>
    </cds-button>
  </div>
</div>
