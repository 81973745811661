<cds-navigation class="utility-header" bannerLogo="logo:Manulife_txt_rgb_HongKong">
  <!-- sidebar Start -->
  <cds-menu>
    <cds-menu-item #menuItem *ngFor="let menu of menus" icon="{{ menu.icon }}" label="{{ menu.label }}">
      <cds-sub-menu-item (click)="go(item.subLink)" *ngFor="let item of menu.subMenu" label="{{ item.subLabel }}"></cds-sub-menu-item>
    </cds-menu-item>
  </cds-menu>
  <!-- sidebar End -->

  <!-- headbar Start -->
  <cds-navigation-utilities>
    <cds-navigation-lang-utility
      [ngStyle]="{ display: isHideNavigationLang ? 'none' : 'unset' }"
      [(selected)]="selectedLang"
      [options]="langList"
      (selectedChange)="changeLang($event)">
    </cds-navigation-lang-utility>
    <cds-navigation-button-utility label="{{ 'logout.button' | lang }}" (cdsClick)="logout()"> </cds-navigation-button-utility>
  </cds-navigation-utilities>
  <!-- headbar End -->

  <!-- content container -->
  <cds-content id="contenContainer" style="height: 100%; display: flex; flex-direction: column">
    <app-admin-header></app-admin-header>
    <div class="layout-content div-bg-light-grey l-pb-8">
      <router-outlet></router-outlet>
    </div>
  </cds-content>
  <!-- footer -->
  <!-- <app-cds-navigation-footer-local
    [config]="footerConfig"
    class="l-ma-0"></app-cds-navigation-footer-local> -->
  <div class="footer l-pl-7 l-pr-7">
    <div class="logo-wrapper">
      <cds-icon class="logo" icon="logo:Manulife_rgb_w" [config]="{ color: 'var(--cds-bg-info)' }"></cds-icon>
    </div>
    <div class="text-wrapper">
      <span class="text cds-detail1">{{ 'common.footerText' | lang }}</span>
    </div>
  </div>
</cds-navigation>
