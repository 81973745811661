<div class="cd-inline-alert">
  <div class="line" [ngClass]="[type]"></div>
  <div class="l-ml-4 l-mr-4 l-pt-4">
    <cds-icon style="width: 30px; height: 30px" icon="action:caution"></cds-icon>
  </div>
  <div class="l-mt-4 l-mb-4 l-pr-6">
    <div>
      <span class="cds-body1-demibold">{{ title }}</span>
    </div>
    <div>
      <span class="cds-detail1">{{ text }}</span>
    </div>
  </div>
  <div class="close-button" *ngIf="showCloseBtn">
    <cds-icon icon="action:cross" (click)="onClose()"></cds-icon>
  </div>
</div>
