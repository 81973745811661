import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { CdsLangService } from '@cds/ng-core/lang';
import { CdRadioComponent, CdRadioConfig } from 'src/app/shared/cd-radio/cd-radio.component';

@Component({
  selector: 'app-regulated-activities',
  templateUrl: './regulated-activities.component.html',
  styleUrls: ['./regulated-activities.component.scss'],
})
export class RegulatedActivitiesComponent implements OnInit {
  @ViewChild('cdRadio', { static: false }) cdRadio?: CdRadioComponent;
  @Input() value?: boolean;
  @Output() valueChange = new EventEmitter<boolean>();
  @Input() formControl?: FormControl;
  config: CdRadioConfig = {
    options: [
      {
        label: this.cdsLangService.translate('common.yes'),
        value: true,
        disable: false,
      },
      {
        label: this.cdsLangService.translate('common.no'),
        value: false,
        disable: false,
      },
    ],
  };
  private _disable = false;
  @Input()
  get disable() {
    return this._disable;
  }
  set disable(val: boolean) {
    this._disable = val;
    this.refreshConfig();
  }
  @Input() group!: FormGroup;
  @Input() controlName = '';

  constructor(private cdsLangService: CdsLangService) {}

  ngOnInit(): void {}

  refreshConfig() {
    this.config = {
      options: [
        {
          label: this.cdsLangService.translate('common.yes'),
          value: true,
          disable: this._disable,
        },
        {
          label: this.cdsLangService.translate('common.no'),
          value: false,
          disable: this._disable,
        },
      ],
    };
    this.cdRadio?.refreshConfig();
  }
}
