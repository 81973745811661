<app-cd-page-title title="{{ 'scheme.master.title' | lang }}"> </app-cd-page-title>
<div class="row l-pt-5">
  <div class="col-xs-12 col-md-12">
    <div>
      <app-cd-table-title [title]="'common.recordList' | lang" [total]="pageConfig.totalCount ? pageConfig.totalCount : 0">
        <ng-template #tableAction>
          <app-permission [role]="permissionAccess" [permission]="permissionItem">
            <cds-button [config]="btnCfg" size="sm" (click)="create()">
              {{ 'scheme.master.create' | lang }}
            </cds-button>
          </app-permission>
        </ng-template>
      </app-cd-table-title>
    </div>
    <app-data-table
      [frontPagination]="false"
      [isLoading]="loading"
      [fixed]="false"
      [overlay]="overlay"
      [pageConfig]="pageConfig"
      [dataSource]="dataDisplayed"
      [columnsConfig]="columnsConfig"
      (reloadTable)="reloadTable($event)">
      <ng-template appDtBodyCellDef="schemeCode" let-element>
        <span class="cds-body1-demibold break-word db-click" (click)="schemeClick(element)">{{ element['schemeCode'] }}</span>
      </ng-template>
      <ng-template appDtBodyCellDef="currentInflation" let-element>
        <span>{{ element['currentInflation'].salaryInflationFactor }}%</span>
      </ng-template>
    </app-data-table>
  </div>
</div>
