/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit } from '@angular/core';
import { CdsButtonConfig } from '@cds/ng-core/button';
import { CdsColorTheme } from '@cds/ng-core/configuration';
import { CdsTextfieldConfig } from '@cds/ng-core/textfield';
import { NotificationModel } from './notificationmodel';
import { MatDialogRef } from '@cds/ng-web-components/popup';
import { CdsPopupService } from '@cds/ng-web-components/popup';
import { NotififacionpopupComponent } from '../notififacionpopup/notififacionpopup.component';
import { NotificationDataService } from './notification-data.service';
import { Router } from '@angular/router';
import { DateTime } from 'luxon';
import { LayoutHeaderService } from 'src/app/core/services/layout-header.service';
import { AdminHeaderConfig } from '../../layout/admin-layout/admin-header/admin-header-config';
import { FormControl } from '@angular/forms';
import { PreviewAudienceListPopupComponent } from '../preview-audience-list-popup/preview-audience-list-popup.component';
import { CdsIconConfig } from '@cds/ng-core/icon';
import { PermissionAccess, PermissionItem } from 'src/app/core/models/enum/permission.enum';
import { finalize } from 'rxjs';
import { ToastAlertService } from 'src/app/shared/toast-alert.service';

@Component({
  selector: 'app-notificationlist',
  templateUrl: './notificationlist.component.html',
  styleUrls: ['./notificationlist.component.scss'],
})
export class NotificationlistComponent implements OnInit {
  config: CdsButtonConfig = {
    label: 'Create Message',
    size: 'md',
    style: 'secondary',
  };
  pageindex = 1;
  color: CdsColorTheme = 'ml';
  pageCount = 15;
  pageSize = 5;
  txtfieldConfig: CdsTextfieldConfig = {
    type: 'number',
  };

  subscribedInfoLoading = false;
  listLoading = false;

  get showProgress() {
    return this.subscribedInfoLoading || this.listLoading;
  }
  pageindexChange = 1;
  totalCount = 0;
  pageindexInput = 1;
  summaryList: NotificationModel[] = [];
  headerButton: AdminHeaderConfig = {
    title: 'notification.list',
    //titleDetail: 'this is a detail text',
    // rightButton: {
    //   text: 'LayneButton',
    //   icon: {
    //     type: 'action:edit',
    //   },
    //   method: this.doMethodTest.bind(this),
    // },
    // backButton: {
    //   text: 'back to list',
    //   url: '#',
    //   method: this.doMethodTest.bind(this),
    // },
  };

  audienceFormControl!: FormControl;

  previewButtonCfg: CdsIconConfig = { color: '#EC6453' };

  categoryMap = {
    HCM_002: 'OFFER_AND_PROMOTIONS',
    HCM_004: 'MARKET_INSIGHTS',
  };
  ca = new Map();

  PermissionAccess = PermissionAccess;

  PermissionItem = PermissionItem;

  targetAudienceNo?: number;

  targetAudienceNoUpdateTime = '';

  constructor(
    private cdsPopup: CdsPopupService,
    public notificationService: NotificationDataService,
    private router: Router,
    private layoutHeaderService: LayoutHeaderService,
    private toastAlert: ToastAlertService
  ) {}

  ngOnInit(): void {
    this.layoutHeaderService.doHidenBackButton();
    this.totalCount = this.summaryList.length;
    this.audienceFormControl = new FormControl(this.notificationService.mobileSubscriptionList);
    this.ca = new Map(Object.entries(this.categoryMap));
    this.getNotificationList(1, this.pageSize);
    this.dosetHeaderTitle();
  }

  loadTable() {
    this.pageindex = 1;
    this.getNotificationList(this.pageindex, this.pageSize);
    if (this.audienceFormControl.value === this.notificationService.mobileSubscriptionList) {
      this.getSubscribedInfo();
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  doClickDetail(e: any, model: NotificationModel) {
    const obj = e.target.getElementsByClassName('icon')[0];
    if (obj.classList.contains('backrotate')) {
      obj.classList.add('gorotate');
      obj.classList.remove('backrotate');
      e.target.classList.add('short-des');
      model.isShowDetail = true;
    } else {
      obj.classList.add('backrotate');
      obj.classList.remove('gorotate');
      e.target.classList.remove('short-des');
      model.isShowDetail = false;
    }
  }
  pageChange(pageNo: number) {
    this.pageindex = pageNo;
    this.pageindexInput = pageNo;
    this.getNotificationList(this.pageindex, this.pageSize);
  }

  searchByInputPageNo(num: number) {
    if (num < 1) {
      num = 1;
    }
    if (num > this.pageCount) {
      num = this.pageCount;
    }
    this.pageindex = num;
    this.pageindexInput = num;
  }

  recallMessage(model: NotificationModel) {
    const popupRef: MatDialogRef<NotififacionpopupComponent> = this.cdsPopup.open(NotififacionpopupComponent, {
      size: 'md',
      data: {
        name: '',
      },
    });

    popupRef.afterClosed().subscribe(data => {
      if (data.agree == true) {
        this.cancelNotification(model.id.toString());
      }
    });
  }

  cancelNotification(id: string) {
    const cancelStatus = this.notificationService.cancelStatus(id);
    cancelStatus.subscribe((data: any) => {
      if (data.result == 0 && data.message == 'SUCCESS') {
        this.getNotificationList(this.pageindex, this.pageSize);
      } else {
        this.toastError(data.message);
      }
    });
  }

  getSubscribedInfo() {
    this.subscribedInfoLoading = true;
    this.notificationService
      .getSubscribed()
      .pipe(
        finalize(() => {
          this.subscribedInfoLoading = false;
        })
      )
      .subscribe(res => {
        if (res.result === 0 && res.data) {
          this.targetAudienceNo = res.data.subscribedSum;
          this.targetAudienceNoUpdateTime = DateTime.fromISO(res.data.updatedAt).setZone('Asia/Hong_Kong').toFormat('h:mma dd/MM/yyyy');
        }
      });
  }

  getNotificationList(pageIndex: number, pageSize: number) {
    this.listLoading = true;
    window.scrollTo(0, 0);
    const list = this.notificationService.getSummaryList(pageIndex, pageSize, this.audienceFormControl.value);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    list
      .pipe(
        finalize(() => {
          this.listLoading = false;
        })
      )
      .subscribe((data: any) => {
        if (data.result == 0 && data.message == 'SUCCESS') {
          const currentTime = DateTime.now();
          if (data.data.content && data.data.content.length > 0) {
            this.summaryList = data.data.content;
            this.summaryList.forEach(item => {
              item.showRecall = true;
              // Target Broadcast Datetime at least 4 minutes later than current datetime to show recall
              if (
                DateTime.fromISO(item.targetBroadcastDate).minus({
                  minutes: 4,
                }) < currentTime
              ) {
                item.showRecall = false;
              }
            });
          }
          this.totalCount = data.data.totalElements;
          this.pageindex = data.data.pageable.pageNumber + 1;

          this.pageCount = Math.floor((this.totalCount + this.pageSize - 1) / this.pageSize);
        }
      });
  }

  formateDate(date: string) {
    return DateTime.fromISO(date).toFormat('dd/MM/yyyy');
  }

  formateDateToTime(date: string) {
    return DateTime.fromISO(date).toFormat('HH:mm');
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  doClickDetailMobile(e: any, model: NotificationModel) {
    const obj = e.target.getElementsByClassName('m-area-icon')[0];

    if (obj.classList.contains('backrotate')) {
      obj.classList.add('gorotate');
      obj.classList.remove('backrotate');
      model.isShowDetail = true;
    } else {
      obj.classList.add('backrotate');
      obj.classList.remove('gorotate');
      model.isShowDetail = false;
    }
  }

  createMessage() {
    this.router.navigate(['/mobile-subscription/create']);
  }

  doMethodTest() {}

  dosetHeaderTitle() {
    this.layoutHeaderService.setHeaderButton(this.headerButton);
  }

  manageAudience() {
    this.router.navigate([`/mobile-subscription/manage-audience-list`], {
      queryParams: { value: this.audienceFormControl.value },
    });
  }

  audienceDropdownChange() {
    this.loadTable();
  }

  previewAudienceList(id: string, listName: string) {
    this.cdsPopup
      .open(PreviewAudienceListPopupComponent, {
        size: 'lg',
        data: {
          id,
          listName,
        },
      })
      .afterClosed()
      .subscribe(() => {});
  }

  showTargetAudienceNo = (category: string, status: string) => {
    if (category === 'HCM_004' && status !== 'Issued') {
      return false;
    }
    return true;
  };

  toastError(errorMessage: string) {
    this.toastAlert.show('error', 'common.error', errorMessage, 5000);
  }
}
