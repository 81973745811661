/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CdsButtonConfig } from '@cds/ng-core/button';
import { AuthenticationService } from 'src/app/core/services/authentication.service';

@Component({
  selector: 'app-time-out',
  templateUrl: './time-out.component.html',
  styleUrls: ['./time-out.component.scss'],
})
export class TimeOutComponent implements OnInit {
  constructor(private router: Router, private authenticationService: AuthenticationService) {}

  ssoButtonConfig: CdsButtonConfig = {
    buttonType: 'button',
    style: 'secondary',
    size: 'sm',
    fullWidth: true,
  };

  toLogin() {
    this.router.navigate(['/user/login']);
  }

  ngOnInit() {
    this.authenticationService.currentUserValue().then(data => {
      if (data.token !== undefined) {
        this.authenticationService.deleteSession(false);
      } else {
        this.router.navigate(['/user/login']);
      }
    });
  }
}
