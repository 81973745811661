/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, map } from 'rxjs';

@Injectable()
export class DataPermissionInterceptor implements HttpInterceptor {
  constructor(private router: Router) {}

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      map(event => {
        if (event instanceof HttpResponse) {
          if (event && event.body && event.body.message && event.body.result === 4) {
            if (event.body.message === 'Can not access this employer data' || event.body.message === 'Can not access this Member Account data') {
              this.router.navigate(['/error/permission-error']);
            }
          }
          return event;
        }
        return event; //Fixme: It should be return nothing
      })
    );
  }
}
