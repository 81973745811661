import { SharedModule } from 'src/app/shared/shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UploadCenterComponent } from './upload-center/upload-center.component';
import { UploadCenterRoutingModule } from './upload-center-routing.module';
import { CdsLanguageModule } from '@cds/ng-core/lang';
import { CdsIconModule } from '@cds/ng-web-components/icon';
import { UploadDocumentComponent } from './upload-document/upload-document.component';
import { CdsFileUploadModule } from '@cds/ng-experimental/file-upload';
import { CdsTableModule } from '@cds/ng-data-table/table';

@NgModule({
  declarations: [UploadCenterComponent, UploadDocumentComponent],
  imports: [CommonModule, CdsIconModule, CdsLanguageModule, CdsTableModule, CdsFileUploadModule, SharedModule, UploadCenterRoutingModule],
})
export class UploadCenterModule {}
