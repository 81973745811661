import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-response-error',
  templateUrl: './response-error.component.html',
  styleUrls: ['./response-error.component.scss'],
})
export class ResponseErrorComponent implements OnInit {
  constructor(private route: ActivatedRoute) {}
  status = '404';
  message = 'Resource not found';
  ngOnInit(): void {
    const status = this.route.snapshot.queryParams['status'];
    if (status) {
      this.status = status;
    }
    const message = this.route.snapshot.queryParams['message'];
    if (message) {
      this.message = message;
    }
  }
}
