import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { CDS_POPUP_DATA } from '@cds/ng-web-components/popup';
import { CdHttpServeService } from 'src/app/shared/cd-http-serve/cd-http-serve.service';
import { agentCodeRemovePrefix0 } from 'src/app/utils/utils';
import { AgentDetailInfo } from '../../service/model/agent-info-response.model';
import { CdsAlertService } from '@cds/ng-web-components/alert';
import { NotifyService } from 'src/app/core/services/notify.service';

@Component({
  selector: 'app-add-new-commission-popup',
  templateUrl: './add-new-commission-popup.component.html',
  styleUrls: ['./add-new-commission-popup.component.scss'],
})
export class AddNewCommissionPopupComponent implements OnInit {
  agentCode = '';
  errorMsg = '';

  constructor(
    private dialogRef: MatDialogRef<AddNewCommissionPopupComponent>,
    private cdHttpServeService: CdHttpServeService,
    private notifyService: NotifyService,
    private alert: CdsAlertService,
    @Inject(CDS_POPUP_DATA)
    public data: {
      agentCodeList: string[];
    }
  ) {}

  ngOnInit(): void {}

  onCancel() {
    this.dialogRef.close({ agree: false });
  }

  add() {
    this.errorMsg = '';

    this.agentCode = this.agentCode.trim();

    if (this.agentCode === '') {
      this.errorMsg = 'Please input agent code';
      return;
    }

    if (this.data.agentCodeList.includes(this.agentCode.trim())) {
      this.errorMsg = 'Duplicate agent code used';
      return;
    }

    this.notifyService.screenLoadingSubject.next(true);
    this.cdHttpServeService
      .post<{
        agentCode: string;
        name: string;
        chineseName: string;
        errorCode?: number;
      }>('/ext/eb-ssms/customer-service/add-delegation/validate', {
        agentCode: agentCodeRemovePrefix0(this.agentCode.trim()),
      })
      .subscribe({
        next: res => {
          if (res.result === 0) {
            this.cdHttpServeService
              .get<AgentDetailInfo>('/ext/eb-ssms/agent-service/agent/information', { agentInfo: agentCodeRemovePrefix0(this.agentCode.trim()) })
              .subscribe({
                next: res => {
                  if (res.result === 0) {
                    this.dialogRef.close({
                      agree: true,
                      agent: {
                        agentId: res.data.agentId,
                        agentCode: res.data.agentCode,
                        name: res.data.agentName,
                        chineseName: res.data.agentNameTC,
                      },
                    });
                  } else {
                    this.alert.error('Error!', res.message);
                  }
                  this.notifyService.screenLoadingSubject.next(false);
                },
                error: err => {
                  this.alert.error('Error!', err);
                  this.notifyService.screenLoadingSubject.next(false);
                },
              });
          } else if (res.result === 2100) {
            switch (res.data.errorCode) {
              case 2101:
                this.errorMsg = 'Invalid agent code';
                break;
              case 21031:
                this.errorMsg = 'Inactive MPF license';
                break;
              case 2102:
                this.errorMsg = 'Inactive agent status';
                break;
              default:
                this.errorMsg = 'Invalid agent code';
                break;
            }
            this.notifyService.screenLoadingSubject.next(false);
          } else {
            this.notifyService.screenLoadingSubject.next(false);
          }
        },
        error: () => {
          this.notifyService.screenLoadingSubject.next(false);
        },
      });
  }

  valueChange(val: string) {
    if (val === '') {
      this.errorMsg = '';
    }
  }
}
