import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { CdsOption } from '@cds/ng-core/configuration';
import { CdsIconConfig } from '@cds/ng-core/icon';
import { ActionIcons } from '@cds/ng-themes';
import { CdsPopupService } from '@cds/ng-web-components/popup';
import moment from 'moment';
import { IFF_STATUS } from 'src/app/config/iff-status.config';
import { EmpfAppStatusService } from 'src/app/core/services/empf-app-status/empf-app-status.service';
import { IffStatusService } from 'src/app/core/services/iff-status/iff-status.service';
import { dateRangeInvalidValidator } from 'src/app/core/validators';
import { dateValidator } from 'src/app/shared/validators/validators';
import { copyText } from 'src/app/utils/utils';
import { AdviseEmpfrefComponent } from '../../case-summary/advise-empfref/advise-empfref.component';
import { IndividualType } from '../../case-summary/case-summary.component';
import { EmpfAppStatusPopupComponent } from '../../empf-app-status-popup/empf-app-status-popup.component';
import { IffStatusFlowPopupComponent } from '../../iff-status-flow-popup/iff-status-flow-popup.component';
import { CdsToastService } from '@cds/ng-web-components/toast';
import { Router } from '@angular/router';

enum IffStatus {
  'All',
  'Completed',
  'Declined',
}

@Component({
  selector: 'app-empf-salessupport-er',
  templateUrl: './empf-salessupport-er.component.html',
  styleUrls: ['./empf-salessupport-er.component.scss'],
})
export class EmpfSalessupportErComponent implements OnInit {
  IffStatus = IffStatus;
  iffStatusSelected = IffStatus.All;
  formGroup!: FormGroup;
  IndividualType = IndividualType;
  individualTypeSelected = IndividualType.ALL;
  copyText = copyText;
  @ViewChild('tableBox', { static: false })
  tableBox?: ElementRef<HTMLDivElement>;
  readonly displayedColumns = [
    'eMPFRefNo',
    'MDRefNo',
    'DistrictCode',
    'AgentCode',
    'ProductType',
    'EmployerName',
    'BRNumber',
    'MDExpiryDate',
    'MDDeclarationDate',
    'MDIFFStatus',
    'eMPFAppStatus',
    'SplitCase',
    'hkidOrPassportNo',
    'DeclinedReason',
  ];
  allPageData = [
    [
      {
        eMPFRefNo: 'adfas',
        MDRefNo: 'adfas',
        DistrictCode: 'adfas',
        AgentCode: 'adfas',
        ProductType: 'adfas',
        EmployerName: 'asdf',
        BRNumber: 'dfdf',
        MDExpiryDate: 'asdfsf',
        MDDeclarationDate: 'adfas',
        MDIFFStatus: 'Expired',
        eMPFAppStatus: 'eMPF Processing',
        SplitCase: 'adfas',
        hkidOrPassportNo: 'sdfdsf',
        DeclinedReason: 'asdf',
      },
    ],
  ];
  currentPageNumOrigin = 1;
  abmOptionList: CdsOption[] = [];
  totalElements = 0;
  resetDatePicker = false;
  iFFExpiryDateFromMax = new Date('3000/01/01');
  iFFExpiryDateToMin = new Date('2000/01/01');
  iffStatusoptionList: CdsOption[] = [];
  empfAppStatusoptionList: CdsOption[] = [];
  declinedReasonOptionList: CdsOption[] = [];
  isLoading = false;
  iconConfig: CdsIconConfig = {
    size: 'sm',
    color: 'default',
  };
  infoIcon = ActionIcons.info_1;

  get currentPageIndex() {
    return this.currentPageNumOrigin - 1;
  }

  get currentPageData() {
    if (this.allPageData.length === 0) {
      return [];
    }
    return this.allPageData[this.currentPageIndex];
  }

  get iFFExpiryDateFrom() {
    return this.formGroup.get('iFFExpiryDateFrom') as FormControl;
  }

  get iFFExpiryDateTo() {
    return this.formGroup.get('iFFExpiryDateTo') as FormControl;
  }

  get checkIsHaveScroll() {
    if (this.tableBox) {
      return this.tableBox.nativeElement.scrollWidth > this.tableBox.nativeElement.clientWidth;
    }
    return false;
  }

  get mDIFFStatus() {
    return this.formGroup.get('mDIFFStatus') as FormControl;
  }

  get eMPFAppStatus() {
    return this.formGroup.get('eMPFAppStatus') as FormControl;
  }

  get abm() {
    return this.formGroup.get('abm') as FormControl;
  }

  get declinedReason() {
    return this.formGroup.get('declinedReason') as FormControl;
  }

  constructor(
    private cdsPopup: CdsPopupService,
    public iffStatusService: IffStatusService,
    public empfAppStatusService: EmpfAppStatusService,
    private toast: CdsToastService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.formGroup = new FormGroup(
      {
        empfRefNo: new FormControl(),
        employerName: new FormControl(),
        agentCode: new FormControl(),
        districtCode: new FormControl(),
        abm: new FormControl([]),
        iFFExpiryDateFrom: new FormControl(null, {
          validators: [dateValidator('common.invalidDate')],
        }),
        iFFExpiryDateTo: new FormControl(null, {
          validators: [dateValidator('common.invalidDate')],
        }),
        hkidOrPassportNo: new FormControl(),
        declinedReason: new FormControl([]),
        mDIFFStatus: new FormControl([]),
        eMPFAppStatus: new FormControl([]),
      },
      [dateRangeInvalidValidator('iFFExpiryDateFrom', 'iFFExpiryDateTo')]
    );

    this.initIffStatusOptions();
  }

  private initIffStatusOptions() {
    this.iffStatusoptionList = IFF_STATUS.map<CdsOption>(element => ({
      label: element.text,
      value: element.value,
    }));
  }

  iFFExpiryDateFromChange(val: string) {
    const dateObject = moment(val, 'DD/MM/YYYY');
    if (dateObject.isValid()) {
      this.iFFExpiryDateToMin = dateObject.toDate();
    }
  }

  iFFExpiryDateToChange(val: string) {
    const dateObject = moment(val, 'DD/MM/YYYY');
    if (dateObject.isValid()) {
      this.iFFExpiryDateFromMax = dateObject.toDate();
    }
  }

  onClickSearch() {}

  onClickReset() {
    this.formGroup.reset({
      abm: [],
      declinedReason: [],
      mDIFFStatus: [],
      eMPFAppStatus: [],
    });
  }

  onGenerateNewMDReferenceNo() {}

  checkIFFStatusTag(value: IffStatus) {
    this.iffStatusSelected = value;
  }

  checkProductTypeTag(value: IndividualType) {
    this.individualTypeSelected = value;
  }

  onDisplayAdviseIFF() {
    this.cdsPopup.open(IffStatusFlowPopupComponent, {
      size: 'sm',
    });
  }

  onDisplayAdviseRef() {
    this.cdsPopup.open(AdviseEmpfrefComponent, {
      size: 'sm',
    });
  }

  onDisplayAdviseEmpf() {
    this.cdsPopup.open(EmpfAppStatusPopupComponent, {
      size: 'sm',
    });
  }

  pageChange(num: number): void {
    console.log(num);
  }

  copy() {
    this.toast.success('Copy successfully');
  }

  onGoCaseDetails() {
    this.router.navigate(['/salesjourney/er-empf-case-details']);
  }
}
