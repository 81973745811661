import * as jwtDecode from 'jwt-decode';
const APIPaths = {
  accessTokenKey: 'lmsAuth',
};
export class TokenHelper {
  public static getAccessToken(): string {
    return localStorage.getItem(APIPaths.accessTokenKey) || '';
  }

  public static setAccessToken(token: string): void {
    return localStorage.setItem(APIPaths.accessTokenKey, token);
  }

  public static setToken(token: any): void {
    this.setAccessToken(token.access_token);
    localStorage.setItem('image', token.img);
  }

  public static removeAccessToken(): void {
    return localStorage.removeItem(APIPaths.accessTokenKey);
  }

  public static getBearerToken() {
    const token = localStorage.getItem(APIPaths.accessTokenKey);
    return {
      Authorization: token ? 'Bearer ' + token : null,
    };
  }

  // public static isTokenCurrent(value: string | AuthenticatedUser) {

  //     let user: AuthenticatedUser = null;

  //     if (typeof value === 'string') {
  //         user = TokenHelper.parseUserToken(value);
  //     } else {
  //         user = value;
  //     }
  //     if (!user)
  //         return null;
  //     else if (!user.authenticated)
  //         return false;
  //     else
  //         return user.exp && user.exp > new Date();
  // }
}
