<div class="rest-warning">
  <div class="cd-waring-popup">
    <div>
      <span class="cds-body-hero" style="font-weight: 300">The changes you’ve made </span>
      <span class="cds-body-hero" style="font-style: italic; font-weight: 400">will be lost, </span>
      <span class="cds-body-hero" style="font-weight: 300">are you sure to continue?</span>
    </div>
    <div class="botton-list">
      <cds-button [style]="'secondary'" size="md" (click)="onCancel()">{{ 'common.cancel' | lang }}</cds-button>
      <cds-button size="md" (click)="onContinue()">{{ 'common.continue' | lang }}</cds-button>
    </div>
  </div>
</div>
