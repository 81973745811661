<div class="row">
  <div class="col-xs-12 l-mt-7 l-mb-2">
    <span class="cds-body1"
      >Lorem ipsum dolor sit amet. Qui autem impedit est tenetur reiciendis et sunt rerum qui quisquam animi et repudiandae rerum ab cumque dolore ut accusamus
      ipsum. Doloribus aliquid quo delectus atque aut corporis eius eum maiores optio in autem consequatur quo quidem officiis. Est nisi delectus et laboriosam
      galisum est nihil tempore quo beatae quibusdam. 33 velit omnis in dignissimos saepe aut atque consectetur et dolores mollitia in tenetur galisum nam illo
      eveniet!
    </span>
  </div>
  <div class="col-xs-12 l-mt-7 l-mb-2">
    <span class="cds-h2-light">{{ 'list.record-list' | lang }}</span>
  </div>
  <div class="col-xs-12 l-mb-3">
    <span class="cds-body1">Total {{ totalCount }} records</span>
  </div>
</div>

<app-loading *ngIf="inProgress || inPutProgress" [overlayInPopup]="true"></app-loading>

<div class="row scroll-container" *ngIf="!isMobile">
  <div class="scroll-content col-xs-12">
    <div class="col-xs-12 table-title l-mt-5">
      <div class="row col-xs-12 middle-xs no-padding">
        <div class="icon-width"></div>
        <div class="col-xs-2 sort-item" (click)="sortChange('createdAt')">
          <span class="cds-h6-demibold sort-item-text">{{ columList[0] }}</span>
          <cds-icon class="sort-icon" [icon]="getSortIcon('createdAt')"></cds-icon>
        </div>
        <!-- <div class="col-xs-2 sort-item" (click)="sortChange('terminationDate')">
          <span class="cds-h6-demibold sort-item-text">{{ columList[1] }}</span>
          <cds-icon class="sort-icon" [icon]="getSortIcon('terminationDate')"></cds-icon>
        </div> -->
        <div class="col-xs-2 sort-item" (click)="sortChange('dateOfEmploy')">
          <span class="cds-h6-demibold sort-item-text">{{ columList[1] }}</span>
          <cds-icon class="sort-icon" [icon]="getSortIcon('dateOfEmploy')"></cds-icon>
        </div>
        <div class="col-xs sort-item" (click)="sortChange('companyName')">
          <span class="cds-h6-demibold sort-item-text">{{ columList[2] }}</span>
          <cds-icon class="sort-icon" [icon]="getSortIcon('companyName')"></cds-icon>
        </div>
        <div class="col-xs sort-item" (click)="sortChange('firstName')">
          <span class="cds-h6-demibold sort-item-text">{{ columList[3] }}</span>
          <cds-icon class="sort-icon" [icon]="getSortIcon('firstName')"></cds-icon>
        </div>
        <!-- <div class="col-xs-2 sort-item" (click)="sortChange('empfMemberAccountNumber')">
          <span class="cds-h6-demibold sort-item-text">{{ columList[4] }}</span>
          <cds-icon class="sort-icon" [icon]="getSortIcon('empfMemberAccountNumber')"></cds-icon>
        </div> -->
        <div class="col-xs">
          <span class="cds-h6-demibold">{{ columList[4] }}</span>
        </div>
        <div class="col-xs flex">
          <span class="cds-h6-demibold l-mr-2 l-mb-0">{{ columList[5] }}</span>
          <app-tooltip-icon [tooltipString]="askClientTip"></app-tooltip-icon>
        </div>
        <div class="col-xs-2">
          <span class="cds-h6-demibold">{{ columList[6] }}</span>
        </div>
      </div>
    </div>
    <div *ngFor="let item of recordList">
      <app-expand-item [data]="item" (statusChange)="statusChange($event)"></app-expand-item>
    </div>
  </div>
</div>

<div class="row l-mt-4" *ngIf="isMobile">
  <div *ngFor="let item of recordList" class="col-xs-12">
    <app-expand-item-mobile [data]="item" (statusChange)="statusChange($event)"></app-expand-item-mobile>
  </div>
</div>

<div *ngIf="!inProgress && recordList.length === 0" class="l-d-f l-jc-cen l-ai-cen l-pt-4 l-pb-4">
  <div class="mirror-rotate-h l-mr-4">
    <cds-icon size="md" icon="form:search"></cds-icon>
  </div>
  <span class="cds-h2-light font-dark-navy l-ma-0">{{ 'agent.noResultFound' | lang }}</span>
</div>

<div class="col-xs-12 col-md-12 l-pt-4 l-pb-4 l-d-f l-jc-sb" *ngIf="recordList?.length">
  <app-pagination [pageCount]="pageCount" [isLoading]="inProgress || inPutProgress" [useOf]="true" (pageChange)="pageChange($event)"> </app-pagination>
</div>

<div class="n-c-line last-line"></div>
