import { AfterViewInit, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-status-light',
  templateUrl: './status-light.component.html',
  styleUrls: ['./status-light.component.scss'],
})
export class StatusLightComponent implements OnInit, AfterViewInit {
  @Input() text?: string;
  @Input() color?: string;
  @Input() tipText?: string;

  stepperTarget: 'pc' | 'ipad' = 'pc';

  constructor() {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    if (window.matchMedia('only screen and (max-width: 1500px)').matches) {
      this.stepperTarget = 'ipad';
    } else {
      this.stepperTarget = 'pc';
    }
  }

  onMouseenter(event: MouseEvent) {
    if (event.target && this.stepperTarget === 'pc') {
      event.target.dispatchEvent(new Event('click'));
    }
  }

  onMouseleave(event: MouseEvent) {
    if (event.target && this.stepperTarget === 'pc') {
      event.target.dispatchEvent(new Event('click'));
    }
  }
}
