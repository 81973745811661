import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-least-one-warning',
  templateUrl: './least-one-warning.component.html',
  styleUrls: ['./least-one-warning.component.scss'],
})
export class LeastOneWarningComponent implements OnInit {
  constructor(private dialogRef: MatDialogRef<LeastOneWarningComponent>) {}

  ngOnInit(): void {}

  onCancel() {
    this.dialogRef.close({ agree: false });
  }
}
