<ng-container *ngIf="isStaff">
  <div class="row l-pt-8">
    <div class="col-xs-12 cds-color-green l-plr-0">
      <span class="cds-h5-demibold">{{ 'payroll.information-of-forfeiture-handling' | lang }}</span>
    </div>
    <div class="col-xs-12 p-line"></div>
  </div>

  <div class="row">
    <!-- <app-row-item label="payroll.annualized-prem" data="{{ schemeInfo?.annualizedPrem | ssmsDate }}" class="col-xs-6 col-sm-3 col-md-3 col-lg-3"> </app-row-item> -->
    <app-row-item
      label="payroll.forfeiture-handling"
      [data]="forfeitureHandlingRender | fn : schemeInfo?.forfeitureHandling"
      class="col-xs-6 col-sm-3 col-md-3 col-lg-3">
    </app-row-item>
    <app-row-item
      label="payroll.allocation-method"
      [data]="allocationMethodRender | fn : schemeInfo?.allocationMethod"
      class="col-xs-6 col-sm-3 col-md-3 col-lg-3">
    </app-row-item>
  </div>
</ng-container>

<ng-container *ngIf="showInvestedFundsInformation | fn : schemeInfo?.activeLives : schemeInfo?.fundList : isStaff">
  <div class="row l-pt-8">
    <div class="col-xs-12 cds-color-green l-plr-0">
      <span class="cds-h5-demibold">{{ 'er.profile.invested-funds-information' | lang }}</span>
    </div>
    <div class="col-xs-12 p-line"></div>
  </div>

  <div class="row">
    <div class="col-xs-12 l-mt-5 l-mb-6" *ngIf="isStaff">
      <div class="back-button-area">
        <div class="icon-size" (click)="gotoPrivilegeRatesDetails()">
          <cds-icon size="sm" color="#ff6666" icon="action:button_right_filled"></cds-icon>
        </div>
        <button class="button-border-none-font" (click)="gotoPrivilegeRatesDetails()">View Privilege Rates Details</button>
      </div>
    </div>

    <div class="col-xs-6 col-sm-3 col-md-3 col-lg-3 l-pt-2 l-pb-2">
      <div class="cds-color-navy-light3">
        <span class="cds-body2">{{ 'payroll.fund-currency' | lang }}</span>
      </div>
      <div class="l-pt-1">
        <span class="cds-body1">{{ schemeInfo?.fundCurrency }}</span>
      </div>
    </div>
    <div class="col-xs-6 col-sm-3 col-md-3 col-lg-3 l-pt-2 l-pb-2">
      <div class="cds-color-navy-light3">
        <span class="cds-body2">{{ 'payroll.er-new-bu' | lang }}</span>
      </div>
      <div class="l-pt-1">
        <cds-checkbox [checked]="schemeInfo?.erNewBuFlag === 'Y'" [config]="noConfig"></cds-checkbox>
      </div>
    </div>
  </div>

  <div class="row l-pt-5">
    <div class="col-xs-12 row middle-xs l-plr-0">
      <div class="l-mr-2">
        <cds-icon color="#282B3E" width="20px" height="20px" icon="form:document"></cds-icon>
      </div>
      <div class="col-xs">
        <span class="cds-h5-demibold">Effective Date: {{ schemeInfo?.effectiveDate | ssmsDate }}</span>
      </div>
      <ng-container *ngIf="schemeInfo && schemeInfo.fundList">
        <div *ngIf="schemeInfo.fundList.length > 0" class="row col-xs-offset-3">
          <cds-button (click)="expandAll()" [config]="config">Expand All</cds-button>
        </div>
      </ng-container>
    </div>
    <div class="col-xs-12 l-pt-5"></div>
  </div>

  <app-cd-accordion>
    <app-cd-accordion-panel *ngFor="let item of schemeInfo?.fundList" [title]="item.fundCode + '&nbsp;&nbsp;' + item.fundNm" [expand]="item.show">
      <div class="col-xs l-plr-0">
        <div class="row">
          <app-row-item label="payroll.bid-price" *ngIf="isStaff" [data]="(item.unitPrice | number) || '/'" class="col-xs-6 col-sm-3 col-md-3 col-lg-3">
          </app-row-item>
          <app-row-item label="employer.no-of-unit" *ngIf="isStaff" [data]="(item.fundUnit | number) || '/'" class="col-xs-6 col-sm-3 col-md-3 col-lg-3">
          </app-row-item>
          <app-row-item label="payroll.unit-balance" [data]="(item.totalBalance | number : '1.2-2') || '/'" class="col-xs-6 col-sm-3 col-md-3 col-lg-3">
          </app-row-item>
          <!-- <app-row-item label="payroll.fund-used" [checked]="item.fundUsed === 'Y'" class="col-xs-6 col-sm-3 col-md-3 col-lg-3"> </app-row-item> -->
        </div>

        <div class="row">
          <div class="col-xs-10 row update-pad">
            <div class="col-xs-12 l-pt-2 l-pb-2 cds-color-navy-light4">
              <span class="cds-body2"> Latest update: {{ item.latestUpdate | ssmsDateTime }} (+8T)</span>
            </div>

            <div class="col-xs-12 row l-plr-0 l-pt-2 l-pb-2">
              <ng-container *ngFor="let key of keyListOfFundPercent | fn : item.fundPercent; index as index">
                <div class="col-xs-3 l-pt-2 l-pb-2">
                  <span class="cds-body2">{{ fundPercentLabel | fn : key }} %</span>
                </div>
                <div class="col-xs-3 l-pt-2 l-pb-2" [class.border-right]="index % 2 === 0">
                  <span class="cds-body2">{{ fundPercentNumber | fn : item.fundPercent : key | number : '1.2-2' }}</span>
                </div>
              </ng-container>
            </div>

            <!-- <div class="col-xs-12 row l-plr-0 l-pt-2 l-pb-2">
              <div class="col-xs row">
                <div class="col-xs-6 l-pt-2 l-pb-2">
                  <span class="cds-body2">FSEE/EEMC %</span>
                </div>
                <div class="col-xs-6 l-pt-2 l-pb-2">
                  <span class="cds-body2">{{ item.fsee_EEMCPercent }}</span>
                </div>
                <div class="col-xs-6 l-pt-2 l-pb-2">
                  <span class="cds-body2">FSER/ERMC %</span>
                </div>
                <div class="col-xs-6 l-pt-2 l-pb-2">
                  <span class="cds-body2">{{ item.fser_ERMCPercent }}</span>
                </div>
                <div class="col-xs-6 l-pt-2 l-pb-2">
                  <span class="cds-body2">OPT %</span>
                </div>
                <div class="col-xs-6 l-pt-2 l-pb-2">
                  <span class="cds-body2">{{ item.optPercent }}</span>
                </div>
              </div>
              <div class="v-line"></div>
              <div class="col-xs row">
                <div class="col-xs-6 l-pt-2 l-pb-2">
                  <span class="cds-body2">PSEE/EEVC %</span>
                </div>
                <div class="col-xs-6 l-pt-2 l-pb-2">
                  <span class="cds-body2">{{ item.psee_EEVCPercent }}</span>
                </div>
                <div class="col-xs-6 l-pt-2 l-pb-2">
                  <span class="cds-body2">PSER/ERVC %</span>
                </div>
                <div class="col-xs-6 l-pt-2 l-pb-2">
                  <span class="cds-body2">{{ item.pser_ERVCPercent }}</span>
                </div>
                <div class="col-xs-6 l-pt-2 l-pb-2">
                  <span class="cds-body2">Bonus Ftr %</span>
                </div>
                <div class="col-xs-6 l-pt-2 l-pb-2">
                  <span class="cds-body2">{{ item.bonusFtrPercent }}</span>
                </div>
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </app-cd-accordion-panel>
  </app-cd-accordion>
</ng-container>
