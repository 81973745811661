import { Component, OnInit, OnDestroy, ViewChildren, QueryList, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';

import { Subscription, Observable, map, of } from 'rxjs';

import { CdsPopupService, MatDialogRef } from '@cds/ng-web-components/popup';
import { CdsDropdownConfig } from '@cds/ng-core/dropdown';
import { CdsIconConfig } from '@cds/ng-core/icon';
import { Sort } from '@cds/ng-data-table/sort';
import { ActionIcons, ArrowIcons } from '@cds/ng-themes';
import { CdsAlertService } from '@cds/ng-web-components/alert';
import { CdsButtonConfig } from '@cds/ng-core/button';

import { LanguageChangeService } from 'src/app/shared/language-change.service';
import { CdHttpServeService } from 'src/app/shared/cd-http-serve/cd-http-serve.service';
import { AddToItemComponent } from './add-to-item/add-to-item.component';
import { deepCopy } from 'src/app/utils/copy';
import { updateDelegationEmpValiRespErrHandle, ValiRespErrData } from '../update-delegation-emp-vali-resp-err-handle.helper';
import { CdWarningPopupComponent } from 'src/app/shared/cd-warning-popup/cd-warning-popup.component';
import { specialCharacter } from 'src/app/utils/special-character';
import { AgentBranchPopComponent } from '../agent-branch-pop/agent-branch-pop.component';
import { CdInputComponent } from 'src/app/components/cd-input/cd-input.component';
import { agentCodeAddPrefix0, agentCodeRemovePrefix0, pageToTop } from 'src/app/utils/utils';
import { CdStepperComponent } from 'src/app/shared/cd-stepper/cd-stepper.component';
import { UserExitEditmodePopComponent } from '../user-exit-editmode-pop/user-exit-editmode-pop.component';
import { CdStepComponent } from 'src/app/shared/cd-stepper/cd-step/cd-step.component';

interface Delegation {
  isSelected?: boolean;
  employerId: string;
  employerName: string;
  employerNameTC: string;
  groupNum: number;
  numOfEmployee: number;
  noOfEmployeeDelegated: number;
  delegatedAgents?: { agentCode: string; name: string; nameTC: string }[];
}

enum SelfRouter {
  employerList,
  change,
  changeSummary,
}

interface CustomerServiceEmployerResponse {
  content: {
    employerId: string;
    employerName: string;
    employerNameTC: string;
    employerNo: number;
    employeeAmt: number;
    delegateAmt: number;
    delegatedIsEligible: number;
    delegateAgentInfo: { agentCode: string; name: string; nameTC: string }[];
  }[];
  empty: boolean;
  first: boolean;
  last: boolean;
  number: number;
  numberOfElements: number;
  pageable: {
    pageNumber: number;
    pageSize: number;
    offset: number;
    paged: boolean;
    unpaged: boolean;
  };
  size: number;
  totalElements: number;
  totalPages: number;
}

interface DelegateInfo {
  isSeleted: boolean;
  agentCode: string;
  agentName: string;
  agentNameTC: string;
  couldUpdateDelegateAmt: number;
  newDelegatedAgentCode: string;
  newDelegatedAgentCodeIsValidator: boolean;
  newDelegatedAgentName: string;
  newDelegatedAgentNameTC: string;
  selectReasonCodeOfChange: number;
  inputReasonOfChange: string;
  isShowMissReason: boolean;
  isShowMissAgentCode: boolean;
}

type Language = 'EN' | 'TC';

interface EmployerQueryParams {
  employerName: string;
  employerNo: string;
  delegateAgentCode: string;
  page: number;
  size: number;
  language: Language;
  sort: string;
}

interface EmployersValidationParam {
  employerId: string;
  data: {
    oldAgentCode: string;
    targetAgentCode: string;
    reason: {
      code: number;
      message: string;
    };
  }[];
}

export interface DelegatedAgentsResponseData {
  isExpend: boolean;
  employerInfo: {
    id: string;
    employerNo: string;
    employerName: string;
    employerNameTC: string;
    delegateAmt: number;
    delegatedIsEligible: number;
  };
  delegateInfos: DelegateInfo[];
  unavailable: {
    oldAgent: {
      nameTC: string;
      name: string;
      agentCode: string;
    };
    targetAgent: {
      nameTC: string;
      name: string;
      agentCode: string;
    };
    delegatedAmt: number;
  }[];
}

@Component({
  selector: 'app-er-delegation-change',
  templateUrl: './er-delegation-change.component.html',
  styleUrls: ['./er-delegation-change.component.scss'],
})
export class ErDelegationChangeComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('stepper') stepper!: CdStepperComponent;
  @ViewChildren('step') stepList!: QueryList<CdStepComponent>;
  @ViewChildren('newDelegatedAgent')
  newDelegatedAgentInputList!: QueryList<CdInputComponent>;
  @ViewChildren('tooltip') tooltipList!: QueryList<ElementRef<HTMLDivElement>>;
  @ViewChild('tableBox', { static: false })
  tableBox?: ElementRef<HTMLDivElement>;
  SelfRouter = SelfRouter;
  currentSite: SelfRouter = SelfRouter.employerList;
  subscriptionList: Subscription[] = [];
  resetTable = false;
  agentCodeErrMsg = '';

  backIconConfig: CdsIconConfig = {
    color: 'cta',
    size: 'sm',
  };
  backIcon = ActionIcons.button_left_filled;
  buttonDownIcon = ActionIcons.button_down;
  buttonRightIcon = ActionIcons.button_right;
  rightArrowIcon = ArrowIcons.right;
  reloadIcon = ArrowIcons.reload;

  infoIcon = ActionIcons.info_1;
  iconConfig: CdsIconConfig = {
    size: 'sm',
    color: 'default',
  };

  displayedColumns: string[] = ['isSelected', 'employerName', 'groupNum', 'numOfEmployee', 'noOfEmployeeDelegated', 'delegatedAgents'];

  allPageData: Delegation[][] = [];
  currentPageNumOrigin = 1;
  totalRecords = 0;
  employerQueryParams: EmployerQueryParams = {
    employerName: '',
    employerNo: '',
    delegateAgentCode: '',
    page: 1,
    size: 10,
    language: 'EN',
    sort: 'employerName asc',
  };
  isShowProgress = false;

  reasonOfAgentChangeConfig: CdsDropdownConfig = {
    label: '',
    placeholder: 'Select reason of change',
    options: [
      { label: 'Customer complaint', value: 1 },
      { label: 'Agent termination', value: 2 },
      { label: 'Performance issue', value: 3 },
      { label: 'Wrong agent code', value: 4 },
      { label: 'Others', value: 5 },
    ],
  };

  primaryButtonConfig: CdsButtonConfig = {
    size: 'md',
    style: 'primary',
  };
  secondaryButtonConfig: CdsButtonConfig = {
    size: 'md',
    style: 'secondary',
  };

  updateDelegationDelegatedAgentsData: DelegatedAgentsResponseData[] = [];

  newDelegatedAgentNameSummary = '';
  newDelegatedAgentNameTCSummary = '';
  newDelegatedAgentCodeSummary = '';
  newDelegatedAgentCodeSummaryIsValidator = false;
  reasonCodeOfAgentChangeControlSummary = -1;
  theOtherReasonOfChangeSummary = '';

  editedDelegationDelegatedAgentsData: DelegatedAgentsResponseData[] = [];
  editedDelegationDelegatedAgentsDataPage = 1;
  stepperTarget: 'pc' | 'ipad' = 'pc';

  private employersSubmitParam: EmployersValidationParam[] = [];

  private delegationEmployerQueryUrl = '/ext/eb-ssms/customer-service/delegation/employer/query';
  private addDelegationValidate = '/ext/eb-ssms/customer-service/add-delegation/validate';
  private delegatedAgentsQuery = '/ext/eb-ssms/customer-service/update-delegation/delegated-agents/query';
  private employersValidation = '/ext/eb-ssms/customer-service/update-delegation/employers/validation';
  private employersSubmit = '/ext/eb-ssms/customer-service/update-delegation/employers/submit';

  stepperItems = [
    { en: 'Select Item(s)', zh: '選擇項目' },
    { en: 'Fill agent code', zh: '輸入代理人編碼' },
    { en: 'Select Reason', zh: '選擇原因' },
    { en: 'Add code to selected item(s)', zh: '新增編號至已選項目' },
    { en: 'Submission', zh: '提交' },
  ];

  constructor(
    private router: Router,
    private cdHttpServeService: CdHttpServeService,
    private languageChangeService: LanguageChangeService,
    private alert: CdsAlertService,
    private cdsPopup: CdsPopupService
  ) {}

  ngOnInit() {
    this.subscriptionList.push(
      this.languageChangeService.lanSubject.subscribe(lan => {
        this.employerQueryParams.language = lan === 'en' ? 'EN' : 'TC';
      })
    );
    this.employerQuery();
  }

  ngAfterViewInit() {
    const stopPropagation = (event: MouseEvent) => {
      event.stopPropagation();
    };

    this.tooltipList.changes.subscribe(() => {
      this.tooltipList.forEach(toolTip => {
        toolTip.nativeElement.removeEventListener('click', stopPropagation, false);
      });

      this.tooltipList.forEach(toolTip => {
        toolTip.nativeElement.addEventListener('click', stopPropagation, false);
      });
    });

    this.tooltipList.forEach(toolTip => {
      toolTip.nativeElement.addEventListener('click', stopPropagation, false);
    });

    if (window.matchMedia('only screen and (max-width: 1500px)').matches) {
      this.stepperTarget = 'ipad';
    } else {
      this.stepperTarget = 'pc';
    }
  }

  ngOnDestroy() {
    this.subscriptionList.forEach(element => {
      element.unsubscribe();
    });
  }

  asyncAgentCodeValidate(
    delegateItem?:
      | {
          newDelegatedAgentCodeIsValidator: boolean;
          newDelegatedAgentName: string;
          newDelegatedAgentNameTC: string;
        }
      | DelegateInfo
  ) {
    return (agentCode: string): Observable<string | null> | null => {
      if (agentCode && agentCode.length !== 6) {
        if (delegateItem) {
          delegateItem.newDelegatedAgentCodeIsValidator = false;
          delegateItem.newDelegatedAgentName = '';
          delegateItem.newDelegatedAgentNameTC = '';
        }
        return of('agentCode.lengthShouldBe6');
      }

      if (!agentCode) {
        if (delegateItem) {
          delegateItem.newDelegatedAgentCodeIsValidator = false;
          delegateItem.newDelegatedAgentName = '';
          delegateItem.newDelegatedAgentNameTC = '';

          if ('isShowMissReason' in delegateItem) {
            this.delegateInfoValidate(delegateItem);
          }
        }
        return null;
      }

      agentCode = agentCodeRemovePrefix0(agentCode);

      return this.cdHttpServeService
        .post<{
          agentCode: string;
          name: string;
          chineseName: string;
          errorCode?: number;
        }>(this.addDelegationValidate, { agentCode })
        .pipe(
          map(res => {
            if (res.data.errorCode) {
              if (delegateItem) {
                delegateItem.newDelegatedAgentCodeIsValidator = false;
                delegateItem.newDelegatedAgentName = '';
                delegateItem.newDelegatedAgentNameTC = '';
              }
              return this.agentCodeTransfer(res.data.errorCode);
            } else {
              if (delegateItem) {
                delegateItem.newDelegatedAgentCodeIsValidator = true;
                delegateItem.newDelegatedAgentName = res.data.name;
                delegateItem.newDelegatedAgentNameTC = res.data.chineseName;

                if ('isShowMissReason' in delegateItem) {
                  this.delegateInfoValidate(delegateItem);
                }
              }
              return null;
            }
          })
        );
    };
  }

  agentCodeTransfer(errorCode: number) {
    let agentCodeCheckResultByLan = '';
    if (errorCode === 2101) {
      agentCodeCheckResultByLan = 'agentCode.notExists';
    } else if (errorCode === 2102) {
      agentCodeCheckResultByLan = 'agentCode.inactiveAgent';
    } else if (errorCode === 2103) {
      agentCodeCheckResultByLan = 'agentCode.agentIsNotInSameDistinct';
    } else if (errorCode === 2199) {
      agentCodeCheckResultByLan = 'agentCode.lengthShouldBe6';
    } else if (errorCode === 21031) {
      agentCodeCheckResultByLan = 'agentCode.notHaveLicense';
    }
    return agentCodeCheckResultByLan;
  }

  private employerQuery() {
    return new Promise<void>(resolve => {
      this.isShowProgress = true;

      this.employerQueryParams.employerName = this.employerQueryParams.employerName.trim();
      this.employerQueryParams.employerNo = this.employerQueryParams.employerNo.trim();
      this.employerQueryParams.delegateAgentCode = this.employerQueryParams.delegateAgentCode.trim();

      this.cdHttpServeService
        .post<CustomerServiceEmployerResponse>(this.delegationEmployerQueryUrl, {
          ...this.employerQueryParams,
          isCoolingPeriod: 'N',
          showEligibleDelegatedZero: 'N',
          isDelegated: 'Y',
        })
        .subscribe({
          next: res => {
            if (res.result !== 0) {
              console.warn('Waring!', `${res.message} ${res.data}`);
              this.alert.warning('Warning!', `${res.message} ${res.data}`);
              this.totalRecords = 0;
            } else {
              const totalPages = res.data.totalPages;
              const currentAllPageData = this.allPageData.length;
              if (totalPages > currentAllPageData) {
                for (let i = 0; i < totalPages - currentAllPageData; i++) {
                  this.allPageData.push([]);
                }
              } else if (totalPages < currentAllPageData) {
                for (let i = 0; i < currentAllPageData - totalPages; i++) {
                  this.allPageData.pop();
                }
              }

              this.totalRecords = res.data.totalElements;
              this.currentPageNumOrigin = res.data.number;
              if (!res.data.empty) {
                this.allPageData[this.currentPageIndex] = res.data.content.map((item, index) => {
                  const delegation: Delegation = {
                    isSelected: false,
                    employerId: item.employerId,
                    employerName: item.employerName,
                    employerNameTC: item.employerNameTC,
                    groupNum: item.employerNo,
                    numOfEmployee: item.employeeAmt,
                    noOfEmployeeDelegated: item.delegatedIsEligible,
                  };
                  if (item.delegateAgentInfo) {
                    delegation.delegatedAgents = item.delegateAgentInfo.map(a => {
                      a.agentCode = agentCodeAddPrefix0(a.agentCode);
                      return a;
                    });
                  }
                  const oldItemPageData = this.allPageData[this.currentPageIndex];
                  if (oldItemPageData.length > 0) {
                    delegation.isSelected = oldItemPageData[index].isSelected;
                  }
                  return delegation;
                });
              }
            }

            this.isShowProgress = false;
            resolve();
          },
          error: error => {
            console.error('error---', error);
            this.alert.error('Error!', error);
            this.totalRecords = 0;
            this.isShowProgress = false;
            resolve();
          },
        });
    });
  }

  onBack() {
    this.router.navigate(['agent/employer-history']);
  }

  get currentPageIndex() {
    return this.currentPageNumOrigin - 1;
  }

  get currentPageData() {
    if (this.allPageData.length === 0) {
      return [];
    }
    return this.allPageData[this.currentPageIndex];
  }

  private popupWaring(cb: () => void) {
    const dialogRef = this.cdsPopup.open(CdWarningPopupComponent, {
      data: {
        title: 'delegationChange.alertDialogWaring',
        buttons: [
          {
            style: 'secondary',
            size: 'sm',
            text: 'confirmation.cancel',
            cb: () => {
              dialogRef.close();
            },
          },
          {
            style: 'primary',
            size: 'sm',
            text: 'delegationChange.continue',
            cb: () => {
              dialogRef.close();
              cb();
            },
          },
        ],
      },
    });
  }

  search() {
    this.popupWaring(() => {
      this.clearAllPageData();
      this.employerQueryParams.page = 1;
      this.employerQuery();
      this.onResetTable();
    });
  }

  reset() {
    this.popupWaring(() => {
      this.clearAllPageData();
      this.employerQueryParams.employerName = '';
      this.employerQueryParams.employerNo = '';
      this.employerQueryParams.delegateAgentCode = '';
      this.employerQueryParams.page = 1;
      this.employerQuery();
      this.onResetTable();
    });
  }

  onSortChange(event: Sort) {
    let active;
    switch (event.active) {
      case 'employerName':
        active = 'employerName';
        break;
      case 'groupNum':
        active = 'employerNo';
        break;
      case 'numOfEmployee':
        active = 'employeeAmt';
        break;
      case 'noOfEmployeeDelegated':
        active = 'delegatedIsEligible';
        break;
      default:
        active = 'employerName';
        break;
    }

    this.employerQueryParams.sort = `${active} ${event.direction}`;
    this.employerQueryParams.page = 1;
    this.clearAllPageData();
    this.employerQuery();
  }

  private clearAllPageData() {
    this.allPageData = [];
  }

  delegationChangeListIsHalfChecked() {
    const isAllChecked = this.delegationChangeListIsAllChecked();
    const isAllUnChecked = this.currentPageData.every(item => !item.isSelected);

    return !isAllChecked && !isAllUnChecked;
  }

  onAllCheckedChange(boo: boolean) {
    this.currentPageData.forEach(element => {
      element.isSelected = boo;
    });
  }

  onRowCheckOfDelegationChangeList(boo: boolean, element: Delegation) {
    element.isSelected = boo;
  }

  delegationChangeListIsAllChecked() {
    return this.currentPageData.every(item => item.isSelected);
  }

  onPageChange(goPage: number) {
    if (this.employerQueryParams.page === goPage) {
      return;
    }

    this.employerQueryParams.page = goPage;
    this.employerQuery();
  }

  get isDisabledContinue() {
    return this.getTotalCheckedNumber === 0;
  }

  get getTotalCheckedNumber() {
    let total = 0;

    this.allPageData.forEach(itemPage => {
      itemPage.forEach(delegation => {
        if (delegation.isSelected) {
          total++;
        }
      });
    });

    return total;
  }

  onGoChange() {
    if (!this.isDisabledContinue) {
      this.stickyBarDataReset();
      this.updateDelegationDelegatedAgentsData = [];
      this.updateDelegationDelegatedAgentsQuery();
      this.currentSite = SelfRouter.change;
      setTimeout(() => {
        if (this.stepperTarget === 'ipad') {
          this.stepper.setStepList(this.stepList);
        }
        this.stepper.setFinishStep(1);
      }, 0);
    }
  }

  onBackToEmployerList() {
    this.clearAllCheckFroEmployerList();
    this.currentSite = SelfRouter.employerList;
  }

  private clearAllCheckFroEmployerList() {
    this.allPageData.forEach(itemPage => {
      itemPage.forEach(element => {
        element.isSelected = false;
      });
    });
  }

  getGoEEPath(employerId: string, employerName: string, employerNameTC: string) {
    const employerNameParam = employerName ? employerName : '';
    const employerNameParamTC = employerNameTC ? employerNameTC : '';
    return (
      '/agent/employee-delegation-change/' +
      employerId +
      '?en=' +
      encodeURI(specialCharacter(employerNameParam)) +
      '&zh=' +
      encodeURI(specialCharacter(employerNameParamTC))
    );
  }

  toggleExpand(data: DelegatedAgentsResponseData) {
    data.isExpend = !data.isExpend;
  }

  addToItem() {
    if (this.disableAddToItem) {
      return;
    }

    if (this.newDelegatedAgentCodeSummary && this.newDelegatedAgentCodeSummary.length !== 6) {
      this.agentCodeErrMsg = 'agentCode.lengthShouldBe6';
      return;
    }

    this.cdHttpServeService
      .post<{
        agentCode: string;
        name: string;
        chineseName: string;
        errorCode?: number;
      }>(this.addDelegationValidate, {
        agentCode: agentCodeRemovePrefix0(this.newDelegatedAgentCodeSummary),
      })
      .subscribe(res => {
        if (res.data.errorCode) {
          this.agentCodeErrMsg = this.agentCodeTransfer(res.data.errorCode);
        } else {
          this.updateDelegationDelegatedAgentsData.forEach(data => {
            data.delegateInfos
              .filter(item => item.isSeleted)
              .forEach(element => {
                element.newDelegatedAgentCode = this.newDelegatedAgentCodeSummary;
                element.newDelegatedAgentCodeIsValidator = true;
                element.newDelegatedAgentName = res.data.name;
                element.newDelegatedAgentNameTC = res.data.chineseName;
                element.selectReasonCodeOfChange = this.reasonCodeOfAgentChangeControlSummary;
                element.inputReasonOfChange = this.theOtherReasonOfChangeSummary;

                this.delegateInfoValidate(element);

                element.isSeleted = false;
              });
          });

          this.newDelegatedAgentCodeSummary = '';
          this.reasonCodeOfAgentChangeControlSummary = -1;
          this.newDelegatedAgentNameSummary = '';
          this.newDelegatedAgentNameTCSummary = '';
          this.newDelegatedAgentCodeSummaryIsValidator = false;

          this.refreshStep();

          this.agentCodeErrMsg = '';
        }
      });
  }

  private updateDelegationDelegatedAgentsQuery() {
    const list: string[] = [];
    this.allPageData.forEach(itemPage => {
      itemPage.forEach(element => {
        if (element.isSelected) {
          list.push(element.employerId);
        }
      });
    });
    this.cdHttpServeService.post<DelegatedAgentsResponseData[]>(this.delegatedAgentsQuery, list).subscribe({
      next: res => {
        if (res.result !== 0) {
          console.warn('Waring!', `${res.message} ${res.data}`);
          this.alert.warning('Warning!', `${res.message} ${res.data}`);
        } else {
          this.updateDelegationDelegatedAgentsData = res.data.map((item): DelegatedAgentsResponseData => {
            item.delegateInfos = item.delegateInfos || [];
            item.unavailable = item.unavailable || [];
            const temp: DelegatedAgentsResponseData = {
              isExpend: true,
              employerInfo: item.employerInfo,
              delegateInfos: item.delegateInfos.map(info => {
                info.agentCode = agentCodeAddPrefix0(info.agentCode);
                return Object.assign({}, info, {
                  isSeleted: false,
                  newDelegatedAgentCode: '',
                  newDelegatedAgentCodeIsValidator: false,
                  selectReasonCodeOfChange: -1,
                  inputReasonOfChange: '',
                  isShowMissReason: false,
                  isShowMissAgentCode: false,
                });
              }),
              unavailable: item.unavailable.map(u => {
                u.oldAgent.agentCode = agentCodeAddPrefix0(u.oldAgent.agentCode);
                u.targetAgent.agentCode = agentCodeAddPrefix0(u.targetAgent.agentCode);
                return u;
              }),
            };
            return temp;
          });
        }
      },
      error: error => {
        console.error('error---', error);
        this.alert.error('Error!', error);
      },
    });
  }

  updateDelegatedAgentsAllChecked(data: DelegatedAgentsResponseData, val: boolean) {
    data.delegateInfos.forEach(element => {
      this.delegateItemSeleted(element, val);
    });
  }

  isDelegatedAgentsAllChecked(data: DelegatedAgentsResponseData) {
    return data.delegateInfos && data.delegateInfos.length > 0 && data.delegateInfos.every(item => item.isSeleted);
  }

  isDelegatedAgentsHalfChecked(data: DelegatedAgentsResponseData) {
    return !data.delegateInfos.every(item => item.isSeleted) && !data.delegateInfos.every(item => !item.isSeleted);
  }

  get disabledNewDelegatedAgentCodeInput() {
    let result = true;
    for (const data of this.updateDelegationDelegatedAgentsData) {
      for (const element of data.delegateInfos) {
        if (element.isSeleted) {
          result = false;
          break;
        }
      }
    }
    return result;
  }

  get disableReasonOfAgentChangeDropdown() {
    return !(this.newDelegatedAgentCodeSummary.length > 0 && !this.disabledNewDelegatedAgentCodeInput);
  }

  get disableAddToItem() {
    return (
      !this.newDelegatedAgentCodeSummary ||
      this.newDelegatedAgentCodeSummary.length === 0 ||
      this.reasonCodeOfAgentChangeControlSummary === -1 ||
      !this.updateDelegationDelegatedAgentsData.some(data => data.delegateInfos.some(element => element.isSeleted))
    );
  }

  get disableReset() {
    return !(
      this.newDelegatedAgentCodeSummary.trim().length > 0 ||
      this.reasonCodeOfAgentChangeControlSummary ||
      !this.disabledNewDelegatedAgentCodeInput ||
      this.updateDelegationDelegatedAgentsData.some(data =>
        data.delegateInfos.some(
          element => element.newDelegatedAgentCode.length > 0 || element.inputReasonOfChange.length > 0 || element.selectReasonCodeOfChange > 0
        )
      )
    );
  }

  get disableSubmit() {
    let boo = false;
    this.updateDelegationDelegatedAgentsData.forEach(data => {
      data.delegateInfos.forEach(element => {
        if (element.newDelegatedAgentCode.length > 0 && !element.newDelegatedAgentCodeIsValidator) {
          boo = true;
        }
      });
    });
    if (boo) {
      return true;
    }

    this.updateDelegationDelegatedAgentsData.forEach(data => {
      data.delegateInfos.forEach(element => {
        if (!this.delegateInfoValidate(element, false)) {
          boo = true;
        }
      });
    });

    if (!boo) {
      boo = !this.updateDelegationDelegatedAgentsData.some(data =>
        data.delegateInfos.some(
          element =>
            element.newDelegatedAgentCode.length === 6 &&
            element.newDelegatedAgentCodeIsValidator &&
            ((element.selectReasonCodeOfChange === 5 && element.inputReasonOfChange.length > 0) ||
              (element.selectReasonCodeOfChange > 0 && element.selectReasonCodeOfChange !== 5))
        )
      );
    }

    return boo;
  }

  resetDelegationChange() {
    if (this.disableReset) {
      return;
    }

    const popupRef: MatDialogRef<UserExitEditmodePopComponent> = this.cdsPopup.open(UserExitEditmodePopComponent, {});
    popupRef.afterClosed().subscribe(data => {
      if (data.agree) {
        this.newDelegatedAgentInputList.forEach(element => {
          element.clearErrorInfo();
        });

        this.stickyBarDataReset();
        this.updateDelegationDelegatedAgentsData.forEach(data => {
          data.delegateInfos.forEach(element => {
            element.isSeleted = false;
            element.newDelegatedAgentCode = '';
            element.newDelegatedAgentCodeIsValidator = false;
            element.newDelegatedAgentName = '';
            element.newDelegatedAgentNameTC = '';
            element.selectReasonCodeOfChange = -1;
            element.inputReasonOfChange = '';
            element.isShowMissReason = false;
            element.isShowMissAgentCode = false;
          });
        });

        this.stepper.removeLightsOutStep(3);
        this.stepper.removeLightsOutStep(4);
        this.refreshStep();
      }
    });
  }

  private stickyBarDataReset() {
    this.newDelegatedAgentNameSummary = '';
    this.newDelegatedAgentNameTCSummary = '';
    this.newDelegatedAgentCodeSummary = '';
    this.newDelegatedAgentCodeSummaryIsValidator = false;
    this.reasonCodeOfAgentChangeControlSummary = -1;
    this.theOtherReasonOfChangeSummary = '';
  }

  onNewDelegatedAgentCodeSummaryChange(val: string) {
    if (val.length === 0) {
      this.agentCodeErrMsg = '';
    }
    this.refreshStep();

    // const tempInfo = {
    //   newDelegatedAgentCodeIsValidator: false,
    //   newDelegatedAgentName: '',
    //   newDelegatedAgentNameTC: '',
    // };
    // const validate = this.asyncAgentCodeValidate(tempInfo)(val);
    // if (validate) {
    //   validate.subscribe(() => {
    //     this.newDelegatedAgentCodeSummaryIsValidator =
    //       tempInfo.newDelegatedAgentCodeIsValidator;
    //     this.newDelegatedAgentNameSummary = tempInfo.newDelegatedAgentName;
    //     this.newDelegatedAgentNameTCSummary = tempInfo.newDelegatedAgentNameTC;
    //     this.refreshStep();
    //   });
    // } else {
    //   this.newDelegatedAgentCodeSummaryIsValidator =
    //     tempInfo.newDelegatedAgentCodeIsValidator;
    //   this.newDelegatedAgentNameSummary = tempInfo.newDelegatedAgentName;
    //   this.newDelegatedAgentNameTCSummary = tempInfo.newDelegatedAgentNameTC;
    //   this.refreshStep();
    // }
  }

  delegateItemSeleted(delegateItem: DelegateInfo, event: boolean) {
    delegateItem.isSeleted = event;

    this.refreshStep();
  }

  reasonOfAgentChangeControlSummaryChange(val: number) {
    if (!val || this.reasonCodeOfAgentChangeControlSummary === val) {
      return;
    }

    this.reasonCodeOfAgentChangeControlSummary = val;

    if (val === 5) {
      const dialogRef = this.cdsPopup.open(AddToItemComponent);
      dialogRef.afterClosed().subscribe(result => {
        if (result != undefined) {
          if (typeof result === 'string' && result.trim().length > 0) {
            this.theOtherReasonOfChange(result);
          }
        } else {
          this.reasonCodeOfAgentChangeControlSummary = -1;
          this.theOtherReasonOfChangeSummary = '';
        }
        this.refreshStep();
      });
    } else {
      this.refreshStep();
    }
  }

  private theOtherReasonOfChange(val: string) {
    this.theOtherReasonOfChangeSummary = val;

    // this.updateDelegationDelegatedAgentsData.forEach(data => {
    //   data.delegateInfos
    //     .filter(item => item.isSeleted)
    //     .forEach(element => {
    //       element.inputReasonOfChange = val;

    //       this.delegateInfoValidate(element);
    //     });
    // });
  }

  submit() {
    if (this.disableSubmit) {
      return;
    }

    this.editedDelegationDelegatedAgentsData = this.needUpdateDelegationDelegatedAgentsData(this.updateDelegationDelegatedAgentsData);
    this.employersSubmitParam = this.loadEmployersSubmitParam(this.editedDelegationDelegatedAgentsData);

    this.employersSubmitParam.forEach(emp => {
      emp.data.forEach(element => {
        element.oldAgentCode = agentCodeRemovePrefix0(element.oldAgentCode);
        element.targetAgentCode = agentCodeRemovePrefix0(element.targetAgentCode);
      });
    });

    this.cdHttpServeService.post<ValiRespErrData[]>(this.employersValidation, this.employersSubmitParam).subscribe({
      next: res => {
        if (res.result === 2100) {
          const { title, messages, errorCode } = updateDelegationEmpValiRespErrHandle(this.languageChangeService, res.data);

          if (errorCode && errorCode === 2104) {
            messages.forEach(msg => {
              this.alert.error(title, msg as string);
            });
            return;
          }

          if (errorCode && errorCode === 2103) {
            const delegatedAgentsBranchErrComponentRef = this.cdsPopup.open(AgentBranchPopComponent, {
              size: 'md',
              data: {
                list: messages,
                language: this.employerQueryParams.language,
              },
            });
            delegatedAgentsBranchErrComponentRef.afterClosed().subscribe(res => {
              if (res.agree) {
                this.currentSite = SelfRouter.changeSummary;
                pageToTop();
              }
            });
            return;
          }

          this.currentSite = SelfRouter.changeSummary;
          pageToTop();

          // const updatedSuccessfullyRef = this.cdsPopup.open(
          //   CdWarningPopupComponent,
          //   {
          //     size: 'md',
          //     data: {
          //       title,
          //       messages,
          //       buttons: [
          //         {
          //           style: 'secondary',
          //           size: 'sm',
          //           text: 'confirmation.cancel',
          //           cb: () => {
          //             updatedSuccessfullyRef.close();
          //           },
          //         },
          //         {
          //           style: 'primary',
          //           size: 'sm',
          //           text: 'delegationChange.continue',
          //           cb: () => {
          //             updatedSuccessfullyRef.close();
          //             this.currentSite = SelfRouter.changeSummary;
          //           },
          //         },
          //       ],
          //     },
          //   }
          // );
        } else if (res.result === 0) {
          this.currentSite = SelfRouter.changeSummary;
          pageToTop();
        }
      },
      error: error => {
        console.error('error---', error);
        this.alert.error('Error!', error);
      },
    });
  }

  private loadEmployersSubmitParam(data: DelegatedAgentsResponseData[]): EmployersValidationParam[] {
    const params: EmployersValidationParam[] = [];
    data.forEach(element => {
      const itemParam: EmployersValidationParam = {
        employerId: element.employerInfo.id,
        data: [],
      };

      element.delegateInfos.forEach(delegate => {
        let message;
        if (delegate.selectReasonCodeOfChange === 5) {
          message = delegate.inputReasonOfChange;
        } else {
          message = this.getReasonOfChange(delegate.selectReasonCodeOfChange);
        }
        itemParam.data.push({
          oldAgentCode: delegate.agentCode,
          targetAgentCode: delegate.newDelegatedAgentCode,
          reason: {
            code: delegate.selectReasonCodeOfChange,
            message,
          },
        });
      });

      params.push(itemParam);
    });

    return params;
  }

  onBackChange() {
    this.currentSite = SelfRouter.change;
    setTimeout(() => {
      if (this.stepperTarget === 'ipad') {
        this.stepper.setStepList(this.stepList);
      }
      this.refreshStep();
    }, 0);
  }

  needUpdateDelegationDelegatedAgentsData(updateDelegationDelegatedAgentsData: DelegatedAgentsResponseData[]) {
    let cloneList = deepCopy(updateDelegationDelegatedAgentsData) as DelegatedAgentsResponseData[];
    cloneList.forEach(data => {
      data.delegateInfos = data.delegateInfos.filter(info => {
        return (
          info.newDelegatedAgentCode &&
          info.newDelegatedAgentCode.length > 0 &&
          info.selectReasonCodeOfChange > 0 &&
          ((info.selectReasonCodeOfChange === 5 && info.inputReasonOfChange.length > 0) || info.selectReasonCodeOfChange !== 5)
        );
      });
    });
    cloneList = cloneList.filter(item => item.delegateInfos.length > 0);
    cloneList.forEach(element => {
      element.isExpend = false;
    });
    return cloneList;
  }

  get editedDelegationDelegatedAgentsDataPageCount() {
    return Math.ceil(this.editedDelegationDelegatedAgentsData.length / 5);
  }

  get editedDelegationDelegatedAgentsShowData() {
    const startIndex = (this.editedDelegationDelegatedAgentsDataPage - 1) * 5;
    return this.editedDelegationDelegatedAgentsData.slice(startIndex, startIndex + 5);
  }

  editedDataPageChange(page: number) {
    this.editedDelegationDelegatedAgentsDataPage = page;
  }

  confirm() {
    const dialogRef = this.cdsPopup.open(CdWarningPopupComponent, {
      data: {
        title: 'delegationChange.confirmIn',
        buttons: [
          {
            style: 'secondary',
            size: 'sm',
            text: 'confirmation.cancel',
            cb: () => {
              dialogRef.close();
            },
          },
          {
            style: 'primary',
            size: 'sm',
            text: 'delegationChange.submit',
            cb: () => {
              dialogRef.close();

              this.onEmployersSubmit();

              const updatedSuccessfullyRef = this.cdsPopup.open(CdWarningPopupComponent, {
                data: {
                  title: 'delegationChange.gotItInfo',
                  buttons: [
                    {
                      style: 'primary',
                      size: 'sm',
                      text: 'confirmation.gotIt',
                      cb: () => {
                        updatedSuccessfullyRef.close();
                        this.clearAllPageData();
                        this.employerQueryParams.employerName = '';
                        this.employerQueryParams.employerNo = '';
                        this.employerQueryParams.delegateAgentCode = '';
                        this.employerQueryParams.page = 1;
                        this.employerQuery();
                        this.router.navigate(['/agent/employer-history']);
                      },
                    },
                  ],
                },
              });
            },
          },
        ],
      },
    });
  }

  onCanDelegateAmtToggle(event: MouseEvent) {
    if (event.target && this.stepperTarget === 'pc') {
      event.target.dispatchEvent(new Event('click'));
    }
  }

  getReasonOfChange(reasonCode: number) {
    const option = this.reasonOfAgentChangeConfig.options.find(item => item.value === reasonCode);
    return option?.label || '';
  }

  onEmployersSubmit() {
    this.cdHttpServeService.post(this.employersSubmit, this.employersSubmitParam).subscribe({
      next: res => {
        console.log('onEmployersSubmit', res);
      },
      error: error => {
        console.error('error---', error);
        this.alert.error('Error!', error);
      },
    });
  }

  delegateInfoValidate(delegate: DelegateInfo, isAllowEdit = true) {
    if (
      delegate.newDelegatedAgentCode.length === 6 &&
      delegate.newDelegatedAgentCodeIsValidator &&
      !(
        (delegate.selectReasonCodeOfChange === 5 && delegate.inputReasonOfChange.length > 0) ||
        (delegate.selectReasonCodeOfChange > 0 && delegate.selectReasonCodeOfChange !== 5)
      )
    ) {
      if (isAllowEdit) {
        delegate.isShowMissReason = true;
      }
      return false;
    } else if (
      !(delegate.newDelegatedAgentCode.length === 6 && delegate.newDelegatedAgentCodeIsValidator) &&
      ((delegate.selectReasonCodeOfChange === 5 && delegate.inputReasonOfChange.length > 0) ||
        (delegate.selectReasonCodeOfChange > 0 && delegate.selectReasonCodeOfChange !== 5))
    ) {
      if (isAllowEdit) {
        delegate.isShowMissAgentCode = true;
      }
      return false;
    } else {
      if (isAllowEdit) {
        delegate.isShowMissReason = false;
        delegate.isShowMissAgentCode = false;
      }
      return true;
    }
  }

  private onResetTable() {
    this.resetTable = true;
    setTimeout(() => {
      this.resetTable = false;
    }, 0);
  }

  get updateDelegationSelectedTotal() {
    let total = 0;

    this.updateDelegationDelegatedAgentsData.forEach(element => {
      element.delegateInfos.forEach(delegate => {
        if (delegate.isSeleted) {
          total++;
        }
      });
    });

    return total;
  }

  private refreshStep() {
    let stepNum = 1;

    if (this.updateDelegationDelegatedAgentsData.some(element => element.delegateInfos.some(delegate => delegate.isSeleted))) {
      stepNum = 2;
    }

    if (stepNum === 2 && this.newDelegatedAgentCodeSummary.length > 0) {
      stepNum = 3;
    }

    if (stepNum === 3 && this.reasonCodeOfAgentChangeControlSummary > 0) {
      stepNum = 4;
    }

    if (!this.disableSubmit) {
      stepNum = 5;

      if (this.updateDelegationDelegatedAgentsData.some(element => element.delegateInfos.some(delegate => delegate.isSeleted))) {
        if (this.newDelegatedAgentCodeSummary.length === 0) {
          this.stepper.setLightsOutStep(3);
        } else {
          this.stepper.removeLightsOutStep(3);
        }
        if (this.reasonCodeOfAgentChangeControlSummary === -1) {
          this.stepper.setLightsOutStep(4);
        } else {
          this.stepper.removeLightsOutStep(4);
        }
      } else {
        this.stepper.removeLightsOutStep(3);
        this.stepper.removeLightsOutStep(4);
      }
    }

    this.stepper.setFinishStep(stepNum);
  }

  get checkIsHaveScroll() {
    if (this.tableBox) {
      return this.tableBox.nativeElement.scrollWidth > this.tableBox.nativeElement.clientWidth;
    }
    return false;
  }
}
