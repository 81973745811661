import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize } from 'rxjs';
import { ToastAlertService } from 'src/app/shared/toast-alert.service';
import { employerStatusMapObj } from '../../employer/employer';
import { EmployeeService } from '../employee.service';
import { EmploymentInfo } from '../employee';

@Component({
  selector: 'app-employment-info',
  templateUrl: './employment-info.component.html',
  styleUrls: ['./employment-info.component.scss'],
})
export class EmploymentInfoComponent implements OnInit, OnChanges {
  @Input() id = '';

  isLoading = false;

  data: EmploymentInfo = {};

  employerStatusMapObj = employerStatusMapObj;

  constructor(public service: EmployeeService, private route: ActivatedRoute, private toastAlert: ToastAlertService, private router: Router) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['id'].currentValue !== undefined && changes['id'].currentValue !== changes['id'].previousValue) {
      this.getData();
    }
  }

  ngOnInit(): void {
    this.getData();
  }

  toastError(errorMessage: string) {
    this.toastAlert.show('error', 'common.error', errorMessage, 5000);
  }

  getData() {
    if (!this.id) return;
    this.isLoading = true;
    this.service
      .getEmploymentInfo(this.id)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe(res => {
        if (res.result === 0 && res.data) {
          this.data = res.data;
        } else {
          this.toastError(res.message);
        }
      });
  }
  goToVesting(data: EmploymentInfo) {
    this.router.navigate(['/employer/payroll-group-info'], { queryParams: { id: data.erId, payrollGroupId: data.payrollGroupId, tab: 'vestingRule' } });
  }
}
