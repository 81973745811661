<div class="sm-layout cds-grey light">
  <div class="menu">
    <div class="left-part">
      <div class="logo-con">
        <cds-icon style="width: 100%; height: 100%" color="#FFFFFF" icon="logo:Manulife_symbol"></cds-icon>
      </div>
    </div>
    <div class="center">Manulife 宏利</div>
    <div class="left-right">
      <div class="logo-con" [matMenuTriggerFor]="menu" (click)="isOpen = true">
        <cds-icon color="white" icon="general:globe"></cds-icon>
        <cds-icon color="white" icon="action:button_up" *ngIf="isOpen; else closeIcon"></cds-icon>
        <ng-template #closeIcon>
          <cds-icon color="white" icon="action:button_down"></cds-icon>
        </ng-template>
      </div>
    </div>
  </div>

  <div class="div-border-top height-screen" style="overflow: auto">
    <cds-content>
      <router-outlet></router-outlet>
    </cds-content>
  </div>

  <div style="background: white; width: 100%" class="col-xs-12 col-sm-12 col-md-12 col-lg-12 l-pt-3 div-border-top cds-grey light footer-fixed">
    <div class="row">
      <div class="col-xs-12 col-md-12 l-pa-2 login-privacy">
        {{ 'login.footer.privacy' | lang }}
      </div>
      <div class="col-xs-12 col-md-12 l-pa-2 login-privacy">
        {{ 'login.footer.leagal' | lang }}
      </div>
      <div class="col-xs-12 col-md-12 l-pa-2 login-privacy">
        {{ 'login.footer.terms' | lang }}
      </div>
      <div class="col-xs-12 col-md-12 l-pa-2 login-privacy">
        {{ 'login.footer.internet' | lang }}
      </div>
      <div class="col-xs-12 col-md-12 l-pa-2 login-privacy">{{ copyright }}&nbsp;{{ 'login.footer.company' | lang }}</div>
    </div>
  </div>
</div>

<div class="l-plr-0 md-bg md-layout cds-grey light">
  <div class="width-8 l-plr-0">
    <div class="menu width-8">
      <div class="left-part">
        <div class="logo-con">
          <cds-icon style="width: 100%; height: 100%" color="#FFFFFF" icon="logo:Manulife_symbol"></cds-icon>
        </div>
      </div>
      <div class="center">Manulife&nbsp;&nbsp;<span style="font-weight: 500">宏利</span></div>
      <div class="left-right">
        <div class="logo-con" [matMenuTriggerFor]="menu" (click)="isOpen = true">
          <cds-icon color="black" icon="general:globe"></cds-icon>
          <cds-icon color="black" icon="action:button_up" *ngIf="isOpen; else closeIcon2"></cds-icon>
          <ng-template #closeIcon2>
            <cds-icon color="black" icon="action:button_down"></cds-icon>
          </ng-template>
        </div>
      </div>
    </div>

    <div class="div-border-top" style="overflow: auto; padding-top: 92px; padding-bottom: 99px">
      <cds-content>
        <router-outlet></router-outlet>
      </cds-content>
    </div>

    <div style="background: white; height: 100px" class="div-border-top width-8 footer-fixed">
      <div class="l-pl-6 l-pr-6 l-pt-3 l-pb-4">
        <div class="footer-layout">
          <div class="l-pa-2 login-privacy">
            {{ 'login.footer.privacy' | lang }}
          </div>
          <div class="l-pa-2 login-privacy">
            {{ 'login.footer.leagal' | lang }}
          </div>
          <div class="l-pa-2 login-privacy">
            {{ 'login.footer.terms' | lang }}
          </div>
          <div class="l-pa-2 login-privacy">
            {{ 'login.footer.internet' | lang }}
          </div>
        </div>
        <div class="l-pa-2 login-privacy">{{ copyright }}&nbsp;{{ 'login.footer.company' | lang }}</div>
      </div>
    </div>
  </div>

  <div class="width-4 l-plr-0 img-height" style="position: fixed; right: 0"></div>
</div>

<mat-menu #menu="matMenu" (closed)="isOpen = false" class="options-list">
  <button
    mat-menu-item
    class="tabindex-focus"
    [class.selected]="item.value === selectedValue"
    *ngFor="let item of languages"
    (click)="changeLang(item.value)"
    tabindex="-1">
    <span class="cds-detail1">{{ item.label | lang }}</span>
  </button>
</mat-menu>
