<cds-popup class="popup no-close-icon">
  <cds-popup-title>
    <div *ngIf="data.editMode">Edit Special Quote</div>
    <div *ngIf="!data.editMode">
      {{ isSummary ? 'Add Special Quote Summary' : 'Add Special Quote' }}
    </div>
    <div class="close-icon" (click)="close()">
      <cds-icon icon="action:cross"></cds-icon>
    </div>
  </cds-popup-title>

  <cds-popup-content>
    <div class="row l-plr-0 l-ai-cen relative" *ngIf="!isSummary">
      <app-loading *ngIf="isLoading" [overlayInPopup]="true"></app-loading>

      <!-- Delete -->
      <div *ngIf="data.editMode" class="desktop-show">
        <ng-container [ngTemplateOutlet]="deleteTemplate"></ng-container>
      </div>

      <!-- Delete -->
      <div *ngIf="data.editMode" class="mobile-show">
        <ng-container [ngTemplateOutlet]="deleteTemplate"></ng-container>
      </div>

      <!-- SQ Reference No. -->
      <div class="l-mb-5 col-xs-12 col-md-6">
        <cds-textfield
          [config]="txtfieldConfig"
          (change)="validate()"
          [(ngModel)]="dataSource.sqReferenceNo"
          #sqReferenceNo="ngModel"
          required
          [maxlength]="50"
          [appDescriptionValidator]="I18N_KEY.INVALID_DESCRIPTION">
        </cds-textfield>

        <div *ngIf="sqReferenceNo.invalid && (sqReferenceNo.dirty || sqReferenceNo.touched)" class="l-pt-2">
          <cds-assistive-text *ngIf="sqReferenceNo?.errors?.['required']" class="l-pt-2" [showIcon]="false">Misssing SQ Reference No.</cds-assistive-text>
          <cds-assistive-text *ngIf="sqReferenceNo?.errors?.['maxlength']" class="l-pt-2" [showIcon]="false">Max length is 50</cds-assistive-text>
          <cds-assistive-text
            *ngIf="sqReferenceNo?.errors?.['descriptionInvalid']"
            class="l-pt-2"
            [showIcon]="false"
            >{{ sqReferenceNo?.errors?.['descriptionInvalid'] | lang }}</cds-assistive-text
          >
        </div>
      </div>

      <!-- SQ Submission Date -->
      <div class="l-mb-5 col-xs-12 col-md-6" *ngIf="showDatePicker">
        <cds-textfield
          type="text"
          label="SQ Submission Date"
          placeholder="DD/MM/YYYY"
          [(ngModel)]="dataSource.sqSubmissionDate"
          #sqSubmissionDate="ngModel"
          required
          [appDateValidator]="I18N_KEY.INVALID_DATE"
          [appDateValidatorMaxDate]="nowDateStr"
          (change)="validate()"
          [max]="nowDate"
          [cdsDatepicker]="picker"></cds-textfield>
        <cds-datepicker #picker="cdsDatepicker"></cds-datepicker>

        <div *ngIf="sqSubmissionDate.invalid && (sqSubmissionDate.dirty || sqSubmissionDate.touched)" class="l-pt-2">
          <cds-assistive-text *ngIf="sqSubmissionDate?.errors?.['required']" class="l-pt-2" [showIcon]="false">Missing SQ Submission Date</cds-assistive-text>
          <cds-assistive-text
            *ngIf="sqSubmissionDate?.errors?.['dateInvalid']"
            class="l-pt-2"
            [showIcon]="false"
            >{{ sqSubmissionDate?.errors?.['dateInvalid'] | lang }}</cds-assistive-text
          >
        </div>
      </div>

      <!-- Apply to SSN -->
      <ng-container *ngIf="!data.editMode">
        <app-cd-multidropdown
          class="l-mb-5 col-xs-12 col-md-6"
          [style.width]="'100%'"
          label="Apply to SSN"
          placeholder="Select Company"
          [(selected)]="dataSource.companyList"
          (selectedChange)="validate()"
          [toppingList]="customerOption"></app-cd-multidropdown>

        <div (click)="selectAll()" class="select-all l-mb-5 col-xs-12 col-md-6">
          <!-- <cds-icon
            *ngIf="
              ifSelectAll | fn : dataSource.companyList?.length;
              else notSelectAll
            "
            icon="action:button_checkmark_outlined"
            [config]="selectAllIconConfig"></cds-icon> -->
          <cds-icon icon="action:button_checkmark_outlined" [config]="selectAllIconConfig"></cds-icon>
          <span class="l-ml-2 cds-body2 bold l-mb-0">Apply to all Related Groups</span>
        </div>
      </ng-container>

      <!-- Quote Type -->
      <div class="l-mb-5 col-xs-12 col-md-6 top-2">
        <cds-dropdown [(ngModel)]="dataSource.quoteType" #quoteType="ngModel" (change)="validate()" [config]="dropdownConfig"> </cds-dropdown>
      </div>

      <!-- Quote Status -->
      <div class="l-mb-5 col-xs-12 col-md-6 top-2">
        <cds-dropdown [(ngModel)]="dataSource.quoteStatus" #quoteStatus="ngModel" (change)="validate()" [config]="statusDropdownConfig"> </cds-dropdown>
      </div>

      <div class="l-mb-5 col-xs-12">
        <cds-checkbox [(ngModel)]="dataSource.showRemarks" (change)="validate()" label="Remarks"> </cds-checkbox>
      </div>

      <div class="l-mb-5 col-xs-12" *ngIf="dataSource.showRemarks">
        <cds-form-field class="full-width">
          <cds-textarea
            [maxRows]="1"
            [(ngModel)]="dataSource.remarks"
            #remarks="ngModel"
            required
            [maxlength]="200"
            [appDescriptionValidator]="I18N_KEY.INVALID_DESCRIPTION"
            (change)="validate()"
            placeholder="Input Remark Message">
          </cds-textarea>

          <ng-container *ngIf="remarks?.invalid && (remarks?.dirty || remarks?.touched)">
            <cds-assistive-text *ngIf="remarks?.errors?.['required']" [showIcon]="false"
              >Please input Remark message as ‘Remarks’ button is selected</cds-assistive-text
            >
            <cds-assistive-text *ngIf="remarks?.errors?.['maxlength']" [showIcon]="false">Max length is 200</cds-assistive-text>
            <cds-assistive-text
              *ngIf="remarks?.errors?.['descriptionInvalid']"
              [showIcon]="false"
              >{{ remarks?.errors?.['descriptionInvalid'] | lang }}</cds-assistive-text
            >
          </ng-container>
        </cds-form-field>
      </div>
    </div>

    <div *ngIf="isSummary">
      <div class="row-layout">
        <div class="label">SQ Reference No.</div>
        <div class="value">
          {{ dataSource.sqReferenceNo }}
        </div>
      </div>

      <div class="row-layout">
        <div class="label">Apply to SSN</div>
        <div class="value">
          <div *ngFor="let item of dataSource.companyList">
            <span>{{ item.label }}</span
            ><span class="l-ml-4">{{ item.subLabel }}</span>
          </div>
        </div>
      </div>

      <div class="row-layout">
        <div class="label">SQ Submission Date</div>

        <div class="value">
          {{ dataSource.sqSubmissionDate }}
        </div>
      </div>

      <div class="row-layout">
        <div class="label">Quote Type</div>
        <div class="value">
          {{ employerService.optionLabelRender | fn : dataSource.quoteType : SpecialQuoteTypeOptions }}
        </div>
      </div>

      <div class="row-layout">
        <div class="label">Status</div>
        <div class="value">
          {{ employerService.optionLabelRender | fn : dataSource.quoteStatus : SpecialQuoteStatusOptions }}
        </div>
      </div>

      <div class="row-layout">
        <div class="label">Remarks</div>
        <div class="value">
          {{ dataSource.showRemarks ? dataSource.remarks : '' }}
        </div>
      </div>
    </div>
  </cds-popup-content>

  <cds-popup-actions>
    <ng-container *ngIf="!isSummary">
      <cds-button [config]="resetButtonConfig" [disabled]="resetDisabled" (click)="reset()">{{ 'common.reset' | lang }}</cds-button>

      <cds-button *ngIf="!data.editMode" [config]="saveButtonConfig" [disabled]="saveDisabled" (click)="save()">{{ 'common.submit' | lang }}</cds-button>

      <cds-button *ngIf="data.editMode" [config]="saveButtonConfig" [disabled]="saveDisabled" (click)="confirm()">{{ 'common.confirm' | lang }}</cds-button>
    </ng-container>

    <ng-container *ngIf="isSummary">
      <cds-button [config]="backButtonConfig" (click)="back()">{{ 'common.backToEdit' | lang }}</cds-button>

      <cds-button [config]="saveButtonConfig" (click)="confirm()">{{ 'common.confirm' | lang }}</cds-button>
    </ng-container>
  </cds-popup-actions>
</cds-popup>

<ng-template #deleteTemplate>
  <app-permission [role]="PermissionAccess.E" [permission]="PermissionItem.CUSTOMER_EMPLOYER_SQ">
    <div class="button-area" (click)="delete()">
      <div class="icon-size">
        <cds-icon icon="form:delete" [config]="iconConfig"></cds-icon>
      </div>
      <span class="cds-h6-demibold l-pl-1">Delete Record</span>
    </div>
  </app-permission>
</ng-template>
