import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TerminationListComponent } from './termination-list/termination-list.component';
import { TerminationRoutingModule } from './termination-routing.module';
import { CdsProgressModule } from '@cds/ng-web-components/progress';
import { CdsLanguageModule } from '@cds/ng-core/lang';
import { CdsTextfieldModule } from '@cds/ng-web-components/textfield';
import { CdsIconModule } from '@cds/ng-web-components/icon';
import { CdsButtonModule } from '@cds/ng-web-components/button';
import { CdsPaginationModule } from '@cds/ng-web-components/pagination';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CdsPopupModule } from '@cds/ng-web-components/popup';
import { HttpClientModule } from '@angular/common/http';
import { CdsTableModule } from '@cds/ng-data-table/table';
import { CdsSortModule } from '@cds/ng-data-table/sort';
import { CdsCheckboxModule } from '@cds/ng-web-components/checkbox';
import { CdsTabsModule } from '@cds/ng-web-components/tabs';
import { CdsDropdownModule } from '@cds/ng-web-components/dropdown';
import { CdsTextareaModule } from '@cds/ng-web-components/textarea';
import { CdsTooltipModule } from '@cds/ng-web-components/tooltip';
import { CdAccordionModule } from 'src/app/components/accordion/cd-accordion.module';
import { RowItemModule } from 'src/app/components/row-item/row-item.module';
import { ExpandTableModule } from 'src/app/components/table/expand-table.module';
import { ExpandItemComponent } from './termination-list/expand-item/expand-item.component';
import { ExpandItemMobileComponent } from './termination-list/expand-item-mobile/expand-item-mobile.component';
import { SharedModule } from 'src/app/shared/shared.module';

@NgModule({
  declarations: [TerminationListComponent, ExpandItemComponent, ExpandItemMobileComponent],
  imports: [
    CommonModule,
    TerminationRoutingModule,
    CdsTextfieldModule,
    CdsLanguageModule,
    CdsIconModule,
    CdsButtonModule,
    CdsPaginationModule,
    FormsModule,
    CdsPopupModule,
    HttpClientModule,
    CdsProgressModule,
    CdsTableModule,
    CdsSortModule,
    CdsCheckboxModule,
    CdsTabsModule,
    CdsTableModule,
    CdsDropdownModule,
    CdsTooltipModule,
    CdsIconModule,
    ReactiveFormsModule,
    CdsTextareaModule,
    ExpandTableModule,
    CdAccordionModule,
    RowItemModule,
    SharedModule,
  ],
})
export class TerminationModule {}
