/* eslint-disable @typescript-eslint/no-explicit-any */
import { utils, writeFile, BookType, Range } from 'xlsx';

export function aoaToSheet(options: optionsConfig, data: string[][]) {
  const fileType = options.fileType || FileType.XLSX;
  const workBook = utils.book_new();
  const workSheet = utils.aoa_to_sheet(data);
  const hideColumns = options.hideColumns;
  if (options.merges) {
    workSheet['!merges'] = options.merges;
  }
  if (hideColumns) {
    hideColumns.forEach(columnIndex => {
      workSheet['!cols'] = workSheet['!cols'] || [];
      workSheet['!cols'][columnIndex] = { hidden: true };
    });
  }
  utils.book_append_sheet(workBook, workSheet);
  writeFile(workBook, `${options.fileName}.${fileType}`, {
    bookType: fileType,
  });
}

export function jsonToSheet(options: optionsConfig, data: any[]) {
  let tempData = [...data];
  const header = options.header;
  const hideColumns = options.hideColumns;
  const fileType = options.fileType || FileType.XLSX;
  if (header) {
    tempData = tempData.map(item => {
      const obj: any = {};
      for (const key in item) {
        if (header[key]) {
          obj[header[key]] = item[key];
        } else {
          obj[key] = item[key];
        }
      }
      return obj;
    });
  }
  if (hideColumns) {
    hideColumns.forEach(columnIndex => {
      workSheet['!cols'] = workSheet['!cols'] || [];
      workSheet['!cols'][columnIndex] = { hidden: true };
    });
  }
  const workBook = utils.book_new();
  const workSheet = utils.json_to_sheet(tempData);
  if (options.merges) {
    workSheet['!merges'] = options.merges;
  }
  utils.book_append_sheet(workBook, workSheet);
  writeFile(workBook, `${options.fileName}.${fileType}`, {
    bookType: fileType,
  });
}

export interface optionsConfig {
  fileName: string;
  fileType?: BookType;
  header?: any;
  merges?: Range[];
  hideColumns?: number[];
}

export enum FileType {
  XLSX = 'xlsx',
  CSV = 'csv',
}

export const DOWN_LOAD_MAX = 200000;
