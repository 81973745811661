<div class="cd-autocomplete" [class.focus]="isFocus">
  <mat-form-field class="example-full-width" appearance="fill">
    <div *ngIf="iconLocation === 'between'" class="icon-box cd-search-icon" (click)="onSearch()">
      <cds-icon [config]="{ size: 'xs' }" icon="form:search"></cds-icon>
    </div>
    <input
      (blur)="onBlur()"
      (keydown.enter)="onSearch()"
      (focus)="onFocus()"
      type="text"
      matInput
      placeholder="{{ (placeholder ? placeholder : '') | lang }}"
      class="text-field"
      [ngClass]="[iconLocation === 'right' ? 'icon-location-right' : '']"
      [formControl]="autoCompleteControl"
      [matAutocomplete]="auto"
      #trigger="matAutocompleteTrigger"
      appCdAutocompletePosition="trigger" />
    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" (optionSelected)="optionSelectedFn($event)">
      <mat-option *ngFor="let option of optionsDisplayed" [value]="option">
        {{ option.label | lang }}
      </mat-option>
    </mat-autocomplete>
    <div *ngIf="iconLocation === 'between'" class="icon-box cd-search-clear-icon">
      <cds-icon [config]="{ color: '#8E90A2', size: 'xs' }" icon="action:button_x_filled" (click)="clearInput()"></cds-icon>
    </div>

    <div *ngIf="iconLocation === 'right'" class="icon-location-right-x-icon" #iconLocationRightXIcon>
      <cds-icon [config]="{ color: '#8E90A2', size: 'xs' }" icon="action:button_x_filled" (click)="clearInput()"></cds-icon>
    </div>
    <div *ngIf="iconLocation === 'right'" class="icon-location-right-search-icon" #iconLocationRightSearchIcon (click)="iconSearch()">
      <cds-icon [config]="{ size: 'xs' }" icon="form:search"></cds-icon>
    </div>
  </mat-form-field>
</div>
