import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { CdsOption } from '@cds/ng-core/configuration';
import { CdsIconConfig } from '@cds/ng-core/icon';
import { ActionIcons } from '@cds/ng-themes';
import { CdsPopupService } from '@cds/ng-web-components/popup';
import moment from 'moment';
import { IFF_STATUS } from 'src/app/config/iff-status.config';
import { IffStatusService } from 'src/app/core/services/iff-status/iff-status.service';
import { dateRangeInvalidValidator } from 'src/app/core/validators';
import { dateValidator } from 'src/app/shared/validators/validators';
import { copyText } from 'src/app/utils/utils';
import { AdviseEmpfrefComponent } from '../../case-summary/advise-empfref/advise-empfref.component';
import { IffStatusFlowPopupComponent } from '../../iff-status-flow-popup/iff-status-flow-popup.component';
import { CdsToastService } from '@cds/ng-web-components/toast';
import { AddMpfReferenceNumberPopupComponent } from '../../add-mpf-reference-number-popup/add-mpf-reference-number-popup.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-mdiff-agent-er',
  templateUrl: './mdiff-agent-er.component.html',
  styleUrls: ['./mdiff-agent-er.component.scss'],
})
export class MdiffAgentErComponent implements OnInit {
  copyText = copyText;
  formGroup!: FormGroup;
  readonly displayedColumns = ['MDRefNo', 'EmployerName', 'BRNumber', 'MDDeclarationDate', 'MDIFFStatus', 'SplitCase', 'AddeMPFRefNo'];
  allPageData = [
    [
      {
        MDRefNo: 'MD12312831D',
        EmployerName: 'asdf',
        BRNumber: 'sdfsdf',
        MDDeclarationDate: '15/06/2024',
        MDIFFStatus: 'Pending Split Agent(s)',
        SplitCase: '1',
        AddeMPFRefNo: 'abc',
      },
    ],
  ];
  currentPageNumOrigin = 1;
  resetDatePicker = false;
  declarationDateFromMax = new Date('3000/01/01');
  declarationDateToMin = new Date('2000/01/01');
  iffStatusoptionList: CdsOption[] = [];
  isLoading = false;
  totalElements = 0;
  iconConfig: CdsIconConfig = {
    size: 'sm',
    color: 'default',
  };
  infoIcon = ActionIcons.info_1;

  get currentPageIndex() {
    return this.currentPageNumOrigin - 1;
  }

  get currentPageData() {
    if (this.allPageData.length === 0) {
      return [];
    }
    return this.allPageData[this.currentPageIndex];
  }

  get declarationDateFrom() {
    return this.formGroup.get('declarationDateFrom') as FormControl;
  }

  get declarationDateTo() {
    return this.formGroup.get('declarationDateTo') as FormControl;
  }

  get mDIFFStatus() {
    return this.formGroup.get('mDIFFStatus') as FormControl;
  }

  constructor(private cdsPopup: CdsPopupService, public iffStatusService: IffStatusService, private toast: CdsToastService, private router: Router) {}

  ngOnInit(): void {
    this.formGroup = new FormGroup(
      {
        mdReferenceNo: new FormControl(),
        employerName: new FormControl(),
        declarationDateFrom: new FormControl(null, {
          validators: [dateValidator('common.invalidDate')],
        }),
        declarationDateTo: new FormControl(null, {
          validators: [dateValidator('common.invalidDate')],
        }),
        bRNumber: new FormControl(),
        mDIFFStatus: new FormControl([]),
      },
      [dateRangeInvalidValidator('declarationDateFrom', 'declarationDateTo')]
    );

    this.initIffStatusOptions();
  }

  onClickSearch() {}

  onClickReset() {
    this.formGroup.reset({
      mDIFFStatus: [],
    });
  }

  private initIffStatusOptions() {
    this.iffStatusoptionList = IFF_STATUS.map<CdsOption>(element => ({
      label: element.text,
      value: element.value,
    }));
  }

  declarationDateFromChange(val: string) {
    const dateObject = moment(val, 'DD/MM/YYYY');
    if (dateObject.isValid()) {
      this.declarationDateToMin = dateObject.toDate();
    }
  }

  declarationDateToChange(val: string) {
    const dateObject = moment(val, 'DD/MM/YYYY');
    if (dateObject.isValid()) {
      this.declarationDateFromMax = dateObject.toDate();
    }
  }

  onGenerateNewMDReferenceNo() {}

  onOpenAddEmpfRefNoPopup() {
    this.cdsPopup.open(AddMpfReferenceNumberPopupComponent, {
      size: 'sm',
    });
  }

  onDisplayAdviseIFF() {
    this.cdsPopup.open(IffStatusFlowPopupComponent, {
      size: 'sm',
    });
  }

  onDisplayAdviseRef() {
    this.cdsPopup.open(AdviseEmpfrefComponent, {
      size: 'sm',
    });
  }

  pageChange(num: number): void {
    console.log(num);
  }

  copy() {
    this.toast.success('Copy successfully');
  }

  onGoCaseDetails() {
    this.router.navigate(['/salesjourney/er-empf-case-details']);
  }
}
