import { PaymentNotice } from './../employer';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { CdsButtonConfig } from '@cds/ng-core/button';
import { CdsOption } from '@cds/ng-core/configuration';
import { CdsDropdownConfig } from '@cds/ng-core/dropdown';
import { CdsRadioConfig } from '@cds/ng-core/radio';
import { DateTime } from 'luxon';
import moment from 'moment';
import { finalize } from 'rxjs';
import { BTN_CONFIG_TERTIARY } from 'src/app/config/btn.config';
import { ColumnConfig } from 'src/app/shared/data-table';
import { dateValidator } from 'src/app/shared/validators/validators';
import { MONTH_OPTS } from 'src/app/config/month.config';
import { AppealDetails, EmployerProfileDetail, PaymentNoticeRequest, PaymentNoticeTableItem } from '../employer';
import { EmployerService } from '../employer.service';
import { ToastAlertService } from 'src/app/shared/toast-alert.service';

@Component({
  selector: 'app-employer-profile-payment-notice',
  templateUrl: './employer-profile-payment-notice.component.html',
  styleUrls: ['./employer-profile-payment-notice.component.scss'],
})
export class EmployerProfilePaymentNoticeComponent implements OnInit, OnChanges {
  @Input() employerProfileDetail?: EmployerProfileDetail;

  radioConfig: CdsRadioConfig = {
    options: [
      { label: 'Contribution Date', value: 'DAY' },
      { label: 'Contribution Month', value: 'MONTH' },
    ],
  };

  form = new FormGroup({});

  data: PaymentNotice[] = [];

  paymentNoticeTableDataSource: PaymentNoticeTableItem[] = [];

  apealDetailsDataSource: AppealDetails[] = [];

  get dateControl() {
    return this.form.get('date');
  }

  columnsConfig: ColumnConfig[] = [
    {
      key: 'noticeIssueDate',
      title: 'Notice Issue Date',
    },
    {
      key: 'typeOfNotice',
      title: 'Type of Notice',
    },
    {
      key: 'noticeReferenceNumber',
      title: 'Notice Reference No.',
    },
    {
      key: 'firstPaymentDueDate',
      title: 'First Payment Due Date',
    },
    {
      key: 'secondPaymentDueDate',
      title: 'Second Payment Due Date',
    },
  ];

  apealDetailsColumnsConfig: ColumnConfig[] = [
    { key: 'appealSubmissionDate', title: 'Appeal Submission Date' },
    { key: 'appealResultStatus', title: 'Appeal Result Status' },
    { key: 'dateOfAppealResult', title: 'Date of Appeal Result' },
    { key: 'mpfaObjectionCaseNumber	', title: 'MPFA Objection Case No.' },
  ];

  moreBtnCfg: CdsButtonConfig = BTN_CONFIG_TERTIARY;

  maxTime = this.getNowTime();

  maxTimeInStr = DateTime.fromJSDate(this.maxTime).toFormat('dd/MM/yyyy');

  get searchBy() {
    return this.form.get('searchBy')?.value || '';
  }

  get monthControl() {
    return this.form.get('month');
  }

  get yearControl() {
    return this.form.get('year');
  }

  monthCfg: CdsDropdownConfig = this.getMonthCfg();

  yearCfg: CdsDropdownConfig = this.getYearCfg();

  get currentMonth() {
    return new Date().getMonth();
  }

  get currentYear() {
    return new Date().getFullYear();
  }

  get currentDate() {
    return DateTime.now().toFormat('dd/MM/yyyy');
  }

  isLoading = false;

  showMore = false;

  constructor(private service: EmployerService, private toastAlert: ToastAlertService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['employerProfileDetail'] && changes['employerProfileDetail'].currentValue !== changes['employerProfileDetail'].previousValue) {
      if (this.employerProfileDetail) {
        this.getPaymentNotice();
      }
    }
  }

  ngOnInit(): void {
    this.initForm();
  }

  initForm() {
    this.form = new FormGroup({
      searchBy: new FormControl('DAY'),
      date: new FormControl(this.currentDate, [dateValidator('create.dateError', '', this.maxTimeInStr)]),
      month: new FormControl(this.currentMonth),
      year: new FormControl(this.currentYear),
    });
  }

  getPaymentNotice() {
    if (!this.employerProfileDetail) return;
    const params: PaymentNoticeRequest = {
      startDate: this.searchBy === 'DAY' ? this.dateTrans(this.dateControl?.value) : '',
      id: this.employerProfileDetail?.id,
      erAcctCd: this.employerProfileDetail?.employerAccountCode,
      type: this.searchBy === 'DAY' ? 'day' : 'month',
      conMnth: this.searchBy === 'DAY' ? '' : this.getConMnth(),
    };
    this.isLoading = true;
    this.showMore = false;
    this.service
      .getPaymentNotice(params)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe(res => {
        if (res.result === 0) {
          this.data = res.data?.list || [];
        } else {
          this.toastError(res.message);
        }
      });
  }

  onDateChange() {
    if (this.dateControl?.valid) {
      this.getPaymentNotice();
    }
  }

  radioChange(value: string) {
    this.getPaymentNotice();
    if (value === 'MONTH') {
      // this.dateControl?.setValue(undefined);
      // this.monthControl?.setValue(this.currentMonth);
      // this.yearControl?.setValue(this.currentYear);
    } else {
      // this.monthControl?.setValue(undefined);
      // this.yearControl?.setValue(undefined);
      // this.dateControl?.setValue(this.currentDate);
    }
  }

  createMonthOpts(): CdsOption[] {
    if (this.yearControl?.value !== this.currentYear) {
      return [...MONTH_OPTS];
    }
    return MONTH_OPTS.slice(0, this.currentMonth + 1);
  }

  createYearOpts(): CdsOption[] {
    const result = [];
    for (let i = this.currentYear; i >= 1970; i--) {
      result.push({
        label: i.toString(),
        value: i,
      });
    }
    return result;
  }

  dateYearChange(value?: number) {
    this.monthCfg = this.getMonthCfg();
    if (this.currentYear === value && this.monthControl?.value > this.currentMonth) {
      this.monthControl?.setValue(undefined);
    } else if (value !== undefined) {
      this.getPaymentNotice();
    }
  }

  dateMonthChange(value?: number) {
    if (value !== undefined) {
      this.getPaymentNotice();
    }
  }

  dateTrans(date: string) {
    if (date) {
      return moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD');
    }

    return date;
  }

  getConMnth() {
    if (this.monthControl?.value === '') return '';
    return DateTime.fromObject({ year: this.yearControl?.value, month: this.monthControl?.value + 1 }).toFormat('yyyy-MM');
  }

  getNowTime() {
    return moment().toDate();
  }

  getMaxFromDate = (toDateStr?: string) => {
    if (!toDateStr) {
      return this.getNowTime();
    }
    return moment(toDateStr, 'DD/MM/YYYY').toDate();
  };

  getMonthCfg(): CdsDropdownConfig {
    return { options: this.createMonthOpts() };
  }

  getYearCfg(): CdsDropdownConfig {
    return { options: this.createYearOpts() };
  }

  clickShowMore() {
    this.showMore = true;
  }

  toastError(errorMessage: string) {
    this.toastAlert.show('error', 'common.error', errorMessage, 5000);
  }
}
