<app-loading [overlay]="true" *ngIf="isLoading"></app-loading>

<app-employee-profile-title [detailData]="detailData" [isPolicy]="true"></app-employee-profile-title>

<!-- <div class="row l-pt-8">
  <div class="title-img">
    <cds-icon size="md" color="#00A758" icon="people:login_1"></cds-icon>
  </div>
  <div class="flex l-jc-sb">
    <div>
      <div>
        <div class="cds-h4-bold item-title no-margin bold">{{ detailData.lastName }}{{ detailData.firstName }}</div>
        <div class="item-value cds-body1-demibold-margin bold">{{ detailData.lastZhName }}{{ detailData.firstZhName }}</div>
      </div>
      <div class="flex l-ai-cen l-mt-1">
        <app-icon-status [value]="detailData.status" [statusMapObj]="employeeStatusMapObj"></app-icon-status>
      </div>
      <div *ngIf="detailData?.latestUpdate" class="row middle-xs l-plr-0 l-pt-1 cds-body2">
        <span class="l-mr-1">Latest update from eMPF:</span>
        <span class="l-mr-1"> {{ updateDate | fn : detailData.latestUpdate }}</span>
        <span>(HKT)</span>
      </div>
    </div>
  </div>
</div> -->

<div class="row">
  <div class="col-xs-6 l-pt-8 l-plr-0">
    <cds-dropdown [(ngModel)]="id" [config]="dropdownConfig" (cdsChange)="onSelectChange($event)"></cds-dropdown>
  </div>
</div>

<div class="row l-pt-5">
  <div class="col-xs-12 row middle-xs basic-pad">
    <div class="l-mr-2">
      <cds-icon color="#282B3E" width="20px" height="20px" icon="form:document"></cds-icon>
    </div>
    <div>
      <span class="cds-h5-demibold">{{ 'payroll.basic-information' | lang }}</span>
    </div>
  </div>
  <div class="col-xs-12 row bg-white l-pt-2 l-pb-2">
    <app-row-item label="ee.member-empf-id" [data]="detailData.empfId" *ngIf="!isStaff" class="col-xs-6 col-sm-3 col-md-3 col-lg-3"> </app-row-item>
    <app-row-item label="sendIffInBatchSummary.dob" [data]="detailData.dob | ssmsDate" *ngIf="!isStaff" class="col-xs-6 col-sm-3 col-md-3 col-lg-3">
    </app-row-item>
    <app-row-item label="employee.ageRank" [data]="detailData.age" *ngIf="!isStaff" class="col-xs-6 col-sm-3 col-md-3 col-lg-3"> </app-row-item>
    <app-row-item label="employee.gender" [data]="this.getGender(detailData.gender)" *ngIf="!isStaff" class="col-xs-6 col-sm-3 col-md-3 col-lg-3">
    </app-row-item>
    <app-row-item label="ee.member-account-code" [data]="detailData.memberAccountCode" class="col-xs-6 col-sm-3 col-md-3 col-lg-3"> </app-row-item>
    <app-row-item label="ee.origin-member-account-code" [data]="detailData.originalMemberAccountCode" class="col-xs-6 col-sm-3 col-md-3 col-lg-3">
    </app-row-item>
    <app-row-item label="ee.last-updated-date-of-member-status" [data]="detailData.latestUpdate | ssmsDate" class="col-xs-6 col-sm-3 col-md-3 col-lg-3">
    </app-row-item>
    <app-row-item label="ee.opt.Scheme-Code" [data]="schemeCodeRender | fn : detailData.schemeCode" *ngIf="isStaff" class="col-xs-6 col-sm-3 col-md-3 col-lg-3">
    </app-row-item>
    <app-row-item label="ee.member-account-type" [data]="detailData.memberAccountType" class="col-xs-6 col-sm-3 col-md-3 col-lg-3"> </app-row-item>
    <app-row-item
      label="ee.profile.Employee-Type"
      [data]="employeeTypeRender | fn : detailData.employeeType"
      *ngIf="isStaff"
      class="col-xs-6 col-sm-3 col-md-3 col-lg-3">
    </app-row-item>
    <app-row-item label="ee.scheme-joined-date" *ngIf="!isStaff" [data]="detailData.schemeJoinedDate | ssmsDate" class="col-xs-6 col-sm-3 col-md-3 col-lg-3">
    </app-row-item>
    <app-row-item label="ee.business-nature" [data]="businessNatureRender | fn : detailData.businessNature" class="col-xs-6 col-sm-3 col-md-3 col-lg-3">
    </app-row-item>
    <app-row-item label="ee.occupation-job-position" [data]="occupationJobPosition | fn : detailData" class="col-xs-6 col-sm-3 col-md-3 col-lg-3">
    </app-row-item>
    <app-row-item label="ee.name-of-employer" [data]="detailData.employerName" *ngIf="!isStaff" class="col-xs-6 col-sm-3 col-md-3 col-lg-3"> </app-row-item>
    <app-row-item label="ee.scheme-joined-date" *ngIf="isStaff" [data]="detailData.schemeJoinedDate | ssmsDate" class="col-xs-6 col-sm-3 col-md-3 col-lg-3">
    </app-row-item>

    <app-row-item label="ee.eca-last-transfer-in-date" [data]="detailData.ecaLastTransferInDate | ssmsDate" class="col-xs-6 col-sm-3 col-md-3 col-lg-3">
    </app-row-item>
    <app-row-item label="ee.pm-last-transfer-in-date" [data]="detailData.pmLastTransferInDate | ssmsDate" class="col-xs-6 col-sm-3 col-md-3 col-lg-3">
    </app-row-item>

    <div class="col-xs-12 l-mt-6" *ngIf="isStaff">
      <div class="cds-body1-demibold l-mb-4 bold">{{ 'er.profile.commission-information' | lang }}</div>
      <div class="cds-body2">
        {{ 'er.profile.commission-pool-number' | lang }}
        <span class="db-click cds-body1" (click)="gotoPool(detailData.commissionPoolNumber)">{{ detailData.commissionPoolNumber }}</span>
      </div>
    </div>
  </div>
</div>

<div class="l-mt-8">
  <app-contact [id]="id" (gainData)="contactDataChange($event)"></app-contact>
</div>

<div class="row l-pt-8 l-mb-8" *ngIf="!isStaff">
  <div class="col-xs-12">
    <div class="l-mb-6">
      <app-campaign-information [md5]="md5" [inPolicy]="true"></app-campaign-information>
    </div>
  </div>
</div>

<div class="row p-tab l-pt-8" *ngIf="typeChecked && permissionChecked && (isStaff || withMa)">
  <div class="col-xs-12 l-plr-0 contribution-tab-s">
    <cds-tab-group *ngIf="tabReload" [selectedIndex]="tabIndex" (selectedTabIndexChange)="selectedTabIndexChange($event)">
      <cds-tab label="Policy Details" *ngIf="isStaff">
        <app-policy-detail [id]="id" *ngIf="ifLoadTab | fn : activedTabList : 'policyDetails'"></app-policy-detail>
      </cds-tab>
      <cds-tab label="Employment info" *ngIf="isStaff && (show | fn : [AccountTypeE.CA, AccountTypeE.SEP] : accountType)">
        <app-employment-info [id]="id" *ngIf="ifLoadTab | fn : activedTabList : 'employementInfo'"></app-employment-info>
      </cds-tab>
      <cds-tab label="Agent Info" *ngIf="isStaff && (show | fn : [AccountTypeE.PA, AccountTypeE.CA, AccountTypeE.TVC, AccountTypeE.FRC] : accountType)">
        <app-agent-info [id]="id" *ngIf="ifLoadTab | fn : activedTabList : 'agentInfo'"></app-agent-info>
      </cds-tab>
      <cds-tab label="Fund Info" *ngIf="isStaff || withMa">
        <app-fund-info [id]="id" *ngIf="ifLoadTab | fn : activedTabList : 'fundInfo'"></app-fund-info>
      </cds-tab>
      <cds-tab label="Contribution Records" *ngIf="isStaff && (show | fn : [AccountTypeE.CA, AccountTypeE.SEP] : accountType)">
        <app-contribution-records *ngIf="ifLoadTab | fn : activedTabList : 'contributionRecords'" [customerId]="id"></app-contribution-records>
      </cds-tab>
      <cds-tab label="Default Contribution" *ngIf="isStaff && (show | fn : [AccountTypeE.CA, AccountTypeE.SEP] : accountType)">
        <app-employee-default-contribution *ngIf="ifLoadTab | fn : activedTabList : 'defaultContribution'" [id]="id"></app-employee-default-contribution>
      </cds-tab>
      <cds-tab label="Transfer" *ngIf="isStaff && id">
        <app-policy-transfer [id]="id" *ngIf="ifLoadTab | fn : activedTabList : 'transfer'"></app-policy-transfer>
      </cds-tab>
      <cds-tab label="WithDrawal" *ngIf="isStaff && id">
        <app-policy-withdrawal [id]="id" *ngIf="ifLoadTab | fn : activedTabList : 'withDrawal'"></app-policy-withdrawal>
      </cds-tab>
      <cds-tab label="Termination" *ngIf="isStaff && id">
        <app-policy-termination [id]="id" *ngIf="ifLoadTab | fn : activedTabList : 'termination'"></app-policy-termination>
      </cds-tab>
      <cds-tab label="eMPF Workflow" *ngIf="isStaff">
        <app-empf-workflow [id]="id" *ngIf="ifLoadTab | fn : activedTabList : 'empfWorkflow'"></app-empf-workflow>
      </cds-tab>
    </cds-tab-group>
  </div>
</div>

<div class="l-mt-8">
  <app-back-top></app-back-top>
</div>
