import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { AdminHeaderConfig } from 'src/app/views/layout/admin-layout/admin-header/admin-header-config';
import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root',
})
export class LayoutHeaderService {
  private title = new BehaviorSubject<string>('');

  componentSource = new Subject<boolean>();
  isShowButton = new Subject<boolean>();
  isShowBackButton = new Subject<boolean>();
  backButtonText = new Subject<string>();
  buttonText = new Subject<string>();
  iconType = new Subject<string>();
  backUrl = '';
  detailText = new Subject<string>();
  private fun: () => void | null = () => {};
  private backfun: () => void | null = () => {};
  constructor(public router: Router) {}

  setTitle(title: string): void {
    this.title.next(title);
  }

  getTitle(): BehaviorSubject<string> {
    return this.title;
  }

  setHeaderButton(params: AdminHeaderConfig): void {
    if (params.rightButton?.method) {
      this.fun = params.rightButton.method;
    }
    if (params.backButton?.method) {
      this.backfun = params.backButton.method;
    }
    if (params.backButton?.url) {
      this.backUrl = params.backButton.url;
      this.isShowBackButton.next(true);
      this.backButtonText.next(params.backButton.text ?? '');
    }

    this.isShowButton.next(params.rightButton ? true : false);
    this.buttonText.next(params.rightButton?.text ?? '');
    this.iconType.next(params.rightButton?.icon?.type ?? '');
    this.title.next(params.title ?? '');
    this.detailText.next(params.titleDetail ?? '');
  }

  doHidenBackButton() {
    this.isShowBackButton.next(false);
  }

  doButtonMethod(): void {
    this.fun();
  }
  doBackButtonMethod(): void {
    if (this.backUrl !== '#') {
      this.router.navigate([this.backUrl]);
    } else {
      this.backfun();
    }
    //init
    this.isShowBackButton.next(false);
    this.detailText.next('');
    this.isShowButton.next(false);
  }
}
