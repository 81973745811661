<app-cd-page-title title="{{ 'commission.scale.list.title' | lang }}"> </app-cd-page-title>
<div class="row">
  <div class="col-xs-12 l-pt-7">
    <div class="cds-h2 cds-light l-mb-4">
      {{ 'common.searchRecord' | lang }}
    </div>

    <div class="l-mb-8">
      <div class="row l-plr-0" [formGroup]="formGroup">
        <div class="col-xs-12 col-md-6 col-lg-3 l-pb-5 l-plr-0 l-pr-20">
          <div class="cds-body2 cds-dark-navy-text light-3">
            {{ 'commission.scale.code.label' | lang }}
          </div>
          <input [formControl]="scaleCode" (input)="scaleCodeInput($event)" class="text-field" placeholder="{{ 'commission.scale.code.placeholder' | lang }}" />
        </div>
        <div class="col-xs-12 col-md-6 col-lg-3 l-pb-5 l-plr-0 l-pr-20">
          <app-autocomplete [group]="formGroup" controlName="scaleType" [control]="SCALE_TYPE_CONFIG" [selectOptions]="scaleTypeOpts" [locked]="false">
          </app-autocomplete>
          <cds-assistive-text *ngIf="formGroup.controls['scaleType'].errors?.['invalidSelect']">
            {{ 'common.select.invalid' | lang }}
          </cds-assistive-text>
        </div>
        <div class="col-xs-12 col-md-6 col-lg-3 l-pb-5 l-plr-0 l-pr-20">
          <app-autocomplete [group]="formGroup" controlName="scalePattern" [control]="SCALE_PATTERN_CONFIG" [selectOptions]="scalePatternOpts" [locked]="false">
          </app-autocomplete>
          <cds-assistive-text *ngIf="formGroup.controls['scalePattern'].errors?.['invalidSelect']">
            {{ 'common.select.invalid' | lang }}
          </cds-assistive-text>
        </div>
        <div class="col-xs-12 col-md-6 col-lg-3 l-pb-5 l-plr-0">
          <div class="cds-body2 cds-dark-navy-text light-3">
            {{ 'commission.scale.percentage.label' | lang }}
          </div>
          <div class="l-d-f">
            <input
              type="text"
              [formControl]="scalePercentageFrom"
              (input)="scalePercentageFromInput($event)"
              class="text-field"
              placeholder="{{ 'commission.scale.percentage.placeholder.from' | lang }}" />
            <span>%</span>
            <span class="l-mr-4 l-ml-4">-</span>
            <input
              type="text"
              [formControl]="scalePercentageTo"
              (input)="scalePercentageToInput($event)"
              class="text-field"
              placeholder="{{ 'commission.scale.percentage.placeholder.to' | lang }}" />
            <span>%</span>
          </div>
        </div>
        <div class="col-xs-12 col-md-6 col-lg-3 l-pb-5 l-plr-0">
          <div class="search-area-btn">
            <cds-button [disabled]="!isSearchEnable" [style]="'secondary'" size="md" (click)="search()">{{ 'common.search' | lang }} </cds-button>
            <cds-button [style]="'secondary'" size="md" (click)="reset()">{{ 'common.reset' | lang }} </cds-button>
          </div>
        </div>
      </div>
    </div>

    <div class="l-mb-5 l-d-f l-jc-sb">
      <div>
        <div class="cds-h2 cds-dark-navy-text l-ma-0 l-pb-2">
          {{ 'commission.scale.list.title.table' | lang }}
        </div>
        <div class="cds-body1 l-ma-0" *ngIf="!isLoading">
          <span>{{ 'common.total' | lang }} {{ totalElements | number : '1.0-0' }} {{ 'common.records' | lang }}</span>
        </div>
      </div>
      <div>
        <app-permission [role]="permissionAccess" [permission]="permissionItem">
          <cds-button [style]="'secondary'" size="md" routerLink="/commission/scale-info">{{ 'common.createRecord' | lang }}</cds-button>
        </app-permission>
      </div>
    </div>

    <div class="l-p-rel">
      <app-loading *ngIf="isLoading" [overlay]="true"></app-loading>
      <div>
        <app-no-result-found *ngIf="!isLoading && pageCount.count === 0; else dataTableTemplate"></app-no-result-found>
        <ng-template #dataTableTemplate>
          <cds-table class="cds-table full table-fixed" [dataSource]="dataSource">
            <ng-container cdsColumnDef="scaleCode">
              <cds-header-cell *cdsHeaderCellDef>
                <div class="l-d-f l-jc-sb l-ai-cen">
                  <div>{{ 'commission.scale.list.code' | lang }}</div>
                  <div>
                    <app-icon-button *ngIf="codeSortIcon === ''" icon="arrow:sort" (funCall)="onSortChange({ active: 'scaleCode', direction: 'asc' })">
                    </app-icon-button>
                    <app-icon-button *ngIf="codeSortIcon === 'asc'" icon="arrow:up" (funCall)="onSortChange({ active: 'scaleCode', direction: 'desc' })">
                    </app-icon-button>
                    <app-icon-button *ngIf="codeSortIcon === 'desc'" icon="arrow:down" (funCall)="onSortChange({ active: 'scaleCode', direction: '' })">
                    </app-icon-button>
                  </div>
                </div>
              </cds-header-cell>
              <cds-cell *cdsCellDef="let element">
                <span class="cds-body1-demibold text-underline cds-body1-demibold" (click)="dbClick(element.id)">
                  {{ element.scaleCode | uppercase }}
                </span>
              </cds-cell>
            </ng-container>
            <ng-container cdsColumnDef="scaleType">
              <cds-header-cell *cdsHeaderCellDef>
                <div class="l-d-f l-jc-sb l-ai-cen">
                  <div>{{ 'commission.scale.type.label' | lang }}</div>
                  <div>
                    <app-icon-button *ngIf="typeSortIcon === ''" icon="arrow:sort" (funCall)="onSortChange({ active: 'scaleType', direction: 'asc' })">
                    </app-icon-button>
                    <app-icon-button *ngIf="typeSortIcon === 'asc'" icon="arrow:up" (funCall)="onSortChange({ active: 'scaleType', direction: 'desc' })">
                    </app-icon-button>
                    <app-icon-button *ngIf="typeSortIcon === 'desc'" icon="arrow:down" (funCall)="onSortChange({ active: 'scaleType', direction: '' })">
                    </app-icon-button>
                  </div>
                </div>
              </cds-header-cell>
              <cds-cell *cdsCellDef="let element">
                <div class="text-overflow">
                  {{ element.scaleType | transfer2Label : scaleTypeOpts | lang }}
                </div>
              </cds-cell>
            </ng-container>
            <ng-container cdsColumnDef="scalePattern">
              <cds-header-cell *cdsHeaderCellDef>
                <div class="l-d-f l-jc-sb l-ai-cen">
                  <div>{{ 'commission.scale.pattern.label' | lang }}</div>
                  <div>
                    <app-icon-button *ngIf="patternSortIcon === ''" icon="arrow:sort" (funCall)="onSortChange({ active: 'scalePattern', direction: 'asc' })">
                    </app-icon-button>
                    <app-icon-button
                      *ngIf="patternSortIcon === 'asc'"
                      icon="arrow:up"
                      (funCall)="
                        onSortChange({
                          active: 'scalePattern',
                          direction: 'desc'
                        })
                      ">
                    </app-icon-button>
                    <app-icon-button *ngIf="patternSortIcon === 'desc'" icon="arrow:down" (funCall)="onSortChange({ active: 'scalePattern', direction: '' })">
                    </app-icon-button>
                  </div>
                </div>
              </cds-header-cell>
              <cds-cell *cdsCellDef="let element">
                <div class="text-overflow">
                  {{ element.scalePattern | transfer2Label : scalePatternOpts | lang }}
                </div>
              </cds-cell>
            </ng-container>
            <cds-header-row *cdsHeaderRowDef="displayedColumns"></cds-header-row>
            <cds-row *cdsRowDef="let row; columns: displayedColumns"></cds-row>
          </cds-table>
          <div class="l-pt-4 l-pb-4">
            <app-pagination [pageCount]="pageCount" (pageChange)="pageChange()" [useOf]="true"> </app-pagination>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</div>
