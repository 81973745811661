<div class="cd-carousel">
  <div style="width: 100%; overflow-x: hidden" (touchstart)="handleTouchStart($event)" (touchmove)="handleTouchMove($event)" (touchend)="handleTouchEnd()">
    <div #carouselBox class="carousel-box" [ngStyle]="{ width: list.length * 100 + '%' }">
      <ng-content></ng-content>
    </div>
  </div>

  <div class="handle">
    <div
      *ngFor="let announcement of list; let i = index"
      (click)="goSlide(i)"
      class="point"
      [ngClass]="{ selected: currentIndexSlides === i, 'l-ml-3': i > 0 }"></div>
  </div>
</div>
