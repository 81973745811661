import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-cd-carousel',
  templateUrl: './cd-carousel.component.html',
  styleUrls: ['./cd-carousel.component.scss'],
})
export class CdCarouselComponent implements OnInit, AfterViewInit {
  @Input() list: unknown[] = [];
  @ViewChild('carouselBox', { static: true }) carouselBox!: ElementRef<HTMLElement>;
  currentIndexSlides = 0;
  slidesTouchStartX = 0;
  slidesTouchEndX = 0;

  constructor() {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    // Auto slide
    setInterval(() => {
      this.nextSlide();
    }, 5000);
  }

  showSlide(index: number) {
    if (index < 0) {
      this.currentIndexSlides = this.list.length - 1;
    } else if (index >= this.list.length) {
      this.currentIndexSlides = 0;
    } else {
      this.currentIndexSlides = index;
    }

    const offset = -this.currentIndexSlides * (100 / this.list.length);
    this.carouselBox.nativeElement.style.transform = `translateX(${offset}%)`;
  }

  prevSlide() {
    this.showSlide(this.currentIndexSlides - 1);
  }

  nextSlide() {
    this.showSlide(this.currentIndexSlides + 1);
  }

  goSlide(index: number) {
    this.showSlide(index);
  }

  handleTouchStart(event: TouchEvent) {
    this.slidesTouchStartX = event.touches[0].clientX;
  }

  handleTouchMove(event: TouchEvent) {
    this.slidesTouchEndX = event.touches[0].clientX;
  }

  handleTouchEnd() {
    if (this.slidesTouchStartX - this.slidesTouchEndX > 50) {
      this.nextSlide();
    } else if (this.slidesTouchEndX - this.slidesTouchStartX > 50) {
      this.prevSlide();
    }
  }
}
