import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { CdsSearchModule } from '@cds/ng-web-components/search';
import { CdsFormFieldModule } from '@cds/ng-web-components/form-field';
import { CdsTextfieldModule } from '@cds/ng-web-components/textfield';
import { CdsAssistiveTextModule } from '@cds/ng-web-components/assistive-text';
import { CdsLanguageModule } from '@cds/ng-core/lang';
import { CdsDropdownModule } from '@cds/ng-web-components/dropdown';
import { CdsButtonModule } from '@cds/ng-web-components/button';
import { CdsLinkModule } from '@cds/ng-web-components/link';
import { SharedModule } from 'src/app/shared/shared.module';

import { RoleAssignUsersComponent } from './role-assign-users.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CdsSearchModule,
    CdsFormFieldModule,
    CdsTextfieldModule,
    CdsAssistiveTextModule,
    CdsLanguageModule,
    CdsDropdownModule,
    CdsButtonModule,
    CdsLinkModule,
    SharedModule,
  ],
  declarations: [RoleAssignUsersComponent],
  exports: [RoleAssignUsersComponent],
})
export class RoleAssignUsersModule {}
