import { Router } from '@angular/router';
/* eslint-disable @typescript-eslint/no-explicit-any */
import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { PermissionAccess, PermissionItem } from 'src/app/core/models/enum/permission.enum';
import { PermissionService } from 'src/app/core/services/permission.service';
import { WidgetComponent } from '../widget.component';
import { CdsPopupService } from '@cds/ng-web-components/popup';

@Component({
  selector: 'app-widgets-quick-links',
  templateUrl: './widgets-quick-links.component.html',
  styleUrls: ['./widgets-quick-links.component.scss'],
})
export class WidgetsQuickLinksComponent implements OnInit, AfterViewInit, OnDestroy, WidgetComponent {
  @Input() data: any;
  @ViewChild('quickLinks', { static: true }) quickLinks!: ElementRef;
  isShowUpdateABMList = false;
  isFixedCol3 = false;

  constructor(private permissionService: PermissionService, private router: Router, private cdsPopup: CdsPopupService) {}

  ngOnInit(): void {
    this.permissionService.hasPermission(PermissionAccess.W, PermissionItem.SALES_JOURNEY_SALES_SUPPORT).then(hasPermission => {
      this.isShowUpdateABMList = hasPermission;
    });
  }

  ngAfterViewInit(): void {
    this.resizeListener();
    window.addEventListener('resize', this.resizeListener);
  }

  private resizeListener = () => {
    this.isFixedCol3 = this.quickLinks.nativeElement.offsetWidth <= 392;
  };

  ngOnDestroy(): void {
    window.removeEventListener('resize', this.resizeListener);
  }

  onClickUploadABMList() {
    this.router.navigate(['/upload-center', { isOpenUploadABMList: true }]);
  }
}
