<div class="row">
  <div *ngIf="!!editItem" class="col-xs-12 col-md-7 l-pt-4">
    <div class="edit-datetime">
      <div class="cds-body2 l-ma-0">{{ 'common.record.time.create' | lang }}: {{ editItem.createTime | ssmsDateTime }}({{ 'common.HKT' | lang }})</div>
      <div class="split-line l-pl-4 l-pr-4">|</div>
      <div class="cds-body2 l-ma-0">{{ 'common.record.time.update' | lang }}: {{ editItem.lastUpdateTime | ssmsDateTime }}({{ 'common.HKT' | lang }})</div>
    </div>
  </div>
  <div class="cds-h2-light col-xs-12 col-md-12 l-pt-7">
    <span *ngIf="!isSubmitted">{{ 'commission.scale.record.details' | lang }}</span>
    <span *ngIf="isSubmitted">{{ 'commission.scale.record.review' | lang }}</span>
  </div>
  <div *ngIf="!editItem" class="cds-body2 col-xs-12 col-md-12 l-mb-0" [class.l-pb-7]="isSubmitted">
    <span *ngIf="!isSubmitted">{{ 'commission.scale.record.subtitle.create' | lang }}</span>
    <span *ngIf="isSubmitted">{{ 'commission.scale.record.subtitle.review' | lang }}</span>
  </div>
  <div *ngIf="!isSubmitted && !editItem" class="col-xs-12 col-md-12 cds-body2 font-error l-pb-3">* {{ 'commission.scale.record.create.tips' | lang }}</div>
</div>

<!-- commission scale record detail -->
<app-commission-record-detail
  #commissionRecordDetail
  [isEditPage]="isEditPage"
  [isSubmitted]="isSubmitted"
  [commissionItem]="commissionItem"
  [editItem]="editItem"
  [dataSource]="dataSource"
  (commissionItemChange)="checkSaveChangesEnable()">
</app-commission-record-detail>

<div class="row">
  <div class="cds-h2-light col-xs-12 col-md-12 l-pt-4">
    {{ 'commission.scale.breakpoint.details' | lang }}
  </div>
  <div class="cds-body2 col-xs-12 col-md-12" *ngIf="!isSubmitted && !isEditPage">
    {{ 'commission.scale.breakpoint.subtitle' | lang }}
  </div>
</div>
<!-- breakpoint details -->
<app-commission-breakpoint
  #breakpoint
  [isEditPage]="isEditPage"
  [isSubmitted]="isSubmitted"
  [commissionItem]="commissionItem"
  [dataSource]="dataSource"
  (dataSourceChange)="checkSaveChangesEnable()">
</app-commission-breakpoint>

<div class="row">
  <div class="col-xs-12 col-md-2 l-pt-6" *ngIf="!isSubmitted && !editItem">
    <cds-button [fullWidth]="true" (click)="submit()" [disabled]="!(commissionRecordDetail.recordDetailEnable && breakpoint.breakpointEnable)">
      {{ 'common.submit' | lang }}
    </cds-button>
  </div>
  <div class="col-xs-12 col-md-2 l-pt-6" *ngIf="!isSubmitted && !!editItem && !isEditPage">
    <cds-button class="button-padding" [fullWidth]="true" (click)="submit()" [disabled]="!isSaveChangesEnable">
      {{ 'common.saveChanges' | lang }}
    </cds-button>
  </div>
  <div class="col-xs-12 col-md-12">
    <br />
  </div>
  <div class="col-xs-12 col-md-2 l-pt-3" *ngIf="isSubmitted">
    <cds-button [fullWidth]="true" (click)="confirm()">
      {{ 'common.confirm' | lang }}
    </cds-button>
  </div>
  <div class="col-xs-12 col-md-2 l-pt-3" *ngIf="isSubmitted">
    <cds-button class="button-padding" [fullWidth]="true" [config]="btnCfg" (click)="backToEdit()">
      {{ 'common.backToEdit' | lang }}
    </cds-button>
  </div>
</div>
