<app-loading *ngIf="isLoading" [overlay]="true"></app-loading>
<form [formGroup]="form">
  <div class="row l-pt-5">
    <div class="col-xs-12">
      <div class="l-mb-2 l-mt-4 inner-html-des" innerHTML="{{ 'notification.create.htmlDes' | lang }}"></div>
    </div>
  </div>

  <div class="col-xs-12 col-sm-12 col-md-10 col-lg-10">
    <div class="row mycontent">
      <div class="col-xs-12 l-plr-0">
        <p class="cds-h2-light">{{ 'create.boradcast' | lang }}</p>
      </div>
      <div *ngIf="showDatePicker" class="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-3 l-mb-4 l-pl-0">
        <cds-textfield
          #dateLimit
          formControlName="dateLimit"
          [(ngModel)]="summaryDTO.targetBroadcastDate"
          (ngModelChange)="validating()"
          (change)="setTimeConfig(dateLimit.value)"
          placeholder="DD/MM/YYYY"
          label="Target Broadcast Date"
          [min]="jsDate"
          [cdsDatepicker]="picker">
        </cds-textfield>
        <cds-datepicker #picker="cdsDatepicker"></cds-datepicker>
        <cds-assistive-text class="margin-top-1" *ngIf="form.get('dateLimit')?.invalid">{{ 'create.dateError' | lang }} </cds-assistive-text>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-3 l-mb-4 l-pl-0">
        <cds-dropdown
          class="xs-margin-bottom"
          [(ngModel)]="summaryDTO.targetBroadcastTime"
          (ngModelChange)="validating()"
          [ngModelOptions]="{ standalone: true }"
          [config]="targetBroadcastTimeConfig"></cds-dropdown>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-3 l-mb-4 l-pl-0">
        <cds-dropdown
          class="xs-margin-bottom"
          [(ngModel)]="summaryDTO.category"
          (ngModelChange)="validating()"
          [ngModelOptions]="{ standalone: true }"
          (change)="categoryChange($event)"
          [config]="categoryConfig"></cds-dropdown>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-3 l-mb-4 l-pl-0">
        <cds-dropdown [(ngModel)]="summaryDTO.priority" (ngModelChange)="validating()" [ngModelOptions]="{ standalone: true }" [config]="priorityConfig">
        </cds-dropdown>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-3 l-mb-4 l-pl-0">
        <cds-dropdown
          [(ngModel)]="summaryDTO.audienceListName"
          (ngModelChange)="validating()"
          [ngModelOptions]="{ standalone: true }"
          [config]="audienceListConfig">
        </cds-dropdown>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-3 l-mb-4 l-pl-0">
        <cds-textfield
          #pushCampaignId
          formControlName="pushCampaignId"
          [(ngModel)]="summaryDTO.pushCampaignId"
          (ngModelChange)="validating()"
          (change)="campaignIdChange(pushCampaignId.value)"
          placeholder="Input Push Campaign ID"
          label="Push Campaign ID">
        </cds-textfield>
        <cds-assistive-text class="margin-top-1" *ngIf="form.get('pushCampaignId')?.invalid">Exceeds character limit </cds-assistive-text>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-xs-12">
      <p class="cds-h2-light l-mt-4">{{ 'create.messageDetails' | lang }}</p>
    </div>
    <div class="col-xs-12">
      <p class="cds-h5-demibold">{{ 'create.versionTitle.en' | lang }}</p>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-10 col-lg-10">
      <cds-textarea
        [minRows]="1"
        [maxRows]="1"
        [maxlength]="100"
        formControlName="subjectEn"
        [(ngModel)]="summaryDTO.subjectEn"
        (ngModelChange)="validating()"
        label="{{ 'create.subject.en' | lang }}"
        placeholder="{{ 'create.subject.placeholder.en' | lang }}">
      </cds-textarea>
      <app-cd-form-assistive [form]="form" key="subjectEn"></app-cd-form-assistive>

      <cds-textarea
        [minRows]="3"
        [maxRows]="3"
        [maxlength]="300"
        formControlName="bodyEn"
        [(ngModel)]="summaryDTO.messageBodyEn"
        (ngModelChange)="validating()"
        class="input-margin"
        label="{{ 'create.message.body.en' | lang }}"
        placeholder="{{ 'create.message.placeholder.en' | lang }}">
      </cds-textarea>
      <app-cd-form-assistive [form]="form" key="bodyEn"></app-cd-form-assistive>

      <!-- <cds-textfield
        class="l-pb-2"
        formControlName="urlLimitEn"
        [(ngModel)]="summaryDTO.deepLinkUrl"
        (ngModelChange)="validating()"
        label="{{ 'create.link.en' | lang }}"
        placeholder="{{ 'create.link.placeholder.en' | lang }}"
        type="text">
      </cds-textfield> -->
      <app-cd-form-assistive [form]="form" key="urlLimitEn"></app-cd-form-assistive>
    </div>
  </div>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-10 col-lg-10">
      <div class="p-line"></div>
    </div>
    <div class="col-xs-12">
      <p class="cds-h5-demibold">{{ 'create.versionTitle.zh' | lang }}</p>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-10 col-lg-10">
      <cds-textarea
        [minRows]="1"
        [maxRows]="1"
        [maxlength]="50"
        formControlName="subjectZh"
        [(ngModel)]="summaryDTO.subjectCn"
        (ngModelChange)="validating()"
        placeholder="{{ 'create.subject.placeholder.zh' | lang }}"
        label="{{ 'create.subject.label.zh' | lang }}"></cds-textarea>
      <app-cd-form-assistive [form]="form" key="subjectZh"></app-cd-form-assistive>

      <cds-textarea
        [minRows]="3"
        [maxRows]="3"
        [maxlength]="150"
        formControlName="bodyZh"
        [(ngModel)]="summaryDTO.messageBodyCn"
        (ngModelChange)="validating()"
        class="input-margin"
        placeholder="{{ 'create.subject.zh' | lang }}"
        label="{{ 'create.message.label.zh' | lang }}"
        [minRows]="0"
        type="text"></cds-textarea>
      <app-cd-form-assistive [form]="form" key="bodyZh"></app-cd-form-assistive>
    </div>
  </div>

  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-10 col-lg-10">
      <div class="p-line"></div>
    </div>

    <div class="col-xs-12">
      <p class="cds-h5-demibold">{{ 'notification.deepLink' | lang }}</p>
    </div>

    <div class="col-xs-12 col-sm-12 col-md-10 col-lg-10">
      <cds-textfield
        class="l-pb-2"
        formControlName="urlLimitZh"
        [(ngModel)]="summaryDTO.deepLinkUrl"
        (ngModelChange)="validating()"
        label="{{ 'create.link.zh' | lang }}"
        placeholder="{{ 'create.link.placeholder.zh' | lang }}"
        type="text">
      </cds-textfield>
      <app-cd-form-assistive [form]="form" key="urlLimitZh"></app-cd-form-assistive>
    </div>
  </div>

  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-2 margin-sbumit botton-padding">
      <cds-button (click)="reset()" [fullWidth]="true" [config]="resetConfig" label="{{ 'create.botton.reset' | lang }}"></cds-button>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-2 margin-sbumit botton-padding">
      <cds-button (click)="submit()" [fullWidth]="true" [config]="confirmConfig" label="{{ 'create.botton.submit' | lang }}"> </cds-button>
    </div>
  </div>
</form>
