import { AfterContentInit, AfterViewInit, Component, ContentChildren, OnInit, QueryList, Input } from '@angular/core';

import { CdStepComponent } from './cd-step/cd-step.component';

@Component({
  selector: 'app-cd-stepper',
  templateUrl: './cd-stepper.component.html',
  styleUrls: ['./cd-stepper.component.scss'],
})
export class CdStepperComponent implements OnInit, AfterViewInit, AfterContentInit {
  @ContentChildren(CdStepComponent) stepList!: QueryList<CdStepComponent>;
  @Input() orientation: 'vertical' | 'horizontal' = 'horizontal';
  @Input() isFreeStyle = false;

  private lightsOutStepList: number[] = [];

  private currentFinishStep = 0;

  constructor() {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {}

  ngAfterContentInit() {
    this.stepList.forEach((step, index) => {
      step.setLabel(step.label || String(index + 1));
    });
  }

  setStepList(stepList: QueryList<CdStepComponent>) {
    this.stepList = stepList;
    this.ngAfterContentInit();
  }

  getFinishStep(): number {
    return this.currentFinishStep;
  }

  setFinishStep(index: number) {
    this.currentFinishStep = index;
    this.refreshStatus();
  }

  setLightsOutStep(index: number) {
    this.lightsOutStepList.push(index);
    this.lightsOutStepList = Array.from(new Set(this.lightsOutStepList));
    this.refreshStatus();
  }

  removeLightsOutStep(index: number) {
    this.lightsOutStepList = this.lightsOutStepList.filter(num => num !== index);
    this.refreshStatus();
  }

  clear() {
    this.setFinishStep(0);
    this.lightsOutStepList.length = 0;
  }

  next() {
    if (this.currentFinishStep < this.stepList.length) {
      this.currentFinishStep++;
    }

    this.refreshStatus();
  }

  last() {
    if (this.currentFinishStep > 0) {
      this.currentFinishStep--;
    }

    this.refreshStatus();
  }

  private refreshStatus() {
    for (let i = 0; i < this.currentFinishStep; i++) {
      if (!this.lightsOutStepList.includes(i - 1)) {
        this.stepList.get(i)?.setFinish();
      }
    }

    this.lightsOutStepList.forEach(element => {
      this.stepList.get(element - 1)?.setUnfinished();
    });

    this.stepList
      .toArray()
      .slice(this.currentFinishStep)
      .forEach(element => {
        element.setUnfinished();
      });
  }
}
