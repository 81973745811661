<cds-popup class="popup no-close-icon">
  <cds-popup-title>
    <div class="col-xs-12 cds-h2-light" *ngIf="inEdit">Add Records</div>
    <div class="col-xs-12" *ngIf="!inEdit">
      <div class="cds-h2-light l-mb-2">Records Summary</div>
      <div class="cds-body1">Please check to confirm and add the record(s).</div>
    </div>
    <div class="close-icon" (click)="close()">
      <cds-icon icon="action:cross"></cds-icon>
    </div>
  </cds-popup-title>

  <cds-popup-content>
    <div class="relative" [formGroup]="form">
      <app-loading *ngIf="isLoading" [overlayInPopup]="true"></app-loading>
      <table formArrayName="tableArray" class="cds-table full text-left cds-body1">
        <thead>
          <tr class="div-bg-light-grey cds-header-row">
            <th style="width: 5%" class="l-pa-4 cds-demibold"></th>
            <th style="width: 25%" class="l-pa-4 cds-demibold">SEP Commencement Date</th>
            <th style="width: 35%" class="l-pa-4 cds-demibold">
              <div>HKID/ Passport</div>
              <div class="cds-body2 l-mb-0">Please include bracket ( ) when typing HKID</div>
            </th>
            <th style="width: 25%" class="l-pa-4 cds-demibold">Date of Birth</th>
            <th style="width: 5%" class="l-pa-4 cds-demibold" *ngIf="inEdit"></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let formGroup of tableArray?.controls; let index = index" class="cds-row" [formGroupName]="index.toString()" class="">
            <td class="l-pa-4">
              <div class="l-d-f">
                {{ index + 1 }}
              </div>
            </td>
            <td class="l-pa-4 vertical-align-top">
              <div class="l-d-f">
                <cds-form-field>
                  <div *ngIf="!inEdit">
                    <div>
                      {{ getControlValue | fn : 'sepCommDate' : index }}
                    </div>
                  </div>

                  <div *ngIf="inEdit">
                    <cds-textfield
                      formControlName="sepCommDate"
                      placeholder="DD/MM/YYYY"
                      label=""
                      (cdsChange)="onControlChange('sepCommDate', index)"
                      [cdsDatepicker]="sepCommDatePicker"
                      [max]="getMaxFromDate | fn : maxTimeInStr">
                    </cds-textfield>
                    <cds-datepicker #sepCommDatePicker="cdsDatepicker"></cds-datepicker>

                    <div
                      *ngIf="
                        getControl('sepCommDate', index)?.invalid && (getControl('sepCommDate', index)?.dirty || getControl('sepCommDate', index)?.touched)
                      ">
                      <cds-assistive-text *ngIf="getControl('sepCommDate', index)?.errors?.['required']" class="l-pt-2" [showIcon]="false"
                        >Missing SEP Commencement Date</cds-assistive-text
                      >
                      <cds-assistive-text
                        *ngIf="getControl('sepCommDate', index)?.errors?.['dateInvalid'] || getControl('sepCommDate', index)?.errors?.['startLaterThanEnd']"
                        [showIcon]="false"
                        >Please input SEP Commencement Date with format DD/MM/YYYY</cds-assistive-text
                      >

                      <cds-assistive-text
                        *ngIf="getControl('sepCommDate', index)?.errors?.['backendInvalid']"
                        [showIcon]="false"
                        >{{ getControl('sepCommDate', index)?.errors?.['backendInvalid'] }}</cds-assistive-text
                      >
                    </div>

                    <div *ngIf="getWarnMessage('sepCommDate', index)" class="warn-valid-message">
                      <cds-assistive-text [showIcon]="false">{{ getWarnMessage('sepCommDate', index) }}</cds-assistive-text>
                    </div>
                  </div>
                </cds-form-field>
              </div>
            </td>
            <td class="l-pa-4 vertical-align-top">
              <div class="l-d-f">
                <cds-form-field>
                  <div *ngIf="!inEdit">
                    <div>
                      {{ getUserIdStr | fn : index }}
                    </div>
                  </div>

                  <div [class.hidden]="!inEdit">
                    <div class="id-input">
                      <div class="id-input-type">
                        <cds-dropdown
                          label=""
                          (change)="onControlChange('userId', index)"
                          (cdsChange)="dropdownChange($event, index)"
                          formControlName="userIdType"
                          [config]="agentSepUserIdTypeConfig"></cds-dropdown>
                      </div>
                      <div class="id-input-text">
                        <cds-textfield
                          label=""
                          formControlName="userId"
                          (change)="onControlChange('userId', index)"
                          (cdsChange)="userIdChange($event, getControl('userId', index))"
                          [placeholder]="getControl('userIdType', index)?.value === 'hkid' ? 'A000000(0)' : 'Input Passport'">
                        </cds-textfield>
                      </div>
                    </div>
                    <div *ngIf="getControl('userId', index)?.invalid && (getControl('userId', index)?.dirty || getControl('userId', index)?.touched)">
                      <cds-assistive-text *ngIf="getControl('userId', index)?.errors?.['required']" class="l-pt-2" [showIcon]="false"
                        >Missing HKID/ Passport</cds-assistive-text
                      >
                      <cds-assistive-text *ngIf="getControl('userId', index)?.errors?.['maxlength']" class="l-pt-2" [showIcon]="false"
                        >Max length is 80.</cds-assistive-text
                      >
                      <cds-assistive-text
                        *ngIf="getControl('userId', index)?.errors?.['hkidInvalid']"
                        [showIcon]="false"
                        >{{ getControl('userId', index)?.errors?.['hkidInvalid'] }}</cds-assistive-text
                      >
                      <cds-assistive-text
                        *ngIf="getControl('userId', index)?.errors?.['passportInvalid']"
                        [showIcon]="false"
                        >{{ getControl('userId', index)?.errors?.['passportInvalid'] }}</cds-assistive-text
                      >
                      <cds-assistive-text
                        *ngIf="getControl('userId', index)?.errors?.['backendInvalid']"
                        [showIcon]="false"
                        >{{ getControl('userId', index)?.errors?.['backendInvalid'] }}</cds-assistive-text
                      >
                    </div>

                    <div *ngIf="getWarnMessage('userId', index)" class="warn-valid-message">
                      <cds-assistive-text [showIcon]="false">{{ getWarnMessage('userId', index) }}</cds-assistive-text>
                    </div>
                  </div>
                </cds-form-field>
              </div>
            </td>
            <td class="l-pa-4 vertical-align-top">
              <div class="l-d-f">
                <cds-form-field>
                  <div *ngIf="!inEdit">
                    <div>
                      {{ getControlValue | fn : 'dob' : index }}
                    </div>
                  </div>

                  <div *ngIf="inEdit">
                    <cds-textfield
                      formControlName="dob"
                      placeholder="DD/MM/YYYY"
                      label=""
                      (cdsChange)="onControlChange('dob', index)"
                      [cdsDatepicker]="dobPicker"
                      [max]="getMaxFromDate | fn : maxTimeInStr">
                    </cds-textfield>
                    <cds-datepicker #dobPicker="cdsDatepicker"></cds-datepicker>

                    <div *ngIf="getControl('dob', index)?.invalid && (getControl('dob', index)?.dirty || getControl('dob', index)?.touched)">
                      <cds-assistive-text *ngIf="getControl('dob', index)?.errors?.['required']" class="l-pt-2" [showIcon]="false"
                        >Missing Date of Birth</cds-assistive-text
                      >

                      <cds-assistive-text
                        *ngIf="getControl('dob', index)?.errors?.['dateInvalid'] || getControl('dob', index)?.errors?.['startLaterThanEnd']"
                        [showIcon]="false"
                        >Please input Date of Birth with format DD/MM/YYYY</cds-assistive-text
                      >

                      <cds-assistive-text
                        *ngIf="getControl('dob', index)?.errors?.['backendInvalid']"
                        [showIcon]="false"
                        >{{ getControl('dob', index)?.errors?.['backendInvalid'] }}</cds-assistive-text
                      >
                    </div>

                    <div *ngIf="getWarnMessage('dob', index)" class="warn-valid-message">
                      <cds-assistive-text [showIcon]="false">{{ getWarnMessage('dob', index) }}</cds-assistive-text>
                    </div>
                  </div>
                </cds-form-field>
              </div>
            </td>
            <td class="l-pa-4" *ngIf="inEdit" class="delete-cell">
              <cds-icon class="delete-icon l-pl-3" icon="form:delete" (click)="delete(index)"></cds-icon>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="row add-button-container" *ngIf="inEdit">
      <div *ngIf="!continueDisabled" class="add-button-area" (click)="add()">
        <div class="div-icon">+</div>
        <span class="l-ml-2">Add New</span>
      </div>
      <div *ngIf="continueDisabled" class="add-button-area-disabled">
        <div class="div-icon">+</div>
        <span class="l-ml-2">Add New</span>
      </div>
    </div>
  </cds-popup-content>

  <cds-popup-actions>
    <div class="col-xs-12" *ngIf="showButton">
      <ng-container *ngIf="inEdit">
        <cds-button [config]="resetButtonConfig" [disabled]="resetDisabled" (click)="reset()">{{ 'common.reset' | lang }}</cds-button>

        <cds-button [config]="saveButtonConfig" [disabled]="continueDisabled" (click)="continue()">{{ 'common.continue' | lang }}</cds-button>
      </ng-container>

      <ng-container *ngIf="!inEdit">
        <cds-button [config]="resetButtonConfig" (click)="backToEdit()">{{ 'common.backToEdit' | lang }}</cds-button>

        <cds-button [config]="saveButtonConfig" (click)="confirm()" [disabled]="!checkOk">{{ 'employer.confirm-and-add' | lang }}</cds-button>
      </ng-container>
    </div>
  </cds-popup-actions>
</cds-popup>
