/* eslint-disable @typescript-eslint/no-explicit-any */

export enum SubActivity {
  CONTR_REGULAR = 'CONTR_REGULAR',
  CONTR_LUMPSUM = 'CONTR_LUMPSUM',
  CONTR_SURCHG = 'CONTR_SURCHG',
  CONTR_SUBSEQUENT = 'CONTR_SUBSEQUENT',
  TRF_IN_PP = 'TRF_IN_PP',
  TRF_IN_PM = 'TRF_IN_PM',
  TRF_IN_PC = 'TRF_IN_PC',
  TRF_IN_PT = 'TRF_IN_PT',
  TRF_IN_PE = 'TRF_IN_PE',
  TRF_IN_MMB = 'TRF_IN_MMB',
  TRF_IN_INTRAGROUP = 'TRF_IN_INTRAGROUP',
  TRF_IN_MMB_PE = 'TRF_IN_MMB_PE',
  TRF_IN_MMB_INTRA_GRP = 'TRF_IN_MMB_INTRA_GRP',
}

export const SubActivityOpts: any[] = [
  { label: 'CONTR_LUMPSUM', value: SubActivity.CONTR_LUMPSUM },
  { label: 'CONTR_REGULAR', value: SubActivity.CONTR_REGULAR },
  { label: 'CONTR_SUBSEQUENT', value: SubActivity.CONTR_SUBSEQUENT },
  { label: 'CONTR_SURCHG', value: SubActivity.CONTR_SURCHG },
  { label: 'TRF_IN_PE', value: SubActivity.TRF_IN_PE },
  { label: 'TRF_IN_PM', value: SubActivity.TRF_IN_PM },
  { label: 'TRF_IN_PC', value: SubActivity.TRF_IN_PC },
  { label: 'TRF_IN_PP', value: SubActivity.TRF_IN_PP },
  { label: 'TRF_IN_PT', value: SubActivity.TRF_IN_PT },
  { label: 'TRF_IN_INTRAGROUP', value: SubActivity.TRF_IN_INTRAGROUP },
  { label: 'TRF_IN_MMB', value: SubActivity.TRF_IN_MMB },
  { label: 'TRF_IN_MMB_PE', value: SubActivity.TRF_IN_MMB_PE },
  { label: 'TRF_IN_MMB_INTRA_GRP', value: SubActivity.TRF_IN_MMB_INTRA_GRP },
];
