import { AclUserResponse } from './../models/permission';
/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { PermissionAccess, PermissionItem } from '../models/enum/permission.enum';
import { IPageableRequest } from '../models/request';
import { BasicResponse, PageResponse } from '../models/response/response';
import { IAclUserRequest, IRole, IRoleInfo, IRoleUserAssign } from '../models/role';

const filterParams = (iObj?: any): HttpParams => {
  if (iObj) {
    Object.keys(iObj).forEach(key => iObj[key] === undefined && delete iObj[key]);
    return new HttpParams({
      fromObject: iObj as any,
    });
  }
  return new HttpParams();
};

@Injectable({
  providedIn: 'root',
})
export class RoleService {
  constructor(private http: HttpClient) {}

  private getApiPath(...strings: string[]) {
    return environment.apiPrefix.concat(environment.identityServiceExt).concat(...strings);
  }
  private getRoleApiPath(iStr: string) {
    return this.getApiPath('/role', iStr);
  }
  private getRoleUserApiPath(iStr: string) {
    return this.getApiPath('/user', iStr);
  }

  findAll(params: IPageableRequest): Observable<PageResponse<IRole>> {
    return this.http.get<PageResponse<any>>(this.getRoleApiPath('/list'), {
      params: filterParams(params),
    });
  }

  deleteById(roleId: string): Observable<BasicResponse<any>> {
    return this.http.delete<BasicResponse<any>>(this.getRoleApiPath(`/delete/${roleId}`));
  }

  getById(roleId: string): Observable<BasicResponse<IRoleInfo>> {
    return this.http.get<BasicResponse<IRoleInfo>>(this.getRoleApiPath(`/query/${roleId}`)).pipe(
      map(res => {
        if (res.result === 0 && !!res.data?.permissions) {
          const resMap = new Map<PermissionItem, PermissionAccess>();
          for (const [key, value] of Object.entries(res.data.permissions)) {
            const access = PermissionAccess[`${value}`.toUpperCase() as keyof typeof PermissionAccess];
            const permission = PermissionItem[`${key}`.toUpperCase() as keyof typeof PermissionItem];
            if (!!access && !!permission) {
              resMap.set(permission, access);
            }
          }
          res.data.permissions = resMap;
        }
        return res;
      })
    );
  }

  saveOrUpdate(params: IRoleInfo): Observable<BasicResponse<IRoleInfo>> {
    const postData: any = params;
    if (postData.permissions) {
      postData.permissions = Object.fromEntries(params.permissions);
    }
    return this.http.post<BasicResponse<IRoleInfo>>(this.getRoleApiPath(params.roleId ? '/update' : '/create'), postData);
  }

  searchAclUser(aclUserRequest: IAclUserRequest): Observable<PageResponse<AclUserResponse>> {
    return this.http.get<PageResponse<AclUserResponse>>(this.getRoleUserApiPath('/search-all'), {
      params: new HttpParams({ fromObject: aclUserRequest as any }),
    });
  }

  addUserToRole(iRoleUserAssign: IRoleUserAssign): Observable<PageResponse<AclUserResponse>> {
    return this.http.post<PageResponse<AclUserResponse>>(this.getRoleUserApiPath('/assign'), iRoleUserAssign);
  }

  getIdentityByRoleId(iRoleId: string, iPageableRequest: IPageableRequest): Observable<PageResponse<any>> {
    return this.http.get<PageResponse<AclUserResponse>>(this.getRoleUserApiPath(`/query/${iRoleId}`), {
      params: new HttpParams({ fromObject: iPageableRequest as any }),
    });
  }

  deleteByRoleUser(userRoleId: string): Observable<BasicResponse<any>> {
    return this.http.delete<BasicResponse<any>>(this.getRoleUserApiPath(`/delete/${userRoleId}`));
  }
}
