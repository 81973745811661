<div>
  <div class="cds-body2 cds-dropdown-label ng-star-inserted label-color">
    {{ label | lang }}
    <span class="font-error" *ngIf="!nullable && !locked">*</span>
  </div>
  <div *ngIf="locked; else select">
    {{ field | lang }}
  </div>
  <ng-template #select>
    <mat-select
      class="text-field my-placeholder"
      panelClass="fixed-panel"
      [class.color-black]="!!field"
      placeholder="{{ placeholder | lang }}"
      [ngModel]="field"
      (ngModelChange)="change($event)">
      <mat-select-trigger *ngIf="!!field">
        <div class="l-d-f l-jc-sb">
          {{ field | lang }}
          <app-icon-button icon="action:button_x_filled" [iconCfg]="iconCfg" (funCall)="clearSelection()" [stopFunCallPropagation]="true"></app-icon-button>
        </div>
      </mat-select-trigger>
      <mat-option class="div-border-top div-border-bottom option" *ngFor="let option of options" [value]="option.value"> {{ option.label | lang }}</mat-option>
    </mat-select>
  </ng-template>
</div>
