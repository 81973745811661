import { Component } from '@angular/core';

import { MatDialogRef } from '@cds/ng-web-components/popup';

@Component({
  selector: 'app-add-to-item',
  templateUrl: './add-to-item.component.html',
  styleUrls: ['./add-to-item.component.scss'],
})
export class AddToItemComponent {
  text = '';

  constructor(private dialogRef: MatDialogRef<AddToItemComponent>) {}

  continue() {
    if (this.disabledContinue) {
      return;
    }

    this.dialogRef.close(this.text);
  }

  cancel() {
    this.dialogRef.close();
  }

  get disabledContinue() {
    return this.text.length === 0;
  }
}
