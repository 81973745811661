import { LanguageChangeService } from 'src/app/shared/language-change.service';
import { BranchAgentResponse } from './agent-branch-pop/branch-agent-response';
import { agentCodeAddPrefix0 } from 'src/app/utils/utils';

type ValiRespErrDataErrorCode = 2101 | 2102 | 2103 | 2104 | 21018 | 21017 | 21016 | 21015 | 21021 | 21020 | 21019;

export type ValiRespErrData =
  | ValiRespErrData2103
  | ValiRespErrData2104
  | ValiRespErrData2101
  | ValiRespErrData2102
  | ValiRespErrData21018
  | ValiRespErrData21017
  | ValiRespErrData21016
  | ValiRespErrData21015
  | ValiRespErrData21021
  | ValiRespErrData21020
  | ValiRespErrData21019;

interface ValiRespErrDataBase {
  errorCode: ValiRespErrDataErrorCode;
  employerInfo: {
    employerId: string;
    employerName: string;
  };
  agent: {
    agentCode: string;
    name: string;
    branchCode: string;
  };
}

interface ValiRespErrData2103 extends ValiRespErrDataBase {
  errorCode: 2103;
  targetAgent: BranchAgentResponse;
}

interface ValiRespErrData2104 extends ValiRespErrDataBase {
  errorCode: 2104;
  existAgents: {
    agentCode: string;
    name: string;
    chineseName: string;
  }[];
  limitAgent: number;
}

interface ValiRespErrData2101 {
  errorCode: 2101;
  message: 'valiRespErrData2101';
}
interface ValiRespErrData2102 {
  errorCode: 2102;
  message: 'ValiRespErrData2102';
}
interface ValiRespErrData21018 {
  errorCode: 21018;
  message: 'valiRespErrData21018';
}
interface ValiRespErrData21017 {
  errorCode: 21017;
  message: 'valiRespErrData21017';
}
interface ValiRespErrData21016 {
  errorCode: 21016;
  message: 'valiRespErrData21016';
}
interface ValiRespErrData21015 {
  errorCode: 21015;
  message: 'valiRespErrData21015';
}
interface ValiRespErrData21021 {
  errorCode: 21021;
  message: 'valiRespErrData21021';
}
interface ValiRespErrData21020 {
  errorCode: 21020;
  message: 'valiRespErrData21020';
}
interface ValiRespErrData21019 {
  errorCode: 21019;
  message: 'valiRespErrData21019';
}

const errorMessageMap = {
  21019: 'valiRespErrData21019',
  21020: 'valiRespErrData21020',
  21021: 'valiRespErrData21021',
  21015: 'valiRespErrData21015',
  21016: 'valiRespErrData21016',
  21017: 'valiRespErrData21017',
  21018: 'valiRespErrData21018',
  2102: 'ValiRespErrData2102',
  2101: 'valiRespErrData2101',
};

export function updateDelegationEmpValiRespErrHandle(languageChangeService: LanguageChangeService, dataList: ValiRespErrData[]) {
  const errInfo2103: BranchAgentResponse[] = [];
  const errInfo2104: string[] = [];
  const errInfoOther: string[] = [];

  dataList.forEach(data => {
    let errInfo;
    switch (data.errorCode) {
      case 2104:
        errInfo = `${data.employerInfo.employerName} ${languageChangeService.lang.translate('delegationChange.delegatedAgentMoreThanFour2')} ${
          data.limitAgent
        } ${languageChangeService.lang.translate('delegationChange.delegatedAgentMoreThanFour3')} ${data.existAgents
          .map(item => `${agentCodeAddPrefix0(item.agentCode)} ${languageChangeService.lang.currentLang === 'en' ? item.name : item.chineseName || item.name}`)
          .join(', ')}`;
        errInfo2104.push(errInfo);
        break;

      case 2103:
        if (data.targetAgent?.agentCode) {
          data.targetAgent.agentCode = agentCodeAddPrefix0(data.targetAgent.agentCode);
        }
        errInfo2103.push({
          agentCode: data.targetAgent.agentCode,
          branchCode: data.targetAgent.branchCode,
          chineseName: data.targetAgent.chineseName,
          name: data.targetAgent.name,
        });
        break;

      default:
        errInfoOther.push(languageChangeService.lang.translate(errorMessageMap[data.errorCode]));
        break;
    }
  });

  if (errInfo2103.length > 0) {
    return {
      title: 'branch.text',
      messages: errInfo2103,
      errorCode: 2103,
    };
  } else if (errInfo2104.length > 0) {
    return {
      title: 'delegationChange.delegatedAgentMoreThanFour',
      messages: errInfo2104,
      errorCode: 2104,
    };
  } else {
    return {
      title: '',
      messages: errInfoOther,
    };
  }
}
