import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';

import { Observable, map, throwError, catchError } from 'rxjs';
import { CdsLangService } from '@cds/ng-core/lang';
import { CdsAlertService } from '@cds/ng-web-components/alert';

import { CdHttpServeService } from 'src/app/shared/cd-http-serve/cd-http-serve.service';
import { SplitAgentNotificationInfoBody } from './split-agent-notification-info-resolve.model';

interface ResolveResult {
  body: SplitAgentNotificationInfoBody;
  summaryId: string;
  iffId: string;
  iffVersionNo: string;
}

@Injectable({
  providedIn: 'root',
})
export class SplitAgentNotificationInfoResolveService implements Resolve<ResolveResult> {
  private readonly splitAgentNotificationInfoUrl = '/ext/eb-ssms-sales-journey-service/splitAgentNotification/info';
  private readonly messageUrl = '/ext/eb-ssms-sales-journey-service/clientConfirmation/client/getMessageUrl';

  constructor(private router: Router, private cdHttpServeService: CdHttpServeService, private cdsLangService: CdsLangService, private alert: CdsAlertService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<ResolveResult> | Promise<ResolveResult> | ResolveResult {
    let id = '';
    let iffId = '';
    let iffVersionNo = '';

    if (window.location.href.includes('?messageId=')) {
      const messageIdStartIndex = window.location.href.indexOf('?messageId=') + 11;
      const messageId = window.location.href.substring(messageIdStartIndex);
      this.cdHttpServeService
        .post<{ url: string }>(this.messageUrl, {
          messageId,
        })
        .pipe(
          map(res => {
            if (res.result !== 0) {
              this.router.navigate(['/error/split-agent-notification-info-error']).then(() => {
                this.cdsLangService.setCurrentLang(language === 'en' ? 'en' : 'zh');
              });
            } else {
              window.location.href = res.data.url;
            }
          }),
          catchError(msg => {
            this.router.navigate(['/error/split-agent-notification-info-error']).then(() => {
              this.cdsLangService.setCurrentLang(language === 'en' ? 'en' : 'zh');
              this.alert.error('Error', String(msg));
            });
            return throwError(() => msg);
          })
        );
    }

    if (!window.location.href.includes('/salesjourney/split-agent-notification')) {
      id = sessionStorage.getItem('summaryId') || '';
      iffId = sessionStorage.getItem('iffId') || '';
      iffVersionNo = sessionStorage.getItem('iffVersionNo') || '';
    } else {
      const summaryIdStartIndex = window.location.href.indexOf('?id=') + 4;
      const summaryIdEndIndex = window.location.href.indexOf('&iffId');
      id = window.location.href.substring(summaryIdStartIndex, summaryIdEndIndex);

      const iffIdStartIndex = window.location.href.indexOf('&iffId=') + 7;
      const iffIdEndIndex = window.location.href.indexOf('&iffVersionNo');
      iffId = window.location.href.substring(iffIdStartIndex, iffIdEndIndex);

      const iffVersionNoStartIndex = window.location.href.indexOf('&iffVersionNo=') + 14;
      const iffVersionNoEndIndex = window.location.href.indexOf('&language');
      iffVersionNo = window.location.href.substring(iffVersionNoStartIndex, iffVersionNoEndIndex);

      if (id.includes('%') && id.lastIndexOf('%') !== id.indexOf('%') && sessionStorage.getItem('num')) {
        id = sessionStorage.getItem('summaryId') || '';
        iffId = sessionStorage.getItem('iffId') || '';
        iffVersionNo = sessionStorage.getItem('iffVersionNo') || '';
      }

      sessionStorage.setItem('num', '1');
      sessionStorage.setItem('summaryId', id);
      sessionStorage.setItem('iffId', iffId);
      sessionStorage.setItem('iffVersionNo', iffVersionNo);
    }
    const language = route.queryParams['language'];

    return this.cdHttpServeService
      .post<SplitAgentNotificationInfoBody>(this.splitAgentNotificationInfoUrl, {
        summaryId: id,
        iffId,
        iffVersionNo,
      })
      .pipe(
        map(res => {
          if (res.result === 15) {
            this.router.navigate(['/success/split-agent-notification-info-success']).then(() => {
              this.cdsLangService.setCurrentLang(language === 'en' ? 'en' : 'zh');
              setTimeout(() => {
                this.router.navigate(['/home']);
              }, 5000);
            });
          } else if (res.result !== 0) {
            this.router.navigate(['/error/split-agent-notification-info-error']).then(() => {
              this.cdsLangService.setCurrentLang(language === 'en' ? 'en' : 'zh');
            });
          }
          return { body: res.data, summaryId: id, iffId, iffVersionNo };
        }),
        catchError(msg => {
          const s = window.location.href.indexOf('/salesjourney/split-agent-notification');
          const path = window.location.href.substring(s);
          sessionStorage.setItem('targetUrl', path);
          this.router.navigate(['/error/split-agent-notification-info-error']).then(() => {
            this.cdsLangService.setCurrentLang(language === 'en' ? 'en' : 'zh');
            this.alert.error('Error', String(msg));
          });
          return throwError(() => msg);
        })
      );
  }
}
