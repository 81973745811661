<div class="row" [class.static-class]="!sticky" [class.sticky-class]="sticky">
  <div class="col-xs-12" [class.l-plr-0]="isPolicy">
    <div class="flex l-jc-sb l-ai-cen nowrap flex-container" [class.white-background]="!isPolicy" [class.l-pt-7]="!sticky">
      <div class="row no-wrap" [class.l-plr-0]="isPolicy">
        <div class="l-plr-0">
          <div class="row l-plr-0">
            <div class="title-icon">
              <cds-icon color="#00A758" width="40px" height="40px" icon="people:login_1"></cds-icon>
            </div>
            <div class="col-xs l-plr-0">
              <div class="row l-plr-0">
                <div class="col-xs-12 l-plr-0">
                  <div *ngIf="sticky">
                    <span class="cds-h4-demibold">{{ detailData?.lastName }}&nbsp;{{ detailData?.firstName }}</span>
                    <span class="cds-body1-demibold"> {{ detailData?.lastZhName }}{{ detailData?.firstZhName }} </span>
                  </div>

                  <div *ngIf="!sticky">
                    <div class="cds-h4-demibold font-weight-6 l-mb-0">{{ detailData?.lastName }}&nbsp;{{ detailData?.firstName }}</div>
                    <div class="cds-body1-demibold font-weight-6 l-mb-0">{{ detailData?.lastZhName }}{{ detailData?.firstZhName }}</div>
                  </div>

                  <div class="row middle-xs l-plr-0 l-pt-1">
                    <!-- <div class="dot-icon" [style]="companyService.getStatusColorStyle(detailData?.employerStatus)"></div>
                    <div>
                      {{ companyService.getStatus(detailData?.employerStatus) }}
                    </div> -->
                    <app-icon-status [value]="detailData?.status" [statusMapObj]="employeeStatusMapObj"></app-icon-status>
                  </div>

                  <div *ngIf="!sticky" class="row middle-xs l-plr-0 l-pt-1 cds-body2">
                    <span class="l-mr-1">Latest update from eMPF:</span>
                    <span class="l-mr-1"> {{ updateDate | fn : detailData?.latestUpdate }}</span>
                    <span>(HKT)</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <ng-container *ngIf="sticky">
          <app-row-item label="Member eMPF ID" *ngIf="!isPolicy" [data]="detailData?.empfId" class="l-ml-8"> </app-row-item>
          <app-row-item label="ee.member-account-code" *ngIf="isPolicy" [data]="detailData.memberAccountCode" class="l-ml-8"> </app-row-item>
          <app-row-item label="ee.member-account-type" *ngIf="isPolicy" [data]="detailData.memberAccountType" class="l-ml-8"></app-row-item>
        </ng-container>
      </div>

      <div class="button-area" *ngIf="showBtn && !sticky">
        <cds-button [config]="linkBtnConfig">Link to e-CWS</cds-button>
      </div>
    </div>
  </div>
</div>
