import { AccountType } from 'src/app/core/models/enum/account-type.enum';
import { ScaleType } from 'src/app/core/models/enum/commission-scale.enum';
import { AccumulationType } from 'src/app/core/models/enum/accumulation-type.enum';

const adjustArray0: string[] = [ScaleType.FS_PC, ScaleType.TRF_SP_PC, ScaleType.FY, ScaleType.TRF_SP, ScaleType.FS_SF, 'POLICY'];
const adjustArray4: string[] = [ScaleType.FS_PC, ScaleType.FY, ScaleType.FS_SF, 'POLICY'];
const adjustArray1: string[] = [
  ScaleType.FS_PC,
  ScaleType.PS_PC,
  ScaleType.TRF_SP_PC,
  ScaleType.FY,
  ScaleType.PS,
  ScaleType.TRF_SP,
  ScaleType.FS_SF,
  ScaleType.PS_SF,
  'POLICY',
];
const adjustArray2: string[] = [ScaleType.TRF_SP_PC, ScaleType.TRF_SP, 'POLICY'];
const adjustArray3: string[] = [ScaleType.FS_PC, ScaleType.RY_PC, ScaleType.FY, ScaleType.FS_SF, 'POLICY'];

export const AccountAdjMap = new Map([
  [AccountType.MPF_ER, adjustArray0],
  [AccountType.MPF_SEP, adjustArray4],
  [AccountType.MPF_SEP_AGENT, adjustArray4],
  [AccountType.MPF_FRC, adjustArray2],
  [AccountType.MPF_TVC, adjustArray3],
  [AccountType.ORSO_ER, adjustArray1],
  [AccountType.MACAU_PPS_ER, adjustArray1],
  [AccountType.MACAU_CPF_ER, adjustArray1],
]);

export const AdjustTypes = [
  { label: '01: FS HKPC', value: ScaleType.FS_PC, correspondingScale: 'FS HKPC Scale', ptd: 'FS HKPC PTD' },
  { label: '02: PS HKPC', value: ScaleType.PS_PC, correspondingScale: 'PS HKPC Scale', ptd: 'PS HKPC PTD' },
  { label: '03: TRF SP HKPC', value: ScaleType.TRF_SP_PC, correspondingScale: 'Transfer SP HKPC Scale', ptd: 'TRF SP HKPC PTD' },
  { label: '04: RY HKPC', value: ScaleType.RY_PC, correspondingScale: 'RY HKPC Scale', ptd: 'RY HKPC PTD' },
  { label: '11: FYR COMM', value: ScaleType.FY, correspondingScale: '1st Yr Comm Scale', ptd: '1st Yr COMM PTD' },
  { label: '12: PS COMM', value: ScaleType.PS, correspondingScale: 'PS Comm Scale', ptd: 'PS COMM PTD' },
  { label: '13: TRF SP COMM', value: ScaleType.TRF_SP, correspondingScale: 'Transfer SP Comm Scale', ptd: 'TRF SP COMM PTD' },
  { label: '14: FS Service Fee', value: ScaleType.FS_SF, correspondingScale: 'FS Service Fee Scale', ptd: 'FS Service Fee PTD' },
  { label: '15: PS Service Fee', value: ScaleType.PS_SF, correspondingScale: 'PS Service Fee Scale', ptd: 'PS Service Fee PTD' },
  { label: '20: Policy count', value: 'POLICY', correspondingScale: '', ptd: '' },
];

export const AdjustAccumulations = [
  {
    scaleType: ScaleType.FS_PC,
    agents: [
      {
        adjustType: 'agent',
        accumulationType: AccumulationType.CAL_YTD,
        amount: '0',
      },
      {
        adjustType: 'agent',
        accumulationType: AccumulationType.CYTD,
        amount: '0',
      },
      {
        adjustType: 'agent',
        accumulationType: AccumulationType.PTD,
        amount: '0',
      },
    ],
    scales: [
      {
        adjustType: 'agent',
        accumulationType: AccumulationType.RELEASED_CYTD,
        amount: '0',
      },
      {
        adjustType: 'scale',
        accumulationType: AccumulationType.PREMIUM_CYTD,
        amount: '0',
      },
      {
        adjustType: 'scale',
        accumulationType: AccumulationType.PREMIUM_PTD,
        amount: '0',
      },
    ],
  },
  {
    scaleType: ScaleType.PS_PC,
    agents: [
      {
        adjustType: 'agent',
        accumulationType: AccumulationType.CAL_YTD,
        amount: '0',
      },
      {
        adjustType: 'agent',
        accumulationType: AccumulationType.CYTD,
        amount: '0',
      },
      {
        adjustType: 'agent',
        accumulationType: AccumulationType.PTD,
        amount: '0',
      },
    ],
    scales: [
      {
        adjustType: 'agent',
        accumulationType: AccumulationType.RELEASED_CYTD,
        amount: '0',
      },
      {
        adjustType: 'scale',
        accumulationType: AccumulationType.PREMIUM_CYTD,
        amount: '0',
      },
      {
        adjustType: 'scale',
        accumulationType: AccumulationType.PREMIUM_PTD,
        amount: '0',
      },
    ],
  },
  {
    scaleType: ScaleType.TRF_SP_PC,
    agents: [
      {
        adjustType: 'agent',
        accumulationType: AccumulationType.CAL_YTD,
        amount: '0',
      },
      {
        adjustType: 'agent',
        accumulationType: AccumulationType.CYTD,
        amount: '0',
      },
      {
        adjustType: 'agent',
        accumulationType: AccumulationType.PTD,
        amount: '0',
      },
    ],
    scales: [
      {
        adjustType: 'agent',
        accumulationType: AccumulationType.RELEASED_CYTD,
        amount: '0',
      },
      {
        adjustType: 'scale',
        accumulationType: AccumulationType.PREMIUM_CYTD,
        amount: '0',
      },
      {
        adjustType: 'scale',
        accumulationType: AccumulationType.PREMIUM_PTD,
        amount: '0',
      },
    ],
  },
  {
    scaleType: ScaleType.RY_PC,
    agents: [
      {
        adjustType: 'agent',
        accumulationType: AccumulationType.CAL_YTD,
        amount: '0',
      },
      {
        adjustType: 'agent',
        accumulationType: AccumulationType.CYTD,
        amount: '0',
      },
      {
        adjustType: 'agent',
        accumulationType: AccumulationType.PTD,
        amount: '0',
      },
    ],
    scales: [
      {
        adjustType: 'agent',
        accumulationType: AccumulationType.RELEASED_CYTD,
        amount: '0',
      },
      {
        adjustType: 'scale',
        accumulationType: AccumulationType.PREMIUM_CYTD,
        amount: '0',
      },
      {
        adjustType: 'scale',
        accumulationType: AccumulationType.PREMIUM_PTD,
        amount: '0',
      },
    ],
  },
  {
    scaleType: ScaleType.FY,
    agents: [
      {
        adjustType: 'agent',
        accumulationType: AccumulationType.CAL_YTD,
        amount: '0',
      },
      {
        adjustType: 'agent',
        accumulationType: AccumulationType.CYTD,
        amount: '0',
      },
      {
        adjustType: 'agent',
        accumulationType: AccumulationType.PTD,
        amount: '0',
      },
    ],
    scales: [
      {
        adjustType: 'scale',
        accumulationType: AccumulationType.PREMIUM_CYTD,
        amount: '0',
      },
      {
        adjustType: 'scale',
        accumulationType: AccumulationType.PREMIUM_PTD,
        amount: '0',
      },
    ],
  },
  {
    scaleType: ScaleType.PS,
    agents: [
      {
        adjustType: 'agent',
        accumulationType: AccumulationType.CAL_YTD,
        amount: '0',
      },
      {
        adjustType: 'agent',
        accumulationType: AccumulationType.CYTD,
        amount: '0',
      },
      {
        adjustType: 'agent',
        accumulationType: AccumulationType.PTD,
        amount: '0',
      },
    ],
    scales: [
      {
        adjustType: 'scale',
        accumulationType: AccumulationType.PREMIUM_CYTD,
        amount: '0',
      },
      {
        adjustType: 'scale',
        accumulationType: AccumulationType.PREMIUM_PTD,
        amount: '0',
      },
    ],
  },
  {
    scaleType: ScaleType.TRF_SP,
    agents: [
      {
        adjustType: 'agent',
        accumulationType: AccumulationType.CAL_YTD,
        amount: '0',
      },
      {
        adjustType: 'agent',
        accumulationType: AccumulationType.CYTD,
        amount: '0',
      },
      {
        adjustType: 'agent',
        accumulationType: AccumulationType.PTD,
        amount: '0',
      },
    ],
    scales: [
      {
        adjustType: 'scale',
        accumulationType: AccumulationType.PREMIUM_CYTD,
        amount: '0',
      },
      {
        adjustType: 'scale',
        accumulationType: AccumulationType.PREMIUM_PTD,
        amount: '0',
      },
    ],
  },
  {
    scaleType: ScaleType.FS_SF,
    agents: [
      {
        adjustType: 'agent',
        accumulationType: AccumulationType.CAL_YTD,
        amount: '0',
      },
      {
        adjustType: 'agent',
        accumulationType: AccumulationType.CYTD,
        amount: '0',
      },
      {
        adjustType: 'agent',
        accumulationType: AccumulationType.PTD,
        amount: '0',
      },
    ],
    scales: [
      {
        adjustType: 'scale',
        accumulationType: AccumulationType.GROSS_PREM_CYTD,
        amount: '0',
      },
      {
        adjustType: 'scale',
        accumulationType: AccumulationType.PREMIUM_CYTD,
        amount: '0',
      },
      {
        adjustType: 'scale',
        accumulationType: AccumulationType.PREMIUM_PTD,
        amount: '0',
      },
    ],
  },
  {
    scaleType: ScaleType.PS_SF,
    agents: [
      {
        adjustType: 'agent',
        accumulationType: AccumulationType.CAL_YTD,
        amount: '0',
      },
      {
        adjustType: 'agent',
        accumulationType: AccumulationType.CYTD,
        amount: '0',
      },
      {
        adjustType: 'agent',
        accumulationType: AccumulationType.PTD,
        amount: '0',
      },
    ],
    scales: [
      {
        adjustType: 'scale',
        accumulationType: AccumulationType.GROSS_PREM_CYTD,
        amount: '0',
      },
      {
        adjustType: 'scale',
        accumulationType: AccumulationType.PREMIUM_CYTD,
        amount: '0',
      },
      {
        adjustType: 'scale',
        accumulationType: AccumulationType.PREMIUM_PTD,
        amount: '0',
      },
    ],
  },
];

export const Transactions = [
  {
    scaleType: ScaleType.FS_PC,
    share: '100',
    scaleRate: '0',
    txnCode: 'COMMISSION_ADJUSTMENT',
    commissionCode: 'MANUAL_ADJUSTMENT_OR_CLAWBACK',
  },
  {
    scaleType: ScaleType.PS_PC,
    share: '100',
    scaleRate: '0',
    txnCode: 'COMMISSION_ADJUSTMENT',
    commissionCode: 'MANUAL_ADJUSTMENT_OR_CLAWBACK',
  },
  {
    scaleType: ScaleType.TRF_SP_PC,
    share: '100',
    scaleRate: '0',
    txnCode: 'COMMISSION_ADJUSTMENT',
    commissionCode: 'TRANSFER_PC',
  },
  {
    scaleType: ScaleType.RY_PC,
    share: '100',
    scaleRate: '0',
    txnCode: 'COMMISSION_ADJUSTMENT',
    commissionCode: 'TRANSFER_PC',
  },
  {
    scaleType: ScaleType.FY,
    share: '100',
    scaleRate: '0',
    txnCode: 'COMMISSION_ADJUSTMENT',
    commissionCode: 'FIRST_YEAR_COMMISSION',
  },
  {
    scaleType: ScaleType.PS,
    share: '100',
    scaleRate: '0',
    txnCode: 'COMMISSION_ADJUSTMENT',
    commissionCode: 'FIRST_YEAR_COMMISSION',
  },
  {
    scaleType: ScaleType.TRF_SP,
    share: '100',
    scaleRate: '0',
    txnCode: 'COMMISSION_ADJUSTMENT',
    commissionCode: 'TRANSFER_COMMISSION',
  },
  {
    scaleType: ScaleType.FS_SF,
    share: '100',
    scaleRate: '0',
    txnCode: 'COMMISSION_ADJUSTMENT',
    commissionCode: 'SERVICE_FEE',
  },
  {
    scaleType: ScaleType.PS_SF,
    share: '100',
    scaleRate: '0',
    txnCode: 'COMMISSION_ADJUSTMENT',
    commissionCode: 'SERVICE_FEE',
  },
];
