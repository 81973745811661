/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { finalize } from 'rxjs';

import moment from 'moment';
import { CDS_SIZE } from '@cds/ng-core/configuration';
import { CdsPopupService } from '@cds/ng-web-components/popup';
import { MatDialogRef } from '@angular/material/dialog';

import { ColumnConfig, PageConfig } from 'src/app/shared/data-table';
import { CommissionErrorLogService } from 'src/app/core/services/commission/commission-error-log.service';
import { ResponseResult } from 'src/app/core/models/response/response-result';
import { AccountTypes } from 'src/app/core/models/enum/account-type.enum';
import { TxnCodeOpts } from 'src/app/core/models/enum/txn-code.enum';

import { ERROR_LOG_COLUMNS, I18N_KEY, PAGE_SIZE } from './error-log.config';
import { UserAgreementPopupComponent } from '../user-agreement-popup.component';
import { numberValidator, dateRangeInCompleteValidator, dateRangeInvalidValidator, dateValidator } from 'src/app/core/validators';
@Component({
  selector: 'app-error-log',
  templateUrl: './error-log.component.html',
  styleUrls: ['./error-log.component.scss'],
})
export class ErrorLogComponent implements OnInit {
  isShowSearchDatePicker = true; // Fix DatePicker Bug

  previousSearchParams: any;

  isDataLoading = false;
  pageConfig: PageConfig = {};
  dataDisplayed: any[] = [];
  columnsConfig: Array<ColumnConfig> = ERROR_LOG_COLUMNS;

  formGroup = new FormGroup(
    {
      occurDateFrom: new FormControl(null, [dateValidator({ error: I18N_KEY.INVALID_DATE_FORMAT })]),
      occurDateTo: new FormControl(null, [dateValidator({ error: I18N_KEY.INVALID_DATE_FORMAT })]),
      employerAccountCode: new FormControl(null, [
        numberValidator({
          error: I18N_KEY.ACCOUNT_NO_INVALID,
        }),
      ]),
      memberAccountCode: new FormControl(null, [
        numberValidator({
          error: I18N_KEY.MEMBER_NO_INVALID,
        }),
      ]),
      referenceNo: new FormControl(null),
    },
    [
      dateRangeInCompleteValidator('occurDateFrom', 'occurDateTo', { error: I18N_KEY.ERROR_DATE_REANGE }),
      dateRangeInvalidValidator('occurDateFrom', 'occurDateTo', { error: I18N_KEY.INVALID_DATE_REANGE }),
    ]
  );

  get occurDateFrom() {
    return this.formGroup.get('occurDateFrom') as FormControl;
  }

  get occurDateTo() {
    return this.formGroup.get('occurDateTo') as FormControl;
  }

  get employerAccountCode() {
    return this.formGroup.get('employerAccountCode') as FormControl;
  }

  get memberAccountCode() {
    return this.formGroup.get('memberAccountCode') as FormControl;
  }

  get referenceNo() {
    return this.formGroup.get('referenceNo') as FormControl;
  }

  get searchDisabled() {
    let tag = true;
    const controlValues = this.formGroup.value;
    const keys = Object.keys(controlValues);
    keys.forEach((key: string) => {
      if (controlValues[key]) {
        tag = false;
      }
    });

    return tag || this.formGroup.invalid || this.isDataLoading;
  }

  get getTotalCount() {
    return this.pageConfig.totalCount || 0;
  }

  constructor(private cdsPopup: CdsPopupService, private errorLogService: CommissionErrorLogService) {}

  ngOnInit() {
    this.search();
  }

  search() {
    this.previousSearchParams = null;
    this.pageConfig = {
      pageSize: PAGE_SIZE,
      current: 1,
    };
    this.getData();
  }

  getData() {
    this.isDataLoading = true;
    const params = this.createParams();
    this.errorLogService
      .getData(params)
      .pipe(
        finalize(() => {
          this.isDataLoading = false;
        })
      )
      .subscribe((res: any) => {
        if (res.result === ResponseResult.SUCCESS) {
          this.previousSearchParams = params;
          this.pageConfig = {
            ...this.pageConfig,
            totalCount: res.data.totalElements,
          };
          const tempData = res.data.content || [];
          this.dataDisplayed = this.createDisplayData(tempData);
        } else {
          this.openAlertPop(res.message);
        }
      });
  }

  createDisplayData(data: any[]) {
    return data.map((item: any) => {
      item.accountType = AccountTypes.find(list => list.value === item.accountType)?.label || item.accountType;
      item.occurDate = item.occurDate ? moment(new Date(item.occurDate)).format('DD/MM/YYYY') : '';
      item.contStartDate = item.contStartDate ? moment(new Date(item.contStartDate)).format('DD/MM/YYYY') : '';
      const txnCode = TxnCodeOpts.find(list => list.value === item.txnCode);
      item.txnCode = txnCode ? txnCode.label.split(':')[0] : item.txnCode;
      return item;
    });
  }

  createParams() {
    const params: any = {
      page: (this.pageConfig.current || 1) - 1,
      size: PAGE_SIZE,
      sort: 'occurDate,desc',
    };
    if (this.previousSearchParams) {
      return { ...this.previousSearchParams, ...params };
    }
    const formValue = this.getFormValue();
    if (formValue.occurDateFrom) {
      formValue.occurDateFrom = moment(formValue.occurDateFrom, 'DD/MM/YYYY').format('YYYY-MM-DD');
    }
    if (formValue.occurDateTo) {
      formValue.occurDateTo = moment(formValue.occurDateTo, 'DD/MM/YYYY').format('YYYY-MM-DD');
    }
    return { ...params, ...formValue };
  }

  getFormValue() {
    const valueTemp: any = {};
    const value = this.formGroup.value;
    const keys: string[] = Object.keys(value);
    keys.forEach(key => {
      if (value[key] !== null) {
        valueTemp[key] = value[key];
      }
    });
    return valueTemp;
  }

  reset() {
    this.previousSearchParams = null;
    this.formGroup.reset();
    this.isShowSearchDatePicker = false;
    this.pageConfig = {};
    setTimeout(() => {
      this.isShowSearchDatePicker = true; // Fix DatePicker Bug
    });
    this.search();
  }

  pageChange(ev: any) {
    if (this.pageConfig.current !== ev.current) {
      this.pageConfig.current = ev.current;
      this.getData();
    }
  }

  openAlertPop(key: string) {
    const popupRef: MatDialogRef<UserAgreementPopupComponent> = this.cdsPopup.open(UserAgreementPopupComponent, {
      data: {
        continue: I18N_KEY.COMMON_GOT,
        type: 'alert',
        message: key,
      },
      size: CDS_SIZE['MEDIUM'],
    });
    popupRef.afterClosed().subscribe(() => {});
  }
}
