<div class="sw-update-ask">
  <div class="l-d-f l-ai-cen sound-warpper">
    <div class="sound-low-box">
      <cds-icon icon="action:sound_low"></cds-icon>
    </div>
    <div class="tip-box">
      <div>
        <span class="cds-body1-demibold">{{ 'swUpdateAsk.1' | lang }}</span>
      </div>
      <div class="text-click">
        <span (click)="refresh()" class="cds-body1-bold">{{ 'swUpdateAsk.2' | lang }}</span>
      </div>
      <div>
        <span class="cds-body1-demibold">{{ 'swUpdateAsk.3' | lang }}</span>
      </div>
    </div>
  </div>
  <div class="close-box">
    <cds-icon (click)="close()" class="close" icon="action:cross"></cds-icon>
  </div>
</div>
