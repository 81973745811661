<div class="row">
  <div class="col-xs-12 l-pt-5">
    <span class="cds-body1-demibold">By Bill Reference No.</span>
  </div>
  <div class="col-xs-12">
    <div class="p-line"></div>
  </div>
</div>

<div class="row">
  <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 l-pb-4 l-mr-4">
    <cds-textfield
      placeholder="DD/MM/YYYY"
      label="Run Date Quick Filtering"
      [cdsDatepicker]="picker"
      [formControl]="runDateFormControl"
      (cdsChange)="onDateChange($event)">
    </cds-textfield>
    <cds-datepicker #picker="cdsDatepicker"></cds-datepicker>

    <div *ngIf="runDateFormControl?.invalid && (runDateFormControl?.dirty || runDateFormControl?.touched)" class="l-pt-2">
      <cds-assistive-text *ngIf="runDateFormControl?.errors?.['dateInvalid']" class="l-pt-2" [showIcon]="false">{{
        'create.dateError' | lang
      }}</cds-assistive-text>
    </div>
  </div>
</div>

<div class="row">
  <cds-accordion class="col-xs-12" *ngIf="data && data.length">
    <cds-accordion-panel *ngFor="let billReference of data" [title]="'Bill Reference No. ' + billReference.billReferenceNumber">
      <div *ngFor="let item of billReference.list; index as i" class="l-pb-6" [class.border-bottom]="i !== billReference.list.length - 1">
        <div class="run-date cds-h5-demibold l-mb-4 flex">
          <cds-icon class="l-mr-2" icon="action:date" [config]="iconConfig"></cds-icon>
          <span>Run Date:</span>
          <span>{{ item.runDate | ssmsDate }}</span>
        </div>
        <div class="l-mb-4">
          <div class="vl">
            <div class="vl-label">Billing Status Type</div>
            <div class="vl-value">{{ item.billingStatusCode }}</div>
          </div>
          <div class="vl">
            <div class="vl-label">Remittance Statement Received Date</div>
            <div class="vl-value">{{ item.remittanceStatementReceivedDate | ssmsDate }}</div>
          </div>
          <div class="vl">
            <div class="vl-label">Receipt Reference No.</div>
            <div class="vl-value">
              <span *ngFor="let number of item.receiptReferenceNumberList; index as i" class="cds-body1">
                <span class="click" (click)="toDetail(number)">{{ number }}</span
                ><span class="l-mr-2" *ngIf="item.receiptReferenceNumberList && i !== item.receiptReferenceNumberList.length - 1">, </span>
              </span>
            </div>
          </div>
          <div class="vl">
            <div class="vl-label">Payment Submission Refeernce No.</div>
            <div class="vl-value">
              <span *ngFor="let number of item.paymentSubmissionReferenceNumberList; index as i" class="cds-body1">
                <span class="click" (click)="toDetail(number)">{{ number }}</span
                ><span class="l-mr-2" *ngIf="item.paymentSubmissionReferenceNumberList && i !== item.paymentSubmissionReferenceNumberList.length - 1">, </span>
              </span>
            </div>
          </div>
          <div class="vl">
            <div class="vl-label">Workflow Reference No.</div>
            <div class="vl-value">
              <span *ngFor="let number of item.paymentWorkflowReferenceNoList; index as i" class="cds-body1">
                <span class="click" (click)="toDetail(number)">{{ number }}</span
                ><span class="l-mr-2" *ngIf="item.paymentWorkflowReferenceNoList && i !== item.paymentWorkflowReferenceNoList.length - 1">, </span>
              </span>
            </div>
          </div>
        </div>
        <app-amount-table
          [contributionTableDataSource]="service.contributionAmountTableDataTrans | fn : item"
          [contributionDetails]="getContributionDetails(item)"
          [billingClass]="billingClass"
          [employerId]="employerId"
          [byBillReference]="true">
        </app-amount-table>
      </div>
    </cds-accordion-panel>
  </cds-accordion>

  <div class="col-xs-12 l-ai-cen l-pt-4" *ngIf="!data || !data.length">
    <app-no-result-found></app-no-result-found>
  </div>
</div>
