/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { CdsButtonConfig } from '@cds/ng-core/button';
import { CdsIconConfig } from '@cds/ng-core/icon';
import { CdsLangService } from '@cds/ng-core/lang';
import { CdsPopupService } from '@cds/ng-web-components/popup';
import { finalize } from 'rxjs';
import { ToastAlertService } from 'src/app/shared/toast-alert.service';
import { EmployeeService } from '../../employee/employee.service';
import { CommontsPopupComponent } from '../../employer/commonts-popup/commonts-popup.component';
import { CampaignErrorInfo, CustomerListFileCheckRes, CustomerListUploadErrorMessageTypeE, customerListUploadErrorMessageTypeMap } from '../campaign';
import { AgentSepCheckResultTypeE } from '../../employer/employer';
import { deepCopy } from 'src/app/utils/copy';
import { DateTime } from 'luxon';

@Component({
  selector: 'app-upload-customer-list',
  templateUrl: './upload-customer-list.component.html',
  styleUrls: ['./upload-customer-list.component.scss'],
})
export class UploadCustomerListComponent implements OnInit {
  show = true;

  cancelButtonConfig: CdsButtonConfig = { size: 'sm', style: 'secondary' };

  confirmButtonConfig: CdsButtonConfig = { size: 'sm' };

  uploadFormControl = new FormControl();

  warnIconConfig: CdsIconConfig = {
    color: '#D77D28',
  };

  errorIconConfig: CdsIconConfig = {
    color: '#C14A36',
  };

  file!: File;

  noFileSelect = true;

  fileAvailable = true;

  get showFileErrorMessage() {
    return !this.fileValid.size || !this.fileValid.type || !this.fileValid.type;
  }

  isLoading = false;

  checked = false;

  checkOk = true;

  checkResultInfoList: CustomerListFileCheckRes[] = [];

  displayedColumns = ['serialNumber', 'campaignName', 'memberAccountCode'];

  AgentSepCheckResultTypeE = AgentSepCheckResultTypeE;

  CustomerListUploadErrorMessageTypeE = CustomerListUploadErrorMessageTypeE;

  fileValid = this.fileValidinit();

  constructor(
    private dialogRef: MatDialogRef<UploadCustomerListComponent>,
    private cdsPopup: CdsPopupService,
    private langService: CdsLangService,
    private service: EmployeeService,
    private toastAlert: ToastAlertService
  ) {}

  ngOnInit(): void {
    this.uploadFormControl.valueChanges.subscribe(value => {
      if (value === null) {
        this.fileValid = this.fileValidinit();
        this.noFileSelect = true;
      } else {
        this.noFileSelect = false;
      }
    });
  }

  fileChange(event: any) {
    this.file = event?.target?.files[0];
    this.checkFile(this.file);
    this.checked = false;
    this.checkOk = true;
    this.checkResultInfoList = [];
  }

  fileValidinit() {
    return {
      type: true,
      size: true,
      name: true,
    };
  }

  checkFile(file: any) {
    if (!file || !file.size || !file.type || !file.name) {
      this.fileValid = this.fileValidinit();
    }

    // file size limit is 10MB
    if (file.size <= 10485760) {
      this.fileValid.size = true;
    } else {
      this.fileValid.size = false;
    }

    if (file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
      this.fileValid.type = true;
    } else {
      this.fileValid.type = false;
    }

    const regexPattern = /^HKR Customer Engagement Tracking_Member_\d{8}.xlsx$/;

    const nameOk = regexPattern.test(file.name);

    if (nameOk) {
      const dateStr = file.name.substr(-8);
      const dateStrValid = DateTime.fromFormat(dateStr, 'yyyyMMdd').isValid;

      if (dateStrValid) {
        this.fileValid.name = true;
      } else {
        this.fileValid.name = false;
      }
    } else {
      this.fileValid.name = false;
    }
  }

  confirm() {
    if (this.noFileSelect || this.showFileErrorMessage) {
      return;
    }
    this.upload();
  }

  toastError(errorMessage: string) {
    this.toastAlert.show('error', 'common.error', errorMessage, 5000);
  }

  cancel() {
    if (this.noFileSelect) {
      this.dialogRef.close({
        agree: false,
      });
      return;
    }
    this.show = false;
    const popupRef: MatDialogRef<CommontsPopupComponent> = this.cdsPopup.open(CommontsPopupComponent, {
      size: 'md',
      data: {
        message: this.langService.translate('common.action.cancel'),
        noCloseIcon: true,
      },
    });
    popupRef.afterClosed().subscribe(data => {
      this.show = true;
      if (data.agree) {
        this.dialogRef.close({
          agree: false,
        });
      }
    });
  }

  upload() {
    this.isLoading = true;
    this.service
      .uploadCustomerList(this.file)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe({
        next: res => {
          if (res.result === 0 && res.data) {
            if (res.data.length === 0) {
              this.dialogRef.close({
                agree: true,
              });
            } else {
              this.checkResultInfoList = res.data;
              this.checkOk = false;
            }
          } else {
            this.toastError(res.message);
          }
        },
      });
  }

  warningTitleRender = (item: CustomerListFileCheckRes) => {
    const i18nKey = customerListUploadErrorMessageTypeMap.get(item.messageType as CustomerListUploadErrorMessageTypeE) || '';
    if (item.messageType === CustomerListUploadErrorMessageTypeE.WRONG_CAMPAIGN_ID) {
      return this.langService.translate(i18nKey, { p1: item.campaignId as string });
    }
    return this.langService.translate(i18nKey);
  };

  warningTextRender = (item: CustomerListFileCheckRes) => {
    if (item.messageType === CustomerListUploadErrorMessageTypeE.MISSING_MEMBER_ACCOUNT_CODE) {
      return this.langService.translate('campaign.wrong-campaign-id-tip');
    }
    return '';
  };

  tableDataTrans(list: CampaignErrorInfo[]) {
    if (!list) {
      return [];
    }
    return list.map((item, index) => {
      const data = deepCopy(item);
      data.serialNumber = index + 1;
      return data;
    });
  }
}
