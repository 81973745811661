<div class="trustee-details-popup" appOutsideClose (outsideEvent)="gotIt()">
  <div class="l-ml-7 l-mr-7">
    <span class="cds-body-hero-light">Trustee Definition:</span>
  </div>
  <div class="l-d-f l-fd-col l-mt-5 l-ml-7 l-mr-7 trustee-details-box">
    <div class="l-d-f">
      <div class="head-short-form">
        <span class="cds-detail2">Short Form</span>
      </div>
      <div class="head-definition">
        <span class="cds-detail2">Definition</span>
      </div>
    </div>
    <div class="l-d-f" *ngFor="let item of items">
      <div class="body-short-form">
        <span class="cds-body2-demibold">{{ item.shortForm }}</span>
      </div>
      <div class="body-definition">
        <span class="cds-body2">{{ item.definition }}</span>
      </div>
    </div>
  </div>
  <div class="l-pt-5 l-d-f l-jc-fe l-pl-7 l-pr-7" style="border-top: 1px solid var(--cds-divider-default)">
    <cds-button (click)="gotIt()">{{ 'common.mingBai' | lang }}</cds-button>
  </div>
</div>
