import { Component, HostListener, OnInit } from '@angular/core';
import { CdsButtonConfig } from '@cds/ng-core/button';
import { CdsPopupService } from '@cds/ng-web-components/popup';
import { PermissionService } from 'src/app/core/services/permission.service';
import { FormControl, FormGroup } from '@angular/forms';
import { configPrimary, configSecondary, BaseChequeSearch, I18N_KEY, maxDate, minDate } from '../../benefit-payment';
import { PaymentServiceSHKP } from '../../paymentSHKP.service';
import { dateValidator } from 'src/app/core/validators';

@Component({
  selector: 'app-cheque-printing-list',
  templateUrl: './cheque-printing-list.component.html',
  styleUrls: ['./cheque-printing-list.component.scss'],
})
export class ChequePrintingListComponent implements OnInit {
  constructor(private cdsPopup: CdsPopupService, private service: PaymentServiceSHKP, private permissionService: PermissionService) {}

  searchButtonConfig: CdsButtonConfig = configPrimary();

  resetButtonConfig: CdsButtonConfig = configSecondary();

  _valid = true;

  searchDTO: BaseChequeSearch = {};

  isMobile = false;

  I18N_KEY = I18N_KEY;
  maxDate = maxDate;
  minDate = minDate;

  _search = 0;
  _tabIndex = 0;

  form = new FormGroup({
    instructionFileDateFrom: new FormControl('', [dateValidator()]),
    instructionFileDateTo: new FormControl('', [dateValidator()]),
    payRefNo: new FormControl('', []),
    // appRefNo: new FormControl('', []),
    payeeName: new FormControl('', []),
    isReissue: new FormControl(),
  });

  ngOnInit(): void {
    this.onResize();
    this.initForm();
  }

  async validFn() {
    return this.form.valid && this._valid;
  }

  reissueChnage(val: boolean) {
    this.searchDTO.isReissue = val ? 'Y' : undefined;
    this._valid = true;
  }

  search() {
    this.validFn().then(r => {
      if (r) {
        this._search = this._search + 1;
      }
    });
  }
  reset() {
    this.initForm();
  }

  initForm() {
    this.searchDTO = {};
    this._search = 0;
    this.validating();
  }

  selectedTabIndexChange(index: number) {
    this._tabIndex = index;
  }

  validating() {
    if (
      !!this.searchDTO.instructionFileDateFrom ||
      !!this.searchDTO.instructionFileDateTo ||
      !!this.searchDTO.payRefNo ||
      // !!this.searchDTO.appRefNo ||
      !!this.searchDTO.payeeName ||
      !!this.searchDTO.isReissue
    ) {
      this._valid = true;
    } else {
      this._valid = false;
    }
  }

  inputChange(val: string, label: string) {
    switch (label) {
      case 'payRefNo':
        this.searchDTO.payRefNo = val;
        break;
      case 'instructionFileDateFrom':
        this.searchDTO.instructionFileDateFrom = val;
        break;
      case 'instructionFileDateTo':
        this.searchDTO.instructionFileDateTo = val;
        break;
      case 'appRefNo':
        this.searchDTO.appRefNo = val;
        break;
      case 'payeeName':
        this.searchDTO.payeeName = val;
        break;
    }
    this.validating();
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    if (window.innerWidth < 1024) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
  }
}
