import { Directive, OnDestroy } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';

@Directive({
  selector: '[appMenuClose]',
  exportAs: 'appMenuClose',
})
export class MatMenuCloseDirective implements OnDestroy {
  public constructor(private readonly matMenuTrigger: MatMenuTrigger) {
    window.addEventListener('touchmove', this.scrollEvent, true);
  }

  public ngOnDestroy(): void {
    window.removeEventListener('touchmove', this.scrollEvent, true);
  }

  private scrollEvent = (): void => {
    console.log('matMenuTrigger touchmove');
    if (this.matMenuTrigger == null) {
      return;
    }
    if (this.matMenuTrigger.menuOpen) {
      console.log('matSelect menuOpen');
      this.matMenuTrigger.closeMenu();
    }
  };
}
