import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AgentPerformanceEnrolmentComponent } from './agent-performance-enrolment.component';
import { AgentPerformanceEnrolmentRoutingModule } from './agent-performance-enrolment-routing.module';
import { CdsButtonModule } from '@cds/ng-web-components/button';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CdsDropdownModule } from '@cds/ng-web-components/dropdown';
import { CdsIconModule } from '@cds/ng-web-components/icon';
import { CdsLinkModule } from '@cds/ng-web-components/link';
import { CdsSearchModule } from '@cds/ng-web-components/search';
import { CdsPaginationModule } from '@cds/ng-web-components/pagination';
import { AppPaginationModule } from 'src/app/shared/pagination/pagination.module';
import { LoadingModule } from 'src/app/shared/loading/loading.modules';
import { NoResultFoundModule } from 'src/app/shared/no-result-found/no-result-found.module';
import { CdsTableModule } from '@cds/ng-data-table/table';
import { CdsPopupModule } from '@cds/ng-web-components/popup';
import { CdsTextfieldModule } from '@cds/ng-web-components/textfield';
import { RowItemModule } from 'src/app/components/row-item/row-item.module';
import { CdsSortModule } from '@cds/ng-data-table/sort';
import { PermissionModule } from 'src/app/shared/permission/permission.module';
import { CdsLanguageModule } from '@cds/ng-core/lang';
import { ExpandTableModule } from 'src/app/components/table/expand-table.module';
import { CdsDatepickerModule } from '@cds/ng-web-components/datepicker';
import { ExpandItemMobileComponent } from './expand-item-mobile/expand-item-mobile.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { CdsAssistiveTextModule } from '@cds/ng-web-components/assistive-text';

@NgModule({
  declarations: [AgentPerformanceEnrolmentComponent, ExpandItemMobileComponent],
  imports: [
    CommonModule,
    AgentPerformanceEnrolmentRoutingModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    CdsDropdownModule,
    CdsButtonModule,
    CdsIconModule,
    CdsLinkModule,
    CdsSearchModule,
    CdsPaginationModule,
    CdsLanguageModule,
    CdsTableModule,
    CdsTextfieldModule,
    CdsDatepickerModule,
    AppPaginationModule,
    LoadingModule,
    NoResultFoundModule,
    CdsPopupModule,
    RowItemModule,
    CdsSortModule,
    PermissionModule,
    ExpandTableModule,
    SharedModule,
    CdsAssistiveTextModule,
  ],
})
export class AgentPerformanceEnrolmentModule {}
