<cds-popup>
  <cds-popup-title *ngIf="data.title">{{ data.title | lang }}</cds-popup-title>
  <cds-popup-content>
    <div innerHtml="{{ data.message | lang }}"></div>
  </cds-popup-content>
  <cds-popup-actions>
    <cds-button [config]="agreeButtonConfig" (click)="agree()">{{ 'common.confirm' | lang }}</cds-button>
    <cds-button [config]="disagreeButtonConfig" (click)="close()">{{ 'common.cancel' | lang }}</cds-button>
  </cds-popup-actions>
</cds-popup>
