/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, ElementRef, EventEmitter, forwardRef, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { catchError, finalize, Subscription, throwError } from 'rxjs';
import { InputAutocompleteService } from './input-autocomplete.service';

@Component({
  selector: 'app-dynamic-input-autocomplete',
  templateUrl: './input-autocomplete.component.html',
  styleUrls: ['./input-autocomplete.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputAutocompleteComponent),
      multi: true,
    },
  ],
})
export class InputAutocompleteComponent implements OnInit, OnDestroy, OnChanges {
  constructor(private service: InputAutocompleteService) {}

  @ViewChild('selectPanelRef') selectPanelRef!: ElementRef;

  @Input() value = '';

  @Input() label?: string;

  @Input() editMode = true;

  @Input() disabled = false;

  @Input() placeholder = '';

  @Input() optionList: any[] = [];

  @Input() requestUrl = '';

  @Input() requestParams?: any;

  @Input() optionKey = '';

  @Input() searchKey = '';

  @Input() pageSize = 10;

  @Input() initLoad = true;

  @Input() showDropdownButton = false;

  @Input() searchUntilFocus = false;

  @Input() minLength = 0;

  @Input() inputValid = true;

  @Input() resDataInContent = true;

  @Input() labelRenderFn?: (data: any) => string;

  @Output() valueChange = new EventEmitter<string>();

  @Output() selectChange = new EventEmitter<any>();

  @Output() focusEvent = new EventEmitter();

  @Output() blurEvent = new EventEmitter();

  searchValue = '';

  subscription: Subscription | null = null;

  isLoading = false;

  _input = false;

  page = 0;

  noMorePage = false;

  toggleDisabled = false;

  readyToSearch = false;

  firstLoad = true;

  ngOnInit(): void {
    if (this.optionKey && !this.searchKey) {
      this.searchKey = this.optionKey;
    }

    if (this.disabled || !this.editMode) return;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.disabled || !this.editMode) return;

    if (changes['requestParams'] || changes['requestUrl'] || changes['optionKey'] || changes['searchKey']) {
      if (this.searchUntilFocus) {
        this.readyToSearch = true;
      } else {
        if (this.firstLoad && !this.initLoad) {
          this.firstLoad = false;
        } else {
          this.search(this.value);
        }
      }
    }
  }

  search(value = '') {
    setTimeout(() => {
      if (this.value.length < this.minLength || !this.inputValid) return;
      this.optionList = [];
      this.init();
      this.get(value);
    });
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
    this.subscription = null;
  }

  init() {
    this.page = 0;
    this.noMorePage = false;
  }

  get(searchValue = '') {
    this.subscription?.unsubscribe();
    this.subscription = null;
    const params = {
      ...this.requestParams,
      [this.searchKey]: searchValue,
    };
    this.isLoading = true;
    this.subscription = this.service
      .get(this.requestUrl, params, this.page, this.pageSize, this.resDataInContent)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
        catchError(err => {
          this.noMorePage = true;
          const error = err.message || err.statusText;
          return throwError(() => new Error(error));
        })
      )
      .subscribe((content: any[]) => {
        const optionList = content;
        if (optionList.length < this.pageSize) {
          this.noMorePage = true;
        }
        this.optionList = [...this.optionList, ...optionList];
      });
  }

  inputVal(val: string, searchAction = false, data?: any) {
    if (this.disabled) return;
    this.page = 0;
    this.noMorePage = false;
    this.optionList = [];
    this.value = val;

    this.searchValue = searchAction ? this.value : '';

    this.propagateOnChange(this.value);
    this.valueChange.emit(val);
    if (data) {
      this.selectChange.emit(data);
    }
    if (this.requestUrl) {
      if (this.initLoad || this.value) {
        this.search(this.searchValue);
      } else {
        this.optionList = [];
      }
    }
  }
  cssTrigger(val: boolean) {
    if (!val) {
      this.blurEvent.emit();
      this.toggleDisabled = true;
      setTimeout(() => {
        this.toggleDisabled = false;
      }, 300);
    } else {
      this.focusEvent.emit();
    }

    this._input = val;
    if (this.readyToSearch && this._input) {
      this.readyToSearch = false;
      this.search(this.value);
    }
  }

  toggleSelectPanel() {
    if (this.toggleDisabled || this.disabled) return;
    this._input = !this._input;
  }

  writeValue(value: string) {
    if (value !== undefined) {
      this.value = value;
      this.propagateOnChange(this.value);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  propagateOnChange = (value: string) => {};

  propagateOnTouched = () => {};

  registerOnChange(fn: (value: string) => void) {
    this.propagateOnChange = fn;
  }

  registerOnTouched(fn: () => void) {
    this.propagateOnTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  selectPanelonScroll() {
    if (this.isLoading || this.noMorePage) return;
    const element = this.selectPanelRef.nativeElement;
    if (element.scrollHeight - element.scrollTop === element.clientHeight) {
      this.page++;
      this.get(this.searchValue);
    }
  }
}
