import { Component, OnInit } from '@angular/core';
import { CdsButtonConfig } from '@cds/ng-core/button';
import { MatDialogRef } from '@cds/ng-web-components/popup';
import { ChequePrintingListComponent } from '../cheque-printing-list/cheque-printing-list.component';

@Component({
  selector: 'app-cheque-printing-save-success',
  templateUrl: './cheque-printing-save-success.component.html',
  styleUrls: ['./cheque-printing-save-success.component.scss'],
})
export class ChequePrintingSaveSuccessComponent implements OnInit {
  constructor(private dialogRef: MatDialogRef<ChequePrintingListComponent>) {}

  agreeButtonConfig: CdsButtonConfig = {
    style: 'secondary',
  };
  disagreeButtonConfig: CdsButtonConfig = {
    style: 'primary',
  };

  ngOnInit(): void {}

  gotIt() {
    this.dialogRef.close({ agree: true });
  }
}
