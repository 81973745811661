<div class="row">
  <div class="col-xs-12 l-pt-8 cds-color-green">
    <span class="cds-h5-demibold">{{ (isStaff ? 'account.member-level' : 'account.member-level-for-agent') | lang }}</span>
  </div>
  <div class="col-xs-12">
    <div class="p-line"></div>
  </div>
</div>

<div class="row middle-xs l-pb-5 l-pt-2">
  <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 l-mr-4 l-pt-5">
    <cds-search [(ngModel)]="searchDTO.searchKey" (ngModelChange)="memberSearchChange()" [config]="config"></cds-search>
  </div>
  <div class="l-pt-5 search-padding">
    <cds-button [fullWidth]="true" class="bottom-xs" [config]="searchButtonConfig" label="{{ 'account.search-go' | lang }}" (click)="search()"></cds-button>
  </div>
</div>

<div class="row">
  <app-data-table
    class="col-xs-12"
    [frontPagination]="false"
    [isLoading]="_progress"
    [fixed]="false"
    [overlay]="true"
    [pageConfig]="pageConfig"
    [dataSource]="dataSource"
    [columnsConfig]="columnsConfig"
    (reloadTable)="reloadTable($event)">
    <ng-template appDtBodyCellDef="memberName" let-element>
      <span [class.click]="isStaff" (click)="goToDetail(element)">
        <span class="l-mr-2">{{ element.lastName }}</span>
        <span class="l-mr-4">{{ element.firstName }}</span>
        <span *ngIf="element.chineseLastName || element.chineseFirstName"
          >(
          <span>{{ element.chineseLastName }}</span>
          <span>{{ element.chineseFirstName }}</span>
          )</span
        >
      </span>
    </ng-template>
    <ng-template appDtBodyCellDef="totalPaidContribution" let-element>
      <div>{{ element.totalPaidContribution | number }}</div>
    </ng-template>
    <ng-template appDtBodyCellDef="paymentReceivedDate" let-element>
      <div>{{ element.paymentReceivedDate | date : 'dd/MM/yyyy' }}</div>
    </ng-template>
  </app-data-table>
</div>
