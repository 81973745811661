<div class="l-pt-7 l-pl-7 l-pr-6 l-pb-6" appOutsideClose (outsideEvent)="backTosummary()">
  <div>
    <span class="cds-body-hero">eMPF source</span>
  </div>
  <div class="l-mt-4">
    <span class="cds-body2"
      >Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin blandit enim erat, ac lacinia ipsum porta ac. Aenean eu augue finibus, pulvinar ex sed,
      volutpat quam. Quisque sem augue, congue in diam ac.</span
    >
  </div>
  <div class="l-mt-4">
    <img style="width: 216px; height: 195px" src="../../../../../assets/img/empf.png" alt="../../../../../assets/img/empf.png" />
  </div>
  <div class="l-mt-4" style="text-align: end">
    <cds-button [size]="'sm'" (click)="backTosummary()">Got it</cds-button>
  </div>
</div>
