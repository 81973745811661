/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { BasicResponse, EnhanceResponse } from 'src/app/core/models/response/response';
//import { map, timeout } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AdjustmentService {
  constructor(private http: HttpClient) {}

  addAdjustment(params: any): Observable<BasicResponse<any>> {
    return this.http.post<any>(`${environment.apiPrefix}${environment.commissionServiceExt}/commission-adjustment/add-adjustment`, params);
  }

  check(params: any): Observable<EnhanceResponse<any>> {
    return this.http.post<any>(`${environment.apiPrefix}${environment.commissionServiceExt}/commission-adjustment/check`, params).pipe(
      map(res => {
        if (params.requestId) {
          res.requestId = params.requestId;
        }
        return res;
      })
    );
  }
}
