/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, map, Observable, throwError } from 'rxjs';
import { ToastAlertService } from 'src/app/shared/toast-alert.service';
import { AuthenticationService } from '../services/authentication.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private router: Router, private authenticationService: AuthenticationService, private toastAlert: ToastAlertService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      map(evt => {
        if (evt instanceof HttpResponse) {
          return evt;
        }
        return evt; //Fixme: It should be return nothing
      }),
      catchError(err => {
        if (err.status === 401) {
          if (
            request.url &&
            request.url.indexOf('/auth/token') === -1 &&
            request.url.indexOf('/auth/otp/token') === -1 &&
            request.url.indexOf('/authority/otp/request') === -1 &&
            request.url.indexOf('/authority/otp/verify') === -1
          ) {
            this.authenticationService.sessionTimeOut();
          }
          console.log('this.router.url----------', this.router.url);
        } else if (err.status === 403) {
          this.authenticationService.setRouterUrl(this.router.url);
          this.router.navigate(['/error/permission-error']);
        } else if (err.status !== 200) {
          if (environment.profile !== 'dev') {
            const msg: string = err.error.message || err.message;
            if (msg.includes('for assets/') && msg.includes('.svg')) {
              return throwError(() => new Error(error));
            }
            this.router.navigate(['/error/response-error'], {
              queryParams: {
                status: err.status,
                message: err.error.message || err.message,
              },
            });
          } else {
            this.toastAlert.show('error', 'common.error', err.error.message, 5000);
          }
        }
        const error = err.message || err.statusText;
        return throwError(() => new Error(error));
      })
    );
  }
}
