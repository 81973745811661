/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CommissionRelatedPartiesService } from 'src/app/core/services/commission/commission-related-parties.service';

import { BasicResponse, SimpleResponse } from 'src/app/core/models/response/response';

@Injectable({
  providedIn: 'root',
})
export class CommissionPoolService {
  baseUrl: string = environment.apiPrefix.concat(environment.commissionServiceExt).concat('/commission-pool-agent/list-pool-agents');
  constructor(private http: HttpClient, private relatedPartiesService: CommissionRelatedPartiesService) {}

  selectList(params: any): Observable<BasicResponse<any>> {
    return this.http
      .get<BasicResponse<any>>(
        `${environment.apiPrefix}${environment.commissionServiceExt}/commission-pool/list`,
        //`http://localhost:8085/commission-pool/list`
        { params: params }
      )
      .pipe(
        map(res => {
          if (res && res.data && res.result == 0) {
            if (res.data.content) {
              const content: any[] = res.data.content;
              content.forEach(item => {
                if (item.commissionPoolCustomers) {
                  const filterParams = {
                    limit: 10,
                    accountCode: params.accountCode,
                    accountName: params.accountName,
                    currentEffective: params.currentEffective,
                  };
                  const list: any[] = item.commissionPoolCustomers;
                  item.commissionPoolCustomers = this.relatedPartiesService.convertList(list, filterParams).list;
                }
              });
              res.data.content = content;
            }
          }
          return res;
        })
      );
  }
  //   https://eas-hk-dev-api.ap.manulife.com/ext/eb-ssms/commission-service/commission-scale/list?page=1&size=10
  //   https://eas-hk-dev-api.ap.manulife.com/ext/eb-ssms/commission-service/commission-scale/list?page=0&size=20

  deleteById(id: string): Observable<SimpleResponse> {
    return this.http.get<SimpleResponse>(`${environment.apiPrefix}${environment.commissionServiceExt}/commission-pool/delete/${id}`);
  }

  getById(scaleId: string): Observable<BasicResponse<any>> {
    return this.http.get<BasicResponse<any>>(`${environment.apiPrefix}${environment.commissionServiceExt}/commission-pool/info/${scaleId}`);
  }

  saveOrUpdate(id: string, params: any): Observable<SimpleResponse> {
    if (id) {
      return this.http.post<SimpleResponse>(`${environment.apiPrefix}${environment.commissionServiceExt}/commission-pool/update/${id}`, params);
    } else {
      return this.http.post<SimpleResponse>(environment.apiPrefix.concat(environment.commissionServiceExt).concat('/commission-pool/add'), params);
    }
  }

  addSeparateCommissionPool(params: any): Observable<SimpleResponse> {
    return this.http.post<SimpleResponse>(`${environment.apiPrefix}${environment.commissionServiceExt}/commission-pool/add-separate-pool`, params);
  }
}
