import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, OnChanges, forwardRef, AfterViewInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, FormControl } from '@angular/forms';
import { CdMultidropdownTopping } from './cd-multidropdown.model';

@Component({
  selector: 'app-cd-multidropdown',
  templateUrl: './cd-multidropdown.component.html',
  styleUrls: ['./cd-multidropdown.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CdMultidropdownComponent),
      multi: true,
    },
  ],
})
export class CdMultidropdownComponent implements OnInit, AfterViewInit, OnChanges, ControlValueAccessor {
  @Input() placeholder = 'pleaseChoose';
  @Input() toppingList: CdMultidropdownTopping[] = [];
  @Input() disabled = false;
  @Input() selected?: CdMultidropdownTopping[] = [];
  @Input() formControl?: FormControl;
  @Input() label = '';
  @Output() selectedChange = new EventEmitter<CdMultidropdownTopping[]>();
  open = false;
  toppings: CdMultidropdownTopping[] = [];

  constructor() {}

  ngOnInit(): void {}

  ngAfterViewInit() {
    if (this.formControl) {
      if (this.formControl.value != null) {
        this.selected = this.formControl.value;
      }
      this.formControl.valueChanges.subscribe(val => {
        this.selected = val;
      });
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['selected']) {
      this.toppings = changes['selected'].currentValue;
      this.propagateOnChange(this.toppings);
    }
  }

  onOutsideEvent() {
    if (this.open) {
      this.open = false;
    }
  }

  onToggel() {
    if (this.disabled) {
      return;
    }

    this.open = !this.open;
  }

  getCheck(t: CdMultidropdownTopping) {
    return this.toppings.some(item => item.value === t.value);
  }

  refrushToppings(t: CdMultidropdownTopping) {
    if (this.getCheck(t)) {
      this.toppings = this.toppings.filter(item => item.value !== t.value);
    } else {
      this.toppings.push(t);
    }
    this.propagateOnChange(this.toppings);
    this.selectedChange.emit(this.toppings);
    this.formControl?.setValue(this.toppings);
    this.formControl?.markAsDirty();
    this.formControl?.markAsTouched();
  }

  get getTitle() {
    if (this.toppings.length > 0) {
      return `${this.toppings[0].label} ${this.toppings[0].subLabel ? this.toppings[0].subLabel : ''}${
        this.toppings.length > 1 ? ' (' + this.toppings.length + ')' : ''
      }`;
    }

    return null;
  }

  writeValue(value: CdMultidropdownTopping[]) {
    if (value) {
      this.toppings = value;
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  propagateOnChange = (value: CdMultidropdownTopping[]) => {};

  propagateOnTouched = () => {};

  registerOnChange(fn: (value: CdMultidropdownTopping[]) => void) {
    this.propagateOnChange = fn;
  }

  registerOnTouched(fn: () => void) {
    this.propagateOnTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }
}
