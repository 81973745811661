/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgModel } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CdsButtonConfig } from '@cds/ng-core/button';
import { CdsOption } from '@cds/ng-core/configuration';
import { CdsDropdownConfig } from '@cds/ng-core/dropdown';
import { CdsLangService } from '@cds/ng-core/lang';
import { CdsTextfieldConfig } from '@cds/ng-core/textfield';
import { CdsPopupService } from '@cds/ng-web-components/popup';
import { DateTime } from 'luxon';
import moment from 'moment';
import { finalize } from 'rxjs';
import { PermissionAccess, PermissionItem } from 'src/app/core/models/enum/permission.enum';
import { LayoutHeaderService } from 'src/app/core/services/layout-header.service';
import { PermissionService } from 'src/app/core/services/permission.service';
import { AlertPopupComponent } from 'src/app/shared/alert-popup/alert-popup.component';
import { ContinuePopupComponent } from 'src/app/shared/continue-popup/continue-popup.component';
import { ColumnConfig, PageConfig } from 'src/app/shared/data-table';
import { deepCopy } from 'src/app/utils/copy';
import { AdminHeaderConfig } from '../../layout/admin-layout/admin-header/admin-header-config';
import { EditCompletedActivityComponent } from '../edit-completed-activity/edit-completed-activity.component';
import { Activity, ActivitySearchRequest, ActivitySearchRequestKey, activityTypeAllOptions, ActivityTypeE, activityTypeptions, I18N_KEY } from '../employer';
import { EmployerService } from '../employer.service';

@Component({
  selector: 'app-payroll-group-activity-record',
  templateUrl: './payroll-group-activity-record.component.html',
  styleUrls: ['./payroll-group-activity-record.component.scss'],
})
export class PayrollGroupActivityRecordComponent implements OnInit {
  @ViewChild('createStartDate') createStartDate!: NgModel;
  @ViewChild('createEndDate') createEndDate!: NgModel;

  id = '';

  activityTypeDropdownConfig: CdsDropdownConfig = {
    label: 'Activity Type',
    options: activityTypeptions,
    placeholder: 'Select Activity Type',
  };

  pageConfig: PageConfig = {
    current: 1,
    pageSize: 10,
    totalCount: 0,
  };

  searchButtonConfig: CdsButtonConfig = {
    size: 'sm',
  };

  resetButtonConfig: CdsButtonConfig = {
    size: 'sm',
    style: 'secondary',
  };

  searchButtonDisabled = true;

  resetButtonDisabled = true;

  isAllEmpty = true;

  showDatePicker = true;

  isLoading = false;

  numberOfElements = 0;

  activityTypes: CdsOption[] = activityTypeAllOptions;

  displayedColumnsWithoutPermission = ['activityCode', 'description', 'score', 'completionDate'];

  displayedColumns = [...this.displayedColumnsWithoutPermission, 'action'];

  originDataSource: Activity[] = [];

  dataSource: Activity[] = [];

  descriptionTxtfieldConfig: CdsTextfieldConfig = {
    placeholder: 'Input Activity Description',
    type: 'text',
  };

  scoreTxtfieldConfig: CdsTextfieldConfig = {
    placeholder: 'Input Activity Score',
    type: 'number',
  };

  PermissionAccess = PermissionAccess;

  PermissionItem = PermissionItem;

  I18N_KEY = I18N_KEY;

  canEdit = false;

  columnsConfig: ColumnConfig[] = [
    {
      key: 'activityCode',
      title: 'Activity Code',
    },
    { key: 'description', title: 'Decription' },
    { key: 'score', title: 'Score' },
    { key: 'completionDate', title: 'Date of Completion' },
  ];

  headerButton: AdminHeaderConfig = {
    title: 'employer.er-activity-record',
    backButton: {
      text: 'Employer Enquiry Information Page',
      url: '#',
      method: this.back.bind(this),
    },
  };

  back() {
    this.router.navigate(['/employer/profile-detail'], {
      queryParams: { id: this.id },
    });
  }

  erActivityScore = 0;

  completionActivityScore = 0;

  get nowTimeInstr() {
    return DateTime.now().toFormat('dd/MM/yyyy');
  }

  get minTime() {
    return this.getMinTime();
  }

  get minTimeInStr() {
    return DateTime.fromJSDate(this.minTime).toFormat('dd/MM/yyyy');
  }

  get maxTime() {
    return this.getNowTime();
  }

  get maxTimeInStr() {
    return DateTime.fromJSDate(this.maxTime).toFormat('dd/MM/yyyy');
  }

  searchDTO: ActivitySearchRequest = {
    page: 0,
    size: 10,
    activityCode: '',
    description: '',
    fromDate: this.minTimeInStr,
    toDate: this.maxTimeInStr,
    score: '',
    activityType: ActivityTypeE.ALL,
  };

  searchParams: ActivitySearchRequest = deepCopy(this.searchDTO);

  constructor(
    public employerService: EmployerService,
    private cdsPopup: CdsPopupService,
    private langService: CdsLangService,
    private permissionService: PermissionService,
    private layoutHeaderService: LayoutHeaderService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    document.getElementsByClassName('cds-navigation-content')[0].scrollTop = 0;
    this.layoutHeaderService.setHeaderButton(this.headerButton);

    this.permissionService.hasPermission(PermissionAccess.W, PermissionItem.CUSTOMER_EMPLOYER_ACTIVITY_CODE).then(hasPermission => {
      this.canEdit = hasPermission;
      if (hasPermission) {
        this.columnsConfig = [...this.columnsConfig, { key: 'action', title: 'Action' }];
      }
    });

    this.id = this.activatedRoute.snapshot.queryParams['id'];

    this.loadTable();
  }

  searchChange(event: any, key: ActivitySearchRequestKey) {
    const val = event?.target?.value;
    this.searchDTO[key] = val;
    this.validating();
  }

  activityChange(value: ActivityTypeE) {
    this.searchDTO.activityType = value;
    this.validating();
  }

  searchButtonDisabledFn = (activityCodeValid: any, description: any, createStartDate: any, createEndDate: any, score: any, isAllEmpty: any) => {
    if (isAllEmpty) {
      this.resetButtonDisabled = true;
      return true;
    } else {
      const invalid = [activityCodeValid, description, createStartDate, createEndDate, score].some(item => !item);
      this.searchButtonDisabled = invalid;

      return this.searchButtonDisabled;
    }
  };

  validating() {
    if (
      !!this.searchDTO.activityCode ||
      !!this.searchDTO.fromDate ||
      !!this.searchDTO.toDate ||
      !!this.searchDTO.description ||
      !!this.searchDTO.score ||
      !!this.searchDTO.activityType
    ) {
      this.isAllEmpty = false;
    } else {
      this.isAllEmpty = true;
    }
  }

  getMaxFromDate = (toDateStr?: string) => {
    if (!toDateStr) {
      return this.getNowTime();
    }
    return moment(toDateStr, 'DD/MM/YYYY').toDate();
  };

  getMinToDate = (fromDateStr = '01/01/0000') => {
    return moment(fromDateStr, 'DD/MM/YYYY').toDate();
  };

  getMinTime() {
    return moment().subtract(5, 'year').add(1, 'day').toDate();
  }

  getNowTime() {
    return moment().toDate();
  }

  search = () => {
    if (this.searchButtonDisabled) {
      return;
    }
    this.pageConfig = {
      current: 1,
      pageSize: 10,
      totalCount: 0,
    };
    this.searchParams = deepCopy(this.searchDTO);
    this.loadTable();
    this.resetButtonDisabled = false;
  };

  reset = () => {
    if (this.resetButtonDisabled) {
      return;
    }
    this.searchDTO = {
      page: 0,
      size: 10,
      activityCode: '',
      fromDate: this.minTimeInStr,
      toDate: this.maxTimeInStr,
      description: '',
      score: '',
      activityType: undefined,
    };
    this.searchButtonDisabled = true;
    this.resetButtonDisabled = true;

    this.pageConfig = {
      current: 1,
      pageSize: 10,
      totalCount: 0,
    };

    this.searchParams = deepCopy(this.searchDTO);

    this.showDatePicker = false;
    setTimeout(() => {
      this.showDatePicker = true;
      this.loadTable();
      this.validating();
    });
  };

  getRequestParams() {
    const params: ActivitySearchRequest = {
      ...this.searchParams,
      page: (this.pageConfig.current as number) - 1,
      size: this.pageConfig.pageSize as number,
    };

    params.fromDate = params.fromDate ? this.dateTrans(params.fromDate) : '';
    params.toDate = params.toDate ? this.dateTrans(params.toDate) : '';
    params.employerId = this.id;
    return params;
  }

  dateTrans(date: string) {
    if (date) {
      return moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD');
    }

    return date;
  }

  loadTable() {
    const params: ActivitySearchRequest = this.getRequestParams();
    this.getCompletionActivityScore(params);
    this.getErActivityScore(this.id as string);

    this.isLoading = true;
    this.employerService
      .getActivityCompletionList(params)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe(data => {
        if (data.result === 0 && data.data) {
          this.isLoading = false;
          this.dataSource = data.data.empty ? [] : data.data.content;
          // this.originDataSource = deepCopy(this.dataSource);
          this.numberOfElements = data.data.totalElements;

          this.pageConfig = {
            ...this.pageConfig,
            totalCount: data.data.totalElements,
          };
        }
      });
  }

  add() {
    this.cdsPopup
      .open(EditCompletedActivityComponent, {
        size: 'lg',
        data: {
          employerId: this.id,
        },
      })
      .afterClosed()
      .subscribe(result => {
        if (result?.agree) {
          this.employerService
            .addCompletionActivity(result.data)
            .pipe(
              finalize(() => {
                this.isLoading = false;
              })
            )
            .subscribe(res => {
              if (res.result === 0) {
                this.popupWithReload();
              }
            });
        } else {
          this.isLoading = false;
        }
      });
  }

  edit(activity: Activity) {
    this.cdsPopup
      .open(EditCompletedActivityComponent, {
        size: 'lg',
        data: {
          isEdit: true,
          activity,
          employerId: this.id,
        },
      })
      .afterClosed()
      .subscribe(result => {
        if (result?.agree) {
          this.employerService
            .editCompletionActivity(result.data)
            .pipe(
              finalize(() => {
                this.isLoading = false;
              })
            )
            .subscribe(res => {
              if (res.result === 0) {
                this.popupWithReload();
              }
            });
        } else {
          this.isLoading = false;
        }
      });
  }

  changeEditStaus(activityCode: string) {
    this.dataSource.forEach(item => {
      if (item.activityCode === activityCode) {
        item.inEdit = true;
      } else {
        item.inEdit = false;
      }
    });

    this.dataSource = [...this.dataSource];
  }

  delete(id: string) {
    this.cdsPopup
      .open(ContinuePopupComponent, {
        size: 'sm',
        data: {
          title: 'Are you sure you want to delete this activity ?',
          continueButtonName: this.langService.translate('common.delete'),
        },
      })
      .afterClosed()
      .subscribe(result => {
        if (result?.agree) {
          this.isLoading = true;
          this.employerService
            .deleteActivity(id)
            .pipe(
              finalize(() => {
                this.isLoading = false;
              })
            )
            .subscribe({
              next: res => {
                if (res.result === 0) {
                  this.popupWithReload();
                }
              },
            });
        } else {
          this.isLoading = false;
        }
      });
  }

  save = (id: string, activityCode: string, description: string, score: number) => {
    const ifSaveButtonDisabled = this.ifSaveButtonDisabled(activityCode, description, score);
    if (ifSaveButtonDisabled) {
      return;
    }
    this.doSave(id, activityCode);
  };

  doSave(id: string, activityCode: string) {
    const lineData = this.getLineData(activityCode, this.dataSource);
    const params: Activity = {
      id,
      description: lineData?.description,
      score: lineData?.score,
    };
    this.isLoading = true;

    this.employerService
      .editActivity(params)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe({
        next: res => {
          if (res.result === 0) {
            if (lineData) {
              lineData.inEdit = false;
            }
            this.popupWithReload();
          }
        },
      });
  }

  ifResetButtonDisabled = (activityCode: string, description: string, score: number) => {
    const originLineData = this.getLineData(activityCode, this.originDataSource);
    if (originLineData && description === originLineData.description && score === originLineData.score) {
      return true;
    }
    return false;
  };

  getLineData(activityCode: string, dataSource: Activity[]) {
    if (!activityCode || !dataSource) {
      return null;
    }
    const lineData = dataSource.find(lineData => lineData.activityCode === activityCode);
    return lineData;
  }

  ifSaveButtonDisabled = (activityCode: string, description: string, score: number) => {
    const ifResetButtonDisabled = this.ifResetButtonDisabled(activityCode, description, score);
    if (ifResetButtonDisabled) {
      return true;
    }
    if (description && score) {
      return false;
    }
    return true;
  };

  getErActivityScore(id: string) {
    this.employerService.getErActivityScore(id).subscribe(data => {
      if (data.result === 0) {
        this.erActivityScore = data.data as number;
      }
    });
  }

  getCompletionActivityScore(params: ActivitySearchRequest) {
    this.employerService.getCompletionActivityScore(params).subscribe(data => {
      if (data.result === 0) {
        this.completionActivityScore = data.data as number;
      }
    });
  }

  popupWithReload() {
    this.cdsPopup
      .open(AlertPopupComponent, {
        size: 'sm',
        data: {
          message: this.langService.translate('common.action.success'),
          buttonName: this.langService.translate('common.gotItWithExclamation'),
        },
      })
      .afterClosed()
      .subscribe(() => {
        this.loadTable();
      });
  }

  reloadTable(event: any) {
    this.pageConfig = {
      ...this.pageConfig,
      current: event.current,
    };

    this.loadTable();
  }
}
