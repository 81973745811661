<div class="row l-pt-5">
  <div class="col-xs-12">
    <div class="l-mb-7 inner-html-des" innerHTML="{{ 'notification.list.htmlDes' | lang }}"></div>

    <div class="l-mb-9">
      <div class="cds-body2 l-mb-0">
        {{ 'notification.audience.choose' | lang }}
      </div>

      <div class="audience-area l-mb-4">
        <cds-dropdown
          class="dropdown l-mb-2"
          [formControl]="audienceFormControl"
          [config]="notificationService.audienceDropdownConfig"
          (change)="audienceDropdownChange()"
          label="{{ 'notification.audience.choose' | lang }}">
        </cds-dropdown>
        <app-permission [role]="PermissionAccess.W" [permission]="PermissionItem.CUSTOMER_MOBILE_SUBSCRIPTION">
          <cds-button class="button l-mb-2" (click)="manageAudience()" *ngIf="audienceFormControl.value !== notificationService.mobileSubscriptionList">
            {{ 'notification.audience.manage' | lang }}
          </cds-button>
        </app-permission>
      </div>

      <div *ngIf="audienceFormControl.value === notificationService.mobileSubscriptionList" class="target-audience">
        <div class="number">
          Subscribed Members: <span>{{ targetAudienceNo | number }}</span>
        </div>
        <div class="update-time">
          Latest update:
          <span>{{ targetAudienceNoUpdateTime ? targetAudienceNoUpdateTime + '(HKT)' : '' }}</span>
        </div>
      </div>
    </div>

    <div class="n-operation">
      <div class="n-record">
        <div class="cds-h2-light n-o-des v-center">
          {{ 'notification.record.list' | lang }}
        </div>
        <div class="cds-body1 n-o-record-count">
          {{ 'notification.record.total' | lang }} {{ totalCount }}
          {{ 'notification.record.total.des' | lang }}
        </div>
      </div>

      <app-permission [role]="PermissionAccess.W" [permission]="PermissionItem.CUSTOMER_MOBILE_SUBSCRIPTION">
        <div class="n-o-btn v-center">
          <cds-button (click)="createMessage()" [config]="config"></cds-button>
        </div>
      </app-permission>
    </div>

    <!-- <cds-progress
      *ngIf="showProgress"
      mode="indeterminate"
      type="bar"></cds-progress> -->

    <app-loading *ngIf="showProgress" [overlayInPopup]="true"></app-loading>

    <app-no-result-found *ngIf="!totalCount && !showProgress"></app-no-result-found>

    <ng-container *ngIf="totalCount">
      <div class="m-content">
        <div class="m-content-list" *ngFor="let item of summaryList; index as i">
          <div class="m-content-click" (click)="doClickDetailMobile($event, item)">
            <div class="m-content-top-area">
              <div class="cds-body1-demibold m-content-txt-area line-break">
                <div class="m-area-txt">{{ item.subjectEn }}</div>
                <div class="m-area-txt">{{ item.subjectCn }}</div>
              </div>
              <div class="m-area-icon backrotate">
                <cds-icon icon="action:up" width="32px" height="32px"></cds-icon>
              </div>
            </div>
            <div class="cds-body2 m-txt-grey">
              {{ 'notification.create.date' | lang }} :
              {{ formateDate(item.createDate) }}
            </div>
            <div class="cds-body2 m-txt-grey">
              {{ 'confirm.targetBroadcastDate&Time' | lang }} : {{ formateDate(item.targetBroadcastDate) }},
              {{ formateDateToTime(item.targetBroadcastDate) }}
            </div>
            <div class="n-c-line" style="margin-top: 12px"></div>
            <div class="cds-body1 m-txt v-center">{{ 'confirm.category' | lang }} : {{ ca.get(item.category) }}</div>
            <div class="cds-body1 m-txt v-center">{{ 'confirm.priority' | lang }} : {{ item.priority }}</div>
            <div class="m-txt v-center">{{ 'notification.Status' | lang }} : {{ item.recordStatus }}</div>
          </div>

          <div *ngIf="item.isShowDetail" class="m-content-detail line-break">
            <div class="n-c-line" style="margin-top: 12px"></div>

            <div class="cds-body2 detail-title v-center">
              {{ 'create.messageDetails' | lang }}
            </div>
            <div class="cds-body1 detail-content v-center">
              {{ item.messageBodyEn }}
            </div>
            <div class="cds-body1 detail-content v-center">
              {{ item.messageBodyCn }}
            </div>
            <div class="cds-body2 detail-title v-center">
              {{ 'notification.url' | lang }}
            </div>
            <div class="cds-body1 detail-content v-center">
              {{ item.deepLinkUrl }}
            </div>

            <div class="cds-body2 detail-title v-center">
              {{ 'confirm.pushCampaignId' | lang }}
            </div>
            <div class="cds-body1 detail-content v-center">
              {{ item.pushCampaignId }}
            </div>

            <ng-container *ngIf="showTargetAudienceNo | fn : item.category : item.recordStatus">
              <div class="cds-body2 detail-title v-center">
                {{ 'notification.targetAudienceNo' | lang }}
              </div>
              <div class="cds-body1 detail-content v-center">
                {{ item.targetAudienceNo | number }}
              </div>
            </ng-container>

            <app-permission [role]="PermissionAccess.E" [permission]="PermissionItem.CUSTOMER_MOBILE_SUBSCRIPTION">
              <div *ngIf="item.recordStatus === 'Pending' && item.showRecall" class="opration">
                <div class="o-icon">
                  <cds-icon icon="form:remove_file" size="sm"></cds-icon>
                </div>
                <div class="o-des" (click)="recallMessage(item)">
                  <span class="cds-body2-demibold">{{ 'notification.recall.message' | lang }}</span>
                </div>
              </div>
            </app-permission>
          </div>
        </div>
        <!-- <div class="n-pagenation v-center">
          <div class="pagenation-area">
            <div class="page-choose">
              <cds-pagination
                [pageCount]="pageCount"
                [color]="color"
                (pageChange)="pageChange($event)"
                [(page)]="pageindex"
              ></cds-pagination>
            </div>
            <div class="page-input">
              <div style="margin-right: 10px" class="page-input-text">
                {{ "notification.page" | lang }}
              </div>

              <cds-textfield
                [config]="txtfieldConfig"
                #pageNoSearchValue
                [(ngModel)]="pageindex"
                (change)="searchByInputPageNo($event, pageNoSearchValue.value)"
              ></cds-textfield>

              <div style="margin-left: 10px" class="page-input-text">
                of {{ pageCount }}
              </div>
            </div>
          </div>
        </div> -->
      </div>

      <div class="cds-h6 n-t-title-area row v-center">
        <div class="n-t-title col-sm-1"></div>
        <div class="n-t-title col-sm-2">
          {{ 'notification.create.date' | lang }}
        </div>
        <div class="n-t-title col-sm-2">
          {{ 'confirm.targetBroadcastDateAndTime' | lang }}
        </div>
        <div class="n-t-title col-sm-3">
          {{ 'notification.push.subject' | lang }}
        </div>
        <div class="n-t-title col-sm-2">{{ 'confirm.category' | lang }}</div>
        <div class="n-t-title col-sm-1">{{ 'confirm.priority' | lang }}</div>
        <div class="n-t-title col-sm-1">{{ 'notification.Status' | lang }}</div>
      </div>
      <div class="n-t-content">
        <div class="n-t-content-area" *ngFor="let item of summaryList; index as i">
          <div (click)="doClickDetail($event, item)" class="cds-body1 n-t-content-short-des v-center row line-break">
            <div class="n-icon-area col-sm-1">
              <div class="icon backrotate">
                <cds-icon icon="action:button_right" width="32px" height="32px"></cds-icon>
              </div>
            </div>
            <div class="n-short-des col-sm-2">
              {{ formateDate(item.createDate) }}
            </div>
            <div class="n-short-des col-sm-2">
              <div>{{ formateDate(item.targetBroadcastDate) }},</div>
              <div>{{ formateDateToTime(item.targetBroadcastDate) }}</div>
            </div>
            <div class="n-short-des col-sm-3">
              <div class="des-en">{{ item.subjectEn }}</div>
              <div class="des-ch">{{ item.subjectCn }}</div>
            </div>
            <div class="n-short-des col-sm-2">{{ ca.get(item.category) }}</div>
            <div class="n-short-des col-sm-1">{{ item.priority }}</div>
            <div class="n-short-des col-sm-1">{{ item.recordStatus }}</div>
          </div>
          <div *ngIf="item.isShowDetail" class="n-t-content-detail-des row line-break">
            <div class="col-sm-1"></div>
            <div class="col-sm-11 n-detail-content">
              <div
                class="preview-button"
                *ngIf="audienceFormControl.value !== notificationService.mobileSubscriptionList"
                (click)="previewAudienceList(item.id, audienceFormControl.value)">
                <cds-icon class="l-mr-2" icon="action:button_right_filled" [config]="previewButtonCfg"></cds-icon>
                <span class="cds-body2-demibold">Preview Audience List</span>
              </div>

              <div class="detail-title v-center cds-body2">
                {{ 'create.messageDetails' | lang }}
              </div>
              <div class="detail-msg v-center cds-body1">
                {{ item.messageBodyEn }}
              </div>
              <div class="detail-msg v-center cds-body1">
                {{ item.messageBodyCn }}
              </div>
              <div class="detail-title v-center cds-body2">
                {{ 'notification.url' | lang }}
              </div>

              <div class="detail-msg v-center cds-body1">
                {{ item.deepLinkUrl }}
              </div>

              <div class="row l-lrp-0">
                <div class="col-xs-12 col-sm-6 l-lrp-0">
                  <div class="cds-body2 detail-title v-center">
                    {{ 'confirm.pushCampaignId' | lang }}
                  </div>
                  <div class="cds-body1 detail-content v-center">
                    {{ item.pushCampaignId }}
                  </div>
                </div>

                <div class="col-xs-12 col-sm-6 l-lrp-0" *ngIf="showTargetAudienceNo | fn : item.category : item.recordStatus">
                  <div class="cds-body2 detail-title v-center">
                    {{ 'notification.targetAudienceNo' | lang }}
                  </div>
                  <div class="cds-body1 detail-content v-center">
                    {{ item.targetAudienceNo | number }}
                  </div>
                </div>
              </div>

              <app-permission [role]="PermissionAccess.W" [permission]="PermissionItem.CUSTOMER_MOBILE_SUBSCRIPTION">
                <div *ngIf="item.recordStatus === 'Pending' && item.showRecall" class="opration">
                  <div class="o-icon">
                    <cds-icon icon="form:remove_file" size="sm"></cds-icon>
                  </div>
                  <div class="o-des" (click)="recallMessage(item)">
                    <span class="cds-body2-demibold">{{ 'notification.recall.message' | lang }}</span>
                  </div>
                </div>
              </app-permission>
            </div>
          </div>
        </div>
      </div>
      <div class="n-pagenation v-center row">
        <div class="col-sm-1"></div>
        <div class="pagenation-area col-sm-11">
          <div class="page-choose">
            <cds-pagination [pageCount]="pageCount" [color]="color" (pageChange)="pageChange($event)" [page]="pageindex"></cds-pagination>
          </div>
          <div class="page-input">
            <div style="margin-right: 10px" class="page-input-text">
              {{ 'notification.page' | lang }}
            </div>

            <input
              [(ngModel)]="pageindexInput"
              type="number"
              class="input"
              #pageNoSearchValue
              (keydown.enter)="searchByInputPageNo(+pageNoSearchValue.value)" />

            <div style="margin-left: 10px" class="page-input-text">of {{ pageCount }}</div>
          </div>
        </div>
      </div>

      <div class="n-c-line last-line"></div>
    </ng-container>
  </div>
</div>
