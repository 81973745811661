import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-cd-step',
  templateUrl: './cd-step.component.html',
  styleUrls: ['./cd-step.component.scss'],
})
export class CdStepComponent implements OnInit {
  @Input() label!: string;
  private isFinish = false;

  constructor() {}

  ngOnInit(): void {}

  setLabel(val: string) {
    Promise.resolve().then(() => {
      this.label = val;
    });
  }

  setFinish() {
    this.isFinish = true;
  }

  setUnfinished() {
    this.isFinish = false;
  }

  get finishStatus() {
    return this.isFinish;
  }
}
