/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, CDS_POPUP_DATA, CdsPopupService } from '@cds/ng-web-components/popup';
import { CdsButtonConfig } from '@cds/ng-core/button';
import { CommissionAgentService } from 'src/app/core/services/commission/commission-agent.service';
import { CommissionPoolService } from 'src/app/core/services/commission/commission-pool.service';

import { Agent } from '../agent.model';
import { texts } from '../../pool-info.model';
import { AgentChannel } from 'src/app/core/models/enum/agent-channel.enum';

@Component({
  selector: 'app-agent-add-alert',
  templateUrl: './agent-add-alert.component.html',
  styleUrls: ['./agent-add-alert.component.scss'],
})
export class AgentAddAlertComponent implements OnInit {
  constructor(
    private cdsPopup: CdsPopupService,
    private agentService: CommissionAgentService,
    private poolService: CommissionPoolService,
    private dialogRef: MatDialogRef<AgentAddAlertComponent>,
    @Inject(CDS_POPUP_DATA)
    public data: {
      title: string;
      message: string;
      cancel: string;
      continue: string;
      type: string;
      commissionPoolId: string;
      agentList: any[];
    }
  ) {
    this.commissionPoolId = data.commissionPoolId;
  }

  agentCode = '';
  errorMsg = '';
  disableAdd = true;
  loading = false;
  commissionPoolId = '';
  waiveMonthlyBonus = false;
  agent: Agent = {};
  disagreeButtonConfig: CdsButtonConfig = { size: 'sm', style: 'secondary' };
  agreeButtonConfig: CdsButtonConfig = { size: 'sm' };

  input(event: any) {
    event.target.value = event.target.value.replace(/[^\d]+/g, '');
    if (event.keyCode == 13 || event.target.value.length >= 6) {
      this.change(event.target.value, event);
    }
  }

  change(filterValue: string, event: any) {
    this.errorMsg = '';
    this.agent = {};
    this.disableAdd = true;
    if (filterValue) {
      filterValue = filterValue.trim();
      event.target.value = filterValue;
    }
    if (filterValue) {
      this.loading = true;
      if (this.data.agentList) {
        this.data.agentList.forEach(item => {
          if (item.agentCode && item.agentCode == filterValue) {
            this.errorMsg = texts.invalidAgentCode;
          }
        });
        if (this.errorMsg) {
          this.loading = false;
          return;
        }
      }
      this.agentService.getAgentByCode(filterValue).subscribe(resp => {
        this.loading = false;
        if (resp && resp.result == 0 && resp.data) {
          this.agent = resp.data;
          if (resp.data.agentChannel != AgentChannel.BROKER && this.waiveMonthlyBonus) {
            this.errorMsg = texts.nonBrokerChannel;
            return;
          }
          this.disableAdd = false;
          return;
        }
        this.errorMsg = texts.invalidAgentCode;
      });
    }
  }

  agree() {
    this.dialogRef.close({ agree: true, agent: this.agent });
  }
  disagree() {
    this.dialogRef.close({ agree: false });
  }

  add() {
    if (this.disableAdd) {
      return;
    }
    this.agree();
  }

  ngOnInit() {
    this.loading = true;
    this.poolService.getById(this.commissionPoolId).subscribe(resp => {
      this.loading = false;
      if (resp && resp.data && resp.result == 0) {
        this.waiveMonthlyBonus = resp.data.waiveMonthlyBonus;
      }
    });
  }
}
