<div class="add-to-item">
  <span class="cds-body-hero">{{ 'delegationChange.inputTheOtherReasonOfChange' | lang }}</span>
  <div class="textarea">
    <app-cd-textarea
      [(value)]="text"
      [maxlength]="100"
      [height]="'96px'"
      fontSize="1.1rem"
      [placeholder]="'delegationChange.textInputGoesHere' | lang"
      [label]="'delegationChange.otherReason' | lang">
    </app-cd-textarea>
  </div>
  <div class="botton-list">
    <cds-button (click)="cancel()" [style]="'secondary'" size="sm">{{ 'confirmation.cancel' | lang }}</cds-button>
    <cds-button [disabled]="disabledContinue" (click)="continue()" [style]="'primary'" size="sm">{{ 'delegationChange.continue' | lang }} </cds-button>
  </div>
</div>
