<div class="col-xs-12 l-mb-2 container row l-plr-0">
  <div class="l-pb-4 l-pt-4 col-xs-12" [class.line-bord-show]="expand">
    <div (click)="trigger()" class="pointer title-container">
      <div class="expand-icon">
        <cds-icon [width]="size" [height]="size" [@openClose]="_iconClass" [config]="_config" [icon]="icon"></cds-icon>
      </div>

      <div class="l-pt-1">
        <div class="line-break cds-body1-demibold font-weight">
          {{ data.empfMemberAccountNumber }}
          <span>{{ data.firstName }}</span
          >&nbsp;<span>{{ data.lastName }}</span
          >&nbsp;
          <span *ngIf="data.chineseFirstName || data.chineseLastName">
            <span>{{ data.chineseLastName }}</span
            >&nbsp;<span>{{ data.chineseFirstName }}</span>
          </span>
        </div>
      </div>
    </div>

    <div class="line-bord-show l-pb-2">
      <app-row-item
        label="list.employer-account-code-name"
        [data]="data.trusteeEmployerAccountNumber + ' ' + (data.companyName || '') + (data.chineseCompanyName ? ' ' + data.chineseCompanyName + ' ' : '')"
        class="l-pt-4 l-pb-4">
      </app-row-item>
    </div>

    <div class="l-pt-4 cds-body2">
      <div>
        {{ 'termination.list.terminationDate' | lang }}:
        <span>{{ data.terminationDate | date : 'dd/MM/yyyy' }}</span>
      </div>
    </div>

    <div class="cds-body2">
      <div>
        {{ 'termination.list.dateOfAssignment' | lang }}:
        <span>{{ data.dateOfAssignment | date : 'dd/MM/yyyy' }}</span>
      </div>
    </div>

    <div class="cds-body2">
      <div>
        {{ 'termination.list.agentFollowUpStatus' | lang }}:
        <span>{{ data.agentFollowUpStatus }}</span>
      </div>
    </div>

    <div class="cds-body2">
      <div>
        {{ 'termination.list.accountStatus' | lang }}:
        <span>{{ data.accountStatus }}</span>
      </div>
    </div>
  </div>

  <div [@openClose]="_detailClass" class="col-xs-12">
    <div class="l-mt-4 l-mb-2">
      <span class="cds-body1-ita font-weight font-italic">{{ 'termination.list.detailTitle' | lang }}</span>
    </div>

    <app-row-item label="termination.list.employeeAge" data="{{ data.age }}" class="l-pt-4 l-pb-4"> </app-row-item>

    <app-row-item label="termination.list.employeeGender" data="{{ data.gender }}" class="l-pt-4 l-pb-4"> </app-row-item>

    <app-row-item label="termination.dateOfBirth" data="{{ data.dateOfBirth | date : 'dd/MM/yyyy' }}" class="l-pt-4"> </app-row-item>

    <app-row-item
      label="termination.transferAmount"
      data="{{ data.transferAmount !== undefined && data.transferAmount !== null ? 'HKD ' + (data.transferAmount | number : '1.2-2') : '' }}"
      class="l-pb-4">
    </app-row-item>

    <app-row-item
      label="termination.list.employeeMobileNo"
      data="{{ '+(' + data.primaryMobileNumberCountryCode + ')' + data.primaryMobileNumber }}"
      class="l-pt-4 l-pb-4">
    </app-row-item>

    <app-row-item label="termination.list.employeeEmailAddress" data="{{ data.emailAddress }}" class="l-pt-4 l-pb-4"> </app-row-item>

    <app-row-item label="termination.autoRolloverDate" data="{{ data.autoRolloverDate | date : 'dd/MM/yyyy' }}" class="l-pt-4"> </app-row-item>

    <app-row-item label="termination.preassignedAgent" data="{{ data.preassignedAgentCode + ' ' + data.preassignedAgentName }}" class="l-pb-4"> </app-row-item>

    <app-row-item
      label="termination.servicingAgent"
      data="{{ service.joinName | fn : data.servicingAgentCode : ' ' : data.servicingAgentName }}"
      class="l-pb-4">
    </app-row-item>
  </div>
</div>
