import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TrusteeDetailsPopupComponent } from './trustee-details-popup.component';
import { CdsButtonModule } from '@cds/ng-web-components/button';
import { CdsLanguageModule } from '@cds/ng-core/lang';
import { SharedModule } from 'src/app/shared/shared.module';

@NgModule({
  declarations: [TrusteeDetailsPopupComponent],
  imports: [CommonModule, CdsButtonModule, CdsLanguageModule, SharedModule],
})
export class TrusteeDetailsPopupModule {}
