<div class="row div-border-bottom">
  <div class="col-xs-12 l-pt-6 bakc-row l-pb-3" *ngIf="showBack">
    <div class="l-d-f l-ai-cen pointer" (click)="goBack()">
      <cds-icon size="sm" color="#ff6666" icon="action:button_left_filled"></cds-icon>
      <span class="cds-body2-demibold l-ml-2">
        {{ backLandingLabel | lang }}
      </span>
    </div>
  </div>
  <div class="col-xs-12 title-row">
    <div class="page-title l-pt-6 l-pb-6" [class.border-right]="rightContent">
      <span class="cds-h4-demibold">{{ title }}</span>
      <div *ngIf="pageAction" class="action l-pl-4">
        <ng-content *ngTemplateOutlet="pageAction"></ng-content>
      </div>
    </div>
    <div class="l-pl-4 l-pr-4" *ngIf="rightContent">
      <ng-content *ngTemplateOutlet="rightContent"></ng-content>
    </div>
  </div>
</div>
