<div class="row col-xs-12 col-md-12 l-pb-6 l-pt-6 background-grey">
  <div class="l-pl-7">
    <p class="cds-h5-light l-pb-2 papending-fontbold">Individuals IFF Pending Status</p>

    <div>
      <span class="papending-fontbold">{{ data.productType }}</span>
    </div>
    <div class="papending-align 1-pt-2">
      <div style="display: flex">
        <div class="papending-lightalign">
          <div class="light orange"></div>
        </div>
        <div>
          <span>Pending to send :</span>
        </div>
      </div>
      <span>{{ detailsByProductType.pendingsend }}</span>
    </div>
    <div class="papending-align 1-pt-2">
      <div style="display: flex">
        <div class="papending-lightalign">
          <div class="light green"></div>
        </div>
        <div>
          <span>Pending customer submission :</span>
        </div>
      </div>
      <span>{{ detailsByProductType.pendingcustomer }}</span>
    </div>
    <div class="papending-align 1-pt-2">
      <div style="display: flex">
        <div class="papending-lightalign">
          <div class="light blue"></div>
        </div>
        <div>
          <span>Pending 2nd agent :</span>
        </div>
      </div>
      <span>{{ detailsByProductType.pendingAgent }}</span>
    </div>

    <div class="row l-pt-6 col-xs-12 col-md-12 l-d-f l-jc-sb">
      <div></div>
      <cds-button label="Got it" (click)="gotIt()" [config]="clearButton"></cds-button>
    </div>
  </div>
</div>
