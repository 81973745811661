<div class="bg">
  <cds-popup class="relative no-close-icon">
    <app-loading *ngIf="isLoading" [overlayInPopup]="true"></app-loading>

    <cds-popup-title
      ><div class="close-icon" (click)="close()">
        <cds-icon icon="action:cross"></cds-icon></div
    ></cds-popup-title>
    <div class="row col-xs-12 l-plr-0 middle-xs l-pb-4">
      <div class="l-pr-6">
        <span class="cds-h2">{{ 'offer.special-offer-arrangement' | lang }}</span>
      </div>

      <app-permission [role]="PermissionAccess.W" [permission]="PermissionItem.CUSTOMER_EMPLOYER_ADDITIONAL_INFORMATION">
        <div class="icon-area l-mt-1" (click)="editTrigger()">
          <div class="l-pr-2 pointer">
            <cds-icon color="#282B3E" width="20px" height="20px" icon="action:edit"></cds-icon>
          </div>
          <div class="pointer">
            <span class="cds-h6-demibold">{{ (_isEdit ? 'common.cancel.editing' : 'common.edit') | lang }}</span>
          </div>
        </div>
      </app-permission>
    </div>

    <div class="min-height" *ngIf="!formReady"></div>

    <div class="div-bg-white border" [formGroup]="form" *ngIf="formReady">
      <div class="row">
        <div class="col-xs-12 l-pt-5"></div>
        <div class="row col-xs l-lrp-0 l-jc-sb nowrap">
          <div class="l-plr-0">
            <div class="row l-plr-0">
              <div class="title-icon">
                <cds-icon width="40px" height="40px" icon="action:date_range"></cds-icon>
              </div>
              <div class="col-xs">
                <div class="row l-plr-0">
                  <div class="col-xs-12">
                    <div class="">
                      <span class="cds-h4-demibold">{{ data.employerProfileDetail?.companyName || '&nbsp;' }}</span>
                    </div>
                    <div class="margin-b-1">
                      <span class="cds-body1-demibold">{{ data.employerProfileDetail?.companyChineseName || '&nbsp;' }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row col-xs-12 l-plr-0 p-line l-pt-2 l-pb-2"></div>

      <div class="l-pa-4 l-pl-7 l-pt-6">
        <div class="row col-xs-12 border l-pa-4">
          <cds-checkbox class="l-pr-2 l-pl-5" formControlName="multiNationalClient" (change)="multiNationalClientChange($event)"></cds-checkbox>
          <div class="cds-color-green">
            <span class="cds-body2">Multi-National Client</span>
          </div>
        </div>
        <div class="row border border-top-none l-pt-4 l-pb-4">
          <div class="col-xs-12">
            <span class="cds-body1-ita">{{ 'offer.handled-by' | lang }}</span>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 l-pt-2 l-pb-2">
            <app-dynamic-input-autocomplete
              label="{{ 'offer.relationship-manager' | lang }}"
              [requestUrl]="requestUrl"
              [requestParams]="requestParams"
              optionKey="userName"
              searchKey="searchString"
              formControlName="multiRelationshipManager"
              [placeholder]="_isEdit && !multiRelationshipManager.disabled ? 'Input Relationship Manager' : ''"
              [editMode]="_isEdit"></app-dynamic-input-autocomplete>
            <ng-container *ngIf="_isEdit">
              <cds-assistive-text *ngIf="multiRelationshipManager?.errors?.['required']" class="l-pt-2" [showIcon]="false">
                Missing Relationship Manager
              </cds-assistive-text>
              <cds-assistive-text *ngIf="multiRelationshipManager?.errors?.['maxlength']" class="l-pt-2" [showIcon]="false">
                The maximum length is 200
              </cds-assistive-text>
              <cds-assistive-text *ngIf="multiRelationshipManager?.errors?.['minlength']" class="l-pt-2" [showIcon]="false">
                The minimum length is 2
              </cds-assistive-text>
              <cds-assistive-text *ngIf="multiRelationshipManager?.errors?.['userNameInvalid']" class="l-pt-2" [showIcon]="false">
                {{ multiRelationshipManager.errors?.['userNameInvalid'] | lang }}
              </cds-assistive-text>
            </ng-container>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 l-pt-2 l-pb-2">
            <app-dynamic-input-autocomplete
              label="{{ 'offer.relationship-team-manager' | lang }}"
              [requestUrl]="requestUrl"
              [requestParams]="requestParams"
              optionKey="userName"
              searchKey="searchString"
              formControlName="multiRelationshipTeamManager"
              [placeholder]="_isEdit && !multiRelationshipTeamManager.disabled ? 'Input Relationship Team Manager' : ''"
              [editMode]="_isEdit"></app-dynamic-input-autocomplete>
            <ng-container *ngIf="_isEdit">
              <cds-assistive-text *ngIf="multiRelationshipTeamManager?.errors?.['required']" class="l-pt-2" [showIcon]="false">
                Missing Relationship Team Manager
              </cds-assistive-text>
              <cds-assistive-text *ngIf="multiRelationshipTeamManager?.errors?.['maxlength']" class="l-pt-2" [showIcon]="false">
                The maximum length is 200
              </cds-assistive-text>
              <cds-assistive-text *ngIf="multiRelationshipTeamManager?.errors?.['minlength']" class="l-pt-2" [showIcon]="false">
                The minimum length is 2
              </cds-assistive-text>
              <cds-assistive-text *ngIf="multiRelationshipTeamManager?.errors?.['userNameInvalid']" class="l-pt-2" [showIcon]="false">
                {{ multiRelationshipTeamManager.errors?.['userNameInvalid'] | lang }}
              </cds-assistive-text>
            </ng-container>
          </div>
        </div>
      </div>

      <div class="row l-pt-4" formArrayName="providerNames">
        <div class="col-xs-12 cds-color-green">
          <span class="cds-h5-demibold">{{ 'offer.multi-service-provider' | lang }}</span>
        </div>
        <!-- <div class="row col-xs-12 l-pt-4" *ngIf="!providerNames?.controls?.length">
          No Multi-Service Provider can be found.
        </div> -->
        <div class="row col-xs-12 l-pt-4" *ngIf="providerNames?.controls?.length || true">
          <div class="row col-xs-12 l-plr-0 l-pt-4 l-pb-4 div-border-bottom div-border-top">
            <div class="l-pl-4 l-pr-4">#</div>
            <div class="col-xs">
              <span class="cds-body1">Provider Name</span>
            </div>
          </div>
          <div
            *ngFor="let item of providerNames?.controls; let i = index"
            class="row col-xs-12 l-plr-0 div-bg-light-grey l-pt-4 l-pb-4 div-border-bottom l-ai-cen">
            <div class="l-pl-4 l-pr-4">{{ i + 1 }}</div>
            <ng-container *ngIf="_isEdit">
              <div class="col-xs">
                <cds-dropdown [formControlName]="i.toString()" [config]="providersConfig" (change)="providerChange()"> </cds-dropdown>
                <cds-assistive-text *ngIf="providerNames.controls[i].errors?.['required']" class="l-pt-2" [showIcon]="false">
                  Missing Provider name
                </cds-assistive-text>
                <cds-assistive-text *ngIf="providerNames.controls[i].errors?.['dulplicated']" class="l-pt-2" [showIcon]="false">
                  {{ providerNames.controls[i].errors?.['dulplicated'] | lang }}
                </cds-assistive-text>
              </div>
              <div class="remove-button l-mlr-4" (click)="removeProvider(i)">
                <cds-icon color="#8E90A2" icon="action:button_x_filled"></cds-icon>
              </div>
            </ng-container>
            <div *ngIf="!_isEdit">
              {{ service.optionLabelRender | fn : item.value : providersConfig.options }}
            </div>
          </div>
          <div
            *ngIf="!providerNames || !providerNames.controls || providerNames.controls.length === 0"
            class="col-xs-12 middle-xs div-bg-light-grey l-pt-4 l-pb-4">
            No Multi-Service Provider can be found.
          </div>
          <div
            *ngIf="_isEdit && (!providerNames || !providerNames.controls || providerNames.controls.length < providersConfig.options.length)"
            class="col-xs-12 middle-xs div-bg-light-grey l-pt-4 l-pb-4">
            <div class="pointer button-area l-plr-0" (click)="addProviders()">
              <div class="div-icon">+</div>
              <span class="l-ml-2 text">Add New</span>
            </div>
          </div>
        </div>
      </div>

      <div class="row l-pt-7">
        <div class="col-xs-12">
          <span class="cds-body1-ita">{{ 'offer.handled-by' | lang }}</span>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 l-pt-2 l-pb-2">
          <app-dynamic-input-autocomplete
            label="{{ 'offer.relationship-manager' | lang }}"
            [requestUrl]="requestUrl"
            [requestParams]="requestParams"
            optionKey="userName"
            searchKey="searchString"
            formControlName="relationshipManager"
            [placeholder]="_isEdit ? 'Input Relationship Manager' : ''"
            [editMode]="_isEdit"></app-dynamic-input-autocomplete>
          <ng-container *ngIf="_isEdit">
            <cds-assistive-text *ngIf="relationshipManager?.errors?.['required']" class="l-pt-2" [showIcon]="false">
              Missing Relationship manager
            </cds-assistive-text>
            <cds-assistive-text *ngIf="relationshipManager?.errors?.['maxlength']" class="l-pt-2" [showIcon]="false">
              The maximum length is 200
            </cds-assistive-text>
            <cds-assistive-text *ngIf="relationshipManager?.errors?.['minlength']" class="l-pt-2" [showIcon]="false">
              The minimum length is 2
            </cds-assistive-text>
            <cds-assistive-text *ngIf="relationshipManager?.errors?.['userNameInvalid']" class="l-pt-2" [showIcon]="false">
              {{ relationshipManager.errors?.['userNameInvalid'] | lang }}
            </cds-assistive-text>
          </ng-container>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 l-pt-2 l-pb-2">
          <app-dynamic-input-autocomplete
            label="{{ 'offer.relationship-team-manager' | lang }}"
            [requestUrl]="requestUrl"
            [requestParams]="requestParams"
            optionKey="userName"
            searchKey="searchString"
            formControlName="relationshipTeamManager"
            [placeholder]="_isEdit ? 'Input Relationship Team Manager' : ''"
            [editMode]="_isEdit"></app-dynamic-input-autocomplete>
          <ng-container *ngIf="_isEdit">
            <cds-assistive-text *ngIf="relationshipTeamManager?.errors?.['required']" class="l-pt-2" [showIcon]="false">
              Missing Relationship Team Manager
            </cds-assistive-text>
            <cds-assistive-text *ngIf="relationshipTeamManager?.errors?.['maxlength']" class="l-pt-2" [showIcon]="false">
              The maximum length is 200
            </cds-assistive-text>
            <cds-assistive-text *ngIf="relationshipTeamManager?.errors?.['minlength']" class="l-pt-2" [showIcon]="false">
              The minimum length is 2
            </cds-assistive-text>
            <cds-assistive-text *ngIf="relationshipTeamManager?.errors?.['userNameInvalid']" class="l-pt-2" [showIcon]="false">
              {{ relationshipTeamManager.errors?.['userNameInvalid'] | lang }}
            </cds-assistive-text>
          </ng-container>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 l-pt-2 l-pb-2">
          <app-dynamic-input-autocomplete
            label="{{ 'offer.cso' | lang }}"
            [requestUrl]="requestUrl"
            [requestParams]="requestParams"
            optionKey="userName"
            searchKey="searchString"
            formControlName="cso"
            [placeholder]="_isEdit ? 'Input CSO' : ''"
            [editMode]="_isEdit"></app-dynamic-input-autocomplete>
          <ng-container *ngIf="_isEdit">
            <cds-assistive-text *ngIf="cso?.errors?.['required']" class="l-pt-2" [showIcon]="false"> Missing CSO </cds-assistive-text>
            <cds-assistive-text *ngIf="cso?.errors?.['maxlength']" class="l-pt-2" [showIcon]="false"> The maximum length is 200 </cds-assistive-text>
            <cds-assistive-text *ngIf="cso?.errors?.['minlength']" class="l-pt-2" [showIcon]="false"> The minimum length is 2 </cds-assistive-text>
            <cds-assistive-text *ngIf="cso?.errors?.['userNameInvalid']" class="l-pt-2" [showIcon]="false">
              {{ cso.errors?.['userNameInvalid'] | lang }}
            </cds-assistive-text>
          </ng-container>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 l-pt-2 l-pb-2">
          <app-dynamic-input-autocomplete
            label="{{ 'offer.cso-team-manager' | lang }}"
            [requestUrl]="requestUrl"
            [requestParams]="requestParams"
            optionKey="userName"
            searchKey="searchString"
            formControlName="csoTeamManager"
            [placeholder]="_isEdit ? 'Input CSO Team Manager' : ''"
            [editMode]="_isEdit"></app-dynamic-input-autocomplete>
          <ng-container *ngIf="_isEdit">
            <cds-assistive-text *ngIf="csoTeamManager?.errors?.['required']" class="l-pt-2" [showIcon]="false"> Missing CSO Team Manager </cds-assistive-text>
            <cds-assistive-text *ngIf="csoTeamManager?.errors?.['maxlength']" class="l-pt-2" [showIcon]="false"> The maximum length is 200 </cds-assistive-text>
            <cds-assistive-text *ngIf="csoTeamManager?.errors?.['minlength']" class="l-pt-2" [showIcon]="false"> The minimum length is 2 </cds-assistive-text>
            <cds-assistive-text *ngIf="csoTeamManager?.errors?.['userNameInvalid']" class="l-pt-2" [showIcon]="false">
              {{ csoTeamManager.errors?.['userNameInvalid'] | lang }}
            </cds-assistive-text>
          </ng-container>
        </div>
      </div>
      <div class="row l-pt-2 l-pb-2">
        <div class="col-xs-12 p-line"></div>
      </div>

      <div class="row l-pt-5 l-pb-8">
        <div class="row col-xs-12 middle-xs l-pt-2 l-pb-2">
          <div class="col-xs-4">
            <span class="cds-body2 cds-color-grey">{{ 'offer.msp-arrangement' | lang }}</span>
          </div>
          <div class="col-xs">
            <ng-container *ngIf="_isEdit">
              <cds-dropdown
                formControlName="mspArrangement"
                [config]="mspArrangementConfig"
                [placeholder]="_isEdit ? 'Choose MSP Arrangement' : ''"
                (change)="mspArrangementChange($event)">
              </cds-dropdown>
              <cds-assistive-text *ngIf="mspArrangement?.errors?.['required']" class="l-pt-2" [showIcon]="false"> Missing MSP arrangement </cds-assistive-text>
            </ng-container>
            <ng-container *ngIf="!_isEdit">
              <div>
                {{ service.optionLabelRender | fn : mspArrangement?.value : mspArrangementConfig.options }}
              </div>
            </ng-container>
          </div>
        </div>
        <div class="row col-xs-12 middle-xs l-pt-2 l-pb-2" *ngIf="_isEdit || showArrangementDescription">
          <div class="col-xs-4">
            <span class="cds-body2 cds-color-grey">{{ 'offer.arrangement-description' | lang }}</span>
          </div>
          <div class="col-xs">
            <ng-container *ngIf="_isEdit">
              <cds-textfield
                formControlName="arrangementDescription"
                [config]="arrangementDescriptionTextfieldConfig"
                [placeholder]="_isEdit ? 'Input Arrangement Description' : ''"></cds-textfield>
              <cds-assistive-text *ngIf="arrangementDescription?.errors?.['required']" class="l-pt-3" [showIcon]="false">
                Missing Arrangement Description
              </cds-assistive-text>
              <cds-assistive-text *ngIf="arrangementDescription?.errors?.['maxlength']" class="l-pt-3" [showIcon]="false">
                The maximum length is 200
              </cds-assistive-text>
              <cds-assistive-text *ngIf="arrangementDescription?.errors?.['descriptionInvalid']" class="l-pt-3" [showIcon]="false">
                {{ arrangementDescription.errors?.['descriptionInvalid'] | lang }}
              </cds-assistive-text>
            </ng-container>
            <ng-container *ngIf="!_isEdit">
              <div>{{ arrangementDescription?.value }}</div>
            </ng-container>
          </div>
        </div>
        <div class="row col-xs-12 middle-xs l-pt-2 l-pb-2">
          <div class="col-xs-4">
            <span class="cds-body2 cds-color-grey">{{ 'offer.option-frequency' | lang }}</span>
          </div>
          <div class="col-xs">
            <ng-container *ngIf="_isEdit">
              <cds-dropdown
                formControlName="memberOptionFrequency"
                [config]="memberOptionFrequencyConfig"
                [placeholder]="_isEdit ? 'Choose Member Option Frequency' : ''"
                (change)="optionFrequencyChange($event)">
              </cds-dropdown>
              <cds-assistive-text *ngIf="memberOptionFrequency?.errors?.['required']" class="l-pt-2" [showIcon]="false">
                Missing Member Option Frequency
              </cds-assistive-text>
            </ng-container>
            <ng-container *ngIf="!_isEdit">
              <div>
                {{ service.optionLabelRender | fn : memberOptionFrequency?.value : memberOptionFrequencyConfig.options }}
              </div>
            </ng-container>
          </div>
        </div>
        <div class="row col-xs-12 middle-xs l-pt-2 l-pb-2" *ngIf="_isEdit || showOptFrequencyDescription">
          <div class="col-xs-4">
            <span class="cds-body2 cds-color-grey">{{ 'offer.opt-frequency-description' | lang }}</span>
          </div>
          <div class="col-xs">
            <ng-container *ngIf="_isEdit">
              <cds-textfield
                formControlName="optFrequencyDescription"
                [config]="optFrequencyDescriptionTextfieldConfig"
                [placeholder]="_isEdit ? 'Input Opt Frequency Description' : ''">
              </cds-textfield>
              <cds-assistive-text *ngIf="optFrequencyDescription?.errors?.['required']" class="l-pt-3" [showIcon]="false">
                Missing Opt Frequency Description
              </cds-assistive-text>
              <cds-assistive-text *ngIf="optFrequencyDescription?.errors?.['maxlength']" class="l-pt-3" [showIcon]="false">
                The maximum length is 200
              </cds-assistive-text>
              <cds-assistive-text *ngIf="optFrequencyDescription?.errors?.['descriptionInvalid']" class="l-pt-3" [showIcon]="false">
                {{ optFrequencyDescription.errors?.['descriptionInvalid'] | lang }}
              </cds-assistive-text>
            </ng-container>
            <ng-container *ngIf="!_isEdit">
              <div>{{ optFrequencyDescription?.value }}</div>
            </ng-container>
          </div>
        </div>
        <div class="row col-xs-12 middle-xs l-pt-2 l-pb-2">
          <div class="col-xs-4">
            <span class="cds-body2 cds-color-grey">{{ 'offer.last-opt-date' | lang }}</span>
          </div>
          <div class="col-xs date-picker">
            <ng-container *ngIf="_isEdit && showDatePicker">
              <cds-textfield formControlName="lastOptDate" placeholder="DD/MM/YYYY" [max]="currentDate" [cdsDatepicker]="datePicker"> </cds-textfield>
              <cds-datepicker #datePicker="cdsDatepicker"></cds-datepicker>
              <cds-assistive-text *ngIf="lastOptDate?.errors?.['required']" class="l-pt-3" [showIcon]="false"> Missing Last Opt Date </cds-assistive-text>
              <cds-assistive-text *ngIf="lastOptDate?.errors?.['dateInvalid']" class="l-pt-3" [showIcon]="false">
                {{ lastOptDate.errors?.['dateInvalid'] | lang }}
              </cds-assistive-text>
            </ng-container>
            <ng-container *ngIf="!_isEdit">
              <div>
                {{ lastOptDate?.value }}
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>

    <cds-popup-actions *ngIf="_isEdit" class="end-xs l-mb-4">
      <cds-button label="{{ 'common.reset' | lang }}" [style]="'secondary'" [disabled]="!form.dirty" (click)="reset()"></cds-button>
      <cds-button [disabled]="!form.valid || !form.dirty" (click)="submit()" label="{{ 'common.confirm' | lang }}"></cds-button>
    </cds-popup-actions>
  </cds-popup>
</div>
