/* eslint-disable @typescript-eslint/no-explicit-any */
import { CdkDrag, CdkDragEnd, CdkDragStart, DragRef } from '@angular/cdk/drag-drop';
import { AfterViewInit, Component, ElementRef, HostListener, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { CdsAlertService } from '@cds/ng-web-components/alert';
import { BTN_CONFIG_TERTIARY } from 'src/app/config/btn.config';
import { PermissionAccess, PermissionItem } from 'src/app/core/models/enum/permission.enum';
import { Session } from 'src/app/core/models/user';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { CdHttpServeService } from 'src/app/shared/cd-http-serve/cd-http-serve.service';
import { WidgetInfo } from '../widgets/widget.config';
import { ChatService } from '../chatbot/_service/chat.service';
import { Subject } from 'rxjs';
import { PermissionService } from 'src/app/core/services/permission.service';
import { CdsLangService } from '@cds/ng-core/lang';
import { Router } from '@angular/router';
import { WIDGETS_COMPONENT_NAME, WIDGETS_VIEW_ALL_GOAL } from 'src/app/config/widgets.config';
import { environment } from 'src/environments/environment';

interface Announcement {
  announcementId: number;
  announcementDate: string;
  announcementBodyEng: string;
  announcementBodyChi: string;
  announcementSubjectEng: string;
  announcementSubjectChi: string;
}

type RolePriority = 'SuperAdmin' | 'SuperUser' | 'SalesSupport' | 'BranchLevel' | 'SalesJourneyAgent';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss'],
})
export class WelcomeComponent implements OnInit, AfterViewInit, OnDestroy {
  btnCfg = BTN_CONFIG_TERTIARY;
  PermissionAccess = PermissionAccess;
  PermissionItem = PermissionItem;
  hasSuperAdminRole = false;
  hasSuperUserRole = false;
  hasSalesSupportRole = false;
  hasBranchLevelRole = false;
  hasSalesJourneyAgentRole = false;
  rolePriority?: RolePriority;
  isShowChatBotIcon = false;

  startY = 0;
  lastScrollTop?: number = undefined;
  lastScrollLeft?: number = undefined;
  dragEl?: HTMLElement;
  dragRef?: DragRef;
  isDragging = false;
  isAllowAnimal = false;
  isShowAnnouncement = false;
  announcementList: Announcement[] = [];
  @ViewChildren('drag') dragList!: QueryList<CdkDrag>;
  @ViewChild('landingBox', { static: false }) landingBox!: ElementRef<HTMLElement>;
  @ViewChild('mobileWidgetsBox', { static: false }) mobileWidgetsBox!: ElementRef<HTMLElement>;

  currentUser: Session = new Session();
  greeting = '';
  displayName = '';
  currentLastUpdate = 0;
  col = 0;
  navigationContent?: HTMLElement;
  currentIndexSlides = 0;
  slidesTouchStartX = 0;
  slidesTouchEndX = 0;
  mobileWidgetsPage = 0;

  constructor(
    private authenticationService: AuthenticationService,
    private cdHttpServeService: CdHttpServeService,
    private alert: CdsAlertService,
    public chatService: ChatService,
    private permissionService: PermissionService,
    public cdsLangService: CdsLangService,
    private router: Router
  ) {
    this.getIdleTime();
    this.userInactive.subscribe(() => {
      // Alerts.showInfoMessage("")
    });
  }

  widgetInfoList: WidgetInfo[] = [];

  private readonly defaultAgentWidgetInfoList: WidgetInfo[] = [
    {
      id: 0,
      width: 1,
      height: 330,
      component: 'WidgetsQuickLinksComponent',
      name: 'Quick links',
      isDraggable: false,
      isViewAll: false,
    },
    {
      id: 1,
      width: 2,
      height: 330,
      component: 'WidgetsProductionCreditComponent',
      name: 'Production Credit',
      isDraggable: true,
      isViewAll: false,
    },
    {
      id: 2,
      width: 1,
      height: 330,
      component: 'WidgetsPendingIffComponent',
      name: 'Pending IFF',
      isDraggable: true,
      isViewAll: true,
    },
    {
      id: 3,
      width: 1,
      height: 330,
      component: 'WidgetsCampaignResultComponent',
      name: 'Sales Campaign',
      isDraggable: true,
      isViewAll: false,
    },
    {
      id: 4,
      width: 3,
      height: 554,
      component: 'WidgetsCampaignOverviewComponent',
      name: 'Customer Campaign Overview',
      isDraggable: true,
      isViewAll: true,
    },
  ];

  private readonly defaultSuperAdminWidgetInfoList: WidgetInfo[] = [
    {
      id: 0,
      width: 1,
      height: 330,
      component: 'WidgetsQuickLinksComponent',
      name: 'Quick links',
      isDraggable: false,
      isViewAll: false,
    },
    {
      id: 1,
      width: 3,
      height: 554,
      component: 'WidgetsCampaignOverviewComponent',
      name: 'Customer Campaign Overview',
      isDraggable: true,
      isViewAll: true,
    },
  ];

  private readonly defaultSuperUserWidgetInfoList: WidgetInfo[] = [
    {
      id: 0,
      width: 1,
      height: 330,
      component: 'WidgetsQuickLinksComponent',
      name: 'Quick links',
      isDraggable: false,
      isViewAll: false,
    },
    {
      id: 1,
      width: 3,
      height: 554,
      component: 'WidgetsCampaignOverviewComponent',
      name: 'Customer Campaign Overview',
      isDraggable: true,
      isViewAll: true,
    },
  ];

  private readonly defaultSalesSupportWidgetInfoList: WidgetInfo[] = [
    {
      id: 0,
      width: 1,
      height: 349,
      component: 'WidgetsQuickLinksComponent',
      name: 'Quick links',
      isDraggable: false,
      isViewAll: false,
    },
    {
      id: 1,
      width: 2,
      height: 349,
      component: 'WidgetsProductionCreditByDistrictComponent',
      name: 'Production Credit',
      isDraggable: true,
      isViewAll: false,
    },
  ];

  private readonly defaultBranchLevelWidgetInfoList: WidgetInfo[] = [
    {
      id: 0,
      width: 1,
      height: 349,
      component: 'WidgetsQuickLinksComponent',
      name: 'Quick links',
      isDraggable: false,
      isViewAll: false,
    },
    {
      id: 1,
      width: 2,
      height: 349,
      component: 'WidgetsProductionCreditByDistrictComponent',
      name: 'Production Credit',
      isDraggable: true,
      isViewAll: false,
    },
  ];

  closeAnnouncement() {
    this.isShowAnnouncement = false;
  }

  cdkDragStarted(start: CdkDragStart) {
    this.isAllowAnimal = false;
    this.isDragging = true;

    if (this.navigationContent) {
      this.dragRef = start.source._dragRef;
      this.dragEl = start.source.element.nativeElement;
    }
  }

  cdkDragEnded(cdkDragEnd: CdkDragEnd) {
    this.isDragging = false;
    this.dragRef = undefined;
    this.dragEl = undefined;
    const draggedEl = cdkDragEnd.source.element.nativeElement;
    const draggedWvIndex = this.widgetInfoList.indexOf(cdkDragEnd.source.data.widgetInfo);
    const overlapFrontmostWv = this.getOverlapFrontmostEl(draggedEl);
    if (overlapFrontmostWv) {
      const overlapFrontmostWvIndex = this.widgetInfoList.indexOf(overlapFrontmostWv);
      this.insertElement(this.widgetInfoList, draggedWvIndex, overlapFrontmostWvIndex);
      this.saveComponentOrder();
    } else {
      this.isAllowAnimal = true;
      setTimeout(() => {
        this.isAllowAnimal = false;
      }, 300);
    }

    for (const rectangle of this.dragList) {
      rectangle.reset();
    }
  }

  private insertElement(array: WidgetInfo[], draggedWvIndex: number, targetIndex: number) {
    const removedElement = array.splice(draggedWvIndex, 1)[0];
    array.splice(targetIndex, 0, removedElement);
  }

  private getOverlapFrontmostEl(drag: HTMLElement) {
    let overlapFrontmostWv = null;

    for (const rectangle of this.dragList) {
      if (rectangle.element.nativeElement !== drag) {
        const rect = rectangle.element.nativeElement.getBoundingClientRect();
        const dragRect = drag.getBoundingClientRect();
        if (dragRect.left < rect.right && dragRect.right > rect.left && dragRect.top < rect.bottom && dragRect.bottom > rect.top) {
          if (!rectangle.data.widgetInfo.isDraggable) {
            break;
          }
          overlapFrontmostWv = rectangle.data.widgetInfo as WidgetInfo;
          break;
        }
      }
    }

    return overlapFrontmostWv;
  }

  ngOnInit(): void {
    this.permissionService.hasPermission(PermissionAccess.W, PermissionItem.CHATBOT_AGENT).then(boo => {
      this.isShowChatBotIcon = boo;
    });

    const now: number = new Date().getHours();
    console.log('now hour: ' + now);
    if (now >= 6 && now < 12) {
      this.greeting = 'common.GoodMorning';
    } else if (now >= 12 && now < 17) {
      this.greeting = 'common.GoodAfternoon';
    } else {
      this.greeting = 'common.GoodEvening';
    }
    this.authenticationService.currentUserValue().then(res => {
      this.displayName = res.displayName;
      this.currentLastUpdate = res.currentLastUpdate;
    });
    if (!['prod', 'uat'].includes(environment.profile)) {
      this.getAnnouncementList();
      this.initRole().then(() => {
        this.loadComponentOrder();
      });
    }
  }

  private async initRole() {
    this.hasSuperAdminRole = await this.permissionService.hasPermission(PermissionAccess.R, PermissionItem.SALES_JOURNEY_SUPER_ADMIN);
    this.hasSuperUserRole = await this.permissionService.hasPermission(PermissionAccess.R, PermissionItem.SALES_JOURNEY_SUPER_USER);
    this.hasSalesSupportRole = await this.permissionService.hasPermission(PermissionAccess.R, PermissionItem.SALES_JOURNEY_SALES_SUPPORT);
    this.hasBranchLevelRole = await this.permissionService.hasPermission(PermissionAccess.R, PermissionItem.SALES_JOURNEY_BRANCH_LEVEL);
    this.hasSalesJourneyAgentRole = await this.permissionService.hasPermission(PermissionAccess.R, PermissionItem.SALES_JOURNEY_AGENT);

    if (this.hasSuperAdminRole) {
      this.rolePriority = 'SuperAdmin';
    } else if (this.hasSuperUserRole) {
      this.rolePriority = 'SuperUser';
    } else if (this.hasSalesSupportRole) {
      this.rolePriority = 'SalesSupport';
    } else if (this.hasBranchLevelRole) {
      this.rolePriority = 'BranchLevel';
    } else if (this.hasSalesJourneyAgentRole) {
      this.rolePriority = 'SalesJourneyAgent';
    }
  }

  pcBox: any;

  ngAfterViewInit(): void {
    this.navigationContent = document.querySelector('cds-navigation .cds-navigation-content') as HTMLElement;
    this.resizeListener();
    window.addEventListener('resize', this.resizeListener);

    document.addEventListener('mousemove', this.mousemove);
  }

  mousemove = (event: MouseEvent) => {
    if (!this.isDragging) {
      return;
    }
    const boundaryCheckingDistance = 40;
    const sensitivity = 20;
    const offsetY = event.clientY - window.innerHeight / 2;

    if (this.navigationContent && this.dragEl && this.dragRef) {
      if (offsetY > 0 && offsetY > window.innerHeight / 2 - boundaryCheckingDistance) {
        this.navigationContent.scrollBy(0, sensitivity);
      } else if (offsetY < 0 && offsetY < -window.innerHeight / 2 + boundaryCheckingDistance) {
        this.navigationContent.scrollBy(0, -sensitivity);
      }
    }
  };

  landingBoxNumOfCol = 3;
  private resizeListener = () => {
    if (window.matchMedia('only screen and (max-width: 826px)').matches) {
      this.landingBoxNumOfCol = 1;
      this.mobileWidgetsPage = 0;
    } else if (window.matchMedia('only screen and (max-width: 1452px)').matches) {
      this.landingBoxNumOfCol = 2;
    } else {
      this.landingBoxNumOfCol = 3;
    }
  };

  ngOnDestroy(): void {
    window.removeEventListener('resize', this.resizeListener);
    document.removeEventListener('mousemove', this.mousemove);
  }

  loadComponentOrder() {
    this.cdHttpServeService.get<{ privilege: RolePriority; componentsList: [] }>('/ext/eb-ssms/agent-service/agent/main/component/order', undefined).subscribe({
      next: res => {
        if (res.result !== 0) {
          this.alert.warning('Warning!', `${res.message}`);
        } else {
          if (res.data && res.data.privilege === this.rolePriority) {
            this.widgetInfoList = res.data.componentsList;
          } else {
            if (this.rolePriority === 'SuperAdmin') {
              this.widgetInfoList = this.defaultSuperAdminWidgetInfoList;
            } else if (this.rolePriority === 'SuperUser') {
              this.widgetInfoList = this.defaultSuperUserWidgetInfoList;
            } else if (this.rolePriority === 'SalesSupport') {
              this.widgetInfoList = this.defaultSalesSupportWidgetInfoList;
            } else if (this.rolePriority === 'BranchLevel') {
              this.widgetInfoList = this.defaultBranchLevelWidgetInfoList;
            } else if (this.rolePriority === 'SalesJourneyAgent') {
              this.widgetInfoList = this.defaultAgentWidgetInfoList;
            }
            this.saveComponentOrder();
          }
        }
      },
      error: err => {
        this.alert.error('Error!', err);
      },
    });
  }

  goGoal(componentName: WIDGETS_COMPONENT_NAME) {
    if (!WIDGETS_VIEW_ALL_GOAL[componentName]) {
      return;
    }
    this.router.navigate([WIDGETS_VIEW_ALL_GOAL[componentName]]);
  }

  saveComponentOrder() {
    const params = {
      privilege: this.rolePriority,
      componentsList: this.widgetInfoList,
    };
    this.cdHttpServeService.post('/ext/eb-ssms/agent-service/agent/main/component/order', params).subscribe({
      next: res => {
        if (res.result !== 0) {
          this.alert.warning('Warning!', `${res.message}`);
        }
      },
      error: err => {
        this.alert.error('Error!', err);
      },
    });
  }

  getAnnouncementList() {
    this.cdHttpServeService.get<Announcement[]>('/ext/eb-ssms-sales-journey-service/announcement/list', undefined).subscribe({
      next: res => {
        if (res.result !== 0) {
          this.alert.warning('Warning!', `${res.message}`);
        }
        this.announcementList = res.data;
        if (this.announcementList && this.announcementList.length > 0) {
          this.isShowAnnouncement = true;
        }
      },
      error: err => {
        this.alert.error('Error!', err);
      },
    });
  }

  mobileWidgetsPageLast() {
    if (this.mobileWidgetsPage === 0) {
      return;
    }
    this.mobileWidgetsPage--;
    this.showMobileWidgetsPage(this.mobileWidgetsPage);
  }

  mobileWidgetsPageNext() {
    if (!((this.widgetInfoList.length >= 3 && this.mobileWidgetsPage < 2) || (this.widgetInfoList.length === 2 && this.mobileWidgetsPage < 1))) {
      return;
    }
    this.mobileWidgetsPage++;
    this.showMobileWidgetsPage(this.mobileWidgetsPage);
  }

  showMobileWidgetsPage(index: number) {
    const offset = -index * (100 / 3);
    if (this.mobileWidgetsBox) {
      this.mobileWidgetsBox.nativeElement.style.transform = `translateX(${offset}%)`;
    }
  }

  // chatbot-cso ↓
  userActivity: any;
  timer: any;
  userInactive: Subject<any> = new Subject();

  setTimeout(time: any) {
    this.userActivity = setTimeout(() => this.userInactive.next(undefined), time);
  }

  @HostListener('window:mousemove') refreshUserState() {
    clearTimeout(this.userActivity);
    if (this.timer) {
      this.setTimeout(this.timer);
    }
  }

  getIdleTime() {
    this.chatService.getIdleTime().subscribe(resp => {
      if (resp) {
        this.timer = resp.time;
        this.setTimeout(resp.time);
      }
    });
  }
  // chatbot-cso ↑
}
