<app-loading [overlay]="true" *ngIf="isLoading"></app-loading>

<div class="row l-pt-8 l-pb-4">
  <div class="col-xs-12 cds-color-green">
    <span class="cds-h4-demibold">{{ 'ee.opt.servicing-agent-information' | lang }}</span>
  </div>
  <div class="col-xs-12">
    <div class="p-line"></div>
  </div>
</div>

<!-- <div class="row">
  <app-no-result-found *ngIf="!servicingAgentData.status" class="col-12"></app-no-result-found>
</div> -->

<!-- <app-cd-accordion>
  <app-cd-accordion-panel
    [title]="titleGen | fn : servicingAgentData"
    [right]="service.getStatus | fn : servicingAgentData.status : agentStatusMapObj"
    [statusColor]="service.getStatusColor | fn : servicingAgentData.status : agentStatusMapObj">
    <app-row-item label="ee.opt.MPF-registration-number" [data]="servicingAgentData.registrationNumber" class="col-xs-12"> </app-row-item>
    <app-row-item label="ee.opt.Branch-Code" [data]="servicingAgentData.branchCode" class="col-xs-6 col-sm-3 col-md-3 col-lg-3"> </app-row-item>
    <app-row-item label="ee.opt.Mobile" [data]="servicingAgentData.mobile" class="col-xs-6 col-sm-3 col-md-3 col-lg-3"> </app-row-item>
    <app-row-item label="ee.opt.Office" [data]="servicingAgentData.office" class="col-xs-6 col-sm-3 col-md-3 col-lg-3"> </app-row-item>
    <app-row-item label="ee.opt.Email" [data]="servicingAgentData.email" class="col-xs-6 col-sm-3 col-md-3 col-lg-3"> </app-row-item>
  </app-cd-accordion-panel>
</app-cd-accordion> -->

<app-cd-accordion *ngFor="let item of servicingAgentData">
  <app-cd-accordion-panel
    [title]="titleGen | fn : item"
    [right]="service.getStatus | fn : item.status : agentStatusMapObj"
    [statusColor]="service.getStatusColor | fn : item.status : agentStatusMapObj">
    <app-row-item label="ee.opt.MPF-registration-number" [data]="item.registrationNumber" class="col-xs-12"> </app-row-item>
    <app-row-item label="ee.opt.Branch-Code" [data]="item.branchCode" class="col-xs-6 col-sm-3 col-md-3 col-lg-3"> </app-row-item>
    <app-row-item label="ee.opt.Mobile" [data]="item.mobile" class="col-xs-6 col-sm-3 col-md-3 col-lg-3"> </app-row-item>
    <app-row-item label="ee.opt.Office" [data]="item.office" class="col-xs-6 col-sm-3 col-md-3 col-lg-3"> </app-row-item>
    <app-row-item label="ee.opt.Email" [data]="item.email" class="col-xs-6 col-sm-3 col-md-3 col-lg-3"> </app-row-item>
  </app-cd-accordion-panel>
</app-cd-accordion>

<ng-container *ngIf="data.showCommission === true">
  <div class="row l-pt-8 l-pb-4">
    <div class="col-xs-12 cds-color-green">
      <span class="cds-h4-demibold">{{ 'ee.opt.commission-agent-information' | lang }}</span>
    </div>
    <div class="col-xs-12">
      <div class="p-line"></div>
    </div>
  </div>

  <!-- <div class="row">
    <app-no-result-found *ngIf="!commissionAgentData.length" class="col-12"></app-no-result-found>
  </div> -->

  <app-cd-accordion *ngFor="let item of commissionAgentData">
    <app-cd-accordion-panel
      [title]="titleGen | fn : item"
      [right]="service.getStatus | fn : item.status : agentStatusMapObj"
      [statusColor]="service.getStatusColor | fn : item.status : agentStatusMapObj">
      <app-row-item label="ee.opt.MPF-registration-number" [data]="item.registrationNumber" class="col-xs-12"> </app-row-item>
      <app-row-item label="ee.opt.Branch-Code" [data]="item.branchCode" class="col-xs-6 col-sm-3 col-md-3 col-lg-3"> </app-row-item>
      <app-row-item label="ee.opt.Mobile" [data]="item.mobile" class="col-xs-6 col-sm-3 col-md-3 col-lg-3"> </app-row-item>
      <app-row-item label="ee.opt.Office" [data]="item.office" class="col-xs-6 col-sm-3 col-md-3 col-lg-3"> </app-row-item>
      <app-row-item label="ee.opt.Email" [data]="item.email" class="col-xs-6 col-sm-3 col-md-3 col-lg-3"> </app-row-item>
    </app-cd-accordion-panel>
  </app-cd-accordion>
</ng-container>

<ng-container *ngIf="data.showDelegated === true">
  <div class="row l-pt-8 l-pb-4">
    <div class="col-xs-12 cds-color-green">
      <span class="cds-h4-demibold">{{ 'ee.opt.delegated-agent-information' | lang }}</span>
    </div>
    <div class="col-xs-12">
      <div class="p-line"></div>
    </div>
  </div>

  <!-- <app-cd-accordion>
    <app-cd-accordion-panel
      [title]="titleGen | fn : delegatedAgentData"
      [right]="service.getStatus | fn : delegatedAgentData.status : agentStatusMapObj"
      [statusColor]="service.getStatusColor | fn : delegatedAgentData.status : agentStatusMapObj">
      <app-row-item label="ee.opt.MPF-registration-number" [data]="delegatedAgentData.registrationNumber" class="col-xs-12"> </app-row-item>
      <app-row-item label="ee.opt.Branch-Code" [data]="delegatedAgentData.branchCode" class="col-xs-6 col-sm-3 col-md-3 col-lg-3"> </app-row-item>
      <app-row-item label="ee.opt.Mobile" [data]="delegatedAgentData.mobile" class="col-xs-6 col-sm-3 col-md-3 col-lg-3"> </app-row-item>
      <app-row-item label="ee.opt.Office" [data]="delegatedAgentData.office" class="col-xs-6 col-sm-3 col-md-3 col-lg-3"> </app-row-item>
      <app-row-item label="ee.opt.Email" [data]="delegatedAgentData.email" class="col-xs-6 col-sm-3 col-md-3 col-lg-3"> </app-row-item>
    </app-cd-accordion-panel>
  </app-cd-accordion> -->

  <app-cd-accordion *ngFor="let item of delegatedAgentData">
    <app-cd-accordion-panel
      [title]="titleGen | fn : item"
      [subTitle]="'Dalegation Date:' + item.delegationDate"
      subTitleClass="body1"
      subTitleOutClass="sub-title-out-class"
      [right]="service.getStatus | fn : item.status : agentStatusMapObj"
      [statusColor]="service.getStatusColor | fn : item.status : agentStatusMapObj">
      <app-row-item label="ee.opt.MPF-registration-number" [data]="item.registrationNumber" class="col-xs-12"> </app-row-item>
      <app-row-item label="ee.opt.Branch-Code" [data]="item.branchCode" class="col-xs-6 col-sm-3 col-md-3 col-lg-3"> </app-row-item>
      <app-row-item label="ee.opt.Mobile" [data]="item.mobile" class="col-xs-6 col-sm-3 col-md-3 col-lg-3"> </app-row-item>
      <app-row-item label="ee.opt.Office" [data]="item.office" class="col-xs-6 col-sm-3 col-md-3 col-lg-3"> </app-row-item>
      <app-row-item label="ee.opt.Email" [data]="item.email" class="col-xs-6 col-sm-3 col-md-3 col-lg-3"> </app-row-item>
    </app-cd-accordion-panel>
  </app-cd-accordion>
</ng-container>
