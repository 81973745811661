import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CdsDropdownConfig } from '@cds/ng-core/dropdown';
import { PermissionAccess, PermissionItem } from 'src/app/core/models/enum/permission.enum';
import { LayoutHeaderService } from 'src/app/core/services/layout-header.service';
import { PermissionService } from 'src/app/core/services/permission.service';
import { deepCopy } from 'src/app/utils/copy';
import { AdminHeaderConfig } from '../../layout/admin-layout/admin-header/admin-header-config';
import {
  AddressDTO,
  BillingClass,
  ContributionFrequencyE,
  contributionFrequencyMapObj,
  EmployerProfileDetail,
  ErAddressTypeE,
  erAddressTypeMapObj,
  inValidStatusMapObj,
} from '../employer';
import { ContactStatusE } from '../employer-profile-company-list/employer-profile-company-list';
import { EmployerService } from '../employer.service';
import { ToastAlertService } from 'src/app/shared/toast-alert.service';

@Component({
  selector: 'app-payroll-group-info',
  templateUrl: './payroll-group-info.component.html',
  styleUrls: ['./payroll-group-info.component.scss'],
})
export class PayrollGroupInfoComponent implements OnInit {
  fakeDate = new Date();

  ContactStatusE = ContactStatusE;

  inValidStatusMapObj = inValidStatusMapObj;

  ErAddressTypeE = ErAddressTypeE;

  isHeaderSticky = false;

  permissionChecked = false;

  constructor(
    private layoutHeaderService: LayoutHeaderService,
    public service: EmployerService,
    private route: ActivatedRoute,
    private router: Router,
    private permissionService: PermissionService,
    private toastAlert: ToastAlertService
  ) {}

  headerButton: AdminHeaderConfig = {
    title: 'er.employer-payroll-group-info',
    backButton: {
      text: 'Employer Enquiry Information Page',
      url: '#',
      method: this.goBack.bind(this),
    },
  };

  goBack() {
    this.router.navigate(['/employer/list']);
  }
  dropdownConfig: CdsDropdownConfig = {
    label: 'Choose an employer payroll group',
    options: [],
  };

  billingClass?: BillingClass;

  _id?: string;

  _entityCode?: string;

  employerProfileDetail?: EmployerProfileDetail;

  billingClassList?: BillingClass[] = [];

  addressList: AddressDTO[] = [];

  payrollGroupId = '';

  isStaff = false;

  tabIndex = 0;

  activedTabList: string[] = [];

  tabList: string[] = [];

  _typeShow = false;

  ngOnInit(): void {
    document.getElementsByClassName('cds-navigation-content')[0].scrollTop = 0;
    this.layoutHeaderService.setHeaderButton(this.headerButton);

    const _id = this.route.snapshot.queryParams['id'];
    const _entityCode = this.route.snapshot.queryParams['entityCode'];

    this.payrollGroupId = this.route.snapshot.queryParams['payrollGroupId'];

    this.initType(_id);
    this.permissionService.hasPermission(PermissionAccess.R, PermissionItem.CUSTOMER_EMPLOYER).then(havePermission => {
      if (havePermission) {
        this.isStaff = true;
      }
      this.permissionChecked = true;
      this.tabList = this.getTablist();
      this.changeTab();
    });

    if (_id && /.*/.test(_id)) {
      this._id = _id;
      this._entityCode = _entityCode;
      this.getBillingClassList(_id);
      this.getProfileDetail(_id);
    }
  }

  initType(id: string) {
    if (id.startsWith('ORSO') || id.startsWith('MACAUCPF') || id.startsWith('MACAUPPS')) {
      this._typeShow = true;
    }
  }

  getTablist() {
    if (this.isStaff) {
      return ['schemeInfo', 'billingInfo', 'accountInfo', 'paymentRecord', 'accountBalance', 'vestingRule', 'empfWorkflow', 'chargeInfo', 'formulaClass'];
    } else {
      return ['schemeInfo', 'billingInfo', 'accountInfo', 'accountBalance', 'vestingRule'];
    }
  }

  // TODO
  changeTab() {
    const tab = this.route.snapshot.queryParams['tab'] || '';

    const index = this.tabList.indexOf(tab);

    if (index > -1) {
      this.selectedTabIndexChange(index);
    }
  }

  initSelect(billingClassList: BillingClass[] | undefined) {
    billingClassList?.forEach(billingClass => {
      if (billingClass.payrollGroupId) {
        this.dropdownConfig.options.push({
          label:
            (Number(billingClass.payrollGroupId) < 10 ? billingClass.payrollGroupId?.padStart(2, '0') : billingClass.payrollGroupId) +
            ' - ' +
            billingClass.payrollGroupName,
          value: billingClass,
        });
      }
    });
    const _billingClass = billingClassList?.find(x => this.payrollGroupId == x.payrollGroupId);
    if (_billingClass) {
      this.billingClass = _billingClass;
    }
  }

  getBillingClassList(id: string) {
    this.service.getBillingClassList(id).subscribe(data => {
      if (data.result === 0) {
        const _billingClassList = data.data;

        if (_billingClassList) {
          this.billingClassList = this.addressListSort(_billingClassList);

          this.initSelect(this.billingClassList);
        }
      } else {
        this.toastError(data.message);
      }
    });
  }

  addressListSort(billingClassList?: BillingClass[]) {
    if (!billingClassList) return billingClassList;
    return billingClassList.map(item => {
      const data: BillingClass = deepCopy(item);
      let addressList: AddressDTO[] = deepCopy(data.addressList);
      addressList = addressList.filter(item => {
        return this.isStaff || item.addressType !== ErAddressTypeE.RESIDENTIAL;
      });

      addressList.sort((a, b) => erAddressTypeMapObj[a.addressType].order - erAddressTypeMapObj[b.addressType].order);
      data.addressList = addressList;
      return data;
    });
  }

  addressTypeRender(type: ErAddressTypeE) {
    return erAddressTypeMapObj[type].text || '';
  }

  getProfileDetail(id: string) {
    this.service.getProfileDetail(id).subscribe(data => {
      if (data.result === 0) {
        this.employerProfileDetail = data.data;
      } else {
        this.toastError(data.message);
      }
    });
  }

  onRegisteredToggle(event: MouseEvent) {
    if (event.target) {
      event.target.dispatchEvent(new Event('click'));
    }
  }

  onBusinessToggle(event: MouseEvent) {
    if (event.target) {
      event.target.dispatchEvent(new Event('click'));
    }
  }

  showPlaceholderHeight(isSticky: boolean) {
    this.isHeaderSticky = isSticky;
  }

  contributionFrequencyRender(type: ContributionFrequencyE) {
    return contributionFrequencyMapObj[type] || '';
  }

  addressRender(adress: AddressDTO) {
    let list = [
      adress.room && adress.zhAddrFlg === 'Y' ? adress.room + '室' : adress.room,
      adress.floor && adress.zhAddrFlg === 'Y' ? adress.floor + '樓' : adress.floor,
      adress.block && adress.zhAddrFlg === 'Y' ? adress.block + '座' : adress.block,
      adress.building,
      adress.street,
      adress.district,
      adress.city,
      adress.country,
    ];

    list = list.filter(item => !!item);

    return list.join(', ');
  }

  selectedTabIndexChange(value: number) {
    this.tabIndex = value;
    const tab = this.tabList[value];
    this.activedTabList.push(tab);
    this.activedTabList = [...this.activedTabList];
  }

  ifLoadTab(activedTabList: string[], tab: string) {
    if (activedTabList.includes(tab)) return true;
    return false;
  }

  selectChange(value?: BillingClass) {
    if (value) {
      const tab = this.tabList[this.tabIndex];
      this.activedTabList = [tab];
    }
  }

  toastError(errorMessage: string) {
    this.toastAlert.show('error', 'common.error', errorMessage, 5000);
  }
}
