<div class="select-product-type">
  <div class="top">
    <span class="text">IFF Paper Form</span>
  </div>

  <div class="content">
    <div>
      <span class="cds-h3-light"> Select Product Type </span>
    </div>

    <div class="select-list l-mt-7">
      <div class="select-box" (click)="goIndividual()">
        <cds-icon style="width: 80px; height: 80px; --cds-icon-color: var(--cds-bg-ml)" icon="people:login_1"></cds-icon>
        <div class="l-mt-5">
          <span class="cds-h2-light">Individual</span>
        </div>
      </div>
      <div class="select-box l-ml-6" (click)="goEmployer()">
        <cds-icon style="width: 80px; height: 80px; --cds-icon-color: var(--cds-bg-ml)" icon="environment:office_building"></cds-icon>
        <div class="l-mt-5">
          <span class="cds-h2-light">Employer</span>
        </div>
      </div>
    </div>
  </div>
</div>
