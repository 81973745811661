<div class="announcement-details">
  <div class="head">
    <div class="back-to-announcement-center cursor-pointer" (click)="onBack()">
      <cds-icon class="dlgt-back-icon" [config]="backErIconConfig" [icon]="backErIcon"> </cds-icon>
      <div class="l-ml-2">
        <span class="cds-body2-demibold back-text"> Announcement Center </span>
      </div>
    </div>
    <div class="title">
      <span class="cds-h4-demibold" *ngIf="currentAnnouncementId === null">Create Announcement</span>
      <span class="cds-h4-demibold" *ngIf="currentAnnouncementId !== null">Edit Announcement</span>
    </div>
  </div>
  <div class="content">
    <div>
      <span class="cds-body1"
        >Lorem ipsum dolor sit amet. Qui autem impedit est tenetur reiciendis et sunt rerum qui quisquam animi et repudiandae rerum ab cumque dolore ut
        accusamus ipsum. Doloribus aliquid quo delectus atque aut corporis eius eum maiores optio in autem consequatur quo quidem officiis.
      </span>
    </div>

    <div class="l-mt-7">
      <span class="cds-h2-light">Announcement Details</span>
    </div>

    <div class="row l-d-f padding-none">
      <div *ngIf="!refreshTimePicker" class="col-lg-3 col-md-6 col-sm-12 col-xs-12 search-item">
        <span class="cds-body2" style="color: var(--cds-text-disabled)">Effective Date From</span>
        <cds-textfield
          placeholder="DD/MM/YYYY"
          [cdsDatepicker]="effectiveDateFromPicker"
          ngDefaultControl
          [formControl]="effectiveDateFrom"
          [max]="effectiveDateFromMax"
          [min]="effectiveDateFromMin"></cds-textfield>
        <cds-datepicker #effectiveDateFromPicker="cdsDatepicker"></cds-datepicker>
        <cds-assistive-text class="l-mt-3" *ngIf="effectiveDateFrom.dirty && effectiveDateFrom.hasError('required')">{{
          'Please input effective date from' | lang
        }}</cds-assistive-text>
        <cds-assistive-text *ngIf="effectiveDateFrom.errors?.['error']" class="l-mt-1 l-pt-3">
          {{ effectiveDateFrom.errors?.['error'] | lang }}
        </cds-assistive-text>
        <cds-assistive-text style="margin-top: 14px" *ngIf="effectiveDateFrom.errors && effectiveDateFrom.errors['dateError'] && effectiveDateFrom.dirty">{{
          effectiveDateFrom.errors['dateError'] | lang
        }}</cds-assistive-text>
      </div>
      <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12 search-item">
        <span class="cds-body2" style="color: var(--cds-text-disabled)">Effective Time From</span>
        <app-cd-time-picker
          [ngClass]="{ disabled: !effectiveDateFrom.value }"
          ngDefaultControl
          [formControl]="effectiveTimeFrom"
          [max]="effectiveTimeFromMax"
          [error]="formGroup.errors && formGroup.errors['timeError'] && formGroup.dirty"></app-cd-time-picker>
        <cds-assistive-text class="l-mt-1" *ngIf="formGroup.errors && formGroup.errors['timeError'] && formGroup.dirty">{{
          formGroup.errors['timeError'] | lang
        }}</cds-assistive-text>
      </div>
      <div *ngIf="!refreshTimePicker" class="col-lg-3 col-md-6 col-sm-12 col-xs-12 search-item">
        <span class="cds-body2" style="color: var(--cds-text-disabled)">Effective Date To</span>
        <cds-textfield
          placeholder="DD/MM/YYYY"
          [cdsDatepicker]="effectiveDateToPicker"
          ngDefaultControl
          [formControl]="effectiveDateTo"
          [max]="effectiveDateToMax"
          [min]="effectiveDateToMin">
        </cds-textfield>
        <cds-assistive-text class="l-mt-3" *ngIf="effectiveDateTo.dirty && effectiveDateTo.hasError('required')">{{
          'Please input effective date to' | lang
        }}</cds-assistive-text>
        <cds-datepicker #effectiveDateToPicker="cdsDatepicker"></cds-datepicker>
        <cds-assistive-text *ngIf="effectiveDateTo.errors?.['error']" class="l-mt-1 l-pt-3">
          {{ effectiveDateTo.errors?.['error'] | lang }}
        </cds-assistive-text>
      </div>
      <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12 search-item">
        <span class="cds-body2" style="color: var(--cds-text-disabled)">Effective Time To</span>
        <app-cd-time-picker
          [ngClass]="{ disabled: !effectiveDateTo.value }"
          ngDefaultControl
          [formControl]="effectiveTimeTo"
          [min]="effectiveTimeToMin"
          [error]="formGroup.errors && formGroup.errors['timeError'] && formGroup.dirty"></app-cd-time-picker>
        <cds-assistive-text class="l-mt-1" *ngIf="formGroup.errors && formGroup.errors['timeError'] && formGroup.dirty">{{
          formGroup.errors['timeError'] | lang
        }}</cds-assistive-text>
      </div>
    </div>

    <div class="l-mt-7">
      <div class="message-details-box">
        <span class="cds-h2-light">Message Details</span>
      </div>
      <div>
        <span class="cds-h5-demibold">English Version</span>
      </div>
      <div class="l-mt-5 l-d-f l-jc-sb">
        <div>
          <span class="cds-body2">English Announcement Subject</span>
        </div>
        <div>
          <span class="cds-body2">{{ announcementSubjectEng.value.length }}/30</span>
        </div>
      </div>
      <div class="l-mt-2">
        <input class="my-input" type="text" placeholder="Input subject" [formControl]="announcementSubjectEng" />
        <cds-assistive-text class="l-mt-2" *ngIf="announcementSubjectEng.dirty && announcementSubjectEng.hasError('required')">{{
          'Please input english announcement subject' | lang
        }}</cds-assistive-text>
        <cds-assistive-text class="l-mt-2" *ngIf="announcementSubjectEng.dirty && announcementSubjectEng.hasError('maxlength')">{{
          'Exceeds character limit' | lang
        }}</cds-assistive-text>
      </div>
      <div class="l-mt-5 l-d-f l-jc-sb">
        <div>
          <span class="cds-body2">English Announcement Body</span>
        </div>
        <div>
          <span class="cds-body2">{{ announcementBodyEng.value.length }}/500</span>
        </div>
      </div>
      <div>
        <div class="l-mt-2">
          <textarea class="my-textarea" placeholder="Input message" [formControl]="announcementBodyEng"></textarea>
        </div>
        <cds-assistive-text class="l-mt-2" *ngIf="announcementBodyEng.dirty && announcementBodyEng.hasError('required')">{{
          'Please input english announcement body' | lang
        }}</cds-assistive-text>
        <cds-assistive-text class="l-mt-2" *ngIf="announcementBodyEng.dirty && announcementBodyEng.hasError('maxlength')">{{
          'Exceeds character limit' | lang
        }}</cds-assistive-text>
      </div>
      <div class="l-mt-7 line"></div>
    </div>

    <div class="l-mt-7">
      <div>
        <span class="cds-h5-demibold">Chinese Version</span>
      </div>
      <div class="l-mt-5 l-d-f l-jc-sb">
        <div>
          <span class="cds-body2">Chinese Announcement Subject</span>
        </div>
        <div>
          <span class="cds-body2">{{ announcementSubjectChi.value.length }}/30</span>
        </div>
      </div>
      <div class="l-mt-2">
        <input class="my-input" type="text" placeholder="請輸入標題" [formControl]="announcementSubjectChi" />
        <cds-assistive-text class="l-mt-2" *ngIf="announcementSubjectChi.dirty && announcementSubjectChi.hasError('required')">{{
          'Please input chinese announcement subject' | lang
        }}</cds-assistive-text>
        <cds-assistive-text class="l-mt-2" *ngIf="announcementSubjectChi.dirty && announcementSubjectChi.hasError('maxlength')">{{
          'Exceeds character limit' | lang
        }}</cds-assistive-text>
      </div>
      <div class="l-mt-5 l-d-f l-jc-sb">
        <div>
          <span class="cds-body2">Chinese Announcement Body</span>
        </div>
        <div>
          <span class="cds-body2">{{ announcementBodyChi.value.length }}/500</span>
        </div>
      </div>
      <div>
        <div class="l-mt-2">
          <textarea class="my-textarea" placeholder="請輸入訊息內容" [formControl]="announcementBodyChi"></textarea>
        </div>
        <cds-assistive-text class="l-mt-2" *ngIf="announcementBodyChi.dirty && announcementBodyChi.hasError('required')">{{
          'Please input chinese announcement body' | lang
        }}</cds-assistive-text>
        <cds-assistive-text class="l-mt-2" *ngIf="announcementBodyChi.dirty && announcementBodyChi.hasError('maxlength')">{{
          'Exceeds character limit' | lang
        }}</cds-assistive-text>
      </div>
    </div>

    <div *ngIf="currentAnnouncementId !== null" class="l-d-if l-mt-7 cursor-pointer" (click)="recallAnnouncement(currentAnnouncementId)">
      <div>
        <cds-icon icon="form:delete" style="--cds-icon-color: var(--cds-color-dark-1-coral)"></cds-icon>
      </div>
      <div class="l-ml-2">
        <span class="cds-h6-demibold" style="color: var(--cds-color-dark-1-coral)">Recall Announcement</span>
      </div>
    </div>

    <div *ngIf="screenSizeService.isLargeScreen" class="button-box l-mt-8">
      <cds-button [disabled]="!isChangedFormGroupValues" [style]="'secondary'" size="sm" (click)="reset()">Reset</cds-button>
      <cds-button [disabled]="!formGroup.valid || !this.isChangedFormGroupValues" class="l-ml-4" size="sm" (click)="submit()">{{
        (currentAnnouncementId === null ? 'common.confirmAndSubmit' : 'common.confirmEdit') | lang
      }}</cds-button>
    </div>

    <div *ngIf="screenSizeService.isSmallScreen" class="button-box l-mt-6 l-d-f l-fd-col">
      <cds-button [disabled]="!formGroup.valid || !this.isChangedFormGroupValues" size="sm" (click)="submit()">{{
        (currentAnnouncementId === null ? 'common.confirmAndSubmit' : 'common.confirmEdit') | lang
      }}</cds-button>
      <cds-button [disabled]="!isChangedFormGroupValues" class="l-mt-6" [style]="'secondary'" size="sm" (click)="reset()">Reset</cds-button>
    </div>
  </div>
</div>
