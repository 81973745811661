export class EmployeeRequest {
  page?: number;
  size?: number;
  identityId?: string;
  employerId?: string;
  trusteeMbrId?: string;
  employeeName?: string;
  trusteeEmplrCd?: string;
  delegateAgentCode?: string;
  language?: string;
  ageStart?: number;
  ageEnd?: number;
  gender?: string;
  sort?: string;
  nonDelegated?: string;
  delegable?: string;
  isDelegated?: string;
  isCoolingPeriod?: string;
}
