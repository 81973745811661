<div class="split-agent-notification-info-success">
  <div class="form-long-box">
    <cds-icon class="icon-form-long" icon="form:form_long"></cds-icon>
  </div>
  <div class="l-d-f l-fd-col">
    <div>
      <span class="cds-h2-demibold">
        {{ 'common.splitAgentNotificationInfoSuccess' | lang }}
      </span>
    </div>
    <!-- <div class="l-mt-4">
      <span class="cds-h5-demibold">
        {{ 'common.redirecting-to-home' | lang }}
      </span>
    </div> -->
  </div>
</div>
