/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CdsIconConfig } from '@cds/ng-core/icon/icon.config';
import Big from 'big.js';
import { ScalePattern } from 'src/app/core/models/enum/commission-scale.enum';
import { DEFAULT_BREAKPOINT } from '../commission-scale.config';
import { BreakpointDetailVo, BreakpointVo, CommissionScaleVo } from '../commission-scale.model';

@Component({
  selector: 'app-commission-breakpoint',
  templateUrl: './commission-breakpoint.component.html',
  styleUrls: ['./commission-breakpoint.component.scss'],
})
export class CommissionBreakpointComponent {
  private breakpointErr: any = {
    year: { empty: 'Missing no. of year', exist: 'No. of year already exist' },
    amount: {
      empty: 'Missing amount',
      invalidFirst: 'ONLY FIRST OCCURENCE OF PERCENT IS ENTERED, BREAKPOINT AMOUNT MUST = BLANK',
      invalidLast: 'THE LAST BREAKPOINT VALUE SHOULD BE ',
    },
    percentage: {
      empty: 'Missing percentage',
      invalidFirst: 'FIRST OCCURENCE OF PERCENT MUST BE ENTERED',
      samePercent: 'TWO SUCCESSIVE PERCENT WITH THE SAME NON-ZERO PERCENTAGE',
    },
  };
  private maxAmount = Big('99999999999999999999.9998');

  deleteIconCfg: CdsIconConfig = { color: '#8E90A2' };
  plusIconCfg: CdsIconConfig = { color: '#EC6453' };

  private _isEditPage = false;
  private _isSubmitted = false;
  private _commissionItem!: CommissionScaleVo;
  private _dataSource!: BreakpointVo[];
  @Input()
  set isEditPage(value: boolean) {
    this._isEditPage = value;
  }
  get isEditPage() {
    return this._isEditPage;
  }

  @Input()
  set isSubmitted(value: boolean) {
    this._isSubmitted = value;
  }
  get isSubmitted() {
    return this._isSubmitted;
  }

  @Input()
  set commissionItem(item: CommissionScaleVo) {
    this._commissionItem = item;
  }
  get commissionItem() {
    return this._commissionItem;
  }

  @Input()
  set dataSource(data: BreakpointVo[]) {
    this._dataSource = data;
  }
  get dataSource() {
    return this._dataSource;
  }

  @Output() dataSourceChange = new EventEmitter();

  breakpointEnable = false;

  addOrDeleteNoOfYear(index?: number) {
    //delete number of year
    if (index !== undefined) {
      this.dataSource.splice(index, 1);
      this.validateBreakpoints();
    } else {
      //add number of year
      const newDetails = {
        yearNo: null,
        isLocked: false,
        list: [JSON.parse(JSON.stringify(DEFAULT_BREAKPOINT))],
      };
      if (this.commissionItem.scalePattern !== ScalePattern.FLAT) {
        newDetails.list.push(JSON.parse(JSON.stringify(DEFAULT_BREAKPOINT)));
        newDetails.list.push(JSON.parse(JSON.stringify(DEFAULT_BREAKPOINT)));
      }
      this.dataSource.push(newDetails);
      this.dataSource.sort((a, b) => (a.yearNo ? Number(a.yearNo) : 0) - (b.yearNo ? Number(b.yearNo) : 0));
    }
  }

  addOrDeleteBreakpoint(parentIndex: number, itemIndex?: number) {
    const flag = this.dataSource[parentIndex].list.length === 10 || this.commissionItem.scalePattern === ScalePattern.FLAT;
    if (flag && !itemIndex) return;
    //add breakpoint
    if (itemIndex === undefined) {
      this.dataSource[parentIndex].list.push(JSON.parse(JSON.stringify(DEFAULT_BREAKPOINT)));
    } else {
      //delete breakpoint
      this.dataSource[parentIndex].list.splice(itemIndex, 1);
    }

    this.validateBreakpoints(parentIndex, itemIndex);
  }

  breakpointChange(type: string, value: any, parentIndex: number, item?: BreakpointDetailVo, itemIndex?: number) {
    switch (type) {
      case 'yearNo': {
        this.dataSource[parentIndex].yearNo = value;
        break;
      }
      case 'amount': {
        let val = value.target.value || ''.replace(/,/g, '');
        const inputAmt = Big(val ? val : '0');

        if (inputAmt.gt(this.maxAmount)) {
          value.target.value = this.maxAmount.toFixed(2);
          val = value.target.value;
        }
        if (item && !Number(val)) {
          value.target.value = '';
          item.amount = '';
        } else if (item) {
          item.amount = val;
        }
        break;
      }
      case 'percentage': {
        const val = value.target.value || ''.replace(/,/g, '');
        if (item && !Number(val)) {
          value.target.value = '';
          item.percentage = '';
        } else if (item) {
          item.percentage = val;
        }
        break;
      }
    }
    this.validateBreakpoints(parentIndex, itemIndex, type === 'percentage');
  }

  //when call deleteNoOfYear(), should validate all of the breakpoints
  //when call addOrDeleteBreakpoint() or breakpointsChange(), should validate the specified breakpoints
  private validateBreakpoints(parentIndex?: number, itemIndex?: number, isPercentage?: boolean) {
    for (const data of this.dataSource) {
      const index = this.dataSource.indexOf(data);
      const validateSpecified = parentIndex !== undefined && itemIndex !== undefined;
      if (validateSpecified && index !== parentIndex) continue;

      //validate breakpoints
      data.yearError = '';
      if (!data.yearNo) {
        data.yearError = this.breakpointErr.year.empty;
      } else if (data.yearNo) {
        const len = this.dataSource.filter(item => item.yearNo === data.yearNo).length;
        if (len > 1) {
          data.yearError = this.breakpointErr.year.exist;
        }
      }

      const filterList = this.filterEmptyRow(data);
      const len = filterList.length;
      filterList.forEach((item, j) => {
        item.amountError = '';
        item.percentageError = '';

        if (!!Number(item.amount) && !!Number(item.percentage) && len === 1) {
          item.amountError = this.breakpointErr.amount.invalidFirst;
        } else if (!!Number(item.percentage) && !Number(item.amount) && len > 1) {
          item.amountError = this.breakpointErr.amount.empty;
        }
        if (len > 1 && j + 1 === len) {
          this.checkLastAmt(index);
        }

        if (!Number(item.percentage)) {
          item.percentageError = this.breakpointErr.percentage.empty;
          if (!!data.yearNo && len === 1 && !!isPercentage) {
            this.dataSource[index].list[itemIndex as number].percentageError = this.breakpointErr.percentage.invalidFirst;
            //invalid first row error of percentage, should only be display in one place
            //clear the other invalid first row error
            this.dataSource[index].list.forEach((bk, i) => {
              if (i !== itemIndex && !!bk.percentageError && bk.percentageError.indexOf('FIRST') > -1) {
                bk.percentageError = '';
              }
            });
          }
        } else {
          if (len === 1) {
            this.dataSource[index].list.forEach((detail, k) => {
              if (k !== itemIndex) {
                detail.percentageError = '';
              }
            });
          } else {
            const existLen = filterList.filter(data => Number(data.percentage) === Number(item.percentage)).length;
            if (existLen > 1) {
              item.percentageError = this.breakpointErr.percentage.samePercent;
            }
          }
        }
      });
    }

    const breakpointFlag = this.dataSource.every(
      data =>
        (!data.yearError && !!data.yearNo && this.filterEmptyRow(data).every(item => !item.amountError && !item.percentageError)) ||
        (!data.yearNo && this.filterEmptyRow(data).length === 0)
    );

    this.breakpointEnable = breakpointFlag;
    this.dataSourceChange.emit();
  }

  //filter empty row of breakpoint details
  private filterEmptyRow(data: BreakpointVo) {
    let filterList = data.list.filter(item => !!item.amount || !!item.percentage);

    if (filterList.length === 0) {
      filterList = [];
      if (data.yearNo) {
        filterList = [data.list[0]];
      } else {
        data.yearError = '';
        data.list.forEach(item => {
          item.amountError = '';
          item.percentageError = '';
        });
      }
    }
    return filterList;
  }

  //validate whether the last breakpoint's amount is equal to its previous sum
  private checkLastAmt(index: number) {
    const data = this.dataSource[index];
    const filterList = this.filterEmptyRow(data);
    const len = filterList.length;
    const lastItem = filterList[len - 1];

    if (len > 1 && !!lastItem.amount) {
      let totalAmt = Big('0');
      const lastAmt = Big(lastItem.amount ? lastItem.amount : '0');
      filterList.forEach((data, i) => {
        if (i + 1 !== len) {
          const amt = Big(data.amount ? data.amount : '0');
          totalAmt = totalAmt.add(amt);
        }
      });
      if (lastAmt.toFixed(2) !== totalAmt.toFixed(2)) {
        lastItem.amountError = this.breakpointErr.amount.invalidLast + totalAmt;
      }
    }
  }
}
