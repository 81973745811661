/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { BasicResponse } from '../../models/response/response';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CommissionHoldOnReportService {
  constructor(private http: HttpClient) {}

  getData(params: any): Observable<BasicResponse<any>> {
    return this.http.get<BasicResponse<any>>(`${environment.apiPrefix}${environment.commissionServiceExt}${'/customer-transaction/hold-on/list'}`, {
      params: params,
    });
  }
}

export interface HoldOnReportItem {
  referenceNo: string;
  accountType: string;
  subActivityCd: string;
  accountNo: string;
  memberNo: string;
  totalPremium: boolean;
  commissionPoolNo: string;
  processId: string;
  holdOnReason: string;
  freezeDate: string;
  efctvDate: string;
}
