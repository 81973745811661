import { AfterViewInit } from '@angular/core';
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit, ViewChildren, ViewChild, QueryList } from '@angular/core';
import { AbstractControl, FormControl, ValidationErrors, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CdsLangService } from '@cds/ng-core/lang';
import { CdsAlertService } from '@cds/ng-web-components/alert';
import { CdsPopupService } from '@cds/ng-web-components/popup';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { CdHttpServeService } from 'src/app/shared/cd-http-serve/cd-http-serve.service';
import { CdRadioComponent } from 'src/app/shared/cd-radio/cd-radio.component';
import { HideTextType } from 'src/app/shared/hide-text/hide-text.pipe';
import { LanguageChangeService } from 'src/app/shared/language-change.service';
import { agentCodeRemovePrefix0, pageToTop } from 'src/app/utils/utils';
import { AgentDetailInfo } from '../service/model/agent-info-response.model';
import { ErSubmitSuccessComponent } from './er-submit-success/er-submit-success.component';
import { AdviseEmpfrefComponent } from '../case-summary/advise-empfref/advise-empfref.component';
import { IffService } from '../service/iff.service';
import { TempCacheService } from 'src/app/shared/temp-cache/temp-cache.service';
import { AgentChannel } from 'src/app/core/models/enum/agent-channel.enum';
import { ContactPersonContact, EmployerColumns, PreferredLanguageType } from '../case-summary/case-summary.model';
import { DeliveryChannelType } from 'src/app/core/models/enum/delivery-channel-type.enum';
import { RegulatedActivitiesComponent } from '../regulated-activities/regulated-activities.component';
import { NotifyService } from 'src/app/core/services/notify.service';
import { AgentChannelService } from 'src/app/core/services/agent-channel.service';

@Component({
  selector: 'app-er-send-iff-in-batch-summary',
  templateUrl: './er-send-iff-in-batch-summary.component.html',
  styleUrls: ['./er-send-iff-in-batch-summary.component.scss'],
})
export class ErSendIffInBatchSummaryComponent implements OnInit, AfterViewInit {
  @ViewChildren('deliveryChannelCdRadio')
  deliveryChannelCdRadioList!: QueryList<CdRadioComponent>;
  @ViewChild('secondAgentIntermediaryContactMethodCdRadio')
  secondAgentIntermediaryContactMethodCdRadio?: CdRadioComponent;
  @ViewChild('thirdAgentIntermediaryContactMethodCdRadio')
  thirdAgentIntermediaryContactMethodCdRadio?: CdRadioComponent;
  @ViewChildren('itemPreferredLanguage')
  preferredLanguageList?: QueryList<CdRadioComponent>;
  @ViewChild('secondAgentPreferredLanguage') secondAgentPreferredLanguage?: CdRadioComponent;
  @ViewChild('thirdAgentPreferredLanguage') thirdAgentPreferredLanguage?: CdRadioComponent;
  @ViewChildren('regulatedActivities')
  regulatedActivitiesList?: QueryList<RegulatedActivitiesComponent>;

  currentStep = 1;
  DeliveryChannelType = DeliveryChannelType;
  HideTextType = HideTextType;
  PreferredLanguageType = PreferredLanguageType;

  frControl = new FormControl(undefined);
  primaryControl = new FormControl(false);
  currentStep2FrControl1 = new FormControl(true);
  currentStep2FrControl2 = new FormControl(true);
  empfFrControl = new FormControl(false);
  dataList: (EmployerColumns & { isIntragroupTransfer: FormControl })[] = [];
  secondRegulatedControl1 = new FormControl(undefined);
  secondRegulatedControl2 = new FormControl(undefined);
  readonlySecondRegulatedControl1 = new FormControl(false);
  readonlySecondRegulatedControl2 = new FormControl(false);
  secondAgentCodeAndErr = {
    agentCode: '',
    agentCodeErr: false,
  };
  thirdAgentCodeAndErr = {
    agentCode: '',
    agentCodeErr: false,
  };
  isLoadingSecondAgent = false;
  isLoadingSecondAgentSuccess = true;
  isLoadingThirdAgent = false;
  isLoadingThirdAgentSuccess = true;
  secondAgent?: AgentDetailInfo;
  thirdAgent?: AgentDetailInfo;
  multipleFrControl = new FormControl(false);
  servicingAgentCode?: string;

  deliveryChannelConfig = {
    options: [
      {
        label: 'common.email',
        value: DeliveryChannelType.Email,
        disable: true,
      },
      {
        label: 'common.sms',
        value: DeliveryChannelType.SMS,
        disable: true,
      },
    ],
  };
  _isDisableContinue = false;
  private servicingAgentId = '';
  private empfRefNo?: string;

  secondAgentPreferredPreferred?: PreferredLanguageType;
  secondAgentPreferredLanguageConfig = {
    options: [
      {
        label: 'common.chinese',
        value: PreferredLanguageType.Chinese,
        disable: true,
      },
      {
        label: 'common.english',
        value: PreferredLanguageType.English,
        disable: true,
      },
    ],
  };

  thirdAgentPreferredPreferred?: PreferredLanguageType;
  thirdAgentPreferredLanguageConfig = {
    options: [
      {
        label: 'common.chinese',
        value: PreferredLanguageType.Chinese,
        disable: true,
      },
      {
        label: 'common.english',
        value: PreferredLanguageType.English,
        disable: true,
      },
    ],
  };

  constructor(
    private router: Router,
    public cdsLangService: CdsLangService,
    private cdsPopup: CdsPopupService,
    private cdHttpServeService: CdHttpServeService,
    private alert: CdsAlertService,
    public languageChangeService: LanguageChangeService,
    private authenticationService: AuthenticationService,
    private notifyService: NotifyService,
    private iffService: IffService,
    private route: ActivatedRoute,
    private tempCacheService: TempCacheService,
    public agentChannelService: AgentChannelService
  ) {}

  ngAfterViewInit(): void {
    pageToTop();
  }

  ngOnInit(): void {
    this.languageChangeService.lanSubject.subscribe(() => {
      if (this.secondAgent) {
        this.refreshIntermediaryContact(this.secondAgent);
        this.secondAgentIntermediaryContactMethodCdRadio?.refreshConfig();
      }
      if (this.thirdAgent) {
        this.refreshIntermediaryContact(this.thirdAgent);
        this.thirdAgentIntermediaryContactMethodCdRadio?.refreshConfig();
      }
      if (this.regulatedActivitiesList) {
        this.regulatedActivitiesList.forEach(d => {
          d.refreshConfig();
        });
      }
    });

    this.frControl.valueChanges.subscribe((boo: boolean) => {
      this.primaryControl.setValue(boo);
      this.multipleFrControl.setValue(boo);
      this.secondRegulatedControl1.setValue(undefined);
      this.secondRegulatedControl2.setValue(undefined);

      if (!boo) {
        if (this.secondAgentCodeAndErr.agentCode) {
          this.secondRegulatedControl1.setValue(false);
        }
        if (this.thirdAgentCodeAndErr.agentCode) {
          this.secondRegulatedControl2.setValue(false);
        }
      }
    });

    this.empfRefNo = this.route.snapshot.paramMap.get('empfRefNo') || undefined;
    if (this.empfRefNo) {
      const data = this.tempCacheService.getItem(`case-summary-er-${this.empfRefNo}`);
      if (data) {
        this.dataList = [data as EmployerColumns & { isIntragroupTransfer: FormControl }];
      }
    } else {
      const data = localStorage.getItem('case-summary-selected-data');
      if (data) {
        this.dataList = JSON.parse(data);
      }
    }

    if (this.dataList.length > 0) {
      const customerIdList: string[] = [];
      this.dataList.forEach(element => {
        if (element.customerId) {
          customerIdList.push(element.customerId);
        }
        element.rmReferralCode = new FormControl('', [Validators.required, this.rmReferralCodeVali]);
        element.inputCustomerId = new FormControl('', [this.inputCustomerIdVali]);
        element.sourceCode = new FormControl('', [this.sourceCodeVali]);
        element.campaignCode = new FormControl('', [this.campaignCodeVali]);
        element.isIntragroupTransfer = new FormControl({ value: false, disabled: false });
      });
      this.postMemberContacts(customerIdList);

      if (this.dataList.length === 1 && this.dataList[0].SplitCase) {
        this.getAgentCode(this.dataList[0].salesJourneySummaryId).then(agentCodeList => {
          if (agentCodeList && agentCodeList.length > 0) {
            this.dataList[0].secondAgentCode = agentCodeList[0];
            this.dataList[0].thirdAgentCode = agentCodeList[1];
            this.secondAgentCodeAndErr.agentCode = agentCodeList[0];
            this.thirdAgentCodeAndErr.agentCode = agentCodeList[1];
          }
        });
      }
    }

    this.secondRegulatedControl1.valueChanges.subscribe((boo: boolean) => {
      this.currentStep2FrControl1.setValue(boo);
      this.readonlySecondRegulatedControl1.setValue(boo);
    });
    this.secondRegulatedControl2.valueChanges.subscribe((boo: boolean) => {
      this.currentStep2FrControl2.setValue(boo);
      this.readonlySecondRegulatedControl2.setValue(boo);
    });
    this.authenticationService.currentUserValue().then(res => {
      this.servicingAgentId = res.id + '';

      this.iffService.getAgentCode(this.servicingAgentId).subscribe({
        next: res => {
          if (res.result !== 0) {
            this.alert.warning('Warning!', `${res.message}`);
          } else {
            this.servicingAgentCode = res.data.agentCode;
          }
        },
        error: err => {
          this.alert.error('Error!', err);
        },
      });
    });
  }

  rmReferralCodeVali = (control: AbstractControl): ValidationErrors | null => {
    let input = control.value || '';
    input = input.trim();
    const isValid = input.length >= 6 && input.length <= 8;
    return isValid ? null : { lengthError: true };
  };

  inputCustomerIdVali = (control: AbstractControl): ValidationErrors | null => {
    let input = control.value || '';
    input = input.trim();
    const isValid = ((input.length >= 6 && input.length <= 8) || input.length === 0) && /^[A-Za-z0-9-]*$/.exec(input);
    return isValid ? null : { lengthError: true };
  };

  sourceCodeVali = (control: AbstractControl): ValidationErrors | null => {
    let input = control.value || '';
    input = input.trim();
    const isValid = (input.length === 10 || input.length === 0) && /^[A-Za-z0-9-]*$/.exec(input);
    return isValid ? null : { lengthError: true };
  };

  campaignCodeVali = (control: AbstractControl): ValidationErrors | null => {
    let input = control.value || '';
    input = input.trim();
    const isValid = (input.length === 10 || input.length === 0) && /^[A-Za-z0-9-]*$/.exec(input);
    return isValid ? null : { lengthError: true };
  };

  thirdAgentIntermediaryContactMethodValueChange(value?: string) {
    this.thirdAgentPreferredPreferred = undefined;
    if (value === DeliveryChannelType.SMS) {
      this.thirdAgentPreferredLanguageConfig = {
        options: [
          {
            label: 'common.chinese',
            value: PreferredLanguageType.Chinese,
            disable: false,
          },
          {
            label: 'common.english',
            value: PreferredLanguageType.English,
            disable: false,
          },
        ],
      };
    } else if (value === DeliveryChannelType.Email) {
      this.thirdAgentPreferredLanguageConfig = {
        options: [
          {
            label: 'common.chinese',
            value: PreferredLanguageType.Chinese,
            disable: true,
          },
          {
            label: 'common.english',
            value: PreferredLanguageType.English,
            disable: true,
          },
        ],
      };
    }
    if (this.thirdAgentPreferredLanguage) {
      this.thirdAgentPreferredLanguage.refreshConfig();
    }
  }

  secondAgentIntermediaryContactMethodValueChange(value?: string) {
    this.secondAgentPreferredPreferred = undefined;
    if (value === DeliveryChannelType.SMS) {
      this.secondAgentPreferredLanguageConfig = {
        options: [
          {
            label: 'common.chinese',
            value: PreferredLanguageType.Chinese,
            disable: false,
          },
          {
            label: 'common.english',
            value: PreferredLanguageType.English,
            disable: false,
          },
        ],
      };
    } else if (value === DeliveryChannelType.Email) {
      this.secondAgentPreferredLanguageConfig = {
        options: [
          {
            label: 'common.chinese',
            value: PreferredLanguageType.Chinese,
            disable: true,
          },
          {
            label: 'common.english',
            value: PreferredLanguageType.English,
            disable: true,
          },
        ],
      };
    }
    if (this.secondAgentPreferredLanguage) {
      this.secondAgentPreferredLanguage.refreshConfig();
    }
  }

  backOne() {
    this.currentStep = 1;
    this.isLoadingSecondAgentSuccess = true;
    this.isLoadingThirdAgentSuccess = true;
  }

  get isDisableSecondRegulatedControl1() {
    return !this.frControl.value || this.secondAgentCodeAndErr.agentCode.length === 0;
  }
  get isDisableSecondRegulatedControl2() {
    return !this.frControl.value || this.secondAgentCodeAndErr?.agentCode?.length === 0 || this.thirdAgentCodeAndErr?.agentCode?.length === 0;
  }

  postMemberContacts(customerIdList: string[]) {
    this.cdHttpServeService
      .post<
        {
          contactPersonContacts: ContactPersonContact[];
          employerId: string;
        }[]
      >(`/ext/eb-ssms-sales-journey-service/iffByRecordList/contacts/employer`, customerIdList)
      .subscribe({
        next: res => {
          if (res.result !== 0) {
            this.alert.warning('Warning!', `${res.message}`);
          } else {
            this.dataList.forEach(item => {
              const a = res.data.find(d => d.employerId === item.customerId);
              if (a) {
                item.contactPersonContacts = a.contactPersonContacts;
                item.authorizedPersonNameControl = new FormControl(null);
                item.authorizedPersonNameConfigEng = {
                  options: [],
                };
                item.authorizedPersonNameConfigChi = {
                  options: [],
                };

                item.contactPersonContacts.forEach(contact => {
                  item.authorizedPersonNameConfigEng?.options.push({
                    label: contact.contactPersonName,
                    value: contact.contactPersonId,
                  });
                  item.authorizedPersonNameConfigChi?.options.push({
                    label: contact.contactPersonNameChi,
                    value: contact.contactPersonId,
                  });

                  contact.deliveryChannelConfig = {
                    options: [
                      {
                        label: 'common.email',
                        value: DeliveryChannelType.Email,
                        disable: true,
                      },
                      {
                        label: 'common.sms',
                        value: DeliveryChannelType.SMS,
                        disable: true,
                      },
                    ],
                  };
                  if (contact.emailAddr) {
                    contact.deliveryChannelConfig.options[0].disable = false;
                  }
                  if (contact.phoneNumber) {
                    contact.deliveryChannelConfig.options[1].disable = false;
                  }
                });

                if (item.authorizedPersonNameConfigEng) {
                  item.authorizedPersonNameConfigEng.placeholder = 'Select Authorized Person Name';
                }
                if (item.authorizedPersonNameConfigChi) {
                  item.authorizedPersonNameConfigChi.placeholder = '選擇獲授權人士全名';
                }

                if (item.authorizedPersonNameConfigEng?.options.length === 1) {
                  item.authorizedPersonNameControl.setValue(item.authorizedPersonNameConfigEng?.options[0].value);
                  this.authorizedPersonNameControlChange(item);
                }
                if (item.authorizedPersonNameConfigChi?.options.length === 1) {
                  item.authorizedPersonNameControl.setValue(item.authorizedPersonNameConfigChi?.options[0].value);
                  this.authorizedPersonNameControlChange(item);
                }
              }

              item.preferredLanguageConfig = {
                options: [
                  {
                    label: 'common.chinese',
                    value: PreferredLanguageType.Chinese,
                    disable: true,
                  },
                  {
                    label: 'common.english',
                    value: PreferredLanguageType.English,
                    disable: true,
                  },
                ],
              };
            });
          }
        },
        error: err => {
          this.alert.error('Error!', err);
        },
      });
  }

  itemDeliveryChannelChange(item: EmployerColumns) {
    item.preferredLanguage = undefined;
    if (item.deliveryChannel === DeliveryChannelType.SMS) {
      item.preferredLanguageConfig = {
        options: [
          {
            label: 'common.chinese',
            value: PreferredLanguageType.Chinese,
            disable: false,
          },
          {
            label: 'common.english',
            value: PreferredLanguageType.English,
            disable: false,
          },
        ],
      };
    } else {
      item.preferredLanguageConfig = {
        options: [
          {
            label: 'common.chinese',
            value: PreferredLanguageType.Chinese,
            disable: true,
          },
          {
            label: 'common.english',
            value: PreferredLanguageType.English,
            disable: true,
          },
        ],
      };
    }
    if (this.preferredLanguageList) {
      this.preferredLanguageList.forEach(d => {
        d.refreshConfig();
      });
    }
  }

  goBackWithData() {
    this.router.navigate(['/salesjourney/product-type-summary/case-summary']);
  }

  get dbsInputHasErrors() {
    if (!this.isChannelDBS) {
      return false;
    }
    return !this.dataList.every(item => {
      return !(
        (item.rmReferralCode && item.rmReferralCode.dirty && item.rmReferralCode.errors) ||
        (item.campaignCode && item.campaignCode.dirty && item.campaignCode.errors) ||
        (item.sourceCode && item.sourceCode.dirty && item.sourceCode.errors) ||
        (item.inputCustomerId && item.inputCustomerId.dirty && item.inputCustomerId.errors)
      );
    });
  }

  private refreshIntermediaryContact(agentDetailInfo: AgentDetailInfo) {
    let shortEmail = '';
    if (agentDetailInfo.emailAddress) {
      const ea = agentDetailInfo.emailAddress;
      const endIndex = ea.indexOf('@');
      shortEmail = ea.substring(0, 4) + '*****' + ea.substring(endIndex);
    }

    let shortPhone = '';
    if (agentDetailInfo.mobileNumber) {
      const mn = agentDetailInfo.mobileNumber;
      let phoneArr = new Array((mn + '').length - 3);
      phoneArr = phoneArr.fill('*');
      shortPhone = mn.substring(0, 1) + phoneArr.join('') + mn.substring((mn + '').length - 2);
    }

    agentDetailInfo.intermediaryContactMethodConfig = {
      options: [
        {
          label: this.cdsLangService.translate('sendIffInBatchSummary.contactMethodEmail') + (agentDetailInfo.emailAddress ? `(${shortEmail})` : ''),
          value: DeliveryChannelType.Email,
          disable: !agentDetailInfo.emailAddress,
        },
        {
          label: this.cdsLangService.translate('sendIffInBatchSummary.contactMethodPhone') + (agentDetailInfo.mobileNumber ? `(${shortPhone})` : ''),
          value: DeliveryChannelType.SMS,
          disable: !agentDetailInfo.mobileNumber,
        },
      ],
    };

    if (!agentDetailInfo.intermediaryContactMethodValue) {
      if (agentDetailInfo.emailAddress) {
        agentDetailInfo.intermediaryContactMethodValue = DeliveryChannelType.Email;
      } else if (agentDetailInfo.mobileNumber) {
        agentDetailInfo.intermediaryContactMethodValue = DeliveryChannelType.SMS;
      }
    }
  }

  getIntermediaryContactMethod(agent?: AgentDetailInfo) {
    if (!agent) {
      return undefined;
    }

    if (agent.intermediaryContactMethodValue === DeliveryChannelType.Email) {
      return agent.emailAddress;
    } else {
      return agent.mobileNumber;
    }
  }

  authorizedPersonNameControlChange(er: EmployerColumns) {
    const ap = this.getAuthorizedPerson(er);

    if (ap && ap.deliveryChannelConfig) {
      if (!ap.deliveryChannelConfig.options[0].disable) {
        er.deliveryChannel = DeliveryChannelType.Email;
      } else if (!ap.deliveryChannelConfig.options[1].disable) {
        er.deliveryChannel = DeliveryChannelType.SMS;
      } else {
        er.deliveryChannel = undefined;
      }
    } else {
      er.deliveryChannel = undefined;
    }

    this.deliveryChannelCdRadioList.forEach(element => {
      element.refreshConfig();
    });

    this.itemDeliveryChannelChange(er);
  }

  getAuthorizedPerson(er: EmployerColumns) {
    return er.contactPersonContacts?.find(item => item.contactPersonId === er.authorizedPersonNameControl?.value);
  }

  multipleContinue() {
    if (this.isDisableContinue || this.dbsInputHasErrors) {
      return;
    }

    if (this.isChannelDBS) {
      let isStop = false;
      for (let i = 0; i < this.dataList.length; i++) {
        if (this.dataList[i].rmReferralCode?.errors) {
          this.dataList[i].rmReferralCode?.markAsDirty();
          isStop = true;
        }
      }
      if (isStop) {
        return;
      }
    }

    this.dataList.forEach(item => {
      item.isIntragroupTransfer.disable();
    });
    this.currentStep = 12;
    pageToTop();
  }

  backStep1() {
    this.dataList.forEach(item => {
      item.isIntragroupTransfer.enable();
    });
    this.currentStep = 1;
  }

  secondAgentCodeChange(val: string) {
    if (val.length === 0) {
      this.secondAgentCodeAndErr.agentCodeErr = false;
      this.thirdAgentCodeAndErr.agentCode = '';
      this.secondRegulatedControl2.setValue(undefined);
      this.secondRegulatedControl1.setValue(undefined);
    } else {
      if (!this.frControl.value) {
        this.secondRegulatedControl1.setValue(false);
      }
    }
  }

  thirdAgentCodeChange(val: string) {
    if (val.length === 0) {
      this.thirdAgentCodeAndErr.agentCodeErr = false;
      this.secondRegulatedControl2.setValue(undefined);
    } else {
      if (!this.frControl.value) {
        this.secondRegulatedControl2.setValue(false);
      }
    }
  }

  getEmployerName(er: EmployerColumns) {
    if (this.cdsLangService.currentLang === 'en') {
      return er.EmployerName || er.EmployerNameTC;
    } else {
      return er.EmployerNameTC || er.EmployerName;
    }
  }

  goCaseSummary() {
    localStorage.removeItem('case-summary-search-params');
    this.router.navigate(['/salesjourney/product-type-summary/case-summary']);
  }

  get isDisableContinue() {
    return (
      this._isDisableContinue ||
      this.isLoadingSecondAgent ||
      this.isLoadingThirdAgent ||
      !this.dataList.every(item => {
        return (
          item.authorizedPersonNameControl?.value &&
          (item.deliveryChannel === DeliveryChannelType.Email || (item.deliveryChannel === DeliveryChannelType.SMS && item.preferredLanguage))
        );
      }) ||
      (this.frControl.value !== true && this.frControl.value !== false) ||
      (this.frControl.value === true &&
        this.secondAgentCodeAndErr.agentCode &&
        this.secondRegulatedControl1.value !== true &&
        this.secondRegulatedControl1.value !== false) ||
      (this.frControl.value === true &&
        this.thirdAgentCodeAndErr.agentCode &&
        this.secondRegulatedControl2.value !== true &&
        this.secondRegulatedControl2.value !== false)
    );
  }

  onDisplayAdviseRef() {
    this.cdsPopup.open(AdviseEmpfrefComponent, {
      size: 'lg',
    });
  }

  agentInformation(agentCodeAndErr: { agentCode: string; agentCodeErr: boolean }) {
    return new Promise<AgentDetailInfo>((resolve, reject) => {
      this.cdHttpServeService
        .post<{
          agentCode: string;
          name: string;
          chineseName: string;
          errorCode?: number;
        }>('/ext/eb-ssms/customer-service/add-delegation/validate', {
          agentCode: agentCodeRemovePrefix0(agentCodeAndErr.agentCode),
        })
        .subscribe({
          next: res => {
            if (res.result !== 0) {
              agentCodeAndErr.agentCodeErr = true;
              reject();
            } else {
              agentCodeAndErr.agentCodeErr = false;
              this.cdHttpServeService
                .get<AgentDetailInfo>(`/ext/eb-ssms/agent-service/agent/information`, {
                  agentInfo: agentCodeRemovePrefix0(agentCodeAndErr.agentCode),
                })
                .subscribe({
                  next: res => {
                    if (res.result !== 0) {
                      this.alert.warning('Warning!', `${res.message}`);
                      reject();
                    } else {
                      resolve(res.data);
                    }
                  },
                  error: err => {
                    this.alert.error('Error!', err);
                    reject();
                  },
                });
            }
          },
          error: err => {
            this.alert.error('Error!', err);
            agentCodeAndErr.agentCodeErr = true;
            reject();
          },
        });
    });
  }

  stepOneContinue() {
    if (this.isDisableContinue || this.dbsInputHasErrors) {
      return;
    }

    if (this.isChannelDBS) {
      if (this.dataList[0].rmReferralCode?.errors) {
        this.dataList[0].rmReferralCode?.markAsDirty();
        return;
      }
    }

    this.secondAgent = undefined;
    this.thirdAgent = undefined;

    if (!this.secondAgentCodeAndErr.agentCode && !this.thirdAgentCodeAndErr.agentCode) {
      this.getSingleSelected.isIntragroupTransfer.disable();
      this.currentStep = 3;
      pageToTop();
      return;
    }

    this.secondAgentCodeAndErr.agentCodeErr = false;
    if (this.secondAgentCodeAndErr.agentCode) {
      if (this.servicingAgentCode === this.secondAgentCodeAndErr.agentCode) {
        this.secondAgentCodeAndErr.agentCodeErr = true;
        return;
      }
    }

    this.thirdAgentCodeAndErr.agentCodeErr = false;
    if (this.thirdAgentCodeAndErr.agentCode) {
      if (this.servicingAgentCode === this.thirdAgentCodeAndErr.agentCode || this.secondAgentCodeAndErr.agentCode === this.thirdAgentCodeAndErr.agentCode) {
        this.thirdAgentCodeAndErr.agentCodeErr = true;
        return;
      }
    }

    if (this.secondAgentCodeAndErr.agentCode) {
      this.isLoadingSecondAgent = true;
      this.isLoadingSecondAgentSuccess = false;
      this.agentInformation(this.secondAgentCodeAndErr)
        .then(data => {
          this.secondAgent = data;
          this.isLoadingSecondAgent = false;
          this.isLoadingSecondAgentSuccess = true;

          this.refreshIntermediaryContact(this.secondAgent);
          this.secondAgentIntermediaryContactMethodCdRadio?.refreshConfig();

          if (this.isLoadingThirdAgentSuccess) {
            this.isLoadingSecondAgentSuccess = false;
            this.isLoadingThirdAgentSuccess = false;
            this.currentStep = 2;
            this.secondAgentIntermediaryContactMethodValueChange(this.secondAgent.intermediaryContactMethodValue);
            this.thirdAgentIntermediaryContactMethodValueChange(this.thirdAgent?.intermediaryContactMethodValue);
            pageToTop();
          }
        })
        .catch(() => {
          this.isLoadingSecondAgent = false;
          this.isLoadingSecondAgentSuccess = false;
        });
    }

    if (this.thirdAgentCodeAndErr.agentCode) {
      this.isLoadingThirdAgent = true;
      this.isLoadingThirdAgentSuccess = false;
      this.agentInformation(this.thirdAgentCodeAndErr)
        .then(data => {
          this.thirdAgent = data;
          this.isLoadingThirdAgent = false;
          this.isLoadingThirdAgentSuccess = true;

          this.refreshIntermediaryContact(data);
          this.thirdAgentIntermediaryContactMethodCdRadio?.refreshConfig();

          if (this.isLoadingSecondAgentSuccess) {
            this.isLoadingSecondAgentSuccess = false;
            this.isLoadingThirdAgentSuccess = false;
            this.currentStep = 2;
            this.secondAgentIntermediaryContactMethodValueChange(this.secondAgent?.intermediaryContactMethodValue);
            this.thirdAgentIntermediaryContactMethodValueChange(this.thirdAgent?.intermediaryContactMethodValue);
            pageToTop();
          }
        })
        .catch(() => {
          this.isLoadingThirdAgent = false;
          this.isLoadingThirdAgentSuccess = false;
        });
    }
  }

  get getSingleSelected() {
    return this.dataList[0];
  }

  getAgentName(secondAgent?: AgentDetailInfo) {
    if (!secondAgent) {
      return '';
    }

    if (this.cdsLangService.currentLang === 'en') {
      return secondAgent.agentName || secondAgent.agentNameTC;
    } else {
      return secondAgent.agentNameTC || secondAgent.agentName;
    }
  }

  get disableStepTwoContinue() {
    return Boolean(
      (this.secondAgent && this.secondAgent.intermediaryContactMethodValue === DeliveryChannelType.SMS && !this.secondAgentPreferredPreferred) ||
        (this.thirdAgent && this.thirdAgent.intermediaryContactMethodValue === DeliveryChannelType.SMS && !this.thirdAgentPreferredPreferred)
    );
  }

  stepTwoContinue() {
    if (this.disableStepTwoContinue) {
      return;
    }

    this.getSingleSelected.isIntragroupTransfer.disable();
    this.currentStep = 3;
    pageToTop();
  }

  stepThreeBack() {
    this.getSingleSelected.isIntragroupTransfer.enable();
    if (!this.thirdAgent && !this.secondAgent) {
      this.currentStep = 1;
    } else {
      this.currentStep = 2;
    }
    pageToTop();
  }

  submit() {
    let isToAgentConfirmation = false;
    const data = this.dataList.map(element => {
      const authorizedPerson = this.getAuthorizedPerson(element);
      const agentInfos: any[] = [
        {
          agentIdentityId: this.servicingAgentId,
          regulatedAct: this.frControl.value,
          primaryAgent: true,
          agentNotificationChannel:
            element.deliveryChannel === DeliveryChannelType.Email ? 'E' : element.deliveryChannel === DeliveryChannelType.SMS ? 'M' : '',
        },
      ];

      if (this.secondAgent?.agentId) {
        const a: any = {
          agentid: this.secondAgent.agentId,
          regulatedAct: this.secondRegulatedControl1.value || false,
          primaryAgent: false,
        };
        agentInfos.push(a);
        if (this.secondRegulatedControl1.value) {
          a.agentNotificationChannel =
            this.secondAgent.intermediaryContactMethodValue === DeliveryChannelType.Email
              ? 'E'
              : this.secondAgent.intermediaryContactMethodValue === DeliveryChannelType.SMS
              ? 'M'
              : '';
          if (this.secondAgent.intermediaryContactMethodValue === DeliveryChannelType.SMS) {
            a['smsLanguage'] = this.secondAgentPreferredPreferred === PreferredLanguageType.English ? 0 : 1;
          }
        }
      }

      if (this.thirdAgent?.agentId) {
        const a: any = {
          agentid: this.thirdAgent.agentId,
          regulatedAct: this.secondRegulatedControl2.value || false,
          primaryAgent: false,
        };
        agentInfos.push(a);
        if (this.secondRegulatedControl2.value) {
          a.agentNotificationChannel =
            this.thirdAgent.intermediaryContactMethodValue === DeliveryChannelType.Email
              ? 'E'
              : this.thirdAgent.intermediaryContactMethodValue === DeliveryChannelType.SMS
              ? 'M'
              : '';
          if (this.thirdAgent.intermediaryContactMethodValue === DeliveryChannelType.SMS) {
            a['smsLanguage'] = this.thirdAgentPreferredPreferred === PreferredLanguageType.English ? 0 : 1;
          }
        }
      }

      isToAgentConfirmation = agentInfos.some((info, index: number) => {
        if (index > 0) {
          return info.regulatedAct;
        }
        return false;
      });

      const result: any = {
        authPersonId: authorizedPerson?.contactPersonId,
        authPersonName: authorizedPerson?.contactPersonName,
        authPersonFirstName: authorizedPerson?.contactPersonFirstName,
        authPersonLastName: authorizedPerson?.contactPersonLastName,
        salesJourneySummaryId: element.salesJourneySummaryId,
        empfRefNo: element.eMPFRef,
        caseSplit: agentInfos.length > 1,
        emailAddr: authorizedPerson?.emailAddr,
        countryCallingCode: authorizedPerson?.countryCallingCode,
        mobile: authorizedPerson?.phoneNumber,
        clientCommunicationChannel:
          element?.deliveryChannel === DeliveryChannelType.Email ? 'E' : element?.deliveryChannel === DeliveryChannelType.SMS ? 'M' : '',
        agentInfos,
        dbs: element.rmReferralCode?.value
          ? {
              dbsRmReferralCd: element.rmReferralCode.value,
              dbsCustomerId: element.inputCustomerId?.value,
              dbsCampaignCd: element.campaignCode?.value,
              dbsSourceCd: element.sourceCode?.value,
            }
          : undefined,
        intraGroup: element.isIntragroupTransfer.value,
      };
      if (element.deliveryChannel === DeliveryChannelType.SMS) {
        result['smsLanguage'] = element.preferredLanguage === PreferredLanguageType.English ? 0 : 1;
      }
      if (!result.mobile) {
        delete result.mobile;
        delete result.countryCallingCode;
      }
      if (!result.emailAddr) {
        delete result.emailAddr;
      }
      if (!this.isChannelDBS) {
        delete result.dbs;
      }
      return result;
    });
    this.notifyService.screenLoadingSubject.next(true);
    this.cdHttpServeService.post(`/ext/eb-ssms-sales-journey-service/iffByRecordList/submissions`, data).subscribe({
      next: res => {
        if (res.result === 0) {
          let msg = '';
          if (isToAgentConfirmation) {
            msg = 'sendIffInBatchSummary.popupScreenTitleForSplitAgent';
          } else {
            msg = 'sendIffInBatchSummary.popupScreenTitleCustomer';
          }
          const ref = this.cdsPopup.open(ErSubmitSuccessComponent, {
            data: {
              message: this.cdsLangService.translate(msg),
            },
          });
          ref.afterClosed().subscribe(agree => {
            if (agree) {
              localStorage.removeItem('case-summary-search-params');
              this.router.navigate(['/salesjourney/product-type-summary/case-summary']);
            }
          });
        } else {
          this.alert.error('Error!', res.message);
        }
        this.notifyService.screenLoadingSubject.next(false);
      },
      error: err => {
        this.notifyService.screenLoadingSubject.next(false);
        this.alert.error('Error!', err);
      },
    });
  }

  getAgentCode(salesJourneySummaryId: string) {
    return new Promise<string[]>(resolve => {
      this.cdHttpServeService.post<string[]>('/ext/eb-ssms-sales-journey-service/iffByRecordList/empfSplitAgentCd', { salesJourneySummaryId }).subscribe({
        next: res => {
          if (res.result !== 0) {
            this.alert.warning('Warning!', `${res.message}`);
          } else {
            resolve(res.data);
          }
        },
        error: err => {
          this.alert.error('Error!', err);
        },
      });
    });
  }

  get isChannelDBS() {
    return this.agentChannelService.channel === AgentChannel.DBS;
  }

  get isDisabled() {
    if (this.agentChannelService.channel === AgentChannel.DBS) {
      return true;
    }
    if (this.agentChannelService.channel === AgentChannel.CORPORATE && this.agentChannelService.agentCode?.startsWith('400')) {
      return true;
    }
    return false;
  }
}
