import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CdsLanguageModule } from '@cds/ng-core/lang';
import { CdsPopupModule } from '@cds/ng-web-components/popup';
import { CdsButtonModule } from '@cds/ng-web-components/button';

import { ContinuePopupComponent } from './continue-popup.component';

@NgModule({
  imports: [CommonModule, CdsPopupModule, CdsLanguageModule, CdsButtonModule],
  declarations: [ContinuePopupComponent],
  exports: [ContinuePopupComponent],
})
export class ContinuePopupModule {}
