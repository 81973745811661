/* eslint-disable @typescript-eslint/no-explicit-any */
import { Pipe, PipeTransform } from '@angular/core';
import { PermissionService } from 'src/app/core/services/permission.service';

@Pipe({ name: 'permission' })
export class PermissionPipe implements PipeTransform {
  constructor(private permissionService: PermissionService) {}

  transform(role: any, permission: any): Promise<boolean> {
    if (role && permission) {
      return this.permissionService.hasPermission(role, permission);
    }
    return new Promise(resolve => {
      resolve(false);
    });
  }
}
