import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { DateTime } from 'luxon';
import moment from 'moment';
import { deepCopy } from 'src/app/utils/copy';

/**
 * Determine whether there are duplicated controls at the same form level.
 *
 * @param errorMessageKey
 */
export function duplicatedValidator(errorMessageKey: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value || !control.parent?.controls) {
      return null;
    }
    let dulplicatedCount = 0;
    (control.parent?.controls as AbstractControl[]).forEach((item: AbstractControl) => {
      if (item.value === control.value) {
        dulplicatedCount++;
      }
    });
    // have duplicated item except itself
    return dulplicatedCount > 1 ? { dulplicated: errorMessageKey } : null;
  };
}

/**
 * Accept English and numeric, allow space between.
 *
 * @param errorMessageKey
 */
export function nameValidator(errorMessageKey: string, supportChinese = false): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) {
      return null;
    }
    const regex = /^[A-Za-z)0-9.]+(\s+[A-Za-z)0-9.]+)*$/;
    const regexWithChinese = /^[A-Za-z)0-9\u4E00-\u9FFF.（）]+(\s+[A-Za-z)0-9\u4E00-\u9FFF.（）]+)*$/;
    const available = (supportChinese ? regexWithChinese : regex).test(control.value);

    return available ? null : { nameInvalid: errorMessageKey };
  };
}

/**
 * Allow English, numeric and special characters, allow space between.
 *
 * @param errorMessageKey
 */
export function descriptionValidator(errorMessageKey: string, supportChinese = false, onlySupportChinese = false): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) {
      return null;
    }
    let regex: RegExp;
    const englishRegex = /^[A-Za-z)0-9`~!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+(\s+[A-Za-z)0-9`~!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+)*$/;
    const chineseRegex =
      /^[\u4E00-\u9fa5a-zA-Z0-9`~!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?·~！@#￥%……&*（）——【】、；：‘’“”，《。》、？]+(\s+[\u3400-\u9FFFa-zA-Z0-9`~!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?·~！@#￥%……&*（）——【】、；：‘’“”，《。》、？\s]+)*$/;
    const onlyChineseRegex =
      /^[\u4E00-\u9fa50-9`~!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?·~！@#￥%……&*（）——【】、；：‘’“”，《。》、？]+(\s+[\u3400-\u9FFF0-9`~!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?·~！@#￥%……&*（）——【】、；：‘’“”，《。》、？\s]+)*$/;

    if (supportChinese) {
      if (onlySupportChinese) {
        regex = onlyChineseRegex;
      } else {
        regex = chineseRegex;
      }
    } else {
      regex = englishRegex;
    }

    return regex.test(control.value) ? null : { descriptionInvalid: errorMessageKey };
  };
}

/**
 * Allow date format 'DD/MM/YYYY'.
 *
 * @param errorMessageKey
 */
export function dateValidator(errorMessageKey: string, minDate?: string, maxDate?: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) {
      return null;
    }

    const regex = /^(0[1-9]|[1-2][0-9]|3[0-1])\/(0[1-9]|1[0-2])\/\d{4}$/;
    const regexAvailable = regex.test(control.value);

    let rangeAvailable = true;

    if (regexAvailable) {
      const date = moment(control.value, 'DD/MM/YYYY');
      if (!date.isValid()) {
        rangeAvailable = false;
      }
      if (minDate && date.diff(moment(minDate, 'DD/MM/YYYY'), 'days') < 0) {
        rangeAvailable = false;
      }
      if (maxDate && date.diff(moment(maxDate, 'DD/MM/YYYY'), 'days') > 0) {
        rangeAvailable = false;
      }
    }

    return regexAvailable && rangeAvailable ? null : { dateInvalid: errorMessageKey };
  };
}

/**
 * Validator for number range. startValue can not bigger than endValue.
 *
 * @param errorMessageKey
 * @param startValueControlName
 * @param endValueControlName
 * @param needBoth if need input both startValue and endValue
 */
export function rangeValidator(errorMessageKey: string, startValueControlName: string, endValueControlName: string, needBoth = false): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const startControl = control.get(startValueControlName);
    const endControl = control.get(endValueControlName);
    const startValue = startControl?.value;
    const endValue = endControl?.value;
    const startErrors = startControl?.errors ? deepCopy(startControl?.errors) : {};
    const endErrors = endControl?.errors ? deepCopy(endControl?.errors) : {};

    const regex = /^[0-9]+$/;
    if ((startValue && !regex.test(startValue)) || (endValue && !regex.test(endValue))) {
      delete startErrors['invalidRange'];
      delete endErrors['invalidRange'];
      startControl?.setErrors(Object.keys(startErrors).length === 0 ? null : startErrors);
      endControl?.setErrors(Object.keys(endErrors).length === 0 ? null : endErrors);
      return null;
    }

    if (startValue !== '' && endValue !== '' && Number(startValue) > Number(endValue)) {
      startErrors['invalidRange'] = errorMessageKey;
      endErrors['invalidRange'] = errorMessageKey;
      startControl?.setErrors(startErrors);
      endControl?.setErrors(endErrors);
      return null;
    }

    if (needBoth) {
      if (startValue === '' && endValue !== '') {
        startErrors['required'] = errorMessageKey;
        startControl?.markAsTouched();
        startControl?.markAsDirty();
      }
      if (startValue !== '' && endValue === '') {
        endErrors['required'] = errorMessageKey;
        endControl?.markAsTouched();
        endControl?.markAsDirty();
      }
      if (startValue === '' && endValue === '') {
        delete startErrors['required'];
        delete endErrors['required'];
        // startControl?.markAsTouched();
        // startControl?.markAsDirty();
        // endControl?.markAsTouched();
        // endControl?.markAsDirty();
      }
    }

    delete startErrors['invalidRange'];
    delete endErrors['invalidRange'];
    startControl?.setErrors(Object.keys(startErrors).length === 0 ? null : startErrors);
    endControl?.setErrors(Object.keys(endErrors).length === 0 ? null : endErrors);
    return null;
  };
}

/**
 * Validator for startDate and endDate, startDate cannot later than endDate.
 * Allow date format 'dd/MM/yyyy'.
 *
 * @param errorMessageKey
 * @param startValue
 * @param endValue
 */
export function startAndEndDateValidator(errorMessageKey: string, startValue: string, endValue: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const startControl = control.get(startValue);
    const endControl = control.get(endValue);
    const createStartDate = DateTime.fromFormat(startControl?.value, 'dd/MM/yyyy');
    const createEndDate = DateTime.fromFormat(endControl?.value, 'dd/MM/yyyy');
    const startErrors = startControl?.errors ? deepCopy(startControl?.errors) : {};
    const endErrors = endControl?.errors ? deepCopy(endControl?.errors) : {};
    if (createStartDate.isValid && createEndDate.isValid) {
      if (createStartDate > createEndDate) {
        startErrors['startLaterThanEnd'] = errorMessageKey;
        endErrors['startLaterThanEnd'] = errorMessageKey;
        startControl?.setErrors(startErrors);
        endControl?.setErrors(endErrors);
        return null;
      }
    }

    delete startErrors['startLaterThanEnd'];
    delete endErrors['startLaterThanEnd'];
    startControl?.setErrors(Object.keys(startErrors).length === 0 ? null : startErrors);
    endControl?.setErrors(Object.keys(endErrors).length === 0 ? null : endErrors);
    return null;
  };
}

/**
 * Max number.
 *
 * @param max
 */
export function maxValidator(max: number): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) {
      return null;
    }

    return control.value <= max ? null : { max: true };
  };
}

/**
 * Min number.
 *
 * @param min
 */
export function minValidator(min: number): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) {
      return null;
    }

    return control.value >= min ? null : { min: true };
  };
}

/**
 * Agent code str list, agent code accept English and numeric, each agent code maxlength is 50, use comma as separator.
 *
 * @param errorMessageKey
 */
export function agentCodeListValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) {
      return null;
    }
    const regex = /^[\d]+(,[\d]+)*$/;
    if (!regex.test(control.value)) {
      return { agentCodeListInvalid: 'Invalid Agent Code.' };
    }

    const agentList: string[] = control.value.split(',');

    if (agentList.length > 10) {
      return { agentCodeListInvalid: 'Supports input of up to 10 agentCode.' };
    }

    for (let index = 0; index < agentList.length; index++) {
      if (agentList[index].length > 20) {
        return { agentCodeListInvalid: 'Max length of each Agent Code is 20.' };
      }
    }

    if (new Set(agentList).size !== agentList.length) {
      return { agentCodeListInvalid: 'Duplicated Agent Code.' };
    }

    return null;
  };
}

/**
 * Allow number string, such as '01229342'
 *
 * @param errorMessageKey
 */
export function numberStringValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) {
      return null;
    }
    const regex = /^[0-9]+$/;
    if (!regex.test(control.value)) {
      return { invalidNumberString: 'Please input numbers' };
    }

    return null;
  };
}

/**
 * Allow English, numeric and special characters
 *
 * @param errorMessageKey
 */
export function hkidValidator(errorMessageKey: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) {
      return null;
    }
    const regex = /^[A-Za-z)0-9`~!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+$/;
    return regex.test(control.value) ? null : { hkidInvalid: errorMessageKey };
  };
}

/**
 * Allow English, numeric and special characters
 *
 * @param errorMessageKey
 */
export function passportValidator(errorMessageKey: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) {
      return null;
    }
    const regex = /^[A-Za-z)0-9`~!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+$/;
    return regex.test(control.value) ? null : { passportInvalid: errorMessageKey };
  };
}

/**
 * Allow English, numeric and special characters
 *
 * @param errorMessageKey
 */
export function idValidator(errorMessageKey: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) {
      return null;
    }
    const regex = /^[A-Za-z)0-9`~!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+$/;
    return regex.test(control.value) ? null : { idInvalid: errorMessageKey };
  };
}
