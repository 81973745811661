<div class="l-pb-4">
  <div class="row l-plr-0 cds-white div-border-top div-border-bottom">
    <div class="col-xs-2 col-md-2 l-pb-2 l-pt-2 l-pl-4 l-pr-4"></div>
    <div class="col-xs-5 col-md-5 l-pb-2 l-pt-2 l-pl-4 l-pr-4">{{ 'calendar.event.date' | lang }}<span class="cds-coral-text dark-1">*</span></div>
    <div class="col-xs-5 col-md-5 l-pb-2 l-pt-2 l-pl-4 l-pr-4">{{ 'calendar.event.date' | lang }}<span class="cds-coral-text dark-1">*</span></div>
  </div>
  <form [formGroup]="formGroup">
    <ng-container formArrayName="formArray">
      <ng-container *ngFor="let myForm of formArray.controls; let index = index; odd as odd">
        <div [formGroupName]="index.toString()" class="row l-plr-0 div-border-bottom" [class.cds-white]="odd">
          <div class="col-xs-2 col-md-2 l-pt-4 l-pb-4 l-pl-4 l-pr-4 div-border-right l-d-f l-ai-cen">
            {{ monthConfig[index].label | lang }}
          </div>
          <div class="col-xs-5 col-md-5 l-pt-3 l-pl-4 l-pr-8 div-border-right">
            <cds-textfield placeholder="{{ 'calendar.action.inputEventDate' | lang }}" formControlName="eventDateOne"> </cds-textfield>
            <div style="min-height: 32px">
              <cds-assistive-text
                class="l-pt-3"
                *ngIf="formArray.controls[index].get('eventDateOne')?.errors && formArray.controls[index].get('eventDateOne')?.dirty">
                {{ formArray.controls[index].get('eventDateOne')?.errors?.['error'] | lang }}
              </cds-assistive-text>
              <cds-assistive-text class="l-pt-3" *ngIf="formArray.controls[index].errors && formArray.controls[index].dirty">
                {{ formArray.controls[index].errors?.['error'] | lang}}
              </cds-assistive-text>
            </div>
          </div>
          <div class="col-xs-5 col-md-5 l-pt-3 l-pl-4 l-pr-8 div-border-right">
            <cds-textfield placeholder="{{ 'calendar.action.inputEventDate' | lang }}" formControlName="eventDateTwo"> </cds-textfield>
            <div style="min-height: 32px">
              <cds-assistive-text
                class="l-pt-3"
                *ngIf="formArray.controls[index].get('eventDateTwo')?.errors && formArray.controls[index].get('eventDateTwo')?.dirty">
                {{ formArray.controls[index].get('eventDateTwo')?.errors?.['error'] | lang }}
              </cds-assistive-text>
            </div>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </form>
</div>
