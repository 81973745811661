import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CdsLanguageModule } from '@cds/ng-core/lang';
import { CdsProgressModule } from '@cds/ng-web-components/progress';
import { LoadingComponent } from './loading.component';
@NgModule({
  declarations: [LoadingComponent],
  imports: [CommonModule, CdsProgressModule, CdsLanguageModule],
  exports: [LoadingComponent],
})
export class LoadingModule {}
