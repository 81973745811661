import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { CdsButtonConfig } from '@cds/ng-core/button';

@Component({
  selector: 'app-close-chat-confirm',
  templateUrl: './close-chat-confirm.component.html',
  styleUrls: ['./close-chat-confirm.component.scss'],
})
export class CloseChatConfirmComponent implements OnInit {
  agreeButtonConfig: CdsButtonConfig = {
    /* ... */
  };
  disagreeButtonConfig: CdsButtonConfig = {
    /* ... */
  };

  constructor(private dialogRef: MatDialogRef<CloseChatConfirmComponent>) {}

  ngOnInit(): void {}

  agree() {
    this.dialogRef.close(true);
  }
  disagree() {
    this.dialogRef.close(false);
  }
}
