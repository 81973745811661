<div class="bg">
  <cds-popup>
    <div class="row l-plr-0">
      <div class="col-xs-12 cds-color-green">
        <span class="cds-h5-demibold">{{ 'er.profile.invested-funds-information' | lang }}</span>
      </div>
      <div class="col-xs-12 row table-tile l-pt-5">
        <div class="col-xs">
          <span class="cds-body1-demibold">Fund Code</span>
        </div>
        <div class="col-xs">
          <span class="cds-body1-demibold">Fund Description</span>
        </div>
        <div class="col-xs flex">
          <span class="cds-body1-demibold">Fund Balance</span>
          <app-tooltip-icon class="l-ml-2" tooltipString="Sum of “Unit Balance” of all invested funds of the ER"></app-tooltip-icon>
        </div>
      </div>
      <ng-container *ngIf="data.investedFundsData.length">
        <div *ngFor="let item of investedFundsData" class="col-xs-12 row table-item">
          <div class="col-xs">
            <span class="cds-body1">{{ item.fundCode }}</span>
          </div>
          <div class="col-xs">
            <span class="cds-body1">{{ item.fundDescription }}</span>
          </div>
          <div class="col-xs">
            <span class="cds-body1">{{ item.totalBalance | number : '1.2-2' }}</span>
          </div>
        </div>
      </ng-container>
      <div class="col-xs">
        <app-no-result-found *ngIf="!data.investedFundsData.length"></app-no-result-found>
      </div>
    </div>
  </cds-popup>
</div>
