import { Component, OnInit } from '@angular/core';
import { CdsButtonConfig } from '@cds/ng-core/button';
import { MatDialogRef } from '@cds/ng-web-components/popup';
import { ChequePrintingListPTWComponent } from '../cheque-printing-list/cheque-printing-list-ptw.component';

@Component({
  selector: 'app-cheque-printing-back-popup-ptw',
  templateUrl: './cheque-printing-back-popup-ptw.component.html',
  styleUrls: ['./cheque-printing-back-popup-ptw.component.scss'],
})
export class ChequePrintingBackPopupPTWComponent implements OnInit {
  constructor(private dialogRef: MatDialogRef<ChequePrintingListPTWComponent>) {}

  agreeButtonConfig: CdsButtonConfig = {
    style: 'secondary',
  };
  disagreeButtonConfig: CdsButtonConfig = {
    style: 'primary',
  };
  ngOnInit(): void {}
  agree() {
    this.dialogRef.close({ agree: false });
  }
  disagree() {
    this.dialogRef.close({ agree: true });
  }
}
