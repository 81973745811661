/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { tab } from './commission-default-scale.config';

@Component({
  selector: 'app-commission-default-scale',
  templateUrl: './commission-default-scale.component.html',
  styleUrls: ['./commission-default-scale.component.scss'],
})
export class CommissionDefaultScaleComponent implements OnInit {
  tab = tab;
  tabSelected: any;
  constructor(private route: ActivatedRoute) {}

  ngOnInit() {
    this.setTabSelected();
  }

  setTabSelected() {
    const tabSelected = this.route.snapshot.paramMap.get('tab') || this.tab[1].value;
    const selectedTab = this.tab.find(item => item.value === tabSelected) || this.tab[0];
    this.tabClick(selectedTab);
  }

  tabClick(tab: any) {
    this.tabSelected = tab;
    this.tab.forEach(j => {
      const item: any = j;
      if (item.value == tab.value) {
        item.selected = true;
      } else {
        item.selected = false;
      }
    });
  }
}
