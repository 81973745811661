import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { CdsButtonConfig } from '@cds/ng-core/button';

@Component({
  selector: 'app-exit-iff-warn-popup',
  templateUrl: './exit-iff-warn-popup.component.html',
  styleUrls: ['./exit-iff-warn-popup.component.scss'],
})
export class ExitIffWarnPopupComponent implements OnInit {
  disagreeButtonConfig: CdsButtonConfig = {
    size: 'md',
    style: 'secondary',
    disabled: false,
  };
  agreeButtonConfig: CdsButtonConfig = {
    size: 'md',
    style: 'primary',
    disabled: false,
  };
  constructor(private dialogRef: MatDialogRef<ExitIffWarnPopupComponent>) {}

  ngOnInit(): void {}
  disagree() {
    this.dialogRef.close({ agree: false });
  }
  agree() {
    this.dialogRef.close({ agree: true });
  }
}
