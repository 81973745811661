import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { NgModel } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { CdsButtonConfig } from '@cds/ng-core/button';
import { CdsDropdownConfig } from '@cds/ng-core/dropdown';
import { CdsIconConfig } from '@cds/ng-core/icon';
import { CdsLangService } from '@cds/ng-core/lang';
import { CdsTextfieldConfig } from '@cds/ng-core/textfield';
import { CdsPopupService, CDS_POPUP_DATA } from '@cds/ng-web-components/popup';
import moment from 'moment';
import { finalize } from 'rxjs';
import { BTN_CONFIG_TERTIARY } from 'src/app/config/btn.config';
import { PermissionAccess, PermissionItem } from 'src/app/core/models/enum/permission.enum';
import { ContinuePopupComponent } from 'src/app/shared/continue-popup/continue-popup.component';
import { ToastAlertService } from 'src/app/shared/toast-alert.service';
import { deepCopy } from 'src/app/utils/copy';
import { ErRecord, I18N_KEY, SpecialQuote, SpecialQuoteStatusOptions, SpecialQuoteTypeOptions } from '../../employer';
import { EmployerProfileCompanyListService } from '../../employer-profile-company-list/employer-profile-company-list.service';
import { EmployerService } from '../../employer.service';
import { CdMultidropdownTopping } from 'src/app/shared/cd-multidropdown/cd-multidropdown.model';

@Component({
  selector: 'app-add-special-quote',
  templateUrl: './add-special-quote.component.html',
  styleUrls: ['./add-special-quote.component.scss'],
})
export class AddSpecialQuoteComponent implements OnInit {
  @ViewChild('sqReferenceNo') sqReferenceNo?: NgModel;
  @ViewChild('sqSubmissionDate') sqSubmissionDate?: NgModel;
  @ViewChild('remarks') remarks?: NgModel;

  resetButtonConfig: CdsButtonConfig = { size: 'sm', style: 'secondary' };

  backButtonConfig: CdsButtonConfig = { size: 'sm', style: 'secondary' };

  saveButtonConfig: CdsButtonConfig = { size: 'sm' };

  resetDisabled = true;

  saveDisabled = true;

  isLoading = false;

  isSummary = false;

  get nowDateStr() {
    return moment().format('DD/MM/YYYY');
  }

  btnCfg: CdsButtonConfig = BTN_CONFIG_TERTIARY;

  customerOption: CdMultidropdownTopping[] = [];

  originDataSource: SpecialQuote = this.data.editMode
    ? {
        ...this.data.specialQuote,
      }
    : {
        sqReferenceNo: '',
        quoteType: undefined,
        sqSubmissionDate: '',
        customerIds: [],
        companyList: [],
        showRemarks: false,
        quoteStatus: undefined,
        remarks: '',
      };

  dataSource: SpecialQuote = deepCopy(this.originDataSource);

  txtfieldConfig: CdsTextfieldConfig = {
    label: 'SQ Reference No.',
    placeholder: 'Input SQ Reference No.',
    type: 'text',
  };

  dropdownConfig: CdsDropdownConfig = {
    label: 'Quote Type',
    placeholder: 'Select Quote Type',
    options: SpecialQuoteTypeOptions,
  };

  statusDropdownConfig: CdsDropdownConfig = {
    label: 'Status',
    placeholder: 'Select Status',
    options: SpecialQuoteStatusOptions,
  };

  selectedCustomerOption: CdMultidropdownTopping[] = [];

  selectAllIconConfig: CdsIconConfig = {
    color: '#EC6453',
  };

  showDatePicker = true;

  SpecialQuoteTypeOptions = SpecialQuoteTypeOptions;

  SpecialQuoteStatusOptions = SpecialQuoteStatusOptions;

  iconConfig: CdsIconConfig = {
    color: '#D03A39',
    size: 'sm',
  };

  I18N_KEY = I18N_KEY;

  PermissionAccess = PermissionAccess;

  PermissionItem = PermissionItem;

  get nowDate() {
    return new Date();
  }

  constructor(
    private dialogRef: MatDialogRef<AddSpecialQuoteComponent>,
    public employerService: EmployerService,
    private cdsPopup: CdsPopupService,
    private langService: CdsLangService,
    private companyService: EmployerProfileCompanyListService,
    private toastAlert: ToastAlertService,
    @Inject(CDS_POPUP_DATA)
    public data: {
      id: string;
      editMode: boolean;
      specialQuote: SpecialQuote;
    }
  ) {}

  ngOnInit(): void {
    if (!this.data.editMode) {
      this.loadCompany();
    }
  }

  loadCompany() {
    this.isLoading = true;
    this.companyService
      .findAll(this.data.id)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe({
        next: res => {
          if (res.result === 0 && res.data) {
            this.customerOption = this.customerOptionGen(res.data);
            this.ifSelectAll(this.dataSource.companyList?.length);
            // this.add();
            this.originDataSource = deepCopy(this.dataSource);
          } else {
            this.toastAlert.show('error', 'common.error', res.message, 5000);
          }
        },
      });
  }

  customerOptionGen(data: ErRecord[]): CdMultidropdownTopping[] {
    return data
      .filter(item => item.id !== this.data.id)
      .map(item => {
        return {
          subLabel: item.employerName,
          label: item.employerNo || '',
          value: item.id,
          isParentCompany: item.parentCompany === 'Y',
          exatraInfo: item.parentCompany === 'Y' ? '(mother company)' : '',
        };
      });
  }

  delete() {
    this.cdsPopup
      .open(ContinuePopupComponent, {
        size: 'sm',
        data: {
          title: 'Are you sure you want to delete this SQ record?',
          continueButtonName: this.langService.translate('common.delete'),
        },
      })
      .afterClosed()
      .subscribe(result => {
        if (result?.agree) {
          this.dialogRef.close({
            agree: true,
            isDelete: true,
          });
        }
      });
  }

  reset() {
    if (this.resetDisabled) {
      return;
    }

    this.cdsPopup
      .open(ContinuePopupComponent, {
        size: 'sm',
        data: { message: this.langService.translate('common.action.cancel') },
      })
      .afterClosed()
      .subscribe(result => {
        if (result?.agree) {
          // reload datepicker dom to clear the selected date
          this.showDatePicker = false;
          setTimeout(() => {
            this.showDatePicker = true;
            this.dataSource = deepCopy(this.originDataSource);
            this.sqReferenceNo?.control.markAsPristine();
            this.validate();
          });
        }
      });
  }

  save() {
    if (this.saveDisabled) {
      return;
    }

    this.isSummary = true;
  }

  confirm() {
    if (this.saveDisabled) {
      return;
    }

    const params = this.getAddParams();
    delete params.updatedAt;

    this.dialogRef.close({
      agree: true,
      params: params,
    });
  }
  validate() {
    setTimeout(() => {
      this.doValidate();
    });
  }

  doValidate = () => {
    const allValid =
      this.sqReferenceNo?.control.valid && this.sqSubmissionDate?.control.valid && (this.dataSource.showRemarks ? this.remarks?.control.valid : true);

    if (JSON.stringify(this.dataSource) !== JSON.stringify(this.originDataSource)) {
      this.resetDisabled = false;

      this.saveDisabled = !(
        !!this.dataSource.sqReferenceNo &&
        this.dataSource.quoteType !== undefined &&
        !!this.dataSource.sqSubmissionDate &&
        (this.dataSource.showRemarks ? !!this.dataSource.remarks : true) &&
        this.dataSource.quoteStatus !== undefined &&
        allValid
      );
    } else {
      this.resetDisabled = true;
      this.saveDisabled = true;
    }
  };

  getAddParams() {
    this.dataSource.customerIds = this.dataSource.companyList?.map(item => item.value);
    return this.dataSource;
  }

  selectAll() {
    if (this.ifSelectAll(this.dataSource.companyList?.length)) {
      this.dataSource.companyList = [];
    } else {
      this.dataSource.companyList = [...this.customerOption];
    }
    this.dataSource = { ...this.dataSource };
    this.validate();
  }

  ifSelectAll = (selectedLength?: number) => {
    if (!selectedLength) {
      return false;
    }
    if (selectedLength === this.customerOption.length) {
      return true;
    } else {
      return false;
    }
  };

  back() {
    this.isSummary = false;
  }

  close() {
    if (!this.resetDisabled) {
      this.cdsPopup
        .open(ContinuePopupComponent, {
          size: 'sm',
          data: { message: this.langService.translate('common.action.cancel') },
        })
        .afterClosed()
        .subscribe(result => {
          if (result?.agree) {
            this.dialogRef.close();
          }
        });
    } else {
      this.dialogRef.close();
    }
  }
}
